import { FC, useMemo } from 'react';

import { useAppSelector, useDataLoad } from 'hooks';
import PdfNoDataText from 'portfolio3/features/pdf/components/PdfNoDataText';
import { getDataLoadRequests } from 'utils';

import { PdfCommonDataEntity } from '../../../../components/pdfStudent/DataEntity';
import { widgetPdfIcons } from '../../../../icons';
import { professionJobsSelector } from '../../../../selectors';
import PdfCommonDataEntityContainer from '../common';

import '../index.scss';

const PdfJobsContainer: FC = () => {
  const jobs = useAppSelector(professionJobsSelector);
  useDataLoad({ shouldLoad: true, requests: getDataLoadRequests().professionJobs });

  const hasData = jobs.content.length > 0;

  const filteredYear = useMemo(
    () =>
      jobs.content.length > 0
        ? jobs.content?.reduce((prevItem, nextItem) => (prevItem.year > nextItem.year ? prevItem : nextItem)).year
        : undefined,
    [jobs],
  );

  return (
    <PdfCommonDataEntityContainer title="Трудоустройство" icon={widgetPdfIcons.pdfIconProfessionJobs}>
      {hasData &&
        jobs.content.map((entity) => (
          <PdfCommonDataEntity key={entity.id || entity.recordId} entity={entity} year={filteredYear} />
        ))}
      {!hasData && <PdfNoDataText />}
    </PdfCommonDataEntityContainer>
  );
};

export default PdfJobsContainer;
