import { Dispatch, FC, SetStateAction } from 'react';

import { Box } from '@mui/material';
import { MaskKeys } from 'const';
import { BaseInputLabel, FormControl, Input, MaskedInput } from 'portfolio3/ui-kit';
import { DayPicker, DayPickerController } from 'portfolio3/ui-kit/datePickers';

import { IStudentSearcherFormData } from '../types';
import * as styles from './styles';

interface ITopControlsProps {
  formData: IStudentSearcherFormData;
  setFormData: Dispatch<SetStateAction<IStudentSearcherFormData>>;
}

const StudentSearcherTopControls: FC<ITopControlsProps> = ({ formData, setFormData }) => {
  const nameController = {
    handleChange: (name: string) => {
      setFormData({
        ...formData,
        name,
      });
    },
  };

  const surnameController = {
    handleChange: (surname: string) => {
      setFormData({
        ...formData,
        surname,
      });
    },
  };

  const patronymicController = {
    handleChange: (patronymic: string) => {
      setFormData({
        ...formData,
        patronymic,
      });
    },
  };

  const snilsController = {
    handleChange: (snils: string) => {
      setFormData({
        ...formData,
        snils,
      });
    },
  };

  const phoneController = {
    handleChange: (phoneNumber: string) => {
      setFormData({
        ...formData,
        phoneNumber,
      });
    },
  };

  const dateController: DayPickerController = {
    handleChange(value) {
      setFormData({
        ...formData,
        birthDate: value,
      });
    },
    handleClear() {
      setFormData({
        ...formData,
        birthDate: null,
      });
    },
  };

  const inputsRenderMode = 'fixed';
  const inputSize = 'medium';

  return (
    <Box className="top-controls" sx={styles.root}>
      <FormControl
        renderMode={inputsRenderMode}
        inputSize={inputSize}
        label={<BaseInputLabel>Фамилия</BaseInputLabel>}
        control={
          <Input
            size={inputSize}
            renderMode={inputsRenderMode}
            controller={surnameController}
            placeholder="Фамилия учащегося..."
            value={formData.surname}
          />
        }
      />
      <FormControl
        renderMode={inputsRenderMode}
        inputSize={inputSize}
        label={<BaseInputLabel>Имя</BaseInputLabel>}
        control={
          <Input
            size={inputSize}
            renderMode={inputsRenderMode}
            controller={nameController}
            placeholder="Имя учащегося..."
            value={formData.name}
          />
        }
      />
      <FormControl
        renderMode={inputsRenderMode}
        inputSize={inputSize}
        label={<BaseInputLabel>Отчество</BaseInputLabel>}
        control={
          <Input
            size={inputSize}
            renderMode={inputsRenderMode}
            controller={patronymicController}
            placeholder="Отчество учащегося..."
            value={formData.patronymic}
          />
        }
      />
      <FormControl
        sx={styles.dateControl}
        renderMode={inputsRenderMode}
        inputSize={inputSize}
        label={<BaseInputLabel>Дата рождения</BaseInputLabel>}
        control={
          <DayPicker
            renderMode={inputsRenderMode}
            size={inputSize}
            value={formData.birthDate}
            controller={dateController}
            maxValue={new Date()}
          />
        }
      />
      <FormControl
        sx={styles.snilsControl}
        renderMode={inputsRenderMode}
        inputSize={inputSize}
        label={<BaseInputLabel>СНИЛС</BaseInputLabel>}
        control={
          <MaskedInput
            size={inputSize}
            renderMode={inputsRenderMode}
            controller={snilsController}
            maskedInputProps={{
              placeholder: 'XXX-XXX-XXX XX',
              value: formData.snils || '',
              mask: MaskKeys.SNILS,
            }}
          />
        }
      />
      <FormControl
        sx={styles.phoneControl}
        renderMode={inputsRenderMode}
        inputSize={inputSize}
        label={<BaseInputLabel>Номер телефона</BaseInputLabel>}
        control={
          <MaskedInput
            size={inputSize}
            renderMode={inputsRenderMode}
            controller={phoneController}
            maskedInputProps={{
              placeholder: '+7 (XXX) XXX XX-XX',
              value: formData.phoneNumber || '',
              mask: MaskKeys.PHONE_NUMBER,
            }}
          />
        }
      />
    </Box>
  );
};

export default StudentSearcherTopControls;
