import { createContext } from 'react';

interface IWidgetDataContext {
  widgetLabel: string | null;
}

const initialState: IWidgetDataContext = {
  widgetLabel: null,
};

export const WidgetDataContext = createContext(initialState);
