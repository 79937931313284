/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { isValidElement } from 'react';

import anchrome from 'anchorme';

import { IChildInfo, IDictionaryItem, IUserFio } from '../api/types';
import { getCultureMuseumsPdf } from '../api';
import { MOSCOW_DOMAIN, REGIONAL_DOMAIN } from '../const';
import { NestedKeyOf } from './types';

export const currentDomain = window.location.hostname.includes(REGIONAL_DOMAIN) ? REGIONAL_DOMAIN : MOSCOW_DOMAIN;

export const getPersonInitials = (personInfo: IUserFio | IChildInfo | undefined) => {
  const firstname = (personInfo as IChildInfo)?.firstname || (personInfo as IUserFio)?.firstName || '';
  const lastname = (personInfo as IChildInfo)?.lastname || (personInfo as IUserFio)?.lastName || '';

  return `${firstname.slice(0, 1)}${lastname.slice(0, 1)}`;
};

export const getPersonFullName = (personInfo: IUserFio | IChildInfo | undefined) => {
  const firstname = (personInfo as IChildInfo)?.firstname || (personInfo as IUserFio)?.firstName || '';
  const lastname = (personInfo as IChildInfo)?.lastname || (personInfo as IUserFio)?.lastName;
  const patronymic = (personInfo as IChildInfo)?.patronymic || (personInfo as IUserFio)?.patronymic;

  return `${lastname}${firstname ? ` ${firstname[0]}.` : ''}${patronymic ? ` ${patronymic[0]}.` : ''}`;
};

export const sortTableByField = (field: string, direction: string | undefined) => {
  if (direction === 'asc') {
    return (a: any, b: any) => (a[field] > b[field] ? 1 : -1);
  } if (direction === 'desc') {
    return (a: any, b: any) => (a[field] < b[field] ? 1 : -1);
  }
};

export const openUrlInNewTab = (url: string) => {
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  link.click();
  link.remove();
}

export const openCultureMuseumsPdf = async () => {
  const {response} = await getCultureMuseumsPdf();
  const urlCreator = window.URL || window.webkitURL;
  const url = urlCreator.createObjectURL(response.blob);

  openUrlInNewTab(url);
}

// Возможно нужно будет использовать в формах, где можно вставлять ссылки
export const detectLink = (text: string) => {
  const processedText: string[] = [];
  // Обработка строки и добавление к переносу строки пробелов, для корректной работы
  text.split('').forEach((char: string) => {
    if (char.match(/\n/)) {
      char = ` ${char} `;
    }
    processedText.push(char);
  });

  // Разбиваем строку на массив слов
  const textArray = processedText.join('').split(' ');
  return textArray.map((textItem) => {
    // Разбиваем каждое слово на массив букв
    const textItemSymbols = textItem.split('');
    const strRegExp = /\W/g;
    const pastNonWord: string[] = [];
    // разворачиваем массив букв и проверяем, является ли буква спецсимволом, пока не натыкаемся на не спецсимвол
    // Это нужно для того, чтобы ссылка работала, если у неё в конце есть спецсиволы (например ,)
    textItemSymbols.reverse().every((itemSymbol) => {
      const result = itemSymbol.match(strRegExp);
      if (result) {
        // Спецсимволы записываем в отдельный массив
        pastNonWord.push(itemSymbol);
        return true;
      }
      return false;
    });

    // Разворачиваем массив спецсимволов обратно и преобразуем в строку
    const pastSymbols = pastNonWord.reverse().join('');
    let linkBody = textItem;
    // Вырезаем из ссылки спецсимволы
    if (pastSymbols) linkBody = textItem.slice(0, -pastSymbols.length);

    // Проверка, является ли данный символ переносом строки
    const isSymbolEnter = Boolean(textItem.match(/\n/));

    // Проверяем, валидна ли ссылка
    // Если она валидна, возвращаем тег a
    if (anchrome.validate.url(encodeURI(linkBody)) === true) {
      // Если у ссылки нет протокола, то прикрепляем его
      let linkPath = linkBody;
      if (!linkBody.includes('http')) {
        linkPath = 'https://'.concat(linkBody);
      }

      return (
        <>
          <a href={linkPath} target="_blank" rel="noreferrer">
            {linkBody}
          </a>
          {pastSymbols}
          {!isSymbolEnter ? <span>&nbsp;</span> : null}
        </>
      );
    }
    return (
      <>
        {textItem}
        {!isSymbolEnter ? <span>&nbsp;</span> : null}
      </>
    );
  });
};

export const copyObjectDeep = (object: object) => JSON.parse(JSON.stringify(object));

export const isUUIDString = (uuid: string | number | undefined) => {
  if (!uuid || typeof uuid !== 'string') return false;
  const regExp = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return regExp.test(uuid);
};

export const isSectionAllowed = (sectionCode: number, allowedCodes: number[]): boolean => allowedCodes.includes(sectionCode);

export const getStudentGuid = (isWebview: boolean | undefined) => {
  if (!isWebview) return;
  const url = new URL(window.location.href);
  const studentGuid = url.searchParams.get('contingent_guid') || undefined;
  return studentGuid;
};

// Делает объект и все вложенные объекты иммутабельными
export const deepFreeze = (obj: any) => {
  Object.keys(obj).forEach((prop: string) => {
    if (typeof obj[prop] === 'object' && !Object.isFrozen(obj[prop])) deepFreeze(obj[prop]);
  });
  return Object.freeze(obj);
};
type RecordKey = string | number | symbol;
/**
 * Меняет местами ключи и значения для переданной записи.
 * @param input исходная запись
 * @returns результат замены
 */
export function reverseRecord<K extends RecordKey, V extends RecordKey>(
  input: Record<K, V>
) {
  const entries = Object.entries(input).map(([key, value]) => [
    value, key
  ]);
  return Object.fromEntries(entries) as Record<V, K>;
}

export function getItemAndIndexFromArrayById<T extends { id: number }>(array: T[], itemId: number): [T | undefined, number] {
  return [
    array.find((item) => item.id === itemId) ?? undefined,
    array.findIndex((item) => item.id === itemId)
  ];
}

export function isDefined<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export function findChildComponentByType(children: React.ReactNode, type: React.FC<any>) {
  const childrenArray = React.Children.toArray(children);

  const component = childrenArray.find((child) => {
    if (isValidElement(child) && child.type === type) return children;
  });

  return component ?? null;
}

export const numberToPixelsString = (num: number): string => `${num}px`;

export const clamp = (value: number, min: number, max: number) => {
  if (value < min) return min;
  if (value > max) return max;
  return value;
};

/**
 * Ф-я возвращает рандомное число между двумя числами включительно
 * @param min - минимальное значение
 * @param max - максимально значение
 * @returns рандомное число в заданном диапазоне
 */
export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * ф-я округляет дробные числа до десятых, но если последнее число - 0, то до ближайшего целого
 * @param number - любое число
 * @returns Дробное число, округленное до десятых (1,5) или ближайшее целое
 */
export const roundNumberToTenths = (number: number): number => {
  const roundedToTenths = number.toFixed(1);
  const lastIndex = roundedToTenths.length - 1;
  return Number(roundedToTenths[lastIndex] === '0' ? Math.round(Number(roundedToTenths)) : roundedToTenths);
};

/**
 * Функция устанавливает значение во вложенное свойство объекта
 * @returns Новый объект с измененным свойством
 */
export const deepSetProperty = <T extends unknown, ValueType>(
  object: T,
  key: NestedKeyOf<Required<T>>,
  value: ValueType,
): T => {
  const levels = key.split('.');
  const levelsLength = levels.length;

  if (levelsLength === 0) return object;

  const currentLevelKey = levels[0];
  const isObject = typeof object === 'object';

  // если обход еще не закончен то object[currentLevelKey] будет объектом
  // если передан не объект, то создается пустой объект
  return {
    ...(isObject ? object : {} as any),
    [currentLevelKey]:
      levels.length > 1
        ? deepSetProperty((object as any)[currentLevelKey], levels.splice(1).join('.'), value)
        : value,
  };
};

export const deepGetProperty = <T extends unknown>(
  object: T,
  key: NestedKeyOf<Required<T>>
): unknown => {
  let value: any = object;

  const levels = key.split('.');

  for (const key of levels) {
    value = value?.[key];
  }

  return value;
}

export const getPaginationLimitOptions = (limits: number[]) => {
  return limits.map(
    (limit): IDictionaryItem => ({
      code: limit,
      value: String(limit),
    }),
  );
}
