import {
  DELETE_PORTFOLIO_AFFILATION,
  DELETE_PORTFOLIO_EMPLOYMENT,
  DELETE_PORTFOLIO_EVENT,
  DELETE_PORTFOLIO_JOB,
  DELETE_PORTFOLIO_PROJECT,
  DELETE_PORTFOLIO_REWARD,
  DELETE_PORTFOLIO_SPO_DOCUMENT,
  DELETE_PORTFOLIO_SPORT_REWARD,
  DELETE_STUDENT_CHECKIN,
  EDIT_PORTFOLIO_AFFILATION,
  EDIT_PORTFOLIO_EMPLOYMENT,
  EDIT_PORTFOLIO_EVENT,
  EDIT_PORTFOLIO_JOB,
  EDIT_PORTFOLIO_PROJECT,
  EDIT_PORTFOLIO_REWARD,
  EDIT_PORTFOLIO_SPO_DOCUMENT,
  EDIT_PORTFOLIO_SPORT_REWARD,
} from 'actions';
import { REQUEST, SUCCESS } from 'actions/utils';
import {
  searchStudentAffilations,
  searchStudentCheckInHistory,
  searchStudentCultureVisitings,
  searchStudentDiagnotics,
  searchStudentDocuments,
  searchStudentEmployments,
  searchStudentEvents,
  searchStudentGiaList,
  searchStudentGiaWorldskills,
  searchStudentIndependentDiagnostics,
  searchStudentInterests,
  searchStudentJobs,
  searchStudentMetaskills,
  searchStudentProfessionalAdditionalEducation,
  searchStudentProjects,
  searchStudentRewards,
  searchStudentSpoEducation,
  searchStudentSportRewards,
} from 'api';
import { ApiResult } from 'api/impl';
import { IRootState } from 'reducers';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import { GenericGetAction } from 'sagas';
import store from 'store';

import {
  CommonActionsObject,
  SEARCH_AFFILIATIONS,
  SEARCH_CHECKIN_HISTORY,
  SEARCH_CULTURE_VISITINGS,
  SEARCH_DIAGNOSTICS,
  SEARCH_DOCUMENTS,
  SEARCH_EMPLOYMENTS,
  SEARCH_EVENTS,
  SEARCH_GIA,
  SEARCH_GIA_WORLDSKILLS,
  SEARCH_INDEPENDENT_DIAGNOSTICS,
  SEARCH_INTERESTS,
  SEARCH_JOBS,
  SEARCH_METASKILLS,
  SEARCH_PROFESSIONAL_ADDITIONAL_EDUCATION,
  SEARCH_PROJECTS,
  SEARCH_REWARDS,
  SEARCH_SPO_EDUCATION,
  SEARCH_SPORT_REWARDS,
  searchAffiliationsActions,
  searchCheckinHistoryActions,
  searchCultureVisitingsActions,
  searchDiagnosticsActions,
  searchDocumentsActions,
  searchEmploymentsActions,
  searchEventsActions,
  searchGiaActions,
  searchGiaWorldskillsActions,
  searchIndependentDiagnosticsActions,
  searchInterestsActions,
  searchJobsActions,
  searchMetaskillsActions,
  searchProfessionalAdditionalEducationActions,
  searchProjectsActions,
  searchRewardsActions,
  searchSpoEducationActions,
  searchSportRewardsActions,
} from './actions';

export const collection = [
  watchSearchRewards,
  watchSearchSportRewards,
  watchSearchGia,
  watchSearchDiagnostics,
  watchSearchEvents,
  watchSearchProjects,
  watchSearchAffiliations,
  watchSearchEmployments,
  watchSearchCultureVisitings,
  watchSearchCheckInHistory,
  watchSearchInterests,
  watchSearchIndependentDiagnostics,
  watchSearchProfessionalAdditionalEducation,
  watchSearchDocuments,
  watchSearchSpoEducation,
  watchSearchJobs,
  watchSearchMetaskills,
  watchSearchGiaWorldskills,

  ...createModifyWatchers(EDIT_PORTFOLIO_REWARD, DELETE_PORTFOLIO_REWARD, searchRewardsActions),
  ...createModifyWatchers(EDIT_PORTFOLIO_SPORT_REWARD, DELETE_PORTFOLIO_SPORT_REWARD, searchSportRewardsActions),
  ...createModifyWatchers(EDIT_PORTFOLIO_EVENT, DELETE_PORTFOLIO_EVENT, searchEventsActions),
  ...createModifyWatchers(EDIT_PORTFOLIO_PROJECT, DELETE_PORTFOLIO_PROJECT, searchProjectsActions),
  ...createModifyWatchers(EDIT_PORTFOLIO_AFFILATION, DELETE_PORTFOLIO_AFFILATION, searchAffiliationsActions),
  ...createModifyWatchers(EDIT_PORTFOLIO_EMPLOYMENT, DELETE_PORTFOLIO_EMPLOYMENT, searchEmploymentsActions),
  ...createModifyWatchers(null, DELETE_STUDENT_CHECKIN, searchCheckinHistoryActions),
  ...createModifyWatchers(EDIT_PORTFOLIO_SPO_DOCUMENT, DELETE_PORTFOLIO_SPO_DOCUMENT, searchDocumentsActions),
  ...createModifyWatchers(EDIT_PORTFOLIO_JOB, DELETE_PORTFOLIO_JOB, searchJobsActions),
].map(fork);

function* watchSearchRewards() {
  yield takeLatest(SEARCH_REWARDS[REQUEST], createSagaFunction(searchRewardsActions, searchStudentRewards));
}
function* watchSearchSportRewards() {
  yield takeLatest(
    SEARCH_SPORT_REWARDS[REQUEST],
    createSagaFunction(searchSportRewardsActions, searchStudentSportRewards),
  );
}
function* watchSearchGia() {
  yield takeLatest(SEARCH_GIA[REQUEST], createSagaFunction(searchGiaActions, searchStudentGiaList));
}
function* watchSearchDiagnostics() {
  yield takeLatest(SEARCH_DIAGNOSTICS[REQUEST], createSagaFunction(searchDiagnosticsActions, searchStudentDiagnotics));
}
function* watchSearchEvents() {
  yield takeLatest(SEARCH_EVENTS[REQUEST], createSagaFunction(searchEventsActions, searchStudentEvents));
}
function* watchSearchProjects() {
  yield takeLatest(SEARCH_PROJECTS[REQUEST], createSagaFunction(searchProjectsActions, searchStudentProjects));
}
function* watchSearchAffiliations() {
  yield takeLatest(
    SEARCH_AFFILIATIONS[REQUEST],
    createSagaFunction(searchAffiliationsActions, searchStudentAffilations),
  );
}
function* watchSearchEmployments() {
  yield takeLatest(SEARCH_EMPLOYMENTS[REQUEST], createSagaFunction(searchEmploymentsActions, searchStudentEmployments));
}
function* watchSearchCultureVisitings() {
  yield takeLatest(
    SEARCH_CULTURE_VISITINGS[REQUEST],
    createSagaFunction(searchCultureVisitingsActions, searchStudentCultureVisitings),
  );
}
function* watchSearchCheckInHistory() {
  yield takeLatest(
    SEARCH_CHECKIN_HISTORY[REQUEST],
    createSagaFunction(searchCheckinHistoryActions, searchStudentCheckInHistory),
  );
}
function* watchSearchInterests() {
  yield takeLatest(SEARCH_INTERESTS[REQUEST], createSagaFunction(searchInterestsActions, searchStudentInterests));
}
function* watchSearchIndependentDiagnostics() {
  yield takeLatest(
    SEARCH_INDEPENDENT_DIAGNOSTICS[REQUEST],
    createSagaFunction(searchIndependentDiagnosticsActions, searchStudentIndependentDiagnostics),
  );
}
function* watchSearchProfessionalAdditionalEducation() {
  yield takeLatest(
    SEARCH_PROFESSIONAL_ADDITIONAL_EDUCATION[REQUEST],
    createSagaFunction(searchProfessionalAdditionalEducationActions, searchStudentProfessionalAdditionalEducation),
  );
}
function* watchSearchDocuments() {
  yield takeLatest(SEARCH_DOCUMENTS[REQUEST], createSagaFunction(searchDocumentsActions, searchStudentDocuments));
}
function* watchSearchSpoEducation() {
  yield takeLatest(
    SEARCH_SPO_EDUCATION[REQUEST],
    createSagaFunction(searchSpoEducationActions, searchStudentSpoEducation),
  );
}
function* watchSearchJobs() {
  yield takeLatest(SEARCH_JOBS[REQUEST], createSagaFunction(searchJobsActions, searchStudentJobs));
}
function* watchSearchMetaskills() {
  yield takeLatest(SEARCH_METASKILLS[REQUEST], createSagaFunction(searchMetaskillsActions, searchStudentMetaskills));
}
function* watchSearchGiaWorldskills() {
  yield takeLatest(
    SEARCH_GIA_WORLDSKILLS[REQUEST],
    createSagaFunction(searchGiaWorldskillsActions, searchStudentGiaWorldskills),
  );
}

function createModifyWatchers(
  editType: Record<string, string> | null,
  deleteType: Record<string, string> | null,
  actionsObject: CommonActionsObject,
) {
  const watchers = [];

  if (editType) {
    watchers.push(function* watchEdit() {
      yield takeLatest(editType[SUCCESS], createModifySagaFunction(actionsObject));
    });
  }

  if (deleteType) {
    watchers.push(function* watchDelete() {
      yield takeLatest(deleteType[SUCCESS], createModifySagaFunction(actionsObject));
    });
  }

  return watchers;
}

const createModifySagaFunction = (actionsObject: CommonActionsObject) => {
  return function* sagaFunction() {
    const state = store.getState() as IRootState;

    const { isOpen, query } = state.searchPanel;
    const meshId = state.currentStudent.meshId;

    if (!isOpen) return;

    yield put(actionsObject.request(meshId, query));
  };
};

const createSagaFunction = (
  actionsObject: CommonActionsObject,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  apiMethod: (personId: string, searchString: string) => Promise<ApiResult<any, any>>,
) => {
  type PayloadType = ReturnType<typeof actionsObject.request>['payload']['payload'];

  return function* sagaFunction({ payload }: GenericGetAction<PayloadType>) {
    if (!payload) {
      return;
    }
    const { personId, searchString } = payload;
    const { response, error } = yield call(apiMethod, personId, encodeURIComponent(searchString));

    if (response) {
      yield put(actionsObject.success(response));
    } else {
      yield put(actionsObject.failure(error));
    }
  };
};
