import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import ResultDoughnut from 'components/common/ResultDoughnut';
import { format } from 'date-fns';
import TrimTextMultiLine from 'portfolio3/components/common/TrimTextMultiLine';
import { NeutralColors } from 'portfolio3/styles';
import { IGiaWorldSkillsEntity } from 'types';
import { checkPassedExamDate, getDoughnutData, getMark } from 'utils';

import * as styles from './styles';

interface IGiaWorldskillsCardProps {
  entity: IGiaWorldSkillsEntity;
}

const GiaWorldskillsCard: FC<IGiaWorldskillsCardProps> = ({ entity }) => {
  const { resultScore, maxCompetenceScore, name, resultDate } = entity;

  const doughnutData = getDoughnutData(resultScore, maxCompetenceScore);

  const mark = getMark(resultScore, maxCompetenceScore);

  let dateString: string | null = null;
  if (resultDate) {
    const examDate = new Date(resultDate);
    const passingYear = resultDate.slice(0, 4);
    dateString = checkPassedExamDate(examDate)
      ? `Проведен ${format(new Date(resultDate), 'dd.MM.yyyy')}`
      : `Будет сдан в ${passingYear} г.`;
  }

  return (
    <Box className="gia-worldskills-card pdf-keep" sx={styles.root}>
      <Box className="gia-worldskills-card__top" sx={styles.topBlock}>
        <Box sx={styles.chart}>
          <ResultDoughnut total={resultScore} data={doughnutData} color={NeutralColors.night_neutrals_600} />
        </Box>
        <Box className="gia-worldskills-card__info" sx={styles.info}>
          <Typography variant="Paragraph MD/Regular" color={NeutralColors.night_neutrals_500}>
            {mark}
          </Typography>
          <Typography component={TrimTextMultiLine} line={3} variant="Paragraph LG/Semi Bold">
            {name}
          </Typography>
        </Box>
      </Box>

      {dateString && (
        <Typography variant="Paragraph MD/Regular" color={NeutralColors.night_neutrals_500} textAlign="center">
          {dateString}
        </Typography>
      )}
    </Box>
  );
};

export default GiaWorldskillsCard;
