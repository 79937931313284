import React from 'react';

import { Typography } from '@mui/material';

import { ReportErrorTypeCodes, ReportErrorTypeNames } from '../../../../../const';
import { IconAlertCircleOutline, IconPersonDelete } from '../../../../../icons';
import ErrorReportTypeButton from '../TypeButton';

import './index.scss';

const errorTypes = [
  {
    icon: <IconAlertCircleOutline />,
    typeCode: ReportErrorTypeCodes.cardDataError,
    title: ReportErrorTypeNames.cardDataError,
  },
  {
    icon: <IconPersonDelete />,
    typeCode: ReportErrorTypeCodes.informationDoesNotApplyToMe,
    title: ReportErrorTypeNames.informationDoesNotApplyToMe,
  },
];

interface IErrorReportTypeProps {
  onChangeErrorType: (errorType: number) => void;
}

const ErrorReportType: React.FC<IErrorReportTypeProps> = ({ onChangeErrorType }) => {
  const handleChangeErrorType = (errorType: number) => {
    return () => {
      onChangeErrorType(errorType);
    };
  };

  return (
    <div className="error-report-type">
      <Typography variant="Paragraph LG/Semi Bold">Выберите тип ошибки</Typography>
      <ul className="error-report-type__list">
        {errorTypes.map((errorType) => {
          const { icon, title, typeCode } = errorType;

          return (
            <li key={typeCode}>
              <ErrorReportTypeButton icon={icon} onClick={handleChangeErrorType(typeCode)}>
                {title}
              </ErrorReportTypeButton>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ErrorReportType;
