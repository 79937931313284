import { SxProps } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';
import { CommonUiKitSize } from 'portfolio3/ui-kit/types';
import { getInputTokens } from 'portfolio3/ui-kit/utils';
import { numberToPixelsString } from 'utils';

export const input = (size: CommonUiKitSize): SxProps => {
  const { fontSize } = getInputTokens(size);

  return {
    flex: 1,
    minWidth: '100px',
    border: 0,
    outline: 0,
    padding: 0,
    background: 'transparent',

    fontSize: numberToPixelsString(fontSize),
  };
};

export const placeholder = (isVisible: boolean): SxProps => ({
  color: NeutralColors.light_neutrals_500,
  lineHeight: '100%',
  transition: 'opacity 0.1s',
  position: 'absolute',
  opacity: 0,
  pointerEvents: 'none',

  ...(isVisible && {
    opacity: 1,
  }),
});

export const renderValue = (size: CommonUiKitSize): SxProps => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: size === 'large' ? '6px' : '4px',
  width: '100%',
});

export const listbox: SxProps = {
  maxHeight: '360px',
  overflowY: 'auto',
  backgroundColor: '#ffffff',
  border: '1px solid',
  borderColor: NeutralColors.light_neutrals_300,
  borderRadius: '8px',
};
