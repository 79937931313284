/* eslint-disable prettier/prettier */
/* eslint-disable */

export enum GiaMarkKeys {
  PASSED = 'passed',
  NOT_PASSED = 'notPassed',
  PASSED_ABBREVIATION = 'passedAbbreviation',
  NOT_PASSED_ABBREVIATION = 'notPassedAbbreviation',
}

export const GiaMark: Record<GiaMarkKeys, string> = {
  [GiaMarkKeys.PASSED]: 'Зачет',
  [GiaMarkKeys.NOT_PASSED]: 'Незачет',
  [GiaMarkKeys.PASSED_ABBREVIATION]: 'Зач',
  [GiaMarkKeys.NOT_PASSED_ABBREVIATION]: 'Незач'
};

export enum GiaCategoryKeys {
  COMPULSORY = 'compulsory',
  OPTIONALLY = 'optionally',
  APPROBATION = 'approbation',
}

export const GiaCategory: Record<GiaCategoryKeys, string> = {
  [GiaCategoryKeys.COMPULSORY]: 'обязательный',
  [GiaCategoryKeys.OPTIONALLY]: 'по выбору',
  [GiaCategoryKeys.APPROBATION]: 'пробный',
};

export enum GiaTypeKeys {
    OGE = 'oge',
    GVE_11 = 'gve11',
    EGE = 'ege',
    GVE_9 = 'gve9',
    OGE_FULLNAME = 'ogeFullName',
    EGE_FULLNAME = 'egeFullName',
    GVE9_FULLNAME = 'gve9FullName',
    GVE11_FULLNAME = 'gve11FullName',
    OGE_SHORTNAME = 'ogeShortName',
    EGE_SHORTNAME = 'egeShortName',
    GVE9_SHORTNAME = 'gve9ShortName',
    GVE11_SHORTNAME = 'gve11ShortName',
    WRITING = 'writing',
    NARRATION = 'narration',
    INTERVIEW_RUS = 'interviewRus',
    OTHER = 'other'

}

export const GiaType: Record<GiaTypeKeys, string> = {
  [GiaTypeKeys.OGE]: 'ОГЭ',
  [GiaTypeKeys.GVE_11]: 'ГВЭ-11',
  [GiaTypeKeys.EGE]: 'ЕГЭ',
  [GiaTypeKeys.GVE_9]: 'ГВЭ-9',
  [GiaTypeKeys.OGE_FULLNAME]: 'Основной государственный экзамен (ОГЭ)',
  [GiaTypeKeys.EGE_FULLNAME]: 'Единый государственный экзамен (ЕГЭ)',
  [GiaTypeKeys.GVE9_FULLNAME]: 'Государственный выпускной экзамен (ГВЭ - 9)',
  [GiaTypeKeys.GVE11_FULLNAME]: 'Государственный выпускной экзамен (ГВЭ - 11)',
  [GiaTypeKeys.OGE_SHORTNAME]: 'Основной государственный экзамен',
  [GiaTypeKeys.EGE_SHORTNAME]: 'Единый государственный экзамен',
  [GiaTypeKeys.GVE9_SHORTNAME]: 'Государственный выпускной экзамен',
  [GiaTypeKeys.GVE11_SHORTNAME]: 'Государственный выпускной экзамен',
  [GiaTypeKeys.WRITING]: 'Итоговое сочинение',
  [GiaTypeKeys.NARRATION]: 'Итоговое изложение',
  [GiaTypeKeys.INTERVIEW_RUS]: 'Итоговое собеседование по русскому языку',
  [GiaTypeKeys.OTHER]: 'Другое'
};

export const CompulsoryGiaSubjects = [
  'Русский язык',
  'Математика',
  'Математика базовая',
  'Итоговое сочинение',
  'Итоговое изложение',
  'Итоговое собеседование по русскому языку'
];
