import {
  Children,
  cloneElement,
  isValidElement,
  MouseEvent,
  Ref,
  SyntheticEvent,
  useImperativeHandle,
  useState,
} from 'react';
import { MoonLoader } from 'react-spinners';

import { Box, menuItemClasses } from '@mui/material';
import { getAccentColor } from 'portfolio3/styles';

import { ISelectStateController } from '../../types';
import { isMenuItemElement } from '../../utils';
import SelectBase, { ISelectBase } from '../SelectBase';

export interface IModalSelectBaseProps<ModalProps> extends ISelectBase<ModalProps> {
  multiple?: boolean;
  stateController?: Ref<ISelectStateController>;
  loading?: boolean;
}

const ModalSelectBase = <ModalProps,>({
  controller,
  multiple,
  children,
  open,
  onOpen,
  onClose,
  stateController,
  loading,
  ...props
}: IModalSelectBaseProps<ModalProps>) => {
  const [isModalOpen, setModalOpen] = useState(open);
  const TypedSelectBase = SelectBase<ModalProps>;

  useImperativeHandle<ISelectStateController | undefined, ISelectStateController>(stateController, () => ({
    isOpen: isModalOpen ?? false,
    setOpen: (state) => setModalOpen(state),
  }));

  const handleOpen = (e: SyntheticEvent) => {
    setModalOpen(true);
    onOpen?.(e);
  };

  const handleClose = (e: SyntheticEvent) => {
    setModalOpen(false);
    onClose?.(e);
  };

  const handleClick = (e: MouseEvent<HTMLLIElement>) => {
    const target = e.currentTarget;
    if (target.classList.contains(menuItemClasses.root) && target.value) {
      const stringTarget = String(target.value);
      let newValue: string | undefined = '';

      if (multiple) {
        const currentValue = props.value ? String(props.value).split(',').filter(Boolean) : [];
        const tempValue = currentValue?.filter((value) => value !== stringTarget) || [];
        const isSameLength = currentValue && tempValue.length === currentValue.length;

        if (isSameLength || !currentValue) {
          tempValue.push(stringTarget);
        }

        newValue = tempValue.length > 0 ? tempValue.join(',') : undefined;
      } else {
        newValue = stringTarget.length > 0 ? stringTarget : undefined;
        handleClose(e);
      }

      if (String(newValue) === String(props.value)) return;

      controller.handleChange(newValue);
    }
  };

  return (
    <TypedSelectBase {...props} controller={controller} open={isModalOpen} onOpen={handleOpen} onClose={handleClose}>
      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginBlock: '16px' }}>
          <MoonLoader color={getAccentColor('blue', '100')} size={48} loading />
        </Box>
      )}

      {!loading &&
        Children.map(children, (child) => {
          if (!isValidElement(child)) return null;

          if (!isMenuItemElement(child)) return child;

          const values = String(props.value)?.split(',');
          const selected = multiple ? values?.includes(String(child.props.value)) : props.value == child.props.value;
          return cloneElement(child, {
            onClick: handleClick,
            selected,
          });
        })}
    </TypedSelectBase>
  );
};

export default ModalSelectBase;
