/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { Doughnut } from '@reactchartjs/react-chart.js';
import { Colors } from '../../../../style/variables';

import './index.scss';

interface IImportResultDoughnutProps {
  successCount: number,
  failCount: number
}

const ImportResultDoughnut: React.FC<IImportResultDoughnutProps> = ({ successCount, failCount }) => {
  const cutOutValue = 55;

  const singleData = {
    datasets: [
      {
        label: 'число оценок',
        data: [successCount, failCount],
        backgroundColor: [Colors['green-strong'], Colors['red-strong']],
        hoverBackgroundColor: [Colors['green-strong'], Colors['red-strong']],
        borderWidth: 1
      }
    ]
  };

  const options = {
    maintainAspectRatio: false,
    cutoutPercentage: cutOutValue,
    legend: {
      display: false
    },
    tooltips: {
      enabled: false
    },
    plugins: {
      datalabels: {
        display: false,
      }
    }
  };

  return (
    <div className="import-doughnut">
      <Doughnut data={singleData} options={options} type="doughnut" />
    </div>
  );
};

export default ImportResultDoughnut;
