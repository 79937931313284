import { SxStyles } from 'types';

export const root: SxStyles = {
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
};

export const noDataContainer: SxStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '16px',

  padding: '16px',
  paddingRight: '44px',

  backgroundColor: '#ffffff',
  borderRadius: '12px',
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1);',
};
