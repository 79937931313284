import { FC, ReactNode } from 'react';

import { Box, IconButton, ModalProps, Typography } from '@mui/material';
import clsx from 'clsx';
import { IconClose } from 'icons/edit';
import TrimText from 'portfolio3/components/common/TrimText';
import { CommonUiKitSize } from 'portfolio3/ui-kit/types';
import { SxStyles } from 'types';
import { mergeSx, numberToPixelsString, SafeOmit } from 'utils';

import ModalBase from '../ModalBase';
import * as styles from './styles';

const PopUpWidth: Record<CommonUiKitSize, number> = {
  small: 612,
  medium: 724,
  large: 918,
};
const PopUpMaxHeight: Record<CommonUiKitSize, number> = {
  small: 460,
  medium: 540,
  large: 680,
};

interface IPopUpProps extends SafeOmit<ModalProps, 'children'> {
  title: string;
  description?: ReactNode;
  onClose: () => void;
  // Статичный элемент, который не будет скроллится
  staticBodyElement?: ReactNode;
  body: ReactNode;
  size?: CommonUiKitSize;
  leftButton?: ReactNode;
  rightMainButton?: ReactNode;
  rightAdditionalButton?: ReactNode;
  bodySx?: SxStyles;
  fixedHeight?: boolean;
  onExited?: () => void;
}

const PopUp: FC<IPopUpProps> = ({
  title,
  description,
  onClose,
  body,
  size = 'medium',
  leftButton,
  rightMainButton,
  rightAdditionalButton,
  staticBodyElement,
  bodySx,
  fixedHeight,
  onExited,
  /* оригинальный sx перенаправляется в contentSx базового компонента */
  sx,
  ...props
}) => {
  const hasFooter = leftButton || rightMainButton || rightAdditionalButton;

  const width = numberToPixelsString(PopUpWidth[size]);
  const maxHeight = numberToPixelsString(PopUpMaxHeight[size]);

  return (
    <ModalBase
      {...props}
      className={clsx('pop-up', props.className)}
      onClose={onClose}
      contentSx={mergeSx(
        styles.root(Boolean(staticBodyElement)),
        { width, maxHeight, height: fixedHeight ? maxHeight : 'auto' },
        sx,
      )}
      onExited={onExited}
    >
      <>
        {/* header */}
        <Box className="pop-up__header" sx={styles.header}>
          {/* header title */}
          <Box sx={styles.headerTitle}>
            <Typography component={TrimText} variant="Headings/H6">
              {title}
            </Typography>
            <IconButton sx={styles.headerCloseButton} onClick={onClose}>
              <IconClose />
            </IconButton>
          </Box>
          {/* header description */}
          {description && (
            <Typography variant="Paragraph MD/Regular" sx={styles.headerDescription}>
              {description}
            </Typography>
          )}
        </Box>

        {/* body */}
        {staticBodyElement}
        <Box className="pop-up__body" sx={mergeSx(styles.body, bodySx)}>
          {body}
        </Box>

        {/* footer */}
        {hasFooter && (
          <Box className="pop-up__footer" sx={styles.footer}>
            {leftButton}
            {(rightMainButton || rightAdditionalButton) && (
              <Box sx={styles.footerRightGroup}>
                {rightAdditionalButton}
                {rightMainButton}
              </Box>
            )}
          </Box>
        )}
      </>
    </ModalBase>
  );
};

export default PopUp;
