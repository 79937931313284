import { forwardRef, ForwardRefRenderFunction, PropsWithChildren } from 'react';

import { Box, inputBaseClasses, styled, useFormControl } from '@mui/material';
import clsx from 'clsx';
import { skipPropsForwarding } from 'utils';

import { UiKitInputBaseProps } from '../types';
import { FakeInputBoxStyles } from './InputBase.styled';

const StyledFakeInputBox = styled(Box, {
  shouldForwardProp: skipPropsForwarding<UiKitInputBaseProps>(['renderMode']),
})<UiKitInputBaseProps>(FakeInputBoxStyles);

const FakeInputBox: ForwardRefRenderFunction<HTMLDivElement, PropsWithChildren<UiKitInputBaseProps>> = (props, ref) => {
  // disabled, focused, error
  const formControlState = useFormControl();

  const classes = clsx({
    [inputBaseClasses.disabled]: formControlState?.disabled || props.disabled,
    [inputBaseClasses.focused]: formControlState?.focused,
    [inputBaseClasses.error]: formControlState?.error,
  });

  // Для передачи атрибутов инпута в див пришлось отключить проверку, но ничего лишнего не должно пройти
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputProps = props as any;
  return <StyledFakeInputBox {...inputProps} className={clsx('fake-input-box', props.className, classes)} ref={ref} />;
};

export default forwardRef(FakeInputBox);
