import { typographyClasses } from '@mui/material';
import { getAccentColor, NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const header: SxStyles = {
  display: 'flex',
  justifyContent: 'space-between',

  height: '52px',
  padding: '12px 20px',
  backgroundColor: getAccentColor('indigo', '80'),

  transition: 'background-color 0.1s',

  [`.${typographyClasses.root}`]: {
    color: NeutralColors.light_neutrals_0,
  },
};

export const headerVisible: SxStyles = {
  backgroundColor: getAccentColor('indigo', '200'),
};

export const headerLeft: SxStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
  color: NeutralColors.light_neutrals_0,
};

export const headerRight: SxStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
};

export const button: SxStyles = {
  height: '20px',

  svg: {
    width: '20px',
    height: '20px',
    color: NeutralColors.light_neutrals_0,
  },
};
