import { FC, PropsWithChildren } from 'react';

import { Stack, Typography, TypographyProps } from '@mui/material';
import { SxStyles } from 'types';
import { mergeSx } from 'utils';

import * as styles from './styles';

interface IPanelSectionProps {
  title: string;
  titleVariant: TypographyProps['variant'];
  sx?: SxStyles;
}

const PanelSection: FC<PropsWithChildren<IPanelSectionProps>> = ({ title, titleVariant, sx, children }) => {
  return (
    <Stack className="panel-section" gap="12px" sx={mergeSx(styles.root, sx)}>
      <Typography variant={titleVariant}>{title}</Typography>
      {children}
    </Stack>
  );
};

export default PanelSection;
