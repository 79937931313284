import { FC } from 'react';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import clsx from 'clsx';
import ResultDoughnut from 'components/common/ResultDoughnut';
import { IconArrowRight } from 'icons';
import { ISubjectTheme } from 'portfolio3/features/performance';
import { Tooltip } from 'ui-kit';
import { getDoughnutColor, getDoughnutData } from 'utils';

import './index.scss';

interface ISubjectThemeListProps {
  themes: ISubjectTheme[];
}

const SubjectThemeList: FC<ISubjectThemeListProps> = ({ themes }) => {
  return (
    <TableContainer className="subject-theme-list">
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className="cell-expand">Тема</TableCell>
            <TableCell>Уроков</TableCell>
            <TableCell align="center">Ср. балл</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {themes.map(({ name, passedLessons, totalLessons, averageGrade }) => (
            <TableRow key={name} className="row">
              {/* topic */}
              <Tooltip title={name} arrow placement="top" width="184px">
                <TableCell className="cell cell-expand cell-topic">{name}</TableCell>
              </Tooltip>
              {/* lessons */}
              <TableCell className="cell cell-lessons">
                <div className="cell-lessons__chart">
                  <ResultDoughnut
                    borderThickness={70}
                    total={null}
                    data={getDoughnutData(passedLessons, totalLessons)}
                    color={getDoughnutColor(passedLessons, totalLessons)}
                  />
                </div>
                <span className={clsx({ 'cell-lessons--grayed': passedLessons === 0 })}>
                  {passedLessons} из {totalLessons}
                </span>
              </TableCell>

              {/* average result */}
              <TableCell className="cell cell-result" align="center">
                {averageGrade && averageGrade.toString().replace('.', ',')}
              </TableCell>

              <div className="row__arrow">
                <IconArrowRight />
              </div>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SubjectThemeList;
