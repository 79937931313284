import { forwardRef } from 'react';

import { Button, ButtonProps, styled, svgIconClasses } from '@mui/material';

import { buttonStyles } from '../utils';

const StyledButtonMore = styled(Button)(() => {
  return {
    ...buttonStyles,
  };
});

const ButtonMore = forwardRef<HTMLButtonElement, ButtonProps>(({ ...props }, ref) => {
  return (
    <StyledButtonMore
      sx={{
        gap: '4px',

        [`& .${svgIconClasses.root}`]: {
          width: 20,
          height: 20,
        },
      }}
      {...props}
      ref={ref}
    />
  );
});

ButtonMore.displayName = 'ButtonMore';

export default ButtonMore;
