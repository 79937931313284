/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import ResultDoughnut from '../../../common/ResultDoughnut';
import { getDoughnutData, getDoughnutGradeColor } from '../../../../utils';

import './index.scss';

interface IAverageResultTileProps {
  doughuntValue: number;
}

const AverageResultTile: React.FC<IAverageResultTileProps> = ({
  doughuntValue
}) => {
  return (
    <div className="average-result-tile tile">
      <ResultDoughnut
        total={doughuntValue}
        data={getDoughnutData(doughuntValue, 5)}
        color={getDoughnutGradeColor(doughuntValue)}
      />
      <div className="tile__text-content">
        <h5 className="average-result-tile__title">Средний балл за год</h5>
        <p className="average-result-tile__description">по всем предметам</p>
      </div>
    </div>
  );
};

export default AverageResultTile;
