import { FC, PropsWithChildren } from 'react';

import { Dialog } from '@mui/material';

import { IconClose } from '../../../../icons';

interface IFeaturePreviewDesktopContainerProps {
  isOpen: boolean;
  onClose: () => void;
}

const FeaturePreviewDesktopContainer: FC<IFeaturePreviewDesktopContainerProps & PropsWithChildren> = ({
  isOpen,
  onClose,
  children,
}) => (
  <Dialog open={isOpen} className="feature-modal feature-modal--desktop">
    <IconClose className="feature-modal__close" onClick={onClose} />
    {children}
  </Dialog>
);

export default FeaturePreviewDesktopContainer;
