/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useEffect, useState } from 'react';

import {
  MenuItem, tooltipClasses
} from '@mui/material';
import clsx from 'clsx';
import { debounce } from 'lodash';

import { IDictionaryItem } from '../../../../api/types';
import CommonSelect from '../CommonSelect';
import { ISingleDictionarySelectController } from './types';
import { DifferentValue } from '../../../../const';
import { Tooltip } from '../../../../ui-kit';
import { detectTouchDevice } from '../../../../utils';

import './index.scss';

interface ISingleDictionarySelectProps {
  placeholder: string,
  selectController?: ISingleDictionarySelectController,
  options: IDictionaryItem[],
  optionsClass?: string,
  error?: boolean,
  value?: number | string | number[],
  isFirstOptionMarked?: boolean;
}

const SingleDictionarySelect: React.FC<ISingleDictionarySelectProps> = ({
  error,
  placeholder,
  selectController,
  optionsClass,
  value,
  options,
  isFirstOptionMarked
}) => {
  const getValue = () => {
    if (!value) return 'placeholder';
    return value;
  };

  const [shownOptions, setShownOptions] = useState(options ?? []);
  const hasDifferentValue = options?.findIndex((option) => option?.value?.trim() === DifferentValue) >= 0;

  const [tooltipIndex, setTooltipIndex] = useState<number | undefined>(undefined);
  const debounceShowTooltip = debounce((index) => setTooltipIndex(index), 350);

  const isTouchDevice = detectTouchDevice();

  const handleOptionMouseEnter = (index: number) => {
    if (isTouchDevice) return undefined;

    return (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      const target = e.target as HTMLLIElement | undefined;

      if (target?.offsetWidth !== target?.scrollWidth) {
        debounceShowTooltip(index);
      }
    };
  };

  const handleOptionMouseLeave = () => {
    setTooltipIndex(undefined);
    debounceShowTooltip.cancel();
  };

  useEffect(() => {
    if (hasDifferentValue) {
      setShownOptions((prevstate) => {
        const differentValueIndex = prevstate.findIndex((option) => option?.value?.trim() === DifferentValue);
        const newstate = [...prevstate];
        const item = newstate.splice(differentValueIndex, 1)[0];
        newstate.splice(shownOptions.length, 1, item);

        return newstate;
      });
    } else {
      setShownOptions(options);
    }
  }, [options]);

  return (
    <CommonSelect
      placeholder={placeholder}
      value={getValue()}
      controller={selectController}
      options={shownOptions}
      error={error}
      renderValue={(selectedValues: any) => {
        if (selectedValues === 'placeholder') return <span>{placeholder}</span>;
        return options?.find((currentValue) => currentValue.code === value)?.value;
      }}
    >
      {shownOptions?.map((currentValue, index) => (
        <MenuItem
          value={currentValue?.code}
          key={currentValue?.code}
          className={
            clsx('select__item--common', optionsClass,
              { 'select__item--marked': isFirstOptionMarked && index === 0 },
              { 'select__item--divided': hasDifferentValue && index === shownOptions.length - 1 })
          }
          onMouseOver={handleOptionMouseEnter(index)}
          onMouseOut={handleOptionMouseLeave}
          onPointerDown={() => setTooltipIndex(undefined)}
        >
          <Tooltip
            open={tooltipIndex === index}
            title={currentValue?.value}
            placement="left"
            width="184px"
            arrow
            sx={{
              [`& .${tooltipClasses.tooltip}`]: {
                marginRight: '36px'
              },
            }}
          >
            <div className="select__item-value">
              {currentValue?.value}
            </div>
          </Tooltip>
        </MenuItem>
      ))}
    </CommonSelect>
  );
};

export default SingleDictionarySelect;
