import { SxStyles } from 'types';

export const header: SxStyles = {
  padding: '24px',
  paddingBottom: 0,
};

export const headerTitle: SxStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  gap: '12px',
};

export const inlinePadding: SxStyles = {
  paddingInline: '8px',
};
