/* eslint-disable prettier/prettier */
/* eslint-disable */

import connectReducers from '../utils/connectReducers';

import {
  GET_LINK_SETTINGS
} from '../actions';

import {
  FAILURE,
  REQUEST,
  SUCCESS
} from '../actions/utils';

import { ILinkVisibilitySettings } from '../api/types';

import genericReducers from './genericReducer';

import { IReducers } from '.';

export type LinkVisibilitySettingsState = ILinkVisibilitySettings;

const initialState = {} as ILinkVisibilitySettings;

const linkVisibilitySettings: IReducers<LinkVisibilitySettingsState> = {
  [GET_LINK_SETTINGS[REQUEST]]: genericReducers().get.request,
  [GET_LINK_SETTINGS[SUCCESS]]: genericReducers().get.success,
  [GET_LINK_SETTINGS[FAILURE]]: genericReducers().get.failure
};

export default connectReducers(initialState, linkVisibilitySettings);
