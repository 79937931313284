import { FC, ReactNode } from 'react';

import { Box, Typography } from '@mui/material';

import * as styles from './styles';

interface ISearchResultStudentProps {
  name: string;
  parallel: string;
  rightElement: ReactNode;
}

const SearchResultStudent: FC<ISearchResultStudentProps> = ({ name, parallel, rightElement }) => {
  return (
    <Box className="search-result-student" sx={styles.root}>
      <Box sx={styles.leftContainer}>
        <Typography variant="Paragraph MD/Semi Bold">{name}</Typography>
        <Typography variant="Paragraph MD/Regular">{parallel}</Typography>
      </Box>
      {rightElement}
    </Box>
  );
};

export default SearchResultStudent;
