import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { IconChevronSmallDown } from 'icons';
import { Button } from 'portfolio3/ui-kit/button';

import * as styles from './styles';

interface IMoreElementsButtonProps {
  elementsCount: number;
  isMobile: boolean;
  onClick: () => void;
}

const MoreElementsButton: FC<IMoreElementsButtonProps> = ({ elementsCount: nextElementsCount, isMobile, onClick }) => {
  if (isMobile) {
    return (
      <Button variant="tetriary" onClick={onClick} sx={{ width: '100%' }}>
        Показать еще
      </Button>
    );
  }

  return (
    <Box component="button" sx={styles.desktopRoot} onClick={onClick}>
      <Typography variant="Paragraph MD/Medium" sx={styles.desktopText}>
        Показать еще {nextElementsCount}
      </Typography>
      <IconChevronSmallDown sx={styles.desktopIcon} />
    </Box>
  );
};

export default MoreElementsButton;
