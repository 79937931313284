/* eslint-disable @typescript-eslint/no-explicit-any */
import { action, createAction, createRequestTypes, FAILURE, INVALIDATE, REQUEST, SUCCESS } from 'actions/utils';
import { MetaObject } from 'api/types';

export const GET_STUDENT_PERFORMANCE_OLD = createRequestTypes('GET_STUDENT_PERFORMANCE_OLD');
export const getStudentPerformanceOldActions = {
  request: (personId: string) => action(GET_STUDENT_PERFORMANCE_OLD[REQUEST], { payload: { personId } }),
  success: (response: any) => action(GET_STUDENT_PERFORMANCE_OLD[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_STUDENT_PERFORMANCE_OLD[FAILURE], { payload: error }),
  invalidate: () => createAction(GET_STUDENT_PERFORMANCE_OLD[INVALIDATE], null),
};

export const GET_STUDENT_PERFORMANCE_LESSONS_OLD = createRequestTypes('GET_STUDENT_PERFORMANCE_LESSONS_OLD');
export const getStudentPerformanceLessonsOldActions = {
  request: (personId: string, subjectName: string, meta?: MetaObject) =>
    action(GET_STUDENT_PERFORMANCE_LESSONS_OLD[REQUEST], { payload: { personId, subjectName, meta } }),
  success: (response: any) => action(GET_STUDENT_PERFORMANCE_LESSONS_OLD[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_STUDENT_PERFORMANCE_LESSONS_OLD[FAILURE], { payload: error }),
  invalidate: () => createAction(GET_STUDENT_PERFORMANCE_LESSONS_OLD[INVALIDATE], null),
};

export const collection = {
  getStudentPerformanceOldActions,
  getStudentPerformanceLessonsOldActions,
};
