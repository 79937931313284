import React, { useState } from 'react';

import { Box } from '@mui/material';
import { FileDrop, IFile, IFileController } from 'portfolio3/features/dataEntryForm';
import { BaseInputLabel, FormControl } from 'portfolio3/ui-kit';

import FileListItem from '../../../../../FileListItem';

export interface IFilesTabProps {
  personId: string;
  controller: IFileController;
  files: IFile[];
  accept?: string;
  readableFileTypes?: string[];
  label?: string;
  required?: boolean;
  maxFiles?: number;
}

const FilesTab: React.FC<IFilesTabProps> = ({
  personId,
  controller,
  files,
  accept,
  readableFileTypes,
  label,
  required,
  maxFiles,
}) => {
  const [isFileSubstrateVisible, setFileSubstrateVisible] = useState(false);
  const filteredFiles = files?.filter((file) => !file.response.isDelete);

  const activeFilesCount = filteredFiles.length;
  const isFileDropVisible = maxFiles === undefined || activeFilesCount < maxFiles;

  const hasFiles = activeFilesCount > 0;
  return (
    <>
      {isFileDropVisible && (
        <FormControl
          required={required}
          renderMode="fixed"
          label={label && <BaseInputLabel>{label}</BaseInputLabel>}
          control={
            <FileDrop
              personId={personId}
              fileController={controller}
              documentClass="StudentAchievement"
              setFileSubstrateVisible={setFileSubstrateVisible}
              accept={accept}
              readableFileTypes={readableFileTypes}
            />
          }
        />
      )}
      {hasFiles && (
        <Box sx={{ marginTop: '16px' }}>
          {filteredFiles.map((file) => (
            <FileListItem key={Math.random()} file={file} fileController={controller} />
          ))}
        </Box>
      )}

      {isFileSubstrateVisible && <div className="secondary-block__file-substrate" />}
    </>
  );
};

export default FilesTab;
