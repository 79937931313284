/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';

const Cat: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M27.9619 24.0651V4.42178C27.9619 2.98428 26.626 2.28116 25.5322 3.29678L21.5186 7.24901C21.3315 7.43322 21.0795 7.53647 20.817 7.53647H11.2206C10.9581 7.53647 10.7061 7.43322 10.519 7.24901L6.50537 3.29678C5.41162 2.28116 4.07568 2.98428 4.07568 4.42178V24.0651C4.07568 26.2769 5.83562 28.0702 8.00621 28.0702H24.0314C26.202 28.0702 27.9619 26.2769 27.9619 24.0651Z"
      fill="url(#paint0_linear_311_287425)"
    />
    <path
      d="M27.9619 24.0651V4.42178C27.9619 2.98428 26.626 2.28116 25.5322 3.29678L21.5186 7.24901C21.3315 7.43322 21.0795 7.53647 20.817 7.53647H11.2206C10.9581 7.53647 10.7061 7.43322 10.519 7.24901L6.50537 3.29678C5.41162 2.28116 4.07568 2.98428 4.07568 4.42178V24.0651C4.07568 26.2769 5.83562 28.0702 8.00621 28.0702H24.0314C26.202 28.0702 27.9619 26.2769 27.9619 24.0651Z"
      fill="url(#paint1_linear_311_287425)"
    />
    <path
      d="M27.9619 24.0651V4.42178C27.9619 2.98428 26.626 2.28116 25.5322 3.29678L21.5186 7.24901C21.3315 7.43322 21.0795 7.53647 20.817 7.53647H11.2206C10.9581 7.53647 10.7061 7.43322 10.519 7.24901L6.50537 3.29678C5.41162 2.28116 4.07568 2.98428 4.07568 4.42178V24.0651C4.07568 26.2769 5.83562 28.0702 8.00621 28.0702H24.0314C26.202 28.0702 27.9619 26.2769 27.9619 24.0651Z"
      fill="url(#paint2_linear_311_287425)"
    />
    <path
      d="M27.9619 24.0651V4.42178C27.9619 2.98428 26.626 2.28116 25.5322 3.29678L21.5186 7.24901C21.3315 7.43322 21.0795 7.53647 20.817 7.53647H11.2206C10.9581 7.53647 10.7061 7.43322 10.519 7.24901L6.50537 3.29678C5.41162 2.28116 4.07568 2.98428 4.07568 4.42178V24.0651C4.07568 26.2769 5.83562 28.0702 8.00621 28.0702H24.0314C26.202 28.0702 27.9619 26.2769 27.9619 24.0651Z"
      fill="url(#paint3_linear_311_287425)"
    />
    <path
      d="M27.9619 24.0651V4.42178C27.9619 2.98428 26.626 2.28116 25.5322 3.29678L21.5186 7.24901C21.3315 7.43322 21.0795 7.53647 20.817 7.53647H11.2206C10.9581 7.53647 10.7061 7.43322 10.519 7.24901L6.50537 3.29678C5.41162 2.28116 4.07568 2.98428 4.07568 4.42178V24.0651C4.07568 26.2769 5.83562 28.0702 8.00621 28.0702H24.0314C26.202 28.0702 27.9619 26.2769 27.9619 24.0651Z"
      fill="url(#paint4_linear_311_287425)"
    />
    <path
      d="M27.9619 24.0651V4.42178C27.9619 2.98428 26.626 2.28116 25.5322 3.29678L21.5186 7.24901C21.3315 7.43322 21.0795 7.53647 20.817 7.53647H11.2206C10.9581 7.53647 10.7061 7.43322 10.519 7.24901L6.50537 3.29678C5.41162 2.28116 4.07568 2.98428 4.07568 4.42178V24.0651C4.07568 26.2769 5.83562 28.0702 8.00621 28.0702H24.0314C26.202 28.0702 27.9619 26.2769 27.9619 24.0651Z"
      fill="url(#paint5_radial_311_287425)"
    />
    <g filter="url(#filter0_f_311_287425)">
      <rect x="5.0542" y="19.8203" width="2.72692" height="3.69801" rx="1.36346" fill="#D67908" />
    </g>
    <g filter="url(#filter1_f_311_287425)">
      <path
        d="M20.7509 8.20041C17.7677 8.30795 25.9774 13.1542 25.9774 13.1542L27.1024 4.0917C27.1571 3.63077 26.7383 3.13819 25.8525 3.96072C25.8525 3.96072 22.0259 7.72873 21.8388 7.91295C21.6518 8.09716 21.4308 8.16187 21.1372 8.20041C20.9876 8.22004 20.9017 8.19497 20.7509 8.20041Z"
        fill="url(#paint6_linear_311_287425)"
      />
    </g>
    <path
      d="M6.13268 11.503L8.93268 8.90295C9.25268 8.60295 9.25268 8.12295 8.93268 7.83295L5.91799 5.15577C5.53518 4.81983 4.74268 4.92921 4.74268 5.76295V10.963C4.74268 11.7964 5.62268 11.983 6.13268 11.503Z"
      fill="url(#paint7_linear_311_287425)"
    />
    <g filter="url(#filter2_f_311_287425)">
      <path
        d="M26.491 3.65184C26.549 3.50816 26.6884 3.41406 26.8433 3.41406C27.0531 3.41406 27.2231 3.58411 27.2231 3.79388V6.03906L25.8057 5.35156L26.491 3.65184Z"
        fill="url(#paint8_linear_311_287425)"
      />
    </g>
    <g filter="url(#filter3_f_311_287425)">
      <path
        d="M5.44629 3.72656L8.82125 6.9461C9.57536 7.66548 9.58949 8.86461 8.85254 9.60156V9.60156"
        stroke="#FFDF70"
        strokeLinecap="round"
      />
    </g>
    <g filter="url(#filter4_f_311_287425)">
      <path
        d="M14.8053 18.6499L14.8696 18.6349C15.3432 18.5245 15.8364 18.5296 16.3076 18.6499C16.8287 18.748 17.1398 19.3454 16.6998 19.7754L16.149 20.377C15.879 20.647 15.2862 20.647 15.0162 20.377L14.4732 19.7754C14.024 19.2676 14.3053 18.7598 14.8053 18.6499Z"
        fill="url(#paint9_linear_311_287425)"
      />
    </g>
    <path
      d="M15.2266 18.2066L15.291 18.1916C15.7646 18.0811 16.2578 18.0862 16.729 18.2066C17.2501 18.3047 17.5612 18.902 17.1212 19.332L16.5704 19.9336C16.3004 20.2036 15.7076 20.2036 15.4376 19.9336L14.8946 19.332C14.4454 18.8242 14.7266 18.3164 15.2266 18.2066Z"
      fill="url(#paint10_radial_311_287425)"
    />
    <g filter="url(#filter5_f_311_287425)">
      <ellipse cx="16.2705" cy="18.7411" rx="0.761719" ry="0.274258" fill="url(#paint11_linear_311_287425)" />
    </g>
    <g filter="url(#filter6_f_311_287425)">
      <path
        d="M24.7852 20.8926L28.5898 19.0801"
        stroke="url(#paint12_linear_311_287425)"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </g>
    <g filter="url(#filter7_f_311_287425)">
      <path
        d="M24.7852 22.9235L26.7118 24.2891"
        stroke="url(#paint13_linear_311_287425)"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </g>
    <path
      d="M25.5664 20.5391L29.3711 18.7266"
      stroke="url(#paint14_linear_311_287425)"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <g filter="url(#filter8_f_311_287425)">
      <path
        d="M25.5664 20.7109L29.3711 18.8984"
        stroke="#FF8485"
        strokeWidth="0.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </g>
    <g filter="url(#filter9_f_311_287425)">
      <ellipse
        cx="29.6057"
        cy="18.5809"
        rx="0.18384"
        ry="0.165827"
        transform="rotate(-22.7455 29.6057 18.5809)"
        fill="#FFDD86"
      />
    </g>
    <path
      d="M25.5637 22.6689L29.3653 24.4879"
      stroke="url(#paint15_linear_311_287425)"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <g filter="url(#filter10_f_311_287425)">
      <path
        d="M25.4983 22.5487L29.2998 24.3678"
        stroke="#FF8485"
        strokeWidth="0.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </g>
    <g filter="url(#filter11_f_311_287425)">
      <ellipse
        cx="29.5403"
        cy="24.3679"
        rx="0.18384"
        ry="0.165827"
        transform="rotate(28.2981 29.5403 24.3679)"
        fill="#FFDD86"
      />
    </g>
    <path
      d="M6.5281 22.6806L2.72654 24.4996"
      stroke="url(#paint16_linear_311_287425)"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <g filter="url(#filter12_f_311_287425)">
      <path
        d="M6.59353 22.5605L2.79197 24.3795"
        stroke="#FF8485"
        strokeWidth="0.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </g>
    <g filter="url(#filter13_f_311_287425)">
      <ellipse
        rx="0.18384"
        ry="0.165827"
        transform="matrix(-0.942776 -0.333427 -0.333427 0.942776 6.64639 22.5052)"
        fill="#FFDD86"
      />
    </g>
    <path
      d="M6.53969 20.5244L2.72612 18.7307"
      stroke="url(#paint17_linear_311_287425)"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <g filter="url(#filter14_f_311_287425)">
      <path
        d="M6.67397 20.499L2.8604 18.7053"
        stroke="#FF8485"
        strokeWidth="0.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </g>
    <g filter="url(#filter15_f_311_287425)">
      <ellipse
        rx="0.18384"
        ry="0.165827"
        transform="matrix(-0.338111 -0.941106 -0.941106 0.338111 6.75053 20.5041)"
        fill="#FFDD86"
      />
    </g>
    <path
      d="M20.418 21.0547C20.418 23.4947 18.4361 25.3906 15.9961 25.3906C13.5561 25.3906 11.6836 23.4478 11.6836 21.0078C11.6836 21.0078 13.4883 23.1406 15.9961 21.0078C18.3477 23.0859 20.418 21.0547 20.418 21.0547Z"
      fill="url(#paint18_linear_311_287425)"
    />
    <g filter="url(#filter16_f_311_287425)">
      <path
        d="M19.4316 18.468C19.0064 18.468 18.6562 18.1249 18.6562 17.7083V16.287C18.6562 15.8704 19.0064 15.5273 19.4316 15.5273C19.8567 15.5273 20.2069 15.8704 20.2069 16.287V17.7083C20.2069 18.1371 19.8567 18.468 19.4316 18.468Z"
        fill="url(#paint19_linear_311_287425)"
      />
    </g>
    <path
      d="M19.9706 18.0861C19.5455 18.0861 19.1953 17.7431 19.1953 17.3265V15.9052C19.1953 15.4886 19.5455 15.1455 19.9706 15.1455C20.3958 15.1455 20.7459 15.4886 20.7459 15.9052V17.3265C20.7459 17.7553 20.3958 18.0861 19.9706 18.0861Z"
      fill="url(#paint20_linear_311_287425)"
    />
    <path
      d="M19.9706 18.0861C19.5455 18.0861 19.1953 17.7431 19.1953 17.3265V15.9052C19.1953 15.4886 19.5455 15.1455 19.9706 15.1455C20.3958 15.1455 20.7459 15.4886 20.7459 15.9052V17.3265C20.7459 17.7553 20.3958 18.0861 19.9706 18.0861Z"
      fill="url(#paint21_linear_311_287425)"
    />
    <g filter="url(#filter17_f_311_287425)">
      <path
        d="M11.6054 18.468C11.1802 18.468 10.8301 18.1249 10.8301 17.7083V16.287C10.8301 15.8704 11.1802 15.5273 11.6054 15.5273C12.0306 15.5273 12.3807 15.8704 12.3807 16.287V17.7083C12.3807 18.1371 12.0306 18.468 11.6054 18.468Z"
        fill="url(#paint22_linear_311_287425)"
      />
    </g>
    <path
      d="M12.0292 18.0861C11.604 18.0861 11.2539 17.7431 11.2539 17.3265V15.9052C11.2539 15.4886 11.604 15.1455 12.0292 15.1455C12.4544 15.1455 12.8045 15.4886 12.8045 15.9052V17.3265C12.8045 17.7553 12.4544 18.0861 12.0292 18.0861Z"
      fill="url(#paint23_linear_311_287425)"
    />
    <path
      d="M12.0292 18.0861C11.604 18.0861 11.2539 17.7431 11.2539 17.3265V15.9052C11.2539 15.4886 11.604 15.1455 12.0292 15.1455C12.4544 15.1455 12.8045 15.4886 12.8045 15.9052V17.3265C12.8045 17.7553 12.4544 18.0861 12.0292 18.0861Z"
      fill="url(#paint24_linear_311_287425)"
    />
    <g filter="url(#filter18_f_311_287425)">
      <circle cx="20.2069" cy="15.7636" r="0.236228" fill="#775B70" />
    </g>
    <g filter="url(#filter19_f_311_287425)">
      <circle cx="12.265" cy="15.7636" r="0.236228" fill="#775B70" />
    </g>
    <g filter="url(#filter20_f_311_287425)">
      <rect x="22.1025" y="8.10156" width="4.5851" height="14.1857" fill="url(#paint25_linear_311_287425)" />
    </g>
    <path
      d="M25.8331 11.503L23.0331 8.90295C22.7131 8.60295 22.7131 8.12295 23.0331 7.83295L26.0478 5.15577C26.4306 4.81983 27.2231 4.92921 27.2231 5.76295V10.963C27.2231 11.7964 26.3431 11.983 25.8331 11.503Z"
      fill="#FFB915"
    />
    <path
      d="M25.8331 11.503L23.0331 8.90295C22.7131 8.60295 22.7131 8.12295 23.0331 7.83295L26.0478 5.15577C26.4306 4.81983 27.2231 4.92921 27.2231 5.76295V10.963C27.2231 11.7964 26.3431 11.983 25.8331 11.503Z"
      fill="url(#paint26_linear_311_287425)"
    />
    <g filter="url(#filter21_f_311_287425)">
      <path
        d="M26.6338 5.36719L23.0088 8.49219L26.5557 11.3047L26.6338 5.36719Z"
        fill="url(#paint27_radial_311_287425)"
      />
    </g>
    <g filter="url(#filter22_f_311_287425)">
      <path
        d="M23.7275 8.28906L26.2588 11.0234"
        stroke="url(#paint28_linear_311_287425)"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
    </g>
    <g filter="url(#filter23_f_311_287425)">
      <path d="M5.22314 5.49902V11.4067L8.69189 8.31738L5.22314 5.49902Z" fill="#FEB33E" />
    </g>
    <g filter="url(#filter24_f_311_287425)">
      <path d="M5.22314 5.72656V10.9141" stroke="#FFE7A3" strokeWidth="0.15" strokeLinecap="round" />
    </g>
    <defs>
      <filter
        id="filter0_f_311_287425"
        x="3.5542"
        y="18.3203"
        width="5.72705"
        height="6.69824"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.75" result="effect1_foregroundBlur_311_287425" />
      </filter>
      <filter
        id="filter1_f_311_287425"
        x="19.0981"
        y="2.51855"
        width="9.00879"
        height="11.6357"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_311_287425" />
      </filter>
      <filter
        id="filter2_f_311_287425"
        x="24.8057"
        y="2.41406"
        width="3.41748"
        height="4.625"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_311_287425" />
      </filter>
      <filter
        id="filter3_f_311_287425"
        x="2.94629"
        y="1.22656"
        width="8.94971"
        height="10.7285"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_311_287425" />
      </filter>
      <filter
        id="filter4_f_311_287425"
        x="13.748"
        y="18.0557"
        width="3.66162"
        height="3.02344"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_311_287425" />
      </filter>
      <filter
        id="filter5_f_311_287425"
        x="14.9088"
        y="17.8668"
        width="2.72344"
        height="1.74883"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.3" result="effect1_foregroundBlur_311_287425" />
      </filter>
      <filter
        id="filter6_f_311_287425"
        x="23.6851"
        y="17.9795"
        width="6.00488"
        height="4.01367"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_311_287425" />
      </filter>
      <filter
        id="filter7_f_311_287425"
        x="23.6851"
        y="21.8232"
        width="4.12695"
        height="3.56543"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_311_287425" />
      </filter>
      <filter
        id="filter8_f_311_287425"
        x="24.9165"
        y="18.248"
        width="5.10449"
        height="3.11328"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_311_287425" />
      </filter>
      <filter
        id="filter9_f_311_287425"
        x="28.9243"
        y="17.9121"
        width="1.36279"
        height="1.33789"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_311_287425" />
      </filter>
      <filter
        id="filter10_f_311_287425"
        x="24.8481"
        y="21.8984"
        width="5.10156"
        height="3.11914"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_311_287425" />
      </filter>
      <filter
        id="filter11_f_311_287425"
        x="28.8604"
        y="23.6982"
        width="1.35986"
        height="1.33984"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_311_287425" />
      </filter>
      <filter
        id="filter12_f_311_287425"
        x="2.14209"
        y="21.9102"
        width="5.10156"
        height="3.11914"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_311_287425" />
      </filter>
      <filter
        id="filter13_f_311_287425"
        x="5.96436"
        y="21.8369"
        width="1.36377"
        height="1.33594"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_311_287425" />
      </filter>
      <filter
        id="filter14_f_311_287425"
        x="2.21045"
        y="18.0557"
        width="5.11377"
        height="3.09375"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_311_287425" />
      </filter>
      <filter
        id="filter15_f_311_287425"
        x="6.08252"
        y="19.8223"
        width="1.33594"
        height="1.36426"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_311_287425" />
      </filter>
      <filter
        id="filter16_f_311_287425"
        x="18.1562"
        y="15.0273"
        width="2.55078"
        height="3.94043"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_311_287425" />
      </filter>
      <filter
        id="filter17_f_311_287425"
        x="10.3301"
        y="15.0273"
        width="2.55078"
        height="3.94043"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_311_287425" />
      </filter>
      <filter
        id="filter18_f_311_287425"
        x="19.4707"
        y="15.0273"
        width="1.47266"
        height="1.47266"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_311_287425" />
      </filter>
      <filter
        id="filter19_f_311_287425"
        x="11.5288"
        y="15.0273"
        width="1.47266"
        height="1.47266"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_311_287425" />
      </filter>
      <filter
        id="filter20_f_311_287425"
        x="18.1025"
        y="4.10156"
        width="12.585"
        height="22.1855"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_311_287425" />
      </filter>
      <filter
        id="filter21_f_311_287425"
        x="22.4088"
        y="4.76719"
        width="4.825"
        height="7.1375"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.3" result="effect1_foregroundBlur_311_287425" />
      </filter>
      <filter
        id="filter22_f_311_287425"
        x="22.7275"
        y="7.28906"
        width="4.53125"
        height="4.73438"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.375" result="effect1_foregroundBlur_311_287425" />
      </filter>
      <filter
        id="filter23_f_311_287425"
        x="4.72314"
        y="4.99902"
        width="4.46875"
        height="6.90723"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_311_287425" />
      </filter>
      <filter
        id="filter24_f_311_287425"
        x="4.64795"
        y="5.15137"
        width="1.15039"
        height="6.33789"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_311_287425" />
      </filter>
      <linearGradient
        id="paint0_linear_311_287425"
        x1="7.96385"
        y1="-0.869758"
        x2="7.96385"
        y2="29.0702"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.288159" stopColor="#F2CC26" />
        <stop offset="0.762024" stopColor="#E99E20" />
        <stop offset="0.9925" stopColor="#E3821D" />
        <stop offset="1" stopColor="#E3801D" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_311_287425"
        x1="8.8838"
        y1="9.92151"
        x2="1.2588"
        y2="9.92151"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E99E20" stopOpacity="0" />
        <stop offset="0.9925" stopColor="#E3821D" />
        <stop offset="1" stopColor="#E3801D" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_311_287425"
        x1="26.7588"
        y1="9.9215"
        x2="30.1963"
        y2="9.9215"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E99E20" stopOpacity="0" />
        <stop offset="0.9925" stopColor="#E3821D" />
        <stop offset="1" stopColor="#E3801D" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_311_287425"
        x1="16.0198"
        y1="-3.8439"
        x2="16.0188"
        y2="29.0702"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.77079" stopColor="#F59639" stopOpacity="0" />
        <stop offset="1" stopColor="#FF63C4" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_311_287425"
        x1="16.0188"
        y1="9.13272"
        x2="16.0188"
        y2="30.5702"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.854227" stopColor="white" stopOpacity="0" />
        <stop offset="0.985362" stopColor="white" />
      </linearGradient>
      <radialGradient
        id="paint5_radial_311_287425"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(22.1651 2.28897) rotate(55.8501) scale(5.39983 10.6704)"
      >
        <stop stopColor="#FFA720" />
        <stop offset="0.921158" stopColor="#FFA720" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint6_linear_311_287425"
        x1="21.9082"
        y1="4.85135"
        x2="28.165"
        y2="11.6951"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFA720" />
        <stop offset="1" stopColor="#FFA720" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_311_287425"
        x1="10.6024"
        y1="8.61671"
        x2="4.74268"
        y2="8.06983"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.437473" stopColor="#ED8C1B" />
        <stop offset="1" stopColor="#FFB03A" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_311_287425"
        x1="27.04"
        y1="2.96094"
        x2="26.444"
        y2="5.30239"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.28598" stopColor="#FFE792" />
        <stop offset="1" stopColor="#FFDD65" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_311_287425"
        x1="15.7506"
        y1="20.3508"
        x2="15.0788"
        y2="21.4446"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E3900E" />
        <stop offset="1" stopColor="#EA9D26" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint10_radial_311_287425"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16.4462 19.1797) rotate(-153.246) scale(2.11729 2.12981)"
      >
        <stop stopColor="#EA088B" />
        <stop offset="1" stopColor="#E61E27" />
      </radialGradient>
      <linearGradient
        id="paint11_linear_311_287425"
        x1="17.2037"
        y1="18.7411"
        x2="15.6803"
        y2="18.7411"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.9" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_311_287425"
        x1="24.2899"
        y1="21.2363"
        x2="28.0243"
        y2="19.3206"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E88105" />
        <stop offset="1" stopColor="#E37D02" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_311_287425"
        x1="24.2899"
        y1="22.5798"
        x2="28.0243"
        y2="24.4955"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.286458" stopColor="#DE7D07" />
        <stop offset="0.817708" stopColor="#E37D02" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_311_287425"
        x1="25.2899"
        y1="20.9453"
        x2="29.3711"
        y2="19.0078"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.15625" stopColor="#D64A38" />
        <stop offset="1" stopColor="#FB7425" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_311_287425"
        x1="25.0739"
        y1="22.7093"
        x2="29.1466"
        y2="24.6647"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.15625" stopColor="#D64A38" />
        <stop offset="1" stopColor="#FB7425" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_311_287425"
        x1="7.01785"
        y1="22.721"
        x2="2.94525"
        y2="24.6765"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.15625" stopColor="#D64A38" />
        <stop offset="1" stopColor="#FB7425" />
      </linearGradient>
      <linearGradient
        id="paint17_linear_311_287425"
        x1="6.8182"
        y1="20.9293"
        x2="2.72751"
        y2="19.0119"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.15625" stopColor="#D64A38" />
        <stop offset="1" stopColor="#FB7425" />
      </linearGradient>
      <linearGradient
        id="paint18_linear_311_287425"
        x1="16.0467"
        y1="25.5225"
        x2="16.0467"
        y2="21.1004"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E61E27" />
        <stop offset="1" stopColor="#672A7A" />
      </linearGradient>
      <linearGradient
        id="paint19_linear_311_287425"
        x1="20.6882"
        y1="17.6795"
        x2="21.6884"
        y2="21.1404"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EC9611" />
        <stop offset="1" stopColor="#EA9D26" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint20_linear_311_287425"
        x1="22.8991"
        y1="16.2111"
        x2="18.4304"
        y2="16.2111"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#432A3D" />
        <stop offset="0.567708" stopColor="#684E61" />
        <stop offset="0.765625" stopColor="#43253D" />
      </linearGradient>
      <linearGradient
        id="paint21_linear_311_287425"
        x1="20.321"
        y1="16.0549"
        x2="20.321"
        y2="18.3518"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.326531" stopColor="#43253D" stopOpacity="0" />
        <stop offset="1" stopColor="#43253D" />
      </linearGradient>
      <linearGradient
        id="paint22_linear_311_287425"
        x1="12.862"
        y1="17.6795"
        x2="13.8622"
        y2="21.1404"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E89311" />
        <stop offset="1" stopColor="#EA9D26" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint23_linear_311_287425"
        x1="14.9577"
        y1="16.2111"
        x2="10.489"
        y2="16.2111"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#432A3D" />
        <stop offset="0.567708" stopColor="#684E61" />
        <stop offset="0.765625" stopColor="#43253D" />
      </linearGradient>
      <linearGradient
        id="paint24_linear_311_287425"
        x1="12.3796"
        y1="16.0549"
        x2="12.3796"
        y2="18.3518"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.326531" stopColor="#43253D" stopOpacity="0" />
        <stop offset="1" stopColor="#43253D" />
      </linearGradient>
      <linearGradient
        id="paint25_linear_311_287425"
        x1="23.3525"
        y1="8.83854"
        x2="22.956"
        y2="20.8891"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.548257" stopColor="#FFDD65" />
        <stop offset="1" stopColor="#FFDD65" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint26_linear_311_287425"
        x1="26.5401"
        y1="13.5073"
        x2="25.2901"
        y2="10.0386"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EF8A47" />
        <stop offset="1" stopColor="#EF8A47" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint27_radial_311_287425"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(22.8682 5.77344) rotate(55.7389) scale(7.82686 9.38424)"
      >
        <stop stopColor="#FFDF70" />
        <stop offset="1" stopColor="#FFDF70" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint28_linear_311_287425"
        x1="25.0557"
        y1="8.47656"
        x2="25.0557"
        y2="10.8203"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.432292" stopColor="#FFE7A3" />
        <stop offset="1" stopColor="#FFDF83" stopOpacity="0" />
      </linearGradient>
    </defs>
  </SvgIcon>
);

export default Cat;
