import { FC, useState } from 'react';

import { Stack } from '@mui/material';
import { IDictionaryItem } from 'api/types';
import { BaseToggleLabel, Checkbox, SearchInput } from 'portfolio3/ui-kit';
import { InputController } from 'portfolio3/ui-kit/types';

interface ISpecialitiesSearchableToggleList {
  specialities: IDictionaryItem[];
  selectedSpecialities: number[];
  onToggle: (specialityCode: number) => void;
}

const SpecialitiesSearchableToggleList: FC<ISpecialitiesSearchableToggleList> = ({
  specialities,
  selectedSpecialities,
  onToggle,
}) => {
  const [search, setSearch] = useState('');

  const searchController: InputController = {
    handleChange(value) {
      setSearch(value);
    },
  };

  const filteredSpecialitiesBySearch = specialities.filter((speciality) =>
    search ? speciality.value.toUpperCase().includes(search.toUpperCase()) : speciality,
  );

  return (
    <Stack gap="16px" paddingTop="2px">
      <SearchInput renderMode="fixed" placeholder="Поиск..." value={search} controller={searchController} />
      {filteredSpecialitiesBySearch.map((speciality) => (
        <BaseToggleLabel
          key={speciality.code}
          value={speciality.code}
          label={speciality.value}
          checked={selectedSpecialities.includes(speciality.code)}
          onChange={() => onToggle(speciality.code)}
          size="small"
          control={<Checkbox size="medium" />}
        />
      ))}
    </Stack>
  );
};

export default SpecialitiesSearchableToggleList;
