enum SortTypeKeys {
  DEFAULT = 'default',
  REQUIRED_BUDGET_BALL_ASC = 'requiredBudgetBallAsc',
  REQUIRED_BUDGET_BALL_DESC = 'requiredBudgetBallDesc',
  REQUIRED_PAID_BALL_ASC = 'requiredPaidBallAsc',
  REQUIRED_PAID_BALL_DESC = 'requiredPaidBallDesc',
  DIFFERENCE_BETWEEN_REQUIRED_BUDGET_BALL_AND_BALL_ASC = 'differenceBetweenRequiredBudgetBallAndBallAsc',
  DIFFERENCE_BETWEEN_REQUIRED_BUDGET_BALL_AND_BALL_DESC = 'differenceBetweenRequiredBudgetBallAndBallDesc',
  ENTITY_NAME_A_TO_Z = 'entityNameAToZ',
  ENTITY_NAME_Z_TO_A = 'entityNameZToA',
}

export const SortTypeCodes: Record<SortTypeKeys, number> = {
  default: 1,
  requiredBudgetBallAsc: 2,
  requiredBudgetBallDesc: 3,
  requiredPaidBallAsc: 4,
  requiredPaidBallDesc: 5,
  differenceBetweenRequiredBudgetBallAndBallAsc: 6,
  differenceBetweenRequiredBudgetBallAndBallDesc: 7,
  entityNameAToZ: 8,
  entityNameZToA: 9,
};
