import { REQUEST } from 'actions/utils';
import { getProforientation, getProforientationToken } from 'api';
import { ApiResult } from 'api/impl';
import { IProfessionProforientationData, IProfessionProforientationResponse, IResponsePayload } from 'api/types';
import { LocalStorageGlobals } from 'const';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import { isDefined } from 'utils';

import { GET_PROFORIENTATION, getProforientationActions } from './actions';

type GetProforientationResult = ApiResult<IProfessionProforientationResponse<IProfessionProforientationData>, unknown>;

export const collection = [watchGetProforientation].map(fork);

function* watchGetProforientation() {
  yield takeLatest(GET_PROFORIENTATION[REQUEST], getProforientationSaga);
}

function* getProforientationSaga({ payload }: ReturnType<typeof getProforientationActions.request>) {
  const { guid: actualGuid } = payload;
  let guid = actualGuid;

  // Подмена для теста
  // Если Татарченко Алексей Сергеевич
  if (guid === '75b6ea3b-87ac-4e16-ac3b-936ac100d17f') {
    guid = 'dc079cba-a906-4a26-9220-917524554cf2';
  }

  let response: GetProforientationResult['response'] = undefined;
  let error: GetProforientationResult['error'] = undefined;

  // 1) получить первый ответ
  const initialResult: GetProforientationResult = yield call(getProforientation, guid);
  response = initialResult.response;
  error = initialResult.error;

  // 2) проверить есть ли ошибка авторизации
  if (!response?.success) {
    // 3) если есть ошибка авторизации то получить новый токен
    const tokenResult: ApiResult<IResponsePayload<string>, unknown> = yield call(getProforientationToken);

    const newToken = tokenResult.response?.data ?? '';

    // 4) сохранить новый токен в LS
    localStorage.setItem(LocalStorageGlobals.PROFORIENTATION_TOKEN, newToken);

    // 5) отправить запрос повторно с новым токеном
    const secondResult: GetProforientationResult = yield call(getProforientation, guid);
    response = secondResult.response;
    error = secondResult.error;
  }

  if (isDefined(response)) {
    yield put(getProforientationActions.success(response));
  } else {
    yield put(getProforientationActions.failure(error));
  }
}
