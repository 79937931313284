import { CheckboxSize } from 'portfolio3/ui-kit/types';

import { ICheckboxTokens } from '../types';

export const CheckboxRootSize: Record<CheckboxSize, number> = {
  small: 20,
  medium: 24,
};

export const getCheckboxTokens = (size: CheckboxSize): ICheckboxTokens => {
  return {
    rootSize: CheckboxRootSize[size],
  };
};
