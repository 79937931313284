import { GeneralLocationTypes, LocationTypes } from 'portfolio3/features/diagnostics';
import { AccentColorType } from 'portfolio3/styles';

export const DIAGNOSTIC_PERIOD_ALL_CODE = 9999;
export const DIAGNOSTIC_PERIOD_ALL_TYPE_CODE = 'all';

export const DIAGNOSTIC_LEVEL_TYPE_ALL_CODE = 9999;
export const DIAGNOSTIC_SUBJECT_ALL_CODE = 9999;

/* фильтр период в самодиагностиках */
export enum SelfDiagnosticPeriodTypeKeys {
  YEAR = 'year',
  FULL = 'full',
}

export const SelfDiagnosticPeriodTypeCode: Record<SelfDiagnosticPeriodTypeKeys, number> = {
  [SelfDiagnosticPeriodTypeKeys.YEAR]: 1,
  [SelfDiagnosticPeriodTypeKeys.FULL]: 2,
};

/* фильтр тип диагностики */
export enum DiagnosticTypeKeys {
  INDEPENDENT = 'independent',
  SELF = 'self',
}

export const DiagnosticTypeCode: Record<DiagnosticTypeKeys, number> = {
  [DiagnosticTypeKeys.INDEPENDENT]: 1,
  [DiagnosticTypeKeys.SELF]: 2,
};

/* код для получения списка независимых диагностик */
export enum DiagnosticCountKeys {
  ALL = 'all',
  VISIBLE = 'visible',
}

export const DiagnosticCountCode: Record<DiagnosticCountKeys, number> = {
  [DiagnosticCountKeys.ALL]: 1,
  [DiagnosticCountKeys.VISIBLE]: 2,
};

/* фильтр - тип отображения независимых диагностик */
enum IndependentDiagnosticViewTypeKeys {
  RATING = 'rating',
  ANALYSIS = 'analysis',
}

export const IndependentDiagnosticViewTypeCode: Record<IndependentDiagnosticViewTypeKeys, number> = {
  [IndependentDiagnosticViewTypeKeys.RATING]: 1,
  [IndependentDiagnosticViewTypeKeys.ANALYSIS]: 2,
};

/* уровень независимых диагностик */
export enum IndependentDiagnosticLevelTypeKeys {
  BELOW_BASE = 'belowBase',
  BASE = 'base',
  OVER_HIGH = 'overHigh',
  HIGH = 'high',
}

export const IndependentDiagnosticLevelTypeNames: Record<IndependentDiagnosticLevelTypeKeys, string> = {
  [IndependentDiagnosticLevelTypeKeys.BELOW_BASE]: 'Ниже базового',
  [IndependentDiagnosticLevelTypeKeys.BASE]: 'Базовый',
  [IndependentDiagnosticLevelTypeKeys.OVER_HIGH]: 'Повышенный',
  [IndependentDiagnosticLevelTypeKeys.HIGH]: 'Высокий',
};

export const DiagnosticLevelColorSet1: Record<IndependentDiagnosticLevelTypeKeys, AccentColorType> = {
  belowBase: 'red',
  base: 'orange',
  overHigh: 'yellow',
  high: 'green',
};

export const DiagnosticLevelColorSet2: Record<IndependentDiagnosticLevelTypeKeys, AccentColorType> = {
  belowBase: 'red',
  base: 'orange',
  overHigh: 'yellow',
  high: 'lime',
};

export const DiagnosticLevelsLabelsLarge: Record<IndependentDiagnosticLevelTypeKeys, string> = {
  belowBase: 'ниже базового',
  base: 'базовый уровень',
  overHigh: 'повышенный уровень',
  high: 'высокий уровень',
};

export const DiagnosticLevelsLabelsSmall: Record<IndependentDiagnosticLevelTypeKeys, string> = {
  belowBase: 'ниже базового',
  base: 'базовый',
  overHigh: 'повышенный',
  high: 'высокий',
};

// Rating

export enum DiagnosticRatingRegionCodes {
  Region = 1,
  School,
  Class,
}

export const DiagnosticRatingRegionCodesObject: Record<GeneralLocationTypes, DiagnosticRatingRegionCodes> = {
  generalRatingRegion: DiagnosticRatingRegionCodes.Region,
  generalRatingSchool: DiagnosticRatingRegionCodes.School,
  generalRatingClass: DiagnosticRatingRegionCodes.Class,
};

export const IndependentDiagnosticCodesObject: Record<LocationTypes, DiagnosticRatingRegionCodes> = {
  ratingRegion: DiagnosticRatingRegionCodes.Region,
  ratingSchool: DiagnosticRatingRegionCodes.School,
  ratingClass: DiagnosticRatingRegionCodes.Class,
};
