/* eslint-disable prettier/prettier */
/* eslint-disable */

import connectReducers from '../utils/connectReducers';

import {
  SET_SELF_DIAGNOSIS_SUBJECT
} from '../actions';

export type TSelfDiagnosisSubjectState = {
  subject?: string
}

export interface ISelfDiagnosisSubjectReducers {
    [key: string]: (state: TSelfDiagnosisSubjectState, action: any) => TSelfDiagnosisSubjectState;
}

const initialState = {
  subject: undefined
};

const selfDiagnosisSubject: ISelfDiagnosisSubjectReducers = {
  [SET_SELF_DIAGNOSIS_SUBJECT]: (state, { payload: subject }: { payload: string | undefined }) => ({
    subject
  })
};

export default connectReducers(initialState, selfDiagnosisSubject);
