/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { SvgIcon } from '@mui/material';
import { IconRouteProps } from './types';

const IconRouteCreation: React.FC<IconRouteProps> = ({ outerFill, ...props }) => (
  <SvgIcon width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path className="outer" d="M0 8C0 3.58172 3.58172 0 8 0H22C26.4183 0 30 3.58172 30 8V22C30 26.4183 26.4183 30 22 30H8C3.58172 30 0 26.4183 0 22V8Z" fill={outerFill || '#FFF3E5'} />
    <path className="inner" fillRule="evenodd" clipRule="evenodd" d="M18.9585 11.499C18.3602 11.844 17.596 11.639 17.251 11.0415C16.906 10.4432 17.111 9.67903 17.7085 9.33403C18.3069 8.98903 19.071 9.19403 19.416 9.79153C19.761 10.3899 19.556 11.154 18.9585 11.499ZM14.1669 10.8332C13.4769 10.8332 12.9169 10.2732 12.9169 9.5832C12.9169 8.8932 13.4769 8.3332 14.1669 8.3332C14.8569 8.3332 15.4169 8.8932 15.4169 9.5832C15.4169 10.2732 14.8569 10.8332 14.1669 10.8332ZM11.916 12.7082C11.571 13.3057 10.8069 13.5107 10.2085 13.1657C9.61104 12.8207 9.40604 12.0565 9.75104 11.4582C10.096 10.8607 10.8602 10.6557 11.4585 11.0007C12.056 11.3457 12.261 12.1099 11.916 12.7082ZM10.6994 17.2857C10.1269 17.6724 9.35021 17.5215 8.96437 16.949C8.57771 16.3774 8.72937 15.5999 9.30104 15.214C9.87354 14.8274 10.6502 14.979 11.036 15.5507C11.4227 16.1232 11.271 16.8999 10.6994 17.2857ZM21.2835 9.23736C19.681 7.58403 17.3452 6.6607 14.9219 6.66653H14.921C10.801 6.70153 7.28437 9.7932 6.74354 13.859C6.42521 16.2424 7.13521 18.6432 8.69187 20.4449C10.2485 22.2482 12.506 23.3007 14.8844 23.3332H14.9152C15.916 23.3332 16.7977 22.6774 17.0219 21.7615C17.1877 21.0765 17.0152 20.3815 16.5485 19.8549C16.481 19.7799 16.4419 19.6799 16.4419 19.584C16.4419 19.3549 16.6294 19.1674 16.8585 19.1674H18.2344C20.8944 19.1674 23.2269 17.0024 23.3277 14.4407C23.4044 12.524 22.6785 10.6765 21.2835 9.23736Z" fill="#FF922B" />
  </SvgIcon>

);

export default IconRouteCreation;
