/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useContext } from 'react';

import clsx from 'clsx';
import { useMediaQuery } from '@mui/material';

import InterestNavigationButton from '../NavigationButton';
import { InterestsProgressBarContext } from '../../../../../context';
import { IconChevronSmallLeft, IconChevronSmallRight } from '../../../../../icons';

import './index.scss';

interface IInterestProgressBarProps {}

const InterestProgressBar = React.forwardRef<HTMLElement, IInterestProgressBarProps>(({
}, ref) => {
  const {
    sections,
    currentSection,
    visibleSections,
    lastVisitedSectionIndex,
    onChangeCurrentSection
  } = useContext(InterestsProgressBarContext);

  const isMobileProgressBar = useMediaQuery('(max-width: 638px)', { noSsr: true });

  const isArrowsShown = visibleSections.length < sections.length;
  const isLeftArrowActive = visibleSections[0].id !== sections[0].id;
  const isRightArrowActive = visibleSections[visibleSections.length - 1].id !== sections[sections.length - 1].id;

  return (
    <div className="interests-progress-bar">
      <div className="interests-progress-bar__margin" />
      <nav className="interests-progress-bar__nav" ref={ref}>
        {isArrowsShown && (
          <IconChevronSmallLeft
            className={clsx(
              'interests-progress-bar__icon',
              'interests-progress-bar__icon--left',
              {'interests-progress-bar__icon--active': isLeftArrowActive}
            )}
          />
        )}
        {visibleSections.map((visibleSection) => (
          <InterestNavigationButton
            key={visibleSection.id}
            interestSection={visibleSection}
            index={sections.findIndex((section) => section.id === visibleSection.id)}
            currentSectionTheme={currentSection.theme}
            onChangeCurrentSection={onChangeCurrentSection}
            lastVisitedSectionIndex={lastVisitedSectionIndex}
            isActive={visibleSection.id === sections.find((section) => section.id === currentSection.id)?.id}
            isMobile={isMobileProgressBar}
          />
        ))}
        {isArrowsShown && (
          <IconChevronSmallRight
            className={clsx(
              'interests-progress-bar__icon',
              'interests-progress-bar__icon--right',
              {'interests-progress-bar__icon--active': isRightArrowActive}
            )}
          />
        )}
      </nav>
      <div className="interests-progress-bar__margin" />
    </div>
  );
});

export default InterestProgressBar;
