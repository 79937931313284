import { SxStyles } from 'types';

export const bodyContainer: SxStyles = {
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',

  paddingBlock: '16px 32px',
  paddingInline: '24px',
};

export const badges: SxStyles = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '12px',
};
