import { Theme } from '@mui/material';
import { getAccentColor } from 'portfolio3/styles';

import pattern from './assets/pattern.svg';

export const wrapperStyles = (theme: Theme) => ({
  background: `url(${pattern}), ${getAccentColor(theme.palette.accentColor, '100')}`,
  backgroundBlendMode: 'overlay',
  backgroundRepeat: 'repeat',
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',

  p: 2,
  borderRadius: 1.5,
  width: '100%',

  img: {
    width: '56px',
    height: '56px',
  },

  [theme.breakpoints.down('commonSm')]: {
    p: 1.5,
    img: {
      width: '32px',
      height: '32px',
    },
  },
});
