import { useEffect } from 'react';

import { goBack } from '../utils';

export default (isOpen: boolean, dialogType: string): void => {
  useEffect(() => {
    const modalHash = `#modal-${dialogType}`;

    if (isOpen) {
      window.location.hash = modalHash;
    } else if (window.location.hash === modalHash) {
      goBack();
    }
  }, [isOpen, dialogType]);
};
