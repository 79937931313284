import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const HammerAndWrench: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.38087 4.99414L15.8184 13.4316C16.4774 14.0907 16.4774
      15.1591 15.8184 15.8181C15.1594 16.4771 14.0909 16.4771 13.4319
      15.8181L4.99438 7.38063L7.38087 4.99414Z"
      fill="#7D4533"
    />
    <path
      d="M6.4278 1.25425L2.44883 5.23322C2.339 5.34306 2.339 5.52114
        2.44883 5.63097L3.37493 7.31261L5.03419 8.21633C5.14403 8.32617
        5.3221 8.32617 5.43194 8.21633L8.21617 5.4321C8.32601 5.32226
        8.32601 5.14419 8.21617 5.03435L7.11944 3.93761C6.85101 3.66735
        7.09117 3.37511 7.09117 3.37511L8.62349 1.8428C8.7907 1.67558
        8.68945 1.3892 8.4543 1.36424L6.65636 1.17345C6.57188 1.16448
        6.48787 1.19418 6.4278 1.25425Z"
      fill="#B4ACBC"
    />
    <path
      d="M14.805 5.13277L16.2394 3.69839C16.4194 3.51276 16.7344 3.60276
        16.7962 3.85589C17.0212 4.78964 16.7737 5.81339 16.0425 6.54464C15.2645
        7.32264 14.1468 7.55719 13.1654 7.24451L7.68869 12.7596C7.98467 13.7472
        7.73481 14.8647 6.94684 15.629C6.21559 16.3322 5.20871 16.574 4.29184
        16.349C4.03871 16.2872 3.95434 15.9722 4.13434 15.7922L5.56871 14.3578C5.69809
        14.2284 5.69809 14.0203 5.56871 13.8909L4.66309 12.9853C4.53371 12.8559 4.32559
        12.8559 4.19621 12.9853L2.76184 14.4197C2.58184 14.6053 2.26684 14.5153 2.20496
        14.2622C1.97996 13.3284 2.22746 12.3047 2.95871 11.5734C3.74421 10.7879 4.87595
        10.5564 5.8641 10.8827L11.3213 5.38732C11.0117 4.39273 11.2587 3.2608 12.0544
        2.48902C12.7856 1.78589 13.7925 1.54402 14.7094 1.76902C14.9625 1.83089 15.0469
        2.14589 14.8669 2.32589L13.4325 3.76026C13.3031 3.88964 13.3031 4.09777 13.4325
        4.22714L14.3381 5.13277C14.4675 5.26214 14.6756 5.26214 14.805 5.13277Z"
      fill="#B4ACBC"
    />
    <path
      d="M0.661916 6.35517C0.606998 6.30026 0.606998 6.21122 0.661916 6.1563L1.02387
       5.79435C1.46321 5.35501 2.17552 5.35501 2.61486 5.79435L4.80247 7.98196C5.24181
       8.4213 5.24181 9.13361 4.80247 9.57295L4.44052 9.9349C4.3856 9.98982 4.29656
       9.98982 4.24164 9.9349L0.661916 6.35517Z"
      fill="#CDC4D6"
    />
  </SvgIcon>
);

export default HammerAndWrench;
