import { IRootState } from 'reducers';
import { createSelector } from 'reselect';

import { mapProfessionJobsResponse } from './mappers';

const professionJobsSelector = createSelector([(state: IRootState) => state.studentJobs], (studentJobs) => {
  return mapProfessionJobsResponse(studentJobs);
});

export default professionJobsSelector;
