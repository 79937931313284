/* eslint-disable prettier/prettier */
/* eslint-disable */

import { SectionCodes } from "./sections";

export enum RecommendationsHeadKindKeys {
  SPORT = 'sport',
  CULTURE = 'culture',
  STUDY = 'study',
  SCIENCE = 'science',
  CREATION = 'creation'
}

export const RecommendationsHeadKindCodes: Record<RecommendationsHeadKindKeys, number> = {
  [RecommendationsHeadKindKeys.STUDY]: SectionCodes.study,
  [RecommendationsHeadKindKeys.SCIENCE]: SectionCodes.science,
  [RecommendationsHeadKindKeys.SPORT]: SectionCodes.sport,
  [RecommendationsHeadKindKeys.CREATION]: SectionCodes.creation,
  [RecommendationsHeadKindKeys.CULTURE]: SectionCodes.culture,
};
