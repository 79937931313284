import { FC } from 'react';

import { styled } from '@mui/material';
import clsx from 'clsx';

import { ToggleRenderMode } from '../../types';
import BaseFormControl from '../bases/BaseFormControl';
import { IBaseFormControlProps, IImplFormControlProps } from '../types';

const StyledFormControl = styled(BaseFormControl)<IBaseFormControlProps<ToggleRenderMode>>(
  ({ renderMode = 'column' }) => {
    const isInline = renderMode === 'inline';
    const flexDirection = isInline ? 'row' : 'column';
    const alignItems = isInline ? 'center' : 'flex-start';
    const justifyContent = isInline ? 'space-between' : 'flex-start';

    return {
      '& .toggle-control__label-container': {
        display: 'flex',
        flexDirection,
        alignItems,
        justifyContent,
        gap: 8,
      },
    };
  },
);

/**
 * Компонент FormControl для Radio, Checkbox, Toggle
 */
const ToggleFormControl: FC<IImplFormControlProps<ToggleRenderMode>> = ({ label, control, helper, ...props }) => {
  return (
    <StyledFormControl {...props} className={clsx(props.className, 'toggle-control')}>
      <div className="toggle-control__label-container">
        {label}
        {control}
      </div>
      {helper}
    </StyledFormControl>
  );
};

export default ToggleFormControl;
