import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const avatarSection: SxStyles = {
  paddingBottom: '20px',
  borderBottom: '1px solid',
  borderColor: NeutralColors.light_neutrals_300,
};

export const avatarsGrid: SxStyles = (theme) => {
  return {
    display: 'grid',
    gridAutoRows: '156px',
    gridTemplateColumns: 'repeat(auto-fill, minmax(140px, 1fr))',
    gap: '12px',

    [theme.breakpoints.down('commonSm')]: {
      gridAutoRows: '100px',
      gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))',
    },
  };
};
