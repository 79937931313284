import { StudentSectionSettingTypeCodes } from 'const';
import useWidgetVisibility from 'hooks/useWidgetVisibility';

const civilUseWidgetVisibilityCreator = (subsectionTypeCode: string) => {
  const useProfessionWidgetVisibility = () => {
    return useWidgetVisibility({
      sectionTypeCode: StudentSectionSettingTypeCodes.civil,
      subsectionTypeCode,
    });
  };
  return useProfessionWidgetVisibility;
};

const useRewards = civilUseWidgetVisibilityCreator(StudentSectionSettingTypeCodes.civilReward);

const useClubs = civilUseWidgetVisibilityCreator(StudentSectionSettingTypeCodes.civilClub);

const useContests = civilUseWidgetVisibilityCreator(StudentSectionSettingTypeCodes.civilContest);

const useSections = civilUseWidgetVisibilityCreator(StudentSectionSettingTypeCodes.civilUnit);

export const civilWidgetVisibilityHooks = { useRewards, useClubs, useContests, useSections };
