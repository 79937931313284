import { useSelector } from 'react-redux';

import { SectionCodes } from 'const';
import {
  IEntryFormEntityProperties,
  IPortfolioEntryFormData,
  MappedEventData,
} from 'portfolio3/features/dataEntryForm';
import { IRootState } from 'reducers';
import { getCategoryProperties } from 'utils';

import { getCommonEventProperties, getCommonRewardProperties } from './utils';

interface IProps {
  formData: IPortfolioEntryFormData;
  mappedEventData: MappedEventData;
}

const useProfessionEntityProperties = ({ formData, mappedEventData }: IProps): IEntryFormEntityProperties | null => {
  const portfolioSections = useSelector((state: IRootState) => state.sectionRef.content);
  const rewards = useSelector((state: IRootState) => state.rewardKinds.content);

  const categoryCode = formData.categoryCode;
  const dataKind = formData.dataKind;
  const typeCode = formData.typeCode;

  const categoryProperties = categoryCode ? getCategoryProperties(categoryCode) : null;

  let name: string | undefined;
  let typeName: string | undefined;
  let attributes: string[] | undefined;

  if (typeCode === SectionCodes.professionExamGia) {
    name = portfolioSections.find((section) => section.code === formData.typeCode)?.value;
    typeName = 'ГИА';
    attributes = ['ГИА'];
  }

  if (dataKind === SectionCodes.professionEvent) {
    const props = getCommonEventProperties(mappedEventData);
    name = props.name ?? 'Другое';
    typeName = props.typeName;
    attributes = props.attributes;
  }

  if (dataKind === SectionCodes.professionReward) {
    const props = getCommonRewardProperties(rewards, portfolioSections, formData);
    name = props.name;
    typeName = props.typeName;
    attributes = props.attributes;
  }

  if (categoryCode !== SectionCodes.profession) return null;
  if (typeCode === SectionCodes.professionSpoJob) return null;
  if (typeCode === SectionCodes.professionSpoMetaskills) return null;

  return {
    entityInfo: {
      name,
      categoryName: categoryProperties?.name ?? '',
      typeName: typeName ?? '',
      attributes,
    },
  };
};

export default useProfessionEntityProperties;
