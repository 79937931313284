import { FC } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { placeholderWatch } from 'images';
import { NeutralColors } from 'portfolio3/styles';

const ImportLoader: FC = () => {
  return (
    <Stack className="import-loader" sx={{ alignItems: 'center', textAlign: 'center' }}>
      <Box component="img" src={placeholderWatch} alt="Загрузка" sx={{ width: '126px', height: '124px' }} />
      <Typography variant="Paragraph LG/Semi Bold" marginTop="12px">
        Считываем данные... Если размер файла большой, то это может занять некоторое время
      </Typography>
      <Typography variant="Paragraph MD/Regular" marginTop="4px" color={NeutralColors.light_neutrals_600}>
        Вы можете закрыть это окно и отслеживать процесс импорта на панели задач внизу экрана. Как только импорт будет
        завершен, вы сможете перейти к результатам импорта через панель задач.
      </Typography>
    </Stack>
  );
};

export default ImportLoader;
