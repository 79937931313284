import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';

import {
  ICommonResponse,
  IDictionaryItem,
  ILocation,
  IModifiedDictionaryItemList,
  IModifiedEventKindsList,
} from 'api/types';
import { SectionCodes } from 'const';
import { IRootState } from 'reducers';
import { createSelector } from 'reselect';
import { sectionRefArchiveFalseSelector } from 'selectors';
import { getAgeLimitFromArray } from 'utils';

import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';
import { CultureActivitySecondaryBlock } from '../../secondaryBlocks';

interface ICultureSecondaryBlockProps {
  activityTypeData: IModifiedDictionaryItemList;
  portfolioKindData: IModifiedDictionaryItemList;
  olympiadTypes: ICommonResponse<IDictionaryItem>;
  eventKindData: IModifiedEventKindsList;
  museums: ICommonResponse<ILocation>;
  cinemas: ICommonResponse<ILocation>;
  theatres: ICommonResponse<ILocation>;
}

const CultureSecondaryBlock: React.FC<ICultureSecondaryBlockProps> = ({
  portfolioKindData,
  eventKindData,
  olympiadTypes,
  activityTypeData,
  museums,
  theatres,
  cinemas,
}) => {
  const { formData, onChangeFormData } = useContext(LocalDataEntryFormContext);

  useEffect(() => {
    if (portfolioKindData.content.length === 1) {
      onChangeFormData({
        ...formData,
        dataKind: portfolioKindData.content[0].code,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioKindData.content.length]);

  const getLocationOptions = () => {
    switch (formData.name) {
      case SectionCodes.museumVisiting:
        return museums?.content ?? [];
      case SectionCodes.cinemaVisiting:
        return cinemas?.content ?? [];
      case SectionCodes.theatreVisiting:
        return theatres?.content ?? [];
      default:
        return [];
    }
  };

  const currentEventKindData = eventKindData.content.find((activity) => activity.code === formData.name);

  const eventData = {
    type:
      activityTypeData.content?.find((portfolioKind) => portfolioKind.code === currentEventKindData?.categoryCode)
        ?.value || 'Посещение онлайн мероприятий',
    name: currentEventKindData?.value,
    ageLimit: getAgeLimitFromArray(currentEventKindData?.ageLimits),
    levelEvent: currentEventKindData?.levelEvent
      ? olympiadTypes.content?.find((type) => type.code === currentEventKindData?.levelEvent)?.value
      : undefined,
    levelEventCode: currentEventKindData?.levelEvent,
    organizators: currentEventKindData?.organizators,
  };

  return (
    <>
      {formData.dataKind === SectionCodes.cultureEvent && formData.name && (
        <CultureActivitySecondaryBlock
          eventData={eventData}
          eventLevelOptions={olympiadTypes?.content || []}
          name={eventKindData.content?.find((eventKind) => eventKind.code === formData.name)?.value}
          placeOptions={getLocationOptions()}
        />
      )}
    </>
  );
};

const portfolioKindMapping = createSelector(
  [(state: IRootState) => sectionRefArchiveFalseSelector(state)],
  (sections) => ({
    content: sections.content?.filter((section) => section.parentId === SectionCodes.culture) || [],
    loading: sections.loading,
  }),
);

const eventKindsMapping = createSelector([(state: IRootState) => state.eventKinds], (eventKinds) => ({
  content:
    eventKinds?.content?.filter(
      (eventKind) =>
        eventKind.categoryCode === SectionCodes.cultureVisiting ||
        eventKind.categoryCode === SectionCodes.cultureOnline,
    ) || [],
  loading: eventKinds.loading,
}));

const portfolioActivityTypeMapping = createSelector(
  [(state: IRootState) => sectionRefArchiveFalseSelector(state)],
  (sections) => ({
    content: sections.content?.filter((section) => section.parentId === SectionCodes.cultureEvent) || [],
    loading: sections.loading,
  }),
);

export default connect((state: IRootState) => ({
  portfolioKindData: portfolioKindMapping(state),
  activityTypeData: portfolioActivityTypeMapping(state),
  eventKindData: eventKindsMapping(state),
  olympiadTypes: state.olympiadTypes,
  museums: state.museums,
  cinemas: state.cinemas,
  theatres: state.theatres,
}))(CultureSecondaryBlock);
