import { FC } from 'react';
import { useCalendarGrid } from 'react-aria';
import { CalendarState, RangeCalendarState } from 'react-stately';

import { CalendarDate } from '@internationalized/date';
import { Box } from '@mui/material';

import YearCell from '../Cell';
import * as styles from './styles';

interface IYearGridProps {
  calendarState: CalendarState | RangeCalendarState;
  startYear: number;
  endYear: number;
}

const YearGrid: FC<IYearGridProps> = ({ calendarState, startYear, endYear }) => {
  const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => index + startYear);

  const startDate = new CalendarDate(startYear, 1, 1);
  const endDate = new CalendarDate(endYear, 1, 1);

  const { gridProps } = useCalendarGrid(
    {
      startDate,
      endDate,
    },
    calendarState,
  );

  return (
    <Box className="year-calendar-grid" {...gridProps} sx={styles.root}>
      {years.map((year) => {
        const date = new CalendarDate(year, 1, 1);

        return <YearCell key={year} cellOptions={{ date }} calendarState={calendarState} />;
      })}
    </Box>
  );
};

export default YearGrid;
