import { FC } from 'react';

import { Box } from '@mui/material';
import { BaseInputLabel, FormControl, Switcher, SwitcherButton, SwitcherVariant } from 'portfolio3/ui-kit';
import { CommonUiKitSize, InputRenderMode } from 'portfolio3/ui-kit/types';
import { SxStyles } from 'types';

interface IDualSwitcherControl {
  isMobile: boolean;
  inputRenderMode: InputRenderMode;
  inputSize: CommonUiKitSize;
  required?: boolean;
  label: string;
  value: number;
  firstLabel: string;
  secondLabel: string;
  onChange: (value: number) => void;
}

const DualSwitcherControl: FC<IDualSwitcherControl> = ({
  isMobile,
  inputRenderMode,
  inputSize,
  required,
  label,
  value,
  firstLabel,
  secondLabel,
  onChange,
}) => {
  const formControlSx: SxStyles = isMobile ? null : { width: 'max-content' };
  const inputLabelSx: SxStyles = isMobile ? { color: '#7B819B !important' } : null;
  const size: CommonUiKitSize = isMobile ? 'large' : 'medium';
  const variant: SwitcherVariant = isMobile ? 'gray' : 'accent';

  return (
    <Box className="dual-switch-control">
      <FormControl
        sx={formControlSx}
        required={required}
        renderMode={inputRenderMode}
        inputSize={inputSize}
        label={
          <BaseInputLabel sx={inputLabelSx} overrideRenderMode="fixed">
            {label}
          </BaseInputLabel>
        }
        control={
          <Switcher
            useHiddenList
            componentSize={size}
            componentVariant={variant}
            value={value}
            onChange={onChange}
            stretchy={isMobile}
          >
            <SwitcherButton content={firstLabel} value={1} componentVariant={variant} />
            <SwitcherButton content={secondLabel} value={2} componentVariant={variant} />
          </Switcher>
        }
      />
    </Box>
  );
};

export default DualSwitcherControl;
