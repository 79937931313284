import { Theme } from '@mui/material';
import { generateAccentTheme } from 'portfolio3/styles/theme';

import useBrightTheme from './useBrightTheme';

const useSectionTheme = (defaultTheme: Theme) => {
  const isBrightTheme = useBrightTheme();
  const theme = isBrightTheme ? defaultTheme : generateAccentTheme('indigo');

  return theme;
};

export default useSectionTheme;
