import { createContext } from 'react';

import { VuzRecommendationTab } from '../const';
import { IVuzRecommendationClientFilters, IVuzRecommendationFilters, SetFilterFunction } from '../types';

interface IVuzRecommendationContext {
  currentTab: VuzRecommendationTab;
  serverFilters: IVuzRecommendationFilters | null;
  computedInitialServerFilters: IVuzRecommendationFilters;
  clientFilters: IVuzRecommendationClientFilters | null;
  setServerFilter: SetFilterFunction<IVuzRecommendationFilters>;
  setClientFilter: SetFilterFunction<IVuzRecommendationClientFilters>;
  totalElements: number;
}

export const VuzRecommendationContext = createContext({} as IVuzRecommendationContext);
