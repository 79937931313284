import { FC, PropsWithChildren } from 'react';

import { Box } from '@mui/material';
import { mergeSx } from 'utils';

import * as styles from './styles';

interface IThemeAvatarPreviewProps {
  isActive: boolean;
  onClick: VoidFunction;
}

const ThemeAvatarPreview: FC<PropsWithChildren<IThemeAvatarPreviewProps>> = ({ isActive, onClick, children }) => {
  return (
    <Box className="theme-avatar-preview" onClick={onClick} sx={mergeSx(styles.root, isActive && styles.rootActive)}>
      <Box className="theme-avatar-preview__content" sx={styles.content}>
        {children}
      </Box>
    </Box>
  );
};

export default ThemeAvatarPreview;
