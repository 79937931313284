import { forwardRef, ReactNode } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

import { styled } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';
import { inheritSx } from 'utils';

import { buttonStyles } from '../utils';

interface INavigationButtonProps extends NavLinkProps {
  name: string;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
}

const StyledNavLink = styled(NavLink)(() => {
  return {
    ...inheritSx([buttonStyles]),

    display: 'flex',
    gap: '8px',
    fontFamily: 'Inter',
    fontSize: '14px',
    lineHeight: '20px',

    svg: {
      width: '20px',
      height: '20px',
      color: NeutralColors.light_neutrals_900,
    },
  };
});

const NavigationButton = forwardRef<HTMLAnchorElement, INavigationButtonProps>(
  ({ name, startAdornment, endAdornment, ...props }, ref) => {
    return (
      <StyledNavLink ref={ref} className="navigation-link" activeClassName="navigation-link--active" {...props}>
        {startAdornment}
        {name}
        {endAdornment}
      </StyledNavLink>
    );
  },
);

NavigationButton.displayName = 'NavigationButton';

export default NavigationButton;
