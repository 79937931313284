/* eslint-disable max-len */
import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconProfessionEducationCommon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.012 11.01c3.04 0 5.5-2.46 5.5-5.5S15.052 0 12.012 0s-5.5 2.47-5.5 5.51c0 3.04 2.46 5.5 5.5 5.5Zm0-8.7c1.77 0 3.2 1.44 3.2 3.2 0 1.76-1.44 3.2-3.2 3.2a3.21 3.21 0 0 1-3.2-3.2c0-1.76 1.44-3.2 3.2-3.2ZM17.65 10.99h-.2L14.55 24h2.28l2.34-10.49c1.56.62 2.59 2.13 2.59 3.83V24h2.23v-6.66c0-3.5-2.85-6.35-6.35-6.35h.01ZM6.36 10.99C2.85 10.99 0 13.84 0 17.34V24h2.23v-6.66c0-1.7 1.03-3.2 2.59-3.83L7.16 24h2.28l-2.9-13.01h-.2.02ZM13.69 12.32a5.148 5.148 0 0 1-3.38.01l-.99 3.05 2.69 7.29 2.69-7.29-.99-3.05-.02-.01Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconProfessionEducationCommon;
