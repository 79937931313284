import { FC, useContext, useEffect, useState } from 'react';

import { IGeneralRating, IIndependentDiagnosticGeneralRating } from 'api/types';
import { DIAGNOSTIC_PERIOD_ALL_CODE, DiagnosticRatingRegionCodes } from 'const';
import { cup } from 'images';
import { WidgetContainer } from 'portfolio3/components/common/WidgetContainer';
import { emitYMEvent } from 'portfolio3/features/yandexMetrika';
import { Switcher, SwitcherButton } from 'portfolio3/ui-kit';

import { InfoBox } from '../components';
import Rating from '../components/Rating';
import ShowAllRating from '../components/ShowAllRating';
import { generalRatingTooltipText, MAX_VISIBLE_RATING } from '../const';
import DiagnosticContext from '../context/diagnosticContext';
import { useDiagnosticRating } from '../hooks/useDiagnosticRating';
import { city, classIcon, school } from '../images/tabs';
import { IRatingTab } from '../types';
import {
  createMapFromRating,
  getGeneralRatingInfoBoxText,
  getGeneralRatingLastPlaceNumber,
  getGeneralRatingPenultimatePlaceNumber,
  getGeneralRatingPercentLowerOthers,
  getGeneralRatingStudentPlaceNumber,
  getRatingRegionPlaceName,
} from '../utils';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IIndependentRatingContainer {}

const IndependentRatingContainer: FC<IIndependentRatingContainer> = () => {
  const { filters, generalRating, independentDiagnosticPeriods } = useContext(DiagnosticContext);
  const [selectedSubject, setSelectedSubject] = useState<number>(1);
  const [selectedRegion, setSelectedRegion] = useState<number>(DiagnosticRatingRegionCodes.Region);
  const currentPeriod = independentDiagnosticPeriods.find((period) => filters.independentPeriodCode === period.code);
  const { formChartRows } = useDiagnosticRating('generalRating');

  useEffect(() => {
    setSelectedSubject(1);
    setSelectedRegion(DiagnosticRatingRegionCodes.Region);
  }, [currentPeriod]);

  const generalRatingGroups = generalRating.content?.flatMap((rating) => {
    if (
      (currentPeriod?.code === DIAGNOSTIC_PERIOD_ALL_CODE && rating.learningYear === 'all') ||
      rating.learningYear === currentPeriod?.value
    ) {
      return rating.generalRating.map((subject) => subject);
    }
    return [];
  });

  const currentRatingObject = generalRatingGroups?.filter((_, index) => index + 1 === selectedSubject)[0];

  /* определение информации для инфобокса */
  // % учащихся, чей результат хуже, чем у учащегося
  const percentLowerOthers = Number(getGeneralRatingPercentLowerOthers(selectedRegion, currentRatingObject));
  const studentPlaceNumber = getGeneralRatingStudentPlaceNumber(selectedRegion, currentRatingObject);
  const ratingPenultimatePlaceNumber = getGeneralRatingPenultimatePlaceNumber(selectedRegion, currentRatingObject);
  const ratingLastPlaceNumber = getGeneralRatingLastPlaceNumber(selectedRegion, currentRatingObject);
  const ratingRegionName = getRatingRegionPlaceName(selectedRegion);
  const isOnLastPlaces =
    studentPlaceNumber === ratingPenultimatePlaceNumber || studentPlaceNumber === ratingLastPlaceNumber;
  // % лучших учеников
  const percentHigherOthers = 100 - percentLowerOthers;
  const infoboxPercent = percentHigherOthers.toFixed();
  const infoboxRenderCondition =
    !isOnLastPlaces && !isNaN(percentLowerOthers) && Boolean(currentRatingObject?.subjectGeneralRatingName);
  const infoboxTitle = 'Молодец!';
  const infoboxText = getGeneralRatingInfoBoxText(
    infoboxPercent,
    ratingRegionName,
    currentRatingObject?.subjectGeneralRatingName || '',
  );
  const chartRows = currentRatingObject ? formChartRows(currentRatingObject, selectedRegion) : [];
  const ratingTabs = currentRatingObject ? createRatingTabs(currentRatingObject) : [];

  function createRatingTabs(generalRating: IIndependentDiagnosticGeneralRating): IRatingTab[] {
    const ratingTabsMap = createMapFromRating<IGeneralRating>(generalRating, 'generalRating');
    const locationNamesPool = ['Москве', 'твоей школе', 'твоем классе'];
    const ratingImagesPool = [city, school, classIcon];

    return Array.from(ratingTabsMap).map((tab) => {
      const [tabIndex, rating] = tab;

      return {
        typography: {
          heading: `${rating.placeGeneralRatingStudent} место`,
          paragraph: `В ${locationNamesPool[tabIndex - 1]}`,
        },
        image: ratingImagesPool[tabIndex - 1],
        hasCrown: rating.placeGeneralRatingStudent === 1,
        value: tabIndex,
      };
    });
  }

  function handleSubjectChange(value: number) {
    setSelectedSubject(value);

    if (value !== selectedSubject) {
      const ratingObjectIndex = value - 1;
      const subjectName = generalRatingGroups?.[ratingObjectIndex]?.subjectGeneralRatingName;

      emitYMEvent({
        type: 'subjectDiagnosticsSwitching',
        payload: {
          subject: subjectName ?? '',
        },
      });
    }
  }

  function handleRegionChange(value: number) {
    setSelectedRegion(value);
  }

  function getAllPlacesNumber(generalRating: IIndependentDiagnosticGeneralRating): number {
    const ratingTabsMap = createMapFromRating<IGeneralRating>(generalRating, 'generalRating');
    const allPlaces = ratingTabsMap.get(selectedRegion).allPlaces;
    return allPlaces?.length || 0;
  }

  return (
    <>
      {/* фильтры */}
      {generalRatingGroups && generalRatingGroups.length > 0 && (
        <>
          <WidgetContainer.Divider sx={{ marginTop: 0 }} />
          <WidgetContainer.Padding>
            <Switcher
              sx={{ border: 0, padding: 0 }}
              value={selectedSubject}
              onChange={handleSubjectChange}
              useHiddenList
            >
              {generalRatingGroups?.map((ratingGroup, index) => {
                return <SwitcherButton key={index} content={ratingGroup.subjectGeneralRatingName} value={index + 1} />;
              })}
            </Switcher>
          </WidgetContainer.Padding>
        </>
      )}

      {/* Блок с графиком рейтинга */}
      {currentRatingObject && (
        <WidgetContainer.Padding sx={{ marginTop: '20px' }}>
          <Rating
            header={{
              title: `Твой рейтинг по предмету ${currentRatingObject?.subjectGeneralRatingName}`,
              buttonText: 'Как это считается?',
              tooltipText: generalRatingTooltipText,
            }}
            InfoBox={infoboxRenderCondition && <InfoBox title={infoboxTitle} text={infoboxText} image={cup} />}
            tabs={ratingTabs}
            tabValue={selectedRegion}
            onTabChange={handleRegionChange}
            chartRows={chartRows.slice(0, MAX_VISIBLE_RATING)}
            caption="средний результат в %"
            chartFullWidth
          />
          {getAllPlacesNumber(currentRatingObject) > MAX_VISIBLE_RATING && (
            <ShowAllRating tabValue={selectedRegion} ratingObject={currentRatingObject} />
          )}
        </WidgetContainer.Padding>
      )}
    </>
  );
};

export default IndependentRatingContainer;
