import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconCubeFilled: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="m12 10.503 8.512-3.578a1.751 1.751 0 0 0-.511-.375l-7.2-3.374a1.908 1.908 0 0 0-1.602 0l-7.2 3.374c-.2.093-.365.226-.511.375L12 10.503Zm-.75 1.324L3 8.36v7.73c-.005.64.379 1.228.99 1.517l7.2 3.373c.01.005.02.008.03.01l.03.011v-9.173Zm1.5 9.17v-9.17L21 8.36v7.738c0 .64-.387 1.224-.999 1.51l-7.2 3.372c-.01.005-.019.008-.028.01l-.023.008Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconCubeFilled;
