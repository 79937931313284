import { FC, useContext } from 'react';

import { Box } from '@mui/material';
import { SelfDiagnosticPeriodTypeCode } from 'const';
import { Switcher, SwitcherButton } from 'portfolio3/ui-kit';
import { Select } from 'portfolio3/ui-kit/selects';
import { IController } from 'portfolio3/ui-kit/types';
import { SxStyles } from 'types';

import DiagnosticContext from '../../context/diagnosticContext';

const styles: SxStyles = {
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '8px',
};

const SelfDiagnosticsFilter: FC = () => {
  const { filters, setSingleFilter, selfDiagnosticSubjects } = useContext(DiagnosticContext);

  const handleChangePeriod = (periodCode: number) => {
    setSingleFilter('selfPeriodCode', periodCode);
  };

  const subjectController: IController<string | undefined> = {
    handleChange(subjectCode) {
      setSingleFilter('selfSubjectCode', Number(subjectCode));
    },
  };

  return (
    <Box sx={styles}>
      <Switcher componentSize="medium" value={filters.selfPeriodCode} onChange={handleChangePeriod}>
        <SwitcherButton value={SelfDiagnosticPeriodTypeCode.year} content="Текущий год" />
        <SwitcherButton value={SelfDiagnosticPeriodTypeCode.full} content="За все время" />
      </Switcher>
      <Box sx={{ width: '162px' }}>
        <Select
          inputRenderMode="fixed"
          inputSize="medium"
          value={filters.selfSubjectCode}
          options={selfDiagnosticSubjects}
          controller={subjectController}
        />
      </Box>
    </Box>
  );
};

export default SelfDiagnosticsFilter;
