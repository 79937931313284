/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconArrowOpen: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M6.66665 17.3334H22.4866L17.6426 23.1467C17.1706 23.7121 17.248 24.5534 17.8133 25.0241C18.38 25.4961 19.22 25.4188 19.692 24.8534L26.3586 16.8534C26.4106 16.7907 26.436 16.7174 26.4746 16.6481C26.5066 16.5921 26.5453 16.5441 26.5693 16.4827C26.6293 16.3294 26.6653 16.1681 26.6653 16.0054C26.6653 16.0041 26.6666 16.0014 26.6666 16.0001C26.6666 15.9987 26.6653 15.9961 26.6653 15.9947C26.6653 15.8321 26.6293 15.6707 26.5693 15.5174C26.5453 15.4561 26.5066 15.4081 26.4746 15.3521C26.436 15.2827 26.4106 15.2094 26.3586 15.1467L19.692 7.14675C19.4266 6.83075 19.048 6.66675 18.6666 6.66675C18.3653 6.66675 18.0626 6.76808 17.8133 6.97608C17.248 7.44675 17.1706 8.28808 17.6426 8.85342L22.4866 14.6667H6.66665C5.93065 14.6667 5.33331 15.2641 5.33331 16.0001C5.33331 16.7361 5.93065 17.3334 6.66665 17.3334Z" fill="#A28E35" />
  </SvgIcon>
);

export default IconArrowOpen;
