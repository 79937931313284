/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import {
  Avatar as AvatarMaterial,
  AvatarProps,
} from '@mui/material';

import './index.scss';


const Avatar: React.FC<AvatarProps> = (props) => {
  const { sizes } = props;

  return (
    <div className={sizes === 'large' ? 'avatar avatar--large' : 'avatar'}>
      <AvatarMaterial
        className={`${props?.className} ${sizes}`}
        {...props}
      />
    </div>
  );
};

export default Avatar;
