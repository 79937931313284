import { SectionCodes } from './sections';

export const AUTOMATICALLY_ADDED_SOURCE_VALUE = 'Добавлена автоматически';

export enum GlobalRoleKeys {
  STUDENT = 'student',
  AGENT = 'agent',
  OPERATOR = 'operator',
  HEAD_TEACHER = 'headTeacher',
  ADMIN_O_O = 'adminOo',
  TEACHER = 'teacher',
  ADMIN = 'admin',
}

export const GlobalRoleTags: Record<GlobalRoleKeys, string> = {
  student: 'STUDENT',
  agent: 'AGENT',
  operator: 'OPERATOR',
  headTeacher: 'HEAD_TEACHER',
  adminOo: 'ADMIN_O_O',
  teacher: 'TEACHER',
  admin: 'ADMIN',
};

export enum LocalRoleKeys {
  // учащийся
  STUDENT_OPERATOR = 'studentOperator',
  // родитель
  AGENT_OPERATOR = 'agentOperator',
  // оператор
  GLOBAL_OPERATOR = 'globalOperator',
  STUDY_OPERATOR = 'studyOperator',
  SCIENCE_OPERATOR = 'scienceOperator',
  SPORT_OPERATOR = 'sportOperator',
  CREATION_OPERATOR = 'creationOperator',
  CULTURE_OPERATOR = 'cultureOperator',
  CIVIL_OPERATOR = 'civilOperator',
  PROFESSION_OPERATOR = 'professionOperator',
  // сотрудник
  EMPLOYEE_O_O = 'employeeOo', // завуч
  ADMIN_O_O = 'adminOo', // админ ОО
  TEACHER_O_O = 'teacherOo', // классный руководитель (учитель)
  // администратор
  ADMIN_OPERATOR = 'adminOperator', // админ
  ADMIN_R_O = 'adminReadOnly', // админ в режиме просмотра
}

export const LocalRoleTags: Record<LocalRoleKeys, string> = {
  studentOperator: 'STUDENT_OPERATOR',
  agentOperator: 'AGENT_OPERATOR',
  globalOperator: 'GLOBAL_OPERATOR',
  studyOperator: 'STUDY_OPERATOR',
  scienceOperator: 'SCIENCE_OPERATOR',
  sportOperator: 'SPORT_OPERATOR',
  creationOperator: 'CREATION_OPERATOR',
  cultureOperator: 'CULTURE_OPERATOR',
  civilOperator: 'CIVIL_OPERATOR',
  professionOperator: 'PROFESSION_OPERATOR',
  employeeOo: 'EMPLOYEE_O_O',
  adminOo: 'ADMIN_O_O',
  teacherOo: 'TEACHER_O_O',
  adminOperator: 'ADMIN_OPERATOR',
  adminReadOnly: 'ADMIN_R_O',
};

const allSections = [
  SectionCodes.study,
  SectionCodes.science,
  SectionCodes.sport,
  SectionCodes.creation,
  SectionCodes.culture,
  SectionCodes.civil,
  SectionCodes.profession,
];

export const OperatorLocalRoleSections: Record<string, number[]> = {
  [LocalRoleTags.globalOperator]: allSections,
  [LocalRoleTags.studyOperator]: [SectionCodes.study],
  [LocalRoleTags.scienceOperator]: [SectionCodes.science],
  [LocalRoleTags.sportOperator]: [SectionCodes.sport],
  [LocalRoleTags.creationOperator]: [SectionCodes.creation],
  [LocalRoleTags.cultureOperator]: [SectionCodes.culture],
  [LocalRoleTags.civilOperator]: [SectionCodes.civil],
  [LocalRoleTags.professionOperator]: [SectionCodes.profession],
};

export const mainPorfolioRoleTags = [LocalRoleTags.studentOperator, LocalRoleTags.agentOperator];

export const operatorRoleTags = [
  LocalRoleTags.globalOperator,
  LocalRoleTags.studyOperator,
  LocalRoleTags.scienceOperator,
  LocalRoleTags.sportOperator,
  LocalRoleTags.creationOperator,
  LocalRoleTags.cultureOperator,
  LocalRoleTags.civilOperator,
  LocalRoleTags.professionOperator,
];

export const employeeRoleTags = [LocalRoleTags.employeeOo, LocalRoleTags.adminOo, LocalRoleTags.teacherOo];

export const adminRoleTags = [LocalRoleTags.adminOperator, LocalRoleTags.adminReadOnly];

// export const RoleMetrikaCodes: Record<string, number> = {
//   [RoleKeys.PARENT]: 85548340,
//   [RoleKeys.STUDENT]: 85544086,
//   [RoleKeys.EMPLOYEE]: 85548397,
//   [RoleKeys.EMPLOYEE_O_O]: 87585674,
//   [RoleKeys.ADMIN_O_O]: 87585659
// };

// export const OUTER_PARENT_METRIKA_CODE = 39054700;
// export const OUTER_STUDENT_METRIKA_CODE = 39054715;
