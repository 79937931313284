import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const Language: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="230" height="129" viewBox="0 0 230 129" xmlns="http://www.w3.org/2000/svg" {...props} fill="none">
    {/*eslint-disable max-len */}
    <path
      d="M86.09 125.36h-44v-22.71l33.36-1 17.16-9.47-6.56 12.29.04 20.89Zm-43-1h42v-20.15l5.09-9.53-14.42 7.95h-.12l-32.51 1-.04 20.73ZM212.631 117.64h-45a.504.504 0 0 1-.354-.146.504.504 0 0 1-.146-.354v-15a.501.501 0 0 1 .5-.5h45a.498.498 0 0 1 .5.5v15a.513.513 0 0 1-.5.5Zm-44.5-1h44v-14h-44v14Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M214.631 102.64h-57a.504.504 0 0 1-.354-.146.504.504 0 0 1-.146-.354v-6a.503.503 0 0 1 .5-.5h57a.498.498 0 0 1 .5.5v6a.513.513 0 0 1-.5.5Zm-56.5-1h56v-5h-56v5Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M207.631 117.64a.504.504 0 0 1-.354-.146.504.504 0 0 1-.146-.354v-15a.501.501 0 0 1 1 0v15a.513.513 0 0 1-.5.5ZM211.631 102.64a.504.504 0 0 1-.354-.146.504.504 0 0 1-.146-.354v-6a.503.503 0 0 1 .5-.5.498.498 0 0 1 .5.5v6a.513.513 0 0 1-.5.5ZM209.631 102.64a.504.504 0 0 1-.354-.146.504.504 0 0 1-.146-.354v-6a.503.503 0 0 1 .5-.5.498.498 0 0 1 .5.5v6a.513.513 0 0 1-.5.5ZM166.631 102.64a.504.504 0 0 1-.354-.146.504.504 0 0 1-.146-.354v-6a.503.503 0 0 1 .5-.5.498.498 0 0 1 .5.5v6a.513.513 0 0 1-.5.5ZM205.631 117.64a.504.504 0 0 1-.354-.146.504.504 0 0 1-.146-.354v-15a.501.501 0 0 1 1 0v15a.513.513 0 0 1-.5.5ZM170.631 117.64a.504.504 0 0 1-.354-.146.504.504 0 0 1-.146-.354v-15a.501.501 0 0 1 1 0v15a.513.513 0 0 1-.5.5ZM172.631 117.64a.504.504 0 0 1-.354-.146.504.504 0 0 1-.146-.354v-15a.501.501 0 0 1 1 0v15a.513.513 0 0 1-.5.5Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M209.631 96.64h-44a.5.5 0 0 1-.5-.5v-6.25a.503.503 0 0 1 .5-.5h44a.498.498 0 0 1 .5.5v6.25a.513.513 0 0 1-.5.5Zm-43.5-1h43v-5.25h-43v5.25Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M194.761 90.39h-35.13a.5.5 0 0 1-.5-.5V78.18a.503.503 0 0 1 .5-.5h35.13a.499.499 0 0 1 .5.5v11.71a.5.5 0 0 1-.5.5Zm-34.63-1h34.13V78.68h-34.13v10.71Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M190.85 90.39a.5.5 0 0 1-.5-.5V78.18a.499.499 0 1 1 1 0v11.71a.5.5 0 0 1-.5.5ZM206.68 95.85a.501.501 0 0 1-.5-.5v-4.68a.498.498 0 0 1 .853-.354.5.5 0 0 1 .147.354v4.68a.5.5 0 0 1-.5.5ZM177.34 95.85a.497.497 0 0 1-.5-.5v-4.68a.5.5 0 0 1 1 0v4.68a.503.503 0 0 1-.5.5ZM189.289 90.39a.5.5 0 0 1-.5-.5V78.18a.5.5 0 0 1 1 0v11.71a.503.503 0 0 1-.5.5ZM162 90.39a.5.5 0 0 1-.5-.5V78.18a.503.503 0 0 1 .5-.5.501.501 0 0 1 .5.5v11.71a.503.503 0 0 1-.5.5ZM163.529 90.39a.5.5 0 0 1-.5-.5V78.18a.5.5 0 0 1 1 0v11.71a.498.498 0 0 1-.5.5ZM38 73.93a23.14 23.14 0 1 1 20.14-11.71l5 11.3-10-5.2A23.09 23.09 0 0 1 38 73.93Zm0-45.29a22.15 22.15 0 1 0 14.71 38.71l.25-.23 8.1 4.2-4-9.16.13-.22A22.15 22.15 0 0 0 38 28.64ZM111.15 99.93a41.05 41.05 0 1 1-.06-82.1 41.05 41.05 0 0 1 .06 82.1Zm.06-81a40.001 40.001 0 1 0 18.875 75.252A40.005 40.005 0 0 0 150.83 53.63a40.113 40.113 0 0 0-39.62-34.71v.01Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M111.18 99.64c-8.88 0-16.1-18.4-16.1-41s7.22-41 16.1-41c8.88 0 16.1 18 16.1 41 0 22.6-7.22 41-16.1 41Zm0-81c-8.33 0-15.1 17.94-15.1 40s6.77 40 15.1 40 15.1-18 15.1-40-6.77-40-15.1-40Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="m112.12 99.63-.19-1c.29-.06 29.2-6.37 29.2-42.51 0-16.82-7.92-26.12-14.56-30.95a38.873 38.873 0 0 0-14.63-6.56l.17-1c.3.05 30 5.45 30 38.5.02 37.01-29.69 43.45-29.99 43.52ZM109.529 99.63c-.3-.07-30-6.51-30-43.49 0-33 29.71-38.45 30-38.5l.17 1c-.29.05-29.18 5.32-29.18 37.51 0 18.89 7.93 29.43 14.58 35a37.348 37.348 0 0 0 14.62 7.56l-.19.92Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M111.18 63.64c-17.92 0-32.5-10.32-32.5-23s14.58-23 32.5-23 32.5 10.31 32.5 23-14.58 23-32.5 23Zm0-45c-17.37 0-31.5 9.86-31.5 22s14.13 22 31.5 22c17.37 0 31.5-9.87 31.5-22s-14.13-22-31.5-22Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M111.131 81.64c-22.33 0-40.5-13-40.5-29h1c0 15.43 17.72 28 39.5 28s39.5-12.57 39.5-28h1c0 15.99-18.17 29-40.5 29Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M111.131 92.64c-17.94 0-33.94-8.61-38.91-20.92l.93-.38c4.82 12 20.44 20.3 38 20.3s33-8.27 37.9-20.1l.92.38c-5.08 12.2-21.04 20.72-38.84 20.72ZM111.18 46.64C97.86 46.64 87 40.13 87 32.14s10.83-14.5 24.15-14.5c13.32 0 24.15 6.5 24.15 14.5s-10.8 14.5-24.12 14.5Zm0-28C98.41 18.64 88 24.69 88 32.14s10.38 13.5 23.15 13.5c12.77 0 23.15-6.06 23.15-13.5s-10.3-13.5-23.12-13.5Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M111.18 32.64c-9 0-16-3.3-16-7.5s7-7.5 16-7.5 16 3.29 16 7.5-7.01 7.5-16 7.5Zm0-14c-8.15 0-15 3-15 6.5s6.88 6.5 15 6.5c8.12 0 15-3 15-6.5s-6.86-6.5-15-6.5ZM152.26 46.36l6.69-12.53V12.92h43.96v21.04h-1V13.92h-41.96v20.16l-4.96 9.29 14.29-9.38h19.3v1h-19l-17.32 11.37Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M185.511 32.62a12.089 12.089 0 0 1-9.38-9.42l1-.13c0 .27 1 6.7 8.63 8.58l-.25.97Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M176.131 32.64v-1c6 0 9-12.49 9-12.62l1 .23c-.13.54-3.13 13.39-10 13.39Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="m172.479 19.63-.19-1a79.54 79.54 0 0 1 19.38-.9l-.09 1a77.546 77.546 0 0 0-19.1.9Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M179.001 17.47v.44c-.008.201.037.401.13.58a.995.995 0 0 0 .31.48c.133.142.298.251.48.32.179.09.379.132.58.12l.4-.05c.25-.07.478-.205.66-.39l.24-.3c.133-.232.202-.494.2-.76v-.44a1.075 1.075 0 0 0-.13-.58 1.145 1.145 0 0 0-.31-.48 1.079 1.079 0 0 0-.48-.31 1.152 1.152 0 0 0-.58-.13l-.4.06c-.248.07-.475.2-.66.38l-.23.3c-.137.23-.21.492-.21.76Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M110.43 17.59c0-7.43-8.4-13.45-18.75-13.45s-18.75 6-18.75 13.45c0 7.45 8.39 13.41 18.75 13.41 2.496.037 4.983-.3 7.38-1l5.37 3.54-1.12-5.36c4.34-2.51 7.12-6.32 7.12-10.59Z"
      fill="#fff"
    />
    <path
      d="m105.169 34.57-6.17-4.07a25.557 25.557 0 0 1-7.3 1c-10.62 0-19.25-6.26-19.25-13.95 0-7.69 8.61-13.91 19.23-13.91 10.62 0 19.25 6.25 19.25 14 0 4.21-2.57 8.13-7.05 10.79l1.29 6.14Zm-6-5.16 4.56 3-1-4.53.32-.18c4.36-2.48 6.86-6.17 6.86-10.11 0-7.14-8.19-12.95-18.25-12.95s-18.25 5.81-18.25 13 8.19 13 18.25 13c2.45.007 4.886-.35 7.23-1.06l.28-.17ZM59.13 106.34h-1v15.33h1v-15.33Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="m58.985 105.5-.708.707.962.961.707-.707-.961-.961ZM59.228 120.846l-.955.955.707.707.955-.955-.707-.707ZM69.67 106.82h-1v15.33h1v-15.33Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="m69.516 105.989-.707.707.962.961.707-.707-.962-.961ZM69.778 121.326l-.962.962.707.707.962-.962-.707-.707Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M69.17 106.32H58.63v1h10.54v-1ZM69.17 113.99H58.63v1h10.54v-1ZM69.17 120.7H58.63v1h10.54v-1ZM181.211 65.05h-1.13a3.282 3.282 0 0 1-3.27-3.27V51.13a3.28 3.28 0 0 1 3.27-3.27h1.13a3.28 3.28 0 0 1 3.27 3.27v10.65a3.28 3.28 0 0 1-3.27 3.27Zm-1.13-16.19a2.273 2.273 0 0 0-2.27 2.27v10.65a2.272 2.272 0 0 0 2.27 2.27h1.13a2.27 2.27 0 0 0 2.27-2.27V51.13a2.273 2.273 0 0 0-2.27-2.27h-1.13Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M175.411 52.49a3.32 3.32 0 0 0-2.86 3.29v2.58a3.327 3.327 0 0 0 2.9 3.29 2.806 2.806 0 0 1 0-.43l-.04-8.73Z"
      fill="#fff"
    />
    <path
      d="M175.391 62.15a3.84 3.84 0 0 1-3.34-3.79v-2.58a3.839 3.839 0 0 1 3.29-3.78l.57-.09v9.31c-.005.09-.005.18 0 .27l.09.65-.61.01Zm-.48-9a2.844 2.844 0 0 0-1.86 2.65v2.58a2.813 2.813 0 0 0 1.86 2.62v-7.85ZM206.519 65.05h-1.13a3.28 3.28 0 0 1-3.27-3.27V51.13a3.28 3.28 0 0 1 3.27-3.27h1.13a3.277 3.277 0 0 1 3.27 3.27v10.65a3.276 3.276 0 0 1-3.27 3.27Zm-1.13-16.19a2.276 2.276 0 0 0-1.605.665 2.265 2.265 0 0 0-.665 1.605v10.65a2.271 2.271 0 0 0 2.27 2.27h1.13a2.269 2.269 0 0 0 2.27-2.27V51.13a2.269 2.269 0 0 0-2.27-2.27h-1.13Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M211.19 60.42a3.323 3.323 0 0 0 2.86-3.28v-2.59a3.327 3.327 0 0 0-2.9-3.29c.01.143.01.287 0 .43l.04 8.73Z"
      fill="#fff"
    />
    <path
      d="M210.69 61v-9.31c.005-.09.005-.18 0-.27l-.09-.65h.63a3.832 3.832 0 0 1 3.34 3.78v2.59a3.853 3.853 0 0 1-3.29 3.78l-.59.08Zm1-9.09v7.87a2.827 2.827 0 0 0 1.86-2.64v-2.59a2.828 2.828 0 0 0-1.86-2.64ZM193 72.18v-1c7.21 0 10.21-1.81 11.46-3.33a4.067 4.067 0 0 0 1-3.2l1-.2a5.049 5.049 0 0 1-1.18 4c-2.03 2.48-6.16 3.73-12.28 3.73Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M198.42 70.46v-1.49a2.5 2.5 0 0 0-2.5-2.5h-1.82a2.5 2.5 0 0 0-2.5 2.5v1.49a2.5 2.5 0 0 0 2.5 2.5h1.82a2.5 2.5 0 0 0 2.5-2.5Z"
      fill="#fff"
    />
    <path
      d="M195.91 73.47h-1.82a2.998 2.998 0 0 1-3-3V69a2.999 2.999 0 0 1 3-3h1.82a2.998 2.998 0 0 1 3 3v1.49a3 3 0 0 1-3 2.98ZM194.09 67a2 2 0 0 0-2 2v1.49a2 2 0 0 0 2 2h1.82a1.998 1.998 0 0 0 2-2V69a2 2 0 0 0-2-2h-1.82ZM205.461 48.4c-.04-.4-1.05-10.4-12.46-10.4s-11.84 10-11.86 10.39h-1c0-.12.49-11.36 12.86-11.36s13.44 11.22 13.45 11.33l-.99.04Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default Language;
