/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconChevronSmallUp: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M16.977 15.768 12 11.5l-4.977 4.268a.883.883 0 0 1-1.308-.128A1.043 1.043 0 0 1 5.5 15c0-.286.114-.57.334-.768l5.572-5c.043-.039.095-.059.143-.088.039-.024.072-.053.115-.071a.859.859 0 0 1 .332-.072L12 9l.004.001c.113 0 .225.027.332.072.043.018.076.047.115.071.049.029.1.049.143.088l5.572 5c.393.353.447.983.119 1.408a.883.883 0 0 1-1.308.128Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconChevronSmallUp;
