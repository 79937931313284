/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useState, useEffect, useMemo, useContext } from 'react';

import { connect } from 'react-redux';
import { ThemeProvider, useMediaQuery } from '@mui/material';
import { toast } from 'portfolio3/ui-kit';
import { cloneDeep, debounce, isEqual } from 'lodash';

import {
  IUserContext,
  IPostPortfolioEvent,
  IEventKind,
  IPostPortfolioReward,
  IPostPortfolioProject,
  IPostPortfolioEmployment,
  IPostPortfolioAffilation,
  ICommonResponse,
  ILocation,
  IChildInfo,
  ICreationKind,
  IPostPortfolioSportReward,
  IRewardKind,
  ICulturalKind,
  ISportReward,
  IAffilationKind,
  IDictionaryItem,
} from 'api/types';
import {
  DifferentValue,
  StatusRewardCodes,
  SectionCodes,
  OtherOrganizationCode,
  DifferentCode
} from 'const';
import { IRootState } from 'reducers';
import {
  editPortfolioAffilationActions,
  editPortfolioEmploymentActions,
  editPortfolioEventActions,
  editPortfolioGiaWorldSkillsActions,
  editPortfolioProjectActions,
  editPortfolioRewardActions,
  editPortfolioSportRewardActions,
  postStudentMetaSkillsActions,
  postPortfolioAffilationActions,
  postPortfolioEmploymentActions,
  postPortfolioEventActions,
  postPortfolioGiaWorldSkillsActions,
  postPortfolioProjectActions,
  postPortfolioRewardActions,
  postPortfolioSportRewardActions,
  postPortfolioJobActions,
  editPortfolioJobActions,
  postPortfolioSpoDocumentActions,
  editPortfolioSpoDocumentActions
} from 'actions';
import {
  getDataEntryFormHeaderText, convertDateToServerDate, getCategoryAccentColor, getCategoryProperties, getFormDataResetAction, getFormDataEditAction, getDataLoadRequests, isDefined, getEntityCardIllustration
} from 'utils';
import { IQueryParams } from 'api';
import { DataEntryFormStep, DataEntryFormTab, mapLinkablePersonObjectsToEntityLinkedObjects, IInnerFormProps, IModifiedPersonObject, IPortfolioDataEntryFormErrors, IPortfolioEntryFormData, linkablePersonObjectsSelector } from 'portfolio3/features/dataEntryForm';
import { isDataEntryFormValid } from './validation';
import { getEditEntityHandler, getPostEntityHandler, GetSendEntityHandlerParameters, getMappedPostGiaWorldSkillsData, getMappedPostJobData, getMappedPostSpoDocumentFactory } from './sendHandlers';
import { useAppSelector, useBrightTheme, useDataLoad, useUserSourceCode } from 'hooks';
import { defaultPortfolioEntryFormData, defaultPortfolioEntryFormErrors } from "./utils";
import { LocalDataEntryFormContext } from './context/localDataEntryFormContext';

import { MetaSkillRefState, ProfessionProgrammRefState, SectionRefState, SpoOrganizationRefState } from 'reducers/reference';
import { AccentColorType } from 'portfolio3/styles';
import { FormEntityHoverActions, FormEntityTags } from 'portfolio3/components/dataEntry';

import StudentFormDesktop from './components/StudentFormDesktop';
import StudentFormMobile from './components/StudentFormMobile';
import EmployeeForm from './components/EmployeeForm';
import { useRelatedObject, useScienceEntityProperties, useMappedEventData, useTabs, useStudyEntityProperties, useSportEntityProperties, useCreationEntityProperties, useCultureEntityProperties, useCivilEntityProperties, useProfessionEntityProperties } from './hooks';
import { commonTheme, generateAccentTheme } from 'portfolio3/styles/theme';
import { availableEmployeeParentSectionsSelector, sectionRefArchiveFalseSelector } from 'selectors';
import { getMappedFormData, serializeInterval } from 'portfolio3/features/dataEntryForm';
import { RendererContext } from 'context';
import { getYMEventPayload } from 'portfolio3/features/yandexMetrika';
import { WidgetDataContext } from 'portfolio3/components/common/WidgetContainer';

const getYandexMetrikaRewardAddingEventPayload = (sections: SectionRefState['content'], typeCode: number) => {
  const selectRewardTypeValue = sections.find((section) => section.code === typeCode)?.value;

  const payload = getYMEventPayload({type: 'rewardAddingSuccessfull', payload: {
    rewardSections: selectRewardTypeValue ?? '',
  }});
  return payload;
};

const getYandexMetrikaEntityAddingEventPayload = (widgetLabel: string | null) => {
  const payload = getYMEventPayload({type: 'addEntitySuccessfull', payload: {
    Subsections: widgetLabel ?? '',
  }});
  return payload;
};

interface IPortfolioDataEntryFormProps {
  isOpen: boolean;
  portfolioParentSections: ICommonResponse<IDictionaryItem>,
  currentUser: IUserContext;
  eventKinds: ICommonResponse<IEventKind>
  affilationKinds: ICommonResponse<IAffilationKind>;
  creationKinds: ICommonResponse<ICreationKind>
  culturalKinds: ICommonResponse<ICulturalKind>;
  sportClubs: ICommonResponse<ILocation>;
  sportRewards: ICommonResponse<ISportReward>;
  rewardKinds: ICommonResponse<IRewardKind>;
  metaSkillRef: MetaSkillRefState;
  spoOrganizationRef: SpoOrganizationRefState;
  professionProgrammRef: ProfessionProgrammRefState;
  currentStudent: IChildInfo;
  queryParams?: IQueryParams;
  excludedEventTypeOptions?: number[];
  onFormChanged: (isChanged: boolean) => void;
  initialFormData?: Partial<IModifiedPersonObject>;
  onClose: (type: 'submit' | 'close') => void
  onOpenEventForm: (categoryCode: number, dataKind: number) => void;

  postPortfolioEvent: typeof postPortfolioEventActions.request;
  editPortfolioEvent: typeof editPortfolioEventActions.request;
  postPortfolioReward: typeof postPortfolioRewardActions.request;
  postPortfolioSportReward: typeof postPortfolioSportRewardActions.request;
  editPortfolioSportReward: typeof editPortfolioSportRewardActions.request;
  editPortfolioReward: typeof editPortfolioRewardActions.request;
  postPortfolioProject: typeof postPortfolioProjectActions.request;
  editPortfolioProject: typeof editPortfolioProjectActions.request;
  postPortfolioEmployment: typeof postPortfolioEmploymentActions.request;
  editPortfolioEmployment: typeof editPortfolioEmploymentActions.request;
  postPortfolioAffilation: typeof postPortfolioAffilationActions.request;
  postStudentMetaSkills: typeof postStudentMetaSkillsActions.request;
  editPortfolioAffilation: typeof editPortfolioAffilationActions.request;
  postPortfolioGiaWorldSkills: typeof postPortfolioGiaWorldSkillsActions.request;
  editPortfolioGiaWorldSkills: typeof editPortfolioGiaWorldSkillsActions.request;
  postPortfolioSpoDocument: typeof postPortfolioSpoDocumentActions.request;
  editPortfolioSpoDocument: typeof editPortfolioSpoDocumentActions.request;
  postPorfolioJob: typeof postPortfolioJobActions.request;
  editPortfolioJob: typeof editPortfolioJobActions.request;
}

const PortfolioDataEntryForm: React.FC<IPortfolioDataEntryFormProps> = ({
  isOpen,
  portfolioParentSections,
  currentUser,
  eventKinds,
  affilationKinds,
  sportClubs,
  sportRewards,
  rewardKinds,
  metaSkillRef,
  spoOrganizationRef,
  professionProgrammRef,
  currentStudent,
  queryParams,
  excludedEventTypeOptions,
  onFormChanged,
  initialFormData,
  onClose,
  onOpenEventForm,

  postPortfolioEvent,
  editPortfolioEvent,
  postPortfolioReward,
  postPortfolioSportReward,
  editPortfolioReward,
  editPortfolioSportReward,
  postPortfolioProject,
  editPortfolioProject,
  postPortfolioEmployment,
  editPortfolioEmployment,
  postPortfolioAffilation,
  editPortfolioAffilation,
  postPortfolioGiaWorldSkills,
  editPortfolioGiaWorldSkills,
  postPortfolioSpoDocument,
  editPortfolioSpoDocument,
  postPorfolioJob,
  editPortfolioJob,
  postStudentMetaSkills,
}) => {
  const store = useAppSelector((state) => state);
  const linkablePersonObjects = useAppSelector(linkablePersonObjectsSelector);
  const isAdminViewing = useAppSelector((state) => state.adminViewMode.isViewing);
  const portfolioSections = useAppSelector(sectionRefArchiveFalseSelector);

  const { currentRenderer } = useContext(RendererContext);
  const isBrightTheme = useBrightTheme();
  const sourceCode = useUserSourceCode();
  const { widgetLabel } = useContext(WidgetDataContext);

  const studentType = currentRenderer === 'main' || isAdminViewing;

  const [formStep, setFormStep] = useState<DataEntryFormStep>(DataEntryFormStep.PRIMARY);
  const [formTab, setFormTab] = useState<DataEntryFormTab>(DataEntryFormTab.GENERAL);
  const [isEmployeeFormRolled, setEmployeeFormRolled] = useState(false);

  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));
  const [formData, setFormData] = useState<IPortfolioEntryFormData>(defaultPortfolioEntryFormData);
  const [formErrors, setFormErrors] = useState<IPortfolioDataEntryFormErrors>(defaultPortfolioEntryFormErrors);
  const [isSubmitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const sectionCodesString = portfolioParentSections.content?.map((section) => `&categoryCode=${section.code}`).join('');

  const DEBOUNCE = 500;

  const creatorId = currentUser.data?.staffId || String(currentUser.data?.userId);

  const { categoryCode, dataKind, typeCode } = formData;
  const typeCodeOrDataKind = typeCode ?? dataKind;
  const accentColor: AccentColorType = categoryCode && isBrightTheme ? getCategoryAccentColor(categoryCode) : 'indigo';

  const loadRequests = getDataLoadRequests();
  const skipCheck = { visibilitySettingsCheck: false };
  useDataLoad({ shouldLoad: isOpen, requests: [...loadRequests.dataEntryMain, ...loadRequests.sectionsRef], ...skipCheck });
  useDataLoad({ shouldLoad: categoryCode === SectionCodes.study, requests: loadRequests.dataEntryStudy, ...skipCheck });
  useDataLoad({ shouldLoad: categoryCode === SectionCodes.science, requests: loadRequests.dataEntryScience, ...skipCheck });
  useDataLoad({ shouldLoad: categoryCode === SectionCodes.sport, requests: loadRequests.dataEntrySport, ...skipCheck });
  useDataLoad({ shouldLoad: categoryCode === SectionCodes.creation, requests: loadRequests.dataEntryCreation, ...skipCheck });
  useDataLoad({ shouldLoad: categoryCode === SectionCodes.culture, requests: loadRequests.dataEntryCulture, ...skipCheck });
  useDataLoad({ shouldLoad: categoryCode === SectionCodes.civil, requests: loadRequests.dataEntryCivil, ...skipCheck });
  useDataLoad({ shouldLoad: categoryCode === SectionCodes.profession, requests: loadRequests.dataEntryProfession, ...skipCheck });

  const sendEntityHandlerParameters: GetSendEntityHandlerParameters = [currentStudent.meshId, creatorId, sourceCode, queryParams, studentType, sectionCodesString, formData];
  const handleEditEntity = useMemo(() => getEditEntityHandler.apply(null, sendEntityHandlerParameters), [sendEntityHandlerParameters]);
  const handlePostEntity = useMemo(() => getPostEntityHandler.apply(null, sendEntityHandlerParameters), [sendEntityHandlerParameters]);

  useEffect(() => {
    if (isOpen) {
      setFormTab(DataEntryFormTab.GENERAL);
    }
  }, [isOpen]);

  useEffect(() => {
    return () => {
      setFormData(defaultPortfolioEntryFormData);
      setSubmitButtonDisabled(false);
    };
  }, []);

  useEffect(() => {
    if (!formData.categoryCode && portfolioParentSections.content.length > 0) {
      setFormData({
        ...defaultPortfolioEntryFormData,
        categoryCode: portfolioParentSections.content[0]?.code
      });
    }
  }, [formData.categoryCode]);

  useEffect(() => {
    if (!initialFormData) {
      setFormData({
        ...defaultPortfolioEntryFormData,
        categoryCode: portfolioParentSections.content[0]?.code
      });
    }
  }, [portfolioParentSections.content]);

  useEffect(() => {
    if (!initialFormData) {
      setFormData(defaultPortfolioEntryFormData);
      return;
    }

    setFormData(getMappedFormData(initialFormData, store, linkablePersonObjects));
  }, [initialFormData]);

  const {
    name,
    secondName,
    subjectId,
    format,
    startDate,
    date,
    endDate,
    classStart,
    classEnd,
    description,
    age,
    attachment,
    eventLevel,
    linkedObjects,
    place,
    workCode,
    placeName,
    organizator,
    participant,
    entityId,
    entityType,
    sportRewardCode,
    result,
    profile,
    reward,
    rewardNumber,
    subCategory,
    sportKind,
    tourismKind,
    eventNumber,
    actionDate,
    actionStage,
    stageEvent,
    stageStartDate,
    stageEndDate,
    creationKindCode,
    olympiadLevel,
    address,
    expireDate,
    organizationCategory,
    teamName,
    teamStartDate,
    teamEndDate,
    trainingStageCode,
    medicalClearanceFromDate,
    medicalClearanceToDate,
    isPresentationCompetence,
    participantCategory,
    profession,
  } = formData;

  const attachmentResponse = attachment.map(file => file.response).filter(isDefined);

  const entityLinkedObjects = mapLinkablePersonObjectsToEntityLinkedObjects(linkedObjects);
  const linkedObjectIds = entityLinkedObjects && entityLinkedObjects.length > 0 ? entityLinkedObjects.map((object) => object.entityId).join('; ') : undefined;
  const subjectIds = subjectId.join(', ');

  const organizators = organizator.filter((organizator) => organizator).join('; ');

  const getSubspecies = () => {
    if (categoryCode === SectionCodes.sport) {
      return subjectIds.length > 0 ? subjectIds : undefined;
    }
    return undefined;
  };

  // TODO перенести на новые обработчики
  const handleSendEventFormData = () => {
    if (!categoryCode || !dataKind || !typeCode) return;

    const getEventName = (): string | undefined => {
      if (name === DifferentCode) return secondName;
      return eventKinds?.content?.find((eventKind) => eventKind.code === name)?.value;
    };

    const getEventKindCode = () => {
      return name === DifferentCode ? undefined : Number(name);
    };

    const eventFormData: IPostPortfolioEvent = {
      personId: currentStudent.meshId,
      categoryCode,
      dataKind,
      typeCode,
      sourceCode: sourceCode!,
      creatorId,
      name: getEventName(),
      subjectCode: dataKind === SectionCodes.studyEvent ? subjectIds : undefined,
      disciplineCode: dataKind === SectionCodes.scienceEvent ? subjectIds : undefined,
      ageLimit: serializeInterval(classStart, classEnd),
      formatCode: format,
      startDate: convertDateToServerDate(startDate) || null,
      endDate: convertDateToServerDate(endDate) || null,
      parallelsCode: serializeInterval(classStart, classEnd),
      levelOlympiadCode: olympiadLevel,
      description,
      levelEventCode: eventLevel,
      linkedObjectIds,
      linkedObjects: entityLinkedObjects,
      location: place,
      locationName: placeName,
      organizators: organizators!,
      workCode,
      participantNumber: participant,
      profile,
      result,
      eventNumber,
      actionStage: actionStage!,
      actionDate: convertDateToServerDate(actionDate) || null,
      stageEvent: stageEvent!,
      subspecies: getSubspecies(),
      stageStartDate: convertDateToServerDate(stageStartDate) || null,
      stageEndDate: convertDateToServerDate(stageEndDate) || null,
      creationKindCode,
      sportKindCode: sportKind,
      tourismKindCode: tourismKind,
      subcategoryCode: subCategory,
      eventKindCode: getEventKindCode(),
      isImport: false,
      isDelete: false,
      isPresentationCompetence:
        categoryCode === SectionCodes.profession ? Boolean(isPresentationCompetence) : undefined,
      participantCategory,
      profession,
    };

    if (initialFormData?.id) {
      editPortfolioEvent(eventFormData, initialFormData.id, attachmentResponse, queryParams, studentType, sectionCodesString);
    } else {
      const ymMeta = getYandexMetrikaEntityAddingEventPayload(widgetLabel);
      postPortfolioEvent(eventFormData, attachmentResponse, queryParams, studentType, sectionCodesString, ymMeta);
    }
    onClose('submit');
  };

  const handleSendProjectFormData = () => {
    if (!categoryCode || !dataKind) return;

    const projectFormData: IPostPortfolioProject = {
      personId: currentStudent.meshId,
      categoryCode,
      dataKind,
      sourceCode: sourceCode!,
      creatorId,
      linkedObjectIds,
      linkedObjects: entityLinkedObjects,
      startDate: convertDateToServerDate(startDate),
      endDate: convertDateToServerDate(endDate),
      name: String(name),
      description,
      subcategoryCode: subCategory,
      disciplineCode: subjectIds,
      levelProjectCode: eventLevel,
      projectFormatCode: format,
      isImport: false,
      isDelete: false
    };

    if (initialFormData?.id) {
      editPortfolioProject(projectFormData, initialFormData.id, attachmentResponse, queryParams, studentType, sectionCodesString);
    } else {
      const ymMeta = getYandexMetrikaEntityAddingEventPayload(widgetLabel);
      postPortfolioProject(projectFormData, attachmentResponse, queryParams, studentType, sectionCodesString, ymMeta);
    }
    onClose('submit');
  };

  const handleSendOccupationFormData = () => {
    if (!categoryCode || !dataKind || !typeCode) return;

    const occupationSubspecies = getSubspecies();
    if (!creatorId) return;

    const getDisciplines = () => {
      if (categoryCode === SectionCodes.sport) return;
      return subjectIds;
    };

    const occupationFormData: IPostPortfolioEmployment = {
      personId: currentStudent.meshId,
      categoryCode,
      dataKind,
      typeCode,
      sourceCode: sourceCode!,
      creatorId,
      linkedObjectIds,
      linkedObjects: entityLinkedObjects,
      startDate: convertDateToServerDate(startDate) || null,
      endDate: convertDateToServerDate(endDate) || null,
      name: secondName || '',
      description,
      subcategoryCode: subCategory,
      disciplineCode: getDisciplines(),
      achievementActivityFormatCode: format,
      subspecies: occupationSubspecies,
      ageLimit: serializeInterval(classStart, classEnd),
      location: place,
      sportKindCode: sportKind,
      tourismKindCode: tourismKind,
      creationKindCode,
      isImport: false,
      isDelete: false
    };

    if (initialFormData?.id) {
      editPortfolioEmployment(occupationFormData, initialFormData.id, attachmentResponse, queryParams, studentType, sectionCodesString);
    } else {
      const ymMeta = getYandexMetrikaEntityAddingEventPayload(widgetLabel);
      postPortfolioEmployment(occupationFormData, attachmentResponse, queryParams, studentType, sectionCodesString, ymMeta);
    }
    onClose('submit');
  };

  const handleSendAffilationFormData = () => {
    if (!categoryCode || !dataKind || !typeCode) return;

    const getAffilationName = (): string | undefined => {
      if (categoryCode === SectionCodes.sport) {
        const sportNameObject = sportClubs?.content?.find((sportClub) => sportClub.code === name);
        return sportNameObject?.code === OtherOrganizationCode ? secondName : sportNameObject?.value;
        // return !name || sportName === DifferentValue ? secondName : sportName;
      }
      const eventName = affilationKinds?.content?.find((eventKind) => eventKind.code === name)?.value;
      return !name || eventName === DifferentValue ? secondName : eventName;
    };

    const getAffilationKindCode = () => {
      if (categoryCode === SectionCodes.creation) {
        return affilationKinds?.content?.find((affilation) => affilation.categoryCode === typeCode)?.code;
      }

      if (categoryCode === SectionCodes.civil && typeof formData.name === 'number') {
        return formData.name;
      }
    };

    const affilationSubspecies = getSubspecies();
    const affilationName = getAffilationName();
    const affilationKindCode = getAffilationKindCode();

    const affilationFormData: IPostPortfolioAffilation = {
      personId: currentStudent.meshId,
      sourceCode: sourceCode!,
      creatorId,
      categoryCode,
      dataKind,
      typeCode,
      linkedObjectIds,
      linkedObjects: entityLinkedObjects,
      subspecies: affilationSubspecies,
      subcategoryCode: subCategory,
      ageLimit: serializeInterval(classStart, classEnd),
      affilationLevelCode: eventLevel,
      creationKindCode,
      sportKindCode: sportKind,
      tourismKindCode: tourismKind,
      affilationKindCode,
      name: affilationName,
      address,
      description,
      startDate: convertDateToServerDate(startDate) || null,
      endDate: convertDateToServerDate(endDate) || null,
      status: formData.status,
      organizationCategory,
      teamName,
      teamStartDate: convertDateToServerDate(teamStartDate) || null,
      teamEndDate: convertDateToServerDate(teamEndDate) || null,
      trainingStageCode,
      medicalClearanceFromDate: convertDateToServerDate(medicalClearanceFromDate) || null,
      medicalClearanceToDate: convertDateToServerDate(medicalClearanceToDate) || null,

      isImport: false,
      isDelete: false
    };

    if (initialFormData?.id) {
      editPortfolioAffilation(affilationFormData, initialFormData.id, attachmentResponse, queryParams, studentType, sectionCodesString);
    } else {
      const ymMeta = getYandexMetrikaEntityAddingEventPayload(widgetLabel);
      postPortfolioAffilation(affilationFormData, attachmentResponse, queryParams, studentType, sectionCodesString, ymMeta);
    }
    onClose('submit');
  };

  const handleSendRewardFormData = () => {
    if (!categoryCode || !dataKind || !typeCode) return;

    const getRewardName = (): string | undefined => {
      let rewardName: string | undefined;
      if (typeCode === SectionCodes.civilRewardStatus) {
        rewardName = rewardKinds.content?.find((rewardKind) => rewardKind.code === name)?.value;
      } else {
        rewardName = rewardKinds.content?.find((rewardKind) => rewardKind.code === reward)?.value;
      }

      if (rewardName?.trim() === DifferentValue) return undefined;
      return rewardName;
    };
    const rewardName = getRewardName() || secondName;

    const getStatusRewardCode = (section?: number, rewardType?: number): number => {
      if (section === SectionCodes.study) return StatusRewardCodes.constant;
      if (section === SectionCodes.science) return StatusRewardCodes.once;
      if (section === SectionCodes.creation) return StatusRewardCodes.once;
      return StatusRewardCodes.constant;
    };

    const rewardFormData: IPostPortfolioReward = {
      personId: currentStudent.meshId,
      categoryCode,
      dataKind,
      typeCode,
      sourceCode: sourceCode!,
      statusRewardCode: getStatusRewardCode(categoryCode, reward),
      creatorId,
      entityId,
      entityType,
      rewardTypeCode: reward || Number(name),
      linkedObjectIds,
      linkedObjects: entityLinkedObjects,
      date: convertDateToServerDate(date),
      name: rewardName,
      ageLimit: serializeInterval(classStart, classEnd),
      subspecies: getSubspecies(),
      subcategoryCode: subCategory,
      levelRewardCode: eventLevel,
      rewardNumber,
      description,
      isImport: false,
      isDelete: false
    };

    if (initialFormData?.id) {
      editPortfolioReward(rewardFormData, initialFormData.id, attachmentResponse, queryParams, studentType, sectionCodesString);
    } else {
      const ymMeta = getYandexMetrikaRewardAddingEventPayload(portfolioSections.content, typeCode);
      postPortfolioReward(rewardFormData, attachmentResponse, queryParams, studentType, sectionCodesString, ymMeta);
    }
    onClose('submit');
  };

  const handleSendSportRewardData = () => {
    if (!categoryCode || !dataKind || !typeCode) return;

    const getSportRewardName = (): string | undefined => {
      if (categoryCode === SectionCodes.sport) {
        const sportName = sportRewards?.content?.find((rewardKind) => rewardKind.code === name)?.value.trim();
        return sportName !== DifferentValue ? sportName : secondName;
      }
    };
    const sportRewardName = getSportRewardName();

    const sportRewardFormData: IPostPortfolioSportReward = {
      personId: currentStudent.meshId,
      categoryCode,
      dataKind,
      typeCode,
      sourceCode: sourceCode!,
      creatorId,
      linkedObjectIds,
      linkedObjects: entityLinkedObjects,
      date: convertDateToServerDate(date),
      entityId,
      entityType,
      sportRewardCode,
      ageLimitCode: age,
      name: sportRewardName,
      subspecies: getSubspecies(),
      sportKindCode: sportKind,
      tourismKindCode: tourismKind,
      subcategoryCode: subCategory,
      levelRewardCode: eventLevel,
      rewardNumber,
      description,
      isImport: false,
      isDelete: false,
      expireDate: convertDateToServerDate(expireDate)
    };

    if (initialFormData?.id) {
      editPortfolioSportReward(sportRewardFormData, initialFormData.id, attachmentResponse, queryParams, studentType, sectionCodesString);
    } else {
      const ymMeta = getYandexMetrikaRewardAddingEventPayload(portfolioSections.content, typeCode);
      postPortfolioSportReward(sportRewardFormData, attachmentResponse, queryParams, studentType, sectionCodesString, ymMeta);
    }
    onClose('submit');
  };

  const handleSendGiaWorldSkillsData = () => {
    if (initialFormData?.id) {
      handleEditEntity(editPortfolioGiaWorldSkills, getMappedPostGiaWorldSkillsData, initialFormData.id);
    } else {
      const ymMeta = getYandexMetrikaEntityAddingEventPayload(widgetLabel);
      handlePostEntity(postPortfolioGiaWorldSkills, getMappedPostGiaWorldSkillsData, ymMeta);
    }
    onClose('submit');
  }

  const handleSendStudyDocumentData = () => {
    const getMappedPostSpoDocument = getMappedPostSpoDocumentFactory(spoOrganizationRef.content, professionProgrammRef.content);

    if (initialFormData?.id) {
      handleEditEntity(editPortfolioSpoDocument, getMappedPostSpoDocument, initialFormData.id);
    } else {
      const ymMeta = getYandexMetrikaEntityAddingEventPayload(widgetLabel);
      handlePostEntity(postPortfolioSpoDocument, getMappedPostSpoDocument, ymMeta);
    }
    onClose('submit');
  };

  const handleSendStudentMetaskills = () => {
    if (formData?.spoMetaskills) {
      const rootMetaSkills = metaSkillRef.content.filter((metaskill) => !metaskill.parentId);
      // фильтрует пустые корневые навыки
      const filteredMetaskills = formData.spoMetaskills.filter((metaskillCode, _, array) => {
        const rootMetaskillObject = rootMetaSkills.find((metaskill) => metaskill.code === metaskillCode);
        if (!rootMetaskillObject) return true;

        return array.some((localMetaskillCode) => {
          const metaskillObject = metaSkillRef.content.find((metaskill) => metaskill.code === localMetaskillCode);
          return metaskillObject?.parentId === rootMetaskillObject.code;
        });
      });

      postStudentMetaSkills(currentStudent.meshId, filteredMetaskills);
    }
    onClose('submit');
  }

  const handleSendProfessionJobData = () => {
    if (initialFormData?.id) {
      handleEditEntity(editPortfolioJob, getMappedPostJobData, initialFormData.id);
    } else {
      const ymMeta = getYandexMetrikaEntityAddingEventPayload(widgetLabel);
      handlePostEntity(postPorfolioJob, getMappedPostJobData, ymMeta);
    }
    onClose('submit');
  };

  const handleSendFormData = debounce(() => {
    if (dataKind === SectionCodes.studyEvent
      || dataKind === SectionCodes.scienceEvent
      || dataKind === SectionCodes.cultureEvent
      || dataKind === SectionCodes.creationEvent
      || dataKind === SectionCodes.sportEvent
      || dataKind === SectionCodes.civilEvent
      || dataKind === SectionCodes.professionEvent
    ) {
      handleSendEventFormData();
    } else if (dataKind === SectionCodes.studyReward
      || dataKind === SectionCodes.scienceReward
      || dataKind === SectionCodes.creationReward
      || dataKind === SectionCodes.civilReward
      || dataKind === SectionCodes.professionReward
    ) {
      handleSendRewardFormData();
    } else if (dataKind === SectionCodes.project) {
      handleSendProjectFormData();
    } else if (dataKind === SectionCodes.scienceOccupation
      || dataKind === SectionCodes.creationOccupation
      || dataKind === SectionCodes.sportOccupation
      || dataKind === SectionCodes.civilOccupation
    ) {
      handleSendOccupationFormData();
    } else if (dataKind === SectionCodes.creationAffilation
      || dataKind === SectionCodes.sportAffilation
      || dataKind === SectionCodes.civilAffilation
    ) {
      handleSendAffilationFormData();
    } else if (dataKind === SectionCodes.sportReward) {
      handleSendSportRewardData();
    } else if (dataKind === SectionCodes.professionExam) {
      handleSendGiaWorldSkillsData();
    } else if (typeCode === SectionCodes.studySpoDocuments) {
      handleSendStudyDocumentData();
    } else if (typeCode === SectionCodes.professionSpoMetaskills) {
      handleSendStudentMetaskills();
    } else if (typeCode === SectionCodes.professionSpoJob) {
      handleSendProfessionJobData();
    } else {
      toast.error('Не все обязательные поля заполнены');
    }
  }, DEBOUNCE);

  const normalizeFormDataObject = (formData: Record<string, unknown>): Record<string, unknown> => {
    const copy = cloneDeep(formData);

    for (let key in copy) {
      const value = copy[key];

      if (value === '' || value === undefined) {
        copy[key] = null;
      }

      const isArray = Array.isArray(value);

      if ((isArray && value.length === 1 && value[0] === '') || (isArray && value.length === 0)) {
        copy[key] = null;
      }

      if (value instanceof Date) {
        copy[key] = new Date(value.getFullYear(), value.getMonth() + 1, value.getDate());
      }

      if (key === 'attachment' && isArray) {
        copy[key] = value.map((file: unknown): unknown => {
          if (file && typeof file === 'object' && 'localId' in file) {
            return {
              ...file,
              localId: null,
            };
          }
          return file;
        });
      }
    }

    return copy;
  }

  const isFormChanged = () => {
    const skippedProperties: Partial<IPortfolioEntryFormData> = { typeCode: undefined };
    const checkedFormData = {
      ...formData,
      ...skippedProperties
    };
    const initialMappedFormData = {
      ...(initialFormData ? getMappedFormData(initialFormData, store, linkablePersonObjects) : undefined),
      ...skippedProperties
    };

    const normalizedFormData = normalizeFormDataObject(checkedFormData);
    const normalizedInitialFormData = normalizeFormDataObject(initialMappedFormData);

    return !isEqual(normalizedFormData, normalizedInitialFormData);
  };

  useEffect(() => {
    const isChanged = isFormChanged();

    const isValid = isDataEntryFormValid(
      formData,
      formErrors,
      eventKinds?.content || [],
      rewardKinds?.content || [],
      sportRewards?.content || [],
      affilationKinds?.content || [],
      sportClubs?.content || [],
      creatorId,
      sourceCode,
    );

    const isButtonActive = isValid && isChanged;

    setSubmitButtonDisabled(!isButtonActive);
    onFormChanged(isChanged);
  }, [formData]);

  const handleEditForm = () => {
    if (studentType) {
      const newFormData = getFormDataEditAction(formData)(formData);
      setFormData(newFormData);
      setFormErrors(defaultPortfolioEntryFormErrors);
    } else {
      setEmployeeFormRolled(true);
    }
  };

  const handleClearForm = () => {
    setFormData(studentType ? getFormDataResetAction(formData)(formData) : defaultPortfolioEntryFormData);
    setFormErrors(defaultPortfolioEntryFormErrors);
  };

  const handleChangeFormTab = (tab: DataEntryFormTab) => setFormTab(tab);
  const handleChangeFormStep = (step: DataEntryFormStep) => setFormStep(step);
  const handleChangeEmployeeFormRolled = (isRolled: boolean) => setEmployeeFormRolled(isRolled);

  const inputRenderMode = isMobile ? 'floating' : 'fixed';
  const inputSize = 'medium';

  const formPicture = isBrightTheme ? getEntityCardIllustration(typeCodeOrDataKind) : null;
  const formThemeAccent: AccentColorType = isBrightTheme && studentType ? accentColor : 'indigo';

  const mappedEventData = useMappedEventData({ formData });
  const { relatedObjectType, relatedObjectName } = useRelatedObject({ formData });
  const tabs = useTabs({ formData, isMobile });
  const studyProperties = useStudyEntityProperties({ formData, mappedEventData });
  const scienceProperties = useScienceEntityProperties({ formData, mappedEventData });
  const sportProperties = useSportEntityProperties({ formData, mappedEventData });
  const creationProperties = useCreationEntityProperties({ formData, mappedEventData });
  const cultureProperties = useCultureEntityProperties({ formData, mappedEventData });
  const civilProperties = useCivilEntityProperties({ formData, mappedEventData });
  const professionProperties = useProfessionEntityProperties({ formData, mappedEventData });

  const entityProperties = [
    studyProperties,
    scienceProperties,
    sportProperties,
    creationProperties,
    cultureProperties,
    civilProperties,
    professionProperties,
  ].find((props) => props) ?? null;
  const entityInfo = entityProperties?.entityInfo;

  const studentFormTitle = getDataEntryFormHeaderText(formData);
  const categoryProperties = categoryCode ? getCategoryProperties(categoryCode) : null;

  const isEntityActionsHidden = studentType && formData?.dataKind && (
    formData.dataKind === SectionCodes.project
    || formData.dataKind === SectionCodes.scienceOccupation
    || formData.dataKind === SectionCodes.creationAffilation
    || formData.dataKind === SectionCodes.civilOccupation
  );

  const headerTagsElement = entityInfo && (
    <FormEntityTags
      accentColor={categoryCode ? getCategoryAccentColor(categoryCode) : 'indigo'}
      categoryIcon={categoryProperties?.icon}
      categoryName={entityInfo.categoryName}
      typeName={entityInfo.typeName}
    />
  );
  const headerActionsElement = <FormEntityHoverActions onEdit={handleEditForm} onClear={handleClearForm} />;

  const innerProps: IInnerFormProps = {
    accentColor,
    entityActions: !isEntityActionsHidden && headerActionsElement,
    isSubmitDisabled: isSubmitButtonDisabled,
    entityProperties,
    tabs,
    relatedObjectType,
    relatedObjectName,
    onSubmit: handleSendFormData,
    onClose: () => onClose('close'),
  };

  return (
    <LocalDataEntryFormContext.Provider value={{
      tabs,
      formTab,
      onChangeFormTab: handleChangeFormTab,

      formStep,
      onChangeFormStep: handleChangeFormStep,

      isEmployeeFormRolled,
      onChangeEmployeeFormRolled: handleChangeEmployeeFormRolled,

      formData,
      onChangeFormData: setFormData,

      formErrors,
      onChangeFormErrors: setFormErrors,

      excludedEventTypeOptions,
      onOpenEventForm,

      studentType,
      isOpen,
      isMobile,
      inputRenderMode,
      inputSize,
      image: formPicture ?? undefined,
    }}>
      <ThemeProvider theme={generateAccentTheme(formThemeAccent)}>
        {studentType && isMobile && (
          <StudentFormMobile
            title={studentFormTitle}
            {...innerProps}
          />
        )}
        {studentType && !isMobile && (
          <StudentFormDesktop
            title={studentFormTitle}
            {...innerProps}
          />
        )}
        {!studentType && (
          <EmployeeForm
            studentName={`${currentStudent.lastname} ${currentStudent.firstname}`}
            headerTags={headerTagsElement}
            {...innerProps}
          />
        )}
      </ThemeProvider>
    </LocalDataEntryFormContext.Provider>
  );
};

export default connect(
  (state: IRootState) => ({
    portfolioParentSections: availableEmployeeParentSectionsSelector(state),
    affilationKinds: state.affilationKinds,
    creationKinds: state.creationKinds,
    culturalKinds: state.culturalKinds,
    eventKinds: state.eventKinds,
    currentUser: state.currentUser,
    sportClubs: state.sportClubs,
    sportRewards: state.sportRewardKinds,
    currentStudent: state.currentStudent,
    rewardKinds: state.rewardKinds,
    metaSkillRef: state.metaSkillRef,
    spoOrganizationRef: state.spoOrganizationRef,
    professionProgrammRef: state.professionProgrammRef,
  }),
  {
    postPortfolioEvent: postPortfolioEventActions.request,
    editPortfolioEvent: editPortfolioEventActions.request,
    postPortfolioReward: postPortfolioRewardActions.request,
    postPortfolioSportReward: postPortfolioSportRewardActions.request,
    editPortfolioReward: editPortfolioRewardActions.request,
    editPortfolioSportReward: editPortfolioSportRewardActions.request,
    postPortfolioProject: postPortfolioProjectActions.request,
    editPortfolioProject: editPortfolioProjectActions.request,
    postPortfolioEmployment: postPortfolioEmploymentActions.request,
    editPortfolioEmployment: editPortfolioEmploymentActions.request,
    postStudentMetaSkills: postStudentMetaSkillsActions.request,
    postPortfolioAffilation: postPortfolioAffilationActions.request,
    editPortfolioAffilation: editPortfolioAffilationActions.request,
    postPortfolioGiaWorldSkills: postPortfolioGiaWorldSkillsActions.request,
    editPortfolioGiaWorldSkills: editPortfolioGiaWorldSkillsActions.request,
    postPortfolioSpoDocument: postPortfolioSpoDocumentActions.request,
    editPortfolioSpoDocument: editPortfolioSpoDocumentActions.request,
    postPorfolioJob: postPortfolioJobActions.request,
    editPortfolioJob: editPortfolioJobActions.request,
  }
)(PortfolioDataEntryForm);
