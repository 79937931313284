/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useMemo } from 'react';

import { connect } from 'react-redux';

import { IRootState } from '../../../../reducers';
import { ICommonResponse } from '../../../../api/types';
import PdfCommonDataEntityContainer from '../common';
import { PdfCommonDataEntity } from '../../../../components/pdfStudent/DataEntity';
import { widgetPdfIcons } from '../../../../icons';
import { sportOccupationsSelector } from '../../../../selectors';
import { IEntity } from '../../../../types';

import '../index.scss';
import { useDataLoad } from 'hooks';
import { getDataLoadRequests } from 'utils';

interface IPdfSportSectionContainerProps {
  studentOccupations: ICommonResponse<IEntity>
}

const PdfSportSectionContainer: React.FC<IPdfSportSectionContainerProps> = ({
  studentOccupations
}) => {
  useDataLoad({ shouldLoad: true, requests: getDataLoadRequests().sportSections });

  const filteredOccupationsYear = useMemo(() => (studentOccupations.content && studentOccupations.content?.length > 0
    ? studentOccupations.content?.reduce((prevItem, nextItem) => (prevItem.year > nextItem.year ? prevItem : nextItem)).year
    : undefined),
  [studentOccupations]);

  return (
    <PdfCommonDataEntityContainer
      title="Кружки и секции"
      icon={widgetPdfIcons.pdfIconSportUnits}
    >
      {studentOccupations.content
        .map((olympiad: IEntity) => (
          <PdfCommonDataEntity key={olympiad.id || olympiad.recordId} entity={olympiad} year={filteredOccupationsYear} />
        ))}
    </PdfCommonDataEntityContainer>
  );
};

export default connect(
  (state: IRootState) => ({
    studentOccupations: sportOccupationsSelector(state)
  })
)(PdfSportSectionContainer);
