import { ICommonResponse } from 'api/types';
import { IRootState } from 'reducers';
import { createSelector } from 'reselect';

import { LOWEST_SUBJECT_GRADE } from '../const';
import { IPerformanceSubjectTopic, IPerformanceSubjectTopicInfo } from '../types';

export const isPerformanceWidgetHasDataSelector = createSelector(
  [(state: IRootState) => state.studentPerformanceOld],
  (studentPerformance) => {
    const performance = studentPerformance.content?.year ?? [];
    const hasData = performance.length > 0;

    const totalSubjects = performance.flatMap((year) => year.subjects);
    const hasAnyAverageMarks = totalSubjects.some((subject) => subject.averageMark >= LOWEST_SUBJECT_GRADE);
    const hasAnyFinalMarks = totalSubjects.some((subject) => subject.averageYearMark >= LOWEST_SUBJECT_GRADE);

    // Есть данные и есть подходящие average или final оценки
    return hasData && (hasAnyAverageMarks || hasAnyFinalMarks);
  },
);

export const subjectTopicsSelector = createSelector(
  [(state: IRootState) => state.studentPerformanceLessonsOld],
  (studentPerformanceLessons): ICommonResponse<IPerformanceSubjectTopicInfo> => {
    const subjectTopics = studentPerformanceLessons.content.map((subjectLessons): IPerformanceSubjectTopicInfo => {
      const topics =
        subjectLessons.topics?.map(
          (subjectTopic): IPerformanceSubjectTopic => ({
            name: subjectTopic.lessonTopic,
            totalLessons: subjectTopic.learningLessonNumber,
            passedLessons: subjectTopic.learningPassedLessonNumber,
            averageResult: subjectTopic.averageMark,
          }),
        ) || [];

      return {
        learningYear: subjectLessons.learningYear || '',
        totalTopics: subjectLessons.numberAllTopic || 0,
        doneTopics: subjectLessons.numberDoneTopic || 0,
        percentDoneTopics: subjectLessons.percentDoneTopic || 0,
        topics,
      };
    });

    return {
      content: subjectTopics,
      loading: studentPerformanceLessons.loading || false,
    };
  },
);
