import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconAlertTriangleFilled: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M13 13a1 1 0 0 1-2 0V9a1 1 0 0 1 2 0v4Zm-1 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm10.561-.697L14.889 3.584C14.29 2.592 13.21 2 12 2c-1.21 0-2.29.592-2.889 1.584L1.439 16.303a2.976 2.976 0 0 0-.045 3.033C1.973 20.363 3.098 21 4.328 21h15.344c1.23 0 2.355-.637 2.934-1.664.54-.956.523-2.09-.045-3.033Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconAlertTriangleFilled;
