import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const BriefCase: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.125 8.4375H16.875V15.75C16.875 16.3713 16.3713 16.875
      15.75 16.875H2.25C1.62868 16.875 1.125 16.3713 1.125 15.75V8.4375Z"
      fill="#8C5543"
    />
    <path
      d="M6.24376 5.0625H2.25C1.62868 5.0625 1.125 5.56618 1.125
      6.1875V8.4375C1.125 9.68014 2.13236 10.6875 3.375 10.6875H14.625C15.8676
      10.6875 16.875 9.68014 16.875 8.4375V6.1875C16.875 5.56618 16.3713 5.0625
      15.75 5.0625H11.7562C11.4957 3.77874 10.3607 2.8125 9 2.8125C7.63934 2.8125
      6.50435 3.77874 6.24376 5.0625ZM7.40852 5.0625C7.64018 4.40708 8.26525
       3.9375 9 3.9375C9.73475 3.9375 10.3598 4.40708 10.5915 5.0625H7.40852Z"
      fill="#6D4534"
    />
    <path
      d="M7.3125 10.6875L9 10.125L10.6875
      10.6875V11.8125C10.6875 12.1232 10.4357 12.375 10.125
       12.375H7.875C7.56434 12.375 7.3125 12.1232 7.3125 11.8125V10.6875Z"
      fill="#D3883E"
    />
    <path
      d="M7.3125 10.125C7.3125 9.81434 7.56434 9.5625 7.875
      9.5625H10.125C10.4357 9.5625 10.6875 9.81434 10.6875
      10.125V10.6875H7.3125V10.125Z"
      fill="#E19747"
    />
  </SvgIcon>
);

export default BriefCase;
