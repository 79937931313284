/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import { InputProps, Input as InputMaterial } from '@mui/material';
import { Colors } from '../../../style/variables';

import './index.scss';

const useStyles = makeStyles({
  input: ({ multiline, error }: InputProps) => ({
    marginTop: '8px',
    boxSizing: 'border-box',
    border: '1px solid',
    borderColor: error ? Colors['red-rose'] : Colors['control-stroke'],
    borderRadius: '8px',
    outline: 'none',
    padding: '8px 16px',
    height: !multiline ? '40px' : undefined,

    '& input': {
      padding: multiline ? '6px 0 7px' : 'initial',
      marginBottom: '1px'
    },
    '& textarea': {
      padding: multiline ? '6px 0 7px' : 'initial'
    },
    '&.Mui-focused': {
      borderColor: Colors['blue-primrose']
    }
  })
});

const Input: React.FC<InputProps> = (props) => {
  const classes = useStyles(props);
  const { placeholder, multiline } = props;

  return (
    <InputMaterial
      margin="none"
      disableUnderline
      classes={{
        root: classes.input
      }}
      className="input"
      placeholder={placeholder}
      multiline={multiline}
      rows={multiline ? 4 : undefined}
      {...props}
    />
  );
};

export default Input;
