import { FC, PropsWithChildren } from 'react';

import { Box } from '@mui/material';
import { SxStyles } from 'types';
import { mergeSx } from 'utils';

import { WidgetContainerContext } from '../context/widgetContainerContext';
import { WidgetDataContext } from '../context/widgetDataContext';
import WidgetContainerDivider from '../Divider';
import WidgetContainerHeader from '../Header';
import WidgetContainerPadding from '../Padding';
import WidgetContainerSubHeader from '../SubHeader';
import {
  IWidgetContainerDividerProps,
  IWidgetContainerHeaderProps,
  IWidgetContainerPaddingProps,
  WidgetContainerSubHeaderProps,
} from '../types';
import * as styles from './styles';

interface IWidgetContainerRootComposition {
  Divider: FC<IWidgetContainerDividerProps>;
  Header: FC<IWidgetContainerHeaderProps>;
  SubHeader: FC<WidgetContainerSubHeaderProps>;
  Padding: FC<IWidgetContainerPaddingProps>;
}

interface IWidgetContainerRootProps {
  sx?: SxStyles;
  containerData: { id: string; label: string };
  withBackground?: boolean;
}

const WidgetContainerRoot: FC<IWidgetContainerRootProps & PropsWithChildren> & IWidgetContainerRootComposition = ({
  sx,
  containerData,
  withBackground,
  children,
}) => {
  return (
    <WidgetDataContext.Provider
      value={{
        widgetLabel: containerData.label,
      }}
    >
      <WidgetContainerContext.Provider
        value={{
          withBackground: withBackground ?? false,
        }}
      >
        <Box className="widget-container" id={containerData.id} sx={mergeSx(styles.root, sx)}>
          {children}
        </Box>
      </WidgetContainerContext.Provider>
    </WidgetDataContext.Provider>
  );
};

WidgetContainerRoot.Divider = WidgetContainerDivider;
WidgetContainerRoot.Header = WidgetContainerHeader;
WidgetContainerRoot.SubHeader = WidgetContainerSubHeader;
WidgetContainerRoot.Padding = WidgetContainerPadding;

export default WidgetContainerRoot;
