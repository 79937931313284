import { ComponentType, FC, PropsWithChildren } from 'react';

import { useMediaQuery } from '@mui/material';
import PaddingContainer from 'portfolio3/components/common/PaddingContainer';
import { commonTheme } from 'portfolio3/styles/theme';

const WithStudentPadding: FC<PropsWithChildren> = ({ children }) => {
  // контентный отступ включается в момент перехода fixed -> fluid
  // на мобилках отступ по краям экрана отсутствует
  const useBasePadding = useMediaQuery(commonTheme.breakpoints.between('commonSm', 'commonXl'));
  const padding = useBasePadding ? undefined : 0;

  return (
    <>
      <PaddingContainer padding={padding}>{children}</PaddingContainer>
    </>
  );
};

const withStudentPaddingContainer = () => {
  return <P extends PropsWithChildren<unknown>>(WrappedComponent: ComponentType<P>): FC<P> => {
    return function WidthStudentPaddingContainer({ ...props }) {
      return (
        <WithStudentPadding>
          <WrappedComponent {...props} />
        </WithStudentPadding>
      );
    };
  };
};

export default withStudentPaddingContainer;
