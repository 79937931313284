import { FAILURE, INVALIDATE, REQUEST, SUCCESS } from 'actions/utils';
import { ICommonResponse, ICommonResponseObject, ILearningYearPerformance, ISubjectThemePerformance } from 'api/types';
import { IReducers } from 'reducers';
import genericReducers from 'reducers/genericReducer';
import { connectReducers } from 'utils';

import {
  GET_STUDENT_AVERAGE_GRADES,
  GET_STUDENT_FINAL_GRADES,
  GET_STUDENT_SUBJECT_THEMES,
  getStudentSubjectThemesActions,
  INVALIDATE_STUDENT_SUBJECT_THEMES_CACHE,
} from './actions';

export type StudentFinalGradesState = ICommonResponse<ILearningYearPerformance>;
export const studentFinalGrades = createReducer(GET_STUDENT_FINAL_GRADES);

export type StudentAverageGradesState = ICommonResponse<ILearningYearPerformance>;
export const studentAverageGrades = createReducer(GET_STUDENT_AVERAGE_GRADES);

// student subject themes
export type StudentSubjectThemesState = ICommonResponseObject<ISubjectThemePerformance | null>;
const subjectThemesInitialState: StudentSubjectThemesState = {
  content: null,
  loading: false,
  requestCache: 'notCached',
};
export const studentSubjectThemes: IReducers<StudentSubjectThemesState> = {
  [GET_STUDENT_SUBJECT_THEMES[REQUEST]]: genericReducers().get.request,
  [GET_STUDENT_SUBJECT_THEMES[SUCCESS]]: genericReducers().get.success,
  [GET_STUDENT_SUBJECT_THEMES[FAILURE]]: genericReducers().get.failure,
  [GET_STUDENT_SUBJECT_THEMES[INVALIDATE]]: genericReducers().get.invalidate(subjectThemesInitialState),
};

// student subject themes cache
export type StudentSubjectThemesCacheState = Record<number, ISubjectThemePerformance>;
const subjectThemesCacheInitialState: StudentSubjectThemesCacheState = {};
export const studentSubjectThemesCache: IReducers<StudentSubjectThemesCacheState> = {
  [GET_STUDENT_SUBJECT_THEMES[SUCCESS]]: (state, action: ReturnType<typeof getStudentSubjectThemesActions.success>) => {
    const themePerformance = action.payload.data;
    const subjectId = themePerformance?.subjectId;

    if (!subjectId || themePerformance.theme.length === 0) {
      return state;
    }

    return {
      ...state,
      [subjectId]: themePerformance,
    };
  },
  [INVALIDATE_STUDENT_SUBJECT_THEMES_CACHE]: () => subjectThemesCacheInitialState,
};

export const collection = {
  studentFinalGrades,
  studentAverageGrades,
  studentSubjectThemes: connectReducers(subjectThemesInitialState, studentSubjectThemes),
  studentSubjectThemesCache: connectReducers(subjectThemesCacheInitialState, studentSubjectThemesCache),
};

function createReducer<DataType>(actions: Record<string, string>) {
  const initialState: ICommonResponse<DataType> = {
    content: [],
    loading: false,
    requestCache: 'notCached',
  };

  const reducer: IReducers<ICommonResponse<DataType>> = {
    [actions[REQUEST]]: genericReducers().get.request,
    [actions[SUCCESS]]: genericReducers().get.success,
    [actions[FAILURE]]: genericReducers().get.failure,
    [actions[INVALIDATE]]: genericReducers().get.invalidate(initialState),
  };

  return connectReducers(initialState, reducer);
}
