/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';
import genericReducers from '../genericReducer';
import { IReducers } from '../index';

import { GET_REWARD_KINDS } from '../../actions/employees';
import { REQUEST, FAILURE, SUCCESS } from '../../actions/utils';
import { ICommonResponse, IRewardKind } from '../../api/types';

export type RewardKindState = ICommonResponse<IRewardKind>;

const initialState: RewardKindState = {
  content: [],
  loading: false,
};

const rewardKinds: IReducers<RewardKindState> = {
  [GET_REWARD_KINDS[REQUEST]]: genericReducers().get.request,
  [GET_REWARD_KINDS[SUCCESS]]: genericReducers().get.success,
  [GET_REWARD_KINDS[FAILURE]]: genericReducers().get.failure
};

export default connectReducers(initialState, rewardKinds);
