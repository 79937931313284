import { ComponentType, FC, PropsWithChildren, useContext, useEffect } from 'react';

import { RendererContext } from 'context';
import { RendererType } from 'types';

interface IWithRendererTypeContainerProps {
  rendererType: RendererType;
}

const WithRendererTypeContainer: FC<IWithRendererTypeContainerProps & PropsWithChildren> = ({
  rendererType,
  children,
}) => {
  const { setCurrentRenderer } = useContext(RendererContext);

  useEffect(() => {
    setCurrentRenderer(rendererType);
  }, [rendererType, setCurrentRenderer]);

  return <>{children}</>;
};

const withRendererType = (rendererType: RendererType) => {
  return <P extends PropsWithChildren<unknown>>(WrappedComponent: ComponentType<P>): FC<P> => {
    return function withRendererType({ ...props }) {
      return (
        <WithRendererTypeContainer rendererType={rendererType}>
          <WrappedComponent {...props} />
        </WithRendererTypeContainer>
      );
    };
  };
};

export default withRendererType;
