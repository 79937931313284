/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';
import genericReducers from '../genericReducer';
import { IReducers } from '../index';

import { GET_SPORT_KINDS } from '../../actions/employees';
import { REQUEST, FAILURE, SUCCESS } from '../../actions/utils';

import { ICommonResponse, IDictionaryItem } from '../../api/types';

export type SportKindsState = ICommonResponse<IDictionaryItem>;

const initialState = {} as ICommonResponse<IDictionaryItem>;

const sportKinds: IReducers<SportKindsState> = {
  [GET_SPORT_KINDS[REQUEST]]: genericReducers().get.request,
  [GET_SPORT_KINDS[SUCCESS]]: genericReducers().get.success,
  [GET_SPORT_KINDS[FAILURE]]: genericReducers().get.failure
};

export default connectReducers(initialState, sportKinds);
