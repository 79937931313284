/* eslint-disable prettier/prettier */
/* eslint-disable */

import { isDefined } from '.';
import { IAdminSettingSection, IDictionaryItem } from '../api/types';
import {
  AdminParentSectionSettingCodeToSectionRef,
  SectionCodes,
} from '../const';
import {
  IconRouteProfile,
  IconRouteCivil,
  IconRouteCreation,
  IconRouteCulture,
  IconRouteScience,
  IconRouteSport,
  IconRouteStudy,
  IconRouteProfession,
  IconRouteRecommendation
} from '../icons/routeSections';

import {
  IconSchool,
  IconScience,
  IconSport,
  IconStar,
  IconCreation,
  IconCulture,
  IconProfession,
  IconRecommendation
} from '../icons/sections';

const ORDERED_SECTION_CODES = [
  SectionCodes.study,
  SectionCodes.science,
  SectionCodes.sport,
  SectionCodes.creation,
  SectionCodes.culture,
  SectionCodes.civil,
  SectionCodes.profession,
  SectionCodes.profile,
  SectionCodes.recommendation,
];

export const getSectionRouteSvgIcon = (sectionCode: number) => {
  switch (sectionCode) {
    case SectionCodes.profile: return IconRouteProfile;
    case SectionCodes.study: return IconRouteStudy;
    case SectionCodes.science: return IconRouteScience;
    case SectionCodes.sport: return IconRouteSport;
    case SectionCodes.creation: return IconRouteCreation;
    case SectionCodes.culture: return IconRouteCulture;
    case SectionCodes.civil: return IconRouteCivil;
    case SectionCodes.profession: return IconRouteProfession;
    case SectionCodes.recommendation: return IconRouteRecommendation;
    default: return IconRouteStudy;
  }
};

export const getSectionSvgIcon = (categoryCode: number) => {
  switch (categoryCode) {
    case SectionCodes.profile: return IconSchool;
    case SectionCodes.study: return IconSchool;
    case SectionCodes.science: return IconScience;
    case SectionCodes.sport: return IconSport;
    case SectionCodes.creation: return IconCreation;
    case SectionCodes.culture: return IconCulture;
    case SectionCodes.civil: return IconStar;
    case SectionCodes.profession: return IconProfession;
    case SectionCodes.recommendation: return IconRecommendation;
    default: return IconSchool;
  }
};

export const getSortedParentSections = (sections: IDictionaryItem[]) => {
  return ORDERED_SECTION_CODES.map((sectionCode): IDictionaryItem | null => {
    const currentSection = sections.find((section) => section.code === sectionCode);
    if (!currentSection) return null;

    return currentSection;
  }).filter(isDefined);
};

export const getSortedParentAdminSections = (sections: IAdminSettingSection[]) => {
  return ORDERED_SECTION_CODES.map((sectionCode): IAdminSettingSection | null => {
    const currentSection = sections.find((section) => AdminParentSectionSettingCodeToSectionRef[section.sectionId] === sectionCode);
    if (!currentSection) return null;

    return currentSection;
  }).filter(isDefined);
};

export const filterParentSectionRefCodes = (code: number) => {
  return ORDERED_SECTION_CODES.includes(code);
};

export const sortParentSectionRefCodes = (code1: number, code2: number): number => {
  const firstCodeIndex = ORDERED_SECTION_CODES.findIndex((code) => code === code1);
  const secondCodeIndex = ORDERED_SECTION_CODES.findIndex((code) => code === code2);
  return firstCodeIndex - secondCodeIndex;
};
