/* eslint-disable max-len */
import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconProfileInterestsCommon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.751 3H5.241A3.74 3.74 0 0 0 1.5 6.741v8.735a3.74 3.74 0 0 0 3.741 3.741h2.322v4.013c0 .719.885 1.07 1.375.543l4.241-4.556h5.581a3.74 3.74 0 0 0 3.741-3.74V6.75a3.74 3.74 0 0 0-3.741-3.741L18.751 3Zm-6.545 12.993a.337.337 0 0 1-.219.08.354.354 0 0 1-.219-.08c-2.76-2.365-4.853-3.89-4.853-5.914 0-1.603 1.139-2.812 2.646-2.812 1.279 0 2.04.893 2.418 1.542.385-.649 1.139-1.542 2.418-1.542 1.507 0 2.646 1.209 2.646 2.812 0 2.024-2.085 3.549-4.854 5.914h.018Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconProfileInterestsCommon;
