import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import { routes } from 'const';

import Auth from './containers/common/Auth';
import BuildConfigLoader from './containers/common/BuildConfigLoader';
import MainContainer from './containers/common/MainContainer';
import MainSettingsLoader from './containers/common/MainSettingsLoader';
import { ServiceErrorPage } from './pages';
import RendererRoutes from './RendererRoutes';

const Routes: FC = () => {
  return (
    <Switch>
      <Route path={routes.SERVICE_ERROR.path} exact>
        <ServiceErrorPage />
      </Route>
      <Route path="*">
        <BuildConfigLoader>
          <Auth>
            <MainSettingsLoader>
              <MainContainer>
                <RendererRoutes />
              </MainContainer>
            </MainSettingsLoader>
          </Auth>
        </BuildConfigLoader>
      </Route>
    </Switch>
  );
};

export default Routes;
