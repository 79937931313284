import { IRootState } from 'reducers';
import { createSelector } from 'reselect';
import { IIndependentDiagnosticData } from 'types';
import { isDefined } from 'utils';

const independentDiagnosticListSelector = createSelector(
  [(state: IRootState) => state.independentDiagnostics],
  (independentDiagnostics) => {
    const learningYearsDiagnostics = independentDiagnostics.content?.filter((diagnosticObject) =>
      Number(diagnosticObject.learningYear.split('-')[0]),
    );

    const diagnosticObjects = learningYearsDiagnostics
      ?.flatMap((diagnosticGroup) => {
        const newDiagnosticObjects = diagnosticGroup.diagnostics.diagnostic?.map((diagnostic) => {
          return {
            ...diagnostic,
            learingYear: diagnosticGroup.learningYear,
          };
        });

        return newDiagnosticObjects;
      })
      .filter(isDefined);

    const diagnosticData = diagnosticObjects
      ?.map((diagnosticInfo): IIndependentDiagnosticData | null => {
        return {
          ...diagnosticInfo,
          learningYear: diagnosticInfo.learingYear,
          visibilitySettingValue: diagnosticInfo?.isVisible ?? false,
        };
      })
      .filter(isDefined)
      .sort((left, right) => {
        const leftDate = new Date(left.eventDate);
        const rightDate = new Date(right.eventDate);
        return Number(rightDate) - Number(leftDate);
      });

    return {
      ...independentDiagnostics,
      content: diagnosticData || [],
    };
  },
);

export default independentDiagnosticListSelector;
