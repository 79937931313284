import { FC } from 'react';

import { Box } from '@mui/material';
import { IconClose } from 'icons/edit';
import { SxStyles } from 'types';

interface IDrawerCrossProps {
  onClick?: () => void;
  sx?: SxStyles;
}

const DrawerCross: FC<IDrawerCrossProps> = ({ sx, onClick }) => {
  return (
    <Box className="drawer-cross" sx={sx}>
      <IconClose onClick={onClick} />
    </Box>
  );
};

export default DrawerCross;
