import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root: SxStyles = {
  width: '508px',
  height: 'max-content',
};

export const infoModalContent: SxStyles = {
  display: 'flex',
  gap: '12px',

  padding: '24px 20px',
};

export const infoModalButtons: SxStyles = {
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '12px',

  padding: '12px 20px',

  borderTop: `1px solid ${NeutralColors.light_neutrals_300}`,
  backgroundColor: NeutralColors.light_neutrals_100,
};

export const contentBlock: SxStyles = {
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
};
