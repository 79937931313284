/* eslint-disable max-len */
import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconFileFilled: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 3.978 17.742 8h-3.028C14.32 8 14 7.619 14 7.15V3.978Zm5.741 3.35-4.445-5a.998.998 0 0 0-.74-.328h-8C5.146 2 4 3.122 4 4.5v15C4 20.878 5.146 22 6.556 22h10.888C18.854 22 20 20.878 20 19.5V8a.998.998 0 0 0-.259-.672Z"
      fill={props.fill || 'currentColor'}
    />
    <mask id="a" maskUnits="userSpaceOnUse" x="4" y="2" width="16" height="20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 3.978 17.742 8h-3.028C14.32 8 14 7.619 14 7.15V3.978Zm5.741 3.35-4.445-5a.998.998 0 0 0-.74-.328h-8C5.146 2 4 3.122 4 4.5v15C4 20.878 5.146 22 6.556 22h10.888C18.854 22 20 20.878 20 19.5V8a.998.998 0 0 0-.259-.672Z"
        fill="#fff"
      />
    </mask>
  </SvgIcon>
);

export default IconFileFilled;
