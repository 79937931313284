/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

const IconGrossMaster: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="50" height="50" fill="#E5E5E5"/>
    <rect width="1924" height="1397" transform="translate(-1231 -649)" fill="white"/>
    <rect width="50" height="50" fill="#E83F40"/>
    <path d="M44.612 2H5.38794V36.9024C5.38794 39.0623 6.23117 41.1338 7.73212 42.661C9.23308 44.1883 11.2688 45.0463 13.3915 45.0463H36.6085C38.7311 45.0463 40.7669 44.1883 42.2678 42.661C43.7688 41.1338 44.612 39.0623 44.612 36.9024V2Z" fill="#969696"/>
    <path d="M10.5668 13.2167H8.2666V20.0192H9.77988V17.7129H10.6071C12.0195 17.7129 13.2234 17.0286 13.2234 15.4066C13.2234 13.7847 12.033 13.2167 10.5668 13.2167ZM10.513 16.4879H9.77988V14.4348H10.4524C11.273 14.4348 11.7236 14.6949 11.7236 15.4066C11.7236 16.1184 11.347 16.4879 10.513 16.4879Z" fill="#747778"/>
    <path d="M16.7072 13.0936C14.898 13.0936 13.6672 14.3665 13.6672 16.5907C13.6672 18.8148 14.9115 20.122 16.7072 20.122C18.503 20.122 19.7405 18.7875 19.7405 16.5633C19.7405 14.3391 18.5097 13.0936 16.7072 13.0936ZM16.7072 18.8217C15.7925 18.8217 15.2141 17.9525 15.2141 16.5907C15.2141 15.2288 15.7925 14.4212 16.7072 14.4212C17.6219 14.4212 18.1936 15.2219 18.1936 16.5907C18.1936 17.9594 17.6017 18.8217 16.7072 18.8217Z" fill="#747778"/>
    <path d="M23.4531 18.8216C22.4779 18.8216 21.8389 18.0072 21.8389 16.6043C21.8389 15.2014 22.5586 14.4212 23.48 14.4212C23.7017 14.4268 23.9201 14.4775 24.1222 14.5704C24.3244 14.6632 24.5062 14.7963 24.657 14.9618L25.4573 13.9627C25.1962 13.6856 24.8831 13.4646 24.5365 13.313C24.1899 13.1614 23.8169 13.0821 23.4396 13.0798C21.7448 13.0798 20.2786 14.387 20.2786 16.6454C20.2786 18.9037 21.7044 20.1219 23.3993 20.1219C23.8097 20.1303 24.2167 20.0448 24.5903 19.8719C24.964 19.699 25.2947 19.443 25.5582 19.1227L24.7646 18.1578C24.6044 18.3563 24.4047 18.5181 24.1787 18.6325C23.9526 18.747 23.7053 18.8114 23.4531 18.8216Z" fill="#747778"/>
    <path d="M28.6453 18.8216C27.6903 18.8216 27.0177 18.0072 27.0177 16.6043C27.0177 15.2014 27.7441 14.4212 28.6588 14.4212C28.8815 14.4266 29.1009 14.4773 29.3042 14.5701C29.5074 14.663 29.6905 14.7961 29.8425 14.9618L30.6496 13.9627C30.3889 13.6849 30.0759 13.4636 29.7292 13.3119C29.3825 13.1602 29.0093 13.0813 28.6319 13.0798C26.9437 13.0798 25.4775 14.387 25.4775 16.6454C25.4775 18.9038 26.8899 20.1356 28.5848 20.1356C28.9961 20.1435 29.404 20.0579 29.7786 19.8851C30.1533 19.7122 30.4853 19.4565 30.7505 19.1364L29.9501 18.1715C29.7894 18.3667 29.5902 18.5254 29.3655 18.6374C29.1407 18.7494 28.8954 18.8121 28.6453 18.8216Z" fill="#747778"/>
    <path d="M33.3734 16.6043L32.8622 17.6171C32.8622 17.6171 32.8622 16.4195 32.8622 15.6188V13.2167H31.3691V20.0192H32.9698L34.7252 16.5974L35.3978 15.1124H35.4382C35.3642 15.8652 35.2431 16.7754 35.2431 17.5761V19.9918H36.7362V13.2167H35.1288L33.3734 16.6043Z" fill="#747778"/>
    <path d="M40.2471 13.2167C38.828 13.2167 37.6644 13.7163 37.6644 15.3314C37.6407 15.764 37.7549 16.1928 37.99 16.5541C38.2251 16.9154 38.5685 17.1898 38.9692 17.3365L37.2407 20.0192H38.9692L40.3883 17.5692H41.1416V20.0192H42.6549V13.2167H40.2471ZM41.1416 16.351H40.3749C39.5745 16.351 39.1239 16.0157 39.1239 15.3314C39.1239 14.647 39.5476 14.4348 40.348 14.4348H41.1416V16.351Z" fill="#747778"/>
    <path d="M10.2976 12.9361H8.00415V19.7797H9.53088V17.4392H10.338C11.7571 17.4392 12.961 16.7548 12.961 15.1261C12.961 13.4973 11.7705 12.9361 10.2976 12.9361ZM10.2505 16.2142H9.53088V14.1611H10.2034C11.024 14.1611 11.4679 14.4143 11.4679 15.1261C11.4679 15.8378 11.0845 16.2142 10.2505 16.2142Z" fill="#D8D8D8"/>
    <path d="M16.4381 12.8129C14.6356 12.8129 13.4048 14.0858 13.4048 16.31C13.4048 18.5342 14.6356 19.8687 16.4381 19.8687C18.2405 19.8687 19.4781 18.5342 19.4781 16.31C19.4781 14.0858 18.2742 12.8129 16.4381 12.8129ZM16.4381 18.541C15.5301 18.541 14.9517 17.6787 14.9517 16.31C14.9517 14.9413 15.5301 14.1406 16.4381 14.1406C17.346 14.1406 17.9312 14.9481 17.9312 16.31C17.9312 17.6719 17.3527 18.541 16.4381 18.541Z" fill="#D8D8D8"/>
    <path d="M23.191 18.541C22.209 18.541 21.5701 17.7266 21.5701 16.3305C21.5701 14.9344 22.2965 14.1406 23.2112 14.1406C23.6604 14.1547 24.086 14.349 24.3949 14.6812L25.1952 13.6958C24.9332 13.4199 24.6199 13.1998 24.2735 13.0482C23.9271 12.8967 23.5546 12.8167 23.1775 12.8129C21.4894 12.8129 20.0232 14.1201 20.0232 16.3785C20.0232 18.6368 21.4423 19.8687 23.1372 19.8687C23.5473 19.8756 23.9539 19.7895 24.3273 19.6167C24.7007 19.4439 25.0317 19.1886 25.2961 18.8695L24.4958 17.9046C24.3361 18.0999 24.1369 18.258 23.9117 18.3678C23.6865 18.4776 23.4407 18.5367 23.191 18.541Z" fill="#D8D8D8"/>
    <path d="M28.3627 18.541C27.3875 18.541 26.7486 17.7266 26.7486 16.3305C26.7486 14.9344 27.4682 14.1406 28.3896 14.1406C28.8369 14.155 29.2602 14.3494 29.5666 14.6812L30.3804 13.6958C30.1184 13.4199 29.8051 13.1998 29.4587 13.0482C29.1123 12.8967 28.7398 12.8167 28.3627 12.8129C26.6679 12.8129 25.2017 14.1201 25.2017 16.3785C25.2017 18.6368 26.6208 19.8687 28.3156 19.8687C28.726 19.8771 29.133 19.7916 29.5067 19.6187C29.8803 19.4458 30.2111 19.1898 30.4746 18.8695L29.7079 17.9046C29.5426 18.1038 29.3366 18.2639 29.1043 18.3739C28.872 18.4838 28.6189 18.5408 28.3627 18.541Z" fill="#D8D8D8"/>
    <path d="M33.1112 16.3305L32.6 17.3434V12.9361H31.1069V19.7797H32.7076L34.463 16.3579L35.1356 14.8728H35.176C35.1087 15.6256 34.9876 16.5358 34.9876 17.3365V19.7523H36.4807V12.9361H34.8666L33.1112 16.3305Z" fill="#D8D8D8"/>
    <path d="M39.9849 12.9361C38.5658 12.9361 37.4022 13.4425 37.4022 15.0576C37.3781 15.4894 37.4913 15.9176 37.7251 16.2788C37.9589 16.64 38.3009 16.9149 38.7003 17.0628L36.9785 19.7455H38.7003L40.1261 17.2955H40.8794V19.7455H42.3927V12.9361H39.9849ZM40.8794 16.0705H40.1059C39.3123 16.0705 38.8886 15.7283 38.8886 15.0576C38.8886 14.3869 39.3123 14.1611 40.1059 14.1611H40.8794V16.0705Z" fill="#D8D8D8"/>
    <path d="M10.0894 33.2206H11.8649V33.7681H10.7216V36.0949H10.0894V33.2206Z" fill="#747778"/>
    <path d="M12.3423 33.2206H13.3108C13.9363 33.2206 14.434 33.4464 14.434 34.1445C14.434 34.8425 13.9295 35.1231 13.331 35.1231H12.9812V36.0949H12.3423V33.2206ZM13.2906 34.5893C13.6471 34.5893 13.8085 34.4319 13.8085 34.1308C13.8085 33.8297 13.6202 33.727 13.2772 33.727H12.9812V34.5893H13.2906Z" fill="#747778"/>
    <path d="M14.7903 34.6441C14.7719 34.4612 14.7914 34.2764 14.8476 34.1017C14.9037 33.9269 14.9953 33.7661 15.1164 33.6296C15.2375 33.4932 15.3853 33.3841 15.5504 33.3094C15.7156 33.2347 15.8942 33.1962 16.0749 33.1962C16.2556 33.1962 16.4343 33.2347 16.5994 33.3094C16.7645 33.3841 16.9123 33.4932 17.0334 33.6296C17.1545 33.7661 17.2461 33.9269 17.3022 34.1017C17.3584 34.2764 17.3779 34.4612 17.3595 34.6441C17.3862 34.8316 17.373 35.0228 17.3207 35.2046C17.2684 35.3865 17.1783 35.5548 17.0565 35.698C16.9346 35.8413 16.7839 35.9562 16.6145 36.035C16.4452 36.1138 16.2611 36.1545 16.0749 36.1545C15.8887 36.1545 15.7047 36.1138 15.5353 36.035C15.3659 35.9562 15.2152 35.8413 15.0934 35.698C14.9715 35.5548 14.8814 35.3865 14.8291 35.2046C14.7768 35.0228 14.7636 34.8316 14.7903 34.6441ZM16.7004 34.6441C16.7004 34.0692 16.4583 33.7339 16.0749 33.7339C15.6915 33.7339 15.4494 34.0692 15.4494 34.6441C15.4494 35.2189 15.6915 35.5885 16.0749 35.5885C16.4583 35.5885 16.7004 35.2189 16.7004 34.6441Z" fill="#747778"/>
    <path d="M17.7567 34.6783C17.7379 34.4874 17.7587 34.2946 17.8178 34.1124C17.8769 33.9302 17.973 33.7627 18.0998 33.6207C18.2266 33.4787 18.3814 33.3655 18.554 33.2883C18.7267 33.2111 18.9133 33.1717 19.1019 33.1727C19.2602 33.1735 19.4168 33.2066 19.5624 33.2701C19.708 33.3335 19.8395 33.4261 19.9493 33.5422L19.613 33.9597C19.5501 33.8888 19.4734 33.8321 19.3877 33.7932C19.3019 33.7543 19.2092 33.7341 19.1153 33.7339C18.732 33.7339 18.4428 34.076 18.4428 34.6509C18.4428 35.2258 18.7118 35.5885 19.1153 35.5885C19.2204 35.5852 19.3237 35.5598 19.4186 35.5139C19.5135 35.4679 19.598 35.4025 19.6668 35.3216L20.0031 35.7253C19.8918 35.8615 19.7518 35.9704 19.5934 36.0439C19.4351 36.1174 19.2624 36.1535 19.0884 36.1497C18.9023 36.1547 18.7172 36.1191 18.5457 36.0453C18.3742 35.9715 18.2202 35.8612 18.094 35.7219C17.9679 35.5825 17.8725 35.4172 17.8143 35.2372C17.7562 35.0572 17.7365 34.8666 17.7567 34.6783Z" fill="#747778"/>
    <path d="M20.1171 34.6783C20.0982 34.4874 20.119 34.2946 20.1781 34.1124C20.2372 33.9302 20.3333 33.7627 20.4602 33.6207C20.587 33.4787 20.7418 33.3655 20.9144 33.2883C21.087 33.2111 21.2737 33.1717 21.4622 33.1727C21.6206 33.1735 21.7772 33.2066 21.9228 33.2701C22.0683 33.3335 22.1999 33.4261 22.3097 33.5422L21.9734 33.9597C21.9105 33.8888 21.8337 33.8321 21.748 33.7932C21.6623 33.7543 21.5695 33.7341 21.4757 33.7339C21.0923 33.7339 20.8031 34.076 20.8031 34.6509C20.8031 35.2258 21.0789 35.5885 21.4757 35.5885C21.5807 35.5852 21.684 35.5598 21.7789 35.5139C21.8739 35.4679 21.9584 35.4025 22.0272 35.3216L22.3635 35.7253C22.2522 35.8615 22.1122 35.9704 21.9538 36.0439C21.7954 36.1174 21.6228 36.1535 21.4488 36.1497C21.2626 36.1547 21.0776 36.1191 20.906 36.0453C20.7345 35.9715 20.5805 35.8612 20.4544 35.7219C20.3282 35.5825 20.2329 35.4172 20.1747 35.2372C20.1165 35.0572 20.0969 34.8666 20.1171 34.6783Z" fill="#747778"/>
    <path d="M22.7739 33.2205H23.4465L23.9307 34.4934C23.9845 34.6645 24.0316 34.8425 24.0922 35.0204C24.1527 34.8425 24.1998 34.6645 24.2536 34.4934L24.6975 33.2137H25.37V36.088H24.7849V35.0409C24.7849 34.7603 24.8387 34.3566 24.8656 34.0623L24.6302 34.7467L24.2603 35.8622H23.9038L23.507 34.7603L23.2784 34.076C23.2784 34.3566 23.3591 34.7603 23.3591 35.0546V36.1085H22.7874L22.7739 33.2205Z" fill="#747778"/>
    <path d="M26.0762 33.2206H27.845V33.7681H26.7151V34.3429H27.6903V34.8904H26.7151V35.5748H27.8854V36.1223H26.0762V33.2206Z" fill="#747778"/>
    <path d="M28.4503 33.2206H29.0825V34.2403C29.0715 34.5884 29.0424 34.9356 28.9951 35.2805L29.2776 34.6509L30.0174 33.2206H30.69V36.0949H30.0578V35.0752C30.0578 34.7399 30.1116 34.3498 30.1385 34.035L29.856 34.6646L29.1162 36.0949H28.4436L28.4503 33.2206ZM28.827 32.3788H29.365C29.365 32.5568 29.439 32.6594 29.6004 32.6594C29.7618 32.6594 29.8224 32.5568 29.8291 32.3788H30.3806C30.3806 32.7279 30.1654 32.9879 29.6139 32.9879C29.0624 32.9879 28.8471 32.7279 28.827 32.3788Z" fill="#747778"/>
    <path d="M31.2414 34.6783C31.2225 34.4874 31.2433 34.2946 31.3024 34.1124C31.3615 33.9302 31.4576 33.7627 31.5845 33.6207C31.7113 33.4787 31.866 33.3655 32.0387 33.2883C32.2113 33.2111 32.398 33.1717 32.5865 33.1727C32.7449 33.1735 32.9015 33.2066 33.047 33.2701C33.1926 33.3335 33.3242 33.4261 33.4339 33.5422L33.0977 33.9597C33.0348 33.8888 32.958 33.8321 32.8723 33.7932C32.7866 33.7543 32.6938 33.7341 32.6 33.7339C32.2099 33.7339 31.9274 34.076 31.9274 34.6509C31.9274 35.2258 32.1964 35.5885 32.6 35.5885C32.7053 35.5869 32.8092 35.5623 32.9043 35.5162C32.9995 35.4702 33.0838 35.4038 33.1515 35.3216L33.4878 35.7253C33.3775 35.861 33.2386 35.9696 33.0814 36.0431C32.9242 36.1165 32.7527 36.153 32.5798 36.1497C32.3931 36.1557 32.2073 36.1208 32.0349 36.0475C31.8626 35.9741 31.7078 35.864 31.5809 35.7245C31.454 35.585 31.358 35.4194 31.2994 35.2389C31.2409 35.0584 31.2211 34.8672 31.2414 34.6783Z" fill="#747778"/>
    <path d="M34.4159 33.7681H33.6223V33.2206H35.8149V33.7681H35.0414V36.0949H34.4159V33.7681Z" fill="#747778"/>
    <path d="M36.2588 33.2206H38.0276V33.7681H36.8977V34.3429H37.8595V34.8904H36.8977V35.5748H38.068V36.1223H36.2588V33.2206Z" fill="#747778"/>
    <path d="M38.6328 33.2206H39.6013C40.2201 33.2206 40.7245 33.4464 40.7245 34.1445C40.7245 34.8425 40.2133 35.1231 39.6148 35.1231H39.2718V36.0949H38.6328V33.2206ZM39.5811 34.5893C39.9309 34.5893 40.099 34.4319 40.099 34.1308C40.099 33.8297 39.9107 33.727 39.561 33.727H39.2718V34.5893H39.5811Z" fill="#747778"/>
    <path d="M9.90088 33.0358H11.6832V33.5764H10.5398V35.9032H9.90088V33.0358Z" fill="#D8D8D8"/>
    <path d="M12.154 33.0358H13.1292C13.748 33.0358 14.2457 33.2616 14.2457 33.9597C14.2457 34.6577 13.7412 34.9314 13.1427 34.9314H12.7929V35.9032H12.1204L12.154 33.0358ZM13.109 34.4045C13.4588 34.4045 13.6202 34.2471 13.6202 33.9528C13.6202 33.6585 13.4319 33.5422 13.0889 33.5422H12.7929V34.4045H13.109Z" fill="#D8D8D8"/>
    <path d="M14.609 34.4935C14.5861 34.3086 14.6022 34.1208 14.6561 33.9427C14.7101 33.7646 14.8006 33.6001 14.9218 33.4604C15.043 33.3206 15.192 33.2086 15.359 33.132C15.5259 33.0553 15.707 33.0156 15.8902 33.0156C16.0734 33.0156 16.2545 33.0553 16.4214 33.132C16.5884 33.2086 16.7374 33.3206 16.8586 33.4604C16.9798 33.6001 17.0703 33.7646 17.1243 33.9427C17.1782 34.1208 17.1943 34.3086 17.1714 34.4935C17.1964 34.6801 17.1818 34.87 17.1287 35.0505C17.0756 35.2309 16.9852 35.3977 16.8635 35.5396C16.7418 35.6816 16.5917 35.7954 16.4233 35.8735C16.2548 35.9515 16.0719 35.992 15.8868 35.9923C15.1268 35.9581 14.609 35.3969 14.609 34.4935ZM16.5191 34.4935C16.5191 33.9118 16.2702 33.5765 15.8868 33.5765C15.5035 33.5765 15.2614 33.9118 15.2614 34.4935C15.2614 35.0752 15.5035 35.4311 15.8868 35.4311C16.2702 35.4311 16.5191 35.0342 16.5191 34.4935Z" fill="#D8D8D8"/>
    <path d="M17.6017 34.4935C17.5829 34.3026 17.6037 34.1098 17.6628 33.9276C17.7219 33.7454 17.818 33.5778 17.9448 33.4359C18.0716 33.2939 18.2264 33.1807 18.399 33.1035C18.5717 33.0263 18.7583 32.9869 18.9469 32.9879C19.1062 32.9885 19.2639 33.0215 19.4106 33.085C19.5573 33.1485 19.69 33.2411 19.801 33.3574L19.4647 33.7749C19.3315 33.6363 19.1508 33.5554 18.9603 33.549C18.577 33.549 18.2878 33.8981 18.2878 34.4729C18.2878 35.0478 18.5635 35.4037 18.9603 35.4037C19.0655 35.4013 19.1691 35.3762 19.2641 35.3302C19.3592 35.2842 19.4436 35.2183 19.5118 35.1368L19.8481 35.5405C19.7379 35.6762 19.599 35.7848 19.4418 35.8583C19.2846 35.9317 19.1131 35.9682 18.9401 35.9648C18.7534 35.9709 18.5676 35.936 18.3953 35.8627C18.223 35.7893 18.0681 35.6792 17.9412 35.5397C17.8143 35.4002 17.7183 35.2346 17.6598 35.0541C17.6012 34.8736 17.5814 34.6824 17.6017 34.4935Z" fill="#D8D8D8"/>
    <path d="M19.9289 34.4935C19.91 34.3026 19.9308 34.1098 19.9899 33.9276C20.0491 33.7454 20.1451 33.5778 20.272 33.4359C20.3988 33.2939 20.5536 33.1807 20.7262 33.1035C20.8988 33.0263 21.0855 32.9869 21.274 32.9879C21.4334 32.9885 21.5911 33.0215 21.7378 33.085C21.8844 33.1485 22.0172 33.2411 22.1282 33.3574L21.7919 33.7749C21.6611 33.6367 21.4826 33.5558 21.2942 33.549C20.9041 33.549 20.6217 33.8981 20.6217 34.4729C20.6217 35.0478 20.8907 35.4037 21.2942 35.4037C21.3996 35.4021 21.5034 35.3775 21.5986 35.3314C21.6938 35.2854 21.7781 35.219 21.8457 35.1368L22.182 35.5405C22.0718 35.6762 21.9329 35.7848 21.7757 35.8583C21.6185 35.9317 21.447 35.9682 21.274 35.9649C21.0868 35.9719 20.9002 35.9378 20.7271 35.8649C20.5539 35.792 20.3982 35.682 20.2706 35.5424C20.1429 35.4028 20.0464 35.2368 19.9874 35.0558C19.9284 34.8748 19.9085 34.683 19.9289 34.4935Z" fill="#D8D8D8"/>
    <path d="M22.5854 33.0358H23.258L23.7154 34.3087L23.8768 34.8356C23.9306 34.6645 23.9777 34.4798 24.0315 34.3087L24.4754 33.0358H25.1479V35.9032H24.5695V34.8493C24.5794 34.5242 24.6064 34.1998 24.6502 33.8775L24.4216 34.5619L24.018 35.6637H23.7221L23.3185 34.5619L23.0966 33.8775C23.1405 34.1998 23.1674 34.5242 23.1773 34.8493V35.9032H22.6056L22.5854 33.0358Z" fill="#D8D8D8"/>
    <path d="M25.8879 33.0358H27.6904V33.5764H26.5269V34.1513H27.4886V34.6988H26.5269V35.3831H27.6904V35.9238H25.8879V33.0358Z" fill="#D8D8D8"/>
    <path d="M28.2617 33.0358H28.8939V34.0487C28.8939 34.384 28.8401 34.7878 28.8132 35.0957L29.0957 34.4661L29.8355 33.0358H30.5081V35.9033H29.8826V34.8836C29.8923 34.5357 29.9192 34.1885 29.9633 33.8433L29.7077 34.4935L28.9612 35.9238H28.2886L28.2617 33.0358ZM28.6384 32.1872H29.1764C29.1764 32.3651 29.2571 32.4746 29.4118 32.4746C29.5665 32.4746 29.6338 32.3651 29.6405 32.1872H30.1785C30.1785 32.5362 29.9633 32.7963 29.4118 32.7963C28.8603 32.7963 28.6585 32.5362 28.6384 32.1872Z" fill="#D8D8D8"/>
    <path d="M31.0531 34.4935C31.0343 34.3026 31.0551 34.1098 31.1142 33.9276C31.1733 33.7454 31.2694 33.5778 31.3962 33.4359C31.5231 33.2939 31.6778 33.1807 31.8504 33.1035C32.0231 33.0263 32.2097 32.9869 32.3983 32.9879C32.5566 32.9887 32.7132 33.0218 32.8588 33.0852C33.0044 33.1487 33.1359 33.2413 33.2457 33.3574L32.9094 33.7749C32.7786 33.6367 32.6001 33.5558 32.4117 33.549C32.0216 33.549 31.7392 33.8981 31.7392 34.4729C31.7392 35.0478 32.0082 35.4037 32.4117 35.4037C32.5179 35.4014 32.6225 35.3764 32.7187 35.3304C32.8148 35.2845 32.9004 35.2185 32.97 35.1368L33.3062 35.5405C33.1947 35.6763 33.0546 35.785 32.8963 35.8584C32.738 35.9318 32.5655 35.9682 32.3916 35.9648C32.2048 35.9709 32.019 35.936 31.8467 35.8627C31.6744 35.7893 31.5195 35.6792 31.3926 35.5397C31.2657 35.4002 31.1698 35.2346 31.1112 35.0541C31.0526 34.8736 31.0328 34.6824 31.0531 34.4935Z" fill="#D8D8D8"/>
    <path d="M34.214 33.5764H33.4338V33.0358H35.6264V33.5764H34.8529V35.9032H34.214V33.5764Z" fill="#D8D8D8"/>
    <path d="M36.0706 33.0358H37.8394V33.5764H36.7095V34.1513H37.6713V34.6988H36.7095V35.3831H37.8798V35.9238H36.0706V33.0358Z" fill="#D8D8D8"/>
    <path d="M38.4512 33.0358H39.4197C40.0384 33.0358 40.5429 33.2616 40.5429 33.9597C40.5429 34.6577 40.0384 34.9314 39.4398 34.9314H39.0901V35.9032H38.4512V33.0358ZM39.3995 34.4045C39.7492 34.4045 39.9174 34.2471 39.9174 33.9528C39.9174 33.6585 39.729 33.5422 39.386 33.5422H39.0901V34.4045H39.3995Z" fill="#D8D8D8"/>
    <path d="M44.612 8.49457H5.38794V10.2671H44.612V8.49457Z" fill="#E83F40"/>
    <path d="M44.612 6.72888H5.38794V8.50138H44.612V6.72888Z" fill="#748FFC"/>
    <path d="M44.612 5.02484H5.38794V6.79734H44.612V5.02484Z" fill="white"/>
    <path d="M3.61232 39.284C3.61232 39.284 3.3164 43.0343 8.11852 44.4099C7.8524 44.509 7.59576 44.6327 7.35179 44.7794C9.37442 45.9238 11.696 46.402 13.9967 46.1481C14.5271 46.4739 15.1572 46.5885 15.7656 46.4698C17.7833 46.025 20.3323 44.1772 23.7893 45.5254C23.7893 45.5254 21.0587 42.1652 19.1621 42.0557C18.3235 42.0388 17.4992 42.2771 16.7946 42.74C15.6176 42.0557 12.2346 40.2558 9.17445 40.0505C9.14291 40.181 9.12044 40.3137 9.10719 40.4474C8.33374 38.8529 7.19038 36.5602 7.02896 34.3634C8.23476 31.2791 10.5822 28.7995 13.5663 27.4582C13.5663 27.4582 13.1964 27.4172 12.6247 27.3966C13.6251 26.7355 14.5719 25.994 15.4562 25.1793C14.4494 25.1645 13.4439 25.2586 12.4566 25.4599C12.5656 25.0313 12.5656 24.5814 12.4566 24.1528C12.4566 24.1528 9.28878 25.631 8.18578 26.2811C7.08277 26.9313 6.71958 27.7046 6.46401 29.5934C6.46401 29.5934 6.46401 29.6277 6.46401 29.6482V29.6961C6.45615 28.9922 6.3193 28.2959 6.06047 27.643C6.06047 27.643 3.63923 28.9638 4.16383 33.6996L3.3971 32.0366C3.03279 34.5346 3.29183 37.0857 4.15038 39.4551L3.61232 39.284ZM6.97516 40.4543V40.4064L7.04241 40.4748L6.97516 40.4543Z" fill="#747778"/>
    <path d="M12.3693 22.9894C12.3693 22.9894 8.87199 24.625 7.66137 25.3367C6.45075 26.0485 5.97995 27.0066 5.7042 29.3539C5.42845 31.7013 12.0331 25.4805 11.986 25.371C12.2017 25.0196 12.3467 24.6281 12.4125 24.2193C12.4783 23.8105 12.4636 23.3924 12.3693 22.9894Z" fill="#CCCCCC"/>
    <path d="M6.00668 30.0451C5.92857 30.0466 5.85091 30.0326 5.77801 30.004C5.63677 29.9288 5.47535 29.7577 5.52916 29.3197C5.81164 26.9176 6.30933 25.8979 7.54686 25.1793C8.78438 24.4607 12.2548 22.8388 12.2548 22.8251C12.2771 22.8151 12.3011 22.8099 12.3254 22.8099C12.3498 22.8099 12.3738 22.8151 12.3961 22.8251C12.418 22.8352 12.4373 22.8503 12.4525 22.8693C12.4677 22.8883 12.4783 22.9107 12.4835 22.9346C12.5867 23.3256 12.6095 23.734 12.5505 24.1344C12.4915 24.5348 12.3519 24.9184 12.1405 25.2614L12.0867 25.3572C12.0867 25.4462 12.0127 25.5283 11.8715 25.672C11.7302 25.8158 7.47287 30.0451 6.00668 30.0451ZM12.2548 23.2015C11.5083 23.5505 8.77093 24.8508 7.72845 25.453C6.56491 26.1374 6.12774 27.0408 5.85199 29.3471C5.85199 29.5387 5.87889 29.6482 5.9327 29.6755C6.79358 29.9904 11.4074 25.7747 11.8042 25.2956C11.8289 25.2187 11.8628 25.1452 11.9051 25.0766C12.2455 24.5272 12.3703 23.8676 12.2548 23.2289V23.2015Z" fill="#969696"/>
    <path d="M15.6916 24.1255C15.6916 24.1255 7.95034 23.8175 5.71069 29.0734L9.01299 28.8612C9.01299 28.8612 13.7344 26.0485 15.6916 24.1255Z" fill="#CCCCCC"/>
    <path d="M5.71089 29.2376C5.68479 29.2376 5.65904 29.2314 5.6357 29.2195C5.61235 29.2076 5.59204 29.1904 5.57638 29.1691C5.56341 29.1438 5.55664 29.1156 5.55664 29.087C5.55664 29.0584 5.56341 29.0303 5.57638 29.0049C7.8362 23.7079 15.6649 23.9612 15.7187 23.9612C15.7513 23.962 15.783 23.973 15.8094 23.9925C15.8358 24.0121 15.8558 24.0393 15.8666 24.0707C15.8771 24.1012 15.8789 24.1341 15.8717 24.1656C15.8646 24.197 15.8488 24.2258 15.8263 24.2486C13.8758 26.158 9.30912 28.8886 9.10063 29.0049C9.07633 29.011 9.05094 29.011 9.02664 29.0049L5.71762 29.2171L5.71089 29.2376ZM15.2748 24.2965C13.6875 24.2965 7.96399 24.7208 5.97319 28.8886L8.96611 28.6969C11.1716 27.3886 13.2802 25.9179 15.2748 24.2965Z" fill="#969696"/>
    <path d="M13.5665 26.6507C13.5665 26.6507 7.72189 25.9047 5.68401 29.0733C3.64614 32.2419 5.87906 34.8493 6.12118 34.7809C6.3633 34.7124 7.72189 29.2512 13.5665 26.6507Z" fill="#CCCCCC"/>
    <path d="M6.16833 34.952C5.97328 34.952 5.72443 34.6646 5.54284 34.4114C4.99932 33.6198 4.71009 32.6763 4.7149 31.7107C4.71972 30.745 5.01835 29.8046 5.56974 29.0186C7.64797 25.8021 13.3715 26.4933 13.6405 26.5207C13.6755 26.5268 13.7077 26.5439 13.7325 26.5697C13.7573 26.5954 13.7734 26.6285 13.7786 26.6642C13.7839 26.6999 13.7779 26.7363 13.7615 26.7683C13.7452 26.8003 13.7193 26.8262 13.6876 26.8424C10.4428 28.3131 7.88994 31.0139 6.57187 34.3703C6.38355 34.8152 6.33647 34.9178 6.21541 34.952H6.16833ZM12.1609 26.7397C10.3248 26.7397 7.21753 27.075 5.8724 29.1555C5.47966 29.7113 5.21829 30.3519 5.10864 31.0273C4.99898 31.7027 5.044 32.3947 5.24019 33.0495C5.40228 33.6239 5.70551 34.1468 6.12125 34.5688L6.26921 34.2198C7.54086 30.9811 9.93313 28.3278 12.9949 26.7602C12.7326 26.7602 12.4501 26.7466 12.134 26.7466L12.1609 26.7397Z" fill="#969696"/>
    <path d="M5.22642 26.8423C5.22642 26.8423 2.53616 28.3069 3.13474 33.5491C3.73332 38.7913 5.22642 35.9923 5.22642 35.9923C5.22642 35.9923 6.30253 29.0186 5.22642 26.8423Z" fill="#CCCCCC"/>
    <path d="M4.35223 36.9092H4.23116C3.6124 36.7861 3.20214 35.6979 2.96674 33.5833C2.38833 28.2863 5.03824 26.7396 5.15258 26.7396C5.19352 26.7225 5.23943 26.7225 5.28037 26.7396C5.30061 26.7463 5.31935 26.757 5.33551 26.7711C5.35167 26.7852 5.36493 26.8024 5.37453 26.8218C6.46408 29.0323 5.43506 35.7869 5.37453 36.0743V36.1291C5.32745 36.1633 4.90373 36.9092 4.35223 36.9092ZM5.15931 27.0887C4.62125 27.4924 2.81878 29.1965 3.2963 33.5353C3.5855 36.1496 4.08993 36.5465 4.29842 36.5465C4.50692 36.5465 4.97099 36.1222 5.07187 35.9169C5.14585 35.4516 6.04037 29.2992 5.15931 27.0887Z" fill="#969696"/>
    <path d="M6.41038 32.8168C5.71772 33.3493 5.15737 34.0396 4.77427 34.8324C4.39116 35.6251 4.19598 36.4981 4.20436 37.3815C4.32542 40.29 9.29569 43.4381 9.29569 43.4381C9.34117 42.7758 9.18878 42.115 8.85852 41.5424C7.86985 39.4209 5.93958 35.8622 6.41038 32.8168Z" fill="#CCCCCC"/>
    <path d="M9.29557 43.6023C9.26683 43.6093 9.23687 43.6093 9.20813 43.6023C8.99964 43.4723 4.16389 40.3721 4.04282 37.4088C4.02744 36.4968 4.22558 35.5941 4.62087 34.7754C5.01615 33.9567 5.59724 33.2454 6.3161 32.7004C6.34347 32.6835 6.37487 32.6746 6.40689 32.6746C6.43892 32.6746 6.47032 32.6835 6.49769 32.7004C6.525 32.7186 6.54654 32.7444 6.55967 32.7748C6.57281 32.8052 6.57698 32.8388 6.57167 32.8715C6.15468 35.5747 7.69486 38.7502 8.71043 40.8443L9.01981 41.5287C9.36254 42.1338 9.51509 42.8311 9.45698 43.527C9.45147 43.5536 9.43929 43.5783 9.42161 43.5987C9.40393 43.6191 9.38136 43.6345 9.3561 43.6434C9.3316 43.6378 9.31002 43.6232 9.29557 43.6023ZM6.16813 33.2137C5.584 33.7296 5.11808 34.3695 4.80318 35.0884C4.48828 35.8073 4.33205 36.5877 4.34548 37.3746C4.44636 39.8041 8.13875 42.4731 9.1207 43.1369C9.08274 42.6064 8.93855 42.0894 8.69698 41.6177C8.60282 41.4123 8.50194 41.1933 8.3876 40.9675C7.43256 38.935 5.96636 35.9169 6.16813 33.2137Z" fill="#969696"/>
    <path d="M2.3479 31.756C2.3479 31.756 5.8587 39.8999 6.51781 41.6039C7.17693 43.308 3.20879 40.1668 3.20879 40.1668C2.22091 37.4839 1.92453 34.5884 2.3479 31.756Z" fill="#CCCCCC"/>
    <path d="M6.41027 42.2336C5.67717 42.2336 3.90159 40.8649 3.10796 40.2626C3.0865 40.2446 3.07023 40.2209 3.06089 40.1942C2.06374 37.4827 1.76281 34.5572 2.18655 31.6944C2.19107 31.6586 2.20758 31.6254 2.2333 31.6005C2.25902 31.5756 2.29238 31.5604 2.32779 31.5576C2.363 31.5535 2.39855 31.5614 2.42893 31.5799C2.45932 31.5985 2.48286 31.6267 2.49593 31.6602C2.49593 31.7355 6.02018 39.8178 6.66584 41.5082C6.71292 41.6314 6.85416 42.0009 6.61876 42.1925C6.55545 42.2288 6.4823 42.2432 6.41027 42.2336ZM3.34336 40.0231C4.82301 41.166 6.16814 42.0214 6.42372 41.9051C6.42372 41.9051 6.42372 41.8367 6.36319 41.6314C5.81168 40.1942 3.20212 34.1034 2.41522 32.3104C2.13428 34.9197 2.45199 37.5598 3.34336 40.0231Z" fill="#969696"/>
    <path d="M8.74392 40.6527C8.54552 41.4695 8.63966 42.3315 9.00943 43.084C9.37921 43.8365 10.0004 44.4303 10.7616 44.7589C13.5931 46.1276 17.6823 43.9856 17.6352 43.9034C17.5882 43.8213 12.8937 40.947 8.74392 40.6527Z" fill="#CCCCCC"/>
    <path d="M12.9947 45.4433C12.2111 45.4604 11.4339 45.2967 10.7214 44.9642C9.92071 44.6203 9.26644 43.9976 8.87578 43.2076C8.48513 42.4176 8.38361 41.512 8.5894 40.6527C8.59877 40.6154 8.62075 40.5826 8.65149 40.5601C8.68223 40.5376 8.71977 40.5267 8.75754 40.5295C13.0014 40.817 17.7161 43.7597 17.7968 43.8898C17.8192 43.9204 17.8313 43.9576 17.8313 43.9958C17.8313 44.034 17.8192 44.0712 17.7968 44.1019C16.3358 44.9609 14.682 45.4228 12.9947 45.4433ZM8.85843 40.8375C8.695 41.6132 8.80828 42.4228 9.17799 43.1212C9.54769 43.8197 10.1497 44.3614 10.8761 44.6494C13.2705 45.8197 16.5997 44.3688 17.3866 43.965C16.5324 43.3902 12.4768 41.1318 8.85843 40.8375Z" fill="#969696"/>
    <path d="M2.58349 39.7836C2.58349 39.7836 2.21358 44.2114 8.08508 45.6006C13.9566 46.9899 12.5778 44.9847 12.5778 44.9847C9.66169 42.526 6.24957 40.7503 2.58349 39.7836Z" fill="#CCCCCC"/>
    <path d="M11.3267 46.2577C10.2208 46.2121 9.12286 46.0469 8.05134 45.7649C2.13275 44.3551 2.41523 39.811 2.41523 39.7699C2.41646 39.7456 2.423 39.7218 2.43437 39.7003C2.44574 39.6789 2.46166 39.6603 2.48098 39.6459C2.5003 39.6315 2.52253 39.6216 2.54605 39.617C2.56958 39.6124 2.59381 39.6132 2.617 39.6193C6.31493 40.5982 9.75805 42.3873 12.7055 44.8616C12.8091 44.9902 12.8733 45.147 12.89 45.3125C12.9068 45.478 12.8753 45.6448 12.7997 45.7923C12.571 46.1276 12.0935 46.2577 11.3267 46.2577ZM2.73806 39.9684C2.73806 40.8307 3.10125 44.2251 8.11859 45.409C11.6227 46.2371 12.349 45.806 12.497 45.5801C12.645 45.3543 12.4432 45.06 12.4365 45.0532C9.5921 42.6815 6.287 40.9487 2.73806 39.9684Z" fill="#969696"/>
    <path d="M6.72632 45.8676C6.72632 45.8676 9.41658 44.0541 16.1422 44.4989C22.8679 44.9437 13.5663 49.9464 6.72632 45.8676Z" fill="#CCCCCC"/>
    <path d="M12.8332 47.6538C10.6625 47.6698 8.52591 47.1033 6.63891 46.0113C6.61626 45.9961 6.59725 45.9759 6.58328 45.9522C6.56931 45.9285 6.56074 45.9019 6.5582 45.8744C6.55725 45.846 6.56357 45.8178 6.57653 45.7926C6.5895 45.7674 6.60868 45.7461 6.63218 45.7307C6.74652 45.6554 9.45696 43.8898 16.1221 44.362C17.5883 44.4647 18.4155 44.7795 18.5702 45.3064C18.6375 45.5254 18.6106 45.8744 18.1062 46.2782C17.2116 47.0105 15.2007 47.6538 12.8332 47.6538ZM7.06262 45.8676C11.2931 48.2218 16.4113 47.2363 17.8977 46.025C18.0658 45.895 18.3281 45.6418 18.2608 45.4091C18.2205 45.279 17.9447 44.8342 16.1019 44.7247C10.6609 44.3141 7.86298 45.4433 7.06262 45.8676Z" fill="#969696"/>
    <path d="M24.926 46.723C24.926 46.723 21.8995 43.0001 19.8011 42.8769C17.7027 42.7537 16.1154 44.6015 14.629 45.0806C13.7743 45.3729 12.8623 45.4481 11.9724 45.2996C11.9724 45.2996 13.7883 48.2697 16.0414 47.7701C18.2945 47.2705 21.0924 45.2243 24.926 46.723Z" fill="#CCCCCC"/>
    <path d="M15.5233 47.9959C13.4518 47.9959 11.8982 45.498 11.8309 45.3885C11.8127 45.3613 11.803 45.3291 11.803 45.2961C11.803 45.2632 11.8127 45.231 11.8309 45.2037C11.849 45.1765 11.8747 45.1555 11.9047 45.1433C11.9347 45.1311 11.9675 45.1283 11.9991 45.1353C12.86 45.2713 13.7402 45.1986 14.5683 44.9231C15.1476 44.7102 15.6988 44.4252 16.2093 44.0745C17.2451 43.3902 18.4221 42.6579 19.8143 42.7469C21.9531 42.8701 24.9258 46.4903 25.0536 46.6477C25.0744 46.6778 25.0856 46.7136 25.0856 46.7504C25.0856 46.7872 25.0744 46.823 25.0536 46.853C25.0313 46.8825 25.0003 46.9037 24.9651 46.9136C24.9299 46.9235 24.8925 46.9215 24.8585 46.9078C22.0674 45.8197 19.8681 46.6409 17.9311 47.3595C17.3247 47.6061 16.7022 47.8096 16.0681 47.9686C15.8879 47.997 15.7054 48.0062 15.5233 47.9959ZM12.3219 45.5185C12.8465 46.244 14.3396 47.9754 16.0076 47.5716C16.6263 47.4183 17.2332 47.2193 17.8235 46.9762C19.5924 46.2919 21.7513 45.5117 24.3945 46.2919C23.5067 45.2995 21.3545 43.0685 19.7941 42.9727C18.5095 42.8974 17.4267 43.5886 16.3842 44.2525C15.8537 44.6298 15.28 44.94 14.6759 45.1764C13.9208 45.4463 13.121 45.5625 12.3219 45.5185Z" fill="#969696"/>
    <path d="M45.9101 39.4825C46.7579 37.1126 47.0054 34.5637 46.6298 32.0708L45.9168 33.7201C46.4414 29.0186 44.0202 27.6498 44.0202 27.6498C43.7614 28.3027 43.6245 28.999 43.6167 29.7029C43.6189 29.687 43.6189 29.6709 43.6167 29.655V29.6003C43.3611 27.7114 42.9441 26.8628 41.8949 26.2811C40.8457 25.6994 37.6308 24.1596 37.6308 24.1596C37.5218 24.5906 37.5218 25.0426 37.6308 25.4736C36.6435 25.2723 35.638 25.1782 34.6312 25.193C35.5172 26.0056 36.4639 26.7469 37.4627 27.4103C36.891 27.4103 36.5211 27.4719 36.5211 27.4719C39.5073 28.8493 41.836 31.3738 42.9979 34.4935C42.8365 36.6834 41.6931 38.9829 40.9197 40.5774C40.9059 40.4415 40.8835 40.3066 40.8524 40.1737C37.7788 40.3516 34.416 42.1378 33.2322 42.829C32.5264 42.3691 31.7029 42.131 30.8648 42.1446C28.9682 42.2541 26.2375 45.6143 26.2375 45.6143C29.6945 44.2456 32.2234 46.1071 34.2613 46.5588C34.8705 46.6788 35.5018 46.5615 36.0301 46.2303C38.3311 46.4882 40.6538 46.0098 42.6751 44.8615C42.4306 44.7138 42.1741 44.5879 41.9083 44.4851C46.7105 43.1164 46.4145 39.3661 46.4145 39.3661L45.9101 39.4825ZM42.9912 40.5364L43.0584 40.4679V40.5158L42.9912 40.5364Z" fill="#747778"/>
    <path d="M37.6307 22.9894C37.6307 22.9894 41.128 24.625 42.3387 25.3367C43.5493 26.0485 44.0201 27.0066 44.2958 29.3539C44.5716 31.7013 37.967 25.4805 38.0141 25.371C37.7983 25.0196 37.6533 24.6281 37.5875 24.2193C37.5217 23.8105 37.5364 23.3924 37.6307 22.9894Z" fill="#CCCCCC"/>
    <path d="M43.9935 30.0452C42.5273 30.0452 38.1421 25.7474 38.0951 25.6995C37.9404 25.5421 37.8597 25.4599 37.8664 25.371L37.8126 25.2751C37.6012 24.9322 37.4616 24.5485 37.4026 24.1481C37.3436 23.7478 37.3664 23.3393 37.4696 22.9483C37.4748 22.9244 37.4854 22.9021 37.5006 22.883C37.5157 22.864 37.5351 22.8489 37.557 22.8388C37.5793 22.8288 37.6033 22.8237 37.6276 22.8237C37.652 22.8237 37.676 22.8288 37.6983 22.8388C37.6983 22.8388 41.2225 24.4881 42.4062 25.193C43.5899 25.8979 44.1616 26.9313 44.4239 29.3334C44.4777 29.7577 44.3163 29.9288 44.1751 30.0178C44.1167 30.0383 44.0552 30.0476 43.9935 30.0452ZM38.196 25.3231C38.5928 25.8021 43.2066 30.0315 44.0675 29.703C44.1392 29.6756 44.1661 29.5661 44.1482 29.3745C43.8791 27.075 43.4352 26.1648 42.2717 25.4805C41.2292 24.9124 38.4919 23.5779 37.779 23.2289C37.6564 23.8765 37.7815 24.5472 38.1287 25.1041C38.1594 25.1742 38.1819 25.2477 38.196 25.3231Z" fill="#969696"/>
    <path d="M34.3083 24.1255C34.3083 24.1255 42.0496 23.8175 44.2892 29.0734L40.9869 28.8612C40.9869 28.8612 36.2655 26.0485 34.3083 24.1255Z" fill="#CCCCCC"/>
    <path d="M44.2892 29.2376L40.9734 29.0186C40.9491 29.0247 40.9237 29.0247 40.8994 29.0186C40.7111 28.9022 36.1444 26.1716 34.1738 24.2623C34.1512 24.2395 34.1355 24.2107 34.1283 24.1792C34.1212 24.1477 34.123 24.1148 34.1334 24.0843C34.1443 24.053 34.1643 24.0257 34.1907 24.0062C34.2171 23.9866 34.2487 23.9757 34.2814 23.9748C34.3554 23.9748 42.1639 23.7216 44.4237 29.0186C44.4367 29.0439 44.4434 29.0721 44.4434 29.1007C44.4434 29.1293 44.4367 29.1575 44.4237 29.1828C44.4067 29.2016 44.3858 29.2162 44.3625 29.2257C44.3393 29.2352 44.3142 29.2392 44.2892 29.2376ZM41.034 28.6969L44.0269 28.8885C42.0092 24.7208 36.3125 24.3239 34.7253 24.2965C36.7199 25.9179 38.8284 27.3886 41.034 28.6969Z" fill="#969696"/>
    <path d="M36.4336 26.6507C36.4336 26.6507 42.2782 25.9047 44.3161 29.0733C46.3539 32.2419 44.121 34.8493 43.8789 34.7809C43.6368 34.7124 42.2782 29.2512 36.4336 26.6507Z" fill="#CCCCCC"/>
    <path d="M43.8319 34.952C43.7109 34.952 43.6638 34.8152 43.4755 34.3703C42.1574 31.0139 39.6045 28.3131 36.3597 26.8424C36.328 26.8262 36.3021 26.8003 36.2858 26.7683C36.2694 26.7363 36.2635 26.6998 36.2687 26.6642C36.2739 26.6285 36.2901 26.5954 36.3149 26.5696C36.3397 26.5439 36.3718 26.5268 36.4068 26.5207C36.6489 26.5207 42.3724 25.8363 44.4776 29.0186C45.029 29.8046 45.3276 30.745 45.3324 31.7107C45.3372 32.6763 45.048 33.6198 44.5045 34.4114C44.3027 34.6646 44.0539 34.952 43.8319 34.952ZM37.0121 26.7397C40.0738 28.3073 42.4661 30.9606 43.7378 34.1992L43.8857 34.5483C44.2992 34.1244 44.602 33.6022 44.7668 33.029C44.963 32.3742 45.008 31.6821 44.8983 31.0067C44.7887 30.3313 44.5273 29.6908 44.1346 29.135C42.5742 26.7192 38.5792 26.6849 37.039 26.7671L37.0121 26.7397Z" fill="#969696"/>
    <path d="M44.7734 26.8423C44.7734 26.8423 47.4637 28.3069 46.8651 33.5491C46.2665 38.7913 44.7734 35.9923 44.7734 35.9923C44.7734 35.9923 43.6973 29.0186 44.7734 26.8423Z" fill="#CCCCCC"/>
    <path d="M45.6478 36.9093C45.0963 36.9093 44.6726 36.1634 44.6255 36.0744V36.0196C44.5851 35.7322 43.5561 28.9776 44.6255 26.7671C44.6351 26.7477 44.6483 26.7305 44.6645 26.7164C44.6807 26.7024 44.6994 26.6916 44.7196 26.685C44.7606 26.6678 44.8065 26.6678 44.8474 26.685C44.9618 26.7465 47.6117 28.2658 47.0333 33.5286C46.7979 35.6432 46.3607 36.7314 45.7689 36.8546L45.6478 36.9093ZM44.9281 35.9375C45.0492 36.1428 45.3855 36.6219 45.7016 36.5671C45.9101 36.5261 46.4145 36.1497 46.7037 33.5354C47.1812 29.1829 45.3586 27.4925 44.8407 27.0887C43.9596 29.2992 44.8542 35.4516 44.9281 35.9375Z" fill="#969696"/>
    <path d="M43.5897 32.8168C44.2824 33.3493 44.8427 34.0396 45.2258 34.8324C45.6089 35.6251 45.8041 36.4981 45.7957 37.3815C45.6747 40.29 40.7044 43.4381 40.7044 43.4381C40.6589 42.7758 40.8113 42.115 41.1416 41.5424C42.1302 39.4209 44.0605 35.8622 43.5897 32.8168Z" fill="#CCCCCC"/>
    <path d="M40.7043 43.6024C40.6846 43.6087 40.6634 43.6087 40.6437 43.6024C40.6185 43.5935 40.5959 43.5781 40.5782 43.5577C40.5605 43.5373 40.5483 43.5126 40.5428 43.486C40.4847 42.7901 40.6373 42.0928 40.98 41.4877L41.2894 40.8033C42.305 38.7092 43.8451 35.5337 43.4281 32.8305C43.4228 32.7978 43.427 32.7641 43.4401 32.7338C43.4533 32.7034 43.4748 32.6776 43.5021 32.6594C43.5295 32.6425 43.5609 32.6335 43.5929 32.6335C43.6249 32.6335 43.6564 32.6425 43.6837 32.6594C44.4026 33.2044 44.9837 33.9157 45.3789 34.7344C45.7742 35.5531 45.9724 36.4558 45.957 37.3678C45.8359 40.3311 41.0002 43.4313 40.7917 43.5613C40.7665 43.5825 40.7364 43.5966 40.7043 43.6024ZM43.8317 33.2137C44.0604 35.917 42.5942 38.935 41.6122 40.9676C41.5046 41.1934 41.397 41.4124 41.3028 41.6177C41.0613 42.0894 40.9171 42.6065 40.8791 43.137C41.8611 42.4526 45.5535 39.8041 45.6543 37.3747C45.6678 36.5877 45.5115 35.8073 45.1966 35.0885C44.8817 34.3696 44.4158 33.7297 43.8317 33.2137Z" fill="#969696"/>
    <path d="M47.652 31.756C47.652 31.756 44.1412 39.8999 43.4821 41.6039C42.823 43.308 46.7911 40.1668 46.7911 40.1668C47.779 37.4839 48.0754 34.5884 47.652 31.756Z" fill="#CCCCCC"/>
    <path d="M43.5897 42.2336C43.5154 42.2371 43.4421 42.2154 43.3812 42.172C43.1458 42.0009 43.287 41.6314 43.3341 41.4877C44.0067 39.7973 47.4704 31.715 47.504 31.6397C47.5171 31.6062 47.5406 31.578 47.571 31.5594C47.6014 31.5409 47.637 31.533 47.6722 31.537C47.708 31.5386 47.7421 31.5533 47.7681 31.5785C47.7941 31.6036 47.8102 31.6376 47.8134 31.6739C48.2372 34.5367 47.9362 37.4622 46.9391 40.1737C46.9297 40.2004 46.9135 40.224 46.892 40.2421C46.0984 40.8923 44.3228 42.2336 43.5897 42.2336ZM47.5847 32.3104C46.7978 34.1308 44.2219 40.1942 43.6368 41.6314C43.5561 41.8504 43.5763 41.912 43.5763 41.912C43.8117 42.0215 45.2106 41.166 46.6566 40.0231C47.548 37.5598 47.8657 34.9197 47.5847 32.3104Z" fill="#969696"/>
    <path d="M41.256 40.6527C41.4544 41.4695 41.3603 42.3315 40.9905 43.084C40.6207 43.8365 39.9995 44.4303 39.2383 44.7589C36.4068 46.1276 32.3176 43.9856 32.3647 43.9034C32.4117 43.8213 37.1063 40.947 41.256 40.6527Z" fill="#CCCCCC"/>
    <path d="M36.9987 45.4432C35.318 45.4104 33.6738 44.9391 32.2234 44.0745C32.2011 44.0438 32.189 44.0066 32.189 43.9684C32.189 43.9302 32.2011 43.893 32.2234 43.8623C32.3041 43.7323 37.0256 40.7896 41.2627 40.5021C41.2985 40.5013 41.3335 40.5131 41.3618 40.5355C41.39 40.558 41.4096 40.5897 41.4174 40.6253C41.6285 41.4875 41.5297 42.398 41.1387 43.1925C40.7477 43.9869 40.0903 44.6129 39.2854 44.9573C38.5693 45.2939 37.7873 45.4601 36.9987 45.4432ZM32.6337 43.9581C33.4408 44.3961 36.7498 45.8333 39.1374 44.6425C39.8639 44.3545 40.4659 43.8128 40.8356 43.1143C41.2053 42.4159 41.3185 41.6063 41.1551 40.8306C37.5636 41.1317 33.5551 43.3901 32.6337 43.9445V43.9581Z" fill="#969696"/>
    <path d="M47.4169 39.7836C47.4169 39.7836 47.7868 44.2114 41.9153 45.6006C36.0438 46.9899 37.4225 44.9847 37.4225 44.9847C40.3387 42.526 43.7508 40.7503 47.4169 39.7836Z" fill="#CCCCCC"/>
    <path d="M38.6732 46.2577C37.9065 46.2577 37.429 46.1003 37.2205 45.7923C37.1474 45.6504 37.115 45.4905 37.1269 45.3308C37.1388 45.1711 37.1945 45.018 37.2877 44.889C40.2309 42.4021 43.6748 40.6032 47.3762 39.6194C47.4195 39.6025 47.4674 39.6025 47.5107 39.6194C47.5472 39.6507 47.5711 39.6945 47.578 39.7426C47.578 39.7836 47.8874 44.3278 41.9419 45.7376C40.8735 46.0284 39.7778 46.2027 38.6732 46.2577ZM37.5298 45.108C37.5298 45.108 37.355 45.3954 37.4962 45.6075C37.6374 45.8197 38.3705 46.2919 41.8746 45.4365C46.8987 44.2525 47.2551 40.8581 47.2551 39.9958C43.6907 40.9761 40.3728 42.7189 37.5231 45.108H37.5298Z" fill="#969696"/>
    <path d="M43.2737 45.8676C43.2737 45.8676 40.5834 44.0541 33.8577 44.4989C27.1321 44.9437 36.4337 49.9464 43.2737 45.8676Z" fill="#CCCCCC"/>
    <path d="M37.1667 47.6537C34.7992 47.6537 32.7883 47.0104 31.8938 46.285C31.3893 45.8812 31.3624 45.5322 31.4297 45.3132C31.5844 44.7863 32.4116 44.4714 33.8778 44.3688C40.5497 43.8897 43.2534 45.6485 43.3677 45.7375C43.3912 45.7529 43.4104 45.7742 43.4234 45.7994C43.4364 45.8246 43.4427 45.8528 43.4417 45.8812C43.4392 45.9087 43.4306 45.9353 43.4166 45.959C43.4027 45.9827 43.3837 46.0029 43.361 46.0181C41.4734 47.1077 39.3368 47.6719 37.1667 47.6537ZM36.0502 44.622C35.3777 44.622 34.6647 44.622 33.898 44.6973C32.0552 44.8342 31.7794 45.279 31.7391 45.3816C31.6718 45.6143 31.9341 45.8675 32.1023 45.9976C33.5886 47.202 38.7069 48.1944 42.9373 45.8402C42.2513 45.5185 40.0923 44.622 36.0502 44.622Z" fill="#969696"/>
    <path d="M25.074 46.723C25.074 46.723 28.1005 43.0001 30.1989 42.8769C32.2973 42.7537 33.8846 44.6015 35.371 45.0806C36.2257 45.3729 37.1378 45.4481 38.0276 45.2996C38.0276 45.2996 36.2117 48.2697 33.9922 47.7701C31.7727 47.2705 28.9076 45.2243 25.074 46.723Z" fill="#CCCCCC"/>
    <path d="M34.4765 47.996C34.291 47.9955 34.1062 47.9748 33.925 47.9344C33.2909 47.7754 32.6684 47.5719 32.062 47.3253C30.125 46.6409 27.9324 45.7855 25.1346 46.8736C25.1006 46.8873 25.0632 46.8893 25.028 46.8795C24.9928 46.8696 24.9618 46.8483 24.9395 46.8189C24.9187 46.7889 24.9075 46.753 24.9075 46.7162C24.9075 46.6795 24.9187 46.6436 24.9395 46.6136C25.0673 46.4562 28.04 42.8359 30.1788 42.7127C31.5777 42.6237 32.748 43.3971 33.7838 44.0404C34.2904 44.4025 34.8394 44.699 35.4181 44.9232C36.2462 45.1987 37.1264 45.2714 37.9873 45.1354C38.0188 45.1283 38.0517 45.1311 38.0817 45.1433C38.1117 45.1555 38.1374 45.1766 38.1554 45.2038C38.1737 45.231 38.1834 45.2632 38.1834 45.2962C38.1834 45.3291 38.1737 45.3613 38.1554 45.3886C38.1016 45.4981 36.548 47.996 34.4765 47.996ZM27.8517 46.0182C29.3401 46.0818 30.8051 46.418 32.1763 47.0105C32.7666 47.2536 33.3735 47.4526 33.9923 47.6059C35.6871 47.9823 37.1533 46.2372 37.6779 45.5528C36.8832 45.6188 36.0835 45.5258 35.3239 45.2791C34.7198 45.0427 34.1461 44.7325 33.6156 44.3552C32.5731 43.6708 31.4903 42.9865 30.2057 43.0754C28.6453 43.1712 26.4864 45.4023 25.6053 46.3946C26.3314 46.1554 27.0886 46.0285 27.8517 46.0182Z" fill="#969696"/>
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="5" y="2" width="40" height="44">
    <path d="M44.612 2H5.38794V36.9024C5.38794 39.0623 6.23117 41.1338 7.73212 42.661C9.23308 44.1883 11.2688 45.0463 13.3915 45.0463H36.6085C38.7311 45.0463 40.7669 44.1883 42.2678 42.661C43.7688 41.1338 44.612 39.0623 44.612 36.9024V2Z" fill="#969696"/>
    </mask>
    <g mask="url(#mask0)">
    <path opacity="0.3" d="M44.5042 3.99843C40.806 -0.187123 35.7795 -3.25177 30.0983 -4.7847C24.4171 -6.31763 18.3527 -6.2456 12.7181 -4.57825C7.08356 -2.9109 2.148 0.272077 -1.42694 4.54402C-5.00188 8.81597 -7.04535 13.9727 -7.28345 19.3231C-2.96802 22.071 1.99851 23.8707 7.22035 24.5786C15.1204 25.638 23.1816 24.1576 29.9961 20.3961C36.8106 16.6346 41.9455 10.8309 44.5042 3.99843Z" fill="url(#paint0_linear)"/>
    </g>
    <rect x="-1166.5" y="-124.5" width="1768" height="796" rx="4.5" stroke="#7B61FF" strokeDasharray="10 5"/>
    <defs>
    <linearGradient id="paint0_linear" x1="11.5896" y1="-0.217482" x2="22.4575" y2="22.3406" gradientUnits="userSpaceOnUse">
    <stop stopColor="white" stopOpacity="0"/>
    <stop offset="0.35" stopColor="white" stopOpacity="0.01"/>
    <stop offset="0.49" stopColor="white" stopOpacity="0.03"/>
    <stop offset="0.59" stopColor="white" stopOpacity="0.08"/>
    <stop offset="0.67" stopColor="white" stopOpacity="0.15"/>
    <stop offset="0.74" stopColor="white" stopOpacity="0.24"/>
    <stop offset="0.8" stopColor="white" stopOpacity="0.35"/>
    <stop offset="0.85" stopColor="white" stopOpacity="0.47"/>
    <stop offset="0.9" stopColor="white" stopOpacity="0.62"/>
    <stop offset="0.95" stopColor="white" stopOpacity="0.79"/>
    <stop offset="1" stopColor="white"/>
    </linearGradient>
    </defs>
  </svg>
);

export default IconGrossMaster;
