import { FC, PropsWithChildren } from 'react';

import { Box, Typography } from '@mui/material';
import { IconCheckmark } from 'icons/edit';
import { mergeSx } from 'utils';

import * as categoryPreviewStyles from './categoryThemePreviewStyles';
import * as commonStyles from './commonStyles';
import * as settingPreviewStyles from './settingThemePreviewStyles';

interface ICategoryThemePreviewProps {
  type: 'category' | 'setting';
  isActive: boolean;
  name: string;
  onClick: () => void;
}

const ThemePreview2: FC<ICategoryThemePreviewProps & PropsWithChildren> = ({
  type,
  isActive,
  name,
  onClick,
  children,
}) => {
  const styles = type === 'category' ? categoryPreviewStyles : settingPreviewStyles;

  return (
    <Box
      className="theme-preview"
      sx={mergeSx(commonStyles.commonRoot(isActive), styles.root, styles.rootOverlayTrigger(isActive))}
      onClick={onClick}
    >
      <Box className="theme-preview__inner" sx={commonStyles.commonInnerContainer(isActive)}>
        {children}

        <Box className="theme-preview__overlay" sx={styles.overlay}>
          {isActive ? (
            <IconCheckmark sx={styles.overlayIcon} />
          ) : (
            <Typography variant="Paragraph SM/Medium" sx={styles.overlayText}>
              {name}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ThemePreview2;
