import { IStudentVisibilitySetting } from 'api/types';
import { ILinkSetting } from 'components/redesignStudent/LinkSharingForm/types';
import { isDefined } from 'utils';

import { YandexMetrikaSections, YandexMetrikaVisibilitySections } from '../types/shapes';

export const getMetrikaEnabledLinkSettingsSections = (settings: ILinkSetting[]): YandexMetrikaSections => {
  return getMappedEnabledLinkSections(settings);
};

export const getMetrikaVisibilitySections = (settings: IStudentVisibilitySetting[]): YandexMetrikaSections => {
  return getMappedSettingSections(settings);
};

function getMappedEnabledLinkSections(settings: ILinkSetting[]): YandexMetrikaSections {
  const mappedSettings = settings.map((setting): YandexMetrikaSections[number] | null => {
    const { isActive, name, childSettings } = setting;

    if (!isActive) return null;

    const subSections = childSettings ? getMappedEnabledLinkSections(childSettings) : null;

    if (subSections && subSections.length > 0) {
      return {
        name,
        subSections,
      };
    }

    return {
      name,
    };
  });

  return mappedSettings.filter(isDefined);
}

function getMappedSettingSections(settings: IStudentVisibilitySetting[]): YandexMetrikaVisibilitySections {
  const mappedSettings = settings.map((setting): YandexMetrikaVisibilitySections[number] | null => {
    const { isVisible, name, subSections: childSettings } = setting;
    const subSections = childSettings ? getMappedSettingSections(childSettings) : null;

    if (subSections && subSections.length > 0) {
      return {
        name,
        subSections,
        visible: isVisible,
      };
    }

    return {
      name,
      visible: isVisible,
    };
  });

  return mappedSettings.filter(isDefined);
}
