/* eslint-disable prettier/prettier */
/* eslint-disable */

export const REQUEST = 'REQUEST';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';
export const INVALIDATE = 'INVALIDATE';

export type Action<T> = {
  type: string;
  payload: T;
};

export const createRequestTypes = (base: string): Record<string, string> => (
  [REQUEST, SUCCESS, FAILURE, INVALIDATE].reduce((acc, type) => {
    acc[type] = `${base}_${type}`;

    return acc;
  }, {} as Record<string, string>)
);

/**
 * Функция конструирует объект Action
 * @deprecated из-за необходимости явно задавать свойство payload использование этой функции становится неудобным, используйте createAction
 * @param type тип экшена
 * @param payload payload экшена, необходимо передавать объект { payload: ... }
 * @returns объект Action
 */
export const action = <T>(type: string, payload?: T) => ({ type, ...payload } as Action<T>);

/**
 * Функция конструирует объект Action
 * @param type тип экшена
 * @param payload payload экшена
 * @returns объект Action
 */
export const createAction = <T>(type: string, payload: T) => {
  const action: Action<T> = {
    type,
    payload,
  };
  return action;
}
