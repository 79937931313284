import React, { useContext, useMemo } from 'react';
import { connect } from 'react-redux';

import { FormHelperText } from '@mui/material';
import {
  ICommonResponse,
  ICreationKind,
  IDictionaryItem,
  IModifiedAffilationKindsList,
  IModifiedDictionaryItemList,
  IModifiedEventKindsList,
  IModifiedRewardKindsList,
} from 'api/types';
import { SectionCodes, SubcategoryParentsCodes } from 'const';
import { BaseInputLabel, FormControl } from 'portfolio3/ui-kit';
import { SearchSelect } from 'portfolio3/ui-kit/selects';
import { IController } from 'portfolio3/ui-kit/types';
import { IRootState } from 'reducers';
import { createSelector } from 'reselect';
import { sectionRefArchiveFalseSelector } from 'selectors';

import { getAgeLimitFromArray } from '../../../../../utils';
import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';
import {
  AffiliationSecondaryBlock,
  CreationContestSecondaryBlock,
  NameRewardSecondaryBlock,
  OccupationSecondaryBlock,
} from '../../secondaryBlocks';

interface ICreationSecondaryBlockProps {
  activityTypeData: IModifiedDictionaryItemList;
  eventKindData: IModifiedEventKindsList;
  olympiadTypes: ICommonResponse<IDictionaryItem>;
  rewardKindData: IModifiedRewardKindsList;
  affilationKindData: IModifiedAffilationKindsList;
  creationKinds: ICommonResponse<ICreationKind>;
  subcategories: IModifiedRewardKindsList;
}

const CreationSecondaryBlock: React.FC<ICreationSecondaryBlockProps> = ({
  activityTypeData,
  eventKindData,
  creationKinds,
  subcategories,
  olympiadTypes,
  rewardKindData,
  affilationKindData,
}) => {
  const { inputRenderMode, inputSize, formData, formErrors, onChangeFormData, onChangeFormErrors } =
    useContext(LocalDataEntryFormContext);

  const creationKindController: IController<string | undefined> = useMemo(
    () => ({
      handleChange: (creationKindCode) => {
        onChangeFormData((prevstate) => ({
          ...prevstate,
          creationKindCode: Number(creationKindCode),
        }));
        onChangeFormErrors((prevstate) => ({
          ...prevstate,
          creationKindError: false,
        }));
      },
      handleBlur: (type) => {
        if (!type) {
          onChangeFormErrors((prevstate) => ({
            ...prevstate,
            creationKindError: true,
          }));
        }
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData.id],
  );

  const getAffiliationNameInputLabel = () => {
    if (formData.typeCode === SectionCodes.creationCollective) return 'Название коллектива';
    if (formData.typeCode === SectionCodes.creationEnsemble) return 'Название ансамбля';
    return 'Название';
  };

  const getSecondaryAffiliationNameInputPlaceholder = () => {
    if (formData.typeCode === SectionCodes.creationCollective) return 'Введите название коллектива...';
    if (formData.typeCode === SectionCodes.creationEnsemble) return 'Введите название ансамбля...';
    return 'Введите название коллектива...';
  };

  const getOccupationNameInputLabel = () => {
    if (formData.typeCode === SectionCodes.creationCircle) return 'Название кружка';
    if (formData.typeCode === SectionCodes.creationSchool) return 'Название школы искусств';
    return '';
  };

  const getOccupationNameInputPlaceHolder = () => {
    if (formData.typeCode === SectionCodes.creationCircle) return 'Введите название кружка...';
    if (formData.typeCode === SectionCodes.creationSchool) return 'Введите название школы искусств...';
    return '';
  };

  const currentEventKindData = eventKindData.content.find((activity) => activity.code === formData.name);

  const eventData = {
    type:
      activityTypeData.content?.find((portfolioKind) => portfolioKind.code === currentEventKindData?.categoryCode)
        ?.value || 'Конкурс',
    name: currentEventKindData?.value,
    ageLimit: getAgeLimitFromArray(currentEventKindData?.ageLimits),
    levelEvent: currentEventKindData?.levelEvent
      ? olympiadTypes.content?.find((type) => type.code === currentEventKindData?.levelEvent)?.value
      : undefined,
    levelEventCode: currentEventKindData?.levelEvent,
    organizators: currentEventKindData?.organizators,
  };

  const filteredCreationKinds = creationKinds.content?.filter(
    (creationKind) => creationKind.categoryCode === formData.subCategory,
  );

  return (
    <>
      {formData.dataKind === SectionCodes.creationAffilation && formData.name && (
        <AffiliationSecondaryBlock
          withoutSubjects
          hasSubCategory
          isSubcategoryRequired
          name={affilationKindData.content.find((name) => name.code === formData.name)?.value}
          subjectOptions={filteredCreationKinds}
          subCategoryOptions={subcategories.content}
          levelOptions={olympiadTypes?.content || []}
          nameInputLabel={getAffiliationNameInputLabel()}
          nameInputPlaceholder={getSecondaryAffiliationNameInputPlaceholder()}
          subjectInputLabel=""
          subjectInputPlaceholder=""
        >
          <FormControl
            sx={{ width: '100%' }}
            required
            disabled={!formData.subCategory}
            error={formErrors.creationKindError}
            renderMode={inputRenderMode}
            inputSize={inputSize}
            label={<BaseInputLabel>Вид творческой деятельности</BaseInputLabel>}
            helper={formErrors.creationKindError && <FormHelperText>Выберите творческую дейстельность</FormHelperText>}
            control={
              <SearchSelect
                inputRenderMode={inputRenderMode}
                inputSize={inputSize}
                placeholder="Выберите вид творческой деятельности..."
                value={formData.creationKindCode}
                options={filteredCreationKinds}
                controller={creationKindController}
                strictMaxWidth
              />
            }
          />
        </AffiliationSecondaryBlock>
      )}
      {formData.dataKind === SectionCodes.creationOccupation && formData.typeCode && (
        <OccupationSecondaryBlock
          withoutSubjects
          subCategoryOptions={subcategories.content}
          nameInputLabel={getOccupationNameInputLabel()}
          nameInputPlaceholder={getOccupationNameInputPlaceHolder()}
          subjectInputLabel=""
          subjectInputPlaceholder=""
          subjectOptions={filteredCreationKinds}
        >
          <FormControl
            sx={{ width: '100%' }}
            required
            disabled={!formData.subCategory}
            error={formErrors.creationKindError}
            renderMode={inputRenderMode}
            inputSize={inputSize}
            label={<BaseInputLabel>Вид творческой деятельности</BaseInputLabel>}
            helper={formErrors.creationKindError && <FormHelperText>Выберите творческую дейстельность</FormHelperText>}
            control={
              <SearchSelect
                inputRenderMode={inputRenderMode}
                inputSize={inputSize}
                placeholder="Выберите вид творческой деятельности..."
                value={formData.creationKindCode}
                options={filteredCreationKinds}
                controller={creationKindController}
                strictMaxWidth
              />
            }
          />
        </OccupationSecondaryBlock>
      )}
      {formData.dataKind === SectionCodes.creationEvent && formData.name && (
        <CreationContestSecondaryBlock
          eventData={eventData}
          subCategoryOptions={subcategories.content}
          subjectOptions={filteredCreationKinds}
          eventLevelOptions={olympiadTypes?.content || []}
        />
      )}
      {formData.dataKind === SectionCodes.creationReward && formData.reward && (
        <NameRewardSecondaryBlock
          result={rewardKindData.content?.find((reward) => reward.code === formData.reward)?.value}
        />
      )}
    </>
  );
};

const portfolioActivityTypeMapping = createSelector(
  [(state: IRootState) => sectionRefArchiveFalseSelector(state)],
  (sections) => ({
    content: sections.content?.filter((section) => section.parentId === SectionCodes.creationEvent) || [],
    loading: sections.loading,
  }),
);

const eventKindsMapping = createSelector([(state: IRootState) => state.eventKinds], (eventKinds) => ({
  content: eventKinds?.content?.filter((eventKind) => eventKind.categoryCode === SectionCodes.creationContest) || [],
  loading: eventKinds.loading,
}));

const rewardKindsMapping = createSelector([(state: IRootState) => state.rewardKinds], (rewardKinds) => ({
  content:
    rewardKinds.content?.filter((rewardKind) => rewardKind.categoryCode === SectionCodes.creationContestReward) || [],
  loading: rewardKinds.loading,
}));

const affilationKindsMapping = createSelector([(state: IRootState) => state.affilationKinds], (affilationKinds) => ({
  content:
    affilationKinds.content?.filter(
      (affilationKind) => affilationKind.categoryCode === SectionCodes.creationCollective && affilationKind.parentId,
    ) || [],
  loading: affilationKinds.loading,
}));

const subcategoriesMapping = createSelector([(state: IRootState) => state.portfolioSubcategories], (subcategories) => ({
  content:
    subcategories.content?.filter((subcategory) => subcategory?.parentId === SubcategoryParentsCodes.creation) || [],
  loading: subcategories.loading,
}));

export default connect((state: IRootState) => ({
  activityTypeData: portfolioActivityTypeMapping(state),
  eventKindData: eventKindsMapping(state),
  rewardKindData: rewardKindsMapping(state),
  affilationKindData: affilationKindsMapping(state),
  creationKinds: state.creationKinds,
  subcategories: subcategoriesMapping(state),
  olympiadTypes: state.olympiadTypes,
}))(CreationSecondaryBlock);
