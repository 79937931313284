import { FC } from 'react';

import { Box, Typography, useMediaQuery } from '@mui/material';
import TrimTextMultiLine from 'portfolio3/components/common/TrimTextMultiLine';
import { NeutralColors } from 'portfolio3/styles';
import { commonTheme } from 'portfolio3/styles/theme';

import { IconArrowRight } from '../../../../icons/arrows';
import * as styles from './styles';

interface IEntityCardRewardProps {
  name: string;
  date?: string;
  image: string;
  onOpenRewardCard: () => void;
}

const EntityCardReward: FC<IEntityCardRewardProps> = ({ name, date, image, onOpenRewardCard }) => {
  const isSmallScreen = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  return (
    <Box className="entity-card-reward" sx={styles.root}>
      <Box className="entity-card-reward__left" sx={styles.leftElement}>
        <Typography
          component={TrimTextMultiLine}
          line={1}
          variant={isSmallScreen ? 'Paragraph MD/Semi Bold' : 'Paragraph LG/Semi Bold'}
        >
          {name}
        </Typography>
        {date && (
          <Typography
            variant={isSmallScreen ? 'Paragraph SM/Medium' : 'Paragraph LG/Medium'}
            color={NeutralColors.light_neutrals_600}
          >
            {`Дата получения: ${date}`}
          </Typography>
        )}
      </Box>
      <Box className="entity-card-reward__right" sx={styles.rightElement}>
        <Box component="img" alt="Награда" src={image} sx={styles.rewardImage} />
        <Box component="button" sx={styles.arrowButton} onClick={onOpenRewardCard}>
          <IconArrowRight />
        </Box>
      </Box>
    </Box>
  );
};

export default EntityCardReward;
