import { DifferentCode, DifferentValue, DocumentTypeCodes } from 'const';
import { IFile } from 'portfolio3/features/dataEntryForm';
import { IStudySpoDocumentFormData } from 'portfolio3/features/dataEntryForm';

import { alternativeValidatorFactory, IValidateAlternative } from '../common';

export const isSpoDocumentValid = (formData: IStudySpoDocumentFormData, attachments: IFile[]) => {
  const documentType = formData.documentTypeCode;

  const validators = {
    [DocumentTypeCodes.spoCertificate]: isSpoCertificateValid,
    [DocumentTypeCodes.spoJobCertificate]: isSpoJobCertificateValid,
    [DocumentTypeCodes.spoShortTrainingCertificate]: isSpoShortTrainingCertificateValid,
    [DocumentTypeCodes.spoProfessionalCertificate]: isSpoProfessionalCertificateValid,
    [DocumentTypeCodes.spoOther]: isSpoOtherDocumentValid,
  };

  const validator = documentType ? validators[documentType] : null;

  const hasAttachments = attachments.filter((attachment) => !attachment.response.isDelete).length > 0;
  if (!hasAttachments) return false;

  return validator ? validator(formData) : false;
};

const spoDocumentValidateAlternatives: IValidateAlternative<IStudySpoDocumentFormData>[] = [
  {
    targetProp: 'organizationCode',
    excludeValue: DifferentCode,
    orSomeProps: ['organizationName'],
  },
  {
    targetProp: 'professionalProgrammCode',
    excludeValue: DifferentCode,
    orSomeProps: ['professionalProgrammName'],
  },
  {
    targetProp: 'docPlace',
    excludeValue: DifferentValue,
    orSomeProps: ['docPlaceOther'],
  },
];

const spoCertificateRequiredProps = ['educationProgramm', 'organizationCode', 'hours', 'docDate', 'docPlace'] as const;
export const isSpoCertificateValid = alternativeValidatorFactory(
  spoCertificateRequiredProps,
  spoDocumentValidateAlternatives,
);

const spoJobCertificateRequiredProps = [
  'organizationCode',
  'docNum',
  'regNum',
  'docDate',
  'docPlace',
  'professionalProgrammCode',
  'hours',
  'profession',
  'rankCode',
] as const;
export const isSpoJobCertificateValid = alternativeValidatorFactory(
  spoJobCertificateRequiredProps,
  spoDocumentValidateAlternatives,
);

const spoShortTrainingCertificateRequiredProps = [
  'organizationCode',
  'educationProgramm',
  'hours',
  'regNum',
  'docDate',
  'docPlace',
] as const;
export const isSpoShortTrainingCertificateValid = alternativeValidatorFactory(
  spoShortTrainingCertificateRequiredProps,
  spoDocumentValidateAlternatives,
);

const spoProfessionalCertificateRequiredProps = [
  'organizationCode',
  'educationProgramm',
  'hours',
  'regNum',
  'docDate',
  'docPlace',
] as const;
export const isSpoProfessionalCertificateValid = alternativeValidatorFactory(
  spoProfessionalCertificateRequiredProps,
  spoDocumentValidateAlternatives,
);

const spoOtherRequiredProps = ['documentName', 'organizationCode', 'hours', 'docDate', 'docPlace'] as const;
export const isSpoOtherDocumentValid = alternativeValidatorFactory(
  spoOtherRequiredProps,
  spoDocumentValidateAlternatives,
);
