/* eslint-disable max-len */
import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconUpload: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 4h14c.55 0 1 .45 1 1v2c0 .55-.45 1-1 1s-1-.45-1-1V6H6v1c0 .55-.45 1-1 1s-1-.45-1-1V5c0-.55.45-1 1-1Zm3.001 10a1 1 0 0 1-.601-1.8l4-3a1 1 0 0 1 1.175-.018l4 2.814a1 1 0 1 1-1.15 1.636l-2.428-1.708c.002.025.003.05.003.076v8a1 1 0 1 1-2 0v-8l-2.4 1.8c-.18.135-.39.2-.599.2Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconUpload;
