import { FC } from 'react';

import ChangeHistory from '../../containers/employee/ChangeHistory';

const ChangeHistoryPage: FC = () => (
  <>
    <div className="employee-content">
      <ChangeHistory />
    </div>
  </>
);

export default ChangeHistoryPage;
