/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';

import {
  FAILURE,
  INVALIDATE,
  REQUEST,
  SUCCESS
} from '../../actions/utils';

import genericReducers from '../genericReducer';
import { IReducers } from '..';
import { ICommonResponse, IStudentEmployment } from '../../api/types';
import { GET_STUDENT_EMPLOYMENTS } from '../../actions';

export type StudentEmploymentsState = ICommonResponse<IStudentEmployment>;

const initialState = {
  content: [],
  loading: false
} as ICommonResponse<IStudentEmployment>;

const studentEmployments: IReducers<StudentEmploymentsState> = {
  [GET_STUDENT_EMPLOYMENTS[REQUEST]]: genericReducers().get.request,
  [GET_STUDENT_EMPLOYMENTS[SUCCESS]]: genericReducers().get.success,
  [GET_STUDENT_EMPLOYMENTS[FAILURE]]: genericReducers().get.failure,
  [GET_STUDENT_EMPLOYMENTS[INVALIDATE]]: genericReducers().get.invalidate(initialState),
};

export default connectReducers(initialState, studentEmployments);
