import { FC, ReactNode } from 'react';

import { Box, Typography } from '@mui/material';
import { IconInfoOutline } from 'icons';
import { NeutralColors } from 'portfolio3/styles';
import { Tooltip } from 'ui-kit';

import * as styles from './styles';

interface IInfoRowProps {
  name: string;
  value: ReactNode;
  tooltip?: string;
  onValueClick?: () => void;
}

const InfoRow: FC<IInfoRowProps> = ({ name, value, tooltip, onValueClick }) => {
  return (
    <Box component="li" className="info-row" sx={styles.root}>
      <Box component="p" className="info-row__name-container" sx={styles.name}>
        <Typography
          component="span"
          className="info-row__name"
          variant="Paragraph MD/Regular"
          color={NeutralColors.light_neutrals_600}
        >
          {name}
          {tooltip && (
            <Tooltip title={tooltip} arrow placement="top" width="220px">
              <Box sx={styles.iconContainer}>
                <IconInfoOutline
                  sx={{
                    width: 24,
                    height: 24,
                    color: NeutralColors.light_neutrals_600,
                  }}
                />
              </Box>
            </Tooltip>
          )}
        </Typography>
      </Box>
      <Box
        component="p"
        className="info-row__value"
        onClick={onValueClick}
        sx={styles.value(onValueClick !== undefined)}
      >
        {value}
      </Box>
    </Box>
  );
};

export default InfoRow;
