import { FAILURE, REQUEST, SUCCESS } from 'actions/utils';
import { ICommonResponseObject } from 'api/types';
import { IReducers } from 'reducers';
import genericReducers from 'reducers/genericReducer';
import { connectReducers } from 'utils';

import { DELETE_FAVORITE_VUZ } from '../actions';

export type DeleteFavoriteVuzState = ICommonResponseObject<null>;

const initialState: DeleteFavoriteVuzState = {
  content: null,
  loading: false,
};

const deleteFavoriteVuz: IReducers<DeleteFavoriteVuzState> = {
  [DELETE_FAVORITE_VUZ[REQUEST]]: genericReducers().delete.request,
  [DELETE_FAVORITE_VUZ[SUCCESS]]: genericReducers().delete.success,
  [DELETE_FAVORITE_VUZ[FAILURE]]: genericReducers().delete.failure,
};

export default connectReducers(initialState, deleteFavoriteVuz);
