import { FC } from 'react';

import {
  FormControlLabel as MaterialFormControlLabel,
  formControlLabelClasses,
  FormControlLabelProps,
  styled,
} from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';
import { getToggleLabelTokens } from 'portfolio3/ui-kit/utils';
import { numberToPixelsString } from 'utils';

import { CommonUiKitSize } from '../../types';

export interface IBaseToggleLabelProps extends FormControlLabelProps {
  size?: CommonUiKitSize;
}

type StyledFormControlLabelProps = Pick<IBaseToggleLabelProps, 'size'>;

const StyledFormControlLabel = styled(MaterialFormControlLabel)<StyledFormControlLabelProps>(({ size }) => {
  const tokens = size ? getToggleLabelTokens(size) : getToggleLabelTokens('medium');
  const { fontSize, lineHeight } = tokens;

  return {
    display: 'flex',
    alignItems: 'center',
    gap: 12,

    margin: 0,

    [`& .${formControlLabelClasses.label}`]: {
      fontSize,
      fontWeight: 400,
      lineHeight: numberToPixelsString(lineHeight),
      color: NeutralColors.light_neutrals_900,
    },

    [`& .${formControlLabelClasses.disabled}.${formControlLabelClasses.label}`]: {
      color: NeutralColors.light_neutrals_500,
    },

    [`&.${formControlLabelClasses.labelPlacementStart}`]: {
      justifyContent: 'flex-end',
    },

    [`&.${formControlLabelClasses.labelPlacementTop}, &.${formControlLabelClasses.labelPlacementBottom}`]: {
      width: 'max-content',
    },
  };
});

/**
 * Компонент для добавления подписей к контролам Radio, Checkbox, Toggle
 */
const BaseToggleLabel: FC<IBaseToggleLabelProps> = (props) => {
  return <StyledFormControlLabel {...props} />;
};

export default BaseToggleLabel;
