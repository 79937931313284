import { FC, Fragment } from 'react';

import FixedWidthContainer from 'portfolio3/components/common/FixedWidthContainer';
import { GlobalFooterContainer } from 'portfolio3/containers/footers';
import { EmployeeHeaderContainer, GlobalHeaderContainer } from 'portfolio3/containers/headers';
import { compose } from 'redux';

import {
  withDrawers,
  withErrorBoundary,
  withFooter,
  withHeader,
  withReferenceData,
  withRendererType,
  withThemeBackground,
} from '../hocs';

interface IEmployeeRendererProps {
  render: FC;
}

const isContainersFullWidth = true;

const EmployeeRenderer: FC<IEmployeeRendererProps> = ({ render: Component }) => {
  const Container = isContainersFullWidth ? Fragment : FixedWidthContainer;

  return (
    <Container>
      <Component />
    </Container>
  );
};

export default compose<FC<IEmployeeRendererProps>>(
  withRendererType('employee'),
  withErrorBoundary(),
  withReferenceData(),
  withDrawers(),
  withThemeBackground(),
  withHeader(GlobalHeaderContainer, isContainersFullWidth),
  withHeader(EmployeeHeaderContainer, isContainersFullWidth),
  withFooter(GlobalFooterContainer, isContainersFullWidth),
)(EmployeeRenderer);
