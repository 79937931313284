/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import './index.scss';

interface IMobileDragIndicatorProps {
  onTouchStart: (event?: React.TouchEvent<HTMLButtonElement>) => void;
}

const MobileDragIndicator: React.FC<IMobileDragIndicatorProps> = ({
  onTouchStart
}) => (
  <div className="mobile-drag-container">
    <div className="mobile-drag-container__handle" />
    <button className="mobile-drag-container__button" type="button" onTouchStart={onTouchStart} />
  </div>
);

export default MobileDragIndicator;
