import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const Artist: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="230" height="129" viewBox="0 0 230 129" xmlns="http://www.w3.org/2000/svg" {...props} fill="none">
    {/*eslint-disable max-len */}
    <path
      d="M68.497 107.818c-.91 0-1.767-.522-2.082-1.352L53.456 72.569a.317.317 0 0 1 .015-.26.353.353 0 0 1 .219-.172l3.194-1.016c.195-.061.413.035.481.213l13.048 34.13c.173.446.143.92-.082 1.345a1.89 1.89 0 0 1-1.098.899c-.248.068-.496.11-.736.11ZM54.29 72.672l12.83 33.567c.263.693 1.098 1.057 1.85.817.3-.096.533-.288.668-.549a.986.986 0 0 0 .053-.823l-12.92-33.808-2.481.796Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M53.817 72.802a.356.356 0 0 1-.218-.068.351.351 0 0 1-.158-.288l.113-4.399c0-.144.105-.267.248-.315a.396.396 0 0 1 .414.103l3.081 3.383c.076.089.106.205.076.308-.03.11-.12.192-.241.234l-3.194 1.015a.256.256 0 0 1-.12.027Zm.459-3.821-.075 2.991 2.172-.693-2.097-2.298ZM72.653 104.902c-.398 0-.782-.096-1.135-.288a2.028 2.028 0 0 1-1-1.242L61.04 68.535a.32.32 0 0 1 .046-.261.386.386 0 0 1 .233-.158l3.284-.74c.203-.049.406.068.459.246l9.545 35.084c.128.46.046.933-.218 1.331-.263.405-.691.686-1.187.803a2.554 2.554 0 0 1-.549.062ZM61.86 68.699l9.388 34.508c.09.343.33.631.661.816.33.186.722.241 1.097.151a1.13 1.13 0 0 0 .722-.487.984.984 0 0 0 .135-.816l-9.455-34.748-2.548.576Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M61.4 68.796a.395.395 0 0 1-.247-.083.333.333 0 0 1-.128-.295l.556-4.37c.015-.145.128-.261.286-.296.15-.034.308.02.398.137l2.729 3.63a.342.342 0 0 1 .045.316.38.38 0 0 1-.263.213l-3.285.74c-.03 0-.06.008-.09.008Zm.813-3.767-.376 2.97 2.232-.507-1.856-2.463ZM32.696 94.692H15.408c-.21 0-.376-.151-.376-.343V34.651c0-.192.166-.343.376-.343h10.515c.21 0 .376.15.376.343 0 .192-.165.343-.376.343h-10.14v59.01h16.913c.21 0 .375.151.375.344 0 .192-.165.343-.375.343Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M19.595 69.303h-4.187c-.21 0-.376-.15-.376-.343V34.652c0-.192.166-.343.376-.343s.376.15.376.343v33.965h3.81c.211 0 .377.151.377.343 0 .192-.173.343-.376.343Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M32.38 94.904c-.022 0-.045 0-.06-.007l-17.047-2.635c-.203-.034-.346-.206-.308-.398l10.906-58.86a.338.338 0 0 1 .15-.219.398.398 0 0 1 .279-.062l10.605 1.64c.203.035.346.206.308.398a.374.374 0 0 1-.436.282L26.54 33.464l-10.786 58.18 16.672 2.574c.202.034.345.206.308.398-.015.171-.173.288-.354.288Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M24.519 67.938c-.023 0-.045 0-.06-.007l-4.54-.7c-.203-.034-.346-.206-.309-.398l6.27-33.828a.338.338 0 0 1 .15-.22.398.398 0 0 1 .278-.062l10.605 1.64c.203.035.346.206.308.398a.374.374 0 0 1-.436.282l-10.237-1.579-6.14 33.15 4.17.644c.204.035.347.206.309.398-.03.165-.188.282-.368.282Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M32.922 94.266a.37.37 0 0 1-.12-.02L16.445 89.14c-.196-.061-.3-.254-.233-.432l21.166-56.486a.34.34 0 0 1 .188-.199.375.375 0 0 1 .285-.013l16.356 5.105c.195.062.3.254.233.432L33.275 94.033a.34.34 0 0 1-.188.199.442.442 0 0 1-.165.034ZM17.04 88.598l15.649 4.886L53.614 37.65l-15.649-4.886L17.04 88.599Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M41.926 70.243a.37.37 0 0 1-.12-.02l-16.363-5.105c-.195-.062-.3-.254-.233-.433l12.161-32.463a.34.34 0 0 1 .188-.199.375.375 0 0 1 .286-.013L54.2 37.115c.196.062.301.254.233.432L42.273 70.01a.34.34 0 0 1-.189.199.312.312 0 0 1-.158.034Zm-15.882-5.66 15.65 4.885 11.92-31.811-15.649-4.886-11.92 31.811ZM31.193 91.26c-1.45 0-2.63-1.077-2.63-2.4 0-1.325 1.18-2.403 2.63-2.403s2.63 1.078 2.63 2.402-1.18 2.402-2.63 2.402Zm0-4.116c-1.037 0-1.879.768-1.879 1.715 0 .947.842 1.716 1.88 1.716 1.037 0 1.878-.769 1.878-1.716s-.842-1.715-1.879-1.715ZM165.172 121.068h-2.443c-.21 0-.376-.151-.376-.343 0-.192.166-.343.376-.343h2.067v-51.03h-39.092v1.104c0 .192-.166.343-.376.343s-.376-.15-.376-.343v-1.448c0-.192.166-.343.376-.343h39.844c.211 0 .376.151.376.343v51.717c0 .185-.165.343-.376.343Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M167.614 119.647h-2.442c-.211 0-.376-.151-.376-.343 0-.192.165-.343.376-.343h2.067v-51.03h-39.093V69c0 .192-.165.343-.376.343-.21 0-.375-.15-.375-.343v-1.413c0-.192.165-.343.375-.343h39.844c.211 0 .376.15.376.343v51.716c0 .192-.165.343-.376.343Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M162.737 122.481h-39.845c-.21 0-.375-.151-.375-.343V70.422c0-.192.165-.343.375-.343h39.845c.21 0 .375.15.375.343v51.716c0 .192-.165.343-.375.343Zm-39.469-.686h39.093v-51.03h-39.093v51.03ZM173.252 60.383c-.211 0-.376-.151-.376-.343 0-.192.165-.343.376-.343l39.664-.083c2.961 0 5.366-2.195 5.366-4.899V14.334c0-2.704-2.405-4.9-5.366-4.9h-70.353c-2.962 0-5.367 2.196-5.367 4.9v40.381c0 2.704 2.405 4.9 5.367 4.9h26.472c.211 0 .376.15.376.342 0 .193-.165.343-.376.343h-26.472c-3.375 0-6.119-2.504-6.119-5.585V14.334c0-3.08 2.744-5.585 6.119-5.585h70.353c3.374 0 6.118 2.504 6.118 5.585v40.381c0 3.081-2.744 5.585-6.118 5.585l-39.664.083Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M211.082 58.976H172.5c-.21 0-.376-.15-.376-.343 0-.192.166-.343.376-.343h38.582c2.961 0 5.366-2.196 5.366-4.9V15.659c0-2.703-2.405-4.9-5.366-4.9h-66.685c-2.962 0-5.367 2.197-5.367 4.9v37.733c0 2.703 2.405 4.899 5.367 4.899h24.135c.21 0 .375.15.375.343 0 .192-.165.343-.375.343h-24.135c-3.375 0-6.119-2.504-6.119-5.585V15.658c0-3.08 2.744-5.585 6.119-5.585h66.685c3.374 0 6.118 2.504 6.118 5.585v37.733c0 3.08-2.744 5.585-6.118 5.585Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M177.484 78.998c-.909 0-1.766-.521-2.082-1.351L162.444 43.75a.318.318 0 0 1 .015-.261.352.352 0 0 1 .218-.171l3.194-1.016c.196-.062.414.034.481.213l13.049 34.13c.173.446.142.92-.083 1.345a1.885 1.885 0 0 1-1.097.898c-.248.07-.496.11-.737.11Zm-14.206-35.145 12.831 33.567c.263.693 1.097 1.057 1.849.817.3-.096.533-.288.668-.55a.985.985 0 0 0 .053-.823l-12.92-33.807-2.481.796Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M162.805 43.983a.354.354 0 0 1-.218-.068.35.35 0 0 1-.158-.289l.112-4.398c0-.144.106-.268.248-.316a.397.397 0 0 1 .414.103l3.082 3.383a.34.34 0 0 1 .075.309c-.03.11-.121.192-.241.233l-3.194 1.016a.258.258 0 0 1-.12.027Zm.458-3.822-.075 2.992 2.172-.693-2.097-2.299ZM180.647 48.89c-.157 0-.3-.09-.353-.227-.06-.158.008-.33.166-.418.15-.083 15.325-8.125 15.288-12.516-.008-.7-.429-1.25-1.293-1.688-7.193-3.664-7.479-8.172-7.268-9.936.315-2.67 2.285-4.872 5.013-5.627 3.48-.96 7.329.529 10.561 4.076 4.103 4.502 5.419 8.722 3.916 12.543-1.639 4.166-6.525 7.754-14.499 10.664-5.901 2.154-11.403 3.108-11.455 3.122-.03.006-.053.006-.076.006Zm13.665-30.007c-.639 0-1.27.083-1.894.254-2.435.673-4.194 2.649-4.48 5.043-.413 3.452 2.097 6.828 6.885 9.264 1.105.563 1.669 1.331 1.676 2.285.03 3.794-9.44 9.613-13.679 12.022 5.637-1.263 20.128-5.215 23.15-12.88 1.413-3.575.135-7.568-3.781-11.87-2.443-2.684-5.224-4.118-7.877-4.118Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M179.13 46.961a.451.451 0 0 1-.203-.055.33.33 0 0 1-.143-.432c.03-.062 3.172-6.354 1.894-9.003a1.78 1.78 0 0 0-1.15-.974c-2.458-.727-3.901-2.683-4.201-4.192-.203-1.03.097-1.922.826-2.45.812-.59 2.511-1.009 5.976.357 1.796.707 2.916 1.845 3.315 3.383 1.338 5.118-5.728 12.906-6.028 13.236a.37.37 0 0 1-.286.13Zm-.932-16.969c-.827 0-1.308.213-1.579.405-.691.5-.646 1.338-.556 1.79.271 1.36 1.624 3.047 3.698 3.658.737.22 1.278.673 1.609 1.352.872 1.825-.023 4.975-.857 7.164 2.022-2.649 5.058-7.329 4.202-10.602-.346-1.33-1.293-2.285-2.879-2.91-1.646-.644-2.811-.857-3.638-.857ZM176.153 45.973a.38.38 0 0 1-.323-.172 302.56 302.56 0 0 0-3.645-5.482c-.669-.981-2.225-1.318-3.473-1.585-1.188-.254-2.12-.46-2.277-1.14-.158-.699.631-1.564 2.826-3.073 1.277-.885 2.442-1.098 3.465-.645 3.593 1.599 3.795 11.335 3.795 11.754a.353.353 0 0 1-.27.336c-.03 0-.068.007-.098.007Zm-4.525-11.644c-.571 0-1.202.247-1.916.734-2.413 1.66-2.578 2.223-2.541 2.388.06.254.977.453 1.714.617 1.383.302 3.104.673 3.938 1.894a284.75 284.75 0 0 1 2.894 4.337c-.21-3.239-1.045-8.79-3.322-9.806a1.826 1.826 0 0 0-.767-.164ZM95.082 39.798h-.045c-.707-.069-6.938-.741-8.494-2.155a.36.36 0 0 1-.105-.185 28.47 28.47 0 0 1-.323-6.34c.015-.185.165-.33.398-.322.21.013.368.171.353.363-.195 2.848.203 5.496.3 6.073 1.256.981 5.826 1.66 7.765 1.866 3.653-3.89 5.074-8.851 4.585-11.775-.165-.98-.548-1.68-1.075-1.976-2.487-1.386-7.02-3.91-9.808-.103-.068.096-.136.186-.196.288-.105.165-.338.213-.518.117a.328.328 0 0 1-.128-.473c.067-.11.143-.213.218-.316 3.18-4.357 8.305-1.503 10.763-.137.79.439 1.285 1.29 1.488 2.49.527 3.116-.992 8.4-4.893 12.468a.406.406 0 0 1-.285.117Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M92.826 22.644a.377.377 0 0 1-.225-.069c-.128-.089-3.112-2.148-3.886-4.281-.692-1.887-1.729-4.735 2.819-6.787 1.796-.81 3.224-.96 4.374-.48 1.195.508 2.007 1.66 2.548 3.644.774 2.813.767 4.926-.007 7.053a.365.365 0 0 1-.27.227.404.404 0 0 1-.354-.09c-.083-.075-2.037-1.77-4.728.687a.43.43 0 0 1-.27.096Zm1.662-11.198c-.745 0-1.617.226-2.624.679-3.96 1.784-3.119 4.096-2.443 5.956.58 1.592 2.654 3.239 3.375 3.774 2.308-1.949 4.232-1.283 5.081-.796.572-1.846.519-3.74-.165-6.217-.481-1.763-1.157-2.772-2.135-3.184a2.615 2.615 0 0 0-1.09-.212Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M96.472 24.263h-.015c-.21-.007-.369-.165-.361-.357l.113-2.991c.007-.192.188-.343.39-.33.211.007.369.165.361.357l-.112 2.992c-.008.185-.173.33-.376.33ZM102.403 29.814c-.804 0-1.549-.096-2.202-.336-.196-.069-.286-.268-.21-.446.074-.178.292-.26.488-.192 3.217 1.173 9.636-1.558 10.891-2.12l-.105-2.018c-9.148.035-11.44 1.304-11.463 1.318a.399.399 0 0 1-.519-.103.324.324 0 0 1 .106-.474c.09-.055 2.458-1.427 12.229-1.427.203 0 .368.144.376.323l.15 2.573a.342.342 0 0 1-.203.322c-.271.13-5.547 2.58-9.538 2.58Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M113.872 26.075a.456.456 0 0 1-.21-.055l-2.255-1.372a.34.34 0 0 1-.158-.378c.09-.316 2.323-7.685 5.397-9.791.15-.097 1.353-.796 2.187-.392.444.22.669.673.669 1.352 0 .028 0 .048-.007.069-.233 1.002-2.398 9.833-5.54 10.553-.022.014-.052.014-.083.014Zm-1.818-1.86 1.879 1.146c2.209-.775 4.216-7.37 4.825-9.95-.007-.274-.06-.603-.27-.706-.354-.172-1.105.165-1.399.343-2.563 1.743-4.622 7.877-5.035 9.167Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M112.4 27.063c-.429 0-.812-.192-1.045-.405a.32.32 0 0 1 0-.487c.15-.13.383-.13.526 0 .12.103.534.405.977 0 .173-.158.263-.302.256-.405-.008-.082-.09-.144-.09-.144a.328.328 0 0 1-.091-.474.395.395 0 0 1 .511-.089c.038.02.369.233.414.638.037.323-.128.645-.474.961a1.426 1.426 0 0 1-.984.405ZM119.164 15.034c-.256 0-.323-.034-.361-.048a.334.334 0 0 1-.203-.357.405.405 0 0 1-.406-.103c-.368-.405-.864-1.358-.075-2.072 2.074-1.894 3.585-2.813 4.435-2.724a.915.915 0 0 1 .684.377c.526.748.007 1.984-.241 2.478-.526 1.063-1.488 2.195-2.443 2.326-.729.096-1.142.123-1.39.123Zm3.262-4.625c-.496 0-1.729.66-3.781 2.532-.496.453.098 1.119.12 1.146a.323.323 0 0 1 .083.268.439.439 0 0 1 .211-.007c.052.007.353.02 1.375-.117.549-.075 1.368-.92 1.872-1.928.39-.782.503-1.516.293-1.818-.03-.041-.06-.062-.128-.069-.015-.007-.03-.007-.045-.007ZM87.37 41.383a.364.364 0 0 1-.15-.027c-.129-.048-.782-.378-.782-1.9 0-1.51.638-1.867.759-1.922a.395.395 0 0 1 .503.165c.083.164.015.356-.158.446-.03.02-.353.28-.353 1.31 0 1.057.346 1.276.346 1.276.188.076.27.268.188.447-.06.13-.203.205-.353.205Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M95.36 49.706h-8.922a.393.393 0 0 1-.309-.144c-1.969-2.552.233-8.282.331-8.529.053-.13.18-.22.338-.226l7.517-.323c.112 0 .233.041.308.13 3.104 3.541 1.172 8.653 1.09 8.866a.373.373 0 0 1-.354.227Zm-8.72-.686h8.441c.323-.995 1.406-5.009-.932-7.843l-7.065.302c-.36 1.036-1.82 5.462-.444 7.541Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M90.233 49.706h-.022c-.21-.014-.369-.171-.354-.363.429-6.746 4.24-7.46 4.397-7.487h.008c1.902 0 2.06 2.773 2.06 2.896.007.192-.15.35-.361.357-.248-.007-.384-.137-.391-.33 0-.02-.143-2.243-1.248-2.243.038 0-3.322.68-3.72 6.84 0 .193-.166.33-.369.33ZM94.33 41.17c-.18 0-.345-.123-.368-.294l-.218-1.373c-.03-.185.113-.364.316-.391.203-.027.398.103.428.288l.218 1.373c.03.185-.112.363-.315.39-.023.008-.046.008-.06.008Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M89.181 52.98c-1.037 0-2.352-.234-3.066-1.29-.504-.742-.662-1.386-.489-1.908.173-.515.609-.707.654-.727.188-.076.413 0 .496.171.083.172.008.37-.18.453a.534.534 0 0 0-.256.322c-.068.227-.053.639.414 1.332.856 1.276 3.104.947 3.615.85.248-.253 1.03-1.166.699-2.078a5.992 5.992 0 0 1-.105-.234c-.075-.171.015-.364.203-.439a.396.396 0 0 1 .488.158c.045.089.09.171.12.26.21.412.481.783.624.817.203.048.324.233.27.419-.052.185-.255.295-.458.247a1.107 1.107 0 0 1-.383-.192c-.24.892-.954 1.557-1 1.598a.37.37 0 0 1-.172.09c-.053.006-.677.15-1.474.15Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M89.332 52.753c-.188 0-.353-.13-.376-.309-.022-.185.135-.357.338-.37.617-.055.564-1.27.564-1.277-.015-.185.143-.35.353-.363.203-.014.384.13.399.322.052.7-.158 1.914-1.248 2.004-.007-.014-.022-.007-.03-.007ZM103.35 61.07a.431.431 0 0 1-.181-.042c-9.643-4.741-11.47-6.896-11.47-9.222 0-1.722 2.09-2.21 3.3-2.347a.337.337 0 0 1 .03-.254c.098-.164.33-.226.511-.137 4.811 2.374 10.147 9.12 10.373 9.4.082.104.09.24.03.358a.386.386 0 0 1-.339.185c-.285 0-1.21.906-1.939 1.907a.403.403 0 0 1-.315.151Zm-7.412-10.993a.486.486 0 0 1-.105.014c-.03 0-3.382.027-3.382 1.715 0 1.99 1.774 4.021 10.778 8.474.429-.562 1.135-1.393 1.782-1.763-1.105-1.352-5.224-6.196-9.073-8.44Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M104.102 61.756c-.023 0-.045 0-.076-.007-.052-.007-.496-.096-.721-.487-.173-.295-.165-.66.022-1.084.076-.179.301-.261.489-.186.195.069.286.275.203.446-.098.213-.12.391-.06.494a.347.347 0 0 0 .15.124l2.496-.57c-.211-.405-.692-1.063-1.662-1.221-.202-.034-.345-.213-.308-.398a.375.375 0 0 1 .436-.281c1.887.295 2.375 1.976 2.39 2.051a.347.347 0 0 1-.037.261.387.387 0 0 1-.233.158l-3.007.686c-.022.014-.052.014-.082.014Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M109.641 76.817c-.18 0-.353-.117-.383-.275-.015-.068-.03-.13-.008-.178-.428-1.29-4.818-10.149-5.479-11.363-.812-1.49-.045-3.376-.015-3.458a.38.38 0 0 1 .481-.2c.195.07.293.268.218.44-.007.014-.684 1.688-.015 2.91.053.095 4.623 8.981 5.434 11.369.399-.11 1.03-.254 2.03-.487-1.722-5.476-4.525-14.012-5.066-14.616a.328.328 0 0 1 .03-.48.39.39 0 0 1 .511 0c.759.693 4.292 11.843 5.359 15.247.03.089.015.185-.038.267a.357.357 0 0 1-.233.158c-.894.206-2.322.535-2.645.618a.27.27 0 0 1-.121.034c-.022.014-.045.014-.06.014Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M108.611 79.596a.389.389 0 0 1-.338-.192c-.316-.583-.534-1.853.917-2.518a.396.396 0 0 1 .33 0c.053.02 1.293.549 2.578-.618a.323.323 0 0 1 .136-.075c.18-.062 4.449-1.51 6.366-.673.444.192.744.494.887.885.105.288.06.584-.135.851-1.271 1.722-9.757 2.285-10.719 2.347-.007-.007-.015-.007-.022-.007Zm.774-2.024c-.707.398-.631 1.002-.526 1.317 3.518-.233 9.087-.967 9.869-2.024.083-.11.067-.185.045-.254-.075-.213-.241-.37-.496-.487-1.316-.576-4.495.268-5.705.672-1.345 1.194-2.691.934-3.187.776ZM90.572 28.133a.39.39 0 0 1-.339-.192c-.699-1.276-2.322-2.306-2.337-2.32a.352.352 0 0 1-.158-.226.331.331 0 0 1 .068-.26l.879-1.078c.09-.082 1.157-.967 2.21-.803.518.083.924.384 1.225.892.128.227.24.494.323.803.293 1.07.196 1.9-.293 2.47-.594.707-1.54.714-1.578.714Zm-1.94-2.861c.511.363 1.534 1.16 2.15 2.147.218-.04.549-.144.782-.418.33-.385.376-1.036.15-1.873a2.94 2.94 0 0 0-.255-.652c-.18-.316-.406-.494-.684-.536-.557-.089-1.293.392-1.526.584l-.617.748Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M89.767 25.69c-.022 0-.045 0-.067-.006-.203-.035-.339-.213-.301-.398.007-.055.293-1.304 2.285-1.304h.113c.21.007.368.165.368.35-.008.185-.173.336-.376.336h-.098c-1.375 0-1.548.741-1.548.748-.038.158-.195.274-.376.274ZM80.049 34.995a.443.443 0 0 1-.165-.035c-.286-.13-1.714-.823-1.714-1.68 0-.955 6.584-7.967 9.906-8.276.203-.02.391.117.414.309.022.185-.128.357-.338.377-3 .281-9.058 6.992-9.23 7.623 0 .254.654.714 1.187.975 3.63-.775 10.14-6.683 10.207-6.745a.397.397 0 0 1 .534 0 .32.32 0 0 1 0 .487c-.278.254-6.892 6.258-10.718 6.958-.03.007-.06.007-.083.007Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M73.652 44.766c-.045 0-.097-.007-.142-.014-.88-.172-.67-1.482-.602-1.915.76-4.844 4.435-8.92 4.593-9.091a.396.396 0 0 1 .413-.103c.15.048.248.178.248.322 0 1.016 1.864 1.03 1.88 1.03.12 0 .225.048.3.137.068.082.09.199.06.302-1.03 3.314-4.953 9.332-6.75 9.332Zm3.984-10.053c-1.007 1.25-3.405 4.55-3.976 8.22-.158.982.03 1.153.03 1.153h.03c.85 0 4.465-4.535 5.833-8.44-.684-.082-1.526-.336-1.917-.933Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M69.774 46.742c-.398 0-.767-.034-1.075-.09a.395.395 0 0 1-.195-.095c-.188-.172-.316-.487.105-1.05.707-.933 3.45-2.93 4.63-2.45.308.13.632.453.406 1.283-.519 1.908-2.398 2.402-3.87 2.402Zm-.631-.72c.85.089 3.232.157 3.78-1.846.09-.343.038-.474.023-.487-.037-.028-.263-.062-.782.137-1.285.5-2.72 1.702-3.021 2.196ZM77.794 34.995c-.21 0-.376-.151-.376-.343 0-.872.902-1.366.94-1.386a.39.39 0 0 1 .51.123c.106.165.046.37-.135.467-.007 0-.563.315-.563.789 0 .199-.166.35-.376.35ZM79.357 35.612a.4.4 0 0 1-.27-.103.325.325 0 0 1 .007-.487l.692-.617c.15-.13.383-.13.533.006a.325.325 0 0 1-.007.488l-.692.617a.425.425 0 0 1-.263.096ZM85.31 67.931c-.827 0-1.413-.227-1.751-.672-.564-.748-.128-1.812-.105-1.853.075-.178.293-.26.48-.2.196.07.294.269.219.447-.09.213-.256.844.022 1.221.188.247.564.37 1.127.37 1.143 0 1.3-1.42 1.308-1.475.023-.185.21-.322.406-.308.203.02.361.185.338.37-.06.728-.586 2.1-2.044 2.1Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M79.275 79.65a1.674 1.674 0 0 1-1.39-.714 1.586 1.586 0 0 1-.249-1.303c.098-.384.226-.871.361-1.406.76-2.882 1.338-4.68 1.706-5.36 1.511-2.764 3.405-4.48 3.48-4.549a.413.413 0 0 1 .534 0 .33.33 0 0 1 0 .488c-.022.013-1.879 1.715-3.337 4.37-.338.618-.925 2.464-1.646 5.215-.143.536-.263 1.016-.361 1.4a.968.968 0 0 0 .143.782.961.961 0 0 0 .593.385c.391.075.58-.138.602-.165l6.344-11.356c.09-.172.323-.233.503-.151.188.09.256.295.166.46L80.35 79.15c-.158.22-.534.5-1.075.5Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M78.147 85.82c-.406 0-.774-.124-1.082-.364-1.623-1.263-1.323-5.517-1.263-6.19-.128-.72-.571-3.74 1.03-4.014.308-.089 1.097-.04 1.578.886.09.171.016.377-.172.46-.188.082-.414.013-.504-.159-.316-.596-.691-.528-.73-.514-.683.117-.69 1.976-.45 3.273a.179.179 0 0 1 0 .09c-.12 1.262-.166 4.747 1 5.646.248.2.54.247.909.165.556-.124.917-.398 1.113-.824.51-1.111-.279-2.97-.52-3.45-.12-.124-.187-.481-.375-1.483-.038-.185.098-.364.308-.398.203-.034.399.09.436.281.098.529.218 1.119.248 1.201h-.007c.45.61.774 2.175.774 2.196.278 1.695-.353 2.806-1.789 3.129a1.94 1.94 0 0 1-.504.068ZM90.865 85.174c-.21 0-.376-.15-.376-.343l-.022-27.467a56.18 56.18 0 0 1-.872 3.623c-1.458 5.098-2.676 5.366-3.074 5.455-1.233.274-2.128.22-2.654-.158-.428-.309-.443-.714-.443-.755 0-12.385 2.676-14.23 2.788-14.306a.405.405 0 0 1 .526.082c.12.158.076.37-.09.48-.015.014-2.472 1.908-2.472 13.744 0 0 .014.124.172.227.196.13.707.302 1.992.02 1.864-.411 3.856-9.977 4.12-12.68v-.686c0-.144.097-.275.255-.323a.402.402 0 0 1 .383.076c.045.034.068.123.083.247h.03l.022 32.421c.008.185-.157.343-.368.343Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M90.947 89.202c-5.54 0-11.274-1.413-11.274-3.774 0-2.36 5.735-3.774 11.274-3.774 5.54 0 11.275 1.414 11.275 3.774 0 2.36-5.735 3.774-11.275 3.774Zm0-6.861c-6.2 0-10.523 1.626-10.523 3.087 0 1.462 4.322 3.088 10.523 3.088s10.523-1.626 10.523-3.088c0-1.461-4.322-3.087-10.523-3.087Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M90.948 91.947c-5.833 0-12.026-1.324-12.026-3.774 0-.7.519-1.338 1.54-1.908a.4.4 0 0 1 .52.117c.105.165.052.37-.128.474-.542.295-1.18.768-1.18 1.317 0 1.462 4.63 3.088 11.274 3.088 6.645 0 11.275-1.626 11.275-3.088 0-.549-.647-1.022-1.181-1.317a.327.327 0 0 1-.127-.474c.105-.165.338-.213.518-.117 1.023.57 1.541 1.208 1.541 1.908 0 2.45-6.193 3.774-12.026 3.774ZM96.676 22.644c-.203 0-.376-.151-.376-.336 0-.186.165-.343.368-.35.496-.014 1.052-.158 1.052-.343 0-.193.166-.343.376-.343s.376.15.376.343c0 .72-.91 1.001-1.766 1.029h-.03ZM154.461 89.888c-3.525 0-6.389-2.614-6.389-5.832s2.864-5.832 6.389-5.832c3.525 0 6.389 2.614 6.389 5.832s-2.864 5.832-6.389 5.832Zm0-10.978c-3.112 0-5.637 2.305-5.637 5.146 0 2.84 2.525 5.146 5.637 5.146 3.112 0 5.637-2.305 5.637-5.146 0-2.84-2.525-5.146-5.637-5.146ZM135.67 109.787a.485.485 0 0 1-.135-.02.338.338 0 0 1-.233-.268L132.1 90.973a.327.327 0 0 1 .12-.31.406.406 0 0 1 .353-.068l17.829 4.803c.166.048.278.186.271.35-.008.158-.135.288-.308.316l-10.05 1.667 8.231 2.128c.135.034.24.137.263.26a.337.337 0 0 1-.136.336l-12.777 9.264a.413.413 0 0 1-.226.068Zm-2.736-18.389 3.007 17.408 11.695-8.481-9.087-2.347c-.165-.04-.286-.185-.271-.343a.357.357 0 0 1 .309-.322l10.086-1.675-15.739-4.24ZM139.428 85.085h-9.771c-.21 0-.376-.151-.376-.343v-8.92c0-.193.166-.344.376-.344h9.771c.211 0 .376.151.376.344v8.92c0 .192-.165.343-.376.343Zm-9.395-.686h9.019v-8.234h-9.019v8.234ZM194.237 104.641h-10.124c-1.676 0-3.037-1.242-3.037-2.772v-9.243c0-1.53 1.361-2.772 3.037-2.772h10.124c1.676 0 3.037 1.242 3.037 2.772v9.243c0 1.53-1.361 2.772-3.037 2.772Zm-10.132-14.108c-1.262 0-2.285.94-2.285 2.086v9.243c0 1.153 1.03 2.086 2.285 2.086h10.125c1.263 0 2.285-.94 2.285-2.086v-9.243c0-1.153-1.03-2.086-2.285-2.086h-10.125Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M194.936 90.533h-11.53c-1.285 0-2.338-.96-2.338-2.134v-2.552c0-1.173 1.045-2.134 2.338-2.134h11.53c1.285 0 2.338.96 2.338 2.134v2.552c0 1.174-1.045 2.134-2.338 2.134Zm-11.53-6.134c-.872 0-1.586.652-1.586 1.448v2.552c0 .796.714 1.448 1.586 1.448h11.53c.872 0 1.586-.652 1.586-1.448v-2.552c0-.796-.714-1.448-1.586-1.448h-11.53ZM196.898 94.211h-15.454c-.21 0-.376-.15-.376-.343 0-.192.166-.343.376-.343h15.454c.21 0 .376.15.376.343 0 .192-.166.343-.376.343ZM196.898 102.185h-15.454c-.21 0-.376-.151-.376-.343 0-.193.166-.343.376-.343h15.454c.21 0 .376.15.376.343 0 .192-.166.343-.376.343Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M194.545 90.533c-.211 0-.376-.15-.376-.343v-4.295c0-.192.165-.343.376-.343.21 0 .376.15.376.343v4.295c0 .192-.166.343-.376.343ZM193.2 90.533c-.21 0-.376-.15-.376-.343v-4.295c0-.192.166-.343.376-.343s.376.15.376.343v4.295c0 .192-.166.343-.376.343ZM191.862 90.533c-.21 0-.376-.15-.376-.343v-4.295c0-.192.166-.343.376-.343.211 0 .376.15.376.343v4.295c0 .192-.173.343-.376.343ZM190.516 90.533c-.21 0-.375-.15-.375-.343v-4.295c0-.192.165-.343.375-.343.211 0 .376.15.376.343v4.295c0 .192-.173.343-.376.343ZM189.171 90.533c-.211 0-.376-.15-.376-.343v-4.295c0-.192.165-.343.376-.343.21 0 .376.15.376.343v4.295c0 .192-.166.343-.376.343ZM187.825 90.533c-.21 0-.376-.15-.376-.343v-4.295c0-.192.166-.343.376-.343s.376.15.376.343v4.295c0 .192-.166.343-.376.343ZM186.48 90.533c-.21 0-.376-.15-.376-.343v-4.295c0-.192.166-.343.376-.343.211 0 .376.15.376.343v4.295c0 .192-.165.343-.376.343ZM185.142 90.533c-.21 0-.375-.15-.375-.343v-4.295c0-.192.165-.343.375-.343.211 0 .376.15.376.343v4.295c0 .192-.173.343-.376.343ZM183.797 90.533c-.211 0-.376-.15-.376-.343v-4.295c0-.192.165-.343.376-.343.21 0 .376.15.376.343v4.295c0 .192-.173.343-.376.343ZM215.283 113.561h-10.124c-1.676 0-3.037-1.242-3.037-2.772v-9.243c0-1.53 1.361-2.772 3.037-2.772h10.124c1.676 0 3.037 1.242 3.037 2.772v9.243c0 1.53-1.361 2.772-3.037 2.772Zm-10.132-14.108c-1.263 0-2.285.94-2.285 2.086v9.243c0 1.153 1.03 2.086 2.285 2.086h10.125c1.262 0 2.285-.94 2.285-2.086v-9.243c0-1.153-1.03-2.086-2.285-2.086h-10.125Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M215.982 99.453h-11.53c-1.285 0-2.338-.96-2.338-2.134v-2.552c0-1.173 1.045-2.134 2.338-2.134h11.53c1.285 0 2.337.96 2.337 2.134v2.552c0 1.174-1.044 2.134-2.337 2.134Zm-11.53-6.134c-.872 0-1.586.652-1.586 1.448v2.552c0 .796.714 1.448 1.586 1.448h11.53c.872 0 1.586-.652 1.586-1.448v-2.552c0-.796-.714-1.448-1.586-1.448h-11.53ZM217.944 103.131H202.49c-.21 0-.376-.151-.376-.343 0-.192.166-.343.376-.343h15.454c.21 0 .375.151.375.343 0 .192-.165.343-.375.343ZM217.944 111.105H202.49c-.21 0-.376-.151-.376-.343 0-.193.166-.344.376-.344h15.454c.21 0 .375.151.375.344 0 .192-.165.343-.375.343Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M215.591 99.453c-.211 0-.376-.15-.376-.343v-4.295c0-.192.165-.343.376-.343.21 0 .375.15.375.343v4.295c0 .192-.165.343-.375.343ZM214.246 99.453c-.211 0-.376-.15-.376-.343v-4.295c0-.192.165-.343.376-.343.21 0 .376.15.376.343v4.295c0 .192-.166.343-.376.343ZM212.908 99.453c-.21 0-.376-.15-.376-.343v-4.295c0-.192.166-.343.376-.343.211 0 .376.15.376.343v4.295c0 .192-.173.343-.376.343ZM211.562 99.453c-.21 0-.375-.15-.375-.343v-4.295c0-.192.165-.343.375-.343.211 0 .376.15.376.343v4.295c0 .192-.173.343-.376.343ZM210.217 99.453c-.211 0-.376-.15-.376-.343v-4.295c0-.192.165-.343.376-.343.21 0 .375.15.375.343v4.295c0 .192-.165.343-.375.343ZM208.871 99.453c-.211 0-.376-.15-.376-.343v-4.295c0-.192.165-.343.376-.343.21 0 .376.15.376.343v4.295c0 .192-.166.343-.376.343ZM207.526 99.453c-.21 0-.376-.15-.376-.343v-4.295c0-.192.166-.343.376-.343.211 0 .376.15.376.343v4.295c0 .192-.165.343-.376.343ZM206.188 99.453c-.21 0-.376-.15-.376-.343v-4.295c0-.192.166-.343.376-.343.211 0 .376.15.376.343v4.295c0 .192-.173.343-.376.343ZM204.843 99.453c-.211 0-.376-.15-.376-.343v-4.295c0-.192.165-.343.376-.343.21 0 .375.15.375.343v4.295c0 .192-.172.343-.375.343ZM48.863 117.809c-.045 0-.09 0-.135-.007-1.21-.076-2.135-1.036-2.052-2.148l1.39-18.575 2.293-17.181c.023-.172.18-.302.376-.302h1.503a.4.4 0 0 1 .27.103.325.325 0 0 1 .106.247l-.504 18.458-1.33 17.765c-.038.466-.27.898-.654 1.207a2.015 2.015 0 0 1-1.263.433Zm-.082-.687c.308.021.616-.075.857-.26.24-.186.375-.453.398-.735l1.33-17.75.489-18.095h-.782l-2.255 16.873-1.383 18.554c-.03.357.098.693.353.96.248.268.602.433.993.453Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M52.239 80.282h-1.504c-.21 0-.376-.151-.376-.343 0-.192.166-.343.376-.343h1.504c.21 0 .375.15.375.343 0 .192-.165.343-.375.343Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M52.253 80.316a.427.427 0 0 1-.203-.055c-.173-.103-.226-.315-.113-.473.053-.076 1.33-1.935-.015-3.781-.639-.878-.827-2.08-.872-2.84-.594.507-1.443 1.447-1.443 2.648 0 1.935 1.428 3.904 1.443 3.925a.324.324 0 0 1-.105.473.397.397 0 0 1-.519-.096c-.067-.075-1.57-2.154-1.57-4.295 0-2.217 2.262-3.596 2.36-3.65a.416.416 0 0 1 .39-.014c.12.061.196.185.188.315 0 .02-.09 1.99.76 3.157 1.623 2.223.082 4.44.014 4.528a.39.39 0 0 1-.315.158Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default Artist;
