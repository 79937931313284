import { FC } from 'react';

import { Box } from '@mui/material';
import { useVisibleNavigation } from 'hooks';

import { DesktopPortfolioNavigation } from '../../PortfolioNavigation';
import * as styles from './styles';

const StudentNavigationHeader: FC = () => {
  const { primaryNavigation, secondaryNavigation } = useVisibleNavigation();

  return (
    <Box className="navigation-header" sx={styles.root}>
      <DesktopPortfolioNavigation primaryNavigation={primaryNavigation} secondaryNavigation={secondaryNavigation} />
    </Box>
  );
};

export default StudentNavigationHeader;
