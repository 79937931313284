/* eslint-disable max-len */
import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconScienceEmploymentsCommon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M24 1.7H13.3V0h-2.5v1.7H0v17.2h7.7l-3 5.1h2.9l3-5.1h.1V24h2.5v-5.1l3 5.1h2.9l-3-5.1H24V1.7Zm-3.4 14.1H3.4V5h17.2v10.8Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconScienceEmploymentsCommon;
