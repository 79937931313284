/* eslint-disable @typescript-eslint/no-explicit-any */

import { FC, useEffect, useId, useRef } from 'react';

import { Box, Typography } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';
import { Button } from 'portfolio3/ui-kit/button';
import { transliteString } from 'utils';

import { IExcelDropController } from './types';

const MAX_FILE_LENGTH = 5000000;

interface IExcelDropProps {
  fileController: IExcelDropController;
}

const ExcelDrop: FC<IExcelDropProps> = ({ fileController }) => {
  const fileInputId = useId();
  const fileInput = useRef<HTMLInputElement>(null);
  const dropArea = useRef<any>(null);

  const handleSendFile = (file: any) => {
    let fileName = file.name;
    if (/[А-Я-Ё]/gi.test(file.name) === true) {
      fileName = transliteString(file.name);
    }
    if (!fileName) return;

    fileController.handleSendFile(file);

    if (file.size > MAX_FILE_LENGTH) {
      fileController.handleSetError(true, 'Объем файла слишком большой');
    } else if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      fileController.handleSetError(
        true,
        // eslint-disable-next-line max-len
        'Недопустимый формат файла. Поддерживается импорт только из файлов формата XLSX. Вы можете скачать шаблон файла для импорта',
      );
    } else {
      fileController.handleSetError(false, '');
    }
  };

  const handleFileSelected = (event: React.ChangeEvent<HTMLInputElement>): void => {
    let fileArray: File[] = [];
    if (event.target.files) fileArray = Array.from(event.target.files);
    handleSendFile(fileArray[0]);
  };

  const preventDefaults = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event: any) => {
    let fileArray: File[] = [];
    if (event.dataTransfer.files) fileArray = Array.from(event.dataTransfer.files);
    handleSendFile(fileArray[0]);
  };
  useEffect(() => {
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
      dropArea.current.addEventListener(eventName, preventDefaults, false);
    });
    dropArea.current.addEventListener('drop', handleDrop, false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropArea]);

  return (
    <Box
      component="label"
      htmlFor={fileInputId}
      className="excel-drop"
      ref={dropArea}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '12px',
        padding: '20px 24px',

        border: '2px dashed',
        borderColor: NeutralColors.light_neutrals_400,
        borderRadius: '8px',
      }}
    >
      <Typography variant="Paragraph LG/Semi Bold" color={NeutralColors.light_neutrals_600}>
        Перетащите файл сюда, чтобы загрузить
      </Typography>
      <Typography variant="Paragraph LG/Regular" color={NeutralColors.light_neutrals_600}>
        Формат файла: XLSX
      </Typography>
      <Button onClick={() => fileInput.current?.click()}>Выбрать файл</Button>

      <Box
        component="input"
        id={fileInputId}
        value=""
        name="excel-drop"
        type="file"
        ref={fileInput}
        onChange={handleFileSelected}
        sx={{
          display: 'none',
          pointerEvents: 'none',
        }}
      />
    </Box>
  );
};

export default ExcelDrop;
