import { FC } from 'react';

import { IconCheckmark } from 'icons';
import { getAccentColor } from 'portfolio3/styles';

import IconBadge from './IconBadge';

const VerifiedBadge: FC = () => {
  const teal8 = getAccentColor('teal', '8');
  const teal100 = getAccentColor('teal', '100');

  return (
    <IconBadge backgroundColor={teal8} iconColor={teal100}>
      <IconCheckmark />
    </IconBadge>
  );
};

export default VerifiedBadge;
