import { getSourceCode } from 'utils';

import useUserFunctionality from './useUserFunctionality';

const useUserSourceCode = () => {
  const user = useUserFunctionality();
  return getSourceCode(user);
};

export default useUserSourceCode;
