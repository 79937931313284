/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

const IconJuniorAdventurer7: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="50" height="50" fill="#E5E5E5"/>
    <rect width="1924" height="1397" transform="translate(-1410 -1194)" fill="white"/>
    <g clip-path="url(#clip0)">
    <path d="M24.995 48.143C20.444 48.1449 15.9946 46.7884 12.2098 44.2451C8.42491 41.7018 5.47462 38.0859 3.73213 33.8549C1.98963 29.6239 1.53321 24.9679 2.4206 20.4757C3.308 15.9836 5.49933 11.8573 8.71741 8.61877C11.9355 5.38021 16.0357 3.17492 20.4994 2.28188C24.9631 1.38884 29.5897 1.84816 33.794 3.60175C37.9982 5.35534 41.5912 8.3244 44.1184 12.1333C46.6457 15.9423 47.9936 20.42 47.9917 25C47.984 31.1355 45.5586 37.0175 41.2476 41.356C36.9366 45.6945 31.0918 48.1352 24.995 48.143Z" fill="white"/>
    <path d="M25.0048 1.95471C29.5339 1.95471 33.9613 3.30629 37.7271 5.83854C41.4929 8.37079 44.428 11.97 46.1612 16.181C47.8944 20.3919 48.3479 25.0256 47.4643 29.4959C46.5807 33.9663 44.3998 38.0725 41.1972 41.2955C37.9946 44.5184 33.9143 46.7133 29.4723 47.6025C25.0302 48.4917 20.4258 48.0353 16.2415 46.2911C12.0572 44.5468 8.48073 41.5931 5.9645 37.8033C3.44827 34.0135 2.10523 29.5579 2.10523 25C2.11294 18.8904 4.52804 13.0332 8.82086 8.71309C13.1137 4.39294 18.9338 1.96247 25.0048 1.95471ZM25.0048 1.74956C20.4354 1.74956 15.9686 3.11317 12.1692 5.66797C8.36988 8.22276 5.40866 11.854 3.66002 16.1024C1.91138 20.3509 1.45385 25.0258 2.3453 29.5359C3.23675 34.0461 5.43714 38.1889 8.66821 41.4406C11.8993 44.6922 16.0159 46.9066 20.4975 47.8037C24.9791 48.7008 29.6245 48.2404 33.8461 46.4806C38.0677 44.7208 41.6759 41.7408 44.2146 37.9173C46.7532 34.0937 48.1082 29.5985 48.1082 25C48.1094 21.9464 47.5127 18.9224 46.3521 16.1009C45.1915 13.2795 43.4898 10.7159 41.3442 8.55659C39.1986 6.39733 36.6512 4.68477 33.8476 3.51678C31.044 2.34879 28.0391 1.74828 25.0048 1.74956Z" fill="#BA6445"/>
    <path d="M25.0048 46.8144C36.9764 46.8144 46.6812 37.0478 46.6812 25C46.6812 12.9522 36.9764 3.18562 25.0048 3.18562C13.0332 3.18562 3.32837 12.9522 3.32837 25C3.32837 37.0478 13.0332 46.8144 25.0048 46.8144Z" fill="#748FFC"/>
    <path d="M25.0048 45.3783C36.1883 45.3783 45.2543 36.2546 45.2543 25C45.2543 13.7454 36.1883 4.62167 25.0048 4.62167C13.8214 4.62167 4.75537 13.7454 4.75537 25C4.75537 36.2546 13.8214 45.3783 25.0048 45.3783Z" fill="#E83F40"/>
    <path d="M24.9951 -8L13.5793 25L24.9951 58L36.508 25L24.9951 -8Z" fill="#748FFC"/>
    <path d="M24.9951 58L36.508 25L24.9951 -8" fill="#5073CC"/>
    <path d="M58 25L24.9951 13.5018L-8 25L24.9951 36.4884L58 25Z" fill="#748FFC"/>
    <path d="M-8 25L24.9951 36.4885L58 25" fill="#5073CC"/>
    <path d="M25.0047 43.9618C35.4108 43.9618 43.8466 35.4723 43.8466 25C43.8466 14.5277 35.4108 6.03819 25.0047 6.03819C14.5986 6.03819 6.16284 14.5277 6.16284 25C6.16284 35.4723 14.5986 43.9618 25.0047 43.9618Z" fill="#F2935F"/>
    <path d="M1.90137 25V43.2682L26.2473 25H1.90137Z" fill="#BA6445"/>
    <path d="M48.1083 25V43.2682L23.7527 25H48.1083Z" fill="#BA6445"/>
    <path d="M36.8671 25C36.7788 28.1083 35.4897 31.0594 33.2738 33.2266C31.0579 35.3937 28.0898 36.606 24.9999 36.606C21.91 36.606 18.9419 35.3937 16.726 33.2266C14.5101 31.0594 13.2211 28.1083 13.1327 25H1.90137C1.90137 31.1664 4.33547 37.0802 8.66819 41.4405C13.0009 45.8008 18.8774 48.2504 25.0048 48.2504C31.1322 48.2504 37.0086 45.8008 41.3413 41.4405C45.6741 37.0802 48.1082 31.1664 48.1082 25H36.8671Z" fill="#F2935F"/>
    <path d="M25.0049 36.9476C31.5616 36.9476 36.8769 31.5985 36.8769 25C36.8769 18.4015 31.5616 13.0524 25.0049 13.0524C18.4481 13.0524 13.1328 18.4015 13.1328 25C13.1328 31.5985 18.4481 36.9476 25.0049 36.9476Z" fill="url(#paint0_linear)" stroke="#BA6445" stroke-width="0.2" stroke-miterlimit="10"/>
    <path d="M16.5305 20.1154H18.9962L20.229 24.9414C20.5396 26.0746 20.7435 27.1199 21.0541 28.2629H21.1221C21.4327 27.1199 21.656 26.0746 21.9472 24.9414L23.1606 20.1154H25.5389L22.4326 30.4707H19.6369L16.5305 20.1154Z" fill="#BA6445"/>
    <path d="M26.1211 20.1154H28.4508V30.4707H26.1211V20.1154Z" fill="#BA6445"/>
    <path d="M30.5186 20.1154H32.8483V30.4707H30.5186V20.1154Z" fill="#BA6445"/>
    <path opacity="0.5" d="M40.0607 13.5604C38.3204 11.244 36.0719 9.36348 33.4906 8.06546C30.9093 6.76745 28.065 6.08703 25.1794 6.07726C14.9188 6.07726 6.56078 14.5471 6.15308 25.1465C8.95696 27.2053 12.2082 28.5616 15.6371 29.103C20.8797 29.8582 26.2181 28.7084 30.6942 25.8599C35.1703 23.0114 38.4912 18.6507 40.0607 13.5604Z" fill="url(#paint1_linear)"/>
    <path d="M13.589 34.6226L14.8704 35.7948C15.6955 36.5373 16.0838 37.4361 15.2684 38.3543C15.1021 38.5391 14.8982 38.6857 14.6706 38.7841C14.4431 38.8826 14.1971 38.9307 13.9495 38.9252C13.7018 38.9196 13.4582 38.8605 13.2353 38.7519C13.0123 38.6433 12.8151 38.4877 12.6571 38.2957L12.1912 37.8757L11.0457 39.1456L10.2012 38.3837L13.589 34.6226ZM13.2104 37.5533C13.6764 37.9734 14.0841 37.9734 14.4336 37.5533C14.783 37.1332 14.6665 36.7913 14.2103 36.3712L13.822 36.0195L12.8027 37.1528L13.2104 37.5533Z" fill="#BA6445"/>
    <path d="M15.4626 39.5755C16.1712 38.0808 17.404 37.6217 18.6369 38.2176C19.8697 38.8135 20.2968 40.0737 19.6076 41.5586C18.9184 43.0435 17.6661 43.5124 16.4139 42.9654C15.1616 42.4183 14.7539 41.0702 15.4626 39.5755ZM18.5398 41.0506C18.9766 40.1323 18.8407 39.4094 18.2195 39.0968C17.5982 38.7842 16.9478 39.1554 16.511 40.0737C16.0741 40.992 16.1906 41.7638 16.8119 42.0275C17.4332 42.2913 18.103 41.9689 18.5398 41.0506Z" fill="#BA6445"/>
    <path d="M20.423 41.8224C20.4546 41.4918 20.5532 41.1713 20.7127 40.8806C20.8721 40.5898 21.089 40.335 21.35 40.1318C21.611 39.9286 21.9106 39.7814 22.2303 39.6992C22.55 39.6169 22.8831 39.6014 23.209 39.6536C23.4879 39.7038 23.7541 39.809 23.9924 39.9633C24.2306 40.1175 24.4362 40.3178 24.5971 40.5524L23.8788 41.1776C23.7879 41.0366 23.6697 40.9155 23.5313 40.8215C23.3928 40.7275 23.237 40.6626 23.0731 40.6306C22.3936 40.5133 21.7529 41.0311 21.5781 42.0275C21.4034 43.024 21.782 43.7371 22.5489 43.8641C22.7287 43.8838 22.9106 43.8672 23.084 43.8151C23.2574 43.7631 23.4187 43.6768 23.5584 43.5613L24.0341 44.3623C23.7994 44.5658 23.5222 44.7135 23.2232 44.7947C22.9241 44.8758 22.6107 44.8883 22.3062 44.8313C21.9743 44.7815 21.6571 44.6602 21.3761 44.4756C21.0951 44.291 20.8571 44.0476 20.6783 43.7619C20.4995 43.4761 20.3841 43.1549 20.34 42.8202C20.2959 42.4855 20.3242 42.1451 20.423 41.8224Z" fill="#BA6445"/>
    <path d="M25.1697 42.4964C25.1119 42.1686 25.1216 41.8322 25.1983 41.5083C25.2749 41.1844 25.4168 40.8797 25.6152 40.6132C25.8136 40.3467 26.0642 40.124 26.3516 39.9589C26.639 39.7939 26.957 39.69 27.2859 39.6536C27.5668 39.6309 27.8494 39.664 28.1176 39.7512C28.3857 39.8383 28.6342 39.9778 28.8488 40.1616L28.3246 40.9529C28.1943 40.8342 28.0403 40.7449 27.8728 40.6909C27.7054 40.637 27.5285 40.6198 27.3539 40.6403C26.6646 40.6989 26.1793 41.3632 26.2764 42.3792C26.3734 43.3952 26.917 43.9716 27.6451 43.8934C27.8373 43.877 28.0237 43.8187 28.1914 43.7226C28.359 43.6264 28.5038 43.4947 28.6158 43.3366L29.2856 43.9911C29.1088 44.2471 28.8778 44.4604 28.6092 44.6158C28.3407 44.7712 28.0412 44.8648 27.7324 44.8899C27.4031 44.9249 27.07 44.8885 26.7558 44.7832C26.4415 44.6779 26.1533 44.5061 25.9105 44.2794C25.6677 44.0526 25.476 43.7762 25.3482 43.4687C25.2204 43.1611 25.1596 42.8296 25.1697 42.4964Z" fill="#BA6445"/>
    <path d="M29.4119 39.4192L30.4505 38.9893L31.1301 40.6501C31.3455 41.2259 31.5302 41.8129 31.6834 42.4085L31.7319 41.1874L32.0037 38.3543L33.1201 37.8854L35.0615 42.5844L34.0131 43.0142L33.3336 41.3437C33.1104 40.7966 32.9453 40.1323 32.7803 39.595V40.8064L32.46 43.6492L31.3436 44.1083L29.4119 39.4192Z" fill="#BA6445"/>
    <path d="M34.0713 37.2895L34.9255 36.5373L36.0904 37.8952C36.4817 38.3705 36.8478 38.8661 37.1873 39.3801L36.8379 38.2176L36.1875 35.4334L37.1 34.6323L40.4102 38.4618L39.5656 39.2043L38.391 37.8464C37.993 37.397 37.6242 36.8206 37.2941 36.3712L37.6436 37.5338L38.2843 40.3179L37.3718 41.1092L34.0713 37.2895Z" fill="#BA6445"/>
    <path d="M10.8222 29.1519C9.96798 29.3766 9.36613 29.0639 9.18169 28.3606C8.99725 27.6572 9.37584 27.071 10.2301 26.8464C10.3901 26.7756 10.5635 26.7405 10.7383 26.7434C10.9131 26.7463 11.0852 26.7872 11.2429 26.8633C11.4005 26.9393 11.5399 27.0488 11.6516 27.1841C11.7633 27.3195 11.8447 27.4775 11.89 27.6474C12.0745 28.3508 11.6765 28.9076 10.8222 29.1519ZM9.79325 30.5195L9.64764 29.8845L10.6184 29.6208L10.4436 28.9076L10.9581 28.7709L11.162 29.5524L12.2589 29.2593L12.4142 29.8552L9.79325 30.5195ZM10.3854 27.452C9.8612 27.5986 9.59911 27.8721 9.69618 28.2238C9.79325 28.5755 10.1427 28.6927 10.6669 28.556C11.1911 28.4192 11.4726 28.1359 11.3853 27.7842C11.2979 27.4325 10.9096 27.3153 10.3854 27.452Z" fill="#BA6445"/>
    <path d="M8.86157 25.7718V25.1563H9.90025V24.1794H8.87128V23.5639H11.5699V24.1794H10.4342V25.1563H11.5602V25.762L8.86157 25.7718Z" fill="#BA6445"/>
    <path d="M9.02661 22.0693L9.18193 21.4734L10.1527 21.7274L10.2206 21.4636C10.3759 20.8677 10.7157 20.4867 11.2884 20.643C11.8611 20.7993 12.0262 21.3268 11.8709 21.9032L11.6573 22.7531L9.02661 22.0693ZM9.59934 19.8419L9.75466 19.2558L12.3659 19.9298L12.2106 20.5257L9.59934 19.8419ZM11.3758 21.7957C11.4534 21.4929 11.3758 21.278 11.1234 21.2096C10.871 21.1412 10.7448 21.2877 10.6574 21.6199L10.5992 21.825L11.3175 22.0107L11.3758 21.7957Z" fill="#BA6445"/>
    <path d="M9.82251 17.2336L10.0652 16.7744C10.2108 16.8526 10.337 16.8428 10.4049 16.706C10.4729 16.5693 10.4049 16.4716 10.2788 16.3837L10.5214 15.9343C10.8029 16.1101 10.9194 16.3934 10.667 16.9112C10.4147 17.429 10.1331 17.3703 9.82251 17.2336ZM10.337 17.9272L10.6282 17.3996L11.463 17.8588C11.7446 18.0151 12.0455 18.2398 12.2882 18.4059L11.8999 17.8881L11.1039 16.5986L11.4145 16.0222L13.7734 17.3313L13.4919 17.8588L12.6473 17.3996C12.3755 17.2433 12.0746 17.0284 11.8319 16.8526L12.2202 17.3703L13.055 18.6403L12.7541 19.2167L10.337 17.9272Z" fill="#BA6445"/>
    <path d="M13.1133 13.746L14.6373 12.3002L16.4914 14.254L16.0546 14.6741L14.55 13.0817L13.9093 13.6972L15.4139 15.2895L14.9771 15.7096L13.1133 13.746Z" fill="#BA6445"/>
    <path d="M17.1711 13.7362L16.9866 13.2771C17.0533 13.2658 17.1164 13.239 17.1711 13.1989C17.2152 13.1745 17.2525 13.1394 17.2797 13.0967C17.3068 13.0541 17.3229 13.0052 17.3264 12.9547V12.8668L15.4917 11.6554L16.0353 11.3135L16.7245 11.8117L17.2584 12.2416L17.1031 11.5773L16.9284 10.698L17.4526 10.3659L17.8506 12.5933C17.9045 12.7723 17.8996 12.964 17.8367 13.14C17.7737 13.316 17.656 13.4668 17.5011 13.5702C17.4064 13.6521 17.293 13.7091 17.1711 13.7362Z" fill="#BA6445"/>
    <path d="M19.0543 10.1998L18.3651 10.4734L18.1807 10.0044L20.1221 9.22291L20.3163 9.70159L19.6271 9.97513L20.4328 12.0169L19.8406 12.3002L19.0543 10.1998Z" fill="#BA6445"/>
    <path d="M21.248 8.92007L22.908 8.60746L23.0051 9.10568L21.947 9.31084L22.044 9.84814L22.9468 9.67229L23.0439 10.1803L22.1411 10.3561L22.2576 10.9716L23.3642 10.7567L23.4613 11.2647L21.7819 11.587L21.248 8.92007Z" fill="#BA6445"/>
    <path d="M24.3156 8.39254H24.9077L24.801 10.5906H25.4805L25.5872 8.39254H26.1697L26.0629 10.5906H26.7424L26.8492 8.39254H27.4413L27.3054 11.3233L24.1797 11.167L24.3156 8.39254Z" fill="#BA6445"/>
    <path d="M28.8878 8.9103L30.5186 9.36945L30.373 9.86767L29.3343 9.55506L29.179 10.0826L30.0624 10.3464L29.9168 10.8348L29.0334 10.5808L28.8587 11.1767L29.9362 11.4991L29.7906 11.9876L28.1306 11.4991L28.8878 8.9103Z" fill="#BA6445"/>
    <path d="M30.9941 11.0497C31.0611 10.8821 31.1618 10.7303 31.2899 10.6037C31.418 10.4772 31.5707 10.3787 31.7385 10.3145C31.9063 10.2503 32.0854 10.2217 32.2647 10.2305C32.444 10.2394 32.6195 10.2855 32.7803 10.3659C32.9142 10.4355 33.0325 10.5321 33.1277 10.6498C33.2229 10.7675 33.293 10.9036 33.3336 11.0497L32.8773 11.2549C32.8254 11.0797 32.7067 10.9322 32.5473 10.8446C32.2172 10.6687 31.8095 10.8446 31.5766 11.3135C31.3436 11.7824 31.4018 12.2123 31.7513 12.3979C31.843 12.4388 31.9422 12.4599 32.0425 12.4599C32.1428 12.4599 32.242 12.4388 32.3337 12.3979L32.4502 12.8863C32.2952 12.9524 32.1274 12.9823 31.9593 12.9738C31.7912 12.9653 31.6272 12.9187 31.4795 12.8375C31.3248 12.7585 31.1882 12.6478 31.0786 12.5125C30.969 12.3772 30.8887 12.2203 30.843 12.0519C30.7973 11.8835 30.7871 11.7073 30.8131 11.5347C30.8392 11.3621 30.9008 11.1969 30.9941 11.0497Z" fill="#BA6445"/>
    <path d="M34.5471 12.1634L33.9549 11.7043L34.2753 11.294L35.9255 12.5933L35.6052 13.0036L35.0227 12.5444L33.6734 14.254L33.1978 13.873L34.5471 12.1634Z" fill="#BA6445"/>
    <path d="M36.7702 13.4334L37.372 14.1368C37.7312 14.5568 37.9059 14.9965 37.5079 15.3286C37.419 15.4105 37.3087 15.465 37.19 15.4858C37.0714 15.5065 36.9492 15.4927 36.8381 15.4458C36.9095 15.5741 36.9322 15.7241 36.9018 15.8679C36.8714 16.0117 36.7902 16.1394 36.6731 16.2274C36.2557 16.5888 35.7703 16.4032 35.382 15.9538L34.7219 15.2309L36.7702 13.4334ZM35.7024 15.6119C35.9159 15.8659 36.1198 15.9245 36.3042 15.7584C36.4887 15.5924 36.4498 15.4165 36.2266 15.1625L36.0033 14.8988L35.4791 15.3579L35.7024 15.6119ZM36.5372 14.8206C36.7216 15.0453 36.9061 15.0648 37.0517 14.9378C37.1973 14.8108 37.1779 14.6448 36.9934 14.4201L36.809 14.254L36.343 14.6545L36.5372 14.8206Z" fill="#BA6445"/>
    <path d="M38.7698 15.9831L39.6143 17.4583L39.1678 17.7123L38.6339 16.7353L38.1582 17.0089L38.6144 17.8099L38.1776 18.0639L37.7214 17.2629L37.1778 17.5755L37.7311 18.5524L37.3137 18.8455L36.4497 17.3313L38.7698 15.9831Z" fill="#BA6445"/>
    <path d="M40.226 18.7087L40.4104 19.2949L39.4397 19.6172L39.7309 20.5062L40.7017 20.1838L40.8958 20.7602L38.2845 21.6101L38.1001 21.024L39.1679 20.6723L38.8767 19.7833L37.8089 20.135L37.6147 19.5586L40.226 18.7087Z" fill="#BA6445"/>
    <path d="M41.1965 22.1767L41.2548 22.7824L40.2258 22.8899L40.3131 23.8179L41.3518 23.7202L41.4101 24.3357L38.7211 24.5897L38.6144 24.0231L39.7307 23.9156L39.6433 22.9387L38.5173 23.0462L38.4688 22.4307L41.1965 22.1767Z" fill="#BA6445"/>
    <path d="M41.3324 25.8011L41.2548 26.397L40.2841 26.2798C39.957 26.2294 39.6329 26.1609 39.3133 26.0746L39.8666 26.4165L41.1092 27.2957L41.0218 27.9405L38.3523 27.5888L38.43 26.9929L39.4007 27.1101C39.7277 27.1611 40.0517 27.2296 40.3714 27.3153L39.8181 26.9538L38.5756 26.0746L38.6532 25.4298L41.3324 25.8011Z" fill="#BA6445"/>
    <path d="M40.7111 29.357L40.5072 29.9334L39.5365 29.5817L39.3229 30.1972L37.4397 30.5977L37.6533 29.992L38.9735 29.6989L39.0705 29.4156L37.993 29.0346L38.1969 28.4485L40.7111 29.357ZM39.3326 29.7771L39.8762 30.4023C39.9965 30.5009 40.0828 30.6352 40.1229 30.786C40.163 30.9369 40.1548 31.0966 40.0995 31.2425C40.0819 31.3189 40.0451 31.3895 39.9927 31.4476L39.5074 31.1643C39.5321 31.1317 39.5518 31.0955 39.5656 31.0568C39.5656 30.9396 39.5656 30.8615 39.4297 30.6954L39.0414 30.2362L39.3326 29.7771Z" fill="#BA6445"/>
    </g>
    <rect x="-1345.5" y="-669.5" width="1768" height="796" rx="4.5" stroke="#7B61FF" strokeDasharray="10 5"/>
    <defs>
    <linearGradient id="paint0_linear" x1="19.0349" y1="16.921" x2="28.6454" y2="29.7578" gradientUnits="userSpaceOnUse">
    <stop stopColor="white"/>
    <stop offset="0.03" stopColor="#FEFEFF"/>
    <stop offset="1" stopColor="#F1ECFF"/>
    </linearGradient>
    <linearGradient id="paint1_linear" x1="18.3843" y1="10.4538" x2="27.0604" y2="26.6052" gradientUnits="userSpaceOnUse">
    <stop stopColor="white" stopOpacity="0"/>
    <stop offset="0.35" stopColor="white" stopOpacity="0.01"/>
    <stop offset="0.49" stopColor="white" stopOpacity="0.03"/>
    <stop offset="0.59" stopColor="white" stopOpacity="0.08"/>
    <stop offset="0.67" stopColor="white" stopOpacity="0.15"/>
    <stop offset="0.74" stopColor="white" stopOpacity="0.24"/>
    <stop offset="0.8" stopColor="white" stopOpacity="0.35"/>
    <stop offset="0.85" stopColor="white" stopOpacity="0.47"/>
    <stop offset="0.9" stopColor="white" stopOpacity="0.62"/>
    <stop offset="0.95" stopColor="white" stopOpacity="0.79"/>
    <stop offset="1" stopColor="white"/>
    </linearGradient>
    <clipPath id="clip0">
    <rect width="50" height="50" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);

export default IconJuniorAdventurer7;
