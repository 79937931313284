import { createSelector } from 'reselect';

import { IRootState } from '../../../reducers';
import { mapCivilRewardsResponse } from './mappers';

const civilRewardsSelector = createSelector(
  [
    (state: IRootState) => state.studentRewards,
    (state: IRootState) => state.studentEvents,
    (state: IRootState) => state.sectionRef,
  ],
  (studentRewards, studentEvents, sectionRef) => {
    return mapCivilRewardsResponse(studentEvents, studentRewards, sectionRef);
  },
);

export default civilRewardsSelector;
