import { TypedUseSelectorHook, useSelector } from 'react-redux';

import { IRootState } from 'reducers';

export * from './dataLoad';
export * from './sections';
export { default as useAvatar } from './useAvatar';
export { default as useBrightTheme } from './useBrightTheme';
export { default as useBuildConfig } from './useBuildConfig';
export { default as useCallbackRef } from './useCallbackRef';
export { default as useClientWidth } from './useClientWidth';
export { default as useCommonAdvertVisibility } from './useCommonAdvertVisibility';
export { default as useComponentDidMount } from './useComponentDidMount';
export { default as useComponentDidUpdate } from './useComponentDidUpdate';
export { default as useDataEntryDrawer } from './useDataEntryDrawer';
export { default as useDebouncedState } from './useDebouncedState';
export { default as useDetailViewDrawer } from './useDetailViewDrawer';
export { default as useDisclosure } from './useDisclosure';
export { default as useEntityFilters } from './useEntityFilters';
export { default as useGiaExamCardProperties } from './useGiaExamCardProperties';
export { default as useHeaderHeight } from './useHeaderHeight';
export { default as useHiddenHorizontalList } from './useHiddenHorizontalList';
export { default as useInterestsProgressBar } from './useInterestsProgressBar';
export { default as useLinkModeStudentName } from './useLinkModeStudentName';
export { default as useLoadApplicationStatusConfig } from './useLoadApplicationStatusConfig';
export { default as useLoadStudentAvatars } from './useLoadStudentAvatars';
export { default as useMeasureMultiline } from './useMeasureMultiline';
export { default as useMediaQueryWidth } from './useMediaQueryWidth';
export { default as usePreloadImages } from './usePreloadImages';
export { default as useQueryParams } from './useQueryParams';
export { default as useScrollToElement } from './useScrollToElement';
export { default as useSectionTheme } from './useSectionTheme';
export { default as useSingleEffect } from './useSingleEffect';
export { default as useSlidesCount } from './useSlidesCount';
export { default as useSortState } from './useSortState';
export { default as useSPOStudent } from './useSPOStudent';
export { default as useStudentContext } from './useStudentContext';
export { default as useStudentNavigation } from './useStudentNavigation';
export { default as useThemeSettings } from './useThemeSettings';
export { default as useUserFunctionality } from './useUserFunctionality';
export { default as useUserSourceCode } from './useUserSourceCode';
export { default as useVisibleNavigation } from './useVisibleNavigation';
export { default as useWidgetVisibility } from './useWidgetVisibility';
export * from './viewedFunctions';

export const useAppSelector: TypedUseSelectorHook<IRootState> = useSelector;
