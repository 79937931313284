import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  clearEntityErrorMessagesAction,
  getEntityErrorMessagesActions,
  reportErrorDrawerActions,
  thanksTeacherDrawerActions,
} from 'actions';
import { EntityType } from 'const';
import { WidgetDataContext } from 'portfolio3/components/common/WidgetContainer';
import { IRootState } from 'reducers';

interface IGetHandlersParameters {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formData: any;
}

const useExamEntityHandlers = () => {
  const dispatch = useDispatch();
  const currentStudent = useSelector((state: IRootState) => state.currentStudent);
  const linkMode = useSelector((state: IRootState) => state.linkMode.mode ?? false);

  const { widgetLabel } = useContext(WidgetDataContext);

  const getHandlers = ({ formData }: IGetHandlersParameters) => {
    const entityType = EntityType.GOVEXAM;

    const name = formData.name;
    const entityId = formData.id;

    const handleReport = () => {
      if (linkMode) return;

      dispatch(clearEntityErrorMessagesAction());
      dispatch(
        reportErrorDrawerActions.setFormData({
          entityName: 'Экзамен',
          categoryName: name ?? '',
          entityType,
          entityId,
        }),
      );
      dispatch(reportErrorDrawerActions.setOpen(true));
      dispatch(getEntityErrorMessagesActions.request(currentStudent.meshId, entityType, entityId, undefined));
    };

    const handleThanksTeacher = () => {
      if (linkMode) return;

      const entityId = formData.id;

      dispatch(
        thanksTeacherDrawerActions.setFormData({
          entityName: 'Экзамен',
          categoryName: name ?? '',
          entityType,
          entityId,
          entityFormData: formData,
        }),
      );
      dispatch(thanksTeacherDrawerActions.setOpen(true, widgetLabel));
    };

    return {
      handleReport,
      handleThanksTeacher,
    };
  };

  return {
    getHandlers,
  };
};

export default useExamEntityHandlers;
