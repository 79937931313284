/* eslint-disable prettier/prettier */
/* eslint-disable */

import { IReducers } from '..';
import { GET_OLYMPIAD_TYPES } from '../../actions';
import { FAILURE, REQUEST, SUCCESS } from '../../actions/utils';
import { ICommonResponse, IDictionaryItem } from '../../api/types';
import { connectReducers } from '../../utils';
import genericReducers from '../genericReducer';

export type OlympiadTypesState = ICommonResponse<IDictionaryItem>;

const initialState: OlympiadTypesState = {
  content: [],
  loading: false,
};

const olympiadTypes: IReducers<OlympiadTypesState> = {
  [GET_OLYMPIAD_TYPES[REQUEST]]: genericReducers().get.request,
  [GET_OLYMPIAD_TYPES[SUCCESS]]: genericReducers().get.success,
  [GET_OLYMPIAD_TYPES[FAILURE]]: genericReducers().get.failure
};

export default connectReducers(initialState, olympiadTypes);
