import { FC } from 'react';

import { Box } from '@mui/material';
import { IconChevronFilledArrowDown, IconChevronFilledArrowUp } from 'icons';
import { NeutralColors } from 'portfolio3/styles';
import { mergeSx } from 'utils';

import * as styles from './styles';

interface INumberInputEndAdornmentProps {
  onStepUp: () => void;
  onStepDown: () => void;
}

const NumberInputEndAdornment: FC<INumberInputEndAdornmentProps> = ({ onStepUp, onStepDown }) => {
  return (
    <Box sx={styles.endAdornmentRoot}>
      <Box
        onClick={onStepUp}
        sx={mergeSx(styles.endAdornmentButton, {
          borderBottom: '1px solid',
          borderColor: NeutralColors.light_neutrals_400,
        })}
      >
        <IconChevronFilledArrowUp />
      </Box>
      <Box onClick={onStepDown} sx={styles.endAdornmentButton}>
        <IconChevronFilledArrowDown />
      </Box>
    </Box>
  );
};

export default NumberInputEndAdornment;
