import { FC } from 'react';

import clsx from 'clsx';

import { IconChevronFilledArrowDown, IconChevronFilledArrowUp } from '../../../../icons';

import './index.scss';

const formatNumber = (number: number) => {
  return number.toFixed(2).replace('.', ',');
};

interface ISubjectRowProps {
  name: string;
  mark: number;
  markChange: number;
  color: string;
  selected: boolean;
  onSubjectSelected?: () => void;
}

const SubjectRow: FC<ISubjectRowProps> = ({ name, mark, markChange, color, selected, onSubjectSelected }) => {
  const differenceSign = Math.sign(markChange);
  const changeClassName = differenceSign > 0 ? 'stats__change--positive' : 'stats__change--negative';

  return (
    <p className={clsx('subject-row', { 'subject-row--selected': selected })} onClick={onSubjectSelected}>
      <div className="subject-row__marker" style={{ backgroundColor: color }}></div>
      <p className="subject-row__name">{name}</p>
      <div className="stats">
        <span className="stats__value">{formatNumber(mark)}</span>
        {differenceSign !== 0 ? (
          <span className={`stats__change ${changeClassName}`}>
            {differenceSign > 0 ? <IconChevronFilledArrowUp /> : <IconChevronFilledArrowDown />}
            {formatNumber(Math.abs(markChange))}
          </span>
        ) : null}
      </div>
    </p>
  );
};

export default SubjectRow;
