import { FC } from 'react';

import { Link, Stack, Typography } from '@mui/material';
import { getAccentColor, NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

interface IDrawerVuzCardRowProps {
  leftSide: string;
  rightSide: string;
  isUrl?: boolean;
  isMobile?: boolean;
  disableBottomUnderline?: boolean;
}

const DrawerVuzCardRow: FC<IDrawerVuzCardRowProps> = ({
  leftSide,
  rightSide,
  isUrl,
  isMobile,
  disableBottomUnderline,
}) => {
  const rowSx: SxStyles = {
    flexDirection: isMobile ? 'column' : 'row',
    gap: isMobile ? '4px' : '12px',

    paddingBottom: disableBottomUnderline ? 0 : '12px',

    borderBottom: disableBottomUnderline ? 'none' : `1px solid ${NeutralColors.light_neutrals_300}`,
  };

  const leftSideSx: SxStyles = {
    minWidth: '150px',
    color: NeutralColors.light_neutrals_600,
  };

  return (
    <Stack sx={rowSx} className="drawer-vuz-card-row">
      <Typography sx={leftSideSx} variant="Paragraph MD/Regular">
        {leftSide}
      </Typography>
      {isUrl ? (
        <Link
          sx={{ textDecoration: 'none', color: getAccentColor('indigo', '100') }}
          target="_blank"
          href={rightSide}
          variant="Paragraph MD/Semi Bold"
        >
          {rightSide}
        </Link>
      ) : (
        <Typography color={NeutralColors.light_neutrals_900} variant="Paragraph MD/Regular">
          {rightSide}
        </Typography>
      )}
    </Stack>
  );
};

export default DrawerVuzCardRow;
