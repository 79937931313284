/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import {
  PdfPortfolioTitlePage,
  PdfPortfolioPersonalInfo
} from '../../components/pdfStudent';
import { PdfPerformanceContainer, PdfPerformanceContainerOld } from '../../containers/pdfStudent';

import { ISectionsVisibility } from '../../api/types';
import {
  PdfAdditionalEducationContainer,
  PdfCivilClubContainer,
  PdfCivilContestContainer,
  PdfCivilSectionContainer,
  PdfCreativeCircleContainer,
  PdfCreativeContestContainer,
  PdfCreativeSectionContainer,
  PdfCultureVisiting,
  PdfDocumentsContainer,
  PdfGiaWorldskillsContainer,
  PdfInterestsContainer,
  PdfJobsContainer,
  PdfMetaskillsContainer,
  PdfOlympiadContainer,
  PdfOnlineVisiting,
  PdfPracticeContainer,
  PdfProfessionEventsContainer,
  PdfProforientationRecommendations,
  PdfProforientationTests,
  PdfScienceContestContainer,
  PdfScienceOccupationContainer,
  PdfScienceProjectContainer,
  PdfSportClubContainer,
  PdfSportCompetitionContainer,
  PdfSportExpeditionContainer,
  PdfSportSectionContainer,
  PdfTrainingContainer
} from '../../containers/pdfStudent/dataEntityContainers';
import PdfGia from '../../components/pdfStudent/PdfGia';
import PdfDiagnostics from '../../components/pdfStudent/PdfDiagnostics';
import PdfEducationSection from '../../containers/pdfStudent/eductionSectionContainer';
import {
  pdfSectionCivil,
  pdfSectionCreation,
  pdfSectionCulture,
  pdfSectionProfession,
  pdfSectionProfile,
  pdfSectionScience,
  pdfSectionSport,
  pdfSectionStudy,
} from '../../icons';
import {
  PdfCivilRewardContainer,
  PdfCreationRewardContainer,
  PdfProfessionRewardContainer,
  PdfScienceRewardContainer,
  PdfSportRewardContainer
} from '../../containers/pdfStudent/rewardContainers';
import { useBuildConfig } from 'hooks';

import './index.scss';

interface IStudentPortfolioPdfProps {
  display: ISectionsVisibility;
}

const StudentPortfolioPdf: React.FC<IStudentPortfolioPdfProps> = ({ display }) => {
  const { OLD_PERFORMANCE_WIDGET } = useBuildConfig() ?? {};

  const studyPerformanceContainer = OLD_PERFORMANCE_WIDGET ? <PdfPerformanceContainerOld /> : <PdfPerformanceContainer />;

  return (
    <>
      <div className="student-portfolio-pdf">
        <PdfPortfolioTitlePage isStudentInfoShown={display.studentData} />
        <div className="student-portfolio-pdf__container">
          {display.studentData && <PdfPortfolioPersonalInfo />}
          {display.profile && (
            <PdfEducationSection
              title="Обо мне"
              icon={pdfSectionProfile}
            >
              {display.interests && <PdfInterestsContainer />}
            </PdfEducationSection>
          )}
          {display.studies
            && (
            <PdfEducationSection
              title="Учёба"
              icon={pdfSectionStudy}
            >
              {display.trainingInfo && <PdfTrainingContainer />}
              {display.practice && <PdfPracticeContainer />}
              {display.documents && <PdfDocumentsContainer />}
              {display.performance && studyPerformanceContainer}
              {display.gia && <PdfGia displaySettings={display} />}
              {display.diagnostic && <PdfDiagnostics displaySettings={display} />}
              {display.olympiads && <PdfOlympiadContainer />}
            </PdfEducationSection>
            )}
          {display.science
            && (
            <PdfEducationSection
              title="Наука"
              icon={pdfSectionScience}
            >
              {display.scienceReward && <PdfScienceRewardContainer />}
              {display.projects && <PdfScienceProjectContainer />}
              {display.scienceEmployments && <PdfScienceOccupationContainer />}
              {display.scienceContest && <PdfScienceContestContainer />}
            </PdfEducationSection>
            )}
          {display.sport
            && (
            <PdfEducationSection
              title="Cпорт"
              icon={pdfSectionSport}
            >
              {display.sportReward && <PdfSportRewardContainer />}
              {display.sportClub && <PdfSportClubContainer />}
              {display.sportUnit && <PdfSportSectionContainer />}
              {display.sportGames && <PdfSportCompetitionContainer />}
              {display.hike && <PdfSportExpeditionContainer />}
            </PdfEducationSection>
            )}
          {display.creation
            && (
            <PdfEducationSection
              title="Творчество"
              icon={pdfSectionCreation}
            >
              {display.creationReward && <PdfCreationRewardContainer />}
              {display.creationClub && <PdfCreativeCircleContainer />}
              {display.creationContest && <PdfCreativeContestContainer />}
              {display.creationUnit && <PdfCreativeSectionContainer />}
            </PdfEducationSection>
            )}
          {display.culture
            && (
            <PdfEducationSection
              title="Культура"
              icon={pdfSectionCulture}
            >
              {display.offlineVisit && <PdfCultureVisiting />}
              {display.onlineVisit && <PdfOnlineVisiting />}
            </PdfEducationSection>
            )}
          {display.civil
            && (
            <PdfEducationSection
              title="Гражданская активность"
              icon={pdfSectionCivil}
            >
              {display.civilReward && <PdfCivilRewardContainer />}
              {display.civilClub && <PdfCivilClubContainer />}
              {display.civilContest && <PdfCivilContestContainer />}
              {display.civilUnit && <PdfCivilSectionContainer />}
            </PdfEducationSection>
            )}
          {display.profession && (
            <PdfEducationSection title="Моя профессия" icon={pdfSectionProfession}>
              {display.professionRecommendations && <PdfProforientationRecommendations />}
              {display.professionProfTests && <PdfProforientationTests />}
              {display.professionRewards && <PdfProfessionRewardContainer />}
              {display.job && <PdfJobsContainer />}
              {display.metaskills && <PdfMetaskillsContainer />}
              {display.professionEducation && <PdfAdditionalEducationContainer />}
              {display.professionEvents && <PdfProfessionEventsContainer />}
              {display.professionWorldskills && <PdfGiaWorldskillsContainer />}
            </PdfEducationSection>
          )}
        </div>
      </div>
    </>
  );
};

export default StudentPortfolioPdf;
