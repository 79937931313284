import { ComponentType, KeyboardEvent, ReactNode, SyntheticEvent, useEffect, useRef } from 'react';

import { Box, InputBaseProps, useFormControl } from '@mui/material';
import { FakeInputBox, FakeInputSelect } from 'portfolio3/ui-kit/inputs';
import SelectArrow from 'portfolio3/ui-kit/SelectArrow';
import { CommonUiKitSize, IController, InputRenderMode } from 'portfolio3/ui-kit/types';
import { SxStyles } from 'types';
import { isDefined } from 'utils';

import SelectClearButton from '../SelectClearButton';

export interface IInputBaseProps extends InputBaseProps {
  renderMode: InputRenderMode;
}

export interface ISelectBase<ModalProps> {
  inputRenderMode: InputRenderMode; // определяет тип рендера input элемента (fluid / fixed)
  inputSize: CommonUiKitSize;
  controller: IController<string | undefined>;
  ModalComponent: ComponentType<ModalProps>; // компонент модального окна (меню / модалка / дровер)
  ModalComponentProps: Omit<ModalProps, 'open'>; // пропсы, которые передадутся в модальное окно
  children: ReactNode;
  value?: string | number | number[];
  open?: boolean;
  onOpen?: (e: SyntheticEvent) => void; // handler клика на инпут
  onClose?: (e: SyntheticEvent) => void; // handler при закрытии модального окна
  InputComponent?: ComponentType<IInputBaseProps>; // компонент заменит базовый инпут
  InputComponentProps?: InputBaseProps; // пропсы, которые передадутся в инпут
  type?: 'select' | 'input'; // определяет можно ли печатать в инпуте (селект или токен филд)
  disabled?: boolean;
  error?: boolean;
  placeholder?: string;
  // Ф-я, принимающая строчку с кодами DictionaryItem (при multi будет несколько кодов в одной строке)
  renderValue?: ((value: string | undefined) => string | ReactNode) | undefined;
  strictMaxWidth?: boolean; // ограничивает максимальную ширину меню по ширине инпута селекта
  fakeInputSx?: SxStyles; // объект sx для передачи в компонент FakeInputSelect
  useFakeBoxComponent?: boolean; // заменяет компонент FakeInputSelect на более простой FakeInputBox
}

const SelectBase = <ModalProps,>({
  inputRenderMode,
  inputSize,
  controller,
  onOpen,
  onClose,
  ModalComponent,
  // InputComponent,
  ModalComponentProps,
  // InputComponentProps,
  children,
  // error,
  disabled,
  placeholder,
  open = false,
  value,
  type = 'select',
  renderValue,
  strictMaxWidth,
  fakeInputSx,
  useFakeBoxComponent,
}: ISelectBase<ModalProps>) => {
  const formControl = useFormControl();
  const selectRef = useRef<HTMLDivElement>(null);
  const menuWidth = selectRef.current?.clientWidth || 0;

  const isDisabled = formControl?.disabled ?? disabled ?? false;

  // при типе данных массив, он будет неявно преобразован в строку в разделителем ","
  const inputValue = typeof value !== 'undefined' ? String(value) : value;

  const handleOpen = (e: SyntheticEvent) => {
    if (isDisabled) return;
    onOpen?.(e);
  };
  const handleClose = (e: SyntheticEvent) => {
    onClose?.(e);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    // TODO для пробела селект не сохраняет открытие состояние
    const validKeys = [' ', 'Enter'];
    const hasValidKey = validKeys.indexOf(e.key) !== -1;
    if (hasValidKey && !open) {
      e.preventDefault();
      handleOpen(e);
    }
  };

  // TOOD добавить блюр при закрытии (возможно)
  const handleBlur = () => {
    if (!open) {
      controller.handleBlur?.(inputValue);
    }
  };

  useEffect(() => {
    if (value && Boolean(value)) {
      formControl?.onFilled();
    } else {
      formControl?.onEmpty();
    }
  }, [formControl, value]);

  useEffect(() => {
    if (open) {
      formControl?.onFocus();
    } else {
      formControl?.onBlur();
    }
  }, [formControl, open]);

  // const inputProps: IInputBaseProps = {
  //   renderMode: inputRenderMode,
  //   size: inputSize,
  //   value: renderValue ? renderValue(String(value)) : value,
  //   onClick: handleOpen,
  //   onKeyDown: handleKeyDown,
  //   endAdornment: <SelectArrow style={adornmentStyle} />,
  //   readOnly: type === 'select',
  //   inputProps: {
  //     style: {
  //       cursor: isDisabled ? 'default' : type === 'select' ? 'pointer' : 'default',
  //     },
  //   },
  //   style: {
  //     cursor: isDisabled ? 'default' : type === 'select' ? 'pointer' : 'default',
  //   },
  //   ...InputComponentProps,
  //   placeholder: placeholder,
  //   ref: selectRef,
  //   error: error || formControl?.error,
  //   disabled: isDisabled,
  // };

  const modalProps: Omit<ModalProps, 'open'> = {
    ...ModalComponentProps,
    anchorEl: selectRef.current,
    open,
    onClose: handleClose,
    // TODO для дровера эти стили будут не актуальны, нужно подумать что можно сделать
    PaperProps: {
      style: {
        marginTop: 8,
        minWidth: menuWidth,
        maxWidth: strictMaxWidth ? menuWidth : undefined,
      },
    },
  };

  // const inputElement = (InputComponent && <InputComponent {...inputProps} />) || <InputBase {...inputProps} />;

  const renderedValue = renderValue ? renderValue(isDefined(value) ? String(value) : undefined) : value;

  const FakeInputComponent = useFakeBoxComponent ? FakeInputBox : FakeInputSelect;

  const handleClear = () => {
    controller.handleClear?.();
    // восстановление фокуса на инпут
    selectRef.current?.focus();
  };

  const endAdornmentElement = (
    <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
      {controller.handleClear && value && <SelectClearButton isDisabled={isDisabled} onClear={handleClear} />}
      <SelectArrow isOpen={open} isDisabled={isDisabled} />
    </Box>
  );

  return (
    <>
      <FakeInputComponent
        tabIndex={isDisabled ? -1 : 0}
        renderMode={inputRenderMode}
        size={inputSize}
        onClick={handleOpen}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        style={{
          cursor: isDisabled ? 'default' : type === 'select' ? 'pointer' : 'default',
        }}
        disabled={isDisabled}
        placeholder={placeholder}
        value={value}
        endAdornment={endAdornmentElement}
        ref={selectRef}
        sx={fakeInputSx}
      >
        {renderedValue}
      </FakeInputComponent>

      <input
        type="text"
        aria-hidden
        style={{ display: 'none' }}
        value={inputValue ?? ''}
        // TODO добавить обработчик
        onChange={() => null}
        tabIndex={-1}
      />

      {/* {inputElement} */}
      <ModalComponent {...(modalProps as unknown as ModalProps)}>{children}</ModalComponent>
    </>
  );
};

export default SelectBase;
