import { FAILURE, INVALIDATE, REQUEST, SUCCESS } from 'actions/utils';
import { ICommonResponseObject, ISpeciality } from 'api/types';
import { IReducers } from 'reducers';
import genericReducers from 'reducers/genericReducer';
import { connectReducers } from 'utils';

import { GET_SPECIALITIES_REF } from '../actions';

export type SpecialitiesRefState = ICommonResponseObject<ISpeciality[]>;

const initialState: SpecialitiesRefState = {
  content: [],
  loading: false,
};

const specialitiesRef: IReducers<SpecialitiesRefState> = {
  [GET_SPECIALITIES_REF[REQUEST]]: genericReducers().get.request,
  [GET_SPECIALITIES_REF[SUCCESS]]: genericReducers().get.success,
  [GET_SPECIALITIES_REF[FAILURE]]: genericReducers().get.failure,
  [GET_SPECIALITIES_REF[INVALIDATE]]: genericReducers().get.invalidate(initialState),
};

export default connectReducers(initialState, specialitiesRef);
