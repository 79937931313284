export enum SourceKeys {
  UNSPECIFIED = 'unspecified',
  DOU_ENROLLMENT = 'douEnrollment',
  EZHD = 'ezhd',
  OLYMPIAD = 'olympiad',
  MPDM_UPLOAD = 'mpdmUpload',
  STUDENT = 'student',
  PARENT = 'parent',
  OPERATOR = 'operator',
  EMPLOYEE_O_O = 'employeeOo',
  ADMIN = 'admin',
  UCHI_RU = 'uchiRu',
  PROFTECH = 'proftech',
  FIRST_MOVEMENT = 'firstMovement',
}

// data_source_ref
export const SourceCode: Record<SourceKeys, number> = {
  unspecified: 0,
  douEnrollment: 1,
  ezhd: 2,
  olympiad: 3,
  mpdmUpload: 4,
  student: 10,
  parent: 11,
  operator: 12,
  employeeOo: 13,
  admin: 14,
  uchiRu: 15,
  proftech: 16,
  firstMovement: 20,
};
