import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { IconAlertCircleOutline } from 'icons';
import { getAccentColor, NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

const stylesRoot: SxStyles = {
  display: 'grid',
  gridTemplateColumns: '152px 1fr auto',
  gap: '8px',
};

const stylesDescription: SxStyles = {
  alignSelf: 'flex-start',

  display: 'flex',
  alignItems: 'center',
  gap: '4px',

  svg: {
    width: '20px',
    height: '20px',
    color: getAccentColor('red', '100'),
  },
};

interface IIncorrectRecordItemCommentProps {
  param: string;
  value: string;
  description: string;
}

const IncorrectRecordItemComment: FC<IIncorrectRecordItemCommentProps> = ({ param, value, description }) => {
  return (
    <Box className="incorrect-record-item-comment" sx={stylesRoot}>
      <Typography variant="Paragraph MD/Medium" color={NeutralColors.light_neutrals_600}>
        {param}
      </Typography>
      <Typography variant="Paragraph MD/Regular">{value}</Typography>
      <Box sx={stylesDescription}>
        <IconAlertCircleOutline />
        <Typography variant="Paragraph MD/Regular" color={getAccentColor('red', '100')}>
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

export default IncorrectRecordItemComment;
