/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconCheckMark: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M5.95219 12.8561L5.95207 12.856L1.08942 7.67736C1.08935 7.6773 1.08929 7.67723 1.08923 7.67717C0.615085 7.17403 0.64202 6.38281 1.14406 5.91074L1.14429 5.91053C1.64768 5.43836 2.43881 5.46286 2.91053 5.9657L5.95219 12.8561ZM5.95219 12.8561C6.18851 13.1074 6.51845 13.2499 6.86332 13.2499L6.87032 13.2499L6.87212 13.2499C7.21928 13.2474 7.55177 13.1002 7.78608 12.8423C7.7862 12.8422 7.78631 12.842 7.78643 12.8419L16.9229 2.84253C17.3879 2.33377 17.3531 1.54262 16.844 1.07734L16.8438 1.07713C16.3342 0.6125 15.5442 0.646044 15.0768 1.15716L15.0768 1.15723L6.85078 10.16L2.91068 5.96585L5.95219 12.8561Z" fill="white" stroke="white" strokeWidth="0.5" />
  </SvgIcon>
);

export default IconCheckMark;
