/* eslint-disable prettier/prettier */
/* eslint-disable */

export const transportSports = [
  'Автомобильный спорт', 'Велоспорт-ВМХ', 'Велоспорт-маунтинбайк', 'Велоспорт-трек', 'Велоспорт-шоссе', 'Мотоциклетный спорт'
];

export const groundSports = [
  'Американский футбол', 'Баскетбол', 'Бейсбол', 'Волейбол', 'Гандбол', 'Лапта', 'Регби', 'Софтбол', 'Флорбол', 'Футбол', 'Хоккей на траве', 'Хоккей с мячом'
];

export const singleSports = [
  'Альпинизм',
  'Бадминтон',
  'Бильярдный спорт',
  'Боулинг',
  'Гольф',
  'Городошный спорт',
  'Кинологический спорт',
  'Конный спорт',
  'Настольный теннис',
  'Полиатлон',
  'Прыжки на батуте',
  'Скалолазание',
  'Сквош',
  'Современное пятиборье',
  'Спортивно-прикладное собаководство',
  'Спортивное ориентирование',
  'Спортивный туризм',
  'Теннис',
  'Триатлон'
]

export const contactSports = [
  'Айкидо',
  'Армспорт',
  'Бокс',
  'Борьба на поясах',
  'Восточное боевое единоборство',
  'Всестилевое каратэ',
  'Джиу-джитсу',
  'Дзюдо',
  'Капоэйра',
  'Каратэ',
  'Кендо',
  'Кикбоксинг',
  'Киокусинкай',
  'Корэш',
  'Рукопашный бой',
  'Сават',
  'Самбо',
  'Смешанное боевое единоборство (ММА)',
  'Спортивная борьба',
  'Сумо',
  'Тайский бокс',
  'Тхэквондо',
  'Универсальный бой',
  'Ушу',
  'Фехтование'
]

export const waterSports = [
  'Водно-моторный спорт',
  'Водное поло',
  'Воднолыжный спорт',
  'Гребля на байдарках и каноэ',
  'Гребной слалом',
  'Гребной спорт',
  'Морское многоборье',
  'Парусный спорт',
  'Плавание',
  'Подводный спорт',
  'Прыжки в воду',
  'Рафтинг',
  'Синхронное плавание'
]

export const heavySports = [
  'Бодибилдинг',
  'Гиревой спорт',
  'Пауэрлифтинг',
  'Перетягивание каната',
  'Тяжелая атлетика'
]

export const lightSports = [
  'Легкая атлетика',
  'Спортивная акробатика',
  'Спортивная аэробика',
  'Спортивная гимнастика',
  'Фитнес-аэробика',
  'Художественная гимнастика',
  'Эстетическая гимнастика '
]

export const targetSports = [
  'Дартс',
  'Практическая стрельба',
  'Пулевая стрельба',
  'Пэйнтбол',
  'Стендовая стрельба',
  'Стрельба из арбалета',
  'Стрельба из лука'
]

export const airSports = [
  'Вертолетный спорт',
  'Воздухоплавательный спорт',
  'Парашютный спорт',
  'Планерный спорт',
  'Самолетный спорт',
  'Спорт сверхлегкой авиации'
]

export const winterSports = [
  'Бобслей',
  'Горнолыжный спорт',
  'Кёрлинг',
  'Конькобежный спорт',
  'Лыжное двоеборье',
  'Лыжные гонки',
  'Прыжки на лыжах с трамплина',
  'Санный спорт',
  'Северное многоборье',
  'Сноуборд',
  'Фигурное катание на коньках',
  'Фристайл',
  'Хоккей'
]

export const logicSports = [
  'Го',
  'Спортивный бридж',
  'Шахматы',
  'Шашки'
]
