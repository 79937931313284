import { ComponentType, FC, PropsWithChildren, useEffect } from 'react';
import { connect } from 'react-redux';

import {
  getAffilationKindsActions,
  getAvatarTypeRefActions,
  getDocumentRefActions,
  getEmploymentDocTypeRefActions,
  getEventKindsActions,
  getLevelBusinessRefActions,
  getMetaSkillRefActions,
  getProfessionProgrammRefActions,
  getProfessionRankRefActions,
  getSalaryRangeRefActions,
  getSectionRefActions,
  getSpoGiaMarkRefActions,
  getSpoOrganizationRefActions,
  getSportClubsAction,
  getSportKindsAction,
  getSportRewardKindsAction,
  getTourismKindsAction,
} from 'actions';
import { MAX_PAGE_SIZE } from 'api';
import { ILinkVisibilitySettings } from 'api/types';
import { IRootState } from 'reducers';
import { hasContextUserInfoErrorSelector } from 'selectors';
import { shouldLoadCache } from 'utils';

interface IReferenceDataProps {
  state: IRootState;
  linkMode: boolean | undefined;
  linkVisibilitySettings: ILinkVisibilitySettings;
  hasContextUserInfoError: boolean;
  getSectionRef: typeof getSectionRefActions.request;
  getAffilationKinds: typeof getAffilationKindsActions.request;
  getSportClubs: typeof getSportClubsAction.request;
  getSportRewardKinds: typeof getSportRewardKindsAction.request;
  getEventKinds: typeof getEventKindsActions.request;
  getTourismKinds: typeof getTourismKindsAction.request;
  getSportKinds: typeof getSportKindsAction.request;
  getDocumentRef: typeof getDocumentRefActions.request;
  getMetaSkillRef: typeof getMetaSkillRefActions.request;
  getProfessionProgrammRef: typeof getProfessionProgrammRefActions.request;
  getLevelBusinessRef: typeof getLevelBusinessRefActions.request;
  getSalaryRangeRef: typeof getSalaryRangeRefActions.request;
  getEmploymentDocTypeRef: typeof getEmploymentDocTypeRefActions.request;
  getSpoOgranizationRef: typeof getSpoOrganizationRefActions.request;
  getSpoGiaMarkRef: typeof getSpoGiaMarkRefActions.request;
  getProfessionRankRef: typeof getProfessionRankRefActions.request;
  getAvatarTypeRef: typeof getAvatarTypeRefActions.request;
}

/**
 * Компонент загружает справочные данные приложения исходя из полученного контекста пользователя
 * и признака доступа к портфолио по ссылке
 */
const ReferenceData: FC<IReferenceDataProps & PropsWithChildren> = ({
  children,
  state,
  linkMode,
  linkVisibilitySettings,
  hasContextUserInfoError,
  getSectionRef,
  getAffilationKinds,
  getSportClubs,
  getSportRewardKinds,
  getEventKinds,
  getTourismKinds,
  getSportKinds,
  getDocumentRef,
  getMetaSkillRef,
  getProfessionProgrammRef,
  getLevelBusinessRef,
  getSalaryRangeRef,
  getEmploymentDocTypeRef,
  getSpoOgranizationRef,
  getSpoGiaMarkRef,
  getProfessionRankRef,
  getAvatarTypeRef,
}) => {
  const { display: linkDisplay } = linkVisibilitySettings.content || {};

  const load = (key: keyof IRootState) => shouldLoadCache(state, key);

  useEffect(() => {
    if (hasContextUserInfoError) return;
    if (linkMode === undefined) return;

    const queryParams = { size: MAX_PAGE_SIZE };

    const metaSkillsRefLoadCondition = !linkMode || linkDisplay?.metaskills;
    const professionProgrammRefLoadCondition =
      !linkMode || linkDisplay?.professionEducation || linkDisplay?.trainingInfo;

    load('documentRef') && getDocumentRef({ ...queryParams, sort: 'code' });
    load('avatarTypeRef') && getAvatarTypeRef({ ...queryParams, sort: 'code' });

    // получение справочников для доступа по ссылке
    if (metaSkillsRefLoadCondition) {
      load('metaSkillRef') && getMetaSkillRef({ ...queryParams, sort: 'code' });
    }

    if (professionProgrammRefLoadCondition) {
      load('professionProgrammRef') && getProfessionProgrammRef(queryParams);
    }

    // справочники только для просмотра НЕ по ссылке
    if (!linkMode) {
      load('sectionRef') && getSectionRef(queryParams);
      load('affilationKinds') && getAffilationKinds({ ...queryParams, sort: 'value' });
      load('sportClubs') && getSportClubs(queryParams);
      load('sportRewardKinds') && getSportRewardKinds({ ...queryParams, isArchive: 'false' });
      load('eventKinds') && getEventKinds({ ...queryParams, sort: 'value', isArchive: 'false' });
      load('sportKinds') && getSportKinds({ ...queryParams, sort: 'value', isArchive: 'false' });
      load('tourismKinds') && getTourismKinds(queryParams);
      load('levelBusinessRef') && getLevelBusinessRef(queryParams);
      load('salaryRangeRef') && getSalaryRangeRef(queryParams);
      load('employmentDocTypeRef') && getEmploymentDocTypeRef(queryParams);
      load('spoOrganizationRef') && getSpoOgranizationRef(queryParams);
      load('spoGiaMarkRef') && getSpoGiaMarkRef(queryParams);
      load('professionRankRef') && getProfessionRankRef(queryParams);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkMode]);

  return <>{children}</>;
};

const ReferenceDataRedux = connect(
  (state: IRootState) => ({
    state,
    linkMode: state.linkMode.mode,
    linkVisibilitySettings: state.linkVisibilitySettings,
    hasContextUserInfoError: hasContextUserInfoErrorSelector(state),
  }),
  {
    getSectionRef: getSectionRefActions.request,
    getAffilationKinds: getAffilationKindsActions.request,
    getSportClubs: getSportClubsAction.request,
    getSportRewardKinds: getSportRewardKindsAction.request,
    getEventKinds: getEventKindsActions.request,
    getTourismKinds: getTourismKindsAction.request,
    getSportKinds: getSportKindsAction.request,
    getDocumentRef: getDocumentRefActions.request,
    getMetaSkillRef: getMetaSkillRefActions.request,
    getProfessionProgrammRef: getProfessionProgrammRefActions.request,
    getLevelBusinessRef: getLevelBusinessRefActions.request,
    getSalaryRangeRef: getSalaryRangeRefActions.request,
    getEmploymentDocTypeRef: getEmploymentDocTypeRefActions.request,
    getSpoOgranizationRef: getSpoOrganizationRefActions.request,
    getSpoGiaMarkRef: getSpoGiaMarkRefActions.request,
    getProfessionRankRef: getProfessionRankRefActions.request,
    getAvatarTypeRef: getAvatarTypeRefActions.request,
  },
)(ReferenceData);

const withReferenceData = () => {
  return <P extends PropsWithChildren<unknown>>(WrappedComponent: ComponentType<P>): FC<P> => {
    return function WithReferenceData({ ...props }) {
      return (
        <ReferenceDataRedux>
          <WrappedComponent {...props} />
        </ReferenceDataRedux>
      );
    };
  };
};

export default withReferenceData;
