import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';

import { Grid, List } from '@mui/material';
import { CommonAnnouncement } from 'components/redesignStudent/announcement';
import { useScrollToElement } from 'hooks';
import { useUserFunctionality } from 'hooks';

import { IUserContext } from '../../../api/types';
import { IPortfolioNavigation, SectionCodeVisibilitySettings } from '../../../const';
import { CurrentPortfolioSectionContext } from '../../../context';
import { IRootState } from '../../../reducers';
import { linkVisibilitySettingsSelector, visibilitySettingsSelector } from '../../../selectors';
import { IViewVisibilitySetting } from '../../../types';
import { openUrlInNewTab } from '../../../utils';
import ButtonNavigation from './ButtonNavigation';

import './index.scss';

export interface IPortfolioSideNavigationProps {
  portfolioNavigation: IPortfolioNavigation[];
  currentUser: IUserContext;
  visibilitySettings: IViewVisibilitySetting[];
  linkVisibilitySettings: IViewVisibilitySetting[];
  linkMode: boolean | undefined;
  sectionCode: number;
  onNavigationButtonClick: (button: IPortfolioNavigation) => void;
}

const PortfolioSideNavigation: React.FC<IPortfolioSideNavigationProps> = ({
  portfolioNavigation,
  sectionCode,
  currentUser,
  linkMode,
  linkVisibilitySettings,
  visibilitySettings,
  onNavigationButtonClick,
}) => {
  const { isStudent } = useUserFunctionality();
  const [isAnnouncementOpen, setAnnouncementOpen] = useState(false);
  const soonListItemRef = useRef<HTMLDivElement>(null);
  const { currentPortfolioSection, isContainerScrolled, setCurrentPortfolioSection } =
    useContext(CurrentPortfolioSectionContext);

  const { scrollToElementGlobal } = useScrollToElement();

  const currentVisibilitySettings = linkMode ? linkVisibilitySettings : visibilitySettings;

  const { sectionTypeCode } = SectionCodeVisibilitySettings[sectionCode];
  const currentSubSections = currentVisibilitySettings.find(
    (setting) => setting.sectionTypeCode === sectionTypeCode,
  )?.subSections;

  const visibleNavigationItems = useMemo(() => {
    const filteredNavigation = portfolioNavigation.map((navigation) => {
      const navigationSetting = currentSubSections?.find(
        (sectionSetting) => sectionSetting.sectionTypeCode === navigation.sectionTypeCode,
      );
      const isVisible = navigationSetting?.computedVisibility ?? false;
      return {
        ...navigation,
        isVisible,
      };
    });

    return filteredNavigation.filter((navItem) => navItem?.isVisible);
  }, [currentSubSections, portfolioNavigation]);
  const navigationHeight = (visibleNavigationItems.length + 1) * 60;

  const handleClickNavigationButton = (navigation: IPortfolioNavigation) => {
    const { url, id } = navigation;

    return () => {
      if (url) {
        openUrlInNewTab(url);
      } else {
        scrollToElementGlobal(id, currentPortfolioSection);
      }

      onNavigationButtonClick(navigation);
    };
  };

  useEffect(() => {
    if (visibleNavigationItems.length === 0) return;

    const firstNavigationId = visibleNavigationItems[0].id;
    setCurrentPortfolioSection(firstNavigationId);
  }, [setCurrentPortfolioSection, visibleNavigationItems]);

  return (
    <>
      {visibleNavigationItems.length > 0 && (
        <div
          className={
            isContainerScrolled ? 'portfolio-navigation portfolio-navigation--scrolled' : 'portfolio-navigation'
          }
          style={{ maxHeight: `${navigationHeight}px` }}
        >
          <Grid container direction="column" className="portfolio-navigation__inner">
            <List component="nav" aria-label="secondary mailbox folder" sx={{ padding: 0 }}>
              {visibleNavigationItems.map((navigation: IPortfolioNavigation) => (
                <ButtonNavigation
                  key={navigation.id}
                  navigation={navigation}
                  onClickListItem={handleClickNavigationButton(navigation)}
                  currentPortfolioSection={currentPortfolioSection}
                />
              ))}
            </List>
          </Grid>
        </div>
      )}
      <CommonAnnouncement
        title={isStudent ? 'Твои навыки и результаты обучения' : 'Навыки и результаты обучения'}
        titleColor="#6741D9"
        buttonText={isStudent ? 'Круто, буду ждать!' : 'Спасибо, буду ждать'}
        buttonColor="#845EF7"
        hasControls
        anchorElement={soonListItemRef.current}
        isAnnouncementOpen={isAnnouncementOpen}
        onCloseAnnouncement={() => setAnnouncementOpen(false)}
      >
        {isStudent ? (
          <>
            <strong>{currentUser.data?.info?.userFio?.firstName},</strong> скоро здесь будет информация о твоих навыках
            и результатах обучения!
          </>
        ) : (
          <>Скоро здесь будет информация о навыках и результатах обучения вашего ребёнка</>
        )}
      </CommonAnnouncement>
    </>
  );
};

export default connect((state: IRootState) => ({
  currentUser: state.currentUser,
  linkMode: state.linkMode.mode,
  visibilitySettings: visibilitySettingsSelector(state),
  linkVisibilitySettings: linkVisibilitySettingsSelector(state),
}))(PortfolioSideNavigation);
