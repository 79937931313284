/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';

import {
  FAILURE,
  INVALIDATE,
  REQUEST,
  SUCCESS
} from '../../actions/utils';

import genericReducers from '../genericReducer';
import { IReducers } from '..';
import { ICommonResponse, IStudentReward } from '../../api/types';
import { GET_STUDENT_REWARDS } from '../../actions';

export type StudentRewardsState = ICommonResponse<IStudentReward>;

const initialState = {
  content: [],
  loading: false
} as ICommonResponse<IStudentReward>;

const studentRewards: IReducers<StudentRewardsState> = {
  [GET_STUDENT_REWARDS[REQUEST]]: genericReducers().get.request,
  [GET_STUDENT_REWARDS[SUCCESS]]: genericReducers().get.success,
  [GET_STUDENT_REWARDS[FAILURE]]: genericReducers().get.failure,
  [GET_STUDENT_REWARDS[INVALIDATE]]: genericReducers().get.invalidate(initialState),
};

export default connectReducers(initialState, studentRewards);
