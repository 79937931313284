import { FC, ReactElement, ReactNode } from 'react';
import { connect } from 'react-redux';

import { Box, useMediaQuery } from '@mui/material';
import { ExpandableEntityList } from 'components/redesignStudent';
import {
  useBrightTheme,
  useCommonAdvertVisibility,
  useDataEntryDrawer,
  useEntityFilters,
  useScrollToElement,
  useUserFunctionality,
  useWidgetVisibility,
} from 'hooks';
import useDataLoad, { IDataRequest } from 'hooks/dataLoad/useDataLoad';
import { placeholderCharacter } from 'images';
import NoDataPlug from 'portfolio3/components/common/NoDataPlug';
import { WidgetContainer as Widget } from 'portfolio3/components/common/WidgetContainer';
import { IModifiedPersonObject } from 'portfolio3/features/dataEntryForm';
import { commonTheme } from 'portfolio3/styles/theme';
import { IRootState } from 'reducers';
import { IEntity } from 'types';

import { mapEntityToElementWithoutYear, mapEntityToElementWithYear } from '../utils';
import WidgetContainerAddButton from '../WidgetContainerAddButton';
import WidgetContainerTitle from '../WidgetContainerTitle';

interface IReduxProps {
  linkMode: boolean | undefined;
}

interface IWidgetSimpleContainerProps extends IReduxProps {
  containerData: { id: string; label: string };
  title: string;
  initialFormData?: Partial<IModifiedPersonObject>;
  sectionTypeCode: string;
  subsectionTypeCode: string;
  entities: IEntity[];
  isLoading?: boolean;
  noDataTitle: string;
  noDataImage?: string;
  noDataSubtitle?: string;
  mapEntityToElement: (entity: IEntity) => ReactElement;
  advert?: ReactNode;
  emptyAddButtonPlacement?: 'title' | 'plug';
  withoutTimeline?: boolean;
  dataRequests?: IDataRequest[];
  backgroundImage?: string;
  forceHideAddButton?: boolean;
}

/**
 * Компонент для отображеня виджета по сущностям у которых entityType != Event
 */
const WidgetSimpleContainer: FC<IWidgetSimpleContainerProps> = ({
  linkMode,

  containerData,
  title,
  initialFormData,
  sectionTypeCode,
  subsectionTypeCode,
  entities,
  isLoading,
  noDataTitle,
  noDataImage,
  noDataSubtitle,
  mapEntityToElement,
  advert,
  emptyAddButtonPlacement = 'title',
  withoutTimeline,
  dataRequests,
  backgroundImage,
  forceHideAddButton,
}) => {
  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const { handleOpenPrimaryDataEntry } = useDataEntryDrawer();
  const openDataEntry = () => handleOpenPrimaryDataEntry(initialFormData ?? {}, { widgetLabel: containerData.label });

  const isBrightTheme = useBrightTheme();
  const isAdvertVisible = useCommonAdvertVisibility();
  const { isSettingsMode, isWidgetVisible, toggleElement, overlayElement } = useWidgetVisibility({
    sectionTypeCode,
    subsectionTypeCode,
  });

  useDataLoad({ shouldLoad: isWidgetVisible, requests: dataRequests ?? [] });

  const { filteredEntities, displayedEntityCount, hiddenEntityCount, setInitialEntityCount, setAllEntityCount } =
    useEntityFilters({ containerId: containerData.id, entities, initialEntityCount: 5 });

  const { scrollToElementGlobal } = useScrollToElement();
  const { isAdmin } = useUserFunctionality();

  const isAddButtonVisible = !linkMode && !isAdmin && !forceHideAddButton;

  const hasHiddenEntities = hiddenEntityCount > 0;
  const shownEntities = displayedEntityCount;
  const isNotEmpty = filteredEntities && filteredEntities.length > 0;

  const handleSetInitialDisplayedEntities = () => {
    setInitialEntityCount();
    scrollToElementGlobal(containerData.id);
  };

  const buttonAddElement = initialFormData && (
    <WidgetContainerAddButton isMobile={isMobile} isReward={false} onClick={openDataEntry} />
  );
  const visibleButtonAddElement = !isSettingsMode && isAddButtonVisible && buttonAddElement;
  const titleRightElement = isSettingsMode
    ? toggleElement
    : (emptyAddButtonPlacement === 'title' || isNotEmpty) && visibleButtonAddElement;
  const noDataPlugElement = (
    <NoDataPlug
      image={noDataImage ?? placeholderCharacter}
      title={noDataTitle}
      subtitle={noDataSubtitle}
      loading={isLoading}
    >
      {emptyAddButtonPlacement === 'plug' && visibleButtonAddElement}
    </NoDataPlug>
  );

  if (!isWidgetVisible) return null;

  const mapEntity =
    withoutTimeline || isMobile
      ? mapEntityToElementWithoutYear(mapEntityToElement)
      : mapEntityToElementWithYear(mapEntityToElement);

  return (
    <Widget containerData={containerData} withBackground={isBrightTheme}>
      {overlayElement}
      <Widget.Header
        title={<WidgetContainerTitle title={title} right={titleRightElement} />}
        backgroundImage={backgroundImage}
      />
      <Widget.Padding>
        <ExpandableEntityList
          hasHiddenEntities={hasHiddenEntities}
          shownEntities={shownEntities}
          totalEntitites={filteredEntities?.length || 0}
          onSetAllEntities={setAllEntityCount}
          onSetInitialEntities={handleSetInitialDisplayedEntities}
        >
          {isNotEmpty ? filteredEntities.slice(0, displayedEntityCount).map(mapEntity) : noDataPlugElement}
        </ExpandableEntityList>
      </Widget.Padding>
      {isAdvertVisible && advert && (
        <Box sx={{ marginTop: isMobile ? '16px' : '24px', paddingInline: isMobile ? '12px' : '24px' }}>{advert}</Box>
      )}
    </Widget>
  );
};

export default connect((state: IRootState) => ({
  linkMode: state.linkMode.mode,
}))(WidgetSimpleContainer);
