/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

const IconSecondPlace: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="44" height="44" fill="#E5E5E5"/>
    <rect width="1924" height="1397" transform="translate(-1135 -207)" fill="white"/>
    <path d="M22 44C34.1503 44 44 34.1503 44 22C44 9.84974 34.1503 0 22 0C9.84974 0 0 9.84974 0 22C0 34.1503 9.84974 44 22 44Z" fill="#CCCCCC"/>
    <path d="M19.6122 34.9024C19.5717 35.0699 19.4992 35.2281 19.3987 35.3682C19.1778 35.6757 18.872 35.9118 18.5187 36.0476C18.0733 36.2263 17.5932 36.3015 17.1145 36.2676C16.8634 36.2592 16.6128 36.2376 16.364 36.2029C16.1326 36.1642 15.9036 36.1124 15.6781 36.0476C15.4516 35.9894 15.2316 35.9053 15.031 35.8341H14.9792L15.0569 35.7241C15.2148 35.5411 15.4004 35.3839 15.6069 35.2582C15.8139 35.1233 16.0381 35.0167 16.2734 34.9412C16.5616 34.8707 16.8567 34.8316 17.1534 34.8247C17.5847 34.8247 18.0161 34.8247 18.4475 34.8247C18.8034 34.8247 19.1657 34.8247 19.5281 34.8765L19.6122 34.9024Z" fill="#969696"/>
    <path d="M16.5387 33.99C16.4999 34.0483 16.474 34.1 16.4416 34.1453C16.1833 34.4915 15.8004 34.7235 15.374 34.7924C15.1614 34.8382 14.9443 34.8599 14.7269 34.8571C14.5195 34.8518 14.3134 34.8236 14.1122 34.773C13.8928 34.7296 13.6766 34.6712 13.4651 34.5983C13.2846 34.5318 13.1094 34.4518 12.941 34.3589C12.6757 34.2165 12.4169 34.0547 12.1581 33.893L12.1128 33.8606L12.2616 33.7377C12.5922 33.5006 12.9818 33.3595 13.3875 33.33C13.8764 33.2994 14.3668 33.3541 14.8369 33.4918C15.3934 33.6406 15.9499 33.8089 16.4999 33.9642L16.5387 33.99Z" fill="#969696"/>
    <path d="M11.4723 14.0411C11.2682 13.81 11.1297 13.5285 11.0711 13.2258C10.9799 12.793 11.0181 12.3429 11.1811 11.9317C11.2995 11.6288 11.4514 11.3402 11.6341 11.0711C11.8226 10.8007 12.0395 10.5512 12.2811 10.327C12.4817 10.1199 12.7017 9.93875 12.9282 9.74463L12.9411 9.70581C13.0117 9.84528 13.0556 9.9967 13.0705 10.1523C13.1033 10.4346 13.0946 10.7202 13.0447 10.9999C12.9835 11.3139 12.8812 11.6185 12.7405 11.9058C12.3801 12.6433 11.9648 13.3528 11.4982 14.0282L11.4723 14.0411Z" fill="#969696"/>
    <path d="M12.5078 13.0059C12.7858 12.2278 13.2858 11.5484 13.9459 11.0516C14.6061 10.5547 15.3974 10.2624 16.2219 10.2106C15.9084 10.9598 15.423 11.6248 14.8049 12.1517C14.1656 12.7014 13.3509 13.0044 12.5078 13.0059Z" fill="#969696"/>
    <path d="M9.88696 31.35C10.0499 31.2495 10.2296 31.1794 10.4176 31.1429C10.896 31.0557 11.3894 31.105 11.8411 31.2853C12.3743 31.5007 12.8874 31.7627 13.3746 32.0682C13.5817 32.1847 13.7887 32.3141 14.0217 32.4694L13.944 32.5212C13.6498 32.817 13.2545 32.9904 12.8375 33.0065C12.3343 33.0254 11.8367 32.8948 11.4075 32.6312C10.8543 32.3148 10.3596 31.9055 9.9452 31.4212L9.88696 31.35Z" fill="#969696"/>
    <path d="M8.3147 28.4059L8.71587 28.3542C8.99245 28.3292 9.2711 28.3668 9.53117 28.4642C9.69615 28.5277 9.8562 28.6034 10.01 28.6906C10.418 28.9235 10.7924 29.2109 11.1229 29.5448L11.9447 30.3342L12.0159 30.4118C11.7828 30.5864 11.5041 30.6898 11.2135 30.7095C10.9384 30.7213 10.6637 30.6788 10.405 30.5844C10.1463 30.4901 9.90874 30.3457 9.70587 30.1595C9.50713 29.9855 9.31914 29.7997 9.14293 29.603C8.82759 29.2633 8.55985 28.8824 8.34705 28.4706C8.33203 28.4515 8.32102 28.4295 8.3147 28.4059Z" fill="#969696"/>
    <path d="M10.2494 16.9595C10.1231 16.8648 10.0022 16.7633 9.88708 16.6553C9.74216 16.515 9.62571 16.3481 9.54414 16.1636C9.41272 15.8674 9.33809 15.5491 9.32414 15.2253C9.31129 14.7261 9.38799 14.2287 9.55061 13.7565C9.6838 13.3333 9.86623 12.9272 10.0941 12.5465C10.105 12.5204 10.1203 12.4963 10.1394 12.4753L10.2235 12.5918C10.5051 12.9848 10.671 13.4488 10.7024 13.9312C10.735 14.3908 10.7024 14.8526 10.6053 15.303C10.5018 15.8206 10.3918 16.3383 10.2818 16.8559C10.2733 16.8911 10.2625 16.9257 10.2494 16.9595Z" fill="#969696"/>
    <path d="M7.40234 25.2352C7.58943 25.2203 7.77768 25.2379 7.95881 25.287C8.46446 25.439 8.91442 25.7359 9.25293 26.1411C9.66053 26.6125 10.0284 27.1168 10.3529 27.6488L10.5729 27.9982C10.4969 28.0269 10.4191 28.0507 10.34 28.0694C10.0427 28.1436 9.73033 28.1301 9.44058 28.0305C8.95117 27.8442 8.53405 27.5064 8.24999 27.0664C7.87111 26.5298 7.58664 25.9324 7.40881 25.3C7.40578 25.2807 7.40578 25.261 7.40881 25.2417L7.40234 25.2352Z" fill="#969696"/>
    <path d="M8.48299 15.3677C8.63719 15.4692 8.77106 15.5987 8.87769 15.7494C9.03602 15.9464 9.16665 16.1641 9.26593 16.3965C9.39156 16.7424 9.46991 17.1037 9.49887 17.4706C9.55063 18.175 9.55063 18.8822 9.49887 19.5865C9.49887 19.5865 9.49887 19.6318 9.49887 19.6706L9.33064 19.6059C9.07701 19.4855 8.85252 19.3115 8.67267 19.0959C8.49281 18.8803 8.36187 18.6282 8.28887 18.3571C8.15933 17.9143 8.11325 17.4514 8.15299 16.9918C8.18107 16.4636 8.27898 15.9414 8.44417 15.4389C8.45532 15.4142 8.46829 15.3904 8.48299 15.3677Z" fill="#969696"/>
    <path d="M7.4803 18.5382L7.73912 18.6806C8.20121 18.9957 8.55216 19.4486 8.74206 19.9747C8.94674 20.5258 9.0963 21.0958 9.18853 21.6764C9.23383 21.9223 9.27265 22.1747 9.31147 22.427C9.31147 22.427 9.31147 22.4788 9.31147 22.5241H9.1303C8.8196 22.4485 8.5324 22.2973 8.29422 22.0839C8.05603 21.8706 7.87424 21.6017 7.765 21.3011C7.56211 20.8116 7.44606 20.2905 7.42206 19.7611C7.38666 19.3716 7.38666 18.9796 7.42206 18.59C7.42206 18.59 7.47382 18.5706 7.4803 18.5382Z" fill="#969696"/>
    <path d="M7.11792 21.8771H7.21498C7.80378 22.0336 8.30784 22.4145 8.6191 22.9383C8.94367 23.4576 9.21054 24.0109 9.41498 24.5883C9.4991 24.8083 9.57674 25.0347 9.66086 25.2353C9.66086 25.2353 9.66086 25.2677 9.66086 25.2871C9.4305 25.3328 9.19232 25.3194 8.96851 25.2483C8.69303 25.1552 8.43953 25.0068 8.22364 24.812C8.00774 24.6173 7.83403 24.3804 7.71321 24.1159C7.57298 23.8551 7.4623 23.5795 7.38321 23.2941C7.30557 22.99 7.22792 22.6471 7.16321 22.3688C7.11792 22.2071 7.11792 22.0518 7.11792 21.8771Z" fill="#969696"/>
    <path d="M17.0045 31.5377H17.0433C17.614 31.9283 18.1547 32.3608 18.661 32.8318C19.0299 33.1735 19.3173 33.5936 19.5022 34.0612C19.5791 34.2688 19.6228 34.4872 19.6316 34.7083C19.6316 34.7083 19.6316 34.7471 19.6316 34.7795H19.4957C18.8682 34.601 18.2814 34.3021 17.768 33.8995C17.4442 33.6568 17.1898 33.3332 17.0304 32.9612C16.8536 32.5305 16.8375 32.0505 16.9851 31.6089C16.9888 31.5845 16.9953 31.5606 17.0045 31.5377Z" fill="#969696"/>
    <path d="M15.044 30.1918C15.5908 30.7893 16.0485 31.4628 16.4028 32.1912C16.6231 32.6564 16.6997 33.1769 16.6228 33.6859C16.6228 33.7571 16.5904 33.8218 16.5775 33.8994H16.5387C15.927 33.5557 15.4092 33.0669 15.031 32.4759C14.8035 32.128 14.6841 31.7204 14.6881 31.3047C14.6917 30.9293 14.804 30.5629 15.0116 30.25C15.0179 30.2284 15.0289 30.2085 15.044 30.1918Z" fill="#969696"/>
    <path d="M15.3935 13.8018L14.7464 14.0671C14.353 14.2335 13.946 14.3655 13.5299 14.4618C12.9112 14.6279 12.2521 14.5467 11.6923 14.2353C11.6364 14.204 11.5824 14.1694 11.5305 14.1318L11.6405 14.0282C12.1213 13.587 12.7036 13.2713 13.3358 13.1094C13.6101 13.0311 13.8984 13.0154 14.1796 13.0636C14.4607 13.1117 14.7274 13.2225 14.9599 13.3877C15.1285 13.4981 15.2755 13.6384 15.3935 13.8018Z" fill="#969696"/>
    <path d="M12.0741 30.4118C11.9511 30.1594 11.8411 29.9265 11.7376 29.6935C11.6849 29.5764 11.6416 29.4552 11.6082 29.3312C11.5564 29.0918 11.4917 28.8588 11.4594 28.613C11.389 28.2109 11.4386 27.7971 11.602 27.4231C11.7654 27.0491 12.0354 26.7315 12.3782 26.51L12.4429 26.4712C12.4947 26.6912 12.5399 26.9047 12.5852 27.1182C12.6933 27.6177 12.7433 28.1279 12.7341 28.6388C12.7304 29.2223 12.5399 29.7892 12.1905 30.2565L12.0741 30.4118Z" fill="#969696"/>
    <path d="M13.6854 15.5294C13.5236 15.6782 13.3748 15.84 13.213 15.9759C12.9413 16.2023 12.6695 16.4288 12.3848 16.6229C11.9367 16.9588 11.3846 17.1261 10.8254 17.0953C10.6636 17.0953 10.5019 17.05 10.3271 17.0241C10.3271 16.9788 10.3271 16.9594 10.3595 16.9464C10.4501 16.7976 10.5342 16.6488 10.6313 16.5064C10.7253 16.3652 10.8268 16.2292 10.9354 16.0988C11.1386 15.846 11.3811 15.6276 11.6536 15.4517C11.9406 15.259 12.2785 15.156 12.6242 15.156C12.9699 15.156 13.3078 15.259 13.5948 15.4517L13.666 15.5035C13.666 15.5035 13.6724 15.4841 13.6854 15.5294Z" fill="#969696"/>
    <path d="M12.5528 17.4707L12.3198 17.8266C12.041 18.2617 11.7132 18.6633 11.3428 19.0236C10.9007 19.4378 10.318 19.6691 9.71217 19.6707H9.60864C9.60864 19.6707 9.60864 19.6707 9.60864 19.6254C9.7863 18.9263 10.1509 18.2888 10.6633 17.7813C10.9047 17.5582 11.2056 17.4101 11.5296 17.355C11.8535 17.2998 12.1865 17.34 12.4881 17.4707H12.5204H12.5528Z" fill="#969696"/>
    <path d="M11.8023 24.2452C11.8023 24.3294 11.8023 24.407 11.8023 24.4847C11.8337 25.1363 11.7771 25.7893 11.634 26.4258C11.5024 26.9968 11.1863 27.5084 10.7346 27.8817L10.6311 27.9529C10.6311 27.8752 10.5858 27.8041 10.5664 27.7394C10.4126 27.1927 10.3578 26.6229 10.4046 26.057C10.4247 25.6645 10.5574 25.2861 10.7869 24.9671C11.0164 24.6481 11.333 24.4019 11.6988 24.2582L11.8023 24.2452Z" fill="#969696"/>
    <path d="M11.608 21.9546C11.5281 22.6201 11.3628 23.2725 11.1163 23.8958C10.9832 24.226 10.7901 24.5287 10.5469 24.7887C10.3435 25.0019 10.1021 25.1753 9.8351 25.2999H9.78981H9.75745C9.75465 25.2438 9.75465 25.1877 9.75745 25.1317C9.69082 24.4923 9.77048 23.8462 9.9904 23.2422C10.0982 22.8982 10.3036 22.5928 10.5815 22.3631C10.8594 22.1334 11.1981 21.9892 11.5563 21.9481H11.6275L11.608 21.9546Z" fill="#969696"/>
    <path d="M9.39526 22.5048C9.42762 22.3172 9.44703 22.1425 9.47938 21.9678C9.55805 21.4733 9.73163 20.9987 9.99056 20.5701C10.16 20.2491 10.4241 19.9881 10.7471 19.8225C11.0702 19.657 11.4363 19.5949 11.7959 19.6448H11.8347C11.8023 19.729 11.7764 19.8131 11.7441 19.8907C11.5651 20.4428 11.315 20.9692 11 21.4566C10.6504 21.9909 10.1038 22.3653 9.47938 22.4984L9.39526 22.5048Z" fill="#969696"/>
    <path d="M24.5884 34.9024C24.626 35.0709 24.6988 35.2297 24.8019 35.3682C25.021 35.6747 25.3245 35.9107 25.6754 36.0477C26.1234 36.2251 26.6053 36.3003 27.086 36.2677C27.3372 36.2589 27.5877 36.2373 27.8366 36.2029C28.0679 36.1635 28.2968 36.1117 28.5225 36.0477C28.749 35.9894 28.969 35.9053 29.1696 35.8341H29.2213C29.2213 35.7953 29.1696 35.7565 29.1372 35.7241C28.9831 35.5398 28.7994 35.3823 28.5937 35.2582C28.391 35.1238 28.1712 35.0171 27.9401 34.9412C27.652 34.8702 27.3569 34.8312 27.0601 34.8247C26.6331 34.8247 26.1996 34.8247 25.766 34.8247C25.3325 34.8247 25.0478 34.8247 24.6854 34.8765L24.5884 34.9024Z" fill="#969696"/>
    <path d="M27.6812 33.9899L27.7782 34.1452C28.0356 34.4924 28.419 34.7247 28.8459 34.7922C29.0584 34.8381 29.2755 34.8598 29.4929 34.8569C29.7111 34.8541 29.9283 34.8259 30.14 34.7728C30.3594 34.7294 30.5756 34.6711 30.787 34.5981C30.9924 34.5235 31.1914 34.4326 31.3823 34.3264C31.6476 34.184 31.9 34.0222 32.1653 33.8605L32.2041 33.8281C32.1578 33.7833 32.108 33.7422 32.0553 33.7052C31.7252 33.4672 31.3353 33.326 30.9294 33.2975C30.4427 33.2673 29.9544 33.322 29.4864 33.4593C28.93 33.6081 28.3735 33.7763 27.8235 33.9316L27.6812 33.9899Z" fill="#969696"/>
    <path d="M32.7477 14.0412C32.9518 13.8101 33.0903 13.5286 33.1489 13.2259C33.2402 12.7931 33.2019 12.343 33.0389 11.9318C32.921 11.6275 32.7667 11.3386 32.5795 11.0712C32.3932 10.7991 32.1761 10.5494 31.9324 10.3271C31.7319 10.1201 31.5119 9.93887 31.2854 9.74476L31.2207 9.69946C31.1522 9.83932 31.1105 9.99075 31.0977 10.1459C31.0589 10.4185 31.0589 10.6952 31.0977 10.9677C31.1594 11.2807 31.2593 11.585 31.3954 11.8736C31.7605 12.6099 32.1779 13.3192 32.6442 13.9959L32.7477 14.0412Z" fill="#969696"/>
    <path d="M31.7058 13.0059C31.4278 12.2278 30.9278 11.5484 30.2677 11.0516C29.6076 10.5547 28.8163 10.2624 27.9917 10.2106C28.3052 10.9598 28.7907 11.6248 29.4088 12.1517C30.0474 12.7024 30.8625 13.0055 31.7058 13.0059Z" fill="#969696"/>
    <path d="M34.3331 31.3501C34.1684 31.2484 33.9863 31.1782 33.796 31.143C33.3198 31.0541 32.828 31.1035 32.379 31.2854C31.8431 31.4994 31.3276 31.7615 30.839 32.0683C30.6319 32.1848 30.4313 32.3142 30.1919 32.4695L30.2695 32.5212C30.5637 32.8171 30.9591 32.9905 31.376 33.0065C31.879 33.0231 32.376 32.8927 32.806 32.6312C33.3685 32.3173 33.8721 31.9079 34.2942 31.4212L34.3331 31.3501Z" fill="#969696"/>
    <path d="M35.905 28.4059L35.5039 28.3542C35.2273 28.3292 34.9486 28.3668 34.6886 28.4642C34.5236 28.5277 34.3635 28.6034 34.2097 28.6906C33.8017 28.9235 33.4273 29.2109 33.0968 29.5448L32.275 30.3342L32.2039 30.4118C32.4364 30.5875 32.7154 30.6909 33.0062 30.7095C33.2845 30.7239 33.5629 30.6827 33.8251 30.5882C34.0873 30.4938 34.3281 30.348 34.5333 30.1595C34.732 29.9855 34.92 29.7997 35.0962 29.603C35.4026 29.2605 35.6636 28.8799 35.8727 28.4706L35.905 28.4059Z" fill="#969696"/>
    <path d="M33.9641 16.9595C34.0937 16.8665 34.2169 16.7649 34.3329 16.6553C34.4778 16.515 34.5942 16.3481 34.6758 16.1636C34.8072 15.8674 34.8819 15.5491 34.8958 15.2253C34.9087 14.7261 34.832 14.2287 34.6693 13.7565C34.5362 13.3333 34.3537 12.9272 34.1258 12.5465C34.1125 12.5217 34.0973 12.4979 34.0805 12.4753L33.9899 12.5918C33.7107 12.9855 33.5471 13.4494 33.5176 13.9312C33.4828 14.3905 33.5133 14.8523 33.6082 15.303C33.7117 15.8206 33.8282 16.3383 33.9382 16.8559L33.9641 16.9595Z" fill="#969696"/>
    <path d="M36.8176 25.2352C36.6305 25.2199 36.4421 25.2374 36.2611 25.287C35.7554 25.4389 35.3055 25.7359 34.967 26.1411C34.5585 26.6202 34.1885 27.1308 33.8605 27.6682L33.647 28.0176C33.7211 28.0453 33.7967 28.0691 33.8734 28.0887C34.1707 28.1634 34.4832 28.1499 34.7729 28.0499C35.2651 27.8655 35.6849 27.5275 35.9699 27.0858C36.3488 26.5491 36.6333 25.9517 36.8111 25.3193C36.8141 25.3 36.8141 25.2804 36.8111 25.2611L36.8176 25.2352Z" fill="#969696"/>
    <path d="M35.7371 15.3677C35.5809 15.4691 35.4449 15.5985 35.3359 15.7494C35.1818 15.9481 35.0535 16.1656 34.9541 16.3965C34.8285 16.7424 34.7502 17.1037 34.7212 17.4706C34.6694 18.175 34.6694 18.8822 34.7212 19.5865C34.7212 19.5865 34.7212 19.6318 34.7212 19.6706L34.8959 19.6059C35.1483 19.4847 35.3715 19.3104 35.5502 19.0948C35.7289 18.8793 35.8589 18.6276 35.9312 18.3571C36.0628 17.9147 36.111 17.4518 36.0735 16.9918C36.0423 16.4639 35.9445 15.9422 35.7824 15.4389C35.7691 15.414 35.754 15.3902 35.7371 15.3677Z" fill="#969696"/>
    <path d="M36.74 18.5382C36.6494 18.59 36.5588 18.6288 36.4812 18.6806C36.0191 18.9957 35.6681 19.4486 35.4782 19.9747C35.2706 20.5248 35.1209 21.0952 35.0318 21.6764C34.9865 21.9223 34.9476 22.1747 34.9023 22.427C34.905 22.4593 34.905 22.4918 34.9023 22.5241H35.077C35.3843 22.4555 35.6695 22.3113 35.9068 22.1045C36.1441 21.8977 36.3259 21.6349 36.4359 21.34C36.6439 20.8518 36.7622 20.3301 36.7853 19.8C36.8207 19.4104 36.8207 19.0184 36.7853 18.6288C36.7853 18.6288 36.7465 18.5706 36.74 18.5382Z" fill="#969696"/>
    <path d="M37.1087 21.8771H37.0117C36.7149 21.9546 36.4364 22.09 36.1922 22.2754C35.9479 22.4609 35.7426 22.6927 35.5881 22.9577C35.2693 23.4715 35.0068 24.0182 34.8052 24.5883C34.7211 24.8083 34.6434 25.0347 34.5593 25.2353C34.5593 25.2353 34.5593 25.2677 34.5593 25.2871C34.7875 25.3328 35.0236 25.3194 35.2452 25.2483C35.5215 25.1553 35.7759 25.007 35.9929 24.8122C36.2099 24.6175 36.3848 24.3806 36.507 24.1159C36.6472 23.8551 36.7579 23.5795 36.837 23.2941C36.9146 22.99 36.9923 22.6471 37.0505 22.3688C37.0829 22.2071 37.0893 22.0518 37.1087 21.8771Z" fill="#969696"/>
    <path d="M27.2155 31.5377H27.1767C26.6048 31.9266 26.064 32.3593 25.559 32.8318C25.1881 33.1717 24.9003 33.5924 24.7178 34.0612C24.6381 34.268 24.5943 34.4868 24.5884 34.7083C24.5858 34.732 24.5858 34.7558 24.5884 34.7795H24.7243C25.3513 34.5996 25.9378 34.3009 26.452 33.8995C26.7674 33.6515 27.0164 33.3291 27.1767 32.9612C27.3535 32.5305 27.3695 32.0505 27.222 31.6089C27.222 31.6089 27.222 31.5636 27.2155 31.5377Z" fill="#969696"/>
    <path d="M29.1759 30.1918C28.6334 30.7914 28.1782 31.4645 27.8235 32.1912C27.6054 32.6571 27.5311 33.1775 27.61 33.6859C27.6195 33.7581 27.6347 33.8295 27.6553 33.8994H27.6941C28.3044 33.5538 28.8218 33.0654 29.2018 32.4759C29.4262 32.1265 29.5453 31.7199 29.5447 31.3047C29.5412 30.9293 29.4288 30.5629 29.2212 30.25L29.1759 30.1918Z" fill="#969696"/>
    <path d="M28.8264 13.8018L29.4735 14.0671C29.8665 14.2344 30.2736 14.3665 30.6899 14.4618C31.3087 14.6272 31.9675 14.546 32.5276 14.2353C32.5813 14.2038 32.6332 14.1692 32.6829 14.1318L32.5794 14.0282C32.0986 13.587 31.5162 13.2713 30.8841 13.1094C30.6098 13.0311 30.3214 13.0154 30.0403 13.0636C29.7592 13.1117 29.4925 13.2225 29.2599 13.3877C29.0914 13.4981 28.9444 13.6384 28.8264 13.8018Z" fill="#969696"/>
    <path d="M30.703 28.4706C30.6188 28.6453 30.5412 28.8006 30.47 28.9623C30.2273 29.4683 30.0428 30.0001 29.92 30.5476C29.794 31.1178 29.8506 31.7132 30.0818 32.2494L30.14 32.3659H30.1788C30.6492 31.9013 31.0019 31.3312 31.2077 30.7029C31.3193 30.3968 31.3575 30.0687 31.3193 29.745C31.2811 29.4214 31.1675 29.1112 30.9877 28.8394C30.9165 28.6906 30.813 28.5806 30.703 28.4706Z" fill="#969696"/>
    <path d="M32.1459 30.4118C32.2623 30.1594 32.3788 29.9265 32.4823 29.6935C32.5358 29.5774 32.577 29.4559 32.6053 29.3312C32.671 29.0954 32.7207 28.8554 32.7541 28.613C32.826 28.2115 32.7778 27.7978 32.6155 27.4237C32.4532 27.0496 32.184 26.7317 31.8417 26.51L31.777 26.4712C31.7253 26.6912 31.6735 26.9047 31.6347 27.1182C31.5245 27.6174 31.4724 28.1277 31.4794 28.6388C31.4892 29.2212 31.6791 29.7863 32.0229 30.2565L32.1459 30.4118Z" fill="#969696"/>
    <path d="M30.5349 15.5294C30.6967 15.6782 30.8455 15.84 31.0073 15.9759C31.279 16.2023 31.5508 16.4288 31.8355 16.6229C32.2831 16.9599 32.8356 17.1272 33.3949 17.0953C33.5567 17.0953 33.7184 17.05 33.8867 17.0241C33.8887 16.9982 33.8887 16.9723 33.8867 16.9464C33.7961 16.7976 33.712 16.6488 33.6149 16.5064C33.5209 16.3652 33.4194 16.2292 33.3108 16.0988C33.1059 15.8476 32.8637 15.6294 32.5926 15.4517C32.3056 15.259 31.9677 15.156 31.622 15.156C31.2763 15.156 30.9384 15.259 30.6514 15.4517L30.5802 15.5035C30.5802 15.5035 30.5479 15.4841 30.5349 15.5294Z" fill="#969696"/>
    <path d="M31.6672 17.4706L31.9002 17.8264C32.1787 18.2599 32.5042 18.6614 32.8708 19.0235C33.3135 19.4366 33.8958 19.6677 34.5014 19.6706H34.6114C34.6114 19.6706 34.6114 19.6706 34.6114 19.6253C34.4337 18.9248 34.0666 18.2867 33.5502 17.7812C33.3076 17.5535 33.0031 17.4026 32.675 17.3473C32.347 17.2921 32.0099 17.3349 31.7061 17.4706H31.6672Z" fill="#969696"/>
    <path d="M32.4176 24.2452C32.4176 24.3294 32.4176 24.407 32.4176 24.4847C32.383 25.1364 32.4396 25.7898 32.5858 26.4258C32.718 26.995 33.0313 27.5059 33.4787 27.8817L33.5823 27.9529L33.6534 27.7394C33.8083 27.1929 33.861 26.6226 33.8087 26.057C33.7911 25.6639 33.6593 25.2844 33.4295 24.9649C33.1997 24.6454 32.8818 24.3999 32.5146 24.2582L32.4176 24.2452Z" fill="#969696"/>
    <path d="M32.6117 21.9547C32.6916 22.6202 32.8569 23.2726 33.1035 23.8959C33.23 24.2138 33.4143 24.5056 33.647 24.7565C33.8504 24.9697 34.0918 25.143 34.3588 25.2677C34.3588 25.2677 34.3588 25.2677 34.404 25.2677V25.0994C34.4676 24.4601 34.388 23.8147 34.1711 23.21C34.0633 22.8659 33.8579 22.5605 33.58 22.3308C33.3021 22.1011 32.9635 21.957 32.6052 21.9159H32.6117V21.9547Z" fill="#969696"/>
    <path d="M34.8182 22.5048C34.8182 22.3171 34.7729 22.1424 34.7405 21.9677C34.6619 21.4732 34.4883 20.9986 34.2294 20.57C34.0589 20.2485 33.7935 19.9872 33.4694 19.8217C33.1453 19.6561 32.7781 19.5944 32.4176 19.6448H32.3853C32.3853 19.7289 32.4435 19.813 32.4758 19.8906C32.6685 20.438 32.9317 20.9578 33.2588 21.4371C33.6084 21.9714 34.1549 22.3458 34.7794 22.4789L34.8182 22.5048Z" fill="#969696"/>
    <path d="M21.9998 40.493C18.3422 40.493 14.7668 39.4084 11.7257 37.3764C8.68451 35.3443 6.31422 32.4561 4.91454 29.077C3.51485 25.6978 3.14863 21.9795 3.86218 18.3922C4.57574 14.805 6.33702 11.5098 8.9233 8.92355C11.5096 6.33726 14.8047 4.57598 18.392 3.86243C21.9793 3.14887 25.6976 3.51509 29.0767 4.91478C32.4559 6.31447 35.3441 8.68475 37.3761 11.7259C39.4081 14.7671 40.4927 18.3425 40.4927 22C40.4876 26.9031 38.5376 31.6039 35.0706 35.0708C31.6036 38.5378 26.9028 40.4878 21.9998 40.493ZM21.9998 4.86591C18.611 4.86591 15.2983 5.87081 12.4806 7.75353C9.66289 9.63625 7.46677 12.3122 6.16993 15.4431C4.87308 18.5739 4.53377 22.019 5.1949 25.3427C5.85602 28.6664 7.48789 31.7194 9.88413 34.1157C12.2804 36.5119 15.3334 38.1438 18.6571 38.8049C21.9808 39.466 25.4259 39.1267 28.5567 37.8299C31.6876 36.5331 34.3636 34.3369 36.2463 31.5192C38.129 28.7015 39.1339 25.3888 39.1339 22C39.1288 17.4574 37.3219 13.1022 34.1098 9.89005C30.8976 6.6779 26.5425 4.87105 21.9998 4.86591Z" fill="#969696"/>
    <path d="M27.3382 29.4671H16.8235V27.2606L20.5958 23.4494C21.7088 22.302 22.4378 21.5082 22.7829 21.0682C23.085 20.6949 23.3333 20.2811 23.5205 19.8388C23.6731 19.4674 23.7501 19.0692 23.747 18.6676C23.7733 18.4239 23.7465 18.1773 23.6683 17.945C23.5901 17.7126 23.4624 17.5 23.2941 17.3218C22.9219 17.0155 22.4491 16.8587 21.9676 16.8818C21.3792 16.8837 20.7992 17.021 20.2723 17.2829C19.6561 17.5941 19.0785 17.9763 18.5511 18.4218L16.8235 16.3771C17.3895 15.8718 18.0051 15.4252 18.6611 15.0441C19.1647 14.7784 19.6994 14.5762 20.2529 14.4424C20.8889 14.2927 21.5407 14.221 22.1941 14.2288C23.0507 14.2125 23.9 14.3894 24.6788 14.7465C25.348 15.0624 25.9169 15.5573 26.3223 16.1765C26.7232 16.8085 26.9301 17.544 26.9176 18.2924C26.9214 18.9572 26.7962 19.6164 26.5488 20.2335C26.2672 20.9067 25.8878 21.5347 25.4229 22.0971C24.5884 23.0672 23.6868 23.9775 22.7247 24.8212L20.7835 26.6394V26.7818H27.3382V29.4671Z" fill="#969696"/>
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="44" height="44">
      <path d="M22 44C34.1503 44 44 34.1503 44 22C44 9.84974 34.1503 0 22 0C9.84974 0 0 9.84974 0 22C0 34.1503 9.84974 44 22 44Z" fill="#A9BEFF"/>
    </mask>
    <g mask="url(#mask0)">
      <path opacity="0.3" d="M5.45437 25.1772C28.3648 25.1772 46.9373 6.60464 46.9373 -16.3058C46.9373 -39.2162 28.3648 -57.7887 5.45437 -57.7887C-17.456 -57.7887 -36.0286 -39.2162 -36.0286 -16.3058C-36.0286 6.60464 -17.456 25.1772 5.45437 25.1772Z" fill="url(#paint0_linear)"/>
    </g>
    <rect x="-1070.5" y="-130.5" width="1768" height="373" rx="4.5" stroke="#7B61FF" strokeDasharray="10 5"/>
    <defs>
      <linearGradient id="paint0_linear" x1="-9.12386" y1="-43.6246" x2="22.9897" y2="16.5584" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" stopOpacity="0"/>
        <stop offset="0.35" stopColor="white" stopOpacity="0.01"/>
        <stop offset="0.49" stopColor="white" stopOpacity="0.03"/>
        <stop offset="0.59" stopColor="white" stopOpacity="0.08"/>
        <stop offset="0.67" stopColor="white" stopOpacity="0.15"/>
        <stop offset="0.74" stopColor="white" stopOpacity="0.24"/>
        <stop offset="0.8" stopColor="white" stopOpacity="0.35"/>
        <stop offset="0.85" stopColor="white" stopOpacity="0.47"/>
        <stop offset="0.9" stopColor="white" stopOpacity="0.62"/>
        <stop offset="0.95" stopColor="white" stopOpacity="0.79"/>
        <stop offset="1" stopColor="white"/>
      </linearGradient>
    </defs>
  </svg>
);

export default IconSecondPlace;
