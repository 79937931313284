import { FC, PropsWithChildren } from 'react';

import { Box, useTheme } from '@mui/material';
import { IconCheckmark } from 'icons/edit';
import { getAccentColor } from 'portfolio3/styles';
import { NeutralColors } from 'portfolio3/styles';

import BaseBadge from './BaseBadge';

interface IToggleBadge {
  selected?: boolean;
  onClick?: () => void;
}

const ToggleBadge: FC<IToggleBadge & PropsWithChildren> = ({ selected, onClick, children }) => {
  const { palette } = useTheme();
  const backgroundColor = selected ? getAccentColor(palette.accentColor, '100') : 'transparent';
  const textColor = selected ? NeutralColors.light_neutrals_0 : NeutralColors.light_neutrals_600;
  const border = `1px solid ${selected ? 'transparent' : NeutralColors.light_neutrals_500}`;

  return (
    <Box onClick={onClick}>
      <BaseBadge sx={{ border, cursor: 'pointer' }} textColor={textColor} backgroundColor={backgroundColor}>
        {selected && <IconCheckmark />}
        {children}
      </BaseBadge>
    </Box>
  );
};
export default ToggleBadge;
