import { SET_MAIN_BOUNDARY_ERROR } from 'actions/boundaryError';
import { IReducers } from 'reducers';
import { connectReducers } from 'utils';

export type BoundaryErrorState = {
  hasMainBoundaryError: boolean;
};

type SetMainBoundaryErrorAction = { payload: boolean };

const initialState: BoundaryErrorState = {
  hasMainBoundaryError: false,
};

const boundaryError: IReducers<BoundaryErrorState> = {
  [SET_MAIN_BOUNDARY_ERROR]: (state, { payload }: SetMainBoundaryErrorAction) => {
    return {
      ...state,
      hasMainBoundaryError: payload,
    };
  },
};

export default connectReducers(initialState, boundaryError);
