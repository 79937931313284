/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useMemo } from 'react';

import { connect } from 'react-redux';

import PdfCommonDataEntityContainer from '../common';
import { IRootState } from '../../../../reducers';
import { ICommonResponse } from '../../../../api/types';
import { PdfCommonDataEntity } from '../../../../components/pdfStudent/DataEntity';
import { widgetPdfIcons } from '../../../../icons';
import { studyOlympiadsSelector } from '../../../../selectors';
import { IEntity } from '../../../../types';

import '../index.scss';
import { useDataLoad } from 'hooks';
import { getDataLoadRequests } from 'utils';

interface IPdfOlympiadContainerProps {
  studentOlympiads: ICommonResponse<IEntity>;
}

const PdfOlympiadContainer: React.FC<IPdfOlympiadContainerProps> = ({
  studentOlympiads
}) => {
  useDataLoad({ shouldLoad: true, requests: getDataLoadRequests().studyOlympiads });

  const filteredOlympiadsYear = useMemo(() => (studentOlympiads.content && studentOlympiads.content?.length > 0
    ? studentOlympiads.content?.reduce((prevItem, nextItem) => (prevItem.year > nextItem.year ? prevItem : nextItem)).year
    : undefined),
  [studentOlympiads]);

  return (
    <PdfCommonDataEntityContainer
      title="Олимпиады"
      icon={widgetPdfIcons.pdfIconStudyOlympiads}
    >
      {studentOlympiads.content
        .map((olympiad: IEntity) => (
          <PdfCommonDataEntity key={olympiad.id || olympiad.recordId} entity={olympiad} year={filteredOlympiadsYear} />
        ))}
    </PdfCommonDataEntityContainer>
  );
};

export default connect(
  (state: IRootState) => ({
    studentOlympiads: studyOlympiadsSelector(state)
  })
)(PdfOlympiadContainer);
