import { FC, useContext, useMemo } from 'react';

import { DocumentTypeCodes } from 'const';

import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';
import StudySpoDocumentBlock from './StudySpoDocumentBlock';
import { StudySpoDocumentFields } from './StudySpoDocumentBlock/utils';

const StudySpoDocumentSecondaryBlock: FC = () => {
  const { formData } = useContext(LocalDataEntryFormContext);

  const data = formData.studySpoDocument;

  const formFields = useMemo(() => {
    let fields: Partial<Record<StudySpoDocumentFields, boolean>> = {};

    switch (data?.documentTypeCode) {
      // Сертификат
      case DocumentTypeCodes.spoCertificate: {
        fields = {
          educationProgramm: true,
          organization: true,
          hours: true,
          docDate: true,
          docPlace: true,
        };
        break;
      }
      // Свидетельство о профессии рабочего, должности служащего
      case DocumentTypeCodes.spoJobCertificate: {
        fields = {
          organization: true,
          certificateNum: true,
          regNum: true,
          docDate: true,
          docPlace: true,
          professionalProgramm: true,
          hours: true,
          examMark: false,
          resultDate: true,
          profession: true,
          rank: true,
        };
        break;
      }
      // Удостоверение о краткосрочном квалификационном обучении
      case DocumentTypeCodes.spoShortTrainingCertificate: {
        fields = {
          organization: true,
          educationProgramm: true,
          hours: true,
          regNum: true,
          docDate: true,
          docPlace: true,
        };
        break;
      }
      // Удостоверение о повышении квалификации
      case DocumentTypeCodes.spoProfessionalCertificate: {
        fields = {
          organization: true,
          educationProgramm: true,
          hours: true,
          regNum: true,
          docDate: true,
          docPlace: true,
          docNum: false,
          docSeries: false,
        };
        break;
      }
      // Другое
      case DocumentTypeCodes.spoOther: {
        fields = {
          documentName: true,
          organization: true,
          educationProgramm: false,
          professionalProgramm: false,
          hours: true,
          regNum: false,
          docDate: true,
          docPlace: true,
          examMark: false,
          profession: false,
          rank: false,
          docNum: false,
          docSeries: false,
        };
        break;
      }
      default: {
        fields = {};
      }
    }

    return fields;
  }, [data?.documentTypeCode]);

  return <StudySpoDocumentBlock fields={formFields} />;
};

export default StudySpoDocumentSecondaryBlock;
