/* eslint-disable prettier/prettier */
/* eslint-disable */

import { FC, useRef, PropsWithChildren } from 'react';

import {
  CarouselProvider,
  ButtonBack,
  ButtonNext
} from 'pure-react-carousel';

import { iconCarouselArrow } from '../../../icons';
import { useCallbackRef, useSlidesCount } from '../../../hooks';

import './index.scss';

interface ICarouselProps {
  items: any;
  cardWidth: number;
  gap: number;
}

const Carousel: FC<ICarouselProps & PropsWithChildren> = ({
  items,
  cardWidth,
  gap,
  children
}) => {
  const itemsRef = useRef(items);
  const itemsUniqueId = useRef<number | null>(null);

  const { element, elementRef } = useCallbackRef();
  const calculatedSlidesCount = useSlidesCount(element, cardWidth, gap);

  const uniqueId = items !== itemsRef.current ? Math.random() : itemsUniqueId.current;
  itemsRef.current = items;
  itemsUniqueId.current = uniqueId;

  return (
    <div className="common-carousel" ref={elementRef}>
      <CarouselProvider
        key={uniqueId}
        naturalSlideWidth={cardWidth}
        naturalSlideHeight={270}
        totalSlides={items.length}
        visibleSlides={calculatedSlidesCount}
        dragEnabled={false}
        isIntrinsicHeight
      >
        { children }
        {items.length > calculatedSlidesCount && (
          <>
            <ButtonBack className="arrow arrow_left box-shadow_tokyo">
              <img src={iconCarouselArrow} alt="назад" />
            </ButtonBack>
            <ButtonNext className="arrow arrow_right box-shadow_tokyo">
              <img src={iconCarouselArrow} alt="вперед" />
            </ButtonNext>
          </>
        )}
      </CarouselProvider>
    </div>
  );
};

export default Carousel;
