import { FC, PropsWithChildren, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { FeaturePreviewContainer, FeaturePreviewEffects } from 'components/common/FeaturePreview';
import { CurrentPortfolioSectionContext } from 'context';
import { useHeaderHeight } from 'hooks';
import { YandexMetrika } from 'portfolio3/features/yandexMetrika';
import { getCurrentPortfolioSectionId } from 'utils';

import { rootStyles } from './styles';

const MainContainer: FC<PropsWithChildren> = ({ children }) => {
  const [currentPortfolioSection, setCurrentPortfolioSection] = useState<string | undefined>();
  const [isContainerScrolled, setContainerScrolled] = useState(false);

  const headerHeight = useHeaderHeight();

  useEffect(() => {
    const handleScroll = () => {
      const currentSection = getCurrentPortfolioSectionId(headerHeight);
      const { scrollTop } = document.documentElement;

      if (currentSection !== currentPortfolioSection) {
        setCurrentPortfolioSection(currentSection);
      }

      if (scrollTop > 0 && !isContainerScrolled) {
        setContainerScrolled(true);
      } else if (scrollTop <= 0 && isContainerScrolled) {
        setContainerScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  return (
    <CurrentPortfolioSectionContext.Provider
      value={{
        setCurrentPortfolioSection,
        isContainerScrolled,
        currentPortfolioSection,
      }}
    >
      <FeaturePreviewContainer />
      <FeaturePreviewEffects />
      <YandexMetrika />
      <Box component="main" sx={rootStyles} className="main-container">
        {children}
      </Box>
    </CurrentPortfolioSectionContext.Provider>
  );
};

export default MainContainer;
