/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import format from 'date-fns/format';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { IRootState } from '../../../reducers';
import { ChangeHistoryTabs, ChangeHistoryTabsRoute, DifferentCode, EntityType, HistoryActionTypeCodes } from '../../../const';
import { IChangeHistoryFilters } from './types';
import { ChangeHistoryContext } from '../../../context';
import {
  getHistoryActions,
  getImportHistoryActions,
  deleteEmployeeAffilationActions,
  deleteEmployeeEmploymentActions,
  deleteEmployeeEventActions,
  deleteEmployeeProjectActions,
  deleteEmployeeRewardActions,
  deleteEmployeeSportRewardActions,
  getEventByIdActions,
  getProjectByIdActions,
  getEmploymentByIdActions,
  getAffilationByIdActions,
  getRewardByIdActions,
  getSportRewardByIdActions,
  clearEmployeeHistoryFormAction,
  setCurrentStudentAction,
  getGiaWorldSkillsByIdActions,
  deleteEmployeeGiaWorldSkillsActions
} from '../../../actions';
import { ChangeHistoryHeader, EmployeeImportDataTable, EmployeeManualDataTable } from '../../../components/employee/ChangeHistory';
import { IChildInfo, ICommonResponse, IDictionaryItem } from '../../../api/types';
import { HistoryFormEditStatusState } from '../../../reducers/employee/historyFormEditStatus';
import { isRegionalUser } from '../../../utils';

import PaddingContainer from 'portfolio3/components/common/PaddingContainer';
import { HistoryState } from 'reducers/employee/history';
import { availableEmployeeParentSectionsSelector } from 'selectors';
import { useLoadSectionRef } from 'hooks';

import './index.scss';

interface IChangeHistoryProps {
  portfolioSections: ICommonResponse<IDictionaryItem>,
  history: HistoryState,
  historyFormEditStatus: HistoryFormEditStatusState,
  getHistory: typeof getHistoryActions.request,
  getImportHistory: typeof getImportHistoryActions.request,
  deleteEmployeeReward: typeof deleteEmployeeRewardActions.request,
  deleteEmployeeSportReward: typeof deleteEmployeeSportRewardActions.request,
  deleteEmployeeEvent: typeof deleteEmployeeEventActions.request,
  deleteEmployeeProject: typeof deleteEmployeeProjectActions.request,
  deleteEmployeeEmployment: typeof deleteEmployeeEmploymentActions.request,
  deleteEmployeeAffilation: typeof deleteEmployeeAffilationActions.request,
  deleteEmployeeGiaWorldSkills: typeof deleteEmployeeGiaWorldSkillsActions.request,
  getEventById: typeof getEventByIdActions.request,
  getProjectById: typeof getProjectByIdActions.request,
  getEmploymentById: typeof getEmploymentByIdActions.request,
  getAffilationById: typeof getAffilationByIdActions.request,
  getRewardById: typeof getRewardByIdActions.request,
  getSportRewardById: typeof getSportRewardByIdActions.request,
  getGiaWorldSkillsById: typeof getGiaWorldSkillsByIdActions.request,
  clearEmployeeHistoryForm: typeof clearEmployeeHistoryFormAction,
  setCurrentStudent: typeof setCurrentStudentAction
}

const ChangeHistory: React.FC<IChangeHistoryProps> = ({
  portfolioSections,
  history: historyState,
  historyFormEditStatus,
  getHistory,
  getImportHistory,
  deleteEmployeeAffilation,
  deleteEmployeeEmployment,
  deleteEmployeeEvent,
  deleteEmployeeProject,
  deleteEmployeeReward,
  deleteEmployeeSportReward,
  deleteEmployeeGiaWorldSkills,
  getEmploymentById,
  getEventById,
  getRewardById,
  getSportRewardById,
  getAffilationById,
  getProjectById,
  getGiaWorldSkillsById,
  clearEmployeeHistoryForm,
  setCurrentStudent
}) => {
  const initialFilters: IChangeHistoryFilters = {
    startDate: null,
    endDate: null,
    actionType: DifferentCode,
    category: portfolioSections.content.length === 1 ? portfolioSections.content[0].code : DifferentCode,
    page: 0,
    limit: 10,
    sort: { date: 'desc' }
  };
  const [filters, setFilters] = useState<IChangeHistoryFilters>(initialFilters);
  const [historyType, setHistoryType] = useState<ChangeHistoryTabs>(ChangeHistoryTabs.manual);

  useLoadSectionRef();

  const history = useHistory();
  const match = useRouteMatch();

  const isRegional = isRegionalUser();

  const getCurrentHistoryMethod = (actionType?: number) => {
    switch (actionType) {
      case HistoryActionTypeCodes.POST: return 'POST';
      case HistoryActionTypeCodes.DELETE: return 'DELETE';
      case HistoryActionTypeCodes.PUT: return 'PUT';
      default: return undefined;
    }
  };

  const getCurrentHistoryCategoryCode = (categoryCode?: number) => {
    if (categoryCode === DifferentCode) return undefined;
    return categoryCode;
  };

  // в таблице есть подписка на historyFormEntity, поэтому при выходе со страницы необходимо очистить этот стейт
  // иначе при возвращании на страницу открывается окно редактирования сущности
  useEffect(() => {
    return () => {
      clearEmployeeHistoryForm();
    };
  }, []);

  useEffect(() => {
    setFilters(initialFilters);

    const tabRoute = ChangeHistoryTabsRoute[historyType];
    history.replace(`${match.url}/${tabRoute}`);
  }, [historyType]);

  useEffect(() => {
    const total = historyState.totalElements ?? 1;
    const limit = filters.limit;
    // индекс максимальной доступной страницы
    const maxPageIndex = Math.ceil(total / limit) - 1;
    const clampedMaxPageIndex = maxPageIndex < 0 ? 0 : maxPageIndex;
    const isPageOverflow = filters.page > clampedMaxPageIndex;

    if (isPageOverflow) {
      setFilters((prevState) => ({
        ...prevState,
        page: clampedMaxPageIndex,
      }));
      return;
    }

    if (historyType === ChangeHistoryTabs.manual) {
      getHistory({
        method: getCurrentHistoryMethod(filters.actionType),
        categoryCode: getCurrentHistoryCategoryCode(filters.category),
        startDate: filters.startDate ? format(filters.startDate, 'yyyy-MM-dd') : undefined,
        endDate: filters.endDate ? format(filters.endDate, 'yyyy-MM-dd') : undefined,
        pagingOptions: {
          pageNumber: filters.page,
          pageSize: filters.limit
        },
        sort: filters.sort
      });
    }
    if (historyType === ChangeHistoryTabs.import) {
      getImportHistory({
        categoryCode: getCurrentHistoryCategoryCode(filters.category),
        startDate: filters.startDate ? format(filters.startDate, 'yyyy-MM-dd') : undefined,
        endDate: filters.endDate ? format(filters.endDate, 'yyyy-MM-dd') : undefined,
        pagingOptions: {
          pageNumber: filters.page,
          pageSize: filters.limit
        },
        sort: filters.sort
      });
    }
  }, [filters]);

  useEffect(() => {
    if (historyFormEditStatus.status === 'success' && historyFormEditStatus.loading === false) {
      getHistory({
        method: getCurrentHistoryMethod(filters.actionType),
        categoryCode: getCurrentHistoryCategoryCode(filters.category),
        startDate: filters.startDate ? format(filters.startDate, 'yyyy-MM-dd') : undefined,
        endDate: filters.endDate ? format(filters.endDate, 'yyyy-MM-dd') : undefined,
        pagingOptions: {
          pageNumber: filters.page,
          pageSize: filters.limit
        },
        sort: filters.sort
      });
    }
  }, [historyFormEditStatus]);

  const handledeleteEmployeeObject = (type: EntityType, id?: number) => {
    const mappedFilters = {
      method: getCurrentHistoryMethod(filters.actionType),
      categoryCode: getCurrentHistoryCategoryCode(filters.category),
      startDate: filters.startDate ? format(filters.startDate, 'yyyy-MM-dd') : undefined,
      endDate: filters.endDate ? format(filters.endDate, 'yyyy-MM-dd') : undefined,
      pagingOptions: {
        pageNumber: filters.page,
        pageSize: filters.limit
      },
      sort: filters.sort
    };
    if (!id) return;
    switch (type) {
      case EntityType.REWARD:
        deleteEmployeeReward(id, mappedFilters);
        break;
      case EntityType.SPORT_REWARD:
        deleteEmployeeSportReward(id, mappedFilters);
        break;
      case EntityType.EVENT:
        deleteEmployeeEvent(id, mappedFilters);
        break;
      case EntityType.PROJECT:
        deleteEmployeeProject(id, mappedFilters);
        break;
      case EntityType.AFFILATION:
        deleteEmployeeAffilation(id, mappedFilters);
        break;
      case EntityType.EMPLOYMENT:
        deleteEmployeeEmployment(id, mappedFilters);
        break;
      case EntityType.GIA_WORLDSKILLS:
        deleteEmployeeGiaWorldSkills(id, mappedFilters);
        break;
      default:
        break;
    }
  };

  const handleEditEmployeeObject = (type: EntityType, currentStudent: IChildInfo, id?: number) => {
    setCurrentStudent(currentStudent);
    clearEmployeeHistoryForm();
    if (!id) return;
    switch (type) {
      case EntityType.REWARD:
        getRewardById(id);
        break;
      case EntityType.SPORT_REWARD:
        getSportRewardById(id);
        break;
      case EntityType.EVENT:
        getEventById(id);
        break;
      case EntityType.PROJECT:
        getProjectById(id);
        break;
      case EntityType.AFFILATION:
        getAffilationById(id);
        break;
      case EntityType.EMPLOYMENT:
        getEmploymentById(id);
        break;
      case EntityType.GIA_WORLDSKILLS:
        getGiaWorldSkillsById(id);
        break;
      default:
        break;
    }
  };

  return (
    <ChangeHistoryContext.Provider value={{
      currentFilters: filters,
      setCurrentFilters: setFilters
    }}
    >
      <ChangeHistoryHeader historyType={historyType} onChangeHistoryType={setHistoryType} />
      <PaddingContainer className="change-history-container" sx={{ marginTop: '24px' }}>
        {historyType === ChangeHistoryTabs.manual && <EmployeeManualDataTable onDeleteEmployeeObject={handledeleteEmployeeObject} onEditEmployeeObject={handleEditEmployeeObject} />}
        {historyType === ChangeHistoryTabs.import && !isRegional && <EmployeeImportDataTable />}
      </PaddingContainer>
    </ChangeHistoryContext.Provider>
  );
};

export default connect(
  (state: IRootState) => ({
    history: state.history,
    historyFormEditStatus: state.historyFormEditStatus,
    portfolioSections: availableEmployeeParentSectionsSelector(state),
  }),
  {
    getHistory: getHistoryActions.request,
    getImportHistory: getImportHistoryActions.request,
    deleteEmployeeReward: deleteEmployeeRewardActions.request,
    deleteEmployeeSportReward: deleteEmployeeSportRewardActions.request,
    deleteEmployeeEvent: deleteEmployeeEventActions.request,
    deleteEmployeeProject: deleteEmployeeProjectActions.request,
    deleteEmployeeEmployment: deleteEmployeeEmploymentActions.request,
    deleteEmployeeAffilation: deleteEmployeeAffilationActions.request,
    deleteEmployeeGiaWorldSkills: deleteEmployeeGiaWorldSkillsActions.request,
    getEventById: getEventByIdActions.request,
    getProjectById: getProjectByIdActions.request,
    getEmploymentById: getEmploymentByIdActions.request,
    getAffilationById: getAffilationByIdActions.request,
    getRewardById: getRewardByIdActions.request,
    getSportRewardById: getSportRewardByIdActions.request,
    getGiaWorldSkillsById: getGiaWorldSkillsByIdActions.request,
    clearEmployeeHistoryForm: clearEmployeeHistoryFormAction,
    setCurrentStudent: setCurrentStudentAction
  }
)(ChangeHistory);
