import { FC, PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';

const ModalContainer: FC<PropsWithChildren> = ({ children }) => {
  const modalRoot = document.getElementById('modal_root');
  if (modalRoot) {
    return createPortal(<div>{children}</div>, modalRoot);
  }
  return null;
};

export default ModalContainer;
