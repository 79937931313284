import { SxStyles } from 'types';

export const root: SxStyles = {
  display: 'flex',
  gap: '16px',
};

export const dateControl: SxStyles = {
  maxWidth: '180px',
};

export const snilsControl: SxStyles = {
  maxWidth: '150px',
};

export const phoneControl: SxStyles = {
  maxWidth: '180px',
};
