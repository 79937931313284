import { FC } from 'react';

import { Typography, TypographyProps } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';
import { mergeSx } from 'utils';

const styles: SxStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '24px',

  color: NeutralColors.light_neutrals_600,
};

const WeekDayCell: FC<TypographyProps> = (props) => {
  return <Typography {...props} variant="Paragraph SM/Bold" className="weekday-cell" sx={mergeSx(styles, props.sx)} />;
};

export default WeekDayCell;
