/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';
import genericReducers from '../genericReducer';
import { IReducers } from '../index';

import { GET_IMPORT_LIST } from '../../actions/employees';
import { REQUEST, FAILURE, SUCCESS } from '../../actions/utils';

import { IImportListResponse } from '../../api/types';

export type ImportListState = IImportListResponse;

const initialState = {} as IImportListResponse;

const importList: IReducers<ImportListState> = {
  [GET_IMPORT_LIST[REQUEST]]: genericReducers().get.request,
  [GET_IMPORT_LIST[SUCCESS]]: genericReducers().get.success,
  [GET_IMPORT_LIST[FAILURE]]: genericReducers().get.failure
};

export default connectReducers(initialState, importList);
