/* eslint-disable max-len */
import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconSectionScience: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5.713 3.766h8.592a1.133 1.133 0 0 0 0-2.266H5.713a1.133 1.133 0 1 0 0 2.266ZM18.32 16.14l-4.78-7.883V6.302a.885.885 0 1 0-1.771 0v2.387l.814 1.324H7.286l.942-1.572V6.316a.885.885 0 0 0-1.771 0V8.03l-4.788 8.13a1.63 1.63 0 0 0 1.452 2.352h13.754a1.63 1.63 0 0 0 1.445-2.372Z"
      fill={props.fill || 'currentColor' || '#5C7CFA'}
    />
  </SvgIcon>
);

export default IconSectionScience;
