import { createSelector } from 'reselect';

import { IRootState } from '../../../reducers';
import { mapSportOccupationsResponse } from './mappers';

const sportOccupationsSelector = createSelector([(state: IRootState) => state.studentEmployments], (employments) => {
  return mapSportOccupationsResponse(employments);
});

export default sportOccupationsSelector;
