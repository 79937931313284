/* eslint-disable max-len */
import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconSectionCulture: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17.8 16.602H2.2a1.2 1.2 0 1 0 0 2.4h15.6a1.2 1.2 0 0 0 0-2.4ZM8.792 9.597v4.058a1.2 1.2 0 0 0 2.4 0V9.597a1.2 1.2 0 1 0-2.4 0ZM14.058 9.597v4.058a1.2 1.2 0 1 0 2.4 0V9.597a1.2 1.2 0 1 0-2.4 0ZM3.527 9.597v4.058a1.2 1.2 0 0 0 2.4 0V9.597a1.2 1.2 0 1 0-2.4 0ZM1.938 6.62h16.125A.937.937 0 0 0 19 5.681a.922.922 0 0 0-.51-.818l-.082-.037-7.718-3.675a1.643 1.643 0 0 0-1.38 0l-7.793 3.69a.952.952 0 0 0-.517.84.937.937 0 0 0 .938.937Z"
      fill={props.fill || 'currentColor' || '#FF6B6B'}
    />
  </SvgIcon>
);

export default IconSectionCulture;
