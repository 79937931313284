/* eslint-disable max-len */
import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconSportContestsCommon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.2 17H0v7h6.2v-7ZM10.5 2 12 0l1.5 2 2.3.7-1.4 2-.1 2.5-2.3-.7-2.3.7-.1-2.5-1.4-2 2.3-.7ZM15.098 10.4h-6.2V24h6.2V10.4ZM24 14.5h-6.2V24H24v-9.5Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconSportContestsCommon;
