import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';
import { numberToPixelsString } from 'utils';

import { getFocusStateStyles, ITokenFieldTokenTokens, resetButtonStyles } from '../utils';

export const root = (tokens: ITokenFieldTokenTokens, disabled?: boolean): SxStyles => {
  const { height, paddingInline, gap } = tokens;

  return {
    display: 'flex',
    alignItems: 'center',
    gap: numberToPixelsString(gap),

    height,
    paddingInline: numberToPixelsString(paddingInline),

    border: disabled ? 'none' : '1px solid',
    borderColor: NeutralColors.light_neutrals_400,
    borderRadius: '8px',

    backgroundColor: disabled ? NeutralColors.light_neutrals_300 : NeutralColors.light_neutrals_200,
  };
};

export const label: SxStyles = {
  display: 'grid',
  gridAutoFlow: 'column',
  gridTemplateColumns: 'auto',
  whiteSpace: 'nowrap',
};

export const deleteButton = (tokens: ITokenFieldTokenTokens): SxStyles => {
  const { iconSize } = tokens;

  return {
    ...resetButtonStyles,

    width: iconSize,
    height: iconSize,
    cursor: 'pointer',

    '& svg': {
      width: 'inherit',
      height: 'inherit',
      color: NeutralColors.light_neutrals_600,
    },

    ':focus-visible': {
      ...getFocusStateStyles(),
    },
  };
};
