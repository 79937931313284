import { ComponentProps, FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Typography } from '@mui/material';
import { useAppSelector } from 'hooks';
import { PerformanceYearCard } from 'portfolio3/features/pdf/components/cards';
import PdfNoDataText from 'portfolio3/features/pdf/components/PdfNoDataText';
import { getStudentPerformanceOldActions } from 'portfolio3/features/performanceOld/model';
import { IPerformanceYear } from 'portfolio3/features/performanceOld/types';
import { isDefined } from 'utils';

import { widgetPdfIcons } from '../../../icons';
import PdfCommonDataEntityContainer from '../dataEntityContainers/common';

import './index.scss';

const LOWEST_SUBJECT_GRADE = 2;

type PerformanceYearCardProps = ComponentProps<typeof PerformanceYearCard>;

const mapYearPerformanceToCard = (
  year: IPerformanceYear,
  type: 'average' | 'final',
): PerformanceYearCardProps | null => {
  const averageResultProp: keyof IPerformanceYear = type === 'average' ? 'averageAllMarks' : 'averageAllYearMarks';
  const subjectResultProp: keyof IPerformanceYear['subjects'][number] =
    type === 'average' ? 'averageMark' : 'averageYearMark';

  const { learningYear, subjects } = year;

  const mappedSubjects = subjects
    .filter((subject) => subject[subjectResultProp] >= LOWEST_SUBJECT_GRADE)
    .map((subject): PerformanceYearCardProps['subjects'][number] => {
      return {
        name: subject.subjectName,
        result: subject[subjectResultProp],
      };
    });

  if (!learningYear || mappedSubjects.length === 0) return null;

  return {
    averageResult: year[averageResultProp] ?? 0,
    subjects: mappedSubjects,
  };
};

const PdfPerformanceContainerOld: FC = () => {
  const dispatch = useDispatch();
  const currentStudent = useAppSelector((state) => state.currentStudent);
  const studentPerformance = useAppSelector((state) => state.studentPerformanceOld.content?.year ?? []);

  const learningYears = studentPerformance
    .map((year) => year.learningYear)
    .filter(isDefined)
    // года обучения сортируются по убыванию
    .sort((a, b) => Number(b.slice(0, 4)) - Number(a.slice(0, 4)));

  useEffect(() => {
    if (!currentStudent.meshId) return;

    dispatch(getStudentPerformanceOldActions.request(currentStudent.meshId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStudent.meshId]);

  return (
    <PdfCommonDataEntityContainer title="Предметные результаты" icon={widgetPdfIcons.pdfIconStudyResults}>
      {learningYears.map((learningYear) => {
        const currentYearPerformance = studentPerformance.find((year) => year.learningYear === learningYear);

        if (!currentYearPerformance) return null;

        const averagePerformanceCardProps = mapYearPerformanceToCard(currentYearPerformance, 'average');
        const finalPerformanceCardProps = mapYearPerformanceToCard(currentYearPerformance, 'final');

        return (
          <Box
            key={learningYear}
            className="pdf-performance-block"
            sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}
          >
            {/* средняя */}
            <Box>
              <Typography variant="Headings/H5" marginBottom="16px">
                Средняя успеваемость за {learningYear} учебный год
              </Typography>
              {averagePerformanceCardProps ? (
                <PerformanceYearCard {...averagePerformanceCardProps} />
              ) : (
                <PdfNoDataText />
              )}
            </Box>
            {/* итоговая */}
            <Box>
              <Typography variant="Headings/H5" marginBottom="16px">
                Итоговая успеваемость за {learningYear} учебный год
              </Typography>
              {finalPerformanceCardProps ? <PerformanceYearCard {...finalPerformanceCardProps} /> : <PdfNoDataText />}
            </Box>
          </Box>
        );
      })}
    </PdfCommonDataEntityContainer>
  );
};

export default PdfPerformanceContainerOld;
