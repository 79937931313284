import { FC } from 'react';
import { YMInitializer } from 'react-yandex-metrika';

import { useAppSelector, useUserFunctionality } from 'hooks';
import { userLocalRolesSelector } from 'selectors';

import { getYandexMetrikaCode } from '../../utils/yandexMetrika';

const YandexMetrika: FC = () => {
  const localRoles = useAppSelector(userLocalRolesSelector);
  const user = useUserFunctionality();

  if (user.isAdmin) return null;

  const accounts = getYandexMetrikaCode(user, localRoles);

  return (
    <YMInitializer
      accounts={accounts}
      options={{
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
      }}
    />
  );
};

export default YandexMetrika;
