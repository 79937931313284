import { FC, PropsWithChildren } from 'react';

import { Box } from '@mui/material';
import PdfIconImage from 'portfolio3/features/pdf/components/PdfIconImage';
import { SxStyles } from 'types';

import './index.scss';

const blockStyles: SxStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
};

interface IPdfCommonDataEntityContainerProps {
  title: string;
  icon: string;
}

const PdfCommonDataEntityContainer: FC<IPdfCommonDataEntityContainerProps & PropsWithChildren> = ({
  title,
  icon,
  children,
}) => (
  <div className="pdf-data-entity-container">
    <div className="pdf-data-entity-container__header">
      <Box sx={blockStyles}>
        <PdfIconImage size={24} svgUrl={icon} />
        <h3 className="pdf-data-entity-container__title">{title}</h3>
      </Box>
    </div>
    <div className="pdf-data-entity-container__content">{children}</div>
  </div>
);

export default PdfCommonDataEntityContainer;
