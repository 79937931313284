import { FC } from 'react';

import { Stack, Typography } from '@mui/material';
import { IPreviewData } from 'api/types';

import CorrectRecords from './CorrectRecords';
import IncorrectRecords from './IncorrectRecords';

interface IHeaderEmployeeProps {
  previewData: IPreviewData;
}

const ImportPreview: FC<IHeaderEmployeeProps> = ({ previewData }) => {
  const { correctRecordsCount, incorrectRecordsCount, incorrectRecords: incorrectRecordsWrapper } = previewData;

  const incorrectRecords = incorrectRecordsWrapper?.records ?? [];

  const isEmpty = correctRecordsCount === 0 && incorrectRecordsCount === 0;
  const hasCorrect = correctRecordsCount > 0;
  const hasIncorrect = incorrectRecordsCount > 0;

  return (
    <Stack className="import-preview" gap="12px">
      {isEmpty && <Typography>Загруженный файл пуст. Добавьте данные в файл.</Typography>}
      {hasCorrect && <CorrectRecords count={correctRecordsCount} />}
      {hasIncorrect && <IncorrectRecords count={incorrectRecordsCount} records={incorrectRecords} />}
    </Stack>
  );
};

export default ImportPreview;
