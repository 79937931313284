import { ChangeEvent, FC } from 'react';

import { RadioGroup } from '@mui/material';
import { IDictionaryItem } from 'api/types';
import { MobileViewDrawer } from 'portfolio3/components/drawers';
import { BaseToggleLabel } from 'portfolio3/ui-kit';
import RadioButton from 'portfolio3/ui-kit/RadioButton';

interface ISortingDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  value: number;
  onChange: (value: number) => void;
  options: IDictionaryItem[];
}

const SortingDrawer: FC<ISortingDrawerProps> = ({ isOpen, onClose, value, onChange, options }) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const numberValue = Number(event.target.value);
    onChange(isNaN(numberValue) ? value : numberValue);
    onClose();
  };

  return (
    <MobileViewDrawer title="Сортировка" onClose={onClose} open={isOpen}>
      <RadioGroup value={value} onChange={handleChange} sx={{ gap: '12px' }}>
        {options.map((option) => (
          <BaseToggleLabel
            size="small"
            key={option.code}
            value={option.code}
            label={option.value}
            control={<RadioButton size="small" />}
          />
        ))}
      </RadioGroup>
    </MobileViewDrawer>
  );
};

export default SortingDrawer;
