import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import TrimTextMultiLine from 'portfolio3/components/common/TrimTextMultiLine';
import { NeutralColors } from 'portfolio3/styles';

import * as styles from './styles';

interface IRewardProps {
  name: string;
  date?: string;
  image: string;
}

const Reward: FC<IRewardProps> = ({ name, date, image }) => {
  return (
    <Box className="reward" sx={styles.root}>
      <Box sx={styles.description}>
        <Typography component={TrimTextMultiLine} line={2} variant="Paragraph MD/Semi Bold">
          {name}
        </Typography>
        {date && (
          <Typography variant="Paragraph MD/Regular" color={NeutralColors.light_neutrals_600}>
            {date}
          </Typography>
        )}
      </Box>
      <Box component="img" src={image} alt="Награда" sx={styles.image} />
    </Box>
  );
};

export default Reward;
