import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { noData } from 'images';
import { NeutralColors } from 'portfolio3/styles';
import PopUp from 'portfolio3/ui-kit/modals/PopUp';

import { IActiveShareLink } from '../../types';
import ActiveLinksTable from '../ActiveLinksTable';
import * as styles from './styles';

interface IActiveLinksPopUpProps {
  isOpen: boolean;
  onClose: () => void;
  activeLinks: IActiveShareLink[];
}

const ActiveLinksPopUp: FC<IActiveLinksPopUpProps> = ({ isOpen, onClose, activeLinks }) => {
  const hasData = activeLinks.length > 0;

  const noDataPlugElement = (
    <Box sx={styles.noDataPlug}>
      <Box component="img" src={noData} alt="Нет ссылок" sx={{ width: '213px', paddingBlock: '8px' }} />
      <Typography variant="Headings/H5" color={NeutralColors.light_neutrals_600}>
        Вы пока не поделились ни одной ссылкой...
      </Typography>
    </Box>
  );
  const tableElement = <ActiveLinksTable links={activeLinks} />;

  return (
    <PopUp
      size="large"
      open={isOpen}
      onClose={onClose}
      title="Активные ссылки"
      // eslint-disable-next-line max-len
      description="Здесь показаны все активные ссылки на твоё портфолио. Ты можешь отключать доступ по ним в любой момент"
      body={hasData ? tableElement : noDataPlugElement}
      sx={{ height: '100vh' }}
    />
  );
};

export default ActiveLinksPopUp;
