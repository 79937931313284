/* eslint-disable max-len */
import React from 'react';

const IconHonourableMention: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_10155_224845)">
      <path
        d="M16.6111 22.1777L6.10469 44.6072C5.78418 45.2867 6.44444 46.0175 7.14956 45.7739L11.9508 44.1136C13.1752 43.6906 14.5149 44.3188 14.9765 45.5303L16.7906 50.3188C17.0534 51.0047 18.0085 51.0431 18.3226 50.3765L28.8483 27.9085L16.6111 22.1777Z"
        fill="#A9BEFF"
      />
      <path
        d="M32.7391 22.1777L43.2455 44.6072C43.5661 45.2867 42.9058 46.0175 42.2007 45.7739L37.3994 44.1136C36.175 43.6906 34.8353 44.3188 34.3737 45.5303L32.5596 50.3188C32.2968 51.0047 31.3417 51.0431 31.0276 50.3765L20.502 27.9085L32.7391 22.1777Z"
        fill="#A9BEFF"
      />
      <path
        d="M18.8105 4.80007L19.1695 4.26802C22.1695 -0.199926 28.7913 -0.0460802 31.5861 4.55648L32.0092 5.24879L32.9131 5.08854C38.1182 4.14623 42.5925 8.83213 41.4131 13.986L41.1695 15.0501L42.0605 15.6783C46.3874 18.7167 46.2015 25.1975 41.7015 27.9796L40.9195 28.4603L41.0733 29.2616C42.0733 34.5501 37.2592 39.0885 32.0349 37.7808L31.4131 37.627L31.0541 38.159C28.0541 42.627 21.4323 42.4732 18.6374 37.8706L18.2143 37.1783L17.3105 37.3385C12.1054 38.2808 7.63101 33.5949 8.81049 28.4411L9.05408 27.377L8.16306 26.7488C3.83614 23.7103 4.02203 17.2296 8.52203 14.4475L9.30408 13.9667L9.15024 13.1655C8.15024 7.877 12.9643 3.33854 18.1887 4.64623L18.8105 4.80007Z"
        fill="#A9BEFF"
      />
      <path
        d="M40.1692 34.3831C38.5858 37.0306 35.3935 38.6203 32.0281 37.7742L31.4064 37.6203L31.0474 38.1524C28.0474 42.6203 21.4256 42.4665 18.6307 37.8639L18.2076 37.1716L17.3038 37.3319C14.1499 37.9024 11.2653 36.4088 9.74609 33.9985"
        stroke="#748FFC"
        strokeWidth="0.641026"
        strokeMiterlimit="10"
      />
      <path
        d="M19.7717 7.29333C22.4576 3.29974 28.3807 3.43436 30.8807 7.54974L30.9576 7.67795C35.7333 6.81257 39.8359 11.1138 38.7525 15.8446L38.7205 15.9921L38.8423 16.0754C42.8102 18.8638 42.6371 24.8062 38.5089 27.3574L38.5346 27.5049C39.432 32.2356 35.1179 36.2997 30.4448 35.1267C27.7589 39.1203 21.8359 38.9856 19.3359 34.8703L19.2589 34.7421C14.4833 35.6074 10.3807 31.3062 11.4641 26.5754L11.4961 26.428L11.3743 26.3446C7.40636 23.5562 7.57944 17.6138 11.7076 15.0626L11.682 14.9151C10.7846 10.1844 15.0987 6.12026 19.7717 7.29333Z"
        stroke="#748FFC"
        strokeWidth="0.641026"
        strokeMiterlimit="10"
      />
      <path
        d="M24.9633 33.5561C31.7819 33.5561 37.3095 28.0285 37.3095 21.2099C37.3095 14.3913 31.7819 8.86377 24.9633 8.86377C18.1447 8.86377 12.6172 14.3913 12.6172 21.2099C12.6172 28.0285 18.1447 33.5561 24.9633 33.5561Z"
        fill="#748FFC"
      />
      <path
        d="M33.5798 18.9728C33.5798 18.7164 33.3875 18.5561 33.0029 18.4984L27.8042 17.742L25.4773 13.0305C25.3491 12.7484 25.176 12.6074 24.9709 12.6074C24.7657 12.6074 24.5926 12.7484 24.4644 13.0305L22.1311 17.742L16.9324 18.4984C16.5478 18.5626 16.3555 18.7164 16.3555 18.9728C16.3555 19.1202 16.4388 19.2805 16.6119 19.4728L20.3811 23.1395L19.4901 28.319C19.4773 28.4151 19.4709 28.4856 19.4709 28.5241C19.4709 28.6715 19.5093 28.7933 19.5798 28.8895C19.6503 28.992 19.7593 29.0369 19.9067 29.0369C20.0285 29.0369 20.1696 28.9984 20.3234 28.9151L24.9709 26.4728L29.6183 28.9151C29.7657 28.9984 29.9003 29.0369 30.035 29.0369C30.176 29.0369 30.2785 28.9856 30.3491 28.8895C30.4196 28.7869 30.458 28.6651 30.458 28.5241C30.458 28.4343 30.458 28.3638 30.4452 28.319L29.5542 23.1395L33.3106 19.4728C33.4901 19.2933 33.5798 19.1267 33.5798 18.9728Z"
        fill="#A9BEFF"
      />
      <mask
        id="mask0_10155_224845"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="5"
        y="1"
        width="41"
        height="41"
      >
        <path
          d="M18.8105 4.80007L19.1695 4.26802C22.1695 -0.199926 28.7913 -0.0460802 31.5861 4.55648L32.0092 5.24879L32.9131 5.08854C38.1182 4.14623 42.5925 8.83213 41.4131 13.986L41.1695 15.0501L42.0605 15.6783C46.3874 18.7167 46.2015 25.1975 41.7015 27.9796L40.9195 28.4603L41.0733 29.2616C42.0733 34.5501 37.2592 39.0885 32.0349 37.7808L31.4131 37.627L31.0541 38.159C28.0541 42.627 21.4323 42.4732 18.6374 37.8706L18.2143 37.1783L17.3105 37.3385C12.1054 38.2808 7.63101 33.5949 8.81049 28.4411L9.05408 27.377L8.16306 26.7488C3.83614 23.7103 4.02203 17.2296 8.52203 14.4475L9.30408 13.9667L9.15024 13.1655C8.15024 7.877 12.9643 3.33854 18.1887 4.64623L18.8105 4.80007Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_10155_224845)">
        <path
          opacity="0.3"
          d="M9.1899 29.2363C31.8867 29.2363 50.2861 10.8369 50.2861 -11.8599C50.2861 -34.5567 31.8867 -52.9561 9.1899 -52.9561C-13.5069 -52.9561 -31.9062 -34.5567 -31.9062 -11.8599C-31.9062 10.8369 -13.5069 29.2363 9.1899 29.2363Z"
          fill="url(#paint0_linear_10155_224845)"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_10155_224845"
        x1="-5.25881"
        y1="-38.9304"
        x2="26.5617"
        y2="20.6978"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0" />
        <stop offset="0.35" stopColor="white" stopOpacity="0" />
        <stop offset="0.49" stopColor="white" stopOpacity="0.03" />
        <stop offset="0.59" stopColor="white" stopOpacity="0.08" />
        <stop offset="0.67" stopColor="white" stopOpacity="0.15" />
        <stop offset="0.74" stopColor="white" stopOpacity="0.24" />
        <stop offset="0.8" stopColor="white" stopOpacity="0.35" />
        <stop offset="0.85" stopColor="white" stopOpacity="0.47" />
        <stop offset="0.9" stopColor="white" stopOpacity="0.62" />
        <stop offset="0.95" stopColor="white" stopOpacity="0.79" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <clipPath id="clip0_10155_224845">
        <rect width="50" height="50" fill="white" transform="translate(0.111328 0.857422)" />
      </clipPath>
    </defs>
  </svg>
);

export default IconHonourableMention;
