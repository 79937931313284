import { mainApi, proforientationApi } from './impl';
import {
  IGetProforientationRequestParams,
  IProfessionProforientationData,
  IProfessionProforientationResponse,
  IResponsePayload,
} from './types';

export const getProforientation = (guid: string) =>
  proforientationApi.request<
    IGetProforientationRequestParams,
    IProfessionProforientationResponse<IProfessionProforientationData>
  >('Integration/ExportResultInteractions', { guid });

export const getProforientationToken = () =>
  mainApi.get<IResponsePayload<string>>(`/external/proforientation/authentication`);
