import { FC } from 'react';

import { Box, Fade, Modal, ModalProps } from '@mui/material';
import clsx from 'clsx';
import { SxStyles } from 'types';
import { mergeSx } from 'utils';

import * as styles from './ModalBase.styled';

interface IModalBaseProps extends ModalProps {
  contentSx?: SxStyles;
  onExited?: () => void;
}

// Содержит базовые стили для модалки (тени, фон и прочее)
const ModalBase: FC<IModalBaseProps> = ({ children, contentSx, onExited, ...props }) => {
  return (
    <Modal {...props} className={clsx('modal-base', props.className)} sx={mergeSx(styles.root, props.sx)}>
      <Fade in={props.open} onExited={onExited}>
        <Box className="modal-base-content" sx={mergeSx(styles.content, contentSx)}>
          {children}
        </Box>
      </Fade>
    </Modal>
  );
};

export default ModalBase;
