import { useContext } from 'react';
import { useDispatch } from 'react-redux';

import { detailViewDrawerActions } from 'actions';
import { EntityType } from 'const';
import { WidgetDataContext } from 'portfolio3/components/common/WidgetContainer';
import { emitYMEvent } from 'portfolio3/features/yandexMetrika';
import { ICardData } from 'types';

const useDetailViewDrawer = () => {
  const dispatch = useDispatch();
  const { widgetLabel } = useContext(WidgetDataContext);

  const handleOpenPrimaryDetailView = (cardData: ICardData, entityType: EntityType) => {
    dispatch(detailViewDrawerActions.setPrimaryCardData(cardData));
    dispatch(detailViewDrawerActions.setPrimaryOpen(true, widgetLabel));

    emitYMEvent({ type: 'detailsBlockOpening', payload: { Subsections: widgetLabel ?? '' } });

    if (entityType === EntityType.GIA_WORLDSKILLS) {
      emitYMEvent({ type: 'examDetailsOpen' });
    }
  };

  return {
    handleOpenPrimaryDetailView,
  };
};

export default useDetailViewDrawer;
