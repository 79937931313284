import { createSelector } from 'reselect';

import { IRootState } from '../../../reducers';
import { mapScienceOccupationsResponse } from './mappers';

const scienceOccupationsSelector = createSelector([(state: IRootState) => state.studentEmployments], (employments) => {
  return mapScienceOccupationsResponse(employments);
});

export default scienceOccupationsSelector;
