import { ICommonResponse, IDictionaryItem } from 'api/types';
import { AdminParentSectionSettingCodeToSectionRef } from 'const';
import { IRootState } from 'reducers';
import { createSelector } from 'reselect';
import { filterParentSectionRefCodes, sortParentSectionRefCodes } from 'utils';

interface IExtendedSection extends IDictionaryItem {
  sectionRefCode: number;
}

const settingsParentSectionsSelector = createSelector(
  [(state: IRootState) => state.settingSections],
  (sections): ICommonResponse<IExtendedSection> => {
    const mappedSections = sections.content.map((section) => {
      return {
        ...section,
        sectionRefCode: AdminParentSectionSettingCodeToSectionRef[section.code],
      };
    });
    const parentSections = mappedSections.filter((section) => filterParentSectionRefCodes(section.sectionRefCode));
    const sortedSections = parentSections.sort((a, b) => sortParentSectionRefCodes(a.sectionRefCode, b.sectionRefCode));

    return {
      ...sections,
      content: sortedSections,
    };
  },
);

export default settingsParentSectionsSelector;
