export enum VuzRecommendationTab {
  REAL_GIA = 1,
  TRIAL_GIA = 2,
  SUBJECTS = 3,
  FAVORITES = 4,
}

export enum VuzDetailsTab {
  SPECIALITIES = 1,
  FACULTIES = 2,
}

export const MINIMUM_EGE_EXAMS = 3;
export const MINIMUM_FILTER_SUBJECTS_TO_START_INCLUDING_EVERY_SPECIALITY_SUBJECT = 3;

export const RUSSIAN_LANGUAGE_SUBJECT = 'Русский язык';
export const REF_FOREIGN_LANGUAGE_SUBJECT = 'Иностранный язык';
export const FOREIGN_LANGUAGE_SUBJECT_INITIAL_CODE = -1;

export * from './filters';
export * from './militaryTrainingType';
export * from './paginationLimitOptions';
export * from './sortOptions';
export * from './sortType';
export * from './trainingType';
