import { FC } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { Box, useMediaQuery } from '@mui/material';
import { routes } from 'const';
import FixedWidthContainer from 'portfolio3/components/common/FixedWidthContainer';
import { GlobalHeader } from 'portfolio3/components/common/headers';
import { commonTheme } from 'portfolio3/styles/theme';

import { IHeaderContainerCommonProps } from '../types';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IGlobalHeaderContainerProps extends IHeaderContainerCommonProps {}

const GlobalHeaderContainer: FC<IGlobalHeaderContainerProps> = ({ isFullWidth }) => {
  const matchWebviewRoute = useRouteMatch({ path: routes.WEBVIEW.path });
  const isFullWidthBreakpoint = useMediaQuery(commonTheme.breakpoints.down('commonXl'));

  const isWebview = matchWebviewRoute !== null;
  const Container = isFullWidthBreakpoint || isFullWidth ? Box : FixedWidthContainer;

  if (isWebview) return null;

  return (
    <Container className="global-header-container">
      <GlobalHeader />
    </Container>
  );
};

export default GlobalHeaderContainer;
