import { Dispatch, SetStateAction } from 'react';

import { isDefined } from 'utils';

import { IAdminSettingSection, ISectionsVisibility } from '../../../api/types';
import { AdminSectionSettingCodes } from '../../../const';
import { DynamicDisplaySettings, ILinkSetting } from './types';
import { isIndeterminateSetting } from './utils';

export const initialDisplaySettings: ISectionsVisibility = {
  studentData: true,

  profile: true,
  interests: true,
  //recommendations: true,
  // profileGoals: true,

  studies: true,
  diagnostic: true,
  independentDiagnostic: true,
  selfDiagnostic: true,
  performance: true,
  olympiads: true,
  trainingInfo: true,
  practice: true,
  documents: true,
  gia: true,
  oge: true,
  ege: true,
  gve9: true,
  gve11: true,
  other: true,
  trialExam: true,

  science: true,
  scienceReward: true,
  projects: true,
  scienceEmployments: true,
  scienceContest: true,

  sport: true,
  sportReward: true,
  sportClub: true,
  sportUnit: true,
  sportGames: true,
  hike: true,

  creation: true,
  creationReward: true,
  creationClub: true,
  creationContest: true,
  creationUnit: true,

  culture: true,
  offlineVisit: true,
  onlineVisit: true,

  civil: true,
  civilReward: true,
  civilClub: true,
  civilContest: true,
  civilUnit: true,

  profession: true,
  professionRewards: true,
  job: true,
  metaskills: true,
  professionEducation: true,
  professionEvents: true,
  professionWorldskills: true,
  professionCareerGuidance: true,
  professionRecommendations: true,
  professionProfTests: true,
};

interface ILocalSetting {
  name: string;
  isActive: boolean | undefined;
  adminSettingCode?: number;
  linkSettingCode: keyof ISectionsVisibility;
  linkChildCodes?: (keyof ISectionsVisibility)[];
  linkParentCode?: keyof ISectionsVisibility;
}

/**
 * На основе исходного объекта настроек возвращает объект с настройками с применением админских настроек
 * @param adminSettings настройки админа
 * @returns объект с настройками
 */
export const getDefaultRawDisplaySettings = (adminSettings: IAdminSettingSection[]): ISectionsVisibility => {
  const localSettings = getMappedLocalSettings(false, adminSettings, initialDisplaySettings);

  // превращаем стандартные настройки в массив [key, value]
  const initialDisplaySettingsEntries = Object.entries(initialDisplaySettings).map(
    ([_key, value]: [string, boolean]) => {
      const key = _key as keyof typeof initialDisplaySettings;
      return [key, value] as const;
    },
  );

  // фильтрация по наличию ключа из стандартных настроек в настройках админа
  const displaySettingsMappedEntries = initialDisplaySettingsEntries
    .map(([key]) => {
      const localSetting = localSettings.find((setting) => setting.linkSettingCode === key);

      if (localSetting) {
        return [key, localSetting.isActive] as const;
      }

      return null;
    })
    .filter(isDefined);

  // обратная сборка в объект
  const modifiedDisplaySettings = Object.fromEntries(displaySettingsMappedEntries) as unknown as ISectionsVisibility;

  return modifiedDisplaySettings;
};

export const getSharingFormSettings = (
  isSmallScreen: boolean,
  adminSettings: IAdminSettingSection[],
  rawDisplaySettings: DynamicDisplaySettings,
  setRawDisplaySettings: Dispatch<SetStateAction<DynamicDisplaySettings>>,
): ILinkSetting[] => {
  const localSettings = getMappedLocalSettings(isSmallScreen, adminSettings, rawDisplaySettings);

  const linkCodeToSettingMap = new Map<keyof DynamicDisplaySettings, ILocalSetting>();
  localSettings.forEach((setting) => linkCodeToSettingMap.set(setting.linkSettingCode, setting));

  const parentSettings = localSettings.filter((setting) => !setting.linkParentCode);

  const mappedSettings = parentSettings.map((setting) => {
    return mapLocalSettingToSetting(linkCodeToSettingMap, setting, setRawDisplaySettings);
  });
  return mappedSettings;
};

function getMappedLocalSettings(
  isSmallScreen: boolean,
  adminSettings: IAdminSettingSection[],
  displaySettings: DynamicDisplaySettings,
): ILocalSetting[] {
  const localSettings: ILocalSetting[] = [
    // studentData
    {
      name: 'Личные данные',
      isActive: displaySettings.studentData,
      linkSettingCode: 'studentData',
    },
    // profile
    {
      name: 'Обо мне',
      isActive: displaySettings.profile,
      adminSettingCode: AdminSectionSettingCodes.profile,
      linkSettingCode: 'profile',
      linkChildCodes: ['interests'],
    },
    {
      name: 'Анкета интересов',
      isActive: displaySettings.interests,
      adminSettingCode: AdminSectionSettingCodes.interest,
      linkSettingCode: 'interests',
      linkParentCode: 'profile',
    },
    // study
    {
      name: 'Учёба',
      isActive: displaySettings.studies,
      adminSettingCode: AdminSectionSettingCodes.study,
      linkSettingCode: 'studies',
      linkChildCodes: ['trainingInfo', 'practice', 'documents', 'performance', 'gia', 'diagnostic', 'olympiads'],
    },
    // study/training
    {
      name: 'Сведения об обучении',
      isActive: displaySettings.trainingInfo,
      adminSettingCode: AdminSectionSettingCodes.studyTraining,
      linkSettingCode: 'trainingInfo',
      linkParentCode: 'studies',
    },
    // study/practice
    {
      name: 'Практика',
      isActive: displaySettings.practice,
      adminSettingCode: AdminSectionSettingCodes.studyPractice,
      linkSettingCode: 'practice',
      linkParentCode: 'studies',
    },
    // study/documents
    {
      name: 'Документы',
      isActive: displaySettings.documents,
      adminSettingCode: AdminSectionSettingCodes.studyDocuments,
      linkSettingCode: 'documents',
      linkParentCode: 'studies',
    },
    // study/performance
    {
      name: 'Предметные результаты',
      isActive: displaySettings.performance,
      adminSettingCode: AdminSectionSettingCodes.performance,
      linkSettingCode: 'performance',
      linkParentCode: 'studies',
    },
    // study/gia
    {
      name: isSmallScreen ? 'ГИА' : 'Государственная итоговая аттестация',
      isActive: displaySettings.gia,
      adminSettingCode: AdminSectionSettingCodes.gia,
      linkSettingCode: 'gia',
      linkParentCode: 'studies',
      linkChildCodes: ['oge', 'ege', 'gve9', 'gve11', 'other'],
    },
    {
      name: isSmallScreen ? 'ОГЭ' : 'Основной государственный экзамен',
      isActive: displaySettings.oge,
      adminSettingCode: AdminSectionSettingCodes.oge,
      linkSettingCode: 'oge',
      linkParentCode: 'gia',
    },
    {
      name: isSmallScreen ? 'EГЭ' : 'Единый государственный экзамен',
      isActive: displaySettings.ege,
      adminSettingCode: AdminSectionSettingCodes.ege,
      linkSettingCode: 'ege',
      linkParentCode: 'gia',
    },
    {
      name: isSmallScreen ? 'ГВЭ-9' : 'Государственный выпускной экзамен (ГВЭ-9)',
      isActive: displaySettings.gve9,
      adminSettingCode: AdminSectionSettingCodes.gve9,
      linkSettingCode: 'gve9',
      linkParentCode: 'gia',
    },
    {
      name: isSmallScreen ? 'ГВЭ-11' : 'Государственный выпускной экзамен (ГВЭ-11)',
      isActive: displaySettings.gve11,
      adminSettingCode: AdminSectionSettingCodes.gve11,
      linkSettingCode: 'gve11',
      linkParentCode: 'gia',
    },
    {
      name: 'Другое',
      isActive: displaySettings.other,
      adminSettingCode: AdminSectionSettingCodes.other,
      linkSettingCode: 'other',
      linkParentCode: 'gia',
    },
    // study/diagnostics
    {
      name: 'Диагностики',
      isActive: displaySettings.diagnostic,
      adminSettingCode: AdminSectionSettingCodes.diagnosis,
      linkSettingCode: 'diagnostic',
      linkParentCode: 'studies',
      linkChildCodes: ['independentDiagnostic', 'selfDiagnostic'],
    },
    {
      name: 'Независимые диагностики',
      isActive: displaySettings.independentDiagnostic,
      adminSettingCode: AdminSectionSettingCodes.diagnosisIndependent,
      linkSettingCode: 'independentDiagnostic',
      linkParentCode: 'diagnostic',
    },
    {
      name: 'Самодиагностики',
      isActive: displaySettings.selfDiagnostic,
      adminSettingCode: AdminSectionSettingCodes.diagnosisSelf,
      linkSettingCode: 'selfDiagnostic',
      linkParentCode: 'diagnostic',
    },
    // study/olympiad
    {
      name: 'Олимпиады',
      isActive: displaySettings.olympiads,
      adminSettingCode: AdminSectionSettingCodes.olympiad,
      linkSettingCode: 'olympiads',
      linkParentCode: 'studies',
    },
    // science
    {
      name: 'Наука',
      isActive: displaySettings.science,
      adminSettingCode: AdminSectionSettingCodes.science,
      linkSettingCode: 'science',
      linkChildCodes: ['scienceReward', 'projects', 'scienceEmployments', 'scienceContest'],
    },
    {
      name: 'Награды',
      isActive: displaySettings.scienceReward,
      adminSettingCode: AdminSectionSettingCodes.reward,
      linkSettingCode: 'scienceReward',
      linkParentCode: 'science',
    },
    {
      name: 'Проекты',
      isActive: displaySettings.projects,
      adminSettingCode: AdminSectionSettingCodes.project,
      linkSettingCode: 'projects',
      linkParentCode: 'science',
    },
    {
      name: 'Занятия',
      isActive: displaySettings.scienceEmployments,
      adminSettingCode: AdminSectionSettingCodes.employment,
      linkSettingCode: 'scienceEmployments',
      linkParentCode: 'science',
    },
    {
      name: 'Конкурсы, конфренции',
      isActive: displaySettings.scienceContest,
      adminSettingCode: AdminSectionSettingCodes.contestConference,
      linkSettingCode: 'scienceContest',
      linkParentCode: 'science',
    },
    // sport
    {
      name: 'Спорт',
      isActive: displaySettings.sport,
      adminSettingCode: AdminSectionSettingCodes.sport,
      linkSettingCode: 'sport',
      linkChildCodes: ['sportReward', 'sportClub', 'sportUnit', 'sportGames', 'hike'],
    },
    {
      name: 'Награды и разряды',
      isActive: displaySettings.sportReward,
      adminSettingCode: AdminSectionSettingCodes.sportReward,
      linkSettingCode: 'sportReward',
      linkParentCode: 'sport',
    },
    {
      name: 'Клубы и команды',
      isActive: displaySettings.sportClub,
      adminSettingCode: AdminSectionSettingCodes.clubTeam,
      linkSettingCode: 'sportClub',
      linkParentCode: 'sport',
    },
    {
      name: 'Кружки и секции',
      isActive: displaySettings.sportUnit,
      adminSettingCode: AdminSectionSettingCodes.circleSection,
      linkSettingCode: 'sportUnit',
      linkParentCode: 'sport',
    },
    {
      name: 'Соревнования',
      isActive: displaySettings.sportGames,
      adminSettingCode: AdminSectionSettingCodes.competition,
      linkSettingCode: 'sportGames',
      linkParentCode: 'sport',
    },
    {
      name: 'Походы и экспедиции',
      isActive: displaySettings.hike,
      adminSettingCode: AdminSectionSettingCodes.expedition,
      linkSettingCode: 'hike',
      linkParentCode: 'sport',
    },
    // creation
    {
      name: 'Творчество',
      isActive: displaySettings.creation,
      adminSettingCode: AdminSectionSettingCodes.creation,
      linkSettingCode: 'creation',
      linkChildCodes: ['creationReward', 'creationClub', 'creationContest', 'creationUnit'],
    },
    {
      name: 'Награды и достижения',
      isActive: displaySettings.creationReward,
      adminSettingCode: AdminSectionSettingCodes.rewardAchievement,
      linkSettingCode: 'creationReward',
      linkParentCode: 'creation',
    },
    {
      name: 'Творческие коллективы',
      isActive: displaySettings.creationClub,
      adminSettingCode: AdminSectionSettingCodes.creationCollective,
      linkSettingCode: 'creationClub',
      linkParentCode: 'creation',
    },
    {
      name: 'Конкурсы',
      isActive: displaySettings.creationContest,
      adminSettingCode: AdminSectionSettingCodes.contest,
      linkSettingCode: 'creationContest',
      linkParentCode: 'creation',
    },
    {
      name: 'Кружки',
      isActive: displaySettings.creationUnit,
      adminSettingCode: AdminSectionSettingCodes.circle,
      linkSettingCode: 'creationUnit',
      linkParentCode: 'creation',
    },
    // culture
    {
      name: 'Культура',
      isActive: displaySettings.culture,
      adminSettingCode: AdminSectionSettingCodes.culture,
      linkSettingCode: 'culture',
      linkChildCodes: ['offlineVisit', 'onlineVisit'],
    },
    {
      name: 'Посещение учреждений',
      isActive: displaySettings.offlineVisit,
      adminSettingCode: AdminSectionSettingCodes.cultureVisiting,
      linkSettingCode: 'offlineVisit',
      linkParentCode: 'culture',
    },
    {
      name: 'Онлайн-мероприятия',
      isActive: displaySettings.onlineVisit,
      adminSettingCode: AdminSectionSettingCodes.onlineVisiting,
      linkSettingCode: 'onlineVisit',
      linkParentCode: 'culture',
    },
    // civil
    {
      name: 'Гражданская активность',
      isActive: displaySettings.civil,
      adminSettingCode: AdminSectionSettingCodes.civil,
      linkSettingCode: 'civil',
      linkChildCodes: ['civilReward', 'civilClub', 'civilContest', 'civilUnit'],
    },
    {
      name: 'Награды и статусы',
      isActive: displaySettings.civilReward,
      adminSettingCode: AdminSectionSettingCodes.rewardStatus,
      linkSettingCode: 'civilReward',
      linkParentCode: 'civil',
    },
    {
      name: 'Клубы',
      isActive: displaySettings.civilClub,
      adminSettingCode: AdminSectionSettingCodes.club,
      linkSettingCode: 'civilClub',
      linkParentCode: 'civil',
    },
    {
      name: 'Конкурсы, соревнования',
      isActive: displaySettings.civilContest,
      adminSettingCode: AdminSectionSettingCodes.contestCompetition,
      linkSettingCode: 'civilContest',
      linkParentCode: 'civil',
    },
    {
      name: 'Кружки',
      isActive: displaySettings.civilUnit,
      adminSettingCode: AdminSectionSettingCodes.civilCircle,
      linkSettingCode: 'civilUnit',
      linkParentCode: 'civil',
    },
    // profession
    {
      name: 'Моя профессия',
      isActive: displaySettings.profession,
      adminSettingCode: AdminSectionSettingCodes.profession,
      linkSettingCode: 'profession',
      linkChildCodes: [
        'professionCareerGuidance',
        'professionRecommendations',
        'professionProfTests',
        'professionRewards',
        'job',
        'metaskills',
        'professionEducation',
        'professionEvents',
        'professionWorldskills',
      ],
    },
    {
      name: 'Профориентация',
      isActive: displaySettings.professionCareerGuidance,
      adminSettingCode: AdminSectionSettingCodes.professionProforientation,
      linkSettingCode: 'professionCareerGuidance',
      linkParentCode: 'profession',
    },
    {
      name: 'Рекомендации',
      isActive: displaySettings.professionRecommendations,
      adminSettingCode: AdminSectionSettingCodes.professionProforientationRecommendations,
      linkSettingCode: 'professionRecommendations',
      linkParentCode: 'profession',
    },
    {
      name: 'Профессиональные пробы',
      isActive: displaySettings.professionProfTests,
      adminSettingCode: AdminSectionSettingCodes.professionProforientationTests,
      linkSettingCode: 'professionProfTests',
      linkParentCode: 'profession',
    },
    {
      name: 'Награды',
      isActive: displaySettings.professionRewards,
      adminSettingCode: AdminSectionSettingCodes.professionReward,
      linkSettingCode: 'professionRewards',
      linkParentCode: 'profession',
    },
    {
      name: 'Трудоустройство',
      isActive: displaySettings.job,
      adminSettingCode: AdminSectionSettingCodes.professionJob,
      linkSettingCode: 'job',
      linkParentCode: 'profession',
    },
    {
      name: 'Метанавыки',
      isActive: displaySettings.metaskills,
      adminSettingCode: AdminSectionSettingCodes.professionMetaskills,
      linkSettingCode: 'metaskills',
      linkParentCode: 'profession',
    },
    {
      name: 'Профессиональное обучение',
      isActive: displaySettings.professionEducation,
      adminSettingCode: AdminSectionSettingCodes.professionEducation,
      linkSettingCode: 'professionEducation',
      linkParentCode: 'profession',
    },
    {
      name: 'Мероприятия',
      isActive: displaySettings.professionEvents,
      adminSettingCode: AdminSectionSettingCodes.professionEvents,
      linkSettingCode: 'professionEvents',
      linkParentCode: 'profession',
    },
    {
      name: 'Демонстрационные экзамены',
      isActive: displaySettings.professionWorldskills,
      adminSettingCode: AdminSectionSettingCodes.professionExamGia,
      linkSettingCode: 'professionWorldskills',
      linkParentCode: 'profession',
    },
  ];

  // оставляем разделы, которые включены админом
  const filteredLocalSettings = localSettings.filter((setting) => {
    const adminSetting = adminSettings.find((adminSetting) => adminSetting.sectionId === setting.adminSettingCode);
    const isDefaultVisible = setting.linkSettingCode === 'studentData';
    return adminSetting?.isVisible ?? isDefaultVisible;
  });

  return filteredLocalSettings;
}

function mapLocalSettingToSetting(
  mappedSettings: Map<keyof DynamicDisplaySettings, ILocalSetting>,
  setting: ILocalSetting,
  setDisplaySettings: Dispatch<SetStateAction<DynamicDisplaySettings>>,
): ILinkSetting {
  const { name, isActive, adminSettingCode } = setting;

  const childSettings = getChildSettings(mappedSettings, setting, setDisplaySettings);

  return {
    name,
    isActive: isActive ?? false,
    isIndeterminate: childSettings ? isIndeterminateSetting(childSettings) : false,
    sectionCode: adminSettingCode,
    toggle() {
      setDisplaySettings((prevState) => {
        const settingKey = setting.linkSettingCode;
        const settingState = prevState[settingKey];
        const newSettingValue = !settingState;

        let newSettings = { ...prevState };
        newSettings[settingKey] = newSettingValue;
        newSettings = toggleChildSettings(mappedSettings, setting, newSettingValue, newSettings);
        newSettings = toggleParentSetting(mappedSettings, setting, newSettings);

        // фильтрация лишних разделов по настройкам админа после переключения
        const filteredEntries = Object.entries(newSettings).filter(([key]) => {
          return mappedSettings.has(key as keyof typeof newSettings);
        });
        const filteredSettings = Object.fromEntries(filteredEntries) as Record<keyof typeof newSettings, boolean>;

        return filteredSettings;
      });
    },
    childSettings,
  };
}

function getChildSettings(
  mappedSettings: Map<keyof DynamicDisplaySettings, ILocalSetting>,
  setting: ILocalSetting,
  setDisplaySettings: Dispatch<SetStateAction<DynamicDisplaySettings>>,
) {
  if (!setting.linkChildCodes) return undefined;

  const childSettings = setting.linkChildCodes
    .map((code) => {
      const childSetting = mappedSettings.get(code);
      return childSetting ?? null;
    })
    .filter(isDefined);

  return childSettings.map((setting) => mapLocalSettingToSetting(mappedSettings, setting, setDisplaySettings));
}

function toggleParentSetting(
  mappedSettings: Map<keyof DynamicDisplaySettings, ILocalSetting>,
  setting: ILocalSetting,
  displaySettings: DynamicDisplaySettings,
): DynamicDisplaySettings {
  let newSettings = { ...displaySettings };

  if (!setting.linkParentCode) return newSettings;

  const parentSetting = mappedSettings.get(setting.linkParentCode);

  if (!parentSetting) return newSettings;

  const isSomeChildsVisible =
    parentSetting.linkChildCodes?.some((linkChildCode) => {
      return newSettings[linkChildCode];
    }) ?? false;

  const parentSettingCode = parentSetting.linkSettingCode;
  newSettings[parentSettingCode] = isSomeChildsVisible;

  if (parentSetting.linkParentCode) {
    newSettings = toggleParentSetting(mappedSettings, parentSetting, newSettings);
  }

  return newSettings;
}

function toggleChildSettings(
  mappedSettings: Map<keyof DynamicDisplaySettings, ILocalSetting>,
  setting: ILocalSetting,
  newSettingValue: boolean,
  displaySettings: DynamicDisplaySettings,
): DynamicDisplaySettings {
  let newSettings = { ...displaySettings };
  if (!setting.linkChildCodes) return newSettings;

  setting.linkChildCodes.forEach((childLinkCode) => {
    newSettings[childLinkCode] = newSettingValue;

    const childSetting = mappedSettings.get(childLinkCode);
    if (childSetting?.linkChildCodes) {
      newSettings = toggleChildSettings(mappedSettings, childSetting, newSettingValue, newSettings);
    }
  });

  return newSettings;
}
