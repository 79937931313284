import { createSelector } from 'reselect';

import { IRootState } from '../../../reducers';
import { mapCultureOnlineEventsResponse } from './mappers';

const cultureOnlineEventsSelector = createSelector(
  [(state: IRootState) => state.studentEvents, (state: IRootState) => state.sectionRef],
  (studentEvents, sectionRef) => {
    return mapCultureOnlineEventsResponse(studentEvents, sectionRef);
  },
);

export default cultureOnlineEventsSelector;
