import React, { forwardRef } from 'react';

import { Box, BoxProps } from '@mui/material';
import clsx from 'clsx';

import './index.scss';

const FunnelBlock: React.ForwardRefRenderFunction<unknown, BoxProps> = ({ children, className, ...props }, ref) => (
  <Box className={clsx('funnel-block', className)} {...props} ref={ref}>
    {children}
  </Box>
);

export default forwardRef<unknown, BoxProps>(FunnelBlock);
