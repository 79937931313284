/* eslint-disable prettier/prettier */
/* eslint-disable */

export const getAge = (date: string): number => {
  let today: Date = new Date();
  let birthDate: Date = new Date(date);
  let age: number = today.getFullYear() - birthDate.getFullYear();

  let m: number = today.getMonth() - birthDate.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
  }

  return age;
};
