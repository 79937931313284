import { AdminSectionSettingCodes, StudentSectionSettingTypeCodes } from 'const';
import { IVisibilityChildSetting } from 'containers/admin/VisibilitySettings/types';
import { IViewVisibilitySetting } from 'types';

const diagnosticIndependentNestedSections = [
  StudentSectionSettingTypeCodes.diagnosticIndependentList,
  StudentSectionSettingTypeCodes.diagnosticIndependentRating,
];

export const syncIndependentDiagnosticSettings = (
  isVisible: boolean,
  sectionTypeCode: string,
  settingSections: IViewVisibilitySetting[],
): IViewVisibilitySetting[] => {
  return settingSections.map((parentItem: IViewVisibilitySetting) => {
    if (parentItem.sectionTypeCode === StudentSectionSettingTypeCodes.study) {
      return {
        ...parentItem,
        subSections: parentItem.subSections?.map((studyItem: IViewVisibilitySetting) => {
          if (studyItem.sectionTypeCode === StudentSectionSettingTypeCodes.studyDiagnostic) {
            return {
              ...studyItem,
              subSections: studyItem.subSections
                ? getDiagnosticSubSections(isVisible, sectionTypeCode, studyItem.subSections)
                : undefined,
            };
          }

          return studyItem;
        }),
      };
    }

    return parentItem;
  });
};

function getDiagnosticSubSections(
  isVisible: boolean,
  sectionTypeCode: string,
  studySections: IViewVisibilitySetting[],
) {
  //если включили/выключили родительскую настройку, то аналогичное действие выполняем со вложенными
  if (sectionTypeCode === StudentSectionSettingTypeCodes.diagnosticIndependent) {
    return studySections?.map((diagnosticItem: IViewVisibilitySetting) => {
      if (
        sectionTypeCode === StudentSectionSettingTypeCodes.diagnosticIndependent &&
        diagnosticIndependentNestedSections.includes(diagnosticItem.sectionTypeCode)
      ) {
        return { ...diagnosticItem, computedVisibility: isVisible };
      }

      return diagnosticItem;
    });
  }

  //если кликнули по вложенной настройке
  if (diagnosticIndependentNestedSections.includes(sectionTypeCode)) {
    //если включили активный вложенный раздел, то включаем родитескую настройку
    if (isVisible) {
      return studySections?.map((diagnosticItem: IViewVisibilitySetting) => {
        if (diagnosticItem.sectionTypeCode === StudentSectionSettingTypeCodes.diagnosticIndependent) {
          return { ...diagnosticItem, computedVisibility: true };
        }

        return diagnosticItem;
      });
    } else {
      //поиск вложенного включенного раздела
      const visibleNestedSection = studySections.find(
        (item) => diagnosticIndependentNestedSections.includes(item.sectionTypeCode) && item.computedVisibility,
      );

      //если ни 1 включенный раздел не найден, отключить родительский
      if (!visibleNestedSection) {
        return studySections?.map((diagnosticItem: IViewVisibilitySetting) => {
          if (diagnosticItem.sectionTypeCode === StudentSectionSettingTypeCodes.diagnosticIndependent) {
            return { ...diagnosticItem, computedVisibility: false };
          }

          return diagnosticItem;
        });
      }
    }
  }

  return studySections;
}

export const syncIndependentDiagnosticAdminSettings = (
  sectionCode: number,
  isActive: boolean,
  parentChildren: IVisibilityChildSetting[],
): IVisibilityChildSetting[] | [] => {
  if (parentChildren && sectionCode === AdminSectionSettingCodes.diagnosisIndependentRating) {
    return setDiagnosisIndependentVisibilityDependingOnNesting(
      AdminSectionSettingCodes.diagnosisIndependentList,
      !isActive,
      parentChildren,
    );
  }

  if (parentChildren && sectionCode === AdminSectionSettingCodes.diagnosisIndependentList) {
    return setDiagnosisIndependentVisibilityDependingOnNesting(
      AdminSectionSettingCodes.diagnosisIndependentRating,
      !isActive,
      parentChildren,
    );
  }

  if (parentChildren && sectionCode === AdminSectionSettingCodes.diagnosisIndependent) {
    return parentChildren.map((item) =>
      item.sectionCode === AdminSectionSettingCodes.diagnosisIndependentList ||
      item.sectionCode === AdminSectionSettingCodes.diagnosisIndependentRating
        ? { ...item, isActive: !isActive }
        : item,
    );
  }

  return parentChildren;
};

function setDiagnosisIndependentVisibilityDependingOnNesting(
  checkingElementCode: number,
  isActive: boolean,
  parentChildren: IVisibilityChildSetting[],
) {
  if (!isActive) {
    const checkingElement = parentChildren.find((item) => item.sectionCode === checkingElementCode);

    if (!checkingElement?.isActive) {
      return parentChildren.map((item) =>
        item.sectionCode === AdminSectionSettingCodes.diagnosisIndependent ? { ...item, isActive: false } : item,
      );
    }
  } else {
    return parentChildren.map((item) =>
      item.sectionCode === AdminSectionSettingCodes.diagnosisIndependent ? { ...item, isActive: true } : item,
    );
  }

  return parentChildren;
}
