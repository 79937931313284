import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { useUserFunctionality } from 'hooks';
import { NeutralColors } from 'portfolio3/styles';
import { PersonsListState } from 'reducers/employee/persons';

import SearchResultStudent from '../../components/SearchResultStudent';
import { useAdminStudentResult } from './useAdminStudentResult';
import { useOperatorStudentResult } from './useOperatorStudentResult';

interface ISearchResultStudentContainerProps {
  personsList: PersonsListState;
}

const SearchResultStudentContainer: FC<ISearchResultStudentContainerProps> = ({ personsList }) => {
  const { isAdmin } = useUserFunctionality();

  const studentsLength = personsList.content?.student.length ?? 0;
  const foundStudent = personsList.content?.student[0];

  const { operatorRightElement, operatorDrawerElement } = useOperatorStudentResult(foundStudent);
  const { adminRightElement } = useAdminStudentResult(foundStudent);

  let specialCaseText: string | null = null;

  if (studentsLength === 0) {
    specialCaseText = 'По вашему запросу ничего не найдено. Ослабьте условия поиска и попробуйте еще раз';
  }
  if (studentsLength > 1) {
    specialCaseText = 'По вашему запросу найдено более одного учащегося. Пожалуйста, конкретизируйте запрос';
  }

  const titleElement = (
    <Typography variant="Paragraph LG/Semi Bold" marginBottom="12px">
      Результаты поиска
    </Typography>
  );

  if (specialCaseText || !foundStudent) {
    return (
      <Box>
        {titleElement}
        <Typography variant="Paragraph LG/Medium" color={NeutralColors.light_neutrals_600}>
          {specialCaseText}
        </Typography>
      </Box>
    );
  }

  const rightElement = isAdmin ? adminRightElement : operatorRightElement;

  const { firstname, lastname, patronymic, grade } = foundStudent;
  const name = [lastname, firstname, patronymic].join(' ');
  const parallelName = grade?.name ? `${grade.name} класс` : '';

  return (
    <Box className="search-result-student-container">
      {titleElement}
      <SearchResultStudent name={name} parallel={parallelName} rightElement={rightElement} />
      {!isAdmin && operatorDrawerElement}
    </Box>
  );
};

export default SearchResultStudentContainer;
