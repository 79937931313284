import { FC } from 'react';

import { Fade, Tooltip as TooltipMaterial, tooltipClasses, TooltipProps } from '@mui/material';
import { SxStyles } from 'types';
import { mergeSx } from 'utils';

const styles: SxStyles = {
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '8px 12px',

    borderRadius: '8px',
    backgroundColor: 'rgba(13, 13, 16, 0.8)',

    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 500,
    textAlign: 'center',
  },

  [`& .${tooltipClasses.arrow}`]: {
    color: 'rgba(13, 13, 16, 0.8)',
  },
};

interface ITooltipProps extends TooltipProps {
  width?: string;
}

const Tooltip: FC<ITooltipProps> = ({ width, sx, ...props }) => {
  const dynamicStyles: SxStyles = {
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: width ?? '492px',
    },
  };

  return (
    <TooltipMaterial
      enterTouchDelay={10}
      {...props}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
      componentsProps={{
        popper: {
          sx: mergeSx(styles, dynamicStyles, sx),
        },
      }}
    />
  );
};

export default Tooltip;
