import { FC } from 'react';

import { Box } from '@mui/material';
import { IDictionaryItem } from 'api/types';
import ToggleOutline from 'portfolio3/ui-kit/toggles/ToggleOutline';
import { SxStyles } from 'types';

interface ISubjectsListControlProps {
  options: IDictionaryItem[];
  selectedCodes: number[];
  onToggle: (code: number) => void;
  disabled?: boolean;
}

const root: SxStyles = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '8px 12px',
};

const SubjectsListControl: FC<ISubjectsListControlProps> = ({ options, selectedCodes, onToggle, disabled }) => {
  return (
    <Box className="subjects-list-control" sx={root}>
      {options.map((option) => {
        const { code, value } = option;
        const isEnabled = selectedCodes.includes(code);
        return (
          <ToggleOutline
            key={option.code}
            size="small"
            label={value}
            checked={isEnabled}
            onChange={() => onToggle(code)}
            disabled={disabled}
          />
        );
      })}
    </Box>
  );
};

export default SubjectsListControl;
