import { createSelector } from 'reselect';

import { IRootState } from '../../../reducers';
import { mapCreationClubsResponse } from './mappers';

const creationClubsSelector = createSelector([(state: IRootState) => state.studentAffilations], (affiliations) => {
  return mapCreationClubsResponse(affiliations);
});

export default creationClubsSelector;
