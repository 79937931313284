import { FC, useRef } from 'react';
import { AriaCalendarCellProps, mergeProps, useCalendarCell } from 'react-aria';
import { CalendarState, RangeCalendarState } from 'react-stately';

import { Typography } from '@mui/material';
import { getAccentColor, NeutralColors } from 'portfolio3/styles';
import { getButtonStyles } from 'portfolio3/ui-kit/utils';
import { SxStyles } from 'types';
import { mergeSx } from 'utils';

const styles = (isSelected: boolean): SxStyles => {
  return {
    ...getButtonStyles(),

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    width: '60px',
    height: '36px',
    padding: '8px',

    border: '1px solid',
    borderColor: NeutralColors.light_neutrals_300,
    borderRadius: '8px',

    transition: 'backgroundColor 0.1',

    '&:hover': {
      border: isSelected ? undefined : 'none',
      backgroundColor: isSelected ? undefined : getAccentColor('indigo', '8'),
    },
  };
};

const selected: SxStyles = {
  border: 'none',
  backgroundColor: getAccentColor('indigo', '100'),
  color: NeutralColors.light_neutrals_0,
};

interface IYearCellProps {
  cellOptions: AriaCalendarCellProps;
  calendarState: CalendarState | RangeCalendarState;
}

const YearCell: FC<IYearCellProps> = ({ cellOptions, calendarState }) => {
  const ref = useRef(null);
  const { cellProps, buttonProps, isSelected } = useCalendarCell(cellOptions, calendarState, ref);

  const text = cellOptions.date.year;

  return (
    <Typography
      variant="Paragraph MD/Regular"
      className="year-cell"
      component="button"
      {...mergeProps(cellProps, buttonProps)}
      ref={ref}
      sx={mergeSx(styles(isSelected), isSelected ? selected : null)}
    >
      {text}
    </Typography>
  );
};

export default YearCell;
