import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root: SxStyles = {
  display: 'flex',
  alignItems: 'center',
};

export const logoContainer: SxStyles = {
  display: 'flex',
  alignItems: 'center',
};

export const logoImage: SxStyles = {
  height: '40px',
  width: '40px',
};

export const content: SxStyles = {
  display: 'flex',
  marginLeft: '-4px',
  alignItems: 'center',
  gap: '12px',
};

export const hideTitleOnSmallScreen: SxStyles = (theme) => {
  return {
    [theme.breakpoints.down('commonSm')]: {
      display: 'none',
    },
  };
};

export const nameContainer: SxStyles = {
  display: 'grid',
  gridAutoFlow: 'column',
  gap: '8px',

  '& > *': {
    alignSelf: 'flex-start',
  },
};

export const name: SxStyles = (theme) => {
  return {
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '24px',
    color: NeutralColors.light_neutrals_800,

    [theme.breakpoints.down('commonMd')]: {
      fontSize: '16px',
    },
  };
};
