import { useSelector } from 'react-redux';

import { SectionCodes } from 'const';
import {
  IEntryFormEntityProperties,
  IPortfolioEntryFormData,
  MappedEventData,
} from 'portfolio3/features/dataEntryForm';
import { IRootState } from 'reducers';
import { createSelector } from 'reselect';
import { getCategoryProperties, isDefined } from 'utils';

import { getCommonEventProperties } from './utils';

interface IProps {
  formData: IPortfolioEntryFormData;
  mappedEventData: MappedEventData;
}

const olympiadRewardSelector = createSelector([(state: IRootState) => state.rewardKinds], (rewardKinds) => {
  return rewardKinds.content.filter((rewardKind) => rewardKind.categoryCode === SectionCodes.eventOlympiad);
});

const useStudyEntityProperties = ({ formData, mappedEventData }: IProps): IEntryFormEntityProperties | null => {
  const portfolioSections = useSelector((state: IRootState) => state.sectionRef.content);
  const olympiadRewardKinds = useSelector((state: IRootState) => olympiadRewardSelector(state));
  const documentRef = useSelector((state: IRootState) => state.documentRef.content);

  const categoryCode = formData.categoryCode;
  const dataKind = formData.dataKind;
  const typeCode = formData.typeCode;

  const categoryProperties = categoryCode ? getCategoryProperties(categoryCode) : null;

  let name: string | undefined;
  let typeName: string | undefined;
  let attributes: string[] | undefined;

  if (dataKind === SectionCodes.studyEvent) {
    const props = getCommonEventProperties(mappedEventData);
    name = props.name ?? 'Другое';
    typeName = props.typeName;
    attributes = props.attributes;
  }

  if (dataKind === SectionCodes.studyReward) {
    const rewardName = olympiadRewardKinds.find((reward) => reward.code === formData.reward)?.value;
    const rewardType = portfolioSections.find((section) => section.code === formData.typeCode)?.value;

    name = rewardName;
    typeName = 'Награда';
    attributes = [rewardType].filter(isDefined);
  }

  if (typeCode === SectionCodes.studySpoDocuments) {
    name = documentRef.find((ref) => ref.code === formData.studySpoDocument?.documentTypeCode)?.value;
  }

  if (categoryCode !== SectionCodes.study) return null;

  return {
    entityInfo: {
      name,
      categoryName: categoryProperties?.name ?? '',
      typeName: typeName ?? '',
      attributes,
    },
  };
};

export default useStudyEntityProperties;
