import { FC } from 'react';

import { Box } from '@mui/material';
import { getAccentColor } from 'portfolio3/styles';
import { SxStyles } from 'types';

const styles = (isSelected: boolean): SxStyles => {
  const backgroundColor = isSelected ? getAccentColor('indigo', '24') : undefined;

  return {
    position: 'relative',
    backgroundColor,

    '&::before, &::after': {
      content: '""',

      position: 'absolute',
      width: '100%',
      height: '100%',

      backgroundColor,
    },

    '&::before': {
      left: '100%',
    },

    '&::after': {
      right: '100%',
    },
  };
};

interface IDayCellConnectorProps {
  isSelected?: boolean;
}

const DayCellConnector: FC<IDayCellConnectorProps> = ({ isSelected }) => {
  return <Box className="day-cell-connector" sx={styles(isSelected ?? false)} />;
};

export default DayCellConnector;
