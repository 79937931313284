import { ComponentType, FC, PropsWithChildren } from 'react';
import { connect } from 'react-redux';

import { setLocalVisibilitySettingsAction } from 'actions';
import { ILocalVisibilitySettingsList } from 'api/types';
import { PortfolioSettingsContext } from 'context';
import { IViewVisibilitySetting } from 'types';
import { LocalVisibilitySettingsService, syncIndependentDiagnosticSettings } from 'utils';

interface IWithSettingsContextContainerProps {
  setLocalVisibilitySettings: typeof setLocalVisibilitySettingsAction;
}

const WithSettingsContextContainer: FC<IWithSettingsContextContainerProps & PropsWithChildren> = ({
  setLocalVisibilitySettings,
  children,
}) => {
  const setVisibility = (prevSettings: IViewVisibilitySetting[], isVisible: boolean, ...typeCodes: string[]) => {
    const constructor = new LocalVisibilitySettingsService(prevSettings);
    constructor.setSettingVisibility(isVisible, ...typeCodes);

    const { result } = constructor;
    const settingsWithSyncDiagnostic = syncIndependentDiagnosticSettings(
      isVisible,
      typeCodes[typeCodes.length - 1],
      result,
    );

    const localVisibilityList: ILocalVisibilitySettingsList = {
      sections: settingsWithSyncDiagnostic,
    };

    setLocalVisibilitySettings(localVisibilityList);
  };

  const handleSetSectionVisibility = (
    prevSettings: IViewVisibilitySetting[],
    sectionTypeCode: string,
    visible: boolean,
  ) => {
    setVisibility(prevSettings, visible, sectionTypeCode);
  };

  const handleSetSubsectionVisibility = (
    prevSettings: IViewVisibilitySetting[],
    sectionTypeCode: string,
    subsectionTypeCode: string,
    visible: boolean,
  ) => {
    setVisibility(prevSettings, visible, sectionTypeCode, subsectionTypeCode);
  };

  const handleSetSubcategoryVisibility = (
    prevSettings: IViewVisibilitySetting[],
    sectionTypeCode: string,
    subsectionTypeCode: string,
    subcategoryTypeCode: string,
    visible: boolean,
  ) => {
    setVisibility(prevSettings, visible, sectionTypeCode, subsectionTypeCode, subcategoryTypeCode);
  };

  return (
    <PortfolioSettingsContext.Provider
      value={{
        isSettingsMode: true,
        setSectionVisibility: handleSetSectionVisibility,
        setSubsectionVisibility: handleSetSubsectionVisibility,
        setSubcategoryVisibility: handleSetSubcategoryVisibility,
      }}
    >
      {children}
    </PortfolioSettingsContext.Provider>
  );
};

const WidthSettingsContainerRedux = connect(null, {
  setLocalVisibilitySettings: setLocalVisibilitySettingsAction,
})(WithSettingsContextContainer);

const withSettingsContext = () => {
  return <P extends PropsWithChildren<unknown>>(WrappedComponent: ComponentType<P>): FC<P> => {
    return function WithHeader({ ...props }) {
      return (
        <WidthSettingsContainerRedux>
          <WrappedComponent {...props} />
        </WidthSettingsContainerRedux>
      );
    };
  };
};

export default withSettingsContext;
