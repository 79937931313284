import { ICommonResponseObject, IDictionaryItem } from 'api/types';
import { IRootState } from 'reducers';
import { createSelector } from 'reselect';

import { filterTrialGiaExam } from '../../utils';
import recommendationGiaList from './recommendationGiaList';
import { getSubjectsForExams } from './utils';

type TrialExamsResponseObject = ICommonResponseObject<IDictionaryItem[]> & {
  isCached: boolean;
};

const trialExamSubjectsSelector = createSelector(
  [(state: IRootState) => recommendationGiaList(state), (state: IRootState) => state.egeExamSubjectsRef],
  (studentGiaList, egeExamSubjectsRef): TrialExamsResponseObject => {
    const trialExams = studentGiaList.content.filter(filterTrialGiaExam);
    const trialExamSubjects = getSubjectsForExams(egeExamSubjectsRef.content, trialExams);

    return {
      content: trialExamSubjects,
      loading: studentGiaList.loading || egeExamSubjectsRef.loading,
      isCached:
        studentGiaList.requestCache !== undefined &&
        egeExamSubjectsRef.requestCache !== undefined &&
        studentGiaList.requestCache !== 'notCached' &&
        egeExamSubjectsRef.requestCache !== 'notCached',
    };
  },
);

export default trialExamSubjectsSelector;
