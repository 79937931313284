import { FC } from 'react';

import { Box } from '@mui/material';
import { Colors } from 'style/variables';

const MenuEmptyResult: FC = () => {
  return (
    <Box
      sx={{
        width: '100%',
        textAlign: 'center',
        color: Colors['note-text'],
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 400,
      }}
    >
      Ничего не найдено
    </Box>
  );
};

export default MenuEmptyResult;
