import { SxProps } from '@mui/material';

/**
 * данные стили дублируют стили MainContainer
 * необходимо применить к таким компонентам как ColorBackground, PatternBackground
 */
export const backgroundFullHeightStyles: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  flex: 'auto',
};
