/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useRef, useState } from 'react';

import clsx from 'clsx';
import { useMediaQuery } from '@mui/material';

import { interestsIconHeart } from '../../../../icons';
import {
  IInterest, IInterestAction, IInterestChild, IOffset, ISectionTheme
} from '../types';

import './index.scss';

interface IInterestBubbleProps {
  interest: IInterest,
  theme: ISectionTheme,
  actions?: IInterestAction[],
  bubbleChildren?: IInterestChild[],
  onClick: (interest: IInterest, ref: any) => void,
  onChildClick: (interestId: number, childId: number) => void,
  updateBubblesComponent: () => void
}

const NUMBER_OF_ELEMENTS_IN_CIRCLE = 9;

const InterestBubble: React.FC<IInterestBubbleProps> = ({
  interest,
  theme,
  actions,
  bubbleChildren,
  onClick,
  onChildClick,
  updateBubblesComponent
}) => {
  const isMobile = useMediaQuery('(max-width: 766px)', { noSsr: true });

  const [areChildrenShown, setChildrenShown] = useState(false);
  const bubbleRef = useRef<HTMLButtonElement | null>(null);
  const { mainColor, secondaryColor } = theme;
  const {
    top, bottom, left, right
  } = interest.offsets as IOffset;
  const isInterestSelected = Boolean(actions);
  const backgroundColor = isInterestSelected ? mainColor : undefined;
  const color = isInterestSelected ? 'white' : undefined;

  const filteredActions = actions?.filter((action) => action.isActive);

  const handleInterestObjectClick = (e: any) => {
    // запрещает случайные клики по элементу "+", который переключяет отображение дочерних обьектов
    if (e.target.classList.contains('bubble')) {
      onClick({ ...interest }, bubbleRef.current);
    }
  };

  const handleToggleChildren = () => {
    if (isMobile) return;

    toggleChildren();
    updateBubblesComponent();
  };

  const toggleChildren = () => {
    setChildrenShown((prevstate) => !prevstate);
  };

  const calculateWrapperSize = (numberOfCircles: number) => {
    const basicSize = 500;
    const sizeModificator = Math.floor(numberOfCircles / NUMBER_OF_ELEMENTS_IN_CIRCLE) + numberOfCircles / NUMBER_OF_ELEMENTS_IN_CIRCLE * 20;

    return {
      width: `${basicSize + sizeModificator}px`,
      height: `${basicSize + sizeModificator}px`
    };
  };

  const calculateChildTransforms = (index: number) => {
    const translateModificator = Math.floor(index / NUMBER_OF_ELEMENTS_IN_CIRCLE);
    const translateBasis = 182;
    const translateOffset = 90;
    const translate = translateBasis + translateModificator * translateOffset;

    const rotateBasis = 360 / NUMBER_OF_ELEMENTS_IN_CIRCLE;
    const rotateOffset = 20;
    const rotate = index * rotateBasis + translateModificator * rotateOffset;

    return {
      rotate,
      translate
    };
  };

  const { width, height } = calculateWrapperSize(bubbleChildren?.length ?? 0);

  const bubbleOffsets = !areChildrenShown ? {
    top,
    bottom,
    left,
    right
  } : {};

  return (
    <div
      className={clsx('interests-bubbles__bubble-wrapper', { 'interests-bubbles__bubble-wrapper--selected': isInterestSelected })}
      style={areChildrenShown ? { width, height } : undefined}
    >
      <button
        ref={bubbleRef}
        type="button"
        key={interest.id}
        className="interests-bubbles__bubble bubble"
        style={{
          backgroundColor,
          color,
          ...bubbleOffsets
        }}
        onClick={handleInterestObjectClick}
      >
        {filteredActions && filteredActions?.length > 0 && (
          <>
            <div
              className="bubble__actions-container"
              style={{ backgroundColor: theme.secondaryColor }}
            >
              <img
                src={interestsIconHeart}
                alt="Действия выбраны"
              />
            </div>
            {interest.children && interest.children.length > 0 && (
            <button
              type="button"
              className={clsx('bubble__child-opener', { 'bubble__child-opener--open': areChildrenShown })}
              aria-label={areChildrenShown ? 'Скрыть подкатегории' : 'Показать подкатегории'}
              onClick={handleToggleChildren}
            />
            )}
          </>
        )}
        <span className="bubble__parent-info">
          {interest.name}
        </span>
      </button>
      {areChildrenShown && bubbleChildren?.map((child, index) => {
        const { translate, rotate } = calculateChildTransforms(index);
        return (
          <React.Fragment key={child.id}>
            <button
              type="button"
              className="interests-bubbles__child bubble"
              style={{
                backgroundColor: child.isActive ? mainColor : undefined,
                color: child.isActive ? 'white' : undefined,
                transform: `rotate(${rotate}deg) translate(${translate}px) rotate(-${rotate}deg)`
              }}
              onClick={(event) => { event.stopPropagation(); onChildClick(interest.id, child.id); }}
            >
              <span className="bubble__child-info">
                {child.name}
              </span>
            </button>
            <div
              key={`${child.id}-pointer`}
              className="interests-bubbles__pointer"
              style={{
                width: `${translate}px`,
                transform: `rotate(${rotate}deg)`
              }}
            />
          </React.Fragment>
        );
      })}
    </div>

  );
};

export default InterestBubble;
