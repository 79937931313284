import { IBuildConfig } from 'packages/buildConfig';

import { LocalServiceApi } from './LocalServiceApi';

export class AuthServiceApi extends LocalServiceApi {
  constructor(prefix: string) {
    super(prefix);
  }

  protected onConfigLoad(config: IBuildConfig): void {
    const xApiKey = config.GATEWAY_API_KEY;
    this.url = config.AUTH_SERVICE_URL || '';
    this.xApiKey = xApiKey && xApiKey.length > 0 ? xApiKey : undefined;
  }
}
