/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

const IconJuniorTourist3: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="50" height="50" fill="#E5E5E5"/>
    <rect width="1924" height="1397" transform="translate(-1228 -1085)" fill="white"/>
    <g clipPath="url(#clip0)">
    <path d="M2.18286 24.8877C2.35602 18.9158 4.83536 13.247 9.09458 9.08449C13.3538 4.92201 19.0576 2.59364 24.995 2.59364C30.9325 2.59364 36.6363 4.92201 40.8955 9.08449C45.1547 13.247 47.6341 18.9158 47.8072 24.8877H2.18286Z" fill="white"/>
    <path d="M25.0048 2.12906C30.9935 2.13648 36.7375 4.52018 40.9902 8.76277C45.243 13.0054 47.6612 18.7644 47.7199 24.79H2.28003C2.34135 18.7636 4.76168 13.0045 9.01596 8.76216C13.2702 4.51984 19.0153 2.13645 25.0048 2.12906ZM25.0048 1.9337C21.9943 1.93242 19.0129 2.52797 16.2312 3.68634C13.4494 4.84471 10.9217 6.5432 8.79241 8.68478C6.66316 10.8264 4.9741 13.3691 3.8217 16.1676C2.66931 18.9662 2.07617 21.9658 2.07617 24.9951H47.9238C47.9238 18.8789 45.5091 13.0131 41.211 8.68824C36.9128 4.36338 31.0833 1.9337 25.0048 1.9337Z" fill="#BA6445"/>
    <path d="M25.0048 3.35979C22.1803 3.35851 19.3833 3.91718 16.7735 5.00388C14.1637 6.09058 11.7922 7.68403 9.79454 9.69318C7.79689 11.7023 6.21222 14.0878 5.13106 16.7134C4.04989 19.3389 3.49341 22.1531 3.49341 24.9951H46.5065C46.5065 19.2571 44.2411 13.754 40.2088 9.69663C36.1764 5.63922 30.7074 3.35979 25.0048 3.35979Z" fill="#748FFC"/>
    <path d="M25.005 4.77609C19.6757 4.77609 14.5647 6.90631 10.7963 10.6981C7.02794 14.4899 4.91089 19.6327 4.91089 24.9951H45.0797C45.081 22.3412 44.5628 19.713 43.5546 17.2606C42.5465 14.8082 41.0681 12.5797 39.204 10.7022C37.3399 8.82465 35.1265 7.33496 32.6903 6.31817C30.254 5.30137 27.6425 4.77737 25.005 4.77609Z" fill="#E83F40"/>
    <path d="M24.9951 -8L13.5793 24.9951L24.9951 58L36.508 24.9951L24.9951 -8Z" fill="#748FFC"/>
    <path d="M24.9951 58L36.508 24.9951L24.9951 -8" fill="#5073CC"/>
    <path d="M58 24.9951L24.9951 13.5084L-8 24.9951L24.9951 36.4819L58 24.9951Z" fill="#748FFC"/>
    <path d="M-8 24.9951L24.9951 36.4819L58 24.9951" fill="#5073CC"/>
    <path d="M25.0047 43.9541C35.4108 43.9541 43.8466 35.4659 43.8466 24.9951C43.8466 14.5243 35.4108 6.0361 25.0047 6.0361C14.5986 6.0361 6.16284 14.5243 6.16284 24.9951C6.16284 35.4659 14.5986 43.9541 25.0047 43.9541Z" fill="#F2935F"/>
    <path d="M35.6149 30.3087C34.648 32.282 33.1575 33.9477 31.3082 35.1216C29.4589 36.2955 27.323 36.9318 25.1366 36.9601C22.9503 36.9884 20.7988 36.4076 18.9201 35.282C17.0415 34.1564 15.5088 32.5298 14.4918 30.5822L4.83301 36.3842C6.84861 40.0139 9.79479 43.0332 13.3645 45.1274C16.9342 47.2217 20.9968 48.3143 25.129 48.2914C29.2611 48.2684 33.3114 47.1308 36.858 44.997C40.4045 42.8632 43.3174 39.8114 45.2931 36.1595L35.6149 30.3087Z" fill="#F2935F"/>
    <path d="M25.0049 36.941C31.5616 36.941 36.8769 31.5926 36.8769 24.9951C36.8769 18.3976 31.5616 13.0493 25.0049 13.0493C18.4481 13.0493 13.1328 18.3976 13.1328 24.9951C13.1328 31.5926 18.4481 36.941 25.0049 36.941Z" fill="url(#paint0_linear)" stroke="#BA6445" strokeWidth="0.2" strokeMiterlimit="10"/>
    <path d="M19.3262 20.1113H21.6462V30.465H19.3262V20.1113Z" fill="#BA6445"/>
    <path d="M23.7234 20.1113H26.0434V30.465H23.7234V20.1113Z" fill="#BA6445"/>
    <path d="M28.0432 20.1113H30.373V30.465H28.0432V20.1113Z" fill="#BA6445"/>
    <path opacity="0.5" d="M40.061 13.5572C38.3191 11.2428 36.0703 9.36377 33.4894 8.06612C30.9084 6.76847 28.0648 6.08717 25.1796 6.07518C14.919 6.07518 6.56103 14.5437 6.15332 25.1514C8.96162 27.1928 12.2126 28.5321 15.6374 29.0585C20.8749 29.8157 26.2092 28.6715 30.6846 25.8311C35.16 22.9906 38.484 18.6394 40.061 13.5572Z" fill="url(#paint1_linear)"/>
    <path d="M13.589 34.6163L14.8704 35.7884C15.6955 36.5405 16.0838 37.4391 15.2684 38.3475C15.1029 38.5337 14.8993 38.6816 14.6716 38.781C14.4439 38.8804 14.1975 38.929 13.9493 38.9234C13.7012 38.9179 13.4572 38.8583 13.2341 38.7487C13.011 38.6392 12.8142 38.4823 12.6571 38.2889L12.1912 37.8689L11.0457 39.1484L10.2012 38.3963L13.589 34.6163ZM13.2104 37.5466C13.6764 37.9666 14.0841 37.9568 14.4336 37.5466C14.783 37.1363 14.6665 36.7847 14.2103 36.3647L13.822 36.013L12.8027 37.1461L13.2104 37.5466Z" fill="#BA6445"/>
    <path d="M15.4626 39.5782C16.1712 38.0838 17.404 37.6247 18.6369 38.2108C19.8697 38.7968 20.2968 40.0666 19.6076 41.5513C18.9184 43.036 17.6661 43.5048 16.4139 42.9578C15.1616 42.4108 14.7539 41.0727 15.4626 39.5782ZM18.5398 41.0531C18.9669 40.135 18.8407 39.4024 18.2195 39.0996C17.5982 38.7968 16.9478 39.1582 16.511 40.0764C16.0741 40.9945 16.1906 41.7662 16.8119 42.0299C17.4332 42.2936 18.103 41.9713 18.5398 41.0531Z" fill="#BA6445"/>
    <path d="M20.423 41.815C20.4484 41.4802 20.5425 41.1544 20.6992 40.858C20.8559 40.5616 21.0719 40.301 21.3337 40.0927C21.5954 39.8844 21.8971 39.7329 22.2199 39.6477C22.5426 39.5626 22.8794 39.5455 23.209 39.5978C23.4892 39.6475 23.7566 39.7536 23.9951 39.9097C24.2336 40.0657 24.4384 40.2686 24.5972 40.5061L23.8788 41.1215C23.6921 40.8398 23.4027 40.6433 23.0731 40.5745C22.3936 40.4671 21.7529 40.975 21.5782 41.9811C21.4035 42.9871 21.782 43.6806 22.5489 43.8076C22.735 43.8352 22.9248 43.8225 23.1056 43.7702C23.2865 43.718 23.4541 43.6275 23.5973 43.5048L24.073 44.3155C23.8367 44.516 23.5591 44.6612 23.2603 44.7406C22.9615 44.82 22.6489 44.8316 22.3451 44.7746C22.0153 44.7286 21.6991 44.612 21.4178 44.4327C21.1366 44.2533 20.8968 44.0154 20.7147 43.7349C20.5325 43.4545 20.4123 43.138 20.362 42.8068C20.3116 42.4756 20.3325 42.1374 20.423 41.815Z" fill="#BA6445"/>
    <path d="M25.1698 42.489C25.1076 42.159 25.1139 41.8197 25.1885 41.4923C25.263 41.1649 25.4042 40.8566 25.603 40.5868C25.8018 40.3171 26.054 40.0916 26.3436 39.9248C26.6333 39.7579 26.9542 39.6532 27.286 39.6173C27.5677 39.5936 27.8513 39.6273 28.1198 39.7162C28.3883 39.8052 28.6362 39.9476 28.8489 40.135L28.3247 40.9164C28.1931 40.7997 28.0389 40.7118 27.8718 40.658C27.7048 40.6042 27.5285 40.5857 27.354 40.6038C26.6648 40.6722 26.1794 41.3266 26.2765 42.3425C26.3735 43.3583 26.9171 43.9346 27.6452 43.8662C27.8386 43.85 28.026 43.7908 28.1939 43.6928C28.3618 43.5948 28.5059 43.4606 28.6159 43.2997L29.2857 43.9541C29.1104 44.2115 28.8798 44.4259 28.6109 44.5815C28.342 44.7371 28.0419 44.8298 27.7326 44.8527C27.4057 44.8874 27.0752 44.8518 26.763 44.7482C26.4508 44.6446 26.1641 44.4755 25.9218 44.252C25.6795 44.0285 25.4871 43.7557 25.3575 43.4518C25.2279 43.1479 25.1639 42.8197 25.1698 42.489Z" fill="#BA6445"/>
    <path d="M29.4119 39.3731L30.4505 38.9531L31.1301 40.6038C31.3442 41.1833 31.5288 41.7734 31.6834 42.3718L31.7319 41.1606L32.0037 38.3182L33.1201 37.8591L35.0615 42.5476L34.0131 42.9773L33.3336 41.3266C33.1104 40.7699 32.9453 40.1057 32.7803 39.5684V40.7796L32.46 43.6513L31.3436 44.1104L29.4119 39.3731Z" fill="#BA6445"/>
    <path d="M34.0715 37.2828L34.9258 36.5405L36.0907 37.8884C36.484 38.362 36.8502 38.8577 37.1876 39.3731L36.8381 38.2107L36.1877 35.4269L37.1002 34.6358L40.4298 38.3963L39.5853 39.1484L38.4107 37.781C38.0127 37.3316 37.6438 36.8042 37.3138 36.306L37.6632 37.4684L38.2845 40.3498L37.372 41.1508L34.0715 37.2828Z" fill="#BA6445"/>
    <path d="M10.6282 17.2494L10.9679 16.7415L11.8319 17.3178L12.2784 16.6438L12.7152 16.9368L12.2784 17.6108L13.2491 18.2359L12.9191 18.7438L10.6282 17.2494ZM12.327 17.1029C12.1685 17.0322 12.0272 16.9278 11.9129 16.7967C11.7985 16.6656 11.714 16.511 11.665 16.3437C11.6161 16.1764 11.6039 16.0003 11.6294 15.8278C11.6548 15.6553 11.7173 15.4905 11.8125 15.3447C11.9088 15.1996 12.0349 15.0769 12.1823 14.985C12.3298 14.893 12.495 14.834 12.6671 14.8118C12.8391 14.7895 13.0139 14.8047 13.1796 14.8562C13.3453 14.9077 13.4981 14.9943 13.6277 15.1103C13.7893 15.183 13.9332 15.2901 14.0497 15.4242C14.1661 15.5583 14.2522 15.7163 14.302 15.8872C14.3518 16.058 14.3642 16.2378 14.3383 16.4139C14.3124 16.5901 14.2488 16.7585 14.1519 16.9075C14.0526 17.0514 13.9236 17.172 13.7739 17.2613C13.6241 17.3506 13.4569 17.4064 13.2838 17.4249C13.1107 17.4434 12.9356 17.4243 12.7705 17.3687C12.6054 17.3131 12.4542 17.2225 12.327 17.1029ZM13.2977 15.6377C12.8414 15.3447 12.4629 15.3349 12.2687 15.6377C12.0746 15.9405 12.2202 16.2921 12.6764 16.6145C13.1327 16.9368 13.521 16.9368 13.7248 16.6145C13.9287 16.2921 13.7345 15.9307 13.288 15.6377H13.2977Z" fill="#BA6445"/>
    <path d="M13.5015 13.3716L13.9577 12.9614L14.6469 13.7428L15.3459 13.1274L14.6566 12.346L15.1129 11.9358L16.9281 13.9772L16.4719 14.3777L15.7244 13.5377L15.0352 14.153L15.773 15.0028L15.3167 15.4033L13.5015 13.3716Z" fill="#BA6445"/>
    <path d="M16.3264 11.0469L16.87 10.7636L17.3166 11.6427L17.5592 11.5158C18.0931 11.2325 18.5979 11.2325 18.8794 11.7697C19.1609 12.3069 18.8794 12.7465 18.3358 13.0493L17.569 13.4497L16.3264 11.0469ZM18.0834 12.6293C18.365 12.4828 18.4814 12.2972 18.3552 12.053C18.2291 11.8088 18.0349 11.8088 17.734 11.9748L17.5398 12.0725L17.8893 12.7367L18.0834 12.6293ZM18.3455 9.98224L18.8891 9.69897L20.1317 12.1018L19.5978 12.3948L18.3455 9.98224Z" fill="#BA6445"/>
    <path d="M20.2871 9.23014L20.8696 9.08362L21.0928 10.0604C21.1608 10.373 21.1996 10.7539 21.2384 11.0372L21.3646 10.4023L21.782 8.8785L22.4033 8.72222L23.0246 11.3595L22.4421 11.506L22.2189 10.5292C22.1509 10.2167 22.1121 9.85527 22.0733 9.55247L21.9471 10.1874L21.5685 11.6623L20.9472 11.8186L20.2871 9.23014ZM20.4521 8.37059L20.9569 8.25337C20.9569 8.40965 21.0831 8.49756 21.2287 8.45849C21.3743 8.41942 21.4132 8.31198 21.384 8.14593L21.8791 8.02872C21.9374 8.35105 21.8112 8.63431 21.2967 8.76129C20.7822 8.88827 20.5492 8.68315 20.4521 8.37059Z" fill="#BA6445"/>
    <path d="M25.6648 9.09338L24.927 9.04454V8.53662L27.0141 8.65383V9.17152L26.286 9.09338L26.1598 11.2911H25.558L25.6648 9.09338Z" fill="#BA6445"/>
    <path d="M27.6064 11.4474L27.8394 10.959C27.8959 10.9957 27.9583 11.0222 28.0239 11.0371C28.0717 11.0537 28.1231 11.057 28.1726 11.0467C28.2221 11.0364 28.268 11.0129 28.3054 10.9785L28.3636 10.9102L27.9656 8.74174L28.5966 8.89803L28.7034 9.74781L28.7616 10.4315L29.1305 9.85526L29.5964 9.16175L30.1886 9.30827L28.8781 11.1348C28.7861 11.305 28.6418 11.4407 28.4669 11.5217C28.2919 11.6026 28.0957 11.6245 27.9074 11.5841C27.8 11.5562 27.6983 11.5099 27.6064 11.4474Z" fill="#BA6445"/>
    <path d="M31.1301 9.67944L31.9649 10.0799C32.4988 10.3339 32.8483 10.7246 32.5668 11.3204C32.5059 11.4361 32.4225 11.5383 32.3215 11.6209C32.2206 11.7035 32.1042 11.7649 31.9792 11.8013C31.8542 11.8378 31.7233 11.8486 31.5941 11.8331C31.4649 11.8176 31.3401 11.7761 31.2272 11.7111L30.9262 11.5646L30.5379 12.3948L29.9846 12.1409L31.1301 9.67944ZM31.4019 11.252C31.7028 11.3888 31.9067 11.3204 32.0329 11.0664C32.1591 10.8125 32.0329 10.6464 31.7319 10.4999L31.4893 10.3827L31.1495 11.1251L31.4019 11.252Z" fill="#BA6445"/>
    <path d="M34.0325 11.1934L34.5082 11.5548L33.9354 12.3167C33.7413 12.5804 33.4792 12.8442 33.285 13.059L33.8578 12.7465L35.2265 12.1018L35.741 12.4925L34.1102 14.6609L33.6345 14.2898L34.2072 13.5279C34.4014 13.2739 34.6635 13.0102 34.8673 12.7855L34.3043 13.0981L32.9259 13.7428L32.4114 13.3521L34.0325 11.1934Z" fill="#BA6445"/>
    <path d="M35.6635 14.2995C35.7847 14.1605 35.934 14.0491 36.1014 13.9727C36.2688 13.8964 36.4504 13.8569 36.6342 13.8569C36.818 13.8569 36.9997 13.8964 37.1671 13.9727C37.3345 14.0491 37.4838 14.1605 37.605 14.2995C37.7999 14.5371 37.8941 14.8424 37.8671 15.1493H37.3623C37.3733 15.0592 37.366 14.9677 37.341 14.8804C37.316 14.7931 37.2737 14.7118 37.2167 14.6414C36.974 14.3581 36.5372 14.3484 36.1197 14.7098C35.7023 15.0712 35.6344 15.4814 35.8868 15.7842C35.9568 15.8584 36.0411 15.9175 36.1345 15.9578C36.228 15.9981 36.3287 16.0188 36.4304 16.0186L36.3527 16.5168C36.1865 16.5203 36.0217 16.4862 35.8705 16.4168C35.7192 16.3475 35.5854 16.2448 35.4791 16.1163C35.3672 15.9873 35.2829 15.8365 35.2314 15.6733C35.1799 15.5101 35.1622 15.338 35.1795 15.1677C35.1968 14.9973 35.2487 14.8324 35.332 14.683C35.4152 14.5337 35.528 14.4032 35.6635 14.2995Z" fill="#BA6445"/>
    <path d="M38.5174 16.6438L38.1388 15.9991L38.5854 15.7354L39.6338 17.5619L39.1969 17.8159L38.8184 17.1712L36.9351 18.275L36.6245 17.7475L38.5174 16.6438Z" fill="#BA6445"/>
    </g>
    <rect x="-1163.5" y="-560.5" width="1768" height="796" rx="4.5" stroke="#7B61FF" strokeDasharray="10 5"/>
    <defs>
    <linearGradient id="paint0_linear" x1="19.0349" y1="16.927" x2="28.6436" y2="29.7633" gradientUnits="userSpaceOnUse">
    <stop stopColor="white"/>
    <stop offset="0.03" stopColor="#FEFEFF"/>
    <stop offset="1" stopColor="#F1ECFF"/>
    </linearGradient>
    <linearGradient id="paint1_linear" x1="18.3845" y1="10.4511" x2="27.0586" y2="26.6012" gradientUnits="userSpaceOnUse">
    <stop stopColor="white" stopOpacity="0"/>
    <stop offset="0.35" stopColor="white" stopOpacity="0.01"/>
    <stop offset="0.49" stopColor="white" stopOpacity="0.03"/>
    <stop offset="0.59" stopColor="white" stopOpacity="0.08"/>
    <stop offset="0.67" stopColor="white" stopOpacity="0.15"/>
    <stop offset="0.74" stopColor="white" stopOpacity="0.24"/>
    <stop offset="0.8" stopColor="white" stopOpacity="0.35"/>
    <stop offset="0.85" stopColor="white" stopOpacity="0.47"/>
    <stop offset="0.9" stopColor="white" stopOpacity="0.62"/>
    <stop offset="0.95" stopColor="white" stopOpacity="0.79"/>
    <stop offset="1" stopColor="white"/>
    </linearGradient>
    <clipPath id="clip0">
    <rect width="50" height="50" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);

export default IconJuniorTourist3;
