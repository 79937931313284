import React from 'react';

import { Box } from '@mui/material';
import { girl1 } from 'images';

import { chipContent, imgWrapper } from './style';

interface IThemeCoverPreviewProps {
  img: string;
}

const ThemeCoverPreview: React.FC<IThemeCoverPreviewProps> = ({ img }) => {
  return (
    <Box sx={chipContent}>
      <img src={img} alt="Картинка" />
      <Box sx={imgWrapper}>
        <img src={girl1} alt="Аватар" />
      </Box>
    </Box>
  );
};

export default ThemeCoverPreview;
