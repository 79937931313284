import { FC, useContext } from 'react';

import { Box } from '@mui/material';
import { IconArrowUp } from 'icons';
import { Button } from 'portfolio3/ui-kit/button';

import { initialClientFilters } from '../../../const';
import { VuzRecommendationContext } from '../../../context/vuzRecommendationContext';

interface IMobilePaginationWidgetProps {
  disabled?: boolean;
  onScrollToTop: () => void;
}

const MobilePaginationWidget: FC<IMobilePaginationWidgetProps> = ({ disabled, onScrollToTop }) => {
  const { clientFilters, setClientFilter, totalElements } = useContext(VuzRecommendationContext);
  const MOBILE_PAGINATION_MORE_LIMIT = 5;

  const limit = clientFilters?.limit ?? initialClientFilters.limit;
  const isLastPage = limit >= totalElements;

  const handleClick = () => {
    setClientFilter('limit', limit + MOBILE_PAGINATION_MORE_LIMIT);
  };

  const buttonText = isLastPage ? 'Вернуться в начало списка' : 'Показать еще';
  const isDisabled = isLastPage ? false : disabled;
  const clickHandler = isLastPage ? onScrollToTop : handleClick;
  const icon = isLastPage ? <IconArrowUp /> : undefined;

  return (
    <Box className="pagination">
      <Button
        sx={{
          width: '100%',
        }}
        variant="tetriary"
        size="medium"
        disabled={isDisabled}
        onClick={clickHandler}
        iconLeft={icon}
      >
        {buttonText}
      </Button>
    </Box>
  );
};

export default MobilePaginationWidget;
