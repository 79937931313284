import { FC, useContext, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { EmployeeSubheader } from 'portfolio3/components/common/headers';
import { getAccentColor } from 'portfolio3/styles';
import { FiltersCountBadge } from 'portfolio3/ui-kit/badges';
import { Button } from 'portfolio3/ui-kit/button';

import { DifferentCode } from '../../../../const';
import { DeleteHistoryContext } from '../../../../context';
import { IconChevronSmallDown, IconChevronSmallUp, IconFunnelOutline } from '../../../../icons';
import DeleteHistoryFilters from '../filters';

const DeleteHistoryHeader: FC = () => {
  const [isFiltersOpen, setFiltersOpen] = useState(false);

  const { currentFilters } = useContext(DeleteHistoryContext);

  const filtersCounter = useMemo(() => {
    const { startDate, endDate, category, subCategory, student, deleter } = currentFilters;
    return (
      Number(Boolean(startDate)) +
      Number(Boolean(endDate)) +
      Number(category !== DifferentCode) +
      Number(Boolean(subCategory)) +
      Number(Boolean(student?.lastName)) +
      Number(Boolean(student?.firstName)) +
      Number(Boolean(student?.patronymic)) +
      Number(Boolean(deleter?.firstName)) +
      Number(Boolean(deleter?.lastName)) +
      Number(Boolean(deleter?.patronymic))
    );
  }, [currentFilters]);

  const toggleFilters = () => {
    setFiltersOpen((prevstate) => !prevstate);
  };

  const filterButton = (
    <Button
      variant="secondary"
      size="small"
      iconLeft={
        <Box sx={{ position: 'relative', display: 'flex' }}>
          <IconFunnelOutline />
          {filtersCounter > 0 && (
            <FiltersCountBadge
              count={filtersCounter}
              color={getAccentColor('orange', '100')}
              outlineColor="#ffffff"
              size="small"
              sx={{ position: 'absolute', top: '-4px', right: '-5px' }}
            />
          )}
        </Box>
      }
      iconRight={isFiltersOpen ? <IconChevronSmallUp /> : <IconChevronSmallDown />}
      onClick={toggleFilters}
    >
      Фильтры
    </Button>
  );

  return (
    <Box className="subheader-container" sx={{ position: 'relative' }}>
      <EmployeeSubheader title="История удаления" rightElement={filterButton} sx={{ alignItems: 'center' }} />
      <Box
        sx={{
          position: 'absolute',
          zIndex: 10,
          top: '100%',
          left: 0,
          right: 0,
          // используем visibility из-за локального состояния внутри компонента
          visibility: isFiltersOpen ? 'visible' : 'hidden',
        }}
      >
        <DeleteHistoryFilters onCloseFilters={toggleFilters} />
      </Box>
    </Box>
  );
};

export default DeleteHistoryHeader;
