/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconSelectArrow: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M6.00761 6C5.82102 6 5.64998 5.92298 5.51004 5.79974L0.876416 1.19384C0.596533 0.91656 0.596533 0.485237 0.876416 0.207959C1.1563 -0.0693196 1.59167 -0.0693196 1.87156 0.207959L6.00761 4.33633L10.1281 0.223363C10.408 -0.0539152 10.8434 -0.0539152 11.1233 0.223363C11.4031 0.500642 11.4031 0.931964 11.1233 1.20924L6.50518 5.81515C6.38079 5.93838 6.1942 6 6.00761 6Z" fill="#181920" />
  </SvgIcon>
);

export default IconSelectArrow;
