import { IChildInfo, IPerson, OrganizationRefItem } from 'api/types';

export const mapPersonToChildInfo = (student: IPerson, organizations: OrganizationRefItem[]): IChildInfo => {
  const { mshId, firstname, lastname, patronymic, organizationId, gender } = student;
  const schoolName = organizations.find((school) => school.globalId === organizationId)?.shortName;

  return {
    meshId: mshId,
    firstname,
    lastname,
    patronymic,
    schoolId: organizationId,
    schoolName,
    gender,
  };
};
