import { createSelector } from 'reselect';

import { IRootState } from '../../../reducers';
import { mapSportRewardsResponse } from './mappers';

const sportRewardsSelector = createSelector(
  [
    (state: IRootState) => state.studentSportRewards,
    (state: IRootState) => state.studentEvents,
    (state: IRootState) => state.sportKinds,
    (state: IRootState) => state.tourismKinds,
    (state: IRootState) => state.sectionRef,
  ],
  (studentRewards, studentEvents, sportKinds, tourismKinds, sectionRef) => {
    return mapSportRewardsResponse(studentEvents, studentRewards, sportKinds, tourismKinds, sectionRef);
  },
);

export default sportRewardsSelector;
