import { RadioButtonSize } from 'portfolio3/ui-kit/types';

import { IRadioButtonTokens } from '../types';

export const RadioButtonOuterSize: Record<RadioButtonSize, number> = {
  small: 20,
  medium: 24,
};

export const RadioButtonInnerSize: Record<RadioButtonSize, number> = {
  small: 10,
  medium: 12,
};

export const getRadioButtonTokens = (size: RadioButtonSize): IRadioButtonTokens => {
  return {
    outerSize: RadioButtonOuterSize[size],
    innerSize: RadioButtonInnerSize[size],
  };
};
