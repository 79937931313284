import { CommonUiKitSize } from 'portfolio3/ui-kit/types';
import { getInputTokens } from 'portfolio3/ui-kit/utils';
import { SxStyles } from 'types';
import { numberToPixelsString } from 'utils';

export const root: SxStyles = {
  width: '100%',
};

export const tags: SxStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  width: '100%',
};

export const rightPanel = (inputSize: CommonUiKitSize): SxStyles => {
  const { paddingRight } = getInputTokens(inputSize);

  return {
    position: 'absolute',
    right: numberToPixelsString(paddingRight),

    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  };
};
