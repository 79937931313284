import { GET_ADMIN_VIEW_MODE_SETTINGS } from '../../actions';
import { FAILURE, INVALIDATE, REQUEST, SUCCESS } from '../../actions/utils';
import { ICommonResponseObject, IUserSettingsByAdminResponse } from '../../api/types';
import { connectReducers } from '../../utils';
import { IReducers } from '..';
import genericReducers from '../genericReducer';

export type AdminViewModeSettingsState = ICommonResponseObject<IUserSettingsByAdminResponse | null>;

const initialState: AdminViewModeSettingsState = {
  content: null,
  loading: false,
};

const adminViewModeSettings: IReducers<AdminViewModeSettingsState> = {
  [GET_ADMIN_VIEW_MODE_SETTINGS[REQUEST]]: genericReducers().get.request,
  [GET_ADMIN_VIEW_MODE_SETTINGS[SUCCESS]]: genericReducers().get.success,
  [GET_ADMIN_VIEW_MODE_SETTINGS[FAILURE]]: genericReducers().get.failure,
  [GET_ADMIN_VIEW_MODE_SETTINGS[INVALIDATE]]: genericReducers().get.invalidate(initialState),
};

export default connectReducers(initialState, adminViewModeSettings);
