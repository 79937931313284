import { FC, ReactNode, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Typography } from '@mui/material';
import { deleteAttachmentTemplateInfoActions } from 'actions';
import { ITemplateParseInfo } from 'api/types';
import { importerChapters } from 'const';
import { format, isToday, isYesterday } from 'date-fns';
import ruLocale from 'date-fns/locale/ru';
import { IconArrowRight, IconCloudUploadOutline } from 'icons';
import { IconClose } from 'icons/edit';
import UnstyledButton from 'portfolio3/components/common/UnstyledButton';
import { getAccentColor, NeutralColors } from 'portfolio3/styles';
import { mergeSx } from 'utils';

import { ExclamationMarkIndicator, SuccessIndicator } from '../icons';
import LoadingIndicator from '../LoadingIndicator';
import * as styles from './styles';

const getTaskDate = (taskDateString: string) => {
  const taskDate = new Date(taskDateString);

  let dateFormat = 'dd.MM.yyyy';

  if (isToday(taskDate)) {
    dateFormat = 'Сегодня';
  }

  if (isYesterday(taskDate)) {
    dateFormat = 'Вчера';
  }

  return format(taskDate, `${dateFormat} в HH:mm`, { locale: ruLocale });
};

interface ITaskProps {
  task: ITemplateParseInfo;
  onOpenImportDialog: (initialChapter: number, uuid: string) => void;
}

const Task: FC<ITaskProps> = ({ task, onOpenImportDialog }) => {
  const dispatch = useDispatch();

  const [isHovered, setHovered] = useState(false);

  const { uuid, creationDate, status, errorMessage, filename } = task;

  const isSuccess = status === 'SUCCESS';
  const isPreview = status === 'PREVIEW';
  const isSaving = status === 'SAVING';
  const isChecking = status === 'CHECKING';
  const isFail = status === 'FAIL';

  const isContinueArrowShown = isSuccess || isPreview;
  const isLaoding = isChecking || isSaving;

  const taskDate = getTaskDate(creationDate);

  let statusIndicatorElement: ReactNode = null;
  if (isSuccess) statusIndicatorElement = <SuccessIndicator />;
  if (isPreview) statusIndicatorElement = <ExclamationMarkIndicator color={getAccentColor('orange', '100')} />;
  if (isFail) statusIndicatorElement = <ExclamationMarkIndicator color={getAccentColor('red', '100')} />;

  let textStatus: string | null = null;
  if (isPreview) textStatus = 'Требуются ваши действия';
  if (isFail) textStatus = errorMessage ?? 'Произошла ошибка при импорте';

  return (
    <Box
      className="task"
      sx={mergeSx(styles.root, isHovered && { backgroundColor: getAccentColor('indigo', '8') })}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Box sx={styles.leftPanel}>
        <Box
          className="task__action-type"
          sx={mergeSx(
            styles.actionType,
            isHovered && { backgroundColor: getAccentColor('indigo', '80'), color: NeutralColors.light_neutrals_0 },
          )}
        >
          <IconCloudUploadOutline />
        </Box>
      </Box>
      <Box sx={styles.rightPanel}>
        <Box className="task__info">
          <Typography variant="Paragraph MD/Medium">{filename}</Typography>
          <Typography variant="Paragraph MD/Regular" color={NeutralColors.light_neutrals_600}>
            {taskDate}
          </Typography>
          {textStatus && (
            <Typography
              variant="Paragraph SM/Medium"
              marginTop="5px"
              color={isFail ? getAccentColor('red', '100') : getAccentColor('orange', '100')}
            >
              {textStatus}
            </Typography>
          )}
        </Box>
        <Box sx={styles.buttons}>
          {isContinueArrowShown && (
            <UnstyledButton
              sx={styles.openArrow}
              onClick={() => onOpenImportDialog(isSuccess ? importerChapters.FOURTH : importerChapters.THIRD, uuid)}
            >
              <IconArrowRight />
            </UnstyledButton>
          )}

          {isLaoding && <LoadingIndicator />}
          {!isLaoding && (
            <Box sx={styles.indicatorContainer}>
              {statusIndicatorElement}
              <UnstyledButton
                sx={mergeSx(styles.deleteButton, isHovered && { opacity: 1, transform: 'scale(1.25)' })}
                onClick={() => dispatch(deleteAttachmentTemplateInfoActions.request(uuid))}
              >
                <IconClose />
              </UnstyledButton>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Task;
