import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Typography } from '@mui/material';
import { IconSearch } from 'icons/internetAndCode';
import Toggle from 'portfolio3/ui-kit/Toggle';

import { saveAdminFunctionSettingsActions } from '../../../actions';
import { IAdminFunctionSetting, IUserContext } from '../../../api/types';
import { IRootState } from '../../../reducers';
import SectionIconWrapper from './SectionIconWrapper';

import './index.scss';

interface ISearchSettingBlockProps {
  adminSettings: IAdminFunctionSetting[];
  currentUser: IUserContext;
  saveAdminFunctionSettings: typeof saveAdminFunctionSettingsActions.request;
}

const SearchSettingBlock: React.FC<ISearchSettingBlockProps> = ({
  adminSettings,
  currentUser,
  saveAdminFunctionSettings,
}) => {
  const [searchSetting, setSearchSetting] = useState({
    isVisible: true,
    functionType: 'Search',
  });

  useEffect(() => {
    const adminSearchSetting = adminSettings?.find((setting) => setting.functionType === 'Search');
    if (adminSearchSetting) setSearchSetting(adminSearchSetting);
  }, [adminSettings]);

  const handleUpdateSearchSetting = () => {
    setSearchSetting((prevstate) => {
      const newSetting = { ...prevstate, isVisible: !prevstate.isVisible };
      saveAdminFunctionSettings({
        administratorUserId: currentUser.data?.meshId || '',
        admSettings: [newSetting],
      });
      return newSetting;
    });
  };

  return (
    <div className="setting-block ">
      <div className="setting-block__section setting-block__section--rolled">
        <div className="setting-block__opener">
          <SectionIconWrapper Icon={IconSearch} accentColorType="yellow" disabled={!searchSetting.isVisible} />
          <Typography variant="Headings/H6">Поиск по портфолио</Typography>
        </div>
        <div className="setting-block__status-container">
          <Toggle
            className="setting-block__switch"
            onChange={handleUpdateSearchSetting}
            checked={searchSetting.isVisible}
          />
          <p className="setting-block__section-status">{searchSetting.isVisible ? 'Активен' : 'Неактивен'}</p>
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state: IRootState) => ({
    currentUser: state.currentUser,
  }),
  {
    saveAdminFunctionSettings: saveAdminFunctionSettingsActions.request,
  },
)(SearchSettingBlock);
