import { FAILURE, REQUEST, SUCCESS } from 'actions/utils';
import { ICommonResponseObject } from 'api/types';
import { IReducers } from 'reducers';
import genericReducers from 'reducers/genericReducer';
import { connectReducers } from 'utils';

import { ADD_FAVORITE_VUZ } from '../actions';

export type AddFavoriteVuzState = ICommonResponseObject<null>;

const initialState: AddFavoriteVuzState = {
  content: null,
  loading: false,
};

const addFavoriteVuz: IReducers<AddFavoriteVuzState> = {
  [ADD_FAVORITE_VUZ[REQUEST]]: genericReducers().createUpdate.request,
  [ADD_FAVORITE_VUZ[SUCCESS]]: genericReducers().createUpdate.success,
  [ADD_FAVORITE_VUZ[FAILURE]]: genericReducers().createUpdate.failure,
};

export default connectReducers(initialState, addFavoriteVuz);
