import { AnimalsAvatarsArray, BoysAvatarsArray, GenderCodes, GirlsAvatarsArray } from '../const';
import { Colors } from '../style/variables';

export const getRandomAvatarColor = (fio: string) => {
  const colorArray = [
    Colors['blue-primrose_d8'],
    Colors['orange-sunset_d6'],
    Colors['red-rose'],
    Colors['amethyst-starling'],
    Colors['fir-green_d6'],
  ];
  const colorIndex =
    Array.from(fio)
      .map((ltr) => ltr.charCodeAt(0))
      .reduce((s, n) => s + n) % 5;
  return colorArray[colorIndex];
};

/** @deprecated Использовалась для получения системного аватара. Позже требуется удалить вместе с картинками */
export const getAvaliableAvatars = (gender: number | undefined | null) => {
  if (gender === GenderCodes.boy) return [...BoysAvatarsArray, ...AnimalsAvatarsArray];
  return [...GirlsAvatarsArray, ...AnimalsAvatarsArray];
};
