import { FC, useContext, useEffect } from 'react';

import { Box, Typography } from '@mui/material';
import { GroupedActionButton, GroupedActionButtonsContainer } from 'components/common/GroupedActionButtons';
import { useAppSelector, useScrollToElement } from 'hooks';
import { IconBookmarkFilled, IconBookmarkOutline } from 'icons';
import InlinePadding from 'portfolio3/components/common/InlinePadding';
import UnderlinedTabs from 'portfolio3/components/common/UnderlinedTabs';
import { DrawerHeader } from 'portfolio3/components/drawers';
import { getAccentColor, NeutralColors } from 'portfolio3/styles';
import { NeutralNumberBadge } from 'portfolio3/ui-kit/badges';
import { Drawer, IDrawerBase } from 'portfolio3/ui-kit/Drawer';

import {
  detailsPaginationLimitOptions,
  drawerFacultiesFullSortOptions,
  drawerFacultiesSortOptions,
  drawerSpecialitiesSortOptions,
  VuzDetailsTab,
} from '../../const';
import { VuzDetailsContext } from '../../context';
import { IDetailsDrawerProps } from '../../types';
import { PaginationWidget } from '..';
import DetailsDrawerFilters from '../DetailsDrawerFilters';
import DetailsFacultyList from '../DetailsFacultyList';
import DetailsSpecialityList from '../DetailsSpecialityList';
import DrawerVuzCard from '../DrawerVuzCard';
import * as styles from './styles';

const SECTION_LIST_ID = 'section-list-id';

const VuzDetailsDrawerDesktop: FC<IDetailsDrawerProps> = ({
  isOpen,
  isLoading,
  isFavoriteVuz,
  isFavoriteStateLoading,
  onClose,
  onAddToFavorites,
  vuzInfo,
  specialities,
  faculties,
  facultyListType,
  isFacultyListEmpty,
  hasPagination,
  specialitiesDataPlugElement,
  facultiesDataPlugElement,
}) => {
  const { isViewing: isAdminViewing } = useAppSelector((state) => state.adminViewMode);

  const { currentTab, filters, totalElements, setFilter, onChangeCurrentTab } = useContext(VuzDetailsContext);

  const { scrollToElementLocal } = useScrollToElement();

  useEffect(() => {
    setFilter('page', 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.limit]);

  useEffect(() => {
    scrollToElementLocal(SECTION_LIST_ID);
  }, [filters.page, scrollToElementLocal]);

  const handleChangeSearch = (value: string) => setFilter('search', value);
  const handleChangeSort = (value: number) => setFilter('sortType', value);
  const handleChangePage = (value: number) => setFilter('page', value);
  const handleChangeLimit = (value: number) => setFilter('limit', value);

  const facultySortOptions = facultyListType === 'full' ? drawerFacultiesFullSortOptions : drawerFacultiesSortOptions;

  const header: IDrawerBase['header'] = (crossButton) => (
    <DrawerHeader
      title="Информация о вузе"
      overrideBackgroundColor={NeutralColors.light_neutrals_100}
      overrideBorderColor={NeutralColors.light_neutrals_400}
      backgroundImage={null}
      crossButton={crossButton}
      removeBottomPadding={!isFacultyListEmpty && !isLoading}
    >
      {vuzInfo && <DrawerVuzCard vuzInfo={vuzInfo} />}
      {!isFacultyListEmpty && currentTab && !isLoading && (
        <UnderlinedTabs value={currentTab} onChange={onChangeCurrentTab} sx={{ marginTop: '16px' }}>
          <UnderlinedTabs.Button value={VuzDetailsTab.SPECIALITIES} content="Специальности" />
          <UnderlinedTabs.Button value={VuzDetailsTab.FACULTIES} content="Факультеты" />
        </UnderlinedTabs>
      )}
    </DrawerHeader>
  );

  const favoriteIconColor = isFavoriteStateLoading ? getAccentColor('yellow', '64') : getAccentColor('yellow', '100');
  const footerElement = (
    <GroupedActionButtonsContainer disableAbsolutePosition>
      <GroupedActionButton
        variant="secondary"
        iconColorOverride={isFavoriteVuz ? favoriteIconColor : undefined}
        startIcon={isFavoriteVuz ? IconBookmarkFilled : IconBookmarkOutline}
        handleClick={onAddToFavorites}
        disabled={isFavoriteStateLoading}
      >
        {isFavoriteVuz ? 'В избранном' : 'Добавить в избранное'}
      </GroupedActionButton>
    </GroupedActionButtonsContainer>
  );

  const filtersElement = (
    <InlinePadding _p="20px">
      {isFacultyListEmpty && (
        <Typography component="span" variant="Paragraph LG/Semi Bold" sx={styles.specialityListLabel}>
          Специальности
          <NeutralNumberBadge number={totalElements} sx={styles.numberBadge} />
        </Typography>
      )}
      <DetailsDrawerFilters
        searchTerm={filters.search}
        sortType={filters.sortType}
        sortOptions={currentTab === VuzDetailsTab.SPECIALITIES ? drawerSpecialitiesSortOptions : facultySortOptions}
        onChangeSearchTerm={handleChangeSearch}
        onChangeSortType={handleChangeSort}
      />
    </InlinePadding>
  );

  const paginationElement = (
    <PaginationWidget
      totalElements={totalElements}
      page={filters.page}
      limit={filters.limit}
      limitOptions={detailsPaginationLimitOptions}
      onChangePage={handleChangePage}
      onChangeLimit={handleChangeLimit}
      sx={styles.pagination}
    />
  );

  const specialitiesListElement = (
    <>
      {specialitiesDataPlugElement ? (
        <Box marginTop="12px">{specialitiesDataPlugElement}</Box>
      ) : (
        <DetailsSpecialityList specialities={specialities}>{hasPagination && paginationElement}</DetailsSpecialityList>
      )}
    </>
  );

  const facultiesListElement = (
    <>
      {facultiesDataPlugElement ? (
        <Box marginTop="12px">{facultiesDataPlugElement}</Box>
      ) : (
        <DetailsFacultyList faculties={faculties} facultyListType={facultyListType}>
          {hasPagination && paginationElement}
        </DetailsFacultyList>
      )}
    </>
  );

  return (
    <Drawer
      open={isOpen}
      isFullHeight
      anchor="right"
      header={header}
      footer={isAdminViewing ? undefined : footerElement}
      onClose={onClose}
      contentSx={styles.drawerContainer}
    >
      <>
        {filtersElement}
        <Box className="section-list" id={SECTION_LIST_ID}>
          {currentTab === VuzDetailsTab.SPECIALITIES ? specialitiesListElement : facultiesListElement}
        </Box>
      </>
    </Drawer>
  );
};

export default VuzDetailsDrawerDesktop;
