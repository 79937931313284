import { FC } from 'react';

import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';
import { commonTheme } from 'portfolio3/styles/theme';

import { IProforientationTest } from '../../model/types';
import * as styles from './styles';

interface IProforientationTestCardProps {
  proforientationTest: IProforientationTest;
}

const ProforientationTestCard: FC<IProforientationTestCardProps> = ({ proforientationTest }) => {
  const { date, name, location, illustration } = proforientationTest;

  const isSmallScreen = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  return (
    <Box className="proforientation-test-card" sx={styles.root}>
      <Stack className="proforientation-test-card__content" gap="8px">
        <Typography variant="Paragraph MD/Regular" color={NeutralColors.light_neutrals_600}>
          {date}
        </Typography>
        <Typography variant="Paragraph LG/Semi Bold">{name}</Typography>
        <Typography variant="Paragraph MD/Regular">{location}</Typography>
      </Stack>
      {!isSmallScreen && <Box className="proforientation-test-card__image" sx={styles.image(illustration)}></Box>}
    </Box>
  );
};

export default ProforientationTestCard;
