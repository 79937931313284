import { FC, ReactNode, useContext, useLayoutEffect, useRef, useState } from 'react';

import { Box, Collapse, Fade, Stack, Typography } from '@mui/material';
import FunnelBlock from 'components/common/FunnelBlock';
import UnderlinedTabs from 'portfolio3/components/common/UnderlinedTabs';
import { FormEntityInfo } from 'portfolio3/components/dataEntry';
import { DrawerHeader } from 'portfolio3/components/drawers';
import { IInnerFormProps, IPortfolioEntryFormData } from 'portfolio3/features/dataEntryForm';
import { Button } from 'portfolio3/ui-kit/button';
import { Drawer, IDrawerBase } from 'portfolio3/ui-kit/Drawer';
import { mergeSx } from 'utils';

import * as commonStyles from '../../commonStyles';
import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';
import { defaultPortfolioEntryFormData } from '../../utils';
import PrimaryBlocks from '../PrimaryBlocks';
import SecondaryBlocks from '../SecondaryBlocks';
import * as styles from './styles';

interface IEmployeeFormProps {
  studentName: string;
  headerTags?: ReactNode;
}

const EmployeeForm: FC<IEmployeeFormProps & IInnerFormProps> = ({
  studentName,
  headerTags,

  entityActions,
  isSubmitDisabled,
  entityProperties,
  tabs,
  relatedObjectType,
  relatedObjectName,
  onSubmit,
  onClose,
}) => {
  const localDataEntryContextValue = useContext(LocalDataEntryFormContext);

  const {
    isOpen,
    formStep,
    formTab,
    onChangeFormTab,
    formData,
    onChangeFormData,
    isEmployeeFormRolled,
    onChangeEmployeeFormRolled,
    image,
  } = localDataEntryContextValue;

  const [localFormData, setLocalFormData] = useState(formData);
  const [fakeCollapseBoxHeight, setFakeCollapseBoxHeight] = useState(0);

  const entityInfoElementRef = useRef<HTMLElement>(null);
  const hiddenPrimaryBlocksRef = useRef<HTMLElement>(null);

  const entityInfo = entityProperties?.entityInfo;
  const hasTabs = tabs && tabs.length > 0;

  const entityInfoFadeDuration = 1000;
  const primaryBlocksRollDuration = 500;

  /**
   * Вызывается при открытии формы редактирования primary полей
   * Устанавливает локальную formData в текущее значение основной formData
   */
  useLayoutEffect(() => {
    if (isEmployeeFormRolled) {
      setLocalFormData(formData);
    }
  }, [formData, isEmployeeFormRolled]);

  /**
   * Вызывается при измениях локальной formData
   * Обновляет высоту фейк бокса
   */
  useLayoutEffect(() => {
    const height =
      Number(hiddenPrimaryBlocksRef.current?.getBoundingClientRect().height) -
      Number(entityInfoElementRef.current?.getBoundingClientRect().height);

    const adjustedHeight = isNaN(height) || height < 0 ? 0 : height;
    setFakeCollapseBoxHeight(adjustedHeight);
  }, [localFormData]);

  /**
   * Вызывается в момент закрытия формы редактирования primary полей
   * Устанавливает высоту фейк бокса в 0
   * Обновляет ссылку на объект в локальной formData, после завершения анимации закрытия
   *   (нужно для триггера эффекта по установке высоты при повторном открытии формы)
   */
  useLayoutEffect(() => {
    let timeout: NodeJS.Timeout;
    if (!isEmployeeFormRolled) {
      setFakeCollapseBoxHeight(0);
      // обновление через {...formData} - иначе не триггерится эффект на обновление высоты
      timeout = setTimeout(() => setLocalFormData({ ...formData }), primaryBlocksRollDuration);
    }

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmployeeFormRolled]);

  const handleEditorStartFromCategory = () => {
    const newStateOfForm = { ...defaultPortfolioEntryFormData, section: formData.categoryCode };
    onChangeFormData(newStateOfForm);
    onChangeEmployeeFormRolled(false);
  };

  const handleEditorClose = () => {
    onChangeEmployeeFormRolled(false);
  };

  const handleEditorCloseAndSave = () => {
    // оставить только измененные поля для primary блока
    const newStateOfForm: IPortfolioEntryFormData = {
      ...defaultPortfolioEntryFormData,
      categoryCode: localFormData.categoryCode,
      dataKind: localFormData.dataKind,
      typeCode: localFormData.typeCode,
      name: localFormData.name,
      reward: localFormData.reward,
      entityId: localFormData.entityId,
    };
    onChangeFormData(newStateOfForm);

    onChangeEmployeeFormRolled(false);
  };

  const overlayElement = <Box className="overlay" sx={styles.overlay} />;

  const entityInfoElement = entityInfo && (
    <Box ref={entityInfoElementRef} sx={{ marginTop: '16px' }}>
      <FormEntityInfo
        name={entityInfo.name ?? 'Другое'}
        attributes={entityInfo.attributes}
        actions={entityActions}
        tags={headerTags}
        relatedObjectType={relatedObjectType}
        relatedObjectName={relatedObjectName}
      />
    </Box>
  );

  const tabsElement = hasTabs && (
    <Box sx={{ position: 'relative' }}>
      {isEmployeeFormRolled && overlayElement}
      <UnderlinedTabs value={formTab} onChange={onChangeFormTab} sx={{ marginTop: '24px' }}>
        {tabs.map((tab) => (
          <UnderlinedTabs.Button key={tab.value} value={tab.value} content={tab.title} />
        ))}
      </UnderlinedTabs>
    </Box>
  );

  const headerTitleElement = (
    <Box>
      <Typography variant="Headings/H6">Ввод данных в портфолио</Typography>
      <Typography variant="Paragraph LG/Regular" marginTop="4px">
        {studentName}
      </Typography>
    </Box>
  );

  const header: IDrawerBase['header'] = (crossButton) => (
    <DrawerHeader
      title={headerTitleElement}
      backgroundImage={image ?? null}
      crossButton={crossButton}
      removeBottomPadding={hasTabs && formStep !== 'primary'}
    >
      {/* рендер primary контролов */}
      {formStep === 'primary' && (
        <FunnelBlock sx={mergeSx(commonStyles.formControlsColumn, { marginTop: '16px' })}>
          <PrimaryBlocks />
        </FunnelBlock>
      )}

      {formStep !== 'primary' && (
        <Box sx={{ position: 'relative' }}>
          {/* данные о сущности */}
          {entityInfo && (
            /* Устанавливаем первичный opacity 1 для предотвращения анимации при первом открытии формы */
            <Fade in={!isEmployeeFormRolled} timeout={entityInfoFadeDuration} style={{ opacity: 1 }}>
              <div>{entityInfoElement}</div>
            </Fade>
          )}

          {/* Фейк Collapse нужен для отображения анимации ролла */}
          {/* Проверка на высоту нужна для корректной анимации */}
          <Collapse in={isEmployeeFormRolled && fakeCollapseBoxHeight !== 0} timeout={primaryBlocksRollDuration}>
            <div
              style={{
                width: '100%',
                height: fakeCollapseBoxHeight,
              }}
            ></div>
          </Collapse>

          {/* Collapse с блоком primary контролов */}
          <Collapse
            in={isEmployeeFormRolled}
            timeout={primaryBlocksRollDuration}
            sx={{ position: 'absolute', top: 0, width: '100%' }}
          >
            <Fade in={isEmployeeFormRolled} timeout={primaryBlocksRollDuration}>
              <FunnelBlock ref={hiddenPrimaryBlocksRef}>
                <Box sx={commonStyles.formControlsColumn}>
                  {/* Подмена контекста для временной формы редактирования */}
                  <LocalDataEntryFormContext.Provider
                    value={{
                      ...localDataEntryContextValue,
                      formData: localFormData,
                      onChangeFormData: setLocalFormData,
                    }}
                  >
                    <PrimaryBlocks />
                  </LocalDataEntryFormContext.Provider>
                </Box>
                <Stack direction="row" justifyContent="space-between" mt="24px">
                  <Button variant="text" onClick={handleEditorStartFromCategory}>
                    Начать сначала
                  </Button>
                  <Stack direction="row" gap="16px">
                    <Button variant="secondary" onClick={handleEditorClose}>
                      Свернуть
                    </Button>
                    <Button variant="primary" onClick={handleEditorCloseAndSave}>
                      Сохранить
                    </Button>
                  </Stack>
                </Stack>
              </FunnelBlock>
            </Fade>
          </Collapse>
        </Box>
      )}

      {/* табы */}
      {hasTabs && formStep !== 'primary' && tabsElement}
    </DrawerHeader>
  );

  const employeeFooter = (
    <Box sx={styles.footer}>
      <Button variant="secondary" onClick={onClose}>
        Отмена
      </Button>
      <Button variant="primary" onClick={onSubmit} disabled={isSubmitDisabled}>
        Добавить в портфолио
      </Button>
    </Box>
  );

  return (
    <Drawer
      open={isOpen}
      isMobile={false}
      anchor="right"
      header={header}
      footer={formStep !== 'primary' ? employeeFooter : null}
      sx={commonStyles.drawer}
      onClose={onClose}
      contentSx={{ overflow: isEmployeeFormRolled ? 'hidden' : 'auto', position: 'relative' }}
    >
      {isEmployeeFormRolled && overlayElement}
      <SecondaryBlocks />
    </Drawer>
  );
};

export default EmployeeForm;
