import { FC } from 'react';

import { Stack, Typography } from '@mui/material';
import { IconInfoFilled } from 'icons';
import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

const VuzRecommendationWidgetListInfoBlock: FC = () => {
  const stackStyles: SxStyles = {
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',

    width: '100%',

    padding: '16px',
    marginBottom: '16px',
    backgroundColor: NeutralColors.light_neutrals_200,
    borderRadius: '8px',
  };
  return (
    <Stack sx={stackStyles}>
      <IconInfoFilled fill={NeutralColors.light_neutrals_600} sx={{ width: '20px' }} />
      <Typography color={NeutralColors.light_neutrals_900} variant="Paragraph MD/Regular">
        Данные представлены за 2023 год
      </Typography>
    </Stack>
  );
};

export default VuzRecommendationWidgetListInfoBlock;
