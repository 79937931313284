import { FC } from 'react';

import { Stack, Typography } from '@mui/material';

import CardPadding from '../CardPadding';
import { clickableTitleStyles } from '../utils';

interface ISelfDiagnosticCardProps {
  name: string;
  total: number;
  onView: () => void;
}

const SelfDiagnosticCard: FC<ISelfDiagnosticCardProps> = ({ name, total, onView }) => (
  <CardPadding withHorizontalPadding>
    <Stack className="self-diagnostic-card" gap="4px">
      <Typography component="button" variant="Paragraph MD/Semi Bold" sx={clickableTitleStyles} onClick={onView}>
        {name}
      </Typography>
      <Typography variant="Paragraph MD/Medium">Найдено диагностик: {total}</Typography>
    </Stack>
  </CardPadding>
);

export default SelfDiagnosticCard;
