import { SAVE_USER_THEME_SETTINGS, SET_USER_THEME_CODE, SET_USER_THEME_SETTINGS } from 'actions';
import { FAILURE, REQUEST, SUCCESS } from 'actions/utils';
import { IThemeSettings } from 'api/types';
import { defaultThemeSettings } from 'const';
import { connectReducers } from 'utils';

import { IReducers } from '..';
import genericReducers from '../genericReducer';

const LOCAL_STORAGE_THEME_SETTINGS_KEY = 'user_theme_settings';

export type ThemeSettingsState = {
  code?: number;
  settings: IThemeSettings;
};
type SetThemeSettingsAction = { payload: Partial<IThemeSettings> };
type SetThemeCodeAction = { payload: number };

const themeSettingsFromLocalStorage = localStorage.getItem(LOCAL_STORAGE_THEME_SETTINGS_KEY);
const initialState: ThemeSettingsState = {
  code: undefined,
  settings: themeSettingsFromLocalStorage ? JSON.parse(themeSettingsFromLocalStorage) : defaultThemeSettings,
};

const themeSettings: IReducers<ThemeSettingsState> = {
  [SAVE_USER_THEME_SETTINGS[REQUEST]]: genericReducers().createUpdate.request,
  [SAVE_USER_THEME_SETTINGS[SUCCESS]]: genericReducers().createUpdate.success,
  [SAVE_USER_THEME_SETTINGS[FAILURE]]: genericReducers().createUpdate.failure,

  [SET_USER_THEME_SETTINGS]: (state, action: SetThemeSettingsAction) => {
    const newState = { ...state, settings: { ...state.settings, ...action.payload } };
    localStorage.setItem(LOCAL_STORAGE_THEME_SETTINGS_KEY, JSON.stringify(newState.settings));

    return newState;
  },
  [SET_USER_THEME_CODE]: (state, action: SetThemeCodeAction) => {
    const newState = { ...state, code: action.payload };
    return newState;
  },
};

export default connectReducers(initialState, themeSettings);
