/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';

import { GET_PROJECT_FORMATS } from '../../actions/employees';

import {
  FAILURE,
  REQUEST,
  SUCCESS
} from '../../actions/utils';

import { ICommonResponse, IDictionaryItem } from '../../api/types';

import genericReducers from '../genericReducer';

import { IReducers } from '..';

export type ProjectFormatsState = ICommonResponse<IDictionaryItem>;

const initialState = {} as ICommonResponse<IDictionaryItem>;

const projectFormats: IReducers<ProjectFormatsState> = {
  [GET_PROJECT_FORMATS[REQUEST]]: genericReducers().get.request,
  [GET_PROJECT_FORMATS[SUCCESS]]: genericReducers().get.success,
  [GET_PROJECT_FORMATS[FAILURE]]: genericReducers().get.failure
};

export default connectReducers(initialState, projectFormats);
