import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const drawerVuzCard = (isMobile?: boolean): SxStyles => {
  const border = isMobile ? 'none' : `1px solid ${NeutralColors.light_neutrals_300}`;
  const boxShadow = isMobile ? 'none' : `0px 1px 2px rgba(41, 41, 64, 0.07)`;
  return {
    marginTop: isMobile ? '20px' : '16px',
    padding: isMobile ? 0 : '20px',

    border,
    borderRadius: isMobile ? 0 : '12px',

    boxShadow,
    backgroundColor: NeutralColors.light_neutrals_0,
  };
};
