/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { IconButton } from '@mui/material';

import { IPersonObject } from '../../../api/types';
import { IconArrowOpen } from '../../../icons';
import { getEntityHeaderText, getLinkedEntityIcon, getEntityColors } from '../../../utils';

import './index.scss';

interface ILinkedObjectProps {
  linkedObject: IPersonObject
  onOpenLinkedObjectCard: (linkedObject: IPersonObject) => void
}

const LinkedObject: React.FC<ILinkedObjectProps> = ({ linkedObject, onOpenLinkedObjectCard }) => {
  const objectColors = getEntityColors(linkedObject.categoryCode);

  const {
    levelProject, projectFormat, levelEvent, format, achievementActivityFormat
  } = linkedObject;

  const entityTypeCode = linkedObject?.typeCode || linkedObject?.type?.code || linkedObject?.rewardType?.code || linkedObject?.dataKind;

  const entityTypeValue = getEntityHeaderText(entityTypeCode);

  const objectFeatures = [
    entityTypeValue && { text: entityTypeValue, isColored: true },
    levelProject && { text: levelProject.value },
    projectFormat && { text: projectFormat.value },
    levelEvent && { text: levelEvent.value },
    format && { text: format.value },
    achievementActivityFormat && { text: achievementActivityFormat.value }
  ];

  return (
    <div className="linked-object">
      <div className="linked-object__indicator" style={{ backgroundColor: objectColors.background }}>
        {getLinkedEntityIcon(entityTypeCode)}
      </div>
      <div className="linked-object__info">
        <p className="linked-object__name">{linkedObject.name}</p>
        <div className="linked-object__features-container">
          {objectFeatures.filter((feature) => feature).map((feature) => (
            <p key={feature?.text} className="linked-object__feature" style={feature?.isColored ? { backgroundColor: objectColors.background, color: objectColors.text } : undefined}>{feature?.text}</p>
          ))}
        </div>
      </div>
      <IconButton
        className="linked-object__open-btn"
        disableTouchRipple
        onClick={() => onOpenLinkedObjectCard(linkedObject)}
        size="large">
        <IconArrowOpen />
      </IconButton>
    </div>
  );
};

export default LinkedObject;
