/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useState, useRef } from 'react';

import { FormControl, IconButton, Typography } from '@mui/material';

import { IDictionaryItem } from '../../../api/types';
import Toggle from 'portfolio3/ui-kit/Toggle';
import { MultipleSearchSelect } from '../../common/Select';
import { getSettingActivity } from './utils';
import { IconSelectArrow } from '../../../icons';
import { IVisibilityChildSetting } from '../../../containers/admin/VisibilitySettings/types';
import ReasonMessagePopper from './reasonMessagePopper';
import SecondaryChildSetting from './secondaryChildSetting';

import './index.scss';

interface IChildSettingProps {
  childSetting: IVisibilityChildSetting,
  parallelOptions: IDictionaryItem[],
  onChangeChildSetting: (childSetting: IVisibilityChildSetting, comment?: string, selectValue?: string, isActivityUnchanged?: boolean) => void,
  onChangeSecondaryChildSetting: (parentName: string, childSetting: IVisibilityChildSetting, comment?: string, selectValue?: string, isActivityUnchanged?: boolean) => void,
}

const ChildSettingBlock: React.FC<IChildSettingProps> = ({
  childSetting,
  parallelOptions,
  onChangeChildSetting,
  onChangeSecondaryChildSetting
}) => {
  const hasChildren = childSetting.children && childSetting.children.length > 0;
  const switchRef = useRef<HTMLDivElement>(null);
  const [isChildrenShown, setChildrenShown] = useState(true);
  const [isReasonPopperOpen, setReasonPopperOpen] = useState(false);
  const [parallelsValue, setParallelsValue] = useState(childSetting.selectValue);

  const parallelController = {
    handleChange: (searchString: string) => {
      if (parallelsValue !== searchString) {
        setParallelsValue(searchString);
      }
    }
  };

  const ChildIcon = childSetting.Icon;

  const toggleSettingChildren = () => {
    if (!hasChildren) return;
    setChildrenShown((prevstate) => !prevstate);
  };

  const handleSubmitMessagePopper = (comment?: string) => {
    setReasonPopperOpen(false);
    onChangeChildSetting(childSetting, comment);
  };

  const handleChangeSecondaryChildSetting = (secondaryChildSetting: IVisibilityChildSetting, comment?: string, selectValue?: string, isActivityUnchanged?: boolean) => {
    onChangeSecondaryChildSetting(childSetting.name, secondaryChildSetting, comment, selectValue, isActivityUnchanged);
  };

  const handleCloseParallelsSelect = () => {
    onChangeChildSetting(childSetting, undefined, parallelsValue, true);
  };

  return (
    <div>
      <div className="setting-block__child">
        <div className="setting-block__left-column">
          <div
            className="setting-block__opener"
            onClick={toggleSettingChildren}
          >
            {hasChildren && (
            <IconButton
              className={isChildrenShown ? 'setting-block__arrow' : 'setting-block__arrow setting-block__arrow--reversed'}
              size="large">
              <IconSelectArrow className="setting-block__arrow-icon" style={{ color: '#7B819B' }} />
            </IconButton>
            )}
            {ChildIcon ? <ChildIcon className="setting-block__child-icon" /> : null}
            <Typography variant="Paragraph LG/Semi Bold">{childSetting.name}</Typography>
          </div>
        </div>
        <div className="setting-block__right-column">
          <div className="setting-block__status-container" ref={switchRef}>
            <Toggle
              className="setting-block__switch"
              onChange={(e) => { if (childSetting.isActive) setReasonPopperOpen(true); else onChangeChildSetting(childSetting); }}
              checked={childSetting.isActive}
            />
            <Typography variant="Paragraph LG/Regular">{getSettingActivity(childSetting)}</Typography>
          </div>
          <FormControl className={`setting-block__control ${!childSetting.isActive ? 'setting-block__control--disabled' : ''}`}>
            <MultipleSearchSelect
              searchController={parallelController}
              value={childSetting.selectValue}
              options={parallelOptions}
              isNotClearable
              label="параллели"
              cutOutValue="параллель"
              placeholder="Выберите параллель ..."
              onClose={handleCloseParallelsSelect}
            />
          </FormControl>
        </div>
        <ReasonMessagePopper
          anchorElement={switchRef.current}
          isOpen={isReasonPopperOpen}
          onClose={() => setReasonPopperOpen(false)}
          onSubmit={handleSubmitMessagePopper}
        />
      </div>
      {isChildrenShown && childSetting.children?.map((secondaryChildSetting) => (
        <SecondaryChildSetting
          key={secondaryChildSetting.name}
          parallelOptions={parallelOptions}
          secondaryChildSetting={secondaryChildSetting}
          onChangeSecondaryChildSetting={handleChangeSecondaryChildSetting}
        />
      ))}
    </div>
  );
};

export default ChildSettingBlock;
