import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconSectionStudy: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      // eslint-disable-next-line max-len
      d="M18.652 5.278h-.791V3.063a.97.97 0 0 0-.21-.586.982.982 0 0 0-.526-.34 8.199 8.199 0 0 0-3.82.201A8.153 8.153 0 0 0 10 4.25a8.152 8.152 0 0 0-3.305-1.91 8.2 8.2 0 0 0-3.82-.203.983.983 0 0 0-.569.339.97.97 0 0 0-.222.62v2.183h-.792a.85.85 0 0 0-.574.276.837.837 0 0 0-.217.596v10.777a.835.835 0 0 0 .322.67.851.851 0 0 0 .73.155 14.754 14.754 0 0 1 8.178.204c.169.059.353.059.522 0 2.65-.82 5.473-.91 8.17-.26a.853.853 0 0 0 .98-.418.835.835 0 0 0 .096-.367V6.151a.835.835 0 0 0-.515-.806.853.853 0 0 0-.332-.067Zm-9.641 8.955a8.876 8.876 0 0 0-4.893-1.775V3.935a6.057 6.057 0 0 1 2.698.496c.848.37 1.6.928 2.195 1.633v8.169Zm6.87-1.815-.229.063a8.899 8.899 0 0 0-4.662 1.752v-8.17a6.045 6.045 0 0 1 2.201-1.607 6.09 6.09 0 0 1 2.69-.482v8.444Z"
      fill={props.fill || 'currentColor' || '#845EF7'}
    />
  </SvgIcon>
);

export default IconSectionStudy;
