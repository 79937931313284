import { Component, PropsWithChildren } from 'react';
import { connect } from 'react-redux';

import { boundaryErrorActions } from 'actions';
import { IResponseError, IUserContext } from 'api/types';
import ContextErrorPlug from 'components/common/ContextErrorPlug';
import { GlobalFooterContainer } from 'portfolio3/containers/footers';
import { GlobalHeaderContainer } from 'portfolio3/containers/headers';
import { IRootState } from 'reducers';
import { hasContextUserInfoErrorSelector } from 'selectors';

interface IMainErrorBoundaryProps extends PropsWithChildren<unknown> {
  linkMode: boolean | undefined;
  hasContextUserInfoError: boolean;
  currentUser: IUserContext;
  authError?: IResponseError;
  setMainBoundaryError: typeof boundaryErrorActions.setMainBoundaryError;
}

interface IMainErrorBoundaryState {
  hasRuntimeError: boolean;
}

/**
 * Компонент для обработки верхнеуровневых ошибок приложения, таких как:
 * - ошибки в рантайме
 * - ошибки метода /app/portfolio/users/context/ которые не были перехвачены ранее
 */
class MainErrorBoundary extends Component<IMainErrorBoundaryProps, IMainErrorBoundaryState> {
  constructor(props: IMainErrorBoundaryProps) {
    super(props);

    this.state = {
      hasRuntimeError: false,
    };
  }

  static getDerivedStateFromError(): IMainErrorBoundaryState {
    return { hasRuntimeError: true };
  }

  render(): React.ReactNode {
    const { linkMode, hasContextUserInfoError, currentUser, authError, setMainBoundaryError, children } = this.props;
    const { hasRuntimeError } = this.state;

    if (linkMode === undefined) {
      return children;
    }

    const hasFatalContextError = authError && !currentUser.data;

    const hasMainPortfolioError = hasRuntimeError || hasFatalContextError || hasContextUserInfoError;
    const hasLinkPortfolioError = hasRuntimeError;

    const hasError = linkMode ? hasLinkPortfolioError : hasMainPortfolioError;

    setMainBoundaryError(hasError);

    if (hasError) {
      return (
        <>
          <GlobalHeaderContainer isFullWidth />
          <ContextErrorPlug />
          <GlobalFooterContainer isFullWidth />
        </>
      );
    }

    return children;
  }
}

export default connect(
  (state: IRootState) => ({
    linkMode: state.linkMode.mode,
    currentUser: state.currentUser,
    authError: state.auth.error,
    hasContextUserInfoError: hasContextUserInfoErrorSelector(state),
  }),
  {
    setMainBoundaryError: boundaryErrorActions.setMainBoundaryError,
  },
)(MainErrorBoundary);
