import { createSelector } from 'reselect';

import {
  learningYearAveragePerformanceSelector,
  learningYearFinalPerformanceSelector,
} from './learningYearPerformanceSelector';

export const isPerformanceWidgetHasDataSelector = createSelector(
  [learningYearFinalPerformanceSelector, learningYearAveragePerformanceSelector],
  (finalPerformance, averagePerformance) => {
    const hasData = [finalPerformance.content, averagePerformance.content].some((performance) => {
      const hasLearningYears = performance.length > 0;
      const hasAnySubjects = performance.flatMap((p) => p.subjects).length > 0;

      return hasLearningYears || hasAnySubjects;
    });

    return hasData;
  },
);
