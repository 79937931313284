import { FC, useContext, useMemo } from 'react';
import { connect } from 'react-redux';

import { SectionCodes } from 'const';
import { useAppSelector } from 'hooks';
import { IRootState } from 'reducers';
import { EventKindState } from 'reducers/employee/eventKinds';
import { sectionRefArchiveFalseSelector } from 'selectors';

import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';
import { ActivityBlock } from '../../primaryBlocks';

interface ICulturePrimaryBlockProps {
  eventKinds: EventKindState;
}

const CulturePrimaryBlock: FC<ICulturePrimaryBlockProps> = ({ eventKinds }) => {
  const portfolioSections = useAppSelector(sectionRefArchiveFalseSelector);

  const { formData } = useContext(LocalDataEntryFormContext);

  // activity
  const activityTypeOptions = useMemo(() => {
    return portfolioSections.content.filter((section) => section.parentId === SectionCodes.cultureEvent);
  }, [portfolioSections.content]);
  const activityNameOptions = useMemo(() => {
    return eventKinds.content.filter((eventKind) => eventKind.categoryCode === formData.typeCode);
  }, [eventKinds.content, formData.typeCode]);

  return (
    <>
      {formData.dataKind === SectionCodes.cultureEvent && (
        <ActivityBlock
          typeOptions={activityTypeOptions}
          nameOptions={activityNameOptions}
          nameInputLabel="Вид"
          nameInputPlaceholder="Выберите вид мероприятия..."
        />
      )}
    </>
  );
};

export default connect((state: IRootState) => ({
  eventKinds: state.eventKinds,
}))(CulturePrimaryBlock);
