import { FC, PropsWithChildren, useContext, useRef } from 'react';
import { AriaButtonOptions } from 'react-aria';

import { InputAdornment } from '@mui/material';
import { useComponentDidUpdate } from 'hooks';
import { FakeInputBox } from 'portfolio3/ui-kit';
import { ClearInputButton } from 'portfolio3/ui-kit/inputButtons';

import { CalendarInputButton } from '../buttons';
import { DatePickerContext } from '../context';

interface IDateInputBoxProps {
  isOpen: boolean;
  isDisabled: boolean;
  calendarButtonProps: AriaButtonOptions<'button'>;
  hasValue: boolean;
  onClear?: () => void;
}

const DateInputBox: FC<IDateInputBoxProps & PropsWithChildren> = ({
  isOpen,
  isDisabled,
  calendarButtonProps,
  hasValue,
  onClear,
  children,
}) => {
  const openButtonRef = useRef<HTMLButtonElement>(null);
  const { renderMode, size } = useContext(DatePickerContext);

  // Нужно для имитации стандартного поведения react-aria:
  // когда после закрытия popover фокус остается на кнопке
  // это поведение не работает с кастомным popover компонентом
  useComponentDidUpdate(() => {
    if (!isOpen) {
      openButtonRef.current?.focus();
    }
  }, [isOpen]);

  return (
    <FakeInputBox
      renderMode={renderMode}
      size={size}
      className="date-input-box"
      disabled={isDisabled}
      sx={{ display: 'flex', justifyContent: 'space-between' }}
    >
      {children}

      <InputAdornment position="end">
        {onClear && hasValue && <ClearInputButton onClick={onClear} disabled={isDisabled} />}
        {/* почему-то disabled не наследуется из options */}
        <CalendarInputButton isOpen={isOpen} ariaOptions={{ ...calendarButtonProps, isDisabled }} ref={openButtonRef} />
      </InputAdornment>
    </FakeInputBox>
  );
};

export default DateInputBox;
