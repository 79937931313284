import { getAccentColor, NeutralColors } from 'portfolio3/styles';
import { getFocusStateStyles, resetButtonStyles } from 'portfolio3/ui-kit/utils';
import { SxStyles } from 'types';

export const teacherMissingButton: SxStyles = {
  ...resetButtonStyles,

  width: 'max-content',
  color: getAccentColor('indigo', '200'),
  cursor: 'pointer',

  '&:focus-visible': {
    ...getFocusStateStyles(),
  },
};

export const teacherMissingControlContainer: SxStyles = {
  display: 'flex',
  gap: '16px',
};

export const yearPickerContainer: SxStyles = {
  display: 'flex',
  gap: '8px',
};

export const yearPickerTooltipButton: SxStyles = {
  alignSelf: 'flex-end',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '40px',
  height: '40px',
};

export const tooltipIcon: SxStyles = {
  width: '24px',
  height: '24px',
  color: NeutralColors.light_neutrals_600,
};
