import { FC } from 'react';
import { DateValue } from 'react-aria';

import { CalendarDate } from '@internationalized/date';
import { MobileViewDrawer } from 'portfolio3/components/drawers';

import { YearPickerComponentProps } from '../localTypes';
import { DatePickerContext } from '../shared/context';
import DatePicker from '../shared/DatePicker';
import Popover from '../shared/Popover';
import { dateToCalendarDate, dateValueToDate, getDateFromYear } from '../utils';
import YearCalendar from './Calendar';

const YearPicker: FC<YearPickerComponentProps> = ({
  value,
  controller,
  disabled,
  minValue,
  maxValue,
  isMobile,
  renderMode,
  size,
}) => {
  let mappedValue: CalendarDate | null = null;

  if (value) {
    mappedValue = dateToCalendarDate(getDateFromYear(value));
  }

  // дефолтные значения min: now, max: 2050
  const localMinValue = minValue ?? new Date().getFullYear();
  const localMaxValue = maxValue ?? 2050;

  const mappedMinValue = dateToCalendarDate(getDateFromYear(localMinValue));
  const mappedMaxValue = dateToCalendarDate(getDateFromYear(localMaxValue));

  const handleChange = (date: DateValue) => {
    const mappedDate = dateValueToDate(date);
    const year = mappedDate?.getFullYear() ?? null;
    controller.handleChange(year);
  };

  return (
    <DatePickerContext.Provider
      value={{
        renderMode: renderMode ?? 'fixed',
        size: size ?? 'medium',
      }}
    >
      <DatePicker
        options={{
          value: mappedValue,
          onChange: handleChange,
          onBlur: () => controller.handleBlur?.(value),
          isDisabled: disabled,
          minValue: mappedMinValue,
          maxValue: mappedMaxValue,
        }}
        modifySegments={(segments) => [segments[segments.length - 1]]}
        onClear={controller.handleClear}
      >
        {(triggerRef, state, calendarProps) => {
          const calendarElement = <YearCalendar calendarOptions={calendarProps} />;

          if (isMobile) {
            return (
              <MobileViewDrawer title="Год" open={state.isOpen} onClose={state.close}>
                {calendarElement}
              </MobileViewDrawer>
            );
          }

          return (
            <Popover triggerRef={triggerRef} datePickerState={state}>
              {calendarElement}
            </Popover>
          );
        }}
      </DatePicker>
    </DatePickerContext.Provider>
  );
};

export default YearPicker;
