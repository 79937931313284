import { useAppSelector } from 'hooks';

const useLinkModeStudentName = (inputFirstName: string | undefined, inputLastName: string | undefined) => {
  const isLinkMode = useAppSelector((state) => state.linkMode.mode) ?? false;
  const isStudentDataVisible =
    useAppSelector((state) => state.linkVisibilitySettings.content?.display.studentData) ?? false;
  const isLinkStudentDataVisible = isLinkMode && isStudentDataVisible;

  const requiredFullName = inputFirstName && inputLastName ? `${inputFirstName} ${inputLastName}` : null;

  const firstName = !isLinkMode || isLinkStudentDataVisible ? inputFirstName ?? null : null;
  const lastName = !isLinkMode || isLinkStudentDataVisible ? inputLastName ?? null : null;
  const fullName = !isLinkMode || isLinkStudentDataVisible ? requiredFullName : 'Личные данные скрыты';

  return {
    firstName,
    lastName,
    fullName,
  };
};

export default useLinkModeStudentName;
