import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { BaseInputLabel, FormControl, NumberInput } from 'portfolio3/ui-kit';
import { InputController } from 'portfolio3/ui-kit/types';

import * as styles from './styles';

interface IPassingBallControlProps {
  minValue?: number;
  maxValue?: number;
  maxPossibleValue: number;
  minController: InputController;
  maxController: InputController;
  disabled?: boolean;
}

const PassingBallControl: FC<IPassingBallControlProps> = ({
  minValue,
  maxValue,
  maxPossibleValue,
  minController,
  maxController,
  disabled,
}) => {
  return (
    <FormControl
      renderMode="fixed"
      disabled={disabled}
      label={<BaseInputLabel>Проходной балл</BaseInputLabel>}
      control={
        <Box sx={styles.inputsContainer}>
          <FormControl
            renderMode="fixed"
            label={null}
            control={
              <NumberInput
                controller={minController}
                renderMode="fixed"
                size="medium"
                placeholder="0"
                min={0}
                max={maxValue ?? maxPossibleValue}
                value={minValue}
                withControls={false}
                sx={styles.input}
              />
            }
          />
          <Typography variant="Paragraph MD/Regular">–</Typography>
          <FormControl
            renderMode="fixed"
            label={null}
            control={
              <NumberInput
                controller={maxController}
                renderMode="fixed"
                size="medium"
                min={0}
                max={maxPossibleValue}
                placeholder={String(maxPossibleValue)}
                value={maxValue}
                withControls={false}
                sx={styles.input}
              />
            }
          />
        </Box>
      }
    />
  );
};

export default PassingBallControl;
