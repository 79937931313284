import { FC, useState } from 'react';

import { Box, useMediaQuery } from '@mui/material';
import { IDictionaryItem } from 'api/types';
import { IconHeight } from 'icons';
import { commonTheme } from 'portfolio3/styles/theme';
import { SearchInput } from 'portfolio3/ui-kit';
import { Button } from 'portfolio3/ui-kit/button';
import { Select } from 'portfolio3/ui-kit/selects';
import { InputController, SelectController } from 'portfolio3/ui-kit/types';
import { SxStyles } from 'types';

import SortingDrawer from '../SortingDrawer';

interface IDetailsDrawerFiltersProps {
  searchTerm: string;
  sortType: number;
  sortOptions: IDictionaryItem[];
  onChangeSearchTerm: (value: string) => void;
  onChangeSortType: (value: number) => void;
}

const root: SxStyles = {
  display: 'flex',
  gap: '16px',
};

const DetailsDrawerFilters: FC<IDetailsDrawerFiltersProps> = ({
  searchTerm,
  sortType,
  sortOptions,
  onChangeSearchTerm,
  onChangeSortType,
}) => {
  const isSmallScreen = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerState = (open: boolean) => () => setDrawerOpen(open);

  const searchTermController: InputController = {
    handleChange(value) {
      onChangeSearchTerm(value);
    },
  };

  const sortTypeController: SelectController = {
    handleChange(value) {
      onChangeSortType(Number(value));
    },
  };

  return (
    <Box className="details-drawer-filters" sx={root}>
      <SearchInput value={searchTerm} controller={searchTermController} renderMode="fixed" placeholder="Поиск..." />

      {isSmallScreen && (
        <>
          <Button variant="secondary" onlyIcon onClick={handleDrawerState(true)}>
            <IconHeight />
          </Button>
          <SortingDrawer
            isOpen={isDrawerOpen}
            value={sortType}
            options={sortOptions}
            onChange={onChangeSortType}
            onClose={handleDrawerState(false)}
          />
        </>
      )}

      {!isSmallScreen && (
        <Box sx={{ width: '236px', minWidth: '236px' }}>
          <Select
            value={sortType}
            options={sortOptions}
            controller={sortTypeController}
            inputRenderMode="fixed"
            inputSize="medium"
            startIcon={<IconHeight />}
          />
        </Box>
      )}
    </Box>
  );
};

export default DetailsDrawerFilters;
