/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';

import {
  GET_STUDENT_INTERESTS,
  DELETE_STUDENT_INTEREST
} from '../../actions';

import {
  FAILURE,
  INVALIDATE,
  REQUEST,
  SUCCESS
} from '../../actions/utils';

import { ICommonResponse, IInterestResponse } from '../../api/types';

import genericReducers from '../genericReducer';

import { IReducers } from '..';

export type StudentInterestsState = ICommonResponse<IInterestResponse>;

const initialState: StudentInterestsState = {
  content: [],
  loading: false
};

const studentInterests: IReducers<StudentInterestsState> = {
  [GET_STUDENT_INTERESTS[REQUEST]]: genericReducers().get.request,
  [GET_STUDENT_INTERESTS[SUCCESS]]: genericReducers().get.success,
  [GET_STUDENT_INTERESTS[FAILURE]]: genericReducers().get.failure,
  [GET_STUDENT_INTERESTS[INVALIDATE]]: genericReducers().get.invalidate(initialState),
  [DELETE_STUDENT_INTEREST[REQUEST]]: genericReducers().delete.request,
  [DELETE_STUDENT_INTEREST[SUCCESS]]: (state: StudentInterestsState) => ({
    ...state,
    loading: false
  }),
  [DELETE_STUDENT_INTEREST[FAILURE]]: genericReducers().delete.failure,
};

export default connectReducers(initialState, studentInterests);
