import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';
import { numberToPixelsString } from 'utils';

import { cellNestedRenderBox } from './classes';
import { CellDensity } from './types';

export const container: SxStyles = {
  border: '1px solid',
  borderColor: NeutralColors.light_neutrals_300,
  borderRadius: '8px',
  backgroundColor: NeutralColors.light_neutrals_0,
  overflow: 'hidden',
};

export const header: SxStyles = {
  paddingInline: '20px',
  borderBottom: '1px solid',
  borderColor: NeutralColors.light_neutrals_300,
};

export const table: SxStyles = {
  display: 'grid',
  width: '100%',
  borderCollapse: 'collapse',

  'thead, tbody, tr': {
    display: 'contents',
  },

  'tr:nth-child(even) td': {
    backgroundColor: NeutralColors.light_neutrals_100,
  },

  'tr:last-child td': {
    border: 'none',
  },
};

export const cell: SxStyles = {
  padding: 0,
};

export const dataCellInlinePadding: SxStyles = {
  '&:first-child': {
    paddingLeft: '20px',
  },

  '&:last-child': {
    paddingRight: '20px',
  },
};

export const headCellInlinePadding: SxStyles = {
  [`&:first-child .${cellNestedRenderBox}`]: {
    paddingLeft: '20px',
  },

  [`&:last-child .${cellNestedRenderBox}`]: {
    paddingRight: '20px',
  },
};

export const getCellRenderBoxStyles = (density: CellDensity): SxStyles => {
  let paddingBlockNumber = 0;

  if (density === 'small') paddingBlockNumber = 10;
  if (density === 'medium') paddingBlockNumber = 14;
  if (density === 'large') paddingBlockNumber = 16;
  if (typeof density === 'number') paddingBlockNumber = density;

  return {
    paddingBlock: numberToPixelsString(paddingBlockNumber),
    paddingInline: '12px',

    borderBottom: '1px solid',
    borderColor: NeutralColors.light_neutrals_300,
  };
};

export const headCell: SxStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',

  width: '100%',
  height: '100%',
};

export const sortIconsContainer: SxStyles = {
  position: 'relative',

  display: 'flex',
  alignItems: 'center',

  width: '16px',
  height: '24px',

  svg: {
    width: '16px',
    height: '16px',
    color: NeutralColors.light_neutrals_500,

    position: 'absolute',
  },

  'svg:first-child': {
    top: 0,
  },
  'svg:last-child': {
    bottom: 0,
  },
};

export const tableFooter: SxStyles = {
  gridColumn: '1/-1',

  borderTop: '1px solid',
  borderColor: NeutralColors.light_neutrals_300,
};
