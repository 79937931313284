import { FC, useContext, useMemo } from 'react';
import { connect } from 'react-redux';

import { IDictionaryItem } from 'api/types';
import { SectionCodes } from 'const';
import { useAppSelector } from 'hooks';
import { IRootState } from 'reducers';
import { AffilationKindState } from 'reducers/employee/affilationKinds';
import { EventKindState } from 'reducers/employee/eventKinds';
import { RewardKindState } from 'reducers/employee/rewardKinds';
import { createSelector } from 'reselect';
import { sectionRefArchiveFalseSelector } from 'selectors';

import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';
import { ActivityBlock, AffiliationBlock, OccupationBlock, RewardBlock } from '../../primaryBlocks';
import { filterPersonsObjects } from '../../selectors';

interface ICivilPrimaryBlockProps {
  affilationKinds: AffilationKindState;
  eventKinds: EventKindState;
  rewardKinds: RewardKindState;
  civilObjectsDictionary: IDictionaryItem[];
}

const CivilPrimaryBlock: FC<ICivilPrimaryBlockProps> = ({
  affilationKinds,
  eventKinds,
  rewardKinds,
  civilObjectsDictionary,
}) => {
  const portfolioSections = useAppSelector(sectionRefArchiveFalseSelector);

  const { studentType, onOpenEventForm, isMobile, formData } = useContext(LocalDataEntryFormContext);

  // affilation
  const affilationTypeOptions = useMemo(() => {
    return portfolioSections.content.filter((section) => section.parentId === SectionCodes.civilAffilation);
  }, [portfolioSections.content]);
  // TODO проверить
  const affilationNameOptions = useMemo(() => {
    return affilationKinds.content.filter(
      (affilationKind) => affilationKind.categoryCode === formData.typeCode && affilationKind.parentId,
    );
  }, [affilationKinds.content, formData.typeCode]);

  // occupation
  const occupationTypeOptions = useMemo(() => {
    return portfolioSections.content.filter((section) => section.parentId === SectionCodes.civilOccupation);
  }, [portfolioSections.content]);

  // activity
  const activityTypeOptions = useMemo(() => {
    return portfolioSections.content.filter((section) => section.parentId === SectionCodes.civilEvent);
  }, [portfolioSections.content]);
  const activityNameOptions = useMemo(() => {
    return eventKinds.content.filter((eventKind) => eventKind.categoryCode === formData.typeCode);
  }, [eventKinds.content, formData.typeCode]);

  // rewards
  const rewardTypeOptions = useMemo(() => {
    return portfolioSections.content.filter((section) => section.parentId === SectionCodes.civilReward);
  }, [portfolioSections.content]);
  const rewardItemOptions = civilObjectsDictionary;
  const rewardResultOptions = rewardKinds.content.filter((rewardKind) => rewardKind.categoryCode === formData.typeCode);

  const getAffiliationNameInputLabel = () => {
    if (formData.typeCode === SectionCodes.civilAffilationGroup) return 'Название объединения';
    if (formData.typeCode === SectionCodes.civilAffilationMovement)
      return isMobile ? 'Название движения' : 'Название общественного движения';
    return 'Название';
  };
  const getAffiliationNameInputPlaceholder = () => {
    if (formData.typeCode === SectionCodes.civilAffilationGroup) return 'Выберите объединение...';
    if (formData.typeCode === SectionCodes.civilAffilationMovement) return 'Выберите общественное движение...';
    return '';
  };

  const getActivityNameInputLabel = () => {
    if (formData.typeCode === SectionCodes.civilEventLocal) return 'Название мероприятия';
    if (formData.typeCode === SectionCodes.civilEventEcology) return 'Название конференции / фестиваля';
    if (formData.typeCode === SectionCodes.civilEventVolonteer) return 'Название конкурса / фестиваля';
    if (formData.typeCode === SectionCodes.civilEventPatriotic) return 'Название мероприятия';
    if (formData.typeCode === SectionCodes.civilEventQuest) return 'Название квеста / квиза';
    return 'Название';
  };
  const getActivityNameInputPlaceholder = () => {
    if (formData.typeCode === SectionCodes.civilEventLocal) return 'Выберите мероприятие...';
    if (formData.typeCode === SectionCodes.civilEventEcology)
      return studentType ? 'Выбери мероприятие...' : 'Выберите конференцию / фестиваль...';
    if (formData.typeCode === SectionCodes.civilEventVolonteer)
      return studentType ? 'Выбери мероприятие...' : 'Выберите конкурс / фестиваль...';
    if (formData.typeCode === SectionCodes.civilEventPatriotic)
      return studentType ? 'Выбери мероприятие...' : 'Выберите мероприятие...';
    if (formData.typeCode === SectionCodes.civilEventQuest)
      return studentType ? 'Выбери мероприятие...' : 'Выберите квест / квиз...';
    return 'Выберите название...';
  };

  const handleOpenEventForm = () => {
    onOpenEventForm(SectionCodes.civil, SectionCodes.civilEvent);
  };

  return (
    <>
      {formData.dataKind === SectionCodes.civilAffilation && (
        <AffiliationBlock
          typeOptions={affilationTypeOptions}
          nameOptions={affilationNameOptions}
          nameInputLabel={getAffiliationNameInputLabel()}
          nameInputPlaceholder={getAffiliationNameInputPlaceholder()}
        />
      )}
      {formData.dataKind === SectionCodes.civilOccupation && <OccupationBlock typeOptions={occupationTypeOptions} />}
      {formData.dataKind === SectionCodes.civilEvent && (
        <ActivityBlock
          typeOptions={activityTypeOptions}
          nameOptions={activityNameOptions}
          nameInputLabel={getActivityNameInputLabel()}
          nameInputPlaceholder={getActivityNameInputPlaceholder()}
          hasOtherNameOption
        />
      )}
      {formData.dataKind === SectionCodes.civilReward && (
        <RewardBlock
          shortType={formData.typeCode === SectionCodes.civilRewardStatus}
          isNameOptionActive={formData.typeCode === SectionCodes.civilRewardEvent}
          typeOptions={rewardTypeOptions}
          itemOptions={rewardItemOptions}
          resultOptions={rewardResultOptions}
          onOpenEventForm={handleOpenEventForm}
          nameInputLabel="Мероприятие"
          nameInputPlaceholder={
            studentType ? 'Выбери мероприятие, за которое была получена награда...' : 'Выберите мероприятие...'
          }
        />
      )}
    </>
  );
};

const civilObjectsSelector = createSelector([(state: IRootState) => state.personsObjects], (personsObjects) => {
  return filterPersonsObjects(personsObjects.content, [SectionCodes.civilEvent]);
});

export default connect((state: IRootState) => ({
  affilationKinds: state.affilationKinds,
  eventKinds: state.eventKinds,
  rewardKinds: state.rewardKinds,
  civilObjectsDictionary: civilObjectsSelector(state),
}))(CivilPrimaryBlock);
