/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconClose: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M13.6496 13.0001L18.6579 7.99175C19.114 7.53559 19.114 6.79828 18.6579 6.34212C18.2017 5.88596 17.4644 5.88596 17.0083 6.34212L12 11.3505L6.99171 6.34212C6.53556 5.88596 5.79826 5.88596 5.34211 6.34212C4.88596 6.79828 4.88596 7.53559 5.34211 7.99175L10.3504 13.0001L5.34211 18.0085C4.88596 18.4647 4.88596 19.202 5.34211 19.6582C5.5696 19.8857 5.86826 20 6.16691 20C6.46556 20 6.76422 19.8857 6.99171 19.6582L12 14.6498L17.0083 19.6582C17.2358 19.8857 17.5344 20 17.8331 20C18.1317 20 18.4304 19.8857 18.6579 19.6582C19.114 19.202 19.114 18.4647 18.6579 18.0085L13.6496 13.0001Z" fill="#B0B3C3" />
  </SvgIcon>
);

export default IconClose;
