import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const DigitalCurator: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="230" height="129" viewBox="0 0 230 129" xmlns="http://www.w3.org/2000/svg" {...props} fill="none">
    {/*eslint-disable max-len */}
    <path
      d="M42.82 61.474a.318.318 0 0 1-.135-.028c-.143-.055-.24-.178-.24-.315v-3.417h-5.54c-2.368 0-4.3-1.764-4.3-3.925v-2.47c0-2.162 1.932-3.925 4.3-3.925h38.19c2.368 0 4.3 1.763 4.3 3.924v2.47c0 2.162-1.932 3.926-4.3 3.926H46.88l-3.78 3.65a.382.382 0 0 1-.279.11ZM36.905 48.08c-1.954 0-3.548 1.454-3.548 3.238v2.47c0 1.785 1.594 3.24 3.548 3.24h5.915c.21 0 .376.15.376.343v2.895l3.247-3.136a.394.394 0 0 1 .27-.11h28.382c1.955 0 3.548-1.454 3.548-3.238v-2.47c0-1.784-1.593-3.24-3.548-3.24h-38.19v.008Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M60.492 51.662h-22.94c-.21 0-.376-.151-.376-.343 0-.193.165-.343.376-.343h22.94c.21 0 .375.15.375.343 0 .192-.173.343-.375.343ZM71.961 54.077h-34.41c-.21 0-.375-.151-.375-.343 0-.192.165-.343.376-.343h34.41c.21 0 .375.15.375.343 0 .192-.165.343-.376.343ZM100.749 80.687a.364.364 0 0 1-.27-.11l-3.781-3.65H68.474c-2.368 0-4.3-1.764-4.3-3.925v-2.47c0-2.162 1.932-3.926 4.3-3.926h38.191c2.367 0 4.299 1.764 4.299 3.925v2.47c0 2.162-1.932 3.925-4.299 3.925h-5.54v3.418c0 .137-.09.267-.24.315a.301.301 0 0 1-.136.028ZM68.474 67.293c-1.954 0-3.548 1.454-3.548 3.238v2.47c0 1.785 1.594 3.24 3.548 3.24h28.382a.38.38 0 0 1 .27.11l3.247 3.135V76.59c0-.192.166-.343.376-.343h5.916c1.954 0 3.547-1.454 3.547-3.239v-2.47c0-1.784-1.593-3.239-3.547-3.239h-38.19v-.006Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M106.026 70.875h-22.94c-.21 0-.376-.151-.376-.343 0-.193.165-.343.376-.343h22.94c.21 0 .375.15.375.343 0 .192-.172.343-.375.343ZM106.026 73.29H71.608c-.21 0-.376-.151-.376-.343 0-.193.166-.344.376-.344h34.41c.211 0 .376.151.376.344 0 .192-.165.343-.368.343Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M119.374 90.033a.391.391 0 0 1-.225-.069c-.166-.117-.203-.33-.075-.48a4.94 4.94 0 0 0 1.157-3.177V42.069c0-2.916-2.601-5.29-5.795-5.29H29.945c-3.195 0-5.795 2.374-5.795 5.29v44.238a4.96 4.96 0 0 0 1.082 3.08c.12.152.083.371-.09.48a.406.406 0 0 1-.526-.075c-.805-1.022-1.225-2.223-1.225-3.478V42.069c0-3.293 2.939-5.976 6.546-5.976h84.492c3.607 0 6.546 2.683 6.546 5.976v44.238c0 1.303-.451 2.545-1.308 3.588a.367.367 0 0 1-.293.138ZM120.427 106.679h-97.6c-1.165 0-2.232-.508-2.849-1.352-.57-.782-.684-1.729-.293-2.593l.324-.721a.39.39 0 0 1 .488-.185c.188.068.286.274.203.446l-.323.72c-.293.652-.21 1.359.218 1.949.48.659 1.315 1.05 2.225 1.05h97.599c.88 0 1.691-.371 2.18-.981.436-.556.556-1.235.331-1.874l-.346-.953c-.068-.179.045-.371.241-.433a.386.386 0 0 1 .473.22l.346.954c.308.851.15 1.756-.436 2.491-.624.789-1.669 1.262-2.781 1.262Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M120.427 103.591h-97.6c-1.165 0-2.232-.507-2.849-1.351-.57-.783-.684-1.73-.293-2.594l4.457-9.888a.376.376 0 0 1 .346-.213h95.172c.165 0 .308.096.353.234l3.623 10.059c.308.851.151 1.757-.436 2.491-.616.789-1.661 1.262-2.773 1.262Zm-95.683-13.36-4.36 9.676c-.293.651-.21 1.358.218 1.948.481.659 1.316 1.05 2.225 1.05h97.6c.879 0 1.691-.37 2.18-.981.435-.556.556-1.235.33-1.873l-3.54-9.82H24.744Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M117.233 95.371H26.022a.369.369 0 0 1-.308-.15.32.32 0 0 1-.038-.317l1.541-3.595c.06-.13.195-.22.353-.22h88.964c.18 0 .338.117.368.282l.699 3.595a.3.3 0 0 1-.083.282.411.411 0 0 1-.285.123Zm-90.662-.686h90.211l-.564-2.91h-88.4l-1.247 2.91ZM92.88 103.591H51.502c-.563 0-1.082-.247-1.39-.659-.286-.391-.339-.857-.143-1.283l1.887-4.192a.377.377 0 0 1 .345-.213H92.55c.165 0 .308.096.353.233l1.534 4.262c.15.418.075.871-.21 1.235-.302.391-.805.617-1.346.617Zm-40.423-5.66-1.797 3.979a.63.63 0 0 0 .076.645c.165.227.45.357.766.357h41.37c.3 0 .579-.124.744-.336a.623.623 0 0 0 .113-.618l-1.45-4.028H52.456ZM114.978 85.092H29.404c-.21 0-.376-.15-.376-.343V43.627c0-.192.166-.343.376-.343h85.581c.211 0 .376.15.376.343v41.115a.375.375 0 0 1-.383.35Zm-85.198-.686h84.829V43.97H29.78v40.436ZM71.623 41.857c-1.037 0-1.879-.769-1.879-1.716s.842-1.715 1.88-1.715c1.037 0 1.878.768 1.878 1.715 0 .947-.841 1.716-1.879 1.716Zm0-2.745c-.624 0-1.127.46-1.127 1.03 0 .569.503 1.028 1.127 1.028s1.128-.46 1.128-1.029c0-.57-.504-1.029-1.128-1.029ZM183.512 38.473a.317.317 0 0 1-.135-.027c-.143-.055-.241-.179-.241-.316v-1.832h-3.044c-1.458 0-2.646-1.084-2.646-2.415v-1.428c0-1.33 1.188-2.415 2.646-2.415h22.083c1.458 0 2.646 1.084 2.646 2.415v1.428c0 1.33-1.188 2.415-2.646 2.415h-16.25l-2.142 2.065a.379.379 0 0 1-.271.11Zm-3.42-7.747c-1.045 0-1.894.776-1.894 1.73v1.427c0 .953.849 1.729 1.894 1.729h3.42c.21 0 .376.15.376.343v1.31l1.601-1.55a.392.392 0 0 1 .27-.11h16.416c1.045 0 1.894-.775 1.894-1.73v-1.42c0-.953-.849-1.729-1.894-1.729h-22.083Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M200.033 34.301h-16.325c-.211 0-.376-.15-.376-.343 0-.192.165-.343.376-.343h16.325c.211 0 .376.151.376.343 0 .192-.165.343-.376.343ZM193.854 32.689h-12.793c-.21 0-.375-.151-.375-.343 0-.193.165-.344.375-.344h12.793c.211 0 .376.151.376.343 0 .193-.165.344-.376.344Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M168.742 70.675c-.21 0-.376-.15-.376-.343 0-.192.166-.343.376-.343l39.664-.082c2.961 0 5.366-2.196 5.366-4.9v-40.38c0-2.704-2.405-4.9-5.366-4.9h-70.353c-2.962 0-5.367 2.196-5.367 4.9v40.38c0 2.704 2.405 4.9 5.367 4.9h26.472c.211 0 .376.15.376.343 0 .192-.165.343-.376.343h-26.472c-3.375 0-6.118-2.505-6.118-5.585V24.626c0-3.08 2.743-5.585 6.118-5.585h70.353c3.375 0 6.118 2.505 6.118 5.585v40.382c0 3.08-2.743 5.585-6.118 5.585l-39.664.082Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M206.572 69.269H167.99c-.21 0-.375-.151-.375-.344 0-.192.165-.343.375-.343h38.582c2.961 0 5.366-2.195 5.366-4.899V25.951c0-2.704-2.405-4.9-5.366-4.9h-66.685c-2.962 0-5.367 2.196-5.367 4.9v37.732c0 2.704 2.405 4.9 5.367 4.9h24.135c.21 0 .376.15.376.343 0 .192-.166.343-.376.343h-24.135c-3.375 0-6.118-2.505-6.118-5.586V25.951c0-3.081 2.743-5.586 6.118-5.586h66.685c3.375 0 6.118 2.505 6.118 5.586v37.732c0 3.081-2.743 5.586-6.118 5.586Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M172.974 89.291c-.909 0-1.766-.521-2.082-1.352l-12.958-33.896a.318.318 0 0 1 .015-.261.352.352 0 0 1 .218-.172l3.194-1.015c.196-.062.414.034.482.213l13.048 34.13c.173.446.143.92-.083 1.345a1.885 1.885 0 0 1-1.097.898c-.248.07-.496.11-.737.11Zm-14.206-35.145 12.831 33.567c.263.693 1.097 1.057 1.849.817.3-.096.533-.289.669-.55a.985.985 0 0 0 .052-.823l-12.92-33.807-2.481.796Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M158.295 54.276a.355.355 0 0 1-.218-.069.35.35 0 0 1-.158-.288l.113-4.398c0-.144.105-.268.248-.316a.395.395 0 0 1 .413.103l3.082 3.383c.075.09.105.206.075.309-.03.11-.12.192-.241.233l-3.194 1.016a.258.258 0 0 1-.12.027Zm.458-3.822-.075 2.992 2.172-.693-2.097-2.299Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M174.62 69.035c-.21 0-.376-.15-.376-.343V41.534h-30.546v26.973c0 .192-.165.343-.376.343-.21 0-.376-.15-.376-.343V41.191c0-.192.166-.343.376-.343h31.298c.211 0 .376.15.376.343v27.501c0 .193-.173.344-.376.344ZM207.075 61.069h-24.804c-.21 0-.375-.151-.375-.343v-14.41c0-.192.165-.343.375-.343h24.804c.211 0 .376.151.376.343v14.41c0 .192-.165.343-.376.343Zm-24.428-.686h24.052V46.659h-24.052v13.724Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default DigitalCurator;
