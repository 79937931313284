import { FC, useContext } from 'react';

import { Box } from '@mui/material';
import { IDictionaryItem } from 'api/types';
import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

import { VuzRecommendationTab } from '../../const';
import { VuzRecommendationContext } from '../../context';
import { useFilterControls } from '../../hooks';

interface IMobileControlsProps {
  subjectOptions: IDictionaryItem[];
  specialityOptions: IDictionaryItem[];
}

const controlsDivider: SxStyles = {
  height: '1px',
  backgroundColor: NeutralColors.light_neutrals_300,
  marginBlock: '16px',
};

const MobileControls: FC<IMobileControlsProps> = ({ subjectOptions, specialityOptions }) => {
  const { currentTab } = useContext(VuzRecommendationContext);

  const {
    mobileSubjectsControl,
    mobileSpecialitiesControl,
    mobileTrainingTypeControl,
    mobileMilitaryTrainingTypeControl,
    mobileHigherBallToggleControl,
    passingBallControl,
  } = useFilterControls({
    subjectOptions,
    specialityOptions,
  });

  const giaTabControls = [
    mobileSubjectsControl,
    mobileSpecialitiesControl,
    mobileTrainingTypeControl,
    mobileMilitaryTrainingTypeControl,
    mobileHigherBallToggleControl,
  ];
  const subjectsTabControls = [
    mobileSubjectsControl,
    mobileSpecialitiesControl,
    mobileTrainingTypeControl,
    mobileMilitaryTrainingTypeControl,
    passingBallControl,
  ];

  const controls = [VuzRecommendationTab.REAL_GIA, VuzRecommendationTab.TRIAL_GIA].includes(currentTab)
    ? giaTabControls
    : subjectsTabControls;

  return (
    <Box className="mobile-controls">
      {controls.map((control, index) => {
        return (
          <>
            {index !== 0 && <Box sx={controlsDivider} />}
            {control}
          </>
        );
      })}
    </Box>
  );
};

export default MobileControls;
