import { FC } from 'react';

import { Box, DrawerProps } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';
import { Drawer, IDrawerBase } from 'portfolio3/ui-kit/Drawer';

import MobileDrawerHeader from '../MobileDrawerHeader';

interface IMobileViewDrawerProps {
  title: string;
  onExitBack?: () => void;
}

const MobileViewDrawer: FC<IMobileViewDrawerProps & IDrawerBase & DrawerProps> = ({
  title,
  onExitBack,
  children,
  ...drawerProps
}) => {
  const header: IDrawerBase['header'] = (crossButton) => (
    <MobileDrawerHeader
      title={title}
      overrideTitleColor={NeutralColors.light_neutrals_900}
      overrideBackgroundColor={NeutralColors.light_neutrals_0}
      crossButton={crossButton}
      onExitBack={onExitBack}
    />
  );

  return (
    <Drawer
      {...drawerProps}
      isMobile
      swipeable
      anchor="bottom"
      header={header}
      swipeableProps={{
        onClose: (event) => drawerProps.onClose?.(event, 'escapeKeyDown'),
      }}
    >
      <Box sx={{ paddingInline: '12px', paddingBottom: '12px' }}>{children}</Box>
    </Drawer>
  );
};

export default MobileViewDrawer;
