/* eslint-disable prettier/prettier */
/* eslint-disable */

// interest_head_kind_ref
export enum InterestHeadKindKeys {
  SPORT = 'sport',
  HEALTHY = 'healthy',
  TRAVEL = 'travel',
  MUSIC = 'music',
  LITERATURE = 'literature',
  CINEMA = 'cinema',
  PHOTO = 'photo',
  DANCE = 'dance',
  THEATRICAL = 'theatrical',
  DESIGN = 'design',
  CULTURAL_LEISURE = 'culturalLeisure',
  FASHION = 'fashion',
  COLLECTION = 'collection',
  TRANSPORT = 'transport',
  CHARACTER = 'character',
  HISTORY = 'history',
  SPACE = 'space',
  LANGUAGE = 'language',
  INFORMATION = 'information',
  SCIENCE = 'science',
  GAME = 'game',
  ANIMAL = 'animals',
  COOKERY = 'cookery',
  ACTIVITY = 'activity',
  SOCIAL_MEDIA = 'socialMedia',
}

export const InterestHeadKindCodes: Record<InterestHeadKindKeys, number> = {
  [InterestHeadKindKeys.SPORT]: 1,
  [InterestHeadKindKeys.HEALTHY]: 2,
  [InterestHeadKindKeys.TRAVEL]: 3,
  [InterestHeadKindKeys.MUSIC]: 4,
  [InterestHeadKindKeys.LITERATURE]: 5,
  [InterestHeadKindKeys.CINEMA]: 6,
  [InterestHeadKindKeys.PHOTO]: 7,
  [InterestHeadKindKeys.DANCE]: 8,
  [InterestHeadKindKeys.THEATRICAL]: 9,
  [InterestHeadKindKeys.DESIGN]: 10,
  [InterestHeadKindKeys.CULTURAL_LEISURE]: 11,
  [InterestHeadKindKeys.FASHION]: 12,
  [InterestHeadKindKeys.COLLECTION]: 13,
  [InterestHeadKindKeys.TRANSPORT]: 14,
  [InterestHeadKindKeys.CHARACTER]: 15,
  [InterestHeadKindKeys.HISTORY]: 16,
  [InterestHeadKindKeys.SPACE]: 17,
  [InterestHeadKindKeys.LANGUAGE]: 18,
  [InterestHeadKindKeys.INFORMATION]: 19,
  [InterestHeadKindKeys.SCIENCE]: 20,
  [InterestHeadKindKeys.GAME]: 21,
  [InterestHeadKindKeys.ANIMAL]: 22,
  [InterestHeadKindKeys.COOKERY]: 23,
  [InterestHeadKindKeys.ACTIVITY]: 24,
  [InterestHeadKindKeys.SOCIAL_MEDIA]: 25,
};

export const InterestHeadKindsPath: Record<InterestHeadKindKeys, string> = {
  [InterestHeadKindKeys.SPORT]: 'sport',
  [InterestHeadKindKeys.HEALTHY]: 'healthy',
  [InterestHeadKindKeys.TRAVEL]: 'travel',
  [InterestHeadKindKeys.MUSIC]: 'music',
  [InterestHeadKindKeys.LITERATURE]: 'literature',
  [InterestHeadKindKeys.CINEMA]: 'cinema',
  [InterestHeadKindKeys.PHOTO]: 'photo',
  [InterestHeadKindKeys.DANCE]: 'dance',
  [InterestHeadKindKeys.THEATRICAL]: 'theatrical',
  [InterestHeadKindKeys.DESIGN]: 'design',
  [InterestHeadKindKeys.CULTURAL_LEISURE]: 'cultural',
  [InterestHeadKindKeys.FASHION]: 'fashion',
  [InterestHeadKindKeys.COLLECTION]: 'collection',
  [InterestHeadKindKeys.TRANSPORT]: 'transport',
  [InterestHeadKindKeys.CHARACTER]: 'character',
  [InterestHeadKindKeys.HISTORY]: 'history',
  [InterestHeadKindKeys.SPACE]: 'space',
  [InterestHeadKindKeys.LANGUAGE]: 'language',
  [InterestHeadKindKeys.INFORMATION]: 'information',
  [InterestHeadKindKeys.SCIENCE]: 'science',
  [InterestHeadKindKeys.GAME]: 'game',
  [InterestHeadKindKeys.ANIMAL]: 'animals',
  [InterestHeadKindKeys.COOKERY]: 'cookery',
  [InterestHeadKindKeys.ACTIVITY]: 'activity',
  [InterestHeadKindKeys.SOCIAL_MEDIA]: 'social',
};

// interest_group_kind_ref
export enum InterestGroupKindKeys {
  /** Зимний спорт */
  WINTER_SPORT = 'winterSport',
  /** Водный спорт */
  WATER_SPORT = 'waterSport',
  /** Легкая атлетика */
  ATHLETICS = 'athletics',
  /** Тяжелая атлетика */
  WEIGHTLIFTING = 'weightlifting',
  /** Борьба */
  FIGHT = 'fight',
  /** Командный */
  TEAM_SPORT = 'teamSport',
  /** Одиночный спорт */
  SINGLES_SPORT = 'singlesSport',
  /** Управление транспортом */
  TRANSPORT_MANAGEMENT = 'transportManagement',
  /** Стрельба */
  SHOOTING = 'shooting',
  /** Интеллектуальный спорт */
  INTELLECTUAL_SPORT = 'intellectualSport',
  /** Полеты */
  FLIGHT = 'flight',
  /** Другое */
  OTHER = 'other',
  /** ЗОЖ */
  HEALTHY_LIFESTYLE = 'healthyLifestyle',
  /** Активный образ жизни */
  ACTIVE_LIFESTYLE = 'activeLifestyle',
  /** Туризм */
  TOURISM = 'tourism',
  /** Путешествия */
  TRAVEL = 'travel',
  /** Музыкальные инструменты */
  MUSICAL_INSTRUMENTS = 'musicalInstruments',
  /** Вокал */
  VOCALS = 'vocals',
  /** Музыкальные жанры */
  MUSIC_GENRES = 'musicGenres',
  /** Фотографии людей */
  PEOPLE_PHOTOS = 'peoplePhotos',
  /** Фотографии природы и объектов */
  NATURE_PHOTOS = 'naturePhotos',
  /** Комбинированные фотографии */
  COMBINED_PHOTOS = 'combinedPhotos',
  /** Учреждения культуры */
  CULTURAL_INSTITUTIONS = 'culturalInstitutions',
  /** Театральное творчество */
  THEATRICAL_CREATIVITY = 'theatricalCreativity',
  /** Цирковое искусство */
  CIRCUS_ART = 'circusArt',
  /** Режиссура и КВН */
  DIRECTING = 'directing',
  /** Изобразительное искусство */
  ART = 'art',
  /** Декоративно-прикладное творчество */
  DECORATIVE_ART = 'decorativeArt',
  /** Дизайн */
  DESIGN = 'design',
  /** Культурные мероприятия */
  CULTURAL_EVENTS = 'culturalEvents',
  /** Мода и одежда */
  FASHION = 'fashion',
  /** Макияж */
  MAKEUP = 'makeup',
  /** Образ */
  FASHION_IMAGE = 'fashionImage',
  /** Украшения */
  FASHION_DECORATIONS = 'fashionDecorations',
  /** Монеты, денежные знаки, ценные бумаги */
  COINS = 'coins',
  /** Военная атрибутика */
  MILITARY = 'military',
  /** Почтовая и печатная продукция */
  POSTAL_PRODUCTS = 'postalProducts',
  /** Предметы искусства, антиквариата */
  ANTIQUES = 'antiques',
  /** Аудиовизуальная информация */
  AUDIOVISUAL = 'audiovisual',
  /** Предметы, связанные с играми, персонажами */
  GAME_SUBJECTS = 'gameSubjects',
  /** Предметы быта */
  HOUSEWARE = 'houseware',
  /** Техника */
  TECHNICS = 'technics',
  /** Природные объекты */
  NATURE_SUBJECTS = 'natureSubjects',
  /** Модели */
  MODELS = 'models',
  /** Транспорт */
  TRANSPORT = 'transport',
  /** Исторические субъекты */
  HISTORICAL_SUBJECTS = 'historicalSubjects',
  /** Естественные науки */
  NATURAL_SCIENCE = 'naturalScience',
  /** Технические науки */
  ENGINEERING = 'engineering',
  /** Медицинские науки */
  MEDICINE = 'medicine',
  /** Сельско-хозяйственные науки */
  AGRICULTURAL_SCIENCE = 'agriculturalScience',
  /** Гуманитарные и социальные науки */
  HUMANITIES_SCIENCE = 'humanitiesScience',
  /** Животные */
  ANIMAL = 'animals',
  /** Растения */
  PLANTS = 'plants',
  /** Флористика */
  FLORISTRY = 'floristry',
  /** Оказание помощи */
  HELP = 'help',
  /** Школьная/районная гражданская активность */
  CIVIC_ENGAGEMENT = 'civicEngagement',
  /** Общественная деятельность */
  SOCIAL_ACTIVITY = 'socialActivity'
}

export const InterestGroupKindCodes: Record<InterestGroupKindKeys, number> = {
  [InterestGroupKindKeys.WINTER_SPORT]: 1,
  [InterestGroupKindKeys.WATER_SPORT]: 2,
  [InterestGroupKindKeys.ATHLETICS]: 3,
  [InterestGroupKindKeys.WEIGHTLIFTING]: 4,
  [InterestGroupKindKeys.FIGHT]: 5,
  [InterestGroupKindKeys.TEAM_SPORT]: 6,
  [InterestGroupKindKeys.SINGLES_SPORT]: 7,
  [InterestGroupKindKeys.TRANSPORT_MANAGEMENT]: 8,
  [InterestGroupKindKeys.SHOOTING]: 9,
  [InterestGroupKindKeys.INTELLECTUAL_SPORT]: 10,
  [InterestGroupKindKeys.FLIGHT]: 11,
  [InterestGroupKindKeys.OTHER]: 12,
  [InterestGroupKindKeys.HEALTHY_LIFESTYLE]: 13,
  [InterestGroupKindKeys.ACTIVE_LIFESTYLE]: 14,
  [InterestGroupKindKeys.TOURISM]: 15,
  [InterestGroupKindKeys.TRAVEL]: 16,
  [InterestGroupKindKeys.MUSICAL_INSTRUMENTS]: 17,
  [InterestGroupKindKeys.VOCALS]: 18,
  [InterestGroupKindKeys.MUSIC_GENRES]: 19,
  [InterestGroupKindKeys.PEOPLE_PHOTOS]: 20,
  [InterestGroupKindKeys.NATURE_PHOTOS]: 21,
  [InterestGroupKindKeys.COMBINED_PHOTOS]: 22,
  [InterestGroupKindKeys.CULTURAL_INSTITUTIONS]: 23,
  [InterestGroupKindKeys.THEATRICAL_CREATIVITY]: 24,
  [InterestGroupKindKeys.CIRCUS_ART]: 25,
  [InterestGroupKindKeys.DIRECTING]: 26,
  [InterestGroupKindKeys.ART]: 27,
  [InterestGroupKindKeys.DECORATIVE_ART]: 28,
  [InterestGroupKindKeys.DESIGN]: 29,
  [InterestGroupKindKeys.CULTURAL_EVENTS]: 30,
  [InterestGroupKindKeys.FASHION]: 31,
  [InterestGroupKindKeys.MAKEUP]: 32,
  [InterestGroupKindKeys.FASHION_IMAGE]: 33,
  [InterestGroupKindKeys.FASHION_DECORATIONS]: 34,
  [InterestGroupKindKeys.COINS]: 35,
  [InterestGroupKindKeys.MILITARY]: 36,
  [InterestGroupKindKeys.POSTAL_PRODUCTS]: 37,
  [InterestGroupKindKeys.ANTIQUES]: 38,
  [InterestGroupKindKeys.AUDIOVISUAL]: 39,
  [InterestGroupKindKeys.GAME_SUBJECTS]: 40,
  [InterestGroupKindKeys.HOUSEWARE]: 41,
  [InterestGroupKindKeys.TECHNICS]: 42,
  [InterestGroupKindKeys.NATURE_SUBJECTS]: 43,
  [InterestGroupKindKeys.MODELS]: 44,
  [InterestGroupKindKeys.TRANSPORT]: 45,
  [InterestGroupKindKeys.HISTORICAL_SUBJECTS]: 46,
  [InterestGroupKindKeys.NATURAL_SCIENCE]: 47,
  [InterestGroupKindKeys.ENGINEERING]: 48,
  [InterestGroupKindKeys.MEDICINE]: 49,
  [InterestGroupKindKeys.AGRICULTURAL_SCIENCE]: 50,
  [InterestGroupKindKeys.HUMANITIES_SCIENCE]: 51,
  [InterestGroupKindKeys.ANIMAL]: 52,
  [InterestGroupKindKeys.PLANTS]: 53,
  [InterestGroupKindKeys.FLORISTRY]: 54,
  [InterestGroupKindKeys.HELP]: 55,
  [InterestGroupKindKeys.CIVIC_ENGAGEMENT]: 56,
  [InterestGroupKindKeys.SOCIAL_ACTIVITY]: 57
};
