import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconBarChart: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M12 4c-.55 0-1 .45-1 1v15c0 .55.45 1 1 1s1-.45 1-1V5c0-.55-.45-1-1-1Zm7 8c-.55 0-1 .45-1 1v7c0 .55.45 1 1 1s1-.45 1-1v-7c0-.55-.45-1-1-1ZM4 9c0-.55.45-1 1-1s1 .45 1 1v11c0 .55-.45 1-1 1s-1-.45-1-1V9Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconBarChart;
