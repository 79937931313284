import { FC, useRef, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { Box, Menu } from '@mui/material';
import { useHiddenHorizontalList } from 'hooks';
import { IconMoreHorizontal } from 'icons';
import { MenuDivider } from 'portfolio3/ui-kit';

import { IPortfolioNavigationCommonProps, ISectionNavigationLink } from '../types';
import HiddenNavigationButton from './HiddenButton';
import * as styles from './styles';
import VisibleNavigationButton from './VisibleButton';

type PortfolioNavigationProps = IPortfolioNavigationCommonProps;

const PortfolioNavigation: FC<PortfolioNavigationProps> = ({ primaryNavigation, secondaryNavigation }) => {
  const [isHiddenNavigationsOpen, setIsHiddenNavigationsOpen] = useState(false);
  const navigationContainerRef = useRef<HTMLDivElement>(null);
  const buttonMoreRef = useRef<HTMLButtonElement>(null);
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  const combinedNavigation = [...primaryNavigation, ...secondaryNavigation];
  const totalElements = combinedNavigation.length;

  const elementsGap = 2;
  const { visibleElementsCount } = useHiddenHorizontalList({
    containerRef: navigationContainerRef,
    buttonMoreRef,
    shouldHideButtonMore: true,
    elementsGap,
    extraPaddings: 16,
    allElementsCount: totalElements,
    hiddenElementClassName: 'visually-hidden',
    additionalDeps: [match],
    onAllElementsVisible: () => setIsHiddenNavigationsOpen(false),
  });

  const handleOpenMoreMenu = () => {
    setIsHiddenNavigationsOpen(true);
  };

  const handleCloseMoreMenu = () => {
    setIsHiddenNavigationsOpen(false);
  };

  const handleClickNavigation = (to: string) => {
    return () => {
      handleCloseMoreMenu();
      history.push(to);
    };
  };

  const mapNavigationButton = (navigation: ISectionNavigationLink, isHidden?: boolean) => {
    const isActive = location.pathname.includes(navigation.to);
    return (
      <VisibleNavigationButton
        name={navigation.name}
        icon={navigation.icon}
        key={navigation.name}
        isActive={isActive}
        onClick={handleClickNavigation(`${match.url}${navigation.to}`)}
        tabIndex={isHidden ? -1 : undefined}
      />
    );
  };

  const mapMenuNavigationButton = (navigation: ISectionNavigationLink) => {
    const isSecondaryNavigation =
      secondaryNavigation.find(
        (secondaryNavigation) => secondaryNavigation.sectionTypeCode === navigation.sectionTypeCode,
      ) !== undefined;

    const isFirstSecondaryNavigation = navigation.sectionTypeCode === secondaryNavigation[0].sectionTypeCode;
    const hiddenElements = totalElements - visibleElementsCount;
    const isDividerShown = isFirstSecondaryNavigation && hiddenElements > secondaryNavigation.length;

    const isActive = location.pathname.includes(navigation.to);

    return (
      <>
        {isDividerShown && <MenuDivider />}
        <HiddenNavigationButton
          name={navigation.name}
          icon={isSecondaryNavigation ? navigation.icon : undefined}
          key={navigation.name}
          isActive={isActive}
          onClick={handleClickNavigation(`${match.url}${navigation.to}`)}
        />
      </>
    );
  };

  return (
    <Box sx={styles.container} ref={navigationContainerRef}>
      {combinedNavigation.map((navigation) => mapNavigationButton(navigation))}
      <VisibleNavigationButton
        name="Еще"
        icon={IconMoreHorizontal}
        key="Еще"
        ref={buttonMoreRef}
        isActive={false}
        onClick={handleOpenMoreMenu}
        id="navigation-button-more"
        aria-haspopup="true"
        aria-controls={isHiddenNavigationsOpen ? 'navigation-menu-more' : undefined}
        aria-expanded={isHiddenNavigationsOpen ? 'true' : undefined}
        tabIndex={visibleElementsCount === combinedNavigation.length ? -1 : 0}
      />
      <Menu
        sx={styles.menu}
        anchorEl={buttonMoreRef.current}
        autoFocus
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={isHiddenNavigationsOpen}
        onClose={handleCloseMoreMenu}
        id="navigation-menu-more"
        MenuListProps={{
          'aria-labelledby': 'button-more',
        }}
      >
        {combinedNavigation.slice(visibleElementsCount).map((navigation) => mapMenuNavigationButton(navigation))}
      </Menu>
    </Box>
  );
};

export default PortfolioNavigation;
