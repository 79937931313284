import { ThemeBackgroundKeys, ThemeCategoryKeys, ThemeCoverKeys, ThemePatternKeys } from 'const';
import { IThemeCategory } from 'types';

const strictBackgrounds = [
  ThemeBackgroundKeys.NEUTRAL,
  ThemeBackgroundKeys.INDIGO,
  ThemeBackgroundKeys.GRAPE,
  ThemeBackgroundKeys.ORANGE,
  ThemeBackgroundKeys.GREEN,
  ThemeBackgroundKeys.CYAN,
];

const patternBackgrounds = [
  ThemeBackgroundKeys.GRADIENT_COSMO,
  ThemeBackgroundKeys.GRADIENT_PURPLE,
  ThemeBackgroundKeys.GRADIENT_GREEN,
  ThemeBackgroundKeys.GRADIENT_BLUE,
  ThemeBackgroundKeys.GRADIENT_VIOLET,
  ThemeBackgroundKeys.GRADIENT_YELLOW,
];

const strictCovers = [
  ThemeCoverKeys.ABSTRACT,
  ThemeCoverKeys.ABSTRACT1,
  ThemeCoverKeys.ABSTRACT2,
  ThemeCoverKeys.BLUE_POINTS,
  ThemeCoverKeys.CIAN,
  ThemeCoverKeys.COSMOS,
  ThemeCoverKeys.GREEN_POINTS,
  ThemeCoverKeys.LINE_BLUE,
  ThemeCoverKeys.LINE_PINK,
  ThemeCoverKeys.NEON,
  ThemeCoverKeys.ORANGE,
  ThemeCoverKeys.SEA,
  ThemeCoverKeys.WATERCOLOR,
  ThemeCoverKeys.WATERCOLOR2,
  ThemeCoverKeys.WATERCOLOR3,
];

const brightCovers = [
  ThemeCoverKeys.BRIGHT_SCIENCE,
  ThemeCoverKeys.BRIGHT_ZOZH,
  ThemeCoverKeys.BRIGHT_IT,
  ThemeCoverKeys.BRIGHT_COSMOS,
  ThemeCoverKeys.BRIGHT_CORGI,
  ThemeCoverKeys.BRIGHT_BUTTERFLY,
  ThemeCoverKeys.BRIGHT_UNICORN,
];

const snowCovers = [
  ThemeCoverKeys.SNOW_RED_HAIRED_GIRL,
  ThemeCoverKeys.SNOW_PRESENTS,
  ThemeCoverKeys.SNOW_HOME,
  ThemeCoverKeys.SNOW_HOME2,
];

const patterns = Object.values(ThemePatternKeys);

/**
 * Категории настроек.
 * При добавлении новой категории требуется добавить сюда новый объект и заполнить его группы названиями настроек
 */
export const strictCategory: IThemeCategory = {
  name: ThemeCategoryKeys.STRICT,
  background: strictBackgrounds,
  cover: strictCovers,
};

export const patternCategory: IThemeCategory = {
  name: ThemeCategoryKeys.PATTERN,
  background: patternBackgrounds,
  pattern: patterns,
  cover: strictCovers,
};

export const brightCategory: IThemeCategory = {
  name: ThemeCategoryKeys.BRIGHT,
  background: [ThemeBackgroundKeys.BRIGHT_BACKGROUND],
  cover: brightCovers,
};

export const snowCategory: IThemeCategory = {
  name: ThemeCategoryKeys.SNOW,
  background: [ThemeBackgroundKeys.SNOW_BACKGROUND],
  cover: snowCovers,
};
