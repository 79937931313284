import { FC, PropsWithChildren } from 'react';

import { Box } from '@mui/material';

interface IGridLayoutProps {
  gap: string;
}

const GridLayout: FC<IGridLayoutProps & PropsWithChildren> = ({ gap, children }) => {
  return (
    <Box className="grid-layout" sx={{ display: 'flex', flexWrap: 'wrap', gap }}>
      {children}
    </Box>
  );
};

export default GridLayout;
