import { FC, MouseEvent, useRef } from 'react';

import { MenuProps } from '@mui/material';
import { IconPlus } from 'icons/edit';
import { Button } from 'portfolio3/ui-kit/button';
import { BaseMenu } from 'portfolio3/ui-kit/menu';

import { IModalSelectBaseProps, ModalSelectBase } from '../../bases';
import { useSelectMenuList } from '../../hooks';
import { ISelectItem, ISelectStateController } from '../../types';

interface ISelectProps
  extends Omit<IModalSelectBaseProps<MenuProps>, 'ModalComponent' | 'ModalComponentProps' | 'children'> {
  options?: ISelectItem[];
  // (categoryCode: number, dataKind: number) => void
  // eslint-disable-next-line @typescript-eslint/ban-types
  onOpenEventForm: Function;
}

const EventSelect: FC<ISelectProps> = ({ options, onOpenEventForm, ...props }) => {
  const TypedSelect = ModalSelectBase<MenuProps>;

  const menuList = useSelectMenuList({ options: options ?? [], shouldShowEmptyListPlaceholder: false });

  const stateRef = useRef<ISelectStateController>(null);

  const handleOpenEventForm = (event: MouseEvent<HTMLButtonElement>) => {
    props.onClose?.(event);
    onOpenEventForm();
    stateRef.current?.setOpen(false);
  };

  const renderValue = (value: string | undefined) => {
    return options?.find((option) => option.code === Number(value))?.value;
  };

  return (
    <TypedSelect
      {...props}
      stateController={stateRef}
      ModalComponent={BaseMenu}
      ModalComponentProps={{}}
      renderValue={renderValue}
    >
      {menuList}
      <Button
        key="button"
        iconLeft={<IconPlus />}
        variant="text"
        onClick={handleOpenEventForm}
        sx={{ width: '100%', justifyContent: 'flex-start' }}
      >
        Добавить новое мероприятие
      </Button>
    </TypedSelect>
  );
};

export default EventSelect;
