import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { eisDopRecommendationsDrawerActions } from 'actions';
import { Slide, Slider } from 'pure-react-carousel';
import { getNumeral } from 'utils';

import { IEisDopRecommendationGroup } from '../../../../../types';
import Carousel from '../../../../common/Carousel';
import { GroupRecommendation } from '../recommendation';

import './index.scss';

interface IClassRecommendationCarouselProps {
  groups: IEisDopRecommendationGroup[];
}

const EisDopRecommendationCarousel: FC<IClassRecommendationCarouselProps> = ({ groups }) => {
  const dispatch = useDispatch();

  const handleViewRecommendationGroup = (group: IEisDopRecommendationGroup) => {
    return () => {
      const { drawerTitle, recommendations } = group;
      const title = `${drawerTitle}: ${recommendations.length}`;

      dispatch(eisDopRecommendationsDrawerActions.setTitle(title));
      dispatch(eisDopRecommendationsDrawerActions.setRecommendations(recommendations));
      dispatch(eisDopRecommendationsDrawerActions.setOpen(true));
    };
  };

  return (
    <div className="eis-dop-recommendation-carousel">
      <Carousel items={groups} cardWidth={220} gap={12}>
        <Slider>
          {groups.map((group, index) => {
            const { cardTitle, accentColor, illustration, recommendations } = group;

            const sectionsAmount = recommendations.length;
            const sectionWord = getNumeral(sectionsAmount, 'кружок', 'кружка', 'кружков');
            const description = `Мы подобрали тебе ${sectionsAmount} ${sectionWord}`;

            return (
              <Slide key={`${index}-${cardTitle}`} index={index} className="slide">
                <GroupRecommendation
                  title={cardTitle}
                  description={description}
                  accentColor={accentColor}
                  backgroundIllustration={illustration}
                  onView={handleViewRecommendationGroup(group)}
                />
              </Slide>
            );
          })}
        </Slider>
      </Carousel>
    </div>
  );
};

export default EisDopRecommendationCarousel;
