import { DoughnutColors } from '../const';

export const getProgressValue = (value: number, max: number) => {
  return (value / max) * 100;
};

export const getDoughnutData = (result: number, max: number) => {
  const valueProgressbar = getProgressValue(result, max);
  return {
    incomingData: [valueProgressbar, 100 - valueProgressbar],
    incomingLabels: ['1', '2'],
  };
};

export const getDoughnutGradeColor = (result: number) => {
  if (result > 4.2) return DoughnutColors.GREEN;
  if (result > 3.75) return DoughnutColors.YELLOW;
  return DoughnutColors.RED;
};

export const getDoughnutPercentColor = (result: number, max: number) => {
  const percent = (result * 100) / max;
  if (percent >= 90) return DoughnutColors.GREEN;
  if (percent >= 50) return DoughnutColors.YELLOW;
  return DoughnutColors.RED;
};

export const getDoughnutColor = (result: number, max: number) => {
  if (result === max) return DoughnutColors.GREEN;
  return DoughnutColors.YELLOW;
};
