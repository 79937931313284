import { SxStyles } from 'types';

export const headerContainer: SxStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',

  marginBlock: '12px',
};

export const filtersContainer: SxStyles = {
  display: 'flex',
  gap: '12px',
};
