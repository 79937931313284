/* eslint-disable prettier/prettier */
/* eslint-disable */

import { DifferentCode } from '.';

export enum HistoryActionTypesKeys {
  ALL = 'ALL',
  POST = 'POST',
  DELETE = 'DELETE',
  PUT = 'PUT'
}

export const HistoryActionTypeValues: Record<string, string> = {
  [HistoryActionTypesKeys.ALL]: 'Все действия',
  [HistoryActionTypesKeys.POST]: 'Добавление',
  [HistoryActionTypesKeys.DELETE]: 'Удаление',
  [HistoryActionTypesKeys.PUT]: 'Редактирование'
};

export const HistoryActionTypeCodes: Record<string, number> = {
  [HistoryActionTypesKeys.ALL]: DifferentCode,
  [HistoryActionTypesKeys.POST]: 1,
  [HistoryActionTypesKeys.DELETE]: 2,
  [HistoryActionTypesKeys.PUT]: 3
};

export const HistoryActionAttributesLabels: Record<string, string> = {
  ADDING: 'добавление',
  EDITING: 'редактирование',
  DELETING: 'удаление',
  UNKNOWN: 'неизвестно'
}

