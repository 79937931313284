/* eslint-disable prettier/prettier */
/* eslint-disable */

import { SectionCodes, SportSubcategoryCodes } from '../const';
import { RewardNames, SportRewardCodes } from '../const/rewards';

import {
  IconBronzeCup,
  IconBronzeGto,
  IconBronzeMedal,
  IconCandidateMaster,
  IconCivicAktivist,
  IconCivicEcolog,
  IconCivicVolonteer,
  IconDiploma,
  IconDiploma_1,
  IconDiploma_2,
  IconDiploma_3,
  IconFirstAdventure,
  IconFirstCategory,
  IconFirstPlace,
  IconGoldCup,
  IconGoldGto,
  IconGoldMedal,
  IconGrossMaster,
  IconHonourableMention,
  IconInternationalMaster,
  IconJuniorAdventurer1,
  IconJuniorAdventurer2,
  IconJuniorAdventurer3,
  IconJuniorAdventurer4,
  IconJuniorAdventurer5,
  IconJuniorAdventurer6,
  IconJuniorAdventurer7,
  IconJuniorAdventurer9,
  IconJuniorTourist1,
  IconJuniorTourist2,
  IconJuniorTourist3,
  IconNeutralMedal,
  IconParticipant,
  IconRussiaMaster,
  IconSecondCategory,
  IconSecondPlace,
  IconSilverCup,
  IconSilverGto,
  IconSilverMedal,
  IconThirdCategory,
  IconThirdPlace
} from '../icons/medals';

import {
  aktivist,
  aktivistSmall,
  blueMedal,
  blueMedalBig,
  bronzeCup,
  bronzeCupBig,
  bronzeGto,
  bronzeGtoSmall,
  bronzeMedal,
  bronzeMedalBig,
  candidate,
  candidateSmall,
  ecolog,
  ecologSmall,
  firstAdventure,
  firstAdventureSmall,
  firstCategory,
  firstCategorySmall,
  firstPlace,
  firstPlaceBig,
  goldCup,
  goldCupBig,
  goldGto,
  goldGtoSmall,
  goldMedal,
  goldMedalBig,
  grossMaster,
  grossMasterSmall,
  internationalMaster,
  internationalMasterSmall,
  juniorAdventurer1,
  juniorAdventurer1Small,
  juniorAdventurer2,
  juniorAdventurer2Small,
  juniorAdventurer3,
  juniorAdventurer3Small,
  juniorAdventurer4,
  juniorAdventurer4Small,
  juniorAdventurer5,
  juniorAdventurer5Small,
  juniorAdventurer6,
  juniorAdventurer6Small,
  juniorAdventurer7,
  juniorAdventurer7Small,
  juniorAdventurer8,
  juniorAdventurer8Small,
  juniorAdventurer9,
  juniorAdventurer9Small,
  juniorTourist1,
  juniorTourist1Small,
  juniorTourist2,
  juniorTourist2Small,
  juniorTourist3,
  juniorTourist3Small,
  russiaMaster,
  russiaMasterSmall,
  secondCategory,
  secondCategorySmall,
  secondPlace,
  secondPlaceBig,
  silverCup,
  silverCupBig,
  silverGto,
  silverGtoSmall,
  silverMedal,
  silverMedalBig,
  thirdCategory,
  thirdCategorySmall,
  thirdPlace,
  thirdPlaceBig,
  volonteer,
  volonteerSmall,
  диплом_1_activity,
  диплом_1_card,
  диплом_2_activity,
  диплом_2_card,
  диплом_3_activity,
  диплом_3_card,
  диплом_activity,
  диплом_card,
  похвальная_грамота_activity,
  похвальная_грамота_card,
  участник_activity,
  участник_card,
} from '../images';

export const getSportAwardDescription = (
  categoryCode?: number,
  ageLimit?: string,
  sportKind?: string,
  tourismKind?: string,
  name?: string,
  fallback?: string,
): string => {
  if (categoryCode === SectionCodes.gtoReward && ageLimit) return ageLimit;
  if ((categoryCode === SectionCodes.sportRank || categoryCode === SectionCodes.sportCategory) && sportKind) return sportKind;
  if ((categoryCode === SectionCodes.sportRewardTourism || categoryCode === SectionCodes.sportRewardCompetition) && tourismKind) return tourismKind

  return name ?? fallback ?? 'Не выбрано';
};

// иконки для отображения в карточке награды
export const getRewardCardIcon = (value: string) => {
  switch (value) {
    case RewardNames.WINNER:
      return goldMedalBig;
    case RewardNames.AWARDEE:
      return silverMedalBig;
    case RewardNames.LAUREATE:
      return bronzeMedalBig;
    case RewardNames.FIRST:
      return firstPlaceBig;
    case RewardNames.SECOND:
      return secondPlaceBig;
    case RewardNames.THIRD:
      return thirdPlaceBig;
    case RewardNames.VOLONTEER:
      return volonteer;
    case RewardNames.ACTIVIST:
      return aktivist;
    case RewardNames.ECOLOG:
      return ecolog;
    case RewardNames.ДИПЛОМ_1:
      return диплом_1_card;
    case RewardNames.ДИПЛОМ_2:
      return диплом_2_card;
    case RewardNames.ДИПЛОМ_3:
      return диплом_3_card;
    case RewardNames.ДИПЛОМ:
      return диплом_card;
    case RewardNames.ПОХВАЛЬНАЯ_ГРАМОТА:
      return похвальная_грамота_card;
    case RewardNames.УЧАСТНИК:
      return участник_card;

    default:
      return blueMedalBig;
  }
};

// иконки для отображения в карточке мероприятия
export const getRewardActivityIcon = (value: string) => {
  switch (value) {
    case RewardNames.WINNER:
      return goldMedal;
    case RewardNames.AWARDEE:
      return silverMedal;
    case RewardNames.LAUREATE:
      return bronzeMedal;
    case RewardNames.FIRST:
      return firstPlace;
    case RewardNames.SECOND:
      return secondPlace;
    case RewardNames.THIRD:
      return thirdPlace;
    case RewardNames.VOLONTEER:
      return volonteerSmall;
    case RewardNames.ACTIVIST:
      return aktivistSmall;
    case RewardNames.ECOLOG:
      return ecologSmall;
    case RewardNames.ДИПЛОМ_1:
      return диплом_1_activity;
    case RewardNames.ДИПЛОМ_2:
      return диплом_2_activity;
    case RewardNames.ДИПЛОМ_3:
      return диплом_3_activity;
    case RewardNames.ДИПЛОМ:
      return диплом_activity;
    case RewardNames.ПОХВАЛЬНАЯ_ГРАМОТА:
      return похвальная_грамота_activity;
    case RewardNames.УЧАСТНИК:
      return участник_activity;

    default:
      return blueMedal;
  }
};

// иконки для отображения в ленте наград
export const getRewardShowcaseSvgIcon = (value: string): React.FC<React.SVGProps<SVGSVGElement>> => {
  switch (value) {
    case RewardNames.WINNER: return IconGoldMedal;
    case RewardNames.AWARDEE: return IconSilverMedal;
    case RewardNames.LAUREATE: return IconBronzeMedal;
    case RewardNames.FIRST: return IconFirstPlace;
    case RewardNames.SECOND: return IconSecondPlace;
    case RewardNames.THIRD: return IconThirdPlace;
    case RewardNames.VOLONTEER: return IconCivicVolonteer;
    case RewardNames.ACTIVIST: return IconCivicAktivist;
    case RewardNames.ECOLOG: return IconCivicEcolog;
    case RewardNames.ДИПЛОМ_1: return IconDiploma_1;
    case RewardNames.ДИПЛОМ_2: return IconDiploma_2;
    case RewardNames.ДИПЛОМ_3: return IconDiploma_3;
    case RewardNames.ДИПЛОМ: return IconDiploma;
    case RewardNames.ПОХВАЛЬНАЯ_ГРАМОТА: return IconHonourableMention;
    case RewardNames.УЧАСТНИК: return IconParticipant;
    default: return IconNeutralMedal;
  }
};

export const getSportRewardCardIcon = (code?: number | null) => {
  switch (code) {
    case SportRewardCodes.goldGto:
      return goldGto;
    case SportRewardCodes.silverGto:
      return silverGto;
    case SportRewardCodes.bronzeGto:
      return bronzeGto;
    case SportRewardCodes.internationalMaster:
      return internationalMaster;
    case SportRewardCodes.russiaMaster:
      return russiaMaster;
    case SportRewardCodes.candidate:
      return candidate;
    case SportRewardCodes.grossMaster:
      return grossMaster;
    case SportRewardCodes.firstCategory:
      return firstCategory;
    case SportRewardCodes.secondCategory:
      return secondCategory;
    case SportRewardCodes.thirdCategory:
      return thirdCategory;
    case SportRewardCodes.firstAdventure:
      return firstAdventure;
    case SportRewardCodes.juniorAdventurer1:
      return juniorAdventurer1;
    case SportRewardCodes.juniorAdventurer2:
      return juniorAdventurer2;
    case SportRewardCodes.juniorAdventurer3:
      return juniorAdventurer3;
    case SportRewardCodes.juniorAdventurer4:
      return juniorAdventurer4;
    case SportRewardCodes.juniorAdventurer5:
      return juniorAdventurer5;
    case SportRewardCodes.juniorAdventurer6:
      return juniorAdventurer6;
    case SportRewardCodes.juniorAdventurer7:
      return juniorAdventurer7;
    case SportRewardCodes.juniorAdventurer8:
      return juniorAdventurer8;
    case SportRewardCodes.juniorAdventurer9:
      return juniorAdventurer9;
    case SportRewardCodes.juniorTourist1:
      return juniorTourist1;
    case SportRewardCodes.juniorTourist2:
      return juniorTourist2;
    case SportRewardCodes.juniorTourist3:
      return juniorTourist3;
    case SportRewardCodes.gold:
      return goldCupBig;
    case SportRewardCodes.silver:
      return silverCupBig;
    case SportRewardCodes.bronze:
      return bronzeCupBig;
    case SportRewardCodes.winner:
      return goldMedalBig;
    case SportRewardCodes.prizer:
      return silverMedalBig;
    default:
      return blueMedalBig;
  }
};

export const getSportRewardActivityIcon = (code?: number | null) => {
  switch (code) {
    case SportRewardCodes.goldGto:
      return goldGtoSmall;
    case SportRewardCodes.silverGto:
      return silverGtoSmall;
    case SportRewardCodes.bronzeGto:
      return bronzeGtoSmall;
    case SportRewardCodes.internationalMaster:
      return internationalMasterSmall;
    case SportRewardCodes.russiaMster:
      return russiaMasterSmall;
    case SportRewardCodes.candidate:
      return candidateSmall;
    case SportRewardCodes.grossMaster:
      return grossMasterSmall;
    case SportRewardCodes.firstCategory:
      return firstCategorySmall;
    case SportRewardCodes.secondCategory:
      return secondCategorySmall;
    case SportRewardCodes.thirdCategory:
      return thirdCategorySmall;
    case SportRewardCodes.firstAdventure:
      return firstAdventureSmall;
    case SportRewardCodes.juniorAdventurer1:
      return juniorAdventurer1Small;
    case SportRewardCodes.juniorAdventurer2:
      return juniorAdventurer2Small;
    case SportRewardCodes.juniorAdventurer3:
      return juniorAdventurer3Small;
    case SportRewardCodes.juniorAdventurer4:
      return juniorAdventurer4Small;
    case SportRewardCodes.juniorAdventurer5:
      return juniorAdventurer5Small;
    case SportRewardCodes.juniorAdventurer6:
      return juniorAdventurer6Small;
    case SportRewardCodes.juniorAdventurer7:
      return juniorAdventurer7Small;
    case SportRewardCodes.juniorAdventurer8:
      return juniorAdventurer8Small;
    case SportRewardCodes.juniorAdventurer9:
      return juniorAdventurer9Small;
    case SportRewardCodes.juniorTourist1:
      return juniorTourist1Small;
    case SportRewardCodes.juniorTourist2:
      return juniorTourist2Small;
    case SportRewardCodes.juniorTourist3:
      return juniorTourist3Small;
    case SportRewardCodes.gold:
      return goldCup;
    case SportRewardCodes.silver:
      return silverCup;
    case SportRewardCodes.bronze:
      return bronzeCup;
    case SportRewardCodes.winner:
      return goldMedal;
    case SportRewardCodes.prizer:
      return silverMedal;
    default:
      return blueMedal;
  }
};

export const getSportRewardShowcaseSvgIcon = (code?: number | null): React.FC<React.SVGProps<SVGSVGElement>> => {
  switch (code) {
    case SportRewardCodes.goldGto: return IconGoldGto;
    case SportRewardCodes.silverGto: return IconSilverGto;
    case SportRewardCodes.bronzeGto: return IconBronzeGto;
    case SportRewardCodes.internationalMaster: return IconInternationalMaster;
    case SportRewardCodes.russiaMster: return IconRussiaMaster;
    case SportRewardCodes.candidate: return IconCandidateMaster;
    case SportRewardCodes.grossMaster: return IconGrossMaster;
    case SportRewardCodes.firstCategory: return IconFirstCategory;
    case SportRewardCodes.secondCategory: return IconSecondCategory;
    case SportRewardCodes.thirdCategory: return IconThirdCategory;
    case SportRewardCodes.firstAdventure: return IconFirstAdventure;
    case SportRewardCodes.juniorAdventurer1: return IconJuniorAdventurer1;
    case SportRewardCodes.juniorAdventurer2: return IconJuniorAdventurer2;
    case SportRewardCodes.juniorAdventurer3: return IconJuniorAdventurer3;
    case SportRewardCodes.juniorAdventurer4: return IconJuniorAdventurer4;
    case SportRewardCodes.juniorAdventurer5: return IconJuniorAdventurer5;
    case SportRewardCodes.juniorAdventurer6: return IconJuniorAdventurer6;
    case SportRewardCodes.juniorAdventurer7: return IconJuniorAdventurer7;
    case SportRewardCodes.juniorAdventurer8: return IconJuniorAdventurer9;
    case SportRewardCodes.juniorAdventurer9: return IconJuniorAdventurer1;
    case SportRewardCodes.juniorTourist1: return IconJuniorTourist1;
    case SportRewardCodes.juniorTourist2: return IconJuniorTourist2;
    case SportRewardCodes.juniorTourist3: return IconJuniorTourist3;
    case SportRewardCodes.gold: return IconGoldCup;
    case SportRewardCodes.silver: return IconSilverCup;
    case SportRewardCodes.bronze: return IconBronzeCup;
    case SportRewardCodes.winner: return IconGoldMedal;
    case SportRewardCodes.prizer: return IconSilverMedal;
    default: return IconNeutralMedal;
  }
};
