/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useState, useEffect } from 'react';

import {
  Drawer as DrawerMaterial,
  DrawerProps
} from '@mui/material';

import './index.scss';

export interface IDrawerProps extends DrawerProps {
  isContainerScrollable: boolean;
  isFullHeight: boolean;
}

const Drawer: React.FC<IDrawerProps> = (props) => {
  const {
    children, open, isContainerScrollable, isFullHeight, ...restProps
  } = props;
  const [isDrawerOpen, setDrawerOpen] = useState<boolean | undefined>(false);

  useEffect(() => {
    setDrawerOpen(open);
  }, [open]);

  return (
    <DrawerMaterial
      open={isDrawerOpen}
      className={`drawer ${isContainerScrollable ? 'drawer--scrollable' : ''} ${isFullHeight ? 'drawer--fullHeight' : ''}`}
      {...restProps}
    >
      {children}
    </DrawerMaterial>
  );
};

export default Drawer;
