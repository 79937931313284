import { FC, PropsWithChildren, useRef } from 'react';

import { Box, Typography, useMediaQuery } from '@mui/material';
import { useMeasureMultiline } from 'hooks';
import { NeutralColors } from 'portfolio3/styles';
import { commonTheme } from 'portfolio3/styles/theme';
import { mergeSx } from 'utils';

import { IconArrowRight } from '../../../../../icons/arrows';
import * as styles from './styles';

interface IErrorReportTypeButtonProps {
  icon: JSX.Element;
  onClick: () => void;
}

const ErrorReportTypeButton: FC<IErrorReportTypeButtonProps & PropsWithChildren> = ({ icon, onClick, children }) => {
  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const titleRef = useRef(null);
  const { isMultiline } = useMeasureMultiline(titleRef.current);

  return (
    <Box
      component="button"
      className="error-report-type-button"
      sx={mergeSx(styles.buttonStyles, styles.root)}
      onClick={onClick}
    >
      <Box sx={styles.titleContainer}>
        <Box
          className="error-report-type-button__icon"
          sx={mergeSx(styles.iconContainer, isMultiline && { marginTop: '2px' })}
        >
          {icon}
        </Box>
        <Typography
          variant={isMobile ? 'Paragraph MD/Regular' : 'Paragraph LG/Regular'}
          sx={styles.title}
          ref={titleRef}
        >
          {children}
        </Typography>
      </Box>

      <IconArrowRight fill={NeutralColors.light_neutrals_600} />
    </Box>
  );
};

export default ErrorReportTypeButton;
