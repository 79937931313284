/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { FC } from 'react';

import { IPersonObject } from '../../../../../api/types';
import LinkedObject from '../../../LinkedObject';

export interface ILinkedObjectsTabProps {
  linkedObjects: IPersonObject[] | undefined;
  onOpenLinkedObjectCard: (linkedObject: IPersonObject) => void;
}

const LinkedObjectsTab: FC<ILinkedObjectsTabProps> = ({
  linkedObjects,
  onOpenLinkedObjectCard
}) => {
  if (!linkedObjects || linkedObjects.length === 0) return null;

  return (
    <>
      {linkedObjects?.map((linkedObject) => (
        <LinkedObject linkedObject={linkedObject} onOpenLinkedObjectCard={onOpenLinkedObjectCard} />
      ))}
    </>
  );
};

export default LinkedObjectsTab;
