import { FC, Fragment, PropsWithChildren } from 'react';

import { Box, useMediaQuery } from '@mui/material';
import { commonTheme } from 'portfolio3/styles/theme';

import { ILocalVuzSpeciality } from '../../types';
import { cardSpecialityMapFactory } from '../utils';
import * as styles from './styles';

interface IDetailsSpecialityListProps {
  specialities: ILocalVuzSpeciality[];
}

const DetailsSpecialityList: FC<IDetailsSpecialityListProps & PropsWithChildren> = ({ specialities, children }) => {
  const isSmallScreen = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const mapCardSpeciality = cardSpecialityMapFactory('20px', '16px', styles.specialityCard);

  return (
    <Box className="details-speciality-list" sx={styles.root}>
      {specialities.map((speciality, index) => {
        const { number } = speciality;
        const isLastIndex = specialities.length === index + 1;
        const hasDivider = !isSmallScreen || !isLastIndex;

        return (
          <Fragment key={`${number}-${index}`}>
            {mapCardSpeciality(speciality, index)}
            {hasDivider && <Box sx={styles.listDivier} />}
          </Fragment>
        );
      })}
      {children}
    </Box>
  );
};

export default DetailsSpecialityList;
