import React from 'react';

import { FormControl, Typography } from '@mui/material';

import { ICardDetailsController, IReportErrorFormData } from '../types';
import DetailCheckbox from './DetailCheckbox';

import './index.scss';

interface ICardDetailsErrorProps {
  formData: IReportErrorFormData;
  controller: ICardDetailsController;
  isDisabled: boolean;
}

const CardDetailsError: React.FC<ICardDetailsErrorProps> = ({ formData, controller, isDisabled }) => {
  return (
    <FormControl className="detail-list-control">
      <Typography variant="Paragraph LG/Semi Bold">
        Выберите один или несколько разделов, где допущена ошибка
      </Typography>

      <DetailCheckbox
        disabled={isDisabled}
        label="Общие сведения"
        onChange={controller.general}
        value={formData.errorGeneralMessage}
        checked={formData.errorGeneralMessageChecked}
      />
      <DetailCheckbox
        disabled={isDisabled}
        label="Файлы и вложения"
        onChange={controller.fileMetadata}
        value={formData.errorFileMetadataMessage}
        checked={formData.errorFileMetadataMessageChecked}
      />
      <DetailCheckbox
        disabled={isDisabled}
        label="Связанные достижения"
        onChange={controller.childEntity}
        value={formData.errorChildEntityMessage}
        checked={formData.errorChildEntityMessageChecked}
      />
    </FormControl>
  );
};

export default CardDetailsError;
