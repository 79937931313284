import { IProforientationRequest } from 'api/types';
import { LocalStorageGlobals } from 'const';
import { buildConfig } from 'packages/buildConfig';

import { ApiResult } from './types';
import { processBaseResponse } from './utils';

export class ProforientationApi {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  request<Params, TResponse, TError = any>(action: string, params: Params): Promise<ApiResult<TResponse, TError>> {
    const localStorageToken = localStorage.getItem(LocalStorageGlobals.PROFORIENTATION_TOKEN);

    const url = buildConfig.config?.PROFORIENTATION_API_URL ?? '';
    const token = localStorageToken ?? buildConfig.config?.PROFORIENTATION_API_TOKEN ?? '';

    const body: IProforientationRequest<Params> = {
      token,
      action,
      params,
    };

    return fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
    })
      .then(async (response) => {
        return await processBaseResponse(response);
      })
      .then(
        (response) => ({ response }),
        (error) => ({ error }),
      );
  }
}
