import { FC } from 'react';

import { Box, IconButton, Typography } from '@mui/material';
import { IconClose, IconPlus } from 'icons/edit';
import { BaseInputLabel, ClearableInput, FormControl } from 'portfolio3/ui-kit';
import { CommonUiKitSize, IController, InputRenderMode } from 'portfolio3/ui-kit/types';
import { Colors } from 'style/variables';

import * as styles from './styles';
import { IMultiInputController } from './types';

interface IMultiInputProps {
  isMobile?: boolean;
  inputRenderMode?: InputRenderMode;
  inputSize?: CommonUiKitSize;
  values: string[];
  label: string;
  placeholder?: string;
  addFieldButtonName?: string;
  controller: IMultiInputController;
}

const MultiInputControl: FC<IMultiInputProps> = ({
  isMobile,
  inputRenderMode = 'fixed',
  inputSize = 'medium',
  values,
  label,
  placeholder,
  addFieldButtonName,
  controller,
}) => {
  const createInputController = (index: number): IController<string> => {
    return {
      handleChange: (value) => {
        controller.handleChange(value, index);
      },
    };
  };

  const handleAddField = () => {
    controller.handleAddField();
  };

  const handleClearField = (index: number) => {
    if (index === 0 || values.length === 1) return;
    return () => {
      // очистка поля перед удалением, нужна для корректного ре рендара компонента
      controller.handleChange('', index);
      controller.handleDeleteField(index);
    };
  };

  return (
    <Box sx={styles.rootStyles(inputRenderMode)}>
      {values.map((value, index) => {
        const isFirstInput = index === 0;
        const MobileIcon = isFirstInput ? IconPlus : IconClose;
        const mobileIconHandler = isFirstInput ? handleAddField : handleClearField(index);

        return (
          <FormControl
            renderMode={inputRenderMode}
            inputSize={inputSize}
            key={index}
            label={<BaseInputLabel>{label}</BaseInputLabel>}
            control={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <ClearableInput
                  renderMode={inputRenderMode}
                  size={inputSize}
                  placeholder={placeholder}
                  value={value}
                  controller={createInputController(index)}
                  forceClearButtonShow={!isFirstInput}
                  onClear={isMobile ? undefined : handleClearField(index)}
                />
                {isMobile && (
                  <IconButton onClick={mobileIconHandler} sx={{ width: '24px', height: '24px' }}>
                    <MobileIcon sx={{ color: Colors['blue-primrose'], width: '24px', height: '24px' }} />
                  </IconButton>
                )}
              </Box>
            }
          />
        );
      })}
      {!isMobile && (
        <Typography
          component="button"
          variant="Paragraph MD/Medium"
          sx={styles.addButtonStyles}
          onClick={handleAddField}
        >
          {addFieldButtonName}
        </Typography>
      )}
    </Box>
  );
};

export default MultiInputControl;
