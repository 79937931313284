/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { SvgIcon } from '@mui/material';
import { IconRouteProps } from './types';

const IconRouteProfession: React.FC<IconRouteProps> = ({ outerFill, ...props }) => (
  <SvgIcon width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path className="outer" d="M0 8C0 3.58172 3.58172 0 8 0H22C26.4183 0 30 3.58172 30 8V22C30 26.4183 26.4183 30 22 30H8C3.58172 30 0 26.4183 0 22V8Z" fill={outerFill || '#F8EBFC'} />
    <path className="inner" d="M22.1775 9.93169H19.0725V6.97321C19.0735 6.84512 19.0499 6.71811 19.0032 6.59957C18.9564 6.48103 18.8874 6.37333 18.8001 6.28276C18.7129 6.19218 18.6091 6.12054 18.4949 6.072C18.3807 6.02346 18.2584 5.99899 18.135 6.00003H11.8575C11.6089 6.00003 11.3704 6.10256 11.1946 6.28507C11.0188 6.46758 10.92 6.71511 10.92 6.97321V9.93169H7.82251C7.33916 9.93169 6.8756 10.131 6.53381 10.4858C6.19203 10.8406 6.00002 11.3218 6.00002 11.8236V22.1003C5.99903 22.3494 6.04544 22.5963 6.13659 22.8267C6.22773 23.0572 6.36182 23.2666 6.53115 23.4431C6.70049 23.6196 6.90173 23.7597 7.12334 23.8552C7.34494 23.9508 7.58255 24 7.82251 24H22.1775C22.4175 24 22.6551 23.9508 22.8767 23.8552C23.0983 23.7597 23.2995 23.6196 23.4688 23.4431C23.6382 23.2666 23.7723 23.0572 23.8634 22.8267C23.9546 22.5963 24.001 22.3494 24 22.1003V11.8236C24 11.3218 23.808 10.8406 23.4662 10.4858C23.1244 10.131 22.6608 9.93169 22.1775 9.93169ZM12.795 8.10989C12.7968 8.06714 12.814 8.02665 12.8432 7.99639C12.8723 7.96613 12.9113 7.9483 12.9525 7.9464H17.0475C17.088 7.9484 17.1261 7.9665 17.154 7.99695C17.182 8.0274 17.1975 8.06784 17.1975 8.10989V9.93169H12.795V8.10989ZM21.6525 22.0536H8.34751C8.28546 22.0536 8.22402 22.041 8.16669 22.0163C8.10937 21.9917 8.05728 21.9555 8.0134 21.91C7.96953 21.8644 7.93472 21.8104 7.91098 21.7509C7.88723 21.6913 7.87501 21.6276 7.87501 21.5632V17.0009H10.92V19.0796C10.92 19.3377 11.0188 19.5852 11.1946 19.7677C11.3704 19.9502 11.6089 20.0528 11.8575 20.0528C12.1061 20.0528 12.3446 19.9502 12.5204 19.7677C12.6962 19.5852 12.795 19.3377 12.795 19.0796V17.0009H17.1975V19.0796C17.1975 19.3377 17.2963 19.5852 17.4721 19.7677C17.6479 19.9502 17.8864 20.0528 18.135 20.0528C18.3836 20.0528 18.6221 19.9502 18.7979 19.7677C18.9737 19.5852 19.0725 19.3377 19.0725 19.0796V17.0009H22.125V21.5632C22.125 21.6932 22.0752 21.818 21.9866 21.91C21.898 22.002 21.7778 22.0536 21.6525 22.0536Z" fill="#CC5DE8" />
  </SvgIcon>
);

export default IconRouteProfession;
