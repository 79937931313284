import { FC, PropsWithChildren } from 'react';

import { useTheme } from '@mui/material';
import { getAccentColor } from 'portfolio3/styles';

import BaseBadge from './BaseBadge';

const StrongAccentBadge: FC<PropsWithChildren> = ({ children }) => {
  const theme = useTheme();

  const accentColor = theme.palette.accentColor;
  const textColor = getAccentColor(accentColor, '200');
  const backgroundColor = getAccentColor(accentColor, '8');

  return (
    <BaseBadge textColor={textColor} backgroundColor={backgroundColor}>
      {children}
    </BaseBadge>
  );
};

export default StrongAccentBadge;
