import { FC, ReactNode } from 'react';

import { Typography } from '@mui/material';
import { commonBreakpoints } from 'const';
import { useMediaQueryWidth } from 'hooks';
import { Colors } from 'style/variables';

import './index.scss';

interface IInfoBoxProps {
  title: string;
  text: ReactNode;
  image: string;
}

const InfoBox: FC<IInfoBoxProps> = ({ title, text, image }) => {
  const isMobile = useMediaQueryWidth(commonBreakpoints.breakpointSm, 'down');

  return (
    <div className="info-box">
      <div className="info-box__text-content">
        <div className="info-box__title-wrapper">
          <Typography color={Colors.orange_11} variant="Headings/H6">
            {title}
          </Typography>
        </div>
        <div className="info-box__text-wrapper">
          <Typography variant="Paragraph MD/Regular">{text}</Typography>
        </div>
      </div>
      {!isMobile && (
        <div className="info-box__image-wrapper">
          <img className="info-box__image" src={image} alt="картинка" />
        </div>
      )}
    </div>
  );
};

export default InfoBox;
