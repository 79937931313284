/* eslint-disable max-len */
import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconSportClubsCommon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.3 2.4c0 1.3-1.1 2.4-2.4 2.4-1.3 0-2.4-1.1-2.4-2.4C9.6 1.1 10.7 0 12 0s2.3 1.1 2.3 2.4ZM7 5.4c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2ZM21 5.4c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2Z"
      fill={props.fill || 'currentColor'}
    />
    <path d="M15.098 16c-.1.1-.1.2-.2.3.2.1.4.3.6.4" fill="#fff" />
    <path
      d="M5.602 24v-2.8c0-2.1 1.2-3.9 2.8-5-.7-.8-1.2-1.8-1.2-3 0-2.4 2-4.4 4.4-4.4 2.4 0 4.4 2 4.4 4.4 0 1.2-.5 2.2-1.2 3 1.6 1 2.7 2.9 2.8 4.9v2.8h-12v.1Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M16.602 16c1.4 1.3 2.3 3.2 2.3 5.1v2.8h5.1v-2.5c0-1.8-1-3.4-2.5-4.3.6-.7 1.1-1.6 1.1-2.6 0-2.1-1.7-3.9-3.9-3.9-.7 0-1.3.2-1.8.5.3.7.4 1.4.4 2.1.1 1-.2 2-.7 2.8ZM4.4 21.2c0-2 .9-3.8 2.3-5.2-.4-.8-.7-1.8-.7-2.7 0-.9.2-1.7.5-2.4-.4-.1-.8-.2-1.2-.2-2.1 0-3.9 1.7-3.9 3.9 0 1 .4 1.9 1.1 2.6-1.4.9-2.4 2.5-2.5 4.4V24h4.3c.1 0 .1-2.8.1-2.8Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconSportClubsCommon;
