/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useCallback } from 'react';

import clsx from 'clsx';
import { IconButton, useMediaQuery } from '@mui/material';

import {
  Draggable, DraggableStateSnapshot, DraggingStyle, NotDraggingStyle
} from 'react-beautiful-dnd';
import {
  IconArrowBack,
  interestsInsipidIcon,
  interestsUnknownIcon,
  interestsSipidIcon
} from '../../../../icons';
import { Button } from '../../../../ui-kit';
import { detectTouchDevice } from '../../../../utils';
import { ISectionCardTheme, TContainerKeys } from '../types';

import './index.scss';

interface IDirectionsDragItemProps {
  id: string,
  index: number,
  name: string,
  cardTheme: ISectionCardTheme,
  rotation: string,
  isInInitialContainer?: boolean,
  moveElementOnClick?: (sourceIndex: number, destinationId: TContainerKeys) => void
}

const DirectionsDragItem: React.FC<IDirectionsDragItemProps> = ({
  id,
  index,
  name,
  cardTheme,
  rotation,
  isInInitialContainer,
  moveElementOnClick
}) => {
  const isMobile = useMediaQuery('(max-width: 1023px)', { noSsr: true });
  const getDragItemImage = useCallback(() => {
    if (isInInitialContainer) {
      return isMobile ? cardTheme.mobileMainImage : cardTheme.desktopMainImage;
    }
    return isMobile ? cardTheme.mobileSecondaryImage : cardTheme.desktopSecondaryImage;
  }, [cardTheme, isMobile]);

  const backgroundImage = getDragItemImage();
  const isTouchDevice = detectTouchDevice();
  function getStyle(style: DraggingStyle | NotDraggingStyle | undefined, snapshot: DraggableStateSnapshot) {
    const rotateTransform = `rotate(${rotation})`;
    const newStyle = {
      ...style,
      transform: style?.transform ? `${style.transform} ${rotateTransform}` : rotateTransform
    };
    if (!snapshot.isDropAnimating) {
      if (!snapshot.isDragging) {
        return {
          ...style,
          transform: rotateTransform
        };
      }
      return newStyle;
    }
    return {
      ...newStyle,
      // cannot be 0, but make it super tiny
      transitionDuration: '0.001s'
    };
  }

  const renderDragItemActions = () => {
    if (isMobile) {
      return <>
        <IconButton
          className="drag-item__mobile-btn drag-item__mobile-btn--type-insipid"
          disableTouchRipple
          onClick={() => moveElementOnClick?.(index, 'insipid')}
          size="large">
          <img src={interestsInsipidIcon} alt="Нет" />
        </IconButton>
        <IconButton
          className="drag-item__mobile-btn drag-item__mobile-btn--type-unknown"
          disableTouchRipple
          onClick={() => moveElementOnClick?.(index, 'unknown')}
          size="large">
          <img src={interestsUnknownIcon} alt="Не знаю" />
        </IconButton>
        <IconButton
          className="drag-item__mobile-btn drag-item__mobile-btn--type-sipid"
          disableTouchRipple
          onClick={() => moveElementOnClick?.(index, 'sipid')}
          size="large">
          <img src={interestsSipidIcon} alt="Да" />
        </IconButton>
      </>;
    }
    return (
      <>
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          className="drag-item__btn"
          onClick={() => moveElementOnClick?.(index, 'insipid')}
        >
          <IconArrowBack className="drag-item__btn-icon drag-item__btn-icon--type-insipid" />
          Нет
        </Button>
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          className="drag-item__btn"
          onClick={() => moveElementOnClick?.(index, 'unknown')}
        >
          Не знаю
          <IconArrowBack className="drag-item__btn-icon drag-item__btn-icon--type-unknown" />
        </Button>
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          className="drag-item__btn"
          onClick={() => moveElementOnClick?.(index, 'sipid')}
        >
          Да
          <IconArrowBack className="drag-item__btn-icon drag-item__btn-icon--type-sipid" />
        </Button>
      </>
    );
  };

  const renderDragItemContent = () => (
    <>
      <h3 className="drag-item__title">{name}</h3>
      <div className="drag-item__actions">
        {renderDragItemActions()}
      </div>
    </>
  );

  if (isTouchDevice && isMobile) {
    return (
      <div
        className="drag-item"
        style={
          {
            transform: isMobile
              ?  undefined
              : `rotate(${rotation})`,
            backgroundImage: `url(${backgroundImage})`
          }
        }
      >
        {renderDragItemContent()}
      </div>
    );
  }

  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <div
          className={clsx('drag-item', { 'drag-item--dragging': snapshot.isDragging })}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{ ...getStyle(provided.draggableProps.style, snapshot), backgroundImage: `url(${backgroundImage})` }}
        >
          {renderDragItemContent()}
        </div>
      )}
    </Draggable>
  );
};

export default DirectionsDragItem;
