import { IRootState } from 'reducers';
import { createSelector } from 'reselect';

import { filterSpecialityByMinBallExistance, getMappedVuzList } from './utils';

const vuzListRealGiaSelector = createSelector([(state: IRootState) => state.vuzList], (vuzList) => {
  return getMappedVuzList({
    vuzList: vuzList.content.items,
    specialityFilters: [filterSpecialityByMinBallExistance],
    skipVuzWithEmptySpecialities: true,
  });
});

export default vuzListRealGiaSelector;
