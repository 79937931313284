/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';
import { connect } from 'react-redux';

import PdfCommonRewards from '../../../components/pdfStudent/PdfCommonRewards';
import PdfCommonDataEntityContainer from '../dataEntityContainers/common';
import { IRootState } from '../../../reducers';
import { IModifiedReward } from '../../../types';
import { ICommonResponse } from '../../../api/types';
import { widgetPdfIcons } from '../../../icons';
import { sportRewardsSelector } from '../../../selectors';
import { useDataLoad } from 'hooks';
import { getDataLoadRequests } from 'utils';

interface IPdfSportRewardContainerProps {
  studentRewards: ICommonResponse<IModifiedReward>;
}

const PdfSportRewardContainer: React.FC<IPdfSportRewardContainerProps> = ({
  studentRewards
}) => {
  useDataLoad({ shouldLoad: true, requests: getDataLoadRequests().sportRewards });

  return (
    <PdfCommonDataEntityContainer
      title="Награды"
      icon={widgetPdfIcons.pdfIconSportRewards}
    >
      <PdfCommonRewards rewards={studentRewards.content || []} />
    </PdfCommonDataEntityContainer>
  );
};

export default connect(
  (state: IRootState) => ({
    studentRewards: sportRewardsSelector(state)
  })
)(PdfSportRewardContainer);
