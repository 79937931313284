import { ComponentType, FC, PropsWithChildren } from 'react';

import MainErrorBoundary from 'portfolio3/containers/common/MainErrorBoundary';

const withErrorBoundary = () => {
  return <P extends PropsWithChildren<unknown>>(WrappedComponent: ComponentType<P>): FC<P> => {
    return function WithErrorBoundaryContainer({ ...props }) {
      return (
        <MainErrorBoundary>
          <WrappedComponent {...props} />
        </MainErrorBoundary>
      );
    };
  };
};

export default withErrorBoundary;
