import React from 'react';
import { connect } from 'react-redux';

import { Grid, IconButton, Stack } from '@mui/material';
import { MoonLoader } from 'portfolio3/components/common/loaders';
import { IFile, IFileController } from 'portfolio3/features/dataEntryForm';
import { NeutralColors } from 'portfolio3/styles';
import { isDefined } from 'utils';

import { getAttachmentActions } from '../../../actions';
import { IChildInfo } from '../../../api/types';
import {
  iconAudioFile,
  iconCloseFile,
  IconDownloadFile,
  iconImageFile,
  iconTextFile,
  iconVideoFile,
} from '../../../icons';
import { IRootState } from '../../../reducers';

import './index.scss';

interface IFileListItemProps {
  file: IFile | undefined;
  fileController: IFileController;
  currentStudent?: IChildInfo;
  getAttachment: typeof getAttachmentActions.request;
}

const FileListItem: React.FC<IFileListItemProps> = ({ file, fileController, currentStudent, getAttachment }) => {
  const getFileSize = (size: number) => {
    if (size > 1000) {
      return `${Math.round(size / 1000)} кБ`;
    }
    if (size > 1000000) {
      return `${Math.round(size / 1000000)} МБ`;
    }
    return `${size} Б`;
  };

  const getFileImage = (name: string) => {
    if (!name) return;
    if (name.endsWith('jpg') || name.endsWith('png') || name.endsWith('bmp')) return iconImageFile;
    if (name.endsWith('mp4') || name.endsWith('avi')) return iconVideoFile;
    if (name.endsWith('mp3')) return iconAudioFile;

    return iconTextFile;
  };

  const handleDeleteFile = (fileId: string) => {
    fileController.handleSetDeleteFileStatus(fileId);
  };

  if (isDefined(file) && file?.isLoading)
    return (
      <Stack
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          paddingBlock: '12px',
          border: `1px solid ${NeutralColors.light_neutrals_300}`,
          borderRadius: '8px',
        }}
      >
        <MoonLoader size={50} />
      </Stack>
    );

  const fileName = file?.response.name ?? file?.file?.name ?? '';

  return file && file.response.name && file.localId ? (
    <Grid item key={fileName} className="file" container direction="row" alignItems="center">
      <div className="file__image">
        <img src={getFileImage(fileName)} alt="файл" />
      </div>
      <p className="file__info">
        <span className="file__name">{fileName}</span>
        {file?.file?.size && <span className="file__size">{getFileSize(file.file.size)}</span>}
      </p>
      <div className="file__controls">
        <IconButton
          className="file__button-download"
          disableTouchRipple
          onClick={() =>
            getAttachment({ id: file.response.id, personId: currentStudent?.meshId, name: file.response.name })
          }
          size="large"
        >
          <IconDownloadFile />
        </IconButton>
        <IconButton
          className="file__button-close"
          disableTouchRipple
          onClick={() => handleDeleteFile(file.localId)}
          size="large"
        >
          <img src={iconCloseFile} alt="закрыть" />
        </IconButton>
      </div>
    </Grid>
  ) : null;
};

export default connect(
  (state: IRootState) => ({
    currentStudent: state.currentStudent,
  }),
  {
    getAttachment: getAttachmentActions.request,
  },
)(FileListItem);
