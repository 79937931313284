import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconLinkAlt: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M11.527 3.9c2.182-2.187 5.703-2.582 8.135-.68 2.913 2.278 3.104 6.523.573 9.06l-1.423 1.425a1.003 1.003 0 0 1-1.422 0 1.009 1.009 0 0 1 0-1.425l1.422-1.426a4.04 4.04 0 0 0-.272-5.95c-1.627-1.363-4.069-1.103-5.568.399L11.7 6.578a1.003 1.003 0 0 1-1.422 0c-.393-.393-.397-1.028.177-1.603L11.527 3.9Zm1.766 5.393-4 4a.999.999 0 1 0 1.414 1.414l4-4a.999.999 0 1 0-1.414-1.414Zm-1.013 8.104-1.274 1.27c-1.5 1.497-3.948 1.755-5.576.398a4.015 4.015 0 0 1-.273-5.93l1.425-1.42a1.004 1.004 0 0 0 0-1.421 1.012 1.012 0 0 0-1.425 0l-1.275 1.272c-2.172 2.166-2.553 5.65-.675 8.074 2.271 2.933 6.531 3.131 9.073.598l1.425-1.42a1.004 1.004 0 0 0 0-1.422 1.009 1.009 0 0 0-1.425 0Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconLinkAlt;
