import { FC } from 'react';

import { IChildInfo } from 'api/types';
import ModalContainer from 'components/common/ModalContainer';
import PortfolioData from 'components/employee/PortfolioData';
import { Drawer } from 'ui-kit';

interface IDataEntryDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  students: IChildInfo[];
}

const DataEntryDrawer: FC<IDataEntryDrawerProps> = ({ isOpen, onClose, students }) => {
  return (
    <ModalContainer>
      <Drawer
        isContainerScrollable={false}
        isFullHeight
        anchor="right"
        transitionDuration={{ appear: 300, enter: 300, exit: 250 }}
        open={isOpen}
        onClose={onClose}
      >
        <PortfolioData availableStudents={students} open={isOpen} onClose={onClose} />
      </Drawer>
    </ModalContainer>
  );
};

export default DataEntryDrawer;
