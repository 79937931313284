import { ChangeEvent, FC } from 'react';

import { Box, DrawerProps, RadioGroup, Typography } from '@mui/material';
import { Drawer, IDrawerBase } from 'portfolio3/ui-kit/Drawer';

import * as styles from './styles';

interface IMobileStatusSelectorDrawer {
  code: number;
  onChangeRadio: (e: ChangeEvent<HTMLInputElement>) => void;
  onClose: () => void;
}

const MobileStatusSelectorDrawer: FC<IMobileStatusSelectorDrawer & IDrawerBase & DrawerProps> = ({
  code,
  open,
  onChangeRadio,
  children,
  onClose,
}) => {
  const drawerTitle: IDrawerBase['header'] = (crossButton) => (
    <Box className="status-selector-drawer__title-container" sx={styles.drawerTitleContainer}>
      <Typography className="title-container__title" variant="Paragraph MD/Semi Bold">
        Статус
      </Typography>

      <Box sx={{ position: 'absolute', top: '10px', right: '12px' }}>{crossButton}</Box>
    </Box>
  );

  return (
    <Drawer
      className="status-selector-drawer"
      anchor="bottom"
      header={drawerTitle}
      contentSx={styles.drawerContent}
      swipeableProps={{
        onClose,
      }}
      open={open}
      onClose={onClose}
      swipeable
      isMobile
    >
      <RadioGroup
        value={code}
        sx={styles.radioGroup}
        onChange={onChangeRadio}
        className="status-selector-drawer__radio-group"
      >
        {children}
      </RadioGroup>
    </Drawer>
  );
};

export default MobileStatusSelectorDrawer;
