/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { Doughnut } from '@reactchartjs/react-chart.js';

import './index.scss';

interface IDoughnutChartProps {
  total: number | string | null ;
  color: string;
  data: any;
  noMobileHeader?: boolean;
  borderThickness?: number;
}

const ResultDoughnut: React.FC<IDoughnutChartProps> = ({
  total, color, data, noMobileHeader, borderThickness = 90
}) => {
  const { incomingData, incomingLabels } = data;

  const singleData = () => ({
    labels: incomingLabels,
    datasets: [
      {
        label: '',
        data: incomingData,
        backgroundColor: [color, '#F4F3F8'],
        hoverBackgroundColor: [color, '#F4F3F8'],
        borderWidth: 0,
        weight: 0.3
      }
    ]
  });

  const options = {
    responsice: true,
    maintainAspectRatio: false,
    cutoutPercentage: borderThickness,
    legend: {
      display: false
    },
    tooltips: {
      enabled: false
    },
    plugins: {
      datalabels: {
        display: false,
        color: '#7B819B'
      }
    }
  };
  return (
    <div className="exam-doughnut">
      <Doughnut data={singleData} options={options} type="doughnut" />
      <div className={`exam-doughnut__header ${noMobileHeader ? 'exam-doughnut__header--no-mobile' : ''}`}>
        <span className="exam-doughnut__result">
          {total?.toString().replace('.', ',')}
        </span>
      </div>
    </div>
  );
};

export default ResultDoughnut;
