/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import makeStyles from '@mui/styles/makeStyles';

import { Skeleton as SkeletonMaterial } from '@mui/material';
import { SkeletonProps } from '@mui/lab';

import { Colors } from '../../style/variables';

interface ISkeletonProps {
  backgroundColor?: string;
  marginBottom?: number;
  borderRadius?: number;
}

const useStyles = makeStyles({
  root: ({ marginBottom, backgroundColor, borderRadius }: ISkeletonProps) => ({
    transform: 'none',
    marginBottom: (marginBottom || marginBottom === 0) ? marginBottom : 16,
    backgroundColor: backgroundColor || Colors['disable-text'],
    borderRadius
  })
});

const Skeleton: React.FC<SkeletonProps & ISkeletonProps> = (props) => {
  const { marginBottom, backgroundColor, borderRadius, ...componentProps } = props;
  const classes = useStyles({ marginBottom, backgroundColor, borderRadius });

  return (
    <SkeletonMaterial
      className={classes.root}
      {...componentProps}
    />
  );
};

export default Skeleton;
