/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useEffect, useContext, useMemo } from 'react';

import { IDictionaryItem } from '../../../../api/types';
import { SectionCodes } from '../../../../const';

import { LocalDataEntryFormContext } from '../context/localDataEntryFormContext';
import { BaseInputLabel, FormControl } from 'portfolio3/ui-kit';
import { EventSelect, Select } from 'portfolio3/ui-kit/selects';
import { IController } from 'portfolio3/ui-kit/types';

interface IRewardBlockProps {
  typeOptions: IDictionaryItem[];
  itemOptions: IDictionaryItem[];
  resultOptions: IDictionaryItem[];
  nameInputLabel: string;
  nameInputPlaceholder: string;
  shortType: boolean;
  isNameOptionActive?: boolean;
  onOpenEventForm: Function;
}

const RewardBlock: React.FC<IRewardBlockProps> = ({
  typeOptions,
  itemOptions,
  resultOptions,
  nameInputLabel,
  nameInputPlaceholder,
  shortType,
  isNameOptionActive,
  onOpenEventForm,
}) => {
  const { studentType, inputRenderMode, inputSize, formData, onChangeFormData } = useContext(LocalDataEntryFormContext);

  const isSportReward = formData.dataKind === SectionCodes.sportReward;

  useEffect(() => {
    if (typeOptions.length === 1) {
      onChangeFormData({
        ...formData,
        typeCode: typeOptions[0].code
      });
    }
  }, [typeOptions.length]);

  const getEntityType = (): string | undefined => {
    // нужно узнать что тут должно быть
    // formData.entityType используется только при отправке
    // сущностей reward и sport reward
    switch (formData.dataKind) {
      case SectionCodes.studyReward:
        return 'study';
      case SectionCodes.scienceReward:
        return 'science';
      case SectionCodes.creationReward:
        return 'creation';
      case SectionCodes.sportReward:
        return 'sport';
      case SectionCodes.civilReward:
        return 'civil';
      default:
        return undefined;
    }
  };

  const typeController: IController<string | undefined> = useMemo(() => ({
    handleChange: (type) => {
      onChangeFormData((prevstate) => ({
        ...prevstate,
        typeCode: Number(type),
        name: undefined,
        reward: undefined,
        entityId: undefined
      }));
    }
  }), [formData.id]);

  const entityController: IController<string | undefined> = useMemo(() => ({
    handleChange: (entityId) => {
      onChangeFormData((prevstate) => ({
        ...prevstate,
        entityId: Number(entityId),
        entityType: getEntityType()
      }));
    }
  }), [formData.id, getEntityType]);

  const rewardController: IController<string | undefined> = useMemo(() => ({
    handleChange: (reward) => {
      const rewardNumber = Number(reward);
      onChangeFormData((prevstate) => ({
        ...prevstate,
        reward: rewardNumber,
        name: rewardNumber,
        sportRewardCode: isSportReward ? rewardNumber : undefined
      }));
    }
  }), [formData.id]);

  const getRewardPlaceholder = () => (studentType ? 'Выбери награду...' : 'Выберите награду...');

  const isTypeSelectDisabled = typeOptions.length < 2;
  return (
    <>
      <FormControl
        renderMode={inputRenderMode}
        inputSize={inputSize}
        disabled={isTypeSelectDisabled}
        label={<BaseInputLabel>Тип награды</BaseInputLabel>}
        control={
          <Select
            inputRenderMode={inputRenderMode}
            inputSize={inputSize}
            placeholder="Выберите тип награды..."
            value={formData.typeCode}
            options={typeOptions}
            controller={typeController}
            strictMaxWidth
          />
        }
      />
      {formData.typeCode && (!shortType || (isNameOptionActive && formData.typeCode)) && (
        <FormControl
          renderMode={inputRenderMode}
          inputSize={inputSize}
          label={<BaseInputLabel>{nameInputLabel}</BaseInputLabel>}
          control={
            <EventSelect
              inputRenderMode={inputRenderMode}
              inputSize={inputSize}
              placeholder={nameInputPlaceholder}
              value={formData.entityId}
              options={itemOptions}
              controller={entityController}
              onOpenEventForm={onOpenEventForm}
              strictMaxWidth
            />
          }
        />
      )}
      {((formData.entityId && isNameOptionActive) || (formData.typeCode && !isNameOptionActive)) && (
        <FormControl
          renderMode={inputRenderMode}
          inputSize={inputSize}
          label={<BaseInputLabel>Награда</BaseInputLabel>}
          control={
            <Select
              inputRenderMode={inputRenderMode}
              inputSize={inputSize}
              placeholder={getRewardPlaceholder()}
              value={formData.reward}
              options={resultOptions}
              controller={rewardController}
              strictMaxWidth
              markOtherOption
            />
          }
        />
      )}
    </>
  );
};

export default RewardBlock;
