import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const Cinema: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="230" height="129" viewBox="0 0 230 129" xmlns="http://www.w3.org/2000/svg" {...props} fill="none">
    {/*eslint-disable max-len */}
    <path
      d="M203.88 100.78H26.38v-81.5h177.5v81.5Zm-176.5-1h175.5v-79.5H27.38v79.5Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M218.93 116a7.347 7.347 0 0 1-2.48-.42 7.944 7.944 0 0 1-4.11-3.61c-7 1-9.31-3.46-9.41-3.66a.51.51 0 0 1-.05-.22V11.76a.503.503 0 0 1 .5-.5.498.498 0 0 1 .5.5V108c.37.64 2.62 4 8.66 3a.501.501 0 0 1 .54.29 7.07 7.07 0 0 0 3.72 3.42c1.9.68 4.12.39 6.6-.85a.5.5 0 0 1 .694.612.504.504 0 0 1-.254.288 11.083 11.083 0 0 1-4.91 1.24Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M213.12 10.28h-1v77h1v-77ZM224.12 19.28h-1v95h1v-95ZM6.88 11.76h-1v96.38h1V11.76ZM16.12 10.28h-1v77h1v-77ZM19.64 119.21a17.467 17.467 0 0 1-3.75-.44.496.496 0 0 1-.386-.404.501.501 0 0 1 .229-.508.51.51 0 0 1 .377-.068c8 1.77 10.11-2.46 10.39-3.11v-94.9a.5.5 0 0 1 1 0v95c.01.056.01.114 0 .17 0 0-1.66 4.26-7.86 4.26ZM116.69 85.28a26.999 26.999 0 0 1-19.091-46.092 27.003 27.003 0 0 1 29.424-5.853A26.999 26.999 0 0 1 116.69 85.28Zm0-53a25.997 25.997 0 0 0-24.02 16.05 26 26 0 1 0 24.02-16.05Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M117.11 85.28a.501.501 0 0 1-.46-.3.498.498 0 0 1-.04-.19l-.4-25.47-19.79 16a.51.51 0 0 1-.71-.07 27 27 0 1 1 21.41 10l-.01.03Zm-.42-27.5a.542.542 0 0 1 .21 0 .478.478 0 0 1 .29.44l.41 26a25.998 25.998 0 0 0 24.564-20.798A26 26 0 1 0 96.181 74.22l20.199-16.37a.511.511 0 0 1 .31-.07Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M117 80.26a.483.483 0 0 1-.352-.14.48.48 0 0 1-.148-.35l-.32-20.45-15.87 12.88a.47.47 0 0 1-.37.1.44.44 0 0 1-.33-.18A22 22 0 1 1 117 80.26Zm-.34-22.48a.542.542 0 0 1 .21 0 .478.478 0 0 1 .29.44l.33 21a20.998 20.998 0 0 0 17.919-30.448A21 21 0 1 0 100.04 71.07l16.31-13.21a.512.512 0 0 1 .34-.08h-.03Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M108.86 67.48a12.653 12.653 0 0 0 6.19 1.76c4.86 0 6.36-3.09 6.32-5.41-.05-3.91-3.57-5.59-7.22-5.59H112v-2.83h2.11c2.75 0 6.23-1.42 6.23-4.73 0-2.24-1.42-4.21-4.9-4.21-2.003.06-3.946.703-5.59 1.85l-1-2.75a13.202 13.202 0 0 1 7.3-2.15c5.5 0 8 3.26 8 6.66 0 2.88-1.72 5.33-5.15 6.57v.09c3.43.68 6.23 3.26 6.23 7.17 0 4.47-3.48 8.38-10.19 8.38a14.252 14.252 0 0 1-7.26-1.89l1.08-2.92ZM40.12 36.78h-11v-11h11v11Zm-10-1h9v-9h-9v9ZM40.12 56.78h-11v-11h11v11Zm-10-1h9v-9h-9v9ZM40.12 76.78h-11v-11h11v11Zm-10-1h9v-9h-9v9ZM40.12 96.78h-11v-11h11v11Zm-10-1h9v-9h-9v9ZM201.12 36.78h-11v-11h11v11Zm-10-1h9v-9h-9v9ZM201.12 56.78h-11v-11h11v11Zm-10-1h9v-9h-9v9ZM201.12 76.78h-11v-11h11v11Zm-10-1h9v-9h-9v9ZM201.12 96.78h-11v-11h11v11Zm-10-1h9v-9h-9v9ZM181.62 42.78a.472.472 0 0 1-.3-.1l-8-6a.5.5 0 0 1-.1-.7.51.51 0 0 1 .7-.1l8 6a.501.501 0 0 1 .1.7.502.502 0 0 1-.4.2ZM156.62 82.78h-.16a.49.49 0 0 1-.289-.255.503.503 0 0 1-.021-.385l1-3a.5.5 0 0 1 .94.32l-1 3a.496.496 0 0 1-.47.32ZM59.62 59.78a.47.47 0 0 1-.35-.15l-3-3a.495.495 0 1 1 .7-.7l3 3a.481.481 0 0 1 0 .7.47.47 0 0 1-.35.15ZM64.62 32.78a.47.47 0 0 1-.35-.15.478.478 0 0 1 0-.7l1-1a.495.495 0 0 1 .7.7l-1 1a.471.471 0 0 1-.35.15Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M179.58 90.78H50.66a4.55 4.55 0 0 1-4.54-4.54V32.32a4.55 4.55 0 0 1 4.54-4.54h128.92a4.548 4.548 0 0 1 4.54 4.54v53.92a4.551 4.551 0 0 1-4.54 4.54Zm-128.92-62a3.55 3.55 0 0 0-3.54 3.54v53.92a3.55 3.55 0 0 0 3.54 3.54h128.92a3.55 3.55 0 0 0 3.54-3.54V32.32a3.549 3.549 0 0 0-3.54-3.54H50.66Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default Cinema;
