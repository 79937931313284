import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconCheckmark: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M9.863 18a1 1 0 0 1-.729-.315l-4.863-5.179a1 1 0 1 1 1.457-1.369l4.125 4.391 8.408-9.202a1 1 0 0 1 1.477 1.348l-9.137 10a.998.998 0 0 1-.73.326h-.008Z"
      fill={props.fill || 'currentColor'}
      strokeWidth="1px"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default IconCheckmark;
