import { FC, ReactNode } from 'react';

import { Stack, Typography, useMediaQuery } from '@mui/material';
import TagLine from 'portfolio3/components/common/TagLine';
import { commonTheme } from 'portfolio3/styles/theme';
import { Attributes } from 'portfolio3/ui-kit';
import { horizontalOverflowStyles } from 'portfolio3/ui-kit/utils';
import { IEntityFeature } from 'types';

import CardPadding from '../CardPadding';
import { clickableTitleStyles } from '../utils';
import * as styles from './styles';

interface IBaseCardProps {
  tags?: IEntityFeature[];
  title?: string;
  stage?: string;
  types?: string[];
  place?: string;
  isApproved?: boolean;
  isPartiallyApproved?: boolean;
  dateElement?: ReactNode;
  rightElement?: ReactNode;
  onView?: () => void;
}

const BaseCard: FC<IBaseCardProps> = ({
  tags,
  title,
  stage,
  types,
  place,
  isApproved,
  isPartiallyApproved,
  dateElement,
  rightElement,
  onView,
}) => {
  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const tagsElement = tags && (
    <TagLine
      tags={tags}
      isApproved={isApproved}
      isPartiallyApproved={isPartiallyApproved}
      sx={horizontalOverflowStyles.element}
    />
  );
  const titleElement = title && (
    <Typography
      component="button"
      className="base-card__title"
      variant="Paragraph LG/Semi Bold"
      sx={clickableTitleStyles}
      onClick={onView}
    >
      {title}
    </Typography>
  );
  const localDateElement = dateElement && (
    <Typography className="base-card__date" variant="Paragraph MD/Regular">
      {dateElement}
    </Typography>
  );

  const stageElement = stage && <Typography variant="Paragraph MD/Semi Bold">{stage}</Typography>;

  const attributes = types?.map((attribute) => (
    <Typography key={attribute} variant="Paragraph MD/Regular">
      {attribute}
    </Typography>
  ));
  const typesElement = attributes && <Attributes attributes={attributes} />;

  const placeElement = place && <Typography variant="Paragraph MD/Regular">{place}</Typography>;

  const mobileContainer = (
    <Stack className="base-card" sx={styles.mobileWrapper}>
      {tagsElement && <CardPadding.Row sx={horizontalOverflowStyles.container}>{tagsElement}</CardPadding.Row>}
      <CardPadding.Row>
        <Stack direction="row" gap="8px" justifyContent="space-between">
          <Stack gap="8px">
            {titleElement}
            {stageElement}
            {typesElement}
            {placeElement}
            {localDateElement}
          </Stack>
          {rightElement}
        </Stack>
      </CardPadding.Row>
    </Stack>
  );
  const desktopContainer = (
    <CardPadding.Row>
      <Stack className="base-card" sx={styles.desktopWrapper}>
        <Stack gap="12px">
          {tagsElement}
          <Stack gap="8px">
            <Stack gap="4px">
              {titleElement}
              {stageElement}
              {typesElement}
              {placeElement}
            </Stack>
            {localDateElement}
          </Stack>
        </Stack>
        {rightElement}
      </Stack>
    </CardPadding.Row>
  );

  return <CardPadding>{isMobile ? mobileContainer : desktopContainer}</CardPadding>;
};

export default BaseCard;
