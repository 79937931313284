import { SET_APPLICATION_STATUS } from 'actions/applicationStatus';
import { IReducers } from 'reducers';
import { IApplicationStatus } from 'types';
import connectReducers from 'utils/connectReducers';

import { setApplicationStatusAction } from './../actions';

export type ApplicationStatusState = IApplicationStatus;

const initialState: ApplicationStatusState = {
  STATUS: 'NORMAL',
  TEXT: '',
};

const applicationStatus: IReducers<ApplicationStatusState> = {
  [SET_APPLICATION_STATUS]: (_, action: ReturnType<typeof setApplicationStatusAction>) => {
    return action.payload ?? initialState;
  },
};

export default connectReducers(initialState, applicationStatus);
