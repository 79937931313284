import { createSelector } from 'reselect';

import { IRootState } from '../../reducers';

export const hasContextUserInfoErrorSelector = createSelector(
  [(state: IRootState) => state.currentUser, (state: IRootState) => state.linkMode.mode],
  (currentUser, linkMode) => {
    return !linkMode && !currentUser.data?.info;
  },
);
