/* eslint-disable prettier/prettier */
/* eslint-disable */

import { PostStudentGiaWorldSkills } from '../../../../api/types';
import { clamp, convertDateToServerDate } from '../../../../utils';
import { mapLinkablePersonObjectsToEntityLinkedObjects, IPortfolioEntryFormData } from 'portfolio3/features/dataEntryForm';
import { isGiaWorldSkillsValid } from '../validation/validators';
import { getBasePostEntityProperties } from './index';
import { GetMappedEntityData } from './types';

export const getMappedPostGiaWorldSkillsData: GetMappedEntityData<PostStudentGiaWorldSkills> = (
  personId: string,
  creatorId: string,
  sourceCode: number | undefined,
  formData: IPortfolioEntryFormData
): PostStudentGiaWorldSkills | null => {
  if (!sourceCode || !isGiaWorldSkillsValid(formData)) return null;

  const { categoryCode, dataKind, typeCode, giaName, giaKod, giaCompetenceCode, giaIsEarlyRelease, giaMaxCompetenceScore, giaResultScore, giaResultDate, linkedObjects } = formData;

  if (!categoryCode || !dataKind || !typeCode) return null;

  const clampedResultScore = clamp(giaResultScore, 0, 500);
  const clampedMaxCompetenceScore = clamp(giaMaxCompetenceScore, 10, 500);

  const postGiaWorldSkills: PostStudentGiaWorldSkills = {
    ...getBasePostEntityProperties(personId, creatorId, sourceCode, categoryCode, dataKind, typeCode),

    resultDate: convertDateToServerDate(giaResultDate),
    competenceCode: giaCompetenceCode,
    name: giaName,
    resultScore: clampedResultScore,
    kod: giaKod,
    isEarlyRelease: giaIsEarlyRelease || false,
    maxCompetenceScore: clampedMaxCompetenceScore,
    linkedObjects: mapLinkablePersonObjectsToEntityLinkedObjects(linkedObjects),
  };

  return postGiaWorldSkills;
};
