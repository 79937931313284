import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const Engineer: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    width="230"
    height="129"
    viewBox="0 0 230 129"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    sx={{ fill: 'none' }}
  >
    {/*eslint-disable max-len */}
    <path
      d="M29.8 80.5a41.34 41.34 0 0 0 12 29.18M181.139 61.9c14.282 0 25.86-11.578 25.86-25.86s-11.578-25.86-25.86-25.86-25.86 11.578-25.86 25.86 11.578 25.86 25.86 25.86Z"
      stroke={props.fill || 'currentColor'}
      strokeMiterlimit="10"
    />
    <path
      d="M167.981 47.38a7.26 7.26 0 1 0 0-14.52 7.26 7.26 0 0 0 0 14.52Z"
      stroke={props.fill || 'currentColor'}
      strokeMiterlimit="10"
    />
    <path
      d="M167.979 43.76a4.54 4.54 0 1 0 0-9.08 4.54 4.54 0 0 0 0 9.08Z"
      stroke={props.fill || 'currentColor'}
      strokeMiterlimit="10"
    />
    <path
      d="M169.29 38.76a.86.86 0 1 1-1.72 0 .86.86 0 0 1 1.72 0Z"
      fill={props.fill || 'currentColor'}
      stroke={props.fill || 'currentColor'}
    />
    <path
      d="M194.299 44.66a7.26 7.26 0 1 0 0-14.52 7.26 7.26 0 1 0 0 14.52Z"
      stroke={props.fill || 'currentColor'}
      strokeMiterlimit="10"
    />
    <path
      d="M194.3 41.03a4.54 4.54 0 1 0 0-9.08 4.54 4.54 0 0 0 0 9.08Z"
      stroke={props.fill || 'currentColor'}
      strokeMiterlimit="10"
    />
    <path
      d="M195.611 36.04a.86.86 0 1 1-1.72 0 .86.86 0 0 1 1.72 0Z"
      fill={props.fill || 'currentColor'}
      stroke={props.fill || 'currentColor'}
    />
    <path
      d="M193.389 38.76a1.36 1.36 0 1 0 0-2.72 1.36 1.36 0 0 0 0 2.72ZM167.389 41.76a1.36 1.36 0 1 0 0-2.72 1.36 1.36 0 0 0 0 2.72Z"
      fill="#fff"
      stroke={props.fill || 'currentColor'}
      strokeMiterlimit="10"
    />
    <path
      d="M161.891 18.77s2.91 5.47 17.43 3.66c14.52-1.81 17.46-7 17.46-7M182.579 28.38a12.51 12.51 0 0 1-6.93.58c-3.88-.73-9.09-1.33-12.21-.09-5.44 2.18-8.17 8.53-5.44 14.88 2.73 6.35 6.19 6.45 10.81 6.4 4.62-.05 8.48-3 14.6-2.77 6.8.27 7.88.77 13.37 0 8.52-1.18 8.89-10.89 7.74-14.52-1.15-3.63-4.78-9.07-15.67-6.35a54.661 54.661 0 0 0-6.27 1.87Z"
      stroke={props.fill || 'currentColor'}
      strokeMiterlimit="10"
    />
    <path
      d="M171.61 90s-4.09 0-4.54.67c-.45.67-1.15.87 5.45 3a35.801 35.801 0 0 0 10 1.77M182.501 115.79s-14.23 2.16-22.54 0l4.39-7.61"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M167.07 90.7s-8.17 13-3.63 19.3c0 0-1.81-5.07 5-3.44 6.81 1.63 14.07 6.16 14.07 6.16s-1.82-3.63 5.44-5.44c6.71-1.68 13.61 5.44 13.61 5.44s-.9-21.78-5.44-22C191.58 90.5 182.5 95 182.5 95s-1.82 11.34 0 17.69"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m184.271 109.37-1.77 7.38s-1.82 2.32 8.17 1.42c9.99-.9 10-1.82 10-1.82l-1-3.88 1.85.25M174.19 61s-4.4 0-1.67 4.57a13.902 13.902 0 0 1 1.81 9.08l-.14 2.56s-.77 2 7.62.36c8.39-1.64 11.58 0 11.58 0v-2.96a3.207 3.207 0 0 1-4.54-.91c-1.81-2.72 4.54-10 4.54-10l2.72 1.54a12.225 12.225 0 0 0-3.94 4.3c-2.41 4.14 1.22 5 1.22 5M167.07 77.33c.91 0 1.82-1.81 1.82-5.44 0-3.63-3.53-9.15-4.89-10.89-1.36-1.74-10.49-2.69-12.31-1.78-1.82.91-5.44 4.54-5.44 7.26 0 2.72 6.35 8.17 6.35 8.17s7.24 4.46 9.52 4a44.031 44.031 0 0 0 4.95-1.32ZM210.999 59.06a17.99 17.99 0 0 1-4.3.77 15.297 15.297 0 0 0-4.89 1.17c-4.79 1.85-2.07 6.39-4.79 10.93s3.98 8.07 9.23 6.75c5.25-1.32 12.55-5 13.45-11.33.79-5.51-3.89-9.66-8.7-8.29ZM195.201 84.59s-3.63-3.63-10 0c0 0-.91 6.46-1.81 7.31-.9.85 1.08 2.19 1.08 2.19"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M192.549 90.69s-1-2.47-4.61-4.29l-2.16 3.6s2.16 1.32 2.16 2"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M194.299 90.69a6.126 6.126 0 0 0-3.63-3.38l-2.73-.91M173.419 81a11.238 11.238 0 0 0 .76 6.19c.27.73.71 1.47 1.06 2.34 2.72 6.9 8.17 4.17 8.17 4.17"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M174.181 87.15c-2 .74-3.48 4.7-3 3.85 0 0 .38 1.76 4 1.76.91 0 1.2-.34 1.2-.34M191.509 79.86l-3.57-.89v-1.59"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m177.189 78.62-.6 1.24s-6.8 1.1 1.37 2a24.73 24.73 0 0 0 13.55-2s1-.71 2.79 2.18c2.23 3.58-.91 7.09-.91 7.09"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M187.94 79s-.9 1.08-4.77 1.49c-2.21.07-4.419-.132-6.58-.6M191.51 59.74s4.6 1.26 4.6 3.07"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M193.391 63.72s0-2.72 1.71-2.23c1.71.49 1 4 1 4l1.82.91a10.319 10.319 0 0 0-2.27 3.94c-.46 1.51.45 3.32.45 3.53 0 .94-2.72.7-2.72.7M163.441 63.72s-1.33 7.93-3.63 9.07c-3.63 1.82-8.17-.9-8.17-.9M167.071 77.33l-7.26-4.54M201.551 61s-2.72 4.22 0 10.73c2.72 6.51 18.15-4.38 18.15-4.38M172.52 65.53s-1.78.61-.89 4.85c.89 4.24.89 4.23 2.7 4.23M168.43 67.74l3.2-1.11M169.369 72.79l3.15 1.08"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M167.07 77.33s4.54 0 5.45-3.63M206.18 65.42a1.341 1.341 0 0 0 .69-2.26A4.411 4.411 0 0 1 205.8 61c-.62-2.69-3.34.94-2.43 2.76a2.935 2.935 0 0 0 2.81 1.66v0Z"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M212.78 61.9a2.662 2.662 0 0 1 1.14-2.76 14.99 14.99 0 0 0 2.15-1.77c1.82-1.82 1.82 1.81 1.25 4.53a1.44 1.44 0 0 1 2 0c1.26.91-2.26 5.84-2.26 5.84s-2.49 2.69-4.28-5.84ZM157.54 62.81s-1.36 2.18 2.27 1.54c2.5-.43 1.56-2.29.73-3.4-.4-.54-.8-1.09-1.18-1.65-.5-.73-1.38-1.63-2.43-1-1.66.88 1.07 2.7.61 4.51ZM152.06 62.55a3.223 3.223 0 0 0-.38-2.93c-1.19-1.76-3.32-4.42-4.57-3.16-1.82 1.81 2.06 3.59 1 5-1.37 1.83-2.85-4.06-2.85-.43 0 4.11 1.75 5.16 3.63 4.53a4.469 4.469 0 0 0 3.17-3.01v0Z"
      fill="#fff"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m94.67 103.7-8.38 7.45M76.38 83.51l90.911-44.36-63.37 56.33M47.29 96.15l25-11M21.06 14a9.71 9.71 0 1 1 3.77 19.05A9.71 9.71 0 0 1 21.06 14v0Z"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.25 31.47A11.78 11.78 0 1 1 30 33M25.24 35.07a11.7 11.7 0 0 1-9.16-2"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.4 27.76a14.388 14.388 0 0 1-23.932 5.073A14.39 14.39 0 0 1 9.941 16.55M12.43 13A14.4 14.4 0 0 1 37 20.15"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.36 6.47A16.53 16.53 0 0 1 26 39.15M7.67 29.52a16.838 16.838 0 0 1-1-3.38A16.53 16.53 0 0 1 19 6.85l.59-.13M120.62 23.13a8.618 8.618 0 0 1 8.841 3.68 8.622 8.622 0 0 1-3.887 12.746 8.622 8.622 0 0 1-8.067-15.146 8.606 8.606 0 0 1 3.113-1.28v0Z"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M114.559 38.67a10.476 10.476 0 0 1-1.837-11.375 10.473 10.473 0 0 1 9.719-6.187 10.48 10.48 0 0 1 7.348 17.792 9.167 9.167 0 0 1-1.24 1.1M124.319 41.87a10.419 10.419 0 0 1-8.13-1.76"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M134.24 35.37a12.786 12.786 0 0 1-11.946 8.255A12.779 12.779 0 0 1 110.74 25.37M113 22.25a12.792 12.792 0 0 1 18.669.213 12.794 12.794 0 0 1 3.111 6.147"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M123.539 16.47a14.678 14.678 0 0 1 10.187 23.608 14.68 14.68 0 0 1-8.687 5.392M108.721 36.94a14.927 14.927 0 0 1-.93-3 14.679 14.679 0 0 1 11-17.14l.52-.11M137.05 119.34l-3.76-4V94.39l-1.96-2.08 1.96-2.09V78.23l3.76-4"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m140.929 100.3-.61-.11 1.68-9.29-2.11.69-.2-.6 3.1-1-1.86 10.31ZM146.169 100.28a2.08 2.08 0 0 1-1.58-.61 2.242 2.242 0 0 1-.56-1.78c.159-1.854.494-3.69 1-5.48a3.221 3.221 0 0 1 1.19-1.76 2.809 2.809 0 0 1 1.67-.52h.72a2.106 2.106 0 0 1 1.59.61 2.28 2.28 0 0 1 .56 1.76 29.266 29.266 0 0 1-1 5.5c-.188.7-.603 1.32-1.18 1.76a2.837 2.837 0 0 1-1.68.52h-.73Zm1.69-9.52a2.277 2.277 0 0 0-2.25 1.83 27.516 27.516 0 0 0-1 5.33 1.8 1.8 0 0 0 .38 1.32 1.533 1.533 0 0 0 1.13.41h.72a2.283 2.283 0 0 0 2.25-1.83c.505-1.74.84-3.525 1-5.33a1.844 1.844 0 0 0-.38-1.32 1.549 1.549 0 0 0-1.14-.41h-.71ZM154.159 100.28a2.093 2.093 0 0 1-1.58-.61 2.252 2.252 0 0 1-.56-1.78c.159-1.854.494-3.689 1-5.48a3.275 3.275 0 0 1 1.19-1.76 2.84 2.84 0 0 1 1.68-.52h.72a2.1 2.1 0 0 1 1.58.61 2.291 2.291 0 0 1 .56 1.76 29.184 29.184 0 0 1-1 5.5 3.303 3.303 0 0 1-1.19 1.76 2.838 2.838 0 0 1-1.68.52h-.72Zm1.7-9.52a2.289 2.289 0 0 0-2.26 1.83 27.548 27.548 0 0 0-.95 5.33c-.052.473.084.948.38 1.32a1.531 1.531 0 0 0 1.13.41h.72a2.271 2.271 0 0 0 2.25-1.83c.505-1.74.84-3.525 1-5.33a1.796 1.796 0 0 0-.38-1.32 1.524 1.524 0 0 0-1.13-.41h-.76Z"
      fill={props.fill || 'currentColor'}
      stroke={props.fill || 'currentColor'}
      strokeWidth=".5"
      strokeMiterlimit="10"
    />
    <path
      d="m150.289 43.15-46 12M32.29 78.15l17-6M82.493 45.597c1.138-6.96-3.583-13.525-10.543-14.662-6.96-1.138-13.525 3.583-14.662 10.543-1.138 6.96 3.583 13.525 10.543 14.662 6.96 1.138 13.525-3.583 14.662-10.543Z"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M80.433 53.621c5.569-5.823 5.362-15.059-.461-20.628-5.824-5.569-15.06-5.363-20.628.461-5.57 5.824-5.363 15.06.46 20.628 5.824 5.57 15.06 5.363 20.629-.46ZM69.89 28.95v1.83M62.59 30.91l.91 1.58M57.25 36.25l1.58.91M55.3 43.55h1.82M57.25 50.84l1.58-.91M62.59 56.18l.91-1.58M69.89 58.14v-1.82M77.18 56.18l-.91-1.58M82.52 50.84l-1.57-.91M84.48 43.55h-1.82M82.52 36.25l-1.57.91M77.18 30.91l-.91 1.58M87.21 62.8a25.89 25.89 0 0 1-35.72-1M51.49 25.32a25.898 25.898 0 0 1 37 .24"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M97.414 43.758c.117-15.199-12.11-27.614-27.308-27.73-15.198-.117-27.614 12.109-27.73 27.307-.117 15.199 12.109 27.614 27.307 27.73 15.199.118 27.614-12.109 27.73-27.307Z"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M98.425 48.792c2.895-15.696-7.483-30.768-23.18-33.663-15.696-2.894-30.767 7.483-33.662 23.18-2.895 15.696 7.483 30.767 23.18 33.662 15.695 2.895 30.767-7.483 33.662-23.179Z"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M70 64.55c11.598 0 21-9.402 21-21s-9.402-21-21-21-21 9.402-21 21 9.402 21 21 21Z"
      stroke={props.fill || 'currentColor'}
      strokeMiterlimit="10"
    />
    <mask id="a" fill="#fff">
      <path d="M15.59 111.17h-.83v11.36h.83v-11.36Z" />
    </mask>
    <path d="M15.59 111.17h-.83v11.36h.83v-11.36Z" fill={props.fill || 'currentColor'} />
    <path
      d="M14.76 111.17v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 11.36v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-10.36h.83v-2h-.83v2Zm-.17-1v11.36h2v-11.36h-2Zm1 10.36h-.83v2h.83v-2Zm.17 1v-11.36h-2v11.36h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#a)"
    />
    <mask id="b" fill="#fff">
      <path d="M16.98 116.03h-.83v6.5h.83v-6.5Z" />
    </mask>
    <path d="M16.98 116.03h-.83v6.5h.83v-6.5Z" fill={props.fill || 'currentColor'} />
    <path
      d="M16.15 116.03v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 6.5v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-5.5h.83v-2h-.83v2Zm-.17-1v6.5h2v-6.5h-2Zm1 5.5h-.83v2h.83v-2Zm.17 1v-6.5h-2v6.5h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#b)"
    />
    <mask id="c" fill="#fff">
      <path d="M18.36 116.85h-.83v5.68h.83v-5.68Z" />
    </mask>
    <path d="M18.36 116.85h-.83v5.68h.83v-5.68Z" fill={props.fill || 'currentColor'} />
    <path
      d="M17.53 116.85v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 5.68v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-4.68h.83v-2h-.83v2Zm-.17-1v5.68h2v-5.68h-2Zm1 4.68h-.83v2h.83v-2Zm.17 1v-5.68h-2v5.68h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#c)"
    />
    <mask id="d" fill="#fff">
      <path d="M19.74 113.44h-.83v9.08h.83v-9.08Z" />
    </mask>
    <path d="M19.74 113.44h-.83v9.08h.83v-9.08Z" fill={props.fill || 'currentColor'} />
    <path
      d="M18.91 113.44v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 9.08v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-8.08h.83v-2h-.83v2Zm-.17-1v9.08h2v-9.08h-2Zm1 8.08h-.83v2h.83v-2Zm.17 1v-9.08h-2v9.08h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#d)"
    />
    <mask id="e" fill="#fff">
      <path d="M21.13 117.17h-.83v5.36h.83v-5.36Z" />
    </mask>
    <path d="M21.13 117.17h-.83v5.36h.83v-5.36Z" fill={props.fill || 'currentColor'} />
    <path
      d="M20.3 117.17v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 5.36v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-4.36h.83v-2h-.83v2Zm-.17-1v5.36h2v-5.36h-2Zm1 4.36h-.83v2h.83v-2Zm.17 1v-5.36h-2v5.36h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#e)"
    />
    <mask id="f" fill="#fff">
      <path d="M22.52 113.03h-.84v9.5h.84v-9.5Z" />
    </mask>
    <path d="M22.52 113.03h-.84v9.5h.84v-9.5Z" fill={props.fill || 'currentColor'} />
    <path
      d="M21.68 113.03v-1h-1v1h1Zm.84 0h1v-1h-1v1Zm0 9.5v1h1v-1h-1Zm-.84 0h-1v1h1v-1Zm0-8.5h.84v-2h-.84v2Zm-.16-1v9.5h2v-9.5h-2Zm1 8.5h-.84v2h.84v-2Zm.16 1v-9.5h-2v9.5h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#f)"
    />
    <mask id="g" fill="#fff">
      <path d="M23.89 111.46h-.83v11.07h.83v-11.07Z" />
    </mask>
    <path d="M23.89 111.46h-.83v11.07h.83v-11.07Z" fill={props.fill || 'currentColor'} />
    <path
      d="M23.06 111.46v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 11.07v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-10.07h.83v-2h-.83v2Zm-.17-1v11.07h2v-11.07h-2Zm1 10.07h-.83v2h.83v-2Zm.17 1v-11.07h-2v11.07h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#g)"
    />
    <mask id="h" fill="#fff">
      <path d="M25.28 114.34h-.83v8.18h.83v-8.18Z" />
    </mask>
    <path d="M25.28 114.34h-.83v8.18h.83v-8.18Z" fill={props.fill || 'currentColor'} />
    <path
      d="M24.45 114.34v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 8.18v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-7.18h.83v-2h-.83v2Zm-.17-1v8.18h2v-8.18h-2Zm1 7.18h-.83v2h.83v-2Zm.17 1v-8.18h-2v8.18h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#h)"
    />
    <mask id="i" fill="#fff">
      <path d="M26.66 113.03h-.83v9.5h.83v-9.5Z" />
    </mask>
    <path d="M26.66 113.03h-.83v9.5h.83v-9.5Z" fill={props.fill || 'currentColor'} />
    <path
      d="M25.83 113.03v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 9.5v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-8.5h.83v-2h-.83v2Zm-.17-1v9.5h2v-9.5h-2Zm1 8.5h-.83v2h.83v-2Zm.17 1v-9.5h-2v9.5h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#i)"
    />
    <mask id="j" fill="#fff">
      <path d="M28.05 112.39h-.83v10.14h.83v-10.14Z" />
    </mask>
    <path d="M28.05 112.39h-.83v10.14h.83v-10.14Z" fill={props.fill || 'currentColor'} />
    <path
      d="M27.22 112.39v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 10.14v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-9.14h.83v-2h-.83v2Zm-.17-1v10.14h2v-10.14h-2Zm1 9.14h-.83v2h.83v-2Zm.17 1v-10.14h-2v10.14h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#j)"
    />
    <mask id="k" fill="#fff">
      <path d="M29.43 115.69h-.83v6.83h.83v-6.83Z" />
    </mask>
    <path d="M29.43 115.69h-.83v6.83h.83v-6.83Z" fill={props.fill || 'currentColor'} />
    <path
      d="M28.6 115.69v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 6.83v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-5.83h.83v-2h-.83v2Zm-.17-1v6.83h2v-6.83h-2Zm1 5.83h-.83v2h.83v-2Zm.17 1v-6.83h-2v6.83h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#k)"
    />
    <mask id="l" fill="#fff">
      <path d="M30.81 113.19h-.83v9.34h.83v-9.34Z" />
    </mask>
    <path d="M30.81 113.19h-.83v9.34h.83v-9.34Z" fill={props.fill || 'currentColor'} />
    <path
      d="M29.98 113.19v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 9.34v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-8.34h.83v-2h-.83v2Zm-.17-1v9.34h2v-9.34h-2Zm1 8.34h-.83v2h.83v-2Zm.17 1v-9.34h-2v9.34h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#l)"
    />
    <mask id="m" fill="#fff">
      <path d="M43.27 111.17h-.83v11.36h.83v-11.36Z" />
    </mask>
    <path d="M43.27 111.17h-.83v11.36h.83v-11.36Z" fill={props.fill || 'currentColor'} />
    <path
      d="M42.44 111.17v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 11.36v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-10.36h.83v-2h-.83v2Zm-.17-1v11.36h2v-11.36h-2Zm1 10.36h-.83v2h.83v-2Zm.17 1v-11.36h-2v11.36h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#m)"
    />
    <mask id="n" fill="#fff">
      <path d="M40.5 120.66h-.83v1.86h.83v-1.86Z" />
    </mask>
    <path d="M40.5 120.66h-.83v1.86h.83v-1.86Z" fill={props.fill || 'currentColor'} />
    <path
      d="M39.67 120.66v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 1.86v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-.86h.83v-2h-.83v2Zm-.17-1v1.86h2v-1.86h-2Zm1 .86h-.83v2h.83v-2Zm.17 1v-1.86h-2v1.86h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#n)"
    />
    <mask id="o" fill="#fff">
      <path d="M39.12 119.38h-.83v3.14h.83v-3.14Z" />
    </mask>
    <path d="M39.12 119.38h-.83v3.14h.83v-3.14Z" fill={props.fill || 'currentColor'} />
    <path
      d="M38.29 119.38v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 3.14v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-2.14h.83v-2h-.83v2Zm-.17-1v3.14h2v-3.14h-2Zm1 2.14h-.83v2h.83v-2Zm.17 1v-3.14h-2v3.14h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#o)"
    />
    <mask id="p" fill="#fff">
      <path d="M32.2 113.44h-.83v9.08h.83v-9.08Z" />
    </mask>
    <path d="M32.2 113.44h-.83v9.08h.83v-9.08Z" fill={props.fill || 'currentColor'} />
    <path
      d="M31.37 113.44v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 9.08v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-8.08h.83v-2h-.83v2Zm-.17-1v9.08h2v-9.08h-2Zm1 8.08h-.83v2h.83v-2Zm.17 1v-9.08h-2v9.08h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#p)"
    />
    <mask id="q" fill="#fff">
      <path d="M37.74 117.17h-.84v5.36h.84v-5.36Z" />
    </mask>
    <path d="M37.74 117.17h-.84v5.36h.84v-5.36Z" fill={props.fill || 'currentColor'} />
    <path
      d="M36.9 117.17v-1h-1v1h1Zm.84 0h1v-1h-1v1Zm0 5.36v1h1v-1h-1Zm-.84 0h-1v1h1v-1Zm0-4.36h.84v-2h-.84v2Zm-.16-1v5.36h2v-5.36h-2Zm1 4.36h-.84v2h.84v-2Zm.16 1v-5.36h-2v5.36h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#q)"
    />
    <mask id="r" fill="#fff">
      <path d="M41.88 113.03h-.83v9.5h.83v-9.5Z" />
    </mask>
    <path d="M41.88 113.03h-.83v9.5h.83v-9.5Z" fill={props.fill || 'currentColor'} />
    <path
      d="M41.05 113.03v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 9.5v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-8.5h.83v-2h-.83v2Zm-.17-1v9.5h2v-9.5h-2Zm1 8.5h-.83v2h.83v-2Zm.17 1v-9.5h-2v9.5h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#r)"
    />
    <mask id="s" fill="#fff">
      <path d="M36.35 111.46h-.83v11.07h.83v-11.07Z" />
    </mask>
    <path d="M36.35 111.46h-.83v11.07h.83v-11.07Z" fill={props.fill || 'currentColor'} />
    <path
      d="M35.52 111.46v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 11.07v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-10.07h.83v-2h-.83v2Zm-.17-1v11.07h2v-11.07h-2Zm1 10.07h-.83v2h.83v-2Zm.17 1v-11.07h-2v11.07h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#s)"
    />
    <mask id="t" fill="#fff">
      <path d="M34.96 114.34h-.83v8.18h.83v-8.18Z" />
    </mask>
    <path d="M34.96 114.34h-.83v8.18h.83v-8.18Z" fill={props.fill || 'currentColor'} />
    <path
      d="M34.13 114.34v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 8.18v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-7.18h.83v-2h-.83v2Zm-.17-1v8.18h2v-8.18h-2Zm1 7.18h-.83v2h.83v-2Zm.17 1v-8.18h-2v8.18h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#t)"
    />
    <mask id="u" fill="#fff">
      <path d="M47.42 113.03h-.83v9.5h.83v-9.5Z" />
    </mask>
    <path d="M47.42 113.03h-.83v9.5h.83v-9.5Z" fill={props.fill || 'currentColor'} />
    <path
      d="M46.59 113.03v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 9.5v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-8.5h.83v-2h-.83v2Zm-.17-1v9.5h2v-9.5h-2Zm1 8.5h-.83v2h.83v-2Zm.17 1v-9.5h-2v9.5h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#u)"
    />
    <mask id="v" fill="#fff">
      <path d="M46.04 112.39h-.83v10.14h.83v-10.14Z" />
    </mask>
    <path d="M46.04 112.39h-.83v10.14h.83v-10.14Z" fill={props.fill || 'currentColor'} />
    <path
      d="M45.21 112.39v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 10.14v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-9.14h.83v-2h-.83v2Zm-.17-1v10.14h2v-10.14h-2Zm1 9.14h-.83v2h.83v-2Zm.17 1v-10.14h-2v10.14h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#v)"
    />
    <mask id="w" fill="#fff">
      <path d="M33.58 115.69h-.83v6.83h.83v-6.83Z" />
    </mask>
    <path d="M33.58 115.69h-.83v6.83h.83v-6.83Z" fill={props.fill || 'currentColor'} />
    <path
      d="M32.75 115.69v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 6.83v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-5.83h.83v-2h-.83v2Zm-.17-1v6.83h2v-6.83h-2Zm1 5.83h-.83v2h.83v-2Zm.17 1v-6.83h-2v6.83h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#w)"
    />
    <mask id="x" fill="#fff">
      <path d="M44.65 113.19h-.83v9.34h.83v-9.34Z" />
    </mask>
    <path d="M44.65 113.19h-.83v9.34h.83v-9.34Z" fill={props.fill || 'currentColor'} />
    <path
      d="M43.82 113.19v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 9.34v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-8.34h.83v-2h-.83v2Zm-.17-1v9.34h2v-9.34h-2Zm1 8.34h-.83v2h.83v-2Zm.17 1v-9.34h-2v9.34h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#x)"
    />
    <mask id="y" fill="#fff">
      <path d="M48.8 116.03h-.83v6.5h.83v-6.5Z" />
    </mask>
    <path d="M48.8 116.03h-.83v6.5h.83v-6.5Z" fill={props.fill || 'currentColor'} />
    <path
      d="M47.97 116.03v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 6.5v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-5.5h.83v-2h-.83v2Zm-.17-1v6.5h2v-6.5h-2Zm1 5.5h-.83v2h.83v-2Zm.17 1v-6.5h-2v6.5h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#y)"
    />
    <mask id="z" fill="#fff">
      <path d="M50.19 120.66h-.83v1.86h.83v-1.86Z" />
    </mask>
    <path d="M50.19 120.66h-.83v1.86h.83v-1.86Z" fill={props.fill || 'currentColor'} />
    <path
      d="M49.36 120.66v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 1.86v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-.86h.83v-2h-.83v2Zm-.17-1v1.86h2v-1.86h-2Zm1 .86h-.83v2h.83v-2Zm.17 1v-1.86h-2v1.86h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#z)"
    />
    <mask id="A" fill="#fff">
      <path d="M51.57 119.38h-.83v3.14h.83v-3.14Z" />
    </mask>
    <path d="M51.57 119.38h-.83v3.14h.83v-3.14Z" fill={props.fill || 'currentColor'} />
    <path
      d="M50.74 119.38v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 3.14v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-2.14h.83v-2h-.83v2Zm-.17-1v3.14h2v-3.14h-2Zm1 2.14h-.83v2h.83v-2Zm.17 1v-3.14h-2v3.14h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#A)"
    />
    <mask id="B" fill="#fff">
      <path d="M52.95 113.44h-.83v9.08h.83v-9.08Z" />
    </mask>
    <path d="M52.95 113.44h-.83v9.08h.83v-9.08Z" fill={props.fill || 'currentColor'} />
    <path
      d="M52.12 113.44v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 9.08v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-8.08h.83v-2h-.83v2Zm-.17-1v9.08h2v-9.08h-2Zm1 8.08h-.83v2h.83v-2Zm.17 1v-9.08h-2v9.08h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#B)"
    />
    <mask id="C" fill="#fff">
      <path d="M54.34 117.17h-.83v5.36h.83v-5.36Z" />
    </mask>
    <path d="M54.34 117.17h-.83v5.36h.83v-5.36Z" fill={props.fill || 'currentColor'} />
    <path
      d="M53.51 117.17v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 5.36v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-4.36h.83v-2h-.83v2Zm-.17-1v5.36h2v-5.36h-2Zm1 4.36h-.83v2h.83v-2Zm.17 1v-5.36h-2v5.36h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#C)"
    />
    <mask id="D" fill="#fff">
      <path d="M55.72 113.03h-.83v9.5h.83v-9.5Z" />
    </mask>
    <path d="M55.72 113.03h-.83v9.5h.83v-9.5Z" fill={props.fill || 'currentColor'} />
    <path
      d="M54.89 113.03v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 9.5v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-8.5h.83v-2h-.83v2Zm-.17-1v9.5h2v-9.5h-2Zm1 8.5h-.83v2h.83v-2Zm.17 1v-9.5h-2v9.5h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#D)"
    />
    <mask id="E" fill="#fff">
      <path d="M57.11 111.46h-.83v11.07h.83v-11.07Z" />
    </mask>
    <path d="M57.11 111.46h-.83v11.07h.83v-11.07Z" fill={props.fill || 'currentColor'} />
    <path
      d="M56.28 111.46v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 11.07v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-10.07h.83v-2h-.83v2Zm-.17-1v11.07h2v-11.07h-2Zm1 10.07h-.83v2h.83v-2Zm.17 1v-11.07h-2v11.07h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#E)"
    />
    <mask id="F" fill="#fff">
      <path d="M58.49 114.34h-.83v8.18h.83v-8.18Z" />
    </mask>
    <path d="M58.49 114.34h-.83v8.18h.83v-8.18Z" fill={props.fill || 'currentColor'} />
    <path
      d="M57.66 114.34v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 8.18v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-7.18h.83v-2h-.83v2Zm-.17-1v8.18h2v-8.18h-2Zm1 7.18h-.83v2h.83v-2Zm.17 1v-8.18h-2v8.18h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#F)"
    />
    <mask id="G" fill="#fff">
      <path d="M59.87 113.03h-.83v9.5h.83v-9.5Z" />
    </mask>
    <path d="M59.87 113.03h-.83v9.5h.83v-9.5Z" fill={props.fill || 'currentColor'} />
    <path
      d="M59.04 113.03v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 9.5v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-8.5h.83v-2h-.83v2Zm-.17-1v9.5h2v-9.5h-2Zm1 8.5h-.83v2h.83v-2Zm.17 1v-9.5h-2v9.5h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#G)"
    />
    <mask id="H" fill="#fff">
      <path d="M61.26 112.39h-.83v10.14h.83v-10.14Z" />
    </mask>
    <path d="M61.26 112.39h-.83v10.14h.83v-10.14Z" fill={props.fill || 'currentColor'} />
    <path
      d="M60.43 112.39v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 10.14v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-9.14h.83v-2h-.83v2Zm-.17-1v10.14h2v-10.14h-2Zm1 9.14h-.83v2h.83v-2Zm.17 1v-10.14h-2v10.14h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#H)"
    />
    <mask id="I" fill="#fff">
      <path d="M62.64 115.69h-.83v6.83h.83v-6.83Z" />
    </mask>
    <path d="M62.64 115.69h-.83v6.83h.83v-6.83Z" fill={props.fill || 'currentColor'} />
    <path
      d="M61.81 115.69v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 6.83v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-5.83h.83v-2h-.83v2Zm-.17-1v6.83h2v-6.83h-2Zm1 5.83h-.83v2h.83v-2Zm.17 1v-6.83h-2v6.83h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#I)"
    />
    <mask id="J" fill="#fff">
      <path d="M64.03 113.19h-.83v9.34h.83v-9.34Z" />
    </mask>
    <path d="M64.03 113.19h-.83v9.34h.83v-9.34Z" fill={props.fill || 'currentColor'} />
    <path
      d="M63.2 113.19v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 9.34v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-8.34h.83v-2h-.83v2Zm-.17-1v9.34h2v-9.34h-2Zm1 8.34h-.83v2h.83v-2Zm.17 1v-9.34h-2v9.34h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#J)"
    />
    <mask id="K" fill="#fff">
      <path d="M65.41 113.44h-.83v9.08h.83v-9.08Z" />
    </mask>
    <path d="M65.41 113.44h-.83v9.08h.83v-9.08Z" fill={props.fill || 'currentColor'} />
    <path
      d="M64.58 113.44v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 9.08v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-8.08h.83v-2h-.83v2Zm-.17-1v9.08h2v-9.08h-2Zm1 8.08h-.83v2h.83v-2Zm.17 1v-9.08h-2v9.08h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#K)"
    />
    <mask id="L" fill="#fff">
      <path d="M68.18 114.34h-.83v8.18h.83v-8.18Z" />
    </mask>
    <path d="M68.18 114.34h-.83v8.18h.83v-8.18Z" fill={props.fill || 'currentColor'} />
    <path
      d="M67.35 114.34v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 8.18v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-7.18h.83v-2h-.83v2Zm-.17-1v8.18h2v-8.18h-2Zm1 7.18h-.83v2h.83v-2Zm.17 1v-8.18h-2v8.18h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#L)"
    />
    <mask id="M" fill="#fff">
      <path d="M66.79 115.69h-.83v6.83h.83v-6.83Z" />
    </mask>
    <path d="M66.79 115.69h-.83v6.83h.83v-6.83Z" fill={props.fill || 'currentColor'} />
    <path
      d="M65.96 115.69v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 6.83v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-5.83h.83v-2h-.83v2Zm-.17-1v6.83h2v-6.83h-2Zm1 5.83h-.83v2h.83v-2Zm.17 1v-6.83h-2v6.83h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#M)"
    />
    <mask id="N" fill="#fff">
      <path d="M69.59 111.17h-.83v11.36h.83v-11.36Z" />
    </mask>
    <path d="M69.59 111.17h-.83v11.36h.83v-11.36Z" fill={props.fill || 'currentColor'} />
    <path
      d="M68.76 111.17v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 11.36v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-10.36h.83v-2h-.83v2Zm-.17-1v11.36h2v-11.36h-2Zm1 10.36h-.83v2h.83v-2Zm.17 1v-11.36h-2v11.36h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#N)"
    />
    <mask id="O" fill="#fff">
      <path d="M70.98 116.03h-.83v6.5h.83v-6.5Z" />
    </mask>
    <path d="M70.98 116.03h-.83v6.5h.83v-6.5Z" fill={props.fill || 'currentColor'} />
    <path
      d="M70.15 116.03v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 6.5v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-5.5h.83v-2h-.83v2Zm-.17-1v6.5h2v-6.5h-2Zm1 5.5h-.83v2h.83v-2Zm.17 1v-6.5h-2v6.5h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#O)"
    />
    <mask id="P" fill="#fff">
      <path d="M72.36 116.85h-.83v5.68h.83v-5.68Z" />
    </mask>
    <path d="M72.36 116.85h-.83v5.68h.83v-5.68Z" fill={props.fill || 'currentColor'} />
    <path
      d="M71.53 116.85v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 5.68v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-4.68h.83v-2h-.83v2Zm-.17-1v5.68h2v-5.68h-2Zm1 4.68h-.83v2h.83v-2Zm.17 1v-5.68h-2v5.68h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#P)"
    />
    <mask id="Q" fill="#fff">
      <path d="M73.74 113.44h-.83v9.08h.83v-9.08Z" />
    </mask>
    <path d="M73.74 113.44h-.83v9.08h.83v-9.08Z" fill={props.fill || 'currentColor'} />
    <path
      d="M72.91 113.44v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 9.08v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-8.08h.83v-2h-.83v2Zm-.17-1v9.08h2v-9.08h-2Zm1 8.08h-.83v2h.83v-2Zm.17 1v-9.08h-2v9.08h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#Q)"
    />
    <mask id="R" fill="#fff">
      <path d="M75.13 117.17h-.83v5.36h.83v-5.36Z" />
    </mask>
    <path d="M75.13 117.17h-.83v5.36h.83v-5.36Z" fill={props.fill || 'currentColor'} />
    <path
      d="M74.3 117.17v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 5.36v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-4.36h.83v-2h-.83v2Zm-.17-1v5.36h2v-5.36h-2Zm1 4.36h-.83v2h.83v-2Zm.17 1v-5.36h-2v5.36h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#R)"
    />
    <mask id="S" fill="#fff">
      <path d="M76.52 113.03h-.84v9.5h.84v-9.5Z" />
    </mask>
    <path d="M76.52 113.03h-.84v9.5h.84v-9.5Z" fill={props.fill || 'currentColor'} />
    <path
      d="M75.68 113.03v-1h-1v1h1Zm.84 0h1v-1h-1v1Zm0 9.5v1h1v-1h-1Zm-.84 0h-1v1h1v-1Zm0-8.5h.84v-2h-.84v2Zm-.16-1v9.5h2v-9.5h-2Zm1 8.5h-.84v2h.84v-2Zm.16 1v-9.5h-2v9.5h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#S)"
    />
    <mask id="T" fill="#fff">
      <path d="M77.89 111.46h-.83v11.07h.83v-11.07Z" />
    </mask>
    <path d="M77.89 111.46h-.83v11.07h.83v-11.07Z" fill={props.fill || 'currentColor'} />
    <path
      d="M77.06 111.46v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 11.07v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-10.07h.83v-2h-.83v2Zm-.17-1v11.07h2v-11.07h-2Zm1 10.07h-.83v2h.83v-2Zm.17 1v-11.07h-2v11.07h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#T)"
    />
    <mask id="U" fill="#fff">
      <path d="M79.28 114.34h-.83v8.18h.83v-8.18Z" />
    </mask>
    <path d="M79.28 114.34h-.83v8.18h.83v-8.18Z" fill={props.fill || 'currentColor'} />
    <path
      d="M78.45 114.34v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 8.18v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-7.18h.83v-2h-.83v2Zm-.17-1v8.18h2v-8.18h-2Zm1 7.18h-.83v2h.83v-2Zm.17 1v-8.18h-2v8.18h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#U)"
    />
    <mask id="V" fill="#fff">
      <path d="M80.66 113.03h-.83v9.5h.83v-9.5Z" />
    </mask>
    <path d="M80.66 113.03h-.83v9.5h.83v-9.5Z" fill={props.fill || 'currentColor'} />
    <path
      d="M79.83 113.03v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 9.5v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-8.5h.83v-2h-.83v2Zm-.17-1v9.5h2v-9.5h-2Zm1 8.5h-.83v2h.83v-2Zm.17 1v-9.5h-2v9.5h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#V)"
    />
    <mask id="W" fill="#fff">
      <path d="M82.05 112.39h-.83v10.14h.83v-10.14Z" />
    </mask>
    <path d="M82.05 112.39h-.83v10.14h.83v-10.14Z" fill={props.fill || 'currentColor'} />
    <path
      d="M81.22 112.39v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 10.14v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-9.14h.83v-2h-.83v2Zm-.17-1v10.14h2v-10.14h-2Zm1 9.14h-.83v2h.83v-2Zm.17 1v-10.14h-2v10.14h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#W)"
    />
    <mask id="X" fill="#fff">
      <path d="M83.43 115.69h-.83v6.83h.83v-6.83Z" />
    </mask>
    <path d="M83.43 115.69h-.83v6.83h.83v-6.83Z" fill={props.fill || 'currentColor'} />
    <path
      d="M82.6 115.69v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 6.83v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-5.83h.83v-2h-.83v2Zm-.17-1v6.83h2v-6.83h-2Zm1 5.83h-.83v2h.83v-2Zm.17 1v-6.83h-2v6.83h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#X)"
    />
    <mask id="Y" fill="#fff">
      <path d="M84.81 113.19h-.83v9.34h.83v-9.34Z" />
    </mask>
    <path d="M84.81 113.19h-.83v9.34h.83v-9.34Z" fill={props.fill || 'currentColor'} />
    <path
      d="M83.98 113.19v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 9.34v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-8.34h.83v-2h-.83v2Zm-.17-1v9.34h2v-9.34h-2Zm1 8.34h-.83v2h.83v-2Zm.17 1v-9.34h-2v9.34h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#Y)"
    />
    <mask id="Z" fill="#fff">
      <path d="M97.27 111.17h-.83v11.36h.83v-11.36Z" />
    </mask>
    <path d="M97.27 111.17h-.83v11.36h.83v-11.36Z" fill={props.fill || 'currentColor'} />
    <path
      d="M96.44 111.17v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 11.36v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-10.36h.83v-2h-.83v2Zm-.17-1v11.36h2v-11.36h-2Zm1 10.36h-.83v2h.83v-2Zm.17 1v-11.36h-2v11.36h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#Z)"
    />
    <mask id="aa" fill="#fff">
      <path d="M94.5 120.66h-.83v1.86h.83v-1.86Z" />
    </mask>
    <path d="M94.5 120.66h-.83v1.86h.83v-1.86Z" fill={props.fill || 'currentColor'} />
    <path
      d="M93.67 120.66v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 1.86v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-.86h.83v-2h-.83v2Zm-.17-1v1.86h2v-1.86h-2Zm1 .86h-.83v2h.83v-2Zm.17 1v-1.86h-2v1.86h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#aa)"
    />
    <mask id="ab" fill="#fff">
      <path d="M93.12 119.38h-.83v3.14h.83v-3.14Z" />
    </mask>
    <path d="M93.12 119.38h-.83v3.14h.83v-3.14Z" fill={props.fill || 'currentColor'} />
    <path
      d="M92.29 119.38v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 3.14v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-2.14h.83v-2h-.83v2Zm-.17-1v3.14h2v-3.14h-2Zm1 2.14h-.83v2h.83v-2Zm.17 1v-3.14h-2v3.14h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#ab)"
    />
    <mask id="ac" fill="#fff">
      <path d="M86.2 113.44h-.83v9.08h.83v-9.08Z" />
    </mask>
    <path d="M86.2 113.44h-.83v9.08h.83v-9.08Z" fill={props.fill || 'currentColor'} />
    <path
      d="M85.37 113.44v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 9.08v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-8.08h.83v-2h-.83v2Zm-.17-1v9.08h2v-9.08h-2Zm1 8.08h-.83v2h.83v-2Zm.17 1v-9.08h-2v9.08h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#ac)"
    />
    <mask id="ad" fill="#fff">
      <path d="M91.74 117.17h-.84v5.36h.84v-5.36Z" />
    </mask>
    <path d="M91.74 117.17h-.84v5.36h.84v-5.36Z" fill={props.fill || 'currentColor'} />
    <path
      d="M90.9 117.17v-1h-1v1h1Zm.84 0h1v-1h-1v1Zm0 5.36v1h1v-1h-1Zm-.84 0h-1v1h1v-1Zm0-4.36h.84v-2h-.84v2Zm-.16-1v5.36h2v-5.36h-2Zm1 4.36h-.84v2h.84v-2Zm.16 1v-5.36h-2v5.36h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#ad)"
    />
    <mask id="ae" fill="#fff">
      <path d="M95.88 113.03h-.83v9.5h.83v-9.5Z" />
    </mask>
    <path d="M95.88 113.03h-.83v9.5h.83v-9.5Z" fill={props.fill || 'currentColor'} />
    <path
      d="M95.05 113.03v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 9.5v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-8.5h.83v-2h-.83v2Zm-.17-1v9.5h2v-9.5h-2Zm1 8.5h-.83v2h.83v-2Zm.17 1v-9.5h-2v9.5h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#ae)"
    />
    <mask id="af" fill="#fff">
      <path d="M90.35 111.46h-.83v11.07h.83v-11.07Z" />
    </mask>
    <path d="M90.35 111.46h-.83v11.07h.83v-11.07Z" fill={props.fill || 'currentColor'} />
    <path
      d="M89.52 111.46v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 11.07v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-10.07h.83v-2h-.83v2Zm-.17-1v11.07h2v-11.07h-2Zm1 10.07h-.83v2h.83v-2Zm.17 1v-11.07h-2v11.07h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#af)"
    />
    <mask id="ag" fill="#fff">
      <path d="M88.96 114.34h-.83v8.18h.83v-8.18Z" />
    </mask>
    <path d="M88.96 114.34h-.83v8.18h.83v-8.18Z" fill={props.fill || 'currentColor'} />
    <path
      d="M88.13 114.34v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 8.18v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-7.18h.83v-2h-.83v2Zm-.17-1v8.18h2v-8.18h-2Zm1 7.18h-.83v2h.83v-2Zm.17 1v-8.18h-2v8.18h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#ag)"
    />
    <mask id="ah" fill="#fff">
      <path d="M101.42 113.03h-.83v9.5h.83v-9.5Z" />
    </mask>
    <path d="M101.42 113.03h-.83v9.5h.83v-9.5Z" fill={props.fill || 'currentColor'} />
    <path
      d="M100.59 113.03v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 9.5v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-8.5h.83v-2h-.83v2Zm-.17-1v9.5h2v-9.5h-2Zm1 8.5h-.83v2h.83v-2Zm.17 1v-9.5h-2v9.5h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#ah)"
    />
    <mask id="ai" fill="#fff">
      <path d="M100.041 112.39h-.83v10.14h.83v-10.14Z" />
    </mask>
    <path d="M100.041 112.39h-.83v10.14h.83v-10.14Z" fill={props.fill || 'currentColor'} />
    <path
      d="M99.21 112.39v-1h-1v1h1Zm.831 0h1v-1h-1v1Zm0 10.14v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-9.14h.83v-2h-.83v2Zm-.17-1v10.14h2v-10.14h-2Zm1 9.14h-.83v2h.83v-2Zm.17 1v-10.14h-2v10.14h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#ai)"
    />
    <mask id="aj" fill="#fff">
      <path d="M87.58 115.69h-.83v6.83h.83v-6.83Z" />
    </mask>
    <path d="M87.58 115.69h-.83v6.83h.83v-6.83Z" fill={props.fill || 'currentColor'} />
    <path
      d="M86.75 115.69v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 6.83v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-5.83h.83v-2h-.83v2Zm-.17-1v6.83h2v-6.83h-2Zm1 5.83h-.83v2h.83v-2Zm.17 1v-6.83h-2v6.83h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#aj)"
    />
    <mask id="ak" fill="#fff">
      <path d="M98.65 113.19h-.83v9.34h.83v-9.34Z" />
    </mask>
    <path d="M98.65 113.19h-.83v9.34h.83v-9.34Z" fill={props.fill || 'currentColor'} />
    <path
      d="M97.82 113.19v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 9.34v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-8.34h.83v-2h-.83v2Zm-.17-1v9.34h2v-9.34h-2Zm1 8.34h-.83v2h.83v-2Zm.17 1v-9.34h-2v9.34h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#ak)"
    />
    <mask id="al" fill="#fff">
      <path d="M102.801 116.03h-.83v6.5h.83v-6.5Z" />
    </mask>
    <path d="M102.801 116.03h-.83v6.5h.83v-6.5Z" fill={props.fill || 'currentColor'} />
    <path
      d="M101.971 116.03v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 6.5v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-5.5h.83v-2h-.83v2Zm-.17-1v6.5h2v-6.5h-2Zm1 5.5h-.83v2h.83v-2Zm.17 1v-6.5h-2v6.5h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#al)"
    />
    <mask id="am" fill="#fff">
      <path d="M104.189 120.66h-.83v1.86h.83v-1.86Z" />
    </mask>
    <path d="M104.189 120.66h-.83v1.86h.83v-1.86Z" fill={props.fill || 'currentColor'} />
    <path
      d="M103.359 120.66v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 1.86v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-.86h.83v-2h-.83v2Zm-.17-1v1.86h2v-1.86h-2Zm1 .86h-.83v2h.83v-2Zm.17 1v-1.86h-2v1.86h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#am)"
    />
    <mask id="an" fill="#fff">
      <path d="M105.57 119.38h-.83v3.14h.83v-3.14Z" />
    </mask>
    <path d="M105.57 119.38h-.83v3.14h.83v-3.14Z" fill={props.fill || 'currentColor'} />
    <path
      d="M104.74 119.38v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 3.14v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-2.14h.83v-2h-.83v2Zm-.17-1v3.14h2v-3.14h-2Zm1 2.14h-.83v2h.83v-2Zm.17 1v-3.14h-2v3.14h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#an)"
    />
    <mask id="ao" fill="#fff">
      <path d="M106.949 113.44h-.83v9.08h.83v-9.08Z" />
    </mask>
    <path d="M106.949 113.44h-.83v9.08h.83v-9.08Z" fill={props.fill || 'currentColor'} />
    <path
      d="M106.119 113.44v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 9.08v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-8.08h.83v-2h-.83v2Zm-.17-1v9.08h2v-9.08h-2Zm1 8.08h-.83v2h.83v-2Zm.17 1v-9.08h-2v9.08h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#ao)"
    />
    <mask id="ap" fill="#fff">
      <path d="M108.34 117.17h-.83v5.36h.83v-5.36Z" />
    </mask>
    <path d="M108.34 117.17h-.83v5.36h.83v-5.36Z" fill={props.fill || 'currentColor'} />
    <path
      d="M107.51 117.17v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 5.36v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-4.36h.83v-2h-.83v2Zm-.17-1v5.36h2v-5.36h-2Zm1 4.36h-.83v2h.83v-2Zm.17 1v-5.36h-2v5.36h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#ap)"
    />
    <mask id="aq" fill="#fff">
      <path d="M109.721 113.03h-.83v9.5h.83v-9.5Z" />
    </mask>
    <path d="M109.721 113.03h-.83v9.5h.83v-9.5Z" fill={props.fill || 'currentColor'} />
    <path
      d="M108.891 113.03v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 9.5v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-8.5h.83v-2h-.83v2Zm-.17-1v9.5h2v-9.5h-2Zm1 8.5h-.83v2h.83v-2Zm.17 1v-9.5h-2v9.5h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#aq)"
    />
    <mask id="ar" fill="#fff">
      <path d="M111.109 111.46h-.83v11.07h.83v-11.07Z" />
    </mask>
    <path d="M111.109 111.46h-.83v11.07h.83v-11.07Z" fill={props.fill || 'currentColor'} />
    <path
      d="M110.279 111.46v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 11.07v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-10.07h.83v-2h-.83v2Zm-.17-1v11.07h2v-11.07h-2Zm1 10.07h-.83v2h.83v-2Zm.17 1v-11.07h-2v11.07h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#ar)"
    />
    <mask id="as" fill="#fff">
      <path d="M112.49 114.34h-.83v8.18h.83v-8.18Z" />
    </mask>
    <path d="M112.49 114.34h-.83v8.18h.83v-8.18Z" fill={props.fill || 'currentColor'} />
    <path
      d="M111.66 114.34v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 8.18v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-7.18h.83v-2h-.83v2Zm-.17-1v8.18h2v-8.18h-2Zm1 7.18h-.83v2h.83v-2Zm.17 1v-8.18h-2v8.18h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#as)"
    />
    <mask id="at" fill="#fff">
      <path d="M113.869 113.03h-.83v9.5h.83v-9.5Z" />
    </mask>
    <path d="M113.869 113.03h-.83v9.5h.83v-9.5Z" fill={props.fill || 'currentColor'} />
    <path
      d="M113.039 113.03v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 9.5v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-8.5h.83v-2h-.83v2Zm-.17-1v9.5h2v-9.5h-2Zm1 8.5h-.83v2h.83v-2Zm.17 1v-9.5h-2v9.5h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#at)"
    />
    <mask id="au" fill="#fff">
      <path d="M115.26 112.39h-.83v10.14h.83v-10.14Z" />
    </mask>
    <path d="M115.26 112.39h-.83v10.14h.83v-10.14Z" fill={props.fill || 'currentColor'} />
    <path
      d="M114.43 112.39v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 10.14v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-9.14h.83v-2h-.83v2Zm-.17-1v10.14h2v-10.14h-2Zm1 9.14h-.83v2h.83v-2Zm.17 1v-10.14h-2v10.14h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#au)"
    />
    <mask id="av" fill="#fff">
      <path d="M116.641 115.69h-.83v6.83h.83v-6.83Z" />
    </mask>
    <path d="M116.641 115.69h-.83v6.83h.83v-6.83Z" fill={props.fill || 'currentColor'} />
    <path
      d="M115.811 115.69v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 6.83v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-5.83h.83v-2h-.83v2Zm-.17-1v6.83h2v-6.83h-2Zm1 5.83h-.83v2h.83v-2Zm.17 1v-6.83h-2v6.83h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#av)"
    />
    <mask id="aw" fill="#fff">
      <path d="M118.029 113.19h-.83v9.34h.83v-9.34Z" />
    </mask>
    <path d="M118.029 113.19h-.83v9.34h.83v-9.34Z" fill={props.fill || 'currentColor'} />
    <path
      d="M117.199 113.19v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 9.34v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-8.34h.83v-2h-.83v2Zm-.17-1v9.34h2v-9.34h-2Zm1 8.34h-.83v2h.83v-2Zm.17 1v-9.34h-2v9.34h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#aw)"
    />
    <mask id="ax" fill="#fff">
      <path d="M119.41 113.44h-.83v9.08h.83v-9.08Z" />
    </mask>
    <path d="M119.41 113.44h-.83v9.08h.83v-9.08Z" fill={props.fill || 'currentColor'} />
    <path
      d="M118.58 113.44v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 9.08v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-8.08h.83v-2h-.83v2Zm-.17-1v9.08h2v-9.08h-2Zm1 8.08h-.83v2h.83v-2Zm.17 1v-9.08h-2v9.08h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#ax)"
    />
    <mask id="ay" fill="#fff">
      <path d="M122.18 114.34h-.83v8.18h.83v-8.18Z" />
    </mask>
    <path d="M122.18 114.34h-.83v8.18h.83v-8.18Z" fill={props.fill || 'currentColor'} />
    <path
      d="M121.35 114.34v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 8.18v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-7.18h.83v-2h-.83v2Zm-.17-1v8.18h2v-8.18h-2Zm1 7.18h-.83v2h.83v-2Zm.17 1v-8.18h-2v8.18h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#ay)"
    />
    <mask id="az" fill="#fff">
      <path d="M120.791 115.69h-.83v6.83h.83v-6.83Z" />
    </mask>
    <path d="M120.791 115.69h-.83v6.83h.83v-6.83Z" fill={props.fill || 'currentColor'} />
    <path
      d="M119.961 115.69v-1h-1v1h1Zm.83 0h1v-1h-1v1Zm0 6.83v1h1v-1h-1Zm-.83 0h-1v1h1v-1Zm0-5.83h.83v-2h-.83v2Zm-.17-1v6.83h2v-6.83h-2Zm1 5.83h-.83v2h.83v-2Zm.17 1v-6.83h-2v6.83h2Z"
      fill={props.fill || 'currentColor'}
      mask="url(#az)"
    />
    <path
      d="M220.071 26.65a1.885 1.885 0 0 1-1.161 1.737 1.88 1.88 0 1 1 1.161-1.737v0Z"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M218.19 29.91a3.26 3.26 0 1 0 0-6.52 3.26 3.26 0 0 0 0 6.52Z"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M223.189 26.65a5.003 5.003 0 0 1-3.086 4.62 5 5 0 1 1 3.086-4.62ZM219.001 111.65a2.807 2.807 0 0 1-1.735 2.596 2.809 2.809 0 1 1-1.075-5.406 2.805 2.805 0 0 1 2.599 1.733c.14.341.212.707.211 1.077v0Z"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M216.189 116.55a4.9 4.9 0 1 0 0-9.8 4.9 4.9 0 0 0 0 9.8Z"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M223.689 111.65a7.496 7.496 0 0 1-4.629 6.929 7.503 7.503 0 0 1-8.174-1.626 7.499 7.499 0 1 1 12.803-5.303Z"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default Engineer;
