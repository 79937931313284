import { FC } from 'react';

import { Stack } from '@mui/material';

import { ISearchVuz } from '../../types';
import VuzCardContainer from '../VuzCardContainer';

interface IVuzRecommendationListProps {
  vuzList: ISearchVuz[];
}

const VuzRecommendationList: FC<IVuzRecommendationListProps> = ({ vuzList }) => {
  const vuzCardElements = vuzList.map((vuz, index) => {
    return <VuzCardContainer key={vuz.info.id || vuz.info.name || index} vuz={vuz} />;
  });
  return <Stack gap="16px">{vuzCardElements}</Stack>;
};

export default VuzRecommendationList;
