import { FC } from 'react';

import { Box, FormHelperText } from '@mui/material';
import { BaseInputLabel, FormControl, Input } from 'portfolio3/ui-kit';
import { AutocompleteBase } from 'portfolio3/ui-kit/autocomplete';
import { CommonUiKitSize, IController, InputController, InputRenderMode } from 'portfolio3/ui-kit/types';

interface ICodeNameControl {
  isMobile: boolean;
  inputSize: CommonUiKitSize;
  inputRenderMode: InputRenderMode;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  errorLabel?: string;
  label: string;

  codeOptions: string[];
  codeValue?: string;
  codeController: IController<string | null>;
  codePlaceholder: string;
  nameValue?: string;
  nameController: InputController;
  namePlaceholder: string;

  isNameControlShown: boolean;
}

const StringAutocomplete = AutocompleteBase<string, false, false, false>;

/**
 * Контрол для отображения списка из предопределенных значений и инпута для ввода пользовательского значения
 */
const CodeNameControl: FC<ICodeNameControl> = ({
  isMobile,
  inputSize,
  inputRenderMode,
  required,
  disabled,
  error,
  errorLabel,
  label,
  codeOptions,
  codeValue,
  codeController,
  codePlaceholder,
  nameValue,
  nameController,
  namePlaceholder,
  isNameControlShown,
}) => {
  const isCodeError = error && !codeValue;
  const isNameError = error && !isCodeError;

  return (
    <Box className="code-name-control" sx={{ width: '100%' }}>
      <FormControl
        required={required}
        disabled={disabled}
        error={isCodeError}
        renderMode={inputRenderMode}
        inputSize={inputSize}
        label={<BaseInputLabel>{label}</BaseInputLabel>}
        helper={isCodeError && <FormHelperText>{errorLabel}</FormHelperText>}
        control={
          <StringAutocomplete
            inputSize={inputSize}
            inputRenderMode={inputRenderMode}
            options={codeOptions}
            controller={codeController}
            placeholder={codePlaceholder}
            value={codeValue}
          />
        }
      />
      {isNameControlShown && (
        <FormControl
          sx={{ marginTop: isMobile ? 0 : '12px' }}
          required={required}
          disabled={disabled}
          error={isNameError}
          renderMode={inputRenderMode}
          label={<></>}
          helper={isNameError && <FormHelperText>{errorLabel}</FormHelperText>}
          control={
            <Input
              renderMode={inputRenderMode}
              size={inputSize}
              placeholder={namePlaceholder}
              value={nameValue}
              controller={nameController}
            />
          }
        />
      )}
    </Box>
  );
};

export default CodeNameControl;
