/* eslint-disable max-len */
import React from 'react';

import { SvgIcon } from '@mui/material';
import { getAccentColor } from 'portfolio3/styles';

import { IconRouteProps } from './types';

const IconRouteRecommendation: React.FC<IconRouteProps> = ({ outerFill, ...props }) => (
  <SvgIcon width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="30" height="30" rx="8" fill={outerFill || getAccentColor('pink', '100')} />
    <g clipPath="url(#a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m11.174 11.91 4.617-4.617a1.24 1.24 0 0 1 1.758-.008c.3.3.425.725.342 1.141l-.792 3.817h4.709c1.791 0 3 1.833 2.3 3.483l-2.717 6.342a1.678 1.678 0 0 1-1.533 1.008h-7.5c-.917 0-1.667-.75-1.667-1.666v-8.325c0-.442.175-.867.483-1.175Zm-2.15 9.5c0 .916-.75 1.666-1.666 1.666-.917 0-1.667-.75-1.667-1.666v-6.667c0-.917.75-1.667 1.667-1.667.916 0 1.666.75 1.666 1.667v6.667Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M5 5h20v20H5z" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default IconRouteRecommendation;
