import { greenColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root: SxStyles = {
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: 0,

  height: '6px',
};

export const testAnimation: SxStyles = {
  '@keyframes loading': {
    '0%': {
      width: '0%',
      opacity: 0,
    },
    '2%': {
      opacity: 1,
    },
    '20%': {
      width: '85%',
      opacity: 1,
    },
    '100%': {
      width: '100%',
      opacity: 1,
    },
  },

  animation: 'loading 50s forwards',
};

export const exitingAnimation: SxStyles = {
  '@keyframes exiting': {
    '0%': {
      width: '0%',
      opacity: 1,
    },
    '80%': {
      opacity: 1,
    },
    '100%': {
      width: '100%',
      opacity: 0,
    },
  },

  animation: 'exiting 350ms',
};

export const loader: SxStyles = {
  position: 'absolute',
  inset: 0,

  height: 'inherit',
  backgroundColor: greenColors[100],

  opacity: 0,
  width: 0,
};
