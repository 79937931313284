/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';
import genericReducers from '../genericReducer';
import { IReducers } from '../index';

import { GET_ORGANIZATIONS } from '../../actions/employees';
import { REQUEST, FAILURE, SUCCESS } from '../../actions/utils';

import { ICommonResponse, OrganizationRefItem } from '../../api/types';

export type OrganizationState = ICommonResponse<OrganizationRefItem>;

const initialState: OrganizationState = {
  content: [],
  loading: false,
};

const organizations: IReducers<OrganizationState> = {
  [GET_ORGANIZATIONS[REQUEST]]: genericReducers().get.request,
  [GET_ORGANIZATIONS[SUCCESS]]: genericReducers().get.success,
  [GET_ORGANIZATIONS[FAILURE]]: genericReducers().get.failure
};

export default connectReducers(initialState, organizations);
