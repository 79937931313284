import { forwardRef, ForwardRefRenderFunction } from 'react';

import { Box, BoxProps } from '@mui/material';
import { SxStyles } from 'types';
import { mergeSx } from 'utils';

const styles = (line: number): SxStyles => ({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: String(line),
  overflow: 'hidden',
});

interface ITrimTextMultiLineProps extends BoxProps {
  line: number;
}

const TrimTextMultiLine: ForwardRefRenderFunction<HTMLDivElement, ITrimTextMultiLineProps> = (
  { line, children, sx, ...props },
  ref,
) => {
  return (
    <Box className={`trim-text-multi-line-${line}`} sx={mergeSx(styles(line), sx)} {...props} ref={ref}>
      {children}
    </Box>
  );
};

export default forwardRef(TrimTextMultiLine);
