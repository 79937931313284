import { FC, Fragment } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Box, useMediaQuery } from '@mui/material';
import {
  clearIndependentDiagnosisSettingAction,
  saveIndependentDiagnosisSettingsActions,
  saveVisibilitySettingsActions,
} from 'actions';
import { IChildInfo, ISaveIndependentDiagnosisSettingsRequest, ISaveVisibilitySettingsRequest } from 'api/types';
import { PortfolioSectionVisibility } from 'containers/redesignStudent';
import FixedWidthContainer from 'portfolio3/components/common/FixedWidthContainer';
import { SettingsHeader } from 'portfolio3/components/common/headers';
import PaddingContainer from 'portfolio3/components/common/PaddingContainer';
import { NeutralColors } from 'portfolio3/styles';
import { commonTheme } from 'portfolio3/styles/theme';
import { IRootState } from 'reducers';
import { IndependentDiagnosisSettingsState } from 'reducers/independentDiagnosisSettings';
import { localVisibilitySettingsSelector } from 'selectors';
import { IViewVisibilitySetting } from 'types';
import { mapViewSettingsToSaveSettingsRequest } from 'utils';

import { IHeaderContainerCommonProps } from '../types';
import { studentHeaderContainerStyles } from '../utils';

interface ISettingsHeaderContainerProps extends IHeaderContainerCommonProps {
  currentStudent: IChildInfo;
  independentDiagnosisSettings: IndependentDiagnosisSettingsState;
  localVisibilitySettings: IViewVisibilitySetting[];
  clearIndependentDiagnosisSetting: typeof clearIndependentDiagnosisSettingAction;
  saveVisibilitySettings: typeof saveVisibilitySettingsActions.request;
  saveIndependentDiagnosisSettings: typeof saveIndependentDiagnosisSettingsActions.request;
}

const SettingsHeaderContainer: FC<ISettingsHeaderContainerProps> = ({
  isFullWidth,
  currentStudent,
  independentDiagnosisSettings,
  localVisibilitySettings,
  clearIndependentDiagnosisSetting,
  saveVisibilitySettings,
  saveIndependentDiagnosisSettings,
}) => {
  const history = useHistory();
  const isFullWidthBreakpoint = useMediaQuery(commonTheme.breakpoints.down('commonXl'));

  const Container = isFullWidthBreakpoint || isFullWidth ? Fragment : FixedWidthContainer;
  const isSaveSettingsActionBlocked = localVisibilitySettings.every((setting) => !setting.computedVisibility);

  const handleSettingsSave = () => {
    if (!currentStudent.meshId) {
      return;
    }

    const mappedSettings = mapViewSettingsToSaveSettingsRequest(localVisibilitySettings);

    const saveSettingsRequest: ISaveVisibilitySettingsRequest = {
      personId: currentStudent.meshId,
      setting: {
        visibilitySettings: mappedSettings,
      },
    };
    saveVisibilitySettings(saveSettingsRequest, currentStudent.meshId, history);

    const settingEntries = Object.entries(independentDiagnosisSettings.settings);
    const saveIndependentDiagnosisRequest: ISaveIndependentDiagnosisSettingsRequest = {
      personId: currentStudent.meshId,
      changeCard: settingEntries.map((entry) => {
        const [recordId, isVisible] = entry;
        return {
          recordId,
          isVisible,
        };
      }),
    };
    saveIndependentDiagnosisSettings(saveIndependentDiagnosisRequest);
    clearIndependentDiagnosisSetting();
  };

  return (
    <Box sx={{ zIndex: 1000, position: 'sticky', top: 0 }} className="settings-header-container">
      <Container>
        <Box sx={studentHeaderContainerStyles}>
          <PaddingContainer
            backgroundColor={NeutralColors.light_neutrals_0}
            sx={{
              borderBottom: '1px solid',
              borderColor: NeutralColors.light_neutrals_300,
            }}
          >
            <SettingsHeader isSaveButtonDisabled={isSaveSettingsActionBlocked} onSettingsSave={handleSettingsSave} />
          </PaddingContainer>
          <PaddingContainer backgroundColor={NeutralColors.light_neutrals_0}>
            <PortfolioSectionVisibility />
          </PaddingContainer>
        </Box>
      </Container>
    </Box>
  );
};

export default connect(
  (state: IRootState) => ({
    currentStudent: state.currentStudent,
    independentDiagnosisSettings: state.independentDiagnosisSettings,
    localVisibilitySettings: localVisibilitySettingsSelector(state),
  }),
  {
    clearIndependentDiagnosisSetting: clearIndependentDiagnosisSettingAction,
    saveVisibilitySettings: saveVisibilitySettingsActions.request,
    saveIndependentDiagnosisSettings: saveIndependentDiagnosisSettingsActions.request,
  },
)(SettingsHeaderContainer);
