/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import CommonDialog, { ICommonDialog } from '../../../ui-kit/dialog/CommonDialog';

type IUncontrollableDialogProps = ICommonDialog

const UncontrollableDialog: React.FC<IUncontrollableDialogProps> = ({
  className,
  children,
  isOpen,
  title,
  description
}) => (
  <CommonDialog
    isOpen={isOpen}
    description={description}
    title={title}
    className={`dialog ${className}`}
    unControllable
  >
    {children}
  </CommonDialog>
);

export default UncontrollableDialog;
