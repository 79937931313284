import { apiPaths } from 'const';
import { serializeQueryParams } from 'utils';

import { mainApi, mosVuzApi } from './impl';
import {
  IEgeExamSubject,
  IFavoriteUniversity,
  IMosRuPaginatedResponse,
  ISpeciality,
  IVuz,
  IVuzFaculty,
  IVuzFacultySearchQueryParams,
  IVuzSearchQueryParams,
  IVuzSpeciality,
} from './types';

// поиск вузов
export const getVuzList = (queryParams: IVuzSearchQueryParams) =>
  mosVuzApi.get<IMosRuPaginatedResponse<IVuz>>(`/search${serializeQueryParams(queryParams)}`);

// возвращает общее количество вузов, которые нашлись по поисковому запросу
export const getVuzListCount = (queryParams: IVuzSearchQueryParams) =>
  mosVuzApi.get<number>(`/search_count${serializeQueryParams(queryParams)}`);

// получение факультетов вуза со списком специальностей (с возможностью фильтрации)
export const getVuzFaculties = (id: number, queryParams: IVuzFacultySearchQueryParams) =>
  mosVuzApi.get<IMosRuPaginatedResponse<IVuzFaculty>>(`/vuz/${id}/faculties${serializeQueryParams(queryParams)}`);

// получение общего количества направлений вуза
export const getVuzSpecialities = (id: number, queryParams: IVuzFacultySearchQueryParams) =>
  mosVuzApi.get<IMosRuPaginatedResponse<IVuzSpeciality>>(`/vuz/${id}/specialities${serializeQueryParams(queryParams)}`);

// справочные данные
export const getEgeExamSubjectsRef = () => mosVuzApi.get<IEgeExamSubject[]>('/subjects');
export const getSpecialitiesRef = () => mosVuzApi.get<ISpeciality[]>('/specialities');

// Избранное
export const getFavoriteUniversityList = (personId: string) =>
  mainApi.get<IFavoriteUniversity[] | null>(`${apiPaths.STUDENT}/${personId}/favorite-university`);
export const addFavoriteUniversity = (personId: string, universityId: number) =>
  mainApi.post(`${apiPaths.STUDENT}/${personId}/favorite-university${serializeQueryParams({ universityId })}`, null);
export const deleteFavoriteUniversity = (personId: string, universityId: number) =>
  mainApi.delete(`${apiPaths.STUDENT}/${personId}/favorite-university${serializeQueryParams({ universityId })}`);
