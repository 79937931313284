import { FC, ReactNode } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

interface IInfoBlockProps {
  leftEntity: ReactNode;
  text: string;
  size: number;
}

const infoBlockStyles =
  (size: number): SxStyles =>
  () => {
    return {
      width: size,
      minWidth: size,
      height: '48px',
      padding: '8px 12px',

      border: `1px solid ${NeutralColors.light_neutrals_300}`,
      borderRadius: '12px',
    };
  };

const InfoBlock: FC<IInfoBlockProps> = ({ leftEntity, text, size }) => {
  return (
    <Box sx={infoBlockStyles(size)} className="info-block">
      <Stack gap="8px" direction="row">
        {leftEntity}
        <Typography variant="Paragraph SM/Regular">{text}</Typography>
      </Stack>
    </Box>
  );
};

export default InfoBlock;
