import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { IconArrowRight } from 'icons';
import { Button } from 'portfolio3/ui-kit/button';
import { SxStyles } from 'types';
import { mergeSx } from 'utils';

import * as styles from './styles';

interface IAdvertProps {
  title: string;
  prompt: string;
  linkText: string;
  href?: string;
  contentSx?: SxStyles;
  onLinkClick?: () => void;
}

const Advert: FC<IAdvertProps> = ({ title, prompt, linkText, href, contentSx, onLinkClick }) => {
  return (
    <Box sx={styles.root}>
      <Box sx={mergeSx(styles.content, contentSx)}>
        <Typography variant="Headings/H6">{title}</Typography>
        <Typography variant="Paragraph LG/Regular" sx={styles.prompt}>
          {prompt}
        </Typography>
        <Box
          component="a"
          href={href}
          target="_blank"
          rel="noreferrer"
          sx={styles.link}
          onClick={onLinkClick}
          tabIndex={-1}
        >
          <Button iconRight={<IconArrowRight />}>{linkText}</Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Advert;
