import { FC } from 'react';

import { styled } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';
import { getOutlineToggleTokens } from 'portfolio3/ui-kit/utils/tokens';

import { BaseToggleLabel } from '../bases';
import { IBaseToggleLabelProps } from '../bases';

type StyledToggleLabelOutlineProps = IBaseToggleLabelProps;

const StyledToggleLabelOutline = styled(BaseToggleLabel)<StyledToggleLabelOutlineProps>(({ size, disabled }) => {
  const { height, paddingInline } = size ? getOutlineToggleTokens(size) : getOutlineToggleTokens('medium');
  return {
    width: 'max-content',
    height,
    paddingInline,

    backgroundColor: disabled ? NeutralColors.light_neutrals_200 : NeutralColors.light_neutrals_0,

    border: `1px solid ${NeutralColors.light_neutrals_400}`,
    borderRadius: '12px',
  };
});

const ToggleLabelOutline: FC<IBaseToggleLabelProps> = (props) => <StyledToggleLabelOutline {...props} />;

export default ToggleLabelOutline;
