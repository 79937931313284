/* eslint-disable prettier/prettier */
/* eslint-disable */

import { validateEmptyString, validateProperties } from '../common';
import { SectionCodes } from '../../../../../const';
import { CommonValidator } from '../types';
import { IPortfolioEntryFormData } from 'portfolio3/features/dataEntryForm';

const projectValidators: Record<string, CommonValidator> = {
  [SectionCodes.science]: isScienceProjectValid
};

export const isProjectValid = (formData: IPortfolioEntryFormData): boolean => {
  const { categoryCode } = formData;
  const commonValidator = () => validateProperties(getCommonProjectProperties(formData));

  const validator = categoryCode ? projectValidators[categoryCode] : null;
  return validator ? validator(formData, commonValidator) : false;
};

// SectionCodes.science
function isScienceProjectValid(formData: IPortfolioEntryFormData, commonValidator: () => boolean): boolean {
  return validateProperties([
    commonValidator(),
    validateEmptyString(formData.name),
    formData.categoryCode === SectionCodes.science,
    formData.subCategory,
    formData.name,
    formData.subjectId.length > 0
  ]);
}

function getCommonProjectProperties(formData: IPortfolioEntryFormData): unknown[] {
  const { startDate, eventLevel } = formData;

  return [startDate, eventLevel];
}
