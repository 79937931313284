/* eslint-disable max-len */
import React from 'react';

const IconDiploma: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_10155_209098)">
      <path
        d="M42.7225 0H8.88912C7.31723 0 6.04297 1.27427 6.04297 2.84615V47.1538C6.04297 48.7257 7.31723 50 8.88912 50H42.7225C44.2943 50 45.5686 48.7257 45.5686 47.1538V2.84615C45.5686 1.27427 44.2943 0 42.7225 0Z"
        fill="#A9BEFF"
      />
      <path
        d="M39.9993 6.23693C39.0185 4.71129 39.2429 3.02539 39.2429 3.02539H12.3711C12.3711 3.02539 12.5955 4.71129 11.6147 6.23693C10.6019 7.80744 8.62109 7.94206 8.62109 7.94206V42.051C8.62109 42.051 10.6019 42.1856 11.6147 43.7562C12.5955 45.2818 12.3711 46.9677 12.3711 46.9677H39.2493C39.2493 46.9677 39.0249 45.2818 40.0057 43.7562C41.0185 42.1856 42.9993 42.051 42.9993 42.051V7.94206C42.9993 7.94206 41.0185 7.80744 40.0057 6.23693H39.9993Z"
        fill="#748FFC"
      />
      <mask
        id="mask0_10155_209098"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="8"
        y="3"
        width="35"
        height="44"
      >
        <path
          d="M39.9973 6.23693C39.0166 4.71129 39.2409 3.02539 39.2409 3.02539H12.3691C12.3691 3.02539 12.5935 4.71129 11.6127 6.23693C10.5999 7.80744 8.61914 7.94206 8.61914 7.94206V42.051C8.61914 42.051 10.5999 42.1856 11.6127 43.7562C12.5935 45.2818 12.3691 46.9677 12.3691 46.9677H39.2473C39.2473 46.9677 39.023 45.2818 40.0038 43.7562C41.0166 42.1856 42.9973 42.051 42.9973 42.051V7.94206C42.9973 7.94206 41.0166 7.80744 40.0038 6.23693H39.9973Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_10155_209098)">
        <path d="M44.5502 6.27539H7.05664V7.93565H44.5502V6.27539Z" fill="#E83F40" />
        <path d="M44.5502 4.62158H7.05664V6.28184H44.5502V4.62158Z" fill="#748FFC" />
        <path d="M44.5502 3.02539H7.05664V4.68565H44.5502V3.02539Z" fill="white" />
      </g>
      <path
        d="M28.1005 30.3008L20.3826 42.3457L19.2479 37.3072L14.4082 38.4867L22.4915 26.5957L28.1005 30.3008Z"
        fill="#A9BEFF"
      />
      <path
        d="M22.6133 30.3013L30.3312 42.3462L31.4658 37.3077L36.3056 38.4872L28.2223 26.5962L22.6133 30.3013Z"
        fill="#A9BEFF"
      />
      <path
        d="M22.1385 11.9998C23.9847 9.25617 28.0488 9.35232 29.7667 12.1728L29.818 12.2626C33.0936 11.6664 35.9142 14.6216 35.1706 17.8651L35.1449 17.9677L35.2283 18.0254C37.9526 19.9421 37.8372 24.019 35.0039 25.769L35.0231 25.8716C35.6385 29.1216 32.677 31.91 29.4719 31.1023C27.6257 33.8459 23.5616 33.7498 21.8436 30.9292L21.7924 30.8395C18.5167 31.4357 15.6962 28.4805 16.4398 25.2369L16.4654 25.1344L16.3821 25.0767C13.6577 23.16 13.7731 19.0831 16.6065 17.3331L16.5872 17.2305C15.9718 13.9805 18.9334 11.1921 22.1385 11.9998Z"
        fill="#A9BEFF"
      />
      <path
        d="M31.2549 19.8201C31.2549 19.6599 31.1331 19.5573 30.8895 19.5189L27.601 19.0381L26.1267 16.0573C26.0433 15.8778 25.9343 15.7881 25.8061 15.7881C25.6779 15.7881 25.569 15.8778 25.4856 16.0573L24.0113 19.0381L20.7228 19.5189C20.4792 19.5573 20.3574 19.6599 20.3574 19.8201C20.3574 19.9099 20.4151 20.0189 20.5241 20.1342L22.9087 22.4483L22.3446 25.724C22.3382 25.7881 22.3318 25.8265 22.3318 25.8522C22.3318 25.9419 22.3574 26.0189 22.4023 26.083C22.4472 26.1471 22.5177 26.1791 22.6074 26.1791C22.6843 26.1791 22.7741 26.1535 22.8702 26.1022L25.8061 24.5573L28.742 26.1022C28.8318 26.1535 28.9215 26.1791 29.0049 26.1791C29.0946 26.1791 29.1587 26.1471 29.2036 26.083C29.2484 26.0189 29.2741 25.9419 29.2741 25.8522C29.2741 25.7945 29.2741 25.7496 29.2677 25.724L28.7036 22.4483L31.0818 20.1342C31.1972 20.0189 31.2549 19.9163 31.2549 19.8201Z"
        fill="#748FFC"
      />
      <path
        d="M34.6766 29.3395C33.7536 30.8844 31.8882 31.8075 29.9266 31.3203L29.5677 31.2306L29.3625 31.5382C27.6125 34.1472 23.7471 34.0575 22.1189 31.3716L21.8753 30.9677L21.3497 31.0639C19.51 31.3972 17.8305 30.5254 16.9395 29.1216"
        stroke="#748FFC"
        strokeWidth="0.641026"
        strokeMiterlimit="10"
      />
      <path
        d="M22.8112 13.7498L23.1061 13.3139C24.4779 11.2755 27.4971 11.346 28.7728 13.4421L29.0869 13.9614L29.5997 13.8652C32.0356 13.4229 34.1253 15.6152 33.5741 18.0255L33.4394 18.6152L33.933 18.9614C35.9587 20.3844 35.8689 23.4101 33.7664 24.7114L33.3241 24.987L33.4394 25.5832C33.8946 27.9934 31.6959 30.0703 29.3176 29.4678L28.8048 29.3396L28.51 29.7755C27.1382 31.8139 24.1189 31.7434 22.8433 29.6473L22.5292 29.128L22.0164 29.2242C19.5805 29.6665 17.4907 27.4742 18.042 25.0639L18.1766 24.4742L17.683 24.128C15.6574 22.705 15.7471 19.6793 17.8497 18.378L18.292 18.1024L18.1766 17.5062C17.7215 15.096 19.9202 13.0191 22.2984 13.6216L22.8112 13.7498Z"
        stroke="#748FFC"
        strokeWidth="0.641026"
        strokeMiterlimit="10"
      />
      <path
        d="M10.6973 10V40C10.6973 40 12.4409 40.1218 13.3255 41.5C14.1909 42.8397 13.9857 44.3205 13.9857 44.3205H37.6203C37.6203 44.3205 37.4216 42.8397 38.2806 41.5C39.1716 40.1218 40.9088 40 40.9088 40V10"
        stroke="#A9BEFF"
        strokeWidth="0.641026"
        strokeMiterlimit="10"
      />
      <mask
        id="mask1_10155_209098"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="6"
        y="0"
        width="40"
        height="50"
      >
        <path
          d="M42.7244 0H8.89108C7.31919 0 6.04492 1.27427 6.04492 2.84615V47.1538C6.04492 48.7257 7.31919 50 8.89108 50H42.7244C44.2963 50 45.5706 48.7257 45.5706 47.1538V2.84615C45.5706 1.27427 44.2963 0 42.7244 0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_10155_209098)">
        <path
          opacity="0.3"
          d="M4.25045 28.5702C26.9472 28.5702 45.3466 10.1709 45.3466 -12.5259C45.3466 -35.2227 26.9472 -53.6221 4.25045 -53.6221C-18.4463 -53.6221 -36.8457 -35.2227 -36.8457 -12.5259C-36.8457 10.1709 -18.4463 28.5702 4.25045 28.5702Z"
          fill="url(#paint0_linear_10155_209098)"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_10155_209098"
        x1="-10.1919"
        y1="-39.59"
        x2="21.6222"
        y2="20.0318"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0" />
        <stop offset="0.35" stopColor="white" stopOpacity="0" />
        <stop offset="0.49" stopColor="white" stopOpacity="0.03" />
        <stop offset="0.59" stopColor="white" stopOpacity="0.08" />
        <stop offset="0.67" stopColor="white" stopOpacity="0.15" />
        <stop offset="0.74" stopColor="white" stopOpacity="0.24" />
        <stop offset="0.8" stopColor="white" stopOpacity="0.35" />
        <stop offset="0.85" stopColor="white" stopOpacity="0.47" />
        <stop offset="0.9" stopColor="white" stopOpacity="0.62" />
        <stop offset="0.95" stopColor="white" stopOpacity="0.79" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <clipPath id="clip0_10155_209098">
        <rect width="50" height="50" fill="white" transform="translate(0.806641)" />
      </clipPath>
    </defs>
  </svg>
);

export default IconDiploma;
