import { CSSProperties, FC } from 'react';

import { Box, useMediaQuery } from '@mui/material';
import { IconSchoolFilled } from 'icons';
import { NeutralColors } from 'portfolio3/styles';
import { commonTheme } from 'portfolio3/styles/theme';

interface IVuzIconProps {
  logo?: string;
}

const VuzIcon: FC<IVuzIconProps> = ({ logo }) => {
  const isSmallScreen = useMediaQuery(commonTheme.breakpoints.down('commonSm'));
  const size = isSmallScreen ? '40px' : '48px';
  const imgStyles: CSSProperties = {
    width: size,
    height: size,
  };
  const padding = isSmallScreen ? '8px' : '12px 11px';
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        minWidth: size,
        maxWidth: size,
        height: size,
        padding: logo ? '0' : padding,

        border: `1px solid ${NeutralColors.light_neutrals_300}`,
        borderRadius: '12px',
        backgroundColor: NeutralColors.light_neutrals_200,
        overflow: 'hidden',
      }}
      className="logo"
    >
      {logo ? (
        <img style={imgStyles} src={logo} alt="Логотип" />
      ) : (
        <IconSchoolFilled fill={NeutralColors.light_neutrals_500} />
      )}
    </Box>
  );
};

export default VuzIcon;
