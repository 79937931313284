import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconQr: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      // eslint-disable-next-line max-len
      d="M5.25 3A2.261 2.261 0 0 0 3 5.25v3.5A2.261 2.261 0 0 0 5.25 11h3.5A2.261 2.261 0 0 0 11 8.75v-3.5A2.261 2.261 0 0 0 8.75 3h-3.5Zm10 0A2.261 2.261 0 0 0 13 5.25v3.5A2.261 2.261 0 0 0 15.25 11h3.5A2.261 2.261 0 0 0 21 8.75v-3.5A2.261 2.261 0 0 0 18.75 3h-3.5Zm-10 1.5h3.5c.423 0 .75.327.75.75v3.5c0 .423-.327.75-.75.75h-3.5a.739.739 0 0 1-.75-.75v-3.5c0-.423.327-.75.75-.75Zm10 0h3.5c.423 0 .75.327.75.75v3.5c0 .423-.327.75-.75.75h-3.5a.739.739 0 0 1-.75-.75v-3.5c0-.423.327-.75.75-.75ZM6 6v2h2V6H6Zm10 0v2h2V6h-2ZM5.25 13A2.261 2.261 0 0 0 3 15.25v3.5A2.261 2.261 0 0 0 5.25 21h3.5A2.261 2.261 0 0 0 11 18.75v-3.5A2.261 2.261 0 0 0 8.75 13h-3.5ZM13 13v2.75h2.75V13H13Zm2.75 2.75v2.5h2.5v-2.5h-2.5Zm2.5 0H21V13h-2.75v2.75Zm0 2.5V21H21v-2.75h-2.75Zm-2.5 0H13V21h2.75v-2.75ZM5.25 14.5h3.5c.423 0 .75.327.75.75v3.5c0 .423-.327.75-.75.75h-3.5a.739.739 0 0 1-.75-.75v-3.5c0-.423.327-.75.75-.75ZM6 16v2h2v-2H6Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconQr;
