import { FC, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { ILinkVisibilitySettings } from 'api/types';
import { AdminSectionSettingCodes, AdminSectionSettingNames, sectionRoutes } from 'const';
import { GlobalFooterContainer } from 'portfolio3/containers/footers';
import { GlobalHeaderContainer, StudentHeaderContainer } from 'portfolio3/containers/headers';
import { IRootState } from 'reducers';
import { AdminSettingsState } from 'reducers/admin/adminSettings';
import { compose } from 'redux';
import { isLinkSectionVisible } from 'utils';

import {
  withBottomNavigation,
  withErrorBoundary,
  withFixedWidthContainer,
  withFooter,
  withHeader,
  withReferenceData,
  withRendererType,
  withStudentPaddingContainer,
  withThemeBackground,
} from '../hocs';

const ProfileSection = lazy(() => import('portfolio3/containers/sections/ProfileSection'));
const StudySection = lazy(() => import('portfolio3/containers/sections/StudySection'));
const ScienceSection = lazy(() => import('portfolio3/containers/sections/ScienceSection'));
const SportSection = lazy(() => import('portfolio3/containers/sections/SportSection'));
const CreationSection = lazy(() => import('portfolio3/containers/sections/CreationSection'));
const CultureSection = lazy(() => import('portfolio3/containers/sections/CultureSection'));
const CivilSection = lazy(() => import('portfolio3/containers/sections/CivilSection'));
const ProfessionSection = lazy(() => import('portfolio3/containers/sections/ProfessionSection'));
const RewardSection = lazy(() => import('portfolio3/containers/sections/RewardSection'));

interface ILinkRendererProps {
  linkVisibilitySettings: ILinkVisibilitySettings;
  adminVisibilitySettings: AdminSettingsState;
}

const LinkRenderer: FC<ILinkRendererProps> = ({ linkVisibilitySettings, adminVisibilitySettings }) => {
  const match = useRouteMatch();
  const { display } = linkVisibilitySettings.content || {};

  return (
    <Suspense fallback={<></>}>
      <Switch>
        {isLinkSectionVisible(
          display?.profile,
          AdminSectionSettingNames[AdminSectionSettingCodes.profile],
          adminVisibilitySettings.content?.sections,
        ) && <Route path={`${match.url}${sectionRoutes.PROFILE}`} render={() => <ProfileSection />} />}
        {isLinkSectionVisible(
          display?.studies,
          AdminSectionSettingNames[AdminSectionSettingCodes.study],
          adminVisibilitySettings.content?.sections,
        ) && <Route path={`${match.url}${sectionRoutes.STUDY}`} render={() => <StudySection />} />}
        {isLinkSectionVisible(
          display?.science,
          AdminSectionSettingNames[AdminSectionSettingCodes.science],
          adminVisibilitySettings.content?.sections,
        ) && <Route path={`${match.url}${sectionRoutes.SCIENCE}`} render={() => <ScienceSection />} />}
        {isLinkSectionVisible(
          display?.sport,
          AdminSectionSettingNames[AdminSectionSettingCodes.sport],
          adminVisibilitySettings.content?.sections,
        ) && <Route path={`${match.url}${sectionRoutes.SPORT}`} render={() => <SportSection />} />}
        {isLinkSectionVisible(
          display?.creation,
          AdminSectionSettingNames[AdminSectionSettingCodes.creation],
          adminVisibilitySettings.content?.sections,
        ) && <Route path={`${match.url}${sectionRoutes.CREATION}`} render={() => <CreationSection />} />}
        {isLinkSectionVisible(
          display?.culture,
          AdminSectionSettingNames[AdminSectionSettingCodes.culture],
          adminVisibilitySettings.content?.sections,
        ) && <Route path={`${match.url}${sectionRoutes.CULTURE}`} render={() => <CultureSection />} />}
        {isLinkSectionVisible(
          display?.civil,
          AdminSectionSettingNames[AdminSectionSettingCodes.civil],
          adminVisibilitySettings.content?.sections,
        ) && <Route path={`${match.url}${sectionRoutes.CIVIL}`} render={() => <CivilSection />} />}
        {isLinkSectionVisible(
          display?.profession,
          AdminSectionSettingNames[AdminSectionSettingCodes.profession],
          adminVisibilitySettings.content?.sections,
        ) && <Route path={`${match.url}${sectionRoutes.PROFESSION}`} render={() => <ProfessionSection />} />}
        <Route path={`${match.url}${sectionRoutes.REWARD}`} render={() => <RewardSection />} />
      </Switch>
    </Suspense>
  );
};

const withRedux = connect((state: IRootState) => ({
  linkVisibilitySettings: state.linkVisibilitySettings,
  adminVisibilitySettings: state.adminSettings,
}));

const isSideContainersFullWidth = false;

export default compose<FC>(
  withRedux,
  withRendererType('link'),
  withErrorBoundary(),
  withReferenceData(),
  withThemeBackground(),
  // уточнить про глобальный хедер в линк моде
  withHeader(GlobalHeaderContainer, isSideContainersFullWidth),
  withHeader(StudentHeaderContainer, isSideContainersFullWidth),
  withBottomNavigation(),
  withFooter(GlobalFooterContainer, isSideContainersFullWidth),
  withFixedWidthContainer(),
  withStudentPaddingContainer(),
)(LinkRenderer);
