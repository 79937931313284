import { SxStyles } from 'types';

export const root = (color: string, backgroundColor: string, isExtendedBackground: boolean): SxStyles => ({
  position: 'relative',

  paddingInline: '20px',
  paddingTop: '36px',
  paddingBottom: '12px',

  color,
  backgroundColor,

  '&::after': {
    content: isExtendedBackground ? '""' : 'none',
    position: 'absolute',
    left: 0,
    bottom: 0,
    transform: 'translateY(100%)',

    width: '100%',
    height: '22px',
    backgroundColor,
  },
});

export const crossButton: SxStyles = {
  position: 'absolute',
  top: '12px',
  right: '16px',
};

export const exitBackButton: SxStyles = {
  width: '24px',
  height: '24px',
};

export const titleContainer: SxStyles = {
  display: 'grid',
  gridAutoFlow: 'column',
  alignItems: 'center',
  gap: '12px',
};

export const title: SxStyles = {
  flexGrow: 1,
  flexShrink: 1,
  textAlign: 'center',

  fontWeight: 700,
  fontSize: '18px',
  lineHeight: '28px',
};
