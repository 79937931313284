/* eslint-disable prettier/prettier */
/* eslint-disable */

import { FC, PropsWithChildren } from 'react';

import clsx from 'clsx';

import RecommendationContainerIllustration, {
  IRecommendationContainerIllustrationProps
} from './illustration';
import { findChildComponentByType } from '../../../../../../utils';

import './index.scss';

interface IRecommendationContainerSubcomponents {
  Illustration: FC<IRecommendationContainerIllustrationProps>;
  Content: FC<PropsWithChildren>;
  Footer: FC<PropsWithChildren>;
}

interface IRecommendationContainerProps {
  className: string;
}

const RecommendationContainer: FC<IRecommendationContainerProps & PropsWithChildren> & IRecommendationContainerSubcomponents = ({
  className,
  children
}) => {

  const RecommendationIllustration = findChildComponentByType(children, RecommendationContainer.Illustration);
  const RecommendationContent = findChildComponentByType(children, RecommendationContainer.Content);
  const RecommendationFooter = findChildComponentByType(children, RecommendationContainer.Footer);

  return (
    <div className={clsx('recommendation-container', className)}>
      <div className="recommendation-container__illustration-container">
        {RecommendationIllustration}
      </div>

      <div className="recommendation-container__content">
        {RecommendationContent}
      </div>

      <footer className="recommendation-container__footer">
        {RecommendationFooter}
      </footer>
  </div>
  );
};

RecommendationContainer.Illustration = RecommendationContainerIllustration;
RecommendationContainer.Content = ({ children }: PropsWithChildren) => <>{children}</>;
RecommendationContainer.Footer = ({ children }: PropsWithChildren) => <>{children}</>;

export default RecommendationContainer;
