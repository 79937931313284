import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const paper: SxStyles = (theme) => {
  return {
    display: 'flex',

    gap: '12px',
    padding: '20px',

    borderRadius: '12px',
    border: `1px solid ${NeutralColors.light_neutrals_300}`,
    boxShadow: '0px 1px 2px 0px rgba(41, 41, 64, 0.07);',
    backgroundColor: NeutralColors.light_neutrals_0,

    [theme.breakpoints.down('commonSm')]: {
      flexDirection: 'column',
      padding: '12px',
    },
  };
};

export const paperIcon: SxStyles = (theme) => {
  return {
    minWidth: '170px',
    height: '110px',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    borderRadius: '12px',
    border: `1px solid ${NeutralColors.light_neutrals_300}`,

    background: NeutralColors.light_neutrals_100,
    color: NeutralColors.light_neutrals_400,

    [theme.breakpoints.down('commonSm')]: {
      width: '100%',
      height: '123px',
    },
  };
};

export const paperContentWrapper: SxStyles = {
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
};

export const cardDetails: SxStyles = (theme) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',

    [theme.breakpoints.down('commonSm')]: {
      gap: '8px',
    },
  };
};

export const address: SxStyles = {
  display: 'flex',
  gap: '8px',
};

export const description: SxStyles = {
  color: NeutralColors.light_neutrals_600,
};

export const paperNoDataImage: SxStyles = {
  width: '48px',
  height: '48px',
};

export const addressIcon: SxStyles = {
  width: '20px',
  height: '20px',

  color: NeutralColors.light_neutrals_600,
};

export const moreButton: SxStyles = (theme) => {
  return {
    [theme.breakpoints.down('commonSm')]: {
      width: '100%',
    },
  };
};
