import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

const rowGap = 4;

export const chart =
  (fullWidth?: boolean): SxStyles =>
  ({ breakpoints }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: `${rowGap}px`,

    paddingTop: '2px',
    paddingLeft: fullWidth ? '0' : '12px',

    overflow: 'hidden',

    [breakpoints.down('commonSm')]: !fullWidth && {
      gap: 0,
    },
  });

export const chartBackground =
  (fullWidth?: boolean): SxStyles =>
  ({ breakpoints }) => {
    const borderTop = `1px solid ${NeutralColors.light_neutrals_400}`;
    return {
      display: 'flex',
      justifyContent: 'space-between',
      width: fullWidth ? '100%' : '65%',
      // TODO вынести 22 в константы?
      paddingInline: '22px',
      paddingTop: '8px',

      borderTop,

      [breakpoints.down('commonMd')]: {
        width: fullWidth ? '100%' : '60%',
      },

      [breakpoints.down('commonSm')]: {
        width: '100%',
        borderTop: fullWidth ? borderTop : 'none',
      },
    };
  };

export const typographyContainer =
  (fullWidth?: boolean): SxStyles =>
  ({ breakpoints }) => ({
    display: 'flex',
    justifyContent: 'center',
    marginTop: `-${rowGap}px`,
    width: fullWidth ? `100%` : '70%',

    [breakpoints.down('commonSm')]: {
      width: '100%',
    },
  });

export const columnTypography: SxStyles = () => ({
  color: NeutralColors.light_neutrals_600,
});
