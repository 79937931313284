import { IRootState } from 'reducers';
import { createSelector } from 'reselect';

import { filterArchiveFalse } from './utils';

const sectionRefArchiveFalseSelector = createSelector(
  [(state: IRootState) => state.sectionRef],
  (sectionRef): IRootState['sectionRef'] => {
    return {
      ...sectionRef,
      content: sectionRef.content.filter(filterArchiveFalse),
    };
  },
);

export default sectionRefArchiveFalseSelector;
