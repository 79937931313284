import React, { useState } from 'react';

import { Stack } from '@mui/material';
import { ILinkablePersonObject } from 'portfolio3/features/dataEntryForm';
import { Button } from 'portfolio3/ui-kit/button';

import CommonEmployeeLinkedSelect from '../../../../ui-kit/select/AutoComplete/employee';
import { ILinkedSelectorController } from './types';

interface IEmployeeLinkedItemSelectorProps {
  objectController: ILinkedSelectorController;
  options: ILinkablePersonObject[];
  values: ILinkablePersonObject[];
}

const EmployeeLinkedItemSelector: React.FC<IEmployeeLinkedItemSelectorProps> = ({
  objectController,
  options,
  values,
}) => {
  const [activeControlsCount, setActiveControlsCount] = useState(values.length);

  const filteredOptions = options.filter((option) => {
    return !values.find((value) => value.code === option.code);
  });

  const handleChangeValue = (value: ILinkablePersonObject, index: number) => {
    if (value) {
      // заполнили значение - новое поле не добавляется
      objectController.handleChange(value, index);
      return;
    }

    // удаляем значение
    objectController.handleDeleteField(index);
    // сокращаем количество активных контролов, если возможно
    setActiveControlsCount((prevState) => (prevState > 1 ? prevState - 1 : prevState));
  };

  const isAddLinkDisabled = activeControlsCount > values.length;

  const isSelectLoading = !options.length;
  const selectPlaceholder = 'Начните вводить название объекта, с которым необходимо установить связь...';

  // если отсутствуют данные, или нажали кнопку "Добавить еще связь" - показываем доп контрол
  const hasAdditionalControl = values.length === 0 || activeControlsCount > values.length;

  return (
    <Stack className="employee-linked-objects" gap="8px">
      {values.map((item, index) => (
        <CommonEmployeeLinkedSelect
          key={item?.code}
          value={item}
          options={filteredOptions}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(event: any, value: ILinkablePersonObject) => {
            handleChangeValue(value, index);
          }}
          loading={isSelectLoading}
          placeholder={selectPlaceholder}
        />
      ))}
      {hasAdditionalControl && (
        <CommonEmployeeLinkedSelect
          options={filteredOptions}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(event: any, value: ILinkablePersonObject) => {
            handleChangeValue(value, values.length);
          }}
          loading={isSelectLoading}
          placeholder={selectPlaceholder}
        />
      )}
      <Button variant="text" disabled={isAddLinkDisabled} onClick={() => setActiveControlsCount(values.length + 1)}>
        Добавить еще связь
      </Button>
    </Stack>
  );
};

export default React.memo(EmployeeLinkedItemSelector);
