import { ISectionsVisibility } from 'api/types';
import { format } from 'date-fns';
import { getDaysPluralization } from 'utils';

import { initialDisplaySettings } from './consts';
import { DynamicDisplaySettings, ILinkSetting } from './types';

export const formatActiveLinkDate = (date: string) => {
  return format(new Date(date), 'dd.MM.yyyy');
};

export const formatActiveLinkEndDate = (endDate: string, expireDays: number) => {
  const dateString = formatActiveLinkDate(endDate);
  return `до ${dateString} (еще ${expireDays} ${getDaysPluralization(expireDays)})`;
};

export const isIndeterminateSetting = (childSettings: ILinkSetting[]): boolean => {
  const hasAtleastOneInactiveSetting = (settings: ILinkSetting[] | undefined): boolean => {
    if (!settings) return false;

    const firstInactiveFound = settings.find((setting) => {
      const isSelfInactive = !setting.isActive;
      const isChildInactive = hasAtleastOneInactiveSetting(setting.childSettings);

      return isSelfInactive || isChildInactive;
    });

    return Boolean(firstInactiveFound);
  };

  if (childSettings.every((setting) => !setting.isActive)) {
    return false;
  }

  return hasAtleastOneInactiveSetting(childSettings);
};

export const mapDynamicSettingsToFullSettings = (settings: DynamicDisplaySettings): ISectionsVisibility => {
  type SettingsEntryType = [keyof typeof initialDisplaySettings, boolean][];
  const initialSettingsEntries = Object.entries(initialDisplaySettings) as SettingsEntryType;

  const mappedEntries = initialSettingsEntries.map(([key]): SettingsEntryType[number] => {
    // настройка не найдена в актуальных настройках - добавляем как false
    if (!settings[key]) {
      return [key, false];
    }

    return [key, settings[key] ?? false];
  });

  const fullSettings = Object.fromEntries(mappedEntries) as unknown as ISectionsVisibility;
  return fullSettings;
};
