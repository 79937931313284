/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';

import { GET_PORTFOLIO_SUBCATEGORIES } from '../../actions/employees';

import {
  FAILURE,
  REQUEST,
  SUCCESS
} from '../../actions/utils';

import { ICommonResponse, IDictionaryItem } from '../../api/types';

import genericReducers from '../genericReducer';

import { IReducers } from '..';

export type PortfolioSubcategoriesState = ICommonResponse<IDictionaryItem>;

const initialState = {} as ICommonResponse<IDictionaryItem>;

const portfolioSubcategories: IReducers<PortfolioSubcategoriesState> = {
  [GET_PORTFOLIO_SUBCATEGORIES[REQUEST]]: genericReducers().get.request,
  [GET_PORTFOLIO_SUBCATEGORIES[SUCCESS]]: genericReducers().get.success,
  [GET_PORTFOLIO_SUBCATEGORIES[FAILURE]]: genericReducers().get.failure
};

export default connectReducers(initialState, portfolioSubcategories);
