import { FC, useContext, useMemo } from 'react';

import { Box, FormHelperText } from '@mui/material';
import { IBaseRefItem } from 'api/types';
import FormDivider from 'portfolio3/components/forms/FormDivider';
import {
  BaseInputLabel,
  BaseToggleLabel,
  Checkbox,
  FormControl,
  Input,
  Textarea,
  ToggleFormControl,
} from 'portfolio3/ui-kit';
import { DayPicker } from 'portfolio3/ui-kit/datePickers';
import { ISelectItem, Select } from 'portfolio3/ui-kit/selects';
import Toggle from 'portfolio3/ui-kit/Toggle';

import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';
import { getDayPickerControllerFactory } from '../../controllers';
import {
  getCommonInputControllerFactory,
  getCommonSelectControllerFactory,
  getCommonToggleControllerFactory,
} from '../../utils';
import { SecondaryBlockContainer } from '../common';

interface IProfessionJobSecondaryBlockProps {
  levelBusinessRef: IBaseRefItem[];
  salaryRangeRef: IBaseRefItem[];
  employmentDocTypeRef: IBaseRefItem[];
}

const ProfessionJobSecondaryBlock: FC<IProfessionJobSecondaryBlockProps> = ({
  levelBusinessRef,
  salaryRangeRef,
  employmentDocTypeRef,
}) => {
  const {
    studentType,
    isMobile,
    inputRenderMode,
    inputSize,
    formData,
    formErrors,
    onChangeFormData,
    onChangeFormErrors,
  } = useContext(LocalDataEntryFormContext);

  const inputController = getCommonInputControllerFactory(onChangeFormData, onChangeFormErrors);
  const selectController = getCommonSelectControllerFactory(onChangeFormData, onChangeFormErrors);
  const toggleController = getCommonToggleControllerFactory(onChangeFormData);
  const dayPickerControllerFactory = getDayPickerControllerFactory(onChangeFormData, onChangeFormErrors);

  const organizationController = useMemo(
    () => inputController('professionSpoJob.organization', 'professionSpoJobErrors.organizationError'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData.id],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeIsProfile = useMemo(() => toggleController('professionSpoJob.isProfile'), [formData.id]);

  const businessLevelController = useMemo(
    () => selectController('professionSpoJob.businessLevelCode', 'professionSpoJobErrors.businessLevelError'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData.id],
  );

  const salaryRangeController = useMemo(
    () => selectController('professionSpoJob.salaryRangeCode'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData.id],
  );

  const positionController = useMemo(
    () => inputController('professionSpoJob.position', 'professionSpoJobErrors.positionError'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData.id],
  );

  const mainFunctionalityController = useMemo(
    () => inputController('professionSpoJob.mainFunctionality', 'professionSpoJobErrors.mainFunctionalityError'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData.id],
  );

  const handleChangeHasGraduationDate = useMemo(
    () => toggleController('professionSpoJob.hasGraduationDate'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData.id],
  );

  const graduationDateController = dayPickerControllerFactory(
    'professionSpoJob.graduationDate',
    'professionSpoJobErrors.graduationDateError',
    {
      disableFuture: true,
    },
  );

  const handleChangeHasExpulsionDate = useMemo(
    () => toggleController('professionSpoJob.hasExpulsionDate'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData.id],
  );

  const expulsionDateController = dayPickerControllerFactory(
    'professionSpoJob.expulsionDate',
    'professionSpoJobErrors.expulsionDateError',
    {
      disableFuture: true,
    },
  );

  const handleChangeHasContract = useMemo(
    () => toggleController('professionSpoJob.hasContract'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData.id],
  );

  const contractTypeController = useMemo(
    () => selectController('professionSpoJob.contractTypeCode'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData.id],
  );

  const contractDateController = dayPickerControllerFactory(
    'professionSpoJob.contractDate',
    'professionSpoJobErrors.contractDateError',
    {
      disableFuture: true,
      required: true,
    },
  );

  const data = formData.professionSpoJob;
  const errors = formErrors.professionSpoJobErrors;

  const optionallyDisabledControl = data?.isPartiallyEditable;

  // опции, когда контролы неактивны и справочники приходят с другими кодами
  const fakeBusinessLevelOptions: ISelectItem[] = [
    { code: data?.businessLevelCode ?? 1, value: data?.businessLevelValue ?? '' },
  ];
  const fakeSalaryRangeOptions: ISelectItem[] = [
    { code: data?.salaryRangeCode ?? 1, value: data?.salaryRangeValue ?? '' },
  ];
  const fakeContractTypeOptions: ISelectItem[] = [
    { code: data?.contractTypeCode ?? 1, value: data?.contractTypeValue ?? '' },
  ];

  return (
    <SecondaryBlockContainer>
      <SecondaryBlockContainer.GeneralTab>
        <FormControl
          disabled={optionallyDisabledControl}
          required
          error={errors.organizationError}
          renderMode={inputRenderMode}
          inputSize={inputSize}
          label={<BaseInputLabel>Название организации трудоустройства</BaseInputLabel>}
          helper={errors.organizationError && <FormHelperText>Введите организацию</FormHelperText>}
          control={
            <Input
              renderMode={inputRenderMode}
              size={inputSize}
              placeholder={`${studentType ? 'Введи' : 'Введите'} название организации трудоустройства...`}
              value={data?.organization}
              controller={organizationController}
            />
          }
        />
        <ToggleFormControl
          withTopMargin={isMobile}
          renderMode="inline"
          label={null}
          control={
            <BaseToggleLabel
              disabled={optionallyDisabledControl}
              size="small"
              label="Трудоустройство по профилю"
              control={<Checkbox checked={data?.isProfile} onChange={(_, checked) => handleChangeIsProfile(checked)} />}
            />
          }
        />
        <FormControl
          disabled={optionallyDisabledControl}
          required
          error={errors.businessLevelError}
          renderMode={inputRenderMode}
          inputSize={inputSize}
          label={<BaseInputLabel>Предприятие/организация по уровню бизнеса</BaseInputLabel>}
          helper={
            errors.businessLevelError ? (
              <FormHelperText>Выберите уровень бизнеса</FormHelperText>
            ) : (
              <FormHelperText>Критерий численности работников, человек</FormHelperText>
            )
          }
          control={
            <Select
              inputRenderMode={inputRenderMode}
              inputSize={inputSize}
              placeholder="Выбери значение..."
              value={data?.businessLevelCode}
              options={optionallyDisabledControl ? fakeBusinessLevelOptions : levelBusinessRef}
              controller={businessLevelController}
              strictMaxWidth
            />
          }
        />
        <FormControl
          disabled={optionallyDisabledControl}
          renderMode={inputRenderMode}
          inputSize={inputSize}
          label={<BaseInputLabel>Диапазон среднемесячной зарплаты</BaseInputLabel>}
          helper={
            <FormHelperText>
              Диапазон среднемесячной зарплаты первого года работы выпускника по данной специальности в рублях
            </FormHelperText>
          }
          control={
            <Select
              inputRenderMode={inputRenderMode}
              inputSize={inputSize}
              placeholder="Выбери диапазон среднемесячной зарплаты..."
              value={data?.salaryRangeCode}
              options={optionallyDisabledControl ? fakeSalaryRangeOptions : salaryRangeRef}
              controller={salaryRangeController}
              strictMaxWidth
            />
          }
        />
        <FormControl
          required
          error={errors.positionError}
          renderMode={inputRenderMode}
          inputSize={inputSize}
          label={<BaseInputLabel>Должность</BaseInputLabel>}
          helper={errors.positionError && <FormHelperText>Введите должность</FormHelperText>}
          control={
            <Input
              renderMode={inputRenderMode}
              size={inputSize}
              placeholder={`${studentType ? 'Введи' : 'Введите'} название должности...`}
              value={data?.position}
              controller={positionController}
            />
          }
        />
        <FormControl
          required
          error={errors.mainFunctionalityError}
          renderMode={inputRenderMode}
          inputSize={inputSize}
          label={<BaseInputLabel>Основной функционал</BaseInputLabel>}
          helper={errors.mainFunctionalityError && <FormHelperText>Введите основной функционал</FormHelperText>}
          control={
            <Textarea
              renderMode={inputRenderMode}
              size={inputSize}
              placeholder="Опиши свои основные функции ..."
              value={data?.mainFunctionality}
              controller={mainFunctionalityController}
            />
          }
        />
        <ToggleFormControl
          withTopMargin={isMobile}
          renderMode="inline"
          label={null}
          control={
            <BaseToggleLabel
              disabled={optionallyDisabledControl}
              size="small"
              label="Дата выпуска"
              control={
                <Toggle
                  checked={data?.hasGraduationDate}
                  onChange={(_, checked) => handleChangeHasGraduationDate(checked)}
                />
              }
            />
          }
        />

        {data?.hasGraduationDate && (
          <FormControl
            disabled={optionallyDisabledControl}
            renderMode={inputRenderMode}
            inputSize={inputSize}
            error={errors.graduationDateError.active}
            helper={
              errors.graduationDateError.active && (
                <FormHelperText>{errors.graduationDateError.description}</FormHelperText>
              )
            }
            label={null}
            control={
              <Box sx={!isMobile ? { width: '220px' } : undefined}>
                <DayPicker
                  isMobile={isMobile}
                  renderMode={inputRenderMode}
                  size={inputSize}
                  controller={graduationDateController}
                  value={data.graduationDate ?? null}
                  maxValue={new Date()}
                />
              </Box>
            }
          />
        )}
        <ToggleFormControl
          withTopMargin={isMobile}
          renderMode="inline"
          label={null}
          control={
            <BaseToggleLabel
              disabled={optionallyDisabledControl}
              size="small"
              label="Дата отчисления"
              control={
                <Toggle
                  checked={data?.hasExpulsionDate}
                  onChange={(_, checked) => handleChangeHasExpulsionDate(checked)}
                />
              }
            />
          }
        />
        {data?.hasExpulsionDate && (
          <FormControl
            disabled={optionallyDisabledControl}
            renderMode={inputRenderMode}
            inputSize={inputSize}
            error={errors.expulsionDateError.active}
            helper={
              errors.expulsionDateError.active && (
                <FormHelperText>{errors.expulsionDateError.description}</FormHelperText>
              )
            }
            label={null}
            control={
              <Box sx={!isMobile ? { width: '220px' } : undefined}>
                <DayPicker
                  isMobile={isMobile}
                  renderMode={inputRenderMode}
                  size={inputSize}
                  controller={expulsionDateController}
                  value={data.expulsionDate ?? null}
                  maxValue={new Date()}
                />
              </Box>
            }
          />
        )}

        <FormControl withTopMargin={isMobile} label={null} control={<FormDivider />} />

        <ToggleFormControl
          withTopMargin={isMobile}
          renderMode="inline"
          label={null}
          control={
            <BaseToggleLabel
              disabled={optionallyDisabledControl}
              size="small"
              label="Договор на трудоустройство"
              control={
                <Toggle checked={data?.hasContract} onChange={(_, checked) => handleChangeHasContract(checked)} />
              }
            />
          }
        />

        {data?.hasContract && (
          <>
            <FormControl
              disabled={optionallyDisabledControl}
              renderMode={inputRenderMode}
              inputSize={inputSize}
              label={<BaseInputLabel>Тип договора на трудоустройство</BaseInputLabel>}
              control={
                <Select
                  inputRenderMode={inputRenderMode}
                  inputSize={inputSize}
                  placeholder="Выбери тип договора..."
                  value={data?.contractTypeCode}
                  options={optionallyDisabledControl ? fakeContractTypeOptions : employmentDocTypeRef}
                  controller={contractTypeController}
                  strictMaxWidth
                />
              }
            />
            <FormControl
              required
              disabled={optionallyDisabledControl}
              renderMode={inputRenderMode}
              inputSize={inputSize}
              error={errors.contractDateError.active}
              helper={
                errors.contractDateError.active && (
                  <FormHelperText>{errors.contractDateError.description}</FormHelperText>
                )
              }
              label={<BaseInputLabel>Дата заключения договора</BaseInputLabel>}
              control={
                <Box sx={!isMobile ? { width: '220px' } : undefined}>
                  <DayPicker
                    isMobile={isMobile}
                    renderMode={inputRenderMode}
                    size={inputSize}
                    controller={contractDateController}
                    value={data.contractDate ?? null}
                    maxValue={new Date()}
                  />
                </Box>
              }
            />
          </>
        )}
      </SecondaryBlockContainer.GeneralTab>
    </SecondaryBlockContainer>
  );
};

export default ProfessionJobSecondaryBlock;
