import { FC } from 'react';

import { Box, useTheme } from '@mui/material';
import { useBrightTheme } from 'hooks';
import { AccentColorType, getAccentColor } from 'portfolio3/styles';

import { IIndependentDiagnosticData } from '../../../../../types';
import DiagnosticCard from '../DiagnosticCard';

import './index.scss';

interface IDiagnosticCardProps {
  diagnostic: IIndependentDiagnosticData;
}

const DiagnosticCardWrapper: FC<IDiagnosticCardProps> = ({ diagnostic }) => {
  const isBrightTheme = useBrightTheme();
  const theme = useTheme();

  const accentColorType: AccentColorType = isBrightTheme ? theme.palette.accentColor : 'indigo';
  const pinColor = getAccentColor(accentColorType, '48');

  return (
    <div className="diagnostic-card-wrapper">
      <div className="diagnostic-card-wrapper__side">
        <div className="diagnostic-card-wrapper__line"></div>
        <Box
          className="diagnostic-card-wrapper__pin"
          sx={{
            ':before': {
              backgroundColor: pinColor,
            },
          }}
        ></Box>
      </div>
      <DiagnosticCard diagnostic={diagnostic} />
    </div>
  );
};

export default DiagnosticCardWrapper;
