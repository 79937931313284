import { FC } from 'react';

import { useDetailViewDrawer } from 'hooks';
import { IModifiedReward } from 'types';

import { CommonRewardCard } from '../../components/cards';
import SearchSection from '../../components/SearchSection';

interface ICommonRewardCardListProps {
  rewards: IModifiedReward[];
}

const CommonRewardCardList: FC<ICommonRewardCardListProps> = ({ rewards }) => {
  const { handleOpenPrimaryDetailView } = useDetailViewDrawer();

  const cards = rewards.map((reward, index, array) => {
    const handleView = () => handleOpenPrimaryDetailView(reward.cardData, reward.entityType);

    const card = <CommonRewardCard reward={reward} onView={handleView} />;

    return (
      <SearchSection.CardContainer
        key={reward.id ?? reward.recordId ?? index}
        card={card}
        mapIndex={index}
        totalCards={array.length}
        onView={handleView}
      />
    );
  });

  return cards;
};

export default CommonRewardCardList;
