import { FC } from 'react';

import { Box, DrawerProps, Stack } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';
import { Button } from 'portfolio3/ui-kit/button';
import { Drawer, IDrawerBase } from 'portfolio3/ui-kit/Drawer';

import MobileDrawerHeader from '../MobileDrawerHeader';

interface IMobileActionsDrawerProps {
  title: string;
  isResetDisabled?: boolean;
  isApplyDisabled?: boolean;
  onReset: () => void;
  onApply: () => void;
  onExitBack?: () => void;
}

const MobileActionsDrawer: FC<IMobileActionsDrawerProps & IDrawerBase & DrawerProps> = ({
  title,
  isResetDisabled,
  isApplyDisabled,
  onReset,
  onApply,
  onExitBack,
  children,
  ...drawerProps
}) => {
  const header: IDrawerBase['header'] = (crossButton) => (
    <MobileDrawerHeader
      title={title}
      overrideTitleColor={NeutralColors.light_neutrals_900}
      overrideBackgroundColor={NeutralColors.light_neutrals_0}
      crossButton={crossButton}
      onExitBack={onExitBack}
    />
  );

  const footer = (
    <Stack direction="row" gap="16px" sx={{ paddingBlock: '16px 20px', paddingInline: '12px' }}>
      <Button sx={{ flex: 1 }} variant="outline" disabled={isResetDisabled} onClick={onReset}>
        Сбросить
      </Button>
      <Button sx={{ flex: 1 }} variant="primary" disabled={isApplyDisabled} onClick={onApply}>
        Применить
      </Button>
    </Stack>
  );

  return (
    <Drawer
      {...drawerProps}
      swipeable
      swipeableProps={{
        onClose: (event) => drawerProps.onClose?.(event, 'escapeKeyDown'),
      }}
      anchor="bottom"
      isMobile
      header={header}
      footer={footer}
    >
      <Box sx={{ paddingInline: '12px' }}>{children}</Box>
    </Drawer>
  );
};

export default MobileActionsDrawer;
