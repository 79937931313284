import { FC, PropsWithChildren } from 'react';

import { Box, useMediaQuery } from '@mui/material';
import InlinePadding from 'portfolio3/components/common/InlinePadding';
import { commonTheme } from 'portfolio3/styles/theme';
import { mergeSx } from 'utils';

import { FacultyListType, ILocalVuzFaculty } from '../../types';
import FacultyListName from '../FacultyListName';
import { cardSpecialityMapFactory } from '../utils';
import * as styles from './styles';

interface IDetailsFacultyListProps {
  faculties: ILocalVuzFaculty[];
  facultyListType: FacultyListType;
}

const DetailsFacultyList: FC<IDetailsFacultyListProps & PropsWithChildren> = ({
  faculties,
  facultyListType,
  children,
}) => {
  const isSmallScreen = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const mapCardSpeciality = cardSpecialityMapFactory('20px', '16px', styles.specialityCard);

  const padding = isSmallScreen ? '16px' : '20px';

  return (
    <Box className="details-faculty-list" sx={facultyListType === 'full' ? styles.rootFull : styles.rootShort}>
      {faculties.map((faculty) => {
        const { name, specialities } = faculty;

        return (
          <>
            <Box
              key={name}
              className="faculty-container"
              sx={facultyListType === 'full' ? styles.facultyContainerFull : styles.facultyContainerShort}
            >
              <InlinePadding _p={padding}>
                <FacultyListName name={name} isMobile={isSmallScreen} listType={facultyListType} />
              </InlinePadding>

              {facultyListType === 'full' && (
                <>
                  {specialities.map((speciality, specialityIndex) => {
                    return (
                      <>
                        {specialityIndex !== 0 && (
                          <InlinePadding _p={padding}>
                            <Box sx={mergeSx(styles.listDivier, styles.specialyDivider)} />
                          </InlinePadding>
                        )}

                        {mapCardSpeciality(speciality, specialityIndex)}
                      </>
                    );
                  })}
                </>
              )}
            </Box>
            <Box sx={styles.listDivier} />
          </>
        );
      })}

      {children && (
        <Box className="details-faculty-list__bottom" sx={styles.bottomContainer}>
          {children}
        </Box>
      )}
    </Box>
  );
};

export default DetailsFacultyList;
