import { FC, useContext } from 'react';

import { IDictionaryItem } from 'api/types';

import { VuzLocalFiltersContext } from '../../context';
import SpecialitiesSearchableToggleList from '../SpecialitiesSearchableToggleList';

interface IInnerContextControlProps {
  specialityOptions: IDictionaryItem[];
}

const InnerContextControl: FC<IInnerContextControlProps> = ({ specialityOptions }) => {
  const { localFilters: innerLocalFilters, setLocalFilter: setInnerLocalFilter } = useContext(VuzLocalFiltersContext);

  const { specialities } = innerLocalFilters;

  const handleToggle = (specialityCode: number) => {
    const hasSpeciality = specialities.includes(specialityCode);
    const newSpecialities = hasSpeciality
      ? specialities.filter((code) => code !== specialityCode)
      : [...specialities, specialityCode];

    setInnerLocalFilter('specialities', newSpecialities);
  };

  return (
    <SpecialitiesSearchableToggleList
      specialities={specialityOptions}
      selectedSpecialities={specialities}
      onToggle={handleToggle}
    />
  );
};

export default InnerContextControl;
