/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { Switch as SwitchMaterial, SwitchProps } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { Colors } from '../../style/variables';

interface ISwitchCustomProps {
  customSize?: 'small' | 'medium' | 'large'
}

const useStyles = makeStyles((theme) => ({
  switch: {
    width: 48,
    height: 28,
    padding: 0,
    margin: 0,
    '&.MuiSwitch-sizeLarge': {
      width: 52,
      height: 32,

      '& $thumb': {
        width: 24,
        height: 24
      }
    }
  },
  switchBase: {
    padding: 0,
    color: Colors.white,
    '&$checked': {
      transform: 'translateX(20px)',
      color: Colors.white,
      '& + $track': {
        backgroundColor: Colors['fir-green'],
        opacity: 1,
        border: 'none'
      }
    },
    '&$focusVisible $thumb': {
      color: Colors['fir-green'],
      border: `6px solid ${Colors.white}`
    },
    '&.Mui-disabled': {
      color: Colors.white,
      '& + $track': {
        backgroundColor: Colors['page-background'],
        opacity: 1,
        border: 'none'
      },
      '&$checked + $track': {
        opacity: 0.3
      },
      '& $thumb': {
        boxShadow: 'none'
      }
    }
  },
  thumb: {
    width: 20,
    height: 20,
    marginTop: 4,
    marginLeft: 4,
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1), 0px 4px 12px rgba(0, 0, 0, 0.1)'
  },
  track: {
    borderRadius: '100px',
    backgroundColor: Colors['control-stroke'],
    opacity: 1,
    transition: theme.transitions.create(['background-color'])
  },
  checked: {},
  focusVisible: {},
  sizeSmall: {
    width: 33,
    height: 20,
    '& $switchBase$checked': {
      transform: 'translateX(14px)'
    },
    '& $thumb': {
      width: 12,
      height: 12,
      marginTop: 0,
      marginLeft: 0
    }
  }
}));

const Switch: React.FC<SwitchProps & ISwitchCustomProps> = (props) => {
  const classes = useStyles();

  return (
    <SwitchMaterial
      className={props?.customSize === 'large' ? 'MuiSwitch-sizeLarge' : ''}
      disableRipple
      color="default"
      classes={{
        root: classes.switch,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
        sizeSmall: classes.sizeSmall
      }}
      // @ts-ignore
      size={['small', 'medium'].includes(props?.customSize) ? props?.customSize : undefined}
      {...props}
    />
  );
};

export default Switch;
