import { FC, useEffect, useState } from 'react';

import { Box, useMediaQuery } from '@mui/material';
import { IMappedLearningYearPerformance, SubjectThemesContainer } from 'portfolio3/features/performance';
import SubjectHint from 'portfolio3/features/performance/components/SubjectHint';
import { emitYMEvent } from 'portfolio3/features/yandexMetrika';
import { commonTheme } from 'portfolio3/styles/theme';

import PolarAreaChart from '../../common/PolarAreaChart';
import ShowMore from '../../common/ShowMore';
import SubjectRow from './subjectRow';

import './index.scss';

const COLORS = [
  '#FFE8CC',
  '#FFDEEB',
  '#F3D9FA',
  '#E5DBFF',
  '#DBE4FF',
  '#D0EBFF',
  '#C5F6FA',
  '#C3FAE8',
  '#D3F9D8',
  '#E9FAC8',
  '#FFF3BF',
];
const ACTIVE_COLORS = [
  '#FFC078',
  '#FAA2C1',
  '#E599F7',
  '#B197FC',
  '#91A7FF',
  '#74C0FC',
  '#66D9E8',
  '#63E6BE',
  '#8CE99A',
  '#C0EB75',
  '#FFE066',
];

function extendArrayWithLastItem<T>(array: T[], desiredLength: number) {
  return array.length < desiredLength
    ? [...array, ...Array.from({ length: desiredLength - array.length }, () => array[array.length - 1])]
    : array;
}

interface IPerformanceDiagram {
  yearPerformance: IMappedLearningYearPerformance | undefined;
  selectedSubjectId: number | undefined;
  onSelectSubject: (id: number) => void;
}

const PerformanceDiagram: FC<IPerformanceDiagram> = ({ yearPerformance, selectedSubjectId, onSelectSubject }) => {
  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const initialSubjectsDisplayCount = 5;
  const [subjectsDisplayCount, setSubjectsDisplayCount] = useState(initialSubjectsDisplayCount);

  const { averageGrade, subjects } = yearPerformance ?? {};

  const totalSubjectsCount = subjects?.length ?? 0;
  const subjectColors = extendArrayWithLastItem(COLORS, totalSubjectsCount);
  const subjectActiveColors = extendArrayWithLastItem(ACTIVE_COLORS, totalSubjectsCount);
  const hiddenSubjectsCount = totalSubjectsCount - initialSubjectsDisplayCount;
  const isAllSubjectsShown = totalSubjectsCount === subjectsDisplayCount;

  useEffect(() => {
    setSubjectsDisplayCount(initialSubjectsDisplayCount);
  }, [yearPerformance?.year]);

  const handleShowAllSubjects = () => {
    emitYMEvent({ type: 'listItemsDisclosure' });
    setSubjectsDisplayCount(totalSubjectsCount);
  };
  const handleShowInitialSubjects = () => setSubjectsDisplayCount(initialSubjectsDisplayCount);

  // chart data
  const chartAverage = averageGrade;
  const chartLabels = subjects?.map((subject) => subject.name);
  const chartData = subjects?.map((subject) => subject.grade);
  const chartColors = subjectColors.map((color, index) => {
    const selectedSubjectIndex = subjects?.findIndex((subject) => subject.id === selectedSubjectId);
    if (selectedSubjectIndex === -1 || selectedSubjectIndex === index) return subjectActiveColors[index];
    return color;
  });

  return (
    <div className="performance-diagram-wrapper">
      <div className="performance-diagram">
        <div className="performance-diagram__chart-container">
          <PolarAreaChart
            average={chartAverage}
            labels={chartLabels ?? []}
            data={chartData ?? []}
            colors={chartColors}
            min={1}
            max={5.1}
            stepSize={1}
          />
          <div className="performance-diagram__subjects">
            {subjects?.slice(0, subjectsDisplayCount).map((subject, index) => {
              const { id, name, grade, gradeDifference } = subject;

              return (
                <SubjectRow
                  key={id + name}
                  name={name}
                  mark={grade}
                  markChange={gradeDifference ?? 0}
                  color={subjectActiveColors[index]}
                  selected={id === selectedSubjectId}
                  onSubjectSelected={() => onSelectSubject(id)}
                />
              );
            })}
            <ShowMore
              hiddenCount={hiddenSubjectsCount}
              isAllShown={isAllSubjectsShown}
              onShowInitialItems={handleShowInitialSubjects}
              onShowAllItems={handleShowAllSubjects}
            />
          </div>
        </div>
        <div className="performance-diagram__details-container">
          <SubjectThemesContainer selectedSubjectId={selectedSubjectId} yearPerformance={yearPerformance} />
        </div>
      </div>
      {isMobile && (
        <Box marginTop="12px">
          <SubjectHint />
        </Box>
      )}
    </div>
  );
};

export default PerformanceDiagram;
