import {
  getIndependentDiagnosticsActions,
  getIndependentDiagnosticsGeneralRatingActions,
  getIndependentDiagnosticsRatingActions,
} from 'actions';
import {
  getIndependentDiagnostics,
  getIndependentDiagnosticsGeneralRating,
  getIndependentDiagnosticsRating,
} from 'api';
import { call, put } from 'redux-saga/effects';
import { GenericGetAction } from 'sagas';

export function* getIndependentDiagnosticsSaga({
  payload,
}: GenericGetAction<Parameters<typeof getIndependentDiagnosticsActions.request>>) {
  if (!payload) {
    yield put(getIndependentDiagnosticsActions.failure(null));
    return;
  }

  const [personId, count] = payload;
  const { response, error } = yield call(getIndependentDiagnostics, personId, count);

  if (response) {
    yield put(getIndependentDiagnosticsActions.success(response));
  } else {
    yield put(getIndependentDiagnosticsActions.failure(error));
  }
}

export function* getIndependentDiagnosticsGeneralRatingSaga({
  payload,
}: GenericGetAction<Parameters<typeof getIndependentDiagnosticsGeneralRatingActions.request>>) {
  if (!payload) {
    yield put(getIndependentDiagnosticsGeneralRatingActions.failure(null));
    return;
  }

  const [personId] = payload;
  const { response, error } = yield call(getIndependentDiagnosticsGeneralRating, personId);

  if (response) {
    yield put(getIndependentDiagnosticsGeneralRatingActions.success(response));
  } else {
    yield put(getIndependentDiagnosticsGeneralRatingActions.failure(error));
  }
}

export function* getIndependentDiagnosticsRatingSaga({
  payload,
}: GenericGetAction<Parameters<typeof getIndependentDiagnosticsRatingActions.request>>) {
  if (!payload) {
    yield put(getIndependentDiagnosticsRatingActions.failure(null));
    return;
  }

  const [personId] = payload;
  const { response, error } = yield call(getIndependentDiagnosticsRating, personId);

  if (response) {
    yield put(getIndependentDiagnosticsRatingActions.success(response));
  } else {
    yield put(getIndependentDiagnosticsRatingActions.failure(error));
  }
}
