/* eslint-disable prettier/prettier */
/* eslint-disable */

import { InterestGroupKindCodes, InterestGroupKindKeys, InterestHeadKindCodes, InterestHeadKindKeys } from '../../../const';
import {
  interestsActingDesktopImage,
  interestsActingMobileImage,
  interestsActiveGamesDesktopImage,
  interestsAgriculturalDesktopImage,
  interestsAgriculturalMobileImage,
  interestsAnimalDesktopImage,
  interestsAnimalMobileImage,
  interestsAntiqueDesktopImage,
  interestsAntiqueMobileImage,
  interestsArtDesktopImage,
  interestsArtMobileImage,
  interestsAthleticDesktopImage,
  interestsAthleticMobileImage,
  interestsBikeDesktopImage,
  interestsBikeMobileImage,
  interestsBiologyDesktopImage,
  interestsBiologyMobileImage,
  interestsBloggingDesktopImage,
  interestsBloggingMobileImage,
  interestsCharacterDesktopImage,
  interestsCharacterMobileImage,
  interestsChemistryDesktopImage,
  interestsChemistryMobileImage,
  interestsChessDesktopImage,
  interestsChessMobileImage,
  interestsCinemaDesktopImage,
  interestsCinemaMobileImage,
  interestsCircusArtDesktopImage,
  interestsCircusArtMobileImage,
  interestsCircusDesktopImage,
  interestsCircusMobileImage,
  interestsCivicDesktopImage,
  interestsCivicMobileImage,
  interestsCoinDesktopImage,
  interestsCoinMobileImage,
  interestsCollectingDesktopImage,
  interestsCollectingMobileImage,
  interestsConcertDesktopImage,
  interestsConcertMobileImage,
  interestsConstructorDesktopImage,
  interestsConstructorMobileImage,
  interestsCookingDesktopImage,
  interestsCookingMobileImage,
  interestsCosplayDesktopImage,
  interestsCosplayMobileImage,
  interestsDanceDesktopImage,
  interestsDanceMobileImage,
  interestsDecorativeDesktopImage,
  interestsDecorativeMobileImage,
  interestsDesignDesktopImage,
  interestsDesignMobileImage,
  interestsEngineeringDesktopImage,
  interestsEngineeringMobileImage,
  interestsFashionDesktopImage,
  interestsFashionMobileImage,
  interestsFightDesktopImage,
  interestsFightMobileImage,
  interestsFlightDesktopImage,
  interestsFlightMobileImage,
  interestsFloristryDesktopImage,
  interestsFloristryMobileImage,
  interestsFootballDesktopImage,
  interestsFootballMobileImage,
  interestsHandWorkDesktopImage,
  interestsHandWorkMobileImage,
  interestsHikingDesktopImage,
  interestsHikingMobileImage,
  interestsHistoryDesktopImage,
  interestsHistoryMobileImage,
  interestsIntellectualDesktopImage,
  interestsIntellectualGamesDesktopImage,
  interestsIntellectualGamesMobileImage,
  interestsIntellectualMobileImage,
  interestsItDesktopImage,
  interestsItMobileImage,
  interestsLanguageDesktopImage,
  interestsLanguageMobileImage,
  interestsLeisureCinemaDesktopImage,
  interestsLeisureCinemaMobileImage,
  interestsLeisureDesktopImage,
  interestsLeisureMobileImage,
  interestsLeisureMuseumDesktopImage,
  interestsLeisureMuseumMobileImage,
  interestsLeisureTheatreDesktopImage,
  interestsLeisureTheatreMobileImage,
  interestsLiteratureDesktopImage,
  interestsLiteratureMobileImage,
  interestsMathematicDesktopImage,
  interestsMathematicMobileImage,
  interestsMedicineDesktopImage,
  interestsMedicineMobileImage,
  interestsMilitaryDesktopImage,
  interestsMilitaryMobileImage,
  interestsModellingDesktopImage,
  interestsModellingMobileImage,
  interestsModelsDesktopImage,
  interestsModelsMobileImage,
  interestsMotorDesktopImage,
  interestsMotorMobileImage,
  interestsMountainDesktopImage,
  interestsMountainMobileImage,
  interestsMuseumDesktopImage,
  interestsMuseumMobileImage,
  interestsMusicalInstrumentsDesktopImage,
  interestsMusicalInstrumentsMobileImage,
  interestsMusicDesktopImage,
  interestsMusicMobileImage,
  interestsNaturalDesktopImage,
  interestsNaturalMobileImage,
  interestsOrchestraDesktopImage,
  interestsOrchestraMobileImage,
  interestsOrienteeringDesktopImage,
  interestsOrienteeringMobileImage,
  interestsPaintingDesktopImage,
  interestsPaintingMobileImage,
  interestsPhotoDesktopImage,
  interestsPhotoMobileImage,
  interestsPhysicsDesktopImage,
  interestsPhysicsMobileImage,
  interestsPlantsDesktopImage,
  interestsPlantsMobileImage,
  interestsPublicDesktopImage,
  interestsPublicMobileImage,
  interestsReconstructionDesktopImage,
  interestsReconstructionMobileImage,
  interestsSailingDesktopImage,
  interestsSailingMobileImage,
  interestsScienceDesktopImage,
  interestsScienceMobileImage,
  interestsShortVideoDesktopImage,
  interestsShortVideoMobileImage,
  interestsSkatesDesktopImage,
  interestsSkatesMobileImage,
  interestsSkiDesktopImage,
  interestsSkiMobileImage,
  interestsSpaceDesktopImage,
  interestsSpaceMobileImage,
  interestsSpeleoDesktopImage,
  interestsSpeleoMobileImage,
  interestsSportDesktopImage,
  interestsSportMobileImage,
  interestsStampDesktopImage,
  interestsStampMobileImage,
  interestsTableGamesDesktopImage,
  interestsTableGamesMobileImage,
  interestsTargetDesktopImage,
  interestsTargetMobileImage,
  interestsTechnicDesktopImage,
  interestsTechnicMobileImage,
  interestsTheatreDesktopImage,
  interestsTheatreMobileImage,
  interestsTourismDesktopImage,
  interestsTourismMobileImage,
  interestsTransportDesktopImage,
  interestsTransportMobileImage,
  interestsTvDesktopImage,
  interestsTvMobileImage,
  interestsVideoDesktopImage,
  interestsVideoGamesDesktopImage,
  interestsVideoGamesMobileImage,
  interestsVideoMobileImage,
  interestsVocalsDesktopImage,
  interestsVocalsMobileImage,
  interestsWaterDesktopImage,
  interestsWaterMobileImage,
  interestsWaterTourismDesktopImage,
  interestsWaterTourismMobileImage,
  interestsWeightDesktopImage,
  interestsWeightMobileImage,
  interestsZozhDesktopImage,
  interestsZozhMobileImage
} from '../../../images';

export interface ICardImages {
  desktop: string,
  mobile: string
}

type ImageObject = {
  [key: number]: ICardImages | (
    (value: string, groupCode: number | null) => ICardImages
  );
}

const headCodeImages: ImageObject = {
  // Спорт
  [InterestHeadKindCodes[InterestHeadKindKeys.SPORT]]: (value, groupCode) => {
    switch (groupCode) {
      case InterestGroupKindCodes[InterestGroupKindKeys.WINTER_SPORT]: return {
        desktop: interestsSkatesDesktopImage,
        mobile: interestsSkatesMobileImage
      }
      case InterestGroupKindCodes[InterestGroupKindKeys.WATER_SPORT]: return {
        desktop: interestsWaterDesktopImage,
        mobile: interestsWaterMobileImage
      }
      case InterestGroupKindCodes[InterestGroupKindKeys.ATHLETICS]: return {
        desktop: interestsAthleticDesktopImage,
        mobile: interestsAthleticMobileImage
      }
      case InterestGroupKindCodes[InterestGroupKindKeys.WEIGHTLIFTING]: return {
        desktop: interestsWeightDesktopImage,
        mobile: interestsWeightMobileImage
      }
      case InterestGroupKindCodes[InterestGroupKindKeys.FIGHT]: return {
        desktop: interestsFightDesktopImage,
        mobile: interestsFightMobileImage
      }
      case InterestGroupKindCodes[InterestGroupKindKeys.TEAM_SPORT]: return {
        desktop: interestsFootballDesktopImage,
        mobile: interestsFootballMobileImage
      }
      case InterestGroupKindCodes[InterestGroupKindKeys.SINGLES_SPORT]: return {
        desktop: interestsMountainDesktopImage,
        mobile: interestsMountainMobileImage
      }
      case InterestGroupKindCodes[InterestGroupKindKeys.TRANSPORT_MANAGEMENT]: return {
        desktop: interestsMotorDesktopImage,
        mobile: interestsMotorMobileImage
      }
      case InterestGroupKindCodes[InterestGroupKindKeys.SHOOTING]: return {
        desktop: interestsTargetDesktopImage,
        mobile: interestsTargetMobileImage
      }
      case InterestGroupKindCodes[InterestGroupKindKeys.INTELLECTUAL_SPORT]: return {
        desktop: interestsChessDesktopImage,
        mobile: interestsChessMobileImage
      }
      case InterestGroupKindCodes[InterestGroupKindKeys.FLIGHT]: return {
        desktop: interestsFlightDesktopImage,
        mobile: interestsFlightMobileImage
      }
      default: return {
        desktop: interestsSportDesktopImage,
        mobile: interestsSportMobileImage
      }
    }
  },
  // ЗОЖ и активный образ жизни
  [InterestHeadKindCodes[InterestHeadKindKeys.HEALTHY]]: (value, groupCode) => {
    const MOUNTAIN_VALUES = ['скалолазание'];
    const HIKING_VALUES = ['прогулки, хайкинг'];
    const SWIMMING_VALUES = ['плавание'];
    const RUNNING_VALUES = ['бег'];
    const KARTING_VALUES = ['картинг/гонки'];

    if (groupCode === InterestGroupKindCodes[InterestGroupKindKeys.ACTIVE_LIFESTYLE]) {
      if (MOUNTAIN_VALUES.includes(value)) return ({
        desktop: interestsMountainDesktopImage,
        mobile: interestsMountainMobileImage
      });
      if (HIKING_VALUES.includes(value)) return ({
        desktop: interestsHikingDesktopImage,
        mobile: interestsHikingDesktopImage
      });
      if (SWIMMING_VALUES.includes(value)) return ({
        desktop: interestsWaterDesktopImage,
          mobile: interestsWaterMobileImage
      });
      if (RUNNING_VALUES.includes(value)) return ({
        desktop: interestsZozhDesktopImage,
        mobile: interestsZozhMobileImage
      });
      if (KARTING_VALUES.includes(value)) return ({
        desktop: interestsMotorDesktopImage,
          mobile: interestsMotorMobileImage
      });

      return {
        desktop: interestsBikeDesktopImage,
        mobile: interestsBikeMobileImage
      };
    }

    return {
      desktop: interestsZozhDesktopImage,
      mobile: interestsZozhMobileImage
    };
  },
  // Туризм и Путешествия
  [InterestHeadKindCodes[InterestHeadKindKeys.TRAVEL]]: (value, groupCode) => {
    switch (groupCode) {
      case InterestGroupKindCodes[InterestGroupKindKeys.TOURISM]: {
        const TOURISM_WATER_VALUES = ['водный туризм'];
        const TOURISM_COMBINED_VALUES = ['комбинированный туризм'];
        const TOURISM_SKI_VALUES = ['лыжный туризм'];
        const TOURISM_TRANSPORT_VALUES = ['туризм на средствах передвижения'];
        const TOURISM_SAILING_VALUES = ['парусный туризм'];
        const TOURISM_SPELEO_VALUES = ['спелео-туризм (пещеры)'];

        if (TOURISM_WATER_VALUES.includes(value)) return ({
          desktop: interestsWaterTourismDesktopImage,
          mobile: interestsWaterTourismMobileImage
        });
        if (TOURISM_COMBINED_VALUES.includes(value)) return ({
          desktop: interestsOrienteeringDesktopImage,
          mobile: interestsOrienteeringMobileImage
        });
        if (TOURISM_SKI_VALUES.includes(value)) return ({
          desktop: interestsSkiDesktopImage,
          mobile: interestsSkiMobileImage
        });
        if (TOURISM_TRANSPORT_VALUES.includes(value)) return ({
          desktop: interestsBikeDesktopImage,
          mobile: interestsBikeMobileImage
        });
        if (TOURISM_SAILING_VALUES.includes(value)) return ({
          desktop: interestsSailingDesktopImage,
          mobile: interestsSailingMobileImage
        });
        if (TOURISM_SPELEO_VALUES.includes(value)) return ({
          desktop: interestsSpeleoDesktopImage,
          mobile: interestsSpeleoMobileImage
        });

        return {
          desktop: interestsHikingDesktopImage,
          mobile: interestsHikingMobileImage
        };
      }
      case InterestGroupKindCodes[InterestGroupKindKeys.TRAVEL]: {
        const TRAVEL_BEACH_VALUES = ['пляжный отдых'];
        const TRAVEL_MOUNTAIN_VALUES = ['горнолыжный туризм'];
        const TRAVEL_RAFT_VALUES = ['сплавы'];

        if (TRAVEL_BEACH_VALUES.includes(value)) return ({
          desktop: interestsSailingDesktopImage,
          mobile: interestsWaterTourismMobileImage
        });
        if (TRAVEL_MOUNTAIN_VALUES.includes(value)) return ({
          desktop: interestsSkiDesktopImage,
          mobile: interestsSkiMobileImage
        });
        if (TRAVEL_RAFT_VALUES.includes(value)) return ({
          desktop: interestsWaterTourismDesktopImage,
          mobile: interestsWaterTourismMobileImage
        });

        return {
          desktop: interestsTourismDesktopImage,
          mobile: interestsTourismMobileImage
        };
      }
      default: return {
        desktop: interestsTourismDesktopImage,
        mobile: interestsTourismMobileImage
      };
    }
  },
  // Музыка
  [InterestHeadKindCodes[InterestHeadKindKeys.MUSIC]]: (value, groupCode) => {
    switch (groupCode) {
      case InterestGroupKindCodes[InterestGroupKindKeys.MUSICAL_INSTRUMENTS]: return {
        desktop: interestsMusicalInstrumentsDesktopImage,
        mobile: interestsMusicalInstrumentsMobileImage
      }
      case InterestGroupKindCodes[InterestGroupKindKeys.VOCALS]: return {
        desktop: interestsVocalsDesktopImage,
        mobile: interestsVocalsMobileImage
      }
      case InterestGroupKindCodes[InterestGroupKindKeys.MUSIC_GENRES]: return {
        desktop: interestsMusicDesktopImage,
        mobile: interestsMusicMobileImage
      }
      default: return {
        desktop: interestsMusicalInstrumentsDesktopImage,
        mobile: interestsMusicalInstrumentsMobileImage
      }
    }
  },
  // Литература
  [InterestHeadKindCodes[InterestHeadKindKeys.LITERATURE]]: {
    desktop: interestsLiteratureDesktopImage,
    mobile: interestsLiteratureMobileImage
  },
  // Кино и видео
  [InterestHeadKindCodes[InterestHeadKindKeys.CINEMA]]: (value) => {
    // актуальные значения: кино, мультфильмы
    // остальные архивные
    const CINEMA_VALUES = ['кино', 'мультфильмы', 'сериалы'];
    const BLOGGING_VALUES = ['стримы', 'обзоры', 'видеоблоги, интервью'];
    const SHORT_VIDEO_VALUES = ['короткие видео (тик-ток)'];
    const TV_VALUES = ['новости', 'реалити-шоу'];
    const VIDEO_VALUES = ['образовательные видео', 'музыкальные видео'];

    if (CINEMA_VALUES.includes(value)) return ({
      desktop: interestsCinemaDesktopImage,
      mobile: interestsCinemaMobileImage
    });
    if (BLOGGING_VALUES.includes(value)) return ({
      desktop: interestsBloggingDesktopImage,
      mobile: interestsBloggingMobileImage
    });
    if (SHORT_VIDEO_VALUES.includes(value)) return ({
      desktop: interestsShortVideoDesktopImage,
      mobile: interestsShortVideoMobileImage
    });
    if (TV_VALUES.includes(value)) return ({
      desktop: interestsTvDesktopImage,
      mobile: interestsTvMobileImage
    });
    if (VIDEO_VALUES.includes(value)) return ({
      desktop: interestsVideoDesktopImage,
      mobile: interestsVideoMobileImage
    });

    return {
      desktop: interestsCinemaDesktopImage,
      mobile: interestsCinemaMobileImage
    };
  },
  // Фотография
  [InterestHeadKindCodes[InterestHeadKindKeys.PHOTO]]: {
    desktop: interestsPhotoDesktopImage,
    mobile: interestsPhotoMobileImage
  },
  // Танцы
  [InterestHeadKindCodes[InterestHeadKindKeys.DANCE]]: {
    desktop: interestsDanceDesktopImage,
    mobile: interestsDanceMobileImage
  },
  // Театральное, цирковое искусство
  [InterestHeadKindCodes[InterestHeadKindKeys.THEATRICAL]]: (value, groupCode) => {
    switch (groupCode) {
      case InterestGroupKindCodes[InterestGroupKindKeys.CULTURAL_INSTITUTIONS]: {
        const THEATRE_VALUES = ['театры'];
        const CIRCUS_VALUES = ['цирки'];

        if (THEATRE_VALUES.includes(value)) return ({
          desktop: interestsTheatreDesktopImage,
          mobile: interestsTheatreMobileImage
        });
        if (CIRCUS_VALUES.includes(value)) return ({
          desktop: interestsCircusDesktopImage,
          mobile: interestsCircusMobileImage
        });

        return {
          desktop: interestsTheatreDesktopImage,
          mobile: interestsTheatreMobileImage
        };
      }
      case InterestGroupKindCodes[InterestGroupKindKeys.THEATRICAL_CREATIVITY]: return {
        desktop: interestsTheatreDesktopImage,
        mobile: interestsTheatreMobileImage
      }
      case InterestGroupKindCodes[InterestGroupKindKeys.CIRCUS_ART]: return {
        desktop: interestsCircusArtDesktopImage,
        mobile: interestsCircusArtMobileImage
      }
      case InterestGroupKindCodes[InterestGroupKindKeys.DIRECTING]: return {
        desktop: interestsActingDesktopImage,
        mobile: interestsActingMobileImage
      }
      default: return {
        desktop: interestsTheatreDesktopImage,
        mobile: interestsTheatreMobileImage
      }
    }
  },
  // Искусство и дизайн
  [InterestHeadKindCodes[InterestHeadKindKeys.DESIGN]]: (value, groupCode) => {
    switch (groupCode) {
      case InterestGroupKindCodes[InterestGroupKindKeys.CULTURAL_INSTITUTIONS]: return {
        desktop: interestsMuseumDesktopImage,
          mobile: interestsMuseumMobileImage
      }
      case InterestGroupKindCodes[InterestGroupKindKeys.ART]: {
        const SCULPTURE_VALUES = ['скульптура'];
        const HISTORY_VALUES = ['история изобразительного искусства'];
        const PHOTO_VALUES = ['художественная фотография'];

        if (SCULPTURE_VALUES.includes(value)) return ({
          desktop: interestsLeisureMuseumDesktopImage,
          mobile: interestsLeisureMuseumMobileImage
        });
        if (HISTORY_VALUES.includes(value)) return ({
          desktop: interestsMuseumDesktopImage,
          mobile: interestsMuseumMobileImage
        });
        if (PHOTO_VALUES.includes(value)) return ({
          desktop: interestsPhotoDesktopImage,
          mobile: interestsPhotoMobileImage
        });

        return {
          desktop: interestsPaintingDesktopImage,
          mobile: interestsPaintingMobileImage
        };
      }
      case InterestGroupKindCodes[InterestGroupKindKeys.DECORATIVE_ART]: {
        const HANDWORK_VALUES = ['рукоделие'];
        const PAPER_VALUES = ['декоративная работа с бумагой'];

        if (HANDWORK_VALUES.includes(value)) return ({
          desktop: interestsHandWorkDesktopImage,
          mobile: interestsHandWorkMobileImage
        });
        if (PAPER_VALUES.includes(value)) return ({
          desktop: interestsDecorativeDesktopImage,
          mobile: interestsDecorativeMobileImage
        });

        return {
          desktop: interestsArtDesktopImage,
          mobile: interestsArtMobileImage
        };
      }
      case InterestGroupKindCodes[InterestGroupKindKeys.DESIGN]: return {
        desktop: interestsDesignDesktopImage,
        mobile: interestsDesignMobileImage
      }
      default: return {
        desktop: interestsPaintingDesktopImage,
        mobile: interestsPaintingMobileImage
      }
    }
  },
  // Культурный досуг
  [InterestHeadKindCodes[InterestHeadKindKeys.CULTURAL_LEISURE]]: (value, groupCode) => {
    switch (groupCode) {
      case InterestGroupKindCodes[InterestGroupKindKeys.CULTURAL_INSTITUTIONS]: {
        const THEATRE_VALUES = ['театры'];
        const CIRCUS_VALUES = ['цирки'];
        const CINEMA_VALUES = ['кинотеатры'];
        const MUSEUM_VALUES = ['парки', 'дома культуры', 'музеи'];

        if (THEATRE_VALUES.includes(value)) return ({
          desktop: interestsLeisureTheatreDesktopImage,
          mobile: interestsLeisureTheatreMobileImage
        });
        if (CIRCUS_VALUES.includes(value)) return ({
          desktop: interestsCircusDesktopImage,
          mobile: interestsCircusMobileImage
        });
        if (CINEMA_VALUES.includes(value)) return ({
          desktop: interestsLeisureCinemaDesktopImage,
          mobile: interestsLeisureCinemaMobileImage
        });
        if (MUSEUM_VALUES.includes(value)) return ({
          desktop: interestsLeisureMuseumDesktopImage,
          mobile: interestsLeisureMuseumMobileImage
        });

        return {
          desktop: interestsLeisureDesktopImage,
          mobile: interestsLeisureMobileImage
        };
      }
      case InterestGroupKindCodes[InterestGroupKindKeys.CULTURAL_EVENTS]: {
        const CONCERT_VALUES = ['концерты'];

        if (CONCERT_VALUES.includes(value)) return ({
          desktop: interestsConcertDesktopImage,
          mobile: interestsConcertMobileImage
        });

        return {
          desktop: interestsLeisureMuseumDesktopImage,
          mobile: interestsLeisureMuseumMobileImage
        };
      }
      default: return {
        desktop: interestsLeisureDesktopImage,
        mobile: interestsLeisureMobileImage
      }
    }
  },
  // Мода и стиль
  [InterestHeadKindCodes[InterestHeadKindKeys.FASHION]]: (value, groupCode) => {
    switch (groupCode) {
      case InterestGroupKindCodes[InterestGroupKindKeys.MAKEUP]: {
        const BLOGGING_VALUES = ['бьютиблогинг'];

        if (BLOGGING_VALUES.includes(value)) return ({
          desktop: interestsBloggingDesktopImage,
          mobile: interestsBloggingMobileImage
        });

        return {
          desktop: interestsFashionDesktopImage,
          mobile: interestsFashionMobileImage
        };
      }
      case InterestGroupKindCodes[InterestGroupKindKeys.FASHION_IMAGE]: {
        const COSPLAY_VALUES = ['косплей'];

        if (COSPLAY_VALUES.includes(value)) return ({
          desktop: interestsCosplayDesktopImage,
          mobile: interestsCosplayMobileImage
        });

        return {
          desktop: interestsFashionDesktopImage,
          mobile: interestsFashionMobileImage
        };
      }
      default: return {
        desktop: interestsFashionDesktopImage,
        mobile: interestsFashionMobileImage
      }
    }
  },
  // Коллекционирование
  [InterestHeadKindCodes[InterestHeadKindKeys.COLLECTION]]: (value, groupCode) => {
    switch (groupCode) {
      case InterestGroupKindCodes[InterestGroupKindKeys.MILITARY]: return {
        desktop: interestsMilitaryDesktopImage,
        mobile: interestsMilitaryMobileImage
      }
      case InterestGroupKindCodes[InterestGroupKindKeys.POSTAL_PRODUCTS]: {
        const BOOK_VALUES = ['книги'];

        if (BOOK_VALUES.includes(value)) return ({
          desktop: interestsLiteratureDesktopImage,
          mobile: interestsLiteratureMobileImage
        });

        return {
          desktop: interestsStampDesktopImage,
          mobile: interestsStampMobileImage
        };
      }
      case InterestGroupKindCodes[InterestGroupKindKeys.ANTIQUES]: return {
        desktop: interestsAntiqueDesktopImage,
        mobile: interestsAntiqueMobileImage
      }
      case InterestGroupKindCodes[InterestGroupKindKeys.GAME_SUBJECTS]: {
        const GAME_VALUES = ['видеоигры'];
        const TOY_VALUES = ['игрушки'];
        const CONSTRUCTOR_VALUES = ['лего'];

        if (GAME_VALUES.includes(value)) return ({
          desktop: interestsVideoGamesDesktopImage,
          mobile: interestsVideoGamesMobileImage
        });
        if (TOY_VALUES.includes(value)) return ({
          desktop: interestsIntellectualDesktopImage,
          mobile: interestsIntellectualGamesMobileImage
        });
        if (CONSTRUCTOR_VALUES.includes(value)) return ({
          desktop: interestsConstructorDesktopImage,
          mobile: interestsConstructorMobileImage
        });

        return {
          desktop: interestsCollectingDesktopImage,
          mobile: interestsCollectingMobileImage
        };
      }
      case InterestGroupKindCodes[InterestGroupKindKeys.TECHNICS]: {
        const PHOTO_VALUES = ['фототехника'];
        const COMPUTER_VALUES = ['компьютерная техника'];

        if (PHOTO_VALUES.includes(value)) return ({
          desktop: interestsPhotoDesktopImage,
          mobile: interestsPhotoMobileImage
        });
        if (COMPUTER_VALUES.includes(value)) return ({
          desktop: interestsItDesktopImage,
          mobile: interestsItMobileImage
        });

        return {
          desktop: interestsTechnicDesktopImage,
          mobile: interestsTechnicMobileImage
        };
      }
      case InterestGroupKindCodes[InterestGroupKindKeys.NATURE_SUBJECTS]: {
        const PLANT_VALUES = ['кактусы', 'комнатные растения'];

        if (PLANT_VALUES.includes(value)) return ({
          desktop: interestsPlantsDesktopImage,
          mobile: interestsPlantsMobileImage
        });

        return {
          desktop: interestsNaturalDesktopImage,
          mobile: interestsNaturalMobileImage
        };
      }
      case InterestGroupKindCodes[InterestGroupKindKeys.MODELS]: return {
        desktop: interestsModelsDesktopImage,
        mobile: interestsModelsMobileImage
      }
      default: return {
        desktop: interestsCollectingDesktopImage,
        mobile: interestsCollectingMobileImage
      }
    }
  },
  // Транспорт
  [InterestHeadKindCodes[InterestHeadKindKeys.TRANSPORT]]: {
    desktop: interestsTransportDesktopImage,
    mobile: interestsTransportMobileImage
  },
  // Персонажи игр/книг/фильмов
  [InterestHeadKindCodes[InterestHeadKindKeys.CHARACTER]]: {
    desktop: interestsCharacterDesktopImage,
    mobile: interestsCharacterMobileImage
  },
  // История
  [InterestHeadKindCodes[InterestHeadKindKeys.HISTORY]]: {
    desktop: interestsHistoryDesktopImage,
    mobile: interestsHistoryMobileImage
  },
  // Космос
  [InterestHeadKindCodes[InterestHeadKindKeys.SPACE]]: {
    desktop: interestsSpaceDesktopImage,
    mobile: interestsSpaceMobileImage
  },
  // Иностранные языки
  [InterestHeadKindCodes[InterestHeadKindKeys.LANGUAGE]]: {
    desktop: interestsLanguageDesktopImage,
    mobile: interestsLanguageMobileImage
  },
  // IT (Информационные технологии)
  [InterestHeadKindCodes[InterestHeadKindKeys.INFORMATION]]: {
    desktop: interestsItDesktopImage,
    mobile: interestsItMobileImage
  },
  // Науки и технологии
  [InterestHeadKindCodes[InterestHeadKindKeys.SCIENCE]]: (value, groupCode) => {
    switch (groupCode) {
      case InterestGroupKindCodes[InterestGroupKindKeys.NATURAL_SCIENCE]: {
        const MATHEMATIC_VALUES = ['математика', 'информатика'];
        const PHYSICS_VALUES = ['физика', 'астрономия (научные исследования о космосе)'];
        const CHEMISTRY_VALUES = ['химия и науки о материалах'];
        const BIOLOGY_VALUES = ['биология и науки о жизни и живом'];

        if (MATHEMATIC_VALUES.includes(value)) return ({
          desktop: interestsMathematicDesktopImage,
          mobile: interestsMathematicMobileImage
        });
        if (PHYSICS_VALUES.includes(value)) return ({
          desktop: interestsPhysicsDesktopImage,
          mobile: interestsPhysicsMobileImage
        });
        if (CHEMISTRY_VALUES.includes(value)) return ({
          desktop: interestsChemistryDesktopImage,
          mobile: interestsChemistryMobileImage
        });
        if (BIOLOGY_VALUES.includes(value)) return ({
          desktop: interestsBiologyDesktopImage,
          mobile: interestsBiologyMobileImage
        });

        return {
          desktop: interestsScienceDesktopImage,
          mobile: interestsScienceMobileImage
        };
      }
      case InterestGroupKindCodes[InterestGroupKindKeys.ENGINEERING]: return {
        desktop: interestsEngineeringDesktopImage,
        mobile: interestsEngineeringMobileImage
      }
      case InterestGroupKindCodes[InterestGroupKindKeys.MEDICINE]: return {
        desktop: interestsMedicineDesktopImage,
        mobile: interestsMedicineMobileImage
      }
      case InterestGroupKindCodes[InterestGroupKindKeys.AGRICULTURAL_SCIENCE]: return {
        desktop: interestsAgriculturalDesktopImage,
        mobile: interestsAgriculturalMobileImage
      }
      default: return {
        desktop: interestsScienceDesktopImage,
        mobile: interestsScienceMobileImage
      };
    }
  },
  // Игры
  [InterestHeadKindCodes[InterestHeadKindKeys.GAME]]: (value) => {
    const TABLE_GAMES_VALUES = ['настольные игры'];
    const ACTIVE_GAMES_VALUES = ['активные игры', 'детские подвижные игры'];
    const INTELLECTUAL_GAMES_VALUES = ['интеллектуальные групповые игры (квесты, квизы и др.)'];
    const PUZZLE_GAMES_VALUES = ['интеллектуальные индивидуальные игры (головоломки, пазлы)'];
    const CONSTRUCTOR_VALUES = ['конструкторы'];
    const MODEL_VALUES = ['стендовый моделизм (сборные модели)'];

    if (TABLE_GAMES_VALUES.includes(value)) return ({
      desktop: interestsTableGamesDesktopImage,
      mobile: interestsTableGamesMobileImage
    });
    if (ACTIVE_GAMES_VALUES.includes(value)) return ({
      desktop: interestsActiveGamesDesktopImage,
      mobile: interestsActiveGamesDesktopImage
    });
    if (INTELLECTUAL_GAMES_VALUES.includes(value)) return ({
      desktop: interestsIntellectualGamesDesktopImage,
      mobile: interestsIntellectualGamesMobileImage
    });
    if (PUZZLE_GAMES_VALUES.includes(value)) return ({
      desktop: interestsIntellectualDesktopImage,
      mobile: interestsIntellectualMobileImage
    });
    if (CONSTRUCTOR_VALUES.includes(value)) return ({
      desktop: interestsConstructorDesktopImage,
      mobile: interestsConstructorMobileImage
    });
    if (MODEL_VALUES.includes(value)) return ({
      desktop: interestsModellingDesktopImage,
      mobile: interestsModellingMobileImage
    });

    return {
      desktop: interestsVideoGamesDesktopImage,
      mobile: interestsVideoGamesMobileImage
    };
  },
  // Животные и Растения
  [InterestHeadKindCodes[InterestHeadKindKeys.ANIMAL]]: (value, groupCode) => {
    switch (groupCode) {
      case InterestGroupKindCodes[InterestGroupKindKeys.ANIMAL]: {
        const NATURAL_VALUES = [
          'беспозвоночные',
          'бабочки',
          'улитки',
          'пауки',
          'муравьи',
          'жуки и кузнечики'
        ];

        if (NATURAL_VALUES.includes(value)) return ({
          desktop: interestsNaturalDesktopImage,
          mobile: interestsNaturalMobileImage
        });

        return {
          desktop: interestsAnimalDesktopImage,
          mobile: interestsAnimalMobileImage
        };
      }
      case InterestGroupKindCodes[InterestGroupKindKeys.PLANTS]: return {
        desktop: interestsPlantsDesktopImage,
        mobile: interestsPlantsMobileImage
      }
      case InterestGroupKindCodes[InterestGroupKindKeys.FLORISTRY]: return {
        desktop: interestsFloristryDesktopImage,
        mobile: interestsFloristryMobileImage
      }
      default: return {
        desktop: interestsAnimalDesktopImage,
        mobile: interestsAnimalMobileImage
      }
    }
  },
  // Кулинария
  [InterestHeadKindCodes[InterestHeadKindKeys.COOKERY]]: {
    desktop: interestsCookingDesktopImage,
    mobile: interestsCookingMobileImage
  },
  // Общественная деятельность, гражданская активность
  [InterestHeadKindCodes[InterestHeadKindKeys.ACTIVITY]]: (value, groupCode) => {
    switch (groupCode) {
      case InterestGroupKindCodes[InterestGroupKindKeys.CIVIC_ENGAGEMENT]: return {
        desktop: interestsPublicDesktopImage,
        mobile: interestsPublicMobileImage
      }
      default: return {
        desktop: interestsCivicDesktopImage,
        mobile: interestsCivicMobileImage
      }
    }
  },
  // Медиа и социальные сети
  [InterestHeadKindCodes[InterestHeadKindKeys.SOCIAL_MEDIA]]: (value) => {
    const CINEMA_VALUES = ['сериалы'];
    const BLOGGING_VALUES = ['стримы', 'обзоры', 'видеоблоги, интервью'];
    const SHORT_VIDEO_VALUES = ['короткие видео (тик-ток)'];
    const TV_VALUES = ['новости', 'реалити-шоу'];
    const VIDEO_VALUES = ['образовательные видео', 'музыкальные видео'];

    if (CINEMA_VALUES.includes(value)) return ({
      desktop: interestsCinemaDesktopImage,
      mobile: interestsCinemaMobileImage
    });
    if (BLOGGING_VALUES.includes(value)) return ({
      desktop: interestsBloggingDesktopImage,
      mobile: interestsBloggingMobileImage
    });
    if (SHORT_VIDEO_VALUES.includes(value)) return ({
      desktop: interestsShortVideoDesktopImage,
      mobile: interestsShortVideoMobileImage
    });
    if (TV_VALUES.includes(value)) return ({
      desktop: interestsTvDesktopImage,
      mobile: interestsTvMobileImage
    });
    if (VIDEO_VALUES.includes(value)) return ({
      desktop: interestsVideoDesktopImage,
      mobile: interestsVideoMobileImage
    });

    return {
      desktop: interestsShortVideoDesktopImage,
      mobile: interestsShortVideoMobileImage
    };
  },
};

export const getInterestCardImage = (
  headCode: number,
  groupCode: number | null,
  value: string
): ICardImages => {
  const processedValue = value.toLowerCase().trim();
  const imageObject = headCodeImages[headCode];

  return typeof imageObject === 'function'
    ? imageObject(processedValue, groupCode)
    : imageObject;
};
