/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';

import {
  FAILURE,
  REQUEST,
  SUCCESS
} from '../../actions/utils';

import { IReducers } from '..';
import {
  EDIT_PORTFOLIO_AFFILATION,
  EDIT_PORTFOLIO_EMPLOYMENT,
  EDIT_PORTFOLIO_EVENT,
  EDIT_PORTFOLIO_PROJECT,
  EDIT_PORTFOLIO_REWARD,
  EDIT_PORTFOLIO_SPORT_REWARD
} from '../../actions';

export type HistoryFormEditStatusState = {
  status: 'pending' | 'success' | 'failure'
  loading: boolean
};

const initialState = {
  status: 'pending',
  loading: false
} as HistoryFormEditStatusState;

const historyFormEditStatus: IReducers<HistoryFormEditStatusState> = {

  [EDIT_PORTFOLIO_AFFILATION[REQUEST]]: () => ({
    status: 'pending',
    loading: true
  }),
  [EDIT_PORTFOLIO_AFFILATION[SUCCESS]]: () => ({
    status: 'success',
    loading: false
  }),
  [EDIT_PORTFOLIO_AFFILATION[FAILURE]]: () => ({
    status: 'failure',
    loading: false
  }),

  [EDIT_PORTFOLIO_EMPLOYMENT[REQUEST]]: () => ({
    status: 'pending',
    loading: true
  }),
  [EDIT_PORTFOLIO_EMPLOYMENT[SUCCESS]]: () => ({
    status: 'success',
    loading: false
  }),
  [EDIT_PORTFOLIO_EMPLOYMENT[FAILURE]]: () => ({
    status: 'failure',
    loading: false
  }),

  [EDIT_PORTFOLIO_EVENT[REQUEST]]: () => ({
    status: 'pending',
    loading: true
  }),
  [EDIT_PORTFOLIO_EVENT[SUCCESS]]: () => ({
    status: 'success',
    loading: false
  }),
  [EDIT_PORTFOLIO_EVENT[FAILURE]]: () => ({
    status: 'failure',
    loading: false
  }),

  [EDIT_PORTFOLIO_PROJECT[REQUEST]]: () => ({
    status: 'pending',
    loading: true
  }),
  [EDIT_PORTFOLIO_PROJECT[SUCCESS]]: () => ({
    status: 'success',
    loading: false
  }),
  [EDIT_PORTFOLIO_PROJECT[FAILURE]]: () => ({
    status: 'failure',
    loading: false
  }),

  [EDIT_PORTFOLIO_REWARD[REQUEST]]: () => ({
    status: 'pending',
    loading: true
  }),
  [EDIT_PORTFOLIO_REWARD[SUCCESS]]: () => ({
    status: 'success',
    loading: false
  }),
  [EDIT_PORTFOLIO_REWARD[FAILURE]]: () => ({
    status: 'failure',
    loading: false
  }),

  [EDIT_PORTFOLIO_SPORT_REWARD[REQUEST]]: () => ({
    status: 'pending',
    loading: true
  }),
  [EDIT_PORTFOLIO_SPORT_REWARD[SUCCESS]]: () => ({
    status: 'success',
    loading: false
  }),
  [EDIT_PORTFOLIO_SPORT_REWARD[FAILURE]]: () => ({
    status: 'failure',
    loading: false
  })

};

export default connectReducers(initialState, historyFormEditStatus);
