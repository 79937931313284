import { IIndependentDiagnosticGeneralRating, IIndependentDiagnosticRating } from 'api/types';
import {
  DiagnosticRatingRegionCodes,
  DiagnosticRatingRegionCodesObject,
  IndependentDiagnosticCodesObject,
} from 'const';
import { isDefined } from 'utils';

import { GeneralLocationTypes, LocationTypes } from '../types';
import { RatingType } from './types';

interface IMap<K, V> extends Map<K, V> {
  get(key: K): V;
}

export function createMapFromRating<T>(
  rating: IIndependentDiagnosticGeneralRating | IIndependentDiagnosticRating,
  type: RatingType,
) {
  const unusedKeys = ['subjectGeneralRatingName', 'subject', 'workId', 'maxResult'];
  const chartsMap: IMap<DiagnosticRatingRegionCodes, T> = new Map();

  for (const [key, value] of Object.entries(rating)) {
    if (unusedKeys.includes(key) || !isDefined(value)) continue;
    if (type === 'generalRating') {
      chartsMap.set(DiagnosticRatingRegionCodesObject[key as GeneralLocationTypes], value);
    } else {
      chartsMap.set(IndependentDiagnosticCodesObject[key as LocationTypes], value);
    }
  }
  return chartsMap;
}
