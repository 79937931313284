import { ComponentType, FC, PropsWithChildren, useState } from 'react';

import { DrawerFormsContext } from 'context';
import { ThemePanel } from 'portfolio3/components/theme';

const DrawersContextContainer: FC<PropsWithChildren> = ({ children }) => {
  const [isOpenThemePanel, setIsOpenThemePanel] = useState(false);

  const handleOpenThemePanel = () => setIsOpenThemePanel(true);
  const handleCloseThemePanel = () => setIsOpenThemePanel(false);

  return (
    <DrawerFormsContext.Provider
      value={{
        onOpenThemePanel: handleOpenThemePanel,
      }}
    >
      {children}

      <ThemePanel isOpen={isOpenThemePanel} onClose={handleCloseThemePanel} />
    </DrawerFormsContext.Provider>
  );
};

const withDrawersContext = () => {
  return <P extends PropsWithChildren<unknown>>(WrappedComponent: ComponentType<P>): FC<P> => {
    return function WithDrawersContext({ ...props }) {
      return (
        <DrawersContextContainer>
          <WrappedComponent {...props} />
        </DrawersContextContainer>
      );
    };
  };
};

export default withDrawersContext;
