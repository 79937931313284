import { ReactNode } from 'react';

import { IPersonObject } from 'api/types';
import { AccentColorType } from 'portfolio3/styles';
import { ICardData, ITab } from 'types';
import { SafeOmit } from 'utils';

import { IFile } from './files';

export * from './files';

export enum DataEntryFormTab {
  GENERAL = 1,
  FILES,
  LINKED,
}

export enum DataEntryFormStep {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export interface IPortfolioEntryFormData {
  /* primary block */
  categoryCode?: number;
  dataKind?: number;
  typeCode?: number;
  name?: number | string;
  // reward
  reward?: number;
  entityId?: number;

  id?: number;
  subjectId: number[];
  linkedObjects: ILinkablePersonObject[];
  startDate: Date | null;
  date: Date | null;
  endDate: Date | null;
  format: number;
  organizator: string[];
  profile?: string;
  participant?: string;
  place?: string;
  placeName?: string;
  result?: string;
  workCode?: string;
  description?: string;
  attachment: IFile[];
  secondName?: string;
  address?: string;
  recordId?: number;
  entityType?: string;
  sportRewardCode?: number;
  recordType?: string;
  classStart?: number;
  classEnd?: number;
  eventLevel?: number;
  olympiadLevel?: number;
  rewardNumber?: string;
  subCategory?: number;
  age?: number;
  sportKind?: number;
  tourismKind?: number;
  eventNumber?: string | null;
  actionStage?: string | null;
  stageEvent?: string | null;
  actionDate?: Date | null;
  stageStartDate: Date | null;
  stageEndDate: Date | null;
  creationKindCode?: number;
  status?: string;
  expireDate?: Date | null;
  organizationCategory?: string;
  trainingStageCode?: number;
  teamName?: string;
  teamStartDate?: Date | null;
  teamEndDate?: Date | null;
  medicalClearanceFromDate?: Date | null;
  medicalClearanceToDate?: Date | null;

  // Компетенция презентационная
  isPresentationCompetence?: boolean;
  // Категория участников
  participantCategory?: string;
  // Наименование профессии (для мероприятий категории "Профессия")
  profession?: string;

  // gia worldskills
  giaCompetenceCode?: string;
  giaName?: string;
  giaResultScore?: number;
  giaMaxCompetenceScore?: number;
  giaKod?: string;
  giaResultDate?: Date | null;
  giaIsEarlyRelease?: boolean;

  // spo profession
  spoMetaskills?: number[];

  studySpoDocument?: IStudySpoDocumentFormData;
  professionSpoJob?: IProfessionSpoJobFormData;
}

export interface IStudySpoDocumentFormData {
  documentTypeCode?: number; // Тип документа

  documentName?: string; // Название документа
  organizationCode?: number; // Название организации (справочник)
  organizationName?: string;
  educationProgramm?: string; // Название программы обучения
  professionalProgrammCode?: number; // Название программы профессионального обучения (справочник)
  professionalProgrammName?: string;
  hours?: string; // Объем часов
  regNum?: string; // Регистрационный номер
  docDate?: Date; // Дата выдачи
  docPlace?: string; // Город выдачи
  docPlaceOther?: string;
  examMarkCode?: number; // Итоговая аттестация (справочник)
  profession?: string; // Квалификация
  rankCode?: number; // Разряд (справочник)
  docNum?: string; // Номер документа
  docSeries?: string; // Серия документа
  resultDate?: Date; // Дата решения аттестационной комиссии
}

export interface IProfessionSpoJobFormData {
  recordId?: string;
  eventId?: string;
  isPartiallyEditable?: boolean; // Свойство для защиты полей от редактирования

  organization?: string; // Название организации трудоустройства
  isProfile?: boolean; // Трудоустройство по профилю
  businessLevelCode?: number; // Предприятие/организация по уровню бизнеса (справочник)
  salaryRangeCode?: number; // Диапазон среднемесячной зарплаты (справочник)
  position?: string; // Должность
  mainFunctionality?: string; // Основной функционал

  // Дата выпуска
  hasGraduationDate?: boolean;
  graduationDate?: Date;

  // Дата отчисления
  hasExpulsionDate?: boolean;
  expulsionDate?: Date;

  // Договор на трудоустройство
  hasContract?: boolean;
  contractTypeCode?: number; // Тип договора на трудоустройство (справочник)
  contractDate?: Date; // Дата заключения договора

  // значения для подмены опций селектов (когда источник данных кликхаус приходят другие коды)
  // не используются при редактировании или отправке
  businessLevelValue?: string;
  salaryRangeValue?: string;
  contractTypeValue?: string;
}

export interface IErrorWithDescription {
  active: boolean;
  description: string;
}

export interface IPortfolioDataEntryFormErrors {
  teamStartDateError: IErrorWithDescription;
  teamEndDateError: IErrorWithDescription;
  endDateError: IErrorWithDescription;
  subjectError: boolean;
  fileError: boolean;
  dateError: IErrorWithDescription;
  activityDateError: IErrorWithDescription;
  eventDateError: IErrorWithDescription;
  stageDateError: IErrorWithDescription;
  eventLevelError: boolean;
  nameError: boolean;
  secondNameError: boolean;
  subcategoryError: boolean;
  placeError: boolean;
  placeNameError: boolean;
  sportKindError: boolean;
  tourismKindError: boolean;
  creationKindError: boolean;
  ageError: boolean;
  rewardNumberError: boolean;
  teamNameError: boolean;

  participantCategoryError: boolean;

  // gia
  giaCompetenceCodeError: boolean;
  giaNameError: boolean;
  giaResultScoreError: boolean;
  giaMaxCompetenceScoreError: boolean;
  giaCodeError: boolean;

  studySpoDocumentErrors: {
    documentName: boolean;
    organization: boolean;
    educationProgramm: boolean;
    professionalProgramm: boolean;
    hours: boolean;
    regNum: boolean;
    docDate: IErrorWithDescription;
    docPlace: boolean;
    examMark: boolean;
    profession: boolean;
    rank: boolean;
    docNum: boolean;
    docSeries: boolean;
    resultDate: IErrorWithDescription;
  };

  professionSpoJobErrors: {
    organizationError: boolean;
    businessLevelError: boolean;
    positionError: boolean;
    mainFunctionalityError: boolean;
    graduationDateError: IErrorWithDescription;
    expulsionDateError: IErrorWithDescription;
    contractDateError: IErrorWithDescription;
  };
}

interface ILinkedPersonObject extends IPersonObject {
  dataItemType?: string;
}

export interface IModifiedPersonObject extends IPersonObject {
  linkedPersonObjects?: ILinkedPersonObject[];
  dataItemAttributes?: {
    name: string;
    color: string;
    background: string;
    icon: JSX.Element;
  };
  dataItemType?: string;
  cardData: ICardData;
}

export interface ILinkablePersonObject {
  code: number;
  value: string;
  categoryCode: number;
  typeCode: number | null;
  format: string;
  entityTypeCode: number;
}

export interface IEntryFormEntityProperties {
  entityInfo?: {
    name?: string;
    categoryName: string;
    typeName: string;
    attributes?: string[];
  };
}

export interface IEventData {
  type: string;
  name?: string;
  ageLimit?: string;
  ageLimits?: number[];
  levelEvent?: string;
  levelEventCode?: number;
  organizators?: string;
  categoryCode?: number;
}

export type MappedEventData = SafeOmit<IEventData, 'type'> & Partial<Pick<IEventData, 'type'>>;

export interface IInnerFormProps {
  accentColor: AccentColorType;
  entityActions: ReactNode;
  isSubmitDisabled: boolean;
  entityProperties: IEntryFormEntityProperties | null;
  tabs: ITab[];
  relatedObjectType?: string;
  relatedObjectName?: string;
  onSubmit: () => void;
  onClose?: () => void;
}
