import { IconClose } from 'icons/edit';
import { NeutralColors } from 'portfolio3/styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EmployeeCloseButton = ({ closeToast }: any) => {
  return (
    <IconClose
      onClick={closeToast}
      sx={{ position: 'relative', zIndex: 10, width: '20px', height: '20px', color: NeutralColors.light_neutrals_600 }}
    />
  );
};

export default EmployeeCloseButton;
