/* eslint-disable prettier/prettier */
/* eslint-disable */

import {
  createRequestTypes,
  action,
  FAILURE,
  REQUEST,
  SUCCESS
} from './utils';

export const INITIALIZATION = 'INITIALIZATION';
export const initializationAction = () => action(INITIALIZATION);

export const GET_USER_CONTEXT = createRequestTypes('GET_USER_CONTEXT');
export const getUserContextActions = {
  request: () => action(GET_USER_CONTEXT[REQUEST]),
  success: (response: any) => action(GET_USER_CONTEXT[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_USER_CONTEXT[FAILURE], { payload: error })
};

// LINK
export const GET_LINK_SETTINGS = createRequestTypes('GET_LINK_SETTINGS');
export const getLinkSettingsActions = {
  request: (url: string, currentDate: Date) => action(GET_LINK_SETTINGS[REQUEST], { payload: { url, currentDate } }),
  success: (response: any) => action(GET_LINK_SETTINGS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_LINK_SETTINGS[FAILURE], { payload: error })
};

export const SET_LINK_MODE = 'SET_LINK_MODE';
export const setLinkModeAction = (isLinkMode: boolean) => action(SET_LINK_MODE, { payload: { isLinkMode } });

export const SET_LINK_ERROR = 'SET_LINK_ERROR';
export const setLinkErrorAction = (status: boolean, code: number) => action(SET_LINK_ERROR, { payload: { status, code } });

export const GET_FEEDBACK_LINK = createRequestTypes('GET_FEEDBACK_LINK');
export const getFeedbackLinkActions = {
  request: () => action(GET_FEEDBACK_LINK[REQUEST]),
  success: (response: any) => action(GET_FEEDBACK_LINK[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_FEEDBACK_LINK[FAILURE], { payload: error })
};
