import { FC, ReactNode } from 'react';

import { Box, Typography, useMediaQuery } from '@mui/material';
import { MobileViewDrawer } from 'portfolio3/components/drawers';
import { commonTheme } from 'portfolio3/styles/theme';
import InfoModal from 'portfolio3/ui-kit/modals/InfoModal';

import * as styles from './styles';

type ReportErrorDrawerSubmitDialogProps = {
  title: string;
  description: string;
  iconElement: ReactNode;
  isOpen: boolean;
  onClose: () => void;
};

const ReportErrorDrawerSubmitDialog: FC<ReportErrorDrawerSubmitDialogProps> = ({
  title,
  description,
  iconElement,
  isOpen,
  onClose,
}) => {
  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  if (isMobile) {
    return (
      <MobileViewDrawer title="" open={isOpen} onClose={onClose}>
        <Box sx={styles.mobileRoot}>
          <Box sx={styles.mobileIconContainer}>{iconElement}</Box>
          <Typography variant="Headings/H6">{title}</Typography>
          <Typography variant="Paragraph MD/Regular">{description}</Typography>
        </Box>
      </MobileViewDrawer>
    );
  }

  return (
    <InfoModal
      open={isOpen}
      onClose={onClose}
      header={title}
      text={description}
      variant="info"
      onCancel={onClose}
      cancelButtonText="Понятно"
    />
  );
};

export default ReportErrorDrawerSubmitDialog;
