/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { FormControlLabel as FormControlLabelMaterial, FormControlLabelProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Colors } from '../../style/variables';

interface IFormControlLabelBackground {
  backgroundLabel?: boolean;
}

const useStyles = makeStyles({
  controlLabel: ({ backgroundLabel }: IFormControlLabelBackground) => ({
    padding: backgroundLabel ? '12px 16px' : 0,
    borderRadius: 8,
    margin: 0,
    position: 'relative',
    '& .MuiFormControlLabel-label': {
      marginLeft: 12,
      color: Colors['main-text'],
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px'
    },
    '& .Mui-disabled': {
      color: Colors['note-text']
    },
    '& .Mui-checked': {
      position: 'static'
    },
    '&:hover .MuiCheckbox-root': {
      position: 'static'
    },
    '&:hover .MuiRadio-root': {
      position: 'static'
    },
    '& .Mui-checked:before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: backgroundLabel ? Colors['blue-primrose_l2'] : 'transparent',
      top: 0,
      left: 0,
      'z-index': '-1',
      borderRadius: 8
    },
    '& .Mui-disabled.Mui-checked:before': {
      backgroundColor: backgroundLabel ? Colors['page-background'] : 'transparent'
    },
    '&:hover .MuiCheckbox-root:before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: backgroundLabel ? Colors['page-background'] : 'transparent',
      top: 0,
      left: 0,
      'z-index': '-1',
      borderRadius: 8
    },
    '&:hover .MuiRadio-root:before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: backgroundLabel ? Colors['page-background'] : 'transparent',
      top: 0,
      left: 0,
      'z-index': '-1',
      borderRadius: 8
    },
    '&:hover .Mui-checked:before': {
      backgroundColor: backgroundLabel ? Colors['blue-primrose_l2'] : 'transparent'
    },
    '&:hover .Mui-disabled:before': {
      backgroundColor: 'transparent'
    },
    '&:hover .Mui-disabled.Mui-checked:before': {
      backgroundColor: backgroundLabel ? Colors['page-background'] : 'transparent'
    }
  })
});

const FormControlLabel: React.FC<FormControlLabelProps & IFormControlLabelBackground> = (props) => {
  const classes = useStyles(props);

  return (
    <FormControlLabelMaterial
      classes={{
        root: classes.controlLabel
      }}
      {...props}
    />
  );
};

export default FormControlLabel;
