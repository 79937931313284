import { FC, PropsWithChildren } from 'react';

import { SxProps } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';
import { mergeSx } from 'utils';

import BaseBadge from './BaseBadge';

const styles: SxProps = {
  padding: '4px',
};

interface IIconBadgeProps {
  iconColor?: string;
  backgroundColor: string;
  sx?: SxStyles;
}
const IconBadge: FC<IIconBadgeProps & PropsWithChildren> = ({
  iconColor = NeutralColors.light_neutrals_600,
  ...props
}) => {
  return <BaseBadge textColor={iconColor} {...props} sx={mergeSx(props.sx, styles)} />;
};

export default IconBadge;
