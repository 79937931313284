/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconChevronSmallRight: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M10.232 7.023 14.5 12l-4.268 4.977a.883.883 0 0 0 .128 1.308c.187.144.414.215.64.215.286 0 .57-.114.768-.334l5-5.572c.039-.043.059-.095.088-.143.024-.039.053-.072.071-.115a.859.859 0 0 0 .072-.332L17 12l-.001-.004a.859.859 0 0 0-.072-.332c-.018-.043-.047-.076-.071-.115-.029-.049-.049-.1-.088-.143l-5-5.572a1.053 1.053 0 0 0-1.408-.119.883.883 0 0 0-.128 1.308Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconChevronSmallRight;
