import { FC, memo } from 'react';
import { useDispatch } from 'react-redux';

import { searchPanelActions } from '../../model/actions';
import CivilSection from '../CivilSection';
import CreationSection from '../CreationSection';
import CultureSection from '../CultureSection';
import ProfessionSection from '../ProfessionSection';
import ProfileSection from '../ProfileSection';
import ScienceSection from '../ScienceSection';
import SportSection from '../SportSection';
import StudySection from '../StudySection';

const SearcherResultSections: FC = () => {
  const dispatch = useDispatch();
  const handleClose = () => dispatch(searchPanelActions.setOpen(false));

  return (
    <>
      <ProfileSection onCloseSearcher={handleClose} />
      <StudySection onCloseSearcher={handleClose} />
      <ScienceSection />
      <SportSection />
      <CreationSection />
      <CultureSection />
      <CivilSection />
      <ProfessionSection onCloseSearcher={handleClose} />
    </>
  );
};

export default memo(SearcherResultSections);
