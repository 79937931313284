import { SxProps } from '@mui/material';

export const getPatternStyle = (pattern: string, color: string): SxProps => ({
  background: `url(${pattern}), ${color}, rgba(100%, 100%, 100%, 0.2)`,
  backgroundBlendMode: 'overlay',
  backgroundSize: '400%',
  backgroundPosition: '50% 50%',
  width: '100%',
  height: '100%',

  '&:before': {
    position: 'absolute',
    height: '100%',
    width: '100%',
    content: '""',
    zIndex: -1,
    backgroundColor: '#ffffff',
  },
});
