import { ComponentType, FC, PropsWithChildren } from 'react';

import FixedWidthContainer from 'portfolio3/components/common/FixedWidthContainer';

const withFixedWidthContainer = () => {
  return <P extends PropsWithChildren<unknown>>(WrappedComponent: ComponentType<P>): FC<P> => {
    return function WithFixedWidthContainer({ ...props }) {
      return (
        <FixedWidthContainer>
          <WrappedComponent {...props} />
        </FixedWidthContainer>
      );
    };
  };
};

export default withFixedWidthContainer;
