import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const ContactSports: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    width="230"
    height="129"
    viewBox="0 0 230 129"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    fill="none"
    sx={{ fill: 'none' }}
  >
    {/*eslint-disable max-len */}
    <path
      d="M201.581 28.85c-.08 0-.16-.02-.23-.06-.09-.05-9.08-4.88-16.46-2.28a.51.51 0 0 1-.668-.505.504.504 0 0 1 .338-.445c7.8-2.75 16.88 2.14 17.26 2.35a.503.503 0 0 1 .247.564.497.497 0 0 1-.487.376Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M181.871 41.8a.41.41 0 0 1-.21 0l-10-4.83a.5.5 0 0 1-.28-.5c0-.18.5-4.34 6.7-8.48 6.2-4.14 11.51-5.1 18.66-3.28.62-.82 2.73-2.94 9-5.28a.409.409 0 0 1 .22 0l2.34.21c2.853.253 5.663.868 8.36 1.83l4.11 1.47h.09l2.62 1.59a.3.3 0 0 1 .11.09c1.78 2 1.91 2.65 1.81 3.05a.66.66 0 0 1-.25.36.786.786 0 0 1-.67.18 1.31 1.31 0 0 1-.82-.7 2.995 2.995 0 0 0-1.62-.94.448.448 0 0 1-.16-.06 14.335 14.335 0 0 0-6.9-1.77h-2.73l-3.25.26a.5.5 0 0 1-.489-.267.497.497 0 0 1 .211-.678.509.509 0 0 1 .188-.055l3.23-.31h2.78a15.266 15.266 0 0 1 7.31 1.86 3.89 3.89 0 0 1 1.28.53c-.18-.23-.41-.49-.67-.79l-2.51-1.52-4.07-1.45a32.988 32.988 0 0 0-8.11-1.78l-2.22-.19c-7.25 2.73-8.55 5.08-8.56 5.1a.497.497 0 0 1-.57.26c-7.11-1.89-12-1.06-18.19 3.05-4.79 3.19-5.94 6.4-6.2 7.42l9.7 4.66a.496.496 0 0 1 .23.67.48.48 0 0 1-.47.29Zm42.68-14.67h.07-.07Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M199.209 31.74h-.07a.488.488 0 0 1-.42-.55c0-.16.57-3.83 10.37-6-.72-.17-1.66-.34-2.52-.47a.446.446 0 0 1-.31-.17l-1.26-1.55-.74.51a.477.477 0 0 1-.28.1h-2.55l-1.43 1.39a.614.614 0 0 1-.2.13l-4.06 1.35a.499.499 0 0 1-.63-.32.498.498 0 0 1 .148-.537.494.494 0 0 1 .172-.093l3.94-1.32 1.46-1.46a.541.541 0 0 1 .36-.14h2.6l1-.69a.492.492 0 0 1 .66.09l1.46 1.69c4.16.62 4.17 1.09 4.18 1.47 0 .56-.64.69-.89.74-9.81 2-10.48 5.35-10.48 5.38a.507.507 0 0 1-.51.45ZM184.06 79.2a.46.46 0 0 1-.22-.06l-5.28-2.6-.09-.06c-.41-.33-10.18-8.07-11.76-12.6-1.58-4.53.95-14.38 1-14.8a.508.508 0 0 1 .786-.275.507.507 0 0 1 .195.33.513.513 0 0 1-.011.195c0 .1-2.55 10-1.07 14.22 1.48 4.22 10.7 11.56 11.39 12.11l5 2.47 3.61-2.13.12-.05 3.11-.86v-.91l-2.87-2-11.64-9.89L173.8 60a.495.495 0 0 1-.14-.49l2.34-9.25a.485.485 0 0 1 .37-.36c.09 0 2.32-.6 3.92-3.8 1.48-3 1.19-4.42 1.14-4.61a.772.772 0 0 1 0-.54c.22-1 2-2.21 5.32-3.77a11.72 11.72 0 0 1 5.82-1.34 4.207 4.207 0 0 0 3.67-1.45.48.48 0 0 1 .46-.12 9.24 9.24 0 0 0 5.49-.57.509.509 0 0 1 .52 0c.07 0 1.74 1.16 5.11-.07 3.18-1.16 2.65-3.17 2.62-3.25a.51.51 0 0 1 .34-.62.5.5 0 0 1 .62.33s.86 3-3.24 4.48c-3.14 1.15-5.09.44-5.76.1a10.131 10.131 0 0 1-5.67.57 5.058 5.058 0 0 1-4.17 1.55c-1.873-.057-3.73.37-5.39 1.24-4.17 1.97-4.75 2.97-4.82 3.11.09.26.55 1.93-1.19 5.41a8.118 8.118 0 0 1-4.28 4.23l-2.18 8.71 2.3 2.12 11.58 9.84 3.06 2.1a.483.483 0 0 1 .21.41v1.55a.5.5 0 0 1-.36.48l-3.42 1-3.75 2.19a.521.521 0 0 1-.26.02Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M166.7 58.94a.512.512 0 0 1-.4-.2l-.62-.81a7.394 7.394 0 0 1-.94-7.28l6.63-14.39A.49.49 0 0 1 172 36a.5.5 0 0 1 .25.66l-6.63 14.38a6.4 6.4 0 0 0 .83 6.27l.61.81a.486.486 0 0 1-.124.729.49.49 0 0 1-.266.071l.03.02ZM210.09 26a.507.507 0 0 1-.16-1c2.365-.73 4.826-1.1 7.3-1.1a.502.502 0 0 1 .48.52.482.482 0 0 1-.51.48 23.355 23.355 0 0 0-7 1.05.39.39 0 0 1-.11.05ZM204.29 32.91a.496.496 0 0 1-.44-.27.487.487 0 0 1 .057-.55.506.506 0 0 1 .153-.12l2.23-1.16 6.11-2 8-1a.421.421 0 0 1 .22 0 1.35 1.35 0 0 0 1.41-.42.5.5 0 0 1 .7-.08.518.518 0 0 1 .08.71 2.32 2.32 0 0 1-2.38.77l-7.86 1-5.94 1.91-2.15 1.12a.459.459 0 0 1-.19.09ZM186.72 81.94h-.4a1.407 1.407 0 0 1-1-.6 1.374 1.374 0 0 1-.17-1.14l.46-1.56v-1a.508.508 0 0 1 .5-.5.5.5 0 0 1 .5.5v1.06a.818.818 0 0 1 0 .14l-.48 1.62a.343.343 0 0 0 .05.3.306.306 0 0 0 .26.16c.391.047.788.002 1.16-.13l.28-.1a4.733 4.733 0 0 0 2.44-2l.59-1a.519.519 0 0 1 .18-.18l.29-.16a1.576 1.576 0 0 0 .78-1.22 1.528 1.528 0 0 0-.58-1.33l-.55-.44a.501.501 0 0 1 .265-.858c.124-.02.25.008.355.078l.56.44a2.56 2.56 0 0 1-.33 4.2l-.18.09-.52.89a5.673 5.673 0 0 1-3 2.44l-.28.1c-.379.133-.779.2-1.18.2ZM182.65 64.8h-.06l-3.74-.41a.502.502 0 0 1 .11-1l3.39.36.6-1.26.5-1.3-8.45-5.54a.499.499 0 1 1 .54-.84l8.82 5.78a.518.518 0 0 1 .19.6l-.66 1.71-.77 1.61a.495.495 0 0 1-.47.29Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M183.7 64.62c-.379 0-.752-.09-1.09-.26a.51.51 0 0 1-.22-.67.495.495 0 0 1 .49-.275.502.502 0 0 1 .19.055 1.37 1.37 0 0 0 1.14.05 1.558 1.558 0 0 0 0-1.38l-.51-.14a.515.515 0 0 1-.365-.635.52.52 0 0 1 .635-.365l.62.17a.74.74 0 0 1 .44.35 2.58 2.58 0 0 1 .06 2.41 1.069 1.069 0 0 1-.54.49c-.27.116-.557.184-.85.2ZM182.001 42.92a.437.437 0 0 1-.22-.05l-10.52-5.09a.5.5 0 0 1 .43-.9l10.48 5.12a.492.492 0 0 1 .254.288.51.51 0 0 1-.024.382.478.478 0 0 1-.4.25ZM224.4 34a3.847 3.847 0 0 1-1.12-.17l-.27-.08a3.752 3.752 0 0 1-2.38-2.16l-.08-.19-.06.05a.661.661 0 0 1-.971-.161.651.651 0 0 1-.099-.349v-2.5a.503.503 0 0 1 .5-.5.512.512 0 0 1 .5.5v1.79a.478.478 0 0 1 .43-.1.52.52 0 0 1 .34.29l.33.8a2.759 2.759 0 0 0 1.75 1.59l.28.09c.428.128.881.152 1.32.07a1.15 1.15 0 0 0 .94-1.13L225 31a3.459 3.459 0 0 1-1-1.65l-.52-2.07a.517.517 0 0 1 .375-.625.515.515 0 0 1 .625.375l.52 2.08c.112.446.347.852.68 1.17l.87.84a.907.907 0 0 1 .28.64 2.15 2.15 0 0 1-1.76 2.12c-.22.059-.444.1-.67.12ZM189.109 81.17c-.049.01-.1.01-.15 0a1.865 1.865 0 0 1-1.08-1 4.83 4.83 0 0 1-.34-1.19.507.507 0 1 1 1-.18c.048.316.135.625.26.92.091.217.263.39.48.48a.492.492 0 0 1 .352.427.502.502 0 0 1-.522.543ZM187.479 81.83a.511.511 0 0 1-.5-.44 7.397 7.397 0 0 0-.51-2 .504.504 0 0 1 .471-.69.486.486 0 0 1 .459.31 8.51 8.51 0 0 1 .57 2.23.482.482 0 0 1-.1.365.497.497 0 0 1-.33.185l-.06.04ZM190.1 80a.459.459 0 0 1-.23-.06 2.175 2.175 0 0 1-1.09-1.43.5.5 0 0 1 .759-.525.5.5 0 0 1 .221.315 1.174 1.174 0 0 0 .58.76.498.498 0 0 1-.24.94ZM125.44 23.25a.51.51 0 0 1-.2-1l10.63-4.64c1.49-.5 3.84-1.7 4-2.87a.786.786 0 0 0-.23-.66c-.73-.73-2.86-.89-4.64-.73a.497.497 0 0 1-.493-.262.48.48 0 0 1-.057-.188.511.511 0 0 1 .46-.55c.95-.08 4.16-.26 5.44 1a1.747 1.747 0 0 1 .51 1.46c-.22 2.19-4.14 3.56-4.59 3.7l-10.59 4.63a.45.45 0 0 1-.24.11Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M136.249 33.68a.52.52 0 0 1-.38-.17.498.498 0 0 1 0-.7.826.826 0 0 0 .21-.7c-.06-.26-.45-.95-2.57-1.54a26.015 26.015 0 0 0-2.92-.56c-3.12-.48-6.64-1-9.82-5.1a5.636 5.636 0 0 1-1.19-4.22 5.186 5.186 0 0 1 3.51-4.2 5 5 0 0 1 3.13-.52 6.09 6.09 0 0 0 3.28-.46l7.15-2.32a.524.524 0 0 1 .31 1l-7.15 2.32a7.05 7.05 0 0 1-3.74.5 4.069 4.069 0 0 0-2.6.45 4.249 4.249 0 0 0-2.9 3.42 4.649 4.649 0 0 0 1 3.47c2.94 3.75 6.12 4.24 9.19 4.72 1.01.139 2.012.336 3 .59 2 .54 3.07 1.31 3.29 2.29a1.787 1.787 0 0 1-.52 1.65.502.502 0 0 1-.28.08Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M145.93 23.92a.468.468 0 0 1-.3-.1A37.087 37.087 0 0 0 135.1 19a.518.518 0 0 1-.355-.645.519.519 0 0 1 .645-.355 37.15 37.15 0 0 1 10.84 5 .497.497 0 0 1 .174.558.5.5 0 0 1-.474.342v.02ZM127 28.85a.473.473 0 0 1-.35-.14.49.49 0 0 1-.109-.163.49.49 0 0 1 0-.384.491.491 0 0 1 .109-.163 7.003 7.003 0 0 0 1.35-5.94.51.51 0 0 1 .41-.58.51.51 0 0 1 .58.41 7.938 7.938 0 0 1-1.69 6.81.47.47 0 0 1-.3.15Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M132.889 30.4h-.08a.5.5 0 0 1-.42-.57 33.982 33.982 0 0 1 3.88-11.14.496.496 0 0 1 .68-.168.502.502 0 0 1 .18.678 33.52 33.52 0 0 0-3.75 10.8.502.502 0 0 1-.49.4ZM143.17 24a.533.533 0 0 1-.4-.19 1.547 1.547 0 0 0-.86-.55.49.49 0 0 1-.301-.77.5.5 0 0 1 .521-.2 2.489 2.489 0 0 1 1.43.9.499.499 0 0 1-.39.81ZM131.599 15.85a.52.52 0 0 1-.46-.29 7.027 7.027 0 0 0-1.19-.74 5.155 5.155 0 0 1-1.55-1 .617.617 0 0 1 .116-.88.623.623 0 0 1 .304-.12c.212-.02.427-.02.64 0a1.555 1.555 0 0 1-.21-.3 1.268 1.268 0 0 1 0-1.25.997.997 0 0 1 .62-.44.994.994 0 0 1 .49-.5 1.35 1.35 0 0 1 1 0 .943.943 0 0 1 0-.3c.081-.305.279-.567.55-.73a1 1 0 0 1 .74-.09c.27.092.503.271.66.51l2.32 3a.502.502 0 0 1-.79.62l-2.32-3a.806.806 0 0 0-.15-.16v.11c-.022.214.009.43.09.63a.508.508 0 0 1-.09.45.488.488 0 0 1-.42.19 2.293 2.293 0 0 1-.71-.19l-.14-.06a.864.864 0 0 0-.28-.06.498.498 0 0 1-.2.579.502.502 0 0 1-.61-.049c.06.07.06.18.12.28.114.172.256.324.42.45l1.47 1.3a.503.503 0 0 1 .15.53.504.504 0 0 1-.42.34h-.2c.227.137.409.339.52.58a.501.501 0 0 1-.3.64l-.17-.05ZM120.399 20a.49.49 0 0 1-.46-.31 23.175 23.175 0 0 1-1.26-4.46.507.507 0 1 1 1-.17 22.03 22.03 0 0 0 1.2 4.26.493.493 0 0 1-.009.386.504.504 0 0 1-.281.264.568.568 0 0 1-.19.03ZM121.651 14a3.16 3.16 0 0 1-2.21-.93 3.235 3.235 0 0 1-.81-1.23 1.92 1.92 0 0 1 .12-1.65c.119-.211.296-.385.51-.5a1.4 1.4 0 0 1 .85-.11c.39.06.758.218 1.07.46a.5.5 0 0 1 .085.705.503.503 0 0 1-.705.085 1.2 1.2 0 0 0-.6-.26.459.459 0 0 0-.24 0 .334.334 0 0 0-.12.12.992.992 0 0 0 0 .81c.131.326.333.62.59.86a2.08 2.08 0 0 0 1.81.64.49.49 0 0 1 .6.36.513.513 0 0 1-.054.38.487.487 0 0 1-.306.23 2.704 2.704 0 0 1-.59.03Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M126.3 16.77a6.127 6.127 0 0 1-4-1.84.513.513 0 0 1 .11-.7.496.496 0 0 1 .556-.023c.056.034.105.08.144.133.38.52 2.42 1.67 3.51 1.39.24-.06.49-.19.55-.7a5.02 5.02 0 0 0-.3-1.69.51.51 0 0 1 .21-.58l.74-.45c-.25-.41-.61-.94-.91-1.39a6.396 6.396 0 0 1-.79-1.28 1.997 1.997 0 0 1 0-.85v-.13a2.846 2.846 0 0 0-.62-2.2 2.478 2.478 0 0 0-2-.86.502.502 0 0 1-.54-.46.51.51 0 0 1 .46-.54 3.457 3.457 0 0 1 2.88 1.22 3.832 3.832 0 0 1 .85 3v.15a1.293 1.293 0 0 0 0 .43l.65 1c.43.606.831 1.234 1.2 1.88a.5.5 0 0 1 0 .38.488.488 0 0 1-.25.29c-.21.11-.55.31-.8.46.176.566.258 1.157.24 1.75a1.716 1.716 0 0 1-1.3 1.54 2.914 2.914 0 0 1-.59.07Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M120.679 10.74a.498.498 0 0 1-.48-.39.493.493 0 0 1 .37-.6 4.059 4.059 0 0 0 3-3.07c.1-.309.1-.641 0-.95-.24-.57-1-.79-1.51-.87a7.14 7.14 0 0 0-4.27.56 3.324 3.324 0 0 0-1.46 1.16c.13.426.087.886-.12 1.28a1.68 1.68 0 0 1-1.21.92.49.49 0 0 1-.37-.1.477.477 0 0 1-.2-.33l-.2-1.41a1.067 1.067 0 0 1 .2-.92.892.892 0 0 1 1.14-.1 4.473 4.473 0 0 1 1.8-1.41 8.231 8.231 0 0 1 4.87-.64 2.787 2.787 0 0 1 2.25 1.46c.194.49.229 1.029.1 1.54a4.993 4.993 0 0 1-3.71 3.85l-.2.02Zm-5.48-3.52v.21l.05-.11a.372.372 0 0 1-.05-.1Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M119.32 16.83h-.09a5.687 5.687 0 0 1-4-3.42 11.72 11.72 0 0 1-.75-5.09.51.51 0 0 1 .52-.48.5.5 0 0 1 .48.52c-.133 1.58.096 3.171.67 4.65a4.663 4.663 0 0 0 3.22 2.83.497.497 0 0 1 .4.59.493.493 0 0 1-.45.4Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M108.239 19.19a.49.49 0 0 1-.44-.27.502.502 0 0 1 .22-.68 3.648 3.648 0 0 0 1.93-2 8.175 8.175 0 0 0 .44-3.19v-.91a15 15 0 0 1 0-2.12 4.779 4.779 0 0 1 1.23-3 2.894 2.894 0 0 1 3.32-.63.499.499 0 0 1 .2.68.5.5 0 0 1-.68.2 1.89 1.89 0 0 0-2.13.45 3.904 3.904 0 0 0-.94 2.39c-.05.665-.05 1.334 0 2v.94a9.14 9.14 0 0 1-.5 3.57 4.716 4.716 0 0 1-2.42 2.57.59.59 0 0 1-.23 0Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M108.91 24.63a.493.493 0 0 1-.41-.22.499.499 0 0 1 0-.62c.437-.45.777-.984 1-1.57l-.22.13a.88.88 0 0 1-1.266-.596.905.905 0 0 1 .046-.534c.15-.35.29-.71.43-1.06-.438.11-.894.13-1.34.06a.857.857 0 0 1-.81-.77c0-.2.06-.57.63-.81l.57-.21.52-.19a.49.49 0 0 1 .65.27.5.5 0 0 1-.26.65.942.942 0 0 1 .85.16.829.829 0 0 1 .19 1c-.12.33-.25.65-.38 1a2.59 2.59 0 0 0 .76-.79.49.49 0 0 1 .56-.21.484.484 0 0 1 .36.49 5.524 5.524 0 0 1-.37 1.88 14.148 14.148 0 0 0 2.93-3.86 9.086 9.086 0 0 0 1.57-4.57 6.787 6.787 0 0 0-.06-.78 5.702 5.702 0 0 1 0-1.65.499.499 0 0 1 .205-.323.488.488 0 0 1 .375-.077.483.483 0 0 1 .399.377c.014.063.014.13.001.193a5.324 5.324 0 0 0 0 1.37c0 .29.06.58.07.87a10 10 0 0 1-1.71 5.09c-1.27 2.22-2.77 4.4-5.11 5.3-.06.01-.12.01-.18 0ZM124.879 10.2a.644.644 0 0 1-.23 0 .508.508 0 0 1-.21-.68 1.922 1.922 0 0 1 1.84-1 .497.497 0 0 1 .44.34.51.51 0 0 1-.027.383.504.504 0 0 1-.483.277.94.94 0 0 0-.88.5.508.508 0 0 1-.45.18Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M125.53 10.74a.531.531 0 0 1-.24-.06.513.513 0 0 1-.18-.69 1.003 1.003 0 0 1 1.06-.48.496.496 0 0 1 .312.221.485.485 0 0 1 .058.379.495.495 0 0 1-.221.315.496.496 0 0 1-.379.065.449.449 0 0 1-.41.25ZM137.371 34.81a2.12 2.12 0 0 1-1.94-1.1 1.6 1.6 0 0 1 .07-1.44 1.324 1.324 0 0 1 1-.71.503.503 0 1 1 .12 1 .402.402 0 0 0-.25.2.622.622 0 0 0 0 .53 1.153 1.153 0 0 0 1.09.53 3.995 3.995 0 0 0 1.54-.52 29.207 29.207 0 0 0 4.14-2.51 11.593 11.593 0 0 0 2.86-2.85 5.45 5.45 0 0 0 1-3.69 1.897 1.897 0 0 0-.35-1 1.1 1.1 0 0 0-1.41-.21.504.504 0 1 1-.56-.84 2.141 2.141 0 0 1 2.75.42c.322.424.516.93.56 1.46a6.483 6.483 0 0 1-1.18 4.38 13.224 13.224 0 0 1-3.15 3.1 29.76 29.76 0 0 1-4.29 2.59 4.66 4.66 0 0 1-2 .62v.04Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M136.781 32.9a.503.503 0 0 1-.47-.34.497.497 0 0 1 .31-.63 18.937 18.937 0 0 0 5.95-3 7.144 7.144 0 0 0 2.84-5.51.498.498 0 0 1 .853-.354.5.5 0 0 1 .147.354 8.152 8.152 0 0 1-3.2 6.31 19.764 19.764 0 0 1-6.21 3.14.512.512 0 0 1-.22.03ZM142.6 72.74c-.42 0-1.48-.31-5.2-2.27l-.17-.1a.488.488 0 0 1-.26-.64c.17-.49 4-12.36 6.11-18.76L137 34.48a.488.488 0 0 1-.026-.414.492.492 0 0 1 .295-.291.497.497 0 0 1 .573.172.497.497 0 0 1 .088.193l6.18 16.65c.04.106.04.224 0 .33L141 60.6c-1.84 5.66-2.61 8-3 9.05 3.77 2 4.48 2.09 4.59 2.09.41-.14 2.07-.45 3.11-.64l-.55-.69a.49.49 0 0 1-.08-.48l4.62-13a18.74 18.74 0 0 0 1.07-7.23L150 33.5a.482.482 0 0 1 .082-.292.484.484 0 0 1 .238-.188c18.22-7.27 20.65-8.18 21.09-8.27.48-.07 9.44-2.1 15.66-3.52L186 17.45a.486.486 0 0 1-.33-.3.491.491 0 0 1 .1-.52L184.49 12a4.39 4.39 0 0 1 .12-1.89 2.95 2.95 0 0 0 .06-1.6 3.015 3.015 0 0 0-.65-.87c-.16-.17-.33-.34-.47-.52a1.316 1.316 0 0 1 .016-1.635 1.319 1.319 0 0 1 1.594-.365c.73.34 1.45.71 2.15 1.11.175.093.331.218.46.37.164.248.245.543.23.84l.13 3.14c-.011.183.013.366.07.54.1.202.24.382.41.53a14.736 14.736 0 0 1 1.8 2.27 1.324 1.324 0 0 1-.081 1.555 1.325 1.325 0 0 1-.429.345c-1 .49-1.94.95-2.94 1.36l1.19 4.28a.462.462 0 0 1-.05.38.5.5 0 0 1-.32.24c-13.14 3-15.69 3.55-16.25 3.64-.59.17-12.48 4.88-20.53 8.09l.76 15.81a19.951 19.951 0 0 1-1.13 7.62L146.11 70l.9 1.13a.487.487 0 0 1 .07.49.493.493 0 0 1-.38.31c-.91.17-3.39.62-3.87.78a1.078 1.078 0 0 1-.23.03ZM184.59 6a.327.327 0 0 0-.26.13.289.289 0 0 0 0 .38c.128.16.265.314.41.46.367.338.662.746.87 1.2.21.708.21 1.462 0 2.17a3.474 3.474 0 0 0-.13 1.44l1.23 4.44c.94-.39 1.86-.82 2.75-1.28a.294.294 0 0 0 .17-.21.34.34 0 0 0 0-.26 13.721 13.721 0 0 0-1.64-2.07 2.753 2.753 0 0 1-.66-.9 2.6 2.6 0 0 1-.14-.87L187 7.48a1.192 1.192 0 0 0 0-.28l-.15-.1c-.68-.38-1.38-.75-2.08-1.08l-.18-.02Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M147.47 24.6a.482.482 0 0 1-.41-.22.499.499 0 0 1 .13-.69c6.76-4.64 36.4-11.94 37.66-12.25a.51.51 0 0 1 .6.37.493.493 0 0 1-.37.6c-.3.08-30.71 7.57-37.32 12.1a.523.523 0 0 1-.29.09ZM145 74.75h-.22a18.55 18.55 0 0 1-6.32-1.7 2.627 2.627 0 0 1-1.17-.91 2.715 2.715 0 0 1-.06-2.19.519.519 0 0 1 1 .27c-.189.441-.207.937-.05 1.39.197.242.457.426.75.53 1.885.9 3.919 1.447 6 1.61a.84.84 0 0 0 .49 0 .497.497 0 0 1 .228-.297.48.48 0 0 1 .372-.043.512.512 0 0 1 .35.63 1.21 1.21 0 0 1-1.37.71Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M146.121 74.32h-.41a.498.498 0 0 1-.34-.178.499.499 0 0 1 .063-.708.502.502 0 0 1 .367-.114c.186.025.374.025.56 0a.252.252 0 0 0 .13-.1.499.499 0 0 1 .876-.286.51.51 0 0 1 .124.366 1.148 1.148 0 0 1-.83 1 1.77 1.77 0 0 1-.54.02Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M146.931 73.78a.499.499 0 0 1-.535-.465.498.498 0 0 1 .465-.535 2.934 2.934 0 0 0 1.64-.78.314.314 0 0 0 .1-.1h-.72a3.05 3.05 0 0 1-.75-.18.51.51 0 0 1-.31-.64.52.52 0 0 1 .64-.31c.15.064.308.108.47.13h.26c.14-.01.28-.01.42 0a1.072 1.072 0 0 1 1 .71 1.085 1.085 0 0 1-.42 1.12 3.902 3.902 0 0 1-2.2 1l-.06.05Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M111.607 76.201a45.476 45.476 0 0 0 2.883-3.363c.867-1.122 1.751-2.394 2.438-3.674.683-1.272 1.194-2.592 1.27-3.802l.001-.016V65.169c.952-.878 1.927-1.745 2.922-2.53 2.168.964 5.104 2.459 7.535 4.377 2.554 2.016 4.435 4.406 4.463 7.048.029 3.881-1.482 6.376-3.062 7.904a9.55 9.55 0 0 1-2.267 1.631 6.496 6.496 0 0 1-.828.358c-.088.03-.159.05-.212.065a50.27 50.27 0 0 0-15.143-7.82Z"
      fill="#fff"
      stroke={props.fill || 'currentColor'}
    />
    <path
      d="M64.3 56.27A3.22 3.22 0 0 1 62.82 53a9.36 9.36 0 0 1 1.48-2.88 7.59 7.59 0 0 1 7.2-3 5.9 5.9 0 0 1 3.7 1.67c2.8 2.8 2.19 8 2.19 8s0 1.08-1.79 1.55a9.608 9.608 0 0 1-3.06.18 27.996 27.996 0 0 1-4.3-.61 10.68 10.68 0 0 1-3.94-1.64v0ZM64.9 60.92s.69 2.94 2.76 3.87c1.13.51 2.68.43 4.76-1.09.15-.1.3-.22.46-.35A7.272 7.272 0 0 0 74.65 61c.4-.784.735-1.6 1-2.44"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M64.3 50.07s-8.76-1.28-17.43 5.65a30.859 30.859 0 0 0-7.2 8.1A7.54 7.54 0 0 0 42 74l10.07 6.7 2.5-3.94 2.34-3.67-3.61-2.44-2.77-1.87s7.39-1.88 12.55-10.3c.41-.68.82-1.41 1.2-2.18M67.67 59.67a.2.2 0 0 1 .18-.1.79.79 0 0 1 .49.29 3.87 3.87 0 0 0 2.87 1.31M72.29 63.53c0-.76.1-1.52.16-2.28v-.33M68 63.55l2.15.92M75.66 78.61l.25.2c.67.53 2.55 2.07 4.42 3.83 2.44 2.29 4.88 5 4.7 6.38a46.59 46.59 0 0 0-.45 6.85v3.51l-3.53 12v.29l.82 4.41 7.6 4.56a.884.884 0 0 1 .41.53.89.89 0 0 1-.88 1.1l-11.72-.19a1.993 1.993 0 0 1-2.1-2.18l.26-4 .15-4.41v-1.27M38.2 79.31a15.169 15.169 0 0 1 10.74 6.23v0M60.74 84.51c.5-.53 5.1-5.22 10-6.2"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M75.2 94.2c-.23.72-3.35 5.92-3.35 5.92l.34 3.82 1.33 6 2.13.42 1.66.32a2.463 2.463 0 0 0 2-.49l.83-.67.35-1.39v-1.47l-.7-.61-2 .67h-.05l-1-2.08-2.43-2.43.79-.76.4-5.45a4.309 4.309 0 0 0-.26-1.79A4.21 4.21 0 0 0 73.29 92l-7.82-4.29a26.78 26.78 0 0 1-4.68-3.25c-.46-.41-.91-.83-1.35-1.27l-1.13-1.12s-5.2.35-5.83 0a.422.422 0 0 0-.14 0c-.68 0-2 2.12-2 2.12l-1.4 1.37-10.37 10.2-4.65 4.24a11.08 11.08 0 0 0-2.55 3.51 11.861 11.861 0 0 1-3.29 4.25l-7.27 6-2.39 2-1.64 4.48a2.91 2.91 0 0 1-3 1.89v0c-.82 0-4 2.65-5.36 2.65H4.05a.233.233 0 0 1-.18-.11c-1.58-3.43 6.23-6.48 6.23-6.48l6.34-6.49.29-.3C23 96.6 27.79 95.61 30.91 92.49c2-2 4.12-6.52 5.41-9.54.529-1.26 1.15-2.48 1.86-3.65v-.08c.46-.74 1-1.48 1.48-2.18L42 74M84.58 95.87l9.87-15.17.24.06c1.33.32 8 1.69 14.24-1.09l-6.44 10.23A5.007 5.007 0 0 0 102 94a6.888 6.888 0 0 0 1.24 2.35l.3.37 6.61 8.15 7.16 8.81-.35 3.46-8 4.6-.14.08a.47.47 0 0 0 .23.88h16.55c.546 0 1.07-.217 1.457-.603.386-.387.603-.911.603-1.457a4.784 4.784 0 0 0-.7-2.47c-.84-1.36-2.15-3.45-2.39-3.45-.24 0-1.73-3.12-1.73-3.12l-5.55 2.08"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m110.699 93.19 1.57 1.71.09.09 4 4.3a20.347 20.347 0 0 1 3.74 5.77l2.79 6.51"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M112.43 94.92c7.63-3.48 14.19-10.41 14.19-10.41a49.781 49.781 0 0 0-15.87-8.09v0s-12.15 2.19-18.39-1.28c-1.48-.82-2.92-1.71-4.26-2.59l-.26-.17A87.657 87.657 0 0 1 80.52 67M110.13 104.84l-1.51 8.81.72 5.28a2.062 2.062 0 0 1-.87 2c-.34.237-.746.363-1.16.36H91.74a.33.33 0 0 1-.3-.21.32.32 0 0 1 .16-.41l9.73-4.93 1.38-3.46 5.91 1.38"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M103.521 96.68v.01l-.81 15.58M75.2 48.7s8.1.43 13 3.2c0 0 4-1.88 18.001 3.92a12.002 12.002 0 0 0 4.94-1.14c1.4-.77 3.27.28 4.35 1.06a.372.372 0 0 1-.007.605.37.37 0 0 1-.223.065h-2.74l1.54 1c.352.21.686.447 1 .71a.319.319 0 0 1 .061.402.318.318 0 0 1-.381.139l-2.91-.87-.48.15a4.5 4.5 0 0 1-3.68-.5l-6.8-.59-6.72-1.49s-4.27-2.07-10.51-.36-8.58 5.76-8.58 5.76a1.653 1.653 0 0 1-.41.18 2.16 2.16 0 0 1-1.6 0M65.77 74s.94-.6 2.47-1.48c.73-.42 1.6-.9 2.56-1.41A55.468 55.468 0 0 1 80.52 67a18.55 18.55 0 0 1 5.2-.89c7.63 0 7.28 1 13.88.7"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M110.699 76.42s6.72-6.67 7-11.09v-.38c.08-1.37.11-2.75.11-4 0-2.68-.12-4.77-.12-4.77l-2.54.25M81.74 78.34c-.22 0-.62.16-1.16.25-.31.07-.67.12-1.08.17a16.777 16.777 0 0 1-3.59 0 12.215 12.215 0 0 1-1.82-.28c-.34-.07-.69-.16-1-.26-4.67-1.39-2.25-7.14-2.25-7.14M80.52 82.43 82 78.27l-.19.06M84.09 72.91c.042.332.065.666.07 1a5.47 5.47 0 0 1-.54 2.58 3.91 3.91 0 0 1-1.86 1.8v0M84 72.87h.17c1.19-.2 2.38-.36 3.58-.47"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M76.43 76.71a1.25 1.25 0 0 0 .69 1.4M72 74.55a1.3 1.3 0 0 0 .13 1.37c.338.546.818.989 1.39 1.28M79.49 78.43v.06M79.5 78.72a3.859 3.859 0 0 1-1-1.68c-.1-.35-.13-.79.17-1a.66.66 0 0 1 .61 0c.186.093.347.23.47.4.364.452.608.989.71 1.56M68.32 72.68c-.022.8.079 1.6.3 2.37a8 8 0 0 0 1.38 2.3c.19.272.423.512.69.71.05.04.108.07.17.09.35.19.76.26 1.11.46.18.1.33.23.52.32a1.44 1.44 0 0 0 1.51-.21M78 73.39c.37-.12.77-.12 1.14 0a4.47 4.47 0 0 1 2.66 1.7c.336.547.592 1.14.76 1.76.08.22.14.45.22.68M81.57 70.6a7.36 7.36 0 0 1 2.59 2.12.17.17 0 0 1 0 .13s-.09.07-.11 0M87.91 72.35c0 .06.09.14.14.2a55.6 55.6 0 0 0 6.64 8.21l.09.1-.27-.07M74.39 105.42a16.126 16.126 0 0 0 2.06 2.58c.158.189.347.348.56.47.279.101.576.138.87.11a.315.315 0 0 0 .19-.06.197.197 0 0 0 .08-.14 1.79 1.79 0 0 0-.36-1.65.23.23 0 0 0-.07-.07M75.64 111.45a11.64 11.64 0 0 0 5.44.21h.07-.16M16.38 111.62h.06a7.888 7.888 0 0 0 4.06 1.91.29.29 0 0 1 .26.13M63.09 58.24c-.005.07-.005.14 0 .21a3 3 0 0 0 1.81 2.46c.246.097.506.154.77.17M117.74 65c1.08-1 2.2-2 3.35-2.89M53.21 70.67h.11a9.55 9.55 0 0 1 3.45-.8 4.46 4.46 0 0 0 2-.31c.69-.37 1.1-1.19 1.84-1.5.74-.31 1.6 0 2.36-.27a5.22 5.22 0 0 0 1.75-1.41A5.19 5.19 0 0 1 67.49 65h.07c.06 0 .11 0 .13.06.02.06-.11.12-.1 0M57.1 73.21a6.618 6.618 0 0 1 2.26-1.5c.709-.26 1.438-.461 2.18-.6l4.4-.93c.32-.06.72-.11.92.14a.62.62 0 0 1-.09.77 1.85 1.85 0 0 1-.72.42c-.732.31-1.444.668-2.13 1.07-.23.13-.48.35-.42.61.05.107.126.2.22.27.375.315.788.58 1.23.79l.45.19c.6.22 1.3.42 1.56 1a1.41 1.41 0 0 1-.16 1.21 4.28 4.28 0 0 1-2.36 1.53l-2.6.94a3 3 0 0 1-.73.19 2.693 2.693 0 0 1-1-.18 40.915 40.915 0 0 1-5.43-2.26.49.49 0 0 1-.14-.11c-.12-.09-.19-.23-.09-.33M110.789 76.36v.06c-.52.75-1.38 2.46-1.82 3.25a4.46 4.46 0 0 1-.27.42"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default ContactSports;
