import { StudentSectionSettingTypeCodes } from 'const';
import { useAppSelector } from 'hooks';
import { visibilitySettingsSelector } from 'selectors';
import { findTargetVisibilitySetting } from 'utils/localVisibilitySettingsService';

import { collection as searchActions } from '../model/actions';
import { searchProfileInterestsSelector } from '../model/selectors';

const profileCode = StudentSectionSettingTypeCodes.profile;
const interestCode = StudentSectionSettingTypeCodes.profileInterest;

const useSearchProfile = () => {
  const settings = useAppSelector(visibilitySettingsSelector);
  const { loading: isInterestsLoading, interests } = useAppSelector(searchProfileInterestsSelector);

  const profileEntitiesCount = [interests.length].reduce((prev, current) => prev + current, 0);

  const hasInterests = interests.length > 0;

  const isInterestsEnabled =
    findTargetVisibilitySetting(settings, [profileCode, interestCode])?.computedVisibility ?? false;

  const isProfileInterestsVisible = hasInterests && isInterestsEnabled;

  const isProfileLoading = isInterestsLoading;
  const isProfileVisible = isProfileInterestsVisible;

  const profileActions = [isInterestsEnabled && searchActions.searchInterestsActions];

  return {
    interests,
    isProfileInterestsVisible,
    isProfileLoading,
    isProfileVisible,
    profileActions,
    profileEntitiesCount,
  };
};

export default useSearchProfile;
