import React from 'react';

import { useUserFunctionality } from 'hooks';

import { scaredCat } from '../../../images';

import './index.scss';

const ContextErrorPlug: React.FC = () => {
  const { isStudent, isParent } = useUserFunctionality();

  const title = isStudent || isParent ? 'Упс! Кажется, что-то пошло не так...' : 'Извините, произошёл сбой';
  const description = isStudent ? 'Подожди немного или' : 'Подождите немного или';
  const buttonText = isStudent ? 'обнови страницу' : 'обновите страницу';

  const refreshPage = () => {
    window.location.reload();
    return false;
  };

  return (
    <section className="context-error-plug">
      <img className="context-error-plug__image" src={scaredCat} alt="Ошибка" />
      <h3 className="context-error-plug__title">{title}</h3>
      <p className="context-error-plug__description">
        {description}{' '}
        <button onClick={refreshPage} type="button" className="context-error-plug__button">
          {buttonText}
        </button>
      </p>
    </section>
  );
};

export default ContextErrorPlug;
