import { REQUEST } from 'actions/utils';
import { getStudentAverageGrades, getStudentFinalGrades, getStudentSubjectThemes } from 'api';
import { ApiResult } from 'api/impl';
import { call, fork, put, takeLatest } from 'redux-saga/effects';

import {
  GET_STUDENT_AVERAGE_GRADES,
  GET_STUDENT_FINAL_GRADES,
  GET_STUDENT_SUBJECT_THEMES,
  getStudentAverageGradesActions,
  getStudentFinalGradesActions,
  getStudentSubjectThemesActions,
} from './actions';

type CommonActionsObject = typeof getStudentFinalGradesActions;

export const collection = [watchGetStudentFinalGrades, watchGetStudentAverageGrades, watchGetStudentSubjectThemes].map(
  fork,
);

function* watchGetStudentFinalGrades() {
  yield takeLatest(
    GET_STUDENT_FINAL_GRADES[REQUEST],
    createSagaFunction(getStudentFinalGradesActions, getStudentFinalGrades),
  );
}

function* watchGetStudentAverageGrades() {
  yield takeLatest(
    GET_STUDENT_AVERAGE_GRADES[REQUEST],
    createSagaFunction(getStudentAverageGradesActions, getStudentAverageGrades),
  );
}

function* watchGetStudentSubjectThemes() {
  yield takeLatest(GET_STUDENT_SUBJECT_THEMES[REQUEST], getStudentSubjectThemesSaga);
}

function* getStudentSubjectThemesSaga({ payload }: ReturnType<typeof getStudentSubjectThemesActions.request>) {
  const { personId, subjectId, meta } = payload;
  const { response, error } = yield call(getStudentSubjectThemes, personId, subjectId, meta);

  if (response) {
    yield put(getStudentSubjectThemesActions.success(response));
  } else {
    yield put(getStudentSubjectThemesActions.failure(error));
  }
}

const createSagaFunction = (
  actionsObject: CommonActionsObject,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  apiMethod: (personId: string) => Promise<ApiResult<any, any>>,
) => {
  return function* sagaFunction({ payload }: ReturnType<typeof actionsObject.request>) {
    const personId = payload;
    const { response, error } = yield call(apiMethod, personId);

    if (response) {
      yield put(actionsObject.success(response));
    } else {
      yield put(actionsObject.failure(error));
    }
  };
};
