import { FC, PropsWithChildren } from 'react';

import { IconButton } from '@mui/material';
import { CarouselProvider } from 'pure-react-carousel';

import { IconChevronSmallLeft, IconChevronSmallRight } from '../../../../../icons';

import './index.scss';

interface IFeaturePreviewCarouselProps {
  totalSlides: number;
  currentSlide: number;
  onBack: () => void;
  onNext: () => void;
}

const FeaturePreviewCarousel: FC<IFeaturePreviewCarouselProps & PropsWithChildren> = ({
  totalSlides,
  currentSlide,
  onBack,
  onNext,
  children,
}) => {
  return (
    <CarouselProvider
      naturalSlideWidth={900}
      naturalSlideHeight={600}
      totalSlides={totalSlides}
      currentSlide={currentSlide}
      visibleSlides={1}
      dragEnabled={false}
      isIntrinsicHeight
    >
      {children}
      {totalSlides > 1 && (
        <>
          <IconButton
            disabled={currentSlide === 0}
            onClick={onBack}
            className="arrow arrow_left box-shadow_tokyo"
            size="large"
          >
            <IconChevronSmallLeft />
          </IconButton>
          <IconButton
            disabled={currentSlide === totalSlides - 1}
            onClick={onNext}
            className="arrow arrow_right box-shadow_tokyo"
            size="large"
          >
            <IconChevronSmallRight />
          </IconButton>
        </>
      )}
    </CarouselProvider>
  );
};

export default FeaturePreviewCarousel;
