import { SxStyles } from 'types';

export const drawerContent: SxStyles = (theme) => {
  return {
    padding: '20px 24px',

    [theme.breakpoints.down('commonSm')]: {
      padding: '12px 16px',
      paddingBottom: '16px',
    },
  };
};

export const cardList: SxStyles = {
  '& > *:not(:first-child)': {
    marginTop: '12px',
  },
};
