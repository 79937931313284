/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useId, useRef } from 'react';

import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { postAttachment } from 'api';
import { IAttachment } from 'api/types';
import { LocalDataEntryFormContext } from 'components/common/PortfolioDataEntryForm/context/localDataEntryFormContext';
import { FileErrorCode, GENERAL_ERROR, tooltipOptions } from 'const';
import { uniqueId } from 'lodash';
import { indigoColors, NeutralColors } from 'portfolio3/styles';
import { commonTheme } from 'portfolio3/styles/theme';
import { toast } from 'portfolio3/ui-kit';
import { Button } from 'portfolio3/ui-kit/button';
import { transliteString } from 'utils';

import { IFileController } from '../../types';

import './index.scss';

const MAX_FILE_LENGTH = 20000000;
const MAX_FILE_LENGTH_MB = MAX_FILE_LENGTH / Math.pow(1000, 2);
interface IFileDropProps {
  documentClass: string;
  fileController: IFileController;
  personId: string;
  setFileSubstrateVisible: React.Dispatch<React.SetStateAction<boolean>>;
  accept?: string;
  readableFileTypes?: string[];
}

const FileDrop: React.FC<IFileDropProps> = ({
  documentClass,
  fileController,
  personId,
  setFileSubstrateVisible,
  accept,
  readableFileTypes,
}) => {
  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'), { noSsr: true });
  const { studentType } = useContext(LocalDataEntryFormContext);

  const fileInputId = useId();
  const fileInput = useRef<HTMLInputElement>(null);
  const dropArea = useRef<any>(null);

  const handleSendFile = async (file: File) => {
    const localId = uniqueId();
    let fileName = file.name;

    if (/[А-Я-Ё]/gi.test(file.name) === true) {
      fileName = transliteString(file.name);
    }

    fileName = encodeURIComponent(fileName);

    if (!personId || !fileName) return;
    if (file.size > MAX_FILE_LENGTH) {
      toast.error(`Превышен максимально допустимый размер вложения (${MAX_FILE_LENGTH_MB}МБ)`, tooltipOptions);
      return;
    }
    // * 1 handleAddFileSchema(localId) - обработка локального файла (с моковыми данными сервера) (лежит в формдате)
    // * 2 handleFulfillSchema(localId, response) - дополняем файл резалтом с сервера
    // * 3 handleDeleteFile(localId)
    const formData = new FormData();
    formData.append('document', file);
    fileController.handleAddFileSchema(localId, file);

    const result = await postAttachment(formData, {
      DocumentTitle: fileName,
      documentClass,
      PersonId: personId,
    });

    if (result.response) {
      const { id, deleted, name } = result.response.data;
      const decodedName = decodeURIComponent(name);
      const newAttachment: IAttachment = { id, isDelete: deleted, name: decodedName };
      fileController.handleFulfillSchema(localId, newAttachment);
    } else if (result.error.code === FileErrorCode.format || result.error.code === FileErrorCode.size) {
      fileController.handleDeleteFile(localId);
      toast.error(result.error.message, tooltipOptions);
    } else {
      fileController.handleDeleteFile(localId);
      toast.error(GENERAL_ERROR, tooltipOptions);
    }
  };

  const handleFileSelected = (event: React.ChangeEvent<HTMLInputElement>): void => {
    let fileArray: File[] = [];
    if (event.target.files) fileArray = Array.from(event.target.files);
    fileArray.forEach((file) => {
      handleSendFile(file);
    });
  };

  const preventDefaults = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event: any) => {
    let fileArray: File[] = [];
    if (event.dataTransfer.files) fileArray = Array.from(event.dataTransfer.files);
    fileArray.forEach((file) => {
      handleSendFile(file);
    });
    setFileSubstrateVisible(false);
  };
  useEffect(() => {
    if (!dropArea.current) return;
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
      dropArea.current.addEventListener(eventName, preventDefaults, false);
    });
    dropArea.current.addEventListener('drop', handleDrop, false);
    dropArea.current.addEventListener('dragenter', () => {
      setFileSubstrateVisible(true);
    });
    dropArea.current.addEventListener('dragleave', () => {
      setFileSubstrateVisible(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropArea]);

  const mobileContent = (
    <Typography
      variant="Paragraph LG/Semi Bold"
      color={NeutralColors.light_neutrals_600}
      textAlign="center"
      padding="16px"
    >
      Добавить файл
    </Typography>
  );

  const desktopContentStudent = (
    <Stack gap="12px" alignItems="center" padding="20px">
      <Box>
        <Typography variant="Paragraph LG/Semi Bold" textAlign="center">
          Перетащите файл сюда, чтобы загрузить
        </Typography>
        <Stack marginTop="2px" alignItems="center">
          {accept && readableFileTypes && (
            <Typography variant="Paragraph MD/Regular" color={NeutralColors.light_neutrals_600}>
              Допустимые форматы: {readableFileTypes.join(', ').toUpperCase()}
            </Typography>
          )}
          <Typography variant="Paragraph MD/Regular" color={NeutralColors.light_neutrals_600}>
            Максимальный размер файла {MAX_FILE_LENGTH_MB} МБ
          </Typography>
        </Stack>
      </Box>
      <Button size="small" onClick={() => fileInput.current?.click()}>
        Выбрать файл
      </Button>
    </Stack>
  );

  const desktopContentOperator = (
    <Stack alignItems="center" padding="36px">
      <Typography variant="Paragraph LG/Regular" color={NeutralColors.light_neutrals_600}>
        Перетащите файлы сюда, чтобы загрузить
      </Typography>
      <Typography variant="Paragraph LG/Regular" color={NeutralColors.light_neutrals_600}>
        или{' '}
        <Typography component="span" variant="Paragraph LG/Semi Bold" color={indigoColors[200]}>
          нажмите, чтобы выбрать с компьютера
        </Typography>
      </Typography>
    </Stack>
  );

  return (
    <label htmlFor={fileInputId} className="file-drop" ref={dropArea}>
      {isMobile && mobileContent}
      {!isMobile && studentType && desktopContentStudent}
      {!isMobile && !studentType && desktopContentOperator}

      <input
        id={fileInputId}
        value=""
        className="file-drop__input"
        name="file-drop"
        type="file"
        multiple
        ref={fileInput}
        onChange={handleFileSelected}
        accept={accept}
      />
    </label>
  );
};

export default React.memo(FileDrop);
