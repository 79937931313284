import { FC } from 'react';

import { ThemeProvider, Typography } from '@mui/material';
import { AccentColorType, NeutralColors } from 'portfolio3/styles';
import { generateAccentTheme } from 'portfolio3/styles/theme';
import { Button } from 'portfolio3/ui-kit/button';

import RecommendationContainer from '../recommendationContainer';

import './index.scss';

interface IGroupRecommendationProps {
  title: string;
  description: string;
  backgroundIllustration: string;
  accentColor: AccentColorType;
  onView: () => void;
}

const GroupRecommendation: FC<IGroupRecommendationProps> = ({
  title,
  description,
  backgroundIllustration,
  accentColor,
  onView,
}) => {
  return (
    <ThemeProvider theme={generateAccentTheme(accentColor)}>
      <RecommendationContainer className="group-recommendation">
        <RecommendationContainer.Illustration illustration={backgroundIllustration} width={220} height={200} />

        <RecommendationContainer.Content>
          <Typography variant="Paragraph LG/Semi Bold">{title}</Typography>
          <Typography variant="Paragraph MD/Regular" color={NeutralColors.light_neutrals_600} marginTop="4px">
            {description}
          </Typography>
        </RecommendationContainer.Content>

        <RecommendationContainer.Footer>
          <Button onClick={onView} sx={{ width: '100%' }}>
            Посмотреть
          </Button>
        </RecommendationContainer.Footer>
      </RecommendationContainer>
    </ThemeProvider>
  );
};

export default GroupRecommendation;
