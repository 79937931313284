import { useCallback } from 'react';

import { getScrollableContainerElement } from 'utils';

import useHeaderHeight from './useHeaderHeight';

const useScrollToElement = () => {
  const headerHeight = useHeaderHeight();

  const scrollToElementGlobal = useCallback(
    (id: string, currentElement?: string) => {
      const scrollableContainer = getScrollableContainerElement();
      const element = document.getElementById(id);

      if (!element || !scrollableContainer || id === currentElement) return;

      element.scrollIntoView();

      // разница между высотой хедера и расстоянием между верхней границей виджета и краем экрана
      const elementTopY = element.getBoundingClientRect().top;
      const scrollDelta = headerHeight - elementTopY;
      window.scrollBy(0, -scrollDelta);
    },
    [headerHeight],
  );

  const scrollToElementLocal = useCallback((id: string) => {
    const element = document.getElementById(id);

    if (!element) return;

    element.scrollIntoView();
  }, []);

  return { scrollToElementGlobal, scrollToElementLocal };
};

export default useScrollToElement;
