import { FC, ReactNode } from 'react';

import { Box, Typography, useMediaQuery } from '@mui/material';
import { MoonLoader } from 'portfolio3/components/common/loaders';
import { commonTheme } from 'portfolio3/styles/theme';

import DefaultImagePlug from '../DefaultImagePlug';
import * as styles from './styles';

interface INoDataPlugProps {
  title: string;
  loading: boolean;
  imageElement?: ReactNode;
}

const NoDataPlug: FC<INoDataPlugProps> = ({ title, loading, imageElement = <DefaultImagePlug /> }) => {
  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  return (
    <Box className="no-data-plug" sx={styles.root}>
      {loading && <MoonLoader />}

      {!loading && (
        <>
          {imageElement}
          <Typography variant={isMobile ? 'Paragraph LG/Semi Bold' : 'Headings/H6'} sx={styles.title}>
            {title}
          </Typography>
        </>
      )}
    </Box>
  );
};

export default NoDataPlug;
