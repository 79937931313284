import { createSelector } from 'reselect';

import { IRootState } from '../../../reducers';
import { mapProfessionEventsResponse } from './mappers';

const professionEventsSelector = createSelector(
  [
    (state: IRootState) => state.studentEvents,
    (state: IRootState) => state.studentRewards,
    (state: IRootState) => state.sectionRef,
  ],
  (studentEvents, studentRewards, sectionRef) => {
    return mapProfessionEventsResponse(studentEvents, studentRewards, sectionRef);
  },
);

export default professionEventsSelector;
