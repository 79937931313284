import { FC } from 'react';

import { placeholderWatch } from 'images';

import CommonPlaceholder from './CommonPlaceholder';

const LoadingPlaceholder: FC = () => {
  return (
    <CommonPlaceholder image={placeholderWatch} alt="Загрузка" description="Ищем информацию по вашему запросу..." />
  );
};

export default LoadingPlaceholder;
