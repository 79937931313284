import React from 'react';

import { Box, Typography } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';

import FunnelBlock from '../../FunnelBlock';

import './index.scss';

const InformationError: React.FC = () => {
  return (
    /* eslint-disable prettier/prettier */
    <FunnelBlock className="information-error">
      <Typography variant="Paragraph LG/Semi Bold">
        Указанные данные не относятся ко мне
      </Typography>
      <Box sx={{ height: '1px', backgroundColor: NeutralColors.light_neutrals_300, marginBlock: '12px' }} />
      <Typography variant="Paragraph LG/Regular">
        После обработки вашего сообщения оператором, данные
        {' '}
        будут удалены из вашего портфолио. Если вы согласны, нажмите кнопку «Отправить».
      </Typography>
    </FunnelBlock>
  );
};

export default InformationError;
