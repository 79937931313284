import { IThemeSettings } from 'api/types';
import * as themes from 'images/themes';
import { getAccentColor, NeutralColors } from 'portfolio3/styles';

// theme.name
export enum ThemeCategoryKeys {
  STRICT = 'strict',
  PATTERN = 'pattern',
  BRIGHT = 'bright',
  SNOW = 'snow',
}

// theme.background
export enum ThemeBackgroundKeys {
  // однотон цвет
  NEUTRAL = 'neutral',
  INDIGO = 'indigo',
  GRAPE = 'grape',
  ORANGE = 'orange',
  GREEN = 'green',
  CYAN = 'cyan',

  // градиент цвет
  GRADIENT_COSMO = 'gradientCosmo',
  GRADIENT_PURPLE = 'gradientPurple',
  GRADIENT_GREEN = 'gradientGreen',
  GRADIENT_BLUE = 'gradientBlue',
  GRADIENT_VIOLET = 'gradientViolet',
  GRADIENT_YELLOW = 'gradientYellow',

  // отдельные фоны
  BRIGHT_BACKGROUND = 'brightBackground',
  SNOW_BACKGROUND = 'snowBackground',
}

export const ThemeBackgroundHoverValues: Record<ThemeBackgroundKeys, string> = {
  // однотон цвет
  [ThemeBackgroundKeys.NEUTRAL]: 'Серый',
  [ThemeBackgroundKeys.INDIGO]: 'Синий',
  [ThemeBackgroundKeys.GRAPE]: 'Фиолетовый',
  [ThemeBackgroundKeys.ORANGE]: 'Оранжевый',
  [ThemeBackgroundKeys.GREEN]: 'Зеленый',
  [ThemeBackgroundKeys.CYAN]: 'Голубой',

  // градиент цвет
  [ThemeBackgroundKeys.GRADIENT_COSMO]: 'Стиль',
  [ThemeBackgroundKeys.GRADIENT_PURPLE]: 'Рассвет',
  [ThemeBackgroundKeys.GRADIENT_GREEN]: 'Лето',
  [ThemeBackgroundKeys.GRADIENT_BLUE]: 'Сияние',
  [ThemeBackgroundKeys.GRADIENT_VIOLET]: 'Леденец',
  [ThemeBackgroundKeys.GRADIENT_YELLOW]: 'Закат',

  // отдельные фоны
  brightBackground: '',
  snowBackground: '',
};

// theme.pattern
export enum ThemePatternKeys {
  CATS1 = 'cats1',
  CATS2 = 'cats2',
  CATS3 = 'cats3',
  MEMES = 'memes',
  PLANETS = 'planets',
  SPACE = 'space',
  CACTI = 'cacti',
}

// Названия для паттернов при состоянии hover
export const ThemePatternHoverValues: Record<ThemePatternKeys, string> = {
  [ThemePatternKeys.CATS1]: 'МЭШик 1',
  [ThemePatternKeys.CATS2]: 'МЭШик 2',
  [ThemePatternKeys.CATS3]: 'КосмоКотики',
  [ThemePatternKeys.MEMES]: 'Мемы',
  [ThemePatternKeys.PLANETS]: 'Стиль',
  [ThemePatternKeys.SPACE]: 'Космос',
  [ThemePatternKeys.CACTI]: 'Кактусы',
};

// theme.controls
export enum ThemeControlKeys {
  // радужная кнопка (каждый раздел использует свой цвет)
  COLOR = 'color',
}

// theme.cover
export enum ThemeCoverKeys {
  ABSTRACT = 'abstract',
  ABSTRACT1 = 'abstract1',
  ABSTRACT2 = 'abstract2',
  BLUE_POINTS = 'bluePoints',
  CIAN = 'cian',
  COSMOS = 'cosmos',
  GREEN_POINTS = 'greenPoints',
  LINE_BLUE = 'linesBlue',
  LINE_PINK = 'linesPink',
  NEON = 'neon',
  ORANGE = 'orange',
  SEA = 'sea',
  WATERCOLOR = 'watercolor',
  WATERCOLOR2 = 'watercolor2',
  WATERCOLOR3 = 'watercolor3',

  // яркая тема
  BRIGHT_SCIENCE = 'brightScience',
  BRIGHT_ZOZH = 'brightZozh',
  BRIGHT_IT = 'brightIt',
  BRIGHT_CORGI = 'brightCorgi',
  BRIGHT_COSMOS = 'brightCosmos',
  BRIGHT_BUTTERFLY = 'brightButterfly',
  BRIGHT_UNICORN = 'brightUnicorn',

  // снежная тема
  SNOW_RED_HAIRED_GIRL = 'snowRedHairedGirl',
  SNOW_PRESENTS = 'snowPresents',
  SNOW_HOME = 'snowHome',
  SNOW_HOME2 = 'snowHome2',
}

export const ThemeCoverHoverValues: Record<ThemeCoverKeys, string> = {
  [ThemeCoverKeys.ABSTRACT]: 'Градиент',
  [ThemeCoverKeys.ABSTRACT1]: 'Абстракция 1',
  [ThemeCoverKeys.ABSTRACT2]: 'Абстракция 2',
  [ThemeCoverKeys.BLUE_POINTS]: 'Абстракция 3',
  [ThemeCoverKeys.CIAN]: 'Абстракция 4',
  [ThemeCoverKeys.COSMOS]: 'Космос',
  [ThemeCoverKeys.GREEN_POINTS]: 'Абстракция 5',
  [ThemeCoverKeys.LINE_BLUE]: 'Линии 1',
  [ThemeCoverKeys.LINE_PINK]: 'Линии 2',
  [ThemeCoverKeys.NEON]: 'Неон',
  [ThemeCoverKeys.ORANGE]: 'Абстракция 6',
  [ThemeCoverKeys.SEA]: 'Абстракция 7',
  [ThemeCoverKeys.WATERCOLOR]: 'Акварель 1',
  [ThemeCoverKeys.WATERCOLOR2]: 'Акварель 2',
  [ThemeCoverKeys.WATERCOLOR3]: 'Акварель 3',

  brightScience: 'Наука',
  brightZozh: 'ЗОЖ',
  brightIt: 'IT',
  brightCorgi: 'Корги',
  brightCosmos: 'Космос',
  brightButterfly: 'Бабочки',
  brightUnicorn: 'Единороги',

  snowRedHairedGirl: 'Лес',
  snowPresents: 'Подарки',
  snowHome: 'Синий дом',
  snowHome2: 'Красный дом',
};

export const ThemeBackgroundValues: Record<ThemeBackgroundKeys, string> = {
  [ThemeBackgroundKeys.NEUTRAL]: NeutralColors.light_neutrals_200,
  [ThemeBackgroundKeys.INDIGO]: getAccentColor('indigo', '24'),
  [ThemeBackgroundKeys.GRAPE]: getAccentColor('grape', '24'),
  [ThemeBackgroundKeys.ORANGE]: getAccentColor('orange', '24'),
  [ThemeBackgroundKeys.GREEN]: getAccentColor('green', '24'),
  [ThemeBackgroundKeys.CYAN]: getAccentColor('cyan', '24'),

  [ThemeBackgroundKeys.GRADIENT_COSMO]:
    'linear-gradient(157.45deg, rgba(31, 247, 253, 0.6) -14.38%,' +
    'rgba(179, 59, 246, 0.6) 42.31%, rgba(255, 132, 76, 0.6) 82.46%, rgba(255, 132, 75, 0.6) 101.14%)',
  [ThemeBackgroundKeys.GRADIENT_PURPLE]:
    'linear-gradient(173.7deg, rgba(255, 202, 166, 0.7) 11.86%,' +
    'rgba(252, 163, 159, 0.7) 62.78%, rgba(248, 101, 148, 0.7) 102.75%)',
  [ThemeBackgroundKeys.GRADIENT_GREEN]:
    'linear-gradient(345.37deg, rgba(110, 208, 251, 0.7) 6.81%, rgba(154, 238, 162, 0.7) 95.87%)',
  [ThemeBackgroundKeys.GRADIENT_BLUE]: 'linear-gradient(184.65deg, #2CD8D5 -24.53%, #C5C1FF 49.95%, #FFBAC3 120.97%)',
  [ThemeBackgroundKeys.GRADIENT_VIOLET]:
    'linear-gradient(110.48deg, rgba(176, 30, 255, 0.7) 0%, rgba(225, 70, 124, 0.7) 100%)',
  [ThemeBackgroundKeys.GRADIENT_YELLOW]:
    'linear-gradient(180deg, rgba(249, 212, 35, 0.7) 0%, rgba(225, 79, 173, 0.7) 100%)',

  brightBackground: themes.brightBackgroundOverlay,
  snowBackground: themes.snowBackgroundPreview,
};

export const ThemePatternValues: Record<ThemePatternKeys, string> = {
  [ThemePatternKeys.CATS1]: themes.cats1,
  [ThemePatternKeys.CATS2]: themes.cats2,
  [ThemePatternKeys.CATS3]: themes.cats3,
  [ThemePatternKeys.MEMES]: themes.memes,
  [ThemePatternKeys.PLANETS]: themes.planets,
  [ThemePatternKeys.SPACE]: themes.space,
  [ThemePatternKeys.CACTI]: themes.cacti,
};

export const ThemeCoverValues: Record<ThemeCoverKeys, string> = {
  [ThemeCoverKeys.ABSTRACT]: themes.abstract,
  [ThemeCoverKeys.ABSTRACT1]: themes.abstract1,
  [ThemeCoverKeys.ABSTRACT2]: themes.abstract2,
  [ThemeCoverKeys.BLUE_POINTS]: themes.bluePoints,
  [ThemeCoverKeys.CIAN]: themes.cian,
  [ThemeCoverKeys.COSMOS]: themes.cosmos,
  [ThemeCoverKeys.GREEN_POINTS]: themes.greenPoints,
  [ThemeCoverKeys.LINE_BLUE]: themes.linesBlue,
  [ThemeCoverKeys.LINE_PINK]: themes.linesPink,
  [ThemeCoverKeys.NEON]: themes.neon,
  [ThemeCoverKeys.ORANGE]: themes.orange,
  [ThemeCoverKeys.SEA]: themes.sea,
  [ThemeCoverKeys.WATERCOLOR]: themes.watercolor,
  [ThemeCoverKeys.WATERCOLOR2]: themes.watercolor2,
  [ThemeCoverKeys.WATERCOLOR3]: themes.watercolor3,

  brightScience: themes.brightScience,
  brightZozh: themes.brightZozh,
  brightIt: themes.brightIt,
  brightCorgi: themes.brightCorgi,
  brightCosmos: themes.brightComsoms,
  brightButterfly: themes.brightButterfly,
  brightUnicorn: themes.brightUnicorn,

  snowRedHairedGirl: themes.snowRedHairedGirl,
  snowPresents: themes.snowPresents,
  snowHome: themes.snowHome,
  snowHome2: themes.snowHome2,
};

export const defaultThemeSettings: IThemeSettings = {
  themeName: ThemeCategoryKeys.STRICT,
  background: ThemeBackgroundKeys.NEUTRAL,
  controls: ThemeControlKeys.COLOR,
  cover: ThemeCoverKeys.ABSTRACT,
};
