import { ElementRef, FC, ReactNode, RefObject, useEffect, useLayoutEffect, useRef } from 'react';
import { DateValue, RangeCalendarProps, useDateRangePicker } from 'react-aria';
import { DateRangePickerState, DateRangePickerStateOptions, useDateRangePickerState } from 'react-stately';

import { Box, useFormControl } from '@mui/material';
import { SxStyles } from 'types';

import { IDateInputHandle } from '../../localTypes';
import DateField from '../DateField';
import DateInputBox from '../DateInputBox';
import useCommonDatePickerState from '../hooks/useCommonDatePickerState';

const boxContentStyles: SxStyles = {
  display: 'flex',

  '& > *': {
    width: 'max-content',
  },
};

interface IDateRangePickerProps {
  options?: DateRangePickerStateOptions<DateValue>;
  onSetInitialStartDate: (date: DateValue | null) => void;
  children?: (
    triggerRef: RefObject<Element>,
    state: DateRangePickerState,
    calendarProps: RangeCalendarProps<DateValue>,
  ) => ReactNode;
  onClear?: () => void;
}

const DateRangePicker: FC<IDateRangePickerProps> = ({ options, onSetInitialStartDate, children, onClear }) => {
  const formControl = useFormControl();
  const isDisabled = formControl?.disabled || options?.isDisabled || false;

  const modifiedOptions = {
    ...options,
    isDisabled,
  };

  const state = useDateRangePickerState(modifiedOptions);
  const ref = useRef<ElementRef<'div'>>(null);
  const { groupProps, startFieldProps, endFieldProps, buttonProps, calendarProps } = useDateRangePicker(
    // label используется чтобы убрать ворнинг про aria-label
    { label: 'date range picker', ...modifiedOptions },
    state,
    ref,
  );

  const isMounted = useRef(false);
  const prevStartFieldValue = useRef(startFieldProps.value);
  const startDateInputHandle = useRef<IDateInputHandle>(null);
  const endDateInputHandle = useRef<IDateInputHandle>(null);

  const { isOpen, value } = state;

  const startSegments = startDateInputHandle.current?.segments ?? [];
  const endSegments = endDateInputHandle.current?.segments ?? [];
  const hasFilledSegments = [...startSegments, ...endSegments].some(
    (segment) => segment.value && !segment.isPlaceholder,
  );

  const { isInputHidden, setFocused } = useCommonDatePickerState(isOpen, hasFilledSegments);

  // Установка стейта startDate при ручном вводе в первое поле
  useLayoutEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    const startValue = startFieldProps.value;
    const isChanged = !prevStartFieldValue.current || startValue?.compare(prevStartFieldValue.current) !== 0;

    if (isChanged) {
      onSetInitialStartDate(startValue ?? null);
      prevStartFieldValue.current = startValue;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startFieldProps.value]);

  // При очистке одного из компонентов endDate весь стейт очищается,
  // данный хук сохраняет стейт startDate если он был установлен ранее
  useEffect(() => {
    const startValue = startFieldProps.value;
    const endValue = endFieldProps.value;

    if (startValue && !endValue) {
      onSetInitialStartDate(startValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endFieldProps.value]);

  const handleClear = () => {
    // требуется привести к any потому что setValue не может принимать null, при этом нормально работает с ними
    // если не установить значение напрямую, то при заполнении первой даты через инпут, он не будет очищен
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const localState = state as any;
    localState?.setValue?.({ start: null, end: null });

    onClear?.();
  };

  return (
    <Box className="date-range-picker">
      <Box className="date-range-picker__group" {...groupProps} ref={ref}>
        <DateInputBox
          isOpen={state.isOpen}
          isDisabled={isDisabled}
          calendarButtonProps={buttonProps}
          hasValue={Boolean(value.start) || Boolean(value.end)}
          onClear={handleClear}
        >
          <Box sx={boxContentStyles}>
            <DateField
              fieldOptions={{
                ...startFieldProps,
                onFocus: () => setFocused(true),
                onBlur: () => setFocused(false),
              }}
              isHidden={isInputHidden}
              handle={startDateInputHandle}
            />
            <Box
              component="span"
              sx={{ marginInline: '4px', opacity: isInputHidden ? 0 : 1, transition: 'opacity 0.1s' }}
              aria-hidden="true"
            >
              —
            </Box>
            <DateField
              fieldOptions={{
                ...endFieldProps,
                onFocus: () => setFocused(true),
                onBlur: () => setFocused(false),
              }}
              isHidden={isInputHidden}
              handle={endDateInputHandle}
            />
          </Box>
        </DateInputBox>
      </Box>
      {children && children(ref, state, calendarProps)}
    </Box>
  );
};

export default DateRangePicker;
