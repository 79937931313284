import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconCheckmarkCircleFilled: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="m16.295 9.605-4.568 6a1 1 0 0 1-.789.395h-.007c-.307 0-.597-.142-.787-.383l-2.432-3.108a1 1 0 1 1 1.575-1.232l1.633 2.087 3.784-4.97a1 1 0 0 1 1.591 1.211ZM12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconCheckmarkCircleFilled;
