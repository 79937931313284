/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';

import {
  GET_PDF_STUDENT_DIAGNOSIS_FULL
} from '../../actions';

import {
  FAILURE,
  REQUEST,
  SUCCESS
} from '../../actions/utils';

import { IDiagnosisResponse } from '../../api/types';

import genericReducers from '../genericReducer';

import { IReducers } from '..';

export type PdfStudentDiagnosisFullState = IDiagnosisResponse;

const initialState = {} as IDiagnosisResponse;

const pdfStudentDiagnosisFull: IReducers<PdfStudentDiagnosisFullState> = {
  [GET_PDF_STUDENT_DIAGNOSIS_FULL[REQUEST]]: genericReducers().get.request,
  [GET_PDF_STUDENT_DIAGNOSIS_FULL[SUCCESS]]: genericReducers().get.success,
  [GET_PDF_STUDENT_DIAGNOSIS_FULL[FAILURE]]: genericReducers().get.failure
};

export default connectReducers(initialState, pdfStudentDiagnosisFull);
