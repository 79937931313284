import { FC } from 'react';
import InputMask, { Props as InputMaskProps } from 'react-input-mask';

import { InputController } from '../../types';
import { UiKitInputBaseProps } from '../types';
import Input from './Input';

interface IMaskedInputProps extends UiKitInputBaseProps {
  controller: InputController;
  maskedInputProps: InputMaskProps;
}

const MaskedInput: FC<IMaskedInputProps> = ({ maskedInputProps, ...props }) => {
  return (
    <InputMask onChange={(e) => props.controller.handleChange(e.target.value)} {...maskedInputProps}>
      <Input {...props} />
    </InputMask>
  );
};

export default MaskedInput;
