import { useSelector } from 'react-redux';

import { IPortfolioEntryFormData } from 'portfolio3/features/dataEntryForm';
import { IRootState } from 'reducers';

interface IProps {
  formData: IPortfolioEntryFormData;
}

interface IResult {
  relatedObjectType?: string;
  relatedObjectName?: string;
}

/**
 * Хук возвращает тип и название связанного объекта (Мероприятие/Награда)
 */
const useRelatedObject = ({ formData }: IProps): IResult => {
  const personObjects = useSelector((state: IRootState) => state.personsObjects);

  let relatedType: string | undefined;
  let relatedName: string | undefined;

  // Связанное мероприятие в наградах
  if (formData.entityId) {
    const linkedEntityName = personObjects.content.find((object) => object.id === Number(formData.entityId))?.name;

    if (linkedEntityName) {
      relatedType = 'Мероприятие';
      relatedName = linkedEntityName;
    }
  }

  return {
    relatedObjectType: relatedType,
    relatedObjectName: relatedName,
  };
};

export default useRelatedObject;
