import { FC, PropsWithChildren, useContext, useEffect } from 'react';

import { Box } from '@mui/material';
import * as commonStyles from 'components/common/PortfolioDataEntryForm/commonStyles';
import { LocalDataEntryFormContext } from 'components/common/PortfolioDataEntryForm/context/localDataEntryFormContext';
import { DataEntryFormStep, DataEntryFormTab } from 'portfolio3/features/dataEntryForm';
import { drawerContentPadding } from 'portfolio3/ui-kit/utils';
import { SxStyles } from 'types';

import { findChildComponentByType, mergeSx } from '../../../../../../utils';
import * as styles from './styles';
import FilesTab, { IFilesTabProps } from './tabs/filesTab';
import GeneralTab from './tabs/generalTab';
import LinkedObjectsTab, { ILinkedObjectsTabProps } from './tabs/linkedObjectsTab';

interface ISecondaryBlockSubcomponents {
  GeneralTab: FC<PropsWithChildren>;
  FilesTab: FC<IFilesTabProps>;
  LinkedObjectsTab: FC<ILinkedObjectsTabProps>;
}

interface ITabWrapperProps {
  isMobile: boolean;
  sxArray?: SxStyles[];
}

const TabWrapper: FC<ITabWrapperProps & PropsWithChildren> = ({ isMobile, sxArray, children }) => {
  const sx = [];
  if (isMobile) sx.push(styles.secondaryBlockMobileContainer);
  if (sxArray) sx.push(...sxArray);

  if (sx.length > 0) {
    return <Box sx={mergeSx(...sx)}>{children}</Box>;
  }

  return <>{children}</>;
};

const SecondaryBlockContainer: FC<PropsWithChildren> & ISecondaryBlockSubcomponents = ({ children }) => {
  const { isMobile, formTab, onChangeFormStep, tabs } = useContext(LocalDataEntryFormContext);

  const generalTab = findChildComponentByType(children, SecondaryBlockContainer.GeneralTab);
  const filesTab = findChildComponentByType(children, SecondaryBlockContainer.FilesTab);
  const linkedObjectsTab = findChildComponentByType(children, SecondaryBlockContainer.LinkedObjectsTab);

  const isLinkedTab = formTab === DataEntryFormTab.LINKED;

  useEffect(() => {
    onChangeFormStep(DataEntryFormStep.SECONDARY);
    return () => onChangeFormStep(DataEntryFormStep.PRIMARY);
  }, [onChangeFormStep]);

  const isMobileTopPaddingDisabled = tabs.length === 0;

  return (
    <Box
      className="secondary-block-container"
      sx={mergeSx(
        drawerContentPadding(isMobileTopPaddingDisabled),
        isLinkedTab ? styles.secondaryBlockLinkedTab : null,
      )}
    >
      {formTab === DataEntryFormTab.GENERAL && (
        <TabWrapper isMobile={isMobile} sxArray={[commonStyles.formControlsColumn]}>
          {generalTab}
        </TabWrapper>
      )}
      {formTab === DataEntryFormTab.FILES && <TabWrapper isMobile={isMobile}>{filesTab}</TabWrapper>}
      {formTab === DataEntryFormTab.LINKED && <TabWrapper isMobile={isMobile}>{linkedObjectsTab}</TabWrapper>}
    </Box>
  );
};

SecondaryBlockContainer.GeneralTab = GeneralTab;
SecondaryBlockContainer.FilesTab = FilesTab;
SecondaryBlockContainer.LinkedObjectsTab = LinkedObjectsTab;

export default SecondaryBlockContainer;
