/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { IRootState } from '../../../../reducers';
import { useQueryParams } from '../../../../hooks';
import { ViewedFunctionCodes, ViewedFunctionKeys } from '../../../../const';
import { ILinkMode } from '../../../../api/types';

import { IndependentDiagnosticsPreviewEffect } from '../IndependentDiagnostics';

interface IFeaturePreviewEffectsProps {
  linkMode: ILinkMode;
}

const FeaturePreviewEffects: React.FC<IFeaturePreviewEffectsProps> = ({
  linkMode
}) => {
  const history = useHistory();
  const query = useQueryParams();
  const [viewedFunctionCode, setViewedFunctionCode] = useState<number | null>(null);

  const functionCode = Number(query.get('function'));

  useEffect(() => {
    if (functionCode === 0) return;

    setViewedFunctionCode(functionCode);

    history.replace({
      pathname: history.location.pathname,
      search: ''
    });
  }, [functionCode]);

  if (linkMode.mode) return null;

  switch (viewedFunctionCode) {
    // case ViewedFunctionCodes[ViewedFunctionKeys.INDEPENDENT_DIAGNOSTICS]:
    // case ViewedFunctionCodes[ViewedFunctionKeys.INDEPENDENT_DIAGNOSTICS_DATA_UPDATE]: {
    //   return <IndependentDiagnosticsPreviewEffect />
    // }
    default: return null;
  }
}

export default connect(
  (state: IRootState) => ({
    linkMode: state.linkMode,
  })
)(FeaturePreviewEffects);
