/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconChevronLargeDown: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M12.012 18c-.28 0-.537-.116-.747-.3l-6.95-6.91a1.026 1.026 0 0 1 0-1.478 1.049 1.049 0 0 1 1.493 0l6.204 6.193 6.18-6.17a1.049 1.049 0 0 1 1.493 0c.42.416.42 1.063 0 1.479l-6.927 6.909c-.187.185-.466.277-.746.277Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconChevronLargeDown;
