import { SxStyles } from 'types';

export const root: SxStyles = {
  display: 'flex',
  gap: '16px',
};

export const emailControl: SxStyles = {
  maxWidth: '260px',
};

export const parallelControl: SxStyles = {
  maxWidth: '300px',
};
