import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconArrowLeft: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M19 11H7.135l3.633-4.36a1 1 0 1 0-1.536-1.28l-5 6c-.039.047-.059.102-.088.154-.024.042-.053.078-.071.124a.985.985 0 0 0-.072.358L4 12l.001.004c0 .122.027.243.072.358.018.046.047.082.071.124.029.052.049.107.088.154l5 6a.999.999 0 1 0 1.536-1.28L7.135 13H19a1 1 0 0 0 0-2Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconArrowLeft;
