import React, { useCallback, useMemo, useState } from 'react';

import { Box, Button, Stack, Typography, useMediaQuery } from '@mui/material';
import { AccentColorType, getAccentColor, NeutralColors } from 'portfolio3/styles';
import { commonTheme } from 'portfolio3/styles/theme';

import DiagramCard from '../DiagramCard';
import DynamicSubjectChart from '../DynamicSubjectChart';
import { IDiagnosticChartData } from '../DynamicSubjectChart/types';
import * as styles from './styles';
import SubjectList from './SubjectList';
import { IChartSubject } from './types';

const lineColors: AccentColorType[] = ['grape', 'violet', 'cyan', 'teal', 'lime'];

interface IResultDynamicsProps {
  subjects: string[];
  results: IDiagnosticChartData[];
}

const ResultDynamics: React.FC<IResultDynamicsProps> = ({ subjects, results }) => {
  const [activeSubjects, setActiveSubjects] = useState(subjects);
  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));
  const labelsVariant = isMobile ? 'Paragraph MD/Semi Bold' : 'Paragraph LG/Semi Bold';

  const handleResetFilters = useCallback(() => {
    setActiveSubjects([]);
  }, []);
  const handleChangeFilter = useCallback(
    (value: string) => () => {
      setActiveSubjects((prev) =>
        !prev.includes(value) ? [...prev, value] : prev.filter((subject) => subject !== value),
      );
    },
    [],
  );

  const filteredChartData = results.filter((result) => {
    return activeSubjects.includes(result.name);
  });

  const chartSubjects: IChartSubject[] = useMemo(() => {
    const availableLineColors = [...lineColors];

    return subjects.map((subject): IChartSubject => {
      const isChecked = activeSubjects.includes(subject);
      const color = isChecked ? availableLineColors.shift() : null;
      return {
        name: subject,
        color: color ?? 'blue',
        isChecked,
      };
    });
  }, [activeSubjects, subjects]);

  const chartLineColors = chartSubjects
    .filter((subject) => subject.isChecked)
    .map((subject) => {
      return getAccentColor(subject.color, '100');
    });

  return (
    <DiagramCard title="Динамика результатов по предметам">
      <Typography sx={{ marginTop: '4px' }} variant="Paragraph MD/Regular" color={NeutralColors.light_neutrals_600}>
        Все учебные года
      </Typography>

      <Box sx={styles.contentWrapperStyles} className="result-dynamics">
        {/* график */}
        <DynamicSubjectChart
          chartData={filteredChartData}
          lineColors={chartLineColors}
          verticalLegendSpacing={isMobile ? 32 : 50}
          chartHeight={isMobile ? 186 : 240}
        />

        {/* список предметов */}
        <Stack sx={styles.actionsStyles} className="result-dynamics__subjects">
          <Stack justifyContent="space-between" direction="row" gap={1.5}>
            <Typography variant={labelsVariant}>Предметы</Typography>
            <Button
              variant="text"
              sx={styles.clearAllStyles}
              disabled={!activeSubjects.length}
              onClick={handleResetFilters}
            >
              <Typography variant={labelsVariant} color={NeutralColors.light_neutrals_600}>
                Сбросить все
              </Typography>
            </Button>
          </Stack>

          <SubjectList
            isMobile={isMobile}
            chartSubjects={chartSubjects}
            checkedSubjects={activeSubjects}
            onChange={handleChangeFilter}
          />
        </Stack>
      </Box>
    </DiagramCard>
  );
};

export default ResultDynamics;
