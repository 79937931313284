/* eslint-disable max-len */
import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconScienceProjectsCommon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.101 21.2c0 1.5-1.3 2.8-2.8 2.8h-2.6c-1.4 0-2.8-1.1-2.8-3.1v-1.6h8.2v1.9Zm1-6.4c-.5.4-.9 1-1 1.5l-.1.8h-8l-.1-.8c-.1-.6-.4-1.1-.8-1.4-2.6-2-3.8-5.2-3.1-8.4.7-3.2 3.2-5.7 6.3-6.4 2.5-.5 5 .1 7 1.7 1.9 1.6 3.1 3.9 3.1 6.4-.1 2.6-1.3 5-3.3 6.6Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconScienceProjectsCommon;
