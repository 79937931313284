import { FC } from 'react';

import { Typography } from '@mui/material';
import { getAccentColor } from 'portfolio3/styles';
import { getButtonStyles } from 'portfolio3/ui-kit/utils';
import { SxStyles } from 'types';

const styles: SxStyles = {
  ...getButtonStyles(),
  color: getAccentColor('indigo', '100'),
};

interface ITodayButtonProps {
  onSetToday: () => void;
}

const TodayButton: FC<ITodayButtonProps> = ({ onSetToday }) => {
  const today = new Date();
  const date = Intl.DateTimeFormat('ru-RU', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(today);
  const formattedDate = `Сегодня: ${date}`;

  return (
    <Typography variant="Paragraph MD/Medium" onClick={onSetToday} sx={styles}>
      {formattedDate}
    </Typography>
  );
};

export default TodayButton;
