import { ComponentType, FC, PropsWithChildren } from 'react';

import { ThemeBackgroundContainer } from 'portfolio3/containers/theme';

const withThemeBackground = () => {
  return <P extends PropsWithChildren<unknown>>(WrappedComponent: ComponentType<P>): FC<P> => {
    return function WidthThemeBackgroundContainer({ ...props }) {
      return (
        <ThemeBackgroundContainer>
          <WrappedComponent {...props} />
        </ThemeBackgroundContainer>
      );
    };
  };
};

export default withThemeBackground;
