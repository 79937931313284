import { FC, useContext } from 'react';

import { Box, Typography } from '@mui/material';
import { IDictionaryItem } from 'api/types';
import { PortfolioSettingsContext } from 'context';
import { useAppSelector, useDisclosure } from 'hooks';
import { IconChevronSmallDown, IconChevronSmallUp } from 'icons';
import { Button } from 'portfolio3/ui-kit/button';

import { VuzRecommendationTab } from '../../const';
import { VuzLocalFiltersContext, VuzRecommendationContext } from '../../context';
import { useFilterControls } from '../../hooks';
import { isFiltersChanged, isInitialFiltersChanged } from '../../utils';
import * as styles from './styles';

interface IDesktopFiltersProps {
  subjectOptions: IDictionaryItem[];
  specialityOptions: IDictionaryItem[];

  onReset: () => void;
}

const DesktopFilters: FC<IDesktopFiltersProps> = ({ subjectOptions, specialityOptions, onReset }) => {
  const vuzListLoading = useAppSelector((state) => state.vuzList.loading);

  const { currentTab, serverFilters, computedInitialServerFilters, clientFilters } =
    useContext(VuzRecommendationContext);
  const { localFilters, onApplyFilters } = useContext(VuzLocalFiltersContext);
  const { isSettingsMode } = useContext(PortfolioSettingsContext);
  const { isOpen: isAdditionalFiltersOpen, onToggle: toggleAdditionalFilters } = useDisclosure();

  const {
    searchControl,
    subjectsControl,
    specialitiesControl,
    trainingTypeControl,
    militaryTrainingControl,
    higherBallToggleControl,
    passingBallControl,
  } = useFilterControls({ subjectOptions, specialityOptions });

  const isInitialFiltersFormChanged = isInitialFiltersChanged(localFilters, computedInitialServerFilters);
  const isFiltersFormChanged = isFiltersChanged(
    localFilters,
    serverFilters,
    computedInitialServerFilters,
    clientFilters,
  );
  const isFiltersFormResetDisabled = !isInitialFiltersFormChanged;
  const isFiltersFormSearchDisabled = !isFiltersFormChanged;

  const handleApplyFilters = () => {
    onApplyFilters?.(localFilters);
  };

  const isGiaType = [VuzRecommendationTab.REAL_GIA, VuzRecommendationTab.TRIAL_GIA].includes(currentTab);

  const giaBottomBlockControls = (
    <>
      <Box className="gia-bottom-controls" sx={styles.giaBottomLayout}>
        {trainingTypeControl}
        {militaryTrainingControl}
      </Box>
      {higherBallToggleControl}
    </>
  );

  const subjectsBottomBlockControls = (
    <Box className="subjects-bottom-controls" sx={styles.subjectsBottomLayout}>
      {trainingTypeControl}
      {militaryTrainingControl}
      {passingBallControl}
    </Box>
  );

  return (
    <Box className="filters" sx={styles.root}>
      <Typography variant="Paragraph LG/Semi Bold">Фильтры</Typography>

      {searchControl}
      {subjectsControl}

      {isAdditionalFiltersOpen && (
        <>
          {specialitiesControl}
          {isGiaType ? giaBottomBlockControls : subjectsBottomBlockControls}
        </>
      )}

      <Box className="filters__buttons" sx={styles.buttonsContainer}>
        <Button
          variant="tetriary"
          iconRight={isAdditionalFiltersOpen ? <IconChevronSmallUp /> : <IconChevronSmallDown />}
          onClick={toggleAdditionalFilters}
          disabled={isSettingsMode}
        >
          {isAdditionalFiltersOpen ? 'Свернуть фильтры' : 'Еще фильтры'}
        </Button>
        <Box sx={{ display: 'flex', gap: '16px' }}>
          <Button variant="secondary" onClick={onReset} disabled={isSettingsMode || isFiltersFormResetDisabled}>
            Сбросить все
          </Button>
          <Button
            variant="primary"
            onClick={handleApplyFilters}
            disabled={vuzListLoading || isSettingsMode || isFiltersFormSearchDisabled}
          >
            Начать поиск
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default DesktopFilters;
