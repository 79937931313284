import { IRootState } from 'reducers';
import { createSelector } from 'reselect';

import { filterSpecialityByMinBallExistance, getMappedVuzList } from './utils';

const vuzListSelector = createSelector([(state: IRootState) => state.vuzList], (vuzList) => {
  const filteredVuzList = getMappedVuzList({
    vuzList: vuzList.content.items,
    specialityFilters: [filterSpecialityByMinBallExistance],
  });

  return filteredVuzList;
});

export default vuzListSelector;
