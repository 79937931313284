import { FC } from 'react';

import { Box } from '@mui/material';
import FixedWidthContainer from 'portfolio3/components/common/FixedWidthContainer';
import { GlobalFooter } from 'portfolio3/components/common/footers';
import PaddingContainer from 'portfolio3/components/common/PaddingContainer';
import { NeutralColors } from 'portfolio3/styles';

import { IFooterContainerCommonProps } from '../types';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IGlobalFooterContainerProps extends IFooterContainerCommonProps {}

const GlobalFooterContainer: FC<IGlobalFooterContainerProps> = ({ isFullWidth }) => {
  const contentElement = (
    <PaddingContainer>
      <GlobalFooter />
    </PaddingContainer>
  );

  return (
    <Box borderTop={`1px solid ${NeutralColors.light_neutrals_400}`}>
      {isFullWidth && contentElement}
      {!isFullWidth && <FixedWidthContainer withPadding={false}>{contentElement}</FixedWidthContainer>}
    </Box>
  );
};

export default GlobalFooterContainer;
