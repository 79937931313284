import { FC } from 'react';

import { InputAdornment } from '@mui/material';
import { IconSearch } from 'icons/internetAndCode';

import { InputController } from '../../types';
import InputBase from '../bases/InputBase';
import { UiKitInputBaseProps } from '../types';
import { getBaseInputProps } from '../utils';

interface IInputProps extends UiKitInputBaseProps {
  controller: InputController;
}

const SearchInput: FC<IInputProps> = ({ controller, value, ...props }) => {
  return (
    <InputBase
      {...getBaseInputProps(controller)}
      {...props}
      value={value ?? ''}
      startAdornment={
        <InputAdornment position="start">
          <IconSearch />
        </InputAdornment>
      }
    />
  );
};

export default SearchInput;
