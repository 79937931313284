import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Fade } from '@mui/material';
import { saveUserThemeSettingsActions, setUserThemeSettingsAction } from 'actions';
import { IThemeSettings } from 'api/types';
import {
  ThemeBackgroundHoverValues,
  ThemeBackgroundKeys,
  ThemeCategoryKeys,
  ThemeCoverHoverValues,
  ThemeCoverKeys,
  ThemePatternHoverValues,
  ThemePatternKeys,
  ViewedFunctionCodes,
} from 'const';
import { useAppSelector, useViewedFunctionCode } from 'hooks';
import { IThemeCategory } from 'types';
import { getBackgroundValue, getCoverValue, getPatternValue } from 'utils';

import { brightCategory, patternCategory, snowCategory, strictCategory } from '../const';
import { brightThemePreview, snowThemePreview } from '../images';
import ThemeAlert from '../ThemeAlert';
import ThemeBgColorPreview from '../ThemeBgColorPreview';
import ThemeBgImagePreview from '../ThemeBgImagePreview';
import ThemeCoverPreview from '../ThemeCoverPreview';
import ThemePatternPreview from '../ThemePatternPreview';
import ThemePreview from '../ThemePreview';
import AvatarSection from './AvatarSection';
import PanelHeader from './PanelHeader';
import PanelSection from './PanelSection';
import * as styles from './styles';

interface IThemePanelProps {
  isOpen: boolean;
  onClose: () => void;
}

const getInitialPalette = (categoryKey: string) => {
  switch (categoryKey) {
    case ThemeCategoryKeys.STRICT:
      return strictCategory;
    case ThemeCategoryKeys.PATTERN:
      return patternCategory;
    case ThemeCategoryKeys.BRIGHT:
      return brightCategory;
    case ThemeCategoryKeys.SNOW:
      return snowCategory;
    default:
      return strictCategory;
  }
};

const ThemePanel: React.FC<IThemePanelProps> = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const currentStudent = useAppSelector((state) => state.currentStudent);
  const themeSettings = useAppSelector((state) => state.userThemeSettings);

  const { isReady: isThemeNotificationReady, viewFunction: viewThemeNotification } = useViewedFunctionCode(
    ViewedFunctionCodes.themePanelNotification,
  );

  const {
    themeName: themeCategory,
    background: themeBackground,
    cover: themeCover,
    themePattern,
  } = themeSettings.settings;

  const [currentPalette, setCurrentPalette] = useState<IThemeCategory>(getInitialPalette(themeCategory));

  const handleChangeThemeCategory = (palette: IThemeCategory) => () => {
    dispatch(setUserThemeSettingsAction(getInitialSettings(palette)));
    setCurrentPalette(palette);
  };
  const handleChangeBackground = (background: ThemeBackgroundKeys) => () => {
    dispatch(setUserThemeSettingsAction({ background }));
  };
  const handleChangePattern = (pattern: ThemePatternKeys) => () => {
    dispatch(setUserThemeSettingsAction({ themePattern: pattern }));
  };
  const handleChangeCover = (cover: ThemeCoverKeys) => () => {
    dispatch(setUserThemeSettingsAction({ cover }));
  };

  const getInitialSettings = (palette: IThemeCategory): IThemeSettings => ({
    themeName: palette.name,
    background: palette.background[0],
    themePattern: palette.pattern ? palette.pattern[0] : undefined,
    controls: palette.controls ? palette.controls[0] : '',
    cover: palette.cover[0],
  });

  const handleClose = () => {
    dispatch(saveUserThemeSettingsActions.request(currentStudent.meshId, themeSettings.settings));
    onClose();
  };

  const categoryBlocks = (
    <>
      <ThemePreview
        type="category"
        isActive={themeCategory === ThemeCategoryKeys.STRICT}
        name="Строгая"
        onClick={handleChangeThemeCategory(strictCategory)}
      >
        <ThemeBgColorPreview themeColor={getBackgroundValue(ThemeBackgroundKeys.NEUTRAL)} />
      </ThemePreview>
      <ThemePreview
        type="category"
        isActive={themeCategory === ThemeCategoryKeys.PATTERN}
        name="Стильная"
        onClick={handleChangeThemeCategory(patternCategory)}
      >
        <ThemePatternPreview
          color={getBackgroundValue(ThemeBackgroundKeys.GRADIENT_PURPLE)}
          pattern={getPatternValue(ThemePatternKeys.CATS3)}
        />
      </ThemePreview>
      <ThemePreview
        type="category"
        isActive={themeCategory === ThemeCategoryKeys.BRIGHT}
        name="Яркая"
        onClick={handleChangeThemeCategory(brightCategory)}
      >
        <ThemeBgImagePreview img={brightThemePreview} />
      </ThemePreview>
      <ThemePreview
        type="category"
        isActive={themeCategory === ThemeCategoryKeys.SNOW}
        name="Зимняя"
        onClick={handleChangeThemeCategory(snowCategory)}
      >
        <ThemeBgImagePreview img={snowThemePreview} />
      </ThemePreview>
    </>
  );

  const backgroundBlocks = (
    <>
      {currentPalette.background.map((background) => {
        const isImageBackground = [ThemeBackgroundKeys.BRIGHT_BACKGROUND, ThemeBackgroundKeys.SNOW_BACKGROUND].includes(
          background,
        );
        const backgroundValue = getBackgroundValue(background);

        return (
          <ThemePreview
            key={background}
            type="setting"
            isActive={themeBackground === background}
            name={ThemeBackgroundHoverValues[background]}
            onClick={handleChangeBackground(background)}
          >
            {isImageBackground ? (
              <ThemeBgImagePreview img={backgroundValue} />
            ) : (
              <ThemeBgColorPreview themeColor={backgroundValue} />
            )}
          </ThemePreview>
        );
      })}
    </>
  );

  const patternBlocks = currentPalette.pattern && (
    <>
      {currentPalette.pattern.map((pattern) => (
        <ThemePreview
          key={Math.random()}
          type="setting"
          isActive={themePattern === pattern}
          name={ThemePatternHoverValues[pattern]}
          onClick={handleChangePattern(pattern)}
        >
          <ThemePatternPreview color={getBackgroundValue(themeBackground)} pattern={getPatternValue(pattern)} />
        </ThemePreview>
      ))}
    </>
  );

  const coverBlocks = (
    <>
      {currentPalette.cover.map((img) => (
        <ThemePreview
          key={Math.random()}
          type="setting"
          isActive={themeCover === img}
          name={ThemeCoverHoverValues[img]}
          onClick={handleChangeCover(img)}
        >
          <ThemeCoverPreview img={getCoverValue(img)} />
        </ThemePreview>
      ))}
    </>
  );

  return (
    <Fade in={isOpen} timeout={600} className="theme-panel-fade">
      <Box className="theme-panel" sx={styles.root}>
        <PanelHeader onClose={handleClose} />
        <Box className="theme-panel__body" sx={styles.body}>
          <AvatarSection />
          <PanelSection title="Тема" titleVariant="Headings/H6">
            <Box sx={styles.settingsGrid}>{categoryBlocks}</Box>
          </PanelSection>
          <PanelSection title="Фон" titleVariant="Paragraph MD/Medium">
            <Box sx={styles.settingsGrid}>{backgroundBlocks}</Box>
          </PanelSection>
          {patternBlocks && (
            <PanelSection title="Паттерн" titleVariant="Paragraph MD/Medium">
              <Box sx={styles.settingsGrid}>{patternBlocks}</Box>
            </PanelSection>
          )}
          <PanelSection title="Обложка портфолио" titleVariant="Paragraph MD/Medium">
            <Box sx={styles.settingsGrid}>{coverBlocks}</Box>
          </PanelSection>
        </Box>
        {isThemeNotificationReady && (
          <Box className="theme-panel__alert" sx={styles.alert}>
            <ThemeAlert onClose={viewThemeNotification} />
          </Box>
        )}
      </Box>
    </Fade>
  );
};

export default ThemePanel;
