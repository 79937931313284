import { styled } from '@mui/material';
import { getAccentColor, NeutralColors } from 'portfolio3/styles';

import StyledBaseButton from '../bases/BaseButton.styled';

const OutlineButton = styled(StyledBaseButton)(({ theme }) => {
  const accentColor = theme.palette.accentColor;
  const color_100 = getAccentColor(accentColor, '100');
  const color_48 = getAccentColor(accentColor, '48');
  const color_200 = getAccentColor(accentColor, '200');

  return {
    border: '1px solid',
    borderColor: color_100,
    color: color_200,
    backgroundColor: NeutralColors.light_neutrals_0,

    '&:hover': {
      color: NeutralColors.light_neutrals_0,
      backgroundColor: color_100,
    },

    '&:active': {
      color: NeutralColors.light_neutrals_0,
      backgroundColor: color_200,
    },

    '&:disabled': {
      color: color_48,
      borderColor: color_48,
      backgroundColor: NeutralColors.light_neutrals_0,
    },
  };
});

export default OutlineButton;
