/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useState } from 'react';

import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';

import {
  Tab as TabMaterial,
  Tabs as TabsMaterial
} from '@mui/material';
import { Colors } from '../../style/variables';

interface ITabProps {
    label: string;
}
interface ITabsProps {
    tabArray: ITabProps[];
}

const Tabs = withStyles({
  indicator: {
    height: 4,
    backgroundColor: Colors['blue-primrose']
  }
})(TabsMaterial);

const Tab = withStyles(() => createStyles({
  root: {
    minWidth: 'fit-content',
    padding: '24px 0',
    margin: '0 15px',
    textTransform: 'none',
    color: Colors['main-text'],
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    '&$selected': {
      color: Colors['blue-primrose'],
      fontWeight: 700,
      lineHeight: '20px'
    }
  },
  selected: {}
}))((props: ITabProps) => <TabMaterial disableRipple {...props} />);

const TabsComponent: React.FC<ITabsProps> = (props) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Tabs value={value} onChange={handleChange}>
      {props.tabArray.map((prop: ITabProps) => <Tab label={prop.label} />)}
    </Tabs>
  );
};

export default TabsComponent;
