import { getAccentColor } from 'portfolio3/styles';
import { InputRenderMode } from 'portfolio3/ui-kit/types';
import { getButtonStyles } from 'portfolio3/ui-kit/utils';
import { SxStyles } from 'types';

export const rootStyles = (inputRenderMode: InputRenderMode): SxStyles => ({
  display: 'flex',
  flexDirection: 'column',
  gap: inputRenderMode === 'floating' ? '4px' : '8px',
  width: '100%',
});

export const addButtonStyles: SxStyles = {
  ...getButtonStyles(),

  width: 'max-content',
  color: getAccentColor('indigo', '100'),
};
