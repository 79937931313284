import { SxStyles } from 'types';
import { numberToPixelsString } from 'utils';

import { innerPaddingDesktop, innerPaddingMobile } from './../../../const';

export const titleContainer: SxStyles = (theme) => ({
  marginTop: '24px',

  [theme.breakpoints.down('commonSm')]: {
    marginTop: '16px',
  },
});

export const title: SxStyles = (theme) => ({
  paddingLeft: numberToPixelsString(innerPaddingDesktop),

  letterSpacing: '0.75px',
  textTransform: 'uppercase',

  [theme.breakpoints.down('commonSm')]: {
    paddingLeft: numberToPixelsString(innerPaddingMobile),
  },
});
