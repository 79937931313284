/* eslint-disable max-len */
import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconCultureOnlineEventsCommon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5.698 8.7c1.1 0 1.9-.8 1.9-1.9 0-1.1-.8-1.9-1.9-1.9-1.1 0-1.8.8-1.8 1.9 0 1.1.8 1.9 1.8 1.9Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M0 1v17h10.7v2.5H8.2V23h7.5v-2.5h-2.5V18H24V1H0Zm21.4 10.2-3.6-3.5-5 4.2-4-2.6-.8.5c-1.1.9-2 1.7-3 2.4-.8.7-1.7 1.2-2.5 1.9V3.5h18.9v7.7Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconCultureOnlineEventsCommon;
