import { FC, useState } from 'react';

import { Box } from '@mui/material';
import { IconChevronSmallDown, IconFileTextOutline, IconUpload } from 'icons';
import { EmployeeSubheader } from 'portfolio3/components/common/headers';
import PaddingContainer from 'portfolio3/components/common/PaddingContainer';
import WithActionsPopup from 'portfolio3/components/common/WithActionsPopup';
import { IEntityAction } from 'portfolio3/components/entityActions';
import {
  ExcelImporter,
  SearchResultListContainer,
  SearchResultStudentContainer,
  StudentSearcher,
} from 'portfolio3/features/employee';
import { Button } from 'portfolio3/ui-kit/button';

import { useAppSelector, useUserFunctionality } from '../../hooks';
import { isRegionalUser } from '../../utils';

const StudentsRegister: FC = () => {
  const personsList = useAppSelector((state) => state.personsList);

  const { isOperator, isEmployee } = useUserFunctionality();

  const [isSampleDialogOpen, setSampleDialogOpen] = useState(false);
  const [isImportDialogOpen, setImportDialogOpen] = useState(false);

  const isRegional = isRegionalUser();
  const hasImportFunctionality = !isRegional && (isOperator || isEmployee);

  const handleSampleDialogOpen = (isOpen: boolean) => setSampleDialogOpen(isOpen);
  const handleImportDialogOpen = (isOpen: boolean) => setImportDialogOpen(isOpen);

  const importActions: IEntityAction[] = [
    {
      text: 'Импортировать данные из файла',
      icon: IconUpload,
      effect: () => handleImportDialogOpen(true),
    },
    {
      text: 'Скачать шаблон для импорта',
      icon: IconFileTextOutline,
      effect: () => handleSampleDialogOpen(true),
    },
  ];

  const importButtonElement = (
    <WithActionsPopup actions={importActions}>
      {(ref, onClick) => (
        <Button ref={ref} onClick={onClick} iconRight={<IconChevronSmallDown />} size="small" variant="secondary">
          Импортировать
        </Button>
      )}
    </WithActionsPopup>
  );

  const isLoading = personsList.loading;
  const isCached = personsList.requestCache === 'cached';
  const isResultsShown = !isLoading && isCached;

  return (
    <>
      <div className="employee-page">
        <EmployeeSubheader
          title="Поиск портфолио учащихся"
          rightElement={hasImportFunctionality && importButtonElement}
        />
        {hasImportFunctionality && (
          <ExcelImporter
            isSampleDialogOpen={isSampleDialogOpen}
            isImportDialogOpen={isImportDialogOpen}
            onSampleDialogOpen={handleSampleDialogOpen}
            onImportDialogOpen={handleImportDialogOpen}
          />
        )}
        <PaddingContainer sx={{ marginTop: '24px' }}>
          <StudentSearcher />

          {isResultsShown && (
            <Box marginTop="32px">
              {isEmployee && <SearchResultListContainer personsList={personsList} />}
              {!isEmployee && <SearchResultStudentContainer personsList={personsList} />}
            </Box>
          )}
        </PaddingContainer>
      </div>
    </>
  );
};

export default StudentsRegister;
