/* eslint-disable prettier/prettier */
/* eslint-disable */

import { isCrios } from './browsers';

export const transliteString = (str: string) => {
  const ru: any = {
    а: 'a',
    б: 'b',
    в: 'v',
    г: 'g',
    д: 'd',
    е: 'e',
    ё: 'e',
    ж: 'j',
    з: 'z',
    и: 'i',
    к: 'k',
    л: 'l',
    м: 'm',
    н: 'n',
    о: 'o',
    п: 'p',
    р: 'r',
    с: 's',
    т: 't',
    у: 'u',
    ф: 'f',
    х: 'h',
    ц: 'c',
    ч: 'ch',
    ш: 'sh',
    щ: 'shch',
    ы: 'y',
    э: 'e',
    ю: 'u',
    я: 'ya'
  };
  const n_str: string[] = [];

  str = str.replace(/[ъь]+/g, '').replace(/й/g, 'i');

  for (let i = 0; i < str.length; ++i) {
    n_str.push(
      ru[str[i]]
         || ru[str[i].toLowerCase()] == undefined && str[i]
         || ru[str[i].toLowerCase()].toUpperCase()
    );
  }

  return n_str.join('');
};

export const getMymeType = (name: string) => {
  const extension = name.slice((name.lastIndexOf('.') - 1 >>> 0) + 2);
  switch (extension) {
    case 'pdf':
      return 'application/pdf';
    case 'bmp':
      return 'image/bmp';
    case 'jpg':
      return 'image/jpeg';
    case 'jpeg':
      return 'image/jpeg';
    case 'png':
      return 'image/png';
    case 'xls':
      return 'application/vnd.ms-excel';
    case 'xlsx':
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    default:
      return 'image/jpeg';
  }
};

export const downloadFileWithLink = (fileURL: string, fileName: string) => {
  const downloadLink = document.createElement('a');
  downloadLink.href = fileURL;
  downloadLink.style.display = 'none';
  downloadLink.download = fileName;
  downloadLink.name = fileName;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  downloadLink?.parentNode?.removeChild(downloadLink);
};

export const convertDataURLtoBlob = (dataurl: string) => {
  const arr = dataurl.split(',');
  // @ts-ignore
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

export const getFileExtenson = (name: string) => name.split('.').pop()?.toUpperCase() || 'без расширения';

export const downloadFileFromResponse = (name: string, response: any) => {
  if ((navigator as any)?.msSaveBlob) { // IE11 and Edge 17-
    (navigator as any)?.msSaveBlob(response.blob, name);
  } else if (isCrios) { // Chrome IOS
    const file = new File([response.blob], name, { type: getMymeType(name) });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, '_blank');
    setTimeout(() => {
      window.URL.revokeObjectURL(fileURL);
    }, 0);
  } else { // every other browser
    const reader = new FileReader();
    reader.onloadend = () => {
      downloadFileWithLink(reader.result as string, name);
    };
    reader.readAsDataURL(response.blob);
  }
}
