import { createSelector } from 'reselect';

import { IRootState } from '../../../reducers';
import { mapProfessionAdditionalEducationResponse } from './mappers';

const professionAdditionalEducationSelector = createSelector(
  [(state: IRootState) => state.studentProfessionalAdditionalEducation],
  (studentProfessionalEducation) => {
    return mapProfessionAdditionalEducationResponse(studentProfessionalEducation);
  },
);

export default professionAdditionalEducationSelector;
