/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';

import {
  GET_STUDENT_INDEPENDENT_DIAGNOSIS
} from '../../actions';

import {
  FAILURE,
  REQUEST,
  SUCCESS
} from '../../actions/utils';

import { IIndependentDiagnosticResponse } from '../../api/types';

import genericReducers from '../genericReducer';

import { IReducers } from '..';

export type StudentIndependentDiagnosisState = IIndependentDiagnosticResponse;

const initialState = {} as IIndependentDiagnosticResponse;

const studentDiagnosis: IReducers<StudentIndependentDiagnosisState> = {
  [GET_STUDENT_INDEPENDENT_DIAGNOSIS[REQUEST]]: genericReducers().get.request,
  [GET_STUDENT_INDEPENDENT_DIAGNOSIS[SUCCESS]]: genericReducers().get.success,
  [GET_STUDENT_INDEPENDENT_DIAGNOSIS[FAILURE]]: genericReducers().get.failure
};

export default connectReducers(initialState, studentDiagnosis);
