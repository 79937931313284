import { SxProps } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';

export const root = (selected: boolean): SxProps => ({
  width: '100%',
  maxWidth: 'max-content',
  height: '48px',
  padding: '10px 12px',

  borderRadius: '12px',
  backgroundColor: selected ? NeutralColors.light_neutrals_0 : 'none',

  cursor: 'pointer',

  '& ~ *': {
    marginTop: '4px',
  },

  '& .label': {
    fontWeight: selected ? 600 : 400,
  },

  '&:hover': !selected
    ? {
        backgroundColor: NeutralColors.light_neutrals_300,

        '& .label': {
          fontWeight: 500,
        },
      }
    : null,
});

export const sectionIconContainer: SxProps = {
  width: '28px',
  height: '28px',
  minWidth: 'unset',
  padding: '4px',
  marginRight: '8px',

  backgroundColor: NeutralColors.light_neutrals_300,
  borderRadius: '8px',

  '& svg': {
    width: '20px',
    height: '20px',
    color: NeutralColors.light_neutrals_600,
  },
};

export const rightIcon: SxProps = {
  width: '20px',
  height: '20px',
  marginLeft: '8px',
  color: NeutralColors.light_neutrals_600,
};
