import { inputClasses, menuClasses, styled } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';

import BaseMenu from '../BaseMenu';

const StyledSearchableMenu = styled(BaseMenu)(() => ({
  [`& .${menuClasses.paper}`]: {
    padding: 0,
    overflow: 'hidden',
  },

  [`& .${inputClasses.root}`]: {
    width: '100%',
    margin: 0,
  },

  '& .searchable-menu': {
    maxHeight: 'inherit',
    display: 'grid',
    gridAutoFlow: 'row',
    gridTemplateRows: 'auto 1fr',

    '&__input-container': {
      padding: '8px',
      outline: 'none',
      backgroundColor: NeutralColors.light_neutrals_100,

      borderBottom: '1px solid',
      borderColor: NeutralColors.light_neutrals_300,
    },

    '&__list': {
      padding: '8px',
      overflowY: 'scroll',
    },
  },
}));

export default StyledSearchableMenu;
