import { FC } from 'react';

import { Box } from '@mui/material';
import { SxStyles } from 'types';
import { mergeSx } from 'utils';

import { CommonUiKitSize } from '../types';

const root = (size: CommonUiKitSize): SxStyles => {
  const isSmall = size === 'small';
  const height = isSmall ? '14px' : '18px';
  const fontSize = isSmall ? '11px' : '14px';
  const padding = isSmall ? '0 4px' : '2px 6px';

  return {
    width: 'max-content',
    height,
    padding,
    borderRadius: '20px',

    fontWeight: 700,
    fontSize,
    lineHeight: '14px',
    color: '#ffffff',

    outline: '3px solid',
  };
};

interface IFiltersCountBadgeProps {
  count: number;
  color: string;
  outlineColor: string;
  size?: CommonUiKitSize;
  sx?: SxStyles;
}

const FiltersCountBadge: FC<IFiltersCountBadgeProps> = ({ count, color, outlineColor, size = 'medium', sx }) => {
  const colorStyles: SxStyles = {
    backgroundColor: color,
    outlineColor,
  };

  return <Box sx={mergeSx(root(size), colorStyles, sx)}>{count}</Box>;
};

export default FiltersCountBadge;
