import { FC } from 'react';

import { useAppSelector, useAvatar } from 'hooks';

import { Avatar } from '../../../ui-kit';
import { getPersonInitials } from '../../../utils';

import './index.scss';

const PdfPortfolioPersonalInfo: FC = () => {
  const currentStudent = useAppSelector((state) => state.currentStudent);

  const { avatarImage } = useAvatar();

  return (
    <div className="pdf-portfolio-personal-info">
      <Avatar className="pdf-student-avatar" alt="аватар" variant="circular" src={avatarImage}>
        {getPersonInitials(currentStudent)}
      </Avatar>
      <div className="pdf-student-info">
        <h3 className="pdf-student-info__fio">
          {currentStudent?.firstname} {currentStudent?.lastname}
        </h3>
        <p className="pdf-student-info__school">{currentStudent?.schoolName}</p>
      </div>
    </div>
  );
};

export default PdfPortfolioPersonalInfo;
