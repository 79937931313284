import { FC } from 'react';

import { Box } from '@mui/material';
import { IconChevronSmallDown } from 'icons';
import { NeutralColors } from 'portfolio3/styles';

import { ISwitcherButtonProps } from '../../types';
import SwitcherButton from './SwitcherButton';

interface ISwitcherButtonMoreProps extends ISwitcherButtonProps {
  content: string;
  count?: number;
  onClick?: () => void;
}
type ButtonMoreContentProps = Pick<ISwitcherButtonMoreProps, 'content' | 'count' | 'componentVariant'>;

const ButtonMoreContent: FC<ButtonMoreContentProps> = ({ content, count }) => {
  return (
    <>
      <Box component="span">{content}</Box>
      {count !== undefined && (
        <Box
          sx={{
            marginLeft: '8px',
            color: NeutralColors.light_neutrals_600,
          }}
          component="span"
        >
          {count}
        </Box>
      )}
      <IconChevronSmallDown
        sx={{ marginLeft: '4px', width: '20px', height: '20px', color: NeutralColors.light_neutrals_600 }}
      />
    </>
  );
};

const SwitcherButtonMore: FC<ISwitcherButtonMoreProps> = ({ onClick, ...props }) => {
  const Content = <ButtonMoreContent {...props} />;

  return <SwitcherButton {...props} content={Content} onClick={onClick} />;
};

export default SwitcherButtonMore;
