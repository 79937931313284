/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';

import {
  FAILURE,
  INVALIDATE,
  REQUEST,
  SUCCESS
} from '../../actions/utils';

import genericReducers from '../genericReducer';
import { IReducers } from '..';
import { ICommonResponse, IStudentProject } from '../../api/types';
import { GET_STUDENT_PROJECTS } from '../../actions';

export type StudentProjectsState = ICommonResponse<IStudentProject>;

const initialState = {
  content: [],
  loading: false
} as ICommonResponse<IStudentProject>;

const studentProjects: IReducers<StudentProjectsState> = {
  [GET_STUDENT_PROJECTS[REQUEST]]: genericReducers().get.request,
  [GET_STUDENT_PROJECTS[SUCCESS]]: genericReducers().get.success,
  [GET_STUDENT_PROJECTS[FAILURE]]: genericReducers().get.failure,
  [GET_STUDENT_PROJECTS[INVALIDATE]]: genericReducers().get.invalidate(initialState),
};

export default connectReducers(initialState, studentProjects);
