import { FC, useContext, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import { ICommonResponse, IDictionaryItem } from 'api/types';
import { SectionCodes } from 'const';
import { useAppSelector } from 'hooks';
import { BaseInputLabel, FormControl } from 'portfolio3/ui-kit/forms';
import { IconSelect, IIconSelectItem, Select } from 'portfolio3/ui-kit/selects';
import { IController } from 'portfolio3/ui-kit/types';
import { IRootState } from 'reducers';
import { availableEmployeeParentSectionsSelector } from 'selectors';
import { sectionRefArchiveFalseSelector } from 'selectors';
import { getSectionIcon } from 'utils';

import {
  CivilPrimaryBlock,
  CreationPrimaryBlock,
  CulturePrimaryBlock,
  ProfessionPrimaryBlock,
  SciencePrimaryBlock,
  SportPrimaryBlock,
  StudyPrimaryBlock,
} from '../../categories/primary';
import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';
import { defaultPortfolioEntryFormData } from '../../utils';

interface IPrimaryBlocksProps {
  availableSections: ICommonResponse<IDictionaryItem>;
}

const PrimaryBlocks: FC<IPrimaryBlocksProps> = ({ availableSections }) => {
  const nonArchiveSections = useAppSelector(sectionRefArchiveFalseSelector);

  const { studentType, inputRenderMode, inputSize, formData, onChangeFormData } = useContext(LocalDataEntryFormContext);

  const categoryCodeOptions: IIconSelectItem[] = useMemo(() => {
    return availableSections.content.map((portfolioSection): IIconSelectItem => {
      return {
        ...portfolioSection,
        startIcon: getSectionIcon(portfolioSection.code),
        selectedIconColor: '#ffffff',
      };
    });
  }, [availableSections.content]);

  const dataKindOptions = useMemo(() => {
    return nonArchiveSections.content.filter((section) => section.parentId === formData.categoryCode);
  }, [formData.categoryCode, nonArchiveSections.content]);

  const categoryCodeController: IController<string | undefined> = useMemo(
    () => ({
      handleChange: (section) => {
        onChangeFormData({
          ...defaultPortfolioEntryFormData,
          categoryCode: Number(section),
        });
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData.id],
  );

  const dataKindController: IController<string | undefined> = useMemo(
    () => ({
      handleChange: (categoryKind) => {
        onChangeFormData((prevstate) => ({
          ...defaultPortfolioEntryFormData,
          dataKind: Number(categoryKind),
          categoryCode: prevstate.categoryCode,
          organizator: [''],
        }));
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData.id],
  );

  useEffect(() => {
    if (dataKindOptions.length === 1) {
      onChangeFormData({
        ...formData,
        dataKind: dataKindOptions[0].code,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataKindOptions]);

  const isDataKindSelectDisabled = dataKindOptions.length === 1;

  return (
    <>
      {/* categoryCode */}
      {!studentType && (
        <FormControl
          renderMode="fixed"
          inputSize="medium"
          label={<BaseInputLabel>Категория</BaseInputLabel>}
          control={
            <IconSelect
              inputRenderMode="fixed"
              inputSize="medium"
              value={formData.categoryCode}
              options={categoryCodeOptions}
              controller={categoryCodeController}
            />
          }
        />
      )}
      {/* dataKind */}
      {!studentType && (
        <FormControl
          disabled={isDataKindSelectDisabled}
          renderMode={inputRenderMode}
          inputSize={inputSize}
          label={<BaseInputLabel>Вид данных</BaseInputLabel>}
          control={
            <Select
              inputRenderMode={inputRenderMode}
              inputSize={inputSize}
              placeholder="Выберите вид данных..."
              value={formData.dataKind}
              options={dataKindOptions}
              controller={dataKindController}
              strictMaxWidth
            />
          }
        />
      )}
      {formData.categoryCode === SectionCodes.study && <StudyPrimaryBlock />}
      {formData.categoryCode === SectionCodes.science && <SciencePrimaryBlock />}
      {formData.categoryCode === SectionCodes.sport && <SportPrimaryBlock />}
      {formData.categoryCode === SectionCodes.creation && <CreationPrimaryBlock />}
      {formData.categoryCode === SectionCodes.culture && <CulturePrimaryBlock />}
      {formData.categoryCode === SectionCodes.civil && <CivilPrimaryBlock />}
      {formData.categoryCode === SectionCodes.profession && <ProfessionPrimaryBlock />}
    </>
  );
};

export default connect((state: IRootState) => ({
  availableSections: availableEmployeeParentSectionsSelector(state),
}))(PrimaryBlocks);
