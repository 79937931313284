import { forwardRef } from 'react';

import { Menu, menuClasses, MenuProps, styled } from '@mui/material';

const BaseMenu = forwardRef<HTMLDivElement, MenuProps>((props, ref) => {
  return <Menu {...props} ref={ref} />;
});

BaseMenu.displayName = 'BaseMenu';

const StyledBaseMenu = styled(BaseMenu)(() => ({
  [`& .${menuClasses.paper}`]: {
    maxHeight: '360px',
    padding: '8px',

    backgroundColor: '#ffffff',
    border: '1px solid #E8E8EF',
    borderRadius: '8px',
    boxShadow: '0px 4px 12px rgba(146, 146, 146, 0.2)',

    overflowY: 'auto',
  },
  [`& .${menuClasses.list}`]: {
    padding: 0,
  },
}));

export default StyledBaseMenu;
