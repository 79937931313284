import { SxStyles } from 'types';

export const root: SxStyles = {
  height: '100%',
};

export const iframe: SxStyles = {
  width: '100%',
  height: '100%',
  border: 'none',
};
