/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useContext } from 'react';
import { connect } from 'react-redux';

import SettingsSwitch from '../../../components/common/SettingsSwitch';
import { Button } from '../../../ui-kit';
import { IRootState } from '../../../reducers';
import { PortfolioSettingsContext } from '../../../context';
import { ILinkMode } from '../../../api/types';
import { findTargetVisibilitySetting } from '../../../utils/localVisibilitySettingsService';
import { linkVisibilitySettingsSelector, localVisibilitySettingsSelector, visibilitySettingsSelector } from '../../../selectors';
import { IViewVisibilitySetting } from '../../../types';

import './index.scss';

interface IHiddenEntityContainerProps {
  visibilitySettings: IViewVisibilitySetting[];
  localVisibilitySettings: IViewVisibilitySetting[];
  linkVisibilitySettings: IViewVisibilitySetting[];
  linkMode: ILinkMode;
  sectionTypeCode: string;
  subsectionTypeCode: string;
  children: React.ReactNode;
}

const HiddenEntityContainer: React.FC<IHiddenEntityContainerProps> = ({
  localVisibilitySettings,
  linkVisibilitySettings,
  linkMode,
  visibilitySettings,
  sectionTypeCode,
  subsectionTypeCode,
  children
}) => {
  const { isSettingsMode, setSubsectionVisibility } = useContext(PortfolioSettingsContext);

  /* режим настроек */
  if (isSettingsMode) {
    const localSetting = findTargetVisibilitySetting(localVisibilitySettings, [sectionTypeCode, subsectionTypeCode]);
    const isAdminEnabled = localSetting?.isAdminVisible;
    const isVisible = localSetting?.computedVisibility ?? false;

    const handleSetSubsectionVisibility = (isVisible: boolean) => {
      return () => {
        if (!sectionTypeCode || !subsectionTypeCode) return;
        setSubsectionVisibility(localVisibilitySettings, sectionTypeCode, subsectionTypeCode, isVisible);
      }
    };

    if (!isAdminEnabled) return null;

    return !isVisible ? (
      <div className="hidden-entity-container" id={subsectionTypeCode}>
        <div className="hidden-entity-container__switch">
          <SettingsSwitch
            checked={isVisible}
            onChange={handleSetSubsectionVisibility(!isVisible)}
          />
        </div>
        <div className="hidden-entity-container__overlay">
          <h3 className="hidden-entity-container__title">Раздел скрыт</h3>
          <Button
            type="button"
            variant="outlined"
            onClick={handleSetSubsectionVisibility(true)}
          >
            Показать
          </Button>
        </div>
        {children}
      </div>
    ) : (
      <div className="hidden-entity-container">
        <div className="hidden-entity-container__switch">
          <SettingsSwitch
            checked={isVisible}
            onChange={handleSetSubsectionVisibility(!isVisible)}
          />
        </div>
        {children}
      </div>
    );
  }

  /* режим просмотра портфолио */
  const settings = linkMode.mode ? linkVisibilitySettings : visibilitySettings;
  const sectionSettings = findTargetVisibilitySetting(settings, [sectionTypeCode, subsectionTypeCode]);
  const isSectionVisible = sectionSettings?.computedVisibility ?? false;

  return isSectionVisible ? <>{children}</> : null;
};

export default connect(
  (state: IRootState) => ({
    visibilitySettings: visibilitySettingsSelector(state),
    localVisibilitySettings: localVisibilitySettingsSelector(state),
    linkVisibilitySettings: linkVisibilitySettingsSelector(state),
    linkMode: state.linkMode,
  })
)(HiddenEntityContainer);
