import {
  IVuzDetailsFilters,
  IVuzRecommendationClientFilters,
  IVuzRecommendationFilters,
  VuzLocalFilters,
} from '../types';
import { detailsPaginationLimits, paginationLimits } from './paginationLimitOptions';
import { SortTypeCodes } from './sortType';

export const initialServerFilters: IVuzRecommendationFilters = {
  search: '',
  subjects: [],
  specialities: [],
  trainingTypeCode: undefined,
  militaryTrainingTypeCode: undefined,
  minPassingBall: undefined,
  maxPassingBall: undefined,
};

export const initialClientFilters: IVuzRecommendationClientFilters = {
  isHigherBallToggle: false,
  sortType: SortTypeCodes.default,
  page: 1,
  limit: paginationLimits[0],
};

export const initialLocalFilters: VuzLocalFilters = {
  ...initialServerFilters,
  ...initialClientFilters,
};

export const initialDetailsDrawerFilters: IVuzDetailsFilters = {
  search: '',
  sortType: SortTypeCodes.default,
  page: 1,
  limit: detailsPaginationLimits[0],
};
