import { SxProps } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';

export const root: SxProps = {
  position: 'relative',
};

export const year: SxProps = {
  position: 'absolute',
  top: '14px',
  left: '-18px',

  fontWeight: 900,
  fontSize: '20px',
  lineHeight: '24px',
  color: NeutralColors.light_neutrals_500,
  opacity: 0.4,

  transform: 'rotate(-90deg)',
};
