import { FC } from 'react';

import { InputBase, styled, TextareaAutosizeProps } from '@mui/material';

import { Colors } from '../../style/variables';
import { numberToPixelsString } from '../../utils';
import { InputRenderMode, InputTokens } from '../utils';

interface ITextAreaProps extends TextareaAutosizeProps {
  renderMode: InputRenderMode;
}

const StyledInputBase = styled(InputBase)(({ renderMode, disabled }: ITextAreaProps) => {
  const inputTokens = InputTokens[renderMode];

  const backgroundColor = disabled ? '#f4f3f8' : '#ffffff';
  const textColor = disabled ? Colors['note-text'] : Colors['main-text'];

  return {
    padding: 0,

    '& textarea': {
      paddingRight: inputTokens.paddingLeft,
      fontSize: numberToPixelsString(inputTokens.fontSize),
      lineHeight: numberToPixelsString(inputTokens.lineHeight),
      paddingBlock: numberToPixelsString(inputTokens.paddingBlock),
      paddingInline: numberToPixelsString(inputTokens.paddingLeft),

      maxWidth: '100%',
      minHeight: numberToPixelsString(87),

      backgroundColor,
      border: '1px solid',
      borderRadius: numberToPixelsString(8),
      borderColor: Colors['control-stroke'],

      outline: 'none',
      color: textColor,

      '&::placeholder': {
        color: Colors['note-text'],
      },
    },

    '&.Mui-focused textarea': {
      borderColor: Colors['blue-primrose'],
    },
  };
});

const TextareaNew: FC<ITextAreaProps> = ({ renderMode, ...props }) => {
  return (
    <StyledInputBase
      renderMode={renderMode}
      disabled={props.disabled}
      multiline
      maxRows={Infinity}
      inputProps={props}
    />
  );
};

export default TextareaNew;
