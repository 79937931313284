import { FC, useContext } from 'react';

import { ThemeProvider, Typography } from '@mui/material';
import { PortfolioSettingsContext } from 'context';
import TrimTextMultiLine from 'portfolio3/components/common/TrimTextMultiLine';
import { emitYMEvent } from 'portfolio3/features/yandexMetrika';
import { getAccentColor } from 'portfolio3/styles';
import { generateAccentTheme } from 'portfolio3/styles/theme';
import { AccentBadge } from 'portfolio3/ui-kit/badges';

import { IconInfoFilled } from '../../../../../../icons/notifications';
import { classIllustration } from '../../../../../../images';
import { Tooltip } from '../../../../../../ui-kit';
import RecommendationLinkButton from '../common/linkButton';
import RecommendationContainer from '../recommendationContainer';

import './index.scss';

interface IClassRecommendationProps {
  name: string;
  image: string;
  link: string;
  isLocalSchool: boolean;
  onOpen: () => void;
}

const ClassRecommendation: FC<IClassRecommendationProps> = ({ name, image, link, isLocalSchool, onOpen }) => {
  const { isSettingsMode } = useContext(PortfolioSettingsContext);

  const tooltipText = 'В твоей школе пока нет данного класса, но ты можешь посмотреть его в других школах';

  const tooltipElement = (
    <Tooltip arrow={true} className="class-recommendation__tooltip" placement="top" width="259" title={tooltipText}>
      <div onClick={onOpen}>
        <IconInfoFilled />
      </div>
    </Tooltip>
  );

  const handleAboutLinkClick = () => {
    // Только если есть в нашей школе
    if (isLocalSchool) {
      emitYMEvent({ type: 'recommendationClassAboutMeClick' });
    }
  };

  return (
    <RecommendationContainer className="class-recommendation">
      <RecommendationContainer.Illustration illustration={classIllustration} width={220} height={237} />
      <RecommendationContainer.Content>
        <>
          <ThemeProvider theme={generateAccentTheme(isLocalSchool ? 'indigo' : 'orange')}>
            <AccentBadge sx={{ borderRadius: '12px' }}>
              {isLocalSchool ? 'Есть в твоей школе!' : 'Нет в твоей школе'}
              {!isLocalSchool && tooltipElement}
            </AccentBadge>
          </ThemeProvider>
          <Typography component={TrimTextMultiLine} line={3} variant="Paragraph LG/Semi Bold" marginTop="12px">
            Обрати внимание на{' '}
            <Typography variant="Paragraph LG/Semi Bold" color={getAccentColor('indigo', '200')}>
              {name}
            </Typography>
          </Typography>
        </>
      </RecommendationContainer.Content>

      <RecommendationContainer.Footer>
        <div className="class-recommendation__avatar-container">
          <img className="class-recommendation__avatar" src={image} alt="profile class avatar" />
        </div>
        <RecommendationLinkButton link={link} disabled={isSettingsMode} onClick={handleAboutLinkClick}>
          {isLocalSchool ? 'Подробнее' : 'Список школ'}
        </RecommendationLinkButton>
      </RecommendationContainer.Footer>
    </RecommendationContainer>
  );
};

export default ClassRecommendation;
