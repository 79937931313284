import { FC } from 'react';

import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { IChildInfo } from 'api/types';
import { useAppSelector, useLinkModeStudentName } from 'hooks';
import { portfolioLogo } from 'images';
import TrimText from 'portfolio3/components/common/TrimText';
import { StatusSelector } from 'portfolio3/components/proftech';
import { HeaderAvatar } from 'portfolio3/features/avatar';
import { NeutralColors } from 'portfolio3/styles';
import { commonTheme } from 'portfolio3/styles/theme';
import { mergeSx } from 'utils';

import * as styles from './styles';

interface IUserCardProps {
  student?: IChildInfo;
}

const StudentCard: FC<IUserCardProps> = ({ student }) => {
  const isBottomSpoStatusSelector = useMediaQuery(commonTheme.breakpoints.down('commonLg'));
  const isLinkMode = useAppSelector((state) => state.linkMode.mode) ?? false;

  const { fullName } = useLinkModeStudentName(student?.firstname, student?.lastname);

  return (
    <Box className="student-card" sx={styles.root}>
      <Box className="student-card__logo" sx={styles.logoContainer}>
        <Box component="img" src={portfolioLogo} alt="логотип" sx={styles.logoImage} />
      </Box>
      <Box className="student-card__content" sx={styles.content}>
        <HeaderAvatar />
        {/**
         * Скрытие происходит, когда в хедере отображаются кнопки: Поделиться, Темы, Настройки, Поиск
         * То есть при доступе по ссылке их не будет, и можно показывать имя пользователя
         */}
        <Stack className="student-card__title" sx={mergeSx(!isLinkMode && styles.hideTitleOnSmallScreen)}>
          <Typography variant="Paragraph MD/Medium" color={NeutralColors.light_neutrals_600}>
            Портфолио
          </Typography>
          <Box className="student-card__name" sx={styles.nameContainer}>
            <Typography component={TrimText} sx={styles.name}>
              {fullName}
            </Typography>
            {!isBottomSpoStatusSelector && <StatusSelector />}
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default StudentCard;
