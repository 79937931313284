/* eslint-disable max-len */
import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconSectionCivil: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m9.931 16.075 4.57 2.768a1.103 1.103 0 0 0 1.642-1.19l-1.214-5.196 4.046-3.506a1.102 1.102 0 0 0-.627-1.928l-5.323-.452-2.078-4.91a1.11 1.11 0 0 0-2.031 0L6.837 6.563l-5.322.452a1.095 1.095 0 0 0-.627 1.928l4.046 3.498-1.214 5.196a1.095 1.095 0 0 0 1.642 1.19l4.57-2.752Z"
      fill={props.fill || 'currentColor' || '#51CF66'}
    />
  </SvgIcon>
);

export default IconSectionCivil;
