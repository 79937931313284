import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const It: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="230" height="129" viewBox="0 0 230 129" xmlns="http://www.w3.org/2000/svg" {...props} fill="none">
    {/*eslint-disable max-len */}
    <g clipPath="url(#clip0_113_115825)">
      <path
        d="M139.46 46.9999C139.169 47.0111 138.879 46.9628 138.607 46.8579C138.335 46.753 138.088 46.5938 137.88 46.3899C137.667 46.1492 137.507 45.8662 137.41 45.5596C137.314 45.2529 137.283 44.9293 137.32 44.6099C137.476 42.7554 137.811 40.9203 138.32 39.1299C138.507 38.4259 138.926 37.8056 139.51 37.3699C140.001 37.0232 140.589 36.8412 141.19 36.8499H141.91C142.201 36.8363 142.492 36.8836 142.764 36.9886C143.036 37.0936 143.283 37.2541 143.49 37.4599C143.694 37.6971 143.844 37.9749 143.932 38.275C144.02 38.575 144.043 38.8903 144 39.1999C143.844 41.0578 143.509 42.8963 143 44.6899C142.808 45.3944 142.39 46.0164 141.81 46.4599C141.319 46.8067 140.731 46.9887 140.13 46.9799L139.46 46.9999ZM141.16 37.4799C140.63 37.4746 140.115 37.6546 139.704 37.9888C139.293 38.323 139.012 38.7903 138.91 39.3099C138.402 41.0494 138.067 42.8347 137.91 44.6399C137.857 45.1129 137.994 45.5875 138.29 45.9599C138.441 46.101 138.619 46.2101 138.813 46.2806C139.007 46.351 139.213 46.3814 139.42 46.3699H140.14C140.671 46.3749 141.187 46.1935 141.598 45.8573C142.009 45.5211 142.289 45.0514 142.39 44.5299C142.896 42.7901 143.231 41.005 143.39 39.1999C143.441 38.7302 143.304 38.2593 143.01 37.8899C142.859 37.7488 142.681 37.6398 142.487 37.5693C142.293 37.4988 142.086 37.4684 141.88 37.4799H141.16Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M146.569 46.9999L145.959 46.8899L147.599 37.5999L145.489 38.2799L145.289 37.6899L148.389 36.6899L146.569 46.9999Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M151.809 47.0002C151.518 47.0126 151.228 46.9648 150.956 46.8598C150.684 46.7549 150.437 46.595 150.229 46.3902C150.014 46.1508 149.853 45.8681 149.756 45.561C149.659 45.2539 149.63 44.9297 149.669 44.6102C149.826 42.7557 150.161 40.9205 150.669 39.1302C150.856 38.4261 151.276 37.8059 151.859 37.3702C152.346 37.0236 152.931 36.8415 153.529 36.8502H154.249C154.542 36.8358 154.835 36.8826 155.109 36.9877C155.382 37.0927 155.631 37.2536 155.839 37.4602C156.05 37.6983 156.209 37.9778 156.305 38.281C156.402 38.5841 156.434 38.904 156.399 39.2202C156.243 41.0781 155.908 42.9166 155.399 44.7102C155.211 45.4137 154.796 46.0356 154.219 46.4802C153.728 46.8269 153.14 47.009 152.539 47.0002H151.809ZM153.499 37.4802C152.97 37.4768 152.456 37.6575 152.046 37.9913C151.635 38.3252 151.354 38.7915 151.249 39.3102C150.758 41.0513 150.44 42.8366 150.299 44.6402C150.246 45.1131 150.383 45.5877 150.679 45.9602C150.83 46.1013 151.008 46.2104 151.202 46.2808C151.397 46.3513 151.603 46.3817 151.809 46.3702H152.529C153.06 46.3732 153.575 46.1911 153.985 45.8552C154.396 45.5193 154.677 45.0508 154.779 44.5302C155.286 42.7904 155.62 41.0052 155.779 39.2002C155.826 38.7309 155.69 38.2616 155.399 37.8902C155.247 37.7488 155.067 37.6396 154.871 37.5692C154.675 37.4988 154.467 37.4685 154.259 37.4802H153.499Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M158.919 46.9999L158.309 46.8899L159.999 37.5999L157.889 38.2799L157.689 37.6899L160.789 36.6899L158.919 46.9999Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M164.161 47.0002C163.868 47.0122 163.576 46.9642 163.303 46.8593C163.029 46.7544 162.78 46.5948 162.571 46.3902C162.359 46.1489 162.2 45.8657 162.106 45.5589C162.011 45.2522 161.982 44.9289 162.021 44.6102C162.177 42.7557 162.512 40.9205 163.021 39.1302C163.204 38.4245 163.624 37.8031 164.211 37.3702C164.698 37.0236 165.283 36.8415 165.881 36.8502H166.601C166.893 36.8358 167.186 36.8826 167.46 36.9877C167.734 37.0927 167.983 37.2536 168.191 37.4602C168.402 37.6977 168.56 37.9775 168.655 38.281C168.75 38.5844 168.779 38.9046 168.741 39.2202C168.585 41.0781 168.25 42.9166 167.741 44.7102C167.552 45.4163 167.133 46.0392 166.551 46.4802C166.064 46.8281 165.479 47.0103 164.881 47.0002H164.161ZM165.851 37.4802C165.322 37.4768 164.808 37.6575 164.397 37.9913C163.987 38.3252 163.705 38.7915 163.601 39.3102C163.109 41.0513 162.791 42.8366 162.651 44.6402C162.595 45.1118 162.728 45.5862 163.021 45.9602C163.174 46.1006 163.354 46.2092 163.549 46.2796C163.745 46.35 163.953 46.3808 164.161 46.3702H164.881C165.411 46.3732 165.926 46.1911 166.337 45.8552C166.748 45.5193 167.028 45.0508 167.131 44.5302C167.637 42.7904 167.972 41.0052 168.131 39.2002C168.185 38.7318 168.052 38.2611 167.761 37.8902C167.607 37.7498 167.427 37.6412 167.232 37.5708C167.036 37.5004 166.828 37.4696 166.621 37.4802H165.851Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M171.27 46.9999L170.66 46.8899L172.29 37.5999L170.19 38.2799L169.99 37.6899L173.09 36.6899L171.27 46.9999Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M115.001 33.4799C114.71 33.4911 114.42 33.4428 114.148 33.3379C113.876 33.233 113.629 33.0738 113.421 32.8699C113.208 32.6292 113.048 32.3462 112.951 32.0395C112.855 31.7329 112.824 31.4093 112.861 31.0899C113.018 29.2354 113.352 27.4003 113.861 25.6099C114.034 24.9128 114.436 24.2935 115.001 23.8499C115.492 23.5032 116.08 23.3211 116.681 23.3299H117.401C117.692 23.3163 117.983 23.3635 118.255 23.4686C118.527 23.5736 118.774 23.7341 118.981 23.9399C119.196 24.1752 119.358 24.4544 119.454 24.7583C119.551 25.0622 119.581 25.3835 119.541 25.6999C119.385 27.5578 119.05 29.3963 118.541 31.1899C118.36 31.9076 117.944 32.5445 117.361 32.9999C116.87 33.3466 116.282 33.5287 115.681 33.5199L115.001 33.4799ZM116.701 23.9599C116.171 23.9545 115.656 24.1345 115.245 24.4687C114.835 24.8029 114.553 25.2703 114.451 25.7899C113.943 27.5293 113.608 29.3147 113.451 31.1199C113.398 31.5929 113.535 32.0674 113.831 32.4399C113.982 32.581 114.16 32.6901 114.354 32.7605C114.548 32.831 114.755 32.8614 114.961 32.8499H115.681C116.213 32.8544 116.73 32.6716 117.142 32.3334C117.553 31.9952 117.832 31.5232 117.931 30.9999C118.437 29.2601 118.772 27.4749 118.931 25.6699C118.982 25.2002 118.845 24.7293 118.551 24.3599C118.4 24.2188 118.222 24.1098 118.028 24.0393C117.834 23.9688 117.627 23.9384 117.421 23.9499L116.701 23.9599Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M122.069 33.4999L121.459 33.3899L123.099 24.0999L120.989 24.7799L120.789 24.1899L123.889 23.1899L122.069 33.4999Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M127.309 33.4797C127.018 33.4921 126.728 33.4443 126.456 33.3393C126.184 33.2344 125.937 33.0745 125.729 32.8697C125.514 32.6303 125.353 32.3476 125.256 32.0405C125.159 31.7334 125.13 31.4092 125.169 31.0897C125.326 29.2352 125.661 27.4 126.169 25.6097C126.356 24.9056 126.776 24.2854 127.359 23.8497C127.846 23.5031 128.431 23.3209 129.029 23.3297H129.749C130.042 23.3153 130.335 23.3621 130.609 23.4672C130.882 23.5722 131.131 23.7331 131.339 23.9397C131.55 24.1778 131.709 24.4573 131.805 24.7604C131.902 25.0636 131.934 25.3835 131.899 25.6997C131.743 27.5576 131.408 29.3961 130.899 31.1897C130.716 31.9091 130.297 32.5464 129.709 32.9997C129.218 33.3464 128.63 33.5285 128.029 33.5197L127.309 33.4797ZM128.999 23.9997C128.47 23.9962 127.956 24.1769 127.546 24.5108C127.135 24.8447 126.854 25.311 126.749 25.8297C126.241 27.5691 125.906 29.3544 125.749 31.1597C125.696 31.6326 125.833 32.1072 126.129 32.4797C126.28 32.6208 126.458 32.7298 126.652 32.8003C126.847 32.8708 127.053 32.9012 127.259 32.8897H127.999C128.542 32.8977 129.07 32.7117 129.488 32.3653C129.906 32.0188 130.186 31.5346 130.279 30.9997C130.786 29.2599 131.12 27.4747 131.279 25.6697C131.326 25.2004 131.19 24.7311 130.899 24.3597C130.747 24.2183 130.567 24.1091 130.371 24.0387C130.175 23.9683 129.967 23.938 129.759 23.9497L128.999 23.9997Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M134.421 33.4999L133.811 33.3899L135.451 24.0999L133.341 24.7799L133.141 24.1899L136.241 23.1899L134.421 33.4999Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M139.661 33.4797C139.368 33.4917 139.076 33.4437 138.803 33.3388C138.529 33.2339 138.28 33.0743 138.071 32.8697C137.859 32.6284 137.7 32.3452 137.606 32.0384C137.511 31.7317 137.482 31.4084 137.521 31.0897C137.677 29.2352 138.012 27.4 138.521 25.6097C138.704 24.9039 139.124 24.2826 139.711 23.8497C140.198 23.5031 140.783 23.3209 141.381 23.3297H142.101C142.393 23.3153 142.686 23.3621 142.96 23.4672C143.234 23.5722 143.483 23.7331 143.691 23.9397C143.902 24.1772 144.06 24.457 144.155 24.7605C144.25 25.0639 144.279 25.3841 144.241 25.6997C144.085 27.5576 143.75 29.3961 143.241 31.1897C143.06 31.9104 142.641 32.5485 142.051 32.9997C141.564 33.3475 140.979 33.5298 140.381 33.5197L139.661 33.4797ZM141.351 23.9997C140.822 23.9962 140.308 24.1769 139.897 24.5108C139.487 24.8447 139.205 25.311 139.101 25.8297C138.609 27.5708 138.291 29.3561 138.151 31.1597C138.095 31.6313 138.228 32.1057 138.521 32.4797C138.674 32.6201 138.854 32.7287 139.049 32.7991C139.245 32.8695 139.453 32.9003 139.661 32.8897H140.381C140.918 32.8905 141.439 32.7013 141.851 32.3555C142.262 32.0097 142.539 31.5294 142.631 30.9997C143.137 29.2599 143.472 27.4747 143.631 25.6697C143.685 25.2013 143.552 24.7306 143.261 24.3597C143.107 24.2192 142.927 24.1107 142.732 24.0403C142.536 23.9699 142.328 23.9391 142.121 23.9497L141.351 23.9997Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M146.77 33.4999L146.16 33.3899L147.79 24.0999L145.69 24.7799L145.49 24.1899L148.59 23.1899L146.77 33.4999Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M100.001 19.4799C99.7098 19.4911 99.4196 19.4428 99.1479 19.3379C98.8762 19.233 98.6288 19.0738 98.4208 18.8699C98.2077 18.6292 98.0477 18.3462 97.9513 18.0395C97.8548 17.7329 97.8239 17.4093 97.8608 17.0899C98.0175 15.2354 98.3524 13.4003 98.8608 11.6099C99.0345 10.9128 99.4356 10.2935 100.001 9.84992C100.492 9.5032 101.08 9.32113 101.681 9.32992H102.401C102.692 9.31633 102.983 9.36355 103.255 9.46857C103.527 9.57359 103.774 9.73412 103.981 9.93992C104.196 10.1752 104.358 10.4544 104.454 10.7583C104.551 11.0622 104.581 11.3835 104.541 11.6999C104.385 13.5578 104.05 15.3963 103.541 17.1899C103.36 17.9076 102.944 18.5445 102.361 18.9999C101.87 19.3466 101.282 19.5287 100.681 19.5199L100.001 19.4799ZM101.701 9.95992C101.171 9.95455 100.656 10.1345 100.245 10.4687C99.8345 10.8029 99.5534 11.2703 99.4508 11.7899C98.9428 13.5293 98.6078 15.3147 98.4508 17.1199C98.398 17.5929 98.5346 18.0674 98.8308 18.4399C98.9817 18.581 99.1596 18.6901 99.3538 18.7605C99.548 18.831 99.7545 18.8614 99.9608 18.8499H100.681C101.213 18.8544 101.73 18.6716 102.142 18.3334C102.553 17.9952 102.832 17.5232 102.931 16.9999C103.437 15.2601 103.772 13.4749 103.931 11.6699C103.982 11.2002 103.845 10.7293 103.551 10.3599C103.4 10.2188 103.222 10.1098 103.028 10.0393C102.834 9.96883 102.627 9.93843 102.421 9.94992L101.701 9.95992Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M107.069 19.4999L106.459 19.3899L108.099 10.0999L105.989 10.7799L105.789 10.1899L108.889 9.18994L107.069 19.4999Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M112.309 19.4797C112.018 19.4921 111.728 19.4443 111.456 19.3393C111.184 19.2344 110.937 19.0745 110.729 18.8697C110.514 18.6303 110.353 18.3476 110.256 18.0405C110.159 17.7334 110.13 17.4092 110.169 17.0897C110.326 15.2352 110.661 13.4 111.169 11.6097C111.356 10.9056 111.776 10.2854 112.359 9.84969C112.838 9.50915 113.412 9.32729 113.999 9.32969H114.719C115.012 9.31531 115.305 9.36212 115.579 9.46715C115.852 9.57218 116.101 9.73311 116.309 9.93969C116.52 10.1778 116.679 10.4573 116.775 10.7604C116.872 11.0636 116.904 11.3835 116.869 11.6997C116.713 13.5576 116.378 15.3961 115.869 17.1897C115.694 17.905 115.286 18.5417 114.709 18.9997C114.218 19.3464 113.63 19.5285 113.029 19.5197L112.309 19.4797ZM113.999 9.99969C113.47 9.99624 112.956 10.1769 112.546 10.5108C112.135 10.8447 111.854 11.311 111.749 11.8297C111.241 13.5691 110.906 15.3544 110.749 17.1597C110.696 17.6326 110.833 18.1072 111.129 18.4797C111.28 18.6208 111.458 18.7298 111.652 18.8003C111.847 18.8708 112.053 18.9012 112.259 18.8897H112.999C113.542 18.8977 114.07 18.7117 114.488 18.3653C114.906 18.0188 115.186 17.5346 115.279 16.9997C115.786 15.2599 116.12 13.4747 116.279 11.6697C116.326 11.2004 116.19 10.7311 115.899 10.3597C115.747 10.2183 115.567 10.1091 115.371 10.0387C115.175 9.96826 114.967 9.93798 114.759 9.94969L113.999 9.99969Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M119.421 19.4999L118.811 19.3899L120.451 10.0999L118.341 10.7799L118.141 10.1899L121.241 9.18994L119.421 19.4999Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M124.661 19.4797C124.368 19.4917 124.076 19.4437 123.803 19.3388C123.529 19.2339 123.28 19.0743 123.071 18.8697C122.859 18.6284 122.7 18.3452 122.606 18.0384C122.511 17.7317 122.482 17.4084 122.521 17.0897C122.677 15.2352 123.012 13.4 123.521 11.6097C123.704 10.9039 124.124 10.2826 124.711 9.84969C125.198 9.50311 125.783 9.32095 126.381 9.32969H127.101C127.393 9.31531 127.686 9.36212 127.96 9.46715C128.234 9.57218 128.483 9.73311 128.691 9.93969C128.902 10.1772 129.06 10.457 129.155 10.7605C129.25 11.0639 129.279 11.3841 129.241 11.6997C129.085 13.5576 128.75 15.3961 128.241 17.1897C128.06 17.9104 127.641 18.5485 127.051 18.9997C126.564 19.3475 125.979 19.5298 125.381 19.5197L124.661 19.4797ZM126.351 9.99969C125.822 9.99624 125.308 10.1769 124.897 10.5108C124.487 10.8447 124.205 11.311 124.101 11.8297C123.609 13.5708 123.291 15.3561 123.151 17.1597C123.095 17.6313 123.228 18.1057 123.521 18.4797C123.674 18.6201 123.854 18.7287 124.049 18.7991C124.245 18.8695 124.453 18.9003 124.661 18.8897H125.381C125.918 18.8905 126.439 18.7013 126.851 18.3555C127.262 18.0097 127.539 17.5294 127.631 16.9997C128.137 15.2599 128.472 13.4747 128.631 11.6697C128.685 11.2013 128.552 10.7306 128.261 10.3597C128.107 10.2192 127.927 10.1107 127.732 10.0403C127.536 9.96992 127.328 9.93912 127.121 9.94969L126.351 9.99969Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M131.77 19.4999L131.16 19.3899L132.79 10.0999L130.69 10.7799L130.49 10.1899L133.59 9.18994L131.77 19.4999Z"
        fill={props.fill || 'currentColor'}
      />
      <path d="M235.029 63.8496H173.279V64.8496H235.029V63.8496Z" fill={props.fill || 'currentColor'} />
      <path
        d="M172.469 66.7897C173.817 66.7897 174.909 65.6972 174.909 64.3497C174.909 63.0021 173.817 61.9097 172.469 61.9097C171.122 61.9097 170.029 63.0021 170.029 64.3497C170.029 65.6972 171.122 66.7897 172.469 66.7897Z"
        fill="white"
      />
      <path
        d="M172.469 67.2799C171.887 67.2799 171.319 67.1072 170.835 66.7837C170.351 66.4602 169.974 66.0005 169.752 65.4627C169.53 64.9249 169.472 64.3332 169.587 63.7626C169.701 63.1921 169.982 62.6682 170.394 62.2575C170.806 61.8467 171.331 61.5675 171.902 61.4552C172.473 61.3429 173.064 61.4025 173.601 61.6266C174.138 61.8506 174.597 62.229 174.919 62.7138C175.241 63.1986 175.411 63.768 175.409 64.3499C175.407 65.1279 175.096 65.8731 174.545 66.4223C173.994 66.9715 173.247 67.2799 172.469 67.2799ZM172.469 62.4099C172.086 62.4099 171.711 62.5237 171.391 62.7369C171.072 62.95 170.824 63.253 170.677 63.6075C170.53 63.962 170.492 64.3521 170.567 64.7284C170.641 65.1047 170.826 65.4504 171.097 65.7217C171.369 65.993 171.714 66.1778 172.091 66.2526C172.467 66.3275 172.857 66.2891 173.212 66.1422C173.566 65.9954 173.869 65.7467 174.082 65.4277C174.296 65.1087 174.409 64.7336 174.409 64.3499C174.409 64.0951 174.359 63.8429 174.262 63.6075C174.164 63.3721 174.021 63.1583 173.841 62.9781C173.661 62.798 173.447 62.6551 173.212 62.5576C172.976 62.4601 172.724 62.4099 172.469 62.4099Z"
        fill={props.fill || 'currentColor'}
      />
      <path d="M254.129 68.7197H192.379V69.7197H254.129V68.7197Z" fill={props.fill || 'currentColor'} />
      <path
        d="M189.53 71.6598C190.877 71.6598 191.97 70.5674 191.97 69.2198C191.97 67.8722 190.877 66.7798 189.53 66.7798C188.182 66.7798 187.09 67.8722 187.09 69.2198C187.09 70.5674 188.182 71.6598 189.53 71.6598Z"
        fill="white"
      />
      <path
        d="M189.53 72.1598C188.948 72.1598 188.38 71.9874 187.896 71.6643C187.413 71.3413 187.036 70.8821 186.814 70.3449C186.591 69.8077 186.533 69.2165 186.646 68.6462C186.76 68.0759 187.04 67.5521 187.451 67.1409C187.862 66.7297 188.386 66.4497 188.956 66.3363C189.527 66.2228 190.118 66.2811 190.655 66.5036C191.192 66.7261 191.651 67.1029 191.974 67.5864C192.297 68.0699 192.47 68.6383 192.47 69.2198C192.47 69.9995 192.16 70.7473 191.609 71.2987C191.057 71.85 190.31 72.1598 189.53 72.1598ZM189.53 67.2798C189.146 67.2798 188.771 67.3936 188.452 67.6067C188.133 67.8199 187.884 68.1229 187.738 68.4774C187.591 68.8319 187.552 69.2219 187.627 69.5983C187.702 69.9746 187.887 70.3203 188.158 70.5916C188.429 70.8629 188.775 71.0477 189.151 71.1225C189.528 71.1974 189.918 71.1589 190.272 71.0121C190.627 70.8653 190.93 70.6166 191.143 70.2976C191.356 69.9786 191.47 69.6035 191.47 69.2198C191.47 68.7053 191.265 68.2118 190.902 67.848C190.538 67.4842 190.044 67.2798 189.53 67.2798Z"
        fill={props.fill || 'currentColor'}
      />
      <path d="M246 59.7798H184.25V60.7798H246V59.7798Z" fill={props.fill || 'currentColor'} />
      <path
        d="M181.409 62.7198C182.756 62.7198 183.849 61.6274 183.849 60.2798C183.849 58.9323 182.756 57.8398 181.409 57.8398C180.061 57.8398 178.969 58.9323 178.969 60.2798C178.969 61.6274 180.061 62.7198 181.409 62.7198Z"
        fill="white"
      />
      <path
        d="M181.409 63.2198C180.827 63.2218 180.258 63.0511 179.773 62.7292C179.288 62.4074 178.91 61.9489 178.686 61.4119C178.462 60.8748 178.402 60.2834 178.514 59.7124C178.627 59.1415 178.906 58.6167 179.317 58.2045C179.727 57.7923 180.251 57.5113 180.822 57.3971C181.392 57.2828 181.984 57.3405 182.522 57.5627C183.06 57.7849 183.519 58.1617 183.843 58.6454C184.166 59.1291 184.339 59.6979 184.339 60.2798C184.339 61.0578 184.031 61.8041 183.481 62.3552C182.932 62.9063 182.187 63.2172 181.409 63.2198ZM181.409 58.3498C181.024 58.3478 180.648 58.4602 180.327 58.6726C180.007 58.885 179.757 59.1879 179.609 59.5428C179.461 59.8977 179.421 60.2886 179.496 60.6658C179.57 61.0431 179.755 61.3897 180.027 61.6616C180.299 61.9335 180.646 62.1185 181.023 62.193C181.4 62.2675 181.791 62.2283 182.146 62.0802C182.501 61.9321 182.804 61.6819 183.016 61.3614C183.229 61.0408 183.341 60.6644 183.339 60.2798C183.336 59.7688 183.132 59.2794 182.771 58.918C182.409 58.5566 181.92 58.3525 181.409 58.3498Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M206.11 71.2499C206.028 71.0127 205.875 70.807 205.67 70.6617C205.466 70.5164 205.221 70.4389 204.97 70.44C204.646 70.44 204.336 70.5685 204.107 70.7973C203.879 71.0261 203.75 71.3364 203.75 71.66C203.75 71.9835 203.879 72.2938 204.107 72.5226C204.336 72.7514 204.646 72.8799 204.97 72.8799C205.221 72.881 205.466 72.8035 205.67 72.6582C205.875 72.5129 206.028 72.3072 206.11 72.0699H266.31V71.2499H206.11Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M200.429 66.3799C200.334 66.1083 200.145 65.8793 199.897 65.7333C199.649 65.5873 199.357 65.5339 199.073 65.5824C198.789 65.6309 198.532 65.7782 198.346 65.9983C198.16 66.2183 198.059 66.497 198.059 66.7849C198.059 67.0729 198.16 67.3516 198.346 67.5716C198.532 67.7917 198.789 67.939 199.073 67.9875C199.357 68.036 199.649 67.9825 199.897 67.8366C200.145 67.6906 200.334 67.4616 200.429 67.1899H260.619V66.3799H200.429Z"
        fill={props.fill || 'currentColor'}
      />
      <path d="M235.029 86.5996H173.279V87.5996H235.029V86.5996Z" fill={props.fill || 'currentColor'} />
      <path
        d="M172.469 89.5397C173.817 89.5397 174.909 88.4472 174.909 87.0997C174.909 85.7521 173.817 84.6597 172.469 84.6597C171.122 84.6597 170.029 85.7521 170.029 87.0997C170.029 88.4472 171.122 89.5397 172.469 89.5397Z"
        fill="white"
      />
      <path
        d="M172.469 89.9996C171.887 89.9996 171.319 89.8269 170.835 89.5034C170.351 89.1799 169.974 88.7202 169.752 88.1824C169.53 87.6446 169.472 87.053 169.587 86.4824C169.701 85.9118 169.982 85.388 170.394 84.9772C170.806 84.5664 171.331 84.2872 171.902 84.1749C172.473 84.0626 173.064 84.1222 173.601 84.3463C174.138 84.5704 174.597 84.9487 174.919 85.4335C175.241 85.9183 175.411 86.4877 175.409 87.0696C175.407 87.8476 175.096 88.5929 174.545 89.1421C173.994 89.6913 173.247 89.9996 172.469 89.9996ZM172.469 85.1296C172.086 85.1296 171.711 85.2434 171.391 85.4566C171.072 85.6697 170.824 85.9727 170.677 86.3272C170.53 86.6817 170.492 87.0718 170.567 87.4481C170.641 87.8244 170.826 88.1701 171.097 88.4414C171.369 88.7127 171.714 88.8975 172.091 88.9724C172.467 89.0472 172.857 89.0088 173.212 88.862C173.566 88.7151 173.869 88.4665 174.082 88.1474C174.296 87.8284 174.409 87.4533 174.409 87.0696C174.401 86.5603 174.194 86.0746 173.831 85.7172C173.468 85.3598 172.979 85.1596 172.469 85.1596V85.1296Z"
        fill={props.fill || 'currentColor'}
      />
      <path d="M254.129 90.6602H192.379V91.6602H254.129V90.6602Z" fill={props.fill || 'currentColor'} />
      <path
        d="M189.53 93.5997C190.877 93.5997 191.97 92.5073 191.97 91.1597C191.97 89.8122 190.877 88.7197 189.53 88.7197C188.182 88.7197 187.09 89.8122 187.09 91.1597C187.09 92.5073 188.182 93.5997 189.53 93.5997Z"
        fill="white"
      />
      <path
        d="M189.53 94.0997C188.948 94.0997 188.38 93.9273 187.896 93.6043C187.413 93.2812 187.036 92.822 186.814 92.2848C186.591 91.7476 186.533 91.1565 186.646 90.5862C186.76 90.0159 187.04 89.492 187.451 89.0808C187.862 88.6697 188.386 88.3897 188.956 88.2762C189.527 88.1628 190.118 88.221 190.655 88.4435C191.192 88.666 191.651 89.0429 191.974 89.5264C192.297 90.0098 192.47 90.5783 192.47 91.1597C192.47 91.9395 192.16 92.6873 191.609 93.2386C191.057 93.79 190.31 94.0997 189.53 94.0997ZM189.53 89.2197C189.146 89.2197 188.771 89.3335 188.452 89.5467C188.133 89.7599 187.884 90.0628 187.738 90.4173C187.591 90.7718 187.552 91.1619 187.627 91.5382C187.702 91.9145 187.887 92.2602 188.158 92.5315C188.429 92.8028 188.775 92.9876 189.151 93.0625C189.528 93.1373 189.918 93.0989 190.272 92.9521C190.627 92.8052 190.93 92.5566 191.143 92.2375C191.356 91.9185 191.47 91.5434 191.47 91.1597C191.47 90.6452 191.265 90.1518 190.902 89.7879C190.538 89.4241 190.044 89.2197 189.53 89.2197Z"
        fill={props.fill || 'currentColor'}
      />
      <path d="M246 82.5298H184.25V83.5298H246V82.5298Z" fill={props.fill || 'currentColor'} />
      <path
        d="M181.409 85.4698C182.756 85.4698 183.849 84.3774 183.849 83.0298C183.849 81.6823 182.756 80.5898 181.409 80.5898C180.061 80.5898 178.969 81.6823 178.969 83.0298C178.969 84.3774 180.061 85.4698 181.409 85.4698Z"
        fill="white"
      />
      <path
        d="M181.408 86.0001C180.829 86.002 180.262 85.8326 179.779 85.5133C179.295 85.1941 178.917 84.7391 178.691 84.2055C178.466 83.672 178.403 83.0837 178.51 82.5144C178.618 81.9452 178.891 81.4204 179.296 81.0059C179.7 80.5915 180.219 80.3058 180.785 80.1849C181.352 80.064 181.941 80.1131 182.48 80.3261C183.019 80.5392 183.483 80.9066 183.813 81.3824C184.144 81.8581 184.327 82.4209 184.338 83.0001C184.346 83.3904 184.277 83.7783 184.133 84.1413C183.989 84.5043 183.775 84.8351 183.502 85.1143C183.23 85.3936 182.904 85.6157 182.544 85.7678C182.185 85.9199 181.799 85.9989 181.408 86.0001ZM181.408 81.1301C181.021 81.1284 180.643 81.2424 180.321 81.4576C179.999 81.6729 179.749 81.9794 179.603 82.3378C179.457 82.6962 179.422 83.0901 179.501 83.4689C179.581 83.8477 179.772 84.194 180.05 84.4634C180.328 84.7327 180.68 84.9127 181.061 84.9803C181.442 85.0479 181.835 84.9999 182.188 84.8426C182.542 84.6852 182.84 84.4257 183.045 84.0974C183.25 83.769 183.352 83.3869 183.338 83.0001C183.328 82.4943 183.12 82.0125 182.76 81.6576C182.399 81.3027 181.914 81.1026 181.408 81.1001V81.1301Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M206.11 76.9399C206.028 76.7027 205.875 76.497 205.67 76.3517C205.466 76.2064 205.221 76.1288 204.97 76.1299C204.646 76.1299 204.336 76.2584 204.107 76.4872C203.879 76.716 203.75 77.0263 203.75 77.3499C203.75 77.6735 203.879 77.9838 204.107 78.2126C204.336 78.4414 204.646 78.5699 204.97 78.5699C205.222 78.5703 205.468 78.4913 205.672 78.3441C205.877 78.1969 206.03 77.989 206.11 77.7499H266.31V76.9399H206.11Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M196.36 79.9999C196.278 79.7627 196.125 79.557 195.92 79.4117C195.716 79.2664 195.471 79.1889 195.22 79.19C194.896 79.19 194.586 79.3185 194.357 79.5473C194.129 79.7761 194 80.0864 194 80.41C194 80.7335 194.129 81.0438 194.357 81.2726C194.586 81.5014 194.896 81.6299 195.22 81.6299C195.471 81.631 195.716 81.5535 195.92 81.4082C196.125 81.2629 196.278 81.0572 196.36 80.8199H256.56V79.9999H196.36Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M198 94.8199C197.904 94.5483 197.716 94.3192 197.467 94.1733C197.219 94.0273 196.927 93.9738 196.643 94.0223C196.36 94.0708 196.102 94.2181 195.916 94.4382C195.731 94.6583 195.629 94.9369 195.629 95.2249C195.629 95.5128 195.731 95.7915 195.916 96.0116C196.102 96.2317 196.36 96.379 196.643 96.4275C196.927 96.476 197.219 96.4225 197.467 96.2765C197.716 96.1306 197.904 95.9015 198 95.6299H258.2V94.8199H198Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M209 74.1002C208.904 73.8285 208.716 73.5995 208.467 73.4535C208.219 73.3076 207.927 73.2541 207.643 73.3026C207.36 73.3511 207.102 73.4984 206.916 73.7185C206.731 73.9385 206.629 74.2172 206.629 74.5052C206.629 74.7931 206.731 75.0718 206.916 75.2919C207.102 75.5119 207.36 75.6593 207.643 75.7077C207.927 75.7562 208.219 75.7028 208.467 75.5568C208.716 75.4108 208.904 75.1818 209 74.9102H269.2V74.1002H209Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M227.179 70.4399H223.379C221.959 70.4399 220.809 71.5906 220.809 73.0099V76.8099C220.809 78.2293 221.959 79.3799 223.379 79.3799H227.179C228.598 79.3799 229.749 78.2293 229.749 76.8099V73.0099C229.749 71.5906 228.598 70.4399 227.179 70.4399Z"
        fill="white"
      />
      <path
        d="M227.179 79.8797H223.379C222.565 79.877 221.786 79.5528 221.211 78.9776C220.636 78.4024 220.311 77.6231 220.309 76.8097V72.9997C220.311 72.1863 220.636 71.4069 221.211 70.8318C221.786 70.2566 222.565 69.9323 223.379 69.9297H227.179C227.992 69.9323 228.771 70.2566 229.346 70.8318C229.922 71.4069 230.246 72.1863 230.249 72.9997V76.7997C230.249 77.6148 229.925 78.3967 229.35 78.974C228.775 79.5514 227.994 79.877 227.179 79.8797ZM223.379 70.9397C222.831 70.9397 222.306 71.1564 221.918 71.5424C221.53 71.9285 221.311 72.4524 221.309 72.9997V76.7997C221.309 77.3487 221.527 77.8752 221.915 78.2634C222.303 78.6516 222.83 78.8697 223.379 78.8697H227.179C227.728 78.8697 228.254 78.6516 228.642 78.2634C229.031 77.8752 229.249 77.3487 229.249 76.7997V72.9997C229.249 72.4507 229.031 71.9242 228.642 71.536C228.254 71.1478 227.728 70.9297 227.179 70.9297L223.379 70.9397Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M209.71 85.4697H206.73C205.311 85.4697 204.16 86.6204 204.16 88.0397V91.0297C204.16 92.4491 205.311 93.5997 206.73 93.5997H209.71C211.13 93.5997 212.28 92.4491 212.28 91.0297V88.0397C212.28 86.6204 211.13 85.4697 209.71 85.4697Z"
        fill="white"
      />
      <path
        d="M209.708 94.1H206.708C206.304 94.0974 205.905 94.0151 205.533 93.8577C205.161 93.7004 204.823 93.4712 204.54 93.1832C204.256 92.8952 204.033 92.5541 203.881 92.1794C203.73 91.8048 203.654 91.404 203.658 91V88C203.677 87.198 204.008 86.435 204.582 85.8743C205.156 85.3136 205.926 84.9998 206.728 85H209.728C210.526 85.0075 211.29 85.3244 211.859 85.884C212.428 86.4437 212.758 87.2022 212.778 88V91C212.781 91.4053 212.704 91.8072 212.551 92.1825C212.398 92.5578 212.172 92.8993 211.887 93.1873C211.602 93.4753 211.262 93.7042 210.889 93.8608C210.515 94.0174 210.114 94.0987 209.708 94.1ZM206.708 85.97C206.17 85.9751 205.654 86.19 205.271 86.569C204.889 86.9481 204.669 87.4614 204.658 88V91C204.658 91.549 204.876 92.0755 205.265 92.4637C205.653 92.8519 206.179 93.07 206.728 93.07H209.728C210.274 93.0647 210.795 92.8443 211.179 92.4567C211.563 92.0691 211.778 91.5456 211.778 91V88C211.76 87.4632 211.534 86.9545 211.148 86.5813C210.762 86.2081 210.245 85.9997 209.708 86L206.708 85.97Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M45.8001 74.0002C41.1106 74.0055 36.4914 72.86 32.3478 70.6642C28.2041 68.4685 24.6626 65.2894 22.0338 61.406C19.405 57.5226 17.7693 53.0534 17.2702 48.3906C16.7711 43.7277 17.4238 39.0136 19.1711 34.6617C20.9183 30.3099 23.7068 26.4533 27.2918 23.4302C30.8768 20.4071 35.1489 18.3099 39.7333 17.3226C44.3177 16.3353 49.0743 16.4879 53.5859 17.7672C58.0976 19.0465 62.2263 21.4134 65.6101 24.6602C69.7346 28.6177 72.5815 33.7181 73.785 39.306C74.9885 44.894 74.4935 50.7141 72.3638 56.0186C70.234 61.3231 66.5668 65.8696 61.8333 69.0738C57.0997 72.278 51.5162 73.9936 45.8001 74.0002ZM45.8001 17.6802H45.1601C38.7627 17.8249 32.6133 20.1833 27.7597 24.3536C22.9062 28.5239 19.6487 34.2481 18.5423 40.5508C17.4359 46.8535 18.5491 53.3449 21.6922 58.9189C24.8352 64.4929 29.8137 68.8047 35.7794 71.1197C41.7451 73.4346 48.3289 73.6095 54.4091 71.6145C60.4892 69.6195 65.6896 65.5781 69.1241 60.1788C72.5587 54.7795 74.0149 48.3564 73.2447 42.0038C72.4745 35.6512 69.5255 29.7622 64.9001 25.3402C59.7662 20.4251 52.9275 17.6904 45.8201 17.7102L45.8001 17.6802Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M60.0008 68.7396C57.7608 68.7396 55.0008 67.8096 51.8808 65.9896C46.8808 63.0896 41.5508 58.2296 36.8108 52.3096C26.8108 39.7596 22.8108 26.8096 27.8108 22.8296C30.2408 20.8896 34.4008 21.4596 39.5208 24.4396C44.6408 27.4196 49.8008 32.2096 54.5408 38.1296C59.2808 44.0496 62.8408 50.3296 64.5908 55.8196C66.3808 61.4696 66.0208 65.6496 63.5908 67.5996C62.5632 68.3858 61.2936 68.7889 60.0008 68.7396ZM31.3508 22.7396C30.2752 22.6832 29.2138 23.0052 28.3508 23.6496C24.0008 27.1196 28.2308 39.9996 37.5908 51.6796C42.2608 57.5096 47.5108 62.2896 52.3808 65.1296C57.0008 67.8496 60.9008 68.4696 63.0008 66.8196C65.1008 65.1696 65.3108 61.2696 63.6708 56.1296C61.9708 50.7496 58.4608 44.5796 53.8008 38.7496C49.0908 32.9196 43.8408 28.1496 39.0008 25.3096C36.0008 23.5896 33.4008 22.7096 31.3508 22.7096V22.7396Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M64.1203 67.1602L63.3503 66.5202C65.653 63.4416 67.2414 59.889 68.0003 56.1202C69.2003 50.2702 68.9203 41.1202 60.7503 30.9402C54.9203 23.6602 47.7503 20.1402 39.5403 20.5202C35.808 20.6955 32.1519 21.6385 28.8003 23.2902L28.3203 22.4202C28.5003 22.3202 47.1003 12.2802 61.5303 30.3202C77.6603 50.5002 64.2503 67.0002 64.1203 67.1602Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M51.5508 70.6303C44.6708 70.6303 35.5508 68.1003 27.2808 57.7103C12.8508 39.7103 26.7208 23.7103 26.8608 23.5703L27.6108 24.2303C25.2546 27.1316 23.5311 30.4936 22.5508 34.1003C20.3808 42.1003 22.2308 49.8003 28.0608 57.1003C36.2308 67.3103 45.0608 69.5803 51.0608 69.6903C54.8952 69.777 58.7007 69.0084 62.2008 67.4403L62.6608 68.3303C59.1638 69.8802 55.3758 70.6644 51.5508 70.6303Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M31.2494 54.4402C26.6794 54.4402 22.6495 52.8602 20.0895 49.6602C14.5295 42.7102 17.9694 30.6602 27.7695 22.8302C32.1503 19.197 37.5309 16.9793 43.1995 16.4702C48.4995 16.1502 52.9095 17.8302 55.6194 21.2302C58.3294 24.6302 58.9995 29.3002 57.5395 34.3902C55.7973 39.811 52.4495 44.5746 47.9394 48.0502C42.6594 52.2802 36.5895 54.4402 31.2494 54.4402ZM44.4795 17.4402C44.0795 17.4402 43.6694 17.4402 43.2594 17.4402C37.7929 17.9312 32.6058 20.0765 28.3894 23.5902C18.9994 31.1102 15.6495 42.5102 20.8695 49.0002C26.0895 55.4902 37.9995 54.7702 47.3195 47.2702C51.6682 43.9252 54.8948 39.3348 56.5695 34.1102C57.9594 29.3402 57.3494 24.9802 54.8395 21.8502C52.5395 19.0002 48.8995 17.4302 44.4795 17.4302V17.4402Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M35.0497 65.74C29.2897 65.74 24.1797 63.74 20.9297 59.67L21.7097 59.05C28.3697 67.37 43.3497 66.48 55.1097 57.05C66.8697 47.62 70.9997 33.23 64.3697 24.92L65.1497 24.29C72.1497 33.04 67.9297 48.09 55.7397 57.85C49.2097 63.07 41.6897 65.74 35.0497 65.74Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M39.7709 71.71C36.4108 71.774 33.0908 70.9715 30.1309 69.38L30.6209 68.51C38.3809 72.87 50.4109 70.62 59.8909 63.04C69.3709 55.46 74.1509 44.32 71.6909 35.82L72.6909 35.54C75.2609 44.42 70.2709 56.05 60.5609 63.82C54.1009 69 46.5109 71.71 39.7709 71.71Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M27.4309 44.1103C26.2142 44.1869 24.996 43.9843 23.8694 43.5181C22.7429 43.0519 21.7378 42.3343 20.9309 41.4203C19.1909 39.2403 18.9309 36.0903 20.3109 32.5403C21.9074 28.7079 24.4793 25.3603 27.7709 22.8303C30.9597 20.1787 34.7862 18.4068 38.8709 17.6903C42.6309 17.1603 45.6609 18.0603 47.4009 20.2403C49.1409 22.4203 49.4009 25.5603 48.0109 29.1103C46.4214 32.9439 43.8522 36.2924 40.5609 38.8203C37.3778 41.4942 33.5455 43.281 29.4509 44.0003C28.781 44.0844 28.106 44.1212 27.4309 44.1103ZM40.9009 18.5403C40.2685 18.5442 39.6371 18.591 39.0109 18.6803C35.0989 19.3669 31.4363 21.0706 28.3909 23.6203C25.237 26.0327 22.7744 29.2334 21.2509 32.9003C20.0309 36.1003 20.2009 38.9003 21.7109 40.7903C23.2209 42.6803 25.9209 43.4603 29.3109 42.9703C33.2263 42.2759 36.8893 40.5616 39.9309 38.0003C43.0829 35.5835 45.5478 32.3843 47.0809 28.7203C48.291 25.5103 48.0809 22.7203 46.6109 20.8203C45.8955 20.0307 45.0092 19.4149 44.0197 19.0198C43.0301 18.6247 41.9635 18.4607 40.9009 18.5403Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M25.2596 35.2699C24.7014 35.3187 24.1396 35.2365 23.6188 35.0299C23.0979 34.8233 22.6325 34.498 22.2596 34.0799C21.3196 32.9099 21.4096 31.0799 22.5196 28.8799C23.8253 26.5289 25.5984 24.47 27.7296 22.8299C29.7842 21.1177 32.1659 19.8418 34.7296 19.0799C37.0996 18.4799 38.9196 18.7899 39.8596 19.9699C41.7396 22.3199 39.3396 27.2599 34.3896 31.2199C32.3375 32.9359 29.955 34.2122 27.3896 34.9699C26.6944 35.1558 25.9791 35.2566 25.2596 35.2699ZM36.9996 19.7799C36.3542 19.79 35.7125 19.8807 35.0896 20.0499C32.6458 20.772 30.3758 21.9869 28.4196 23.6199C26.3976 25.1671 24.716 27.1143 23.4796 29.3399C22.5696 31.1199 22.4796 32.6199 23.1096 33.4599C23.7396 34.2999 25.2796 34.4599 27.2196 33.9999C29.6589 33.2793 31.9253 32.0678 33.8796 30.4399C38.2396 26.9499 40.6696 22.4399 39.1896 20.5899C38.9076 20.3008 38.5647 20.078 38.1859 19.9379C37.8071 19.7978 37.4019 19.7438 36.9996 19.7799Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M152.369 50.5703H73.6094C70.4337 50.5703 67.8594 53.1447 67.8594 56.3203V101.5C67.8594 104.676 70.4337 107.25 73.6094 107.25H152.369C155.545 107.25 158.119 104.676 158.119 101.5V56.3203C158.119 53.1447 155.545 50.5703 152.369 50.5703Z"
        fill="white"
      />
      <path
        d="M152.369 107.74H73.6194C71.9609 107.74 70.3701 107.082 69.1964 105.91C68.0227 104.739 67.362 103.149 67.3594 101.49V56.3203C67.362 54.6618 68.0227 53.0721 69.1964 51.9003C70.3701 50.7285 71.9609 50.0703 73.6194 50.0703H152.369C154.028 50.0703 155.619 50.7285 156.792 51.9003C157.966 53.0721 158.627 54.6618 158.629 56.3203V101.49C158.627 103.149 157.966 104.739 156.792 105.91C155.619 107.082 154.028 107.74 152.369 107.74ZM73.6194 51.0703C72.2261 51.0703 70.8897 51.6231 69.9035 52.6074C68.9174 53.5917 68.362 54.927 68.3594 56.3203V101.49C68.362 102.884 68.9174 104.219 69.9035 105.203C70.8897 106.188 72.2261 106.74 73.6194 106.74H152.369C153.763 106.74 155.099 106.188 156.085 105.203C157.071 104.219 157.627 102.884 157.629 101.49V56.3203C157.627 54.927 157.071 53.5917 156.085 52.6074C155.099 51.6231 153.763 51.0703 152.369 51.0703H73.6194Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M158 121.93H67.0005C66.5648 121.954 66.1301 121.869 65.7355 121.683C65.3408 121.497 64.9986 121.216 64.7397 120.865C64.4808 120.514 64.3133 120.104 64.2524 119.672C64.1914 119.24 64.2389 118.799 64.3905 118.39L68.5505 108.29H157.25L160.63 118.56C160.746 118.961 160.765 119.383 160.686 119.793C160.607 120.202 160.432 120.587 160.175 120.916C159.919 121.245 159.588 121.508 159.21 121.684C158.832 121.861 158.417 121.945 158 121.93Z"
        fill="white"
      />
      <path
        d="M158 122.43H66.9995C66.4514 122.439 65.9095 122.313 65.4213 122.064C64.9332 121.814 64.5136 121.449 64.1995 121C63.9233 120.595 63.753 120.128 63.7041 119.64C63.6552 119.153 63.7292 118.661 63.9195 118.21L68.1995 107.8H157.61L161.1 118.41C161.249 118.858 161.288 119.336 161.214 119.803C161.139 120.269 160.952 120.711 160.67 121.09C160.36 121.506 159.957 121.843 159.493 122.076C159.03 122.309 158.518 122.43 158 122.43ZM68.8695 108.79L64.8695 118.58C64.749 118.88 64.7033 119.206 64.7364 119.528C64.7695 119.85 64.8804 120.16 65.0595 120.43C65.2836 120.749 65.5838 121.008 65.933 121.183C66.2821 121.358 66.6693 121.442 67.0595 121.43H158.06C158.428 121.435 158.792 121.352 159.123 121.188C159.453 121.025 159.74 120.786 159.96 120.49C160.149 120.239 160.275 119.946 160.325 119.636C160.376 119.326 160.35 119.008 160.25 118.71L156.98 108.79H68.8695Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M158 118.79H66.9996C66.5635 118.812 66.1288 118.725 65.7344 118.538C65.34 118.351 64.9982 118.069 64.7396 117.717C64.4809 117.365 64.3136 116.955 64.2525 116.522C64.1914 116.09 64.2385 115.649 64.3896 115.24L68.5496 105.14H157.25L160.63 115.41C160.747 115.811 160.768 116.235 160.689 116.645C160.611 117.056 160.436 117.443 160.179 117.773C159.923 118.103 159.591 118.367 159.212 118.544C158.833 118.721 158.418 118.805 158 118.79Z"
        fill="white"
      />
      <path
        d="M157.999 119.29H66.9993C66.4508 119.296 65.9091 119.168 65.4224 118.915C64.9356 118.662 64.5189 118.292 64.2093 117.84C63.9331 117.435 63.7628 116.968 63.7139 116.48C63.6649 115.993 63.739 115.501 63.9293 115.05L68.2093 104.64H157.619L161.109 115.25C161.259 115.698 161.298 116.176 161.223 116.642C161.148 117.109 160.962 117.551 160.679 117.93C160.37 118.35 159.966 118.692 159.501 118.928C159.035 119.164 158.521 119.288 157.999 119.29ZM68.8693 105.64L64.8693 115.43C64.7488 115.73 64.7031 116.056 64.7362 116.378C64.7693 116.7 64.8802 117.01 65.0593 117.28C65.284 117.599 65.5843 117.857 65.9333 118.032C66.2824 118.206 66.6692 118.291 67.0593 118.28H158.059C158.433 118.277 158.8 118.184 159.131 118.01C159.461 117.836 159.745 117.586 159.959 117.28C160.149 117.031 160.274 116.739 160.325 116.431C160.376 116.122 160.35 115.806 160.249 115.51L156.979 105.58L68.8693 105.64Z"
        fill={props.fill || 'currentColor'}
      />
      <path d="M154.981 110.39H69.9609L71.4009 106.72H154.321L154.981 110.39Z" fill="white" />
      <path
        d="M155.57 110.89H69.2305L71.0605 106.22H154.74L155.57 110.89ZM70.7005 109.89H154.38L153.91 107.22H71.7405L70.7005 109.89Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M132.28 118.79H93.7096C93.5247 118.798 93.3407 118.761 93.1739 118.681C93.0071 118.6 92.8628 118.48 92.7538 118.331C92.6448 118.181 92.5746 118.007 92.5495 117.823C92.5243 117.64 92.545 117.453 92.6096 117.28L94.3696 113H132L133.43 117.35C133.479 117.523 133.487 117.706 133.452 117.883C133.416 118.06 133.339 118.226 133.226 118.367C133.114 118.508 132.969 118.62 132.804 118.694C132.64 118.767 132.46 118.8 132.28 118.79Z"
        fill="white"
      />
      <path
        d="M132.28 119.29H93.71C93.43 119.291 93.1541 119.223 92.9065 119.092C92.6589 118.961 92.4472 118.772 92.29 118.54C92.148 118.329 92.0608 118.086 92.0364 117.833C92.0119 117.58 92.051 117.324 92.15 117.09L94 112.5H132.3L133.84 117.2C133.919 117.432 133.94 117.68 133.902 117.922C133.864 118.164 133.767 118.394 133.62 118.59C133.466 118.803 133.265 118.977 133.033 119.098C132.8 119.22 132.542 119.285 132.28 119.29ZM94.71 113.5L93.07 117.5C93.0351 117.585 93.0223 117.677 93.0328 117.768C93.0433 117.859 93.0768 117.946 93.13 118.02C93.195 118.113 93.2818 118.19 93.3829 118.242C93.484 118.294 93.5962 118.321 93.71 118.32H132.28C132.39 118.321 132.499 118.296 132.598 118.247C132.697 118.199 132.783 118.128 132.85 118.04C132.905 117.97 132.941 117.888 132.955 117.8C132.969 117.713 132.96 117.623 132.93 117.54L131.61 113.54L94.71 113.5Z"
        fill={props.fill || 'currentColor'}
      />
      <path d="M152.879 57.9102H73.1094V99.8902H152.879V57.9102Z" fill="white" />
      <path
        d="M153.379 100.39H72.6094V57.3896H153.379V100.39ZM73.6094 99.3896H152.379V58.3896H73.6094V99.3896Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M78.0001 69.48C77.7623 69.4893 77.5251 69.4498 77.3032 69.3638C77.0814 69.2778 76.8795 69.1472 76.7101 68.98C76.5368 68.7839 76.407 68.5533 76.3295 68.3033C76.2519 68.0534 76.2282 67.7898 76.2601 67.53C76.3765 66.0178 76.6411 64.5206 77.0501 63.06C77.2066 62.4787 77.5601 61.9698 78.0501 61.62C78.4485 61.3329 78.929 61.1821 79.4201 61.19H80.0001C80.2394 61.1799 80.4783 61.2191 80.702 61.3051C80.9256 61.3911 81.1291 61.5221 81.3001 61.69C81.471 61.8856 81.5993 62.1147 81.6767 62.3626C81.7542 62.6106 81.7792 62.8719 81.7501 63.13C81.6337 64.6489 81.3691 66.1527 80.9601 67.62C80.7941 68.1969 80.4426 68.703 79.9601 69.06C79.5586 69.3398 79.0794 69.4867 78.5901 69.48H78.0001ZM79.3901 61.71C78.9571 61.7084 78.5371 61.8571 78.2015 62.1306C77.866 62.4042 77.6357 62.7857 77.5501 63.21C77.1437 64.6299 76.8823 66.0874 76.7701 67.56C76.7486 67.75 76.765 67.9424 76.8182 68.126C76.8714 68.3096 76.9604 68.4809 77.0801 68.63C77.1997 68.7517 77.3432 68.8473 77.5015 68.911C77.6598 68.9747 77.8295 69.005 78.0001 69H78.5901C79.023 69.0016 79.4431 68.853 79.7786 68.5794C80.1142 68.3059 80.3444 67.9244 80.4301 67.5C80.8282 66.0782 81.0894 64.6216 81.2101 63.15C81.25 62.7666 81.1388 62.3827 80.9001 62.08C80.7757 61.964 80.6293 61.8741 80.4696 61.8157C80.3098 61.7573 80.1399 61.7315 79.9701 61.74L79.3901 61.71Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M83.8195 69.5001L83.3195 69.4101L84.6595 61.8301L82.9395 62.3801L82.7695 61.9001L85.2995 61.0601L83.8195 69.5001Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M88.0909 69.4801C87.8545 69.492 87.6182 69.4556 87.3964 69.373C87.1746 69.2905 86.972 69.1636 86.8009 69C86.6279 68.8034 86.4978 68.573 86.4186 68.3234C86.3394 68.0738 86.3129 67.8104 86.3409 67.5501C86.4657 66.0381 86.7335 64.5414 87.1409 63.0801C87.2975 62.4988 87.6509 61.9898 88.1409 61.64C88.5362 61.3543 89.0131 61.2035 89.5009 61.21H90.0909C90.3303 61.2 90.5692 61.2391 90.7928 61.3251C91.0164 61.4111 91.22 61.5422 91.3909 61.71C91.5618 61.9057 91.6901 62.1347 91.7675 62.3827C91.845 62.6306 91.87 62.8919 91.8409 63.1501C91.7021 64.6631 91.4208 66.1598 91.0009 67.6201C90.8349 68.1969 90.4835 68.703 90.0009 69.06C89.5994 69.3399 89.1202 69.4868 88.6309 69.4801H88.0909ZM89.4709 61.71C89.0392 61.7088 88.6205 61.8579 88.2866 62.1315C87.9527 62.4052 87.7244 62.7865 87.6409 63.21C87.2345 64.6299 86.9731 66.0874 86.8609 67.56C86.8395 67.75 86.8558 67.9424 86.909 68.126C86.9622 68.3096 87.0512 68.4809 87.1709 68.63C87.2931 68.7463 87.4377 68.8364 87.5958 68.8949C87.754 68.9533 87.9225 68.9789 88.0909 68.97H88.6809C89.1138 68.9717 89.5339 68.823 89.8694 68.5495C90.205 68.2759 90.4352 67.8944 90.5209 67.47C90.9141 66.0471 91.1752 64.591 91.3009 63.12C91.3437 62.738 91.2361 62.3542 91.0009 62.05C90.8765 61.934 90.7301 61.8441 90.5704 61.7857C90.4106 61.7273 90.2408 61.7016 90.0709 61.71H89.4709Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M93.9001 69.5001L93.4001 69.4101L94.7301 61.8301L93.0001 62.3801L92.8401 61.9001L95.3701 61.0601L93.9001 69.5001Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M98.1707 69.4802C97.933 69.4895 97.6958 69.4499 97.4739 69.3639C97.252 69.2779 97.0501 69.1473 96.8807 68.9802C96.7057 68.7849 96.5741 68.5546 96.4948 68.3046C96.4155 68.0546 96.3903 67.7906 96.4207 67.5302C96.5455 66.0182 96.8134 64.5215 97.2207 63.0602C97.3773 62.4789 97.7308 61.9699 98.2207 61.6202C98.6161 61.3344 99.093 61.1836 99.5807 61.1902H100.171C100.409 61.1796 100.646 61.2187 100.868 61.3047C101.09 61.3908 101.292 61.522 101.461 61.6902C101.635 61.8839 101.766 62.1125 101.845 62.3607C101.925 62.609 101.95 62.8712 101.921 63.1302C101.8 64.6484 101.535 66.1517 101.131 67.6202C100.965 68.197 100.613 68.7031 100.131 69.0602C99.7278 69.337 99.2496 69.4836 98.7607 69.4802H98.1707ZM99.5507 61.7102C99.119 61.709 98.7004 61.858 98.3665 62.1316C98.0326 62.4053 97.8043 62.7866 97.7207 63.2102C97.3143 64.63 97.053 66.0875 96.9407 67.5602C96.9193 67.7501 96.9357 67.9425 96.9889 68.1261C97.0421 68.3097 97.1311 68.481 97.2507 68.6301C97.3729 68.7464 97.5175 68.8365 97.6757 68.895C97.8339 68.9534 98.0023 68.979 98.1707 68.9702H98.7607C99.1937 68.9718 99.6137 68.8231 99.9493 68.5496C100.285 68.276 100.515 67.8945 100.601 67.4702C100.994 66.0472 101.255 64.5911 101.381 63.1202C101.421 62.7367 101.309 62.3528 101.071 62.0502C100.946 61.9341 100.8 61.8442 100.64 61.7858C100.48 61.7274 100.311 61.7017 100.141 61.7102H99.5507Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M103.999 69.5001L103.499 69.4101L104.829 61.8301L103.109 62.3801L102.949 61.9001L105.479 61.0601L103.999 69.5001Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M108.49 62.4101L108.03 61.8801L107.9 62.6901L107.38 62.6001L107.5 61.9301L106.9 62.4401L106.56 62.0501L107.18 61.5201H106.49V61.0001H107.33L106.87 60.4401L107.27 60.1101L107.72 60.6801L107.88 59.8101L108.38 59.9201L108.27 60.6101L108.88 60.0801L109.22 60.4701L108.59 61.0001H109.27V61.5201H108.41L108.88 62.0801L108.49 62.4101Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M110.54 69.8798H110.25V69.5998L110 69.3298L110.14 68.5698H110.72L110.54 69.8798ZM111.07 66.0598L110.43 65.9698L110.59 65.0298L111.25 65.1198L111.07 66.0598Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M114 61.1602V63.2202C113.97 64.6669 113.717 66.1004 113.25 67.4702L112.48 69.5402L111.99 69.3502L112.77 67.2902C113.211 65.9838 113.454 64.6186 113.49 63.2402V61.1602H114Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M79.0991 77.58C79.3504 77.5784 79.599 77.6323 79.8271 77.7377C80.0553 77.8431 80.2574 77.9976 80.4191 78.19L80.9991 75L81.4991 75.1L80.7991 79.1L80.2391 82.27C80.2391 82.39 80.3591 82.54 80.5791 82.76L80.2191 83.12C80.0218 82.9685 79.8668 82.7687 79.7691 82.54C79.6807 82.6194 79.5871 82.6929 79.4891 82.76C79.1184 83.0173 78.6802 83.1599 78.2291 83.17H77.9591C77.5369 83.1864 77.1253 83.0362 76.8129 82.7519C76.5005 82.4675 76.3124 82.0718 76.2891 81.65C76.2928 80.7496 76.4379 79.8554 76.7191 79C76.8985 78.5937 77.1898 78.2469 77.5591 78C77.9289 77.7397 78.367 77.5936 78.8191 77.58H79.0991ZM78.8191 78.1C78.4755 78.1175 78.1434 78.2295 77.8593 78.4235C77.5753 78.6176 77.3503 78.8863 77.2091 79.2C76.9356 79.985 76.7904 80.8089 76.7791 81.64C76.8046 81.9247 76.9399 82.1884 77.1564 82.375C77.373 82.5617 77.6537 82.6567 77.9391 82.64H78.2091C78.5552 82.6228 78.8901 82.5112 79.1774 82.3173C79.4647 82.1234 79.6935 81.8546 79.8391 81.54L80.2591 79.1C80.2336 78.8153 80.0982 78.5516 79.8817 78.365C79.6652 78.1783 79.3845 78.0833 79.0991 78.1H78.8191Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M84.0003 83.1999C83.5762 83.2137 83.1635 83.0616 82.8498 82.7759C82.5361 82.4902 82.3461 82.0934 82.3203 81.6699C82.3297 80.7618 82.4816 79.8608 82.7703 78.9999C82.9529 78.5956 83.2436 78.2495 83.6103 77.9999C83.9854 77.7439 84.4263 77.6015 84.8803 77.5899H85.1603C85.4077 77.5886 85.6524 77.6417 85.877 77.7454C86.1016 77.8491 86.3007 78.0008 86.4603 78.1899L86.5703 77.6499L87.0803 77.7399L86.2703 82.2899C86.2703 82.4099 86.3903 82.5499 86.6103 82.7799L86.2603 83.1399C86.0605 82.9843 85.9025 82.7816 85.8003 82.5499C85.7146 82.6353 85.6208 82.7123 85.5203 82.7799C85.1488 83.0356 84.7111 83.178 84.2603 83.1899L84.0003 83.1999ZM84.9003 78.1099C84.5544 78.1233 84.2192 78.2335 83.9328 78.428C83.6465 78.6224 83.4204 78.8933 83.2803 79.2099C83.0045 80.0015 82.856 80.8318 82.8403 81.6699C82.8658 81.9546 83.0012 82.2182 83.2177 82.4049C83.4342 82.5916 83.7149 82.6866 84.0003 82.6699H84.2803C84.6213 82.6526 84.9515 82.5442 85.2364 82.3561C85.5213 82.1679 85.7506 81.9067 85.9003 81.5999L86.3403 79.0699C86.3056 78.7889 86.1677 78.5309 85.9532 78.3461C85.7388 78.1612 85.4633 78.0628 85.1803 78.0699L84.9003 78.1099Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M90.4703 78.64H89.3003L88.6203 82.52C88.6203 82.64 88.7403 82.79 88.9503 82.99L88.5903 83.34C88.3303 83.09 88.0503 82.81 88.1203 82.43L88.7803 78.64H88.2703V78.13H88.8703L89.4303 75L89.9303 75.09L89.3903 78.09H90.4703V78.64Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M92.5606 83.1999C92.1376 83.2136 91.7259 83.0612 91.4138 82.7752C91.1017 82.4893 90.9139 82.0925 90.8906 81.6699C90.8964 80.7598 91.0553 79.8572 91.3606 78.9999C91.5359 78.594 91.824 78.2469 92.1906 77.9999C92.5657 77.7439 93.0067 77.6015 93.4606 77.5899H93.7406C93.9881 77.588 94.233 77.6408 94.4577 77.7445C94.6825 77.8482 94.8815 78.0003 95.0406 78.1899L95.1506 77.6499L95.6606 77.7399L94.8506 82.2899C94.8506 82.4099 94.9706 82.5499 95.1906 82.7799L94.8406 83.1399C94.6408 82.9843 94.4828 82.7816 94.3806 82.5499C94.2949 82.6354 94.2011 82.7124 94.1006 82.7799C93.7299 83.0372 93.2917 83.1798 92.8406 83.1899L92.5606 83.1999ZM93.4606 78.1099C93.115 78.1245 92.7803 78.2351 92.4942 78.4295C92.208 78.6238 91.9817 78.894 91.8406 79.2099C91.5649 80.0015 91.4164 80.8318 91.4006 81.6699C91.4261 81.9546 91.5615 82.2183 91.778 82.4049C91.9945 82.5916 92.2752 82.6866 92.5606 82.6699H92.8406C93.1819 82.6537 93.5125 82.5457 93.7976 82.3574C94.0827 82.1691 94.3118 81.9074 94.4606 81.5999L94.9006 79.0699C94.866 78.7889 94.728 78.5309 94.5136 78.3461C94.2991 78.1612 94.0236 78.0628 93.7406 78.0699L93.4606 78.1099Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M100.539 80.3001H96.6895V79.7901H100.539V80.3001ZM100.879 78.3601H96.9995V77.8501H100.849L100.879 78.3601Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M102.42 83.2701L101.91 83.1801L101.98 82.8001C102.146 82.2588 102.367 81.7361 102.64 81.2401L104.84 76.9101C105.103 76.4249 105.311 75.9117 105.46 75.3801L105.55 74.8901L106.06 75.0001L105.98 75.4701C105.805 76.0466 105.578 76.6057 105.3 77.1401L103.09 81.4801C102.848 81.9323 102.644 82.404 102.48 82.8901L102.42 83.2701Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M107.61 83.2701L107.09 83.1801L107.17 82.8001C107.331 82.2572 107.553 81.734 107.83 81.2401L110.03 76.9101C110.289 76.4229 110.497 75.9102 110.65 75.3801L110.74 74.8901L111.25 75.0001L111.16 75.4701C110.985 76.0451 110.761 76.6038 110.49 77.1401L108.28 81.4801C108.036 81.9329 107.829 82.4044 107.66 82.8901L107.61 83.2701Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M113.441 81.4499L112.441 79.5699L112.311 79.3499V78.8299C112.398 78.762 112.479 78.6849 112.551 78.5999L114.271 76.6699L114.651 77.0099L113.001 78.9299C112.952 79.003 112.895 79.0701 112.831 79.1299C112.831 79.1799 112.901 79.2599 112.951 79.3299L113.951 81.2099L113.441 81.4499Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M116.851 81.4499L115.851 79.5699L115.721 79.3499V78.8299C115.809 78.762 115.889 78.6849 115.961 78.5999L117.681 76.6699L118.061 77.0099L116.351 78.9299C116.299 79.0007 116.242 79.0676 116.181 79.1299C116.222 79.1942 116.258 79.261 116.291 79.3299L117.291 81.2099L116.851 81.4499Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M120.861 83.1702C120.623 83.1795 120.386 83.14 120.164 83.054C119.942 82.968 119.74 82.8374 119.571 82.6702C119.395 82.4734 119.263 82.2415 119.184 81.9898C119.104 81.738 119.079 81.4723 119.111 81.2102C119.233 79.702 119.497 78.2087 119.901 76.7502C120.063 76.1714 120.415 75.664 120.901 75.3102C121.3 75.0256 121.78 74.8751 122.271 74.8802H122.861C123.099 74.8709 123.336 74.9105 123.558 74.9965C123.779 75.0825 123.981 75.2131 124.151 75.3802C124.325 75.574 124.456 75.8026 124.535 76.0508C124.615 76.2991 124.64 76.5613 124.611 76.8202C124.487 78.3356 124.219 79.8357 123.811 81.3002C123.649 81.8791 123.297 82.3865 122.811 82.7402C122.415 83.026 121.939 83.1768 121.451 83.1702H120.861ZM122.241 75.3902C121.808 75.3886 121.388 75.5373 121.052 75.8108C120.717 76.0844 120.486 76.4659 120.401 76.8902C120.005 78.3125 119.744 79.769 119.621 81.2402C119.581 81.6237 119.692 82.0076 119.931 82.3102C120.055 82.4263 120.202 82.5162 120.361 82.5746C120.521 82.633 120.691 82.6587 120.861 82.6502H121.451C121.883 82.6534 122.303 82.5051 122.637 82.2311C122.971 81.9571 123.199 81.5747 123.281 81.1502C123.685 79.7299 123.947 78.2727 124.061 76.8002C124.082 76.6103 124.066 76.4179 124.013 76.2343C123.959 76.0506 123.87 75.8793 123.751 75.7302C123.629 75.614 123.484 75.5239 123.326 75.4654C123.168 75.4069 122.999 75.3814 122.831 75.3902H122.241Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M126.659 83.18L126.159 83.09L127.499 75.51L125.779 76.07L125.619 75.58L128.149 74.75L126.659 83.18Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M130.94 83.1499C130.52 83.1631 130.108 83.0442 129.76 82.8099C129.598 82.707 129.463 82.569 129.362 82.4062C129.262 82.2435 129.199 82.0601 129.18 81.8699H129.69C129.69 82.2999 130.29 82.6699 130.94 82.6699H131.23C131.554 82.6774 131.875 82.6002 132.161 82.4459C132.447 82.2916 132.688 82.0655 132.86 81.7899C133.104 81.1518 133.226 80.4732 133.22 79.7899C133.22 79.3599 132.61 78.9899 131.97 78.9899H129.97L131.12 74.8799H134.23V75.3899H131.5L130.65 78.4799H132C132.417 78.4717 132.826 78.5941 133.17 78.8299C133.332 78.9301 133.469 79.0659 133.572 79.227C133.674 79.3881 133.738 79.5703 133.76 79.7599C133.778 80.5128 133.642 81.2615 133.36 81.9599C133.185 82.3276 132.893 82.6267 132.53 82.8099C132.142 83.0301 131.705 83.1472 131.26 83.1499H130.94Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M135.33 83.2699L135.23 82.7599L137.12 82.3699C137.541 82.2583 137.923 82.0347 138.225 81.723C138.528 81.4112 138.741 81.0231 138.84 80.5999L139.14 78.9299C139.048 79.0079 138.948 79.075 138.84 79.1299C138.462 79.3348 138.04 79.4446 137.61 79.4499H137.34C136.929 79.4527 136.525 79.3345 136.18 79.1099C136.024 79.0119 135.893 78.8785 135.797 78.7204C135.702 78.5623 135.645 78.3841 135.63 78.1999C135.603 77.4728 135.726 76.7478 135.99 76.0699C136.163 75.7032 136.456 75.4067 136.82 75.2299C137.194 75.0125 137.618 74.8954 138.05 74.8899H138.33C138.74 74.883 139.141 75.0016 139.48 75.2299C139.634 75.3328 139.765 75.467 139.863 75.6239C139.961 75.7807 140.025 75.9566 140.05 76.1399C140.058 76.539 140.025 76.9378 139.95 77.3299L139.36 80.6799C139.247 81.208 138.986 81.6934 138.61 82.0806C138.233 82.4679 137.755 82.7414 137.23 82.8699L135.33 83.2699ZM138.05 75.4099C137.735 75.4071 137.425 75.4834 137.147 75.6319C136.869 75.7804 136.633 75.9964 136.46 76.2599C136.239 76.8719 136.13 77.5191 136.14 78.1699C136.14 78.5799 136.71 78.9299 137.34 78.9299H137.61C137.927 78.9396 138.24 78.8661 138.519 78.717C138.798 78.5679 139.033 78.3482 139.2 78.0799C139.431 77.4771 139.544 76.8353 139.53 76.1899C139.53 75.7599 138.95 75.4099 138.33 75.4099H138.05Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M141.8 83.1501L141.3 83.0601L141.75 80.4801C142.076 79.0497 142.764 77.7273 143.75 76.6401L145.02 75.3601H141.02V74.8501H146.22L144.15 77.0001C143.222 78.0049 142.568 79.2306 142.25 80.5601L141.8 83.1501Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M76.76 96.6501L76.25 96.5601L77.6 88.8901L78.12 88.9801L76.76 96.6501Z"
        fill={props.fill || 'currentColor'}
      />
      <path d="M90.6501 27.69L73.5801 20.3L88.5201 9.20996L90.6501 27.69Z" fill="white" />
      <path
        d="M91.2401 28.4898L72.5901 20.4098L88.9101 8.2998L91.2401 28.4898ZM74.5801 20.1798L90.0501 26.8798L88.1201 10.1298L74.5801 20.1798Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M91.25 30.0698C92.3546 30.0698 93.25 29.1744 93.25 28.0698C93.25 26.9653 92.3546 26.0698 91.25 26.0698C90.1454 26.0698 89.25 26.9653 89.25 28.0698C89.25 29.1744 90.1454 30.0698 91.25 30.0698Z"
        fill="white"
      />
      <path
        d="M91.25 30.5698C90.7555 30.5698 90.2722 30.4232 89.8611 30.1485C89.45 29.8738 89.1295 29.4833 88.9403 29.0265C88.7511 28.5697 88.7016 28.0671 88.798 27.5821C88.8945 27.0971 89.1326 26.6517 89.4822 26.3021C89.8319 25.9524 90.2773 25.7143 90.7623 25.6179C91.2472 25.5214 91.7499 25.5709 92.2067 25.7601C92.6635 25.9493 93.054 26.2698 93.3287 26.6809C93.6034 27.092 93.75 27.5754 93.75 28.0698C93.7474 28.7321 93.4831 29.3664 93.0149 29.8347C92.5466 30.303 91.9122 30.5672 91.25 30.5698ZM91.25 26.5698C90.9533 26.5698 90.6633 26.6578 90.4166 26.8226C90.17 26.9874 89.9777 27.2217 89.8642 27.4958C89.7507 27.7699 89.7209 28.0715 89.7788 28.3625C89.8367 28.6534 89.9796 28.9207 90.1893 29.1305C90.3991 29.3403 90.6664 29.4831 90.9574 29.541C91.2483 29.5989 91.5499 29.5692 91.824 29.4556C92.0981 29.3421 92.3324 29.1499 92.4972 28.9032C92.662 28.6565 92.75 28.3665 92.75 28.0698C92.75 27.672 92.592 27.2905 92.3107 27.0092C92.0294 26.7279 91.6478 26.5698 91.25 26.5698Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M88.25 11.0698C89.3546 11.0698 90.25 10.1744 90.25 9.06982C90.25 7.96525 89.3546 7.06982 88.25 7.06982C87.1454 7.06982 86.25 7.96525 86.25 9.06982C86.25 10.1744 87.1454 11.0698 88.25 11.0698Z"
        fill="white"
      />
      <path
        d="M88.25 11.5698C87.7555 11.5698 87.2722 11.4232 86.8611 11.1485C86.45 10.8738 86.1295 10.4833 85.9403 10.0265C85.7511 9.56972 85.7016 9.06705 85.798 8.5821C85.8945 8.09715 86.1326 7.65169 86.4822 7.30206C86.8319 6.95243 87.2773 6.71433 87.7623 6.61786C88.2472 6.5214 88.7499 6.57091 89.2067 6.76013C89.6635 6.94935 90.054 7.26978 90.3287 7.6809C90.6034 8.09202 90.75 8.57537 90.75 9.06983C90.7474 9.73206 90.4831 10.3664 90.0149 10.8347C89.5466 11.303 88.9122 11.5672 88.25 11.5698ZM88.25 7.56983C87.9533 7.56983 87.6633 7.6578 87.4166 7.82262C87.17 7.98744 86.9777 8.22171 86.8642 8.4958C86.7507 8.76989 86.7209 9.07149 86.7788 9.36246C86.8367 9.65343 86.9796 9.92071 87.1893 10.1305C87.3991 10.3403 87.6664 10.4831 87.9574 10.541C88.2483 10.5989 88.5499 10.5692 88.824 10.4556C89.0981 10.3421 89.3324 10.1499 89.4972 9.90318C89.662 9.65651 89.75 9.3665 89.75 9.06983C89.75 8.672 89.592 8.29047 89.3107 8.00917C89.0294 7.72786 88.6478 7.56983 88.25 7.56983Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M72.25 22.0698C73.3546 22.0698 74.25 21.1744 74.25 20.0698C74.25 18.9653 73.3546 18.0698 72.25 18.0698C71.1454 18.0698 70.25 18.9653 70.25 20.0698C70.25 21.1744 71.1454 22.0698 72.25 22.0698Z"
        fill="white"
      />
      <path
        d="M72.25 22.5698C71.7555 22.5698 71.2722 22.4232 70.8611 22.1485C70.45 21.8738 70.1295 21.4833 69.9403 21.0265C69.7511 20.5697 69.7016 20.0671 69.798 19.5821C69.8945 19.0971 70.1326 18.6517 70.4822 18.3021C70.8319 17.9524 71.2773 17.7143 71.7623 17.6179C72.2472 17.5214 72.7499 17.5709 73.2067 17.7601C73.6635 17.9493 74.054 18.2698 74.3287 18.6809C74.6034 19.092 74.75 19.5754 74.75 20.0698C74.7474 20.7321 74.4831 21.3664 74.0149 21.8347C73.5466 22.303 72.9122 22.5672 72.25 22.5698ZM72.25 18.5698C71.9533 18.5698 71.6633 18.6578 71.4166 18.8226C71.17 18.9874 70.9777 19.2217 70.8642 19.4958C70.7507 19.7699 70.7209 20.0715 70.7788 20.3625C70.8367 20.6534 70.9796 20.9207 71.1893 21.1305C71.3991 21.3403 71.6664 21.4831 71.9574 21.541C72.2483 21.5989 72.5499 21.5692 72.824 21.4556C73.0981 21.3421 73.3324 21.1499 73.4972 20.9032C73.662 20.6565 73.75 20.3665 73.75 20.0698C73.75 19.672 73.592 19.2905 73.3107 19.0092C73.0294 18.7279 72.6478 18.5698 72.25 18.5698Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M25.5608 106.06L14.8008 98.16L16.2608 84.89L28.4908 79.52L39.2508 87.43L37.7808 100.69L25.5608 106.06ZM15.8608 97.7L25.6908 104.92L36.8508 100L38.1908 87.88L28.3608 80.66L17.1908 85.58L15.8608 97.7Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M28.25 82.0698C29.3546 82.0698 30.25 81.1744 30.25 80.0698C30.25 78.9653 29.3546 78.0698 28.25 78.0698C27.1454 78.0698 26.25 78.9653 26.25 80.0698C26.25 81.1744 27.1454 82.0698 28.25 82.0698Z"
        fill="white"
      />
      <path
        d="M28.25 82.5698C27.7555 82.5698 27.2722 82.4232 26.8611 82.1485C26.45 81.8738 26.1295 81.4834 25.9403 81.0265C25.7511 80.5697 25.7016 80.0671 25.798 79.5821C25.8945 79.0972 26.1326 78.6517 26.4822 78.3021C26.8319 77.9524 27.2773 77.7143 27.7623 77.6179C28.2472 77.5214 28.7499 77.5709 29.2067 77.7601C29.6635 77.9493 30.054 78.2698 30.3287 78.6809C30.6034 79.092 30.75 79.5754 30.75 80.0698C30.7474 80.7321 30.4831 81.3664 30.0149 81.8347C29.5466 82.303 28.9122 82.5672 28.25 82.5698ZM28.25 78.5698C27.9533 78.5698 27.6633 78.6578 27.4166 78.8226C27.17 78.9874 26.9777 79.2217 26.8642 79.4958C26.7507 79.7699 26.7209 80.0715 26.7788 80.3625C26.8367 80.6534 26.9796 80.9207 27.1893 81.1305C27.3991 81.3403 27.6664 81.4831 27.9574 81.541C28.2483 81.5989 28.5499 81.5692 28.824 81.4556C29.0981 81.3421 29.3324 81.1499 29.4972 80.9032C29.662 80.6565 29.75 80.3665 29.75 80.0698C29.75 79.672 29.592 79.2905 29.3107 79.0092C29.0294 78.7279 28.6478 78.5698 28.25 78.5698Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M17.25 87.0698C18.3546 87.0698 19.25 86.1744 19.25 85.0698C19.25 83.9653 18.3546 83.0698 17.25 83.0698C16.1454 83.0698 15.25 83.9653 15.25 85.0698C15.25 86.1744 16.1454 87.0698 17.25 87.0698Z"
        fill="white"
      />
      <path
        d="M17.25 87.5698C16.7555 87.5698 16.2722 87.4232 15.8611 87.1485C15.45 86.8738 15.1295 86.4834 14.9403 86.0265C14.7511 85.5697 14.7016 85.0671 14.798 84.5821C14.8945 84.0972 15.1326 83.6517 15.4822 83.3021C15.8319 82.9524 16.2773 82.7143 16.7623 82.6179C17.2472 82.5214 17.7499 82.5709 18.2067 82.7601C18.6635 82.9493 19.054 83.2698 19.3287 83.6809C19.6034 84.092 19.75 84.5754 19.75 85.0698C19.7474 85.7321 19.4831 86.3664 19.0149 86.8347C18.5466 87.303 17.9122 87.5672 17.25 87.5698ZM17.25 83.5698C16.9533 83.5698 16.6633 83.6578 16.4166 83.8226C16.17 83.9874 15.9777 84.2217 15.8642 84.4958C15.7507 84.7699 15.7209 85.0715 15.7788 85.3625C15.8367 85.6534 15.9796 85.9207 16.1893 86.1305C16.3991 86.3403 16.6664 86.4831 16.9574 86.541C17.2483 86.5989 17.5499 86.5692 17.824 86.4556C18.0981 86.3421 18.3324 86.1499 18.4972 85.9032C18.662 85.6565 18.75 85.3665 18.75 85.0698C18.75 84.672 18.592 84.2905 18.3107 84.0092C18.0294 83.7279 17.6478 83.5698 17.25 83.5698Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M16.25 100.07C17.3546 100.07 18.25 99.1744 18.25 98.0698C18.25 96.9653 17.3546 96.0698 16.25 96.0698C15.1454 96.0698 14.25 96.9653 14.25 98.0698C14.25 99.1744 15.1454 100.07 16.25 100.07Z"
        fill="white"
      />
      <path
        d="M16.25 100.57C15.7555 100.57 15.2722 100.423 14.8611 100.149C14.45 99.8738 14.1295 99.4834 13.9403 99.0265C13.7511 98.5697 13.7016 98.0671 13.798 97.5821C13.8945 97.0972 14.1326 96.6517 14.4822 96.3021C14.8319 95.9524 15.2773 95.7143 15.7623 95.6179C16.2472 95.5214 16.7499 95.5709 17.2067 95.7601C17.6635 95.9493 18.054 96.2698 18.3287 96.6809C18.6034 97.092 18.75 97.5754 18.75 98.0698C18.7474 98.7321 18.4831 99.3664 18.0149 99.8347C17.5466 100.303 16.9122 100.567 16.25 100.57ZM16.25 96.5698C15.9533 96.5698 15.6633 96.6578 15.4166 96.8226C15.17 96.9874 14.9777 97.2217 14.8642 97.4958C14.7507 97.7699 14.7209 98.0715 14.7788 98.3625C14.8367 98.6534 14.9796 98.9207 15.1893 99.1305C15.3991 99.3403 15.6664 99.4831 15.9574 99.541C16.2483 99.5989 16.5499 99.5692 16.824 99.4556C17.0981 99.3421 17.3324 99.1499 17.4972 98.9032C17.662 98.6565 17.75 98.3665 17.75 98.0698C17.75 97.672 17.592 97.2905 17.3107 97.0092C17.0294 96.7279 16.6478 96.5698 16.25 96.5698Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M25.25 107.07C26.3546 107.07 27.25 106.174 27.25 105.07C27.25 103.965 26.3546 103.07 25.25 103.07C24.1454 103.07 23.25 103.965 23.25 105.07C23.25 106.174 24.1454 107.07 25.25 107.07Z"
        fill="white"
      />
      <path
        d="M25.25 107.57C24.7555 107.57 24.2722 107.423 23.8611 107.149C23.45 106.874 23.1295 106.483 22.9403 106.027C22.7511 105.57 22.7016 105.067 22.798 104.582C22.8945 104.097 23.1326 103.652 23.4822 103.302C23.8319 102.952 24.2773 102.714 24.7623 102.618C25.2472 102.521 25.7499 102.571 26.2067 102.76C26.6635 102.949 27.054 103.27 27.3287 103.681C27.6034 104.092 27.75 104.575 27.75 105.07C27.7474 105.732 27.4831 106.366 27.0149 106.835C26.5466 107.303 25.9122 107.567 25.25 107.57ZM25.25 103.57C24.9533 103.57 24.6633 103.658 24.4166 103.823C24.17 103.987 23.9777 104.222 23.8642 104.496C23.7507 104.77 23.7209 105.071 23.7788 105.362C23.8367 105.653 23.9796 105.921 24.1893 106.13C24.3991 106.34 24.6664 106.483 24.9574 106.541C25.2483 106.599 25.5499 106.569 25.824 106.456C26.0981 106.342 26.3324 106.15 26.4972 105.903C26.662 105.657 26.75 105.366 26.75 105.07C26.75 104.672 26.592 104.29 26.3107 104.009C26.0294 103.728 25.6478 103.57 25.25 103.57Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M37.25 102.07C38.3546 102.07 39.25 101.174 39.25 100.07C39.25 98.9653 38.3546 98.0698 37.25 98.0698C36.1454 98.0698 35.25 98.9653 35.25 100.07C35.25 101.174 36.1454 102.07 37.25 102.07Z"
        fill="white"
      />
      <path
        d="M37.25 102.57C36.7555 102.57 36.2722 102.423 35.8611 102.149C35.4499 101.874 35.1295 101.483 34.9403 101.027C34.7511 100.57 34.7016 100.067 34.798 99.5821C34.8945 99.0972 35.1326 98.6517 35.4822 98.3021C35.8319 97.9524 36.2773 97.7143 36.7623 97.6179C37.2472 97.5214 37.7499 97.5709 38.2067 97.7601C38.6635 97.9493 39.054 98.2698 39.3287 98.6809C39.6034 99.092 39.75 99.5754 39.75 100.07C39.7474 100.732 39.4831 101.366 39.0149 101.835C38.5466 102.303 37.9122 102.567 37.25 102.57ZM37.25 98.5698C36.9533 98.5698 36.6633 98.6578 36.4166 98.8226C36.17 98.9874 35.9777 99.2217 35.8642 99.4958C35.7507 99.7699 35.7209 100.071 35.7788 100.362C35.8367 100.653 35.9796 100.921 36.1893 101.13C36.3991 101.34 36.6664 101.483 36.9574 101.541C37.2483 101.599 37.5499 101.569 37.824 101.456C38.0981 101.342 38.3324 101.15 38.4972 100.903C38.662 100.657 38.75 100.366 38.75 100.07C38.75 99.672 38.592 99.2905 38.3107 99.0092C38.0294 98.7279 37.6478 98.5698 37.25 98.5698Z"
        fill={props.fill || 'currentColor'}
      />
      <path
        d="M38.25 89.0698C39.3546 89.0698 40.25 88.1744 40.25 87.0698C40.25 85.9653 39.3546 85.0698 38.25 85.0698C37.1454 85.0698 36.25 85.9653 36.25 87.0698C36.25 88.1744 37.1454 89.0698 38.25 89.0698Z"
        fill="white"
      />
      <path
        d="M38.25 89.5698C37.7555 89.5698 37.2722 89.4232 36.8611 89.1485C36.4499 88.8738 36.1295 88.4834 35.9403 88.0265C35.7511 87.5697 35.7016 87.0671 35.798 86.5821C35.8945 86.0972 36.1326 85.6517 36.4822 85.3021C36.8319 84.9524 37.2773 84.7143 37.7623 84.6179C38.2472 84.5214 38.7499 84.5709 39.2067 84.7601C39.6635 84.9493 40.054 85.2698 40.3287 85.6809C40.6034 86.092 40.75 86.5754 40.75 87.0698C40.7474 87.7321 40.4831 88.3664 40.0149 88.8347C39.5466 89.303 38.9122 89.5672 38.25 89.5698ZM38.25 85.5698C37.9533 85.5698 37.6633 85.6578 37.4166 85.8226C37.17 85.9874 36.9777 86.2217 36.8642 86.4958C36.7507 86.7699 36.7209 87.0715 36.7788 87.3625C36.8367 87.6534 36.9796 87.9207 37.1893 88.1305C37.3991 88.3403 37.6664 88.4831 37.9574 88.541C38.2483 88.5989 38.5499 88.5692 38.824 88.4556C39.0981 88.3421 39.3324 88.1499 39.4972 87.9032C39.662 87.6565 39.75 87.3665 39.75 87.0698C39.75 86.672 39.592 86.2905 39.3107 86.0092C39.0294 85.7279 38.6478 85.5698 38.25 85.5698Z"
        fill={props.fill || 'currentColor'}
      />
      <path d="M200.25 10.5698H168.25V11.5698H200.25V10.5698Z" fill={props.fill || 'currentColor'} />
      <path d="M188.25 13.5698H165.25V14.5698H188.25V13.5698Z" fill={props.fill || 'currentColor'} />
      <path d="M196.25 16.5698H171.25V17.5698H196.25V16.5698Z" fill={props.fill || 'currentColor'} />
      <path d="M202.25 20.5698H181.25V21.5698H202.25V20.5698Z" fill={props.fill || 'currentColor'} />
      <path d="M200.25 24.5698H178.25V25.5698H200.25V24.5698Z" fill={props.fill || 'currentColor'} />
      <path d="M187.25 28.5698H171.25V29.5698H187.25V28.5698Z" fill={props.fill || 'currentColor'} />
      <path d="M211.25 31.5698H178.25V32.5698H211.25V31.5698Z" fill={props.fill || 'currentColor'} />
      <path d="M187.25 34.5698H178.25V35.5698H187.25V34.5698Z" fill={props.fill || 'currentColor'} />
      <path d="M189.25 38.5698H181.25V39.5698H189.25V38.5698Z" fill={props.fill || 'currentColor'} />
      <path d="M55.25 86.5698H46.25V87.5698H55.25V86.5698Z" fill={props.fill || 'currentColor'} />
      <path d="M51.25 92.5698H43.25V93.5698H51.25V92.5698Z" fill={props.fill || 'currentColor'} />
      <path d="M55.25 97.5698H43.25V98.5698H55.25V97.5698Z" fill={props.fill || 'currentColor'} />
      <path d="M56.25 89.5698H43.25V90.5698H56.25V89.5698Z" fill={props.fill || 'currentColor'} />
      <path d="M55.25 101.57H44.25V102.57H55.25V101.57Z" fill={props.fill || 'currentColor'} />
      <path d="M49.25 105.57H44.25V106.57H49.25V105.57Z" fill={props.fill || 'currentColor'} />
      <path d="M49.25 109.57H29.25V110.57H49.25V109.57Z" fill={props.fill || 'currentColor'} />
      <path d="M38.25 105.57H31.25V106.57H38.25V105.57Z" fill={props.fill || 'currentColor'} />
    </g>
    <defs>
      <clipPath id="clip0_113_115825">
        <rect width="230" height="129" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default It;
