import { FC } from 'react';
import { ToastContainer, ToastContainerProps } from 'react-toastify';

import { ToastTypeKeys } from '../types';

import 'react-toastify/dist/ReactToastify.css';
import './index.scss';

const StudentToastContainer: FC<ToastContainerProps> = (props) => (
  <ToastContainer
    enableMultiContainer
    containerId={ToastTypeKeys.STUDENT}
    position="top-right"
    autoClose={3000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick={false}
    rtl={false}
    pauseOnFocusLoss={false}
    draggable
    pauseOnHover={false}
    {...props}
    className="student-toast-container"
  />
);

export default StudentToastContainer;
