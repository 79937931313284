import { IDictionaryItem } from 'api/types';
import { isDefined } from 'utils';

const parallelTemplate = ' параллель';
const courseTemplate = 'Курс ';

export const getAvailableVisibilitySettingsParallelCodes = (learnerCategories: IDictionaryItem[]) => {
  const parallelCodes = learnerCategories
    .filter((category) => category.value.includes(parallelTemplate))
    .map((category) => category.code);

  return parallelCodes;
};

export const getAvailableVisibilitySettingsCourseCodes = (learnerCategories: IDictionaryItem[]) => {
  const courseCodes = learnerCategories
    .filter((category) => category.value.includes(courseTemplate))
    .map((category) => category.code);

  return courseCodes;
};

export const formatLearnerCategoryCodes = (selectedCodes: number[], learnerCategories: IDictionaryItem[]) => {
  const availableParallelCodes = getAvailableVisibilitySettingsParallelCodes(learnerCategories);
  const availableCourseCodes = getAvailableVisibilitySettingsCourseCodes(learnerCategories);

  const selectedParallelCodes: number[] = [];
  const selectedCourseCodes: number[] = [];

  selectedCodes.forEach((selectedCode) => {
    if (availableParallelCodes.includes(selectedCode)) selectedParallelCodes.push(selectedCode);
    if (availableCourseCodes.includes(selectedCode)) selectedCourseCodes.push(selectedCode);
  });

  const parallelNumberValues = getLearnerCategoryNumberValues(selectedParallelCodes, learnerCategories);
  const courseNumberValues = getLearnerCategoryNumberValues(selectedCourseCodes, learnerCategories);

  const isAllParallels = parallelNumberValues.length === availableParallelCodes.length;
  const isAllCourses = courseNumberValues.length === availableCourseCodes.length;

  const hasParallels = parallelNumberValues.length > 0;
  const hasCourses = courseNumberValues.length > 0;

  const parallelCodeString = isAllParallels ? 'Все параллели' : `${parallelNumberValues.join(', ')} параллель`;
  const divider = hasParallels && hasCourses ? ', ' : '';
  const courseCodeString = isAllCourses ? 'Все курсы' : `${courseNumberValues.join(', ')} курс`;

  const normalizedParallelCodeString = hasParallels ? parallelCodeString : '';
  const normalizedCourseCodeString = hasCourses ? courseCodeString : '';

  return `${normalizedParallelCodeString}${divider}${normalizedCourseCodeString}`;
};

function getLearnerCategoryNumberValues(selectedCodes: number[], learnerCategories: IDictionaryItem[]) {
  const numberValues = selectedCodes.map((selectedCode) => {
    const learnerCategory = learnerCategories.find((category) => category.code === selectedCode);

    if (!learnerCategories) return null;

    const trimmedCategoryValue = learnerCategory?.value.replace(parallelTemplate, '').replace(courseTemplate, '');
    const categoryNumber = Number(trimmedCategoryValue);

    return !isNaN(categoryNumber) ? categoryNumber : null;
  });

  return numberValues.filter(isDefined);
}
