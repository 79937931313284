import { FC } from 'react';

import { Box, Typography, useMediaQuery } from '@mui/material';
import { IconArrowDiagonalRightUp, IconImageOutline, IconPinOutline } from 'icons';
import { commonTheme } from 'portfolio3/styles/theme';
import { NeutralBadge } from 'portfolio3/ui-kit/badges';
import { Button } from 'portfolio3/ui-kit/button';
import { IMappedEisDopRecommendation } from 'types';

import * as styles from './styles';

const RecommendationDetailsCard: FC<IMappedEisDopRecommendation> = ({
  title,
  image,
  description,
  ageTo,
  ageFrom,
  address,
  link,
}) => {
  const isSmallScreen = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  return (
    <Box className="recommendation-details" sx={styles.paper}>
      {image ? (
        <Box
          className="recommendation-details__image"
          sx={styles.paperIcon}
          component="img"
          alt="Фотография"
          src={image}
        />
      ) : (
        <Box sx={styles.paperIcon}>
          <IconImageOutline sx={styles.paperNoDataImage} />
        </Box>
      )}
      <Box className="recommendation-details__content" sx={styles.paperContentWrapper}>
        <Box sx={styles.cardDetails}>
          <Typography variant={isSmallScreen ? 'Paragraph LG/Semi Bold' : 'Headings/H6'}>{title}</Typography>
          <NeutralBadge>
            от {ageFrom} до {ageTo} лет
          </NeutralBadge>
          <Box sx={styles.address}>
            {!isSmallScreen && <IconPinOutline sx={styles.addressIcon} />}
            <Typography variant="Paragraph MD/Regular">{address}</Typography>
          </Box>
        </Box>
        <Typography sx={styles.description} variant="Paragraph MD/Regular">
          {description}
        </Typography>
        <a style={{ textDecoration: 'none' }} href={link} rel="noreferrer" target="_blank">
          <Button sx={styles.moreButton} iconRight={<IconArrowDiagonalRightUp />}>
            Подробнее
          </Button>
        </a>
      </Box>
    </Box>
  );
};

export default RecommendationDetailsCard;
