import { ICommonResponse, ISource } from 'api/types';
import { DocumentCodeToKey, DocumentShortNames, DocumentTypeCodes, EntityType, SectionCodes, SourceCode } from 'const';
import { DocumentRefState, ProfessionProgrammRefState, SectionRefState } from 'reducers/reference';
import { StudentEventsState } from 'reducers/student/events';
import { StudentRewardsState } from 'reducers/student/rewards';
import { StudentSpoDocumentsState } from 'reducers/student/studentSpoDocuments';
import { StudentSpoEducationState } from 'reducers/student/studentSpoEducation';
import { mapRewardEntity, mapStudyOlympiadToEntity } from 'selectors/entityMappers';
import { ICardData, IEntity, IEntityFeature, IModifiedReward, IStudyDocumentEntity } from 'types';
import {
  formatDate,
  getProfessionalEducationEntityBackgroundImage,
  getStudyDocumentEntityGeneralInfo,
  getStudyPracticeEntityGeneralInfo,
  getStudyTrainingEntityGeneralInfo,
  getYearFromDate,
  isDefined,
} from 'utils';

import { getSpoTrainingDateElement } from './utils';

export const mapStudyOlympiadResponse = (
  events: StudentEventsState,
  rewards: StudentRewardsState,
  sectionRef: SectionRefState,
): ICommonResponse<IEntity> => {
  const boundRewards = rewards.content?.filter((reward) => reward.entityId);
  const olympiads = events.content
    .filter((event) => event.categoryCode == SectionCodes.study)
    .map((event) => {
      const { id, recordId } = event;

      const eventReward = boundRewards?.find((reward) => reward.entityId == id || reward.entityId == recordId);
      return mapStudyOlympiadToEntity(sectionRef.content, event, eventReward);
    });

  return {
    ...events,
    content: olympiads,
  };
};

export const mapStudySpoDocumentResponse = (
  spoDocuments: StudentSpoDocumentsState,
  documentRef: DocumentRefState,
): ICommonResponse<IStudyDocumentEntity> => {
  const documents = spoDocuments.content.map((document): IStudyDocumentEntity => {
    const { name, document: documentType } = document;
    const { code: documentRefCode } = documentType;

    const documentRefName =
      documentRefCode !== DocumentTypeCodes.spoOther
        ? documentRef.content.find((ref) => ref.code === documentRefCode)?.value
        : undefined;

    return {
      name,
      badge: DocumentShortNames[DocumentCodeToKey[documentRefCode]],
      formData: document,
      cardData: {
        files: document.fileReferences,
        name: documentRefName ?? name,
        formData: document,
        generalInfo: getStudyDocumentEntityGeneralInfo(document, documentRefCode),
        excludeCardTabs: ['linkedObjects'],
        illustrationFallback: null,
        entityFlags: ['document'],
      },
    };
  });

  return {
    ...spoDocuments,
    content: documents,
  };
};

export const mapStudySpoPracticeResponse = (spoEducation: StudentSpoEducationState): ICommonResponse<IEntity> => {
  const practice = spoEducation.content.practices.map((practice): IEntity | null => {
    const { recordId, organization, docDate } = practice;

    if (!docDate) return null;

    const mergedFormData = {
      ...practice,
      // name необходимо добавить для поддержки работы функции ReportError, name передается в заголовок дровера
      name: organization,
      categoryCode: SectionCodes.study,
      dataKind: SectionCodes.studySpo,
      typeCode: SectionCodes.studySpoPractice,
    };

    const proftechSource: ISource = {
      code: SourceCode.proftech,
      value: 'АИС "Зачисление в Профтех"',
    };

    const cardData: ICardData = {
      name: organization,
      generalInfo: getStudyPracticeEntityGeneralInfo(practice, proftechSource),
      files: null,
      formData: mergedFormData,
      excludeCardTabs: ['files', 'linkedObjects'],
      entityFlags: ['undeletable'],
      illustrationFallback: null,
    };

    return {
      formData: mergedFormData,
      recordId,
      name: organization || '',
      date: docDate ? `Дата договора: ${formatDate(docDate)}` : '',
      features: [{ text: 'производственная практика', isColored: true }],
      year: getYearFromDate(docDate),
      cardData,
      entityProps: {
        type: EntityType.PRACTICE,
        flags: ['approved', 'undeletable'],
      },
    };
  });

  return {
    ...spoEducation,
    content: practice.filter(isDefined),
  };
};

export const mapStudySpoTrainingResponse = (
  spoEducation: StudentSpoEducationState,
  professionProgrammRef: ProfessionProgrammRefState,
): ICommonResponse<IEntity> => {
  const education = spoEducation.content.profEducations.map((education): IEntity | null => {
    const {
      recordId,
      professionName,
      studyStartDate,
      isEffectivePlan,
      educationCourse,
      isOutletGroup,
      studyForm,
      periodHaveToStudy,
    } = education;

    if (!studyStartDate) return null;

    const mergedFormData = {
      ...education,
      // name необходимо добавить для поддержки работы функции ReportError, name передается в заголовок дровера
      name: professionName,
      categoryCode: SectionCodes.study,
      dataKind: SectionCodes.studySpo,
      typeCode: SectionCodes.studySpoTraining,
    };

    const proftechSource: ISource = {
      code: SourceCode.proftech,
      value: 'АИС "Зачисление в Профтех"',
    };

    const professionWithCode = professionProgrammRef.content.find((profession) => profession.value === professionName);

    const cardData: ICardData = {
      name: isEffectivePlan ? 'Обучение по эффективному учебному плану' : 'Обучение по базовому учебному плану',
      generalInfo: getStudyTrainingEntityGeneralInfo(education, proftechSource, professionWithCode?.profCode),
      files: null,
      formData: mergedFormData,
      excludeCardTabs: ['files', 'linkedObjects'],
      illustrationFallback: null,
    };

    const planText = isEffectivePlan ? 'эффективный учебный план' : 'базовый учебный план';

    const features: IEntityFeature[] = [{ text: planText, isColored: true }];
    if (isOutletGroup) features.push({ text: 'Выпускная группа' });
    if (studyForm) features.push({ text: studyForm });

    return {
      formData: mergedFormData,
      recordId,
      name: professionName,
      date: getSpoTrainingDateElement(studyStartDate, periodHaveToStudy),
      stage: educationCourse ? `${educationCourse} курс` : '',
      features,
      year: getYearFromDate(studyStartDate),
      backgroundImage: getProfessionalEducationEntityBackgroundImage(professionName),
      cardData,
      entityProps: {
        type: EntityType.TRAINING_INFO,
        flags: ['approved', 'undeletable'],
      },
    };
  });

  return {
    ...spoEducation,
    content: education.filter(isDefined),
  };
};

export const mapStudyRewardsResponse = (
  events: StudentEventsState,
  rewards: StudentRewardsState,
  sectionRef: SectionRefState,
): ICommonResponse<IModifiedReward> => {
  const studyRewards = rewards.content
    .filter((reward) => reward.categoryCode === SectionCodes.study)
    .map((reward) => {
      return mapRewardEntity(reward, events.content, sectionRef.content);
    });

  return {
    ...rewards,
    content: studyRewards,
  };
};
