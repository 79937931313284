import { FC } from 'react';

import { SvgIconProps } from '@mui/material';

const IconLoadingCircle: FC<SvgIconProps> = (props) => (
  // если использовать SvgIcon, то иконка отображается некорректно
  <svg width="20" height="20" fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5.669 5.669a6.125 6.125 0 1 1 5.29 10.38"
      stroke={props.fill || 'currentColor'}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconLoadingCircle;
