import { IDictionaryItem, IEgeExamSubject, IGiaItem, ISearchVuzSpeciality, IVuz } from 'api/types';
import { capitalize } from 'lodash';
import { isDefined } from 'utils';

import { ILocalVuzSpeciality, ISearchVuz } from '../../types';
import { convertSearchVuzSpecialityToLocal, isAnyForeignLanguageSubject } from '../../utils';
import { FOREIGN_LANGUAGE_SUBJECT_INITIAL_CODE } from './../../const';

/**
 * Функция возвращяает массив предметов в зависимости от сданных экзаменов
 * Используя за основу справочник предметов ЕГЭ
 */
export function getSubjectsForExams(egeSubjectsRef: IEgeExamSubject[], exams: IGiaItem[]): IDictionaryItem[] {
  const mappedEgeSubjectsRef = egeSubjectsRef.map((subjectRef) => ({
    ...subjectRef,
    title: subjectRef.title.toLowerCase(),
  }));
  const examSubjectNames = exams.map((exam) => exam.name?.toLowerCase()).filter(isDefined);
  const uniqueExamSubjectNames = Array.from(new Set(examSubjectNames));

  let foreignLanguageCodeCounter = FOREIGN_LANGUAGE_SUBJECT_INITIAL_CODE;

  const subjects = uniqueExamSubjectNames
    .map((examSubjectName): IDictionaryItem | null => {
      const subjectFromRef = mappedEgeSubjectsRef.find((subjectRef) => subjectRef.title === examSubjectName);

      // Если предмет экзамена это вид иностранного языка, то добавляем его в список
      // Используются локальные ID, которые заменяются на ID предмета "Иностранный язык" из справочника при поиске
      if (isAnyForeignLanguageSubject(examSubjectName)) {
        return {
          code: foreignLanguageCodeCounter--,
          value: capitalize(examSubjectName),
        };
      }

      if (!subjectFromRef) return null;

      return {
        code: subjectFromRef.id,
        value: capitalize(subjectFromRef.title),
      };
    })
    .filter(isDefined);

  return subjects;
}

export function normalizeSearchVuzSpeciality(speciality: ISearchVuzSpeciality): ISearchVuzSpeciality {
  return {
    ...speciality,
    subjects: Array.from(new Set(speciality.subjects)),
  };
}

export const filterSpecialityByMinBallExistance = (speciality: ILocalVuzSpeciality) =>
  isDefined(speciality.budgetMinBall) || isDefined(speciality.paidMinBall);

interface GetMappedVuzListParams {
  vuzList: IVuz[];
  specialityFilters: ((speciality: ILocalVuzSpeciality) => boolean)[];
  skipVuzWithEmptySpecialities?: boolean;
}

export const getMappedVuzList = (params: GetMappedVuzListParams) => {
  const { vuzList, specialityFilters, skipVuzWithEmptySpecialities } = params;

  const mappedVuzList = vuzList
    .map((vuz): ISearchVuz | null => {
      if (!isDefined(vuz.name)) return null;

      const { id, name, logo, url, war, war_center, specialities, address, committee_address, phone, committee_phone } =
        vuz;

      const convertedVuzSpecialities =
        specialities?.map(normalizeSearchVuzSpeciality).map(convertSearchVuzSpecialityToLocal) || [];

      const validSpecialities = specialityFilters.reduce<ILocalVuzSpeciality[]>((filteredSpecs, mapper) => {
        return filteredSpecs.filter(mapper);
      }, convertedVuzSpecialities);

      if (validSpecialities.length === 0 && skipVuzWithEmptySpecialities) return null;

      return {
        info: {
          id,
          name,
          logo,
          address,
          commiteeAddress: committee_address,
          phone,
          commiteePhone: committee_phone,
          suitableSpecialitiesNumber: validSpecialities.length,
          vuzUrl: url,
          warDepartment: war,
          warCenter: war_center,
        },
        specialities: validSpecialities,
      };
    })
    .filter(isDefined);

  return mappedVuzList;
};
