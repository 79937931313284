import { DateErrorKeys } from 'const';
import { format } from 'date-fns';

type ErrorValues = Record<DateErrorKeys, string>;

export const dataEntryMinDate = new Date(2009, 0);
export const dataEntryMaxDate = new Date(2050, 0);

export const dataEntryDateErrorValues: ErrorValues = {
  beforeMinDate: `Дата не может быть раньше ${format(dataEntryMinDate, 'dd.MM.yyyy')}`,
  afterMaxDate: `Дата не может быть позже ${format(dataEntryMaxDate, 'dd.MM.yyyy')}`,
  beforeStartDate: 'Дата не может быть раньше даты начала мероприятия',
  afterEndDate: 'Дата не может быть позже окончания мероприятия',
  beforeToday: 'Дата не может быть раньше текущей',
  afterToday: 'Дата не может быть позже текущей',
  emptyDate: 'Выберите дату',
};
