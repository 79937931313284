import безопасность from './безопасность.svg';
import гостеприимство from './гостеприимство.svg';
import здравоохранение from './здравоохранение.svg';
import ит from './ит.svg';
import креативная_индустрия from './креативная_индустрия.svg';
import образование from './образование.svg';
import промышленность from './промышленность.svg';
import проф_тестирование from './проф_тестирование.svg';
import строительство from './строительство.svg';
import торговля_и_финансы from './торговля_и_финансы.svg';
import транспорт from './транспорт.svg';

export const industryIllustrations = {
  безопасность,
  гостеприимство,
  здравоохранение,
  ит,
  креативная_индустрия,
  образование,
  промышленность,
  проф_тестирование,
  строительство,
  торговля_и_финансы,
  транспорт,
};
