/* eslint-disable prettier/prettier */
/* eslint-disable */

import { validateProperties } from '../common';
import { DifferentCode, SectionCodes } from '../../../../../const';
import { IEventKind } from '../../../../../api/types';
import { CommonValidator } from '../types';
import { IPortfolioEntryFormData } from 'portfolio3/features/dataEntryForm';

const eventValidators: Record<string, CommonValidator> = {
  [SectionCodes.study]: isStudyEventValid,
  [SectionCodes.science]: isScienceEventValid,
  [SectionCodes.sport]: isSportEventValid,
  [SectionCodes.creation]: isCreationEventValid,
  [SectionCodes.culture]: isCultureEventValid,
  [SectionCodes.civil]: isCivilEventValid,
  [SectionCodes.profession]: isProfessionEventValid
};

export const isEventValid = (formData: IPortfolioEntryFormData, eventKinds: IEventKind[]): boolean => {
  const { categoryCode } = formData;
  const commonValidator = () => validateProperties(getCommonEventProperties(formData, eventKinds));

  const validator = categoryCode ? eventValidators[categoryCode] : null;
  return validator ? validator(formData, commonValidator) : false;
};

// SectionCode.study
function isStudyEventValid(formData: IPortfolioEntryFormData, commonValidator: () => boolean): boolean {
  return validateProperties([
    commonValidator(),
    formData.categoryCode === SectionCodes.study,
    formData.subjectId.length > 0
  ]);
}

// SectionCode.science
function isScienceEventValid(formData: IPortfolioEntryFormData, commonValidator: () => boolean): boolean {
  return validateProperties([
    commonValidator(),
    formData.categoryCode === SectionCodes.science,
    formData.subjectId.length > 0,
    formData.eventLevel
  ]);
}

// SectionCode.sport
function isSportEventValid(formData: IPortfolioEntryFormData, commonValidator: () => boolean): boolean {
  const commonValidators = [
    commonValidator(),
    formData.categoryCode === SectionCodes.sport
  ];

  switch (formData.typeCode) {
    // Соревнование
    case SectionCodes.sportEventCompetition: {
      return validateProperties([
        ...commonValidators,
        formData.sportKind,
      ]);
    }
    // Поход / экспедиция
    case SectionCodes.sportEventExpedition: {
      return validateProperties([
        ...commonValidators,
        formData.subjectId.length > 0,
        formData.tourismKind
      ]);
    }
    // Первенство по туризму
    case SectionCodes.sportEventTourism: {
      return validateProperties([
        ...commonValidators,
        formData.tourismKind
      ]);
    }
  }

  return false;
}

// SectionCode.creation
function isCreationEventValid(formData: IPortfolioEntryFormData, commonValidator: () => boolean): boolean {
  return validateProperties([
    commonValidator(),
    formData.categoryCode === SectionCodes.creation,
    formData.subCategory,
    formData.creationKindCode
  ]);
}

// SectionCode.culture
function isCultureEventValid(formData: IPortfolioEntryFormData, commonValidator: () => boolean): boolean {
  const commonProperties = [
    commonValidator(),
    formData.categoryCode === SectionCodes.culture
  ];

  switch (formData.typeCode) {
    case SectionCodes.cultureOnline: {
      return validateProperties(commonProperties);
    }
    case SectionCodes.cultureVisiting: {
      return validateProperties([
        ...commonProperties,
        formData.place,
        formData.placeName,
      ]);
    }
  }

  return false;
}

// SectionCode.civil
function isCivilEventValid(formData: IPortfolioEntryFormData, commonValidator: () => boolean): boolean {
  return validateProperties([
    commonValidator(),
    formData.categoryCode === SectionCodes.civil
  ]);
}

// SectionCode.profession
function isProfessionEventValid(formData: IPortfolioEntryFormData, commonValidator: () => boolean): boolean {
  return validateProperties([
    commonValidator(),
    formData.categoryCode === SectionCodes.profession,
    formData.participantCategory
  ]);
}

function getEventName(formData: IPortfolioEntryFormData, eventKinds: IEventKind[]): string | undefined {
  const { name, secondName } = formData;
  if (Number(name) === DifferentCode) return secondName;

  const eventName = eventKinds.find((event) => event.code === name)?.value;
  return eventName;
}

function getCommonEventProperties(formData: IPortfolioEntryFormData, eventKinds: IEventKind[]): unknown[] {
  const eventName = getEventName(formData, eventKinds);
  const { eventLevel, startDate } = formData;

  return [eventName, eventLevel, startDate];
}
