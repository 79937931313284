import { StudentSectionSettingTypeCodes } from 'const';
import useWidgetVisibility from 'hooks/useWidgetVisibility';

const cultureUseWidgetVisibilityCreator = (subsectionTypeCode: string) => {
  const useProfessionWidgetVisibility = () => {
    return useWidgetVisibility({
      sectionTypeCode: StudentSectionSettingTypeCodes.culture,
      subsectionTypeCode,
    });
  };
  return useProfessionWidgetVisibility;
};

const useOfflineVisitings = cultureUseWidgetVisibilityCreator(StudentSectionSettingTypeCodes.cultureOfflineVisiting);

const useOnlineVisitings = cultureUseWidgetVisibilityCreator(StudentSectionSettingTypeCodes.cultureOnlineVisiting);

export const cultureWidgetVisibilityHooks = { useOfflineVisitings, useOnlineVisitings };
