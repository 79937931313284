import { IPerformanceSubject, IPerformanceYear, IYearOption, MarkType } from './types';

export const filterSubjects = (
  subjects: IPerformanceSubject[] | undefined,
  markType: MarkType,
  lowestGrade: number,
): IPerformanceSubject[] => {
  return (
    subjects
      // Отфильтровываем предметы по нижней границы оценки
      ?.filter((subject) =>
        markType === MarkType.AVERAGE ? subject.averageMark >= lowestGrade : subject.averageYearMark >= lowestGrade,
      )
      // Сортируем предметы по алфавиту
      .sort((x, y) => (x.subjectName.toLowerCase() < y.subjectName.toLowerCase() ? -1 : 1)) || []
  );
};

export const getYearOptionPerformance = (
  performanceYears: IPerformanceYear[],
  yearOptions: IYearOption[],
  yearCode: number | undefined,
) => {
  const yearOption = yearOptions.find((yearOption) => yearOption.code === yearCode);
  return performanceYears.find((performance) => performance.learningYear === yearOption?.value);
};
