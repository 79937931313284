import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Box, useMediaQuery } from '@mui/material';
import { eisDopRecommendationsDrawerActions } from 'actions';
import { RecommendationDetailsCard } from 'components/redesignStudent/DataEntity/profile/recommendation';
import { useAppSelector, useScrollToElement } from 'hooks';
import { DrawerHeader, MobileDrawerHeader } from 'portfolio3/components/drawers';
import { NeutralColors } from 'portfolio3/styles';
import { commonTheme } from 'portfolio3/styles/theme';
import { Drawer, IDrawerBase } from 'portfolio3/ui-kit/Drawer';
import { mergeSx } from 'utils';

import MoreElementsButton from './MoreElementsButton';
import ScrollTopButton from './ScrollTopButton';
import * as styles from './styles';

const INITIAL_VISIBLE_ELEMENTS = 5;
const DESKTOP_ELEMENTS_STEP = 10;
const MOBILE_ELEMENTS_STEP = 5;
const CARD_LIST_ID = 'eis-dop-recommendations-drawer-list';

const EisDopRecommendationsDrawer: FC = () => {
  const dispatch = useDispatch();
  const { isOpen, title, recommendations } = useAppSelector((state) => state.eisDopRecommendationsDrawer);

  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));
  const { scrollToElementGlobal } = useScrollToElement();

  const [visibleElements, setVisibleElements] = useState(INITIAL_VISIBLE_ELEMENTS);

  useEffect(() => {
    if (isOpen) {
      setVisibleElements(INITIAL_VISIBLE_ELEMENTS);
    }
  }, [isOpen]);

  const totalElements = recommendations.length;
  const hiddenElements = totalElements - visibleElements;
  const isAllVisible = hiddenElements <= 0;
  const isMoreElementsButtonVisible = !isAllVisible || isMobile;
  const elementsStep = isMobile ? MOBILE_ELEMENTS_STEP : DESKTOP_ELEMENTS_STEP;
  const nextElementsStep = hiddenElements < elementsStep ? hiddenElements : elementsStep;

  const visibleRecommendations = recommendations.slice(0, visibleElements);

  const handleClose = () => dispatch(eisDopRecommendationsDrawerActions.setOpen(false));
  const handleClickPaginationButton = () => setVisibleElements((current) => current + elementsStep);

  const desktopHeader: IDrawerBase['header'] = (crossButton) => (
    <DrawerHeader
      title={title}
      backgroundImage={null}
      accentColor="blue"
      overrideBackgroundColor={NeutralColors.light_neutrals_100}
      overrideBorderColor={NeutralColors.light_neutrals_400}
      crossButton={crossButton}
    />
  );

  const mobileHeader: IDrawerBase['header'] = (crossButton) => (
    <MobileDrawerHeader
      title={title}
      accentColor="blue"
      overrideTitleColor={NeutralColors.light_neutrals_900}
      overrideBackgroundColor={NeutralColors.light_neutrals_0}
      crossButton={crossButton}
      multilineTitle
      isExtendedBackground={false}
    />
  );

  const moreElementsButton = (
    <MoreElementsButton elementsCount={nextElementsStep} isMobile={isMobile} onClick={handleClickPaginationButton} />
  );

  return (
    <Drawer
      open={isOpen}
      isFullHeight
      isMobile={isMobile}
      anchor={isMobile ? 'bottom' : 'right'}
      swipeable={isMobile}
      swipeableProps={{
        onClose: handleClose,
      }}
      onClose={handleClose}
      header={isMobile ? mobileHeader : desktopHeader}
      contentSx={mergeSx(styles.drawerContent, {
        backgroundColor: isMobile ? NeutralColors.light_neutrals_0 : NeutralColors.light_neutrals_200,
      })}
    >
      <Box sx={styles.cardList} id={CARD_LIST_ID}>
        {visibleRecommendations.map((recommendation) => {
          const { title, address } = recommendation;
          const key = `${title}-${address}`;

          return <RecommendationDetailsCard key={key} {...recommendation} />;
        })}
      </Box>

      {isMoreElementsButtonVisible && (
        <Box marginTop="12px">
          {!isMobile && moreElementsButton}
          {isMobile && !isAllVisible && moreElementsButton}
          {isMobile && isAllVisible && <ScrollTopButton onClick={() => scrollToElementGlobal(CARD_LIST_ID)} />}
        </Box>
      )}
    </Drawer>
  );
};

export default EisDopRecommendationsDrawer;
