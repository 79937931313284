/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useState } from 'react';

import DiagnosticCardWrapper from '../DIagnosticCardWrapper';
import DiagnosticListExpandButton from './expandButton';
import { IIndependentSelfDiagnostic } from '../../../../../api/types';

import './index.scss';

interface IIndependentDiagnosticListProps {
  diagnostics: IIndependentSelfDiagnostic[];
}

const IndependentDiagnosticList: React.VFC<IIndependentDiagnosticListProps> = ({
  diagnostics
}) => {
  const initialDisplayCount = 4;
  const totalEntitiesCount = diagnostics.length;

  const [displayCount, setDisplayCount] = useState(initialDisplayCount);

  const year = diagnostics && new Date(diagnostics[0].eventDate).getFullYear() || null;

  const setInitialDisplayCount = () => {
    setDisplayCount(initialDisplayCount);
  };

  const setAllDisplayCount = () => {
    setDisplayCount(totalEntitiesCount);
  };

  const isAllEntitiesShown = totalEntitiesCount === displayCount;
  const defaultHiddenEntities = diagnostics.length - initialDisplayCount;

  return (
    <div className="independent-diagnostic-list">
      <header className="independent-diagnostic-list__header">
        <h3 className="independent-diagnostic-list__title">Пройденные независимые диагностики</h3>
        <DiagnosticListExpandButton
          hiddenEntitiesCount={defaultHiddenEntities}
          isAllEntitiesShown={isAllEntitiesShown}
          onSetAllDisplayCount={setAllDisplayCount}
          onSetInitialDisplayCount={setInitialDisplayCount}
        />
      </header>
      <div className="independent-diagnostic-list__items">
        {year && <div className="list-year">{year}</div>}
        {diagnostics.slice(0, displayCount).map((diagnostic) => (
          <DiagnosticCardWrapper
            key={diagnostic.recordId}
            diagnostic={{
              ...diagnostic,
              visibilitySettingValue: diagnostic.isVisible,
              learningYear: '',
              workId: 0}
            }
          />
        ))}
      </div>
    </div>
  );
};

export default IndependentDiagnosticList;
