/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useContext } from 'react';

import { IconButton } from '@mui/material';

import SubjectDetails from './subjectDetails';
import ModalContainer from 'components/common/ModalContainer';
import MobileDragIndicator from 'components/common/MobileDragIndicator';
import SubjectDetailsEmpty from '../SubjectDetailsEmpty';
import SubjectTopicsEmpty from '../SubjectTopicsEmpty';
import ResultsLoader from '../ResultsLoader';
import { Drawer } from 'ui-kit';
import { iconClose } from 'icons';
import { IPerformanceSubjectTopicInfo } from '../../types';
import { PerformanceSubjectContext } from '../../context';

interface IMobileSubjectDetailsProps {
  isOpen: boolean;
  subjectTopic?: IPerformanceSubjectTopicInfo;
  currentSubjectTopicsCount: number;
  isTopicsLoading: boolean;
  onClose: () => void;
}

const MobileSubjectDetails: React.FC<IMobileSubjectDetailsProps> = ({
  isOpen,
  subjectTopic,
  currentSubjectTopicsCount,
  isTopicsLoading,
  onClose
}) => {
  const { selectedSubject } = useContext(PerformanceSubjectContext);

  const isVisibleDetails = selectedSubject && (currentSubjectTopicsCount > 0 || isTopicsLoading);
  const isEmptyTopics = selectedSubject && currentSubjectTopicsCount === 0 && !isTopicsLoading;

  const renderSubjectDetails = () => {
    if (isVisibleDetails && selectedSubject) {
      return (
        <SubjectDetails
          subject={selectedSubject.name}
          subjectResult={selectedSubject.mark}
          subjectTopic={subjectTopic}
        />
      );
    }

    if (isEmptyTopics) return <SubjectTopicsEmpty isMobile />;

    return <SubjectDetailsEmpty />;
  };

  return (
    <ModalContainer>
      <Drawer
        isContainerScrollable={false}
        isFullHeight
        anchor="bottom"
        transitionDuration={{ appear: 300, enter: 300, exit: 250 }}
        open={isOpen}
        onClose={onClose}
      >
        <div className="mobile-subject-details">
          <div className="mobile-subject-details__header">
            <MobileDragIndicator onTouchStart={onClose}/>
            <IconButton
              className="button-close"
              disableTouchRipple
              onClick={onClose}
              size="large">
              <img src={iconClose} alt="закрыть" />
            </IconButton>
          </div>

          <div className="mobile-subject-details__content">
            {isTopicsLoading ? <ResultsLoader /> : renderSubjectDetails()}
          </div>
        </div>
      </Drawer>
    </ModalContainer>
  );
};

export default MobileSubjectDetails;
