import { FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react';

import { Box, useMediaQuery } from '@mui/material';
import { IDictionaryItem } from 'api/types';
import { DIAGNOSTIC_LEVEL_TYPE_ALL_CODE, DIAGNOSTIC_SUBJECT_ALL_CODE } from 'const';
import { useDisclosure } from 'hooks';
import FilterButton from 'portfolio3/components/common/buttons/FilterButton';
import MobileActionsDrawer from 'portfolio3/components/drawers/MobileActionsDrawer';
import { RadioGroupControl, RadioGroupController } from 'portfolio3/components/forms/RadioGroupControl';
import { emitYMEvent } from 'portfolio3/features/yandexMetrika';
import { NeutralColors } from 'portfolio3/styles';
import { commonTheme } from 'portfolio3/styles/theme';
import { Switcher, SwitcherButtonCount } from 'portfolio3/ui-kit';
import { Select } from 'portfolio3/ui-kit/selects';
import { IController } from 'portfolio3/ui-kit/types';
import { IIndependentDiagnosticData, IIndependentDiagnosticLevelType } from 'types';

import DiagnosticContext from '../../context/diagnosticContext';
import * as styles from './styles';

interface IIndependentDiagnosticsListFilterProps {
  diagnostics: IIndependentDiagnosticData[];
  levelTypes: IIndependentDiagnosticLevelType[];
  subjects: IDictionaryItem[];
  spoilerButton: ReactNode;
}

const IndependentDiagnosticsListFilter: FC<IIndependentDiagnosticsListFilterProps> = ({
  diagnostics,
  levelTypes,
  subjects,
  spoilerButton,
}) => {
  const { filters, setSingleFilter } = useContext(DiagnosticContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tempLevelTypeCode, setTempLevelTypeCode] = useState(filters.independentListLevelTypeCode);
  const [tempSubjectCode, setTempSubjectCode] = useState(filters.independentListSubjectCode);
  const isSmallScreen = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const { independentListLevelTypeCode: levelTypeCode, independentListSubjectCode: subjectCode } = filters;

  /* фильтрация списка диагностик */
  // по предмету
  const filteredDiagnosticsSubject = diagnostics.filter((diagnostic) => {
    const filterSubjectCode = isSmallScreen ? tempSubjectCode : subjectCode;

    if (filterSubjectCode === DIAGNOSTIC_SUBJECT_ALL_CODE) return true;

    const subjectValue = subjects.find((subject) => subject.code === filterSubjectCode)?.value;
    if (!subjectValue) return false;
    return diagnostic.subject.toLowerCase() === subjectValue.toLowerCase();
  });

  // фильтрация уровней знаний в зависимости от видимых диагностик
  const filteredLevelTypes = useMemo(() => {
    const currentLevelTypesCount = new Map<string, number>();
    // поиск количества диагностик по каждому уровню
    filteredDiagnosticsSubject.forEach((diagnostic) => {
      const levelType = diagnostic.levelType?.toLowerCase();
      if (!levelType) return;
      const count = currentLevelTypesCount.get(levelType) ?? 0;
      currentLevelTypesCount.set(levelType, count + 1);
    });

    const unmaskedResult = levelTypes
      .filter((levelType) => {
        return (
          currentLevelTypesCount.has(levelType.value.toLowerCase()) || levelType.code === DIAGNOSTIC_LEVEL_TYPE_ALL_CODE
        );
      })
      // обновление поля count
      .map((levelType) => {
        const newCount =
          levelType.code === DIAGNOSTIC_LEVEL_TYPE_ALL_CODE
            ? filteredDiagnosticsSubject.length
            : currentLevelTypesCount.get(levelType.value.toLowerCase()) || 0;
        return {
          ...levelType,
          count: newCount,
        };
      })
      // фильтрация табов с count = 0
      .filter((levelType) => levelType.count > 0 || levelType.code === DIAGNOSTIC_LEVEL_TYPE_ALL_CODE);

    const resultMask = ['Все', 'Высокий', 'Повышенный', 'Базовый', 'Ниже базового'];
    const maskMap = new Map(resultMask.map((v, i) => [v, i]));

    const maskedResult = unmaskedResult.sort((a, b) => {
      const aIndex = maskMap.get(a.value);
      const bIndex = maskMap.get(b.value);
      if (!aIndex || !bIndex) return 1;
      return aIndex - bIndex;
    });

    return maskedResult;
  }, [filteredDiagnosticsSubject, levelTypes]);

  // обработка изменения subjectCode
  useEffect(() => {
    if (isSmallScreen) return;

    const hasLevelType = filteredLevelTypes.find((levelType) => levelType.code === levelTypeCode);
    if (!hasLevelType) {
      setSingleFilter('independentListLevelTypeCode', DIAGNOSTIC_LEVEL_TYPE_ALL_CODE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjectCode]);

  // обработка изменения subjectCode в мобильных фильтрах
  useEffect(() => {
    const hasLevelType = filteredLevelTypes.find((levelType) => levelType.code === tempLevelTypeCode);
    if (!hasLevelType) {
      setTempLevelTypeCode(DIAGNOSTIC_LEVEL_TYPE_ALL_CODE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempSubjectCode]);

  const changeLevelType = (levelType: number) => {
    setSingleFilter('independentListLevelTypeCode', levelType);

    emitYMEvent({ type: 'difficultyLevelChoosing' });
  };

  const changeSubject = (subjectCode: number) => {
    setSingleFilter('independentListSubjectCode', subjectCode);

    emitYMEvent({ type: 'diagnosticItemsFiltering' });
  };

  const handleChangeLevelType = (levelType: number) => {
    changeLevelType(levelType);
  };

  const subjectController: IController<string | undefined> = {
    handleChange(subjectCode) {
      changeSubject(Number(subjectCode));
    },
  };

  const tempLevelTypeController: RadioGroupController = {
    handleChange(levelTypeCode) {
      setTempLevelTypeCode(levelTypeCode ?? DIAGNOSTIC_LEVEL_TYPE_ALL_CODE);
    },
  };

  const tempSubjectController: RadioGroupController = {
    handleChange(subjectCode) {
      setTempSubjectCode(subjectCode ?? DIAGNOSTIC_SUBJECT_ALL_CODE);
    },
  };

  const handleOpenFiltersDrawer = () => {
    setTempLevelTypeCode(levelTypeCode);
    setTempSubjectCode(subjectCode);
    onOpen();
  };

  const handleResetFiltersDrawer = () => {
    setTempLevelTypeCode(DIAGNOSTIC_LEVEL_TYPE_ALL_CODE);
    setTempSubjectCode(DIAGNOSTIC_SUBJECT_ALL_CODE);
  };

  const handleApplyFiltersDrawer = () => {
    changeLevelType(tempLevelTypeCode);
    changeSubject(tempSubjectCode);
    onClose();
  };

  const isFiltersApplied =
    levelTypeCode !== DIAGNOSTIC_LEVEL_TYPE_ALL_CODE || subjectCode !== DIAGNOSTIC_SUBJECT_ALL_CODE;
  const isFiltersResetDisabled =
    tempLevelTypeCode === DIAGNOSTIC_LEVEL_TYPE_ALL_CODE && tempSubjectCode === DIAGNOSTIC_SUBJECT_ALL_CODE;

  return (
    <Box sx={styles.root}>
      {!isSmallScreen && (
        <>
          <Switcher useHiddenList componentSize="medium" value={levelTypeCode} onChange={handleChangeLevelType}>
            {filteredLevelTypes.map((levelType) => (
              <SwitcherButtonCount
                key={levelType.value}
                content={levelType.value}
                count={levelType.count}
                value={levelType.code}
              />
            ))}
          </Switcher>
          <Select
            inputRenderMode="fixed"
            inputSize="medium"
            value={subjectCode}
            options={subjects}
            controller={subjectController}
          />
        </>
      )}

      {isSmallScreen && (
        <>
          {spoilerButton}

          <FilterButton onClick={handleOpenFiltersDrawer} withIndicator={isFiltersApplied} />

          <MobileActionsDrawer
            open={isOpen}
            onClose={onClose}
            title="Фильтр  пройденные независимые диагностики"
            isResetDisabled={isFiltersResetDisabled}
            onReset={handleResetFiltersDrawer}
            onApply={handleApplyFiltersDrawer}
          >
            <RadioGroupControl
              isMobile
              label="Уровень знаний"
              value={tempLevelTypeCode}
              options={filteredLevelTypes}
              controller={tempLevelTypeController}
            />
            <Box sx={{ height: '1px', backgroundColor: NeutralColors.light_neutrals_300, marginBlock: '16px' }} />
            <RadioGroupControl
              isMobile
              label="Предметы"
              value={tempSubjectCode}
              options={subjects}
              controller={tempSubjectController}
            />
          </MobileActionsDrawer>
        </>
      )}
    </Box>
  );
};

export default IndependentDiagnosticsListFilter;
