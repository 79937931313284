import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import PopUp from 'portfolio3/ui-kit/modals/PopUp';

import * as styles from './styles';

interface IQrCodePopUpProps {
  isOpen: boolean;
  onClose: () => void;
  qrCode: string;
}

const QrCodePopUp: FC<IQrCodePopUpProps> = ({ isOpen, onClose, qrCode }) => {
  const bodyElement = (
    <Box sx={styles.body}>
      <Typography variant="Paragraph LG/Semi Bold" sx={styles.description}>
        Предложи отсканировать этот QR-код тому, с кем ты хочешь поделиться своим портфолио
      </Typography>
      <Box component="img" src={qrCode} alt="qr-code" sx={styles.qrCodeImage} />
    </Box>
  );

  return (
    <PopUp
      open={isOpen}
      title="QR-код для просмотра портфолио"
      onClose={onClose}
      body={bodyElement}
      sx={{ width: '400px', height: '480px' }}
      bodySx={{ padding: '32px 40px' }}
    />
  );
};

export default QrCodePopUp;
