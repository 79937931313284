/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { Droppable } from 'react-beautiful-dnd';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useMediaQuery } from '@mui/material';

import SwiperCore, { Pagination, Navigation } from 'swiper';
import { IDragItem, TContainerKeys } from '../types';
import CheckerDragItem from './dragItem';

import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import './index.scss';

interface ICheckerDropContainerProps {
  title: string,
  prefix: TContainerKeys,
  elements: IDragItem[],
  onMoveElement: (sourceIndex: number, sourceId: TContainerKeys, destinationId: TContainerKeys) => void
}

const CheckerDropContainer: React.FC<ICheckerDropContainerProps> = ({
  title, prefix, elements, onMoveElement
}) => {
  const isMobile = useMediaQuery('(max-width: 900px)', { noSsr: true });
  if (isMobile) {
    SwiperCore.use([Navigation, Pagination]);
    return (
      <div className={`drop-container ${prefix}`}>
        <h5 className="drop-container__title">{title}</h5>
        <Swiper
          effect="flip"
          grabCursor
          pagination={{ dynamicBullets: true, dynamicMainBullets: 9 }}
          navigation
        >
          {elements.map((dragItem, index) => (
            <SwiperSlide>
              <CheckerDragItem
                index={index}
                key={dragItem.id}
                id={dragItem.id}
                name={dragItem.value}
                cardTheme={dragItem.theme.card}
                prefix={prefix}
                onMoveElement={onMoveElement}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

    );
  }

  return (
    <div className={`drop-container ${prefix}`}>
      <h5 className="drop-container__title">{title}</h5>
      <Droppable droppableId={`${prefix}`}>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {elements.map((dragItem, index) => (
              <CheckerDragItem
                index={index}
                key={dragItem.id}
                id={dragItem.id}
                name={dragItem.value}
                cardTheme={dragItem.theme.card}
                prefix={prefix}
                onMoveElement={onMoveElement}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

export default CheckerDropContainer;
