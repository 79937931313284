import { getStudentAvatarActions, SET_AVATAR_TYPE_SETTING, setAvatarTypeSettingAction } from '../../actions/students';
import { connectReducers } from '../../utils';
import { IReducers } from '..';
import { GET_STUDENT_AVATAR } from './../../actions/students';
import { SUCCESS } from './../../actions/utils';

interface IAvatarItem {
  image: string | null;
  // avatar_type_ref
  type: number;
}

export type AvatarsState = Map<string, IAvatarItem>;

const initialState: AvatarsState = new Map();

const avatars: IReducers<AvatarsState> = {
  [GET_STUDENT_AVATAR[SUCCESS]]: (state, action: ReturnType<typeof getStudentAvatarActions.success>) => {
    const { avatar, avatarSetting } = action.payload;

    const newState = new Map(state);

    const imageSrc = `data:image;base64,${avatar}`;

    newState.set(avatarSetting.personId, { image: imageSrc, type: avatarSetting.avatarTypeCode });

    return newState;
  },
  [SET_AVATAR_TYPE_SETTING]: (state, action: ReturnType<typeof setAvatarTypeSettingAction>) => {
    const { personId, type } = action.payload;

    const newState = new Map(state);
    const personSettings = newState.get(personId);

    if (personSettings) {
      newState.set(personId, { ...personSettings, type });
    }

    return newState;
  },
};

export default connectReducers(initialState, avatars);
