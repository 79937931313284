/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

const IconGoldCup: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="50" height="50" fill="#E5E5E5"/>
    <rect width="1924" height="1397" transform="translate(-1079 -308)" fill="white"/>
    <g clip-path="url(#clip0)">
    <path d="M17.5193 34.8582C5.52278 31.4199 4.11761 18.3457 4.10523 18.2161L4 17.1111H13.3719V19.0865H6.24084C6.81652 22.0927 9.18116 30.3952 18.0702 32.9446L17.5193 34.8582Z" fill="#FAA215"/>
    <path d="M32.4744 34.8582L31.9297 32.9631C40.8126 30.4137 43.1834 22.1112 43.7529 19.105H36.6218V17.1111H45.9999L45.8947 18.1976C45.8823 18.3457 44.471 31.4199 32.4744 34.8582Z" fill="#FAA215"/>
    <path d="M38.5594 14.0248C38.5594 14.0248 38.2561 36.4262 26.5938 39.2349C26.3772 39.2904 26.1605 39.3274 25.9377 39.3707L25.3991 39.4447C25.1425 39.4818 24.8819 39.4818 24.6254 39.4447C24.4211 39.4447 24.223 39.3953 24.0063 39.3645C23.7897 39.3336 23.604 39.2842 23.3873 39.2349C11.7684 36.42 11.4651 14.0248 11.4651 14.0248H38.5594Z" fill="#FFC300"/>
    <path d="M35.1239 51.5251H14.9006C14.9247 51.2716 15.0269 51.0316 15.1932 50.8382C15.3594 50.6448 15.5815 50.5074 15.8292 50.4449C22.6383 47.8461 23.375 41.4757 23.4245 39.2103C23.4389 38.9409 23.4389 38.671 23.4245 38.4016H26.6124C26.6124 38.4016 26.6124 38.5312 26.6124 38.7535C26.6124 38.8769 26.6124 39.0374 26.6124 39.2103C26.6619 41.4695 27.4171 47.8523 34.2139 50.4449C34.4581 50.5107 34.6762 50.6495 34.8389 50.8426C35.0016 51.0358 35.101 51.2739 35.1239 51.5251Z" fill="#FFC300"/>
    <path opacity="0.7" d="M35.1238 51.5249H30.3945C30.2234 51.252 30.0005 51.015 29.7383 50.8274C25.3185 47.6298 25.8447 40.2285 25.9376 39.3705C26.1676 39.177 26.3866 38.9709 26.5937 38.7532C32.3691 33.4384 33.2605 18.3827 33.3657 14H38.5593C38.5593 14 38.256 36.4261 26.5937 39.2347C26.6432 41.494 27.3984 47.8767 34.1952 50.4693C34.4392 50.5304 34.6588 50.6641 34.8246 50.8527C34.9905 51.0412 35.0948 51.2757 35.1238 51.5249Z" fill="#F7931E"/>
    <path opacity="0.4" d="M22.1308 51.5251L22.2856 50.6547C22.2856 50.6547 24.805 44.8892 24.5635 39.4447L23.9445 38.4262C22.1308 35.6299 16.863 28.278 17.5439 14.0248H14.2507C14.4117 18.3891 15.8292 32.778 23.4307 38.9756C23.6226 39.1114 23.8207 39.2349 24.0498 39.3645C24.0498 39.3645 24.3655 47.6238 18.3981 50.6979C18.1127 50.9049 17.8969 51.1933 17.7791 51.5251H22.1308Z" fill="white"/>
    <path d="M27.046 30.105H25.1333V24.8765V24.0123V23.0741C24.8114 23.3889 24.5947 23.5988 24.4709 23.6914L23.4743 24.5185L22.5706 23.3827L25.4923 21.0617H27.0646L27.046 30.105Z" fill="#E57C13"/>
    </g>
    <rect x="-1014.5" y="-231.5" width="1768" height="373" rx="4.5" stroke="#7B61FF" strokeDasharray="10 5"/>
    <defs>
    <clipPath id="clip0">
    <rect width="50" height="50" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);

export default IconGoldCup;
