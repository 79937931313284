/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useContext } from "react";

import { Box, Grid } from "@mui/material";
import { format } from "date-fns";
import ResultDoughnut from "../../../../common/ResultDoughnut";
import {
  getMark,
  getDoughnutPercentColor,
  getDoughnutData,
} from "../../../../../utils";
import { PortfolioSettingsContext } from "../../../../../context";
import { IGiaWorldSkillsEntity } from "../../../../../types";
import { EntityActionsMenuButton, IEntityActionMap, useCommonEntityHandlers } from "portfolio3/components/entityActions";
import { useDetailViewDrawer } from "hooks";

import "./index.scss";

interface IGiaDataEntityProps {
  entity: IGiaWorldSkillsEntity;
}

const GiaDataEntity: React.FC<IGiaDataEntityProps> = ({ entity }) => {
  const { resultScore, maxCompetenceScore, name, resultDate, cardData, entityType } = entity;

  const { getHandlers } = useCommonEntityHandlers();
  const { isSettingsMode } = useContext(PortfolioSettingsContext);
  const { handleOpenPrimaryDetailView } = useDetailViewDrawer();

  const { handleDelete, deleteDialogElement, handleReport } = getHandlers({
    formData: cardData.formData,
    cardData,
  });
  const doughnutData = getDoughnutData(resultScore, maxCompetenceScore);

  const handleOpenMoreEntityData = () => {
    handleOpenPrimaryDetailView(cardData, entity.entityProps.type);
  };

  const cardActions: IEntityActionMap = {
    view: {
      isDisabled: false,
      effect: handleOpenMoreEntityData,
    },
    delete: {
      isDisabled: false,
      effect: handleDelete(),
    },
    report: {
      effect: handleReport,
    },
  };

  const isActionsVisible = !isSettingsMode;

  return (
    <Box className="gia-data-entity">
      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        direction="column"
        justifyContent="space-between"
        style={{ height: "100%" }}
      >
        <Grid
          container
          item
          justifyContent="center"
          direction="column"
          alignItems="center"
        >
          <Box className="progeress-bar">
            <ResultDoughnut
              total={resultScore}
              data={doughnutData}
              color={getDoughnutPercentColor(resultScore, maxCompetenceScore)}
            />
          </Box>
          <p className="result">{getMark(resultScore, maxCompetenceScore)}</p>
          <h4 className="title">{name}</h4>
        </Grid>

        <Grid item>
          <p className="date-result">
            {resultDate
              ? `Проведен ${format(new Date(resultDate), "dd.MM.yyyy")}`
              : ""}
          </p>
        </Grid>
      </Grid>
      {isActionsVisible && (
        <Box className="btn-more">
        <EntityActionsMenuButton
          formData={cardData.formData}
          entityType={entityType}
          entityFlags={cardData.entityFlags ?? []}
          actionsMap={cardActions}
        />
        </Box>
      )}
      {deleteDialogElement}
    </Box>
  );
};

export default GiaDataEntity;
