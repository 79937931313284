import React from 'react';

import { Slide, Slider } from 'pure-react-carousel';

import { IModifiedReward } from '../../../types';
import Carousel from '../../common/Carousel';
import AwardBlock from './awardBlock';

import './index.scss';

interface IAwardsCarouselProps {
  awards: IModifiedReward[];
  loading: boolean;
}

const AwardsCarousel: React.FC<IAwardsCarouselProps> = ({ awards, loading }) => {
  return (
    <div className="award-carousel">
      <Carousel items={awards} cardWidth={200} gap={16}>
        <Slider>
          {awards.map((item, index) => (
            <Slide key={item.id || item.recordId} index={index} className="slide">
              <AwardBlock award={item} loading={loading} />
            </Slide>
          ))}
        </Slider>
      </Carousel>
    </div>
  );
};

export default AwardsCarousel;
