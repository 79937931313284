import { ElementRef, FC, Ref, useEffect, useImperativeHandle, useRef } from 'react';
import { useDateField } from 'react-aria';
import { DateFieldStateOptions, DatePickerState, useDateFieldState } from 'react-stately';

import { createCalendar } from '@internationalized/date';
import { Box } from '@mui/material';

import { IDateInputHandle, ModifySegmentsCallback } from '../../localTypes';
import { dateToCalendarDate, getDateFromYear } from '../../utils';
import DateFieldSegment from './DateFieldSegment';

interface IDateInputProps {
  fieldOptions: Partial<DateFieldStateOptions>;
  modifySegments?: ModifySegmentsCallback;
  isHidden: boolean;
  handle?: Ref<IDateInputHandle>;
  // проп для обновления стейта календаря в YearPicker
  singleDatePickerState?: DatePickerState;
}

const DateField: FC<IDateInputProps> = ({ fieldOptions, modifySegments, isHidden, handle, singleDatePickerState }) => {
  const dateFieldStateOptions: DateFieldStateOptions = {
    ...fieldOptions,
    locale: 'ru-RU',
    createCalendar,
  };

  const state = useDateFieldState(dateFieldStateOptions);

  const ref = useRef<ElementRef<'div'>>(null);
  const { fieldProps } = useDateField(dateFieldStateOptions, state, ref);

  const dateSegments = modifySegments ? modifySegments(state.segments) : state.segments;
  const yearSegment = dateSegments[dateSegments.length - 1];

  useImperativeHandle(handle, () => ({
    segments: dateSegments,
    clearSegments() {
      state.clearSegment('day');
      state.clearSegment('month');
      state.clearSegment('year');
    },
  }));

  /**
   * При ручном вводе или через стрелки клавиатуры, в YearPicker не устанавливается значение
   * Так как заполняется только год, а день и месяц остаются пустыми.
   * Решение: ставить месяц и день руками, как это сделано в меню
   */
  useEffect(() => {
    // только для YearPicker
    if (dateSegments.length !== 1) return;

    const inputYear = Number(yearSegment.text);

    if (!isNaN(inputYear)) {
      const calendarDate = dateToCalendarDate(getDateFromYear(inputYear));
      singleDatePickerState?.setDateValue(calendarDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearSegment.text]);

  return (
    <Box {...fieldProps} ref={ref} className="date-field" sx={{ display: 'flex', flexGrow: 1 }}>
      <Box
        className="date-field__inner"
        sx={{ display: 'flex', opacity: isHidden ? 0 : 1, transition: 'opacity 0.1s' }}
      >
        {dateSegments.map((segment, index) => {
          return <DateFieldSegment key={index} segment={segment} state={state} />;
        })}
      </Box>
    </Box>
  );
};

export default DateField;
