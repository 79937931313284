/* eslint-disable max-len */
import React from 'react';

const IconDiploma_2: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_10155_223582)">
      <path
        d="M42.7264 0.523926H8.89303C7.32114 0.523926 6.04688 1.79819 6.04688 3.37008V47.6778C6.04688 49.2497 7.32114 50.5239 8.89303 50.5239H42.7264C44.2982 50.5239 45.5725 49.2497 45.5725 47.6778V3.37008C45.5725 1.79819 44.2982 0.523926 42.7264 0.523926Z"
        fill="#CCCCCC"
      />
      <path
        d="M40.0032 6.76085C39.0224 5.23521 39.2468 3.54932 39.2468 3.54932H12.375C12.375 3.54932 12.5994 5.23521 11.6186 6.76085C10.6058 8.33137 8.625 8.46598 8.625 8.46598V42.575C8.625 42.575 10.6058 42.7096 11.6186 44.2801C12.5994 45.8057 12.375 47.4916 12.375 47.4916H39.2532C39.2532 47.4916 39.0288 45.8057 40.0096 44.2801C41.0224 42.7096 43.0032 42.575 43.0032 42.575V8.46598C43.0032 8.46598 41.0224 8.33137 40.0096 6.76085H40.0032Z"
        fill="#969696"
      />
      <mask
        id="mask0_10155_223582"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="8"
        y="3"
        width="36"
        height="45"
      >
        <path
          d="M40.0013 6.76085C39.0205 5.23521 39.2448 3.54932 39.2448 3.54932H12.373C12.373 3.54932 12.5974 5.23521 11.6166 6.76085C10.6038 8.33137 8.62305 8.46598 8.62305 8.46598V42.575C8.62305 42.575 10.6038 42.7096 11.6166 44.2801C12.5974 45.8057 12.373 47.4916 12.373 47.4916H39.2513C39.2513 47.4916 39.0269 45.8057 40.0077 44.2801C41.0205 42.7096 43.0013 42.575 43.0013 42.575V8.46598C43.0013 8.46598 41.0205 8.33137 40.0077 6.76085H40.0013Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_10155_223582)">
        <path d="M44.5541 6.76758H7.06055V8.42783H44.5541V6.76758Z" fill="#E83F40" />
        <path d="M44.5541 5.11377H7.06055V6.77403H44.5541V5.11377Z" fill="#748FFC" />
        <path d="M44.5541 3.51758H7.06055V5.17783H44.5541V3.51758Z" fill="white" />
      </g>
      <path
        d="M10.7012 10.5239V40.5239C10.7012 40.5239 12.4448 40.6457 13.3294 42.0239C14.1948 43.3637 13.9896 44.8444 13.9896 44.8444H37.6242C37.6242 44.8444 37.4255 43.3637 38.2845 42.0239C39.1755 40.6457 40.9127 40.5239 40.9127 40.5239V10.5239"
        stroke="#CCCCCC"
        strokeWidth="0.641026"
        strokeMiterlimit="10"
      />
      <path
        d="M28.1044 30.8248L20.3865 42.8696L19.2519 37.8312L14.4121 39.0107L22.4954 27.1196L28.1044 30.8248Z"
        fill="#CCCCCC"
      />
      <path
        d="M22.6172 30.8252L30.3351 42.8701L31.4698 37.8317L36.3095 39.0111L28.2262 27.1201L22.6172 30.8252Z"
        fill="#CCCCCC"
      />
      <path
        d="M22.1424 12.5237C23.9886 9.78009 28.0527 9.87625 29.7706 12.6968L29.8219 12.7865C33.0975 12.1903 35.9181 15.1455 35.1745 18.3891L35.1488 18.4916L35.2322 18.5493C37.9565 20.466 37.8411 24.5429 35.0078 26.2929L35.027 26.3955C35.6424 29.6455 32.6809 32.4339 29.4758 31.6262C27.6296 34.3698 23.5655 34.2737 21.8475 31.4532L21.7963 31.3634C18.5206 31.9596 15.7001 29.0044 16.4437 25.7609L16.4693 25.6583L16.386 25.6006C13.6617 23.6839 13.777 19.607 16.6104 17.857L16.5911 17.7545C15.9758 14.5045 18.9373 11.716 22.1424 12.5237Z"
        fill="#CCCCCC"
      />
      <path
        d="M34.6805 29.8635C33.7575 31.4083 31.8921 32.3314 29.9305 31.8442L29.5716 31.7545L29.3664 32.0622C27.6164 34.6711 23.7511 34.5814 22.1228 31.8955L21.8793 31.4917L21.3536 31.5878C19.5139 31.9211 17.8344 31.0494 16.9434 29.6455"
        stroke="#969696"
        strokeWidth="0.641026"
        strokeMiterlimit="10"
      />
      <path
        d="M22.8151 14.2738L23.11 13.8379C24.4818 11.7994 27.501 11.8699 28.7767 13.9661L29.0908 14.4853L29.6036 14.3891C32.0395 13.9468 34.1292 16.1391 33.578 18.5494L33.4434 19.1391L33.9369 19.4853C35.9626 20.9084 35.8728 23.934 33.7703 25.2353L33.328 25.5109L33.4434 26.1071C33.8985 28.5173 31.6998 30.5943 29.3216 29.9917L28.8087 29.8635L28.5139 30.2994C27.1421 32.3379 24.1228 32.2673 22.8472 30.1712L22.5331 29.652L22.0203 29.7481C19.5844 30.1904 17.4946 27.9981 18.0459 25.5879L18.1805 24.9981L17.6869 24.652C15.6613 23.2289 15.751 20.2032 17.8536 18.902L18.2959 18.6263L18.1805 18.0302C17.7254 15.6199 19.9241 13.543 22.3023 14.1456L22.8151 14.2738Z"
        stroke="#969696"
        strokeWidth="0.641026"
        strokeMiterlimit="10"
      />
      <path d="M25.1111 18.209V26.1705H23.4316V18.209H25.1111Z" fill="#969696" />
      <path d="M28.1814 18.209V26.1705H26.502V18.209H28.1814Z" fill="#969696" />
      <mask
        id="mask1_10155_223582"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="6"
        y="0"
        width="40"
        height="51"
      >
        <path
          d="M42.7283 0.523926H8.89498C7.32309 0.523926 6.04883 1.79819 6.04883 3.37008V47.6778C6.04883 49.2497 7.32309 50.5239 8.89498 50.5239H42.7283C44.3002 50.5239 45.5745 49.2497 45.5745 47.6778V3.37008C45.5745 1.79819 44.3002 0.523926 42.7283 0.523926Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_10155_223582)">
        <path
          opacity="0.3"
          d="M4.25436 29.0942C26.9511 29.0942 45.3505 10.6948 45.3505 -12.002C45.3505 -34.6988 26.9511 -53.0981 4.25436 -53.0981C-18.4424 -53.0981 -36.8418 -34.6988 -36.8418 -12.002C-36.8418 10.6948 -18.4424 29.0942 4.25436 29.0942Z"
          fill="url(#paint0_linear_10155_223582)"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_10155_223582"
        x1="-10.188"
        y1="-39.0661"
        x2="21.6262"
        y2="20.5557"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0" />
        <stop offset="0.35" stopColor="white" stopOpacity="0" />
        <stop offset="0.49" stopColor="white" stopOpacity="0.03" />
        <stop offset="0.59" stopColor="white" stopOpacity="0.08" />
        <stop offset="0.67" stopColor="white" stopOpacity="0.15" />
        <stop offset="0.74" stopColor="white" stopOpacity="0.24" />
        <stop offset="0.8" stopColor="white" stopOpacity="0.35" />
        <stop offset="0.85" stopColor="white" stopOpacity="0.47" />
        <stop offset="0.9" stopColor="white" stopOpacity="0.62" />
        <stop offset="0.95" stopColor="white" stopOpacity="0.79" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <clipPath id="clip0_10155_223582">
        <rect width="50" height="50" fill="white" transform="translate(0.810547 0.523926)" />
      </clipPath>
    </defs>
  </svg>
);

export default IconDiploma_2;
