import { SxProps, Theme } from '@mui/material';
import { SxStyles } from 'types';

export const contentWrapperStyles: SxStyles = (theme: Theme) => ({
  display: 'grid',
  gridAutoFlow: 'column',
  // удобнее работать с фиксированной шириной второго столбца
  gridTemplateColumns: 'minmax(0px, auto) 250px',
  gap: 3,
  height: '266px',

  marginTop: '20px',

  [theme.breakpoints.down('commonMd')]: {
    gridTemplateColumns: 'minmax(0px, auto) auto',
  },

  [theme.breakpoints.down('commonSm')]: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1.5,
    height: '100%',
  },
});

export const actionsStyles: SxStyles = (theme) => ({
  maxWidth: '288px',
  gap: '12px',
  marginTop: '-36px',

  [theme.breakpoints.down('commonSm')]: {
    maxWidth: '100%',
    marginTop: 0,
  },
});

export const clearAllStyles: SxProps = {
  '&.MuiButtonBase-root': {
    p: 0,
    m: 0,
    bgcolor: 'transparent',

    '&:hover': {
      bgcolor: 'transparent',
    },
  },
};

export const checkBoxStyles = (isActive: boolean, color: string) => ({
  '&.MuiButtonBase-root': {
    bgcolor: isActive ? color : 'tranparent',
    borderColor: color,
  },
});
