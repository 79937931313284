import { ComponentType, FC, PropsWithChildren } from 'react';

import { useUserFunctionality } from 'hooks';
import {
  DataEntryDrawersContainer,
  DetailViewDrawersContainer,
  EisDopRecommendationsDrawer,
  LinkSharingDrawer,
  ReportErrorDrawer,
  ThanksTeacherDrawer,
} from 'portfolio3/containers/drawers';
import { VuzDetailsDrawer } from 'portfolio3/features/vuzRecommendation';

const WithDrawersContainer: FC<PropsWithChildren> = ({ children }) => {
  const { isStudent, isParent } = useUserFunctionality();

  const withStudentDrawers = isStudent || isParent;

  return (
    <>
      {children}
      <DataEntryDrawersContainer />
      <DetailViewDrawersContainer />
      {withStudentDrawers && (
        <>
          <ReportErrorDrawer />
          <ThanksTeacherDrawer />
          <LinkSharingDrawer />
          <VuzDetailsDrawer />
          <EisDopRecommendationsDrawer />
        </>
      )}
    </>
  );
};

const withDrawers = () => {
  return <P extends PropsWithChildren<unknown>>(WrappedComponent: ComponentType<P>): FC<P> => {
    return function WithDrawers({ ...props }) {
      return (
        <WithDrawersContainer>
          <WrappedComponent {...props} />
        </WithDrawersContainer>
      );
    };
  };
};

export default withDrawers;
