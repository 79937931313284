import { useContext, useMemo } from 'react';

import { IDictionaryItem } from 'api/types';
import { PortfolioSettingsContext } from 'context';
import { useAppSelector } from 'hooks';
import { RadioGroupControl, RadioGroupController } from 'portfolio3/components/forms/RadioGroupControl';
import { BaseInputLabel, BaseToggleLabel, Checkbox, FormControl, SearchInput } from 'portfolio3/ui-kit';
import { Select, TokenFieldSelect } from 'portfolio3/ui-kit/selects';
import { ToggleOutline } from 'portfolio3/ui-kit/toggles';
import { InputController, SelectController } from 'portfolio3/ui-kit/types';

import { MobileSpecialitiesControl, PassingBallControl, SubjectsListControl } from '../components';
import {
  MilitaryTrainingTypeCodes,
  MilitaryTrainingTypeNames,
  TrainingTypeCodes,
  TrainingTypeNames,
  VuzRecommendationTab,
} from '../const';
import { VuzLocalFiltersContext } from '../context';
import { VuzRecommendationContext } from '../context/vuzRecommendationContext';
import { maxSpecialitiesBallSelector } from '../model/selectors';

const trainingTypeOptions: IDictionaryItem[] = [
  {
    code: TrainingTypeCodes.bachelor,
    value: TrainingTypeNames.bachelor,
  },
  {
    code: TrainingTypeCodes.specialist,
    value: TrainingTypeNames.specialist,
  },
];

const militaryTrainingOptions: IDictionaryItem[] = [
  {
    code: MilitaryTrainingTypeCodes.warDepartment,
    value: MilitaryTrainingTypeNames.warDepartment,
  },
  {
    code: MilitaryTrainingTypeCodes.warCenter,
    value: MilitaryTrainingTypeNames.warCenter,
  },
];

interface IUseFilterControls {
  subjectOptions: IDictionaryItem[];
  specialityOptions: IDictionaryItem[];
}

const useFilterControls = ({ subjectOptions, specialityOptions }: IUseFilterControls) => {
  const maxSpecialityBall = useAppSelector(maxSpecialitiesBallSelector);

  const { currentTab } = useContext(VuzRecommendationContext);
  const { localFilters, setLocalFilter } = useContext(VuzLocalFiltersContext);
  const { isSettingsMode } = useContext(PortfolioSettingsContext);

  const {
    search,
    subjects,
    specialities,
    trainingTypeCode,
    militaryTrainingTypeCode,
    minPassingBall,
    maxPassingBall,
    isHigherBallToggle,
  } = localFilters;

  // Поиск
  const searchController: InputController = {
    handleChange(value) {
      setLocalFilter('search', value);
    },
  };
  const searchControl = (
    <SearchInput
      renderMode="fixed"
      size="medium"
      placeholder="Поиск..."
      value={search}
      controller={searchController}
      disabled={isSettingsMode}
    />
  );

  // Предметы
  const subjectControlLabel = useMemo(() => {
    if (currentTab === VuzRecommendationTab.REAL_GIA) return 'Предметы итогового ЕГЭ';
    if (currentTab === VuzRecommendationTab.TRIAL_GIA) return 'Предметы пробного ЕГЭ';

    return 'Предметы';
  }, [currentTab]);

  const handleToggleSubjects = (code: number) => {
    const hasCode = subjects.includes(code);
    const newSubjects = hasCode ? subjects.filter((subjectCode) => subjectCode !== code) : [...subjects, code];
    setLocalFilter('subjects', newSubjects);
  };
  const subjectsControl = (
    <SubjectsListControl
      options={subjectOptions}
      selectedCodes={subjects}
      onToggle={handleToggleSubjects}
      disabled={isSettingsMode}
    />
  );
  const mobileSubjectsControl = (
    <FormControl
      renderMode="fixed"
      label={<BaseInputLabel>{subjectControlLabel}</BaseInputLabel>}
      control={subjectsControl}
    />
  );

  // Специальности
  const specialitiesController: SelectController = {
    handleChange(value) {
      const valueMumbers =
        value
          ?.split(',')
          .map((value) => Number(value))
          .filter(Boolean) ?? [];

      setLocalFilter('specialities', valueMumbers);
    },
  };
  const handleChangeMobileSpecialities = (specialityCode: number) => {
    const hasSpeciality = specialities.includes(specialityCode);
    const newSpecialities = hasSpeciality
      ? specialities.filter((code) => code !== specialityCode)
      : [...specialities, specialityCode];

    setLocalFilter('specialities', newSpecialities);
  };
  const specialitiesControl = (
    <FormControl
      renderMode="fixed"
      label={<BaseInputLabel>Специальность</BaseInputLabel>}
      disabled={isSettingsMode}
      control={
        <TokenFieldSelect
          inputRenderMode="fixed"
          inputSize="medium"
          placeholder="Выберите специальность..."
          value={specialities}
          options={specialityOptions}
          controller={specialitiesController}
        />
      }
    />
  );
  const mobileSpecialitiesControl = (
    <MobileSpecialitiesControl
      value={specialities}
      options={specialityOptions}
      onChange={handleChangeMobileSpecialities}
      disabled={isSettingsMode}
    />
  );

  // Тип подготовки
  const trainingTypeController: SelectController = {
    handleChange(value) {
      setLocalFilter('trainingTypeCode', Number(value));
    },
  };
  const mobileTrainingTypeController: RadioGroupController = {
    handleChange(trainingType) {
      setLocalFilter('trainingTypeCode', trainingType);
    },
  };
  const trainingTypeControl = (
    <FormControl
      renderMode="fixed"
      disabled={isSettingsMode}
      label={<BaseInputLabel>Тип подготовки</BaseInputLabel>}
      control={
        <Select
          inputRenderMode="fixed"
          inputSize="medium"
          placeholder="Выберите тип..."
          value={trainingTypeCode}
          options={trainingTypeOptions}
          controller={trainingTypeController}
        />
      }
    />
  );
  const mobileTrainingTypeControl = (
    <RadioGroupControl
      isMobile
      label="Тип подготовки"
      value={trainingTypeCode}
      options={trainingTypeOptions}
      controller={mobileTrainingTypeController}
      disabled={isSettingsMode}
    />
  );

  // Военная подготовка
  const militaryTrainingController: SelectController = {
    handleChange(value) {
      setLocalFilter('militaryTrainingTypeCode', Number(value));
    },
  };
  const mobileMilitaryTrainingTypeController: RadioGroupController = {
    handleChange(militaryTrainingType) {
      setLocalFilter('militaryTrainingTypeCode', militaryTrainingType);
    },
  };
  const militaryTrainingControl = (
    <FormControl
      renderMode="fixed"
      disabled={isSettingsMode}
      label={<BaseInputLabel>Военная подготовка</BaseInputLabel>}
      control={
        <Select
          inputRenderMode="fixed"
          inputSize="medium"
          placeholder="Выберите тип..."
          value={militaryTrainingTypeCode}
          options={militaryTrainingOptions}
          controller={militaryTrainingController}
        />
      }
    />
  );
  const mobileMilitaryTrainingTypeControl = (
    <RadioGroupControl
      isMobile
      label="Военная подготовка"
      value={militaryTrainingTypeCode}
      options={militaryTrainingOptions}
      controller={mobileMilitaryTrainingTypeController}
      disabled={isSettingsMode}
    />
  );

  // Рекомендации с превышающим баллом
  const handleChangeHigherBallToggle = (checked: boolean) => {
    setLocalFilter('isHigherBallToggle', checked);
  };
  const higherBallToggleControl = (
    <ToggleOutline
      size="small"
      label="Рекомендации с превышающим баллом"
      checked={isHigherBallToggle}
      onChange={(_, checked) => handleChangeHigherBallToggle(checked)}
      disabled={isSettingsMode}
    />
  );
  const mobileHigherBallToggleControl = (
    <BaseToggleLabel
      checked={isHigherBallToggle}
      onChange={(_, checked) => handleChangeHigherBallToggle(checked)}
      label="Рекомендации с превышающим баллом"
      control={<Checkbox />}
      disabled={isSettingsMode}
    />
  );

  // Проходной балл
  const minPassingBallController: InputController = {
    handleChange(value) {
      setLocalFilter('minPassingBall', value ? Number(value) : undefined);
    },
  };
  const maxPassingBallController: InputController = {
    handleChange(value) {
      setLocalFilter('maxPassingBall', value ? Number(value) : undefined);
    },
  };
  const passingBallControl = (
    <PassingBallControl
      minValue={minPassingBall}
      maxValue={maxPassingBall}
      maxPossibleValue={maxSpecialityBall}
      minController={minPassingBallController}
      maxController={maxPassingBallController}
      disabled={isSettingsMode}
    />
  );

  return {
    searchControl,
    subjectsControl,
    mobileSubjectsControl,
    specialitiesControl,
    mobileSpecialitiesControl,
    trainingTypeControl,
    mobileTrainingTypeControl,
    militaryTrainingControl,
    mobileMilitaryTrainingTypeControl,
    higherBallToggleControl,
    mobileHigherBallToggleControl,
    passingBallControl,
  };
};

export default useFilterControls;
