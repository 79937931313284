import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root: SxStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  padding: '16px',
};

export const description: SxStyles = {
  width: '280px',
  textAlign: 'center',
  color: NeutralColors.light_neutrals_600,
};

export const qrCodeImage: SxStyles = {
  width: '200px',
  height: '200px',

  marginTop: '60px',

  transform: 'scale(1.48)',
};

export const closeButton: SxStyles = {
  width: '100%',
  marginTop: '60px',
};
