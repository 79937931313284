import { FC, PropsWithChildren } from 'react';

import { Box } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';

const SearchSectionDivider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box className="search-section-divider" sx={{ height: '1px', backgroundColor: NeutralColors.light_neutrals_300 }}>
      {children}
    </Box>
  );
};

export default SearchSectionDivider;
