/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useRef, useState } from 'react';

import Popper from '../../../../common/Popper';
import { IconClose } from '../../../../../icons';
import { CommonInterestKindsProps } from './types';
import { IInterestKind } from '../../types';
import { useHiddenHorizontalList } from '../../../../../hooks';

import './index.scss';

interface IDesktopInterestKindsProps extends CommonInterestKindsProps {}

const DesktopInterestKinds: React.VFC<IDesktopInterestKindsProps> = ({
  interestKinds,
  theme,
  selectedKinds,
  onSelectKind
}) => {
  const [isMorePopperOpen, setIsMorePopperOpen] = useState(false);
  const filtersWrapperRef = useRef<HTMLDivElement>(null);
  const buttonMoreRef = useRef<HTMLButtonElement>(null);

  const { visibleElementsCount } = useHiddenHorizontalList({
    containerRef: filtersWrapperRef,
    buttonMoreRef,
    elementsGap: 8,
    allElementsCount: interestKinds.length,
    hiddenElementClassName: 'visually-hidden',
    additionalDeps: [selectedKinds],
    onAllElementsVisible: () =>  setIsMorePopperOpen(false)
  });

  const handleMoreOpen = () => {
    setIsMorePopperOpen(true);
  };

  const handleMoreClose = () => {
    setIsMorePopperOpen(false);
  };

  const renderKindsList = (kinds: IInterestKind[]) => {
    return kinds.map((kind) => {
      const isSelected = selectedKinds?.find((selectedId) => kind.code === selectedId) !== undefined;

      return (
        <button
          key={kind.id}
          type="button"
          className={`interests-kinds__kind ${isSelected ? 'interests-kinds__kind--selected' : ''}`}
          onClick={onSelectKind(kind.code)}
        >
          {kind.name}
          {isSelected && <IconClose className="interests-kinds__icon-selected" />}
        </button>
      );
    });
  };

  return (
    <div className="interests-kinds interests-kinds--desktop" ref={filtersWrapperRef}>
      {renderKindsList(interestKinds)}
      <button
        ref={buttonMoreRef}
        className="interests-kinds__kind interests-kinds__button-more visually-hidden"
        onClick={handleMoreOpen}
      >
        Ещё
        <div className="dots">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </button>

      <Popper
        open={isMorePopperOpen}
        anchorEl={buttonMoreRef.current}
        onClosePopperMenu={handleMoreClose}
        placement="bottom-end"
        className="interests-kinds__more-popper"
      >
        <div className="interests-kinds__more">
          {renderKindsList(interestKinds.slice(visibleElementsCount))}
        </div>
      </Popper>
    </div>
  );
};

export default DesktopInterestKinds;
