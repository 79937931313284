/* eslint-disable prettier/prettier */
/* eslint-disable */

import genericReducers from '../genericReducer';
import { connectReducers } from '../../utils';
import { IReducers } from '../index';

import { GET_INTEREST_GROUP_KINDS } from '../../actions';
import { REQUEST, FAILURE, SUCCESS } from '../../actions/utils';

import { ICommonResponse, IInterestGroupKind } from '../../api/types';

export type InterestGroupKindsState = ICommonResponse<IInterestGroupKind>;

const initialState = {
  content: [],
  loading: false
} as InterestGroupKindsState;

const interestGroupKinds: IReducers<InterestGroupKindsState> = {
  [GET_INTEREST_GROUP_KINDS[REQUEST]]: genericReducers().get.request,
  [GET_INTEREST_GROUP_KINDS[SUCCESS]]: genericReducers().get.success,
  [GET_INTEREST_GROUP_KINDS[FAILURE]]: genericReducers().get.failure
};

export default connectReducers(initialState, interestGroupKinds);
