/* eslint-disable prettier/prettier */
/* eslint-disable */

import { routes } from '../const';

export const getSharedLinkUrl = (urlHash: string) => {
  const sharedPortfolioUrl = `${window.location.origin}${routes.SHARED_LINK.path}`;
  return `${sharedPortfolioUrl}/${urlHash}`;
};

export const clearSharingCookie = () => {
  document.cookie = 'SHARE= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/';
};

export const getSharedLinkString = () => {
  const urlPartsArray = window.location.href.split('/');
  let urlString = '';
  urlPartsArray.findIndex((part, index) => {
    if (part === 'shared-link') {
      urlString = urlPartsArray[index + 1];
      return index;
    }
  });
  return urlString;
};
