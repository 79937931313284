/* eslint-disable prettier/prettier */
/* eslint-disable */

import { call, put } from 'redux-saga/effects';

import {
  getUserContext,
  getLinkSettings,
  getFeedbackLink,
  apiImpl
} from '../api';

import {
  getLinkSettingsActions,
  getStudentInfoActions,
  getUserContextActions,
  setLinkErrorAction,
  setLinkModeAction,
  getFeedbackLinkActions,
  setUserThemeSettingsAction
} from '../actions';

import { getAuthToken, redirectToChoicePage } from '../utils';
import { GenericGetAction } from '.';
import { ContextInternalCodesThatShouldNotRedirectToAuthPage, RedirectToServiceErrorPageHttpCodes } from 'const';

function* initializationSaga() {
  const token = getAuthToken();

  if (token) {
    yield call(apiImpl.setToken, token);
    yield put(getUserContextActions.request());
  } else {
    redirectToChoicePage();
  }
}

function* getUserContextSaga() {
  const { response, error } = yield call(getUserContext);
  const errorCode: number | undefined = error?.code;
  const statusCode: number | undefined = error?.statusCode;

  if (response) {
    yield put(getUserContextActions.success(response));
    return;
  }

  // http статус коды которые должны показать особую сраницу ошибки (нет редиректа на /choice)
  const httpStatusKeepOnCurrentPage = statusCode && RedirectToServiceErrorPageHttpCodes.includes(statusCode);
  // внутренние коды ошибок которые НЕ должны редиректить на /choice (нет редиректа на /choice)
  const internalCodeKeepOnCurrentPage = errorCode && ContextInternalCodesThatShouldNotRedirectToAuthPage.includes(errorCode);

  const keepOnCurrentPage = httpStatusKeepOnCurrentPage || internalCodeKeepOnCurrentPage;

  if (keepOnCurrentPage) {
    yield put(getUserContextActions.failure(error));
    return;
  }

  redirectToChoicePage();
}

function* getLinkSettingsSaga({ payload }: GenericGetAction<any>) {
  const { response, error } = yield call(getLinkSettings, payload);

  if (response) {
    yield put(getStudentInfoActions.request(response.data.personId));
    yield put(setUserThemeSettingsAction(response.data.themeSettings));
    yield put(setLinkModeAction(true));
    yield put(getLinkSettingsActions.success(response));
  } else {
    yield put(setLinkErrorAction(true, error.code));
    yield put(getLinkSettingsActions.failure(error));
  }
}

function* getFeedbackLinkSaga() {
  const { response, error } = yield call(getFeedbackLink);

  if (response) {
    yield put(getFeedbackLinkActions.success(response));
    window.location.href = response.data.url;
  } else {
    yield put(getFeedbackLinkActions.failure(error));
  }
}

export {
  getUserContextSaga,
  getLinkSettingsSaga,
  getFeedbackLinkSaga,
  initializationSaga
};
