import { SxStyles } from 'types';

export const tabsContainer: SxStyles = () => ({
  marginBlock: '12px',
});

export const chartTitleRow = (isMobile?: boolean): SxStyles => ({
  display: 'flex',
  alignItems: 'center',
  paddingInline: isMobile ? 0 : '22px',
  zIndex: 2,
  width: '100%',
});

export const chartTitlePlaceBar: SxStyles = ({ breakpoints }) => ({
  width: `65%`,

  [breakpoints.down('commonMd')]: {
    width: `65%`,
  },

  [breakpoints.down('commonSm')]: {
    width: '100%',
  },
});

export const resultContainer: SxStyles = ({ breakpoints }) => ({
  width: 'calc(35% - 12px)',
  paddingLeft: '12px',

  [breakpoints.down('commonMd')]: {
    width: 'calc(40% - 12px)',
  },

  [breakpoints.down('commonSm')]: {
    width: '45%',
  },

  [breakpoints.down(600)]: {
    width: '65%',
  },
});
