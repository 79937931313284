import { FC } from 'react';

import { buttonBaseClasses, styled, Switch, switchClasses, SwitchProps } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';

import { getFocusStateStyles } from '../utils';
import { getToggleTokens } from '../utils/tokens';

export type IToggleProps = SwitchProps;

const StyledSwitch = styled(Switch)(({ size }) => {
  const tokens = size ? getToggleTokens(size) : getToggleTokens('medium');

  const { width, height, padding } = tokens;
  const thumbSize = height - padding * 2;

  return {
    position: 'relative',
    width,
    height,

    padding: 0,
    overflow: 'initial',

    // root
    [`& .${switchClasses.switchBase}`]: {
      justifyContent: 'flex-start',

      width: '100%',
      height: '100%',

      padding: 0,

      borderRadius: 100,
      transform: 'none',

      [`&, &:hover`]: {
        backgroundColor: NeutralColors.light_neutrals_400,
      },

      [`&.${switchClasses.disabled}`]: {
        backgroundColor: NeutralColors.light_neutrals_300,
      },
    },

    // root checked
    [`& .${switchClasses.switchBase}.${switchClasses.checked}`]: {
      transform: 'none',

      [`&, &:hover`]: {
        backgroundColor: '#40C057',
      },

      [`&.${switchClasses.disabled}`]: {
        opacity: 0.4,
      },
    },

    // thumb
    [`& .${switchClasses.thumb}`]: {
      width: thumbSize,
      height: thumbSize,

      backgroundColor: '#ffffff',
      boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.15)',

      transition: 'transform 0.1s',
      transform: `translateX(${padding}px)`,
    },

    [`& .${switchClasses.switchBase}.${switchClasses.checked} .${switchClasses.thumb}`]: {
      transform: `translateX(${width - thumbSize - padding}px)`,
    },

    // thumb checked shadow
    [`&.${switchClasses.checked} .${switchClasses.thumb}`]: {
      boxShadow: '0px 1px 2px rgba(41, 41, 64, 0.07), -1px 1.5px 3px rgba(0, 0, 0, 0.12)',
    },

    // thumb disabled shadow
    [`&.${switchClasses.disabled} .${switchClasses.thumb}`]: {
      boxShadow: '0px 3px 6px rgba(105, 105, 105, 0.08)',
    },

    // thumb disabled/checked shadow
    [`&.${switchClasses.disabled}.${switchClasses.checked} .${switchClasses.thumb}`]: {
      boxShadow: '0px 1px 2px rgba(41, 41, 64, 0.07)',
    },

    [`& .${switchClasses.track}`]: {
      display: 'none',
    },

    [`& .${switchClasses.switchBase} .${switchClasses.input}`]: {
      left: 0,
      width: '100%',
    },

    [`& .${buttonBaseClasses.focusVisible}`]: {
      ...getFocusStateStyles(),
    },
  };
});

const Toggle: FC<IToggleProps> = (props) => {
  return <StyledSwitch {...props} disableRipple />;
};

export default Toggle;
