import { createSelector } from 'reselect';

import { ICommonResponse } from '../../../api/types';
import { IRootState } from '../../../reducers';
import { IClassRecommendationEntity } from '../../../types';
import { getProffClassRecommendationImage } from '../../../utils';

const profileClassRecommendationsSelector = createSelector(
  [(state: IRootState) => state.studentClassRecommendations],
  (studentClassRecommendations): ICommonResponse<IClassRecommendationEntity> => {
    if (!studentClassRecommendations.content) {
      return {
        content: [],
        loading: studentClassRecommendations.loading,
      };
    }

    const classRecommendations = studentClassRecommendations.content.map(
      (recommendation): IClassRecommendationEntity => {
        return {
          isLocalSchool: recommendation.isLocalSchool,
          name: recommendation.name,
          image: getProffClassRecommendationImage(recommendation.code),
          link: recommendation.linkInfo,
        };
      },
    );

    return {
      content: classRecommendations,
      loading: studentClassRecommendations.loading,
    };
  },
);

export default profileClassRecommendationsSelector;
