import { FC } from 'react';

import { Box, Grid } from '@mui/material';
import { meshLoader } from 'images';

import * as styles from './styles';

const AppLoader: FC = () => {
  return (
    <Box sx={styles.whiteBackground}>
      <Box sx={styles.background}>
        <Grid container justifyContent="center" alignItems="center" sx={styles.background}>
          <Grid item>
            <Box component="img" src={meshLoader} alt="загрузка" sx={styles.image} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AppLoader;
