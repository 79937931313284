import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const body: SxStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

export const description: SxStyles = {
  zIndex: 10,
  textAlign: 'center',
  color: NeutralColors.light_neutrals_600,
};

export const qrCodeImage: SxStyles = {
  width: '200px',
  height: '200px',

  marginTop: '20px',

  transform: 'scale(1.48)',
};
