import React from 'react';
import { connect } from 'react-redux';

import { Box, Typography } from '@mui/material';
import Table from 'portfolio3/components/common/Table';
import { Button } from 'portfolio3/ui-kit/button';

import { getImportReportActions } from '../../../../actions/employees';
import { IconDownload } from '../../../../icons';
import ImportResultDoughnut from '../doughnut';
import * as styles from './styles';

interface IChangeHistoryImportItemProps {
  id: number;
  section: string;
  path: string[];
  date: string;
  name: string;
  icon: JSX.Element;
  successCount: number;
  failCount: number;
  getImportReport: typeof getImportReportActions.request;
}

const ChangeHistoryImportItem: React.FC<IChangeHistoryImportItemProps> = ({
  id,
  section,
  path,
  date,
  name,
  icon,
  successCount,
  failCount,
  getImportReport,
}) => {
  const handleDownloadImportReport = () => {
    getImportReport(id);
  };

  return (
    <Table.Row>
      <Table.DataCell>
        <Box sx={styles.sectionContainer}>
          {icon}
          <Typography variant="Paragraph MD/Regular">{section}</Typography>
        </Box>
      </Table.DataCell>
      <Table.DataCell>
        {path.map((pathElement) => (
          <Typography variant="Paragraph MD/Regular" key={pathElement}>
            {pathElement}
          </Typography>
        ))}
      </Table.DataCell>
      <Table.DataCell>
        <Typography variant="Paragraph MD/Regular">{date}</Typography>
      </Table.DataCell>
      <Table.DataCell>
        <Typography variant="Paragraph MD/Regular">{name}</Typography>
      </Table.DataCell>
      <Table.DataCell>
        <Box sx={styles.statusContainer}>
          <Box sx={styles.statusChartContainer}>
            <ImportResultDoughnut successCount={successCount} failCount={failCount} />
            <Box>
              <Typography variant="Paragraph MD/Regular">{`Успешно: ${successCount}`}</Typography>
              <Typography variant="Paragraph MD/Regular">{`Неуспешно ${failCount}`}</Typography>
            </Box>
          </Box>

          <Button variant="text" iconRight={<IconDownload />} onClick={handleDownloadImportReport}>
            Скачать отчёт
          </Button>
        </Box>
      </Table.DataCell>
    </Table.Row>
  );
};

export default connect(undefined, {
  getImportReport: getImportReportActions.request,
})(ChangeHistoryImportItem);
