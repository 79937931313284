import { ReactNode } from 'react';

import { AccentColorType } from 'portfolio3/styles';
import { ICardData, ITab } from 'types';

export enum DetailViewTab {
  GENERAL = 1,
  FILES = 2,
  LINKED_OBJECTS = 3,
}

export interface ICommonDrawerProps {
  isOpen: boolean;
  title: string;
  accentColor: AccentColorType;
  image: string | null;
  footer: ReactNode;
  cardData: ICardData | undefined;
  onClose: () => void;

  isTabsShown: boolean;
  tabs: ITab[];
  currentTab?: number;
  onChangeTab: (tab: number) => void;
}
