import { DIAGNOSTIC_LEVEL_TYPE_ALL_CODE } from 'const';
import { IRootState } from 'reducers';
import { createSelector } from 'reselect';
import { IIndependentDiagnosticLevelType } from 'types';

import independentDiagnosticListSelector from './independentDiagnosticList';

const independentDiagnosticListLevelTypesSelector = createSelector(
  [(state: IRootState) => independentDiagnosticListSelector(state)],
  (independentDiagnostics): IIndependentDiagnosticLevelType[] => {
    const levelTypesMap: Map<string, number> = new Map();

    independentDiagnostics.content.forEach((diagnostic) => {
      const { levelType } = diagnostic;
      if (!levelType) return;

      const count = levelTypesMap.get(levelType) || 0;
      levelTypesMap.set(levelType, count + 1);
    });

    const levelTypes = Array.from(levelTypesMap.entries()).map(
      ([name, count], index): IIndependentDiagnosticLevelType => {
        return {
          code: index + 1,
          value: name,
          count,
        };
      },
    );

    const totalCount = levelTypes.reduce((count, type) => type.count + count, 0);

    levelTypes.unshift({
      code: DIAGNOSTIC_LEVEL_TYPE_ALL_CODE,
      value: 'Все',
      count: totalCount,
    });

    return levelTypes;
  },
);

export default independentDiagnosticListLevelTypesSelector;
