/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import DiagnocticDoughnutStats from '../DiagnocticDoughnutStats/self';
import PdfCommonDataEntityContainer from '../../../containers/pdfStudent/dataEntityContainers/common';
import IndependentDiagnosticComponent from './IndependentDiagnostics';
import { getPdfStudentDiagnosisFullActions, getPdfStudentDiagnosisYearActions, getStudentIndependentDiagnosisActions } from '../../../actions';
import { DiagnosticCountCode, DiagnosticCountKeys, SelfDiagnosticPeriodTypeCode } from '../../../const';
import { IChildInfo, IDiagnosisResponse, IIndependentDiagnosticResponse, ISectionsVisibility } from '../../../api/types';
import { IRootState } from '../../../reducers';

import './index.scss';
import { widgetPdfIcons } from 'icons';

interface IPdfDiagnosticsProps {
  displaySettings: ISectionsVisibility | undefined;
  currentStudent: IChildInfo;
  pdfStudentDiagnosisFull: IDiagnosisResponse;
  pdfStudentDiagnosisYear: IDiagnosisResponse;
  pdfStudentIndependentDiagnosisFull: IIndependentDiagnosticResponse;
  getPdfStudentDiagnosisYear: typeof getPdfStudentDiagnosisYearActions.request;
  getPdfStudentDiagnosisFull: typeof getPdfStudentDiagnosisFullActions.request;
  getPdfStudentIndependentDiagnosis: typeof getStudentIndependentDiagnosisActions.request;
}

const PdfDiagnostics: React.FC<IPdfDiagnosticsProps> = ({
  displaySettings,
  currentStudent,
  pdfStudentDiagnosisYear,
  pdfStudentDiagnosisFull,
  pdfStudentIndependentDiagnosisFull,
  getPdfStudentDiagnosisFull,
  getPdfStudentDiagnosisYear,
  getPdfStudentIndependentDiagnosis
}) => {
  useEffect(() => {
    if (!currentStudent?.meshId) return;
    getPdfStudentDiagnosisYear(currentStudent.meshId, SelfDiagnosticPeriodTypeCode.year);
    getPdfStudentDiagnosisFull(currentStudent.meshId, SelfDiagnosticPeriodTypeCode.full);
    getPdfStudentIndependentDiagnosis(currentStudent.meshId, SelfDiagnosticPeriodTypeCode.full, DiagnosticCountCode[DiagnosticCountKeys.VISIBLE]);
  }, [currentStudent.meshId]);

  let isDiagnosisYearExist = pdfStudentDiagnosisYear.content?.selfDiagnosticSubjects?.length !== 0;
  let isDiagnosisFullExist = pdfStudentDiagnosisFull.content?.selfDiagnosticSubjects?.length !== 0;

  const { content: independentDiagnostics } = pdfStudentIndependentDiagnosisFull;
  const isIndependentTotalExist =  !!independentDiagnostics?.totalDiagnostic;
  const isIndependentSelfExist = independentDiagnostics?.selfDiagnostic.length !== 0;

  return (
    <PdfCommonDataEntityContainer
      title="Диагностики"
      icon={widgetPdfIcons.pdfIconStudyDiagnostics}
    >
      {displaySettings?.independentDiagnostic && (
        <div className="pdf-diagnostic-block">
            <h5 className="pdf-diagnostic-block__title">Независимые диагностики</h5>
            {isIndependentTotalExist || isIndependentSelfExist ? (
              <IndependentDiagnosticComponent independentDiagnostics={pdfStudentIndependentDiagnosisFull} />
            ) : (
              <p>Данные отсутствуют</p>
            )}
        </div>
      )}
      {displaySettings?.selfDiagnostic && (
        <>
          <div className="pdf-diagnostic-block pdf-keep">
            <h5 className="pdf-diagnostic-block__title">Пройденные диагностики за год</h5>
            {isDiagnosisYearExist && pdfStudentDiagnosisYear.content ? (
              <DiagnocticDoughnutStats studentDiagnosis={pdfStudentDiagnosisYear.content} bestSubject={pdfStudentDiagnosisYear.content?.bestResult} />
            ) : (
              <p>Нет данных о диагностиках за год</p>
            )}
          </div>
          <div className="pdf-diagnostic-block pdf-keep">
            <h5 className="pdf-diagnostic-block__title">Пройденные диагностики за все время</h5>

            {isDiagnosisFullExist && pdfStudentDiagnosisFull.content ? (
              <DiagnocticDoughnutStats studentDiagnosis={pdfStudentDiagnosisFull.content} bestSubject={pdfStudentDiagnosisFull.content?.bestResult} />
            ) : (
              <p>Нет данных о диагностиках за все время</p>
            )}
          </div>
        </>
      )}
    </PdfCommonDataEntityContainer>
  );
};

export default connect(
  (state: IRootState) => ({
    currentStudent: state.currentStudent,
    pdfStudentDiagnosisFull: state.pdfStudentDiagnosisFull,
    pdfStudentDiagnosisYear: state.pdfStudentDiagnosisYear,
    pdfStudentIndependentDiagnosisFull: state.studentIndependentDiagnosis
  }),
  {
    getPdfStudentDiagnosisFull: getPdfStudentDiagnosisFullActions.request,
    getPdfStudentDiagnosisYear: getPdfStudentDiagnosisYearActions.request,
    getPdfStudentIndependentDiagnosis: getStudentIndependentDiagnosisActions.request
  }
)(PdfDiagnostics);
