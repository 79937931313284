import { FC, PropsWithChildren } from 'react';

import { Box } from '@mui/material';
import { SxStyles } from 'types';
import { mergeSx } from 'utils';

const styles = (color: string, image?: string): SxStyles => {
  return (theme) => ({
    paddingBlock: '16px',

    borderRadius: '12px',
    backgroundColor: color,
    backgroundImage: `url(${image})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right -30px top',

    [theme.breakpoints.down('commonSm')]: {
      paddingBlock: '12px',
      backgroundImage: 'none',
    },
  });
};

interface IBackgroundContainerProps {
  color: string;
  image?: string;
  sx?: SxStyles;
}

const BackgroundContainer: FC<IBackgroundContainerProps & PropsWithChildren> = ({ color, image, sx, children }) => {
  return <Box sx={mergeSx(styles(color, image), sx)}>{children}</Box>;
};

export default BackgroundContainer;
