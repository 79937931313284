import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconHeartOutline: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M7.22 6c-.864 0-1.674.334-2.28.941a3.241 3.241 0 0 0 0 4.573L12 18.585l7.06-7.071a3.24 3.24 0 0 0 0-4.573c-1.212-1.215-3.348-1.213-4.56 0l-1.792 1.795a1.028 1.028 0 0 1-1.416 0L9.5 6.94A3.2 3.2 0 0 0 7.22 6ZM12 21a.994.994 0 0 1-.707-.294l-7.768-7.78a5.245 5.245 0 0 1 0-7.399A5.19 5.19 0 0 1 7.22 4c1.399 0 2.712.543 3.695 1.527L12 6.614l1.085-1.086A5.187 5.187 0 0 1 16.78 4c1.398 0 2.71.543 3.694 1.527a5.244 5.244 0 0 1 0 7.399l-7.767 7.781A1 1 0 0 1 12 21Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconHeartOutline;
