import { FC } from 'react';

import { Box, ThemeProvider, Typography } from '@mui/material';
import Table from 'portfolio3/components/common/Table';
import { AccentColorType } from 'portfolio3/styles';
import { generateAccentTheme } from 'portfolio3/styles/theme';
import { AccentBadge } from 'portfolio3/ui-kit/badges';
import { mergeSx } from 'utils';

import { IconArrowRight } from '../../../../icons';
import * as styles from './styles';

const getActionName = (action: string) => {
  switch (action) {
    case 'change':
      return 'изменение настроек';
    case 'enable':
      return 'включение раздела';
    case 'disable':
      return 'отключение раздела';
    default:
      return '';
  }
};

const getActionAccentColor = (action: string): AccentColorType => {
  switch (action) {
    case 'change':
      return 'orange';
    case 'enable':
      return 'green';
    case 'disable':
      return 'red';
    default:
      return 'blue';
  }
};

interface IAdminHistoryItemProps {
  id: number;
  icon: JSX.Element;
  entity: string;
  author: string;
  date: string;
  action: string;
  prevInfo?: string;
  currentInfo?: string;
  comment?: string;
}

const AdminHistoryItem: FC<IAdminHistoryItemProps> = ({
  id,
  date,
  icon,
  entity,
  author,
  action,
  prevInfo,
  currentInfo,
  comment,
}) => {
  const actionName = getActionName(action);
  const accentColor = getActionAccentColor(action);

  const density = 'medium';

  return (
    <Table.Row>
      {/* Автор */}
      <Table.DataCell density={density}>
        <Typography variant="Paragraph MD/Regular">{`#${id}`}</Typography>
      </Table.DataCell>
      {/* Сущность */}
      <Table.DataCell density={density}>
        <Box sx={styles.entityNameContainer}>
          {icon}
          <Typography variant="Paragraph MD/Regular">{entity}</Typography>
        </Box>
      </Table.DataCell>
      {/* Действие */}
      <Table.DataCell density={density}>
        <ThemeProvider theme={generateAccentTheme(accentColor)}>
          <Box sx={styles.actionContainer}>
            <AccentBadge>{actionName}</AccentBadge>
            {action === 'change' && (
              <Box sx={styles.actionChangeContainer}>
                <Typography variant="Paragraph MD/Medium" sx={mergeSx(styles.grayColor, styles.strikedText)}>
                  {prevInfo}
                </Typography>
                <IconArrowRight />
                <Typography variant="Paragraph MD/Medium" sx={styles.grayColor}>
                  {currentInfo}
                </Typography>
              </Box>
            )}
            {action === 'enable' && currentInfo && (
              <Typography variant="Paragraph MD/Medium" sx={styles.grayColor}>
                {currentInfo}
              </Typography>
            )}
            {action === 'disable' && comment && (
              <Typography variant="Paragraph MD/Medium" sx={styles.grayColor}>
                {comment}
              </Typography>
            )}
          </Box>
        </ThemeProvider>
      </Table.DataCell>
      {/* Автор */}
      <Table.DataCell density={density}>
        <Typography variant="Paragraph MD/Regular">{author}</Typography>
      </Table.DataCell>
      {/* Дата и время */}
      <Table.DataCell density={density}>
        <Typography variant="Paragraph MD/Regular">{date}</Typography>
      </Table.DataCell>
    </Table.Row>
  );
};

export default AdminHistoryItem;
