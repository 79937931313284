import { GET_STUDENT_EIS_DOP_RECOMMENDATIONS } from '../../actions';
import { FAILURE, INVALIDATE, REQUEST, SUCCESS } from '../../actions/utils';
import { ICommonResponse, IEisDopRecommendation } from '../../api/types';
import { connectReducers } from '../../utils';
import { IReducers } from '..';
import genericReducers from '../genericReducer';

export type StudentEisDopRecommendationsState = ICommonResponse<IEisDopRecommendation>;

const initialState: StudentEisDopRecommendationsState = {
  content: [],
  loading: false,
};

const studentEisDopRecommendations: IReducers<StudentEisDopRecommendationsState> = {
  [GET_STUDENT_EIS_DOP_RECOMMENDATIONS[REQUEST]]: genericReducers().get.request,
  [GET_STUDENT_EIS_DOP_RECOMMENDATIONS[SUCCESS]]: genericReducers().get.success,
  [GET_STUDENT_EIS_DOP_RECOMMENDATIONS[FAILURE]]: genericReducers().get.failure,
  [GET_STUDENT_EIS_DOP_RECOMMENDATIONS[INVALIDATE]]: genericReducers().get.invalidate(initialState),
};

export default connectReducers(initialState, studentEisDopRecommendations);
