import { FC } from 'react';

import { Box } from '@mui/material';
import usePagination from '@mui/material/usePagination';

import PaginationButton from './PaginationButton';
import * as styles from './styles';

interface IPaginationProps {
  page: number;
  totalPages: number;
  onChange: (page: number) => void;
  disabled?: boolean;
}

const Pagination: FC<IPaginationProps> = ({ page, totalPages, onChange, disabled }) => {
  const { items } = usePagination({
    page,
    count: totalPages,
    disabled,
    onChange(_, page) {
      if (!page) return;
      if (page < 0 || page > totalPages) return;
      onChange(page);
    },
  });

  const prevButton = items.find((item) => item.type === 'previous');
  const nextButton = items.find((item) => item.type === 'next');
  const pages = items.filter((item) => item.type !== 'previous' && item.type !== 'next');

  return (
    <Box className="pagination" sx={styles.root}>
      {prevButton && <PaginationButton paginationItem={prevButton} />}
      <Box className="pagination__pages" sx={styles.pages}>
        {pages.map((pageItem) => {
          return <PaginationButton key={`${pageItem.type}-${pageItem.page}`} paginationItem={pageItem} />;
        })}
      </Box>
      {nextButton && <PaginationButton paginationItem={nextButton} />}
    </Box>
  );
};

export default Pagination;
