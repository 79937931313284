import { createSelector } from 'reselect';

import { IRootState } from '../../../reducers';
import { mapCivilClubsResponse } from './mappers';

const civilClubsSelector = createSelector(
  [(state: IRootState) => state.studentAffilations, (state: IRootState) => state.sectionRef],
  (studentAffilations, sectionRef) => {
    return mapCivilClubsResponse(studentAffilations, sectionRef);
  },
);

export default civilClubsSelector;
