import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconPaperPlaneOutline: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="m14.352 18.046-1.763-5.29 5.29-5.291-3.527 10.581Zm2.184-11.925-5.29 5.291-5.291-1.764 10.58-3.527Zm4.458-2.223a.904.904 0 0 0-.083-.343.919.919 0 0 0-.186-.28.912.912 0 0 0-.365-.218.937.937 0 0 0-.26-.051c-.022-.001-.04-.006-.063-.006a.975.975 0 0 0-.284.046L2.65 8.746a.95.95 0 0 0 0 1.804l8.1 2.7 2.702 8.102a.949.949 0 0 0 1.801 0l5.701-17.103A.951.951 0 0 0 21 3.964c0-.022-.005-.043-.006-.066Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconPaperPlaneOutline;
