import { SxStyles } from 'types';

export const root: SxStyles = (theme) => ({
  display: 'flex',
  gap: '12px',

  [theme.breakpoints.down('commonSm')]: {
    gap: '8px',
  },
});

export const partialApprovePopperTarget: SxStyles = {
  cursor: 'pointer',
};
