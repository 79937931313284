/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useContext, useMemo } from 'react';

import { FormHelperText } from '@mui/material';

import { ActivitySecondaryBlock } from '../basicBlocks';
import { IDictionaryItem } from '../../../../../api/types';
import { DifferentCode } from '../../../../../const';
import { IEventData } from 'portfolio3/features/dataEntryForm';

import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';
import { BaseInputLabel, FormControl, Input } from 'portfolio3/ui-kit';
import { StretchyTokenField } from 'portfolio3/ui-kit/autocomplete';
import { TokenFieldController } from 'portfolio3/ui-kit/types';

interface IScienceContestSecondaryBlockProps {
  subjectOptions: IDictionaryItem[];
  eventLevelOptions: IDictionaryItem[];
  nameLabel: string;
  namePlaceholder: string;
  eventData: IEventData;
}

const ScienceContestSecondaryBlock: React.FC<IScienceContestSecondaryBlockProps> = ({
  subjectOptions,
  eventLevelOptions,
  nameLabel,
  namePlaceholder,
  eventData,
}) => {
  const { isMobile, inputRenderMode, inputSize, formData, formErrors, onChangeFormData, onChangeFormErrors } = useContext(LocalDataEntryFormContext);

  const secondNameController = useMemo(() => ({
    handleChange: (secondName: string) => {
      onChangeFormData((prevstate) => ({
        ...prevstate,
        secondName
      }));
      onChangeFormErrors((prevstate) => ({
        ...prevstate,
        secondNameError: false
      }));
    },
    handleBlur: (secondName: any) => {
      if (!secondName) {
        onChangeFormErrors((prevstate) => ({
          ...prevstate,
          secondNameError: true
        }));
      }
    }
  }), [formData.id]);

  const subjectController: TokenFieldController = useMemo(() => ({
    handleChange: (subjects) => {
      onChangeFormData((prevState) => ({
        ...prevState,
        subjectId: subjects.map((subject) => subject.code),
      }));
      onChangeFormErrors((prevState) => ({
        ...prevState,
        subjectError: subjects.length === 0,
      }));
    },
    handleBlur: (subjects) => {
      onChangeFormErrors((prevState) => ({
        ...prevState,
        subjectError: subjects.length === 0,
      }));
    },
  }), [formData.id]);

  const additionalTopControllers = (
    <>
      {Number(formData.name) === DifferentCode && (
        <FormControl
          sx={{ width: '100%' }}
          required
          error={formErrors.secondNameError}
          renderMode={inputRenderMode}
          inputSize={inputSize}
          label={<BaseInputLabel>{nameLabel}</BaseInputLabel>}
          helper={formErrors.secondNameError && <FormHelperText>Введите название конкурса</FormHelperText>}
          control={
            <Input
              renderMode={inputRenderMode}
              size={inputSize}
              placeholder={namePlaceholder}
              value={formData.secondName}
              controller={secondNameController}
            />
          }
        />
      )}
      <FormControl
        required
        error={formErrors.subjectError}
        renderMode={inputRenderMode}
        inputSize={inputSize}
        label={
          <BaseInputLabel>
            {isMobile ? 'Предмет / дисциплина' : 'Предмет / дисциплина (один или несколько)'}
          </BaseInputLabel>
        }
        helper={
          formErrors.subjectError && <FormHelperText>Выберите хотя бы один предмет</FormHelperText>
        }
        control={
          <StretchyTokenField
            size={inputSize}
            inputRenderMode={inputRenderMode}
            options={subjectOptions || []}
            controller={subjectController}
            value={formData.subjectId}
            placeholder="Начните вводить название предмета..."
          />
        }
      />
    </>
  );

  const organizatorLabel = 'Организатор конкурса';
  const organizatorPlaceholder = 'Введите название организатора конкурса...';
  return (
    <ActivitySecondaryBlock
      eventData={eventData}
      eventLevelOptions={eventLevelOptions}
      additionalTopControllers={additionalTopControllers}
      organizatorLabel={organizatorLabel}
      organizatorPlaceholder={organizatorPlaceholder}
    />
  );
};

export default ScienceContestSecondaryBlock;
