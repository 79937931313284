import { FC, useContext } from 'react';

import { Box, useTheme } from '@mui/material';
import { getAccentColor } from 'portfolio3/styles';
import { mergeSx } from 'utils';

import { WidgetContainerContext } from '../context/widgetContainerContext';
import { IWidgetContainerHeaderProps } from '../types';
import BackgroundContainer from './BackgroundContainer';
import * as styles from './styles';

const WidgetContainerHeader: FC<IWidgetContainerHeaderProps> = ({
  sx,
  backgroundContainerSx,
  title,
  subtitle,
  options,
  tabs,
  backgroundImage,
}) => {
  const { withBackground } = useContext(WidgetContainerContext);
  const theme = useTheme();

  const rootPaddingsSx = withBackground ? styles.rootPaddingsBackground : styles.rootPaddings;
  const paddingSx = withBackground ? styles.elementsInlinePaddingBackground : styles.elementsInlinePadding;

  const containerContent = (
    <>
      {title && (
        <Box className="widget-container-header__title" sx={paddingSx}>
          {title}
        </Box>
      )}
      {subtitle && (
        <Box className="widget-container-header__subtitle" sx={paddingSx}>
          {subtitle}
        </Box>
      )}
      {options && (
        <Box className="widget-container-header__options" sx={paddingSx}>
          {options}
        </Box>
      )}
      {tabs && (
        <Box className="widget-container-header__overflow" sx={styles.overflowContainer}>
          <Box className="widget-container-header__tabs" sx={mergeSx(paddingSx, styles.overflowElement)}>
            {tabs}
          </Box>
        </Box>
      )}
    </>
  );

  return (
    <Box className="widget-container-header" sx={mergeSx(styles.rootSpacing, rootPaddingsSx, sx)}>
      {withBackground ? (
        <BackgroundContainer
          color={getAccentColor(theme.palette.accentColor, '8')}
          image={backgroundImage}
          sx={mergeSx(styles.rootSpacing, backgroundContainerSx)}
        >
          {containerContent}
        </BackgroundContainer>
      ) : (
        containerContent
      )}
    </Box>
  );
};

export default WidgetContainerHeader;
