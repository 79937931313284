import { FC, ReactNode, useEffect, useMemo, useState } from 'react';

import { Box, useMediaQuery } from '@mui/material';
import { useAppSelector, useBrightTheme, useComponentDidUpdate, useScrollToElement, useSPOStudent } from 'hooks';
import { widgetIllustrations } from 'images';
import UnderlinedTabs from 'portfolio3/components/common/UnderlinedTabs';
import { WidgetContainer as Widget } from 'portfolio3/components/common/WidgetContainer';
import { WidgetContainerTitle } from 'portfolio3/components/widgetContainerRecipes';
import { emitYMEvent, YandexMetrikaRecommendationSearchType } from 'portfolio3/features/yandexMetrika';
import { commonTheme } from 'portfolio3/styles/theme';
import { inheritSx, isDefined } from 'utils';

import {
  CatImagePlug,
  DefaultImagePlug,
  DesktopFilters,
  MobileFilters,
  MobilePaginationWidget,
  NoDataPlug,
  PaginationWidget,
  VuzRecommendationWidgetListHeader,
  VuzRecommendationWidgetListInfoBlock,
} from '../../components';
import { Placeholders } from '../../components/Placeholders';
import {
  initialClientFilters,
  initialLocalFilters,
  initialServerFilters,
  MINIMUM_EGE_EXAMS,
  MINIMUM_FILTER_SUBJECTS_TO_START_INCLUDING_EVERY_SPECIALITY_SUBJECT,
  paginationLimitOptions,
  SortTypeCodes,
  VuzRecommendationTab,
} from '../../const';
import { VuzLocalFiltersContext } from '../../context';
import { VuzRecommendationContext } from '../../context/vuzRecommendationContext';
import { useSearchVuz } from '../../hooks';
import {
  egeExamSubjectsSelector,
  flatSpecialitiesSelector,
  realExamSubjectsSelector,
  recommendationGiaList,
  trialExamSubjectsSelector,
  vuzListFavoriteSelector,
  vuzListRealGiaSelector,
  vuzListSelector,
  vuzListTrialGiaSelector,
} from '../../model/selectors';
import { IVuzRecommendationClientFilters, IVuzRecommendationFilters, VuzLocalFilters } from '../../types';
import {
  filterRealGiaExam,
  filterTrialGiaExam,
  getMappedUniqueRefSubjectCodes,
  getStudentSubjectsExamBallSummary,
  setFilterFactory,
} from '../../utils';
import {
  mapVuzByHigherBallToggleFilter,
  mapVuzWithEverySpecialitySubjectIncludedInSubjects,
  mapVuzWithEverySubjectIncludedInSpecialitySubjects,
} from '../utils';
import { sortVuzList } from '../utils/sortVuzesList';
import VuzRecommendationList from '../VuzRecommendationsList';
import * as styles from './styles';

let ymMaxPageVisited = initialClientFilters.page;

interface IVuzRecommendationWidgetProps {
  currentTab: VuzRecommendationTab;
  onTabChange: (tab: VuzRecommendationTab) => void;
  showTrialEgeTab: boolean;
  showRealEgeTab: boolean;
  isSettingsMode: boolean;
  toggleElement: ReactNode;
}

const VuzRecommendationWidget: FC<IVuzRecommendationWidgetProps> = ({
  currentTab,
  onTabChange,
  showTrialEgeTab,
  showRealEgeTab,
  isSettingsMode,
  toggleElement,
}) => {
  const studentGiaList = useAppSelector(recommendationGiaList);
  const egeExamSubjectsRef = useAppSelector((state) => state.egeExamSubjectsRef);
  const egeExamAllSubjects = useAppSelector(egeExamSubjectsSelector);
  const trialExamSubjects = useAppSelector(trialExamSubjectsSelector);
  const realExamSubjects = useAppSelector(realExamSubjectsSelector);
  const flatSpecialities = useAppSelector(flatSpecialitiesSelector);
  const mappedTrialGiaVuzList = useAppSelector(vuzListTrialGiaSelector);
  const mappedRealGiaVuzList = useAppSelector(vuzListRealGiaSelector);
  const mappedVuzList = useAppSelector(vuzListSelector);
  const mappedFavoriteVuzList = useAppSelector(vuzListFavoriteSelector);
  const vuzListLoading = useAppSelector((state) => state.vuzList.loading);
  const vuzListError = useAppSelector((state) => state.vuzList.error);

  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const isSpoStudent = useSPOStudent();
  const isBrightTheme = useBrightTheme();
  const { scrollToElementGlobal } = useScrollToElement();

  const contentElementId = 'recommendation-vuz-content';
  const isRealEgeTab = currentTab === VuzRecommendationTab.REAL_GIA;
  const isTrialEgeTab = currentTab === VuzRecommendationTab.TRIAL_GIA;
  const isFavoritesTab = currentTab === VuzRecommendationTab.FAVORITES;

  let initialServerFiltersSubjects: number[] = [];
  if (isRealEgeTab) initialServerFiltersSubjects = realExamSubjects.content.map((subject) => subject.code);
  if (isTrialEgeTab) initialServerFiltersSubjects = trialExamSubjects.content.map((subject) => subject.code);
  const computedInitialServerFilters: IVuzRecommendationFilters = {
    ...initialServerFilters,
    subjects: initialServerFiltersSubjects,
  };

  const [serverFilters, setServerFilters] = useState<IVuzRecommendationFilters | null>(computedInitialServerFilters);
  const [clientFilters, setClientFilters] = useState<IVuzRecommendationClientFilters | null>(null);
  const [localFilters, setLocalFilters] = useState<VuzLocalFilters>(initialLocalFilters);
  const { searchVuz } = useSearchVuz({ currentTab });

  // яндекс метрика
  const [ymSingleEffectSearchLocalFilters, setYmSingleEffectSearchLocalFilters] = useState<VuzLocalFilters | null>(
    null,
  );

  const setServerFilter = setFilterFactory(setServerFilters);
  const setClientFilter = setFilterFactory(setClientFilters);
  const setLocalFilter = setFilterFactory(setLocalFilters);

  const handleChangeSort = (value: number) =>
    setClientFilters({ ...(clientFilters ?? initialClientFilters), sortType: value });

  const handleApplyLocalFilters = (filters: VuzLocalFilters) => {
    setLocalFilters(filters);

    const { isHigherBallToggle, ...serverFilters } = filters;

    setServerFilters(serverFilters);
    setClientFilters({
      isHigherBallToggle,
      sortType: SortTypeCodes.default,
      page: initialLocalFilters.page,
      limit: initialLocalFilters.limit,
    });

    searchVuz(serverFilters);

    setYmSingleEffectSearchLocalFilters(filters);
  };

  const handleChangePage = (page: number) => {
    setLocalFilter('page', page);
    setClientFilters({
      ...(clientFilters ?? initialClientFilters),
      page,
    });

    const maxPage = Math.max(page, ymMaxPageVisited);
    ymMaxPageVisited = maxPage;

    emitYMEvent({
      type: 'recommendationPagesSwitch',
      payload: {
        maxPage,
      },
    });
  };
  const handleChangeLimit = (limit: number) => {
    setLocalFilter('limit', limit);
    setClientFilters({
      ...(clientFilters ?? initialClientFilters),
      limit,
    });
  };

  const handleResetFilters = () => setLocalFilters({ ...initialLocalFilters, ...computedInitialServerFilters });

  const handleScrollToContentTop = () => scrollToElementGlobal(contentElementId);

  const getVuzListByCurrentTab = () => {
    switch (currentTab) {
      case VuzRecommendationTab.REAL_GIA:
        return mappedRealGiaVuzList;
      case VuzRecommendationTab.TRIAL_GIA:
        return mappedTrialGiaVuzList;
      case VuzRecommendationTab.FAVORITES:
        return mappedFavoriteVuzList;
      default:
        return mappedVuzList;
    }
  };

  const getAvailableSubjectsByCurrentTab = () => {
    switch (currentTab) {
      case VuzRecommendationTab.REAL_GIA:
        return realExamSubjects.content;
      case VuzRecommendationTab.TRIAL_GIA:
        return trialExamSubjects.content;
      default:
        return egeExamAllSubjects;
    }
  };

  const getStudentGiaListByCurrentTab = () => {
    switch (currentTab) {
      case VuzRecommendationTab.REAL_GIA:
        return studentGiaList.content.filter(filterRealGiaExam);
      case VuzRecommendationTab.TRIAL_GIA:
        return studentGiaList.content.filter(filterTrialGiaExam);
      case VuzRecommendationTab.FAVORITES: {
        const hasMinimumRealExams = realExamSubjects.content.length >= MINIMUM_EGE_EXAMS;
        const hasMinimumTrialExams = trialExamSubjects.content.length >= MINIMUM_EGE_EXAMS;

        const totalExams = studentGiaList.content;
        if (hasMinimumTrialExams) return totalExams.filter(filterTrialGiaExam);
        if (hasMinimumRealExams) return totalExams.filter(filterRealGiaExam);

        return [];
      }
      default:
        return [];
    }
  };

  const vuzList = getVuzListByCurrentTab();
  const tabBasedGiaList = getStudentGiaListByCurrentTab();
  const availableSubjects = getAvailableSubjectsByCurrentTab();

  const selectedServerSubjectCodes = serverFilters?.subjects ?? availableSubjects.map((subject) => subject.code);
  const selectedServerSubjectNames = selectedServerSubjectCodes
    .map((subjectCode) => {
      const subject = availableSubjects.find((filterSubject) => filterSubject.code === subjectCode);
      return subject?.value;
    })
    .filter(isDefined);

  const refSubjectNames = egeExamSubjectsRef.content.map((subject) => subject.title);
  const selectedRefSubjects = getMappedUniqueRefSubjectCodes(selectedServerSubjectCodes, egeExamSubjectsRef.content);
  const lookForEverySpecialitySubjectInSelectedSubjects =
    selectedRefSubjects.length >= MINIMUM_FILTER_SUBJECTS_TO_START_INCLUDING_EVERY_SPECIALITY_SUBJECT;

  // Дополнение в специальности суммы баллов по пройденым экзаменам учащегося
  const vuzListWithBallSummary = vuzList.map((vuz) => {
    return {
      ...vuz,
      specialities: vuz.specialities.map((speciality) => {
        const specialitySubjectNames = speciality.subjects ?? [];
        return {
          ...speciality,
          studentSubjectsBallSummary: getStudentSubjectsExamBallSummary(
            tabBasedGiaList,
            specialitySubjectNames,
            selectedServerSubjectNames,
          ),
        };
      }),
    };
  });

  const isFilteredByHigherBall = clientFilters?.isHigherBallToggle;
  const filteredVuzList = vuzListWithBallSummary
    // Фильтрация/Маппинг по GIA/isHigherBallToggle
    .map((vuz) => {
      return isFilteredByHigherBall ? mapVuzByHigherBallToggleFilter(vuz) : vuz;
    })
    // Фильтрация специальностей по предметам в фильтрах
    .map((vuz) => {
      if (!vuz) return null;

      // на вкладке "по выбранным предметам" разрешено искать без выбранных предметов, у таких вузов нет специальностей
      if (selectedRefSubjects.length === 0) return vuz;

      return lookForEverySpecialitySubjectInSelectedSubjects
        ? mapVuzWithEverySpecialitySubjectIncludedInSubjects(vuz, selectedServerSubjectNames, refSubjectNames)
        : mapVuzWithEverySubjectIncludedInSpecialitySubjects(vuz, selectedServerSubjectNames);
    })
    .filter(isDefined);

  const sortedVuzList = useMemo(() => {
    const sortedVuzlist = sortVuzList(filteredVuzList, clientFilters?.sortType ?? SortTypeCodes.default);
    return sortedVuzlist;
  }, [clientFilters?.sortType, filteredVuzList]);

  // Пагинация
  const totalElements = sortedVuzList.length;
  const page = clientFilters?.page ?? initialClientFilters.page;
  const limit = clientFilters?.limit ?? initialClientFilters.limit;
  const pageIndex = page - 1;
  const sliceStartIndex = pageIndex * limit;
  const sliceEndIndex = page * limit;
  const pagedVuzList = sortedVuzList.slice(sliceStartIndex, sliceEndIndex);

  // Логика при смене текущего таба
  useComponentDidUpdate(() => {
    // Очистить фильтры
    setServerFilters(computedInitialServerFilters);
    setClientFilters(null);
    setLocalFilters(initialLocalFilters);

    // Получить список вузов по стандартным фильтрам
    searchVuz(initialServerFilters);
  }, [currentTab]);

  useEffect(() => {
    if (![VuzRecommendationTab.REAL_GIA, VuzRecommendationTab.TRIAL_GIA].includes(currentTab)) return;

    const isTrialGiaTab = currentTab === VuzRecommendationTab.TRIAL_GIA;
    const subjects = (isTrialGiaTab ? trialExamSubjects : realExamSubjects).content.map((exam) => exam.code);

    setLocalFilter('subjects', subjects);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trialExamSubjects, realExamSubjects, currentTab]);

  // Корректировка текущей страницы
  useEffect(() => {
    let maxPage = Math.ceil(totalElements / limit);
    if (maxPage < 1) maxPage = 1;

    if (page > maxPage) {
      setLocalFilter('page', maxPage);
      setClientFilters({
        ...(clientFilters ?? initialClientFilters),
        page: maxPage,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, totalElements]);

  useComponentDidUpdate(() => {
    handleScrollToContentTop();
  }, [page]);

  // яндекс метрика
  useEffect(() => {
    // когда не загружается и есть фильтры - значит завершился запрос на получение списка после ручного поиска
    if (ymSingleEffectSearchLocalFilters && !vuzListLoading) {
      const filters = ymSingleEffectSearchLocalFilters;
      const searchTypes: YandexMetrikaRecommendationSearchType[] = [];

      const searchString = filters.search.trim();
      const hasSearchString = searchString.length > 0;

      if (hasSearchString) searchTypes.push('words');
      if (filters.subjects.length > 0) searchTypes.push('subject');
      if (filters.specialities.length > 0) searchTypes.push('specialization');
      if (isDefined(filters.trainingTypeCode)) searchTypes.push('typeTraining');
      if (isDefined(filters.militaryTrainingTypeCode)) searchTypes.push('militaryTraining');
      if (filters.isHigherBallToggle) searchTypes.push('exceedingScore');

      emitYMEvent({
        type: 'recommendationSearch',
        payload: {
          typeSearch: searchTypes,
          wordsSearch: hasSearchString ? searchString : undefined,
          resultWordSearch: filteredVuzList.length,
        },
      });

      // удаляем фильтры, чтобы текущая ветка if сработала только 1 раз
      setYmSingleEffectSearchLocalFilters(null);
    }
  }, [filteredVuzList.length, vuzListLoading, ymSingleEffectSearchLocalFilters]);

  const headerTitleElement = (
    <WidgetContainerTitle title="Рекомендации вузов" right={isSettingsMode && toggleElement} />
  );

  const headerTabsElement = (
    <UnderlinedTabs value={currentTab} onChange={onTabChange} sx={{ gap: '24px' }}>
      {showRealEgeTab && (
        <UnderlinedTabs.Button value={VuzRecommendationTab.REAL_GIA} content="По результатам итогового ЕГЭ" />
      )}
      {showTrialEgeTab && (
        <UnderlinedTabs.Button value={VuzRecommendationTab.TRIAL_GIA} content="По результатам пробного ЕГЭ" />
      )}
      <UnderlinedTabs.Button value={VuzRecommendationTab.SUBJECTS} content="По выбранным предметам" />
      <UnderlinedTabs.Button value={VuzRecommendationTab.FAVORITES} content="Избранное" />
    </UnderlinedTabs>
  );

  const desktopFiltersElement = (
    <DesktopFilters
      subjectOptions={availableSubjects}
      specialityOptions={flatSpecialities}
      onReset={handleResetFilters}
    />
  );
  const mobileFiltersElement = (
    <MobileFilters subjectOptions={availableSubjects} specialityOptions={flatSpecialities} />
  );

  const contentElement = (
    <Box id={contentElementId}>
      <VuzRecommendationWidgetListHeader
        currentTab={currentTab}
        vuzListCount={totalElements}
        vuzListSortType={clientFilters?.sortType ?? SortTypeCodes.default}
        onChangeSortType={handleChangeSort}
      />
      {!isFavoritesTab && <VuzRecommendationWidgetListInfoBlock />}
      <VuzRecommendationList vuzList={pagedVuzList} />
    </Box>
  );

  const hasData = sortedVuzList.length > 0;
  const isSelectedZeroSubjects = serverFilters?.subjects.length === 0;

  const defaultPlaceholderTitle = isFavoritesTab
    ? 'В избранное пока ничего не добавлено'
    : 'По заданным условиям ничего не найдено';
  const defaultPlaceholderImageElement = isFavoritesTab ? <DefaultImagePlug /> : <CatImagePlug />;

  const defaultPlaceholderElement = isSpoStudent ? (
    <Placeholders.DataNotFoundSpo title={defaultPlaceholderTitle} />
  ) : (
    <NoDataPlug loading={false} title={defaultPlaceholderTitle} imageElement={defaultPlaceholderImageElement} />
  );

  let placeholderOrLoaderElement: ReactNode = null;

  // TODO можно создать отдельный компонент на каждую заглушку и передавать isSpoStudent
  if (!hasData) {
    placeholderOrLoaderElement = defaultPlaceholderElement;
  }

  if (isSelectedZeroSubjects && (isTrialEgeTab || isRealEgeTab)) {
    const description = isTrialEgeTab
      ? 'Ничего не найдено, должен быть выбран хотя бы один предмет пробного ЕГЭ'
      : 'Ничего не найдено, должен быть выбран хотя бы один предмет итогового ЕГЭ';

    placeholderOrLoaderElement = isSpoStudent ? (
      <Placeholders.ZeroSubjectsSpo description={description} />
    ) : (
      <NoDataPlug loading={false} title={description} imageElement={defaultPlaceholderImageElement} />
    );
  }

  if (vuzListError) {
    placeholderOrLoaderElement = isSpoStudent ? (
      <Placeholders.ErrorSpo />
    ) : (
      <NoDataPlug
        loading={false}
        title="Не удалось получить данные. Попробуйте повторить запрос позже"
        imageElement={defaultPlaceholderImageElement}
      />
    );
  }

  if (vuzListLoading) {
    placeholderOrLoaderElement = isSpoStudent ? (
      <Placeholders.DataLoadingSpo />
    ) : (
      <NoDataPlug loading={true} title="" imageElement={defaultPlaceholderImageElement} />
    );
  }

  const isContentShown = hasData && placeholderOrLoaderElement === null;

  return (
    <VuzRecommendationContext.Provider
      value={{
        currentTab,
        serverFilters,
        computedInitialServerFilters,
        clientFilters,
        setServerFilter,
        setClientFilter,
        totalElements,
      }}
    >
      {/* Хедер */}
      <Widget.Header
        title={headerTitleElement}
        tabs={headerTabsElement}
        sx={isBrightTheme ? null : styles.removeBottomPadding}
        backgroundImage={widgetIllustrations.recommendationVuz}
        backgroundContainerSx={{ '&&&': { ...inheritSx([styles.removeBottomPadding]) } }}
      />
      <Widget.Divider sx={styles.dividerWithoutMargin} />

      {/* Фильтры */}
      {!isFavoritesTab && (
        <VuzLocalFiltersContext.Provider
          value={{
            localFilters,
            setLocalFilter,
            onApplyFilters: handleApplyLocalFilters,
          }}
        >
          {/* В мобильных фильтрах Widget.Padding внутри */}
          {isMobile && (
            <Box className="widget-filters-padding" sx={styles.filtersPaddingContainer}>
              {mobileFiltersElement}
            </Box>
          )}
          {!isMobile && (
            <>
              <Widget.Padding sx={styles.filtersPaddingContainer}>{desktopFiltersElement}</Widget.Padding>
              <Widget.Divider sx={styles.dividerWithoutMargin} />
            </>
          )}
        </VuzLocalFiltersContext.Provider>
      )}

      {/* Контент / Заглушка */}
      <Widget.Padding sx={styles.contentContainer}>
        {isContentShown ? contentElement : placeholderOrLoaderElement ?? defaultPlaceholderElement}
      </Widget.Padding>

      {/* Пагинация */}
      {isContentShown && (
        <Widget.Padding sx={styles.bottomContainer}>
          {isMobile ? (
            <MobilePaginationWidget
              disabled={vuzListLoading || isSettingsMode}
              onScrollToTop={handleScrollToContentTop}
            />
          ) : (
            <PaginationWidget
              totalElements={totalElements}
              page={clientFilters?.page ?? initialClientFilters.page}
              limit={clientFilters?.limit ?? initialClientFilters.limit}
              limitOptions={paginationLimitOptions}
              onChangePage={handleChangePage}
              onChangeLimit={handleChangeLimit}
              disabled={vuzListLoading || isSettingsMode}
            />
          )}
        </Widget.Padding>
      )}
    </VuzRecommendationContext.Provider>
  );
};

export default VuzRecommendationWidget;
