import { FC, useContext, useEffect, useRef, useState } from 'react';

import { Box } from '@mui/material';
import { IDictionaryItem } from 'api/types';
import { PortfolioSettingsContext } from 'context';
import { useDisclosure } from 'hooks';
import { useComponentDidUpdate } from 'hooks';
import { IconFunnelFilled } from 'icons';
import { debounce } from 'lodash';
import { WidgetContainer as Widget } from 'portfolio3/components/common/WidgetContainer';
import { MobileActionsDrawer } from 'portfolio3/components/drawers';
import { StatusBadge } from 'portfolio3/ui-kit/badges';
import { Button } from 'portfolio3/ui-kit/button';
import { isDefined, mergeSx } from 'utils';

import { initialLocalFilters, SortTypeCodes, VuzRecommendationTab } from '../../const';
import { VuzLocalFiltersContext, VuzRecommendationContext } from '../../context';
import { useFilterControls } from '../../hooks';
import { VuzLocalFilters } from '../../types';
import { isFiltersChanged, isInitialFiltersChanged, setFilterFactory } from '../../utils';
import QuickAccessSpecialitiesDrawer from '../QickAccessSpecialitiesDrawer';
import QuickAccessSubjectsDrawer from '../QuickAccessSubjectsDrawer';
import VuzListSortButton from '../VuzListSortButton';
import MobileControls from './MobileControls';
import * as styles from './styles';

interface IMobileFiltersProps {
  subjectOptions: IDictionaryItem[];
  specialityOptions: IDictionaryItem[];
}

const MobileFilters: FC<IMobileFiltersProps> = ({ subjectOptions, specialityOptions }) => {
  const { currentTab, clientFilters, serverFilters, computedInitialServerFilters, setClientFilter } =
    useContext(VuzRecommendationContext);
  const { localFilters, onApplyFilters } = useContext(VuzLocalFiltersContext);
  const { isSettingsMode } = useContext(PortfolioSettingsContext);
  const { searchControl } = useFilterControls({
    subjectOptions,
    specialityOptions,
  });

  const { isOpen: isMainFiltersOpen, onOpen: onOpenMainFilters, onClose: onCloseMainFilters } = useDisclosure();
  const { isOpen: isQuickSubjectsOpen, onOpen: onOpenQuickSubjects, onClose: onCloseQuickSubjects } = useDisclosure();
  const {
    isOpen: isQuickSpecialitiesOpen,
    onOpen: onOpenQuickSpecialities,
    onClose: onCloseQuickSpecialities,
  } = useDisclosure();
  const [innerLocalFilters, setInnerLocalFilters] = useState<VuzLocalFilters>(localFilters);

  const debounceSetServerSearchFilter = useRef(
    debounce((localFilters: VuzLocalFilters) => {
      onApplyFilters?.(localFilters);
    }, 750),
  ).current;

  const isFormInitialServerFiltersChanged = isInitialFiltersChanged(innerLocalFilters, computedInitialServerFilters);
  const isFiltersFormChanged = isFiltersChanged(
    innerLocalFilters,
    serverFilters,
    computedInitialServerFilters,
    clientFilters,
  );
  const isFiltersFormResetDisabled = !isFormInitialServerFiltersChanged;
  const isFiltersFormSearchDisabled = !isFiltersFormChanged;

  const getQuickSubjectsDrawerTitle = () => {
    if (currentTab === VuzRecommendationTab.REAL_GIA) return 'Предметы итогового ЕГЭ';
    if (currentTab === VuzRecommendationTab.TRIAL_GIA) return 'Предметы пробного ЕГЭ';
    return 'Предметы';
  };

  // Синхронизация с верхним стейтом при открытии
  useEffect(() => {
    if (isMainFiltersOpen) {
      setInnerLocalFilters(localFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMainFiltersOpen]);

  useComponentDidUpdate(() => {
    // При обновлении поля поиска в мобильных фильтрах - отправлять запрос
    debounceSetServerSearchFilter(localFilters);
  }, [localFilters.search]);

  const setInnerLocalSingleFilter = setFilterFactory(setInnerLocalFilters);

  const handleReset = () => {
    setInnerLocalFilters({ ...initialLocalFilters, ...computedInitialServerFilters });
  };

  const handleCloseAndRestoreState = () => {
    onCloseMainFilters();
    setInnerLocalFilters(localFilters);
  };

  const handleCloseAndApply = () => {
    onCloseMainFilters();
    onApplyFilters?.(innerLocalFilters);
  };

  const handleResetQuickSubjects = () => {
    // вне модалки работаем с локальными фильтрами
    onApplyFilters?.({
      ...localFilters,
      subjects: [],
    });
  };

  const handleResetQuickSpecialities = () => {
    // вне модалки работаем с локальными фильтрами
    onApplyFilters?.({
      ...localFilters,
      specialities: [],
    });
  };

  const handleChangeSortType = (value: number) => {
    setClientFilter('sortType', value);
  };

  const quickSubjects = localFilters.subjects
    .map((subjectCode) => {
      return subjectOptions.find((subject) => subject.code === subjectCode)?.value;
    })
    .filter(isDefined);
  const quickSpecialities = localFilters.specialities
    .map((specialityCode) => {
      return specialityOptions.find((speciality) => speciality.code === specialityCode)?.value;
    })
    .filter(isDefined);

  return (
    <Box className="filters">
      {/* filters top */}
      <Widget.Padding>
        <Box className="filters__top" sx={styles.top}>
          {searchControl}
          <VuzListSortButton
            isMobile
            currentTab={currentTab}
            sortType={clientFilters?.sortType || SortTypeCodes.default}
            onChangeSortType={handleChangeSortType}
          />
          <Button variant="secondary" onlyIcon onClick={onOpenMainFilters} disabled={isSettingsMode}>
            <IconFunnelFilled />
          </Button>
        </Box>
      </Widget.Padding>
      {/* filters quick access */}
      <Box className="filters__overflow" sx={styles.overflowContainer}>
        <Widget.Padding sx={mergeSx(styles.quickAccess, styles.overflowElement)}>
          <StatusBadge
            badgeName="Предметы"
            values={quickSubjects}
            minValues={1}
            onClick={onOpenQuickSubjects}
            onReset={handleResetQuickSubjects}
            disabled={isSettingsMode}
          />
          <StatusBadge
            badgeName="Специальности"
            values={quickSpecialities}
            minValues={1}
            onClick={onOpenQuickSpecialities}
            onReset={handleResetQuickSpecialities}
            disabled={isSettingsMode}
          />
        </Widget.Padding>
      </Box>

      <QuickAccessSubjectsDrawer
        title={getQuickSubjectsDrawerTitle()}
        isOpen={isQuickSubjectsOpen}
        onClose={onCloseQuickSubjects}
        subjects={subjectOptions}
      />

      <QuickAccessSpecialitiesDrawer
        isOpen={isQuickSpecialitiesOpen}
        onClose={onCloseQuickSpecialities}
        specialities={specialityOptions}
      />

      <MobileActionsDrawer
        open={isMainFiltersOpen}
        onClose={handleCloseAndRestoreState}
        title="Фильтры"
        onReset={handleReset}
        onApply={handleCloseAndApply}
        isFullHeight
        isResetDisabled={isFiltersFormResetDisabled}
        isApplyDisabled={isFiltersFormSearchDisabled}
      >
        <VuzLocalFiltersContext.Provider
          value={{
            localFilters: innerLocalFilters,
            setLocalFilter: setInnerLocalSingleFilter,
            onApplyFilters: null,
          }}
        >
          <MobileControls subjectOptions={subjectOptions} specialityOptions={specialityOptions} />
        </VuzLocalFiltersContext.Provider>
      </MobileActionsDrawer>
    </Box>
  );
};

export default MobileFilters;
