/* eslint-disable max-len */
import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconBrushFilled: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M16.376 4.668c.777-.777 2.047-.915 2.886-.207.493.417.746 1.002.738 1.596a2.033 2.033 0 0 1-.602 1.47l-6.485 6.49a.47.47 0 0 1-.665 0l-2.273-2.274a.472.472 0 0 1 0-.667l6.4-6.408ZM4.054 16.414c.032-1.836 1.275-3.496 3.07-3.866 2.923-.603 5.338 2.194 4.043 5.19-.584 1.35-1.971 2.177-3.44 2.203h-.002L4.479 20A.471.471 0 0 1 4 19.52l.054-3.106Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconBrushFilled;
