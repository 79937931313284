import { forwardRef, ForwardRefRenderFunction } from 'react';

import { Box, BoxProps } from '@mui/material';
import clsx from 'clsx';
import { SxStyles } from 'types';
import { mergeSx } from 'utils';

interface IInlinePaddingProps extends BoxProps {
  _p: string;
}

const InlinePadding: ForwardRefRenderFunction<HTMLElement, IInlinePaddingProps> = ({ _p, ...props }, ref) => {
  const styles: SxStyles = {
    paddingInline: _p,
  };
  return (
    <Box {...props} ref={ref} className={clsx('inline-padding', props.className)} sx={mergeSx(props.sx, styles)} />
  );
};

export default forwardRef<HTMLElement, IInlinePaddingProps>(InlinePadding);
