import { FC, Fragment } from 'react';
import { useCalendarGrid } from 'react-aria';
import { CalendarState, RangeCalendarState } from 'react-stately';

import { endOfMonth, getWeeksInMonth } from '@internationalized/date';
import { Box } from '@mui/material';
import { getAccentColor } from 'portfolio3/styles';
import { mergeSx } from 'utils';

import { DayCell, DayCellConnector, WeekDayCell } from '../cells';
import * as styles from './styles';

interface ICalendarGridProps {
  calendarState: CalendarState | RangeCalendarState;
}

const CalendarGrid: FC<ICalendarGridProps> = ({ calendarState }) => {
  const startDate = calendarState.visibleRange.start;
  const endDate = endOfMonth(startDate);

  const { gridProps, headerProps } = useCalendarGrid(
    {
      startDate,
      endDate,
    },
    calendarState,
  );

  const weekDays = ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'];
  const weeksInMonth = getWeeksInMonth(startDate, 'ru-RU');
  const weekIndexes = Array.from({ length: weeksInMonth }, (_, index) => index);

  const range = 'highlightedRange' in calendarState ? calendarState.highlightedRange : null;

  return (
    <Box className="calendar-grid" {...gridProps} sx={styles.root}>
      <Box className="calendar-grid__weekdays" {...headerProps} sx={styles.gridTemplate}>
        {weekDays.map((day, index, array) => {
          const isLastDay = array.length === index + 1;
          const color = isLastDay ? getAccentColor('red', '200') : undefined;

          return (
            <Fragment key={index}>
              {index !== 0 && <DayCellConnector />}
              <WeekDayCell key={index} sx={{ color }}>
                {day}
              </WeekDayCell>
            </Fragment>
          );
        })}
      </Box>
      <Box className="calendar-grid__days" sx={mergeSx(styles.gridTemplate, styles.days)}>
        {weekIndexes.map((weekIndex) => {
          return calendarState.getDatesInWeek(weekIndex, startDate).map((date, index) => {
            let isRangeSelected = false;
            let isSelectedInBetweenRange = false;
            let isConnectorSelected = false;

            if (range) {
              isSelectedInBetweenRange = date ? date?.compare(range.start) > 0 && date?.compare(range.end) < 0 : false;
              isConnectorSelected = date ? date?.compare(range.start) > 0 && date?.compare(range.end) <= 0 : false;
              isRangeSelected = date ? date?.compare(range.start) === 0 || date?.compare(range.end) === 0 : false;
            }

            const cellElement = date ? (
              <DayCell
                cellOptions={{ date }}
                calendarState={calendarState}
                startDate={startDate}
                isRangeSelected={isRangeSelected}
                isSelectedInBetweenRange={isSelectedInBetweenRange}
              />
            ) : (
              <Box />
            );

            return (
              <Fragment key={index}>
                {index !== 0 && <DayCellConnector isSelected={isConnectorSelected} />}
                {cellElement}
              </Fragment>
            );
          });
        })}
      </Box>
    </Box>
  );
};

export default CalendarGrid;
