import React from 'react';
import { connect } from 'react-redux';

import { SxProps, Typography, useMediaQuery } from '@mui/material';
import { getFeedbackLinkActions } from 'actions';
import { emitYMEvent } from 'portfolio3/features/yandexMetrika';

import { IconArrowOpen } from '../../../../icons';
import { smileCatPortrait } from '../../../../images';
import { Button } from '../../../../ui-kit';

import './index.scss';

const linkStyles: SxProps = {
  textDecoration: 'none',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '20px',
  color: '#0055CB',
  cursor: 'pointer',
};

interface INoDataPlugProps {
  onChangeStepForward: () => void;
  getFeedbackLink: typeof getFeedbackLinkActions.request;
}

const NoDataPlug: React.FC<INoDataPlugProps> = ({ onChangeStepForward, getFeedbackLink }) => {
  const isLargeScreen = useMediaQuery('(min-width: 1366px)', { noSsr: true });
  const isMobile = useMediaQuery('(max-width: 767px)', { noSsr: true });

  const handleClickLink = () => getFeedbackLink();

  const handleContinue = () => {
    onChangeStepForward();
    emitYMEvent({ type: 'continueInterestClick' });
  };

  return (
    <div className="interests-directions__plug">
      <img src={smileCatPortrait} alt="Успех" />
      <h5>Карточки закончились!</h5>
      <p className="interests-directions__plug-description">
        Чего-то не хватает?{' '}
        <Typography sx={linkStyles} component="span" onClick={handleClickLink}>
          Напиши в поддержку
        </Typography>{' '}
        и предложи добавить новый интерес!
      </p>
      <Button
        className="interests-directions__btn"
        size={isLargeScreen || isMobile ? 'large' : 'medium'}
        endIcon={<IconArrowOpen className="interests-entry__btn-icon" />}
        onClick={handleContinue}
      >
        Продолжить
      </Button>
    </div>
  );
};

export default connect(null, {
  getFeedbackLink: getFeedbackLinkActions.request,
})(NoDataPlug);
