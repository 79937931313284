import { buildConfig } from 'packages/buildConfig';
import { getAuthToken } from 'utils';

import BaseApi from './BaseApi';
import { ApiRequestMethod } from './types';
import { processBaseResponse } from './utils';

export class DnevnikApi extends BaseApi {
  constructor() {
    super({ url: '', prefix: '/jersey/api' });

    if (buildConfig.config) {
      this.url = buildConfig.config.DNEVNIK_URL || '';
    } else {
      // отписка не требуется
      buildConfig.onLoad((config) => {
        this.url = config.DNEVNIK_URL || '';
      });
    }
  }

  protected request: ApiRequestMethod = (method: string, { path, requestBody, requestHeaders }) => {
    return fetch(`${this.url}${this.prefix}${path}`, {
      method,
      body: requestBody,
      headers: {
        'auth-token': getAuthToken(),
        ...requestHeaders,
      },
    })
      .then(async (response) => {
        return await processBaseResponse(response);
      })
      .then(
        (response) => ({ response }),
        (error) => ({ error }),
      );
  };
}
