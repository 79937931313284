/* eslint-disable prettier/prettier */
/* eslint-disable */

import connectReducers from '../utils/connectReducers';

import {
  SET_DIAGNOSTIC_TYPE
} from '../actions';
import { DiagnosticTypeCode } from '../const';

export type TDiagnosticTypeState = {
  type: number
}

export interface IDiagnosticTypeReducers {
    [key: string]: (state: TDiagnosticTypeState, action: any) => TDiagnosticTypeState;
}

const initialState = {
  type: DiagnosticTypeCode.independent
};

const DiagnosticType: IDiagnosticTypeReducers = {
  [SET_DIAGNOSTIC_TYPE]: (state, { payload: type }: { payload: number }) => ({
    type
  })
};

export default connectReducers(initialState, DiagnosticType);
