import { ComponentType, ElementType, FC, PropsWithChildren } from 'react';

import { Box } from '@mui/material';
import { useAppSelector } from 'hooks';
import { IFooterContainerCommonProps } from 'portfolio3/containers/footers/types';

interface IWithFooterContainerProps {
  FooterComponent: ElementType<IFooterContainerCommonProps>;
  isFullWidth: boolean;
}

const WithFooterContainer: FC<IWithFooterContainerProps & PropsWithChildren> = ({
  FooterComponent,
  isFullWidth,
  children,
}) => {
  const { isViewing: isAdminViewing } = useAppSelector((state) => state.adminViewMode);

  return (
    /* стили необходимы для прижимания футера к низу страницы при любой высоте контента */
    <Box sx={{ display: 'grid', gridTemplateRows: '1fr auto', height: '100%' }} className="footer-content-wrapper">
      <Box
        sx={{ mb: '64px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}
        className="content"
      >
        {children}
      </Box>
      <FooterComponent isFullWidth={isFullWidth && !isAdminViewing} />
    </Box>
  );
};

const withFooter = (FooterComponent: ElementType<IFooterContainerCommonProps>, isFullWidth: boolean) => {
  return <P extends PropsWithChildren<unknown>>(WrappedComponent: ComponentType<P>): FC<P> => {
    return function withFooter({ ...props }) {
      return (
        <WithFooterContainer FooterComponent={FooterComponent} isFullWidth={isFullWidth}>
          <WrappedComponent {...props} />
        </WithFooterContainer>
      );
    };
  };
};

export default withFooter;
