/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useContext, useEffect, useMemo, useState } from 'react';

import SubjectRow from './subjectRow';
import { SubjectDetails } from '../SubjectDetails';
import SubjectDetailsEmpty from '../SubjectDetailsEmpty';
import SubjectTopicsEmpty from '../SubjectTopicsEmpty';
import ResultsLoader from '../ResultsLoader';
import SubjectHint from '../SubjectHint';
import PolarAreaChart from 'components/common/PolarAreaChart';
import ShowMore from 'components/common/ShowMore';
import performanceSubjectContext from '../../context/performanceSubjectContext';
import {
  IPerformanceDiagramData,
  IPerformanceDiagramSubject,
  IPerformanceSubjectTopicInfo,
  MarkType
} from '../../types';

import { Box, useMediaQuery } from '@mui/material';
import { commonTheme } from 'portfolio3/styles/theme';
import { emitYMEvent } from 'portfolio3/features/yandexMetrika';

import './index.scss';

const COLORS = ['#FFE8CC', '#FFDEEB', '#F3D9FA', '#E5DBFF', '#DBE4FF', '#D0EBFF', '#C5F6FA', '#C3FAE8', '#D3F9D8', '#E9FAC8', '#FFF3BF'];
const ACTIVE_COLORS = ['#FFC078', '#FAA2C1', '#E599F7', '#B197FC', '#91A7FF', '#74C0FC', '#66D9E8', '#63E6BE', '#8CE99A', '#C0EB75', '#FFE066'];

function extendArrayWithLastItem<T>(array: T[], desiredLength: number) {
  return array.length < desiredLength
    ? [
      ...array,
      ...Array.from({ length: desiredLength - array.length }, () => array[array.length - 1])
    ]
    : array;
};

interface IChartData {
  average?: number | string;
  labels?: string[];
  data?: number[];
  colors?: string[];
}

interface IPerformanceDiagram {
  markType: MarkType;
  performanceData: IPerformanceDiagramData;
  subjectTopic?: IPerformanceSubjectTopicInfo;
  isTopicsLoading: boolean;
  onOpenDetailedInfoSubject: (a: boolean) => void;
}

const PerformanceDiagram: React.FC<IPerformanceDiagram> = ({
  markType,
  performanceData,
  subjectTopic,
  isTopicsLoading,
  onOpenDetailedInfoSubject,
}) => {
  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const initialSubjectsDisplayCount = 5;
  const [chartData, setChartData] = useState<IChartData>();
  const [subjectsDisplayCount, setSubjectsDisplayCount] = useState(initialSubjectsDisplayCount);
  const { selectedSubject, setSelectedSubject } = useContext(performanceSubjectContext);

  const isAverageMark = markType === MarkType.AVERAGE;
  const totalSubjectsCount = performanceData.currentYear.subjects.length;
  const subjectColors = extendArrayWithLastItem(COLORS, totalSubjectsCount);
  const subjectActiveColors = extendArrayWithLastItem(ACTIVE_COLORS, totalSubjectsCount);
  const hiddenSubjectsCount = totalSubjectsCount - initialSubjectsDisplayCount;
  const isAllSubjectsShown = totalSubjectsCount === subjectsDisplayCount;

  const subjectTopicsCount = subjectTopic?.totalTopics || 0;

  const isVisibleDetails = selectedSubject && (subjectTopicsCount > 0 || isTopicsLoading);
  const isEmptyTopics = selectedSubject && subjectTopicsCount === 0 && !isTopicsLoading;

  const diagramSubjects: IPerformanceDiagramSubject[] = useMemo(() => {
    const diagramSubjects: IPerformanceDiagramSubject[] = performanceData.currentYear.subjects.map((subject, index) => {

      const currentYearMark = isAverageMark ? subject.averageMark : subject.averageYearMark;
      const previousYearSubject = performanceData.previousYear?.subjects
        .find((previousYearSubject) => previousYearSubject.subjectName === subject.subjectName);
      const previousYearMark = isAverageMark ? previousYearSubject?.averageMark : previousYearSubject?.averageYearMark;
      const markChange = previousYearMark ? currentYearMark - previousYearMark : 0;

      return {
        name: subject.subjectName,
        mark: currentYearMark,
        markChange: markChange,
        color: subjectActiveColors[index]
      };
    });
    return diagramSubjects;
  }, [performanceData.currentYear, performanceData.previousYear]);

  useEffect(() => {
    setSubjectsDisplayCount(initialSubjectsDisplayCount)
  }, [performanceData])

  useEffect(() => {
    const performance = performanceData.currentYear;

    setChartData({
      average: isAverageMark ? performance.averageAllMarks : performance.averageAllYearMarks,
      labels: performance.subjects.map((subject) => subject.subjectName),
      data: performance.subjects.map((subject) => {
        const value = isAverageMark ? subject.averageMark : subject.averageYearMark;
        return value;
      }),
      colors: subjectColors.map((color, index) => {
        const subjectIndex = performance.subjects.findIndex((subject) => subject.subjectName === selectedSubject?.name);
        if (subjectIndex === -1 || subjectIndex === index) return subjectActiveColors[index];
        return color;
      })
    });
  }, [performanceData, markType, selectedSubject]);

  const renderSubjectDetails = () => {
    if (isVisibleDetails && selectedSubject) {
      return (
        <SubjectDetails
          subject={selectedSubject.name}
          subjectResult={selectedSubject.mark}
          subjectTopic={subjectTopic}
        />
      );
    }

    if (isEmptyTopics) return <SubjectTopicsEmpty />;

    return <SubjectDetailsEmpty />;
  };

  const handleShowAllSubjects = () => {
    emitYMEvent({ type: 'listItemsDisclosure' });
    setSubjectsDisplayCount(totalSubjectsCount);
  };
  const handleShowInitialSubjects = () => setSubjectsDisplayCount(initialSubjectsDisplayCount);

  const handleSelectSubject = (subject: IPerformanceDiagramSubject) => {
    // нужно для более плавного обновления боковой панели при смене предмета
    requestAnimationFrame(() => {
      setSelectedSubject({
        name: subject.name,
        mark: subject.mark
      });
      onOpenDetailedInfoSubject(true);
    });
  };

  return (
    <div className="performance-diagram-wrapper">
      <div className="performance-diagram">
        <div className="performance-diagram__chart-container">
          {chartData && (
            <PolarAreaChart
              average={chartData.average}
              labels={chartData.labels ?? []}
              data={chartData.data ?? []}
              colors={chartData.colors ?? []}
              min={1}
              max={5.1}
              stepSize={1}
            />
          )}
          <div className="performance-diagram__subjects">
            {diagramSubjects.slice(0, subjectsDisplayCount).map((subject) => (
              <SubjectRow
                key={subject.name}
                selected={subject.name === selectedSubject?.name}
                onSubjectSelected={() => handleSelectSubject(subject)}
                {...subject}
              />
            ))}
            <ShowMore
              hiddenCount={hiddenSubjectsCount}
              isAllShown={isAllSubjectsShown}
              onShowInitialItems={handleShowInitialSubjects}
              onShowAllItems={handleShowAllSubjects}
            />
          </div>
        </div>
        <div className="performance-diagram__details-container">
          {isTopicsLoading ? <ResultsLoader /> : renderSubjectDetails()}
        </div>
      </div>
      {isMobile && (
        <Box marginTop="12px">
          <SubjectHint />
        </Box>
      )}
    </div>
  );
};

export default PerformanceDiagram;
