/* eslint-disable prettier/prettier */
/* eslint-disable */

import { ColorKeys, Colors } from '../style/variables';
import { ISectionColorTheme } from '../types';

export const ProfileColorTheme: ISectionColorTheme = getSectionColorTheme('blue');
export const StudyColorTheme: ISectionColorTheme = getSectionColorTheme('violet');
export const ScienceColorTheme: ISectionColorTheme = getSectionColorTheme('indigo');
export const SportColorTheme: ISectionColorTheme = getSectionColorTheme('cyan');
export const CreationColorTheme: ISectionColorTheme = getSectionColorTheme('orange');
export const CultureColorTheme: ISectionColorTheme = getSectionColorTheme('red');
export const CivilColorTheme: ISectionColorTheme = getSectionColorTheme('green');
export const ProfessionColorTheme: ISectionColorTheme = getSectionColorTheme('grape');

function getSectionColorTheme(colorPrefix: string): ISectionColorTheme {
  const getColorKey = (prefix: string, index: string) => `${prefix}_${index}` as ColorKeys;

  return {
    color_01: Colors[getColorKey(colorPrefix, '01')],
    color_02: Colors[getColorKey(colorPrefix, '02')],
    color_03: Colors[getColorKey(colorPrefix, '03')],
    color_04: Colors[getColorKey(colorPrefix, '04')],
    color_05: Colors[getColorKey(colorPrefix, '05')],
    color_06: Colors[getColorKey(colorPrefix, '06')],
    color_07: Colors[getColorKey(colorPrefix, '07')],
    color_08: Colors[getColorKey(colorPrefix, '08')],
    color_09: Colors[getColorKey(colorPrefix, '09')],
    color_10: Colors[getColorKey(colorPrefix, '10')],
    color_11: Colors[getColorKey(colorPrefix, '11')],
  };
}
