import { IRootState } from 'reducers';
import { createSelector } from 'reselect';

import { mapStudySpoTrainingResponse } from './mappers';

const studySpoTrainingSelector = createSelector(
  (state: IRootState) => state.studentSpoEducation,
  (state: IRootState) => state.professionProgrammRef,
  (studentEducation, professionProgrammRef) => {
    return mapStudySpoTrainingResponse(studentEducation, professionProgrammRef);
  },
);

export default studySpoTrainingSelector;
