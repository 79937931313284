import { FC } from 'react';

import { Box, Typography, useMediaQuery } from '@mui/material';
import { portfolioLogo, portfolioLogoSmall } from 'images';
import { commonTheme } from 'portfolio3/styles/theme';

import * as styles from './styles';

const ServiceTitle: FC = () => {
  const isSmallScreen = useMediaQuery(commonTheme.breakpoints.down('commonSm'));
  const logo = isSmallScreen ? portfolioLogoSmall : portfolioLogo;

  return (
    <Box className="service-title" sx={styles.root}>
      <img src={logo} alt="логотип" />
      <Typography sx={styles.serviceName}>Портфолио</Typography>
    </Box>
  );
};

export default ServiceTitle;
