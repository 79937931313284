import { FC } from 'react';

import { IconCheckmark } from 'icons';
import { NeutralColors } from 'portfolio3/styles';

import IconBadge from './IconBadge';

const PartiallyVerifiedBadge: FC = () => {
  return (
    <IconBadge backgroundColor={NeutralColors.light_neutrals_200} iconColor={NeutralColors.light_neutrals_600}>
      <IconCheckmark />
    </IconBadge>
  );
};

export default PartiallyVerifiedBadge;
