import { SxStyles } from 'types';

export const inputsContainer: SxStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',

  width: 'max-content',
};

export const input: SxStyles = {
  width: '80px',

  // скрытие дефолтных стрелок числового инпута
  '& input': {
    appearance: 'textarea',

    '::-webkit-inner-spin-button, ::-webkit-outer-spin-button': {
      display: 'none',
    },
  },
};
