import { FC } from 'react';

import { styled } from '@mui/material';
import { InputFormControlContext } from 'context';
import { skipPropsForwarding } from 'utils';

import { InputRenderMode } from '../../types';
import BaseFormControl from '../bases/BaseFormControl';
import { IBaseFormControlProps, IImplFormControlProps } from '../types';

const StyledFormControl = styled(BaseFormControl, {
  shouldForwardProp: skipPropsForwarding<IBaseFormControlProps<InputRenderMode>>(['renderMode']),
})<IBaseFormControlProps<InputRenderMode>>(() => {
  return {
    position: 'relative',
    width: '100%',
  };
});

/**
 * Компонент FormControl для контролов с базой InputBase
 */
const FormControl: FC<IImplFormControlProps<InputRenderMode>> = ({ label, control, helper, ...props }) => {
  const { renderMode, inputSize } = props;

  const withTopMargin = renderMode === 'floating';

  return (
    <InputFormControlContext.Provider
      value={{
        renderMode,
        inputSize,
      }}
    >
      <StyledFormControl withTopMargin={withTopMargin} {...props}>
        <>
          {label}
          {control}
          {helper}
        </>
      </StyledFormControl>
    </InputFormControlContext.Provider>
  );
};

export default FormControl;
