export enum AvatarTypeRefKeys {
  DEFAULT = 'default',
  FIRST_MOVEMENT = 'firstMovement',
}

// avatar_type_ref
export const AvatarTypeRef: Record<AvatarTypeRefKeys, number> = {
  default: 1,
  firstMovement: 2,
};
