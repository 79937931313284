import { FC } from 'react';

import { IconArrowUp } from 'icons';
import { Button } from 'portfolio3/ui-kit/button';

interface IScrollTopButtonProps {
  onClick: () => void;
}

const ScrollTopButton: FC<IScrollTopButtonProps> = ({ onClick }) => {
  return (
    <Button iconLeft={<IconArrowUp />} variant="tetriary" onClick={onClick} sx={{ width: '100%' }}>
      Вернуться в начало списка
    </Button>
  );
};

export default ScrollTopButton;
