import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { useSPOStudent } from 'hooks';
import { IconClose } from 'icons/edit';
import { UnstyledButton } from 'portfolio3/ui-kit';
import { mergeSx } from 'utils';

import * as styles from './style';

interface IThemeAlertProps {
  onClose: VoidFunction;
}

const ThemeAlert: FC<IThemeAlertProps> = ({ onClose }) => {
  const isSpoStudent = useSPOStudent();

  const backgroundStyles = isSpoStudent ? styles.rootSpoBackground : styles.rootDefaultBackground;

  return (
    <Box sx={mergeSx(styles.root, backgroundStyles)}>
      <UnstyledButton onClick={onClose} sx={styles.button}>
        <IconClose />
      </UnstyledButton>
      <Typography sx={styles.paragraph}>
        Все, с кем ты поделишься своим портфолио тоже увидят это оформление ;)
      </Typography>
      <Typography sx={styles.paragraph}>А мы будем со временем добавлять сюда новые темы!</Typography>
    </Box>
  );
};

export default ThemeAlert;
