import React from 'react';

import { Box, Stack, Theme, Typography } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';

import IconStars from '../assets/IconStars.svg';
import { wrapperStyles } from '../styles';
import { IAchievementProps } from '../types';

const getStyles = (theme: Theme) => ({
  flexDirection: 'row',
  gap: 0.5,

  [theme.breakpoints.down('commonSm')]: {
    flexDirection: 'column',
  },
});

const SpecialistAchievement: React.FC<IAchievementProps> = ({ text }) => {
  return (
    <Box sx={wrapperStyles}>
      <Stack sx={getStyles}>
        <Stack>
          <Typography variant="Paragraph LG/Bold" color={NeutralColors.light_neutrals_0}>
            Специалист
          </Typography>
          <Typography variant="Paragraph MD/Regular" color={NeutralColors.light_neutrals_0}>
            {text}
          </Typography>
        </Stack>
        <img src={IconStars} alt="" />
      </Stack>
    </Box>
  );
};

export default SpecialistAchievement;
