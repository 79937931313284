/* eslint-disable max-len */
import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconSectionProfile: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#a)" fill={props.fill || 'currentColor' || '#339AF0'}>
      <path d="M10 2.24c4.275 0 7.758 3.483 7.758 7.756S14.275 17.751 10 17.751 2.242 14.27 2.242 9.996 5.725 2.241 10 2.241ZM10 .5C4.751.5.5 4.75.5 9.996c0 5.247 4.251 9.496 9.5 9.496s9.5-4.25 9.5-9.496C19.5 4.749 15.249.5 10 .5Z" />
      <path d="M10 6.292a2.598 2.598 0 0 1 2.597 2.596 2.598 2.598 0 0 1-5.193 0A2.598 2.598 0 0 1 10 6.292Zm0-1.74a4.334 4.334 0 0 0-4.338 4.336A4.334 4.334 0 0 0 10 13.224a4.334 4.334 0 0 0 4.339-4.336A4.334 4.334 0 0 0 10 4.55Z" />
      <path d="M2.978 16.382C4.72 18.289 7.213 19.5 10 19.5a9.442 9.442 0 0 0 7.022-3.118A7.702 7.702 0 0 0 10 11.848a7.702 7.702 0 0 0-7.022 4.534Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default IconSectionProfile;
