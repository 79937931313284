import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root: SxStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
};

export const serviceName: SxStyles = (theme) => {
  return {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '32px',
    color: NeutralColors.light_neutrals_800,

    [theme.breakpoints.down('commonSm')]: {
      fontSize: '18px',
      lineHeight: '24px',
    },
  };
};
