import { ComponentProps, FC } from 'react';

import { Box, Typography } from '@mui/material';
import { useAppSelector, useDataLoad } from 'hooks';
import { PerformanceYearCard } from 'portfolio3/features/pdf/components/cards';
import PdfNoDataText from 'portfolio3/features/pdf/components/PdfNoDataText';
import { IMappedLearningYearPerformance, performanceModel } from 'portfolio3/features/performance';
import { getDataLoadRequests } from 'utils';

import { widgetPdfIcons } from '../../../icons';
import PdfCommonDataEntityContainer from '../dataEntityContainers/common';

import './index.scss';

type PerformanceYearCardProps = ComponentProps<typeof PerformanceYearCard>;

const mapYearPerformanceToCard = (yearPerformance: IMappedLearningYearPerformance): PerformanceYearCardProps | null => {
  const { averageGrade, subjects } = yearPerformance;

  const mappedSubjects = subjects.map((subject): PerformanceYearCardProps['subjects'][number] => {
    return {
      name: subject.name,
      result: subject.grade,
    };
  });

  if (mappedSubjects.length === 0) return null;

  return {
    averageResult: averageGrade,
    subjects: mappedSubjects,
  };
};

const PdfPerformanceContainer: FC = () => {
  const averagePerformance = useAppSelector(performanceModel.selectors.learningYearAveragePerformanceSelector);
  const finalPerformance = useAppSelector(performanceModel.selectors.learningYearFinalPerformanceSelector);

  const averageYears = averagePerformance.content.map((performance) => performance.year);
  const finalYears = finalPerformance.content.map((performance) => performance.year);

  const learningYears = Array.from(new Set([...averageYears, ...finalYears]));

  useDataLoad({ shouldLoad: true, requests: getDataLoadRequests().performanceEntryMethods });

  return (
    <PdfCommonDataEntityContainer title="Предметные результаты" icon={widgetPdfIcons.pdfIconStudyResults}>
      {learningYears.map((learningYear) => {
        const currentYearAveragePerformance = averagePerformance.content.find(
          (performance) => performance.year === learningYear,
        );
        const currentYearFinalPerformance = finalPerformance.content.find(
          (performance) => performance.year === learningYear,
        );

        const averagePerformanceCardProps = currentYearAveragePerformance
          ? mapYearPerformanceToCard(currentYearAveragePerformance)
          : null;
        const finalPerformanceCardProps = currentYearFinalPerformance
          ? mapYearPerformanceToCard(currentYearFinalPerformance)
          : null;

        return (
          <Box
            key={learningYear}
            className="pdf-performance-block"
            sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}
          >
            {/* средняя */}
            <Box>
              <Typography variant="Headings/H5" marginBottom="16px">
                Средняя успеваемость за {learningYear} учебный год
              </Typography>
              {averagePerformanceCardProps ? (
                <PerformanceYearCard {...averagePerformanceCardProps} />
              ) : (
                <PdfNoDataText />
              )}
            </Box>
            {/* итоговая */}
            <Box>
              <Typography variant="Headings/H5" marginBottom="16px">
                Итоговая успеваемость за {learningYear} учебный год
              </Typography>
              {finalPerformanceCardProps ? <PerformanceYearCard {...finalPerformanceCardProps} /> : <PdfNoDataText />}
            </Box>
          </Box>
        );
      })}
    </PdfCommonDataEntityContainer>
  );
};

export default PdfPerformanceContainer;
