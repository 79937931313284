import { FC } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';
import { getDoughnutData } from 'utils';

import DiagramCard from '../../DiagramCard';
import Diagram from './Diagram';
import * as styles from './styles';

interface IBestSubjectDiagramProps {
  subject: string;
  date: string;
  diagramColor: string;
  resultValue: number;
  resultPercent: number;
  maxValue: number;
  levelType: string;
}

const BestSubjectDiagram: FC<IBestSubjectDiagramProps> = ({
  subject,
  date,
  diagramColor,
  resultValue,
  resultPercent,
  maxValue,
  levelType,
}) => {
  const data = getDoughnutData(resultValue, maxValue);

  return (
    <DiagramCard title="Твой лучший результат" sx={styles.diagramCard}>
      <Box sx={styles.container}>
        <Diagram
          total={resultPercent}
          title={levelType}
          color={diagramColor}
          incomingData={data.incomingData}
          incomingLabels={data.incomingLabels}
        />
        <Stack gap="8px">
          <Typography variant="Paragraph LG/Semi Bold">{subject}</Typography>
          <Typography variant="Paragraph LG/Regular">
            {resultValue} баллов из {maxValue}
          </Typography>
          <Typography variant="Paragraph LG/Regular" color={NeutralColors.light_neutrals_600}>
            {date}
          </Typography>
        </Stack>
      </Box>
    </DiagramCard>
  );
};

export default BestSubjectDiagram;
