import { createSelector } from 'reselect';

import { IRootState } from '../../../reducers';
import { mapSportCompetitionsResponse } from './mappers';

const sportCompetitionsSelector = createSelector(
  [
    (state: IRootState) => state.studentEvents,
    (state: IRootState) => state.studentSportRewards,
    (state: IRootState) => state.sportKinds,
    (state: IRootState) => state.sectionRef,
  ],
  (studentEvents, studentRewards, sportKinds, sectionRef) => {
    return mapSportCompetitionsResponse(studentEvents, studentRewards, sportKinds, sectionRef);
  },
);

export default sportCompetitionsSelector;
