import { FC, useDeferredValue, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Typography } from '@mui/material';
import { setCurrentStudentAction } from 'actions';
import { IPerson } from 'api/types';
import { useAppSelector, useDisclosure } from 'hooks';
import Table from 'portfolio3/components/common/Table';
import { SearchInput } from 'portfolio3/ui-kit';
import { Button } from 'portfolio3/ui-kit/button';
import { InputController } from 'portfolio3/ui-kit/types';
import { PersonsListState } from 'reducers/employee/persons';
import { SortType } from 'types';

import DataEntryDrawer from '../../components/DataEntryDrawer';
import { mapPersonToChildInfo } from '../../utils';
import * as styles from './styles';

interface ISearchResultListContainerProps {
  personsList: PersonsListState;
}

const SearchResultListContainer: FC<ISearchResultListContainerProps> = ({ personsList }) => {
  const dispatch = useDispatch();
  const organizations = useAppSelector((state) => state.organizations.content);

  const mappedStudents =
    personsList.content?.student.map((student) => {
      const { lastname, firstname, patronymic, mshId } = student;
      return {
        ...student,
        fullName: [lastname, firstname, patronymic].join(' '),
        meshId: mshId,
      };
    }) ?? [];

  const { isOpen: isDrawerOpen, onClose: onDrawerClose, onOpen: onDrawerOpen } = useDisclosure();
  const [search, setSearch] = useState('');
  const deferredSearch = useDeferredValue(search);
  const [sortType, setSortType] = useState<SortType>(null);

  const searchController: InputController = {
    handleChange(value) {
      setSearch(value);
    },
  };

  const handleChangeSort = () => {
    let nextSort: SortType = null;

    if (!sortType) nextSort = 'asc';
    if (sortType === 'asc') nextSort = 'desc';
    if (sortType === 'desc') nextSort = null;

    setSortType(nextSort);
  };

  const openDataEntry = (student: IPerson) => {
    const mappedStudent = mapPersonToChildInfo(student, organizations);

    dispatch(setCurrentStudentAction(mappedStudent));
    onDrawerOpen();
  };

  const filteredStudents = mappedStudents.filter((student) => {
    return student.fullName.toLowerCase().includes(deferredSearch.toLowerCase().trim());
  });

  const sortedFilteredStudents =
    sortType === null
      ? filteredStudents
      : filteredStudents.sort((a, b) => {
          const firstName = a.fullName.toLowerCase();
          const secondName = b.fullName.toLowerCase();
          if (sortType === 'asc') {
            return firstName.localeCompare(secondName);
          } else {
            return secondName.localeCompare(firstName);
          }
        });

  const headerElement = (
    <Box sx={styles.header}>
      <Typography variant="Headings/H6" marginBottom="4px">
        Доступные учащиеся
      </Typography>
      <SearchInput
        value={search}
        controller={searchController}
        renderMode="fixed"
        size="small"
        placeholder="Поиск..."
        sx={{ width: '300px' }}
      />
    </Box>
  );

  const density = 'large';

  return (
    <Table
      headerElement={headerElement}
      loading={personsList.loading}
      tableProps={{ sx: { gridTemplateColumns: '1fr auto' } }}
    >
      <Table.THead>
        <Table.Row>
          <Table.HeadCell withSorting sortType={sortType} boxProps={{ onClick: handleChangeSort }}>
            Учащийся
          </Table.HeadCell>
          <Table.HeadCell />
        </Table.Row>
      </Table.THead>
      <Table.TBody>
        {sortedFilteredStudents.length === 0 && (
          <Typography variant="Headings/H6" sx={styles.noDataPlug}>
            По вашему запросу ничего не найдено. Ослабьте условия поиска и попробуйте еще раз
          </Typography>
        )}

        {sortedFilteredStudents.map((student) => {
          const { fullName, mshId } = student;

          return (
            <Table.Row key={mshId}>
              <Table.DataCell density={density}>
                <Typography variant="Paragraph MD/Regular">{fullName}</Typography>
              </Table.DataCell>
              <Table.DataCell density={density}>
                <Button onClick={() => openDataEntry(student)} size="small">
                  Внести данные
                </Button>
              </Table.DataCell>
            </Table.Row>
          );
        })}
      </Table.TBody>

      <DataEntryDrawer isOpen={isDrawerOpen} onClose={onDrawerClose} students={sortedFilteredStudents} />
    </Table>
  );
};

export default SearchResultListContainer;
