/* eslint-disable prettier/prettier */
/* eslint-disable */

import { FC, PropsWithChildren, useState } from 'react';

import {
  IconButton, PopperPlacementType, Typography
} from '@mui/material';

import Popper from '../../common/Popper';
import { Button } from 'portfolio3/ui-kit/button';
import { iconClose } from '../../../icons';
import { Textarea } from 'portfolio3/ui-kit';

import './index.scss';

interface IReasonMessagePopperProps {
  isOpen: boolean,
  anchorElement: HTMLElement | null,
  placement?: PopperPlacementType,
  onClose: () => void,
  onSubmit: (comment?: string) => void
}

const ReasonMessagePopper: FC<IReasonMessagePopperProps & PropsWithChildren> = ({
  isOpen,
  anchorElement,
  children,
  placement,
  onClose,
  onSubmit
}) => {
  const [comment, setComment] = useState<string>();

  const reasonMessageController = {
    handleChange: (message: string) => {
      if (message.length > 500) return;
      setComment(message);
    }
  };

  const handleClosePopper = () => {
    setComment(undefined);
    onClose();
  };

  return (
    <Popper
      open={isOpen}
      anchorEl={anchorElement}
      placement={placement || 'top-start'}
      className="message"
      onClosePopperMenu={handleClosePopper}
      role={undefined}
      transition
      disablePortal
      modifiers={[
        {name: 'preventOverflow', enabled: true},
        {name: 'arrow', enabled: true},
      ]}
    >
      <div>
        <Typography className="message__title" variant="Paragraph LG/Semi Bold">Отключение раздела</Typography>
        <div data-popper-arrow className="message__arrow" />
        <IconButton
          className="message__btn-close"
          disableTouchRipple
          onClick={handleClosePopper}
          size="large">
          <img src={iconClose} alt="закрыть" />
        </IconButton>
        <Textarea
          placeholder="Укажите причину отключения раздела (не обязательно)..."
          controller={reasonMessageController}
          value={comment}
          renderMode="fixed"
          size="small"
        />
        <div className="message__btn-container">
          {children}
          <Button
            size="small"
            className="message__button"
            onClick={() => { onSubmit(comment); setComment(undefined); }}
          >
            Отключить раздел
          </Button>
        </div>
      </div>
    </Popper>
  );
};

export default ReasonMessagePopper;
