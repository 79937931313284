/* eslint-disable max-len */
import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconStudyDiagnosticsCommon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.5 12.5H0V24h6.5V12.5ZM15.2 6.3H8.7V24h6.5V6.3ZM24 0h-6.5v24H24V0Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconStudyDiagnosticsCommon;
