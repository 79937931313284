/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useMemo } from 'react';

import { connect } from 'react-redux';

import { GiaType } from '../../../const';

import { IRootState } from '../../../reducers';
import {
  IGiaItem,
  ISectionsVisibility,
  ICommonResponse
} from '../../../api/types';
import ExamContainer from './ExamContainer';
import PdfCommonDataEntityContainer from '../../../containers/pdfStudent/dataEntityContainers/common';

import './index.scss';
import { useDataLoad } from 'hooks';
import { getDataLoadRequests } from 'utils';
import { widgetPdfIcons } from 'icons';

interface IPdfGiaProps {
  studentGiaList: ICommonResponse<IGiaItem>;
  displaySettings?: ISectionsVisibility;
}

const PdfGia: React.FC<IPdfGiaProps> = ({
  studentGiaList,
  displaySettings
}) => {
  useDataLoad({ shouldLoad: true, requests: getDataLoadRequests().studyGia });

  const studentGia: IGiaItem[] = studentGiaList.content;

  const OgeExams = useMemo(() => studentGia.filter((govExam) => govExam.formaGia === GiaType.oge), [studentGia]);
  const Gve9Exams = useMemo(() => studentGia.filter((govExam) => govExam.formaGia === GiaType.gve9), [studentGia]);
  const EgeExams = useMemo(() => studentGia.filter((govExam) => govExam.formaGia === GiaType.ege), [studentGia]);
  const Gve11Exams = useMemo(() => studentGia.filter((govExam) => govExam.formaGia === GiaType.gve11), [studentGia]);
  const OtherExams = useMemo(() => studentGia.filter((govExam) => govExam.formaGia === GiaType.writing || govExam.formaGia === GiaType.narration
  || govExam.formaGia === GiaType.interviewRus), [studentGia]);

  return (
    <PdfCommonDataEntityContainer
      title="Государственная итоговая аттестация"
      icon={widgetPdfIcons.pdfIconStudyGia}
    >
      <>
        {displaySettings?.oge && <ExamContainer title={GiaType.ogeFullName} exams={OgeExams} />}
        {displaySettings?.ege && <ExamContainer title={GiaType.egeFullName} exams={EgeExams} />}
        {displaySettings?.gve9 && <ExamContainer title={GiaType.gve9FullName} exams={Gve9Exams} />}
        {displaySettings?.gve11 && <ExamContainer title={GiaType.gve11FullName} exams={Gve11Exams} />}
        {displaySettings?.other && <ExamContainer exams={OtherExams} />}
      </>
    </PdfCommonDataEntityContainer>
  );
};

export default connect(
  (state: IRootState) => ({
    studentGiaList: state.studentGiaList,
  })
)(PdfGia);
