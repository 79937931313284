import { FC, ReactNode } from 'react';

import { Box, ThemeProvider } from '@mui/material';
import { AccentColorType } from 'portfolio3/styles';
import { generateAccentTheme } from 'portfolio3/styles/theme';
import { AccentBadge, NeutralBadge } from 'portfolio3/ui-kit/badges';
import { SxStyles } from 'types';

interface IFormEntityTagsProps {
  accentColor: AccentColorType;
  categoryName: string;
  categoryIcon: ReactNode;
  typeName: string;
}

const root: SxStyles = {
  display: 'flex',
  gap: '8px',
};

const FormEntityTags: FC<IFormEntityTagsProps> = ({ accentColor, categoryName, categoryIcon, typeName }) => {
  return (
    <Box sx={root}>
      <ThemeProvider theme={generateAccentTheme(accentColor)}>
        <AccentBadge>
          {categoryIcon}
          {categoryName}
        </AccentBadge>
      </ThemeProvider>
      <NeutralBadge>{typeName}</NeutralBadge>
    </Box>
  );
};

export default FormEntityTags;
