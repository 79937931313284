import { StudentSectionSettingTypeCodes } from 'const';
import { useAppSelector } from 'hooks';
import { visibilitySettingsSelector } from 'selectors';
import { findTargetVisibilitySetting } from 'utils/localVisibilitySettingsService';

import { collection as searchActions } from '../model/actions';
import { searchCultureOnlineEventsSelector, searchCultureVisitingsSelector } from '../model/selectors';

const cultureCode = StudentSectionSettingTypeCodes.culture;
const visitingsCode = StudentSectionSettingTypeCodes.cultureOfflineVisiting;
const onlineEventsCode = StudentSectionSettingTypeCodes.cultureOnlineVisiting;

const useSearchCulture = () => {
  const settings = useAppSelector(visibilitySettingsSelector);
  const { loading: isVisitingsLoading, content: visitings } = useAppSelector(searchCultureVisitingsSelector);
  const { loading: isOnlineEventsLoading, content: onlineEvents } = useAppSelector(searchCultureOnlineEventsSelector);

  const cultureEntitiesCount = [visitings.length, onlineEvents.length].reduce((prev, current) => prev + current, 0);

  const hasVisitings = visitings.length > 0;
  const hasOnlineEvents = onlineEvents.length > 0;

  const isVisitingsEnabled =
    findTargetVisibilitySetting(settings, [cultureCode, visitingsCode])?.computedVisibility ?? false;
  const isOnlineEventsEnabled =
    findTargetVisibilitySetting(settings, [cultureCode, onlineEventsCode])?.computedVisibility ?? false;

  const isCultureVisitingVisible = hasVisitings && isVisitingsEnabled;
  const isCultureOnlineEventVisible = hasOnlineEvents && isOnlineEventsEnabled;

  const isCultureLoading = isVisitingsLoading || isOnlineEventsLoading;
  const isCultureVisible = isCultureVisitingVisible || isCultureOnlineEventVisible;

  const culureActions = [
    isVisitingsEnabled && searchActions.searchEventsActions,
    isVisitingsEnabled && searchActions.searchCheckinHistoryActions,
    isVisitingsEnabled && searchActions.searchCultureVisitingsActions,
    isOnlineEventsEnabled && searchActions.searchEventsActions,
  ];

  return {
    visitings,
    onlineEvents,
    isCultureVisitingVisible,
    isCultureOnlineEventVisible,
    isCultureLoading,
    isCultureVisible,
    culureActions,
    cultureEntitiesCount,
  };
};

export default useSearchCulture;
