import { FC, ReactNode } from 'react';

import { Box, Typography } from '@mui/material';
import { SxStyles } from 'types';

interface IDrawerHeaderTitleProps {
  title: string | ReactNode;
  crossButton?: ReactNode;
}

const root: SxStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  gap: '12px',
};

const DrawerHeaderTitle: FC<IDrawerHeaderTitleProps> = ({ title, crossButton }) => {
  return (
    <Box className="drawer-header-title" sx={root}>
      {typeof title === 'string' ? <Typography variant="Headings/H4">{title}</Typography> : title}
      {crossButton}
    </Box>
  );
};

export default DrawerHeaderTitle;
