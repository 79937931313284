/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

const IconThirdCategory: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="50" height="50" fill="#E5E5E5"/>
    <rect width="1924" height="1397" transform="translate(-1170 -758)" fill="white"/>
    <g clip-path="url(#clip0)">
    <path d="M24.8817 54.3834C41.0707 54.3834 54.1944 41.4519 54.1944 25.5C54.1944 9.54808 41.0707 -3.38348 24.8817 -3.38348C8.69281 -3.38348 -4.43091 9.54808 -4.43091 25.5C-4.43091 41.4519 8.69281 54.3834 24.8817 54.3834Z" fill="white"/>
    <path d="M24.8816 54.5C19.0607 54.5 13.3706 52.7992 8.53067 49.6126C3.69078 46.4261 -0.0814524 41.8969 -2.30901 36.5978C-4.53656 31.2988 -5.11939 25.4678 -3.9838 19.8424C-2.8482 14.2169 -0.0451756 9.04964 4.07081 4.99391C8.1868 0.938193 13.4309 -1.82379 19.1399 -2.94276C24.849 -4.06173 30.7665 -3.48743 36.1443 -1.29249C41.5221 0.902448 46.1186 4.61945 49.3525 9.38847C52.5864 14.1575 54.3125 19.7643 54.3125 25.5C54.3053 33.1891 51.2022 40.5612 45.6844 45.9982C40.1666 51.4352 32.685 54.4929 24.8816 54.5ZM24.8816 -3.27588C19.1057 -3.27588 13.4595 -1.5882 8.65703 1.57374C3.85454 4.73567 0.111461 9.22985 -2.09888 14.488C-4.30922 19.7461 -4.88755 25.5319 -3.76073 31.1139C-2.6339 36.6959 0.147458 41.8232 4.23164 45.8476C8.31581 49.872 13.5194 52.6126 19.1843 53.723C24.8492 54.8333 30.7211 54.2634 36.0573 52.0855C41.3935 49.9075 45.9545 46.2192 49.1634 41.487C52.3723 36.7549 54.0851 31.1913 54.0851 25.5C54.0754 17.8711 50.9956 10.5573 45.5209 5.16286C40.0463 -0.231604 32.6239 -3.26639 24.8816 -3.27588Z" fill="#969696"/>
    <path d="M24.8818 52.0796C39.7794 52.0796 51.8563 40.1795 51.8563 25.5C51.8563 10.8205 39.7794 -1.07959 24.8818 -1.07959C9.98414 -1.07959 -2.09277 10.8205 -2.09277 25.5C-2.09277 40.1795 9.98414 52.0796 24.8818 52.0796Z" fill="#748FFC"/>
    <path d="M24.8818 49.704C38.4479 49.704 49.4454 38.8675 49.4454 25.5C49.4454 12.1325 38.4479 1.29596 24.8818 1.29596C11.3156 1.29596 0.318115 12.1325 0.318115 25.5C0.318115 38.8675 11.3156 49.704 24.8818 49.704Z" fill="#E83F40"/>
    <path d="M24.8817 48.0007C37.4932 48.0007 47.7169 37.9268 47.7169 25.5C47.7169 13.0731 37.4932 2.99921 24.8817 2.99921C12.2703 2.99921 2.04663 13.0731 2.04663 25.5C2.04663 37.9268 12.2703 48.0007 24.8817 48.0007Z" fill="#969696"/>
    <path d="M44.2779 4.03013L44.842 6.48639L41.9944 6.54018L37.7185 11.6141L12.9001 10.9238L8.35129 6.49536H5.2308L5.71298 3.65363C2.54895 6.37991 0.00854111 9.73718 -1.74017 13.5033C-3.48888 17.2694 -4.40602 21.3585 -4.43091 25.5C-4.42402 31.4624 -2.54463 37.2767 0.949326 42.1448C4.44328 47.0128 9.38056 50.6961 15.0836 52.6892L19.8871 50.977L16.985 48.1352L19.2958 42.6042H30.868L33.4972 48.8076L30.1857 50.9949L35.0893 52.5637C39.8355 50.8534 44.0482 47.9552 47.3129 44.1542C50.5776 40.3531 52.7819 35.7801 53.7091 30.885C54.6362 25.9899 54.2541 20.9413 52.6005 16.2358C50.9469 11.5303 48.0786 7.33 44.2779 4.04806V4.03013Z" fill="#969696"/>
    <path d="M13.5461 49.1213C13.4733 49.2379 13.4096 49.3454 13.3369 49.4441C12.8184 50.1701 12.0308 50.666 11.1443 50.8246C10.7213 50.9048 10.2922 50.9498 9.86155 50.959C9.43244 50.9426 9.00592 50.8856 8.58788 50.7887C8.1532 50.6954 7.72479 50.5756 7.30511 50.4301C6.88501 50.2792 6.47742 50.0964 6.08603 49.8833C5.54017 49.5875 5.0125 49.2558 4.47574 48.9331C4.47574 48.9331 4.47574 48.9331 4.38477 48.8614C4.48334 48.7724 4.58658 48.6886 4.69409 48.6104C5.37556 48.1302 6.17837 47.8448 7.01399 47.7856C8.02051 47.7302 9.02932 47.8423 9.99802 48.1173C11.1443 48.4131 12.2906 48.7538 13.4278 49.0765L13.5461 49.1213Z" fill="#CCCCCC"/>
    <path d="M9.91607 51.0756H9.86149C9.42597 51.0574 8.99329 50.9973 8.56962 50.8963C8.12722 50.81 7.69226 50.6902 7.26865 50.5377C6.83951 50.3838 6.42285 50.198 6.02228 49.9819C5.64017 49.7757 5.25807 49.5427 4.88507 49.3185L4.41199 49.0317H4.34831C4.34177 49.0176 4.33838 49.0023 4.33838 48.9869C4.33838 48.9714 4.34177 48.9561 4.34831 48.942C4.34098 48.9126 4.34098 48.8818 4.34831 48.8524L4.44838 48.7717L4.66673 48.5924C5.3669 48.1011 6.19247 47.8124 7.05031 47.7587C8.06918 47.689 9.09245 47.8013 10.0707 48.0904C10.8804 48.2966 11.6992 48.5297 12.4816 48.7538L13.5096 49.0496H13.5824H13.637C13.6651 49.0667 13.6873 49.0917 13.7007 49.1213C13.7075 49.1369 13.7109 49.1537 13.7109 49.1706C13.7109 49.1876 13.7075 49.2044 13.7007 49.2199L13.637 49.3275L13.4914 49.5427C13.2259 49.9158 12.8884 50.2337 12.4982 50.4783C12.1079 50.7229 11.6726 50.8893 11.217 50.968C10.7878 51.0445 10.3522 51.0805 9.91607 51.0756ZM4.56665 48.8614L5.01244 49.1213C5.37634 49.3454 5.75844 49.5785 6.14055 49.7847C6.53004 49.9928 6.93445 50.1726 7.35053 50.3226C7.76499 50.4626 8.1872 50.5794 8.61511 50.6722C9.02462 50.7653 9.44172 50.8223 9.86149 50.8425H9.91607C10.3227 50.8342 10.7277 50.7892 11.1261 50.708C11.9687 50.5427 12.7146 50.0645 13.2094 49.3723C13.2549 49.3185 13.2913 49.2468 13.3368 49.1841L12.336 48.8972C11.5537 48.6731 10.7349 48.44 9.93427 48.2339C8.99006 47.9614 8.00507 47.8522 7.02302 47.9111C6.20779 47.9666 5.4239 48.2427 4.7577 48.709C4.68997 48.7546 4.62608 48.8056 4.56665 48.8614Z" fill="#969696"/>
    <path d="M3.09279 8.59299C2.68676 8.11308 2.41126 7.5393 2.29219 6.9256C2.11983 6.06108 2.19867 5.16617 2.51963 4.34384C2.75136 3.71349 3.05666 3.11183 3.4294 2.55095C3.79898 2.00342 4.22587 1.49564 4.70307 1.03596C5.12156 0.623595 5.56735 0.256052 6.00403 -0.138383C6.04669 -0.172252 6.09236 -0.202255 6.1405 -0.228027C6.27765 0.0539246 6.36385 0.357284 6.39523 0.668418C6.45606 1.22432 6.43461 1.78598 6.33155 2.33581C6.20536 2.97465 5.99749 3.59516 5.71291 4.18248C4.96052 5.67577 4.10009 7.11379 3.13827 8.48542L3.09279 8.59299Z" fill="#CCCCCC"/>
    <path d="M3.09283 8.70061C3.07995 8.7063 3.066 8.70924 3.05189 8.70924C3.03778 8.70924 3.02383 8.7063 3.01095 8.70061C2.57672 8.21359 2.27884 7.62345 2.14667 6.9884C1.96258 6.08813 2.04468 5.1547 2.38321 4.29906C2.63006 3.66698 2.95063 3.06532 3.33847 2.50617C3.71188 1.95343 4.14171 1.43978 4.62123 0.973251C4.93056 0.677425 5.24897 0.399526 5.531 0.121628L5.904 -0.201092L6.00408 -0.272807H6.04957C6.06538 -0.279465 6.0824 -0.282898 6.0996 -0.282898C6.1168 -0.282898 6.13382 -0.279465 6.14964 -0.272807C6.17976 -0.259587 6.20513 -0.237716 6.22242 -0.210056C6.37215 0.0873716 6.46468 0.409547 6.49535 0.740175C6.5556 1.3054 6.53107 1.87628 6.42257 2.43446C6.30193 3.0845 6.09068 3.71498 5.79483 4.30803C5.14628 5.62366 4.40133 6.891 3.56591 8.09999L3.2202 8.62889L3.202 8.64682C3.19131 8.66548 3.17521 8.68057 3.15576 8.69015C3.13631 8.69973 3.11439 8.70337 3.09283 8.70061ZM6.09506 -0.021803L5.71295 0.309882C5.40363 0.578815 5.08522 0.856713 4.80319 1.14358C4.33506 1.60008 3.91447 2.1016 3.54771 2.64064C3.18035 3.18236 2.87522 3.76264 2.63795 4.37078C2.32556 5.17648 2.24988 6.05262 2.4196 6.89875C2.53135 7.44647 2.77469 7.95983 3.12922 8.39581L3.42944 7.92966C4.25985 6.72859 5.0017 5.47033 5.64927 4.16459C5.92928 3.59505 6.13413 2.99252 6.25881 2.37171C6.3628 1.83719 6.38731 1.29061 6.33159 0.74914C6.29569 0.481222 6.2158 0.220841 6.09506 -0.021803Z" fill="#969696"/>
    <path d="M5.23096 6.48644C5.80256 4.90226 6.83445 3.51874 8.19814 2.50815C9.56183 1.49757 11.1971 0.904567 12.9003 0.802979C12.2457 2.31521 11.2428 3.65646 9.97084 4.72044C8.6512 5.84069 6.97266 6.46608 5.23096 6.48644Z" fill="#CCCCCC"/>
    <path d="M5.23085 6.59401C5.21288 6.59631 5.19462 6.59328 5.17841 6.58529C5.1622 6.57731 5.14878 6.56473 5.13987 6.54918C5.13109 6.53419 5.12646 6.51719 5.12646 6.49988C5.12646 6.48257 5.13109 6.46556 5.13987 6.45057C5.72089 4.84714 6.76683 3.44706 8.14797 2.42398C9.5291 1.40091 11.1847 0.799862 12.9093 0.695398C12.9283 0.695031 12.9471 0.698866 12.9645 0.706623C12.9818 0.714379 12.9971 0.725859 13.0093 0.74022C13.0207 0.757592 13.0267 0.777822 13.0267 0.798489C13.0267 0.819155 13.0207 0.839385 13.0093 0.856758C12.3561 2.38703 11.3426 3.74214 10.0526 4.81008C8.71409 5.9498 7.00888 6.58505 5.23995 6.60297L5.23085 6.59401ZM12.7091 0.937438C11.1062 1.07144 9.57385 1.64681 8.28692 2.59787C6.99999 3.54894 6.01036 4.83737 5.431 6.31611C7.08665 6.26693 8.67425 5.65576 9.92524 4.58597C11.1207 3.59238 12.0725 2.34504 12.7091 0.937438Z" fill="#969696"/>
    <path d="M-0.173096 43.7696C0.162455 43.5597 0.536413 43.4166 0.92772 43.3482C1.9117 43.1604 2.92995 43.2601 3.85717 43.6351C4.95674 44.0735 6.01598 44.6044 7.02315 45.2218C7.45074 45.4728 7.87833 45.7328 8.34231 46.0555C8.28278 46.0796 8.22751 46.1129 8.17855 46.1541C7.57215 46.7514 6.76071 47.1032 5.90414 47.1402C4.86293 47.1765 3.83412 46.9082 2.9474 46.3693C1.80535 45.7306 0.786079 44.8992 -0.063924 43.913L-0.173096 43.7696Z" fill="#CCCCCC"/>
    <path d="M5.63096 47.2567C4.6662 47.2296 3.72537 46.9545 2.90166 46.4589C1.74887 45.8131 0.720329 44.9726 -0.136954 43.9757L-0.209736 43.8861C-0.21772 43.8725 -0.221924 43.857 -0.221924 43.8413C-0.221924 43.8255 -0.21772 43.8101 -0.209736 43.7965C-0.202357 43.7638 -0.182894 43.735 -0.155149 43.7158C0.194968 43.505 0.579858 43.3564 0.982057 43.2765C1.98774 43.0863 3.02787 43.1891 3.97519 43.5723C5.08436 44.0088 6.15283 44.5398 7.16846 45.159C7.54147 45.3832 7.98725 45.6521 8.49672 45.9927C8.51129 46.0048 8.52294 46.0199 8.53081 46.037C8.53869 46.054 8.54258 46.0726 8.54221 46.0914C8.54151 46.1108 8.53621 46.1299 8.52674 46.147C8.51726 46.1641 8.50387 46.1788 8.48762 46.19H8.43304L8.34206 46.2437C7.69923 46.8713 6.83589 47.2308 5.93118 47.2478L5.63096 47.2567ZM-0.000489891 43.7965C0.837668 44.7755 1.84479 45.601 2.97444 46.2348C3.85884 46.7799 4.88867 47.0516 5.93118 47.0147C6.76034 46.9826 7.54663 46.6432 8.13281 46.0645C7.69613 45.7686 7.29583 45.5356 6.96831 45.3473C5.96606 44.7293 4.90952 44.2013 3.81143 43.7696C2.91303 43.3849 1.92035 43.2665 0.954764 43.4289C0.620336 43.5064 0.298725 43.6301 -0.000489891 43.7965Z" fill="#969696"/>
    <path d="M-3.42114 37.7813C-3.12092 37.7365 -2.85709 37.6917 -2.58416 37.6737C-2.01534 37.6209 -1.44187 37.7007 -0.91019 37.9068C-0.568234 38.0304 -0.236665 38.1803 0.0814545 38.355C0.923775 38.8313 1.69784 39.4161 2.38316 40.0941C2.94722 40.6201 3.51127 41.1579 4.07533 41.7077L4.22089 41.8512C3.74012 42.2074 3.16513 42.4191 2.56511 42.4608C1.98962 42.4913 1.41375 42.4079 0.871525 42.2154C0.329304 42.023 -0.168263 41.7254 -0.591772 41.3402C-0.999968 40.9874 -1.38869 40.6134 -1.75627 40.2196C-2.37978 39.5246 -2.91447 38.7569 -3.34836 37.9337L-3.42114 37.7813Z" fill="#CCCCCC"/>
    <path d="M2.29242 42.5862C1.20402 42.5597 0.161386 42.1494 -0.64612 41.4298C-1.04206 41.0889 -1.41866 40.7268 -1.77423 40.3451V40.3003C-2.40932 39.5985 -2.95329 38.8216 -3.39361 37.9875C-3.39141 37.9576 -3.39141 37.9277 -3.39361 37.8978V37.8261C-3.40688 37.7914 -3.40688 37.7532 -3.39361 37.7185C-3.38582 37.7034 -3.37419 37.6905 -3.35984 37.681C-3.3455 37.6716 -3.32893 37.666 -3.31173 37.6647H-3.02971L-2.46565 37.593C-1.87819 37.5395 -1.28618 37.6224 -0.737096 37.8351C-0.391104 37.963 -0.0537989 38.1128 0.272743 38.2833C1.12196 38.7789 1.90185 39.3816 2.59264 40.0762L3.94819 41.376L4.28481 41.6898L4.38488 41.7974L4.43037 41.8511C4.43691 41.8652 4.4403 41.8805 4.4403 41.896C4.4403 41.9114 4.43691 41.9267 4.43037 41.9408C4.421 41.9726 4.40195 42.0007 4.37579 42.0215C3.87728 42.3857 3.28426 42.6032 2.66543 42.649L2.29242 42.5862ZM-3.24805 37.8709C-2.81901 38.6894 -2.28717 39.4513 -1.66506 40.1389V40.1838C-1.31025 40.5513 -0.937245 40.9278 -0.546046 41.2595C-0.135134 41.6339 0.348119 41.9229 0.874874 42.1093C1.40163 42.2957 1.96109 42.3755 2.51986 42.3442C3.05235 42.311 3.56533 42.1342 4.00278 41.8332H3.95729L3.62068 41.5105L2.29242 40.1748C1.61281 39.5068 0.848322 38.9283 0.0180087 38.4536C-0.29252 38.2951 -0.614914 38.1602 -0.946343 38.0502C-1.46404 37.8515 -2.02169 37.7748 -2.57482 37.8261C-2.75678 37.8261 -2.92963 37.8261 -3.12068 37.8889L-3.24805 37.8709Z" fill="#969696"/>
    <path d="M0.581853 14.5185C0.318811 14.3262 0.066727 14.1197 -0.173252 13.9C-0.473149 13.6153 -0.711444 13.2738 -0.873771 12.896C-1.15446 12.2985 -1.31511 11.6531 -1.34685 10.9955C-1.36904 9.98073 -1.20888 8.97011 -0.873771 8.01033C-0.59643 7.14959 -0.221034 6.32257 0.24524 5.5451L0.336217 5.40167L0.51817 5.64371C1.09251 6.433 1.4327 7.36415 1.50072 8.33305C1.56933 9.26362 1.50502 10.1991 1.30967 11.112C1.09132 12.1698 0.854783 13.2187 0.627342 14.2675C0.627342 14.3572 0.609146 14.4109 0.581853 14.5185Z" fill="#CCCCCC"/>
    <path d="M0.581963 14.635C0.558562 14.644 0.532582 14.644 0.509181 14.635L0.254447 14.4378C0.072403 14.3091 -0.09805 14.1651 -0.255022 14.0075C-0.562841 13.7031 -0.809744 13.3442 -0.982834 12.9497C-1.26793 12.338 -1.42865 11.6771 -1.45591 11.0044C-1.48244 9.97769 -1.32222 8.95455 -0.982834 7.98343C-0.696271 7.11467 -0.314975 6.27908 0.154372 5.49131V5.41959L0.199861 5.34788C0.210592 5.33133 0.225392 5.31772 0.242888 5.30832C0.260383 5.29892 0.280007 5.29402 0.299936 5.29409C0.317653 5.29373 0.335194 5.29762 0.351045 5.30543C0.366895 5.31323 0.380578 5.32473 0.390912 5.33891L0.572865 5.58095C1.16749 6.39891 1.51735 7.36481 1.5827 8.3689C1.6639 9.30076 1.61184 10.2392 1.42804 11.1568C1.28248 11.9009 1.11872 12.636 0.954966 13.3711L0.754818 14.3123C0.752633 14.3482 0.752633 14.3841 0.754818 14.4199V14.5364C0.749543 14.5544 0.740271 14.5709 0.72768 14.5849C0.715089 14.5988 0.699495 14.6099 0.682036 14.6171L0.581963 14.635ZM0.354521 5.59888C-0.107358 6.36793 -0.479686 7.18588 -0.755393 8.03721C-1.08717 8.98212 -1.24432 9.97799 -1.21937 10.9776C-1.19054 11.6211 -1.03932 12.2536 -0.773588 12.8422C-0.611944 13.216 -0.380491 13.5565 -0.0912638 13.8462C0.064373 13.9928 0.228399 14.1304 0.40001 14.2585L0.518279 14.3482C0.513983 14.3335 0.513983 14.318 0.518279 14.3034L0.718428 13.4069C0.882186 12.6718 1.04594 11.9278 1.19151 11.1927C1.39178 10.2683 1.45612 9.32031 1.38256 8.37786C1.33843 7.4156 1.02342 6.48442 0.472791 5.68853L0.354521 5.59888Z" fill="#969696"/>
    <path d="M-5.29517 31.291C-4.91207 31.2621 -4.52683 31.3016 -4.15796 31.4076C-3.0924 31.7009 -2.14128 32.3038 -1.42866 33.1377C-0.596058 34.1146 0.152536 35.158 0.80936 36.2573L1.25514 36.9655C1.08229 37.0193 0.936727 37.0821 0.782067 37.1179C0.179393 37.2607 -0.45225 37.2296 -1.03746 37.0283C-2.05218 36.6614 -2.91778 35.9785 -3.50293 35.083C-4.28328 33.9929 -4.87079 32.7805 -5.24058 31.4972V31.3896C-5.26666 31.3616 -5.2854 31.3278 -5.29517 31.291Z" fill="#CCCCCC"/>
    <path d="M0.0177913 37.3151C-0.364512 37.3201 -0.745219 37.2656 -1.11032 37.1538C-2.1479 36.7698 -3.03398 36.0694 -3.63947 35.1547C-4.424 34.0495 -5.01447 32.8222 -5.38622 31.5241V31.4076C-5.39535 31.3845 -5.39535 31.3589 -5.38622 31.3358C-5.38622 31.2821 -5.38622 31.2103 -5.28614 31.2103C-4.88135 31.1585 -4.47017 31.1859 -4.07615 31.291C-3.00334 31.603 -2.05172 32.2281 -1.34686 33.0839C-0.4954 34.0556 0.271512 35.0961 0.945753 36.1946L1.20958 36.6249L1.39154 36.9028C1.39829 36.9184 1.40178 36.9351 1.40178 36.9521C1.40178 36.969 1.39829 36.9858 1.39154 37.0014C1.38714 37.0187 1.37804 37.0345 1.36522 37.0472C1.3524 37.0598 1.33634 37.0688 1.31876 37.0731L1.155 37.1269C1.0518 37.1707 0.94535 37.2067 0.83658 37.2344C0.568026 37.2942 0.293043 37.3213 0.0177913 37.3151ZM-5.16787 31.3986C-4.8083 32.6808 -4.22993 33.8934 -3.45751 34.9844C-2.88301 35.8596 -2.03762 36.5291 -1.04663 36.8938C-0.462786 37.1019 0.170894 37.1331 0.772897 36.9834L1.07312 36.8848L0.973045 36.7235L0.709213 36.3021C0.0403357 35.2153 -0.717275 34.184 -1.5561 33.2184C-2.23978 32.4042 -3.15568 31.8108 -4.18532 31.5151C-4.50418 31.4236 -4.83609 31.3843 -5.16787 31.3986Z" fill="#969696"/>
    <path d="M14.5105 44.1371H14.5833C15.7597 44.928 16.8756 45.8029 17.9221 46.7547C18.6851 47.4505 19.2763 48.3091 19.6507 49.2648C19.8188 49.6944 19.9112 50.1492 19.9236 50.6094C19.9236 50.6094 19.9236 50.6991 19.9236 50.7618L19.6416 50.6901C18.3467 50.3312 17.1375 49.7217 16.0844 48.8972C15.4213 48.4051 14.893 47.7582 14.5469 47.0147C14.1867 46.1592 14.1512 45.2045 14.4468 44.3254C14.465 44.2357 14.4923 44.1909 14.5105 44.1371Z" fill="#CCCCCC"/>
    <path d="M19.9145 50.9142H19.8144H19.6234C18.3022 50.5589 17.0654 49.9496 15.9843 49.1213C15.3004 48.61 14.7616 47.9339 14.4195 47.1581C14.0359 46.2607 14.0003 45.2558 14.3194 44.3343C14.3306 44.294 14.3458 44.255 14.3649 44.2177C14.3704 44.2034 14.3789 44.1904 14.3899 44.1796C14.4009 44.1688 14.4141 44.1604 14.4286 44.155C14.4444 44.1483 14.4614 44.1449 14.4786 44.1449C14.4958 44.1449 14.5129 44.1483 14.5287 44.155H14.5924C15.7756 44.9438 16.895 45.822 17.9403 46.7816C18.7207 47.4877 19.3248 48.3621 19.7052 49.3364C19.8712 49.7791 19.9633 50.2453 19.9782 50.717C19.9826 50.7527 19.9826 50.7888 19.9782 50.8245V50.8783C19.9894 50.9072 19.9894 50.9391 19.9782 50.968L19.9145 50.9142ZM14.5651 44.3253C14.2547 45.2017 14.2871 46.1606 14.656 47.0146C14.9821 47.7549 15.4959 48.4001 16.1481 48.8882C17.1859 49.7101 18.3798 50.3196 19.6597 50.6811H19.8053C19.8082 50.2263 19.7313 49.7744 19.5779 49.3454C19.2132 48.4044 18.6306 47.5603 17.8766 46.8802C16.8434 45.9433 15.736 45.0889 14.5651 44.3253Z" fill="#969696"/>
    <path d="M10.4802 41.4119C11.6086 42.6268 12.5552 43.9942 13.2914 45.4728C13.7436 46.4163 13.8988 47.4716 13.7371 48.5028C13.7371 48.6462 13.6735 48.7896 13.6462 48.942H13.5643C12.3105 48.2333 11.2517 47.2331 10.4802 46.0286C10.0076 45.3179 9.76052 44.4847 9.77056 43.6351C9.77972 42.8772 10.0069 42.1374 10.4256 41.5015L10.4802 41.4119Z" fill="#CCCCCC"/>
    <path d="M13.628 49.0585C13.5888 49.0646 13.5489 49.0646 13.5097 49.0585C12.2221 48.3538 11.1338 47.3431 10.3437 46.1182C9.85397 45.3911 9.60014 44.5345 9.61593 43.6619C9.62497 42.8818 9.85842 42.1203 10.2892 41.4657L10.3346 41.4029C10.345 41.3887 10.3587 41.3772 10.3745 41.3694C10.3904 41.3616 10.4079 41.3577 10.4256 41.3581C10.44 41.3522 10.4555 41.3491 10.4711 41.3491C10.4867 41.3491 10.5022 41.3522 10.5166 41.3581C11.648 42.5844 12.6003 43.9603 13.346 45.4459C13.8025 46.4136 13.9607 47.4921 13.8008 48.5476C13.8008 48.6462 13.8008 48.7448 13.7372 48.8434V48.9868C13.7429 48.9995 13.7459 49.0133 13.7459 49.0272C13.7459 49.0411 13.7429 49.0548 13.7372 49.0675L13.628 49.0585ZM10.4802 41.636C10.0848 42.2472 9.87053 42.9551 9.86157 43.6799C9.84897 44.5048 10.09 45.3142 10.553 46.0017C11.3061 47.1614 12.3331 48.1241 13.5461 48.8075C13.5461 48.7089 13.5916 48.6193 13.6007 48.5296C13.7848 47.5098 13.6483 46.4589 13.2095 45.5176C12.4903 44.1032 11.5714 42.7964 10.4802 41.636Z" fill="#969696"/>
    <path d="M11.1896 8.10001L9.88868 8.63788C9.07691 8.97774 8.23678 9.24768 7.37773 9.44468C6.08235 9.77472 4.70729 9.59128 3.54762 8.9337L3.2019 8.71856L3.42935 8.51237C4.42322 7.62177 5.62724 6.99006 6.93194 6.67466C7.5005 6.51777 8.09729 6.48705 8.67935 6.58471C9.2614 6.68236 9.81425 6.90597 10.2981 7.23942C10.6407 7.47669 10.9418 7.76736 11.1896 8.10001Z" fill="#CCCCCC"/>
    <path d="M6.04054 9.71361C5.1414 9.71955 4.25761 9.48402 3.48409 9.03231L3.27485 8.89784L3.20207 8.81716C3.19553 8.8031 3.19214 8.78781 3.19214 8.77234C3.19214 8.75687 3.19553 8.74158 3.20207 8.72752C3.19553 8.71346 3.19214 8.69817 3.19214 8.6827C3.19214 8.66723 3.19553 8.65194 3.20207 8.63788L3.28395 8.56616L3.42951 8.42273C4.43337 7.51316 5.64865 6.86054 6.96849 6.52227C7.55301 6.36032 8.16691 6.32941 8.76514 6.43179C9.36337 6.53417 9.93074 6.76725 10.4256 7.11392C10.7815 7.35592 11.0898 7.65967 11.3354 8.01036C11.3433 8.02561 11.3475 8.04252 11.3475 8.05967C11.3475 8.07682 11.3433 8.09373 11.3354 8.10897C11.3249 8.14266 11.3022 8.17138 11.2717 8.18965L10.8168 8.37791L9.97072 8.72752C9.15139 9.06623 8.30558 9.33905 7.44157 9.54329C6.98208 9.64922 6.51238 9.70632 6.04054 9.71361ZM3.41131 8.70063L3.60236 8.83509C4.73886 9.47438 6.08371 9.65129 7.3506 9.32814C8.20509 9.13989 9.03969 8.87276 9.84335 8.5303L10.6894 8.18069L11.0079 8.04622C10.7863 7.761 10.5226 7.51018 10.2255 7.30217C9.75805 6.97443 9.2219 6.75434 8.6566 6.65814C8.0913 6.56194 7.51129 6.59209 6.9594 6.74638C5.67654 7.079 4.49585 7.7161 3.52049 8.60202L3.41131 8.70063Z" fill="#969696"/>
    <path d="M7.30497 37.862L7.79624 38.866C8.29226 39.8973 8.67061 40.9797 8.92435 42.0932C9.18754 43.2489 9.0729 44.457 8.59684 45.5445C8.59684 45.6252 8.51496 45.7059 8.46947 45.7866L8.42398 45.8404L8.3512 45.7866C7.37915 44.8427 6.64938 43.6842 6.22235 42.407C5.9969 41.7841 5.92146 41.1181 6.00199 40.4616C6.08251 39.8051 6.31679 39.176 6.68633 38.624C6.84099 38.355 7.08663 38.1309 7.30497 37.862Z" fill="#CCCCCC"/>
    <path d="M8.41475 45.9479H8.36926L8.26919 45.8852C7.28475 44.9215 6.54588 43.7419 6.11304 42.4428C5.87947 41.8038 5.80141 41.1196 5.88516 40.4454C5.96891 39.7713 6.21212 39.1259 6.59522 38.5612C6.71857 38.3782 6.8554 38.2045 7.00461 38.0413L7.21386 37.7903C7.23072 37.7816 7.24944 37.7771 7.26845 37.7771C7.28745 37.7771 7.30617 37.7816 7.32303 37.7903C7.34251 37.7917 7.36122 37.7983 7.37722 37.8094C7.39322 37.8204 7.40593 37.8355 7.41401 37.853L7.59596 38.2295L7.90528 38.857C8.40598 39.8967 8.78741 40.9881 9.04249 42.1111C9.29133 43.2812 9.15756 44.4988 8.66039 45.5893C8.63076 45.6598 8.59735 45.7286 8.56031 45.7955C8.56031 45.7955 8.56031 45.8403 8.51482 45.8493C8.50803 45.8728 8.49528 45.8941 8.47777 45.9114C8.46026 45.9286 8.43857 45.9412 8.41475 45.9479ZM7.27754 38.0502C7.24944 38.0907 7.21907 38.1296 7.18657 38.1668C7.04273 38.3226 6.90903 38.4873 6.78627 38.6598C6.42432 39.1966 6.19541 39.8097 6.11798 40.4497C6.04055 41.0896 6.11676 41.7386 6.34049 42.3442C6.75082 43.5889 7.45554 44.7196 8.39655 45.6431C8.43225 45.5893 8.46271 45.5323 8.48753 45.4728C8.95115 44.4073 9.06565 43.2258 8.81505 42.0932C8.55861 41.0064 8.18025 39.951 7.68694 38.9467C7.59596 38.7405 7.49589 38.5343 7.38672 38.3192L7.27754 38.0502Z" fill="#969696"/>
    <path d="M4.33923 41.7974C4.09359 41.2864 3.85705 40.8203 3.64781 40.3451C3.53553 40.1062 3.44717 39.857 3.38397 39.6011C3.25298 39.1232 3.14971 38.6383 3.07465 38.1488C2.92831 37.333 3.02984 36.4927 3.36651 35.7336C3.70318 34.9744 4.25997 34.3302 4.96697 33.8818L5.10343 33.8011C5.20351 34.2403 5.30358 34.6975 5.39456 35.1099C5.61175 36.1266 5.71547 37.1637 5.70388 38.2026C5.68925 39.3876 5.29788 40.5382 4.58486 41.4926C4.51208 41.5822 4.4393 41.636 4.33923 41.7974Z" fill="#CCCCCC"/>
    <path d="M4.33913 41.9139C4.32014 41.9109 4.30209 41.9037 4.28634 41.8928C4.27058 41.882 4.25753 41.8677 4.24815 41.8512L4.02981 41.394L3.55673 40.39C3.44322 40.1453 3.35484 39.89 3.2929 39.628L3.20192 39.4308C3.09036 39.0146 3.00228 38.5926 2.93809 38.1668C2.78949 37.3284 2.89491 36.4655 3.24117 35.6859C3.58743 34.9063 4.15919 34.2444 4.88499 33.7832L4.96687 33.7294H5.02146C5.05664 33.7163 5.09545 33.7163 5.13063 33.7294C5.14771 33.7358 5.16268 33.7467 5.1739 33.761C5.18513 33.7752 5.19219 33.7922 5.19431 33.8101L5.29438 34.2224C5.35807 34.5272 5.43085 34.823 5.49453 35.1189C5.71657 36.1439 5.82034 37.1905 5.80385 38.2385C5.78543 39.4464 5.38468 40.6185 4.65755 41.5912C4.60664 41.6656 4.55198 41.7374 4.49379 41.8063L4.41191 41.905C4.38943 41.9161 4.36371 41.9193 4.33913 41.9139ZM5.02146 33.9804C4.3393 34.4231 3.80354 35.0528 3.48022 35.7919C3.1569 36.5311 3.06016 37.3472 3.20192 38.1399C3.25964 38.5572 3.34473 38.9705 3.45666 39.377L3.51124 39.5742C3.57024 39.8243 3.65562 40.0677 3.76598 40.3003C3.91154 40.632 4.0662 40.9547 4.23906 41.2954L4.37552 41.5822L4.49379 41.4209C5.19652 40.4862 5.58177 39.3568 5.59461 38.1937C5.61049 37.1635 5.50671 36.1348 5.28529 35.1278C5.2216 34.832 5.15792 34.5362 5.08514 34.2314L5.02146 33.9804Z" fill="#969696"/>
    <path d="M7.65989 11.5424C7.33237 11.8471 7.02305 12.1699 6.68644 12.4388C6.12238 12.9049 5.56743 13.3352 4.97608 13.7835C4.04953 14.4647 2.90986 14.8041 1.75551 14.7427C1.42799 14.7427 1.10048 14.6441 0.736572 14.5992C0.736572 14.5185 0.78206 14.4737 0.800256 14.4379C0.982209 14.142 1.16416 13.8372 1.35521 13.5414C1.54987 13.2537 1.76256 12.9783 1.99205 12.7167C2.41091 12.2123 2.90837 11.7765 3.46587 11.4258C4.05197 11.0255 4.74594 10.8065 5.45902 10.797C6.1721 10.7874 6.87187 10.9876 7.46884 11.372C7.52079 11.3997 7.56959 11.4327 7.6144 11.4706C7.6144 11.4706 7.63259 11.5155 7.65989 11.5424Z" fill="#CCCCCC"/>
    <path d="M2.07389 14.8771H1.74637C1.51696 14.8643 1.28887 14.8343 1.06405 14.7875L0.727437 14.7427C0.708681 14.743 0.690206 14.7381 0.67412 14.7286C0.658034 14.7191 0.644985 14.7053 0.636461 14.6889C0.629704 14.6733 0.626221 14.6565 0.626221 14.6396C0.626221 14.6226 0.629704 14.6058 0.636461 14.5903V14.5185L0.681949 14.411L0.818414 14.1958C0.954879 13.9717 1.09134 13.7386 1.246 13.5235C1.44128 13.2305 1.65393 12.9491 1.88284 12.6808C2.31231 12.1715 2.81858 11.73 3.38395 11.372C3.98956 10.9609 4.70546 10.7363 5.44082 10.7267C6.17619 10.7171 6.89787 10.923 7.51429 11.3182C7.5695 11.3487 7.62133 11.3847 7.66895 11.4258C7.69146 11.4389 7.71024 11.4574 7.72353 11.4796C7.72353 11.5334 7.72353 11.6141 7.72353 11.6499L7.42331 11.9457C7.20497 12.1519 6.98662 12.3671 6.75008 12.5643C6.21332 13.0125 5.64927 13.4607 5.07612 13.8731C4.20982 14.515 3.15775 14.8668 2.07389 14.8771ZM0.900293 14.5096H1.10044C1.32788 14.5096 1.54623 14.5813 1.76457 14.5992C2.89365 14.6611 4.00858 14.3277 4.91236 13.658C5.47641 13.2635 6.03137 12.7615 6.56813 12.3581C6.79557 12.1699 7.02301 11.9547 7.23226 11.7485L7.4506 11.5423L7.36872 11.4886C6.79256 11.1114 6.11413 10.9147 5.42247 10.9243C4.73082 10.9339 4.05828 11.1493 3.49312 11.5423C2.94982 11.8904 2.46228 12.3164 2.0466 12.8063C1.81916 13.0663 1.60991 13.3532 1.41886 13.6221C1.22781 13.891 1.13683 14.0703 1.00037 14.2855L0.863903 14.5096H0.900293Z" fill="#969696"/>
    <path d="M5.32185 15.5674C5.15809 15.8184 5.00343 16.0604 4.83967 16.2935C4.26907 17.1761 3.59751 17.9912 2.83819 18.7229C1.94297 19.602 0.735807 20.1035 -0.527946 20.1213H-0.74629C-0.750911 20.0916 -0.750911 20.0614 -0.74629 20.0317C-0.385018 18.61 0.371056 17.3155 1.43715 16.2935C1.91943 15.8187 2.5328 15.4941 3.20089 15.3602C3.86898 15.2263 4.56228 15.289 5.19448 15.5405H5.25816C5.25816 15.5405 5.28546 15.5405 5.32185 15.5674Z" fill="#CCCCCC"/>
    <path d="M-0.536863 20.202H-0.755207C-0.784972 20.1997 -0.812766 20.1865 -0.833015 20.1649C-0.853263 20.1432 -0.864467 20.1149 -0.864379 20.0855C-0.873658 20.0472 -0.873658 20.0072 -0.864379 19.9689C-0.488638 18.5181 0.289426 17.1989 1.38274 16.159C1.88084 15.6702 2.51382 15.3365 3.20291 15.1994C3.89201 15.0623 4.6068 15.128 5.25834 15.3881H5.34022C5.36797 15.4074 5.38743 15.4361 5.39481 15.4688C5.40135 15.4828 5.40474 15.4981 5.40474 15.5136C5.40474 15.5291 5.40135 15.5444 5.39481 15.5584L5.24924 15.7825L4.91263 16.2845C4.34178 17.1774 3.66365 17.9991 2.89295 18.7318C1.97044 19.6199 0.734583 20.1211 -0.555059 20.1303L-0.536863 20.202ZM-0.618742 19.9689H-0.527766C0.701914 19.963 1.88052 19.4836 2.75649 18.6332C3.51238 17.9131 4.17821 17.1066 4.73978 16.2308C4.85805 16.0694 4.96722 15.8991 5.07639 15.7287L5.15827 15.6122C4.54588 15.3706 3.87519 15.3106 3.22866 15.4396C2.58213 15.5686 1.98796 15.881 1.51921 16.3383C0.487965 17.3369 -0.250655 18.5912 -0.618742 19.9689Z" fill="#969696"/>
    <path d="M3.775 29.3278C3.775 29.4981 3.775 29.6505 3.775 29.8029C3.84291 31.136 3.72661 32.472 3.42929 33.7742C3.16058 34.9246 2.52248 35.9589 1.60976 36.7235L1.39141 36.8759C1.39141 36.7145 1.30043 36.5801 1.25495 36.4366C0.936674 35.324 0.825709 34.1638 0.927431 33.0122C0.972154 32.2082 1.25429 31.4345 1.73916 30.7864C2.22404 30.1382 2.89053 29.6437 3.65673 29.3637L3.775 29.3278Z" fill="#CCCCCC"/>
    <path d="M1.38274 36.9924C1.36515 36.9881 1.34909 36.9791 1.33628 36.9665C1.32346 36.9539 1.31436 36.938 1.30996 36.9207L1.26447 36.7773C1.26447 36.6697 1.20079 36.5711 1.1735 36.4725C0.843822 35.3497 0.729664 34.1762 0.836881 33.0122C0.881141 32.1899 1.16696 31.3979 1.65958 30.7326C2.1522 30.0674 2.83039 29.5575 3.61167 29.2651H3.71174H3.77542C3.80758 29.2526 3.84334 29.2526 3.8755 29.2651C3.8867 29.2939 3.8867 29.3259 3.8755 29.3547V29.5161C3.8755 29.6236 3.8755 29.7312 3.8755 29.8388C3.94499 31.1808 3.82869 32.5259 3.52979 33.8369C3.24994 35.0177 2.58529 36.0757 1.63747 36.849L1.49191 36.9566H1.41913C1.41038 36.9714 1.39784 36.9838 1.38274 36.9924ZM3.65715 29.4802C2.91112 29.758 2.26472 30.2463 1.79872 30.8841C1.33272 31.5218 1.06779 32.2807 1.03703 33.066C0.94231 34.1891 1.05952 35.3198 1.38274 36.4008L1.46462 36.6876L1.5465 36.6338C2.45363 35.8907 3.09201 34.8779 3.36603 33.7473C3.66186 32.4571 3.77514 31.1327 3.70264 29.8119C3.70264 29.7043 3.70264 29.5968 3.70264 29.4892L3.65715 29.4802Z" fill="#969696"/>
    <path d="M3.37482 24.6752C3.20199 26.0052 2.8628 27.309 2.36498 28.5569C2.08637 29.2213 1.68539 29.8291 1.18229 30.3497C0.762305 30.7836 0.266058 31.139 -0.282434 31.3986H-0.373411H-0.437094C-0.437094 31.282 -0.437094 31.1655 -0.482582 31.049C-0.603784 29.7772 -0.439158 28.4948 -0.00040704 27.2929C0.217673 26.5955 0.636233 25.9755 1.20459 25.5077C1.77296 25.0399 2.46675 24.7446 3.20197 24.6573H3.29294L3.37482 24.6752Z" fill="#CCCCCC"/>
    <path d="M-0.436963 31.5689C-0.500647 31.5689 -0.582525 31.5241 -0.582525 31.4703V31.3627V31.1117C-0.712101 29.795 -0.544331 28.4664 -0.0912521 27.2212C0.138047 26.5023 0.575207 25.865 1.16665 25.3873C1.75809 24.9096 2.47812 24.6123 3.23849 24.5318H3.40225C3.45683 24.5318 3.52052 24.6125 3.51142 24.6663C3.33434 26.008 2.99217 27.3235 2.49248 28.5838C2.20478 29.2476 1.79798 29.855 1.29159 30.3767C0.865283 30.8261 0.355787 31.1912 -0.209522 31.4524H-0.318694L-0.436963 31.5689ZM3.2021 24.7739C2.49574 24.8575 1.82864 25.1394 1.28025 25.586C0.731858 26.0326 0.32527 26.6251 0.108896 27.2929C-0.335299 28.5028 -0.496999 29.7959 -0.364182 31.0759V31.291C0.173339 31.0426 0.65805 30.6958 1.06415 30.2691C1.56071 29.7487 1.95275 29.1403 2.21955 28.4762C2.69711 27.2856 3.02702 26.0424 3.2021 24.7739Z" fill="#969696"/>
    <path d="M-1.18311 25.7869C-1.12852 25.4104 -1.08303 25.0428 -1.01935 24.6932C-0.855203 23.6882 -0.496955 22.7235 0.0359802 21.8515C0.386302 21.1988 0.932572 20.6685 1.60018 20.3332C2.26778 19.9979 3.02417 19.8738 3.76602 19.9779H3.8388H3.88429C3.8206 20.1393 3.76602 20.3096 3.70233 20.471C3.30223 21.6122 2.75817 22.6995 2.08295 23.7071C1.35791 24.7898 0.231097 25.5493 -1.05574 25.8227L-1.18311 25.7869Z" fill="#CCCCCC"/>
    <path d="M-1.18303 25.9034C-1.19591 25.9091 -1.20986 25.912 -1.22397 25.912C-1.23808 25.912 -1.25203 25.9091 -1.26491 25.9034C-1.27758 25.8717 -1.27758 25.8365 -1.26491 25.8048L-1.21032 25.4462C-1.21032 25.1952 -1.14664 24.9442 -1.10115 24.7111C-0.931776 23.6907 -0.567496 22.7112 -0.027629 21.8246C0.336975 21.1542 0.901328 20.6101 1.5894 20.2655C2.27747 19.9209 3.0562 19.7923 3.82068 19.8972H3.90256H3.96624C3.99147 19.9131 4.01112 19.9362 4.02251 19.9635C4.03391 19.9908 4.0365 20.0208 4.02992 20.0496L3.97534 20.193C3.97534 20.3096 3.89346 20.4351 3.84797 20.5516C3.43111 21.6688 2.87166 22.7291 2.1831 23.7071C1.43961 24.8125 0.287147 25.5878 -1.02837 25.8675H-1.18303V25.9034ZM3.26572 20.0586C2.62296 20.0621 1.99305 20.2363 1.4423 20.5629C0.891555 20.8894 0.440332 21.3563 0.136129 21.9142C-0.386238 22.7734 -0.73819 23.7225 -0.901004 24.7111C-0.946492 24.9531 -0.982883 25.1952 -1.01018 25.4462C-1.01529 25.5148 -1.01529 25.5837 -1.01018 25.6524C0.232426 25.3829 1.31932 24.6464 2.01934 23.5995C2.68491 22.6306 3.22294 21.5823 3.62053 20.4799C3.67512 20.3634 3.71151 20.2378 3.75699 20.1213L3.26572 20.0586Z" fill="#969696"/>
    <path d="M36.5266 49.1213C36.5994 49.2379 36.6631 49.3454 36.7359 49.4441C37.2543 50.1701 38.0419 50.666 38.9284 50.8246C39.3514 50.9048 39.7805 50.9498 40.2112 50.959C40.6403 50.9426 41.0668 50.8856 41.4848 50.7887C41.9195 50.6954 42.3479 50.5756 42.7676 50.4301C43.1877 50.2792 43.5953 50.0964 43.9867 49.8833C44.5325 49.5875 45.0602 49.2558 45.597 48.9331C45.597 48.9331 45.597 48.9331 45.6879 48.8614C45.5894 48.7724 45.4861 48.6886 45.3786 48.6104C44.6972 48.1302 43.8943 47.8448 43.0587 47.7856C42.0522 47.7302 41.0434 47.8423 40.0747 48.1173C38.9284 48.4131 37.7821 48.7538 36.6449 49.0765L36.5266 49.1213Z" fill="#CCCCCC"/>
    <path d="M40.1568 51.0755C39.7378 51.0653 39.3206 51.0173 38.9104 50.9321C38.4549 50.8534 38.0195 50.687 37.6293 50.4424C37.239 50.1978 36.9015 49.8799 36.636 49.5068L36.4904 49.2916L36.4267 49.184C36.42 49.1685 36.4165 49.1517 36.4165 49.1347C36.4165 49.1178 36.42 49.101 36.4267 49.0854C36.4402 49.0558 36.4624 49.0308 36.4904 49.0137H36.545H36.6178L37.6458 48.7179C38.4282 48.4938 39.247 48.2607 40.0567 48.0545C41.035 47.7654 42.0583 47.6531 43.0771 47.7228C43.935 47.7765 44.7605 48.0652 45.4607 48.5565L45.6791 48.7358L45.7791 48.8165C45.7865 48.846 45.7865 48.8767 45.7791 48.9062C45.7857 48.9202 45.7891 48.9355 45.7891 48.951C45.7891 48.9664 45.7857 48.9817 45.7791 48.9958H45.7154L45.2424 49.2827C44.8694 49.5068 44.4873 49.7398 44.1052 49.946C43.7046 50.1621 43.2879 50.3479 42.8588 50.5018C42.4352 50.6543 42.0002 50.7742 41.5578 50.8604C41.1341 50.9615 40.7015 51.0215 40.266 51.0397L40.1568 51.0755ZM36.6997 49.184C36.6997 49.2468 36.7816 49.3185 36.827 49.3723C37.3305 50.0722 38.0908 50.5513 38.9468 50.708C39.3635 50.7891 39.7867 50.8341 40.2114 50.8425C40.6311 50.8222 41.0482 50.7652 41.4577 50.6722C41.8857 50.5793 42.3079 50.4626 42.7223 50.3225C43.1384 50.1725 43.5428 49.9927 43.9323 49.7847C44.3144 49.5785 44.6965 49.3454 45.0604 49.1213L45.5062 48.8613C45.4471 48.8025 45.3832 48.7485 45.3152 48.7C44.648 48.2369 43.8642 47.9639 43.0498 47.9111C42.0552 47.8512 41.0576 47.9635 40.1022 48.2428C39.3016 48.449 38.4828 48.682 37.7004 48.9062L36.6997 49.184Z" fill="#969696"/>
    <path d="M46.98 8.59299C47.386 8.11308 47.6615 7.5393 47.7806 6.9256C47.953 6.06108 47.8741 5.16617 47.5532 4.34384C47.3214 3.71349 47.0161 3.11183 46.6434 2.55095C46.2738 2.00342 45.8469 1.49564 45.3697 1.03596C44.9512 0.623595 44.46 0.256052 44.0688 -0.138383C44.0261 -0.172252 43.9804 -0.202255 43.9323 -0.228027C43.7951 0.0539246 43.7089 0.357284 43.6776 0.668418C43.6167 1.22432 43.6382 1.78598 43.7412 2.33581C43.8674 2.97465 44.0753 3.59516 44.3599 4.18248C45.1123 5.67577 45.9727 7.11379 46.9345 8.48542L46.98 8.59299Z" fill="#CCCCCC"/>
    <path d="M46.9801 8.70062C46.9657 8.70654 46.9502 8.70959 46.9346 8.70959C46.919 8.70959 46.9035 8.70654 46.8891 8.70062V8.64684L46.5434 8.11793C45.708 6.90895 44.963 5.64161 44.3145 4.32597C44.0186 3.73293 43.8074 3.10244 43.6867 2.4524C43.5782 1.89422 43.5537 1.32334 43.6139 0.758119C43.6446 0.42749 43.7371 0.105316 43.8869 -0.192112C43.9042 -0.219772 43.9295 -0.241643 43.9596 -0.254863C43.9755 -0.261521 43.9925 -0.264954 44.0097 -0.264954C44.0269 -0.264954 44.0439 -0.261521 44.0597 -0.254863L44.1598 -0.183148L44.5328 0.139572C44.8421 0.41747 45.1605 0.695368 45.4426 0.991195C45.9242 1.45583 46.3542 1.9697 46.7253 2.52412C47.0974 3.08539 47.4026 3.68698 47.6351 4.31701C47.9736 5.17265 48.0557 6.10607 47.8716 7.00634C47.7395 7.6414 47.4416 8.23154 47.0074 8.71855C46.9947 8.72486 46.9806 8.72814 46.9664 8.72814C46.9522 8.72814 46.9382 8.72486 46.9255 8.71855L46.9801 8.70062ZM43.9778 -0.021788C43.8715 0.217337 43.804 0.471441 43.7777 0.731226C43.722 1.2727 43.7465 1.81928 43.8505 2.35379C43.9752 2.97461 44.18 3.57714 44.46 4.14668C45.1076 5.45242 45.8494 6.71068 46.6798 7.91175L46.9801 8.3779C47.3346 7.94192 47.5779 7.42856 47.6897 6.88084C47.8594 6.0347 47.7837 5.15857 47.4713 4.35286C47.2341 3.74472 46.9289 3.16445 46.5616 2.62272C46.1993 2.08038 45.7784 1.57841 45.3061 1.12566C45.015 0.8388 44.6966 0.560902 44.3963 0.291968L44.0142 -0.0397164L43.9778 -0.021788Z" fill="#969696"/>
    <path d="M44.8422 6.48644C44.2706 4.90226 43.2387 3.51874 41.875 2.50815C40.5113 1.49757 38.876 0.904567 37.1729 0.802979C37.8274 2.31521 38.8304 3.65646 40.1023 4.72044C41.4219 5.84069 43.1005 6.46608 44.8422 6.48644Z" fill="#CCCCCC"/>
    <path d="M44.842 6.594C43.073 6.57608 41.3678 5.94082 40.0293 4.80111C38.7394 3.73317 37.7259 2.37806 37.0726 0.847786C37.0612 0.830413 37.0552 0.810184 37.0552 0.789517C37.0552 0.76885 37.0612 0.74862 37.0726 0.731248C37.0848 0.716887 37.1001 0.705407 37.1175 0.69765C37.1348 0.689894 37.1536 0.686059 37.1727 0.686425C38.8973 0.79089 40.5528 1.39194 41.934 2.41501C43.3151 3.43808 44.361 4.83817 44.942 6.4416C44.9508 6.45659 44.9555 6.4736 44.9555 6.49091C44.9555 6.50822 44.9508 6.52522 44.942 6.54021C44.9341 6.55907 44.9198 6.57468 44.9016 6.58446C44.8834 6.59425 44.8624 6.59761 44.842 6.594ZM37.3637 0.937431C38.0023 2.36388 38.9639 3.62729 40.1749 4.63078C41.4259 5.70057 43.0135 6.31175 44.6691 6.36092C44.0937 4.87053 43.1026 3.57068 41.8101 2.61113C40.5176 1.65158 38.9763 1.0714 37.3637 0.937431Z" fill="#969696"/>
    <path d="M50.2461 43.7696C49.9106 43.5597 49.5366 43.4166 49.1453 43.3482C48.1613 43.1604 47.1431 43.2601 46.2159 43.6351C45.1163 44.0735 44.057 44.6044 43.0499 45.2218C42.6223 45.4728 42.1947 45.7328 41.7307 46.0555C41.7902 46.0796 41.8455 46.1129 41.8945 46.1541C42.5009 46.7514 43.3123 47.1032 44.1689 47.1402C45.2101 47.1765 46.2389 46.9082 47.1256 46.3693C48.2677 45.7306 49.2869 44.8992 50.1369 43.913L50.2461 43.7696Z" fill="#CCCCCC"/>
    <path d="M44.4416 47.2567H44.1414C43.2564 47.2192 42.4182 46.8542 41.7942 46.2348L41.7032 46.181H41.6486C41.6324 46.1699 41.619 46.1552 41.6095 46.138C41.6 46.1209 41.5947 46.1019 41.594 46.0824C41.5936 46.0636 41.5975 46.0451 41.6054 46.028C41.6133 46.0109 41.6249 45.9958 41.6395 45.9838C42.149 45.6431 42.5493 45.3742 42.9678 45.1501C43.9834 44.5308 45.0519 43.9999 46.161 43.5634C47.1084 43.1802 48.1485 43.0774 49.1542 43.2675C49.5564 43.3474 49.9413 43.496 50.2914 43.7068C50.3191 43.7261 50.3386 43.7548 50.346 43.7875C50.3539 43.8011 50.3581 43.8166 50.3581 43.8323C50.3581 43.848 50.3539 43.8635 50.346 43.8771L50.2732 43.9668C49.4159 44.9636 48.3874 45.8042 47.2346 46.4499C46.3932 46.9593 45.4289 47.2379 44.4416 47.2567ZM41.9397 46.0376C42.5216 46.6262 43.3084 46.9754 44.1414 47.0147C45.1595 47.0461 46.1648 46.7844 47.0344 46.2617C48.1641 45.6279 49.1712 44.8024 50.0093 43.8233C49.7229 43.6702 49.4169 43.5556 49.0996 43.4827C48.1393 43.3028 47.1466 43.4025 46.2429 43.7696C45.1448 44.2013 44.0883 44.7293 43.086 45.3473C42.7767 45.5087 42.3764 45.7417 41.9397 46.0376Z" fill="#969696"/>
    <path d="M53.4938 37.7813C53.1936 37.7365 52.9298 37.6917 52.6569 37.6737C52.088 37.6209 51.5146 37.7007 50.9829 37.9068C50.6409 38.0304 50.3094 38.1803 49.9912 38.355C49.1489 38.8313 48.3749 39.4161 47.6895 40.0941C47.1255 40.6201 46.5614 41.1579 45.9974 41.7077L45.8518 41.8512C46.3326 42.2074 46.9076 42.4191 47.5076 42.4608C48.0831 42.4913 48.6589 42.4079 49.2012 42.2154C49.7434 42.023 50.241 41.7254 50.6645 41.3402C51.0727 40.9874 51.4614 40.6134 51.829 40.2196C52.4525 39.5246 52.9872 38.7569 53.4211 37.9337L53.4938 37.7813Z" fill="#CCCCCC"/>
    <path d="M47.7804 42.5862H47.4802C46.8614 42.5405 46.2683 42.3229 45.7698 41.9587C45.7437 41.938 45.7246 41.9098 45.7153 41.878C45.7087 41.864 45.7053 41.8487 45.7053 41.8332C45.7053 41.8178 45.7087 41.8025 45.7153 41.7884L45.7607 41.7346L45.9609 41.636L46.2975 41.3222L47.6531 40.0224C48.3438 39.3278 49.1237 38.7251 49.973 38.2295C50.3011 38.0596 50.6382 37.907 50.9828 37.7723C51.5335 37.5662 52.1247 37.4865 52.7113 37.5393L53.2754 37.611H53.5574C53.5746 37.6122 53.5912 37.6178 53.6055 37.6273C53.6199 37.6367 53.6315 37.6496 53.6393 37.6648C53.6526 37.6994 53.6526 37.7377 53.6393 37.7723V37.844V37.9337C53.1968 38.7665 52.653 39.5432 52.0199 40.2465L51.9744 40.2913C51.6189 40.673 51.2423 41.0351 50.8463 41.376C50.0143 42.1437 48.9202 42.5756 47.7804 42.5862ZM46.0155 41.8332C46.4529 42.1342 46.9659 42.311 47.4984 42.3442C48.0572 42.3756 48.6166 42.2957 49.1434 42.1093C49.6701 41.9229 50.1534 41.6339 50.5643 41.2595C50.9555 40.9278 51.3285 40.5513 51.6833 40.1838V40.1389C52.3083 39.4536 52.8404 38.6912 53.2663 37.8709H53.1389C52.9479 37.8709 52.775 37.8171 52.5931 37.8082C52.0399 37.7524 51.4811 37.8293 50.9646 38.0323C50.6298 38.1555 50.3045 38.3023 49.9911 38.4716C49.1842 38.9451 48.4414 39.5174 47.7804 40.1748L46.4158 41.4657L46.0792 41.7884L46.0155 41.8332Z" fill="#969696"/>
    <path d="M49.4911 14.5185C49.7542 14.3262 50.0062 14.1197 50.2462 13.9C50.5461 13.6153 50.7844 13.2738 50.9467 12.896C51.2274 12.2985 51.3881 11.6531 51.4198 10.9955C51.442 9.98073 51.2819 8.97011 50.9467 8.01033C50.6694 7.14959 50.294 6.32257 49.8277 5.5451L49.7367 5.40167L49.5548 5.64371C48.9805 6.433 48.6403 7.36415 48.5723 8.33305C48.5036 9.26362 48.5679 10.1991 48.7633 11.112C48.9816 12.1698 49.2182 13.2187 49.4456 14.2675C49.4456 14.3572 49.4638 14.4109 49.4911 14.5185Z" fill="#CCCCCC"/>
    <path d="M49.4908 14.6351C49.4734 14.6278 49.4578 14.6168 49.4452 14.6029C49.4326 14.5889 49.4233 14.5724 49.4181 14.5544V14.4379C49.4202 14.4021 49.4202 14.3661 49.4181 14.3303L49.2179 13.389C49.0541 12.654 48.8904 11.9189 48.7448 11.1748C48.5432 10.2506 48.4758 9.30288 48.5447 8.35999C48.61 7.3559 48.9599 6.39 49.5545 5.57205L49.7365 5.33001C49.7365 5.33001 49.8001 5.27622 49.8274 5.28519C49.8474 5.28512 49.867 5.29001 49.8845 5.29942C49.902 5.30882 49.9168 5.32243 49.9275 5.33897V5.40172L49.9821 5.4824C50.4531 6.26935 50.8344 7.10512 51.1193 7.97452C51.4587 8.94564 51.6189 9.96879 51.5924 10.9955C51.5442 11.6752 51.3619 12.339 51.0556 12.9498C50.8806 13.3379 50.6338 13.6905 50.3278 13.9897C50.1708 14.1472 50.0004 14.2912 49.8183 14.42L49.5636 14.6172C49.5426 14.6322 49.5165 14.6387 49.4908 14.6351ZM49.7183 5.60791L49.6364 5.70652C49.0696 6.4976 48.7387 7.42915 48.6811 8.39585C48.6128 9.32048 48.6771 10.25 48.8722 11.1569C49.0177 11.892 49.1815 12.636 49.3453 13.3711L49.5454 14.2676C49.5497 14.2822 49.5497 14.2977 49.5454 14.3124L49.6637 14.2227C49.8353 14.0946 49.9993 13.957 50.155 13.8104C50.4405 13.5314 50.6717 13.2033 50.8373 12.8422C51.103 12.2537 51.2542 11.6212 51.2831 10.9776C51.308 9.97805 51.1509 8.98218 50.8191 8.03727C50.5434 7.18594 50.1711 6.36799 49.7092 5.59894L49.7183 5.60791Z" fill="#969696"/>
    <path d="M55.3679 31.291C54.9848 31.2621 54.5996 31.3016 54.2307 31.4076C53.1652 31.7009 52.2141 32.3038 51.5014 33.1377C50.6688 34.1146 49.9202 35.158 49.2634 36.2573L48.8176 36.9655C48.9905 37.0193 49.136 37.0821 49.2907 37.1179C49.8934 37.2607 50.525 37.2296 51.1102 37.0283C52.125 36.6614 52.9906 35.9785 53.5757 35.083C54.3561 33.9929 54.9436 32.7805 55.3134 31.4972V31.3896C55.3394 31.3616 55.3582 31.3278 55.3679 31.291Z" fill="#CCCCCC"/>
    <path d="M50.0549 37.3152C49.7881 37.3133 49.5224 37.2802 49.2634 37.2165C49.1547 37.1887 49.0482 37.1528 48.945 37.109L48.7813 37.0552C48.7637 37.0508 48.7476 37.0419 48.7348 37.0293C48.722 37.0166 48.7129 37.0008 48.7085 36.9835C48.7017 36.9679 48.6982 36.9511 48.6982 36.9342C48.6982 36.9172 48.7017 36.9004 48.7085 36.8849L48.8904 36.607L49.1543 36.1767C49.8198 35.0792 50.5776 34.0387 51.4196 33.066C52.1245 32.2102 53.0761 31.5851 54.1489 31.2731C54.5353 31.1614 54.9401 31.1248 55.3407 31.1655C55.3665 31.1676 55.3908 31.1786 55.4091 31.1967C55.4275 31.2148 55.4387 31.2387 55.4407 31.2641C55.4456 31.2739 55.4481 31.2847 55.4481 31.2955C55.4481 31.3064 55.4456 31.3171 55.4407 31.3269V31.4165C55.069 32.7146 54.4785 33.9419 53.694 35.0471C53.1109 35.999 52.2297 36.7384 51.183 37.1538C50.8179 37.2656 50.4372 37.3201 50.0549 37.3152ZM48.9996 36.8849L49.2998 36.9835C49.9018 37.1331 50.5355 37.1019 51.1194 36.8938C52.1103 36.5292 52.9557 35.8596 53.5302 34.9844C54.3027 33.8934 54.881 32.6808 55.2406 31.3986C54.9088 31.3843 54.5769 31.4237 54.2581 31.5152C53.2284 31.8108 52.3125 32.4042 51.6288 33.2184C50.79 34.184 50.0324 35.2153 49.3635 36.3022L49.0997 36.7235L48.9996 36.8849Z" fill="#969696"/>
    <path d="M35.5625 44.1371H35.4897C34.3133 44.928 33.1974 45.8029 32.1509 46.7547C31.3879 47.4505 30.7967 48.3091 30.4223 49.2648C30.2542 49.6944 30.1618 50.1492 30.1494 50.6094C30.1494 50.6094 30.1494 50.6991 30.1494 50.7618L30.4314 50.6901C31.7263 50.3312 32.9355 49.7217 33.9886 48.8972C34.6517 48.4051 35.18 47.7582 35.5261 47.0147C35.8863 46.1592 35.9218 45.2045 35.6262 44.3254C35.608 44.2357 35.5807 44.1909 35.5625 44.1371Z" fill="#CCCCCC"/>
    <path d="M30.1583 50.9142H30.0855C30.0743 50.8853 30.0743 50.8534 30.0855 50.8246V50.7708C30.0811 50.735 30.0811 50.6989 30.0855 50.6632C30.1004 50.1915 30.1925 49.7253 30.3585 49.2827C30.7389 48.3083 31.343 47.4339 32.1234 46.7278C33.1687 45.7682 34.2881 44.8901 35.4714 44.1012H35.535C35.5509 44.0946 35.5679 44.0911 35.5851 44.0911C35.6023 44.0911 35.6193 44.0946 35.6351 44.1012C35.6497 44.1066 35.6629 44.115 35.6738 44.1258C35.6848 44.1366 35.6933 44.1496 35.6988 44.164C35.7025 44.2027 35.7025 44.2417 35.6988 44.2805C36.0179 45.202 35.9823 46.207 35.5987 47.1043C35.2567 47.8801 34.7178 48.5562 34.0339 49.0675C32.9528 49.8958 31.716 50.5052 30.3949 50.8604H30.2038H30.1037L30.1583 50.9142ZM35.5077 44.3253C34.3487 45.0962 33.2535 45.9565 32.2326 46.8981C31.4677 47.5692 30.8726 48.4073 30.4949 49.3454C30.3322 49.7638 30.243 50.2064 30.2311 50.6542H30.3767C31.6566 50.2927 32.8505 49.6832 33.8884 48.8613C34.5502 48.3852 35.0765 47.7492 35.4168 47.0147C35.7857 46.1606 35.8181 45.2017 35.5077 44.3253Z" fill="#969696"/>
    <path d="M39.5926 41.4119C38.4642 42.6268 37.5176 43.9942 36.7814 45.4728C36.3292 46.4163 36.1739 47.4716 36.3356 48.5028C36.3356 48.6462 36.3993 48.7896 36.4266 48.942H36.5085C37.7623 48.2333 38.8211 47.2331 39.5926 46.0286C40.0652 45.3179 40.3123 44.4847 40.3022 43.6351C40.2931 42.8772 40.0659 42.1374 39.6472 41.5015L39.5926 41.4119Z" fill="#CCCCCC"/>
    <path d="M36.463 49.0496C36.4425 49.0572 36.4199 49.0572 36.3993 49.0496C36.3993 49.0496 36.3357 49.0496 36.3357 48.9869V48.8524C36.3357 48.7448 36.2902 48.6462 36.272 48.5386C36.1122 47.4831 36.2703 46.4047 36.7269 45.4369C37.4725 43.9513 38.4248 42.5755 39.5562 41.3491C39.5706 41.3432 39.5861 41.3402 39.6017 41.3402C39.6173 41.3402 39.6328 41.3432 39.6472 41.3491C39.6651 41.3479 39.6829 41.3514 39.6989 41.3593C39.7149 41.3672 39.7285 41.3792 39.7382 41.394L39.7837 41.4567C40.2272 42.1014 40.4764 42.8567 40.5024 43.6351C40.5182 44.5076 40.2643 45.3643 39.7746 46.0913C38.9883 47.3075 37.9104 48.3142 36.6359 49.0227H36.5449L36.463 49.0496ZM39.5926 41.636C38.4986 42.8074 37.5796 44.1265 36.8633 45.5535C36.4165 46.4794 36.2642 47.5169 36.4266 48.5297C36.4266 48.6193 36.4266 48.7179 36.4812 48.8165C37.7024 48.1368 38.7338 47.1697 39.4835 46.0017C39.9464 45.3143 40.1875 44.5049 40.1749 43.6799C40.1768 42.9586 39.9752 42.2509 39.5926 41.636Z" fill="#969696"/>
    <path d="M38.8831 8.10001L40.184 8.63788C40.9958 8.97774 41.8359 9.24768 42.695 9.44468C43.9903 9.77472 45.3654 9.59128 46.5251 8.9337C46.6343 8.87095 46.7252 8.79924 46.8526 8.71856L46.6252 8.51237C45.6365 7.62438 44.439 6.99283 43.1408 6.67466C42.5722 6.51777 41.9754 6.48705 41.3934 6.58471C40.8113 6.68236 40.2585 6.90597 39.7746 7.23942C39.432 7.47669 39.1309 7.76736 38.8831 8.10001Z" fill="#CCCCCC"/>
    <path d="M44.0323 9.71362C43.5733 9.70969 43.1159 9.65861 42.6677 9.56123C41.8037 9.35699 40.9578 9.08417 40.1385 8.74546L39.2924 8.39585L38.8375 8.20759C38.807 8.18932 38.7843 8.1606 38.7739 8.12692C38.7659 8.11167 38.7617 8.09476 38.7617 8.07761C38.7617 8.06046 38.7659 8.04355 38.7739 8.02831C39.0195 7.67761 39.3277 7.37386 39.6836 7.13186C40.1776 6.78342 40.745 6.54927 41.3436 6.44683C41.9421 6.34439 42.5564 6.37631 43.1407 6.54021C44.4729 6.8822 45.6983 7.54442 46.707 8.46756L46.8526 8.61099L46.9345 8.68271C46.941 8.69677 46.9444 8.71206 46.9444 8.72753C46.9444 8.743 46.941 8.75829 46.9345 8.77236C46.9348 8.78981 46.9309 8.8071 46.923 8.82272C46.915 8.83834 46.9034 8.85182 46.889 8.862L46.7707 8.94268L46.5615 9.07715C45.7914 9.5085 44.918 9.7283 44.0323 9.71362ZM39.065 8.04624L39.3834 8.1807L40.2295 8.53031C41.0331 8.87277 41.8677 9.1399 42.7222 9.32815C43.9891 9.6513 45.334 9.4744 46.4705 8.83511L46.6615 8.70064L46.5524 8.60203C45.577 7.71611 44.3963 7.07902 43.1134 6.74639C42.5616 6.59211 41.9815 6.56195 41.4162 6.65815C40.8509 6.75435 40.3148 6.97445 39.8474 7.30219C39.5502 7.51019 39.2865 7.76101 39.065 8.04624Z" fill="#969696"/>
    <path d="M42.768 37.862L42.3222 38.866C41.8262 39.8973 41.4478 40.9797 41.1941 42.0932C40.9309 43.2489 41.0456 44.457 41.5216 45.5445C41.5216 45.6252 41.6035 45.7059 41.649 45.7866V45.8404L41.7218 45.7866C42.6938 44.8427 43.4236 43.6842 43.8506 42.407C44.0761 41.7841 44.1515 41.1181 44.071 40.4616C43.9905 39.8051 43.7562 39.176 43.3866 38.624C43.232 38.355 42.9864 38.1309 42.768 37.862Z" fill="#CCCCCC"/>
    <path d="M41.6396 45.9479C41.6252 45.9538 41.6097 45.9569 41.5941 45.9569C41.5785 45.9569 41.563 45.9538 41.5486 45.9479L41.494 45.8762C41.457 45.8093 41.4236 45.7404 41.3939 45.67C40.9012 44.565 40.78 43.3336 41.0482 42.156C41.3033 41.033 41.6847 39.9415 42.1854 38.9019L42.4948 38.2743L42.6767 37.8978C42.6848 37.8803 42.6975 37.8652 42.7135 37.8542C42.7295 37.8432 42.7482 37.8366 42.7677 37.8351C42.7845 37.8264 42.8033 37.8219 42.8223 37.8219C42.8413 37.8219 42.86 37.8264 42.8769 37.8351L43.0861 38.0861C43.2353 38.2493 43.3721 38.4231 43.4955 38.606C43.8786 39.1707 44.1218 39.8161 44.2056 40.4903C44.2893 41.1645 44.2113 41.8486 43.9777 42.4876C43.5454 43.7818 42.8063 44.9557 41.8215 45.9121L41.7397 45.9748L41.6396 45.9479ZM42.795 38.0502L42.6858 38.2654C42.5766 38.4805 42.4766 38.6867 42.3856 38.8929C41.8884 39.9144 41.51 40.988 41.2575 42.0932C41.0069 43.2259 41.1214 44.4073 41.585 45.4728C41.6098 45.5323 41.6403 45.5893 41.676 45.6431C42.617 44.7196 43.3217 43.5889 43.732 42.3442C43.9558 41.7386 44.032 41.0896 43.9545 40.4497C43.8771 39.8097 43.6482 39.1966 43.2863 38.6598C43.168 38.4895 43.0315 38.3281 42.8951 38.1668C42.8569 38.1321 42.8233 38.093 42.795 38.0502Z" fill="#969696"/>
    <path d="M45.7334 41.7974C45.9791 41.2864 46.2156 40.8203 46.4249 40.3451C46.5371 40.1062 46.6255 39.857 46.6887 39.6011C46.8197 39.1232 46.923 38.6383 46.998 38.1488C47.1444 37.333 47.0428 36.4927 46.7062 35.7336C46.3695 34.9744 45.8127 34.3302 45.1057 33.8818L44.9692 33.8011C44.8692 34.2403 44.7691 34.6975 44.6781 35.1099C44.4609 36.1266 44.3572 37.1637 44.3688 38.2026C44.3834 39.3876 44.7748 40.5382 45.4878 41.4926C45.5606 41.5822 45.6334 41.636 45.7334 41.7974Z" fill="#CCCCCC"/>
    <path d="M45.7336 41.9139C45.7159 41.9143 45.6984 41.9104 45.6825 41.9026C45.6667 41.8948 45.653 41.8833 45.6427 41.8691L45.5608 41.7705C45.5026 41.7015 45.4479 41.6297 45.397 41.5553C44.666 40.5847 44.2649 39.4113 44.2507 38.2026C44.2342 37.1546 44.338 36.1081 44.56 35.083C44.6237 34.7872 44.6965 34.4914 44.7602 34.1866L44.8603 33.7742C44.8624 33.7563 44.8694 33.7393 44.8807 33.7251C44.8919 33.7109 44.9069 33.7 44.9239 33.6935C44.9591 33.6804 44.9979 33.6804 45.0331 33.6935H45.0877L45.1696 33.7473C45.8954 34.2086 46.4671 34.8704 46.8134 35.65C47.1597 36.4297 47.2651 37.2926 47.1165 38.1309C47.0596 38.5684 46.9776 39.0024 46.8708 39.4308V39.628C46.8015 39.889 46.7102 40.1439 46.5979 40.39L46.1248 41.394L45.9065 41.8512C45.8971 41.8677 45.8841 41.882 45.8683 41.8928C45.8526 41.9037 45.8345 41.9109 45.8155 41.9139H45.7336ZM45.0513 33.9804L44.9967 34.2404C44.9239 34.5362 44.8603 34.832 44.7966 35.1368C44.5752 36.1438 44.4714 37.1724 44.4873 38.2026C44.5001 39.3658 44.8853 40.4952 45.5881 41.4298L45.7063 41.5912L45.8428 41.3043C46.0157 40.9637 46.1703 40.641 46.3159 40.3093C46.4262 40.0767 46.5116 39.8333 46.5706 39.5832L46.6252 39.386C46.7345 38.9763 46.8165 38.5601 46.8708 38.1399C47.0126 37.3472 46.9159 36.5311 46.5925 35.7919C46.2692 35.0528 45.7335 34.4231 45.0513 33.9804Z" fill="#969696"/>
    <path d="M42.4131 11.5424C42.7406 11.8471 43.0499 12.1699 43.3865 12.4388C43.9506 12.9049 44.5055 13.3352 45.0969 13.7835C46.0234 14.4647 47.1631 14.8041 48.3175 14.7427C48.645 14.7427 48.9725 14.6441 49.3364 14.5992C49.3364 14.5185 49.3364 14.4737 49.2727 14.4379C49.0908 14.142 48.9088 13.8372 48.7178 13.5414C48.5231 13.2537 48.3104 12.9783 48.0809 12.7167C47.6621 12.2123 47.1646 11.7765 46.6071 11.4258C46.021 11.0255 45.327 10.8065 44.614 10.797C43.9009 10.7874 43.2011 10.9876 42.6041 11.372C42.5522 11.3997 42.5034 11.4327 42.4586 11.4706C42.4586 11.4706 42.4404 11.5155 42.4131 11.5424Z" fill="#CCCCCC"/>
    <path d="M47.9988 14.8771C46.9307 14.8477 45.8992 14.487 45.0512 13.8462C44.4781 13.4428 43.914 12.9498 43.3772 12.5374C43.1407 12.3402 42.9224 12.125 42.704 11.9189L42.3219 11.623C42.312 11.6138 42.3041 11.6027 42.2987 11.5904C42.2933 11.578 42.2905 11.5647 42.2905 11.5513C42.2905 11.5379 42.2933 11.5246 42.2987 11.5123C42.3041 11.4999 42.312 11.4888 42.3219 11.4796L42.3765 11.4168L42.5312 11.3182C43.1476 10.923 43.8693 10.7171 44.6046 10.7267C45.34 10.7363 46.0559 10.9609 46.6615 11.372C47.2269 11.73 47.7331 12.1715 48.1626 12.6808C48.3991 12.9587 48.6175 13.2635 48.7994 13.5235C48.9814 13.7835 49.0906 13.9717 49.227 14.1958L49.3635 14.4199C49.3651 14.4528 49.3651 14.4857 49.3635 14.5185V14.5903C49.3703 14.6058 49.3737 14.6226 49.3737 14.6396C49.3737 14.6565 49.3703 14.6733 49.3635 14.6889C49.355 14.7053 49.3419 14.7191 49.3258 14.7286C49.3098 14.7381 49.2913 14.743 49.2725 14.7427L48.9359 14.7964C48.7111 14.8433 48.483 14.8732 48.2536 14.8861L47.9988 14.8771ZM42.5857 11.5423L42.8041 11.7485C43.0133 11.9547 43.2408 12.1699 43.4682 12.3581C44.005 12.7974 44.5599 13.2546 45.124 13.658C46.0285 14.3263 47.1429 14.6595 48.2718 14.5992C48.4901 14.5992 48.7085 14.5454 48.9359 14.5096H49.1361L48.9996 14.2855C48.8631 14.0703 48.7267 13.8372 48.5811 13.6221C48.3872 13.3391 48.1776 13.0667 47.9534 12.8063C47.5377 12.3164 47.0501 11.8904 46.5068 11.5423C45.9417 11.1493 45.2691 10.9339 44.5775 10.9243C43.8858 10.9147 43.2074 11.1114 42.6312 11.4886L42.5857 11.5423Z" fill="#969696"/>
    <path d="M44.751 15.5674C44.9147 15.8184 45.0694 16.0604 45.2332 16.2935C45.8038 17.1761 46.4753 17.9912 47.2346 18.7229C48.1299 19.602 49.337 20.1035 50.6008 20.1213H50.8191C50.8237 20.0916 50.8237 20.0614 50.8191 20.0317C50.4578 18.61 49.7018 17.3155 48.6357 16.2935C48.1534 15.8187 47.54 15.4941 46.8719 15.3602C46.2038 15.2263 45.5105 15.289 44.8783 15.5405H44.8147C44.8147 15.5405 44.7874 15.5405 44.751 15.5674Z" fill="#CCCCCC"/>
    <path d="M50.61 20.202C49.3204 20.1929 48.0845 19.6916 47.162 18.8036C46.3937 18.0687 45.7158 17.2473 45.1423 16.3563L44.8148 15.8632L44.651 15.6391C44.6445 15.625 44.6411 15.6098 44.6411 15.5943C44.6411 15.5788 44.6445 15.5635 44.651 15.5495C44.6584 15.5168 44.6779 15.4881 44.7056 15.4688H44.7875C45.439 15.2086 46.1538 15.143 46.8429 15.2801C47.532 15.4172 48.165 15.7509 48.6631 16.2397C49.745 17.2805 50.5133 18.596 50.8829 20.0407C50.8921 20.0699 50.8921 20.1011 50.8829 20.1303C50.884 20.1629 50.8737 20.1948 50.8539 20.2209C50.8341 20.247 50.8058 20.2656 50.7738 20.2737H50.5554L50.61 20.202ZM44.9149 15.6391L45.0059 15.7646L45.3243 16.2577C45.8921 17.1316 46.5607 17.9377 47.3167 18.6601C48.1926 19.5105 49.3712 19.9899 50.6009 19.9958H50.6828C50.3274 18.6144 49.5942 17.3556 48.563 16.3563C48.0949 15.898 47.5007 15.5849 46.8539 15.4558C46.2072 15.3268 45.5362 15.3874 44.924 15.6301L44.9149 15.6391Z" fill="#969696"/>
    <path d="M46.2976 29.3278C46.2976 29.4981 46.2976 29.6505 46.2976 29.8029C46.2297 31.136 46.346 32.472 46.6433 33.7742C46.912 34.9246 47.5501 35.9589 48.4628 36.7235L48.6812 36.8759C48.7267 36.7145 48.7722 36.5801 48.8177 36.4366C49.1359 35.324 49.2469 34.1638 49.1452 33.0122C49.1004 32.2082 48.8183 31.4345 48.3334 30.7864C47.8486 30.1382 47.1821 29.6437 46.4159 29.3637L46.2976 29.3278Z" fill="#CCCCCC"/>
    <path d="M48.6903 36.9924C48.6669 37.0014 48.6409 37.0014 48.6175 36.9924H48.5447L48.3991 36.8849C47.4513 36.1115 46.7867 35.0536 46.5068 33.8728C46.2079 32.5617 46.0916 31.2167 46.1611 29.8747C46.1611 29.7671 46.1611 29.6595 46.1611 29.5519V29.3906C46.1499 29.3617 46.1499 29.3298 46.1611 29.3009C46.1933 29.2884 46.229 29.2884 46.2612 29.3009H46.3249H46.4249C47.1977 29.6003 47.8663 30.1131 48.3505 30.7778C48.8346 31.4426 49.1137 32.2309 49.1542 33.0481C49.2615 34.2121 49.1473 35.3856 48.8176 36.5083C48.8176 36.607 48.763 36.7056 48.7267 36.8131V36.9566C48.7223 36.9739 48.7132 36.9897 48.7003 37.0024C48.6875 37.015 48.6715 37.0239 48.6539 37.0283L48.6903 36.9924ZM46.4159 29.4802C46.4159 29.5878 46.4159 29.6954 46.4159 29.8029C46.3434 31.1238 46.4566 32.4481 46.7525 33.7383C47.0265 34.869 47.6649 35.8817 48.572 36.6249L48.6539 36.6787L48.7358 36.3918C49.0574 35.2982 49.1684 34.155 49.0633 33.0212C49.0325 32.2359 48.7676 31.477 48.3016 30.8392C47.8356 30.2015 47.1892 29.7132 46.4431 29.4354L46.4159 29.4802Z" fill="#969696"/>
    <path d="M46.698 24.6752C46.8708 26.0052 47.21 27.309 47.7078 28.5569C47.9864 29.2213 48.3874 29.8291 48.8905 30.3497C49.3105 30.7836 49.8068 31.139 50.3553 31.3986H50.4462H50.5099C50.5099 31.282 50.5099 31.1655 50.5099 31.049C50.6457 29.7805 50.4966 28.4982 50.0732 27.2929C49.8551 26.5955 49.4366 25.9755 48.8682 25.5077C48.2999 25.0399 47.6061 24.7446 46.8709 24.6573H46.7799L46.698 24.6752Z" fill="#CCCCCC"/>
    <path d="M50.5099 31.5689H50.4099H50.3007C49.7369 31.3097 49.23 30.9442 48.8087 30.4932C48.2965 29.9743 47.8862 29.3664 47.5987 28.7003C47.0948 27.4414 46.7525 26.1254 46.5798 24.7828C46.5764 24.7558 46.5827 24.7284 46.5976 24.7055C46.6126 24.6825 46.6352 24.6655 46.6616 24.6573V24.7738V24.6483H46.8709C47.6312 24.7288 48.3513 25.0261 48.9427 25.5038C49.5342 25.9815 49.9713 26.6188 50.2006 27.3377C50.6537 28.5829 50.8215 29.9116 50.6919 31.2283V31.4793V31.5868C50.6805 31.6008 50.6658 31.6117 50.649 31.6186C50.6323 31.6255 50.6141 31.6282 50.596 31.6264C50.5779 31.6246 50.5606 31.6184 50.5456 31.6084C50.5305 31.5984 50.5183 31.5848 50.5099 31.5689ZM46.8709 24.7738C47.0208 26.0514 47.3262 27.3065 47.7806 28.512C48.0509 29.1744 48.4425 29.7822 48.936 30.3049C49.3421 30.7317 49.8269 31.0785 50.3644 31.3269V31.1207C50.5268 29.8313 50.39 28.5225 49.9641 27.2929C49.7477 26.625 49.3411 26.0326 48.7927 25.586C48.2443 25.1394 47.5772 24.8575 46.8709 24.7738Z" fill="#969696"/>
    <path d="M51.2558 25.7869C51.2012 25.4104 51.1557 25.0428 51.092 24.6932C50.9279 23.6882 50.5696 22.7235 50.0367 21.8515C49.6864 21.1988 49.1401 20.6685 48.4725 20.3332C47.8049 19.9979 47.0485 19.8738 46.3067 19.9779H46.2339C46.2976 20.1393 46.3522 20.3096 46.4158 20.471C46.8016 21.6101 47.3304 22.6972 47.9897 23.7071C48.7148 24.7898 49.8416 25.5493 51.1284 25.8227L51.2558 25.7869Z" fill="#CCCCCC"/>
    <path d="M51.2559 25.9034H51.1012C49.7815 25.6163 48.6304 24.8268 47.8988 23.7071C47.2155 22.727 46.665 21.6632 46.2612 20.5427C46.2612 20.4261 46.1703 20.3006 46.1339 20.1841L46.0793 20.0407C46.0652 20.021 46.0576 19.9975 46.0576 19.9734C46.0576 19.9493 46.0652 19.9259 46.0793 19.9062C46.0793 19.9062 46.143 19.8614 46.1612 19.8614H46.2521C47.0165 19.7578 47.7947 19.8869 48.4825 20.2314C49.1703 20.5759 49.7349 21.1192 50.1005 21.7887C50.6403 22.6754 51.0046 23.6549 51.174 24.6753C51.174 24.9173 51.2468 25.1594 51.2831 25.4193L51.3377 25.7689C51.3504 25.8006 51.3504 25.8359 51.3377 25.8675C51.3279 25.8794 51.3153 25.8888 51.3011 25.895C51.2869 25.9012 51.2714 25.9041 51.2559 25.9034ZM46.334 20.1213C46.334 20.2379 46.4159 20.3634 46.4705 20.4799C46.8681 21.5824 47.4061 22.6306 48.0717 23.5995C48.7717 24.6464 49.8586 25.3829 51.1012 25.6524V25.4462C51.1012 25.1952 51.0284 24.9532 50.9829 24.7111C50.8173 23.7219 50.4622 22.7727 49.9367 21.9142C49.5912 21.2909 49.0617 20.7857 48.4184 20.4656C47.775 20.1454 47.0482 20.0254 46.334 20.1213Z" fill="#969696"/>
    <path d="M24.8817 46.3962C20.6874 46.3962 16.5873 45.1706 13.0999 42.8745C9.6125 40.5784 6.8944 37.3149 5.28932 33.4966C3.68424 29.6784 3.26428 25.4768 4.08254 21.4234C4.9008 17.3699 6.92054 13.6466 9.88634 10.7242C12.8521 7.80185 16.6308 5.81169 20.7445 5.0054C24.8582 4.19912 29.1221 4.61294 32.9971 6.19451C36.8721 7.77609 40.1841 10.4544 42.5144 13.8908C44.8446 17.3271 46.0883 21.3672 46.0883 25.5C46.0811 31.0398 43.8445 36.3507 39.8691 40.268C35.8936 44.1852 30.5038 46.389 24.8817 46.3962ZM24.8817 5.02522C20.772 5.02522 16.7546 6.22605 13.3375 8.47585C9.92038 10.7256 7.25708 13.9234 5.68436 17.6647C4.11164 21.4059 3.70015 25.5227 4.50191 29.4945C5.30367 33.4662 7.28269 37.1144 10.1887 39.9779C13.0947 42.8413 16.7972 44.7914 20.8279 45.5814C24.8586 46.3714 29.0366 45.966 32.8335 44.4163C36.6304 42.8666 39.8756 40.2423 42.1588 36.8752C44.4421 33.5081 45.6607 29.5496 45.6607 25.5C45.6559 20.0712 43.4652 14.8661 39.5694 11.0274C35.6736 7.18865 30.3912 5.02997 24.8817 5.02522Z" fill="#2E9E43"/>
    <path d="M24.8817 44.1909C21.1301 44.1909 17.4627 43.0947 14.3433 41.0409C11.2239 38.9871 8.79268 36.068 7.35699 32.6527C5.92131 29.2374 5.54566 25.4793 6.27757 21.8536C7.00948 18.2279 8.81606 14.8975 11.4689 12.2836C14.1217 9.66962 17.5016 7.88948 21.1811 7.16829C24.8607 6.4471 28.6746 6.81724 32.1407 8.23191C35.6067 9.64658 38.5692 12.0422 40.6535 15.1159C42.7378 18.1896 43.8503 21.8033 43.8503 25.5C43.8431 30.455 41.8423 35.2049 38.2866 38.7086C34.7308 42.2123 29.9103 44.1838 24.8817 44.1909ZM24.8817 7.23944C21.2164 7.23944 17.6335 8.31041 14.5859 10.3169C11.5384 12.3234 9.16308 15.1753 7.76044 18.512C6.3578 21.8487 5.99081 25.5203 6.70587 29.0625C7.42093 32.6047 9.18592 35.8584 11.7777 38.4122C14.3694 40.966 17.6715 42.7052 21.2663 43.4097C24.8611 44.1143 28.5873 43.7527 31.9736 42.3706C35.3598 40.9885 38.2541 38.648 40.2904 35.6451C42.3267 32.6421 43.4136 29.1116 43.4136 25.5C43.4088 20.6585 41.4548 16.0166 37.9804 12.5931C34.5061 9.16959 29.7952 7.24419 24.8817 7.23944Z" fill="#CCCCCC"/>
    <path d="M16.4847 36.2573C17.2034 35.5581 16.9214 34.8141 16.2755 34.1686L15.2747 33.1556L12.2998 36.0601L12.9639 36.7235L13.9738 35.7553L14.3286 36.1139C14.4528 36.274 14.6103 36.4062 14.7906 36.5015C14.9708 36.5969 15.1696 36.6532 15.3737 36.6668C15.5778 36.6804 15.7825 36.6509 15.9741 36.5803C16.1657 36.5097 16.3398 36.3996 16.4847 36.2573ZM14.829 35.5223L14.5014 35.1995L15.4112 34.3031L15.7205 34.6079C16.0753 34.9665 16.1572 35.2712 15.8479 35.5671C15.5386 35.8629 15.1929 35.9257 14.829 35.5581V35.5223Z" fill="#747778"/>
    <path d="M15.3293 38.7764L16.1663 39.2335L16.8759 38.5074L17.9859 39.117L17.7584 40.0941L18.6682 40.5692L19.578 36.356L18.5863 35.8181L15.3293 38.7764ZM18.6682 36.6966C18.5499 37.1269 18.4044 37.593 18.3043 38.0144L18.2042 38.3909L17.4309 37.9696L17.7038 37.6827C18.0041 37.3779 18.3407 37.0104 18.6136 36.6876L18.6682 36.6966Z" fill="#747778"/>
    <path d="M22.1525 37.2076C21.6617 37.1037 21.1493 37.1836 20.7151 37.4317L21.0608 38.1219C21.3389 37.974 21.6607 37.9264 21.9705 37.9875C22.3072 38.0502 22.5164 38.2295 22.4527 38.5433C22.389 38.857 22.1707 38.9646 21.6885 38.866L21.2609 38.7853L21.1245 39.4487L21.6703 39.5652C22.1707 39.6638 22.3799 39.861 22.3163 40.2017C22.2526 40.5423 21.9069 40.6768 21.4884 40.5961C21.1173 40.5202 20.791 40.3048 20.5786 39.9955L19.96 40.5244C20.2885 40.9896 20.7876 41.311 21.3519 41.4208C22.2617 41.6001 23.0441 41.3133 23.1714 40.5244C23.2207 40.3137 23.1962 40.0929 23.1021 39.8976C23.008 39.7024 22.8497 39.5442 22.6529 39.4487C22.8332 39.4062 22.996 39.3105 23.1196 39.1743C23.2432 39.0381 23.3216 38.868 23.3443 38.6867C23.5171 37.9068 23.0896 37.3958 22.1525 37.2076Z" fill="#747778"/>
    <path d="M25.9462 37.3869L24.5178 37.5303L24.9272 41.636L25.837 41.5374L25.6823 40.1479L26.2009 40.0941C27.1107 40.0045 27.7748 39.5025 27.6656 38.5433C27.5564 37.5841 26.8559 37.2883 25.9462 37.3869ZM26.1281 39.3501L25.6732 39.4039L25.5368 38.1668L25.9643 38.1219C26.4738 38.0682 26.7649 38.1937 26.8104 38.624C26.8559 39.0542 26.6012 39.2963 26.0917 39.3501H26.1281Z" fill="#747778"/>
    <path d="M29.385 36.4994C28.5844 36.858 28.0568 37.3958 28.4753 38.2923C28.5734 38.5336 28.7473 38.7378 28.9715 38.8749C29.1957 39.012 29.4586 39.0748 29.7216 39.0543L29.4305 40.9637L30.404 40.5244L30.5859 38.8212L31.0135 38.624L31.6412 39.9686L32.4964 39.5832L30.7588 35.8539L29.385 36.4994ZM30.2948 38.1847C29.8399 38.3909 29.5124 38.3102 29.3395 37.9427C29.1667 37.5751 29.3395 37.342 29.8035 37.1359L30.2402 36.9386L30.7315 37.9875L30.2948 38.1847Z" fill="#747778"/>
    <path d="M36.8636 35.6567L36.4542 35.2443L36.0903 35.5939L33.7431 33.2004L31.9236 34.8947L33.0153 36.4994C33.5703 37.351 33.6794 37.7544 33.5885 37.9337L33.452 38.0592L33.8614 38.4805L34.8894 39.3769L35.499 38.8032L34.662 37.9068L36.2996 36.3559L37.1274 37.2075L37.7734 36.7055L36.8636 35.6567ZM34.3436 37.2434C34.2152 36.8008 34.0122 36.3826 33.7431 36.0063C33.4611 35.585 33.3064 35.3698 33.0244 34.9575L33.6066 34.4017L35.4262 36.1946L34.3436 37.2434Z" fill="#747778"/>
    <path d="M16.5757 35.827C17.3035 35.1278 17.0215 34.3838 16.3756 33.7383L15.3748 32.7343L12.3999 35.6029L13.064 36.2663L14.1194 35.2892L14.4742 35.6567C14.5955 35.8113 14.7483 35.9393 14.9226 36.0326C15.097 36.1258 15.2891 36.1822 15.4869 36.1983C15.6846 36.2143 15.8835 36.1896 16.071 36.1256C16.2584 36.0617 16.4303 35.96 16.5757 35.827ZM14.929 35.1009L14.6015 34.7782L15.5113 33.8818L15.8115 34.1866C16.1754 34.5451 16.2573 34.8499 15.948 35.1547C15.6387 35.4595 15.293 35.4595 14.929 35.1009Z" fill="#DBDBDB"/>
    <path d="M15.3474 38.3999L16.1844 38.857L16.894 38.1309L18.0039 38.7405L17.7583 39.7176L18.6681 40.1927L19.5778 35.9795L18.5953 35.4416L15.3474 38.3999ZM18.6681 36.3201C18.5498 36.7504 18.4133 37.2166 18.3132 37.6379L18.2132 38.0144L17.4308 37.5931L17.7037 37.3062C18.013 37.0014 18.3405 36.6428 18.6135 36.3111L18.6681 36.3201Z" fill="#DBDBDB"/>
    <path d="M22.2252 36.8221C21.7345 36.7182 21.2221 36.7981 20.7878 37.0462L21.1335 37.7365C21.4119 37.5892 21.7334 37.5417 22.0433 37.602C22.3799 37.6647 22.5892 37.844 22.5255 38.1578C22.4618 38.4715 22.2525 38.5791 21.7613 38.4805L21.3428 38.3998L21.1972 39.0632L21.7431 39.1797C22.2525 39.2783 22.4618 39.4756 22.389 39.8162C22.3162 40.1569 21.9796 40.2913 21.5611 40.2107C21.1893 40.1366 20.8623 39.9207 20.6514 39.61L20.0327 40.1389C20.3578 40.6036 20.8538 40.9252 21.4156 41.0354C22.3253 41.2147 23.1168 40.9278 23.2351 40.1389C23.286 39.9282 23.2624 39.7067 23.1681 39.5111C23.0737 39.3155 22.9144 39.1574 22.7165 39.0632C22.8985 39.0223 23.0633 38.9273 23.1887 38.791C23.314 38.6547 23.3937 38.4837 23.417 38.3012C23.5899 37.5213 23.1623 37.0103 22.2252 36.8221Z" fill="#DBDBDB"/>
    <path d="M25.8553 37.0641L24.436 37.2165L24.8818 41.2954L25.7916 41.1968L25.6642 39.8431L26.1737 39.7893C27.0835 39.6907 27.7567 39.1887 27.6475 38.2295C27.5383 37.2703 26.7741 36.9745 25.8553 37.0641ZM26.0463 39.0363H25.5823L25.455 37.8082L25.8826 37.7634C26.392 37.7096 26.6832 37.8261 26.7286 38.2564C26.7741 38.6867 26.5558 38.9467 26.0463 39.0363Z" fill="#DBDBDB"/>
    <path d="M29.385 36.1229C28.5844 36.4814 28.0568 37.0193 28.4753 37.9157C28.5734 38.1571 28.7473 38.3613 28.9715 38.4983C29.1957 38.6354 29.4586 38.6983 29.7216 38.6777L29.4305 40.5872L30.404 40.1479L30.5859 38.4446L31.0135 38.2474L31.6412 39.5921L32.4964 39.2066L30.7588 35.4774L29.385 36.1229ZM30.2948 37.8082C29.8399 38.0054 29.5124 37.9247 29.3395 37.5661C29.1667 37.2076 29.3395 36.9655 29.8035 36.7593L30.2402 36.5621L30.7315 37.611L30.2948 37.8082Z" fill="#DBDBDB"/>
    <path d="M36.8636 35.3609L36.4542 34.9396L36.0903 35.2892L33.7431 32.8957L31.9236 34.5899L33.0153 36.1946C33.5703 37.091 33.6794 37.4496 33.5885 37.6289L33.452 37.7634L33.8614 38.1757L34.8894 39.117L35.499 38.5433L34.662 37.6468L36.2996 36.087L37.1274 36.9386L37.737 36.3649L36.8636 35.3609ZM34.3527 36.9386C34.224 36.4989 34.021 36.0837 33.7522 35.7105C33.4702 35.2802 33.3155 35.074 33.0335 34.6527L33.6157 34.1059L35.4353 35.8987L34.3527 36.9386Z" fill="#DBDBDB"/>
    <path d="M32.4147 15.8722C32.4442 17.0764 32.0196 18.2486 31.2229 19.1622C30.3398 20.1164 29.1697 20.7668 27.8841 21.0178V21.1075C29.295 21.1963 30.6401 21.7272 31.7233 22.6225C32.1609 23.0418 32.5031 23.5479 32.7274 24.1074C32.9517 24.6668 33.0528 25.2668 33.0242 25.8676C33.0681 26.7575 32.8915 27.6445 32.5095 28.452C32.1274 29.2595 31.5516 29.9632 30.8317 30.5022C29.3943 31.6078 27.3109 32.1606 24.5816 32.1606C22.5121 32.2004 20.4562 31.8221 18.5408 31.049V27.3378C19.3944 27.7589 20.291 28.0894 21.2155 28.3238C22.1591 28.5739 23.1314 28.7034 24.1085 28.7093C25.2384 28.7881 26.3663 28.5321 27.3473 27.9742C27.7061 27.6977 27.989 27.3372 28.1701 26.9254C28.3513 26.5136 28.4249 26.0636 28.3844 25.6166C28.4132 25.1945 28.3145 24.7734 28.1008 24.4065C27.8871 24.0396 27.5679 23.7432 27.1835 23.5548C25.9673 23.0792 24.6594 22.8741 23.3534 22.9541H21.7613V19.6104H23.3807C24.6007 19.6905 25.822 19.4751 26.9379 18.9829C27.3106 18.764 27.6132 18.4461 27.8111 18.0656C28.009 17.6851 28.0942 17.2574 28.0569 16.8314C28.0569 15.2716 27.0653 14.4917 25.082 14.4917C24.3708 14.4975 23.6648 14.6125 22.9895 14.8323C22.1501 15.1178 21.3519 15.5095 20.615 15.9977L18.5681 12.9946C20.5663 11.6152 22.9614 10.9049 25.4004 10.9687C27.1936 10.8784 28.9717 11.3321 30.4951 12.2685C31.1028 12.6556 31.5988 13.1907 31.9351 13.822C32.2714 14.4533 32.4366 15.1595 32.4147 15.8722Z" fill="#747778"/>
    <path d="M31.9052 15.424C31.9347 16.6281 31.5101 17.8003 30.7134 18.7139C29.8323 19.666 28.6658 20.3161 27.3836 20.5696V20.6592C28.7915 20.7493 30.1334 21.2801 31.2138 22.1742C31.6528 22.5925 31.996 23.0985 32.2203 23.6581C32.4447 24.2178 32.5451 24.8183 32.5147 25.4193C32.5581 26.3082 32.3821 27.1942 32.0019 28.0014C31.6216 28.8086 31.0483 29.5129 30.3313 30.0539C28.8696 31.1596 26.7832 31.7124 24.0721 31.7124C22.0027 31.7512 19.947 31.3729 18.0312 30.6008V26.8895C18.8886 27.3092 19.7881 27.6397 20.7151 27.8756C21.6556 28.1256 22.6249 28.2552 23.599 28.2611C24.7349 28.3435 25.8697 28.0874 26.856 27.526C27.2164 27.2505 27.5011 26.8903 27.6838 26.4784C27.8666 26.0666 27.9417 25.6161 27.9022 25.1683C27.9314 24.7451 27.8319 24.3228 27.6164 23.9556C27.4008 23.5883 27.0791 23.2927 26.6922 23.1065C25.4759 22.6309 24.1681 22.4258 22.8621 22.5059H21.2518V19.1621H22.8712C24.0911 19.2423 25.3124 19.0268 26.4284 18.5346C26.8011 18.3157 27.1037 17.9978 27.3016 17.6173C27.4995 17.2368 27.5847 16.8091 27.5474 16.3832C27.5474 14.8233 26.5558 14.0434 24.5725 14.0434C23.8613 14.0493 23.1553 14.1642 22.48 14.3841C21.6406 14.6695 20.8424 15.0612 20.1055 15.5495L18.0676 12.5464C20.0619 11.1658 22.4546 10.4554 24.8909 10.5204C26.6963 10.4227 28.4883 10.8767 30.022 11.8203C30.6229 12.2116 31.1118 12.7485 31.4416 13.3796C31.7713 14.0106 31.931 14.7146 31.9052 15.424Z" fill="#DBDBDB"/>
    <path opacity="0.3" d="M47.8987 7.44557C44.1872 2.86212 39.1425 -0.493858 33.4407 -2.17252C27.739 -3.85118 21.6527 -3.77229 15.9978 -1.94644C10.3428 -0.120588 5.38939 3.36498 1.80152 8.04303C-1.78635 12.7211 -3.83722 18.3681 -4.07617 24.227C0.254857 27.2362 5.23936 29.207 10.4801 29.9822C18.4087 31.1423 26.499 29.5212 33.3382 25.4021C40.1774 21.283 45.3309 14.9275 47.8987 7.44557Z" fill="url(#paint0_linear)"/>
    </g>
    <rect x="-1105.5" y="-233.5" width="1768" height="796" rx="4.5" stroke="#7B61FF" strokeDasharray="10 5"/>
    <defs>
    <linearGradient id="paint0_linear" x1="14.8651" y1="2.82888" x2="27.4007" y2="26.6755" gradientUnits="userSpaceOnUse">
    <stop stopColor="white" stopOpacity="0"/>
    <stop offset="0.35" stopColor="white" stopOpacity="0.01"/>
    <stop offset="0.49" stopColor="white" stopOpacity="0.03"/>
    <stop offset="0.59" stopColor="white" stopOpacity="0.08"/>
    <stop offset="0.67" stopColor="white" stopOpacity="0.15"/>
    <stop offset="0.74" stopColor="white" stopOpacity="0.24"/>
    <stop offset="0.8" stopColor="white" stopOpacity="0.35"/>
    <stop offset="0.85" stopColor="white" stopOpacity="0.47"/>
    <stop offset="0.9" stopColor="white" stopOpacity="0.62"/>
    <stop offset="0.95" stopColor="white" stopOpacity="0.79"/>
    <stop offset="1" stopColor="white"/>
    </linearGradient>
    <clipPath id="clip0">
    <rect width="50" height="50" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);

export default IconThirdCategory;
