import { menuItemClasses, SxProps } from '@mui/material';
import { getAccentColor, NeutralColors } from 'portfolio3/styles';

export const styles: SxProps = () => {
  return {
    display: 'flex',
    gap: '8px',
    borderRadius: '10px',
    padding: '6px 8px',

    '& .avatar': {
      borderRadius: '50%',
      width: '32px',
      height: '32px',
    },

    [`&:hover, &.${menuItemClasses.focusVisible}`]: {
      backgroundColor: NeutralColors.light_neutrals_300,
    },

    [`&.${menuItemClasses.root}.${menuItemClasses.selected}`]: {
      backgroundColor: getAccentColor('indigo', '100'),
    },
  };
};
