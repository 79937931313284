import { getAccentColor } from 'portfolio3/styles';
import { getButtonStyles } from 'portfolio3/ui-kit/utils';
import { SxStyles } from 'types';

export const clickableTitleStyles: SxStyles = {
  ...getButtonStyles(),

  width: 'fit-content',
  textAlign: 'left',
  wordBreak: 'break-word',

  '&:hover': {
    color: getAccentColor('indigo', '200'),
  },
};
