import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import BackArrow from 'portfolio3/components/common/buttons/BackArrow';
import { EmployeeSubheader } from 'portfolio3/components/common/headers';
import { Button } from 'portfolio3/ui-kit/button';

import { IconEyeFilled } from '../../../icons';

interface IViewHeaderProps {
  firstName: string;
  lastName: string;
  isParentView: boolean;
  onSetParentView: () => void;
  onClose: () => void;
}

const ViewHeader: FC<IViewHeaderProps> = ({ firstName, lastName, isParentView, onSetParentView, onClose }) => {
  const title = isParentView
    ? `Так видит родитель портфолио учащегося ${firstName} ${lastName}`
    : `Портфолио ${firstName} ${lastName}`;

  const titleElement = (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
      <BackArrow size="medium" onClick={onClose} />
      <Typography variant="Headings/H5">{title}</Typography>
    </Box>
  );

  const buttonElement = !isParentView && (
    <Button variant="secondary" iconLeft={<IconEyeFilled />} onClick={onSetParentView}>
      Что видит родитель
    </Button>
  );

  return <EmployeeSubheader title={titleElement} rightElement={buttonElement} sx={{ alignItems: 'center' }} />;
};

export default ViewHeader;
