import { IDictionaryItem } from 'api/types';
import { capitalize } from 'lodash';
import { IRootState } from 'reducers';
import { createSelector } from 'reselect';

const egeExamSubjectsSelector = createSelector(
  [(state: IRootState) => state.egeExamSubjectsRef],
  (egeExamSubjectsRef): IDictionaryItem[] => {
    const egeExamSubjects = egeExamSubjectsRef.content ?? [];

    return egeExamSubjects.map((egeSubject): IDictionaryItem => {
      return {
        code: egeSubject.id,
        value: capitalize(egeSubject.title),
      };
    });
  },
);

export default egeExamSubjectsSelector;
