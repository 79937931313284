import { SxStyles } from 'types';

export const sectionContainer: SxStyles = {
  display: 'flex',
  gap: '8px',

  svg: {
    width: '20px',
    height: '20px',
  },
};

export const statusContainer: SxStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  gap: '24px',
};

export const statusChartContainer: SxStyles = {
  display: 'flex',
  gap: '8px',
};
