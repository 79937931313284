/* eslint-disable prettier/prettier */
/* eslint-disable */

export enum LocationStepKeys {
  ONE = 'one',
  DOUBLE = 'double',
  TRIPPLE = 'tripple',
}

export const LocationStep: Record<string, string> = {
  [LocationStepKeys.ONE]: 'one',
  [LocationStepKeys.DOUBLE]: 'double',
  [LocationStepKeys.TRIPPLE]: 'tripple'
};

export const LocationStepValue: Record<string, number> = {
  [LocationStepKeys.ONE]: -1,
  [LocationStepKeys.DOUBLE]: -2,
  [LocationStepKeys.TRIPPLE]: -3
};
