import { FAILURE, INVALIDATE, REQUEST, SUCCESS } from 'actions/utils';
import { ICommonResponseObject, IMosRuPaginatedResponse, IVuz } from 'api/types';
import { IReducers } from 'reducers';
import genericReducers from 'reducers/genericReducer';
import { connectReducers } from 'utils';

import { GET_VUZ_LIST } from '../actions';

export type VuzListState = ICommonResponseObject<IMosRuPaginatedResponse<IVuz>>;

const initialState: VuzListState = {
  content: {
    page: 0,
    per_page: 0,
    pages: 0,
    items: [],
  },
  loading: false,
};

const vuzList: IReducers<VuzListState> = {
  [GET_VUZ_LIST[REQUEST]]: genericReducers().get.request,
  [GET_VUZ_LIST[SUCCESS]]: genericReducers().get.success,
  [GET_VUZ_LIST[FAILURE]]: genericReducers().get.failure,
  [GET_VUZ_LIST[INVALIDATE]]: genericReducers().get.invalidate(initialState),
};

export default connectReducers(initialState, vuzList);
