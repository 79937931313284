import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { Box, useMediaQuery } from '@mui/material';
import { saveAvatarSettingsActions } from 'actions';
import { AvatarTypeRef } from 'const';
import { useAppSelector, useAvatar } from 'hooks';
import { ProfileInfoAvatar } from 'portfolio3/features/avatar';
import { commonTheme } from 'portfolio3/styles/theme';

import ThemeAvatarPreview from '../../ThemeAvatarPreview';
import PanelSection from '../PanelSection';
import * as styles from './styles';

const AvatarSection: FC = () => {
  const dispatch = useDispatch();
  const currentStudent = useAppSelector((state) => state.currentStudent);

  const { avatarType, availableAvatarTypes } = useAvatar();
  const { meshId } = currentStudent;
  const isSmallScreen = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const handleChangeAvatarType = (type: number) => {
    dispatch(saveAvatarSettingsActions.request(meshId, type));
  };

  // оставляем только нестандартные аватарки
  const nonDefaultRefTypes = availableAvatarTypes.filter((ref) => ref.code !== AvatarTypeRef.default);
  const isAvatarSectionVisible = nonDefaultRefTypes.length > 0;

  if (!isAvatarSectionVisible) return null;

  return (
    <PanelSection title="Аватар" titleVariant="Headings/H6" sx={styles.avatarSection}>
      <Box sx={styles.avatarsGrid}>
        {availableAvatarTypes.map((ref) => {
          const { code } = ref;

          return (
            <ThemeAvatarPreview key={code} isActive={avatarType === code} onClick={() => handleChangeAvatarType(code)}>
              <ProfileInfoAvatar avatarSize={isSmallScreen ? 64 : 96} avatarTypeOverride={code} />
            </ThemeAvatarPreview>
          );
        })}
      </Box>
    </PanelSection>
  );
};

export default AvatarSection;
