import { SectionCodes, SourceCode } from 'const';
import { IRootState } from 'reducers';
import { createSelector } from 'reselect';
import { isDefined } from 'utils';

const hasFirstMovementEntitySelector = createSelector(
  [(state: IRootState) => state.studentAffilations],
  (affilations) => {
    if (affilations.loading) return false;

    const currentDate = new Date();

    const hasFirstMovementAffilations = affilations.content.some((affilation) => {
      const { type, affilationKind, endDate, source } = affilation;

      const hasRequiredTypeCode = type.code === SectionCodes.civilAffilationMovement; // 51

      if (!hasRequiredTypeCode) return false;

      // Российское движение детей и молодежи «Движение первых»
      const hasRequiredAffilationKindCode = affilationKind?.code === 25;

      const isEndDateNull = endDate === null;
      const isEndDateAfterCurrentDate = isDefined(endDate) && new Date(endDate) > currentDate;

      const hasRequiredSourceCode = source.code === SourceCode.operator || source.code === SourceCode.firstMovement;

      return hasRequiredSourceCode && hasRequiredAffilationKindCode && (isEndDateNull || isEndDateAfterCurrentDate);
    });

    return hasFirstMovementAffilations;
  },
);

export default hasFirstMovementEntitySelector;
