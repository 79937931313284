/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { IconButton } from '@mui/material';

import { getEntityHeaderText, getLinkedEntityIcon, getEntityColors } from '../../../../utils';
import { ILinkablePersonObject } from 'portfolio3/features/dataEntryForm';
import { ILinkedSelectorController } from './types';
import { SectionCodes } from '../../../../const';
import { iconClose } from '../../../../icons';
import { CommonStudentLinkedSelect } from '../../../../ui-kit/select/AutoComplete';

import './index.scss';

interface IStudentLinkedItemSelectorProps {
  objectController: ILinkedSelectorController,
  options: ILinkablePersonObject[],
  values: ILinkablePersonObject[],
}

const StudentLinkedItemSelector: React.FC<IStudentLinkedItemSelectorProps> = ({
  objectController,
  options,
  values,
}) => {
  const filteredOptions = options.filter((option) => {
    return !values.find((value) => value.code === option.code);
  });

  return (
    <div className="student-linked-objects">
      <CommonStudentLinkedSelect
        disableClearable
        options={filteredOptions}
        onChange={(event: any, value: ILinkablePersonObject) => { objectController.handleChange(value, values.length); }}
        loading={!options?.length}
        placeholder="Начните вводить название достижения, с которым необходимо установить связь..."
        label="Достижение для связи"
      />
      {values.length > 0 && (
        <div className="student-linked-objects__container">
          {values.map((item, index) => {
            if (!item) return;

            const {
              format, categoryCode, typeCode, code, value
            } = item;
            const objectColors = getEntityColors(categoryCode || SectionCodes.science);
            const entityTypeValue = getEntityHeaderText(typeCode);
            const objectFeatures: any[] = [
              entityTypeValue && { text: entityTypeValue, isColored: true },
              format && { text: format }
            ];
            return item && (
              <div className="linked-object" key={code}>
                <div className="linked-object__indicator" style={{ backgroundColor: objectColors?.background }}>
                  {getLinkedEntityIcon(typeCode)}
                </div>
                <div className="linked-object__info">
                  <p className="linked-object__name">{value}</p>
                  <div className="linked-object__features-container">
                    {objectFeatures.filter((feature) => feature).map((feature) => (
                      <p key={feature?.text} className="linked-object__feature" style={feature?.isColored ? { backgroundColor: objectColors?.background, color: objectColors?.text } : undefined}>{feature?.text}</p>
                    ))}
                  </div>
                </div>
                <IconButton
                  className="linked-object__open-btn"
                  onClick={() => objectController.handleDeleteField(index)}
                  size="large">
                  <img src={iconClose} alt="закрыть" />
                </IconButton>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default React.memo(StudentLinkedItemSelector);
