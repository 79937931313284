import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconShareFilled: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M18 15.0005C17.183 15.0005 16.443 15.3305 15.901 15.8615L7.966 12.3355C7.979 12.2245 8 12.1145 8 12.0005C8 11.8865 7.979 11.7765 7.966 11.6655L15.901 8.13949C16.443 8.67049 17.183 9.00049 18 9.00049C19.654 9.00049 21 7.65449 21 6.00049C21 4.34649 19.654 3.00049 18 3.00049C16.346 3.00049 15 4.34649 15 6.00049C15 6.11449 15.021 6.22449 15.034 6.33549L7.099 9.86149C6.557 9.33049 5.817 9.00049 5 9.00049C3.346 9.00049 2 10.3465 2 12.0005C2 13.6545 3.346 15.0005 5 15.0005C5.817 15.0005 6.557 14.6705 7.099 14.1395L15.034 17.6655C15.021 17.7765 15 17.8865 15 18.0005C15 19.6545 16.346 21.0005 18 21.0005C19.654 21.0005 21 19.6545 21 18.0005C21 16.3465 19.654 15.0005 18 15.0005Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconShareFilled;
