/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';

import {
  FAILURE,
  REQUEST,
  SUCCESS
} from '../../actions/utils';

import { ICommonResponse, IDictionaryItem } from '../../api/types';

import genericReducers from '../genericReducer';
import { GET_SETTINGS_SECTIONS } from '../../actions';

import { IReducers } from '..';

export type SettingSectionsState = ICommonResponse<IDictionaryItem>;

const initialState = {
  content: [],
  loading: false,
} as ICommonResponse<IDictionaryItem>;

const settingSections: IReducers<SettingSectionsState> = {
  [GET_SETTINGS_SECTIONS[REQUEST]]: genericReducers().get.request,
  [GET_SETTINGS_SECTIONS[SUCCESS]]: genericReducers().get.success,
  [GET_SETTINGS_SECTIONS[FAILURE]]: genericReducers().get.failure
};

export default connectReducers(initialState, settingSections);
