import { IInputTokens, InputRenderModeKeys, InputSize, InputSizeKeys } from '../types';

export const InputHeight: Record<InputSizeKeys, number> = {
  [InputSizeKeys.SMALL]: 36,
  [InputSizeKeys.MEDIUM]: 40,
  [InputSizeKeys.LARGE]: 48,
};

export const InputFontSize: Record<InputSizeKeys, number> = {
  [InputSizeKeys.SMALL]: 14,
  [InputSizeKeys.MEDIUM]: 16,
  [InputSizeKeys.LARGE]: 16,
};

export const InputFontLineHeight: Record<InputSizeKeys, number> = {
  [InputSizeKeys.SMALL]: 20,
  [InputSizeKeys.MEDIUM]: 24,
  [InputSizeKeys.LARGE]: 24,
};

export const InputBlockPadding: Record<InputSizeKeys, number> = {
  [InputSizeKeys.SMALL]: 8,
  [InputSizeKeys.MEDIUM]: 8,
  [InputSizeKeys.LARGE]: 12,
};

export const InputLeftPadding: Record<InputSizeKeys, number> = {
  [InputSizeKeys.SMALL]: 12,
  [InputSizeKeys.MEDIUM]: 16,
  [InputSizeKeys.LARGE]: 16,
};

export const InputRightPadding: Record<InputSizeKeys, number> = {
  [InputSizeKeys.SMALL]: 16,
  [InputSizeKeys.MEDIUM]: 16,
  [InputSizeKeys.LARGE]: 16,
};

const getInputTokens = (size: InputSize): IInputTokens => {
  return {
    minHeight: InputHeight[size],
    fontSize: InputFontSize[size],
    lineHeight: InputFontLineHeight[size],
    paddingBlock: InputBlockPadding[size],
    paddingLeft: InputLeftPadding[size],
    paddingRight: InputRightPadding[size],
  };
};

export const InputTokens: Record<InputRenderModeKeys, IInputTokens> = {
  [InputRenderModeKeys.DESKTOP]: getInputTokens('large'),
  [InputRenderModeKeys.MOBILE]: getInputTokens('large'),
};
