import { FC } from 'react';

import MesUiGlobalFooter from '@mes-ui/global-footer';
import { Box } from '@mui/material';
import { useBuildConfig } from 'hooks';

import '@mes-ui/global-footer/styles.css';

const GlobalFooter: FC = () => {
  const config = useBuildConfig();

  return (
    <Box
      className="global-footer-container"
      sx={{
        // удаление ненужных стилей с футера
        '& > *': {
          border: 'none',
        },
        '& > * > *': {
          paddingInline: 'unset',
          background: 'transparent',
        },
      }}
    >
      <MesUiGlobalFooter version={config?.VERSION} />
    </Box>
  );
};

export default GlobalFooter;
