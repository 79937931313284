/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { Polar } from '@reactchartjs/react-chart.js';
import { ColorKeys, Colors } from '../../../style/variables';

import './index.scss';

interface IPolarAreaChartProps {
  average?: number | string;
  labels: string[];
  data: number[];
  colors: string[];
  min: number;
  max: number;
  stepSize: number;
}

const PolarAreaChart: React.VFC<IPolarAreaChartProps> = ({
  average,
  labels,
  data,
  colors,
  min,
  max,
  stepSize
}) => {

  const chartData = {
    labels: labels,
    datasets: [
      {
        data: data,
        hoverBackgroundColor: colors,
        backgroundColor: colors
      }
    ],
  };

  const chartOptions = {
    // https://github.com/chartjs/Chart.js/issues/5907
    startAngle: 2 * Math.PI * 1891 - Math.PI / 2,
    maintainAspectRatio: false,
    elements: {
      arc: {
        borderColor: Colors[ColorKeys.DISABLE_INPUT],
        hoverBorderColor: Colors[ColorKeys.DISABLE_INPUT],
        borderAlign: 'center',
        borderWidth: 1
      }
    },
    legend: {
      display: false
    },
    scale: {
      display: true,
      gridLines: {
        display: true,
        color: Colors[ColorKeys.WHITE],
        z: 1
      },
      angleLines: {
        display: true,
        lineWidth: 1,
        color: Colors[ColorKeys.DISABLE_INPUT]
      },
      ticks: {
        display: false,
        min,
        max,
        stepSize
      },
    },
    animation: {
      animateRotate: false
    },
    tooltips: {
      enabled: false
    },
    plugins: {
      datalabels: {
        display: false
      }
    }
  };

  return (
    <div className="polar-area-chart">
      <Polar
        data={chartData}
        options={chartOptions}
      />
      <div className="polar-area-chart__average">
        {average?.toString().replace('.', ',')}
      </div>
    </div>
  );
};

export default PolarAreaChart;
