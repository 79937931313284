export enum EntityType {
  EMPLOYMENT = 'Employment',
  EVENT = 'Event',
  REWARD = 'Reward',
  SPORT_REWARD = 'SportReward',
  PROJECT = 'Project',
  AFFILATION = 'Affilation',
  GIA_WORLDSKILLS = 'GIAWorldskills',
  OLYMPIAD = 'Olympiad',
  CULTURAL_EVENT = 'CulturalEvent',
  GOVEXAM = 'Govexam',
  INDEPENDENT_DIAGNOSTIC = 'IndependentDiagnostic',
  ADDITIONAL_EDUCATION = 'AdditionalEducation', // Профподготовка
  DOCUMENT = 'Document',
  JOB = 'Job', // Профессия/Трудоустройство
  TRAINING_INFO = 'TrainingInfo', // Учеба/Сведения об обучении
  PRACTICE = 'Practice', // Учеба/Практика

  // METASKILL = 'Metaskill',
  // SPO_STATUS = 'SPOStatus',
}

export const EntityTypeNames: Record<EntityType, string> = {
  [EntityType.EMPLOYMENT]: 'employment',
  [EntityType.EVENT]: 'event',
  [EntityType.REWARD]: 'reward',
  [EntityType.SPORT_REWARD]: 'sport-reward',
  [EntityType.PROJECT]: 'project',
  [EntityType.AFFILATION]: 'affilation',
  [EntityType.GIA_WORLDSKILLS]: 'gia-worldskills',
  [EntityType.OLYMPIAD]: 'olympiad',
  [EntityType.CULTURAL_EVENT]: 'cultural-event',
  [EntityType.GOVEXAM]: 'govexam',
  [EntityType.INDEPENDENT_DIAGNOSTIC]: 'independent-diagnostic',
  [EntityType.ADDITIONAL_EDUCATION]: 'additional-education',
  [EntityType.DOCUMENT]: 'document',
  [EntityType.JOB]: 'job',
  [EntityType.TRAINING_INFO]: 'training-info',
  [EntityType.PRACTICE]: 'practice',
};
