import React from 'react';

import { IconClose } from 'icons/edit';

import { commonBreakpoints } from '../../../../const';
import { useMediaQueryWidth } from '../../../../hooks';
import { IconPaperPlaneOutline } from '../../../../icons';
import { GroupedActionButton, GroupedActionButtonsContainer } from '../../GroupedActionButtons';
import { IThanksFormData } from '../types';
import { isSubmitEnabled } from '../utils';

interface IThanksActionButtonsProps {
  formData: IThanksFormData;
  studentClassLevel: number;
  onExitBack: () => void;
  onSubmit: () => void;
}

const ThanksActionButtons: React.FC<IThanksActionButtonsProps> = ({
  formData,
  studentClassLevel,
  onExitBack,
  onSubmit,
}) => {
  const isSmallScreen = useMediaQueryWidth(commonBreakpoints.breakpointSm, 'down');
  const isSubmitDisabled = !isSubmitEnabled(formData, studentClassLevel);

  return (
    <GroupedActionButtonsContainer disableAbsolutePosition>
      {!isSmallScreen && (
        <GroupedActionButton variant="secondary" startIcon={IconClose} handleClick={onExitBack}>
          Отмена
        </GroupedActionButton>
      )}
      <GroupedActionButton
        variant="primary"
        startIcon={IconPaperPlaneOutline}
        handleClick={onSubmit}
        disabled={isSubmitDisabled}
      >
        Отправить
      </GroupedActionButton>
    </GroupedActionButtonsContainer>
  );
};

export default ThanksActionButtons;
