import {
  LINK_SHARING_DRAWER_SET_OPEN,
  LINK_SHARING_SET_CURRENT_LINK,
  LINK_SHARING_SET_LINK_QR_CODE_OPEN,
  LINK_SHARING_SET_LINK_URL_OPEN,
} from 'actions';
import { IReducers } from 'reducers';
import { connectReducers } from 'utils';

export type LinkSharingDrawerState = {
  isOpen: boolean;
  isLinkUrlOpen: boolean;
  isLinkQrCodeOpen: boolean;
  currentLinkUrl?: string;
  currentQrCode?: string;
};

type SetOpenAction = { payload: boolean };
type SetCurrentLinkAction = { payload: { linkUrl?: string; qrCode?: string } };

const initialState: LinkSharingDrawerState = {
  isOpen: false,
  isLinkUrlOpen: false,
  isLinkQrCodeOpen: false,
};

const linkSharingDrawer: IReducers<LinkSharingDrawerState> = {
  [LINK_SHARING_DRAWER_SET_OPEN]: (state, { payload }: SetOpenAction) => {
    return {
      ...state,
      isOpen: payload,
    };
  },
  [LINK_SHARING_SET_LINK_URL_OPEN]: (state, { payload }: SetOpenAction) => {
    return {
      ...state,
      isLinkUrlOpen: payload,
    };
  },
  [LINK_SHARING_SET_LINK_QR_CODE_OPEN]: (state, { payload }: SetOpenAction) => {
    return {
      ...state,
      isLinkQrCodeOpen: payload,
    };
  },
  [LINK_SHARING_SET_CURRENT_LINK]: (state, { payload }: SetCurrentLinkAction) => {
    return {
      ...state,
      currentLinkUrl: payload.linkUrl,
      currentQrCode: payload.qrCode,
    };
  },
};

export default connectReducers(initialState, linkSharingDrawer);
