export enum MarkType {
  AVERAGE = 1,
  FINAL = 2,
}

export enum VisibilityType {
  DIAGRAM = 1,
  TILES = 2,
  CHART = 3,
}

export interface IMappedLearningYearPerformance {
  year: string;
  averageGrade: number;
  educationLevel: string;
  subjects: {
    id: number;
    name: string;
    grade: number;
    gradeDifference: number | null;
    year: string;
  }[];
}

export interface ISubjectTheme {
  name: string;
  passedLessons: number;
  totalLessons: number;
  averageGrade?: number;
}
