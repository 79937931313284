import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconFunnelOutline: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="m10.877 17.457 2.026 1.533v-4.553c0-.166.042-.329.12-.475L17.323 6H6.643l4.122 7.978c.074.142.112.3.112.459v3.02ZM13.903 22a.994.994 0 0 1-.603-.203l-4.026-3.045a1 1 0 0 1-.397-.797v-3.274L4.112 5.459A1 1 0 0 1 5 4h14a1 1 0 0 1 .88 1.475l-4.977 9.215V21a1 1 0 0 1-1 1Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconFunnelOutline;
