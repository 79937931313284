/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { IDiagnosticBestResult, ITotalDiagnostic } from '../../../api/types';
import DiagramDoughnut from '../../common/DiagramDoughnut';
import DoughnutDiagnosticChart from '../../common/doughnutDiagnosticChart';

import './index.scss';

interface IndependentDiagnocticDoughnutStatsProps {
  totalDiagnostic: ITotalDiagnostic,
  bestResult: IDiagnosticBestResult
}

const IndependentDiagnocticDoughnutStats: React.FC<IndependentDiagnocticDoughnutStatsProps> = ({ totalDiagnostic, bestResult }) => {
  const markColors = ['#EDEDED', '#C6C6C6', '#A1A1A1', '#585858'];
  const {
    totalLevelBelowBase, totalLevelBase, totalLevelHight, totalLevelOverHight
  } = totalDiagnostic.totalLevelType;
  const markData = {
    incomingData: [totalLevelBelowBase, totalLevelBase, totalLevelOverHight, totalLevelHight].map((value) => Number(value)),
    incomingLabels: ['ниже базового', 'базовый', 'повышенный', 'высокий']
  };

  let valueProgressBar = 0;
  const normalizedMarkValue = bestResult.bestResultPercent || 0;
  const normalizedMarkBasis = 100;
  const mark = normalizedMarkValue;

  if (normalizedMarkValue) {
    valueProgressBar = normalizedMarkBasis ? Number(normalizedMarkValue) * 100 / normalizedMarkBasis : Number(normalizedMarkValue);
  }

  const doughnutData = {
    incomingData: [valueProgressBar, 100 - valueProgressBar],
    incomingLabels: ['1', '2']
  };

  return (
    <div className="pdf-doughnut-stats pdf-keep">
      <div className="pdf-doughnut-stats__completed completed">
        <h3 className="completed__title">Пройденные независимые диагностики</h3>
        <div className="completed__body">
          <div className="completed__chart">
            <DoughnutDiagnosticChart colors={markColors} data={markData} />
          </div>
          <div className="completed__info">
            <div className="completed__info-item">
              <div className="completed__color-tip" style={{ backgroundColor: markColors[0] }} />
              <span className="completed__rank">ниже базового</span>
              <span className="completed__result">{markData.incomingData[0]}</span>
            </div>
            <div className="completed__info-item">
              <div className="completed__color-tip" style={{ backgroundColor: markColors[1] }} />
              <span className="completed__rank">базовый уровень</span>
              <span className="completed__result">{markData.incomingData[1]}</span>
            </div>
            <div className="completed__info-item">
              <div className="completed__color-tip" style={{ backgroundColor: markColors[2] }} />
              <span className="completed__rank">повышенный уровень</span>
              <span className="completed__result">{markData.incomingData[2]}</span>
            </div>
            <div className="completed__info-item">
              <div className="completed__color-tip" style={{ backgroundColor: markColors[3] }} />
              <span className="completed__rank">высокий уровень</span>
              <span className="completed__result">{markData.incomingData[3]}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="pdf-doughnut-stats__aside">
        <div className="pdf-doughnut-stats__best-percent best-percent">
          <span className="best-percent__title">Лучший результат в процентах</span>
          <span className="best-percent__result">
            {normalizedMarkValue}%
          </span>
          <div className="best-percent__chart">
          <DiagramDoughnut total={mark !== null ? mark : '-'} incomingData={doughnutData.incomingData} incomingLabels={doughnutData.incomingLabels} color="#B9B9B9" />
          </div>
        </div>
        <div className="pdf-doughnut-stats__best-subject best-subject">
          <span className="best-subject__title">Лучший результат по предмету</span>
          <span className="best-subject__result">{bestResult.bestResultSubject}</span>
        </div>
      </div>
    </div>
  );
};

export default IndependentDiagnocticDoughnutStats;
