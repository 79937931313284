/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';
import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { IconCloseAutocomplete, IconMobileSelectArrow, IconSelectArrow } from '../../../icons';
import { Colors } from '../../../style/variables';

import './index.scss';
import useClientWidth from '../../../hooks/useClientWidth';

const useStyles = makeStyles({
  buttonSelect: {
    '&.Mui-focused': {
      '& .achievement-form_combo': {
        borderColor: Colors['blue-primrose']
      }
    },
    '& .MuiSvgIcon-root': {
      width: '12px',
      height: '6px'
    },
    '& .MuiAutocomplete-clearIndicator': {
      marginRight: '10px',
      '& svg': {
        width: '10px',
        height: '10px'
      }
    },
    '& .MuiIconButton-root': {
      padding: 0
    }

  },
  textField: {
    '&.achievement-form_combo': {
      marginTop: 0,
      boxSizing: 'border-box',
      border: `1px solid ${Colors['control-stroke']}`,
      borderRadius: '8px',
      outline: 'none',

      '& .MuiAutocomplete-endAdornment': {
        top: 'calc(50% - 12px)',
        right: '14px'
      },

      '& .MuiAutocomplete-inputRoot': {
        padding: '8px 16px',
        paddingRight: '39px',

        '& input': {
          padding: 0
        },
        '& fieldSet': {
          border: 'none'
        }
      },
      '& input': {
        '&::-webkit-input-placeholder': {
          color: Colors['disable-text'],
          opacity: 1
        },
        '&::-moz-placeholder': {
          color: Colors['disable-text'],
          opacity: 1
        },
        '&:-moz-placeholder': {
          color: Colors['disable-text'],
          opacity: 1
        },
        '&:-ms-input-placeholder': {
          color: Colors['disable-text'],
          opacity: 1
        },
        '&:focus::-webkit-input-placeholder': {
          color: 'transparent'
        },
        '&:focus::-moz-placeholder': {
          color: 'transparent'
        },
        '&:focus:-moz-placeholder': {
          color: 'transparent'
        },
        '&:focus:-ms-input-placeholder': {
          color: 'transparent'
        }
      },
      '& textarea': {
        '&::-webkit-input-placeholder': {
          color: Colors['disable-text'],
          opacity: 1
        },
        '&::-moz-placeholder': {
          color: Colors['disable-text'],
          opacity: 1
        },
        '&:-moz-placeholder': {
          color: Colors['disable-text'],
          opacity: 1
        },
        '&:-ms-input-placeholder': {
          color: Colors['disable-text'],
          opacity: 1
        },
        '&:focus::-webkit-input-placeholder': {
          color: 'transparent'
        },
        '&:focus::-moz-placeholder': {
          color: 'transparent'
        },
        '&:focus:-moz-placeholder': {
          color: 'transparent'
        },
        '&:focus:-ms-input-placeholder': {
          color: 'transparent'
        }
      },
      '& .MuiInput-underline:before': {
        display: 'none'
      },
      '& .MuiInput-underline:after': {
        display: 'none'
      },
      '& .MuiIconButton-label': {
        color: Colors['blue-primrose']
      },
      '& .MuiInputBase-input': {
        padding: '8px 16px'
      },
      '& .MuiOutlinedInput-notchedOutline': {
        display: 'none'
      }
    }
  },
  icon: {
    width: '12px',
    height: '6px'
  },
  mobileIcon: {
    width: '20px',
    height: '20px'
  },
  popper: {
    display: 'flex',
    'word-wrap': 'break-word',
    'flex-wrap': 'wrap',
    'word-break': 'break-word',
    width: '100%'
  }
});

interface IButtonSelectProps {
  options: any,
  placeholder: string,
  value?: any,
  label?: string,
  onChange?: any,
  loading?: boolean,
  disableClearable?: boolean,
}

const CommonEmployeeLinkedSelect: React.FC<IButtonSelectProps> = ({
  value,
  label,
  options,
  onChange,
  loading,
  placeholder,
  disableClearable,
  ...props
}) => {
  const isMobile = useClientWidth() <= 600;
  const classes = useStyles();
  return (
    <Autocomplete
      popupIcon={isMobile ? <IconMobileSelectArrow classes={{ root: classes.mobileIcon }} /> : <IconSelectArrow classes={{ root: classes.icon }} />}
      clearIcon={<IconCloseAutocomplete classes={{ root: classes.icon }} />}
      options={options}
      getOptionLabel={(object) => object?.value || ''}
      loading={loading}
      classes={{ root: classes.buttonSelect, popper: classes.popper }}
      loadingText="Загрузка"
      value={value}
      onChange={onChange}
      disableClearable={disableClearable}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={!isMobile ? placeholder : undefined}
          label={isMobile ? label : undefined}
          margin="none"
          variant="outlined"
          className="achievement-form_combo"
          classes={{ root: classes.textField }}
        />
      )}
      {...props}
    />
  );
};

export default CommonEmployeeLinkedSelect;
