/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';

import {
  FAILURE,
  INVALIDATE,
  REQUEST,
  SUCCESS
} from '../../actions/utils';

import genericReducers from '../genericReducer';
import { IReducers } from '..';
import { ICommonResponse, IStudentEvent } from '../../api/types';
import { GET_STUDENT_EVENTS } from '../../actions';

export type StudentEventsState = ICommonResponse<IStudentEvent>;

const initialState = {
  content: [],
  loading: false
} as ICommonResponse<IStudentEvent>;

const studentEvents: IReducers<StudentEventsState> = {
  [GET_STUDENT_EVENTS[REQUEST]]: genericReducers().get.request,
  [GET_STUDENT_EVENTS[SUCCESS]]: genericReducers().get.success,
  [GET_STUDENT_EVENTS[FAILURE]]: genericReducers().get.failure,
  [GET_STUDENT_EVENTS[INVALIDATE]]: genericReducers().get.invalidate(initialState),
};

export default connectReducers(initialState, studentEvents);
