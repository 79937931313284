import { EntityType } from '../../../const';

export enum FormStep {
  SELECT_ERROR_TYPE,
  SUBMIT_ERROR,
}

export interface IInitialReportErrorFormData {
  entityName: string;
  categoryName: string;
  entityType: EntityType;
  entityId?: string;
  recordId?: string;
}

export interface IReportErrorFormData extends IInitialReportErrorFormData {
  errorTypeCode?: number;
  errorGeneralMessageChecked?: boolean;
  errorFileMetadataMessageChecked?: boolean;
  errorChildEntityMessageChecked?: boolean;
  errorGeneralMessage?: string;
  errorFileMetadataMessage?: string;
  errorChildEntityMessage?: string;
}

export type CardDetailsChangeHandler = (isChecked: boolean, value: string | undefined) => void;

export interface ICardDetailsController {
  general: CardDetailsChangeHandler;
  fileMetadata: CardDetailsChangeHandler;
  childEntity: CardDetailsChangeHandler;
}
