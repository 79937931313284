import { menuItemClasses, svgIconClasses } from '@mui/material';
import { IStatusColors } from 'const';
import { getAccentColor, NeutralColors } from 'portfolio3/styles';
import { getButtonStyles } from 'portfolio3/ui-kit/utils';
import { SxStyles } from 'types';
import { inheritSx } from 'utils';

export const statusSelector =
  ({ text, background, border: borderColor }: IStatusColors, isMobile?: boolean, isOpen?: boolean): SxStyles =>
  () => {
    const padding = isMobile ? '5px 12px' : '3px 8px';
    const border = borderColor && isOpen ? `1px solid ${borderColor}` : '1px solid transparent';

    return {
      ...inheritSx([getButtonStyles()]),

      display: 'flex',
      alignItems: 'center',
      gap: '4px',

      padding,

      border,
      borderRadius: isMobile ? '12px' : '8px',
      backgroundColor: background,

      color: text,
      boxShadow: isMobile ? '0px 3px 6px -1px rgba(41, 41, 64, 0.08)' : 'none',
      filter: isMobile ? 'drop-shadow(1px 0px 5px rgba(41, 41, 64, 0.04))' : 'none',
    };
  };

export const adornment: SxStyles = () => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  [`.${svgIconClasses.root}, .proftech-checkbox, .proftech-round-box`]: {
    width: '16px',
    height: '16px',
  },
});

export const menuItem: SxStyles = () => ({
  [`#status-selector-menu &.${menuItemClasses.root}.${menuItemClasses.selected}`]: {
    backgroundColor: getAccentColor('indigo', '100'),
  },
});

export const menuItemText =
  (isSelected?: boolean): SxStyles =>
  () => ({
    color: isSelected ? NeutralColors.light_neutrals_0 : NeutralColors.light_neutrals_900,
  });

export const menuIcon: SxStyles = () => ({
  width: '18px',
  height: '18px',
});

export const personalData: SxStyles = () => ({
  width: '724px',
});

export const modalHeader: SxStyles = () => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  padding: '20px',
  borderBottom: '1px solid #E8E8EF',
});

export const modalContent: SxStyles = () => ({
  background: 'white',

  width: '100%',
  minHeight: '160px',
  margin: 'auto',
});

export const modalFooter: SxStyles = () => ({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  position: 'relative',

  borderTop: '1px solid #E8E8EF',
  padding: '15px 25px 10px 0',

  background: '#FFFFFF',
});

export const buttonsGroup: SxStyles = () => ({
  display: 'flex',
  justifyContent: 'space-between',

  width: '200px',
});

export const modalText: SxStyles = () => ({
  paddingTop: '15px',
  paddingLeft: '25px',
});

export const modalTextDownload: SxStyles = () => ({
  paddingLeft: '25px',
  paddingTop: '10px',

  color: '#364FC7',

  cursor: 'pointer',
});

export const briefCase: SxStyles = () => ({
  width: '95px',
  height: '95px',

  transform: 'rotate(-20deg)',

  position: 'absolute',
  bottom: '47px',
  right: '14px',
});

export const officeBuilding: SxStyles = () => ({
  width: '70px',
  height: '70px',

  position: 'absolute',
  bottom: '30px',
  right: '57px',
});
