/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { Doughnut } from '@reactchartjs/react-chart.js';

import './index.scss';

interface IDoughnutChartProps {
  colors: string[]
  data: any
  noMobileHeader?: boolean;
}

const DoughnutDiagnosticChart: React.FC<IDoughnutChartProps> = ({ colors, data, noMobileHeader }) => {
  const { incomingData, incomingLabels } = data;
  const cutOutValue = incomingData.length === 2 ? 65 : 70;
  const total = incomingData.reduce((partialSum: number, nextNumber: number) => partialSum + nextNumber, 0);

  const singleData = {
    labels: incomingLabels,
    datasets: [
      {
        label: 'число оценок',
        data: incomingData,
        backgroundColor: colors,
        hoverBackgroundColor: colors,
        borderWidth: 5,
        weight: 0.3
      }
    ]
  };

  const options = {
    responsice: true,
    maintainAspectRatio: false,
    cutoutPercentage: cutOutValue,
    legend: {
      display: false
    },
    plugins: {
      datalabels: {
        display: false,
        color: '#7B819B'
      }
    }
  };

  return (
    <div className="doughnut">
      <Doughnut data={singleData} options={options} type="doughnut" />
      <div className={`doughnut__header ${noMobileHeader ? 'doughnut__header--no-mobile' : ''}`}>
        <span className="doughnut__label">всего</span>
        <span className="doughnut__result">{total}</span>
      </div>
    </div>
  );
};

export default DoughnutDiagnosticChart;
