import { FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

import {
  clearAttachmentTemlpateAction,
  deleteAttachmentTemplateInfoActions,
  getAttachmentTemplateInfoActions,
  getAttachmentTemplateInfoPartitionActions,
  getImportTemplateActions,
  postAttachmentTemplateActions,
} from 'actions';
import {
  IAttachmentTemplate,
  ICommonResponse,
  IDictionaryItem,
  IImportListResponse,
  IPreviewData,
  IPutTemplateAttachmentResponse,
} from 'api/types';
import { importerChapters, SectionCodes, tooltipOptions } from 'const';
import { useLoadSectionRef } from 'hooks';
import { IconDownload } from 'icons';
import { TaskPanel } from 'portfolio3/features/employee';
import { toast } from 'portfolio3/ui-kit';
import { Button } from 'portfolio3/ui-kit/button';
import PopUp from 'portfolio3/ui-kit/modals/PopUp';
import { IRootState } from 'reducers';
import { availableEmployeeParentSectionsSelector } from 'selectors';
import { getImportDataKindOptions, getImportDataTypeOptions, getSampleSecondaryTypeCode } from 'utils';

import {
  DataTypeSelector,
  defaultDataTypeFormData,
  IDataTypeSelectorFormData,
} from '../../components/DataTypeSelector';
import ImportPopUp from '../ImportPopUp';
import ExcelDrop from './ExcelDrop';
import ExcelFile from './ExcelFile';
import ImportLoader from './ImportLoader';
import ImportPreview from './ImportPreview';
import ImportResult from './ImportResult';
import { IAttachmentError } from './types';

interface IExcelImporterProps {
  isSampleDialogOpen: boolean;
  isImportDialogOpen: boolean;
  onSampleDialogOpen: (isOpen: boolean) => void;
  onImportDialogOpen: (isOpen: boolean) => void;

  portfolioSections: ICommonResponse<IDictionaryItem>;
  attachmentTemplate: IAttachmentTemplate;
  importList: IImportListResponse;
  getImportTemplate: typeof getImportTemplateActions.request;
  getAttachmentTemplateInfo: typeof getAttachmentTemplateInfoActions.request;
  getAttachmentTemplatePartition: typeof getAttachmentTemplateInfoPartitionActions.request;
  postAttachmentTemplate: typeof postAttachmentTemplateActions.request;
  clearAttachmentTemplate: typeof clearAttachmentTemlpateAction;
  deleteAttachmentTemplateInfo: typeof deleteAttachmentTemplateInfoActions.request;
}

const ExcelImporter: FC<IExcelImporterProps> = ({
  isSampleDialogOpen,
  isImportDialogOpen,
  onSampleDialogOpen,
  onImportDialogOpen,

  portfolioSections,
  attachmentTemplate,
  importList,
  getImportTemplate,
  getAttachmentTemplateInfo,
  getAttachmentTemplatePartition,
  postAttachmentTemplate,
  clearAttachmentTemplate,
  deleteAttachmentTemplateInfo,
}) => {
  const history = useHistory();
  const [currentImporterChapter, setCurrentImporterChapter] = useState(importerChapters.FIRST);
  const [sampleDataTypeFormData, setSampleDataTypeFormData] =
    useState<IDataTypeSelectorFormData>(defaultDataTypeFormData);
  const [importerDataTypeFormData, setImporterDataTypeFormData] =
    useState<IDataTypeSelectorFormData>(defaultDataTypeFormData);
  const [isImportDialogLoading, setImportDialogLoading] = useState(false);
  const [excelAttachment, setExcelAttachment] = useState<File>();
  const [attachmentError, setAttachmentError] = useState<IAttachmentError>({
    active: false,
    text: '',
  });

  const [partitionLoading, setPartitionLoading] = useState(false);
  const [currentPartition, setCurrentPartition] = useState(1);

  useLoadSectionRef();

  const { correctRecordsCount, cacheUUID, incorrectRecordsCount, partitionsNumber } =
    attachmentTemplate.content as IPutTemplateAttachmentResponse;

  const hasMorePartitions = currentPartition < partitionsNumber;
  const canPreloadPartitions = currentImporterChapter === importerChapters.THIRD;

  useEffect(() => {
    setCurrentPartition(1);
  }, [cacheUUID]);

  useEffect(() => {
    if (!attachmentTemplate.partitionLoading) {
      setPartitionLoading(false);
      setCurrentPartition((prev) => prev + 1);
    }
  }, [attachmentTemplate.partitionLoading]);

  useEffect(() => {
    const currentImport = importList.content?.parseInfo.find((importItem) => importItem.uuid === cacheUUID);
    if (
      currentImport?.status === 'FAIL' &&
      (currentImporterChapter === importerChapters.THIRD || currentImporterChapter === importerChapters.FOURTH)
    ) {
      toast.error('Произошла ошибка во время загрузки', tooltipOptions);
      setImportDialogLoading(false);
      handleMoveChapter(-1);
    } else if (attachmentTemplate?.status === 'FAIL') {
      handleMoveChapter(-1);
      setImportDialogLoading(false);
    }
    if (
      (currentImport?.status === 'SUCCESS' && currentImporterChapter === importerChapters.FOURTH) ||
      (currentImport?.status === 'PREVIEW' && currentImporterChapter === importerChapters.THIRD)
    ) {
      if (currentPartition === 1) {
        getAttachmentTemplateInfo(currentImport.uuid);
      }
      setImportDialogLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importList, cacheUUID, attachmentTemplate.status]);

  const handleDeleteAttachmentTemplateInfo = () => {
    const { cacheUUID } = attachmentTemplate.content as IPutTemplateAttachmentResponse;
    if (cacheUUID) deleteAttachmentTemplateInfo(cacheUUID);
  };

  const handleRedirectToHistoryPage = () => {
    history.push('/portfolio/employee/employee-history');
  };

  const handleSendTemplateFormData = (attachmentToSend: IDataTypeSelectorFormData, saveCheck: boolean, file?: File) => {
    const { section, kind, type } = attachmentToSend;
    const { cacheUUID } = attachmentTemplate.content as IPutTemplateAttachmentResponse;

    const attachment = {
      categoryCode: section,
      dataKind: kind,
      mainTypeCode: type,
      secondaryTypeCode: getSampleSecondaryTypeCode(type),
      cacheUUID: saveCheck ? cacheUUID : undefined,
    };

    const formData = new FormData();
    formData.append('attachment', new Blob([JSON.stringify(attachment)], { type: 'application/json' }));

    if (file) {
      formData.append('file', file);
    }

    postAttachmentTemplate(formData, {});
  };

  const handleMoveChapter = (step: number) => {
    if (
      (currentImporterChapter === importerChapters.SECOND || currentImporterChapter === importerChapters.THIRD) &&
      step === 1
    ) {
      const saveCheck = currentImporterChapter === importerChapters.THIRD;
      handleSendTemplateFormData(importerDataTypeFormData, saveCheck, excelAttachment);
      setImportDialogLoading(true);
    }
    setCurrentImporterChapter((prevstate) => prevstate + step);
  };

  const handleOpenImportDialog = (chapter: number) => {
    setCurrentImporterChapter(chapter);
    onImportDialogOpen(true);
  };

  const fileController = {
    handleSendFile: (file: File) => {
      if (file) {
        setExcelAttachment(file);
      }
    },
    handleSetError: (active: boolean, text: string | JSX.Element) => {
      setAttachmentError({
        active,
        text,
      });
    },
  };

  const handleLoadNextPartition = () => {
    if (!canPreloadPartitions) return;

    const nextPartition = attachmentTemplate.partitionLoading ? currentPartition : currentPartition + 1;

    if (!partitionLoading && nextPartition <= partitionsNumber) {
      setPartitionLoading(true);
      getAttachmentTemplatePartition(nextPartition, cacheUUID);
    }
  };

  /* Скачать шаблон для импорта */
  const sampleType = sampleDataTypeFormData.type;
  const sampleDataKind = sampleDataTypeFormData.kind;
  const isSampleDownloadDisabled = !sampleType && sampleDataKind !== SectionCodes.project;

  const closeSampleDialog = () => {
    onSampleDialogOpen(false);
  };

  const clearSampleDialogState = () => {
    setSampleDataTypeFormData(defaultDataTypeFormData);
  };

  const handleSubmitSampleDialog = () => {
    const { section, kind, type } = sampleDataTypeFormData;
    if (!section || !kind) return;
    getImportTemplate(section, kind, type);
    closeSampleDialog();
  };

  const samplePopUpBodyElement = (
    <DataTypeSelector
      kindOptions={getImportDataKindOptions(sampleDataTypeFormData.section)}
      typeOptions={getImportDataTypeOptions(sampleDataTypeFormData.kind)}
      formData={sampleDataTypeFormData}
      onChangeFormData={setSampleDataTypeFormData}
      categoryOptions={portfolioSections.content}
      gap={20}
    />
  );

  const sampleBackButtonElement = (
    <Button variant="secondary" onClick={closeSampleDialog}>
      Отмена
    </Button>
  );

  const sampleDownloadButtonElement = (
    <Button onClick={handleSubmitSampleDialog} iconLeft={<IconDownload />} disabled={isSampleDownloadDisabled}>
      Скачать шаблон
    </Button>
  );

  /* Импорт данных из файла */
  const isImportLoading =
    (attachmentTemplate?.loading && !correctRecordsCount && !incorrectRecordsCount) || isImportDialogLoading;

  const closeImportDialog = () => {
    onImportDialogOpen(false);
  };

  const clearImportDialogState = () => {
    clearAttachmentTemplate();

    setExcelAttachment(undefined);
    setImportDialogLoading(false);
    setCurrentImporterChapter(importerChapters.FIRST);
    setImporterDataTypeFormData(defaultDataTypeFormData);
  };

  const mappedPreviewData = useMemo<IPreviewData>(() => {
    const { correctRecordsCount, incorrectRecordsCount, incorrectRecords } =
      attachmentTemplate.content as IPutTemplateAttachmentResponse;

    return {
      correctRecordsCount: correctRecordsCount ?? 0,
      incorrectRecordsCount: incorrectRecordsCount ?? 0,
      incorrectRecords: {
        records: incorrectRecords?.records ?? [],
      },
    };
  }, [attachmentTemplate.content]);

  const importBodyElement = (
    <>
      {currentImporterChapter === importerChapters.FIRST && (
        <DataTypeSelector
          kindOptions={getImportDataKindOptions(importerDataTypeFormData.section)}
          typeOptions={getImportDataTypeOptions(importerDataTypeFormData.kind)}
          formData={importerDataTypeFormData}
          onChangeFormData={setImporterDataTypeFormData}
          categoryOptions={portfolioSections.content}
          gap={16}
        />
      )}
      {currentImporterChapter === importerChapters.SECOND && (
        <>
          {excelAttachment && (
            <ExcelFile
              file={excelAttachment}
              onClearFile={() => setExcelAttachment(undefined)}
              attachmentError={attachmentError}
            />
          )}
          {!excelAttachment && <ExcelDrop fileController={fileController} />}
        </>
      )}
      {currentImporterChapter === importerChapters.THIRD && isImportLoading && <ImportLoader />}
      {currentImporterChapter === importerChapters.THIRD && !isImportLoading && (
        <ImportPreview previewData={mappedPreviewData} />
      )}
      {currentImporterChapter === importerChapters.FOURTH && (
        <ImportResult
          successRecordCount={correctRecordsCount || undefined}
          onRedirectToHistoryPage={handleRedirectToHistoryPage}
        />
      )}
    </>
  );

  // смещаем на предыдущий шаг во время загрузки файла
  const importCurrentVisibleStep =
    currentImporterChapter === importerChapters.THIRD && isImportLoading
      ? importerChapters.SECOND
      : currentImporterChapter;

  return (
    <>
      <PopUp
        open={isSampleDialogOpen}
        onClose={closeSampleDialog}
        title="Скачать шаблон для импорта"
        description="Выберите раздел и тип данных, шаблон для импорта которых вы хотите скачать"
        body={samplePopUpBodyElement}
        rightAdditionalButton={sampleBackButtonElement}
        rightMainButton={sampleDownloadButtonElement}
        size="small"
        fixedHeight
        onExited={clearSampleDialogState}
      />

      <ImportPopUp
        isOpen={isImportDialogOpen}
        isLoading={isImportLoading}
        onClose={closeImportDialog}
        onDeleteImportedData={handleDeleteAttachmentTemplateInfo}
        onOpenSampleDialog={() => onSampleDialogOpen(true)}
        currentStep={currentImporterChapter}
        currentVisibleStep={importCurrentVisibleStep}
        formData={importerDataTypeFormData}
        onMoveChapter={handleMoveChapter}
        bodyElement={importBodyElement}
        isAttachmentReady={!attachmentError.active && Boolean(excelAttachment)}
        correctRecordsCount={correctRecordsCount}
        onExited={clearImportDialogState}
        onScrollLoad={handleLoadNextPartition}
        shouldLoadOnScroll={hasMorePartitions && canPreloadPartitions}
      />

      <TaskPanel onOpenImportDialog={handleOpenImportDialog} />
    </>
  );
};

export default connect(
  (state: IRootState) => ({
    attachmentTemplate: state.attachmentTemplate,
    importList: state.importList,
    portfolioSections: availableEmployeeParentSectionsSelector(state),
  }),
  {
    getImportTemplate: getImportTemplateActions.request,
    getAttachmentTemplateInfo: getAttachmentTemplateInfoActions.request,
    getAttachmentTemplatePartition: getAttachmentTemplateInfoPartitionActions.request,
    postAttachmentTemplate: postAttachmentTemplateActions.request,
    clearAttachmentTemplate: clearAttachmentTemlpateAction,
    deleteAttachmentTemplateInfo: deleteAttachmentTemplateInfoActions.request,
  },
)(ExcelImporter);
