import { useEffect, useState } from 'react';

import { buildConfig, IBuildConfig } from 'packages/buildConfig';

const useBuildConfig = () => {
  // стейт нужен для отложенного рендера компонентов после загрузки конфига
  const [config, setConfig] = useState<IBuildConfig | null>(null);

  useEffect(() => {
    async function loadConfig() {
      if (buildConfig.config) {
        setConfig(buildConfig.config);
        return;
      }

      const loadedConfig = await buildConfig.loadConfig();
      setConfig(loadedConfig);
    }

    loadConfig();
  }, []);

  return config;
};

export default useBuildConfig;
