/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';
import genericReducers from '../genericReducer';
import { IReducers } from '../index';

import { GET_MUSEUMS } from '../../actions/employees';
import { REQUEST, FAILURE, SUCCESS } from '../../actions/utils';

import { ICommonResponse, ILocation } from '../../api/types';

export type MuseumsState = ICommonResponse<ILocation>;

const initialState = {} as ICommonResponse<ILocation>;

const museums: IReducers<MuseumsState> = {
  [GET_MUSEUMS[REQUEST]]: genericReducers().get.request,
  [GET_MUSEUMS[SUCCESS]]: genericReducers().get.success,
  [GET_MUSEUMS[FAILURE]]: genericReducers().get.failure
};

export default connectReducers(initialState, museums);
