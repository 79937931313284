import { FC } from 'react';

import { useDetailViewDrawer } from 'hooks';
import { IEntity } from 'types';

import { CommonEntityCard } from '../../components/cards';
import SearchSection from '../../components/SearchSection';

interface ICommonEntityCardListProps {
  entities: IEntity[];
}

const CommonEntityCardList: FC<ICommonEntityCardListProps> = ({ entities }) => {
  const { handleOpenPrimaryDetailView } = useDetailViewDrawer();

  const cards = entities.map((entity, index, array) => {
    const handleView = () => {
      if (entity.cardData) {
        handleOpenPrimaryDetailView(entity.cardData, entity.entityProps.type);
      }
    };

    const card = <CommonEntityCard entity={entity} onView={handleView} />;

    return (
      <SearchSection.CardContainer
        key={entity.id ?? entity.recordId ?? index}
        card={card}
        mapIndex={index}
        totalCards={array.length}
        onView={handleView}
      />
    );
  });

  return cards;
};

export default CommonEntityCardList;
