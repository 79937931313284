/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';

import { GET_HISTORY } from '../../actions/employees';

import {
  FAILURE,
  REQUEST,
  SUCCESS
} from '../../actions/utils';

import genericReducers from '../genericReducer';
import { IReducers } from '..';
import { IHistoryResponse, IHistory } from '../../api/types';

export type HistoryState = IHistoryResponse<IHistory>;

const initialState = {
  content: [],
  loading: false
} as IHistoryResponse<IHistory>;

const history: IReducers<HistoryState> = {
  [GET_HISTORY[REQUEST]]: genericReducers().get.request,
  [GET_HISTORY[SUCCESS]]: genericReducers().get.success,
  [GET_HISTORY[FAILURE]]: genericReducers().get.failure
};

export default connectReducers(initialState, history);
