/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconSortAsc: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M1.50008 0.333008H7.33341C7.79175 0.333008 8.16675 0.708008 8.16675 1.16634C8.16675 1.62467 7.79175 1.99967 7.33341 1.99967H1.50008C1.04175 1.99967 0.666748 1.62467 0.666748 1.16634C0.666748 0.708008 1.04175 0.333008 1.50008 0.333008ZM4.00008 6.16634H7.33342C7.79175 6.16634 8.16675 6.54134 8.16675 6.99967C8.16675 7.45801 7.79175 7.83301 7.33342 7.83301H4.00008C3.54175 7.83301 3.16675 7.45801 3.16675 6.99967C3.16675 6.54134 3.54175 6.16634 4.00008 6.16634ZM7.33341 11.9997H5.66675C5.20841 11.9997 4.83341 12.3747 4.83341 12.833C4.83341 13.2913 5.20841 13.6663 5.66675 13.6663H7.33341C7.79175 13.6663 8.16675 13.2913 8.16675 12.833C8.16675 12.3747 7.79175 11.9997 7.33341 11.9997ZM14.8336 10.8695L15.9211 9.81951C16.2527 9.50034 16.7802 9.51034 17.0994 9.84034C17.4194 10.172 17.4102 10.6995 17.0794 11.0187L14.5794 13.4328C14.4169 13.5878 14.2086 13.6662 14.0002 13.6662C13.7869 13.6662 13.5736 13.5853 13.4111 13.422L10.9111 10.922C10.5852 10.597 10.5852 10.0695 10.9111 9.74367C11.2361 9.41867 11.7636 9.41867 12.0894 9.74367L13.1669 10.8212V3.13034L12.0794 4.17951C11.7477 4.50034 11.2211 4.48951 10.9011 4.15867C10.5811 3.82784 10.5902 3.30034 10.9211 2.98034L13.4211 0.566174C13.7486 0.253674 14.2686 0.255341 14.5894 0.577008L17.0894 3.07701C17.4144 3.40284 17.4144 3.93034 17.0894 4.25534C16.9269 4.41867 16.7136 4.49951 16.5002 4.49951C16.2869 4.49951 16.0736 4.41867 15.9111 4.25534L14.8336 3.17784V10.8695Z" fill="#7B819B" />
  </SvgIcon>
);

export default IconSortAsc;
