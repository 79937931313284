import { FC } from 'react';

import { Box } from '@mui/material';
import { VerifiedBadge } from 'portfolio3/ui-kit/badges';

import * as styles from './styles';

interface IAchievementCompactProps {
  image: string;
  isApproved: boolean;
}

const AchievementCompact: FC<IAchievementCompactProps> = ({ image, isApproved }) => {
  return (
    <Box className="achievement-compact" sx={styles.root}>
      {isApproved && <VerifiedBadge />}
      <Box component="img" src={image} alt="Награда" sx={styles.image} />
    </Box>
  );
};

export default AchievementCompact;
