import { FC } from 'react';

import { Box, useMediaQuery } from '@mui/material';
import { commonTheme } from 'portfolio3/styles/theme';

import { IDictionaryItem } from '../../../api/types';
import FunnelBlock from '../FunnelBlock';
import ThanksForm from './Form';
import { IThanksFormData } from './types';

interface IPortfolioThanksFormProps {
  formData: IThanksFormData;
  onChangeFormData: (formData: IThanksFormData | undefined) => void;
  subjects: IDictionaryItem[];
  teachers: IDictionaryItem[];
  studentClassLevel: number;
}

const PortfolioThanksForm: FC<IPortfolioThanksFormProps> = ({
  formData,
  onChangeFormData,
  subjects,
  teachers,
  studentClassLevel,
}) => {
  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const ContainerElement = isMobile ? FunnelBlock : Box;

  return (
    <ContainerElement sx={{ marginTop: isMobile ? '12px' : 0 }}>
      <ThanksForm
        studentClassLevel={studentClassLevel}
        formData={formData}
        onChangeFormData={onChangeFormData}
        teachers={teachers || []}
        subjects={subjects}
      />
    </ContainerElement>
  );
};

export default PortfolioThanksForm;
