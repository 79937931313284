import { FC, Fragment, PropsWithChildren } from 'react';

import clsx from 'clsx';
import { useMediaQueryWidth } from 'hooks';
import { commonTheme } from 'portfolio3/styles/theme';

import PaddingContainer from '../PaddingContainer';

import './index.scss';

interface IFixedWidthContainerProps {
  withPadding?: boolean;
  className?: string;
}

/**
 * Компонент служит для ограничения ширины контента для определенных экранов
 */
const FixedWidthContainer: FC<IFixedWidthContainerProps & PropsWithChildren> = ({
  withPadding = true,
  className,
  children,
}) => {
  // добавление отступов при переходе fluid -> fixed
  const usePadding = useMediaQueryWidth(commonTheme.breakpoints.values.commonXl, 'up');
  const Container = withPadding && usePadding ? PaddingContainer : Fragment;

  return (
    <div className={clsx('fixed-width-container', className)}>
      <Container>{children}</Container>
    </div>
  );
};

export default FixedWidthContainer;
