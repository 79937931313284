/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { DialogActions } from '@mui/material';
import CommonDialog from '../../../ui-kit/dialog/CommonDialog';
import { Button } from 'portfolio3/ui-kit/button';
import { SxStyles } from 'types';

interface IConfirmCloseDialogProps {
  title: string;
  description: string;
  isOpen: boolean;
  submitButtonTitle?: string;
  onConfirmClose: () => void;
  onCancel: () => void;
}

const actionsStyles: SxStyles = (theme) => ({
  display: 'flex',

  [theme.breakpoints.down('commonSm')]: {
    justifyContent: 'space-between',

    '& > *': {
      flexBasis: 1,
      flexGrow: 1,
    }
  }
});

const ConfirmCloseDialog: React.FC<IConfirmCloseDialogProps> = ({
  isOpen,
  title,
  description,
  submitButtonTitle,
  onCancel,
  onConfirmClose
}) => {
  const dialogActions = () => (
    <DialogActions sx={actionsStyles}>
      <Button
        size="medium"
        variant="secondary"
        onClick={onCancel}
      >
        Отмена
      </Button>
      <Button
        size="medium"
        onClick={onConfirmClose}
      >
        {submitButtonTitle || 'Да, закрыть'}
      </Button>
    </DialogActions>
  );

  return (
    <CommonDialog
      isOpen={isOpen}
      description={description}
      title={title}
      actions={dialogActions()}
      onClose={onCancel}
      className="dialog"
    />
  );
};

export default ConfirmCloseDialog;
