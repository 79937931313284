import { FC, ReactNode } from 'react';

import { Box, Typography } from '@mui/material';
import { SxStyles } from 'types';

import PdfBadge from '../PdfBadge';

const headerStyles: SxStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
};

const bodyStyles: SxStyles = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '12px',
  marginTop: '12px',
};

interface IMetaskillsTagsBlockProps {
  icon: ReactNode;
  title: string;
  tags: string[];
}

const MetaskillsTagsBlock: FC<IMetaskillsTagsBlockProps> = ({ icon, title, tags }) => {
  return (
    <Box className="metaskills-tags-block">
      <Box className="metaskills-tags-block__header" sx={headerStyles}>
        {icon}
        <Typography component="span" variant="Paragraph LG/Semi Bold" display="block">
          {title}
        </Typography>
      </Box>
      <Box className="metaskills-tags-block__body" sx={bodyStyles}>
        {tags.map((tag) => {
          return <PdfBadge key={tag}>{tag}</PdfBadge>;
        })}
      </Box>
    </Box>
  );
};

export default MetaskillsTagsBlock;
