import { FC, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { Box, useMediaQuery } from '@mui/material';
import { gratitudeTeacherActions, thanksTeacherDrawerActions } from 'actions';
import { getOrganizationTeachers } from 'api';
import { IChildInfo, IDictionaryItem, IPostGratitudeTeacherRequest, IUserContext } from 'api/types';
import { ViewDialog } from 'components/common/Dialog';
import {
  getGratitudeText,
  IThanksFormData,
  PortfolioThanksForm,
  ThanksActionButtons,
} from 'components/common/PortfolioThanksForm';
import { EntityType } from 'const';
import { useAppSelector, useBrightTheme, useDataLoad, useUserSourceCode } from 'hooks';
import { IconAlertCircleOutline, IconInfoFilled } from 'icons';
import { cardIllustrations } from 'images';
import { FormEntityInfo } from 'portfolio3/components/dataEntry';
import { DrawerHeader, MobileDrawerHeader } from 'portfolio3/components/drawers';
import { getYMEventPayload } from 'portfolio3/features/yandexMetrika';
import { commonTheme } from 'portfolio3/styles/theme';
import { Drawer, IDrawerBase } from 'portfolio3/ui-kit/Drawer';
import { PrimaryInfobox } from 'portfolio3/ui-kit/infobox';
import { drawerContentPadding } from 'portfolio3/ui-kit/utils';
import { IRootState } from 'reducers';
import { ThanksTeacherDrawerState } from 'reducers/drawers/thanksTeacherDrawer';
import { GratitudeTeacherState } from 'reducers/student/gratitudeTeacher';
import { olympiadSubjectsRefArchiveFalseSelector } from 'selectors';
import { Colors } from 'style/variables';
import { getDataLoadRequests, isDefined, mergeSx } from 'utils';

import './index.scss';

interface IThanksTeacherDrawerProps {
  thanksTeacherDrawer: ThanksTeacherDrawerState;
  currentUser: IUserContext;
  currentStudent: IChildInfo;
  gratitudeTeacherStatus: GratitudeTeacherState;
  gratitudeTeacher: typeof gratitudeTeacherActions.request;
}

const ThanksTeacherDrawer: FC<IThanksTeacherDrawerProps> = ({
  thanksTeacherDrawer,
  currentUser,
  currentStudent,
  gratitudeTeacherStatus,
  gratitudeTeacher,
}) => {
  const olympiadSubjects = useAppSelector(olympiadSubjectsRefArchiveFalseSelector);

  const { isOpen, widgetLabel, formData, isSubmitModalOpen } = thanksTeacherDrawer;

  const dispatch = useDispatch();
  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const sourceCode = useUserSourceCode();
  const [teachers, setTeachers] = useState<IDictionaryItem[]>();
  // массив предметов сущности, необходим для:
  // 1) получения списка учителей если найден только 1 предмет
  // 2) блокировки выбора предмета если найдет только 1 предмет
  // 3) замены данных селекта предметов на этот массив если найдено более 1 предмета
  // все условия выше верны только если предмет найден в справочнике предметов олимпиад
  const [entityDefinedSubjects, setEntityDefinedSubjects] = useState<IDictionaryItem[] | null>(null);

  // получение справочника предметов
  useDataLoad({ shouldLoad: isOpen, requests: getDataLoadRequests().thanksTeacherDrawer });

  const isBrightTheme = useBrightTheme();

  const studentClassLevelNumber = Number(currentStudent.classLevel) || 11;

  useEffect(() => {
    // Выполнить при открытии и после загрузки предметов 1 раз
    if (!isOpen || olympiadSubjects.requestCache !== 'cached') return;

    const entitySubjects: string[] = [];

    const eventSubjects: IDictionaryItem[] | undefined = formData?.entityFormData?.subjects;
    const examSubject: string | undefined = formData?.entityFormData?.name;

    // предметы олимпиады
    eventSubjects?.forEach((subject: IDictionaryItem | undefined) => {
      if (subject && typeof subject?.value === 'string') {
        entitySubjects.push(subject.value);
      }
    });

    // предмет экзамена
    if (examSubject && typeof examSubject === 'string') {
      entitySubjects.push(examSubject);
    }

    // оставить только предметы из справочника
    const dictionaryEntitySubjects = entitySubjects
      .map((subject): IDictionaryItem | undefined =>
        olympiadSubjects.content.find((olympiadSubject) => olympiadSubject.value === subject),
      )
      .filter(isDefined);

    if (formData) {
      handleChangeFormData({
        ...formData,
        // при наличии только 1 предмета зафиксировать его на форме
        subjectCode: dictionaryEntitySubjects.length === 1 ? dictionaryEntitySubjects[0].code : undefined,
        subjectName: dictionaryEntitySubjects.length === 1 ? dictionaryEntitySubjects[0].value : undefined,
      });

      setEntityDefinedSubjects(dictionaryEntitySubjects);
      updateOrganizationTeachers(dictionaryEntitySubjects);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, olympiadSubjects.requestCache]);

  useEffect(() => {
    if (formData) {
      handleChangeFormData({
        ...formData,
        gratitudeText: getGratitudeText(formData),
      });
    }

    // при изменении учителя или предмета изменить текст благодарности
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.teacherName, formData?.subjectName]);

  const handleChangeFormData = (formData: IThanksFormData | undefined) => {
    dispatch(thanksTeacherDrawerActions.setFormData(formData));
  };

  const handleClose = () => {
    dispatch(thanksTeacherDrawerActions.setFormData(undefined));
    setTeachers([]);
    setEntityDefinedSubjects([]);
    dispatch(thanksTeacherDrawerActions.setOpen(false, null));
  };

  const handleCloseSubmitDialog = () => {
    dispatch(thanksTeacherDrawerActions.setSubmitModalOpen(false));
  };

  // обновление списка учителей после определения предметов сущности
  const updateOrganizationTeachers = (definedSubjects: IDictionaryItem[]) => {
    const organizationId = currentStudent.schoolId;

    if (!organizationId) return;

    const teacherSubject = definedSubjects.length === 1 ? definedSubjects[0].value : undefined;

    getOrganizationTeachers(organizationId, teacherSubject).then((result) => {
      const teacherDictionary = result.response?.data.map(
        (teacher): IDictionaryItem => ({
          code: teacher.staffId,
          value: [teacher.lastName, teacher.firstName, teacher.patronymic].join(' '),
        }),
      );

      setTeachers(teacherDictionary);
    });
  };

  const handleSubmit = () => {
    const creatorId = currentUser.data?.meshId;
    const personId = currentStudent.meshId;

    if (!creatorId || !personId || !formData) return;

    const { teacherId, teacherName, entityType, entityId, recordId, subjectCode, gratitudeText, yearEducation } =
      formData;

    let computedEntityType = entityType;
    // для сущностей олимпиада нужно установить тип Event при отправке
    if (entityType === EntityType.EVENT || entityType === EntityType.OLYMPIAD) {
      computedEntityType = EntityType.EVENT;
    }

    const request: IPostGratitudeTeacherRequest = {
      personId,
      creatorId,
      staffId: teacherId,
      teacherFIO: teacherName,
      entityType: computedEntityType,
      entityId: Number(entityId),
      recordId,
      subjectCode,
      gratitudeText: gratitudeText || '',
      yearEducation,
      sourceCode,
    };

    const ymMeta = getYMEventPayload({
      type: 'gratitudeTeacherOpenSuccessfull',
      payload: {
        Subsections: widgetLabel ?? '',
      },
    });
    gratitudeTeacher(personId, request, ymMeta);
    handleClose();
  };

  const entityInfoElement = formData && (
    <FormEntityInfo name={formData.entityName} attributes={[formData.categoryName]} />
  );

  const desktopHeader: IDrawerBase['header'] = (crossButton) => (
    <DrawerHeader
      title="Спасибо учителю"
      backgroundImage={isBrightTheme ? cardIllustrations.studyOlympiad : null}
      accentColor="violet"
      crossButton={crossButton}
    >
      <Box sx={{ marginTop: '16px' }}>{entityInfoElement}</Box>
    </DrawerHeader>
  );

  const mobileHeader: IDrawerBase['header'] = (crossButton) => (
    <MobileDrawerHeader crossButton={crossButton} title="Спасибо учителю" accentColor="violet" />
  );

  const footer = formData && (
    <>
      {!isMobile && (
        <Box sx={{ paddingInline: '24px', paddingBottom: '20px' }}>
          <PrimaryInfobox icon={<IconInfoFilled />}>
            Эту благодарность получит учитель, которого ты выбрал.
          </PrimaryInfobox>
        </Box>
      )}
      <ThanksActionButtons
        formData={formData}
        onExitBack={handleClose}
        onSubmit={handleSubmit}
        studentClassLevel={studentClassLevelNumber}
      />
    </>
  );

  const formSubjects =
    entityDefinedSubjects && entityDefinedSubjects.length > 0 ? entityDefinedSubjects : olympiadSubjects.content;

  return (
    <>
      <Drawer
        open={isOpen}
        isFullHeight
        isMobile={isMobile}
        anchor={isMobile ? 'bottom' : 'right'}
        swipeable={isMobile}
        swipeableProps={{
          onClose: handleClose,
        }}
        header={isMobile ? mobileHeader : desktopHeader}
        footer={footer}
        onClose={handleClose}
        sx={{ backgroundColor: isMobile ? '#F4F3F8' : 'initial' }}
      >
        {formData && (
          <Box sx={mergeSx(drawerContentPadding(true), { position: 'relative' })}>
            {isMobile && entityInfoElement}
            <PortfolioThanksForm
              formData={formData}
              onChangeFormData={handleChangeFormData}
              subjects={formSubjects}
              teachers={teachers ?? []}
              studentClassLevel={studentClassLevelNumber}
            />
          </Box>
        )}
      </Drawer>
      {gratitudeTeacherStatus.errorMessage !== undefined && (
        <ViewDialog
          className="thanks-dialog"
          isOpen={isSubmitModalOpen}
          onClose={handleCloseSubmitDialog}
          title={
            <>
              {isMobile && <IconAlertCircleOutline fill={Colors.red_07} />}
              {'Произошла ошибка при отправке благодарности'}
            </>
          }
          description="По переданной сущности уже была добавлена благодарность ранее"
        />
      )}
    </>
  );
};

export default connect(
  (state: IRootState) => ({
    thanksTeacherDrawer: state.thanksTeacherDrawer,
    currentUser: state.currentUser,
    currentStudent: state.currentStudent,
    gratitudeTeacherStatus: state.gratitudeTeacherStatus,
  }),
  {
    gratitudeTeacher: gratitudeTeacherActions.request,
  },
)(ThanksTeacherDrawer);
