/* eslint-disable prettier/prettier */
/* eslint-disable */

import connectReducers from '../utils/connectReducers';

import {
  SET_LINK_MODE
} from '../actions';
import { ILinkMode } from '../api/types';

export type LinkModeState = ILinkMode;

export interface ILinkModeReducers {
  [key: string]: (state: LinkModeState, action: any) => LinkModeState;
}

type LinkModePayload = {
  isLinkMode: boolean;
}

const initialState: LinkModeState = {
  mode: undefined
};

const linkMode: ILinkModeReducers = {
  [SET_LINK_MODE]: (_, { payload: { isLinkMode } }: { payload: LinkModePayload }) => ({
    mode: isLinkMode
  })
};

export default connectReducers(initialState, linkMode);
