import React, { useContext } from 'react';
import { connect } from 'react-redux';

import { ICommonResponse, IDictionaryItem, IModifiedEventKindsList, IModifiedRewardKindsList } from 'api/types';
import { SectionCodes } from 'const';
import { useAppSelector } from 'hooks';
import { IRootState } from 'reducers';
import { createSelector } from 'reselect';
import { olympiadSubjectsRefArchiveFalseSelector } from 'selectors';
import { getAgeLimitFromArray } from 'utils';

import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';
import {
  NameRewardSecondaryBlock,
  OlympiadSecondaryBlock,
  StudySpoDocumentSecondaryBlock,
} from '../../secondaryBlocks';

interface IStudySecondaryBlockProps {
  eventKindData: IModifiedEventKindsList;
  olympiadTypes: ICommonResponse<IDictionaryItem>;
  olympiadLevels: ICommonResponse<IDictionaryItem>;
  rewardKindData: IModifiedRewardKindsList;
}

const StudySecondaryBlock: React.FC<IStudySecondaryBlockProps> = ({
  eventKindData,
  rewardKindData,
  olympiadTypes,
  olympiadLevels,
}) => {
  const olympiadSubjects = useAppSelector(olympiadSubjectsRefArchiveFalseSelector);

  const { formData } = useContext(LocalDataEntryFormContext);

  const currentEventKindData = eventKindData.content.find((activity) => activity.code === formData.name);

  const eventData = {
    type: 'Олимпиада',
    name: currentEventKindData?.value,
    ageLimit: getAgeLimitFromArray(currentEventKindData?.ageLimits),
    levelEvent: currentEventKindData?.levelEvent
      ? olympiadTypes.content?.find((type) => type.code === currentEventKindData?.levelEvent)?.value
      : undefined,
    levelEventCode: currentEventKindData?.levelEvent,
    organizators: currentEventKindData?.organizators,
  };

  return (
    <>
      {formData.dataKind === SectionCodes.studyEvent && formData.name && (
        <OlympiadSecondaryBlock
          eventData={eventData}
          subjectOptions={olympiadSubjects.content}
          eventLevelOptions={olympiadTypes?.content || []}
          olympiadLevelOptions={olympiadLevels?.content || []}
        />
      )}
      {formData.dataKind === SectionCodes.studyReward && formData.reward && (
        <NameRewardSecondaryBlock
          result={rewardKindData.content.find((reward) => reward.code === formData.reward)?.value}
        />
      )}
      {formData.typeCode === SectionCodes.studySpoDocuments && formData.studySpoDocument?.documentTypeCode && (
        <StudySpoDocumentSecondaryBlock />
      )}
    </>
  );
};

const eventKindsMapping = createSelector([(state: IRootState) => state.eventKinds], (eventKinds) => ({
  content: eventKinds?.content?.filter((eventKind) => eventKind.categoryCode === SectionCodes.eventOlympiad) || [],
  loading: eventKinds.loading,
}));

const rewardKindsMapping = createSelector([(state: IRootState) => state.rewardKinds], (rewardKinds) => ({
  content: rewardKinds.content?.filter((rewardKind) => rewardKind.categoryCode === SectionCodes.eventOlympiad) || [],
  loading: rewardKinds.loading,
}));

export default connect((state: IRootState) => ({
  eventKindData: eventKindsMapping(state),
  rewardKindData: rewardKindsMapping(state),
  olympiadTypes: state.olympiadTypes,
  olympiadLevels: state.olympiadLevels,
}))(StudySecondaryBlock);
