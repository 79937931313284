import { SxStyles } from 'types';

export const rootStyles: SxStyles = {
  display: 'flex',
  flexDirection: 'column',
  flex: 'auto',

  width: '100%',
  height: '100%',
};
