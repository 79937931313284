/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconChevronFilledArrowUp: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M16.213 16H7.787c-.673 0-1.28-.402-1.585-1.049a2.087 2.087 0 0 1 .258-2.21l4.214-5.099c.672-.817 1.98-.817 2.652 0l4.213 5.098c.504.611.605 1.478.259 2.211-.305.647-.912 1.049-1.585 1.049Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconChevronFilledArrowUp;
