import { getAccentColor, NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';
import { numberToPixelsString } from 'utils';

const barCircleSize = 44;

export const barCircleBase: SxStyles = () => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  minWidth: barCircleSize,
  width: barCircleSize,
  height: barCircleSize,

  border: `1px solid ${NeutralColors.light_neutrals_400}`,
  borderRadius: '30px',

  backgroundColor: NeutralColors.light_neutrals_0,
  color: NeutralColors.light_neutrals_900,
});

export const placeBar =
  (yours: boolean | undefined, percent: number): SxStyles =>
  ({ palette }) => {
    return {
      position: 'relative',
      display: 'grid',
      gridAutoFlow: 'column',
      gridTemplateColumns: 'auto 1fr auto',

      width: `${percent}%`,
      minWidth: `${barCircleSize * 2}px`,

      borderRadius: '24px',
      backgroundColor: yours ? getAccentColor(palette.accentColor, '48') : NeutralColors.light_neutrals_300,
    };
  };

export const avatar: SxStyles = {
  zIndex: 1,
  justifySelf: 'flex-end',
};

export const placeBarTypographyContainer: SxStyles = () => ({
  display: 'flex',
  alignItems: 'center',

  overflow: 'hidden',
});

export const tooltipTarget: SxStyles = {
  zIndex: 10,
  position: 'absolute',
  top: 0,

  width: '56px',
  height: numberToPixelsString(barCircleSize),

  borderRadius: '20px',
};

export const placeBarTypography: SxStyles = () => ({
  display: 'flex',
  justifyContent: 'center',

  height: '100%',
  marginLeft: '8px',

  whiteSpace: 'nowrap',
  lineHeight: numberToPixelsString(barCircleSize),

  color: NeutralColors.light_neutrals_600,

  '&.active': {
    color: NeutralColors.light_neutrals_0,
  },
});
