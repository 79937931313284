/* eslint-disable max-len */
import { FC } from 'react';

import { Box } from '@mui/material';
import { PropsWithSx } from 'types';

const BadgeSmall: FC<PropsWithSx> = ({ sx }) => {
  return (
    <Box
      component="svg"
      xmlns="http://www.w3.org/2000/svg"
      width="22px"
      height="22px"
      fill="none"
      viewBox="0 0 22 22"
      sx={sx}
    >
      <circle cx="11" cy="11" r="11" fill="#E53130" />
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <path
            fill="#fff"
            d="M10.068 9.248H7.154c-.09 0-.123-.114-.05-.165l8.245-5.52a.086.086 0 0 1 .128.102l-5.158 14.56c-.033.092-.167.069-.167-.028V9.334a.087.087 0 0 0-.084-.086Z"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M3.666 3.667h14.667v14.667H3.666z" />
        </clipPath>
        <clipPath id="b">
          <path fill="#fff" d="M6.875 3.667h8.708v14.667H6.875z" />
        </clipPath>
      </defs>
    </Box>
  );
};

export default BadgeSmall;
