/* eslint-disable prettier/prettier */
/* eslint-disable */

import { IQueryParams } from '../api';
import { IPutAttachmentRequest } from '../api/types';
import {
  createRequestTypes,
  action,
  FAILURE,
  REQUEST,
  SUCCESS
} from './utils';

export const GET_ATTACHMENT = createRequestTypes('GET_ATTACHMENT');
export const getAttachmentActions = {
  request: (queryParams: IQueryParams) => action(GET_ATTACHMENT[REQUEST], { payload: { queryParams } }),
  success: (response: any) => action(GET_ATTACHMENT[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_ATTACHMENT[FAILURE], { payload: error })
};

export const PUT_ATTACHMENT = createRequestTypes('PUT_ATTACHMENT');
export const putAttachmentActions = {
  request: (request: IPutAttachmentRequest, entityId: string, entityName: string) => action(PUT_ATTACHMENT[REQUEST], { payload: { request, entityId, entityName } }),
  success: (response: any) => action(PUT_ATTACHMENT[SUCCESS], { payload: response }),
  failure: (error: any) => action(PUT_ATTACHMENT[FAILURE], { payload: error })
};

export const POST_ATTACHMENT_TEMPLATE = createRequestTypes('POST_ATTACHMENT_TEMPLATE');
export const postAttachmentTemplateActions = {
  request: (formData: FormData, requestHeaders?: any) => action(POST_ATTACHMENT_TEMPLATE[REQUEST], { payload: formData, requestHeaders }),
  success: (response: any) => action(POST_ATTACHMENT_TEMPLATE[SUCCESS], { payload: response }),
  failure: (error: any) => action(POST_ATTACHMENT_TEMPLATE[FAILURE], { payload: error })
};

export const CLEAR_ATTACHMENT_TEMPLATE = 'CLEAR_ATTACHMENT_TEMPLATE';
export const clearAttachmentTemlpateAction = () => action(CLEAR_ATTACHMENT_TEMPLATE);

export const GET_IMPORT_TEMPLATE = createRequestTypes('GET_IMPORT_TEMPLATE');
export const getImportTemplateActions = {
  request: (categoryCode: number, dataKind: number, typeCode?: number) => action(GET_IMPORT_TEMPLATE[REQUEST], { payload: { categoryCode, dataKind, typeCode } }),
  success: (response: any) => action(GET_IMPORT_TEMPLATE[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_IMPORT_TEMPLATE[FAILURE], { payload: error })
};

export const GET_ATTACHMENT_TEMPLATE_INFO = createRequestTypes('GET_ATTACHMENT_TEMPLATE_INFO');
export const getAttachmentTemplateInfoActions = {
  request: (uuid: string) => action(GET_ATTACHMENT_TEMPLATE_INFO[REQUEST], { payload: uuid }),
  success: (response: any) => action(GET_ATTACHMENT_TEMPLATE_INFO[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_ATTACHMENT_TEMPLATE_INFO[FAILURE], { payload: error })
};

export const GET_ATTACHMENT_TEMPLATE_INFO_PARTITION = createRequestTypes('GET_ATTACHMENT_TEMPLATE_INFO_PARTITION');
export const getAttachmentTemplateInfoPartitionActions = {
  request: (partition: number, uuid: string) => action(GET_ATTACHMENT_TEMPLATE_INFO_PARTITION[REQUEST], { payload: { partition, uuid } }),
  success: (response: any) => action(GET_ATTACHMENT_TEMPLATE_INFO_PARTITION[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_ATTACHMENT_TEMPLATE_INFO_PARTITION[FAILURE], { payload: error })
}

export const DELETE_ATTACHMENT_TEMPLATE_INFO = createRequestTypes('DELETE_ATTACHMENT_TEMPLATE_INFO');
export const deleteAttachmentTemplateInfoActions = {
  request: (uuid?: string) => action(DELETE_ATTACHMENT_TEMPLATE_INFO[REQUEST], { payload: uuid }),
  success: (response: any) => action(DELETE_ATTACHMENT_TEMPLATE_INFO[SUCCESS], { payload: response }),
  failure: (error: any) => action(DELETE_ATTACHMENT_TEMPLATE_INFO[FAILURE], { payload: error })
};
