import React, { useContext, useMemo } from 'react';
import { connect } from 'react-redux';

import { FormHelperText } from '@mui/material';
import {
  ICommonResponse,
  IDictionaryItem,
  IEventKind,
  ILocation,
  IModifiedDictionaryItemList,
  IModifiedEventKindsList,
  IModifiedRewardKindsList,
  ISportReward,
  SportAgeRefItem,
} from 'api/types';
import {
  SectionCodes,
  SportSubcategoryCodes,
  subcategoryCodes,
  SubcategoryParentsCodes,
  WithoutOrganizationCode,
} from 'const';
import { BaseInputLabel, FormControl } from 'portfolio3/ui-kit';
import { SearchSelect } from 'portfolio3/ui-kit/selects';
import { IController } from 'portfolio3/ui-kit/types';
import { IRootState } from 'reducers';
import { createSelector } from 'reselect';
import { sectionRefArchiveFalseSelector } from 'selectors';
import { getAgeLimitFromArray } from 'utils';

import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';
import {
  CompetitionSecondaryBlock,
  GtoRewardSecondaryBlock,
  NameRewardSecondaryBlock,
  OccupationSecondaryBlock,
  SportRankRewardSecondaryBlock,
  TourismExpeditionSecondaryBlock,
  TourismRewardSecondaryBlock,
} from '../../secondaryBlocks';
import { SportAffiliationSecondaryBlock } from '../../secondaryBlocks/sportBlocks';

interface ISportSecondaryBlockProps {
  activityTypeData: IModifiedDictionaryItemList;
  competitionKindData: IModifiedEventKindsList;
  expeditionKindData: IModifiedEventKindsList;
  tourismKindData: IModifiedEventKindsList;
  eventNamesData: IModifiedEventKindsList;
  tourismKinds: ICommonResponse<IDictionaryItem>;
  olympiadTypes: ICommonResponse<IDictionaryItem>;
  subcategories: IModifiedRewardKindsList;
  sportKinds: ICommonResponse<IDictionaryItem>;
  sportClubs: ICommonResponse<ILocation>;
  sportTrainingStage: ICommonResponse<IDictionaryItem>;
  sportRewards: ICommonResponse<ISportReward>;
  sportAgesRef: ICommonResponse<SportAgeRefItem>;
}

const SportSecondaryBlock: React.FC<ISportSecondaryBlockProps> = ({
  activityTypeData,
  competitionKindData,
  expeditionKindData,
  tourismKindData,
  tourismKinds,
  sportKinds,
  subcategories,
  olympiadTypes,
  sportClubs,
  sportTrainingStage,
  sportRewards,
  sportAgesRef,
}) => {
  const { inputRenderMode, inputSize, formData, formErrors, onChangeFormData, onChangeFormErrors } =
    useContext(LocalDataEntryFormContext);

  const sportKindOptions = sportKinds.content?.filter((sportKind) => !sportKind.parentId) || [];
  const tourismKindOptions = tourismKinds.content?.filter((tourismKind) => !tourismKind.parentId) || [];

  const sportKindController: IController<string | undefined> = useMemo(
    () => ({
      handleChange: (sportKind) => {
        onChangeFormData((prevstate) => ({
          ...prevstate,
          sportKind: Number(sportKind),
          subjectId: [],
        }));
        onChangeFormErrors((prevstate) => ({
          ...prevstate,
          sportKindError: false,
        }));
      },
      handleBlur: (type) => {
        if (!type) {
          onChangeFormErrors((prevstate) => ({
            ...prevstate,
            sportKindError: true,
          }));
        }
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData.id],
  );

  const tourismKindController: IController<string | undefined> = useMemo(
    () => ({
      handleChange: (tourismKind) => {
        onChangeFormData((prevstate) => ({
          ...prevstate,
          tourismKind: Number(tourismKind),
          subjectId: [],
        }));
        onChangeFormErrors((prevstate) => ({
          ...prevstate,
          sportKindError: false,
        }));
      },
      handleBlur: (type) => {
        if (!type) {
          onChangeFormErrors((prevstate) => ({
            ...prevstate,
            sportKindError: true,
          }));
        }
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData.id],
  );

  const sportAgesRefFiltered =
    sportAgesRef.content?.filter((sportAge) => sportAge.sectionCode === formData.typeCode && !sportAge.isArchive) || [];

  const getOccupationNameInputLabel = () => {
    if (formData.typeCode === SectionCodes.sportOccupationCircle) return 'Название кружка';
    if (formData.typeCode === SectionCodes.sportOccupationSection) return 'Название секции';
    return '';
  };

  const getOccupationNameInputPlaceHolder = () => {
    if (formData.typeCode === SectionCodes.sportOccupationCircle) return 'Введите название кружка...';
    if (formData.typeCode === SectionCodes.sportOccupationSection) return 'Введите название секции...';
    return '';
  };

  const getTourismExpeditionOrganizatorLabel = () => {
    if (formData.typeCode === SectionCodes.sportEventExpedition) return 'Организатор похода / экспедиции';
    if (formData.typeCode === SectionCodes.sportEventTourism) return 'Организатор первенства по туризму';
    return '';
  };

  const getTourismExpeditionOrganizatorPlaceholder = () => {
    if (formData.typeCode === SectionCodes.sportEventExpedition)
      return 'Введите название организатора похода / экспедиции...';
    if (formData.typeCode === SectionCodes.sportEventTourism)
      return 'Введите название организатора первенства по туризму...';
    return '';
  };

  const getSubjectLabel = () => {
    if (formData.subCategory === subcategoryCodes.sport) return 'Подвид спорта';
    if (formData.subCategory === subcategoryCodes.tourism) return 'Подвид туризма';
    return 'Подвид спорта';
  };

  const getSubjectPlaceholder = () => {
    const isSport = formData.subCategory === subcategoryCodes.sport;
    const isTourism = formData.subCategory === subcategoryCodes.tourism;

    if (isSport) {
      return formData.sportKind ? 'Начните вводить название подвида спорта...' : 'Сначала выберите вид спорта...';
    }

    if (isTourism) {
      return formData.tourismKind ? 'Начните вводить название подвида туризма...' : 'Сначала выберите вид туризма...';
    }

    return 'Сначала выберите вид спорта...';
  };

  const getEventKindData = (): IEventKind[] => {
    switch (formData.typeCode) {
      case SectionCodes.sportEventCompetition:
        return competitionKindData.content;
      case SectionCodes.sportEventExpedition:
        return expeditionKindData.content;
      case SectionCodes.sportEventTourism:
        return tourismKindData.content;
      default:
        return [];
    }
  };

  const filterSubjects = () => {
    switch (formData.subCategory) {
      case SportSubcategoryCodes.tourism:
        return tourismKinds.content?.filter((tourismKind) => tourismKind.parentId === formData.tourismKind);
      case SportSubcategoryCodes.sport:
      default:
        return sportKinds.content?.filter((sportKind) => sportKind.parentId === formData.sportKind);
    }
  };

  const currentEventKindData = getEventKindData()?.find((activity) => activity.code === formData.name);

  const eventData = {
    type:
      activityTypeData.content?.find((portfolioKind) => portfolioKind.code === currentEventKindData?.categoryCode)
        ?.value || 'Соревнование',
    name: currentEventKindData?.value,
    ageLimit: getAgeLimitFromArray(currentEventKindData?.ageLimits),
    levelEvent: currentEventKindData?.levelEvent
      ? olympiadTypes.content?.find((type) => type.code === currentEventKindData?.levelEvent)?.value
      : undefined,
    levelEventCode: currentEventKindData?.levelEvent,
    organizators: currentEventKindData?.organizators,
  };

  const sportClubsName =
    formData?.name === WithoutOrganizationCode
      ? 'Клуб/команда'
      : sportClubs?.content.find((name) => name.code === formData.name)?.value ?? 'Клуб/Команда';

  return (
    <>
      {formData.dataKind === SectionCodes.sportAffilation && (formData.name || formData.secondName) && (
        <SportAffiliationSecondaryBlock
          name={sportClubsName}
          sportTrainingStageOptions={sportTrainingStage.content}
          nameInputLabel="Название спортивной организации"
          nameInputPlaceholder="Введите название спортивной организации..."
        >
          <FormControl
            required
            error={formErrors.sportKindError}
            renderMode={inputRenderMode}
            inputSize={inputSize}
            label={<BaseInputLabel>Вид спорта</BaseInputLabel>}
            helper={formErrors.sportKindError && <FormHelperText>Выберите вид спорта</FormHelperText>}
            control={
              <SearchSelect
                inputRenderMode={inputRenderMode}
                inputSize={inputSize}
                placeholder="Выберите вид спорта..."
                value={formData.sportKind}
                options={sportKindOptions}
                controller={sportKindController}
                strictMaxWidth
              />
            }
          />
        </SportAffiliationSecondaryBlock>
      )}
      {formData.dataKind === SectionCodes.sportOccupation && formData.typeCode && (
        <OccupationSecondaryBlock
          subCategoryOptions={subcategories.content}
          nameInputLabel={getOccupationNameInputLabel()}
          nameInputPlaceholder={getOccupationNameInputPlaceHolder()}
          subjectInputLabel={getSubjectLabel()}
          subjectInputPlaceholder={getSubjectPlaceholder()}
          isSubjectInputDisabled={!(formData?.sportKind || formData?.tourismKind)}
          subjectOptions={filterSubjects()}
        >
          {formData.subCategory === subcategoryCodes.tourism ? (
            <FormControl
              required
              disabled={!formData.subCategory}
              error={formErrors.sportKindError}
              renderMode={inputRenderMode}
              inputSize={inputSize}
              label={<BaseInputLabel>Вид туризма</BaseInputLabel>}
              helper={formErrors.sportKindError && <FormHelperText>Выберите вид туризма</FormHelperText>}
              control={
                <SearchSelect
                  inputRenderMode={inputRenderMode}
                  inputSize={inputSize}
                  placeholder={formData.subCategory ? 'Выберите вид туризма...' : 'Сначала выберите подкатегорию'}
                  value={formData.tourismKind}
                  options={tourismKindOptions}
                  controller={tourismKindController}
                  strictMaxWidth
                />
              }
            />
          ) : (
            <FormControl
              required
              disabled={!formData.subCategory}
              error={formErrors.sportKindError}
              renderMode={inputRenderMode}
              inputSize={inputSize}
              label={<BaseInputLabel>Вид спорта</BaseInputLabel>}
              helper={formErrors.sportKindError && <FormHelperText>Выберите вид спорта</FormHelperText>}
              control={
                <SearchSelect
                  inputRenderMode={inputRenderMode}
                  inputSize={inputSize}
                  placeholder={formData.subCategory ? 'Выберите вид спорта...' : 'Сначала выберите подкатегорию'}
                  value={formData.sportKind}
                  options={sportKindOptions}
                  controller={sportKindController}
                  strictMaxWidth
                />
              }
            />
          )}
        </OccupationSecondaryBlock>
      )}
      {formData.dataKind === SectionCodes.sportEvent &&
        formData.typeCode === SectionCodes.sportEventCompetition &&
        formData.name && (
          <CompetitionSecondaryBlock
            sportKindOptions={sportKindOptions}
            eventData={eventData}
            subjectOptions={sportKinds.content?.filter((sportKind) => sportKind.parentId === formData?.sportKind) || []}
            eventLevelOptions={olympiadTypes?.content || []}
          />
        )}
      {formData.dataKind === SectionCodes.sportEvent &&
        (formData.typeCode === SectionCodes.sportEventExpedition ||
          formData.typeCode === SectionCodes.sportEventTourism) &&
        formData.name && (
          <TourismExpeditionSecondaryBlock
            tourismKindOptions={tourismKindOptions}
            eventData={eventData}
            organizatorLabel={getTourismExpeditionOrganizatorLabel()}
            organizatorPlaceholder={getTourismExpeditionOrganizatorPlaceholder()}
            nameLabel={
              formData.typeCode === SectionCodes.sportEventExpedition
                ? 'Название похода / экспедиции'
                : 'Название первенства по туризму'
            }
            namePlaceholder={
              formData.typeCode === SectionCodes.sportEventCompetition
                ? 'Введите название похода / экспедиции...'
                : 'Введите название первенства по туризму...'
            }
            subjectOptions={
              tourismKinds.content?.filter((tourismKind) => tourismKind.parentId === formData?.tourismKind) || []
            }
            eventLevelOptions={olympiadTypes?.content || []}
          />
        )}
      {formData.dataKind === SectionCodes.sportReward &&
        formData.typeCode === SectionCodes.gtoReward &&
        (formData.rewardNumber || formData.name) && <GtoRewardSecondaryBlock ageOptions={sportAgesRefFiltered} />}
      {formData.dataKind === SectionCodes.sportReward &&
        (formData.rewardNumber || formData.name) &&
        (formData.typeCode === SectionCodes.sportRank || formData.typeCode === SectionCodes.sportCategory) && (
          <SportRankRewardSecondaryBlock sportKindOptions={sportKindOptions} />
        )}
      {formData.dataKind === SectionCodes.sportReward &&
        formData.typeCode === SectionCodes.sportRewardTourism &&
        (formData.rewardNumber || formData.reward) && (
          <TourismRewardSecondaryBlock levelOptions={olympiadTypes?.content || []} ageOptions={sportAgesRefFiltered} />
        )}
      {formData.dataKind === SectionCodes.sportReward &&
        formData.typeCode === SectionCodes.sportRewardCompetition &&
        (formData.rewardNumber || formData.name) && (
          <NameRewardSecondaryBlock
            result={sportRewards.content?.find((reward) => reward.code === formData.sportRewardCode)?.value}
          />
        )}
    </>
  );
};

const portfolioActivityTypeMapping = createSelector(
  [(state: IRootState) => sectionRefArchiveFalseSelector(state)],
  (sections) => ({
    content: sections.content?.filter((section) => section.parentId === SectionCodes.sportEvent) || [],
    loading: sections.loading,
  }),
);

const competitionKindsMapping = createSelector([(state: IRootState) => state.eventKinds], (eventKinds) => ({
  content:
    eventKinds?.content?.filter((eventKind) => eventKind.categoryCode === SectionCodes.sportEventCompetition) || [],
  loading: eventKinds.loading,
}));

const expeditionKindsMapping = createSelector([(state: IRootState) => state.eventKinds], (eventKinds) => ({
  content:
    eventKinds?.content?.filter((eventKind) => eventKind.categoryCode === SectionCodes.sportEventExpedition) || [],
  loading: eventKinds.loading,
}));

const tourismKindsMapping = createSelector([(state: IRootState) => state.eventKinds], (eventKinds) => ({
  content: eventKinds?.content?.filter((eventKind) => eventKind.categoryCode === SectionCodes.sportEventTourism) || [],
  loading: eventKinds.loading,
}));

const eventNamesMapping = createSelector([(state: IRootState) => state.eventKinds], (eventKinds) => ({
  content:
    eventKinds?.content?.filter(
      (eventKind) =>
        eventKind.categoryCode === SectionCodes.sportEventExpedition ||
        eventKind.categoryCode === SectionCodes.sportEventTourism,
    ) || [],
  loading: eventKinds.loading,
}));

const subcategoriesMapping = createSelector([(state: IRootState) => state.portfolioSubcategories], (subcategories) => ({
  content:
    subcategories.content?.filter((subcategory) => subcategory?.parentId === SubcategoryParentsCodes.sport) || [],
  loading: subcategories.loading,
}));

const sportTrainingStageMappinng = createSelector(
  [(state: IRootState) => state.sportTrainingStage],
  (sportTrainingStage) => ({
    content: sportTrainingStage.content,
    loading: sportTrainingStage.loading,
  }),
);
export default connect((state: IRootState) => ({
  activityTypeData: portfolioActivityTypeMapping(state),
  competitionKindData: competitionKindsMapping(state),
  expeditionKindData: expeditionKindsMapping(state),
  tourismKindData: tourismKindsMapping(state),
  tourismKinds: state.tourismKinds,
  sportAgesRef: state.sportAgesRef,
  eventNamesData: eventNamesMapping(state),
  sportKinds: state.sportKinds,
  sportRewards: state.sportRewardKinds,
  sportClubs: state.sportClubs,
  sportTrainingStage: sportTrainingStageMappinng(state),
  subcategories: subcategoriesMapping(state),
  olympiadTypes: state.olympiadTypes,
}))(SportSecondaryBlock);
