import { FC } from 'react';
import { DateRangePickerState } from 'react-stately';

import { Box, Typography } from '@mui/material';
import { getAccentColor, NeutralColors } from 'portfolio3/styles';
import { getButtonStyles } from 'portfolio3/ui-kit/utils';
import { SxStyles } from 'types';

import { RangePresetNameMapping } from '../../const';
import { RangePreset } from '../../localTypes';
import { dateToCalendarDate } from '../../utils';
import OptionsPanel from '../OptionsPanel';

const list: SxStyles = {
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
};

const button = (selected: boolean): SxStyles => {
  return {
    ...getButtonStyles(),

    height: '36px',
    padding: '8px 12px',

    borderRadius: '6px',
    textAlign: 'start',
    color: selected ? NeutralColors.light_neutrals_0 : NeutralColors.light_neutrals_900,
    backgroundColor: selected ? getAccentColor('indigo', '100') : 'transparent',
  };
};

interface IRangePresetsPanelProps {
  currentPreset: RangePreset | null;
  presets: RangePreset[];
  onChange: (preset: RangePreset) => void;
  dateRangePickerState: DateRangePickerState;
}

const RangePresetsPanel: FC<IRangePresetsPanelProps> = ({ currentPreset, presets, onChange, dateRangePickerState }) => {
  const applyPreset = (preset: RangePreset) => {
    const today = new Date();
    const calendarToday = dateToCalendarDate(today);
    const calendarTomorrow = calendarToday.add({ days: 1 });

    if (preset === 'tomorrow') {
      dateRangePickerState.setDateRange({ start: calendarTomorrow, end: calendarTomorrow });
      return;
    }

    if (preset === 'selected range') {
      return;
    }

    let subtractDays = 0;
    if (preset === 'today') subtractDays = 0;
    if (preset === 'last 2 days') subtractDays = 1;
    if (preset === 'last 7 days') subtractDays = 7;
    if (preset === 'last month') subtractDays = 30;
    if (preset === 'last year') subtractDays = 365;

    dateRangePickerState.setDateRange({ start: calendarToday.subtract({ days: subtractDays }), end: calendarToday });
  };

  const handleChange = (preset: RangePreset) => {
    applyPreset(preset);
    onChange(preset);
  };

  return (
    <OptionsPanel type="presets">
      <Box sx={list}>
        {presets.map((preset) => {
          const isSelected = preset === currentPreset;
          const presetName = RangePresetNameMapping[preset];

          return (
            <Typography
              key={preset}
              component="button"
              variant={isSelected ? 'Paragraph MD/Semi Bold' : 'Paragraph MD/Regular'}
              onClick={() => handleChange(preset)}
              sx={button(isSelected)}
            >
              {presetName}
            </Typography>
          );
        })}
      </Box>
    </OptionsPanel>
  );
};

export default RangePresetsPanel;
