import { FC, SyntheticEvent, useState } from 'react';

import { MenuProps } from '@mui/material';
import { DifferentCode, DifferentValue } from 'const';
import { ISearchableMenuProps, SearchableMenu } from 'portfolio3/ui-kit/menu';

import { IModalSelectBaseProps, ModalSelectBase } from '../../bases';
import { useSelectMenuList } from '../../hooks';
import { ISelectItem, ISelectOptionsListSettings } from '../../types';

interface ISearchSelectProps
  extends Omit<IModalSelectBaseProps<MenuProps>, 'ModalComponent' | 'ModalComponentProps' | 'children'> {
  options?: ISelectItem[];
}

const SearchSelect: FC<ISearchSelectProps & ISelectOptionsListSettings> = ({
  options,
  addOtherOption,
  markOtherOption,
  markFirstOption,
  ...props
}) => {
  const TypedSelect = ModalSelectBase<ISearchableMenuProps>;

  const [searchValue, setSearchValue] = useState('');

  const menuList = useSelectMenuList({
    options: options ?? [],
    search: searchValue,
    addOtherOption,
    markOtherOption,
    markFirstOption,
  });

  const handleOpen = (e: SyntheticEvent<Element, Event>) => {
    props.onOpen?.(e);
    setSearchValue('');
  };

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
  };

  const renderValue = (value: string | undefined) => {
    if (Number(value) === DifferentCode) return DifferentValue;
    return options?.find((option) => option.code === Number(value))?.value;
  };

  return (
    <TypedSelect
      {...props}
      onOpen={handleOpen}
      ModalComponent={SearchableMenu}
      ModalComponentProps={{ searchValue, onSearchChange: handleSearchChange }}
      renderValue={props.renderValue ?? renderValue}
    >
      {menuList}
    </TypedSelect>
  );
};

export default SearchSelect;
