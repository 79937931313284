/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { DialogActions } from '@mui/material';
import CommonDialog, { ICommonDialog } from '../../../ui-kit/dialog/CommonDialog';
import { Button } from '../../../ui-kit';
import useDialogHash from '../../../hooks/useDialogHash';

interface ISubmitDialogProps extends ICommonDialog {
  submitButtonTitle?: string;
  isButtonsDisabled?: boolean;
  onSubmit: () => void;
}

const SubmitDialog: React.FC<ISubmitDialogProps> = ({
  className,
  children,
  isOpen,
  title,
  submitButtonTitle,
  description,
  isButtonsDisabled,
  onSubmit,
  onClose
}) => {
  useDialogHash(isOpen, 'submit');

  const dialogActions = () => (
    <DialogActions className="dialog__actions">
      <Button
        size="large"
        className="dialog__button"
        disabled={isButtonsDisabled}
        variant="outlined"
        onClick={onClose}
      >
        Отмена
      </Button>
      <Button
        size="large"
        disabled={isButtonsDisabled}
        className="dialog__button"
        onClick={onSubmit}
      >
        {submitButtonTitle}
      </Button>
    </DialogActions>
  );

  return (
    <CommonDialog
      isOpen={isOpen}
      description={description}
      title={title}
      actions={dialogActions()}
      onClose={onClose}
      className={`dialog ${className}`}
    >
      {children}
    </CommonDialog>
  );
};

export default SubmitDialog;
