import { action, createRequestTypes, FAILURE, INVALIDATE, REQUEST, SUCCESS } from 'actions/utils';
import { IVuzSearchQueryParams } from 'api/types';

import { IVuzInfo } from '../types';

export const GET_VUZ_LIST = createRequestTypes('GET_VUZ_LIST');
export const getVuzListActions = {
  request: (queryParams: IVuzSearchQueryParams) => action(GET_VUZ_LIST[REQUEST], { payload: [queryParams] }),
  success: (response: unknown) => action(GET_VUZ_LIST[SUCCESS], { payload: response }),
  failure: (error: unknown) => action(GET_VUZ_LIST[FAILURE], { payload: error }),
  invalidate: () => action(GET_VUZ_LIST[INVALIDATE]),
};

export const GET_VUZ_LIST_COUNT = createRequestTypes('GET_VUZ_LIST_COUNT');
export const getVuzListCountActions = {
  request: (queryParams: IVuzSearchQueryParams) => action(GET_VUZ_LIST_COUNT[REQUEST], { payload: [queryParams] }),
  success: (response: unknown) => action(GET_VUZ_LIST_COUNT[SUCCESS], { payload: response }),
  failure: (error: unknown) => action(GET_VUZ_LIST_COUNT[FAILURE], { payload: error }),
  invalidate: () => action(GET_VUZ_LIST_COUNT[INVALIDATE]),
};

export const GET_EGE_EXAM_SUBJECTS_REF = createRequestTypes('GET_EGE_EXAM_SUBJECTS_REF');
export const getEgeExamSubjectsRefActions = {
  request: () => action(GET_EGE_EXAM_SUBJECTS_REF[REQUEST]),
  success: (response: unknown) => action(GET_EGE_EXAM_SUBJECTS_REF[SUCCESS], { payload: response }),
  failure: (error: unknown) => action(GET_EGE_EXAM_SUBJECTS_REF[FAILURE], { payload: error }),
};

export const GET_SPECIALITIES_REF = createRequestTypes('GET_SPECIALITIES_REF');
export const getSpecialitiesRefActions = {
  request: () => action(GET_SPECIALITIES_REF[REQUEST]),
  success: (response: unknown) => action(GET_SPECIALITIES_REF[SUCCESS], { payload: response }),
  failure: (error: unknown) => action(GET_SPECIALITIES_REF[FAILURE], { payload: error }),
};

export const VUZ_DETAILS_DRAWER_SET_OPEN = 'VUZ_DETAILS_DRAWER_SET_OPEN';
export const VUZ_DETAILS_DRAWER_SET_INFO = 'VUZ_DETAILS_DRAWER_SET_INFO';
export const vuzDetailsDrawerActions = {
  setOpen: (open: boolean) => action(VUZ_DETAILS_DRAWER_SET_OPEN, { payload: open }),
  setInfo: (info: IVuzInfo | null) => action(VUZ_DETAILS_DRAWER_SET_INFO, { payload: info }),
};

export const GET_FAVORITE_VUZ_LIST = createRequestTypes('GET_FAVORITE_VUZ_LIST');
export const getFavoriteVuzListActions = {
  request: (personId: string) => action(GET_FAVORITE_VUZ_LIST[REQUEST], { payload: [personId] }),
  success: (response: unknown) => action(GET_FAVORITE_VUZ_LIST[SUCCESS], { payload: response }),
  failure: (error: unknown) => action(GET_FAVORITE_VUZ_LIST[FAILURE], { payload: error }),
  invalidate: () => action(GET_FAVORITE_VUZ_LIST[INVALIDATE]),
};

export const ADD_FAVORITE_VUZ = createRequestTypes('ADD_FAVORITE_VUZ');
export const addFavoriteVuzActions = {
  request: (personId: string, vuzId: number) => action(ADD_FAVORITE_VUZ[REQUEST], { payload: [personId, vuzId] }),
  success: () => action(ADD_FAVORITE_VUZ[SUCCESS], { payload: null }),
  failure: () => action(ADD_FAVORITE_VUZ[FAILURE], { payload: null }),
};

export const DELETE_FAVORITE_VUZ = createRequestTypes('DELETE_FAVORITE_VUZ');
export const deleteFavoriteVuzActions = {
  request: (personId: string, vuzId: number) => action(DELETE_FAVORITE_VUZ[REQUEST], { payload: [personId, vuzId] }),
  success: () => action(DELETE_FAVORITE_VUZ[SUCCESS], { payload: null }),
  failure: () => action(DELETE_FAVORITE_VUZ[FAILURE], { payload: null }),
};
