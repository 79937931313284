import { CLEAR_ENTITY_ERROR_MESSAGES, GET_ENTITY_ERROR_MESSAGES } from '../../actions';
import { FAILURE, REQUEST, SUCCESS } from '../../actions/utils';
import { IEntityErrorMessage } from '../../api/types';
import { connectReducers } from '../../utils';
import { IReducers } from '..';

export type EntityErrorMessagesState = {
  loading: boolean;
  success: boolean;
  errors: IEntityErrorMessage[];
};

type GetEntityErrorMessagesSuccessAction = {
  payload: IEntityErrorMessage[];
};

const initialState: EntityErrorMessagesState = {
  loading: false,
  success: false,
  errors: [],
};

const entityErrorMessages: IReducers<EntityErrorMessagesState> = {
  [GET_ENTITY_ERROR_MESSAGES[REQUEST]]: () => ({
    loading: true,
    success: false,
    errors: [],
  }),
  [GET_ENTITY_ERROR_MESSAGES[SUCCESS]]: (_, action: GetEntityErrorMessagesSuccessAction) => ({
    loading: false,
    success: true,
    errors: action.payload,
  }),
  [GET_ENTITY_ERROR_MESSAGES[FAILURE]]: () => ({
    loading: false,
    success: true,
    errors: [],
  }),
  [CLEAR_ENTITY_ERROR_MESSAGES]: () => ({
    loading: false,
    success: false,
    errors: [],
  }),
};

export default connectReducers(initialState, entityErrorMessages);
