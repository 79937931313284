import { SxStyles } from 'types';

export const root: SxStyles = (theme) => ({
  paddingBlock: '16px',

  [theme.breakpoints.down('commonSm')]: {
    paddingBlock: 0,
  },
});

export const row: SxStyles = (theme) => ({
  paddingInline: '24px',

  [theme.breakpoints.down('commonSm')]: {
    paddingInline: '16px',
  },
});
