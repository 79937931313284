import { createSelector } from 'reselect';

import { IRootState } from '../../../reducers';
import { mapCreationOccupationsResponse } from './mappers';

const creationOccupationsSelector = createSelector(
  [(state: IRootState) => state.studentEmployments],
  (studentOccupations) => {
    return mapCreationOccupationsResponse(studentOccupations);
  },
);

export default creationOccupationsSelector;
