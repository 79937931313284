export enum RegionalCodeKeys {
  MOSCOW = 'moscow',
  // Московская Область
  MOSCOW_MO = 'moscowMO',
  CALUGA = 'caluga',
}

export const RegionalCodes: Record<RegionalCodeKeys, number> = {
  moscow: 77,
  moscowMO: 50,
  caluga: 40,
};
