import { FC, useRef, useState } from 'react';

import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import { Tooltip } from 'ui-kit';

import { IPlaceBar } from '../types';
import BarAvatar from './BarAvatar';
import BarPlace from './BarPlace';
import * as styles from './style';

type PlaceBarProps = IPlaceBar;

const PlaceBar: FC<PlaceBarProps> = ({ place, percent, text, yours, Emoji }) => {
  const [isTextShort, setTextShort] = useState(true);

  const tooltipTargetRef = useRef<HTMLElement>(null);

  const chartText = isTextShort ? text : `Средний результат ${text}`;

  function handleRefMoveTooltipTarget(node: HTMLDivElement | null) {
    if (!node || !tooltipTargetRef.current) return;

    const rootRightPosition = node.getBoundingClientRect().right;
    const tooltipTargetPosition = tooltipTargetRef.current.getBoundingClientRect().right;

    const difference = rootRightPosition - tooltipTargetPosition;

    // Если область тултипа начинает выходить за границу полоски, то прижимается к самой правой части полоски
    if (difference < 0) {
      tooltipTargetRef.current.style.right = '0';
    }
  }

  function handleRefTrimText(node: HTMLDivElement | null) {
    if (!node || text.includes('чел')) return;

    node.offsetWidth >= 180 ? setTextShort(false) : setTextShort(true);
  }

  return (
    <Box className="placeBar" sx={styles.placeBar(yours, percent)} ref={handleRefMoveTooltipTarget}>
      <BarPlace yours={yours} place={place} />
      <Box className="placeBar-text" ref={handleRefTrimText} sx={styles.placeBarTypographyContainer}>
        <Typography className={clsx(yours && 'active')} sx={styles.placeBarTypography} variant="Paragraph MD/Medium">
          {chartText}
          <Tooltip title={isTextShort ? text : ''} placement="top" arrow>
            <Box sx={styles.tooltipTarget} ref={tooltipTargetRef} />
          </Tooltip>
        </Typography>
      </Box>
      <Box sx={styles.avatar}>
        <BarAvatar yours={yours} Emoji={Emoji} />
      </Box>
    </Box>
  );
};

export default PlaceBar;
