import React from 'react';

import AdminAnalyticsContainer from '../../containers/admin/Analytics';

import './index.scss';

const AdminAnalyticsPage: React.FC = () => (
  <div className="analytics-page">
    <AdminAnalyticsContainer />
  </div>
);

export default AdminAnalyticsPage;
