import { useDispatch } from 'react-redux';

import { setCurrentStudentAction } from 'actions';
import { IPerson } from 'api/types';
import { useAppSelector, useDisclosure } from 'hooks';
import { Button } from 'portfolio3/ui-kit/button';

import DataEntryDrawer from '../../components/DataEntryDrawer';
import { mapPersonToChildInfo } from '../../utils';

export const useOperatorStudentResult = (foundStudent: IPerson | undefined) => {
  const dispatch = useDispatch();
  const organizations = useAppSelector((state) => state.organizations.content);

  const { isOpen: isDrawerOpen, onClose: onDrawerClose, onOpen: onDrawerOpen } = useDisclosure();

  const openDataEntry = (student: IPerson | undefined) => {
    if (!student) return;

    const mappedStudent = mapPersonToChildInfo(student, organizations);

    dispatch(setCurrentStudentAction(mappedStudent));
    onDrawerOpen();
  };

  const operatorRightElement = (
    <Button onClick={() => openDataEntry(foundStudent)} size="small">
      Внести данные
    </Button>
  );

  const operatorDrawerElement = <DataEntryDrawer isOpen={isDrawerOpen} onClose={onDrawerClose} students={[]} />;

  return {
    operatorRightElement,
    operatorDrawerElement,
  };
};
