import ym from 'react-yandex-metrika';

import { YandexMetrikaEvent } from '../events';

export const getYMEventPayload = (event: YandexMetrikaEvent) => {
  return 'payload' in event ? event.payload : undefined;
};

export const emitYMEvent = (event: YandexMetrikaEvent) => {
  const payload = 'payload' in event ? event.payload : undefined;
  ym('reachGoal', event.type, payload);
};
