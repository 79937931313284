import { FC, useContext } from 'react';
import { connect } from 'react-redux';

import { EntityType } from 'const';
import { PortfolioSettingsContext } from 'context';
import { EntityActionsMenuButton, useCommonEntityHandlers } from 'portfolio3/components/entityActions';
import FileCard from 'portfolio3/components/proftech/FileCard';
import { IRootState } from 'reducers';
import { IStudyDocumentEntity } from 'types';

interface IStudyDocumentDataEntityProps {
  entity: IStudyDocumentEntity;
  linkMode: boolean;
}

const StudyDocumentDataEntity: FC<IStudyDocumentDataEntityProps> = ({ entity, linkMode }) => {
  const { name, badge, formData, cardData } = entity;

  const { isSettingsMode } = useContext(PortfolioSettingsContext);
  const { getHandlers } = useCommonEntityHandlers();

  const { handleView, handleEdit, handleDelete, handleDownloadAttachments, deleteDialogElement } = getHandlers({
    formData,
    cardData,
  });

  const isActionsVisible = !linkMode && !isSettingsMode;
  const actionsElement = isActionsVisible ? (
    <>
      <EntityActionsMenuButton
        formData={formData}
        entityType={EntityType.DOCUMENT}
        entityFlags={entity.cardData.entityFlags ?? []}
        actionsMap={{
          view: {
            effect: handleView,
          },
          edit: {
            effect: handleEdit,
          },
          delete: {
            effect: handleDelete(),
          },
          download: {
            effect: handleDownloadAttachments(cardData.files),
          },
        }}
      />
      {deleteDialogElement}
    </>
  ) : undefined;

  return (
    <FileCard
      // TODO добавить превью
      preview={undefined}
      previewPlaceholderText="JPG"
      badge={badge}
      name={name}
      actionsButtonElement={actionsElement}
    />
  );
};

export default connect((state: IRootState) => ({
  linkMode: state.linkMode.mode ?? false,
}))(StudyDocumentDataEntity);
