import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

import { numberToPixelsString } from './../../../../../../utils/common';

const rowActiveBorderColor = 'rgba(250, 176, 5, 1)';
const rowActiveColor = 'rgba(250, 176, 5, 0.08)';

// TODO добавить адаптив (36)
// вынести в константы?
const barCircleSize = 44;

export const chartRow =
  (yours?: boolean, resultBarProps?: boolean): SxStyles =>
  ({ breakpoints }) => ({
    display: 'flex',
    alignItems: 'center',
    // TODO вынести 22 в константы?
    paddingInline: '22px',
    paddingBlock: yours && resultBarProps ? '12px' : '6px',

    border: `1px dashed ${yours && resultBarProps ? rowActiveBorderColor : 'transparent'}`,
    borderRight: '1px solid transparent',
    borderLeft: '1px solid transparent',
    backgroundColor: yours && resultBarProps ? rowActiveColor : 'transparent',
    zIndex: 2,

    // визуальное смещение кружков на графике
    width: `calc(100% + ${numberToPixelsString(barCircleSize)})`,
    transform: `translateX(-${numberToPixelsString(barCircleSize / 2)})`,

    [breakpoints.down('commonSm')]: resultBarProps && {
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      gap: '8px',

      paddingInline: '0',
      paddingBlock: '16px',
      borderBottom: !yours && `1px solid ${NeutralColors.light_neutrals_300}`,
    },
  });

export const barContainer =
  (hideResult?: boolean): SxStyles =>
  ({ breakpoints }) => ({
    width: hideResult ? `65%` : '100%',

    [breakpoints.down('commonMd')]: {
      width: hideResult ? `60%` : '100%',
    },

    [breakpoints.down('commonSm')]: {
      width: '100%',
    },
  });

export const resultContainer: SxStyles = ({ breakpoints }) => ({
  width: 'calc(35% - 12px)',
  paddingLeft: '12px',

  [breakpoints.down('commonMd')]: {
    width: 'calc(40% - 12px)',
  },

  [breakpoints.down('commonSm')]: {
    width: '45%',
  },

  [breakpoints.down(600)]: {
    width: '65%',
  },
});
