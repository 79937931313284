import { FC } from 'react';

import { ToggleLabelOutline } from 'portfolio3/ui-kit/forms';
import Toggle, { IToggleProps } from 'portfolio3/ui-kit/Toggle';
import { CommonUiKitSize } from 'portfolio3/ui-kit/types';

interface IToggleOutlineProps extends IToggleProps {
  label: string;
  size?: CommonUiKitSize;
}

/**
 * Toggle компонент с серым border\
 * Использует внутри себя только ToggleLabelOutline + Toggle
 * @example
 * <ToggleOutline
     label="Название"
     size="large"
     checked={checked}
     onChange={(_, checked) => setChecked(checked)}
    />
 */
const ToggleOutline: FC<IToggleOutlineProps> = ({ sx, size, label, disabled, ...props }) => {
  return (
    <ToggleLabelOutline
      sx={sx}
      size={size}
      label={label}
      disabled={disabled}
      control={<Toggle size={size} disabled={disabled} {...props} />}
    />
  );
};

export default ToggleOutline;
