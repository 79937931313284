/* eslint-disable max-len */
import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconSectionProfession: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17.177 4.932h-3.104V1.973a.999.999 0 0 0-.273-.69.928.928 0 0 0-.665-.283H6.858a.92.92 0 0 0-.663.285.992.992 0 0 0-.275.688v2.959H2.823c-.484 0-.947.199-1.29.554A1.929 1.929 0 0 0 1 6.824V17.1c-.001.25.045.496.137.727.09.23.225.44.394.616.17.177.37.317.592.412.222.096.46.145.7.145h14.354c.24 0 .478-.05.7-.145.221-.095.422-.235.592-.412.17-.176.303-.386.394-.616.092-.23.138-.478.137-.727V6.824c0-.502-.192-.983-.534-1.338a1.79 1.79 0 0 0-1.289-.554ZM7.795 3.11a.174.174 0 0 1 .048-.114.162.162 0 0 1 .11-.05h4.094c.041.002.08.02.107.05.028.031.043.072.043.114v1.822H7.795V3.11Zm8.857 13.944H3.348a.457.457 0 0 1-.335-.144.493.493 0 0 1-.138-.347v-4.562H5.92v2.079c0 .258.099.505.275.688a.92.92 0 0 0 .663.285.92.92 0 0 0 .662-.285.992.992 0 0 0 .275-.688V12h4.402v2.08c0 .258.1.505.275.688a.92.92 0 0 0 .663.285.92.92 0 0 0 .663-.285.992.992 0 0 0 .274-.688V12h3.053v4.563a.5.5 0 0 1-.138.347.464.464 0 0 1-.334.144Z"
      fill={props.fill || 'currentColor' || '#CC5DE8'}
    />
  </SvgIcon>
);

export default IconSectionProfession;
