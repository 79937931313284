/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { IconArrowOpen } from '../../../../icons';
import { Button } from '../../../../ui-kit';

import './index.scss';

interface IInterestsFooterProps {
  submitButtonTitle?: string,
  submitDisabled?: boolean,
  onSubmit: React.MouseEventHandler<HTMLButtonElement>,
  onBack: React.MouseEventHandler<HTMLButtonElement>,
}

const InterestsFooter: React.FC<IInterestsFooterProps> = ({
  submitButtonTitle = 'Далее',
  submitDisabled,
  onSubmit,
  onBack
}) => (
  <footer className="interests-footer">
    <Button
      className="interests-footer__button"
      size="large"
      variant="outlined"
      color="secondary"
      startIcon={<IconArrowOpen className="interests-footer__btn-icon interests-footer__btn-icon--reversed" />}
      onClick={onBack}
    >
      Назад
    </Button>
    <Button
      className="interests-footer__button"
      size="large"
      endIcon={<IconArrowOpen className="interests-footer__btn-icon" />}
      onClick={onSubmit}
      disabled={submitDisabled}
    >
      {submitButtonTitle}
    </Button>
  </footer>
);

export default InterestsFooter;
