import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconLikeOutline: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      // eslint-disable-next-line max-len
      d="M6.475 21.608V8.412l5.13-5.47a1.912 1.912 0 0 1 1.052-.589c.4-.074.783-.014 1.15.18.354.186.616.472.785.858.17.386.2.789.091 1.209l-.812 3.812h7.277c.52 0 .981.2 1.387.598.405.4.608.86.608 1.382v1.41c0 .137.01.278.03.422.02.145.001.276-.058.392l-3.207 7.434c-.181.44-.488.81-.92 1.109-.432.299-.884.449-1.357.449H6.475ZM8.3 9.39v10.237h9.656l3.178-7.545v-1.69h-9.607l1.198-5.702L8.3 9.39ZM3.475 21.608a1.912 1.912 0 0 1-1.406-.575 1.912 1.912 0 0 1-.575-1.406v-9.235c0-.544.192-1.01.575-1.398a1.9 1.9 0 0 1 1.406-.582h3v1.98h-3v9.235h3v1.98h-3Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconLikeOutline;
