/* eslint-disable prettier/prettier */
/* eslint-disable */

import { IPortfolioEntryFormData } from 'portfolio3/features/dataEntryForm';
import { defaultPortfolioEntryFormData } from 'components/common/PortfolioDataEntryForm/utils';
import { format } from 'date-fns';
import { getAccentColor } from 'portfolio3/styles';
import { IRootState } from 'reducers';
import store from 'store';
import { IGeneraInfoItem, IUserFunctionality } from 'types';

import {
  IActiveTab, IDictionaryItem, ISource, Job, Practice, ProffEducation, SpoDocument
} from '../api/types';
import {
  AUTOMATICALLY_ADDED_SOURCE_VALUE, DocumentTypeCodes, SectionCodes, SectionColors, SectionNames, SourceCode, SportSubcategoryCodes
} from '../const';
import {
  IconCreation,
  IconCulture,
  IconProfession,
  IconSchool,
  IconScience,
  IconSport,
  IconStar,
  widgetIcons,
} from '../icons';
import { cardIllustrations } from '../images';
import { isDefined } from './common';

import {
  createPeriod,
  formatDate, formatDateWithTime, getDateRange
} from './dates';

export const getAgeLimitFromArray = (ageLimits?: number[]) => {
  if (!ageLimits || ageLimits.length === 0) return;
  if (ageLimits.length === 1) return `${ageLimits[0]} класс`;
  return `${ageLimits?.[0]} - ${ageLimits?.[ageLimits.length - 1]} классы`;
};

export const getCardAgeLimitFromArray = (ageLimits?: number[]) => {
  if (!ageLimits || ageLimits.length === 0) return;
  if (ageLimits.length === 1) return `${ageLimits[0]} класс`;
  return `c ${ageLimits?.[0]} по ${ageLimits?.[ageLimits.length - 1]} класс`;
};

/** @deprecated Используйте getCategoryProperties, для цветов ThemeProvider с нужным accentColor */
export const getBasicBlockAttributes = (blockCategory?: number) => {
  switch (blockCategory) {
    case SectionCodes.study:
      return {
        name: SectionNames.study,
        color: SectionColors.study.text,
        background: SectionColors.study.background,
        icon: <IconSchool className="secondary-block__icon" />
      };
    case SectionCodes.science:
      return {
        name: SectionNames.science,
        color: SectionColors.science.text,
        background: SectionColors.science.background,
        icon: <IconScience className="secondary-block__icon" />
      };
    case SectionCodes.sport:
      return {
        name: SectionNames.sport,
        color: SectionColors.sport.text,
        background: SectionColors.sport.background,
        icon: <IconSport className="secondary-block__icon" />
      };
    case SectionCodes.creation:
      return {
        name: SectionNames.creation,
        color: SectionColors.creation.text,
        background: SectionColors.creation.background,
        icon: <IconCreation className="secondary-block__icon" />
      };
    case SectionCodes.culture:
      return {
        name: SectionNames.culture,
        color: SectionColors.culture.text,
        background: SectionColors.culture.background,
        icon: <IconCulture className="secondary-block__icon" />
      };
    case SectionCodes.civil:
      return {
        name: SectionNames.civil,
        color: SectionColors.civil.text,
        background: SectionColors.civil.background,
        icon: <IconStar className="secondary-block__icon" />
      };
    case SectionCodes.profession:
      return {
        name: SectionNames.profession,
        color: SectionColors.profession.text,
        background: SectionColors.profession.background,
        icon: <IconProfession className="secondary-block__icon" />
      };
    default:
      return {
        name: SectionNames.civil,
        color: SectionColors.civil.text,
        background: SectionColors.civil.background,
        icon: <IconStar className="secondary-block__icon" />
      };
  }
};

export const validateParallel = (parallel: string): number | undefined => {
  const parallelNumber = Number(parallel);
  if (parallelNumber < 1 || parallelNumber > 11 || isNaN(parallelNumber)) return;
  return parallelNumber;
};

export const getDataEntryFormHeaderText = (formData: IPortfolioEntryFormData) => {
  const leadingText = formData.id ? 'Редактирование' : 'Добавление';

  switch (formData.dataKind) {
    // rewards
    case SectionCodes.studyReward:
    case SectionCodes.scienceReward:
    case SectionCodes.sportReward:
    case SectionCodes.creationReward:
    case SectionCodes.civilReward:
    case SectionCodes.professionReward:
      return `${leadingText} награды`;
    // study
    case SectionCodes.studyEvent: return `${leadingText} олимпиады`;
    case SectionCodes.studySpo: {
      switch (formData.typeCode) {
        case SectionCodes.studySpoDocuments: return `${leadingText} документа`;
        default: return `${leadingText} мероприятия`;
      }
    }
    // science
    case SectionCodes.scienceEvent: return `${leadingText} мероприятия`;
    case SectionCodes.scienceOccupation: return `${leadingText} занятия`;
    case SectionCodes.project: return `${leadingText} проекта`;
    // sport
    case SectionCodes.sportAffilation: return `${leadingText} клуба / команды`;
    case SectionCodes.sportOccupation: return `${leadingText} кружка / секции`;
    case SectionCodes.sportEvent: {
      switch (formData.typeCode) {
        case SectionCodes.sportEventCompetition:
          return `${leadingText} соревнования`;
        case SectionCodes.sportEventExpedition:
        case SectionCodes.sportEventTourism:
        default:
          return `${leadingText} похода / экспедиции`;
      }
    }
    // creation
    case SectionCodes.creationEvent: return `${leadingText} конкурса`;
    case SectionCodes.creationAffilation: return `${leadingText} творческого коллектива`;
    case SectionCodes.creationOccupation: return `${leadingText} кружка / cекции`;
    // civil
    case SectionCodes.civilAffilation: return `${leadingText} клуба`;
    case SectionCodes.civilEvent: return `${leadingText} мероприятия`;
    case SectionCodes.civilOccupation: return `${leadingText} кружка`;
    // profession
    case SectionCodes.professionEvent: return `${leadingText} мероприятия`;
    case SectionCodes.professionExam: return `${leadingText} ГИА`;
    case SectionCodes.professionSpo: {
      switch (formData.typeCode) {
        case SectionCodes.professionSpoJob: return `${leadingText} данных о трудоустройстве`;
        case SectionCodes.professionSpoMetaskills: return `Метанавыки`;
        default: return `${leadingText} мероприятия`;
      }
    }
    default: return `${leadingText} мероприятия`;
  }
};

export const getEntityHeaderText = (typeCode?: number | null) => {
  switch (typeCode) {
    case SectionCodes.contestReward: return 'Награда за научный конкурс';
    case SectionCodes.projectReward: return 'Награда за научный проект';
    case SectionCodes.gtoReward: return 'Значок «Готов к труду и обороне»';
    case SectionCodes.sportRank: return 'Спортивное звание';
    case SectionCodes.sportCategory: return 'Спортивный разряд';
    case SectionCodes.sportRewardTourism: return 'Знак отличия по туризму';
    case SectionCodes.sportRewardCompetition: return 'Награда за спортивное соревнование';
    case SectionCodes.creationContestReward: return 'Награда за творческий конкурс';
    case SectionCodes.rewardOlympiad: return 'Награда за олимпиаду';
    case SectionCodes.scienceCircle: return 'Кружок';
    case SectionCodes.contest: return 'Конкурс';
    case SectionCodes.conference: return 'Конференция / конкурс проектов';
    case SectionCodes.sportClubTeam: return 'Клуб / команда';
    case SectionCodes.sportOccupationCircle: return 'Кружок';
    case SectionCodes.sportOccupationSection: return 'Секция';
    case SectionCodes.sportEventCompetition: return 'Соревнование';
    case SectionCodes.sportEventExpedition: return 'Поход / экспедиция';
    case SectionCodes.sportEventTourism: return 'Первенство по туризму';
    case SectionCodes.creationCollective: return 'Коллектив';
    case SectionCodes.creationContest: return 'Конкурс';
    case SectionCodes.creationCircle: return 'Кружок';
    case SectionCodes.creationSchool: return 'Школа искусств';
    case SectionCodes.eventOlympiad: return 'Олимпиада';
    case SectionCodes.cultureVisiting: return 'Посещение культурных учреждений';
    case SectionCodes.cultureOnline: return 'Посещение онлайн-мероприятий';
    case SectionCodes.civilAffilationGroup: return 'Объединение';
    case SectionCodes.civilAffilationMovement: return 'Общественное движение';
    case SectionCodes.civilOccupationCircle: return 'Кружок';
    case SectionCodes.civilEventEcology: return 'Экологическая конференция / фестиваль';
    case SectionCodes.civilEventLocal: return 'Мероприятие по краеведению';
    case SectionCodes.civilEventVolonteer: return 'Волонтерский / социальный конкурс';
    case SectionCodes.civilEventPatriotic: return 'Военно-патриотическое мероприятие';
    case SectionCodes.civilEventQuest: return 'Квест / квиз';
    case SectionCodes.civilRewardStatus: return 'Звание / статус';
    case SectionCodes.civilRewardEvent: return 'Награда за мероприятие гражд. активности';
    case SectionCodes.professionRewardEvent: return 'Награда';
    case SectionCodes.professionEventChampionship: return 'Мероприятие';
    case SectionCodes.professionExamGia: return 'Демонстрационный экзамен';
    case SectionCodes.professionSpoJob: return 'Трудоустройство';
    case SectionCodes.studySpoDocuments: return 'Документ';
    case SectionCodes.studySpoPractice: return 'Сведения о производственной практике';
    case SectionCodes.studySpoTraining: return 'Сведения об обучении';
    default: return 'Мероприятие';
  }
};

export const getEntityCategoryCodeRewardIllustration = (categoryCode: number) => {
  switch(categoryCode) {
    case SectionCodes.study: return cardIllustrations.studyOlympiad;
    case SectionCodes.science: return cardIllustrations.scienceReward;
    case SectionCodes.sport: return cardIllustrations.sportReward;
    case SectionCodes.creation: return cardIllustrations.creationReward;
    case SectionCodes.civil: return cardIllustrations.civilReward;
    case SectionCodes.profession: return cardIllustrations.professionReward;
    default: return null;
  }
}

export const getEntityCategoryCodeEventIllustration = (categoryCode: number) => {
  switch(categoryCode) {
    case SectionCodes.study: return cardIllustrations.studyOlympiad;
    case SectionCodes.science: return cardIllustrations.scienceContest;
    case SectionCodes.sport: return cardIllustrations.sportCompetition;
    case SectionCodes.creation: return cardIllustrations.creationContest;
    case SectionCodes.civil: return cardIllustrations.civilEvent;
    case SectionCodes.profession: return cardIllustrations.professionEvent;
    default: return null;
  }
}

export const getEntityCardIllustration = (typeCode?: number) => {
  switch (typeCode) {
    // study
    case SectionCodes.rewardOlympiad:
      return cardIllustrations.studyOlympiad;
    case SectionCodes.eventOlympiad:
      return cardIllustrations.studyOlympiad;

    // science
    case SectionCodes.scienceReward:
    case SectionCodes.contestReward:
    case SectionCodes.projectReward:
      return cardIllustrations.scienceReward;
    case SectionCodes.project:
      return cardIllustrations.scienceProject;
    case SectionCodes.scienceCircle:
      return cardIllustrations.scienceOccupation;
    case SectionCodes.scienceEvent:
    case SectionCodes.contest:
    case SectionCodes.conference:
      return cardIllustrations.scienceContest;

    // sport
    case SectionCodes.sportReward:
    case SectionCodes.gtoReward:
    case SectionCodes.sportRank:
    case SectionCodes.sportCategory:
    case SectionCodes.sportRewardTourism:
    case SectionCodes.sportRewardCompetition:
      return cardIllustrations.sportReward;
    case SectionCodes.sportAffilation:
    case SectionCodes.sportClubTeam:
      return cardIllustrations.sportClub;
    case SectionCodes.sportOccupation:
    case SectionCodes.sportOccupationCircle:
    case SectionCodes.sportOccupationSection:
      return cardIllustrations.sportSection;
    case SectionCodes.sportEventCompetition:
      return cardIllustrations.sportCompetition;
    case SectionCodes.sportEvent:
    case SectionCodes.sportEventExpedition:
    case SectionCodes.sportEventTourism:
      return cardIllustrations.sportExpedition;

    // creation
    case SectionCodes.creationContestReward:
      return cardIllustrations.creationReward;
    case SectionCodes.creationCollective:
      return cardIllustrations.creationClub;
    case SectionCodes.creationCircle:
    case SectionCodes.creationSchool:
    case SectionCodes.creationOccupation:
      return cardIllustrations.creationSection;
    case SectionCodes.creationContest:
      return cardIllustrations.creationContest;

    // culture
    case SectionCodes.cultureOnline:
      return cardIllustrations.cultureOnlineVisiting;
    case SectionCodes.cultureVisiting:
      return cardIllustrations.cultureVisiting;

    // civil
    case SectionCodes.civilReward:
    case SectionCodes.civilRewardStatus:
    case SectionCodes.civilRewardEvent:
      return cardIllustrations.civilReward;
    case SectionCodes.civilAffilation:
    case SectionCodes.civilAffilationGroup:
    case SectionCodes.civilAffilationMovement:
      return cardIllustrations.civilAffilation;
    case SectionCodes.civilOccupationCircle:
      return cardIllustrations.civilOccupation;
    case SectionCodes.civilEvent:
    case SectionCodes.civilEventEcology:
    case SectionCodes.civilEventLocal:
    case SectionCodes.civilEventVolonteer:
    case SectionCodes.civilEventPatriotic:
    case SectionCodes.civilEventQuest:
      return cardIllustrations.civilEvent;

    // profession
    case SectionCodes.professionRewardEvent:
      return cardIllustrations.professionReward;
    case SectionCodes.professionEventChampionship:
      return cardIllustrations.professionEvent;
    case SectionCodes.professionExamGia:
      return cardIllustrations.professionGia;

    default:
      return null;
  }
};

export const getSpecialStatusView = (status: string) => {
  // старый формат данных
  if (status === '1') return 'Нет';
  if (status === '2') return 'Да';

  return status;
};

export const getSpecialStatusEdit = (status: string) => {
  // старый формат данных
  if (status === '1' || status === '2') return '';

  return status;
}

export const getSourceValue = (source: ISource) => {
  switch (source.code) {
    case SourceCode.student:
    case SourceCode.parent:
    case SourceCode.admin:
    case SourceCode.operator:
    case SourceCode.employeeOo:
      return source.value;
    case SourceCode.uchiRu:
      return 'Платформа Учи.ру';
    case SourceCode.proftech:
      return 'АИС "Зачисление в Профтех"';
    default:
      return AUTOMATICALLY_ADDED_SOURCE_VALUE;
  }
};

export const getProfessionEducationEntityGeneralInfo = (formData: any) => {
  return [
    { name: 'Тип события', value: 'Профессиональное обучение' },
    formData.type && { name: 'Документ', value: formData.type },
    formData.number && { name: 'Номер документа', value: formData.number },
    formData.eventDate && { name: 'Дата получения', value: format(new Date(formData.eventDate), 'dd.MM.yyyy') },
    formData.programm && { name: 'Образовательная программа', value: formData.programm },
    formData.organization && { name: 'Организация', value: formData.organization },
    formData.profession && { name: 'Профессия', value: formData.profession },
    formData.profession && formData.qualification && { name: 'Квалификация', value: `${formData.profession} ${formData.qualification}`},
    { name: 'Кто добавил', value: 'Загружено автоматически' },
    formData.eventTime && { name: 'Дата добавления', value: formatDateWithTime(formData.eventTime) }
  ].filter((info) => info);
};

export const getProfessionJobEntityGeneralInfo = (formData: Job): IGeneraInfoItem[] => {
  const isProftech = formData.source.code === SourceCode.proftech;
  const verifiedText = 'Ты можешь добавить данные, но они будут не верифицированы';
  const unverifiedText = 'Данные не верифицированы';

  let positionTooltip: string | undefined = undefined;
  let mainFunctionalityTooltip: string | undefined = undefined;

  if (isProftech) {
    positionTooltip = !Boolean(formData.position) ? verifiedText : unverifiedText;
    mainFunctionalityTooltip = !Boolean(formData.mainFunctionality) ? verifiedText : unverifiedText;
  }

  let graduationDateName = 'Дата выпуска/отчисления';
  let graduationDateValue = '-';
  if (formData.graduationDate) {
    graduationDateName = 'Дата выпуска';
    graduationDateValue = formatDate(formData.graduationDate);
  }
  if (formData.expulsionDate) {
    graduationDateName = 'Дата отчисления';
    graduationDateValue = formatDate(formData.expulsionDate);
  }

  const contractDate = formData?.contract?.date;

  const info: (boolean | IGeneraInfoItem)[] = [
    Boolean(formData.organization) && { name: 'Организация трудоустройства', value: formData.organization },
    { name: 'Трудоустройство по профилю', value: formData.isByProfile ? 'Да' : 'Нет' },
    Boolean(formData.employmentType?.value) && { name: 'Разновидности трудоустройства', value: formData.employmentType?.value },
    Boolean(formData.businessLevel?.value) && { name: 'Предприятие/организация по уровню бизнеса', value: formData.businessLevel?.value },
    Boolean(formData.salaryRange?.value) && { name: 'Диапазон среднемесячной зарплаты', value: formData.salaryRange?.value },
    { name: 'Должность', value: formData.position ? formData.position : '-', tooltip: positionTooltip },
    { name: 'Основной функционал', value: formData.mainFunctionality ? formData.mainFunctionality : '-', tooltip: mainFunctionalityTooltip },
    // дата выпуска или отчисления
    { name: graduationDateName, value: graduationDateValue },
    { name: 'Договор на трудоустройство', value: formData.contract?.isContract ? 'Да' : 'Нет' },
    Boolean(formData?.contract?.type?.value) && { name: 'Тип договора на трудоустройство', value: formData?.contract?.type?.value },
    isDefined(contractDate) && { name: 'Дата заключения договора', value: formatDate(contractDate)},
    Boolean(formData.source) && { name: 'Кто добавил', value: getSourceValue(formData.source) },
    Boolean(formData.creationDate) && { name: 'Дата добавления', value: formatDateWithTime(formData.creationDate) },
  ];

  return info.filter(Boolean) as IGeneraInfoItem[];
};

export const getStudyTrainingEntityGeneralInfo = (formData: ProffEducation, source: ISource, professionCode?: string): IGeneraInfoItem[] => {
  const date = createPeriod(formData?.studyStartDate, formData.studyTerm?.years, formData.studyTerm?.months);

  const professionName = professionCode ? `${professionCode} ${formData.professionName}` : formData.professionName;

  const isBasePlanItem = (item: IGeneraInfoItem, propToCheck: string | number | boolean = true) => {
    if (formData.isEffectivePlan) {
      return false;
    }

    return isDefined(propToCheck) && item;
  };

  const info: (boolean | IGeneraInfoItem)[] = [
    isBasePlanItem({ name: 'Профессия/специальность', value: professionName }),
    Boolean(formData.educationLevel) && { name: 'Программа обучения', value: formData.educationLevel },
    isBasePlanItem({ name: 'Учебный год программы', value: formData.learningYear }, formData.learningYear),
    isBasePlanItem({ name: 'Формат обучения', value: formData.studyForm }, formData.studyForm),
    Boolean(formData.studyStartDate) && { name: 'Срок обучения', value: date },
    isBasePlanItem({ name: 'Программа обучения на базе', value: formData.programBase }, formData.programBase),
    isBasePlanItem({ name: 'Углубленное обучение', value: formData.isAdvancedLevel ? 'Да' : 'Нет'}),
    isBasePlanItem({ name: 'Курс обучения', value: `${formData.educationCourse} курс` }, formData.educationCourse),
    isDefined(formData.studyStartDate) && isBasePlanItem({ name: 'Дата начала обучения', value: formatDate(formData.studyStartDate) }, formData.studyStartDate),
    Boolean(formData.groupName) && { name: 'Учебная группа', value: formData.groupName},
    isDefined(formData.isSmallGroup) && { name: 'Малокомплектная группа', value: formData.isSmallGroup ? 'Да' : 'Нет' },
    isDefined(formData.isOutletGroup) && { name: 'Выпускная группа', value: formData.isOutletGroup ? 'Да' : 'Нет' },
    { name: 'Кто добавил', value: getSourceValue(source) },
    { name: 'Дата добавления', value: formatDateWithTime(formData.creationDate) }
  ];

  return info.filter(Boolean) as IGeneraInfoItem[];
}

export const getStudyPracticeEntityGeneralInfo = (formData: Practice, source: ISource): IGeneraInfoItem[] => {
  const info: (boolean | IGeneraInfoItem)[] = [
    isDefined(formData.docDate) && { name: 'Дата договора', value: formatDate(formData.docDate) },
    Boolean(formData.docNum) && { name: 'Номер договора', value: formData.docNum },
    { name: 'Кто добавил', value: getSourceValue(source) },
    { name: 'Дата добавления', value: formatDateWithTime(formData.creationDate) }
  ];

  return info.filter(Boolean) as IGeneraInfoItem[];
}

export const getStudyDocumentEntityGeneralInfo = (formData: SpoDocument, documentTypeCode: number): IGeneraInfoItem[] => {
  const commonInfo: (boolean | IGeneraInfoItem)[] = [
    Boolean(formData.source) && { name: 'Кто добавил', value: getSourceValue(formData.source) },
    Boolean(formData.creationDate) && { name: 'Дата добавления', value: formatDateWithTime(formData.creationDate) },
  ];

  switch (documentTypeCode) {
    case DocumentTypeCodes.spoCertificate: {
      const info: (boolean | IGeneraInfoItem)[] = [
        Boolean(formData.educationProgram) && { name: 'Название программы (темы) обучения', value: formData.educationProgram },
        Boolean(formData.organizationName) && { name: 'Название организации', value: formData.organizationName },
        Boolean(formData.hours) && { name: 'Объем часов', value: `${formData.hours} ак. ч.` },
        Boolean(formData.issueDate) && { name: 'Дата выдачи', value: formatDate(formData.issueDate) },
        Boolean(formData.issuePlace) && { name: 'Город выдачи', value: formData.issuePlace },
        ...commonInfo,
      ];
      return info.filter(Boolean) as IGeneraInfoItem[];
    }
    case DocumentTypeCodes.spoJobCertificate: {
      const info: (boolean | IGeneraInfoItem)[] = [
        Boolean(formData.organizationName) && { name: 'Название организации', value: formData.organizationName },
        Boolean(formData.docNumber) && { name: '№ Свидетельства', value: formData.docNumber },
        Boolean(formData.regNumber) && { name: 'Регистрационный №', value: formData.regNumber },
        Boolean(formData.issueDate) && { name: 'Дата выдачи', value: formatDate(formData.issueDate) },
        Boolean(formData.issuePlace) && { name: 'Город выдачи', value: formData.issuePlace },
        Boolean(formData.professionProgramName) && { name: 'Название программы профессионального обучения', value: formData.professionProgramName },
        Boolean(formData.hours) && { name: 'Объем часов', value: `${formData.hours} ак. ч.` },
        Boolean(formData.examMark?.value) && { name: 'Итоговая аттестация (квалификационный экзамен)', value: formData.examMark?.value },
        isDefined(formData.resultDate) && { name: 'Дата решения аттестационной комиссии', value: formatDate(formData.resultDate) },
        Boolean(formData.profession) && { name: 'Квалификация', value: formData.profession },
        Boolean(formData.rank?.value) && { name: 'Разряд', value: formData.rank?.value },
        ...commonInfo,
      ];
      return info.filter(Boolean) as IGeneraInfoItem[];
    }
    case DocumentTypeCodes.spoShortTrainingCertificate: {
      const info: (boolean | IGeneraInfoItem)[] = [
        Boolean(formData.organizationName) && { name: 'Название организации', value: formData.organizationName },
        Boolean(formData.educationProgram) && { name: 'Название программы обучения', value: formData.educationProgram },
        Boolean(formData.hours) && { name: 'Объем часов', value: `${formData.hours} ак. ч.` },
        Boolean(formData.regNumber) && { name: 'Регистрационный №', value: formData.regNumber },
        Boolean(formData.issueDate) && { name: 'Дата выдачи', value: formatDate(formData.issueDate) },
        Boolean(formData.issuePlace) && { name: 'Город выдачи', value: formData.issuePlace },
        ...commonInfo,
      ];
      return info.filter(Boolean) as IGeneraInfoItem[];
    }
    case DocumentTypeCodes.spoProfessionalCertificate:
    case DocumentTypeCodes.spoOther: {
      const info: (boolean | IGeneraInfoItem)[] = [
        Boolean(formData.organizationName) && { name: 'Название организации', value: formData.organizationName },
        Boolean(formData.educationProgram) && { name: 'Название программы обучения', value: formData.educationProgram },
        Boolean(formData.hours) && { name: 'Объем часов', value: `${formData.hours} ак. ч.` },
        Boolean(formData.regNumber) && { name: 'Регистрационный №', value: formData.regNumber },
        Boolean(formData.issueDate) && { name: 'Дата выдачи', value: formatDate(formData.issueDate) },
        Boolean(formData.issuePlace) && { name: 'Город выдачи', value: formData.issuePlace },
        { name: 'Номер документа', value: formData.docNumber ? formData.docNumber : '-' },
        { name: 'Серия документа', value: formData.docSeries ? formData.docSeries : '-' },
        ...commonInfo,
      ];
      return info.filter(Boolean) as IGeneraInfoItem[];
    }
    default: {
      return [];
    }
  }
};

export const getEntityGeneralInfo = (formData: any, subspeciesString?: string) => {
  const state: IRootState = store.getState();
  const sectionRef = state.sectionRef.content;

  const formDataCode = formData.typeCode || formData.type?.code || formData.dataKind;

  const firstAchievementTypeCode = 86;
  const lastAchievementTypeCode = 207;
  const isAchivementCode = formDataCode >= firstAchievementTypeCode && formDataCode <= lastAchievementTypeCode;

  let sectionCode = formDataCode;

  if (isAchivementCode) {
    const dataKind = formData.dataKind;
    const childrens = sectionRef.filter((ref) => ref.parentId === dataKind);
    const firstChildrenWithLowestTypeCode = childrens.sort((a, b) => a.code - b.code)[0];

    if (firstChildrenWithLowestTypeCode) {
      sectionCode = firstChildrenWithLowestTypeCode.code;
    }
  }

  switch (sectionCode) {
    case SectionCodes.contestReward:
    case SectionCodes.projectReward:
    case SectionCodes.professionRewardEvent:
      return [
        formData.date && { name: 'Дата получения', value: format(new Date(formData.date), 'dd.MM.yyyy') },
        formData.rewardNumber && { name: 'Номер награды', value: formData.rewardNumber },
        formData.description && { name: 'Описание награды', value: formData.description },
        formData.source?.value && { name: 'Кто добавил', value: getSourceValue(formData.source) },
        formData.creationDate && { name: 'Дата добавления', value: formatDateWithTime(formData.creationDate) },
        formData.editDate && { name: 'Дата изменения', value: formatDateWithTime(formData.editDate) }
      ].filter((info) => info);
    case SectionCodes.gtoReward:
      return [
        formData.ageLimit && { name: 'Ступень', value: formData.ageLimit.value },
        formData.date && { name: 'Дата получения', value: format(new Date(formData.date), 'dd.MM.yyyy') },
        formData.rewardNumber && { name: 'Номер награды', value: formData.rewardNumber },
        formData.description && { name: 'Описание награды', value: formData.description },
        formData.source && { name: 'Кто добавил', value: getSourceValue(formData.source) },
        formData.creationDate && { name: 'Дата добавления', value: formatDateWithTime(formData.creationDate) },
        formData.editDate && { name: 'Дата изменения', value: formatDateWithTime(formData.editDate) }
      ].filter((info) => info);
    case SectionCodes.sportRank:
    case SectionCodes.sportCategory:
      return [
        formData.subcategory && { name: 'Подкатегория', value: formData.subcategory.value },
        formData.subcategory?.code === SportSubcategoryCodes.sport && formData.sportKind && { name: 'Вид спорта', value: formData.sportKind?.value },
        formData.subcategory?.code === SportSubcategoryCodes.tourism && formData.tourismKind && { name: 'Вид туризма', value: formData.tourismKind?.value },
        formData.date && { name: 'Дата получения', value: format(new Date(formData.date), 'dd.MM.yyyy') },
        formData.rewardNumber && { name: 'Номер награды', value: formData.rewardNumber },
        formData.description && { name: 'Описание награды', value: formData.description },
        formData.source && { name: 'Кто добавил', value: getSourceValue(formData.source) },
        formData.creationDate && { name: 'Дата добавления', value: formatDateWithTime(formData.creationDate) },
        formData.editDate && { name: 'Дата изменения', value: formatDateWithTime(formData.editDate) }
      ].filter((info) => info);
    case SectionCodes.sportRewardTourism:
      return [
        formData.levelReward && { name: 'Уровень награды', value: formData.levelReward.value },
        formData.date && { name: 'Дата получения', value: format(new Date(formData.date), 'dd.MM.yyyy') },
        formData.rewardNumber && { name: 'Номер награды', value: formData.rewardNumber },
        formData.description && { name: 'Описание награды', value: formData.description },
        formData.source && { name: 'Кто добавил', value: getSourceValue(formData.source) },
        formData.creationDate && { name: 'Дата добавления', value: formatDateWithTime(formData.creationDate) },
        formData.editDate && { name: 'Дата изменения', value: formatDateWithTime(formData.editDate) }
      ].filter((info) => info);
    case SectionCodes.sportRewardCompetition:
      return [
        formData.date && { name: 'Дата получения', value: format(new Date(formData.date), 'dd.MM.yyyy') },
        formData.rewardNumber && { name: 'Номер награды', value: formData.rewardNumber },
        formData.description && { name: 'Описание награды', value: formData.description },
        formData.source && { name: 'Кто добавил', value: getSourceValue(formData.source) },
        formData.creationDate && { name: 'Дата добавления', value: formatDateWithTime(formData.creationDate) },
        formData.editDate && { name: 'Дата изменения', value: formatDateWithTime(formData.editDate) }
      ].filter((info) => info);
    case SectionCodes.creationContestReward:
      return [
        formData.date && { name: 'Дата получения', value: format(new Date(formData.date), 'dd.MM.yyyy') },
        formData.rewardNumber && { name: 'Номер награды', value: formData.rewardNumber },
        formData.description && { name: 'Описание награды', value: formData.description },
        formData.source?.value && { name: 'Кто добавил', value: getSourceValue(formData.source) },
        formData.creationDate && { name: 'Дата добавления', value: formatDateWithTime(formData.creationDate) },
        formData.editDate && { name: 'Дата изменения', value: formatDateWithTime(formData.editDate) }
      ].filter((info) => info);
    case SectionCodes.scienceCircle:
      return [
        formData.subcategory && { name: 'Подкатегория', value: formData.subcategory?.value },
        formData.disciplines && { name: 'Предмет / дисциплина', value: formData.disciplines?.map((discipline: IDictionaryItem) => discipline.value).join(', ') },
        formData.startDate && { name: 'Даты занятий', value: getDateRange(formData.startDate, formData.endDate) },
        formData.achievementActivityFormat && { name: 'Формат занятий', value: formData.achievementActivityFormat.value },
        formData.ageLimit && { name: 'Возрастное ограничение', value: getCardAgeLimitFromArray(formData.ageLimit?.split(', ')) },
        formData.location && { name: 'Место проведения', value: formData.location },
        formData.description && { name: 'Описание', value: formData.description },
        formData.source && { name: 'Кто добавил', value: getSourceValue(formData.source) },
        formData.creationDate && { name: 'Дата добавления', value: formatDateWithTime(formData.creationDate) },
        formData.editDate && { name: 'Дата изменения', value: formatDateWithTime(formData.editDate) }
      ].filter((info) => info);
    case SectionCodes.contest:
    case SectionCodes.conference:
      return [
        formData.disciplines && { name: 'Предмет / дисциплина', value: formData.disciplines?.map((discipline: IDictionaryItem) => discipline.value).join(', ') },
        formData.startDate && { name: 'Даты проведения', value: getDateRange(formData.startDate, formData.endDate) },
        formData.levelEvent && { name: 'Уровень мероприятия', value: formData.levelEvent.value },
        formData.format && { name: 'Формат проведения', value: formData.format.value },
        formData.eventNumber && { name: 'Номер мероприятия', value: formData.eventNumber },
        formData.stageEvent && { name: 'Этап мероприятия', value: formData.stageEvent },
        formData.stageStartDate && { name: 'Даты этапа', value: getDateRange(formData.stageStartDate, formData.stageEndDate) },
        formData.actionStage && { name: 'Событие', value: formData.actionStage },
        formData.actionDate && { name: 'Дата события', value: format(new Date(formData.actionDate), 'dd.MM.yyyy') },
        formData.organizators && { name: 'Организатор', value: formData.organizators },
        formData.participantNumber && { name: 'Номер участника', value: formData.participantNumber },
        formData.ageLimit && { name: 'Возрастное ограничение', value: getCardAgeLimitFromArray(formData.ageLimit?.split(', ')) },
        formData.location && { name: 'Место проведения', value: formData.location },
        formData.result && { name: 'Результат', value: formData.result },
        formData.description && { name: 'Описание', value: formData.description },
        formData.source && { name: 'Кто добавил', value: getSourceValue(formData.source) },
        formData.creationDate && { name: 'Дата добавления', value: formatDateWithTime(formData.creationDate) },
        formData.editDate && { name: 'Дата изменения', value: formatDateWithTime(formData.editDate) }
      ].filter((info) => info);
    case SectionCodes.sportClubTeam:
      return [
        formData.sportKind && { name: 'Вид спорта', value: formData.sportKind.value },
        formData.startDate && { name: 'Дата зачисления', value:  format(new Date(formData.startDate), 'dd.MM.yyyy') },
        formData.endDate && { name: 'Дата отчисления', value:  format(new Date(formData.endDate), 'dd.MM.yyyy') },
        formData.teamName && { name: 'Название команды', value: formData.teamName },
        formData.teamStartDate  && { name: 'Дата вступления в состав', value: format(new Date(formData.teamStartDate), 'dd.MM.yyyy') },
        formData.teamEndDate  && { name: 'Дата выхода из состава', value: format(new Date(formData.teamEndDate), 'dd.MM.yyyy') },
        formData.trainingStageCode  && { name: 'Этап спортивной подготовки', value: formData.trainingStageCode },
        formData.medicalClearanceFromDate && { name: 'Дата начала мед. допуска', value:  format(new Date(formData.medicalClearanceFromDate), 'dd.MM.yyyy') },
        formData.medicalClearanceToDate && { name: 'Дата окончания мед. допуска', value:  format(new Date(formData.medicalClearanceToDate), 'dd.MM.yyyy') },
        formData.organizationCategory && { name: 'Категория организации', value: formData.organizationCategory },
        formData.description && { name: 'Описание', value: formData.description },
        formData.address && { name: 'Адрес организации', value: formData.address },
        formData.status && { name: 'Особый статус участника', value: formData.status },
        formData.source && { name: 'Кто добавил', value: getSourceValue(formData.source) },
        formData.creationDate && { name: 'Дата добавления', value: formatDateWithTime(formData.creationDate) },
      ].filter((info) => info);
    case SectionCodes.sportOccupationCircle:
    case SectionCodes.sportOccupationSection:
      return [
        formData.subcategory && { name: 'Подкатегория', value: formData.subcategory?.value },
        formData.subcategory?.code === SportSubcategoryCodes.sport && formData.sportKind && { name: 'Вид спорта', value: formData.sportKind?.value },
        formData.subcategory?.code === SportSubcategoryCodes.tourism && formData.tourismKind && { name: 'Вид туризма', value: formData.tourismKind?.value },
        formData.startDate && { name: 'Даты занятий', value: getDateRange(formData.startDate, formData.endDate) },
        formData.achievementActivityFormat && { name: 'Формат занятий', value: formData.achievementActivityFormat.value },
        formData.ageLimit && { name: 'Возрастное ограничение', value: getCardAgeLimitFromArray(formData.ageLimit?.split(', ')) },
        formData.location && { name: 'Место проведения', value: formData.location },
        formData.description && { name: 'Описание', value: formData.description },
        formData.source && { name: 'Кто добавил', value: getSourceValue(formData.source) },
        formData.creationDate && { name: 'Дата добавления', value: formatDateWithTime(formData.creationDate) },
        formData.editDate && { name: 'Дата изменения', value: formatDateWithTime(formData.editDate) }
      ].filter((info) => info);
    case SectionCodes.sportEventCompetition:
      return [
        formData.sportKind && { name: 'Вид спорта', value: formData.sportKind.value },
        formData.subspeciesString && { name: 'Дисциплина', value: formData.subspeciesString },
        formData.startDate && { name: 'Даты проведения', value: getDateRange(formData.startDate, formData.endDate) },
        formData.levelEvent && { name: 'Уровень мероприятия', value: formData.levelEvent.value },
        formData.format && { name: 'Формат проведения', value: formData.format.value },
        formData.eventNumber && { name: 'Номер мероприятия', value: formData.eventNumber },
        formData.stageEvent && { name: 'Этап мероприятия', value: formData.stageEvent },
        formData.stageStartDate && { name: 'Даты этапа', value: getDateRange(formData.stageStartDate, formData.stageEndDate) },
        formData.actionStage && { name: 'Событие', value: formData.actionStage },
        formData.actionDate && { name: 'Дата события', value: format(new Date(formData.actionDate), 'dd.MM.yyyy') },
        formData.organizators && { name: 'Организатор', value: formData.organizators },
        formData.participantNumber && { name: 'Номер участника', value: formData.participantNumber },
        formData.ageLimit && { name: 'Возрастное ограничение', value: getCardAgeLimitFromArray(formData.ageLimit?.split(', ')) },
        formData.location && { name: 'Место проведения', value: formData.location },
        formData.result && { name: 'Результат', value: formData.result },
        formData.description && { name: 'Описание', value: formData.description },
        formData.source && { name: 'Кто добавил', value: getSourceValue(formData.source) },
        formData.creationDate && { name: 'Дата добавления', value: formatDateWithTime(formData.creationDate) },
        formData.editDate && { name: 'Дата изменения', value: formatDateWithTime(formData.editDate) }
      ].filter((info) => info);
    case SectionCodes.sportEventExpedition:
    case SectionCodes.sportEventTourism:
      return [
        formData.tourismKind && { name: 'Дистанция', value: formData.tourismKind.value },
        formData.subspeciesString && { name: 'Тип дистанции', value: formData.subspeciesString },
        formData.startDate && { name: 'Даты проведения', value: getDateRange(formData.startDate, formData.endDate) },
        formData.levelEvent && { name: 'Уровень мероприятия', value: formData.levelEvent.value },
        formData.format && { name: 'Формат проведения', value: formData.format.value },
        formData.eventNumber && { name: 'Номер мероприятия', value: formData.eventNumber },
        formData.stageEvent && { name: 'Этап мероприятия', value: formData.stageEvent },
        formData.stageStartDate && { name: 'Даты этапа', value: getDateRange(formData.stageStartDate, formData.stageEndDate) },
        formData.actionStage && { name: 'Событие', value: formData.actionStage },
        formData.actionDate && { name: 'Дата события', value: format(new Date(formData.actionDate), 'dd.MM.yyyy') },
        formData.organizators && { name: 'Организатор', value: formData.organizators },
        formData.participantNumber && { name: 'Номер участника', value: formData.participantNumber },
        formData.ageLimit && { name: 'Возрастное ограничение', value: getCardAgeLimitFromArray(formData.ageLimit?.split(', ')) },
        formData.location && { name: 'Место проведения', value: formData.location },
        formData.result && { name: 'Результат', value: formData.result },
        formData.description && { name: 'Описание', value: formData.description },
        formData.source && { name: 'Кто добавил', value: getSourceValue(formData.source) },
        formData.creationDate && { name: 'Дата добавления', value: formatDateWithTime(formData.creationDate) },
        formData.editDate && { name: 'Дата изменения', value: formatDateWithTime(formData.editDate) }
      ].filter((info) => info);
    case SectionCodes.creationCollective:
      return [
        formData.subcategory && { name: 'Подкатегория', value: formData.subcategory?.value },
        formData.profile && { name: 'Профиль', value: formData.profile.value },
        formData.creationKind && { name: 'Вид тв. деятельности', value: formData.creationKind.value },
        formData.affilationLevel && { name: 'Уровень объединения', value: formData.affilationLevel.value },
        formData.address && { name: 'Адрес объединения', value: formData.address },
        formData.startDate && { name: 'Даты членства', value: getDateRange(formData.startDate, formData.endDate) },
        formData.status && { name: 'Особый статус', value: getSpecialStatusView(formData.status) },
        formData.ageLimit && { name: 'Возрастное ограничение', value: getCardAgeLimitFromArray(formData.ageLimit?.split(', ')) },
        formData.description && { name: 'Описание', value: formData.description },
        formData.source && { name: 'Кто добавил', value: getSourceValue(formData.source) },
        formData.creationDate && { name: 'Дата добавления', value: formatDateWithTime(formData.creationDate) },
        formData.editDate && { name: 'Дата изменения', value: formatDateWithTime(formData.editDate) }
      ].filter((info) => info);
    case SectionCodes.creationContest:
      return [
        formData.subcategory && { name: 'Подкатегория', value: formData.subcategory?.value },
        formData.profile && { name: 'Профиль', value: formData.profile.value },
        formData.creationKind && { name: 'Вид тв. деятельности', value: formData.creationKind.value },
        formData.startDate && { name: 'Даты проведения', value: getDateRange(formData.startDate, formData.endDate) },
        formData.levelEvent && { name: 'Уровень мероприятия', value: formData.levelEvent.value },
        formData.format && { name: 'Формат проведения', value: formData.format.value },
        formData.eventNumber && { name: 'Номер мероприятия', value: formData.eventNumber },
        formData.stageEvent && { name: 'Этап мероприятия', value: formData.stageEvent },
        formData.stageStartDate && { name: 'Даты этапа', value: getDateRange(formData.stageStartDate, formData.stageEndDate) },
        formData.actionStage && { name: 'Событие', value: formData.actionStage },
        formData.actionDate && { name: 'Дата события', value: format(new Date(formData.actionDate), 'dd.MM.yyyy') },
        formData.organizators && { name: 'Организатор', value: formData.organizators },
        formData.participantNumber && { name: 'Номер участника', value: formData.participantNumber },
        formData.ageLimit && { name: 'Возрастное ограничение', value: getCardAgeLimitFromArray(formData.ageLimit?.split(', ')) },
        formData.location && { name: 'Место проведения', value: formData.location },
        formData.result && { name: 'Результат', value: formData.result },
        formData.description && { name: 'Описание', value: formData.description },
        formData.source && { name: 'Кто добавил', value: getSourceValue(formData.source) },
        formData.creationDate && { name: 'Дата добавления', value: formatDateWithTime(formData.creationDate) },
        formData.editDate && { name: 'Дата изменения', value: formatDateWithTime(formData.editDate) }
      ].filter((info) => info);
    case SectionCodes.creationCircle:
    case SectionCodes.creationSchool:
      return [
        formData.subcategory && { name: 'Подкатегория', value: formData.subcategory?.value },
        formData.profile && { name: 'Профиль', value: formData.profile.value },
        formData.creationKinds && { name: 'Вид тв. деятельности', value: formData.creationKinds.map((creationKind: IDictionaryItem) => creationKind.value).join(', ') },
        formData.startDate && { name: 'Даты занятий', value: getDateRange(formData.startDate, formData.endDate) },
        formData.achievementActivityFormat && { name: 'Формат занятий', value: formData.achievementActivityFormat.value },
        formData.ageLimit && { name: 'Возрастное ограничение', value: getCardAgeLimitFromArray(formData.ageLimit?.split(', ')) },
        formData.location && { name: 'Место проведения', value: formData.location },
        formData.description && { name: 'Описание', value: formData.description },
        formData.source && { name: 'Кто добавил', value: getSourceValue(formData.source) },
        formData.creationDate && { name: 'Дата добавления', value: formatDateWithTime(formData.creationDate) },
        formData.editDate && { name: 'Дата изменения', value: formatDateWithTime(formData.editDate) }
      ].filter((info) => info);
    case SectionCodes.rewardOlympiad:
      return [
        formData.date && { name: 'Дата получения', value: format(new Date(formData.date), 'dd.MM.yyyy') },
        formData.rewardNumber && { name: 'Номер награды', value: formData.rewardNumber },
        formData.description && { name: 'Описание награды', value: formData.description },
        formData.source?.value && { name: 'Кто добавил', value: getSourceValue(formData.source) },
        formData.creationDate && { name: 'Дата добавления', value: formatDateWithTime(formData.creationDate as string) },
        formData.editDate && { name: 'Дата изменения', value: formatDateWithTime(formData.editDate) }
      ].filter((info) => info);
    case SectionCodes.eventOlympiad:
      return [
        formData.subjects && { name: 'Предмет / дисциплина', value: formData.subjects?.map((subject: IDictionaryItem) => subject.value).join(', ') },
        formData.startDate && { name: 'Даты проведения', value: getDateRange(formData.startDate, formData.endDate) },
        formData.levelEvent && { name: 'Уровень мероприятия', value: formData.levelEvent.value },
        formData.format && { name: 'Формат проведения', value: formData.format.value },
        formData.levelOlympiad && { name: 'Уровень олимпиады', value: formData.levelOlympiad.value },
        formData.profile && { name: 'Профиль олимпиады', value: formData.profile },
        formData.eventNumber && { name: 'Номер мероприятия', value: formData.eventNumber },
        formData.stageEvent && { name: 'Этап мероприятия', value: formData.stageEvent },
        formData.stageStartDate && { name: 'Даты этапа', value: getDateRange(formData.stageStartDate, formData.stageEndDate) },
        formData.actionStage && { name: 'Событие', value: formData.actionStage },
        formData.actionDate && { name: 'Дата события', value: format(new Date(formData.actionDate), 'dd.MM.yyyy') },
        formData.organizators && { name: 'Организатор', value: formData.organizators },
        formData.participantNumber && { name: 'Номер участника', value: formData.participantNumber },
        formData.participationStatus?.value && { name: 'Статус участника', value: formData.participationStatus?.value },
        formData.parallelId && { name: 'Параллель участника', value: formData.parallelId },
        formData.ageLimit && { name: 'Возрастное ограничение', value: getCardAgeLimitFromArray(formData.ageLimit?.split(', ')) },
        formData.location && { name: 'Место проведения', value: formData.location },
        formData.result && { name: 'Результат', value: formData.result },
        isDefined(formData.maxScore) && { name: 'Максимальное количество баллов', value: formData.maxScore },
        formData.description && { name: 'Описание', value: formData.description },
        formData.workCode && { name: 'Код работы', value: formData.workCode },
        formData.source && { name: 'Кто добавил', value: getSourceValue(formData.source) },
        formData.creationDate && { name: 'Дата добавления', value: formatDateWithTime(formData.creationDate) },
        formData.editDate && { name: 'Дата изменения', value: formatDateWithTime(formData.editDate) }
      ].filter((info) => info);
    case SectionCodes.civilAffilationGroup:
    case SectionCodes.civilAffilationMovement:
      return [
        formData.subcategory && { name: 'Подкатегория', value: formData.subcategory.value },
        // formData.levelEvent && { name: 'Уровень объединения', value: formData.levelEvent.value },
        formData.affilationLevel && { name: 'Уровень объединения', value: formData.affilationLevel.value },
        formData.address && { name: 'Адрес объединения', value: formData.address },
        formData.startDate && { name: 'Даты членства', value: getDateRange(formData.startDate, formData.endDate) },
        formData.status && { name: 'Особый статус', value: getSpecialStatusView(formData.status) },
        formData.ageLimit && { name: 'Возрастное ограничение', value: getCardAgeLimitFromArray(formData.ageLimit?.split(', ')) },
        formData.description && { name: 'Описание', value: formData.description },
        formData.source && { name: 'Кто добавил', value: getSourceValue(formData.source) },
        formData.creationDate && { name: 'Дата добавления', value: formatDateWithTime(formData.creationDate) },
        formData.editDate && { name: 'Дата изменения', value: formatDateWithTime(formData.editDate) }
      ].filter((info) => info);
    case SectionCodes.civilEventLocal:
    case SectionCodes.civilEventEcology:
    case SectionCodes.civilEventVolonteer:
    case SectionCodes.civilEventPatriotic:
    case SectionCodes.civilEventQuest:
      return [
        formData.subcategory && { name: 'Подкатегория', value: formData.subcategory?.value },
        formData.startDate && { name: 'Даты проведения', value: getDateRange(formData.startDate, formData.endDate) },
        formData.levelEvent && { name: 'Уровень мероприятия', value: formData.levelEvent.value },
        formData.format && { name: 'Формат проведения', value: formData.format.value },
        formData.eventNumber && { name: 'Номер мероприятия', value: formData.eventNumber },
        formData.stageEvent && { name: 'Этап мероприятия', value: formData.stageEvent },
        formData.stageStartDate && { name: 'Даты этапа', value: getDateRange(formData.stageStartDate, formData.stageEndDate) },
        formData.actionStage && { name: 'Событие', value: formData.actionStage },
        formData.actionDate && { name: 'Дата события', value: format(new Date(formData.actionDate), 'dd.MM.yyyy') },
        formData.organizators && { name: 'Организатор', value: formData.organizators },
        formData.participantNumber && { name: 'Номер участника', value: formData.participantNumber },
        formData.ageLimit && { name: 'Возрастное ограничение', value: getCardAgeLimitFromArray(formData.ageLimit?.split(', ')) },
        formData.location && { name: 'Место проведения', value: formData.location },
        formData.result && { name: 'Результат', value: formData.result },
        formData.description && { name: 'Описание', value: formData.description },
        formData.workCode && { name: 'Код работы', value: formData.workCode },
        formData.source && { name: 'Кто добавил', value: getSourceValue(formData.source) },
        formData.creationDate && { name: 'Дата добавления', value: formatDateWithTime(formData.creationDate) },
        formData.editDate && { name: 'Дата изменения', value: formatDateWithTime(formData.editDate) }
      ].filter((info) => info);
    case SectionCodes.civilOccupationCircle:
      return [
        formData.subcategory && { name: 'Подкатегория', value: formData.subcategory?.value },
        formData.startDate && { name: 'Даты занятий', value: getDateRange(formData.startDate, formData.endDate) },
        formData.achievementActivityFormat && { name: 'Формат занятий', value: formData.achievementActivityFormat.value },
        formData.ageLimit && { name: 'Возрастное ограничение', value: getCardAgeLimitFromArray(formData.ageLimit?.split(', ')) },
        formData.location && { name: 'Место проведения', value: formData.location },
        formData.description && { name: 'Описание', value: formData.description },
        formData.source && { name: 'Кто добавил', value: getSourceValue(formData.source) },
        formData.creationDate && { name: 'Дата добавления', value: formatDateWithTime(formData.creationDate) },
        formData.editDate && { name: 'Дата изменения', value: formatDateWithTime(formData.editDate) }
      ].filter((info) => info);
    case SectionCodes.civilRewardStatus:
      return [
        formData.subcategory && { name: 'Подкатегория', value: formData.subcategory?.value },
        formData.date && { name: 'Дата получения', value: format(new Date(formData.date), 'dd.MM.yyyy') },
        formData.levelEvent && { name: 'Уровень', value: formData.levelEvent.value },
        formData.ageLimit && { name: 'Возрастное ограничение', value: getCardAgeLimitFromArray(formData.ageLimit?.split(', ')) },
        formData.rewardNumber && { name: 'Номер награды', value: formData.rewardNumber },
        formData.description && { name: 'Описание награды', value: formData.description },
        formData.source?.value && { name: 'Кто добавил', value: getSourceValue(formData.source) },
        formData.creationDate && { name: 'Дата добавления', value: formatDateWithTime(formData.creationDate as string) }
      ].filter((info) => info);
    case SectionCodes.civilRewardEvent:
      return [
        formData.date && { name: 'Дата получения', value: format(new Date(formData.date), 'dd.MM.yyyy') },
        formData.rewardNumber && { name: 'Номер награды', value: formData.rewardNumber },
        formData.description && { name: 'Описание награды', value: formData.description },
        formData.source?.value && { name: 'Кто добавил', value: getSourceValue(formData.source) },
        formData.creationDate && { name: 'Дата добавления', value: formatDateWithTime(formData.creationDate) },
        formData.editDate && { name: 'Дата изменения', value: formatDateWithTime(formData.editDate) }
      ].filter((info) => info);
    case SectionCodes.cultureVisiting:
      return [
        formData.locationName && { name: 'Культурное учреждение', value: formData.locationName },
        formData.location && { name: 'Место проведения', value: formData.location },
        formData.levelEvent && { name: 'Уровень мероприятия', value: formData.levelEvent.value },
        formData.startDate && { name: 'Даты посещения', value: getDateRange(formData.startDate, formData.endDate) },
        formData.name && { name: 'Событие', value: formData.name },
        formData.actionStage && { name: 'Конкретное событие в рамках мероприятия', value: formData.actionStage },
        formData.result && { name: 'Результат', value: formData.result },
        formData.organizators && { name: 'Организатор', value: formData.organizators },
        formData.ageLimit && { name: 'Возрастное ограничение', value: getCardAgeLimitFromArray(formData.ageLimit?.split(', ')) },
        formData.description && { name: 'Описание', value: formData.description },
        formData.source?.value && { name: 'Кто добавил', value: getSourceValue(formData.source) },
        formData.creationDate && { name: 'Дата добавления', value: formatDateWithTime(formData.creationDate) },
        formData.editDate && { name: 'Дата изменения', value: formatDateWithTime(formData.editDate) }

      ].filter((info) => info);
    case SectionCodes.cultureOnline:
      return [
        formData.name && { name: 'Событие', value: formData.name },
        formData.levelEvent && { name: 'Уровень мероприятия', value: formData.levelEvent.value },
        formData.startDate && { name: 'Даты посещения', value: getDateRange(formData.startDate, formData.endDate) },
        formData.actionStage && { name: 'Конкретное событие в рамках мероприятия', value: formData.actionStage },
        formData.result && { name: 'Результат', value: formData.result },
        formData.organizators && { name: 'Организатор', value: formData.organizators },
        formData.ageLimit && { name: 'Возрастное ограничение', value: getCardAgeLimitFromArray(formData.ageLimit?.split(', ')) },
        formData.description && { name: 'Описание', value: formData.description },
        formData.source?.value && { name: 'Кто добавил', value: getSourceValue(formData.source) },
        formData.creationDate && { name: 'Дата добавления', value: formatDateWithTime(formData.creationDate) },
        formData.editDate && { name: 'Дата изменения', value: formatDateWithTime(formData.editDate) }
      ].filter((info) => info);
    case SectionCodes.professionEventChampionship: {
      return [
        formData.profession && { name: 'Профессия', value: formData.profession },
        formData.startDate && { name: 'Даты проведения', value: getDateRange(formData.startDate, formData.endDate) },
        formData.levelEvent && { name: 'Уровень мероприятия', value: formData.levelEvent.value },
        formData.format && { name: 'Формат проведения', value: formData.format.value },
        { name: 'Компетенция презентационная', value: formData.isPresentationCompetence ? 'Да' : 'Нет' },
        formData.participantCategory && { name: 'Категория участников', value: formData.participantCategory },
        formData.eventNumber && { name: 'Номер мероприятия', value: formData.eventNumber },
        formData.stageEvent && { name: 'Этап мероприятия', value: formData.stageEvent },
        formData.stageStartDate && { name: 'Даты этапа', value: getDateRange(formData.stageStartDate, formData.stageEndDate) },
        formData.actionStage && { name: 'Событие', value: formData.actionStage },
        formData.actionDate && { name: 'Дата события', value: formatDate(formData.actionDate) },
        formData.organizators && { name: 'Организатор', value: formData.organizators },
        formData.participantNumber && { name: 'Номер участника', value: formData.participantNumber },
        formData.ageLimit && { name: 'Возрастное ограничение', value: getCardAgeLimitFromArray(formData.ageLimit?.split(', ')) },
        // formData.profession && { name: 'ОВЗ', value: formData.profession },
        formData.location && { name: 'Место проведения', value: formData.location },
        formData.result && { name: 'Результат', value: formData.result },
        formData.description && { name: 'Описание', value: formData.description },
        formData.source && { name: 'Кто добавил', value: getSourceValue(formData.source) },
        formData.creationDate && { name: 'Дата добавления', value: formatDateWithTime(formData.creationDate) },
        formData.editDate && { name: 'Дата изменения', value: formatDateWithTime(formData.editDate) }
      ].filter((info) => info);
    }
    case SectionCodes.professionExamGia:
      return [
        formData.competenceCode && { name: 'Код компетенции Worldskills', value: formData.competenceCode },
        formData.name && { name: 'Компетенция', value: formData.name },
        formData.resultScore && { name: 'Количество баллов', value: formData.resultScore },
        formData.maxCompetenceScore && { name: 'Максимальное кол-во баллов', value: formData.maxCompetenceScore },
        formData.resultDate && { name: 'Дата проведения экзамамена', value: formData.resultDate },
        formData.kod && { name: 'Код', value: formData.kod },
        formData.isEarlyRelease && { name: 'Программа с ранним выпуском', value: formData.isEarlyRelease ? 'да' : 'нет' },
        formData.source && { name: 'Кто добавил', value: getSourceValue(formData.source) },
        formData.creationDate && { name: 'Дата добавления', value: formatDateWithTime(formData.creationDate) },
      ].filter((info) => info);
    default:
      return [
        formData.subcategory && { name: 'Подкатегория', value: formData.subcategory?.value },
        formData.levelProject && { name: 'Уровень проекта', value: formData.levelProject.value },
        formData.disciplines && { name: 'Предмет / дисциплина', value: formData.disciplines?.map((discipline: IDictionaryItem) => discipline.value).join(', ') },
        formData.stageStartDate && { name: 'Даты работы над прое...', value: getDateRange(formData.startDate, formData.endDate) },
        formData.description && { name: 'Описание', value: formData.description },
        formData.source && { name: 'Кто добавил', value: getSourceValue(formData.source) },
        formData.creationDate && { name: 'Дата добавления', value: formatDateWithTime(formData.creationDate) },
        formData.editDate && { name: 'Дата изменения', value: formatDateWithTime(formData.editDate) }
      ].filter((info) => info);
  }
};

export const getLinkedEntityIcon = (typeCode?: number | null) => {
  const modifier = '64';
  const studyColor = getAccentColor('violet', modifier);
  const scienceColor = getAccentColor('indigo', modifier);
  const sportColor = getAccentColor('cyan', modifier);
  const creationColor = getAccentColor('orange', modifier);
  const cultureColor = getAccentColor('red', modifier);
  const civilColor = getAccentColor('green', modifier);

  switch (typeCode) {
    case SectionCodes.contestReward:
    case SectionCodes.projectReward:
      return <widgetIcons.IconWidgetScienceRewards fill={scienceColor} />;
    case SectionCodes.gtoReward:
    case SectionCodes.sportRank:
    case SectionCodes.sportCategory:
    case SectionCodes.sportRewardTourism:
    case SectionCodes.sportRewardCompetition:
      return <widgetIcons.IconWidgetSportRewards fill={sportColor} />;
    case SectionCodes.creationContestReward:
      return <widgetIcons.IconWidgetCreationRewards fill={creationColor} />;
    case SectionCodes.rewardOlympiad:
    case SectionCodes.eventOlympiad:
      return <widgetIcons.IconWidgetStudyOlympiads fill={studyColor} />;
    case SectionCodes.scienceCircle:
      return <widgetIcons.IconWidgetScienceEmployments fill={scienceColor} />;
    case SectionCodes.contest:
    case SectionCodes.conference:
      return <widgetIcons.IconWidgetScienceContests fill={scienceColor} />;
    case SectionCodes.sportClub:
    case SectionCodes.sportTeam:
      return <widgetIcons.IconWidgetSportClubs fill={sportColor} />;
    case SectionCodes.sportOccupationCircle:
    case SectionCodes.sportOccupationSection:
    case SectionCodes.sportEventCompetition:
      return <widgetIcons.IconWidgetSportContests fill={sportColor} />;
    case SectionCodes.sportEventExpedition:
    case SectionCodes.sportEventTourism:
      return <widgetIcons.IconWidgetSportHikes fill={sportColor} />;
    case SectionCodes.creationCollective:
      return <widgetIcons.IconWidgetCreationCollectives fill={creationColor} />;
    case SectionCodes.creationContest:
      return <widgetIcons.IconWidgetCreationContests fill={creationColor} />;
    case SectionCodes.creationCircle:
    case SectionCodes.creationSchool:
      return <widgetIcons.IconWidgetCreationUnits fill={creationColor} />;
    case SectionCodes.cultureOnline:
      return <widgetIcons.IconWidgetCultureOnlineEvents fill={cultureColor} />;
    case SectionCodes.cultureVisiting:
      return <widgetIcons.IconWidgetCultureOfflineEvents fill={cultureColor} />;
    case SectionCodes.civilAffilationGroup:
    case SectionCodes.civilAffilationMovement:
      return <widgetIcons.IconWidgetCivilClubs fill={civilColor} />;
    case SectionCodes.civilOccupationCircle:
      return <widgetIcons.IconWidgetCivilUnits fill={civilColor} />;
    case SectionCodes.civilEventEcology:
    case SectionCodes.civilEventLocal:
    case SectionCodes.civilEventVolonteer:
    case SectionCodes.civilEventPatriotic:
      return <widgetIcons.IconWidgetCivilContests fill={civilColor} />;
    case SectionCodes.civilEventQuest:
    case SectionCodes.civilRewardStatus:
    case SectionCodes.civilRewardEvent:
      return <widgetIcons.IconWidgetCivilRewards fill={civilColor} />;
    default: return <widgetIcons.IconWidgetScienceProjects fill={scienceColor} />;
  }
};

export const getFormDataEditAction = (
  formData: IPortfolioEntryFormData
): (formData: IPortfolioEntryFormData) => IPortfolioEntryFormData => {
  const optionalParams: Partial<IPortfolioEntryFormData> = {};

  if (
    formData.dataKind === SectionCodes.studyReward
    || formData.dataKind === SectionCodes.scienceReward
    || formData.dataKind === SectionCodes.creationReward
    || formData.dataKind === SectionCodes.civilReward
    || formData.dataKind === SectionCodes.professionReward
  ) {
    optionalParams.entityId = formData.entityId;
  }
  if (formData.typeCode === SectionCodes.sportRewardTourism) {
    optionalParams.name = formData.name;
  }
  if (formData.typeCode === SectionCodes.studySpoDocuments) {
    optionalParams.studySpoDocument = {
      documentTypeCode: formData.studySpoDocument?.documentTypeCode,
    };
  }

  if (formData.id) {
    optionalParams.id = formData.id;
  }

  return (formData) => ({
    ...defaultPortfolioEntryFormData,
    attachment: formData.attachment,
    linkedObjects: formData.linkedObjects,
    categoryCode: formData.categoryCode,
    dataKind: formData.dataKind,
    typeCode: formData.typeCode,
    ...optionalParams
  });
};

export const getFormDataResetAction = (
  formData: IPortfolioEntryFormData
): (formData: IPortfolioEntryFormData) => IPortfolioEntryFormData => {
  let type: number | undefined;

  // когда доступен только один тип данных
  if (formData.dataKind === SectionCodes.studyEvent
    || formData.dataKind === SectionCodes.creationEvent
    || formData.dataKind === SectionCodes.studyReward
    || formData.dataKind === SectionCodes.creationReward
    || formData.typeCode === SectionCodes.sportEventCompetition
    || formData.typeCode === SectionCodes.studySpoDocuments
  ) {
    type = formData.typeCode;
  }

  return (formData) => ({
    ...defaultPortfolioEntryFormData,
    categoryCode: formData.categoryCode,
    dataKind: formData.dataKind,
    typeCode: type
  });
};

export const getSourceCode = (user: IUserFunctionality) => {
  if (user.isStudent) return SourceCode.student;
  if (user.isParent) return SourceCode.parent;
  if (user.isOperator) return SourceCode.operator;
  if (user.isEmployee) return SourceCode.employeeOo;
  if (user.isAdmin) return SourceCode.admin;

  return undefined;
}

// export const getSourceCode = (userData: IUserData): number | undefined => {
//   const { applications } = userData;
//   const { availableSubsystemRoles } = userData.currentGlobalRole as ICurrentRole;
//   const { globalRoleTagCode } = userData.currentGlobalRole?.globalRoleTag as IGlobalRoleTag;

//   if (globalRoleTagCode === RoleKeys.PARENT) return RoleSourceCodes[RoleSourceKeys.PARENT];
//   if (globalRoleTagCode === RoleKeys.ADMIN) return RoleSourceCodes[RoleSourceKeys.ADMINISTRATOR];
//   if (globalRoleTagCode === RoleKeys.STUDENT) return RoleSourceCodes[RoleSourceKeys.STUDENT];

//   if (globalRoleTagCode === RoleKeys.EMPLOYEE) {
//     const currentApplicationId = getCurrentApplicationId(applications);
//     const validSubsystemRoleCodes = getValidSubsystemRoles(currentApplicationId, availableSubsystemRoles)?.map((role) => role.id);
//     const isAnyCodeOperators = validSubsystemRoleCodes?.some((code) => operatorLocalRoles.includes(code));
//     const isAnyCodeOO = validSubsystemRoleCodes?.some((code) => ooLocalRoles.includes(code));

//     if (isAnyCodeOperators) return RoleSourceCodes.OPERATOR;
//     if (isAnyCodeOO) return RoleSourceCodes.EMPLOYEE_O_O;
//   }
// };
