import { FAILURE, INVALIDATE, REQUEST, SUCCESS } from 'actions/utils';
import { ICommonResponseObject, IFavoriteUniversity } from 'api/types';
import { IReducers } from 'reducers';
import genericReducers from 'reducers/genericReducer';
import { connectReducers } from 'utils';

import { GET_FAVORITE_VUZ_LIST } from '../actions';

export type FavoriteVuzListState = ICommonResponseObject<IFavoriteUniversity[] | null>;

const initialState: FavoriteVuzListState = {
  content: null,
  loading: false,
};

const favoriteVuzList: IReducers<FavoriteVuzListState> = {
  [GET_FAVORITE_VUZ_LIST[REQUEST]]: genericReducers().get.request,
  [GET_FAVORITE_VUZ_LIST[SUCCESS]]: genericReducers().get.success,
  [GET_FAVORITE_VUZ_LIST[FAILURE]]: genericReducers().get.failure,
  [GET_FAVORITE_VUZ_LIST[INVALIDATE]]: genericReducers().get.invalidate(initialState),
};

export default connectReducers(initialState, favoriteVuzList);
