import { SxStyles } from 'types';

export const container: SxStyles = (theme) => ({
  display: 'flex',
  minHeight: '112px',
  minWidth: '112px',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',

  '& canvas': {
    position: 'absolute',
  },

  [theme.breakpoints.down('commonSm')]: {
    minHeight: '100px',
    minWidth: '100px',
  },
});

export const score: SxStyles = {
  width: '92px',
  position: 'absolute',
  gap: '2px',
  textAlign: 'center',
};
