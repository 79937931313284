import { radioClasses, styled } from '@mui/material';
import { getAccentColor, NeutralColors } from 'portfolio3/styles';

import { RadioButtonSize } from '../types';
import { getRadioButtonTokens } from '../utils';

interface IStyledIconProps {
  size?: RadioButtonSize;
}

const StyledRadioIcon = styled('div')<IStyledIconProps>(({ size, theme }) => {
  const accentColor = getAccentColor(theme.palette.accentColor, '100');
  const tokens = size ? getRadioButtonTokens(size) : getRadioButtonTokens('medium');
  const { innerSize } = tokens;

  return {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',

    borderRadius: '50%',
    border: '2px solid',
    borderColor: NeutralColors.light_neutrals_400,

    [`.${radioClasses.root}:hover &, .${radioClasses.checked} &`]: {
      borderColor: accentColor,
    },

    [`.${radioClasses.disabled} &`]: {
      borderColor: NeutralColors.light_neutrals_400,
    },

    '&::after': {
      content: '""',
      position: 'absolute',

      width: innerSize,
      height: innerSize,

      borderRadius: '50%',
      backgroundColor: accentColor,
      transform: 'scale(0)',
      transition: 'transform 0.1s',

      [`.${radioClasses.checked} &`]: {
        transform: 'scale(1)',
      },

      [`.${radioClasses.disabled} &`]: {
        backgroundColor: NeutralColors.light_neutrals_500,
      },
    },
  };
});

export default StyledRadioIcon;
