import { styled, svgIconClasses } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';

import StyledBaseButton from '../bases/BaseButton.styled';

const StyledGrayButton = styled(StyledBaseButton)(({ checked }) => {
  const backgroundColor = checked ? NeutralColors.light_neutrals_0 : 'transparent';
  const borderColor = checked ? NeutralColors.light_neutrals_300 : 'transparent';
  const boxShadow = checked ? '0px 1px 3px rgba(41, 41, 64, 0.08), 0px 1px 2px rgba(41, 41, 64, 0.06)' : 'none';

  return {
    border: '1px solid',

    backgroundColor,
    borderColor,
    boxShadow,

    color: NeutralColors.light_neutrals_900,

    '&:hover': {
      backgroundColor: NeutralColors.light_neutrals_300,
    },

    '&:hover.switcher-button--checked': {
      backgroundColor: NeutralColors.light_neutrals_100,
    },

    '&.switcher-button--disabled': {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      boxShadow: 'none',
      color: NeutralColors.light_neutrals_500,

      [`& .${svgIconClasses.root}`]: {
        color: NeutralColors.light_neutrals_400,
      },
    },

    '&.switcher-button--disabled.switcher-button--checked': {
      backgroundColor: NeutralColors.light_neutrals_0,
    },
  };
});

export default StyledGrayButton;
