import { IStudentAffilation } from 'api/types';
import { SourceCode } from 'const';
import { getCivilAffiliationAchiementImage, isDefined } from 'utils';

export const getCivilAffilationAchievementImage = (affilation: IStudentAffilation) => {
  const currentDate = new Date();

  const { affilationKind, source, endDate } = affilation;
  const affilationKindCode = affilationKind?.code;

  const isEndDateNull = endDate === null;
  const isEndDateAfterCurrentDate = isDefined(endDate) && new Date(endDate) > currentDate;

  const isRequiredSourceCode = source.code != SourceCode.student && source.code !== SourceCode.parent;
  const isRequiredDate = isEndDateNull || isEndDateAfterCurrentDate;

  if (!isRequiredSourceCode && !isRequiredDate) return null;
  if (!affilationKindCode) return null;

  return getCivilAffiliationAchiementImage(affilationKindCode) ?? null;
};
