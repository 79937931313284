import { FC } from 'react';

import { Box } from '@mui/material';

import { DragIndicatorSize } from '../types';
import * as styles from './style';

interface IDragIndicatorProps {
  size: DragIndicatorSize;
}

const DragIndicator: FC<IDragIndicatorProps> = ({ size }) => {
  return <Box sx={styles.dragIndicator(size)} className="drag-indicator" />;
};

export default DragIndicator;
