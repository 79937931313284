import { SxStyles } from 'types';

export const root: SxStyles = {
  width: '100%',
  height: '100%',

  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
};
