import { StudentSectionSettingTypeCodes } from 'const';
import { useAppSelector } from 'hooks';
import { linkVisibilitySettingsSelector, visibilitySettingsSelector } from 'selectors';
import { findTargetVisibilitySetting } from 'utils/localVisibilitySettingsService';

/**
 * Хук определяет является ли текущий учащися "СПО" по наличию доступного раздела в учебе
 */
const useSPOStudent = () => {
  const mainSettings = useAppSelector((state) => visibilitySettingsSelector(state));
  const linkSettings = useAppSelector((state) => linkVisibilitySettingsSelector(state));
  const linkMode = useAppSelector((state) => state.linkMode.mode) ?? false;

  const visibilitySettings = linkMode ? linkSettings : mainSettings;
  const studyTrainingSectionSettings = findTargetVisibilitySetting(visibilitySettings, [
    StudentSectionSettingTypeCodes.study,
    StudentSectionSettingTypeCodes.studyTraining,
  ]);

  return studyTrainingSectionSettings?.isAdminVisible ?? false;
};

export default useSPOStudent;
