/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useContext, useMemo } from 'react';

import { RewardSecondaryBlock } from '../basicBlocks';
import { IDictionaryItem } from '../../../../../api/types';

import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';
import { SearchSelect } from 'portfolio3/ui-kit/selects';
import { BaseInputLabel, FormControl } from 'portfolio3/ui-kit';
import { Box, FormHelperText } from '@mui/material';
import { IController } from 'portfolio3/ui-kit/types';
import { getDayPickerControllerFactory } from '../../controllers';
import { DayPicker } from 'portfolio3/ui-kit/datePickers';

const today = new Date();

interface ISportRankRewardSecondaryBlockProps {
  sportKindOptions: IDictionaryItem[];
}

const SportRankRewardSecondaryBlock: React.FC<ISportRankRewardSecondaryBlockProps> = ({
  sportKindOptions,
}) => {
  const { isMobile, inputRenderMode, inputSize, formData, formErrors, onChangeFormData, onChangeFormErrors } = useContext(LocalDataEntryFormContext);

  const dayPickerControllerFactory = getDayPickerControllerFactory(onChangeFormData, onChangeFormErrors);

  const sportKindController: IController<string | undefined> = useMemo(() => ({
    handleChange: (sportKind) => {
      onChangeFormData((prevState) => ({
        ...prevState,
        sportKind: Number(sportKind),
        subjectId: []
      }));
      onChangeFormErrors((prevState) => ({
        ...prevState,
        sportKindError: false
      }));
    },
    handleBlur: (type) => {
      if (!type) {
        onChangeFormErrors((prevState) => ({
          ...prevState,
          sportKindError: true
        }));
      }
    }
  }), [formData.id]);

  const dateController = dayPickerControllerFactory('expireDate', 'endDateError', {
    disablePast: true,
  });

  const additionalTopControllers = (
    <FormControl
      required
      renderMode={inputRenderMode}
      inputSize={inputSize}
      label={<BaseInputLabel>Вид спорта</BaseInputLabel>}
      error={formErrors.sportKindError}
      helper={formErrors.sportKindError && <FormHelperText>Выберите вид спорта</FormHelperText>}
      control={
        <SearchSelect
          inputRenderMode={inputRenderMode}
          inputSize={inputSize}
          placeholder={formData.subCategory ? 'Выберите вид спорта...' : 'Сначала выберите подкатегорию'}
          value={formData?.sportKind}
          options={sportKindOptions}
          controller={sportKindController}
          strictMaxWidth
        />
      }
    />
  );

  const additionalMiddleControllers = (
    <FormControl
      renderMode={inputRenderMode}
      inputSize={inputSize}
      error={formErrors.endDateError.active}
      helper={formErrors.endDateError.active && <FormHelperText>{formErrors.endDateError.description}</FormHelperText>}
      label={<BaseInputLabel>Дата окончания действия</BaseInputLabel>}
      control={
        <Box sx={!isMobile ? { width: '220px' } : undefined}>
          <DayPicker
            isMobile={isMobile}
            renderMode={inputRenderMode}
            size={inputSize}
            value={formData.expireDate ?? null}
            minValue={today}
            controller={dateController}
          />
        </Box>
      }
    />
  );

  return (
    <RewardSecondaryBlock
      additionalTopControllers={additionalTopControllers}
      additionalMiddleControllers={additionalMiddleControllers}
    />
  );
};

export default SportRankRewardSecondaryBlock;
