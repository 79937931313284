import { SxStyles } from 'types';

export const drawerContent: SxStyles = () => ({
  paddingInline: '16px',
  paddingBottom: '20px',
});

export const drawerTitleContainer: SxStyles = () => ({
  display: 'flex',
  justifyContent: 'center',

  paddingTop: '24px',
  paddingBottom: '16px',
});

export const radioGroup: SxStyles = () => ({
  gap: '12px',

  paddingTop: '5px',
});
