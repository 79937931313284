import { FC, useContext, useState } from 'react';

import { Box, Stack, SxProps, useMediaQuery } from '@mui/material';
import { IDictionaryItem } from 'api/types';
import { DIAGNOSTIC_PERIOD_ALL_CODE, IndependentDiagnosticViewTypeCode, StudentSectionSettingTypeCodes } from 'const';
import { useDisclosure, useWidgetVisibility } from 'hooks';
import { IconBarChart, IconStarFilled } from 'icons';
import FilterButton from 'portfolio3/components/common/buttons/FilterButton';
import MobileActionsDrawer from 'portfolio3/components/drawers/MobileActionsDrawer';
import { RadioGroupControl, RadioGroupController } from 'portfolio3/components/forms/RadioGroupControl';
import { emitYMEvent } from 'portfolio3/features/yandexMetrika';
import { commonTheme } from 'portfolio3/styles/theme';
import { Switcher, SwitcherButton } from 'portfolio3/ui-kit';
import { Select } from 'portfolio3/ui-kit/selects';
import { CommonUiKitSize, IController } from 'portfolio3/ui-kit/types';
import { isCurrentLearningYear } from 'utils';

import DiagnosticContext from '../../context/diagnosticContext';

const IndependentDiagnosticsFilter: FC = () => {
  const { filters, independentDiagnosticPeriods, setSingleFilter } = useContext(DiagnosticContext);
  const [tempPeriodCode, setTempPeriodCode] = useState(filters.independentPeriodCode);
  const isSmallScreen = useMediaQuery(commonTheme.breakpoints.down('commonSm'));
  const { isOpen, onOpen, onClose } = useDisclosure();

  // видимость блока с рейтингом в независимых диагностиках
  const { isSettingsMode, toggleElement } = useWidgetVisibility({
    sectionTypeCode: StudentSectionSettingTypeCodes.study,
    subsectionTypeCode: StudentSectionSettingTypeCodes.studyDiagnostic,
    subcategoryTypeCode: StudentSectionSettingTypeCodes.diagnosticIndependentRating,
  });

  const changeViewType = (viewType: number) => {
    setSingleFilter('independentViewType', viewType);

    if (viewType !== filters.independentViewType) {
      emitYMEvent({ type: 'diagnosticDisplayTypeSwitching' });
    }
  };

  const changePeriod = (period: number) => {
    setSingleFilter('independentPeriodCode', period);
  };

  const periodController: IController<string | undefined> = {
    handleChange(periodCode) {
      emitYMEvent({ type: 'yearStudySwitching' });
      changePeriod(Number(periodCode) || DIAGNOSTIC_PERIOD_ALL_CODE);
    },
  };

  const tempPeriodController: RadioGroupController = {
    handleChange(periodCode) {
      setTempPeriodCode(periodCode ?? DIAGNOSTIC_PERIOD_ALL_CODE);
    },
  };

  const handleOpenFiltersDrawer = () => {
    setTempPeriodCode(filters.independentPeriodCode);
    onOpen();
  };

  const handleResetFiltersDrawer = () => {
    if (independentDiagnosticPeriods.length > 0) {
      setTempPeriodCode(independentDiagnosticPeriods[0].code);
    }
  };

  const handleApplyFiltersDrawer = () => {
    changePeriod(tempPeriodCode || DIAGNOSTIC_PERIOD_ALL_CODE);
    onClose();
  };

  const selectPeriodsRenderValue = (subjectCode: string | undefined): string => {
    const period = independentDiagnosticPeriods?.find((period) => period.code === Number(subjectCode))?.value;
    const isCurrentPeriod = isCurrentLearningYear(period || '');
    const appendedText = isCurrentPeriod ? ' (текущий)' : '';
    return `${period} уч. год` + appendedText;
  };

  const drawerPeriodsRenderLabel = (period: IDictionaryItem): string => {
    const isCurrentPeriod = isCurrentLearningYear(period.value);
    const appendedText = isCurrentPeriod ? ' (текущий)' : '';
    return period.value + appendedText;
  };

  const switcherStyles: SxProps = isSmallScreen ? { width: '100%' } : null;
  const switcherSize: CommonUiKitSize = isSmallScreen ? 'small' : 'medium';

  const isFiltersApplied = filters.independentPeriodCode !== DIAGNOSTIC_PERIOD_ALL_CODE;

  const rightFiltersElement = independentDiagnosticPeriods.length > 0 && (
    <>
      {!isSmallScreen && (
        <Box sx={{ width: '272px' }}>
          <Select
            inputRenderMode="fixed"
            inputSize="medium"
            value={filters.independentPeriodCode}
            options={independentDiagnosticPeriods}
            controller={periodController}
            renderValue={selectPeriodsRenderValue}
          />
        </Box>
      )}
      {isSmallScreen && (
        <>
          <FilterButton onClick={handleOpenFiltersDrawer} withIndicator={isFiltersApplied} />

          <MobileActionsDrawer
            open={isOpen}
            onClose={onClose}
            title="Фильтр независимые диагностики"
            isResetDisabled={tempPeriodCode === independentDiagnosticPeriods[0]?.code}
            onReset={handleResetFiltersDrawer}
            onApply={handleApplyFiltersDrawer}
          >
            <RadioGroupControl
              isMobile
              label="Учебный год"
              value={tempPeriodCode}
              options={independentDiagnosticPeriods}
              controller={tempPeriodController}
              renderLabel={drawerPeriodsRenderLabel}
            />
          </MobileActionsDrawer>
        </>
      )}
    </>
  );

  return (
    <Stack direction="row" flexWrap="wrap" justifyContent="space-between" gap="12px">
      <Box sx={{ flex: 1 }}>
        <Switcher
          sx={switcherStyles}
          componentSize={switcherSize}
          value={filters.independentViewType}
          onChange={changeViewType}
        >
          <SwitcherButton value={IndependentDiagnosticViewTypeCode.rating} content={<IconStarFilled />} />
          <SwitcherButton value={IndependentDiagnosticViewTypeCode.analysis} content={<IconBarChart />} />
        </Switcher>
      </Box>

      {isSettingsMode ? toggleElement : rightFiltersElement}
    </Stack>
  );
};

export default IndependentDiagnosticsFilter;
