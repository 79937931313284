import { FC } from 'react';

import { Box } from '@mui/material';
import { noOlimpiads } from 'images';
import { SxStyles } from 'types';

const defaultImage: SxStyles = (theme) => {
  return {
    width: '268px',
    height: '100%',

    [theme.breakpoints.down('commonSm')]: {
      width: '220px',
    },
  };
};

const DefaultImagePlug: FC = () => {
  return <Box component="img" src={noOlimpiads} alt="Нет данных" sx={defaultImage} />;
};

export default DefaultImagePlug;
