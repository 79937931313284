import React from 'react';

import { Box } from '@mui/material';

import { root } from './style';

interface IThemeBgImagePreviewProps {
  img: string;
}

const ThemeBgImagePreview: React.FC<IThemeBgImagePreviewProps> = ({ img }) => {
  return (
    <Box sx={root}>
      <img src={img} alt="Картинка" />
    </Box>
  );
};

export default ThemeBgImagePreview;
