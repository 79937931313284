/* eslint-disable prettier/prettier */
/* eslint-disable */

import connectReducers from '../utils/connectReducers';

import {
  CLEAR_INDEPENDENT_DIAGNOSIS_SETTING,
  SET_INDEPENDENT_DIAGNOSIS_SETTING
} from '../actions';

export type IndependentDiagnosisSettingsState = {
  settings: {
    [key: string]: boolean;
  };
}

export interface IIndependentDiagnosisSettingsReducers {
  [key: string]: (state: IndependentDiagnosisSettingsState, action: any) => IndependentDiagnosisSettingsState;
}

type Payload = {
  recordId: string;
  isVisible: boolean;
}

const initialState = {
  settings: {}
};

const independentDiagnosisSettings: IIndependentDiagnosisSettingsReducers = {
  [SET_INDEPENDENT_DIAGNOSIS_SETTING]: (state, { payload: { recordId, isVisible } }: { payload: Payload }) => ({
    settings: {
      ...state.settings,
      [recordId]: isVisible
    }
  }),
  [CLEAR_INDEPENDENT_DIAGNOSIS_SETTING]: (state) => ({
    settings: {}
  })
};

export default connectReducers(initialState, independentDiagnosisSettings);
