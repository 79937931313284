import { FC, ReactNode } from 'react';

import { Box } from '@mui/material';
import { SxStyles } from 'types';
import { mergeSx } from 'utils';

const styles: SxStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  width: '32px',
  height: '32px',
  minWidth: '32px',

  borderRadius: '50%',

  svg: {
    width: '20px',
    height: '20px',
  },
};

interface IStatusIconProps {
  color: string;
  iconElement: ReactNode;
  backgroundColor: string;
}

const StatusIcon: FC<IStatusIconProps> = ({ color, iconElement, backgroundColor }) => {
  const dynamicStyles: SxStyles = {
    color,
    backgroundColor,
  };

  return (
    <Box className="status-icon" sx={mergeSx(styles, dynamicStyles)}>
      {iconElement}
    </Box>
  );
};

export default StatusIcon;
