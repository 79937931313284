/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import PdfRewardBlock from './RewardBlock';
import { IModifiedReward } from '../../../types';

import './index.scss';

interface IPdfCommonRewardsProps {
  rewards: IModifiedReward[];
}

const PdfCommonRewards: React.FC<IPdfCommonRewardsProps> = ({
  rewards
}) => (
  <div className="pdf-common-rewards">
    {rewards.map((reward) => (
      <PdfRewardBlock key={reward.id} reward={reward} />
    ))}
  </div>
);

export default PdfCommonRewards;
