import { FC } from 'react';

import { Box } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';

import { ISwitcherButtonProps } from '../../types';
import SwitcherButton from './SwitcherButton';

interface ISwitcherButtonCountProps extends ISwitcherButtonProps {
  content: string;
  count: number;
}
type ButtonCountContentProps = Pick<ISwitcherButtonCountProps, 'content' | 'count' | 'componentVariant'> & {
  checked?: boolean;
};

const ButtonCountContent: FC<ButtonCountContentProps> = ({ content, count, checked, componentVariant = 'accent' }) => {
  let counterColor;
  if (componentVariant === 'accent') {
    counterColor = checked ? NeutralColors.light_neutrals_0 : NeutralColors.light_neutrals_600;
  } else {
    counterColor = NeutralColors.light_neutrals_600;
  }

  return (
    <>
      <Box component="span">{content}</Box>
      <Box
        sx={{
          ml: 1,
          color: counterColor,
        }}
        component="span"
      >
        {count}
      </Box>
    </>
  );
};

const SwitcherButtonCount: FC<ISwitcherButtonCountProps> = ({ ...props }) => {
  const Content = <ButtonCountContent {...props} checked={props.InputProps?.checked} />;

  return <SwitcherButton {...props} content={Content} />;
};

export default SwitcherButtonCount;
