import { buildConfig, IBuildConfig } from 'packages/buildConfig';

import BaseApi from './BaseApi';
import { ApiHeaders, ApiRequestMethod } from './types';
import { prepareHeaders, processBaseResponse } from './utils';

export abstract class LocalServiceApi extends BaseApi {
  private token: string | undefined;
  protected xApiKey: string | undefined;

  constructor(prefix: string) {
    super({ url: '', prefix });

    this.initConfigLoad();
  }

  setToken(token: string) {
    this.token = token;
  }

  removeToken() {
    this.token = undefined;
  }

  protected abstract onConfigLoad(config: IBuildConfig): void;

  protected request: ApiRequestMethod = (method: string, { path, requestBody, requestHeaders }) => {
    const baseHeaders: ApiHeaders = requestHeaders ?? { 'Content-Type': 'application/json' };
    const headers = prepareHeaders(baseHeaders, { token: this.token, xApiKey: this.xApiKey });

    return fetch(`${this.url}${this.prefix}${path}`, {
      method,
      body: requestHeaders ? requestBody : JSON.stringify(requestBody),
      headers,
    })
      .then(async (response) => {
        return await processBaseResponse(response);
      })
      .then(
        (response) => ({ response }),
        (error) => ({ error }),
      );
  };

  private initConfigLoad() {
    if (buildConfig.config) {
      this.onConfigLoad(buildConfig.config);
    } else {
      // отписка не требуется
      buildConfig.onLoad((config) => {
        this.onConfigLoad(config);
      });
    }
  }
}
