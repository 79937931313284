import React from 'react';

import { Slide, Slider } from 'pure-react-carousel';

import { IRecommendationEntity } from '../../../../../types';
import Carousel from '../../../../common/Carousel';
import { SectionRecommendation } from '../recommendation';

import './index.scss';

interface IInterestRecommendationCarouselProps {
  recommendations: IRecommendationEntity[];
}

const InterestRecommendationCarousel: React.FC<IInterestRecommendationCarouselProps> = ({ recommendations }) => {
  return (
    <div className="interest-recommendation-carousel">
      <Carousel items={recommendations} cardWidth={220} gap={12}>
        <Slider>
          {recommendations.map((recommendation, index) => (
            <Slide key={`${index}-${recommendation.sectionCode}`} index={index} className="slide">
              <SectionRecommendation recommendation={recommendation} />
            </Slide>
          ))}
        </Slider>
      </Carousel>
    </div>
  );
};

export default InterestRecommendationCarousel;
