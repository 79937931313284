import { FC, PropsWithChildren, ReactNode } from 'react';

import { styled, Typography, typographyClasses } from '@mui/material';
import { emitYMEvent } from 'portfolio3/features/yandexMetrika';
import { getAccentColor, NeutralColors } from 'portfolio3/styles';
import { getFocusStateStyles, resetButtonStyles } from 'portfolio3/ui-kit/utils';
import { SxStyles } from 'types';
import { Tooltip } from 'ui-kit';

export interface IRatingHeader {
  title: string;
  buttonText: string;
  tooltipText: ReactNode;
  sx?: SxStyles;
}

const StyledRatingHeader = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    justifyContent: 'space-between',

    [theme.breakpoints.down('commonSm')]: {
      [`& .${typographyClasses.root}.rating-header-title`]: {
        fontSize: '15px',
        fontWeight: 700,
      },
    },
  };
});

const tooltipButtonStyles: SxStyles = {
  ...resetButtonStyles,

  '&:hover': {
    cursor: 'pointer',
    color: getAccentColor('indigo', '100'),
  },

  '&:focus-visible': {
    ...getFocusStateStyles(),
  },
};

const RatingHeader: FC<IRatingHeader & PropsWithChildren> = ({ title, buttonText, tooltipText, sx, children }) => {
  const handleOpenTooltip = () => {
    emitYMEvent({ type: 'helperDiagnosticsDisclosure' });
  };

  return (
    <StyledRatingHeader sx={sx}>
      <Typography
        className="rating-header-title"
        color={NeutralColors.light_neutrals_900}
        variant="Paragraph MD/Semi Bold"
      >
        {title}
      </Typography>
      {children}
      <Tooltip
        title={tooltipText}
        PopperProps={{ sx: { width: '320px' } }}
        arrow
        placement="top"
        onOpen={handleOpenTooltip}
      >
        <Typography
          component="button"
          color={NeutralColors.light_neutrals_600}
          sx={tooltipButtonStyles}
          variant="Paragraph MD/Semi Bold"
        >
          {buttonText}
        </Typography>
      </Tooltip>
    </StyledRatingHeader>
  );
};

export default RatingHeader;
