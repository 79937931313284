import { FC, useMemo } from 'react';

import { useAppSelector, useDataLoad } from 'hooks';
import PdfNoDataText from 'portfolio3/features/pdf/components/PdfNoDataText';
import { getDataLoadRequests } from 'utils';

import { PdfCommonDataEntity } from '../../../../components/pdfStudent/DataEntity';
import { widgetPdfIcons } from '../../../../icons';
import { professionAdditionalEducationSelector } from '../../../../selectors';
import PdfCommonDataEntityContainer from '../common';

import '../index.scss';

const PdfAdditionalEducationContainer: FC = () => {
  const additionalEducation = useAppSelector(professionAdditionalEducationSelector);
  useDataLoad({ shouldLoad: true, requests: getDataLoadRequests().professionEducation });

  const hasData = additionalEducation.content.length > 0;

  const filteredYear = useMemo(
    () =>
      additionalEducation.content.length > 0
        ? additionalEducation.content?.reduce((prevItem, nextItem) =>
            prevItem.year > nextItem.year ? prevItem : nextItem,
          ).year
        : undefined,
    [additionalEducation],
  );

  return (
    <PdfCommonDataEntityContainer title="Профессиональное обучение" icon={widgetPdfIcons.pdfIconProfessionEducation}>
      {hasData &&
        additionalEducation.content.map((entity) => (
          <PdfCommonDataEntity key={entity.id || entity.recordId} entity={entity} year={filteredYear} />
        ))}
      {!hasData && <PdfNoDataText />}
    </PdfCommonDataEntityContainer>
  );
};

export default PdfAdditionalEducationContainer;
