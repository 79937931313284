import { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import { Box } from '@mui/material';
import { IconArrowDiagonalRightUp } from 'icons';
import { Button } from 'portfolio3/ui-kit/button';

interface IRecommendationLinkButtonProps {
  link: string;
  disabled?: boolean;
  onClick?: VoidFunction;
}

const RecommendationLinkButton: FC<IRecommendationLinkButtonProps & PropsWithChildren> = ({
  link,
  disabled,
  onClick,
  children,
}) => {
  return (
    <Box
      component={Link}
      sx={{ pointerEvents: disabled ? 'none' : 'all', textDecoration: 'none' }}
      className="recommendation-link-button"
      target="_blank"
      to={{
        pathname: link,
      }}
      onClick={onClick}
    >
      <Button iconRight={<IconArrowDiagonalRightUp />} sx={{ width: '100%' }}>
        {children}
      </Button>
    </Box>
  );
};

export default RecommendationLinkButton;
