import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const entityNameContainer: SxStyles = {
  display: 'flex',
  gap: '8px',

  svg: {
    width: '20px',
    height: '20px',
  },
};

export const actionContainer: SxStyles = {
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
};

export const actionChangeContainer: SxStyles = {
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: '8px',

  svg: {
    width: '20px',
    height: '20px',
    color: NeutralColors.light_neutrals_600,
  },
};

export const strikedText: SxStyles = {
  textDecorationLine: 'line-through',
};

export const grayColor: SxStyles = {
  color: NeutralColors.light_neutrals_600,
};
