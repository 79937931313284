import { FC } from 'react';

import { Box } from '@mui/material';

import { ILocalVuzSpeciality, IVuzInfo } from '../../types';
import { vuzCard } from './styles';
import VuzCardHeader from './VuzCardHeader';
import VuzCardList from './VuzCardList';

export interface IVuzCardProps {
  vuzInfo: IVuzInfo;
  specialities: ILocalVuzSpeciality[];
}

const VuzCard: FC<IVuzCardProps> = ({ vuzInfo, specialities }) => {
  const isOpen = specialities.length > 0;
  return (
    <Box sx={vuzCard} className="vuz-card">
      <VuzCardHeader isOpen={isOpen} vuzInfo={vuzInfo} />
      <VuzCardList specialities={specialities} />
    </Box>
  );
};

export default VuzCard;
