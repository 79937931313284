// TODO: вынести в общие types, const
export type DiagnosticLevels = 'belowBase' | 'base' | 'overHigh' | 'high';
export const diagnosticLevelsNames = ['Высокий', 'Повышенный', 'Базовый', 'Ниже базового'];

export interface IDiagnosticChartItem {
  value?: number;
  levelType?: DiagnosticLevels;
  interval: string;
}

export interface IDiagnosticChartData {
  name: string;
  values: IDiagnosticChartItem[];
}
