import { FC, useContext } from 'react';
import { NavLink, useLocation, useRouteMatch } from 'react-router-dom';

import { Grid, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import clsx from 'clsx';
import SettingsSwitch from 'components/common/SettingsSwitch';
import { useAppSelector, useDataLoad } from 'hooks';

import {
  SectionCodeColors,
  SectionCodes,
  SectionCodeVisibilitySettings,
  sectionRoutes,
  SectionTypeCodes,
} from '../../../const';
import { PortfolioSettingsContext } from '../../../context';
import { localVisibilitySettingsSelector, settingsParentSectionsSelector } from '../../../selectors';
import { getDataLoadRequests, getSectionRouteSvgIcon } from '../../../utils';
import { findTargetVisibilitySetting } from '../../../utils/localVisibilitySettingsService';

import './index.scss';

const getSectionRoute = (sectionCode: number) => {
  switch (sectionCode) {
    case SectionCodes.profile:
      return sectionRoutes.PROFILE;
    case SectionCodes.study:
      return sectionRoutes.STUDY;
    case SectionCodes.science:
      return sectionRoutes.SCIENCE;
    case SectionCodes.sport:
      return sectionRoutes.SPORT;
    case SectionCodes.creation:
      return sectionRoutes.CREATION;
    case SectionCodes.culture:
      return sectionRoutes.CULTURE;
    case SectionCodes.civil:
      return sectionRoutes.CIVIL;
    case SectionCodes.profession:
      return sectionRoutes.PROFESSION;
    case SectionCodes.recommendation:
      return sectionRoutes.RECOMMENDATION;
  }
};

const PortfolioSectionVisibility: FC = () => {
  const localVisibilitySettings = useAppSelector(localVisibilitySettingsSelector);
  const settingParentSections = useAppSelector(settingsParentSectionsSelector);

  const { setSectionVisibility } = useContext(PortfolioSettingsContext);

  useDataLoad({ shouldLoad: true, requests: getDataLoadRequests().settingSectionsRef });

  const match = useRouteMatch();
  const location = useLocation();

  const visiblePortfolioSections = settingParentSections.content
    .map((section) => {
      const { value, sectionRefCode } = section;

      const sectionTypeCode = SectionTypeCodes[sectionRefCode];

      const setting = findTargetVisibilitySetting(localVisibilitySettings, [sectionTypeCode]);

      return {
        name: value,
        code: sectionRefCode,
        isAdminVisible: setting?.isAdminVisible ?? false,
        isVisible: setting?.computedVisibility ?? false,
      };
    })
    .filter((section) => section.isAdminVisible);

  const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>, sectionId: number) => {
    const { sectionTypeCode } = SectionCodeVisibilitySettings[sectionId];
    setSectionVisibility(localVisibilitySettings, sectionTypeCode, event.target.checked);
  };

  return (
    <div className="section-visibility">
      <List component="ul">
        {visiblePortfolioSections.map((section) => {
          const { name, code, isVisible } = section;

          const Icon = getSectionRouteSvgIcon(code);
          const { activeBackground } = SectionCodeColors[code];
          const { activeIconBackground } = SectionCodeColors[code];
          const route = match.url + getSectionRoute(code);
          const isActiveRoute = location.pathname === route;

          const itemBackground = isActiveRoute ? activeBackground : undefined;
          const iconOuterFill = isActiveRoute ? activeIconBackground : undefined;

          return (
            <ListItem
              key={code}
              component="li"
              style={{ backgroundColor: itemBackground }}
              className={clsx('section-visibility__item', { 'section-visibility__item--active': isActiveRoute })}
            >
              <Grid container direction="row" alignItems="center">
                <ListItemIcon>
                  <Icon outerFill={iconOuterFill} />
                </ListItemIcon>
                <ListItemText>
                  <NavLink
                    to={route}
                    className="section-visibility__route-link"
                    activeClassName="section-visibility__route-link--active"
                  >
                    {name}
                  </NavLink>
                </ListItemText>
              </Grid>
              <SettingsSwitch onChange={(e) => handleSwitch(e, section.code)} checked={isVisible} />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

export default PortfolioSectionVisibility;
