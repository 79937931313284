import { SxStyles } from 'types';

export const root: SxStyles = (theme) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  padding: '24px',

  [theme.breakpoints.down('commonSm')]: {
    paddingInline: '40px',
  },
});
