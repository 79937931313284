/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useContext } from 'react';

import { connect } from 'react-redux';

import { IconButton, useMediaQuery } from '@mui/material';
import { IInterestEntity } from '../../../../../types';
import { IconCornerRight, IconDelete, IconEditOutline } from '../../../../../icons';
import { IRootState } from '../../../../../reducers';
import { getInterestCategoryLabel } from '../../../../../utils';
import { PortfolioSettingsContext } from 'context';
import { useUserFunctionality } from 'hooks';

import './index.scss';

interface IInterestyDataEntityProps {
  linkMode: boolean | undefined;
  interest: IInterestEntity;
  onEdit?: (interestId: number) => void;
  onDelete?: (interestId: number) => void;
}

const InterestDataEntity: React.FC<IInterestyDataEntityProps> = ({
  linkMode,
  interest,
  onEdit,
  onDelete
}) => {
  const { isStudent } = useUserFunctionality();
  const { isSettingsMode } = useContext(PortfolioSettingsContext);
  const isMobile = useMediaQuery('(max-width: 766px)', { noSsr: true });

  const {
    id,
    name,
    image,
    mobileImage,
    overlayColor,
    actionKinds,
    subinterests,
    headCode
  } = interest;

  const frontSideShadow = `0px 1px 2px rgba(0, 0, 0, 0.1), inset 0px 6px 0px ${overlayColor}`;
  const backSideShadow = `0px 1px 2px rgba(0, 0, 0, 0.1), inset 0px 72px 0px ${overlayColor}`;
  const entityBackground = `url(${isMobile && mobileImage ? mobileImage : image})`;

  const ATTRIBUTES_LENGTH_LIMIT = 3;
  const notShownAttributesLength = actionKinds.length > ATTRIBUTES_LENGTH_LIMIT ? actionKinds.length - ATTRIBUTES_LENGTH_LIMIT : 0;

  const isEditAvailable =
    isStudent &&
    (interest.editFormData?.actions && interest.editFormData.actions.length > 1) ||
    (interest.editFormData?.categories && interest.editFormData.categories.length > 0);

  const categoryLabel = getInterestCategoryLabel(headCode);

  const handleEditInterest = () => {
    if (!id) return;
    onEdit?.(id);
  };

  const handleDeleteInterest = () => {
    if (!id) return;
    onDelete?.(id);
  };

  return (
    <div className="interest-data-entity">
      <div
        className="card"
      >
        <div className="front" style={{ boxShadow: frontSideShadow, backgroundImage: entityBackground }}>
          <IconCornerRight className="action-icon" />
          <div className="interest-data-entity__name-wrapper">
            <div className="interest-data-entity__name">
              {name}
            </div>
          </div>
          {actionKinds?.length > 0 && (
          <div className="interest-data-entity__attributes-container">
            {actionKinds?.slice(0, ATTRIBUTES_LENGTH_LIMIT)?.map((actionKind) => (
              <div key={actionKind.code} className="interest-data-entity__attribute">{actionKind.value}</div>
            ))}
            {notShownAttributesLength > 0 && (
            <div className="interest-data-entity__attribute">
              ещё +
              {notShownAttributesLength}
            </div>
            )}
          </div>
          )}
        </div>
        <div className="back" style={{backgroundColor: overlayColor}}>
          <div className="back__title" >
            <div className="interest-data-entity__name interest-data-entity__name--back">
              {name}
            </div>
          </div>
          <div className="back__content">
            <div className="interest-data-entity__info-container-wrapper">
              <div className="interest-data-entity__info-container">
                <p className="interest-data-entity__info-title">Действия</p>
                <p className="interest-data-entity__info-text">
                  {actionKinds.map((actionKind) => actionKind.value).join('; ')}
                </p>
              </div>
              {subinterests && subinterests?.length > 0 && (
              <div className="interest-data-entity__info-container">
                <p className="interest-data-entity__info-title">{categoryLabel}</p>
                <p className="interest-data-entity__info-text interest-data-entity__info-text--long">
                  {subinterests.map((subinterest) => subinterest.value).join('; ')}
                </p>
              </div>
              )}
            </div>
            {!linkMode && isStudent && !isSettingsMode && (
              <div className="interest-data-entity__actions-container">
                {isEditAvailable && (
                  <>
                    <IconButton
                      className="interest-data-entity__button"
                      disableTouchRipple
                      onClick={handleEditInterest}
                      size="large">
                      <IconEditOutline className="interest-data-entity__icon" />
                    </IconButton>
                    <div className="interest-data-entity__spacer"></div>
                  </>
                )}
                <IconButton
                  className="interest-data-entity__button"
                  disableTouchRipple
                  onClick={handleDeleteInterest}
                  size="large">
                  <IconDelete className="interest-data-entity__icon" />
                </IconButton>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state: IRootState) => ({
    linkMode: state.linkMode.mode,
  })
)(InterestDataEntity);
