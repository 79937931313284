import { SxStyles } from 'types';

export const noDataPlug: SxStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '16px',

  height: '100%',
};
