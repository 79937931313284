import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { MobileDrawerHeader } from 'portfolio3/components/drawers';
import { NeutralColors } from 'portfolio3/styles';
import { Button } from 'portfolio3/ui-kit/button';
import { Drawer } from 'portfolio3/ui-kit/Drawer';

import * as styles from './styles';

interface IQrCodeDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  qrCode: string;
}

const QrCodeDrawer: FC<IQrCodeDrawerProps> = ({ isOpen, onClose, qrCode }) => {
  const headerElement = (
    <MobileDrawerHeader
      title="QR-код для просмотра портфолио"
      overrideTitleColor={NeutralColors.light_neutrals_900}
      overrideBackgroundColor={NeutralColors.light_neutrals_0}
      multilineTitle
    />
  );

  return (
    <Drawer
      open={isOpen}
      isMobile
      anchor="bottom"
      swipeable
      swipeableProps={{
        onClose,
      }}
      header={headerElement}
      onClose={onClose}
    >
      <Box sx={styles.root}>
        <Typography variant="Paragraph LG/Semi Bold" sx={styles.description}>
          Предложи отсканировать этот QR-код тому, с кем ты хочешь поделиться своим портфолио
        </Typography>
        <Box component="img" src={qrCode} alt="qr-code" sx={styles.qrCodeImage} />
        <Button variant="outline" onClick={onClose} sx={styles.closeButton}>
          Закрыть
        </Button>
      </Box>
    </Drawer>
  );
};

export default QrCodeDrawer;
