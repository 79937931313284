import { FC, Fragment, ReactNode, useContext } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Link, Stack, Typography, useMediaQuery } from '@mui/material';
import { PortfolioSettingsContext } from 'context';
import { useAppSelector } from 'hooks';
import { IconBookmarkFilled, IconBookmarkOutline, IconFlagOutline } from 'icons';
import { IVuzInfo } from 'portfolio3/features/vuzRecommendation';
import { useFavoriteVuz } from 'portfolio3/features/vuzRecommendation/hooks';
import { vuzDetailsDrawerActions } from 'portfolio3/features/vuzRecommendation/model/actions';
import { emitYMEvent } from 'portfolio3/features/yandexMetrika';
import { getAccentColor, NeutralColors } from 'portfolio3/styles';
import { commonTheme } from 'portfolio3/styles/theme';
import { Button } from 'portfolio3/ui-kit/button';

import { getInfoBlocksPluralization, InfoBlocks } from '../utils';
import InfoBlock from './InfoBlock';
import * as styles from './styles';
import VuzIcon from './VuzIcon';

interface IVuzCardHeaderProps {
  isOpen?: boolean;
  vuzInfo: IVuzInfo;
}

const VuzCardHeader: FC<IVuzCardHeaderProps> = ({ isOpen, vuzInfo }) => {
  const dispatch = useDispatch();
  const { isViewing: isAdminViewing } = useAppSelector((state) => state.adminViewMode);

  const { isSettingsMode } = useContext(PortfolioSettingsContext);
  const isSmallScreen = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const {
    id,
    name,
    logo,
    address,
    commiteeAddress,
    phone,
    commiteePhone,
    vuzUrl,
    specialitiesNumber,
    suitableSpecialitiesNumber,
    budgetPlaces,
    warDepartment,
    warCenter,
  } = vuzInfo;

  const { isFavoriteVuz, isFavoriteStateLoading, handleToggleVuzFavorite } = useFavoriteVuz(id);

  const handleOpenDetails = () => {
    dispatch(vuzDetailsDrawerActions.setOpen(true));
    dispatch(vuzDetailsDrawerActions.setInfo(vuzInfo));

    emitYMEvent({ type: 'universityDetails' });
  };

  const fullAdministrationAddress = `Ректорат: ${address} ${commiteeAddress}, ${phone}; ${commiteePhone}`;

  const infoBlocks: ReactNode[] = [];

  if (specialitiesNumber) {
    infoBlocks.push(
      <InfoBlock
        leftEntity={specialitiesNumber}
        text={getInfoBlocksPluralization(specialitiesNumber, InfoBlocks.SPECIALITIES_NUMBER)}
        size={140}
      />,
    );
  }

  if (suitableSpecialitiesNumber) {
    infoBlocks.push(
      <InfoBlock
        leftEntity={suitableSpecialitiesNumber}
        text={getInfoBlocksPluralization(suitableSpecialitiesNumber, InfoBlocks.SUITABLE_SPECIALITIES_NUMBER)}
        size={140}
      />,
    );
  }

  if (budgetPlaces) {
    infoBlocks.push(
      <InfoBlock
        leftEntity={budgetPlaces}
        text={getInfoBlocksPluralization(budgetPlaces, InfoBlocks.BUDGET_PLACES)}
        size={156}
      />,
    );
  }

  if (warDepartment || warCenter) {
    infoBlocks.push(
      <InfoBlock
        leftEntity={<IconFlagOutline fill={NeutralColors.light_neutrals_600} />}
        text="Есть военная подготовка"
        size={141}
      />,
    );
  }

  const addToFavoritesButtonElement = (
    <Button
      variant="secondary"
      onlyIcon
      onClick={handleToggleVuzFavorite}
      disabled={isSettingsMode}
      loading={isFavoriteStateLoading}
    >
      {isFavoriteVuz ? <IconBookmarkFilled fill={getAccentColor('yellow', '100')} /> : <IconBookmarkOutline />}
    </Button>
  );

  return (
    <Stack gap="10px" sx={styles.vuzCardHeader(isOpen, isSmallScreen)} className="vuz-card__header header">
      <Box className="header__top" sx={styles.headerTop}>
        {/* основная информация о вузе */}
        <Stack spacing="12px" direction={isSmallScreen ? 'column' : 'row'} className="header__main-info">
          {isSmallScreen ? (
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <VuzIcon logo={logo} />
              {!isAdminViewing && addToFavoritesButtonElement}
            </Box>
          ) : (
            <VuzIcon logo={logo} />
          )}
          <Stack spacing="4px" className="header__text-content">
            <Typography
              className="header__name"
              component="button"
              variant="Paragraph MD/Semi Bold"
              sx={styles.name}
              onClick={handleOpenDetails}
            >
              {name}
            </Typography>
            <Typography
              sx={styles.headerAddress}
              className="header__address"
              component="p"
              variant="Paragraph MD/Regular"
            >
              {fullAdministrationAddress}
            </Typography>
            {vuzUrl && (
              <Link
                sx={styles.headerLink}
                className="header__link"
                target="_blank"
                href={vuzUrl}
                variant="Paragraph MD/Semi Bold"
              >
                {vuzUrl}
              </Link>
            )}
          </Stack>
        </Stack>
        {/* действия */}
        {!isSmallScreen && (
          <Box className="header__actions" sx={styles.actions}>
            {!isAdminViewing && addToFavoritesButtonElement}
            <Button variant="secondary" onClick={handleOpenDetails} disabled={isSettingsMode}>
              Подробнее
            </Button>
          </Box>
        )}
      </Box>

      {/* карточки с информацией */}
      {infoBlocks.length > 0 && (
        <Stack sx={styles.infoBlocks} direction="row" spacing="12px" className="header__info-blocks">
          {infoBlocks.map((infoBlock) => (
            <Fragment key={Math.random()}>{infoBlock}</Fragment>
          ))}
        </Stack>
      )}
    </Stack>
  );
};

export default VuzCardHeader;
