import { FC } from 'react';

import clsx from 'clsx';

import { useMediaQueryWidth } from '../../../../../hooks';
import { featureThanksTeacher, featureThanksTeacherMobile } from '../../../../../images';
import { IFeaturePreviewComponentProps } from '../../types';

import './index.scss';

const ThanksTeacherPreviewComponent: FC<IFeaturePreviewComponentProps> = () => {
  const isMobile = useMediaQueryWidth(600, 'down');

  return (
    <div className={clsx('thanks-teacher', { 'thanks-teacher--mobile': isMobile })}>
      <img
        src={isMobile ? featureThanksTeacherMobile : featureThanksTeacher}
        className="thanks-teacher__img"
        alt="кот держит сердце"
      />
      <div className="thanks-teacher__description">
        <h5 className="thanks-teacher__header">Скажи спасибо учителю</h5>
        {isMobile ? (
          <>
            <p className="thanks-teacher__text">
              Поблагодари своих учителей за помощь в достижении высоких результов на экзаменах и мероприятиях.
            </p>
            <p className="thanks-teacher__text">
              Сказать спасибо можно за результаты ГИА, олимпиады, награды, конкурсы, работы и проекты. Чтобы
              воспользоватеся данной функцией, открой меню с действиями.
            </p>
          </>
        ) : (
          <p className="thanks-teacher__text">
            Поблагодари своих учителей за помощь в достижении высоких результов на экзаменах и мероприятиях. Сказать
            спасибо можно за результаты ГИА, олимпиады, награды, конкурсы, работы и проекты. Чтобы воспользоватеся
            данной функцией, открой меню с действиями.
          </p>
        )}
      </div>
    </div>
  );
};

export default ThanksTeacherPreviewComponent;
