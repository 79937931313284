import { formControlLabelClasses } from '@mui/material';
import { SxStyles } from 'types';

export const toggleLabel = (isChecked: boolean): SxStyles => {
  return {
    [`& .${formControlLabelClasses.label}`]: {
      fontWeight: isChecked ? 600 : 400,
    },
  };
};

export const textareaControl = (isChecked: boolean): SxStyles => {
  return (theme) => ({
    paddingLeft: '32px',
    marginTop: '12px',
    display: isChecked ? 'flex' : 'none',

    [theme.breakpoints.down('commonSm')]: {
      paddingLeft: 0,
    },
  });
};
