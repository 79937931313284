import { FC } from 'react';

import clsx from 'clsx';

import './index.scss';

interface ISubjectTopocsEmptyProps {
  isMobile?: boolean;
}

const SubjectTopicsEmpty: FC<ISubjectTopocsEmptyProps> = ({ isMobile }) => {
  return (
    <div className={clsx('subject-topics-empty', { 'subject-topics-empty--mobile': isMobile })}>
      <p className="subject-topics-empty__description">
        Данных по выбранному предмету пока нет, как только они появятся, информация о них отобразится здесь
      </p>
    </div>
  );
};

export default SubjectTopicsEmpty;
