import { FC } from 'react';

import { Box, SvgIconProps, Typography } from '@mui/material';
import clsx from 'clsx';

import { IBarCircleBase } from '../../types';
import * as styles from './styles';

interface IBarAvatarProps extends IBarCircleBase {
  Emoji: FC<SvgIconProps>;
}

const BarAvatar: FC<IBarAvatarProps> = ({ Emoji, yours }) => {
  const content = yours ? <Typography variant="Headings/H6">ты</Typography> : <Emoji sx={styles.BarAvatarIcon} />;
  return (
    <Box sx={styles.barAvatar} className={clsx('BarAvatar', yours && 'active')}>
      {content}
    </Box>
  );
};

export default BarAvatar;
