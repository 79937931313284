import { FC, ReactNode, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { IDictionaryItem } from 'api/types';
import {
  DIAGNOSTIC_LEVEL_TYPE_ALL_CODE,
  DIAGNOSTIC_SUBJECT_ALL_CODE,
  DiagnosticTypeCode,
  IndependentDiagnosticViewTypeCode,
  SelfDiagnosticPeriodTypeCode,
  studyNavigation,
} from 'const';
import { ALL_SUBJECTS_CODE } from 'containers/redesignStudent/Diagnostic/const';
import { useBrightTheme } from 'hooks';
import { widgetIllustrations } from 'images';
import { WidgetContainer as Widget } from 'portfolio3/components/common/WidgetContainer';
import { emitYMEvent } from 'portfolio3/features/yandexMetrika';
import { IDiagnosticFilters } from 'types';

import IndependentDiagnosticsFilter from '../components/IndependentDiagnosticsFilter';
import RatingAchievements from '../components/RatingAchievements';
import SelfDiagnosticsFilter from '../components/SelfDiagnosticsFilter';
import DiagnosticContext from '../context/diagnosticContext';
import { DiagnosticTabButton, DiagnosticTabs } from '../DiagnosticTabs';
import { useDiagnosticsWidgetLoader } from '../hooks/useDiagnosticsWidgetLoader';
import IndependentDiagnosticsContainer from './IndependentDiagnosticsContainer';
import SelfDiagnosticsContainer from './SelfDiagnosticsContainer';

const DiagnosticsWidget: FC = () => {
  const isBrightTheme = useBrightTheme();

  const { visibility, data } = useDiagnosticsWidgetLoader();
  const { isSettingsMode, isWidgetVisible, toggleElement, overlayElement } = visibility.mainWidget;
  const {
    isWidgetVisible: isIndependentWidgetVisible,
    toggleElement: independentWidgetToggleElement,
    overlayElement: independentWidgetOverlay,
  } = visibility.independentSubsection;
  const {
    isWidgetVisible: isSelfWidgetVisible,
    toggleElement: selfWidgetToggleElement,
    overlayElement: selfWidgetOverlay,
  } = visibility.selfSubsection;
  const { isWidgetVisible: isIndependentRatingVisible } = visibility.independentRating;

  const {
    independentDiagnostics,
    selfDiagnostics,
    selfDiagnosticSubjects,
    independentDiagnosticAnalyticPeriods,
    independentDiagnosticRatingPeriods,
    generalRating,
  } = data;

  const defaultVisibleType = isIndependentWidgetVisible ? DiagnosticTypeCode.independent : DiagnosticTypeCode.self;

  const [filters, setFilters] = useState<IDiagnosticFilters>({
    type: defaultVisibleType,
    independentViewType: IndependentDiagnosticViewTypeCode.rating,
    independentPeriodCode: independentDiagnosticRatingPeriods[0]?.code,
    independentListLevelTypeCode: DIAGNOSTIC_LEVEL_TYPE_ALL_CODE,
    independentListSubjectCode: DIAGNOSTIC_SUBJECT_ALL_CODE,
    selfPeriodCode: SelfDiagnosticPeriodTypeCode.year,
    // TODO нужно перенести эти константы в const/diagnostics если на них больше нет ссылок
    selfSubjectCode: ALL_SUBJECTS_CODE,
  });

  // определение текущего списка периодов в зависимости от выбранной вкладки
  const independentDiagnosticPeriods: IDictionaryItem[] =
    filters.independentViewType === IndependentDiagnosticViewTypeCode.rating
      ? independentDiagnosticRatingPeriods
      : independentDiagnosticAnalyticPeriods;

  // синхронизация фильтра периода при изменении списка периодов
  useEffect(() => {
    setSingleFilter('independentPeriodCode', independentDiagnosticPeriods[0]?.code);
  }, [independentDiagnosticPeriods]);

  // синхронизация фильтра типа с настройками видимости
  useEffect(() => {
    setSingleFilter('type', isIndependentWidgetVisible ? DiagnosticTypeCode.independent : DiagnosticTypeCode.self);
  }, [isIndependentWidgetVisible]);

  const setSingleFilter = <Key extends keyof IDiagnosticFilters>(key: Key, value: IDiagnosticFilters[Key]) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const handleChangeDiagnosticType = (type: number) => {
    setSingleFilter('type', type);

    if (type !== filters.type) {
      emitYMEvent({ type: 'tabsSwitchingDiagnostics' });
    }
  };

  const currentDiagnosticType = filters.type;

  const tabsElement = (
    <DiagnosticTabs value={currentDiagnosticType} onChange={handleChangeDiagnosticType}>
      {isIndependentWidgetVisible && (
        <DiagnosticTabButton
          value={DiagnosticTypeCode.independent}
          content={
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              Независимые диагностики
              {independentWidgetToggleElement}
            </Box>
          }
        />
      )}
      {isSelfWidgetVisible && (
        <DiagnosticTabButton
          value={DiagnosticTypeCode.self}
          content={
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              Самодиагностики
              {selfWidgetToggleElement}
            </Box>
          }
        />
      )}
    </DiagnosticTabs>
  );

  const isIndependentView = currentDiagnosticType === DiagnosticTypeCode.independent && isIndependentWidgetVisible;
  const isSelfView = currentDiagnosticType === DiagnosticTypeCode.self && isSelfWidgetVisible;
  const isIndependentRatingView =
    isIndependentView && filters.independentViewType === IndependentDiagnosticViewTypeCode.rating;

  const independentHeaderSubtitleElement = isIndependentRatingVisible && (
    <>
      {/* фильтры независимых диагностик */}
      <IndependentDiagnosticsFilter />
      {/* Блок с ачивками */}
      {isIndependentRatingView && <RatingAchievements />}
    </>
  );

  let headerSubtitleElement: ReactNode | null = null;
  if (isIndependentView) headerSubtitleElement = independentHeaderSubtitleElement;
  if (isSelfView) headerSubtitleElement = <SelfDiagnosticsFilter />;

  const titleElement = (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      {tabsElement}
      {isSettingsMode && toggleElement}
    </Box>
  );

  if (!isWidgetVisible) return null;

  return (
    <DiagnosticContext.Provider
      value={{
        filters,
        setSingleFilter,
        selfDiagnostics,
        independentDiagnostics,

        selfDiagnosticSubjects,
        independentDiagnosticPeriods,
        generalRating,
      }}
    >
      <Widget containerData={studyNavigation.diagnostics} withBackground={isBrightTheme}>
        {overlayElement}
        <Widget.Header
          title={titleElement}
          subtitle={headerSubtitleElement}
          backgroundImage={widgetIllustrations.studyDiagnostics}
        />

        {/* Проверка для рендера независимых */}
        {isIndependentView && (
          <Box position="relative">
            {independentWidgetOverlay}
            <IndependentDiagnosticsContainer />
          </Box>
        )}

        {/* Проверка для рендера самодиагностик */}
        {isSelfView && (
          <Box position="relative">
            {selfWidgetOverlay}
            <SelfDiagnosticsContainer />
          </Box>
        )}
      </Widget>
    </DiagnosticContext.Provider>
  );
};

export default DiagnosticsWidget;
