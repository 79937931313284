import { createContext } from 'react';

import { CommonUiKitSize, InputRenderMode } from 'portfolio3/ui-kit/types';

interface IDatePickerContext {
  renderMode: InputRenderMode;
  size: CommonUiKitSize;
}

export const DatePickerContext = createContext({} as IDatePickerContext);
