import { IRootState } from 'reducers';
import { createSelector } from 'reselect';

import { favoriteVuzIdSelector } from '.';
import { filterSpecialityByMinBallExistance, getMappedVuzList } from './utils';

const vuzListFavoriteSelector = createSelector(
  [(state: IRootState) => state.vuzList, (state: IRootState) => favoriteVuzIdSelector(state)],
  (vuzList, favoriteVuzIds) => {
    const favoriteVuzes = vuzList.content.items.filter((vuz) =>
      vuz.id ? favoriteVuzIds?.includes(vuz.id) ?? false : false,
    );

    const filteredVuzList = getMappedVuzList({
      vuzList: favoriteVuzes,
      specialityFilters: [filterSpecialityByMinBallExistance],
    });

    return filteredVuzList;
  },
);

export default vuzListFavoriteSelector;
