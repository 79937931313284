import { useCallback, useEffect, useLayoutEffect, useState } from 'react';

export default (): number => {
  const [currentClientWidth, setCurrentClientWidth] = useState(0);

  const updateCurrentWidth = useCallback(() => {
    setCurrentClientWidth(document.documentElement.clientWidth);
  }, [setCurrentClientWidth]);

  useEffect(() => {
    window.addEventListener('resize', updateCurrentWidth);
    return () => window.removeEventListener('resize', updateCurrentWidth);
    // необходимо выполнить этот эффект только один раз
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    updateCurrentWidth();
    // необходимо выполнить этот эффект только один раз
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return currentClientWidth;
};
