/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useContext, useMemo } from 'react';
import { connect } from 'react-redux';

import clsx from 'clsx';
import {
  List,
  ListItem,
  ListItemText
} from '@mui/material';

import SettingsSwitch from '../../../components/common/SettingsSwitch';
import { IRootState } from '../../../reducers';
import { IPortfolioNavigation, SectionCodeVisibilitySettings } from '../../../const';
import { CurrentPortfolioSectionContext, PortfolioSettingsContext } from '../../../context';
import { localVisibilitySettingsSelector } from '../../../selectors';
import { IViewVisibilitySetting } from '../../../types';
import { findTargetVisibilitySetting } from '../../../utils/localVisibilitySettingsService';
import { widgetIcons } from 'icons';

import './index.scss';

interface ISubsectionViisibilityProps {
  parentSectionCode: number;
  portfolioNavigation: IPortfolioNavigation[];
  localVisibilitySettings: IViewVisibilitySetting[];
}

const PortfolioSubsectionVisibility: React.FC<ISubsectionViisibilityProps> = ({
  parentSectionCode,
  portfolioNavigation,
  localVisibilitySettings
}) => {
  const { isContainerScrolled } = useContext(CurrentPortfolioSectionContext);
  const { setSubsectionVisibility } = useContext(PortfolioSettingsContext);

  const { sectionTypeCode } = SectionCodeVisibilitySettings[parentSectionCode];

  const filteredNavigation = useMemo(() => {
    return portfolioNavigation
      .filter((navigation) => {
        const sectionSetting = findTargetVisibilitySetting(localVisibilitySettings, [sectionTypeCode, navigation.sectionTypeCode]);
        return sectionSetting?.isAdminVisible;
      }).map((navigation) => {
        const sectionSetting = findTargetVisibilitySetting(localVisibilitySettings, [sectionTypeCode, navigation.sectionTypeCode]);
        return {
          ...navigation,
          isEnabled: sectionSetting?.computedVisibility ?? false
        }
      });
  }, [portfolioNavigation, localVisibilitySettings]);

  const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>, subsectionTypeCode: string) => {
    setSubsectionVisibility(localVisibilitySettings, sectionTypeCode, subsectionTypeCode, event.target.checked);
  };

  return (
    <div
      className={
        clsx('subsection-visibility', { 'subsection-visibility--scrolled': isContainerScrolled })
      }
    >
      <List component="ul">
        {filteredNavigation.map((navigation) => {
          const { label, sectionTypeCode, isEnabled, icon: iconKey, url } = navigation;

          const IconComponent = widgetIcons[iconKey];
          const icon = <IconComponent sx={{ color: '#B0B3C3', width: '24px', height: '24px' }} />;

          // не показываем ссылки в настройках
          if (url) return null;

          return (
            <ListItem key={navigation.id} component="li">
              {icon}
              <ListItemText>{label}</ListItemText>
              <SettingsSwitch
                onChange={(e) => handleSwitch(e, sectionTypeCode)}
                checked={isEnabled}
              />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

export default connect(
  (state: IRootState) => ({
    localVisibilitySettings: localVisibilitySettingsSelector(state),
  })
)(PortfolioSubsectionVisibility);
