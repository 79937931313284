/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { Grid } from '@mui/material';

import VerificationSign from '../../../common/VerificationSign';
import { IModifiedReward } from '../../../../types';

import './index.scss';

interface IPdfRewardBlockProps {
  reward: IModifiedReward;
}

const PdfRewardBlock: React.FC<IPdfRewardBlockProps> = ({
  reward
}) => {
  const {
    name,
    awardDate,
    description,
    image,
    awardType,
    isApproved
  } = reward;

  const getRewardName = () => {
    if (!name) return 'Не выбран';
    if (name?.length > 31) return `${name?.slice(0, 28)}...`;
    return name;
  };

  const getRewardDescription = () => {
    if (!description) return 'Не выбран';
    if (description?.length > 47) return `${description?.slice(0, 44)}...`;
    return description;
  };

  const getRewardType = (type: string) => (type.length > 25 ? `${type.slice(0, 22)}...` : type);

  return (
    <div className="pdf-reward-block pdf-keep">
      {isApproved && (
        <div className="pdf-reward-block__verification">
          <VerificationSign isPdf />
        </div>
      )}
      <Grid
        container
        direction="column"
        alignItems="center"
        className="pdf-reward-block__main-info"
      >
        <Grid item className="reward-image">
          <img alt="reward" src={image} />
        </Grid>
        <h5 className="reward-name">{getRewardName()}</h5>
        <p className="reward-date">
          {awardDate}
        </p>
      </Grid>
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        direction="column"
        className="pdf-reward-block__other-info"
      >
        {awardType && (
          <div className="reward-type">
            <span>{getRewardType(awardType)}</span>
          </div>
        )}
        <div className="reward-description">
          {getRewardDescription()}
        </div>
      </Grid>
    </div>
  );
};

export default PdfRewardBlock;
