/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { useMediaQuery } from '@mui/material';

import { IDiagnosticBestResult, ITotalDiagnostic } from '../../../../api/types';
import { DoughnutColors } from '../../../../const';
import { bestResultImage } from '../../../../images';
import DiagramDoughnut from '../../../common/DiagramDoughnut';
import DoughnutDiagnosticChart from '../../../common/doughnutDiagnosticChart';

import './index.scss';

interface IndependentDiagnocticDoughnutStatsProps {
  totalDiagnostic: ITotalDiagnostic,
  bestResult: IDiagnosticBestResult
}

const IndependentDiagnocticDoughnutStats: React.FC<IndependentDiagnocticDoughnutStatsProps> = ({ totalDiagnostic, bestResult }) => {
  const isMobile = useMediaQuery('(max-width: 600px)', { noSsr: true });
  const markColors = ['#FF8787', '#FFA94D', '#FFD43B', '#A9E34B'];
  const {
    totalLevelBelowBase, totalLevelBase, totalLevelHight, totalLevelOverHight
  } = totalDiagnostic.totalLevelType;
  const markData = {
    incomingData: [totalLevelBelowBase, totalLevelBase, totalLevelOverHight, totalLevelHight].map((value) => Number(value)),
    incomingLabels: ['ниже базового', 'базовый', 'повышенный', 'высокий']
  };

  const bestResultData = {
    lesson: bestResult.bestResultSubject,
    resultImage: bestResultImage
  };

  let valueProgressBar = 0;
  let chartColor = DoughnutColors.RED;
  const normalizedMarkValue = bestResult.bestResultPercent || 0;
  const normalizedMarkBasis = 100;
  const mark = normalizedMarkValue;

  if (normalizedMarkValue) {
    valueProgressBar = normalizedMarkBasis ? Number(normalizedMarkValue) * 100 / normalizedMarkBasis : Number(normalizedMarkValue);
    if (Number(valueProgressBar) >= 26) {
      chartColor = DoughnutColors.YELLOW;
    }
    if (Number(valueProgressBar) >= 75) {
      chartColor = DoughnutColors.GREEN;
    }
  }

  const doughnutData = {
    incomingData: [valueProgressBar, 100 - valueProgressBar],
    incomingLabels: ['1', '2']
  };

  return (
    <div className="self-diagnosis__doughnut-stats doughnut-stats diagnostic-gap">
      <h3 className="self-diagnosis__title self-diagnosis__title--mobile">Выполнение самодиагностик</h3>
      <div className="data-entity-diagnosis completed-diagnostics">
        <div className="doughnut-stats__wrapper">
          <h3 className="doughnut-stats__title">Пройденные независимые диагностики</h3>
          <div className="doughnut-stats__item">
            <div className="doughnut-stats__diagram--circle">
              <DoughnutDiagnosticChart colors={markColors} data={markData} />
            </div>
            <div className="doughnut-stats__block">
              <div className="doughnut-stats__block-item">
                <div className="doughnut-stats__color-tip" style={{ backgroundColor: markColors[0] }} />
                <p className="doughnut-stats__rank">ниже базового</p>
                <p className="doughnut-stats__result">{markData.incomingData[0]}</p>
              </div>
              <div className="doughnut-stats__block-item">
                <div className="doughnut-stats__color-tip" style={{ backgroundColor: markColors[1] }} />
                <p className="doughnut-stats__rank">{isMobile ? 'базовый' : 'базовый уровень'}</p>
                <p className="doughnut-stats__result">{markData.incomingData[1]}</p>
              </div>
              <div className="doughnut-stats__block-item">
                <div className="doughnut-stats__color-tip" style={{ backgroundColor: markColors[2] }} />
                <p className="doughnut-stats__rank">{isMobile ? 'повышенный' : 'повышенный уровень'}</p>
                <p className="doughnut-stats__result">{markData.incomingData[2]}</p>
              </div>
              <div className="doughnut-stats__block-item">
                <div className="doughnut-stats__color-tip" style={{ backgroundColor: markColors[3] }} />
                <p className="doughnut-stats__rank">{isMobile ? 'высокий' : 'высокий уровень'}</p>
                <p className="doughnut-stats__result">{markData.incomingData[3]}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="data-entity-small">
        <div className="data-entity-diagnosis">
          <div className="right-procent-text">
            <h3 className="doughnut-stats__lesson-title">Лучший результат в процентах</h3>
            <span className="exam-doughnut__result diagosis-result">
              {normalizedMarkValue}
              %
            </span>
          </div>
          <div className="exam-doughnut right-procent-diagramm">
            <DiagramDoughnut total={mark !== null ? mark : '-'} incomingData={doughnutData.incomingData} incomingLabels={doughnutData.incomingLabels} color={chartColor} />
          </div>
        </div>
        <div
          className="data-entity-diagnosis data-entity-diagnosis-result "
          style={{ backgroundImage: !isMobile ? `url(${bestResultData.resultImage})` : undefined }}
        >
          <div className="data-entity-back">
            <h3 className="doughnut-stats__lesson-title">Лучший результат по предмету</h3>
            <span className="doughnut-stats__lesson">{bestResultData.lesson}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndependentDiagnocticDoughnutStats;
