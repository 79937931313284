/* eslint-disable prettier/prettier */
/* eslint-disable */
import { copyObjectDeep } from './common';
import { SectionTypeCodeVisibilitySettings } from '../const/visibility';
import { IViewVisibilitySetting } from '../types';

interface IReverseSetting {
  childs: IViewVisibilitySetting[];
  parent: IViewVisibilitySetting | null;
}

export const findTargetVisibilitySetting = (
  settings: IViewVisibilitySetting[] | undefined,
  typeCodes: string[]
): IViewVisibilitySetting | undefined => {
  const typeCodesCopy = [...typeCodes];
  const currentTypeCode = typeCodesCopy.shift();

  const setting = settings?.find((setting) => setting.sectionTypeCode === currentTypeCode);

  if (typeCodesCopy.length === 0 || !setting?.subSections) return setting;
  return findTargetVisibilitySetting(setting.subSections, typeCodesCopy);
};

const hasVisibleSubsections = (subsections?: IViewVisibilitySetting[]) => subsections?.some((subsection) => subsection.computedVisibility);

const getReverseSettingsMap = (
  setting: IViewVisibilitySetting,
  parentSetting: IViewVisibilitySetting | null,
  nestLevel: number
) => {
  const mapSetting = (
    setting: IViewVisibilitySetting,
    parentSetting: IViewVisibilitySetting | null,
    nestLevel: number,
    map: Map<number, IReverseSetting[]>
  ) => {
    const defaultReverseSetting: IReverseSetting = {
      parent: parentSetting,
      childs: [],
    };

    const reverseSettingArray = map.get(nestLevel) || [];
    const foundSetting = reverseSettingArray.find(
      (reverseSetting) => reverseSetting.parent === parentSetting
    );
    const reverseSetting = foundSetting || defaultReverseSetting;
    reverseSetting.childs.push(setting);

    if (!foundSetting) reverseSettingArray.push(reverseSetting);
    map.set(nestLevel, reverseSettingArray);

    setting.subSections?.forEach((childSetting) =>
      mapSetting(childSetting, setting, nestLevel + 1, map)
    );
  }

  const settingMap: Map<number, IReverseSetting[]> = new Map();
  mapSetting(setting, parentSetting, nestLevel, settingMap);
  return settingMap;
};

class LocalVisibilitySettingsService {
  private _settings: IViewVisibilitySetting[];

  constructor(prevSettings: IViewVisibilitySetting[]) {
    this._settings = copyObjectDeep(prevSettings);
  }

  get result() {
    return this._settings;
  }

  setSettingVisibility(isVisible: boolean, ...nestedTypeCodes: string[]) {
    const sectionTypeCode = nestedTypeCodes[0];
    const rootSetting = this.findSection(sectionTypeCode) || this.createSection(sectionTypeCode);
    const targetSetting = findTargetVisibilitySetting(this._settings, [...nestedTypeCodes]);

    if (!targetSetting) return;

    targetSetting.computedVisibility = isVisible;
    this.syncSettings(rootSetting, targetSetting);
  }

  private syncSettings(
    rootSetting: IViewVisibilitySetting,
    targetSetting: IViewVisibilitySetting
  ) {
    this.syncChildSettings(targetSetting);
    this.syncParentSettings(rootSetting);
  }

  private findSection(sectionTypeCode: string) {
    return this._settings.find((setting) => setting.sectionTypeCode === sectionTypeCode);
  }

  private createSection(sectionTypeCode: string) {
    const sectionSettings = SectionTypeCodeVisibilitySettings[sectionTypeCode];
    const length = this._settings.push(sectionSettings);
    return this._settings[length - 1];
  }

  private syncChildSettings(rootSetting: IViewVisibilitySetting) {
    if (!rootSetting.isAdminVisible) {
      return;
    }

    rootSetting.subSections?.forEach((childSetting) => {
      // прервать обработку текущего узла, если настройка отключена администратором
      if (!childSetting.isAdminVisible) {
        return;
      }

      childSetting.computedVisibility = rootSetting.computedVisibility ?? false;;
      this.syncChildSettings(childSetting);
    });
  };

  private syncParentSettings(rootSetting: IViewVisibilitySetting) {
    const settingMap = getReverseSettingsMap(rootSetting, null, 0);
    const entries = Array.from(settingMap.entries());
    const sortedReverseSettings = entries
      .sort((left, right) => right[0] - left[0])
      .map((entry) => entry[1]);

    sortedReverseSettings.forEach((settings) => {
      settings.forEach((setting) => {
        const isSomeChildSettingsEnabled = hasVisibleSubsections(setting.childs);

        if (!setting.parent?.isAdminVisible) {
          return;
        }

        if (setting.parent) {
          const isVisible = isSomeChildSettingsEnabled ?? false;
          setting.parent.computedVisibility = isVisible;
        }
      });
    });
  }
}

export default LocalVisibilitySettingsService;
