import { catWizard } from 'images';
import { NeutralColors, violetColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root: SxStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  gap: '16px',
  padding: '16px',

  border: '1px solid',
  borderColor: NeutralColors.light_neutrals_300,
  borderRadius: '12px',
  boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.10)',

  backgroundColor: NeutralColors.light_neutrals_0,
};

export const text: SxStyles = {
  alignSelf: 'center',
  color: NeutralColors.light_neutrals_600,
};

export const image: SxStyles = {
  flexShrink: 0,
  width: '60px',
  height: '60px',

  borderRadius: '100%',
  backgroundColor: violetColors[8],
  backgroundImage: `url(${catWizard})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
};
