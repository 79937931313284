import React from 'react';

import './styles.scss';

const CircularDottedLoader: React.FC = () => (
  <div className="circular-dotted-loader-container">
    <div className="circular-dotted-loader">
      {[...Array(16)].map(() => (
        <div key={Math.random()}>
          <div />
        </div>
      ))}
    </div>
  </div>
);

export default CircularDottedLoader;
