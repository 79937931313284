import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconBriefcaseFilled: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M10 5.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V7h-4V5.5ZM7 21h10V7h-1V5.5C16 4.122 14.878 3 13.5 3h-3A2.503 2.503 0 0 0 8 5.5V7H7v14ZM19 7v14c1.654 0 3-1.346 3-3v-8c0-1.654-1.346-3-3-3ZM2 10c0-1.654 1.346-3 3-3v14c-1.654 0-3-1.346-3-3v-8Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconBriefcaseFilled;
