import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconClose: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="m13.65 12 5.008-5.008a1.165 1.165 0 1 0-1.65-1.65L12 10.351 6.992 5.341a1.165 1.165 0 1 0-1.65 1.65L10.35 12 5.342 17.01a1.165 1.165 0 1 0 1.65 1.65L12 13.648l5.008 5.01a1.163 1.163 0 0 0 1.65 0 1.166 1.166 0 0 0 0-1.65L13.65 12Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconClose;
