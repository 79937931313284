import { FC } from 'react';

import { MenuItemProps } from '@mui/material';
import { Checkbox } from 'portfolio3/ui-kit';

import StyledCheckboxMenuItem from './CheckboxMenuItem.styled';

const CheckboxMenuItem: FC<MenuItemProps> = (props) => {
  const { children, ...itemProps } = props;

  return (
    <StyledCheckboxMenuItem {...itemProps}>
      <Checkbox size="small" checked={itemProps.selected} disabled={itemProps.disabled} />
      {children}
    </StyledCheckboxMenuItem>
  );
};

export default CheckboxMenuItem;
