/* eslint-disable prettier/prettier */
/* eslint-disable */

import connectReducers from '../utils/connectReducers';

import {
  GET_SOURCES
} from '../actions';

import {
  FAILURE,
  REQUEST,
  SUCCESS
} from '../actions/utils';

import { ICommonResponse, ISource } from '../api/types';

import genericReducers from './genericReducer';

import { IReducers } from '.';

export type SourcesState = ICommonResponse<ISource>;

const initialState = {} as ICommonResponse<ISource>;

const sources: IReducers<SourcesState> = {
  [GET_SOURCES[REQUEST]]: genericReducers().get.request,
  [GET_SOURCES[SUCCESS]]: genericReducers().get.success,
  [GET_SOURCES[FAILURE]]: genericReducers().get.failure
};

export default connectReducers(initialState, sources);
