export const getAerobaticsAchieventText = (percent: number) => {
  const diagnosticsPercent = Math.floor(percent);
  return `${
    diagnosticsPercent === 100 ? '' : 'Более'
  } ${diagnosticsPercent}% пройденных тобой диагностик ты прошел с результатом "Высокий"! Фантастика!`;
};

export const getSpecialistAchievementText = (subjectNames: string[]) => {
  return `Лучше всего тебе даются ${subjectNames.join(', ')}! Моё уважение!`;
};
