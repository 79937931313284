/* eslint-disable prettier/prettier */
/* eslint-disable */

import {
  useRef,
  useState,
  useLayoutEffect,
  RefObject,
} from 'react';

import useClientWidth from './useClientWidth';
import { IInterestSection } from '../components/redesignStudent/StudentsInterests/types';

interface IUseProgressBarProps {
  interestSections: IInterestSection[];
  currentSection: IInterestSection;
}

type UseProgressBarReturnType = {
  visibleSections: IInterestSection[];
  onStepForward: (newSection: IInterestSection) => void;
  onStepBack: (newSection: IInterestSection) => void;
  desktopRef: RefObject<HTMLElement>;
  mobileRef: RefObject<HTMLElement>;
}

export default ({
  interestSections,
  currentSection
}: IUseProgressBarProps): UseProgressBarReturnType => {
  const [visibleSections, setVisibleSections] = useState(interestSections);

  const desktopRef = useRef<HTMLElement>(null);
  const mobileRef = useRef<HTMLElement>(null);

  const clientWidth = useClientWidth();
  const isMobileProgressBarShown = clientWidth <= 638;

  const getDotWidth = (screenWidth: number) => {
    if (screenWidth < 1366) return 16;
    return 20;
  };

  useLayoutEffect(() => {
    const progressBarRef = isMobileProgressBarShown ? mobileRef : desktopRef;

    if (!progressBarRef.current) return;

    const elements = Array.from(progressBarRef.current.children) as HTMLElement[];
    const navWidth = progressBarRef.current.offsetWidth;

    if (!elements || !elements.length) return;
    if (navWidth === 0) return;

    const dotWidth = getDotWidth(clientWidth);
    const DOT_GAP = 24;
    const ARROWS_WIDTH = 56;

    const { dots: maxDots } = interestSections.reduce((prev, _, index) => {
      const currentWidth = prev.width + dotWidth + (DOT_GAP * +Boolean(index));
      return {
        width: currentWidth,
        dots: currentWidth < navWidth ? prev.dots + 1 : prev.dots
      };
    }, { dots: 0, width: ARROWS_WIDTH });

    const currentSectionIndex = interestSections.findIndex((section) => section.id === currentSection.id);
    const lastVisibleSectionIndex = visibleSections.findIndex((section) => section.id === visibleSections[visibleSections.length - 1].id);

    let minSection = 0;
    let maxSection = maxDots;

    if (currentSectionIndex + 1 > lastVisibleSectionIndex) {
      minSection = currentSectionIndex - maxDots + 1;
      maxSection = currentSectionIndex + 2;
      minSection = minSection < 0 ? 0 : minSection;
    }

    setVisibleSections(interestSections.slice(minSection, maxSection));
  }, [clientWidth, interestSections, desktopRef, mobileRef]);

  // сдвигает видимые секции прогресс бара вправо при выходе текущей секции за границы
  const handleStepForward = (newSection: IInterestSection) => {
    const lastSectionId = interestSections[interestSections.length - 1].id;

    const sectionsCount = interestSections.length;
    const visibleSectionsCount = visibleSections.length;
    const isLastSection = newSection.id === lastSectionId;
    const isLastVisibleSection = newSection.id === visibleSections[visibleSectionsCount - 1].id;

    if (visibleSectionsCount < sectionsCount) {
      if (isLastSection) {
        setVisibleSections(interestSections.slice(sectionsCount - visibleSectionsCount));
      } else if (isLastVisibleSection) {
        const lastVisibleSectionId = visibleSections[visibleSectionsCount - 1].id;
        const lastVisibleSectionIndex = interestSections.findIndex((interestSection) => interestSection.id === lastVisibleSectionId);

        setVisibleSections(interestSections.slice(lastVisibleSectionIndex - visibleSectionsCount + 2, lastVisibleSectionIndex + 2));
      }
    }
  };

  // сдвигает видимые секции прогресс бара влево при выходе текущей секции за границы
  const handleStepBack = (newSection: IInterestSection) => {
    const firstSectionId = interestSections[0].id;

    const sectionsCount = interestSections.length;
    const visibleSectionsCount = visibleSections.length;
    const isFirstSection = newSection.id === firstSectionId;
    const isFirstVisibleSection = newSection.id === visibleSections[0].id;

    if (visibleSectionsCount < sectionsCount) {
      if (isFirstSection) {
        setVisibleSections(interestSections.slice(0, visibleSectionsCount));
      } else if (isFirstVisibleSection) {
        const fistVisibleSectionId = visibleSections[0].id;
        const firstVisibleSectionIndex = interestSections.findIndex((interestSection) => interestSection.id === fistVisibleSectionId);

        setVisibleSections(interestSections.slice(firstVisibleSectionIndex - 1, firstVisibleSectionIndex + visibleSectionsCount - 1));
      }
    }
  };

  return {
    visibleSections,
    onStepForward: handleStepForward,
    onStepBack: handleStepBack,
    desktopRef,
    mobileRef
  };
};
