/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useCallback } from 'react';

import { IconButton, useMediaQuery } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Draggable } from 'react-beautiful-dnd';

import { ISectionCardTheme, TContainerKeys } from '../types';

import './index.scss';

interface ICheckerDragItemProps {
  id: string,
  index: number,
  name: string,
  cardTheme: ISectionCardTheme,
  prefix: TContainerKeys,
  onMoveElement: (sourceIndex: number, sourceId: TContainerKeys, destinationId: TContainerKeys) => void
}

const CheckerDragItem: React.FC<ICheckerDragItemProps> = ({
  id, index, name, cardTheme, prefix, onMoveElement
}) => {
  const isTablet = useMediaQuery('(max-width: 1023px)', { noSsr: true });
  const isMobile = useMediaQuery('(max-width: 900px)', { noSsr: true });

  const getDragItemImage = useCallback(() => (isTablet && !isMobile ? cardTheme.mobileSecondaryImage : cardTheme.desktopSecondaryImage), [cardTheme, isMobile, isTablet]);
  const backgroundImage = getDragItemImage();

  const handleMoveElementTop = (containersPrefix: TContainerKeys) => {
    if (containersPrefix === 'insipid') return;
    if (containersPrefix === 'sipid') {
      onMoveElement(index, containersPrefix, 'insipid');
    }
    if (containersPrefix === 'unknown') {
      onMoveElement(index, containersPrefix, 'sipid');
    }
  };

  const handleMoveElementBottom = (containersPrefix: TContainerKeys) => {
    if (containersPrefix === 'unknown') return;
    if (containersPrefix === 'sipid') {
      onMoveElement(index, containersPrefix, 'unknown');
    }
    if (containersPrefix === 'insipid') {
      onMoveElement(index, containersPrefix, 'sipid');
    }
  };

  if (isMobile) {
    return (
      <div
        className="drag-item"
      >
        <div className="drag-item__content" style={{ backgroundImage: `url(${backgroundImage})` }}>
          <h3 className="drag-item__title">{name}</h3>
        </div>
        <div className="drag-item__controls">
          <IconButton
            className="drag-item__mobile-btn"
            disableTouchRipple
            disabled={prefix === 'unknown'}
            onClick={() => handleMoveElementBottom(prefix)}
            aria-label="Вниз"
            size="large">
            <ArrowDownwardIcon className="drag-item__btn-icon" />
          </IconButton>
          <IconButton
            className="drag-item__mobile-btn drag-item__mobile-btn--rotated"
            disableTouchRipple
            disabled={prefix === 'insipid'}
            onClick={() => handleMoveElementTop(prefix)}
            aria-label="Вверх"
            size="large">
            <ArrowDownwardIcon className="drag-item__btn-icon" />
          </IconButton>
        </div>
      </div>
    );
  }

  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <div
          className="drag-item"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{ ...provided.draggableProps.style, backgroundImage: `url(${backgroundImage})` }}
        >
          <h3 className="drag-item__title">{name}</h3>
        </div>
      )}
    </Draggable>
  );
};

export default CheckerDragItem;
