/* eslint-disable prettier/prettier */
/* eslint-disable */

import connectReducers from '../utils/connectReducers';

import {
  SET_SELF_PREPARATION_TYPE
} from '../actions';
import { SelfDiagnosticPeriodTypeCode } from '../const';

export type TSelfPreparationTypeState = {
  type: number
}

export interface ISelfPreparationTypeReducers {
    [key: string]: (state: TSelfPreparationTypeState, action: any) => TSelfPreparationTypeState;
}

const initialState = {
  type: SelfDiagnosticPeriodTypeCode.year
};

const selfPreparationType: ISelfPreparationTypeReducers = {
  [SET_SELF_PREPARATION_TYPE]: (state, { payload: type }: { payload: number }) => ({
    type
  })
};

export default connectReducers(initialState, selfPreparationType);
