import { SxProps } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';

export const styles: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  py: 3,

  backgroundColor: NeutralColors.light_neutrals_0,
};
