import { styled } from '@mui/material';
import { getAccentColor, NeutralColors } from 'portfolio3/styles';

import { IStyledButtonProps } from '../../types';

const StyledRatingTab = styled('button')<IStyledButtonProps>(({ checked, theme }) => {
  const accentColor = theme.palette.accentColor;
  const backgroundColor = checked ? getAccentColor(accentColor, '8') : 'transparent';
  const borderWidth = '6';

  const color = checked ? getAccentColor(accentColor, '100') : NeutralColors.light_neutrals_900;
  return {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    gap: '12px',
    width: '100%',

    paddingBlock: '6px',
    paddingInlineEnd: '13px',

    border: 'none',
    borderTop: checked ? `${borderWidth}px solid ${color}` : `${borderWidth}px solid transparent`,
    outline: 'none',
    backgroundColor,

    cursor: checked ? 'default' : 'pointer',

    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: `-${borderWidth}px`,
      width: '1px',
      height: `calc(100% + ${borderWidth}px)`,
      backgroundColor: NeutralColors.light_neutrals_300,
    },

    '&:hover': {
      backgroundColor: getAccentColor(accentColor, '8'),
    },

    '& .MuiTypography-root': {
      color,
    },

    '&.rating-button--disabled': {
      backgroundColor: 'transparent',
      color: NeutralColors.light_neutrals_500,
    },

    '&.rating-button--disabled.rating-button--checked': {
      backgroundColor: NeutralColors.light_neutrals_400,
      color: NeutralColors.light_neutrals_0,
    },

    '& .rating-tab__icon': {
      width: 52,
    },

    '& .rating-tab__crown-wrapper': {
      position: 'absolute',
      top: 0,
      right: '13px',
    },

    '& .rating-tab__crown': {
      width: 32,
      height: 32,
    },

    [theme.breakpoints.down('commonMd')]: {
      '& .rating-tab__icon-wrapper': {
        width: 44,
        height: 44,
      },

      '& .rating-tab__icon': {
        width: 44,
      },

      '& .rating-tab__crown-wrapper': {
        right: '11px',
      },

      '& .rating-tab__crown': {
        width: 26,
        height: 26,
      },
    },

    [theme.breakpoints.down('commonSm')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: 0,

      '& .rating-tab__icon-wrapper': {
        width: 24,
        height: 24,
      },

      '& .rating-tab__icon': {
        width: 24,
      },

      '& .rating-tab__crown-wrapper': {
        right: '6px',
      },

      '& .rating-tab__crown': {
        width: 18,
        height: 18,
      },
    },
  };
});

export default StyledRatingTab;
