import { FC, useContext } from 'react';

import { SectionCodes } from 'const';

import {
  CivilSecondaryBlock,
  CreationSecondaryBlock,
  CultureSecondaryBlock,
  ProfessionSecondaryBlock,
  ScienceSecondaryBlock,
  SportSecondaryBlock,
  StudySecondaryBlock,
} from '../../categories/secondary';
import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';

const SecondaryBlocks: FC = () => {
  const { formData } = useContext(LocalDataEntryFormContext);

  const categoryCode = formData.categoryCode;

  return (
    <>
      {categoryCode === SectionCodes.study && <StudySecondaryBlock />}
      {categoryCode === SectionCodes.science && <ScienceSecondaryBlock />}
      {categoryCode === SectionCodes.sport && <SportSecondaryBlock />}
      {categoryCode === SectionCodes.creation && <CreationSecondaryBlock />}
      {categoryCode === SectionCodes.culture && <CultureSecondaryBlock />}
      {categoryCode === SectionCodes.civil && <CivilSecondaryBlock />}
      {categoryCode === SectionCodes.profession && <ProfessionSecondaryBlock />}
    </>
  );
};

export default SecondaryBlocks;
