import { FC } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { spoPlaceholders } from 'images';
import { SxStyles } from 'types';

const imagStyles: SxStyles = (theme) => {
  return {
    width: '287px',
    height: '100%',

    [theme.breakpoints.down('commonSm')]: {
      width: '200px',
    },
  };
};

interface IDataNotFoundSpoProps {
  title: string;
}

const DataNotFoundSpo: FC<IDataNotFoundSpoProps> = ({ title }) => {
  return (
    <Stack gap="12px" alignItems="center" paddingBottom="16px">
      <Box component="img" src={spoPlaceholders.laboratoryAssistant} alt="Нет данных" sx={imagStyles} />
      <Typography variant="Headings/H6" textAlign="center">
        {title}
      </Typography>
    </Stack>
  );
};

export default DataNotFoundSpo;
