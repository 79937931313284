import { ReactNode } from 'react';
import { toast as toastifyToast, ToastOptions } from 'react-toastify';

import { IconAlertTriangleFilled, IconCheckmarkCircleFilled } from 'icons';
import { getAccentColor } from 'portfolio3/styles';
import { IRootState } from 'reducers';
import store from 'store';
import { getUserFunctionality } from 'utils';

import EmployeeToastContent from './EmployeeToastContainer/ToastContent';
import { ToastTypeKeys } from './types';

export const toast = {
  success: (content: ReactNode, options?: ToastOptions) => {
    const { computedContent, computedContainerId } = getToastProps(
      content,
      <IconCheckmarkCircleFilled />,
      getAccentColor('green', '100'),
    );

    toastifyToast.success(computedContent, { ...options, containerId: computedContainerId });
  },
  error: (content: ReactNode, options?: ToastOptions) => {
    const { computedContent, computedContainerId } = getToastProps(
      content,
      <IconAlertTriangleFilled />,
      getAccentColor('red', '100'),
    );

    toastifyToast.error(computedContent, { ...options, containerId: computedContainerId });
  },
};

function getToastProps(content: ReactNode, employeeIcon: ReactNode, employeeColor: string) {
  const globalRole =
    (store.getState() as IRootState).currentUser.data?.currentGlobalRole?.globalRoleTag?.globalRoleTagCode ?? '';
  const { isParent, isStudent } = getUserFunctionality(globalRole);

  const isStudentType = isParent || isStudent;
  const isEmployeeToast = !isStudentType;

  const computedContent = isEmployeeToast ? getEmployeeContentWrapper(content, employeeIcon, employeeColor) : content;
  const computedContainerId = isEmployeeToast ? ToastTypeKeys.EMPLOYEE : ToastTypeKeys.STUDENT;

  return {
    computedContent,
    computedContainerId,
  };
}

function getEmployeeContentWrapper(content: ReactNode, icon: ReactNode, color: string) {
  return (
    <EmployeeToastContent icon={icon} color={color}>
      {content}
    </EmployeeToastContent>
  );
}
