import { GET_USER_CONTEXT } from '../actions';
import { FAILURE, SUCCESS } from '../actions/utils';
import { IResponseError } from '../api/types';
import connectReducers from '../utils/connectReducers';

export interface IAuthState {
  authenticated: boolean;
  error?: IResponseError;
}

export interface IAuthReducers {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: (state: IAuthState, action: any) => IAuthState;
}

const initialState: IAuthState = {
  authenticated: false,
  error: undefined,
};

const auth: IAuthReducers = {
  [GET_USER_CONTEXT[SUCCESS]]: (state) => ({
    ...state,
    authenticated: true,
  }),
  [GET_USER_CONTEXT[FAILURE]]: (state, action) => ({
    ...state,
    error: action.payload,
  }),
};

export default connectReducers(initialState, auth);
