/* eslint-disable prettier/prettier */
/* eslint-disable */

import { BaseInputLabel, FormControl } from 'portfolio3/ui-kit';
import { Select } from 'portfolio3/ui-kit/selects';
import { IController } from 'portfolio3/ui-kit/types';
import React, { useContext, useEffect, useMemo } from 'react';

import { IDictionaryItem } from '../../../../api/types';
import { LocalDataEntryFormContext } from '../context/localDataEntryFormContext';

interface IGiaBlockProps {
  typeLabel: string;
  typeOptions: IDictionaryItem[];
}

const GiaBlock: React.FC<IGiaBlockProps> = ({
  typeLabel,
  typeOptions,
}) => {
  const { inputRenderMode, inputSize, formData, onChangeFormData } = useContext(LocalDataEntryFormContext);

  useEffect(() => {
    if (typeOptions.length === 1) {
      onChangeFormData({
        ...formData,
        typeCode: typeOptions[0].code
      });
    }
  }, [typeOptions]);

  const typeController: IController<string | undefined> = useMemo(() => ({
    handleChange: (type) => {
      onChangeFormData((prevstate) => ({
        ...prevstate,
        typeCode: Number(type),
        name: undefined
      }));
    }
  }), [formData.id]);

  const isTypeSelectDisabled = typeOptions.length === 1;

  return (
    <>
      <FormControl
        renderMode={inputRenderMode}
        inputSize={inputSize}
        disabled={isTypeSelectDisabled}
        label={<BaseInputLabel>{typeLabel}</BaseInputLabel>}
        control={
          <Select
            inputRenderMode={inputRenderMode}
            inputSize={inputSize}
            placeholder="Выберите тип мероприятия..."
            value={formData.typeCode}
            options={typeOptions}
            controller={typeController}
            strictMaxWidth
          />
        }
      />
    </>
  );
};

export default GiaBlock;
