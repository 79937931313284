import { FC } from 'react';

import { Box } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

const styles: SxStyles = {
  position: 'absolute',
  top: 0,
  right: '32px',

  display: 'flex',
  padding: '12px',

  border: '1px solid',
  borderTop: 0,
  borderRadius: '0 0 12px 12px',
  borderColor: NeutralColors.night_neutrals_700,
  backgroundColor: NeutralColors.night_neutrals_900,
};

const imageStyles: SxStyles = {
  width: '48px',
  height: '48px',
};

interface IAchievementProps {
  image: string;
}

const Achievement: FC<IAchievementProps> = ({ image }) => {
  return (
    <Box className="achievement" sx={styles}>
      <Box component="img" src={image} sx={imageStyles} />
    </Box>
  );
};

export default Achievement;
