import { REQUEST } from 'actions/utils';
import {
  addFavoriteUniversity,
  deleteFavoriteUniversity,
  getEgeExamSubjectsRef,
  getFavoriteUniversityList,
  getSpecialitiesRef,
  getVuzList,
  getVuzListCount,
} from 'api';
import { ApiResult } from 'api/impl';
import { IEgeExamSubject, IFavoriteUniversity, IMosRuPaginatedResponse, ISpeciality, IVuz } from 'api/types';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import { GenericGetAction } from 'sagas';
import { isDefined } from 'utils';

import {
  ADD_FAVORITE_VUZ,
  addFavoriteVuzActions,
  DELETE_FAVORITE_VUZ,
  deleteFavoriteVuzActions,
  GET_EGE_EXAM_SUBJECTS_REF,
  GET_FAVORITE_VUZ_LIST,
  GET_SPECIALITIES_REF,
  GET_VUZ_LIST,
  GET_VUZ_LIST_COUNT,
  getEgeExamSubjectsRefActions,
  getFavoriteVuzListActions,
  getSpecialitiesRefActions,
  getVuzListActions,
  getVuzListCountActions,
} from './actions';

export const collection = [
  watchGetVuzList,
  watchGetVuzListCount,
  watchGetEgeExamSubjects,
  watchGetSpecialities,
  watchGetFavoriteVuzList,
  watchAddFavoriteVuz,
  watchDeleteFavoriteVuz,
].map(fork);

function* watchGetVuzList() {
  yield takeLatest(GET_VUZ_LIST[REQUEST], getVuzListSaga);
}
function* watchGetVuzListCount() {
  yield takeLatest(GET_VUZ_LIST_COUNT[REQUEST], getVuzListCountSaga);
}
function* watchGetEgeExamSubjects() {
  yield takeLatest(GET_EGE_EXAM_SUBJECTS_REF[REQUEST], getEgeExamSubjectsRefSaga);
}
function* watchGetSpecialities() {
  yield takeLatest(GET_SPECIALITIES_REF[REQUEST], getSpecialitiesRefSaga);
}
function* watchGetFavoriteVuzList() {
  yield takeLatest(GET_FAVORITE_VUZ_LIST[REQUEST], getFavoriteVuzListSaga);
}
function* watchAddFavoriteVuz() {
  yield takeLatest(ADD_FAVORITE_VUZ[REQUEST], addFavoriteVuzSaga);
}
function* watchDeleteFavoriteVuz() {
  yield takeLatest(DELETE_FAVORITE_VUZ[REQUEST], deleteFavoriteVuzSaga);
}

function* getVuzListSaga({ payload }: GenericGetAction<Parameters<typeof getVuzListActions.request>>) {
  if (!payload) return;

  const [queryParams] = payload;
  const { response, error }: ApiResult<IMosRuPaginatedResponse<IVuz>, unknown> = yield call(getVuzList, queryParams);

  if (response) {
    yield put(getVuzListActions.success(response));
  } else {
    yield put(getVuzListActions.failure(error));
  }
}

function* getVuzListCountSaga({ payload }: GenericGetAction<Parameters<typeof getVuzListActions.request>>) {
  if (!payload) return;

  const [queryParams] = payload;
  const { response, error }: ApiResult<number, unknown> = yield call(getVuzListCount, queryParams);

  if (isDefined(response)) {
    yield put(getVuzListCountActions.success(response));
  } else {
    yield put(getVuzListCountActions.failure(error));
  }
}

function* getEgeExamSubjectsRefSaga() {
  const { response, error }: ApiResult<IEgeExamSubject[], unknown> = yield call(getEgeExamSubjectsRef);

  if (response) {
    yield put(getEgeExamSubjectsRefActions.success(response));
  } else {
    yield put(getEgeExamSubjectsRefActions.failure(error));
  }
}

function* getSpecialitiesRefSaga() {
  const { response, error }: ApiResult<ISpeciality[], unknown> = yield call(getSpecialitiesRef);

  if (response) {
    yield put(getSpecialitiesRefActions.success(response));
  } else {
    yield put(getSpecialitiesRefActions.failure(error));
  }
}

function* getFavoriteVuzListSaga({ payload }: GenericGetAction<Parameters<typeof getFavoriteVuzListActions.request>>) {
  if (!payload) return;

  const [personId] = payload;
  const { response, error }: ApiResult<IFavoriteUniversity[] | null, unknown> = yield call(
    getFavoriteUniversityList,
    personId,
  );

  if (response) {
    yield put(getFavoriteVuzListActions.success(response));
  } else {
    yield put(getFavoriteVuzListActions.failure(error));
  }
}

function* addFavoriteVuzSaga({ payload }: GenericGetAction<Parameters<typeof addFavoriteVuzActions.request>>) {
  if (!payload) return;

  const [personId, vuzId] = payload;
  const { response }: ApiResult<unknown, unknown> = yield call(addFavoriteUniversity, personId, vuzId);

  if (response) {
    yield put(getFavoriteVuzListActions.request(personId));
    yield put(addFavoriteVuzActions.success());
  } else {
    yield put(addFavoriteVuzActions.failure());
  }
}

function* deleteFavoriteVuzSaga({ payload }: GenericGetAction<Parameters<typeof deleteFavoriteVuzActions.request>>) {
  if (!payload) return;

  const [personId, vuzId] = payload;
  const { response }: ApiResult<unknown, unknown> = yield call(deleteFavoriteUniversity, personId, vuzId);

  if (response) {
    yield put(getFavoriteVuzListActions.request(personId));
    yield put(deleteFavoriteVuzActions.success());
  } else {
    yield put(deleteFavoriteVuzActions.failure());
  }
}
