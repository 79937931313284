/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import clsx from 'clsx';

import { Checkbox as CheckboxMaterial, CheckboxProps } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { Colors } from '../../style/variables';

import {
  iconCheckbox,
  iconCheckboxDisabled,
  iconMinus,
  iconMinusDisabled
} from '../../icons';

interface ICheckboxIndeterminate {
  indeterminateCustom?: boolean;
  background?: string;
}

const useStyles = makeStyles({
  checkbox: {
    borderRadius: 4,
    width: 20,
    height: 20,
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  icon: ({ background }: ICheckboxIndeterminate) => ({
    borderRadius: 4,
    width: 20,
    minWidth: 20,
    height: 20,
    boxShadow: 'none',
    border: `1px solid ${background || Colors['control-stroke']}`,
    backgroundColor: Colors.white,
    'input:hover ~ &': {
      border: `1px solid ${background || Colors['blue-primrose']}`
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      border: `1px solid ${Colors['control-stroke']}`,
      background: Colors['disable-input']
    }
  }),
  checkedIcon: ({ background }: ICheckboxIndeterminate) => ({
    border: `1px solid ${background || Colors['blue-primrose']}`,
    backgroundColor: background || Colors['blue-primrose'],
    '&:before': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundImage:
        `url("${iconCheckbox}")`,
      backgroundPosition: 2,
      backgroundRepeat: 'no-repeat',
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: background || Colors['blue-primrose']
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      border: `1px solid ${Colors['control-stroke']}`,
      background: Colors['disable-input']
    },
    'input:disabled ~ &:before': {
      backgroundImage:
        `url("${iconCheckboxDisabled}")`
    }
  }),
  indeterminate: ({ background }: ICheckboxIndeterminate) => ({
    border: `1px solid ${background || Colors['blue-primrose']}`,
    backgroundColor: background || Colors['blue-primrose'],
    '&:before': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundImage:
        `url("${iconMinus}")`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: background || Colors['blue-primrose']
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      border: `1px solid ${Colors['control-stroke']}`,
      background: Colors['disable-input']
    },
    'input:disabled ~ &:before': {
      backgroundImage:
        `url("${iconMinusDisabled}")`
    }
  })
});

const Checkbox: React.FC<CheckboxProps & ICheckboxIndeterminate> = (props) => {
  const { background } = props;
  const classes = useStyles({ background });

  return (
    <CheckboxMaterial
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, props?.indeterminateCustom ? classes.indeterminate : classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      classes={{
        root: classes.checkbox
      }}
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      {...props}
    />
  );
};

export default Checkbox;
