import { isDefined } from 'utils';

export const enum InfoBlocks {
  SPECIALITIES_NUMBER = 'specialitiesNumber',
  SUITABLE_SPECIALITIES_NUMBER = 'suitableSpecialitiesNumber',
  BUDGET_PLACES = 'budgetPlaces',
}

const infoBlockNames: Record<InfoBlocks, string[]> = {
  specialitiesNumber: ['Направлений подготовки', 'Направление подготовки', 'Направления подготовки'],
  suitableSpecialitiesNumber: ['Направлений подходит тебе', 'Направление подходит тебе', 'Направления подходят тебе'],
  budgetPlaces: ['Бюджетных мест', 'Бюджетное место', 'Бюджетных места'],
};

export const getInfoBlocksPluralization = (number: number | undefined, type: InfoBlocks) => {
  if (!isDefined(number)) return '';

  const lastNumber = Number(String(number)[String(number).length - 1]);

  if ([11, 12, 13, 14, 15, 16, 17, 18, 19].includes(number) || [0, 5, 6, 7, 8, 9].includes(lastNumber)) {
    return infoBlockNames[type][0];
  }

  if (lastNumber === 1) return infoBlockNames[type][1];
  if ([2, 3, 4].includes(lastNumber)) return infoBlockNames[type][2];

  return '';
};
