import { indigoColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root: SxStyles = {
  display: 'flex',
  gap: '12px',

  padding: '16px',

  border: '1px solid',
  borderColor: indigoColors[24],
  borderRadius: '8px',
  backgroundColor: indigoColors[8],

  svg: {
    width: '28px',
    height: '28px',
    color: indigoColors[100],
  },
};
