import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const listDivider: SxStyles = {
  height: '1px',
  marginTop: 0,
  backgroundColor: NeutralColors.light_neutrals_300,
};

export const showMoreButtonContainer: SxStyles = {
  padding: '12px',
  paddingBottom: '24px',
};
