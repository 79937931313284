/* eslint-disable max-len */
import { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const Lion: FC<SvgIconProps> = (props) => (
  <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M25.85 12.4491L23.55 9.28906C24.18 8.73906 24.57 7.93906 24.57 7.03906C24.57 5.37906 23.23 4.03906 21.57 4.03906C20.5 4.03906 19.56 4.59906 19.03 5.44906C18.09 5.08906 17.07 4.89906 16 4.89906C14.93 4.89906 13.91 5.09906 12.97 5.44906C12.44 4.59906 11.5 4.03906 10.43 4.03906C8.77 4.03906 7.43 5.37906 7.43 7.03906C7.43 7.93906 7.83 8.73906 8.45 9.28906L6.15 12.4491C5.4 13.4791 5 14.7291 5 15.9991C5 19.3391 7.7 22.0391 11.04 22.0391H16H20.96C24.3 22.0391 27 19.3391 27 15.9991C27 14.7291 26.6 13.4791 25.85 12.4491Z"
      fill="url(#paint0_radial_64_89926)"
    />
    <path
      d="M25.85 12.4491L23.55 9.28906C24.18 8.73906 24.57 7.93906 24.57 7.03906C24.57 5.37906 23.23 4.03906 21.57 4.03906C20.5 4.03906 19.56 4.59906 19.03 5.44906C18.09 5.08906 17.07 4.89906 16 4.89906C14.93 4.89906 13.91 5.09906 12.97 5.44906C12.44 4.59906 11.5 4.03906 10.43 4.03906C8.77 4.03906 7.43 5.37906 7.43 7.03906C7.43 7.93906 7.83 8.73906 8.45 9.28906L6.15 12.4491C5.4 13.4791 5 14.7291 5 15.9991C5 19.3391 7.7 22.0391 11.04 22.0391H16H20.96C24.3 22.0391 27 19.3391 27 15.9991C27 14.7291 26.6 13.4791 25.85 12.4491Z"
      fill="url(#paint1_radial_64_89926)"
    />
    <path
      d="M25.85 12.4491L23.55 9.28906C24.18 8.73906 24.57 7.93906 24.57 7.03906C24.57 5.37906 23.23 4.03906 21.57 4.03906C20.5 4.03906 19.56 4.59906 19.03 5.44906C18.09 5.08906 17.07 4.89906 16 4.89906C14.93 4.89906 13.91 5.09906 12.97 5.44906C12.44 4.59906 11.5 4.03906 10.43 4.03906C8.77 4.03906 7.43 5.37906 7.43 7.03906C7.43 7.93906 7.83 8.73906 8.45 9.28906L6.15 12.4491C5.4 13.4791 5 14.7291 5 15.9991C5 19.3391 7.7 22.0391 11.04 22.0391H16H20.96C24.3 22.0391 27 19.3391 27 15.9991C27 14.7291 26.6 13.4791 25.85 12.4491Z"
      fill="url(#paint2_radial_64_89926)"
      fillOpacity="0.4"
    />
    <path
      d="M25.85 12.4491L23.55 9.28906C24.18 8.73906 24.57 7.93906 24.57 7.03906C24.57 5.37906 23.23 4.03906 21.57 4.03906C20.5 4.03906 19.56 4.59906 19.03 5.44906C18.09 5.08906 17.07 4.89906 16 4.89906C14.93 4.89906 13.91 5.09906 12.97 5.44906C12.44 4.59906 11.5 4.03906 10.43 4.03906C8.77 4.03906 7.43 5.37906 7.43 7.03906C7.43 7.93906 7.83 8.73906 8.45 9.28906L6.15 12.4491C5.4 13.4791 5 14.7291 5 15.9991C5 19.3391 7.7 22.0391 11.04 22.0391H16H20.96C24.3 22.0391 27 19.3391 27 15.9991C27 14.7291 26.6 13.4791 25.85 12.4491Z"
      fill="url(#paint3_radial_64_89926)"
    />
    <path
      d="M25.85 12.4491L23.55 9.28906C24.18 8.73906 24.57 7.93906 24.57 7.03906C24.57 5.37906 23.23 4.03906 21.57 4.03906C20.5 4.03906 19.56 4.59906 19.03 5.44906C18.09 5.08906 17.07 4.89906 16 4.89906C14.93 4.89906 13.91 5.09906 12.97 5.44906C12.44 4.59906 11.5 4.03906 10.43 4.03906C8.77 4.03906 7.43 5.37906 7.43 7.03906C7.43 7.93906 7.83 8.73906 8.45 9.28906L6.15 12.4491C5.4 13.4791 5 14.7291 5 15.9991C5 19.3391 7.7 22.0391 11.04 22.0391H16H20.96C24.3 22.0391 27 19.3391 27 15.9991C27 14.7291 26.6 13.4791 25.85 12.4491Z"
      fill="url(#paint4_radial_64_89926)"
    />
    <path
      d="M25.85 12.4491L23.55 9.28906C24.18 8.73906 24.57 7.93906 24.57 7.03906C24.57 5.37906 23.23 4.03906 21.57 4.03906C20.5 4.03906 19.56 4.59906 19.03 5.44906C18.09 5.08906 17.07 4.89906 16 4.89906C14.93 4.89906 13.91 5.09906 12.97 5.44906C12.44 4.59906 11.5 4.03906 10.43 4.03906C8.77 4.03906 7.43 5.37906 7.43 7.03906C7.43 7.93906 7.83 8.73906 8.45 9.28906L6.15 12.4491C5.4 13.4791 5 14.7291 5 15.9991C5 19.3391 7.7 22.0391 11.04 22.0391H16H20.96C24.3 22.0391 27 19.3391 27 15.9991C27 14.7291 26.6 13.4791 25.85 12.4491Z"
      fill="url(#paint5_radial_64_89926)"
    />
    <path
      d="M23.5618 9.27868L23.5549 9.26907C22.5489 7.86915 21.3881 6.254 18.8398 5.38509L18.8399 5.37898C18.9036 5.40157 18.967 5.42494 19.03 5.44906C19.56 4.59906 20.5 4.03906 21.57 4.03906C23.23 4.03906 24.57 5.37906 24.57 7.03906C24.57 7.93342 24.1849 8.72902 23.5618 9.27868Z"
      fill="url(#paint6_radial_64_89926)"
    />
    <path
      d="M23.5618 9.27868L23.5549 9.26907C22.5489 7.86915 21.3881 6.254 18.8398 5.38509L18.8399 5.37898C18.9036 5.40157 18.967 5.42494 19.03 5.44906C19.56 4.59906 20.5 4.03906 21.57 4.03906C23.23 4.03906 24.57 5.37906 24.57 7.03906C24.57 7.93342 24.1849 8.72902 23.5618 9.27868Z"
      fill="url(#paint7_radial_64_89926)"
    />
    <path
      d="M8.43427 9.27868L8.44118 9.26907C9.44723 7.86915 10.608 6.254 13.1562 5.38509L13.1561 5.37898C13.0924 5.40157 13.0291 5.42494 12.9661 5.44906C12.4361 4.59906 11.4961 4.03906 10.4261 4.03906C8.76609 4.03906 7.42609 5.37906 7.42609 7.03906C7.42609 7.93342 7.81122 8.72902 8.43427 9.27868Z"
      fill="url(#paint8_radial_64_89926)"
    />
    <path
      d="M9.03993 8.46906C9.12993 8.33906 9.21993 8.21906 9.31993 8.09906V8.08906C10.0599 7.16906 10.9899 6.40906 12.0399 5.85906C11.6799 5.35906 11.0899 5.03906 10.4299 5.03906C9.32993 5.03906 8.42993 5.93906 8.42993 7.03906C8.42993 7.59906 8.65993 8.10906 9.03993 8.46906Z"
      fill="url(#paint9_radial_64_89926)"
    />
    <path
      d="M9.03993 8.46906C9.12993 8.33906 9.21993 8.21906 9.31993 8.09906V8.08906C10.0599 7.16906 10.9899 6.40906 12.0399 5.85906C11.6799 5.35906 11.0899 5.03906 10.4299 5.03906C9.32993 5.03906 8.42993 5.93906 8.42993 7.03906C8.42993 7.59906 8.65993 8.10906 9.03993 8.46906Z"
      fill="url(#paint10_linear_64_89926)"
    />
    <path
      d="M9.03993 8.46906C9.12993 8.33906 9.21993 8.21906 9.31993 8.09906V8.08906C10.0599 7.16906 10.9899 6.40906 12.0399 5.85906C11.6799 5.35906 11.0899 5.03906 10.4299 5.03906C9.32993 5.03906 8.42993 5.93906 8.42993 7.03906C8.42993 7.59906 8.65993 8.10906 9.03993 8.46906Z"
      fill="url(#paint11_radial_64_89926)"
    />
    <path
      d="M22.68 8.09883C22.78 8.22883 22.88 8.34883 22.97 8.47883C23.34 8.11883 23.58 7.60883 23.58 7.04883C23.58 5.94883 22.68 5.04883 21.58 5.04883C20.92 5.04883 20.33 5.36883 19.96 5.86883C21.01 6.40883 21.94 7.17883 22.68 8.09883Z"
      fill="url(#paint12_radial_64_89926)"
    />
    <path
      d="M28.66 12.3691L29.41 12.7391C29.68 12.8791 30 12.6791 30 12.3791V8.44906C30 7.53906 29.64 6.67906 29 6.03906C28.36 5.39906 27.49 5.03906 26.59 5.03906H26L26.59 3.26906C26.79 2.66906 26.34 2.03906 25.7 2.03906H19C18.35 2.03906 17.72 2.24906 17.2 2.63906C16.48 3.17906 16.05 4.00906 16 4.89906H15.99C15.95 4.00906 15.51 3.17906 14.79 2.64906C14.28 2.24906 13.65 2.03906 13 2.03906H6.3C5.66 2.03906 5.21 2.66906 5.41 3.26906L6 5.03906H5.41C4.51 5.03906 3.64 5.39906 3 6.03906C2.36 6.67906 2 7.54906 2 8.44906V12.3791C2 12.6791 2.32 12.8791 2.59 12.7391L3.34 12.3691C3.62 12.2291 3.92 12.3591 3.99 12.6591C4.04 12.8691 3.94 13.0691 3.75 13.1591L2.75 13.6591C2.29 13.8891 2 14.3591 2 14.8791V21.3291C2 24.1191 3.85 26.5691 6.53 27.3391L15.41 29.8791C15.79 29.9891 16.2 29.9891 16.59 29.8791L25.47 27.3391C28.15 26.5691 30 24.1191 30 21.3291V14.8791C30 14.3691 29.71 13.8991 29.25 13.6691L28.25 13.1691C28.07 13.0791 27.97 12.8691 28.01 12.6691C28.08 12.3591 28.39 12.2291 28.66 12.3691ZM27 15.9991C27 19.3391 24.3 22.0391 20.96 22.0391H16H11.04C7.7 22.0391 5 19.3391 5 15.9991C5 14.7191 5.4 13.4791 6.15 12.4491L8.45 9.28906C7.8 8.71906 7.4 7.87906 7.43 6.92906C7.49 5.32906 8.82 4.03906 10.42 4.03906C11.49 4.03906 12.44 4.59906 12.97 5.44906C14.66 7.08906 17.34 7.08906 19.03 5.44906C19.56 4.59906 20.5 4.03906 21.58 4.03906C23.18 4.03906 24.52 5.32906 24.57 6.92906C24.6 7.86906 24.2 8.71906 23.55 9.28906L25.85 12.4491C26.6 13.4791 27 14.7291 27 15.9991Z"
      fill="url(#paint13_linear_64_89926)"
    />
    <path
      d="M28.66 12.3691L29.41 12.7391C29.68 12.8791 30 12.6791 30 12.3791V8.44906C30 7.53906 29.64 6.67906 29 6.03906C28.36 5.39906 27.49 5.03906 26.59 5.03906H26L26.59 3.26906C26.79 2.66906 26.34 2.03906 25.7 2.03906H19C18.35 2.03906 17.72 2.24906 17.2 2.63906C16.48 3.17906 16.05 4.00906 16 4.89906H15.99C15.95 4.00906 15.51 3.17906 14.79 2.64906C14.28 2.24906 13.65 2.03906 13 2.03906H6.3C5.66 2.03906 5.21 2.66906 5.41 3.26906L6 5.03906H5.41C4.51 5.03906 3.64 5.39906 3 6.03906C2.36 6.67906 2 7.54906 2 8.44906V12.3791C2 12.6791 2.32 12.8791 2.59 12.7391L3.34 12.3691C3.62 12.2291 3.92 12.3591 3.99 12.6591C4.04 12.8691 3.94 13.0691 3.75 13.1591L2.75 13.6591C2.29 13.8891 2 14.3591 2 14.8791V21.3291C2 24.1191 3.85 26.5691 6.53 27.3391L15.41 29.8791C15.79 29.9891 16.2 29.9891 16.59 29.8791L25.47 27.3391C28.15 26.5691 30 24.1191 30 21.3291V14.8791C30 14.3691 29.71 13.8991 29.25 13.6691L28.25 13.1691C28.07 13.0791 27.97 12.8691 28.01 12.6691C28.08 12.3591 28.39 12.2291 28.66 12.3691ZM27 15.9991C27 19.3391 24.3 22.0391 20.96 22.0391H16H11.04C7.7 22.0391 5 19.3391 5 15.9991C5 14.7191 5.4 13.4791 6.15 12.4491L8.45 9.28906C7.8 8.71906 7.4 7.87906 7.43 6.92906C7.49 5.32906 8.82 4.03906 10.42 4.03906C11.49 4.03906 12.44 4.59906 12.97 5.44906C14.66 7.08906 17.34 7.08906 19.03 5.44906C19.56 4.59906 20.5 4.03906 21.58 4.03906C23.18 4.03906 24.52 5.32906 24.57 6.92906C24.6 7.86906 24.2 8.71906 23.55 9.28906L25.85 12.4491C26.6 13.4791 27 14.7291 27 15.9991Z"
      fill="url(#paint14_linear_64_89926)"
    />
    <path
      d="M28.66 12.3691L29.41 12.7391C29.68 12.8791 30 12.6791 30 12.3791V8.44906C30 7.53906 29.64 6.67906 29 6.03906C28.36 5.39906 27.49 5.03906 26.59 5.03906H26L26.59 3.26906C26.79 2.66906 26.34 2.03906 25.7 2.03906H19C18.35 2.03906 17.72 2.24906 17.2 2.63906C16.48 3.17906 16.05 4.00906 16 4.89906H15.99C15.95 4.00906 15.51 3.17906 14.79 2.64906C14.28 2.24906 13.65 2.03906 13 2.03906H6.3C5.66 2.03906 5.21 2.66906 5.41 3.26906L6 5.03906H5.41C4.51 5.03906 3.64 5.39906 3 6.03906C2.36 6.67906 2 7.54906 2 8.44906V12.3791C2 12.6791 2.32 12.8791 2.59 12.7391L3.34 12.3691C3.62 12.2291 3.92 12.3591 3.99 12.6591C4.04 12.8691 3.94 13.0691 3.75 13.1591L2.75 13.6591C2.29 13.8891 2 14.3591 2 14.8791V21.3291C2 24.1191 3.85 26.5691 6.53 27.3391L15.41 29.8791C15.79 29.9891 16.2 29.9891 16.59 29.8791L25.47 27.3391C28.15 26.5691 30 24.1191 30 21.3291V14.8791C30 14.3691 29.71 13.8991 29.25 13.6691L28.25 13.1691C28.07 13.0791 27.97 12.8691 28.01 12.6691C28.08 12.3591 28.39 12.2291 28.66 12.3691ZM27 15.9991C27 19.3391 24.3 22.0391 20.96 22.0391H16H11.04C7.7 22.0391 5 19.3391 5 15.9991C5 14.7191 5.4 13.4791 6.15 12.4491L8.45 9.28906C7.8 8.71906 7.4 7.87906 7.43 6.92906C7.49 5.32906 8.82 4.03906 10.42 4.03906C11.49 4.03906 12.44 4.59906 12.97 5.44906C14.66 7.08906 17.34 7.08906 19.03 5.44906C19.56 4.59906 20.5 4.03906 21.58 4.03906C23.18 4.03906 24.52 5.32906 24.57 6.92906C24.6 7.86906 24.2 8.71906 23.55 9.28906L25.85 12.4491C26.6 13.4791 27 14.7291 27 15.9991Z"
      fill="url(#paint15_linear_64_89926)"
    />
    <path
      d="M28.66 12.3691L29.41 12.7391C29.68 12.8791 30 12.6791 30 12.3791V8.44906C30 7.53906 29.64 6.67906 29 6.03906C28.36 5.39906 27.49 5.03906 26.59 5.03906H26L26.59 3.26906C26.79 2.66906 26.34 2.03906 25.7 2.03906H19C18.35 2.03906 17.72 2.24906 17.2 2.63906C16.48 3.17906 16.05 4.00906 16 4.89906H15.99C15.95 4.00906 15.51 3.17906 14.79 2.64906C14.28 2.24906 13.65 2.03906 13 2.03906H6.3C5.66 2.03906 5.21 2.66906 5.41 3.26906L6 5.03906H5.41C4.51 5.03906 3.64 5.39906 3 6.03906C2.36 6.67906 2 7.54906 2 8.44906V12.3791C2 12.6791 2.32 12.8791 2.59 12.7391L3.34 12.3691C3.62 12.2291 3.92 12.3591 3.99 12.6591C4.04 12.8691 3.94 13.0691 3.75 13.1591L2.75 13.6591C2.29 13.8891 2 14.3591 2 14.8791V21.3291C2 24.1191 3.85 26.5691 6.53 27.3391L15.41 29.8791C15.79 29.9891 16.2 29.9891 16.59 29.8791L25.47 27.3391C28.15 26.5691 30 24.1191 30 21.3291V14.8791C30 14.3691 29.71 13.8991 29.25 13.6691L28.25 13.1691C28.07 13.0791 27.97 12.8691 28.01 12.6691C28.08 12.3591 28.39 12.2291 28.66 12.3691ZM27 15.9991C27 19.3391 24.3 22.0391 20.96 22.0391H16H11.04C7.7 22.0391 5 19.3391 5 15.9991C5 14.7191 5.4 13.4791 6.15 12.4491L8.45 9.28906C7.8 8.71906 7.4 7.87906 7.43 6.92906C7.49 5.32906 8.82 4.03906 10.42 4.03906C11.49 4.03906 12.44 4.59906 12.97 5.44906C14.66 7.08906 17.34 7.08906 19.03 5.44906C19.56 4.59906 20.5 4.03906 21.58 4.03906C23.18 4.03906 24.52 5.32906 24.57 6.92906C24.6 7.86906 24.2 8.71906 23.55 9.28906L25.85 12.4491C26.6 13.4791 27 14.7291 27 15.9991Z"
      fill="url(#paint16_radial_64_89926)"
    />
    <path
      d="M28.66 12.3691L29.41 12.7391C29.68 12.8791 30 12.6791 30 12.3791V8.44906C30 7.53906 29.64 6.67906 29 6.03906C28.36 5.39906 27.49 5.03906 26.59 5.03906H26L26.59 3.26906C26.79 2.66906 26.34 2.03906 25.7 2.03906H19C18.35 2.03906 17.72 2.24906 17.2 2.63906C16.48 3.17906 16.05 4.00906 16 4.89906H15.99C15.95 4.00906 15.51 3.17906 14.79 2.64906C14.28 2.24906 13.65 2.03906 13 2.03906H6.3C5.66 2.03906 5.21 2.66906 5.41 3.26906L6 5.03906H5.41C4.51 5.03906 3.64 5.39906 3 6.03906C2.36 6.67906 2 7.54906 2 8.44906V12.3791C2 12.6791 2.32 12.8791 2.59 12.7391L3.34 12.3691C3.62 12.2291 3.92 12.3591 3.99 12.6591C4.04 12.8691 3.94 13.0691 3.75 13.1591L2.75 13.6591C2.29 13.8891 2 14.3591 2 14.8791V21.3291C2 24.1191 3.85 26.5691 6.53 27.3391L15.41 29.8791C15.79 29.9891 16.2 29.9891 16.59 29.8791L25.47 27.3391C28.15 26.5691 30 24.1191 30 21.3291V14.8791C30 14.3691 29.71 13.8991 29.25 13.6691L28.25 13.1691C28.07 13.0791 27.97 12.8691 28.01 12.6691C28.08 12.3591 28.39 12.2291 28.66 12.3691ZM27 15.9991C27 19.3391 24.3 22.0391 20.96 22.0391H16H11.04C7.7 22.0391 5 19.3391 5 15.9991C5 14.7191 5.4 13.4791 6.15 12.4491L8.45 9.28906C7.8 8.71906 7.4 7.87906 7.43 6.92906C7.49 5.32906 8.82 4.03906 10.42 4.03906C11.49 4.03906 12.44 4.59906 12.97 5.44906C14.66 7.08906 17.34 7.08906 19.03 5.44906C19.56 4.59906 20.5 4.03906 21.58 4.03906C23.18 4.03906 24.52 5.32906 24.57 6.92906C24.6 7.86906 24.2 8.71906 23.55 9.28906L25.85 12.4491C26.6 13.4791 27 14.7291 27 15.9991Z"
      fill="url(#paint17_radial_64_89926)"
    />
    <path
      d="M28.66 12.3691L29.41 12.7391C29.68 12.8791 30 12.6791 30 12.3791V8.44906C30 7.53906 29.64 6.67906 29 6.03906C28.36 5.39906 27.49 5.03906 26.59 5.03906H26L26.59 3.26906C26.79 2.66906 26.34 2.03906 25.7 2.03906H19C18.35 2.03906 17.72 2.24906 17.2 2.63906C16.48 3.17906 16.05 4.00906 16 4.89906H15.99C15.95 4.00906 15.51 3.17906 14.79 2.64906C14.28 2.24906 13.65 2.03906 13 2.03906H6.3C5.66 2.03906 5.21 2.66906 5.41 3.26906L6 5.03906H5.41C4.51 5.03906 3.64 5.39906 3 6.03906C2.36 6.67906 2 7.54906 2 8.44906V12.3791C2 12.6791 2.32 12.8791 2.59 12.7391L3.34 12.3691C3.62 12.2291 3.92 12.3591 3.99 12.6591C4.04 12.8691 3.94 13.0691 3.75 13.1591L2.75 13.6591C2.29 13.8891 2 14.3591 2 14.8791V21.3291C2 24.1191 3.85 26.5691 6.53 27.3391L15.41 29.8791C15.79 29.9891 16.2 29.9891 16.59 29.8791L25.47 27.3391C28.15 26.5691 30 24.1191 30 21.3291V14.8791C30 14.3691 29.71 13.8991 29.25 13.6691L28.25 13.1691C28.07 13.0791 27.97 12.8691 28.01 12.6691C28.08 12.3591 28.39 12.2291 28.66 12.3691ZM27 15.9991C27 19.3391 24.3 22.0391 20.96 22.0391H16H11.04C7.7 22.0391 5 19.3391 5 15.9991C5 14.7191 5.4 13.4791 6.15 12.4491L8.45 9.28906C7.8 8.71906 7.4 7.87906 7.43 6.92906C7.49 5.32906 8.82 4.03906 10.42 4.03906C11.49 4.03906 12.44 4.59906 12.97 5.44906C14.66 7.08906 17.34 7.08906 19.03 5.44906C19.56 4.59906 20.5 4.03906 21.58 4.03906C23.18 4.03906 24.52 5.32906 24.57 6.92906C24.6 7.86906 24.2 8.71906 23.55 9.28906L25.85 12.4491C26.6 13.4791 27 14.7291 27 15.9991Z"
      fill="url(#paint18_linear_64_89926)"
    />
    <path
      d="M28.66 12.3691L29.41 12.7391C29.68 12.8791 30 12.6791 30 12.3791V8.44906C30 7.53906 29.64 6.67906 29 6.03906C28.36 5.39906 27.49 5.03906 26.59 5.03906H26L26.59 3.26906C26.79 2.66906 26.34 2.03906 25.7 2.03906H19C18.35 2.03906 17.72 2.24906 17.2 2.63906C16.48 3.17906 16.05 4.00906 16 4.89906H15.99C15.95 4.00906 15.51 3.17906 14.79 2.64906C14.28 2.24906 13.65 2.03906 13 2.03906H6.3C5.66 2.03906 5.21 2.66906 5.41 3.26906L6 5.03906H5.41C4.51 5.03906 3.64 5.39906 3 6.03906C2.36 6.67906 2 7.54906 2 8.44906V12.3791C2 12.6791 2.32 12.8791 2.59 12.7391L3.34 12.3691C3.62 12.2291 3.92 12.3591 3.99 12.6591C4.04 12.8691 3.94 13.0691 3.75 13.1591L2.75 13.6591C2.29 13.8891 2 14.3591 2 14.8791V21.3291C2 24.1191 3.85 26.5691 6.53 27.3391L15.41 29.8791C15.79 29.9891 16.2 29.9891 16.59 29.8791L25.47 27.3391C28.15 26.5691 30 24.1191 30 21.3291V14.8791C30 14.3691 29.71 13.8991 29.25 13.6691L28.25 13.1691C28.07 13.0791 27.97 12.8691 28.01 12.6691C28.08 12.3591 28.39 12.2291 28.66 12.3691ZM27 15.9991C27 19.3391 24.3 22.0391 20.96 22.0391H16H11.04C7.7 22.0391 5 19.3391 5 15.9991C5 14.7191 5.4 13.4791 6.15 12.4491L8.45 9.28906C7.8 8.71906 7.4 7.87906 7.43 6.92906C7.49 5.32906 8.82 4.03906 10.42 4.03906C11.49 4.03906 12.44 4.59906 12.97 5.44906C14.66 7.08906 17.34 7.08906 19.03 5.44906C19.56 4.59906 20.5 4.03906 21.58 4.03906C23.18 4.03906 24.52 5.32906 24.57 6.92906C24.6 7.86906 24.2 8.71906 23.55 9.28906L25.85 12.4491C26.6 13.4791 27 14.7291 27 15.9991Z"
      fill="url(#paint19_radial_64_89926)"
    />
    <path
      d="M28.66 12.3691L29.41 12.7391C29.68 12.8791 30 12.6791 30 12.3791V8.44906C30 7.53906 29.64 6.67906 29 6.03906C28.36 5.39906 27.49 5.03906 26.59 5.03906H26L26.59 3.26906C26.79 2.66906 26.34 2.03906 25.7 2.03906H19C18.35 2.03906 17.72 2.24906 17.2 2.63906C16.48 3.17906 16.05 4.00906 16 4.89906H15.99C15.95 4.00906 15.51 3.17906 14.79 2.64906C14.28 2.24906 13.65 2.03906 13 2.03906H6.3C5.66 2.03906 5.21 2.66906 5.41 3.26906L6 5.03906H5.41C4.51 5.03906 3.64 5.39906 3 6.03906C2.36 6.67906 2 7.54906 2 8.44906V12.3791C2 12.6791 2.32 12.8791 2.59 12.7391L3.34 12.3691C3.62 12.2291 3.92 12.3591 3.99 12.6591C4.04 12.8691 3.94 13.0691 3.75 13.1591L2.75 13.6591C2.29 13.8891 2 14.3591 2 14.8791V21.3291C2 24.1191 3.85 26.5691 6.53 27.3391L15.41 29.8791C15.79 29.9891 16.2 29.9891 16.59 29.8791L25.47 27.3391C28.15 26.5691 30 24.1191 30 21.3291V14.8791C30 14.3691 29.71 13.8991 29.25 13.6691L28.25 13.1691C28.07 13.0791 27.97 12.8691 28.01 12.6691C28.08 12.3591 28.39 12.2291 28.66 12.3691ZM27 15.9991C27 19.3391 24.3 22.0391 20.96 22.0391H16H11.04C7.7 22.0391 5 19.3391 5 15.9991C5 14.7191 5.4 13.4791 6.15 12.4491L8.45 9.28906C7.8 8.71906 7.4 7.87906 7.43 6.92906C7.49 5.32906 8.82 4.03906 10.42 4.03906C11.49 4.03906 12.44 4.59906 12.97 5.44906C14.66 7.08906 17.34 7.08906 19.03 5.44906C19.56 4.59906 20.5 4.03906 21.58 4.03906C23.18 4.03906 24.52 5.32906 24.57 6.92906C24.6 7.86906 24.2 8.71906 23.55 9.28906L25.85 12.4491C26.6 13.4791 27 14.7291 27 15.9991Z"
      fill="url(#paint20_radial_64_89926)"
    />
    <path
      d="M28.66 12.3691L29.41 12.7391C29.68 12.8791 30 12.6791 30 12.3791V8.44906C30 7.53906 29.64 6.67906 29 6.03906C28.36 5.39906 27.49 5.03906 26.59 5.03906H26L26.59 3.26906C26.79 2.66906 26.34 2.03906 25.7 2.03906H19C18.35 2.03906 17.72 2.24906 17.2 2.63906C16.48 3.17906 16.05 4.00906 16 4.89906H15.99C15.95 4.00906 15.51 3.17906 14.79 2.64906C14.28 2.24906 13.65 2.03906 13 2.03906H6.3C5.66 2.03906 5.21 2.66906 5.41 3.26906L6 5.03906H5.41C4.51 5.03906 3.64 5.39906 3 6.03906C2.36 6.67906 2 7.54906 2 8.44906V12.3791C2 12.6791 2.32 12.8791 2.59 12.7391L3.34 12.3691C3.62 12.2291 3.92 12.3591 3.99 12.6591C4.04 12.8691 3.94 13.0691 3.75 13.1591L2.75 13.6591C2.29 13.8891 2 14.3591 2 14.8791V21.3291C2 24.1191 3.85 26.5691 6.53 27.3391L15.41 29.8791C15.79 29.9891 16.2 29.9891 16.59 29.8791L25.47 27.3391C28.15 26.5691 30 24.1191 30 21.3291V14.8791C30 14.3691 29.71 13.8991 29.25 13.6691L28.25 13.1691C28.07 13.0791 27.97 12.8691 28.01 12.6691C28.08 12.3591 28.39 12.2291 28.66 12.3691ZM27 15.9991C27 19.3391 24.3 22.0391 20.96 22.0391H16H11.04C7.7 22.0391 5 19.3391 5 15.9991C5 14.7191 5.4 13.4791 6.15 12.4491L8.45 9.28906C7.8 8.71906 7.4 7.87906 7.43 6.92906C7.49 5.32906 8.82 4.03906 10.42 4.03906C11.49 4.03906 12.44 4.59906 12.97 5.44906C14.66 7.08906 17.34 7.08906 19.03 5.44906C19.56 4.59906 20.5 4.03906 21.58 4.03906C23.18 4.03906 24.52 5.32906 24.57 6.92906C24.6 7.86906 24.2 8.71906 23.55 9.28906L25.85 12.4491C26.6 13.4791 27 14.7291 27 15.9991Z"
      fill="url(#paint21_radial_64_89926)"
    />
    <path
      d="M21.9999 2.03906H18.9999C18.3499 2.03906 17.7199 2.24906 17.1999 2.63906C16.4799 3.17906 16.0499 4.00906 15.9999 4.89906H15.9899C15.9499 4.00906 15.5099 3.17906 14.7899 2.64906C14.2799 2.24906 13.6499 2.03906 12.9999 2.03906H9.35181V4.23922C9.68441 4.10999 10.0447 4.03906 10.4199 4.03906C11.4899 4.03906 12.4399 4.59906 12.9699 5.44906C14.6599 7.08906 17.3399 7.08906 19.0299 5.44906C19.5599 4.59906 20.4999 4.03906 21.5799 4.03906C21.7222 4.03906 21.8625 4.04927 21.9999 4.06898V2.03906Z"
      fill="url(#paint22_radial_64_89926)"
    />
    <path
      d="M2.53198 6.62337H6.5157L5.99737 5.03906H5.41007C4.51007 5.03906 3.64007 5.39906 3.00007 6.03906C2.82172 6.21741 2.66511 6.41363 2.53198 6.62337Z"
      fill="url(#paint23_linear_64_89926)"
    />
    <path
      d="M2.53198 6.62337H6.5157L5.99737 5.03906H5.41007C4.51007 5.03906 3.64007 5.39906 3.00007 6.03906C2.82172 6.21741 2.66511 6.41363 2.53198 6.62337Z"
      fill="url(#paint24_linear_64_89926)"
    />
    <path
      d="M29.4636 6.62337H25.4799L25.9982 5.03906H26.5855C27.4855 5.03906 28.3555 5.39906 28.9955 6.03906C29.1739 6.21741 29.3305 6.41363 29.4636 6.62337Z"
      fill="url(#paint25_linear_64_89926)"
    />
    <path
      d="M29.4636 6.62337H25.4799L25.9982 5.03906H26.5855C27.4855 5.03906 28.3555 5.39906 28.9955 6.03906C29.1739 6.21741 29.3305 6.41363 29.4636 6.62337Z"
      fill="url(#paint26_linear_64_89926)"
    />
    <path
      d="M16 27.0387C12.69 27.0387 10 24.3487 10 21.0387V19.4688H22V21.0387C22 24.3487 19.31 27.0387 16 27.0387Z"
      fill="#FFDEA3"
    />
    <path
      d="M16 27.0387C12.69 27.0387 10 24.3487 10 21.0387V19.4688H22V21.0387C22 24.3487 19.31 27.0387 16 27.0387Z"
      fill="url(#paint27_radial_64_89926)"
    />
    <path
      d="M16 27.0387C12.69 27.0387 10 24.3487 10 21.0387V19.4688H22V21.0387C22 24.3487 19.31 27.0387 16 27.0387Z"
      fill="url(#paint28_radial_64_89926)"
    />
    <path
      d="M16 27.0387C12.69 27.0387 10 24.3487 10 21.0387V19.4688H22V21.0387C22 24.3487 19.31 27.0387 16 27.0387Z"
      fill="url(#paint29_radial_64_89926)"
    />
    <path
      d="M16 27.0387C12.69 27.0387 10 24.3487 10 21.0387V19.4688H22V21.0387C22 24.3487 19.31 27.0387 16 27.0387Z"
      fill="url(#paint30_radial_64_89926)"
    />
    <g filter="url(#filter0_i_64_89926)">
      <path
        d="M19.43 16.8989C19.16 16.8989 18.9 16.7789 18.72 16.5789L16.73 14.2789C16.34 13.8289 15.65 13.8289 15.26 14.2789L13.27 16.5789C13.09 16.7889 12.83 16.8989 12.56 16.8989C11.15 16.8989 10 18.0489 10 19.4689C10 20.8889 11.15 22.0389 12.57 22.0389H13.86C14.75 22.0389 15.54 21.5789 16 20.8889C16.46 21.5789 17.25 22.0389 18.14 22.0389H19.43C20.85 22.0389 22 20.8889 22 19.4689C22 18.0489 20.85 16.8989 19.43 16.8989Z"
        fill="url(#paint31_linear_64_89926)"
      />
      <path
        d="M19.43 16.8989C19.16 16.8989 18.9 16.7789 18.72 16.5789L16.73 14.2789C16.34 13.8289 15.65 13.8289 15.26 14.2789L13.27 16.5789C13.09 16.7889 12.83 16.8989 12.56 16.8989C11.15 16.8989 10 18.0489 10 19.4689C10 20.8889 11.15 22.0389 12.57 22.0389H13.86C14.75 22.0389 15.54 21.5789 16 20.8889C16.46 21.5789 17.25 22.0389 18.14 22.0389H19.43C20.85 22.0389 22 20.8889 22 19.4689C22 18.0489 20.85 16.8989 19.43 16.8989Z"
        fill="url(#paint32_linear_64_89926)"
      />
      <path
        d="M19.43 16.8989C19.16 16.8989 18.9 16.7789 18.72 16.5789L16.73 14.2789C16.34 13.8289 15.65 13.8289 15.26 14.2789L13.27 16.5789C13.09 16.7889 12.83 16.8989 12.56 16.8989C11.15 16.8989 10 18.0489 10 19.4689C10 20.8889 11.15 22.0389 12.57 22.0389H13.86C14.75 22.0389 15.54 21.5789 16 20.8889C16.46 21.5789 17.25 22.0389 18.14 22.0389H19.43C20.85 22.0389 22 20.8889 22 19.4689C22 18.0489 20.85 16.8989 19.43 16.8989Z"
        fill="url(#paint33_linear_64_89926)"
      />
      <path
        d="M19.43 16.8989C19.16 16.8989 18.9 16.7789 18.72 16.5789L16.73 14.2789C16.34 13.8289 15.65 13.8289 15.26 14.2789L13.27 16.5789C13.09 16.7889 12.83 16.8989 12.56 16.8989C11.15 16.8989 10 18.0489 10 19.4689C10 20.8889 11.15 22.0389 12.57 22.0389H13.86C14.75 22.0389 15.54 21.5789 16 20.8889C16.46 21.5789 17.25 22.0389 18.14 22.0389H19.43C20.85 22.0389 22 20.8889 22 19.4689C22 18.0489 20.85 16.8989 19.43 16.8989Z"
        fill="url(#paint34_radial_64_89926)"
      />
    </g>
    <g filter="url(#filter1_f_64_89926)">
      <path
        d="M18.9065 14.0156C18.9065 13.5971 19.2458 13.2578 19.6643 13.2578C20.0828 13.2578 20.4221 13.5971 20.4221 14.0156V15.5C20.4221 15.9185 20.0828 16.2578 19.6643 16.2578C19.2458 16.2578 18.9065 15.9185 18.9065 15.5V14.0156Z"
        fill="#E78D29"
      />
      <path
        d="M10.8987 14.0156C10.8987 13.5971 11.238 13.2578 11.6565 13.2578C12.075 13.2578 12.4143 13.5971 12.4143 14.0156V15.5C12.4143 15.9185 12.075 16.2578 11.6565 16.2578C11.238 16.2578 10.8987 15.9185 10.8987 15.5V14.0156Z"
        fill="#E78D29"
      />
    </g>
    <rect x="19.25" y="13.0391" width="1.51562" height="3" rx="0.757812" fill="url(#paint35_linear_64_89926)" />
    <rect x="19.25" y="13.0391" width="1.51562" height="3" rx="0.757812" fill="url(#paint36_linear_64_89926)" />
    <rect x="11.2422" y="13.0391" width="1.51562" height="3" rx="0.757812" fill="url(#paint37_linear_64_89926)" />
    <rect x="11.2422" y="13.0391" width="1.51562" height="3" rx="0.757812" fill="url(#paint38_linear_64_89926)" />
    <rect x="11.2422" y="13.0391" width="1.51562" height="3" rx="0.757812" fill="url(#paint39_linear_64_89926)" />
    <g filter="url(#filter2_f_64_89926)">
      <path d="M16.0938 14.2949V17.9199L18.3438 17.1387L16.0938 14.2949Z" fill="#FFEDBA" fillOpacity="0.75" />
    </g>
    <g filter="url(#filter3_f_64_89926)">
      <path
        d="M13.2474 18.4364L15.0299 20.2296C15.3132 20.5146 15.7794 20.5146 16.0719 20.2296L17.8543 18.4364C18.1651 18.1238 17.9457 17.5996 17.507 17.5996H13.6039C13.156 17.5996 12.9366 18.1238 13.2474 18.4364Z"
        fill="url(#paint40_linear_64_89926)"
      />
    </g>
    <path
      d="M13.4901 17.8084L15.4401 19.7584C15.7501 20.0684 16.2601 20.0684 16.5801 19.7584L18.5301 17.8084C18.8701 17.4684 18.6301 16.8984 18.1501 16.8984H13.8801C13.3901 16.8984 13.1501 17.4684 13.4901 17.8084Z"
      fill="url(#paint41_linear_64_89926)"
    />
    <path
      d="M13.4901 17.8084L15.4401 19.7584C15.7501 20.0684 16.2601 20.0684 16.5801 19.7584L18.5301 17.8084C18.8701 17.4684 18.6301 16.8984 18.1501 16.8984H13.8801C13.3901 16.8984 13.1501 17.4684 13.4901 17.8084Z"
      fill="url(#paint42_linear_64_89926)"
    />
    <path
      d="M13.4901 17.8084L15.4401 19.7584C15.7501 20.0684 16.2601 20.0684 16.5801 19.7584L18.5301 17.8084C18.8701 17.4684 18.6301 16.8984 18.1501 16.8984H13.8801C13.3901 16.8984 13.1501 17.4684 13.4901 17.8084Z"
      fill="url(#paint43_linear_64_89926)"
    />
    <path
      d="M13.4901 17.8084L15.4401 19.7584C15.7501 20.0684 16.2601 20.0684 16.5801 19.7584L18.5301 17.8084C18.8701 17.4684 18.6301 16.8984 18.1501 16.8984H13.8801C13.3901 16.8984 13.1501 17.4684 13.4901 17.8084Z"
      fill="url(#paint44_linear_64_89926)"
    />
    <g filter="url(#filter4_f_64_89926)">
      <ellipse cx="19.6111" cy="19.0215" rx="2.06085" ry="1.74219" fill="url(#paint45_radial_64_89926)" />
    </g>
    <g filter="url(#filter5_f_64_89926)">
      <path
        d="M16.7969 5.9668C17.1094 5.87826 17.9219 5.7793 18.7031 4.49805C19.4938 3.20133 20.6875 3.0918 21.0781 3.0918"
        stroke="#C49589"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
    </g>
    <g filter="url(#filter6_f_64_89926)">
      <rect x="28.1562" y="14.8477" width="0.8125" height="7.85369" fill="#C39589" fillOpacity="0.5" />
    </g>
    <g filter="url(#filter7_f_64_89926)">
      <rect x="27.4719" y="6.67969" width="0.8125" height="4.39365" fill="#C39589" fillOpacity="0.75" />
    </g>
    <g filter="url(#filter8_f_64_89926)">
      <path d="M25.8281 2.95117L25 5.1543" stroke="#C49589" strokeWidth="0.2" strokeLinecap="round" />
    </g>
    <defs>
      <filter
        id="filter0_i_64_89926"
        x="10"
        y="13.6914"
        width="12"
        height="8.34766"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-0.25" />
        <feGaussianBlur stdDeviation="0.3" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.875 0 0 0 0 0.415625 0 0 0 0 0.415625 0 0 0 0.75 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_64_89926" />
      </filter>
      <filter
        id="filter1_f_64_89926"
        x="10.3987"
        y="12.7578"
        width="10.5234"
        height="4"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_64_89926" />
      </filter>
      <filter
        id="filter2_f_64_89926"
        x="15.0938"
        y="13.2949"
        width="4.25"
        height="5.625"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_64_89926" />
      </filter>
      <filter
        id="filter3_f_64_89926"
        x="12.6021"
        y="17.0996"
        width="5.89795"
        height="3.84375"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_64_89926" />
      </filter>
      <filter
        id="filter4_f_64_89926"
        x="16.5503"
        y="16.2793"
        width="6.12158"
        height="5.48438"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_64_89926" />
      </filter>
      <filter
        id="filter5_f_64_89926"
        x="15.2969"
        y="1.5918"
        width="7.28125"
        height="5.875"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.625" result="effect1_foregroundBlur_64_89926" />
      </filter>
      <filter
        id="filter6_f_64_89926"
        x="26.1562"
        y="12.8477"
        width="4.8125"
        height="11.8535"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_64_89926" />
      </filter>
      <filter
        id="filter7_f_64_89926"
        x="25.4719"
        y="4.67969"
        width="4.8125"
        height="8.39453"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_64_89926" />
      </filter>
      <filter
        id="filter8_f_64_89926"
        x="23.8999"
        y="1.85156"
        width="3.02832"
        height="4.40234"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_64_89926" />
      </filter>
      <radialGradient
        id="paint0_radial_64_89926"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17.6875 5.2015) rotate(90) scale(19.5625 19.4375)"
      >
        <stop stopColor="#FFCB5A" />
        <stop offset="0.766773" stopColor="#EA9231" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_64_89926"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(26.125 12.639) rotate(154.026) scale(8.13413 15.2065)"
      >
        <stop stopColor="#FFCB5A" />
        <stop offset="1" stopColor="#FFCB5A" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_64_89926"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(4 16.8265) rotate(-9.59311) scale(4.50043 13.1042)"
      >
        <stop stopColor="#FFD375" />
        <stop offset="1" stopColor="#FFCB5A" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint3_radial_64_89926"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16 21.639) rotate(-90) scale(6.75 8.9375)"
      >
        <stop stopColor="#DF7D3C" />
        <stop offset="1" stopColor="#DF7D3C" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint4_radial_64_89926"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16 4.3265) rotate(90) scale(4.625 5.24685)"
      >
        <stop stopColor="#DF7D3C" />
        <stop offset="1" stopColor="#DF7D3C" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint5_radial_64_89926"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17.125 11.5765) rotate(87.1376) scale(11.2641 16.3049)"
      >
        <stop offset="0.865337" stopColor="#DF7D3C" stopOpacity="0" />
        <stop offset="1" stopColor="#C8692A" />
      </radialGradient>
      <radialGradient
        id="paint6_radial_64_89926"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(20.375 8.139) rotate(-37.9101) scale(4.47574 5.30265)"
      >
        <stop stopColor="#DF7D3C" />
        <stop offset="1" stopColor="#DF7D3C" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint7_radial_64_89926"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(23.9063 6.264) rotate(161.274) scale(0.973401 2.03584)"
      >
        <stop stopColor="#FFC567" />
        <stop offset="1" stopColor="#FFC362" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint8_radial_64_89926"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(13.6875 4.514) rotate(162.628) scale(3.55864 4.21612)"
      >
        <stop stopColor="#DF7D3C" />
        <stop offset="1" stopColor="#DF7D3C" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint9_radial_64_89926"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(7.81243 6.264) rotate(-3.13636) scale(4.56934 4.80913)"
      >
        <stop stopColor="#DC8C58" />
        <stop offset="1" stopColor="#CC7438" />
      </radialGradient>
      <linearGradient
        id="paint10_linear_64_89926"
        x1="10.4687"
        y1="7.27962"
        x2="9.46868"
        y2="6.35775"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CB6C32" />
        <stop offset="0.586576" stopColor="#D1743A" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint11_radial_64_89926"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(8.50774 7.62337) rotate(-10.8403) scale(1.49544 2.60836)"
      >
        <stop stopColor="#DC8B54" />
        <stop offset="1" stopColor="#DC8B54" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint12_radial_64_89926"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(23.1875 5.04883) rotate(117.038) scale(3.11825 3.27287)"
      >
        <stop stopColor="#F49A45" />
        <stop offset="1" stopColor="#EA8243" />
      </radialGradient>
      <linearGradient
        id="paint13_linear_64_89926"
        x1="16"
        y1="1.73066"
        x2="16"
        y2="40.264"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9F635E" />
        <stop offset="1" stopColor="#612A4F" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_64_89926"
        x1="1.1875"
        y1="21.264"
        x2="7.375"
        y2="21.264"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9A6A66" />
        <stop offset="1" stopColor="#7D5956" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_64_89926"
        x1="32.625"
        y1="21.264"
        x2="24.125"
        y2="21.264"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#996862" />
        <stop offset="0.55147" stopColor="#996862" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint16_radial_64_89926"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16.4375 11.3265) rotate(91.3449) scale(18.6402 27.0972)"
      >
        <stop offset="0.688811" stopColor="#612A4F" stopOpacity="0" />
        <stop offset="1" stopColor="#612A4F" />
      </radialGradient>
      <radialGradient
        id="paint17_radial_64_89926"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(24.5625 9.7015) rotate(136.721) scale(10.3019 10.3305)"
      >
        <stop stopColor="#A16662" />
        <stop offset="1" stopColor="#A16662" stopOpacity="0" />
      </radialGradient>
      <linearGradient id="paint18_linear_64_89926" x1="16" y1="0.514" x2="16" y2="5.264" gradientUnits="userSpaceOnUse">
        <stop stopColor="#754B42" />
        <stop offset="1" stopColor="#754B42" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint19_radial_64_89926"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(13.1875 15.4515) rotate(125.272) scale(9.41616 9.04653)"
      >
        <stop stopColor="#512242" />
        <stop offset="1" stopColor="#612A4F" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint20_radial_64_89926"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(14.125 22.0765) rotate(109.231) scale(5.69265 5.46919)"
      >
        <stop stopColor="#512242" />
        <stop offset="1" stopColor="#612A4F" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint21_radial_64_89926"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(20.5625 19.639) rotate(56.464) scale(6.44841 8.63095)"
      >
        <stop stopColor="#512242" />
        <stop offset="1" stopColor="#612A4F" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint22_radial_64_89926"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16.0937 2.264) rotate(90) scale(5.09375 12.75)"
      >
        <stop offset="0.575758" stopColor="#844649" stopOpacity="0" />
        <stop offset="0.881262" stopColor="#914E52" />
      </radialGradient>
      <linearGradient
        id="paint23_linear_64_89926"
        x1="5.31257"
        y1="5.71712"
        x2="7.09382"
        y2="3.79525"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7E3835" stopOpacity="0" />
        <stop offset="0.665493" stopColor="#442524" />
      </linearGradient>
      <linearGradient
        id="paint24_linear_64_89926"
        x1="4.71882"
        y1="6.014"
        x2="3.25007"
        y2="2.639"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7E3835" stopOpacity="0" />
        <stop offset="0.572141" stopColor="#442524" />
      </linearGradient>
      <linearGradient
        id="paint25_linear_64_89926"
        x1="26.683"
        y1="5.71712"
        x2="24.9018"
        y2="3.79525"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7E3835" stopOpacity="0" />
        <stop offset="0.665493" stopColor="#442524" />
      </linearGradient>
      <linearGradient
        id="paint26_linear_64_89926"
        x1="27.2768"
        y1="6.014"
        x2="28.7455"
        y2="2.639"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7E3835" stopOpacity="0" />
        <stop offset="0.572141" stopColor="#442524" />
      </linearGradient>
      <radialGradient
        id="paint27_radial_64_89926"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(22.5625 27.0388) rotate(-159.264) scale(7.48484 7.48484)"
      >
        <stop offset="0.238636" stopColor="#FFCFAC" />
        <stop offset="1" stopColor="#F5A8A2" />
      </radialGradient>
      <radialGradient
        id="paint28_radial_64_89926"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16 20.3887) rotate(90) scale(7.3125 7.839)"
      >
        <stop offset="0.649573" stopColor="#FFBC99" stopOpacity="0" />
        <stop offset="0.905983" stopColor="#E98C8C" />
      </radialGradient>
      <radialGradient
        id="paint29_radial_64_89926"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(8.25 22.2012) rotate(30.3236) scale(3.83752 6.08326)"
      >
        <stop stopColor="#FFCFAC" />
        <stop offset="1" stopColor="#FFCFAC" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint30_radial_64_89926"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16 20.1387) rotate(90) scale(2.8125 5.36337)"
      >
        <stop stopColor="#D87B7C" />
        <stop offset="1" stopColor="#D87B7C" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint31_linear_64_89926"
        x1="16"
        y1="13.9414"
        x2="16"
        y2="23.3888"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDCC98" />
        <stop offset="1" stopColor="#FBB3BE" />
        <stop offset="1" stopColor="#F4AD92" />
      </linearGradient>
      <linearGradient
        id="paint32_linear_64_89926"
        x1="10.7812"
        y1="15.5138"
        x2="13.2188"
        y2="17.9902"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00435963" stopColor="#DAA168" />
        <stop offset="1" stopColor="#DAA168" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint33_linear_64_89926"
        x1="16"
        y1="22.3576"
        x2="16"
        y2="19.2013"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.268293" stopColor="#E98C8C" />
        <stop offset="1" stopColor="#DC8CE9" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint34_radial_64_89926"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16 16.8888) rotate(90) scale(6.125 9.07688)"
      >
        <stop offset="0.760204" stopColor="#E98F94" stopOpacity="0" />
        <stop offset="1" stopColor="#CC9688" />
      </radialGradient>
      <linearGradient
        id="paint35_linear_64_89926"
        x1="20.6774"
        y1="14.5391"
        x2="19.8434"
        y2="14.5391"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.225" stopColor="#594C4F" />
        <stop offset="1" stopColor="#392833" />
      </linearGradient>
      <linearGradient
        id="paint36_linear_64_89926"
        x1="21.0024"
        y1="16.564"
        x2="21.0024"
        y2="14.9738"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#552D52" />
        <stop offset="1" stopColor="#552D52" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint37_linear_64_89926"
        x1="12.6696"
        y1="14.5391"
        x2="11.8356"
        y2="14.5391"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.225" stopColor="#594C4F" />
        <stop offset="1" stopColor="#392833" />
      </linearGradient>
      <linearGradient
        id="paint38_linear_64_89926"
        x1="10.7813"
        y1="14.5391"
        x2="12"
        y2="14.5391"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#594C4F" />
        <stop offset="1" stopColor="#594C4F" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint39_linear_64_89926"
        x1="12.9946"
        y1="16.564"
        x2="12.9946"
        y2="14.9738"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#552D52" />
        <stop offset="1" stopColor="#552D52" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint40_linear_64_89926"
        x1="15.551"
        y1="18.1621"
        x2="15.551"
        y2="20.9121"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CC9688" />
        <stop offset="1" stopColor="#E98F94" />
      </linearGradient>
      <linearGradient
        id="paint41_linear_64_89926"
        x1="18.6895"
        y1="16.3259"
        x2="14.7836"
        y2="19.7568"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.191489" stopColor="#5F5254" />
        <stop offset="1" stopColor="#2E2127" />
      </linearGradient>
      <linearGradient
        id="paint42_linear_64_89926"
        x1="16.4378"
        y1="17.7365"
        x2="16.4378"
        y2="16.2374"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.146552" stopColor="#34222C" stopOpacity="0" />
        <stop offset="0.872727" stopColor="#34222C" />
      </linearGradient>
      <linearGradient
        id="paint43_linear_64_89926"
        x1="17.3666"
        y1="20.2506"
        x2="17.3666"
        y2="17.9844"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#552D52" />
        <stop offset="1" stopColor="#552D52" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint44_linear_64_89926"
        x1="14.2501"
        y1="19.5134"
        x2="15.2829"
        y2="18.5121"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5F5254" />
        <stop offset="1" stopColor="#5F5254" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint45_radial_64_89926"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(21.198 18.2557) rotate(136.545) scale(3.88941 5.0611)"
      >
        <stop stopColor="#FFEDBA" />
        <stop offset="1" stopColor="#FFEDBA" stopOpacity="0" />
      </radialGradient>
    </defs>
  </SvgIcon>
);

export default Lion;
