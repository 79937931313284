/* eslint-disable prettier/prettier */
/* eslint-disable */

import connectReducers from '../utils/connectReducers';

import {
  GET_OLYMPIAD_FORMATS
} from '../actions';

import {
  FAILURE,
  REQUEST,
  SUCCESS
} from '../actions/utils';

import { IDictionaryItemList } from '../api/types';

import genericReducers from './genericReducer';

import { IReducers } from '.';

export type OlympiadFormatsState = IDictionaryItemList;

const initialState = {} as IDictionaryItemList;

const olympiadFormats: IReducers<OlympiadFormatsState> = {
  [GET_OLYMPIAD_FORMATS[REQUEST]]: genericReducers().get.request,
  [GET_OLYMPIAD_FORMATS[SUCCESS]]: genericReducers().get.success,
  [GET_OLYMPIAD_FORMATS[FAILURE]]: genericReducers().get.failure
};

export default connectReducers(initialState, olympiadFormats);
