import { IQueryParams } from 'api';
import { IReportErrorFormData } from 'components/common/PortfolioReportErrorForm';
import { IThanksFormData } from 'components/common/PortfolioThanksForm';
import { IModifiedPersonObject } from 'portfolio3/features/dataEntryForm';
import { ICardData, IMappedEisDopRecommendation } from 'types';

import { action, createAction } from './utils';

export const DATA_ENTRY_DRAWER_SET_PRIMARY_OPEN = 'DATA_ENTRY_DRAWER_SET_PRIMARY_OPEN';
export const DATA_ENTRY_DRAWER_SET_SECONDARY_OPEN = 'DATA_ENTRY_DRAWER_SET_SECONDARY_OPEN';
export const DATA_ENTRY_DRAWER_SET_PRIMARY_INITIAL_FORM_DATA = 'DATA_ENTRY_DRAWER_SET_PRIMARY_INITIAL_FORM_DATA';
export const DATA_ENTRY_DRAWER_SET_SECONDARY_INITIAL_FORM_DATA = 'DATA_ENTRY_DRAWER_SET_SECONDARY_INITIAL_FORM_DATA';
export const DATA_ENTRY_DRAWER_SET_EXCLUDED_EVENT_TYPES = 'DATA_ENTRY_DRAWER_SET_EXCLUDED_EVENT_TYPES';
export const DATA_ENTRY_DRAWER_SET_PERSONS_OBJECTS_QUERY_PARAMS = 'DATA_ENTRY_DRAWER_SET_PERSONS_OBJECTS_QUERY_PARAMS';
export const dataEntryDrawerActions = {
  setPrimaryOpen: (open: boolean, widgetLabel: string | null) =>
    action(DATA_ENTRY_DRAWER_SET_PRIMARY_OPEN, { payload: { open, widgetLabel } }),
  setSecondaryOpen: (open: boolean, widgetLabel: string | null) =>
    action(DATA_ENTRY_DRAWER_SET_SECONDARY_OPEN, { payload: { open, widgetLabel } }),
  setPrimaryInitialFormData: (initialFormData: Partial<IModifiedPersonObject>) =>
    action(DATA_ENTRY_DRAWER_SET_PRIMARY_INITIAL_FORM_DATA, { payload: initialFormData }),
  setSecondaryInitialFormData: (initialFormData: Partial<IModifiedPersonObject>) =>
    action(DATA_ENTRY_DRAWER_SET_SECONDARY_INITIAL_FORM_DATA, { payload: initialFormData }),
  setExcludedEventTypes: (eventTypes: number[] | undefined) =>
    action(DATA_ENTRY_DRAWER_SET_EXCLUDED_EVENT_TYPES, { payload: eventTypes }),
  setPersonsObjectsQueryParams: (queryParams: IQueryParams) =>
    action(DATA_ENTRY_DRAWER_SET_PERSONS_OBJECTS_QUERY_PARAMS, { payload: queryParams }),
};

export const DETAIL_VIEW_DRAWER_SET_PRIMARY_OPEN = 'DETAIL_VIEW_DRAWER_SET_PRIMARY_OPEN';
export const DETAIL_VIEW_DRAWER_SET_SECONDARY_OPEN = 'DETAIL_VIEW_DRAWER_SET_SECONDARY_OPEN';
export const DETAIL_VIEW_DRAWER_SET_PRIMARY_CARD_DATA = 'DETAIL_VIEW_DRAWER_SET_PRIMARY_CARD_DATA';
export const DETAIL_VIEW_DRAWER_SET_SECONDARY_CARD_DATA = 'DETAIL_VIEW_DRAWER_SET_SECONDARY_CARD_DATA';
export const detailViewDrawerActions = {
  setPrimaryOpen: (open: boolean, widgetLabel: string | null) =>
    action(DETAIL_VIEW_DRAWER_SET_PRIMARY_OPEN, { payload: { open, widgetLabel } }),
  setSecondaryOpen: (open: boolean, widgetLabel: string | null) =>
    action(DETAIL_VIEW_DRAWER_SET_SECONDARY_OPEN, { payload: { open, widgetLabel } }),
  setPrimaryCardData: (cardData: ICardData) => action(DETAIL_VIEW_DRAWER_SET_PRIMARY_CARD_DATA, { payload: cardData }),
  setSecondaryCardData: (cardData: ICardData) =>
    action(DETAIL_VIEW_DRAWER_SET_SECONDARY_CARD_DATA, { payload: cardData }),
};

export const LINK_SHARING_DRAWER_SET_OPEN = 'LINK_SHARING_DRAWER_SET_OPEN';
export const LINK_SHARING_SET_LINK_URL_OPEN = 'LINK_SHARING_SET_LINK_URL_OPEN';
export const LINK_SHARING_SET_LINK_QR_CODE_OPEN = 'LINK_SHARING_SET_LINK_QR_CODE_OPEN';
export const LINK_SHARING_SET_CURRENT_LINK = 'LINK_SHARING_SET_CURRENT_LINK';
export const linkSharingDrawerActions = {
  setOpen: (open: boolean) => action(LINK_SHARING_DRAWER_SET_OPEN, { payload: open }),
  setLinkUrlOpen: (open: boolean) => action(LINK_SHARING_SET_LINK_URL_OPEN, { payload: open }),
  setLinkQrCodeOpen: (open: boolean) => action(LINK_SHARING_SET_LINK_QR_CODE_OPEN, { payload: open }),
  setCurrentLink: (linkUrl?: string, qrCode?: string) =>
    action(LINK_SHARING_SET_CURRENT_LINK, { payload: { linkUrl, qrCode } }),
};

export const REPORT_ERROR_DRAWER_SET_OPEN = 'REPORT_ERROR_DRAWER_SET_OPEN';
export const REPORT_ERROR_DRAWER_SET_FORM_DATA = 'REPORT_ERROR_DRAWER_SET_FORM_DATA';
export const REPORT_ERROR_DRAWER_SET_SUBMIT_MODAL_OPEN = 'REPORT_ERROR_DRAWER_SET_SUBMIT_MODAL_OPEN';
export const reportErrorDrawerActions = {
  setOpen: (open: boolean) => action(REPORT_ERROR_DRAWER_SET_OPEN, { payload: open }),
  setFormData: (formData: IReportErrorFormData | undefined) =>
    action(REPORT_ERROR_DRAWER_SET_FORM_DATA, { payload: formData }),
  setSubmitModalOpen: (open: boolean) => action(REPORT_ERROR_DRAWER_SET_SUBMIT_MODAL_OPEN, { payload: open }),
};

export const THANKS_TEACHER_DRAWER_SET_OPEN = 'THANKS_TEACHER_DRAWER_SET_OPEN';
export const THANKS_TEACHER_DRAWER_SET_FORM_DATA = 'THANKS_TEACHER_DRAWER_SET_FORM_DATA';
export const THANKS_TEACHER_DRAWER_SET_SUBMIT_MODAL_OPEN = 'THANKS_TEACHER_DRAWER_SET_SUBMIT_MODAL_OPEN';
export const thanksTeacherDrawerActions = {
  setOpen: (open: boolean, widgetLabel: string | null) =>
    createAction(THANKS_TEACHER_DRAWER_SET_OPEN, { open, widgetLabel }),
  setFormData: (formData: IThanksFormData | undefined) =>
    action(THANKS_TEACHER_DRAWER_SET_FORM_DATA, { payload: formData }),
  setSubmitModalOpen: (open: boolean) => action(THANKS_TEACHER_DRAWER_SET_SUBMIT_MODAL_OPEN, { payload: open }),
};

export const EIS_DOP_RECOMMENDATIONS_DRAWER_SET_OPEN = 'EIS_DOP_RECOMMENDATIONS_DRAWER_SET_OPEN';
export const EIS_DOP_RECOMMENDATIONS_DRAWER_SET_RECOMMENDATIONS = 'EIS_DOP_RECOMMENDATIONS_DRAWER_SET_RECOMMENDATIONS';
export const EIS_DOP_RECOMMENDATIONS_DRAWER_SET_TITLE = 'EIS_DOP_RECOMMENDATIONS_DRAWER_SET_TITLE';
export const eisDopRecommendationsDrawerActions = {
  setOpen: (open: boolean) => action(EIS_DOP_RECOMMENDATIONS_DRAWER_SET_OPEN, { payload: open }),
  setRecommendations: (recommendations: IMappedEisDopRecommendation[]) =>
    action(EIS_DOP_RECOMMENDATIONS_DRAWER_SET_RECOMMENDATIONS, { payload: recommendations }),
  setTitle: (title: string) => action(EIS_DOP_RECOMMENDATIONS_DRAWER_SET_TITLE, { payload: title }),
};
