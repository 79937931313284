import { FC } from 'react';
import { PropagateLoader } from 'react-spinners';

import { Box } from '@mui/material';
import { getAccentColor } from 'portfolio3/styles';
import { SxStyles } from 'types';

const styles: SxStyles = {
  gridColumn: '1/-1',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  height: '120px',
};

const TableLoadingIndicator: FC = () => {
  return (
    <Box className="table-loading-indicator" sx={styles}>
      <PropagateLoader color={getAccentColor('indigo', '100')} />
    </Box>
  );
};

export default TableLoadingIndicator;
