import { FC } from 'react';

import { EntityType } from 'const';
import { useDetailViewDrawer } from 'hooks';
import { IStudyDocumentEntity } from 'types';

import { DocumentCard } from '../../components/cards';
import SearchSection from '../../components/SearchSection';

interface IDocumentCardListProps {
  documents: IStudyDocumentEntity[];
}

const DocumentCardList: FC<IDocumentCardListProps> = ({ documents }) => {
  const { handleOpenPrimaryDetailView } = useDetailViewDrawer();

  const cards = documents.map((document, index, array) => {
    const { cardData, name, badge, formData } = document;

    const handleView = () => handleOpenPrimaryDetailView(cardData, EntityType.DOCUMENT);

    const card = <DocumentCard name={name} badge={badge} onView={handleView} />;

    return (
      <SearchSection.CardContainer
        key={formData.id}
        card={card}
        mapIndex={index}
        totalCards={array.length}
        onView={handleView}
      />
    );
  });

  return cards;
};

export default DocumentCardList;
