import { SxStyles } from 'types';

export const desktopWrapper: SxStyles = {
  flexDirection: 'row',
  gap: '12px',
  justifyContent: 'space-between',
};

export const mobileWrapper: SxStyles = {
  gap: '12px',
};
