import { IRootState } from 'reducers';
import { createSelector } from 'reselect';

import { filterArchiveFalse } from './utils';

const olympiadSubjectsRefArchiveFalseSelector = createSelector(
  [(state: IRootState) => state.olympiadSubjectsRef],
  (olympidSubjects): IRootState['olympiadSubjectsRef'] => {
    return {
      ...olympidSubjects,
      content: olympidSubjects.content.filter(filterArchiveFalse),
    };
  },
);

export default olympiadSubjectsRefArchiveFalseSelector;
