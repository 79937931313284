import { FC, PropsWithChildren, useContext } from 'react';

import { ThemeCategoryKeys } from 'const';
import { RendererContext } from 'context';
import { useAppSelector, useThemeSettings } from 'hooks';
import { ColorBackground, ImageBackground, PatternBackground, SnowBackground } from 'portfolio3/components/theme';
import { NeutralColors } from 'portfolio3/styles';
import { getBackgroundValue, getPatternValue } from 'utils';

/**
 * Компонент служит для выбора компоненты с фоном приложения (паттерн/картинка/однотонный цвет)
 */
const ThemeBackgroundContainer: FC<PropsWithChildren> = ({ children }) => {
  const { isViewing: isAdminViewing } = useAppSelector((state) => state.adminViewMode);

  const themeSettings = useThemeSettings();
  const { currentRenderer } = useContext(RendererContext);

  // чтобы не показывать настройки темы для админа вне просмотра портфолио
  const isDefaultEmployeeBackground = currentRenderer === 'employee' && !isAdminViewing;

  const defaultBackground = <ColorBackground color={NeutralColors.light_neutrals_200}>{children}</ColorBackground>;

  const getThemeBackgroundComponent = () => {
    const themeCategory = themeSettings?.themeName;
    const themeBackground = themeSettings?.background;
    const themePattern = themeSettings?.themePattern;

    const background = getBackgroundValue(themeBackground);

    switch (themeCategory) {
      case ThemeCategoryKeys.STRICT:
        return <ColorBackground color={background}>{children}</ColorBackground>;
      case ThemeCategoryKeys.PATTERN:
        return (
          <PatternBackground gradient={background} pattern={getPatternValue(themePattern)}>
            {children}
          </PatternBackground>
        );
      case ThemeCategoryKeys.BRIGHT:
        return <ImageBackground image={background}>{children}</ImageBackground>;
      case ThemeCategoryKeys.SNOW:
        return <SnowBackground>{children}</SnowBackground>;
      default:
        return defaultBackground;
    }
  };

  if (isDefaultEmployeeBackground) {
    return defaultBackground;
  }

  return getThemeBackgroundComponent();
};

export default ThemeBackgroundContainer;
