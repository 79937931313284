import DataLoadingSpo from './DataLoadingSpo';
import DataNotFoundSpo from './DataNotFoundSpo';
import ErrorSpo from './ErrorSpo';
import ZeroSubjectsSpo from './ZeroSubjectsSpo';

export const Placeholders = {
  DataLoadingSpo,
  DataNotFoundSpo,
  ErrorSpo,
  ZeroSubjectsSpo,
};
