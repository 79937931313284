import { SxStyles } from 'types';

export const root: SxStyles = (theme) => {
  return {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',

    minHeight: '64px',
    paddingBlock: '8px',

    [theme.breakpoints.down('commonLg')]: {
      gap: '20px',
    },
  };
};

export const leftSide: SxStyles = {
  flexGrow: 1,
};

export const rightSide: SxStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
  height: 'max-content',
};
