import { NeutralColors } from 'portfolio3/styles';
import { Colors } from 'style/variables';
import { SxStyles } from 'types';
import { inheritSx } from 'utils';

export const root: SxStyles = ({ breakpoints }) => ({
  listStyle: 'none',

  display: 'flex',
  alignItems: 'center',
  gap: '12px',

  paddingBottom: '16px',
  borderBottom: '1px solid',
  borderColor: NeutralColors.light_neutrals_300,

  '&:not(:first-child)': {
    marginTop: '16px',
  },

  '&:last-of-type': {
    borderBottom: 'none',
  },

  [breakpoints.down('commonSm')]: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 0,

    paddingBottom: '8px',

    '&:not(:first-child)': {
      marginTop: '8px',
    },
  },
});

export const name: SxStyles = {
  width: '220px',
  minWidth: '220px',
};

export const value = (isButton: boolean): SxStyles => {
  const buttonStyles: SxStyles = {
    cursor: 'pointer',
    color: Colors['blue-primrose'],
    fontWeight: 700,
  };

  return {
    display: 'flex',
    flexWrap: 'wrap',

    wordBreak: 'break-word',
    fontWeight: 500,

    ...(isButton && inheritSx([buttonStyles])),
  };
};

export const iconContainer: SxStyles = {
  display: 'inline-flex',
  width: 'max-content',
  marginLeft: '8px',
  transform: 'translateY(25%)',
};
