import { ComponentProps } from 'react';

import { SxStyles } from 'types';

import { ILocalVuzSpeciality } from '../types';
import VuzCardSpeciality from './VuzCardSpeciality';

export const cardSpecialityMapFactory = (desktopInlinePadding: string, mobileInlinePadding: string, sx?: SxStyles) => {
  return function MappedCardSpeciality(speciality: ILocalVuzSpeciality, index: number) {
    const { number } = speciality;
    return (
      <VuzCardSpeciality
        key={`${number}-${index}`}
        {...mapSpecialityToCardProps(speciality, desktopInlinePadding, mobileInlinePadding)}
        sx={sx}
      />
    );
  };
};

function mapSpecialityToCardProps(
  speciality: ILocalVuzSpeciality,
  desktopInlinePadding: string,
  mobileInlinePadding: string,
): ComponentProps<typeof VuzCardSpeciality> {
  const {
    type,
    number,
    name,
    facultyName,
    budgetMinBall,
    budgetPlaces,
    paidMinBall,
    paidPlaces,
    subjects,
    studentSubjectsBallSummary,
  } = speciality;

  return {
    specialityType: type,
    specialityNumber: number,
    specialityName: name,
    budgetPlaces: budgetPlaces,
    budgetMinBall: budgetMinBall,
    paidPlaces: paidPlaces,
    paidMinBall: paidMinBall,
    specialitySubjects: subjects,
    facultyName: facultyName,
    studentSubjectsBallSummary: studentSubjectsBallSummary,
    desktopInlinePadding,
    mobileInlinePadding,
  };
}
