import { FC } from 'react';

import { Box } from '@mui/material';
import clsx from 'clsx';

import { ISwitcherButtonProps, SwitcherVariant } from '../../types';
import StyledBaseButton from '../bases/BaseButton.styled';
import StyledAccentButton from '../variants/AccentButton.styled';
import StyledGrayButton from '../variants/GrayButton.styled';

const SwitcherButtonVariants: Record<SwitcherVariant, typeof StyledBaseButton> = {
  accent: StyledAccentButton,
  gray: StyledGrayButton,
};

const SwitcherButton: FC<ISwitcherButtonProps> = ({
  content: Content,
  leftIcon: LeftIcon,
  rightIcon: RightIcon,
  componentVariant = 'accent',
  InputProps,
  ...props
}) => {
  const StyledButton = SwitcherButtonVariants[componentVariant] || StyledAccentButton;

  return (
    <StyledButton
      checked={InputProps?.checked}
      {...props}
      className={clsx('switcher-button', {
        'switcher-button--checked': InputProps?.checked,
        'switcher-button--disabled': props.disabled,
      })}
    >
      <input type="radio" aria-hidden hidden {...InputProps} />
      {LeftIcon && <LeftIcon />}
      <Box
        sx={{
          display: 'flex',
        }}
      >
        {Content}
      </Box>
      {RightIcon && <RightIcon />}
    </StyledButton>
  );
};

export default SwitcherButton;
