import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconPieChartFilled: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M20.273 8.667a5.844 5.844 0 0 0-4.94-4.94v4.94h4.94Zm.893 1.666h-6.665a.833.833 0 0 1-.835-.833V2.833c0-.46.374-.833.835-.833C18.636 2 22 5.365 22 9.5a.833.833 0 0 1-.835.833Zm-8.232 1.645h8.15a.92.92 0 0 1 .912 1.01 10.027 10.027 0 0 1-11.114 8.949c-4.585-.506-8.312-4.233-8.82-8.818a10.027 10.027 0 0 1 8.95-11.114.918.918 0 0 1 1.01.91v8.152c0 .503.409.91.912.91Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconPieChartFilled;
