import { IDictionaryItem } from 'api/types';
import { DifferentCode, DifferentValue } from 'const';

export const documentPlaceDictionary: IDictionaryItem[] = [
  {
    code: 1,
    value: 'Москва',
  },
  {
    code: DifferentCode,
    value: DifferentValue,
  },
];
