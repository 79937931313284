export enum ActivityFormatKeys {
  IN_PERSON = 'inPerson',
  IN_ABSENTIA = 'inAbsentia',
  MIXED = 'mixed',
  DISTANT = 'distant',
}

export const ActivityFormatCodes: Record<ActivityFormatKeys, number> = {
  [ActivityFormatKeys.IN_PERSON]: 1,
  [ActivityFormatKeys.IN_ABSENTIA]: 2,
  [ActivityFormatKeys.MIXED]: 3,
  [ActivityFormatKeys.DISTANT]: 4,
};

export const ActivityFormatNames: Record<ActivityFormatKeys, string> = {
  [ActivityFormatKeys.IN_PERSON]: 'очно',
  [ActivityFormatKeys.IN_ABSENTIA]: 'заочно',
  [ActivityFormatKeys.MIXED]: 'очно-заочно',
  [ActivityFormatKeys.DISTANT]: 'дистанционно',
};
