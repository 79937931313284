import { ICommonResponseObject } from 'api/types';
import { GiaType, StudentSectionSettingTypeCodes } from 'const';
import { createSelector } from 'reselect';
import { linkVisibilitySettingsSelector, visibilitySettingsSelector } from 'selectors/visibilitySettings';
import { IViewVisibilitySetting } from 'types';
import { findTargetVisibilitySetting } from 'utils/localVisibilitySettingsService';

import { IRootState } from '../../../reducers';

const findExamVisibility = (settings: IViewVisibilitySetting[], typeCode: string) => {
  const visibilitySettings = findTargetVisibilitySetting(settings, [
    StudentSectionSettingTypeCodes.study,
    StudentSectionSettingTypeCodes.studyGia,
    typeCode,
  ]);

  return visibilitySettings?.computedVisibility ?? false;
};

const studyGiaSelector = createSelector(
  [
    (state: IRootState) => state.studentGiaList,
    (state: IRootState) => state.linkMode.mode,
    visibilitySettingsSelector,
    linkVisibilitySettingsSelector,
  ],
  (studentGiaList, linkMode, visibilitySettings, linkVisibilitySettings) => {
    const settings = linkMode ? linkVisibilitySettings : visibilitySettings;

    const isOgeVisible = findExamVisibility(settings, StudentSectionSettingTypeCodes.giaOge);
    const isEgeVisible = findExamVisibility(settings, StudentSectionSettingTypeCodes.giaEge);
    const isGve9Visible = findExamVisibility(settings, StudentSectionSettingTypeCodes.giaGve9);
    const isGve11Visible = findExamVisibility(settings, StudentSectionSettingTypeCodes.giaGve11);
    const isOtherVisible = findExamVisibility(settings, StudentSectionSettingTypeCodes.giaOther);

    const giaList = studentGiaList.content;

    const oge = isOgeVisible && giaList.filter((exam) => exam.formaGia === GiaType.oge);
    const ege =
      isEgeVisible && giaList.filter((exam) => exam.formaGia === GiaType.ege).filter((exam) => !exam.approbation);
    const gve9 = isGve9Visible && giaList.filter((exam) => exam.formaGia === GiaType.gve9);
    const gve11 = isGve11Visible && giaList.filter((exam) => exam.formaGia === GiaType.gve11);
    const other =
      isOtherVisible &&
      giaList.filter((exam) => [GiaType.writing, GiaType.narration, GiaType.interviewRus].includes(exam.formaGia));

    const giaResult = {
      oge: oge || [],
      ege: ege || [],
      gve9: gve9 || [],
      gve11: gve11 || [],
      other: other || [],
    };

    const returnValue: ICommonResponseObject<typeof giaResult> = {
      ...studentGiaList,
      content: giaResult,
    };

    return returnValue;
  },
);

export default studyGiaSelector;
