import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconCheckmarkCircleOutline: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="m14.705 8.395-3.784 4.969-1.633-2.087a.999.999 0 1 0-1.575 1.232l2.43 3.107a1 1 0 0 0 .789.383h.007a.994.994 0 0 0 .788-.394l4.569-6a.999.999 0 1 0-1.591-1.21ZM12 20c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8Zm0-18C6.477 2 2 6.478 2 12s4.477 10 10 10 10-4.478 10-10S17.523 2 12 2Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconCheckmarkCircleOutline;
