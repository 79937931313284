import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const headerContainer = (checked?: boolean): SxStyles => ({
  display: 'flex',
  gap: '12px',

  paddingBlock: '20px',
  paddingInline: '24px',

  borderBottom: checked ? `1px solid ${NeutralColors.light_neutrals_300}` : '1px solid transparent',
  borderRadius: '12px',

  background: NeutralColors.light_neutrals_0,
});
