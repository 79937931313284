/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const RecommendationWaveBackground: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="220" height="89" viewBox="0 0 220 89" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M-261 0s146.823 40.132 246 40.132C84.177 40.132 220 0 220 0v89h-481V0Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default RecommendationWaveBackground;
