type RewardNameKind =
  | 'WINNER'
  | 'AWARDEE'
  | 'LAUREATE'
  | 'OTHER'
  | 'FIRST'
  | 'SECOND'
  | 'THIRD'
  | 'VOLONTEER'
  | 'ACTIVIST'
  | 'ECOLOG'
  | 'ДИПЛОМ_1'
  | 'ДИПЛОМ_2'
  | 'ДИПЛОМ_3'
  | 'ДИПЛОМ'
  | 'ПОХВАЛЬНАЯ_ГРАМОТА'
  | 'УЧАСТНИК';

export const RewardNames: Record<RewardNameKind, string> = {
  WINNER: 'Победитель',
  AWARDEE: 'Призер',
  LAUREATE: 'Лауреат',
  OTHER: 'Другое',
  FIRST: '1-е место',
  SECOND: '2-е место',
  THIRD: '3-е место',
  VOLONTEER: 'Волонтер',
  ACTIVIST: 'Активист',
  ECOLOG: 'Эколог',
  ДИПЛОМ_1: 'Диплом I степени',
  ДИПЛОМ_2: 'Диплом II степени',
  ДИПЛОМ_3: 'Диплом III степени',
  ДИПЛОМ: 'Диплом',
  ПОХВАЛЬНАЯ_ГРАМОТА: 'Похвальная грамота',
  УЧАСТНИК: 'Участник',
};

export enum SportRewardKeys {
  GOLD_GTO = 'goldGto',
  SILVER_GTO = 'silverGto',
  BRONZE_GTO = 'bronzeGto',
  INTERNATIONAL_MASTER = 'internationalMaster',
  RUSSIA_MASTER = 'russiaMaster',
  GROSS_MASTER = 'grossMaster',
  CANDIDATE = 'candidate',
  FIRST_CATEGORY = 'firstCategory',
  SECOND_CATEGORY = 'secondCategory',
  THIRD_CATEGORY = 'thirdCategory',
  FIRST_JUNIOR_CATEGORY = 'firstJuniorCategory',
  SECOND_JUNIOR_CATEGORY = 'secondJuniorCategory',
  THIRD_JUNIOR_CATEGORY = 'thirdJuniorCategory',
  FIRST_ADVENTURE = 'firstAdventure',
  JUNIOR_ADVENTURER_1 = 'juniorAdventurer1',
  JUNIOR_ADVENTURER_2 = 'juniorAdventurer2',
  JUNIOR_ADVENTURER_3 = 'juniorAdventurer3',
  JUNIOR_ADVENTURER_4 = 'juniorAdventurer4',
  JUNIOR_ADVENTURER_5 = 'juniorAdventurer5',
  JUNIOR_ADVENTURER_6 = 'juniorAdventurer6',
  JUNIOR_ADVENTURER_7 = 'juniorAdventurer7',
  JUNIOR_ADVENTURER_8 = 'juniorAdventurer8',
  JUNIOR_ADVENTURER_9 = 'juniorAdventurer9',
  JUNIOR_TOURIST_1 = 'juniorTourist1',
  JUNIOR_TOURIST_2 = 'juniorTourist2',
  JUNIOR_TOURIST_3 = 'juniorTourist3',
  GOLD = 'gold',
  SILVER = 'silver',
  BRONZE = 'bronze',
  WINNER = 'winner',
  PRIZER = 'prizer',
}

export const SportRewardValues: Record<string, string> = {
  [SportRewardKeys.GOLD_GTO]: 'Золотой значок ГТО',
  [SportRewardKeys.SILVER_GTO]: 'Серебряный значок ГТО',
  [SportRewardKeys.BRONZE_GTO]: 'Бронзовый значок ГТО',
  [SportRewardKeys.INTERNATIONAL_MASTER]: 'Мастер спорта России международного класса',
  [SportRewardKeys.RUSSIA_MASTER]: 'Мастер спорта России',
  [SportRewardKeys.GROSS_MASTER]: 'Гроссмейстер России',
  [SportRewardKeys.CANDIDATE]: 'Кандидат в мастера спорта',
  [SportRewardKeys.FIRST_CATEGORY]: 'Первый спортивный разряд',
  [SportRewardKeys.SECOND_CATEGORY]: 'Второй спортивный разряд',
  [SportRewardKeys.THIRD_CATEGORY]: 'Третий спортивный разряд',
  [SportRewardKeys.FIRST_JUNIOR_CATEGORY]: 'Первый юношеский спортивный разряд',
  [SportRewardKeys.SECOND_JUNIOR_CATEGORY]: 'Второй юношеский спортивный разряд',
  [SportRewardKeys.THIRD_JUNIOR_CATEGORY]: 'Третий юношеский спортивный разряд',
  [SportRewardKeys.FIRST_ADVENTURE]: 'Первый поход',
  [SportRewardKeys.JUNIOR_ADVENTURER_1]: 'Юный путешественник 1 ступени',
  [SportRewardKeys.JUNIOR_ADVENTURER_2]: 'Юный путешественник 2 ступени',
  [SportRewardKeys.JUNIOR_ADVENTURER_3]: 'Юный путешественник 3 ступени',
  [SportRewardKeys.JUNIOR_ADVENTURER_4]: 'Юный путешественник 4 ступени',
  [SportRewardKeys.JUNIOR_ADVENTURER_5]: 'Юный путешественник 5 ступени',
  [SportRewardKeys.JUNIOR_ADVENTURER_6]: 'Юный путешественник 6 ступени',
  [SportRewardKeys.JUNIOR_ADVENTURER_7]: 'Юный путешественник 7 ступени',
  [SportRewardKeys.JUNIOR_ADVENTURER_8]: 'Юный путешественник 8 ступени',
  [SportRewardKeys.JUNIOR_ADVENTURER_9]: 'Юный путешественник 9 ступени',
  [SportRewardKeys.JUNIOR_TOURIST_1]: 'Юный турист 1 степени',
  [SportRewardKeys.JUNIOR_TOURIST_2]: 'Юный турист 2 степени',
  [SportRewardKeys.JUNIOR_TOURIST_3]: 'Юный турист 3 степени',
  [SportRewardKeys.GOLD]: 'Золото',
  [SportRewardKeys.SILVER]: 'Серебро',
  [SportRewardKeys.BRONZE]: 'Бронза',
  [SportRewardKeys.WINNER]: 'Победитель',
  [SportRewardKeys.PRIZER]: 'Призер',
};

export const SportRewardCodes: Record<string, number> = {
  [SportRewardKeys.GOLD_GTO]: 1,
  [SportRewardKeys.SILVER_GTO]: 2,
  [SportRewardKeys.BRONZE_GTO]: 3,
  [SportRewardKeys.INTERNATIONAL_MASTER]: 4,
  [SportRewardKeys.RUSSIA_MASTER]: 5,
  [SportRewardKeys.GROSS_MASTER]: 6,
  [SportRewardKeys.CANDIDATE]: 7,
  [SportRewardKeys.FIRST_CATEGORY]: 8,
  [SportRewardKeys.SECOND_CATEGORY]: 9,
  [SportRewardKeys.THIRD_CATEGORY]: 10,
  [SportRewardKeys.FIRST_JUNIOR_CATEGORY]: 11,
  [SportRewardKeys.SECOND_JUNIOR_CATEGORY]: 12,
  [SportRewardKeys.THIRD_JUNIOR_CATEGORY]: 13,
  [SportRewardKeys.FIRST_ADVENTURE]: 14,
  [SportRewardKeys.JUNIOR_ADVENTURER_1]: 15,
  [SportRewardKeys.JUNIOR_ADVENTURER_2]: 16,
  [SportRewardKeys.JUNIOR_ADVENTURER_3]: 17,
  [SportRewardKeys.JUNIOR_ADVENTURER_4]: 18,
  [SportRewardKeys.JUNIOR_ADVENTURER_5]: 19,
  [SportRewardKeys.JUNIOR_ADVENTURER_6]: 20,
  [SportRewardKeys.JUNIOR_ADVENTURER_7]: 21,
  [SportRewardKeys.JUNIOR_ADVENTURER_8]: 22,
  [SportRewardKeys.JUNIOR_ADVENTURER_9]: 23,
  [SportRewardKeys.JUNIOR_TOURIST_1]: 24,
  [SportRewardKeys.JUNIOR_TOURIST_2]: 25,
  [SportRewardKeys.JUNIOR_TOURIST_3]: 26,
  [SportRewardKeys.GOLD]: 27,
  [SportRewardKeys.SILVER]: 28,
  [SportRewardKeys.BRONZE]: 29,
  [SportRewardKeys.WINNER]: 30,
  [SportRewardKeys.PRIZER]: 31,
};
