import { ComponentType, FC, PropsWithChildren, useEffect } from 'react';
import { connect } from 'react-redux';

import { getAdminFunctionSettingsActions, getPersonsObjectsActions } from 'actions';
import { IChildInfo } from 'api/types';
import { getPersonsObjectsQueryParams } from 'const';
import { IRootState } from 'reducers';
import { AdminFunctionSettingsState } from 'reducers/admin/adminFunctionSettings';
import { hasContextUserInfoErrorSelector } from 'selectors';
import { shouldLoadCache } from 'utils';

interface IStudentDataMainProps {
  state: IRootState;
  currentStudent: IChildInfo;
  hasContextUserInfoError: boolean;
  adminFunctionSettings: AdminFunctionSettingsState;

  getPersonsObjects: typeof getPersonsObjectsActions.request;
  getAdminFunctionSettings: typeof getAdminFunctionSettingsActions.request;
}

const StudentDataMain: FC<IStudentDataMainProps & PropsWithChildren> = ({
  children,
  state,
  currentStudent,
  hasContextUserInfoError,
  adminFunctionSettings,

  getAdminFunctionSettings,
  getPersonsObjects,
}) => {
  const load = (key: keyof IRootState) => shouldLoadCache(state, key);

  useEffect(() => {
    const meshId = currentStudent.meshId;

    if (hasContextUserInfoError) return;
    if (!meshId) return;

    load('personsObjects') && getPersonsObjects(meshId, getPersonsObjectsQueryParams);

    if (!adminFunctionSettings.content) {
      load('adminFunctionSettings') && getAdminFunctionSettings('Search');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStudent.meshId]);

  return <>{children}</>;
};

const StudentDataMainRedux = connect(
  (state: IRootState) => ({
    state,
    currentStudent: state.currentStudent,
    hasContextUserInfoError: hasContextUserInfoErrorSelector(state),
    adminFunctionSettings: state.adminFunctionSettings,
  }),
  {
    getPersonsObjects: getPersonsObjectsActions.request,
    getAdminFunctionSettings: getAdminFunctionSettingsActions.request,
  },
)(StudentDataMain);

const withStudentDataMain = () => {
  return <P extends PropsWithChildren<unknown>>(WrappedComponent: ComponentType<P>): FC<P> => {
    return function WithStudentDataMain({ ...props }) {
      return (
        <StudentDataMainRedux>
          <WrappedComponent {...props} />
        </StudentDataMainRedux>
      );
    };
  };
};

export default withStudentDataMain;
