import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { IconChevronSmallDown, IconChevronSmallUp } from 'icons';

import * as styles from './styles';

interface IAdditionalInfoButtonProps {
  isOpen: boolean;
  onToggle: () => void;
}

const AdditionalInfoButton: FC<IAdditionalInfoButtonProps> = ({ isOpen, onToggle }) => {
  return (
    <Box component="button" onClick={onToggle} sx={styles.root}>
      <Typography sx={styles.label}>Дополнительная информация</Typography>
      {isOpen ? <IconChevronSmallUp sx={styles.icon} /> : <IconChevronSmallDown sx={styles.icon} />}
    </Box>
  );
};

export default AdditionalInfoButton;
