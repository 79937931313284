import { IStudentEvent, IStudentProject, IStudentReward, IStudentSportReward } from '../../api/types';
import { SectionCodes, StudentSectionSettingTypeCodes } from '../../const';
import {
  CivilColorTheme,
  CreationColorTheme,
  CultureColorTheme,
  ProfessionColorTheme,
  ScienceColorTheme,
  SportColorTheme,
  StudyColorTheme,
} from '../../const/sectionColors';
import {
  getRewardActivityIcon,
  getRewardCardIcon,
  getSportRewardActivityIcon,
  getSportRewardCardIcon,
} from '../../utils';

export const getSectionRibbonColor = (sectionCode: number) => {
  switch (sectionCode) {
    case SectionCodes.study:
      return StudyColorTheme.color_06;
    case SectionCodes.science:
      return ScienceColorTheme.color_06;
    case SectionCodes.sport:
      return SportColorTheme.color_06;
    case SectionCodes.creation:
      return CreationColorTheme.color_06;
    case SectionCodes.culture:
      return CultureColorTheme.color_06;
    case SectionCodes.civil:
      return CivilColorTheme.color_06;
    case SectionCodes.profession:
      return ProfessionColorTheme.color_06;
    default:
      return '#e8e8ef';
  }
};

export const getSectionBorderColor = (sectionCode: number) => {
  switch (sectionCode) {
    case SectionCodes.study:
      return StudyColorTheme.color_05;
    case SectionCodes.science:
      return ScienceColorTheme.color_05;
    case SectionCodes.sport:
      return SportColorTheme.color_05;
    case SectionCodes.creation:
      return CreationColorTheme.color_05;
    case SectionCodes.culture:
      return CultureColorTheme.color_05;
    case SectionCodes.civil:
      return CivilColorTheme.color_05;
    case SectionCodes.profession:
      return ProfessionColorTheme.color_05;
    default:
      return '#e8e8ef';
  }
};

export const getSportRewardImage = (rewardTypeCode: number | null | undefined, isSmall = false) => {
  return isSmall ? getSportRewardActivityIcon(rewardTypeCode) : getSportRewardCardIcon(rewardTypeCode);
};

export const getRewardImage = (rewardName: string, isSmall = false) => {
  return isSmall ? getRewardActivityIcon(rewardName) : getRewardCardIcon(rewardName);
};

export const getSectionCodeRewardSettingSubsectionTypeCode = (sectionCode: number) => {
  switch (sectionCode) {
    case SectionCodes.study:
      // для учебы нет отдельного раздела с наградами
      return StudentSectionSettingTypeCodes.studyOlympiad;
    case SectionCodes.science:
      return StudentSectionSettingTypeCodes.scienceReward;
    case SectionCodes.sport:
      return StudentSectionSettingTypeCodes.sportReward;
    case SectionCodes.creation:
      return StudentSectionSettingTypeCodes.creationReward;
    case SectionCodes.civil:
      return StudentSectionSettingTypeCodes.civilReward;
    case SectionCodes.profession:
      return StudentSectionSettingTypeCodes.professionReward;
    default:
      return undefined;
  }
};

export const isRewardSport = (
  reward: IStudentReward | IStudentSportReward | undefined,
): reward is IStudentSportReward => {
  return reward !== undefined && (reward as IStudentSportReward).sportRewardCode !== undefined;
};

export const isStudentEntityProject = (
  entity: IStudentEvent | IStudentProject | undefined,
): entity is IStudentProject => {
  return entity !== undefined && (entity as IStudentProject).projectFormat !== undefined;
};

export const isStudentEntityEvent = (entity: IStudentEvent | IStudentProject | undefined): entity is IStudentEvent => {
  return entity !== undefined && (entity as IStudentEvent).levelEvent !== undefined;
};
