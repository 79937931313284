import { FC, PropsWithChildren, ReactNode, useRef } from 'react';

import { Box, Typography } from '@mui/material';
import { useMeasureMultiline } from 'hooks';
import { mergeSx } from 'utils';

import * as styles from './styles';

interface IPrimaryInfoboxProps {
  icon: ReactNode;
}

const PrimaryInfobox: FC<IPrimaryInfoboxProps & PropsWithChildren> = ({ icon, children }) => {
  const textRef = useRef(null);
  const { isMultiline } = useMeasureMultiline(textRef.current);

  return (
    <Box className="primary-infobox" sx={styles.root}>
      {icon}
      <Typography
        variant="Paragraph MD/Regular"
        sx={mergeSx({ alignSelf: 'flex-start' }, !isMultiline && { marginTop: '4px' })}
        ref={textRef}
      >
        {children}
      </Typography>
    </Box>
  );
};

export default PrimaryInfobox;
