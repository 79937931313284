import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root: SxStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '16px 20px',

  border: '1px solid',
  borderRadius: '8px',
  borderColor: NeutralColors.light_neutrals_300,
  backgroundColor: NeutralColors.light_neutrals_0,
  boxShadow: '0px 1px 2px 0px rgba(41, 41, 64, 0.07)',
};

export const leftContainer: SxStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '200px',
};
