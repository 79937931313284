import { menuItemClasses, styled } from '@mui/material';
import { getAccentColor, NeutralColors } from 'portfolio3/styles';

import BaseMenuItem from '../BaseMenuItem';

const StyledCheckboxMenuItem = styled(BaseMenuItem)(() => {
  return {
    [`&&&.${menuItemClasses.root}.${menuItemClasses.selected}`]: {
      backgroundColor: getAccentColor('indigo', '8'),
      color: NeutralColors.light_neutrals_900,
      fontWeight: 400,
    },

    [`
    &&&.${menuItemClasses.root}:hover.${menuItemClasses.selected},
    &&&.${menuItemClasses.focusVisible}.${menuItemClasses.selected}
    `]: {
      backgroundColor: getAccentColor('indigo', '8'),
    },
  };
});

export default StyledCheckboxMenuItem;
