import { FC } from 'react';

import { styled } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';

interface IBarProps {
  percent: number;
  color: string;
}

const StyledBar = styled('div')(() => {
  return {
    width: '100%',
    height: '4px',

    borderRadius: '4px',
    backgroundColor: NeutralColors.light_neutrals_300,
  };
});

const StyledBarBackground = styled('div')<IBarProps>(({ percent, color }) => {
  return {
    width: `${percent}%`,
    height: '4px',

    borderRadius: '4px',
    backgroundColor: color,
  };
});

const Bar: FC<IBarProps> = ({ percent, color }) => {
  return (
    <StyledBar>
      <StyledBarBackground percent={percent} color={color} />
    </StyledBar>
  );
};

export default Bar;
