import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const Draftman: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="230" height="129" viewBox="0 0 230 129" xmlns="http://www.w3.org/2000/svg" {...props} fill="none">
    {/*eslint-disable max-len */}
    <path
      d="M158.783 116.992H94.706a.398.398 0 0 1-.208-.059.35.35 0 0 1-.137-.154.296.296 0 0 1-.025-.197.315.315 0 0 1 .107-.173l64.077-57.975a.418.418 0 0 1 .406-.075c.07.026.129.07.171.128a.326.326 0 0 1 .062.194v57.968c0 .091-.04.178-.11.242a.393.393 0 0 1-.266.101Zm-63.137-.686h62.791V59.505l-62.791 56.801Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M150.327 107.043h-31.569a.391.391 0 0 1-.21-.056.339.339 0 0 1-.136-.157.325.325 0 0 1 .068-.37l31.606-29.91a.388.388 0 0 1 .191-.098.41.41 0 0 1 .218.016.372.372 0 0 1 .171.124.326.326 0 0 1 .067.19V106.7a.32.32 0 0 1-.032.138.35.35 0 0 1-.089.115.394.394 0 0 1-.285.09Zm-30.72-.686h30.344V77.599l-30.344 28.758ZM138.691 81.71a.38.38 0 0 1-.263-.104l-2.194-2.01a.33.33 0 0 1-.111-.244.33.33 0 0 1 .111-.243c.07-.065.166-.101.266-.101s.196.036.267.1l2.187 1.998a.333.333 0 0 1 .112.243.319.319 0 0 1-.112.244.35.35 0 0 1-.117.084.385.385 0 0 1-.146.032ZM130.672 89.03a.403.403 0 0 1-.271-.102l-2.187-1.997a.329.329 0 0 1-.11-.244c0-.091.039-.179.11-.243a.397.397 0 0 1 .267-.101c.1 0 .196.036.267.1l2.187 1.997c.035.032.063.07.082.112a.318.318 0 0 1-.082.376.393.393 0 0 1-.263.103ZM123.373 95.686a.393.393 0 0 1-.263-.103l-2.188-1.996a.313.313 0 0 1-.084-.371.38.38 0 0 1 .207-.184.406.406 0 0 1 .411.074l2.187 1.997c.069.064.108.15.108.24s-.039.176-.108.24a.401.401 0 0 1-.27.103ZM115.353 103.008a.362.362 0 0 1-.142-.023.338.338 0 0 1-.121-.073l-2.187-1.997a.345.345 0 0 1-.082-.112.317.317 0 0 1 .082-.375.368.368 0 0 1 .263-.104c.05 0 .098.009.144.027a.354.354 0 0 1 .119.077l2.188 1.996c.035.032.063.07.082.112a.32.32 0 0 1-.082.376.364.364 0 0 1-.264.096ZM147.44 73.715a.429.429 0 0 1-.263-.096l-2.187-2.004a.322.322 0 0 1-.114-.24.322.322 0 0 1 .114-.24.376.376 0 0 1 .122-.075.41.41 0 0 1 .412.075l2.187 1.997a.336.336 0 0 1 .108.243.336.336 0 0 1-.108.244.43.43 0 0 1-.271.096ZM132.062 74.14a.404.404 0 0 1-.271-.102l-4.765-4.364a.334.334 0 0 1-.113-.247.321.321 0 0 1 .113-.24l23.917-21.841a.395.395 0 0 1 .263-.098c.098 0 .193.035.263.098l4.788 4.37c.069.065.107.15.107.24s-.038.177-.107.24l-23.932 21.842a.4.4 0 0 1-.263.103Zm-4.285-4.713 4.255 3.883 23.398-21.36-4.254-3.884-23.399 21.36Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M129.883 74.84a4.02 4.02 0 0 1-2.084-.58 3.524 3.524 0 0 1-1.381-1.538 3.155 3.155 0 0 1-.213-1.98c.144-.664.5-1.275 1.024-1.755a.45.45 0 0 1 .428-.09c.047.015.091.039.129.07l2.367 2.21 2.39 2.181a.313.313 0 0 1 .085.371.339.339 0 0 1-.085.11c-.349.318-.764.57-1.221.742-.456.172-.945.26-1.439.26Zm-2.353-5.132a2.595 2.595 0 0 0-.646 1.709c0 .513.158 1.016.455 1.451.298.436.724.786 1.228 1.012a3.26 3.26 0 0 0 1.629.266 3.176 3.176 0 0 0 1.536-.561l-2.112-1.928-2.09-1.95ZM155.995 52.293a.395.395 0 0 1-.271-.103l-4.78-4.364a.345.345 0 0 1-.092-.139.33.33 0 0 1 .055-.311.374.374 0 0 1 .134-.106l9.358-4.165a.4.4 0 0 1 .429.061.31.31 0 0 1 .067.392l-4.555 8.543a.339.339 0 0 1-.114.124.383.383 0 0 1-.164.061l-.067.007Zm-4.165-4.611 4.037 3.685 3.863-7.212-7.9 3.527Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M159.256 45.74a.403.403 0 0 1-.271-.103l-.864-.789a.345.345 0 0 1-.082-.112.318.318 0 0 1 .082-.375.39.39 0 0 1 .526 0l.872.796a.336.336 0 0 1 .1.174.31.31 0 0 1-.021.197.35.35 0 0 1-.136.153.403.403 0 0 1-.206.059ZM168.111 64.974a.412.412 0 0 1-.158 0 .37.37 0 0 1-.196-.196.32.32 0 0 1 .015-.264l4.142-7.919a.37.37 0 0 1 .214-.157.41.41 0 0 1 .275.017.36.36 0 0 1 .187.184c.035.08.035.17 0 .251l-4.141 7.885a.348.348 0 0 1-.137.145.394.394 0 0 1-.201.053ZM210.525 64.974a.398.398 0 0 1-.2-.055.356.356 0 0 1-.138-.144l-4.141-7.919a.314.314 0 0 1 0-.251.36.36 0 0 1 .187-.184.41.41 0 0 1 .275-.018c.09.026.167.083.214.158l4.142 7.94a.33.33 0 0 1 .018.262.348.348 0 0 1-.191.197.488.488 0 0 1-.166.014ZM191.186 15.844a.396.396 0 0 1-.266-.1.33.33 0 0 1-.11-.243v-1.709a1.73 1.73 0 0 0-.614-1.194 2.067 2.067 0 0 0-1.344-.486c-.499 0-.98.174-1.343.486-.364.312-.584.74-.615 1.194v1.709a.327.327 0 0 1-.11.242.394.394 0 0 1-.266.1.393.393 0 0 1-.265-.1.327.327 0 0 1-.11-.242v-1.709a2.39 2.39 0 0 1 .842-1.667 2.856 2.856 0 0 1 1.867-.68c.695 0 1.364.244 1.868.68.504.437.805 1.034.842 1.667v1.709a.33.33 0 0 1-.11.242.396.396 0 0 1-.266.1Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M207.564 62.442a3.4 3.4 0 0 1-1.746-.48 2.985 2.985 0 0 1-1.17-1.277l-16.175-34.418a.315.315 0 0 1-.005-.264.357.357 0 0 1 .2-.19.412.412 0 0 1 .289-.004c.093.034.167.1.207.183l16.175 34.425a2.28 2.28 0 0 0 1.056 1.088 2.612 2.612 0 0 0 1.568.25c.194-.026.384-.077.563-.15a2.324 2.324 0 0 0 1.291-1.213 2.04 2.04 0 0 0-.02-1.69l-16.364-34.85a.321.321 0 0 1-.002-.26.362.362 0 0 1 .198-.187.404.404 0 0 1 .412.068c.036.032.064.07.084.11L210.458 58.4a2.67 2.67 0 0 1 .029 2.22c-.313.713-.924 1.285-1.698 1.588a3.36 3.36 0 0 1-1.225.234Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M170.868 62.654c-.402 0-.8-.073-1.172-.213a3.12 3.12 0 0 1-.669-.33 2.918 2.918 0 0 1-1.202-1.54 2.662 2.662 0 0 1 .097-1.89l15.476-35.29a.345.345 0 0 1 .08-.112.407.407 0 0 1 .532-.007.341.341 0 0 1 .114.241.302.302 0 0 1-.027.132l-15.476 35.283c-.201.461-.227.97-.075 1.446.153.477.476.893.917 1.182.161.1.332.185.511.254a2.642 2.642 0 0 0 1.854-.024 2.323 2.323 0 0 0 1.295-1.211l15.161-34.638a.374.374 0 0 1 .2-.189.412.412 0 0 1 .414.068.34.34 0 0 1 .084.11.304.304 0 0 1 .001.265l-15.161 34.638c-.236.538-.644 1-1.171 1.325-.526.325-1.147.5-1.783.5Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M188.75 26.493a6.883 6.883 0 0 1-3.573-.993c-1.058-.645-1.882-1.563-2.37-2.636a5.408 5.408 0 0 1-.368-3.393 5.74 5.74 0 0 1 1.756-3.01 6.648 6.648 0 0 1 3.291-1.613 7.015 7.015 0 0 1 3.718.324c1.177.441 2.185 1.19 2.896 2.154a5.527 5.527 0 0 1 1.099 3.259 5.44 5.44 0 0 1-.484 2.26 5.873 5.873 0 0 1-1.397 1.916 6.519 6.519 0 0 1-2.095 1.282 6.982 6.982 0 0 1-2.473.45Zm0-11.075a6.081 6.081 0 0 0-3.162.88 5.33 5.33 0 0 0-2.092 2.338 4.778 4.778 0 0 0-.315 3.004 5.07 5.07 0 0 0 1.567 2.657 5.882 5.882 0 0 0 2.921 1.414c1.106.197 2.25.09 3.289-.308a5.6 5.6 0 0 0 2.546-1.925c.621-.858.95-1.865.944-2.893-.01-1.373-.615-2.688-1.682-3.656-1.068-.968-2.511-1.51-4.016-1.51Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M188.75 23.09a2.902 2.902 0 0 1-1.504-.416 2.545 2.545 0 0 1-1-1.107 2.281 2.281 0 0 1-.159-1.428c.102-.48.358-.921.734-1.268a2.8 2.8 0 0 1 1.382-.685 2.955 2.955 0 0 1 1.565.13c.496.183.922.497 1.224.9.302.404.466.88.472 1.369.004.328-.064.652-.198.956a2.48 2.48 0 0 1-.586.813 2.75 2.75 0 0 1-.884.544 2.942 2.942 0 0 1-1.046.191Zm0-4.262c-.388 0-.768.105-1.09.303a1.835 1.835 0 0 0-.723.804 1.65 1.65 0 0 0-.11 1.036c.076.348.264.667.539.917s.625.42 1.006.488c.381.069.776.032 1.134-.104.359-.137.664-.367.879-.663a1.676 1.676 0 0 0-.248-2.26 1.982 1.982 0 0 0-.636-.387 2.12 2.12 0 0 0-.751-.134ZM187.202 49.85a.396.396 0 0 1-.266-.1.33.33 0 0 1-.11-.243.336.336 0 0 1 .112-.24c.07-.064.165-.101.264-.103 3.067 0 7.697 0 10.23-2.779a.401.401 0 0 1 .53-.045.337.337 0 0 1 .134.233.324.324 0 0 1-.086.251c-2.758 3.026-7.599 3.026-10.808 3.026ZM183.677 48.814a.332.332 0 0 1-.12 0 18.423 18.423 0 0 1-4.164-1.832.354.354 0 0 1-.149-.224.33.33 0 0 1 .059-.256.387.387 0 0 1 .244-.139c.099-.015.2.005.282.057a18.058 18.058 0 0 0 3.968 1.742.376.376 0 0 1 .215.177c.043.082.05.176.018.263a.34.34 0 0 1-.139.157.397.397 0 0 1-.214.055Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M185.849 52.067h-1.465a1.106 1.106 0 0 1-.737-.282.923.923 0 0 1-.308-.672v-3.898a.922.922 0 0 1 .308-.672c.196-.178.46-.28.737-.281h1.465c.277 0 .543.1.739.28a.913.913 0 0 1 .306.673v3.898c0 .253-.11.495-.306.674-.196.18-.462.28-.739.28Zm-1.465-5.12a.316.316 0 0 0-.206.081.261.261 0 0 0-.087.188v3.897c.001.07.033.137.087.187.055.05.128.079.206.08h1.465a.313.313 0 0 0 .205-.08.258.258 0 0 0 .088-.187v-3.898a.258.258 0 0 0-.088-.187.314.314 0 0 0-.205-.08h-1.465ZM206.353 75.136a.393.393 0 0 1-.265-.1.327.327 0 0 1-.11-.243c0-.091.039-.178.11-.243.07-.064.166-.1.265-.1 2.73.005 5.393-.774 7.612-2.226 2.218-1.453 3.88-3.506 4.749-5.869.87-2.362.903-4.915.096-7.295-.808-2.381-2.415-4.47-4.595-5.97a.324.324 0 0 1-.071-.484.405.405 0 0 1 .53-.065c2.303 1.585 4.002 3.79 4.857 6.304a11.52 11.52 0 0 1-.089 7.708c-.914 2.497-2.664 4.67-5.003 6.21s-5.149 2.37-8.033 2.373h-.053ZM92.36 104.902H14.372V16.55h77.99v88.352Zm-77.418-.522H91.79V17.072H14.942v87.308Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M92.076 99.584H14.657v.515h77.419v-.515ZM92.076 95.467H14.657v.514h77.419v-.514ZM92.076 91.35H14.657v.514h77.419v-.514ZM92.076 87.233H14.657v.514h77.419v-.514ZM92.076 83.116H14.657v.514h77.419v-.514ZM92.076 78.999H14.657v.515h77.419v-.515ZM92.076 74.882H14.657v.514h77.419v-.514ZM92.076 70.765H14.657v.514h77.419v-.514ZM92.076 66.647H14.657v.515h77.419v-.515ZM92.076 62.53H14.657v.515h77.419v-.514ZM92.076 58.414H14.657v.514h77.419v-.514ZM92.45 54.297H15.032v.515h77.419v-.515ZM92.45 50.18H15.032v.514h77.419v-.514ZM92.45 46.063H15.032v.514h77.419v-.514ZM92.45 41.945H15.032v.515h77.419v-.515ZM92.45 37.829H15.032v.514h77.419v-.514ZM92.45 33.711H15.032v.515h77.419v-.515ZM92.45 29.595H15.032v.514h77.419v-.514ZM92.45 25.477H15.032v.515h77.419v-.515ZM92.45 21.36H15.032v.515h77.419v-.515Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M87.099 17.154h-.564v87.83h.564v-87.83ZM82.59 17.154h-.565v87.83h.564v-87.83ZM78.08 17.154h-.564v87.83h.563v-87.83ZM73.57 17.154h-.564v87.83h.564v-87.83ZM69.06 17.154h-.564v87.83h.564v-87.83ZM64.55 17.154h-.564v87.83h.564v-87.83ZM60.04 17.154h-.563v87.83h.563v-87.83ZM55.53 17.154h-.563v87.83h.564v-87.83ZM51.02 17.154h-.563v87.83h.564v-87.83ZM46.511 17.154h-.564v87.83h.564v-87.83ZM42.001 17.154h-.563v87.83H42v-87.83ZM37.492 17.154h-.564v87.144h.564V17.154ZM32.982 17.154h-.564v87.144h.564V17.154ZM28.472 17.154h-.564v87.144h.564V17.154ZM23.962 17.154h-.564v87.144h.564V17.154ZM19.452 17.154h-.563v87.144h.563V17.154ZM115.075 64.17a.715.715 0 0 1-.275-.05.656.656 0 0 1-.228-.149l-6.84-7.184a.542.542 0 0 1-.151-.487.675.675 0 0 1 .256-.425l3.405-2.69c-3.791-4.3-5.601-9.778-5.05-15.274.551-5.497 3.421-10.583 8.004-14.184 4.572-3.584 10.474-5.436 16.494-5.173 6.021.263 11.702 2.62 15.879 6.587l3.39-2.69a.764.764 0 0 1 .776-.114c.084.036.16.086.223.149l6.848 7.177a.59.59 0 0 1 .134.228.541.541 0 0 1 .016.26.67.67 0 0 1-.256.425l-42.091 33.423a.793.793 0 0 1-.496.171h-.038Zm-6.764-7.788 6.764 7.089 42.092-33.403-6.765-7.088-3.916 3.094-.248-.24c-4.017-3.936-9.547-6.3-15.431-6.6-5.883-.3-11.666 1.49-16.138 4.994-4.48 3.522-7.267 8.51-7.759 13.888-.492 5.379 1.35 10.719 5.129 14.87l.233.26-3.961 3.136Zm42.091-33.416-.256-.254.256.254Zm-35.327 24.949-.225-.364c-1.938-3.103-2.639-6.724-1.983-10.246.655-3.522 2.627-6.727 5.579-9.072 2.952-2.345 6.703-3.684 10.614-3.79 3.912-.106 7.743 1.028 10.842 3.21l.36.253-25.187 20.009Zm3.849-19.213c-2.76 2.201-4.623 5.19-5.284 8.48-.662 3.291-.085 6.69 1.638 9.642l23.774-18.904c-2.966-1.96-6.57-2.948-10.228-2.802-3.659.146-7.155 1.416-9.923 3.605l.023-.02Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="m114.718 58.329-.567.45 2.985 3.132.567-.45-2.985-3.132ZM119.425 56.943l-.568.45 1.707 1.792.567-.45-1.706-1.792ZM121.796 53.106l-.567.45 2.984 3.132.567-.45-2.984-3.132ZM126.504 51.736l-.567.45 1.706 1.792.567-.45-1.706-1.792ZM128.651 47.66l-.567.45 2.984 3.133.567-.45-2.984-3.132ZM133.364 46.28l-.567.45 1.707 1.79.567-.45-1.707-1.79ZM135.516 42.216l-.567.45 2.985 3.132.567-.45-2.985-3.132ZM140.218 40.834l-.567.45 1.707 1.791.567-.45-1.707-1.791ZM142.862 36.37l-.567.45 2.984 3.132.568-.45-2.985-3.133ZM149.237 31.308l-.567.45 2.984 3.133.568-.45-2.985-3.133ZM147.572 34.995l-.567.45 1.707 1.792.567-.45-1.707-1.792ZM57.53 99.186l-14.807-4.152 4.051-5.489-14.055-16.57 24.638-12.88 18.558 7.98.752 18.382L57.53 99.186Zm-12.432-4.92 12.147 3.43 17.858-11.925-.691-16.811-16.987-7.321-22.459 11.74L48.623 89.49l-3.525 4.776ZM36.018 59.82l-16.4-.48 10.364-13.366H43.34L55.95 50.68l-16.115 4.618-3.818 4.522Zm-13.53-1.77 12.838.37 3.66-4.302 12.576-3.609-8.524-3.163H30.756L22.488 58.05ZM85.528 53.521H65.625L49.54 22.987h30.396l5.592 30.534ZM66.572 52.15h17.175l-5.089-27.79H51.93l14.642 27.79Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default Draftman;
