/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

const IconFirstAdventure: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="50" height="50" fill="#E5E5E5"/>
    <rect width="1924" height="1397" transform="translate(-1042 -1085)" fill="white"/>
    <path d="M25 48C37.7025 48 48 37.7025 48 25C48 12.2975 37.7025 2 25 2C12.2975 2 2 12.2975 2 25C2 37.7025 12.2975 48 25 48Z" fill="#748FFC"/>
    <path d="M25 38.1506C32.2628 38.1506 38.1505 32.2629 38.1505 25C38.1505 17.7371 32.2628 11.8494 25 11.8494C17.7371 11.8494 11.8494 17.7371 11.8494 25C11.8494 32.2629 17.7371 38.1506 25 38.1506Z" fill="#F1ECFF"/>
    <path d="M28.9641 7.8244C29.0161 7.60537 29.1103 7.39857 29.2415 7.21558C29.5358 6.81295 29.9431 6.50686 30.4118 6.33617C30.9917 6.10582 31.6154 6.00649 32.2382 6.04529C32.5629 6.04529 32.9147 6.08588 33.2123 6.13323C33.5133 6.18244 33.8114 6.24793 34.1053 6.3294C34.3962 6.41058 34.6803 6.51205 34.9712 6.60676H35.0388C35.0106 6.65995 34.9765 6.70986 34.9373 6.75558C34.7467 6.99043 34.5181 7.19165 34.2609 7.35088C33.9801 7.51528 33.6771 7.63831 33.3612 7.71617C32.9866 7.80817 32.6034 7.86032 32.2179 7.87176C31.6587 7.87176 31.0972 7.87176 30.5335 7.87176C30.0668 7.87176 29.6 7.8244 29.1332 7.79735L28.9641 7.8244Z" fill="#5B7DE8"/>
    <path d="M32.9553 9.00823C32.9921 8.93691 33.0351 8.86899 33.0839 8.80529C33.4014 8.34411 33.8872 8.02592 34.4368 7.91911C34.7018 7.86531 34.9714 7.83812 35.2418 7.83794C35.5111 7.84357 35.7789 7.87988 36.04 7.94617C36.3146 7.99892 36.584 8.07589 36.845 8.17617C37.1109 8.27029 37.3688 8.38567 37.6162 8.52117C37.9612 8.71058 38.2927 8.92029 38.6309 9.12323L38.685 9.17058C38.6253 9.22853 38.562 9.28275 38.4956 9.33294C38.0675 9.64101 37.5609 9.8216 37.0344 9.85382C36.4001 9.8959 35.7633 9.8249 35.1539 9.64411C34.43 9.46147 33.7197 9.23823 32.9959 9.03529L32.9553 9.00823Z" fill="#5B7DE8"/>
    <path d="M41.5125 17.1124C41.9098 17.0466 42.3174 17.0838 42.6963 17.2206C43.2306 17.4136 43.6996 17.7536 44.0492 18.2015C44.3158 18.5373 44.5428 18.9028 44.7257 19.2906C44.902 19.6699 45.0381 20.0667 45.1316 20.4744C45.2195 20.8397 45.2804 21.205 45.348 21.5635C45.3514 21.5973 45.3514 21.6313 45.348 21.665C45.1463 21.6485 44.9493 21.5958 44.7663 21.5094C44.4479 21.3539 44.152 21.1559 43.8869 20.9209C43.5788 20.6412 43.3082 20.3229 43.0819 19.9738C42.5106 19.0741 42.0064 18.1335 41.5733 17.1597C41.5495 17.149 41.5286 17.1328 41.5125 17.1124Z" fill="#5B7DE8"/>
    <path d="M41.9659 18.9591C42.647 19.7879 43.0691 20.7987 43.1798 21.8657C43.2905 22.9326 43.0847 24.0086 42.5883 24.9594C41.9756 24.1093 41.5723 23.1266 41.4112 22.0912C41.2172 21.0144 41.4139 19.9038 41.9659 18.9591Z" fill="#5B7DE8"/>
    <path d="M41.5803 12.4244C41.3721 12.5529 41.1433 12.6444 40.9039 12.695C40.2858 12.8141 39.6464 12.7506 39.0639 12.5123C38.3582 12.2359 37.679 11.8963 37.0345 11.4976C36.7639 11.3421 36.5 11.1797 36.2024 10.97C36.2401 10.9539 36.2745 10.931 36.3039 10.9023C36.6877 10.5235 37.1991 10.3016 37.738 10.28C38.3926 10.2545 39.0401 10.4241 39.5983 10.7671C40.3164 11.1757 40.9574 11.7069 41.4924 12.3365L41.5803 12.4244Z" fill="#5B7DE8"/>
    <path d="M43.6233 16.2465C43.4338 16.2465 43.2647 16.3006 43.0956 16.3141C42.737 16.3491 42.3752 16.2981 42.0403 16.1653C41.8274 16.0831 41.6195 15.9883 41.418 15.8812C40.8919 15.575 40.4076 15.2021 39.9771 14.7718L38.9083 13.7435L38.8203 13.6421C39.1285 13.4114 39.498 13.2773 39.8824 13.2565C40.2406 13.24 40.5985 13.295 40.9352 13.4181C41.272 13.5412 41.581 13.73 41.8441 13.9735C42.1055 14.1983 42.354 14.4377 42.5883 14.6906C42.9813 15.1357 43.3197 15.6262 43.5962 16.1518C43.6026 16.184 43.6117 16.2157 43.6233 16.2465Z" fill="#5B7DE8"/>
    <path d="M32.3465 12.1876H32.2991C31.5562 11.6853 30.8528 11.1267 30.1953 10.5168C29.7173 10.0743 29.347 9.52816 29.113 8.92028C29.0063 8.64615 28.949 8.35528 28.9438 8.06117V7.96646L29.1265 8.00705C29.9402 8.23529 30.701 8.62145 31.3656 9.14352C31.7831 9.46326 32.1116 9.88486 32.3194 10.3679C32.5575 10.9236 32.5816 11.5476 32.3871 12.12L32.3465 12.1876Z" fill="#5B7DE8"/>
    <path d="M34.89 13.9329C34.1831 13.1523 33.5867 12.2782 33.1177 11.3353C32.8346 10.7324 32.7357 10.0594 32.8336 9.40058C32.8336 9.30588 32.8742 9.21794 32.8945 9.12323H32.9486C33.7387 9.57092 34.4075 10.2049 34.8968 10.97C35.1917 11.4226 35.347 11.9519 35.3433 12.4921C35.3346 12.9727 35.1917 13.4413 34.9306 13.845L34.89 13.9329Z" fill="#5B7DE8"/>
    <path d="M39.1379 21.6176C39.1853 21.3132 39.2191 21.0291 39.2665 20.745C39.3471 20.1964 39.4738 19.6555 39.6453 19.1282C39.8917 18.3213 40.4424 17.642 41.1809 17.2341L41.4041 17.1326C41.4041 17.207 41.4041 17.2612 41.4447 17.322C41.6087 18.1516 41.5668 19.0086 41.3229 19.8182C41.2223 20.1749 41.044 20.5048 40.8007 20.7844C40.5574 21.0639 40.2552 21.2861 39.9159 21.435C39.669 21.54 39.4058 21.6018 39.1379 21.6176Z" fill="#5B7DE8"/>
    <path d="M36.8788 16.2059C36.7638 15.9691 36.6623 15.7662 36.5676 15.5632C36.2566 14.9151 36.0184 14.2345 35.8573 13.5338C35.6912 12.7951 35.7647 12.0227 36.067 11.3285C36.089 11.2754 36.1139 11.2234 36.1414 11.1729V11.1391L36.1888 11.1729C36.805 11.7744 37.2688 12.5142 37.5417 13.3309C37.6862 13.7291 37.7352 14.1558 37.6846 14.5764C37.6341 14.997 37.4855 15.3999 37.2508 15.7526C37.1764 15.8744 37.0141 16.0165 36.8788 16.2059Z" fill="#5B7DE8"/>
    <path d="M38.7458 13.6826C38.9014 14.0074 39.0434 14.305 39.1787 14.6094C39.2469 14.7621 39.3012 14.9206 39.3411 15.0829C39.4233 15.3881 39.4888 15.6974 39.5373 16.0097C39.6233 16.5269 39.5573 17.058 39.3471 17.5384C39.1369 18.0188 38.7917 18.4278 38.3534 18.7156L38.2655 18.7629C38.1978 18.4788 38.137 18.2082 38.0828 17.9309C37.9408 17.2822 37.8728 16.6196 37.8799 15.9556C37.8949 15.2001 38.143 14.4677 38.5902 13.8585C38.6392 13.7974 38.6911 13.7387 38.7458 13.6826Z" fill="#5B7DE8"/>
    <path d="M20.8533 7.8244C20.7989 7.60498 20.7024 7.39821 20.5692 7.21558C20.2856 6.81654 19.8902 6.51051 19.4327 6.33617C18.8528 6.10582 18.2291 6.00649 17.6063 6.04529C17.2815 6.04529 16.9298 6.08588 16.6389 6.13323C16.3377 6.18101 16.0395 6.24652 15.746 6.3294C15.4551 6.41058 15.1642 6.51205 14.8733 6.60676H14.8057C14.8385 6.65866 14.8746 6.70838 14.9139 6.75558C15.1147 6.99338 15.355 7.19477 15.6242 7.35088C15.905 7.51528 16.208 7.63831 16.5239 7.71617C16.8962 7.80775 17.2771 7.8599 17.6604 7.87176C18.2241 7.87176 18.7856 7.87176 19.3448 7.87176C19.8115 7.87176 20.2783 7.8244 20.7451 7.79735L20.8533 7.8244Z" fill="#5B7DE8"/>
    <path d="M16.8822 9.00823C16.8455 8.93691 16.8025 8.86899 16.7537 8.80529C16.4336 8.3467 15.949 8.02927 15.4008 7.91911C15.1335 7.86535 14.8616 7.83816 14.589 7.83794C14.3197 7.84403 14.0519 7.88033 13.7908 7.94617C13.5164 7.99991 13.2471 8.07686 12.9858 8.17617C12.7204 8.27157 12.4626 8.3869 12.2146 8.52117C11.8696 8.71058 11.5381 8.92029 11.2066 9.12323L11.1458 9.17058C11.2078 9.22866 11.2733 9.28288 11.3419 9.33294C11.769 9.64302 12.2762 9.82383 12.8031 9.85382C13.4352 9.89582 14.0697 9.8248 14.6769 9.64411C15.4008 9.46147 16.1178 9.23823 16.8349 9.03529L16.8822 9.00823Z" fill="#5B7DE8"/>
    <path d="M8.29793 17.1123C7.9028 17.0465 7.49737 17.0838 7.12087 17.2206C6.58457 17.4099 6.11464 17.7506 5.76793 18.2015C5.49895 18.5356 5.27178 18.9014 5.09146 19.2906C4.92519 19.6719 4.79607 20.0683 4.70587 20.4744C4.61793 20.8397 4.56381 21.205 4.49616 21.5635C4.49282 21.5973 4.49282 21.6313 4.49616 21.665C4.69568 21.6484 4.89052 21.5957 5.07116 21.5094C5.39524 21.3613 5.69787 21.1702 5.97087 20.9412C6.27705 20.6597 6.54742 20.3416 6.77587 19.9941C7.34711 19.0944 7.85132 18.1538 8.2844 17.18C8.29261 17.1583 8.29718 17.1355 8.29793 17.1123Z" fill="#5B7DE8"/>
    <path d="M7.85134 18.9591C7.17403 19.7906 6.75694 20.8033 6.65226 21.8705C6.54758 22.9378 6.75995 24.0122 7.26281 24.9594C7.87192 24.1074 8.27485 23.1255 8.43987 22.0912C8.61663 21.0105 8.40834 19.902 7.85134 18.9591Z" fill="#5B7DE8"/>
    <path d="M8.23022 12.4244C8.43926 12.5513 8.66784 12.6427 8.9067 12.695C9.53995 12.8237 10.1972 12.7601 10.794 12.5123C11.4791 12.2227 12.1378 11.8741 12.7626 11.4706C13.0468 11.3094 13.3223 11.1333 13.5879 10.9429C13.5511 10.9252 13.5169 10.9024 13.4864 10.8753C13.1033 10.4953 12.5915 10.2732 12.0523 10.2529C11.3979 10.2295 10.751 10.3989 10.192 10.74C9.47601 11.1488 8.83722 11.68 8.30464 12.3094L8.23022 12.4244Z" fill="#5B7DE8"/>
    <path d="M6.19409 16.2465C6.37674 16.2465 6.54586 16.3006 6.71497 16.3141C7.07358 16.3496 7.43547 16.2986 7.77027 16.1653C7.9843 16.0857 8.19229 15.9908 8.39262 15.8812C8.92084 15.5751 9.4074 15.2022 9.84027 14.7718L10.9023 13.7435L10.997 13.642C10.694 13.4162 10.3323 13.2823 9.95527 13.2565C9.59372 13.2373 9.23201 13.2909 8.89155 13.4141C8.55109 13.5372 8.23881 13.7275 7.97321 13.9735C7.71644 14.1987 7.47252 14.4381 7.24262 14.6906C6.84537 15.1324 6.50661 15.6235 6.23468 16.1518C6.2177 16.1817 6.20408 16.2135 6.19409 16.2465Z" fill="#5B7DE8"/>
    <path d="M17.471 12.1876H17.5184C18.2521 11.6771 18.9442 11.1094 19.5884 10.4897C20.0685 10.0476 20.4411 9.50154 20.6775 8.89323C20.781 8.61817 20.8381 8.32786 20.8466 8.03411C20.8495 8.00261 20.8495 7.97091 20.8466 7.93941L20.6707 7.98C19.8559 8.20537 19.0945 8.59181 18.4316 9.11647C18.0139 9.4377 17.6836 9.85873 17.471 10.3409C17.2384 10.8981 17.2167 11.5209 17.4102 12.0929C17.4273 12.1264 17.4477 12.1582 17.471 12.1876Z" fill="#5B7DE8"/>
    <path d="M14.9206 13.9329C15.6295 13.1538 16.226 12.2795 16.693 11.3353C16.9738 10.7317 17.0703 10.0587 16.9703 9.40058C16.9565 9.30682 16.9362 9.21415 16.9094 9.12323H16.8621C16.0686 9.56891 15.3972 10.2032 14.9071 10.97C14.6119 11.4221 14.4588 11.9522 14.4674 12.4921C14.4761 12.9727 14.619 13.4413 14.88 13.845C14.8874 13.8768 14.9012 13.9067 14.9206 13.9329Z" fill="#5B7DE8"/>
    <path d="M10.6791 21.6176C10.6318 21.3132 10.5912 21.0291 10.5438 20.745C10.4665 20.1958 10.3397 19.6546 10.165 19.1282C9.92158 18.3218 9.37307 17.6422 8.6362 17.2341L8.41297 17.1326C8.41297 17.207 8.37914 17.2612 8.37238 17.322C8.20287 18.1507 8.24239 19.0086 8.48738 19.8182C8.58829 20.1757 8.76752 20.5063 9.01206 20.786C9.25661 21.0656 9.56033 21.2873 9.9012 21.435C10.1477 21.5415 10.4111 21.6033 10.6791 21.6176Z" fill="#5B7DE8"/>
    <path d="M12.9384 16.2059L13.2428 15.5632C13.556 14.9153 13.7965 14.2347 13.9598 13.5338C14.1226 12.7949 14.0493 12.0235 13.7501 11.3285L13.669 11.1729V11.1391L13.6216 11.1729C13.0039 11.7732 12.5399 12.5134 12.2687 13.3309C12.1242 13.7291 12.0752 14.1558 12.1258 14.5764C12.1763 14.997 12.3249 15.3999 12.5596 15.7526C12.661 15.8744 12.8234 16.0165 12.9384 16.2059Z" fill="#5B7DE8"/>
    <path d="M11.0716 13.6826C10.916 14.0074 10.7739 14.305 10.6386 14.6094C10.5682 14.7619 10.5116 14.9204 10.4695 15.0829C10.3863 15.3874 10.323 15.697 10.2801 16.0097C10.194 16.5269 10.2601 17.058 10.4703 17.5384C10.6804 18.0188 11.0257 18.4278 11.4639 18.7156L11.5519 18.7629C11.6128 18.4788 11.6804 18.2082 11.7345 17.9309C11.8743 17.282 11.9401 16.6193 11.9307 15.9556C11.9291 15.2032 11.6926 14.47 11.2542 13.8585L11.0716 13.6826Z" fill="#5B7DE8"/>
    <path d="M9.4411 29.4309L10.6182 32.2585L6.97198 33.7941L6.62698 32.9756L9.56286 31.7647L9.06904 30.5741L6.13316 31.7647L5.79492 30.9462L9.4411 29.4309Z" fill="white"/>
    <path d="M10.9699 32.8674L12.3634 34.8968L11.7546 35.3229L10.8616 34.0241L10.1852 34.4706L10.9428 35.5665L10.334 35.9926L9.56956 34.89L8.82545 35.4041L9.75898 36.7571L9.17045 37.1765L7.73633 35.1065L10.9699 32.8674Z" fill="white"/>
    <path d="M12.783 35.3974L13.7571 36.3309C14.3794 36.9262 14.6703 37.6297 14.0074 38.3197C13.8733 38.4576 13.7115 38.5653 13.5326 38.6357C13.3536 38.7061 13.1618 38.7375 12.9697 38.7279C12.7777 38.7183 12.5899 38.6679 12.4189 38.58C12.2479 38.4921 12.0976 38.3688 11.978 38.2182L11.633 37.88L10.713 38.8406L10.0703 38.225L12.783 35.3974ZM12.4312 37.6703C12.783 38.0085 13.1077 38.0153 13.3783 37.7176C13.6488 37.42 13.5744 37.1359 13.2294 36.8044L12.9385 36.5L12.1471 37.3726L12.4312 37.6703Z" fill="white"/>
    <path d="M15.2658 37.6771L16.409 38.3535C17.0855 38.7797 17.5252 39.3074 17.1261 39.9432C17.0384 40.0963 16.9077 40.2203 16.7501 40.2997C16.5925 40.3791 16.4151 40.4104 16.2399 40.3897C16.3968 40.5365 16.4955 40.735 16.5179 40.9488C16.5402 41.1625 16.4848 41.3771 16.3617 41.5532C15.9422 42.2297 15.1778 42.1824 14.454 41.7291L13.2161 40.9647L15.2658 37.6771ZM14.7381 41.1541C15.1508 41.4112 15.4552 41.3977 15.6378 41.0932C15.8205 40.7888 15.6852 40.5521 15.2725 40.295L14.8531 40.0379L14.3187 40.8971L14.7381 41.1541ZM15.5296 39.6997C15.8949 39.9229 16.1519 39.8688 16.3008 39.6253C16.4496 39.3818 16.3481 39.1653 15.9964 38.9488L15.6378 38.7324L15.1778 39.4832L15.5296 39.6997Z" fill="white"/>
    <path d="M18.1947 39.4832L19.0403 39.7471L18.6209 41.1L18.9929 41.215C19.8385 41.4721 20.3459 41.9929 20.1091 42.8182C19.8723 43.6435 19.0809 43.86 18.2353 43.603L17.0447 43.2647L18.1947 39.4832ZM18.4044 42.9197C18.8441 43.055 19.1553 42.9806 19.2703 42.5882C19.3853 42.1959 19.1891 42.0268 18.7156 41.9118L18.4179 41.8238L18.1 42.8588L18.4044 42.9197ZM21.3673 40.4574L22.2197 40.7212L21.0629 44.4688L20.2173 44.2118L21.3673 40.4574Z" fill="white"/>
    <path d="M22.9707 40.7685H23.8501L23.8095 42.1215C23.7837 42.618 23.7295 43.1126 23.6472 43.6029L24.0531 42.7506L25.1354 40.8226H26.0757L25.9742 44.7462H25.0948L25.1354 43.3932C25.1598 42.9174 25.2095 42.4432 25.2842 41.9726L24.8784 42.8182L23.796 44.7462H22.8557L22.9707 40.7685ZM23.5254 39.632H24.2695C24.2695 39.8688 24.371 40.0176 24.5875 40.0244C24.8039 40.0312 24.8987 39.8823 24.9189 39.6456H25.6631C25.6292 40.1191 25.3451 40.4641 24.5739 40.4438C23.8028 40.4235 23.5187 40.1123 23.5051 39.632H23.5254Z" fill="white"/>
    <path d="M27.8411 40.6265L30.7905 39.7944L31.8525 43.5691L31.0002 43.8127L30.1343 40.7482L28.8964 41.1L29.7352 44.1577L28.8828 44.4012L27.8411 40.6265Z" fill="white"/>
    <path d="M32.2451 41.3165C31.6228 40.1935 31.8934 39.1991 32.8134 38.685C33.7334 38.1709 34.7345 38.4618 35.3569 39.5779C35.9793 40.6941 35.7222 41.7156 34.8022 42.2365C33.8822 42.7574 32.8675 42.4327 32.2451 41.3165ZM34.5587 40.0177C34.1798 39.3412 33.659 39.0909 33.2057 39.3412C32.7525 39.5915 32.6645 40.1732 33.0501 40.8565C33.4357 41.5397 33.9701 41.8103 34.4031 41.5329C34.836 41.2556 34.9443 40.7077 34.5587 40.0177Z" fill="white"/>
    <path d="M36.4459 38.5971L34.4165 37.8327L35.1471 37.1765L35.8912 37.5147C36.0806 37.5891 36.2768 37.6838 36.5203 37.7988C36.4256 37.5553 36.358 37.3524 36.2971 37.1629L36.04 36.3985L36.7165 35.7762L37.2306 37.9138L39.348 38.685L38.6039 39.3615L37.853 38.9759L37.1765 38.685C37.2644 38.9285 37.3321 39.1382 37.3997 39.3615L37.65 40.2071L36.9736 40.8294L36.4459 38.5971Z" fill="white"/>
    <path d="M38.5295 36.7638C37.4945 36.0129 37.3321 34.9915 37.9545 34.1391C38.5768 33.2868 39.5983 33.1379 40.6604 33.8888C41.7224 34.6397 41.8915 35.6815 41.2692 36.5338C40.6468 37.3862 39.5577 37.5215 38.5295 36.7638ZM40.0921 34.6262C39.463 34.1594 38.888 34.1594 38.5701 34.6262C38.2521 35.0929 38.4348 35.6003 39.0707 36.0671C39.7065 36.5338 40.3018 36.5474 40.6198 36.1212C40.9377 35.695 40.7212 35.0862 40.0854 34.6262H40.0921Z" fill="white"/>
    <path d="M40.7481 30.6756L40.4572 31.3521L41.4719 32.0285C42.4325 32.705 42.8858 33.2597 42.6558 33.8482L42.0943 33.7941C42.0943 33.6115 41.8643 33.2935 41.0593 32.7524C40.4775 32.3668 40.1461 32.1571 39.5034 31.7512L40.4031 29.6L43.7855 31.0138L43.4405 31.8324L40.7481 30.6756ZM44.0899 33.855L43.7855 34.5856L42.534 34.1459L42.0267 33.9294L43.5217 30.3577L44.0358 30.5674L45.2264 31.1491L44.9219 31.8797L43.8734 31.44L43.0481 33.4153L44.0899 33.855Z" fill="white"/>
    <path opacity="0.5" d="M43.0552 11.0715C40.1438 7.42 36.1855 4.746 31.7112 3.40806C27.237 2.07013 22.4605 2.13223 18.0225 3.58604C13.5846 5.03986 9.69718 7.81587 6.88172 11.5418C4.06627 15.2677 2.45736 19.7654 2.27075 24.4318C5.66775 26.8138 9.57252 28.3724 13.676 28.9844C19.8961 29.9157 26.2456 28.6322 31.6155 25.3581C36.9854 22.084 41.0344 17.0273 43.0552 11.0715Z" fill="url(#paint0_linear)"/>
    <path d="M33.5234 24.6212L24.9998 23.0788L16.4763 24.6212L24.9998 26.1635L33.5234 24.6212Z" fill="#A9BEFF"/>
    <path d="M31.0274 30.6485L26.0891 23.5321L18.9727 18.5938L23.9109 25.7103L31.0274 30.6485Z" fill="#A9BEFF"/>
    <path d="M25.0001 33.1447L26.5425 24.6212L25.0001 16.0976L23.4578 24.6212L25.0001 33.1447Z" fill="#A9BEFF"/>
    <path d="M33.537 16.0841L23.4576 23.0788L16.4629 33.1583L26.5423 26.1703L33.537 16.0841Z" fill="#E83F40"/>
    <path d="M16.4629 33.1583L26.5423 26.1703L33.537 16.0841" fill="#CE2E36"/>
    <path d="M25.0001 25.9065C25.71 25.9065 26.2854 25.331 26.2854 24.6212C26.2854 23.9113 25.71 23.3359 25.0001 23.3359C24.2903 23.3359 23.7148 23.9113 23.7148 24.6212C23.7148 25.331 24.2903 25.9065 25.0001 25.9065Z" fill="white"/>
    <rect x="-977.5" y="-560.5" width="1768" height="796" rx="4.5" stroke="#7B61FF" strokeDasharray="10 5"/>
    <defs>
    <linearGradient id="paint0_linear" x1="17.1396" y1="7.39824" x2="27.2055" y2="26.2582" gradientUnits="userSpaceOnUse">
    <stop stopColor="white" stopOpacity="0"/>
    <stop offset="0.35" stopColor="white" stopOpacity="0.01"/>
    <stop offset="0.49" stopColor="white" stopOpacity="0.03"/>
    <stop offset="0.59" stopColor="white" stopOpacity="0.08"/>
    <stop offset="0.67" stopColor="white" stopOpacity="0.15"/>
    <stop offset="0.74" stopColor="white" stopOpacity="0.24"/>
    <stop offset="0.8" stopColor="white" stopOpacity="0.35"/>
    <stop offset="0.85" stopColor="white" stopOpacity="0.47"/>
    <stop offset="0.9" stopColor="white" stopOpacity="0.62"/>
    <stop offset="0.95" stopColor="white" stopOpacity="0.79"/>
    <stop offset="1" stopColor="white"/>
    </linearGradient>
    </defs>
  </svg>
);

export default IconFirstAdventure;
