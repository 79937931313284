/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useEffect, useState } from 'react';

import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import clsx from 'clsx';
import { useMediaQuery } from '@mui/material';

import { IDragElements, IInterest, TContainerKeys } from '../types';
import CheckerDropContainer from './dropContainer';
import { generateListWithNewElement, generateNewListOnClick } from '../utils';
import { initialSubmitDialogData, ISubmitDialogData, getDraggableItem } from './utils';
import InterestsFooter from '../footer';
import { SubmitDialog, ViewDialog } from '../../../common/Dialog';
import { superMeshikPortrait } from '../../../../images';

import './index.scss';

interface IInterestsCheckerProps {
  isWebView?: boolean,
  dragElements: IDragElements,
  selectedInterests: IInterest[],
  setDragElements: React.Dispatch<React.SetStateAction<IDragElements>>,
  onChangeStepForward: () => void,
  onChangeStepBack: () => void
}

const InterestsChecker: React.FC<IInterestsCheckerProps> = ({
  isWebView,
  dragElements,
  selectedInterests,
  setDragElements,
  onChangeStepBack,
  onChangeStepForward
}) => {
  const dropContainerKeys: TContainerKeys[] = ['insipid', 'sipid', 'unknown'];
  const isMobile = useMediaQuery('(max-width: 767px)', { noSsr: true });

  const [isMobileInfoOpen, setMobileInfoOpen] = useState(false);
  const [submitDialogData, setSubmitDialogData] = useState<ISubmitDialogData>(initialSubmitDialogData);

  const handleCloseMobileInfo = () => {
    setMobileInfoOpen(false);
  };

  const handleCloseSubmitDialog = () => {
    setSubmitDialogData(initialSubmitDialogData);
  };

  const handleOpenSubmitDialog = (newAction: () => void) => {
    setSubmitDialogData({
      open: true,
      action: () => {
        setSubmitDialogData(initialSubmitDialogData);
        newAction();
      }
    });
  };

  useEffect(() => {
    if (isMobile) {
      setMobileInfoOpen(true);
    } else if (isMobileInfoOpen) {
      handleCloseMobileInfo();
    }
  }, [isMobile]);

  const shouldInterestsChange = (sourceIndex: string) => {
    const item = getDraggableItem(dragElements.sipid, Number(sourceIndex));
    const selectedIds = selectedInterests.map((interest) => interest.headId);
    return selectedIds?.includes(Number(item?.id));
  };

  const onDragEnd = (result: DropResult) => {
    if (shouldInterestsChange(result.source.droppableId)) {
      handleOpenSubmitDialog(() => setDragElements(generateListWithNewElement(dragElements, result, false)));
      return;
    }
    setDragElements(generateListWithNewElement(dragElements, result, false));
  };

  const moveElementOnClick = (sourceIndex: number, sourceId: TContainerKeys, destinationId: TContainerKeys) => {
    const shouldAddOnBottom = true;
    if (shouldInterestsChange(sourceId)) {
      handleOpenSubmitDialog(() => setDragElements(generateNewListOnClick(dragElements, sourceId, sourceIndex, destinationId, shouldAddOnBottom)));
      return;
    }
    setDragElements(generateNewListOnClick(dragElements, sourceId, sourceIndex, destinationId, shouldAddOnBottom));
  };

  return (
    <section className={clsx('interests-checker', { 'interests-checker--webview': isWebView })}>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="interests-checker__content">
          <h3 className="interests-checker__title">Давай проверим, все ли верно</h3>
          <p className="interests-checker__description">Изменить положение карточки можно, перетащив ее в нужный столбик.</p>
          <div className="interests-checker__dnd-container">
            <CheckerDropContainer
              title="Неинтересно"
              prefix={dropContainerKeys[0]}
              elements={dragElements[dropContainerKeys[0]]}
              onMoveElement={moveElementOnClick}
            />
            <CheckerDropContainer
              title="Интересно"
              prefix={dropContainerKeys[1]}
              elements={dragElements[dropContainerKeys[1]]}
              onMoveElement={moveElementOnClick}
            />
            <CheckerDropContainer
              title="Не знаю"
              prefix={dropContainerKeys[2]}
              elements={dragElements[dropContainerKeys[2]]}
              onMoveElement={moveElementOnClick}
            />
          </div>
          <InterestsFooter onSubmit={onChangeStepForward} onBack={onChangeStepBack} submitDisabled={dragElements.sipid.length === 0} />
        </div>
      </DragDropContext>
      {isMobile && (
      <ViewDialog
        isOpen={isMobileInfoOpen}
        onClose={handleCloseMobileInfo}
        title=""
        className="info-dialog"
      >
        <div className="dialog-content">
          <img src={superMeshikPortrait} alt="кот" />
          <h5>Давай проверим все ли верно</h5>
          <span>
            Проверь все ли карточки правильно разложены по стопкам. Ты можешь изменить положение карточки, используя стрелки «вверх» и «вниз».
          </span>
        </div>
      </ViewDialog>
      )}
      <SubmitDialog
        isOpen={submitDialogData.open}
        onClose={handleCloseSubmitDialog}
        title=""
        className="info-dialog"
        submitButtonTitle="Окей"
        onSubmit={submitDialogData.action}
      >
        <div className="dialog-content">
          <img src={superMeshikPortrait} alt="кот" />
          <h5>Внимание</h5>
          <span>
            При изменении категории удалится прогресс!
          </span>
        </div>
      </SubmitDialog>
    </section>
  );
};

export default InterestsChecker;
