import { FC, useContext } from 'react';

import { Box } from '@mui/material';
import { IDictionaryItem } from 'api/types';
import { ChangeHistoryTabs, DifferentCode, HistoryActionTypeCodes, HistoryActionTypeValues } from 'const';
import { ChangeHistoryContext } from 'context';
import { useAppSelector } from 'hooks';
import { DayRangePicker, DayRangePickerController } from 'portfolio3/ui-kit/datePickers';
import { Select } from 'portfolio3/ui-kit/selects';
import { IController } from 'portfolio3/ui-kit/types';
import { availableEmployeeParentSectionsSelector } from 'selectors';

const actionTypes: IDictionaryItem[] = [
  { code: HistoryActionTypeCodes.ALL, value: HistoryActionTypeValues.ALL },
  { code: HistoryActionTypeCodes.POST, value: HistoryActionTypeValues.POST },
  { code: HistoryActionTypeCodes.DELETE, value: HistoryActionTypeValues.DELETE },
  { code: HistoryActionTypeCodes.PUT, value: HistoryActionTypeValues.PUT },
];

interface IChangeHistoryTableFiltersProps {
  historyType: ChangeHistoryTabs;
}

const ChangeHistoryTableFilters: FC<IChangeHistoryTableFiltersProps> = ({ historyType }) => {
  const portfolioSections = useAppSelector(availableEmployeeParentSectionsSelector);

  const { currentFilters, setCurrentFilters } = useContext(ChangeHistoryContext);
  const { startDate, endDate } = currentFilters;

  const modifiedPortfolioSections = {
    loading: portfolioSections.loading,
    content:
      portfolioSections.content.length > 1
        ? [{ code: DifferentCode, value: 'Все разделы' }, ...portfolioSections.content]
        : portfolioSections.content,
  };

  const dateRangeController: DayRangePickerController = {
    handleChange(value) {
      setCurrentFilters((prevState) => {
        value.end?.setHours(23, 59, 59);
        return {
          ...prevState,
          startDate: value.start,
          endDate: value.end,
          page: 0,
        };
      });
    },
    handleClear() {
      setCurrentFilters((prevState) => {
        return {
          ...prevState,
          startDate: null,
          endDate: null,
          page: 0,
        };
      });
    },
  };

  const actionTypeController: IController<string | undefined> = {
    handleChange: (actionType) =>
      setCurrentFilters({
        ...currentFilters,
        actionType: Number(actionType),
        page: 0,
      }),
  };

  const categoryController: IController<string | undefined> = {
    handleChange: (category) =>
      setCurrentFilters({
        ...currentFilters,
        category: Number(category),
        page: 0,
      }),
  };

  return (
    <Box sx={{ display: 'flex', gap: '12px' }}>
      <Box sx={{ minWidth: '280px' }}>
        <DayRangePicker
          renderMode="fixed"
          size="small"
          startValue={startDate ?? null}
          endValue={endDate ?? null}
          controller={dateRangeController}
        />
      </Box>
      <Box sx={{ minWidth: '300px' }}>
        <Select
          inputRenderMode="fixed"
          inputSize="small"
          placeholder="Выберите категорию"
          value={currentFilters.category}
          options={modifiedPortfolioSections.content}
          controller={categoryController}
        />
      </Box>
      {historyType === ChangeHistoryTabs.manual && (
        <Box sx={{ minWidth: '300px' }}>
          <Select
            inputRenderMode="fixed"
            inputSize="small"
            placeholder="Выберите тип действия"
            value={currentFilters.actionType}
            options={actionTypes}
            controller={actionTypeController}
          />
        </Box>
      )}
    </Box>
  );
};

export default ChangeHistoryTableFilters;
