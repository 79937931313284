/* eslint-disable prettier/prettier */
/* eslint-disable */

import { ToastOptions } from 'react-toastify';

export const tooltipOptions: ToastOptions = {
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: false,
  pauseOnHover: false,
  draggable: true,
  progress: undefined
};
