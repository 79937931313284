import { industryIllustrations } from '../assets';

export const industryCodes = {
  ['безопасность']: 1,
  ['гостеприимство']: 2,
  ['ИТ']: 3,
  ['креативные индустрии']: 4,
  ['образование и соц.сфера']: 5,
  ['промышленность']: 6,
  ['строительство']: 7,
  ['торговля и финансы']: 8,
  ['транспорт']: 9,
  ['здравоохранение']: 10,
} as const;

export const IndustryCodeIllustration: Record<number, string> = {
  [industryCodes['безопасность']]: industryIllustrations.безопасность,
  [industryCodes['гостеприимство']]: industryIllustrations.гостеприимство,
  [industryCodes['ИТ']]: industryIllustrations.ит,
  [industryCodes['креативные индустрии']]: industryIllustrations.креативная_индустрия,
  [industryCodes['образование и соц.сфера']]: industryIllustrations.образование,
  [industryCodes['промышленность']]: industryIllustrations.промышленность,
  [industryCodes['строительство']]: industryIllustrations.строительство,
  [industryCodes['торговля и финансы']]: industryIllustrations.торговля_и_финансы,
  [industryCodes['транспорт']]: industryIllustrations.транспорт,
  [industryCodes['здравоохранение']]: industryIllustrations.здравоохранение,
};
