import { FAILURE, INVALIDATE, REQUEST, SUCCESS } from 'actions/utils';
import { ICommonResponse, ICommonResponseObject } from 'api/types';
import { IReducers } from 'reducers';
import genericReducers from 'reducers/genericReducer';
import { connectReducers } from 'utils';

import { IPerformanceLesson, IStudentPerformanceResponse } from '../types';
import { GET_STUDENT_PERFORMANCE_LESSONS_OLD, GET_STUDENT_PERFORMANCE_OLD } from './actions';

export type StudentPerformanceOldState = ICommonResponseObject<IStudentPerformanceResponse>;
export type StudentPerformanceLessonsOldState = ICommonResponse<IPerformanceLesson>;

const performanceInitialState: StudentPerformanceOldState = {
  content: {
    year: [],
  },
  loading: false,
  requestCache: 'notCached',
};

const performanceLessonsInitialState: StudentPerformanceLessonsOldState = {
  content: [],
  loading: false,
  requestCache: 'notCached',
};

const studentPerformanceReducers: IReducers<StudentPerformanceOldState> = {
  [GET_STUDENT_PERFORMANCE_OLD[REQUEST]]: genericReducers().get.request,
  [GET_STUDENT_PERFORMANCE_OLD[SUCCESS]]: genericReducers().get.success,
  [GET_STUDENT_PERFORMANCE_OLD[FAILURE]]: genericReducers().get.failure,
  [GET_STUDENT_PERFORMANCE_OLD[INVALIDATE]]: genericReducers().get.invalidate(performanceInitialState),
};

const studentPerformanceLessonsReducers: IReducers<StudentPerformanceLessonsOldState> = {
  [GET_STUDENT_PERFORMANCE_LESSONS_OLD[REQUEST]]: genericReducers().get.request,
  [GET_STUDENT_PERFORMANCE_LESSONS_OLD[SUCCESS]]: genericReducers().get.success,
  [GET_STUDENT_PERFORMANCE_LESSONS_OLD[FAILURE]]: genericReducers().get.failure,
  [GET_STUDENT_PERFORMANCE_LESSONS_OLD[INVALIDATE]]: genericReducers().get.invalidate(performanceLessonsInitialState),
};

const studentPerformanceOld = connectReducers(performanceInitialState, studentPerformanceReducers);
const studentPerformanceLessonsOld = connectReducers(performanceLessonsInitialState, studentPerformanceLessonsReducers);

export const collection = {
  studentPerformanceOld,
  studentPerformanceLessonsOld,
};
