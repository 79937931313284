import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const TransportSports: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    width="230"
    height="129"
    viewBox="0 0 230 129"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    fill="none"
    sx={{ fill: 'none' }}
  >
    {/*eslint-disable max-len */}
    <path
      d="m201.709 117.82 22-13.49-136.24-10.87s15.59 13.78 41.76 19c25.4 5.08 72.48 5.36 72.48 5.36Z"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M208.68 103s2.11-8.36-1.45-9c-3.56-.64-4.68 1.87-6.72 1.79-2.04-.08-1.46-2.1-1.46-2.1s-5.26-3-19.42-6.13c-22.2-4.86-32.89-4.59-32.89-4.59l-12 6.2s-26.56-1.54-47.26 4.32M215.6 109.39S162 105.8 147.93 98.6M208.999 113.35s-46.89-3.17-63.25-7.53-21.14-9.4-21.14-9.4"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M90.64 93.71s16.571 11.74 52.201 17.09c35.63 5.35 61.49 5.41 61.49 5.41M162.001 77.06a.15.15 0 0 1 .28 0 2.413 2.413 0 0 0 2.85 1.23 13.649 13.649 0 0 0 3.09-.86 8.048 8.048 0 0 0 1.38-.76 1.452 1.452 0 0 1 2.24.35c1 1.57-3.33 6.52-7.39 5.85-3.45-.58-2.82-4.48-2.45-5.81Z"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M165.16 84.75h-.22a5.222 5.222 0 0 1-4.35-5.43c0-4.79 1.59-9.17 7.55-8.94 4.53.17 5.82 2.49 5.86 6.06a8.291 8.291 0 0 1-2.609 6.082 8.284 8.284 0 0 1-6.231 2.228v0ZM208.679 103s-58.41-12-74.21-13.6M169.42 85.61l-19.17 5.91"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M173.309 79.8s2.65-4.69 4.7-4.61c1.36 0 9 1.41 13.86 2.31a6.3 6.3 0 0 1 5.06 5l2.12 11.15"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M182.12 88.14s.94-11.22-4.11-13M172.41 81.37s5.91 2 6.22 5.84M159.49 83.67a3.113 3.113 0 0 1 1.86-1.33M197.18 59.25l15.58-10.39-99-4.08s11.67 9.56 30.78 12.61c18.53 2.97 52.64 1.86 52.64 1.86Z"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M201.821 48.31s1.3-6.12-1.3-6.49c-2.6-.37-3.34 1.48-4.82 1.48-1.48 0-1.11-1.48-1.11-1.48s-3.9-2-14.24-3.9c-16.22-2.9-24-2.41-24-2.41l-8.53 4.83s-19.28-.38-34.11 4.44M207 52.76s-38.94-1.11-49.32-5.94M202.34 55.81s-34.05-1-46-3.69c-11.95-2.69-15.57-6.22-15.57-6.22"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M116.051 44.88s12.33 8 38.3 10.93a337.855 337.855 0 0 0 44.65 2.19M167.249 30.83a.11.11 0 0 1 .16-.047.115.115 0 0 1 .04.047 1.792 1.792 0 0 0 2.11.81 10.448 10.448 0 0 0 2.21-.71 6.024 6.024 0 0 0 1-.59 1 1 0 0 1 1.63.19c.74 1.11-2.23 4.82-5.19 4.45-2.54-.34-2.16-3.18-1.96-4.15Z"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M169.779 36.31h-.15a3.789 3.789 0 0 1-3.31-3.81c-.1-3.48.9-6.69 5.22-6.69 3.29 0 4.29 1.64 4.42 4.23a5.994 5.994 0 0 1-1.72 4.485 6.004 6.004 0 0 1-4.46 1.785ZM201.82 48.31s-42.65-7.05-54.14-7.79M172.89 36.81l-13.72 4.82"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M175.551 32.5s1.79-3.5 3.28-3.5c1 0 6.52.78 10.11 1.29a4.593 4.593 0 0 1 3.8 3.49l1.85 8"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M182.16 38.3s.38-8.16-3.33-9.28M174.941 33.66s4.34 1.25 4.67 4.06M165.641 35.69s.57-.93 1.32-1"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m46.89 61.38-11.81-7.79 2.76-5.66 16.73 6.18a9.15 9.15 0 0 1 5.19 7.79l1 19.6-3.83 12.2-3.25-2a11.59 11.59 0 0 1-5.41-11l1.33-13.62a6.102 6.102 0 0 0-2.71-5.7Z"
      fill="#fff"
      stroke={props.fill || 'currentColor'}
      strokeMiterlimit="10"
    />
    <path
      d="M57.46 26.86c6.142 0 11.12-4.979 11.12-11.12 0-6.141-4.978-11.12-11.12-11.12-6.141 0-11.12 4.979-11.12 11.12 0 6.141 4.979 11.12 11.12 11.12Z"
      stroke={props.fill || 'currentColor'}
      strokeMiterlimit="10"
    />
    <path
      d="M57.46 29.18c7.422 0 13.44-6.017 13.44-13.44 0-7.423-6.018-13.44-13.44-13.44-7.423 0-13.44 6.017-13.44 13.44 0 7.423 6.017 13.44 13.44 13.44ZM15.94 55.18c6.142 0 11.12-4.979 11.12-11.12 0-6.142-4.978-11.12-11.12-11.12-6.141 0-11.12 4.979-11.12 11.12 0 6.141 4.979 11.12 11.12 11.12ZM34 39.77a4.86 4.86 0 1 0 0-9.72 4.86 4.86 0 0 0 0 9.72Z"
      stroke={props.fill || 'currentColor'}
      strokeMiterlimit="10"
    />
    <path
      d="M57.46 18.75a3.01 3.01 0 1 0 0-6.02 3.01 3.01 0 0 0 0 6.02ZM15.94 57.5c7.423 0 13.44-6.017 13.44-13.44 0-7.423-6.017-13.44-13.44-13.44-7.423 0-13.44 6.017-13.44 13.44 0 7.423 6.017 13.44 13.44 13.44Z"
      stroke={props.fill || 'currentColor'}
      strokeMiterlimit="10"
    />
    <path
      d="M64.1 41.05a1.39 1.39 0 0 1-1-1l-6.77-24a1.38 1.38 0 0 1 1-1.71 1.391 1.391 0 0 1 1.72 1l6.77 24a1.39 1.39 0 0 1-1 1.71 1.37 1.37 0 0 1-.72 0Z"
      fill="#fff"
      stroke={props.fill || 'currentColor'}
      strokeMiterlimit="10"
    />
    <path
      d="M37.47 45.79a1.35 1.35 0 0 1-1-.71 1.4 1.4 0 0 1 .53-1.89c.62-.33 15.19-8.14 25.37-9.41a1.391 1.391 0 0 1 .35 2.76c-9.67 1.2-24.25 9-24.4 9.1a1.34 1.34 0 0 1-.85.15Z"
      fill="#fff"
      stroke={props.fill || 'currentColor'}
      strokeMiterlimit="10"
    />
    <path
      d="M35.17 35.22a1.507 1.507 0 0 1 .29-3l27.67 1a1.5 1.5 0 1 1-.11 3l-27.67-1h-.18Z"
      fill="#fff"
      stroke={props.fill || 'currentColor'}
      strokeMiterlimit="10"
    />
    <path
      d="M37 32.3a.89.89 0 0 1-.763-.956.898.898 0 0 1 .313-.614l6.22-5.36a.9.9 0 0 1 1.18 1.36l-6.22 5.36a.91.91 0 0 1-.73.21ZM29.55 37.92 27.3 52.38 31 53a1.31 1.31 0 0 0 1.5-1.46v-.38a6.35 6.35 0 0 1 1.34-4.77l2.63-1.45-1.61-7.75c-.37-.67.48-4.42-1.26-3.95l-1 .32a6.161 6.161 0 0 0-3.05 4.36Z"
      fill="#fff"
      stroke={props.fill || 'currentColor'}
      strokeMiterlimit="10"
    />
    <path
      d="m28.72 43.23-12.18-.02M28.75 46.05l-11.83-2.31M29.33 36.29l-12.34 6.99M29.09 34.85l-12.87 7.38"
      stroke={props.fill || 'currentColor'}
      strokeMiterlimit="10"
    />
    <path
      d="M40 28.05a1.8 1.8 0 0 1 .2-3.58l4.72-.2a1.802 1.802 0 0 1 .16 3.6l-4.72.2c-.12.009-.241.002-.36-.02ZM70.221 45.28a.71.71 0 0 1-.55-.43l-2.48-5.93a.695.695 0 0 1 1.28-.54l2.53 5.94a.7.7 0 0 1-.37.91.75.75 0 0 1-.41.05Z"
      fill="#fff"
      stroke={props.fill || 'currentColor'}
      strokeMiterlimit="10"
    />
    <path
      d="M64 41.49a1.24 1.24 0 0 1-1-1 1.5 1.5 0 0 1 .92-1.74l3.24-1a1.19 1.19 0 0 1 1.49 1 1.49 1.49 0 0 1-.93 1.73l-3.24 1a1 1 0 0 1-.48.01Z"
      fill="#fff"
      stroke={props.fill || 'currentColor'}
      strokeMiterlimit="10"
    />
    <path
      d="M77.26 65.79S83.89 64 89 66.67l.69 8.48s-5.32-6.85-12.43-9.36Z"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M88 81.86a4.07 4.07 0 0 1-4.77-3.42A42.49 42.49 0 0 0 77.93 66s11.9 7 12.48 11.65c.29 2.35-.57 3.69-2.41 4.21ZM85.7 65.42s3.38-5.3-.62-8.73c-4-3.43-10.66.21-10.66.21l6.45 7.86M75.12 58.42s-3.12 1.22-1.88 5.06"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M67.41 46.28a2.52 2.52 0 0 1 1.14-2.49c1.06-.66 2.51-1 3.53 1 1.79 3.56-2.5 8-2.5 8L65 51.45a4.79 4.79 0 0 0 2.41-5.17Z"
      fill="#fff"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m78.3 66.09-7.74-3.42A1.81 1.81 0 0 1 69.4 61l.18-8.08-6.46-1.71L62 62.81a7.85 7.85 0 0 0 2.43 6.47l4 3.81a12.121 12.121 0 0 1-8.15 5.36l-1.49.25-5.1 16.79s10.8 1.13 17.15-3.49c6.35-4.62 11.76-13.33 11.76-13.33"
      fill="#fff"
    />
    <path
      d="m78.3 66.09-7.74-3.42A1.81 1.81 0 0 1 69.4 61l.18-8.08-6.46-1.71L62 62.81a7.85 7.85 0 0 0 2.43 6.47l4 3.81a12.121 12.121 0 0 1-8.15 5.36l-1.49.25-5.1 16.79s10.8 1.13 17.15-3.49c6.35-4.62 11.76-13.33 11.76-13.33"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="m37.84 47.93-3.06-2.12M35.47 52.25l-3-1.13" stroke={props.fill || 'currentColor'} strokeMiterlimit="10" />
    <path
      d="M16.21 46.85a3.01 3.01 0 1 0 0-6.02 3.01 3.01 0 0 0 0 6.02Z"
      fill="#fff"
      stroke={props.fill || 'currentColor'}
      strokeMiterlimit="10"
    />
    <path
      d="m94 51.22 22 2M148 64.22l-37 3M90 106.22l-52 3M32 80.22l-22 1"
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M110.919 126.66a9.407 9.407 0 0 1-1.76 0 9.948 9.948 0 0 1-4.833-1.835 9.954 9.954 0 0 1-3.257-4.015 7.002 7.002 0 0 1-9.532-7.881 6.997 6.997 0 0 1 8.637-5.356 6.996 6.996 0 0 1 3.235 1.887 9.927 9.927 0 0 1 12.482-.805 9.93 9.93 0 0 1 2.968 3.345 9.514 9.514 0 0 1 1.77 0 9.678 9.678 0 0 1 1.72.34 8.69 8.69 0 0 1 15.09 5.61 10.75 10.75 0 0 1 12.08 1.48 6.923 6.923 0 0 1 1.902-2.345 6.92 6.92 0 0 1 2.718-1.315 5.726 5.726 0 0 1-.78-2.9 5.794 5.794 0 0 1 3.38-5.286 5.792 5.792 0 0 1 6.21.896 10.708 10.708 0 0 1 11.56.58 7.875 7.875 0 0 1 8.613-5.229 7.874 7.874 0 0 1 6.697 7.529c.552-.096 1.11-.146 1.67-.15a9.942 9.942 0 0 1 8.19 4.31 10.172 10.172 0 0 1 1.75-.17 9.921 9.921 0 0 1 8.63 5 7.013 7.013 0 0 1 3.39-.87 7.006 7.006 0 0 1 6.513 4.31c.355.852.537 1.767.537 2.69"
      fill="#fff"
    />
    <path
      d="M110.919 126.66a9.407 9.407 0 0 1-1.76 0 9.948 9.948 0 0 1-4.833-1.835 9.954 9.954 0 0 1-3.257-4.015 7.002 7.002 0 0 1-9.532-7.881 6.997 6.997 0 0 1 8.637-5.356 6.996 6.996 0 0 1 3.235 1.887 9.927 9.927 0 0 1 12.482-.805 9.93 9.93 0 0 1 2.968 3.345 9.514 9.514 0 0 1 1.77 0 9.678 9.678 0 0 1 1.72.34 8.69 8.69 0 0 1 15.09 5.61 10.75 10.75 0 0 1 12.08 1.48 6.923 6.923 0 0 1 1.902-2.345 6.92 6.92 0 0 1 2.718-1.315 5.726 5.726 0 0 1-.78-2.9 5.794 5.794 0 0 1 3.38-5.286 5.792 5.792 0 0 1 6.21.896 10.708 10.708 0 0 1 11.56.58 7.875 7.875 0 0 1 8.613-5.229 7.874 7.874 0 0 1 6.697 7.529c.552-.096 1.11-.146 1.67-.15a9.942 9.942 0 0 1 8.19 4.31 10.172 10.172 0 0 1 1.75-.17 9.921 9.921 0 0 1 8.63 5 7.013 7.013 0 0 1 3.39-.87 7.006 7.006 0 0 1 6.513 4.31c.355.852.537 1.767.537 2.69"
      stroke={props.fill || 'currentColor'}
      strokeMiterlimit="10"
    />
  </SvgIcon>
);

export default TransportSports;
