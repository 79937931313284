import { FC, Fragment, ReactNode } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { IconLinkAlt } from 'icons';
import { NeutralColors } from 'portfolio3/styles';
import { funnelBlockBorderStyles, funnelBlockContainerStyles } from 'portfolio3/ui-kit/utils';
import { SxStyles } from 'types';
import { mergeSx } from 'utils';

import * as styles from './styles';

interface IFormEntityInfoProps {
  name: string;
  tags?: ReactNode;
  actions?: ReactNode;
  attributes?: string[];
  relatedObjectType?: string;
  relatedObjectName?: string;
  sx?: SxStyles;
}

const FormEntityInfo: FC<IFormEntityInfoProps> = ({
  name,
  tags,
  actions,
  attributes,
  relatedObjectType,
  relatedObjectName,
  sx,
}) => {
  return (
    <Box className="form-entity-info" sx={mergeSx(funnelBlockBorderStyles, styles.root, sx)}>
      <Box className="form-entity-info__inner" sx={funnelBlockContainerStyles}>
        <Box className="form-entity-info__title" sx={styles.title}>
          <Typography sx={styles.titleName} variant="Paragraph LG/Semi Bold">
            {name}
          </Typography>
          <Box sx={styles.tagsContainer}>
            <Box className="form-entity-info__actions">{actions}</Box>
            <Box className="form-entity-info__tags">{tags}</Box>
          </Box>
        </Box>
        {attributes && attributes.length > 0 && (
          <Box className="form-entity-info__attributes" sx={styles.attributes}>
            {attributes.map((attribute, index) => {
              const hasDivider = index > 0;
              return (
                <Fragment key={`${attribute}-${index}`}>
                  {hasDivider && <Box sx={styles.attributeDivider} />}
                  <Typography variant="Paragraph MD/Regular">{attribute}</Typography>
                </Fragment>
              );
            })}
          </Box>
        )}
      </Box>
      {relatedObjectType && relatedObjectName && (
        <Box className="form-entity-info__related" sx={mergeSx(funnelBlockContainerStyles, styles.related)}>
          <IconLinkAlt />
          <Stack>
            <Typography variant="Paragraph SM/Medium" color={NeutralColors.light_neutrals_600}>
              {relatedObjectType}
            </Typography>
            <Typography variant="Paragraph MD/Regular">{relatedObjectName}</Typography>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default FormEntityInfo;
