import { FC } from 'react';

import { Box } from '@mui/material';
import { Doughnut } from '@reactchartjs/react-chart.js';
import { SxStyles } from 'types';
import { mergeSx, numberToPixelsString } from 'utils';

interface ICircularProgressBarProps {
  size: number;
  progressColor: string;
  trackColor: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  sx?: SxStyles;
}

const CircularProgressBar: FC<ICircularProgressBarProps> = ({ size, progressColor, trackColor, data, sx }) => {
  const { incomingData, incomingLabels } = data;

  const borderThickness = 90;

  const singleData = () => ({
    labels: incomingLabels,
    datasets: [
      {
        label: '',
        data: incomingData,
        backgroundColor: [progressColor, trackColor],
        hoverBackgroundColor: [progressColor, trackColor],
        borderWidth: 0,
        weight: 0.3,
      },
    ],
  });

  const options = {
    responsice: true,
    maintainAspectRatio: false,
    cutoutPercentage: borderThickness,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };

  const sizeStyles: SxStyles = {
    width: numberToPixelsString(size),
    height: numberToPixelsString(size),
  };

  return (
    <Box className="circular-progress-bar" sx={mergeSx(sizeStyles, sx)}>
      <Doughnut data={singleData} options={options} type="doughnut" />
    </Box>
  );
};

export default CircularProgressBar;
