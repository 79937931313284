import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const Education: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="230" height="129" viewBox="0 0 230 129" xmlns="http://www.w3.org/2000/svg" {...props} fill="none">
    {/*eslint-disable max-len */}
    <path fill={props.fill || 'currentColor'} d="M67.63 26.58H21.84V23h45.79v3.58Zm-44.79-1h43.79V24H22.84v1.58Z" />
    <path fill={props.fill || 'currentColor'} d="M114 28.14H67.15v-3.08H114v3.08Zm-45.83-1H113v-1.08H68.15l.02 1.08Z" />
    <path
      fill={props.fill || 'currentColor'}
      d="M166.06 26.06H113V23h53.08l-.02 3.06Zm-52.08-1h51.08V24H114l-.02 1.06ZM71.79 93.77h-3.6V27.14h3.6v66.63Zm-2.6-1h1.6V28.14h-1.6v64.63ZM24.84 93.75h-3V25.58h3v68.17Zm-2-1h1V26.58h-1v66.17Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M69.19 48.75H23.4v-3h45.79v3Zm-44.79-1h43.79v-1H24.4v1ZM102.84 55.75h-32v-3h32.05l-.05 3Zm-31-1h30.05v-1h-30l-.05 1ZM114 37.75h-3.09V27.14H114v10.61Zm-2.09-1H113v-8.61h-1.09v8.61ZM165.84 90.75h-3V25.14h3v65.61Zm-2-1h1V26.14h-1v63.61ZM97.84 80.29H24v-2.54h73.84v2.54Zm-72.84-1h71.84v-.54H25v.54Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M164.84 80.29h-53v-2.54h53v2.54Zm-52-1h51v-.54h-51v.54ZM96.79 26.06h-16.1V4.75h16.1v21.31Zm-15.1-1h14.1V5.75h-14.1v19.31ZM78 26.06h-9.81V11H78v15.06Zm-8.85-1H77V12h-7.81l-.04 13.06ZM61.38 24h-8.3V12.56h8.3V24Zm-7.3-1h6.3v-9.44h-6.3V23ZM49.92 24h-7.77v-9.88h7.77V24Zm-6.77-1h5.77v-7.88h-5.77V23ZM40.54 24H29.65V9.44h10.89V24Zm-9.89-1h8.89V10.44h-8.89V23ZM105.64 26.06h-7.77V14.65h7.77v11.41Zm-6.77-1h5.77v-9.41h-5.77v9.41ZM151.48 24h-7.25v-9.35h7.25V24Zm-6.25-1h5.25v-7.35h-5.25V23ZM76 53.66h-3.12V36H76v17.66Zm-2.08-1H75V37h-1.12l.04 15.66ZM80.69 53.66h-3.61V42.25h3.61v11.41Zm-2.61-1h1.61v-9.41h-1.61v9.41Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M78.08 53.66H75V40.17h3.08v13.49Zm-2.08-1h1.08V41.17H76v11.49ZM82.77 53.66h-3.08V38.6h3.08v15.06Zm-2.08-1h1.08V39.6h-1.08v13.06Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M84.85 53.66h-3.08V41.73h3.08v11.93Zm-2.08-1h1.08v-9.93h-1.08v9.93ZM73.88 53.66h-3.09V39.12h3.09v14.54Zm-2.09-1h1.09V40.12h-1.09v12.54ZM116.58 23.46h-3.08v-10.9h3.08v10.9Zm-2.08-1h1.08v-8.9h-1.08v8.9Z"
    />
    <path fill={props.fill || 'currentColor'} d="M118.14 23.46h-2.56V6.83h2.56v16.63Zm-1.56-1h.56V7.83h-.56v14.63Z" />
    <path fill={props.fill || 'currentColor'} d="M121.27 23.46h-4.13V11h4.13v12.46Zm-3.13-1h2.13V12h-2.13v10.46Z" />
    <path fill={props.fill || 'currentColor'} d="M122.83 23.46h-2.56v-10.9h2.56v10.9Zm-1.56-1h.56v-8.9h-.56v8.9Z" />
    <path fill={props.fill || 'currentColor'} d="M125.44 23.46h-3.61V7.88h3.61v15.58Zm-2.61-1h1.61V8.88h-1.61v13.58Z" />
    <path
      fill={props.fill || 'currentColor'}
      d="M128 23.46h-3.6V11.52h3.6v11.94Zm-2.6-1h1.6v-9.94h-1.6v9.94ZM142.62 23.54h-9.33V11.6h9.33v11.94Zm-8.33-1h7.33V12.6h-7.33v9.94ZM46.79 46.37h-9.33v-13h9.33v13Zm-8.33-1h7.33v-11h-7.33v11ZM52 46.37h-3.6v-13H52v13Zm-2.6-1H51v-11h-1.6v11Z"
    />
    <path fill={props.fill || 'currentColor'} d="M53.56 46.37H51V35.48h2.56v10.89Zm-1.56-1h.56v-8.89H52v8.89Z" />
    <path
      fill={props.fill || 'currentColor'}
      d="M56.69 46.37h-3.61V33.92h3.61v12.45Zm-2.61-1h1.61V34.92h-1.61v10.45Z"
    />
    <path fill={props.fill || 'currentColor'} d="M58.77 46.37h-3.08v-16.1h3.08v16.1Zm-2.08-1h1.08v-14.1h-1.08v14.1Z" />
    <path fill={props.fill || 'currentColor'} d="M60.33 46.37h-2.56v-13h2.56v13Zm-1.56-1h.56v-11h-.56v11Z" />
    <path fill={props.fill || 'currentColor'} d="M62.42 46.37h-3.09V36h3.09v10.37Zm-2.09-1h1.09V37h-1.09v8.37Z" />
    <path fill={props.fill || 'currentColor'} d="M64.5 46.37h-3.08v-13h3.08v13Zm-2.08-1h1.08v-11h-1.08v11Z" />
    <path
      fill={props.fill || 'currentColor'}
      d="M66.06 46.37H63.5v-16.1h2.56v16.1Zm-1.56-1h.56v-14.1h-.56v14.1ZM69.19 71.75H22.36v-2.42h46.83v2.42Zm-45.83-1h44.83v-.42H23.36v.42ZM99.84 70.75h-29v-3h29v3Zm-28-1h27v-1h-27v1ZM62.42 69.29h-3.09V54.23h3.09v15.06Zm-2.09-1h1.09V55.23h-1.09v13.06ZM67.58 69.29h-4.12V56.83h4.12v12.46Zm-3.12-1h2.12V57.83h-2.12v10.46Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M69.15 69.29h-2.57v-16.1h2.57v16.1Zm-1.57-1h.57v-14.1h-.57v14.1ZM39.5 69.29h-3.6V52.66h3.6v16.63Zm-2.6-1h1.6V53.66h-1.6v14.63Z"
    />
    <path fill={props.fill || 'currentColor'} d="M36.9 69.29h-3.09v-13.5h3.09v13.5Zm-2.09-1h1.09v-11.5h-1.09v11.5Z" />
    <path fill={props.fill || 'currentColor'} d="M34.81 69.29h-5.16v-16.1h5.16v16.1Zm-4.16-1h3.16v-14.1h-3.16v14.1Z" />
    <path fill={props.fill || 'currentColor'} d="M30.61 69.29H27V50.06h3.61v19.23Zm-2.61-1h1.61V51.06H28v17.23Z" />
    <path
      fill={props.fill || 'currentColor'}
      d="M28 69.29h-3.6V51.62H28v17.67Zm-2.6-1H27V52.62h-1.6v15.67ZM55.65 69.29H45.27V54.23h10.38v15.06Zm-9.38-1h8.38V55.23h-8.38v13.06ZM78 68.73h-3v-8.82h3v8.82Zm-2.08-1H77v-6.82h-1l-.08 6.82Z"
    />
    <path fill={props.fill || 'currentColor'} d="M80.69 68.73h-3.61V56.79h3.61v11.94Zm-2.61-1h1.61v-9.94h-1.61v9.94Z" />
    <path fill={props.fill || 'currentColor'} d="M82.77 68.73h-3.08v-9.34h3.08v9.34Zm-2.08-1h1.08v-7.34h-1.08v7.34Z" />
    <path
      fill={props.fill || 'currentColor'}
      d="M84.85 68.73h-3.08V56.79h3.08v11.94Zm-2.08-1h1.08v-9.94h-1.08v9.94ZM76 68.73h-3.12v-10.9H76v10.9Zm-2.08-1H75v-8.9h-1.12l.04 8.9Z"
    />
    <path fill="#fff" d="M207.66 33.89h-70.83v40.1h70.83v-40.1Z" />
    <path
      fill={props.fill || 'currentColor'}
      d="M208.16 74.5h-71.83V33.39h71.83V74.5Zm-70.83-1h69.83V34.39h-69.83V73.5Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M176.25 100.75h-8v-27h8v27Zm-7-1h6v-25h-6v25ZM110.76 79.29c-2.79 0-9.83-.36-13.28-3.81a.53.53 0 0 1-.12-.52l3.15-9.53a24.196 24.196 0 0 1 3.68-15.58 6.147 6.147 0 0 1 2.86-2.29.508.508 0 0 1 .61.28s1.83 4 4.4 4.36l.4-1.19a.516.516 0 0 1 .256-.308.523.523 0 0 1 .744.618l-.52 1.56a.5.5 0 0 1-.47.35c-2.84 0-4.82-3.31-5.45-4.54a5.213 5.213 0 0 0-2 1.71 23.595 23.595 0 0 0-3.5 15c.02.075.02.155 0 .23L98.41 75c3.61 3.3 11.28 3.34 13.08 3.3l2.78-12.91a.487.487 0 0 1 .59-.38.506.506 0 0 1 .317.214.513.513 0 0 1 .073.376l-2.87 13.29a.502.502 0 0 1-.46.4c-.08-.02-.5 0-1.16 0Z"
    />
    <path
      fill="#fff"
      d="M111.89 63.46c2.61 2.93 12 5.41 12 5.41l11.63-3.39h6.26a3.237 3.237 0 0 0 2.43-1.1l1.07-1.21a.922.922 0 0 0 .05-1.14l-.45-.58a2.008 2.008 0 0 0-1.8-.81l-4.6.42 1.22-1.77a.728.728 0 0 0-.21-1 .733.733 0 0 0-.91.09l-3.75 3.75-4.15 1.3h-7.14l-5.9-3.9"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M123.87 69.37a.249.249 0 0 1-.12 0c-.39-.1-9.56-2.55-12.23-5.55a.511.511 0 0 1 0-.71.497.497 0 0 1 .547-.11.507.507 0 0 1 .163.11c2.31 2.6 10.46 4.91 11.6 5.22L135.36 65a.34.34 0 0 1 .14 0h6.26a2.743 2.743 0 0 0 2.05-.93l1.08-1.21a.42.42 0 0 0 0-.51l-.45-.58a1.511 1.511 0 0 0-1.36-.61l-4.6.42a.501.501 0 0 1-.46-.78l1.25-1.8a.24.24 0 0 0-.07-.32.23.23 0 0 0-.29 0l-3.75 3.74a.459.459 0 0 1-.2.13l-4.15 1.3a.39.39 0 0 1-.15 0h-7.14a.539.539 0 0 1-.28-.08l-5.89-3.91a.48.48 0 0 1-.216-.315.493.493 0 0 1 .076-.375.5.5 0 0 1 .69-.14l5.77 3.82h6.91l4-1.24 3.63-3.61a1.229 1.229 0 0 1 1.53-.17 1.225 1.225 0 0 1 .548 1.288 1.23 1.23 0 0 1-.198.452l-.62.89 3.54-.32a2.51 2.51 0 0 1 2.25 1l.45.58a1.425 1.425 0 0 1-.08 1.77l-1.07 1.21a3.717 3.717 0 0 1-2.8 1.27h-6.19L124 69.35l-.13.02ZM114.25 51.75a2.811 2.811 0 0 1-1.9-.72 2.896 2.896 0 0 1-1-2 .5.5 0 0 1 1 0c.051.474.276.912.63 1.23a1.81 1.81 0 0 0 1.49.44 2.068 2.068 0 0 0 1.48-.95 7.273 7.273 0 0 0 .8-1.88.501.501 0 0 1 .48-.36h.52l-1-2.16a.512.512 0 0 1 .15-.6l.6-.47-.76-2.26a.505.505 0 0 1 .07-.47l.59-.76c-1.21-1-5.3-4-8.57-3.23a3.465 3.465 0 0 0-2.69 2.57 4.798 4.798 0 0 0 .53 3.56l1.43 2.51a.484.484 0 0 1 0 .41l-.52 1.56a.498.498 0 0 1-.249.288.491.491 0 0 1-.381.022.5.5 0 0 1-.32-.63l.45-1.35-1.32-2.31a5.761 5.761 0 0 1-.62-4.32 4.424 4.424 0 0 1 3.41-3.28c4.37-1.1 9.61 3.56 9.83 3.76a.498.498 0 0 1 .06.67l-.71.93.79 2.34a.489.489 0 0 1-.17.55l-.56.45.87 1.93a.921.921 0 0 1-.83 1.29h-.29a8.132 8.132 0 0 1-.8 1.78 3.069 3.069 0 0 1-2.19 1.4l-.3.06Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M111.37 47.5h-.09c-.13 0-3-.63-3-2.58a1.241 1.241 0 0 1 .54-1.1 3.246 3.246 0 0 1 2.59.06 30.163 30.163 0 0 0 5.59-2.42.499.499 0 0 1 .5.86 30.203 30.203 0 0 1-6 2.56.58.58 0 0 1-.32 0c-.57-.23-1.52-.43-1.82-.22 0 0-.1.07-.1.27 0 1.12 2.18 1.59 2.2 1.6a.506.506 0 0 1 .45.55.506.506 0 0 1-.55.45l.01-.03ZM141.06 62.6a.51.51 0 0 1-.5-.43.508.508 0 0 1 .43-.56l3.64-.52a.486.486 0 0 1 .506.234.513.513 0 0 1-.356.756l-3.65.52h-.07ZM145.23 63.64h-3.13a.5.5 0 0 1-.5-.5.508.508 0 0 1 .5-.5h3.13a.51.51 0 0 1 .5.5.5.5 0 0 1-.5.5ZM143.66 65.21h-2.08a.5.5 0 1 1 0-1h2.08a.5.5 0 1 1 0 1Z"
    />
    <path
      fill="#fff"
      d="m116.41 57.53 9.07 1.14L139 55.33l6 .4a3.06 3.06 0 0 0 2.77-1.36l.39-.59a2.185 2.185 0 0 0 .14-2.18 1.732 1.732 0 0 0-1.55-1h-1.27l-.78-.52-1.05.52"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M125.48 59.17h-.06L116.35 58a.503.503 0 0 1 .13-1l9 1.13 13.41-3.31a.39.39 0 0 1 .15 0l6 .4a2.552 2.552 0 0 0 2.32-1.14l.4-.59a1.684 1.684 0 0 0 .1-1.68 1.22 1.22 0 0 0-1.1-.68h-1.27a.542.542 0 0 1-.28-.08l-.54-.36-.78.39a.499.499 0 0 1-.67-.22.512.512 0 0 1-.055-.188.504.504 0 0 1 .275-.482l1-.52a.502.502 0 0 1 .5 0l.66.43h1.12a2.21 2.21 0 0 1 2 1.23 2.693 2.693 0 0 1-.16 2.69l-.4.59a3.593 3.593 0 0 1-3.16 1.62l-6-.4-13.4 3.32-.12.02Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M141.06 55.31a.475.475 0 0 1-.35-.15l-1.05-1a.495.495 0 0 1-.14-.39.487.487 0 0 1 .2-.36l2.08-1.56a.491.491 0 0 1 .7.1.497.497 0 0 1-.1.7l-1.62 1.21.32.33 3.9-2.98v-.57a.5.5 0 0 1 .5-.5.51.51 0 0 1 .5.5v.82a.548.548 0 0 1-.2.4l-4.43 3.35a.47.47 0 0 1-.31.1Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M148.34 53.75h-4a.501.501 0 1 1 0-1h4a.499.499 0 0 1 .353.854.499.499 0 0 1-.353.146ZM144.71 52.71a.511.511 0 0 1-.5-.43.494.494 0 0 1 .092-.372.503.503 0 0 1 .328-.198l3.65-.52a.511.511 0 0 1 .57.42.504.504 0 0 1-.243.506.504.504 0 0 1-.187.064l-3.64.52-.07.01ZM117.1 119.11h-.1l-6.25-1a.491.491 0 0 1-.42-.47l-.49-17.64-5.93-10.5a11.207 11.207 0 0 0-3.55-3.71A7.245 7.245 0 0 1 98.64 84 9 9 0 0 1 97 77.67l.31-2.61a.5.5 0 0 1 .55-.44.51.51 0 0 1 .44.56l-.3 2.61a8 8 0 0 0 1.43 5.65 6.414 6.414 0 0 0 1.48 1.5 12.247 12.247 0 0 1 3.85 4l6 10.6a.47.47 0 0 1 .06.24l.51 17.37 5.27.83c0-3.21.28-18.25.52-19.75.17-1.13-3.41-12.58-5.71-19.53a.526.526 0 0 1 1-.32c1 3 6 18.34 5.75 20-.2 1.26-.43 13.76-.52 20.19a.46.46 0 0 1-.18.37.467.467 0 0 1-.36.17Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="m123 124.25-13.28-.55a.495.495 0 0 1-.47-.4 6.264 6.264 0 0 1 1.9-5.54.474.474 0 0 1 .35-.147.49.49 0 0 1 .35.147.502.502 0 0 1 0 .71 5.265 5.265 0 0 0-1.64 4.23l12.34.51a2.583 2.583 0 0 0-1.58-2.21l-4.65-1.93a.482.482 0 0 1-.273-.268.504.504 0 0 1-.038-.191.5.5 0 0 1 .311-.456.51.51 0 0 1 .38-.005l4.65 1.93a3.577 3.577 0 0 1 2.19 3.71.514.514 0 0 1-.54.46ZM106.13 121.55h-.06l-13.21-1.39a.502.502 0 0 1-.44-.43 6.218 6.218 0 0 1 2.24-5.41.485.485 0 0 1 .55-.063.51.51 0 0 1 .09.833 5.268 5.268 0 0 0-1.92 4.12l12.29 1.29a2.594 2.594 0 0 0-1.44-2.31L99.71 116a.503.503 0 0 1-.23-.67.499.499 0 0 1 .67-.23l4.52 2.22a3.58 3.58 0 0 1 2 3.84.5.5 0 0 1-.54.39Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M100.65 116.43h-.12l-5.42-1.27a.506.506 0 0 1-.32-.24.489.489 0 0 1 0-.41l5.39-15.24V85.35a.5.5 0 0 1 1 0v14a.43.43 0 0 1 0 .16l-5.23 14.8 4.48 1 8.47-17.59a.509.509 0 0 1 .67-.24.508.508 0 0 1 .254.287.491.491 0 0 1-.024.383l-8.64 18a.488.488 0 0 1-.51.28ZM124.39 54.27a.536.536 0 0 1-.21-.05l-8-3.72a.498.498 0 0 1 .064-.884.502.502 0 0 1 .356-.016l7.87 3.66 13.3-1 4-2.16a1.66 1.66 0 0 1 2.11.45l.39.51c.098.127.169.271.21.426.042.154.052.316.03.474a1.195 1.195 0 0 1-.5.79 1.43 1.43 0 0 1-1.09.21.996.996 0 0 1-.68-.41l-.49-.49a.493.493 0 0 1-.11-.547.51.51 0 0 1 .82-.163l.52.52.06.09a.477.477 0 0 0 .4 0 .192.192 0 0 0 .08-.12.223.223 0 0 0 0-.14l-.39-.51a.662.662 0 0 0-.84-.18l-4.08 2.21a.672.672 0 0 1-.2.06l-13.54 1-.08-.01ZM110.85 64.68a.493.493 0 0 1-.492-.587.503.503 0 0 1 .212-.323l6.37-4.41c-1.79-2.66-4.89-6.27-6.42-5.65a.503.503 0 0 1-.65-.28.487.487 0 0 1 .005-.383.487.487 0 0 1 .275-.267c2.9-1.15 7.39 5.66 7.89 6.45a.5.5 0 0 1-.13.68l-6.77 4.69a.512.512 0 0 1-.29.08ZM183.34 103.25h-22v-3h22v3Zm-21-1h20v-1h-20v1ZM149.6 68.56a.378.378 0 0 1-.26-.11 12.86 12.86 0 0 1-3.75-9.12.375.375 0 0 1 .085-.307.383.383 0 0 1 .45-.1.383.383 0 0 1 .215.407 12.119 12.119 0 0 0 3.53 8.59.38.38 0 0 1 0 .53.4.4 0 0 1-.27.11ZM193.22 54a8.688 8.688 0 0 1-8.535-10.38 8.688 8.688 0 0 1 11.848-6.34 8.69 8.69 0 0 1 5.367 8.03 8.702 8.702 0 0 1-8.68 8.69Zm0-16.62a7.937 7.937 0 0 0-7.798 9.487 7.939 7.939 0 0 0 15.728-1.557 7.943 7.943 0 0 0-7.93-7.95v.02Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M189 49.31a2.712 2.712 0 0 1-2.658-3.239 2.714 2.714 0 0 1 2.129-2.129A2.71 2.71 0 1 1 189 49.31Zm0-4.66a1.998 1.998 0 0 0-1.414 3.414A2.001 2.001 0 1 0 189 44.65Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M189.14 47a.82.82 0 1 1-.019-1.641.82.82 0 0 1 .019 1.64Zm0-.88v.13l.06-.06c.003-.01.004-.02.003-.03a.093.093 0 0 0-.011-.03.07.07 0 0 0-.052-.03v.02ZM197.45 48.44a2.706 2.706 0 0 1-2.503-1.673 2.708 2.708 0 0 1 3.54-3.54 2.71 2.71 0 0 1-1.037 5.213Zm0-4.67a2 2 0 1 0 0 4.002 2 2 0 0 0 0-4.002Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M197.59 46.1a.809.809 0 1 1 .02-1.618.809.809 0 0 1-.02 1.618Zm0-.87v.12l.07-.06a.068.068 0 0 0-.024-.043.069.069 0 0 0-.046-.017Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M197.16 46.54a.808.808 0 0 1-.749-.5.815.815 0 0 1 .176-.883.806.806 0 0 1 .883-.175.809.809 0 0 1 .259 1.318.817.817 0 0 1-.569.24Zm0-.87v.12l.06-.06a.061.061 0 0 0-.018-.042.058.058 0 0 0-.042-.018ZM188.8 47.51a.824.824 0 0 1-.765-.5.82.82 0 1 1 .765.5Zm0-.88v.13l.06-.07a.062.062 0 0 0-.018-.042.059.059 0 0 0-.042-.018ZM190.88 41.41c-3.34 0-4.14-1.42-4.18-1.49a.384.384 0 0 1 .16-.51.38.38 0 0 1 .5.15s1 1.52 5.23 1 5.32-2 5.33-2.06a.373.373 0 1 1 .65.37c0 .07-1.09 1.83-5.89 2.43a15.33 15.33 0 0 1-1.8.11Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M188.92 50.2a3.199 3.199 0 0 1-3.48-2.28 4.179 4.179 0 0 1-.08-3.27 3.665 3.665 0 0 1 2-2 7.92 7.92 0 0 1 4.13 0 3.76 3.76 0 0 0 2-.17 18.607 18.607 0 0 1 2.06-.61c4-1 5.16 1.27 5.48 2.29a4.924 4.924 0 0 1-.47 3.69 3.177 3.177 0 0 1-2.32 1.46c-.963.148-1.941.175-2.91.08l-1.46-.08c-.898 0-1.788.158-2.63.47a7.132 7.132 0 0 1-2 .42h-.32Zm.15-7.07a3.819 3.819 0 0 0-1.4.21 2.888 2.888 0 0 0-1.62 1.6 3.431 3.431 0 0 0 .08 2.68c.79 1.86 1.73 1.84 3.12 1.83a6.002 6.002 0 0 0 1.82-.39 8.178 8.178 0 0 1 2.89-.5l1.48.08c.919.094 1.847.07 2.76-.07a2.397 2.397 0 0 0 1.8-1.12 4.14 4.14 0 0 0 .4-3.07c-.33-1-1.35-2.6-4.59-1.79-.677.161-1.344.358-2 .59a4.48 4.48 0 0 1-2.44.21 12.524 12.524 0 0 0-2.3-.26ZM193.66 64.77a11.92 11.92 0 0 1-3.33-.59c-1.7-.55-2-.71-2.07-1a.546.546 0 0 1 .1-.49c.1-.14.27-.41 1.7-.41h.1a.367.367 0 0 1 .345.235.377.377 0 0 1-.345.515h-.79c.25.1.63.23 1.19.42 1.003.326 2.046.511 3.1.55a.379.379 0 0 1 .228.62.379.379 0 0 1-.228.13v.02Zm-4.99-1.87.27.23-.07-.14-.2-.09ZM189.49 71.6a13.254 13.254 0 0 1-3.17-.32.366.366 0 0 1-.25-.22.352.352 0 0 1 0-.33l1.41-2.44a.373.373 0 0 1 .685.086.37.37 0 0 1-.035.284l-1.16 2c2.198.254 4.421.217 6.61-.11a.369.369 0 0 1 .277.07.377.377 0 0 1 .141.391.381.381 0 0 1-.171.23.374.374 0 0 1-.137.049c-1.392.197-2.795.3-4.2.31Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M193.66 70.31a.323.323 0 0 1-.2-.06 17.746 17.746 0 0 0-4.41-1.93c-.56-.14-1-.12-1.13 0a.662.662 0 0 0 0 .58.38.38 0 0 1-.19.46.36.36 0 0 1-.47-.11c-1.59-2.22 1-6.45 1.15-6.62a.38.38 0 0 1 .235-.17.37.37 0 0 1 .285.05.376.376 0 0 1 .12.52 9.511 9.511 0 0 0-1.54 4.68 2.003 2.003 0 0 1 1.74-.15c1.363.365 2.674.903 3.9 1.6a19.838 19.838 0 0 1 .17-5 .39.39 0 0 1 .2-.28c.12-.06 3-1.51 4.56-1.43 1.56.08 2 5.23 2.1 7.44a.37.37 0 0 1-.64.27s-2.1-2.12-4-1.64a2.243 2.243 0 0 0-1.48.82.494.494 0 0 0-.05.27c.011.065.028.128.05.19a.36.36 0 0 1-.129.395.36.36 0 0 1-.131.065l-.14.05Zm2.43-2.58a5.678 5.678 0 0 1 3.27 1.33c-.21-3.12-.81-5.8-1.35-5.83-1.4.174-2.756.604-4 1.27a21.492 21.492 0 0 0-.2 4c.453-.33.972-.558 1.52-.67.248-.067.504-.101.76-.1Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M195 72.12c-1.22 0-1.55-.24-1.69-.5a.581.581 0 0 1 0-.53l.56-2.32a.38.38 0 0 1 .45-.28.369.369 0 0 1 .28.45l-.6 2.33a6 6 0 0 0 2.22 0c.947-.05 1.888-.187 2.81-.41l-.24-1a.36.36 0 0 1 .09-.34.36.36 0 0 1 .33-.12l.59.08a.374.374 0 0 1-.1.74l.17.71c0 .39 0 .72-3.54 1-.54.17-.98.19-1.33.19ZM191.76 59.2c-.65 0-.89-.15-1-.31a.498.498 0 0 1-.11-.43v-.79a4.206 4.206 0 0 0-.53-2.71 1.457 1.457 0 0 1-.22-1.48 1.261 1.261 0 0 1 1.08-.56.38.38 0 0 1 .37.38.371.371 0 0 1-.37.37c-.1 0-.36 0-.43.18s-.08.23.21.73c.571.955.794 2.079.63 3.18v.66c.672.068 1.35.01 2-.17.793-.162 1.601-.24 2.41-.23a1.593 1.593 0 0 1-.39-.42c-.69-1 1-3.06 1.48-3.66a.383.383 0 0 1 .47-.08l.88.49a.396.396 0 0 1 .19.33.403.403 0 0 1-.19.33 3.46 3.46 0 0 0-1.13 1.24 1.002 1.002 0 0 0-.19.79.584.584 0 0 0 .35.28c.046.014.09.034.13.06a.383.383 0 0 1 .15.31v.93a.379.379 0 0 1-.18.32.356.356 0 0 1-.36 0 6.431 6.431 0 0 0-3.48 0c-.58.14-1.173.227-1.77.26Zm5.48-4.56c-.79 1-1.46 2.17-1.23 2.52a.81.81 0 0 0 .25.24v-.09a1.68 1.68 0 0 1 .23-1.47c.248-.424.558-.809.92-1.14l-.17-.06ZM187 59.37a8.912 8.912 0 0 1-3.16-1.37c-.41-.34-2.22-1.94-2.22-2.94a3.934 3.934 0 0 1 1.95-2.67c.71-.35 3.83-.06 4.42.68.35.44 1.67 2.38 1.67 3.74 0 1.36-.33 2.06-.89 2.12-.528.17-1.066.31-1.61.42l-.16.02ZM184.58 53a2 2 0 0 0-.67.08c-.52.26-1.54 1.33-1.54 2 0 .43 1 1.55 1.91 2.34.826.563 1.748.97 2.72 1.2.529-.106 1.05-.246 1.56-.42h.07c.22-.425.311-.905.26-1.38a7.178 7.178 0 0 0-1.51-3.28 6.098 6.098 0 0 0-2.8-.54Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M200.53 59.46a3.15 3.15 0 0 1-2.47-1.06 1.53 1.53 0 0 1-.06-1.78 3.554 3.554 0 0 0 .43-1.59 2.082 2.082 0 0 1 1.3-2.08 5.353 5.353 0 0 1 1.69-.39c.438-.02.871-.098 1.29-.23a2.52 2.52 0 0 1 2.37.53 2.822 2.822 0 0 1 .9 2.55c-.3 2.12-2.57 3.43-4.6 4-.28.052-.566.069-.85.05Zm2.89-6.46a1.726 1.726 0 0 0-.5.07 5.642 5.642 0 0 1-1.47.26c-.499.034-.988.15-1.45.34-.67.26-.74.66-.82 1.46a4.2 4.2 0 0 1-.54 1.89.787.787 0 0 0 0 1 2.62 2.62 0 0 0 2.54.68c1.7-.44 3.79-1.57 4-3.33a2.073 2.073 0 0 0-.65-1.87 1.751 1.751 0 0 0-1.11-.5ZM194.29 64.32a.362.362 0 0 1-.2-.06 1.264 1.264 0 0 1-.59-.8.571.571 0 0 1 .19-.49c.237-.69.395-1.404.47-2.13a.364.364 0 0 1 .19-.27c2.26-1.3 3.6 0 3.65.06a.368.368 0 0 1 .005.525.366.366 0 0 1-.403.084.367.367 0 0 1-.122-.079 2.219 2.219 0 0 0-2.6 0 7.548 7.548 0 0 1-.6 2.3c.064.077.138.144.22.2a.38.38 0 0 1 .11.52.391.391 0 0 1-.32.14Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M195.41 63.67a.38.38 0 0 1-.37-.3 3.014 3.014 0 0 0-.52-.41.36.36 0 0 1-.13-.51l.69-1.17a.38.38 0 0 1 .49-.14 3.502 3.502 0 0 1 1.67 1.57.379.379 0 0 1-.22.49.362.362 0 0 1-.48-.21 2.48 2.48 0 0 0-1-1l-.33.55c.57.41.57.67.57.76a.381.381 0 0 1-.37.37Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M197.45 63.23a.36.36 0 0 1-.35-.25 1.601 1.601 0 0 0-.94-.86l-.87-.29a.375.375 0 0 1-.218-.185.365.365 0 0 1-.022-.285.372.372 0 0 1 .47-.24l.88.29a2.392 2.392 0 0 1 1.4 1.31.39.39 0 0 1-.22.49l-.13.02ZM193.15 64.35a2.003 2.003 0 0 1-.73-.13 2.592 2.592 0 0 1-1.42-1.61c-.06-.13-.11-.25-.17-.36-.06-.11-.13-.27-.18-.4a4.006 4.006 0 0 1-.25-2.22.381.381 0 0 1 .46-.27.39.39 0 0 1 .26.46c-.082.6-.002 1.211.23 1.77 0 .1.1.21.15.33.071.133.135.27.19.41a1.932 1.932 0 0 0 1 1.19c.36.126.753.108 1.1-.05a.38.38 0 0 1 .5.17.361.361 0 0 1 .028.285.36.36 0 0 1-.188.215 2.251 2.251 0 0 1-.98.21Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M191.32 63.89a1.708 1.708 0 0 1-1.53-.59.35.35 0 0 1-.115-.126.353.353 0 0 1-.045-.164 2.519 2.519 0 0 1 1.22-1.65.379.379 0 0 1 .48.22.367.367 0 0 1-.092.413.374.374 0 0 1-.128.077c-.303.209-.539.5-.68.84.256.189.575.271.89.23.049.01.1.01.15 0a.352.352 0 0 1 .51 0 .388.388 0 0 1 0 .55.89.89 0 0 1-.66.2ZM196.55 59.75h-.09l-1.14-.29a.371.371 0 0 1-.29-.36v-.52a.381.381 0 0 1 .75 0v.22l.86.22a.382.382 0 0 1 .28.45.387.387 0 0 1-.37.28Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M197.16 62.73a.39.39 0 0 1-.25-.1.36.36 0 0 1-.112-.265.373.373 0 0 1 .112-.265 1.632 1.632 0 0 0 .25-1.83 1.539 1.539 0 0 0-.47-.54 8.311 8.311 0 0 1-4.5.66c-1.12-.12-1.62-.25-1.63-.69-.01-.44.28-.53 1-.66l.11-.23a.37.37 0 0 1 .22-.19.377.377 0 0 1 .46.52l-.19.4-.06.08h.2a7.612 7.612 0 0 0 4.13-.61.62.62 0 0 1 .41 0c.33.06.65.35 1 .89a2.354 2.354 0 0 1-.33 2.72.41.41 0 0 1-.35.11Zm-.39-3h-.07.07Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M193.65 59.94a7.704 7.704 0 0 1-2-.21.38.38 0 0 1-.182-.6.369.369 0 0 1 .257-.131.358.358 0 0 1 .145.02c.657.14 1.329.198 2 .17.449.004.89-.121 1.27-.36a.372.372 0 0 1 .633.27.386.386 0 0 1-.113.27c-.09.11-.49.47-1.78.61l-.23-.04ZM198 54.27a.363.363 0 0 1-.266-.112.377.377 0 0 1-.104-.268 2.43 2.43 0 0 0-1.21-.62.377.377 0 0 1-.195-.59.365.365 0 0 1 .395-.13c.41.11 1.76.54 1.76 1.34a.38.38 0 0 1-.38.38Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M197.4 58.07h-.28a.381.381 0 0 1-.34-.41.388.388 0 0 1 .41-.34h.41a1.699 1.699 0 0 1-.07-1.09 3.51 3.51 0 0 1 .5-1l-.17-.09a.371.371 0 0 1-.2-.4c.09-.43 0-.85-.06-.87a.89.89 0 0 0-.07.36.381.381 0 0 1-.75 0 1.319 1.319 0 0 1 .39-1 .712.712 0 0 1 .64-.1c.56.16.69.83.63 1.45l.34.17a.35.35 0 0 1 .2.27.358.358 0 0 1-.012.172.362.362 0 0 1-.088.148 3.234 3.234 0 0 0-.64 1.11c-.04.272-.002.55.11.8.033.073.053.15.06.23a.528.528 0 0 1-.18.39 1.27 1.27 0 0 1-.83.2ZM185.46 57.68a4.11 4.11 0 0 1-1.91-.55.358.358 0 0 1-.182-.373.373.373 0 0 1 .052-.137.376.376 0 0 1 .228-.171.37.37 0 0 1 .282.041 2.784 2.784 0 0 0 2.27.28 5.408 5.408 0 0 0 1-2.65.38.38 0 0 1 .157-.243.369.369 0 0 1 .283-.057.36.36 0 0 1 .3.43c-.11.64-.52 2.77-1.37 3.19-.345.167-.726.25-1.11.24Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M188.7 58.94a.359.359 0 0 1-.2-.06l-2.33-1.46a.375.375 0 1 1 .39-.64l2.34 1.46a.384.384 0 0 1 .167.235c.022.097.005.2-.047.285a.396.396 0 0 1-.32.18ZM200.91 57.8a1.993 1.993 0 0 1-.55-.07 1.419 1.419 0 0 1-.93-.83 4.175 4.175 0 0 1 0-3.79.38.38 0 0 1 .64.4 3.456 3.456 0 0 0 0 3.11.614.614 0 0 0 .43.39c1.21.34 3.9-1.3 4.84-2a.37.37 0 0 1 .414-.01.365.365 0 0 1 .165.233.376.376 0 0 1-.049.281.37.37 0 0 1-.1.106c-.28.28-3.03 2.18-4.86 2.18ZM191 58.06c-.89 0-.95-.29-1.23-1.66-.35-1.64.44-1.95.53-2a.363.363 0 0 1 .285.021.37.37 0 0 1 .185.219.362.362 0 0 1-.2.46c-.08.06-.23.36-.07 1.14.056.365.15.724.28 1.07h.22a.379.379 0 0 1 .307.664.38.38 0 0 1-.307.086Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M189.14 55.85a.383.383 0 0 1-.36-.25.38.38 0 0 1 .23-.48l1-.35a.38.38 0 0 1 .48.23.38.38 0 0 1-.23.48l-1 .35a.251.251 0 0 1-.12.02ZM190.45 57.82a.249.249 0 0 1-.12 0l-1-.34a.37.37 0 0 1-.216-.189.374.374 0 0 1 .456-.52l1 .34a.369.369 0 0 1 .23.48.378.378 0 0 1-.35.23Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M188.7 58.94a.39.39 0 0 1-.38-.38.374.374 0 0 1 .112-.265.379.379 0 0 1 .268-.105c.05 0 1.17 0 1.38-.89a.382.382 0 0 1 .591-.208.36.36 0 0 1 .147.243.372.372 0 0 1-.008.145 2.179 2.179 0 0 1-2.11 1.46ZM201.18 55.11a1.26 1.26 0 0 1-.91-2.13.745.745 0 0 1 .74-.35c.15 0 .4.15.5.59.032.192.115.372.24.52a.813.813 0 0 1 .2.83.78.78 0 0 1-.63.53h-.14v.01Zm-.37-1.6a.679.679 0 0 0-.11.51.584.584 0 0 0 .51.34 2.296 2.296 0 0 1-.4-.85ZM204.47 56h-.21c-.55-.15-.95-.87-1.24-2.27a1.252 1.252 0 0 1 .52-1.28c.231-.151.445-.325.64-.52.36-.36.64-.28.78-.21.39.22.41.89.34 1.44.15.037.293.102.42.19.47.34.25 1.14-.64 2.4a.88.88 0 0 1-.61.25Zm.11-3.44a6.288 6.288 0 0 1-.58.44.468.468 0 0 0-.21.5c.33 1.56.7 1.7.71 1.7a4.44 4.44 0 0 0 .75-1.37h-.09a.381.381 0 0 1-.45.09.389.389 0 0 1-.19-.43 5.12 5.12 0 0 0 .06-.95v.02ZM186 54.8a.848.848 0 0 1-.72-.3.73.73 0 0 1 0-.73 1.309 1.309 0 0 0-.17-.46c-.14-.26-.47-.86.15-1.21.16-.09.74-.33 1.27.46l.37.51a1.25 1.25 0 0 1 .31 1.16.995.995 0 0 1-.78.53 3.31 3.31 0 0 1-.43.04Zm0-.75c.107.012.214.012.32 0a.439.439 0 0 0 .21-.07s0-.17-.21-.43l-.39-.54c0-.07-.19-.27-.28-.23.052.067.099.137.14.21.197.294.279.65.23 1a.142.142 0 0 1-.02.06ZM182.56 55.2a.997.997 0 0 1-.62-.2 1.996 1.996 0 0 1-.61-1.69c0-.25 0-.64.33-.74a.35.35 0 0 1 .17 0 .804.804 0 0 1 .19-1 .68.68 0 0 1 .58-.21c.16 0 .64.06 1.46 1.28a1.432 1.432 0 0 1 .17 1.28 1.792 1.792 0 0 1-1.23 1.2c-.141.053-.29.08-.44.08Zm-.44-1.35c.009.206.099.4.25.54a.408.408 0 0 0 .38 0 .997.997 0 0 0 .77-.73.653.653 0 0 0-.08-.6c-.52-.77-.84-.95-.91-1-.07-.05-.07.09.19.45s.51.68.2 1.11a.592.592 0 0 1-.8.17v.06ZM163.12 69a.379.379 0 0 1-.25-.66l2.54-2.26a.381.381 0 0 1 .265-.112.367.367 0 0 1 .265.112.38.38 0 0 1 0 .53l-2.54 2.26a.417.417 0 0 1-.28.13Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M168.47 64.25a.338.338 0 0 1-.28-.13.371.371 0 0 1 0-.53l17.19-15.28-25.11 12.27a.38.38 0 0 1-.5-.17.393.393 0 0 1 .17-.51l27.6-13.46a.382.382 0 0 1 .48.13.37.37 0 0 1-.07.48l-19.24 17.11a.38.38 0 0 1-.24.09ZM151.28 64.45a.38.38 0 0 1-.35-.22.393.393 0 0 1 .2-.5l7.59-3.34a.38.38 0 0 1 .498.484.374.374 0 0 1-.198.206l-7.59 3.34a.396.396 0 0 1-.15.03ZM158.83 52.92a4.85 4.85 0 1 1 .136-9.698 4.85 4.85 0 0 1-.136 9.698Zm0-9a4.091 4.091 0 0 0-2.506 7.318 4.09 4.09 0 1 0 2.506-7.318Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M162.1 52.8a.39.39 0 0 1-.31-.15.378.378 0 0 1 .08-.53 4.89 4.89 0 0 0 .6-.52 5.071 5.071 0 0 0-6.336-7.842 5.074 5.074 0 0 0-1.494 1.482 5.072 5.072 0 0 0 .47 6.24c.05.075.071.166.06.255a.376.376 0 0 1-.36.335.379.379 0 0 1-.25-.08 5.81 5.81 0 1 1 8.45.13c-.216.22-.446.423-.69.61a.381.381 0 0 1-.22.07Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M158.84 53.88c-.23.015-.46.015-.69 0a5.868 5.868 0 0 1-2.69-1.05.37.37 0 0 1-.153-.241.376.376 0 0 1 .304-.432.375.375 0 0 1 .279.063 5.079 5.079 0 0 0 3.94.85.372.372 0 0 1 .387.162.379.379 0 0 1-.247.578 6.098 6.098 0 0 1-1.13.07Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M158.78 54.82a6.999 6.999 0 0 1-6.87-5.66 6.903 6.903 0 0 1 .6-4.47.378.378 0 0 1 .36-.208.374.374 0 0 1 .31.538 6.263 6.263 0 0 0-.649 3.398 6.266 6.266 0 0 0 10.345 4.171 6.32 6.32 0 0 0 1.814-2.64.381.381 0 0 1 .47-.23.378.378 0 0 1 .23.48 7 7 0 0 1-5.25 4.53 7.158 7.158 0 0 1-1.36.09ZM165.3 46.89a.361.361 0 0 1-.36-.3 6.272 6.272 0 0 0-10.67-3.12.383.383 0 0 1-.492-.021.382.382 0 0 1-.058-.489 6.995 6.995 0 0 1 7.036-1.904 7.003 7.003 0 0 1 4.914 5.384.376.376 0 0 1-.156.384.37.37 0 0 1-.134.056l-.08.01Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M160.27 55.66a.388.388 0 0 1-.37-.31.383.383 0 0 1 .3-.44 7.25 7.25 0 0 0-.74-14.33.368.368 0 0 1-.323-.256.37.37 0 0 1-.017-.144.383.383 0 0 1 .41-.34 7.997 7.997 0 0 1 7.236 7.555 8 8 0 0 1-6.426 8.255l-.07.01ZM151.8 51.22a.389.389 0 0 1-.35-.23 8.241 8.241 0 0 1-.5-1.64A8.001 8.001 0 0 1 157 40l.28-.06a.38.38 0 0 1 .44.29.357.357 0 0 1-.053.28.364.364 0 0 1-.237.16l-.26.06a7.218 7.218 0 0 0-5.45 8.46c.102.508.256 1.004.46 1.48.038.092.04.195.005.288a.392.392 0 0 1-.195.212.398.398 0 0 1-.19.05ZM178.53 71.37a.269.269 0 0 1-.19-.08l-1.14-1.21a.285.285 0 0 1-.07-.18v-6.26l-.52-.56a.25.25 0 0 1 0-.34l.52-.56v-3.54a.257.257 0 0 1 .07-.17l1.14-1.22a.249.249 0 0 1 .178-.08.258.258 0 0 1 .182.07.264.264 0 0 1 .079.178.256.256 0 0 1-.069.182l-1.08 1.14v3.54a.244.244 0 0 1-.07.17l-.43.46.43.46a.238.238 0 0 1 .07.17v6.26l1.08 1.2a.253.253 0 0 1 0 .35.23.23 0 0 1-.18.02ZM168.58 52a.38.38 0 0 1-.36-.28.38.38 0 0 1 .27-.46l14-3.64a.366.366 0 0 1 .284.033.368.368 0 0 1 .135.512.382.382 0 0 1-.229.175l-14 3.64-.1.02ZM146.72 59a.38.38 0 0 1-.373-.396.374.374 0 0 1 .253-.334l5.16-1.82a.37.37 0 0 1 .48.23.38.38 0 0 1-.23.48L146.85 59h-.13ZM141.66 72.21h-.26a.12.12 0 0 1-.114-.081c-.006-.016-.008-.033-.006-.05v-3.44a.102.102 0 0 1 .006-.048.117.117 0 0 1 .065-.071.123.123 0 0 1 .049-.01h.26a.118.118 0 0 1 .088.038.12.12 0 0 1 .032.092v3.44a.136.136 0 0 1-.006.049.12.12 0 0 1-.066.07.121.121 0 0 1-.048.01Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M142.08 72.21h-.26a.12.12 0 0 1-.114-.081c-.006-.016-.008-.032-.006-.049v-2a.12.12 0 0 1 .12-.12h.26c.031 0 .062.013.084.035a.119.119 0 0 1 .036.085v2a.136.136 0 0 1-.006.049.12.12 0 0 1-.066.071.121.121 0 0 1-.048.01Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M142.5 72.21h-.26a.12.12 0 0 1-.114-.08c-.006-.016-.008-.033-.006-.05v-1.72a.12.12 0 0 1 .12-.12h.26c.031 0 .062.013.084.035a.119.119 0 0 1 .036.085v1.72a.132.132 0 0 1-.12.13Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M142.92 72.21h-.26a.118.118 0 0 1-.113-.08.117.117 0 0 1-.007-.05v-2.75a.117.117 0 0 1 .007-.049.118.118 0 0 1 .065-.07.118.118 0 0 1 .048-.01h.26a.13.13 0 0 1 .12.13v2.75a.13.13 0 0 1-.12.13Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M143.34 72.21h-.26a.121.121 0 0 1-.114-.08.135.135 0 0 1-.006-.05v-1.62a.12.12 0 0 1 .032-.091.118.118 0 0 1 .088-.039h.26a.123.123 0 0 1 .089.039c.011.012.02.026.025.042a.102.102 0 0 1 .006.05v1.62a.103.103 0 0 1-.006.048.118.118 0 0 1-.114.081Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M143.76 72.21h-.26a.121.121 0 0 1-.114-.08.135.135 0 0 1-.006-.05V69.2c0-.032.013-.062.035-.085a.12.12 0 0 1 .085-.035h.26a.12.12 0 0 1 .12.12v2.88a.103.103 0 0 1-.006.05.118.118 0 0 1-.114.08Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M144.18 72.21h-.26a.118.118 0 0 1-.088-.038.12.12 0 0 1-.026-.043.135.135 0 0 1-.006-.049v-3.36c0-.032.013-.062.035-.085a.12.12 0 0 1 .085-.035h.26a.12.12 0 0 1 .12.12v3.36a.102.102 0 0 1-.006.05.118.118 0 0 1-.065.07.123.123 0 0 1-.049.01Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M144.6 72.21h-.26a.121.121 0 0 1-.114-.081.135.135 0 0 1-.006-.049V69.6c0-.032.013-.062.035-.085a.12.12 0 0 1 .085-.035h.26a.12.12 0 0 1 .085.035.118.118 0 0 1 .035.085v2.48a.118.118 0 0 1-.12.13Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M145 72.21h-.26a.123.123 0 0 1-.089-.038.118.118 0 0 1-.025-.043c-.006-.016-.008-.032-.006-.049V69.2a.12.12 0 0 1 .12-.12h.26c.031 0 .062.013.084.035a.119.119 0 0 1 .036.085v2.88a.117.117 0 0 1-.007.05.118.118 0 0 1-.113.08Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M145.44 72.21h-.26a.122.122 0 0 1-.089-.039.118.118 0 0 1-.031-.09V69a.118.118 0 0 1 .072-.12.118.118 0 0 1 .048-.01h.26a.124.124 0 0 1 .089.039.134.134 0 0 1 .025.041.135.135 0 0 1 .006.05v3.07a.115.115 0 0 1-.003.051.115.115 0 0 1-.066.078.118.118 0 0 1-.051.01Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M145.86 72.21h-.26a.122.122 0 0 1-.089-.039.118.118 0 0 1-.031-.09V70a.12.12 0 0 1 .12-.12h.26a.12.12 0 0 1 .14.12v2.07a.14.14 0 0 1-.003.057.12.12 0 0 1-.137.082Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M146.28 72.21H146a.121.121 0 0 1-.114-.081.135.135 0 0 1-.006-.05v-2.83c0-.03.013-.061.035-.084a.12.12 0 0 1 .085-.035h.26a.118.118 0 0 1 .085.035.122.122 0 0 1 .035.085v2.83a.117.117 0 0 1-.024.085.122.122 0 0 1-.076.045ZM150.06 72.21h-.26a.13.13 0 0 1-.086-.042.131.131 0 0 1-.034-.088v-3.44a.13.13 0 0 1 .12-.13h.26a.118.118 0 0 1 .088.038.12.12 0 0 1 .032.092v3.44a.136.136 0 0 1-.006.049.12.12 0 0 1-.066.07.121.121 0 0 1-.048.01ZM149.22 72.21H149a.132.132 0 0 1-.086-.041.131.131 0 0 1-.034-.089v-.56c0-.033.012-.064.034-.088a.133.133 0 0 1 .086-.042h.26a.118.118 0 0 1 .088.039.12.12 0 0 1 .026.042.136.136 0 0 1 .006.05v.56c.003.02 0 .04-.007.06a.125.125 0 0 1-.036.05.122.122 0 0 1-.117.02Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M148.8 72.21h-.26a.133.133 0 0 1-.086-.041.131.131 0 0 1-.034-.089v-1c0-.032.013-.062.035-.085a.12.12 0 0 1 .085-.035h.26a.12.12 0 0 1 .085.035.122.122 0 0 1 .035.085v1a.136.136 0 0 1-.006.049.135.135 0 0 1-.025.042.124.124 0 0 1-.089.039ZM146.7 72.21h-.26a.123.123 0 0 1-.089-.038.118.118 0 0 1-.025-.043.102.102 0 0 1-.006-.049v-2.75a.102.102 0 0 1 .006-.049.118.118 0 0 1 .065-.07.123.123 0 0 1 .049-.01h.26a.118.118 0 0 1 .088.038.12.12 0 0 1 .026.042.135.135 0 0 1 .006.05v2.75a.135.135 0 0 1-.006.048.12.12 0 0 1-.026.043.118.118 0 0 1-.088.038Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M148.38 72.21h-.26a.121.121 0 0 1-.088-.038.118.118 0 0 1-.025-.043.117.117 0 0 1-.007-.049v-1.62a.117.117 0 0 1 .007-.049.117.117 0 0 1 .065-.07.118.118 0 0 1 .048-.01h.26a.12.12 0 0 1 .114.08c.006.016.008.032.006.05v1.62a.103.103 0 0 1-.006.048.118.118 0 0 1-.114.081ZM149.64 72.21h-.26a.121.121 0 0 1-.114-.08.135.135 0 0 1-.006-.05V69.2c0-.032.013-.062.035-.085a.12.12 0 0 1 .085-.035h.26a.12.12 0 0 1 .12.12v2.88a.103.103 0 0 1-.006.05.118.118 0 0 1-.114.08Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M148 72.21h-.26a.12.12 0 0 1-.114-.08c-.006-.017-.008-.033-.006-.05v-3.36a.12.12 0 0 1 .12-.12h.26c.031 0 .062.013.084.035a.12.12 0 0 1 .036.085v3.36a.117.117 0 0 1-.007.05.118.118 0 0 1-.065.07.118.118 0 0 1-.048.01Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M147.54 72.21h-.26a.123.123 0 0 1-.089-.039.118.118 0 0 1-.025-.042c-.006-.016-.008-.032-.006-.049V69.6a.12.12 0 0 1 .12-.12h.26c.031 0 .062.013.084.035a.119.119 0 0 1 .036.085v2.48a.117.117 0 0 1-.007.049.118.118 0 0 1-.113.081ZM151.32 72.21h-.26a.132.132 0 0 1-.12-.13V69.2c0-.032.013-.062.036-.085a.119.119 0 0 1 .084-.035h.26a.12.12 0 0 1 .12.12v2.88a.103.103 0 0 1-.006.05.118.118 0 0 1-.114.08Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M150.9 72.21h-.26a.13.13 0 0 1-.12-.13V69a.13.13 0 0 1 .12-.13h.26c.03.005.057.021.075.045A.123.123 0 0 1 151 69v3.07a.152.152 0 0 1-.001.048.136.136 0 0 1-.02.043.112.112 0 0 1-.035.033.117.117 0 0 1-.044.016ZM147.12 72.21h-.26a.118.118 0 0 1-.114-.081c-.006-.016-.008-.032-.006-.05V70a.12.12 0 0 1 .12-.12h.26a.122.122 0 0 1 .12.12v2.07a.118.118 0 0 1-.028.098.115.115 0 0 1-.092.043Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M150.48 72.21h-.26a.13.13 0 0 1-.12-.13v-2.83a.12.12 0 0 1 .12-.12h.26c.031 0 .062.013.084.035a.119.119 0 0 1 .036.085v2.83a.135.135 0 0 1-.006.049.136.136 0 0 1-.026.042.118.118 0 0 1-.088.039ZM151.74 72.21h-.26a.13.13 0 0 1-.12-.13v-2c0-.032.013-.062.035-.085a.123.123 0 0 1 .085-.035h.26a.12.12 0 0 1 .12.12v2a.103.103 0 0 1-.006.049.118.118 0 0 1-.114.081Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M152.16 72.21h-.26a.13.13 0 0 1-.085-.041.127.127 0 0 1-.035-.089v-.56a.13.13 0 0 1 .12-.13h.26a.12.12 0 0 1 .114.081c.006.016.008.033.006.05v.56a.102.102 0 0 1-.006.048.117.117 0 0 1-.065.071.124.124 0 0 1-.049.01Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M152.58 72.21h-.26a.128.128 0 0 1-.085-.041.127.127 0 0 1-.035-.089v-1a.12.12 0 0 1 .035-.085.122.122 0 0 1 .085-.035h.26a.12.12 0 0 1 .12.12v1a.103.103 0 0 1-.006.049.118.118 0 0 1-.114.081Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M153 72.21h-.26a.127.127 0 0 1-.085-.041.128.128 0 0 1-.035-.089v-2.75c0-.033.012-.064.035-.088a.127.127 0 0 1 .085-.042h.26a.122.122 0 0 1 .089.039.118.118 0 0 1 .031.091v2.75a.118.118 0 0 1-.072.12.115.115 0 0 1-.048.01Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M153.42 72.21h-.26a.127.127 0 0 1-.085-.041.127.127 0 0 1-.035-.089v-1.62c0-.033.012-.064.035-.088a.127.127 0 0 1 .085-.042h.26a.122.122 0 0 1 .089.039c.011.012.02.026.025.042a.135.135 0 0 1 .006.05v1.62a.118.118 0 0 1-.12.13Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M153.84 72.21h-.26a.132.132 0 0 1-.086-.041.131.131 0 0 1-.034-.089V69.2c0-.032.013-.062.035-.085a.12.12 0 0 1 .085-.035h.26a.12.12 0 0 1 .085.035.122.122 0 0 1 .035.085v2.88a.135.135 0 0 1-.006.05.135.135 0 0 1-.025.041.124.124 0 0 1-.089.04Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M154.26 72.21H154a.132.132 0 0 1-.086-.041.131.131 0 0 1-.034-.089v-3.36a.13.13 0 0 1 .12-.12h.26a.12.12 0 0 1 .085.035.122.122 0 0 1 .035.085v3.36a.135.135 0 0 1-.006.05.12.12 0 0 1-.026.041.118.118 0 0 1-.088.04Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M154.68 72.21h-.26a.132.132 0 0 1-.086-.041.131.131 0 0 1-.034-.089V69.6a.12.12 0 0 1 .12-.12h.26a.12.12 0 0 1 .085.035.122.122 0 0 1 .035.085v2.48a.135.135 0 0 1-.006.049.12.12 0 0 1-.066.071.121.121 0 0 1-.048.01Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M155.1 72.21h-.26a.13.13 0 0 1-.12-.13V69.2a.12.12 0 0 1 .12-.12h.26a.12.12 0 0 1 .085.035.122.122 0 0 1 .035.085v2.88a.135.135 0 0 1-.006.05.12.12 0 0 1-.066.07.121.121 0 0 1-.048.01Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M155.52 72.21h-.25a.129.129 0 0 1-.092-.038.127.127 0 0 1-.038-.092V69c0-.034.013-.067.038-.092a.129.129 0 0 1 .092-.038h.25a.121.121 0 0 1 .114.081.135.135 0 0 1 .006.05v3.07a.117.117 0 0 1-.028.098.113.113 0 0 1-.041.03.121.121 0 0 1-.051.011Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M155.94 72.21h-.25a.129.129 0 0 1-.092-.038.127.127 0 0 1-.038-.092V70c0-.017.003-.033.01-.049a.113.113 0 0 1 .071-.065.117.117 0 0 1 .049-.006h.25c.031 0 .062.013.084.035a.119.119 0 0 1 .036.085v2.07a.117.117 0 0 1-.028.098.113.113 0 0 1-.041.031.121.121 0 0 1-.051.01Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M156.36 72.21h-.26a.132.132 0 0 1-.12-.13v-2.83c0-.016.004-.031.01-.046a.116.116 0 0 1 .065-.065.117.117 0 0 1 .045-.01h.26a.12.12 0 0 1 .12.12v2.83a.103.103 0 0 1-.006.05.118.118 0 0 1-.065.07.123.123 0 0 1-.049.01Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M156.78 72.21h-.25a.132.132 0 0 1-.092-.038.131.131 0 0 1-.038-.092v-2.75c0-.034.014-.067.038-.092a.132.132 0 0 1 .092-.038h.25a.123.123 0 0 1 .089.039c.011.012.02.026.025.042.006.016.008.033.006.05v2.75a.102.102 0 0 1-.006.048.118.118 0 0 1-.065.071.123.123 0 0 1-.049.01ZM157.62 72.21h-.25a.132.132 0 0 1-.092-.038.131.131 0 0 1-.038-.092V69.6c0-.017.004-.033.01-.048a.13.13 0 0 1 .029-.04.122.122 0 0 1 .091-.031h.25a.118.118 0 0 1 .12.12v2.479a.102.102 0 0 1-.006.049.118.118 0 0 1-.065.07.123.123 0 0 1-.049.01Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M157.2 72.21h-.2a.132.132 0 0 1-.092-.038.131.131 0 0 1-.038-.092V70c0-.017.003-.033.01-.049a.115.115 0 0 1 .029-.04.122.122 0 0 1 .091-.031h.25c.032 0 .062.013.085.035a.122.122 0 0 1 .035.085v2.07a.12.12 0 0 1-.17.14ZM158.05 72.21h-.26a.133.133 0 0 1-.086-.042.131.131 0 0 1-.034-.088v-3.44c0-.033.012-.065.034-.089a.132.132 0 0 1 .086-.041h.26a.12.12 0 0 1 .089.039.133.133 0 0 1 .025.042.135.135 0 0 1 .006.049v3.44a.136.136 0 0 1-.006.049.135.135 0 0 1-.025.042.124.124 0 0 1-.089.039Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M158.47 72.21h-.26a.133.133 0 0 1-.086-.041.131.131 0 0 1-.034-.089v-2c0-.032.012-.062.035-.085a.12.12 0 0 1 .085-.035h.26a.12.12 0 0 1 .085.035.122.122 0 0 1 .035.085v2a.136.136 0 0 1-.006.049.12.12 0 0 1-.066.071.121.121 0 0 1-.048.01Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M158.89 72.21h-.26a.132.132 0 0 1-.086-.041.131.131 0 0 1-.034-.089v-1.72a.12.12 0 0 1 .12-.12h.26c.032 0 .062.013.085.035a.122.122 0 0 1 .035.085v1.72a.12.12 0 0 1-.032.091.117.117 0 0 1-.088.04Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M159.31 72.21h-.26a.13.13 0 0 1-.12-.13v-2.75a.13.13 0 0 1 .12-.13h.26a.118.118 0 0 1 .088.039.12.12 0 0 1 .026.042.135.135 0 0 1 .006.05v2.75a.135.135 0 0 1-.006.048.12.12 0 0 1-.026.043.118.118 0 0 1-.088.038Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M159.73 72.21h-.25a.129.129 0 0 1-.092-.038.127.127 0 0 1-.038-.092v-1.62a.129.129 0 0 1 .13-.13h.25a.118.118 0 0 1 .088.039c.011.012.02.026.026.042a.135.135 0 0 1 .006.05v1.62a.135.135 0 0 1-.006.048.138.138 0 0 1-.026.042.12.12 0 0 1-.088.04Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M160.15 72.21h-.25a.129.129 0 0 1-.092-.038.127.127 0 0 1-.038-.092V69.2a.102.102 0 0 1 .01-.048.113.113 0 0 1 .071-.066.117.117 0 0 1 .049-.006h.25c.031 0 .062.013.084.035a.119.119 0 0 1 .036.085v2.88a.117.117 0 0 1-.007.049.118.118 0 0 1-.065.071.118.118 0 0 1-.048.01Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M160.57 72.21h-.26a.13.13 0 0 1-.085-.041.132.132 0 0 1-.035-.089v-3.36a.13.13 0 0 1 .12-.12h.26a.12.12 0 0 1 .12.12v3.36a.102.102 0 0 1-.006.05.118.118 0 0 1-.065.07.12.12 0 0 1-.049.01Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M161 72.21h-.25a.132.132 0 0 1-.092-.038.131.131 0 0 1-.038-.092V69.6a.116.116 0 0 1 .039-.089.122.122 0 0 1 .091-.031h.25a.12.12 0 0 1 .12.12v2.48a.118.118 0 0 1-.072.12.115.115 0 0 1-.048.01Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M161.41 72.21h-.25a.132.132 0 0 1-.092-.038.131.131 0 0 1-.038-.092V69.2c0-.017.004-.033.01-.048a.123.123 0 0 1 .071-.066.122.122 0 0 1 .049-.006h.25a.12.12 0 0 1 .12.12v2.88a.102.102 0 0 1-.006.049.118.118 0 0 1-.065.071.123.123 0 0 1-.049.01Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M161.83 72.21h-.25a.132.132 0 0 1-.092-.038.132.132 0 0 1-.038-.092V69c0-.035.014-.068.038-.092a.132.132 0 0 1 .092-.038h.25a.12.12 0 0 1 .17.13v3.07a.115.115 0 0 1-.001.069.12.12 0 0 1-.169.071Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M162.25 72.21H162a.132.132 0 0 1-.092-.038.131.131 0 0 1-.038-.092V70c0-.017.003-.033.01-.049a.115.115 0 0 1 .029-.04.122.122 0 0 1 .091-.031h.25c.032 0 .062.013.085.035a.122.122 0 0 1 .035.085v2.07a.115.115 0 0 1-.003.052.115.115 0 0 1-.066.077.118.118 0 0 1-.051.01Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M162.67 72.21h-.25a.132.132 0 0 1-.092-.038.131.131 0 0 1-.038-.092v-2.83c0-.017.003-.033.01-.049a.115.115 0 0 1 .029-.04.12.12 0 0 1 .091-.031h.25c.032 0 .062.013.085.035a.122.122 0 0 1 .035.085v2.83a.118.118 0 0 1-.072.12.115.115 0 0 1-.048.01ZM166.45 72.21h-.25a.132.132 0 0 1-.092-.038.132.132 0 0 1-.038-.092v-3.44c0-.035.014-.068.038-.092a.132.132 0 0 1 .092-.038h.25a.123.123 0 0 1 .089.038c.011.013.02.027.025.043.006.016.008.032.006.049v3.44a.103.103 0 0 1-.006.049.118.118 0 0 1-.114.08ZM165.61 72.21h-.25a.128.128 0 0 1-.091-.038.129.129 0 0 1-.039-.092v-.56c0-.034.014-.067.039-.092a.128.128 0 0 1 .091-.038h.25a.123.123 0 0 1 .089.039c.011.012.02.026.025.042.006.016.008.033.006.05v.56a.102.102 0 0 1-.006.048.117.117 0 0 1-.065.071.12.12 0 0 1-.049.01Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M165.19 72.21h-.25a.129.129 0 0 1-.092-.038.127.127 0 0 1-.038-.092v-1c0-.017.003-.033.01-.049a.113.113 0 0 1 .071-.065.117.117 0 0 1 .049-.006h.25c.031 0 .062.013.084.035a.119.119 0 0 1 .036.085v1a.117.117 0 0 1-.007.049.118.118 0 0 1-.065.07.121.121 0 0 1-.048.01ZM163.09 72.21h-.25a.129.129 0 0 1-.092-.038.131.131 0 0 1-.038-.092v-2.75c0-.034.014-.067.038-.092a.129.129 0 0 1 .092-.038h.25a.122.122 0 0 1 .089.039.134.134 0 0 1 .025.042.135.135 0 0 1 .006.05v2.75a.135.135 0 0 1-.006.048.134.134 0 0 1-.025.043.122.122 0 0 1-.089.038Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M164.77 72.21h-.25a.129.129 0 0 1-.092-.038.127.127 0 0 1-.038-.092v-1.62a.129.129 0 0 1 .13-.13h.25a.118.118 0 0 1 .088.039.12.12 0 0 1 .032.091v1.62a.135.135 0 0 1-.006.05.12.12 0 0 1-.066.07.121.121 0 0 1-.048.01ZM166 72.21h-.25a.132.132 0 0 1-.092-.038.131.131 0 0 1-.038-.092V69.2a.116.116 0 0 1 .039-.089.122.122 0 0 1 .091-.031h.25c.032 0 .062.013.085.035a.122.122 0 0 1 .035.085v2.88a.118.118 0 0 1-.072.12.115.115 0 0 1-.048.01Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M164.35 72.21h-.25a.129.129 0 0 1-.092-.038.127.127 0 0 1-.038-.092v-3.36a.13.13 0 0 1 .13-.12h.25a.12.12 0 0 1 .085.035.122.122 0 0 1 .035.085v3.36a.135.135 0 0 1-.006.05.12.12 0 0 1-.026.041.118.118 0 0 1-.088.04Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M163.93 72.21h-.25a.129.129 0 0 1-.092-.038.127.127 0 0 1-.038-.092V69.6c0-.017.003-.033.01-.048a.113.113 0 0 1 .071-.066.12.12 0 0 1 .049-.006h.25a.12.12 0 0 1 .12.12v2.48a.135.135 0 0 1-.006.049.12.12 0 0 1-.026.042.118.118 0 0 1-.088.039ZM167.71 72.21h-.25a.132.132 0 0 1-.092-.038.131.131 0 0 1-.038-.092V69.2a.116.116 0 0 1 .039-.089.122.122 0 0 1 .091-.031h.25c.032 0 .062.013.085.035a.122.122 0 0 1 .035.085v2.88a.118.118 0 0 1-.072.12.115.115 0 0 1-.048.01Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M167.29 72.21H167a.132.132 0 0 1-.092-.038.131.131 0 0 1-.038-.092V69c0-.034.014-.067.038-.092a.132.132 0 0 1 .092-.038h.25a.124.124 0 0 1 .089.039.118.118 0 0 1 .031.091v3.07c.007.03.002.06-.013.086a.122.122 0 0 1-.067.054ZM163.51 72.21h-.25a.129.129 0 0 1-.092-.038.131.131 0 0 1-.038-.092V70a.127.127 0 0 1 .038-.089.12.12 0 0 1 .092-.031h.25a.12.12 0 0 1 .12.12v2.07a.115.115 0 0 1-.003.052.129.129 0 0 1-.024.046.121.121 0 0 1-.093.042Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M166.87 72.21h-.25a.132.132 0 0 1-.092-.038.131.131 0 0 1-.038-.092v-2.83c0-.017.004-.033.01-.049a.13.13 0 0 1 .029-.04.122.122 0 0 1 .091-.031h.25a.12.12 0 0 1 .12.12v2.83a.102.102 0 0 1-.006.049.118.118 0 0 1-.065.07.123.123 0 0 1-.049.01ZM168.13 72.21h-.25a.13.13 0 0 1-.13-.13v-2a.116.116 0 0 1 .039-.089.12.12 0 0 1 .091-.031h.25a.12.12 0 0 1 .085.035.122.122 0 0 1 .035.085v2a.135.135 0 0 1-.006.049.134.134 0 0 1-.025.042.122.122 0 0 1-.089.039Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M168.55 72.21h-.25a.129.129 0 0 1-.13-.13v-.56c0-.034.014-.067.038-.092a.129.129 0 0 1 .092-.038h.25a.124.124 0 0 1 .089.039.135.135 0 0 1 .025.042.136.136 0 0 1 .006.05v.56a.135.135 0 0 1-.006.048.133.133 0 0 1-.025.042.123.123 0 0 1-.089.04Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M169 72.21h-.25a.132.132 0 0 1-.092-.038.131.131 0 0 1-.038-.092v-1a.116.116 0 0 1 .039-.089.122.122 0 0 1 .091-.031h.25a.12.12 0 0 1 .085.035.122.122 0 0 1 .035.085v1a.118.118 0 0 1-.072.12.118.118 0 0 1-.048.01Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M169.39 72.21h-.25a.129.129 0 0 1-.092-.038.127.127 0 0 1-.038-.092v-2.75c0-.034.013-.067.038-.092a.129.129 0 0 1 .092-.038h.25a.118.118 0 0 1 .088.039.12.12 0 0 1 .026.042.135.135 0 0 1 .006.05v2.75a.135.135 0 0 1-.006.048.12.12 0 0 1-.026.043.118.118 0 0 1-.088.038Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M169.81 72.21h-.25a.129.129 0 0 1-.092-.038.127.127 0 0 1-.038-.092v-1.62a.129.129 0 0 1 .13-.13h.25a.118.118 0 0 1 .088.039.12.12 0 0 1 .032.091v1.62a.135.135 0 0 1-.006.05.12.12 0 0 1-.066.07.121.121 0 0 1-.048.01Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M170.23 72.21H170a.132.132 0 0 1-.092-.038.131.131 0 0 1-.038-.092V69.2a.116.116 0 0 1 .039-.089.122.122 0 0 1 .091-.031h.25c.032 0 .062.013.085.035a.122.122 0 0 1 .035.085v2.88a.125.125 0 0 1-.007.054.121.121 0 0 1-.133.076Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M170.65 72.21h-.25a.129.129 0 0 1-.092-.038.127.127 0 0 1-.038-.092v-3.36a.13.13 0 0 1 .13-.12h.25c.031 0 .062.013.084.035a.12.12 0 0 1 .036.085v3.36a.117.117 0 0 1-.007.05.118.118 0 0 1-.065.07.118.118 0 0 1-.048.01Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M171.07 72.21h-.25a.128.128 0 0 1-.091-.038.128.128 0 0 1-.039-.092V69.6c0-.017.004-.033.01-.048a.123.123 0 0 1 .071-.066.122.122 0 0 1 .049-.006h.25c.032 0 .063.013.085.035a.118.118 0 0 1 .035.085v2.48a.102.102 0 0 1-.006.049.118.118 0 0 1-.065.07.12.12 0 0 1-.049.01Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M171.49 72.21h-.25a.132.132 0 0 1-.092-.038.131.131 0 0 1-.038-.092V69.2c0-.017.004-.033.01-.048a.123.123 0 0 1 .071-.066.122.122 0 0 1 .049-.006h.25a.12.12 0 0 1 .12.12v2.88a.102.102 0 0 1-.006.049.118.118 0 0 1-.065.071.123.123 0 0 1-.049.01Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M171.91 72.21h-.25a.132.132 0 0 1-.092-.038.131.131 0 0 1-.038-.092V69c0-.034.014-.067.038-.092a.132.132 0 0 1 .092-.038h.25a.13.13 0 0 1 .09.13v3.07a.13.13 0 0 1-.019.086.133.133 0 0 1-.071.054Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M172.33 72.21h-.25a.132.132 0 0 1-.092-.038.131.131 0 0 1-.038-.092V70c0-.017.004-.033.01-.049a.13.13 0 0 1 .029-.04.122.122 0 0 1 .091-.031h.25a.12.12 0 0 1 .13.12v2.07a.134.134 0 0 1-.034.099.126.126 0 0 1-.044.03.127.127 0 0 1-.052.01Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M172.75 72.21h-.25a.132.132 0 0 1-.092-.038.131.131 0 0 1-.038-.092v-2.83c0-.017.003-.033.01-.049a.115.115 0 0 1 .029-.04.122.122 0 0 1 .091-.031h.25a.12.12 0 0 1 .13.12v2.83a.131.131 0 0 1-.038.092.129.129 0 0 1-.092.038Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M173.17 72.21h-.25a.132.132 0 0 1-.092-.038.131.131 0 0 1-.038-.092v-2.75c0-.034.014-.067.038-.092a.132.132 0 0 1 .092-.038h.25c.035 0 .068.014.092.038a.131.131 0 0 1 .038.092v2.75a.131.131 0 0 1-.038.092.129.129 0 0 1-.092.038ZM174 72.21h-.25a.132.132 0 0 1-.092-.038.131.131 0 0 1-.038-.092V69.6a.116.116 0 0 1 .039-.089.122.122 0 0 1 .091-.031h.25a.12.12 0 0 1 .13.12v2.48a.131.131 0 0 1-.038.092.129.129 0 0 1-.092.038Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M173.59 72.21h-.25a.129.129 0 0 1-.092-.038.131.131 0 0 1-.038-.092V70c0-.017.003-.033.01-.049a.115.115 0 0 1 .029-.04.12.12 0 0 1 .091-.031h.25a.12.12 0 0 1 .13.12v2.07a.135.135 0 0 1-.034.098.136.136 0 0 1-.044.031.127.127 0 0 1-.052.01Z"
    />
  </SvgIcon>
);

export default Education;
