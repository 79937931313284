import { useEffect, useState } from 'react';

import useClientWidth from './useClientWidth';

export default (containerElement: Element | null, cardWidth: number, gap: number): number => {
  const clientWidth = containerElement ? containerElement.clientWidth : 0;
  const totalCardWidth = cardWidth + gap;
  const [slidesCount, setSlidesCount] = useState(0);
  const documentWidth = useClientWidth();

  useEffect(() => {
    setSlidesCount(clientWidth / totalCardWidth);
  }, [clientWidth, totalCardWidth, documentWidth]);

  return slidesCount;
};
