import { IRootState } from 'reducers';
import { createSelector } from 'reselect';

const proforientationResultLinkSelector = createSelector(
  [(state: IRootState) => state.proforientation],
  (proforientation) => {
    return proforientation.content?.detailResultsUrl ?? null;
  },
);

export default proforientationResultLinkSelector;
