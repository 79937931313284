import { FC, useRef } from 'react';
import { AriaButtonOptions, useButton } from 'react-aria';

import { ButtonProps } from '@mui/material';
import { IconClose } from 'icons/edit';
import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';
import { mergeSx } from 'utils';

import CommonInputButton from './CommonInputButton';

const styles: SxStyles = {
  backgroundColor: 'transparent',
  transition: 'background-color 0.2s',

  '&:hover, &:focus-visible': {
    backgroundColor: NeutralColors.light_neutrals_200,
  },
};

interface IClearInputButtonProps {
  ariaOptions?: AriaButtonOptions<'button'>;
}

const ClearInputButton: FC<ButtonProps & IClearInputButtonProps> = ({ ariaOptions = {}, ...props }) => {
  const localRef = useRef(null);

  const { buttonProps } = useButton(ariaOptions, localRef);

  return (
    <CommonInputButton
      icon={<IconClose />}
      buttonProps={{ ...props, sx: mergeSx(styles, props.sx) }}
      ref={localRef}
      htmlAttributes={buttonProps}
    />
  );
};

export default ClearInputButton;
