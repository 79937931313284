/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useContext, useMemo } from 'react';

import { ActivitySecondaryBlock } from '../basicBlocks';
import { IDictionaryItem } from '../../../../../api/types';
import { DifferentCode } from '../../../../../const';
import { IEventData } from 'portfolio3/features/dataEntryForm';

import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';
import { BaseInputLabel, FormControl, Input } from 'portfolio3/ui-kit';
import { SearchSelect } from 'portfolio3/ui-kit/selects';
import { FormHelperText } from '@mui/material';
import { IController, TokenFieldController } from 'portfolio3/ui-kit/types';
import { StretchyTokenField } from 'portfolio3/ui-kit/autocomplete';

interface ICompetitionSecondaryBlockProps {
  subjectOptions: IDictionaryItem[];
  eventLevelOptions: IDictionaryItem[];
  sportKindOptions: IDictionaryItem[];
  eventData: IEventData;
}

const CompetitionSecondaryBlock: React.FC<ICompetitionSecondaryBlockProps> = ({
  subjectOptions,
  eventLevelOptions,
  sportKindOptions,
  eventData,
}) => {
  const { inputRenderMode, inputSize, formData, formErrors, onChangeFormData, onChangeFormErrors } = useContext(LocalDataEntryFormContext);

  const sportKindController: IController<string | undefined> = useMemo(() => ({
    handleChange: (sportKind) => {
      onChangeFormData((prevstate) => ({
        ...prevstate,
        sportKind: Number(sportKind),
        subjectId: []
      }));
      onChangeFormErrors((prevstate) => ({
        ...prevstate,
        sportKindError: false
      }));
    },
    handleBlur: (type) => {
      if (!type) {
        onChangeFormErrors((prevstate) => ({
          ...prevstate,
          sportKindError: true
        }));
      }
    }
  }), [formData.id]);

  const secondNameController = useMemo(() => ({
    handleChange: (secondName: string) => {
      onChangeFormData((prevstate) => ({
        ...prevstate,
        secondName
      }));
      onChangeFormErrors((prevstate) => ({
        ...prevstate,
        secondNameError: false
      }));
    },
    handleBlur: (secondName: any) => {
      if (!secondName) {
        onChangeFormErrors((prevstate) => ({
          ...prevstate,
          secondNameError: true
        }));
      }
    }
  }), [formData.id]);

  const subjectController: TokenFieldController = useMemo(() => ({
    handleChange: (subjects) => {
      onChangeFormData((prevState) => ({
        ...prevState,
        subjectId: subjects.map((subject) => subject.code),
      }));
    }
  }), [formData.id]);

  const additionalTopControllers = (
    <>
      <FormControl
        sx={{ width: '100%' }}
        required
        error={formErrors.sportKindError}
        renderMode={inputRenderMode}
        inputSize={inputSize}
        label={<BaseInputLabel>Вид спорта</BaseInputLabel>}
        helper={formErrors.sportKindError && <FormHelperText>Выберите вид спорта</FormHelperText>}
        control={
          <SearchSelect
            inputRenderMode={inputRenderMode}
            inputSize={inputSize}
            placeholder="Выберите вид спорта..."
            value={formData?.sportKind}
            options={sportKindOptions}
            controller={sportKindController}
            strictMaxWidth
          />
        }
      />
      <FormControl
        disabled={!formData?.sportKind}
        renderMode={inputRenderMode}
        inputSize={inputSize}
        label={<BaseInputLabel>Дисциплина</BaseInputLabel>}
        control={
          <StretchyTokenField
            size={inputSize}
            inputRenderMode={inputRenderMode}
            options={subjectOptions || []}
            controller={subjectController}
            value={formData.subjectId}
            placeholder={formData?.sportKind ? 'Начните вводить название дисциплины...' : 'Сначала выберите вид спорта'}
          />
        }
      />
      {formData.name === DifferentCode && (
        <FormControl
          sx={{ width: '100%' }}
          required
          error={formErrors.secondNameError}
          renderMode={inputRenderMode}
          inputSize={inputSize}
          label={<BaseInputLabel>Название соревнования</BaseInputLabel>}
          helper={formErrors.secondNameError && <FormHelperText>Введите название соревнования</FormHelperText>}
          control={
            <Input
              renderMode={inputRenderMode}
              size={inputSize}
              placeholder="Введите название соревнования..."
              value={formData.secondName}
              controller={secondNameController}
            />
          }
        />
      )}
    </>
  );

  const organizatorLabel = 'Организатор соревнования';
  const organizatorPlaceholder = 'Введите название организатора соревнования...';
  return (
    <ActivitySecondaryBlock
      eventLevelOptions={eventLevelOptions}
      additionalTopControllers={additionalTopControllers}
      eventData={eventData}
      organizatorLabel={organizatorLabel}
      organizatorPlaceholder={organizatorPlaceholder}
    />
  );
};

export default CompetitionSecondaryBlock;
