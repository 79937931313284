/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import DiagnosticCard from '../DiagnosticCard';
import { IIndependentSelfDiagnostic } from '../../../../../api/types';

import './index.scss';

interface IDiagnosticCardProps {
  diagnostic: IIndependentSelfDiagnostic;
  year?: number;
}

const DiagnosticCardWrapper: React.VFC<IDiagnosticCardProps> = ({
  diagnostic,
  year
}) => {
  return (
    <div className="pdf-diagnostic-card-wrapper pdf-keep">
      {year && <div className="list-year">{year}</div>}
      <div className="pdf-diagnostic-card-wrapper__side">
        <div className="pdf-diagnostic-card-wrapper__line">
          <div className="pdf-diagnostic-card-wrapper__pin"></div>
        </div>
      </div>
      <DiagnosticCard diagnostic={diagnostic} />
    </div>
  );
};

export default DiagnosticCardWrapper;
