import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { ToggleBadge } from 'portfolio3/ui-kit/badges';

import { IMetaskillsBodyProps } from '../types';
import * as styles from './styles';

const MetaskillsContainerBody: FC<IMetaskillsBodyProps> = ({ handleSelectBadge, metaskills, selectedMetaskills }) => {
  const metaskillsElements = metaskills.map((skill) => {
    const isBadgeSelected = selectedMetaskills.includes(skill.code);
    return (
      <ToggleBadge selected={isBadgeSelected} key={skill.id} onClick={() => handleSelectBadge(skill)}>
        {skill.value}
      </ToggleBadge>
    );
  });
  return (
    <Box sx={styles.bodyContainer} className="metaskills-container__body">
      <Box className="body__title">
        <Typography variant="Paragraph MD/Regular">Выбери навыки:</Typography>
      </Box>
      <Box sx={styles.badges} className="body__badges">
        {metaskillsElements}
      </Box>
    </Box>
  );
};

export default MetaskillsContainerBody;
