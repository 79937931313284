import React from 'react';

import { Slide, Slider } from 'pure-react-carousel';

import { IGiaWorldSkillsEntity } from '../../../../../types';
import Carousel from '../../../../common/Carousel';
import GiaDataEntity from '../gia';

import './index.scss';

interface IGiaCarouselProps {
  studentGiaWorldSkills: IGiaWorldSkillsEntity[];
}

const GiaCarousel: React.FC<IGiaCarouselProps> = ({ studentGiaWorldSkills }) => {
  return (
    <div className="gia-carousel">
      <Carousel items={studentGiaWorldSkills} cardWidth={224} gap={12}>
        <Slider>
          {studentGiaWorldSkills.map((item, index) => (
            <Slide key={item.id || index} index={index} className="slide">
              <GiaDataEntity entity={item} />
            </Slide>
          ))}
        </Slider>
      </Carousel>
    </div>
  );
};

export default GiaCarousel;
