import { createSelector } from 'reselect';

import { IRootState } from '../../../reducers';
import { mapStudyOlympiadResponse } from './mappers';

const studyOlympiadsSelector = createSelector(
  [
    (state: IRootState) => state.studentEvents,
    (state: IRootState) => state.studentRewards,
    (state: IRootState) => state.sectionRef,
  ],
  (studentEvents, studentRewards, sectionRef) => {
    return mapStudyOlympiadResponse(studentEvents, studentRewards, sectionRef);
  },
);

export default studyOlympiadsSelector;
