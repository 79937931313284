import { DrawerProps } from '@mui/material';
import { SxStyles } from 'types';

interface IRadiuses {
  mobile: string;
  left: string;
  right: string;
}

export const drawerPaper =
  (isFullHeight?: boolean, isMobile?: boolean, anchor?: DrawerProps['anchor']): SxStyles =>
  () => {
    const radiuses: IRadiuses = {
      mobile: '16px 16px 0 0',
      left: '0 8px 8px 0',
      right: '8px 0 0 8px',
    };
    const borderRadius = isMobile ? radiuses.mobile : anchor === 'left' ? radiuses.left : radiuses.right;

    return {
      width: '100vw',
      maxWidth: '724px',

      borderRadius,
      height: isFullHeight ? '100%' : undefined,
    };
  };
