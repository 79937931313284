import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const MagnifyingGlass: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.5 6.5C14.5 9.26142 12.2614 11.5 9.5 11.5C6.73858 11.5
      4.5 9.26142 4.5 6.5C4.5 3.73858 6.73858 1.5 9.5 1.5C12.2614 1.5 14.5 3.73858 14.5 6.5Z"
      fill="#39A2FD"
    />
    <path
      d="M12.174 3.86603C12.4502 4.34432 12.3832 4.89994 12.0245 5.10705C11.6658
      5.31416 11.1511 5.09432 10.875 4.61603C10.5989 4.13773 10.6658 3.58211 11.0245
       3.375C11.3832 3.16789 11.8979 3.38773 12.174 3.86603Z"
      fill="white"
    />
    <path
      d="M15 6.5C15 9.53757 12.5376 12 9.50002 12C8.35242 12 7.28692 11.6485
        6.40528 11.0474C6.54268 11.6543 6.3751 12.3164 5.90253 12.7889L4.31154
        14.3799C3.57931 15.1122 2.39213 15.1122 1.65989 14.3799C0.92766 13.6477
        0.92766 12.4605 1.65989 11.7283L3.25088 10.1373C3.71964 9.66853 4.37485
        9.49986 4.97779 9.63128C4.3613 8.74261 4.00002 7.66349 4.00002 6.5C4.00002
        3.46243 6.46245 1 9.50002 1C12.5376 1 15 3.46243 15 6.5ZM14 6.5C14 4.01472
        11.9853 2 9.50002 2C7.01474 2 5.00002 4.01472 5.00002 6.5C5.00002 8.98528
        7.01474 11 9.50002 11C11.9853 11 14 8.98528 14 6.5Z"
      fill="#53546E"
    />
  </SvgIcon>
);

export default MagnifyingGlass;
