import { ChangeEvent, FC, ReactNode, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Typography } from '@mui/material';
import { postStudentSpoStatusActions, updateStudentPersonalAccessActions } from 'actions';
import { SpoStatusButtonColors, SpoStatusCodes, SpoStatusSelectColors } from 'const';
import { differenceInYears } from 'date-fns';
import { useDataLoad, useSPOStudent, useUserFunctionality } from 'hooks';
import { IconChevronSmallDown, IconChevronSmallUp } from 'icons';
import { BriefCase, CheckMarkButton, HammerAndWrench, MagnifyingGlass, NoEntry } from 'icons/proftech';
import { BaseMenu, BaseMenuItem } from 'portfolio3/ui-kit';
import { IRootState } from 'reducers';
import { getDataLoadRequests } from 'utils';

import { MobileStatusSelectorDrawer, StatusSelectorLabel } from './components';
import PersonalAccessModal from './components/PersonalAccessModal';
import * as styles from './styles';
import { formTextWithGender } from './utils';

interface IStatusSelector {
  isMobile?: boolean;
  withDrawerMenu?: boolean;
}

const StatusSelector: FC<IStatusSelector> = ({ isMobile, withDrawerMenu }) => {
  const currentStudent = useSelector((state: IRootState) => state.currentStudent);
  const { code: spoStatusCode, loading } = useSelector((state: IRootState) => state.studentSpoStatus);
  const { content: studentPersonalAccess } = useSelector((state: IRootState) => state.studentPersonalAccess);
  const linkMode = useSelector((state: IRootState) => state.linkMode.mode) ?? false;
  const dispatch = useDispatch();
  const [localSpoStatusCode, setLocalSpoStatusCode] = useState(spoStatusCode ?? SpoStatusCodes.notLookingForJob);

  useEffect(() => {
    setLocalSpoStatusCode(spoStatusCode);
  }, [spoStatusCode]);

  const { isStudent } = useUserFunctionality();
  const isSpoStudent = useSPOStudent();

  const [modalOpen, setModalOpen] = useState(false);

  useDataLoad({ shouldLoad: isSpoStudent, requests: getDataLoadRequests().spoStatusSelector });
  useDataLoad({
    shouldLoad: isSpoStudent && isStudent,
    requests: getDataLoadRequests().studentPersonalAccess,
  });

  const { gender, birthdate } = currentStudent;

  const studentBirthDate = birthdate ? new Date(birthdate) : new Date();
  const studentFullYears = differenceInYears(new Date(), studentBirthDate);
  const showActiveJobSearchOption = studentFullYears >= 18;
  const isDisabled = !isStudent || linkMode;

  const anchorEl = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);

  const handleOpenMenu = () => setOpen(true);
  const handleCloseMenu = () => setOpen(false);
  const handleClose = (statusCode: number) => {
    return () => {
      setLocalSpoStatusCode(statusCode);
      handleCheckSpoStatusCode(statusCode);
      handleCloseMenu();
    };
  };

  const handleCheckSpoStatusCode = (code: number) => {
    if (
      !studentPersonalAccess?.isActive &&
      (code === SpoStatusCodes.activeSearch || code === SpoStatusCodes.practiceSearch)
    ) {
      setModalOpen(true);
    } else {
      if (currentStudent) dispatch(postStudentSpoStatusActions.request(currentStudent.meshId, code));
    }
  };

  const handleConfirmChange = () => {
    if (currentStudent) {
      dispatch(postStudentSpoStatusActions.request(currentStudent.meshId, localSpoStatusCode));
      dispatch(updateStudentPersonalAccessActions.request(currentStudent.meshId, true));
    }
    setModalOpen(false);
  };

  const handleChangeDrawerValue = (e: ChangeEvent<HTMLInputElement>) => {
    setLocalSpoStatusCode(Number(e.currentTarget.value as string));
    handleCheckSpoStatusCode(localSpoStatusCode);
  };

  const menuIconObjects: Record<number, ReactNode> = {
    [SpoStatusCodes.activeSearch]: <MagnifyingGlass sx={styles.menuIcon} />,
    [SpoStatusCodes.employed]: <BriefCase sx={styles.menuIcon} />,
    [SpoStatusCodes.practiceSearch]: <HammerAndWrench sx={styles.menuIcon} />,
    [SpoStatusCodes.practiceDone]: <CheckMarkButton sx={styles.menuIcon} />,
    [SpoStatusCodes.notLookingForJob]: <NoEntry sx={styles.menuIcon} />,
  };

  const statusButtonColors = isMobile
    ? SpoStatusButtonColors[localSpoStatusCode]
    : SpoStatusSelectColors[localSpoStatusCode];

  const selectorText = formTextWithGender(localSpoStatusCode);
  const selectorTextVariant = isMobile ? 'Paragraph MD/Medium' : 'Paragraph SM/Semi Bold';

  const handleCloseModal = () => {
    setLocalSpoStatusCode(spoStatusCode);
    setModalOpen(false);
  };

  const menuElements = Object.entries(SpoStatusCodes).map(([, statusCode]) => {
    const isSelected = localSpoStatusCode === statusCode;
    const optionText = formTextWithGender(statusCode, gender);

    if (!showActiveJobSearchOption && statusCode === SpoStatusCodes.activeSearch) {
      return null;
    }

    if (withDrawerMenu) {
      return (
        <StatusSelectorLabel
          key={`drawer-label-${statusCode}`}
          value={statusCode}
          text={optionText}
          icon={menuIconObjects[statusCode]}
        />
      );
    }

    return (
      <BaseMenuItem
        sx={styles.menuItem}
        key={statusCode}
        selected={isSelected}
        onClick={handleClose(statusCode)}
        autoFocus
      >
        {menuIconObjects[statusCode]}
        <Typography
          sx={styles.menuItemText(isSelected)}
          variant={isSelected ? 'Paragraph MD/Semi Bold' : 'Paragraph MD/Regular'}
        >
          {optionText}
        </Typography>
      </BaseMenuItem>
    );
  });

  const menuElement = withDrawerMenu ? (
    <MobileStatusSelectorDrawer
      code={localSpoStatusCode}
      onChangeRadio={handleChangeDrawerValue}
      onClose={handleCloseMenu}
      open={open}
    >
      {menuElements}
    </MobileStatusSelectorDrawer>
  ) : (
    <BaseMenu
      id="status-selector-menu"
      open={open}
      anchorEl={anchorEl.current}
      anchorOrigin={{ vertical: isMobile ? 'top' : 'bottom', horizontal: isMobile ? 'right' : 'left' }}
      transformOrigin={{ vertical: isMobile ? 'bottom' : 'top', horizontal: isMobile ? 'right' : 'left' }}
      PaperProps={{
        sx: { marginBlock: isMobile ? 0 : '4px 0' },
      }}
      MenuListProps={{
        'aria-labelledby': 'status-selector-button',
      }}
      onClose={handleCloseMenu}
    >
      {menuElements}
    </BaseMenu>
  );

  if (loading) return null;
  if (!isSpoStudent) return null;

  return (
    <>
      <Box
        component="button"
        id="status-selector-button"
        className="status-selector"
        sx={styles.statusSelector(statusButtonColors, isMobile, open)}
        ref={anchorEl}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        aria-controls={open ? 'status-selector-menu' : undefined}
        onClick={handleOpenMenu}
        disabled={isDisabled}
      >
        <Box sx={styles.adornment} className="start-adornment">
          {menuIconObjects[localSpoStatusCode]}
        </Box>
        <Box className="text">
          <Typography variant={selectorTextVariant} color={statusButtonColors.text}>
            {selectorText}
          </Typography>
        </Box>
        {!isMobile && !isDisabled && (
          <Box sx={styles.adornment} className="end-adornment">
            {open ? <IconChevronSmallUp /> : <IconChevronSmallDown />}
          </Box>
        )}
      </Box>
      {menuElement}
      <PersonalAccessModal open={modalOpen} close={handleCloseModal} handleConfirm={handleConfirmChange} />
    </>
  );
};

export default StatusSelector;
