import addFavoriteVuz from './addFavoriteVuz';
import deleteFavoriteVuz from './deleteFavoriteVuz';
import egeExamSubjectsRef from './egeExamSubjectsRef';
import favoriteVuzList from './favoriteVuzList';
import specialitiesRef from './specialitiesRef';
import vuzDetailsDrawer from './vuzDetailsDrawer';
import vuzList from './vuzList';
import vuzListCount from './vuzListCount';

export {
  addFavoriteVuz,
  deleteFavoriteVuz,
  egeExamSubjectsRef,
  favoriteVuzList,
  specialitiesRef,
  vuzDetailsDrawer,
  vuzList,
  vuzListCount,
};

export const collection = {
  addFavoriteVuz,
  deleteFavoriteVuz,
  egeExamSubjectsRef,
  favoriteVuzList,
  specialitiesRef,
  vuzDetailsDrawer,
  vuzList,
  vuzListCount,
};
