/* eslint-disable prettier/prettier */
/* eslint-disable */

import { IQueryParams } from '../api';
import {
  createRequestTypes,
  action,
  FAILURE,
  REQUEST,
  SUCCESS,
  INVALIDATE,
  createAction
} from './utils';
import {
  IPostPortfolioReward,
  IPostPortfolioEvent,
  IPostPortfolioEmployment,
  IPostPortfolioAffilation,
  IPostPortfolioSportReward,
  IPostPortfolioProject,
  IAttachment,
  IPersonsListRequest,
  IHistoryRequest,
  IImportHistoryRequest,
  PostStudentGiaWorldSkills,
  PostJobRequest,
  EditJobRequest,
  PostSpoDocumentRequest,
  EditSpoDocumentRequest,
  IResponsePayload,
  SpoDocument,
  MetaObject
} from '../api/types';

export const GET_AFFILATION_KINDS = createRequestTypes('GET_AFFILATION_KINDS');
export const getAffilationKindsActions = {
  request: (queryParams?: IQueryParams) => action(GET_AFFILATION_KINDS[REQUEST], { payload: { queryParams } }),
  success: (response: any) => action(GET_AFFILATION_KINDS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_AFFILATION_KINDS[FAILURE], { payload: error })
};

export const GET_EVENT_KINDS = createRequestTypes('GET_EVENT_KINDS');
export const getEventKindsActions = {
  request: (queryParams?: IQueryParams) => action(GET_EVENT_KINDS[REQUEST], { payload: { queryParams } }),
  success: (response: any) => action(GET_EVENT_KINDS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_EVENT_KINDS[FAILURE], { payload: error })
};

export const GET_PORTFOLIO_SUBCATEGORIES = createRequestTypes('GET_PORTFOLIO_SUBCATEGORIES');
export const getPortfolioSubcategoriesActions = {
  request: (queryParams?: IQueryParams) => action(GET_PORTFOLIO_SUBCATEGORIES[REQUEST], { payload: { queryParams } }),
  success: (response: any) => action(GET_PORTFOLIO_SUBCATEGORIES[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_PORTFOLIO_SUBCATEGORIES[FAILURE], { payload: error })
};

export const GET_STATUS_REWARDS = createRequestTypes('GET_STATUS_REWARDS');
export const getStatusRewardsActions = {
  request: (queryParams?: IQueryParams) => action(GET_STATUS_REWARDS[REQUEST], { payload: { queryParams } }),
  success: (response: any) => action(GET_STATUS_REWARDS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_STATUS_REWARDS[FAILURE], { payload: error })
};

export const GET_PROJECT_FORMATS = createRequestTypes('GET_PROJECT_FORMATS');
export const getProjectFormatsActions = {
  request: (queryParams?: IQueryParams) => action(GET_PROJECT_FORMATS[REQUEST], { payload: { queryParams } }),
  success: (response: any) => action(GET_PROJECT_FORMATS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_PROJECT_FORMATS[FAILURE], { payload: error })
};

export const GET_SPORT_KINDS = createRequestTypes('GET_SPORT_KINDS');
export const getSportKindsAction = {
  request: (queryParams?: IQueryParams) => action(GET_SPORT_KINDS[REQUEST], { payload: { queryParams } }),
  success: (response: any) => action(GET_SPORT_KINDS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_SPORT_KINDS[FAILURE], { payload: error })
};

export const GET_TOURISM_KINDS = createRequestTypes('GET_TOURISM_KINDS');
export const getTourismKindsAction = {
  request: (queryParams?: IQueryParams) => action(GET_TOURISM_KINDS[REQUEST], { payload: { queryParams } }),
  success: (response: any) => action(GET_TOURISM_KINDS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_TOURISM_KINDS[FAILURE], { payload: error })
};

export const GET_CREATION_KINDS = createRequestTypes('GET_CREATION_KINDS');
export const getCreationKindsAction = {
  request: (queryParams?: IQueryParams) => action(GET_CREATION_KINDS[REQUEST], { payload: { queryParams } }),
  success: (response: any) => action(GET_CREATION_KINDS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_CREATION_KINDS[FAILURE], { payload: error })
};

export const GET_CULTURAL_KINDS = createRequestTypes('GET_CULTURAL_KINDS');
export const getCulturalKindsAction = {
  request: (queryParams?: IQueryParams) => action(GET_CULTURAL_KINDS[REQUEST], { payload: { queryParams } }),
  success: (response: any) => action(GET_CULTURAL_KINDS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_CULTURAL_KINDS[FAILURE], { payload: error })
};

export const GET_REWARD_KINDS = createRequestTypes('GET_REWARD_KINDS');
export const getRewardKindsAction = {
  request: (queryParams?: IQueryParams) => action(GET_REWARD_KINDS[REQUEST], { payload: { queryParams } }),
  success: (response: any) => action(GET_REWARD_KINDS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_REWARD_KINDS[FAILURE], { payload: error })
};

export const GET_DISCIPLINES = createRequestTypes('GET_DISCIPLINES');
export const getDisciplinesAction = {
  request: (queryParams?: IQueryParams) => action(GET_DISCIPLINES[REQUEST], { payload: { queryParams } }),
  success: (response: any) => action(GET_DISCIPLINES[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_DISCIPLINES[FAILURE], { payload: error })
};

export const GET_MUSEUMS = createRequestTypes('GET_MUSEUMS');
export const getMuseumsAction = {
  request: (queryParams?: IQueryParams) => action(GET_MUSEUMS[REQUEST], { payload: { queryParams } }),
  success: (response: any) => action(GET_MUSEUMS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_MUSEUMS[FAILURE], { payload: error })
};

export const GET_THEATRES = createRequestTypes('GET_THEATRES');
export const getTheatresAction = {
  request: (queryParams?: IQueryParams) => action(GET_THEATRES[REQUEST], { payload: { queryParams } }),
  success: (response: any) => action(GET_THEATRES[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_THEATRES[FAILURE], { payload: error })
};

export const GET_CINEMAS = createRequestTypes('GET_CINEMAS');
export const getCinemasAction = {
  request: (queryParams?: IQueryParams) => action(GET_CINEMAS[REQUEST], { payload: { queryParams } }),
  success: (response: any) => action(GET_CINEMAS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_CINEMAS[FAILURE], { payload: error })
};

export const GET_SPORT_CLUBS = createRequestTypes('GET_SPORT_CLUBS');
export const getSportClubsAction = {
  request: (queryParams?: IQueryParams) => action(GET_SPORT_CLUBS[REQUEST], { payload: { queryParams } }),
  success: (response: any) => action(GET_SPORT_CLUBS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_SPORT_CLUBS[FAILURE], { payload: error })
};

export const GET_SPORT_TRAINING_STAGE = createRequestTypes('GET_SPORT_TRAINING_STAGE');
export const getSportTrainingStageAction = {
  request: (queryParams?: IQueryParams) => action(GET_SPORT_TRAINING_STAGE[REQUEST], { payload: { queryParams } }),
  success: (response: any) => action(GET_SPORT_TRAINING_STAGE[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_SPORT_TRAINING_STAGE[FAILURE], { payload: error })
}

export const GET_SPORT_REWARD_KINDS = createRequestTypes('GET_SPORT_REWARD_KINDS');
export const getSportRewardKindsAction = {
  request: (queryParams?: IQueryParams) => action(GET_SPORT_REWARD_KINDS[REQUEST], { payload: { queryParams } }),
  success: (response: any) => action(GET_SPORT_REWARD_KINDS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_SPORT_REWARD_KINDS[FAILURE], { payload: error })
};

export const POST_PORTFOLIO_EVENT = createRequestTypes('POST_PORTFOLIO_EVENT');
export const postPortfolioEventActions = {
  request: (request: IPostPortfolioEvent, attachments: IAttachment[], queryParams?: IQueryParams, studentType?: boolean, sectionCodesString?: string, meta?: MetaObject) => action(POST_PORTFOLIO_EVENT[REQUEST], {
    payload: {
      request, attachments, queryParams, studentType, sectionCodesString, meta
    }
  }),
  success: (response: any) => action(POST_PORTFOLIO_EVENT[SUCCESS], { payload: response }),
  failure: (error: any) => action(POST_PORTFOLIO_EVENT[FAILURE], { payload: error })
};

export const EDIT_PORTFOLIO_EVENT = createRequestTypes('EDIT_PORTFOLIO_EVENT');
export const editPortfolioEventActions = {
  request: (request: IPostPortfolioEvent, id: number, attachments: IAttachment[], queryParams?: IQueryParams, studentType?: boolean, sectionCodesString?: string) => action(EDIT_PORTFOLIO_EVENT[REQUEST], {
    payload: {
      id, request, attachments, queryParams, studentType, sectionCodesString
    }
  }),
  success: (response: any) => action(EDIT_PORTFOLIO_EVENT[SUCCESS], { payload: response }),
  failure: (error: any) => action(EDIT_PORTFOLIO_EVENT[FAILURE], { payload: error })
};

export const DELETE_PORTFOLIO_EVENT = createRequestTypes('DELETE_PORTFOLIO_EVENT');
export const deletePortfolioEventActions = {
  request: (id: number, personId: string, queryParams?: IQueryParams, studentType?: boolean, sectionCodesString?: string) => action(DELETE_PORTFOLIO_EVENT[REQUEST], {
    payload: {
      id, personId, queryParams, studentType, sectionCodesString
    }
  }),
  success: (response: any) => action(DELETE_PORTFOLIO_EVENT[SUCCESS], { payload: response }),
  failure: (error: any) => action(DELETE_PORTFOLIO_EVENT[FAILURE], { payload: error })
};

export const POST_PORTFOLIO_EMPLOYMENT = createRequestTypes('POST_PORTFOLIO_EMPLOYMENT');
export const postPortfolioEmploymentActions = {
  request: (request: IPostPortfolioEmployment, attachments: IAttachment[], queryParams?: IQueryParams, studentType?: boolean, sectionCodesString?: string, meta?: MetaObject) => action(POST_PORTFOLIO_EMPLOYMENT[REQUEST], {
    payload: {
      request, attachments, queryParams, studentType, sectionCodesString, meta
    }
  }),
  success: (response: any) => action(POST_PORTFOLIO_EMPLOYMENT[SUCCESS], { payload: response }),
  failure: (error: any) => action(POST_PORTFOLIO_EMPLOYMENT[FAILURE], { payload: error })
};

export const EDIT_PORTFOLIO_EMPLOYMENT = createRequestTypes('EDIT_PORTFOLIO_EMPLOYMENT');
export const editPortfolioEmploymentActions = {
  request: (request: IPostPortfolioEmployment, id: number, attachments: IAttachment[], queryParams?: IQueryParams, studentType?: boolean, sectionCodesString?: string) => action(EDIT_PORTFOLIO_EMPLOYMENT[REQUEST], {
    payload: {
      id, attachments, request, queryParams, studentType, sectionCodesString
    }
  }),
  success: (response: any) => action(EDIT_PORTFOLIO_EMPLOYMENT[SUCCESS], { payload: response }),
  failure: (error: any) => action(EDIT_PORTFOLIO_EMPLOYMENT[FAILURE], { payload: error })
};

export const DELETE_PORTFOLIO_EMPLOYMENT = createRequestTypes('DELETE_PORTFOLIO_EMPLOYMENT');
export const deletePortfolioEmploymentActions = {
  request: (id: number, personId: string, queryParams?: IQueryParams, studentType?: boolean, sectionCodesString?: string) => action(DELETE_PORTFOLIO_EMPLOYMENT[REQUEST], {
    payload: {
      id, personId, queryParams, studentType, sectionCodesString
    }
  }),
  success: (response: any) => action(DELETE_PORTFOLIO_EMPLOYMENT[SUCCESS], { payload: response }),
  failure: (error: any) => action(DELETE_PORTFOLIO_EMPLOYMENT[FAILURE], { payload: error })
};

export const POST_PORTFOLIO_AFFILATION = createRequestTypes('POST_PORTFOLIO_AFFILATION');
export const postPortfolioAffilationActions = {
  request: (request: IPostPortfolioAffilation, attachments: IAttachment[], queryParams?: IQueryParams, studentType?: boolean, sectionCodesString?: string, meta?: MetaObject) => action(POST_PORTFOLIO_AFFILATION[REQUEST], {
    payload: {
      request, attachments, queryParams, studentType, sectionCodesString, meta
    }
  }),
  success: (response: any) => action(POST_PORTFOLIO_AFFILATION[SUCCESS], { payload: response }),
  failure: (error: any) => action(POST_PORTFOLIO_AFFILATION[FAILURE], { payload: error })
};

export const EDIT_PORTFOLIO_AFFILATION = createRequestTypes('EDIT_PORTFOLIO_AFFILATION');
export const editPortfolioAffilationActions = {
  request: (request: IPostPortfolioAffilation, id: number, attachments: IAttachment[], queryParams?: IQueryParams, studentType?: boolean, sectionCodesString?: string) => action(EDIT_PORTFOLIO_AFFILATION[REQUEST], {
    payload: {
      id, attachments, request, queryParams, studentType, sectionCodesString
    }
  }),
  success: (response: any) => action(EDIT_PORTFOLIO_AFFILATION[SUCCESS], { payload: response }),
  failure: (error: any) => action(EDIT_PORTFOLIO_AFFILATION[FAILURE], { payload: error })
};

export const DELETE_PORTFOLIO_AFFILATION = createRequestTypes('DELETE_PORTFOLIO_AFFILATION');
export const deletePortfolioAffilationActions = {
  request: (id: number, personId: string, queryParams?: IQueryParams, studentType?: boolean, sectionCodesString?: string) => action(DELETE_PORTFOLIO_AFFILATION[REQUEST], {
    payload: {
      id, personId, queryParams, studentType, sectionCodesString
    }
  }),
  success: (response: any) => action(DELETE_PORTFOLIO_AFFILATION[SUCCESS], { payload: response }),
  failure: (error: any) => action(DELETE_PORTFOLIO_AFFILATION[FAILURE], { payload: error })
};

export const POST_PORTFOLIO_REWARD = createRequestTypes('POST_PORTFOLIO_REWARD');
export const postPortfolioRewardActions = {
  request: (request: IPostPortfolioReward, attachments: IAttachment[], queryParams?: IQueryParams, studentType?: boolean, sectionCodesString?: string, meta?: MetaObject) => action(POST_PORTFOLIO_REWARD[REQUEST], {
    payload: {
      request, attachments, queryParams, studentType, sectionCodesString, meta
    }
  }),
  success: (response: any) => action(POST_PORTFOLIO_REWARD[SUCCESS], { payload: response }),
  failure: (error: any) => action(POST_PORTFOLIO_REWARD[FAILURE], { payload: error })
};

export const EDIT_PORTFOLIO_REWARD = createRequestTypes('EDIT_PORTFOLIO_REWARD');
export const editPortfolioRewardActions = {
  request: (request: IPostPortfolioReward, id: number, attachments: IAttachment[], queryParams?: IQueryParams, studentType?: boolean, sectionCodesString?: string) => action(EDIT_PORTFOLIO_REWARD[REQUEST], {
    payload: {
      id, request, attachments, queryParams, studentType, sectionCodesString
    }
  }),
  success: (response: any) => action(EDIT_PORTFOLIO_REWARD[SUCCESS], { payload: response }),
  failure: (error: any) => action(EDIT_PORTFOLIO_REWARD[FAILURE], { payload: error })
};

export const DELETE_PORTFOLIO_REWARD = createRequestTypes('DELETE_PORTFOLIO_REWARD');
export const deletePortfolioRewardActions = {
  request: (id: number, personId: string, queryParams?: IQueryParams, studentType?: boolean, sectionCodesString?: string) => action(DELETE_PORTFOLIO_REWARD[REQUEST], {
    payload: {
      id, personId, queryParams, studentType, sectionCodesString
    }
  }),
  success: (response: any) => action(DELETE_PORTFOLIO_REWARD[SUCCESS], { payload: response }),
  failure: (error: any) => action(DELETE_PORTFOLIO_REWARD[FAILURE], { payload: error })
};

export const POST_PORTFOLIO_SPORT_REWARD = createRequestTypes('POST_PORTFOLIO_SPORT_REWARD');
export const postPortfolioSportRewardActions = {
  request: (request: IPostPortfolioSportReward, attachments: IAttachment[], queryParams?: IQueryParams, studentType?: boolean, sectionCodesString?: string, meta?: MetaObject) => action(POST_PORTFOLIO_SPORT_REWARD[REQUEST], {
    payload: {
      request, attachments, queryParams, studentType, sectionCodesString, meta
    }
  }),
  success: (response: any) => action(POST_PORTFOLIO_SPORT_REWARD[SUCCESS], { payload: response }),
  failure: (error: any) => action(POST_PORTFOLIO_SPORT_REWARD[FAILURE], { payload: error })
};

export const EDIT_PORTFOLIO_SPORT_REWARD = createRequestTypes('EDIT_PORTFOLIO_SPORT_REWARD');
export const editPortfolioSportRewardActions = {
  request: (request: IPostPortfolioSportReward, id: number, attachments: IAttachment[], queryParams?: IQueryParams, studentType?: boolean, sectionCodesString?: string) => action(EDIT_PORTFOLIO_SPORT_REWARD[REQUEST], {
    payload: {
      id, attachments, request, queryParams, studentType, sectionCodesString
    }
  }),
  success: (response: any) => action(EDIT_PORTFOLIO_SPORT_REWARD[SUCCESS], { payload: response }),
  failure: (error: any) => action(EDIT_PORTFOLIO_SPORT_REWARD[FAILURE], { payload: error })
};

export const DELETE_PORTFOLIO_SPORT_REWARD = createRequestTypes('DELETE_PORTFOLIO_SPORT_REWARD');
export const deletePortfolioSportRewardActions = {
  request: (id: number, personId: string, queryParams?: IQueryParams, studentType?: boolean, sectionCodesString?: string) => action(DELETE_PORTFOLIO_SPORT_REWARD[REQUEST], {
    payload: {
      id, personId, queryParams, studentType, sectionCodesString
    }
  }),
  success: (response: any) => action(DELETE_PORTFOLIO_SPORT_REWARD[SUCCESS], { payload: response }),
  failure: (error: any) => action(DELETE_PORTFOLIO_SPORT_REWARD[FAILURE], { payload: error })
};

export const POST_PORTFOLIO_PROJECT = createRequestTypes('POST_PORTFOLIO_PROJECT');
export const postPortfolioProjectActions = {
  request: (request: IPostPortfolioProject, attachments: IAttachment[], queryParams?: IQueryParams, studentType?: boolean, sectionCodesString?: string, meta?: MetaObject) => action(POST_PORTFOLIO_PROJECT[REQUEST], {
    payload: {
      request, attachments, queryParams, studentType, sectionCodesString, meta
    }
  }),
  success: (response: any) => action(POST_PORTFOLIO_PROJECT[SUCCESS], { payload: response }),
  failure: (error: any) => action(POST_PORTFOLIO_PROJECT[FAILURE], { payload: error })
};

export const EDIT_PORTFOLIO_PROJECT = createRequestTypes('EDIT_PORTFOLIO_PROJECT');
export const editPortfolioProjectActions = {
  request: (request: IPostPortfolioProject, id: number, attachments: IAttachment[], queryParams?: IQueryParams, studentType?: boolean, sectionCodesString?: string) => action(EDIT_PORTFOLIO_PROJECT[REQUEST], {
    payload: {
      id, attachments, request, queryParams, studentType, sectionCodesString
    }
  }),
  success: (response: any) => action(EDIT_PORTFOLIO_PROJECT[SUCCESS], { payload: response }),
  failure: (error: any) => action(EDIT_PORTFOLIO_PROJECT[FAILURE], { payload: error })
};

export const DELETE_PORTFOLIO_PROJECT = createRequestTypes('DELETE_PORTFOLIO_PROJECT');
export const deletePortfolioProjectActions = {
  request: (id: number, personId: string, queryParams?: IQueryParams, studentType?: boolean, sectionCodesString?: string) => action(DELETE_PORTFOLIO_PROJECT[REQUEST], {
    payload: {
      id, personId, queryParams, studentType, sectionCodesString
    }
  }),
  success: (response: any) => action(DELETE_PORTFOLIO_PROJECT[SUCCESS], { payload: response }),
  failure: (error: any) => action(DELETE_PORTFOLIO_PROJECT[FAILURE], { payload: error })
};

export const POST_PORTFOLIO_GIA_WORLDSKILLS = createRequestTypes('POST_PORTFOLIO_GIA_WORLDSKILLS');
export const postPortfolioGiaWorldSkillsActions = {
  request: (request: PostStudentGiaWorldSkills, attachments: IAttachment[], queryParams?: IQueryParams, studentType?: boolean, sectionCodesString?: string, meta?: MetaObject) => action(POST_PORTFOLIO_GIA_WORLDSKILLS[REQUEST], {
    payload: {
      request, attachments, queryParams, studentType, sectionCodesString, meta
    }
  }),
  success: (response: any) => action(POST_PORTFOLIO_GIA_WORLDSKILLS[SUCCESS], { payload: response }),
  failure: (error: any) => action(POST_PORTFOLIO_GIA_WORLDSKILLS[FAILURE], { payload: error })
};

export const EDIT_PORTFOLIO_GIA_WORLDSKILLS = createRequestTypes('EDIT_PORTFOLIO_GIA_WORLDSKILLS');
export const editPortfolioGiaWorldSkillsActions = {
  request: (request: PostStudentGiaWorldSkills, id: number, attachments: IAttachment[], queryParams?: IQueryParams, studentType?: boolean, sectionCodesString?: string) => action(EDIT_PORTFOLIO_GIA_WORLDSKILLS[REQUEST], {
    payload: {
      id, attachments, request, queryParams, studentType, sectionCodesString
    }
  }),
  success: (response: any) => action(EDIT_PORTFOLIO_GIA_WORLDSKILLS[SUCCESS], { payload: response }),
  failure: (error: any) => action(EDIT_PORTFOLIO_GIA_WORLDSKILLS[FAILURE], { payload: error })
};

export const DELETE_PORTFOLIO_GIA_WORLDSKILLS = createRequestTypes('DELETE_PORTFOLIO_GIA_WORLDSKILLS');
export const deletePortfolioGiaWorldSkillsActions = {
  request: (id: number, personId: string, queryParams?: IQueryParams, studentType?: boolean, sectionCodesString?: string) => action(DELETE_PORTFOLIO_GIA_WORLDSKILLS[REQUEST], {
    payload: {
      id, personId, queryParams, studentType, sectionCodesString
    }
  }),
  success: (response: any) => action(DELETE_PORTFOLIO_GIA_WORLDSKILLS[SUCCESS], { payload: response }),
  failure: (error: any) => action(DELETE_PORTFOLIO_GIA_WORLDSKILLS[FAILURE], { payload: error })
};

export const POST_PORTFOLIO_SPO_DOCUMENT = createRequestTypes('POST_PORTFOLIO_SPO_DOCUMENT');
export const postPortfolioSpoDocumentActions = {
  request: (request: PostSpoDocumentRequest, attachments: IAttachment[], queryParams?: IQueryParams, studentType?: boolean, sectionCodesString?: string, meta?: MetaObject) => action(POST_PORTFOLIO_SPO_DOCUMENT[REQUEST], {
    payload: {
      request, attachments, queryParams, studentType, sectionCodesString, meta
    }
  }),
  success: (response: IResponsePayload<SpoDocument>) => createAction(POST_PORTFOLIO_SPO_DOCUMENT[SUCCESS], response),
  failure: (error: any) => action(POST_PORTFOLIO_SPO_DOCUMENT[FAILURE], { payload: error })
};

export const EDIT_PORTFOLIO_SPO_DOCUMENT = createRequestTypes('EDIT_PORTFOLIO_SPO_DOCUMENT');
export const editPortfolioSpoDocumentActions = {
  request: (request: EditSpoDocumentRequest, id: number, attachments: IAttachment[], queryParams?: IQueryParams, studentType?: boolean, sectionCodesString?: string) => action(EDIT_PORTFOLIO_SPO_DOCUMENT[REQUEST], {
    payload: {
      id, attachments, request, queryParams, studentType, sectionCodesString
    }
  }),
  success: (response: any) => action(EDIT_PORTFOLIO_SPO_DOCUMENT[SUCCESS], { payload: response }),
  failure: (error: any) => action(EDIT_PORTFOLIO_SPO_DOCUMENT[FAILURE], { payload: error })
};

export const DELETE_PORTFOLIO_SPO_DOCUMENT = createRequestTypes('DELETE_PORTFOLIO_SPO_DOCUMENT');
export const deletePortfolioSpoDocumentActions = {
  request: (id: number, personId: string, queryParams?: IQueryParams, studentType?: boolean, sectionCodesString?: string) => action(DELETE_PORTFOLIO_SPO_DOCUMENT[REQUEST], {
    payload: {
      id, personId, queryParams, studentType, sectionCodesString
    }
  }),
  success: (response: any) => action(DELETE_PORTFOLIO_SPO_DOCUMENT[SUCCESS], { payload: response }),
  failure: (error: any) => action(DELETE_PORTFOLIO_SPO_DOCUMENT[FAILURE], { payload: error })
};

export const POST_PORTFOLIO_JOB = createRequestTypes('POST_PORTFOLIO_JOB');
export const postPortfolioJobActions = {
  request: (request: PostJobRequest, attachments: IAttachment[], queryParams?: IQueryParams, studentType?: boolean, sectionCodesString?: string, meta?: MetaObject) => action(POST_PORTFOLIO_JOB[REQUEST], {
    payload: {
      request, attachments, queryParams, studentType, sectionCodesString, meta
    }
  }),
  success: (response: any) => action(POST_PORTFOLIO_JOB[SUCCESS], { payload: response }),
  failure: (error: any) => action(POST_PORTFOLIO_JOB[FAILURE], { payload: error })
};

export const EDIT_PORTFOLIO_JOB = createRequestTypes('EDIT_PORTFOLIO_JOB');
export const editPortfolioJobActions = {
  request: (request: EditJobRequest, id: number, attachments: IAttachment[], queryParams?: IQueryParams, studentType?: boolean, sectionCodesString?: string) => action(EDIT_PORTFOLIO_JOB[REQUEST], {
    payload: {
      id, attachments, request, queryParams, studentType, sectionCodesString
    }
  }),
  success: (response: any) => action(EDIT_PORTFOLIO_JOB[SUCCESS], { payload: response }),
  failure: (error: any) => action(EDIT_PORTFOLIO_JOB[FAILURE], { payload: error })
};

export const DELETE_PORTFOLIO_JOB = createRequestTypes('DELETE_PORTFOLIO_JOB');
export const deletePortfolioJobActions = {
  request: (id: number, personId: string, queryParams?: IQueryParams, studentType?: boolean, sectionCodesString?: string) => action(DELETE_PORTFOLIO_JOB[REQUEST], {
    payload: {
      id, personId, queryParams, studentType, sectionCodesString
    }
  }),
  success: (response: any) => action(DELETE_PORTFOLIO_JOB[SUCCESS], { payload: response }),
  failure: (error: any) => action(DELETE_PORTFOLIO_JOB[FAILURE], { payload: error })
}

export const GET_PERSONS_OBJECTS = createRequestTypes('GET_PERSONS_OBJECTS');
export const getPersonsObjectsActions = {
  request: (personId: string, queryParams?: IQueryParams, sectionCodes?: string) => action(GET_PERSONS_OBJECTS[REQUEST], { payload: { personId, queryParams, sectionCodes } }),
  success: (response: any) => action(GET_PERSONS_OBJECTS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_PERSONS_OBJECTS[FAILURE], { payload: error })
};

export const GET_ORGANIZATIONS = createRequestTypes('GET_ORGANIZATIONS');
export const getOrganizationsAction = {
  request: (queryParams?: IQueryParams) => action(GET_ORGANIZATIONS[REQUEST], { payload: { queryParams } }),
  success: (response: any) => action(GET_ORGANIZATIONS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_ORGANIZATIONS[FAILURE], { payload: error })
};

export const GET_PARALLELS = createRequestTypes('GET_PARALLELS');
export const getParallelsAction = {
  request: (schoolId: number, queryParams?: IQueryParams) => action(GET_PARALLELS[REQUEST], { payload: { schoolId, queryParams } }),
  success: (response: any) => action(GET_PARALLELS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_PARALLELS[FAILURE], { payload: error })
};

export const GET_PERSONS_LIST = createRequestTypes('GET_PERSONS_LIST');
export const getPersonsListActions = {
  request: (request: IPersonsListRequest) => action(GET_PERSONS_LIST[REQUEST], { payload: { request } }),
  success: (response: any) => action(GET_PERSONS_LIST[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_PERSONS_LIST[FAILURE], { payload: error }),
  invalidate: () => action(GET_PERSONS_LIST[INVALIDATE]),
};

export const GET_HISTORY = createRequestTypes('GET_HISTORY');
export const getHistoryActions = {
  request: (request?: IHistoryRequest) => action(GET_HISTORY[REQUEST], { payload: { request } }),
  success: (response: any) => action(GET_HISTORY[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_HISTORY[FAILURE], { payload: error })
};

export const GET_IMPORT_HISTORY = createRequestTypes('GET_IMPORT_HISTORY');
export const getImportHistoryActions = {
  request: (request?: IImportHistoryRequest) => action(GET_IMPORT_HISTORY[REQUEST], { payload: { request } }),
  success: (response: any) => action(GET_IMPORT_HISTORY[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_IMPORT_HISTORY[FAILURE], { payload: error })
};

export const GET_IMPORT_REPORT = createRequestTypes('GET_IMPORT_REPORT');
export const getImportReportActions = {
  request: (id: number) => action(GET_IMPORT_REPORT[REQUEST], { payload: { id } }),
  success: (response: any) => action(GET_IMPORT_REPORT[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_IMPORT_REPORT[FAILURE], { payload: error })
};

export const GET_IMPORT_LIST = createRequestTypes('GET_IMPORT_LIST');
export const getImportListActions = {
  request: () => action(GET_IMPORT_LIST[REQUEST]),
  success: (response: any) => action(GET_IMPORT_LIST[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_IMPORT_LIST[FAILURE], { payload: error })
};

export const DELETE_EMPLOYEE_EVENT = createRequestTypes('DELETE_EMPLOYEE_EVENT');
export const deleteEmployeeEventActions = {
  request: (id: number, filters: any) => action(DELETE_EMPLOYEE_EVENT[REQUEST], {
    payload: {
      id, filters
    }
  }),
  success: (response: any) => action(DELETE_EMPLOYEE_EVENT[SUCCESS], { payload: response }),
  failure: (error: any) => action(DELETE_EMPLOYEE_EVENT[FAILURE], { payload: error })
};

export const DELETE_EMPLOYEE_PROJECT = createRequestTypes('DELETE_EMPLOYEE_PROJECT');
export const deleteEmployeeProjectActions = {
  request: (id: number, filters: any) => action(DELETE_EMPLOYEE_PROJECT[REQUEST], {
    payload: {
      id, filters
    }
  }),
  success: (response: any) => action(DELETE_EMPLOYEE_PROJECT[SUCCESS], { payload: response }),
  failure: (error: any) => action(DELETE_EMPLOYEE_PROJECT[FAILURE], { payload: error })
};

export const DELETE_EMPLOYEE_SPORT_REWARD = createRequestTypes('DELETE_EMPLOYEE_SPORT_REWARD');
export const deleteEmployeeSportRewardActions = {
  request: (id: number, filters: any) => action(DELETE_EMPLOYEE_SPORT_REWARD[REQUEST], {
    payload: {
      id, filters
    }
  }),
  success: (response: any) => action(DELETE_EMPLOYEE_SPORT_REWARD[SUCCESS], { payload: response }),
  failure: (error: any) => action(DELETE_EMPLOYEE_SPORT_REWARD[FAILURE], { payload: error })
};

export const DELETE_EMPLOYEE_REWARD = createRequestTypes('DELETE_EMPLOYEE_REWARD');
export const deleteEmployeeRewardActions = {
  request: (id: number, filters: any) => action(DELETE_EMPLOYEE_REWARD[REQUEST], {
    payload: {
      id, filters
    }
  }),
  success: (response: any) => action(DELETE_EMPLOYEE_REWARD[SUCCESS], { payload: response }),
  failure: (error: any) => action(DELETE_EMPLOYEE_REWARD[FAILURE], { payload: error })
};

export const DELETE_EMPLOYEE_AFFILATION = createRequestTypes('DELETE_EMPLOYEE_AFFILATION');
export const deleteEmployeeAffilationActions = {
  request: (id: number, filters: any) => action(DELETE_EMPLOYEE_AFFILATION[REQUEST], {
    payload: {
      id, filters
    }
  }),
  success: (response: any) => action(DELETE_EMPLOYEE_AFFILATION[SUCCESS], { payload: response }),
  failure: (error: any) => action(DELETE_EMPLOYEE_AFFILATION[FAILURE], { payload: error })
};

export const DELETE_EMPLOYEE_EMPLOYMENT = createRequestTypes('DELETE_EMPLOYEE_EMPLOYMENT');
export const deleteEmployeeEmploymentActions = {
  request: (id: number, filters: any) => action(DELETE_EMPLOYEE_EMPLOYMENT[REQUEST], {
    payload: {
      id, filters
    }
  }),
  success: (response: any) => action(DELETE_EMPLOYEE_EMPLOYMENT[SUCCESS], { payload: response }),
  failure: (error: any) => action(DELETE_EMPLOYEE_EMPLOYMENT[FAILURE], { payload: error })
};

export const DELETE_EMPLOYEE_GIA_WORLDSKILLS = createRequestTypes('DELETE_EMPLOYEE_GIA_WORLDSKILLS');
export const deleteEmployeeGiaWorldSkillsActions = {
  request: (id: number, filters: any) => action(DELETE_EMPLOYEE_GIA_WORLDSKILLS[REQUEST], {
    payload: {
      id, filters
    }
  }),
  success: (response: any) => action(DELETE_EMPLOYEE_GIA_WORLDSKILLS[SUCCESS], { payload: response }),
  failure: (error: any) => action(DELETE_EMPLOYEE_GIA_WORLDSKILLS[FAILURE], { payload: error })
};

export const GET_EVENT_BY_ID = createRequestTypes('GET_EVENT_BY_ID');
export const getEventByIdActions = {
  request: (id: number) => action(GET_EVENT_BY_ID[REQUEST], { payload: { id } }),
  success: (response: any) => action(GET_EVENT_BY_ID[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_EVENT_BY_ID[FAILURE], { payload: error })
};

export const GET_PROJECT_BY_ID = createRequestTypes('GET_PROJECT_BY_ID');
export const getProjectByIdActions = {
  request: (id: number) => action(GET_PROJECT_BY_ID[REQUEST], { payload: { id } }),
  success: (response: any) => action(GET_PROJECT_BY_ID[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_PROJECT_BY_ID[FAILURE], { payload: error })
};

export const GET_AFFILATION_BY_ID = createRequestTypes('GET_AFFILATION_BY_ID');
export const getAffilationByIdActions = {
  request: (id: number) => action(GET_AFFILATION_BY_ID[REQUEST], { payload: { id } }),
  success: (response: any) => action(GET_AFFILATION_BY_ID[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_AFFILATION_BY_ID[FAILURE], { payload: error })
};

export const GET_EMPLOYMENT_BY_ID = createRequestTypes('GET_EMPLOYMENT_BY_ID');
export const getEmploymentByIdActions = {
  request: (id: number) => action(GET_EMPLOYMENT_BY_ID[REQUEST], { payload: { id } }),
  success: (response: any) => action(GET_EMPLOYMENT_BY_ID[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_EMPLOYMENT_BY_ID[FAILURE], { payload: error })
};

export const GET_REWARD_BY_ID = createRequestTypes('GET_REWARD_BY_ID');
export const getRewardByIdActions = {
  request: (id: number) => action(GET_REWARD_BY_ID[REQUEST], { payload: { id } }),
  success: (response: any) => action(GET_REWARD_BY_ID[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_REWARD_BY_ID[FAILURE], { payload: error })
};

export const GET_SPORT_REWARD_BY_ID = createRequestTypes('GET_SPORT_REWARD_BY_ID');
export const getSportRewardByIdActions = {
  request: (id: number) => action(GET_SPORT_REWARD_BY_ID[REQUEST], { payload: { id } }),
  success: (response: any) => action(GET_SPORT_REWARD_BY_ID[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_SPORT_REWARD_BY_ID[FAILURE], { payload: error })
};

export const GET_GIA_WORLDSKILLS_BY_ID = createRequestTypes('GET_GIA_WORLDSKILLS_BY_ID');
export const getGiaWorldSkillsByIdActions = {
  request: (id: number) => action(GET_GIA_WORLDSKILLS_BY_ID[REQUEST], { payload: { id } }),
  success: (response: any) => action(GET_GIA_WORLDSKILLS_BY_ID[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_GIA_WORLDSKILLS_BY_ID[FAILURE], { payload: error })
};

export const CLEAR_EMPLOYEE_HISTORY_FORM = 'CLEAR_EMPLOYEE_HISTORY_FORM';
export const clearEmployeeHistoryFormAction = () => action(CLEAR_EMPLOYEE_HISTORY_FORM);
