import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconInfoFilled: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d={`M12 9a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm1 7a1 1 0 0 1-2 0v-5a1 1 0 0 1 2 0v5zM12
          2C6.477 2 2 6.477 2 12s4.477 10 10 10c5.522 0 10-4.477 10-10S17.522 2 12 2z`}
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconInfoFilled;
