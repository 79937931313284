/* eslint-disable max-len */
import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconCreationRewardsCommon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.498 9.7v3.6l-3.5-1.1-3.4 1.1V9.7l-2.2-3 3.4-1.1 2.2-2.9 2.1 2.9 3.4 1.1-2 3ZM7.1 18.5v2.8l-2.7-.8-2.7.8v-2.8L0 16.2l2.7-.9L4.4 13 6 15.3l2.7.9-1.6 2.3ZM22.398 18.5v2.8l-2.7-.8-2.7.8v-2.8l-1.6-2.3 2.7-.9 1.7-2.3 1.7 2.3 2.7.9-1.8 2.3Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconCreationRewardsCommon;
