import { StudentSectionSettingTypeCodes } from 'const';
import { useAppSelector } from 'hooks';
import { visibilitySettingsSelector } from 'selectors';
import { findTargetVisibilitySetting } from 'utils/localVisibilitySettingsService';

import { collection as searchActions } from '../model/actions';
import {
  searchProfessionAdditionalEducationSelector,
  searchProfessionEventsSelector,
  searchProfessionGiaWorldskillsSelector,
  searchProfessionJobsSelector,
  searchProfessionMetaskillsSelector,
  searchProfessionRewardsSelector,
} from '../model/selectors';

const professionCode = StudentSectionSettingTypeCodes.profession;
const rewardCode = StudentSectionSettingTypeCodes.professionReward;
const jobCode = StudentSectionSettingTypeCodes.professionJob;
const metaskillCode = StudentSectionSettingTypeCodes.professionMetaskills;
const additionalEducationCode = StudentSectionSettingTypeCodes.professionEducation;
const eventCode = StudentSectionSettingTypeCodes.professionEvent;
const giaWorldskillsCode = StudentSectionSettingTypeCodes.professionGiaWorldskills;

const useSearchProfession = () => {
  const settings = useAppSelector(visibilitySettingsSelector);
  const { loading: isRewardsLoading, content: rewards } = useAppSelector(searchProfessionRewardsSelector);
  const { loading: isJobsLoading, content: jobs } = useAppSelector(searchProfessionJobsSelector);
  const { loading: isMetaskillsLoading, content: metaskills } = useAppSelector(searchProfessionMetaskillsSelector);
  const { loading: isAdditionalEducationLoading, content: additionalEducation } = useAppSelector(
    searchProfessionAdditionalEducationSelector,
  );
  const { loading: isEventsLoading, content: events } = useAppSelector(searchProfessionEventsSelector);
  const { loading: isGiaWorldskillsLoading, content: giaWorldskills } = useAppSelector(
    searchProfessionGiaWorldskillsSelector,
  );

  const professionEntitiesCount = [
    rewards.length,
    jobs.length,
    metaskills.length,
    additionalEducation.length,
    events.length,
    giaWorldskills.length,
  ].reduce((prev, current) => prev + current, 0);

  const hasRewards = rewards.length > 0;
  const hasJobs = jobs.length > 0;
  const hasMetaskills = metaskills.length > 0;
  const hasAdditionalEducation = additionalEducation.length > 0;
  const hasEvents = events.length > 0;
  const hasGiaWorldskills = giaWorldskills.length > 0;

  const isRewardsEnabled =
    findTargetVisibilitySetting(settings, [professionCode, rewardCode])?.computedVisibility ?? false;
  const isJobsEnabled = findTargetVisibilitySetting(settings, [professionCode, jobCode])?.computedVisibility ?? false;
  const isMetaskillsEnabled =
    findTargetVisibilitySetting(settings, [professionCode, metaskillCode])?.computedVisibility ?? false;
  const isAdditionalEducationEnabled =
    findTargetVisibilitySetting(settings, [professionCode, additionalEducationCode])?.computedVisibility ?? false;
  const isEventsEnabled =
    findTargetVisibilitySetting(settings, [professionCode, eventCode])?.computedVisibility ?? false;
  const isGiaWorldskillsEnabled =
    findTargetVisibilitySetting(settings, [professionCode, giaWorldskillsCode])?.computedVisibility ?? false;

  const isProfessionRewardVisible = hasRewards && isRewardsEnabled;
  const isProfessionJobVisible = hasJobs && isJobsEnabled;
  const isProfessionMetaskillsVisible = hasMetaskills && isMetaskillsEnabled;
  const isProfessionAdditionalEducationVisible = hasAdditionalEducation && isAdditionalEducationEnabled;
  const isProfessionEventVisible = hasEvents && isEventsEnabled;
  const isProfessionGiaWorldskillsVisible = hasGiaWorldskills && isGiaWorldskillsEnabled;

  const isProfessionLoading =
    isRewardsLoading ||
    isJobsLoading ||
    isMetaskillsLoading ||
    isAdditionalEducationLoading ||
    isEventsLoading ||
    isGiaWorldskillsLoading;
  const isProfessionVisible =
    isProfessionRewardVisible ||
    isProfessionJobVisible ||
    isProfessionMetaskillsVisible ||
    isProfessionAdditionalEducationVisible ||
    isProfessionEventVisible ||
    isProfessionGiaWorldskillsVisible;

  const professionActions = [
    isRewardsEnabled && searchActions.searchRewardsActions,
    isJobsEnabled && searchActions.searchJobsActions,
    isMetaskillsEnabled && searchActions.searchMetaskillsActions,
    isAdditionalEducationEnabled && searchActions.searchProfessionalAdditionalEducationActions,
    isEventsEnabled && searchActions.searchEventsActions,
    isGiaWorldskillsEnabled && searchActions.searchGiaWorldskillsActions,
  ];

  return {
    rewards,
    jobs,
    metaskills,
    additionalEducation,
    events,
    giaWorldskills,
    isProfessionRewardVisible,
    isProfessionJobVisible,
    isProfessionMetaskillsVisible,
    isProfessionAdditionalEducationVisible,
    isProfessionEventVisible,
    isProfessionGiaWorldskillsVisible,
    isProfessionLoading,
    isProfessionVisible,
    professionActions,
    professionEntitiesCount,
  };
};

export default useSearchProfession;
