import { createSelector } from 'reselect';

import { defaultVisibilitySettings } from '../../const';
import { IRootState } from '../../reducers';
import { IViewVisibilitySetting } from '../../types';
import { getMappedLinkViewSettings } from './utils';

const linkVisibilitySettingsSelector = createSelector(
  [(state: IRootState) => state.linkVisibilitySettings, (state: IRootState) => state.adminSettings],
  (linkVisibilitySettings, adminSettings): IViewVisibilitySetting[] => {
    if (
      linkVisibilitySettings.loading ||
      adminSettings.loading ||
      !linkVisibilitySettings?.content?.display ||
      !adminSettings.content?.sections
    ) {
      return [];
    }

    const settings = getMappedLinkViewSettings(
      defaultVisibilitySettings,
      linkVisibilitySettings.content.display,
      adminSettings.content.sections,
    );
    return settings;
  },
);

export default linkVisibilitySettingsSelector;
