import { GET_STUDENT_SHARE_LINK_LIST } from '../../actions';
import { FAILURE, REQUEST, SUCCESS } from '../../actions/utils';
import { ICommonResponse, IShareLink } from '../../api/types';
import { connectReducers } from '../../utils';
import { IReducers } from '..';
import genericReducers from '../genericReducer';

export type StudentShareLinkListState = ICommonResponse<IShareLink>;

const initialState: StudentShareLinkListState = {
  content: [],
  loading: false,
};

const studentShareLinkList: IReducers<StudentShareLinkListState> = {
  [GET_STUDENT_SHARE_LINK_LIST[REQUEST]]: genericReducers().get.request,
  [GET_STUDENT_SHARE_LINK_LIST[SUCCESS]]: genericReducers().get.success,
  [GET_STUDENT_SHARE_LINK_LIST[FAILURE]]: genericReducers().get.failure,
};

export default connectReducers(initialState, studentShareLinkList);
