import { FC, useContext } from 'react';

import { Stack } from '@mui/material';
import { IDictionaryItem } from 'api/types';
import { ToggleOutline } from 'portfolio3/ui-kit/toggles';

import { VuzLocalFiltersContext } from '../../context';

interface IInnerContextControlProps {
  subjectOptions: IDictionaryItem[];
}

const InnerContextControl: FC<IInnerContextControlProps> = ({ subjectOptions }) => {
  const { localFilters: innerLocalFilters, setLocalFilter: setInnerLocalFilter } = useContext(VuzLocalFiltersContext);

  const { subjects } = innerLocalFilters;

  const handleToggle = (subjectCode: number) => {
    const hasSubject = subjects.includes(subjectCode);
    const newSubjects = hasSubject ? subjects.filter((code) => code !== subjectCode) : [...subjects, subjectCode];

    setInnerLocalFilter('subjects', newSubjects);
  };

  return (
    <Stack gap="8px">
      {subjectOptions.map((subject) => (
        <ToggleOutline
          key={subject.code}
          label={subject.value}
          checked={subjects.includes(subject.code)}
          onChange={() => handleToggle(subject.code)}
          size="small"
          sx={{ width: '100%' }}
        />
      ))}
    </Stack>
  );
};

export default InnerContextControl;
