import { FC, PropsWithChildren } from 'react';

import { emitYMEvent } from 'portfolio3/features/yandexMetrika';

import SpoilerButton from '../../../common/SpoilerButton';

import './index.scss';

interface IExpandableEntityListProps {
  totalEntitites: number;
  shownEntities: number;
  hasHiddenEntities: boolean;
  onSetInitialEntities: () => void;
  onSetAllEntities: () => void;
}

const ExpandableEntityList: FC<IExpandableEntityListProps & PropsWithChildren> = ({
  totalEntitites,
  shownEntities,
  hasHiddenEntities,
  onSetInitialEntities,
  onSetAllEntities,
  children,
}) => {
  const isExpanded = totalEntitites == shownEntities;
  const hiddenEntitiesCount = totalEntitites - shownEntities;

  const handleClick = () => {
    if (isExpanded) {
      onSetInitialEntities();
    } else {
      onSetAllEntities();

      emitYMEvent({ type: 'listBlockDisclosure' });
    }
  };

  return (
    <div className="expandable-entity-list">
      <div>{children}</div>
      {hasHiddenEntities && (
        <div className="expandable-entity-list__expand-button">
          <SpoilerButton
            isExpanded={isExpanded}
            expandedText="Свернуть"
            collapsedText={`Показать еще ${hiddenEntitiesCount}`}
            onClick={handleClick}
          />
        </div>
      )}
    </div>
  );
};

export default ExpandableEntityList;
