import { FC, ReactNode } from 'react';

import { Box, SxProps, Typography } from '@mui/material';
import { mergeSx } from 'utils';

import PaddingContainer from '../../PaddingContainer';
import * as styles from './styles';

interface IEmployeeSubheaderProps {
  title: string | ReactNode;
  tabsElement?: ReactNode;
  rightElement?: ReactNode;
  sx?: SxProps;
}

const EmployeeSubheader: FC<IEmployeeSubheaderProps> = ({ title, tabsElement, rightElement, sx }) => {
  return (
    <PaddingContainer
      className="employee-subheader"
      sx={mergeSx(styles.root, Boolean(tabsElement) && { paddingBottom: 0 }, sx)}
    >
      <Box className="employee-subheader__left" sx={styles.leftContainer}>
        {typeof title === 'string' ? <Typography variant="Headings/H6">{title}</Typography> : title}
        {tabsElement}
      </Box>
      {rightElement && <Box className="employee-subheader__right">{rightElement}</Box>}
    </PaddingContainer>
  );
};

export default EmployeeSubheader;
