/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import {
  Dialog as DialogMaterial,
  DialogProps
} from '@mui/material';

const useStyles = makeStyles({
  dialog: {
    '& .dialog-paper': {
      padding: '24px',
      paddingBottom: '32px',
      maxWidth: '640px'
    },
    '& .MuiDialogTitle-root': {
      padding: 0
    },
    '& .MuiDialogContent-root': {
      padding: '12px 0',
      marginTop: '12px'
    },
    '& .MuiDialogActions-root': {
      padding: 0,
      paddingTop: '16px'
    },
    '& .btn-add': {
      marginLeft: '20px'
    }
  }
});

const Dialog: React.FC<DialogProps> = (props) => {
  const classes = useStyles(props);

  const { children } = props;

  return (
    <DialogMaterial
      classes={{
        root: classes.dialog
      }}
      PaperProps={{
        className: 'dialog-paper'
      }}
      {...props}
    >
      {children}
    </DialogMaterial>
  );
};

export default Dialog;
