import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const styles: SxStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '4px',

  marginBottom: '4px',
};

interface ITableTitleWithCounter {
  title: string;
  counter: number;
}

const TableTitleWithCounter: FC<ITableTitleWithCounter> = ({ title, counter }) => {
  return (
    <Box className="table-title-with-counter" sx={styles}>
      <Typography variant="Headings/H6">{title}</Typography>
      <Typography variant="Paragraph LG/Semi Bold" color={NeutralColors.light_neutrals_600}>
        {counter}
      </Typography>
    </Box>
  );
};

export default TableTitleWithCounter;
