import { FC, PropsWithChildren } from 'react';

import { NeutralColors } from 'portfolio3/styles';

import BaseBadge from './BaseBadge';

const NeutralBadge: FC<PropsWithChildren> = ({ children }) => {
  return (
    <BaseBadge textColor={NeutralColors.light_neutrals_600} backgroundColor={NeutralColors.light_neutrals_200}>
      {children}
    </BaseBadge>
  );
};

export default NeutralBadge;
