import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { IDictionaryItem } from 'api/types';
import { useDisclosure } from 'hooks';
import { IconChevronSmallRight } from 'icons';
import { Token } from 'portfolio3/ui-kit/tokens';

import MainFiltersSpecialitiesDrawer from '../../MainFiltersSpecialitiesDrawer';
import * as styles from './styles';

interface IMobileSpecialitiesControl {
  value: number[];
  options: IDictionaryItem[];
  onChange: (specialityCode: number) => void;
  disabled?: boolean;
}

const MobileSpecialitiesControl: FC<IMobileSpecialitiesControl> = ({ value, options, onChange, disabled }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const hasSelected = value.length > 0;

  const handleDeleteSpeciality = (specialityCode: number) => {
    return () => {
      onChange(specialityCode);
    };
  };

  return (
    <Box className="mobile-specialities-control">
      <Box component="button" onClick={onOpen} sx={styles.button} disabled={disabled}>
        <Typography variant="Paragraph MD/Regular">Специальности</Typography>
        <IconChevronSmallRight />
      </Box>

      {hasSelected && (
        <Box sx={styles.speclialityList}>
          {value.map((specialityCode) => {
            const speciality = options.find((option) => option.code === specialityCode);

            if (!speciality) return null;

            return (
              <Token
                key={speciality.code}
                size="medium"
                label={speciality.value}
                onDelete={handleDeleteSpeciality(speciality.code)}
              />
            );
          })}
        </Box>
      )}

      <MainFiltersSpecialitiesDrawer
        isOpen={isOpen}
        onClose={onClose}
        specialities={options}
        selectedSpecialities={value}
        onToggle={onChange}
      />
    </Box>
  );
};

export default MobileSpecialitiesControl;
