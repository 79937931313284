/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import {
  Button as ButtonMaterial,
  ButtonProps
} from '@mui/material';

import {
  BoxShadows,
  Colors
} from '../../../style/variables';

interface IButtonProps extends ButtonProps {
  forwardRef?: any
}

const useStyles = makeStyles({
  button: ({ variant, color }: IButtonProps) => {
    const boxShadow = (variant !== 'outlined' && color === 'secondary') ? `${BoxShadows.tokio}` : `${BoxShadows.khalifa}`;
    let backgroundColor = `${Colors['blue-primrose']}`;
    let onHoverBackgroundColor = `${Colors['blue-primrose_l4']}`;
    let onActiveBackgroundColor = `${Colors['blue-primrose_d6']}`;
    let borderColor = 'transparent';
    let textColor = `${Colors.white}`;
    let onHoverAndActiveTextColor = `${Colors.white}`;

    if (variant === 'outlined' && color !== 'secondary') {
      backgroundColor = 'transparent';
      onHoverBackgroundColor = `${Colors['blue-primrose']}`;
      onActiveBackgroundColor = `${Colors['blue-primrose_d6']}`;
      borderColor = `${Colors['blue-primrose']}`;
      textColor = `${Colors['blue-primrose']}`;
    }
    if (variant !== 'outlined' && color === 'secondary') {
      backgroundColor = `${Colors.white}`;
      onHoverBackgroundColor = `${Colors.white}`;
      onActiveBackgroundColor = `${Colors.white}`;
      textColor = `${Colors['main-text']}`;
      onHoverAndActiveTextColor = `${Colors['main-text']}`;
    }
    if (variant === 'outlined' && color === 'secondary') {
      backgroundColor = 'transparent';
      onHoverBackgroundColor = `${Colors['note-text']}`;
      onActiveBackgroundColor = `${Colors['note-text']}`;
      textColor = `${Colors['note-text']}`;
      borderColor = `${Colors['disable-text']}`;
    }

    return ({
      padding: '8px 24px',
      borderRadius: 8,
      textTransform: 'none',
      fontSize: 16,
      lineHeight: '24px',
      backgroundColor,
      borderColor,
      color: textColor,
      boxShadow: 'none',
      boxSizing: 'border-box',
      height: '40px',
      whiteSpace: 'nowrap',
      '&:hover': {
        boxShadow,
        borderColor,
        backgroundColor: onHoverBackgroundColor,
        color: onHoverAndActiveTextColor
      },
      '&:active': {
        boxShadow: 'none',
        borderColor,
        backgroundColor: onActiveBackgroundColor,
        color: onHoverAndActiveTextColor
      },
      '&:disabled': {
        opacity: 0.4,
        borderColor,
        backgroundColor,
        color: textColor
      },
      '&.MuiButton-sizeSmall': {
        padding: '6px 24px',
        height: '36px'
      },
      '&.MuiButton-sizeLarge': {
        padding: '12px 16px',
        height: '48px'
      },
      '& svg': {
        color: (variant !== 'outlined' && color === 'secondary') ? `${Colors['note-text']}` : 'inherit'
      }
    });
  }
});

const Button: React.FC<IButtonProps> = ({ forwardRef, ...props }) => {
  const classes = useStyles(props);

  return (
    <ButtonMaterial
      variant="contained"
      color="primary"
      size="medium"
      classes={{
        root: classes.button
      }}
      disableTouchRipple
      ref={forwardRef}
      {...props}
    />
  );
};

export default React.memo(Button);
