/* eslint-disable max-len */
import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconCreationCollectivesCommon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17 3.6h.1v-2h1.7v1.2h-.5v2c0 .7-.6 1.3-1.3 1.3-.7 0-1.3-.6-1.3-1.3 0-.6.6-1.2 1.3-1.2ZM11.7 4.2h.1V1.6H14v1.5h-.6v2.7c0 .9-.8 1.7-1.7 1.7-.9 0-1.7-.8-1.7-1.7 0-.9.8-1.6 1.7-1.6ZM6.5 3.6h.1v-2h1.7v1.2h-.5v2c0 .7-.6 1.3-1.3 1.3-.7 0-1.3-.6-1.3-1.3 0-.6.6-1.2 1.3-1.2ZM7.398 24v-2.1c0-1.6.9-3 2.1-3.8-.6-.6-.9-1.4-.9-2.3 0-1.9 1.5-3.4 3.4-3.4s3.4 1.5 3.4 3.4c0 .9-.3 1.7-.9 2.3 1.2.8 2.1 2.2 2.1 3.7v2.1h-9.2v.1Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M16.4 15.9c0 .7-.2 1.4-.5 2.1.4.3.7.7.9 1.1h5V17c0-1.6-.9-2.9-2.1-3.7.6-.6.9-1.4.9-2.3 0-1.9-1.5-3.4-3.4-3.4s-3.4 1.5-3.4 3.4c0 .4.1.7.2 1.1 1.5.6 2.4 2.1 2.4 3.8ZM7.6 15.9c0-1.7 1-3.1 2.3-3.9.1-.3.2-.7.2-1.1 0-1.9-1.5-3.4-3.4-3.4S3.3 9 3.3 10.9c0 .9.3 1.7.9 2.3-1.2.8-2 2.2-2 3.8v2.1h5c.3-.4.6-.8.9-1.1-.3-.7-.5-1.4-.5-2.1Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconCreationCollectivesCommon;
