import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { setFunctionViewedAction, setViewingFunctionModeAction } from 'actions';
import { useAppSelector, useDataLoad, useUserFunctionality } from 'hooks';
import { getDataLoadRequests, isDefined } from 'utils';

const useViewedFunctions = () => {
  const dispatch = useDispatch();
  const { functions, isViewingMode } = useAppSelector((state) => state.viewedFunctions);
  const currentStudent = useAppSelector((state) => state.currentStudent);
  const currentStudentPersonId = currentStudent.meshId;

  const { isParent, isStudent } = useUserFunctionality();
  const shouldLoadFunctionList = isParent || isStudent;

  useDataLoad({
    requests: getDataLoadRequests().viewedFunctions,
    shouldLoad: shouldLoadFunctionList,
    visibilitySettingsCheck: false,
  });

  const nonArchiveFunctions = useMemo(() => {
    if (!functions) return [];

    return functions.filter((func) => {
      // пускаем если:
      // не пришел флаг архивности ИЛИ флаг архивности равен false
      return !isDefined(func.isArchive) || func.isArchive === false;
    });
  }, [functions]);

  const nonArchiveUnviewedFunctions = useMemo(() => {
    return nonArchiveFunctions.filter((func) => {
      // пускаем если:
      // 1) пришел флаг просмотра
      // 2) флаг просмотра равен false
      return isDefined(func.isViewed) && func.isViewed === false;
    });
  }, [nonArchiveFunctions]);

  const setFunctionViewMode = useCallback(() => {
    dispatch(setViewingFunctionModeAction(true));
  }, [dispatch]);

  const setFunctionViewed = useCallback(
    (functionCode: number) => {
      if (currentStudentPersonId) {
        dispatch(setFunctionViewedAction(currentStudentPersonId, functionCode));
      }
    },
    [dispatch, currentStudentPersonId],
  );

  return {
    nonArchiveFunctions,
    nonArchiveUnviewedFunctions,
    isFunctionViewMode: isViewingMode,
    setFunctionViewed,
    setFunctionViewMode,
  };
};

export default useViewedFunctions;
