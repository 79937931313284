/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import {
  ClickAwayListener, Grow, Paper, Popper as PopperMaterial, PopperProps
} from '@mui/material';

interface IPopperProps extends PopperProps {
  children: JSX.Element;
  onClosePopperMenu: (event: any) => void;
}

const Popper: React.FC<IPopperProps> = ({ children, onClosePopperMenu, ...props }) => (
  <PopperMaterial
    role={undefined}
    transition
    disablePortal
    {...props}
  >
    {({ TransitionProps }) => (
      <Grow {...TransitionProps}>
        <Paper elevation={1}>
          <ClickAwayListener onClickAway={onClosePopperMenu}>
            {children}
          </ClickAwayListener>
        </Paper>
      </Grow>
    )}
  </PopperMaterial>
);

export default Popper;
