import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const Exhibition: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="230" height="129" viewBox="0 0 230 129" xmlns="http://www.w3.org/2000/svg" {...props} fill="none">
    {/*eslint-disable max-len */}
    <path stroke={props.fill || 'currentColor'} strokeMiterlimit="10" d="M96.66 25.5h-77.5V84h77.5V25.5Z" />
    <path stroke={props.fill || 'currentColor'} strokeMiterlimit="10" d="M91.36 31h-66.9v48h66.9V31ZM24.66 31l-5-5" />
    <path
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m91.66 31 5-5M96.66 84l-5-5M19.66 84l5-5"
    />
    <path stroke={props.fill || 'currentColor'} strokeMiterlimit="10" d="M172.16 78.5v-71h-64v71h64Z" />
    <path
      stroke={props.fill || 'currentColor'}
      strokeMiterlimit="10"
      d="M166.14 73.65V12.36h-52.51v61.29h52.51ZM166.14 12.54l5.47-4.58"
    />
    <path
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m166.14 73.92 5.47 4.58M108.16 78.5l5.47-4.58M108.16 7.96l5.47 4.58"
    />
    <path
      stroke={props.fill || 'currentColor'}
      strokeMiterlimit="10"
      d="M10.16 97a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7ZM96.16 97a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7ZM9.66 121V97s32 16 87 .5v24M174.66 121.5v-24s-30 14.5-78 0"
    />
    <path
      fill="#fff"
      stroke={props.fill || 'currentColor'}
      strokeMiterlimit="10"
      d="m216.01 50.283-59.852 13.61 7.328 32.227 59.852-13.61-7.328-32.227Z"
    />
    <path
      stroke={props.fill || 'currentColor'}
      strokeMiterlimit="10"
      d="m188.668 61.338-18.42 4.188 3.838 16.88 18.42-4.189-3.838-16.879ZM166.9 61.45l.33 1.46"
    />
    <path
      stroke={props.fill || 'currentColor'}
      strokeDasharray="2.73 2.73"
      strokeMiterlimit="10"
      d="m167.84 65.58 5.75 25.31"
    />
    <path
      stroke={props.fill || 'currentColor'}
      strokeMiterlimit="10"
      d="m173.9 92.22.33 1.46M175.34 86.16l14.59-3.31M203.88 64.34l10.74-2.44M201.92 66.4l16.12-3.66M205.34 67.24l9.21-2.1M175.66 89.32l26.09-5.93"
    />
    <path
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m207.56 56.24-5.94 4.58 13.28-3.02-7.34-1.56Z"
    />
    <path
      stroke={props.fill || 'currentColor'}
      strokeMiterlimit="10"
      d="m204.54 60.16.87 3.83M207.1 59.57l.87 3.84M209.65 58.99l.88 3.84M212.3 58.8l.7 3.06"
    />
    <path
      fill="#fff"
      stroke={props.fill || 'currentColor'}
      strokeMiterlimit="10"
      d="m215.754 53.9-61.111 5.734 3.087 32.905 61.112-5.733-3.088-32.906Z"
    />
    <path
      stroke={props.fill || 'currentColor'}
      strokeMiterlimit="10"
      d="m187.214 61.32-18.808 1.764 1.617 17.235 18.808-1.765-1.617-17.234ZM165.62 58.59l.14 1.49"
    />
    <path
      stroke={props.fill || 'currentColor'}
      strokeDasharray="2.73 2.73"
      strokeMiterlimit="10"
      d="m166.01 62.8 2.43 25.84"
    />
    <path
      stroke={props.fill || 'currentColor'}
      strokeMiterlimit="10"
      d="m168.57 90 .14 1.5M170.79 84.19l14.88-1.4M201.91 66.25l10.97-1.03M199.7 68.04l16.46-1.55M202.98 69.31l9.41-.88M170.69 87.36l26.64-2.5"
    />
    <path
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m206.61 58.69-6.48 3.77 13.56-1.27-7.08-2.5Z"
    />
    <path
      stroke={props.fill || 'currentColor'}
      strokeMiterlimit="10"
      d="m203.11 62.18.36 3.92M205.72 61.94l.36 3.92M208.33 61.69l.37 3.92M210.98 61.84l.29 3.13"
    />
    <path
      fill="#fff"
      stroke={props.fill || 'currentColor'}
      strokeMiterlimit="10"
      d="M174.16 97a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Z"
    />
    <path
      stroke={props.fill || 'currentColor'}
      strokeMiterlimit="10"
      d="M60.16 47a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7ZM90.46 65.12c-4.37-.48-9.17-.86-12.78 1.65-2.51 1.75-4.44 4.86-7.5 5-1.49 0-3-.67-4.43-.27-.947.411-1.85.914-2.7 1.5a9.389 9.389 0 0 1-4.6.56l-18.81-.96a7.503 7.503 0 0 0-2.32.12 9.38 9.38 0 0 0-2.21 1.12 16 16 0 0 1-10.9 1.88M91 56.54c-8.643 1.55-17.4 2.39-26.18 2.51a8.791 8.791 0 0 1-2.9-.3c-1.005-.469-1.98-1-2.92-1.59a13.369 13.369 0 0 0-5.55-1l-29.17-1M38.11 42.38c2.21-.55 4.78.75 6.72-.44.272-.175.524-.38.75-.61 1.05-1 1.82-2.3 2.86-3.33a10.17 10.17 0 0 1 6.93-2.81M77.9 45.41a7.29 7.29 0 0 0-4.92.88 6.003 6.003 0 0 1-1.21.63 4.13 4.13 0 0 1-1.57.08 17.338 17.338 0 0 0-5.2.3 44.227 44.227 0 0 0-5 2.11 6.32 6.32 0 0 1-5.24.18M71.6 39.59l4.32-.59M28.15 48.06l7.3.22M46.63 64.5h8.06M71.65 62.8l-7.99.4M56.66 68h3M31.66 68h4"
    />
    <path
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".75"
      d="M132.83 62.21c1.259 0 2.28-1.007 2.28-2.25s-1.021-2.25-2.28-2.25-2.28 1.007-2.28 2.25 1.021 2.25 2.28 2.25Z"
    />
    <path
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".75"
      d="M136.03 59.36c1.177 0 2.13-.94 2.13-2.1 0-1.16-.953-2.1-2.13-2.1-1.176 0-2.13.94-2.13 2.1 0 1.16.954 2.1 2.13 2.1ZM148.51 64c1.26 0 2.28-1.007 2.28-2.25s-1.02-2.25-2.28-2.25c-1.259 0-2.28 1.007-2.28 2.25s1.021 2.25 2.28 2.25ZM142.91 29.25c.282.765.424 1.575.42 2.39 0 2.9-1.7 5.25-3.8 5.25-2.1 0-3.81-2.35-3.81-5.25a7.095 7.095 0 0 1 .3-2"
    />
    <path
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".75"
      d="M145 36.44 143.33 33s1.27-1.44.86-2.22c-.41-.78-.86-.32-.86-.32"
    />
    <path
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".75"
      d="M147 34.79s4.42 2.7 4.26 2.7c-.16 0-8.52 3.14-10 3.14-1.48 0 5.74-5.84 5.74-5.84Z"
    />
    <path
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".75"
      d="m136.93 35.48-1.82.81s3.2 4.49 6.09 4.34l-4.27-5.15ZM113.66 45.58l8.37 1.64M166.16 56.81l-23.89-5.24-6.55.28-.61-4.13 7.13-.45a4.915 4.915 0 0 0 4.24-3l.66-1.55"
    />
    <path
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".75"
      d="M158.13 54.56c0-.15-2.47-13.78-6.89-17.07M152.46 42.28s-.45 9.29-6.09 10.19M145.92 40.93l.61 3.3M148.35 40.63l-1.21 2.1M133.9 41.68l-.31 7.34"
    />
    <path
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".75"
      d="M153.15 39.67a6.872 6.872 0 0 1 6.95 2c2.86 3.3 2.07 4.72 2.07 4.72s-2.34 2.32-2.07 8.76"
    />
    <path
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".75"
      d="M154.69 41.68s2.79.45 4.92 3.9l-1.48 8M135.11 36.29l-8.21 8.09a12.49 12.49 0 0 0-4.51 2l-.83 2.41a1.265 1.265 0 0 0 .11 1l.31.51a.551.551 0 0 0 .85.11l.08-.07c.215-.21.346-.49.37-.79l.17-2.25.19.35a.483.483 0 0 0 .63.21v0a.504.504 0 0 0 .29-.45l-.1-1.46M128.42 48.87l5.32-3.52M135.11 48.92c-.15-.05-7.27 0-7.27 0h-1.88a1.481 1.481 0 0 0-.74.19l-.8.44a.708.708 0 0 0-.32.9l.05.12-.49.31a.489.489 0 0 0-.16.67.55.55 0 0 0 .29.2c.297.125.626.153.94.08l.94-.21.39.17c.141.063.294.097.45.1h3.13l3.8-.88 2 .15M137.68 39.42a15.864 15.864 0 0 0-1.68 1.93l.19 1.38h2.37l-2.56.88-.61 3s2.2.87 2.32.58c.12-.29.87-1.49.87-1.49l1.83-.14c.15.14 2.31 1.63 2.31 1.63s1.56-2 1.3-2.12a12.54 12.54 0 0 1-1.3-2.07l1.88-1.68-.8-1.32"
    />
    <path
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".75"
      d="M141.51 43.93a9.29 9.29 0 0 0-.75-1.35c-.979.165-1.94.423-2.87.77 0 0-.19 1.48.27 2s3-.31 3-.31l.35-1.11ZM124.61 46.77a1.813 1.813 0 0 1 2 .6c.72 1.05.4 1.53.4 1.53"
    />
    <path
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".75"
      d="M119.28 39.28a10.28 10.28 0 0 1 7.32.14l-1.39 6.1a3.997 3.997 0 0 1 2.48 1.75c.58 1.3.15 1.65.15 1.65M124.15 50.55l1.99-1.08 1.21-.15M127.05 50.07c-.11 0-3.35 1.65-3.35 1.65M128.43 51c-.16 0-2.76.63-2.76.63M140 25.26a3.609 3.609 0 0 0-.81-1.37c-.26-.26-5.46 1-4.54 6.11.43 2.43.61 3.89 1.21 4.34.6.45.52.27.52.27"
    />
    <path
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".75"
      d="M139.22 23.85c4.26-1.79 8.75 3.71 8.87 6 .12 2.29.72 3.79-.35 4.08-1.07.29-2.41 1.22-3.41.6M143 36.14s-2 1.2-3.5.75l.3 2.09M139.22 30.59a2.106 2.106 0 0 1 2.64-.27M138.29 30.59a1.72 1.72 0 0 0-1.95-.27M137.85 34.93s.81.74 2.69 0M140 31.42s.43-1 1.65-.23M136.61 31.42a1.067 1.067 0 0 1 .84-.413 1.055 1.055 0 0 1 .84.413 2.278 2.278 0 0 1-.13 1.72c-.35.69 1.16.87 1 .43"
    />
    <path
      fill={props.fill || 'currentColor'}
      stroke={props.fill || 'currentColor'}
      d="M140.85 31.34a.2.2 0 1 1 .4 0 .2.2 0 0 1-.4 0ZM137.42 31.42a.2.2 0 1 1 .4 0 .2.2 0 0 1-.4 0Z"
    />
    <path
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".75"
      d="M142.91 29.25s-2.17.6-3.38-2.85a8.716 8.716 0 0 1-3.53 3.16"
    />
    <path
      fill="#fff"
      stroke={props.fill || 'currentColor'}
      strokeMiterlimit="10"
      d="M128.16 26c1.381 0 2.5-2.015 2.5-4.5s-1.119-4.5-2.5-4.5-2.5 2.015-2.5 4.5 1.119 4.5 2.5 4.5Z"
    />
    <path
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".75"
      d="m115.94 46 1-8.31s5.22-4.06 12.46 1.11c0 0-1.1 1.11-1.43 4.64M142.16 57.5l9-2"
    />
  </SvgIcon>
);

export default Exhibition;
