import { FC, ReactNode } from 'react';

import { Box, FormControlLabel, FormControlLabelProps, Typography } from '@mui/material';
import RadioButton from 'portfolio3/ui-kit/RadioButton';
import { SafeOmit } from 'utils';

import * as styles from './styles';

interface IStatusSelectorLabel extends SafeOmit<FormControlLabelProps, 'control' | 'label'> {
  icon: ReactNode;
  text: string;
}

const StatusSelectorLabel: FC<IStatusSelectorLabel> = ({ icon, text, ...props }) => {
  const formControlLabelElement = (
    <Box sx={styles.radioTextContainer}>
      {icon}
      <Typography>{text}</Typography>
    </Box>
  );
  return (
    <FormControlLabel {...props} control={<RadioButton sx={styles.radioButton} />} label={formControlLabelElement} />
  );
};

export default StatusSelectorLabel;
