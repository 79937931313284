import { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconMapFilled: FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="m19 17.454-2-.898V6.546l2 .898v10.01ZM9 6.546l2 .898v10.01l-2-.898V6.546Zm11.41-.66-4-1.798c-.015-.007-.031-.008-.046-.014a1.106 1.106 0 0 0-.36-.067c-.044 0-.087.001-.131.006-.037.004-.072.013-.108.022a1.21 1.21 0 0 0-.13.04c-.014.005-.031.006-.045.013L12 5.701 8.41 4.088c-.015-.007-.031-.008-.046-.014a1.106 1.106 0 0 0-.36-.067c-.044 0-.087.001-.131.006-.037.004-.072.013-.108.022a1.21 1.21 0 0 0-.13.04c-.014.005-.031.006-.045.013l-4 1.798a1 1 0 0 0-.59.912V19a1.002 1.002 0 0 0 1.41.912L8 18.3l3.59 1.613c.015.007.031.004.047.01A.974.974 0 0 0 12 20a.974.974 0 0 0 .363-.078c.015-.006.033-.003.047-.01L16 18.3l3.59 1.613A.998.998 0 0 0 21 19V6.798a1 1 0 0 0-.59-.912Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconMapFilled;
