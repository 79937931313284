import { FC, useContext, useState } from 'react';

import { Box, Typography, useMediaQuery } from '@mui/material';
import { IDictionaryItem } from 'api/types';
import SpoilerButton from 'components/common/SpoilerButton';
// eslint-disable-next-line max-len
import DiagnosticCardWrapper from 'components/redesignStudent/diagnostic/independentDiagnostic/DIagnosticCardWrapper';
import { DIAGNOSTIC_LEVEL_TYPE_ALL_CODE, DIAGNOSTIC_SUBJECT_ALL_CODE, StudentSectionSettingTypeCodes } from 'const';
import { useWidgetVisibility } from 'hooks';
import { emitYMEvent } from 'portfolio3/features/yandexMetrika';
import { NeutralColors } from 'portfolio3/styles';
import { commonTheme } from 'portfolio3/styles/theme';
import { IIndependentDiagnosticData, IIndependentDiagnosticLevelType } from 'types';

import DiagnosticContext from '../../context/diagnosticContext';
import IndependentDiagnosticsListFilter from '../IndependentDiagnosticsListFilter';
import * as styles from './styles';

interface IIndependentDiagnosticListProps {
  diagnostics: IIndependentDiagnosticData[];
  levelTypes: IIndependentDiagnosticLevelType[];
  subjects: IDictionaryItem[];
}

const IndependentDiagnosticList: FC<IIndependentDiagnosticListProps> = ({ diagnostics, levelTypes, subjects }) => {
  const initialDisplayCount = 4;
  const { filters } = useContext(DiagnosticContext);
  const [displayCount, setDisplayCount] = useState(initialDisplayCount);
  const isSmallScreen = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  // видимость блока со списком в независимых диагностиках
  const { isSettingsMode, toggleElement } = useWidgetVisibility({
    sectionTypeCode: StudentSectionSettingTypeCodes.study,
    subsectionTypeCode: StudentSectionSettingTypeCodes.studyDiagnostic,
    subcategoryTypeCode: StudentSectionSettingTypeCodes.diagnosticIndependentList,
  });

  const { independentListLevelTypeCode: levelTypeCode, independentListSubjectCode: subjectCode } = filters;

  // фильтрация списка диагностик по предмету и уровню
  const filteredDiagnostics = diagnostics
    .filter((diagnostic) => {
      if (subjectCode === DIAGNOSTIC_SUBJECT_ALL_CODE) return true;
      const subjectValue = subjects.find((subject) => subject.code === subjectCode)?.value;
      if (!subjectValue) return false;
      return diagnostic.subject.toLowerCase() === subjectValue.toLowerCase();
    })
    .filter((diagnostic) => {
      if (levelTypeCode === DIAGNOSTIC_LEVEL_TYPE_ALL_CODE) return true;
      const levelTypeValue = levelTypes.find((level) => level.code === levelTypeCode)?.value;
      if (!levelTypeValue) return false;
      return diagnostic.levelType?.toLowerCase() === levelTypeValue.toLowerCase();
    });

  const year = (filteredDiagnostics.length > 0 && new Date(filteredDiagnostics[0]?.eventDate).getFullYear()) || null;

  const totalEntitiesCount = filteredDiagnostics.length;

  const isAllEntitiesShown = totalEntitiesCount === displayCount;
  const defaultHiddenEntities = filteredDiagnostics.length - initialDisplayCount;

  const handleClickExpandButton = () => {
    if (isAllEntitiesShown) setDisplayCount(initialDisplayCount);
    else setDisplayCount(totalEntitiesCount);

    emitYMEvent({ type: 'informationExpandCollapse' });
  };

  const spoilerButton = totalEntitiesCount > initialDisplayCount && (
    <SpoilerButton
      isExpanded={isAllEntitiesShown}
      expandedText="Свернуть"
      collapsedText={`Показать еще ${defaultHiddenEntities}`}
      onClick={handleClickExpandButton}
    />
  );

  return (
    <Box className="independent-list">
      {/* header */}
      <Box className="independent-list__header" sx={styles.header}>
        {isSmallScreen && <Typography variant="Paragraph LG/Bold">Пройденные диагностики</Typography>}
        {!isSmallScreen && (
          <>
            <Typography variant="Paragraph LG/Semi Bold">
              Пройденные независимые диагностики{' '}
              <Typography component="span" sx={{ color: NeutralColors.light_neutrals_600 }}>
                {diagnostics.length}
              </Typography>
            </Typography>

            {isSettingsMode ? toggleElement : spoilerButton}
          </>
        )}
      </Box>

      {/* filters */}
      <IndependentDiagnosticsListFilter
        diagnostics={diagnostics}
        levelTypes={levelTypes}
        subjects={subjects}
        spoilerButton={spoilerButton}
      />

      {/* list */}
      <Box className="independent-list__items" sx={styles.items}>
        {filteredDiagnostics.length > 0 && year && <Box sx={styles.year}>{year}</Box>}
        {filteredDiagnostics.slice(0, displayCount).map((diagnostic, index) => (
          <DiagnosticCardWrapper key={`${diagnostic.recordId}-${index}`} diagnostic={diagnostic} />
        ))}
      </Box>
    </Box>
  );
};

export default IndependentDiagnosticList;
