/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useEffect, useState } from 'react';

import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { useSwipeable } from 'react-swipeable';
import { useMediaQuery } from '@mui/material';
import clsx from 'clsx';

import DirectionsDropContainer from './dropContainer';
import { IDragElements, TContainerKeys } from '../types';
import InitialItemsContainer from './initialItemsContainer';
import { generateListWithNewElement, generateNewListOnClick } from '../utils';
import { detectTouchDevice } from '../../../../utils';
import { UncontrollableDialog, ViewDialog } from '../../../common/Dialog';
import NoDataPlug from './noDataPlug';
import { superMeshikPortrait } from '../../../../images';

import './index.scss';

interface IInterestsDirectionsProps {
  isWebView?: boolean;
  dragElements: IDragElements,
  setDragElements: React.Dispatch<React.SetStateAction<IDragElements>>,
  onChangeStepForward: () => void
}

const InterestsDirections: React.FC<IInterestsDirectionsProps> = ({
  isWebView,
  dragElements,
  setDragElements,
  onChangeStepForward
}) => {
  const isTablet = useMediaQuery('(max-width: 1022px)', { noSsr: true });
  const isMobile = useMediaQuery('(max-width: 767px)', { noSsr: true });
  const isSmallDevice = useMediaQuery('(max-width: 375px)', { noSsr: true });
  const isTouchDevice = detectTouchDevice();
  const dropContainerKeys: TContainerKeys[] = ['insipid', 'unknown', 'sipid'];
  const [isInterestInfoOpen, setInterestInfoOpen] = useState(false);
  const [isMobilePlugOpen, setMobilePlugOpen] = useState(false);

  useEffect(() => {
    if (!isSmallDevice && isInterestInfoOpen) {
      setInterestInfoOpen(false);
    } else if (isSmallDevice) {
      setInterestInfoOpen(true);
    }
  }, [isSmallDevice]);

  useEffect(() => {
    if (dragElements.initial.length === 0) {
      setMobilePlugOpen(true);
    } else {
      setMobilePlugOpen(false);
    }
  }, [dragElements.initial]);

  const onDragEnd = (result: DropResult) => {
    if (isTouchDevice && isTablet) return;
    setDragElements(generateListWithNewElement(dragElements, result, true));
  };

  const moveElementOnClick = (sourceIndex: number, destinationId: TContainerKeys) => {
    const sourceId = 'initial';
    setDragElements(generateNewListOnClick(dragElements, sourceId, sourceIndex, destinationId));
  };

  const getDestinationByKeyCode = (key: string) => {
    switch (key) {
      case 'ArrowLeft':
        return 'insipid';
      case 'ArrowRight':
        return 'sipid';
      case 'ArrowDown':
        return 'unknown';
      default:
        break;
    }
  };

  const moveElementByKey = (key: string) => {
    const destination = getDestinationByKeyCode(key);
    if (dragElements.initial.length - 1 < 0 || !destination) return;

    moveElementOnClick(dragElements.initial.length - 1, destination);
  };

  useEffect(() => {
    const keyPressHandler = (event: KeyboardEvent) => moveElementByKey(event.key);
    if (!isTouchDevice && !isTablet) {
      document.addEventListener('keydown', keyPressHandler);
    } else {
      document.removeEventListener('keydown', keyPressHandler);
    }
    return () => document.removeEventListener('keydown', keyPressHandler);
  }, [dragElements.initial]);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => moveElementByKey('ArrowLeft'),
    onSwipedRight: () => moveElementByKey('ArrowRight'),
    onSwipedDown: () => moveElementByKey('ArrowDown')
  });

  const appliedHandlers = isTablet ? swipeHandlers : null;

  const getDescriptionText = () => {
    if (isTouchDevice && isTablet) return 'Свайп вправо - интересно, влево - неинтересны, вниз - не знаешь.';
    if (isTouchDevice) return 'Свайпай вправо карточки с категориями, которые тебе интересны, влево - неинтересны, а если не можешь определиться, то вниз.';
    return 'Перетащи вправо карточки с направлениями, которые тебе интересны, влево - неинтересны, вниз, если не знаешь. Также ты можешь использовать стрелки на клавиатуре для управления карточками.';
  };

  const allDragElementsLength = dragElements.initial.length + dragElements.sipid.length + dragElements.insipid.length + dragElements.unknown.length;

  const handleCloseInterestInfo = () => {
    setInterestInfoOpen(false);
  };

  const handleCloseMobilePlugAndStepForward = () => {
    onChangeStepForward();
  };

  return (
    <section {...appliedHandlers} className={clsx('interests-directions', { 'interests-directions--webview': isWebView })}>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="interests-directions__content">
          <h3 className="interests-directions__title">Что тебе интересно?</h3>
          <p className="interests-directions__description">{getDescriptionText()}</p>
          {isMobile && (
          <p className="interests-directions__counter">
            {`${dragElements.initial.length} из ${allDragElementsLength}`}
          </p>
          )}
          <div className="interests-directions__dnd-container">
            <InitialItemsContainer
              prefix="initial"
              elements={dragElements.initial}
              moveElementOnClick={moveElementOnClick}
              onChangeStepForward={onChangeStepForward}
            />
            <DirectionsDropContainer prefix={dropContainerKeys[0]} elements={dragElements[dropContainerKeys[0]]} />
            <DirectionsDropContainer prefix={dropContainerKeys[1]} elements={dragElements[dropContainerKeys[1]]} />
            <DirectionsDropContainer prefix={dropContainerKeys[2]} elements={dragElements[dropContainerKeys[2]]} />
          </div>
        </div>
      </DragDropContext>
      <ViewDialog
        isOpen={isInterestInfoOpen}
        onClose={handleCloseInterestInfo}
        title=""
        className="info-dialog"
      >
        <div className="dialog-content">
          <img src={superMeshikPortrait} alt="кот" />
          <h5>Что тебе интересно?</h5>
          <span>
            Свайпай вправо карточки с категориями, которые тебе интересны, влево - неинтересны, а если не можешь определиться, то вниз.
          </span>
        </div>
      </ViewDialog>
      {isMobile && (
      <UncontrollableDialog
        isOpen={isMobilePlugOpen}
        title=""
        className="info-dialog"
      >
        <NoDataPlug onChangeStepForward={handleCloseMobilePlugAndStepForward} />
      </UncontrollableDialog>
      )}
    </section>
  );
};

export default InterestsDirections;
