import { FC } from 'react';

import { Box } from '@mui/system';

const MenuDivider: FC = () => {
  return (
    <Box
      className="menu-divider"
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '16px',
        '&::before': {
          content: '""',
          width: '100%',
          height: '1px',
          backgroundColor: '#E8E8EF',
        },
      }}
    />
  );
};

export default MenuDivider;
