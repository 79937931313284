import TrimText from 'portfolio3/components/common/TrimText';
import { BaseMenuItem } from 'portfolio3/ui-kit/menu';

import { ISelectItem } from '../types';
import { FilterOption, RenderOption } from './types';

export const defaultFilterOption: FilterOption<ISelectItem> = (option, search) => {
  if (search.trim().length === 0) return true;
  return option.value.toLowerCase().includes(search.toLowerCase());
};

export const defaultRenderOption: RenderOption<ISelectItem> = (option) => {
  return (
    <BaseMenuItem key={option.code} value={option.code}>
      <TrimText>{option.value}</TrimText>
    </BaseMenuItem>
  );
};
