import { LocalRoleTags } from 'const';
import { isArray } from 'lodash';
import { buildConfig } from 'packages/buildConfig';
import { IUserFunctionality } from 'types';

export const getYandexMetrikaCode = (user: IUserFunctionality, localRoles: string[]): number[] => {
  const configAccounts = buildConfig.config?.YANDEX_METRIKA?.accounts;
  const defaultValue = [0];

  if (!configAccounts) return defaultValue;

  let accounts: number[] | undefined;

  // сначала проверяется локальная роль
  if (localRoles.includes(LocalRoleTags.employeeOo)) accounts = configAccounts.employee_o_o;
  else if (localRoles.includes(LocalRoleTags.adminOo)) accounts = configAccounts.admin_o_o;

  if (!accounts) {
    // используем функционал пользователя, если не найдены совпадения по локальной роли
    if (user.isStudent) accounts = configAccounts.student;
    else if (user.isParent) accounts = configAccounts.parent;
    else if (user.isOperator || user.isEmployee) accounts = configAccounts.employee;
  }

  return isArray(accounts) ? accounts : defaultValue;
};
