/* eslint-disable prettier/prettier */
/* eslint-disable */

// Театральное, цирковое и киноискусство
export const THEATRE_CREATION = [
  "Театральное и музыкальное творчество",
  "Музыкальный театр",
  "Театр миниатюр",
  "Драматический театр",
  "Театр теней",
  "Театр кукол",
  "Мюзикл",
  "Исторический театр",
  "Основы театрального и музыкального творчества",
  "Пантомима",
  "Акробатика",
  "Фокусы",
  "Клоунада",
  "Жонглирование",
];

// Музыка
export const MUSIC_CREATION = [
  "Фортепиано",
  "Гитара",
  "Балалайка",
  "Домра",
  "Гусли",
  "Баян",
  "Аккордеон, баян",
  "Скрипка",
  "Арфа",
  "Виолончель",
  "Иные струнные инструменты",
  "Саксофон",
  "Флейта",
  "Иные духовые инструменты",
  "Ударные инструменты (барабаны, ксилофон, литавры)",
  "Оркестр или ансамбль струнных инструментов",
  "Оркестр или ансамбль духовых инструментов",
  "Оркестр или ансамбль народных инструментов",
  "Эстрадно-джазовый ансамбль",
  "Электроинструменты",
  "Синтезатор",
  "Эстрадный вокал",
  "Академическое пение",
  "Народное пение",
  "Авторская песня",
  "Вокальный ансамбль",
  "Народный хор",
  "Фольклорный или этнокультурный коллектив",
  "Народный ансамбль",
  "Ансамбль авторской песни",
  "Академический хор",
];

// Хореография
export const CHOREOHRAPHY_CREATION = [
  "Эстрадный танец",
  "Современный танец",
  "Классический танец, балет",
  "Народный танец",
  "Историко-бытовой танец",
  "Бальный танец",
  "Джаз-модерн танец",
  "Степ",
];

// Литературно-словесное творчество
export const LITERATURE_CREATION = [
  "Художественное чтение",
  "Разговорный эстрадный жанр",
  "Литературное творчество",
  "Литературоведение",
];

// Изобразительное искусство
export const FINE_ART_CREATION = [
  "История изобразительного искусства",
  "Современные формы изобразительного искусства",
  "Живопись, графика",
  "Скульптура",
  "Художественная фотография",
  "Театр моды",
  "Современные техники декорирования",
  "Экодизайн (фито, ландшафт, мебель, одежда, интерьер и т.д.)",
];

// Декоративно-прикладное творчество
export const CRAFTS_CREATION = [
  "Художественные и ремонтные работы с изделиями, состоящими из различных материалов",
  "Работа с глиной, песком, тестом и пластилином",
  "Работа с древесными материалами, берестой, соломой и бумагой",
  "Изготовление швейного изделия",
  "Работа со стеклом, бисером и современной фурнитурой",
  "Работа с природными материалами",
  "Работа с тканью и текстильными материалами",
  "Работы со смальтой и натуральными камнями",
  "Изготовление кукол и игрушек",
  "Работа с цветами и растениями",
  "Работа с кожей и мехом",
  "Работа с металлами, ювелирное дело",
];
