import { typographyClasses } from '@mui/material';
import { getAccentColor, NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';
import { inheritSx } from 'utils';

import { barCircleBase } from '../style';

export const barPlace: SxStyles = (theme) => ({
  ...inheritSx([barCircleBase]),
  position: 'relative',

  '&.active': {
    border: `1px solid ${getAccentColor(theme.palette.accentColor, '100')}`,

    backgroundColor: getAccentColor(theme.palette.accentColor, '80'),

    [`.${typographyClasses.root}`]: {
      color: NeutralColors.light_neutrals_0,
    },
  },
});

export const barCrown: SxStyles = () => ({
  position: 'absolute',
  top: '-15px',
});
