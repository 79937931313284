import { FC } from 'react';

import { SvgIcon } from '@mui/material';
import { getAccentColor } from 'portfolio3/styles';

const SuccessIndicator: FC = () => {
  return (
    <SvgIcon width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="m17.154 9.126-5.482 7.2a1.2 1.2 0 0 1-.947.474h-.008c-.368 0-.716-.17-.944-.46l-2.919-3.73a1.2 1.2 0 1 1 1.89-1.478l1.96 2.504 4.54-5.963a1.2 1.2 0 0 1 1.91 1.453ZM12 0C5.372 0 0 5.372 0 12c0 6.626 5.372 12 12 12s12-5.374 12-12c0-6.628-5.372-12-12-12Z"
        fill={getAccentColor('green', '100')}
      />
    </SvgIcon>
  );
};

export default SuccessIndicator;
