import { FC, ReactNode, useContext } from 'react';

import { inputBaseClasses, InputLabel, inputLabelClasses, InputLabelProps, styled } from '@mui/material';
import clsx from 'clsx';
import { InputFormControlContext } from 'context';
import { NeutralColors } from 'portfolio3/styles';
import { CommonUiKitSize, InputRenderMode } from 'portfolio3/ui-kit/types';
import { getInputTokens, InputTokens } from 'portfolio3/ui-kit/utils';
import { Colors } from 'style/variables';
import { numberToPixelsString, SafeOmit, skipPropsForwarding } from 'utils';

interface IBaseInputLabelProps extends SafeOmit<InputLabelProps, 'size'> {
  overrideRenderMode?: InputRenderMode;
  overrideInputSize?: CommonUiKitSize;
  endAdornment?: ReactNode;
}

interface IStyledInputLabelProps {
  renderMode?: InputRenderMode;
  inputSize?: CommonUiKitSize;
}

const StyledInputLabel = styled(InputLabel, {
  shouldForwardProp: skipPropsForwarding<IStyledInputLabelProps>(['renderMode', 'inputSize']),
})<IStyledInputLabelProps>(({ renderMode = 'fixed', inputSize, hidden }) => {
  const tokens = inputSize ? getInputTokens(inputSize) : InputTokens[renderMode];

  const { fontSize, lineHeight } = tokens;

  const isFloating = renderMode === 'floating';
  const position = isFloating ? 'absolute' : 'static';
  const top = isFloating ? '10px' : 0;
  const transform = isFloating ? `translate(${tokens.paddingLeft}px, ${tokens.paddingBlock}px)` : 'none';
  const focusedTransform = isFloating
    ? `translate(${tokens.paddingLeft - 3}px, -${tokens.fontSize / 2}px) scale(0.75)`
    : 'none';

  return {
    display: 'flex',
    alignItems: 'center',
    zIndex: 1,
    position,
    top,
    transform,
    transitionProperty: 'width, padding, transform, font-size, font-weight',
    transitionDuration: '.1s',

    fontSize: numberToPixelsString(fontSize),
    lineHeight: numberToPixelsString(lineHeight),

    pointerEvents: 'none',

    ...(hidden && {
      visibility: 'hidden',
    }),

    [`&.${inputLabelClasses.root}`]: {
      color: NeutralColors.light_neutrals_900,
    },

    // disabled floating
    [`&.${inputLabelClasses.disabled}`]: isFloating && {
      color: NeutralColors.light_neutrals_600,
    },

    [`&.${inputLabelClasses.focused}, &.${inputLabelClasses.filled}, &.${inputLabelClasses.shrink}`]: !isFloating
      ? {}
      : {
          // active, filled floating label
          transform: focusedTransform,
          fontWeight: 700,
          color: NeutralColors.light_neutrals_700,

          paddingInline: 6,

          // позиционирование линии, которая будет перекрывать бордер инпута
          '&::before': {
            zIndex: '-1',
            content: '""',
            position: 'absolute',
            top: 'calc(50% - 4px)',
            left: 0,
            right: 0,

            height: '5px',
            width: '100%',
            backgroundColor: '#ffffff',
          },
        },

    [`& .${inputLabelClasses.asterisk}`]: {
      color: Colors['red-rose'],
      fontSize: '20px',
      lineHeight: '16px',
    },

    // Стили для скрытия лейсхолдера в режиме floating
    // в случае, когда инпут обернут в другой элемент
    // + 1 к уровню вложенности инпута
    [`&[data-shrink=false] +  * > .${inputBaseClasses.root} .${inputBaseClasses.input}`]: {
      '&::placeholder': {
        opacity: '0 !important',
      },
    },

    '& .base-input-label__end-adornment': {
      marginLeft: 8,
    },
  };
});

/**
 * Компонент для добавления подписей к контролам с базой InputBase
 */
const BaseInputLabel: FC<IBaseInputLabelProps> = ({
  overrideRenderMode,
  overrideInputSize,
  children,
  endAdornment,
  ...props
}) => {
  const { renderMode, inputSize } = useContext(InputFormControlContext);

  const computedRenderMode = overrideRenderMode ?? renderMode;
  const computedInputSize = overrideInputSize ?? inputSize;

  const isFloating = computedRenderMode === 'floating';

  return (
    <StyledInputLabel
      shrink={!isFloating || props.focused}
      {...props}
      renderMode={computedRenderMode}
      inputSize={computedInputSize}
      className={clsx(props.className, 'base-input-label')}
    >
      {children}
      {endAdornment && <span className="base-input-label__end-adornment">{endAdornment}</span>}
    </StyledInputLabel>
  );
};

export default BaseInputLabel;
