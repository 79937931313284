import { SxProps } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';

export const root: SxProps = {
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
};

export const divider: SxProps = {
  display: 'inline-block',
  width: '5px',
  height: '5px',
  marginInline: '8px',
  borderRadius: '50%',
  backgroundColor: NeutralColors.light_neutrals_400,
};
