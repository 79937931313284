import { IVuzFaculty } from 'api/types';
import { isDefined } from 'utils';

import { ILocalVuzSpeciality } from '../../types';
import { convertVuzFacultySpecialityToLocal } from '../../utils';
import { mergeSpecialities } from '../../utils';

/**
 * Принимает массив факультетов и конвертирует внутренние специальности под нужный интерфейс
 * @param faculties Массив факультетов из метода /faculties
 * @returns Массив специальностей с нужным типом ILocalVisibility
 */
export function flatenSpecialities(faculties: IVuzFaculty[]) {
  const flatSpecialities = faculties
    .flatMap((faculty) => {
      return faculty.specialities.map((speciality) => convertVuzFacultySpecialityToLocal(speciality, faculty.name));
    })
    .filter(isDefined);
  return flatSpecialities;
}

/**
 * Принимает специальности из метода /search и /faculties, если находит более полную специальность,
 * сливает ее со старой из метода /search. Если не находит, возвращает /search специальность
 * @param searchSpecialities массив специальностей из метода /search (не полная информация о специальностях)
 * @param facultySpecialities массив специальностей из метода /faculties (полная информация о специальностях)
 * @returns готовый для вывода массив специальностей
 */
export function complementSearchWithFacultySpecialities(
  searchSpecialities: ILocalVuzSpeciality[],
  facultySpecialities: ILocalVuzSpeciality[],
) {
  const filteredSpecialities = searchSpecialities.map((searchSpeciality) => {
    const searchRelatedFacultySpeciality = facultySpecialities.find((facultySpeciality) => {
      const codeNameCondition =
        `${facultySpeciality.number} ${facultySpeciality.name}`.toLowerCase() ===
        `${searchSpeciality.number} ${searchSpeciality.name}`.toLowerCase();

      const facultyCondition = searchSpeciality.facultyName === facultySpeciality.facultyName;

      const searchSpecialitySubjects = searchSpeciality.subjects
        ?.map((subject) => subject.toLowerCase())
        .sort()
        .join();
      const facultySpecialitySubjects = facultySpeciality.subjects
        ?.map((subject) => subject.toLowerCase())
        .sort()
        .join();
      const subjectsCondition = searchSpecialitySubjects === facultySpecialitySubjects;

      return codeNameCondition && facultyCondition && subjectsCondition;
    });
    if (!searchRelatedFacultySpeciality) return searchSpeciality;

    return mergeSpecialities(searchSpeciality, searchRelatedFacultySpeciality);
  });
  return filteredSpecialities;
}
