/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import ResultDoughnut from 'components/common/ResultDoughnut';
import { getDoughnutData, getDoughnutGradeColor, getDoughnutPercentColor } from 'utils';

import './index.scss';

interface ISubjectOverviewProps {
  subject: string;
  subjectResult: number;
  backgroundImage?: string;
  passedTopics: number;
  totalTopics: number;
  topicsDonePercent: number;
}

const SubjectOverview: React.FC<ISubjectOverviewProps> = ({
  subject,
  subjectResult,
  backgroundImage,
  passedTopics,
  totalTopics,
  topicsDonePercent
}) => {
  const topicsProgress = topicsDonePercent + '%';

  return (
    <div
      className="subject-overview"
      style={{
        background: backgroundImage && `url(${backgroundImage}) no-repeat 110% 15px`
      }}
    >
      <div>
        <h5 className="subject-overview__title">{subject}</h5>
        <div className="subject-topic-details-wrapper">
          <div className="subject-topic-details">
            <div className="subject-topic-details__result">
              <ResultDoughnut
                borderThickness={85}
                total={topicsProgress}
                data={getDoughnutData(passedTopics, totalTopics)}
                color={getDoughnutPercentColor(passedTopics, totalTopics)}
              />
            </div>
            <div>
              <p className="subject-topic-details__title">Пройдено тем</p>
              <p className="subject-topic-details__description">{passedTopics} из {totalTopics}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="subject-overview__result">
        <ResultDoughnut
          borderThickness={85}
          total={subjectResult}
          data={getDoughnutData(subjectResult, 5)}
          color={getDoughnutGradeColor(subjectResult)}
        />
      </div>
    </div>
  );
};

export default SubjectOverview;
