import { FC } from 'react';

import { CommonUiKitSize } from '../types';
import TokenBase from './TokenBase';

interface ITokenFieldTokenProps {
  label: string;
  size: CommonUiKitSize;
  disabled?: boolean;
  onDelete: () => void;
}

const Token: FC<ITokenFieldTokenProps> = ({ label, size, disabled, onDelete }) => {
  return <TokenBase label={label} size={size} disabled={disabled} onDelete={onDelete} />;
};

export default Token;
