import { SxProps } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';
import { getFocusStateStyles } from 'portfolio3/ui-kit/utils';

interface IStyles {
  button: SxProps;
  menu: (minWidth: number) => SxProps;
}

export const styles: IStyles = {
  button: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '8px',
    // 5 из-за бордера
    padding: '5px 8px',
    border: `1px solid ${NeutralColors.light_neutrals_300}`,
    borderRadius: '12px',
    textTransform: 'none',
    backgroundColor: NeutralColors.light_neutrals_0,

    '&:hover': {
      backgroundColor: NeutralColors.light_neutrals_100,
    },

    '&[aria-expanded="true"]': {
      backgroundColor: NeutralColors.light_neutrals_200,
    },

    '&:focus-visible': {
      ...getFocusStateStyles(),
    },
  },
  menu: (minWidth) => ({
    '& .MuiPaper-root': {
      padding: '4px',
      marginTop: '8px',
      minWidth,
      maxHeight: '240px',
      boxShadow: '0px 4px 6px -2px rgba(41, 41, 64, 0.04), 0px 10px 15px -3px rgba(41, 41, 64, 0.08)',
      borderRadius: '12px',
      border: `1px solid ${NeutralColors.light_neutrals_300}`,
      overflow: 'auto',
    },
    '& .MuiList-root': {
      display: 'flex',
      flexDirection: 'column',
      gap: '2px',
      padding: 0,
    },
  }),
};
