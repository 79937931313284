enum ChapterKeys {
  FIRST = 'FIRST',
  SECOND = 'SECOND',
  THIRD = 'THIRD',
  FOURTH = 'FOURTH',
}

export const importerChapters: Record<ChapterKeys, number> = {
  // выбор раздела
  FIRST: 1,
  // выбор файла
  SECOND: 2,
  // предпросмотр
  THIRD: 3,
  // результат
  FOURTH: 4,
};
