import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconFunnelFilled: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M13.903 22a.992.992 0 0 1-.603-.203l-4.026-3.045a1 1 0 0 1-.397-.797v-3.274L4.111 5.459A.999.999 0 0 1 5.001 4h14a1 1 0 0 1 .88 1.475l-4.978 9.215V21a1.003 1.003 0 0 1-1 1Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconFunnelFilled;
