import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root: SxStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '12px',

  border: '1px solid',
  borderColor: NeutralColors.light_neutrals_300,
  borderRadius: '8px',
};

export const select: SxStyles = {
  width: 'max-content',
  minWidth: '190px',
  border: 'none',
};
