/* eslint-disable @typescript-eslint/no-explicit-any */
import { REQUEST } from 'actions/utils';
import { getStudentPerformanceLessonsOld, getStudentPerformanceOld } from 'api';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import { GenericGetAction } from 'sagas';

import {
  GET_STUDENT_PERFORMANCE_LESSONS_OLD,
  GET_STUDENT_PERFORMANCE_OLD,
  getStudentPerformanceLessonsOldActions,
  getStudentPerformanceOldActions,
} from './actions';

export const collection = [watchGetStudentPerformance, watchGetStudentPerformanceLessons].map(fork);

function* watchGetStudentPerformance() {
  yield takeLatest(GET_STUDENT_PERFORMANCE_OLD[REQUEST], getStudentPerformanceSaga);
}
function* watchGetStudentPerformanceLessons() {
  yield takeLatest(GET_STUDENT_PERFORMANCE_LESSONS_OLD[REQUEST], getStudentPerformanceLessonsSaga);
}

function* getStudentPerformanceSaga({ payload: { personId } }: GenericGetAction<any>) {
  const { response, error } = yield call(getStudentPerformanceOld, personId);

  if (response) {
    yield put(getStudentPerformanceOldActions.success(response));
  } else {
    yield put(getStudentPerformanceOldActions.failure(error));
  }
}

function* getStudentPerformanceLessonsSaga({ payload: { personId, subjectName, meta } }: GenericGetAction<any>) {
  const { response, error } = yield call(getStudentPerformanceLessonsOld, personId, subjectName, meta);

  if (response) {
    yield put(getStudentPerformanceLessonsOldActions.success(response));
  } else {
    yield put(getStudentPerformanceLessonsOldActions.failure(error));
  }
}
