import { createSelector } from 'reselect';

import { IRootState } from '../../../reducers';
import { mapCultureVisitingsResponse } from './mappers';

const cultureVisitingsSelector = createSelector(
  [
    (state: IRootState) => state.studentEvents,
    (state: IRootState) => state.studentCheckInHistory,
    (state: IRootState) => state.cultureVisitings,
    (state: IRootState) => state.sectionRef,
  ],
  (studentEvents, checkIns, cultureVisitings, sectionRef) => {
    return mapCultureVisitingsResponse(studentEvents, checkIns, cultureVisitings, sectionRef);
  },
);

export default cultureVisitingsSelector;
