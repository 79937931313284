import { FC } from 'react';

import { useMediaQuery } from '@mui/material';
import { AvatarTypeRef } from 'const';
import { useAppSelector, useAvatar, useLinkModeStudentName } from 'hooks';
import { commonTheme } from 'portfolio3/styles/theme';
import Avatar from 'portfolio3/ui-kit/Avatar';
import { SxStyles } from 'types';

import { FirstMovementAvatarAttachmentLarge } from '../FirstMovementAvatarAttachment';

interface IProfileInfoAvatarProps {
  avatarSize: number;
  avatarTypeOverride?: number;
  avatarSx?: SxStyles;
}

const ProfileInfoAvatar: FC<IProfileInfoAvatarProps> = ({ avatarSize, avatarTypeOverride, avatarSx }) => {
  const currentStudent = useAppSelector((state) => state.currentStudent);
  const isSmallScreen = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const { firstname, lastname } = currentStudent;
  const { firstName, lastName } = useLinkModeStudentName(firstname, lastname);

  const { avatarImage, avatarType } = useAvatar();

  const avatarElement = (
    <Avatar
      image={avatarImage}
      firstName={firstName ?? ''}
      lastName={lastName ?? ''}
      size={avatarSize}
      containerSx={avatarSx}
    />
  );

  const firstMovementAvatarElement = (
    <FirstMovementAvatarAttachmentLarge isSmall={isSmallScreen}>{avatarElement}</FirstMovementAvatarAttachmentLarge>
  );

  const codeToAvatarElement = {
    [AvatarTypeRef.default]: avatarElement,
    [AvatarTypeRef.firstMovement]: firstMovementAvatarElement,
  };

  return codeToAvatarElement[avatarTypeOverride ?? avatarType] ?? avatarElement;
};

export default ProfileInfoAvatar;
