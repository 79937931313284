/* eslint-disable max-len */
import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconSportUnitsCommon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.898 0h3.2v2h3.2V0h3.2v2h3.2V0h3.2v5.2h-16V0Zm15.5 15.9h-14.8c.4-1.9.9-5 .9-8.7h13c.1 3.7.6 6.8.9 8.7ZM2.598 18h18.7v3h-18.7v-3Zm20 6h-21.2v-3h21.3l-.1 3Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconSportUnitsCommon;
