export enum ChangeHistoryTabs {
  manual = 1,
  import = 2,
}
export const ChangeHistoryTabsRoute: Record<ChangeHistoryTabs, string> = {
  [ChangeHistoryTabs.manual]: 'manual',
  [ChangeHistoryTabs.import]: 'import',
};

export enum AdminSettingsPageTabs {
  settings = 1,
  history = 2,
}
export const AdminSettingsPageTabsRoute: Record<AdminSettingsPageTabs, string> = {
  [AdminSettingsPageTabs.settings]: 'settings',
  [AdminSettingsPageTabs.history]: 'history',
};

export enum AdminAnalyticsPageTabs {
  logs = 1,
  reports = 2,
}
export const AdminAnalyticsPageTabsRoute: Record<AdminAnalyticsPageTabs, string> = {
  [AdminAnalyticsPageTabs.logs]: 'logs',
  [AdminAnalyticsPageTabs.reports]: 'reports',
};
