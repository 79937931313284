import { SxStyles } from 'types';

export const root: SxStyles = (theme) => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '16px',

    marginBottom: '16px',

    [theme.breakpoints.down('commonSm')]: {
      marginBottm: '12px',
    },
  };
};

export const countBadgeLocalStyles: SxStyles = (theme) => {
  return {
    display: 'inline-flex',
    transform: 'translateY(-1px)',
    marginLeft: '8px',

    [theme.breakpoints.down('commonSm')]: {
      marginLeft: '4px',
    },
  };
};
