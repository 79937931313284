import { forwardRef } from 'react';

import { MenuProps } from '@mui/material';

import StyledBaseMenu from './BaseMenu.styled';

const BaseMenu = forwardRef<HTMLDivElement, MenuProps>((props, ref) => {
  return (
    <StyledBaseMenu
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transitionDuration={{
        appear: 300,
        enter: 300,
        exit: 250,
      }}
      {...props}
      ref={ref}
    />
  );
});

BaseMenu.displayName = 'BaseMenu';

export default BaseMenu;
