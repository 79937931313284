import { SxStyles } from 'types';

export const resultBar: SxStyles = () => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
  width: '100%',
});

export const resultContent: SxStyles = () => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});
