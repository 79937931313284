import { FC } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { proforientationModelTypes } from 'portfolio3/features/proforientation';
import { NeutralColors } from 'portfolio3/styles';

import * as styles from './styles';

interface IProfessionRecommendationCardProps {
  recommendation: proforientationModelTypes.IRecommendedIndustry;
}

const ProfessionRecommendationCard: FC<IProfessionRecommendationCardProps> = ({ recommendation }) => {
  const { name, specialities, collegeNames } = recommendation;

  const specialitiesElement = specialities.length > 0 && (
    <Stack gap="8px">
      <Typography variant="Paragraph MD/Regular" color={NeutralColors.light_neutrals_700}>
        Специальности:
      </Typography>
      {specialities.map((spec) => {
        const { name, code } = spec;

        return (
          <Box key={`${name}-${code}`} sx={styles.specialityContainer}>
            <Typography variant="Paragraph LG/Regular">{name}</Typography>
            <Typography variant="Paragraph MD/Regular" color={NeutralColors.light_neutrals_700}>
              {code}
            </Typography>
          </Box>
        );
      })}
    </Stack>
  );

  const collegesElement = collegeNames.length > 0 && (
    <Stack gap="8px">
      <Typography variant="Paragraph MD/Regular" color={NeutralColors.light_neutrals_700}>
        Колледжи:
      </Typography>
      {collegeNames.map((college) => {
        return (
          <Typography key={college} variant="Paragraph LG/Regular">
            {college}
          </Typography>
        );
      })}
    </Stack>
  );

  const isDetailsVisible = specialitiesElement || collegesElement;

  return (
    <Box className="recommendation-card pdf-keep" sx={styles.root}>
      <Typography variant="Paragraph LG/Semi Bold">{name}</Typography>

      {isDetailsVisible && (
        <Stack marginTop="16px" gap="16px">
          {specialitiesElement}
          {collegesElement}
        </Stack>
      )}
    </Box>
  );
};

export default ProfessionRecommendationCard;
