import { FAILURE, INVALIDATE, REQUEST, SUCCESS } from 'actions/utils';
import { ICommonResponseObject, IProfessionProforientationData } from 'api/types';
import { IReducers } from 'reducers';
import genericReducers from 'reducers/genericReducer';
import { connectReducers } from 'utils';

import { GET_PROFORIENTATION } from '../actions';

export type ProforientationState = ICommonResponseObject<IProfessionProforientationData | null>;

const initialState: ProforientationState = {
  content: null,
  loading: false,
};

const proforientation: IReducers<ProforientationState> = {
  [GET_PROFORIENTATION[REQUEST]]: genericReducers().get.request,
  [GET_PROFORIENTATION[SUCCESS]]: genericReducers().get.success,
  [GET_PROFORIENTATION[FAILURE]]: genericReducers().get.failure,
  [GET_PROFORIENTATION[INVALIDATE]]: genericReducers().get.invalidate(initialState),
};

export default connectReducers(initialState, proforientation);
