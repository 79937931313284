import { Dispatch, FC, SetStateAction, useEffect, useRef } from 'react';

import { Box } from '@mui/material';
import { SourceCode } from 'const';
import { useDataEntryDrawer, useDetailViewDrawer, useUserFunctionality } from 'hooks';
import { IModifiedPersonObject } from 'portfolio3/features/dataEntryForm';

import PortfolioDataItem from '../PortfolioDataItem';
import { IDeleteDialogData } from './types';

interface IPortfolioDataItemRowProps {
  index: number;
  filteredPersonsObjects: IModifiedPersonObject[];
  isActionsDisabled: boolean;
  updateSizeMap: (index: number, size: number) => void;
  setDeleteDialogData: Dispatch<SetStateAction<IDeleteDialogData | undefined>>;
}

const PortfolioDataItemRow: FC<IPortfolioDataItemRowProps> = ({
  index,
  filteredPersonsObjects,
  isActionsDisabled,
  updateSizeMap,
  setDeleteDialogData,
}) => {
  const { handleOpenPrimaryDetailView } = useDetailViewDrawer();
  const { handleOpenPrimaryDataEntry } = useDataEntryDrawer();
  const { isOperator, isEmployee } = useUserFunctionality();

  const rowRef = useRef<HTMLDivElement>(null);

  const object = filteredPersonsObjects[index];
  const sourceCode = object.source.code;

  const isOperatorOrEmployee = isOperator || isEmployee;
  const hasEditPermission = isOperatorOrEmployee && sourceCode !== SourceCode.uchiRu;

  useEffect(() => {
    updateSizeMap(index, rowRef.current?.getBoundingClientRect().height ?? 0);
  }, [updateSizeMap, index]);

  if (!object) return null;

  return (
    <Box className="portfolio-data-item-row-ref" ref={rowRef}>
      <PortfolioDataItem
        personObject={object}
        canUserEdit={hasEditPermission}
        isMenuActionsDisabled={isActionsDisabled}
        onEditEntryFormData={handleOpenPrimaryDataEntry}
        onOpenDataCard={handleOpenPrimaryDetailView}
        onDeletePortfolioObject={setDeleteDialogData}
      />
    </Box>
  );
};

export default PortfolioDataItemRow;
