import { ComponentType, ElementType, FC, PropsWithChildren } from 'react';

import { IHeaderContainerCommonProps } from 'portfolio3/containers/headers/types';

interface IWithHeaderContainerProps {
  HeaderComponent: ElementType<IHeaderContainerCommonProps>;
  isFullWidth: boolean;
}

const WithHeaderContainer: FC<IWithHeaderContainerProps & PropsWithChildren> = ({
  HeaderComponent,
  isFullWidth,
  children,
}) => {
  return (
    <>
      <HeaderComponent isFullWidth={isFullWidth} />
      {children}
    </>
  );
};

const withHeader = (HeaderComponent: ElementType<IHeaderContainerCommonProps>, isFullWidth: boolean) => {
  return <P extends PropsWithChildren<unknown>>(WrappedComponent: ComponentType<P>): FC<P> => {
    return function WithHeader({ ...props }) {
      return (
        <WithHeaderContainer HeaderComponent={HeaderComponent} isFullWidth={isFullWidth}>
          <WrappedComponent {...props} />
        </WithHeaderContainer>
      );
    };
  };
};

export default withHeader;
