import { FC } from 'react';

import { Box } from '@mui/material';
import { smileCat } from 'images';
import { SxStyles } from 'types';

const smileCatImage: SxStyles = (theme) => {
  return {
    width: '150px',
    height: '150px',

    [theme.breakpoints.down('commonSm')]: {
      width: '124px',
      height: '124px',
    },
  };
};

const CatImagePlug: FC = () => {
  return <Box component="img" src={smileCat} alt="Нет данных" sx={smileCatImage} />;
};

export default CatImagePlug;
