import { cardIllustrations } from 'images';

import {
  IAttachment,
  IStudentCheckIn,
  IStudentCultureVisiting,
  IStudentEvent,
  IStudentFile,
  SectionRefItem,
} from '../../api/types';
import { AUTOMATICALLY_ADDED_SOURCE_VALUE, EntityType, SectionCodes, SourceCode } from '../../const';
import { IEntity } from '../../types';
import {
  flagsMap,
  getCultureEntityBackgroundImage,
  getDateRange,
  getEntityFeatures,
  getEntityGeneralInfo,
  getYearFromDate,
} from '../../utils';

export const mapCultureEventToEntity = (sectionRef: SectionRefItem[], event: IStudentEvent): IEntity => {
  const {
    id,
    recordId,
    name,
    source,
    startDate,
    endDate,
    typeCode,
    levelEvent,
    format: eventFormat,
    locationName,
    eventKindCode,
  } = event;

  const entityTypes = locationName ? [locationName] : undefined;
  const entityFeatures = getEntityFeatures(sectionRef, typeCode, levelEvent, eventFormat);

  return {
    id,
    recordId,
    name,
    types: entityTypes,
    date: getDateRange(startDate, endDate),
    year: getYearFromDate(endDate || startDate),
    features: entityFeatures,
    backgroundImage: getCultureEntityBackgroundImage(eventKindCode),
    formData: event,
    cardData: {
      name,
      generalInfo: getEntityGeneralInfo(event),
      files: event.fileReferences,
      linkedObjects: event.linkedObjects,
      formData: event,
      illustrationFallback: cardIllustrations.cultureVisiting,
    },
    entityProps: {
      type: EntityType.CULTURAL_EVENT,
      flags: flagsMap({ approved: source.code !== SourceCode.student && source.code !== SourceCode.parent }),
    },
  };
};

export const mapCheckInToEntity = (
  sectionRef: SectionRefItem[],
  checkIn: IStudentCheckIn,
  attachments?: IAttachment[],
): IEntity => {
  const {
    id,
    culturalEvent,
    date,
    typeCode,
    dataKind,
    categoryCode,
    level,
    format,
    museum,
    cinema,
    theatre,
    event,
    organizer,
    description,
    source,
    creationDate,
    linkedObjects,
    personId,
  } = checkIn;

  const name = culturalEvent?.value || event || '';

  const entityTypes = [museum ? museum?.value : '', theatre ? theatre?.value : '', cinema ? cinema?.value : ''];

  const formData = {
    id,
    personId,
    categoryCode,
    dataKind,
    typeCode,
    locationName: museum?.value || theatre?.value || cinema?.value,
    location: museum?.address || theatre?.address || cinema?.address,
    date,
    name,
    organizators: organizer,
    description,
    source,
    creationDate,
    linkedObjects,
    fileReferences: attachments || [],
    startDate: date,
    isCheckIn: true,
  };

  return {
    id,
    name,
    types: entityTypes.filter((type) => type),
    date: getDateRange(date),
    year: getYearFromDate(date),
    features: getEntityFeatures(sectionRef, typeCode ?? SectionCodes.cultureVisiting, level, format),
    backgroundImage: getCultureEntityBackgroundImage(dataKind),
    formData,
    cardData: {
      name,
      generalInfo: getEntityGeneralInfo(formData),
      files: formData.fileReferences.map(({ id, name, isDelete }): IStudentFile => {
        return {
          id,
          name: name ?? '',
          deleted: isDelete,
        };
      }),
      linkedObjects: formData.linkedObjects,
      formData,
      illustrationFallback: cardIllustrations.cultureVisiting,
    },
    entityProps: {
      type: EntityType.CULTURAL_EVENT,
      flags: ['approved', 'cultureCheckIn'],
    },
  };
};

export const mapCultureVisitingToEntity = (cultureVisiting: IStudentCultureVisiting): IEntity => {
  const { recordId, personId, visitTime, eventType, culturalInstitutionType, culturalInstitutionName } =
    cultureVisiting;

  const formData = {
    recordId,
    personId,
    categoryCode: SectionCodes.culture,
    typeCode: SectionCodes.cultureVisiting,
    locationName: culturalInstitutionName,
    date: visitTime,
    name: eventType,
    source: { value: AUTOMATICALLY_ADDED_SOURCE_VALUE },
    isApproved: true,
    creationDate: visitTime,
    startDate: visitTime,
    dataKind: SectionCodes.cultureEvent,
  };

  return {
    name: culturalInstitutionType,
    types: [culturalInstitutionName],
    date: getDateRange(visitTime),
    year: getYearFromDate(visitTime),
    features: [{ text: 'муниципальный' }, { text: 'очно' }],
    backgroundImage: getCultureEntityBackgroundImage(null),
    formData,
    cardData: {
      name: culturalInstitutionType,
      generalInfo: getEntityGeneralInfo(formData),
      files: null,
      formData,
      illustrationFallback: cardIllustrations.cultureVisiting,
    },
    entityProps: {
      type: EntityType.CULTURAL_EVENT,
      flags: ['approved', 'undeletable'],
    },
  };
};
