import { FC, useContext } from 'react';

import { Typography, useMediaQuery } from '@mui/material';
import { IIndependentDiagnosticInfo } from 'api/types';
import { commonTheme } from 'portfolio3/styles/theme';
import { isDefined } from 'utils';

import DiagnosticContext from '../../context/diagnosticContext';
import DynamicSubjectChart from '../../DynamicSubjectChart';
import { IDiagnosticChartItem } from '../../DynamicSubjectChart/types';
import { getChartLevelType } from '../../utils';

interface IDiagnosticCardSubjectDynamicProps {
  subject: string;
}

const DiagnosticCardSubjectDynamic: FC<IDiagnosticCardSubjectDynamicProps> = ({ subject }) => {
  const { independentDiagnostics } = useContext(DiagnosticContext);
  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  /* формирование данных для графика */
  // формирование плоского списка из всех диагностик
  const subjectFlatDiagnostics = independentDiagnostics.content
    ?.flatMap((group) => group.diagnostics.diagnostic)
    .filter((diagnostic) => diagnostic?.subject === subject)
    .filter(isDefined);

  // формирование групп диагностик по годам (для определения лучшей диагностики за год)
  const yearsToDiagnosticsMap = new Map<number, IIndependentDiagnosticInfo[]>();
  subjectFlatDiagnostics?.forEach((diagnostic) => {
    const { eventDate } = diagnostic;
    const year = new Date(eventDate).getFullYear();

    const subjectDiagnostics = yearsToDiagnosticsMap.get(year) || [];
    subjectDiagnostics.push(diagnostic);
    yearsToDiagnosticsMap.set(year, subjectDiagnostics);
  });

  const sortedDiagnosticGroups = Array.from(yearsToDiagnosticsMap.entries()).sort(([leftYear], [rightYear]) => {
    return leftYear - rightYear;
  });

  const subjectDynamicChartItems = sortedDiagnosticGroups
    .map(([year, diagnostics]): IDiagnosticChartItem | null => {
      const bestYearDiagnostic = diagnostics.reduce<IIndependentDiagnosticInfo | null>((diagnostic, current) => {
        if (!diagnostic || current.resultValue > diagnostic.resultValue) {
          return current;
        }
        return diagnostic;
      }, null);

      if (!bestYearDiagnostic) return null;

      return {
        value: bestYearDiagnostic.resultValue,
        interval: String(year),
        levelType: getChartLevelType(bestYearDiagnostic.levelType),
      };
    })
    .filter(isDefined);

  if (subjectDynamicChartItems.length < 2) {
    return null;
  }

  return (
    <>
      <Typography variant="Paragraph MD/Semi Bold" sx={{ marginBottom: '24px' }}>
        Динамика результатов по предмету {subject}
      </Typography>
      <DynamicSubjectChart
        chartData={[{ name: subject, values: subjectDynamicChartItems }]}
        lineColors={['#12B886']}
        verticalLegendSpacing={isMobile ? 24 : 16}
        chartHeight={160}
      />
    </>
  );
};

export default DiagnosticCardSubjectDynamic;
