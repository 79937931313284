import { SxProps } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';
import { getFocusStateStyles } from 'portfolio3/ui-kit/utils';

export const buttonStyles: SxProps = {
  display: 'flex',
  alignItems: 'center',

  minWidth: 'max-content',
  height: 40,
  paddingInline: '12px',

  borderRadius: 12,
  textDecoration: 'none',

  color: NeutralColors.light_neutrals_900,
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '20px',

  '&:hover': {
    backgroundColor: 'unset',
  },

  '&.navigation-link--active': {
    backgroundColor: NeutralColors.light_neutrals_300,
    fontWeight: 600,
  },

  '&:focus-visible': {
    ...getFocusStateStyles(),
  },
};
