import { FC, useEffect, useMemo, useState } from 'react';

import { useMediaQuery } from '@mui/material';
import { StudentSectionSettingTypeCodes, studyNavigation } from 'const';
import { useAppSelector, useBrightTheme, useDataLoad, useWidgetVisibility } from 'hooks';
import { IconBarChart, IconGridFilled, IconPieChartFilled } from 'icons';
import { placeholderCharacter, widgetIllustrations } from 'images';
import NoDataPlug from 'portfolio3/components/common/NoDataPlug';
import { WidgetContainer as Widget } from 'portfolio3/components/common/WidgetContainer';
import { WidgetContainerOptions, WidgetContainerTitle } from 'portfolio3/components/widgetContainerRecipes';
import { emitYMEvent } from 'portfolio3/features/yandexMetrika';
import { commonTheme } from 'portfolio3/styles/theme';
import { Switcher, SwitcherButton } from 'portfolio3/ui-kit';
import { getDataLoadRequests } from 'utils';

import PerformanceFilters from '../components/PerformanceFilters';
import { isPerformanceWidgetHasDataSelector } from '../model';
import { IYearOption, MarkType, VisibilityType } from '../types';
import PerformanceContainer from './PerformanceContainer';

const sectionTypeCode = StudentSectionSettingTypeCodes.study;
const subsectionTypeCode = StudentSectionSettingTypeCodes.studyPerformance;

const PerformanceWidgetContainer: FC = () => {
  const studentPerformance = useAppSelector((state) => state.studentPerformanceOld.content?.year ?? []);
  const isLoading = useAppSelector((state) => state.studentPerformanceOld.loading) ?? false;
  const hasData = useAppSelector(isPerformanceWidgetHasDataSelector);

  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const isBrightTheme = useBrightTheme();
  const { isSettingsMode, isWidgetVisible, toggleElement, overlayElement } = useWidgetVisibility({
    sectionTypeCode,
    subsectionTypeCode,
  });

  useDataLoad({ shouldLoad: isWidgetVisible, requests: getDataLoadRequests().performanceOldEntryMethods });

  const [currentVisibilityType, setCurrentVisibilityType] = useState<VisibilityType>(VisibilityType.DIAGRAM);
  const [filterYear, setFilterYear] = useState<number>();
  const [markType, setMarkType] = useState<MarkType>(MarkType.AVERAGE);

  const isOptionsVisible = hasData && !isLoading;

  const possibleYearsOptions: IYearOption[] = useMemo(
    () =>
      studentPerformance
        ?.map((performance, index) => ({ value: performance.learningYear || '', code: index + 1 }))
        .sort((x, y) => Number(y?.value?.substr(-4)) - Number(x?.value?.substr(-4))),
    [studentPerformance],
  );

  const possibleYearsOptionsWithText = possibleYearsOptions.map((option) => {
    return {
      value: option.value + ' уч. год',
      code: option.code,
    };
  });

  const showYearSelection =
    currentVisibilityType === VisibilityType.TILES || currentVisibilityType === VisibilityType.DIAGRAM;

  useEffect(() => {
    setFilterYear(possibleYearsOptions[0]?.code);
  }, [possibleYearsOptions]);

  const handleChangeMarkType = (type: MarkType) => {
    emitYMEvent({ type: 'averageTotalStudySwitching' });
    setMarkType(type);
  };

  const handleVisibilityTypeChange = (type: VisibilityType) => {
    const typeToNameMapping: Record<VisibilityType, 'radarChart' | 'gridTiles' | 'lineChart'> = {
      [VisibilityType.DIAGRAM]: 'radarChart',
      [VisibilityType.TILES]: 'gridTiles',
      [VisibilityType.CHART]: 'lineChart',
    };
    emitYMEvent({
      type: 'graphViewSwitching',
      payload: {
        typeChart: typeToNameMapping[type],
      },
    });

    setCurrentVisibilityType(type);
  };

  const visibilityTypeSwitcherElement = (
    <Switcher
      value={currentVisibilityType}
      onChange={handleVisibilityTypeChange}
      componentSize={isMobile ? 'small' : 'medium'}
      stretchy={isMobile}
    >
      <SwitcherButton value={VisibilityType.DIAGRAM} content={<IconPieChartFilled />} disabled={!hasData} />
      <SwitcherButton value={VisibilityType.TILES} content={<IconGridFilled />} disabled={!hasData} />
      <SwitcherButton value={VisibilityType.CHART} content={<IconBarChart />} disabled={!hasData} />
    </Switcher>
  );

  const handleChangeFilterYear = (year: number) => {
    emitYMEvent({ type: 'yearStudySwitching' });
    setFilterYear(year);
  };

  const filtersElement = (
    <PerformanceFilters
      markType={markType}
      onChangeMarkType={handleChangeMarkType}
      year={filterYear}
      onChangeYear={handleChangeFilterYear}
      yearOptions={possibleYearsOptionsWithText}
      withYearSelector={showYearSelection}
      isMobile={isMobile}
      isDisabled={!hasData}
    />
  );

  const generalNoDataPlug = (
    <NoDataPlug
      image={placeholderCharacter}
      title="Данных по предметным результатам пока нет"
      subtitle="Как только они появятся, информация о них отобразится здесь"
      loading={isLoading}
    />
  );

  if (!isWidgetVisible) return null;

  return (
    <Widget containerData={studyNavigation.performance} withBackground={isBrightTheme}>
      {overlayElement}
      <Widget.Header
        title={<WidgetContainerTitle title="Предметные результаты" right={isSettingsMode && toggleElement} />}
        options={
          isOptionsVisible && <WidgetContainerOptions left={visibilityTypeSwitcherElement} right={filtersElement} />
        }
        backgroundImage={widgetIllustrations.studyPerformance}
      />
      <Widget.Padding>
        {!hasData && generalNoDataPlug}

        {hasData && (
          <PerformanceContainer
            currentVisibilityType={currentVisibilityType}
            filterYear={filterYear}
            markType={markType}
            possibleYearsOptions={possibleYearsOptions}
          />
        )}
      </Widget.Padding>
    </Widget>
  );
};

export default PerformanceWidgetContainer;
