import { SxProps } from '@mui/material';
import { AccentColorType, getAccentColor, NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';
import { inheritSx, numberToPixelsString } from 'utils';

export const getFocusStateStyles = (accentColor: AccentColorType = 'indigo') => {
  const color = getAccentColor(accentColor, '24');

  return {
    outline: 'none',
    boxShadow: `0px 0px 0px 2px ${color}`,
  };
};

interface GetOutlineWithRadiusStylesParams {
  outlineWidth: number;
  outlineOffset?: number;
  radius: string;
  color: string;
  sx?: SxStyles;
}

/**
 * outline не работает с border-radius на сафари
 * данная функция добавляет псевдоэлемент со своим border поверх нужного элемента
 * @param outlineWidth border-width (10)
 * @param outlineOffset отступ от элемента
 * @param radius border-radius ('10px' / '50%')
 * @param color border-color ('#ffffff')
 */
export const getOutlineWithRadiusStyles = (params: GetOutlineWithRadiusStylesParams): SxProps => {
  const { outlineWidth, outlineOffset = 0, radius, color, sx = {} } = params;

  // искусственная вдавленность окружности внутрь элемента и увеличения бордера
  // нужно для скрытия дефекта при масштабировании экрана
  const offset = 0.6;
  const outlineOffsetPx = numberToPixelsString(outlineWidth - offset + outlineOffset);
  const borderPx = numberToPixelsString(outlineWidth + offset);

  return {
    position: 'relative',

    '&::before': {
      content: '""',
      position: 'absolute',
      top: `-${outlineOffsetPx}`,
      right: `-${outlineOffsetPx}`,
      bottom: `-${outlineOffsetPx}`,
      left: `-${outlineOffsetPx}`,
      border: `${borderPx} solid ${color}`,
      borderRadius: radius,

      ...inheritSx([sx]),
    },
  };
};

export const resetButtonStyles: SxProps = {
  padding: 0,
  backgroundColor: 'transparent',
  outline: 0,
  border: 0,
};

export const getButtonStyles = (accentColor: AccentColorType = 'indigo'): SxStyles => {
  return {
    ...resetButtonStyles,

    cursor: 'pointer',

    '&:focus-visible': {
      ...getFocusStateStyles(accentColor),
    },
  };
};

export const trimTextStyles: SxProps = {
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

/**
 * Внутренние отступы для компонента FunnelBlock
 */
export const funnelBlockContainerStyles: SxStyles = (theme) => {
  return {
    padding: '20px',
    backgroundColor: '#ffffff',

    [theme.breakpoints.down('commonSm')]: {
      padding: '8px 16px',
    },
  };
};

/**
 * Рамка для компонента FunnelBlock
 */
export const funnelBlockBorderStyles: SxStyles = {
  border: '1px solid',
  borderColor: NeutralColors.light_neutrals_300,
  borderRadius: '12px',
  boxShadow: '0px 1px 2px rgba(41, 41, 64, 0.07)',
};

export const drawerContentPadding = (disableMobileTopPadding: boolean): SxStyles => {
  return (theme) => {
    return {
      padding: '20px 24px',

      [theme.breakpoints.down('commonSm')]: {
        padding: disableMobileTopPadding ? '0 8px' : '8px',
        paddingBottom: '8px',
      },
    };
  };
};

/**
 * Предоставляет стили для создания контейнера с горизонтальным скроллом
 * container добавляет overflowX на элемент
 * element позволяет внутреннему элементу занимать всю возможную ширину внутри контейнера
 */
export const horizontalOverflowStyles: Record<'container' | 'element', SxStyles> = {
  container: {
    overflowX: 'auto',
  },
  element: {
    minWidth: 'max-content',
  },
};
