import { FC } from 'react';

import Advert from 'portfolio3/components/common/Advert';
import { SxStyles } from 'types';
import { openCultureMuseumsPdf } from 'utils';

import cultureAdvertImage from './cultureAdvertImage.png';

const WidgetMuseumAdvert: FC = () => {
  const contentSx: SxStyles = {
    backgroundImage: `url(${cultureAdvertImage})`,
    backgroundPosition: 'right -110px bottom -65px',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <Advert
      title="В музей — бесплатно с картой «Москвёнок»!"
      prompt="Каждый день ты можешь бесплатно посещать около 100 музеев, галерей и выставочных залов!"
      linkText="Выбрать музей"
      contentSx={contentSx}
      onLinkClick={() => openCultureMuseumsPdf()}
    />
  );
};

export default WidgetMuseumAdvert;
