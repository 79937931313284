import { inputAdornmentClasses, inputBaseClasses } from '@mui/material';
import { getAccentColor, NeutralColors } from 'portfolio3/styles';
import { getFocusStateStyles, getInputTokens, InputTokens } from 'portfolio3/ui-kit/utils';
import { inheritSx, numberToPixelsString } from 'utils';

import { UiKitInputBaseProps } from '../types';

export const InputBaseStyles = ({ renderMode, size }: UiKitInputBaseProps) => {
  const inputTokens = size ? getInputTokens(size) : InputTokens[renderMode];

  return {
    width: '100%',
    maxWidth: '100%',
    minHeight: numberToPixelsString(inputTokens.minHeight),

    padding: 0,
    paddingBlock: numberToPixelsString(inputTokens.paddingBlock),
    paddingInline: numberToPixelsString(inputTokens.paddingLeft),
    paddingRight: numberToPixelsString(inputTokens.paddingRight),

    fontSize: numberToPixelsString(inputTokens.fontSize),
    lineHeight: numberToPixelsString(inputTokens.lineHeight),

    border: '1px solid',
    borderRadius: '8px',
    borderColor: NeutralColors.light_neutrals_400,
    backgroundColor: '#ffffff',
    boxShadow: '0px 1px 2px 0px rgba(41, 41, 64, 0.07)',

    [`& .${inputAdornmentClasses.root} svg`]: {
      width: '20px',
      height: '20px',
      color: NeutralColors.light_neutrals_600,
    },
    [`& .${inputAdornmentClasses.positionStart}`]: {
      marginRight: '8px',
    },
    [`& .${inputAdornmentClasses.positionEnd}`]: {
      marginEnd: '8px',
    },

    [`& .${inputBaseClasses.input}`]: {
      padding: 0,
      outline: 'none',
      color: NeutralColors.light_neutrals_900,

      '&::placeholder': {
        color: NeutralColors.light_neutrals_600,
        opacity: 1,
      },
    },

    '&:hover': {
      borderColor: getAccentColor('indigo', '100'),
    },

    [`&.${inputBaseClasses.disabled}`]: {
      borderColor: NeutralColors.light_neutrals_300,
      backgroundColor: NeutralColors.light_neutrals_200,
      color: NeutralColors.light_neutrals_600,

      // добавлено для поддержки FakeInputBase
      pointerEvents: 'none',

      [`& .${inputBaseClasses.input}`]: {
        color: NeutralColors.light_neutrals_500,
      },

      [`& .${inputAdornmentClasses.root} svg`]: {
        color: NeutralColors.light_neutrals_500,
      },
    },

    [`&.${inputBaseClasses.focused}, &:focus-visible`]: {
      outline: 0,
      borderColor: getAccentColor('indigo', '100'),
      boxShadow: '0px 2.5px 4px 0px rgba(41, 41, 64, 0.24) inset',

      ...inheritSx([getFocusStateStyles()]),
    },

    [`&.${inputBaseClasses.error}`]: {
      borderColor: getAccentColor('red', '100'),

      [`&.${inputBaseClasses.focused}, &:focus-visible`]: {
        ...inheritSx([getFocusStateStyles('red')]),
      },
    },
  };
};

// export const FakeInputBoxStyles = (props: UiKitInputBaseProps) => {
//   const inputTokens = props.size ? getInputTokens(props.size) : InputTokens[props.renderMode];
//   const { minHeight } = inputTokens;

//   return {
//     ...InputBaseStyles(props),

//     display: 'flex',
//     alignItems: 'center',

//     minWidth: 'min-content',
//     maxHeight: numberToPixelsString(minHeight),
//     height: numberToPixelsString(minHeight),
//   };
// };

export const FakeInputBoxStyles = (props: UiKitInputBaseProps) => {
  const inputTokens = props.size ? getInputTokens(props.size) : InputTokens[props.renderMode];
  const { minHeight } = inputTokens;

  return {
    ...InputBaseStyles(props),

    minHeight: numberToPixelsString(minHeight),

    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  };
};

export const FakeInputSelectStyles = (props: UiKitInputBaseProps) => {
  const inputTokens = props.size ? getInputTokens(props.size) : InputTokens[props.renderMode];
  const { minHeight } = inputTokens;

  return {
    ...InputBaseStyles(props),

    minHeight: numberToPixelsString(minHeight),

    '& .fake-input-select__input': {
      display: 'flex',
      width: '100%',
      minWidth: 0,
      pointerEvents: 'none',
      userSelect: 'none',
    },

    '& .fake-input-select__placeholder': {
      color: NeutralColors.light_neutrals_600,
    },
  };
};
