import { IStudentCheckInHistory } from 'api/types';
import { IRootState } from 'reducers';
import { StudentCheckInHistoryState } from 'reducers/student/checkInHistory';
import { createSelector } from 'reselect';
import { userFunctionalitySelector } from 'selectors';
import {
  mapCivilClubsResponse,
  mapCivilContestsResponse,
  mapCivilRewardsResponse,
  mapCivilSectionsResponse,
  mapCreationClubsResponse,
  mapCreationContestsResponse,
  mapCreationOccupationsResponse,
  mapCreationRewardsResponse,
  mapCultureOnlineEventsResponse,
  mapCultureVisitingsResponse,
  mapProfessionAdditionalEducationResponse,
  mapProfessionEventsResponse,
  mapProfessionGiaWorldskillsResponse,
  mapProfessionJobsResponse,
  mapProfessionMetaskillsResponse,
  mapProfessionRewardsResponse,
  mapScienceContestsResponse,
  mapScienceOccupationsResponse,
  mapScienceProjectsResponse,
  mapScienceRewardsResponse,
  mapSportClubsResponse,
  mapSportCompetitionsResponse,
  mapSportExpeditionsResponse,
  mapSportOccupationsResponse,
  mapSportRewardsResponse,
  mapStudyOlympiadResponse,
  mapStudyRewardsResponse,
  mapStudySpoDocumentResponse,
  mapStudySpoPracticeResponse,
  mapStudySpoTrainingResponse,
} from 'selectors/entities';

// Profile
export const searchProfileInterestsSelector = createSelector(
  [(state: IRootState) => state.searchInterests],
  (interests) => {
    const content = interests.content.map((interestObject) => {
      const { interest, interestAction } = interestObject;

      return {
        name: interest.value,
        actions: interestAction.map((action) => action.value),
      };
    });

    return {
      loading: interests.loading,
      interests: content,
    };
  },
);

// Civil
export const searchCivilClubsSelector = createSelector(
  [(state: IRootState) => state.searchAffiliations, (state: IRootState) => state.sectionRef],
  (affiliations, sectionRef) => {
    return mapCivilClubsResponse(affiliations, sectionRef);
  },
);

export const searchCivilContestsSelector = createSelector(
  [
    (state: IRootState) => state.searchEvents,
    (state: IRootState) => state.studentRewards,
    (state: IRootState) => state.sectionRef,
  ],
  (events, rewards, sectionRef) => {
    return mapCivilContestsResponse(events, rewards, sectionRef);
  },
);

export const searchCivilSectionsSelector = createSelector(
  [(state: IRootState) => state.searchEmployments, (state: IRootState) => state.sectionRef],
  (employments, sectionRef) => {
    return mapCivilSectionsResponse(employments, sectionRef);
  },
);

export const searchCivilRewardsSelector = createSelector(
  [
    (state: IRootState) => state.searchRewards,
    (state: IRootState) => state.studentEvents,
    (state: IRootState) => state.sectionRef,
  ],
  (rewards, events, sectionRef) => {
    return mapCivilRewardsResponse(events, rewards, sectionRef);
  },
);

// Creation
export const searchCreationClubsSelector = createSelector(
  [(state: IRootState) => state.searchAffiliations],
  (affiliations) => {
    return mapCreationClubsResponse(affiliations);
  },
);

export const searchCreationContestsSelector = createSelector(
  [
    (state: IRootState) => state.searchEvents,
    (state: IRootState) => state.studentRewards,
    (state: IRootState) => state.sectionRef,
  ],
  (events, rewards, sectionRef) => {
    return mapCreationContestsResponse(events, rewards, sectionRef);
  },
);

export const searchCreationOccupationsSelector = createSelector(
  [(state: IRootState) => state.searchEmployments],
  (employments) => {
    return mapCreationOccupationsResponse(employments);
  },
);

export const searchCreationRewardsSelector = createSelector(
  [
    (state: IRootState) => state.searchRewards,
    (state: IRootState) => state.studentEvents,
    (state: IRootState) => state.sectionRef,
  ],
  (rewards, events, sectionRef) => {
    return mapCreationRewardsResponse(events, rewards, sectionRef);
  },
);

// Culture
export const searchCultureOnlineEventsSelector = createSelector(
  [(state: IRootState) => state.searchEvents, (state: IRootState) => state.sectionRef],
  (events, sectionRef) => {
    return mapCultureOnlineEventsResponse(events, sectionRef);
  },
);

export const searchCultureVisitingsSelector = createSelector(
  [
    (state: IRootState) => state.searchEvents,
    (state: IRootState) => state.searchCheckinHistory,
    (state: IRootState) => state.searchCultureVisitings,
    (state: IRootState) => state.sectionRef,
  ],
  (events, checkIns, cultureVisitings, sectionRef) => {
    const mappedCheckins: StudentCheckInHistoryState = {
      ...checkIns,
      content: checkIns.content.map((checkIn): IStudentCheckInHistory => {
        return {
          checkInHistory: checkIn,
          attachments: [],
        };
      }),
    };

    return mapCultureVisitingsResponse(events, mappedCheckins, cultureVisitings, sectionRef);
  },
);

// Profession
export const searchProfessionAdditionalEducationSelector = createSelector(
  [(state: IRootState) => state.searchProfessionalAdditionalEducation],
  (professionalAdditionalEducation) => {
    return mapProfessionAdditionalEducationResponse(professionalAdditionalEducation);
  },
);

export const searchProfessionEventsSelector = createSelector(
  [
    (state: IRootState) => state.searchEvents,
    (state: IRootState) => state.studentRewards,
    (state: IRootState) => state.sectionRef,
  ],
  (events, rewards, sectionRef) => {
    return mapProfessionEventsResponse(events, rewards, sectionRef);
  },
);

export const searchProfessionJobsSelector = createSelector([(state: IRootState) => state.searchJobs], (jobs) => {
  return mapProfessionJobsResponse(jobs);
});

export const searchProfessionRewardsSelector = createSelector(
  [
    (state: IRootState) => state.studentEvents,
    (state: IRootState) => state.searchRewards,
    (state: IRootState) => state.sectionRef,
  ],
  (events, rewards, sectionRef) => {
    return mapProfessionRewardsResponse(events, rewards, sectionRef);
  },
);

export const searchProfessionMetaskillsSelector = createSelector(
  [(state: IRootState) => state.searchMetaskills, (state: IRootState) => state.metaSkillRef],
  (metaskills, metaskilRef) => {
    return mapProfessionMetaskillsResponse(metaskills, metaskilRef);
  },
);

export const searchProfessionGiaWorldskillsSelector = createSelector(
  [(state: IRootState) => state.searchGiaWorldskills, (state: IRootState) => userFunctionalitySelector(state)],
  (giaWorldskills, userFunctionality) => {
    return mapProfessionGiaWorldskillsResponse(giaWorldskills, userFunctionality);
  },
);

// Science
export const searchScienceContestsSelector = createSelector(
  [
    (state: IRootState) => state.searchEvents,
    (state: IRootState) => state.studentRewards,
    (state: IRootState) => state.sectionRef,
  ],
  (events, rewards, sectionRef) => {
    return mapScienceContestsResponse(events, rewards, sectionRef);
  },
);

export const searchScienceOccupationsSelector = createSelector(
  [(state: IRootState) => state.searchEmployments],
  (employments) => {
    return mapScienceOccupationsResponse(employments);
  },
);

export const searchScienceProjectsSelector = createSelector(
  [(state: IRootState) => state.searchProjects, (state: IRootState) => state.studentRewards],
  (projects, rewards) => {
    return mapScienceProjectsResponse(projects, rewards);
  },
);

export const searchScienceRewardsSelector = createSelector(
  [
    (state: IRootState) => state.searchRewards,
    (state: IRootState) => state.studentEvents,
    (state: IRootState) => state.studentProjects,
    (state: IRootState) => state.sectionRef,
  ],
  (rewards, events, projects, sectionRef) => {
    return mapScienceRewardsResponse(events, projects, rewards, sectionRef);
  },
);

// Sport
export const searchSportClubsSelector = createSelector(
  [(state: IRootState) => state.searchAffiliations, (state: IRootState) => state.sportKinds],
  (affiliations, sportKinds) => {
    return mapSportClubsResponse(affiliations, sportKinds);
  },
);

export const searchSportCompetitionsSelector = createSelector(
  [
    (state: IRootState) => state.searchEvents,
    (state: IRootState) => state.studentSportRewards,
    (state: IRootState) => state.sportKinds,
    (state: IRootState) => state.sectionRef,
  ],
  (events, sportRewards, sportKinds, sectionRef) => {
    return mapSportCompetitionsResponse(events, sportRewards, sportKinds, sectionRef);
  },
);

export const searchSportExpeditionsSelector = createSelector(
  [
    (state: IRootState) => state.searchEvents,
    (state: IRootState) => state.studentSportRewards,
    (state: IRootState) => state.tourismKinds,
    (state: IRootState) => state.sectionRef,
  ],
  (events, sportRewards, tourismKinds, sectionRef) => {
    return mapSportExpeditionsResponse(events, sportRewards, tourismKinds, sectionRef);
  },
);

export const searchSportOccupationsSelector = createSelector(
  [(state: IRootState) => state.searchEmployments],
  (employments) => {
    return mapSportOccupationsResponse(employments);
  },
);

export const searchSportRewardsSelector = createSelector(
  [
    (state: IRootState) => state.searchSportRewards,
    (state: IRootState) => state.studentEvents,
    (state: IRootState) => state.sportKinds,
    (state: IRootState) => state.tourismKinds,
    (state: IRootState) => state.sectionRef,
  ],
  (sportRewards, events, sportKinds, tourismKinds, sectionRef) => {
    return mapSportRewardsResponse(events, sportRewards, sportKinds, tourismKinds, sectionRef);
  },
);

// Study
export const searchStudyOlympiadsSelector = createSelector(
  [
    (state: IRootState) => state.searchEvents,
    (state: IRootState) => state.studentRewards,
    (state: IRootState) => state.sectionRef,
  ],
  (events, rewards, sectionRef) => {
    return mapStudyOlympiadResponse(events, rewards, sectionRef);
  },
);

export const searchStudyRewardsSelector = createSelector(
  [
    (state: IRootState) => state.studentEvents,
    (state: IRootState) => state.searchRewards,
    (state: IRootState) => state.sectionRef,
  ],
  (events, rewards, sectionRef) => {
    return mapStudyRewardsResponse(events, rewards, sectionRef);
  },
);

export const searchStudySelfDiagnosticSubjectsSelector = createSelector(
  [(state: IRootState) => state.searchDiagnostics],
  (searchDiagnostics) => {
    return {
      loading: searchDiagnostics.loading,
      selfDiagnostics: searchDiagnostics.content.length > 0 ? searchDiagnostics.content[0].selfDiagnosticSubjects : [],
    };
  },
);

export const searchStudyDocumentsSelector = createSelector(
  [(state: IRootState) => state.searchDocuments, (state: IRootState) => state.documentRef],
  (documents, documentRef) => {
    return mapStudySpoDocumentResponse(documents, documentRef);
  },
);

export const searchStudySpoTrainingSelector = createSelector(
  (state: IRootState) => state.searchSpoEducation,
  (state: IRootState) => state.professionProgrammRef,
  (education, professionProgrammRef) => {
    return mapStudySpoTrainingResponse(education, professionProgrammRef);
  },
);

export const searchStudySpoPracticeSelector = createSelector(
  [(state: IRootState) => state.searchSpoEducation],
  (education) => {
    return mapStudySpoPracticeResponse(education);
  },
);
