import { ICommonResponse, IDictionaryItem } from 'api/types';
import { OperatorLocalRoleSections } from 'const';
import { IRootState } from 'reducers';
import { createSelector } from 'reselect';
import { userFunctionalitySelector, userLocalRolesSelector } from 'selectors/users';

import parentSectionsArchiveFalseSelector from './parentSectionsArchiveFalseSelector';

const availableEmployeeParentSectionsSelector = createSelector(
  [
    (state: IRootState) => parentSectionsArchiveFalseSelector(state),
    (state: IRootState) => userFunctionalitySelector(state),
    (state: IRootState) => userLocalRolesSelector(state),
  ],
  (parentSections, userFunctionality, localRoles): ICommonResponse<IDictionaryItem> => {
    if (!userFunctionality.isOperator) {
      return parentSections;
    }

    const operatorSections = localRoles.flatMap((localRoleTag) => OperatorLocalRoleSections[localRoleTag]);
    const uniqueOperatorSections = new Set(operatorSections);

    const filteredParentSections = parentSections.content.filter((section) => uniqueOperatorSections.has(section.code));

    return {
      ...parentSections,
      content: filteredParentSections,
    };
  },
);

export default availableEmployeeParentSectionsSelector;
