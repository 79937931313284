import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root: SxStyles = {
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
  padding: '20px',
};

export const topRow: SxStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  gap: '8px',
};

export const title: SxStyles = {
  fontSize: '16px',
  fontWeight: 800,
  lineHeight: '24px',
  color: NeutralColors.light_neutrals_0,
};

export const subtitle: SxStyles = {
  fontSize: '15px',
  fontWeight: 400,
  lineHeight: '22px',
  color: '#FFFFFFCC',
};

export const closeButton: SxStyles = {
  width: '24px',
  height: '24px',

  svg: {
    width: '100%',
    height: '100%',
    color: '#FFFFFFCC',
  },
};
