import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root: SxStyles = {
  padding: '20px',

  border: '1px solid',
  borderColor: NeutralColors.night_neutrals_700,
};

export const specialityContainer: SxStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
};
