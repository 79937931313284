import { styled, svgIconClasses } from '@mui/material';
import { getAccentColor, NeutralColors } from 'portfolio3/styles';

import StyledBaseButton from '../bases/BaseButton.styled';

const StyledAccentButton = styled(StyledBaseButton)(({ checked, theme }) => {
  const accentColor = theme.palette.accentColor;
  const backgroundColor = checked ? getAccentColor(accentColor, '100') : 'transparent';
  const boxShadow = checked ? '0px 1px 2px rgba(41, 41, 64, 0.07)' : 'none';

  const color = checked ? NeutralColors.light_neutrals_0 : NeutralColors.light_neutrals_900;

  return {
    border: 'none',
    backgroundColor,
    boxShadow,

    color,

    '&:hover': {
      backgroundColor: getAccentColor(accentColor, '8'),
    },

    '&:hover.switcher-button--checked': {
      backgroundColor: getAccentColor(accentColor, '80'),
    },

    '&.switcher-button--disabled': {
      backgroundColor: 'transparent',
      color: NeutralColors.light_neutrals_500,

      [`& .${svgIconClasses.root}`]: {
        color: NeutralColors.light_neutrals_500,
      },
    },

    '&.switcher-button--disabled.switcher-button--checked': {
      backgroundColor: NeutralColors.light_neutrals_400,
      color: NeutralColors.light_neutrals_0,

      [`& .${svgIconClasses.root}`]: {
        color: NeutralColors.light_neutrals_0,
      },
    },

    [`& .${svgIconClasses.root}`]: checked && {
      color: NeutralColors.light_neutrals_0,
    },
  };
});

export default StyledAccentButton;
