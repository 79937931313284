/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useMemo } from 'react';

import { IIndependentDiagnosticResponse } from '../../../../api/types';

import IndependentDiagnosticList from './IndependentDiagnosticList';
import HorizontalBarChart from '../../../common/horizontalBarChart';
import IndependentDiagnocticDoughnutStats from '../../DiagnocticDoughnutStats/independent';

import './index.scss';

interface IIndependentDiagnosticProps {
  independentDiagnostics: IIndependentDiagnosticResponse;
}

const IndependentDiagnosticComponent: React.FC<IIndependentDiagnosticProps> = ({independentDiagnostics}) => {
  const sortedDiagnosticSubjects = useMemo(() => independentDiagnostics.content?.averageResultPercent
      ?.map((diagnostic) => ({
        name: diagnostic.subject,
        total: diagnostic.resultPercent
      }))
      ?.sort((firstItem, secondItem) => secondItem.total - firstItem.total), [independentDiagnostics.content?.averageResultPercent]);

  return sortedDiagnosticSubjects && sortedDiagnosticSubjects?.length !== 0 ? (
    <>
      {independentDiagnostics.content?.totalDiagnostic && <IndependentDiagnocticDoughnutStats totalDiagnostic={independentDiagnostics.content?.totalDiagnostic} bestResult={independentDiagnostics.content?.bestResult} />}
      <div className="pdf-independent-diagnostics pdf-keep">
        <h3 className="pdf-independent-diagnostics__title">Результаты независимых диагностик</h3>
        <div className="pdf-independent-diagnostics__chart">
          <HorizontalBarChart barData={sortedDiagnosticSubjects} loading={independentDiagnostics.loading} isOnPdfPage={true} />
        </div>
      </div>
      {independentDiagnostics.content?.selfDiagnostic && (
        <>
          <div className="pdf-divider-diagnostics" />
          <IndependentDiagnosticList diagnostics={independentDiagnostics.content.selfDiagnostic} />
        </>
      )}
    </>
  ) : (
    <div></div>
  );
};

export default IndependentDiagnosticComponent;
