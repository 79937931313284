import { getAccentColor, NeutralColors } from 'portfolio3/styles';
import { getButtonStyles } from 'portfolio3/ui-kit/utils';
import { SxStyles } from 'types';

export const vuzCardHeader =
  (isOpen?: boolean, isSmallScreen?: boolean): SxStyles =>
  () => {
    return {
      borderBottom: isOpen ? `1px solid ${NeutralColors.light_neutrals_300}` : '',
      backgroundColor: isOpen ? NeutralColors.light_neutrals_100 : NeutralColors.light_neutrals_0,
      padding: isSmallScreen ? '12px' : '16px 20px',
    };
  };

export const headerTop: SxStyles = {
  display: 'flex',
  gap: '12px',
  justifyContent: 'space-between',
};

export const headerAddress: SxStyles = {
  color: NeutralColors.light_neutrals_600,
};

export const headerLink: SxStyles = {
  maxWidth: 'max-content',

  color: getAccentColor('indigo', '100'),
  textDecoration: 'none',
};

export const infoBlocks: SxStyles = {
  overflowX: 'auto',
};

export const name: SxStyles = {
  ...getButtonStyles(),

  alignSelf: 'flex-start',
  textAlign: 'left',

  '&:hover': {
    color: getAccentColor('indigo', '200'),
  },
};

export const actions: SxStyles = {
  display: 'flex',
  gap: '12px',
};
