/* eslint-disable max-len */
import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const NoEntry: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.75 10C18.75 14.8325 14.8325 18.75 10 18.75C5.16751 18.75 1.25 14.8325 1.25 10C1.25 5.16751 5.16751 1.25 10 1.25C14.8325 1.25 18.75 5.16751 18.75 10Z"
      fill="#AEAEBC"
    />
    <path
      d="M3.75 9.375C3.75 9.02982 4.02982 8.75 4.375 8.75H15.625C15.9702 8.75 16.25 9.02982 16.25 9.375V10.625C16.25 10.9702 15.9702 11.25 15.625 11.25H4.375C4.02982 11.25 3.75 10.9702 3.75 10.625V9.375Z"
      fill="white"
    />
  </SvgIcon>
);

export default NoEntry;
