import React from 'react';

import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import DoughnutDiagnosticChart from 'components/common/doughnutDiagnosticChart';
import {
  DiagnosticLevelColorSet1,
  DiagnosticLevelColorSet2,
  DiagnosticLevelsLabelsLarge,
  DiagnosticLevelsLabelsSmall,
} from 'const';
import { AccentColorModifier, getAccentColor } from 'portfolio3/styles';
import { commonTheme } from 'portfolio3/styles/theme';

import DiagramCard from '../DiagramCard';
import { legendMarkStyles, wrapperStyles } from './styles';

interface ICompletedDiagnosticsDiagramProps {
  totalLevelBelowBase: string;
  totalLevelBase: string;
  totalLevelOverHight: string;
  totalLevelHight: string;
}
const CompletedDiagnosticsDiagram: React.FC<ICompletedDiagnosticsDiagramProps> = ({
  totalLevelBelowBase,
  totalLevelBase,
  totalLevelOverHight,
  totalLevelHight,
}) => {
  const isSmallScreen = useMediaQuery(commonTheme.breakpoints.down('commonSm'));
  const chartSize = isSmallScreen ? 100 : 112;
  // модификатор для корректного отображения тултипов
  const chartWidthModifier = 30;
  const colorModifier: AccentColorModifier = isSmallScreen ? '100' : '64';
  const labelsDictionary = isSmallScreen ? DiagnosticLevelsLabelsSmall : DiagnosticLevelsLabelsLarge;
  const colorSet = isSmallScreen ? DiagnosticLevelColorSet1 : DiagnosticLevelColorSet2;

  const chartIncomingData = [totalLevelBelowBase, totalLevelBase, totalLevelOverHight, totalLevelHight].map((value) =>
    Number(value),
  );
  const chartData = { incomingData: chartIncomingData, incomingLabels: Object.values(labelsDictionary) };

  const legends = [
    {
      label: labelsDictionary.belowBase,
      accentColor: colorSet.belowBase,
      counter: totalLevelBelowBase,
    },
    { label: labelsDictionary.base, accentColor: colorSet.base, counter: totalLevelBase },
    {
      label: labelsDictionary.overHigh,
      accentColor: colorSet.overHigh,
      counter: totalLevelOverHight,
    },
    { label: labelsDictionary.high, accentColor: colorSet.high, counter: totalLevelHight },
  ];

  const chartColors = Object.values(colorSet).map((color) => getAccentColor(color, colorModifier));

  return (
    <DiagramCard title="Пройдено независимых диагностик">
      <Stack sx={wrapperStyles}>
        <Box
          width={chartSize + chartWidthModifier * 2}
          height={chartSize}
          sx={{ transform: `translateX(-${chartWidthModifier}px)`, zIndex: 1 }}
        >
          <DoughnutDiagnosticChart data={chartData} colors={chartColors} />
        </Box>
        <Stack
          gap={1}
          flexGrow={1}
          sx={{
            transform: `translateX(-${chartWidthModifier * 2}px)`,
            marginRight: `-${chartWidthModifier * 2}px`,
          }}
        >
          {legends.map((legend) => (
            <Stack key={legend.label} alignItems="center" direction="row" gap={1}>
              <Box sx={legendMarkStyles(getAccentColor(legend.accentColor, colorModifier))} />
              <Typography variant="Paragraph MD/Regular">{legend.label}</Typography>
              <Typography variant="Paragraph MD/Bold" ml="auto">
                {legend.counter}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </DiagramCard>
  );
};

export default CompletedDiagnosticsDiagram;
