import { Dispatch, FC, SetStateAction, useEffect, useMemo } from 'react';

import { Stack } from '@mui/material';
import { IDictionaryItem } from 'api/types';
import { SectionCodes } from 'const';
import { BaseInputLabel, FormControl } from 'portfolio3/ui-kit';
import { IconSelect, IIconSelectItem, Select } from 'portfolio3/ui-kit/selects';
import { getSectionIcon, numberToPixelsString } from 'utils';

import { defaultDataTypeFormData } from './consts';
import { IDataTypeSelectorFormData } from './types';

interface IDataTypeSelectorProps {
  formData: IDataTypeSelectorFormData;
  categoryOptions: IDictionaryItem[];
  kindOptions: IDictionaryItem[];
  typeOptions: IDictionaryItem[];
  onChangeFormData: Dispatch<SetStateAction<IDataTypeSelectorFormData>>;
  gap: number;
}

const DataTypeSelector: FC<IDataTypeSelectorProps> = ({
  formData,
  categoryOptions,
  kindOptions,
  typeOptions,
  onChangeFormData,
  gap,
}) => {
  useEffect(() => {
    if (!formData.section && categoryOptions.length > 0) {
      onChangeFormData({
        ...defaultDataTypeFormData,
        section: categoryOptions[0]?.code,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryOptions]);

  useEffect(() => {
    if (!formData.kind && kindOptions.length === 1) {
      onChangeFormData((prevstate) => ({
        ...prevstate,
        kind: kindOptions[0]?.code,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kindOptions]);

  useEffect(() => {
    if (!formData.type && typeOptions.length === 1) {
      onChangeFormData((prevstate) => ({
        ...prevstate,
        type: typeOptions[0]?.code,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeOptions]);

  const mappedCategoryOptions = useMemo<IIconSelectItem[]>(
    () =>
      categoryOptions.map((option) => {
        return { ...option, startIcon: getSectionIcon(option.code), selectedIconColor: '#ffffff' };
      }),
    [categoryOptions],
  );

  const sectionController = {
    handleChange: (section: string | undefined) => {
      onChangeFormData({
        ...defaultDataTypeFormData,
        section: Number(section) || undefined,
      });
    },
  };

  const kindController = {
    handleChange: (kind: string | undefined) => {
      onChangeFormData((prevstate) => ({
        section: prevstate.section,
        kind: Number(kind) || undefined,
        type: undefined,
      }));
    },
  };

  const typeController = {
    handleChange: (type: string | undefined) => {
      onChangeFormData({
        ...formData,
        type: Number(type) || undefined,
      });
    },
  };

  const isKindSelectDisabled = kindOptions.length === 1;
  const isTypeSelectDisabled = !formData.kind || typeOptions.length === 1;

  return (
    <Stack className="data-type-selector" gap={numberToPixelsString(gap)}>
      <FormControl
        renderMode="fixed"
        inputSize="medium"
        label={<BaseInputLabel>Раздел</BaseInputLabel>}
        control={
          <IconSelect
            inputRenderMode="fixed"
            inputSize="medium"
            controller={sectionController}
            value={formData.section}
            options={mappedCategoryOptions}
            placeholder=""
          />
        }
      />
      <FormControl
        renderMode="fixed"
        inputSize="medium"
        label={<BaseInputLabel>Вид данных</BaseInputLabel>}
        disabled={isKindSelectDisabled}
        control={
          <Select
            inputRenderMode="fixed"
            inputSize="medium"
            controller={kindController}
            value={formData.kind}
            options={kindOptions}
            placeholder="Выберите вид данных..."
          />
        }
      />
      {formData.kind && formData.kind !== SectionCodes.project && (
        <FormControl
          renderMode="fixed"
          inputSize="medium"
          label={<BaseInputLabel>Тип мероприятия</BaseInputLabel>}
          disabled={isTypeSelectDisabled}
          control={
            <Select
              inputRenderMode="fixed"
              inputSize="medium"
              controller={typeController}
              value={formData.type}
              options={typeOptions}
              placeholder="Выберите тип активности..."
            />
          }
        />
      )}
    </Stack>
  );
};

export default DataTypeSelector;
