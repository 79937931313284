/* eslint-disable max-len */
import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconCultureOfflineEventsCommon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.4 8.3H0V19h3.4V8.3ZM24 20.6H0V24h24v-3.4ZM10.298 8.3h-3.4V19h3.4V8.3ZM17.1 8.3h-3.4V19h3.4V8.3ZM24.002 8.3h-3.4V19h3.4V8.3ZM0 6.7 12 0l12 6.7H0Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconCultureOfflineEventsCommon;
