/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import clsx from 'clsx';

import { IInterestSection, ISectionTheme } from '../../types';
import { ColorKeys, Colors } from '../../../../../style/variables';

import './index.scss';

interface IInterestNavigationButtonProps {
  interestSection: IInterestSection,
  index: number,
  currentSectionTheme: ISectionTheme,
  lastVisitedSectionIndex: number,
  isActive: boolean,
  isMobile: boolean,
  onChangeCurrentSection: (interestSection: IInterestSection) => void
}

const InterestNavigationButton: React.FC<IInterestNavigationButtonProps> = ({
  interestSection,
  index,
  currentSectionTheme,
  lastVisitedSectionIndex,
  isActive,
  isMobile,
  onChangeCurrentSection
}) => {
  let backgroundColor = index < lastVisitedSectionIndex ? currentSectionTheme.mainColor : currentSectionTheme.secondaryColor;
  let borderColor = index <= lastVisitedSectionIndex ? currentSectionTheme.mainColor : currentSectionTheme.secondaryColor;

  if (isMobile) {
    backgroundColor = index < lastVisitedSectionIndex ? Colors[ColorKeys.BLUE_PRIMROSE]  : '#A5D8FF';
    borderColor = index <= lastVisitedSectionIndex ? Colors[ColorKeys.BLUE_PRIMROSE] : '#A5D8FF';
  }

  const classes = {
    'interests-navigation-button--active': isActive,
    'interests-navigation-button--visited': index <= lastVisitedSectionIndex
  };

  const handleChangeSection = (section: IInterestSection) => {
    if (index > lastVisitedSectionIndex) return;
    return () => onChangeCurrentSection(section);
  };

  return (
    <button
      className={clsx('interests-navigation-button', {...classes})}
      style={{ backgroundColor, borderColor, color: backgroundColor }}
      type="button"
      aria-label={`Раздел ${interestSection.name}`}
      onClick={handleChangeSection(interestSection)}
    />
  );
};

export default InterestNavigationButton;
