import { FC } from 'react';

import { InputController } from '../../types';
import InputBase from '../bases/InputBase';
import { UiKitInputBaseProps } from '../types';
import { getBaseInputProps } from '../utils';

interface IInputProps extends UiKitInputBaseProps {
  controller: InputController;
}

const Input: FC<IInputProps> = ({ controller, value, ...props }) => {
  return <InputBase {...getBaseInputProps(controller)} {...props} value={value ?? ''} />;
};

export default Input;
