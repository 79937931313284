import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { MobileDrawerHeader } from 'portfolio3/components/drawers';
import { NeutralColors } from 'portfolio3/styles';
import { Button } from 'portfolio3/ui-kit/button';
import { Drawer } from 'portfolio3/ui-kit/Drawer';

import * as styles from './styles';

interface IUrlDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  url: string;
  onCopy: () => void;
}

const UrlDrawer: FC<IUrlDrawerProps> = ({ isOpen, onClose, url, onCopy }) => {
  const headerElement = (
    <MobileDrawerHeader
      title="Ссылка сгенерирована. Хотите скопировать ее?"
      overrideTitleColor={NeutralColors.light_neutrals_900}
      overrideBackgroundColor={NeutralColors.light_neutrals_0}
      multilineTitle
    />
  );

  return (
    <Drawer
      open={isOpen}
      isMobile
      anchor="bottom"
      swipeable
      swipeableProps={{
        onClose,
      }}
      header={headerElement}
      onClose={onClose}
    >
      <Box sx={styles.root}>
        <Typography variant="Paragraph LG/Semi Bold" sx={styles.description}>
          {url}
        </Typography>
        <Box sx={styles.buttons}>
          <Button variant="secondary" onClick={onClose}>
            Закрыть
          </Button>
          <Button variant="primary" onClick={onCopy}>
            Скопировать
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default UrlDrawer;
