/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import './index.scss';

interface ILineChartAxisProps {
  children: JSX.Element;
  xAxisLabel?: string;
}

const LineChartAxis: React.FC<ILineChartAxisProps> = ({children, xAxisLabel}) => {
  return (
    <>
      <div className="line-chart">
        <div className="line-wrapper">
          {children}
        </div>
      </div>
      {xAxisLabel && <p className="line-chart__axis-x">{xAxisLabel}</p>}
    </>
  )
}

export default LineChartAxis;
