import { cardIllustrations } from 'images';

import {
  IDictionaryItem,
  IStudentEvent,
  IStudentProject,
  IStudentReward,
  IStudentSportReward,
  SectionRefItem,
} from '../../api/types';
import { EntityType, SectionCodes, SourceCode } from '../../const';
import { IEntity, IEntityFeature, IReward } from '../../types';
import {
  flagsMap,
  formatDate,
  getCivilEntityBackgroundImage,
  getCreationEntityBackgroundImage,
  getDateRange,
  getEntityFeatures,
  getEntityGeneralInfo,
  getEntityHeaderText,
  getProfessionEventsEntityBackgroundImage,
  getRewardActivityIcon,
  getScienceEntityBackgroundImage,
  getSportEntityBackgroundImage,
  getSportRewardCardIcon,
  getStudyEntityBackgroundImage,
  getYearFromDate,
  Simplify,
} from '../../utils';

interface IWithRewardEventType {
  eventType: string;
}

// пропсы сущностей, которые могут отличаться, настраиваются индивидуально
type UncommonEventProperties = Simplify<
  Pick<IEntity, 'formData' | 'types' | 'features' | 'backgroundImage' | 'entityProps'>
>;
type UncommonEventRewardProperties = Simplify<Pick<IReward, 'result' | 'image'> & IWithRewardEventType>;

const PROJECT_EVENT_TYPE = 'проект';

const isApproved = (sourceCode: number) => sourceCode !== SourceCode.student && sourceCode !== SourceCode.parent;

const isStudentEntityEvent = (entity: IStudentEvent | IStudentProject | undefined): entity is IStudentEvent => {
  return (entity as IStudentEvent).levelEvent !== undefined;
};

const getMappedEvent = (
  event: IStudentEvent | IStudentProject,
  eventReward: IStudentReward | IStudentSportReward | undefined,
  // не все объекты сущностей содержат код категории
  categoryCode: number,
  eventPictureFallback: string | null,
  rewardPictureFallback: string | null,
  uncommonEventProps: UncommonEventProperties,
  uncommonRewardProps?: UncommonEventRewardProperties,
): IEntity => {
  const reward =
    eventReward && uncommonRewardProps
      ? getMappedReward(event, eventReward, eventPictureFallback, rewardPictureFallback, uncommonRewardProps)
      : undefined;

  return {
    id: event.id,
    recordId: event.recordId,
    name: event.name,
    stage: isStudentEntityEvent(event) ? event.stageEvent : undefined,
    date: getDateRange(event.startDate, event.endDate),
    year: getYearFromDate(event.endDate || event.startDate),
    reward,
    cardData: {
      name: event.name,
      generalInfo: getEntityGeneralInfo(event),
      files: event.fileReferences,
      linkedObjects: event.linkedObjects,
      reward,
      formData: event,
      illustrationFallback: eventPictureFallback,
    },
    ...uncommonEventProps,
  };
};

const getMappedReward = (
  event: IStudentEvent | IStudentProject,
  eventReward: IStudentReward | IStudentSportReward,
  eventPictureFallback: string | null,
  rewardPictureFallback: string | null,
  uncommonRewardProps: UncommonEventRewardProperties,
): IReward => {
  return {
    rewardDate: eventReward.date ? formatDate(eventReward.date) : undefined,
    formData: eventReward,
    cardData: {
      name: eventReward.name,
      files: eventReward.fileReferences,
      linkedObjects: eventReward.linkedObjects,
      generalInfo: getEntityGeneralInfo(eventReward),
      formData: eventReward,
      illustrationFallback: rewardPictureFallback,
      event: event && {
        cardData: {
          name: event?.name,
          files: event?.fileReferences,
          linkedObjects: event?.linkedObjects,
          generalInfo: getEntityGeneralInfo(event),
          formData: event,
          illustrationFallback: eventPictureFallback,
        },
        type: uncommonRewardProps.eventType,
      },
    },
    ...uncommonRewardProps,
  };
};

export const mapStudyOlympiadToEntity = (
  sectionRef: SectionRefItem[],
  event: IStudentEvent,
  eventReward: IStudentReward | undefined,
): IEntity => {
  const { subjects, levelEvent, format: eventFormat, typeCode } = event;

  const subjectList = subjects ? subjects.map((subject) => subject.value) : [];
  const eventTypes = subjects ? [...subjectList] : [];
  const eventFeatures = getEntityFeatures(sectionRef, typeCode, levelEvent, eventFormat);

  const rewardProps: UncommonEventRewardProperties | undefined = eventReward
    ? {
        result: eventReward.name,
        image: getRewardActivityIcon(eventReward.rewardType.value),
        eventType: getEntityHeaderText(event.typeCode || event.type?.code),
      }
    : undefined;

  return getMappedEvent(
    event,
    eventReward,
    SectionCodes.study,
    cardIllustrations.studyOlympiad,
    cardIllustrations.studyOlympiad,
    {
      formData: event,
      types: eventTypes,
      features: eventFeatures,
      backgroundImage: getStudyEntityBackgroundImage(subjectList),
      entityProps: {
        type: EntityType.OLYMPIAD,
        flags: flagsMap({ approved: isApproved(event.source.code) }),
      },
    },
    rewardProps,
  );
};

export const mapProfessionEventToEntity = (
  sectionRef: SectionRefItem[],
  event: IStudentEvent,
  eventReward: IStudentReward | undefined,
): IEntity => {
  const { levelEvent, format: eventFormat, typeCode, profession } = event;

  const eventTypes: string[] | undefined = profession ? [profession] : undefined;
  const eventFeatures = getEntityFeatures(sectionRef, typeCode, levelEvent, eventFormat);

  const rewardProps: UncommonEventRewardProperties | undefined = eventReward
    ? {
        result: eventReward.rewardType.value,
        image: getRewardActivityIcon(eventReward.rewardType.value),
        eventType: getEntityHeaderText(event.typeCode || event.type?.code),
      }
    : undefined;

  return getMappedEvent(
    event,
    eventReward,
    SectionCodes.profession,
    cardIllustrations.professionEvent,
    cardIllustrations.professionReward,
    {
      formData: event,
      types: eventTypes,
      features: eventFeatures,
      backgroundImage: getProfessionEventsEntityBackgroundImage(),
      entityProps: {
        type: EntityType.EVENT,
        flags: flagsMap({ approved: isApproved(event.source.code) }),
      },
    },
    rewardProps,
  );
};

export const mapCivilContestToEntity = (
  sectionRef: SectionRefItem[],
  event: IStudentEvent,
  eventReward: IStudentReward | undefined,
): IEntity => {
  const { levelEvent, format: eventFormat, subcategory, typeCode } = event;

  const eventTypes = subcategory ? [subcategory?.value] : undefined;
  const eventFeatures = getEntityFeatures(sectionRef, typeCode, levelEvent, eventFormat);

  const rewardProps: UncommonEventRewardProperties | undefined = eventReward
    ? {
        result: eventReward.rewardType.value,
        image: getRewardActivityIcon(eventReward.rewardType.value),
        eventType: getEntityHeaderText(event.typeCode || event.type?.code),
      }
    : undefined;

  return getMappedEvent(
    event,
    eventReward,
    SectionCodes.civil,
    cardIllustrations.civilEvent,
    cardIllustrations.civilReward,
    {
      formData: event,
      types: eventTypes,
      features: eventFeatures,
      backgroundImage: getCivilEntityBackgroundImage(subcategory?.code),
      entityProps: {
        type: EntityType.EVENT,
        flags: flagsMap({ approved: isApproved(event.source.code) }),
      },
    },
    rewardProps,
  );
};

export const mapCreationContestToEntity = (
  sectionRef: SectionRefItem[],
  event: IStudentEvent,
  eventReward: IStudentReward | undefined,
): IEntity => {
  const { creationKind, levelEvent, format: eventFormat, typeCode } = event;

  const eventTypes = creationKind ? [creationKind.value] : [];
  const eventFeatures = getEntityFeatures(sectionRef, typeCode, levelEvent, eventFormat);

  const rewardProps: UncommonEventRewardProperties | undefined = eventReward
    ? {
        result: eventReward.name,
        image: getRewardActivityIcon(eventReward.rewardType.value),
        eventType: getEntityHeaderText(event.typeCode || event.type?.code),
      }
    : undefined;

  return getMappedEvent(
    event,
    eventReward,
    SectionCodes.creation,
    cardIllustrations.creationContest,
    cardIllustrations.creationReward,
    {
      formData: event,
      types: eventTypes,
      features: eventFeatures,
      backgroundImage: getCreationEntityBackgroundImage(eventTypes[0]),
      entityProps: {
        type: EntityType.EVENT,
        flags: flagsMap({ approved: isApproved(event.source.code) }),
      },
    },
    rewardProps,
  );
};

export const mapSportExpeditionToEntity = (
  sectionRef: SectionRefItem[],
  event: IStudentEvent,
  eventReward: IStudentSportReward | undefined,
  tourismKinds: IDictionaryItem[] | undefined,
): IEntity => {
  const { typeCode, levelEvent, format: eventFormat, sportKind } = event;

  const subspeciesArray = event.subspecies?.split(', ');
  const eventTypes = sportKind ? [sportKind.value] : undefined;
  const eventFeatures = getEntityFeatures(sectionRef, typeCode, levelEvent, eventFormat);

  const rewardProps: UncommonEventRewardProperties | undefined = eventReward
    ? {
        result: eventReward.name,
        // ! пока для спорта *small картинки лежат в разрешении 50x50, лучше использовать картинки для карточек наград
        image: getSportRewardCardIcon(eventReward.sportRewardCode),
        eventType: getEntityHeaderText(event.typeCode || event.type?.code),
      }
    : undefined;

  return getMappedEvent(
    event,
    eventReward,
    SectionCodes.sport,
    cardIllustrations.sportExpedition,
    cardIllustrations.sportReward,
    {
      formData: {
        ...event,
        subspeciesString: tourismKinds
          ?.filter((sportKind) => subspeciesArray?.includes(String(sportKind.code)))
          .map((sportKind) => sportKind.value.trim())
          .join(', '),
      },
      types: eventTypes,
      features: eventFeatures,
      backgroundImage: getSportEntityBackgroundImage(sportKind?.value),
      entityProps: {
        type: EntityType.EVENT,
        flags: flagsMap({ approved: isApproved(event.source.code) }),
      },
    },
    rewardProps,
  );
};

export const mapSportCompetitionToEntity = (
  sectionRef: SectionRefItem[],
  event: IStudentEvent,
  eventReward: IStudentSportReward | undefined,
  sportKinds: IDictionaryItem[] | undefined,
): IEntity => {
  const { levelEvent, format: eventFormat, sportKind, subspecies, typeCode } = event;

  const subspeciesArray = subspecies?.split(', ');
  const eventTypes = sportKind ? [sportKind.value] : undefined;
  const eventFeatures = getEntityFeatures(sectionRef, typeCode, levelEvent, eventFormat);

  const rewardProps: UncommonEventRewardProperties | undefined = eventReward
    ? {
        result: eventReward.name,
        image: getSportRewardCardIcon(eventReward.sportRewardCode),
        eventType: getEntityHeaderText(event.typeCode || event.type?.code),
      }
    : undefined;

  return getMappedEvent(
    event,
    eventReward,
    SectionCodes.sport,
    cardIllustrations.sportCompetition,
    cardIllustrations.sportReward,
    {
      formData: {
        ...event,
        subspeciesString: sportKinds
          ?.filter((sportKind) => subspeciesArray?.includes(String(sportKind.code)))
          .map((sportKind) => sportKind.value.trim())
          .join(', '),
      },
      types: eventTypes,
      features: eventFeatures,
      backgroundImage: getSportEntityBackgroundImage(sportKind?.value),
      entityProps: {
        type: EntityType.EVENT,
        flags: flagsMap({ approved: isApproved(event.source.code) }),
      },
    },
    rewardProps,
  );
};

export const mapScienceContestToEntity = (
  sectionRef: SectionRefItem[],
  event: IStudentEvent,
  eventReward: IStudentReward | undefined,
): IEntity => {
  const { subcategory, disciplines, levelEvent, format: eventFormat, typeCode } = event;

  const eventTypes = disciplines?.map((discipline) => discipline.value) ?? [];
  const eventFeatures = getEntityFeatures(sectionRef, typeCode, levelEvent, eventFormat);

  const rewardProps: UncommonEventRewardProperties | undefined = eventReward
    ? {
        result: eventReward.name,
        image: getRewardActivityIcon(eventReward.rewardType.value),
        eventType: getEntityHeaderText(event.typeCode || event.type?.code),
      }
    : undefined;

  return getMappedEvent(
    event,
    eventReward,
    SectionCodes.science,
    cardIllustrations.scienceContest,
    cardIllustrations.scienceReward,
    {
      formData: event,
      types: eventTypes,
      features: eventFeatures,
      backgroundImage: getScienceEntityBackgroundImage(subcategory?.code),
      entityProps: {
        type: EntityType.EVENT,
        flags: flagsMap({ approved: isApproved(event.source.code) }),
      },
    },
    rewardProps,
  );
};

export const mapScienceProjectToEntity = (
  project: IStudentProject,
  projectReward: IStudentReward | undefined,
): IEntity => {
  const { subcategory, disciplines, levelProject, projectFormat } = project;

  const projectTypes = disciplines
    ? [subcategory?.value, ...disciplines.map((discipline) => discipline?.value)]
    : [subcategory?.value];

  const projectFeatures: IEntityFeature[] = [{ text: PROJECT_EVENT_TYPE, isColored: true }];
  if (levelProject) projectFeatures.push({ text: levelProject.value });
  if (projectFormat) projectFeatures.push({ text: projectFormat.value });

  const rewardProps: UncommonEventRewardProperties | undefined = projectReward
    ? {
        result: projectReward.name,
        image: getRewardActivityIcon(projectReward.rewardType.value),
        eventType: getEntityHeaderText(project.dataKind),
      }
    : undefined;

  return getMappedEvent(
    project,
    projectReward,
    SectionCodes.science,
    cardIllustrations.scienceProject,
    cardIllustrations.scienceReward,
    {
      formData: project,
      types: projectTypes,
      features: projectFeatures,
      backgroundImage: getScienceEntityBackgroundImage(subcategory?.code),
      entityProps: {
        type: EntityType.PROJECT,
        flags: flagsMap({ approved: isApproved(project.source.code) }),
      },
    },
    rewardProps,
  );
};
