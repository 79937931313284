import React, { useContext } from 'react';

import { ThemeProvider, Typography } from '@mui/material';
import { PortfolioSettingsContext } from 'context';
import TrimTextMultiLine from 'portfolio3/components/common/TrimTextMultiLine';
import { generateAccentTheme } from 'portfolio3/styles/theme';
import { AccentBadge } from 'portfolio3/ui-kit/badges';

import { ColorKeys, Colors } from '../../../../../../style/variables';
import { IRecommendationEntity } from '../../../../../../types';
import RecommendationCardTitleLink from '../common/cardTitleLink';
import RecommendationLinkButton from '../common/linkButton';
import RecommendationContainer from '../recommendationContainer';

import './index.scss';

interface IRecommendationDataEntity {
  recommendation: IRecommendationEntity;
}

const SectionRecommendation: React.FC<IRecommendationDataEntity> = ({ recommendation }) => {
  const { isSettingsMode } = useContext(PortfolioSettingsContext);

  const { badge, title, place, link, isGTOCard, buttonTitle, backgroundIllustration, accentColor } = recommendation;

  return (
    <ThemeProvider theme={generateAccentTheme(accentColor)}>
      <RecommendationContainer className="section-recommendation">
        <RecommendationContainer.Illustration illustration={backgroundIllustration} width={220} height={170} />

        <RecommendationContainer.Content>
          <>
            {badge && <AccentBadge sx={{ borderRadius: '12px' }}>{badge}</AccentBadge>}
            <Typography component={TrimTextMultiLine} line={2} variant="Paragraph LG/Semi Bold" marginTop="12px">
              {title}
            </Typography>
            {isGTOCard && (
              <RecommendationCardTitleLink
                color={Colors[ColorKeys.BLUE_PRIMROSE]}
                className="section-recommendation__link"
                href={link}
                disabled={isSettingsMode}
              >
                {link}
              </RecommendationCardTitleLink>
            )}
            <p className="section-recommendation__place">{place}</p>
          </>
        </RecommendationContainer.Content>

        <RecommendationContainer.Footer>
          <RecommendationLinkButton link={link} disabled={isSettingsMode}>
            {buttonTitle}
          </RecommendationLinkButton>
        </RecommendationContainer.Footer>
      </RecommendationContainer>
    </ThemeProvider>
  );
};

export default SectionRecommendation;
