/* eslint-disable prettier/prettier */
/* eslint-disable */

import connectReducers from '../utils/connectReducers';

import {
  GET_FEEDBACK_LINK
} from '../actions';

import {
  FAILURE,
  REQUEST,
  SUCCESS
} from '../actions/utils';

import { IFeedbackLink } from '../api/types';

import genericReducers from './genericReducer';

import { IReducers } from '.';

export type FeedbackLinkState = IFeedbackLink;

const initialState = {} as IFeedbackLink;

const feedbackLink: IReducers<FeedbackLinkState> = {
  [GET_FEEDBACK_LINK[REQUEST]]: genericReducers().get.request,
  [GET_FEEDBACK_LINK[SUCCESS]]: genericReducers().get.success,
  [GET_FEEDBACK_LINK[FAILURE]]: genericReducers().get.failure
};

export default connectReducers(initialState, feedbackLink);
