/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';
import genericReducers from '../genericReducer';
import { IReducers } from '../index';

import { GET_CREATION_KINDS } from '../../actions/employees';
import { REQUEST, FAILURE, SUCCESS } from '../../actions/utils';

import { ICommonResponse, ICreationKind } from '../../api/types';

export type CreationKindState = ICommonResponse<ICreationKind>;

const initialState = {} as ICommonResponse<ICreationKind>;

const creationKinds: IReducers<CreationKindState> = {
  [GET_CREATION_KINDS[REQUEST]]: genericReducers().get.request,
  [GET_CREATION_KINDS[SUCCESS]]: genericReducers().get.success,
  [GET_CREATION_KINDS[FAILURE]]: genericReducers().get.failure
};

export default connectReducers(initialState, creationKinds);
