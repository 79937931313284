/* eslint-disable prettier/prettier */
/* eslint-disable */

import genericReducers from '../genericReducer';
import { connectReducers } from '../../utils';
import { IReducers } from '../index';

import { GET_INTEREST_HEAD_KINDS } from '../../actions';
import { REQUEST, FAILURE, SUCCESS } from '../../actions/utils';

import { ICommonResponse, IInterestHeadKind } from '../../api/types';

export type InterestHeadKindsState = ICommonResponse<IInterestHeadKind>;

const initialState = {
  content: [],
  loading: false
} as InterestHeadKindsState;

const interestHeadKinds: IReducers<InterestHeadKindsState> = {
  [GET_INTEREST_HEAD_KINDS[REQUEST]]: genericReducers().get.request,
  [GET_INTEREST_HEAD_KINDS[SUCCESS]]: genericReducers().get.success,
  [GET_INTEREST_HEAD_KINDS[FAILURE]]: genericReducers().get.failure
};

export default connectReducers(initialState, interestHeadKinds);
