import { FC } from 'react';

import { Typography } from '@mui/material';
import InfoModal from 'portfolio3/ui-kit/modals/InfoModal';

interface IUrlPopUpProps {
  isOpen: boolean;
  onClose: () => void;
  url: string;
  onCopy: () => void;
}

const UrlPopUp: FC<IUrlPopUpProps> = ({ isOpen, onClose, url, onCopy }) => {
  const descriptionElement = (
    <>
      Хотите скопировать ссылку{' '}
      <Typography component="span" variant="Paragraph LG/Semi Bold">
        {url}
      </Typography>
      ?
    </>
  );

  return (
    <InfoModal
      open={isOpen}
      onClose={onClose}
      header="Ссылка сгенерирована"
      text={descriptionElement}
      variant="warning"
      onCancel={onClose}
      actionButtonText="Скопировать"
      onAction={onCopy}
    />
  );
};

export default UrlPopUp;
