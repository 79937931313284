import { FC, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { Box, useMediaQuery } from '@mui/material';
import { reportErrorActions, reportErrorDrawerActions } from 'actions';
import { IChildInfo, IPostErrorMessageRequest, IPutErrorMessageRequest } from 'api/types';
import {
  ErrorReportActionButtons,
  FormStep,
  IReportErrorFormData,
  PortfolioReportErrorForm,
} from 'components/common/PortfolioReportErrorForm';
import { EntityType, ReportErrorTypeCodes } from 'const';
import { useBrightTheme, useUserFunctionality } from 'hooks';
import { IconAlertCircleOutline, IconCheckmarkCircleOutline } from 'icons';
import { cardIllustrations } from 'images';
import { FormEntityInfo } from 'portfolio3/components/dataEntry';
import { DrawerHeader, MobileDrawerHeader } from 'portfolio3/components/drawers';
import { getYMEventPayload } from 'portfolio3/features/yandexMetrika';
import { greenColors, redColors } from 'portfolio3/styles';
import { commonTheme } from 'portfolio3/styles/theme';
import { Drawer, IDrawerBase } from 'portfolio3/ui-kit/Drawer';
import { drawerContentPadding } from 'portfolio3/ui-kit/utils';
import { IRootState } from 'reducers';
import { ReportErrorDrawerState } from 'reducers/drawers/reportErrorDrawer';
import { EntityErrorMessagesState } from 'reducers/student/entityErrorMessages';
import { ReportErrorState } from 'reducers/student/reportError';
import { mergeSx } from 'utils';

import ReportErrorDrawerSubmitDialog from './SubmitDialog';

interface IReportErrorDrawerProps {
  reportErrorDrawer: ReportErrorDrawerState;
  reportErrorStatus: ReportErrorState;
  currentStudent: IChildInfo;
  entityErrorMessages: EntityErrorMessagesState;
  reportError: typeof reportErrorActions.request;
}

const ReportErrorDrawer: FC<IReportErrorDrawerProps> = ({
  reportErrorDrawer,
  reportErrorStatus,
  currentStudent,
  entityErrorMessages,
  reportError,
}) => {
  const dispatch = useDispatch();

  const { isStudent } = useUserFunctionality();
  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));
  const [formStep, setFormStep] = useState<FormStep>(FormStep.SELECT_ERROR_TYPE);
  const [initialFormData, setInitialFormData] = useState<IReportErrorFormData | undefined>();

  const { isOpen, formData, isSubmitModalOpen } = reportErrorDrawer;

  const isBrightTheme = useBrightTheme();

  const isCardDetailsError = formData?.errorTypeCode === ReportErrorTypeCodes.cardDataError;

  const handleChangeFormData = (formData: IReportErrorFormData | undefined) => {
    dispatch(reportErrorDrawerActions.setFormData(formData));
  };

  const handleClose = () => {
    dispatch(reportErrorDrawerActions.setOpen(false));
  };

  const handleCloseSubmitDialog = () => {
    dispatch(reportErrorDrawerActions.setSubmitModalOpen(false));
  };

  useEffect(() => {
    if (isOpen) {
      setFormStep(FormStep.SELECT_ERROR_TYPE);
    }
  }, [isOpen]);

  useEffect(() => {
    if (entityErrorMessages.loading || entityErrorMessages.errors.length === 0 || !formData) {
      return;
    }

    let newFormData: IReportErrorFormData = formData;

    const cardDataErrors = entityErrorMessages.errors.filter(
      (error) => error.errorTypeCode === ReportErrorTypeCodes.cardDataError,
    );

    cardDataErrors.forEach((error) => {
      const { errorGeneralMessage, errorFileMetadataMessage, errorChildEntityMessage } = error;
      newFormData = {
        ...formData,
        errorGeneralMessageChecked: Boolean(errorGeneralMessage),
        errorFileMetadataMessageChecked: Boolean(errorFileMetadataMessage),
        errorChildEntityMessageChecked: Boolean(errorChildEntityMessage),
        errorGeneralMessage,
        errorFileMetadataMessage,
        errorChildEntityMessage,
      };
    });
    setInitialFormData(newFormData);
    handleChangeFormData(newFormData);

    // пропускаем formData
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityErrorMessages.errors, entityErrorMessages.loading]);

  /* eslint-disable prettier/prettier */
  /* eslint-disable max-len */
  const dialogIconElement = reportErrorStatus.success ? (
    <IconCheckmarkCircleOutline fill={greenColors[100]} />
  ) : (
    <IconAlertCircleOutline fill={redColors[100]} />
  );
  const dialogTitle = reportErrorStatus.success
    ? 'Сообщение об ошибке отправлено!'
    : 'Произошла ошибка при отправке сообщения';
  const dialogDescription = reportErrorStatus.success
      ? `Обработка ${isStudent ? 'твоего' : 'вашего'} сообщения и внесение изменений в данные карточки займёт до 7 рабочих дней.
        В случае, если изменения не будут внесены, ${isStudent ? 'обратись' : 'обратитесь'} в службу технической поддержки.`
      : reportErrorStatus.errorMessage
  /* eslint-enable prettier/prettier */
  /* eslint-enable max-len */

  const handleExitBack = () => {
    if (formStep === FormStep.SELECT_ERROR_TYPE) {
      handleClose();
    } else {
      setFormStep(FormStep.SELECT_ERROR_TYPE);
    }
  };

  const handleSubmit = () => {
    if (!formData) return;

    const {
      errorTypeCode,
      entityId,
      recordId,
      entityType,
      errorGeneralMessage,
      errorGeneralMessageChecked,
      errorFileMetadataMessage,
      errorFileMetadataMessageChecked,
      errorChildEntityMessage,
      errorChildEntityMessageChecked,
    } = formData;

    if (!currentStudent.meshId || errorTypeCode === undefined || entityErrorMessages.loading) {
      return;
    }

    const prevErrorId = entityErrorMessages.errors
      .filter((error) => error.errorTypeCode === errorTypeCode)
      .reduce<number | null>((prevId, error) => (error.errorId > Number(prevId) ? error.errorId : prevId), null);
    const method = prevErrorId === null ? 'post' : 'put';

    let computedEntityType = entityType;

    // TODO перенести проверку на recordId в селекторы для методов events/list для Olympiad и CulturalEvent
    // насчет EntityType.CULTURAL_EVENT:
    // 1) у визитингов не может отсутствовать recordId - условие не пройдет
    // 2) у чекинов нет возможности отправить сообщение об ошибке
    // условие пройдет только для сущностей CulturalEvent из events/list
    const isOlympiadOrCulturalEvent = entityType === EntityType.OLYMPIAD || entityType === EntityType.CULTURAL_EVENT;
    // если сущность event из постгры с нестандартным типом мероприятия - заменить на Event
    if (isOlympiadOrCulturalEvent && !recordId) {
      computedEntityType = EntityType.EVENT;
    }

    const baseRequest: IPostErrorMessageRequest = {
      entityId,
      recordId,
      entityType: computedEntityType,
      errorGeneralMessage: errorGeneralMessageChecked && isCardDetailsError ? errorGeneralMessage : undefined,
      errorFileMetadataMessage:
        errorFileMetadataMessageChecked && isCardDetailsError ? errorFileMetadataMessage : undefined,
      errorChildEntityMessage:
        errorChildEntityMessageChecked && isCardDetailsError ? errorChildEntityMessage : undefined,
      errorTypeCode,
    };

    const request: IPostErrorMessageRequest | IPutErrorMessageRequest = prevErrorId
      ? {
          ...baseRequest,
          errorId: prevErrorId,
        }
      : baseRequest;

    const ymMeta = getYMEventPayload({
      type: 'bugReportSend',
      payload: {
        bug: formData?.categoryName ?? '',
        text: baseRequest.errorGeneralMessage,
      },
    });
    reportError(currentStudent.meshId, request, method, ymMeta);
    handleClose();
  };

  const entityInfoElement = (
    <FormEntityInfo name={formData?.entityName ?? ''} attributes={[formData?.categoryName ?? '']} />
  );

  const desktopHeader: IDrawerBase['header'] = (crossButton) => (
    <DrawerHeader
      title="Сообщение об ошибке"
      accentColor="red"
      backgroundImage={isBrightTheme ? cardIllustrations.errorReport : null}
      crossButton={crossButton}
    >
      <Box sx={{ marginTop: '16px' }}>{entityInfoElement}</Box>
    </DrawerHeader>
  );

  const mobileHeader: IDrawerBase['header'] = (crossButton) => (
    <MobileDrawerHeader
      crossButton={crossButton}
      title="Сообщение об ошибке"
      accentColor="red"
      onExitBack={formStep === FormStep.SUBMIT_ERROR ? handleExitBack : undefined}
    />
  );

  const footer = formData && (
    <ErrorReportActionButtons
      isMobile={isMobile}
      formData={formData}
      formStep={formStep}
      exitBackTitle={formStep > FormStep.SELECT_ERROR_TYPE ? 'Назад' : 'Отмена'}
      onExitBack={handleExitBack}
      onSubmit={handleSubmit}
      initialFormData={initialFormData}
    />
  );

  return (
    <>
      <Drawer
        open={isOpen && entityErrorMessages.success}
        isFullHeight
        isMobile={isMobile}
        anchor={isMobile ? 'bottom' : 'right'}
        swipeable={isMobile}
        swipeableProps={{
          onClose: handleClose,
        }}
        header={isMobile ? mobileHeader : desktopHeader}
        footer={formStep !== FormStep.SELECT_ERROR_TYPE && footer}
        onClose={handleClose}
        sx={{ backgroundColor: isMobile ? '#F4F3F8' : 'initial' }}
      >
        {formData && (
          <Box sx={mergeSx(drawerContentPadding(true), { position: 'relative' })}>
            {isMobile && entityInfoElement}
            <PortfolioReportErrorForm
              formData={formData}
              onChangeFormData={handleChangeFormData}
              formStep={formStep}
              onChangeFormStep={setFormStep}
            />
          </Box>
        )}
      </Drawer>
      <ReportErrorDrawerSubmitDialog
        title={dialogTitle}
        description={dialogDescription ?? ''}
        iconElement={dialogIconElement}
        isOpen={isSubmitModalOpen}
        onClose={handleCloseSubmitDialog}
      />
    </>
  );
};

export default connect(
  (state: IRootState) => ({
    reportErrorDrawer: state.reportErrorDrawer,
    reportErrorStatus: state.reportErrorStatus,
    currentStudent: state.currentStudent,
    entityErrorMessages: state.entityErrorMessages,
  }),
  {
    reportError: reportErrorActions.request,
  },
)(ReportErrorDrawer);
