import { FC } from 'react';

import { Box, BoxProps } from '@mui/material';
import { getButtonStyles } from 'portfolio3/ui-kit/utils';
import { mergeSx } from 'utils';

const UnstyledButton: FC<BoxProps<'button'>> = (props) => {
  return <Box {...props} component="button" sx={mergeSx(getButtonStyles(), props.sx)} />;
};

export default UnstyledButton;
