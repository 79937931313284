import { FC, ReactNode } from 'react';

import { Box, DrawerProps, Stack, Typography } from '@mui/material';
import { Drawer, IDrawerBase } from 'portfolio3/ui-kit/Drawer';
import { IIndependentDiagnosticData } from 'types';

import * as styles from './styles';

interface IMobileDiagnosticDetailsDrawerProps {
  diagnostic: IIndependentDiagnosticData;
  date: string;
  resultDoughnut: ReactNode;
  resultDescription: ReactNode;
}

const MobileDiagnosticDetailsDrawer: FC<IMobileDiagnosticDetailsDrawerProps & IDrawerBase & DrawerProps> = ({
  diagnostic,
  date,
  resultDoughnut,
  resultDescription,
  children,
  ...drawerProps
}) => {
  const { name, subject } = diagnostic;

  const header: IDrawerBase['header'] = (crossButton) => (
    <Box sx={styles.header}>
      <Typography variant="Paragraph MD/Bold">{name}</Typography>
      <Stack direction="row" alignItems="center">
        <Typography variant="Paragraph MD/Regular">{subject}</Typography>
        <Box sx={styles.horizontalDivider} />
        <Typography variant="Paragraph MD/Regular">{date}</Typography>
      </Stack>

      <Box sx={styles.headerCross}>{crossButton}</Box>
    </Box>
  );

  return (
    <Drawer
      {...drawerProps}
      swipeable
      swipeableProps={{
        onClose: (event) => drawerProps.onClose?.(event, 'escapeKeyDown'),
      }}
      anchor="bottom"
      isMobile
      header={header}
      sx={{ backgroundColor: '#F1F3FE' }}
    >
      <Box>
        <Box sx={styles.contentHeader}>
          <Box sx={styles.doughnutContainer}>{resultDoughnut}</Box>
          {resultDescription}
        </Box>
        <Box sx={{ backgroundColor: '#ffffff' }}>{children}</Box>
      </Box>
    </Drawer>
  );
};

export default MobileDiagnosticDetailsDrawer;
