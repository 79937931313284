import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { Box } from '@mui/material';
import { routes } from 'const';
import StudentInterestsContainer from 'containers/redesignStudent/StudentInterests';
import { GlobalHeaderContainer } from 'portfolio3/containers/headers';
import { compose } from 'redux';

import { withErrorBoundary, withReferenceData, withRendererType, withStudentDataMain, withUserContext } from '../hocs';

const InterestsRenderer: React.FC = () => {
  const matchWebviewRoute = useRouteMatch({ path: routes.WEBVIEW.path });
  const isWebView = matchWebviewRoute !== null;

  return (
    <Box sx={{ display: 'grid', gridTemplateRows: isWebView ? '1fr' : 'auto 1fr', height: '100%' }}>
      {!isWebView && <GlobalHeaderContainer isFullWidth />}
      <StudentInterestsContainer isWebView={isWebView} />
    </Box>
  );
};

export default compose<React.FC>(
  withRendererType('interests'),
  withErrorBoundary(),
  withUserContext(),
  withStudentDataMain(),
  withReferenceData(),
)(InterestsRenderer);
