import { FC } from 'react';
import { connect } from 'react-redux';

import { useMediaQuery } from '@mui/material';
import AwardsCarousel from 'components/redesignStudent/award/AwardCarousel';
import { useBrightTheme, useDataEntryDrawer, useUserFunctionality, useWidgetVisibility } from 'hooks';
import useDataLoad, { IDataRequest } from 'hooks/dataLoad/useDataLoad';
import { placeholderCharacter } from 'images';
import NoDataPlug from 'portfolio3/components/common/NoDataPlug';
import { WidgetContainer as Widget } from 'portfolio3/components/common/WidgetContainer';
import { IModifiedPersonObject } from 'portfolio3/features/dataEntryForm';
import { commonTheme } from 'portfolio3/styles/theme';
import { IRootState } from 'reducers';
import { IModifiedReward } from 'types';

import WidgetContainerAddButton from '../WidgetContainerAddButton';
import WidgetContainerTitle from '../WidgetContainerTitle';

interface IReduxProps {
  linkMode: boolean | undefined;
}

interface IWidgetRewardsContainerProps extends IReduxProps {
  containerData: { id: string; label: string };
  title: string;
  initialFormData: Partial<IModifiedPersonObject>;
  sectionTypeCode: string;
  subsectionTypeCode: string;
  rewards: IModifiedReward[];
  isLoading: boolean;
  noDataTitle: string;
  noDataSubtitle: string;
  dataRequests?: IDataRequest[];
  backgroundImage?: string;
}

/**
 * Компонент для отображения виджета по сущностям reward и sport reward
 */
const WidgetRewardsContainer: FC<IWidgetRewardsContainerProps> = ({
  linkMode,

  containerData,
  title,
  initialFormData,
  sectionTypeCode,
  subsectionTypeCode,
  rewards,
  isLoading,
  noDataTitle,
  noDataSubtitle,
  dataRequests,
  backgroundImage,
}) => {
  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const { handleOpenPrimaryDataEntry } = useDataEntryDrawer();
  const openDataEntry = () => handleOpenPrimaryDataEntry(initialFormData, { widgetLabel: containerData.label });

  const isBrightTheme = useBrightTheme();
  const { isSettingsMode, isWidgetVisible, toggleElement, overlayElement } = useWidgetVisibility({
    sectionTypeCode,
    subsectionTypeCode,
  });

  useDataLoad({ shouldLoad: isWidgetVisible, requests: dataRequests ?? [] });

  const { isAdmin } = useUserFunctionality();

  const isAddButtonVisible = !linkMode && !isAdmin;
  const hasData = rewards.length > 0;

  const awardsCarouselElement = <AwardsCarousel awards={rewards} loading={isLoading} />;
  const buttonAddElement = <WidgetContainerAddButton isMobile={isMobile} isReward onClick={openDataEntry} />;
  const titleRightElement = isSettingsMode ? toggleElement : isAddButtonVisible && buttonAddElement;
  const noDataPlugElement = (
    <NoDataPlug image={placeholderCharacter} title={noDataTitle} subtitle={noDataSubtitle} loading={isLoading} />
  );

  if (!isWidgetVisible) return null;

  return (
    <Widget containerData={containerData} withBackground={isBrightTheme}>
      {overlayElement}
      <Widget.Header
        title={<WidgetContainerTitle title={title} right={titleRightElement} />}
        backgroundImage={backgroundImage}
      />
      <Widget.Padding disableRightPadding={isMobile && hasData}>
        {hasData ? awardsCarouselElement : noDataPlugElement}
      </Widget.Padding>
    </Widget>
  );
};

export default connect((state: IRootState) => ({
  linkMode: state.linkMode.mode,
}))(WidgetRewardsContainer);
