import { createSelector } from 'reselect';
import { userFunctionalitySelector } from 'selectors';

import { IRootState } from '../../../reducers';
import { mapProfessionGiaWorldskillsResponse } from './mappers';

const giaWorldSkillsSelector = createSelector(
  [(state: IRootState) => state.studentGiaWorldSkills, (state: IRootState) => userFunctionalitySelector(state)],
  (studentGiaWorldSkills, userFunctionality) => {
    return mapProfessionGiaWorldskillsResponse(studentGiaWorldSkills, userFunctionality);
  },
);

export default giaWorldSkillsSelector;
