/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { IconButton, useMediaQuery } from '@mui/material';

import { Button } from '../../../../../ui-kit';
import { iconBlueArrow, IconChevronSmallUp } from '../../../../../icons';

interface IDiagnosticListExpandButton {
  hiddenEntitiesCount: number;
  isAllEntitiesShown: boolean;
  onSetInitialDisplayCount: () => void;
  onSetAllDisplayCount: () => void;
}

const DiagnosticListExpandButton: React.VFC<IDiagnosticListExpandButton> = ({
  hiddenEntitiesCount,
  isAllEntitiesShown,
  onSetInitialDisplayCount,
  onSetAllDisplayCount
}) => {
  const isMobile = useMediaQuery('(max-width: 600px)', { noSsr: true });

  if (hiddenEntitiesCount <= 0) return null;

  const onClickHandler = isAllEntitiesShown ? onSetInitialDisplayCount : onSetAllDisplayCount;

  const renderNormalToggleButton = () => {
    const endIcon = isAllEntitiesShown
      ? <img className="button-arrow__icon button-arrow__icon--flipped" src={iconBlueArrow} alt="скрыть" />
      : <img src={iconBlueArrow} alt="показать еще" />;
    const buttonText = isAllEntitiesShown ? 'Свернуть' : `Показать еще ${hiddenEntitiesCount}`

    return (
      <Button
        className="button-arrow"
        endIcon={endIcon}
        onClick={onClickHandler}
      >
        {buttonText}
      </Button>
    );
  };

  const renderMobileToggleButton = () => {
    const className = isAllEntitiesShown ? 'button-icon button-icon--flipped' : 'button-icon';

    return (
      <IconButton
        className={className}
        onClick={onClickHandler}
        disableTouchRipple
        size="large">
        <IconChevronSmallUp />
      </IconButton>
    );
  };

  return isMobile ? renderMobileToggleButton() : renderNormalToggleButton();
};

export default DiagnosticListExpandButton;
