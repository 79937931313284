import { FC, PropsWithChildren } from 'react';

import { useTheme } from '@mui/material';
import { getAccentColor } from 'portfolio3/styles';
import { SxStyles } from 'types';

import BaseBadge from './BaseBadge';

interface IAccentBadgeProps {
  sx?: SxStyles;
}

const AccentBadge: FC<IAccentBadgeProps & PropsWithChildren> = ({ sx, children }) => {
  const theme = useTheme();

  const accentColor = theme.palette.accentColor;
  const textColor = getAccentColor(accentColor, '100');
  const backgroundColor = getAccentColor(accentColor, '8');

  return (
    <BaseBadge textColor={textColor} backgroundColor={backgroundColor} sx={sx}>
      {children}
    </BaseBadge>
  );
};

export default AccentBadge;
