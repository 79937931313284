import { NeutralColors } from 'portfolio3/styles/colors/neutrals';
import { SxStyles } from 'types';

// стили для основного блока карточки, вертикальные паддинги для корректного вида фона
export const mainCardBlock: SxStyles = {
  paddingBlock: '16px',
};

// стили для прочих блоков карточки
export const cardBlock: SxStyles = (theme) => ({
  paddingInline: '16px',

  [theme.breakpoints.down('commonSm')]: {
    paddingInline: '12px',
  },
});

export const cardDivider: SxStyles = {
  width: '100%',
  height: '1px',
  backgroundColor: NeutralColors.light_neutrals_300,
  marginBlock: '16px',
};

export const detailButton: SxStyles = {
  width: 'max-content',
  padding: 0,
  border: 0,
  background: 'none',
  cursor: 'pointer',
};
