import { FC } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { spoPlaceholders } from 'images';
import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

const imagStyles: SxStyles = (theme) => {
  return {
    width: '260px',
    height: '100%',

    [theme.breakpoints.down('commonSm')]: {
      width: '200px',
    },
  };
};

const DataLoadingSpo: FC = () => {
  return (
    <Stack gap="12px" alignItems="center" paddingBottom="16px">
      <Box component="img" src={spoPlaceholders.girlWithNotebook} alt="Загрузка" sx={imagStyles} />
      <Typography variant="Paragraph LG/Regular" color={NeutralColors.light_neutrals_600} textAlign="center">
        Ищем информацию по вашему запросу...
      </Typography>
    </Stack>
  );
};

export default DataLoadingSpo;
