import { createSelector } from 'reselect';

import { IStudentEvent, IStudentProject, IStudentReward, IStudentSportReward } from '../../api/types';
import { SectionCodes } from '../../const';
import { IRootState } from '../../reducers';
import { IEntity } from '../../types';
import { isDefined } from '../../utils';
import {
  mapCivilContestToEntity,
  mapCreationContestToEntity,
  mapProfessionEventToEntity,
  mapScienceContestToEntity,
  mapScienceProjectToEntity,
  mapSportCompetitionToEntity,
  mapSportExpeditionToEntity,
  mapStudyOlympiadToEntity,
} from '../entityMappers';
import filteredRewardsSelector from './filteredRewardsSelector';
import { isRewardSport, isStudentEntityEvent, isStudentEntityProject } from './utils';

type EntityIdToRewardMap = Map<number, IStudentReward | IStudentSportReward>;

const getMappedEntity = (
  categoryCode: number,
  dataKind: number,
  typeCode: number,
  event: IStudentEvent | IStudentProject,
  reward: IStudentReward | IStudentSportReward | undefined,
  state: IRootState,
) => {
  const commonEvent = isStudentEntityEvent(event) ? event : undefined;
  const projectEvent = isStudentEntityProject(event) ? event : undefined;
  const commonReward = !isRewardSport(reward) ? reward : undefined;
  const sportReward = isRewardSport(reward) ? reward : undefined;

  const sectionRef = state.sectionRef.content;

  // stydy
  if (categoryCode === SectionCodes.study && typeCode === SectionCodes.eventOlympiad && commonEvent) {
    return mapStudyOlympiadToEntity(sectionRef, commonEvent, commonReward);
  }

  // science
  if (categoryCode === SectionCodes.science && typeCode === SectionCodes.project && projectEvent) {
    return mapScienceProjectToEntity(projectEvent, commonReward);
  }
  if (categoryCode === SectionCodes.science && typeCode === SectionCodes.contest && commonEvent) {
    return mapScienceContestToEntity(sectionRef, commonEvent, commonReward);
  }

  // sport
  if (categoryCode === SectionCodes.sport && typeCode === SectionCodes.sportEventCompetition && commonEvent) {
    return mapSportCompetitionToEntity(sectionRef, commonEvent, sportReward, state.sportKinds.content);
  }
  if (
    categoryCode === SectionCodes.sport &&
    (typeCode === SectionCodes.sportEventExpedition || typeCode === SectionCodes.sportEventTourism) &&
    commonEvent
  ) {
    return mapSportExpeditionToEntity(sectionRef, commonEvent, sportReward, state.tourismKinds.content);
  }

  // creation
  if (categoryCode === SectionCodes.creation && typeCode === SectionCodes.creationContest && commonEvent) {
    return mapCreationContestToEntity(sectionRef, commonEvent, commonReward);
  }

  // civil
  if (categoryCode === SectionCodes.civil && dataKind === SectionCodes.civilEvent && commonEvent) {
    return mapCivilContestToEntity(state.sectionRef.content, commonEvent, commonReward);
  }

  // profession
  if (categoryCode === SectionCodes.profession && dataKind === SectionCodes.professionEvent && commonEvent) {
    return mapProfessionEventToEntity(state.sectionRef.content, commonEvent, commonReward);
  }
};

const filterStudentEntitiesWithRewards = (
  entities: (IStudentEvent | IStudentProject)[],
  rewardsMap: EntityIdToRewardMap,
) => {
  return entities.filter((entity) => {
    const { id } = entity;
    const recordId = Number(entity.recordId);
    if (id && rewardsMap.has(id)) return true;
    if (recordId && rewardsMap.has(recordId)) return true;
    return false;
  });
};

/**
 * Формирует данные для отображения карточек мероприятий на странице наград
 */
const rewardEntitiesSelector = createSelector(
  [
    (state: IRootState) => filteredRewardsSelector(state),
    (state: IRootState) => state.studentEvents,
    (state: IRootState) => state.studentProjects,
    (state: IRootState) => state,
  ],
  (filteredRewards, events, projects, state): IEntity[] => {
    const rewardEntityIdToRewardMapping: EntityIdToRewardMap = new Map();

    filteredRewards.forEach((reward) => {
      if (reward.entityId != null) {
        rewardEntityIdToRewardMapping.set(Number(reward.entityId), reward);
      }
    });

    const activitiesWithRewards = [
      ...filterStudentEntitiesWithRewards(events.content, rewardEntityIdToRewardMapping),
      ...filterStudentEntitiesWithRewards(projects.content, rewardEntityIdToRewardMapping),
    ];

    const entities = activitiesWithRewards
      .map((event) => {
        const { id, recordId, categoryCode, dataKind } = event;
        const typeCode = isStudentEntityProject(event) ? SectionCodes.project : event.typeCode;

        const eventReward = filteredRewards?.find(
          (reward) => Number(reward.entityId) === id || Number(reward.entityId) === Number(recordId),
        );

        return getMappedEntity(categoryCode, dataKind, typeCode, event, eventReward, state);
      })
      .filter(isDefined);

    return entities;
  },
);

export default rewardEntitiesSelector;
