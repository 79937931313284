import { reverseRecord } from 'utils/common';

export enum DocumentKeys {
  SPO_CERTIFICATE = 'spoCertificate',
  SPO_JOB_CERTIFICATE = 'spoJobCertificate',
  SPO_SHORT_TRAINING_CERTIFICATE = 'spoShortTrainingCertificate',
  SPO_PROFESSIONAL_CERTIFICATE = 'spoProfessionalCertificate',
  SPO_OTHER = 'spoOther',
}

// document_ref
export const DocumentTypeCodes: Record<DocumentKeys, number> = {
  spoCertificate: 1,
  spoJobCertificate: 2,
  spoShortTrainingCertificate: 3,
  spoProfessionalCertificate: 4,
  spoOther: 5,
};

export const DocumentCodeToKey = reverseRecord(DocumentTypeCodes);

export const DocumentShortNames: Record<DocumentKeys, string> = {
  spoCertificate: 'сертификат',
  spoJobCertificate: 'свидетельство',
  spoShortTrainingCertificate: 'удостоверение',
  spoProfessionalCertificate: 'удостоверение',
  spoOther: 'другое',
};
