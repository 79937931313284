import { IBaseRefItem, PostSpoDocumentRequest, ProfessionProgrammRefItem } from 'api/types';
import { DifferentCode, SectionCodes } from 'const';
import { convertDateToServerDate } from 'utils';

import { isSpoDocumentValid } from '../validation/validators';
import { GetMappedEntityData } from './types';

export const getMappedPostSpoDocumentFactory = (
  spoOrganizationRef: IBaseRefItem[],
  professionProgrammRef: ProfessionProgrammRefItem[],
) => {
  const getMappedPostSpoDocumentData: GetMappedEntityData<PostSpoDocumentRequest> = (
    personId,
    creatorId,
    sourceCode,
    formData,
  ): PostSpoDocumentRequest | null => {
    if (!formData.studySpoDocument) return null;

    const documentFormData = formData.studySpoDocument;

    const isValid = isSpoDocumentValid(documentFormData, formData.attachment ?? []);

    if (!sourceCode) return null;
    if (!isValid) return null;

    const {
      documentTypeCode,
      documentName,
      organizationName,
      organizationCode,
      educationProgramm,
      hours,
      examMarkCode,
      profession,
      rankCode,
      resultDate,
      docSeries,
      docNum,
      regNum,
      docDate,
      docPlace,
      docPlaceOther,
      professionalProgrammCode,
      professionalProgrammName,
    } = documentFormData;

    const computedOrganizationName =
      organizationCode === DifferentCode
        ? organizationName
        : spoOrganizationRef.find((ref) => ref.code === organizationCode)?.value;
    const professionProgramm = professionProgrammRef.find((ref) => ref.code === professionalProgrammCode);
    const computedProfessionProgrammName = professionProgramm ? professionProgramm.value : professionalProgrammName;
    const issueDate = docDate ? convertDateToServerDate(docDate) : undefined;

    if (
      !documentName ||
      !documentTypeCode ||
      !computedOrganizationName ||
      !issueDate ||
      (!docPlace && !docPlaceOther)
    ) {
      return null;
    }

    return {
      documentCode: documentTypeCode,
      name: documentName,
      organizationName: computedOrganizationName,
      spoOrganizationCode: organizationCode === DifferentCode ? undefined : organizationCode,
      educationProgram: educationProgramm,
      hours: Number(hours),
      examMarkCode,
      profession,
      rankCode,
      resultDate: resultDate ? convertDateToServerDate(resultDate) : undefined,
      docSeries,
      docNumber: docNum,
      regNumber: regNum,
      issueDate,
      // другое название или название из автокомплита
      issuePlace: docPlaceOther ?? docPlace ?? '',
      professionProgramName: computedProfessionProgrammName,
      professionProgramCode: professionalProgrammCode === DifferentCode ? undefined : professionalProgrammCode,

      personId,
      creatorId,
      sourceCode,

      categoryCode: SectionCodes.study,
      dataKind: SectionCodes.studySpo,
      typeCode: SectionCodes.studySpoDocuments,

      isDelete: false,
    };
  };

  return getMappedPostSpoDocumentData;
};
