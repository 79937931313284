import { FC, PropsWithChildren } from 'react';

import { Box, Typography } from '@mui/material';
import { Checkbox } from 'portfolio3/ui-kit';

import { IMetaskillsHeaderProps } from '../types';
import * as styles from './styles';

const MetaskillsContainerHeader: FC<IMetaskillsHeaderProps & PropsWithChildren> = ({
  skill,
  icon,
  checked,
  handleCheckContainer,
  children,
}) => {
  const handleCheckboxClick = () => handleCheckContainer(skill);
  return (
    <Box sx={styles.headerContainer(checked)} className="metaskills-container__header">
      <Box className="header__checkbox">
        <Checkbox onClick={handleCheckboxClick} checked={checked} />
      </Box>
      <Box className="header__icon">{icon}</Box>
      <Box className="header__title">
        <Typography variant="Paragraph LG/Semi Bold">{children}</Typography>
      </Box>
    </Box>
  );
};

export default MetaskillsContainerHeader;
