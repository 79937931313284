/* eslint-disable max-len */
import { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const Panda: FC<SvgIconProps> = (props) => (
  <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.76685 14.0703C10.2823 14.0703 12.3215 12.0311 12.3215 9.51562C12.3215 7.00014 10.2823 4.96094 7.76685 4.96094C5.25136 4.96094 3.21216 7.00014 3.21216 9.51562C3.21216 12.0311 5.25136 14.0703 7.76685 14.0703Z"
      fill="url(#paint0_linear_64_90104)"
    />
    <path
      d="M7.76685 14.0703C10.2823 14.0703 12.3215 12.0311 12.3215 9.51562C12.3215 7.00014 10.2823 4.96094 7.76685 4.96094C5.25136 4.96094 3.21216 7.00014 3.21216 9.51562C3.21216 12.0311 5.25136 14.0703 7.76685 14.0703Z"
      fill="url(#paint1_linear_64_90104)"
    />
    <path
      d="M7.76685 14.0703C10.2823 14.0703 12.3215 12.0311 12.3215 9.51562C12.3215 7.00014 10.2823 4.96094 7.76685 4.96094C5.25136 4.96094 3.21216 7.00014 3.21216 9.51562C3.21216 12.0311 5.25136 14.0703 7.76685 14.0703Z"
      fill="url(#paint2_linear_64_90104)"
    />
    <path
      d="M7.76685 14.0703C10.2823 14.0703 12.3215 12.0311 12.3215 9.51562C12.3215 7.00014 10.2823 4.96094 7.76685 4.96094C5.25136 4.96094 3.21216 7.00014 3.21216 9.51562C3.21216 12.0311 5.25136 14.0703 7.76685 14.0703Z"
      fill="url(#paint3_radial_64_90104)"
    />
    <path
      d="M7.76685 14.0703C10.2823 14.0703 12.3215 12.0311 12.3215 9.51562C12.3215 7.00014 10.2823 4.96094 7.76685 4.96094C5.25136 4.96094 3.21216 7.00014 3.21216 9.51562C3.21216 12.0311 5.25136 14.0703 7.76685 14.0703Z"
      fill="url(#paint4_radial_64_90104)"
    />
    <path
      d="M24.6099 14.0703C27.1253 14.0703 29.1645 12.0311 29.1645 9.51562C29.1645 7.00014 27.1253 4.96094 24.6099 4.96094C22.0944 4.96094 20.0552 7.00014 20.0552 9.51562C20.0552 12.0311 22.0944 14.0703 24.6099 14.0703Z"
      fill="url(#paint5_linear_64_90104)"
    />
    <g filter="url(#filter0_f_64_90104)">
      <path
        d="M28.6006 9.51593C28.6006 11.7201 26.8138 13.5069 24.6097 13.5069C22.4056 13.5069 20.6188 11.7201 20.6188 9.51593C20.6188 7.3118 22.4056 5.525 24.6097 5.525C26.8138 5.525 28.6006 7.3118 28.6006 9.51593Z"
        stroke="url(#paint6_linear_64_90104)"
        strokeWidth="0.3"
      />
    </g>
    <g filter="url(#filter1_f_64_90104)">
      <path
        d="M11.7828 9.51593C11.7828 11.7339 9.98484 13.5319 7.76691 13.5319C5.54897 13.5319 3.75098 11.7339 3.75098 9.51593C3.75098 7.29799 5.54897 5.5 7.76691 5.5C9.98484 5.5 11.7828 7.29799 11.7828 9.51593Z"
        stroke="url(#paint7_linear_64_90104)"
        strokeWidth="0.25"
      />
    </g>
    <g filter="url(#filter2_ii_64_90104)">
      <path
        d="M30.2349 19.8711C30.2349 27.6011 23.9649 29.7511 16.2349 29.7511C8.50486 29.7511 2.23486 27.6011 2.23486 19.8711C2.23486 12.1411 8.50486 5.87109 16.2349 5.87109C23.9649 5.87109 30.2349 12.1311 30.2349 19.8711Z"
        fill="url(#paint8_radial_64_90104)"
      />
      <path
        d="M30.2349 19.8711C30.2349 27.6011 23.9649 29.7511 16.2349 29.7511C8.50486 29.7511 2.23486 27.6011 2.23486 19.8711C2.23486 12.1411 8.50486 5.87109 16.2349 5.87109C23.9649 5.87109 30.2349 12.1311 30.2349 19.8711Z"
        fill="url(#paint9_radial_64_90104)"
      />
    </g>
    <path
      d="M30.2349 19.8711C30.2349 27.6011 23.9649 29.7511 16.2349 29.7511C8.50486 29.7511 2.23486 27.6011 2.23486 19.8711C2.23486 12.1411 8.50486 5.87109 16.2349 5.87109C23.9649 5.87109 30.2349 12.1311 30.2349 19.8711Z"
      fill="url(#paint10_radial_64_90104)"
    />
    <path
      d="M30.2349 19.8711C30.2349 27.6011 23.9649 29.7511 16.2349 29.7511C8.50486 29.7511 2.23486 27.6011 2.23486 19.8711C2.23486 12.1411 8.50486 5.87109 16.2349 5.87109C23.9649 5.87109 30.2349 12.1311 30.2349 19.8711Z"
      fill="url(#paint11_linear_64_90104)"
    />
    <path
      d="M30.2349 19.8711C30.2349 27.6011 23.9649 29.7511 16.2349 29.7511C8.50486 29.7511 2.23486 27.6011 2.23486 19.8711C2.23486 12.1411 8.50486 5.87109 16.2349 5.87109C23.9649 5.87109 30.2349 12.1311 30.2349 19.8711Z"
      fill="url(#paint12_radial_64_90104)"
    />
    <path
      d="M12.9357 19.7965C11.3341 21.398 9.20129 21.2652 7.88877 19.7965C6.38098 18.1949 7.16289 16.1792 8.60755 14.609C10.2247 12.8511 11.411 12.3968 12.881 13.8668C14.351 15.3368 14.4057 18.3265 12.9357 19.7965Z"
      fill="url(#paint13_linear_64_90104)"
    />
    <path
      d="M12.9357 19.7965C11.3341 21.398 9.20129 21.2652 7.88877 19.7965C6.38098 18.1949 7.16289 16.1792 8.60755 14.609C10.2247 12.8511 11.411 12.3968 12.881 13.8668C14.351 15.3368 14.4057 18.3265 12.9357 19.7965Z"
      fill="url(#paint14_radial_64_90104)"
    />
    <path
      d="M19.0543 19.7965C20.6559 21.398 22.7887 21.2652 24.1012 19.7965C25.609 18.1949 24.8271 16.1792 23.3824 14.609C21.7653 12.8511 20.579 12.3968 19.109 13.8668C17.639 15.3368 17.5843 18.3265 19.0543 19.7965Z"
      fill="url(#paint15_linear_64_90104)"
    />
    <path
      d="M19.0543 19.7965C20.6559 21.398 22.7887 21.2652 24.1012 19.7965C25.609 18.1949 24.8271 16.1792 23.3824 14.609C21.7653 12.8511 20.579 12.3968 19.109 13.8668C17.639 15.3368 17.5843 18.3265 19.0543 19.7965Z"
      fill="url(#paint16_radial_64_90104)"
    />
    <g filter="url(#filter3_f_64_90104)">
      <path
        d="M14.8648 19.2559H16.5623C17.2166 19.2559 17.5417 19.9497 17.2042 20.4894C16.8129 21.1152 16.2637 21.5087 16.2637 21.5087C16.083 21.6431 15.8937 21.7131 15.7136 21.7116C15.5334 21.7131 15.3442 21.6431 15.1634 21.5087C15.1634 21.5087 14.6143 21.1152 14.2229 20.4894C13.8854 19.9497 14.2106 19.2559 14.8648 19.2559Z"
        fill="#A081BC"
      />
    </g>
    <path
      d="M15.1131 18.959H16.8867C17.5703 18.959 17.9101 19.6839 17.5574 20.2479C17.1485 20.9018 16.5747 21.3129 16.5747 21.3129C16.3858 21.4533 16.1881 21.5265 15.9999 21.5249C15.8117 21.5265 15.6139 21.4533 15.4251 21.3129C15.4251 21.3129 14.8513 20.9018 14.4424 20.2479C14.0897 19.6839 14.4295 18.959 15.1131 18.959Z"
      fill="url(#paint17_linear_64_90104)"
    />
    <path
      d="M15.1131 18.959H16.8867C17.5703 18.959 17.9101 19.6839 17.5574 20.2479C17.1485 20.9018 16.5747 21.3129 16.5747 21.3129C16.3858 21.4533 16.1881 21.5265 15.9999 21.5249C15.8117 21.5265 15.6139 21.4533 15.4251 21.3129C15.4251 21.3129 14.8513 20.9018 14.4424 20.2479C14.0897 19.6839 14.4295 18.959 15.1131 18.959Z"
      fill="url(#paint18_linear_64_90104)"
    />
    <path
      d="M15.1131 18.959H16.8867C17.5703 18.959 17.9101 19.6839 17.5574 20.2479C17.1485 20.9018 16.5747 21.3129 16.5747 21.3129C16.3858 21.4533 16.1881 21.5265 15.9999 21.5249C15.8117 21.5265 15.6139 21.4533 15.4251 21.3129C15.4251 21.3129 14.8513 20.9018 14.4424 20.2479C14.0897 19.6839 14.4295 18.959 15.1131 18.959Z"
      fill="url(#paint19_linear_64_90104)"
    />
    <path
      d="M15.1131 18.959H16.8867C17.5703 18.959 17.9101 19.6839 17.5574 20.2479C17.1485 20.9018 16.5747 21.3129 16.5747 21.3129C16.3858 21.4533 16.1881 21.5265 15.9999 21.5249C15.8117 21.5265 15.6139 21.4533 15.4251 21.3129C15.4251 21.3129 14.8513 20.9018 14.4424 20.2479C14.0897 19.6839 14.4295 18.959 15.1131 18.959Z"
      fill="url(#paint20_radial_64_90104)"
    />
    <path
      d="M15.1131 18.959H16.8867C17.5703 18.959 17.9101 19.6839 17.5574 20.2479C17.1485 20.9018 16.5747 21.3129 16.5747 21.3129C16.3858 21.4533 16.1881 21.5265 15.9999 21.5249C15.8117 21.5265 15.6139 21.4533 15.4251 21.3129C15.4251 21.3129 14.8513 20.9018 14.4424 20.2479C14.0897 19.6839 14.4295 18.959 15.1131 18.959Z"
      fill="url(#paint21_radial_64_90104)"
    />
    <g filter="url(#filter4_f_64_90104)">
      <rect x="10.8528" y="15.3672" width="1.46875" height="2.80469" rx="0.734375" fill="#45366A" />
    </g>
    <g filter="url(#filter5_f_64_90104)">
      <rect x="18.884" y="15.3672" width="1.46875" height="2.80469" rx="0.734375" fill="#45366A" />
    </g>
    <rect x="11.2676" y="14.9609" width="1.46875" height="2.97656" rx="0.734375" fill="url(#paint22_linear_64_90104)" />
    <rect x="11.2676" y="14.9609" width="1.46875" height="2.97656" rx="0.734375" fill="url(#paint23_linear_64_90104)" />
    <rect
      width="1.46875"
      height="2.97656"
      rx="0.734375"
      transform="matrix(-1 0 0 1 20.7222 14.9609)"
      fill="url(#paint24_linear_64_90104)"
    />
    <rect
      width="1.46875"
      height="2.97656"
      rx="0.734375"
      transform="matrix(-1 0 0 1 20.7222 14.9609)"
      fill="url(#paint25_linear_64_90104)"
    />
    <g filter="url(#filter6_f_64_90104)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0114 23.0189C13.0114 22.7571 12.7992 22.5449 12.5374 22.5449C12.2757 22.5449 12.0635 22.7571 12.0635 23.0189C12.0635 24.2326 12.978 25.2883 14.1868 25.2883C14.8692 25.2883 15.4577 24.9519 15.841 24.4407C16.2242 24.9519 16.8128 25.2883 17.4951 25.2883C18.704 25.2883 19.6185 24.2326 19.6185 23.0189C19.6185 22.7571 19.4063 22.5449 19.1445 22.5449C18.8828 22.5449 18.6706 22.7571 18.6706 23.0189C18.6706 23.7864 18.1063 24.3404 17.4951 24.3404C16.8839 24.3404 16.3197 23.7864 16.3197 23.0189C16.3197 22.7571 16.1075 22.5449 15.8457 22.5449C15.8441 22.5449 15.8426 22.5449 15.841 22.5449C15.8394 22.5449 15.8378 22.5449 15.8363 22.5449C15.5745 22.5449 15.3623 22.7571 15.3623 23.0189C15.3623 23.7864 14.798 24.3404 14.1868 24.3404C13.5756 24.3404 13.0114 23.7864 13.0114 23.0189Z"
        fill="#A081BC"
      />
    </g>
    <path
      d="M15.99 22.6035C15.99 23.6485 15.21 24.4976 14.25 24.4976C13.29 24.4976 12.51 23.6485 12.51 22.6035"
      stroke="url(#paint26_radial_64_90104)"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M19.48 22.6035C19.48 23.6485 18.7 24.4976 17.74 24.4976C16.78 24.4976 16 23.6485 16 22.6035"
      stroke="url(#paint27_radial_64_90104)"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <g filter="url(#filter7_f_64_90104)">
      <path
        d="M12.51 22.6035C12.5517 23.1243 13.0444 24.1848 14.1194 24.2598C15.4631 24.3535 15.9319 23.291 15.9788 22.6035C15.9527 23.1556 16.5569 24.3379 17.8225 24.3379C18.91 24.3379 19.4215 23.1556 19.4788 22.6035"
        stroke="url(#paint28_linear_64_90104)"
        strokeWidth="0.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <g filter="url(#filter8_f_64_90104)">
      <rect x="12.0864" y="15.4062" width="0.353119" height="1.84375" rx="0.176559" fill="#665A5C" />
    </g>
    <g filter="url(#filter9_f_64_90104)">
      <rect x="20.0552" y="15.4062" width="0.353119" height="1.84375" rx="0.176559" fill="#665A5C" />
    </g>
    <defs>
      <filter
        id="filter0_f_64_90104"
        x="19.8687"
        y="4.775"
        width="9.48174"
        height="9.48125"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.3" result="effect1_foregroundBlur_64_90104" />
      </filter>
      <filter
        id="filter1_f_64_90104"
        x="3.12598"
        y="4.875"
        width="9.28174"
        height="9.28125"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_64_90104" />
      </filter>
      <filter
        id="filter2_ii_64_90104"
        x="2.23486"
        y="4.87109"
        width="29"
        height="25.3809"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" dy="0.5" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.596078 0 0 0 0 0.584314 0 0 0 0 0.643137 0 0 0 0.6 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_64_90104" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.686275 0 0 0 0 0.490196 0 0 0 0 0.862745 0 0 0 1 0" />
        <feBlend mode="normal" in2="effect1_innerShadow_64_90104" result="effect2_innerShadow_64_90104" />
      </filter>
      <filter
        id="filter3_f_64_90104"
        x="13.5879"
        y="18.7559"
        width="4.25146"
        height="3.45508"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_64_90104" />
      </filter>
      <filter
        id="filter4_f_64_90104"
        x="10.3528"
        y="14.8672"
        width="2.46875"
        height="3.80469"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_64_90104" />
      </filter>
      <filter
        id="filter5_f_64_90104"
        x="18.384"
        y="14.8672"
        width="2.46875"
        height="3.80469"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_64_90104" />
      </filter>
      <filter
        id="filter6_f_64_90104"
        x="11.5635"
        y="22.0449"
        width="8.55493"
        height="3.74414"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_64_90104" />
      </filter>
      <filter
        id="filter7_f_64_90104"
        x="12.135"
        y="22.2285"
        width="7.71875"
        height="2.48438"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.125" result="effect1_foregroundBlur_64_90104" />
      </filter>
      <filter
        id="filter8_f_64_90104"
        x="11.5864"
        y="14.9062"
        width="1.35303"
        height="2.84375"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_64_90104" />
      </filter>
      <filter
        id="filter9_f_64_90104"
        x="19.5552"
        y="14.9062"
        width="1.35303"
        height="2.84375"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_64_90104" />
      </filter>
      <linearGradient
        id="paint0_linear_64_90104"
        x1="4.60999"
        y1="11.4297"
        x2="9.92249"
        y2="4.55469"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6363A2" />
        <stop offset="1" stopColor="#5A4A8A" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_64_90104"
        x1="8.92249"
        y1="9.51563"
        x2="8.07874"
        y2="4.08594"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.549155" stopColor="#4D4175" stopOpacity="0" />
        <stop offset="1" stopColor="#4D4175" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_64_90104"
        x1="7.07874"
        y1="9.66406"
        x2="6.15686"
        y2="8.89844"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4E4B84" />
        <stop offset="1" stopColor="#4E4B84" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint3_radial_64_90104"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(12.0787 5.92969) rotate(146.983) scale(10.3233 4.71988)"
      >
        <stop offset="0.869329" stopColor="#4E4B84" stopOpacity="0" />
        <stop offset="1" stopColor="#4E4B84" />
      </radialGradient>
      <radialGradient
        id="paint4_radial_64_90104"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(12.3215 5.96094) rotate(153.909) scale(3.83693 5.37999)"
      >
        <stop stopColor="#5A4482" />
        <stop offset="1" stopColor="#5A4482" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint5_linear_64_90104"
        x1="21.3289"
        y1="7.96094"
        x2="29.1646"
        y2="8.33594"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6363A2" />
        <stop offset="1" stopColor="#5A4A8A" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_64_90104"
        x1="28.7506"
        y1="11.6019"
        x2="21.9381"
        y2="5.49249"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9A8BBD" stopOpacity="0" />
        <stop offset="0.378229" stopColor="#A997CF" />
        <stop offset="1" stopColor="#9A8BBD" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_64_90104"
        x1="11.9078"
        y1="11.6019"
        x2="5.09534"
        y2="5.49249"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9A8BBD" stopOpacity="0" />
        <stop offset="0.378229" stopColor="#A997CF" />
        <stop offset="1" stopColor="#9A8BBD" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint8_radial_64_90104"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16.2349 10.2554) rotate(90) scale(18.3932)"
      >
        <stop stopColor="#EBE3F5" />
        <stop offset="1" stopColor="#DAC8ED" />
      </radialGradient>
      <radialGradient
        id="paint9_radial_64_90104"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16.2349 28.8361) rotate(-90) scale(11.025 31.318)"
      >
        <stop stopColor="#C7B0DE" />
        <stop offset="1" stopColor="#C7B0DE" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint10_radial_64_90104"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(26.4224 11.5861) rotate(138.552) scale(6.42049 16.4397)"
      >
        <stop stopColor="#F4F2F8" />
        <stop offset="1" stopColor="#F4F2F8" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint11_linear_64_90104"
        x1="31.1099"
        y1="25.2111"
        x2="26.5474"
        y2="23.7736"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E1D3F1" />
        <stop offset="1" stopColor="#E1D3F1" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint12_radial_64_90104"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(8.29736 14.7111) rotate(23.798) scale(24.3176 18.5159)"
      >
        <stop offset="0.896988" stopColor="#BAB0C7" stopOpacity="0" />
        <stop offset="0.991591" stopColor="#BAB0C7" />
      </radialGradient>
      <linearGradient
        id="paint13_linear_64_90104"
        x1="10.4987"
        y1="13.1047"
        x2="10.4987"
        y2="19.5543"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#706AA5" />
        <stop offset="1" stopColor="#604685" />
      </linearGradient>
      <radialGradient
        id="paint14_radial_64_90104"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(14.985 16.9673) rotate(180) scale(3.8125 5.39683)"
      >
        <stop offset="0.0139847" stopColor="#5F4E87" />
        <stop offset="1" stopColor="#5F4E87" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint15_linear_64_90104"
        x1="21.4913"
        y1="12.9863"
        x2="21.4913"
        y2="22.023"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#706AA5" />
        <stop offset="1" stopColor="#604685" />
      </linearGradient>
      <radialGradient
        id="paint16_radial_64_90104"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17.485 19.148) rotate(-33.1994) scale(3.98256 5.63757)"
      >
        <stop offset="0.0139847" stopColor="#5F4E87" />
        <stop offset="1" stopColor="#5F4E87" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint17_linear_64_90104"
        x1="15.8643"
        y1="18.1179"
        x2="16.2831"
        y2="21.5829"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5F5254" />
        <stop offset="1" stopColor="#2E2127" />
      </linearGradient>
      <linearGradient
        id="paint18_linear_64_90104"
        x1="15.7636"
        y1="19.6321"
        x2="15.7636"
        y2="18.4281"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.146552" stopColor="#34222C" stopOpacity="0" />
        <stop offset="0.872727" stopColor="#34222C" />
      </linearGradient>
      <linearGradient
        id="paint19_linear_64_90104"
        x1="15.1604"
        y1="21.6515"
        x2="15.1604"
        y2="19.8312"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#552D52" />
        <stop offset="0.713744" stopColor="#552D52" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint20_radial_64_90104"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(13.8595 20.756) rotate(-18.752) scale(4.48262 2.01281)"
      >
        <stop offset="0.827602" stopColor="#5C5458" stopOpacity="0" />
        <stop offset="0.935007" stopColor="#5C5458" />
      </radialGradient>
      <radialGradient
        id="paint21_radial_64_90104"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17.6985 19.3054) rotate(148.131) scale(1.47909 0.532085)"
      >
        <stop stopColor="#6A5C5E" />
        <stop offset="0.80779" stopColor="#6A5C5E" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint22_linear_64_90104"
        x1="12.6567"
        y1="16.5859"
        x2="11.813"
        y2="16.5859"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#594C4F" />
        <stop offset="1" stopColor="#413039" />
      </linearGradient>
      <linearGradient
        id="paint23_linear_64_90104"
        x1="12.9658"
        y1="18.2734"
        x2="12.9658"
        y2="17.2109"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#492847" />
        <stop offset="1" stopColor="#552D52" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint24_linear_64_90104"
        x1="-0.0908508"
        y1="1.30225"
        x2="0.893524"
        y2="1.30225"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#594C4F" />
        <stop offset="1" stopColor="#413039" />
      </linearGradient>
      <linearGradient
        id="paint25_linear_64_90104"
        x1="1.69824"
        y1="2.97656"
        x2="1.69824"
        y2="2.0625"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#492847" />
        <stop offset="1" stopColor="#552D52" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint26_radial_64_90104"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(14.244 22.9766) rotate(90) scale(1.71875 3.74337)"
      >
        <stop offset="0.00558659" stopColor="#423539" />
        <stop offset="1" stopColor="#472849" />
      </radialGradient>
      <radialGradient
        id="paint27_radial_64_90104"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17.734 23.5505) rotate(90) scale(1.62922 2.82735)"
      >
        <stop offset="0.00558659" stopColor="#423539" />
        <stop offset="1" stopColor="#472849" />
      </radialGradient>
      <linearGradient
        id="paint28_linear_64_90104"
        x1="12.3225"
        y1="23.1035"
        x2="19.8381"
        y2="23.1035"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5F5356" />
        <stop offset="0.288981" stopColor="#5F5356" stopOpacity="0" />
        <stop offset="0.444906" stopColor="#5F5356" stopOpacity="0" />
        <stop offset="0.534304" stopColor="#5F5356" />
        <stop offset="1" stopColor="#5F5356" stopOpacity="0" />
      </linearGradient>
    </defs>
  </SvgIcon>
);

export default Panda;
