/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconFilter: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M13.9034 22C13.6894 22 13.4774 21.932 13.3004 21.797L9.27438 18.752C9.02438 18.563 8.87738 18.268 8.87738 17.955V14.681L4.11138 5.459C3.95138 5.149 3.96438 4.778 4.14638 4.48C4.32738 4.182 4.65138 4 5.00038 4H19.0004C19.3524 4 19.6784 4.185 19.8594 4.488C20.0394 4.79 20.0474 5.166 19.8804 5.475L14.9034 14.69V21C14.9034 21.379 14.6884 21.726 14.3484 21.896C14.2074 21.965 14.0554 22 13.9034 22Z"
      fill={props.fill || "#9775FA"} />
    {/* <path d="M13.9034 22C13.6894 22 13.4774 21.932 13.3004 21.797L9.27438 18.752C9.02438 18.563 8.87738 18.268 8.87738 17.955V14.681L4.11138 5.459C3.95138 5.149 3.96438 4.778 4.14638 4.48C4.32738 4.182 4.65138 4 5.00038 4H19.0004C19.3524 4 19.6784 4.185 19.8594 4.488C20.0394 4.79 20.0474 5.166 19.8804 5.475L14.9034 14.69V21C14.9034 21.379 14.6884 21.726 14.3484 21.896C14.2074 21.965 14.0554 22 13.9034 22Z" fill="#9775FA" strokeWidth="0.5" /> */}
  </SvgIcon>

);

export default IconFilter;
