/* eslint-disable prettier/prettier */
/* eslint-disable */

import { toast } from 'portfolio3/ui-kit';
import { call, put } from 'redux-saga/effects';
import { GenericGetAction, GenericPostAction } from '.';
import {
  getActionKindsActions,
  getAdminDeleteHistoryActions,
  getAdminFunctionSettingsActions,
  getAdminHistoryActions,
  getAdminLogActions,
  getAdminIncorrectDataFileActions,
  getAdminSettingsActions,
  getLearnerCategoriesActions,
  getSettingSectionsActions,
  restorePortfolioEntityActions,
  saveAdminFunctionSettingsActions,
  saveAdminSettingsActions,
  getAdminViewModeSettingsActions
} from '../actions';
import {
  getActionKinds,
  getAdminDeleteHistory,
  getAdminFunctionSettings,
  getAdminHistory,
  getAdminIncorrectDataFile,
  getAdminLog,
  getAdminSettings,
  getLearnerCategories,
  getSettingsSections,
  getUserSettingsByAdmin,
  restorePortfolioEntity,
  saveAdminFunctionSettings,
  saveAdminSettings
} from '../api';
import {
  IGetAdminHistory, IGetAdminHistoryRequest, IGetAdminLog, IGetAdminIncorrectDataRequest, IResponsePayload, IUserSettingsByAdminResponse
} from '../api/types';
import { defaultVisibilitySettings, tooltipOptions } from '../const';
import { downloadFileFromResponse, syncLocalVisibilitySettings } from '../utils';
import { ApiResult } from 'api/impl';

function* getAdminSettingsSaga({ payload: { classLevel } }: GenericGetAction<any>) {
  const { response, error } = yield call(getAdminSettings, classLevel);

  if (response) {
    yield put(getAdminSettingsActions.success(response));
  } else {
    yield put(getAdminSettingsActions.failure(error));
  }
}

function* getAdminViewModeSettingsSaga({ payload: { personId } }: GenericGetAction<any>) {
  const { response, error }: ApiResult<IResponsePayload<IUserSettingsByAdminResponse | null>, any> = yield call(getUserSettingsByAdmin, personId);

  if (response) {
    const sectionsSetting = response.data?.setting.visibilitySettings;
    const sections = syncLocalVisibilitySettings(sectionsSetting, defaultVisibilitySettings);

    if (response.data) {
      response.data.setting.visibilitySettings = sections;
    } else {
      response.data = {
        themeSettings: {
          background: '',
          controls: '',
          cover: '',
          themeName: '',
          themePattern: '',
        },
        setting: {
          visibilitySettings: sections,
        },
      };
    }

    yield put(getAdminViewModeSettingsActions.success(response));
  } else {
    yield put(getAdminViewModeSettingsActions.failure(error));
  }
}

function* getLearnerCategoriesSaga({ payload: { queryParams } }: GenericGetAction<any>) {
  const { response, error } = yield call(getLearnerCategories, queryParams);

  if (response) {
    yield put(getLearnerCategoriesActions.success(response));
  } else {
    yield put(getLearnerCategoriesActions.failure(error));
  }
}

function* getSettingSectionsSaga({ payload: { queryParams } }: GenericGetAction<any>) {
  const { response, error } = yield call(getSettingsSections, queryParams);

  if (response) {
    yield put(getSettingSectionsActions.success(response));
  } else {
    yield put(getSettingSectionsActions.failure(error));
  }
}

function* saveAdminSettingsSaga({ payload: request }: GenericPostAction<any>) {
  const { response, error } = yield call(saveAdminSettings, request);

  if (response) {
    yield put(saveAdminSettingsActions.success(response));
  } else {
    yield put(saveAdminSettingsActions.failure(error));
  }
}

function* getAdminHistorySaga({ payload: { request } }: Required<GenericGetAction<IGetAdminHistory>>) {
  const { response, error } = yield call(getAdminHistory, request);

  if (response) {
    yield put(getAdminHistoryActions.success(response));
  } else {
    yield put(getAdminHistoryActions.failure(error));
  }
}

function* getAdminDeleteHistorySaga({ payload: { request } }: Required<GenericGetAction<IGetAdminHistoryRequest>>) {
  const { response, error } = yield call(getAdminDeleteHistory, request);

  if (response) {
    yield put(getAdminDeleteHistoryActions.success(response));
  } else {
    yield put(getAdminDeleteHistoryActions.failure(error));
  }
}

function* getAdminLogSaga({ payload: { startDate, endDate, actionKindCodes } }: Required<GenericGetAction<IGetAdminLog>>) {
  const { response, error } = yield call(getAdminLog, startDate, endDate, actionKindCodes);

  if (response) {
    yield put(getAdminLogActions.success(response));
    downloadFileFromResponse('log.xlsx', response);
  } else {
    yield put(getAdminLogActions.failure(error));
  }
}

function* getActionKindsSaga({ payload: { queryParams } }: GenericGetAction<any>) {
  const { response, error } = yield call(getActionKinds, queryParams);

  if (response) {
    yield put(getActionKindsActions.success(response));
  } else {
    yield put(getActionKindsActions.failure(error));
  }
}

function* getAdminFunctionSettingsSaga({ payload: { functionType } }: GenericGetAction<any>) {
  const { response, error } = yield call(getAdminFunctionSettings, functionType);

  if (response) {
    yield put(getAdminFunctionSettingsActions.success(response));
  } else {
    yield put(getAdminFunctionSettingsActions.failure(error));
  }
}

function* saveAdminFunctionSettingsSaga({ payload: request }: GenericPostAction<any>) {
  const { response, error } = yield call(saveAdminFunctionSettings, request);

  if (response) {
    yield put(saveAdminFunctionSettingsActions.success(response));
  } else {
    yield put(saveAdminFunctionSettingsActions.failure(error));
  }
}

function* restorePortfolioEntitySaga({ payload: { entityId, entityType, adminHistoryRequestData } }: GenericPostAction<any>) {
  const { response, error } = yield call(restorePortfolioEntity, entityId, entityType);

  if (response) {
    yield put(restorePortfolioEntityActions.success(response));
    toast.success('Запись успешно восстановлена', tooltipOptions);
    yield put(getAdminDeleteHistoryActions.request(adminHistoryRequestData));
  } else {
    yield put(restorePortfolioEntityActions.failure(error));
  }
}

function* getAdminIncorrectDataFileSaga({ payload: request }: Required<GenericGetAction<IGetAdminIncorrectDataRequest>>) {
  const { response, error } = yield call(getAdminIncorrectDataFile, request);

  if (response) {
    yield put(getAdminIncorrectDataFileActions.success(response));
    downloadFileFromResponse('incorrect-data.xlsx', response);
  } else {
    yield put(getAdminIncorrectDataFileActions.failure(error));
  }
}

export {
  getAdminSettingsSaga,
  getAdminViewModeSettingsSaga,
  getAdminLogSaga,
  getLearnerCategoriesSaga,
  getSettingSectionsSaga,
  saveAdminSettingsSaga,
  saveAdminFunctionSettingsSaga,
  getAdminHistorySaga,
  getAdminDeleteHistorySaga,
  getActionKindsSaga,
  getAdminFunctionSettingsSaga,
  restorePortfolioEntitySaga,
  getAdminIncorrectDataFileSaga,
};
