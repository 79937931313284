import { useHistory, useLocation } from 'react-router-dom';

import { routes } from 'const';

import useScrollToElement from './useScrollToElement';

const useStudentNavigation = () => {
  const location = useLocation();
  const history = useHistory();

  const { scrollToElementGlobal } = useScrollToElement();

  /**
   * Отправляет на страницу нужного раздела (редирект)
   * @param section название секции в формате "/study"
   */
  const navigateToSection = (section: string) => {
    if (!location.pathname.includes(section)) {
      history.push(`${routes.PORTFOLIO_STUDENT.path}${section}`);
    }
  };

  /**
   * Отправляет на нужный виджет в текущем разделе (скролл)
   * @param widgetId id виджета
   * @param withDelay если true, переход будет осуществлен после задержки
   */
  const navigateToWidget = (widgetId: string, withDelay: boolean) => {
    if (withDelay) {
      setTimeout(() => {
        scrollToElementGlobal(widgetId);
      }, 500);
    } else {
      scrollToElementGlobal(widgetId);
    }
  };

  return { navigateToSection, navigateToWidget };
};

export default useStudentNavigation;
