import { FC } from 'react';

import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';
import { commonTheme } from 'portfolio3/styles/theme';
import { NeutralNumberBadge } from 'portfolio3/ui-kit/badges';

import { VuzRecommendationTab } from '../../const';
import VuzListSortButton from '../VuzListSortButton';
import * as styles from './styles';

interface IVuzRecommendationWidgetListHeaderProps {
  currentTab: VuzRecommendationTab;
  vuzListCount?: number;
  vuzListSortType: number;
  onChangeSortType: (value: number) => void;
}

const VuzRecommendationWidgetListHeader: FC<IVuzRecommendationWidgetListHeaderProps> = ({
  vuzListCount,
  currentTab,
  vuzListSortType,
  onChangeSortType,
}) => {
  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const isRealGia = currentTab === VuzRecommendationTab.REAL_GIA;
  const isTrialGia = currentTab === VuzRecommendationTab.TRIAL_GIA;
  const isFavourites = currentTab === VuzRecommendationTab.FAVORITES;
  const isGiaBased = isRealGia || isTrialGia;

  const hasCount = typeof vuzListCount === 'number';

  // Перенос бейджа только вместе с последним словом
  const giaTitleElement = (
    <>
      Вузы и направления, подходящие{' '}
      <Box component="span" sx={{ whiteSpace: 'nowrap' }}>
        тебе {hasCount && <NeutralNumberBadge number={vuzListCount} sx={styles.countBadgeLocalStyles} />}
      </Box>
    </>
  );

  const commonTitleText = isFavourites ? 'Избранное' : 'Список вузов';
  const commonTitle = (
    <>
      {commonTitleText} {hasCount && <NeutralNumberBadge number={vuzListCount} sx={styles.countBadgeLocalStyles} />}
    </>
  );

  const titleElement = isGiaBased ? giaTitleElement : commonTitle;

  return (
    <Box className="widget-list-header" sx={styles.root}>
      <Stack gap="4px" className="widget-list-header__content">
        <Box className="widget-list-header__title" component="span">
          <Typography component="span" variant="Paragraph LG/Semi Bold">
            {titleElement}
          </Typography>
        </Box>
        {isGiaBased && (
          <Typography sx={{ color: NeutralColors.light_neutrals_600 }} variant="Paragraph MD/Regular">
            Подобраны на основе предметов {isTrialGia && 'пробного'} ЕГЭ
          </Typography>
        )}
      </Stack>
      {!isMobile && (
        <Box>
          <VuzListSortButton
            isMobile={false}
            currentTab={currentTab}
            sortType={vuzListSortType}
            onChangeSortType={onChangeSortType}
          />
        </Box>
      )}
    </Box>
  );
};

export default VuzRecommendationWidgetListHeader;
