import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root: SxStyles = {
  marginBlock: '8px',

  backgroundColor: NeutralColors.light_neutrals_0,
  border: '1px solid',
  borderColor: NeutralColors.light_neutrals_300,
  borderRadius: '8px',
  boxShadow: '0px 10px 15px -3px rgba(41, 41, 64, 0.08), 0px 4px 6px -2px rgba(41, 41, 64, 0.04)',

  overflow: 'hidden',
};

export const container: SxStyles = {
  display: 'flex',
};

export const content: SxStyles = {
  padding: '16px',
};
