import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useMediaQuery } from '@mui/material';
import { getVuzFaculties, getVuzSpecialities } from 'api';
import { useAppSelector } from 'hooks';
import {
  FacultyListType,
  IDetailsDrawerProps,
  ILocalVuzFaculty,
  ILocalVuzSpeciality,
  IVuzDetailsFilters,
} from 'portfolio3/features/vuzRecommendation';
import { commonTheme } from 'portfolio3/styles/theme';

import { NoDataPlug } from '../../components';
import VuzDetailsDrawerDesktop from '../../components/VuzDetailsDrawerDesktop';
import VuzDetailsDrawerMobile from '../../components/VuzDetailsDrawerMobile';
import { initialDetailsDrawerFilters, SortTypeCodes, VuzDetailsTab } from '../../const';
import { VuzDetailsContext } from '../../context';
import { useFavoriteVuz } from '../../hooks';
import { vuzDetailsDrawerActions } from '../../model/actions';
import { convertVuzFacultyToLocal, convertVuzSpecialityToLocal, setFilterFactory } from '../../utils';
import {
  searchDetailsDrawerFaculties,
  searchDetailsDrawerSpecialities,
  sortDetailsDrawerFacultiesFull,
  sortDetailsDrawerFacultiesShort,
  sortDetailsDrawerSpecialities,
} from '../utils';

const VuzDetailsDrawer: FC = () => {
  const dispatch = useDispatch();
  const { isOpen, vuzInfo } = useAppSelector((state) => state.vuzDetailsDrawer);
  const id = vuzInfo?.id;

  const isSmallScreen = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const defaultTab = isSmallScreen ? null : VuzDetailsTab.SPECIALITIES;

  const [currentTab, setCurrentTab] = useState<VuzDetailsTab | null>(defaultTab);
  const [filters, setFilters] = useState<IVuzDetailsFilters>(initialDetailsDrawerFilters);
  const [specialities, setSpecialities] = useState<ILocalVuzSpeciality[]>([]);
  const [faculties, setFaculties] = useState<ILocalVuzFaculty[]>([]);
  const [isSpecialitiesLoading, setSpecialitiesLoading] = useState(false);
  const [isFacultiesLoading, setFacultiesLoading] = useState(false);

  const { isFavoriteVuz, isFavoriteStateLoading, handleToggleVuzFavorite } = useFavoriteVuz(id);

  const { page, limit } = filters;

  const facultyListType: FacultyListType =
    (faculties.length > 0 && faculties[0].specialities.length > 0) || faculties.length === 0 ? 'full' : 'short';

  const isFacultyListEmpty = !isFacultiesLoading && faculties.length === 0;

  const handleChangeCurrentTab = useCallback((value: number | null) => setCurrentTab(value), [setCurrentTab]);
  const setSingleFilter = setFilterFactory(setFilters);

  const handleClose = () => {
    dispatch(vuzDetailsDrawerActions.setOpen(false));
    dispatch(vuzDetailsDrawerActions.setInfo(null));
  };

  const filteredSpecialities = useMemo(() => {
    const searchSpecialities = searchDetailsDrawerSpecialities(specialities, filters.search);
    const sortedSpecialities = sortDetailsDrawerSpecialities(searchSpecialities, filters.sortType);
    return sortedSpecialities;
  }, [filters.search, filters.sortType, specialities]);

  const filteredFaculties = useMemo(() => {
    const sortFunc = facultyListType === 'full' ? sortDetailsDrawerFacultiesFull : sortDetailsDrawerFacultiesShort;

    const searchFaculties = searchDetailsDrawerFaculties(faculties, filters.search, facultyListType);
    const sortedFaculties = sortFunc(searchFaculties, filters.sortType);
    return sortedFaculties;
  }, [faculties, facultyListType, filters.search, filters.sortType]);

  // если загрузился пустой список факультетов сбрасываем таб на специальности
  useEffect(() => {
    // в адаптиве нет необходимости открывать первую страницу если уже открыты специальности
    const nextMobileTab = currentTab === VuzDetailsTab.SPECIALITIES ? currentTab : null;
    const nextDesktopTab = VuzDetailsTab.SPECIALITIES;

    if (isFacultyListEmpty) {
      setCurrentTab(isSmallScreen ? nextMobileTab : nextDesktopTab);
    }

    // убираем currentTab и isSmallScreen из проверки
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFacultyListEmpty]);

  // при изменении размера экрана сбрасываем таб
  useEffect(() => {
    setCurrentTab(isSmallScreen ? null : VuzDetailsTab.SPECIALITIES);
  }, [isSmallScreen]);

  // сбросить состояния при открытии дровера
  useEffect(() => {
    if (isOpen) {
      setCurrentTab(defaultTab);
      setFilters(initialDetailsDrawerFilters);
      setSpecialities([]);
      setFaculties([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  // сбросить фильтры при смене таба
  useEffect(() => {
    setFilters(initialDetailsDrawerFilters);
  }, [currentTab]);

  // сбросить страницу при изменении поиска
  useEffect(() => {
    setSingleFilter('page', 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.search]);

  // Когда в списке факультетов, если меняется тип списка, нужно сбросить фильтры
  // и установить конкретный фильтр сортировки
  useEffect(() => {
    if (currentTab !== VuzDetailsTab.FACULTIES) return;

    setFilters(initialDetailsDrawerFilters);
    setSingleFilter('sortType', facultyListType === 'full' ? SortTypeCodes.default : SortTypeCodes.entityNameAToZ);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab, facultyListType]);

  useEffect(() => {
    if (!id) return;

    const localId = id;

    async function setLocalSpecialities() {
      const { response } = await getVuzSpecialities(localId, { per_page: 9999, page: 1 });
      const rawSpecialities = response?.items;
      const localSpecialities = rawSpecialities?.flatMap(convertVuzSpecialityToLocal) ?? [];

      setSpecialitiesLoading(false);
      setSpecialities(localSpecialities);
    }

    async function setLocalFaculties() {
      const { response } = await getVuzFaculties(localId, { per_page: 9999, page: 1 });
      const rawFaculties = response?.items;
      const localFaculties = rawFaculties?.map(convertVuzFacultyToLocal) ?? [];

      setFacultiesLoading(false);
      setFaculties(localFaculties);
    }

    setSpecialitiesLoading(true);
    setFacultiesLoading(true);

    setLocalSpecialities();
    setLocalFaculties();
  }, [dispatch, id]);

  // Пагинация
  const pageIndex = page - 1;
  const sliceStartIndex = pageIndex * limit;
  const sliceEndIndex = page * limit;
  const pagedSpecialities = filteredSpecialities.slice(sliceStartIndex, sliceEndIndex);
  const pagedFaculties = filteredFaculties.slice(sliceStartIndex, sliceEndIndex);

  const hasPagination =
    currentTab === VuzDetailsTab.SPECIALITIES
      ? filteredSpecialities.length > 0
      : filteredFaculties.length > 0 && facultyListType === 'full';

  let specialitiesNoDataTitle = '';
  if (filteredSpecialities.length === 0) specialitiesNoDataTitle = 'Ничего не найдено, попробуй изменить запрос';
  if (specialities.length === 0) specialitiesNoDataTitle = 'Ничего не найдено';
  const specialitiesDataPlugElement = <NoDataPlug loading={isSpecialitiesLoading} title={specialitiesNoDataTitle} />;

  let facultiesNoDataTitle = '';
  if (filteredFaculties.length === 0) facultiesNoDataTitle = 'Ничего не найдено, попробуй изменить запрос';
  if (faculties.length === 0) facultiesNoDataTitle = 'Ничего не найдено';
  const facultiesDataPlugElement = <NoDataPlug loading={isFacultiesLoading} title={facultiesNoDataTitle} />;

  const totalElements =
    currentTab === VuzDetailsTab.SPECIALITIES ? filteredSpecialities.length : filteredFaculties.length;

  const drawerProps: IDetailsDrawerProps = {
    isOpen,
    isLoading: isSpecialitiesLoading || isFacultiesLoading,
    isFavoriteVuz,
    isFavoriteStateLoading,
    onClose: handleClose,
    onAddToFavorites: handleToggleVuzFavorite,
    vuzInfo,
    specialities: pagedSpecialities,
    faculties: pagedFaculties,
    facultyListType,
    isFacultyListEmpty,
    hasPagination,
    specialitiesDataPlugElement: specialitiesNoDataTitle.length > 0 ? specialitiesDataPlugElement : null,
    facultiesDataPlugElement: facultiesNoDataTitle.length > 0 ? facultiesDataPlugElement : null,
  };

  const drawerElement = isSmallScreen ? (
    <VuzDetailsDrawerMobile {...drawerProps} />
  ) : (
    <VuzDetailsDrawerDesktop {...drawerProps} />
  );

  return (
    <VuzDetailsContext.Provider
      value={{
        currentTab,
        filters,
        totalElements,
        setFilter: setSingleFilter,
        onChangeCurrentTab: handleChangeCurrentTab,
      }}
    >
      {drawerElement}
    </VuzDetailsContext.Provider>
  );
};

export default VuzDetailsDrawer;
