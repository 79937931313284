import { Job } from 'api/types';
import { SourceCode } from 'const';
import { isDefined } from 'utils';

import { IProfessionSpoJobFormData } from '../types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapJobFormData = (formData: any): IProfessionSpoJobFormData => {
  const fd = formData as Partial<Job>;

  const isContract = Boolean(fd.contract?.isContract);

  return {
    recordId: fd.recordId,
    eventId: fd.eventId,
    isPartiallyEditable: isDefined(fd.source) && fd.source.code === SourceCode.proftech,

    organization: fd.organization,
    isProfile: fd.isByProfile,
    businessLevelCode: fd.businessLevel?.code,
    salaryRangeCode: fd.salaryRange?.code,
    position: fd.position,
    mainFunctionality: fd.mainFunctionality,
    hasGraduationDate: Boolean(fd.graduationDate),
    graduationDate: fd.graduationDate ? new Date(Date.parse(fd.graduationDate)) : undefined,
    hasExpulsionDate: Boolean(fd.expulsionDate),
    expulsionDate: fd.expulsionDate ? new Date(Date.parse(fd.expulsionDate)) : undefined,
    hasContract: isContract,
    contractTypeCode: isContract ? fd.contract?.type?.code : undefined,
    contractDate: isContract && fd.contract?.date ? new Date(Date.parse(fd.contract?.date)) : undefined,

    businessLevelValue: fd.businessLevel?.value,
    salaryRangeValue: fd.salaryRange?.value,
    contractTypeValue: fd.contract?.type?.value,
  };
};
