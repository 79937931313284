import { ICommonResponse } from 'api/types';
import { IRootState } from 'reducers';
import { createSelector } from 'reselect';
import { IEisDopRecommendationGroup, IMappedEisDopRecommendation } from 'types';

import eisDopRecommendationsSelector from './eisDopRecommendationsSelector';
import { EisDopRecommendationProperties } from './recommendationThemes';

const eisDopRecommendationGroupsSelector = createSelector(
  [(state: IRootState) => eisDopRecommendationsSelector(state)],
  (mappedEisDopRecommendations): ICommonResponse<IEisDopRecommendationGroup> => {
    const categoryCodeToRecommendationsMap = new Map<number, IMappedEisDopRecommendation[]>();

    // группировка рекомендаций по sectionCode
    mappedEisDopRecommendations.content.forEach((recommendation) => {
      const { sectionCode } = recommendation;

      const categoryRecommendations = categoryCodeToRecommendationsMap.get(sectionCode) ?? [];
      categoryRecommendations.push(recommendation);
      categoryCodeToRecommendationsMap.set(sectionCode, categoryRecommendations);
    });

    const recommendationEntries = Array.from(categoryCodeToRecommendationsMap);

    const recommendationGroups = recommendationEntries.map(
      ([sectionCode, recommendations]): IEisDopRecommendationGroup => {
        const properties = EisDopRecommendationProperties[sectionCode];

        return {
          cardTitle: properties.cardTitle,
          drawerTitle: properties.drawerTitle,
          illustration: properties.theme.backgroundIllustration,
          accentColor: properties.theme.accentColor,
          sectionCode,
          recommendations,
        };
      },
    );

    return {
      loading: mappedEisDopRecommendations.loading,
      content: recommendationGroups,
    };
  },
);

export default eisDopRecommendationGroupsSelector;
