import { SxStyles } from 'types';

export const root: SxStyles = (theme) => ({
  display: 'flex',
  gap: '16px',

  [theme.breakpoints.down('commonMd')]: {
    gap: '8px',
  },
});

export const actions: SxStyles = {
  display: 'flex',
  gap: '12px',
};

export const searchButton: SxStyles = {
  whiteSpace: 'nowrap',
};
