import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { Box, Stack, useMediaQuery } from '@mui/material';
import { setCurrentStudentAction } from 'actions';
import { IChildInfo } from 'api/types';
import { routes } from 'const';
import { useAppSelector, useAvatar, useUserFunctionality } from 'hooks';
import { IconSearch } from 'icons/internetAndCode';
import FixedWidthContainer from 'portfolio3/components/common/FixedWidthContainer';
import { StudentNavigationHeader, StudentServiceHeader } from 'portfolio3/components/common/headers';
import PaddingContainer from 'portfolio3/components/common/PaddingContainer';
import { IStudentPickerItem, StudentPicker } from 'portfolio3/components/common/StudentPicker';
import { PortfolioSearcher } from 'portfolio3/features/search';
import { searchModel } from 'portfolio3/features/search';
import { NeutralColors } from 'portfolio3/styles';
import { commonTheme } from 'portfolio3/styles/theme';
import { Button } from 'portfolio3/ui-kit/button';
import { IRootState } from 'reducers';
import { TUserState } from 'reducers/user';
import { hasContextUserInfoErrorSelector } from 'selectors';
import { invalidateStudentData } from 'utils';

import { IHeaderContainerCommonProps } from '../types';
import { studentHeaderContainerStyles, toggleOverflowMainContainer } from '../utils';
import * as styles from './styles';

interface IStudentHeaderContainerProps extends IHeaderContainerCommonProps {
  hasContextUserInfoError: boolean;
  currentUser: TUserState;
  currentStudent: IChildInfo;
  linkMode: boolean | undefined;

  setCurrentStudent: typeof setCurrentStudentAction;
}

const StudentHeaderContainer: FC<IStudentHeaderContainerProps> = ({
  isFullWidth,
  hasContextUserInfoError,
  currentUser,
  currentStudent,
  linkMode,
  setCurrentStudent,
}) => {
  const dispatch = useDispatch();
  const isPortfolioSearcherOpen = useAppSelector((state) => state.searchPanel).isOpen;

  const { isParent } = useUserFunctionality();
  const matchWebviewRoute = useRouteMatch({ path: routes.WEBVIEW.path });
  const isNavigationHidden = useMediaQuery(commonTheme.breakpoints.down('commonSm'));
  const isStaticHeader = isNavigationHidden;
  const isFullWidthBreakpoint = useMediaQuery(commonTheme.breakpoints.down('commonXl'));

  const [searchButtonRef, setSearchButtonRef] = useState<HTMLButtonElement | null>(null);

  const { getAvatarSettings } = useAvatar();

  const isWebview = matchWebviewRoute !== null;

  const Container = isFullWidthBreakpoint || isFullWidth ? Fragment : FixedWidthContainer;

  const isStudentSelectorShown = isParent && !linkMode && !hasContextUserInfoError;

  useEffect(() => {
    toggleOverflowMainContainer(isPortfolioSearcherOpen);
  }, [isPortfolioSearcherOpen]);

  const parentStudents = useMemo(() => {
    return (
      currentUser.data?.children?.map(
        (child): IStudentPickerItem => ({
          avatar: getAvatarSettings(child.meshId).avatarImage,
          firstName: child.firstname,
          lastName: child.lastname,
          meshId: child.meshId,
          studentClass: child.class,
        }),
      ) ?? []
    );
  }, [currentUser.data?.children, getAvatarSettings]);

  const handleOpenPortfolioSearcher = () => {
    dispatch(searchModel.actions.searchPanelActions.setQuery(''));
    dispatch(searchModel.actions.searchPanelActions.setOpen(true));
  };

  const handleSelectedStudent = (meshId: string) => {
    const selectedStudent = currentUser.data?.children?.find((student) => student.meshId === meshId);

    if (!selectedStudent) return;
    if (selectedStudent.meshId === currentStudent.meshId) return;

    setCurrentStudent(selectedStudent);
    localStorage.setItem('currentStudent', JSON.stringify(selectedStudent));

    invalidateStudentData();
  };

  const studentPickerElement = parentStudents.length > 0 && (
    <StudentPicker
      title={`${currentStudent?.firstname} ${currentStudent?.lastname}`}
      comment="Текущее портфолио"
      selectedStudentMeshId={currentStudent.meshId}
      students={parentStudents}
      onChange={handleSelectedStudent}
    />
  );

  const searchButtonElement = (
    <Button
      variant="secondary"
      onlyIcon
      onClick={handleOpenPortfolioSearcher}
      sx={styles.serviceButtonStyleOverride}
      ref={setSearchButtonRef}
    >
      <IconSearch />
    </Button>
  );

  if (isWebview && isParent) {
    return <>{studentPickerElement}</>;
  }

  return (
    <Box
      sx={{ zIndex: 1000, position: isStaticHeader ? 'static' : 'sticky', top: 0 }}
      className="service-header-container"
    >
      <Container>
        <>
          <Stack sx={studentHeaderContainerStyles}>
            <PaddingContainer
              backgroundColor={NeutralColors.light_neutrals_0}
              sx={{
                borderBottom: '1px solid',
                borderColor: NeutralColors.light_neutrals_300,
              }}
            >
              <StudentServiceHeader
                currentStudent={currentStudent}
                studentPicker={isStudentSelectorShown ? studentPickerElement : null}
                searchButtonElement={searchButtonElement}
              />
            </PaddingContainer>
            {!isNavigationHidden && (
              <PaddingContainer backgroundColor={NeutralColors.light_neutrals_0}>
                <StudentNavigationHeader />
              </PaddingContainer>
            )}
          </Stack>
          {isPortfolioSearcherOpen && <PortfolioSearcher searchButtonRef={searchButtonRef} />}
        </>
      </Container>
    </Box>
  );
};

export default connect(
  (state: IRootState) => ({
    hasContextUserInfoError: hasContextUserInfoErrorSelector(state),
    currentUser: state.currentUser,
    currentStudent: state.currentStudent,
    linkMode: state.linkMode.mode,
  }),
  {
    setCurrentStudent: setCurrentStudentAction,
  },
)(StudentHeaderContainer);
