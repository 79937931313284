/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useContext, useMemo } from 'react';

import { connect } from 'react-redux';

import { IChildInfo } from '../../../../../api/types';

import { IRootState } from '../../../../../reducers';
import { SecondaryBlockContainer, useSecondaryBlockControllers } from '../common';

import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';
import { BaseInputLabel, FormControl, Input, Textarea } from 'portfolio3/ui-kit';
import { Box, FormHelperText } from '@mui/material';
import { DayPicker } from 'portfolio3/ui-kit/datePickers';
import { getDayPickerControllerFactory } from '../../controllers';
import { commonAcceptFileTypes } from 'portfolio3/features/dataEntryForm';

const today = new Date();

interface IRewardSecondaryBlockProps {
  additionalTopControllers?: JSX.Element;
  additionalMiddleControllers?: JSX.Element;
  currentStudent: IChildInfo;
  isRewardNumberRequired?: boolean;
}

const RewardSecondaryBlock: React.FC<IRewardSecondaryBlockProps> = ({
  additionalTopControllers,
  additionalMiddleControllers,
  currentStudent,
  isRewardNumberRequired,
}) => {
  const { studentType, isMobile, inputRenderMode, inputSize, formData, formErrors, onChangeFormData, onChangeFormErrors } = useContext(LocalDataEntryFormContext);

  const { fileController, linkedObjects, linkedObjectController } = useSecondaryBlockControllers({
    formData,
    onChangeFormData,
    onChangeFormErrors
  });

  const dayPickerControllerFactory = getDayPickerControllerFactory(onChangeFormData, onChangeFormErrors);

  const descriptionController = useMemo(() => ({
    handleChange: (description: string) => {
      onChangeFormData((prevState) => ({
        ...prevState,
        description
      }));
    }
  }), [formData.id]);

  const rewardController = useMemo(() => ({
    handleChange: (rewardNumber: string) => {
      let isErrorActive = false;
      if (!rewardNumber && isRewardNumberRequired) {
        isErrorActive = true;
      }
      onChangeFormData((prevState) => ({
        ...prevState,
        rewardNumber
      }));
      onChangeFormErrors((prevState) => ({
        ...prevState,
        rewardNumberError: isErrorActive
      }));
    }
  }), [formData.id]);

  const dateController = dayPickerControllerFactory('date', 'dateError', {
    disableFuture: true,
  });

  return (
    <SecondaryBlockContainer>
      <SecondaryBlockContainer.FilesTab
        personId={currentStudent.meshId}
        controller={fileController}
        files={formData.attachment}
        {...commonAcceptFileTypes}
      />
      <SecondaryBlockContainer.LinkedObjectsTab
        studentType={studentType}
        linkedObjectController={linkedObjectController}
        linkedObjects={linkedObjects}
        formData={formData}
      />

      <SecondaryBlockContainer.GeneralTab>
        {additionalTopControllers}
        <FormControl
          renderMode={inputRenderMode}
          inputSize={inputSize}
          error={formErrors.dateError.active}
          helper={formErrors.dateError.active && <FormHelperText>{formErrors.dateError.description}</FormHelperText>}
          label={<BaseInputLabel>Дата получения</BaseInputLabel>}
          control={
            <Box sx={!isMobile ? { width: '220px' } : undefined}>
              <DayPicker
                isMobile={isMobile}
                renderMode={inputRenderMode}
                size={inputSize}
                value={formData.date ?? null}
                maxValue={today}
                controller={dateController}
              />
            </Box>
          }
        />
        {additionalMiddleControllers}
        <FormControl
          sx={{ width: '100%' }}
          required={isRewardNumberRequired}
          error={formErrors.rewardNumberError}
          renderMode={inputRenderMode}
          inputSize={inputSize}
          label={<BaseInputLabel>Номер награды</BaseInputLabel>}
          helper={formErrors.rewardNumberError && <FormHelperText>Введите номер награды</FormHelperText>}
          control={
            <Input
              renderMode={inputRenderMode}
              size={inputSize}
              placeholder="Номер удостоверения, сертификата или грамоты..."
              value={formData.rewardNumber}
              controller={rewardController}
            />
          }
        />
        <FormControl
          sx={{ width: '100%' }}
          renderMode={inputRenderMode}
          inputSize={inputSize}
          label={<BaseInputLabel>Описание награды</BaseInputLabel>}
          control={
            <Textarea
              renderMode={inputRenderMode}
              size={inputSize}
              placeholder="Любое описание или примечание..."
              value={formData.description}
              controller={descriptionController}
            />
          }
        />
      </SecondaryBlockContainer.GeneralTab>
    </SecondaryBlockContainer>
  );
};

export default connect(
  (state: IRootState) => ({
    currentStudent: state.currentStudent
  })
)(RewardSecondaryBlock);
