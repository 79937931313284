/* eslint-disable prettier/prettier */
/* eslint-disable */

import ruLocale from 'date-fns/locale/ru';
import { format, isAfter } from 'date-fns';
import { getNumeral } from './pluralization';
// @ts-ignore
import buildLocalizeFn from 'date-fns/locale/_lib/buildLocalizeFn';

export const monthValues = {
  narrow: ['Я', 'Ф', 'М', 'А', 'М', 'И', 'И', 'А', 'С', 'О', 'Н', 'Д'],
  abbreviated: [
    'ЯНВ',
    'ФЕВ',
    'МАР',
    'АПР',
    'МАЙ',
    'ИЮН',
    'ИЮЛ',
    'АВГ',
    'СЕНТ',
    'ОКТ',
    'НОЯ',
    'ДЕК'
  ],
  wide: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь'
  ]
};

export const customRuLocale = {
  ...ruLocale,
  localize: {
    ...ruLocale.localize,
    month: buildLocalizeFn({
      values: monthValues,
      defaultWidth: 'wide',
      defaultFormattingWidth: 'wide'
    })
  }
};

export const formatDate = (date: string) => format(new Date(date), 'dd.MM.yyyy');

export const formatDateWithTime = (date: string) => format(new Date(date), 'dd.MM.yyyy в HH:mm', { locale: ruLocale });

export const checkPassedExamDate = (examDate: Date) => {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0);
  return isAfter(currentDate, examDate);
};

export const convertDateToServerDate = (date: any) => {
  if (!date) return;
  if (typeof date === 'string') return date;
  return format(date, 'yyyy-MM-dd');
};

export const getDateRange = (startDate: string, endDate?: string | null) => {
  const formattedStartDate = format(new Date(startDate), 'dd.MM.yyyy');
  if (!endDate) return formattedStartDate;
  const formattedEndDate = format(new Date(endDate), 'dd.MM.yyyy');
  return `${formattedStartDate} - ${formattedEndDate}`;
};

export const getYearsRange = (startDate: string, endDate?: string | null) => {
  const formattedStartDate = format(new Date(startDate), 'yyyy');
  if (!endDate) return formattedStartDate;
  const formattedEndDate = format(new Date(endDate), 'yyyy');
  return `${formattedStartDate} - ${formattedEndDate}`;
};

export const getYearFromDate = (date: string) => {
  const formattedDate = format(new Date(date), 'yyyy');
  return Number(formattedDate);
};

/**
 * Функция принимает строку в виде "2021-2022"
 * и определяет находится ли текущая дата в данном учебном году
 * @param learningYear строка в виде "2021-2022"
 * @returns
 */
export const isCurrentLearningYear = (learningYear: string): boolean => {
  const years = learningYear.split('-');
  if (years.length !== 2) return false;

  const septemberCode = 9;
  const decemberCode = 12;
  const januaryCode = 1;
  const mayCode = 5;

  const date = new Date();
  const currentMonthCode = date.getMonth() + 1;
  const currentYear = date.getFullYear();
  // часть учебного года до перехода в новый календарный год
  const isOnFirstLearningYearPart = currentMonthCode >= septemberCode && currentMonthCode <= decemberCode;
  // часть учебного года после перехода в новый календарный год
  const isOnSecondLearningYearPart = currentMonthCode >= januaryCode && currentMonthCode <= mayCode;

  if (!isOnFirstLearningYearPart && !isOnSecondLearningYearPart) return false;

  const comparedLearningYearPart = isOnFirstLearningYearPart ? years[0] : years[1];
  return currentYear === Number(comparedLearningYearPart);
};

/**
 * Функция принимает разбитую на 3 параметра дату и возвращает целую строку с датой в соответствующих падежах
 * @param years - года
 * @param months - месяцы
 * @param days - дни
 * @returns Строку, в которой соответствующие названия числовых значений отредактированы в соответствующем падеже
 * @example
 * getDateString(1, 2, 3) - 1 год 2 месяца 3 дня
 * getDateString(10, 4, 1) - 10 лет 4 месяца 1 день
 */
export function getDateString(years: number, months: number, days: number): string {
  const yearString = getPartOfDateNumeral(years, 'years');
  const monthString = getPartOfDateNumeral(months, 'months');
  const dayString = getPartOfDateNumeral(days, 'days');
  return `${years} ${yearString} ${months} ${monthString} ${days} ${dayString}`;
}

/**
 * Функция возвращает строку в корректном падеже, используя хелпер getNumeral
 * @param num - год
 * @param type - тип, на основе которого, передаются соответствующие значения в хелпер
 * @returns строчку, с правильным падежом и годом
 */
export const getPartOfDateNumeral = (num: number, type: 'years' | 'months' | 'days') => {
  switch (type) {
    case 'years':
      return getNumeral(num, 'год', 'года', 'лет');
    case 'months':
      return getNumeral(num, 'месяц', 'месяца', 'месяцев');
    case 'days':
      return getNumeral(num, 'день', 'дня', 'дней');
    default: ''
      break;
  }
}

export const createPeriod = (beginDate?: string, years?: number, months?: number, days?: number): string => {
  if (!beginDate) return '';

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: 'UTC'
  }
  const start = new Date(beginDate).toLocaleString('ru', options);
  const end = new Date(start);

  if (years) end.setFullYear(end.getFullYear() + years);
  if (months) end.setMonth(end.getMonth() + months);
  if (days) end.setDate(end.getDate() + days);

  return `${start} - ${end.toLocaleString('ru', options)}`;
}
