/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

const IconJuniorTourist1: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="50" height="50" fill="#E5E5E5"/>
    <rect width="1924" height="1397" transform="translate(-1104 -1085)" fill="white"/>
    <g clip-path="url(#clip0)">
    <path d="M2.18188 24.8877C2.35502 18.9158 4.834 13.247 9.09259 9.08449C13.3512 4.92201 19.0541 2.59364 24.9907 2.59364C30.9273 2.59364 36.6302 4.92201 40.8888 9.08449C45.1474 13.247 47.6264 18.9158 47.7995 24.8877H2.18188Z" fill="white"/>
    <path d="M24.9998 2.12905C30.9876 2.13648 36.7308 4.52018 40.9829 8.76277C45.235 13.0054 47.6528 18.7644 47.7116 24.79H2.28805C2.34683 18.7644 4.76466 13.0054 9.01677 8.76277C13.2689 4.52018 19.0121 2.13648 24.9998 2.12905ZM24.9998 1.9337C18.9222 1.9337 13.0936 4.36338 8.79605 8.68823C4.49854 13.0131 2.08423 18.8788 2.08423 24.9951H47.9154C47.9154 18.8788 45.5011 13.0131 41.2036 8.68823C36.9061 4.36338 31.0774 1.9337 24.9998 1.9337Z" fill="#F7931E"/>
    <path d="M25.0002 3.35979C22.1767 3.35851 19.3805 3.91724 16.7716 5.00407C14.1627 6.09089 11.7922 7.68449 9.79564 9.69377C7.79906 11.7031 6.21554 14.0886 5.13559 16.7141C4.05564 19.3396 3.50044 22.1536 3.50171 24.9951H46.4988C46.5 22.1536 45.9448 19.3396 44.8649 16.7141C43.7849 14.0886 42.2014 11.7031 40.2048 9.69377C38.2083 7.68449 35.8378 6.09089 33.2289 5.00407C30.62 3.91724 27.8238 3.35851 25.0002 3.35979Z" fill="#748FFC"/>
    <path d="M24.9996 4.77609C19.6711 4.77609 14.5608 6.90631 10.793 10.6981C7.02519 14.4899 4.90845 19.6327 4.90845 24.9951H45.0908C45.0908 22.3399 44.5711 19.7107 43.5615 17.2576C42.5518 14.8046 41.0719 12.5756 39.2062 10.6981C37.3406 8.8206 35.1258 7.33128 32.6882 6.31517C30.2506 5.29907 27.638 4.77609 24.9996 4.77609Z" fill="#E83F40"/>
    <path d="M25.0001 -8L13.5859 24.9951L25.0001 58L36.5112 24.9951L25.0001 -8Z" fill="#748FFC"/>
    <path d="M25 58L36.5112 24.9951L25 -8" fill="#5073CC"/>
    <path d="M58 24.9951L25 13.5084L-8 24.9951L25 36.4819L58 24.9951Z" fill="#748FFC"/>
    <path d="M-8 24.9951L25 36.4819L58 24.9951" fill="#5073CC"/>
    <path d="M25 43.9541C35.4046 43.9541 43.8391 35.4659 43.8391 24.9951C43.8391 14.5243 35.4046 6.0361 25 6.0361C14.5954 6.0361 6.16089 14.5243 6.16089 24.9951C6.16089 35.4659 14.5954 43.9541 25 43.9541Z" fill="#FEC200"/>
    <path d="M35.6184 30.3087C34.6516 32.282 33.1613 33.9477 31.3123 35.1216C29.4633 36.2955 27.3277 36.9318 25.1417 36.9601C22.9557 36.9884 20.8045 36.4076 18.9261 35.282C17.0477 34.1564 15.5153 32.5298 14.4984 30.5822L4.79248 36.3842C6.80918 40.0136 9.75595 43.0325 13.3259 45.1264C16.8958 47.2203 20.9583 48.3127 25.0903 48.2898C29.2222 48.2668 33.2725 47.1294 36.8193 44.996C40.366 42.8625 43.2795 39.8111 45.2563 36.1596L35.6184 30.3087Z" fill="#FEC200"/>
    <path d="M25.0002 36.941C31.556 36.941 36.8705 31.5926 36.8705 24.9951C36.8705 18.3976 31.556 13.0493 25.0002 13.0493C18.4444 13.0493 13.1299 18.3976 13.1299 24.9951C13.1299 31.5926 18.4444 36.941 25.0002 36.941Z" fill="url(#paint0_linear)" stroke="#F7931E" stroke-width="0.2" stroke-miterlimit="10"/>
    <path d="M23.8547 20.1113H26.1841V30.465H23.8547V20.1113Z" fill="#E57C13"/>
    <path opacity="0.5" d="M40.0635 13.5572C38.3204 11.2444 36.0716 9.36661 33.4914 8.06913C30.9112 6.77166 28.0688 6.08938 25.1844 6.07516C14.9156 6.07516 6.55883 14.5437 6.16089 25.1514C8.96613 27.191 12.2131 28.5302 15.6338 29.0584C20.8706 29.806 26.201 28.658 30.6751 25.819C35.1492 22.9801 38.4763 18.6347 40.0635 13.5572Z" fill="url(#paint1_linear)"/>
    <path d="M13.5859 34.6163L14.8671 35.7884C15.6921 36.5405 16.09 37.4391 15.265 38.3475C15.1005 38.5335 14.8977 38.6813 14.6709 38.7806C14.444 38.88 14.1984 38.9286 13.951 38.923C13.7036 38.9174 13.4604 38.8578 13.2381 38.7484C13.0159 38.6389 12.8199 38.4821 12.6638 38.2889L12.198 37.8689L11.0527 39.1485L10.2083 38.3964L13.5859 34.6163ZM13.2171 37.5466C13.683 37.9666 14.0906 37.9568 14.44 37.5466C14.7894 37.1363 14.673 36.7847 14.2168 36.3647L13.8285 36.013L12.7997 37.1461L13.2171 37.5466Z" fill="#E57C13"/>
    <path d="M15.4588 39.5782C16.1673 38.0838 17.4 37.6247 18.6423 38.2108C19.8847 38.7968 20.2923 40.0666 19.6129 41.5513C18.9335 43.036 17.6717 43.5048 16.41 42.9578C15.1482 42.4108 14.7503 41.0727 15.4588 39.5782ZM18.5356 41.0531C18.9723 40.135 18.8364 39.4024 18.2153 39.0996C17.5941 38.7968 16.9535 39.1582 16.5167 40.0764C16.08 40.9945 16.1965 41.7662 16.8176 42.0299C17.4388 42.2936 18.0988 41.9713 18.5356 41.0531Z" fill="#E57C13"/>
    <path d="M20.4285 41.815C20.4539 41.4802 20.5479 41.1544 20.7046 40.858C20.8613 40.5615 21.0773 40.301 21.339 40.0927C21.6007 39.8844 21.9024 39.7329 22.2251 39.6477C22.5478 39.5625 22.8845 39.5455 23.2141 39.5977C23.4934 39.646 23.7599 39.7515 23.997 39.9078C24.2341 40.0641 24.4367 40.2677 24.5923 40.5061L23.8838 41.1215C23.6911 40.8414 23.3996 40.6457 23.0685 40.5745C22.3891 40.4671 21.7485 40.975 21.5835 41.981C21.4185 42.9871 21.7873 43.6806 22.5541 43.8076C22.7402 43.8346 22.9298 43.8215 23.1105 43.7693C23.2912 43.7171 23.4588 43.627 23.6023 43.5048L24.0682 44.3155C23.8323 44.5166 23.5548 44.6622 23.256 44.7416C22.9571 44.821 22.6443 44.8323 22.3406 44.7746C22.0117 44.7273 21.6966 44.6098 21.4166 44.43C21.1365 44.2501 20.8979 44.012 20.7168 43.7317C20.5357 43.4514 20.4163 43.1353 20.3666 42.8047C20.3168 42.4742 20.338 42.1367 20.4285 41.815Z" fill="#E57C13"/>
    <path d="M25.1649 42.489C25.1042 42.1591 25.1116 41.8203 25.1867 41.4935C25.2619 41.1667 25.4031 40.859 25.6016 40.5896C25.8002 40.3201 26.0517 40.0947 26.3406 39.9275C26.6295 39.7602 26.9495 39.6546 27.2808 39.6173C27.564 39.5929 27.8492 39.6261 28.1193 39.7151C28.3895 39.804 28.639 39.9468 28.8531 40.135L28.3193 40.9164C28.188 40.7992 28.0339 40.7109 27.8668 40.6571C27.6996 40.6033 27.5232 40.5851 27.3487 40.6038C26.6693 40.6722 26.184 41.3266 26.2811 42.3424C26.3781 43.3583 26.9119 43.9346 27.6399 43.8662C27.8329 43.8487 28.0198 43.7889 28.1875 43.691C28.3551 43.5931 28.4995 43.4596 28.6105 43.2997L29.2705 43.9541C29.0972 44.2113 28.8684 44.4257 28.6011 44.5813C28.3339 44.7369 28.0351 44.8297 27.7272 44.8527C27.4007 44.8858 27.0709 44.849 26.7595 44.7449C26.448 44.6407 26.162 44.4715 25.9201 44.2483C25.6781 44.0252 25.4858 43.7531 25.3557 43.4499C25.2255 43.1467 25.1605 42.8192 25.1649 42.489Z" fill="#E57C13"/>
    <path d="M29.4067 39.3731L30.455 38.9531L31.1247 40.6038C31.3479 41.1606 31.5323 41.8541 31.6876 42.3718V41.1606L31.9594 38.3182L33.0756 37.8591L35.0167 42.5476L33.9782 42.9773L33.2988 41.3168C33.0756 40.7601 32.9009 40.0959 32.7359 39.5587L32.6873 40.7699L32.4156 43.622L31.2994 44.0811L29.4067 39.3731Z" fill="#E57C13"/>
    <path d="M34.075 37.2828L34.9194 36.5405L36.0938 37.8884C36.484 38.3644 36.85 38.8599 37.1905 39.3731L36.8411 38.2107L36.1908 35.4269L37.1032 34.6358L40.4323 38.3963L39.5782 39.1484L38.4038 37.781C38.0155 37.3316 37.637 36.8042 37.3167 36.306L37.6176 37.527L38.297 40.3498L37.3847 41.1508L34.075 37.2828Z" fill="#E57C13"/>
    <path d="M10.6353 17.2494L10.9653 16.7415L11.8388 17.3177L12.2755 16.6438L12.722 16.9368L12.2853 17.6108L13.2558 18.2359L12.9161 18.7438L10.6353 17.2494ZM12.3823 17.1029C12.2239 17.0322 12.0826 16.9278 11.9683 16.7967C11.854 16.6655 11.7694 16.511 11.7205 16.3437C11.6715 16.1764 11.6594 16.0003 11.6848 15.8278C11.7103 15.6553 11.7727 15.4904 11.8679 15.3447C11.9631 15.1982 12.0888 15.0743 12.2362 14.9814C12.3836 14.8885 12.5492 14.8289 12.7217 14.8066C12.8942 14.7843 13.0695 14.7999 13.2354 14.8524C13.4013 14.9048 13.554 14.9928 13.6829 15.1103C13.8404 15.1876 13.9796 15.2981 14.0909 15.4343C14.2023 15.5705 14.2833 15.7292 14.3284 15.8996C14.3735 16.0701 14.3816 16.2483 14.3523 16.4222C14.323 16.596 14.2569 16.7616 14.1585 16.9075C14.059 17.0437 13.9324 17.1575 13.7869 17.2419C13.6414 17.3263 13.48 17.3793 13.313 17.3977C13.1461 17.4161 12.9771 17.3994 12.8169 17.3486C12.6567 17.2978 12.5087 17.2142 12.3823 17.1029ZM13.3529 15.6377C12.9064 15.3447 12.5279 15.3349 12.3241 15.6377C12.1203 15.9405 12.3241 16.2921 12.7317 16.6145C13.1394 16.9368 13.5858 16.9368 13.7897 16.6145C13.9935 16.2921 13.7411 15.9307 13.285 15.6377H13.3529Z" fill="#E57C13"/>
    <path d="M13.5081 13.3716L13.9642 12.9614L14.6534 13.7428L15.3425 13.1274L14.6631 12.346L15.1095 11.9358L16.9051 13.9772L16.4489 14.3777L15.7016 13.5377L15.0028 14.153L15.7501 15.0028L15.2939 15.4033L13.5081 13.3716Z" fill="#E57C13"/>
    <path d="M16.323 11.0469L16.8665 10.7636L17.3227 11.6427L17.5556 11.5157C18.0992 11.2325 18.6039 11.2325 18.8756 11.7697C19.1474 12.3069 18.8756 12.7465 18.3418 13.0493L17.5751 13.4497L16.323 11.0469ZM18.0798 12.6293C18.3612 12.4827 18.4777 12.2972 18.3515 12.053C18.2254 11.8088 18.0409 11.8088 17.7401 11.9748L17.5459 12.0725L17.8856 12.7367L18.0798 12.6293ZM18.3515 9.98222L18.8951 9.69896L20.1471 12.1018L19.6036 12.3948L18.3515 9.98222Z" fill="#E57C13"/>
    <path d="M20.2832 9.23012L20.8753 9.08361L21.1179 10.0701C21.1863 10.3928 21.2381 10.7189 21.2732 11.0469L21.3994 10.412L21.7779 8.93709L22.4088 8.78081L23.03 11.4181L22.4379 11.5646L22.2244 10.5878C22.1544 10.2654 22.1026 9.93933 22.0691 9.61106L21.9429 10.246L21.5644 11.7209L20.9335 11.8772L20.2832 9.23012ZM20.4579 8.37057L20.9529 8.25336C21.0014 8.40964 21.0888 8.49755 21.2344 8.45848C21.38 8.41941 21.4188 8.31196 21.3897 8.14591L21.8847 8.0287C21.9429 8.35103 21.8167 8.6343 21.3023 8.76128C20.7879 8.88825 20.5453 8.68313 20.4579 8.37057Z" fill="#E57C13"/>
    <path d="M25.6795 9.09339L24.9419 9.04455V8.53664L27.0384 8.65385V9.17153L26.291 9.09339L26.1648 11.2911H25.5534L25.6795 9.09339Z" fill="#E57C13"/>
    <path d="M27.5527 11.4474L27.7857 10.959C27.8458 10.9954 27.9113 11.0218 27.9798 11.0372C28.0276 11.0537 28.079 11.0571 28.1285 11.0467C28.1781 11.0364 28.2239 11.0129 28.2613 10.9786L28.3195 10.9102L27.9119 8.74176L28.533 8.89804L28.6495 9.74783L28.7077 10.4316L29.0668 9.85527L29.5424 9.16177L30.1345 9.30828L28.8824 11.1348C28.7904 11.305 28.6462 11.4407 28.4713 11.5217C28.2964 11.6027 28.1001 11.6246 27.9119 11.5841C27.7852 11.5596 27.6638 11.5133 27.5527 11.4474Z" fill="#E57C13"/>
    <path d="M31.1244 9.67946L31.9688 10.0799C32.5026 10.3339 32.8423 10.7246 32.5705 11.3204C32.5096 11.4361 32.4262 11.5383 32.3253 11.6209C32.2244 11.7035 32.108 11.7649 31.9831 11.8013C31.8581 11.8378 31.7272 11.8486 31.598 11.8331C31.4688 11.8176 31.3441 11.7761 31.2311 11.7111L30.9302 11.5646L30.542 12.3949L29.9888 12.1409L31.1244 9.67946ZM31.3961 11.252C31.7067 11.3888 31.9105 11.3204 32.027 11.0665C32.1435 10.8125 32.027 10.6465 31.7261 10.4999L31.4738 10.3827L31.1341 11.1251L31.3961 11.252Z" fill="#E57C13"/>
    <path d="M34.0262 11.1934L34.5114 11.5548L33.9388 12.3167C33.7447 12.5804 33.4729 12.8442 33.2788 13.059L33.8514 12.7465L35.2297 12.1018L35.7441 12.4925L34.1232 14.6707L33.6379 14.2995L34.2203 13.5377C34.4144 13.2837 34.6667 13.02 34.8706 12.7953L34.2979 13.1079L32.9197 13.7525L32.4053 13.3618L34.0262 11.1934Z" fill="#E57C13"/>
    <path d="M35.6764 14.2995C35.7975 14.1605 35.9468 14.0491 36.1142 13.9727C36.2816 13.8964 36.4632 13.8569 36.647 13.8569C36.8307 13.8569 37.0124 13.8964 37.1798 13.9727C37.3472 14.0491 37.4964 14.1605 37.6176 14.2995C37.7172 14.4156 37.7926 14.5506 37.8393 14.6966C37.886 14.8426 37.903 14.9966 37.8893 15.1493H37.3846C37.3988 14.9663 37.3433 14.7847 37.2293 14.6414C36.9867 14.3581 36.5499 14.3484 36.1423 14.7098C35.7346 15.0712 35.6473 15.4814 35.9093 15.7842C35.9775 15.8582 36.0601 15.9172 36.1519 15.9575C36.2438 15.9978 36.3429 16.0187 36.4432 16.0186L36.3655 16.5168C36.1997 16.5183 36.0355 16.4832 35.8846 16.414C35.7337 16.3448 35.5997 16.2432 35.492 16.1163C35.3815 15.9866 35.2984 15.8357 35.2477 15.6727C35.1969 15.5097 35.1797 15.338 35.1969 15.1681C35.2142 14.9982 35.2656 14.8336 35.348 14.6843C35.4304 14.5349 35.5421 14.404 35.6764 14.2995Z" fill="#E57C13"/>
    <path d="M38.5107 16.6438L38.1419 15.9991L38.5884 15.7354L39.6463 17.5619L39.1999 17.8159L38.831 17.1712L36.8899 18.275L36.6472 17.7475L38.5107 16.6438Z" fill="#E57C13"/>
    </g>
    <rect x="-1039.5" y="-560.5" width="1768" height="796" rx="4.5" stroke="#7B61FF" strokeDasharray="10 5"/>
    <defs>
    <linearGradient id="paint0_linear" x1="19.0311" y1="16.927" x2="28.6402" y2="29.762" gradientUnits="userSpaceOnUse">
    <stop stopColor="white"/>
    <stop offset="0.03" stopColor="#FEFEFF"/>
    <stop offset="1" stopColor="#F1ECFF"/>
    </linearGradient>
    <linearGradient id="paint1_linear" x1="18.3903" y1="10.4511" x2="27.0651" y2="26.6001" gradientUnits="userSpaceOnUse">
    <stop stopColor="white" stopOpacity="0"/>
    <stop offset="0.35" stopColor="white" stopOpacity="0.01"/>
    <stop offset="0.49" stopColor="white" stopOpacity="0.03"/>
    <stop offset="0.59" stopColor="white" stopOpacity="0.08"/>
    <stop offset="0.67" stopColor="white" stopOpacity="0.15"/>
    <stop offset="0.74" stopColor="white" stopOpacity="0.24"/>
    <stop offset="0.8" stopColor="white" stopOpacity="0.35"/>
    <stop offset="0.85" stopColor="white" stopOpacity="0.47"/>
    <stop offset="0.9" stopColor="white" stopOpacity="0.62"/>
    <stop offset="0.95" stopColor="white" stopOpacity="0.79"/>
    <stop offset="1" stopColor="white"/>
    </linearGradient>
    <clipPath id="clip0">
    <rect width="50" height="50" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);

export default IconJuniorTourist1;
