/* eslint-disable max-len */
import { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const Rabbit: FC<SvgIconProps> = (props) => (
  <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M27.73 21.89C27.73 21.44 27.69 20.99 27.61 20.56C27.35 18.83 26.56 15.74 24.12 13.24C23.57 12.67 22.99 12.2 22.4 11.81L23.62 5.2C23.92 3.54 22.64 2 20.95 2C19.45 2 18.24 3.21 18.24 4.71V10.11C16.92 9.85 16.01 9.89 16.01 9.89C16.01 9.89 15.31 9.85 14.25 10.02V4.71C14.25 3.21 13.04 2 11.54 2C9.84002 2 8.56002 3.54 8.87002 5.2L10.04 11.54C9.31002 11.98 8.58002 12.54 7.89002 13.24C5.46002 15.74 4.66002 18.82 4.40002 20.56C4.32002 21 4.28002 21.44 4.27002 21.9C4.27002 21.91 4.27002 21.92 4.27002 21.92C4.27002 24.8 5.94002 27.28 8.35002 28.47C8.35002 28.47 10.97 30 15.99 30C21.02 30 23.63 28.47 23.63 28.47C26.05 27.28 27.71 24.8 27.71 21.92C27.73 21.92 27.73 21.91 27.73 21.89Z"
      fill="url(#paint0_radial_64_90019)"
    />
    <path
      d="M27.73 21.89C27.73 21.44 27.69 20.99 27.61 20.56C27.35 18.83 26.56 15.74 24.12 13.24C23.57 12.67 22.99 12.2 22.4 11.81L23.62 5.2C23.92 3.54 22.64 2 20.95 2C19.45 2 18.24 3.21 18.24 4.71V10.11C16.92 9.85 16.01 9.89 16.01 9.89C16.01 9.89 15.31 9.85 14.25 10.02V4.71C14.25 3.21 13.04 2 11.54 2C9.84002 2 8.56002 3.54 8.87002 5.2L10.04 11.54C9.31002 11.98 8.58002 12.54 7.89002 13.24C5.46002 15.74 4.66002 18.82 4.40002 20.56C4.32002 21 4.28002 21.44 4.27002 21.9C4.27002 21.91 4.27002 21.92 4.27002 21.92C4.27002 24.8 5.94002 27.28 8.35002 28.47C8.35002 28.47 10.97 30 15.99 30C21.02 30 23.63 28.47 23.63 28.47C26.05 27.28 27.71 24.8 27.71 21.92C27.73 21.92 27.73 21.91 27.73 21.89Z"
      fill="url(#paint1_radial_64_90019)"
    />
    <path
      d="M27.73 21.89C27.73 21.44 27.69 20.99 27.61 20.56C27.35 18.83 26.56 15.74 24.12 13.24C23.57 12.67 22.99 12.2 22.4 11.81L23.62 5.2C23.92 3.54 22.64 2 20.95 2C19.45 2 18.24 3.21 18.24 4.71V10.11C16.92 9.85 16.01 9.89 16.01 9.89C16.01 9.89 15.31 9.85 14.25 10.02V4.71C14.25 3.21 13.04 2 11.54 2C9.84002 2 8.56002 3.54 8.87002 5.2L10.04 11.54C9.31002 11.98 8.58002 12.54 7.89002 13.24C5.46002 15.74 4.66002 18.82 4.40002 20.56C4.32002 21 4.28002 21.44 4.27002 21.9C4.27002 21.91 4.27002 21.92 4.27002 21.92C4.27002 24.8 5.94002 27.28 8.35002 28.47C8.35002 28.47 10.97 30 15.99 30C21.02 30 23.63 28.47 23.63 28.47C26.05 27.28 27.71 24.8 27.71 21.92C27.73 21.92 27.73 21.91 27.73 21.89Z"
      fill="url(#paint2_radial_64_90019)"
    />
    <path
      d="M27.73 21.89C27.73 21.44 27.69 20.99 27.61 20.56C27.35 18.83 26.56 15.74 24.12 13.24C23.57 12.67 22.99 12.2 22.4 11.81L23.62 5.2C23.92 3.54 22.64 2 20.95 2C19.45 2 18.24 3.21 18.24 4.71V10.11C16.92 9.85 16.01 9.89 16.01 9.89C16.01 9.89 15.31 9.85 14.25 10.02V4.71C14.25 3.21 13.04 2 11.54 2C9.84002 2 8.56002 3.54 8.87002 5.2L10.04 11.54C9.31002 11.98 8.58002 12.54 7.89002 13.24C5.46002 15.74 4.66002 18.82 4.40002 20.56C4.32002 21 4.28002 21.44 4.27002 21.9C4.27002 21.91 4.27002 21.92 4.27002 21.92C4.27002 24.8 5.94002 27.28 8.35002 28.47C8.35002 28.47 10.97 30 15.99 30C21.02 30 23.63 28.47 23.63 28.47C26.05 27.28 27.71 24.8 27.71 21.92C27.73 21.92 27.73 21.91 27.73 21.89Z"
      fill="url(#paint3_radial_64_90019)"
    />
    <path
      d="M27.73 21.89C27.73 21.44 27.69 20.99 27.61 20.56C27.35 18.83 26.56 15.74 24.12 13.24C23.57 12.67 22.99 12.2 22.4 11.81L23.62 5.2C23.92 3.54 22.64 2 20.95 2C19.45 2 18.24 3.21 18.24 4.71V10.11C16.92 9.85 16.01 9.89 16.01 9.89C16.01 9.89 15.31 9.85 14.25 10.02V4.71C14.25 3.21 13.04 2 11.54 2C9.84002 2 8.56002 3.54 8.87002 5.2L10.04 11.54C9.31002 11.98 8.58002 12.54 7.89002 13.24C5.46002 15.74 4.66002 18.82 4.40002 20.56C4.32002 21 4.28002 21.44 4.27002 21.9C4.27002 21.91 4.27002 21.92 4.27002 21.92C4.27002 24.8 5.94002 27.28 8.35002 28.47C8.35002 28.47 10.97 30 15.99 30C21.02 30 23.63 28.47 23.63 28.47C26.05 27.28 27.71 24.8 27.71 21.92C27.73 21.92 27.73 21.91 27.73 21.89Z"
      fill="url(#paint4_radial_64_90019)"
    />
    <path
      d="M27.73 21.89C27.73 21.44 27.69 20.99 27.61 20.56C27.35 18.83 26.56 15.74 24.12 13.24C23.57 12.67 22.99 12.2 22.4 11.81L23.62 5.2C23.92 3.54 22.64 2 20.95 2C19.45 2 18.24 3.21 18.24 4.71V10.11C16.92 9.85 16.01 9.89 16.01 9.89C16.01 9.89 15.31 9.85 14.25 10.02V4.71C14.25 3.21 13.04 2 11.54 2C9.84002 2 8.56002 3.54 8.87002 5.2L10.04 11.54C9.31002 11.98 8.58002 12.54 7.89002 13.24C5.46002 15.74 4.66002 18.82 4.40002 20.56C4.32002 21 4.28002 21.44 4.27002 21.9C4.27002 21.91 4.27002 21.92 4.27002 21.92C4.27002 24.8 5.94002 27.28 8.35002 28.47C8.35002 28.47 10.97 30 15.99 30C21.02 30 23.63 28.47 23.63 28.47C26.05 27.28 27.71 24.8 27.71 21.92C27.73 21.92 27.73 21.91 27.73 21.89Z"
      fill="url(#paint5_radial_64_90019)"
    />
    <path
      d="M27.73 21.89C27.73 21.44 27.69 20.99 27.61 20.56C27.35 18.83 26.56 15.74 24.12 13.24C23.57 12.67 22.99 12.2 22.4 11.81L23.62 5.2C23.92 3.54 22.64 2 20.95 2C19.45 2 18.24 3.21 18.24 4.71V10.11C16.92 9.85 16.01 9.89 16.01 9.89C16.01 9.89 15.31 9.85 14.25 10.02V4.71C14.25 3.21 13.04 2 11.54 2C9.84002 2 8.56002 3.54 8.87002 5.2L10.04 11.54C9.31002 11.98 8.58002 12.54 7.89002 13.24C5.46002 15.74 4.66002 18.82 4.40002 20.56C4.32002 21 4.28002 21.44 4.27002 21.9C4.27002 21.91 4.27002 21.92 4.27002 21.92C4.27002 24.8 5.94002 27.28 8.35002 28.47C8.35002 28.47 10.97 30 15.99 30C21.02 30 23.63 28.47 23.63 28.47C26.05 27.28 27.71 24.8 27.71 21.92C27.73 21.92 27.73 21.91 27.73 21.89Z"
      fill="url(#paint6_radial_64_90019)"
    />
    <path
      d="M27.73 21.89C27.73 21.44 27.69 20.99 27.61 20.56C27.35 18.83 26.56 15.74 24.12 13.24C23.57 12.67 22.99 12.2 22.4 11.81L23.62 5.2C23.92 3.54 22.64 2 20.95 2C19.45 2 18.24 3.21 18.24 4.71V10.11C16.92 9.85 16.01 9.89 16.01 9.89C16.01 9.89 15.31 9.85 14.25 10.02V4.71C14.25 3.21 13.04 2 11.54 2C9.84002 2 8.56002 3.54 8.87002 5.2L10.04 11.54C9.31002 11.98 8.58002 12.54 7.89002 13.24C5.46002 15.74 4.66002 18.82 4.40002 20.56C4.32002 21 4.28002 21.44 4.27002 21.9C4.27002 21.91 4.27002 21.92 4.27002 21.92C4.27002 24.8 5.94002 27.28 8.35002 28.47C8.35002 28.47 10.97 30 15.99 30C21.02 30 23.63 28.47 23.63 28.47C26.05 27.28 27.71 24.8 27.71 21.92C27.73 21.92 27.73 21.91 27.73 21.89Z"
      fill="url(#paint7_radial_64_90019)"
    />
    <path
      d="M21.9675 29.1502V17.4365L9.99165 11.5684C9.27779 12.0038 8.56478 12.5545 7.89002 13.239C5.46002 15.739 4.66002 18.819 4.40002 20.559C4.32002 20.999 4.28002 21.439 4.27002 21.899V21.919C4.27002 24.799 5.94002 27.279 8.35002 28.469C8.35002 28.469 10.97 29.999 15.99 29.999C18.6891 29.999 20.6914 29.5585 21.9675 29.1502Z"
      fill="url(#paint8_radial_64_90019)"
    />
    <path
      d="M21.9675 29.1502V17.4365L9.99165 11.5684C9.27779 12.0038 8.56478 12.5545 7.89002 13.239C5.46002 15.739 4.66002 18.819 4.40002 20.559C4.32002 20.999 4.28002 21.439 4.27002 21.899V21.919C4.27002 24.799 5.94002 27.279 8.35002 28.469C8.35002 28.469 10.97 29.999 15.99 29.999C18.6891 29.999 20.6914 29.5585 21.9675 29.1502Z"
      fill="url(#paint9_radial_64_90019)"
    />
    <path
      d="M14.25 7.415H9.27873L8.86997 5.2C8.55995 3.54 9.83997 2 11.54 2C13.04 2 14.25 3.21 14.25 4.71V7.415Z"
      fill="url(#paint10_radial_64_90019)"
    />
    <path
      d="M10.0421 11.2277C10.2454 12.2732 11.2325 12.976 12.287 12.826L12.5304 12.7913C13.5162 12.6511 14.2487 11.807 14.2487 10.8113V5.48985C14.2487 5.48881 14.2489 5.48779 14.2493 5.48685C14.2497 5.4859 14.25 5.48488 14.25 5.48385V4.71C14.25 3.21 13.04 2 11.54 2C9.83996 2 8.55996 3.54 8.86996 5.2L10.0421 11.2277Z"
      fill="url(#paint11_linear_64_90019)"
    />
    <path
      d="M22.4767 11.2316C22.2734 12.2772 21.2863 12.9799 20.2318 12.8299L19.9884 12.7953C19.0026 12.655 18.2701 11.8109 18.2701 10.8152V5.49375C18.2701 5.49272 18.2699 5.4917 18.2695 5.49075C18.269 5.48981 18.2688 5.48879 18.2688 5.48775V4.71391C18.2688 3.21391 19.4788 2.00391 20.9788 2.00391C22.6788 2.00391 23.9588 3.54391 23.6488 5.20391L22.4767 11.2316Z"
      fill="url(#paint12_linear_64_90019)"
    />
    <path
      d="M22.4767 11.2316C22.2734 12.2772 21.2863 12.9799 20.2318 12.8299L19.9884 12.7953C19.0026 12.655 18.2701 11.8109 18.2701 10.8152V5.49375C18.2701 5.49272 18.2699 5.4917 18.2695 5.49075C18.269 5.48981 18.2688 5.48879 18.2688 5.48775V4.71391C18.2688 3.21391 19.4788 2.00391 20.9788 2.00391C22.6788 2.00391 23.9588 3.54391 23.6488 5.20391L22.4767 11.2316Z"
      fill="url(#paint13_linear_64_90019)"
    />
    <path
      d="M22.4767 11.2316C22.2734 12.2772 21.2863 12.9799 20.2318 12.8299L19.9884 12.7953C19.0026 12.655 18.2701 11.8109 18.2701 10.8152V5.49375C18.2701 5.49272 18.2699 5.4917 18.2695 5.49075C18.269 5.48981 18.2688 5.48879 18.2688 5.48775V4.71391C18.2688 3.21391 19.4788 2.00391 20.9788 2.00391C22.6788 2.00391 23.9588 3.54391 23.6488 5.20391L22.4767 11.2316Z"
      fill="url(#paint14_radial_64_90019)"
    />
    <path
      d="M22.4767 11.2316C22.2734 12.2772 21.2863 12.9799 20.2318 12.8299L19.9884 12.7953C19.0026 12.655 18.2701 11.8109 18.2701 10.8152V5.49375C18.2701 5.49272 18.2699 5.4917 18.2695 5.49075C18.269 5.48981 18.2688 5.48879 18.2688 5.48775V4.71391C18.2688 3.21391 19.4788 2.00391 20.9788 2.00391C22.6788 2.00391 23.9588 3.54391 23.6488 5.20391L22.4767 11.2316Z"
      fill="url(#paint15_radial_64_90019)"
    />
    <path
      d="M22.4767 11.2316C22.2734 12.2772 21.2863 12.9799 20.2318 12.8299L19.9884 12.7953C19.0026 12.655 18.2701 11.8109 18.2701 10.8152V5.49375C18.2701 5.49272 18.2699 5.4917 18.2695 5.49075C18.269 5.48981 18.2688 5.48879 18.2688 5.48775V4.71391C18.2688 3.21391 19.4788 2.00391 20.9788 2.00391C22.6788 2.00391 23.9588 3.54391 23.6488 5.20391L22.4767 11.2316Z"
      fill="url(#paint16_linear_64_90019)"
    />
    <path
      d="M18.2376 7.415H23.2088L23.6176 5.2C23.9276 3.54 22.6476 2 20.9476 2C19.4476 2 18.2376 3.21 18.2376 4.71V7.415Z"
      fill="url(#paint17_radial_64_90019)"
    />
    <path
      d="M12.0701 11.33C11.7401 11.33 11.4701 11.1 11.4101 10.78L10.3301 4.93C10.2401 4.44 10.4601 4.09 10.5901 3.93C10.7301 3.77 11.0301 3.5 11.5201 3.5C12.1901 3.5 12.7501 4.07 12.7501 4.74V10.66C12.7501 11.02 12.4501 11.33 12.0701 11.33Z"
      fill="url(#paint18_radial_64_90019)"
    />
    <path
      d="M12.0701 11.33C11.7401 11.33 11.4701 11.1 11.4101 10.78L10.3301 4.93C10.2401 4.44 10.4601 4.09 10.5901 3.93C10.7301 3.77 11.0301 3.5 11.5201 3.5C12.1901 3.5 12.7501 4.07 12.7501 4.74V10.66C12.7501 11.02 12.4501 11.33 12.0701 11.33Z"
      fill="url(#paint19_radial_64_90019)"
    />
    <path
      d="M12.0701 11.33C11.7401 11.33 11.4701 11.1 11.4101 10.78L10.3301 4.93C10.2401 4.44 10.4601 4.09 10.5901 3.93C10.7301 3.77 11.0301 3.5 11.5201 3.5C12.1901 3.5 12.7501 4.07 12.7501 4.74V10.66C12.7501 11.02 12.4501 11.33 12.0701 11.33Z"
      fill="url(#paint20_linear_64_90019)"
    />
    <path
      d="M19.73 10.65V4.74C19.73 4.07 20.28 3.5 20.96 3.5C21.45 3.5 21.75 3.78 21.89 3.94C22.03 4.1 22.24 4.45 22.15 4.94L21.07 10.78C21.01 11.1 20.73 11.33 20.41 11.33C20.03 11.33 19.73 11.02 19.73 10.65Z"
      fill="url(#paint21_radial_64_90019)"
    />
    <path
      d="M19.73 10.65V4.74C19.73 4.07 20.28 3.5 20.96 3.5C21.45 3.5 21.75 3.78 21.89 3.94C22.03 4.1 22.24 4.45 22.15 4.94L21.07 10.78C21.01 11.1 20.73 11.33 20.41 11.33C20.03 11.33 19.73 11.02 19.73 10.65Z"
      fill="url(#paint22_radial_64_90019)"
    />
    <path
      d="M19.73 10.65V4.74C19.73 4.07 20.28 3.5 20.96 3.5C21.45 3.5 21.75 3.78 21.89 3.94C22.03 4.1 22.24 4.45 22.15 4.94L21.07 10.78C21.01 11.1 20.73 11.33 20.41 11.33C20.03 11.33 19.73 11.02 19.73 10.65Z"
      fill="url(#paint23_linear_64_90019)"
    />
    <path
      d="M22.84 23.13L29.58 24.94C29.85 25.01 30 25.28 29.93 25.55C29.86 25.82 29.59 25.97 29.32 25.9L22.58 24.09C22.31 24.02 22.16 23.75 22.23 23.48C22.3 23.21 22.57 23.05 22.84 23.13Z"
      fill="url(#paint24_linear_64_90019)"
    />
    <path
      d="M22.73 20.9805H29.71C29.99 20.9805 30.21 21.2005 30.21 21.4805C30.21 21.7605 29.99 21.9805 29.71 21.9805H22.73C22.45 21.9805 22.23 21.7605 22.23 21.4805C22.23 21.2105 22.45 20.9805 22.73 20.9805Z"
      fill="url(#paint25_linear_64_90019)"
    />
    <path
      d="M9.65012 23.1301L2.91012 24.9401C2.64012 25.0001 2.48012 25.2801 2.55012 25.5401C2.62012 25.8101 2.89012 25.9601 3.16012 25.8901L9.90012 24.0801C10.1701 24.0101 10.3201 23.7401 10.2501 23.4701C10.1901 23.2101 9.91012 23.0501 9.65012 23.1301Z"
      fill="url(#paint26_linear_64_90019)"
    />
    <path
      d="M9.76003 20.9805H2.78003C2.50003 20.9805 2.28003 21.2005 2.28003 21.4805C2.28003 21.7605 2.50003 21.9805 2.78003 21.9805H9.76003C10.04 21.9805 10.26 21.7605 10.26 21.4805C10.26 21.2105 10.03 20.9805 9.76003 20.9805Z"
      fill="url(#paint27_linear_64_90019)"
    />
    <path
      d="M16.23 23.5195C16.23 24.5645 15.45 25.4136 14.49 25.4136C13.53 25.4136 12.75 24.5645 12.75 23.5195"
      stroke="url(#paint28_radial_64_90019)"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M19.72 23.5195C19.72 24.5645 18.94 25.4136 17.98 25.4136C17.02 25.4136 16.24 24.5645 16.24 23.5195"
      stroke="url(#paint29_radial_64_90019)"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M13.25 19.9645C12.85 19.9645 12.52 19.6345 12.52 19.2345V17.7163C12.52 17.3163 12.85 16.9863 13.25 16.9863C13.65 16.9863 13.98 17.3163 13.98 17.7163V19.2345C13.98 19.6445 13.65 19.9645 13.25 19.9645Z"
      fill="url(#paint30_linear_64_90019)"
    />
    <path
      d="M13.25 19.9645C12.85 19.9645 12.52 19.6345 12.52 19.2345V17.7163C12.52 17.3163 12.85 16.9863 13.25 16.9863C13.65 16.9863 13.98 17.3163 13.98 17.7163V19.2345C13.98 19.6445 13.65 19.9645 13.25 19.9645Z"
      fill="url(#paint31_linear_64_90019)"
    />
    <path
      d="M13.25 19.9645C12.85 19.9645 12.52 19.6345 12.52 19.2345V17.7163C12.52 17.3163 12.85 16.9863 13.25 16.9863C13.65 16.9863 13.98 17.3163 13.98 17.7163V19.2345C13.98 19.6445 13.65 19.9645 13.25 19.9645Z"
      fill="url(#paint32_radial_64_90019)"
    />
    <path
      d="M17.8311 21.0649C17.8311 21.4911 16.9014 22.5538 16.2529 22.5538C15.5592 22.5538 14.6592 21.584 14.6592 21.0649C14.6592 20.5459 15.3623 20.498 16.2529 20.498C17.1436 20.498 17.8311 20.5322 17.8311 21.0649Z"
      fill="url(#paint33_linear_64_90019)"
    />
    <path
      d="M17.8311 21.0649C17.8311 21.4911 16.9014 22.5538 16.2529 22.5538C15.5592 22.5538 14.6592 21.584 14.6592 21.0649C14.6592 20.5459 15.3623 20.498 16.2529 20.498C17.1436 20.498 17.8311 20.5322 17.8311 21.0649Z"
      fill="url(#paint34_radial_64_90019)"
    />
    <path
      d="M17.8311 21.0649C17.8311 21.4911 16.9014 22.5538 16.2529 22.5538C15.5592 22.5538 14.6592 21.584 14.6592 21.0649C14.6592 20.5459 15.3623 20.498 16.2529 20.498C17.1436 20.498 17.8311 20.5322 17.8311 21.0649Z"
      fill="url(#paint35_radial_64_90019)"
    />
    <path
      d="M19.25 19.9645C18.85 19.9645 18.52 19.6345 18.52 19.2345V17.7163C18.52 17.3163 18.85 16.9863 19.25 16.9863C19.65 16.9863 19.98 17.3163 19.98 17.7163V19.2345C19.98 19.6445 19.65 19.9645 19.25 19.9645Z"
      fill="url(#paint36_linear_64_90019)"
    />
    <path
      d="M19.25 19.9645C18.85 19.9645 18.52 19.6345 18.52 19.2345V17.7163C18.52 17.3163 18.85 16.9863 19.25 16.9863C19.65 16.9863 19.98 17.3163 19.98 17.7163V19.2345C19.98 19.6445 19.65 19.9645 19.25 19.9645Z"
      fill="url(#paint37_linear_64_90019)"
    />
    <path
      d="M19.25 19.9645C18.85 19.9645 18.52 19.6345 18.52 19.2345V17.7163C18.52 17.3163 18.85 16.9863 19.25 16.9863C19.65 16.9863 19.98 17.3163 19.98 17.7163V19.2345C19.98 19.6445 19.65 19.9645 19.25 19.9645Z"
      fill="url(#paint38_radial_64_90019)"
    />
    <g filter="url(#filter0_f_64_90019)">
      <circle cx="9.84204" cy="21.3438" r="0.183594" fill="#D6CEDE" />
    </g>
    <g filter="url(#filter1_f_64_90019)">
      <circle cx="29.7634" cy="21.3438" r="0.183594" fill="#D6CEDE" />
    </g>
    <g filter="url(#filter2_f_64_90019)">
      <circle cx="29.5876" cy="25.293" r="0.183594" fill="#D6CEDE" fillOpacity="0.75" />
    </g>
    <g filter="url(#filter3_f_64_90019)">
      <circle cx="9.81567" cy="23.459" r="0.183594" fill="#D6CEDE" fillOpacity="0.75" />
    </g>
    <g filter="url(#filter4_f_64_90019)">
      <path
        d="M12.75 23.5195C12.7917 24.0404 13.2844 25.1008 14.3594 25.1758C15.7031 25.2695 16.1719 24.207 16.2188 23.5195C16.1927 24.0716 16.7969 25.2539 18.0625 25.2539C19.15 25.2539 19.6615 24.0716 19.7188 23.5195"
        stroke="url(#paint39_linear_64_90019)"
        strokeWidth="0.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_64_90019"
        x="9.40845"
        y="20.9102"
        width="0.867188"
        height="0.867188"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.125" result="effect1_foregroundBlur_64_90019" />
      </filter>
      <filter
        id="filter1_f_64_90019"
        x="29.3298"
        y="20.9102"
        width="0.867188"
        height="0.867188"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.125" result="effect1_foregroundBlur_64_90019" />
      </filter>
      <filter
        id="filter2_f_64_90019"
        x="29.1541"
        y="24.8594"
        width="0.867188"
        height="0.867188"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.125" result="effect1_foregroundBlur_64_90019" />
      </filter>
      <filter
        id="filter3_f_64_90019"
        x="9.38208"
        y="23.0254"
        width="0.867188"
        height="0.867188"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.125" result="effect1_foregroundBlur_64_90019" />
      </filter>
      <filter
        id="filter4_f_64_90019"
        x="12.375"
        y="23.1445"
        width="7.71875"
        height="2.48438"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.125" result="effect1_foregroundBlur_64_90019" />
      </filter>
      <radialGradient
        id="paint0_radial_64_90019"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(24.457 12.1973) rotate(132.256) scale(14.7823)"
      >
        <stop stopColor="#E6E6E6" />
        <stop offset="1" stopColor="#D3D3D3" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_64_90019"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(21.8552 12.6643) rotate(134.095) scale(20.9005 24.4853)"
      >
        <stop offset="0.621351" stopColor="#CCC9CF" stopOpacity="0" />
        <stop offset="1" stopColor="#CCC9CF" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_64_90019"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(28.1262 14.7992) rotate(144.002) scale(9.64789 8.08355)"
      >
        <stop stopColor="#F7F5FA" />
        <stop offset="1" stopColor="#F7F5FA" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint3_radial_64_90019"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(19.1866 12.264) rotate(87.0746) scale(20.1998 29.0575)"
      >
        <stop offset="0.550643" stopColor="#B59AD0" stopOpacity="0" />
        <stop offset="1" stopColor="#B59AD0" />
      </radialGradient>
      <radialGradient
        id="paint4_radial_64_90019"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(19.1866 12.264) rotate(92.0005) scale(18.8099 27.2611)"
      >
        <stop offset="0.921768" stopColor="#D6B8E0" stopOpacity="0" />
        <stop offset="1" stopColor="#D6B8E0" />
      </radialGradient>
      <radialGradient
        id="paint5_radial_64_90019"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(14.2488 2.15625) rotate(118.752) scale(4.02786 5.37368)"
      >
        <stop stopColor="#E7E3E7" />
        <stop offset="1" stopColor="#E7E3E7" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint6_radial_64_90019"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(7.28001 22.8125) rotate(16.9837) scale(13.2661 17.4906)"
      >
        <stop stopColor="#CDCAD2" />
        <stop offset="1" stopColor="#CDCAD2" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint7_radial_64_90019"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(22.9675 23.0625) rotate(90) scale(3.71875 5.46875)"
      >
        <stop stopColor="#D3C8DF" />
        <stop offset="1" stopColor="#D2CBDA" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint8_radial_64_90019"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(24.7175 18.374) rotate(159.238) scale(22.3916 15.088)"
      >
        <stop offset="0.910988" stopColor="#E9E9E9" stopOpacity="0" />
        <stop offset="0.994952" stopColor="#E9E9E9" />
      </radialGradient>
      <radialGradient
        id="paint9_radial_64_90019"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(9.90501 30.4365) rotate(-67.3127) scale(8.33222 25.6719)"
      >
        <stop stopColor="#B59AD0" />
        <stop offset="0.880339" stopColor="#B59AD0" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint10_radial_64_90019"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(11.5367 6.51563) rotate(-90) scale(4.51563 3.49949)"
      >
        <stop offset="0.951557" stopColor="#EFEEEF" stopOpacity="0" />
        <stop offset="1" stopColor="#EFEEEF" />
      </radialGradient>
      <linearGradient
        id="paint11_linear_64_90019"
        x1="9.71745"
        y1="10.375"
        x2="13.405"
        y2="9.625"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D3CED5" />
        <stop offset="1" stopColor="#D3CED5" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_64_90019"
        x1="15.4362"
        y1="6.31641"
        x2="24.8112"
        y2="7.06641"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D3CED5" />
        <stop offset="1" stopColor="#D3CED5" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_64_90019"
        x1="20.28"
        y1="13.0977"
        x2="20.28"
        y2="7.87891"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E1E1E1" />
        <stop offset="1" stopColor="#E1E1E1" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint14_radial_64_90019"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(22.5612 12.3789) rotate(-167.047) scale(3.20659 1.5685)"
      >
        <stop stopColor="#ECEBED" />
        <stop offset="1" stopColor="#ECEBED" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint15_radial_64_90019"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(24.1862 3.94141) rotate(148.079) scale(4.49175 8.31212)"
      >
        <stop stopColor="#E7E3E7" />
        <stop offset="1" stopColor="#E7E3E7" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint16_linear_64_90019"
        x1="23.1393"
        y1="8.72266"
        x2="22.4987"
        y2="8.61328"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DDD6E1" />
        <stop offset="1" stopColor="#DFDBE1" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint17_radial_64_90019"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(20.9508 6.51563) rotate(-90) scale(4.51563 3.49949)"
      >
        <stop offset="0.951557" stopColor="#EFEEEF" stopOpacity="0" />
        <stop offset="1" stopColor="#EFEEEF" />
      </radialGradient>
      <radialGradient
        id="paint18_radial_64_90019"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(10.9989 8.90625) rotate(-100.592) scale(7.82075 1.54737)"
      >
        <stop stopColor="#FABDB6" />
        <stop offset="1" stopColor="#EEA49C" />
      </radialGradient>
      <radialGradient
        id="paint19_radial_64_90019"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(9.35935 7.59375) rotate(-62.2783) scale(5.50712 2.2337)"
      >
        <stop stopColor="#FABDB6" />
        <stop offset="1" stopColor="#FABDB6" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint20_linear_64_90019"
        x1="13.0926"
        y1="3.5"
        x2="11.5296"
        y2="6.0625"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.10924" stopColor="#F59D94" />
        <stop offset="1" stopColor="#F2A7A0" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint21_radial_64_90019"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(19.73 8.15625) rotate(-90) scale(7.84375 1.58125)"
      >
        <stop stopColor="#FABDB6" />
        <stop offset="1" stopColor="#EEA49C" />
      </radialGradient>
      <radialGradient
        id="paint22_radial_64_90019"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(18.78 7.59375) rotate(-62.2717) scale(5.50745 2.23419)"
      >
        <stop stopColor="#FABDB6" />
        <stop offset="1" stopColor="#FABDB6" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint23_linear_64_90019"
        x1="22.8112"
        y1="3.8125"
        x2="20.4993"
        y2="5.96932"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.10924" stopColor="#F59D94" />
        <stop offset="1" stopColor="#F2A7A0" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint24_linear_64_90019"
        x1="26.53"
        y1="24.3281"
        x2="26.3358"
        y2="25.0855"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BAB4C0" />
        <stop offset="1" stopColor="#9987AA" />
      </linearGradient>
      <linearGradient
        id="paint25_linear_64_90019"
        x1="26.22"
        y1="21.2192"
        x2="26.22"
        y2="22.1255"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAC6D0" />
        <stop offset="1" stopColor="#ADA0BB" />
      </linearGradient>
      <linearGradient
        id="paint26_linear_64_90019"
        x1="6.44418"
        y1="24.211"
        x2="6.65512"
        y2="25.0782"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BAB4C0" />
        <stop offset="1" stopColor="#9987AA" />
      </linearGradient>
      <linearGradient
        id="paint27_linear_64_90019"
        x1="6.27003"
        y1="21.2192"
        x2="6.27003"
        y2="22.1255"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CAC6D0" />
        <stop offset="1" stopColor="#ADA0BB" />
      </linearGradient>
      <radialGradient
        id="paint28_radial_64_90019"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(14.484 24.0777) rotate(90) scale(1.71875 2.18838)"
      >
        <stop offset="0.00558659" stopColor="#423539" />
        <stop offset="1" stopColor="#3C223C" />
      </radialGradient>
      <radialGradient
        id="paint29_radial_64_90019"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17.974 24.0777) rotate(90) scale(1.71875 2.18838)"
      >
        <stop offset="0.00558659" stopColor="#423539" />
        <stop offset="1" stopColor="#3C223C" />
      </radialGradient>
      <linearGradient
        id="paint30_linear_64_90019"
        x1="13.98"
        y1="18.4754"
        x2="13.1349"
        y2="18.4754"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.225" stopColor="#594C4F" />
        <stop offset="1" stopColor="#392833" />
      </linearGradient>
      <linearGradient
        id="paint31_linear_64_90019"
        x1="14.2081"
        y1="20.0078"
        x2="14.2081"
        y2="18.6088"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#552D52" />
        <stop offset="1" stopColor="#552D52" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint32_radial_64_90019"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(13.5341 18.6537) rotate(-109.132) scale(1.71834 1.64486)"
      >
        <stop offset="0.813951" stopColor="#594C4F" stopOpacity="0" />
        <stop offset="1" stopColor="#594C4F" />
      </radialGradient>
      <linearGradient
        id="paint33_linear_64_90019"
        x1="15.2764"
        y1="20.7234"
        x2="16.5799"
        y2="22.4275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F03273" />
        <stop offset="1" stopColor="#FF32AF" />
      </linearGradient>
      <radialGradient
        id="paint34_radial_64_90019"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17.0107 21.2288) rotate(-172.881) scale(2.53517 2.1517)"
      >
        <stop offset="0.451843" stopColor="#D53238" stopOpacity="0" />
        <stop offset="1" stopColor="#D53238" />
      </radialGradient>
      <radialGradient
        id="paint35_radial_64_90019"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17.5811 20.8942) rotate(154.691) scale(1.47779 0.801625)"
      >
        <stop stopColor="#FF8AD5" />
        <stop offset="0.663187" stopColor="#FF6CCB" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint36_linear_64_90019"
        x1="19.98"
        y1="18.4754"
        x2="19.1349"
        y2="18.4754"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.225" stopColor="#594C4F" />
        <stop offset="1" stopColor="#392833" />
      </linearGradient>
      <linearGradient
        id="paint37_linear_64_90019"
        x1="20.2081"
        y1="20.0078"
        x2="20.2081"
        y2="18.6088"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#552D52" />
        <stop offset="1" stopColor="#552D52" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint38_radial_64_90019"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(19.5341 18.6537) rotate(-109.132) scale(1.71834 1.64486)"
      >
        <stop offset="0.813951" stopColor="#594C4F" stopOpacity="0" />
        <stop offset="1" stopColor="#594C4F" />
      </radialGradient>
      <linearGradient
        id="paint39_linear_64_90019"
        x1="12.5625"
        y1="24.0195"
        x2="20.0781"
        y2="24.0195"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5F5356" />
        <stop offset="0.288981" stopColor="#5F5356" stopOpacity="0" />
        <stop offset="0.444906" stopColor="#5F5356" stopOpacity="0" />
        <stop offset="0.534304" stopColor="#5F5356" />
        <stop offset="1" stopColor="#5F5356" stopOpacity="0" />
      </linearGradient>
    </defs>
  </SvgIcon>
);

export default Rabbit;
