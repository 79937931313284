import { FC, ReactNode } from 'react';

import { ThemeProvider } from '@mui/material';
import { SectionKeys, SectionNames } from 'const';
import { useSectionTheme } from 'hooks';
import { IconSectionCreation } from 'icons';
import { creationTheme } from 'portfolio3/styles/theme';

import SearchSection from '../components/SearchSection';
import { useSearchCreation } from '../hooks';
import CommonEntityCardList from './CardLists/CommonEntityCardList';
import CommonRewardCardList from './CardLists/CommonRewardCardList';

const CreationSection: FC = () => {
  const {
    rewards,
    clubs,
    contests,
    occupations,
    isCreationRewardVisible,
    isCreationClubVisible,
    isCreationContestVisible,
    isCreationOccupationVisible,
    isCreationVisible,
  } = useSearchCreation();

  const theme = useSectionTheme(creationTheme);

  if (!isCreationVisible) return null;

  const subsections: ReactNode[] = [];

  if (isCreationRewardVisible) {
    subsections.push(
      <SearchSection.Subsection title="Награды и достижения">
        <CommonRewardCardList rewards={rewards} />
      </SearchSection.Subsection>,
    );
  }

  if (isCreationClubVisible) {
    subsections.push(
      <SearchSection.Subsection title="Творческие коллективы">
        <CommonEntityCardList entities={clubs} />
      </SearchSection.Subsection>,
    );
  }

  if (isCreationContestVisible) {
    subsections.push(
      <SearchSection.Subsection title="Конкурсы">
        <CommonEntityCardList entities={contests} />
      </SearchSection.Subsection>,
    );
  }

  if (isCreationOccupationVisible) {
    subsections.push(
      <SearchSection.Subsection title="Кружки">
        <CommonEntityCardList entities={occupations} />
      </SearchSection.Subsection>,
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <SearchSection
        name={SectionNames[SectionKeys.CREATION]}
        icon={<IconSectionCreation />}
        subsections={subsections}
      />
    </ThemeProvider>
  );
};

export default CreationSection;
