import { FC, PropsWithChildren } from 'react';

import { NeutralColors } from 'portfolio3/styles';
import { BaseBadge } from 'portfolio3/ui-kit/badges';
import { SxStyles } from 'types';

const styles: SxStyles = {
  border: '1px solid',
  borderColor: NeutralColors.night_neutrals_700,
  borderRadius: '4px',
};

const PdfBadge: FC<PropsWithChildren> = ({ children }) => {
  return (
    <BaseBadge
      textColor={NeutralColors.night_neutrals_600}
      backgroundColor={NeutralColors.night_neutrals_900}
      sx={styles}
    >
      {children}
    </BaseBadge>
  );
};

export default PdfBadge;
