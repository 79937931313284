/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, {
  useCallback,
  useContext, useMemo
} from 'react';

import { connect } from 'react-redux';
import { Box, FormHelperText } from '@mui/material';

import { IDictionaryItem, IChildInfo } from '../../../../../api/types';
import { mergeSx, validateParallel } from '../../../../../utils';
import { IRootState } from '../../../../../reducers';
import { SecondaryBlockContainer, useSecondaryBlockControllers } from '../common';

import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';
import { BaseInputLabel, FormControl, Input, Textarea } from 'portfolio3/ui-kit';
import { Select } from 'portfolio3/ui-kit/selects';
import { IController, TokenFieldController } from 'portfolio3/ui-kit/types';
import DualSwitcherControl from 'portfolio3/components/forms/DualSwitcherControl';
import AgeLimitControl from 'portfolio3/components/forms/AgeLimitControl';
import { StretchyTokenField } from 'portfolio3/ui-kit/autocomplete';
import { useDisclosure } from 'hooks';
import AdditionalInfoButton from 'portfolio3/components/forms/AdditionalInfoButton';
import { formControlsColumn, formControlsRow } from '../../commonStyles';
import { getCommonInputControllerFactory } from "../../utils";
import { getDayRangePickerControllerFactory } from '../../controllers';
import { DayRangePicker } from 'portfolio3/ui-kit/datePickers';
import { dataEntryMaxDate, dataEntryMinDate } from '../../dateErrors';
import { commonAcceptFileTypes } from 'portfolio3/features/dataEntryForm';

interface IOccupationSecondaryBlockProps {
  subCategoryOptions: IDictionaryItem[];
  nameInputLabel: string;
  nameInputPlaceholder: string;
  subjectInputLabel: string;
  subjectInputPlaceholder: string;
  currentStudent: IChildInfo;
  subjectOptions?: IDictionaryItem[];
  isSubjectInputRequired?: boolean;
  isSubjectInputDisabled?: boolean;
  withoutSubjects?: boolean;
  children?: JSX.Element;
}

const OccupationSecondaryBlock: React.FC<IOccupationSecondaryBlockProps> = ({
  subCategoryOptions,
  nameInputLabel,
  nameInputPlaceholder,
  subjectInputLabel,
  subjectInputPlaceholder,
  currentStudent,
  subjectOptions,
  isSubjectInputRequired,
  isSubjectInputDisabled,
  withoutSubjects,
  children
}) => {
  const { studentType, isMobile, inputRenderMode, inputSize, formData, formErrors, onChangeFormData, onChangeFormErrors } = useContext(LocalDataEntryFormContext);
  const { isOpen: isAdditionalInfoShownState, onToggle: onToggleAdditionalInfo } = useDisclosure();
  const isAdditionalInfoShown = isAdditionalInfoShownState || !isMobile;

  const { fileController, linkedObjects, linkedObjectController } = useSecondaryBlockControllers({
    formData,
    onChangeFormData,
    onChangeFormErrors
  });

  const inputController = getCommonInputControllerFactory(onChangeFormData, onChangeFormErrors);
  const dayRangePickerControllerFactory = getDayRangePickerControllerFactory(onChangeFormData, onChangeFormErrors);

  const subCategoryController: IController<string | undefined> = useMemo(() => ({
    handleChange: (subCategory) => {
      onChangeFormData((prevstate) => ({
        ...prevstate,
        subCategory: Number(subCategory),
        creationKindCode: undefined,
        sportKind: undefined,
        subjectId: []
      }));
      onChangeFormErrors((prevstate) => ({
        ...prevstate,
        subcategoryError: false
      }));
    },
    handleBlur: (type) => {
      if (!type) {
        onChangeFormErrors((prevstate) => ({
          ...prevstate,
          subcategoryError: true
        }));
      }
    }
  }), [formData.id]);

  const nameController = useMemo(() => inputController('secondName', 'secondNameError'), [formData.id]);

  const subjectController: TokenFieldController = useMemo(() => ({
    handleChange: (subjects) => {
      onChangeFormData((prevState) => ({
        ...prevState,
        subjectId: subjects.map((subject) => subject.code),
      }));
      onChangeFormErrors((prevState) => ({
        ...prevState,
        subjectError: subjects.length === 0,
      }));
    },
    handleBlur: (subjects) => {
      onChangeFormErrors((prevState) => ({
        ...prevState,
        subjectError: subjects.length === 0,
      }));
    },
  }), [formData.id]);

  const dateRangeController = dayRangePickerControllerFactory('startDate', 'endDate', 'dateError', { required: true });

  const classStartController = useMemo(() => ({
    handleChange: (classStart: string | undefined) => {
      const newClassStart = classStart ? validateParallel(classStart) : null;
      onChangeFormData((prevstate) => ({
        ...prevstate,
        classStart: newClassStart === undefined ? prevstate.classStart : newClassStart || undefined
      }));
    }
  }), [formData.id]);

  const classEndController = useMemo(() => ({
    handleChange: (classEnd: string | undefined) => {
      const newClassEnd = classEnd ? validateParallel(classEnd) : null;
      onChangeFormData((prevstate) => ({
        ...prevstate,
        classEnd: newClassEnd === undefined ? prevstate.classEnd : newClassEnd || undefined
      }));
    }
  }), [formData.id]);

  const placeController = useMemo(() => ({
    handleChange: (place: string) => {
      onChangeFormData((prevstate) => ({
        ...prevstate,
        place
      }));
    }
  }), [formData.id]);

  const descriptionController = useMemo(() => ({
    handleChange: (description: string) => {
      onChangeFormData((prevstate) => ({
        ...prevstate,
        description
      }));
    }
  }), [formData.id]);

  const handleChangeFormat = useCallback((value: number) => {
    onChangeFormData((prevstate) => ({
      ...prevstate,
      format: value
    }));
  }, [formData.id]);

  return (
    <SecondaryBlockContainer>
      <SecondaryBlockContainer.FilesTab
        personId={currentStudent.meshId}
        controller={fileController}
        files={formData.attachment}
        {...commonAcceptFileTypes}
      />
      <SecondaryBlockContainer.LinkedObjectsTab
        studentType={studentType}
        linkedObjectController={linkedObjectController}
        linkedObjects={linkedObjects}
        formData={formData}
      />

      <SecondaryBlockContainer.GeneralTab>
        <FormControl
          required
          error={formErrors.subcategoryError}
          renderMode={inputRenderMode}
          inputSize={inputSize}
          label={<BaseInputLabel>Подкатегория</BaseInputLabel>}
          helper={formErrors.subcategoryError && <FormHelperText>Выберите подкатегорию</FormHelperText>}
          control={
            <Select
              inputRenderMode={inputRenderMode}
              inputSize={inputSize}
              placeholder="Выберите подкатегорию..."
              value={formData.subCategory}
              options={subCategoryOptions}
              controller={subCategoryController}
            />
          }
        />
        <FormControl
          sx={{ width: '100%' }}
          required
          error={formErrors.secondNameError}
          renderMode={inputRenderMode}
          inputSize={inputSize}
          label={<BaseInputLabel>{nameInputLabel}</BaseInputLabel>}
          helper={formErrors.secondNameError && <FormHelperText>Введите название</FormHelperText>}
          control={
            <Input
              renderMode={inputRenderMode}
              size={inputSize}
              placeholder={nameInputPlaceholder}
              value={formData.secondName}
              controller={nameController}
            />
          }
        />
        {children}

        {!withoutSubjects && (
          <FormControl
            required={isSubjectInputRequired}
            disabled={isSubjectInputDisabled}
            renderMode={inputRenderMode}
            inputSize={inputSize}
            label={<BaseInputLabel>{subjectInputLabel}</BaseInputLabel>}
            control={
              <StretchyTokenField
                size={inputSize}
                inputRenderMode={inputRenderMode}
                options={subjectOptions || []}
                controller={subjectController}
                value={formData.subjectId}
                placeholder={subjectInputPlaceholder}
              />
            }
          />
        )}
        <FormControl
          required
          renderMode={inputRenderMode}
          inputSize={inputSize}
          label={
            <BaseInputLabel>
              {isMobile ? 'Даты занятий' : 'Даты занятий (дата начала или интервал)'}
            </BaseInputLabel>
          }
          error={formErrors.dateError.active}
          helper={formErrors.dateError.active && <FormHelperText>{formErrors.dateError.description}</FormHelperText>}
          control={
            <Box sx={mergeSx(!isMobile && {width: 'max-content'})}>
              <DayRangePicker
                isMobile={isMobile}
                renderMode={inputRenderMode}
                size={inputSize}
                startValue={formData.startDate}
                endValue={formData.endDate}
                controller={dateRangeController}
                minValue={dataEntryMinDate}
                maxValue={dataEntryMaxDate}
              />
            </Box>
          }
        />
        <Box sx={isMobile ? formControlsColumn : formControlsRow}>
          <DualSwitcherControl
            required
            isMobile={isMobile}
            inputRenderMode={inputRenderMode}
            inputSize={inputSize}
            label="Формат занятий"
            value={formData.format}
            firstLabel="Очно"
            secondLabel="Заочно"
            onChange={handleChangeFormat}
          />
          <AgeLimitControl
            isMobile={isMobile}
            renderMode={inputRenderMode}
            inputSize={inputSize}
            valueFrom={formData.classStart ? formData.classStart : ''}
            valueTo={formData.classEnd ? formData.classEnd : ''}
            fromController={classStartController}
            toController={classEndController}
            label={isMobile ? 'Возрастное ограничение' : 'Возрастное ограничение участников'}
            endAdornment="класс"
          />
        </Box>

        {isMobile && <AdditionalInfoButton isOpen={isAdditionalInfoShownState} onToggle={onToggleAdditionalInfo} />}
        {isAdditionalInfoShown && (
          <>
            <FormControl
              sx={{ width: '100%' }}
              renderMode={inputRenderMode}
              inputSize={inputSize}
              label={<BaseInputLabel>Место проведения</BaseInputLabel>}
              control={
                <Input
                  renderMode={inputRenderMode}
                  size={inputSize}
                  placeholder="Укажите место проведения занятий..."
                  value={formData.place}
                  controller={placeController}
                />
              }
            />
            <FormControl
              sx={{ width: '100%' }}
              renderMode={inputRenderMode}
              inputSize={inputSize}
              label={<BaseInputLabel>Описание</BaseInputLabel>}
              control={
                <Textarea
                  renderMode={inputRenderMode}
                  size={inputSize}
                  placeholder="Любое описание или примечание..."
                  value={formData.description}
                  controller={descriptionController}
                />
              }
            />
          </>
        )}
      </SecondaryBlockContainer.GeneralTab>
    </SecondaryBlockContainer>
  );
};

export default connect(
  (state: IRootState) => ({
    currentStudent: state.currentStudent
  })
)(OccupationSecondaryBlock);
