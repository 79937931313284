import { FC, useContext } from 'react';

import { Box } from '@mui/material';
import {
  DIAGNOSTIC_PERIOD_ALL_CODE,
  DiagnosticLevelColorSet2,
  DiagnosticLevelsLabelsLarge,
  IndependentDiagnosticLevelTypeKeys,
  IndependentDiagnosticLevelTypeNames,
} from 'const';
import { formatDate, getDiagnosticLevelTypeColor } from 'utils';

import CompletedDiagnosticsDiagram from '../../CompletedDiagnosticsDiagram';
import DiagnosticContext from '../../context/diagnosticContext';
import BestSubjectDiagram from '../BestSubjectDiagram';
import * as styles from './styles';

const AnalyticsDiagrams: FC = () => {
  const { filters, independentDiagnostics, independentDiagnosticPeriods } = useContext(DiagnosticContext);

  /* находим текущий период */
  const currentPeriod = independentDiagnosticPeriods.find((period) => period.code === filters.independentPeriodCode);
  /* находим объект диагностики по текущему периоду */
  const currentPeriodDiagnosticObject = independentDiagnostics.content?.find((diagnosticGroup) => {
    return diagnosticGroup.learningYear === currentPeriod?.value;
  })?.diagnostics;

  let totalDiagnostic = currentPeriodDiagnosticObject?.totalDiagnostic;
  let bestDiagnostic = currentPeriodDiagnosticObject?.bestResult;

  /* расчет при выбранном значение все периоды */
  if (filters.independentPeriodCode === DIAGNOSTIC_PERIOD_ALL_CODE) {
    const tempTotal = {
      total: 0,
      totalLevelType: {
        totalLevelBelowBase: 0,
        totalLevelBase: 0,
        totalLevelHight: 0,
        totalLevelOverHight: 0,
      },
    };
    independentDiagnostics.content?.forEach((diagnosticObject) => {
      const objectTotal = diagnosticObject.diagnostics.totalDiagnostic;
      tempTotal.total += objectTotal?.total || 0;
      tempTotal.totalLevelType.totalLevelBelowBase += objectTotal?.totalLevelType.totalLevelBelowBase || 0;
      tempTotal.totalLevelType.totalLevelBase += objectTotal?.totalLevelType.totalLevelBase || 0;
      tempTotal.totalLevelType.totalLevelHight += objectTotal?.totalLevelType.totalLevelHight || 0;
      tempTotal.totalLevelType.totalLevelOverHight += objectTotal?.totalLevelType.totalLevelOverHight || 0;
    });
    totalDiagnostic = tempTotal;

    bestDiagnostic = independentDiagnostics.content?.reduce((bestResult, currentGroup) => {
      const isBetterResult =
        Number(currentGroup.diagnostics.bestResult?.bestResultPercent) > Number(bestResult?.bestResultPercent);

      if (!bestResult || isBetterResult) {
        return currentGroup.diagnostics.bestResult;
      }

      return bestResult;
    }, currentPeriodDiagnosticObject?.bestResult);
  }

  const diagnosticLevelType = Object.entries(IndependentDiagnosticLevelTypeNames).find(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ([_key, name]) => name.toLowerCase() === bestDiagnostic?.bestResultLevelType.toLowerCase(),
  )?.[0] as IndependentDiagnosticLevelTypeKeys | undefined;
  const bestDiagramColor = getDiagnosticLevelTypeColor(
    bestDiagnostic?.bestResultLevelType || '',
    DiagnosticLevelColorSet2,
    '80',
  );
  const bestDiagramLevelType = diagnosticLevelType
    ? DiagnosticLevelsLabelsLarge[diagnosticLevelType]
    : 'базовый уровень';

  return (
    <Box sx={styles.root}>
      {totalDiagnostic && (
        <CompletedDiagnosticsDiagram
          totalLevelBelowBase={String(totalDiagnostic?.totalLevelType.totalLevelBelowBase)}
          totalLevelBase={String(totalDiagnostic?.totalLevelType.totalLevelBase)}
          totalLevelHight={String(totalDiagnostic?.totalLevelType.totalLevelHight)}
          totalLevelOverHight={String(totalDiagnostic?.totalLevelType.totalLevelOverHight)}
        />
      )}

      {bestDiagnostic && (
        <BestSubjectDiagram
          subject={bestDiagnostic.bestResultSubject}
          date={formatDate(bestDiagnostic.bestResultEventDate)}
          diagramColor={bestDiagramColor}
          resultValue={bestDiagnostic.bestResultValue}
          resultPercent={bestDiagnostic.bestResultPercent}
          maxValue={bestDiagnostic.maxResultValue}
          levelType={bestDiagramLevelType}
        />
      )}
    </Box>
  );
};

export default AnalyticsDiagrams;
