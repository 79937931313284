/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useContext, useMemo } from 'react';

import { RewardSecondaryBlock } from '../basicBlocks';
import { IDictionaryItem } from '../../../../../api/types';
import { DifferentValue, SectionCodes } from '../../../../../const';
import { validateParallel } from '../../../../../utils';

import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';
import { BaseInputLabel, FormControl, Input } from 'portfolio3/ui-kit';
import { Select } from 'portfolio3/ui-kit/selects';
import { FormHelperText } from '@mui/material';
import { IController } from 'portfolio3/ui-kit/types';
import AgeLimitControl from 'portfolio3/components/forms/AgeLimitControl';

interface ICivilRankRewardSecondaryBlockProps {
  eventLevelOptions: IDictionaryItem[];
  subCategoryOptions: IDictionaryItem[];
  name?: string;
}

const CivilRankRewardSecondaryBlock: React.FC<ICivilRankRewardSecondaryBlockProps> = ({
  eventLevelOptions,
  subCategoryOptions,
  name,
}) => {
  const { isMobile, inputRenderMode, inputSize, formData, formErrors, onChangeFormData, onChangeFormErrors } = useContext(LocalDataEntryFormContext);

  const secondNameController = useMemo(() => ({
    handleChange: (secondName: string) => {
      onChangeFormData((prevState) => ({
        ...prevState,
        secondName
      }));
      onChangeFormErrors((prevState) => ({
        ...prevState,
        secondNameError: false
      }));
    },
    handleBlur: (secondName: any) => {
      if (!secondName) {
        onChangeFormErrors((prevState) => ({
          ...prevState,
          secondNameError: true
        }));
      }
    }
  }), [formData.id]);

  const subCategoryController: IController<string | undefined> = useMemo(() => ({
    handleChange: (subCategory) => {
      onChangeFormData((prevState) => ({
        ...prevState,
        subCategory: Number(subCategory),
        creationKindCode: undefined
      }));
      onChangeFormErrors((prevState) => ({
        ...prevState,
        subcategoryError: false
      }));
    },
    handleBlur: (type) => {
      if (!type) {
        onChangeFormErrors((prevState) => ({
          ...prevState,
          subcategoryError: true
        }));
      }
    }
  }), [formData.id]);

  const eventLevelController: IController<string | undefined> = useMemo(() => ({
    handleChange: (eventLevel) => {
      onChangeFormData((prevState) => ({
        ...prevState,
        eventLevel: Number(eventLevel)
      }));
      onChangeFormErrors((prevState) => ({
        ...prevState,
        eventLevelError: false
      }));
    },
    handleBlur: (type) => {
      if (!type) {
        onChangeFormErrors((prevState) => ({
          ...prevState,
          eventLevelError: true
        }));
      }
    }
  }), [formData.id]);

  const classStartController = useMemo(() => ({
    handleChange: (classStart: string | undefined) => {
      const newClassStart = classStart ? validateParallel(classStart) : null;
      onChangeFormData((prevstate) => ({
        ...prevstate,
        classStart: newClassStart === undefined ? prevstate.classStart : newClassStart || undefined
      }));
    }
  }), [formData.id]);

  const classEndController = useMemo(() => ({
    handleChange: (classEnd: string | undefined) => {
      const newClassEnd = classEnd ? validateParallel(classEnd) : null;
      onChangeFormData((prevstate) => ({
        ...prevstate,
        classEnd: newClassEnd === undefined ? prevstate.classEnd : newClassEnd || undefined
      }));
    }
  }), [formData.id]);

  const additionalTopControllers = (
    <>
      <FormControl
        required
        renderMode={inputRenderMode}
        inputSize={inputSize}
        label={<BaseInputLabel>Подкатегория</BaseInputLabel>}
        error={formErrors.subcategoryError}
        helper={formErrors.subcategoryError && <FormHelperText>Выберите подкатегорию</FormHelperText>}
        control={
          <Select
            inputRenderMode={inputRenderMode}
            inputSize={inputSize}
            placeholder="Выберите подкатегорию..."
            value={formData.subCategory}
            options={subCategoryOptions}
            controller={subCategoryController}
          />
        }
      />
      {name === DifferentValue && (
        <FormControl
          required
          renderMode={inputRenderMode}
          inputSize={inputSize}
          label={<BaseInputLabel>Название награды</BaseInputLabel>}
          error={formErrors.secondNameError}
          helper={formErrors.secondNameError && <FormHelperText>Введите название награды</FormHelperText>}
          control={
            <Input
              renderMode={inputRenderMode}
              size={inputSize}
              placeholder="Введите название награды..."
              value={formData.secondName}
              controller={secondNameController}
            />
          }
        />
      )}
    </>
  );

  const additionalMiddleControllers = (
    <>
      <FormControl
        required
        renderMode={inputRenderMode}
        inputSize={inputSize}
        label={
          <BaseInputLabel>
            {formData.typeCode === SectionCodes.civilRewardStatus ? 'Уровень статуса' : 'Уровень мероприятия'}
          </BaseInputLabel>
        }
        error={formErrors.eventLevelError}
        helper={formErrors.eventLevelError && (
          <FormHelperText>
            {formData.typeCode === SectionCodes.civilRewardStatus ? 'Выберите уровень статуса' : 'Выберите уровень мероприятия'}
          </FormHelperText>
        )}
        control={
          <Select
            inputRenderMode={inputRenderMode}
            inputSize={inputSize}
            placeholder={formData.typeCode === SectionCodes.civilRewardStatus ? 'Выберите уровень статуса...' : 'Выберите уровень мероприятия...'}
            value={formData.eventLevel}
            options={eventLevelOptions}
            controller={eventLevelController}
          />
        }
      />
      <div className="portfolio-form__field">
        <AgeLimitControl
          isMobile={isMobile}
          renderMode={inputRenderMode}
          inputSize={inputSize}
          valueFrom={formData.classStart ? formData.classStart : ''}
          valueTo={formData.classEnd ? formData.classEnd : ''}
          fromController={classStartController}
          toController={classEndController}
          label={isMobile ? 'Возрастное ограничение' : 'Возрастное ограничение участников'}
          endAdornment="класс"
        />
      </div>
    </>
  );

  return (
    <RewardSecondaryBlock
      additionalTopControllers={additionalTopControllers}
      additionalMiddleControllers={additionalMiddleControllers}
    />
  );
};

export default CivilRankRewardSecondaryBlock;
