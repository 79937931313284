import { CommonUiKitSize } from '../../types';
import { ITokenFieldTokenTokens } from '../types';

export const TokenFieldTokenHeight: Record<CommonUiKitSize, number> = {
  small: 28,
  medium: 32,
  large: 36,
};

export const TokenFieldTokenPaddingInline: Record<CommonUiKitSize, number> = {
  small: 8,
  medium: 10,
  large: 10,
};

export const TokenFieldTokenGap: Record<CommonUiKitSize, number> = {
  small: 4,
  medium: 4,
  large: 6,
};

export const TokenFieldIconSize: Record<CommonUiKitSize, number> = {
  small: 16,
  medium: 16,
  large: 18,
};

export const TokenFieldTypographyVariant: Record<CommonUiKitSize, string> = {
  small: 'Paragraph MD/Regular',
  medium: 'Paragraph MD/Regular',
  large: 'Paragraph LG/Regular',
};

export const getTokenFieldTokenTokens = (size: CommonUiKitSize): ITokenFieldTokenTokens => {
  return {
    height: TokenFieldTokenHeight[size],
    paddingInline: TokenFieldTokenPaddingInline[size],
    gap: TokenFieldTokenGap[size],
    iconSize: TokenFieldIconSize[size],
    typographyVariant: TokenFieldTypographyVariant[size],
  };
};
