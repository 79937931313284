import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root: SxStyles = (theme) => {
  return {
    '& > *': {
      marginTop: '12px',
    },

    [theme.breakpoints.down('commonSm')]: {
      '& > *': {
        marginTop: '8px',
      },
    },
  };
};

export const specialityCard: SxStyles = (theme) => {
  return {
    paddingBlock: '16px',

    '&:last-child': {
      paddingBottom: 0,
    },

    [theme.breakpoints.down('commonSm')]: {
      paddingBlock: '12px',
    },
  };
};

export const listDivier: SxStyles = {
  height: '1px',
  backgroundColor: NeutralColors.light_neutrals_300,

  '&&&': {
    marginTop: 0,
  },
};
