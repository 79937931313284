/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

const IconBronzeCup: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="50" height="50" fill="#E5E5E5"/>
    <rect width="1924" height="1397" transform="translate(-1185 -308)" fill="white"/>
    <g clip-path="url(#clip0)">
    <path d="M17.5193 34.8581C5.52278 31.4198 4.11761 18.3457 4.10523 18.2161L4 17.1111H13.3719V19.0865H6.26559C6.84127 22.0926 9.20591 30.3951 18.0888 32.9445L17.5193 34.8581Z" fill="#DD794E"/>
    <path d="M32.4744 34.8581L31.9297 32.963C40.8125 30.4136 43.1834 22.1112 43.7529 19.105H36.5971V17.1111H45.9999L45.8823 18.2161C45.8823 18.3457 44.4709 31.4198 32.4744 34.8581Z" fill="#DD794E"/>
    <path d="M38.5596 14.0247C38.5596 14.0247 38.2563 36.426 26.594 39.2346C26.3774 39.2902 26.1607 39.3272 25.9379 39.3704L25.3993 39.4445C25.1407 39.4816 24.878 39.4816 24.6194 39.4445C24.4151 39.4445 24.217 39.3951 24.0004 39.3643C23.7837 39.3334 23.598 39.284 23.3814 39.2346C11.7686 36.4198 11.4653 14.0247 11.4653 14.0247H38.5596Z" fill="#F2935F"/>
    <path d="M35.1237 51.5247H14.8943C14.9188 51.2703 15.0219 51.0298 15.1893 50.8363C15.3567 50.6428 15.5802 50.5059 15.829 50.4445C22.6382 47.8457 23.3748 41.4753 23.4181 39.2099C23.4365 38.9406 23.4365 38.6705 23.4181 38.4012H26.6122C26.6122 38.4012 26.6122 38.5309 26.6122 38.7531C26.6122 38.8766 26.6122 39.037 26.6122 39.2099C26.6617 41.4692 27.4169 47.8519 34.2137 50.4445C34.4579 50.5103 34.676 50.6491 34.8387 50.8422C35.0014 51.0353 35.1008 51.2735 35.1237 51.5247Z" fill="#F2935F"/>
    <path d="M35.1238 51.5248H30.4069C30.2359 51.2518 30.013 51.0149 29.7508 50.8272C25.331 47.6297 25.8572 40.2284 25.95 39.3704C26.18 39.1769 26.3991 38.9708 26.6062 38.7531C32.3816 33.4383 33.2729 18.3827 33.3782 14H38.5717C38.5717 14 38.2684 36.4013 26.6062 39.2099C26.6557 41.4692 27.4109 47.8519 34.2077 50.4445C34.453 50.5092 34.6724 50.6476 34.8363 50.8408C35.0002 51.034 35.1005 51.2728 35.1238 51.5248Z" fill="#DD794E"/>
    <path opacity="0.4" d="M22.1308 51.5248L22.2855 50.6544C22.2855 50.6544 24.7987 44.889 24.5635 39.4445C24.5635 39.4445 24.353 39.105 23.9445 38.426C22.1308 35.6297 16.863 28.2778 17.5439 14.0247H14.2507C14.4055 18.3889 15.8292 32.7778 23.4307 38.9754C23.6164 39.1112 23.8145 39.2346 24.0497 39.3643C24.0497 39.3643 24.3716 47.6235 18.4043 50.6976C18.1169 50.9026 17.9005 51.1917 17.7853 51.5248H22.1308Z" fill="white"/>
    <path d="M28.1539 23.2222C28.169 23.744 27.988 24.2526 27.6464 24.6481C27.2667 25.0607 26.7664 25.3436 26.2164 25.4568V25.4938C26.8213 25.5308 27.3984 25.7601 27.863 26.1481C28.05 26.3304 28.1963 26.55 28.2923 26.7925C28.3883 27.035 28.4319 27.2951 28.4201 27.5555C28.4379 27.9409 28.3615 28.3248 28.1976 28.6743C28.0337 29.0237 27.7872 29.3283 27.4792 29.5617C26.7001 30.0816 25.7718 30.3331 24.836 30.2778C23.9497 30.2944 23.0692 30.1306 22.2486 29.7963V28.2222C22.615 28.4021 22.9987 28.5448 23.3937 28.6481C23.7973 28.7576 24.2135 28.8136 24.6318 28.8148C25.116 28.8516 25.5999 28.7395 26.0183 28.4938C26.1721 28.3748 26.2935 28.2192 26.3714 28.0412C26.4493 27.8633 26.4811 27.6687 26.464 27.4753C26.4766 27.293 26.4345 27.1111 26.343 26.9528C26.2515 26.7945 26.1148 26.6669 25.9503 26.5864C25.4302 26.3767 24.8697 26.286 24.3099 26.321H23.598V24.8457H24.2913C24.8134 24.8784 25.3357 24.7853 25.8141 24.5741C25.9726 24.4783 26.1012 24.3403 26.1854 24.1756C26.2696 24.011 26.306 23.8261 26.2907 23.642C26.2907 22.9629 25.8698 22.6234 25.0155 22.6234C24.7104 22.6259 24.4076 22.6759 24.118 22.7716C23.7612 22.8963 23.422 23.0664 23.109 23.2778L22.23 21.9753C23.0843 21.3779 24.1087 21.0705 25.1517 21.0987C25.9247 21.0561 26.6921 21.2523 27.3492 21.6605C27.6062 21.8304 27.8151 22.0632 27.956 22.3366C28.0969 22.61 28.165 22.915 28.1539 23.2222Z" fill="#BA6445"/>
    </g>
    <rect x="-1120.5" y="-231.5" width="1768" height="373" rx="4.5" stroke="#7B61FF" strokeDasharray="10 5"/>
    <defs>
    <clipPath id="clip0">
    <rect width="50" height="50" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);

export default IconBronzeCup;
