/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconCloseAutocomplete: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M6.0999 4.66676L9.43876 1.32783C9.74285 1.02373 9.74285 0.532183 9.43876 0.228079C9.13466 -0.0760262 8.64312 -0.0760262 8.33902 0.228079L5.00016 3.56701L1.6613 0.228079C1.3572 -0.0760262 0.865668 -0.0760262 0.56157 0.228079C0.257471 0.532183 0.257471 1.02373 0.56157 1.32783L3.90043 4.66676L0.56157 8.00569C0.257471 8.3098 0.257471 8.80134 0.56157 9.10545C0.71323 9.25711 0.912333 9.33333 1.11144 9.33333C1.31054 9.33333 1.50964 9.25711 1.6613 9.10545L5.00016 5.76652L8.33902 9.10545C8.49068 9.25711 8.68979 9.33333 8.88889 9.33333C9.08799 9.33333 9.2871 9.25711 9.43876 9.10545C9.74285 8.80134 9.74285 8.3098 9.43876 8.00569L6.0999 4.66676Z" fill="#B0B3C3" />
  </SvgIcon>
);

export default IconCloseAutocomplete;
