import { FC } from 'react';

import Advert from 'portfolio3/components/common/Advert';
import { SxStyles } from 'types';

import firstMovementAdvertImage from './firstMovementAdvertImage.png';

const WidgetFirstMovementAdvert: FC = () => {
  const contentSx: SxStyles = {
    backgroundImage: `url(${firstMovementAdvertImage})`,
    backgroundPosition: 'right 0 bottom 0',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <Advert
      title="Присоединяйся к «Движению первых!»"
      // eslint-disable-next-line max-len
      prompt="Стань частью сообщества единомышленников, где ты сможешь реализовать свой потенциал и получить уникальный опыт!"
      linkText="Зарегистрироваться"
      contentSx={contentSx}
      href="https://pervye.ru/auth/signup"
    />
  );
};

export default WidgetFirstMovementAdvert;
