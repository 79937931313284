import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { IPortfolioEntryFormData, MappedEventData } from 'portfolio3/features/dataEntryForm';
import { IRootState } from 'reducers';
import { getAgeLimitFromArray } from 'utils';

interface IProps {
  formData: IPortfolioEntryFormData;
}

const useMappedEventData = ({ formData }: IProps): MappedEventData => {
  const portfolioSections = useSelector((state: IRootState) => state.sectionRef.content);
  const eventKinds = useSelector((state: IRootState) => state.eventKinds);
  const olympiadTypes = useSelector((state: IRootState) => state.olympiadTypes);

  const currentEventKindData = useMemo(
    () => eventKinds.content.find((eventKind) => eventKind.code === formData.name),
    [eventKinds.content, formData.name],
  );

  const categoryCode = currentEventKindData?.categoryCode;
  const levelEvent = currentEventKindData?.levelEvent;

  return {
    type: portfolioSections.find((section) => section.code === categoryCode)?.value,
    name: currentEventKindData?.value,
    ageLimit: getAgeLimitFromArray(currentEventKindData?.ageLimits),
    levelEvent: olympiadTypes.content.find((olympiad) => olympiad.code === levelEvent)?.value,
    levelEventCode: levelEvent,
    organizators: currentEventKindData?.organizators,
    categoryCode,
  };
};

export default useMappedEventData;
