import { forwardRef, HTMLAttributes, ReactNode } from 'react';

import { Box, InputAdornment, inputBaseClasses, styled, SxProps, useFormControl } from '@mui/material';
import clsx from 'clsx';
import { SxStyles } from 'types';
import { mergeSx, skipPropsForwarding } from 'utils';

import { UiKitInputBaseProps } from '../types';
import { FakeInputSelectStyles } from './InputBase.styled';

const StyledFakeInputSelect = styled(Box, {
  shouldForwardProp: skipPropsForwarding<UiKitInputBaseProps>(['renderMode', 'inputProps']),
})<UiKitInputBaseProps>(FakeInputSelectStyles);

interface IFakeInputSelectProps extends HTMLAttributes<HTMLDivElement> {
  renderMode: UiKitInputBaseProps['renderMode'];
  size: UiKitInputBaseProps['size'];
  value?: UiKitInputBaseProps['value'];
  placeholder?: UiKitInputBaseProps['placeholder'];
  startAdornment?: UiKitInputBaseProps['startAdornment'];
  endAdornment?: UiKitInputBaseProps['endAdornment'];
  disabled?: UiKitInputBaseProps['disabled'];
  children?: ReactNode;
  sx?: SxStyles;
}

const FakeInputSelect = forwardRef<HTMLDivElement, IFakeInputSelectProps>(
  ({ children, value, placeholder, startAdornment, endAdornment, sx, ...props }, ref) => {
    // disabled, focused, error
    const formControlState = useFormControl();

    const classes = clsx({
      [inputBaseClasses.disabled]: formControlState?.disabled || props.disabled,
      [inputBaseClasses.focused]: formControlState?.focused,
      [inputBaseClasses.error]: formControlState?.error,
    });

    const isFloating = props.renderMode === 'floating';
    const isPlaceholderHidden =
      Boolean(value) || (isFloating && formControlState?.filled) || (isFloating && !formControlState?.focused);

    // Для передачи атрибутов инпута в див пришлось отключить проверку, но ничего лишнего не должно пройти
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const inputProps = props as any;

    const placeholderSx: SxProps = {
      transition: 'opacity 0.1s',
      position: 'absolute',
      opacity: 0,

      ...(!isPlaceholderHidden && {
        position: 'static',
        opacity: 1,
      }),
    };

    const templateColumns = ['1fr'];
    if (startAdornment) templateColumns.unshift('auto');
    if (endAdornment) templateColumns.push('auto');

    const rootStyles: SxProps = {
      display: 'grid',
      gridAutoFlow: 'column',
      gridTemplateColumns: templateColumns.join(' '),
      alignItems: 'center',
      gap: '8px',
    };

    const dynamicParentStyles: SxProps = {
      display: 'grid',
      gridTemplateColumns: 'auto',
    };

    const dynmicChildStyles: SxProps = {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    };

    return (
      <StyledFakeInputSelect
        ref={ref}
        {...inputProps}
        className={clsx('fake-input-select', props.className, classes)}
        sx={mergeSx(rootStyles, sx)}
      >
        {startAdornment && (
          <InputAdornment position="start" className="fake-input-select__start-adornment">
            {startAdornment}
          </InputAdornment>
        )}

        <Box className="fake-input-select__input" sx={dynamicParentStyles}>
          {!isPlaceholderHidden && (
            <Box sx={{ ...placeholderSx, ...dynmicChildStyles }} className="fake-input-select__placeholder">
              {placeholder}
            </Box>
          )}

          {Boolean(value) && <Box sx={{ height: '100%', ...dynmicChildStyles }}>{children}</Box>}
        </Box>

        {endAdornment && (
          <InputAdornment position="end" className="fake-input-select__end-adornment">
            {endAdornment}
          </InputAdornment>
        )}
      </StyledFakeInputSelect>
    );
  },
);

FakeInputSelect.displayName = 'FakeInputSelect';

export default FakeInputSelect;
