import { FC } from 'react';

import { Box } from '@mui/material';
import ResultDoughnut from 'components/common/ResultDoughnut';
import { format } from 'date-fns';
import { IGiaWorldSkillsEntity } from 'types';
import { getDoughnutData, getDoughnutPercentColor, getMark } from 'utils';

import BaseCard from '../BaseCard';
import * as styles from './styles';

interface IGiaWorldskillsCardProps {
  entity: IGiaWorldSkillsEntity;
  onView: () => void;
}

const GiaWorldskillsCard: FC<IGiaWorldskillsCardProps> = ({ entity, onView }) => {
  const { resultScore, maxCompetenceScore, name, resultDate } = entity;

  const mark = getMark(resultScore, maxCompetenceScore);

  const date = resultDate ? `Проведен ${format(new Date(resultDate), 'dd.MM.yyyy')}` : undefined;

  const doughnutData = getDoughnutData(resultScore, maxCompetenceScore);
  const doughnutColor = getDoughnutPercentColor(resultScore, maxCompetenceScore);

  const doughnutElement = (
    <Box sx={styles.chart}>
      <ResultDoughnut total={resultScore} data={doughnutData} color={doughnutColor} />
    </Box>
  );

  return <BaseCard title={name} place={mark} dateElement={date} rightElement={doughnutElement} onView={onView} />;
};

export default GiaWorldskillsCard;
