/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import clsx from 'clsx';

import ResultDoughnut from '../../../common/ResultDoughnut';
import { getDoughnutData, getDoughnutGradeColor } from '../../../../utils';

import './index.scss';

interface ISubjectResultTileProps {
  doughuntValue: number;
  description: string;
  selected: boolean;
}

const SubjectResultTile: React.FC<ISubjectResultTileProps> = ({
  doughuntValue,
  description,
  selected
}) => {
  return (
    <div
      className={clsx(
        'subject-result-tile tile',
        {'subject-result-tile--selected': selected}
      )}
    >
      <ResultDoughnut
        total={doughuntValue.toFixed(2)}
        data={getDoughnutData(doughuntValue, 5)}
        color={getDoughnutGradeColor(doughuntValue)}
      />
      <div className="tile__text-content">
        <p className="subject-result-tile__description">{description}</p>
      </div>
    </div>
  );
};

export default SubjectResultTile;
