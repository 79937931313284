import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root: SxStyles = (theme) => {
  return {
    paddingBottom: '16px',

    '& > *': {
      marginTop: '2px',
    },

    [theme.breakpoints.down('commonSm')]: {
      paddingBottom: '12px',

      '& > *': {
        marginTop: '8px',
      },
    },
  };
};

export const specialityCard: SxStyles = (theme) => {
  return {
    paddingBlock: '16px',

    '&:last-child': {
      paddingBottom: 0,
    },

    [theme.breakpoints.down('commonSm')]: {
      paddingBlock: '12px',
    },
  };
};

export const specialityCardDivider: SxStyles = {
  height: '1px',
  backgroundColor: NeutralColors.light_neutrals_300,
};
