import { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconDownload: FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M11.003 12.076A1.06 1.06 0 0 1 11 12V4a1 1 0 1 1 2 0v8l2.4-1.8a1 1 0 1 1 1.2 1.6l-4 3a1 1 0 0 1-1.175.018l-4-2.814a1 1 0 1 1 1.15-1.636l2.428 1.708ZM6 17v1h12v-1c0-.55.45-1 1-1s1 .45 1 1v2c0 .55-.45 1-1 1H5c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1s1 .45 1 1Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconDownload;
