import { FC, PropsWithChildren } from 'react';

import { Box, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import { commonTheme } from 'portfolio3/styles/theme';
import { SxStyles } from 'types';
import { mergeSx } from 'utils';

interface ISearchSectionPaddingProps {
  className?: string;
  sx?: SxStyles;
}

const SearchSectionPadding: FC<ISearchSectionPaddingProps & PropsWithChildren> = ({ sx, className, children }) => {
  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const localStyles: SxStyles = {
    paddingInline: isMobile ? 0 : '20px',
  };

  return (
    <Box className={clsx('search-section-padding', className)} sx={mergeSx(localStyles, sx)}>
      {children}
    </Box>
  );
};

export default SearchSectionPadding;
