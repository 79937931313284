import React, { useContext } from 'react';
import { connect } from 'react-redux';

import { Box } from '@mui/material';
import { useSortState } from 'hooks';
import Table from 'portfolio3/components/common/Table';
import {
  TableFooterPagination,
  TableLoadingIndicator,
  TableTitleWithCounter,
} from 'portfolio3/components/tableRecipes';
import { createSelector } from 'reselect';

import { IPagination } from '../../../../api/types';
import { DeleteHistoryContext } from '../../../../context';
import { IRootState } from '../../../../reducers';
import { formatDateWithTime, getPersonFullName, getSectionIcon } from '../../../../utils';
import AdminDeletedItem from '../deletedItem';
import { IMappedAdminDeleteHistory } from '../types';

interface IAdminDeleteHistoryTable {
  history: IMappedAdminDeleteHistory[];
  pagination: IPagination;
  loading: boolean;
  onRestoreEntity: (entityId: number, entityType: string) => void;
}

const AdminDeleteHistoryTable: React.FC<IAdminDeleteHistoryTable> = ({
  history,
  pagination,
  loading,
  onRestoreEntity,
}) => {
  const { currentFilters, setCurrentFilters } = useContext(DeleteHistoryContext);

  const { handleSort } = useSortState(setCurrentFilters);

  const nameSortType = currentFilters.sort.name;
  const dateSortType = currentFilters.sort.date;
  const handleSortName = () => handleSort('name');
  const handleSortDate = () => handleSort('date');

  const headerElement = (
    <Box sx={{ marginBlock: '20px 12px' }}>
      <TableTitleWithCounter title="История удаления" counter={pagination.totalElements ?? 0} />
    </Box>
  );

  return (
    <Table
      headerElement={headerElement}
      loading={loading}
      tableProps={{ sx: { gridTemplateColumns: '1fr 1fr 2fr 1fr auto minmax(64px, auto)' } }}
    >
      <Table.THead>
        <Table.Row>
          <Table.HeadCell>ФИО учащегося</Table.HeadCell>
          <Table.HeadCell>Раздел</Table.HeadCell>
          <Table.HeadCell withSorting sortType={nameSortType} boxProps={{ onClick: handleSortName }}>
            Cущность
          </Table.HeadCell>
          <Table.HeadCell>Кто удалил</Table.HeadCell>
          <Table.HeadCell withSorting sortType={dateSortType} boxProps={{ onClick: handleSortDate }}>
            Дата и время
          </Table.HeadCell>
          <Table.HeadCell />
        </Table.Row>
      </Table.THead>
      <Table.TBody>
        {loading && <TableLoadingIndicator />}
        {!loading &&
          history.map((item) => <AdminDeletedItem key={item.entityId} {...item} onRestoreEntity={onRestoreEntity} />)}
      </Table.TBody>
      <Table.TFoot>
        <TableFooterPagination
          currentFilters={currentFilters}
          setCurrentFilters={setCurrentFilters}
          totalPages={pagination.totalPages ?? 0}
          disabled={loading}
          renderPageModification={(page) => page + 1}
          setPageModification={(page) => page - 1}
        />
      </Table.TFoot>
    </Table>
  );
};

const historyMapping = createSelector(
  [
    (state: IRootState) => state.adminDeleteHistory,
    (state: IRootState) => state.sectionRef,
    (state: IRootState) => state.sources,
  ],
  (history, sections, sources) => ({
    ...history,
    content: history.content?.map(
      (item): IMappedAdminDeleteHistory => ({
        entityId: item.entityId,
        entityName: item.entityName,
        entityType: item.entityType,
        student: getPersonFullName(item.student),
        deleter: getPersonFullName(item.creator),
        deleterRole: sources.content?.find((source) => source.code === item.dataSourceCode)?.value ?? '',
        icon: getSectionIcon(item.categoryCode),
        dataKind: sections.content?.find((section) => section.code === item.dataKind)?.value ?? '',
        deletingDate: formatDateWithTime(item.creationDate),
      }),
    ),
    pagination: {
      pageNumber: history.number || 0,
      totalPages: history.totalPages || 0,
      pageSize: history.size || 0,
      offset: history.pageable?.offset,
      totalElements: history.totalElements,
      numberOfElements: history.numberOfElements,
    },
  }),
);

export default connect((state: IRootState) => ({
  history: historyMapping(state).content,
  pagination: historyMapping(state).pagination,
  loading: state.adminDeleteHistory.loading,
}))(AdminDeleteHistoryTable);
