import { StudentSectionSettingTypeCodes } from 'const';
import useWidgetVisibility from 'hooks/useWidgetVisibility';

const scienceUseWidgetVisibilityCreator = (subsectionTypeCode: string) => {
  const useProfessionWidgetVisibility = () => {
    return useWidgetVisibility({
      sectionTypeCode: StudentSectionSettingTypeCodes.science,
      subsectionTypeCode,
    });
  };
  return useProfessionWidgetVisibility;
};

const useRewards = scienceUseWidgetVisibilityCreator(StudentSectionSettingTypeCodes.scienceReward);

const useProjects = scienceUseWidgetVisibilityCreator(StudentSectionSettingTypeCodes.scienceProject);

const useEmployments = scienceUseWidgetVisibilityCreator(StudentSectionSettingTypeCodes.scienceEmployment);

const useContests = scienceUseWidgetVisibilityCreator(StudentSectionSettingTypeCodes.scienceContest);

export const scienceWidgetVisibilityHooks = { useRewards, useProjects, useEmployments, useContests };
