import { FC } from 'react';

import { Checkbox } from 'ui-kit';

interface ISubjectCheckboxProps {
  value: string;
  isChecked: boolean;
  isDisabled: boolean;
  color: string;
  onToggle: () => void;
}

const SubjectCheckbox: FC<ISubjectCheckboxProps> = ({ value, isChecked, isDisabled, color, onToggle }) => {
  return (
    <div
      className={
        isChecked ? 'performance-chart__subject performance-chart__subject--checked' : 'performance-chart__subject'
      }
    >
      <Checkbox checked={isChecked} disabled={isDisabled} onChange={onToggle} background={color} />
      <p className="performance-chart__subject-name" style={{ color }}>
        {value}
      </p>
    </div>
  );
};

export default SubjectCheckbox;
