export const generalRatingTooltipText =
  'Мы взяли твой средний результат прохождения всех диагностик по этому предмету и сравнили ' +
  'с результатам учащихся по Москве, твоей школе и твоему классу';

export const cardRatingTooltipText = (
  <>
    <div>
      Мы взяли твой результат прохождения этой диагностики и сравнили с тем, как эту же диагностику прошли учащиеся в
      Москве, твоей школе и твоем классе.
    </div>
    <br />
    <div>
      Рейтинг показывает, какое место ты занимаешь по результатам прохождения, а также, сколько еще человек имеют такой
      же результат
    </div>
  </>
);

export const MAX_VISIBLE_RATING = 5;
