/* eslint-disable prettier/prettier */
/* eslint-disable */

import { getAuthRoute, LocationStepValue, routes } from '../const';

export const redirectToChoicePage = () => {
  localStorage.removeItem('currentStudent');
  window.location.href = `${getAuthRoute()}${routes.CHOICE.path}`;
};

export const redirectToServiceErrorPage = () => {
  window.location.href = routes.SERVICE_ERROR.path;
};

// По умолчанию переходит на один шаг по истории, иначе - на указанное количество шагов назад
export const goBack = (count?: number) => {
  window.history.go(count || LocationStepValue.one);
};

export const handleCloseFormAndSetHistory = (setToggleForm: (value: React.SetStateAction<boolean>) => void) => {
  setToggleForm(false);
  setTimeout(() => {
    goBack();
  }, 100);
};

export const isSectionInURL = (url: string): boolean => {
  if (url.includes('/profile')
    || url.includes('/study')
    || url.includes('/civil')
    || url.includes('/science')
    || url.includes('/creation')
    || url.includes('/reward')
    || url.includes('/sport')
    || url.includes('/culture')
    || url.includes('/profession')) {
    return true;
  }
  return false;
};

export const getCurrentPortfolioSectionId = (headerHeight: number) => {
  const root = document.getElementById('categoryColumn');

  if (!root || !root.children) return '';

  // получаем массив элементов и фильтруем элементы по наличию id
  const childrenArray = Array.from(root.children).filter((element) => element.id) as HTMLElement[];

  if (childrenArray.length === 0) return '';

  const firstChildren = childrenArray[0];
  const lastChildren = childrenArray[childrenArray.length - 1];

  // начинаем с последнего элемента, проверяем на пересечение верхней границы экрана с верхней границей виджета
  const reversedChildrenArray = [...childrenArray].reverse();
  const extraOffset = 100;

  const acvtiveSectionElement = reversedChildrenArray.find((child) => {
    const offsetTopFromWindow = child.getBoundingClientRect().top;
    // extraOffset - насколько заранее необходимо подсвечивать раздел активным
    return offsetTopFromWindow - headerHeight - extraOffset <= 0;
  });

  const scrollableHeight = document.documentElement.scrollHeight - window.innerHeight;
  const isPageFullyScrolled = window.scrollY >= scrollableHeight - extraOffset;

  if (isPageFullyScrolled) {
    return lastChildren?.id ?? '';
  }

  return acvtiveSectionElement?.id ?? firstChildren?.id ?? '';
};
