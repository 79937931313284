/* eslint-disable max-len */
import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconCivilUnitsCommon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.9 5.6V3.3H8.1v2.3H0v17.1h24V5.6h-8.1ZM12 18.7c-2.6 0-4.7-2.1-4.7-4.7S9.4 9.3 12 9.3s4.7 2.1 4.7 4.7-2.1 4.7-4.7 4.7Zm7.8-7.4c-.9 0-1.6-.7-1.6-1.6 0-.9.7-1.6 1.6-1.6.9 0 1.6.7 1.6 1.6 0 .9-.7 1.6-1.6 1.6Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconCivilUnitsCommon;
