import { ComponentProps, FC, ReactNode } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { PropagateLoader } from 'react-spinners';

import { Box, Typography } from '@mui/material';
import { importerChapters, SectionCodes } from 'const';
import { useAppSelector } from 'hooks';
import { IconArrowLeft } from 'icons';
import { getAccentColor, NeutralColors } from 'portfolio3/styles';
import { Button } from 'portfolio3/ui-kit/button';
import PopUp from 'portfolio3/ui-kit/modals/PopUp';
import { getButtonStyles } from 'portfolio3/ui-kit/utils';
import { availableEmployeeParentSectionsSelector } from 'selectors';
import { getProtfolioKindValue, getProtfolioTypeValue } from 'utils';

import { IDataTypeSelectorFormData } from '../../components/DataTypeSelector';
import ImportStepper from '../../components/ImportStepper';

interface IImportPopUpProps {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onDeleteImportedData: () => void;
  onOpenSampleDialog: () => void;
  onExited: () => void;
  currentStep: number;
  currentVisibleStep: number;
  formData: IDataTypeSelectorFormData;
  onMoveChapter: (chapterDifference: number) => void;
  bodyElement: ReactNode;
  onScrollLoad: () => void;
  shouldLoadOnScroll: boolean;

  isAttachmentReady: boolean;
  correctRecordsCount: number;
}

const ImportPopUp: FC<IImportPopUpProps> = ({
  isOpen,
  isLoading,
  onClose,
  onDeleteImportedData,
  onOpenSampleDialog,
  onExited,
  currentStep,
  currentVisibleStep,
  formData,
  onMoveChapter,
  bodyElement,
  onScrollLoad,
  shouldLoadOnScroll,

  isAttachmentReady,
  correctRecordsCount,
}) => {
  const portfolioSections = useAppSelector(availableEmployeeParentSectionsSelector);

  const sectionName = portfolioSections.content.find((section) => section.code === formData.section)?.value;
  const dataKindName = getProtfolioKindValue(formData.kind);
  const typeCodeName = getProtfolioTypeValue(formData.type);
  const isTypesVisible = sectionName && dataKindName;

  const descriptionOpenSampleDialogElement = (
    <Typography
      tabIndex={0}
      variant="Paragraph MD/Semi Bold"
      component="span"
      color={getAccentColor('indigo', '200')}
      sx={getButtonStyles()}
      onClick={onOpenSampleDialog}
    >
      Скачать шаблон файла для импорта
    </Typography>
  );

  const descriptionGeneralElement = (
    <>
      Выберите категорию и тип данных, которые вы хотите импортировать в систему. {descriptionOpenSampleDialogElement}
    </>
  );

  const descriptionTypesElement = isTypesVisible && (
    <>
      Импорт в: раздел -{' '}
      <Typography variant="Paragraph MD/Semi Bold" component="span" color={NeutralColors.light_neutrals_700}>
        {sectionName}
      </Typography>
      , вид данных -{' '}
      <Typography variant="Paragraph MD/Semi Bold" component="span" color={NeutralColors.light_neutrals_700}>
        {dataKindName}
      </Typography>
      {typeCodeName.length !== 0 && (
        <>
          , тип мероприятия -{' '}
          <Typography variant="Paragraph MD/Semi Bold" component="span" color={NeutralColors.light_neutrals_700}>
            {typeCodeName}
          </Typography>
        </>
      )}
      .
    </>
  );

  const getDescriptionElement = () => {
    if (currentVisibleStep === importerChapters.FIRST) {
      return descriptionGeneralElement;
    }

    if (currentVisibleStep === importerChapters.SECOND) {
      return (
        <>
          {descriptionTypesElement} {descriptionOpenSampleDialogElement}
        </>
      );
    }

    return descriptionTypesElement;
  };

  const descriptionElement = getDescriptionElement();

  const localBodyElement = (
    <InfiniteScroll
      loadMore={onScrollLoad}
      hasMore={shouldLoadOnScroll}
      loader={
        <Box
          sx={{
            width: '100%',
            marginBlock: '40px',

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <PropagateLoader color={getAccentColor('indigo', '100')} />
        </Box>
      }
      useWindow={false}
    >
      {bodyElement}
    </InfiniteScroll>
  );

  const getPopUpActionButtons = (): Pick<
    ComponentProps<typeof PopUp>,
    'rightMainButton' | 'rightAdditionalButton' | 'leftButton'
  > => {
    switch (currentVisibleStep) {
      // выбор раздела
      case importerChapters.FIRST: {
        const { type, kind } = formData;
        const isNextChapterDisabled = !type && kind !== SectionCodes.project;

        return {
          leftButton: null,
          rightAdditionalButton: (
            <Button variant="secondary" onClick={onClose}>
              Отмена
            </Button>
          ),
          rightMainButton: (
            <Button onClick={() => onMoveChapter(1)} disabled={isNextChapterDisabled}>
              Следующий шаг
            </Button>
          ),
        };
      }
      // выбор файла
      case importerChapters.SECOND: {
        // обработка файла
        if (isLoading) {
          return {
            rightAdditionalButton: (
              <Button variant="secondary" onClick={onClose}>
                Закрыть
              </Button>
            ),
          };
        }

        // выбор файла
        return {
          leftButton: (
            <Button variant="tetriary" onClick={onClose}>
              Отмена
            </Button>
          ),
          rightAdditionalButton: (
            <Button variant="secondary" onClick={() => onMoveChapter(-1)} iconLeft={<IconArrowLeft />}>
              Предыдущий шаг
            </Button>
          ),
          rightMainButton: (
            <Button onClick={() => onMoveChapter(1)} disabled={!isAttachmentReady}>
              Следующий шаг
            </Button>
          ),
        };
      }
      // предпросмотр
      case importerChapters.THIRD: {
        const handleCloseAndDeleteData = () => {
          onClose();
          onDeleteImportedData();
        };

        return {
          leftButton: (
            <Button variant="tetriary" onClick={handleCloseAndDeleteData}>
              Отменить все и выйти
            </Button>
          ),
          rightAdditionalButton: (
            <Button variant="secondary" onClick={() => onMoveChapter(-1)} iconLeft={<IconArrowLeft />}>
              Предыдущий шаг
            </Button>
          ),
          rightMainButton: (
            <Button onClick={() => onMoveChapter(1)} disabled={correctRecordsCount === 0}>
              Импортировать успешно обработанные
            </Button>
          ),
        };
      }
      // результат
      case importerChapters.FOURTH: {
        return {
          rightAdditionalButton: (
            <Button variant="secondary" onClick={onClose}>
              Закрыть
            </Button>
          ),
        };
      }
      default: {
        return {
          leftButton: null,
          rightAdditionalButton: null,
          rightMainButton: null,
        };
      }
    }
  };

  return (
    <PopUp
      open={isOpen}
      onClose={onClose}
      title="Импорт данных из файла"
      description={descriptionElement}
      staticBodyElement={
        <Box sx={{ padding: '20px' }}>
          <ImportStepper currentStep={currentStep} />
        </Box>
      }
      body={localBodyElement}
      size="large"
      sx={{ height: '680px' }}
      bodySx={{ paddingTop: 0 }}
      onExited={onExited}
      {...getPopUpActionButtons()}
    />
  );
};

export default ImportPopUp;
