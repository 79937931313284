import { getAccentColor, NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

const successToast: SxStyles = {
  '.Toastify__toast--success': {
    borderColor: getAccentColor('green', '100'),
  },
};

const errorToast: SxStyles = {
  '.Toastify__toast--error': {
    borderColor: getAccentColor('red', '100'),
  },
};

export const root: SxStyles = {
  // контейнер
  '.Toastify__toast-container': {
    top: '20px',
    right: '20px',
    width: '384px',
    padding: 0,
  },

  // тост
  '.Toastify__toast': {
    position: 'relative',
    display: 'flex',
    gap: '8px',

    minHeight: 'auto',
    padding: '16px',

    borderLeft: '4px solid',
    borderRadius: '8px',
    boxShadow: '0px 10px 15px -3px rgba(41, 41, 64, 0.08), 0px 4px 6px -2px rgba(41, 41, 64, 0.04)',

    color: NeutralColors.light_neutrals_900,
    backgroundColor: NeutralColors.light_neutrals_0,

    // стандартные стили для текстового контента
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 600,

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: '-4px',
      right: 0,
      bottom: 0,
      border: '1px solid',
      borderColor: NeutralColors.light_neutrals_400,
      borderRadius: '8px',
    },
  },

  ...successToast,
  ...errorToast,
};
