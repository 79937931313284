import { FC, useState } from 'react';
import { DateRange, DateValue } from 'react-aria';

import { CalendarDate } from '@internationalized/date';
import { MobileViewDrawer } from 'portfolio3/components/drawers';

import { DayRangePickerComponentProps, RangePreset } from '../localTypes';
import { DatePickerContext } from '../shared/context';
import DateRangePicker from '../shared/DateRangePicker';
import Popover from '../shared/Popover';
import RangePresetsPanel from '../shared/RangePresetsPanel';
import { dateToCalendarDate, dateValueToDate } from '../utils';
import DayRangeCalendar from './Calendar';

const DayRangePicker: FC<DayRangePickerComponentProps> = ({
  startValue,
  endValue,
  controller,
  presets,
  disabled,
  minValue,
  maxValue,
  isMobile,
  renderMode,
  size,
  withTodayButton,
}) => {
  const hasSelectedRangePreset = presets?.includes('selected range') ?? false;
  const initialPreset: RangePreset | null = hasSelectedRangePreset ? 'selected range' : null;
  const [preset, setPreset] = useState<RangePreset | null>(initialPreset);

  let mappedStartValue: CalendarDate | null = null;
  let mappedEndValue: CalendarDate | null = null;
  const mappedMinValue = minValue ? dateToCalendarDate(minValue) : undefined;
  const mappedMaxValue = maxValue ? dateToCalendarDate(maxValue) : undefined;

  if (startValue) {
    mappedStartValue = dateToCalendarDate(startValue);
  }

  if (endValue) {
    mappedEndValue = dateToCalendarDate(endValue);
  }

  const handleChange = (date: DateRange | null) => {
    controller.handleChange({
      start: dateValueToDate(date?.start ?? null),
      end: dateValueToDate(date?.end ?? null),
    });

    setPreset(initialPreset);
  };

  const handleSetInitialStartDate = (date: DateValue | null) => {
    controller.handleChange({
      start: dateValueToDate(date),
      end: endValue,
    });
  };

  const handleClear = () => {
    setPreset(initialPreset);
    controller.handleClear?.();
  };

  return (
    <DatePickerContext.Provider
      value={{
        renderMode: renderMode ?? 'fixed',
        size: size ?? 'medium',
      }}
    >
      <DateRangePicker
        options={{
          value: mappedStartValue
            ? {
                start: mappedStartValue,
                end: mappedEndValue,
              }
            : null,
          onChange: handleChange,
          onBlur: () => controller.handleBlur?.({ start: startValue, end: endValue }),
          isDisabled: disabled,
          minValue: mappedMinValue,
          maxValue: mappedMaxValue,
        }}
        onSetInitialStartDate={handleSetInitialStartDate}
        onClear={handleClear}
      >
        {(triggerRef, state, calendarProps) => {
          const calendarElement = (
            <DayRangeCalendar
              datePickerState={state}
              calendarOptions={calendarProps}
              onSetInitialStartDate={handleSetInitialStartDate}
              controller={controller}
              withToday={withTodayButton}
            />
          );

          const presetOptions = presets && (
            <RangePresetsPanel
              currentPreset={preset}
              presets={presets}
              onChange={setPreset}
              dateRangePickerState={state}
            />
          );

          if (isMobile) {
            return (
              <MobileViewDrawer title="Дата или интервал дат" open={state.isOpen} onClose={state.close}>
                {calendarElement}
              </MobileViewDrawer>
            );
          }

          return (
            <Popover triggerRef={triggerRef} datePickerState={state} optionsElement={presetOptions}>
              {calendarElement}
            </Popover>
          );
        }}
      </DateRangePicker>
    </DatePickerContext.Provider>
  );
};

export default DayRangePicker;
