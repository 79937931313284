import { useCallback, useState } from 'react';

export default () => {
  const [element, setElement] = useState<Element | null>(null);
  const elementRef = useCallback((node: Element | null) => {
    if (node !== null) {
      setElement(node);
    }
  }, []);

  return {
    element,
    elementRef,
  };
};
