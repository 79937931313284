/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';

const Hamster: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.3603 9.69164C13.4111 9.3853 13.4375 9.07073 13.4375 8.75C13.4375 5.59162 10.8771 3.03125 7.71875 3.03125C4.56037 3.03125 2 5.59162 2 8.75C2 11.5751 4.04852 13.9217 6.74118 14.3855C6.98078 14.4268 7.10459 14.7155 6.95649 14.9083C5.18696 17.212 4.03125 19.7395 4.03125 21.5469C4.03125 26.0312 8.09375 28.9688 11.0938 28.9688C13.3507 28.9688 14.3641 28.5719 15.0721 28.2946C15.4523 28.1457 15.7444 28.0312 16.0938 28.0312C16.4768 28.0312 16.7773 28.1688 17.1482 28.3386C17.7453 28.6119 18.5248 28.9688 20.125 28.9688C22.9531 28.9688 27.9531 26.8438 27.9531 21.5469C27.9531 19.7619 26.8736 17.2075 25.1605 14.8787C25.0202 14.688 25.139 14.4098 25.3715 14.365C28.0084 13.856 30 11.5356 30 8.75C30 5.59162 27.4396 3.03125 24.2812 3.03125C21.1229 3.03125 18.5625 5.59162 18.5625 8.75C18.5625 9.03331 18.5831 9.31181 18.6229 9.58408C17.795 9.28964 16.9468 9.125 16.0938 9.125C15.1891 9.125 14.2687 9.3299 13.3603 9.69164Z"
      fill="url(#paint0_linear_311_287359)"
    />
    <path
      d="M13.3603 9.69164C13.4111 9.3853 13.4375 9.07073 13.4375 8.75C13.4375 5.59162 10.8771 3.03125 7.71875 3.03125C4.56037 3.03125 2 5.59162 2 8.75C2 11.5751 4.04852 13.9217 6.74118 14.3855C6.98078 14.4268 7.10459 14.7155 6.95649 14.9083C5.18696 17.212 4.03125 19.7395 4.03125 21.5469C4.03125 26.0312 8.09375 28.9688 11.0938 28.9688C13.3507 28.9688 14.3641 28.5719 15.0721 28.2946C15.4523 28.1457 15.7444 28.0312 16.0938 28.0312C16.4768 28.0312 16.7773 28.1688 17.1482 28.3386C17.7453 28.6119 18.5248 28.9688 20.125 28.9688C22.9531 28.9688 27.9531 26.8438 27.9531 21.5469C27.9531 19.7619 26.8736 17.2075 25.1605 14.8787C25.0202 14.688 25.139 14.4098 25.3715 14.365C28.0084 13.856 30 11.5356 30 8.75C30 5.59162 27.4396 3.03125 24.2812 3.03125C21.1229 3.03125 18.5625 5.59162 18.5625 8.75C18.5625 9.03331 18.5831 9.31181 18.6229 9.58408C17.795 9.28964 16.9468 9.125 16.0938 9.125C15.1891 9.125 14.2687 9.3299 13.3603 9.69164Z"
      fill="url(#paint1_radial_311_287359)"
    />
    <path
      d="M13.3603 9.69164C13.4111 9.3853 13.4375 9.07073 13.4375 8.75C13.4375 5.59162 10.8771 3.03125 7.71875 3.03125C4.56037 3.03125 2 5.59162 2 8.75C2 11.5751 4.04852 13.9217 6.74118 14.3855C6.98078 14.4268 7.10459 14.7155 6.95649 14.9083C5.18696 17.212 4.03125 19.7395 4.03125 21.5469C4.03125 26.0312 8.09375 28.9688 11.0938 28.9688C13.3507 28.9688 14.3641 28.5719 15.0721 28.2946C15.4523 28.1457 15.7444 28.0312 16.0938 28.0312C16.4768 28.0312 16.7773 28.1688 17.1482 28.3386C17.7453 28.6119 18.5248 28.9688 20.125 28.9688C22.9531 28.9688 27.9531 26.8438 27.9531 21.5469C27.9531 19.7619 26.8736 17.2075 25.1605 14.8787C25.0202 14.688 25.139 14.4098 25.3715 14.365C28.0084 13.856 30 11.5356 30 8.75C30 5.59162 27.4396 3.03125 24.2812 3.03125C21.1229 3.03125 18.5625 5.59162 18.5625 8.75C18.5625 9.03331 18.5831 9.31181 18.6229 9.58408C17.795 9.28964 16.9468 9.125 16.0938 9.125C15.1891 9.125 14.2687 9.3299 13.3603 9.69164Z"
      fill="url(#paint2_radial_311_287359)"
    />
    <path
      d="M13.3603 9.69164C13.4111 9.3853 13.4375 9.07073 13.4375 8.75C13.4375 5.59162 10.8771 3.03125 7.71875 3.03125C4.56037 3.03125 2 5.59162 2 8.75C2 11.5751 4.04852 13.9217 6.74118 14.3855C6.98078 14.4268 7.10459 14.7155 6.95649 14.9083C5.18696 17.212 4.03125 19.7395 4.03125 21.5469C4.03125 26.0312 8.09375 28.9688 11.0938 28.9688C13.3507 28.9688 14.3641 28.5719 15.0721 28.2946C15.4523 28.1457 15.7444 28.0312 16.0938 28.0312C16.4768 28.0312 16.7773 28.1688 17.1482 28.3386C17.7453 28.6119 18.5248 28.9688 20.125 28.9688C22.9531 28.9688 27.9531 26.8438 27.9531 21.5469C27.9531 19.7619 26.8736 17.2075 25.1605 14.8787C25.0202 14.688 25.139 14.4098 25.3715 14.365C28.0084 13.856 30 11.5356 30 8.75C30 5.59162 27.4396 3.03125 24.2812 3.03125C21.1229 3.03125 18.5625 5.59162 18.5625 8.75C18.5625 9.03331 18.5831 9.31181 18.6229 9.58408C17.795 9.28964 16.9468 9.125 16.0938 9.125C15.1891 9.125 14.2687 9.3299 13.3603 9.69164Z"
      fill="url(#paint3_radial_311_287359)"
    />
    <path
      d="M13.3603 9.69164C13.4111 9.3853 13.4375 9.07073 13.4375 8.75C13.4375 5.59162 10.8771 3.03125 7.71875 3.03125C4.56037 3.03125 2 5.59162 2 8.75C2 11.5751 4.04852 13.9217 6.74118 14.3855C6.98078 14.4268 7.10459 14.7155 6.95649 14.9083C5.18696 17.212 4.03125 19.7395 4.03125 21.5469C4.03125 26.0312 8.09375 28.9688 11.0938 28.9688C13.3507 28.9688 14.3641 28.5719 15.0721 28.2946C15.4523 28.1457 15.7444 28.0312 16.0938 28.0312C16.4768 28.0312 16.7773 28.1688 17.1482 28.3386C17.7453 28.6119 18.5248 28.9688 20.125 28.9688C22.9531 28.9688 27.9531 26.8438 27.9531 21.5469C27.9531 19.7619 26.8736 17.2075 25.1605 14.8787C25.0202 14.688 25.139 14.4098 25.3715 14.365C28.0084 13.856 30 11.5356 30 8.75C30 5.59162 27.4396 3.03125 24.2812 3.03125C21.1229 3.03125 18.5625 5.59162 18.5625 8.75C18.5625 9.03331 18.5831 9.31181 18.6229 9.58408C17.795 9.28964 16.9468 9.125 16.0938 9.125C15.1891 9.125 14.2687 9.3299 13.3603 9.69164Z"
      fill="url(#paint4_radial_311_287359)"
    />
    <path
      d="M13.3603 9.69164C13.4111 9.3853 13.4375 9.07073 13.4375 8.75C13.4375 5.59162 10.8771 3.03125 7.71875 3.03125C4.56037 3.03125 2 5.59162 2 8.75C2 11.5751 4.04852 13.9217 6.74118 14.3855C6.98078 14.4268 7.10459 14.7155 6.95649 14.9083C5.18696 17.212 4.03125 19.7395 4.03125 21.5469C4.03125 26.0312 8.09375 28.9688 11.0938 28.9688C13.3507 28.9688 14.3641 28.5719 15.0721 28.2946C15.4523 28.1457 15.7444 28.0312 16.0938 28.0312C16.4768 28.0312 16.7773 28.1688 17.1482 28.3386C17.7453 28.6119 18.5248 28.9688 20.125 28.9688C22.9531 28.9688 27.9531 26.8438 27.9531 21.5469C27.9531 19.7619 26.8736 17.2075 25.1605 14.8787C25.0202 14.688 25.139 14.4098 25.3715 14.365C28.0084 13.856 30 11.5356 30 8.75C30 5.59162 27.4396 3.03125 24.2812 3.03125C21.1229 3.03125 18.5625 5.59162 18.5625 8.75C18.5625 9.03331 18.5831 9.31181 18.6229 9.58408C17.795 9.28964 16.9468 9.125 16.0938 9.125C15.1891 9.125 14.2687 9.3299 13.3603 9.69164Z"
      fill="url(#paint5_radial_311_287359)"
    />
    <path
      d="M13.3603 9.69164C13.4111 9.3853 13.4375 9.07073 13.4375 8.75C13.4375 5.59162 10.8771 3.03125 7.71875 3.03125C4.56037 3.03125 2 5.59162 2 8.75C2 11.5751 4.04852 13.9217 6.74118 14.3855C6.98078 14.4268 7.10459 14.7155 6.95649 14.9083C5.18696 17.212 4.03125 19.7395 4.03125 21.5469C4.03125 26.0312 8.09375 28.9688 11.0938 28.9688C13.3507 28.9688 14.3641 28.5719 15.0721 28.2946C15.4523 28.1457 15.7444 28.0312 16.0938 28.0312C16.4768 28.0312 16.7773 28.1688 17.1482 28.3386C17.7453 28.6119 18.5248 28.9688 20.125 28.9688C22.9531 28.9688 27.9531 26.8438 27.9531 21.5469C27.9531 19.7619 26.8736 17.2075 25.1605 14.8787C25.0202 14.688 25.139 14.4098 25.3715 14.365C28.0084 13.856 30 11.5356 30 8.75C30 5.59162 27.4396 3.03125 24.2812 3.03125C21.1229 3.03125 18.5625 5.59162 18.5625 8.75C18.5625 9.03331 18.5831 9.31181 18.6229 9.58408C17.795 9.28964 16.9468 9.125 16.0938 9.125C15.1891 9.125 14.2687 9.3299 13.3603 9.69164Z"
      fill="url(#paint6_radial_311_287359)"
    />
    <path
      d="M13.3603 9.69164C13.4111 9.3853 13.4375 9.07073 13.4375 8.75C13.4375 5.59162 10.8771 3.03125 7.71875 3.03125C4.56037 3.03125 2 5.59162 2 8.75C2 11.5751 4.04852 13.9217 6.74118 14.3855C6.98078 14.4268 7.10459 14.7155 6.95649 14.9083C5.18696 17.212 4.03125 19.7395 4.03125 21.5469C4.03125 26.0312 8.09375 28.9688 11.0938 28.9688C13.3507 28.9688 14.3641 28.5719 15.0721 28.2946C15.4523 28.1457 15.7444 28.0312 16.0938 28.0312C16.4768 28.0312 16.7773 28.1688 17.1482 28.3386C17.7453 28.6119 18.5248 28.9688 20.125 28.9688C22.9531 28.9688 27.9531 26.8438 27.9531 21.5469C27.9531 19.7619 26.8736 17.2075 25.1605 14.8787C25.0202 14.688 25.139 14.4098 25.3715 14.365C28.0084 13.856 30 11.5356 30 8.75C30 5.59162 27.4396 3.03125 24.2812 3.03125C21.1229 3.03125 18.5625 5.59162 18.5625 8.75C18.5625 9.03331 18.5831 9.31181 18.6229 9.58408C17.795 9.28964 16.9468 9.125 16.0938 9.125C15.1891 9.125 14.2687 9.3299 13.3603 9.69164Z"
      fill="url(#paint7_radial_311_287359)"
    />
    <path
      d="M13.3603 9.69164C13.4111 9.3853 13.4375 9.07073 13.4375 8.75C13.4375 5.59162 10.8771 3.03125 7.71875 3.03125C4.56037 3.03125 2 5.59162 2 8.75C2 11.5751 4.04852 13.9217 6.74118 14.3855C6.98078 14.4268 7.10459 14.7155 6.95649 14.9083C5.18696 17.212 4.03125 19.7395 4.03125 21.5469C4.03125 26.0312 8.09375 28.9688 11.0938 28.9688C13.3507 28.9688 14.3641 28.5719 15.0721 28.2946C15.4523 28.1457 15.7444 28.0312 16.0938 28.0312C16.4768 28.0312 16.7773 28.1688 17.1482 28.3386C17.7453 28.6119 18.5248 28.9688 20.125 28.9688C22.9531 28.9688 27.9531 26.8438 27.9531 21.5469C27.9531 19.7619 26.8736 17.2075 25.1605 14.8787C25.0202 14.688 25.139 14.4098 25.3715 14.365C28.0084 13.856 30 11.5356 30 8.75C30 5.59162 27.4396 3.03125 24.2812 3.03125C21.1229 3.03125 18.5625 5.59162 18.5625 8.75C18.5625 9.03331 18.5831 9.31181 18.6229 9.58408C17.795 9.28964 16.9468 9.125 16.0938 9.125C15.1891 9.125 14.2687 9.3299 13.3603 9.69164Z"
      fill="url(#paint8_radial_311_287359)"
    />
    <path
      d="M13.3603 9.69164C13.4111 9.3853 13.4375 9.07073 13.4375 8.75C13.4375 5.59162 10.8771 3.03125 7.71875 3.03125C4.56037 3.03125 2 5.59162 2 8.75C2 11.5751 4.04852 13.9217 6.74118 14.3855C6.98078 14.4268 7.10459 14.7155 6.95649 14.9083C5.18696 17.212 4.03125 19.7395 4.03125 21.5469C4.03125 26.0312 8.09375 28.9688 11.0938 28.9688C13.3507 28.9688 14.3641 28.5719 15.0721 28.2946C15.4523 28.1457 15.7444 28.0312 16.0938 28.0312C16.4768 28.0312 16.7773 28.1688 17.1482 28.3386C17.7453 28.6119 18.5248 28.9688 20.125 28.9688C22.9531 28.9688 27.9531 26.8438 27.9531 21.5469C27.9531 19.7619 26.8736 17.2075 25.1605 14.8787C25.0202 14.688 25.139 14.4098 25.3715 14.365C28.0084 13.856 30 11.5356 30 8.75C30 5.59162 27.4396 3.03125 24.2812 3.03125C21.1229 3.03125 18.5625 5.59162 18.5625 8.75C18.5625 9.03331 18.5831 9.31181 18.6229 9.58408C17.795 9.28964 16.9468 9.125 16.0938 9.125C15.1891 9.125 14.2687 9.3299 13.3603 9.69164Z"
      fill="url(#paint9_radial_311_287359)"
    />
    <path
      d="M13.3603 9.69164C13.4111 9.3853 13.4375 9.07073 13.4375 8.75C13.4375 5.59162 10.8771 3.03125 7.71875 3.03125C4.56037 3.03125 2 5.59162 2 8.75C2 11.5751 4.04852 13.9217 6.74118 14.3855C6.98078 14.4268 7.10459 14.7155 6.95649 14.9083C5.18696 17.212 4.03125 19.7395 4.03125 21.5469C4.03125 26.0312 8.09375 28.9688 11.0938 28.9688C13.3507 28.9688 14.3641 28.5719 15.0721 28.2946C15.4523 28.1457 15.7444 28.0312 16.0938 28.0312C16.4768 28.0312 16.7773 28.1688 17.1482 28.3386C17.7453 28.6119 18.5248 28.9688 20.125 28.9688C22.9531 28.9688 27.9531 26.8438 27.9531 21.5469C27.9531 19.7619 26.8736 17.2075 25.1605 14.8787C25.0202 14.688 25.139 14.4098 25.3715 14.365C28.0084 13.856 30 11.5356 30 8.75C30 5.59162 27.4396 3.03125 24.2812 3.03125C21.1229 3.03125 18.5625 5.59162 18.5625 8.75C18.5625 9.03331 18.5831 9.31181 18.6229 9.58408C17.795 9.28964 16.9468 9.125 16.0938 9.125C15.1891 9.125 14.2687 9.3299 13.3603 9.69164Z"
      fill="url(#paint10_radial_311_287359)"
    />
    <path
      d="M13.3603 9.69164C13.4111 9.3853 13.4375 9.07073 13.4375 8.75C13.4375 5.59162 10.8771 3.03125 7.71875 3.03125C4.56037 3.03125 2 5.59162 2 8.75C2 11.5751 4.04852 13.9217 6.74118 14.3855C6.98078 14.4268 7.10459 14.7155 6.95649 14.9083C5.18696 17.212 4.03125 19.7395 4.03125 21.5469C4.03125 26.0312 8.09375 28.9688 11.0938 28.9688C13.3507 28.9688 14.3641 28.5719 15.0721 28.2946C15.4523 28.1457 15.7444 28.0312 16.0938 28.0312C16.4768 28.0312 16.7773 28.1688 17.1482 28.3386C17.7453 28.6119 18.5248 28.9688 20.125 28.9688C22.9531 28.9688 27.9531 26.8438 27.9531 21.5469C27.9531 19.7619 26.8736 17.2075 25.1605 14.8787C25.0202 14.688 25.139 14.4098 25.3715 14.365C28.0084 13.856 30 11.5356 30 8.75C30 5.59162 27.4396 3.03125 24.2812 3.03125C21.1229 3.03125 18.5625 5.59162 18.5625 8.75C18.5625 9.03331 18.5831 9.31181 18.6229 9.58408C17.795 9.28964 16.9468 9.125 16.0938 9.125C15.1891 9.125 14.2687 9.3299 13.3603 9.69164Z"
      fill="url(#paint11_radial_311_287359)"
    />
    <g filter="url(#filter0_f_311_287359)">
      <path
        d="M16.0002 23.9453C16.0588 23.9453 16.1154 23.9554 16.168 23.9742C16.2378 23.999 16.3002 24.0389 16.3516 24.0897C16.4388 24.1758 16.4941 24.2936 16.4997 24.4231C16.5046 24.4967 16.5664 24.7817 16.7298 25.0287C16.8822 25.259 17.072 25.3911 17.3509 25.3688C17.6973 25.3411 17.8375 25.2178 17.9003 25.1313C17.9755 25.0277 17.9979 24.8949 17.9873 24.7945C17.9584 24.5199 18.1576 24.2739 18.4322 24.2449C18.7068 24.216 18.9529 24.4152 18.9818 24.6899C19.0129 24.9853 18.9572 25.3776 18.7095 25.7188C18.4494 26.0771 18.0218 26.3183 17.4307 26.3656C16.7598 26.4193 16.2927 26.0938 16.0002 25.7245C15.7077 26.0938 15.2406 26.4193 14.5697 26.3656C13.9786 26.3183 13.551 26.0771 13.2909 25.7188C13.0432 25.3776 12.9875 24.9853 13.0186 24.6899C13.0475 24.4152 13.2935 24.216 13.5682 24.2449C13.8428 24.2739 14.042 24.5199 14.0131 24.7945C14.0025 24.8949 14.0249 25.0277 14.1001 25.1313C14.1629 25.2178 14.3031 25.3411 14.6494 25.3688C14.9283 25.3911 15.1182 25.259 15.2706 25.0287C15.434 24.7817 15.4958 24.4967 15.5007 24.4231C15.5028 24.3741 15.512 24.3267 15.5274 24.2821C15.5528 24.2085 15.5948 24.1429 15.6488 24.0897C15.7395 24 15.8641 23.9452 16.0002 23.9453Z"
        fill="#CF6161"
      />
    </g>
    <path
      d="M17.5859 21.6719C17.5859 22.1594 16.6562 23.375 16.0078 23.375C15.3141 23.375 14.4141 22.2656 14.4141 21.6719C14.4141 21.0781 15.1172 21.0234 16.0078 21.0234C16.8984 21.0234 17.5859 21.0625 17.5859 21.6719Z"
      fill="url(#paint12_linear_311_287359)"
    />
    <path
      d="M17.5859 21.6719C17.5859 22.1594 16.6562 23.375 16.0078 23.375C15.3141 23.375 14.4141 22.2656 14.4141 21.6719C14.4141 21.0781 15.1172 21.0234 16.0078 21.0234C16.8984 21.0234 17.5859 21.0625 17.5859 21.6719Z"
      fill="url(#paint13_radial_311_287359)"
    />
    <path
      d="M17.5859 21.6719C17.5859 22.1594 16.6562 23.375 16.0078 23.375C15.3141 23.375 14.4141 22.2656 14.4141 21.6719C14.4141 21.0781 15.1172 21.0234 16.0078 21.0234C16.8984 21.0234 17.5859 21.0625 17.5859 21.6719Z"
      fill="url(#paint14_radial_311_287359)"
    />
    <rect x="11.2422" y="17.0156" width="1.48438" height="3" rx="0.742188" fill="url(#paint15_linear_311_287359)" />
    <rect x="11.2422" y="17.0156" width="1.48438" height="3" rx="0.742188" fill="url(#paint16_linear_311_287359)" />
    <rect x="11.2422" y="17.0156" width="1.48438" height="3" rx="0.742188" fill="url(#paint17_radial_311_287359)" />
    <rect x="19.2422" y="17.0156" width="1.48438" height="3" rx="0.742188" fill="url(#paint18_linear_311_287359)" />
    <rect x="19.2422" y="17.0156" width="1.48438" height="3" rx="0.742188" fill="url(#paint19_linear_311_287359)" />
    <rect x="19.2422" y="17.0156" width="1.48438" height="3" rx="0.742188" fill="url(#paint20_radial_311_287359)" />
    <g filter="url(#filter1_i_311_287359)">
      <circle cx="6.85938" cy="8.67969" r="4.28906" fill="url(#paint21_radial_311_287359)" />
      <circle cx="6.85938" cy="8.67969" r="4.28906" fill="url(#paint22_radial_311_287359)" />
    </g>
    <g filter="url(#filter2_i_311_287359)">
      <circle cx="25.1406" cy="8.67969" r="4.28906" fill="url(#paint23_radial_311_287359)" />
      <circle cx="25.1406" cy="8.67969" r="4.28906" fill="url(#paint24_radial_311_287359)" />
      <circle cx="25.1406" cy="8.67969" r="4.28906" fill="url(#paint25_radial_311_287359)" />
    </g>
    <g filter="url(#filter3_f_311_287359)">
      <ellipse
        cx="23.0778"
        cy="17.0276"
        rx="2.51951"
        ry="6.65644"
        transform="rotate(-34.3167 23.0778 17.0276)"
        fill="url(#paint26_radial_311_287359)"
      />
    </g>
    <g filter="url(#filter4_f_311_287359)">
      <path
        d="M24.4844 14.5625C24.026 14.125 22.3406 12.725 21.1406 11.875"
        stroke="url(#paint27_linear_311_287359)"
        strokeWidth="0.2"
      />
    </g>
    <g filter="url(#filter5_f_311_287359)">
      <path
        d="M8.78125 13.7188C10.125 12.8437 12.1719 10.9375 12.7969 10.1562"
        stroke="url(#paint28_linear_311_287359)"
        strokeWidth="0.2"
      />
    </g>
    <path
      d="M13.5154 24.5156C13.4737 24.9115 13.6716 25.5656 14.6091 25.6406C15.6453 25.7235 15.9841 24.5312 15.9998 24.2188"
      stroke="url(#paint29_radial_311_287359)"
      strokeLinecap="round"
    />
    <path
      d="M13.5154 24.5156C13.4737 24.9115 13.6716 25.5656 14.6091 25.6406C15.6453 25.7235 15.9841 24.5312 15.9998 24.2188"
      stroke="url(#paint30_radial_311_287359)"
      strokeLinecap="round"
    />
    <path
      d="M18.4841 24.5156C18.5258 24.9115 18.3279 25.5656 17.3904 25.6406C16.3542 25.7235 16.0154 24.5312 15.9998 24.2188"
      stroke="url(#paint31_radial_311_287359)"
      strokeLinecap="round"
    />
    <path
      d="M18.4841 24.5156C18.5258 24.9115 18.3279 25.5656 17.3904 25.6406C16.3542 25.7235 16.0154 24.5312 15.9998 24.2188"
      stroke="url(#paint32_radial_311_287359)"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0002 23.7188C16.0588 23.7187 16.1154 23.7289 16.168 23.7476C16.2378 23.7724 16.3002 23.8123 16.3516 23.8631C16.4388 23.9493 16.4941 24.067 16.4997 24.1965C16.5046 24.2701 16.5664 24.5551 16.7298 24.8021C16.8822 25.0324 17.072 25.1645 17.3509 25.1422C17.6973 25.1145 17.8375 24.9912 17.9003 24.9047C17.9755 24.8011 17.9979 24.6684 17.9873 24.568C17.9584 24.2934 18.1576 24.0473 18.4322 24.0184C18.7068 23.9895 18.9529 24.1887 18.9818 24.4633C19.0129 24.7587 18.9572 25.151 18.7095 25.4922C18.4494 25.8505 18.0218 26.0918 17.4307 26.139C16.7598 26.1927 16.2927 25.8672 16.0002 25.498C15.7077 25.8672 15.2406 26.1927 14.5697 26.139C13.9786 26.0918 13.551 25.8505 13.2909 25.4922C13.0432 25.151 12.9875 24.7587 13.0186 24.4633C13.0475 24.1887 13.2935 23.9895 13.5682 24.0184C13.8428 24.0473 14.042 24.2934 14.0131 24.568C14.0025 24.6684 14.0249 24.8011 14.1001 24.9047C14.1629 24.9912 14.3031 25.1145 14.6494 25.1422C14.9283 25.1645 15.1182 25.0324 15.2706 24.8021C15.434 24.5551 15.4958 24.2701 15.5007 24.1965C15.5028 24.1475 15.512 24.1002 15.5274 24.0556C15.5528 23.9819 15.5948 23.9164 15.6488 23.8631C15.7395 23.7735 15.8641 23.7186 16.0002 23.7188Z"
      fill="url(#paint33_radial_311_287359)"
    />
    <defs>
      <filter
        id="filter0_f_311_287359"
        x="12.5107"
        y="23.4453"
        width="6.979"
        height="3.42578"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_311_287359" />
      </filter>
      <filter
        id="filter1_i_311_287359"
        x="2.57031"
        y="4.39062"
        width="8.77813"
        height="8.87813"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.2" dy="0.3" />
        <feGaussianBlur stdDeviation="0.25" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.913725 0 0 0 0 0.411765 0 0 0 0 0.501961 0 0 0 0.5 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_311_287359" />
      </filter>
      <filter
        id="filter2_i_311_287359"
        x="20.7016"
        y="4.39062"
        width="8.72813"
        height="8.72813"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-0.15" dy="0.15" />
        <feGaussianBlur stdDeviation="0.25" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.9125 0 0 0 0 0.410625 0 0 0 0 0.502237 0 0 0 0.5 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_311_287359" />
      </filter>
      <filter
        id="filter3_f_311_287359"
        x="17.2861"
        y="9.84766"
        width="11.5835"
        height="14.3594"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.75" result="effect1_foregroundBlur_311_287359" />
      </filter>
      <filter
        id="filter4_f_311_287359"
        x="20.683"
        y="11.393"
        width="4.27021"
        height="3.6418"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.2" result="effect1_foregroundBlur_311_287359" />
      </filter>
      <filter
        id="filter5_f_311_287359"
        x="8.32656"
        y="9.69375"
        width="4.94844"
        height="4.50898"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.2" result="effect1_foregroundBlur_311_287359" />
      </filter>
      <linearGradient
        id="paint0_linear_311_287359"
        x1="16"
        y1="3.03125"
        x2="16"
        y2="28.9688"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FEBD86" />
        <stop offset="1" stopColor="#FBA27F" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_311_287359"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(3.8125 18.2813) rotate(31.1088) scale(4.23389 11.9916)"
      >
        <stop stopColor="#B9886A" />
        <stop offset="1" stopColor="#B9886A" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_311_287359"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(20 4.03125) rotate(43.2987) scale(4.46558 6.9451)"
      >
        <stop stopColor="#D09F75" />
        <stop offset="1" stopColor="#CB976A" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint3_radial_311_287359"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(2 8.40625) rotate(4.80349) scale(7.46371 12.3629)"
      >
        <stop stopColor="#D09F75" />
        <stop offset="1" stopColor="#CB976A" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint4_radial_311_287359"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(29.625 6.21875) rotate(139.525) scale(6.1622 5.29375)"
      >
        <stop stopColor="#FFDAAE" />
        <stop offset="1" stopColor="#FFDAAE" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint5_radial_311_287359"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(12.75 4.65625) rotate(150.945) scale(4.50434 6.47726)"
      >
        <stop offset="0.1469" stopColor="#FFDAAE" />
        <stop offset="1" stopColor="#FFDAAE" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint6_radial_311_287359"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(28.125 14.7187) rotate(-134.661) scale(7.46895 4.24884)"
      >
        <stop stopColor="#F8A583" />
        <stop offset="1" stopColor="#F8A583" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint7_radial_311_287359"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(3.3125 14.4063) rotate(-10.2512) scale(5.9703 3.97299)"
      >
        <stop stopColor="#D48670" />
        <stop offset="1" stopColor="#D48670" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint8_radial_311_287359"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(2.75 25.0938) rotate(-43.6801) scale(9.59268 25.9243)"
      >
        <stop stopColor="#D48670" />
        <stop offset="1" stopColor="#D48670" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint9_radial_311_287359"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16 29.4062) rotate(-90) scale(7.875 14.5)"
      >
        <stop stopColor="#C8656A" />
        <stop offset="1" stopColor="#C8656A" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint10_radial_311_287359"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(13.3125 30.5313) rotate(-87.7542) scale(3.18995 8.51062)"
      >
        <stop stopColor="#C8656A" />
        <stop offset="1" stopColor="#C8656A" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint11_radial_311_287359"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(21.625 30.5313) rotate(-93.3665) scale(3.19301 8.51878)"
      >
        <stop stopColor="#C8656A" />
        <stop offset="1" stopColor="#C8656A" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint12_linear_311_287359"
        x1="15.0312"
        y1="21.2813"
        x2="16.5625"
        y2="23.0312"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F03273" />
        <stop offset="1" stopColor="#FF32AF" />
      </linearGradient>
      <radialGradient
        id="paint13_radial_311_287359"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16.7656 21.8594) rotate(-171.87) scale(2.54116 2.45548)"
      >
        <stop offset="0.451843" stopColor="#D53238" stopOpacity="0" />
        <stop offset="1" stopColor="#D53238" />
      </radialGradient>
      <radialGradient
        id="paint14_radial_311_287359"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17.3359 21.4766) rotate(151.589) scale(1.51887 0.892159)"
      >
        <stop stopColor="#FF8AD5" />
        <stop offset="0.663187" stopColor="#FF6CCB" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint15_linear_311_287359"
        x1="12.7266"
        y1="18.5156"
        x2="11.8673"
        y2="18.5156"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.225" stopColor="#594C4F" />
        <stop offset="1" stopColor="#392833" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_311_287359"
        x1="12.9585"
        y1="20.0593"
        x2="12.9585"
        y2="18.65"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#552D52" />
        <stop offset="1" stopColor="#552D52" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint17_radial_311_287359"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(12.2732 18.6952) rotate(-109.297) scale(1.73269 1.67065)"
      >
        <stop offset="0.813951" stopColor="#594C4F" stopOpacity="0" />
        <stop offset="1" stopColor="#594C4F" />
      </radialGradient>
      <linearGradient
        id="paint18_linear_311_287359"
        x1="20.7266"
        y1="18.5156"
        x2="19.8673"
        y2="18.5156"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.225" stopColor="#594C4F" />
        <stop offset="1" stopColor="#392833" />
      </linearGradient>
      <linearGradient
        id="paint19_linear_311_287359"
        x1="20.9585"
        y1="20.0593"
        x2="20.9585"
        y2="18.65"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#552D52" />
        <stop offset="1" stopColor="#552D52" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint20_radial_311_287359"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(20.2732 18.6952) rotate(-109.297) scale(1.73269 1.67065)"
      >
        <stop offset="0.813951" stopColor="#594C4F" stopOpacity="0" />
        <stop offset="1" stopColor="#594C4F" />
      </radialGradient>
      <radialGradient
        id="paint21_radial_311_287359"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(11.1484 9.90625) rotate(-161.626) scale(6.93975 10.2645)"
      >
        <stop stopColor="#FD9988" />
        <stop offset="1" stopColor="#FF8199" />
      </radialGradient>
      <radialGradient
        id="paint22_radial_311_287359"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(4.81247 13.8438) rotate(-58.05) scale(6.85024 7.04011)"
      >
        <stop stopColor="#FFCFB5" />
        <stop offset="0.867018" stopColor="#FFCFB5" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint23_radial_311_287359"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(22.125 11.4688) rotate(-44.2043) scale(6.36457 9.41379)"
      >
        <stop stopColor="#FFAD98" />
        <stop offset="1" stopColor="#FF8199" />
      </radialGradient>
      <radialGradient
        id="paint24_radial_311_287359"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(21.3125 13.6562) rotate(-43.76) scale(10.2112 9.9649)"
      >
        <stop stopColor="#FFCFB5" />
        <stop offset="0.867018" stopColor="#FFCFB5" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint25_radial_311_287359"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(22.625 11.5938) rotate(-41.6769) scale(6.86164 11.4936)"
      >
        <stop stopColor="#FFCFB5" />
        <stop offset="0.239722" stopColor="#FFCFB5" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint26_radial_311_287359"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(25.6978 18.8159) rotate(-173.201) scale(5.47641 15.9242)"
      >
        <stop offset="0.110122" stopColor="#FFDA9F" />
        <stop offset="1" stopColor="#FFDA9F" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint27_linear_311_287359"
        x1="22.5469"
        y1="11.7031"
        x2="22.5469"
        y2="14.3906"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCE9D" stopOpacity="0" />
        <stop offset="0.208333" stopColor="#FFD6AC" />
        <stop offset="0.755208" stopColor="#FFD9B4" />
        <stop offset="1" stopColor="#FFCE9D" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint28_linear_311_287359"
        x1="13.2201"
        y1="11.4274"
        x2="10.5449"
        y2="11.1704"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCE9D" stopOpacity="0" />
        <stop offset="0.208333" stopColor="#FFD6AC" />
        <stop offset="0.755208" stopColor="#FFD9B4" />
        <stop offset="1" stopColor="#FFCE9D" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint29_radial_311_287359"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(14.755 24.5625) rotate(104.087) scale(1.46596 1.73616)"
      >
        <stop offset="0.550264" stopColor="#4A2D2A" />
        <stop offset="0.725313" stopColor="#625358" />
        <stop offset="1" stopColor="#4C2E4C" />
      </radialGradient>
      <radialGradient
        id="paint30_radial_311_287359"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(13.4295 24.3359) rotate(60.5364) scale(1.38184 1.59746)"
      >
        <stop stopColor="#492F30" />
        <stop offset="1" stopColor="#492F30" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint31_radial_311_287359"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17.3044 24.1797) rotate(78.9646) scale(1.91828 1.60689)"
      >
        <stop offset="0.550264" stopColor="#4A2D2A" />
        <stop offset="0.725313" stopColor="#625358" />
        <stop offset="1" stopColor="#4C2E4C" />
      </radialGradient>
      <radialGradient
        id="paint32_radial_311_287359"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(18.5701 24.3359) rotate(119.464) scale(1.38184 1.59746)"
      >
        <stop stopColor="#492F30" />
        <stop offset="1" stopColor="#492F30" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint33_radial_311_287359"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16.0783 24.2656) rotate(92.8625) scale(1.56445 1.59277)"
      >
        <stop stopColor="#492F30" />
        <stop offset="1" stopColor="#492F30" stopOpacity="0" />
      </radialGradient>
    </defs>
  </SvgIcon>
);

export default Hamster;
