import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root: SxStyles = {
  height: '80px',
  boxShadow: '0px 1px 2px 0px #29294012',
};

export const overlay: SxStyles = {
  position: 'absolute',
  bottom: 0,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  width: '100%',

  backgroundColor: 'rgba(13, 13, 16, 0.48)',
  transition: 'height 0.2s',
};

export const rootOverlayTrigger = (isActive: boolean): SxStyles => {
  return {
    '& .theme-preview__overlay': {
      height: isActive ? '100%' : '24px',
    },

    '&:hover .theme-preview__overlay': {
      height: '100%',

      '& > *': {
        transform: isActive ? 'none' : 'scale(1.2)',
      },
    },
  };
};

export const overlayIcon: SxStyles = {
  color: NeutralColors.light_neutrals_0,

  width: '32px',
  height: '32px',
};

export const overlayText: SxStyles = {
  color: NeutralColors.light_neutrals_0,
  transition: 'transform 0.2s',
};
