import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root: SxStyles = {
  position: 'relative',
  display: 'grid',
  gridTemplateRows: 'auto 104px',
  gridTemplateColumns: '168px',
  width: '168px',
  height: '236px',

  border: '1px solid',
  borderColor: NeutralColors.light_neutrals_300,
  borderRadius: '12px',
  boxShadow: '0px 1px 2px rgba(41, 41, 64, 0.07)',
  backgroundColor: '#ffffff',

  overflow: 'hidden',
};

export const moreButton: SxStyles = {
  position: 'absolute',
  top: '8px',
  right: '8px',
};

export const previewPlaceholder: SxStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  height: '100%',
  padding: '8px',

  backgroundColor: NeutralColors.light_neutrals_200,
};

export const previewPlaceholderText: SxStyles = {
  fontWeight: 800,
  fontSize: '24px',
  lineHeight: '24px',
  letterSpacing: '10%',

  color: NeutralColors.light_neutrals_500,
  opacity: 0.4,

  userSelect: 'none',
};

export const body: SxStyles = {
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  padding: '12px',

  borderTop: '1px solid',
  borderColor: NeutralColors.light_neutrals_300,
  backgroundColor: '#ffffff',
};
