/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';
import genericReducers from '../genericReducer';
import { IReducers } from '../index';

import { GET_PARALLELS } from '../../actions/employees';
import { REQUEST, FAILURE, SUCCESS } from '../../actions/utils';

import { ICommonResponse, IParallels } from '../../api/types';

export type ParallelsState = ICommonResponse<IParallels>;

const initialState: ParallelsState = {
  content: [],
  loading: false,
};

const parallels: IReducers<ParallelsState> = {
  [GET_PARALLELS[REQUEST]]: genericReducers().get.request,
  [GET_PARALLELS[SUCCESS]]: genericReducers().get.success,
  [GET_PARALLELS[FAILURE]]: genericReducers().get.failure
};

export default connectReducers(initialState, parallels);
