import { FC, ReactNode } from 'react';

import { ThemeProvider } from '@mui/material';
import { SectionKeys, SectionNames } from 'const';
import { useSectionTheme } from 'hooks';
import { IconSectionScience } from 'icons';
import { scienceTheme } from 'portfolio3/styles/theme';

import SearchSection from '../components/SearchSection';
import { useSearchScience } from '../hooks';
import CommonEntityCardList from './CardLists/CommonEntityCardList';
import CommonRewardCardList from './CardLists/CommonRewardCardList';

const ScienceSection: FC = () => {
  const {
    rewards,
    projects,
    occupations,
    contests,
    isScienceRewardVisible,
    isScienceProjectVisible,
    isScienceOccupationVisible,
    isScienceContestVisible,
    isScienceVisible,
  } = useSearchScience();

  const theme = useSectionTheme(scienceTheme);

  if (!isScienceVisible) return null;

  const subsections: ReactNode[] = [];

  if (isScienceRewardVisible) {
    subsections.push(
      <SearchSection.Subsection title="Награды">
        <CommonRewardCardList rewards={rewards} />
      </SearchSection.Subsection>,
    );
  }

  if (isScienceProjectVisible) {
    subsections.push(
      <SearchSection.Subsection title="Проекты">
        <CommonEntityCardList entities={projects} />
      </SearchSection.Subsection>,
    );
  }

  if (isScienceOccupationVisible) {
    subsections.push(
      <SearchSection.Subsection title="Занятия">
        <CommonEntityCardList entities={occupations} />
      </SearchSection.Subsection>,
    );
  }

  if (isScienceContestVisible) {
    subsections.push(
      <SearchSection.Subsection title="Конкурсы, конференции и иные события">
        <CommonEntityCardList entities={contests} />
      </SearchSection.Subsection>,
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <SearchSection name={SectionNames[SectionKeys.SCIENCE]} icon={<IconSectionScience />} subsections={subsections} />
    </ThemeProvider>
  );
};

export default ScienceSection;
