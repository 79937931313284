import { IMappedLearningYearPerformance } from 'portfolio3/features/performance';

import { EducationLevel } from './educationLevels';

/**
 * Функция фильтрует входящие данные по уровню образования
 * @param performance Массив с данными по оценкам
 * @param educationLevel Выбранный уровень образования
 * @returns Отфильтрованный массив
 */
export const filterPerformanceByEducationLevel = (
  performance: IMappedLearningYearPerformance[],
  educationLevel: EducationLevel,
): IMappedLearningYearPerformance[] => {
  if (educationLevel === EducationLevel.ANY) return [...performance];

  return performance.filter((performanceObject) => {
    return performanceObject.educationLevel === educationLevel;
  });
};

/**
 * Функция принимает все предметы, которые могут быть показаны на графике,
 * и возвращает массив уникальных названий этих предметов
 * @param subjects Массив предметов за все периоды обучения
 * @returns Массив из уникальных названий предметов, отсортированный по алфавиту
 */
export const getAvailableSubjectNames = (subjects: IMappedLearningYearPerformance['subjects'][number][]): string[] => {
  const subjectSet = new Set<string>();
  subjects.forEach((subject) => subjectSet.add(subject.name));
  const subjectNames = Array.from(subjectSet);
  const sortedSubjectNames = subjectNames.sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1));
  return sortedSubjectNames;
};
