import { FC } from 'react';

import { Typography } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';

interface IWidgetContainerSubTitleProps {
  text: string;
}

const WidgetContainerSubTitle: FC<IWidgetContainerSubTitleProps> = ({ text }) => {
  return (
    <Typography variant="Paragraph MD/Regular" color={NeutralColors.light_neutrals_600}>
      {text}
    </Typography>
  );
};

export default WidgetContainerSubTitle;
