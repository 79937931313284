import { Theme } from '@mui/material';
import { SxStyles } from 'types';

/**
 * Позволяет исключить выбранные свойства объекта при добавлении на dom элемент
 * @param skippedProps массив ключей объекта
 * @returns коллбек для свойства shouldForwardProp в опциях styled()
 */
export const skipPropsForwarding = <Props>(skippedProps: (keyof Props)[]) => {
  return (prop: PropertyKey) => {
    return skippedProps.every((skippedProp) => skippedProp !== prop);
  };
};

/**
 * Функция принимает несколько объктов sx и возвращает массив для передачи в проп sx
 * Any нужен из-за ошибки от ts, когда на деле sx может принимать массив
 * @param sxObjects объекты sx
 * @returns sx[]
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mergeSx = (...sxObjects: (SxStyles | undefined | false)[]): any[] => {
  return sxObjects.filter((sx) => typeof sx !== 'boolean').flatMap((sx) => sx);
};

/**
 * Фукнция принимает Тему Mui или массив объектов sx \
 * Если аргумент - тема, возвращает новую ф-ю, которая принимает массив объектов sx
 * Any нужен из-за ошибки от ts, когда на деле sx может принимать массив
 * @param arg - Тема Mui или массив объектов sx
 * @returns Объект sx
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function inheritSx(arg: Theme | SxStyles[]): any {
  if (Array.isArray(arg)) return createSxObject(arg);

  return (...sxObjects: SxStyles[]) => createSxObject(sxObjects);
}

/**
 * Вспомогательная функция, которая принимает массив sx и формирует единый объект \
 * Также, по надобности, добавляет тему в sx
 * Any нужен из-за ошибки от ts, когда на деле sx может принимать массив
 * @param sxArray - массив sx объектов (SxStyles)
 * @param theme - тема Mui
 * @returns готовый объект стилей без повторений
 */
function createSxObject(sxArray: SxStyles[], theme?: Theme) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sxCombinedArray = sxArray.map((sx: any) => (typeof sx === 'function' ? sx(theme) : sx));
  return sxCombinedArray.reduce((prevSx, newSx) => ({ ...prevSx, ...newSx }));
}
