/* eslint-disable prettier/prettier */
/* eslint-disable */

import { FC, PropsWithChildren } from 'react';

import clsx from 'clsx';

import './index.scss';

interface IRecommendationCardTitleLinkProps {
  className?: string;
  color: string;
  href: string;
  disabled?: boolean;
}

const RecommendationCardTitleLink: FC<IRecommendationCardTitleLinkProps & PropsWithChildren> = ({
  className,
  color,
  href,
  disabled,
  children
}) => {
  return (
    <a
      href={disabled ? undefined : href}
      style={{ color }}
      className={clsx('recommendation-card-title-link', className)}
    >
      {children}
    </a>
  );
};

export default RecommendationCardTitleLink;
