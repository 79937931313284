/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import { Autocomplete as AutocompleteMaterial } from '@mui/material';
import { InputProps } from '@mui/material';
import { Colors } from '../../style/variables';

const useStyles = makeStyles({
  autoComplete: ({ multiline, error }: InputProps) => ({
    marginTop: '8px',
    boxSizing: 'border-box',
    border: '1px solid',
    borderColor: error ? Colors['red-rose'] : Colors['control-stroke'],
    borderRadius: '8px',
    outline: 'none',

    minHeight: '36px',
    display: 'flex',
    alignItems: 'center',

    '&.Mui-focused': {
      borderColor: Colors['blue-primrose']
    },

    '& input': {
      padding: multiline ? '6px 0 7px' : 'initial',
      '&::-webkit-input-placeholder': {
        color: Colors['disable-text'],
        opacity: 1
      }
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"]': {
      padding: '2px',
      paddingLeft: '16px',
      '&::before': {
        display: 'none'
      },
      '&::after': {
        display: 'none'
      }
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child': {
      paddingRight: '35px'
    },
    '& .MuiAutocomplete-endAdornment': {
      display: multiline ? 'none' : 'block'
    }
  })
});

const Autocomplete: React.FC<any> = (props) => {
  const classes = useStyles(props);

  return (
    <AutocompleteMaterial
      classes={{
        root: classes.autoComplete
      }}
      {...props}
    />
  );
};

export default Autocomplete;
