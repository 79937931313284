import { ICommonResponse } from 'api/types';
import { EntityType, SectionCodes, SourceCode } from 'const';
import { cardIllustrations } from 'images';
import { SectionRefState } from 'reducers/reference';
import { StudentEmploymentsState } from 'reducers/student/employments';
import { StudentEventsState } from 'reducers/student/events';
import { StudentProjectsState } from 'reducers/student/projects';
import { StudentRewardsState } from 'reducers/student/rewards';
import { mapRewardEntity, mapScienceContestToEntity, mapScienceProjectToEntity } from 'selectors/entityMappers';
import { IEntity, IEntityFeature, IModifiedReward } from 'types';
import { flagsMap, getDateRange, getEntityGeneralInfo, getScienceEntityBackgroundImage, getYearFromDate } from 'utils';

export const mapScienceContestsResponse = (
  events: StudentEventsState,
  rewards: StudentRewardsState,
  sectionRef: SectionRefState,
): ICommonResponse<IEntity> => {
  const boundRewards = rewards.content?.filter((reward) => reward.entityId);
  const contests = events.content
    .filter((event) => event.categoryCode === SectionCodes.science)
    .map((event) => {
      const { id, recordId } = event;

      const eventReward = boundRewards?.find((reward) => reward.entityId == id || reward.entityId == recordId);
      return mapScienceContestToEntity(sectionRef.content, event, eventReward);
    });

  return {
    ...events,
    content: contests,
  };
};

export const mapScienceOccupationsResponse = (employments: StudentEmploymentsState): ICommonResponse<IEntity> => {
  const scienceEmployments = employments.content.filter(
    (employment) => employment.categoryCode === SectionCodes.science,
  );

  const occupations = scienceEmployments.map((employment): IEntity => {
    const {
      id,
      recordId,
      subcategory,
      name,
      source,
      startDate,
      disciplines,
      achievementActivityFormat,
      type,
      location,
      endDate,
    } = employment;

    const occupationTypes: string[] = [];
    if (subcategory) occupationTypes.push(subcategory.value);
    if (disciplines) {
      occupationTypes.push(...disciplines.map((discipline) => discipline.value));
    }

    const occupationFeatures: IEntityFeature[] = [];
    if (type) occupationFeatures.push({ text: type.value, isColored: true });
    if (achievementActivityFormat) occupationFeatures.push({ text: achievementActivityFormat.value });

    return {
      id,
      recordId,
      name,
      types: occupationTypes,
      date: getDateRange(startDate, endDate),
      year: getYearFromDate(endDate || startDate),
      features: occupationFeatures,
      backgroundImage: getScienceEntityBackgroundImage(subcategory?.code),
      place: location,
      formData: employment,
      cardData: {
        name,
        generalInfo: getEntityGeneralInfo(employment),
        files: employment.fileReferences,
        linkedObjects: employment.linkedObjects,
        formData: employment,
        illustrationFallback: cardIllustrations.scienceOccupation,
      },
      entityProps: {
        type: EntityType.EMPLOYMENT,
        flags: flagsMap({ approved: source?.code !== SourceCode.student && source?.code !== SourceCode.parent }),
      },
    };
  });

  return {
    ...employments,
    content: occupations,
  };
};

export const mapScienceProjectsResponse = (
  projects: StudentProjectsState,
  rewards: StudentRewardsState,
): ICommonResponse<IEntity> => {
  const boundRewards = rewards.content?.filter((reward) => reward.entityId);
  const scienceProjects = projects.content.map((project) => {
    const { id, recordId } = project;
    const projectReward = boundRewards?.find((reward) => reward.entityId == id || reward.entityId == recordId);
    return mapScienceProjectToEntity(project, projectReward);
  });

  return {
    ...projects,
    content: scienceProjects,
  };
};

export const mapScienceRewardsResponse = (
  events: StudentEventsState,
  projects: StudentProjectsState,
  rewards: StudentRewardsState,
  sectionRef: SectionRefState,
): ICommonResponse<IModifiedReward> => {
  const mergedEventsAndProjects = [...events.content, ...projects.content];
  const scienceRewards = rewards.content
    .filter((reward) => reward.categoryCode === SectionCodes.science)
    .map((reward) => {
      return mapRewardEntity(reward, mergedEventsAndProjects, sectionRef.content);
    });

  return {
    ...rewards,
    content: scienceRewards,
  };
};
