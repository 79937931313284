import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { RequestCacheStatus } from 'api/types';
import { IRootState } from 'reducers';
import { linkVisibilitySettingsSelector, visibilitySettingsSelector } from 'selectors';

export interface IDataRequest {
  getStatus: () => RequestCacheStatus | undefined;
  action: () => void;
}

interface IUseDataLoadProps {
  shouldLoad: boolean;
  requests: IDataRequest[];
  visibilitySettingsCheck?: boolean;
}

const useDataLoad = ({ shouldLoad, requests, visibilitySettingsCheck = true }: IUseDataLoadProps) => {
  const visibilitySettings = useSelector((state: IRootState) => visibilitySettingsSelector(state));
  const linkVisibilitySettings = useSelector((state: IRootState) => linkVisibilitySettingsSelector(state));
  const linkMode = useSelector((state: IRootState) => state.linkMode.mode);

  const settings = linkMode ? linkVisibilitySettings : visibilitySettings;
  const isVisiblitySettingsLoading = settings.length === 0;

  useEffect(() => {
    if (!shouldLoad) return;
    if (visibilitySettingsCheck && isVisiblitySettingsLoading) return;

    requests.forEach((request) => {
      const keepFromRequestStatuses: RequestCacheStatus[] = ['cached', 'requested', 'failed'];
      const status = request.getStatus();

      if (status && keepFromRequestStatuses.includes(status)) return;

      request.action();
    });
  }, [shouldLoad, requests, isVisiblitySettingsLoading, visibilitySettingsCheck]);
};

export default useDataLoad;
