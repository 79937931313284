export enum InputSizeKeys {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum InputRenderModeKeys {
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
}

export type InputSize = Lowercase<keyof typeof InputSizeKeys>;
export type InputRenderMode = Lowercase<keyof typeof InputRenderModeKeys>;

export interface IInputTokens {
  minHeight: number;
  fontSize: number;
  lineHeight: number;
  paddingBlock: number;
  paddingLeft: number;
  paddingRight: number;
}
