/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useContext, useMemo } from 'react';

import { RewardSecondaryBlock } from '../basicBlocks';
import { DifferentValue } from '../../../../../const';

import { BaseInputLabel, FormControl, Input } from 'portfolio3/ui-kit';
import { FormHelperText } from '@mui/material';
import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';
import { getCommonInputControllerFactory } from "../../utils";

interface INameRewardSecondaryBlockProps {
  result?: string;
}

const NameRewardSecondaryBlock: React.FC<INameRewardSecondaryBlockProps> = ({
  result,
}) => {
  const { inputRenderMode, inputSize, formData, formErrors, onChangeFormData, onChangeFormErrors } = useContext(LocalDataEntryFormContext);

  const inputController = getCommonInputControllerFactory(onChangeFormData, onChangeFormErrors);

  const secondNameController = useMemo(() => inputController('secondName', 'secondNameError'), [formData.id]);

  const additionalTopControllers = (
    <>
      {result?.trim() === DifferentValue && (
        <FormControl
        required
          renderMode={inputRenderMode}
          inputSize={inputSize}
          label={<BaseInputLabel>Название награды</BaseInputLabel>}
          error={formErrors.secondNameError}
          helper={formErrors.secondNameError && <FormHelperText>Введите название награды</FormHelperText>}
          control={
            <Input
              renderMode={inputRenderMode}
              size={inputSize}
              placeholder="Введите название награды..."
              value={formData.secondName}
              controller={secondNameController}
            />
          }
        />
      )}
    </>
  );

  return (
    <RewardSecondaryBlock
      additionalTopControllers={additionalTopControllers}
    />
  );
};

export default NameRewardSecondaryBlock;
