import { FC } from 'react';

import { IconButton } from '@mui/material';
import MobileDragIndicator from 'components/common/MobileDragIndicator';
import ModalContainer from 'components/common/ModalContainer';
import { iconClose } from 'icons';
import { Drawer } from 'ui-kit';

import SubjectThemesContainer from '../../containers/SubjectThemesContainer';
import { IMappedLearningYearPerformance } from '../../types';

import './index.scss';

interface ISubjectThemesDrawerProps {
  isOpen: boolean;
  yearPerformance: IMappedLearningYearPerformance | undefined;
  selectedSubjectId: number | undefined;
  onClose: () => void;
}

const SubjectThemesDrawer: FC<ISubjectThemesDrawerProps> = ({
  isOpen,
  yearPerformance,
  selectedSubjectId,
  onClose,
}) => {
  return (
    <ModalContainer>
      <Drawer
        isContainerScrollable={false}
        isFullHeight
        anchor="bottom"
        transitionDuration={{ appear: 300, enter: 300, exit: 250 }}
        open={isOpen}
        onClose={onClose}
      >
        <div className="subject-themes-drawer">
          <div className="subject-themes-drawer__header">
            <MobileDragIndicator onTouchStart={onClose} />
            <IconButton className="button-close" disableTouchRipple onClick={onClose} size="large">
              <img src={iconClose} alt="закрыть" />
            </IconButton>
          </div>

          <div className="subject-themes-drawer__content">
            <SubjectThemesContainer selectedSubjectId={selectedSubjectId} yearPerformance={yearPerformance} />
          </div>
        </div>
      </Drawer>
    </ModalContainer>
  );
};

export default SubjectThemesDrawer;
