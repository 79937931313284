import { FC, ReactNode } from 'react';

import { Box, ListItemIcon, menuItemClasses, MenuProps, svgIconClasses, SxProps } from '@mui/material';
import TrimText from 'portfolio3/components/common/TrimText';
import { BaseMenu, BaseMenuItem } from 'portfolio3/ui-kit/menu';

import { IModalSelectBaseProps, ModalSelectBase } from '../../bases';
import { defaultRenderOption, useSelectMenuList } from '../../hooks';
import { IIconSelectItem, ISelectOptionsListSettings } from '../../types';

interface IIconSelectProps
  extends Omit<IModalSelectBaseProps<MenuProps>, 'ModalComponent' | 'ModalComponentProps' | 'children'> {
  options?: IIconSelectItem[];
}

const IconSelect: FC<IIconSelectProps & ISelectOptionsListSettings> = ({
  options,
  addOtherOption,
  markOtherOption,
  markFirstOption,
  ...props
}) => {
  const TypedSelect = ModalSelectBase<MenuProps>;

  const menuList = useSelectMenuList({
    options: options ?? [],
    addOtherOption,
    markOtherOption,
    markFirstOption,
    renderOption: (option) => {
      const isIconOption = 'startIcon' in option;

      if (!isIconOption) {
        return defaultRenderOption(option);
      }

      const menyItemStyles: SxProps = {
        [`&&&.${menuItemClasses.selected}`]: {
          ...(option.selectedBackgroundColor && {
            backgroundColor: option.selectedBackgroundColor,
          }),
          ...(option.selectedIconColor && {
            [`& .${svgIconClasses.root} path`]: {
              fill: option.selectedIconColor,
              color: option.selectedIconColor,
            },
          }),
        },
      };

      return (
        <BaseMenuItem key={option.code} value={option.code} sx={menyItemStyles}>
          {option.startIcon && <ListItemIcon>{option.startIcon}</ListItemIcon>}
          <TrimText>{option.value}</TrimText>
        </BaseMenuItem>
      );
    },
  });

  const renderValue = (value: string | undefined): ReactNode => {
    const optionCode = Number(value);
    const renderedOption = options?.find((option) => option.code === optionCode);
    const optionIcon = renderedOption?.startIcon;
    const optionValue = renderedOption?.value;

    const boxStyles: SxProps = {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',

      [`& .${svgIconClasses.root}`]: {
        width: '20px',
        height: '20px',
      },
    };

    return (
      <Box sx={boxStyles}>
        {optionIcon}
        {optionValue}
      </Box>
    );
  };

  return (
    <TypedSelect
      {...props}
      multiple={false}
      ModalComponent={BaseMenu}
      ModalComponentProps={{}}
      renderValue={renderValue}
    >
      {menuList}
    </TypedSelect>
  );
};

export default IconSelect;
