import { FC } from 'react';

import { Stack, Typography } from '@mui/material';
import { Attributes } from 'portfolio3/ui-kit';

import CardPadding from '../CardPadding';
import { clickableTitleStyles } from '../utils';

interface IInterestCardProps {
  name: string;
  actions: string[];
  onView: () => void;
}

const InterestCard: FC<IInterestCardProps> = ({ name, actions, onView }) => {
  const mappedActionElements = actions.map((action) => (
    <Typography key={action} variant="Paragraph MD/Regular">
      {action}
    </Typography>
  ));

  return (
    <CardPadding withHorizontalPadding>
      <Stack className="interest-card" gap="4px">
        <Typography component="button" variant="Paragraph LG/Semi Bold" sx={clickableTitleStyles} onClick={onView}>
          {name}
        </Typography>
        <Attributes attributes={mappedActionElements} />
      </Stack>
    </CardPadding>
  );
};

export default InterestCard;
