/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { IPerformanceSubjectInfo } from '../types';

interface IPerformanceSubjectContext {
  selectedSubject?: IPerformanceSubjectInfo;
  setSelectedSubject: React.Dispatch<React.SetStateAction<IPerformanceSubjectInfo | undefined>>;
}

export default React.createContext({} as IPerformanceSubjectContext);
