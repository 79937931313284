import { IDictionaryItem } from 'api/types';
import { DIAGNOSTIC_SUBJECT_ALL_CODE } from 'const';
import { IRootState } from 'reducers';
import { createSelector } from 'reselect';

import independentDiagnosticListSelector from './independentDiagnosticList';

const independentDiagnosticListSubjectsSelector = createSelector(
  [(state: IRootState) => independentDiagnosticListSelector(state)],
  (independentDiagnostics): IDictionaryItem[] => {
    const uniqueSubjects = new Set(independentDiagnostics.content.map((diagnostic) => diagnostic.subject));

    const dictionarySubjects = Array.from(uniqueSubjects).map((subject, index): IDictionaryItem => {
      return {
        code: index + 1,
        value: subject,
      };
    });

    dictionarySubjects.unshift({
      code: DIAGNOSTIC_SUBJECT_ALL_CODE,
      value: 'Все предметы',
    });

    return dictionarySubjects;
  },
);

export default independentDiagnosticListSubjectsSelector;
