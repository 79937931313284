import { StudentSectionSettingTypeCodes } from 'const';
import { useAppSelector } from 'hooks';
import { visibilitySettingsSelector } from 'selectors';
import { findTargetVisibilitySetting } from 'utils/localVisibilitySettingsService';

import { collection as searchActions } from '../model/actions';
import {
  searchCreationClubsSelector,
  searchCreationContestsSelector,
  searchCreationOccupationsSelector,
  searchCreationRewardsSelector,
} from '../model/selectors';

const creationCode = StudentSectionSettingTypeCodes.creation;
const rewardCode = StudentSectionSettingTypeCodes.creationReward;
const clubCode = StudentSectionSettingTypeCodes.creationClub;
const contestCode = StudentSectionSettingTypeCodes.creationContest;
const occupationCode = StudentSectionSettingTypeCodes.creationUnit;

const useSearchCreation = () => {
  const settings = useAppSelector(visibilitySettingsSelector);
  const { loading: isRewardsLoading, content: rewards } = useAppSelector(searchCreationRewardsSelector);
  const { loading: isClubsLoading, content: clubs } = useAppSelector(searchCreationClubsSelector);
  const { loading: isContestsLoading, content: contests } = useAppSelector(searchCreationContestsSelector);
  const { loading: isOccupationsLoading, content: occupations } = useAppSelector(searchCreationOccupationsSelector);

  const creationEntitiesCount = [rewards.length, clubs.length, contests.length, occupations.length].reduce(
    (prev, current) => prev + current,
    0,
  );

  const hasRewards = rewards.length > 0;
  const hasClubs = clubs.length > 0;
  const hasContests = contests.length > 0;
  const hasOccupations = occupations.length > 0;

  const isRewardsEnabled =
    findTargetVisibilitySetting(settings, [creationCode, rewardCode])?.computedVisibility ?? false;
  const isClubsEnabled = findTargetVisibilitySetting(settings, [creationCode, clubCode])?.computedVisibility ?? false;
  const isContestsEnabled =
    findTargetVisibilitySetting(settings, [creationCode, contestCode])?.computedVisibility ?? false;
  const isOccupatonsEnabled =
    findTargetVisibilitySetting(settings, [creationCode, occupationCode])?.computedVisibility ?? false;

  const isCreationRewardVisible = hasRewards && isRewardsEnabled;
  const isCreationClubVisible = hasClubs && isClubsEnabled;
  const isCreationContestVisible = hasContests && isContestsEnabled;
  const isCreationOccupationVisible = hasOccupations && isOccupatonsEnabled;

  const isCreationLoading = isRewardsLoading || isClubsLoading || isContestsLoading || isOccupationsLoading;
  const isCreationVisible =
    isCreationRewardVisible || isCreationClubVisible || isCreationContestVisible || isCreationOccupationVisible;

  const creationActions = [
    isRewardsEnabled && searchActions.searchRewardsActions,
    isClubsEnabled && searchActions.searchAffiliationsActions,
    isContestsEnabled && searchActions.searchEventsActions,
    isOccupatonsEnabled && searchActions.searchEmploymentsActions,
  ];

  return {
    rewards,
    clubs,
    contests,
    occupations,
    isCreationRewardVisible,
    isCreationClubVisible,
    isCreationContestVisible,
    isCreationOccupationVisible,
    isCreationLoading,
    isCreationVisible,
    creationActions,
    creationEntitiesCount,
  };
};

export default useSearchCreation;
