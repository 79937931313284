import { FC } from 'react';

import MesUiGlobalHeader from '@mes-ui/global-header';

import '@mes-ui/global-header/styles.css';

const GlobalHeader: FC = () => {
  // const handleLogout = useCallback(() => {
  //   removeAuthToken();
  //   redirectToChoicePage();
  // }, []);

  return <MesUiGlobalHeader />;
};

export default GlobalHeader;
