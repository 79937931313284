import { useContext } from 'react';

import { useMediaQuery } from '@mui/material';
import { PortfolioSettingsContext } from 'context';
import { useAppSelector } from 'hooks';
import { commonTheme } from 'portfolio3/styles/theme';

const useHeaderHeight = () => {
  const isAdminViewMode = useAppSelector((state) => state.adminViewMode.isViewing);

  const { isSettingsMode } = useContext(PortfolioSettingsContext);
  const isHeaderNavigationHidden = useMediaQuery(commonTheme.breakpoints.down('commonMd'));

  let headerHeight = 0;

  if (isSettingsMode) {
    headerHeight = 180;
  } else if (isAdminViewMode) {
    headerHeight = 66;
  } else {
    headerHeight = isHeaderNavigationHidden ? 0 : 130;
  }

  return headerHeight;
};

export default useHeaderHeight;
