import { Children, FC, PropsWithChildren } from 'react';

import { Box, ButtonGroup } from '@mui/material';
import { SxStyles } from 'types';

import { isDefined, mergeSx } from '../../../../utils';
import { isGroupedActionButton } from '../utils';
import ButtonMore from './buttonMore';
import * as styles from './styles';

interface IGroupedActionButtonsContainerProps {
  // TODO удалить когда все дроверы будут обновлены
  disableAbsolutePosition?: boolean;
}

const GroupedActionButtonsContainer: FC<IGroupedActionButtonsContainerProps & PropsWithChildren> = ({
  disableAbsolutePosition,
  children,
}) => {
  const actionButtons = Children.map(children, (child) => {
    if (isGroupedActionButton(child))
      return {
        ...child,
        props: {
          ...child.props,
        },
      };
  })?.filter(isDefined);

  const restChilds = Children.map(children, (child) => {
    if (!isGroupedActionButton(child)) return child;
  })?.filter(isDefined);

  const actionsCount = actionButtons?.length || 0;
  const visibleActionsCount = actionsCount > 2 ? 1 : 2;

  const visibleButtonComponents = actionButtons?.slice(0, visibleActionsCount);
  const hiddenButtonComponents = actionButtons?.slice(visibleActionsCount);

  if (actionsCount === 0) {
    return null;
  }

  // TODO удалить когда будут обновлены все дроверы
  const extraStyles: SxStyles[] = [];
  if (!disableAbsolutePosition) {
    extraStyles.push({
      zIndex: 100,
      position: 'absolute',
      left: 0,
      bottom: 0,
    });
  }

  return (
    <Box className="grouped-action-buttons-container" sx={mergeSx(styles.root, ...extraStyles)}>
      <ButtonGroup
        sx={styles.buttonGroup}
        fullWidth
        disableElevation
        disableRipple
        disableFocusRipple
        className="grouped-action-buttons-container__group"
        variant="text"
      >
        {visibleButtonComponents}
        {actionsCount > 2 && <ButtonMore hiddenButtons={hiddenButtonComponents} />}
        {restChilds}
      </ButtonGroup>
    </Box>
  );
};

export default GroupedActionButtonsContainer;
