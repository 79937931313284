import { FC, ReactNode } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { IconCheckmark } from 'icons';
import { NeutralColors } from 'portfolio3/styles';
import { mergeSx } from 'utils';

import * as styles from './styles';

const steps = ['Выбор раздела', 'Выбор файла', 'Предпросмотр', 'Результат'];

interface IImportStepperProps {
  currentStep: number;
}

const ImportStepper: FC<IImportStepperProps> = ({ currentStep }) => {
  return (
    <Box className="import-stepper" sx={styles.root}>
      {steps.map((step, index, array) => {
        const stepNumber = index + 1;
        const isLast = stepNumber === array.length;
        const isCurrent = stepNumber === currentStep;
        const isCompleted = stepNumber < currentStep;
        const isNotStarted = stepNumber > currentStep;

        let iconElement: ReactNode = null;

        if (isCurrent) {
          iconElement = (
            <Box sx={styles.stepIconCurrent}>
              <Box sx={styles.stepIconCurrentOuter}>
                <Box sx={styles.stepIconCurrentInner}>
                  <Typography variant="Paragraph SM/Semi Bold">{stepNumber}</Typography>
                </Box>
              </Box>
            </Box>
          );
        }

        if (isCompleted) {
          iconElement = (
            <Box sx={styles.stepIconCompleted}>
              <IconCheckmark />
            </Box>
          );
        }

        if (isNotStarted) {
          iconElement = (
            <Box sx={styles.stepIconNotStarted}>
              <Typography variant="Paragraph SM/Semi Bold" color={NeutralColors.light_neutrals_700}>
                {stepNumber}
              </Typography>
            </Box>
          );
        }

        return (
          <Stack key={step} gap="8px">
            <Box
              sx={mergeSx(
                !isLast && styles.iconContainer,
                isCompleted ? styles.stepLineCompleted : styles.stepLineInProgress,
              )}
            >
              {iconElement}
            </Box>
            <Typography
              variant="Paragraph MD/Medium"
              color={isNotStarted ? NeutralColors.light_neutrals_600 : NeutralColors.light_neutrals_900}
            >
              {step}
            </Typography>
          </Stack>
        );
      })}
    </Box>
  );
};

export default ImportStepper;
