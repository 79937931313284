import { FC, PropsWithChildren } from 'react';

import { Box } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

const styles = (type: IOptionsPanelProps['type']): SxStyles => {
  return {
    width: type === 'presets' ? '244px' : 'max-content',
    padding: type === 'presets' ? '12px' : '16px',

    borderLeft: '1px solid',
    borderColor: NeutralColors.light_neutrals_300,
    backgroundColor: NeutralColors.light_neutrals_200,
  };
};

interface IOptionsPanelProps {
  type: 'presets' | 'time';
}

const OptionsPanel: FC<IOptionsPanelProps & PropsWithChildren> = ({ type, children }) => {
  return (
    <Box className="options-panel" sx={styles(type)}>
      {children}
    </Box>
  );
};

export default OptionsPanel;
