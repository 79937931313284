/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

const IconCandidateMaster: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="50" height="50" fill="#E5E5E5"/>
    <rect width="1924" height="1397" transform="translate(-1169 -649)" fill="white"/>
    <rect width="50" height="50" fill="#E83F40"/>
    <path d="M45.2738 2H4.72618C3.22055 2 2 3.22055 2 4.72618V45.2738C2 46.7794 3.22055 48 4.72618 48H45.2738C46.7794 48 48 46.7794 48 45.2738V4.72618C48 3.22055 46.7794 2 45.2738 2Z" fill="#E83F40"/>
    <path d="M43.1564 5.38239H6.8435C6.03652 5.38239 5.38232 6.03658 5.38232 6.84356V43.1565C5.38232 43.9635 6.03652 44.6177 6.8435 44.6177H43.1564C43.9634 44.6177 44.6176 43.9635 44.6176 43.1565V6.84356C44.6176 6.03658 43.9634 5.38239 43.1564 5.38239Z" fill="#969696"/>
    <path d="M10.8618 31.9406C10.7671 32.2112 10.6927 32.5088 10.598 32.793C10.5033 32.5088 10.4221 32.2112 10.3342 31.9406L9.58328 29.9112H8.42651V34.4706H9.36004V32.8132C9.36004 32.36 9.2721 31.6903 9.22475 31.2438L9.59004 32.333L10.2665 34.0985H10.8415L11.518 32.333L11.8968 31.2438C11.8495 31.6903 11.7683 32.36 11.7683 32.8132V34.4706H12.7153V29.8706H11.5856L10.8618 31.9406Z" fill="#747778"/>
    <path d="M16.4833 29.8977L15.0356 34.4706H16.0977L16.3818 33.3747H17.7889L18.073 34.4706H19.1689L17.728 29.8706L16.4833 29.8977ZM16.5915 32.6035L16.7065 32.1774C16.8351 31.7174 16.9568 31.1762 17.0651 30.6891C17.1936 31.1694 17.3153 31.7174 17.4439 32.1774L17.5521 32.6035H16.5915Z" fill="#747778"/>
    <path d="M23.3832 33.6859C22.7067 33.6859 22.2738 33.138 22.2738 32.1909C22.2738 31.2438 22.7676 30.7094 23.3967 30.7094C23.7067 30.7199 24.0004 30.851 24.2152 31.0747L24.7632 30.3983C24.409 30.0273 23.9229 29.811 23.4102 29.7962C23.105 29.7995 22.8037 29.8661 22.5255 29.9919C22.2473 30.1177 21.9983 30.2998 21.7942 30.5269C21.5901 30.7539 21.4353 31.0208 21.3397 31.3107C21.2441 31.6007 21.2097 31.9073 21.2388 32.2112C21.2388 33.7603 22.2129 34.5721 23.3832 34.5721C23.6651 34.5781 23.9447 34.5203 24.2012 34.4032C24.4578 34.2861 24.6845 34.1126 24.8646 33.8956L24.3167 33.2191C24.2058 33.3616 24.0643 33.4775 23.9028 33.5583C23.7413 33.639 23.5637 33.6826 23.3832 33.6859Z" fill="#747778"/>
    <path d="M28.1726 34.4976H29.2076V30.7703H30.4726V29.8976H26.9009V30.7703H28.1726V34.4976Z" fill="#747778"/>
    <path d="M35.9725 33.625H34.0581V32.5697H35.6275V31.6971H34.0581V30.7703H35.8981V29.8976H33.0164V34.4976H35.9725V33.625Z" fill="#747778"/>
    <path d="M39.7471 32.935H40.3153C41.2894 32.935 42.1147 32.4615 42.1147 31.3791C42.1147 30.2968 41.303 29.8976 40.2883 29.8976H38.7053V34.4706H39.7471V32.935ZM39.7471 30.7229H40.2274C40.7889 30.7229 41.1 30.8988 41.1 31.3994C41.1 31.9 40.8294 32.13 40.2544 32.13H39.7471V30.7229Z" fill="#747778"/>
    <path d="M10.3882 40.3626C9.71173 40.3626 9.27203 39.8147 9.27203 38.8676C9.27203 37.9206 9.77262 37.3861 10.4017 37.3861C10.7097 37.397 11.0012 37.5281 11.2135 37.7514L11.7614 37.075C11.5862 36.8916 11.3764 36.7447 11.1443 36.6425C10.9121 36.5404 10.6621 36.485 10.4085 36.4797C10.1033 36.4821 9.80189 36.5479 9.52341 36.6729C9.24493 36.7978 8.99542 36.9792 8.79067 37.2056C8.58592 37.4319 8.43039 37.6984 8.33393 37.9879C8.23746 38.2775 8.20216 38.584 8.23026 38.8879C8.2 39.1842 8.23221 39.4836 8.3248 39.7668C8.4174 40.0499 8.56832 40.3104 8.76784 40.5316C8.96735 40.7528 9.21103 40.9297 9.48314 41.0509C9.75524 41.1721 10.0497 41.2349 10.3476 41.2353C10.6304 41.2403 10.9107 41.1822 11.1682 41.0652C11.4256 40.9482 11.6537 40.7752 11.8359 40.5588L11.2879 39.8823C11.1819 40.023 11.0465 40.1389 10.8911 40.2218C10.7357 40.3048 10.5641 40.3528 10.3882 40.3626Z" fill="#747778"/>
    <path d="M14.2847 41.1744H15.3264V37.447H16.835V41.1744H17.8767V36.5744H14.2847V41.1744Z" fill="#747778"/>
    <path d="M22.5918 36.5C21.3471 36.5 20.5015 37.3591 20.5015 38.8609C20.5015 40.3626 21.3471 41.2353 22.5918 41.2353C23.8365 41.2353 24.6753 40.3356 24.6753 38.8271C24.6753 37.3185 23.8297 36.5 22.5918 36.5ZM22.5918 40.3694C21.9626 40.3694 21.5635 39.7876 21.5635 38.8609C21.5635 37.9341 21.9626 37.3929 22.5918 37.3929C23.2209 37.3929 23.6471 37.9341 23.6471 38.8541C23.6471 39.7741 23.2141 40.3626 22.5918 40.3626V40.3694Z" fill="#747778"/>
    <path d="M28.8762 36.5744H27.2932V41.1744H28.335V39.6185H28.9032C29.8773 39.6185 30.7094 39.1382 30.7094 38.0559C30.7094 36.9735 29.8909 36.5744 28.8762 36.5744ZM28.8762 38.7865H28.3688V37.3997H28.8491C29.4106 37.3997 29.7217 37.5756 29.7217 38.0762C29.7217 38.5768 29.4173 38.7865 28.8423 38.7865H28.8762Z" fill="#747778"/>
    <path d="M32.793 37.447H34.058V41.1744H35.0997V37.447H36.3647V36.5744H32.793V37.447Z" fill="#747778"/>
    <path d="M39.4088 36.5744L37.9612 41.1744H39.0232L39.3074 40.0785H40.7144L40.9985 41.1744H42.0944L40.6535 36.5744H39.4088ZM39.5171 39.2803L39.6321 38.8541C39.7606 38.3941 39.8824 37.8529 39.9906 37.3659C40.1191 37.8462 40.2409 38.3941 40.3694 38.8541L40.4777 39.2803H39.5171Z" fill="#747778"/>
    <path d="M10.4898 31.7174C10.3951 31.988 10.3207 32.2856 10.226 32.563C10.1313 32.2856 10.0501 31.988 9.9622 31.7174L9.21132 29.688H8.08838V34.288H9.01514V32.5968C9.01514 32.1503 8.93397 31.4806 8.88661 31.0342L9.25191 32.1233L9.92838 33.8889H10.5034L11.1798 32.1233L11.5587 31.0342C11.5113 31.4806 11.4301 32.1503 11.4301 32.5968V34.288H12.3772V29.688H11.2137L10.4898 31.7174Z" fill="#D8D8D8"/>
    <path d="M16.1112 29.6744L14.6636 34.2744H15.7256L16.0098 33.1785H17.4168L17.7009 34.2744H18.7968L17.3559 29.6744H16.1112ZM16.2195 32.3803L16.3345 31.9541C16.463 31.4941 16.5848 30.9597 16.693 30.4726C16.8215 30.9529 16.9433 31.4941 17.0718 31.9541L17.18 32.3803H16.2195Z" fill="#D8D8D8"/>
    <path d="M23.0112 33.4627C22.3348 33.4627 21.8951 32.9079 21.8951 31.9677C21.8951 31.0274 22.3957 30.4862 23.0248 30.4862C23.1777 30.4893 23.3284 30.5233 23.4679 30.586C23.6074 30.6488 23.7328 30.7391 23.8365 30.8515L24.3912 30.175C24.2113 29.9885 23.996 29.8398 23.7578 29.7376C23.5197 29.6354 23.2636 29.5817 23.0045 29.5797C22.6992 29.583 22.3979 29.6497 22.1198 29.7754C21.8416 29.9012 21.5925 30.0834 21.3884 30.3104C21.1843 30.5374 21.0295 30.8043 20.9339 31.0942C20.8383 31.3842 20.804 31.6908 20.833 31.9947C20.833 33.5438 21.8071 34.3488 22.9706 34.3488C23.2534 34.3539 23.5337 34.2958 23.7912 34.1787C24.0487 34.0617 24.2768 33.8887 24.4589 33.6724L23.9109 32.9959C23.8036 33.1341 23.6676 33.2474 23.5123 33.3279C23.357 33.4085 23.186 33.4545 23.0112 33.4627Z" fill="#D8D8D8"/>
    <path d="M27.7938 34.2744H28.8356V30.547H30.1006V29.6744H26.5288V30.547H27.7938V34.2744Z" fill="#D8D8D8"/>
    <path d="M35.5935 33.4017H33.6858V32.3397H35.2552V31.467H33.6858V30.547H35.5258V29.6744H32.644V34.2744H35.5935V33.4017Z" fill="#D8D8D8"/>
    <path d="M39.375 32.7117H39.9433C40.9174 32.7117 41.7427 32.2314 41.7427 31.1491C41.7427 30.0667 40.9241 29.6744 39.9162 29.6744H38.3333V34.2744H39.375V32.7117ZM39.375 30.4997H39.8824C40.4438 30.4997 40.755 30.6688 40.755 31.1761C40.755 31.6835 40.4777 31.9135 39.9094 31.9135H39.4021L39.375 30.4997Z" fill="#D8D8D8"/>
    <path d="M10.0096 40.1394C9.33313 40.1394 8.90019 39.5847 8.90019 38.6444C8.90019 37.7041 9.40078 37.1765 10.0299 37.1765C10.1827 37.1804 10.3332 37.2147 10.4726 37.2774C10.6119 37.3401 10.7374 37.43 10.8417 37.5418L11.3896 36.8653C11.0244 36.4912 10.5257 36.2771 10.0028 36.27C9.69788 36.2743 9.3971 36.3417 9.11941 36.4678C8.84171 36.5939 8.5931 36.776 8.38918 37.0028C8.18525 37.2296 8.03044 37.4961 7.93444 37.7856C7.83845 38.0751 7.80335 38.3813 7.83136 38.685C7.79872 38.9844 7.8301 39.2873 7.92343 39.5736C8.01675 39.8599 8.16989 40.1231 8.37268 40.3457C8.57546 40.5683 8.82327 40.7453 9.09965 40.8648C9.37603 40.9844 9.67468 41.0438 9.97578 41.0391C10.2584 41.0432 10.5384 40.9847 10.7957 40.8677C11.053 40.7508 11.2813 40.5783 11.464 40.3627L10.9161 39.6862C10.8055 39.8214 10.6676 39.9317 10.5113 40.0098C10.3551 40.0879 10.1841 40.1321 10.0096 40.1394Z" fill="#D8D8D8"/>
    <path d="M13.9128 40.9512H14.9546V37.2238H16.4631V40.9512H17.5049V36.3512H13.9128V40.9512Z" fill="#D8D8D8"/>
    <path d="M22.2129 36.2632C20.9749 36.2632 20.1226 37.1291 20.1226 38.6309C20.1226 40.1327 20.9749 41.0324 22.2129 41.0324C23.4508 41.0324 24.3234 40.1327 24.3234 38.6309C24.3234 37.1291 23.4576 36.2632 22.2129 36.2632ZM22.2129 40.1394C21.5905 40.1394 21.1914 39.5509 21.1914 38.6309C21.1914 37.7109 21.6176 37.1765 22.2129 37.1765C22.8081 37.1765 23.2411 37.7177 23.2411 38.6444C23.2411 39.5712 22.842 40.1394 22.2129 40.1394Z" fill="#D8D8D8"/>
    <path d="M28.5043 36.3512H26.9214V40.9512H27.9632V39.3885H28.5314C29.5055 39.3885 30.3308 38.9083 30.3308 37.8259C30.3308 36.7435 29.519 36.3512 28.5043 36.3512ZM28.5043 38.5633H27.997V37.1765H28.4773C29.0387 37.1765 29.3499 37.3456 29.3499 37.853C29.3499 38.3603 29.059 38.5633 28.4705 38.5633H28.5043Z" fill="#D8D8D8"/>
    <path d="M32.4209 37.2238H33.6859V40.9512H34.7277V37.2238H35.9927V36.3512H32.4209V37.2238Z" fill="#D8D8D8"/>
    <path d="M39.0368 36.3512L37.5891 40.9512H38.6512L38.9353 39.8824H40.3423L40.6265 40.9783H41.7223L40.2815 36.3783L39.0368 36.3512ZM39.145 39.0571L39.26 38.6309C39.3818 38.1709 39.5103 37.6365 39.6185 37.1494C39.7403 37.6297 39.8688 38.1709 39.9973 38.6309L40.1056 39.0571H39.145Z" fill="#D8D8D8"/>
    <path d="M13.6759 17.6535L12.2486 22.1927L10.7942 19.8115L11.2812 18.9659C11.4706 18.6412 11.5924 18.5465 11.8021 18.5465C11.867 18.5354 11.9334 18.5354 11.9983 18.5465L12.1471 17.6332C12.0284 17.5884 11.9019 17.5677 11.775 17.5724C11.5103 17.5689 11.2509 17.6468 11.0319 17.7956C10.8129 17.9445 10.6449 18.157 10.5506 18.4044L9.95534 19.4665H9.44122V17.6535H8.42651V22.2941H9.44122V20.3053H9.94857L11.1933 22.2941H13.2971L13.5812 21.1982H14.9815L15.2656 22.2941H16.3683L14.9206 17.6535H13.6759ZM13.7909 20.3594L13.8992 19.9332C14.0277 19.4732 14.1495 18.9388 14.2577 18.4518H14.2915C14.4133 18.9321 14.535 19.4732 14.6636 19.9332L14.7718 20.3594H13.7909Z" fill="#747778"/>
    <path d="M19.4935 19.4327H17.9038V17.6536H16.8621V22.2603H17.9038V20.3392H19.4935V22.2603H20.5353V17.6536H19.4935V19.4327Z" fill="#747778"/>
    <path d="M25.1557 17.6536H22.4498C22.301 18.533 22.2266 19.0065 22.071 19.7912C21.8545 20.9074 21.6178 21.3133 21.3945 21.3877H21.1848V22.0642L21.2728 23.6133H22.1995V22.2942H24.716V23.6471H25.6428L25.7307 22.098V21.4215H25.1692L25.1557 17.6536ZM22.9707 19.9265L23.221 18.5262H24.1072V21.3877H22.4836C22.724 20.9302 22.8885 20.4367 22.9707 19.9265Z" fill="#747778"/>
    <path d="M27.7057 19.9468L27.2457 20.9412C27.3199 20.387 27.365 19.8293 27.381 19.2703V17.6536H26.3528V22.2942H27.4554L28.6595 19.9942L29.0587 18.9592C29.0113 19.4665 28.9234 20.0821 28.9234 20.6233V22.2942H30.0057V17.6536H28.9031L27.7057 19.9468Z" fill="#747778"/>
    <path d="M34.6261 17.6536H31.9202C31.7646 18.533 31.6902 19.0065 31.5346 19.7912C31.3181 20.9074 31.0881 21.3133 30.8581 21.3877H30.6484V22.0642L30.7364 23.6133H31.6631V22.2942H34.1729V23.6471H35.1064L35.1943 22.098V21.4215H34.6329L34.6261 17.6536ZM32.4411 19.9265C32.5493 19.365 32.5967 19.0877 32.6914 18.5262H33.5843V21.3877H31.9608C32.1986 20.9297 32.3608 20.4363 32.4411 19.9265Z" fill="#747778"/>
    <path d="M36.6757 17.6536L35.2349 22.2942H36.2969L36.581 21.1983H37.9881L38.2655 22.2942H39.3681L37.9272 17.6874L36.6757 17.6536ZM36.7907 20.3594L36.899 19.9333C37.0275 19.4733 37.156 18.9389 37.2643 18.4518C37.386 18.9321 37.5078 19.4733 37.6363 19.9333L37.7513 20.3594H36.7907Z" fill="#747778"/>
    <path d="M38.9893 17.6536V18.5262H40.2543V22.2603H41.296V18.5262H42.561V17.6536H38.9893Z" fill="#747778"/>
    <path d="M13.1955 17.4032L11.7749 21.9423L10.3137 19.5882L10.794 18.7156C10.9766 18.3909 11.0984 18.2962 11.3081 18.2962C11.373 18.285 11.4394 18.285 11.5043 18.2962L11.6734 17.3559C11.5548 17.3105 11.4282 17.2898 11.3013 17.295C11.0401 17.3008 10.7868 17.3862 10.5753 17.5397C10.3638 17.6932 10.2041 17.9075 10.1175 18.1541L9.51546 19.2162H9.0081V17.4032H7.94604V22.01H8.98781V20.055H9.49516L10.7196 22.01H12.8234L13.1008 20.9412H14.501L14.7852 22.037H15.8878L14.4402 17.4303L13.1955 17.4032ZM13.3105 20.1091L13.4187 19.6829C13.5472 19.2229 13.669 18.6885 13.784 18.2014C13.9058 18.6817 14.0275 19.2229 14.156 19.6829L14.2643 20.1091H13.3105Z" fill="#D8D8D8"/>
    <path d="M19.0131 19.1824H17.4234V17.4033H16.3884V22.0101H17.4234V20.0889H19.0131V22.0101H20.0549V17.4033H19.0131V19.1824Z" fill="#D8D8D8"/>
    <path d="M24.6752 17.4033H21.9693C21.8205 18.2827 21.746 18.7563 21.5905 19.541C21.374 20.6571 21.1372 21.063 20.914 21.1374H20.6975V21.8139L20.7855 23.363H21.719V22.0101H24.2287V23.363H25.1555L25.2434 21.8139V21.1374H24.6819L24.6752 17.4033ZM22.4969 19.6763C22.6052 19.1148 22.6457 18.8374 22.7472 18.276H23.6469V21.1374H22.0234C22.259 20.6791 22.4189 20.1857 22.4969 19.6763Z" fill="#D8D8D8"/>
    <path d="M27.2189 19.6966L26.7656 20.7045C26.813 20.2174 26.8942 19.5748 26.8942 19.0336V17.4033H25.8389V22.0101H26.9415L28.1524 19.7101L28.6056 18.7089C28.5583 19.2163 28.4703 19.8318 28.4703 20.373V22.0101H29.4986V17.4033H28.4297L27.2189 19.6966Z" fill="#D8D8D8"/>
    <path d="M34.1459 17.4033H31.44C31.2912 18.2827 31.2168 18.7563 31.0612 19.541C30.8448 20.6571 30.608 21.063 30.3848 21.1374H30.175V21.8139L30.263 23.363H31.1898V22.0101H33.7062V23.363H34.633L34.7209 21.8139V21.1374H34.1595L34.1459 17.4033ZM31.9677 19.6763C32.0692 19.1148 32.1165 18.8374 32.2112 18.276H33.1177V21.1374H31.4942C31.7298 20.6791 31.8897 20.1857 31.9677 19.6763Z" fill="#D8D8D8"/>
    <path d="M36.2023 17.4033L34.7546 22.0101H35.8235L36.1008 20.9413H37.5079L37.792 22.0371H38.8879L37.447 17.4033H36.2023ZM36.3105 20.1092L36.4255 19.683C36.5541 19.223 36.6758 18.6886 36.784 18.2016C36.9126 18.6819 37.0343 19.223 37.1629 19.683L37.2711 20.1092H36.3105Z" fill="#D8D8D8"/>
    <path d="M38.509 17.4033V18.2827H39.774V22.0101H40.8158V18.2827H42.0808V17.4033H38.509Z" fill="#D8D8D8"/>
    <path d="M7.92574 10.6724C10.8858 11.5226 13.974 11.8365 17.0446 11.5992C19.4026 11.4046 21.7458 11.0588 24.0596 10.5642C25.2637 10.4018 25.1554 10.3815 25.4125 10.3612C25.6696 10.3409 25.5613 10.4018 26.7654 10.5642C29.0837 11.0594 31.4314 11.4051 33.794 11.5992C36.8645 11.8365 39.9528 11.5226 42.9128 10.6724L43.1834 10.5844L43.4202 10.4762C41.3541 11.0576 39.221 11.3669 37.0749 11.3962C34.0157 11.4112 30.9665 11.0476 27.9966 10.3139C27.1687 10.0466 26.3131 9.8741 25.4463 9.79974H25.399C24.5323 9.87479 23.6768 10.0472 22.8487 10.3139C19.8788 11.0476 16.8296 11.4112 13.7704 11.3962C11.6197 11.3687 9.48193 11.0594 7.41162 10.4762L7.64162 10.5844L7.92574 10.6724Z" fill="#747778"/>
    <path d="M25.1082 9.58998C24.8038 8.42645 23.2479 7.70939 21.7258 8.04763C22.0022 8.67486 22.4996 9.1786 23.1233 9.46299C23.7469 9.74738 24.4534 9.79258 25.1082 9.58998Z" fill="#747778"/>
    <path d="M21.7595 13.0197C23.2004 13.1144 24.7766 12.0185 24.9119 10.7941C23.2545 10.8753 22.0166 11.7547 21.7595 13.0197Z" fill="#747778"/>
    <path d="M18.5869 13.5C19.2463 13.5493 19.9031 13.3762 20.4525 13.0084C21.0019 12.6405 21.4121 12.099 21.6175 11.4706C20.956 11.4087 20.2934 11.5768 19.7414 11.9464C19.1894 12.3161 18.7816 12.8648 18.5869 13.5Z" fill="#747778"/>
    <path d="M22.0574 10.2394C21.9424 9.17059 20.4339 8.43323 18.905 8.70382C19.1215 9.7997 20.4541 10.4424 22.0574 10.2394Z" fill="#747778"/>
    <path d="M19.0539 10.7332C18.9389 9.65085 17.5115 8.87291 16.0774 9.1232C16.2803 10.2326 17.5115 10.9023 19.0539 10.7332Z" fill="#747778"/>
    <path d="M15.3604 13.2903C16.0088 13.4629 16.6957 13.4204 17.3178 13.1691C17.94 12.9178 18.4637 12.4713 18.8104 11.8968C18.1618 11.7435 17.4816 11.7952 16.8637 12.0448C16.2458 12.2944 15.7205 12.7296 15.3604 13.2903Z" fill="#747778"/>
    <path d="M15.712 11.0242C15.4617 9.88092 14.4538 9.25181 13.0535 9.33298C12.952 10.1718 14.1764 11.2068 15.712 11.0242Z" fill="#747778"/>
    <path d="M11.9373 13.04C12.5377 13.2377 13.1844 13.2457 13.7896 13.0629C14.3948 12.8802 14.929 12.5155 15.3196 12.0185C14.7191 11.8218 14.0726 11.8143 13.4677 11.997C12.8628 12.1797 12.3285 12.5438 11.9373 13.04Z" fill="#747778"/>
    <path d="M8.68359 12.4447C9.21824 12.6961 9.8169 12.7784 10.3996 12.6805C10.9822 12.5825 11.5211 12.3091 11.9442 11.8967C11.1257 11.3285 9.60359 11.572 8.68359 12.4447Z" fill="#747778"/>
    <path d="M12.3296 11.1188C12.4717 10.165 11.4637 9.3194 10.192 9.3194C10.0296 10.2529 11.024 11.0917 12.3296 11.1188Z" fill="#747778"/>
    <path d="M9.59672 10.6453C9.77936 9.81323 9.02172 9.08264 7.87848 9.00146C7.8176 9.9147 8.46025 10.5168 9.59672 10.6453Z" fill="#747778"/>
    <path d="M6.12646 11.6803C6.56978 11.9259 7.07881 12.0266 7.58224 11.9684C8.08566 11.9101 8.55826 11.6959 8.93382 11.3556C8.48995 11.112 7.98135 11.0124 7.4784 11.0706C6.97545 11.1288 6.50301 11.3419 6.12646 11.6803Z" fill="#747778"/>
    <path d="M4.18506 10.2056C4.47711 10.5145 4.86368 10.7174 5.28379 10.7823C5.70391 10.8472 6.13368 10.7705 6.50535 10.5641C6.21526 10.2521 5.82847 10.0471 5.40746 9.982C4.98644 9.91694 4.55578 9.99571 4.18506 10.2056Z" fill="#747778"/>
    <path d="M7.1142 9.94181C7.11598 9.74767 7.07595 9.55542 6.99684 9.37812C6.91773 9.20082 6.80138 9.04263 6.65571 8.91428C6.51003 8.78593 6.33844 8.69044 6.15259 8.63429C5.96674 8.57814 5.77098 8.56265 5.57861 8.58887C5.54479 9.35328 6.09949 9.84711 7.1142 9.94181Z" fill="#747778"/>
    <path d="M4.79393 9.19766C4.71952 8.52119 4.26628 8.19648 3.44099 8.16266C3.35305 8.76472 3.96863 9.25854 4.79393 9.19766Z" fill="#747778"/>
    <path d="M29.1131 8.04763C27.591 7.70939 26.0284 8.42645 25.7307 9.58998C26.3856 9.79105 27.0914 9.74513 27.7147 9.46091C28.338 9.17669 28.8355 8.67389 29.1131 8.04763Z" fill="#747778"/>
    <path d="M25.9199 10.7941C26.062 12.0185 27.6314 13.1144 29.0723 13.0197C28.8152 11.7547 27.584 10.8753 25.9199 10.7941Z" fill="#747778"/>
    <path d="M29.2278 11.4706C29.434 12.0972 29.8434 12.637 30.3911 13.0047C30.9388 13.3723 31.5936 13.5466 32.2516 13.5C32.0549 12.8674 31.6477 12.3211 31.0976 11.9519C30.5476 11.5827 29.8877 11.4129 29.2278 11.4706Z" fill="#747778"/>
    <path d="M28.7749 10.2394C30.3849 10.4424 31.7108 9.7997 31.934 8.70382C30.412 8.43323 28.8967 9.17059 28.7749 10.2394Z" fill="#747778"/>
    <path d="M34.7548 9.1232C33.3274 8.87291 31.8933 9.65085 31.7783 10.7332C33.3207 10.9023 34.5519 10.2326 34.7548 9.1232Z" fill="#747778"/>
    <path d="M32.0286 11.8968C32.3732 12.4716 32.8957 12.9185 33.5169 13.1699C34.1382 13.4214 34.8244 13.4636 35.4718 13.2903C35.1128 12.7301 34.5887 12.2952 33.972 12.0456C33.3552 11.796 32.6761 11.744 32.0286 11.8968Z" fill="#747778"/>
    <path d="M37.7852 9.33298C36.3849 9.25181 35.3702 9.88092 35.1267 11.0242C36.6555 11.2068 37.8867 10.1718 37.7852 9.33298Z" fill="#747778"/>
    <path d="M35.4854 12.0185C35.876 12.5155 36.4102 12.8802 37.0154 13.0629C37.6205 13.2457 38.2673 13.2377 38.8677 13.04C38.4764 12.5438 37.9422 12.1797 37.3373 11.997C36.7323 11.8143 36.0859 11.8218 35.4854 12.0185Z" fill="#747778"/>
    <path d="M38.8879 11.8967C39.311 12.3091 39.8499 12.5825 40.4326 12.6805C41.0152 12.7784 41.6139 12.6961 42.1485 12.4447C41.2353 11.572 39.7065 11.3285 38.8879 11.8967Z" fill="#747778"/>
    <path d="M40.6401 9.3194C39.3684 9.3194 38.3604 10.165 38.5092 11.1188C39.8081 11.0917 40.8025 10.2529 40.6401 9.3194Z" fill="#747778"/>
    <path d="M42.9602 9.00146C41.8102 9.08264 41.0593 9.81323 41.2352 10.6453C42.3785 10.5168 43.0211 9.9147 42.9602 9.00146Z" fill="#747778"/>
    <path d="M41.9116 11.3557C42.2856 11.6963 42.7571 11.9108 43.2596 11.9691C43.7621 12.0274 44.2702 11.9264 44.7122 11.6804C44.3372 11.3416 43.8659 11.1282 43.3639 11.07C42.8619 11.0118 42.3542 11.1116 41.9116 11.3557Z" fill="#747778"/>
    <path d="M44.3267 10.5641C44.6983 10.7705 45.1281 10.8472 45.5482 10.7823C45.9683 10.7174 46.3549 10.5145 46.647 10.2056C46.2762 9.99571 45.8456 9.91694 45.4246 9.982C45.0035 10.0471 44.6167 10.2521 44.3267 10.5641Z" fill="#747778"/>
    <path d="M45.2534 8.60236C45.0611 8.57614 44.8653 8.59163 44.6794 8.64778C44.4936 8.70393 44.322 8.79942 44.1763 8.92777C44.0306 9.05612 43.9143 9.21431 43.8352 9.39161C43.7561 9.56891 43.7161 9.76116 43.7178 9.9553C44.7393 9.84707 45.294 9.35324 45.2534 8.60236Z" fill="#747778"/>
    <path d="M46.0383 9.19766C46.8636 9.25854 47.5063 8.78501 47.3913 8.16266C46.566 8.19648 46.1195 8.53472 46.0383 9.19766Z" fill="#747778"/>
    <path d="M7.56065 10.307C10.5204 11.1583 13.6093 11.47 16.6795 11.227C19.0378 11.0385 21.3812 10.695 23.6945 10.1988C24.9054 10.0365 24.7904 10.0094 25.0474 9.99587C25.3045 9.98234 25.1962 10.0365 26.4004 10.1988C28.7159 10.695 31.0615 11.0385 33.4221 11.227C36.4923 11.4691 39.581 11.1575 42.5409 10.307L42.8048 10.2191L43.0415 10.1109C40.9781 10.693 38.8468 11 36.703 11.0241C33.6416 11.0395 30.5901 10.6759 27.618 9.94175C26.7911 9.68186 25.9381 9.51398 25.0745 9.44116H25.0001C24.1361 9.5159 23.2831 9.68606 22.4565 9.94851C19.4843 10.6817 16.4329 11.0452 13.3715 11.0309C11.2368 11.0044 9.11476 10.6974 7.06006 10.1176L7.29006 10.2259L7.56065 10.307Z" fill="#D8D8D8"/>
    <path d="M24.7429 9.22473C24.4385 8.06121 22.8827 7.33738 21.3606 7.68238C21.6394 8.30767 22.137 8.80955 22.7599 9.0936C23.3828 9.37764 24.0881 9.42428 24.7429 9.22473Z" fill="#D8D8D8"/>
    <path d="M21.3943 12.6545C22.8352 12.7492 24.4113 11.6465 24.5466 10.4289C22.8893 10.5033 21.6513 11.3895 21.3943 12.6545Z" fill="#D8D8D8"/>
    <path d="M18.2354 13.1617C18.8989 13.2075 19.5585 13.0271 20.1064 12.65C20.6543 12.2728 21.0583 11.7212 21.2524 11.085C20.5872 11.0303 19.9235 11.2072 19.3738 11.5856C18.824 11.9641 18.4218 12.5209 18.2354 13.1617Z" fill="#D8D8D8"/>
    <path d="M21.6921 9.8741C21.5771 8.79851 20.0686 8.06792 18.5398 8.33851C18.7563 9.44115 20.0889 10.077 21.6921 9.8741Z" fill="#D8D8D8"/>
    <path d="M18.6884 10.3679C18.5734 9.28555 17.146 8.50761 15.7119 8.75114C15.9216 9.86732 17.146 10.5303 18.6884 10.3679Z" fill="#D8D8D8"/>
    <path d="M15.0017 12.925C15.6492 13.0961 16.3346 13.0527 16.9553 12.8015C17.5761 12.5503 18.0987 12.1047 18.4449 11.5315C17.7974 11.3787 17.1183 11.4307 16.5016 11.6803C15.8848 11.9299 15.3607 12.3648 15.0017 12.925Z" fill="#D8D8D8"/>
    <path d="M15.3468 10.6521C15.0965 9.5156 14.0885 8.87972 12.6883 8.96766C12.5868 9.80648 13.8112 10.8415 15.3468 10.6521Z" fill="#D8D8D8"/>
    <path d="M11.572 12.6747C12.1773 12.877 12.8303 12.8875 13.4418 12.7046C14.0532 12.5218 14.5933 12.1546 14.9882 11.6532C14.3831 11.4489 13.7295 11.4374 13.1176 11.6204C12.5057 11.8033 11.9656 12.1717 11.572 12.6747Z" fill="#D8D8D8"/>
    <path d="M8.31836 12.0794C8.85342 12.329 9.45162 12.4102 10.0338 12.3123C10.616 12.2145 11.1548 11.9422 11.5789 11.5315C10.7942 10.9565 9.23836 11.2068 8.31836 12.0794Z" fill="#D8D8D8"/>
    <path d="M11.9643 10.7468C12.1132 9.79297 11.0985 8.94738 9.8267 8.95414C9.67111 9.88767 10.6588 10.7197 11.9643 10.7468Z" fill="#D8D8D8"/>
    <path d="M9.23152 10.28C9.41417 9.44798 8.65652 8.71063 7.51328 8.62946C7.4524 9.54269 8.08828 10.1515 9.23152 10.28Z" fill="#D8D8D8"/>
    <path d="M5.76123 11.315C6.2055 11.5587 6.71471 11.6577 7.21792 11.5983C7.72114 11.5389 8.19328 11.324 8.56858 10.9836C8.12351 10.7428 7.61494 10.6455 7.11243 10.7048C6.60991 10.7641 6.138 10.9773 5.76123 11.315Z" fill="#D8D8D8"/>
    <path d="M3.82642 9.8335C4.1154 10.1449 4.50102 10.3498 4.92087 10.4148C5.34072 10.4799 5.77025 10.4014 6.13995 10.192C5.85096 9.88059 5.46534 9.67577 5.04549 9.61071C4.62564 9.54564 4.19611 9.62414 3.82642 9.8335Z" fill="#D8D8D8"/>
    <path d="M6.73535 9.57651C6.80299 8.79181 6.11976 8.22357 5.19976 8.22357C5.17946 8.98798 5.73417 9.48181 6.73535 9.57651Z" fill="#D8D8D8"/>
    <path d="M4.42834 8.8256C4.35393 8.14913 3.9007 7.82442 3.0754 7.79736C2.96717 8.41295 3.60981 8.89324 4.42834 8.8256Z" fill="#D8D8D8"/>
    <path d="M28.7478 7.68238C27.2258 7.33738 25.6699 8.06121 25.3655 9.22473C26.0204 9.42428 26.7256 9.37764 27.3485 9.0936C27.9714 8.80955 28.4691 8.30767 28.7478 7.68238Z" fill="#D8D8D8"/>
    <path d="M25.5547 10.4289C25.6967 11.6465 27.2729 12.7492 28.707 12.6545C28.4567 11.3895 27.2188 10.5033 25.5547 10.4289Z" fill="#D8D8D8"/>
    <path d="M28.8694 11.085C29.0635 11.7212 29.4675 12.2728 30.0154 12.65C30.5633 13.0271 31.2229 13.2075 31.8864 13.1617C31.7 12.5209 31.2978 11.9641 30.748 11.5856C30.1983 11.2072 29.5346 11.0303 28.8694 11.085Z" fill="#D8D8D8"/>
    <path d="M28.3823 9.87405C29.9923 10.077 31.3182 9.42758 31.5414 8.33846C30.0329 8.08817 28.5311 8.79846 28.3823 9.87405Z" fill="#D8D8D8"/>
    <path d="M34.3894 8.76475C32.962 8.52122 31.5279 9.29916 31.4197 10.3815C32.9553 10.5303 34.1864 9.8674 34.3894 8.76475Z" fill="#D8D8D8"/>
    <path d="M31.6633 11.5315C32.0096 12.1047 32.5322 12.5503 33.153 12.8015C33.7737 13.0527 34.4591 13.0961 35.1066 12.925C34.7475 12.3648 34.2235 11.9299 33.6067 11.6803C32.9899 11.4307 32.3109 11.3787 31.6633 11.5315Z" fill="#D8D8D8"/>
    <path d="M37.42 8.96766C36.0197 8.87972 35.0118 9.5156 34.7615 10.6521C36.2903 10.8415 37.5215 9.80648 37.42 8.96766Z" fill="#D8D8D8"/>
    <path d="M35.1472 11.6533C35.5391 12.1487 36.0734 12.5122 36.6781 12.6948C37.2828 12.8774 37.929 12.8704 38.5296 12.6747C38.1389 12.1777 37.6047 11.8131 36.9996 11.6303C36.3944 11.4476 35.7477 11.4556 35.1472 11.6533Z" fill="#D8D8D8"/>
    <path d="M38.5293 11.5315C38.9545 11.9428 39.4944 12.2154 40.0778 12.3132C40.6612 12.411 41.2606 12.3295 41.7967 12.0794C40.8699 11.2068 39.3411 10.9565 38.5293 11.5315Z" fill="#D8D8D8"/>
    <path d="M40.2816 8.9541C39.0031 8.9541 37.9952 9.79293 38.144 10.7468C39.4428 10.7197 40.4372 9.88763 40.2816 8.9541Z" fill="#D8D8D8"/>
    <path d="M42.5882 8.62946C41.4382 8.71063 40.6873 9.44798 40.8632 10.28C42.0132 10.1515 42.6558 9.54269 42.5882 8.62946Z" fill="#D8D8D8"/>
    <path d="M41.5398 10.9836C41.914 11.3234 42.385 11.5381 42.887 11.5975C43.3891 11.6569 43.8971 11.5582 44.3404 11.315C43.9652 10.977 43.4943 10.7635 42.9928 10.7042C42.4912 10.6448 41.9836 10.7424 41.5398 10.9836Z" fill="#D8D8D8"/>
    <path d="M43.9412 10.192C44.3109 10.4014 44.7404 10.4799 45.1602 10.4148C45.5801 10.3498 45.9657 10.1449 46.2547 9.8335C45.885 9.62414 45.4555 9.54564 45.0356 9.61071C44.6158 9.67577 44.2302 9.88059 43.9412 10.192Z" fill="#D8D8D8"/>
    <path d="M44.8882 8.237C44.6958 8.21078 44.5001 8.22628 44.3142 8.28242C44.1284 8.33857 43.9568 8.43407 43.8111 8.56241C43.6654 8.69076 43.5491 8.84895 43.47 9.02625C43.3908 9.20355 43.3508 9.3958 43.3526 9.58994C44.3741 9.48171 44.9288 8.98789 44.8882 8.237Z" fill="#D8D8D8"/>
    <path d="M45.6731 8.8256C46.4984 8.89324 47.141 8.41295 47.026 7.79736C46.2075 7.82442 45.7543 8.16266 45.6731 8.8256Z" fill="#D8D8D8"/>
    <path d="M42.514 26.2582H8.00049V26.6303H42.514V26.2582Z" fill="#747778"/>
    <path d="M42.169 26.008H7.65552V26.3801H42.169V26.008Z" fill="#D8D8D8"/>
    <rect x="-1104.5" y="-124.5" width="1768" height="796" rx="4.5" stroke="#7B61FF" strokeDasharray="10 5"/>
  </svg>
);

export default IconCandidateMaster;
