import { GET_STUDENT_PROFESSIONAL_ADDITIONAL_EDUCATION } from '../../actions';
import { FAILURE, INVALIDATE, REQUEST, SUCCESS } from '../../actions/utils';
import { ICommonResponseObject, IProfessionalAdditionalEducationResponse } from '../../api/types';
import { connectReducers } from '../../utils';
import { IReducers } from '..';
import genericReducers from '../genericReducer';

export type StudentProfessionalAdditionalEducationState =
  ICommonResponseObject<IProfessionalAdditionalEducationResponse>;

const initialState: StudentProfessionalAdditionalEducationState = {
  content: {
    certificate: [],
  },
  loading: false,
};

const studentProfessionalAdditionalEducation: IReducers<StudentProfessionalAdditionalEducationState> = {
  [GET_STUDENT_PROFESSIONAL_ADDITIONAL_EDUCATION[REQUEST]]: genericReducers().get.request,
  [GET_STUDENT_PROFESSIONAL_ADDITIONAL_EDUCATION[SUCCESS]]: genericReducers().get.success,
  [GET_STUDENT_PROFESSIONAL_ADDITIONAL_EDUCATION[FAILURE]]: genericReducers().get.failure,
  [GET_STUDENT_PROFESSIONAL_ADDITIONAL_EDUCATION[INVALIDATE]]: genericReducers().get.invalidate(initialState),
};

export default connectReducers(initialState, studentProfessionalAdditionalEducation);
