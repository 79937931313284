import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root: SxStyles = {
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  padding: '20px',

  border: '1px solid',
  borderColor: NeutralColors.night_neutrals_700,
};
