import { FC, ReactNode, useState } from 'react';

import { Box, ClickAwayListener, Fade, Popper, PopperProps } from '@mui/material';
import { IconClose } from 'icons/edit';
import { NeutralColors } from 'portfolio3/styles';

import * as styles from './styles';

interface IPopoverProps extends PopperProps {
  header: ReactNode;
  body: ReactNode;
  onClose: () => void;
  footer?: ReactNode;
  showClose?: boolean;
  withHeaderDivider?: boolean;
  withFooterDivider?: boolean;
}

/**
 * Всплывающее окно с пояснением, содержащее заголовок, основной контент и футер
 * @example
 * <Popover
      header={<Typography variant="Paragraph MD/Semi Bold">Заголовок</Typography>}
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={() => setAnchorEl(null)}
    >
      <Typography variant="Paragraph MD/Regular">
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Accusantium dolores aut vero repudiandae
        quisquam, earum saepe a debitis quod molestias.
      </Typography>
    </Popover>
 */
const Popover: FC<IPopoverProps> = ({
  header,
  onClose,
  open,
  footer,
  body,
  showClose = true,
  withHeaderDivider = true,
  withFooterDivider = true,
  ...props
}) => {
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);

  return (
    <Popper
      open={open}
      anchorEl={props.anchorEl}
      transition
      className="popper"
      sx={styles.popoverStyles(NeutralColors.light_neutrals_0)}
      modifiers={[
        {
          name: 'arrow',
          enabled: true,
          options: {
            element: arrowRef,
          },
        },
      ]}
      {...props}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Box className="popover__box" sx={styles.popoverBox}>
            <ClickAwayListener onClickAway={onClose}>
              <Box className="popover__content" sx={styles.popoverContent(NeutralColors.light_neutrals_0)}>
                <Box className="popover__arrow" sx={styles.arrowSx} ref={setArrowRef} />
                <Box sx={styles.popoverHeader(withHeaderDivider)} className="popover__header">
                  {header}
                  {showClose && <IconClose onClick={onClose} />}
                </Box>
                {body && (
                  <Box sx={styles.popoverBody} className="popover__body">
                    {body}
                  </Box>
                )}
                {footer && (
                  <>
                    <Box sx={styles.popoverFooter(withFooterDivider)} className="popover__footer">
                      {footer}
                    </Box>
                  </>
                )}
              </Box>
            </ClickAwayListener>
          </Box>
        </Fade>
      )}
    </Popper>
  );
};

export default Popover;
