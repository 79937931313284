export enum LinkExpirationKeys {
  WEEK = 'week',
  MOUNTH = 'mounth',
  YEAR = 'year',
  PERIOD = 'period',
}

export const LinkExpiration: Record<LinkExpirationKeys, string> = {
  week: 'Неделя',
  mounth: 'Месяц',
  year: 'Год',
  period: 'Настроить вручную',
};

export const LinkExpirationId: Record<LinkExpirationKeys, number> = {
  week: 1,
  mounth: 2,
  year: 3,
  period: 4,
};
