/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';

import {
  FAILURE,
  REQUEST,
  SUCCESS
} from '../../actions/utils';

import { ICommonResponse, IDictionaryItem } from '../../api/types';

import genericReducers from '../genericReducer';
import { GET_LEARNER_CATEGORIES } from '../../actions';

import { IReducers } from '..';

export type LearnerCategoriesState = ICommonResponse<IDictionaryItem>;

const initialState = {} as ICommonResponse<IDictionaryItem>;

const learnerCategories: IReducers<LearnerCategoriesState> = {
  [GET_LEARNER_CATEGORIES[REQUEST]]: genericReducers().get.request,
  [GET_LEARNER_CATEGORIES[SUCCESS]]: genericReducers().get.success,
  [GET_LEARNER_CATEGORIES[FAILURE]]: genericReducers().get.failure
};

export default connectReducers(initialState, learnerCategories);
