/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import DeleteHistory from '../../containers/admin/DeleteHistory';

const AdminDeleteHistoryPage: React.FC = () => (
  <div className="admin-content">
    <DeleteHistory />
  </div>
);

export default AdminDeleteHistoryPage;
