/* eslint-disable prettier/prettier */
/* eslint-disable */

import { IQueryParams } from '../api';
import {
  IAdminDeleteHistoryRequest,
  IAdminHistoryRequest,
  IGetAdminIncorrectDataRequest,
  ISaveAdminFunctionSettingsRequest,
  ISaveAdminSettingsRequest
} from '../api/types';
import { AdminPortfolioViewType } from '../types/admin';
import {
  action, createRequestTypes, FAILURE, INVALIDATE, REQUEST, SUCCESS
} from './utils';

export const GET_ADMIN_SETTINGS = createRequestTypes('GET_ADMIN_SETTINGS');
export const getAdminSettingsActions = {
  request: (classLevel?: string) => action(GET_ADMIN_SETTINGS[REQUEST], { payload: { classLevel } }),
  success: (response: any) => action(GET_ADMIN_SETTINGS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_ADMIN_SETTINGS[FAILURE], { payload: error }),
  invalidate: () => action(GET_ADMIN_SETTINGS[INVALIDATE]),
};

export const GET_ADMIN_VIEW_MODE_SETTINGS = createRequestTypes('GET_ADMIN_VIEW_MODE_SETTINGS');
export const getAdminViewModeSettingsActions = {
  request: (personId: string) => action(GET_ADMIN_VIEW_MODE_SETTINGS[REQUEST], { payload: { personId } }),
  success: (response: any) => action(GET_ADMIN_VIEW_MODE_SETTINGS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_ADMIN_VIEW_MODE_SETTINGS[FAILURE], { payload: error }),
  invalidate: () => action(GET_ADMIN_VIEW_MODE_SETTINGS[INVALIDATE]),
};

export const GET_SETTINGS_SECTIONS = createRequestTypes('GET_SETTINGS_SECTIONS');
export const getSettingSectionsActions = {
  request: (queryParams?: IQueryParams) => action(GET_SETTINGS_SECTIONS[REQUEST], { payload: { queryParams } }),
  success: (response: any) => action(GET_SETTINGS_SECTIONS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_SETTINGS_SECTIONS[FAILURE], { payload: error })
};

export const GET_LEARNER_CATEGORIES = createRequestTypes('GET_LEARNER_CATEGORIES');
export const getLearnerCategoriesActions = {
  request: (queryParams?: IQueryParams) => action(GET_LEARNER_CATEGORIES[REQUEST], { payload: { queryParams } }),
  success: (response: any) => action(GET_LEARNER_CATEGORIES[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_LEARNER_CATEGORIES[FAILURE], { payload: error })
};

export const SAVE_ADMIN_SETTINGS = createRequestTypes('SAVE_ADMIN_SETTINGS');
export const saveAdminSettingsActions = {
  request: (request: ISaveAdminSettingsRequest) => action(SAVE_ADMIN_SETTINGS[REQUEST], { payload: request }),
  success: (response: any) => action(SAVE_ADMIN_SETTINGS[SUCCESS], { payload: response }),
  failure: (error: any) => action(SAVE_ADMIN_SETTINGS[FAILURE], { payload: error })
};

export const GET_ADMIN_HISTORY = createRequestTypes('GET_ADMIN_HISTORY');
export const getAdminHistoryActions = {
  request: (request?: IAdminHistoryRequest) => action(GET_ADMIN_HISTORY[REQUEST], { payload: { request } }),
  success: (response: any) => action(GET_ADMIN_HISTORY[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_ADMIN_HISTORY[FAILURE], { payload: error })
};

export const GET_ADMIN_DELETE_HISTORY = createRequestTypes('GET_ADMIN_DELETE_HISTORY');
export const getAdminDeleteHistoryActions = {
  request: (request?: IAdminDeleteHistoryRequest) => action(GET_ADMIN_DELETE_HISTORY[REQUEST], { payload: { request } }),
  success: (response: any) => action(GET_ADMIN_DELETE_HISTORY[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_ADMIN_DELETE_HISTORY[FAILURE], { payload: error })
};

export const CLEAR_ADMIN_SETTINGS = 'CLEAR_ADMIN_SETTINGS';
export const clearAdminsSettingsAction = () => action(CLEAR_ADMIN_SETTINGS);

export const GET_ADMIN_LOG = createRequestTypes('GET_ADMIN_LOG');
export const getAdminLogActions = {
  request: (startDate: string, endDate: string, actionKindCodes: number[]) => action(GET_ADMIN_LOG[REQUEST], { payload: { startDate, endDate, actionKindCodes } }),
  success: (response: any) => action(GET_ADMIN_LOG[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_ADMIN_LOG[FAILURE], { payload: error })
};

export const GET_ACTION_KINDS = createRequestTypes('GET_ACTION_KINDS');
export const getActionKindsActions = {
  request: (queryParams?: IQueryParams) => action(GET_ACTION_KINDS[REQUEST], { payload: { queryParams } }),
  success: (response: any) => action(GET_ACTION_KINDS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_ACTION_KINDS[FAILURE], { payload: error })
};

export const GET_ADMIN_FUNCTION_SETTINGS = createRequestTypes('GET_ADMIN_FUNCTION_SETTINGS');
export const getAdminFunctionSettingsActions = {
  request: (functionType: string) => action(GET_ADMIN_FUNCTION_SETTINGS[REQUEST], { payload: { functionType } }),
  success: (response: any) => action(GET_ADMIN_FUNCTION_SETTINGS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_ADMIN_FUNCTION_SETTINGS[FAILURE], { payload: error })
};

export const SAVE_ADMIN_FUNCTION_SETTINGS = createRequestTypes('SAVE_ADMIN_FUNCTION_SETTINGS');
export const saveAdminFunctionSettingsActions = {
  request: (request: ISaveAdminFunctionSettingsRequest) => action(SAVE_ADMIN_FUNCTION_SETTINGS[REQUEST], { payload: request }),
  success: (response: any) => action(SAVE_ADMIN_FUNCTION_SETTINGS[SUCCESS], { payload: response }),
  failure: (error: any) => action(SAVE_ADMIN_FUNCTION_SETTINGS[FAILURE], { payload: error })
};

export const RESTORE_PORTFOLIO_ENTITY = createRequestTypes('RESTORE_PORTFOLIO_ENTITY');
export const restorePortfolioEntityActions = {
  request: (entityId: number, entityType: string, adminHistoryRequestData: IAdminHistoryRequest) => action(RESTORE_PORTFOLIO_ENTITY[REQUEST], { payload: { entityId, entityType, adminHistoryRequestData } }),
  success: (response: any) => action(RESTORE_PORTFOLIO_ENTITY[SUCCESS], { payload: response }),
  failure: (error: any) => action(RESTORE_PORTFOLIO_ENTITY[FAILURE], { payload: error })
};

export const GET_ADMIN_INCORRECT_DATA_FILE = createRequestTypes('GET_ADMIN_INCORRECT_FILE_FILE');
export const getAdminIncorrectDataFileActions = {
  request: (request: IGetAdminIncorrectDataRequest) => action(GET_ADMIN_INCORRECT_DATA_FILE[REQUEST], { payload: request }),
  success: (response: any) => action(GET_ADMIN_INCORRECT_DATA_FILE[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_ADMIN_INCORRECT_DATA_FILE[FAILURE], { payload: error }),
};

export const SET_ADMIN_VIEWING_MODE = 'SET_ADMIN_VIEWING_MODE';
export const setAdminViewingModeAction = (isViewing: boolean) => action(SET_ADMIN_VIEWING_MODE, { payload: { isViewing } });

export const SET_ADMIN_VIEWING_TYPE = 'SET_ADMIN_VIEWING_TYPE';
export const setAdminViewingTypeAction = (type: AdminPortfolioViewType) => action(SET_ADMIN_VIEWING_TYPE, { payload: { type } });
