import { FC, Fragment, useState } from 'react';

import { Box, useMediaQuery } from '@mui/material';
import ShowMore from 'components/common/ShowMore';
import InlinePadding from 'portfolio3/components/common/InlinePadding';
import { ILocalVuzSpeciality } from 'portfolio3/features/vuzRecommendation';
import { commonTheme } from 'portfolio3/styles/theme';

import { cardSpecialityMapFactory } from '../../utils';
import * as styles from './styles';

interface IVuzCardListProps {
  specialities: ILocalVuzSpeciality[];
}
const INITIAL_SPECIALITY_ELEMENTS_COUNT = 3;

const VuzCardList: FC<IVuzCardListProps> = ({ specialities }) => {
  const isSmallScreen = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const [specialityDisplayCount, setSpecialityDisplayCount] = useState(INITIAL_SPECIALITY_ELEMENTS_COUNT);
  const totalSpecialitiesCount = specialities.length || 0;
  const isAllSpecialitiesShown = specialityDisplayCount === totalSpecialitiesCount;
  const hiddenSpecialityElementsCount = totalSpecialitiesCount - INITIAL_SPECIALITY_ELEMENTS_COUNT;

  const mapCardSpeciality = cardSpecialityMapFactory('20px', '12px', styles.specialityCard);
  const specialityElements = specialities.slice(0, specialityDisplayCount).map((speciality, index) => {
    const { number } = speciality;

    return (
      <Fragment key={`${number}-${index}`}>
        {index !== 0 && (
          <InlinePadding _p={isSmallScreen ? '12px' : '20px'} marginTop={0}>
            <Box sx={styles.specialityCardDivider} />
          </InlinePadding>
        )}
        {mapCardSpeciality(speciality, index)}
      </Fragment>
    );
  });

  const handleShowAllSubjects = () => setSpecialityDisplayCount(totalSpecialitiesCount);
  const handleShowInitialSubjects = () => setSpecialityDisplayCount(INITIAL_SPECIALITY_ELEMENTS_COUNT);

  if (specialityElements.length === 0) {
    return null;
  }

  return (
    <Box className="vuz-card-list" sx={styles.root}>
      {specialityElements}
      {hiddenSpecialityElementsCount > 0 && (
        <InlinePadding _p={isSmallScreen ? '12px' : '20px'}>
          <ShowMore
            hiddenCount={hiddenSpecialityElementsCount}
            isAllShown={isAllSpecialitiesShown}
            onShowAllItems={handleShowAllSubjects}
            onShowInitialItems={handleShowInitialSubjects}
          />
        </InlinePadding>
      )}
    </Box>
  );
};

export default VuzCardList;
