import {
  DETAIL_VIEW_DRAWER_SET_PRIMARY_CARD_DATA,
  DETAIL_VIEW_DRAWER_SET_PRIMARY_OPEN,
  DETAIL_VIEW_DRAWER_SET_SECONDARY_CARD_DATA,
  DETAIL_VIEW_DRAWER_SET_SECONDARY_OPEN,
  detailViewDrawerActions,
} from 'actions';
import { IReducers } from 'reducers';
import { ICardData } from 'types';
import { connectReducers } from 'utils';

type DrawerState = {
  isOpen: boolean;
  widgetLabel: string | null;
};

export type DetailViewDrawerState = {
  primaryState: DrawerState | null;
  secondaryState: DrawerState | null;
  primaryCardData: ICardData | undefined;
  secondaryCardData: ICardData | undefined;
};

const initialState: DetailViewDrawerState = {
  primaryState: null,
  secondaryState: null,
  primaryCardData: undefined,
  secondaryCardData: undefined,
};

type SetPrimaryOpenAction = ReturnType<typeof detailViewDrawerActions.setPrimaryOpen>['payload'];
type SetSecondaryOpenAction = ReturnType<typeof detailViewDrawerActions.setSecondaryOpen>['payload'];
type SetPrimaryCardDataAction = ReturnType<typeof detailViewDrawerActions.setPrimaryCardData>['payload'];
type SetSecondaryCardDataAction = ReturnType<typeof detailViewDrawerActions.setSecondaryCardData>['payload'];

const detailViewDrawer: IReducers<DetailViewDrawerState> = {
  [DETAIL_VIEW_DRAWER_SET_PRIMARY_OPEN]: (state, action: SetPrimaryOpenAction) => {
    const drawerState: DrawerState = {
      isOpen: action.payload.open,
      widgetLabel: action.payload.widgetLabel,
    };
    return { ...state, primaryState: drawerState };
  },
  [DETAIL_VIEW_DRAWER_SET_SECONDARY_OPEN]: (state, action: SetSecondaryOpenAction) => {
    const drawerState: DrawerState = {
      isOpen: action.payload.open,
      widgetLabel: action.payload.widgetLabel,
    };
    return { ...state, secondaryState: drawerState };
  },
  [DETAIL_VIEW_DRAWER_SET_PRIMARY_CARD_DATA]: (state, action: SetPrimaryCardDataAction) => {
    return { ...state, primaryCardData: action.payload };
  },
  [DETAIL_VIEW_DRAWER_SET_SECONDARY_CARD_DATA]: (state, action: SetSecondaryCardDataAction) => {
    return { ...state, secondaryCardData: action.payload };
  },
};

export default connectReducers(initialState, detailViewDrawer);
