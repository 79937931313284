import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import TrimText from 'portfolio3/components/common/TrimText';
import TrimTextMultiLine from 'portfolio3/components/common/TrimTextMultiLine';
import { mergeSx } from 'utils';

import PdfBadge from '../../PdfBadge';
import * as styles from './styles';

interface IDocumentCardProps {
  name: string;
  badge: string;
  preview?: string;
  previewPlaceholderText?: string;
}

const DocumentCard: FC<IDocumentCardProps> = ({ name, badge, preview, previewPlaceholderText }) => {
  return (
    <Box className="document-card pdf-keep" sx={styles.root}>
      <Box className="document-card__preview" sx={mergeSx(Boolean(preview) && { backgroundImage: `url(${preview})` })}>
        {!preview && (
          <Box className="document-card__preview-placeholder" sx={styles.previewPlaceholder}>
            <TrimText sx={styles.previewPlaceholderText}>{previewPlaceholderText}</TrimText>
          </Box>
        )}
      </Box>
      <Box className="document-card__body" sx={styles.body}>
        <PdfBadge>
          <TrimText>{badge}</TrimText>
        </PdfBadge>
        <Typography component={TrimTextMultiLine} line={3} variant="Paragraph MD/Regular">
          {name}
        </Typography>
      </Box>
    </Box>
  );
};

export default DocumentCard;
