import React from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { Doughnut } from '@reactchartjs/react-chart.js';
import { NeutralColors } from 'portfolio3/styles';

import { container, score } from './styles';

interface IDiagramProps {
  total: number;
  title: string;
  color: string;
  incomingData: number[];
  incomingLabels: string[];
}

const Diagram: React.FC<IDiagramProps> = ({ total, title, color, incomingData, incomingLabels }) => {
  const singleData = () => ({
    labels: incomingLabels,
    datasets: [
      {
        data: incomingData,
        backgroundColor: [color, NeutralColors.light_neutrals_300],
        hoverBackgroundColor: [color, NeutralColors.light_neutrals_300],
        borderWidth: 0,
      },
    ],
  });

  const options = {
    responsice: true,
    maintainAspectRatio: false,
    cutoutPercentage: 90,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };

  return (
    <Box sx={container}>
      <Doughnut data={singleData} options={options} type="doughnut" />
      <Stack sx={score}>
        <Typography variant="Headings/H6">{total}%</Typography>
        <Typography variant="Paragraph SM/Medium" color={NeutralColors.light_neutrals_600}>
          {title}
        </Typography>
      </Stack>
    </Box>
  );
};

export default Diagram;
