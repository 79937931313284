import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const Crafts: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="230" height="129" viewBox="0 0 230 129" xmlns="http://www.w3.org/2000/svg" {...props} fill="none">
    {/*eslint-disable max-len */}
    <path
      fill={props.fill || 'currentColor'}
      d="M200.53,45a.41.41,0,0,1-.19,0,.49.49,0,0,1-.27-.65,19.73,19.73,0,0,1,5.36-7,.5.5,0,0,1,.62.79A19.12,19.12,0,0,0,201,44.69.49.49,0,0,1,200.53,45Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M172.35,72a.5.5,0,0,1-.07-1A5.24,5.24,0,0,0,176,68.79c2.82-4,1.36-11.68,1.34-11.76a.51.51,0,0,1,.4-.59.5.5,0,0,1,.58.4c.07.33,1.56,8.22-1.5,12.53A6.22,6.22,0,0,1,172.42,72Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M171.19,68.55a.51.51,0,0,1-.49-.41.5.5,0,0,1,.4-.58,4.28,4.28,0,0,0,2.84-1.86c1.7-2.57.86-6.78.85-6.82a.5.5,0,0,1,1-.2c0,.18.94,4.65-1,7.57a5.29,5.29,0,0,1-3.49,2.29Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M191.73,111.25c-2.22,0-3.87-.14-4-.16a.48.48,0,0,1-.45-.54.49.49,0,0,1,.54-.45c.11,0,11.41,1,15-2.18a2.28,2.28,0,0,0,.67-2.76c-1-2.59-5.69-5.65-10-5.57h-5.74a.51.51,0,0,1-.5-.5.5.5,0,0,1,.5-.5h5.73c4.38-.06,9.71,2.94,11,6.22a3.29,3.29,0,0,1-1,3.87C201,110.84,195.53,111.25,191.73,111.25Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M187.35,99.7h-9.23a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5h9.23a.5.5,0,0,1,.5.5A.5.5,0,0,1,187.35,99.7Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M202.52,41.63a.54.54,0,0,1-.22-.05c-8.15-3.95-6.85-13.71-6.84-13.8a.5.5,0,1,1,1,.14c0,.37-1.2,9.13,6.29,12.76a.5.5,0,0,1-.22,1Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M159.08,28.35l-.13,0a.49.49,0,0,1-.37-.44c0-.27-.65-6.66-8.16-6.66-2.66,0-4.54.64-5.58,1.9a4.34,4.34,0,0,0-.85,3.28.5.5,0,0,1-.93.33c-.17-.31-4-7.54-1.24-12.15,1.42-2.38,4.31-3.59,8.6-3.59,6.09,0,10,1.52,11.55,4.52,2.65,5.05-2.27,12.3-2.48,12.61A.5.5,0,0,1,159.08,28.35Zm-8.66-8.12c6.26,0,8.27,4.12,8.89,6.29,1.22-2.13,3.6-7.06,1.77-10.53-1.39-2.65-5-4-10.66-4-3.91,0-6.51,1-7.75,3.1-1.67,2.81-.5,6.94.44,9.31a4.88,4.88,0,0,1,.95-1.91C145.3,21,147.44,20.23,150.42,20.23Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M151.69,41.73a9.61,9.61,0,0,1-6-2.14.49.49,0,0,1-.19-.39V33.35A9.39,9.39,0,0,1,143,26.5a.5.5,0,0,1,.5-.5.51.51,0,0,1,.5.5c0,4.33,2.27,6.22,2.29,6.24a.47.47,0,0,1,.19.38V39c4.75,3.53,9.46,1,10.8.1-.71-1.23-2.6-4.87-1.54-7a.51.51,0,0,1,.29-.25c.08,0,2-.76,2.55-4a.51.51,0,0,1,.57-.42.5.5,0,0,1,.42.57c-.52,3.28-2.36,4.43-3,4.73-.66,1.74,1.05,5.1,1.8,6.28a.5.5,0,0,1-.12.66A12,12,0,0,1,151.69,41.73Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M143.58,28.35a.5.5,0,0,1-.32-.89,39.73,39.73,0,0,0,6.75-7,.5.5,0,0,1,.61-.18.49.49,0,0,1,.29.56s-.42,3.06,7.36,5.19a.5.5,0,0,1,.35.61A.49.49,0,0,1,158,27c-5.59-1.53-7.35-3.62-7.88-5a43.21,43.21,0,0,1-6.23,6.24A.51.51,0,0,1,143.58,28.35Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M166.63,58h0c-3.71-.17-6.64-.54-6.67-.54a.51.51,0,0,1-.44-.56.52.52,0,0,1,.56-.44s2.93.37,6.59.54a.5.5,0,0,1,0,1Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M148.63,122c-18.36,0-24.83-7.12-25.13-7.47a.86.86,0,0,1-.07-.1,7,7,0,0,1-.63-2.82,5.15,5.15,0,0,1,3.16-4.78,11.19,11.19,0,0,0,5.49-6.51c-1.11-8.11,3.32-18.2,3.51-18.63a.5.5,0,0,1,.92.41c0,.1-4.55,10.35-3.43,18.19a.41.41,0,0,1,0,.19,12,12,0,0,1-6,7.25,4.5,4.5,0,0,0-2.11,6.19c.67.69,9.09,8.88,31.84,6.71,24-2.28,29.34-4.18,31.16-7.08,1.58-2.52-3.21-7.8-8.33-11.64-2.27-1.7-2.48-5.07-2.72-9-.28-4.64-.6-9.89-4.28-14.18a.52.52,0,0,1-.1-.48.48.48,0,0,1,.36-.33,12.11,12.11,0,0,1,9.65,2.39c7.81,5.86,6.48,18.87,6.07,21.83h4.71l1.12-12.24c0-5.23,2.17-8,4.69-11.09a29.57,29.57,0,0,0,5.72-9.8c3.34-10-8.47-26.5-8.59-26.67a.5.5,0,0,1,0-.63.49.49,0,0,1,.63-.08c3.44,2.15,9.44,5,10.92,3.53.93-.92.24-3.54-1.88-7.18a12.78,12.78,0,0,1-1.73-6.43V25.41c-2.28.62-10.47,3.15-15.27,8.91-3.15,3.79-3.46,8.37-3.74,12.41-.21,3.2-.42,6.22-2,8.34-1.36,1.82-4.9,2.83-10.52,3a.52.52,0,0,1-.51-.49.49.49,0,0,1,.48-.51c5.23-.18,8.6-1.08,9.75-2.61,1.41-1.9,1.6-4.64,1.82-7.82.28-4.19.6-8.94,4-13,5.81-7,16-9.3,16.43-9.4a.55.55,0,0,1,.43.1.53.53,0,0,1,.18.39v6.81a11.81,11.81,0,0,0,1.59,5.93c2.47,4.24,3.05,7.06,1.73,8.39-1.9,1.89-7.28-.65-10.24-2.27,3.05,4.67,10.33,17.15,7.47,25.73a30.17,30.17,0,0,1-5.88,10.1c-2.5,3.12-4.48,5.59-4.48,10.51l-1.15,12.74a.51.51,0,0,1-.5.46h-5.76A.53.53,0,0,1,187,103a.56.56,0,0,1-.11-.41c0-.15,2.57-15.32-5.59-21.44a11.57,11.57,0,0,0-8-2.34c3.37,4.42,3.7,9.75,4,14.08.22,3.65.42,6.8,2.32,8.23,5.25,3.94,10.64,9.69,8.58,13s-7.34,5.21-31.91,7.55C153.5,121.89,151,122,148.63,122Z"
    />
    <circle fill={props.fill || 'currentColor'} cx="153.88" cy="29.96" r="1.15" />
    <circle fill={props.fill || 'currentColor'} cx="146.96" cy="29.96" r="1.15" />
    <path
      fill={props.fill || 'currentColor'}
      d="M152.62,115a14.94,14.94,0,0,1-3.47-.34,58.38,58.38,0,0,0-14.88-2.29,9.6,9.6,0,0,1-8.53-4.89.5.5,0,0,1,.22-.67.49.49,0,0,1,.67.22,8.62,8.62,0,0,0,7.64,4.34,59.12,59.12,0,0,1,15.11,2.32c4.46,1.11,14.68-1.13,14.78-1.16.4-.07,9.39-1.73,14.05-.86,4.23.79,7.27-4.12,7.3-4.17a.5.5,0,0,1,.69-.16.49.49,0,0,1,.17.68c-.14.23-3.45,5.55-8.34,4.63-4.47-.83-13.58.85-13.67.86A64.24,64.24,0,0,1,152.62,115Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M134.33,59a4.76,4.76,0,0,1-.83-.08c-4-.74-7.58-6.48-7.74-6.72a.52.52,0,0,1-.07-.25,2.55,2.55,0,0,1,1-2.11c.94-.6,2.37-.44,4.24.49a.5.5,0,0,1,.23.67.51.51,0,0,1-.67.23c-1.91-.95-2.83-.82-3.26-.55a1.5,1.5,0,0,0-.59,1.12c.55.84,3.76,5.54,7,6.14a3.15,3.15,0,0,0,2.59-.62c5.86-4.4,8.4-12.31,9-16.29a2.11,2.11,0,0,0-.41-1.59,2,2,0,0,0-1.31-.74,4.87,4.87,0,0,0-3.22.92c-3.25,2.18-5.12,11.17-5.14,11.26a.5.5,0,0,1-.59.39.51.51,0,0,1-.39-.59c.08-.39,2-9.49,5.57-11.89a5.79,5.79,0,0,1,3.87-1.08,2.92,2.92,0,0,1,2,1.11,3.1,3.1,0,0,1,.61,2.35c-.75,5.13-3.65,12.65-9.37,17A4.1,4.1,0,0,1,134.33,59Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M152.73,80.09a12.43,12.43,0,0,1-8.8-3.34,7.2,7.2,0,0,1-2.18-5.25c.05-2.76,1.26-5.43,2.22-7.57a11.78,11.78,0,0,0,1.31-3.82c0-1.84-2.37-4.64-3.29-5.56a.5.5,0,0,1-.11-.53,10.68,10.68,0,0,1,10.33-6.22A11.09,11.09,0,0,1,162.43,54a.54.54,0,0,1,0,.43.52.52,0,0,1-.35.25c-.1,0-2.05.42-2,3.74a7.89,7.89,0,0,0,1.64,3.88c1.38,2.17,3.1,4.87,2.18,9.3C162.41,78.74,158.83,80.09,152.73,80.09Zm-9.79-26c.81.86,3.36,3.76,3.34,6a12.46,12.46,0,0,1-1.4,4.22c-1,2.17-2.08,4.62-2.13,7.18a6.21,6.21,0,0,0,1.89,4.53,11.46,11.46,0,0,0,8.09,3c5.69,0,8.83-1.19,10.18-7.68.84-4-.69-6.44-2-8.56a8.71,8.71,0,0,1-1.8-4.4c-.06-3,1.36-4.12,2.2-4.53a10,10,0,0,0-9.08-5.12A9.59,9.59,0,0,0,142.94,54.09Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M154.82,90c-1.16,0-1.89-.07-1.92-.07s-16.76.44-19.12-11.38C131.68,68.06,145,60,145.52,59.69a.5.5,0,0,1,.53.85c-.07,0-6.67,4.42-6.67,12.12A9.81,9.81,0,0,0,142.19,80c3.87,3.81,10.47,3.75,10.53,3.75s8.51-.11,12.51-4.67a9.13,9.13,0,0,0,2-7.46,17.39,17.39,0,0,0-7.91-12.69.51.51,0,0,1-.2-.68.49.49,0,0,1,.68-.2s.22.12.49.3a.48.48,0,0,1,.29,0c.15.05,15,5,12.25,20.22C171,88.91,159.45,90,154.82,90Zm-1.89-1.07c.23,0,16.73,1.52,18.93-10.57,1.92-10.6-5-15.86-9-18a18.64,18.64,0,0,1,5.37,11.07A10.1,10.1,0,0,1,166,79.69c-4.31,4.9-12.88,5-13.25,5s-7.07.07-11.24-4a10.79,10.79,0,0,1-3.11-8,14.77,14.77,0,0,1,2.38-8c-3.44,3.26-7.1,8.18-6,13.68C137,89.33,152.77,88.92,152.93,88.91Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M153.88,108.94a5.12,5.12,0,1,1,5.12-5.12A5.12,5.12,0,0,1,153.88,108.94Zm0-9.24a4.12,4.12,0,1,0,4.12,4.12A4.12,4.12,0,0,0,153.88,99.7Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M153.88,112.4a8.58,8.58,0,1,1,8.58-8.58A8.58,8.58,0,0,1,153.88,112.4Zm0-16.16a7.58,7.58,0,1,0,7.58,7.58A7.59,7.59,0,0,0,153.88,96.24Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M162.4,94.83a6,6,0,0,1-1.88-.29,9.43,9.43,0,0,1-4.9-4.43.49.49,0,0,1,.22-.67.5.5,0,0,1,.67.22s1.55,3,4.32,3.93a5.87,5.87,0,0,0,4.63-.6c3.85-2,1.63-6.38,1.54-6.56a.5.5,0,1,1,.88-.47c1,1.86,1.83,5.9-1.95,7.92A7.61,7.61,0,0,1,162.4,94.83Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M172.84,89.48a2,2,0,0,1-.75-.15c-1.87-.76-3.12-4.18-3.26-4.57a.49.49,0,0,1,.3-.63.5.5,0,0,1,.64.3c.33.94,1.46,3.47,2.69,4a.91.91,0,0,0,.82,0c2-1,1.51-4.67,1.5-4.71a.51.51,0,0,1,.43-.56.49.49,0,0,1,.56.43c0,.18.55,4.44-2,5.74A2,2,0,0,1,172.84,89.48Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M146.87,94.13a10.24,10.24,0,0,1-2-.21,5.76,5.76,0,0,1-4.17-2.59,6.32,6.32,0,0,1-.38-4.74.51.51,0,0,1,.64-.31.5.5,0,0,1,.31.63,5.37,5.37,0,0,0,.3,3.93,4.83,4.83,0,0,0,3.49,2.1c5.52,1.06,6.94-2.68,7-2.84a.5.5,0,1,1,.94.33A6.25,6.25,0,0,1,146.87,94.13Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M133.6,90.78l-.48,0a.5.5,0,1,1,.06-1,4,4,0,0,0,4.55-3.62.48.48,0,0,1,.54-.45.49.49,0,0,1,.45.54A5.06,5.06,0,0,1,133.6,90.78Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M175.1,109a3.11,3.11,0,0,1-.43,0,6.9,6.9,0,0,1-6.28-6.18c-1.21-7.28,4.1-8.88,4.16-8.9h0c2.38-.4,3.93,1.44,5.35,6.38a12.7,12.7,0,0,1,.46,6.08A3.37,3.37,0,0,1,175.1,109Zm-2.3-14.14c-.39.12-4.45,1.56-3.42,7.76a5.89,5.89,0,0,0,5.41,5.36,2.37,2.37,0,0,0,2.64-1.84,11.74,11.74,0,0,0-.44-5.6C175.26,94.48,173.67,94.67,172.8,94.82Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M174.33,105.77h-.26a4,4,0,0,1-3.64-3.59c-.7-4.21,2.41-5.14,2.44-5.15h0c1.46-.25,2.38.81,3.2,3.67a7.25,7.25,0,0,1,.26,3.45A2.09,2.09,0,0,1,174.33,105.77ZM173.13,98c-.26.08-2.24.86-1.72,4a3,3,0,0,0,2.79,2.76,1.08,1.08,0,0,0,1.2-.83,6.33,6.33,0,0,0-.24-3C174.28,97.89,173.52,97.92,173.13,98Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M175.1,109a3.11,3.11,0,0,1-.43,0,6.9,6.9,0,0,1-6.28-6.18.51.51,0,0,1,.41-.58.51.51,0,0,1,.58.41,5.89,5.89,0,0,0,5.41,5.36,2.37,2.37,0,0,0,2.64-1.84,11.74,11.74,0,0,0-.44-5.6.5.5,0,0,1,.34-.62.49.49,0,0,1,.62.34,12.7,12.7,0,0,1,.46,6.08A3.37,3.37,0,0,1,175.1,109Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M134.79,110.11a3.38,3.38,0,0,1-3.32-2.65,12.86,12.86,0,0,1,.46-6.09c1.42-4.93,3-6.78,5.35-6.37h.06s5.36,1.61,4.15,8.89a6.92,6.92,0,0,1-6.28,6.19A3,3,0,0,1,134.79,110.11Zm1.82-14.18c-.91,0-2.27.67-3.72,5.72a11.77,11.77,0,0,0-.44,5.61,2.39,2.39,0,0,0,2.64,1.84,5.91,5.91,0,0,0,5.41-5.36c1-6.2-3-7.64-3.42-7.76A2.26,2.26,0,0,0,136.61,95.93Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M135.55,106.93a2.09,2.09,0,0,1-2.05-1.65,7.25,7.25,0,0,1,.26-3.45c.82-2.85,1.75-3.91,3.2-3.67h0s3.15,1,2.45,5.15a4,4,0,0,1-3.65,3.59Zm1-7.81c-.42,0-1.1.34-1.86,3a6.43,6.43,0,0,0-.24,3,1.09,1.09,0,0,0,1.2.84,3,3,0,0,0,2.79-2.76c.53-3.16-1.46-3.93-1.71-4Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M134.79,110.11a3.38,3.38,0,0,1-3.32-2.65,12.86,12.86,0,0,1,.46-6.09.51.51,0,0,1,.62-.34.5.5,0,0,1,.34.62,11.77,11.77,0,0,0-.44,5.61,2.39,2.39,0,0,0,2.64,1.84,5.91,5.91,0,0,0,5.41-5.36.5.5,0,0,1,.58-.41.49.49,0,0,1,.41.57,6.92,6.92,0,0,1-6.28,6.19A3,3,0,0,1,134.79,110.11Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M134.16,54.63H134a.5.5,0,0,1-.48-.52,3.27,3.27,0,0,0-.78-2.11,2.68,2.68,0,0,0-2-.73.5.5,0,0,1-.12-1c.2-.05,5.06-1.22,6.38,1.42a1.76,1.76,0,0,1,0,1.85C136.42,54.56,134.68,54.63,134.16,54.63Zm-1-3.65a3.8,3.8,0,0,1,.37.33,4.05,4.05,0,0,1,1,2.31c.62,0,1.44-.21,1.69-.6q.19-.3-.09-.87C135.66,51.22,134.37,51,133.14,51Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M133.67,61.05a3.34,3.34,0,0,1-1.5-.36l-.21-.1-.15.07a3,3,0,0,1-3.78-1l-1-1.44h-.17a1.86,1.86,0,0,1-1.4-3.1l1.15-1.31a.51.51,0,0,1,.71,0,.51.51,0,0,1,0,.71l-1.15,1.31a.87.87,0,0,0,.65,1.44h.44a.5.5,0,0,1,.42.23l1.1,1.66a2,2,0,0,0,2.5.66l.37-.18a.51.51,0,0,1,.45,0l.43.22a2.35,2.35,0,0,0,2.75-.48l1.93-2a.51.51,0,0,1,.26-.14l5.85-1.23a.51.51,0,0,1,.59.39.5.5,0,0,1-.39.59l-5.69,1.19L136.08,60A3.33,3.33,0,0,1,133.67,61.05Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M169.29,58.94a4.19,4.19,0,0,1-2.54-.88c-5.72-4.3-8.62-11.82-9.37-17a3.1,3.1,0,0,1,.62-2.35,2.89,2.89,0,0,1,2-1.12,5.9,5.9,0,0,1,3.87,1.09c3.6,2.4,5.49,11.5,5.57,11.89a.51.51,0,0,1-.39.59.5.5,0,0,1-.59-.39c0-.09-1.89-9.08-5.14-11.26a4.87,4.87,0,0,0-3.22-.92,1.84,1.84,0,0,0-1.3.74,2.09,2.09,0,0,0-.42,1.59c.58,4,3.13,11.89,9,16.29a3.18,3.18,0,0,0,2.59.62c3.23-.6,6.44-5.3,7-6.14a1.5,1.5,0,0,0-.59-1.12c-.43-.27-1.35-.4-3.26.55a.5.5,0,0,1-.44-.9c1.87-.93,3.3-1.1,4.25-.49a2.53,2.53,0,0,1,1,2.11.45.45,0,0,1-.07.25c-.15.24-3.77,6-7.73,6.72A5,5,0,0,1,169.29,58.94Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M169.46,54.58c-.52,0-2.26-.08-2.9-1.08a1.78,1.78,0,0,1,0-1.85c1.32-2.64,6.18-1.47,6.38-1.42a.5.5,0,0,1-.12,1,2.68,2.68,0,0,0-2,.73,3.24,3.24,0,0,0-.77,2.11.51.51,0,0,1-.49.52Zm1-3.65c-1.22,0-2.47.26-2.92,1.17-.19.38-.23.67-.1.86.25.4,1.08.56,1.7.61a4,4,0,0,1,1-2.31,3.8,3.8,0,0,1,.37-.33Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M170,61a3.33,3.33,0,0,1-2.41-1l-1.93-2a.51.51,0,0,1,0-.71.5.5,0,0,1,.71,0l1.93,2a2.33,2.33,0,0,0,2.75.47l.43-.21a.51.51,0,0,1,.45,0l.37.18a2,2,0,0,0,2.5-.66l1.1-1.67a.51.51,0,0,1,.42-.22h.44a.87.87,0,0,0,.65-1.44l-1.15-1.31a.51.51,0,0,1,.05-.71.49.49,0,0,1,.7,0l1.15,1.31a1.87,1.87,0,0,1-1.4,3.1h-.17l-1,1.44a3,3,0,0,1-3.78,1l-.15-.08-.2.11A3.39,3.39,0,0,1,170,61Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M205.74,38.26a.5.5,0,0,1-.42-.24c-2.18-3.42-4.68-3-4.71-3a.5.5,0,0,1-.16-1c.12,0,3.19-.48,5.71,3.5a.5.5,0,0,1-.15.69A.53.53,0,0,1,205.74,38.26Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M155,27.58a.51.51,0,0,1-.42-.23c-1.12-1.77-2.37-1.61-2.42-1.61a.5.5,0,0,1-.16-1c.08,0,1.93-.29,3.42,2.06a.5.5,0,0,1-.15.69A.53.53,0,0,1,155,27.58Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M145.14,27.58a.5.5,0,0,1-.42-.77c1.49-2.35,3.34-2.07,3.42-2.06a.51.51,0,0,1,.41.58.5.5,0,0,1-.57.41c-.06,0-1.31-.15-2.42,1.61A.51.51,0,0,1,145.14,27.58Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M189,48.78a.5.5,0,0,1-.34-.13c-.06-.06-6.46-6-1.12-14.9a.5.5,0,0,1,.68-.18.51.51,0,0,1,.18.69c-4.91,8.18.69,13.43.93,13.65a.49.49,0,0,1,0,.7A.51.51,0,0,1,189,48.78Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M190.81,43.16a.49.49,0,0,1-.41-.22,11.16,11.16,0,0,1,.91-12.78.52.52,0,0,1,.71-.08.5.5,0,0,1,.08.7,10,10,0,0,0-.87,11.6.5.5,0,0,1-.14.69A.49.49,0,0,1,190.81,43.16Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M188.91,74.27a2.39,2.39,0,0,1-2.19-1,2.22,2.22,0,0,1-.27-.81,2.09,2.09,0,0,1-1,.45,1.82,1.82,0,0,1-1.65-.79,4.07,4.07,0,0,1-1.16-2.45,2,2,0,0,1-1.19-.19,2.18,2.18,0,0,1-.93-2.08,2.44,2.44,0,0,1,1-2.22,2.39,2.39,0,0,1,1.61-.31,2.42,2.42,0,0,1-.12-.68,2.57,2.57,0,0,1,.85-1.89,1.76,1.76,0,0,1,1.55-.57A2.89,2.89,0,0,1,187,62.85c.21-1,.72-2.23,1.94-2.23,1.41,0,1.87,1.61,2,2.71a3.1,3.1,0,0,1,1.84-.53,2.28,2.28,0,0,1,1.69,1.07,1.74,1.74,0,0,1,.28,1.42,5.58,5.58,0,0,1-2.38,2.76c.79.64,2.07,1.86,2.18,3.07a1.88,1.88,0,0,1-2.07,2,2.25,2.25,0,0,1-1.1-.55,2,2,0,0,1-.27.76A2.36,2.36,0,0,1,188.91,74.27Zm-1.13-3.44h0c-.18.36-.56,1.4-.2,2,.19.32.64.48,1.33.48s1.14-.16,1.33-.48c.35-.57,0-1.63-.2-2a.5.5,0,0,1,.9-.45h0c.21.41,1,1.62,1.68,1.74a.9.9,0,0,0,.92-.92c-.08-.9-1.48-2.19-2.35-2.78A.49.49,0,0,1,191,68a.51.51,0,0,1,.25-.42c.63-.36,2.3-1.51,2.52-2.51a.72.72,0,0,0-.12-.63,1.3,1.3,0,0,0-1-.64,2.84,2.84,0,0,0-1.83.84.51.51,0,0,1-.54.11.5.5,0,0,1-.31-.47c0-.56-.19-2.66-1.08-2.66s-1.08,2.1-1.08,2.66a.49.49,0,0,1-.39.49.51.51,0,0,1-.56-.26c-.21-.42-1-1.63-1.68-1.74a.76.76,0,0,0-.68.29,1.61,1.61,0,0,0-.56,1.15,1.83,1.83,0,0,0,.54,1.15.5.5,0,0,1,.08.65.49.49,0,0,1-.62.17,2.48,2.48,0,0,0-2-.11c-.34.21-.51.68-.51,1.38,0,.44.07,1,.42,1.2a1.65,1.65,0,0,0,1.25-.11,2.11,2.11,0,0,1,.92-.42.5.5,0,0,1,.34.92,5.13,5.13,0,0,1-.71.36s-.05.08-.07.13-.12.76.9,2c.39.48.67.45.77.44.62-.07,1.32-1.1,1.56-1.58a.5.5,0,0,1,.9.44Z"
    />
    <circle fill={props.fill || 'currentColor'} cx="188.91" cy="67.45" r="1.58" />
    <path
      fill={props.fill || 'currentColor'}
      d="M197.74,57a2.81,2.81,0,1,1,2.8-2.81A2.81,2.81,0,0,1,197.74,57Zm0-4.62a1.81,1.81,0,1,0,1.8,1.81A1.81,1.81,0,0,0,197.74,52.39Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M182.16,80.09a2.24,2.24,0,1,1,2.23-2.24A2.24,2.24,0,0,1,182.16,80.09Zm0-3.47a1.24,1.24,0,1,0,1.23,1.23A1.24,1.24,0,0,0,182.16,76.62Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M194.89,68a.52.52,0,0,1-.42-.23.5.5,0,0,1,0-.57c.26-.36,2.67-3.54,4.57-3.68a1.55,1.55,0,0,1,1.27.46,1.13,1.13,0,0,1,.35,1.15c-.43,1.5-4.45,2.56-5.68,2.85Zm4.31-3.49h-.06c-.78.06-1.93,1.07-2.86,2.09,1.74-.55,3.3-1.25,3.44-1.74,0,0,0-.06-.1-.17A.56.56,0,0,0,199.2,64.46Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M199.08,74.59h-.23c-1.83-.24-3.82-3.35-4-3.71a.5.5,0,0,1,0-.56.51.51,0,0,1,.53-.19c1.13.29,4.88,1.33,5.26,2.82a1.17,1.17,0,0,1-.4,1.2A1.64,1.64,0,0,1,199.08,74.59Zm-2.62-3.11c.78,1,1.78,2,2.52,2.11a.62.62,0,0,0,.55-.18c.15-.14.13-.2.13-.22C199.53,72.71,198.08,72,196.46,71.48Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M175,76.82a2.38,2.38,0,0,1-1.5-.4,1.48,1.48,0,0,1-.5-1.53,1.88,1.88,0,0,1,1-1.45c1.9-1,5.76.9,6.19,1.11a.49.49,0,0,1,.28.46.51.51,0,0,1-.3.45A16.1,16.1,0,0,1,175,76.82Zm.38-2.67a2,2,0,0,0-.91.18.89.89,0,0,0-.48.72c-.08.43.07.54.12.59.63.49,2.78,0,4.58-.67A10,10,0,0,0,175.39,74.15Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M189.66,86.28a.5.5,0,0,1-.49-.4,8.06,8.06,0,0,1,4.9-8.73.49.49,0,0,1,.47,0,.48.48,0,0,1,.23.42v3.13a.51.51,0,0,1-.21.41c-1.7,1.16-4.4,3.45-4.4,4.63a.5.5,0,0,1-.45.49Zm4.11-7.86a6.87,6.87,0,0,0-3.67,5.33,20,20,0,0,1,3.67-3.27Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M190.81,62.78a.51.51,0,0,1-.5-.47c0-.23-.1-2.2,1.06-3a2.37,2.37,0,0,1,2.41,0,.5.5,0,1,1-.41.91,1.46,1.46,0,0,0-1.43-.1c-.6.41-.66,1.7-.63,2.11a.49.49,0,0,1-.47.53Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M191.17,58.16a.5.5,0,0,1-.44-.25c-1.71-3-4.25-2.13-4.36-2.09a.5.5,0,0,1-.64-.29.49.49,0,0,1,.29-.64s3.44-1.27,5.58,2.52a.51.51,0,0,1-.19.69A.53.53,0,0,1,191.17,58.16Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M191.92,49.47h0a.5.5,0,0,1-.49-.51c0-.3.07-1.81.83-2.36a1.1,1.1,0,0,1,1-.15.5.5,0,0,1,.34.62.51.51,0,0,1-.63.33.15.15,0,0,0-.13,0,2.66,2.66,0,0,0-.42,1.57A.51.51,0,0,1,191.92,49.47Z"
    />
    <path fill={props.fill || 'currentColor'} d="M149.27,28.81h2.52S150.42,32.27,149.27,28.81Z" />
    <path
      fill={props.fill || 'currentColor'}
      d="M50.26,91.65c-.65,0-16,0-16-3.07s15.31-3.08,16-3.08,16,0,16,3.08S50.91,91.65,50.26,91.65Zm0-5.15c-9.52,0-14.72,1.34-15,2.09.24.72,5.44,2.06,15,2.06s14.67-1.32,15-2.07C64.93,87.83,59.73,86.5,50.26,86.5Zm15,2.09h0Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M49.4,114.85c-11.25,0-28-1.28-28-4.79,0-2.7,9.73-3.88,15.53-4.34a.5.5,0,0,1,.54.46.5.5,0,0,1-.46.54c-10.64.85-14.61,2.49-14.61,3.34,0,1.11,7.77,3.79,27,3.79s27-2.68,27-3.79c0-.56-2.36-2.22-13.14-3.21a.5.5,0,0,1,.09-1c6.41.59,14.05,1.8,14.05,4.21C77.4,113.57,60.66,114.85,49.4,114.85Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M51.39,112.87c-.71,0-1.15,0-1.18,0s-13.3,1.09-14.19-8.74c-.85-9.33-1.71-15.38-1.72-15.44a.51.51,0,0,1,.43-.57.5.5,0,0,1,.56.43c0,.06.87,6.13,1.72,15.49.34,3.68,2.63,6.12,6.81,7.24a21,21,0,0,0,6.4.59,19.4,19.4,0,0,0,6.16-.59c4.06-1.13,6.46-3.58,7.14-7.29a96.22,96.22,0,0,0,1.71-15.37.5.5,0,0,1,.5-.5h0a.5.5,0,0,1,.5.5,97.12,97.12,0,0,1-1.73,15.55C63.05,112.11,54.55,112.87,51.39,112.87Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M48.59,111.42c-2.81,0-4.45-.56-5-1.71a2.06,2.06,0,0,1-.16-1.36,3.58,3.58,0,0,1-1.95-.42,4.1,4.1,0,0,1-2.2-5.19,2.86,2.86,0,0,1-2.37-3c0-1.94.89-2.66,1.54-2.93a4.09,4.09,0,0,1,0-2.84,2.79,2.79,0,0,1,1.89-1.55,2.55,2.55,0,0,1,2.2.26,4,4,0,0,1,1.34,1.65A3.25,3.25,0,0,1,44.69,93a3,3,0,0,1,2.78-.54A3.41,3.41,0,0,1,50,94.14a4,4,0,0,1,.18,2.79l3.71-.62c.2-.43,1-1.24,3.9-3,1.54-.92,2.79-1.13,3.71-.61a3.21,3.21,0,0,1,1.33,2.6c.35.54,1.07,1.85.64,2.66a1.39,1.39,0,0,1-2.26,0c-.43-.8.28-2.09.63-2.64A2.29,2.29,0,0,0,61,93.56c-.58-.33-1.51-.12-2.71.6a25.21,25.21,0,0,0-2.88,1.94,6.31,6.31,0,0,1,3.07,2.56c.78,1.56.88,2.88.31,3.92a4.1,4.1,0,0,1-2.53,1.81,4.46,4.46,0,0,1,.54,3,3.42,3.42,0,0,1-2,2.26,2.55,2.55,0,0,1-2.82-.53,2.26,2.26,0,0,1-.33,1.07C51.13,111,50.1,111.42,48.59,111.42ZM44.32,107a.51.51,0,0,1,.34.14.5.5,0,0,1,.06.66s-.6.83-.27,1.49c.22.42,1,1.15,4.14,1.15.79,0,1.8-.14,2.19-.76a2.32,2.32,0,0,0-.11-2,.5.5,0,0,1,.87-.5s1.52,2.19,2.79,1.55a2.49,2.49,0,0,0,1.46-1.6,4.09,4.09,0,0,0-.77-2.81.5.5,0,0,1-.06-.5.49.49,0,0,1,.4-.29,3.68,3.68,0,0,0,2.52-1.45,3.26,3.26,0,0,0-.33-3,5.74,5.74,0,0,0-2.79-2.19.5.5,0,0,1-.38.31l-4.89.82a.51.51,0,0,1-.48-.18.5.5,0,0,1-.06-.51,3.81,3.81,0,0,0,.11-2.77,2.43,2.43,0,0,0-1.81-1.19,2,2,0,0,0-1.89.31c-1,.88-.83,3-.83,3a.51.51,0,0,1-.44.55.5.5,0,0,1-.55-.43c-.1-.69-.57-2.62-1.6-3.28a1.58,1.58,0,0,0-1.37-.14,1.86,1.86,0,0,0-1.27,1A3.71,3.71,0,0,0,39.54,97a.48.48,0,0,1,0,.48.51.51,0,0,1-.43.24c-.11,0-1.21.1-1.21,2.08A2,2,0,0,0,40,101.82a.54.54,0,0,1,.43.24.5.5,0,0,1,0,.49A3.26,3.26,0,0,0,41.89,107c1.36.68,2.07.1,2.1.08A.46.46,0,0,1,44.32,107Zm18-10.51a1.64,1.64,0,0,0-.24,1s.07,0,.24,0a.43.43,0,0,0,.25,0A1.76,1.76,0,0,0,62.29,96.47Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M58,103.68a.5.5,0,0,1-.46-.32.48.48,0,0,1,.14-.55c.43-.38,2.67-2.27,4.12-2a1.27,1.27,0,0,1,.93.65,1,1,0,0,1,0,1C62.12,103.6,59,103.68,58,103.68Zm3.52-1.91a4.35,4.35,0,0,0-1.91.83,4.26,4.26,0,0,0,2.29-.61c-.09-.15-.15-.19-.26-.21Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M62.29,109.7l-.14,0a.5.5,0,0,1-.34-.63c1.43-4.77,4.22-6.28,6.46-7.5,1.94-1,3.47-1.89,3.84-4.45.31-2.22-.27-3.59-1.74-4.06a3.39,3.39,0,0,0-3.93,1.59,2.11,2.11,0,0,0-.24,1.69,4,4,0,0,0,1.83,2,.51.51,0,0,1,.21.68.5.5,0,0,1-.67.21,5,5,0,0,1-2.32-2.58,3.11,3.11,0,0,1,.31-2.47,4.41,4.41,0,0,1,5.12-2.07c1.37.45,2.91,1.71,2.42,5.16-.44,3.06-2.34,4.09-4.36,5.19-2.18,1.19-4.66,2.53-6,6.91A.5.5,0,0,1,62.29,109.7Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M47.48,114.73c-3.06,0-5-.54-5.9-1.59a1.72,1.72,0,0,1-.39-1.5.5.5,0,1,1,1,.26.82.82,0,0,0,.24.64c.49.57,2.12,1.48,7.41,1.1,6.87-.49,7.64-1.59,7.71-1.74a.5.5,0,0,1,.26-.57.49.49,0,0,1,.67.22.9.9,0,0,1-.07.86c-.64,1.12-3.5,1.87-8.5,2.22C49,114.69,48.22,114.73,47.48,114.73ZM57.55,112Zm0,0Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M49.4,117c-9.18,0-14.24-.77-15-2.29a.92.92,0,0,1,0-1,.5.5,0,0,1,.69-.12.49.49,0,0,1,.17.62c.1.18,1.3,1.75,14.15,1.75,14.25,0,15.74-1.86,15.86-2.06a.45.45,0,0,1,.49-.4.5.5,0,0,1,.5.5C66.24,114.74,65,117,49.4,117Zm-14.19-2.67Zm0,0Zm30-.29h0Zm0,0Zm0-.1Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M45.81,105.46c-2.41,0-4.45-2.54-4.55-2.66a.49.49,0,0,1,.52-.79c.09,0,2.29.49,4.62-3.38a.49.49,0,0,1,.68-.17.48.48,0,0,1,.17.68c-1.65,2.76-3.35,3.59-4.47,3.82.89.81,2.41,1.9,3.87,1.35s2.28-1.24,2.53-2a1.86,1.86,0,0,0-.19-1.52.5.5,0,0,1,.83-.55,2.9,2.9,0,0,1,.32,2.36c-.35,1.12-1.4,2-3.14,2.68A3.17,3.17,0,0,1,45.81,105.46Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M58.35,97a.84.84,0,0,1-.58-.22c-.63-.58-.14-2,.2-2.87a.5.5,0,0,1,.31-.29.52.52,0,0,1,.42.06c.23.15,1.37.94,1.32,1.91a1.45,1.45,0,0,1-.94,1.21A1.75,1.75,0,0,1,58.35,97Zm.13-.91h0Zm.18-1.15a3.8,3.8,0,0,0-.2,1l.17-.08c.11,0,.38-.2.39-.36A1,1,0,0,0,58.66,94.9Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M42.53,101.11a.47.47,0,0,1-.35-.15l-1.72-1.72a.5.5,0,0,1,0-.71.51.51,0,0,1,.71,0l1.71,1.72a.5.5,0,0,1,0,.71A.47.47,0,0,1,42.53,101.11Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M44.25,100.25a.5.5,0,0,1-.5-.5V98a.5.5,0,0,1,.5-.5.5.5,0,0,1,.5.5v1.72A.51.51,0,0,1,44.25,100.25Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M81.74,93.76c-6.33,0-12.69-1.23-15.64-3.69-4.37-3.63-5.49-6.75-5.78-8.15-1.43.28-4-1.08-7.52-4-4.8-4-14.9-16.06-4.89-41.05a5.94,5.94,0,0,0,.09-5c-1.27-2.5-4.46-3.33-4.49-3.34a.49.49,0,0,1-.38-.47.48.48,0,0,1,.36-.49L53,24.64a.5.5,0,0,1,.56.21c4.85,7.4,3.11,15.84,1.85,22-.77,3.74-1.44,7-.32,8.49a3.13,3.13,0,0,0,2.38,1.1c4,.4,6.69-1.39,8.09-5.31a8.22,8.22,0,0,0-.63-6.65,22.31,22.31,0,0,1-2.31-7.41c-2-14,13.16-17.89,16.08-18.51-.23-1.83-.85-8.3,1.54-10.65a3.18,3.18,0,0,1,2.9-.84,3.9,3.9,0,0,1,2.79,1.85c1.82,2.94.58,8.41.13,10.13a22.79,22.79,0,0,1,10.09,4.58,14.49,14.49,0,0,1,3.15,17.82c-2.09,4.06-3.33,9.89,2.58,14.62,4,3.22,6,8,5.65,13.72a25.12,25.12,0,0,1-1.7,7.76c-.11.3-3,7.75-8.53,12.53C94.41,92.53,88.08,93.76,81.74,93.76Zm-21-13.14h.13a.52.52,0,0,1,.37.51s.11,3.66,5.52,8.16,24.52,4.61,29.89,0,8.22-12.07,8.25-12.14,5.44-13.06-3.64-20.32c-6.79-5.43-4.7-12.27-2.85-15.87a13.49,13.49,0,0,0-2.91-16.6,22.28,22.28,0,0,0-10.17-4.47.49.49,0,0,1-.33-.23.51.51,0,0,1,0-.4c0-.07,2-6.78.13-9.84A2.9,2.9,0,0,0,83,8.05a2.19,2.19,0,0,0-2,.56c-2,1.94-1.53,8.07-1.19,10.26a.5.5,0,0,1-.41.57,27.24,27.24,0,0,0-8.72,3.32c-5.51,3.37-7.87,8.13-7,14.16A21.19,21.19,0,0,0,65.79,44a9.22,9.22,0,0,1,.69,7.46c-1.08,3-3.55,6.54-9.13,6a4.09,4.09,0,0,1-3.08-1.51c-1.39-1.88-.72-5.15.14-9.28,1.28-6.23,2.87-14-1.5-20.93L45.2,28a7.76,7.76,0,0,1,3.69,3.32,6.87,6.87,0,0,1,0,5.85c-9.77,24.39,0,36,4.61,39.91,5.65,4.73,6.84,3.78,6.85,3.77A.45.45,0,0,1,60.72,80.62Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M61.2,68.82h-.14C50.42,65.9,49.35,59.18,49.31,58.9a.51.51,0,0,1,.42-.57.51.51,0,0,1,.57.43c0,.26,1.06,6.36,11,9.08a.51.51,0,0,1,.35.62A.49.49,0,0,1,61.2,68.82Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M98.67,27.93H65a.5.5,0,0,1-.5-.5.51.51,0,0,1,.5-.5H98.67a.5.5,0,0,1,.5.5A.5.5,0,0,1,98.67,27.93Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M113.42,81.17h0a7.6,7.6,0,0,1-5-2.15,8.84,8.84,0,0,1-2.24-6.58c0-3.55,1.08-4.59,3.34-6.85a64.24,64.24,0,0,0,8.22-9.9c6.44-9.3,5.34-16.9,4.1-20.6-1.51-4.54-4.45-7.32-6.7-8.07a11.26,11.26,0,0,0-9.52,1.27c-2,1.32-4.48,3.92-4.92,8.91a7.83,7.83,0,0,0,1.54,5.93,7.8,7.8,0,0,0,4.83,2.51.5.5,0,0,1-.09,1,8.78,8.78,0,0,1-5.51-2.86,8.78,8.78,0,0,1-1.76-6.67c.47-5.38,3.17-8.22,5.36-9.65a12.25,12.25,0,0,1,10.39-1.39c3,1,5.9,4.43,7.33,8.71,1.29,3.87,2.46,11.82-4.23,21.48a64.6,64.6,0,0,1-8.33,10c-2.25,2.26-3,3-3,6.16a7.9,7.9,0,0,0,1.94,5.86,6.65,6.65,0,0,0,4.28,1.86.5.5,0,0,1,0,1Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M85.55,87.88a4.27,4.27,0,0,1-.71-.07,4.5,4.5,0,0,1-3.22-2.08,5.66,5.66,0,0,1-.21-4.22.5.5,0,1,1,.94.34,4.72,4.72,0,0,0,.15,3.39A3.51,3.51,0,0,0,85,86.83c3.55.7,7-6.39,8-9.18a.5.5,0,0,1,.64-.3A.49.49,0,0,1,94,78C93.8,78.41,90.3,87.88,85.55,87.88Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M92.46,85.79a2.58,2.58,0,0,1-2.25-1,.5.5,0,0,1,.84-.54s1,1.3,4.11-.26a1.5,1.5,0,0,0,1-1.14c.2-1.55-2.07-3.91-3-4.68a.53.53,0,0,1-.13-.58.5.5,0,0,1,.52-.3c.08,0,7.24.85,9-3.48a3.44,3.44,0,0,0,.1-3.11,3.4,3.4,0,0,0-2.39-1.26.5.5,0,0,1-.47-.54.52.52,0,0,1,.53-.46,4.37,4.37,0,0,1,3.17,1.72,4.31,4.31,0,0,1,0,4c-1.52,3.81-6.28,4.23-8.64,4.19,1,1.11,2.51,3,2.3,4.6a2.44,2.44,0,0,1-1.5,1.91A7.11,7.11,0,0,1,92.46,85.79Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M85.9,70.5a.47.47,0,0,1-.17,0c-.31-.11-7.51-2.73-8.2-7.06-.31-2,.8-4,3.3-5.93,2.74-2.11,5.1-2.72,7-1.83,4.1,1.9,4.52,10,4.54,10.33a.5.5,0,0,1-.48.52A.49.49,0,0,1,91.4,66c0-.08-.41-7.82-4-9.46-1.56-.73-3.58-.15-6,1.71-2.19,1.69-3.17,3.37-2.92,5,.6,3.76,7.48,6.25,7.54,6.28a.51.51,0,0,1,.31.64A.52.52,0,0,1,85.9,70.5Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M88.7,78.13a5,5,0,0,1-2.27-.54c-3.81-1.91-5-8.23-5-8.5a.5.5,0,0,1,1-.28A5,5,0,0,0,85,71.29a6.77,6.77,0,0,0,7.62-3.8,5.14,5.14,0,0,0-.48-3.66.5.5,0,0,1,.14-.66.5.5,0,0,1,.67.08c.16.19,4.06,4.7,3.2,9.06-.42,2.1-1.86,3.79-4.3,5A7,7,0,0,1,88.7,78.13Zm-5.65-6.81c.66,1.9,1.86,4.39,3.83,5.38a4.84,4.84,0,0,0,4.48-.28c2.15-1.07,3.41-2.52,3.77-4.3a9,9,0,0,0-1.39-5.9,5,5,0,0,1-.2,1.54,6.47,6.47,0,0,1-3.63,3.86,6.58,6.58,0,0,1-5.31.61A5.29,5.29,0,0,1,83.05,71.32Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M77.34,91.61a.5.5,0,0,1-.44-.25,7.24,7.24,0,0,1-.53-5.48c.65-1.83,2.22-3.24,4.65-4.19a.51.51,0,0,1,.37.94c-2.16.83-3.53,2-4.08,3.57a6.32,6.32,0,0,0,.46,4.67.5.5,0,0,1-.19.68A.5.5,0,0,1,77.34,91.61Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M82.84,82.23a8.65,8.65,0,0,1-1,0,10.15,10.15,0,0,1-7.55-3.93,4.72,4.72,0,0,1-.86-4.6c1.45-3.66,8.09-5.08,8.37-5.14a.5.5,0,1,1,.21,1c-.06,0-6.4,1.37-7.65,4.53a3.73,3.73,0,0,0,.75,3.65c1.75,2.52,4.68,3.3,6.83,3.52,3.67.37,6.56-1.63,8.88-3.22a21.63,21.63,0,0,1,2.45-1.55c5.07-2.53,6.38-5.89,6.6-8.26a8.08,8.08,0,0,0-2.88-7,2.3,2.3,0,0,0-2.16-.57c-1.36.48-2.22,2.74-2.44,3.57a.48.48,0,0,1-.49.37.5.5,0,0,1-.48-.39c-.1-.45-2.43-11.06.77-14.19a3.19,3.19,0,0,1,3-1.15c1.79.5,2.52,3.17,2.55,3.28a.49.49,0,0,1-.35.61.48.48,0,0,1-.61-.35c-.17-.62-.83-2.29-1.86-2.58-.59-.15-1.28.15-2.06.91-2,1.92-1.43,8-.83,11.54a4.88,4.88,0,0,1,2.44-2.56,3.24,3.24,0,0,1,3.09.71,9.11,9.11,0,0,1,3.27,7.92C100.52,72,98,75.18,93.7,77.32a20.11,20.11,0,0,0-2.32,1.47C89.13,80.34,86.38,82.23,82.84,82.23Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M81.9,69.5c-4.55,0-7.1-2-9.15-3.65s-3.33-2.64-5.69-1.8c-3.46,1.23-2.58,4-2.54,4.11a.5.5,0,0,1-.95.33s-1.26-3.81,3.16-5.39c2.88-1,4.71.43,6.64,2S77.7,68.5,81.9,68.5a.5.5,0,0,1,0,1Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M100.13,86.87a.51.51,0,0,1-.39-.19c-1.77-2.15-2.4-4.09-1.88-5.77.78-2.47,3.77-3.35,3.9-3.39a.5.5,0,0,1,.62.34.51.51,0,0,1-.35.62s-2.61.78-3.21,2.74c-.42,1.33.15,3,1.69,4.83a.49.49,0,0,1-.06.7A.51.51,0,0,1,100.13,86.87Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M71.81,75.9a.5.5,0,0,1-.44-.26,4.93,4.93,0,0,1-.44-4.38c1.12-2.66,4.68-3.7,4.83-3.74a.5.5,0,1,1,.27,1s-3.25,1-4.18,3.17a4,4,0,0,0,.4,3.52.49.49,0,0,1-.2.67A.48.48,0,0,1,71.81,75.9Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M89.68,70.72a.49.49,0,0,1-.42-.23l-4.75-7.6a.5.5,0,0,1,.16-.69.51.51,0,0,1,.69.16L90.11,70a.51.51,0,0,1-.16.69A.52.52,0,0,1,89.68,70.72Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M88.73,70.72a.49.49,0,0,1-.3-.1l-3.79-2.85a.49.49,0,0,1-.1-.7.49.49,0,0,1,.69-.1L89,69.82a.5.5,0,0,1,.1.7A.51.51,0,0,1,88.73,70.72Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M89.68,70.72a.5.5,0,0,1-.5-.5V64.53a.5.5,0,0,1,.5-.5.5.5,0,0,1,.5.5v5.69A.5.5,0,0,1,89.68,70.72Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M86.9,48a3,3,0,0,1-2.16-.81c-1.44-1.37-1.38-4-1.3-5.11-1.33-.38-5.08-1.58-5.82-3.51a2,2,0,0,1,.25-1.89,2.78,2.78,0,0,1,2.25-1.49,2.56,2.56,0,0,1,1.55.61c.16-1.3.65-3.32,2.09-4a2,2,0,0,1,1.71-.1,3.59,3.59,0,0,1,1.63,1.78c1-.43,3.5-1.29,5.47-.59a3.63,3.63,0,0,1,2.14,2c1.42,3.15-.94,5.91-2.59,7.1a5.13,5.13,0,0,1-4.24,5.94A4.9,4.9,0,0,1,86.9,48ZM80.12,36.22h0c-.47,0-1,.35-1.41,1.05a1,1,0,0,0-.15,1c.57,1.47,4.2,2.67,5.56,3a.5.5,0,0,1,.37.56s-.46,3.35,1,4.68a2.4,2.4,0,0,0,2.26.45c4.2-.84,3.44-4.93,3.4-5.11a.49.49,0,0,1,.22-.51c.16-.11,4-2.67,2.49-6a2.66,2.66,0,0,0-1.57-1.48c-2.1-.74-5.14.75-5.17.77a.51.51,0,0,1-.42,0,.49.49,0,0,1-.28-.3,3,3,0,0,0-1.27-1.68,1,1,0,0,0-.88.07c-1.33.66-1.62,3.33-1.62,4.3a.51.51,0,0,1-.36.48.51.51,0,0,1-.56-.2A2.32,2.32,0,0,0,80.12,36.22Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M87.14,43.09c-1.18,0-2.23-.28-2.65-1.13-1-2.06,1.59-7.44,1.9-8a.5.5,0,0,1,.67-.23.51.51,0,0,1,.22.67c-1.06,2.12-2.51,5.93-1.9,7.15.52,1.05,3.85.48,6-.25a.5.5,0,1,1,.32,1A16.38,16.38,0,0,1,87.14,43.09Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M95.28,34.64h0a.48.48,0,0,1-.42-.29c-.41-.85-1.27-2.92-.59-3.75a1.05,1.05,0,0,1,1.16-.3,1.45,1.45,0,0,1,1,.86c.49,1.19-.62,3-.75,3.24A.49.49,0,0,1,95.28,34.64ZM95,31.25h0a3.65,3.65,0,0,0,.34,1.68,2.26,2.26,0,0,0,.21-1.39.47.47,0,0,0-.37-.27A.5.5,0,0,0,95,31.25Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M98.38,37a9.47,9.47,0,0,1-2.45-.43.48.48,0,0,1-.35-.48.5.5,0,0,1,.36-.48c1.07-.3,3-.71,3.59-.15a.75.75,0,0,1,.26.69.86.86,0,0,1-.4.63A1.89,1.89,0,0,1,98.38,37Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M84,51.13a.51.51,0,0,1-.35-.13c-2-1.83-2.84-3.68-2.66-5.5a6.18,6.18,0,0,1,2.74-4.19.51.51,0,0,1,.69.16.5.5,0,0,1-.16.69A5.22,5.22,0,0,0,82,45.61c-.14,1.49.65,3.06,2.36,4.66a.5.5,0,0,1-.34.86Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M80.19,46h-.11c-1-.21-5.75-1.34-5.47-3.46,0-.36,0-1.82.89-2.28.71-.34,1.63,0,2.82,1.09C80,42.84,80.8,44.08,80.82,45a1.16,1.16,0,0,1-.28.85A.5.5,0,0,1,80.19,46Zm-4.12-4.87-.12,0c-.27.13-.37.89-.34,1.36a.57.57,0,0,1,0,.13c-.15.79,2.22,1.77,4.21,2.27-.08-.39-.45-1.28-2.17-2.83C76.86,41.4,76.34,41.16,76.07,41.16Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M80.32,34.64c-.82,0-5-.08-5.79-1.62a1.45,1.45,0,0,1,.15-1.56,1.79,1.79,0,0,1,1.49-.9c1.93-.08,4.29,2.93,4.55,3.28a.5.5,0,0,1-.4.8Zm-4.08-3.09h0a.8.8,0,0,0-.69.44c-.22.35-.14.49-.12.55.33.6,2.19.94,3.82,1.05C78.3,32.6,77.08,31.55,76.24,31.55Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M75.32,60.64c-.71,0-4.29-.07-5-1.42a1.32,1.32,0,0,1,.13-1.41A1.66,1.66,0,0,1,71.77,57c1.7-.06,3.72,2.54,4,2.84a.5.5,0,0,1-.4.8ZM71.83,58h0a.65.65,0,0,0-.53.35c-.16.26-.11.36-.09.39.24.44,1.59.75,3,.86C73.45,58.8,72.49,58,71.83,58Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M80.1,55.85a.53.53,0,0,1-.27-.08c-.69-.43-4.17-2.68-4.07-4.43a1.47,1.47,0,0,1,1-1.25,1.82,1.82,0,0,1,1.74,0c1.69,1,2.09,4.76,2.13,5.19a.51.51,0,0,1-.49.55Zm-2.5-5a1,1,0,0,0-.45.12c-.38.17-.39.34-.39.4,0,.68,1.36,2,2.68,2.9C79.19,53,78.69,51.39,78,51A.67.67,0,0,0,77.6,50.88Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M86.22,53.66a.43.43,0,0,1-.19,0,.52.52,0,0,1-.31-.43c0-.87-.12-3.75,1-4.45a1.19,1.19,0,0,1,1.29,0,1.49,1.49,0,0,1,.8,1.16c.14,1.5-2,3.39-2.27,3.6A.5.5,0,0,1,86.22,53.66Zm1.09-4.09,0,0c-.32.19-.51,1.26-.55,2.39A3.42,3.42,0,0,0,87.82,50a.48.48,0,0,0-.29-.39A.49.49,0,0,0,87.31,49.57Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M84.88,15.2a4.19,4.19,0,0,1-2.48-.77C80,12.74,79.7,8.92,79.69,8.75a.49.49,0,0,1,.46-.53.5.5,0,0,1,.54.46S81,12.2,83,13.61a3.68,3.68,0,0,0,3.21.4.5.5,0,0,1,.26,1A5.94,5.94,0,0,1,84.88,15.2Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M84.9,41.5a.56.56,0,0,1-.32-.11.49.49,0,0,1-.07-.7l4-5a.49.49,0,0,1,.7-.08.5.5,0,0,1,.08.7l-4,5A.49.49,0,0,1,84.9,41.5Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M84.9,41.5a.5.5,0,0,1-.25-.93l7-4a.49.49,0,1,1,.49.86l-7,4A.42.42,0,0,1,84.9,41.5Z"
    />
    <path
      fill={props.fill || 'currentColor'}
      d="M84.9,41.5a.5.5,0,0,1-.5-.42.5.5,0,0,1,.41-.57l6-1a.5.5,0,0,1,.58.41.49.49,0,0,1-.41.57l-6,1Z"
    />
  </SvgIcon>
);

export default Crafts;
