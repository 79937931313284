import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconCopyOutline: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M9 13v-1c0-1.654 1.346-3 3-3h1V5.667A.667.667 0 0 0 12.333 5H5.667A.667.667 0 0 0 5 5.667v6.666c0 .368.299.667.667.667H9Zm0 2H5.667A2.67 2.67 0 0 1 3 12.333V5.667A2.67 2.67 0 0 1 5.667 3h6.666A2.67 2.67 0 0 1 15 5.667V9h3c1.654 0 3 1.346 3 3v6c0 1.654-1.346 3-3 3h-6c-1.654 0-3-1.346-3-3v-3Zm2-3c0-.551.449-1 1-1h6c.552 0 1 .449 1 1v6c0 .551-.448 1-1 1h-6c-.551 0-1-.449-1-1v-6Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconCopyOutline;
