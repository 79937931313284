/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useMemo } from 'react';

import { connect } from 'react-redux';

import { IRootState } from '../../../../reducers';
import { ICommonResponse } from '../../../../api/types';
import PdfCommonDataEntityContainer from '../common';
import { PdfCommonDataEntity } from '../../../../components/pdfStudent/DataEntity';
import { widgetPdfIcons } from '../../../../icons';
import { cultureVisitingsSelector } from '../../../../selectors';
import { IEntity } from '../../../../types';

import '../index.scss';
import './index.scss';
import { useDataLoad } from 'hooks';
import { getDataLoadRequests } from 'utils';

interface IPdfCultureInstitutionsVisitingProps {
  studentVisitingEvents: ICommonResponse<IEntity>;
}

const PdfCultureInstitutionsVisiting: React.FC<IPdfCultureInstitutionsVisitingProps> = ({
  studentVisitingEvents
}) => {
  useDataLoad({ shouldLoad: true, requests: getDataLoadRequests().cultureOfflineVisitings });

  const filteredVisitingEventsYear = useMemo(() => (studentVisitingEvents.content && studentVisitingEvents.content?.length > 0
    ? studentVisitingEvents.content?.reduce((prevItem, nextItem) => (prevItem.year > nextItem.year ? prevItem : nextItem)).year
    : undefined),
  [studentVisitingEvents]);

  return (
    <PdfCommonDataEntityContainer
      title="Посещение культурных учреждений"
      icon={widgetPdfIcons.pdfIconCultureOfflineEvents}
    >
      {studentVisitingEvents.content
        .map((olympiad: IEntity) => (
          <PdfCommonDataEntity key={olympiad.id || olympiad.recordId} entity={olympiad} year={filteredVisitingEventsYear} />
        ))}
    </PdfCommonDataEntityContainer>
  );
};

export default connect(
  (state: IRootState) => ({
    studentVisitingEvents: cultureVisitingsSelector(state)
  })
)(PdfCultureInstitutionsVisiting);
