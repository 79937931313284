import {
  THANKS_TEACHER_DRAWER_SET_FORM_DATA,
  THANKS_TEACHER_DRAWER_SET_OPEN,
  THANKS_TEACHER_DRAWER_SET_SUBMIT_MODAL_OPEN,
  thanksTeacherDrawerActions,
} from 'actions';
import { IThanksFormData } from 'components/common/PortfolioThanksForm';
import { IReducers } from 'reducers';
import { connectReducers } from 'utils';

export type ThanksTeacherDrawerState = {
  isOpen: boolean;
  widgetLabel: string | null;
  formData: IThanksFormData | undefined;
  isSubmitModalOpen: boolean;
};

type SetFormDataAction = { payload: IThanksFormData | undefined };
type SetSubmitModalOpenAction = { payload: boolean };

const initialState: ThanksTeacherDrawerState = {
  isOpen: false,
  widgetLabel: null,
  formData: undefined,
  isSubmitModalOpen: false,
};

const thanksTeacherDrawer: IReducers<ThanksTeacherDrawerState> = {
  [THANKS_TEACHER_DRAWER_SET_OPEN]: (state, action: ReturnType<typeof thanksTeacherDrawerActions.setOpen>) => {
    const { open, widgetLabel } = action.payload;

    return {
      ...state,
      isOpen: open,
      isSubmitModalOpen: open ? false : state.isSubmitModalOpen,
      widgetLabel,
    };
  },
  [THANKS_TEACHER_DRAWER_SET_FORM_DATA]: (state, { payload }: SetFormDataAction) => {
    return {
      ...state,
      formData: payload,
    };
  },
  [THANKS_TEACHER_DRAWER_SET_SUBMIT_MODAL_OPEN]: (state, { payload }: SetSubmitModalOpenAction) => {
    return {
      ...state,
      isSubmitModalOpen: payload,
    };
  },
};

export default connectReducers(initialState, thanksTeacherDrawer);
