import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root: SxStyles = (theme) => {
  return {
    padding: '16px',
    overflowY: 'auto',

    [theme.breakpoints.down('commonSm')]: {
      overflowY: 'initial',
    },
  };
};

export const helper: SxStyles = {
  marginTop: '4px',
  color: NeutralColors.light_neutrals_600,
};

export const settingsList: SxStyles = {
  marginTop: '16px',

  '& > * ~ *': {
    marginTop: '12px',
  },
};
