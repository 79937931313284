import React from 'react';

import {
  ICommonResponse,
  IDiagnosisResponse,
  IDictionaryItem,
  IIndependentDiagnosticLearningGroup,
  IIndependentDiagnosticsGeneralRatingResponse,
} from 'api/types';
import { IDiagnosticFilters } from 'types';

// TODO хранить данные из редакса
interface IDiagnosticContext {
  filters: IDiagnosticFilters;
  setSingleFilter: <Key extends keyof IDiagnosticFilters>(key: Key, value: IDiagnosticFilters[Key]) => void;
  selfDiagnostics: IDiagnosisResponse | null;
  independentDiagnostics: ICommonResponse<IIndependentDiagnosticLearningGroup>;

  independentDiagnosticPeriods: IDictionaryItem[];
  selfDiagnosticSubjects: IDictionaryItem[];

  generalRating: IIndependentDiagnosticsGeneralRatingResponse;
}

const DiagnosticContext = React.createContext({} as IDiagnosticContext);

export default DiagnosticContext;
