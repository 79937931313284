import { ICommonResponse } from 'api/types';
import { EntityType, SectionCodes, SourceCode } from 'const';
import { cardIllustrations } from 'images';
import { SectionRefState } from 'reducers/reference';
import { StudentAffilationsState } from 'reducers/student/affilations';
import { StudentEmploymentsState } from 'reducers/student/employments';
import { StudentEventsState } from 'reducers/student/events';
import { StudentRewardsState } from 'reducers/student/rewards';
import { mapCreationContestToEntity, mapRewardEntity } from 'selectors/entityMappers';
import { IEntity, IEntityFeature, IModifiedReward } from 'types';
import { flagsMap, getCreationEntityBackgroundImage, getDateRange, getEntityGeneralInfo, getYearFromDate } from 'utils';

const getClubFeature = (typeCode: number) => {
  if (typeCode === SectionCodes.creationCollective) return 'коллектив';
  return 'коллектив';
};

export const mapCreationClubsResponse = (affilations: StudentAffilationsState): ICommonResponse<IEntity> => {
  const creationAffilations = affilations.content.filter((affilation) => {
    return affilation.category?.code === SectionCodes.creation || affilation.categoryCode === SectionCodes.creation;
  });

  const clubs = creationAffilations.map((affilation): IEntity => {
    const { id, recordId, name, source, affilationLevel, type, startDate, creationKind, endDate } = affilation;

    const eventTypes = creationKind ? [creationKind.value] : undefined;

    const eventFeatures: IEntityFeature[] = [{ text: getClubFeature(type?.code), isColored: true }];
    if (affilationLevel) eventFeatures.push({ text: affilationLevel.value });

    return {
      id,
      recordId,
      name,
      types: eventTypes,
      date: getDateRange(startDate, endDate),
      year: getYearFromDate(endDate || startDate),
      features: eventFeatures,
      backgroundImage: getCreationEntityBackgroundImage(creationKind?.value),
      formData: affilation,
      cardData: {
        name,
        generalInfo: getEntityGeneralInfo(affilation),
        files: affilation.fileReferences,
        linkedObjects: affilation.linkedObjects,
        formData: affilation,
        illustrationFallback: cardIllustrations.creationClub,
      },
      entityProps: {
        type: EntityType.AFFILATION,
        flags: flagsMap({ approved: source.code !== SourceCode.student && source.code !== SourceCode.parent }),
      },
    };
  });

  return {
    ...affilations,
    content: clubs,
  };
};

export const mapCreationContestsResponse = (
  events: StudentEventsState,
  rewards: StudentRewardsState,
  sectionRef: SectionRefState,
): ICommonResponse<IEntity> => {
  const boundRewards = rewards.content.filter((reward) => reward.entityId);

  const creationEvents = events.content
    .filter((event) => event.categoryCode == SectionCodes.creation)
    .map((event): IEntity => {
      const { id, recordId } = event;

      const eventReward = boundRewards?.find((reward) => reward.entityId == id || reward.entityId == recordId);
      return mapCreationContestToEntity(sectionRef.content, event, eventReward);
    });

  return {
    ...events,
    content: creationEvents,
  };
};

export const mapCreationOccupationsResponse = (employments: StudentEmploymentsState): ICommonResponse<IEntity> => {
  const occupations = employments.content
    .filter((employment) => employment.categoryCode === SectionCodes.creation)
    .map((employment): IEntity => {
      const {
        id,
        recordId,
        subcategory,
        name,
        source,
        startDate,
        disciplines,
        achievementActivityFormat,
        type,
        location,
        endDate,
        creationKind,
      } = employment;

      const occupationTypes: string[] = [];
      if (subcategory) occupationTypes.push(subcategory.value);
      if (disciplines) {
        occupationTypes.push(...disciplines.map((discipline) => discipline.value));
      }

      const occupationFeatures: IEntityFeature[] = [];
      if (type) occupationFeatures.push({ text: type.value, isColored: true });
      if (achievementActivityFormat) occupationFeatures.push({ text: achievementActivityFormat.value });

      return {
        id,
        recordId,
        name,
        types: occupationTypes,
        date: getDateRange(startDate, endDate),
        year: getYearFromDate(endDate || startDate),
        features: occupationFeatures,
        backgroundImage: getCreationEntityBackgroundImage(creationKind?.value),
        place: location,
        formData: employment,
        cardData: {
          name,
          generalInfo: getEntityGeneralInfo(employment),
          files: employment.fileReferences,
          linkedObjects: employment.linkedObjects,
          formData: employment,
          illustrationFallback: cardIllustrations.creationSection,
        },
        entityProps: {
          type: EntityType.EMPLOYMENT,
          flags: flagsMap({ approved: source.code !== SourceCode.student && source.code !== SourceCode.parent }),
        },
      };
    });

  return {
    ...employments,
    content: occupations,
  };
};

export const mapCreationRewardsResponse = (
  events: StudentEventsState,
  rewards: StudentRewardsState,
  sectionRef: SectionRefState,
): ICommonResponse<IModifiedReward> => {
  const creationRewards = rewards.content
    .filter((reward) => reward.categoryCode === SectionCodes.creation)
    .map((reward) => {
      return mapRewardEntity(reward, events.content, sectionRef.content);
    });

  return {
    ...rewards,
    content: creationRewards,
  };
};
