import { FC, ReactNode, useRef } from 'react';

import { Box, Typography } from '@mui/material';
import { Tooltip } from 'ui-kit';
import { mergeSx } from 'utils';

import * as styles from './styles';

interface ITaskCounterProps {
  icon: ReactNode;
  count: number;
  color: string;
  label: string;
}

const TaskCounter: FC<ITaskCounterProps> = ({ icon, count, color, label }) => {
  const iconRef = useRef<HTMLElement>();

  return (
    <Tooltip title={label} arrow placement="top" width="152px" PopperProps={{ anchorEl: iconRef.current }}>
      <Box className="task-counter" sx={mergeSx(styles.taskCounter, { svg: { color } })}>
        <Box height="16px" ref={iconRef}>
          {icon}
        </Box>
        <Typography variant="Paragraph MD/Bold">{count}</Typography>
      </Box>
    </Tooltip>
  );
};

export default TaskCounter;
