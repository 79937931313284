import { StudentSectionSettingTypeCodes } from 'const';
import { useAppSelector } from 'hooks';
import { visibilitySettingsSelector } from 'selectors';
import { findTargetVisibilitySetting } from 'utils/localVisibilitySettingsService';

import { collection as searchActions } from '../model/actions';
import {
  searchSportClubsSelector,
  searchSportCompetitionsSelector,
  searchSportExpeditionsSelector,
  searchSportOccupationsSelector,
  searchSportRewardsSelector,
} from '../model/selectors';

const sportCode = StudentSectionSettingTypeCodes.sport;
const rewardCode = StudentSectionSettingTypeCodes.sportReward;
const clubCode = StudentSectionSettingTypeCodes.sportClub;
const occupationCode = StudentSectionSettingTypeCodes.sportUnit;
const competitionCode = StudentSectionSettingTypeCodes.sportGame;
const expeditionCode = StudentSectionSettingTypeCodes.sportHike;

const useSearchSport = () => {
  const settings = useAppSelector(visibilitySettingsSelector);
  const { loading: isRewardsLoading, content: rewards } = useAppSelector(searchSportRewardsSelector);
  const { loading: isClubsLoading, content: clubs } = useAppSelector(searchSportClubsSelector);
  const { loading: isOccupationsLoading, content: occupations } = useAppSelector(searchSportOccupationsSelector);
  const { loading: isCompetitionsLoading, content: competitions } = useAppSelector(searchSportCompetitionsSelector);
  const { loading: isExpeditionsLoading, content: expeditions } = useAppSelector(searchSportExpeditionsSelector);

  const sportEntitiesCount = [
    rewards.length,
    clubs.length,
    occupations.length,
    competitions.length,
    expeditions.length,
  ].reduce((prev, current) => prev + current, 0);

  const hasRewards = rewards.length > 0;
  const hasClubs = clubs.length > 0;
  const hasOccupations = occupations.length > 0;
  const hasCompetitions = competitions.length > 0;
  const hasExpeditions = expeditions.length > 0;

  const isRewardsEnabled = findTargetVisibilitySetting(settings, [sportCode, rewardCode])?.computedVisibility ?? false;
  const isClubsEnabled = findTargetVisibilitySetting(settings, [sportCode, clubCode])?.computedVisibility ?? false;
  const isOccupatonsEnabled =
    findTargetVisibilitySetting(settings, [sportCode, occupationCode])?.computedVisibility ?? false;
  const isCompetitionsEnabled =
    findTargetVisibilitySetting(settings, [sportCode, competitionCode])?.computedVisibility ?? false;
  const isExpeditionsEnabled =
    findTargetVisibilitySetting(settings, [sportCode, expeditionCode])?.computedVisibility ?? false;

  const isSportRewardVisible = hasRewards && isRewardsEnabled;
  const isSportClubVisible = hasClubs && isClubsEnabled;
  const isSportOccupationVisible = hasOccupations && isOccupatonsEnabled;
  const isSportCompetitionVisible = hasCompetitions && isCompetitionsEnabled;
  const isSportExpeditionVisible = hasExpeditions && isExpeditionsEnabled;

  const isSportLoading =
    isRewardsLoading || isClubsLoading || isOccupationsLoading || isCompetitionsLoading || isExpeditionsLoading;
  const isSportVisible =
    isSportRewardVisible ||
    isSportClubVisible ||
    isSportOccupationVisible ||
    isSportCompetitionVisible ||
    isSportExpeditionVisible;

  const sportActions = [
    isRewardsEnabled && searchActions.searchSportRewardsActions,
    isClubsEnabled && searchActions.searchAffiliationsActions,
    isOccupatonsEnabled && searchActions.searchEmploymentsActions,
    isCompetitionsEnabled && searchActions.searchEventsActions,
    isExpeditionsEnabled && searchActions.searchEventsActions,
  ];

  return {
    rewards,
    clubs,
    occupations,
    competitions,
    expeditions,
    isSportRewardVisible,
    isSportClubVisible,
    isSportOccupationVisible,
    isSportCompetitionVisible,
    isSportExpeditionVisible,
    isSportLoading,
    isSportVisible,
    sportActions,
    sportEntitiesCount,
  };
};

export default useSearchSport;
