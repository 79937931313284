import { styled } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';

import StyledBaseButton from '../bases/BaseButton.styled';

const SecondaryButton = styled(StyledBaseButton)(() => {
  return {
    border: '1px solid',
    borderColor: NeutralColors.light_neutrals_400,
    color: NeutralColors.light_neutrals_900,
    backgroundColor: NeutralColors.light_neutrals_0,

    boxShadow: '0px 1px 2px rgba(41, 41, 64, 0.07)',

    '& svg': {
      color: NeutralColors.light_neutrals_600,
    },

    '&:hover': {
      backgroundColor: NeutralColors.light_neutrals_100,
      boxShadow: 'none',
    },

    '&:active': {
      backgroundColor: NeutralColors.light_neutrals_200,
      boxShadow: 'none',
    },

    '&:disabled': {
      color: NeutralColors.light_neutrals_400,
      backgroundColor: NeutralColors.light_neutrals_0,
      boxShadow: 'none',

      '& svg': {
        color: NeutralColors.light_neutrals_400,
      },
    },
  };
});

export default SecondaryButton;
