import { forwardRef } from 'react';

import { MenuList as MaterialMenuList, MenuListProps, styled } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';

const RefMenuList = forwardRef<HTMLUListElement, MenuListProps>((props, ref) => {
  return <MaterialMenuList {...props} ref={ref} />;
});

RefMenuList.displayName = 'MenuList';

const StyledMenuList = styled(RefMenuList)(() => {
  return {
    padding: '8px',
    backgroundColor: NeutralColors.light_neutrals_0,
    border: `1px solid ${NeutralColors.light_neutrals_300}`,
    boxShadow: '1px 0px 5px rgba(41, 41, 64, 0.04), 0px 3px 6px -1px rgba(41, 41, 64, 0.08)',
    borderRadius: '8px',
  };
});

const MenuList = forwardRef<HTMLUListElement, MenuListProps>((props, ref) => {
  return <StyledMenuList {...props} ref={ref} />;
});

MenuList.displayName = 'MenuList';

export default MenuList;
