import { FC, useMemo } from 'react';

import { Box, RadioGroup, RadioGroupProps, styled, Typography } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';
import { ISwitcherProps, Switcher } from 'portfolio3/ui-kit';
import { SxStyles } from 'types';

import { IRatingTab } from '../../types';
import RatingTab from './RatingTab';
import * as styles from './style';

const StyledRadioGroup = styled(RadioGroup)(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
  };
});

const SwitcherComponent: FC<RadioGroupProps> = ({ children, ...props }) => {
  return <StyledRadioGroup {...props}>{children}</StyledRadioGroup>;
};

interface IRatingTabsProps extends ISwitcherProps {
  tabs: IRatingTab[];
}

const RatingTabs: FC<IRatingTabsProps> = ({ tabs, ...props }) => {
  const switcherStyles: SxStyles = {
    border: `1px solid ${NeutralColors.light_neutrals_300}`,
    borderRadius: '12px',
    boxShadow: `0px 1px 2px ${NeutralColors.light_neutrals_800}12`,
    overflow: 'hidden',
    flexWrap: 'nowrap',
  };

  const ratingTabs = useMemo(() => {
    return tabs.map((tab) => {
      const { typography, value, ...props } = tab;
      const content = (
        <Box sx={styles.ratingTabTypographyContainer} className="rating-tab__text">
          <Typography sx={styles.ratingTabHeadingTypography} variant="Headings/H5">
            {typography.heading}
          </Typography>
          <Typography sx={styles.ratingTabTextTypography} variant="Paragraph LG/Medium">
            {typography.paragraph}
          </Typography>
        </Box>
      );
      return <RatingTab key={value} content={content} value={value} {...props} />;
    });
  }, [tabs]);

  return (
    <Switcher {...props} sx={switcherStyles} RadioGroupComponent={SwitcherComponent}>
      {ratingTabs}
    </Switcher>
  );
};

export default RatingTabs;
