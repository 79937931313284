import { FC } from 'react';

import { SxProps } from '@mui/material';
import { useVisibleNavigation } from 'hooks';
import PaddingContainer from 'portfolio3/components/common/PaddingContainer';
import { DesktopPortfolioNavigation } from 'portfolio3/components/common/PortfolioNavigation';
import { NeutralColors } from 'portfolio3/styles';

const rootStyles: SxProps = {
  zIndex: 1000,
  position: 'sticky',
  top: 0,
  paddingBlock: '12px',

  backgroundColor: NeutralColors.light_neutrals_0,
  borderBottom: '2px solid',
  borderColor: NeutralColors.light_neutrals_300,
};

const AdminViewModeHeader: FC = () => {
  const { primaryNavigation, secondaryNavigation } = useVisibleNavigation();

  if (primaryNavigation.length === 0 && secondaryNavigation.length === 0) return null;

  return (
    <PaddingContainer sx={rootStyles}>
      <DesktopPortfolioNavigation primaryNavigation={primaryNavigation} secondaryNavigation={secondaryNavigation} />
    </PaddingContainer>
  );
};

export default AdminViewModeHeader;
