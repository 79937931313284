import { SxProps } from '@mui/material';
import { SCROLL_WIDTH } from 'style/variables';
import { getScrollableContainerElement, numberToPixelsString } from 'utils';

export const studentHeaderContainerStyles: SxProps = {
  overflow: 'hidden',
  borderRadius: '0px 0px 16px 16px',
  boxShadow: '0px 3px 6px -1px rgba(41, 41, 64, 0.08)',
  filter: 'drop-shadow(1px 0px 5px rgba(41, 41, 64, 0.04))',
};

export const toggleOverflowMainContainer = (isOpenSearcher: boolean) => {
  const mainContainer = getScrollableContainerElement();
  if (mainContainer instanceof HTMLElement) {
    mainContainer.style.overflowY = isOpenSearcher ? 'hidden' : 'auto';
    mainContainer.style.paddingRight = isOpenSearcher ? numberToPixelsString(SCROLL_WIDTH) : '0px';
  }
};
