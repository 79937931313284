import { IGiaItem } from 'api/types';
import { CompulsoryGiaSubjects, DoughnutColors, GiaCategory, GiaCategoryKeys, GiaMark, GiaType } from 'const';
import { format } from 'date-fns';
import { accentColors } from 'portfolio3/styles';
import { checkPassedExamDate, getMark, getProgressValue } from 'utils';

const red = accentColors.red[100];
const yellow = accentColors.yellow[100];
const green = accentColors.green[100];

const useGiaExamCardProperties = (exam: IGiaItem) => {
  const {
    name,
    formaGia,
    approbation,
    primaryMarkValue,
    primaryMarkBasis,
    normalizedMarkValue,
    normalizedMarkBasis,
    positiveResultThreshold,
    date,
    isCredit,
  } = exam;

  // категория
  let category: GiaCategoryKeys = GiaCategoryKeys.OPTIONALLY;
  let categoryName: string | null = GiaCategory.optionally;

  if (name && CompulsoryGiaSubjects.includes(name)) {
    category = GiaCategoryKeys.COMPULSORY;
    categoryName = GiaCategory.compulsory;
  }
  if (formaGia === GiaType.oge) {
    category = GiaCategoryKeys.APPROBATION;
    categoryName = approbation ? GiaCategory.approbation : null;
  }

  // график
  let doughnutTotal: string | number | null = normalizedMarkValue;
  let doughnutValue = 0;
  let doughnutColor = DoughnutColors.GRAY;

  if (normalizedMarkBasis) {
    const normalizedMarkValueNumber = Number(normalizedMarkValue);
    const normalizedMarkBasisNumber = Number(normalizedMarkBasis);

    doughnutValue = normalizedMarkBasis
      ? getProgressValue(normalizedMarkValueNumber, normalizedMarkBasisNumber)
      : normalizedMarkValueNumber;

    if (formaGia === GiaType.ege && positiveResultThreshold) {
      if (doughnutValue < positiveResultThreshold) {
        doughnutColor = red;
      }
      if (doughnutValue >= positiveResultThreshold && doughnutValue < 90) {
        doughnutColor = yellow;
      }
      if (doughnutValue >= 90) {
        doughnutColor = green;
      }
    } else {
      doughnutColor = red;
      if (normalizedMarkValueNumber === 4) doughnutColor = yellow;
      if (normalizedMarkValueNumber === 5) doughnutColor = green;
    }
  }

  if (normalizedMarkValue === GiaMark.passed) {
    doughnutTotal = GiaMark.passedAbbreviation;
    doughnutValue = 100;
    doughnutColor = green;
  }
  if (normalizedMarkValue === GiaMark.notPassed) {
    doughnutTotal = GiaMark.notPassedAbbreviation;
    doughnutValue = 100;
    doughnutColor = red;
  }

  const doughnutData = {
    incomingData: [doughnutValue, 100 - doughnutValue],
    incomingLabels: ['1', '2'],
  };

  // оценка
  const mark = !normalizedMarkValue ? 'Пока не сдан' : getMark(Number(primaryMarkValue), Number(primaryMarkBasis));

  // дата
  const examDate = new Date(date);
  const passingYear = date.slice(0, 4);
  const resultDate = checkPassedExamDate(examDate)
    ? `Проведен ${format(new Date(date), 'dd.MM.yyyy')}`
    : `Будет сдан в ${passingYear} г.`;

  // exam_form_ref 4-6
  const isCreditFormaGiaTypes = [GiaType.writing, GiaType.narration, GiaType.interviewRus];
  const isCreditExam = isCreditFormaGiaTypes.includes(formaGia) && Boolean(isCredit);
  const creditExamTitle = normalizedMarkValue === '1' ? 'Зачет' : 'Незачет';

  return {
    category,
    categoryName,
    doughnutTotal,
    doughnutData,
    doughnutColor,
    mark,
    resultDate,
    isCreditExam,
    creditExamTitle,
  };
};

export default useGiaExamCardProperties;
