import { FC } from 'react';

import Advert from 'portfolio3/components/common/Advert';
import { emitYMEvent } from 'portfolio3/features/yandexMetrika';
import { SxStyles } from 'types';

import commonAdvertImage from './commonAdvertImage.png';

const WidgetEmploymentAdvert: FC = () => {
  const contentSx: SxStyles = {
    backgroundImage: `url(${commonAdvertImage})`,
    backgroundPosition: 'right 20px top 20px',
    backgroundRepeat: 'no-repeat',
  };

  const handleClick = () => {
    emitYMEvent({ type: 'redirectBanner' });
  };

  return (
    <Advert
      title="Найди занятие по душе"
      prompt="Выбирай из более чем 120 тыс. кружков и секций"
      linkText="Перейти в Кружки"
      href="https://school.mos.ru/diary/school/circles"
      contentSx={contentSx}
      onLinkClick={handleClick}
    />
  );
};

export default WidgetEmploymentAdvert;
