/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconArrowDiagonalRightUp: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M17.993 7.049a1 1 0 0 0-.994-1L9.006 6H9a1 1 0 0 0-.006 2l5.558.036-8.259 8.258a.999.999 0 1 0 1.414 1.414l8.288-8.288L16 15A1 1 0 0 0 17 16h.001a1 1 0 0 0 1-1.001l-.008-7.95Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconArrowDiagonalRightUp;
