import { ComponentProps, FC } from 'react';

import { IconArrowLeft } from 'icons';
import { Button } from 'portfolio3/ui-kit/button';
import { mergeSx } from 'utils';

import * as styles from './styles';

const BackArrow: FC<ComponentProps<typeof Button>> = ({ size, sx, ...props }) => {
  return (
    <Button {...props} onlyIcon variant="outline" sx={mergeSx(styles.root(size), sx)}>
      <IconArrowLeft />
    </Button>
  );
};

export default BackArrow;
