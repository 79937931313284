import { SxStyles } from 'types';

export const root: SxStyles = {
  gap: '12px',
};

export const specialityInfoContainer: SxStyles = (theme) => {
  return {
    display: 'flex',
    gap: '12px',

    '& > *': {
      flexBasis: '100%',
    },

    [theme.breakpoints.down('commonSm')]: {
      flexDirection: 'column',
      gap: '8px',
    },
  };
};

export const specialityInfo: SxStyles = {
  flexGrow: '1',
};

export const subjectsContainer: SxStyles = {
  display: 'flex',
  gap: '12px',
};
