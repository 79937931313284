import { buildConfig } from 'packages/buildConfig';

import BaseApi from './BaseApi';
import { ApiHeaders, ApiRequestMethod } from './types';
import { prepareHeaders, processBaseResponse } from './utils';

export class MainApi extends BaseApi {
  private token: string | undefined;

  constructor(prefix: string) {
    super({ url: '', prefix });

    if (buildConfig.config) {
      this.url = buildConfig.config.API_URL || '';
    } else {
      // отписка не требуется
      buildConfig.onLoad((config) => {
        this.url = config.API_URL || '';
      });
    }
  }

  setToken(token: string) {
    this.token = token;
  }

  removeToken() {
    this.token = undefined;
  }

  protected request: ApiRequestMethod = (method: string, { path, requestBody, requestHeaders }) => {
    const isFormDataBody = requestBody instanceof FormData;

    const baseHeaders: ApiHeaders = isFormDataBody
      ? { ...requestHeaders }
      : { 'Content-Type': 'application/json', ...requestHeaders };

    const headers = prepareHeaders(baseHeaders, { token: this.token });

    return fetch(`${this.url}${this.prefix}${path}`, {
      method,
      body: isFormDataBody ? requestBody : JSON.stringify(requestBody),
      headers,
    })
      .then(async (response) => {
        return await processBaseResponse(response);
      })
      .then(
        (response) => ({ response }),
        (error) => ({ error }),
      );
  };
}
