/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useContext, useMemo } from 'react';

import { ActivitySecondaryBlock } from '../basicBlocks';
import { IDictionaryItem } from '../../../../../api/types';
import { DifferentCode, SectionCodes } from '../../../../../const';
import { IEventData } from 'portfolio3/features/dataEntryForm';

import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';
import { BaseInputLabel, FormControl, Input } from 'portfolio3/ui-kit';
import { FormHelperText } from '@mui/material';
import { Select } from 'portfolio3/ui-kit/selects';
import { IController, TokenFieldController } from 'portfolio3/ui-kit/types';
import { StretchyTokenField } from 'portfolio3/ui-kit/autocomplete';

interface ITourismExpeditionSecondaryBlockProps {
  tourismKindOptions: IDictionaryItem[];
  subjectOptions: IDictionaryItem[];
  eventLevelOptions: IDictionaryItem[];
  nameLabel: string;
  namePlaceholder: string;
  organizatorLabel: string;
  organizatorPlaceholder: string;
  eventData: IEventData;
}

const TourismExpeditionSecondaryBlock: React.FC<ITourismExpeditionSecondaryBlockProps> = ({
  tourismKindOptions,
  subjectOptions,
  eventLevelOptions,
  nameLabel,
  namePlaceholder,
  organizatorLabel,
  organizatorPlaceholder,
  eventData,
}) => {
  const { inputRenderMode, inputSize, formData, formErrors, onChangeFormData, onChangeFormErrors } = useContext(LocalDataEntryFormContext);
  const isNotSportEventTourism = formData.typeCode !== SectionCodes.sportEventTourism;

  const secondNameController = useMemo(() => ({
    handleChange: (secondName: string) => {
      onChangeFormData((prevstate) => ({
        ...prevstate,
        secondName
      }));
      onChangeFormErrors((prevstate) => ({
        ...prevstate,
        secondNameError: false
      }));
    },
    handleBlur: (secondName: any) => {
      if (!secondName) {
        onChangeFormErrors((prevstate) => ({
          ...prevstate,
          secondNameError: true
        }));
      }
    }
  }), [formData.id]);

  const tourismKindController: IController<string | undefined> = useMemo(() => ({
    handleChange: (tourismKind) => {
      onChangeFormData((prevstate) => ({
        ...prevstate,
        tourismKind: Number(tourismKind),
        subjectId: []
      }));
      onChangeFormErrors((prevstate) => ({
        ...prevstate,
        tourismKindError: false
      }));
    },
    handleBlur: (type) => {
      if (!type) {
        onChangeFormErrors((prevstate) => ({
          ...prevstate,
          tourismKindError: true
        }));
      }
    }
  }), [formData.id]);

  const subjectController: TokenFieldController = useMemo(() => ({
    handleChange: (subjects) => {
      onChangeFormData((prevState) => ({
        ...prevState,
        subjectId: subjects.map((subject) => subject.code),
      }));
      if (isNotSportEventTourism) {
        onChangeFormErrors((prevState) => ({
          ...prevState,
          subjectError: subjects.length === 0,
        }));
      }
    },
    handleBlur: (subjects) => {
      if (isNotSportEventTourism) {
        onChangeFormErrors((prevState) => ({
          ...prevState,
          subjectError: subjects.length === 0,
        }));
      }
    },
  }), [formData.id]);

  const additionalTopControllers = (
    <>
      {Number(formData.name) === DifferentCode && (
        <FormControl
          sx={{ width: '100%' }}
          required
          error={formErrors.secondNameError}
          renderMode={inputRenderMode}
          inputSize={inputSize}
          label={<BaseInputLabel>{nameLabel}</BaseInputLabel>}
          helper={formErrors.secondNameError && <FormHelperText>Введите название</FormHelperText>}
          control={
            <Input
              renderMode={inputRenderMode}
              size={inputSize}
              placeholder={namePlaceholder}
              value={formData.secondName}
              controller={secondNameController}
            />
          }
        />
      )}
      <FormControl
        required
        error={formErrors.tourismKindError}
        renderMode={inputRenderMode}
        inputSize={inputSize}
        label={<BaseInputLabel>Вид туристической деятельности</BaseInputLabel>}
        helper={formErrors.tourismKindError && <FormHelperText>Выберите вид туристической деятельности</FormHelperText>}
        control={
          <Select
            inputRenderMode={inputRenderMode}
            inputSize={inputSize}
            placeholder="Выберите вид туристической деятельности..."
            value={formData?.tourismKind}
            options={tourismKindOptions}
            controller={tourismKindController}
          />
        }
      />
      <FormControl
        // не обязательно для Спорт/Мероприятие/Первенство по туризму
        required={isNotSportEventTourism}
        disabled={!formData?.tourismKind}
        error={formErrors.subjectError}
        renderMode={inputRenderMode}
        inputSize={inputSize}
        label={<BaseInputLabel>Тип дистанции</BaseInputLabel>}
        helper={
          formErrors.subjectError && <FormHelperText>Выберите хотя бы один тип дистанции</FormHelperText>
        }
        control={
          <StretchyTokenField
            size={inputSize}
            inputRenderMode={inputRenderMode}
            options={subjectOptions || []}
            controller={subjectController}
            value={formData.subjectId}
            placeholder="Начните вводить название типа дистанции..."
          />
        }
      />
    </>
  );

  return (
    <ActivitySecondaryBlock
      eventLevelOptions={eventLevelOptions}
      additionalTopControllers={additionalTopControllers}
      eventData={eventData}
      organizatorLabel={organizatorLabel}
      organizatorPlaceholder={organizatorPlaceholder}
    />
  );
};

export default TourismExpeditionSecondaryBlock;
