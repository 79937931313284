/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useMemo } from 'react';

import { connect } from 'react-redux';

import { IRootState } from '../../../../reducers';
import { ICommonResponse } from '../../../../api/types';

import PdfCommonDataEntityContainer from '../common';
import { PdfCommonDataEntity } from '../../../../components/pdfStudent/DataEntity';
import { widgetPdfIcons } from '../../../../icons';
import { cultureOnlineEventsSelector } from '../../../../selectors';
import { IEntity } from '../../../../types';

import '../index.scss';
import './index.scss';
import { useDataLoad } from 'hooks';
import { getDataLoadRequests } from 'utils';

interface IPdfCultureEventContainerProps {
  studentOnlineVisitings: ICommonResponse<IEntity>;
}

const PdfCultureEventContainer: React.FC<IPdfCultureEventContainerProps> = ({
  studentOnlineVisitings
}) => {
  useDataLoad({ shouldLoad: true, requests: getDataLoadRequests().cultureOnlineVisitings });

  const filteredClubsYear = useMemo(() => (studentOnlineVisitings.content && studentOnlineVisitings.content?.length > 0
    ? studentOnlineVisitings.content?.reduce((prevItem, nextItem) => (prevItem.year > nextItem.year ? prevItem : nextItem)).year
    : undefined),
  [studentOnlineVisitings]);

  return (
    <PdfCommonDataEntityContainer
      title="Посещение культурных онлайн-мероприятий"
      icon={widgetPdfIcons.pdfIconCultureOnlineEvents}
    >
      {studentOnlineVisitings.content
        .map((olympiad: IEntity) => (
          <PdfCommonDataEntity key={olympiad.id || olympiad.recordId} entity={olympiad} year={filteredClubsYear} />
        ))}
    </PdfCommonDataEntityContainer>
  );
};

export default connect(
  (state: IRootState) => ({
    studentOnlineVisitings: cultureOnlineEventsSelector(state)
  })
)(PdfCultureEventContainer);
