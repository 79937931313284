import { INVALIDATE_PARALLEL_CURRICULA, SET_PARALLEL_CURRICULA_SUBJECT_PROJECT_EXISTANCE } from '../../actions';
import { connectReducers } from '../../utils';
import { IReducers } from '..';

export type ParallelCurriculaState = {
  hasSubjectProject: boolean | null;
};

type SetSubjectProjectExistanceAction = { payload: ParallelCurriculaState['hasSubjectProject'] };

const initialState: ParallelCurriculaState = {
  hasSubjectProject: null,
};

const parallelCurricula: IReducers<ParallelCurriculaState> = {
  [SET_PARALLEL_CURRICULA_SUBJECT_PROJECT_EXISTANCE]: (state, { payload }: SetSubjectProjectExistanceAction) => {
    return {
      ...state,
      hasSubjectProject: payload,
    };
  },
  [INVALIDATE_PARALLEL_CURRICULA]: () => initialState,
};

export default connectReducers(initialState, parallelCurricula);
