import { ComponentProps, FC } from 'react';

import { Typography } from '@mui/material';
import { Popover } from 'portfolio3/ui-kit';

interface IJobPartialApprovePopperProps {
  anchorEl: ComponentProps<typeof Popover>['anchorEl'];
  isOpen: boolean;
  onClose: () => void;
}

const JobPartialApprovePopper: FC<IJobPartialApprovePopperProps> = ({ anchorEl, isOpen, onClose }) => {
  return (
    <Popover
      header={<Typography variant="Paragraph MD/Semi Bold">Часть данных не верифицирована</Typography>}
      body={
        <Typography variant="Paragraph MD/Regular">
          Данные о должности и основном функционале были добавлены пользователем.
        </Typography>
      }
      placement="top-end"
      anchorEl={anchorEl}
      open={isOpen}
      onClose={onClose}
    />
  );
};

export default JobPartialApprovePopper;
