import { getAccentColor, NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

import backgroundImage from './image.png';

export const personalData: SxStyles = () => ({
  width: '724px',
});

export const modalHeader: SxStyles = () => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '12px',

  padding: '24px',
  borderBottom: '1px solid',
  borderColor: NeutralColors.light_neutrals_300,
});

export const modalContent: SxStyles = () => ({
  background: 'white',

  width: '100%',
  margin: 'auto',

  padding: '16px 24px',

  backgroundImage: `url(${backgroundImage})`,
  backgroundPosition: 'bottom right',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '150px',
});

export const modalFooter: SxStyles = () => ({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  position: 'relative',

  borderTop: '1px solid',
  borderColor: NeutralColors.light_neutrals_300,
  padding: '20px 24px',

  backgroundColor: NeutralColors.light_neutrals_100,
});

export const buttonsGroup: SxStyles = () => ({
  display: 'flex',
  gap: '12px',
});

export const modalTextDownload: SxStyles = () => ({
  marginTop: '8px',

  color: getAccentColor('indigo', '200'),

  textDecoration: 'none',
});
