import { SectionCodes } from 'const';
import { IRootState } from 'reducers';
import { createSelector } from 'reselect';
import { isDefined } from 'utils';

import { getCivilAffilationAchievementImage } from './utils';

const civilAffilationAchievementsSelector = createSelector(
  [(state: IRootState) => state.studentAffilations],
  (affilations) => {
    const achievementImages = affilations.content
      .filter((affilation) => affilation.categoryCode === SectionCodes.civil)
      .map((affilation) => {
        return getCivilAffilationAchievementImage(affilation);
      })
      .filter(isDefined);

    return achievementImages;
  },
);

export default civilAffilationAchievementsSelector;
