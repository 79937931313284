import { FC } from 'react';

import { AvatarTypeRef } from 'const';
import { useAppSelector, useAvatar, useLinkModeStudentName } from 'hooks';
import { NeutralColors } from 'portfolio3/styles';
import Avatar from 'portfolio3/ui-kit/Avatar';
import { getOutlineWithRadiusStyles } from 'portfolio3/ui-kit/utils';
import { SxStyles } from 'types';

import { FirstMovementAvatarAttachmentSmall } from '../FirstMovementAvatarAttachment';

export const avatarStyles: SxStyles = {
  ...getOutlineWithRadiusStyles({ outlineWidth: 4, radius: '16px', color: '#ffffff' }),
};

const HeaderAvatar: FC = () => {
  const currentStudent = useAppSelector((state) => state.currentStudent);

  const { firstname, lastname } = currentStudent;
  const { firstName, lastName } = useLinkModeStudentName(firstname, lastname);

  const { avatarImage, avatarType } = useAvatar();

  const avatarElement = (
    <Avatar
      image={avatarImage}
      firstName={firstName ?? ''}
      lastName={lastName ?? ''}
      size={40}
      containerSx={avatarStyles}
      borderRadius="12px"
      backdropColor={NeutralColors.light_neutrals_0}
    />
  );

  const firstMovementAvatarElement = (
    <FirstMovementAvatarAttachmentSmall>{avatarElement}</FirstMovementAvatarAttachmentSmall>
  );

  const codeToAvatarElement = {
    [AvatarTypeRef.default]: avatarElement,
    [AvatarTypeRef.firstMovement]: firstMovementAvatarElement,
  };

  return codeToAvatarElement[avatarType] ?? avatarElement;
};

export default HeaderAvatar;
