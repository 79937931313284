/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { SvgIcon } from '@mui/material';
import { IconRouteProps } from './types';

const IconRouteScience: React.FC<IconRouteProps> = ({ outerFill, ...props }) => (
  <SvgIcon width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path className="outer" d="M0 8C0 3.58172 3.58172 0 8 0H22C26.4183 0 30 3.58172 30 8V22C30 26.4183 26.4183 30 22 30H8C3.58172 30 0 26.4183 0 22V8Z" fill={outerFill || '#ECF1FF'} />
    <path className="inner" d="M10.7133 8.76646H19.3046C19.6051 8.76646 19.8934 8.64706 20.1059 8.43454C20.3184 8.22202 20.4378 7.93378 20.4378 7.63323C20.4378 7.33268 20.3184 7.04444 20.1059 6.83191C19.8934 6.61939 19.6051 6.5 19.3046 6.5H10.7133C10.4128 6.5 10.1245 6.61939 9.91199 6.83191C9.69947 7.04444 9.58008 7.33268 9.58008 7.63323C9.58008 7.93378 9.69947 8.22202 9.91199 8.43454C10.1245 8.64706 10.4128 8.76646 10.7133 8.76646Z" fill="#5C7CFA" />
    <path className="inner" d="M23.3203 21.1397L18.5395 13.2567V11.3018C18.5395 11.067 18.4463 10.8418 18.2802 10.6758C18.1142 10.5098 17.889 10.4165 17.6542 10.4165C17.4194 10.4165 17.1942 10.5098 17.0282 10.6758C16.8621 10.8418 16.7689 11.067 16.7689 11.3018V13.6887L17.5834 15.0132H12.2855L13.2275 13.4408V11.316C13.2275 11.0812 13.1342 10.856 12.9682 10.69C12.8022 10.5239 12.577 10.4307 12.3422 10.4307C12.1074 10.4307 11.8822 10.5239 11.7162 10.69C11.5501 10.856 11.4569 11.0812 11.4569 11.316V13.03L6.66896 21.1609C6.54637 21.4087 6.48884 21.6835 6.50179 21.9597C6.51473 22.2358 6.59772 22.504 6.74294 22.7392C6.88817 22.9744 7.09085 23.1688 7.33192 23.3041C7.57298 23.4393 7.8445 23.511 8.12091 23.5124H21.8755C22.1542 23.5116 22.4281 23.4393 22.6709 23.3025C22.9137 23.1656 23.1174 22.9687 23.2623 22.7306C23.4073 22.4926 23.4888 22.2213 23.4989 21.9427C23.5091 21.6642 23.4476 21.3877 23.3203 21.1397Z" fill="#5C7CFA" />
  </SvgIcon>
);

export default IconRouteScience;
