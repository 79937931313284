import { IDictionaryItem } from 'api/types';
import { ALL_SUBJECTS_CODE, ALL_SUBJECTS_VALUE } from 'containers/redesignStudent/Diagnostic/const';
import { IRootState } from 'reducers';
import { createSelector } from 'reselect';

const selfDiagnosticSubjectsSelector = createSelector(
  [(state: IRootState) => state.studentDiagnosis],
  (selfDiagnostics): IDictionaryItem[] => {
    const dictionarySubjects =
      selfDiagnostics?.content?.selfDiagnosticSubjects.map(
        (subject, index): IDictionaryItem => ({
          value: subject.name,
          code: index + 1,
        }),
      ) || [];

    dictionarySubjects.unshift({
      value: ALL_SUBJECTS_VALUE,
      code: ALL_SUBJECTS_CODE,
    });

    return dictionarySubjects;
  },
);

export default selfDiagnosticSubjectsSelector;
