import { createContext } from 'react';

import { VuzDetailsTab } from '../const';
import { IVuzDetailsFilters, SetFilterFunction } from '../types';

interface IVuzDetailsContext {
  currentTab: VuzDetailsTab | null;
  filters: IVuzDetailsFilters;
  totalElements: number;
  setFilter: SetFilterFunction<IVuzDetailsFilters>;
  onChangeCurrentTab: (value: number | null) => void;
}

export const VuzDetailsContext = createContext({} as IVuzDetailsContext);
