import { SxStyles } from 'types';

export const taskCounter: SxStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '4px',

  padding: '4px 8px',

  svg: {
    width: '16px',
    height: '16px',
  },
};
