/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';

import {
  FAILURE,
  INVALIDATE,
  REQUEST,
  SUCCESS
} from '../../actions/utils';

import { IAdminSettingResponse, ICommonResponseObject } from '../../api/types';

import genericReducers from '../genericReducer';
import { CLEAR_ADMIN_SETTINGS, GET_ADMIN_SETTINGS } from '../../actions';

import { IReducers } from '..';

export type AdminSettingsState = ICommonResponseObject<IAdminSettingResponse>;

const initialState: AdminSettingsState = {
  content: {
    sections: [],
  },
  loading: false,
};

const adminSettings: IReducers<AdminSettingsState> = {
  [GET_ADMIN_SETTINGS[REQUEST]]: genericReducers().get.request,
  [GET_ADMIN_SETTINGS[SUCCESS]]: genericReducers().get.success,
  [GET_ADMIN_SETTINGS[FAILURE]]: genericReducers().get.failure,
  [GET_ADMIN_SETTINGS[INVALIDATE]]: genericReducers().get.invalidate(initialState),
  [CLEAR_ADMIN_SETTINGS]: () => ({
    content: { sections: [] },
    loading: false
  })
};

export default connectReducers(initialState, adminSettings);
