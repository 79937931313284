import { FC, useContext } from 'react';

import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { capitalize } from 'lodash';
import InlinePadding from 'portfolio3/components/common/InlinePadding';
import {
  TrainingTypeCodeNames,
  TrainingTypeResponseMapping,
  VuzRecommendationTab,
} from 'portfolio3/features/vuzRecommendation/const';
import { NeutralColors } from 'portfolio3/styles';
import { commonTheme } from 'portfolio3/styles/theme';
import { Attributes } from 'portfolio3/ui-kit';
import { NeutralBadge } from 'portfolio3/ui-kit/badges';
import { horizontalOverflowStyles } from 'portfolio3/ui-kit/utils';
import { SxStyles } from 'types';
import { isDefined, mergeSx } from 'utils';

import { VuzRecommendationContext } from '../../context';
import SpecialityInfo from './SpecialityInfo';
import * as styles from './styles';

interface IVuzCardSpecialityProps {
  specialityNumber: string;
  specialityName: string;
  specialityType?: string;
  facultyName?: string;
  budgetPlaces?: number;
  budgetMinBall?: number;
  paidPlaces?: number;
  paidMinBall?: number;
  specialitySubjects?: string[];
  studentSubjectsBallSummary?: number;
  subjectsOverflowParent?: boolean;
  desktopInlinePadding: string;
  mobileInlinePadding: string;
  sx?: SxStyles;
}

const VuzCardSpeciality: FC<IVuzCardSpecialityProps> = ({
  specialityType,
  specialityNumber,
  specialityName,
  budgetPlaces,
  budgetMinBall,
  paidPlaces,
  paidMinBall,
  specialitySubjects = [],
  facultyName,
  studentSubjectsBallSummary,
  subjectsOverflowParent,
  desktopInlinePadding,
  mobileInlinePadding,
  sx,
}) => {
  const { currentTab } = useContext(VuzRecommendationContext);

  const isSubjectsTab = currentTab === VuzRecommendationTab.SUBJECTS;

  const isSmallScreen = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const specialityResponseTrainingType = specialityType?.toLowerCase() ?? '';
  const trainingTypeCode =
    TrainingTypeResponseMapping[specialityResponseTrainingType as keyof typeof TrainingTypeResponseMapping] ?? null;
  const trainingType = trainingTypeCode ? TrainingTypeCodeNames[trainingTypeCode] : specialityType;
  const formattedTrainingType = capitalize(trainingType);
  const formattedFacultyName = capitalize(facultyName);

  const specialityAttributes = [formattedTrainingType, formattedFacultyName].filter(isDefined);

  const padding = isSmallScreen ? mobileInlinePadding : desktopInlinePadding;

  const subjectsNormalElement = (
    <InlinePadding _p={padding}>
      <Box sx={mergeSx(styles.subjectsContainer, horizontalOverflowStyles.container)}>
        {specialitySubjects.map((subject, index) => (
          <NeutralBadge key={subject + index}>{subject}</NeutralBadge>
        ))}
      </Box>
    </InlinePadding>
  );

  const subjectsOverflowParentElement = (
    <Box
      component={InlinePadding}
      _p={padding}
      sx={mergeSx(styles.subjectsContainer, horizontalOverflowStyles.container)}
    >
      {specialitySubjects.map((subject, index) => (
        <NeutralBadge key={subject + index}>{subject}</NeutralBadge>
      ))}
    </Box>
  );

  const subjectsElement = subjectsOverflowParent ? subjectsOverflowParentElement : subjectsNormalElement;
  const studentBall = isSubjectsTab ? undefined : studentSubjectsBallSummary;

  return (
    <Stack sx={mergeSx(styles.root, sx)} className="speciality">
      <InlinePadding _p={padding}>
        <Stack className="speciality__header" gap="4px">
          <Typography className="speciality__title" variant="Paragraph MD/Semi Bold">
            {specialityNumber} {specialityName}
          </Typography>
          {isSmallScreen && formattedTrainingType.length > 0 && formattedFacultyName.length > 0 && (
            <>
              <Typography variant="Paragraph MD/Regular">{formattedTrainingType}</Typography>
              <Typography variant="Paragraph MD/Regular" color={NeutralColors.light_neutrals_600}>
                {formattedFacultyName}
              </Typography>
            </>
          )}
          {!isSmallScreen && (
            <Box className="overflow-container" sx={horizontalOverflowStyles.container}>
              <Attributes attributes={specialityAttributes} sx={horizontalOverflowStyles.element} />
            </Box>
          )}
        </Stack>
      </InlinePadding>
      {!!specialitySubjects.length && subjectsElement}
      <InlinePadding _p={padding}>
        <Box sx={styles.specialityInfoContainer}>
          <SpecialityInfo
            format="Бюджет"
            studentBall={studentBall}
            placeCount={budgetPlaces}
            minBall={budgetMinBall}
            sx={styles.specialityInfo}
          />
          <SpecialityInfo
            format="Платно"
            studentBall={studentBall}
            placeCount={paidPlaces}
            minBall={paidMinBall}
            sx={styles.specialityInfo}
          />
        </Box>
      </InlinePadding>
    </Stack>
  );
};

export default VuzCardSpeciality;
