import { ICommonResponse } from 'api/types';

import { GET_INDEPENDENT_DIAGNOSTICS } from '../../actions';
import { FAILURE, INVALIDATE, REQUEST, SUCCESS } from '../../actions/utils';
import { connectReducers } from '../../utils';
import { IReducers } from '..';
import genericReducers from '../genericReducer';
import { IIndependentDiagnosticLearningGroup } from './../../api/types/diagnostic';

export type IndependentDiagnosticsState = ICommonResponse<IIndependentDiagnosticLearningGroup>;

const initialState: IndependentDiagnosticsState = {
  content: [],
  loading: false,
  requestCache: 'notCached',
};

const independentDiagnostics: IReducers<IndependentDiagnosticsState> = {
  [GET_INDEPENDENT_DIAGNOSTICS[REQUEST]]: genericReducers().get.request,
  [GET_INDEPENDENT_DIAGNOSTICS[SUCCESS]]: genericReducers().get.success,
  [GET_INDEPENDENT_DIAGNOSTICS[FAILURE]]: genericReducers().get.failure,
  [GET_INDEPENDENT_DIAGNOSTICS[INVALIDATE]]: genericReducers().get.invalidate(initialState),
};

export default connectReducers(initialState, independentDiagnostics);
