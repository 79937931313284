import { FC } from 'react';

import { useAdminEntityId } from 'portfolio3/components/entityActions';

import { ICardData, IGeneraInfoItem } from '../../../../../types';
import InfoRow from './InfoRow';

import './index.scss';

export interface IGeneralInfoTabProps {
  cardData: ICardData;
  onOpenEventSecondaryCard: () => void;
}

const GeneralInfoTab: FC<IGeneralInfoTabProps> = ({ cardData, onOpenEventSecondaryCard }) => {
  const adminEntityId = useAdminEntityId({ formData: cardData.formData });

  return (
    <div className="general-info">
      {cardData.name && <h4 className="general-info__title">{cardData.name}</h4>}
      <ul className="general-info__general-info">
        {adminEntityId && <InfoRow name="ID карточки" value={adminEntityId} />}
        {cardData.event?.type && (
          <InfoRow
            name={cardData.event.type}
            value={cardData.event.cardData.name}
            onValueClick={onOpenEventSecondaryCard}
          />
        )}
        {cardData.generalInfo?.map((info: IGeneraInfoItem) => (
          <InfoRow key={info.name} name={info.name} value={info.value} tooltip={info.tooltip} />
        ))}
      </ul>
    </div>
  );
};

export default GeneralInfoTab;
