import { FC } from 'react';
import { useCalendar } from 'react-aria';
import { CalendarStateOptions, DatePickerState, useCalendarState } from 'react-stately';

import { createCalendar } from '@internationalized/date';
import { Box, Stack } from '@mui/material';
import { capitalize } from 'lodash';

import { TodayButton } from '../../shared/buttons';
import CalendarGrid from '../../shared/CalendarGrid';
import CalendarHeader from '../../shared/CalendarHeader';
import { DayPickerController } from '../../types';

interface IDayCalendarProps {
  datePickerState: DatePickerState;
  calendarOptions: Partial<CalendarStateOptions>;
  controller: DayPickerController;
  withToday?: boolean;
}

const DayCalendar: FC<IDayCalendarProps> = ({ datePickerState, calendarOptions, controller, withToday }) => {
  const calendarStateOptions = {
    ...calendarOptions,
    locale: 'ru-RU',
    createCalendar,
  };

  const state = useCalendarState(calendarStateOptions);
  const { calendarProps, prevButtonProps, nextButtonProps } = useCalendar(calendarStateOptions, state);

  const { focusedDate } = state;
  const focusedDateObject = new Date(focusedDate.year, focusedDate.month - 1, focusedDate.day);
  const formattedMonth = Intl.DateTimeFormat('ru-RU', { month: 'long' }).format(focusedDateObject);
  const title = `${capitalize(formattedMonth)} ${focusedDate.year} г.`;

  const handleSetToday = () => {
    controller.handleChange(new Date());
    datePickerState.close();
  };

  return (
    <Stack className="day-calendar" {...calendarProps} alignItems="center">
      <CalendarHeader title={title} prevButtonProps={prevButtonProps} nextButtonProps={nextButtonProps} />
      <Box sx={{ marginTop: '12px' }}>
        <CalendarGrid calendarState={state} />
      </Box>
      {withToday && (
        <Box sx={{ marginTop: '8px' }}>
          <TodayButton onSetToday={handleSetToday} />
        </Box>
      )}
    </Stack>
  );
};

export default DayCalendar;
