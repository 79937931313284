/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconChevronFilledArrowDown: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M12 17c-.506 0-.99-.233-1.326-.642L6.461 11.26a2.085 2.085 0 0 1-.259-2.211C6.507 8.402 7.114 8 7.787 8h8.426c.673 0 1.28.402 1.585 1.049.346.733.245 1.6-.258 2.21l-4.214 5.099c-.336.409-.82.642-1.326.642Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconChevronFilledArrowDown;
