import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { IChildInfo, ICommonResponse } from 'api/types';
import useClientWidth from 'hooks/useClientWidth';
import { placeholderGeometry } from 'images';
import NoDataPlug from 'portfolio3/components/common/NoDataPlug';
import { getYMEventPayload } from 'portfolio3/features/yandexMetrika';
import { IRootState } from 'reducers';

import { MobileSubjectDetails, PerformanceChart, PerformanceDiagram, PerformanceTiles } from '../components';
import { LOWEST_SUBJECT_GRADE } from '../const';
import { PerformanceSubjectContext } from '../context';
import { getStudentPerformanceLessonsOldActions, subjectTopicsSelector } from '../model';
import {
  IPerformanceDiagramData,
  IPerformanceSubjectInfo,
  IPerformanceSubjectTopicInfo,
  IPerformanceYear,
  IYearOption,
  MarkType,
  VisibilityType,
} from '../types';
import { filterSubjects, getYearOptionPerformance } from '../utils';

interface IPerformanceContainerProps {
  currentStudent: IChildInfo;
  studentPerformance: IPerformanceYear[];
  studentPerformanceLoading?: boolean;
  subjectTopics: ICommonResponse<IPerformanceSubjectTopicInfo>;
  getStudentPerformanceLessons: typeof getStudentPerformanceLessonsOldActions.request;

  currentVisibilityType: VisibilityType;
  filterYear: number | undefined;
  markType: MarkType;

  possibleYearsOptions: IYearOption[];
}

const PerformanceContainer: React.FC<IPerformanceContainerProps> = ({
  currentStudent,
  studentPerformance,
  studentPerformanceLoading,
  subjectTopics,
  getStudentPerformanceLessons,

  currentVisibilityType,
  filterYear,
  markType,

  possibleYearsOptions,
}) => {
  const dispatch = useDispatch();

  const [selectedSubject, setSelectedSubject] = useState<IPerformanceSubjectInfo>();

  const [toggleSubjectsList, setToggleSubjectsList] = useState(false);
  const clientWidth = useClientWidth();

  const sortedChartStudentPerformance = useMemo(
    () => studentPerformance.sort((x, y) => Number(x?.learningYear?.substr(-4)) - Number(y?.learningYear?.substr(-4))),
    [studentPerformance],
  );

  // данные за текущий год
  const currentYearPerformance = getYearOptionPerformance(studentPerformance, possibleYearsOptions, filterYear);
  const filteredCurrentYearPerformance: IPerformanceYear = {
    ...currentYearPerformance,
    subjects: filterSubjects(currentYearPerformance?.subjects, markType, LOWEST_SUBJECT_GRADE),
  };

  // данные за прошлый год
  const previousYearCode = filterYear && filterYear - 1;
  const previousYearPerformance = getYearOptionPerformance(studentPerformance, possibleYearsOptions, previousYearCode);
  let filteredPreviousYearPerformance: IPerformanceYear | null = null;
  if (previousYearPerformance) {
    filteredPreviousYearPerformance = {
      ...previousYearPerformance,
      subjects: filterSubjects(previousYearPerformance.subjects, markType, LOWEST_SUBJECT_GRADE),
    };
  }

  const diagramData: IPerformanceDiagramData = {
    currentYear: filteredCurrentYearPerformance,
    previousYear: filteredPreviousYearPerformance ?? undefined,
  };

  const currentYearSubjectTopic = useMemo(() => {
    return subjectTopics.content.find((topic) => {
      const yearOption = possibleYearsOptions.find((yearOption) => yearOption.code === filterYear);
      return topic.learningYear === yearOption?.value;
    });
  }, [subjectTopics.content, possibleYearsOptions, filterYear]);

  const currentSubjectTopicsCount = currentYearSubjectTopic?.totalTopics || 0;

  useEffect(() => {
    if (!currentStudent.meshId || !selectedSubject) return;
    dispatch(getStudentPerformanceLessonsOldActions.invalidate());

    const ymMeta = getYMEventPayload({ type: 'subjectChoosingStudy', payload: { subject: selectedSubject.name } });
    getStudentPerformanceLessons(currentStudent.meshId, selectedSubject.name, ymMeta);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubject]);

  useEffect(() => {
    setSelectedSubject(undefined);
  }, [filterYear]);

  useEffect(() => {
    const subject = currentYearPerformance?.subjects.find((subject) => subject.subjectName === selectedSubject?.name);
    if (!selectedSubject || !subject) return;

    setSelectedSubject({
      ...selectedSubject,
      mark: markType === MarkType.AVERAGE ? subject?.averageMark : subject?.averageYearMark,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markType, currentYearPerformance]);

  const handleCloseMobileDetails = useCallback(() => {
    setToggleSubjectsList(false);
  }, []);

  const currentYearSubjects = currentYearPerformance?.subjects;
  const hasAnyCurrentAverageMarks =
    currentYearSubjects?.some((subject) => subject.averageMark >= LOWEST_SUBJECT_GRADE) ?? false;
  const hasAnyCurrentFinalMarks =
    currentYearSubjects?.some((subject) => subject.averageYearMark >= LOWEST_SUBJECT_GRADE) ?? false;

  const hasCurrentYearPerformance =
    (markType === MarkType.AVERAGE && hasAnyCurrentAverageMarks) ||
    (markType === MarkType.FINAL && hasAnyCurrentFinalMarks);

  if (!hasCurrentYearPerformance) {
    return (
      <NoDataPlug
        image={placeholderGeometry}
        title="Данных по предметным результатам за выбранный период пока нет"
        loading={studentPerformanceLoading}
      />
    );
  }

  return (
    <div>
      <PerformanceSubjectContext.Provider
        value={{
          selectedSubject: selectedSubject,
          setSelectedSubject: setSelectedSubject,
        }}
      >
        {currentVisibilityType === VisibilityType.DIAGRAM && (
          <PerformanceDiagram
            performanceData={diagramData}
            markType={markType}
            subjectTopic={currentYearSubjectTopic}
            isTopicsLoading={subjectTopics.loading}
            onOpenDetailedInfoSubject={setToggleSubjectsList}
          />
        )}
        {currentVisibilityType === VisibilityType.TILES && (
          <PerformanceTiles
            performanceData={filteredCurrentYearPerformance}
            markType={markType}
            subjectTopic={currentYearSubjectTopic}
            isTopicsLoading={subjectTopics.loading}
            onOpenDetailedInfoSubject={setToggleSubjectsList}
          />
        )}
        {clientWidth < 767 && selectedSubject && (
          <MobileSubjectDetails
            isOpen={toggleSubjectsList}
            onClose={handleCloseMobileDetails}
            subjectTopic={currentYearSubjectTopic}
            currentSubjectTopicsCount={currentSubjectTopicsCount}
            isTopicsLoading={subjectTopics.loading}
          />
        )}
      </PerformanceSubjectContext.Provider>
      {currentVisibilityType === VisibilityType.CHART && (
        <PerformanceChart
          performanceData={sortedChartStudentPerformance}
          markType={markType}
          lowestGrade={LOWEST_SUBJECT_GRADE}
        />
      )}
    </div>
  );
};

export default connect(
  (state: IRootState) => ({
    currentStudent: state.currentStudent,
    studentPerformance: state.studentPerformanceOld.content?.year ?? [],
    studentPerformanceLoading: state.studentPerformanceOld.loading,
    subjectTopics: subjectTopicsSelector(state),
  }),
  {
    getStudentPerformanceLessons: getStudentPerformanceLessonsOldActions.request,
  },
)(PerformanceContainer);
