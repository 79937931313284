import { FC } from 'react';

import { Box, Stack, Typography } from '@mui/material';

import LinkActionsButton from '../ActionsButton';
import * as styles from './styles';

interface ILinkCardProps {
  id: number;
  linkUrl: string;
  linkQrCode: string;
  creationDate: string;
  startDate: string;
  endDate: string;
}

const LinkCard: FC<ILinkCardProps> = ({ id, linkUrl, linkQrCode, creationDate, startDate, endDate }) => {
  const renderCardItem = (title: string, value: string) => {
    return (
      <Stack gap="2px">
        <Typography variant="Paragraph MD/Medium" sx={styles.name}>
          {title}
        </Typography>
        <Typography variant="Paragraph MD/Regular">{value}</Typography>
      </Stack>
    );
  };

  return (
    <Box className="link-card" sx={styles.root}>
      <LinkActionsButton id={id} linkUrl={linkUrl} linkQrCode={linkQrCode} sx={styles.actionsButton} />
      <Box sx={styles.cardInfo}>
        {renderCardItem('Дата создания', creationDate)}
        {renderCardItem('Дата начала действия', startDate)}
        {renderCardItem('Срок действия', endDate)}
      </Box>
    </Box>
  );
};

export default LinkCard;
