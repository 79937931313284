import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root: SxStyles = {
  display: 'flex',
  gap: '12px',
  paddingInline: '20px',
  paddingTop: '16px',

  transition: 'background-color 0.1s',
};

export const leftPanel: SxStyles = {
  display: 'flex',
  gap: '12px',
};

export const rightPanel: SxStyles = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '8px',

  paddingBottom: '16px',
  borderBottom: '1px solid',
  borderColor: NeutralColors.light_neutrals_300,
};

export const buttons: SxStyles = {
  display: 'flex',
  gap: '16px',
};

export const actionType: SxStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  width: '38px',
  height: '38px',

  borderRadius: '8px',
  backgroundColor: NeutralColors.light_neutrals_200,
  color: NeutralColors.light_neutrals_600,

  transition: 'all 0.1s',

  svg: {
    width: '24px',
    height: '24px',
  },
};

export const openArrow: SxStyles = {
  width: '24px',
  height: '24px',
  color: NeutralColors.light_neutrals_600,
  borderRadius: '50%',

  '&:hover': {
    backgroundColor: NeutralColors.light_neutrals_300,
  },
};

export const indicatorContainer: SxStyles = {
  position: 'relative',
  width: '24px',
  height: '24px',
};

export const deleteButton: SxStyles = {
  position: 'absolute',
  top: 0,
  left: 0,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '24px',
  height: '24px',

  borderRadius: '50%',
  backgroundColor: NeutralColors.light_neutrals_600,
  color: NeutralColors.light_neutrals_0,

  opacity: 0,
  transition: 'transform 0.1s',

  svg: {
    width: '18px',
    height: '18px',
  },
};
