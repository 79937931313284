import { ICommonResponse } from 'api/types';
import { EntityType, SectionCodes, SourceCode } from 'const';
import { cardIllustrations } from 'images';
import { MetaSkillRefState, SectionRefState } from 'reducers/reference';
import { StudentEventsState } from 'reducers/student/events';
import { StudentGiaWorldSkillsState } from 'reducers/student/giaWorldSkills';
import { StudentRewardsState } from 'reducers/student/rewards';
import { StudentJobsState } from 'reducers/student/studentJobs';
import { StudentMetaSkillsState } from 'reducers/student/studentMetaSkills';
import { StudentProfessionalAdditionalEducationState } from 'reducers/student/studentProfessionalAdditionalEducation';
import { mapProfessionEventToEntity, mapRewardEntity } from 'selectors/entityMappers';
import {
  ICardData,
  IEntity,
  IEntityFeature,
  IGiaWorldSkillsEntity,
  IMappedMetaskillsCategory,
  IModifiedReward,
  IUserFunctionality,
} from 'types';
import {
  flagsMap,
  formatDate,
  getDateRange,
  getEntityGeneralInfo,
  getProfessionalEducationEntityBackgroundImage,
  getProfessionEducationEntityGeneralInfo,
  getProfessionJobEntityGeneralInfo,
  getYearFromDate,
} from 'utils';

import { getRootMetaskillIconElement } from './utils';

export const mapProfessionAdditionalEducationResponse = (
  professionEducation: StudentProfessionalAdditionalEducationState,
): ICommonResponse<IEntity> => {
  const newProfessionalEducation =
    professionEducation.content.certificate?.map((education): IEntity => {
      const { recordId, eventDate, organization, profession, eventTime } = education;

      const name = profession;

      const formData = {
        categoryCode: SectionCodes.profession,
        ...education,
        source: {
          code: 0,
        },
        fileReferences: [],
      };

      return {
        recordId,
        date: getDateRange(eventDate),
        place: organization,
        name,
        year: getYearFromDate(eventTime),
        backgroundImage: getProfessionalEducationEntityBackgroundImage(profession),
        features: [
          {
            text: 'проф. обучение',
            isColored: true,
          },
        ],
        formData,
        cardData: {
          name,
          generalInfo: getProfessionEducationEntityGeneralInfo(formData),
          files: null,
          formData,
          titleFallback: 'Профессиональное обучение',
          illustrationFallback: cardIllustrations.professionEducation,
        },
        entityProps: {
          type: EntityType.ADDITIONAL_EDUCATION,
          flags: ['approved'],
        },
      };
    }) || [];

  return {
    ...professionEducation,
    content: newProfessionalEducation,
  };
};

export const mapProfessionEventsResponse = (
  events: StudentEventsState,
  rewards: StudentRewardsState,
  sectionRef: SectionRefState,
): ICommonResponse<IEntity> => {
  const boundRewards = rewards.content?.filter((reward) => reward.entityId);
  const professionEvents = events.content
    .filter((event) => event.categoryCode === SectionCodes.profession)
    .map((event): IEntity => {
      const { id, recordId } = event;

      const eventReward = boundRewards?.find((reward) => reward.entityId == id || reward.entityId == recordId);
      return mapProfessionEventToEntity(sectionRef.content, event, eventReward);
    });

  return {
    ...events,
    content: professionEvents,
  };
};

const employmentTypeToShortValue = {
  'Призван в ряды ВС РФ': 'призван в ряды ВС РФ',
  'Декретный отпуск и отпуск  по уходу за ребенком': 'декретный отпуск',
  'Уход за родственником, опекунство': 'уход за родственником',
  'Изменение места жительства': 'изменение места жительства',
  'Статус безработного в ДТиЗ': 'безработный',
  'Фактическое трудоустройство (профиль, нет)': 'фактическое',
  'Ожидаемое трудоустройство (профиль, нет)': 'ожидаемое',
  'Продолжение обучения (профиль, нет)': 'продолжение обучения',
  'Планируемое обучение': 'планируемое обучение',
  'Продолжат обучение в ВУЗе (профиль, нет)': 'продолжат обучение в вузе',
  'Продолжат обучение в СПО (профиль, нет)': 'продолжат обучение в СПО',
} as const;

export const mapProfessionJobsResponse = (jobs: StudentJobsState): ICommonResponse<IEntity> => {
  const mappedEmploymentTypes = new Map<string, string>();
  Object.entries(employmentTypeToShortValue).forEach(([key, value]) =>
    mappedEmploymentTypes.set(key.toLowerCase(), value),
  );

  const sortedJobs = [...jobs.content.job].sort((a, b) => {
    const { contract: firstContract, creationDate: firstCreationDate } = a;
    const firstYear = firstContract?.date ? getYearFromDate(firstContract.date) : getYearFromDate(firstCreationDate);

    const { contract: secondContract, creationDate: secondCreationDate } = b;
    const secondYear = secondContract?.date
      ? getYearFromDate(secondContract.date)
      : getYearFromDate(secondCreationDate);

    return secondYear - firstYear;
  });

  const professionJobs = sortedJobs.map((job): IEntity => {
    const {
      id,
      recordId,
      contract,
      isByProfile,
      employmentType,
      organization,
      creationDate,
      position,
      mainFunctionality,
      source,
    } = job;

    const mainFeature: IEntityFeature = {
      isColored: true,
      text: isByProfile ? 'трудоустройство по профилю' : 'трудоустройство',
    };

    const employmentTypeValue = employmentType?.value?.toLowerCase() ?? '';
    const hasEmploymentKey = mappedEmploymentTypes.has(employmentTypeValue);

    const entityFeatures: IEntityFeature[] = [mainFeature];
    if (hasEmploymentKey) {
      const typeFeature: IEntityFeature = {
        isColored: false,
        text: mappedEmploymentTypes.get(employmentTypeValue) || '',
      };
      entityFeatures.push(typeFeature);
    }

    const isApproved = source.code === SourceCode.proftech;
    // одно из полей введено пользователем - серая иконка
    const isPartialApprove = isApproved && (Boolean(position) || Boolean(mainFunctionality));

    const flags = flagsMap({
      approved: isApproved,
      partiallyApproved: isPartialApprove,
      undeletable: !id,
    });

    const mergedFormData = {
      ...job,
      categoryCode: SectionCodes.profession,
      dataKind: SectionCodes.professionSpo,
      typeCode: SectionCodes.professionSpoJob,
    };

    const cardData: ICardData = {
      generalInfo: getProfessionJobEntityGeneralInfo(job),
      files: null,
      formData: mergedFormData,
      excludeCardTabs: ['files', 'linkedObjects'],
      illustrationFallback: null,

      entityFlags: flags,
    };

    return {
      formData: mergedFormData,
      id,
      recordId,
      date: contract?.date ? `Дата договора: ${formatDate(contract.date)}` : '',
      features: entityFeatures,
      name: organization || '',
      year: contract?.date ? getYearFromDate(contract.date) : getYearFromDate(creationDate),
      place: position || '',
      cardData,
      entityProps: {
        type: EntityType.JOB,
        flags,
      },
    };
  });

  return {
    ...jobs,
    content: professionJobs,
  };
};

export const mapProfessionRewardsResponse = (
  events: StudentEventsState,
  rewards: StudentRewardsState,
  sectionRef: SectionRefState,
): ICommonResponse<IModifiedReward> => {
  const professionRewards = rewards.content
    .filter((reward) => reward.categoryCode === SectionCodes.profession)
    .map((reward) => {
      return mapRewardEntity(reward, events.content, sectionRef.content);
    });

  return {
    ...rewards,
    content: professionRewards,
  };
};

export const mapProfessionMetaskillsResponse = (
  metaskills: StudentMetaSkillsState,
  metaskillRef: MetaSkillRefState,
): ICommonResponse<IMappedMetaskillsCategory> => {
  const rootMetaSkills = metaskillRef.content.filter((skill) => typeof skill.parentId !== 'number');

  const categories = rootMetaSkills.map((rootMetaskill): IMappedMetaskillsCategory => {
    const { code, value } = rootMetaskill;

    const childMetaskills = metaskills.content
      .filter((metaskill) => metaskill.parentId === rootMetaskill.code)
      .map((metaskill): IMappedMetaskillsCategory['childMetaskills'][number] => {
        return {
          code: metaskill.code,
          name: metaskill.value,
        };
      });

    return {
      code,
      name: value,
      iconElement: getRootMetaskillIconElement(code),
      childMetaskills,
    };
  });

  const nonEmptyCategories = categories.filter((rootCategory) => rootCategory.childMetaskills.length > 0);

  return {
    ...metaskills,
    content: nonEmptyCategories,
  };
};

export const mapProfessionGiaWorldskillsResponse = (
  giaWorldskills: StudentGiaWorldSkillsState,
  userFunctionality: IUserFunctionality,
): ICommonResponse<IGiaWorldSkillsEntity> => {
  const entities = giaWorldskills.content.map((item): IGiaWorldSkillsEntity => {
    const {
      id,
      resultScore,
      maxCompetenceScore,
      name,
      resultDate,
      competenceCode,
      fileReferences,
      creationDate,
      linkedObjects,
    } = item;
    const { isAdmin } = userFunctionality;

    return {
      id,
      resultScore,
      maxCompetenceScore,
      name,
      resultDate,
      competenceCode,
      creationDate,
      cardData: {
        name,
        generalInfo: getEntityGeneralInfo(item),
        formData: item,
        files: fileReferences || [],
        linkedObjects,
        entityFlags: flagsMap({ undeletable: !isAdmin }),
        illustrationFallback: cardIllustrations.professionGia,
      },
      entityType: EntityType.GIA_WORLDSKILLS,
      entityProps: {
        type: EntityType.GIA_WORLDSKILLS,
        flags: flagsMap({ undeletable: !isAdmin }),
      },
    };
  });

  return {
    ...giaWorldskills,
    content: entities,
  };
};
