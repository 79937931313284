import { SxStyles } from 'types';

export const root: SxStyles = {
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  width: '100%',
};

export const giaBottomLayout: SxStyles = {
  display: 'flex',
  gap: '16px',
};

export const subjectsBottomLayout: SxStyles = {
  display: 'grid',
  gridAutoFlow: 'column',
  gridTemplateColumns: '1fr 1fr auto',
  gap: '16px',
};

export const buttonsContainer: SxStyles = {
  display: 'flex',
  justifyContent: 'space-between',
};
