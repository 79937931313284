/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import InterestProgressBar from '../ProgressBar';
import InterestsFooter from '../../footer';

import './index.scss';

interface IInterestStepFooterProps {
  submitDisabled?: boolean;
  progressBarRef: React.MutableRefObject<HTMLElement | null>;
  onSubmit: React.MouseEventHandler<HTMLButtonElement>;
  onBack: React.MouseEventHandler<HTMLButtonElement>;
}

const InterestStepFooter: React.VFC<IInterestStepFooterProps> = ({
  submitDisabled,
  progressBarRef,
  onSubmit,
  onBack
}) => {
  return (
    <div className="step-footer">
      <div className="step-footer__progress">
        <InterestProgressBar ref={progressBarRef} />
      </div>
      <InterestsFooter
        onSubmit={onSubmit}
        submitDisabled={submitDisabled}
        onBack={onBack}
      />
    </div>
  );
};

export default InterestStepFooter;
