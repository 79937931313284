import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconQuestionMarkCircleFilled: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M13 12.8369V13.9999C13 14.5529 12.553 14.9999 12 14.9999C11.447 14.9999 11 14.5529 11 13.9999V11.9999C11 11.4469 11.447 10.9999 12 10.9999C12.827 10.9999 13.5 10.3269 13.5 9.49988C13.5 8.67288 12.827 7.99988 12 7.99988C11.173 7.99988 10.5 8.67288 10.5 9.49988C10.5 10.0529 10.053 10.4999 9.5 10.4999C8.947 10.4999 8.5 10.0529 8.5 9.49988C8.5 7.56988 10.07 5.99988 12 5.99988C13.93 5.99988 15.5 7.56988 15.5 9.49988C15.5 11.0799 14.44 12.4029 13 12.8369ZM12 17.9999C11.447 17.9999 11 17.5529 11 16.9999C11 16.4469 11.447 15.9999 12 15.9999C12.553 15.9999 13 16.4469 13 16.9999C13 17.5529 12.553 17.9999 12 17.9999ZM12 1.99988C6.486 1.99988 2 6.48588 2 11.9999C2 17.5139 6.486 21.9999 12 21.9999C17.514 21.9999 22 17.5139 22 11.9999C22 6.48588 17.514 1.99988 12 1.99988Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconQuestionMarkCircleFilled;
