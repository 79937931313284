import { FC, forwardRef, HTMLAttributes } from 'react';

import { styled, svgIconClasses, SvgIconProps } from '@mui/material';
import clsx from 'clsx';
import { getAccentColor, NeutralColors } from 'portfolio3/styles';

interface IHiddenNavigationButtonProps extends HTMLAttributes<HTMLButtonElement> {
  name: string;
  icon?: FC<SvgIconProps>;
  isActive: boolean;
}

const StyledNavLink = styled('button')(() => {
  return {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',

    padding: '8px',
    borderRadius: '8px',

    fontFamily: 'Inter',
    color: NeutralColors.light_neutrals_900,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px',
    textDecoration: 'none',

    border: 'none',
    background: 'none',
    cursor: 'pointer',

    [`& .${svgIconClasses.root}`]: {
      width: 20,
      height: 20,
      color: NeutralColors.light_neutrals_500,
    },

    '&.navigation-link--active': {
      backgroundColor: getAccentColor('indigo', '100'),
      fontWeight: 600,
      color: NeutralColors.light_neutrals_0,

      [`& .${svgIconClasses.root}`]: {
        color: NeutralColors.light_neutrals_0,
      },
    },
  };
});

const HiddenNavigationButton = forwardRef<HTMLButtonElement, IHiddenNavigationButtonProps>(
  ({ name, icon: SectionIcon, isActive, ...props }, ref) => {
    return (
      <StyledNavLink ref={ref} className={clsx('navigation-link', { 'navigation-link--active': isActive })} {...props}>
        {SectionIcon && <SectionIcon />}
        {name}
      </StyledNavLink>
    );
  },
);

HiddenNavigationButton.displayName = 'HiddenNavigationButton';

export default HiddenNavigationButton;
