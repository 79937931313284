import { FC, ReactNode } from 'react';

import { Box, useMediaQuery } from '@mui/material';
import { IconArrowRight } from 'icons';
import { commonTheme } from 'portfolio3/styles/theme';
import { Button } from 'portfolio3/ui-kit/button';
import { mergeSx } from 'utils';

import SearchSectionDivider from '../Divider';
import SearchSectionPadding from '../Padding';
import * as styles from './styles';

interface ISearchSectionCardContainerProps {
  card: ReactNode;
  mapIndex: number;
  totalCards: number;
  onView?: () => void;
}

const SearchSectionCardContainer: FC<ISearchSectionCardContainerProps> = ({ card, mapIndex, totalCards, onView }) => {
  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const isLast = mapIndex + 1 === totalCards;

  return (
    <Box className="search-section-card-container" sx={styles.root}>
      <SearchSectionPadding sx={mergeSx(!isMobile && styles.desktopPadding)}>
        {card}
        {onView && !isMobile && (
          <Button
            className="view-button"
            variant="text"
            size="small"
            onlyIcon
            onClick={onView}
            sx={{ borderRadius: '100%' }}
          >
            <IconArrowRight />
          </Button>
        )}
      </SearchSectionPadding>
      {/* разделитель между карточками в подразделе */}
      {!isLast && (
        <SearchSectionPadding sx={mergeSx(isMobile && styles.mobileDividerPadding)}>
          <SearchSectionDivider />
        </SearchSectionPadding>
      )}
    </Box>
  );
};

export default SearchSectionCardContainer;
