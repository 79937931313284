import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const drawerContainer: SxStyles = {
  paddingBlock: '20px 16px',
};

export const pagination: SxStyles = {
  border: 'none',
  borderBottom: '1px solid',
  borderColor: NeutralColors.light_neutrals_300,
  borderRadius: 0,
};

export const specialityListLabel: SxStyles = {
  display: 'block',
  marginBottom: '12px',
};

export const numberBadge: SxStyles = {
  marginLeft: '8px',
  transform: 'translateY(-1px)',
};
