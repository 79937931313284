import { FC, PropsWithChildren } from 'react';

import { Box } from '@mui/material';
import { getOutlineWithRadiusStyles } from 'portfolio3/ui-kit/utils';
import { SxStyles } from 'types';
import { mergeSx } from 'utils';

import BadgeLarge from './BadgeLarge';

const styles: SxStyles = {
  position: 'relative',

  display: 'flex',
  justifyContent: 'center',

  ...getOutlineWithRadiusStyles({
    outlineWidth: 3,
    outlineOffset: 2.5,
    radius: '50%',
    color: '#E53130',
    sx: { zIndex: 1 },
  }),
};

const smallOutlineStyles: SxStyles = {
  ...getOutlineWithRadiusStyles({
    outlineWidth: 3,
    outlineOffset: 6,
    radius: '50%',
    color: '#E53130',
    sx: { zIndex: 1 },
  }),
};

const badge: SxStyles = {
  zIndex: 1,
  position: 'absolute',
  bottom: '-36px',
};

interface FirstMovementAvatarAttachmentLargeProps {
  isSmall: boolean;
}

const FirstMovementAvatarAttachmentLarge: FC<PropsWithChildren<FirstMovementAvatarAttachmentLargeProps>> = ({
  isSmall,
  children,
}) => {
  return (
    <Box className="avatar-attachment" sx={mergeSx(styles, isSmall && smallOutlineStyles)}>
      {children}
      <Box sx={badge}>
        <BadgeLarge />
      </Box>
    </Box>
  );
};

export default FirstMovementAvatarAttachmentLarge;
