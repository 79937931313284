import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';

import * as styles from './styles';

interface ICommonPlaceholderProps {
  image: string;
  alt: string;
  description: string;
}

const CommonPlaceholder: FC<ICommonPlaceholderProps> = ({ image, alt, description }) => {
  return (
    <Box className="common-placeholder" sx={styles.root}>
      <img src={image} alt={alt} />
      <Typography variant="Paragraph LG/Regular" color={NeutralColors.light_neutrals_600} textAlign="center">
        {description}
      </Typography>
    </Box>
  );
};

export default CommonPlaceholder;
