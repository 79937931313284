import { FC, ReactElement } from 'react';

import { Box, Typography } from '@mui/material';
import TrimText from 'portfolio3/components/common/TrimText';
import TrimTextMultiLine from 'portfolio3/components/common/TrimTextMultiLine';
import { StrongAccentBadge } from 'portfolio3/ui-kit/badges';
import { mergeSx } from 'utils';

import * as styles from './styles';

interface IFileCardProps {
  preview?: string;
  previewPlaceholderText?: string;
  badge: string;
  name: string;
  actionsButtonElement?: ReactElement;
}

const FileCard: FC<IFileCardProps> = ({
  preview,
  previewPlaceholderText = 'JPG',
  badge,
  name,
  actionsButtonElement,
}) => {
  return (
    <Box className="document-card" sx={styles.root}>
      <Box className="document-card__more" sx={styles.moreButton}>
        {actionsButtonElement}
      </Box>

      <Box className="document-card__preview" sx={mergeSx(Boolean(preview) && { backgroundImage: `url(${preview})` })}>
        {!preview && (
          <Box className="document-card__preview-placeholder" sx={styles.previewPlaceholder}>
            <TrimText sx={styles.previewPlaceholderText}>{previewPlaceholderText}</TrimText>
          </Box>
        )}
      </Box>
      <Box className="document-card__body" sx={styles.body}>
        <StrongAccentBadge>
          <TrimText>{badge}</TrimText>
        </StrongAccentBadge>
        <TrimTextMultiLine line={2}>
          <Typography variant="Paragraph MD/Regular">{name}</Typography>
        </TrimTextMultiLine>
      </Box>
    </Box>
  );
};

export default FileCard;
