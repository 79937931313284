import { createSelector } from 'reselect';

import { defaultVisibilitySettings, StudentSectionSettingTypeCodes } from '../../const';
import { IRootState } from '../../reducers';
import { IViewVisibilitySetting } from '../../types';
import { LocalVisibilitySettingsService, normalizeViewSettings, toggleViewSettings } from '../../utils';
import { userFunctionalitySelector } from '../users';
import { getMappedViewSettings } from './utils';

const disableProfileRecommendations = (settings: IViewVisibilitySetting[]): IViewVisibilitySetting[] => {
  const visibilityService = new LocalVisibilitySettingsService(settings);
  visibilityService.setSettingVisibility(
    false,
    StudentSectionSettingTypeCodes.profile,
    StudentSectionSettingTypeCodes.profileRecommendations,
  );
  return visibilityService.result;
};

const visibilitySettingsSelector = createSelector(
  [
    (state: IRootState) => state.visibilitySettings,
    (state: IRootState) => state.adminSettings,
    (state: IRootState) => state.adminViewMode,
    (state: IRootState) => state.adminViewModeSettings,
    (state: IRootState) => userFunctionalitySelector(state),
  ],
  (visibilitySettings, adminSettings, adminViewMode, adminViewModeSettings, user): IViewVisibilitySetting[] => {
    const isAdminViewing = user.isAdmin && adminViewMode.isViewing;
    const isAdminViewingWithoutSettings = isAdminViewing && adminViewMode.viewType === 'student';

    const userSettings = isAdminViewing ? adminViewModeSettings : visibilitySettings;

    if (isAdminViewingWithoutSettings) {
      const defaultSettings = toggleViewSettings(defaultVisibilitySettings, true);
      const adminDefaultSettings = disableProfileRecommendations(defaultSettings);
      return adminDefaultSettings;
    }

    if (
      userSettings.loading ||
      adminSettings.loading ||
      !userSettings.content?.setting?.visibilitySettings ||
      adminSettings.content.sections.length === 0
    ) {
      return [];
    }

    const mappedSettings = getMappedViewSettings(
      defaultVisibilitySettings,
      userSettings.content.setting.visibilitySettings || [],
      adminSettings.content.sections,
    );

    const normalizedSettings = normalizeViewSettings(mappedSettings);
    return isAdminViewing ? disableProfileRecommendations(normalizedSettings) : normalizedSettings;
  },
);

export default visibilitySettingsSelector;
