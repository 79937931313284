import { FC, MutableRefObject, ReactElement, useRef, useState } from 'react';

import { Box, MenuProps } from '@mui/material';
import { EntityActionsItem, EntityActionsPopper } from 'components/common/EntityActions';
import { IEntityAction } from 'portfolio3/components/entityActions';

interface IWithActionsPopupProps {
  title?: string;
  actions: IEntityAction[];
  children: (ref: MutableRefObject<null>, onClick: () => void, isOpen: boolean) => ReactElement;
  MenuProps?: Partial<MenuProps>;
}

/**
 * Компонент оборачивает элемент и отображает меню с действиями при клике на этот элемент
 *
 * @param title заголовок меню
 * @param actions список действий
 * @param children функция, которая предоставляет ref и onClick для компонента потребителя
 */
const WithActionsPopup: FC<IWithActionsPopupProps> = ({ title, actions, children, MenuProps }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const anchorRef = useRef<any>(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleActionClick = (effect: IEntityAction['effect']) => {
    return () => {
      handleClose();
      effect();
    };
  };

  return (
    <Box>
      {children(anchorRef, handleOpen, isOpen)}

      <EntityActionsPopper
        title={title}
        onClickAway={handleClose}
        open={isOpen}
        anchorEl={anchorRef.current}
        {...MenuProps}
      >
        {actions.map((action, index) => {
          const { isDisabled, text, icon, effect } = action;
          return (
            <EntityActionsItem
              key={text}
              isDisabled={isDisabled ?? false}
              text={text}
              icon={icon}
              onClick={handleActionClick(effect)}
              MenuItemProps={{
                autoFocus: index === 0,
              }}
            />
          );
        })}
      </EntityActionsPopper>
    </Box>
  );
};

export default WithActionsPopup;
