/* eslint-disable prettier/prettier */
/* eslint-disable */

import connectReducers from '../utils/connectReducers';

import {
  GET_OLYMPIAD_LEVELS
} from '../actions';

import {
  FAILURE,
  REQUEST,
  SUCCESS
} from '../actions/utils';

import { ICommonResponse, IDictionaryItem } from '../api/types';

import genericReducers from './genericReducer';

import { IReducers } from '.';

export type OlympiadLevelsState = ICommonResponse<IDictionaryItem>;

const initialState = {} as ICommonResponse<IDictionaryItem>;

const olympiadLevels: IReducers<OlympiadLevelsState> = {
  [GET_OLYMPIAD_LEVELS[REQUEST]]: genericReducers().get.request,
  [GET_OLYMPIAD_LEVELS[SUCCESS]]: genericReducers().get.success,
  [GET_OLYMPIAD_LEVELS[FAILURE]]: genericReducers().get.failure
};

export default connectReducers(initialState, olympiadLevels);
