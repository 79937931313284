import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const Manicurist: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="230" height="129" viewBox="0 0 230 129" xmlns="http://www.w3.org/2000/svg" {...props} fill="none">
    {/*eslint-disable max-len */}
    <path
      d="M23.556 54.153c-2.879 0-5.284-.59-6.547-1.702-.857-.755-1.195-1.736-.977-2.84.188-.92.714-1.654 1.564-2.176 1.946-1.187 5.577-1.27 10.785-.24 2.037.405 3.308 1.05 3.766 1.928.27.515.196.995.09 1.242-.037.446-.406 2.594-3.818 3.28a24.629 24.629 0 0 1-4.863.508Zm-1.39-6.986c-1.835 0-3.233.282-4.15.838-.683.418-1.097.98-1.24 1.729-.173.878.083 1.626.76 2.223 1.758 1.55 6.065 1.956 10.718 1.022 3.18-.638 3.232-2.607 3.232-2.69 0-.047.015-.102.037-.144.008-.02.158-.336-.06-.734-.255-.46-1.022-1.105-3.24-1.544-2.352-.466-4.381-.7-6.057-.7Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M18.686 49.308a.361.361 0 0 1-.316-.164c-.113-.158-.053-.37.12-.474 4.653-2.628 11.455-.185 11.74-.082.196.069.286.267.211.446-.075.178-.293.26-.488.192-.068-.027-6.735-2.415-11.064.028a.378.378 0 0 1-.203.054ZM22.676 41.596c-3.262 0-5.727-.405-7.2-1.578-.827-.66-1.27-1.538-1.33-2.615-.068-1.303.315-2.34 1.134-3.08 2-1.812 6.126-1.586 10.117-1.373.7.034 1.383.075 2.037.096 5.803.213 6.675 3.568 6.705 3.712 0 .007.007.02.007.028.008.054.173 1.29-.887 2.484s-2.909 1.9-5.51 2.092c-1.848.145-3.547.234-5.073.234Zm-1.104-8.097c-2.406 0-4.6.26-5.758 1.31-.677.611-.97 1.448-.91 2.56.046.892.399 1.592 1.075 2.127 2.067 1.66 6.855 1.55 11.718 1.18 2.39-.178 4.067-.796 4.991-1.839.804-.906.744-1.825.73-1.948-.076-.261-.948-2.972-5.999-3.157-.661-.027-1.353-.062-2.052-.096-1.255-.069-2.555-.137-3.796-.137Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M26.63 41.445a.372.372 0 0 1-.33-.186c-.098-.164-.023-.377.157-.46.03-.013 2.909-1.406 2.894-3.485-.015-2.244-1.661-3.63-4.405-3.699-.21-.007-.368-.164-.368-.35 0-.185.166-.336.384-.336 3.149.082 5.118 1.764 5.133 4.385.015 2.498-3.164 4.028-3.3 4.096a.414.414 0 0 1-.165.035ZM110.888 95.48h-.496c-3.006 0-5.456-2.237-5.456-4.981V31.694c0-2.745 2.45-4.982 5.456-4.982h.496c3.007 0 5.457 2.237 5.457 4.982v58.812c0 2.744-2.45 4.974-5.457 4.974Zm-.496-68.081c-2.593 0-4.705 1.928-4.705 4.295v58.812c0 2.367 2.112 4.295 4.705 4.295h.496c2.594 0 4.706-1.928 4.706-4.295V31.694c0-2.367-2.112-4.295-4.706-4.295h-.496Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M115.97 65.55h-10.651c-.21 0-.376-.151-.376-.343 0-.192.166-.343.376-.343h10.651c.21 0 .376.15.376.343 0 .192-.173.343-.376.343ZM171.898 105.211a.452.452 0 0 1-.135-.021.35.35 0 0 1-.241-.302l-4.66-74.161c0-.062-.075-1.455 1.105-2.594.97-.94 2.481-1.413 4.487-1.413 1.857 0 3.248.473 4.134 1.413 1.286 1.352 1.023 3.157 1.008 3.232l-2.661 71.087a.32.32 0 0 1-.113.234l-2.661 2.429a.397.397 0 0 1-.263.096Zm.549-77.812c-1.781 0-3.104.398-3.931 1.194-.962.92-.91 2.08-.91 2.093l4.615 73.393 1.97-1.798 2.66-70.984c.008-.055.226-1.613-.834-2.732-.744-.768-1.939-1.166-3.57-1.166ZM139.27 80.666a.483.483 0 0 1-.105-.014.351.351 0 0 1-.27-.33v-6.079c0-.192.165-.343.375-.343.211 0 .376.151.376.343v4.948l.647-.879a.368.368 0 0 1 .308-.15c.158 0 .24.047.315.144l.654.816V74.25c0-.192.166-.343.376-.343.211 0 .376.151.376.343v5.922c0 .15-.105.281-.263.33a.404.404 0 0 1-.421-.124l-1.015-1.27-1.029 1.414a.439.439 0 0 1-.324.144Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M140.601 74.21c-1.06 0-1.969-.371-2.691-1.099-3.247-3.28-2.149-13.353-1.428-20.022.218-2.017.406-3.753.406-4.755 0-2.704-.413-6.924-.782-10.657-.293-3.012-.548-5.612-.548-6.971 0-3.3 4.78-3.96 4.983-3.987.022 0 .045-.007.068 0 1.142.048 3.352.735 4.412 2.999 1.375 2.923-.511 17.058-.729 18.664-.181 1.317.097 3.897.421 6.882.721 6.628 1.608 14.87-1.323 17.834-.722.734-1.661 1.111-2.789 1.111Zm.023-46.811c-.406.061-4.307.727-4.307 3.3 0 1.324.255 3.918.548 6.91.369 3.74.782 7.987.782 10.718 0 1.036-.18 2.703-.413 4.824-.669 6.148-1.789 16.44 1.232 19.494.579.59 1.278.871 2.135.871.91 0 1.646-.288 2.24-.892 2.713-2.738 1.804-11.157 1.135-17.305-.331-3.026-.609-5.64-.421-7.033.729-5.352 1.841-16.064.782-18.307-.895-1.922-2.736-2.525-3.713-2.58ZM55.44 33.924H49.3c-.21 0-.376-.15-.376-.343V19.865c0-.193.165-.343.376-.343h6.14c.211 0 .376.15.376.343v13.71c0 .191-.173.35-.376.35Zm-5.772-.686h5.39v-13.03h-5.39v13.03Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M52.366 48.258c-5.104 0-9.253-3.506-9.253-7.822 0-3.246 2.413-6.19 5.998-7.328a.394.394 0 0 1 .481.212c.068.179-.037.378-.233.44-3.284 1.042-5.494 3.725-5.494 6.676 0 3.932 3.81 7.136 8.5 7.136 4.691 0 8.502-3.204 8.502-7.136 0-2.95-2.21-5.634-5.495-6.676-.195-.062-.3-.261-.233-.44.068-.178.278-.274.481-.212 3.586 1.139 5.998 4.082 5.998 7.328 0 4.309-4.149 7.822-9.252 7.822Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M57.484 50.125H47.247c-.21 0-.376-.151-.376-.343v-3.116c0-.192.165-.343.376-.343.21 0 .376.151.376.343v2.773h9.485v-2.773c0-.192.166-.343.376-.343s.376.151.376.343v3.116c0 .192-.165.343-.376.343ZM52.14 44.519c-3.217 0-5.84-1.832-5.84-4.083 0-2.25 2.616-4.083 5.84-4.083 3.224 0 5.84 1.833 5.84 4.083-.007 2.25-2.623 4.083-5.84 4.083Zm0-7.48c-2.804 0-5.089 1.524-5.089 3.397 0 1.873 2.285 3.397 5.089 3.397 2.804 0 5.089-1.523 5.089-3.397-.008-1.873-2.285-3.396-5.089-3.396Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M48.044 45.76a.39.39 0 0 1-.195-.047c-1.88-1.043-3.134-3.123-3.194-5.29-.053-1.922.856-3.61 2.495-4.632 3.495-2.182 7.682-.72 7.862-.66.195.07.286.269.21.447-.075.178-.293.26-.488.192-.038-.014-3.991-1.386-7.163.583-1.45.906-2.218 2.34-2.172 4.049.052 1.941 1.172 3.794 2.833 4.727.18.096.233.31.128.474a.371.371 0 0 1-.316.158ZM129.281 69.345c-.211 0-.376-.151-.376-.344V33.808l-2.285-5.743-2.285 5.743-.661 35.2c0 .193-.143.343-.384.337-.21 0-.375-.158-.368-.35l.669-35.256c0-.041.008-.075.023-.11l2.66-6.69c.106-.274.602-.274.707 0l2.661 6.69a.296.296 0 0 1 .022.117v35.255c-.007.193-.173.344-.383.344Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M126.808 97.916h-.368c-2.308 0-4.187-1.715-4.187-3.822V70.737c0-1.146 1.022-2.079 2.277-2.079h4.187c1.255 0 2.277.933 2.277 2.08v23.356c-.007 2.107-1.879 3.823-4.186 3.823Zm-2.285-28.572c-.842 0-1.526.625-1.526 1.393v23.358c0 1.729 1.541 3.135 3.435 3.135h.368c1.894 0 3.435-1.406 3.435-3.136V70.737c0-.768-.684-1.393-1.526-1.393h-4.186Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M126.47 95.151c-1.579 0-2.751-.954-2.766-.974a.325.325 0 0 1-.023-.487.405.405 0 0 1 .534-.02c.098.081 2.367 1.907 4.698-.29V72.646c0-.192.165-.343.375-.343.211 0 .376.15.376.343v20.873c0 .09-.037.172-.098.233-1.082 1.064-2.157 1.4-3.096 1.4ZM22.015 28.222c-1.97 0-3.623-.137-4.367-.26-2.413-.406-2.488-3.727-2.488-3.926-.075-2.072.601-3.026 1.173-3.465.255-.192.57-.316.91-.364 1.818-.247 11.078-1.345 14.543 1.133.797.57 1.248 1.29 1.346 2.147.112 1.023-.264 1.901-1.113 2.615-2.014 1.695-6.501 2.12-10.004 2.12Zm1.872-7.768c-3.09 0-5.968.364-6.532.44-.21.027-.391.103-.541.212-.369.275-.97 1.03-.902 2.916v.007c0 .028.052 2.95 1.871 3.26 1.721.288 10.794.775 13.725-1.688.677-.57.962-1.235.872-2.045-.075-.672-.413-1.215-1.052-1.674-1.579-1.119-4.6-1.428-7.441-1.428ZM203.715 87.308a.42.42 0 0 1-.218-.061.335.335 0 0 1-.158-.268c-.015-.391-1.736-39.07-.534-49.802 1.218-10.862 9.591-10.471 9.96-10.45.315.013 8.764.535 9.297 9.695.489 8.412-3.059 10.553-5.179 11.836-.218.13-.413.247-.593.37-.857.564-1.744.872-2.676 1.209-1.255.446-2.683.946-4.405 2.195-2.871 2.08-3.239 5.133-3.239 5.16.007.13.939 17.82.744 23.447-.211 5.853-2.774 6.635-2.879 6.663-.045 0-.083.006-.12.006Zm8.666-59.91c-1.533 0-7.787.577-8.824 9.847-1.12 9.957.293 44.135.511 49.103.647-.535 1.751-2.004 1.887-5.744.203-5.599-.729-23.268-.737-23.44.015-.184.398-3.416 3.525-5.68 1.812-1.311 3.292-1.833 4.6-2.3.932-.329 1.729-.61 2.511-1.125.188-.123.398-.247.616-.384 2.112-1.276 5.314-3.204 4.841-11.226-.504-8.645-8.253-9.03-8.584-9.043h-.023c-.03 0-.15-.007-.323-.007Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M212.517 47.812c-.218 0-.436-.014-.662-.04-2.826-.33-7.599-1.97-7.764-9.257-.135-5.99 2.503-8.708 4.743-9.936 1.638-.899 3.623-1.03 5.442-.37 2.044.74 4.69 2.442 5.674 6.319 1.947 7.658-2.082 11.164-4.66 12.57a5.645 5.645 0 0 1-2.773.714Zm-.571-19.322c-.955 0-1.887.233-2.721.686-2.067 1.132-4.51 3.67-4.382 9.332.157 6.772 4.517 8.289 7.11 8.59a5.052 5.052 0 0 0 2.961-.576c2.398-1.31 6.149-4.597 4.307-11.83-.909-3.588-3.344-5.152-5.224-5.839a6.088 6.088 0 0 0-2.051-.363ZM203.204 87.541c-.03 0-.06-.006-.098-.013-.113-.028-2.706-.707-3.179-6.546-.459-5.613-.338-23.33-.331-23.508-.007 0-.519-3.02-3.472-4.982-1.774-1.18-3.225-1.626-4.503-2.024-.947-.295-1.849-.577-2.728-1.105-.188-.11-.391-.227-.609-.343-2.18-1.2-5.818-3.205-5.712-11.63.112-9.168 8.538-10.012 8.846-10.04.361-.034 8.712-.747 10.425 10.06 1.692 10.684 1.737 49.39 1.737 49.782a.351.351 0 0 1-.143.274.407.407 0 0 1-.233.076Zm-11.192-59.525c-.323 0-.511.02-.511.02h-.023c-.33.028-8.057.708-8.162 9.36-.098 8.035 3.179 9.84 5.351 11.034.226.123.444.247.639.357.804.48 1.616.734 2.563 1.029 1.331.411 2.834.878 4.698 2.12 3.232 2.148 3.758 5.36 3.781 5.496 0 .227-.121 17.916.338 23.502.308 3.726 1.481 5.146 2.15 5.66-.015-4.981-.158-39.18-1.729-49.081-.684-4.296-2.563-7.294-5.449-8.673a8.693 8.693 0 0 0-3.646-.824Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M192.253 48.65c-.218 0-.443-.014-.661-.041-2.827-.33-7.599-1.97-7.765-9.257-.135-5.99 2.503-8.707 4.743-9.936 1.639-.899 3.623-1.029 5.442-.37 2.044.74 4.69 2.442 5.675 6.32 1.946 7.657-2.082 11.163-4.66 12.57a5.648 5.648 0 0 1-2.774.714Zm-.571-19.323c-.955 0-1.887.233-2.721.686-2.067 1.132-4.51 3.671-4.382 9.332.158 6.773 4.517 8.29 7.11 8.591a5.033 5.033 0 0 0 2.962-.576c2.398-1.31 6.148-4.598 4.307-11.83-.91-3.589-3.345-5.153-5.224-5.84a5.932 5.932 0 0 0-2.052-.363ZM202.799 70.634c-.279 0-.557-.048-.835-.137-.594-.206-1.067-.604-1.323-1.132a1.977 1.977 0 0 1-.06-1.66c.459-1.119 1.834-1.689 3.059-1.263 1.226.419 1.849 1.674 1.383 2.793-.36.857-1.27 1.4-2.224 1.4Zm0-3.65c-.654 0-1.278.364-1.519.96-.15.371-.135.776.038 1.133.18.357.503.638.909.775.842.288 1.774-.103 2.09-.864.15-.37.135-.776-.038-1.133a1.575 1.575 0 0 0-.909-.775 1.85 1.85 0 0 0-.571-.096ZM29.322 110.481H18.987c-1.496 0-2.714-1.112-2.714-2.477V94.198c0-1.366 1.218-2.477 2.714-2.477h10.342c1.496 0 2.714 1.111 2.714 2.477v13.806c-.008 1.365-1.225 2.477-2.721 2.477ZM18.987 92.407c-1.082 0-1.962.803-1.962 1.79v13.807c0 .988.88 1.79 1.962 1.79h10.342c1.083 0 1.962-.802 1.962-1.79V94.198c0-.988-.88-1.791-1.962-1.791H18.987Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M27.57 92.407h-6.825c-.21 0-.376-.151-.376-.343V79.459c0-1.826 1.631-3.315 3.63-3.315h.316c2 0 3.63 1.49 3.63 3.315V92.07c0 .186-.172.337-.375.337Zm-6.457-.686h6.073V79.459c0-1.448-1.292-2.628-2.878-2.628h-.316c-1.586 0-2.879 1.18-2.879 2.628V91.72ZM18.693 106.741c-.203 0-.368-.151-.376-.336l-.27-12.461a.355.355 0 0 1 .376-.35h11.124c.21 0 .376.15.376.343 0 .192-.166.343-.376.343H18.806l.263 12.111c0 .192-.165.35-.376.35.008 0 0 0 0 0ZM31.66 107.99H16.64c-.21 0-.375-.151-.375-.343 0-.192.165-.343.375-.343H31.66c.21 0 .376.151.376.343 0 .192-.165.343-.376.343Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M22.106 91.165c-.21 0-.376-.15-.376-.343v-11.28c0-.193.166-.344.376-.344s.376.151.376.343v11.281c0 .185-.165.343-.376.343ZM80.124 33.54h-6.208a.406.406 0 0 1-.286-.117.313.313 0 0 1-.09-.274l2.728-18.074c.023-.171.188-.295.376-.295h1.097c.188 0 .354.13.376.302L80.5 33.156a.328.328 0 0 1-.09.267.406.406 0 0 1-.286.117Zm-5.78-.686h5.352l-2.293-17.388h-.436l-2.623 17.388ZM80.124 50.125h-6.208a.407.407 0 0 1-.256-.09c-.143-.123-3.525-3.025-4.216-8.7-.707-5.805 4.284-7.61 4.336-7.623a.463.463 0 0 1 .136-.021h6.208c.03 0 .06 0 .09.007.053.014 5.029 1.18 4.315 7.637-.692 6.313-4.044 8.632-4.18 8.728a.444.444 0 0 1-.225.062Zm-6.058-.687h5.923c.526-.411 3.202-2.758 3.796-8.172.616-5.606-3.24-6.772-3.706-6.889h-6.088c-.556.213-4.405 1.894-3.796 6.89.586 4.871 3.315 7.65 3.871 8.171Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M71.27 42.248c-.188 0-.353-.13-.376-.309-.624-5.208 2.744-6.422 2.887-6.47.195-.07.413.02.48.198a.333.333 0 0 1-.217.44c-.128.048-2.962 1.111-2.406 5.757.023.185-.127.356-.33.377-.015.007-.023.007-.038.007ZM75.276 32.292h-.045c-.203-.021-.353-.193-.323-.385l1.706-12.323a.373.373 0 0 1 .421-.295c.203.02.353.192.324.384l-1.707 12.324c-.022.171-.188.295-.376.295ZM81.034 94.904h-8.516a.377.377 0 0 1-.316-.165.308.308 0 0 1-.015-.329c.008-.02 1.007-1.873 1.669-3.698.541-1.483.098-11.658-.12-15.453-.008 0-.429-1.537.466-2.683.526-.68 1.383-1.063 2.548-1.146 1.075-.082 1.894.172 2.412.741.947 1.05.519 2.807.436 3.122-.075 4.522-.09 13.374.436 14.801.677 1.86 1.368 4.364 1.376 4.392.03.102 0 .212-.068.295a.488.488 0 0 1-.308.123Zm-7.922-.686h7.44c-.225-.79-.736-2.532-1.24-3.911-.69-1.9-.48-14.533-.473-15.069 0-.027.008-.054.015-.089.008-.014.496-1.695-.278-2.545-.353-.392-.947-.556-1.766-.495-.932.07-1.601.357-2 .865-.691.892-.345 2.168-.338 2.182.045.631.79 13.868.09 15.775-.466 1.29-1.097 2.594-1.45 3.287ZM82.717 109.232H71.022c-.647 0-1.27-.254-1.706-.694a1.96 1.96 0 0 1-.572-1.626l.985-8.296c.173-1.447 1.503-2.538 3.097-2.538h8.486c1.458 0 2.683.995 2.856 2.319l1.06 8.261a2.168 2.168 0 0 1-.624 1.798 2.632 2.632 0 0 1-1.887.776Zm-9.891-12.461c-1.21 0-2.217.83-2.353 1.928l-.984 8.296c-.046.398.09.796.383 1.091.293.295.707.466 1.15.466h11.695c.504 0 .985-.199 1.323-.542.338-.343.496-.803.436-1.262l-1.06-8.262c-.127-.974-1.03-1.715-2.112-1.715h-8.478Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M82.401 110.48h-10.92c-.211 0-.376-.151-.376-.343v-1.249c0-.192.165-.343.375-.343h10.921c.211 0 .376.151.376.343v1.249c0 .192-.165.343-.376.343Zm-10.545-.686h10.17v-.563h-10.17v.563ZM80.357 96.77h-6.825c-.21 0-.376-.15-.376-.343v-1.866c0-.192.166-.343.376-.343h6.825c.21 0 .376.15.376.343v1.866c0 .192-.173.343-.376.343Zm-6.457-.686h6.074v-1.18H73.9v1.18ZM76.163 87.652c-.196 0-.361-.145-.376-.323-.008-.096-.586-9.6-.804-12.063-.09-1.008.127-1.763.654-2.23.593-.535 1.353-.473 1.39-.473.203.02.36.185.338.37a.369.369 0 0 1-.405.309c-.023 0-.466-.02-.797.288-.346.316-.489.9-.421 1.681.218 2.47.797 11.98.804 12.077.015.192-.15.35-.353.364h-.03ZM83.927 99.886H69.992c-.21 0-.376-.151-.376-.343 0-.192.166-.343.376-.343h13.935c.21 0 .376.15.376.343 0 .192-.165.343-.376.343ZM84.732 106.116H69.256c-.21 0-.376-.151-.376-.343 0-.192.165-.343.376-.343h15.476c.21 0 .376.151.376.343 0 .192-.166.343-.376.343ZM22.97 66.332c-1.924 0-3.45-.13-3.6-.144-2.038 0-2.691-2.072-2.744-3.17v-.062c.173-1.152.616-1.948 1.315-2.36a2.192 2.192 0 0 1 1.489-.268c.849-.068 8.974-.679 11.477 1.4.519.425.774.947.774 1.53 0 .584-.263 1.105-.774 1.53-1.533 1.277-5.156 1.544-7.937 1.544Zm-5.592-3.314c.022.295.24 2.49 2.029 2.49.12.008 8.651.728 10.996-1.214.361-.295.534-.63.534-1.022 0-.391-.173-.727-.534-1.03-2.345-1.941-10.868-1.227-10.958-1.22a.327.327 0 0 1-.143-.014c-.015 0-.489-.117-.962.171-.489.288-.82.926-.962 1.84Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M26.893 62.05h-8.057c-.21 0-.376-.15-.376-.343 0-.192.165-.343.376-.343h8.057c.21 0 .376.151.376.343 0 .193-.165.343-.376.343ZM154.588 105.869a.402.402 0 0 1-.27-.102c-.196-.179-4.773-4.474-4.435-9.154.188-2.587.91-4.7 1.601-6.738.541-1.599 1.105-3.253 1.398-5.119.631-4.055-2.886-8.193-2.916-8.234a.33.33 0 0 1-.083-.213V53.7c0-.158.12-.295.286-.336.015 0 2.067-.583 2.375-5.654.165-2.752-.271-4.426-.737-6.19-.458-1.75-.932-3.554-.932-6.56 0-6.202 3.368-8.117 3.51-8.2a.417.417 0 0 1 .436.028c.166.13 4.134 3.191 4.134 7.562 0 2.463-.857 5.66-1.616 8.481-.563 2.086-1.044 3.884-1.044 4.892 0 2.279 3.209 5.716 3.239 5.75.06.062.09.145.09.227v22.486a.304.304 0 0 1-.075.206c-.037.048-3.923 4.926-3.923 8.419 0 1.557.766 2.875 1.653 4.405 1.098 1.894 2.345 4.049 2.345 7.143 0 5.565-4.592 9.277-4.788 9.428a.425.425 0 0 1-.248.082Zm-3.953-29.676c.556.679 3.623 4.618 2.991 8.66-.3 1.913-.872 3.595-1.428 5.22-.684 2.005-1.383 4.077-1.571 6.581-.27 3.774 3.022 7.418 3.969 8.378.969-.878 4.269-4.185 4.269-8.68 0-2.93-1.195-4.995-2.255-6.82-.894-1.544-1.736-3.006-1.736-4.728 0-3.486 3.427-8.014 3.999-8.735V53.824c-.557-.611-3.33-3.774-3.33-6.1 0-1.092.496-2.924 1.067-5.058.752-2.785 1.594-5.935 1.594-8.316 0-3.472-2.826-6.155-3.646-6.855-.714.535-2.931 2.587-2.931 7.459 0 2.923.466 4.686.909 6.402.474 1.818.925 3.533.752 6.388-.278 4.584-1.969 5.846-2.661 6.175v22.274h.008Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M159.248 56.582h-8.658c-.211 0-.376-.151-.376-.343 0-.192.165-.343.376-.343h8.658c.211 0 .376.15.376.343 0 .192-.173.343-.376.343ZM159.248 74.21h-8.658c-.211 0-.376-.151-.376-.343 0-.193.165-.344.376-.344h8.658c.211 0 .376.151.376.344 0 .192-.173.343-.376.343ZM46.699 88.489a.406.406 0 0 1-.286-.117.32.32 0 0 1-.082-.288c2.87-13.758.488-32.614.466-32.806a.328.328 0 0 1 .09-.268.376.376 0 0 1 .278-.116l7.125-.151c.106 0 .218.04.286.116a.313.313 0 0 1 .09.275c-1.57 11.431 2.48 32.298 2.518 32.51a.31.31 0 0 1-.075.275.402.402 0 0 1-.278.13l-10.117.446c-.008-.007-.015-.007-.015-.007Zm.88-32.923c.315 2.704 2.111 19.474-.43 32.209l9.216-.405c-.541-2.882-3.841-21.203-2.503-31.941l-6.284.137ZM46.737 110.48c-1.022 0-2-.398-2.684-1.091a3.126 3.126 0 0 1-.901-2.6l1.931-14.396c.233-1.73 1.812-3.005 3.743-2.964l6.773.123c1.743.034 3.202 1.194 3.465 2.772l2.51 14.925a2.487 2.487 0 0 1-.654 2.113 3.02 3.02 0 0 1-2.18.94l-11.95.178h-.053Zm2.014-20.372c-1.488 0-2.743 1.009-2.924 2.367l-1.931 14.396a2.453 2.453 0 0 0 .714 2.059 2.995 2.995 0 0 0 2.172.864l11.951-.178a2.23 2.23 0 0 0 1.616-.7 1.829 1.829 0 0 0 .481-1.571L58.32 92.42c-.21-1.242-1.36-2.161-2.736-2.189l-6.773-.123c-.015.007-.037 0-.06 0Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M48.616 85.545c-.022 0-.037 0-.06-.007-.203-.027-.346-.206-.316-.391 1.782-10.862.526-26.39.511-26.548-.015-.192.143-.357.346-.37.226-.014.391.13.406.315.015.158 1.278 15.761-.519 26.699-.022.178-.18.302-.368.302ZM51.412 93.422c-3.774 0-5.938-.617-6.074-.658-.195-.055-.308-.254-.24-.433.068-.178.278-.274.473-.22.053.014 5.021 1.428 13.049-.075.203-.034.406.083.443.268a.347.347 0 0 1-.293.405c-2.864.535-5.344.713-7.358.713ZM52.178 107.859c-4.45 0-8.44-.645-8.719-.686-.203-.034-.338-.213-.3-.398.037-.185.24-.309.436-.274.112.02 11.522 1.859 17.25-.659.188-.082.413-.014.503.158.09.171.008.377-.173.459-2.465 1.078-5.847 1.4-8.997 1.4ZM54.764 90.218c-.21 0-.375-.151-.375-.344v-1.736c0-.192.165-.343.375-.343s.376.151.376.343v1.736c0 .193-.173.344-.376.344ZM49.3 89.909c-.203 0-.375-.151-.375-.343l-.008-1.42c0-.193.165-.344.376-.344.188 0 .376.151.376.343l.007 1.42c0 .186-.165.344-.376.344Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default Manicurist;
