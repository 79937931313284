import React from 'react';

import { commonBreakpoints } from '../../../../const';
import { useMediaQueryWidth } from '../../../../hooks';
import useDialogHash from '../../../../hooks/useDialogHash';
import DesktopInfoDrawer from './DesktopInfoDialog';
import MobileInfoDialog from './MobileInfoDialog';
import { IInfoDialogProps } from './types';

const InfoDialog: React.FC<IInfoDialogProps> = (props) => {
  useDialogHash(props.open, 'info');
  const isMobile = useMediaQueryWidth(commonBreakpoints.breakpointSm, 'down');

  return isMobile ? <MobileInfoDialog {...props} /> : <DesktopInfoDrawer {...props} />;
};

export default InfoDialog;
