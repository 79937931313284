export type AccentColorType =
  | 'blue'
  | 'violet'
  | 'indigo'
  | 'cyan'
  | 'orange'
  | 'red'
  | 'green'
  | 'grape'
  | 'teal'
  | 'lime'
  | 'yellow'
  | 'pink';
export type AccentColorModifier = '200' | '100' | '80' | '64' | '48' | '24' | '12' | '8';

type AccentColorsObject = {
  [key in AccentColorModifier]: string;
};

export const blueColors: AccentColorsObject = {
  200: '#1864AB',
  100: '#228BE6',
  80: '#4EA2EB',
  64: '#72B5EF',
  48: '#95C8F3',
  24: '#CAE3F9',
  12: '#E4F1FC',
  8: '#EEF6FD',
};

export const violetColors: AccentColorsObject = {
  200: '#5F3DC4',
  100: '#7950F2',
  80: '#9473F5',
  64: '#A98FF7',
  48: '#BFABF9',
  24: '#DFD5FC',
  12: '#EFEAFD',
  8: '#F5F1FE',
};

export const indigoColors: AccentColorsObject = {
  200: '#364FC7',
  100: '#4C6EF5',
  80: '#708BF7',
  64: '#8DA2F9',
  48: '#A9BAFB',
  24: '#D4DCFD',
  12: '#E9EDFD',
  8: '#F1F4FF',
};

export const cyanColors: AccentColorsObject = {
  200: '#0B7285',
  100: '#15AABF',
  80: '#44BBCC',
  64: '#69C9D6',
  48: '#8FD7E1',
  24: '#C7EBF0',
  12: '#E2F4F7',
  8: '#EDF9FA',
};

export const orangeColors: AccentColorsObject = {
  200: '#D9480F',
  100: '#FD7E14',
  80: '#FD9843',
  64: '#FEAD69',
  48: '#FEC18F',
  24: '#FFE0C7',
  12: '#FEEFE2',
  8: '#FFF5ED',
};

export const redColors: AccentColorsObject = {
  200: '#C92A2A',
  100: '#FA5252',
  80: '#FB7575',
  64: '#FC9090',
  48: '#FDACAC',
  24: '#FED6D6',
  12: '#FEEAEA',
  8: '#FFF2F2',
};

export const greenColors: AccentColorsObject = {
  200: '#2B8A3E',
  100: '#40C057',
  80: '#66CD79',
  64: '#85D794',
  48: '#A4E1AF',
  24: '#D1F0D7',
  12: '#E8F7EA',
  8: '#F0FAF2',
};

export const grapeColors: AccentColorsObject = {
  200: '#862E9C',
  100: '#BE4BDB',
  80: '#CB6FE2',
  64: '#D68CE8',
  48: '#E0A9EE',
  24: '#F0D4F7',
  12: '#F7E9FA',
  8: '#FAF1FC',
};

export const tealColors: AccentColorsObject = {
  200: '#087F5B',
  100: '#12B886',
  80: '#41C69E',
  64: '#68D2B2',
  48: '#8EDDC5',
  24: '#C6EEE2',
  12: '#E2F6F0',
  8: '#ECFAF6',
};

export const limeColors: AccentColorsObject = {
  200: '#5C940D',
  100: '#82C91E',
  80: '#9BD44B',
  64: '#AFDD6F',
  48: '#C3E593',
  24: '#E1F2C9',
  12: '#F0F8E4',
  8: '#F5FBED',
};

export const yellowColors: AccentColorsObject = {
  200: '#E67700',
  100: '#FAB005',
  80: '#FBC037',
  64: '#FCCD5F',
  48: '#FDD987',
  24: '#FEECC3',
  12: '#FEF5E1',
  8: '#FFF9EB',
};

export const pinkColors: AccentColorsObject = {
  200: '#A61E4D',
  100: '#E64980',
  80: '#EB6D99',
  64: '#EF8BAE',
  48: '#F3A8C2',
  24: '#F9D3E1',
  12: '#FCE9EF',
  8: '#FDF1F5',
};

export const accentColors: Record<AccentColorType, AccentColorsObject> = {
  blue: blueColors,
  violet: violetColors,
  indigo: indigoColors,
  cyan: cyanColors,
  orange: orangeColors,
  red: redColors,
  green: greenColors,
  grape: grapeColors,
  teal: tealColors,
  lime: limeColors,
  yellow: yellowColors,
  pink: pinkColors,
};

export function getAccentColor(type: AccentColorType, modifier: AccentColorModifier) {
  return accentColors[type][modifier] || '';
}
