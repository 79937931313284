/* eslint-disable prettier/prettier */
/* eslint-disable */

import { IReducers } from "..";
import { GET_OLYMPIAD_PARALLELS } from "../../actions";
import { FAILURE, REQUEST, SUCCESS } from "../../actions/utils";
import { ICommonResponse, IDictionaryItem } from "../../api/types";
import connectReducers from "../../utils/connectReducers";
import genericReducers from "../genericReducer";

export type OlympiadParallelsState = ICommonResponse<IDictionaryItem>;

const initialState = {} as ICommonResponse<IDictionaryItem>;

const olympiadParallels: IReducers<OlympiadParallelsState> = {
  [GET_OLYMPIAD_PARALLELS[REQUEST]]: genericReducers().get.request,
  [GET_OLYMPIAD_PARALLELS[SUCCESS]]: genericReducers().get.success,
  [GET_OLYMPIAD_PARALLELS[FAILURE]]: genericReducers().get.failure
};

export default connectReducers(initialState, olympiadParallels);
