import { Fragment, ReactNode } from 'react';

import { IEntity } from 'types';

import WidgetContainerYear from './WidgetContainerYear';

export const mapEntityToElementWithoutYear = (mapFunction: (entity: IEntity) => ReactNode) => {
  return function MappedEntityElement(entity: IEntity, index: number) {
    return <Fragment key={`${index}-${entity.id || entity.recordId || entity.name}`}>{mapFunction(entity)}</Fragment>;
  };
};

export const mapEntityToElementWithYear = (mapFunction: (entity: IEntity) => ReactNode) => {
  return function MappedEntityElement(entity: IEntity, index: number, entities: IEntity[]) {
    const prevEntity = entities[index - 1] as IEntity | undefined;
    const prevYear = prevEntity?.year;
    const currentYear = entity.year;

    const isYearChanged = prevYear !== currentYear;

    return (
      <Fragment key={`${index}-${entity.id || entity.recordId || entity.name}`}>
        {isYearChanged && currentYear && <WidgetContainerYear year={currentYear} />}
        {mapFunction(entity)}
      </Fragment>
    );
  };
};
