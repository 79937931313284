import { FC, ReactElement } from 'react';

import { Box } from '@mui/material';
import { DetailViewTab } from 'portfolio3/containers/drawers/DetailViewDrawer/types';

import { findChildComponentByType } from '../../../../utils';
import FilesTab, { IFilesTabProps } from '../tabs/Files';
import GeneralInfoTab, { IGeneralInfoTabProps } from '../tabs/GeneralInfo';
import LinkedObjectsTab, { ILinkedObjectsTabProps } from '../tabs/LinkedObjects';
import * as styles from './styles';

interface IPortfolioEntityCardProps {
  currentTab: DetailViewTab;
  children?: (ReactElement<IGeneralInfoTabProps | IFilesTabProps | ILinkedObjectsTabProps> | boolean)[];
}

interface IPortfolioEntityCardPropsSubcomponents {
  GeneralInfoTab: FC<IGeneralInfoTabProps>;
  FilesTab: FC<IFilesTabProps>;
  LinkedObjectsTab: FC<ILinkedObjectsTabProps>;
}

const PortfolioEntityCard: FC<IPortfolioEntityCardProps> & IPortfolioEntityCardPropsSubcomponents = ({
  currentTab,
  children,
}) => {
  const GeneralInfoTab = findChildComponentByType(children, PortfolioEntityCard.GeneralInfoTab);
  const FilesTab = findChildComponentByType(children, PortfolioEntityCard.FilesTab);
  const LinkedObjectsTab = findChildComponentByType(children, PortfolioEntityCard.LinkedObjectsTab);

  return (
    <Box className="entity-card" sx={styles.root}>
      {currentTab === DetailViewTab.GENERAL && GeneralInfoTab}
      {currentTab === DetailViewTab.FILES && FilesTab}
      {currentTab === DetailViewTab.LINKED_OBJECTS && LinkedObjectsTab}
    </Box>
  );
};

PortfolioEntityCard.GeneralInfoTab = GeneralInfoTab;
PortfolioEntityCard.FilesTab = FilesTab;
PortfolioEntityCard.LinkedObjectsTab = LinkedObjectsTab;

export default PortfolioEntityCard;
