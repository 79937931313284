import React, { useContext } from 'react';
import { connect } from 'react-redux';

import { createSelector } from 'reselect';

import {
  ICommonResponse,
  IDictionaryItem,
  IEventKind,
  IModifiedAffilationKindsList,
  IModifiedDictionaryItemList,
  IModifiedEventKindsList,
  IModifiedRewardKindsList,
} from '../../../../../api/types';
import { DifferentValue, SectionCodes, SubcategoryParentsCodes } from '../../../../../const';
import { IRootState } from '../../../../../reducers';
import { sectionRefArchiveFalseSelector } from '../../../../../selectors';
import { getAgeLimitFromArray } from '../../../../../utils';
import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';
import {
  AffiliationSecondaryBlock,
  CivilActivitySecondaryBlock,
  CivilRankRewardSecondaryBlock,
  NameRewardSecondaryBlock,
  OccupationSecondaryBlock,
} from '../../secondaryBlocks';

interface ICivilSecondaryBlockProps {
  activityTypeData: IModifiedDictionaryItemList;
  localKindData: IModifiedEventKindsList;
  ecologyKindData: IModifiedEventKindsList;
  patrioticKindData: IModifiedEventKindsList;
  questKindData: IModifiedEventKindsList;
  volonteerKindData: IModifiedEventKindsList;
  olympiadTypes: ICommonResponse<IDictionaryItem>;
  rewardKindData: IModifiedRewardKindsList;
  affilationKindData: IModifiedAffilationKindsList;
  subcategories: IModifiedRewardKindsList;
}

const CivilSecondaryBlock: React.FC<ICivilSecondaryBlockProps> = ({
  activityTypeData,
  localKindData,
  ecologyKindData,
  patrioticKindData,
  questKindData,
  volonteerKindData,
  subcategories,
  olympiadTypes,
  rewardKindData,
  affilationKindData,
}) => {
  const { isMobile, formData } = useContext(LocalDataEntryFormContext);

  const getAffiliationNameInputLabel = () => {
    if (formData.typeCode === SectionCodes.civilAffilationGroup) return 'Название объединения';
    if (formData.typeCode === SectionCodes.civilAffilationMovement)
      return isMobile ? 'Название движения' : 'Название общественного движения';
    return 'Название';
  };
  const getSecondaryAffiliationNameInputPlaceholder = () => {
    if (formData.typeCode === SectionCodes.civilAffilationGroup) return 'Введите название объединения...';
    if (formData.typeCode === SectionCodes.civilAffilationMovement)
      return isMobile ? 'Название движения' : 'Название общественного движения';
    return '';
  };

  const getAffiliationSubjectInputLabel = () => {
    if (formData.typeCode === SectionCodes.civilAffilationGroup) return 'Название объединения...';
    if (formData.typeCode === SectionCodes.civilAffilationMovement)
      return isMobile ? 'Название движения' : 'Название общественного движения';
    return '';
  };

  const getAffiliationSubjectInputPlaceholder = () => {
    if (formData.typeCode === SectionCodes.civilAffilationGroup) return 'Введите название объединения...';
    if (formData.typeCode === SectionCodes.civilAffilationMovement) return 'Введите название объединения...';
    return '';
  };

  const getCivilActivityNameInputLabel = () => {
    if (formData.typeCode === SectionCodes.civilEventLocal)
      return isMobile ? 'Название мероприятия' : 'Название мероприятия по краеведению';
    if (formData.typeCode === SectionCodes.civilEventEcology)
      return isMobile ? 'Название конференции / фестиваля' : 'Название экологической конференции / фестиваля';
    if (formData.typeCode === SectionCodes.civilEventVolonteer)
      return isMobile ? 'Название конкурса / фестиваля' : 'Название волонтерского/социального конкурса/фестиваля';
    if (formData.typeCode === SectionCodes.civilEventPatriotic)
      return isMobile ? 'Название мероприятия' : 'Название военно-патриотического мероприятия';
    if (formData.typeCode === SectionCodes.civilEventQuest) return 'Название квеста / квиза';
    return '';
  };

  const getCivilActivityNameInputPlaceholder = () => {
    if (formData.typeCode === SectionCodes.civilEventLocal) return 'Введите название мероприятия по краеведению...';
    if (formData.typeCode === SectionCodes.civilEventEcology)
      return 'Введите название экологической конференции / фестиваля...';
    if (formData.typeCode === SectionCodes.civilEventVolonteer)
      return 'Введите название волонтерского/социального конкурса/фестиваля...';
    if (formData.typeCode === SectionCodes.civilEventPatriotic)
      return 'Введите название военно-патриотического мероприятия...';
    if (formData.typeCode === SectionCodes.civilEventQuest) return 'Введите название квеста / квиза...';
    return '';
  };

  const getCivilActivityOrganizatorInputLabel = () => {
    if (isMobile) return 'Организатор';
    if (formData.typeCode === SectionCodes.civilEventLocal) return 'Организатор мероприятия по краеведению';
    if (formData.typeCode === SectionCodes.civilEventEcology)
      return 'Организатор экологической конференции / фестиваля';
    if (formData.typeCode === SectionCodes.civilEventVolonteer)
      return 'Организатор волонтерского/социального конкурса/фестиваля';
    if (formData.typeCode === SectionCodes.civilEventPatriotic) return 'Организатор военно-патриотического мероприятия';
    if (formData.typeCode === SectionCodes.civilEventQuest) return 'Организатор квеста / квиза';
    return '';
  };

  const getCivilActivityOrganizatorInputPlaceholder = () => {
    if (formData.typeCode === SectionCodes.civilEventLocal)
      return 'Введите название организатора мероприятия по краеведению...';
    if (formData.typeCode === SectionCodes.civilEventEcology)
      return 'Введите название организатора экологической конференции / фестиваля...';
    if (formData.typeCode === SectionCodes.civilEventVolonteer)
      return 'Введите название организатора волонтерского/социального конкурса...';
    if (formData.typeCode === SectionCodes.civilEventPatriotic)
      return 'Введите название организатора военно-патриотического мероприятия...';
    if (formData.typeCode === SectionCodes.civilEventQuest) return 'Введите название организатора квеста / квиза...';
    return '';
  };

  const getEventKindData = (): IEventKind[] => {
    switch (formData.typeCode) {
      case SectionCodes.civilEventEcology:
        return ecologyKindData.content;
      case SectionCodes.civilEventLocal:
        return localKindData.content;
      case SectionCodes.civilEventPatriotic:
        return patrioticKindData.content;
      case SectionCodes.civilEventQuest:
        return questKindData.content;
      case SectionCodes.civilEventVolonteer:
        return volonteerKindData.content;
      default:
        return [
          ...ecologyKindData.content,
          ...localKindData.content,
          ...patrioticKindData.content,
          ...questKindData.content,
          ...volonteerKindData.content,
        ];
    }
  };

  const eventKindData = getEventKindData();

  const currentEventKindData = eventKindData.find((activity) => activity.code === formData.name);

  const eventData = {
    type:
      activityTypeData.content?.find((portfolioKind) => portfolioKind.code === currentEventKindData?.categoryCode)
        ?.value || '',
    name: currentEventKindData?.value,
    ageLimit: getAgeLimitFromArray(currentEventKindData?.ageLimits),
    levelEvent: currentEventKindData?.levelEvent
      ? olympiadTypes.content?.find((type) => type.code === currentEventKindData?.levelEvent)?.value
      : undefined,
    levelEventCode: currentEventKindData?.levelEvent,
    organizators: currentEventKindData?.organizators,
  };

  return (
    <>
      {formData.dataKind === SectionCodes.civilAffilation && formData.name && (
        <AffiliationSecondaryBlock
          hasSubCategory
          isSubcategoryRequired
          name={affilationKindData.content.find((name) => name.code === formData.name)?.value || DifferentValue}
          subCategoryOptions={subcategories.content}
          levelOptions={olympiadTypes?.content || []}
          withoutSubjects
          nameInputLabel={getAffiliationNameInputLabel()}
          nameInputPlaceholder={getSecondaryAffiliationNameInputPlaceholder()}
          subjectInputLabel={getAffiliationSubjectInputLabel()}
          subjectInputPlaceholder={getAffiliationSubjectInputPlaceholder()}
        />
      )}
      {formData.dataKind === SectionCodes.civilOccupation && formData.typeCode && (
        <OccupationSecondaryBlock
          subCategoryOptions={subcategories.content}
          withoutSubjects
          nameInputLabel="Название кружка"
          nameInputPlaceholder="Введите название кружка..."
          subjectInputLabel={
            isMobile ? 'Направление активности' : 'Направление гражданской активности (одно или несколько)'
          }
          subjectInputPlaceholder="Начните вводить название направления гражданской активности..."
        />
      )}
      {formData.dataKind === SectionCodes.civilEvent && formData.name && (
        <CivilActivitySecondaryBlock
          eventData={eventData}
          eventLevelOptions={olympiadTypes?.content || []}
          inputLabel={getCivilActivityNameInputLabel()}
          inputPlaceholder={getCivilActivityNameInputPlaceholder()}
          organizatorLabel={getCivilActivityOrganizatorInputLabel()}
          organizatorPlaceholder={getCivilActivityOrganizatorInputPlaceholder()}
        />
      )}
      {formData.dataKind === SectionCodes.civilReward &&
        formData.typeCode === SectionCodes.civilRewardStatus &&
        formData.name && (
          <CivilRankRewardSecondaryBlock
            name={rewardKindData.content?.find((activity) => activity.code === formData.name)?.value}
            eventLevelOptions={olympiadTypes?.content || []}
            subCategoryOptions={subcategories.content}
          />
        )}
      {formData.dataKind === SectionCodes.civilReward &&
        formData.typeCode === SectionCodes.civilRewardEvent &&
        formData.reward && (
          <NameRewardSecondaryBlock
            result={rewardKindData.content.find((reward) => reward.code === formData.reward)?.value}
          />
        )}
    </>
  );
};

const portfolioActivityTypeMapping = createSelector(
  [(state: IRootState) => sectionRefArchiveFalseSelector(state)],
  (sections) => ({
    content: sections.content?.filter((section) => section.parentId === SectionCodes.civilEvent) || [],
    loading: sections.loading,
  }),
);

const localKindsMapping = createSelector([(state: IRootState) => state.eventKinds], (eventKinds) => ({
  content: eventKinds?.content?.filter((eventKind) => eventKind.categoryCode === SectionCodes.civilEventLocal) || [],
  loading: eventKinds.loading,
}));

const ecologyKindsMapping = createSelector([(state: IRootState) => state.eventKinds], (eventKinds) => ({
  content: eventKinds?.content?.filter((eventKind) => eventKind.categoryCode === SectionCodes.civilEventEcology) || [],
  loading: eventKinds.loading,
}));

const patrioticKindsMapping = createSelector([(state: IRootState) => state.eventKinds], (eventKinds) => ({
  content:
    eventKinds?.content?.filter((eventKind) => eventKind.categoryCode === SectionCodes.civilEventPatriotic) || [],
  loading: eventKinds.loading,
}));

const questKindsMapping = createSelector([(state: IRootState) => state.eventKinds], (eventKinds) => ({
  content: eventKinds?.content?.filter((eventKind) => eventKind.categoryCode === SectionCodes.civilEventQuest) || [],
  loading: eventKinds.loading,
}));

const volonteerKindsMapping = createSelector([(state: IRootState) => state.eventKinds], (eventKinds) => ({
  content:
    eventKinds?.content?.filter((eventKind) => eventKind.categoryCode === SectionCodes.civilEventVolonteer) || [],
  loading: eventKinds.loading,
}));

const rewardKindsMapping = createSelector([(state: IRootState) => state.rewardKinds], (rewardKinds) => ({
  content:
    rewardKinds.content?.filter(
      (rewardKind) =>
        rewardKind.categoryCode === SectionCodes.civilRewardStatus ||
        rewardKind.categoryCode === SectionCodes.civilRewardEvent,
    ) || [],
  loading: rewardKinds.loading,
}));

const affilationKindsMapping = createSelector([(state: IRootState) => state.affilationKinds], (affilationKinds) => ({
  content:
    affilationKinds.content?.filter(
      (affilationKind) =>
        (affilationKind.categoryCode === SectionCodes.civilAffilationGroup ||
          affilationKind.categoryCode === SectionCodes.civilAffilationMovement) &&
        affilationKind.parentId,
    ) || [],
  loading: affilationKinds.loading,
}));

const subcategoriesMapping = createSelector([(state: IRootState) => state.portfolioSubcategories], (subcategories) => ({
  content:
    subcategories.content?.filter((subcategory) => subcategory?.parentId === SubcategoryParentsCodes.civil) || [],
  loading: subcategories.loading,
}));

export default connect((state: IRootState) => ({
  activityTypeData: portfolioActivityTypeMapping(state),
  localKindData: localKindsMapping(state),
  ecologyKindData: ecologyKindsMapping(state),
  patrioticKindData: patrioticKindsMapping(state),
  questKindData: questKindsMapping(state),
  volonteerKindData: volonteerKindsMapping(state),
  rewardKindData: rewardKindsMapping(state),
  affilationKindData: affilationKindsMapping(state),
  subcategories: subcategoriesMapping(state),
  olympiadTypes: state.olympiadTypes,
}))(CivilSecondaryBlock);
