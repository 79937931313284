import { IChildInfo } from 'api/types';
import { IDeleteDialogData } from 'components/redesignStudent/DataEntity/types';
import { SectionCodes } from 'const';

export const getGroupedEntityActionDeleteText = (typeCode: number | undefined, isMobile: boolean) => {
  switch (typeCode) {
    case SectionCodes.studySpoDocuments:
      return isMobile ? 'Удалить' : 'Удалить документ';
    default:
      return 'Удалить';
  }
};

/**
 * Возвращает JSX с информационным сообщением по удаляемой сущности
 *
 * @param currentStudent информация об ученике
 * @param deleteDialogData объект с данными диалогового окна удаления сущности
 * @param isStudent признак роли учащегося
 * @returns JSX
 */
export const renderDeleteEntityDialogText = (
  currentStudent: IChildInfo,
  deleteDialogData: IDeleteDialogData | undefined,
  isStudent: boolean,
) => {
  if (isStudent) {
    return renderStudentDeleteDialogText(deleteDialogData);
  }

  return renderCommonDeleteDialogText(currentStudent, deleteDialogData);
};

function getDeleteDialogAdditionalText(deleteDialogData: IDeleteDialogData | undefined) {
  if (!deleteDialogData?.linkedEntityNames) return null;

  return (
    <>
      Нам также придется удалить {deleteDialogData?.linkedEntityTypeName} <b>{deleteDialogData?.linkedEntityNames}</b>.
    </>
  );
}

function renderStudentDeleteDialogText(deleteDialogData: IDeleteDialogData | undefined) {
  return (
    <>
      Ты точно хочешь удалить {deleteDialogData?.typeName} <b>{deleteDialogData?.name}</b> из своего портфолио?{' '}
      {getDeleteDialogAdditionalText(deleteDialogData)}
    </>
  );
}

function renderCommonDeleteDialogText(currentStudent: IChildInfo, deleteDialogData: IDeleteDialogData | undefined) {
  return (
    <>
      {/* eslint-disable-next-line max-len */}
      Вы уверены, что хотите удалить {deleteDialogData?.typeName} <b>{deleteDialogData?.name}</b> из портфолио учащегося{' '}
      <b>{`${currentStudent.firstname} ${currentStudent.lastname}`}</b>?{' '}
      {getDeleteDialogAdditionalText(deleteDialogData)}
    </>
  );
}
