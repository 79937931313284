import { FC, useEffect, useMemo } from 'react';
import { useCalendar } from 'react-aria';
import { CalendarState, CalendarStateOptions, useCalendarState } from 'react-stately';

import { CalendarDate, createCalendar } from '@internationalized/date';
import { Box, Stack } from '@mui/material';
import { makeRange, splitChunks } from 'utils';

import { YEARS_PER_PAGE, YEARS_PER_ROW } from '../../const';
import CalendarHeader from '../../shared/CalendarHeader';
import { dateToCalendarDate, getDateFromYear } from '../../utils';
import YearGrid from '../Grid';

const getCurrentChunk = (currentValue: number | null, minValue: number, maxValue: number, chunkSize: number) => {
  const currentYear = new Date().getFullYear();

  if (!currentValue && currentYear >= minValue && currentYear <= maxValue) {
    return Math.floor((currentYear - minValue) / chunkSize);
  }

  if (currentValue && currentValue >= minValue && currentValue <= maxValue) {
    return Math.floor((currentValue - minValue) / chunkSize);
  }

  return 0;
};

const focusYear = (state: CalendarState, nextYear: number | undefined) => {
  const minYear = state.minValue?.year ?? new Date().getFullYear();
  const maxYear = state.maxValue?.year ?? 2050;

  const isValidYear = nextYear && (!minYear || nextYear >= minYear) && (!maxYear || nextYear <= maxYear);

  if (isValidYear) {
    const nextFocusedDate = dateToCalendarDate(getDateFromYear(nextYear));
    state.setFocusedDate(nextFocusedDate);
  }
};

interface IYearCalendarProps {
  calendarOptions: Partial<CalendarStateOptions>;
}

const YearCalendar: FC<IYearCalendarProps> = ({ calendarOptions }) => {
  const calendarStateOptions = {
    ...calendarOptions,
    locale: 'ru-RU',
    createCalendar,
  };

  const minYear = calendarOptions.minValue?.year ?? new Date().getFullYear();
  const maxYear = calendarOptions.maxValue?.year ?? 2050;
  const startDate = new CalendarDate(minYear, 1, 1);
  const endDate = new CalendarDate(maxYear, 1, 1);

  const state = useCalendarState(calendarStateOptions);
  const modifiedCalendarState: CalendarState = {
    ...state,
    visibleRange: {
      start: startDate,
      end: endDate,
    },
    isCellDisabled: () => false,
    focusNextDay() {
      focusYear(this, this.focusedDate.year + 1);
    },
    focusPreviousDay() {
      focusYear(this, this.focusedDate.year - 1);
    },
    focusNextRow() {
      focusYear(this, this.focusedDate.year + YEARS_PER_ROW);
    },
    focusPreviousRow() {
      focusYear(this, this.focusedDate.year - YEARS_PER_ROW);
    },
    focusNextPage() {
      const nextYearsChunk = yearChunks[currentChunkIndex + 1];
      const nextYear = nextYearsChunk?.[0];
      focusYear(this, nextYear);
    },
    focusPreviousPage() {
      const nextYearsChunk = yearChunks[currentChunkIndex - 1];
      const nextYear = nextYearsChunk?.[0];
      focusYear(this, nextYear);
    },
  };

  const { calendarProps, prevButtonProps, nextButtonProps } = useCalendar(calendarStateOptions, modifiedCalendarState);

  const { focusedDate, setFocusedDate, value } = modifiedCalendarState;
  const currentFocusYear = focusedDate?.year ?? null;

  const yearChunks = useMemo(() => splitChunks(makeRange(minYear, maxYear), YEARS_PER_PAGE), [maxYear, minYear]);
  const currentChunkIndex = getCurrentChunk(currentFocusYear, minYear, maxYear, YEARS_PER_PAGE);
  const currentChunk = yearChunks[currentChunkIndex];
  const currentStartYear = currentChunk[0];
  const currentEndYear = currentChunk[currentChunk.length - 1];

  const title = `${currentStartYear}-${currentEndYear}`;

  // установка нужной даты при открытии календаря без выбранного значения
  useEffect(() => {
    if (value === null) {
      const year = new Date().getFullYear();
      setFocusedDate(new CalendarDate(year, 1, 1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack className="year-calendar" {...calendarProps} alignItems="center">
      <CalendarHeader title={title} prevButtonProps={prevButtonProps} nextButtonProps={nextButtonProps} />
      <Box sx={{ marginTop: '12px' }}>
        <YearGrid calendarState={modifiedCalendarState} startYear={currentStartYear} endYear={currentEndYear} />
      </Box>
    </Stack>
  );
};

export default YearCalendar;
