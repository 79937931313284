import React from 'react';

import { Box, Stack, Typography } from '@mui/material';
import SmallActionsButton from 'portfolio3/components/common/buttons/SmallActionsButton';
import Table from 'portfolio3/components/common/Table';
import { NeutralColors } from 'portfolio3/styles';

import { IconRestoreDeleted } from '../../../../icons';
import { Tooltip } from '../../../../ui-kit';
import { IMappedAdminDeleteHistory } from '../types';
import * as styles from './styles';

interface IAdminDeletedItem extends IMappedAdminDeleteHistory {
  onRestoreEntity: (entityId: number, entityType: string) => void;
}

const AdminDeletedItem: React.FC<IAdminDeletedItem> = ({
  entityId,
  entityType,
  entityName,
  student,
  deleter,
  deleterRole,
  dataKind,
  deletingDate,
  icon,
  onRestoreEntity,
}) => {
  const density = 'medium';

  return (
    <Table.Row>
      <Table.DataCell density={density}>
        <Typography variant="Paragraph MD/Regular">{student}</Typography>
      </Table.DataCell>
      <Table.DataCell density={density}>
        <Box sx={styles.entityNameContainer}>
          {icon}
          <Typography variant="Paragraph MD/Regular">{dataKind}</Typography>
        </Box>
      </Table.DataCell>
      <Table.DataCell density={density}>
        <Typography variant="Paragraph MD/Regular">{entityName}</Typography>
      </Table.DataCell>
      <Table.DataCell density={density}>
        <Stack gap="4px">
          <Typography variant="Paragraph MD/Regular">{deleter}</Typography>
          <Typography variant="Paragraph MD/Regular" color={NeutralColors.light_neutrals_600}>
            {deleterRole}
          </Typography>
        </Stack>
      </Table.DataCell>
      <Table.DataCell density={density}>
        <Box sx={styles.dateContainer}>
          <Typography variant="Paragraph MD/Regular">{deletingDate}</Typography>
        </Box>
      </Table.DataCell>
      <Table.DataCell density={8}>
        <Tooltip title="Восстановить запись" arrow placement="left" width="147px">
          <SmallActionsButton onClick={() => onRestoreEntity(entityId, entityType)}>
            <IconRestoreDeleted />
          </SmallActionsButton>
        </Tooltip>
      </Table.DataCell>
    </Table.Row>
  );
};

export default AdminDeletedItem;
