/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';

import {
  FAILURE,
  INVALIDATE,
  REQUEST,
  SUCCESS
} from '../../actions/utils';

import { ICommonResponseObject, IPersonsList } from '../../api/types';

import genericReducers from '../genericReducer';

import { IReducers } from '..';

import { GET_PERSONS_LIST } from '../../actions';

export type PersonsListState = ICommonResponseObject<IPersonsList | null>;

const initialState: PersonsListState = {
  content: null,
  loading: false,
};

const personsList: IReducers<PersonsListState> = {
  [GET_PERSONS_LIST[REQUEST]]: genericReducers().get.request,
  [GET_PERSONS_LIST[SUCCESS]]: genericReducers().get.success,
  [GET_PERSONS_LIST[FAILURE]]: genericReducers().get.failure,
  [GET_PERSONS_LIST[INVALIDATE]]: genericReducers().get.invalidate(initialState),
};

export default connectReducers(initialState, personsList);
