/* eslint-disable prettier/prettier */
/* eslint-disable */

import connectReducers from '../utils/connectReducers';

import {
  SET_CURRENT_STUDENT
} from '../actions';

import { IChildInfo } from '../api/types';

export type TCurrentStudentState = IChildInfo;

export interface ICurrentStudentReducers {
    [key: string]: (state: TCurrentStudentState, action: any) => TCurrentStudentState;
}

const initialState = {} as IChildInfo;

const currentStudent: ICurrentStudentReducers = {
  [SET_CURRENT_STUDENT]: (state, { payload: currentStudent }: { payload: IChildInfo }) => ({
    ...state,
    ...currentStudent
  })
};

export default connectReducers(initialState, currentStudent);
