import { SpoStatusCodes } from 'const';

import { GET_STUDENT_SPO_STATUS, SET_STUDENT_SPO_STATUS, setStudentSpoStatusAction } from '../../actions';
import { FAILURE, INVALIDATE, REQUEST, SUCCESS } from '../../actions/utils';
import { ICommonResponseObject, SpoStatusRefItem } from '../../api/types';
import { connectReducers } from '../../utils';
import { IReducers } from '..';
import genericReducers from '../genericReducer';

type SpoStatus = {
  code: number;
};

type SetSpoStatusCodeAction = {
  payload: Parameters<typeof setStudentSpoStatusAction>;
};

export type StudentSpoStatusState = ICommonResponseObject<SpoStatusRefItem | null> & SpoStatus;

const initialState: StudentSpoStatusState = {
  code: SpoStatusCodes.notLookingForJob,
  content: null,
  loading: false,
};

const studentSpoStatus: IReducers<StudentSpoStatusState> = {
  [GET_STUDENT_SPO_STATUS[REQUEST]]: genericReducers().get.request,
  [GET_STUDENT_SPO_STATUS[SUCCESS]]: genericReducers().get.success,
  [GET_STUDENT_SPO_STATUS[FAILURE]]: genericReducers().get.failure,
  [GET_STUDENT_SPO_STATUS[INVALIDATE]]: genericReducers().get.invalidate(initialState),
  [SET_STUDENT_SPO_STATUS]: (state, { payload }: SetSpoStatusCodeAction) => {
    const [code] = payload;
    return {
      ...state,
      code: code ?? SpoStatusCodes.notLookingForJob,
    };
  },
};

export default connectReducers(initialState, studentSpoStatus);
