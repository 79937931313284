/* eslint-disable prettier/prettier */
/* eslint-disable */

import connectReducers from '../utils/connectReducers';

import {
  SET_LINK_ERROR
} from '../actions';

import { IReducers } from '.';
import { ILinkError } from '../api/types';

export type LinkErrorState = ILinkError

const initialState = {
  status: false
};

const linkError: IReducers<LinkErrorState> = {
  [SET_LINK_ERROR]: (state, { payload }) => ({
    ...state,
    ...payload
  })
};

export default connectReducers(initialState, linkError);
