import {
  EIS_DOP_RECOMMENDATIONS_DRAWER_SET_OPEN,
  EIS_DOP_RECOMMENDATIONS_DRAWER_SET_RECOMMENDATIONS,
  EIS_DOP_RECOMMENDATIONS_DRAWER_SET_TITLE,
} from 'actions';
import { IReducers } from 'reducers';
import { IMappedEisDopRecommendation } from 'types';
import { connectReducers } from 'utils';

export type EisDopRecommendationsDrawerState = {
  isOpen: boolean;
  recommendations: IMappedEisDopRecommendation[];
  title: string;
};

type SetOpenAction = { payload: EisDopRecommendationsDrawerState['isOpen'] };
type SetRecommendationsAction = { payload: EisDopRecommendationsDrawerState['recommendations'] };
type SetTitleAction = { payload: EisDopRecommendationsDrawerState['title'] };

const initialState: EisDopRecommendationsDrawerState = {
  isOpen: false,
  recommendations: [],
  title: '',
};

const eisDopRecommendationsDrawer: IReducers<EisDopRecommendationsDrawerState> = {
  [EIS_DOP_RECOMMENDATIONS_DRAWER_SET_OPEN]: (state, { payload }: SetOpenAction) => {
    return {
      ...state,
      isOpen: payload,
    };
  },
  [EIS_DOP_RECOMMENDATIONS_DRAWER_SET_RECOMMENDATIONS]: (state, { payload }: SetRecommendationsAction) => {
    return {
      ...state,
      recommendations: payload,
    };
  },
  [EIS_DOP_RECOMMENDATIONS_DRAWER_SET_TITLE]: (state, { payload }: SetTitleAction) => {
    return {
      ...state,
      title: payload,
    };
  },
};

export default connectReducers(initialState, eisDopRecommendationsDrawer);
