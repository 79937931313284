import { createSelector } from 'reselect';
import { IUserFunctionality } from 'types';
import { getUserFunctionality, isDefined } from 'utils';

import { IRootState } from '../../reducers';

export const userFunctionalitySelector = createSelector(
  [(state: IRootState) => state.currentUser.data],
  (currentUserData): IUserFunctionality => {
    const globalRole = currentUserData?.currentGlobalRole?.globalRoleTag?.globalRoleTagCode || '';
    return getUserFunctionality(globalRole);
  },
);

export const currentLocalRoleSelector = createSelector(
  [(state: IRootState) => state.currentUser.data],
  (currentUserData) => {
    const subsystemRoles = currentUserData?.currentGlobalRole?.availableSubsystemRoles;
    return subsystemRoles?.[0] ?? null;
  },
);

export const userLocalRolesSelector = createSelector(
  [(state: IRootState) => state.currentUser.data],
  (currentUserData) => {
    const subsystemRoles = currentUserData?.currentGlobalRole?.availableSubsystemRoles;
    const localRoles = subsystemRoles?.map((role) => role.title).filter(isDefined) ?? [];
    return localRoles;
  },
);
