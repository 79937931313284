import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { IconClose } from 'icons/edit';
import { NeutralColors } from 'portfolio3/styles';
import { Button } from 'portfolio3/ui-kit/button';
import ModalBase from 'portfolio3/ui-kit/modals/ModalBase';
import { getButtonStyles } from 'portfolio3/ui-kit/utils';

import * as styles from './styles';

interface IProps {
  open: boolean;
  close: () => void;
  handleConfirm: () => void;
}

const PersonalAccessModal: FC<IProps> = ({ open, close, handleConfirm }) => {
  return (
    <>
      <ModalBase open={open}>
        <Box sx={styles.personalData}>
          <Box sx={styles.modalHeader}>
            <Typography variant="Headings/H4">Согласие на обработку персональных данных</Typography>
            <Box sx={{ ...getButtonStyles() }} onClick={close}>
              <IconClose fill={NeutralColors.light_neutrals_600} />
            </Box>
          </Box>
          <Box sx={styles.modalContent}>
            <Typography variant="Paragraph LG/Regular">
              Мы хотим помочь тебе в поиске работы или места для практики, чтобы мы смогли <br /> это сделать, нам нужно
              твое согласие на то, что мы можем делиться твоим портфолио <br /> с потенциальными работодателями. Мы
              будем делиться твоим портфолио только <br /> при статусах: “Активно ищу работу” и “Ищу место для
              практики”.
            </Typography>
            <Box marginTop="8px">
              <Typography
                component="a"
                href="/personalAccessAgreement.pdf"
                download="Согласие_на_обработку_ПД_портфолио_учащегося"
                variant="Paragraph LG/Semi Bold"
                sx={styles.modalTextDownload}
              >
                Полный текст согласия на обработку персональных данных
              </Typography>
            </Box>
          </Box>
          <Box sx={styles.modalFooter}>
            <Box sx={styles.buttonsGroup}>
              <Button variant="secondary" onClick={close}>
                Отмена
              </Button>
              <Button variant="primary" onClick={handleConfirm}>
                Согласен
              </Button>
            </Box>
          </Box>
        </Box>
      </ModalBase>
    </>
  );
};

export default PersonalAccessModal;
