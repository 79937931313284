import { ICommonResponse } from 'api/types';
import { EntityType, SectionCodes, SourceCode } from 'const';
import { cardIllustrations } from 'images';
import { SectionRefState } from 'reducers/reference';
import { StudentCheckInHistoryState } from 'reducers/student/checkInHistory';
import { CultureVisitingsState } from 'reducers/student/cultureVisitings';
import { StudentEventsState } from 'reducers/student/events';
import { mapCheckInToEntity, mapCultureEventToEntity, mapCultureVisitingToEntity } from 'selectors/entityMappers';
import { IEntity } from 'types';
import {
  flagsMap,
  getDateRange,
  getEntityFeatures,
  getEntityGeneralInfo,
  getOnlineCultureEntityBackgroundImage,
  getYearFromDate,
} from 'utils';

export const mapCultureOnlineEventsResponse = (
  events: StudentEventsState,
  sectionRef: SectionRefState,
): ICommonResponse<IEntity> => {
  const onlineEvents = events.content
    .filter((event) => event.category?.code === SectionCodes.culture && event.typeCode === SectionCodes.cultureOnline)
    .map((event): IEntity => {
      const {
        id,
        recordId,
        name,
        source,
        startDate,
        endDate,
        typeCode,
        levelEvent,
        format: eventFormat,
        eventKindCode,
        locationName,
      } = event;

      const entityTypes = locationName ? [locationName] : undefined;
      const entityFeatures = getEntityFeatures(sectionRef.content, typeCode, levelEvent, eventFormat);

      return {
        id,
        recordId,
        name,
        types: entityTypes,
        date: getDateRange(startDate, endDate),
        year: getYearFromDate(endDate || startDate),
        features: entityFeatures,
        backgroundImage: getOnlineCultureEntityBackgroundImage(eventKindCode),
        formData: event,
        cardData: {
          name,
          generalInfo: getEntityGeneralInfo(event),
          files: event.fileReferences,
          linkedObjects: event.linkedObjects,
          formData: event,
          illustrationFallback: cardIllustrations.cultureOnlineVisiting,
        },
        entityProps: {
          type: EntityType.CULTURAL_EVENT,
          flags: flagsMap({ approved: source.code !== SourceCode.student && source.code !== SourceCode.parent }),
        },
      };
    });

  return {
    ...events,
    content: onlineEvents,
  };
};

export const mapCultureVisitingsResponse = (
  events: StudentEventsState,
  checkinHistory: StudentCheckInHistoryState,
  cultureVisitings: CultureVisitingsState,
  sectionRef: SectionRefState,
): ICommonResponse<IEntity> => {
  const mappedStudentEvents = events.content
    .filter((event) => event.category?.code === SectionCodes.culture && event.typeCode === SectionCodes.cultureVisiting)
    .map((event): IEntity => {
      return mapCultureEventToEntity(sectionRef.content, event);
    });

  const mappedCheckIns = checkinHistory.content
    .filter((checkIn) => !checkIn.checkInHistory.isDelete)
    .map((checkIn): IEntity => {
      return mapCheckInToEntity(sectionRef.content, checkIn.checkInHistory, checkIn.attachments);
    });

  const mappedCultureVisitings = cultureVisitings.content.map((cultureVisiting): IEntity => {
    return mapCultureVisitingToEntity(cultureVisiting);
  });

  const isDataLoading = events.loading || checkinHistory.loading || cultureVisitings.loading;

  return {
    ...events,
    loading: isDataLoading,
    content: isDataLoading
      ? []
      : [...mappedCheckIns, ...mappedStudentEvents, ...mappedCultureVisitings].sort(
          (prevEvent, nextEvent) =>
            Number(new Date(nextEvent.formData.startDate)) - Number(new Date(prevEvent.formData.startDate)),
        ),
  };
};
