import { FC, ReactElement, useContext, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import { Box, FormHelperText } from '@mui/material';
import { IChildInfo } from 'api/types';
import { LocalDataEntryFormContext } from 'components/common/PortfolioDataEntryForm/context/localDataEntryFormContext';
import { getDayPickerControllerFactory } from 'components/common/PortfolioDataEntryForm/controllers';
import {
  getCommonInputControllerFactory,
  getCommonSelectControllerFactory,
  getDictionaryAutocompleteControllerFactory,
} from 'components/common/PortfolioDataEntryForm/utils';
import { DifferentCode, DifferentValue, documentPlaceDictionary } from 'const';
import CodeNameControl from 'portfolio3/components/forms/CodeNameControl';
import { commonAcceptFileTypes } from 'portfolio3/features/dataEntryForm';
import { BaseInputLabel, FormControl, Input, NumberInput } from 'portfolio3/ui-kit';
import { DayPicker } from 'portfolio3/ui-kit/datePickers';
import { Select } from 'portfolio3/ui-kit/selects';
import { IRootState } from 'reducers';
import {
  ProfessionProgrammRefState,
  ProfessionRankRefState,
  SpoGiaMarkRefState,
  SpoOrganizationRefState,
} from 'reducers/reference';
import { deepSetProperty } from 'utils';

import { SecondaryBlockContainer, useSecondaryBlockControllers } from '../../common';
import { StudySpoDocumentFields } from './utils';

interface IStudySpoDocumentBlockProps {
  fields: Partial<Record<StudySpoDocumentFields, boolean>>;

  currentStudent: IChildInfo;
  spoOrganizationRef: SpoOrganizationRefState;
  professionProgrammRef: ProfessionProgrammRefState;
  spoGiaMarkRef: SpoGiaMarkRefState;
  professionRankRef: ProfessionRankRefState;
}

const StudySpoDocumentBlock: FC<IStudySpoDocumentBlockProps> = ({
  currentStudent,
  fields,
  spoOrganizationRef,
  professionProgrammRef,
  spoGiaMarkRef,
  professionRankRef,
}) => {
  const { isMobile, inputRenderMode, inputSize, formData, formErrors, onChangeFormData, onChangeFormErrors } =
    useContext(LocalDataEntryFormContext);

  const inputController = getCommonInputControllerFactory(onChangeFormData, onChangeFormErrors);
  const selectController = getCommonSelectControllerFactory(onChangeFormData, onChangeFormErrors);
  const dayPickerControllerFactory = getDayPickerControllerFactory(onChangeFormData, onChangeFormErrors);
  const dictionaryAutocompleteController = getDictionaryAutocompleteControllerFactory(
    onChangeFormData,
    onChangeFormErrors,
  );

  const data = formData.studySpoDocument;
  const errors = formErrors.studySpoDocumentErrors;

  const controlMap = new Map<StudySpoDocumentFields, ReactElement>();

  const { fileController } = useSecondaryBlockControllers({
    formData,
    onChangeFormData,
    onChangeFormErrors,
  });

  // очистка второго контрола в CodeNameControl
  useEffect(() => {
    if (!data?.organizationCode) {
      onChangeFormData((prevState) => deepSetProperty(prevState, 'studySpoDocument.organizationName', undefined));
    }
    if (!data?.professionalProgrammCode) {
      onChangeFormData((prevState) =>
        deepSetProperty(prevState, 'studySpoDocument.professionalProgrammName', undefined),
      );
    }
    if (!data?.docPlace) {
      onChangeFormData((prevState) => deepSetProperty(prevState, 'studySpoDocument.docPlaceOther', undefined));
    }
  }, [data?.docPlace, data?.organizationCode, data?.professionalProgrammCode, onChangeFormData]);

  // Название документа
  const isDocumentNameControlRequired = fields.documentName;
  const isDocumentNameControlError = isDocumentNameControlRequired && errors.documentName;
  const documentNameController = useMemo(
    () => inputController('studySpoDocument.documentName', 'studySpoDocumentErrors.documentName'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData.id],
  );
  const documentNameControl = (
    <FormControl
      required={isDocumentNameControlRequired}
      error={isDocumentNameControlError}
      renderMode={inputRenderMode}
      inputSize={inputSize}
      label={<BaseInputLabel>Название документа</BaseInputLabel>}
      helper={isDocumentNameControlError && <FormHelperText>Введите название документа</FormHelperText>}
      control={
        <Input
          renderMode={inputRenderMode}
          size={inputSize}
          placeholder="Введи название документа..."
          value={data?.documentName}
          controller={documentNameController}
        />
      }
    />
  );
  controlMap.set('documentName', documentNameControl);

  // Название программы обучения
  const isEducationProgrammControlRequired = fields.educationProgramm;
  const isEducationProgrammControlError = isEducationProgrammControlRequired && errors.educationProgramm;
  const educationProgrammController = useMemo(
    () => inputController('studySpoDocument.educationProgramm', 'studySpoDocumentErrors.educationProgramm'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData.id],
  );
  const educationProgrammControl = (
    <FormControl
      required={isEducationProgrammControlRequired}
      error={isEducationProgrammControlError}
      renderMode={inputRenderMode}
      inputSize={inputSize}
      label={<BaseInputLabel>Название программы обучения</BaseInputLabel>}
      helper={isEducationProgrammControlError && <FormHelperText>Введите программу обучения</FormHelperText>}
      control={
        <Input
          renderMode={inputRenderMode}
          size={inputSize}
          placeholder="Введи название программы обучения..."
          value={data?.educationProgramm}
          controller={educationProgrammController}
        />
      }
    />
  );
  controlMap.set('educationProgramm', educationProgrammControl);

  // Название организации
  const isOrganizationControlRequired = fields.organization;
  const isOrganizationControlError = isOrganizationControlRequired && errors.organization;
  const organizationDictionary = useMemo(
    () => [...spoOrganizationRef.content, { code: DifferentCode, value: DifferentValue }],
    [spoOrganizationRef.content],
  );
  const organizationOptions = useMemo(() => organizationDictionary.map((item) => item.value), [organizationDictionary]);
  const organizationDictionaryItem = organizationDictionary.find((item) => item.code === data?.organizationCode);
  const organizationCodeController = useMemo(
    () =>
      dictionaryAutocompleteController(
        organizationDictionary,
        'code',
        formData,
        'studySpoDocument.organizationCode',
        'studySpoDocumentErrors.organization',
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData, organizationDictionary],
  );
  const organizationNameController = useMemo(
    () => inputController('studySpoDocument.organizationName', 'studySpoDocumentErrors.organization'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData.id],
  );
  const organizationErrorLabel = data?.organizationCode ? 'Введите название организации' : 'Выберите организацию';
  const organizationControl = (
    <CodeNameControl
      isMobile={isMobile}
      inputSize={inputSize}
      inputRenderMode={inputRenderMode}
      required={isOrganizationControlRequired}
      error={isOrganizationControlError}
      errorLabel={organizationErrorLabel}
      label="Название организации"
      codeOptions={organizationOptions}
      codeValue={organizationDictionaryItem?.value}
      codeController={organizationCodeController}
      codePlaceholder="Начни вводить название организации..."
      nameValue={data?.organizationName}
      nameController={organizationNameController}
      namePlaceholder="Введи название организации..."
      isNameControlShown={data?.organizationCode === DifferentCode}
    />
  );
  controlMap.set('organization', organizationControl);

  // Название программы профессионального обучения
  const isProfessionalProgrammControlRequired = fields.professionalProgramm;
  const isProfessionalProgrammControlError = isProfessionalProgrammControlRequired && errors.professionalProgramm;
  const professionalProgrammDictionary = useMemo(
    () => [
      ...professionProgrammRef.content.map((item) => ({
        ...item,
        value: `${item.value} ${item.profCode}`,
      })),
      { code: DifferentCode, value: DifferentValue },
    ],
    [professionProgrammRef.content],
  );
  const professionalProgrammOptions = useMemo(
    () => professionalProgrammDictionary.map((item) => item.value),
    [professionalProgrammDictionary],
  );
  // useMemo из-за большого массива данных
  const professionalProgrammDictionaryItem = useMemo(() => {
    return professionalProgrammDictionary.find((item) => item.code === data?.professionalProgrammCode);
  }, [data?.professionalProgrammCode, professionalProgrammDictionary]);
  const professionalProgrammCodeController = useMemo(
    () =>
      dictionaryAutocompleteController(
        professionalProgrammDictionary,
        'code',
        formData,
        'studySpoDocument.professionalProgrammCode',
        'studySpoDocumentErrors.professionalProgramm',
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData, professionalProgrammDictionary],
  );
  const professionalProgrammNameController = useMemo(
    () => inputController('studySpoDocument.professionalProgrammName', 'studySpoDocumentErrors.professionalProgramm'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData.id],
  );
  const professionalProgrammErrorLabel = data?.professionalProgrammCode
    ? 'Введите название программы профессионального обучения'
    : 'Выберите программу профессионального обучения';
  const professionalProgrammControl = (
    <CodeNameControl
      isMobile={isMobile}
      inputSize={inputSize}
      inputRenderMode={inputRenderMode}
      required={isProfessionalProgrammControlRequired}
      error={isProfessionalProgrammControlError}
      errorLabel={professionalProgrammErrorLabel}
      label="Название программы профессионального обучения"
      codeOptions={professionalProgrammOptions}
      codeValue={professionalProgrammDictionaryItem?.value}
      codeController={professionalProgrammCodeController}
      codePlaceholder="Начни вводить название программы профессионального обучения..."
      nameValue={data?.professionalProgrammName}
      nameController={professionalProgrammNameController}
      namePlaceholder="Введи название программы профессионального обучения..."
      isNameControlShown={data?.professionalProgrammCode === DifferentCode}
    />
  );
  controlMap.set('professionalProgramm', professionalProgrammControl);

  useEffect(() => {
    // при выборе этого поля (кроме другое) нужно предзаполнять поле квалификация
    // со значением value из этого справочника
    const selectedCode = professionalProgrammDictionaryItem?.code;
    if (selectedCode && selectedCode !== DifferentCode) {
      const programm = professionProgrammRef.content.find((item) => item.code === selectedCode);
      if (programm) {
        onChangeFormData((prevState) => {
          return deepSetProperty(prevState, 'studySpoDocument.profession', programm.value);
        });
      }
    }
  }, [onChangeFormData, professionProgrammRef.content, professionalProgrammDictionaryItem?.code]);

  // Объем часов
  const isHoursControlRequired = fields.hours;
  const isHoursControlError = isHoursControlRequired && errors.hours;
  const hoursController = useMemo(
    () => inputController('studySpoDocument.hours'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData.id],
  );
  const hoursControl = (
    <FormControl
      sx={{ width: isMobile ? 'auto' : '220px' }}
      required={isHoursControlRequired}
      error={isHoursControlError}
      renderMode={inputRenderMode}
      inputSize={inputSize}
      label={<BaseInputLabel>Объем часов</BaseInputLabel>}
      helper={isHoursControlError && <FormHelperText>Введите объем часов</FormHelperText>}
      control={
        <NumberInput
          renderMode={inputRenderMode}
          size={inputSize}
          placeholder="0"
          value={data?.hours}
          controller={hoursController}
          min={0}
        />
      }
    />
  );
  controlMap.set('hours', hoursControl);

  // Регистрационный номер
  const isRegNumControlRequired = fields.regNum;
  const isRegNumControlError = isRegNumControlRequired && errors.regNum;
  const regNumController = useMemo(
    () => inputController('studySpoDocument.regNum', 'studySpoDocumentErrors.regNum'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData.id],
  );
  const regNumControl = (
    <FormControl
      required={isRegNumControlRequired}
      error={isRegNumControlError}
      renderMode={inputRenderMode}
      inputSize={inputSize}
      label={<BaseInputLabel>Регистрационный №</BaseInputLabel>}
      helper={isRegNumControlError && <FormHelperText>Введите Регистрационный номер</FormHelperText>}
      control={
        <Input
          renderMode={inputRenderMode}
          size={inputSize}
          placeholder="Введи регистрационный номер..."
          value={data?.regNum}
          controller={regNumController}
        />
      }
    />
  );
  controlMap.set('regNum', regNumControl);

  // Дата выдачи
  const isDocDateControlRequired = fields.docDate;
  const isDocDateControlError = isDocDateControlRequired && errors.docDate.active;
  const docDateController = dayPickerControllerFactory('studySpoDocument.docDate', 'studySpoDocumentErrors.docDate', {
    disableFuture: true,
    required: isDocDateControlRequired,
  });
  const docDateControl = (
    <FormControl
      required={isDocDateControlRequired}
      renderMode={inputRenderMode}
      inputSize={inputSize}
      label={<BaseInputLabel>Дата выдачи</BaseInputLabel>}
      error={isDocDateControlError}
      helper={isDocDateControlError && <FormHelperText>{errors.docDate.description}</FormHelperText>}
      control={
        <Box sx={{ width: isMobile ? 'auto' : '220px' }}>
          <DayPicker
            isMobile={isMobile}
            renderMode={inputRenderMode}
            size={inputSize}
            controller={docDateController}
            value={data?.docDate ?? null}
            maxValue={new Date()}
          />
        </Box>
      }
    />
  );
  controlMap.set('docDate', docDateControl);

  // Город выдачи
  const isDocPlaceControlRequired = true;
  const isDocPlaceControlError = isDocPlaceControlRequired && errors.docPlace;
  const docPlaceOptions = documentPlaceDictionary.map((item) => item.value);
  const docPlaceValueController = useMemo(
    () =>
      dictionaryAutocompleteController(
        documentPlaceDictionary,
        'value',
        formData,
        'studySpoDocument.docPlace',
        'studySpoDocumentErrors.docPlace',
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData, documentPlaceDictionary],
  );
  const docPlaceOtherController = useMemo(
    () => inputController('studySpoDocument.docPlaceOther', 'studySpoDocumentErrors.docPlace'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData.id],
  );
  const docPlaceErrorLabel = data?.docPlace ? 'Введите город выдачи' : 'Выберите город выдачи';
  const docPlaceControl = (
    <CodeNameControl
      isMobile={isMobile}
      inputSize={inputSize}
      inputRenderMode={inputRenderMode}
      required={isDocPlaceControlRequired}
      error={isDocPlaceControlError}
      errorLabel={docPlaceErrorLabel}
      label="Город выдачи"
      codeOptions={docPlaceOptions}
      codeValue={data?.docPlace}
      codeController={docPlaceValueController}
      codePlaceholder="Выбери город..."
      nameValue={data?.docPlaceOther}
      nameController={docPlaceOtherController}
      namePlaceholder="Введи название города выдачи..."
      isNameControlShown={data?.docPlace === DifferentValue}
    />
  );
  controlMap.set('docPlace', docPlaceControl);

  // Итоговая аттестация (квалификационный экзамен)
  const isExamMarkControlRequired = fields.examMark;
  const isExamMarkControlError = isExamMarkControlRequired && errors.examMark;
  const examMarkController = useMemo(
    () => selectController('studySpoDocument.examMarkCode', 'studySpoDocumentErrors.examMark'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData.id],
  );
  const examMarkControl = (
    <FormControl
      required={isExamMarkControlRequired}
      error={isExamMarkControlError}
      renderMode={inputRenderMode}
      inputSize={inputSize}
      label={<BaseInputLabel>Итоговая аттестация (квалификационный экзамен)</BaseInputLabel>}
      helper={isExamMarkControlError && <FormHelperText>Выберите результат итоговой аттестации</FormHelperText>}
      control={
        <Select
          inputRenderMode={inputRenderMode}
          inputSize={inputSize}
          placeholder="Выбери результат итоговой аттестации..."
          value={data?.examMarkCode}
          options={spoGiaMarkRef.content}
          controller={examMarkController}
          strictMaxWidth
        />
      }
    />
  );
  controlMap.set('examMark', examMarkControl);

  // Квалификация
  const isProfessionControlRequired = fields.profession;
  const isProfessionControlError = isProfessionControlRequired && errors.profession;
  const professionController = useMemo(
    () => inputController('studySpoDocument.profession', 'studySpoDocumentErrors.profession'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData.id],
  );
  const professionControl = (
    <FormControl
      required={isProfessionControlRequired}
      error={isProfessionControlError}
      renderMode={inputRenderMode}
      inputSize={inputSize}
      label={<BaseInputLabel>Квалификация</BaseInputLabel>}
      helper={isProfessionControlError && <FormHelperText>Введите квалификацию</FormHelperText>}
      control={
        <Input
          renderMode={inputRenderMode}
          size={inputSize}
          placeholder="Введи квалификацию..."
          value={data?.profession}
          controller={professionController}
        />
      }
    />
  );
  controlMap.set('profession', professionControl);

  // Разряд
  const isRankControlRequired = fields.rank;
  const isRankControlError = isRankControlRequired && errors.rank;
  const rankController = useMemo(
    () => selectController('studySpoDocument.rankCode', 'studySpoDocumentErrors.rank'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData.id],
  );
  const rankControl = (
    <FormControl
      required={isRankControlRequired}
      error={isRankControlError}
      renderMode={inputRenderMode}
      inputSize={inputSize}
      label={<BaseInputLabel>Разряд</BaseInputLabel>}
      helper={isRankControlError && <FormHelperText>Выберите разряд</FormHelperText>}
      control={
        <Select
          inputRenderMode={inputRenderMode}
          inputSize={inputSize}
          placeholder="Выбери разряд..."
          value={data?.rankCode}
          options={professionRankRef.content}
          controller={rankController}
          strictMaxWidth
        />
      }
    />
  );
  controlMap.set('rank', rankControl);

  // Номер документа
  const isDocNumControlRequired = fields.docNum;
  const isDocNumControlError = isDocNumControlRequired && errors.docNum;
  const docNumController = useMemo(
    () => inputController('studySpoDocument.docNum', 'studySpoDocumentErrors.docNum'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData.id],
  );
  const docNumControl = (
    <FormControl
      required={isDocNumControlRequired}
      error={isDocNumControlError}
      renderMode={inputRenderMode}
      inputSize={inputSize}
      label={<BaseInputLabel>Номер документа</BaseInputLabel>}
      helper={isDocNumControlError && <FormHelperText>Введите номер документа</FormHelperText>}
      control={
        <Input
          renderMode={inputRenderMode}
          size={inputSize}
          placeholder="Введи номер документа..."
          value={data?.docNum}
          controller={docNumController}
        />
      }
    />
  );
  controlMap.set('docNum', docNumControl);

  // Серия документа
  const isDocSeriesControlRequired = fields.docSeries;
  const isDocSeriesControlError = isDocSeriesControlRequired && errors.docSeries;
  const docSeriesController = useMemo(
    () => inputController('studySpoDocument.docSeries', 'studySpoDocumentErrors.docSeries'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData.id],
  );
  const docSeriesControl = (
    <FormControl
      required={isDocSeriesControlRequired}
      error={isDocSeriesControlError}
      renderMode={inputRenderMode}
      inputSize={inputSize}
      label={<BaseInputLabel>Серия документа</BaseInputLabel>}
      helper={isDocSeriesControlError && <FormHelperText>Введите серию документа</FormHelperText>}
      control={
        <Input
          renderMode={inputRenderMode}
          size={inputSize}
          placeholder="Введи серию документа..."
          value={data?.docSeries}
          controller={docSeriesController}
        />
      }
    />
  );
  controlMap.set('docSeries', docSeriesControl);

  // Номер свидетельства
  // В стейте используется поле docNum
  const isCertificateNumControlRequired = fields.certificateNum;
  const isCertificateNumControlError = isCertificateNumControlRequired && errors.docNum;
  const certificateNumController = useMemo(
    () => inputController('studySpoDocument.docNum', 'studySpoDocumentErrors.docNum'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData.id],
  );
  const certificateNumControl = (
    <FormControl
      required={isCertificateNumControlRequired}
      error={isCertificateNumControlError}
      renderMode={inputRenderMode}
      inputSize={inputSize}
      label={<BaseInputLabel>№ Свидетельства</BaseInputLabel>}
      helper={
        <FormHelperText>
          Номер обычно указан красным цветом после “Свидетельство о профессии рабочего, должности служащего” на бланке
        </FormHelperText>
      }
      control={
        <Input
          renderMode={inputRenderMode}
          size={inputSize}
          placeholder="Введи № свидетельства..."
          value={data?.docNum}
          controller={certificateNumController}
        />
      }
    />
  );
  controlMap.set('certificateNum', certificateNumControl);

  // Дата решения аттестационной комиссии
  const isResultDateControlRequired = fields.resultDate;
  const isResultDateControlError = isResultDateControlRequired && errors.resultDate.active;
  const resultDateController = dayPickerControllerFactory(
    'studySpoDocument.resultDate',
    'studySpoDocumentErrors.resultDate',
    {
      disableFuture: true,
      required: isResultDateControlRequired,
    },
  );
  const resultDateControl = (
    <FormControl
      required={isResultDateControlRequired}
      renderMode={inputRenderMode}
      inputSize={inputSize}
      label={<BaseInputLabel>Дата решения аттестационной комиссии</BaseInputLabel>}
      error={isResultDateControlError}
      helper={isResultDateControlError && <FormHelperText>{errors.resultDate.description}</FormHelperText>}
      control={
        <Box sx={{ width: isMobile ? 'auto' : '220px' }}>
          <DayPicker
            isMobile={isMobile}
            renderMode={inputRenderMode}
            size={inputSize}
            controller={resultDateController}
            value={data?.resultDate ?? null}
            maxValue={new Date()}
          />
        </Box>
      }
    />
  );
  controlMap.set('resultDate', resultDateControl);

  return (
    <SecondaryBlockContainer>
      <SecondaryBlockContainer.GeneralTab>
        {Object.keys(fields).map((field) => {
          const formField = field as StudySpoDocumentFields;

          if (!isMobile && formField === 'docSeries' && controlMap.has('docNum')) return null;

          if (!isMobile && formField === 'docNum' && controlMap.has('docSeries')) {
            return (
              <Box key={formField} sx={{ width: '100%', display: 'flex', gap: '20px' }}>
                {controlMap.get('docNum')}
                {controlMap.get('docSeries')}
              </Box>
            );
          }

          return controlMap.get(formField);
        })}
      </SecondaryBlockContainer.GeneralTab>
      <SecondaryBlockContainer.FilesTab
        personId={currentStudent.meshId}
        controller={fileController}
        files={formData.attachment}
        label="Загрузи скан документа"
        required
        maxFiles={1}
        {...commonAcceptFileTypes}
      />
    </SecondaryBlockContainer>
  );
};

export default connect((state: IRootState) => ({
  currentStudent: state.currentStudent,
  spoOrganizationRef: state.spoOrganizationRef,
  professionProgrammRef: state.professionProgrammRef,
  spoGiaMarkRef: state.spoGiaMarkRef,
  professionRankRef: state.professionRankRef,
}))(StudySpoDocumentBlock);
