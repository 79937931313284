import { defaultThemeSettings } from 'const';
import { useAppSelector, useUserFunctionality } from 'hooks';

const useThemeSettings = () => {
  const themeSettings = useAppSelector((state) => state.userThemeSettings).settings;
  const adminViewModeSettings = useAppSelector((state) => state.adminViewModeSettings).content?.themeSettings;
  const { isViewing: isAdminViewing, viewType: adminViewType } = useAppSelector((state) => state.adminViewMode);

  const { isEmployee, isOperator } = useUserFunctionality();

  const isAdminViewingWithoutSettings = isAdminViewing && adminViewType === 'student';

  if (isAdminViewingWithoutSettings) {
    return defaultThemeSettings;
  }

  if (isEmployee || isOperator) {
    return defaultThemeSettings;
  }

  return isAdminViewing ? adminViewModeSettings ?? defaultThemeSettings : themeSettings;
};

export default useThemeSettings;
