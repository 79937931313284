import { FC, useId } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Stack } from '@mui/material';
import { setDisplayedEntityCountAction } from 'actions';
import { ExpandableEntityList } from 'components/redesignStudent';
import { professionNavigation } from 'const';
import { useAppSelector, useScrollToElement } from 'hooks';

import { IRecommendedIndustry } from '../../model/types';
import RecommendationCard from '../RecommendationCard';

const INITIAL_ENTITY_COUNT = 1;
const containerId = professionNavigation.recommendations.id;

interface IRecommendationCardListProps {
  recommendations: IRecommendedIndustry[];
}

const RecommendationCardList: FC<IRecommendationCardListProps> = ({ recommendations }) => {
  const dispatch = useDispatch();
  const entityCount = useAppSelector((state) => state.displayedEntityCount[containerId] ?? INITIAL_ENTITY_COUNT);

  const listId = useId();
  const { scrollToElementGlobal } = useScrollToElement();

  const handleShowInitialEntityCount = () => {
    dispatch(setDisplayedEntityCountAction(containerId, INITIAL_ENTITY_COUNT));
    scrollToElementGlobal(listId);
  };

  const handleShowAllEntities = () => {
    dispatch(setDisplayedEntityCountAction(containerId, recommendations.length));
  };

  return (
    <Box className="recommendation-card-list" id={listId}>
      <ExpandableEntityList
        totalEntitites={recommendations.length}
        shownEntities={entityCount}
        hasHiddenEntities={recommendations.length > INITIAL_ENTITY_COUNT}
        onSetAllEntities={handleShowAllEntities}
        onSetInitialEntities={handleShowInitialEntityCount}
      >
        <Stack gap="16px">
          {recommendations.slice(0, entityCount).map((recommendation) => {
            return <RecommendationCard key={recommendation.name} recommendation={recommendation} />;
          })}
        </Stack>
      </ExpandableEntityList>
    </Box>
  );
};

export default RecommendationCardList;
