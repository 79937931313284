import { FC, PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { userLocalRolesSelector } from 'selectors';

import { IRootState } from '../../../reducers';
import { hasRolePermission } from '../../../utils';

interface IProtectedRouteProps extends RouteProps {
  permissions: string[];
  fallbackPath?: string;
  userLocalRoles: string[];
}

const ProtectedRoute: FC<IProtectedRouteProps & PropsWithChildren> = ({
  children,
  permissions,
  fallbackPath,
  userLocalRoles,
  ...rest
}) => {
  const hasAccess = hasRolePermission(userLocalRoles, permissions);

  return (
    <Route
      {...rest}
      render={() => {
        if (hasAccess) return children;
        if (fallbackPath) return <Redirect to={fallbackPath} />;
        return undefined;
      }}
    />
  );
};

export default connect((state: IRootState) => ({
  userLocalRoles: userLocalRolesSelector(state),
}))(ProtectedRoute);
