import { useContext } from 'react';

import { PortfolioSettingsContext } from 'context';
import { useAppSelector } from 'hooks';
import { isRegionalUser } from 'utils';

const useCommonAdvertVisibility = () => {
  const isRegional = isRegionalUser();
  const linkMode = useAppSelector((state) => state.linkMode.mode);
  const { isSettingsMode } = useContext(PortfolioSettingsContext);

  const isAdvertVisible = !isRegional && !linkMode && !isSettingsMode;
  return isAdvertVisible;
};

export default useCommonAdvertVisibility;
