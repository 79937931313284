import { useMemo } from 'react';

import { useAppSelector } from 'hooks';
import {
  IFile,
  IFileController,
  ILinkablePersonObject,
  linkablePersonObjectsSelector,
} from 'portfolio3/features/dataEntryForm';
import { IPortfolioDataEntryFormErrors, IPortfolioEntryFormData } from 'portfolio3/features/dataEntryForm';

import { IAttachment } from '../../../../../../api/types';
import { ILinkedSelectorController } from '../../../../Select/LinkedItemSelector/types';

interface IUseSecondaryBlockControllersProps {
  formData: IPortfolioEntryFormData;
  onChangeFormData: React.Dispatch<React.SetStateAction<IPortfolioEntryFormData>>;
  onChangeFormErrors: React.Dispatch<React.SetStateAction<IPortfolioDataEntryFormErrors>>;
}

const useSecondaryBlockControllers = ({ formData, onChangeFormData }: IUseSecondaryBlockControllersProps) => {
  const linkedObjectsOptions = useAppSelector(linkablePersonObjectsSelector);

  const fileController: IFileController = useMemo<IFileController>(
    () => ({
      handleAddFileSchema: (localId: string, file: File) => {
        onChangeFormData((prevState) => {
          const mockResponse = {
            isDelete: false,
            id: 1,
          };
          const newAttachment = [
            ...prevState.attachment,
            {
              file,
              localId,
              isLoading: true,
              response: mockResponse,
            },
          ];
          return {
            ...prevState,
            attachment: newAttachment,
          };
        });
      },
      handleFulfillSchema: (localId: string, response: IAttachment) => {
        onChangeFormData((prevState) => {
          const newAttachment = prevState.attachment.map((file): IFile => {
            if (file.localId === localId) {
              return {
                ...file,
                isLoading: false,
                response,
              };
            }

            return file;
          });

          return {
            ...prevState,
            attachment: newAttachment,
          };
        });
      },

      // Удаление при клике на крестик файла
      handleSetDeleteFileStatus: (localId: string) => {
        onChangeFormData((prevState) => {
          const newAttachment = prevState.attachment.map((file): IFile => {
            if (file.localId === localId) {
              return {
                ...file,
                response: {
                  ...file.response,
                  isDelete: true,
                },
              };
            }

            return file;
          });

          return {
            ...prevState,
            attachment: newAttachment,
          };
        });
      },

      handleDeleteFile: (localId: string) => {
        onChangeFormData((prevState) => {
          const newAttachment = prevState.attachment.filter((file) => file.localId !== localId);
          return {
            ...prevState,
            attachment: newAttachment,
          };
        });
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData.id],
  );

  const linkedObjectController: ILinkedSelectorController = useMemo(
    () => ({
      handleChange: (linkedObject: ILinkablePersonObject, index: number) => {
        onChangeFormData((prevState) => {
          const newLinkedObjects = [...prevState.linkedObjects];
          newLinkedObjects[index] = linkedObject;
          return {
            ...prevState,
            linkedObjects: newLinkedObjects,
          };
        });
      },
      handleDeleteField: (index: number) => {
        onChangeFormData((prevState) => {
          const newLinkedObjects = [...prevState.linkedObjects];
          newLinkedObjects.splice(index, 1);
          return {
            ...prevState,
            linkedObjects: newLinkedObjects,
          };
        });
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData.id],
  );

  const linkedObjects = useMemo(
    () => linkedObjectsOptions?.filter((linkedObject) => linkedObject.code !== formData.id),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [linkedObjectsOptions],
  );

  return {
    linkedObjects,
    fileController,
    linkedObjectController,
  };
};

export default useSecondaryBlockControllers;
