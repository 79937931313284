/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Grid } from '@mui/material';
import { format, isAfter } from 'date-fns';

import { IGiaItem } from '../../../../api/types';

import { IRootState } from '../../../../reducers';

import { CompulsoryGiaSubjects } from '../../../../const';
import ExamBlock from '../ExamBlock';
import { StudentGiaListState } from '../../../../reducers/student/studentGiaList';

import './index.scss';

interface IExamContainerProps {
  exams: IGiaItem[];
  studentGiaList: StudentGiaListState;
  title?: string;
}
const ExamContainer: React.FC<IExamContainerProps> = ({
  exams,
  studentGiaList,
  title
}) => {

  const [heightModificator, setHeightModificator] = useState(0);

  const sortExamsByCompulsoryAndDate = (studentExams: IGiaItem[]) => {
    // Данная функция сортирует экзамены по признаку "обязательный/необязательный", после чего сортирует эти две группы экзаменов по дате
    // Возвращает массив из отсортированных экзаменов (сначала обязательные, затем необязательные)
    const compulsoryExams = studentExams.filter((exam) => exam.name && CompulsoryGiaSubjects.includes(exam.name));
    const optionalExams = studentExams.filter((exam) => exam.name && !CompulsoryGiaSubjects.includes(exam.name));
    const sortedCompulsoryExams = compulsoryExams.sort((prevExam, nextExam) => ((isAfter(new Date(prevExam.date), new Date(nextExam.date))) ? -1 : 1));
    const sortedOptionalExams = optionalExams.sort((prevExam, nextExam) => ((isAfter(new Date(prevExam.date), new Date(nextExam.date))) ? -1 : 1));
    return [...sortedCompulsoryExams, ...sortedOptionalExams];
  };

  const getSortedExams = (studentExams: IGiaItem[]) => {
    // Данная функция сначала разбивает массив на несколько других массивов с общим признаком "год сдачи"
    // Затем к каждому массиву применяет функцию сортировки sortExamsByCompulsoryAndDate.
    // Возвращает массив из всех экзаменов отсортированный:
    // 1. По году (сначала поздние)
    // 2. Внутри года по обязательности (сначала обязательные)
    // 3. Внутри обязательности по дате (сначала поздние)
    const examsYears = Array.from(new Set(studentExams.map((exam) => format(new Date(exam.date), 'yyyy'))));
    const sortedExamsYears = examsYears.sort((prevYear, nextYear) => (Number(prevYear) > Number(nextYear) ? -1 : 1));
    const examsByYear = sortedExamsYears.map((year) => ({
      [year]: sortExamsByCompulsoryAndDate(studentExams.filter((exam) => format(new Date(exam.date), 'yyyy') === year))
    }));
    const sortedExams: any[] = [];
    examsByYear.forEach((examByYear) => {
      sortedExams.push(...Object.values(examByYear));
    });
    return sortedExams.flat();
  };

  const sortedExams = getSortedExams(exams);

  return (
    <div className="pdf-exam-container">
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        className="pdf-exam-container__title"
      >
        <Grid item>
          <h4 className="title">{title ? title : 'Другое'}</h4>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        wrap="wrap"
        className="pdf-exam-container__exams"
      >
        {sortedExams?.map((item, index) => (
          <ExamBlock
            exam={item}
            key={index}
            heightModificator={heightModificator}
            setHeightModificator={setHeightModificator}
          />
        ))}
      </Grid>
    </div>
  );
};

export default connect(
  (state: IRootState) => ({
    studentGiaList: state.studentGiaList,
    localVisibilitySettings: state.localVisibilitySettings
  })
)(ExamContainer);
