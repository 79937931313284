import { StudentSectionSettingTypeCodes } from 'const';
import useWidgetVisibility from 'hooks/useWidgetVisibility';

const creationUseWidgetVisibilityCreator = (subsectionTypeCode: string) => {
  const useProfessionWidgetVisibility = () => {
    return useWidgetVisibility({
      sectionTypeCode: StudentSectionSettingTypeCodes.creation,
      subsectionTypeCode,
    });
  };
  return useProfessionWidgetVisibility;
};

const useRewards = creationUseWidgetVisibilityCreator(StudentSectionSettingTypeCodes.creationReward);

const useClubs = creationUseWidgetVisibilityCreator(StudentSectionSettingTypeCodes.creationClub);

const useContests = creationUseWidgetVisibilityCreator(StudentSectionSettingTypeCodes.creationContest);

const useSections = creationUseWidgetVisibilityCreator(StudentSectionSettingTypeCodes.creationUnit);

export const creationWidgetVisibilityHooks = { useRewards, useClubs, useContests, useSections };
