import { FC, useContext, useMemo } from 'react';
import { connect } from 'react-redux';

import { ICommonResponse, IDictionaryItem, IEventKind } from 'api/types';
import { SectionCodes } from 'const';
import { useAppSelector } from 'hooks';
import { IRootState } from 'reducers';
import { createSelector } from 'reselect';
import { sectionRefArchiveFalseSelector } from 'selectors';

import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';
import { ActivityBlock, RewardBlock, StudySpoDocumentPrimaryBlock } from '../../primaryBlocks';
import { filterPersonsObjects } from '../../selectors';

interface IStudyPrimaryBlockProps {
  eventKinds: ICommonResponse<IEventKind>;
  rewardKinds: IDictionaryItem[];
  studyObjectsDictionary: IDictionaryItem[];
}

const StudyPrimaryBlock: FC<IStudyPrimaryBlockProps> = ({ rewardKinds, eventKinds, studyObjectsDictionary }) => {
  const portfolioSections = useAppSelector(sectionRefArchiveFalseSelector);

  const { studentType, onOpenEventForm, formData } = useContext(LocalDataEntryFormContext);

  const activityTypeOptions = useMemo(() => {
    return portfolioSections.content.filter((section) => section.parentId === SectionCodes.studyEvent);
  }, [portfolioSections.content]);
  const activityNameOptions = useMemo(() => {
    return eventKinds.content.filter((eventKind) => eventKind.categoryCode === formData.typeCode);
  }, [eventKinds.content, formData.typeCode]);

  const rewardTypeOptions = useMemo(
    () => portfolioSections.content.filter((section) => section.parentId === SectionCodes.studyReward),
    [portfolioSections],
  );
  const rewardItemOptions = useMemo(() => {
    return studyObjectsDictionary.filter((studyObject) => studyObject.parentId === SectionCodes.eventOlympiad);
  }, [studyObjectsDictionary]);

  const handleOpenEventForm = () => {
    onOpenEventForm(SectionCodes.study, SectionCodes.studyEvent);
  };

  return (
    <>
      {formData.dataKind === SectionCodes.studyEvent && (
        <ActivityBlock
          typeOptions={activityTypeOptions}
          nameOptions={activityNameOptions}
          nameInputLabel="Название олимпиады"
          nameInputPlaceholder="Выберите олимпиаду..."
          hasOtherNameOption
        />
      )}
      {formData.dataKind === SectionCodes.studyReward && (
        <RewardBlock
          shortType={false}
          isNameOptionActive
          typeOptions={rewardTypeOptions}
          itemOptions={rewardItemOptions}
          resultOptions={rewardKinds}
          onOpenEventForm={handleOpenEventForm}
          nameInputLabel="Олимпиада"
          nameInputPlaceholder={
            studentType ? 'Выбери олимпиаду, за которую была получена награда...' : 'Выберите олимпиаду...'
          }
        />
      )}
      {formData.typeCode === SectionCodes.studySpoDocuments && <StudySpoDocumentPrimaryBlock />}
    </>
  );
};

const rewardKindsSelector = createSelector([(state: IRootState) => state.rewardKinds], (rewardKinds) => {
  return rewardKinds.content.filter((rewardKind) => rewardKind.categoryCode === SectionCodes.eventOlympiad);
});

const studyObjectsSelector = createSelector([(state: IRootState) => state.personsObjects], (personsObjects) => {
  return filterPersonsObjects(personsObjects.content, [SectionCodes.studyEvent]);
});

export default connect((state: IRootState) => ({
  eventKinds: state.eventKinds,
  rewardKinds: rewardKindsSelector(state),
  studyObjectsDictionary: studyObjectsSelector(state),
}))(StudyPrimaryBlock);
