import { FC } from 'react';

import { useAppSelector, useDataLoad } from 'hooks';
import { getDataLoadRequests } from 'utils';

import PdfCommonRewards from '../../../components/pdfStudent/PdfCommonRewards';
import { widgetPdfIcons } from '../../../icons';
import { professionRewardsSelector } from '../../../selectors';
import PdfCommonDataEntityContainer from '../dataEntityContainers/common';

const PdfProfessionRewardContainer: FC = () => {
  const rewards = useAppSelector(professionRewardsSelector);
  useDataLoad({ shouldLoad: true, requests: getDataLoadRequests().professionRewards });

  return (
    <PdfCommonDataEntityContainer title="Награды" icon={widgetPdfIcons.pdfIconProfessionRewards}>
      <PdfCommonRewards rewards={rewards.content || []} />
    </PdfCommonDataEntityContainer>
  );
};

export default PdfProfessionRewardContainer;
