import { FC } from 'react';

import { useAppSelector, useDataLoad } from 'hooks';
import DocumentCard from 'portfolio3/features/pdf/components/cards/DocumentCard';
import GridLayout from 'portfolio3/features/pdf/components/GridLayout';
import PdfNoDataText from 'portfolio3/features/pdf/components/PdfNoDataText';
import { getDataLoadRequests } from 'utils';

import { widgetPdfIcons } from '../../../../icons';
import { studyDocumentsSelector } from '../../../../selectors';
import PdfCommonDataEntityContainer from '../common';

import '../index.scss';

const PdfDocumentsContainer: FC = () => {
  const documents = useAppSelector(studyDocumentsSelector);
  useDataLoad({ shouldLoad: true, requests: getDataLoadRequests().studySpoDocuments });

  const hasData = documents.content.length > 0;

  return (
    <PdfCommonDataEntityContainer title="Документы" icon={widgetPdfIcons.pdfIconStudyDocuments}>
      {hasData && (
        <GridLayout gap="16px">
          {documents.content.map((entity) => (
            <DocumentCard
              key={entity.formData.id}
              name={entity.name}
              badge={entity.badge}
              preview={undefined}
              previewPlaceholderText="JPG"
            />
          ))}
        </GridLayout>
      )}
      {!hasData && <PdfNoDataText />}
    </PdfCommonDataEntityContainer>
  );
};

export default PdfDocumentsContainer;
