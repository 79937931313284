/* eslint-disable max-len */
import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const CheckMarkButton: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.25 3.75C1.25 2.36929 2.36929 1.25 3.75 1.25H16.25C17.6307 1.25 18.75 2.36929 18.75 3.75V16.25C18.75 17.6307 17.6307 18.75 16.25 18.75H3.75C2.36929 18.75 1.25 17.6307 1.25 16.25V3.75Z"
      fill="#7950F2"
    />
    <path
      d="M8.27625 14.375C8.03676 14.375 7.79728 14.2854 7.61436 14.1057L4.0241 10.5799C3.65863 10.221 3.65863 9.63875 4.0241 9.27984C4.38956 8.92094 4.98241 8.92094 5.34787 9.27984L8.27625 12.1557L14.6521 5.89418C15.0176 5.53527 15.6104 5.53527 15.9759 5.89418C16.3414 6.25308 16.3414 6.8353 15.9759 7.19421L8.93813 14.1057C8.7556 14.2854 8.51573 14.375 8.27625 14.375Z"
      fill="#F4F4F4"
    />
  </SvgIcon>
);

export default CheckMarkButton;
