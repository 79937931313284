import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const mobileIconContainer: SxStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '32px',
  height: '32px',
  backgroundColor: NeutralColors.light_neutrals_200,
  borderRadius: '8px',
};

export const icon: SxStyles = {
  color: NeutralColors.light_neutrals_600,
};

export const desktopIcon: SxStyles = {
  width: '20px',
  height: '20px',
};

export const mobileIcon: SxStyles = {
  width: '16px',
  height: '16px',
};
