import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root: SxStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '8px',
};

export const title: SxStyles = (theme) => {
  return {
    maxWidth: '333px',

    textAlign: 'center',
    color: NeutralColors.light_neutrals_600,

    [theme.breakpoints.down('commonSm')]: {
      maxWidth: 'initial',
    },
  };
};
