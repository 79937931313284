/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useContext, useMemo, useState } from 'react';

import { connect } from 'react-redux';

import { SecondaryBlockContainer, useSecondaryBlockControllers } from '../common';
import { IRootState } from '../../../../../reducers';
import { IChildInfo } from '../../../../../api/types';
import { getCommonInputControllerFactory } from '../../utils';

import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';
import { BaseInputLabel, FormControl, Input, NumberInput, ToggleFormControl } from 'portfolio3/ui-kit';
import { Box, FormHelperText } from '@mui/material';
import Toggle from 'portfolio3/ui-kit/Toggle';
import { getDayPickerControllerFactory } from '../../controllers';
import { DayPicker } from 'portfolio3/ui-kit/datePickers';
import { commonAcceptFileTypes } from 'portfolio3/features/dataEntryForm';

interface IProfessionGiaWorldSkillsSecondaryBlockProps {
  currentStudent: IChildInfo;
}

const ProfessionGiaWorldSkillsSecondaryBlock: React.FC<IProfessionGiaWorldSkillsSecondaryBlockProps> = ({
  currentStudent,
}) => {
  const { isMobile, inputRenderMode, inputSize, formData, formErrors, onChangeFormData, onChangeFormErrors } = useContext(LocalDataEntryFormContext);

  const { fileController } = useSecondaryBlockControllers({
    formData,
    onChangeFormData,
    onChangeFormErrors
  });

  const today = new Date();

  const createController = getCommonInputControllerFactory(onChangeFormData, onChangeFormErrors);
  const dayPickerControllerFactory = getDayPickerControllerFactory(onChangeFormData, onChangeFormErrors);

  const giaCompetenceCodeController = useMemo(() => createController('giaCompetenceCode', 'giaCompetenceCodeError'), [formData.id]);
  const giaNameController = useMemo(() => createController('giaName', 'giaNameError'), [formData.id]);
  const giaResultScoreController = useMemo(() => createController('giaResultScore', 'giaResultScoreError'), [formData.id]);
  const giaMaxCompetenceScoreController = useMemo(() => createController('giaMaxCompetenceScore', 'giaMaxCompetenceScoreError'), [formData.id]);
  const giaKodController = useMemo(() => createController('giaKod', 'giaCodeError'), [formData.id]);

  const handlePresentaionCompetenceChange = (checked: boolean) => {
    onChangeFormData((prevstate) => ({
      ...prevstate,
      giaIsEarlyRelease: checked,
    }));
  };

  const dateController = dayPickerControllerFactory('giaResultDate', 'dateError', {
    disableFuture: true,
  });

  return (
    <SecondaryBlockContainer>
      <SecondaryBlockContainer.FilesTab
        personId={currentStudent.meshId}
        controller={fileController}
        files={formData.attachment}
        {...commonAcceptFileTypes}
      />

      <SecondaryBlockContainer.GeneralTab>
        {/* formData.giaCompetenceCode */}
        <FormControl
          required
          renderMode={inputRenderMode}
          inputSize={inputSize}
          error={formErrors.giaCompetenceCodeError}
          label={<BaseInputLabel>Код компетенции WorldSkills</BaseInputLabel>}
          helper={formErrors.giaCompetenceCodeError && <FormHelperText>Введите код компетенции</FormHelperText>}
          control={
            <Input
              renderMode={inputRenderMode}
              size={inputSize}
              placeholder="Введите код компетенеции..."
              value={formData.giaCompetenceCode}
              controller={giaCompetenceCodeController}
            />
          }
        />
        {/* formData.giaName */}
        <FormControl
          required
          renderMode={inputRenderMode}
          inputSize={inputSize}
          error={formErrors.giaNameError}
          label={<BaseInputLabel>Компетенция WorldSkills</BaseInputLabel>}
          helper={formErrors.giaNameError && <FormHelperText>Введите компетенцию</FormHelperText>}
          control={
            <Input
              renderMode={inputRenderMode}
              size={inputSize}
              placeholder="Введите компетенецию..."
              value={formData.giaName}
              controller={giaNameController}
            />
          }
        />
        {/* formData.giaResultScore */}
        <FormControl
          required
          renderMode={inputRenderMode}
          inputSize={inputSize}
          error={formErrors.giaResultScoreError}
          label={<BaseInputLabel>Количество баллов</BaseInputLabel>}
          helper={formErrors.giaResultScoreError && <FormHelperText>Введите количество баллов</FormHelperText>}
          control={
            <NumberInput
              renderMode={inputRenderMode}
              size={inputSize}
              placeholder="Введите количество баллов..."
              value={formData.giaResultScore}
              controller={giaResultScoreController}
              min={0}
              max={500}
              withControls={false}
              validateOnBlur
            />
          }
        />
        {/* formData.giaMaxCompetenceScore */}
        <FormControl
          required
          renderMode={inputRenderMode}
          inputSize={inputSize}
          error={formErrors.giaMaxCompetenceScoreError}
          label={<BaseInputLabel>Максимальный балл по компетенции</BaseInputLabel>}
          helper={formErrors.giaMaxCompetenceScoreError && <FormHelperText>Введите максимальный балл</FormHelperText>}
          control={
            <NumberInput
              renderMode={inputRenderMode}
              size={inputSize}
              placeholder="Введите максимальный балл..."
              value={formData.giaMaxCompetenceScore}
              controller={giaMaxCompetenceScoreController}
              min={10}
              max={500}
              withControls={false}
              validateOnBlur
            />
          }
        />
        {/* formData.giaCode */}
        <FormControl
          required
          renderMode={inputRenderMode}
          inputSize={inputSize}
          error={formErrors.giaCodeError}
          label={<BaseInputLabel>КОД</BaseInputLabel>}
          helper={formErrors.giaCodeError && <FormHelperText>Введите КОД</FormHelperText>}
          control={
            <Input
              renderMode={inputRenderMode}
              size={inputSize}
              placeholder="Введите КОД..."
              value={formData.giaKod}
              controller={giaKodController}
            />
          }
        />
        {/* formData.date */}
        <FormControl
          renderMode={inputRenderMode}
          inputSize={inputSize}
          error={formErrors.dateError.active}
          helper={formErrors.dateError.active && <FormHelperText>{formErrors.dateError.description}</FormHelperText>}
          label={<BaseInputLabel>Дата проведения экзамена</BaseInputLabel>}
          control={
            <Box sx={!isMobile ? { width: '220px' } : undefined}>
              <DayPicker
                isMobile={isMobile}
                renderMode={inputRenderMode}
                size={inputSize}
                value={formData.giaResultDate ?? null}
                maxValue={today}
                controller={dateController}
              />
            </Box>
          }
        />
        {/* formData.giaIsEarlyRelease */}
        <ToggleFormControl
          required
          withTopMargin={isMobile}
          renderMode={isMobile ? 'inline' : 'column'}
          inputSize={inputSize}
          label={<BaseInputLabel overrideRenderMode="fixed" overrideInputSize={inputSize}>Программа с ранним выпуском</BaseInputLabel>}
          control={
            <Toggle
              checked={formData.isPresentationCompetence}
              onChange={(_, checked) => handlePresentaionCompetenceChange(checked)}
            />
          }
        />
      </SecondaryBlockContainer.GeneralTab>
    </SecondaryBlockContainer>
  );
};

export default connect(
  (state: IRootState) => ({
    currentStudent: state.currentStudent
  })
)(ProfessionGiaWorldSkillsSecondaryBlock);
