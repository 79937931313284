import { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import ProtectedRoute from 'components/common/ProtectedRoute';
import { routes } from 'const';
import {
  AdminAnalyticsPage,
  AdminDeleteHistoryPage,
  AdminSettingsPage,
  AdminStudentRegister,
  ChangeHistoryPage,
  StudentsRegister,
} from 'pages';
import { isDeviceMobile } from 'utils';

import EmployeeRenderer from './containers/renderers/containers/EmployeeRenderer';
import InterestsRenderer from './containers/renderers/containers/InterestsRenderer';
import LinkRenderer from './containers/renderers/containers/LinkRenderer';
import { MainRendererStudent } from './containers/renderers/containers/MainRenderer';
import SettingsRenderer from './containers/renderers/containers/SettingsRenderer';

const RendererRoutes: FC = () => (
  <Switch>
    <Route path={`${routes.SHARED_LINK.path}/:url`}>
      <LinkRenderer />
    </Route>

    <ProtectedRoute {...routes.WEBVIEW_INTERESTS} fallbackPath={routes.PORTFOLIO_STUDENT.path}>
      <InterestsRenderer />
    </ProtectedRoute>

    <ProtectedRoute {...routes.WEBVIEW} fallbackPath={routes.PORTFOLIO_STUDENT.path}>
      <MainRendererStudent />
    </ProtectedRoute>

    <ProtectedRoute {...routes.PORTFOLIO} exact fallbackPath={`${routes.PORTFOLIO_STUDENT.path}`} />

    {!isDeviceMobile() && (
      <ProtectedRoute {...routes.PORTFOLIO_STUDENT_SETTING} fallbackPath={routes.PORTFOLIO_STUDENT.path}>
        <SettingsRenderer />
      </ProtectedRoute>
    )}

    <ProtectedRoute {...routes.PORTFOLIO_STUDENT} fallbackPath={routes.PORTFOLIO_EMPLOYEE.path}>
      <MainRendererStudent />
    </ProtectedRoute>

    <ProtectedRoute {...routes.STUDENT_INTERESTS} fallbackPath={routes.PORTFOLIO_EMPLOYEE.path}>
      <InterestsRenderer />
    </ProtectedRoute>

    <ProtectedRoute {...routes.PORTFOLIO_EMPLOYEE} fallbackPath={routes.PORTFOLIO_ADMIN.path}>
      <EmployeeRenderer render={StudentsRegister} />
    </ProtectedRoute>

    <ProtectedRoute {...routes.PORTFOLIO_ADMIN} fallbackPath={routes.ROOT.path}>
      <EmployeeRenderer render={AdminStudentRegister} />
    </ProtectedRoute>

    <ProtectedRoute {...routes.EMPLOYEE_HISTORY} fallbackPath={routes.ROOT.path}>
      <EmployeeRenderer render={ChangeHistoryPage} />
    </ProtectedRoute>

    <ProtectedRoute {...routes.ADMIN_SETTINGS} fallbackPath={routes.ROOT.path}>
      <EmployeeRenderer render={AdminSettingsPage} />
    </ProtectedRoute>

    <ProtectedRoute {...routes.ADMIN_ANALYTICS} fallbackPath={routes.ROOT.path}>
      <EmployeeRenderer render={AdminAnalyticsPage} />
    </ProtectedRoute>

    <ProtectedRoute {...routes.ADMIN_DELETE_HISTORY} fallbackPath={routes.ROOT.path}>
      <EmployeeRenderer render={AdminDeleteHistoryPage} />
    </ProtectedRoute>

    <Route path={routes.ROOT.path} render={() => <Redirect to={routes.PORTFOLIO.path} />} />
  </Switch>
);

export default RendererRoutes;
