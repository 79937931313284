import { createAction, createRequestTypes, FAILURE, INVALIDATE, REQUEST, SUCCESS } from 'actions/utils';
import { IResponsePayload, ISubjectThemePerformance, MetaObject } from 'api/types';

export const GET_STUDENT_FINAL_GRADES = createRequestTypes('GET_STUDENT_FINAL_GRADES');
export const getStudentFinalGradesActions = {
  request: (personId: string) => createAction(GET_STUDENT_FINAL_GRADES[REQUEST], personId),
  success: (response: unknown) => createAction(GET_STUDENT_FINAL_GRADES[SUCCESS], response),
  failure: (error: unknown) => createAction(GET_STUDENT_FINAL_GRADES[FAILURE], error),
  invalidate: () => createAction(GET_STUDENT_FINAL_GRADES[INVALIDATE], null),
};

export const GET_STUDENT_AVERAGE_GRADES = createRequestTypes('GET_STUDENT_AVERAGE_GRADES');
export const getStudentAverageGradesActions = {
  request: (personId: string) => createAction(GET_STUDENT_AVERAGE_GRADES[REQUEST], personId),
  success: (response: unknown) => createAction(GET_STUDENT_AVERAGE_GRADES[SUCCESS], response),
  failure: (error: unknown) => createAction(GET_STUDENT_AVERAGE_GRADES[FAILURE], error),
  invalidate: () => createAction(GET_STUDENT_AVERAGE_GRADES[INVALIDATE], null),
};

export const GET_STUDENT_SUBJECT_THEMES = createRequestTypes('GET_STUDENT_SUBJECT_THEMES');
export const getStudentSubjectThemesActions = {
  request: (personId: string, subjectId: number, meta?: MetaObject) =>
    createAction(GET_STUDENT_SUBJECT_THEMES[REQUEST], { personId, subjectId, meta }),
  success: (response: IResponsePayload<ISubjectThemePerformance | null>) =>
    createAction(GET_STUDENT_SUBJECT_THEMES[SUCCESS], response),
  failure: (error: unknown) => createAction(GET_STUDENT_SUBJECT_THEMES[FAILURE], error),
  invalidate: () => createAction(GET_STUDENT_SUBJECT_THEMES[INVALIDATE], null),
};

export const INVALIDATE_STUDENT_SUBJECT_THEMES_CACHE = 'INVALIDATE_STUDENT_SUBJECT_THEMES_CACHE';

export const collection = {
  getStudentFinalGradesActions,
  getStudentAverageGradesActions,
  getStudentSubjectThemesActions,
};
