import { forwardRef } from 'react';

import { IconLoadingCircle } from 'icons';
import { SxStyles } from 'types';
import { mergeSx } from 'utils';

import { getButtonTokens } from '../utils';
import StyledBaseButton from './bases/BaseButton.styled';
import { loadingIconClass } from './classes';
import { ButtonVariant, IButtonProps } from './types';
import OutlineButton from './variants/OutlineButton.styled';
import PrimaryButton from './variants/PrimaryButton.styled';
import SecondaryButton from './variants/SecondaryButton.styled';
import TetriaryButton from './variants/TetriaryButton.styled';
import TextButton from './variants/TextButton.styled';

const ButtonVariants: Record<ButtonVariant, typeof StyledBaseButton> = {
  primary: PrimaryButton,
  outline: OutlineButton,
  text: TextButton,
  secondary: SecondaryButton,
  tetriary: TetriaryButton,
};

const Button = forwardRef<HTMLButtonElement, IButtonProps>(
  (
    {
      sx,
      variant = 'primary',
      size,
      borderRadius,
      iconLeft,
      iconRight,
      onlyIcon,
      loading,
      loadingText,
      children,
      ...props
    },
    ref,
  ) => {
    const StyledButton = ButtonVariants[variant] || PrimaryButton;
    const buttonTokens = size ? getButtonTokens(size) : getButtonTokens('medium');

    const additionalStyles: SxStyles[] = [];

    if (onlyIcon) {
      additionalStyles.push({
        width: buttonTokens.height,
        padding: 0,
      });
    }

    const loadingBodyElement = (
      <>
        {(!onlyIcon && loadingText) ?? 'Загрузка'}
        <IconLoadingCircle className={loadingIconClass} />
      </>
    );

    const normalBodyElement = (
      <>
        {!onlyIcon && iconLeft}
        {children}
        {!onlyIcon && iconRight}
      </>
    );

    return (
      <StyledButton
        sx={mergeSx(sx, ...additionalStyles)}
        size={size}
        borderRadius={borderRadius}
        {...props}
        disabled={props.disabled || loading}
        ref={ref}
      >
        {loading ? loadingBodyElement : normalBodyElement}
      </StyledButton>
    );
  },
);

Button.displayName = 'Button';

export default Button;
