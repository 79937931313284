import { infoMan } from 'images';
import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

import guyWithLaptop from './guyWithLaptop.png';

export const root: SxStyles = (theme) => ({
  position: 'relative',
  padding: '20px',

  borderRadius: '12px',

  backgroundColor: NeutralColors.light_neutrals_200,

  [theme.breakpoints.down('commonSm')]: {
    backgroundImage: 'none',
  },
});

export const rootDefaultBackground: SxStyles = {
  backgroundImage: `url(${infoMan})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right 0 bottom -20px',
};

export const rootSpoBackground: SxStyles = {
  backgroundImage: `url(${guyWithLaptop})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right 0 bottom 0',
};

export const paragraph: SxStyles = (theme) => ({
  maxWidth: '219px',

  fontSize: '15px',
  lineHeight: '22px',

  '&:last-child': {
    marginTop: '12px',
  },

  [theme.breakpoints.down('commonSm')]: {
    maxWidth: 'none',
  },
});

export const button: SxStyles = {
  position: 'absolute',
  top: '8px',
  right: '8px',

  width: '16px',
  height: '16px',

  svg: {
    width: '16px',
    height: '16px',
    color: NeutralColors.light_neutrals_600,
  },
};
