import { FC } from 'react';

import { IDictionaryItem } from 'api/types';
import { MobileViewDrawer } from 'portfolio3/components/drawers';

import SpecialitiesSearchableToggleList from '../SpecialitiesSearchableToggleList';

interface IMainFiltersSpecialitiesDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  specialities: IDictionaryItem[];
  selectedSpecialities: number[];
  onToggle: (specialityCode: number) => void;
}

/**
 * Дровер со списком специальностей, который меняет состояние родительских фильтров
 * Используется в дровере мобильных фильтров
 */
const MainFiltersSpecialitiesDrawer: FC<IMainFiltersSpecialitiesDrawerProps> = ({
  isOpen,
  onClose,
  specialities,
  selectedSpecialities,
  onToggle,
}) => {
  return (
    <MobileViewDrawer open={isOpen} title="Специальности" onClose={onClose} isFullHeight onExitBack={onClose}>
      <SpecialitiesSearchableToggleList
        specialities={specialities}
        selectedSpecialities={selectedSpecialities}
        onToggle={onToggle}
      />
    </MobileViewDrawer>
  );
};

export default MainFiltersSpecialitiesDrawer;
