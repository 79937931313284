import { FC } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { importSuccess } from 'images';
import { NeutralColors } from 'portfolio3/styles';
import { Button } from 'portfolio3/ui-kit/button';
import { getAddingPluralization, getRecordingPluralization } from 'utils';

interface IImportResultProps {
  successRecordCount?: number;
  onRedirectToHistoryPage: () => void;
}

const ImportResult: FC<IImportResultProps> = ({ successRecordCount, onRedirectToHistoryPage }) => {
  const records = successRecordCount ?? 0;
  const pluralizedRecoring = getRecordingPluralization(records);
  const pluralizedAdding = getAddingPluralization(records);

  const title = `${records} ${pluralizedRecoring} успешно ${pluralizedAdding} в систему`;

  return (
    <Stack className="import-result" gap="12px" sx={{ alignItems: 'center', textAlign: 'center' }}>
      <Box component="img" src={importSuccess} alt="Успех" sx={{ width: '200px', height: '150px' }} />
      <Box>
        <Typography variant="Paragraph LG/Semi Bold">{title}</Typography>
        <Typography variant="Paragraph MD/Regular" color={NeutralColors.light_neutrals_600} marginTop="4px">
          Теперь учащиеся увидят их в своих портфолио
        </Typography>
      </Box>
      <Button className="import-result__button" onClick={onRedirectToHistoryPage}>
        К истории изменений
      </Button>
    </Stack>
  );
};

export default ImportResult;
