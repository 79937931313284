import { IBaseRefItem } from 'api/types';
import { isDefined } from 'utils';

type RefItem = Pick<IBaseRefItem, 'isArchive'>;

// В текущей реализации применяется стратегия неисключающей фильтрации
// При возможных значениях isArchive=undefined или isArchive=null элемент не будет исключен при фильтрации

export const filterArchiveFalse = (ref: RefItem): boolean => {
  if (!isDefined(ref.isArchive)) return true;
  return ref.isArchive === false;
};

export const filterArchiveTrue = (ref: RefItem): boolean => {
  if (!isDefined(ref.isArchive)) return true;
  return ref.isArchive === true;
};
