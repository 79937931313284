import React, { useContext } from 'react';
import { connect } from 'react-redux';

import { Slide, Slider } from 'pure-react-carousel';

import { deleteStudentInterestActions } from '../../../../../actions';
import { IChildInfo, ICommonResponse, IInterestResponse } from '../../../../../api/types';
import { InterestSectionThemes } from '../../../../../containers/redesignStudent/StudentInterests/utils';
import { InterestsEditFormContext } from '../../../../../context';
import { IRootState } from '../../../../../reducers';
import { IInterestEntity } from '../../../../../types';
import { getInterestCategoryLabel } from '../../../../../utils';
import Carousel from '../../../../common/Carousel';
import InterestDataEntity from '../Interest';

import './index.scss';

interface IInterestCarouselProps {
  interests: IInterestEntity[];
  currentStudent: IChildInfo;
  studentInterests: ICommonResponse<IInterestResponse>;
  deleteStudentInterest: typeof deleteStudentInterestActions.request;
}

const InterestCarousel: React.FC<IInterestCarouselProps> = ({
  interests,
  currentStudent,
  studentInterests,
  deleteStudentInterest,
}) => {
  const { onOpen: onEditFormOpen, setEditFormAttributes, setEditFormData } = useContext(InterestsEditFormContext);

  const handleEditInterest = (id: number) => {
    const interest = interests.find((interest) => interest.id === id);
    if (!interest) return;

    const sectionTheme = InterestSectionThemes[interest.headCode];

    setEditFormAttributes({
      interestId: interest.id,
      interestName: interest.name,
      categorySectionName: getInterestCategoryLabel(interest.headCode),
      backgroundColor: sectionTheme.header.backgroundColor,
      backgroundImage: sectionTheme.header.editFormImage,
      titleColor: sectionTheme.header.titleColor,
    });

    setEditFormData(interest.editFormData);
    onEditFormOpen();
  };

  const handleDeleteInterest = (id: number) => {
    if (!currentStudent.meshId || studentInterests.loading) return;
    deleteStudentInterest(currentStudent.meshId, id);
  };

  return (
    <div className="interest-carousel">
      <Carousel items={interests} cardWidth={220} gap={12}>
        <Slider>
          {interests.map((interest, index) => (
            <Slide key={interest.id || index} index={index} className="slide">
              <InterestDataEntity interest={interest} onEdit={handleEditInterest} onDelete={handleDeleteInterest} />
            </Slide>
          ))}
        </Slider>
      </Carousel>
    </div>
  );
};

export default connect(
  (state: IRootState) => ({
    currentStudent: state.currentStudent,
    studentInterests: state.studentInterests,
  }),
  {
    deleteStudentInterest: deleteStudentInterestActions.request,
  },
)(InterestCarousel);
