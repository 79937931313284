import { FC } from 'react';

import { styled, Typography, TypographyProps, useMediaQuery } from '@mui/material';
import { getAccentColor, NeutralColors } from 'portfolio3/styles';
import { commonTheme } from 'portfolio3/styles/theme';
import { ISwitcherButtonBaseProps } from 'portfolio3/ui-kit';
import { getFocusStateStyles, resetButtonStyles } from 'portfolio3/ui-kit/utils';
import { inheritSx } from 'utils';

interface IStyledButtonProps {
  checked: boolean;
}

const ButtonTypography: FC<TypographyProps<'button'>> = (props) => {
  return <Typography {...props} component="button" type="button" />;
};

const StyledSwitcherButton = styled(ButtonTypography)<IStyledButtonProps>(({ theme, checked }) => {
  const activeColor = getAccentColor(theme.palette.accentColor, '100');

  return {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    height: '40px',
    fontSize: '14px',
    lineHeight: '20px',

    verticalAlign: 'start',
    color: checked ? activeColor : NeutralColors.light_neutrals_900,
    cursor: 'pointer',

    '&:focus-visible': {
      ...getFocusStateStyles(),
    },

    '&:after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,

      height: '4px',

      backgroundColor: checked ? activeColor : 'transparent',
    },

    ...inheritSx([resetButtonStyles]),
  };
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IUnderlinedTabButtonProps extends ISwitcherButtonBaseProps {}

const UnderlinedTabButton: FC<IUnderlinedTabButtonProps> = ({ content, InputProps, ...props }) => {
  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const isChecked = InputProps?.checked ?? false;

  const mobileVariant: TypographyProps['variant'] = isChecked ? 'Paragraph MD/Bold' : 'Paragraph MD/Medium';
  const desktopVariant: TypographyProps['variant'] = isChecked ? 'Paragraph LG/Bold' : 'Paragraph LG/Medium';

  return (
    <StyledSwitcherButton variant={isMobile ? mobileVariant : desktopVariant} checked={isChecked} {...props}>
      {content}
    </StyledSwitcherButton>
  );
};

export default UnderlinedTabButton;
