import { FC, useContext } from 'react';
import { connect } from 'react-redux';

import { ICommonResponse, IDictionaryItem } from 'api/types';
import { IndependentDiagnosticViewTypeCode, StudentSectionSettingTypeCodes } from 'const';
import { useWidgetVisibility } from 'hooks';
import { placeholderCharacter } from 'images';
import NoDataPlug from 'portfolio3/components/common/NoDataPlug';
import { WidgetContainer } from 'portfolio3/components/common/WidgetContainer';
import { IRootState } from 'reducers';
import {
  independentDiagnosticListLevelTypesSelector,
  independentDiagnosticListSelector,
  independentDiagnosticListSubjectsSelector,
} from 'selectors';
import { IIndependentDiagnosticData, IIndependentDiagnosticLevelType } from 'types';

import IndependentDiagnosticList from '../components/IndependentDiagnosticList';
import DiagnosticContext from '../context/diagnosticContext';
import IndependentAnalysisContainer from './IndependentAnalysisContainer';
import IndependentRatingContainer from './IndependentRatingContainer';

interface IIndependentDiagnosticsContainer {
  isIndependentDiagnosticListLoading: boolean;
  independentDiagnosticList: ICommonResponse<IIndependentDiagnosticData>;
  listLevelTypes: IIndependentDiagnosticLevelType[];
  listSubjects: IDictionaryItem[];
}

const IndependentDiagnosticsContainer: FC<IIndependentDiagnosticsContainer> = ({
  isIndependentDiagnosticListLoading,
  independentDiagnosticList,
  listLevelTypes,
  listSubjects,
}) => {
  const { filters } = useContext(DiagnosticContext);

  // видимость блока с рейтингом в независимых диагностиках
  const { isWidgetVisible: isRatingVisible } = useWidgetVisibility({
    sectionTypeCode: StudentSectionSettingTypeCodes.study,
    subsectionTypeCode: StudentSectionSettingTypeCodes.studyDiagnostic,
    subcategoryTypeCode: StudentSectionSettingTypeCodes.diagnosticIndependentRating,
  });

  // видимость блока со списком в независимых диагностиках
  const { isWidgetVisible: isListVisible } = useWidgetVisibility({
    sectionTypeCode: StudentSectionSettingTypeCodes.study,
    subsectionTypeCode: StudentSectionSettingTypeCodes.studyDiagnostic,
    subcategoryTypeCode: StudentSectionSettingTypeCodes.diagnosticIndependentList,
  });

  if (independentDiagnosticList.content.length === 0) {
    return (
      <NoDataPlug
        image={placeholderCharacter}
        title="Пройденных независимых диагностик пока нет"
        subtitle="Как только они появятся, информация о них отобразится здесь"
        loading={isIndependentDiagnosticListLoading}
      />
    );
  }

  return (
    <>
      {isRatingVisible && (
        <>
          {/* Таб с рейтингом */}
          {filters.independentViewType === IndependentDiagnosticViewTypeCode.rating && <IndependentRatingContainer />}
          {/* Таб с анализом */}
          {filters.independentViewType === IndependentDiagnosticViewTypeCode.analysis && (
            <IndependentAnalysisContainer />
          )}
        </>
      )}

      {isRatingVisible && isListVisible && <WidgetContainer.Divider />}

      {/* Список диагностик (фильтры уровень + предмет внутри компонента) */}
      {isListVisible && (
        <WidgetContainer.Padding>
          <IndependentDiagnosticList
            diagnostics={independentDiagnosticList.content}
            levelTypes={listLevelTypes}
            subjects={listSubjects}
          />
        </WidgetContainer.Padding>
      )}
    </>
  );
};

export default connect((state: IRootState) => ({
  isIndependentDiagnosticListLoading: state.independentDiagnostics.loading ?? false,
  independentDiagnosticList: independentDiagnosticListSelector(state),
  listLevelTypes: independentDiagnosticListLevelTypesSelector(state),
  listSubjects: independentDiagnosticListSubjectsSelector(state),
}))(IndependentDiagnosticsContainer);
