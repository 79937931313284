import { FC } from 'react';

import clsx from 'clsx';
import { crown } from 'portfolio3/features/diagnostics/images';
import { ISwitcherButtonBaseProps } from 'portfolio3/ui-kit/Switcher/types';

import { IRatingTabBase } from '../../../types';
import StyledRatingTab from './styled/Tab.styled';

interface IRatingTabProps extends IRatingTabBase, ISwitcherButtonBaseProps {
  content: JSX.Element;
}

const RatingTab: FC<IRatingTabProps> = ({ content, image, hasCrown, InputProps, disabled, ...props }) => {
  return (
    <StyledRatingTab
      className={clsx('rating-tab', {
        'rating-button--checked': InputProps?.checked,
        'rating-button--disabled': disabled,
      })}
      checked={InputProps?.checked}
      {...props}
    >
      <input type="radio" aria-hidden hidden {...InputProps} />
      <div className="rating-tab__icon-wrapper">
        <img className="rating-tab__icon" src={image} alt="Картинка" />
      </div>
      {content}
      {hasCrown && (
        <div className="rating-tab__crown-wrapper">
          <img className="rating-tab__crown" src={crown} alt="Корона" />
        </div>
      )}
    </StyledRatingTab>
  );
};

export default RatingTab;
