import React from 'react';

import { Typography } from '@mui/material';
import { getAccentColor } from 'portfolio3/styles';
import { SxStyles } from 'types';

import { iconBlueArrow } from '../../../icons';
import { Button } from '../../../ui-kit';

import './index.scss';

interface IShowMoreProps {
  hiddenCount: number;
  isAllShown: boolean;
  onShowInitialItems: () => void;
  onShowAllItems: () => void;
}

const buttonStyles: SxStyles = {
  color: getAccentColor('indigo', '200'),
};

const ShowMore: React.FC<IShowMoreProps> = ({ hiddenCount, isAllShown, onShowInitialItems, onShowAllItems }) => {
  return (
    <div className="button-more">
      {hiddenCount > 0 &&
        (isAllShown ? (
          <Button
            className="button-more__btn"
            endIcon={
              <img className="button-more__arrow button-more__arrow--flipped" src={iconBlueArrow} alt="скрыть" />
            }
            onClick={() => onShowInitialItems()}
          >
            <Typography sx={buttonStyles} variant="Paragraph MD/Medium">
              Свернуть
            </Typography>
          </Button>
        ) : (
          <Button
            className="button-more__btn"
            endIcon={<img className="button-more__arrow" src={iconBlueArrow} alt="показать еще" />}
            onClick={() => onShowAllItems()}
          >
            <Typography sx={buttonStyles} variant="Paragraph MD/Medium">
              Показать еще {hiddenCount}
            </Typography>
          </Button>
        ))}
    </div>
  );
};

export default ShowMore;
