import { styled } from '@mui/material';
import { IconButton } from '@mui/material';

import { ISectionColorTheme } from '../../../../types';

interface IComponentStyledProps {
  colors: ISectionColorTheme;
}

export const StyledIconAddEntityButton = styled(IconButton)(({ colors }: IComponentStyledProps) => ({
  '& svg': {
    width: 16,
    height: 16,
  },
  '& svg path': {
    stroke: colors.color_08,
  },
}));
