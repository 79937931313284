import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root: SxStyles = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const content: SxStyles = () => {
  return {
    width: '100%',
    height: '100%',

    borderRadius: '8px',

    overflow: 'hidden',
    boxShadow: `0px 4px 6px -2px rgba(41, 41, 64, 0.04), 0px 10px 15px -3px rgba(41, 41, 64, 0.08);`,
    background: NeutralColors.light_neutrals_0,
    outline: 'none',
  };
};
