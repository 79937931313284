export enum ProffClassesKeys {
  ENGINEERING_CLASS = 'engineeringClass',
  MEDICAL_CLASS = 'medicalClass',
  KURCHATOV_CLASS = 'kurchatovClass',
  ACADEMIC_CLASS = 'academicClass',
  IT_CLASS = 'itClass',
  PEDAGOGICAL_CLASS = 'pedagogicalClass',
  ENTREPRENEURIAL_CLASS = 'entrepreneurialClass',
  MEDIA_CLASS = 'mediaClass',
  CADET_CLASS = 'cadetClass',
  MATHEMATICAL_VERTICAL = 'mathematicalVertical',
  SPORT_VERTICAL = 'sportVertical',
  NATURAL_SCIENCE_VERTICAL = 'naturalScienceVertical',
  LINGUISTIC_VERTICAL = 'linguisticVertical',
  IT_VERTICAL = 'itVertical',
  MATHEMATICAL_VERTICAL_PLUS = 'mathematicalVerticalPlus',
  SPORT_CLASS = 'sportClass',
}

// proff_classes_ref
export const ProffClassesCodes: Record<ProffClassesKeys, number> = {
  [ProffClassesKeys.ENGINEERING_CLASS]: 1,
  [ProffClassesKeys.MEDICAL_CLASS]: 2,
  [ProffClassesKeys.KURCHATOV_CLASS]: 3,
  [ProffClassesKeys.ACADEMIC_CLASS]: 4,
  [ProffClassesKeys.IT_CLASS]: 5,
  [ProffClassesKeys.PEDAGOGICAL_CLASS]: 6,
  [ProffClassesKeys.ENTREPRENEURIAL_CLASS]: 7,
  [ProffClassesKeys.MEDIA_CLASS]: 8,
  [ProffClassesKeys.CADET_CLASS]: 9,
  [ProffClassesKeys.MATHEMATICAL_VERTICAL]: 10,
  [ProffClassesKeys.SPORT_VERTICAL]: 11,
  [ProffClassesKeys.NATURAL_SCIENCE_VERTICAL]: 12,
  [ProffClassesKeys.LINGUISTIC_VERTICAL]: 13,
  [ProffClassesKeys.IT_VERTICAL]: 14,
  [ProffClassesKeys.MATHEMATICAL_VERTICAL_PLUS]: 15,
  [ProffClassesKeys.SPORT_CLASS]: 16,
};
