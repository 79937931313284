/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useContext, useMemo } from 'react';

import { RewardSecondaryBlock } from '../basicBlocks';
import { IDictionaryItem, SportAgeRefItem } from '../../../../../api/types';

import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';
import { IController } from 'portfolio3/ui-kit/types';
import { BaseInputLabel, FormControl } from 'portfolio3/ui-kit';
import { FormHelperText } from '@mui/material';
import { Select } from 'portfolio3/ui-kit/selects';

interface ITourismRewardSecondaryBlockProps {
  levelOptions: IDictionaryItem[];
  ageOptions: SportAgeRefItem[];
}

const TourismRewardSecondaryBlock: React.FC<ITourismRewardSecondaryBlockProps> = ({
  levelOptions,
  ageOptions,
}) => {
  const { inputRenderMode, inputSize, formData, formErrors, onChangeFormData, onChangeFormErrors } = useContext(LocalDataEntryFormContext);

  const eventLevelController: IController<string | undefined> = useMemo(() => ({
    handleChange: (eventLevel) => {
      onChangeFormData((prevState) => ({
        ...prevState,
        eventLevel: Number(eventLevel)
      }));
      onChangeFormErrors((prevState) => ({
        ...prevState,
        eventLevelError: false
      }));
    },
    handleBlur: (type) => {
      if (!type) {
        onChangeFormErrors((prevState) => ({
          ...prevState,
          eventLevelError: true
        }));
      }
    }
  }), [formData.id]);

  const ageController: IController<string | undefined> = useMemo(() => ({
    handleChange: (age) => {
      onChangeFormData((prevState) => ({
        ...prevState,
        age: Number(age)
      }));
    }
  }), [formData.id]);

  const additionalTopControllers = (
    <>
      <FormControl
        required
        renderMode={inputRenderMode}
        inputSize={inputSize}
        label={<BaseInputLabel>Уровень награды</BaseInputLabel>}
        error={formErrors.eventLevelError}
        helper={formErrors.eventLevelError && <FormHelperText>Выберите уровень награды</FormHelperText>}
        control={
          <Select
            inputRenderMode={inputRenderMode}
            inputSize={inputSize}
            placeholder="Выберите уровень награды..."
            value={formData.eventLevel}
            options={levelOptions}
            controller={eventLevelController}
          />
        }
      />
      <FormControl
        renderMode={inputRenderMode}
        inputSize={inputSize}
        label={<BaseInputLabel>Возрастное ограничение</BaseInputLabel>}
        control={
          <Select
            inputRenderMode={inputRenderMode}
            inputSize={inputSize}
            placeholder="Выберите возрастное ограничение..."
            value={formData.age}
            options={ageOptions}
            controller={ageController}
          />
        }
      />
    </>
  );

  return (
    <RewardSecondaryBlock
      additionalTopControllers={additionalTopControllers}
    />
  );
};

export default TourismRewardSecondaryBlock;
