import { FC, ReactNode } from 'react';

import { ThemeProvider } from '@mui/material';
import { AdminSectionSettingKeys, GiaType, SectionKeys, SectionNames, sectionRoutes, studyNavigation } from 'const';
import { useSectionTheme, useStudentNavigation } from 'hooks';
import { IconSectionStudy } from 'icons';
import { studyTheme } from 'portfolio3/styles/theme';

import { ExamGiaCard, IndependentDiagnosticCard, SelfDiagnosticCard } from '../components/cards';
import SearchSection from '../components/SearchSection';
import { useSearchStudy } from '../hooks';
import CommonEntityCardList from './CardLists/CommonEntityCardList';
import CommonRewardCardList from './CardLists/CommonRewardCardList';
import DocumentCardList from './CardLists/DocumentCardList';

const getGiaSectionId = (formaGia: string) => {
  switch (formaGia) {
    case GiaType.ege:
      return AdminSectionSettingKeys.EGE;
    case GiaType.oge:
      return AdminSectionSettingKeys.OGE;
    case GiaType.gve9:
      return AdminSectionSettingKeys.GVE9;
    case GiaType.gve11:
      return AdminSectionSettingKeys.GVE11;
    case GiaType.other:
      return AdminSectionSettingKeys.OTHER;
    default:
      return AdminSectionSettingKeys.GIA;
  }
};

interface IStudySectionProps {
  onCloseSearcher: () => void;
}

const StudySection: FC<IStudySectionProps> = ({ onCloseSearcher }) => {
  const {
    spoTraining,
    spoPractice,
    documents,
    gia,
    independentDiagnostics,
    selfDiagnostics,
    rewards,
    olympiads,
    isStudySpoTrainingVisible,
    isStudySpoPracticeVisible,
    isStudyDocumentsVisible,
    isStudyGiaVisible,
    isStudyIndependentDiagnosticsVisible,
    isStudySelfDiagnosticsVisible,
    isStudyRewardVisible,
    isStudyOlympiadVisible,
    isStudyVisible,
  } = useSearchStudy();

  const { navigateToSection, navigateToWidget } = useStudentNavigation();

  const theme = useSectionTheme(studyTheme);

  if (!isStudyVisible) return null;

  const viewExamCard = (formaGia: string) => {
    onCloseSearcher();
    navigateToSection(sectionRoutes.STUDY);
    navigateToWidget(getGiaSectionId(formaGia), true);
  };

  const viewDiagnosticCard = () => {
    onCloseSearcher();
    navigateToSection(sectionRoutes.STUDY);
    navigateToWidget(studyNavigation.diagnostics.id, true);
  };

  const subsections: ReactNode[] = [];

  if (isStudySpoTrainingVisible) {
    subsections.push(
      <SearchSection.Subsection title="Сведения об обучении">
        <CommonEntityCardList entities={spoTraining} />
      </SearchSection.Subsection>,
    );
  }

  if (isStudySpoPracticeVisible) {
    subsections.push(
      <SearchSection.Subsection title="Практика">
        <CommonEntityCardList entities={spoPractice} />
      </SearchSection.Subsection>,
    );
  }

  if (isStudyDocumentsVisible) {
    subsections.push(
      <SearchSection.Subsection title="Документы">
        <DocumentCardList documents={documents} />
      </SearchSection.Subsection>,
    );
  }

  if (isStudyGiaVisible) {
    const cards = gia.map((exam, index, array) => {
      const handleView = () => viewExamCard(exam.formaGia);
      const card = <ExamGiaCard exam={exam} onView={handleView} />;
      return (
        <SearchSection.CardContainer
          key={exam.id}
          card={card}
          mapIndex={index}
          totalCards={array.length}
          onView={handleView}
        />
      );
    });
    subsections.push(
      <SearchSection.Subsection title="Государственная итоговая аттестация">{cards}</SearchSection.Subsection>,
    );
  }

  if (isStudyIndependentDiagnosticsVisible) {
    const cards = independentDiagnostics.map((diagnostic, index, array) => {
      const card = <IndependentDiagnosticCard diagnostic={diagnostic} onView={viewDiagnosticCard} />;
      return (
        <SearchSection.CardContainer
          key={`${diagnostic.subject}-${index}`}
          card={card}
          mapIndex={index}
          totalCards={array.length}
          onView={viewDiagnosticCard}
        />
      );
    });
    subsections.push(<SearchSection.Subsection title="Независимые диагностики">{cards}</SearchSection.Subsection>);
  }

  if (isStudySelfDiagnosticsVisible) {
    subsections.push(
      <SearchSection.Subsection title="Самодиагностики">
        {selfDiagnostics.map((selfDiagnostic, index, array) => {
          const card = (
            <SelfDiagnosticCard name={selfDiagnostic.name} total={selfDiagnostic.total} onView={viewDiagnosticCard} />
          );
          return (
            <SearchSection.CardContainer
              key={index}
              card={card}
              mapIndex={index}
              totalCards={array.length}
              onView={viewDiagnosticCard}
            />
          );
        })}
      </SearchSection.Subsection>,
    );
  }

  if (isStudyRewardVisible) {
    subsections.push(
      <SearchSection.Subsection title="Награды">
        <CommonRewardCardList rewards={rewards} />
      </SearchSection.Subsection>,
    );
  }

  if (isStudyOlympiadVisible) {
    subsections.push(
      <SearchSection.Subsection title="Олимпиады">
        <CommonEntityCardList entities={olympiads} />
      </SearchSection.Subsection>,
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <SearchSection name={SectionNames[SectionKeys.STUDY]} icon={<IconSectionStudy />} subsections={subsections} />
    </ThemeProvider>
  );
};

export default StudySection;
