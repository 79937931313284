/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { Line } from '@reactchartjs/react-chart.js';
import { NeutralColors } from 'portfolio3/styles';
import { commonTheme } from 'portfolio3/styles/theme';
import { numberToPixelsString } from 'utils';

import * as styles from './styles';
import { DiagnosticLevels, diagnosticLevelsNames, IDiagnosticChartData, IDiagnosticChartItem } from './types';

/**
 * * Линейный график динамики данных по предметам
 *
 * *` @chartData` - массив данных для построения графика.
 *            У каждого элемента обязательно должен быть interval (Подпись по оси X).
 *            Для отображения динамики по одному предмету требуется передать массив из одного элемента.
 *
 * * `@lineColors` - массив строк с цветами линий.
 *                   Порядок цветов должен совпадать с порядком в массиве данных для графика.
 *
 * * `@DiagnosticLevelsChartValues` - условные единицы для расположения данных на 4-х уровнях по оси Y
 *                              (можно изменить на другие значения с равным шагом),
 *                              но потребуется изменить в options -> scales -> yAxes -> ticks -> stepSize
 *
 * * `@labels` - подписи по оси X формируются по значениям interval первого элмента в массиве chartData
 *
 */

// value необязательно явно задавать undefined, как и type
// Пример входных данных:
// const exampleChartData: IDiagnosticChartData[] = [
//   {
//     name: 'Литература',
//     values: [
//       { value: 80, interval: '2020', type: 'high' },
//       { value: 100, interval: '2021', type: 'elevated' },
//       { value: undefined, interval: '2022' },
//     ],
//   },
//   {
//     name: 'Математика',
//     values: [
//       { value: 100, interval: '2020', type: 'elevated' },
//       { value: 50, interval: '2021', type: 'base' },
//       { value: 10, interval: '2022', type: 'belowBase' },
//     ],
//   },
// ];

const DiagnosticLevelsChartValues: Record<DiagnosticLevels, number> = {
  belowBase: 0,
  base: 5,
  overHigh: 10,
  high: 15,
};

interface IDynamicSubjectChartProps {
  chartData: IDiagnosticChartData[];
  lineColors: string[];
  verticalLegendSpacing: number;
  chartHeight: number;
}

const DynamicSubjectChart: React.FC<IDynamicSubjectChartProps> = ({
  lineColors,
  chartData,
  verticalLegendSpacing,
  chartHeight,
}) => {
  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const wrapperWidth = isMobile ? 162 : 331;

  const typographyVariant = isMobile ? 'Paragraph TN/Semi Bold' : 'Paragraph MD/Medium';

  const labelsSet = new Set<string>();
  chartData.forEach((data) => data.values.forEach((value) => labelsSet.add(value.interval)));
  const labels = Array.from(labelsSet).sort((left, right) => Number(left) - Number(right));

  const getDataValues = (item: IDiagnosticChartItem) => {
    if (!item.value || !item.levelType) return undefined;
    // y - для отображения графика по уровням
    // interval и value - для отображения настоящих данных в тултипе
    return {
      y: DiagnosticLevelsChartValues[item.levelType],
      value: item.value,
      interval: item.interval,
    };
  };

  const datasets = chartData.map((data, index) => {
    // маппинг данных по одному предмету на массив лейблов (интервалов)
    // при отсутствии данных по предмету за период y и value заполняются как undefined
    const mappedValues = labels.map((interval) => {
      const dataForInterval = data.values.find((value) => value.interval === interval);

      if (dataForInterval) {
        return getDataValues(dataForInterval);
      }

      return {
        y: undefined,
        value: undefined,
        interval,
      };
    });

    return {
      label: data.name,
      data: mappedValues,
      borderColor: lineColors[index],
      fill: false,
      tension: 0,
      pointRadius: 5,
      pointHoverRadius: 5,
      pointBorderWidth: 2,
      pointHoverBorderWidth: 2,
      pointBackgroundColor: 'white',
      pointBorderColor: lineColors[index],
    };
  });
  const data = { labels, datasets };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    spanGaps: true,
    layout: { padding: 8 },
    legend: { display: false },
    plugins: { datalabels: { display: false } },
    tooltips: {
      bodyFontFamily: 'Inter',
      displayColors: false,
      bodySpacing: 4,
      titleSpacing: 4,
      callbacks: {
        title() {
          return;
        },
        label(tooltipItem: any, data: any) {
          return data?.datasets[tooltipItem?.datasetIndex]?.label || '';
        },
        afterLabel(tooltipItem: any, data: any) {
          // Получение фактического значения баллов
          const currentInterval = tooltipItem?.label;
          const datasetIndex = tooltipItem?.datasetIndex;
          const currentDataItem = data?.datasets[datasetIndex]?.data?.find(
            (item: IDiagnosticChartItem) => item?.interval === currentInterval,
          );
          return currentDataItem ? `Баллов: ${currentDataItem?.value}` : '';
        },
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            display: false,
            stepSize: 5,
            min: DiagnosticLevelsChartValues.belowBase,
            max: DiagnosticLevelsChartValues.high,
          },
          gridLines: { drawBorder: false },
        },
      ],
      xAxes: [{ gridLines: { borderDash: [10, 15], drawBorder: false, offsetGridLines: false } }],
    },
  };

  return (
    <Stack direction="row" sx={styles.wrapper} className="subject-chart">
      <Stack sx={styles.legend} className="subject-chart__legend">
        {diagnosticLevelsNames.map((name, index) => (
          <Typography
            variant={typographyVariant}
            key={name}
            color={NeutralColors.light_neutrals_600}
            sx={{ marginTop: index !== 0 ? numberToPixelsString(verticalLegendSpacing) : 0, whiteSpace: 'nowrap' }}
          >
            {name}
          </Typography>
        ))}
        <Typography variant={typographyVariant} sx={styles.rotatedLabel}>
          уровень знаний
        </Typography>
      </Stack>
      <Box sx={styles.chartWrapper} className="subject-chart__scroll-area">
        <Box
          sx={styles.chart(labels.length, isMobile, wrapperWidth, chartHeight)}
          className="subject-chart__chart-area"
        >
          <Line data={data} options={options} />
        </Box>
      </Box>
    </Stack>
  );
};

export default DynamicSubjectChart;
