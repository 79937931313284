/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useContext, useMemo } from 'react';

import { RewardSecondaryBlock } from '../basicBlocks';
import { SportAgeRefItem } from '../../../../../api/types';

import { BaseInputLabel, FormControl } from 'portfolio3/ui-kit';
import { FormHelperText } from '@mui/material';
import { Select } from 'portfolio3/ui-kit/selects';
import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';
import { IController } from 'portfolio3/ui-kit/types';

interface IGtoRewardSecondaryBlockProps {
  ageOptions: SportAgeRefItem[];
}

const GtoRewardSecondaryBlock: React.FC<IGtoRewardSecondaryBlockProps> = ({
  ageOptions,
}) => {
  const { inputRenderMode, inputSize, formData, formErrors, onChangeFormData } = useContext(LocalDataEntryFormContext);

  const ageController: IController<string | undefined> = useMemo(() => ({
    handleChange: (age) => {
      onChangeFormData((prevState) => ({
        ...prevState,
        age: Number(age)
      }));
    }
  }), [formData.id]);

  const additionalTopControllers = (
    <>
      <FormControl
        required
        renderMode={inputRenderMode}
        inputSize={inputSize}
        label={<BaseInputLabel>Возрастное ограничение (ступень)</BaseInputLabel>}
        error={formErrors.ageError}
        helper={formErrors.ageError && <FormHelperText>Выберите ступень</FormHelperText>}
        control={
          <Select
            inputRenderMode={inputRenderMode}
            inputSize={inputSize}
            placeholder="Выберите ступень..."
            value={formData.age}
            options={ageOptions}
            controller={ageController}
          />
        }
      />
    </>
  );

  return (
    <RewardSecondaryBlock
      additionalTopControllers={additionalTopControllers}
      isRewardNumberRequired
    />
  );
};

export default GtoRewardSecondaryBlock;
