import { FC, ReactElement } from 'react';
import { connect } from 'react-redux';

import { useMediaQuery } from '@mui/material';
import { ExpandableEntityList } from 'components/redesignStudent';
import {
  useBrightTheme,
  useDataEntryDrawer,
  useEntityFilters,
  useScrollToElement,
  useUserFunctionality,
  useWidgetVisibility,
} from 'hooks';
import useDataLoad, { IDataRequest } from 'hooks/dataLoad/useDataLoad';
import { placeholderCharacter } from 'images';
import NoDataPlug from 'portfolio3/components/common/NoDataPlug';
import { WidgetContainer as Widget } from 'portfolio3/components/common/WidgetContainer';
import { IModifiedPersonObject } from 'portfolio3/features/dataEntryForm';
import { commonTheme } from 'portfolio3/styles/theme';
import { IRootState } from 'reducers';
import { IEntity } from 'types';

import { mapEntityToElementWithoutYear, mapEntityToElementWithYear } from '../utils';
import WidgetContainerAddButton from '../WidgetContainerAddButton';
import WidgetContainerEventSwitcher from '../WidgetContainerEventSwitcher';
import WidgetContainerOptions from '../WidgetContainerOptions';
import WidgetContainerTitle from '../WidgetContainerTitle';

interface IReduxProps {
  linkMode: boolean | undefined;
}

interface IWidgetEventsContainerProps extends IReduxProps {
  containerData: { id: string; label: string };
  title: string;
  initialFormData: Partial<IModifiedPersonObject>;
  sectionTypeCode: string;
  subsectionTypeCode: string;
  events: IEntity[];
  isLoading: boolean;
  noDataTitle: string;
  noDataSubtitle: string;
  mapEntityToElement: (entity: IEntity) => ReactElement;
  excludedEventTypeOptions?: number[];
  dataRequests?: IDataRequest[];
  backgroundImage?: string;
}

/**
 * Компонент для отображения виджета по сущностям event, включает в себя фильтр наград
 */
const WidgetEventsContainer: FC<IWidgetEventsContainerProps> = ({
  linkMode,

  containerData,
  title,
  initialFormData,
  sectionTypeCode,
  subsectionTypeCode,
  events,
  isLoading,
  noDataTitle,
  noDataSubtitle,
  mapEntityToElement,
  excludedEventTypeOptions,
  dataRequests,
  backgroundImage,
}) => {
  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const { handleOpenPrimaryDataEntry } = useDataEntryDrawer();
  const openDataEntry = () => {
    handleOpenPrimaryDataEntry(initialFormData, {
      widgetLabel: containerData.label,
      excludedEventTypes: excludedEventTypeOptions,
    });
  };

  const isBrightTheme = useBrightTheme();
  const { isSettingsMode, isWidgetVisible, toggleElement, overlayElement } = useWidgetVisibility({
    sectionTypeCode,
    subsectionTypeCode,
  });

  useDataLoad({ shouldLoad: isWidgetVisible, requests: dataRequests ?? [] });

  const {
    filteredEntities,
    rewardEntities,
    displayedEntityCount,
    hiddenEntityCount,
    filterValue,
    setInitialEntityCount,
    setAllEntityCount,
    setFilterValue,
  } = useEntityFilters({ containerId: containerData.id, entities: events, initialEntityCount: 5 });

  const { scrollToElementGlobal } = useScrollToElement();
  const { isAdmin } = useUserFunctionality();

  const isAddButtonVisible = !linkMode && !isAdmin && !isSettingsMode;

  const hasHiddenEntities = hiddenEntityCount > 0;
  const totalEntitites = events.length;
  const hasData = events.length > 0;
  const shownEntities = displayedEntityCount;

  const handleSetInitialDisplayedEntities = () => {
    setInitialEntityCount();
    scrollToElementGlobal(containerData.id);
  };

  const handleFilterChange = (value: number) => {
    setFilterValue(value);
  };

  const switcherElement = (
    <WidgetContainerEventSwitcher
      isMobile={isMobile}
      value={filterValue}
      allCount={totalEntitites}
      rewardCount={rewardEntities.length}
      onChange={handleFilterChange}
    />
  );
  const buttonAddElement = <WidgetContainerAddButton isMobile={isMobile} isReward={false} onClick={openDataEntry} />;
  const renderedButton = isAddButtonVisible && buttonAddElement; // условие отображения кнопки
  const titleRightElement = isSettingsMode ? toggleElement : !hasData && renderedButton;
  const noDataPlugElement = (
    <NoDataPlug image={placeholderCharacter} title={noDataTitle} subtitle={noDataSubtitle} loading={isLoading} />
  );

  if (!isWidgetVisible) return null;

  const mapEntity = isMobile
    ? mapEntityToElementWithoutYear(mapEntityToElement)
    : mapEntityToElementWithYear(mapEntityToElement);

  return (
    <Widget containerData={containerData} withBackground={isBrightTheme}>
      {overlayElement}
      <Widget.Header
        title={<WidgetContainerTitle title={title} right={titleRightElement} />}
        options={<WidgetContainerOptions left={hasData && switcherElement} right={hasData && renderedButton} />}
        backgroundImage={backgroundImage}
      />
      <Widget.Padding>
        <ExpandableEntityList
          hasHiddenEntities={hasHiddenEntities}
          shownEntities={shownEntities}
          totalEntitites={filteredEntities?.length || 0}
          onSetAllEntities={setAllEntityCount}
          onSetInitialEntities={handleSetInitialDisplayedEntities}
        >
          {filteredEntities && filteredEntities.length > 0
            ? filteredEntities.slice(0, displayedEntityCount).map(mapEntity)
            : noDataPlugElement}
        </ExpandableEntityList>
      </Widget.Padding>
    </Widget>
  );
};

export default connect((state: IRootState) => ({
  linkMode: state.linkMode.mode,
}))(WidgetEventsContainer);
