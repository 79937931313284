import { styled } from '@mui/material';
import { getAccentColor } from 'portfolio3/styles';

import StyledBaseButton from '../bases/BaseButton.styled';

const TetriaryButton = styled(StyledBaseButton)(({ theme }) => {
  const accentColor = theme.palette.accentColor;
  const color_100 = getAccentColor(accentColor, '100');
  const color_48 = getAccentColor(accentColor, '48');
  const color_24 = getAccentColor(accentColor, '24');
  const color_12 = getAccentColor(accentColor, '12');

  return {
    color: color_100,
    backgroundColor: color_12,
    border: 0,

    '&:hover': {
      backgroundColor: color_24,
    },

    '&:active': {
      backgroundColor: color_48,
    },

    '&:disabled': {
      color: color_48,
      backgroundColor: color_12,
    },
  };
});

export default TetriaryButton;
