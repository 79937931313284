import { FC, useRef, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';

import { Box, Menu } from '@mui/material';
import clsx from 'clsx';
import { useHiddenHorizontalList } from 'hooks';
import { IconChevronSmallDown, IconChevronSmallUp } from 'icons';
import { NeutralColors } from 'portfolio3/styles';

import { IPortfolioNavigationCommonProps, ISectionNavigationLink } from '../types';
import NavigationButton from './Button';
import ButtonMore from './ButtonMore';
import * as styles from './styles';

const PortfolioNavigation: FC<IPortfolioNavigationCommonProps> = ({ primaryNavigation, secondaryNavigation }) => {
  const [isHiddenNavigationsOpen, setIsHiddenNavigationsOpen] = useState(false);
  const navigationContainerRef = useRef<HTMLDivElement>(null);
  const buttonMoreRef = useRef<HTMLButtonElement>(null);
  const { pathname: currentPath } = useLocation();
  const match = useRouteMatch();

  const elementsGap = 4;
  const { visibleElementsCount } = useHiddenHorizontalList({
    containerRef: navigationContainerRef,
    buttonMoreRef,
    elementsGap,
    allElementsCount: primaryNavigation.length,
    hiddenElementClassName: 'visually-hidden',
    additionalDeps: [match],
    onAllElementsVisible: () => setIsHiddenNavigationsOpen(false),
  });

  const handleOpenMoreMenu = () => {
    setIsHiddenNavigationsOpen(true);
  };

  const handleCloseMoreMenu = () => {
    setIsHiddenNavigationsOpen(false);
  };

  const mapNavigationButton = (navigation: ISectionNavigationLink, isHidden?: boolean, withIcon?: boolean) => {
    const Icon = navigation.icon;

    return (
      <NavigationButton
        name={navigation.name}
        key={navigation.name}
        to={`${match.url}${navigation.to}`}
        onClick={handleCloseMoreMenu}
        tabIndex={isHidden ? -1 : undefined}
        startAdornment={withIcon ? <Icon /> : null}
      />
    );
  };

  const ArrowIcon = isHiddenNavigationsOpen ? IconChevronSmallUp : IconChevronSmallDown;
  const hiddenNavigation = primaryNavigation.slice(visibleElementsCount);
  const isActiveHiddenNavigation = hiddenNavigation.some(
    (navigation) => `${match.url}${navigation.to}` === currentPath,
  );

  return (
    <Box className="desktop-navigation" sx={styles.root}>
      <Box sx={styles.mainNavigationsContainer} ref={navigationContainerRef}>
        {primaryNavigation.map((navigation) => mapNavigationButton(navigation))}
        <ButtonMore
          onClick={handleOpenMoreMenu}
          ref={buttonMoreRef}
          className={clsx({
            'visually-hidden': visibleElementsCount === primaryNavigation.length || primaryNavigation.length === 0,
            'active-button-more': isActiveHiddenNavigation,
          })}
          id="navigation-button-more"
          aria-haspopup="true"
          aria-controls={isHiddenNavigationsOpen ? 'navigation-menu-more' : undefined}
          aria-expanded={isHiddenNavigationsOpen ? 'true' : undefined}
          tabIndex={visibleElementsCount === primaryNavigation.length ? -1 : 0}
        >
          Ещё
          <ArrowIcon fill={NeutralColors.light_neutrals_900} />
        </ButtonMore>
        <Menu
          sx={styles.menu}
          anchorEl={buttonMoreRef.current}
          open={isHiddenNavigationsOpen}
          onClose={handleCloseMoreMenu}
          id="navigation-menu-more"
          MenuListProps={{
            'aria-labelledby': 'button-more',
          }}
        >
          {hiddenNavigation.map((navigation) => mapNavigationButton(navigation, true))}
        </Menu>
      </Box>
      <Box sx={styles.sideNavigationsContainer}>
        {secondaryNavigation.map((navigation) => mapNavigationButton(navigation, false, true))}
      </Box>
    </Box>
  );
};

export default PortfolioNavigation;
