import { IRootState } from 'reducers';
import { createSelector } from 'reselect';

import { mapStudySpoDocumentResponse } from './mappers';

const studyDocumentsSelector = createSelector(
  [(state: IRootState) => state.studentSpoDocuments, (state: IRootState) => state.documentRef],
  (studentDocuments, documentRef) => {
    return mapStudySpoDocumentResponse(studentDocuments, documentRef);
  },
);

export default studyDocumentsSelector;
