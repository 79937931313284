/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { MoonLoader } from 'react-spinners';

import { ColorKeys, Colors } from 'style/variables';

const ResultsLoader: React.FC = () => (
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
    <MoonLoader color={Colors[ColorKeys.VIOLET_STRONG]} />
  </div>
);

export default ResultsLoader;
