import { getAccentColor, NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root: SxStyles = {
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr) auto',
};

export const iconContainer: SxStyles = {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',

  '&::after': {
    content: '""',
    position: 'absolute',

    width: '100%',
    height: '2px',
  },
};

export const stepLineCompleted: SxStyles = {
  '&::after': {
    backgroundColor: getAccentColor('green', '100'),
  },
};

export const stepLineInProgress: SxStyles = {
  '&::after': {
    backgroundColor: NeutralColors.light_neutrals_400,
  },
};

const commonStepIconStyles: SxStyles = {
  zIndex: 10,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  width: '24px',
  height: '24px',

  borderRadius: '50%',
};

export const stepIconCurrent: SxStyles = {
  ...commonStepIconStyles,

  position: 'relative',
};

export const stepIconCurrentOuter: SxStyles = {
  ...commonStepIconStyles,

  position: 'absolute',
  width: '32px',
  height: '32px',

  border: '2px solid',
  borderColor: getAccentColor('green', '100'),
  backgroundColor: NeutralColors.light_neutrals_0,
};

export const stepIconCurrentInner: SxStyles = {
  ...commonStepIconStyles,

  width: '20px',
  height: '20px',

  border: '2px solid',
  borderColor: NeutralColors.light_neutrals_400,
};

export const stepIconCompleted: SxStyles = {
  ...commonStepIconStyles,

  backgroundColor: getAccentColor('green', '100'),

  svg: {
    width: '14px',
    height: '14px',
    color: '#ffffff',
  },
};

export const stepIconNotStarted: SxStyles = {
  ...commonStepIconStyles,

  border: '2px solid',
  borderColor: NeutralColors.light_neutrals_400,
  backgroundColor: NeutralColors.light_neutrals_200,
};
