import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import * as styles from './styles';

const SubjectHint: FC = () => {
  return (
    <Box className="subject-hint" sx={styles.root}>
      <Typography variant="Paragraph MD/Regular" sx={styles.text}>
        Нажми на любой предмет, чтобы увидеть подробную информацию по нему
      </Typography>
      <Box className="subject-hint__image" sx={styles.image} />
    </Box>
  );
};

export default SubjectHint;
