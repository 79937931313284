/* eslint-disable max-len */
import { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const Mouse: FC<SvgIconProps> = (props) => (
  <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#filter0_i_64_89995)">
      <circle cx="8.50781" cy="10.5" r="6.5" fill="url(#paint0_linear_64_89995)" />
      <circle cx="8.50781" cy="10.5" r="6.5" fill="url(#paint1_radial_64_89995)" />
      <circle cx="8.50781" cy="10.5" r="6.5" fill="url(#paint2_radial_64_89995)" />
    </g>
    <circle cx="8.00781" cy="11" r="5" fill="url(#paint3_radial_64_89995)" />
    <circle cx="8.00781" cy="11" r="5" fill="url(#paint4_radial_64_89995)" />
    <g filter="url(#filter1_i_64_89995)">
      <circle cx="23.5078" cy="10.5" r="6.5" fill="url(#paint5_radial_64_89995)" />
      <circle cx="23.5078" cy="10.5" r="6.5" fill="url(#paint6_radial_64_89995)" />
      <circle cx="23.5078" cy="10.5" r="6.5" fill="url(#paint7_radial_64_89995)" />
      <circle cx="23.5078" cy="10.5" r="6.5" fill="url(#paint8_radial_64_89995)" />
    </g>
    <g filter="url(#filter2_i_64_89995)">
      <circle cx="24.0078" cy="11" r="5" fill="url(#paint9_radial_64_89995)" />
      <circle cx="24.0078" cy="11" r="5" fill="url(#paint10_radial_64_89995)" />
    </g>
    <path
      d="M24.9766 18C24.9766 22.8232 20.5491 26.2411 17.1754 27.3216C16.4159 27.5649 15.6017 27.5564 14.8367 27.3309C11.27 26.2796 7.10156 23.2261 7.10156 18C7.10156 14.025 10.8203 10.0312 16.0078 10.0312C22.4328 10.0312 24.9766 15.375 24.9766 18Z"
      fill="url(#paint11_linear_64_89995)"
    />
    <path
      d="M24.9766 18C24.9766 22.8232 20.5491 26.2411 17.1754 27.3216C16.4159 27.5649 15.6017 27.5564 14.8367 27.3309C11.27 26.2796 7.10156 23.2261 7.10156 18C7.10156 14.025 10.8203 10.0312 16.0078 10.0312C22.4328 10.0312 24.9766 15.375 24.9766 18Z"
      fill="url(#paint12_radial_64_89995)"
    />
    <path
      d="M24.9766 18C24.9766 22.8232 20.5491 26.2411 17.1754 27.3216C16.4159 27.5649 15.6017 27.5564 14.8367 27.3309C11.27 26.2796 7.10156 23.2261 7.10156 18C7.10156 14.025 10.8203 10.0312 16.0078 10.0312C22.4328 10.0312 24.9766 15.375 24.9766 18Z"
      fill="url(#paint13_radial_64_89995)"
    />
    <path
      d="M24.9766 18C24.9766 22.8232 20.5491 26.2411 17.1754 27.3216C16.4159 27.5649 15.6017 27.5564 14.8367 27.3309C11.27 26.2796 7.10156 23.2261 7.10156 18C7.10156 14.025 10.8203 10.0312 16.0078 10.0312C22.4328 10.0312 24.9766 15.375 24.9766 18Z"
      fill="url(#paint14_radial_64_89995)"
    />
    <path
      d="M24.9766 18C24.9766 22.8232 20.5491 26.2411 17.1754 27.3216C16.4159 27.5649 15.6017 27.5564 14.8367 27.3309C11.27 26.2796 7.10156 23.2261 7.10156 18C7.10156 14.025 10.8203 10.0312 16.0078 10.0312C22.4328 10.0312 24.9766 15.375 24.9766 18Z"
      fill="url(#paint15_radial_64_89995)"
    />
    <g filter="url(#filter3_f_64_89995)">
      <rect x="18.8018" y="19.1875" width="1.48438" height="3" rx="0.742188" fill="#B9A8CE" />
    </g>
    <rect x="19.2266" y="19" width="1.48438" height="3" rx="0.742188" fill="url(#paint16_linear_64_89995)" />
    <rect x="19.2266" y="19" width="1.48438" height="3" rx="0.742188" fill="url(#paint17_linear_64_89995)" />
    <rect x="19.2266" y="19" width="1.48438" height="3" rx="0.742188" fill="url(#paint18_radial_64_89995)" />
    <rect x="11.2891" y="19" width="1.48438" height="3" rx="0.742188" fill="url(#paint19_linear_64_89995)" />
    <rect x="11.2891" y="19" width="1.48438" height="3" rx="0.742188" fill="url(#paint20_linear_64_89995)" />
    <rect x="11.2891" y="19" width="1.48438" height="3" rx="0.742188" fill="url(#paint21_radial_64_89995)" />
    <path
      d="M17.9766 23.8594C17.9766 24.2344 17.2344 25.9688 16.0391 25.9688C14.8438 25.9688 14.0234 24.1797 14.0234 23.8594C14.0234 23.4219 14.2109 23 16.0391 23C17.6516 23 17.9766 23.4844 17.9766 23.8594Z"
      fill="#FF80B1"
    />
    <path
      d="M17.9766 23.8594C17.9766 24.2344 17.2344 25.9688 16.0391 25.9688C14.8438 25.9688 14.0234 24.1797 14.0234 23.8594C14.0234 23.4219 14.2109 23 16.0391 23C17.6516 23 17.9766 23.4844 17.9766 23.8594Z"
      fill="url(#paint22_radial_64_89995)"
    />
    <path
      d="M19.3945 25.375L24.5664 27.5547"
      stroke="url(#paint23_linear_64_89995)"
      strokeWidth="0.8"
      strokeLinecap="round"
    />
    <path
      d="M19.3945 25.375L24.5664 27.5547"
      stroke="url(#paint24_linear_64_89995)"
      strokeWidth="0.8"
      strokeLinecap="round"
    />
    <path
      d="M19.3945 25.375L24.5664 27.5547"
      stroke="url(#paint25_linear_64_89995)"
      strokeWidth="0.8"
      strokeLinecap="round"
    />
    <path
      d="M19.3945 25.375L24.5664 27.5547"
      stroke="url(#paint26_radial_64_89995)"
      strokeWidth="0.8"
      strokeLinecap="round"
    />
    <path d="M20.3789 24.4766H25.5586" stroke="url(#paint27_linear_64_89995)" strokeWidth="0.8" strokeLinecap="round" />
    <path d="M20.3789 24.4766H25.5586" stroke="url(#paint28_linear_64_89995)" strokeWidth="0.8" strokeLinecap="round" />
    <path d="M20.3789 24.4766H25.5586" stroke="url(#paint29_linear_64_89995)" strokeWidth="0.8" strokeLinecap="round" />
    <path d="M20.3789 24.4766H25.5586" stroke="url(#paint30_radial_64_89995)" strokeWidth="0.8" strokeLinecap="round" />
    <path
      d="M12.6055 25.375L7.43359 27.5547"
      stroke="url(#paint31_linear_64_89995)"
      strokeWidth="0.8"
      strokeLinecap="round"
    />
    <path
      d="M12.6055 25.375L7.43359 27.5547"
      stroke="url(#paint32_linear_64_89995)"
      strokeWidth="0.8"
      strokeLinecap="round"
    />
    <path
      d="M12.6055 25.375L7.43359 27.5547"
      stroke="url(#paint33_linear_64_89995)"
      strokeWidth="0.8"
      strokeLinecap="round"
    />
    <path
      d="M12.6055 25.375L7.43359 27.5547"
      stroke="url(#paint34_radial_64_89995)"
      strokeWidth="0.8"
      strokeLinecap="round"
    />
    <path d="M11.6211 24.4766H6.44141" stroke="url(#paint35_linear_64_89995)" strokeWidth="0.8" strokeLinecap="round" />
    <path d="M11.6211 24.4766H6.44141" stroke="url(#paint36_linear_64_89995)" strokeWidth="0.8" strokeLinecap="round" />
    <path d="M11.6211 24.4766H6.44141" stroke="url(#paint37_linear_64_89995)" strokeWidth="0.8" strokeLinecap="round" />
    <path d="M11.6211 24.4766H6.44141" stroke="url(#paint38_radial_64_89995)" strokeWidth="0.8" strokeLinecap="round" />
    <defs>
      <filter
        id="filter0_i_64_89995"
        x="1.90781"
        y="4"
        width="13.1"
        height="13.1"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-0.1" dy="0.1" />
        <feGaussianBlur stdDeviation="0.25" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.647059 0 0 0 0 0.639216 0 0 0 0 0.662745 0 0 0 0.5 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_64_89995" />
      </filter>
      <filter
        id="filter1_i_64_89995"
        x="17.0078"
        y="4"
        width="13.1"
        height="13.1"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.1" dy="0.1" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.647059 0 0 0 0 0.639216 0 0 0 0 0.662745 0 0 0 0.75 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_64_89995" />
      </filter>
      <filter
        id="filter2_i_64_89995"
        x="18.9078"
        y="6"
        width="10.1"
        height="10.1"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-0.1" dy="0.1" />
        <feGaussianBlur stdDeviation="0.25" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.898039 0 0 0 0 0.470588 0 0 0 0 0.580392 0 0 0 0.75 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_64_89995" />
      </filter>
      <filter
        id="filter3_f_64_89995"
        x="18.3018"
        y="18.6875"
        width="2.48438"
        height="4"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_64_89995" />
      </filter>
      <linearGradient
        id="paint0_linear_64_89995"
        x1="7.38281"
        y1="6.625"
        x2="5.57031"
        y2="17"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C1B8C6" />
        <stop offset="1" stopColor="#A183B2" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_64_89995"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(2.8582 4.90044) rotate(51.8428) scale(4.32035 5.98988)"
      >
        <stop stopColor="#A9A4AE" />
        <stop offset="1" stopColor="#A9A4AE" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_64_89995"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(13.7777 5.93172) rotate(132.979) scale(3.64841 6.36883)"
      >
        <stop stopColor="#E3E0E7" />
        <stop offset="1" stopColor="#E3E0E7" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint3_radial_64_89995"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(3.00781 11.875) rotate(13.2908) scale(8.15595)"
      >
        <stop stopColor="#F3758E" />
        <stop offset="1" stopColor="#E7626E" />
      </radialGradient>
      <radialGradient
        id="paint4_radial_64_89995"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(5.25781 13.25) rotate(-40.9555) scale(4.38615 6.124)"
      >
        <stop stopColor="#FF9CA9" />
        <stop offset="0.82863" stopColor="#FF9CA9" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint5_radial_64_89995"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(18.3203 7.6875) rotate(52.4314) scale(10.2508)"
      >
        <stop stopColor="#A6A2AC" />
        <stop offset="1" stopColor="#D3CBDA" />
      </radialGradient>
      <radialGradient
        id="paint6_radial_64_89995"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(27.1953 17) rotate(-110.395) scale(5.20104 12.8911)"
      >
        <stop stopColor="#C5AED9" />
        <stop offset="1" stopColor="#C5AED9" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint7_radial_64_89995"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(30.3828 11.4375) rotate(-165.838) scale(13.7942 9.0204)"
      >
        <stop offset="0.850386" stopColor="#A4A2A9" stopOpacity="0" />
        <stop offset="1" stopColor="#A4A2A9" />
      </radialGradient>
      <radialGradient
        id="paint8_radial_64_89995"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(29.4453 7.5) rotate(147.095) scale(3.79659 5.45721)"
      >
        <stop stopColor="#E7E1ED" />
        <stop offset="0.940539" stopColor="#E7E1ED" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint9_radial_64_89995"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(22.8516 13.0313) rotate(-47.4717) scale(6.14871 8.20628)"
      >
        <stop stopColor="#FFABB3" />
        <stop offset="1" stopColor="#FA91AF" />
      </radialGradient>
      <radialGradient
        id="paint10_radial_64_89995"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(24.0078 11.4375) rotate(-33.9174) scale(4.3683 4.45504)"
      >
        <stop stopColor="#F5BBD5" />
        <stop offset="1" stopColor="#F5BBD5" stopOpacity="0" />
        <stop offset="1" stopColor="#F5BBD5" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint11_linear_64_89995"
        x1="20.0703"
        y1="14.25"
        x2="5.82031"
        y2="36.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.237265" stopColor="#D4CCE2" />
        <stop offset="1" stopColor="#BB8AE9" />
      </linearGradient>
      <radialGradient
        id="paint12_radial_64_89995"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(12.1328 28.25) rotate(-57.3554) scale(9.50082 15.7445)"
      >
        <stop stopColor="#B583E3" />
        <stop offset="1" stopColor="#B583E3" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint13_radial_64_89995"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(3.69531 14.125) rotate(23.8412) scale(11.6162 13.3666)"
      >
        <stop offset="0.12067" stopColor="#9B90A2" />
        <stop offset="1" stopColor="#9B90A2" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint14_radial_64_89995"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(20.4453 15.5625) rotate(141.038) scale(9.24366 11.8599)"
      >
        <stop stopColor="#F6F1FE" />
        <stop offset="0.572797" stopColor="#F6F1FE" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint15_radial_64_89995"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16.0391 27.6094) rotate(-90) scale(18.9219 13.4687)"
      >
        <stop offset="0.877787" stopColor="#C1C0C3" stopOpacity="0" />
        <stop offset="1" stopColor="#C1C0C3" />
      </radialGradient>
      <linearGradient
        id="paint16_linear_64_89995"
        x1="20.7109"
        y1="20.5"
        x2="19.8517"
        y2="20.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.225" stopColor="#594C4F" />
        <stop offset="1" stopColor="#392833" />
      </linearGradient>
      <linearGradient
        id="paint17_linear_64_89995"
        x1="20.9429"
        y1="22.2568"
        x2="20.9429"
        y2="21.1829"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#552D52" />
        <stop offset="1" stopColor="#552D52" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint18_radial_64_89995"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(20.2576 20.6796) rotate(-109.297) scale(1.73269 1.67065)"
      >
        <stop offset="0.813951" stopColor="#594C4F" stopOpacity="0" />
        <stop offset="1" stopColor="#594C4F" />
      </radialGradient>
      <linearGradient
        id="paint19_linear_64_89995"
        x1="12.7734"
        y1="20.5"
        x2="11.9142"
        y2="20.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.225" stopColor="#594C4F" />
        <stop offset="1" stopColor="#392833" />
      </linearGradient>
      <linearGradient
        id="paint20_linear_64_89995"
        x1="13.0054"
        y1="22.2568"
        x2="13.0054"
        y2="21.1829"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#552D52" />
        <stop offset="1" stopColor="#552D52" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint21_radial_64_89995"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(12.3201 20.6796) rotate(-109.297) scale(1.73269 1.67065)"
      >
        <stop offset="0.813951" stopColor="#594C4F" stopOpacity="0" />
        <stop offset="1" stopColor="#594C4F" />
      </radialGradient>
      <radialGradient
        id="paint22_radial_64_89995"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17.9766 23.25) rotate(143.673) scale(3.95643 4.4258)"
      >
        <stop offset="0.726944" stopColor="#E1589F" stopOpacity="0" />
        <stop offset="1" stopColor="#E1589F" />
      </radialGradient>
      <linearGradient
        id="paint23_linear_64_89995"
        x1="22.4141"
        y1="26.0781"
        x2="21.8828"
        y2="27.375"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#978BA4" />
        <stop offset="0.343195" stopColor="#B5A8C7" />
        <stop offset="0.833333" stopColor="#9877BA" />
      </linearGradient>
      <linearGradient
        id="paint24_linear_64_89995"
        x1="25.1172"
        y1="27.7969"
        x2="24.7109"
        y2="27.5547"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B5A8C7" />
        <stop offset="1" stopColor="#B5A8C7" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint25_linear_64_89995"
        x1="19.0234"
        y1="25.2969"
        x2="19.9609"
        y2="25.8125"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A18BBA" />
        <stop offset="1" stopColor="#A18BBA" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint26_radial_64_89995"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(24.6953 27.4375) rotate(-153.435) scale(0.454202 0.784275)"
      >
        <stop stopColor="#C1B1D8" />
        <stop offset="1" stopColor="#B5A8C7" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint27_linear_64_89995"
        x1="22.9688"
        y1="23.9609"
        x2="22.9687"
        y2="25.2813"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#978BA4" />
        <stop offset="0.343195" stopColor="#B5A8C7" />
        <stop offset="0.833333" stopColor="#9877BA" />
      </linearGradient>
      <linearGradient
        id="paint28_linear_64_89995"
        x1="26.1484"
        y1="24.7813"
        x2="25.5586"
        y2="24.7813"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B5A8C7" />
        <stop offset="1" stopColor="#B5A8C7" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint29_linear_64_89995"
        x1="20.1016"
        y1="24.8594"
        x2="20.7266"
        y2="24.8594"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A18BBA" />
        <stop offset="1" stopColor="#A18BBA" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint30_radial_64_89995"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(25.5586 24.3125) rotate(180) scale(0.410156 0.324871)"
      >
        <stop stopColor="#C1B1D8" />
        <stop offset="1" stopColor="#B5A8C7" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint31_linear_64_89995"
        x1="9.58594"
        y1="26.0781"
        x2="10.1172"
        y2="27.375"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#978BA4" />
        <stop offset="0.343195" stopColor="#B5A8C7" />
        <stop offset="0.833333" stopColor="#9877BA" />
      </linearGradient>
      <linearGradient
        id="paint32_linear_64_89995"
        x1="13.1016"
        y1="25.5703"
        x2="12.5625"
        y2="25.8281"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B5A8C7" />
        <stop offset="1" stopColor="#B5A8C7" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint33_linear_64_89995"
        x1="6.96094"
        y1="27.4531"
        x2="7.71094"
        y2="27.0703"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A18BBA" />
        <stop offset="1" stopColor="#A18BBA" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint34_radial_64_89995"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(12.6563 25.25) rotate(162.582) scale(0.417585 0.721049)"
      >
        <stop stopColor="#C1B1D8" />
        <stop offset="1" stopColor="#B5A8C7" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint35_linear_64_89995"
        x1="9.03125"
        y1="23.9609"
        x2="9.03125"
        y2="25.2813"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#978BA4" />
        <stop offset="0.343195" stopColor="#B5A8C7" />
        <stop offset="0.833333" stopColor="#9877BA" />
      </linearGradient>
      <linearGradient
        id="paint36_linear_64_89995"
        x1="11.9297"
        y1="24.7812"
        x2="11.1094"
        y2="24.7812"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B5A8C7" />
        <stop offset="1" stopColor="#B5A8C7" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint37_linear_64_89995"
        x1="5.89063"
        y1="24.75"
        x2="6.72656"
        y2="24.75"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A18BBA" />
        <stop offset="1" stopColor="#A18BBA" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint38_radial_64_89995"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(11.6211 24.4141) rotate(178.909) scale(0.410231 0.324931)"
      >
        <stop stopColor="#C1B1D8" />
        <stop offset="1" stopColor="#B5A8C7" stopOpacity="0" />
      </radialGradient>
    </defs>
  </SvgIcon>
);

export default Mouse;
