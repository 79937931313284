import { getAccentColor } from 'portfolio3/styles';
import { AccentColorType } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const icon =
  (backgroundColor: AccentColorType): SxStyles =>
  () => {
    return {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      width: '40px',
      height: '40px',

      padding: '10px',

      borderRadius: '100%',
      background: getAccentColor(backgroundColor, '8'),
    };
  };
