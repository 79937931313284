/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useMemo } from 'react';

import { connect } from 'react-redux';

import { IRootState } from '../../../../reducers';
import { ICommonResponse } from '../../../../api/types';
import PdfCommonDataEntityContainer from '../common';
import { PdfCommonDataEntity } from '../../../../components/pdfStudent/DataEntity';
import { widgetPdfIcons } from '../../../../icons';
import { scienceProjectsSelector } from '../../../../selectors';
import { IEntity } from '../../../../types';

import '../index.scss';
import { useDataLoad } from 'hooks';
import { getDataLoadRequests } from 'utils';

interface IPdfScienceProjectContainerProps {
  studentProjects: ICommonResponse<IEntity>
}

const PdfScienceProjectContainer: React.FC<IPdfScienceProjectContainerProps> = ({
  studentProjects
}) => {
  useDataLoad({ shouldLoad: true, requests: getDataLoadRequests().scienceProjects });

  const filteredProjectsYear = useMemo(() => (studentProjects.content && studentProjects.content?.length > 0
    ? studentProjects.content?.reduce((prevItem, nextItem) => (prevItem.year > nextItem.year ? prevItem : nextItem)).year
    : undefined),
  [studentProjects]);

  return (
    <PdfCommonDataEntityContainer
      title="Проекты"
      icon={widgetPdfIcons.pdfIcoScienceProjects}
    >
      {studentProjects.content
        .map((olympiad: IEntity) => (
          <PdfCommonDataEntity key={olympiad.id || olympiad.recordId} entity={olympiad} year={filteredProjectsYear} />
        ))}
    </PdfCommonDataEntityContainer>
  );
};

export default connect(
  (state: IRootState) => ({
    studentProjects: scienceProjectsSelector(state)
  })
)(PdfScienceProjectContainer);
