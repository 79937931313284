import { FC } from 'react';

import { Box, IconButton } from '@mui/material';
import { IconChevronFilledArrowDown, IconChevronFilledArrowUp, IconChevronLargeDown, IconChevronLargeUp } from 'icons';
import { SxStyles } from 'types';
import { mergeSx } from 'utils';

import * as styles from './styles';

interface ISelectArrowProps {
  isOpen?: boolean;
  isMobile?: boolean;
  isDisabled?: boolean;
  useButton?: boolean;
  sx?: SxStyles;
  [key: string]: unknown;
}

const SelectArrow: FC<ISelectArrowProps> = ({ isOpen, isMobile, isDisabled, useButton = false, sx, ...restProps }) => {
  const MobileArrow = isOpen ? IconChevronFilledArrowUp : IconChevronFilledArrowDown;
  const NormalArrow = isOpen ? IconChevronLargeUp : IconChevronLargeDown;
  const ArrowComponent = isMobile ? MobileArrow : NormalArrow;

  const wrapperProps = {
    sx: mergeSx(styles.root(isDisabled ?? false), sx),
    ...restProps,
  };

  if (useButton) {
    return (
      <IconButton {...wrapperProps}>
        <ArrowComponent />
      </IconButton>
    );
  }

  return (
    <Box {...wrapperProps}>
      <ArrowComponent />
    </Box>
  );
};

export default SelectArrow;
