import { FC, useEffect, useState } from 'react';

import { getVuzFaculties, getVuzSpecialities } from 'api';
import { useComponentDidMount } from 'hooks';

import { VuzCard } from '../../components';
import { ILocalVuzSpeciality, ISearchVuz, IVuzInfo } from '../../types';
import { complementSearchWithFacultySpecialities, flatenSpecialities } from '../utils';

interface IVuzCardContainerProps {
  vuz: ISearchVuz;
}

const VuzCardContainer: FC<IVuzCardContainerProps> = ({ vuz }) => {
  const { info, specialities } = vuz;
  const { id } = info;
  const [specialitiesNumber, setSpecialitiesNumber] = useState<number | null>(null);
  const [localSpecialities, setLocalSpecialities] = useState<ILocalVuzSpeciality[] | null>(null);
  const [facultySpecialities, setFacultySpecialities] = useState<ILocalVuzSpeciality[] | null>(null);
  const resultSpecialities = localSpecialities ?? specialities;
  const searchParams = {
    per_page: 9999,
    page: 1,
  };

  useComponentDidMount(() => {
    async function getSpecialities() {
      if (!id) return;
      const { response } = await getVuzSpecialities(id, searchParams);

      setSpecialitiesNumber(response?.items.length || null);
    }
    async function getFaculties() {
      if (!id) return;
      const { response } = await getVuzFaculties(id, searchParams);
      setFacultySpecialities(flatenSpecialities(response?.items || []));
    }

    getSpecialities();
    getFaculties();
  });

  useEffect(() => {
    if (!facultySpecialities) return;

    const mergedSpecialities = complementSearchWithFacultySpecialities(specialities, facultySpecialities);
    setLocalSpecialities(mergedSpecialities);
  }, [facultySpecialities, specialities]);

  const budgetPlaces = resultSpecialities.reduce((counter, item) => {
    return counter + (item?.budgetPlaces ?? 0);
  }, 0);

  const vuzInfo: IVuzInfo = {
    ...info,
    specialitiesNumber: specialitiesNumber ?? info.specialitiesNumber,
    budgetPlaces,
  };

  return <VuzCard vuzInfo={vuzInfo} specialities={resultSpecialities} />;
};

export default VuzCardContainer;
