import { IGiaItem } from 'api/types';

import { mapLocalSubjectNameToRefSubjectName } from './subjects';

export const getStudentSubjectsExamBallSummary = (
  exams: IGiaItem[],
  specialitySubjectNames: string[],
  localSubjectNames: string[],
) => {
  const normalizedSpecialitySubjectNames = specialitySubjectNames.map((subject) => subject.toLowerCase());

  const studentSubjectsBallSummary = localSubjectNames.reduce((sum, subject) => {
    const normalizedSubjectName = subject.toLowerCase();

    const localSubjectMappedToRefSubject = mapLocalSubjectNameToRefSubjectName(normalizedSubjectName).toLowerCase();
    const hasSpecialitySubjectsLocalSubject = normalizedSpecialitySubjectNames.includes(localSubjectMappedToRefSubject);

    // если выбранный предмет не найден в предметах специальности, то пропускаем его
    if (!hasSpecialitySubjectsLocalSubject) {
      return sum;
    }

    // получаем экзамены у которых название соответствует названию предмета
    const studentExamsWithSameName = exams.filter((exam) => {
      return exam.name?.toLowerCase() === normalizedSubjectName;
    });

    // при поиске экзаменов может быть найдено несколько экзаменов c одинаковым предметом
    // получаем экзамен с самой последней датой
    const studentExamWithLatestDate = studentExamsWithSameName.reduce<IGiaItem | null>((exam, processedExam) => {
      if (!exam) return processedExam;

      const storedExamDate = new Date(exam.date);
      const processedExamDate = new Date(processedExam.date);

      if (Number(processedExamDate) > Number(storedExamDate)) return processedExam;
      return exam;
    }, null);

    if (studentExamWithLatestDate) {
      const examMark = studentExamWithLatestDate.normalizedMarkValue ?? 0;
      return sum + Number(examMark);
    }

    return sum;
  }, 0);

  return studentSubjectsBallSummary ?? 0;
};
