import { FC } from 'react';

import { useAppSelector, useDataLoad } from 'hooks';
import Metaskills from 'portfolio3/features/pdf/components/Metaskills';
import PdfNoDataText from 'portfolio3/features/pdf/components/PdfNoDataText';
import { getDataLoadRequests } from 'utils';

import { widgetPdfIcons } from '../../../../icons';
import { professionMetaskillsSelector } from '../../../../selectors';
import PdfCommonDataEntityContainer from '../common';

import '../index.scss';

const PdfMetaskillsContainer: FC = () => {
  const metaskills = useAppSelector(professionMetaskillsSelector);
  useDataLoad({ shouldLoad: true, requests: getDataLoadRequests().professionMetaSkills });

  const hasData = metaskills.content.length > 0;

  return (
    <PdfCommonDataEntityContainer title="Метанавыки" icon={widgetPdfIcons.pdfIconProfessionMetaskills}>
      {hasData && <Metaskills metaskills={metaskills.content} />}
      {!hasData && <PdfNoDataText />}
    </PdfCommonDataEntityContainer>
  );
};

export default PdfMetaskillsContainer;
