import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { IconClose } from 'icons/edit';
import { UnstyledButton } from 'portfolio3/ui-kit';

import * as styles from './styles';

interface IPanelHeaderProps {
  onClose: VoidFunction;
}

const PanelHeader: FC<IPanelHeaderProps> = ({ onClose }) => {
  return (
    <Box className="panel-header" sx={styles.root}>
      <Box sx={styles.topRow}>
        <Typography sx={styles.title}>Оформление портфолио</Typography>
        <UnstyledButton sx={styles.closeButton} onClick={onClose}>
          <IconClose />
        </UnstyledButton>
      </Box>
      <Typography sx={styles.subtitle}>Оформи свое портфолио так, как тебе нравится!</Typography>
    </Box>
  );
};

export default PanelHeader;
