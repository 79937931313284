import { FC, useContext } from 'react';

import { Box } from '@mui/material';
import { SxStyles } from 'types';
import { mergeSx, numberToPixelsString } from 'utils';

import { paddingInlineLarge, paddingInlineSmall, paddingInlineSmallBackground } from '../const';
import { WidgetContainerContext } from '../context/widgetContainerContext';
import { WidgetContainerSubHeaderProps } from '../types';

const styles = (withBackground: boolean): SxStyles => {
  return (theme) => ({
    padding: numberToPixelsString(paddingInlineLarge),
    paddingTop: 0,
    paddingBottom: '16px',

    '.widget-container-subheader': {
      '&__subtitle': {
        marginTop: '2px',
      },
      '&__options': {
        marginTop: '8px',
      },
    },

    [theme.breakpoints.down('commonSm')]: {
      padding: numberToPixelsString(withBackground ? paddingInlineSmallBackground : paddingInlineSmall),
      paddingTop: 0,
    },
  });
};

const WidgetContainerSubHeader: FC<WidgetContainerSubHeaderProps> = ({ sx, title, subtitle, options }) => {
  const { withBackground } = useContext(WidgetContainerContext);

  return (
    <Box className="widget-container-subheader" sx={mergeSx(styles(withBackground), sx)}>
      {title && <Box className="widget-container-subheader__title">{title}</Box>}
      {subtitle && <Box className="widget-container-subheader__subtitle">{subtitle}</Box>}
      {options && <Box className="widget-container-subheader__options">{options}</Box>}
    </Box>
  );
};

export default WidgetContainerSubHeader;
