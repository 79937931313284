import { SET_DISPLAYED_ENTITY_COUNT } from 'actions';
import { IReducers } from 'reducers';
import connectReducers from 'utils/connectReducers';

export type DisplayedEntityCountState = {
  [containerId: string]: number;
};

type DisplayedEntityCountPayload = {
  [containerId: string]: number;
};

const initialState: DisplayedEntityCountState = {};

const displayedEntityCount: IReducers<DisplayedEntityCountState> = {
  [SET_DISPLAYED_ENTITY_COUNT]: (
    _,
    { payload: { containerId, displayedEntityCount } }: { payload: DisplayedEntityCountPayload },
  ) => ({
    [containerId]: displayedEntityCount,
  }),
};

export default connectReducers(initialState, displayedEntityCount);
