import { FC, PropsWithChildren } from 'react';

import { Box, Typography, useTheme } from '@mui/material';
import { useBrightTheme } from 'hooks';
import TrimText from 'portfolio3/components/common/TrimText';
import { WidgetDataContext } from 'portfolio3/components/common/WidgetContainer';
import { getAccentColor, NeutralColors } from 'portfolio3/styles';

import SearchSectionPadding from '../Padding';
import * as styles from './styles';

interface ISearchSectionSubsectionProps {
  title: string;
}

const SearchSectionSubsection: FC<ISearchSectionSubsectionProps & PropsWithChildren> = ({ title, children }) => {
  const { palette } = useTheme();
  const isBrightTheme = useBrightTheme();

  const titleColor = isBrightTheme ? getAccentColor(palette.accentColor, '200') : NeutralColors.light_neutrals_700;

  return (
    // контекст виджетов используют дроверы которые открываются через вложенные сущности
    <WidgetDataContext.Provider value={{ widgetLabel: title }}>
      <Box className="search-section-subsection">
        <SearchSectionPadding sx={styles.titleContainer}>
          <Typography
            component={TrimText}
            variant="Paragraph LG/Bold"
            className="search-section-category-label"
            color={titleColor}
            sx={styles.title}
          >
            {title}
          </Typography>
        </SearchSectionPadding>

        <Box className="search-section-subsection__list">{children}</Box>
      </Box>
    </WidgetDataContext.Provider>
  );
};

export default SearchSectionSubsection;
