import { SpoDocument } from 'api/types/entity/spoDocument';

import { GET_STUDENT_SPO_DOCUMENTS } from '../../actions';
import { FAILURE, INVALIDATE, REQUEST, SUCCESS } from '../../actions/utils';
import { ICommonResponse } from '../../api/types';
import { connectReducers } from '../../utils';
import { IReducers } from '..';
import genericReducers from '../genericReducer';

export type StudentSpoDocumentsState = ICommonResponse<SpoDocument>;

const initialState: StudentSpoDocumentsState = {
  content: [],
  loading: false,
};

const studentSpoDocuments: IReducers<StudentSpoDocumentsState> = {
  [GET_STUDENT_SPO_DOCUMENTS[REQUEST]]: genericReducers().get.request,
  [GET_STUDENT_SPO_DOCUMENTS[SUCCESS]]: genericReducers().get.success,
  [GET_STUDENT_SPO_DOCUMENTS[FAILURE]]: genericReducers().get.failure,
  [GET_STUDENT_SPO_DOCUMENTS[INVALIDATE]]: genericReducers().get.invalidate(initialState),
};

export default connectReducers(initialState, studentSpoDocuments);
