import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root: SxStyles = {
  position: 'relative',

  padding: '16px',
  paddingRight: '44px',

  backgroundColor: '#ffffff',
  borderRadius: '12px',
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1);',
};

export const cardInfo: SxStyles = {
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
};

export const name: SxStyles = {
  color: NeutralColors.light_neutrals_600,
};

export const actionsButton: SxStyles = {
  position: 'absolute',
  top: '4px',
  right: '4px',
};
