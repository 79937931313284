import { IDictionaryItem, IPersonObject } from 'api/types';

export const filterPersonsObjects = (personsObjects: IPersonObject[], dataKinds: number[]) => {
  return personsObjects
    .filter((personObject) => {
      const matchDataKind = personObject.dataKind && dataKinds.includes(personObject.dataKind);
      return matchDataKind;
    })
    .map((personObject): IDictionaryItem => {
      return {
        id: personObject.id,
        code: personObject.id,
        value: personObject.name,
        parentId: personObject.typeCode ?? personObject.dataKind,
      };
    });
};
