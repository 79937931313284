import { FC, PropsWithChildren } from 'react';

import { Box, SvgIconProps } from '@mui/material';
import { AccentColorType, getAccentColor, NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

interface ISectionIconWrapperProps extends PropsWithChildren {
  accentColorType: AccentColorType;
  Icon: FC<SvgIconProps>;
  disabled?: boolean;
}

const boxStyles: SxStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '30px',
  height: '30px',
  borderRadius: '8px',
  marginRight: '8px',
};

const SectionIconWrapper: FC<ISectionIconWrapperProps> = ({ accentColorType, Icon, disabled }) => {
  const bgColor = disabled ? NeutralColors.light_neutrals_500 : getAccentColor(accentColorType, '100');
  return (
    <Box sx={boxStyles} bgcolor={bgColor}>
      <Icon sx={{ width: '20px', height: '20px', color: NeutralColors.light_neutrals_0 }} />
    </Box>
  );
};

export default SectionIconWrapper;
