import { ElementRef, FC, ReactNode, RefObject, useRef } from 'react';
import { CalendarProps, DateValue, useDatePicker } from 'react-aria';
import { DatePickerState, DatePickerStateOptions, useDatePickerState } from 'react-stately';

import { Box, useFormControl } from '@mui/material';

import { IDateInputHandle, ModifySegmentsCallback } from '../../localTypes';
import DateField from '../DateField';
import DateInputBox from '../DateInputBox';
import useCommonDatePickerState from '../hooks/useCommonDatePickerState';

interface IDatePickerProps {
  options?: DatePickerStateOptions<DateValue>;
  modifySegments?: ModifySegmentsCallback;
  children?: (
    triggerRef: RefObject<Element>,
    state: DatePickerState,
    calendarProps: CalendarProps<DateValue>,
  ) => ReactNode;
  onClear?: () => void;
}

const DatePicker: FC<IDatePickerProps> = ({ options, modifySegments, children, onClear }) => {
  const formControl = useFormControl();
  const isDisabled = formControl?.disabled || options?.isDisabled || false;

  const modifiedOptions = {
    ...options,
    isDisabled,
  };

  const state = useDatePickerState(modifiedOptions);
  const ref = useRef<ElementRef<'div'>>(null);
  const { groupProps, fieldProps, buttonProps, calendarProps } = useDatePicker(
    // label используется чтобы убрать ворнинг про aria-label
    { label: 'date picker', ...modifiedOptions },
    state,
    ref,
  );

  const { isOpen, value } = state;

  const dateInputHandle = useRef<IDateInputHandle>(null);
  const hasFilledSegments =
    dateInputHandle.current?.segments.some((segment) => segment.value && !segment.isPlaceholder) ?? false;

  const { isInputHidden, setFocused } = useCommonDatePickerState(isOpen, hasFilledSegments);

  const handleInputBlur = () => {
    setFocused(false);

    if (!value) {
      dateInputHandle.current?.clearSegments();
    }
  };

  return (
    <Box className="date-picker">
      <Box className="date-picker__group" {...groupProps} ref={ref}>
        <DateInputBox
          isOpen={state.isOpen}
          isDisabled={isDisabled}
          calendarButtonProps={buttonProps}
          hasValue={Boolean(value)}
          onClear={onClear}
        >
          <DateField
            fieldOptions={{
              ...fieldProps,
              onFocus: () => setFocused(true),
              onBlur: handleInputBlur,
            }}
            singleDatePickerState={state}
            modifySegments={modifySegments}
            isHidden={isInputHidden}
            handle={dateInputHandle}
          />
        </DateInputBox>
      </Box>
      {children && children(ref, state, calendarProps)}
    </Box>
  );
};

export default DatePicker;
