/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, {
  useCallback,
  useContext, useMemo
} from 'react';

import { connect } from 'react-redux';
import { Box, FormHelperText } from '@mui/material';

import { IDictionaryItem, IChildInfo } from '../../../../../api/types';
import { IRootState } from '../../../../../reducers';
import { SecondaryBlockContainer, useSecondaryBlockControllers } from '../common';

import { BaseInputLabel, FormControl, Input, Textarea } from 'portfolio3/ui-kit';
import { Select } from 'portfolio3/ui-kit/selects';
import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';
import { IController, TokenFieldController } from 'portfolio3/ui-kit/types';
import DualSwitcherControl from 'portfolio3/components/forms/DualSwitcherControl';
import { StretchyTokenField } from 'portfolio3/ui-kit/autocomplete';
import { getCommonInputControllerFactory } from '../../utils';
import { isDefined, mergeSx } from 'utils';
import { getDayRangePickerControllerFactory } from '../../controllers';
import { DayRangePicker } from 'portfolio3/ui-kit/datePickers';
import { dataEntryMaxDate, dataEntryMinDate } from '../../dateErrors';
import { commonAcceptFileTypes } from 'portfolio3/features/dataEntryForm';

interface IProjectSecondaryBlockProps {
  subCategoryOptions: IDictionaryItem[];
  subjectOptions: IDictionaryItem[];
  eventLevelOptions: IDictionaryItem[];
  currentStudent: IChildInfo;
}

const ProjectSecondaryBlock: React.FC<IProjectSecondaryBlockProps> = ({
  subCategoryOptions,
  subjectOptions,
  eventLevelOptions,
  currentStudent,
}) => {
  const { studentType, isMobile, inputRenderMode, inputSize, formData, formErrors, onChangeFormData, onChangeFormErrors } = useContext(LocalDataEntryFormContext);

  const { fileController, linkedObjects, linkedObjectController } = useSecondaryBlockControllers({
    formData,
    onChangeFormData,
    onChangeFormErrors
  });

  const inputController = getCommonInputControllerFactory(onChangeFormData, onChangeFormErrors);
  const dayRangePickerControllerFactory = getDayRangePickerControllerFactory(onChangeFormData, onChangeFormErrors);

  const subCategoryController: IController<string | undefined> = useMemo(() => ({
    handleChange: (subCategory) => {
      onChangeFormData((prevstate) => ({
        ...prevstate,
        subCategory: Number(subCategory),
        creationKindCode: undefined,
        subjectId: []
      }));
      onChangeFormErrors((prevstate) => ({
        ...prevstate,
        subcategoryError: false
      }));
    },
    handleBlur: (type) => {
      if (!type) {
        onChangeFormErrors((prevstate) => ({
          ...prevstate,
          subcategoryError: true
        }));
      }
    }
  }), [formData.id]);

  const nameController = useMemo(() => inputController('name', 'nameError'), [formData.id]);

  const subjectController: TokenFieldController = useMemo(() => ({
    handleChange: (subjects) => {
      onChangeFormData((prevState) => ({
        ...prevState,
        subjectId: subjects.map((subject) => subject.code),
      }));
      onChangeFormErrors((prevState) => ({
        ...prevState,
        subjectError: subjects.length === 0,
      }));
    },
    handleBlur: (subjects) => {
      onChangeFormErrors((prevState) => ({
        ...prevState,
        subjectError: subjects.length === 0,
      }));
    },
  }), [formData.id]);

  const dateRangeController = dayRangePickerControllerFactory('startDate', 'endDate', 'dateError', { required: true });

  const descriptionController = useMemo(() => ({
    handleChange: (description: string) => {
      onChangeFormData((prevState) => ({
        ...prevState,
        description
      }));
    }
  }), [formData.id]);

  const eventLevelController: IController<string | undefined> = useMemo(() => ({
    handleChange: (eventLevel) => {
      onChangeFormData((prevState) => ({
        ...prevState,
        eventLevel: Number(eventLevel)
      }));
      onChangeFormErrors((prevState) => ({
        ...prevState,
        eventLevelError: false
      }));
    },
    handleBlur: (type) => {
      if (!type) {
        onChangeFormErrors((prevState) => ({
          ...prevState,
          eventLevelError: true
        }));
      }
    }
  }), [formData.id]);

  const handleChangeFormat = useCallback((value: number) => {
    onChangeFormData((prevstate) => ({
      ...prevstate,
      format: value
    }));
  }, [formData.id]);

  return (
    <SecondaryBlockContainer>
      <SecondaryBlockContainer.FilesTab
        personId={currentStudent.meshId}
        controller={fileController}
        files={formData.attachment}
        {...commonAcceptFileTypes}
      />
      <SecondaryBlockContainer.LinkedObjectsTab
        studentType={studentType}
        linkedObjectController={linkedObjectController}
        linkedObjects={linkedObjects}
        formData={formData}
      />

      <SecondaryBlockContainer.GeneralTab>
        <FormControl
          sx={{ width: '100%' }}
          required
          error={formErrors.subcategoryError}
          renderMode={inputRenderMode}
          inputSize={inputSize}
          label={<BaseInputLabel>Подкатегория</BaseInputLabel>}
          helper={formErrors.subcategoryError && <FormHelperText>Выберите подкатегорию</FormHelperText>}
          control={
            <Select
              inputRenderMode={inputRenderMode}
              inputSize={inputSize}
              placeholder="Выберите подкатегорию..."
              value={formData.subCategory}
              options={subCategoryOptions}
              controller={subCategoryController}
              strictMaxWidth
            />
          }
        />
        <FormControl
          sx={{ width: '100%' }}
          required
          error={formErrors.nameError}
          renderMode={inputRenderMode}
          inputSize={inputSize}
          label={<BaseInputLabel>Название проекта</BaseInputLabel>}
          helper={formErrors.nameError && <FormHelperText>Введите название</FormHelperText>}
          control={
            <Input
              renderMode={inputRenderMode}
              size={inputSize}
              placeholder="Введите название проекта..."
              value={formData.name}
              controller={nameController}
            />
          }
        />
        <FormControl
          sx={{ width: '100%' }}
          required
          error={formErrors.eventLevelError}
          renderMode={inputRenderMode}
          inputSize={inputSize}
          label={<BaseInputLabel>Уровень проекта</BaseInputLabel>}
          helper={formErrors.eventLevelError && <FormHelperText>Выберите уровень проекта</FormHelperText>}
          control={
            <Select
              inputRenderMode={inputRenderMode}
              inputSize={inputSize}
              placeholder="Выберите уровень проекта..."
              value={formData.eventLevel}
              options={eventLevelOptions}
              controller={eventLevelController}
              strictMaxWidth
            />
          }
        />
        <FormControl
          disabled={!isDefined(formData.subCategory)}
          required
          error={formErrors.subjectError}
          renderMode={inputRenderMode}
          inputSize={inputSize}
          label={
            <BaseInputLabel>
              {isMobile ? 'Предмет / дисциплина' : 'Предмет / дисциплина (один или несколько)'}
            </BaseInputLabel>
          }
          helper={
            formErrors.subjectError && <FormHelperText>Выберите хотя бы один предмет</FormHelperText>
          }
          control={
            <StretchyTokenField
              size={inputSize}
              inputRenderMode={inputRenderMode}
              options={subjectOptions || []}
              controller={subjectController}
              value={formData.subjectId}
              placeholder="Начните вводить название предмета..."
            />
          }
        />
        <FormControl
          required
          renderMode={inputRenderMode}
          inputSize={inputSize}
          label={
            <BaseInputLabel>
              {isMobile ? 'Даты работы' : 'Даты работы над проектом (дата начала или интервал)'}
            </BaseInputLabel>
          }
          error={formErrors.dateError.active}
          helper={formErrors.dateError.active && <FormHelperText>{formErrors.dateError.description}</FormHelperText>}
          control={
            <Box sx={mergeSx(!isMobile && {width: 'max-content'})}>
              <DayRangePicker
                isMobile={isMobile}
                renderMode={inputRenderMode}
                size={inputSize}
                startValue={formData.startDate}
                endValue={formData.endDate}
                controller={dateRangeController}
                minValue={dataEntryMinDate}
                maxValue={dataEntryMaxDate}
              />
            </Box>
          }
        />
        <DualSwitcherControl
          required
          isMobile={isMobile}
          inputRenderMode={inputRenderMode}
          inputSize={inputSize}
          label="Формат проведения"
          value={formData.format}
          firstLabel="Индивидуальный"
          secondLabel="Групповой"
          onChange={handleChangeFormat}
        />
        <FormControl
          sx={{ width: '100%' }}
          renderMode={inputRenderMode}
          inputSize={inputSize}
          label={<BaseInputLabel>Описание</BaseInputLabel>}
          control={
            <Textarea
              renderMode={inputRenderMode}
              size={inputSize}
              placeholder="Любое описание или примечание..."
              value={formData.description}
              controller={descriptionController}
            />
          }
        />
      </SecondaryBlockContainer.GeneralTab>
    </SecondaryBlockContainer>
  );
};

export default connect(
  (state: IRootState) => ({
    currentStudent: state.currentStudent
  })
)(ProjectSecondaryBlock);
