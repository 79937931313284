import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconRecommendation: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="m6.174 6.91 4.617-4.617a1.24 1.24 0 0 1 1.758-.008c.3.3.425.725.342 1.141l-.792 3.817h4.709c1.791 0 3 1.833 2.3 3.483l-2.717 6.342a1.678 1.678 0 0 1-1.533 1.008h-7.5c-.917 0-1.667-.75-1.667-1.666V8.085c0-.442.175-.867.483-1.175Zm-2.15 9.5c0 .916-.75 1.666-1.666 1.666-.917 0-1.667-.75-1.667-1.666V9.743c0-.917.75-1.667 1.667-1.667.916 0 1.666.75 1.666 1.667v6.667Z"
        fill={props.fill ?? 'currentColor'}
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default IconRecommendation;
