import { FC } from 'react';

import { Typography, useMediaQuery } from '@mui/material';
import FunnelBlock from 'components/common/FunnelBlock';
import { LinkExpiration, LinkExpirationId } from 'const';
import { commonTheme } from 'portfolio3/styles/theme';
import { BaseInputLabel, FormControl, Switcher, SwitcherButton } from 'portfolio3/ui-kit';
import { DayRangePicker, DayRangePickerController } from 'portfolio3/ui-kit/datePickers';

import * as styles from './styles';

interface ILinksPeriodProps {
  periodType: number;
  onChangePeriodType: (type: number) => void;
  customPeriodStart: Date | null;
  customPeriodEnd: Date | null;
  customPeriodController: DayRangePickerController;
  isCustomPeriodError: boolean;
}

const LinksPeriod: FC<ILinksPeriodProps> = ({
  periodType,
  onChangePeriodType,
  customPeriodStart,
  customPeriodEnd,
  customPeriodController,
  isCustomPeriodError,
}) => {
  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  return (
    <FunnelBlock className="sharing-links-period" sx={styles.root}>
      <Typography variant={isMobile ? 'Headings/H6' : 'Headings/H5'}>Срок действия ссылки</Typography>

      <Switcher
        value={periodType}
        onChange={onChangePeriodType}
        componentVariant="gray"
        componentSize="small"
        sx={styles.switcher}
        useHiddenList
      >
        <SwitcherButton value={LinkExpirationId.week} content={LinkExpiration.week} componentVariant="gray" />
        <SwitcherButton value={LinkExpirationId.mounth} content={LinkExpiration.mounth} componentVariant="gray" />
        <SwitcherButton value={LinkExpirationId.year} content={LinkExpiration.year} componentVariant="gray" />
        <SwitcherButton value={LinkExpirationId.period} content={LinkExpiration.period} componentVariant="gray" />
      </Switcher>

      {periodType === LinkExpirationId.period && (
        <FormControl
          error={isCustomPeriodError}
          renderMode="fixed"
          inputSize="small"
          sx={styles.customPeriodControl}
          label={<BaseInputLabel>Период действия ссылки</BaseInputLabel>}
          control={
            <DayRangePicker
              renderMode="fixed"
              size="small"
              startValue={customPeriodStart}
              endValue={customPeriodEnd}
              controller={customPeriodController}
              minValue={new Date()}
            />
          }
        />
      )}
    </FunnelBlock>
  );
};

export default LinksPeriod;
