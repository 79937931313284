import { IAffilationKind, IDictionaryItem, IEntityLinkedObject, IEventKind, ILocation, ISportReward } from 'api/types';
import { DifferentCode, DifferentValue, OtherOrganizationCode, SectionCodes, SportClubParentCodes } from 'const';
import { uniqueId } from 'lodash';
import { IRootState } from 'reducers';
import { getSpecialStatusEdit, isDefined } from 'utils';

import { mapJobFormData, mapSpoDocumentFormData } from '../formDataMappers';
import { IFile, ILinkablePersonObject, IModifiedPersonObject, IPortfolioEntryFormData } from '../types';

// Коммент ниже используется как референс

/* eslint-disable max-len */
// const getEntityNameCode = (name?: string, categoryCode?: number, typeCode?: number): string | number | undefined => {
//   if (categoryCode === SectionCodes.creationCollective || categoryCode === SectionCodes.creationAffilation) {
//     return affilationKinds?.content?.find((creation) => creation.value === name)?.code
//       || affilationKinds.content?.find((creationKind) => creationKind.value === DifferentValue && creationKind.categoryCode === categoryCode)?.code;
//   }
//   if (categoryCode === SectionCodes.civilAffilationGroup || categoryCode === SectionCodes.civilAffilationMovement) return affilationKinds?.content?.find((affilation) => affilation.value === name)?.code || affilationKinds.content?.find((creationKind) => creationKind.value === DifferentValue)?.code;
//   if (categoryCode === SectionCodes.sportClubTeam) {
//     const possibleSportClubTeam = sportClubs.content?.filter((sportClub) => sportClub.parentId === SportClubParentCodes.sportClub);
//     return possibleSportClubTeam?.find((sportClub) => sportClub.value === name)?.code || possibleSportClubTeam?.find((sportClub) => sportClub.code === OtherOrganizationCode)?.code;
//   }
//   if (categoryCode === SectionCodes.sportRewardCompetition) return typeCode;
//   if (categoryCode === SectionCodes.gtoReward || categoryCode === SectionCodes.sportRank || categoryCode === SectionCodes.sportCategory || categoryCode === SectionCodes.sportRewardTourism || categoryCode === SectionCodes.sportRewardCompetition) return sportRewards?.content?.find((sportReward) => sportReward.value === name)?.code;
//   if (categoryCode === SectionCodes.civilRewardEvent) return eventKinds.content?.find((eventKind) => eventKind.value === name)?.code;
//   if (categoryCode === SectionCodes.scienceCircle || categoryCode === SectionCodes.creationCircle) return name;
//   if (categoryCode === SectionCodes.civilRewardStatus) return typeCode;
//   const entityNameCode = eventKinds.content?.filter((eventKind) => eventKind.categoryCode === categoryCode).find((eventKind) => eventKind.value === name)?.code;
//   return entityNameCode || eventKinds.content?.filter((eventKind) => eventKind.categoryCode === categoryCode).find((eventKind) => eventKind.value.trim() === DifferentValue)?.code;
// };

// const getEntityNameCodeNew = (
//   name: string | undefined,
//   categoryCode: number | undefined,
//   dataKind: number | undefined,
//   typeCode: number | undefined
// ) => {
//   switch (dataKind) {
//     case SectionCodes.professionEvent: {
//       return eventKinds.content
//         ?.filter((eventKind) => eventKind.categoryCode === dataKind)
//         .find((eventKind) => eventKind.value === name)?.code;
//     }
//     default: return undefined;
//   }
// };
/* eslint-enable max-len */

export const getMappedFormData = (
  initialData: Partial<IModifiedPersonObject>,
  store: IRootState,
  linkablePersonObjects: ILinkablePersonObject[],
) => {
  const { eventKinds, affilationKinds, sportClubs, sportRewardKinds, spoOrganizationRef, professionProgrammRef } =
    store;

  let ageLimitArray: number[] | undefined;

  if (initialData.ageLimit) {
    ageLimitArray = typeof initialData.ageLimit === 'string' ? deserializeInterval(initialData.ageLimit) : undefined;
  } else if (initialData.parallelsCode) {
    ageLimitArray = deserializeInterval(initialData.parallelsCode.toString());
  }

  const entityNameCode = getEntityNameCode(
    initialData.name,
    initialData.dataKind || undefined,
    initialData.type?.code || initialData.typeCode,
    initialData.rewardType?.code,
    initialData.sportRewardCode,
    initialData.rewardType?.categoryCode,
    eventKinds.content,
    affilationKinds.content,
    sportClubs.content,
    sportRewardKinds.content,
  );

  // let entityNameCode = getEntityNameCode(
  //   initialData.name,
  //   initialData.rewardType?.categoryCode || initialData.type?.code || initialData.typeCode,
  //   initialData.rewardType?.code || initialData.sportRewardCode
  // );

  // if (initialData.categoryCode === SectionCodes.profession) {
  //   entityNameCode = getEntityNameCodeNew(
  //     initialData.name,
  //     initialData.categoryCode,
  //     initialData.dataKind || undefined,
  //     initialData.typeCode
  //   );
  // }

  const mappedLinkedObjects = initialData.linkedObjects
    ?.map((linkedOnject) => {
      return linkablePersonObjects.find((personObject) => personObject.code === linkedOnject.entityId);
    })
    .filter(isDefined);

  const mappedFormData: IPortfolioEntryFormData = {
    categoryCode: initialData.categoryCode,
    dataKind: initialData.dataKind || undefined,
    typeCode: initialData.type?.code || initialData.typeCode || initialData.rewardType?.categoryCode,
    name: initialData.id ? entityNameCode : undefined,
    reward: initialData.rewardType?.code || initialData.sportRewardCode,
    subjectId:
      initialData.subjectCode
        ?.split(', ')
        .map((subject) => Number(subject))
        .filter((subject) => subject) ||
      initialData.disciplineCode
        ?.split(', ')
        .map((subject) => Number(subject))
        .filter((subject) => subject) ||
      initialData.subspecies
        ?.split(', ')
        .map((subject) => Number(subject))
        .filter((subject) => subject) ||
      [],
    linkedObjects: mappedLinkedObjects ?? [],
    entityId: initialData.entityId || undefined,
    entityType: initialData.entityType || undefined,
    startDate: initialData.startDate ? new Date(Date.parse(initialData.startDate)) : null,
    sportKind: initialData.sportKind?.code,
    tourismKind: initialData.tourismKind?.code,
    endDate: initialData.endDate ? new Date(Date.parse(initialData.endDate)) : null,
    format:
      initialData.format?.code ||
      initialData.achievementActivityFormat?.code ||
      initialData.projectFormat?.code ||
      Number(initialData.status) ||
      1,
    profile: initialData.profile || undefined,
    participant: initialData.participantNumber || undefined,
    age: getInitialAgeLimit(initialData.ageLimit) as number,
    place: initialData.location || undefined,
    result: initialData.result || undefined,
    description: initialData.description || undefined,
    attachment:
      initialData.fileReferences?.map(({ deleted, id, name }): IFile => {
        const localId = uniqueId();
        return {
          localId,
          isLoading: false,
          response: {
            id,
            name: decodeURIComponent(name),
            isDelete: deleted,
          },
        };
      }) || [],
    secondName: initialData.name,
    classStart: ageLimitArray?.[0],
    classEnd: ageLimitArray?.[ageLimitArray.length - 1],
    eventLevel:
      initialData.levelEvent?.code ||
      initialData.levelProject?.code ||
      initialData.affilationLevel?.code ||
      initialData.levelReward?.code,
    olympiadLevel: initialData.levelOlympiad?.code || undefined,
    organizator: initialData.organizators?.split('; ') || [''],
    rewardNumber: initialData.rewardNumber || undefined,
    subCategory: initialData.subcategory?.code || undefined,
    date: initialData.date ? new Date(Date.parse(initialData.date)) : null,
    id: initialData.id,
    stageStartDate: initialData.stageStartDate ? new Date(Date.parse(initialData.stageStartDate)) : null,
    stageEndDate: initialData.stageEndDate ? new Date(Date.parse(initialData.stageEndDate)) : null,
    actionDate: initialData.actionDate ? new Date(Date.parse(initialData.actionDate)) : null,
    stageEvent: initialData.stageEvent,
    actionStage: initialData.actionStage,
    eventNumber: initialData.eventNumber,
    creationKindCode: initialData.creationKind?.code || Number(initialData.creationKindCode) || undefined,
    sportRewardCode: initialData.sportRewardCode,
    placeName: initialData.locationName,
    address: initialData.address,
    status: initialData.status ? getSpecialStatusEdit(initialData.status) : undefined,
    workCode: initialData.workCode,
    profession: initialData.profession,
    expireDate: initialData.expireDate ? new Date(Date.parse(initialData.expireDate)) : null,
    organizationCategory: initialData.organizationCategory,
    trainingStageCode: initialData.trainingStageCode,
    teamName: initialData.teamName,
    teamStartDate: initialData.teamStartDate ? new Date(Date.parse(initialData.teamStartDate)) : null,
    teamEndDate: initialData.teamEndDate ? new Date(Date.parse(initialData.teamEndDate)) : null,
    medicalClearanceFromDate: initialData.medicalClearanceFromDate
      ? new Date(Date.parse(initialData.medicalClearanceFromDate))
      : null,
    medicalClearanceToDate: initialData.medicalClearanceToDate
      ? new Date(Date.parse(initialData.medicalClearanceToDate))
      : null,
    isPresentationCompetence: initialData.isPresentationCompetence,
    participantCategory: initialData.participantCategory,

    giaCompetenceCode: initialData.competenceCode,
    giaName: initialData.name,
    giaResultScore: initialData.resultScore,
    giaMaxCompetenceScore: initialData.maxCompetenceScore,
    giaKod: initialData.kod,
    giaResultDate: initialData.resultDate ? new Date(Date.parse(initialData.resultDate)) : null,
    giaIsEarlyRelease: initialData.isEarlyRelease,

    studySpoDocument: mapSpoDocumentFormData(initialData, spoOrganizationRef.content, professionProgrammRef.content),
    professionSpoJob: mapJobFormData(initialData),
  };
  return mappedFormData;
};

export const serializeInterval = (intervalStart?: number, intervalEnd?: number) => {
  if (!intervalStart) return undefined;
  if (!intervalEnd) return String(intervalStart);

  let intervalString = '';
  for (let currentIntervalValue = intervalStart; currentIntervalValue <= intervalEnd; currentIntervalValue++) {
    intervalString += intervalString.length > 0 ? `, ${currentIntervalValue}` : String(currentIntervalValue);
  }

  return intervalString;
};

export const mapLinkablePersonObjectsToEntityLinkedObjects = (linkedObjects: ILinkablePersonObject[] | undefined) => {
  return linkedObjects?.map((linkedObject): IEntityLinkedObject => {
    return {
      entityId: linkedObject.code,
      entityType: linkedObject.entityTypeCode,
    };
  });
};

function getInitialAgeLimit(ageLimit?: IDictionaryItem | string | null) {
  if (!ageLimit) return;
  if (typeof ageLimit === 'object') {
    return ageLimit.code;
  }
}

function deserializeInterval(intervalString: string) {
  const divider = ',';
  const normalizedIntervalString = intervalString
    .replaceAll('—', divider)
    .replaceAll('-', divider)
    .replaceAll(', ', divider);
  return normalizedIntervalString.split(divider).map(Number);
}

// TODO функция должна возвращать только число
function getEntityNameCode(
  name: string | undefined,
  dataKind: number | undefined,
  typeCode: number | undefined,
  rewardKindCode: number | undefined,
  sportRewardKindCode: number | undefined,
  rewardCategoryCode: number | undefined,
  eventKinds: IEventKind[] | undefined,
  affilationKinds: IAffilationKind[] | undefined,
  sportClubs: ILocation[] | undefined,
  sportRewards: ISportReward[] | undefined,
): string | number | undefined {
  if (dataKind === SectionCodes.professionEvent) {
    return eventKinds
      ?.filter((eventKind) => eventKind.categoryCode === dataKind)
      .find((eventKind) => eventKind.value === name)?.code;
  } else if (dataKind === SectionCodes.project) {
    return name;
  } else if (rewardCategoryCode) {
    switch (rewardCategoryCode) {
      case SectionCodes.civilRewardStatus: {
        return rewardKindCode;
      }
      default:
        return undefined;
    }
  }
  switch (typeCode) {
    case SectionCodes.creationCollective:
    case SectionCodes.creationAffilation: {
      return (
        affilationKinds?.find((creation) => creation.value === name)?.code ||
        affilationKinds?.find(
          (creationKind) => creationKind.value === DifferentValue && creationKind.categoryCode === typeCode,
        )?.code
      );
    }
    case SectionCodes.civilAffilationGroup:
    case SectionCodes.civilAffilationMovement: {
      return (
        affilationKinds?.find((affilation) => affilation.value === name)?.code ||
        affilationKinds?.find((creationKind) => creationKind.value === DifferentValue)?.code
      );
    }
    case SectionCodes.sportClubTeam: {
      const possibleSportClubTeam = sportClubs?.filter(
        (sportClub) => sportClub.parentId === SportClubParentCodes.sportClub,
      );
      return (
        possibleSportClubTeam?.find((sportClub) => sportClub.value === name)?.code ||
        possibleSportClubTeam?.find((sportClub) => sportClub.code === OtherOrganizationCode)?.code
      );
    }
    case SectionCodes.gtoReward:
    case SectionCodes.sportRank:
    case SectionCodes.sportCategory:
    case SectionCodes.sportRewardTourism: {
      return sportRewards?.find((sportReward) => sportReward.value === name)?.code;
    }
    case SectionCodes.sportRewardCompetition: {
      return sportRewardKindCode;
    }
    case SectionCodes.civilRewardEvent: {
      return eventKinds?.find((eventKind) => eventKind.value === name)?.code;
    }
    case SectionCodes.scienceCircle:
    case SectionCodes.creationCircle: {
      return name;
    }
    default: {
      const entityNameCode = eventKinds
        ?.filter((eventKind) => eventKind.categoryCode === typeCode)
        .find((eventKind) => eventKind.value === name)?.code;
      return entityNameCode ?? DifferentCode;
    }
  }
}
