import { SxStyles } from 'types';

export const wrapperStyles: SxStyles = (theme) => ({
  flexDirection: 'row',
  gap: 2,
  alignItems: 'center',

  marginTop: '16px',

  [theme.breakpoints.down('commonSm')]: {
    gap: 1.5,
    marginTop: '12px',
  },
});

export const legendMarkStyles =
  (color: string): SxStyles =>
  (theme) => {
    return {
      width: '12px',
      height: '12px',
      borderRadius: '50%',
      background: color,

      [theme.breakpoints.down('commonMd')]: {
        width: '8px',
        height: '8px',
      },
    };
  };
