import { createContext } from 'react';

import { CommonUiKitSize, InputRenderMode } from 'portfolio3/ui-kit/types';

interface IInputFormControlContext {
  renderMode?: InputRenderMode;
  inputSize?: CommonUiKitSize;
}

export default createContext({} as IInputFormControlContext);
