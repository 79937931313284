import { ICommonResponse } from 'api/types';
import { IRootState } from 'reducers';
import { createSelector } from 'reselect';
import { IMappedEisDopRecommendation } from 'types';
import { isDefined } from 'utils';

import { EisDopCategoryCodeToSectionCode } from './recommendationThemes';

const eisDopRecommendationsSelector = createSelector(
  [(state: IRootState) => state.studentEisDopRecommendations],
  (eisDopRecommendations): ICommonResponse<IMappedEisDopRecommendation> => {
    const recommendations = eisDopRecommendations.content
      .map((recommendation): IMappedEisDopRecommendation | null => {
        const { programName, image_link, organizationAddress, announce, enroll_link, age, category } = recommendation;

        // "от 6.5 до 18.0"
        const ages = age.replace('от ', '').split(' до ').map(Number);
        const ageFrom = Math.floor(ages[0]);
        const ageTo = Math.floor(ages[1]);

        // перевод категории рекомендации к нашей категории
        const recommendationCategoryCode = Number(category);
        const sectionCode = EisDopCategoryCodeToSectionCode[recommendationCategoryCode] as number | undefined;

        if (!sectionCode) return null;

        return {
          title: programName,
          image: image_link,
          address: organizationAddress,
          description: announce,
          link: enroll_link,
          ageFrom,
          ageTo,
          sectionCode,
        };
      })
      .filter(isDefined);

    return {
      loading: eisDopRecommendations.loading,
      content: recommendations,
    };
  },
);

export default eisDopRecommendationsSelector;
