import { IEgeExamSubject } from 'api/types';
import { isDefined } from 'utils';

import {
  FOREIGN_LANGUAGE_SUBJECT_INITIAL_CODE,
  REF_FOREIGN_LANGUAGE_SUBJECT,
  RUSSIAN_LANGUAGE_SUBJECT,
} from '../const';

export const mapLocalSubjectNameToRefSubjectName = (localSubject: string) => {
  return isAnyForeignLanguageSubject(localSubject) ? REF_FOREIGN_LANGUAGE_SUBJECT : localSubject;
};

export const getMappedUniqueRefSubjectCodes = (localSubjectCodes: number[], subjectsRef: IEgeExamSubject[]) => {
  const mappedSubjects = mapLocalSubjectCodesToRefSubjectCodes(localSubjectCodes, subjectsRef).filter(isDefined);
  const uniqueSubjects = Array.from(new Set(mappedSubjects));
  return uniqueSubjects;
};

export const findForeignLanguageSubjectRef = (egeSubjectsRef: IEgeExamSubject[]) => {
  const foreignLanguageSubject = egeSubjectsRef.find(
    (subjectRef) => subjectRef.title.toLowerCase() === REF_FOREIGN_LANGUAGE_SUBJECT.toLowerCase(),
  );

  return foreignLanguageSubject ?? null;
};

export const isAnyForeignLanguageSubject = (subject: string) => {
  const foreignSubjectRegexp = /.* язык/gi;

  const exception = RUSSIAN_LANGUAGE_SUBJECT;
  const isException = subject.toLowerCase().includes(exception.toLowerCase());

  return !isException && foreignSubjectRegexp.test(subject);
};

/**
 * Функция делает обратный маппинг наших кодов предметов на коды из справочника
 */
function mapLocalSubjectCodesToRefSubjectCodes(localSubjectCodes: number[], egeSubjectsRef: IEgeExamSubject[]) {
  const foreignLanguageSubject = findForeignLanguageSubjectRef(egeSubjectsRef);

  return localSubjectCodes?.map((subjectCode) => {
    return subjectCode <= FOREIGN_LANGUAGE_SUBJECT_INITIAL_CODE ? foreignLanguageSubject?.id : subjectCode;
  });
}
