import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root: SxStyles = {
  display: 'grid',
  gridTemplateRows: '130px',
  gridTemplateColumns: '100%',

  width: '216px',
  height: '270px',

  border: '1px solid',
  borderColor: NeutralColors.night_neutrals_700,
};

export const previewPlaceholder: SxStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  height: '100%',
  padding: '8px',

  backgroundColor: NeutralColors.night_neutrals_900,
};

export const previewPlaceholderText: SxStyles = {
  fontWeight: 800,
  fontSize: '24px',
  lineHeight: '24px',
  letterSpacing: '10%',

  color: NeutralColors.light_neutrals_500,
  opacity: 0.4,
};

export const body: SxStyles = {
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  padding: '12px',

  borderTop: '1px solid',
  borderColor: NeutralColors.night_neutrals_700,
};
