/* eslint-disable max-len */
import { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const Bear: FC<SvgIconProps> = (props) => (
  <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M24.4252 13.8436C26.9288 13.8436 28.9583 11.814 28.9583 9.31046C28.9583 6.80689 26.9288 4.77734 24.4252 4.77734C21.9216 4.77734 19.8921 6.80689 19.8921 9.31046C19.8921 11.814 21.9216 13.8436 24.4252 13.8436Z"
      fill="#995F50"
    />
    <path
      d="M24.4252 13.8436C26.9288 13.8436 28.9583 11.814 28.9583 9.31046C28.9583 6.80689 26.9288 4.77734 24.4252 4.77734C21.9216 4.77734 19.8921 6.80689 19.8921 9.31046C19.8921 11.814 21.9216 13.8436 24.4252 13.8436Z"
      fill="url(#paint0_linear_64_90052)"
    />
    <path
      d="M24.4252 13.8436C26.9288 13.8436 28.9583 11.814 28.9583 9.31046C28.9583 6.80689 26.9288 4.77734 24.4252 4.77734C21.9216 4.77734 19.8921 6.80689 19.8921 9.31046C19.8921 11.814 21.9216 13.8436 24.4252 13.8436Z"
      fill="url(#paint1_linear_64_90052)"
    />
    <path
      d="M24.4252 13.8436C26.9288 13.8436 28.9583 11.814 28.9583 9.31046C28.9583 6.80689 26.9288 4.77734 24.4252 4.77734C21.9216 4.77734 19.8921 6.80689 19.8921 9.31046C19.8921 11.814 21.9216 13.8436 24.4252 13.8436Z"
      fill="url(#paint2_radial_64_90052)"
    />
    <g filter="url(#filter0_i_64_90052)">
      <path
        d="M24.425 11.9609C25.8554 11.9609 27.0149 10.8014 27.0149 9.37109C27.0149 7.94076 25.8554 6.78125 24.425 6.78125C22.9947 6.78125 21.8352 7.94076 21.8352 9.37109C21.8352 10.8014 22.9947 11.9609 24.425 11.9609Z"
        fill="url(#paint3_linear_64_90052)"
      />
    </g>
    <path
      d="M7.58504 13.8436C5.08147 13.8436 3.05193 11.814 3.05193 9.31046C3.05193 6.80689 5.08147 4.77734 7.58504 4.77734C10.0886 4.77734 12.1182 6.80689 12.1182 9.31046C12.1182 11.814 10.0886 13.8436 7.58504 13.8436Z"
      fill="#966357"
    />
    <path
      d="M7.58504 13.8436C5.08147 13.8436 3.05193 11.814 3.05193 9.31046C3.05193 6.80689 5.08147 4.77734 7.58504 4.77734C10.0886 4.77734 12.1182 6.80689 12.1182 9.31046C12.1182 11.814 10.0886 13.8436 7.58504 13.8436Z"
      fill="url(#paint4_linear_64_90052)"
    />
    <path
      d="M7.58504 13.8436C5.08147 13.8436 3.05193 11.814 3.05193 9.31046C3.05193 6.80689 5.08147 4.77734 7.58504 4.77734C10.0886 4.77734 12.1182 6.80689 12.1182 9.31046C12.1182 11.814 10.0886 13.8436 7.58504 13.8436Z"
      fill="url(#paint5_linear_64_90052)"
    />
    <path
      d="M7.58504 13.8436C5.08147 13.8436 3.05193 11.814 3.05193 9.31046C3.05193 6.80689 5.08147 4.77734 7.58504 4.77734C10.0886 4.77734 12.1182 6.80689 12.1182 9.31046C12.1182 11.814 10.0886 13.8436 7.58504 13.8436Z"
      fill="url(#paint6_radial_64_90052)"
    />
    <g filter="url(#filter1_i_64_90052)">
      <path
        d="M7.58496 11.9609C6.15463 11.9609 4.99512 10.8014 4.99512 9.37109C4.99512 7.94076 6.15463 6.78125 7.58496 6.78125C9.01529 6.78125 10.1748 7.94076 10.1748 9.37109C10.1748 10.8014 9.01529 11.9609 7.58496 11.9609Z"
        fill="url(#paint7_radial_64_90052)"
      />
      <path
        d="M7.58496 11.9609C6.15463 11.9609 4.99512 10.8014 4.99512 9.37109C4.99512 7.94076 6.15463 6.78125 7.58496 6.78125C9.01529 6.78125 10.1748 7.94076 10.1748 9.37109C10.1748 10.8014 9.01529 11.9609 7.58496 11.9609Z"
        fill="url(#paint8_linear_64_90052)"
      />
    </g>
    <path
      d="M30.2188 20.1773C30.2188 20.1773 30.2188 20.1673 30.2188 20.1475C30.2188 19.6114 30.168 19.0852 30.0664 18.5789C29.7516 16.524 28.7867 12.8608 25.8414 9.90241C21.6063 5.65349 16 5.93146 16 5.93146C16 5.93146 10.3938 5.65349 6.15859 9.90241C3.20312 12.8608 2.24844 16.524 1.93359 18.5789C1.83203 19.0852 1.78125 19.6114 1.78125 20.1574C1.78125 20.1773 1.78125 20.1872 1.78125 20.1872C1.78125 23.5923 3.80234 26.5407 6.7375 27.9504C6.7375 27.9504 9.90625 29.7671 16 29.7671C22.0938 29.7671 25.2625 27.9504 25.2625 27.9504C28.1977 26.5308 30.2188 23.5823 30.2188 20.1773Z"
      fill="url(#paint9_radial_64_90052)"
    />
    <path
      d="M30.2188 20.1773C30.2188 20.1773 30.2188 20.1673 30.2188 20.1475C30.2188 19.6114 30.168 19.0852 30.0664 18.5789C29.7516 16.524 28.7867 12.8608 25.8414 9.90241C21.6063 5.65349 16 5.93146 16 5.93146C16 5.93146 10.3938 5.65349 6.15859 9.90241C3.20312 12.8608 2.24844 16.524 1.93359 18.5789C1.83203 19.0852 1.78125 19.6114 1.78125 20.1574C1.78125 20.1773 1.78125 20.1872 1.78125 20.1872C1.78125 23.5923 3.80234 26.5407 6.7375 27.9504C6.7375 27.9504 9.90625 29.7671 16 29.7671C22.0938 29.7671 25.2625 27.9504 25.2625 27.9504C28.1977 26.5308 30.2188 23.5823 30.2188 20.1773Z"
      fill="url(#paint10_radial_64_90052)"
    />
    <path
      d="M30.2188 20.1773C30.2188 20.1773 30.2188 20.1673 30.2188 20.1475C30.2188 19.6114 30.168 19.0852 30.0664 18.5789C29.7516 16.524 28.7867 12.8608 25.8414 9.90241C21.6063 5.65349 16 5.93146 16 5.93146C16 5.93146 10.3938 5.65349 6.15859 9.90241C3.20312 12.8608 2.24844 16.524 1.93359 18.5789C1.83203 19.0852 1.78125 19.6114 1.78125 20.1574C1.78125 20.1773 1.78125 20.1872 1.78125 20.1872C1.78125 23.5923 3.80234 26.5407 6.7375 27.9504C6.7375 27.9504 9.90625 29.7671 16 29.7671C22.0938 29.7671 25.2625 27.9504 25.2625 27.9504C28.1977 26.5308 30.2188 23.5823 30.2188 20.1773Z"
      fill="url(#paint11_radial_64_90052)"
    />
    <path
      d="M30.2188 20.1773C30.2188 20.1773 30.2188 20.1673 30.2188 20.1475C30.2188 19.6114 30.168 19.0852 30.0664 18.5789C29.7516 16.524 28.7867 12.8608 25.8414 9.90241C21.6063 5.65349 16 5.93146 16 5.93146C16 5.93146 10.3938 5.65349 6.15859 9.90241C3.20312 12.8608 2.24844 16.524 1.93359 18.5789C1.83203 19.0852 1.78125 19.6114 1.78125 20.1574C1.78125 20.1773 1.78125 20.1872 1.78125 20.1872C1.78125 23.5923 3.80234 26.5407 6.7375 27.9504C6.7375 27.9504 9.90625 29.7671 16 29.7671C22.0938 29.7671 25.2625 27.9504 25.2625 27.9504C28.1977 26.5308 30.2188 23.5823 30.2188 20.1773Z"
      fill="url(#paint12_radial_64_90052)"
    />
    <path
      d="M30.2188 20.1773C30.2188 20.1773 30.2188 20.1673 30.2188 20.1475C30.2188 19.6114 30.168 19.0852 30.0664 18.5789C29.7516 16.524 28.7867 12.8608 25.8414 9.90241C21.6063 5.65349 16 5.93146 16 5.93146C16 5.93146 10.3938 5.65349 6.15859 9.90241C3.20312 12.8608 2.24844 16.524 1.93359 18.5789C1.83203 19.0852 1.78125 19.6114 1.78125 20.1574C1.78125 20.1773 1.78125 20.1872 1.78125 20.1872C1.78125 23.5923 3.80234 26.5407 6.7375 27.9504C6.7375 27.9504 9.90625 29.7671 16 29.7671C22.0938 29.7671 25.2625 27.9504 25.2625 27.9504C28.1977 26.5308 30.2188 23.5823 30.2188 20.1773Z"
      fill="url(#paint13_radial_64_90052)"
    />
    <g filter="url(#filter2_f_64_90052)">
      <path
        d="M11.6875 18.1305C11.2775 18.1305 10.9375 17.7905 10.9375 17.3805V15.8945C10.9375 15.4845 11.2775 15.1445 11.6875 15.1445C12.0975 15.1445 12.4375 15.4845 12.4375 15.8945V17.3805C12.4375 17.7905 12.1075 18.1305 11.6875 18.1305Z"
        fill="#79393F"
      />
    </g>
    <g filter="url(#filter3_f_64_90052)">
      <path
        d="M19.6875 18.0445C19.2775 18.0445 18.9375 17.7045 18.9375 17.2945V15.8086C18.9375 15.3986 19.2775 15.0586 19.6875 15.0586C20.0975 15.0586 20.4375 15.3986 20.4375 15.8086V17.2945C20.4375 17.7045 20.1075 18.0445 19.6875 18.0445Z"
        fill="#79393F"
      />
    </g>
    <path
      d="M12 17.732C11.59 17.732 11.25 17.392 11.25 16.982V15.4961C11.25 15.0861 11.59 14.7461 12 14.7461C12.41 14.7461 12.75 15.0861 12.75 15.4961V16.982C12.75 17.392 12.42 17.732 12 17.732Z"
      fill="url(#paint14_linear_64_90052)"
    />
    <path
      d="M12 17.732C11.59 17.732 11.25 17.392 11.25 16.982V15.4961C11.25 15.0861 11.59 14.7461 12 14.7461C12.41 14.7461 12.75 15.0861 12.75 15.4961V16.982C12.75 17.392 12.42 17.732 12 17.732Z"
      fill="url(#paint15_linear_64_90052)"
    />
    <path
      d="M12 17.732C11.59 17.732 11.25 17.392 11.25 16.982V15.4961C11.25 15.0861 11.59 14.7461 12 14.7461C12.41 14.7461 12.75 15.0861 12.75 15.4961V16.982C12.75 17.392 12.42 17.732 12 17.732Z"
      fill="url(#paint16_radial_64_90052)"
    />
    <path
      d="M20 17.732C19.59 17.732 19.25 17.392 19.25 16.982V15.4961C19.25 15.0861 19.59 14.7461 20 14.7461C20.41 14.7461 20.75 15.0861 20.75 15.4961V16.982C20.75 17.392 20.42 17.732 20 17.732Z"
      fill="url(#paint17_linear_64_90052)"
    />
    <path
      d="M20 17.732C19.59 17.732 19.25 17.392 19.25 16.982V15.4961C19.25 15.0861 19.59 14.7461 20 14.7461C20.41 14.7461 20.75 15.0861 20.75 15.4961V16.982C20.75 17.392 20.42 17.732 20 17.732Z"
      fill="url(#paint18_linear_64_90052)"
    />
    <path
      d="M20 17.732C19.59 17.732 19.25 17.392 19.25 16.982V15.4961C19.25 15.0861 19.59 14.7461 20 14.7461C20.41 14.7461 20.75 15.0861 20.75 15.4961V16.982C20.75 17.392 20.42 17.732 20 17.732Z"
      fill="url(#paint19_radial_64_90052)"
    />
    <g filter="url(#filter4_f_64_90052)">
      <path
        d="M15.5576 18.8427C14.8509 18.8299 13.6574 18.9495 12.4324 19.4428C11.0272 20.0088 10.3941 21.6032 10.798 23.0633C11.3695 25.1289 12.6489 27.1865 15.5576 27.1865C18.4664 27.1865 19.7458 25.1289 20.3173 23.0633C20.7212 21.6032 20.0881 20.0088 18.6828 19.4428C17.4579 18.9495 16.2643 18.8299 15.5576 18.8427Z"
        fill="#6A3257"
      />
    </g>
    <path
      d="M15.9999 18.1298C15.2667 18.1166 14.0285 18.2407 12.7577 18.7525C11.2998 19.3396 10.643 20.9937 11.062 22.5085C11.6549 24.6514 12.9822 26.7861 15.9999 26.7861C19.0175 26.7861 20.3449 24.6514 20.9378 22.5085C21.3568 20.9937 20.7 19.3396 19.2421 18.7525C17.9713 18.2407 16.7331 18.1166 15.9999 18.1298Z"
      fill="url(#paint20_linear_64_90052)"
    />
    <path
      d="M15.9999 18.1298C15.2667 18.1166 14.0285 18.2407 12.7577 18.7525C11.2998 19.3396 10.643 20.9937 11.062 22.5085C11.6549 24.6514 12.9822 26.7861 15.9999 26.7861C19.0175 26.7861 20.3449 24.6514 20.9378 22.5085C21.3568 20.9937 20.7 19.3396 19.2421 18.7525C17.9713 18.2407 16.7331 18.1166 15.9999 18.1298Z"
      fill="url(#paint21_linear_64_90052)"
    />
    <path
      d="M15.9999 18.1298C15.2667 18.1166 14.0285 18.2407 12.7577 18.7525C11.2998 19.3396 10.643 20.9937 11.062 22.5085C11.6549 24.6514 12.9822 26.7861 15.9999 26.7861C19.0175 26.7861 20.3449 24.6514 20.9378 22.5085C21.3568 20.9937 20.7 19.3396 19.2421 18.7525C17.9713 18.2407 16.7331 18.1166 15.9999 18.1298Z"
      fill="url(#paint22_radial_64_90052)"
    />
    <path
      d="M15.9999 18.1298C15.2667 18.1166 14.0285 18.2407 12.7577 18.7525C11.2998 19.3396 10.643 20.9937 11.062 22.5085C11.6549 24.6514 12.9822 26.7861 15.9999 26.7861C19.0175 26.7861 20.3449 24.6514 20.9378 22.5085C21.3568 20.9937 20.7 19.3396 19.2421 18.7525C17.9713 18.2407 16.7331 18.1166 15.9999 18.1298Z"
      fill="url(#paint23_radial_64_90052)"
    />
    <path
      d="M15.9951 22.2793C15.9951 23.3243 15.2151 24.1734 14.2551 24.1734C13.2951 24.1734 12.5151 23.3243 12.5151 22.2793"
      stroke="url(#paint24_radial_64_90052)"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M15.9951 22.2793C15.9951 23.3243 15.2151 24.1734 14.2551 24.1734C13.2951 24.1734 12.5151 23.3243 12.5151 22.2793"
      stroke="url(#paint25_radial_64_90052)"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M19.4849 22.2793C19.4849 23.3243 18.7049 24.1734 17.7449 24.1734C16.7849 24.1734 16.0049 23.3243 16.0049 22.2793"
      stroke="url(#paint26_radial_64_90052)"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <g filter="url(#filter5_f_64_90052)">
      <path
        d="M12.5151 22.2793C12.5568 22.8001 13.0495 23.8605 14.1245 23.9355C15.4683 24.0293 15.937 22.9668 15.9839 22.2793C15.9578 22.8314 16.562 24.0137 17.8276 24.0137C18.9151 24.0137 19.4266 22.8314 19.4839 22.2793"
        stroke="url(#paint27_linear_64_90052)"
        strokeWidth="0.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <g filter="url(#filter6_f_64_90052)">
      <path
        d="M14.7099 19.102L16.6 18.9238C17.3286 18.8552 17.7566 19.5207 17.4321 20.1005C17.0558 20.7727 16.4817 21.2271 16.4817 21.2271C16.2932 21.3816 16.0891 21.4721 15.8884 21.4895C15.688 21.5099 15.4706 21.4591 15.2565 21.3426C15.2565 21.3426 14.6076 21.0034 14.1124 20.4134C13.6852 19.9045 13.9813 19.1707 14.7099 19.102Z"
        fill="#DB8E70"
      />
    </g>
    <path
      d="M15.1131 18.7539H16.8867C17.5703 18.7539 17.9101 19.4788 17.5574 20.0428C17.1485 20.6967 16.5747 21.1078 16.5747 21.1078C16.3858 21.2482 16.1881 21.3214 15.9999 21.3199C15.8117 21.3214 15.6139 21.2482 15.4251 21.1078C15.4251 21.1078 14.8513 20.6967 14.4424 20.0428C14.0897 19.4788 14.4295 18.7539 15.1131 18.7539Z"
      fill="url(#paint28_linear_64_90052)"
    />
    <path
      d="M15.1131 18.7539H16.8867C17.5703 18.7539 17.9101 19.4788 17.5574 20.0428C17.1485 20.6967 16.5747 21.1078 16.5747 21.1078C16.3858 21.2482 16.1881 21.3214 15.9999 21.3199C15.8117 21.3214 15.6139 21.2482 15.4251 21.1078C15.4251 21.1078 14.8513 20.6967 14.4424 20.0428C14.0897 19.4788 14.4295 18.7539 15.1131 18.7539Z"
      fill="url(#paint29_linear_64_90052)"
    />
    <path
      d="M15.1131 18.7539H16.8867C17.5703 18.7539 17.9101 19.4788 17.5574 20.0428C17.1485 20.6967 16.5747 21.1078 16.5747 21.1078C16.3858 21.2482 16.1881 21.3214 15.9999 21.3199C15.8117 21.3214 15.6139 21.2482 15.4251 21.1078C15.4251 21.1078 14.8513 20.6967 14.4424 20.0428C14.0897 19.4788 14.4295 18.7539 15.1131 18.7539Z"
      fill="url(#paint30_linear_64_90052)"
    />
    <path
      d="M15.1131 18.7539H16.8867C17.5703 18.7539 17.9101 19.4788 17.5574 20.0428C17.1485 20.6967 16.5747 21.1078 16.5747 21.1078C16.3858 21.2482 16.1881 21.3214 15.9999 21.3199C15.8117 21.3214 15.6139 21.2482 15.4251 21.1078C15.4251 21.1078 14.8513 20.6967 14.4424 20.0428C14.0897 19.4788 14.4295 18.7539 15.1131 18.7539Z"
      fill="url(#paint31_radial_64_90052)"
    />
    <path
      d="M15.1131 18.7539H16.8867C17.5703 18.7539 17.9101 19.4788 17.5574 20.0428C17.1485 20.6967 16.5747 21.1078 16.5747 21.1078C16.3858 21.2482 16.1881 21.3214 15.9999 21.3199C15.8117 21.3214 15.6139 21.2482 15.4251 21.1078C15.4251 21.1078 14.8513 20.6967 14.4424 20.0428C14.0897 19.4788 14.4295 18.7539 15.1131 18.7539Z"
      fill="url(#paint32_radial_64_90052)"
    />
    <g filter="url(#filter7_f_64_90052)">
      <path
        d="M29.1422 20.6986C28.4865 15.2279 26.2124 10.4912 21.8704 8.57028C21.3089 8.32186 20.6692 8.58664 20.3793 9.12787C20.1188 9.61397 20.2047 10.2101 20.5723 10.6211C22.8673 13.1875 24.3448 16.0252 24.4985 20.6094C24.555 22.2962 26.2407 23.5502 27.8017 22.9087C28.6898 22.5437 29.2565 21.6519 29.1422 20.6986Z"
        fill="url(#paint33_linear_64_90052)"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_64_90052"
        x="21.7352"
        y="6.78125"
        width="5.27969"
        height="5.37969"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-0.1" dy="0.2" />
        <feGaussianBlur stdDeviation="0.125" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.980392 0 0 0 0 0.678431 0 0 0 0 0.439216 0 0 0 1 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_64_90052" />
      </filter>
      <filter
        id="filter1_i_64_90052"
        x="4.89512"
        y="6.78125"
        width="5.27969"
        height="5.37969"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-0.1" dy="0.2" />
        <feGaussianBlur stdDeviation="0.125" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.980392 0 0 0 0 0.678431 0 0 0 0 0.439216 0 0 0 1 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_64_90052" />
      </filter>
      <filter
        id="filter2_f_64_90052"
        x="10.4375"
        y="14.6445"
        width="2.5"
        height="3.98633"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_64_90052" />
      </filter>
      <filter
        id="filter3_f_64_90052"
        x="18.4375"
        y="14.5586"
        width="2.5"
        height="3.98633"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_64_90052" />
      </filter>
      <filter
        id="filter4_f_64_90052"
        x="9.67798"
        y="17.8418"
        width="11.7593"
        height="10.3438"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_64_90052" />
      </filter>
      <filter
        id="filter5_f_64_90052"
        x="12.1401"
        y="21.9043"
        width="7.71875"
        height="2.48438"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.125" result="effect1_foregroundBlur_64_90052" />
      </filter>
      <filter
        id="filter6_f_64_90052"
        x="13.215"
        y="18.218"
        width="5.02695"
        height="3.97617"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.35" result="effect1_foregroundBlur_64_90052" />
      </filter>
      <filter
        id="filter7_f_64_90052"
        x="16.2288"
        y="4.47656"
        width="16.928"
        height="22.6035"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_64_90052" />
      </filter>
      <linearGradient
        id="paint0_linear_64_90052"
        x1="24.7864"
        y1="9.78734"
        x2="25.5052"
        y2="7.94359"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8B5754" />
        <stop offset="1" stopColor="#8B5754" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_64_90052"
        x1="21.2552"
        y1="5.72484"
        x2="22.2864"
        y2="6.63109"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8B5754" />
        <stop offset="1" stopColor="#8B5754" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint2_radial_64_90052"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(28.0677 6.84984) rotate(142.716) scale(3.45634 3.61664)"
      >
        <stop stopColor="#BE8D80" />
        <stop offset="1" stopColor="#BE8D80" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint3_linear_64_90052"
        x1="25.4738"
        y1="8.47503"
        x2="23.4425"
        y2="9.16253"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF9A52" />
        <stop offset="1" stopColor="#FFB675" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_64_90052"
        x1="7.22382"
        y1="9.78734"
        x2="6.50507"
        y2="7.94359"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8B5754" />
        <stop offset="1" stopColor="#8B5754" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_64_90052"
        x1="6.28628"
        y1="11.8811"
        x2="6.16129"
        y2="8.38109"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#704848" />
        <stop offset="1" stopColor="#704848" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint6_radial_64_90052"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(10.38 6.00609) rotate(135.465) scale(3.92103 4.10288)"
      >
        <stop stopColor="#BE8D80" />
        <stop offset="1" stopColor="#BE8D80" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint7_radial_64_90052"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(8.05182 8.7094) rotate(-159.574) scale(2.41764 5.89257)"
      >
        <stop stopColor="#FF9A52" />
        <stop offset="1" stopColor="#FBB47E" />
      </radialGradient>
      <linearGradient
        id="paint8_linear_64_90052"
        x1="7.36432"
        y1="9.61565"
        x2="5.78619"
        y2="7.86565"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F78B3F" />
        <stop offset="0.434645" stopColor="#F78B3F" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint9_radial_64_90052"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(15.9455 5.24023) rotate(90) scale(24.5269 23.9742)"
      >
        <stop stopColor="#A86F60" />
        <stop offset="1" stopColor="#8D5857" />
      </radialGradient>
      <radialGradient
        id="paint10_radial_64_90052"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16 28.0527) rotate(-90) scale(11.1719 22.1925)"
      >
        <stop stopColor="#863C6E" />
        <stop offset="1" stopColor="#863C6E" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint11_radial_64_90052"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16 6.61523) rotate(90) scale(23.6719 24.1663)"
      >
        <stop offset="0.945685" stopColor="#945897" stopOpacity="0" />
        <stop offset="1" stopColor="#945897" />
      </radialGradient>
      <radialGradient
        id="paint12_radial_64_90052"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(25.255 19.9434) rotate(180) scale(24.4375 17.875)"
      >
        <stop offset="0.877238" stopColor="#937C79" stopOpacity="0" />
        <stop offset="1" stopColor="#937C79" />
      </radialGradient>
      <radialGradient
        id="paint13_radial_64_90052"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(25.255 5.92774) rotate(122.909) scale(10.8133 11.024)"
      >
        <stop stopColor="#B17D6E" />
        <stop offset="1" stopColor="#B17D6E" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint14_linear_64_90052"
        x1="12.75"
        y1="16.0524"
        x2="11.8817"
        y2="16.0524"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.225" stopColor="#594C4F" />
        <stop offset="1" stopColor="#392833" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_64_90052"
        x1="12.9844"
        y1="17.5921"
        x2="12.9844"
        y2="16.1695"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#492847" />
        <stop offset="1" stopColor="#552D52" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint16_radial_64_90052"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(12.1613 16.4742) rotate(-104.85) scale(1.74797 1.88432)"
      >
        <stop offset="0.813951" stopColor="#594C4F" stopOpacity="0" />
        <stop offset="1" stopColor="#594C4F" />
      </radialGradient>
      <linearGradient
        id="paint17_linear_64_90052"
        x1="20.75"
        y1="16.0524"
        x2="19.8817"
        y2="16.0524"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.225" stopColor="#594C4F" />
        <stop offset="1" stopColor="#392833" />
      </linearGradient>
      <linearGradient
        id="paint18_linear_64_90052"
        x1="20.9844"
        y1="17.5921"
        x2="20.9844"
        y2="16.1695"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#492847" />
        <stop offset="1" stopColor="#552D52" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint19_radial_64_90052"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(20.1613 16.4742) rotate(-104.85) scale(1.74797 1.88432)"
      >
        <stop offset="0.813951" stopColor="#594C4F" stopOpacity="0" />
        <stop offset="1" stopColor="#594C4F" />
      </radialGradient>
      <linearGradient
        id="paint20_linear_64_90052"
        x1="15.9999"
        y1="18.1289"
        x2="15.9999"
        y2="26.7861"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F1BA7F" />
        <stop offset="1" stopColor="#FFA392" />
      </linearGradient>
      <linearGradient
        id="paint21_linear_64_90052"
        x1="15.4424"
        y1="27.5673"
        x2="15.4424"
        y2="23.4423"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E5788F" />
        <stop offset="1" stopColor="#E5788F" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint22_radial_64_90052"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(18.9736 21.0361) rotate(129.413) scale(4.08527 3.62985)"
      >
        <stop stopColor="#FFF2B4" />
        <stop offset="0.794385" stopColor="#FFF2B4" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint23_radial_64_90052"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(15.9999 27.5986) rotate(-90) scale(9.6875 8.48106)"
      >
        <stop offset="0.914557" stopColor="#FDAE61" stopOpacity="0" />
        <stop offset="1" stopColor="#FDAE61" />
      </radialGradient>
      <radialGradient
        id="paint24_radial_64_90052"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(14.2491 22.8374) rotate(90) scale(1.71875 2.18838)"
      >
        <stop offset="0.00558659" stopColor="#423539" />
        <stop offset="1" stopColor="#3C223C" />
      </radialGradient>
      <radialGradient
        id="paint25_radial_64_90052"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(14.3645 21.8174) rotate(70.994) scale(2.97467 7.41114)"
      >
        <stop offset="0.00558659" stopColor="#533530" />
        <stop offset="1" stopColor="#543057" />
      </radialGradient>
      <radialGradient
        id="paint26_radial_64_90052"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17.9424 22.8643) rotate(96.8587) scale(1.70404 4.78132)"
      >
        <stop offset="0.00558659" stopColor="#533530" />
        <stop offset="1" stopColor="#543057" />
      </radialGradient>
      <linearGradient
        id="paint27_linear_64_90052"
        x1="12.3276"
        y1="22.7793"
        x2="19.8433"
        y2="22.7793"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5F5356" />
        <stop offset="0.288981" stopColor="#5F5356" stopOpacity="0" />
        <stop offset="0.444906" stopColor="#5F5356" stopOpacity="0" />
        <stop offset="0.534304" stopColor="#5F5356" />
        <stop offset="1" stopColor="#5F5356" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint28_linear_64_90052"
        x1="15.583"
        y1="19.0687"
        x2="16.0799"
        y2="20.8524"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5F5254" />
        <stop offset="1" stopColor="#2E2127" />
      </linearGradient>
      <linearGradient
        id="paint29_linear_64_90052"
        x1="15.7636"
        y1="19.427"
        x2="15.7636"
        y2="18.223"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.146552" stopColor="#34222C" stopOpacity="0" />
        <stop offset="0.872727" stopColor="#34222C" />
      </linearGradient>
      <linearGradient
        id="paint30_linear_64_90052"
        x1="15.1604"
        y1="21.4464"
        x2="15.1604"
        y2="19.6261"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#552D52" />
        <stop offset="1" stopColor="#552D52" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint31_radial_64_90052"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(13.8595 20.551) rotate(-18.752) scale(4.48262 2.01281)"
      >
        <stop offset="0.827602" stopColor="#5C5458" stopOpacity="0" />
        <stop offset="0.935007" stopColor="#5C5458" />
      </radialGradient>
      <radialGradient
        id="paint32_radial_64_90052"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17.0986 19.4281) rotate(145.376) scale(0.797488 0.483742)"
      >
        <stop stopColor="#6A5C5E" />
        <stop offset="1" stopColor="#6A5C5E" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint33_linear_64_90052"
        x1="28.9499"
        y1="12.6565"
        x2="20.1847"
        y2="17.5111"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BF8A80" />
        <stop offset="1" stopColor="#BF8A80" stopOpacity="0" />
      </linearGradient>
    </defs>
  </SvgIcon>
);

export default Bear;
