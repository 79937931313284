import { REGIONAL_DOMAIN, RegionalCodes } from '../const';
import { currentDomain } from './common';
import { deleteCookie } from './cookie';

export const hasPermission = (currentUser: string, roles: string[]): boolean => roles.includes(currentUser);

export const getAuthToken = () => document.cookie.split('aupd_token=')[1]?.split(';')[0];

export const removeAuthToken = () => deleteCookie('aupd_token', currentDomain);

/**
 * Преобразует полезную нагрузку из токена пользоватея в объект
 *
 * @param token токен
 * @returns объект с данными из payload токена
 */
export const decodeTokenPayload = (token: string): { [key: string]: unknown } => {
  const payload = token.split('.')[1];
  const decoded = atob(payload);
  return JSON.parse(decoded);
};

/**
 * Получает код региона из токена пользователя
 *
 * @param token токен
 * @returns Код региона, null если не найден
 */
export const getTokenRegion = (token?: string): number | null => {
  if (!token) return null;

  const tokenPayload = decodeTokenPayload(token);
  const tokenRegion: unknown = tokenPayload.rgn;

  return typeof tokenRegion === 'number' || typeof tokenRegion === 'string' ? Number(tokenRegion) : null;
};

/**
 * Проверяет, что токен является региональным
 *
 * @param token токен
 * @returns true если регион найден и не равен московскому региону (77), иначе false
 */
export const isRegionalToken = (token?: string): boolean => {
  const region = getTokenRegion(token);

  if (!region) return false;
  if (region === RegionalCodes.moscow) return false;

  return true;
};

/**
 * Проверяет токен пользователя на наличие регионального кода
 * Если код не найден или токен отсутствует, то проверяется текущий домен сервиса
 */
export const isRegionalUser = (): boolean => {
  const token = getAuthToken();
  const isTokenRegional = isRegionalToken(token);

  return isTokenRegional || currentDomain === REGIONAL_DOMAIN;
};

/**
 * Проверяет пользователя на принадлежность к Московской Области
 */
export const isMoscowMOUser = (): boolean => {
  const token = getAuthToken();
  const region = getTokenRegion(token);

  return region === RegionalCodes.moscowMO;
};
