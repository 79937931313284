import { getAccentColor, NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root: SxStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '12px',

  border: '1px solid',
  borderRadius: '8px',
  borderColor: NeutralColors.light_neutrals_300,
  boxShadow: '0px 1px 2px 0px rgba(41, 41, 64, 0.07)',
};

export const rootError: SxStyles = {
  borderColor: getAccentColor('red', '100'),
};

export const info: SxStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
};

export const iconContainer: SxStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  width: '48px',
  height: '48px',

  borderRadius: '8px',
  backgroundColor: NeutralColors.light_neutrals_200,

  svg: {
    width: '28px',
    height: '28px',
    color: NeutralColors.light_neutrals_600,
  },
};

export const deleteButton: SxStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  width: '40px',
  height: '40px',

  borderRadius: '50%',

  '&:hover': {
    backgroundColor: NeutralColors.light_neutrals_200,
  },

  svg: {
    width: '24px',
    height: '24px',
    color: NeutralColors.light_neutrals_600,
  },
};
