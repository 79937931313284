import { FC } from 'react';

import { useAppSelector, useDataLoad } from 'hooks';
import { GiaWorldskillsCard } from 'portfolio3/features/pdf/components/cards';
import GridLayout from 'portfolio3/features/pdf/components/GridLayout';
import PdfNoDataText from 'portfolio3/features/pdf/components/PdfNoDataText';
import { getDataLoadRequests } from 'utils';

import { widgetPdfIcons } from '../../../../icons';
import { professionGiaWorldSkillsSelector } from '../../../../selectors';
import PdfCommonDataEntityContainer from '../common';

import '../index.scss';

const PdfGiaWorldskillsContainer: FC = () => {
  const giaWorldskills = useAppSelector(professionGiaWorldSkillsSelector);
  useDataLoad({ shouldLoad: true, requests: getDataLoadRequests().professionGiaWorldSkills });

  const hasData = giaWorldskills.content.length > 0;

  return (
    <PdfCommonDataEntityContainer title="Демонстрационные экзамены" icon={widgetPdfIcons.pdfIconProfessionGia}>
      {hasData && (
        <GridLayout gap="16px">
          {giaWorldskills.content.map((entity) => {
            return <GiaWorldskillsCard key={entity.id} entity={entity} />;
          })}
        </GridLayout>
      )}
      {!hasData && <PdfNoDataText />}
    </PdfCommonDataEntityContainer>
  );
};

export default PdfGiaWorldskillsContainer;
