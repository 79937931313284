export enum ReportErrorTypeKeys {
  CARD_DATA_ERROR = 'cardDataError',
  INFORMATION_DOES_NOT_APPLY_TO_ME = 'informationDoesNotApplyToMe',
}

// error_type_ref
export const ReportErrorTypeCodes: Record<ReportErrorTypeKeys, number> = {
  [ReportErrorTypeKeys.CARD_DATA_ERROR]: 1,
  [ReportErrorTypeKeys.INFORMATION_DOES_NOT_APPLY_TO_ME]: 2,
};

export const ReportErrorTypeNames: Record<ReportErrorTypeKeys, string> = {
  [ReportErrorTypeKeys.CARD_DATA_ERROR]: 'В данных карточки допущена ошибка',
  [ReportErrorTypeKeys.INFORMATION_DOES_NOT_APPLY_TO_ME]: 'Указанные данные не относятся ко мне',
};

export const ReportErrorTypeAdminNames: Record<ReportErrorTypeKeys, string> = {
  [ReportErrorTypeKeys.CARD_DATA_ERROR]: 'В данных карточки допущена ошибка',
  [ReportErrorTypeKeys.INFORMATION_DOES_NOT_APPLY_TO_ME]: 'Указанные данные не относятся к учащемуся',
};
