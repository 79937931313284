import { FC } from 'react';

import { SvgIconProps } from '@mui/material';
import {
  IGeneralRating,
  IIndependentDiagnosticGeneralRating,
  IIndependentDiagnosticRating,
  IIndependentRating,
} from 'api/types';
import { DiagnosticRatingRegionCodes } from 'const';
import { isDefined } from 'utils';
import { roundNumberToTenths } from 'utils';

import { IChartRow } from '../components/RatingChart/ChartRow';
import { IResultBar } from '../components/RatingChart/types';
import { Cat, Dog, Hamster, Racoon } from '../images/avatars';
import { createMapFromRating } from '../utils';

interface IPlaces<T> {
  [key: number]: T | undefined;
}

interface ICreateRowObject {
  place: number;
  Emoji: FC<SvgIconProps>;
  studentData: number;
  placeData: number;
  studentPlaceStudentsCount?: number;
  placeStudentsCount?: number;
  yours?: boolean;
  resultBarProps?: IResultBar;
}

type RatingType = 'generalRating' | 'independentRating';

export const useDiagnosticRating = (type: RatingType) => {
  function defineYoursCondition(place: number, studentPlace: number, penultimatePlaceNumber: number) {
    if (place === studentPlace) return true;
    if (place === 3 && studentPlace > 3 && studentPlace < penultimatePlaceNumber) return true;
  }

  function createRow(rowData: ICreateRowObject): IChartRow {
    const {
      place,
      Emoji,
      yours,
      studentData,
      placeData,
      placeStudentsCount,
      studentPlaceStudentsCount,
      resultBarProps,
    } = rowData;
    const textPercentNumber = roundNumberToTenths(yours ? studentData : placeData);
    const textStudentsCount = yours ? studentPlaceStudentsCount : placeStudentsCount;
    const text = isDefined(resultBarProps) ? `${textStudentsCount} чел.` : `${textPercentNumber} %`;
    return {
      placeBarProps: {
        percent: yours ? studentData : placeData,
        text,
        Emoji,
        place,
        yours,
      },
      resultBarProps,
    };
  }

  function createChartRows(chartData: IGeneralRating | IIndependentRating) {
    const rows: IChartRow[] = [];
    const avatarPool = [Racoon, Cat, Hamster, Dog];
    if (type === 'generalRating') {
      const {
        // Проценты для строк PlaceBar
        averageResultPercentStudent,
        averageResultPercentFirstPlace,
        averageResultPercentSecondPlace,
        averageResultPercentThirdPlace,
        averageResultPercentPenultimatePlace,
        averageResultPercentLastPlace,
        // Место для BarPlace
        placeGeneralRatingStudent,
        placeGeneralRatingPenultimatePlace,
        placeGeneralRatingLastPlace,
      } = chartData as IGeneralRating;

      const placesPercent: IPlaces<number> = {
        1: averageResultPercentFirstPlace,
        2: averageResultPercentSecondPlace,
        3: averageResultPercentThirdPlace,
        [placeGeneralRatingPenultimatePlace]: averageResultPercentPenultimatePlace,
        [placeGeneralRatingLastPlace]: averageResultPercentLastPlace,
      };

      const placesPool = [1, 2, 3, placeGeneralRatingPenultimatePlace, placeGeneralRatingLastPlace];

      placesPool.forEach((place) => {
        const placePercent = placesPercent[place];

        if (isDefined(placePercent)) {
          const condition = defineYoursCondition(place, placeGeneralRatingStudent, placeGeneralRatingPenultimatePlace);
          rows.push(
            createRow({
              placeData: placePercent,
              studentData: averageResultPercentStudent,
              Emoji: avatarPool.pop() || Dog,
              // yours: place === 3 ? yoursThirdPlaceCondition : yoursCondition,
              // place: place === 3 ? placeGeneralRatingStudent : place,
              yours: condition,
              place: condition ? placeGeneralRatingStudent : place,
            }),
          );
        }
      });
    } else {
      const {
        studentPlace, // место учащегося
        // * результаты места в % (для PlaceBar)
        firstPlaceResult, // 1 место
        secondPlaceResult, // 2 место
        thirdPlaceResult, // 3 место
        penultimatePlaceResult, // n-1 место
        lastPlaceResult, // последнее место
        // * кол-во человек на месте
        firstPlaceStudentCount, // 1 место
        secondPlaceStudentCount, // 2 место
        thirdPlaceStudentCount, // 3 место
        penultimatePlaceStudentCount, // 4 место
        lastPlaceStudentCount, // последнее место
        // * общие для resultBar
        diagnosticMaxResult, // * Максимальный результат (для ResultBar)
        studentResult, // * результат студента
        studentResultMarkValue,
        studentLevel,
        // * текстовые уровни
        firstPlaceLevel,
        secondPlaceLevel,
        thirdPlaceLevel,
        penultimatePlaceLevel,
        lastPlaceLevel,
        // * набранные баллы
        firstDiagnosticExecutionResultMarkValue,
        secondDiagnosticExecutionResultMarkValue,
        thirdDiagnosticExecutionResultMarkValue,
        penultimateDiagnosticExecutionResultMarkValue,
        lastDiagnosticExecutionResultMarkValue,
        // * номера последнего и n-1 мест
        penultimatePlaceNumber, // n-1
        lastPlaceNumber,
        studentPlaceStudentsCount, // кол-во человек, которое заняло такое же место, как у ученика
      } = chartData as IIndependentRating;
      const placesPool = [1, 2, 3, penultimatePlaceNumber, lastPlaceNumber];

      const placesPercent: IPlaces<number> = {
        1: firstPlaceResult,
        2: secondPlaceResult,
        3: thirdPlaceResult,
        [penultimatePlaceNumber]: penultimatePlaceResult,
        [lastPlaceNumber]: lastPlaceResult,
      };
      const placesStudentsCount: IPlaces<number> = {
        1: firstPlaceStudentCount,
        2: secondPlaceStudentCount,
        3: thirdPlaceStudentCount,
        [penultimatePlaceNumber]: penultimatePlaceStudentCount,
        [lastPlaceNumber]: lastPlaceStudentCount,
      };

      const placesLevels: IPlaces<string> = {
        1: firstPlaceLevel,
        2: secondPlaceLevel,
        3: thirdPlaceLevel,
        [penultimatePlaceNumber]: penultimatePlaceLevel,
        [lastPlaceNumber]: lastPlaceLevel,
      };

      const placesMarkValues: IPlaces<number> = {
        1: firstDiagnosticExecutionResultMarkValue,
        2: secondDiagnosticExecutionResultMarkValue,
        3: thirdDiagnosticExecutionResultMarkValue,
        [penultimatePlaceNumber]: penultimateDiagnosticExecutionResultMarkValue,
        [lastPlaceNumber]: lastDiagnosticExecutionResultMarkValue,
      };

      placesPool.forEach((place) => {
        const placePercent = placesPercent[place];
        const placeMarkValue = placesMarkValues[place];
        const placeLevel = placesLevels[place];
        const placeStudentsCount = placesStudentsCount[place];

        if (isDefined(placePercent) && isDefined(placeLevel) && isDefined(placeMarkValue)) {
          const condition = defineYoursCondition(place, studentPlace, penultimatePlaceNumber);
          rows.push(
            createRow({
              placeData: placePercent,
              studentData: studentResult,
              Emoji: avatarPool.pop() || Dog,
              yours: condition,
              placeStudentsCount,
              studentPlaceStudentsCount,
              resultBarProps: {
                maxScore: diagnosticMaxResult,
                studentScore: condition ? studentResultMarkValue : placeMarkValue,
                subjectLevel: condition ? studentLevel : placeLevel,
              },
              place: condition ? studentPlace : place,
            }),
          );
        }
      });
    }
    return rows;
  }

  function formChartRows(
    rating: IIndependentDiagnosticGeneralRating | IIndependentDiagnosticRating,
    selectedLocation: DiagnosticRatingRegionCodes,
  ) {
    const ratingMap =
      type === 'generalRating'
        ? createMapFromRating<IGeneralRating>(rating, type)
        : createMapFromRating<IIndependentRating>(rating, type);
    const rows = ratingMap.get(selectedLocation);

    return createChartRows(isDefined(rows) ? rows : ratingMap.get(DiagnosticRatingRegionCodes.Region));
  }

  return { formChartRows };
};
