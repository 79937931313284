/* eslint-disable @typescript-eslint/no-explicit-any */

import { clearSharingCookie } from 'utils';

import { ApiConstructor, ApiRequestMethod, ApiResult } from './types';

export default abstract class BaseApi {
  protected url: string;
  protected prefix: string;

  constructor({ url, prefix }: ApiConstructor) {
    this.url = url;
    this.prefix = prefix;

    this.get = this.get.bind(this);
    this.post = this.post.bind(this);
    this.patch = this.patch.bind(this);
    this.put = this.put.bind(this);
    this.delete = this.delete.bind(this);
  }

  async get<Res = any>(path: string, requestHeaders?: any): Promise<ApiResult<Res, any>> {
    return this._request<undefined, Res>('GET', { path, requestHeaders });
  }

  async post<Req, Res>(path: string, requestBody: Req, requestHeaders?: any): Promise<ApiResult<Res, any>> {
    return this._request<Req, Res>('POST', { path, requestBody, requestHeaders });
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  async patch<Req extends {}, Res>(path: string, requestBody: Req): Promise<ApiResult<Res, any>> {
    const nulledKeys = Object.entries(requestBody)
      .filter(([val]) => val === null || val === '')
      .map(([key]) => key);
    if (nulledKeys.length) path = `${path}${path.includes('?') ? '&' : '?'}clearFields=${nulledKeys}`;
    return this._request<Req, Res>('PATCH', { path, requestBody });
  }

  async put<Req, Res>(path: string, requestBody: Req, requestHeaders?: any): Promise<ApiResult<Res, any>> {
    return this._request<Req, Res>('PUT', { path, requestBody, requestHeaders });
  }

  async delete<Res>(path: string): Promise<ApiResult<Res, any>> {
    return this._request<undefined, Res>('DELETE', { path });
  }

  private _request: ApiRequestMethod = (method, options) => {
    if (!window.location.pathname.includes('shared-link') && document.cookie.includes('SHARE')) {
      clearSharingCookie();
    }

    return this.request(method, options);
  };

  protected abstract request: ApiRequestMethod;
}
