import { FC } from 'react';

import { Typography } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';

const PdfNoDataText: FC = () => {
  return (
    <Typography className="pdf-no-data-text" variant="Paragraph LG/Medium" color={NeutralColors.night_neutrals_500}>
      Данные отсутствуют
    </Typography>
  );
};

export default PdfNoDataText;
