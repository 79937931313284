import { styled } from '@mui/material';
import { CommonUiKitSize, ControlBorderRadius } from 'portfolio3/ui-kit/types';
import { getButtonTokens, getFocusStateStyles } from 'portfolio3/ui-kit/utils';
import { numberToPixelsString } from 'utils';

import { loadingIconClass } from '../classes';

interface IStyledBaseButtonProps {
  size?: CommonUiKitSize;
  borderRadius?: ControlBorderRadius;
}

const StyledBaseButton = styled('button')(({ size, borderRadius = '8px' }: IStyledBaseButtonProps) => {
  const buttonTokens = size ? getButtonTokens(size) : getButtonTokens('medium');
  const { height, fontSize, lineHeight, inlinePadding } = buttonTokens;

  return {
    display: 'grid',
    gridAutoFlow: 'column',

    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,

    width: 'fit-content',
    height: numberToPixelsString(height),
    paddingBlock: 0,
    paddingInline: numberToPixelsString(inlinePadding),
    borderRadius,

    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: numberToPixelsString(fontSize),
    lineHeight: numberToPixelsString(lineHeight),

    transition: 'background-color 0.2s',

    '& svg': {
      width: '20px',
      height: '20px',
    },

    '&:focus-visible': {
      ...getFocusStateStyles(),
    },

    '&:hover': {
      cursor: 'pointer',
    },

    '&:disabled': {
      cursor: 'auto',
    },

    [`& .${loadingIconClass}`]: {
      animation: '0.6s infinite linear loadingAnimation',
    },

    '@keyframes loadingAnimation': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  };
});

export default StyledBaseButton;
