import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconEyeFilled: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M12 10.5c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5 1.5-.673 1.5-1.5-.673-1.5-1.5-1.5Zm0 5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5Zm9.868-3.998c-.639-1.112-4.162-6.686-10.139-6.499-5.527.141-8.743 5.011-9.597 6.499a1.005 1.005 0 0 0 0 .996C2.762 13.595 6.162 19 12.025 19l.246-.003c5.527-.141 8.743-5.011 9.597-6.499a1.005 1.005 0 0 0 0-.996Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconEyeFilled;
