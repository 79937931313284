import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconPersonDelete: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M10 5c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2Zm0 6c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4Zm10.465-3.5.733-.732a1.04 1.04 0 0 0 0-1.466 1.039 1.039 0 0 0-1.465 0L19 6.035l-.732-.733a1.04 1.04 0 0 0-1.466 0 1.042 1.042 0 0 0 0 1.466l.733.732-.733.732a1.042 1.042 0 0 0 0 1.466 1.04 1.04 0 0 0 1.466 0L19 8.965l.733.733a1.039 1.039 0 0 0 1.465 0 1.04 1.04 0 0 0 0-1.466l-.733-.732ZM3 20c0-3.859 3.141-7 7-7s7 3.141 7 7a1 1 0 1 1-2 0c0-2.757-2.243-5-5-5s-5 2.243-5 5a1 1 0 1 1-2 0Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconPersonDelete;
