import { FC } from 'react';

import {
  buttonBaseClasses,
  Checkbox as MaterialCheckbox,
  checkboxClasses,
  CheckboxProps,
  styled,
  svgIconClasses,
} from '@mui/material';
import { IconCheckmark, IconMinus } from 'icons/edit';
import { getAccentColor } from 'portfolio3/styles';
import { Colors } from 'style/variables';
import { SafeOmit, skipPropsForwarding } from 'utils';

import { CheckboxSize } from '../types';
import { getCheckboxTokens, getFocusStateStyles } from '../utils';

interface ICheckboxProps extends SafeOmit<CheckboxProps, 'size'> {
  size?: CheckboxSize;
}

interface IStyledCheckboxProps {
  componentSize?: CheckboxSize;
}

const StyledCheckbox = styled(MaterialCheckbox, {
  shouldForwardProp: skipPropsForwarding<IStyledCheckboxProps>(['componentSize']),
})<IStyledCheckboxProps>(({ theme, componentSize }) => {
  const accentColor = theme.palette.accentColor;
  const accentColor100 = getAccentColor(accentColor, '100');
  const tokens = componentSize ? getCheckboxTokens(componentSize) : getCheckboxTokens('medium');
  const { rootSize } = tokens;

  const iconSize = rootSize - 4;

  return {
    width: rootSize,
    height: rootSize,
    minWidth: rootSize,
    minHeight: rootSize,

    padding: 0,

    borderRadius: 4,
    border: '2px solid',
    borderColor: Colors['control-stroke'],

    '&:hover': {
      borderColor: accentColor100,
    },

    [`&.${checkboxClasses.checked}`]: {
      borderColor: accentColor100,
      backgroundColor: accentColor100,
    },

    [`&.${checkboxClasses.indeterminate}`]: {
      borderColor: '#B0B3C3',
      backgroundColor: '#B0B3C3',
    },

    [`&.${checkboxClasses.disabled}`]: {
      borderColor: Colors['control-stroke'],
      backgroundColor: '#E8E8EF',

      [`& .${svgIconClasses.root}`]: {
        color: '#B0B3C3',
      },
    },

    [`& .${svgIconClasses.root}`]: {
      width: iconSize,
      height: iconSize,
      color: '#ffffff',
    },

    [`&.${buttonBaseClasses.focusVisible}`]: {
      ...getFocusStateStyles(accentColor),
    },
  };
});

const Checkbox: FC<ICheckboxProps> = ({ size, ...props }) => {
  return (
    <StyledCheckbox
      componentSize={size}
      {...props}
      disableRipple
      icon={<></>}
      checkedIcon={<IconCheckmark />}
      indeterminateIcon={<IconMinus />}
    />
  );
};

export default Checkbox;
