import { Dispatch, FC, SetStateAction } from 'react';

import { Box } from '@mui/material';
import { IDictionaryItem } from 'api/types';
import { useAppSelector, useUserFunctionality } from 'hooks';
import { uniqBy } from 'lodash';
import { BaseInputLabel, FormControl, Input } from 'portfolio3/ui-kit';
import { OrganizationAutocomplete, OrganizationAutocompleteOption } from 'portfolio3/ui-kit/autocomplete';
import { SearchSelect, Select } from 'portfolio3/ui-kit/selects';
import { IController } from 'portfolio3/ui-kit/types';
import { isDefined } from 'utils';

import { IStudentSearcherFormData } from '../types';
import * as styles from './styles';

interface IBottomControlsProps {
  formData: IStudentSearcherFormData;
  setFormData: Dispatch<SetStateAction<IStudentSearcherFormData>>;
}

const StudentSearcherBottomControls: FC<IBottomControlsProps> = ({ formData, setFormData }) => {
  const organizations = useAppSelector((state) => state.organizations);
  const parallels = useAppSelector((state) => state.parallels);

  const { isEmployee } = useUserFunctionality();

  const organizationAutocompleteOptions = organizations.content.map(
    (organization): OrganizationAutocompleteOption => ({
      id: organization.globalId,
      fullName: organization.fullName,
      shortName: organization.shortName,
    }),
  );

  const uniqueParallels = uniqBy(parallels.content, 'parallel');
  const parallelOptions = uniqueParallels
    .map((parallel, index): IDictionaryItem | null => {
      if (!parallel.parallel) return null;
      return {
        code: index + 1,
        value: parallel.parallel,
      };
    })
    .filter(isDefined);

  const parallelsLetters = parallels.content.filter((item) => item.parallel === formData.parallelId);
  const classOptions = parallelsLetters
    .map((parallel, index): IDictionaryItem | null => {
      if (!parallel.letter) return null;
      return {
        code: index + 1,
        value: parallel.letter,
      };
    })
    .filter(isDefined);

  const isOrganizationDisabled = isEmployee && Boolean(formData.organization);
  const isParallelDisabled = !formData.organization;
  const isClassDisabled = !formData.parallelId;

  const emailController = {
    handleChange: (email: string) => {
      setFormData({
        ...formData,
        email,
      });
    },
  };

  const organizationController: IController<OrganizationAutocompleteOption | null> = {
    handleChange: (organization) => {
      setFormData({
        ...formData,
        organization,
        parallelId: undefined,
        classId: undefined,
      });
    },
  };

  const parallelController: IController<string | undefined> = {
    handleChange: (parallelId) => {
      const parallelValue = parallelOptions.find((option) => option.code === Number(parallelId))?.value;

      setFormData({
        ...formData,
        parallelId: parallelValue,
        classId: undefined,
      });
    },
    handleClear() {
      setFormData({
        ...formData,
        parallelId: undefined,
        classId: undefined,
      });
    },
  };

  const classController: IController<string | undefined> = {
    handleChange: (classId) => {
      const classValue = classOptions.find((option) => option.code === Number(classId))?.value;

      setFormData({
        ...formData,
        classId: classValue,
      });
    },
    handleClear() {
      setFormData({
        ...formData,
        classId: undefined,
      });
    },
  };

  const parallelRenderValue = (parallel: string | undefined) => {
    const isParallelClass = !Number.isNaN(Number(parallel));
    return isParallelClass ? `${parallel} классы` : parallel;
  };

  const classRenderValue = (classId: string | undefined) => {
    const isParallelClass = !Number.isNaN(Number(formData.parallelId));
    return isParallelClass ? `${formData.parallelId}-${classId}` : classId;
  };

  const inputsRenderMode = 'fixed';
  const inputSize = 'medium';

  return (
    <Box className="bottom-controls" sx={styles.root}>
      <FormControl
        sx={styles.emailControl}
        renderMode={inputsRenderMode}
        inputSize={inputSize}
        label={<BaseInputLabel>E-mail</BaseInputLabel>}
        control={
          <Input
            size={inputSize}
            renderMode={inputsRenderMode}
            controller={emailController}
            placeholder="Введите e-mail..."
            value={formData.email}
          />
        }
      />
      <FormControl
        renderMode={inputsRenderMode}
        inputSize={inputSize}
        disabled={isOrganizationDisabled}
        label={<BaseInputLabel>Образовательная организация</BaseInputLabel>}
        control={
          <OrganizationAutocomplete
            inputSize={inputSize}
            inputRenderMode={inputsRenderMode}
            options={organizationAutocompleteOptions}
            controller={organizationController}
            placeholder="Начните вводить название образовательной организации..."
            value={formData.organization}
          />
        }
      />
      <FormControl
        sx={styles.parallelControl}
        renderMode={inputsRenderMode}
        inputSize={inputSize}
        disabled={isParallelDisabled}
        label={<BaseInputLabel>Параллель (курс)</BaseInputLabel>}
        control={
          <Select
            inputSize={inputSize}
            inputRenderMode={inputsRenderMode}
            options={parallelOptions}
            controller={parallelController}
            placeholder={isParallelDisabled ? 'Сначала выберите ОО' : 'Выберите параллель...'}
            value={formData.parallelId}
            renderValue={parallelRenderValue}
          />
        }
      />
      <FormControl
        sx={styles.parallelControl}
        renderMode={inputsRenderMode}
        inputSize={inputSize}
        disabled={isClassDisabled}
        label={<BaseInputLabel>Класс (группа)</BaseInputLabel>}
        control={
          <SearchSelect
            inputSize={inputSize}
            inputRenderMode={inputsRenderMode}
            options={classOptions}
            controller={classController}
            placeholder={isClassDisabled ? 'Сначала выберите параллель' : 'Выберите класс...'}
            value={formData.classId}
            renderValue={classRenderValue}
          />
        }
      />
    </Box>
  );
};

export default StudentSearcherBottomControls;
