import { IThemeSettings } from 'api/types';

import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from './utils';

export const SAVE_USER_THEME_SETTINGS = createRequestTypes('SAVE_USER_THEME_SETTINGS');
export const saveUserThemeSettingsActions = {
  request: (personId: string, request: IThemeSettings) =>
    action(SAVE_USER_THEME_SETTINGS[REQUEST], { payload: { personId, request } }),
  success: (response: unknown) => action(SAVE_USER_THEME_SETTINGS[SUCCESS], { payload: response }),
  failure: (error: unknown) => action(SAVE_USER_THEME_SETTINGS[FAILURE], { payload: error }),
};

export const SET_USER_THEME_SETTINGS = 'SET_USER_THEME_SETTINGS';
export const setUserThemeSettingsAction = (settings: Partial<IThemeSettings>) =>
  action(SET_USER_THEME_SETTINGS, { payload: settings });

export const SET_USER_THEME_CODE = 'SET_USER_THEME_CODE';
export const setUserThemeCodeAction = (code: number) => action(SET_USER_THEME_CODE, { payload: code });
