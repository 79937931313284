/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

const IconBronzeGto: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="50" height="50" fill="#E5E5E5"/>
    <rect width="1924" height="1397" transform="translate(-1173 -555)" fill="white"/>
    <g clip-path="url(#clip0)">
    <path d="M25 -6V49.8623L28.1815 52L30.8 48.9008L34.5103 50.2572L36.1735 47.1409L43.0653 48.8493L43.7647 42.1359L46.7756 41.5178L46.605 37.6545L50.2726 35.9805L49.0018 32.2975L52.2344 29.8336L50.1362 26.4941L52.8826 23.3777L50.2726 20.9739L54 14.8443L47.8503 12.3117L48.1744 8.68028L44.5068 7.83037L44.0462 3.74393L40.0544 3.80402L38.8091 -0.0763774L35.15 0.842213L32.9238 -2.51451L29.1879 -0.866194L25 -6Z" fill="#F2935F"/>
    <path d="M25 -6V49.8623L21.8185 52L19.2 48.9008L15.4897 50.2572L13.8265 47.1409L6.93471 48.8493L6.23529 42.1359L3.22441 41.5178L3.395 37.6545L-0.264118 35.9805L0.998235 32.2975L-2.23441 29.8336L-0.136177 26.4941L-2.89118 23.3777L-0.264118 20.9739L-4 14.8443L2.14971 12.3117L1.82559 8.68028L5.49323 7.83037L5.95382 3.74393L9.94559 3.80402L11.1909 -0.0763774L14.85 0.842213L17.0762 -2.51451L20.8121 -0.866194L25 -6Z" fill="#F2935F"/>
    <path d="M17.0759 -2.51453L24.0786 21.4289L20.8118 -0.866214L17.0759 -2.51453Z" fill="#BA6445"/>
    <path d="M11.1907 -0.076355L22.9527 25.8159L14.8498 0.842235L11.1907 -0.076355Z" fill="#BA6445"/>
    <path d="M5.95337 3.74396L21.7498 24.4681L9.94513 3.80405L5.95337 3.74396Z" fill="#BA6445"/>
    <path d="M1.82544 8.68029L21.3237 24.468L5.49309 7.83038L1.82544 8.68029Z" fill="#BA6445"/>
    <path d="M-4 14.8443L21.3238 24.468L2.14971 12.3117L-4 14.8443Z" fill="#BA6445"/>
    <path d="M-2.89087 23.3777L21.3241 24.468L-0.26381 20.9739L-2.89087 23.3777Z" fill="#BA6445"/>
    <path d="M-2.23462 29.8336L21.7501 24.468L-0.136384 26.494L-2.23462 29.8336Z" fill="#BA6445"/>
    <path d="M-0.26416 35.9804L22.3388 24.468L0.998193 32.2974L-0.26416 35.9804Z" fill="#BA6445"/>
    <path d="M3.22461 41.5177L22.6461 25.1462L3.3952 37.6545L3.22461 41.5177Z" fill="#BA6445"/>
    <path d="M6.93476 48.8493L23.5501 25.1462L6.23535 42.1358L6.93476 48.8493Z" fill="#BA6445"/>
    <path d="M15.4897 50.2572L24.6588 25.1462L13.8264 47.1408L15.4897 50.2572Z" fill="#BA6445"/>
    <path d="M21.8185 52L24.4114 25.8159L19.2 48.9009L21.8185 52Z" fill="#BA6445"/>
    <path d="M28.1815 52L25 24.3822V49.8624L28.1815 52Z" fill="#BA6445"/>
    <path d="M30.8 48.9008L25 24.3822L34.5103 50.2573L30.8 48.9008Z" fill="#BA6445"/>
    <path d="M43.0653 48.8493L25 24.3822L36.1735 47.1409L43.0653 48.8493Z" fill="#BA6445"/>
    <path d="M46.7756 41.5178L25 24.3822L43.7647 42.1359L46.7756 41.5178Z" fill="#BA6445"/>
    <path d="M50.2726 35.9805L25 24.3822L46.605 37.6545L50.2726 35.9805Z" fill="#BA6445"/>
    <path d="M52.2344 29.8337L25 24.3822L49.0018 32.2975L52.2344 29.8337Z" fill="#BA6445"/>
    <path d="M52.8826 23.3777L25 24.3822L50.1362 26.4941L52.8826 23.3777Z" fill="#BA6445"/>
    <path d="M54 14.8442L25 24.3821L50.2726 20.9739L54 14.8442Z" fill="#BA6445"/>
    <path d="M48.1744 8.68024L25 24.3821L47.8503 12.3117L48.1744 8.68024Z" fill="#BA6445"/>
    <path d="M44.0462 3.74396L25 24.3822L44.5068 7.8304L44.0462 3.74396Z" fill="#BA6445"/>
    <path d="M38.8091 -0.076355L25 24.3822L40.0544 3.80405L38.8091 -0.076355Z" fill="#BA6445"/>
    <path d="M32.9238 -2.51453L25 24.3822L35.15 0.842192L32.9238 -2.51453Z" fill="#BA6445"/>
    <path d="M25 -6V24.3822L29.1879 -0.866195L25 -6Z" fill="#BA6445"/>
    <path d="M47.8931 24.3821C47.8937 27.5717 47.2359 30.7265 45.9615 33.6467C44.687 36.5669 42.8237 39.1888 40.4896 41.346C39.7055 42.0664 38.8734 42.7318 37.999 43.3377C37.3481 43.7945 36.6733 44.2158 35.9775 44.5997C33.3199 46.0688 30.3962 46.9855 27.3799 47.2954C26.5894 47.3787 25.795 47.4188 25.0001 47.4156C21.1949 47.4196 17.4486 46.4696 14.0996 44.6512C13.6475 44.4023 13.1954 44.1361 12.769 43.8614C11.3856 42.9726 10.099 41.9396 8.93074 40.7794C6.24736 38.1121 4.26028 34.8187 3.1461 31.1922C2.03192 27.5657 1.82515 23.7182 2.54419 19.9919C3.26322 16.2655 4.88577 12.7757 7.26763 9.83259C9.64949 6.88946 12.7169 4.5842 16.1971 3.1217C19.6774 1.65921 23.4627 1.08479 27.2167 1.4495C30.9707 1.8142 34.577 3.10674 37.7151 5.21223C40.8532 7.31771 43.4259 10.1709 45.2045 13.5182C46.9831 16.8656 47.9125 20.6033 47.9102 24.3993L47.8931 24.3821Z" fill="#F2935F"/>
    <path d="M37.9988 43.3378C37.3479 43.7945 36.6731 44.2158 35.9773 44.5998C34.5693 45.3723 33.0859 45.9966 31.5506 46.4627C31.5506 46.4627 30.7573 46.7031 29.4779 46.9778C28.5226 47.1838 27.3797 47.2954 27.3797 47.2954C26.5892 47.3787 25.7948 47.4188 25 47.4156C21.1947 47.4196 17.4484 46.4696 14.0994 44.6513C13.6473 44.4023 13.1953 44.1362 12.7688 43.8615L20.5988 33.7054H31.0644L37.9988 43.3378Z" fill="#3F8E0C"/>
    <path d="M41.146 38.5903C41.1462 39.5482 40.9212 40.4925 40.4893 41.3461C39.1211 42.6097 37.6061 43.7023 35.9772 44.5998C35.6947 44.6409 35.4097 44.6609 35.1243 44.6599C33.9295 44.6599 32.7617 44.3032 31.7684 43.6349C30.7751 42.9666 30.0011 42.0169 29.5443 40.9057C29.0875 39.7946 28.9684 38.572 29.2021 37.3927C29.4358 36.2135 30.0118 35.1305 30.8572 34.2808C31.7026 33.4311 32.7794 32.8528 33.9514 32.6193C35.1234 32.3857 36.3378 32.5073 37.4411 32.9687C38.5444 33.43 39.487 34.2104 40.1495 35.2111C40.812 36.2118 41.1648 37.3878 41.1631 38.5903H41.146Z" fill="#F2935F"/>
    <path d="M8.6318 38.5302C8.6316 39.4881 8.85666 40.4324 9.28856 41.286C10.6567 42.5497 12.1718 43.6422 13.8006 44.5397C14.0831 44.5808 14.3682 44.6009 14.6536 44.5998C15.8483 44.5998 17.0162 44.2431 18.0094 43.5748C19.0027 42.9066 19.7767 41.9568 20.2335 40.8457C20.6903 39.7345 20.8094 38.512 20.5757 37.3327C20.342 36.1534 19.766 35.0704 18.9206 34.2207C18.0752 33.371 16.9984 32.7928 15.8264 32.5592C14.6545 32.3257 13.44 32.4473 12.3367 32.9086C11.2334 33.37 10.2908 34.1504 9.62831 35.151C8.96579 36.1517 8.61305 37.3277 8.61474 38.5302H8.6318Z" fill="#F2935F"/>
    <path d="M31.3542 39.9124C31.395 40.1103 31.4763 40.2974 31.5931 40.4618C31.8388 40.8171 32.1828 41.0917 32.5825 41.2516C33.0892 41.4639 33.6388 41.5521 34.186 41.5092C34.4716 41.5008 34.7565 41.475 35.039 41.4319C35.3012 41.3921 35.5605 41.3347 35.8151 41.2602L36.5742 41.0112H36.6339C36.6339 40.9597 36.5742 40.9168 36.5487 40.8739C36.3725 40.6623 36.162 40.4823 35.926 40.3416C35.6815 40.1932 35.4173 40.0805 35.1413 40.0068C34.8133 39.9316 34.4794 39.8857 34.1434 39.8694H32.6763C32.2669 39.8694 31.8234 39.8694 31.4481 39.9381L31.3542 39.9124Z" fill="#BA6445"/>
    <path d="M34.8342 38.8478L34.9451 39.0281C35.0848 39.235 35.2636 39.412 35.4715 39.549C35.6794 39.6861 35.9121 39.7804 36.1563 39.8265C36.3866 39.8735 36.6207 39.8993 36.8557 39.9038C37.0915 39.8911 37.3256 39.8566 37.5551 39.8008C37.7929 39.7492 38.0266 39.6803 38.2545 39.5947C38.4867 39.5093 38.712 39.406 38.9284 39.2857C39.2354 39.114 39.5169 38.9251 39.7813 38.7362H39.8325L39.6619 38.5903C39.2904 38.3165 38.8501 38.153 38.391 38.1181C37.8356 38.086 37.2788 38.1499 36.7448 38.307C36.1136 38.4787 35.491 38.6761 34.8598 38.865L34.8342 38.8478Z" fill="#BA6445"/>
    <path d="M40.5747 15.5225C40.8022 15.2479 40.9577 14.9203 41.0268 14.5696C41.1268 14.0696 41.0824 13.5512 40.8988 13.0758C40.7662 12.7227 40.5946 12.3858 40.3871 12.0714C40.1837 11.7619 39.9494 11.4742 39.6877 11.2129C39.4574 10.9811 39.21 10.7665 38.9712 10.5432L38.8944 10.4832C38.8215 10.6498 38.7727 10.8262 38.7494 11.0068C38.7208 11.3278 38.7351 11.6512 38.7921 11.9684C38.8578 12.337 38.9726 12.6951 39.1333 13.0329C39.5449 13.8889 40.0151 14.715 40.5406 15.5054L40.5747 15.5225Z" fill="#BA6445"/>
    <path d="M39.3977 14.312C39.0989 13.4169 38.5448 12.6301 37.8046 12.05C37.0644 11.4698 36.1708 11.1219 35.2354 11.0497C35.5887 11.9148 36.1377 12.685 36.8389 13.2989C37.5449 13.9323 38.4522 14.2914 39.3977 14.312Z" fill="#BA6445"/>
    <path d="M42.3658 35.7659C42.1812 35.6474 41.9756 35.5658 41.7602 35.5255C41.2218 35.4118 40.6616 35.4687 40.1567 35.6886C39.5624 35.9405 38.9915 36.245 38.4508 36.5986C38.212 36.7446 37.9817 36.8905 37.7258 37.0708L37.8111 37.1309C38.1403 37.4762 38.5898 37.6794 39.065 37.6975C39.6367 37.7197 40.2017 37.5671 40.6855 37.2597C41.2993 36.8943 41.8473 36.427 42.3061 35.8775L42.3658 35.7659Z" fill="#BA6445"/>
    <path d="M44.1484 32.3147L43.6878 32.2546C43.3771 32.227 43.0643 32.2741 42.7751 32.392C42.5855 32.4602 42.4027 32.5465 42.2293 32.6495C41.7648 32.9212 41.34 33.2564 40.9669 33.6454L40.0372 34.5726L39.9519 34.667C40.2181 34.8654 40.5344 34.9844 40.8646 35.0104C41.1773 35.0245 41.4897 34.9747 41.7829 34.8641C42.0761 34.7534 42.344 34.5842 42.5704 34.3665C42.7963 34.165 43.0099 33.95 43.2101 33.7226C43.545 33.3203 43.8313 32.8793 44.0631 32.4091L44.1484 32.3147Z" fill="#BA6445"/>
    <path d="M41.9564 18.9393C42.1014 18.8105 42.2464 18.7075 42.3658 18.5787C42.5299 18.4141 42.6603 18.2187 42.7497 18.0035C42.9045 17.6573 42.9914 17.2841 43.0055 16.9047C43.0192 16.3218 42.9327 15.7409 42.7497 15.1877C42.5979 14.694 42.392 14.2189 42.1355 13.7712C42.1159 13.7442 42.0988 13.7154 42.0844 13.6853L41.982 13.8312C41.6596 14.2904 41.4732 14.8322 41.4447 15.3937C41.4067 15.9282 41.4411 16.4654 41.547 16.9905C41.6664 17.6 41.7944 18.201 41.9223 18.8019L41.9564 18.9393Z" fill="#BA6445"/>
    <path d="M45.1807 28.5888C44.9713 28.5701 44.7602 28.5905 44.5581 28.6489C43.9768 28.8263 43.4624 29.1764 43.0825 29.6534C42.614 30.2079 42.1945 30.8026 41.8287 31.4305L41.5813 31.8425L41.8457 31.9284C42.1814 32.0154 42.5354 31.9975 42.8607 31.8769C43.4274 31.6561 43.9047 31.2512 44.2169 30.7265C44.6435 30.0929 44.9661 29.3943 45.1722 28.6575C45.1722 28.6575 45.1722 28.6575 45.1722 28.5974C45.1722 28.5373 45.1807 28.606 45.1807 28.5888Z" fill="#BA6445"/>
    <path d="M43.9611 17.0678C43.7821 17.1879 43.6284 17.3426 43.5091 17.5228C43.3279 17.7611 43.1815 18.0242 43.0741 18.304C42.9289 18.711 42.8372 19.1352 42.8011 19.566C42.7414 20.3891 42.7414 21.2154 42.8011 22.0385C42.7973 22.0699 42.7973 22.1016 42.8011 22.1329L42.9888 22.0643C43.2779 21.9208 43.5329 21.7162 43.736 21.4642C43.9392 21.2123 44.0858 20.9191 44.1658 20.6048C44.3131 20.0863 44.3679 19.5457 44.3279 19.008C44.2955 18.3894 44.1837 17.7777 43.9952 17.188C43.9803 17.149 43.9689 17.1088 43.9611 17.0678Z" fill="#BA6445"/>
    <path d="M45.0953 20.7851C44.9931 20.8343 44.8935 20.8888 44.7968 20.9482C44.2745 21.3143 43.8804 21.8371 43.6709 22.442C43.4317 23.0861 43.2601 23.7536 43.1592 24.4337C43.108 24.7256 43.0653 25.0175 43.0142 25.2922C43.0178 25.3293 43.0178 25.3667 43.0142 25.4038C43.0823 25.4079 43.1507 25.4079 43.2189 25.4038C43.5693 25.3212 43.8935 25.1514 44.1617 24.9099C44.43 24.6684 44.6337 24.363 44.7542 24.0216C44.9879 23.4482 45.1207 22.8381 45.1465 22.2188C45.1934 21.765 45.1934 21.3076 45.1465 20.8538C45.1347 20.8274 45.1172 20.8039 45.0953 20.7851Z" fill="#BA6445"/>
    <path d="M45.5136 24.7169H45.4027C44.7322 24.9015 44.1617 25.346 43.8162 25.9532C43.4493 26.5578 43.1463 27.1995 42.9121 27.8676L42.6306 28.6488C42.6306 28.6488 42.6306 28.6488 42.6306 28.7089C42.8854 28.7733 43.152 28.7733 43.4068 28.7089C43.7206 28.5968 44.0087 28.4219 44.2535 28.1947C44.4983 27.9674 44.6948 27.6926 44.8312 27.3868C45.0006 27.0686 45.1323 26.7315 45.2236 26.3824C45.3089 26.0218 45.3942 25.6613 45.4709 25.2921C45.4709 25.0689 45.4709 24.8886 45.5136 24.7169Z" fill="#BA6445"/>
    <path d="M34.3057 35.9805C33.6562 36.4352 33.0431 36.9404 32.4719 37.4914C32.0509 37.8931 31.7271 38.3864 31.5251 38.9337C31.4316 39.1809 31.3797 39.442 31.3716 39.7063C31.3716 39.7063 31.3716 39.7579 31.3716 39.7922H31.5251C32.2373 39.586 32.9014 39.2387 33.4783 38.7706C33.851 38.4834 34.1446 38.1051 34.3313 37.6717C34.5417 37.1606 34.5599 36.5899 34.3825 36.0663C34.3588 36.036 34.3332 36.0073 34.3057 35.9805Z" fill="#BA6445"/>
    <path d="M36.5232 34.4094C35.9044 35.1095 35.3849 35.8925 34.9794 36.7359C34.7391 37.2738 34.6562 37.8694 34.7405 38.4529C34.7405 38.5388 34.7405 38.6161 34.7917 38.7019H34.8344C35.5311 38.2973 36.1173 37.7251 36.5402 37.0364C36.7986 36.6261 36.932 36.1486 36.9241 35.6628C36.9205 35.228 36.7965 34.8028 36.5658 34.4352C36.5537 34.4235 36.5392 34.4146 36.5232 34.4094Z" fill="#BA6445"/>
    <path d="M36.1311 15.2735L36.839 15.5826C37.2849 15.7807 37.7473 15.9388 38.2208 16.0547C38.5803 16.1468 38.9545 16.1645 39.321 16.107C39.6875 16.0494 40.0386 15.9178 40.3532 15.7199L40.5238 15.5997L40.3532 15.4796C39.8188 14.9776 39.1723 14.6125 38.4682 14.415C38.1549 14.3279 37.8263 14.312 37.5062 14.3684C37.1861 14.4248 36.8825 14.5522 36.6173 14.7412C36.4267 14.89 36.2624 15.0699 36.1311 15.2735Z" fill="#BA6445"/>
    <path d="M38.2633 32.3661L37.9904 32.9499C37.7169 33.5436 37.5082 34.1654 37.3677 34.8043C37.2278 35.4691 37.2933 36.1611 37.5554 36.7874C37.5729 36.8356 37.5958 36.8817 37.6236 36.9248C38.1759 36.3952 38.5966 35.7424 38.8518 35.0189C38.977 34.6598 39.019 34.2767 38.9747 33.8988C38.9303 33.5209 38.8008 33.1582 38.596 32.8383C38.4943 32.6745 38.3832 32.5169 38.2633 32.3661Z" fill="#BA6445"/>
    <path d="M39.8924 34.6326L40.2677 33.7741C40.3299 33.6392 40.3785 33.4982 40.4127 33.3535C40.4874 33.0712 40.5444 32.7844 40.5833 32.495C40.6647 32.0282 40.6097 31.5478 40.425 31.1119C40.2402 30.676 39.9337 30.3035 39.5427 30.0397L39.5 30.0311C39.4403 30.2886 39.3806 30.529 39.338 30.778C39.2171 31.3652 39.1599 31.9639 39.1674 32.5636C39.1737 33.2419 39.3882 33.9016 39.7815 34.4523L39.8924 34.6326Z" fill="#BA6445"/>
    <path d="M38.0669 17.2223C38.246 17.4026 38.4166 17.5829 38.6042 17.746C38.7919 17.9091 39.2184 18.2783 39.534 18.5186C40.0431 18.9054 40.671 19.0999 41.3081 19.0681C41.4872 19.0681 41.6663 19.0166 41.8625 18.9822C41.8625 18.9822 41.8625 18.9135 41.8625 18.8964L41.5554 18.3813C41.4445 18.2182 41.3251 18.0551 41.2057 17.9091C40.9635 17.6152 40.6755 17.363 40.3528 17.1622C40.0293 16.9374 39.6456 16.817 39.2525 16.817C38.8594 16.817 38.4756 16.9374 38.1522 17.1622L38.0669 17.2223Z" fill="#BA6445"/>
    <path d="M39.3464 19.566L39.6108 19.9866C39.9254 20.4897 40.2944 20.9562 40.7111 21.3774C40.9527 21.6253 41.2406 21.8227 41.5583 21.9582C41.8759 22.0938 42.217 22.1648 42.562 22.1672H42.6814C42.6814 22.1672 42.6814 22.1672 42.6814 22.1157C42.4837 21.3022 42.0711 20.5577 41.4873 19.9609C41.2243 19.6915 40.8876 19.5069 40.5201 19.4305C40.1526 19.354 39.7708 19.3893 39.4232 19.5316C39.4232 19.5316 39.372 19.566 39.3464 19.566Z" fill="#BA6445"/>
    <path d="M40.1997 27.4556C40.1997 27.5501 40.1997 27.6445 40.1997 27.7303C40.1522 28.495 40.2039 29.2627 40.3532 30.0139C40.5004 30.6808 40.8561 31.2826 41.3682 31.7309L41.4876 31.8168C41.4876 31.7224 41.5473 31.6451 41.5644 31.5592C41.7393 30.9198 41.8 30.2541 41.7435 29.5933C41.7235 29.1383 41.5738 28.6988 41.3121 28.3273C41.0504 27.9558 40.6879 27.6681 40.2679 27.4986L40.1997 27.4556Z" fill="#BA6445"/>
    <path d="M40.4213 24.7771C40.5171 25.5407 40.7033 26.29 40.9757 27.0092C41.124 27.3939 41.344 27.7464 41.6239 28.0479C41.8493 28.2994 42.1218 28.5036 42.4257 28.6489H42.4769V28.4514C42.5493 27.7054 42.459 26.9524 42.2125 26.2451C42.0942 25.848 41.8633 25.4944 41.5481 25.2275C41.233 24.9606 40.8473 24.7921 40.4383 24.7427H40.3616L40.4213 24.7771Z" fill="#BA6445"/>
    <path d="M42.9116 25.4209C42.9116 25.1977 42.8604 24.9917 42.8263 24.7856C42.7327 24.2111 42.5361 23.6584 42.2463 23.1545C42.0583 22.7811 41.7591 22.4762 41.3906 22.2822C41.0221 22.0883 40.6026 22.0151 40.1907 22.0728L40.293 22.3647C40.5034 22.9983 40.7898 23.6037 41.146 24.1675C41.3367 24.4736 41.5855 24.7389 41.8783 24.9481C42.171 25.1573 42.5018 25.3063 42.8518 25.3866L42.9116 25.4209Z" fill="#BA6445"/>
    <path d="M18.6457 39.9124C18.5976 40.1078 18.5168 40.2936 18.4068 40.4618C18.16 40.8195 17.8124 41.0945 17.4089 41.2516C16.9054 41.4647 16.3584 41.5531 15.8139 41.5092C15.5283 41.5008 15.2435 41.475 14.961 41.4319C14.6961 41.3911 14.434 41.3338 14.1763 41.2602L13.4257 41.0284H13.366L13.4598 40.891C13.6359 40.6794 13.8464 40.4994 14.0824 40.3588C14.327 40.2104 14.5912 40.0977 14.8671 40.024C15.1951 39.9488 15.5291 39.9028 15.8651 39.8866H17.3236C17.733 39.8866 18.1766 39.8866 18.5433 39.9553L18.6457 39.9124Z" fill="#BA6445"/>
    <path d="M15.1657 38.8478L15.0548 39.0281C14.7714 39.4445 14.3362 39.7314 13.8436 39.8265C13.6105 39.8738 13.3735 39.8997 13.1357 39.9037C12.9028 39.8904 12.6716 39.856 12.4448 39.8007C12.2042 39.7492 11.9676 39.6804 11.7369 39.5947C11.5054 39.5076 11.2802 39.4043 11.0631 39.2856C10.7645 39.1139 10.4745 38.925 10.2101 38.7362H10.1589L10.3295 38.5902C10.7004 38.3152 11.141 38.1515 11.6004 38.1181C12.153 38.0864 12.7069 38.1503 13.2381 38.3069C13.8692 38.4786 14.5004 38.6761 15.1231 38.8649L15.1657 38.8478Z" fill="#BA6445"/>
    <path d="M9.4254 15.5225C9.19545 15.2484 9.03714 14.9209 8.96481 14.5696C8.87383 14.0691 8.91801 13.5532 9.09275 13.0758C9.23562 12.7192 9.42184 12.3818 9.64716 12.0714C9.85052 11.7619 10.0849 11.4742 10.3466 11.2129C10.5769 10.9811 10.8242 10.7665 11.063 10.5432C11.063 10.5432 11.1057 10.5432 11.1313 10.4832C11.212 10.647 11.2612 10.8246 11.2763 11.0068C11.319 11.3259 11.319 11.6493 11.2763 11.9684C11.2026 12.335 11.0882 12.6921 10.9351 13.0329C10.5238 13.8908 10.0506 14.7172 9.51922 15.5054L9.4254 15.5225Z" fill="#BA6445"/>
    <path d="M10.6023 14.312C10.9029 13.4099 11.4632 12.6181 12.2119 12.0372C12.9607 11.4564 13.864 11.1126 14.8073 11.0497C14.4481 11.9115 13.8999 12.6805 13.2038 13.2989C12.4859 13.9405 11.5623 14.3002 10.6023 14.312Z" fill="#BA6445"/>
    <path d="M7.62549 35.7659C7.81015 35.6474 8.01576 35.5657 8.23108 35.5255C8.7723 35.4116 9.33526 35.4686 9.84314 35.6886C10.4374 35.9404 11.0083 36.245 11.549 36.5986C11.7793 36.7446 12.0181 36.8905 12.274 37.0708C12.2415 37.0888 12.2102 37.1089 12.1802 37.1309C11.8547 37.4764 11.4077 37.6798 10.9349 37.6975C10.3605 37.7198 9.7927 37.5672 9.30578 37.2597C8.69198 36.8942 8.14405 36.4269 7.6852 35.8775L7.62549 35.7659Z" fill="#BA6445"/>
    <path d="M5.84302 32.3147L6.3036 32.2546C6.61712 32.2269 6.93269 32.274 7.22478 32.3919C7.41374 32.4619 7.59637 32.5481 7.77066 32.6495C8.23362 32.9234 8.65816 33.2583 9.03302 33.6453L9.96272 34.5725L10.0395 34.667C9.7773 34.8667 9.46333 34.986 9.13537 35.0104C8.82257 35.0245 8.51018 34.9747 8.21701 34.864C7.92383 34.7534 7.65595 34.5841 7.42949 34.3665C7.20159 34.1671 6.98784 33.952 6.78978 33.7226C6.45492 33.3202 6.16861 32.8793 5.93684 32.4091L5.84302 32.3147Z" fill="#BA6445"/>
    <path d="M8.04365 18.9393C7.89865 18.8105 7.75365 18.7075 7.62571 18.5787C7.46437 18.4118 7.33434 18.217 7.24189 18.0035C7.09103 17.6559 7.00433 17.2835 6.986 16.9047C6.97505 16.3213 7.06451 15.7404 7.25042 15.1877C7.40213 14.694 7.60811 14.2189 7.86453 13.7712L7.91571 13.6853L8.01806 13.8312C8.33681 14.2923 8.52277 14.833 8.55542 15.3937C8.59062 15.9286 8.55331 16.4658 8.44453 16.9905C8.33365 17.6 8.19718 18.201 8.07777 18.8019L8.04365 18.9393Z" fill="#BA6445"/>
    <path d="M4.81958 28.5888C5.02904 28.57 5.24013 28.5904 5.44223 28.6489C6.02349 28.8262 6.53787 29.1764 6.91781 29.6533C7.38352 30.2081 7.8002 30.8027 8.16311 31.4304C8.2484 31.5592 8.32517 31.688 8.41046 31.8425L8.15458 31.9283C7.8189 32.0154 7.46486 31.9974 7.13958 31.8768C6.57153 31.6583 6.09358 31.2529 5.7834 30.7264C5.35002 30.0967 5.02693 29.3969 4.82811 28.6575C4.82811 28.6575 4.82811 28.6575 4.82811 28.5974C4.82502 28.5948 4.82217 28.5919 4.81958 28.5888Z" fill="#BA6445"/>
    <path d="M6.03885 17.0678C6.21407 17.1926 6.36694 17.3465 6.49091 17.5228C6.66917 17.763 6.81541 18.0256 6.92591 18.304C7.06406 18.7125 7.15284 19.1362 7.19032 19.566C7.25001 20.3891 7.25001 21.2154 7.19032 22.0385C7.19032 22.0385 7.19032 22.0986 7.19032 22.1329L6.99415 22.0643C6.70693 21.9181 6.45362 21.7125 6.25078 21.461C6.04794 21.2095 5.90015 20.9178 5.81709 20.6048C5.67253 20.0859 5.62055 19.5452 5.66356 19.008C5.69724 18.3903 5.80609 17.779 5.98768 17.188L6.03885 17.0678Z" fill="#BA6445"/>
    <path d="M4.90466 20.7851L5.19466 20.9482C5.71976 21.3134 6.1168 21.8362 6.32908 22.442C6.5644 23.0873 6.73582 23.7545 6.84084 24.4337C6.89202 24.7256 6.93467 25.0175 6.97731 25.2922C6.97731 25.2922 6.97731 25.3523 6.97731 25.4038C6.90916 25.4085 6.84076 25.4085 6.77261 25.4038C6.42219 25.3212 6.09799 25.1514 5.82975 24.9099C5.5615 24.6684 5.35779 24.363 5.23731 24.0216C5.00356 23.4482 4.87079 22.8381 4.84496 22.2188C4.80233 21.7648 4.80233 21.3078 4.84496 20.8538C4.86181 20.8284 4.88188 20.8053 4.90466 20.7851Z" fill="#BA6445"/>
    <path d="M4.52948 24.7169H4.64036C5.31001 24.9032 5.87991 25.3473 6.22683 25.9532C6.57899 26.5596 6.8677 27.2012 7.0883 27.8676C7.18213 28.1251 7.27595 28.3913 7.36124 28.6488C7.36233 28.6688 7.36233 28.6889 7.36124 28.7089C7.10358 28.7733 6.8342 28.7733 6.57654 28.7089C6.26473 28.595 5.97879 28.4193 5.7356 28.1922C5.49241 27.9651 5.29691 27.6913 5.16066 27.3868C4.98765 27.0703 4.85578 26.7328 4.7683 26.3824C4.67448 26.0218 4.59771 25.6613 4.52095 25.2921C4.51376 25.1004 4.51661 24.9084 4.52948 24.7169Z" fill="#BA6445"/>
    <path d="M15.6943 35.9805H15.737C16.3865 36.4352 16.9996 36.9404 17.5708 37.4914C17.9917 37.8931 18.3156 38.3864 18.5175 38.9337C18.6083 39.1813 18.6573 39.4425 18.6625 39.7063V39.7922H18.5005C17.789 39.5841 17.1253 39.237 16.5473 38.7706C16.1746 38.4834 15.881 38.1051 15.6943 37.6717C15.4902 37.1682 15.4721 36.6079 15.6431 36.0921C15.651 36.0513 15.6686 36.013 15.6943 35.9805Z" fill="#BA6445"/>
    <path d="M13.4681 34.4094C14.0898 35.1073 14.6096 35.8907 15.012 36.7359C15.2551 37.2729 15.341 37.8686 15.2593 38.4529C15.2593 38.5388 15.2593 38.6161 15.2081 38.7019H15.1655C14.4687 38.2973 13.8826 37.7251 13.4596 37.0364C13.1987 36.6268 13.0624 36.1494 13.0673 35.6628C13.0738 35.2273 13.2008 34.8021 13.434 34.4352L13.4681 34.4094Z" fill="#BA6445"/>
    <path d="M13.9118 15.2736C13.6645 15.3852 13.4342 15.4882 13.1954 15.5827C12.753 15.7821 12.2933 15.9401 11.8221 16.0548C11.4563 16.1535 11.0741 16.1746 10.6997 16.117C10.3254 16.0594 9.96695 15.9242 9.64713 15.72L9.46802 15.5998L9.58743 15.4796C10.1382 14.9684 10.8064 14.6026 11.5321 14.4151C11.844 14.3281 12.1712 14.3122 12.4899 14.3687C12.8087 14.4251 13.1109 14.5524 13.3745 14.7413C13.5852 14.8833 13.7675 15.0638 13.9118 15.2736Z" fill="#BA6445"/>
    <path d="M11.7368 32.3661C11.8391 32.5808 11.9244 32.7611 12.0012 32.9499C12.2783 33.5421 12.4872 34.1643 12.6238 34.8043C12.7709 35.468 12.7083 36.1613 12.4447 36.7874C12.4272 36.8356 12.4043 36.8817 12.3765 36.9248C11.8188 36.3981 11.3947 35.7445 11.1397 35.0189C11.0146 34.6598 10.9725 34.2767 11.0169 33.8988C11.0612 33.5209 11.1908 33.1582 11.3956 32.8383C11.498 32.6495 11.6174 32.5207 11.7368 32.3661Z" fill="#BA6445"/>
    <path d="M10.1074 34.6326C9.97095 34.3322 9.84301 34.066 9.7236 33.7741C9.66796 33.6375 9.62234 33.4969 9.58713 33.3535C9.50819 33.0722 9.45115 32.7852 9.41654 32.495C9.33316 32.0291 9.38635 31.5488 9.56962 31.1128C9.75289 30.6768 10.0583 30.3039 10.4486 30.0397L10.5254 29.9968C10.5765 30.2543 10.6362 30.4947 10.6874 30.7437C10.8083 31.3309 10.8655 31.9296 10.858 32.5293C10.8474 33.2069 10.6333 33.8653 10.2439 34.418L10.1074 34.6326Z" fill="#BA6445"/>
    <path d="M11.9328 17.2223C11.7537 17.4026 11.5831 17.5829 11.3954 17.746C11.2078 17.9091 10.7813 18.2783 10.4572 18.5186C9.95173 18.9062 9.32617 19.1008 8.6916 19.0681C8.50396 19.0681 8.32484 19.0166 8.12866 18.9822V18.8964C8.23102 18.7247 8.32484 18.553 8.43572 18.3813C8.54261 18.2169 8.6594 18.0592 8.78543 17.9091C9.02799 17.6123 9.31938 17.3597 9.6469 17.1622C9.97034 16.9374 10.3541 16.817 10.7472 16.817C11.1403 16.817 11.524 16.9374 11.8475 17.1622L11.9243 17.2223H11.9328Z" fill="#BA6445"/>
    <path d="M10.6451 19.566C10.5598 19.7119 10.4745 19.8493 10.3806 19.9866C10.0715 20.4936 9.702 20.9606 9.28035 21.3774C8.79446 21.876 8.13183 22.16 7.438 22.1672H7.31006V22.1157C7.51451 21.303 7.92951 20.5594 8.51271 19.9609C8.77578 19.6915 9.11243 19.5069 9.47994 19.4305C9.84745 19.354 10.2292 19.3893 10.5768 19.5316L10.6451 19.566Z" fill="#BA6445"/>
    <path d="M9.8006 27.4556C9.8006 27.5501 9.8006 27.6445 9.8006 27.7303C9.85227 28.495 9.80062 29.2632 9.64707 30.0139C9.50334 30.6822 9.14699 31.285 8.63207 31.7309L8.51266 31.8168C8.51266 31.7224 8.46148 31.6451 8.44442 31.5592C8.26659 30.9203 8.20306 30.2546 8.25677 29.5933C8.27638 29.1371 8.42701 28.6964 8.69043 28.3246C8.95385 27.9528 9.31874 27.6658 9.74089 27.4986L9.8006 27.4556Z" fill="#BA6445"/>
    <path d="M9.5789 24.7771C9.48304 25.5407 9.29692 26.29 9.02449 27.0092C8.87312 27.3923 8.65343 27.7443 8.37626 28.0479C8.15085 28.2994 7.8784 28.5036 7.57449 28.6489H7.51478C7.51019 28.5832 7.51019 28.5172 7.51478 28.4514C7.43899 27.7054 7.52932 26.9516 7.7792 26.2451C7.89553 25.8469 8.12584 25.4921 8.44137 25.2249C8.75689 24.9577 9.14365 24.79 9.55331 24.7427H9.63008L9.5789 24.7771Z" fill="#BA6445"/>
    <path d="M7.08862 25.4209C7.08862 25.1977 7.1398 24.9917 7.17392 24.7856C7.26752 24.2111 7.46404 23.6584 7.75392 23.1545C7.9404 22.7818 8.23801 22.4772 8.60508 22.2832C8.97215 22.0892 9.3903 22.0157 9.80098 22.0728L9.69862 22.3647C9.48827 22.9983 9.20184 23.6038 8.84568 24.1675C8.45708 24.7825 7.8448 25.2201 7.1398 25.3866L7.08862 25.4209Z" fill="#BA6445"/>
    <path d="M24.9996 37.1566C32.0091 37.1566 37.6914 31.4373 37.6914 24.3822C37.6914 17.3271 32.0091 11.6078 24.9996 11.6078C17.9902 11.6078 12.3079 17.3271 12.3079 24.3822C12.3079 31.4373 17.9902 37.1566 24.9996 37.1566Z" fill="#3F8E0C"/>
    <path d="M24.9998 19.2313C23.6199 19.2313 22.2965 19.783 21.3208 20.7651C20.345 21.7472 19.7969 23.0792 19.7969 24.4681H30.2028C30.2028 23.0792 29.6546 21.7472 28.6788 20.7651C27.7031 19.783 26.3797 19.2313 24.9998 19.2313Z" fill="#F2935F"/>
    <path d="M33.6866 13.7529L24.8596 24.3522L25.1538 24.6004L33.9807 14.0011L33.6866 13.7529Z" fill="#F2935F"/>
    <path d="M11.5051 21.8785L11.4385 22.2589L24.9706 24.6605L25.0373 24.2801L11.5051 21.8785Z" fill="#F2935F"/>
    <path d="M12.1591 19.5538L12.0278 19.9168L24.94 24.6471L25.0713 24.2841L12.1591 19.5538Z" fill="#F2935F"/>
    <path d="M13.1946 17.3903L13.0027 17.7249L24.9026 24.6401L25.0946 24.3055L13.1946 17.3903Z" fill="#F2935F"/>
    <path d="M24.872 24.6141L14.3552 15.7286L14.6026 15.4282L25.1279 24.3222L24.872 24.6141Z" fill="#F2935F"/>
    <path d="M16.3182 13.7502L16.0242 13.9985L24.8566 24.5932L25.1507 24.3449L16.3182 13.7502Z" fill="#F2935F"/>
    <path d="M18.2994 12.3972L17.967 12.5904L24.8375 24.5678L25.1699 24.3747L18.2994 12.3972Z" fill="#F2935F"/>
    <path d="M20.481 11.4132L20.1204 11.5453L24.82 24.5416L25.1807 24.4095L20.481 11.4132Z" fill="#F2935F"/>
    <path d="M22.8077 10.8224L22.4297 10.8895L24.8158 24.5098L25.1938 24.4427L22.8077 10.8224Z" fill="#F2935F"/>
    <path d="M25.1875 10.6377H24.8037V24.4681H25.1875V10.6377Z" fill="#F2935F"/>
    <path d="M27.2032 10.8186L24.803 24.4363L25.1809 24.5038L27.5812 10.8861L27.2032 10.8186Z" fill="#F2935F"/>
    <path d="M29.515 11.4043L24.8289 24.4056L25.1897 24.5373L29.8758 11.5361L29.515 11.4043Z" fill="#F2935F"/>
    <path d="M31.7044 12.3962L24.834 24.3737L25.1664 24.5668L32.0368 12.5894L31.7044 12.3962Z" fill="#F2935F"/>
    <path d="M25.128 24.6141L24.8721 24.3222L35.3974 15.4282L35.6447 15.7286L25.128 24.6141Z" fill="#F2935F"/>
    <path d="M36.806 17.3943L24.906 24.3095L25.0979 24.6441L36.9979 17.7289L36.806 17.3943Z" fill="#F2935F"/>
    <path d="M37.7946 19.6479L24.8782 24.3502L25.0087 24.7134L37.9251 20.011L37.7946 19.6479Z" fill="#F2935F"/>
    <path d="M38.504 21.8838L24.9702 24.2759L25.0366 24.6564L38.5704 22.2643L38.504 21.8838Z" fill="#F2935F"/>
    <path d="M25 36.65C21.8002 36.646 18.7297 35.3783 16.4488 33.1195C14.168 30.8607 12.8588 27.7912 12.803 24.571H37.1971C37.1434 27.7919 35.835 30.8627 33.5536 33.1219C31.2723 35.3812 28.2005 36.6482 25 36.65Z" fill="#F2935F"/>
    <path d="M37.0006 24.7685C36.8836 27.8933 35.568 30.8508 33.3302 33.0198C31.0924 35.1889 28.1065 36.4007 24.9998 36.4007C21.893 36.4007 18.9072 35.1889 16.6694 33.0198C14.4315 30.8508 13.1159 27.8933 12.9989 24.7685H37.0006ZM37.3845 24.3822H12.6492C12.8156 27.5706 14.1912 30.5732 16.4923 32.7708C18.7935 34.9684 21.8447 36.1935 25.0168 36.1935C28.189 36.1935 31.2402 34.9684 33.5413 32.7708C35.8424 30.5732 37.218 27.5706 37.3845 24.3822Z" fill="#BA6445"/>
    <path d="M22.3554 27.4194V28.2944H20.2567V32.4165H19.1971V27.4194H22.3554ZM25.0555 32.4165H23.996V28.3013H22.639V27.4194H26.4125V28.3013H25.0555V32.4165ZM31.7172 29.9111C31.7172 30.7383 31.5121 31.374 31.1019 31.8184C30.6918 32.2627 30.1039 32.4849 29.3383 32.4849C28.5726 32.4849 27.9847 32.2627 27.5746 31.8184C27.1644 31.374 26.9594 30.736 26.9594 29.9043C26.9594 29.0726 27.1644 28.438 27.5746 28.0005C27.987 27.5607 28.5772 27.3408 29.3451 27.3408C30.113 27.3408 30.6998 27.5618 31.1054 28.0039C31.5132 28.446 31.7172 29.0817 31.7172 29.9111ZM28.0702 29.9111C28.0702 30.4694 28.1762 30.8898 28.3881 31.1724C28.6 31.4549 28.9167 31.5962 29.3383 31.5962C30.1836 31.5962 30.6063 31.0345 30.6063 29.9111C30.6063 28.7855 30.1859 28.2227 29.3451 28.2227C28.9235 28.2227 28.6057 28.3651 28.3915 28.6499C28.1773 28.9325 28.0702 29.3529 28.0702 29.9111Z" fill="#3F8E0C"/>
    <path d="M27.32 39.8179C27.3411 40.2606 27.1913 40.6943 26.902 41.0284C26.5953 41.3736 26.185 41.6087 25.7335 41.698C26.2293 41.7323 26.7014 41.9247 27.0812 42.2474C27.2389 42.3929 27.3633 42.5711 27.4457 42.7697C27.5282 42.9684 27.5668 43.1827 27.5588 43.3978C27.5738 43.7159 27.5117 44.0329 27.378 44.3216C27.2443 44.6102 27.0429 44.8618 26.7912 45.0547C26.1478 45.4884 25.3804 45.6965 24.6076 45.6471C23.8659 45.6699 23.1278 45.5353 22.4412 45.2522V43.9215C22.7395 44.0747 23.0543 44.1928 23.3794 44.2735C23.7071 44.3654 24.0457 44.4116 24.3859 44.4109C24.7809 44.4392 25.1756 44.3496 25.5203 44.1533C25.6477 44.0523 25.748 43.9209 25.812 43.771C25.8761 43.621 25.9019 43.4573 25.887 43.2948C25.8972 43.1452 25.8629 42.9958 25.7885 42.8659C25.7141 42.7359 25.6029 42.6312 25.4691 42.5651C25.0492 42.3976 24.598 42.3244 24.147 42.3505H23.5926V41.1572H24.147C24.574 41.1849 25.0012 41.1083 25.3923 40.9339C25.5241 40.8561 25.631 40.7421 25.7005 40.6052C25.77 40.4683 25.7992 40.3143 25.7847 40.1613C25.7847 39.6033 25.4435 39.3028 24.7441 39.3028C24.4946 39.3014 24.2467 39.342 24.0106 39.423C23.7067 39.5189 23.4191 39.6607 23.1576 39.8437L22.4412 38.7877C23.1406 38.2974 23.9773 38.0447 24.8294 38.0666C25.4622 38.0326 26.0903 38.1944 26.6291 38.5302C26.8443 38.6671 27.021 38.8575 27.142 39.083C27.263 39.3086 27.3243 39.5616 27.32 39.8179Z" fill="#F4A382"/>
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="-4" y="-6" width="58" height="58">
    <path d="M25 -6V49.8623L28.1815 52L30.8 48.9008L34.5103 50.2572L36.1735 47.1409L43.0653 48.8493L43.7647 42.1359L46.7756 41.5178L46.605 37.6545L50.2726 35.9805L49.0018 32.2975L52.2344 29.8336L50.1362 26.4941L52.8826 23.3777L50.2726 20.9739L54 14.8443L47.8503 12.3117L48.1744 8.68028L44.5068 7.83037L44.0462 3.74393L40.0544 3.80402L38.8091 -0.0763774L35.15 0.842213L32.9238 -2.51451L29.1879 -0.866194L25 -6Z" fill="#CCCCCC"/>
    <path d="M25 -6V49.8623L21.8185 52L19.2 48.9008L15.4897 50.2572L13.8265 47.1409L6.93471 48.8493L6.23529 42.1359L3.22441 41.5178L3.395 37.6545L-0.264118 35.9805L0.998235 32.2975L-2.23441 29.8336L-0.136177 26.4941L-2.89118 23.3777L-0.264118 20.9739L-4 14.8443L2.14971 12.3117L1.82559 8.68028L5.49323 7.83037L5.95382 3.74393L9.94559 3.80402L11.1909 -0.0763774L14.85 0.842213L17.0762 -2.51451L20.8121 -0.866194L25 -6Z" fill="#CCCCCC"/>
    </mask>
    <g mask="url(#mask0)">
    <path opacity="0.5" d="M8.73386 27.8854C29.364 27.8854 46.0881 11.1064 46.0881 -9.5915C46.0881 -30.2894 29.364 -47.0684 8.73386 -47.0684C-11.8963 -47.0684 -28.6204 -30.2894 -28.6204 -9.5915C-28.6204 11.1064 -11.8963 27.8854 8.73386 27.8854Z" fill="url(#paint0_linear)" fill-opacity="0.6"/>
    </g>
    </g>
    <rect x="-1108.5" y="-30.5" width="1768" height="796" rx="4.5" stroke="#7B61FF" strokeDasharray="10 5"/>
    <defs>
    <linearGradient id="paint0_linear" x1="-4.392" y1="-34.2787" x2="24.6758" y2="20.0209" gradientUnits="userSpaceOnUse">
    <stop stopColor="white" stopOpacity="0"/>
    <stop offset="0.35" stopColor="white" stopOpacity="0.01"/>
    <stop offset="0.49" stopColor="white" stopOpacity="0.03"/>
    <stop offset="0.59" stopColor="white" stopOpacity="0.08"/>
    <stop offset="0.67" stopColor="white" stopOpacity="0.15"/>
    <stop offset="0.74" stopColor="white" stopOpacity="0.24"/>
    <stop offset="0.8" stopColor="white" stopOpacity="0.35"/>
    <stop offset="0.85" stopColor="white" stopOpacity="0.47"/>
    <stop offset="0.9" stopColor="white" stopOpacity="0.62"/>
    <stop offset="0.95" stopColor="white" stopOpacity="0.79"/>
    <stop offset="1" stopColor="white"/>
    </linearGradient>
    <clipPath id="clip0">
    <rect width="50" height="50" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);

export default IconBronzeGto;
