import { IDictionaryItem } from 'api/types';
import { DIAGNOSTIC_PERIOD_ALL_CODE, DIAGNOSTIC_PERIOD_ALL_TYPE_CODE } from 'const';
import { IRootState } from 'reducers';
import { createSelector } from 'reselect';

const independentDiagnosticRatingPeriodsSelector = createSelector(
  [(state: IRootState) => state.independentDiagnosticsGeneralRating],
  (independentDiagnosticsGeneralRating): IDictionaryItem[] => {
    const dictionaryPeriods =
      independentDiagnosticsGeneralRating.content?.map((group, index): IDictionaryItem => {
        return {
          code: index + 1,
          value: group.learningYear,
        };
      }) || [];

    dictionaryPeriods.sort((left, right) => {
      return Number(right.value.split('-')[0]) - Number(left.value.split('-')[0]);
    });

    const hasLearningYearAll = independentDiagnosticsGeneralRating.content?.some(
      (ratingObject) => ratingObject.learningYear === DIAGNOSTIC_PERIOD_ALL_TYPE_CODE,
    );

    if (hasLearningYearAll) {
      dictionaryPeriods.unshift({
        code: DIAGNOSTIC_PERIOD_ALL_CODE,
        value: 'Весь период',
      });
    }

    return dictionaryPeriods;
  },
);

export default independentDiagnosticRatingPeriodsSelector;
