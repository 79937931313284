/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const IconManual: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.18798 18.5405H14.0226C15.6538 18.5405 16.975 17.2193 16.975 15.5881V5.3653C16.975 4.34672 16.1483 3.52004 15.1297 3.52004C15.0043 3.52004 14.8788 3.5348 14.7607 3.55694V3.35027C14.7607 2.33168 13.934 1.50501 12.9154 1.50501C12.6718 1.50501 12.4356 1.54929 12.2216 1.63786C11.8894 1.15071 11.3359 0.833328 10.7011 0.833328C9.7268 0.833328 8.92964 1.59358 8.86321 2.55312C8.73773 2.53097 8.61225 2.51621 8.48678 2.51621C7.46819 2.51621 6.64151 3.34289 6.64151 4.36148V11.4104L4.86267 10.4656C4.32386 10.1778 3.65218 10.2589 3.19455 10.6649L1.66667 12.0156L7.05485 17.6326C7.60843 18.2084 8.39082 18.5405 9.18798 18.5405ZM8.11773 16.6067L3.79242 12.1042L4.16886 11.7721L8.11773 13.8609V4.3615C8.11773 4.16221 8.28011 3.99245 8.48678 3.99245C8.69345 3.99245 8.85584 4.15483 8.85584 4.3615V9.68325H10.332V2.67862C10.332 2.47195 10.4944 2.30957 10.7011 2.30957C10.9078 2.30957 11.0702 2.47195 11.0702 2.67862V9.68325H12.5464V3.3503C12.5464 3.14363 12.7088 2.98124 12.9154 2.98124C13.1221 2.98124 13.2845 3.14363 13.2845 3.3503V9.68325H14.7607V5.36533C14.7607 5.15866 14.9231 4.99627 15.1297 4.99627C15.3364 4.99627 15.4988 5.15866 15.4988 5.36533V15.5881C15.4988 16.4 14.8345 17.0643 14.0226 17.0643H9.1806C8.78203 17.0643 8.39821 16.8946 8.11773 16.6067Z" fill="#B0B3C3"/>
  </SvgIcon>
);

export default IconManual;
