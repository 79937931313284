import { CommonUiKitSize } from 'portfolio3/ui-kit/types';

import { IToggleTokens } from '../types';

export const ToggleWidth: Record<CommonUiKitSize, number> = {
  small: 32,
  medium: 40,
  large: 52,
};

export const ToggleHeight: Record<CommonUiKitSize, number> = {
  small: 20,
  medium: 24,
  large: 32,
};

export const TogglePadding: Record<CommonUiKitSize, number> = {
  small: 2,
  medium: 2,
  large: 2,
};

export const getToggleTokens = (size: CommonUiKitSize): IToggleTokens => {
  return {
    width: ToggleWidth[size],
    height: ToggleHeight[size],
    padding: TogglePadding[size],
  };
};
