import { IDictionaryItem } from 'api/types';
import { IRootState } from 'reducers';
import { createSelector } from 'reselect';

const flatSpecialitiesSelector = createSelector(
  [(state: IRootState) => state.specialitiesRef],
  (specialitiesRef): IDictionaryItem[] => {
    const specialities = specialitiesRef.content ?? [];

    // плоский список специальностей
    const flatSpecialities = specialities.flatMap((topSpeciality) => {
      return topSpeciality.child.flatMap((middleSpeciality) => {
        return middleSpeciality.child;
      });
    });

    // сортировка по коду
    const sortedSpecialities = flatSpecialities.sort((a, b) => a.number.localeCompare(b.number));

    const dictionarySpecialities = sortedSpecialities.map((speciality): IDictionaryItem => {
      const { id, number, name } = speciality;
      return {
        code: id,
        value: `${number} ${name}`,
      };
    });

    return dictionarySpecialities;
  },
);

export default flatSpecialitiesSelector;
