/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useState, useEffect } from 'react';

import { IDiagnosisBarData } from '../../../api/types';
import { Button } from '../../../ui-kit';
import { iconBlueArrow } from '../../../icons';

import 'chartjs-plugin-datalabels';
import './index.scss';

interface IHorizontalBarChartProps {
  barData: IDiagnosisBarData[];
  bestSubjectValue?: number;
}

const ProgressInLearning: React.FC<IHorizontalBarChartProps> = ({ barData, bestSubjectValue }) => {
  const initialDisplayedBarsCount = 3;
  const [displayedBars, setDisplayedBars] = useState(initialDisplayedBarsCount);

  useEffect(() => {
    setDisplayedBars(initialDisplayedBarsCount);
  }, [barData]);

  const progressCharts = bestSubjectValue && barData?.slice(0, displayedBars).map((value, index) => {
    const totalDiagnostic = Math.round(value.total / bestSubjectValue * 100);
    return (
      <div key={index} className="horizontal-diagnostic__container" style={{ backgroundImage: `url(${Object.values(value)[3]})` }}>
        <div className="horizontal-diagnostic__title">
          {value.name}
        </div>
        <div className="horizontal-diagnostic__result">
          {`Пройдено диагностик: ${value.total}`}
        </div>
        <div className="horizontal-diagnostic__result-diagramm" style={{ width: `${totalDiagnostic}%` }} />
      </div>
    );
  });

  return (
    <div className="horizontal-bar">
      <div className="horizontal-bar__container">
        <div className="horizontal-diagnostic">
          {progressCharts}
        </div>
      </div>
      {displayedBars < barData?.length
        && (
        <Button
          className="btn-arrow"
          endIcon={(
            <img src={iconBlueArrow} alt="показать еще" />
                )}
          onClick={() => setDisplayedBars(barData.length)}
        >
          {`Показать еще  ${barData.length - displayedBars}`}
        </Button>
        )}
      {displayedBars > initialDisplayedBarsCount && (
      <Button
        className="btn-arrow"
        endIcon={(
          <img className="btn-arrow__icon btn-arrow__icon--flipped" src={iconBlueArrow} alt="скрыть" />
                )}
        onClick={() => setDisplayedBars(initialDisplayedBarsCount)}
      >
        Скрыть
      </Button>
      )}
    </div>
  );
};

export default ProgressInLearning;
