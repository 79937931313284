/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

const IconFirstCategory: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="50" height="50" fill="#E5E5E5"/>
    <rect width="1924" height="1397" transform="translate(-1046 -758)" fill="white"/>
    <g clip-path="url(#clip0)">
    <path d="M24.8822 54.3838C41.0711 54.3838 54.1948 41.4522 54.1948 25.5003C54.1948 9.54844 41.0711 -3.38312 24.8822 -3.38312C8.69329 -3.38312 -4.43042 9.54844 -4.43042 25.5003C-4.43042 41.4522 8.69329 54.3838 24.8822 54.3838Z" fill="white"/>
    <path d="M24.8821 54.5C19.0612 54.5 13.371 52.7992 8.53116 49.6126C3.69127 46.4261 -0.0809655 41.8969 -2.30852 36.5978C-4.53608 31.2988 -5.11891 25.4678 -3.98331 19.8424C-2.84771 14.2169 -0.0446887 9.04964 4.0713 4.99391C8.18728 0.938193 13.4314 -1.82379 19.1404 -2.94276C24.8494 -4.06173 30.767 -3.48743 36.1448 -1.29249C41.5226 0.902448 46.1191 4.61945 49.353 9.38847C52.5869 14.1575 54.313 19.7643 54.313 25.5C54.3058 33.1891 51.2027 40.5612 45.6849 45.9982C40.1671 51.4352 32.6854 54.4929 24.8821 54.5ZM24.8821 -3.27588C19.1062 -3.27588 13.46 -1.5882 8.65751 1.57374C3.85503 4.73567 0.111948 9.22985 -2.09839 14.488C-4.30873 19.7461 -4.88706 25.5319 -3.76024 31.1139C-2.63342 36.6959 0.147945 41.8232 4.23212 45.8476C8.3163 49.872 13.5199 52.6126 19.1848 53.723C24.8497 54.8333 30.7215 54.2634 36.0578 52.0855C41.394 49.9075 45.955 46.2192 49.1639 41.487C52.3728 36.7549 54.0855 31.1913 54.0855 25.5C54.0759 17.8711 50.996 10.5573 45.5214 5.16286C40.0468 -0.231604 32.6244 -3.26639 24.8821 -3.27588Z" fill="#969696"/>
    <path d="M24.8815 52.0795C39.7792 52.0795 51.8561 40.1794 51.8561 25.4999C51.8561 10.8204 39.7792 -1.07965 24.8815 -1.07965C9.9839 -1.07965 -2.09302 10.8204 -2.09302 25.4999C-2.09302 40.1794 9.9839 52.0795 24.8815 52.0795Z" fill="#748FFC"/>
    <path d="M24.881 49.704C38.4472 49.704 49.4447 38.8675 49.4447 25.5C49.4447 12.1325 38.4472 1.29602 24.881 1.29602C11.3149 1.29602 0.317383 12.1325 0.317383 25.5C0.317383 38.8675 11.3149 49.704 24.881 49.704Z" fill="#E83F40"/>
    <path d="M24.8825 48.0008C37.494 48.0008 47.7176 37.9269 47.7176 25.5C47.7176 13.0732 37.494 2.99927 24.8825 2.99927C12.271 2.99927 2.04736 13.0732 2.04736 25.5C2.04736 37.9269 12.271 48.0008 24.8825 48.0008Z" fill="#969696"/>
    <path d="M44.2784 4.03013L44.8425 6.48639L41.9949 6.54018L37.719 11.6141L12.9006 10.9238L8.35178 6.49536H5.23128L5.71346 3.65363C2.54944 6.37991 0.00902748 9.73718 -1.73968 13.5033C-3.48839 17.2694 -4.40553 21.3585 -4.43042 25.5C-4.42353 31.4624 -2.54414 37.2767 0.949812 42.1448C4.44377 47.0128 9.38104 50.6961 15.084 52.6892L19.8876 50.977L16.9854 48.1352L19.2962 42.6042H30.8685L33.4977 48.8076L30.1861 50.9949L35.0898 52.5637C39.836 50.8534 44.0486 47.9552 47.3134 44.1542C50.5781 40.3531 52.7824 35.7801 53.7095 30.885C54.6366 25.9899 54.2546 20.9413 52.601 16.2358C50.9473 11.5303 48.0791 7.33 44.2784 4.04806V4.03013Z" fill="#969696"/>
    <path d="M13.5461 49.1213C13.4733 49.2378 13.4096 49.3454 13.3369 49.444C12.8184 50.1701 12.0308 50.666 11.1443 50.8245C10.7213 50.9047 10.2922 50.9497 9.86155 50.959C9.43244 50.9426 9.00592 50.8855 8.58788 50.7887C8.1532 50.6953 7.72479 50.5756 7.30511 50.4301C6.88501 50.2792 6.47742 50.0964 6.08603 49.8833C5.54017 49.5875 5.0125 49.2558 4.47574 48.933C4.47574 48.933 4.47574 48.933 4.38477 48.8613C4.48333 48.7724 4.58658 48.6886 4.69409 48.6103C5.37556 48.1302 6.17837 47.8448 7.01399 47.7856C8.02051 47.7302 9.02932 47.8423 9.99802 48.1173C11.1443 48.4131 12.2906 48.7538 13.4278 49.0765L13.5461 49.1213Z" fill="#CCCCCC"/>
    <path d="M9.91607 51.0756H9.86148C9.42597 51.0574 8.99329 50.9974 8.56962 50.8963C8.12722 50.8101 7.69226 50.6902 7.26865 50.5377C6.83963 50.3836 6.42299 50.1978 6.02228 49.9819C5.64017 49.7758 5.24898 49.5427 4.87597 49.3186L4.41199 49.0317H4.34831C4.34177 49.0177 4.33838 49.0024 4.33838 48.9869C4.33838 48.9714 4.34177 48.9561 4.34831 48.9421C4.34098 48.9126 4.34098 48.8819 4.34831 48.8524L4.44838 48.7717L4.66673 48.5925C5.3669 48.1011 6.19247 47.8124 7.05031 47.7588C8.06918 47.689 9.09245 47.8014 10.0707 48.0904C10.8804 48.2966 11.6901 48.5297 12.4816 48.7538L13.5096 49.0496H13.5824H13.637C13.6651 49.0667 13.6873 49.0917 13.7007 49.1214C13.7074 49.1369 13.7109 49.1537 13.7109 49.1707C13.7109 49.1876 13.7074 49.2044 13.7007 49.22L13.6279 49.3275L13.4914 49.5427C13.2259 49.9158 12.8884 50.2338 12.4981 50.4783C12.1079 50.7229 11.6726 50.8893 11.217 50.968C10.7878 51.0445 10.3522 51.0805 9.91607 51.0756ZM4.56665 48.8614L5.00334 49.1214C5.37634 49.3455 5.75844 49.5785 6.14054 49.7847C6.52919 49.9945 6.93372 50.1743 7.35053 50.3226C7.76367 50.4668 8.18614 50.5836 8.61511 50.6722C9.02462 50.7653 9.44172 50.8223 9.86148 50.8425C10.2862 50.8347 10.7094 50.7897 11.1261 50.7081C11.9687 50.5428 12.7146 50.0645 13.2094 49.3724C13.2549 49.3186 13.2913 49.2469 13.3368 49.1841L12.3269 48.8972C11.5445 48.6731 10.7349 48.4401 9.93426 48.2339C8.99006 47.9614 8.00507 47.8522 7.02302 47.9112C6.20779 47.9666 5.4239 48.2427 4.7577 48.709C4.68997 48.7547 4.62608 48.8056 4.56665 48.8614Z" fill="#969696"/>
    <path d="M3.09303 8.59299C2.687 8.11307 2.4115 7.5393 2.29244 6.9256C2.12008 6.06108 2.19892 5.16617 2.51988 4.34384C2.7516 3.71349 3.05691 3.11182 3.42964 2.55095C3.79922 2.00342 4.22612 1.49564 4.70331 1.03596C5.12181 0.623595 5.56759 0.256052 6.00428 -0.138383C6.04693 -0.172252 6.09261 -0.202255 6.14074 -0.228027C6.27789 0.0539246 6.3641 0.357283 6.39548 0.668418C6.4563 1.22432 6.43485 1.78598 6.33179 2.3358C6.20561 2.97465 5.99774 3.59516 5.71315 4.18248C4.96076 5.67577 4.10033 7.11379 3.13852 8.48542L3.09303 8.59299Z" fill="#CCCCCC"/>
    <path d="M3.09307 8.70066C3.0802 8.70635 3.06625 8.70929 3.05213 8.70929C3.03802 8.70929 3.02407 8.70635 3.01119 8.70066C2.57697 8.21365 2.27908 7.62351 2.14692 6.98845C1.96283 6.08818 2.04493 5.15476 2.38346 4.29912C2.6303 3.66704 2.95087 3.06537 3.33871 2.50623C3.71018 1.94954 4.14348 1.43539 4.63058 0.973308C4.9308 0.677481 5.24922 0.390618 5.54034 0.121685L5.92244 -0.201035C5.94855 -0.229716 5.97929 -0.253948 6.01342 -0.27275H6.05891C6.09161 -0.281967 6.12628 -0.281967 6.15898 -0.27275C6.1891 -0.25953 6.21447 -0.237659 6.23176 -0.21C6.38149 0.0874285 6.47402 0.409604 6.50469 0.740232C6.56494 1.30545 6.54041 1.87633 6.43191 2.43451C6.31127 3.08455 6.10002 3.71504 5.80417 4.30808C5.15707 5.62447 4.41206 6.89192 3.57525 8.10005L3.22954 8.62895L3.20225 8.64688C3.19155 8.66554 3.17545 8.68062 3.156 8.69021C3.13655 8.69979 3.11464 8.70343 3.09307 8.70066ZM6.0953 -0.0217462L5.7132 0.309938C5.41298 0.578872 5.08546 0.85677 4.80343 1.14363C4.33093 1.59861 3.90718 2.10027 3.53886 2.6407C3.16758 3.18013 2.86221 3.76086 2.62909 4.37083C2.3077 5.1743 2.22872 6.05216 2.40165 6.89881C2.5134 7.44653 2.75673 7.95989 3.11127 8.39587L3.41149 7.92972C4.2419 6.72865 4.98375 5.47039 5.63132 4.16465C5.91133 3.59511 6.11617 2.99258 6.24086 2.37176C6.34484 1.83725 6.36936 1.29067 6.31364 0.749197C6.28349 0.482303 6.20976 0.221964 6.0953 -0.0217462Z" fill="#969696"/>
    <path d="M5.23096 6.4865C5.80256 4.90232 6.83445 3.5188 8.19814 2.50822C9.56183 1.49763 11.1971 0.904628 12.9003 0.80304C12.2457 2.31527 11.2428 3.65652 9.97084 4.7205C8.6512 5.84075 6.97266 6.46614 5.23096 6.4865Z" fill="#CCCCCC"/>
    <path d="M5.23085 6.5941C5.21288 6.59641 5.19462 6.59338 5.17841 6.58539C5.1622 6.5774 5.14878 6.56483 5.13987 6.54928C5.13109 6.53429 5.12646 6.51729 5.12646 6.49998C5.12646 6.48267 5.13109 6.46566 5.13987 6.45067C5.72089 4.84724 6.76683 3.44715 8.14796 2.42408C9.5291 1.401 11.1846 0.799959 12.9093 0.695494C12.9285 0.693576 12.9479 0.696715 12.9654 0.704589C12.983 0.712463 12.9982 0.724791 13.0093 0.740317C13.0207 0.757689 13.0267 0.777918 13.0267 0.798585C13.0267 0.819252 13.0207 0.839482 13.0093 0.856855C12.3504 2.38393 11.3378 3.73782 10.0526 4.81018C8.71409 5.94989 7.00888 6.58514 5.23995 6.60307L5.23085 6.5941ZM12.7091 0.937535C11.1062 1.07153 9.57384 1.64691 8.28692 2.59797C6.99999 3.54903 6.01036 4.83747 5.431 6.31621C7.08665 6.26703 8.67425 5.65585 9.92524 4.58607C11.1207 3.59248 12.0725 2.34514 12.7091 0.937535Z" fill="#969696"/>
    <path d="M-0.173096 43.7695C0.162455 43.5597 0.536413 43.4166 0.92772 43.3482C1.9117 43.1604 2.92995 43.2601 3.85716 43.6351C4.95674 44.0735 6.01598 44.6043 7.02315 45.2218C7.45074 45.4728 7.87833 45.7327 8.34231 46.0555C8.28278 46.0796 8.22751 46.1129 8.17855 46.1541C7.57214 46.7513 6.76071 47.1031 5.90414 47.1402C4.86293 47.1764 3.83412 46.9082 2.9474 46.3692C1.80535 45.7306 0.786079 44.8992 -0.063924 43.913L-0.173096 43.7695Z" fill="#CCCCCC"/>
    <path d="M5.63144 47.2567C4.66668 47.2295 3.72586 46.9545 2.90215 46.4588C1.74936 45.8131 0.720817 44.9725 -0.136466 43.9757L-0.209247 43.895V43.8412C-0.217232 43.8276 -0.221436 43.8121 -0.221436 43.7964C-0.221436 43.7806 -0.217232 43.7652 -0.209247 43.7516C-0.201868 43.7189 -0.182406 43.6902 -0.154661 43.6709C0.195456 43.4601 0.580346 43.3115 0.982545 43.2316C1.98823 43.0415 3.02836 43.1443 3.97567 43.5275C5.08485 43.964 6.15331 44.4949 7.16895 45.1142C7.54195 45.3383 7.99683 45.6072 8.48811 45.9479C8.50631 45.9569 8.52129 45.9711 8.53104 45.9887C8.5408 46.0064 8.54486 46.0265 8.54269 46.0465C8.54304 46.0649 8.5381 46.0831 8.52845 46.099C8.5188 46.1148 8.50482 46.1277 8.48811 46.1361H8.42442L8.34254 46.1899C7.7085 46.8372 6.84408 47.2164 5.93167 47.2477L5.63144 47.2567ZM-1.68383e-06 43.7964C0.838156 44.7755 1.84528 45.6009 2.97493 46.2347C3.85933 46.7799 4.88915 47.0515 5.93167 47.0146C6.76083 46.9825 7.54712 46.6431 8.1333 46.0644C7.69661 45.7686 7.29631 45.5355 6.9688 45.3472C5.96655 44.7293 4.91001 44.2012 3.81191 43.7695C2.91243 43.3888 1.92093 43.2706 0.955252 43.4288C0.620824 43.5063 0.299213 43.6301 -1.68383e-06 43.7964Z" fill="#969696"/>
    <path d="M-3.42163 37.7813C-3.12141 37.7365 -2.85758 37.6916 -2.58465 37.6737C-2.01583 37.6208 -1.44236 37.7007 -0.910679 37.9068C-0.568723 38.0304 -0.237154 38.1802 0.0809653 38.355C0.923286 38.8313 1.69735 39.4161 2.38267 40.0941C2.94673 40.62 3.51078 41.1579 4.07483 41.7077L4.2204 41.8512C3.73963 42.2073 3.16464 42.419 2.56462 42.4607C1.98913 42.4913 1.41326 42.4079 0.871036 42.2154C0.328814 42.023 -0.168752 41.7253 -0.592261 41.3402C-1.00046 40.9874 -1.38918 40.6134 -1.75676 40.2196C-2.38027 39.5246 -2.91496 38.7569 -3.34885 37.9337L-3.42163 37.7813Z" fill="#CCCCCC"/>
    <path d="M2.29157 42.5862C1.20318 42.5597 0.160537 42.1494 -0.646968 41.4298C-1.05119 41.0853 -1.43398 40.7171 -1.79327 40.3272C-2.42632 39.6239 -2.97012 38.8472 -3.41265 38.0144C-3.41045 37.9845 -3.41045 37.9546 -3.41265 37.9247V37.853C-3.42593 37.8184 -3.42593 37.7801 -3.41265 37.7454C-3.40486 37.7303 -3.39323 37.7174 -3.37889 37.7079C-3.36454 37.6985 -3.34797 37.6929 -3.33078 37.6917H-3.04875L-2.48469 37.6199C-1.89803 37.5678 -1.30702 37.6475 -0.75614 37.853C-0.411552 37.9877 -0.0744638 38.1403 0.253699 38.3102C1.10291 38.8058 1.88281 39.4085 2.5736 40.1031L4.07471 41.5464L4.26576 41.7167L4.36584 41.8243L4.41133 41.8781C4.41787 41.8921 4.42125 41.9074 4.42125 41.9229C4.42125 41.9383 4.41787 41.9536 4.41133 41.9677C4.40196 41.9995 4.3829 42.0276 4.35674 42.0484C3.86046 42.4166 3.2662 42.6346 2.64638 42.6759L2.29157 42.5862ZM-3.2489 37.8709C-2.82302 38.6912 -2.29092 39.4536 -1.66591 40.139C-1.302 40.5065 -0.938093 40.892 -0.537796 41.2237C-0.12718 41.5985 0.356027 41.8878 0.882857 42.0742C1.40969 42.2606 1.96929 42.3402 2.52811 42.3084C3.0606 42.2751 3.57358 42.0984 4.01103 41.7974H3.96554L3.78359 41.6181L2.29157 40.1748C1.61196 39.5068 0.847473 38.9283 0.0171602 38.4536C-0.294273 38.297 -0.616523 38.1622 -0.947191 38.0502C-1.46489 37.8516 -2.02254 37.7748 -2.57567 37.8261C-2.75762 37.8261 -2.93048 37.8261 -3.12153 37.8889L-3.2489 37.8709Z" fill="#969696"/>
    <path d="M0.581608 14.5186C0.318566 14.3263 0.0664822 14.1198 -0.173497 13.9001C-0.473394 13.6154 -0.711688 13.2739 -0.874015 12.8961C-1.1547 12.2986 -1.31535 11.6532 -1.34709 10.9956C-1.36929 9.98085 -1.20913 8.97023 -0.874015 8.01045C-0.596675 7.14971 -0.221278 6.3227 0.244995 5.54523L0.335972 5.40179L0.517925 5.64383C1.09226 6.43312 1.43246 7.36427 1.50047 8.33317C1.56909 9.26374 1.50478 10.1992 1.30942 11.1121C1.09108 12.17 0.854538 13.2188 0.627097 14.2676C0.627097 14.3573 0.608901 14.4111 0.581608 14.5186Z" fill="#CCCCCC"/>
    <path d="M0.581881 14.6351C0.558481 14.6441 0.532501 14.6441 0.5091 14.6351L0.254366 14.4379C0.0744779 14.3065 -0.0957686 14.1627 -0.255103 14.0076C-0.562922 13.7031 -0.809825 13.3443 -0.982915 12.9498C-1.26603 12.3374 -1.42666 11.6769 -1.45599 11.0045C-1.47811 9.97803 -1.31801 8.95565 -0.982915 7.98349C-0.700177 7.11328 -0.318696 6.27729 0.154291 5.49137V5.41966L0.19978 5.34794C0.211074 5.33193 0.225999 5.31873 0.24337 5.3094C0.26074 5.30006 0.28008 5.29484 0.299854 5.29415C0.317572 5.29379 0.335113 5.29768 0.350963 5.30549C0.366814 5.3133 0.380497 5.32479 0.39083 5.33898L0.572783 5.58102C1.16741 6.39897 1.51727 7.36487 1.58262 8.36896C1.66382 9.30082 1.61176 10.2393 1.42796 11.1569C1.2824 11.9099 1.10955 12.6629 0.945788 13.4159L0.754737 14.3124V14.4289V14.5365C0.749461 14.5544 0.74019 14.571 0.727599 14.5849C0.715008 14.5989 0.699414 14.6099 0.681955 14.6172L0.581881 14.6351ZM0.35444 5.60791C-0.10744 6.37696 -0.479767 7.19491 -0.755474 8.04624C-1.08726 8.99115 -1.2444 9.98702 -1.21945 10.9866C-1.19062 11.6301 -1.0394 12.2626 -0.773669 12.8512C-0.611035 13.2218 -0.379632 13.5593 -0.091345 13.8462C0.0642918 13.9928 0.228318 14.1305 0.399928 14.2586L0.518198 14.3482C0.513901 14.3336 0.513901 14.3181 0.518198 14.3034L0.709249 13.407C0.873006 12.6629 1.03676 11.9099 1.19142 11.1569C1.38823 10.2441 1.45255 9.30849 1.38247 8.37792C1.33835 7.41566 1.02334 6.48449 0.472709 5.68859L0.35444 5.60791Z" fill="#969696"/>
    <path d="M-5.29468 31.291C-4.91158 31.2621 -4.52634 31.3016 -4.15747 31.4076C-3.09191 31.7009 -2.1408 32.3038 -1.42818 33.1377C-0.595572 34.1146 0.153022 35.158 0.809845 36.2573L1.25563 36.9655C1.08277 37.0193 0.937213 37.0821 0.782553 37.1179C0.179879 37.2607 -0.451764 37.2296 -1.03698 37.0283C-2.0517 36.6614 -2.91729 35.9785 -3.50244 35.083C-4.28279 33.9929 -4.8703 32.7805 -5.24009 31.4972V31.3896C-5.26617 31.3616 -5.28491 31.3278 -5.29468 31.291Z" fill="#CCCCCC"/>
    <path d="M0.0175457 37.3152C-0.364758 37.3201 -0.745464 37.2656 -1.11056 37.1538C-2.14815 36.7698 -3.03422 36.0695 -3.63971 35.1547C-4.42071 34.0475 -5.01089 32.8208 -5.38646 31.5241V31.4076C-5.39559 31.3845 -5.39559 31.3589 -5.38646 31.3359C-5.38646 31.2821 -5.38646 31.2104 -5.28639 31.2104C-4.8816 31.1586 -4.47041 31.186 -4.0764 31.291C-3.00359 31.603 -2.05197 32.2281 -1.3471 33.0839C-0.497756 34.0572 0.269025 35.0976 0.945507 36.1946L1.20934 36.607L1.39129 36.9028C1.39805 36.9184 1.40153 36.9352 1.40153 36.9521C1.40153 36.969 1.39805 36.9858 1.39129 37.0014C1.38689 37.0187 1.37779 37.0346 1.36497 37.0472C1.35216 37.0598 1.3361 37.0688 1.31851 37.0731L1.16385 37.1269L0.836334 37.2345C0.56778 37.2943 0.292797 37.3214 0.0175457 37.3152ZM-5.16812 31.3986C-4.80855 32.6808 -4.23017 33.8934 -3.45776 34.9844C-2.88326 35.8596 -2.03787 36.5292 -1.04688 36.8938C-0.463031 37.1019 0.170649 37.1331 0.772651 36.9835L1.07287 36.8849L0.972799 36.7145L0.708968 36.3022C0.0439906 35.2128 -0.713848 34.1812 -1.55635 33.2184C-2.23812 32.4021 -3.15473 31.8083 -4.18557 31.5152C-4.50432 31.4232 -4.83632 31.3838 -5.16812 31.3986Z" fill="#969696"/>
    <path d="M14.511 44.1371H14.5837C15.7602 44.9279 16.8761 45.8028 17.9226 46.7547C18.6855 47.4505 19.2768 48.3091 19.6511 49.2647C19.8193 49.6943 19.9116 50.1491 19.9241 50.6094C19.9241 50.6094 19.9241 50.699 19.9241 50.7618L19.642 50.6901C18.3472 50.3312 17.138 49.7217 16.0849 48.8972C15.4218 48.405 14.8935 47.7581 14.5474 47.0146C14.1872 46.1592 14.1517 45.2045 14.4473 44.3253C14.4655 44.2357 14.4928 44.1908 14.511 44.1371Z" fill="#CCCCCC"/>
    <path d="M19.9142 50.9142H19.8142H19.6231C18.3019 50.559 17.0652 49.9497 15.984 49.1214C15.3002 48.61 14.7613 47.934 14.4193 47.1581C14.0357 46.2608 14.0001 45.2559 14.3192 44.3343C14.3303 44.2941 14.3456 44.255 14.3647 44.2178C14.3701 44.2035 14.3787 44.1905 14.3896 44.1797C14.4006 44.1688 14.4138 44.1604 14.4284 44.155C14.4442 44.1484 14.4612 44.145 14.4784 44.145C14.4956 44.145 14.5126 44.1484 14.5284 44.155H14.5921C15.7754 44.9439 16.8947 45.8221 17.94 46.7816C18.7207 47.4857 19.3222 48.3609 19.6959 49.3365C19.8688 49.7775 19.9642 50.2444 19.9779 50.717C19.9824 50.7497 19.9824 50.7829 19.9779 50.8156V50.8784C19.9891 50.9072 19.9891 50.9392 19.9779 50.968L19.9142 50.9142ZM14.5648 44.3254C14.2544 45.2017 14.2869 46.1607 14.6558 47.0147C14.9819 47.7549 15.4957 48.4001 16.1478 48.8883C17.1857 49.7102 18.3795 50.3197 19.6595 50.6812H19.8051C19.8126 50.226 19.7355 49.7733 19.5776 49.3455C19.2129 48.4045 18.6304 47.5603 17.8764 46.8802C16.845 45.9412 15.7374 45.0867 14.5648 44.3254Z" fill="#969696"/>
    <path d="M10.4804 41.4119C11.6089 42.6268 12.5555 43.9942 13.2916 45.4728C13.7438 46.4163 13.8991 47.4716 13.7374 48.5028C13.7374 48.6462 13.6737 48.7896 13.6464 48.942H13.5645C12.3107 48.2333 11.2519 47.2331 10.4804 46.0286C10.0078 45.3179 9.76076 44.4847 9.7708 43.6351C9.77997 42.8772 10.0071 42.1374 10.4258 41.5015L10.4804 41.4119Z" fill="#CCCCCC"/>
    <path d="M13.6286 49.0586C13.5894 49.0646 13.5495 49.0646 13.5104 49.0586C12.2248 48.3511 11.1371 47.3409 10.3444 46.1182C9.85566 45.3907 9.60193 44.5344 9.61657 43.662C9.62561 42.8819 9.85906 42.1203 10.2898 41.4657L10.3444 41.394C10.3547 41.3798 10.3684 41.3683 10.3842 41.3605C10.4001 41.3527 10.4176 41.3488 10.4354 41.3491C10.4498 41.3432 10.4652 41.3402 10.4808 41.3402C10.4965 41.3402 10.5119 41.3432 10.5263 41.3491C11.6578 42.5755 12.6101 43.9513 13.3557 45.4369C13.8122 46.4047 13.9704 47.4831 13.8106 48.5386C13.7946 48.6412 13.7734 48.743 13.7469 48.8434V48.9779C13.7527 48.9906 13.7557 49.0043 13.7557 49.0182C13.7557 49.0321 13.7527 49.0459 13.7469 49.0586H13.6286ZM10.4808 41.636C10.0854 42.2472 9.87117 42.9551 9.8622 43.6799C9.84961 44.5049 10.0906 45.3143 10.5536 46.0017C11.3068 47.1614 12.3337 48.1242 13.5468 48.8076C13.5468 48.709 13.5922 48.6193 13.6104 48.5297C13.7865 47.5088 13.6471 46.4594 13.2101 45.5176C12.4887 44.1046 11.57 42.798 10.4808 41.636Z" fill="#969696"/>
    <path d="M11.1899 8.09989L9.88892 8.63776C9.07715 8.97762 8.23702 9.24756 7.37797 9.44456C6.0826 9.7746 4.70753 9.59116 3.54786 8.93358L3.20215 8.71844L3.42959 8.51225C4.42346 7.62165 5.62748 6.98994 6.93219 6.67454C7.50075 6.51765 8.09754 6.48693 8.67959 6.58458C9.26165 6.68224 9.81449 6.90584 10.2983 7.2393C10.6409 7.47657 10.9421 7.76724 11.1899 8.09989Z" fill="#CCCCCC"/>
    <path d="M6.04035 9.71367C5.14122 9.71961 4.25743 9.48408 3.48391 9.03237L3.27467 8.89791L3.20189 8.81723C3.19534 8.80316 3.19196 8.78787 3.19196 8.7724C3.19196 8.75693 3.19534 8.74165 3.20189 8.72758C3.1939 8.71395 3.1897 8.6985 3.1897 8.68276C3.1897 8.66702 3.1939 8.65157 3.20189 8.63794L3.27467 8.56622L3.42023 8.42279C4.42409 7.51322 5.63937 6.8606 6.95921 6.52233C7.54373 6.36038 8.15763 6.32947 8.75586 6.43185C9.35409 6.53423 9.92146 6.76731 10.4163 7.11398C10.7722 7.35598 11.0805 7.65973 11.3261 8.01043C11.3341 8.02567 11.3382 8.04258 11.3382 8.05973C11.3382 8.07688 11.3341 8.09379 11.3261 8.10904C11.3156 8.14272 11.293 8.17144 11.2624 8.18972L10.8075 8.37797L9.96144 8.72758C9.14363 9.07031 8.29748 9.34323 7.43229 9.54335C6.97576 9.64864 6.50915 9.70574 6.04035 9.71367ZM3.41113 8.70069L3.60218 8.82619C4.73867 9.46548 6.08353 9.64239 7.35041 9.31924C8.20491 9.13098 9.03951 8.86386 9.84317 8.5214L10.6893 8.17179L11.0077 8.03732C10.7862 7.75209 10.5224 7.50128 10.2253 7.29327C9.75787 6.96553 9.22172 6.74543 8.65642 6.64923C8.09111 6.55303 7.5111 6.58319 6.95921 6.73747C5.67566 7.06839 4.49458 7.7057 3.5203 8.59311L3.41113 8.70069Z" fill="#969696"/>
    <path d="M7.30497 37.8619L7.79624 38.8659C8.29226 39.8972 8.67061 40.9796 8.92435 42.0931C9.18754 43.2488 9.0729 44.4569 8.59684 45.5444C8.59684 45.6251 8.51496 45.7058 8.46947 45.7865L8.42398 45.8403L8.3512 45.7865C7.37915 44.8426 6.64938 43.6841 6.22235 42.4069C5.9969 41.784 5.92146 41.118 6.00199 40.4615C6.08251 39.805 6.31678 39.1759 6.68633 38.6239C6.84099 38.355 7.08662 38.1308 7.30497 37.8619Z" fill="#CCCCCC"/>
    <path d="M8.41475 45.9479H8.36926L8.26919 45.8852C7.28475 44.9215 6.54588 43.7419 6.11304 42.4428C5.87947 41.8038 5.80141 41.1196 5.88516 40.4454C5.96891 39.7713 6.21212 39.1259 6.59522 38.5612C6.71563 38.3762 6.85265 38.2022 7.00461 38.0413L7.21386 37.7903C7.23072 37.7816 7.24944 37.7771 7.26845 37.7771C7.28745 37.7771 7.30617 37.7816 7.32303 37.7903C7.34251 37.7917 7.36122 37.7983 7.37722 37.8094C7.39322 37.8204 7.40593 37.8355 7.41401 37.853L7.56867 38.1757C7.68694 38.4178 7.79611 38.6329 7.89618 38.857C8.40348 39.8949 8.7881 40.9867 9.04249 42.1111C9.30909 43.2881 9.19127 44.5181 8.70587 45.6252C8.70587 45.7059 8.63309 45.7686 8.5967 45.8403L8.55121 45.8941C8.53536 45.9142 8.51436 45.9298 8.49038 45.9392C8.4664 45.9487 8.44029 45.9517 8.41475 45.9479ZM7.27754 38.0502C7.2492 38.093 7.21558 38.1321 7.17747 38.1668C7.04101 38.3281 6.90454 38.4895 6.78627 38.6598C6.42632 39.1975 6.1986 39.8105 6.12121 40.4501C6.04383 41.0896 6.11891 41.7382 6.34049 42.3442C6.75082 43.5889 7.45554 44.7196 8.39655 45.6431C8.43225 45.5893 8.46271 45.5323 8.48753 45.4728C8.95115 44.4073 9.06565 43.2258 8.81505 42.0932C8.55861 41.0064 8.18025 39.951 7.68694 38.9467C7.58686 38.7226 7.47769 38.5074 7.35942 38.2654L7.27754 38.0502Z" fill="#969696"/>
    <path d="M4.33898 41.7974C4.09335 41.2864 3.85681 40.8203 3.64756 40.3452C3.53529 40.1062 3.44693 39.857 3.38373 39.6011C3.25274 39.1232 3.14946 38.6383 3.07441 38.1489C2.92806 37.333 3.0296 36.4928 3.36627 35.7336C3.70293 34.9744 4.25972 34.3302 4.96672 33.8818L5.10319 33.8011C5.20326 34.2404 5.30333 34.6976 5.39431 35.1099C5.61151 36.1267 5.71523 37.1637 5.70363 38.2027C5.689 39.3876 5.29764 40.5383 4.58462 41.4926C4.51184 41.5823 4.43906 41.636 4.33898 41.7974Z" fill="#CCCCCC"/>
    <path d="M4.33985 41.9139C4.32086 41.9109 4.30281 41.9037 4.28705 41.8928C4.2713 41.882 4.25824 41.8677 4.24887 41.8512L4.04873 41.4209C3.87587 41.0713 3.71211 40.7306 3.55745 40.39C3.44533 40.1447 3.357 39.8896 3.29362 39.628L3.23903 39.4487C3.12754 39.0264 3.03947 38.5985 2.9752 38.1668C2.8266 37.3285 2.93201 36.4656 3.27828 35.6859C3.62454 34.9063 4.1963 34.2445 4.9221 33.7832L5.01308 33.7294H5.06766C5.08348 33.7227 5.1005 33.7193 5.1177 33.7193C5.1349 33.7193 5.15192 33.7227 5.16774 33.7294C5.18482 33.7358 5.19979 33.7467 5.21101 33.761C5.22224 33.7752 5.2293 33.7922 5.23142 33.8101L5.33149 34.2314C5.39518 34.5272 5.46796 34.8231 5.53164 35.1278C5.7533 36.153 5.85706 37.1995 5.84096 38.2475C5.82682 39.4561 5.42564 40.6295 4.69466 41.6002L4.54 41.8064L4.44902 41.9139C4.43243 41.9234 4.4136 41.9283 4.39443 41.9283C4.37527 41.9283 4.35645 41.9234 4.33985 41.9139ZM5.02217 33.9804C4.34002 34.4231 3.80426 35.0528 3.48094 35.7919C3.15762 36.5311 3.06088 37.3473 3.20264 38.1399C3.26342 38.5633 3.35154 38.9825 3.46648 39.3949L3.51196 39.5742C3.57241 39.8239 3.65774 40.0671 3.7667 40.3003C3.91226 40.641 4.07602 40.9727 4.24887 41.3223L4.37624 41.5822L4.49451 41.4209C5.19724 40.4862 5.58249 39.3568 5.59532 38.1937C5.61121 37.1635 5.50743 36.1348 5.28601 35.1278C5.23142 34.832 5.15864 34.5452 5.09495 34.2314L5.02217 33.9804Z" fill="#969696"/>
    <path d="M7.65989 11.5425C7.33237 11.8473 7.02305 12.17 6.68644 12.4389C6.12238 12.9051 5.56743 13.3354 4.97608 13.7836C4.04953 14.4648 2.90986 14.8042 1.75551 14.7428C1.42799 14.7428 1.10048 14.6442 0.736572 14.5993C0.736572 14.5187 0.78206 14.4738 0.800256 14.438C0.982209 14.1422 1.16416 13.8374 1.35521 13.5415C1.54987 13.2538 1.76256 12.9784 1.99205 12.7168C2.41091 12.2124 2.90837 11.7767 3.46587 11.4259C4.05197 11.0256 4.74594 10.8067 5.45902 10.7971C6.1721 10.7875 6.87187 10.9877 7.46884 11.3721C7.52079 11.3998 7.56958 11.4329 7.6144 11.4708C7.6144 11.4708 7.63259 11.5156 7.65989 11.5425Z" fill="#CCCCCC"/>
    <path d="M2.07438 14.8772H1.74686C1.51745 14.8643 1.28936 14.8344 1.06454 14.7875L0.727925 14.7427C0.709169 14.7431 0.690695 14.7382 0.674609 14.7287C0.658522 14.7192 0.645473 14.7054 0.636949 14.6889C0.630193 14.6733 0.626709 14.6566 0.626709 14.6396C0.626709 14.6227 0.630193 14.6059 0.636949 14.5903C0.639322 14.5665 0.639322 14.5425 0.636949 14.5186C0.650378 14.485 0.665561 14.4521 0.682437 14.42L0.828 14.178C0.964465 13.9628 1.10093 13.7387 1.24649 13.5235C1.39205 13.3084 1.64679 12.9588 1.88333 12.6809C2.31501 12.1736 2.82092 11.7325 3.38444 11.3721C3.99005 10.9609 4.70595 10.7363 5.44131 10.7268C6.17668 10.7172 6.89835 10.9231 7.51477 11.3183C7.57178 11.3459 7.62398 11.3822 7.66943 11.4259C7.6948 11.4395 7.71422 11.4618 7.72402 11.4886C7.74174 11.5119 7.75131 11.5402 7.75131 11.5693C7.75131 11.5984 7.74174 11.6267 7.72402 11.65L7.43289 11.9368C7.21455 12.152 6.98711 12.3671 6.75057 12.5644C6.21381 13.0126 5.64976 13.4608 5.0766 13.8732C4.2103 14.5151 3.15824 14.8669 2.07438 14.8772ZM0.900781 14.5096H1.10093C1.32837 14.5096 1.54671 14.5814 1.76506 14.5993C2.89414 14.6611 4.00907 14.3277 4.91284 13.658C5.486 13.2636 6.03186 12.7616 6.56862 12.3582C6.80516 12.1699 7.0235 11.9548 7.24185 11.7486C7.31463 11.6769 7.37831 11.6052 7.45109 11.5424L7.36921 11.4886C6.79305 11.1114 6.11461 10.9147 5.42296 10.9243C4.73131 10.9339 4.05876 11.1494 3.49361 11.5424C2.94579 11.8846 2.45738 12.3114 2.04708 12.8064C1.81055 13.0753 1.6013 13.3712 1.41935 13.6222C1.23739 13.8732 1.14642 14.0525 1.00995 14.2676L0.864391 14.5096H0.900781Z" fill="#969696"/>
    <path d="M5.32184 15.5674C5.15809 15.8184 5.00343 16.0605 4.83967 16.2936C4.26907 17.1762 3.59751 17.9913 2.83819 18.7229C1.94297 19.602 0.735806 20.1036 -0.527946 20.1214H-0.74629C-0.750911 20.0917 -0.750911 20.0614 -0.74629 20.0317C-0.385018 18.61 0.371056 17.3156 1.43715 16.2936C1.91943 15.8187 2.5328 15.4942 3.20089 15.3603C3.86898 15.2264 4.56228 15.2891 5.19448 15.5405H5.25816C5.25816 15.5405 5.28545 15.5405 5.32184 15.5674Z" fill="#CCCCCC"/>
    <path d="M-0.536863 20.202H-0.755207C-0.784972 20.1997 -0.812766 20.1865 -0.833015 20.1649C-0.853263 20.1432 -0.864467 20.1149 -0.864379 20.0855C-0.873658 20.0472 -0.873658 20.0072 -0.864379 19.9689C-0.488638 18.5181 0.289426 17.1989 1.38274 16.159C1.88084 15.6702 2.51381 15.3365 3.20291 15.1994C3.89201 15.0623 4.6068 15.128 5.25834 15.3881H5.34022C5.36796 15.4074 5.38743 15.4361 5.3948 15.4688C5.40135 15.4828 5.40473 15.4981 5.40473 15.5136C5.40473 15.5291 5.40135 15.5444 5.3948 15.5584L5.24924 15.7825L4.91263 16.2845C4.33917 17.1756 3.66128 17.997 2.89295 18.7318C1.97044 19.6199 0.734582 20.1211 -0.555059 20.1303L-0.536863 20.202ZM-0.618742 19.9689H-0.527766C0.701526 19.9611 1.87944 19.482 2.75649 18.6332C3.51238 17.9131 4.1782 17.1066 4.73977 16.2308C4.85804 16.0694 4.96721 15.8991 5.07639 15.7287L5.15827 15.6122C4.54607 15.3695 3.87508 15.3089 3.2283 15.4379C2.58152 15.567 1.98732 15.88 1.51921 16.3383C0.487965 17.3369 -0.250655 18.5912 -0.618742 19.9689Z" fill="#969696"/>
    <path d="M3.77475 29.3279C3.77475 29.4982 3.77475 29.6506 3.77475 29.803C3.84266 31.1361 3.72636 32.472 3.42904 33.7742C3.16034 34.9246 2.52224 35.9589 1.60951 36.7236L1.39117 36.8759C1.39117 36.7146 1.30019 36.5801 1.2547 36.4367C0.93643 35.3241 0.825465 34.1639 0.927187 33.0123C0.97191 32.2082 1.25404 31.4346 1.73892 30.7864C2.22379 30.1382 2.89028 29.6438 3.65648 29.3637L3.77475 29.3279Z" fill="#CCCCCC"/>
    <path d="M1.38225 36.9925C1.36467 36.9881 1.34861 36.9792 1.33579 36.9665C1.32297 36.9539 1.31387 36.9381 1.30947 36.9208L1.26398 36.7773C1.26398 36.6698 1.2003 36.5712 1.17301 36.4725C0.843334 35.3498 0.729175 34.1763 0.836393 33.0123C0.880652 32.1899 1.16647 31.3979 1.65909 30.7327C2.15172 30.0674 2.82991 29.5576 3.61118 29.2651H3.71125H3.77493C3.79191 29.2592 3.81011 29.2575 3.82792 29.2601C3.84573 29.2628 3.8626 29.2698 3.87701 29.2804C3.89142 29.2911 3.90294 29.3051 3.91052 29.3212C3.91811 29.3373 3.92154 29.355 3.9205 29.3727V29.5161C3.9205 29.6327 3.9205 29.7492 3.9205 29.8568C3.99044 31.1988 3.87413 32.5439 3.57479 33.8549C3.29494 35.0357 2.63028 36.0937 1.68247 36.867L1.53691 36.9746H1.46413C1.45261 36.9832 1.43933 36.9893 1.42519 36.9924C1.41105 36.9955 1.3964 36.9955 1.38225 36.9925ZM3.65666 29.4803C2.91063 29.7581 2.26423 30.2464 1.79823 30.8841C1.33223 31.5219 1.0673 32.2808 1.03654 33.0661C0.941822 34.1891 1.05904 35.3198 1.38225 36.4008L1.46413 36.6877L1.54601 36.6339C2.45314 35.8907 3.09152 34.878 3.36554 33.7474C3.66929 32.4583 3.78568 31.1333 3.71125 29.812C3.67486 29.6954 3.67486 29.5878 3.66576 29.4803H3.65666Z" fill="#969696"/>
    <path d="M3.37482 24.6753C3.20199 26.0052 2.8628 27.309 2.36498 28.5569C2.08637 29.2213 1.68539 29.8291 1.18229 30.3498C0.762304 30.7836 0.266058 31.139 -0.282434 31.3986H-0.373411H-0.437094C-0.437094 31.2821 -0.437094 31.1655 -0.482582 31.049C-0.603784 29.7773 -0.439158 28.4948 -0.000407219 27.2929C0.217672 26.5956 0.636232 25.9755 1.20459 25.5077C1.77296 25.04 2.46675 24.7446 3.20197 24.6573H3.29294L3.37482 24.6753Z" fill="#CCCCCC"/>
    <path d="M-0.43725 31.5689C-0.468827 31.5691 -0.499702 31.5597 -0.525723 31.5421C-0.551745 31.5245 -0.571667 31.4994 -0.582812 31.4703V31.3627V31.1117C-0.714215 29.795 -0.546395 28.466 -0.0915386 27.2212C0.13776 26.5023 0.574921 25.865 1.16636 25.3873C1.75781 24.9096 2.47784 24.6123 3.2382 24.5318H3.40196C3.45655 24.5318 3.52023 24.6125 3.51113 24.6663C3.33405 26.008 2.99189 27.3235 2.4922 28.5838C2.20465 29.2499 1.79441 29.8577 1.28221 30.3767C0.858441 30.825 0.352139 31.1901 -0.209808 31.4524H-0.31898L-0.43725 31.5689ZM3.20181 24.7739C2.49545 24.8575 1.82836 25.1394 1.27996 25.586C0.731572 26.0326 0.324984 26.6251 0.10861 27.2929C-0.335585 28.5028 -0.497286 29.7959 -0.364468 31.0759V31.291C0.171191 31.0395 0.655352 30.6931 1.06386 30.2691C1.56042 29.7487 1.95247 29.1403 2.21927 28.4762C2.69683 27.2856 3.02673 26.0424 3.20181 24.7739Z" fill="#969696"/>
    <path d="M-1.18335 25.7869C-1.12876 25.4104 -1.08328 25.0428 -1.01959 24.6932C-0.855447 23.6882 -0.497199 22.7235 0.0357361 21.8515C0.386058 21.1988 0.932328 20.6685 1.59993 20.3332C2.26754 19.9979 3.02392 19.8738 3.76577 19.9779H3.83855H3.88404C3.82036 20.1393 3.76577 20.3096 3.70209 20.471C3.30198 21.6122 2.75793 22.6995 2.08271 23.7071C1.35767 24.7898 0.230853 25.5493 -1.05598 25.8227L-1.18335 25.7869Z" fill="#CCCCCC"/>
    <path d="M-1.18327 25.9034C-1.19615 25.9091 -1.2101 25.912 -1.22421 25.912C-1.23833 25.912 -1.25228 25.9091 -1.26515 25.9034C-1.27783 25.8717 -1.27783 25.8364 -1.26515 25.8048L-1.21057 25.4551C-1.21057 25.1952 -1.14688 24.9531 -1.1014 24.7111C-0.93202 23.6907 -0.56774 22.7112 -0.0278732 21.8245C0.335728 21.1533 0.899907 20.6085 1.58825 20.2637C2.27659 19.919 3.05584 19.791 3.82043 19.8972H3.90231H3.966C3.99122 19.9131 4.01087 19.9362 4.02227 19.9635C4.03367 19.9907 4.03625 20.0208 4.02968 20.0496L3.97509 20.2109C3.92961 20.3185 3.89322 20.4351 3.84773 20.5516C3.43087 21.6688 2.87142 22.7291 2.18286 23.7071C1.43937 24.8124 0.286903 25.5877 -1.02861 25.8675H-1.18327V25.9034ZM3.26548 20.0585C2.62271 20.0621 1.99281 20.2363 1.44206 20.5629C0.89131 20.8894 0.440088 21.3562 0.135885 21.9142C-0.386482 22.7734 -0.738434 23.7225 -0.901248 24.7111C-0.946736 24.9531 -0.983127 25.1952 -1.01952 25.4462V25.6524C0.223084 25.3829 1.30998 24.6464 2.01 23.5995C2.67527 22.629 3.21614 21.5812 3.62029 20.4799C3.62029 20.3633 3.70217 20.2468 3.74765 20.1392V20.0854L3.26548 20.0585Z" fill="#969696"/>
    <path d="M36.5273 49.1213C36.6001 49.2378 36.6638 49.3454 36.7366 49.444C37.255 50.1701 38.0426 50.666 38.9291 50.8245C39.3521 50.9047 39.7812 50.9497 40.2119 50.959C40.641 50.9426 41.0675 50.8855 41.4856 50.7887C41.9202 50.6953 42.3487 50.5756 42.7683 50.4301C43.1884 50.2792 43.596 50.0964 43.9874 49.8833C44.5333 49.5875 45.0609 49.2558 45.5977 48.933C45.5977 48.933 45.5977 48.933 45.6887 48.8613C45.5901 48.7724 45.4869 48.6886 45.3794 48.6103C44.6979 48.1302 43.8951 47.8448 43.0595 47.7856C42.0529 47.7302 41.0441 47.8423 40.0754 48.1173C38.9291 48.4131 37.7828 48.7538 36.6456 49.0765L36.5273 49.1213Z" fill="#CCCCCC"/>
    <path d="M40.1565 51.0756C39.7375 51.0654 39.3203 51.0174 38.9102 50.9322C38.4546 50.8535 38.0193 50.6871 37.629 50.4425C37.2388 50.1979 36.9013 49.88 36.6357 49.5069L36.4902 49.2917L36.4265 49.1841C36.4197 49.1686 36.4163 49.1518 36.4163 49.1348C36.4163 49.1179 36.4197 49.1011 36.4265 49.0855C36.4399 49.0559 36.4621 49.0309 36.4902 49.0138H36.5448H36.6176L37.6456 48.718C38.428 48.4939 39.2468 48.2608 40.0565 48.0546C41.0349 47.7661 42.058 47.6537 43.0769 47.7229C43.9347 47.7766 44.7603 48.0653 45.4605 48.5566L45.6788 48.7359L45.7789 48.8166C45.7862 48.846 45.7862 48.8768 45.7789 48.9062C45.7854 48.9203 45.7888 48.9356 45.7888 48.9511C45.7888 48.9665 45.7854 48.9818 45.7789 48.9959H45.7152L45.2421 49.2828C44.8691 49.5069 44.487 49.7399 44.1049 49.9461C43.7043 50.1622 43.2877 50.348 42.8585 50.5019C42.4349 50.6544 42 50.7742 41.5576 50.8605C41.1339 50.9616 40.7012 51.0216 40.2657 51.0398L40.1565 51.0756ZM36.6994 49.1841C36.6994 49.2469 36.7813 49.3186 36.8268 49.3724C37.3303 50.0723 38.0906 50.5514 38.9466 50.7081C39.3633 50.7892 39.7864 50.8342 40.2111 50.8426C40.6309 50.8223 41.048 50.7653 41.4575 50.6722C41.8854 50.5794 42.3076 50.4627 42.7221 50.3226C43.1382 50.1726 43.5426 49.9928 43.9321 49.7848C44.3142 49.5786 44.6963 49.3455 45.0602 49.1214L45.506 48.8614C45.4469 48.8026 45.383 48.7486 45.3149 48.7001C44.6477 48.2369 43.8639 47.964 43.0496 47.9112C42.0549 47.8509 41.0572 47.9632 40.102 48.2429C39.3014 48.4491 38.4826 48.6821 37.7002 48.9062L36.6994 49.1841Z" fill="#969696"/>
    <path d="M46.9802 8.59299C47.3863 8.11307 47.6618 7.5393 47.7808 6.9256C47.9532 6.06108 47.8744 5.16617 47.5534 4.34384C47.3217 3.71349 47.0164 3.11182 46.6436 2.55095C46.2741 2.00342 45.8472 1.49564 45.37 1.03596C44.9515 0.623595 44.4602 0.256052 44.069 -0.138383C44.0263 -0.172252 43.9807 -0.202255 43.9325 -0.228027C43.7954 0.0539246 43.7092 0.357283 43.6778 0.668418C43.617 1.22432 43.6384 1.78598 43.7415 2.3358C43.8677 2.97465 44.0755 3.59516 44.3601 4.18248C45.1125 5.67577 45.9729 7.11379 46.9348 8.48542L46.9802 8.59299Z" fill="#CCCCCC"/>
    <path d="M46.9806 8.70074C46.9661 8.70666 46.9507 8.70971 46.9351 8.70971C46.9195 8.70971 46.904 8.70666 46.8896 8.70074V8.64696L46.5439 8.11806C45.7084 6.90907 44.9635 5.64173 44.3149 4.32609C44.0191 3.73305 43.8078 3.10256 43.6872 2.45252C43.5787 1.89434 43.5542 1.32346 43.6144 0.758241C43.6451 0.427612 43.7376 0.105438 43.8874 -0.19199C43.9047 -0.21965 43.93 -0.241521 43.9601 -0.254741C43.976 -0.261399 43.993 -0.264832 44.0102 -0.264832C44.0274 -0.264832 44.0444 -0.261399 44.0602 -0.254741L44.1603 -0.183025L44.5333 0.139694C44.8426 0.417592 45.161 0.69549 45.4431 0.991317C45.9247 1.45595 46.3547 1.96982 46.7258 2.52424C47.0979 3.08551 47.4031 3.6871 47.6356 4.31713C47.9741 5.17277 48.0562 6.10619 47.8721 7.00646C47.74 7.64152 47.4421 8.23166 47.0078 8.71867C46.9952 8.72498 46.9811 8.72826 46.9669 8.72826C46.9527 8.72826 46.9387 8.72498 46.926 8.71867L46.9806 8.70074ZM43.9783 -0.0216659C43.872 0.217459 43.8045 0.471563 43.7782 0.731348C43.7225 1.27282 43.747 1.8194 43.851 2.35391C43.9756 2.97473 44.1805 3.57726 44.4605 4.1468C45.1081 5.45254 45.8499 6.7108 46.6803 7.91187L46.9806 8.37802C47.3351 7.94204 47.5784 7.42868 47.6902 6.88096C47.8599 6.03483 47.7842 5.15869 47.4718 4.35299C47.2346 3.74485 46.9294 3.16457 46.5621 2.62285C46.1998 2.0805 45.7789 1.57853 45.3066 1.12578C45.0155 0.838922 44.697 0.561024 44.3968 0.29209L44.0147 -0.0395944L43.9783 -0.0216659Z" fill="#969696"/>
    <path d="M44.8417 6.4865C44.2701 4.90232 43.2382 3.5188 41.8745 2.50822C40.5108 1.49763 38.8756 0.904628 37.1724 0.80304C37.8269 2.31527 38.8299 3.65652 40.1018 4.7205C41.4214 5.84075 43.1 6.46614 44.8417 6.4865Z" fill="#CCCCCC"/>
    <path d="M44.841 6.59398C43.0721 6.57606 41.3669 5.9408 40.0283 4.80109C38.7384 3.73315 37.7249 2.37804 37.0716 0.847766C37.0602 0.830393 37.0542 0.810164 37.0542 0.789497C37.0542 0.76883 37.0602 0.7486 37.0716 0.731228C37.0834 0.716333 37.0986 0.704472 37.116 0.696665C37.1335 0.688857 37.1526 0.685336 37.1717 0.686406C38.8963 0.79087 40.5518 1.39192 41.933 2.41499C43.3141 3.43806 44.3601 4.83815 44.9411 6.44158C44.9499 6.45657 44.9545 6.47358 44.9545 6.49089C44.9545 6.5082 44.9499 6.5252 44.9411 6.54019C44.9331 6.55905 44.9188 6.57466 44.9006 6.58444C44.8824 6.59423 44.8614 6.59759 44.841 6.59398ZM37.3627 0.937411C38.0013 2.36386 38.963 3.62727 40.1739 4.63076C41.4249 5.70055 43.0125 6.31173 44.6681 6.3609C44.0927 4.87052 43.1016 3.57066 41.8091 2.61111C40.5166 1.65156 38.9753 1.07138 37.3627 0.937411Z" fill="#969696"/>
    <path d="M50.2454 43.7695C49.9098 43.5597 49.5359 43.4166 49.1446 43.3482C48.1606 43.1604 47.1423 43.2601 46.2151 43.6351C45.1155 44.0735 44.0563 44.6043 43.0491 45.2218C42.6216 45.4728 42.194 45.7327 41.73 46.0555C41.7895 46.0796 41.8448 46.1129 41.8937 46.1541C42.5001 46.7513 43.3116 47.1031 44.1682 47.1402C45.2094 47.1764 46.2382 46.9082 47.1249 46.3692C48.2669 45.7306 49.2862 44.8992 50.1362 43.913L50.2454 43.7695Z" fill="#CCCCCC"/>
    <path d="M44.4413 47.2568H44.1411C43.2561 47.2192 42.418 46.8543 41.7939 46.2348L41.7029 46.181H41.6484C41.6321 46.1699 41.6187 46.1552 41.6092 46.1381C41.5998 46.121 41.5945 46.1019 41.5938 46.0824C41.5934 46.0637 41.5973 46.0451 41.6052 46.028C41.613 46.011 41.6247 45.9958 41.6393 45.9838C42.1487 45.6432 42.549 45.3742 42.9675 45.1501C43.9832 44.5308 45.0516 43.9999 46.1608 43.5634C47.1081 43.1802 48.1482 43.0774 49.1539 43.2676C49.5561 43.3474 49.941 43.4961 50.2911 43.7068C50.3189 43.7261 50.3383 43.7549 50.3457 43.7875C50.3537 43.8011 50.3579 43.8166 50.3579 43.8323C50.3579 43.8481 50.3537 43.8635 50.3457 43.8772L50.2729 43.9668C49.4156 44.9637 48.3871 45.8042 47.2343 46.45C46.3929 46.9593 45.4287 47.2379 44.4413 47.2568ZM41.9395 46.0376C42.5213 46.6262 43.3082 46.9754 44.1411 47.0147C45.1592 47.0461 46.1646 46.7844 47.0342 46.2617C48.1638 45.6279 49.1709 44.8025 50.0091 43.8234C49.7227 43.6702 49.4167 43.5556 49.0993 43.4827C48.1391 43.3029 47.1464 43.4025 46.2427 43.7696C45.1446 44.2013 44.088 44.7294 43.0858 45.3473C42.7765 45.5087 42.3762 45.7418 41.9395 46.0376Z" fill="#969696"/>
    <path d="M53.4943 37.7813C53.1941 37.7365 52.9303 37.6916 52.6573 37.6737C52.0885 37.6208 51.5151 37.7007 50.9834 37.9068C50.6414 38.0304 50.3098 38.1802 49.9917 38.355C49.1494 38.8313 48.3753 39.4161 47.69 40.0941C47.126 40.62 46.5619 41.1579 45.9979 41.7077L45.8523 41.8512C46.3331 42.2073 46.9081 42.419 47.5081 42.4607C48.0836 42.4913 48.6594 42.4079 49.2017 42.2154C49.7439 42.023 50.2414 41.7253 50.665 41.3402C51.0731 40.9874 51.4619 40.6134 51.8294 40.2196C52.453 39.5246 52.9876 38.7569 53.4215 37.9337L53.4943 37.7813Z" fill="#CCCCCC"/>
    <path d="M47.7807 42.5863H47.4804C46.8616 42.5405 46.2686 42.323 45.7701 41.9588C45.7439 41.938 45.7249 41.9099 45.7155 41.8781C45.709 41.864 45.7056 41.8487 45.7056 41.8333C45.7056 41.8178 45.709 41.8025 45.7155 41.7884L45.761 41.7346L45.9611 41.636L46.2977 41.3223L47.6533 40.0224C48.3441 39.3278 49.124 38.7251 49.9732 38.2295C50.3014 38.0596 50.6384 37.907 50.983 37.7724C51.5339 37.5668 52.1249 37.4871 52.7116 37.5393L53.2756 37.611H53.5577C53.5749 37.6123 53.5914 37.6179 53.6058 37.6273C53.6201 37.6367 53.6318 37.6496 53.6396 37.6648C53.6528 37.6995 53.6528 37.7377 53.6396 37.7724V37.8441V37.9337C53.1992 38.7679 52.6553 39.5448 52.0202 40.2466L51.9747 40.2914C51.6191 40.673 51.2425 41.0352 50.8466 41.3761C50.0146 42.1437 48.9204 42.5756 47.7807 42.5863ZM46.0157 41.8333C46.4532 42.1343 46.9661 42.311 47.4986 42.3442C48.0574 42.3756 48.6169 42.2957 49.1436 42.1093C49.6704 41.923 50.1536 41.634 50.5645 41.2595C50.9557 40.9278 51.3287 40.5513 51.6836 40.1838V40.139C52.3057 39.4514 52.8375 38.6894 53.2665 37.871H53.1392C52.9481 37.871 52.7753 37.8172 52.5933 37.8082C52.0401 37.7524 51.4814 37.8293 50.9648 38.0323C50.6292 38.1535 50.3037 38.3004 49.9914 38.4716C49.1845 38.9451 48.4417 39.5174 47.7807 40.1748L46.416 41.4657L46.0794 41.7884L46.0157 41.8333Z" fill="#969696"/>
    <path d="M49.4914 14.5186C49.7544 14.3263 50.0065 14.1198 50.2465 13.9001C50.5464 13.6154 50.7847 13.2739 50.947 12.8961C51.2277 12.2986 51.3883 11.6532 51.4201 10.9956C51.4423 9.98085 51.2821 8.97023 50.947 8.01045C50.6696 7.14971 50.2942 6.3227 49.828 5.54523L49.737 5.40179L49.555 5.64383C48.9807 6.43312 48.6405 7.36427 48.5725 8.33317C48.5039 9.26374 48.5682 10.1992 48.7635 11.1121C48.9819 12.17 49.2184 13.2188 49.4459 14.2676C49.4459 14.3573 49.4641 14.4111 49.4914 14.5186Z" fill="#CCCCCC"/>
    <path d="M49.4913 14.635C49.4739 14.6278 49.4583 14.6168 49.4457 14.6028C49.4331 14.5889 49.4238 14.5723 49.4185 14.5544V14.4378C49.4207 14.402 49.4207 14.3661 49.4185 14.3303L49.2184 13.389C49.0546 12.6539 48.8909 11.9188 48.7453 11.1748C48.5437 10.2506 48.4763 9.30282 48.5452 8.35993C48.6105 7.35584 48.9604 6.38994 49.555 5.57199L49.737 5.32995C49.737 5.32995 49.8006 5.27616 49.8279 5.28513C49.8479 5.28506 49.8675 5.28995 49.885 5.29936C49.9025 5.30876 49.9173 5.32237 49.928 5.33891V5.40166L49.9826 5.48234C50.4536 6.26929 50.8349 7.10506 51.1198 7.97446C51.4592 8.94558 51.6194 9.96873 51.5929 10.9955C51.5447 11.6751 51.3624 12.3389 51.0561 12.9497C50.881 13.3378 50.6343 13.6905 50.3283 13.9896C50.1713 14.1472 50.0009 14.2911 49.8188 14.4199L49.5641 14.6171C49.5431 14.6322 49.517 14.6386 49.4913 14.635ZM49.7188 5.60785L49.6369 5.70646C49.0701 6.49754 48.7392 7.42909 48.6816 8.39579C48.6133 9.32041 48.6776 10.2499 48.8727 11.1568C49.0182 11.8919 49.182 12.636 49.3458 13.3711L49.5459 14.2675C49.5502 14.2821 49.5502 14.2977 49.5459 14.3123L49.6642 14.2227C49.8358 14.0946 49.9998 13.9569 50.1554 13.8103C50.441 13.5313 50.6722 13.2032 50.8378 12.8422C51.1035 12.2536 51.2547 11.6211 51.2836 10.9776C51.3085 9.97799 51.1514 8.98212 50.8196 8.03721C50.5439 7.18588 50.1715 6.36793 49.7097 5.59888L49.7188 5.60785Z" fill="#969696"/>
    <path d="M55.3687 31.291C54.9856 31.2621 54.6003 31.3016 54.2315 31.4076C53.1659 31.7009 52.2148 32.3038 51.5022 33.1377C50.6696 34.1146 49.921 35.158 49.2641 36.2573L48.8184 36.9655C48.9912 37.0193 49.1368 37.0821 49.2914 37.1179C49.8941 37.2607 50.5258 37.2296 51.111 37.0283C52.1257 36.6614 52.9913 35.9785 53.5764 35.083C54.3568 33.9929 54.9443 32.7805 55.3141 31.4972V31.3896C55.3402 31.3616 55.3589 31.3278 55.3687 31.291Z" fill="#CCCCCC"/>
    <path d="M50.0554 37.3152C49.7886 37.3133 49.5229 37.2802 49.2639 37.2166C49.1552 37.1888 49.0487 37.1528 48.9455 37.109L48.7818 37.0552C48.7642 37.0509 48.7481 37.0419 48.7353 37.0293C48.7225 37.0167 48.7134 37.0008 48.709 36.9835C48.7022 36.9679 48.6987 36.9511 48.6987 36.9342C48.6987 36.9172 48.7022 36.9005 48.709 36.8849L48.8909 36.607L49.1548 36.1767C49.8203 35.0792 50.5781 34.0387 51.4201 33.066C52.1249 32.2102 53.0766 31.5851 54.1494 31.2731C54.5358 31.1614 54.9406 31.1249 55.3412 31.1656C55.367 31.1676 55.3913 31.1787 55.4096 31.1967C55.4279 31.2148 55.4392 31.2387 55.4412 31.2642C55.4461 31.274 55.4486 31.2847 55.4486 31.2956C55.4486 31.3064 55.4461 31.3172 55.4412 31.3269V31.4166C55.0695 32.7146 54.479 33.9419 53.6945 35.0472C53.1114 35.9991 52.2302 36.7384 51.1835 37.1538C50.8184 37.2657 50.4377 37.3201 50.0554 37.3152ZM49.0001 36.8849L49.3003 36.9835C49.9023 37.1332 50.536 37.1019 51.1198 36.8939C52.1108 36.5292 52.9562 35.8597 53.5307 34.9844C54.3031 33.8934 54.8815 32.6809 55.2411 31.3986C54.9093 31.3843 54.5774 31.4237 54.2585 31.5152C53.2289 31.8109 52.313 32.4042 51.6293 33.2184C50.7905 34.184 50.0329 35.2154 49.364 36.3022L49.1002 36.7235L49.0001 36.8849Z" fill="#969696"/>
    <path d="M35.562 44.1371H35.4892C34.3128 44.9279 33.1969 45.8028 32.1504 46.7547C31.3875 47.4505 30.7962 48.3091 30.4219 49.2647C30.2537 49.6943 30.1614 50.1491 30.1489 50.6094C30.1489 50.6094 30.1489 50.699 30.1489 50.7618L30.431 50.6901C31.7258 50.3312 32.935 49.7217 33.9881 48.8972C34.6512 48.405 35.1795 47.7581 35.5256 47.0146C35.8858 46.1592 35.9213 45.2045 35.6257 44.3253C35.6075 44.2357 35.5802 44.1908 35.562 44.1371Z" fill="#CCCCCC"/>
    <path d="M30.1576 50.9142H30.0848C30.0736 50.8853 30.0736 50.8534 30.0848 50.8246V50.7708C30.0803 50.735 30.0803 50.6989 30.0848 50.6632C30.0996 50.1915 30.1918 49.7253 30.3577 49.2827C30.7382 48.3083 31.3423 47.4339 32.1227 46.7278C33.168 45.7682 34.2873 44.8901 35.4706 44.1012H35.5343C35.5501 44.0946 35.5671 44.0911 35.5843 44.0911C35.6015 44.0911 35.6186 44.0946 35.6344 44.1012C35.6489 44.1066 35.6621 44.115 35.6731 44.1258C35.6841 44.1366 35.6926 44.1496 35.6981 44.164C35.7018 44.2027 35.7018 44.2417 35.6981 44.2805C36.0172 45.202 35.9815 46.207 35.598 47.1043C35.2559 47.8801 34.7171 48.5562 34.0332 49.0675C32.9521 49.8958 31.7153 50.5052 30.3941 50.8604H30.2031H30.103L30.1576 50.9142ZM35.507 44.3253C34.348 45.0962 33.2528 45.9565 32.2319 46.8981C31.4669 47.5692 30.8719 48.4073 30.4942 49.3454C30.3315 49.7638 30.2422 50.2064 30.2304 50.6542H30.3759C31.6559 50.2927 32.8498 49.6832 33.8876 48.8613C34.5494 48.3852 35.0758 47.7492 35.416 47.0147C35.785 46.1606 35.8174 45.2017 35.507 44.3253Z" fill="#969696"/>
    <path d="M39.5928 41.4119C38.4644 42.6268 37.5178 43.9942 36.7817 45.4728C36.3294 46.4163 36.1742 47.4716 36.3359 48.5028C36.3359 48.6462 36.3996 48.7896 36.4269 48.942H36.5087C37.7626 48.2333 38.8214 47.2331 39.5928 46.0286C40.0655 45.3179 40.3125 44.4847 40.3025 43.6351C40.2933 42.8772 40.0662 42.1374 39.6474 41.5015L39.5928 41.4119Z" fill="#CCCCCC"/>
    <path d="M36.4635 49.0496C36.443 49.0572 36.4204 49.0572 36.3998 49.0496C36.3835 49.0448 36.3688 49.0355 36.3575 49.0228C36.3463 49.0101 36.3388 48.9945 36.3361 48.9779V48.8345C36.3361 48.7358 36.2907 48.6372 36.2725 48.5386C36.1127 47.4831 36.2708 46.4047 36.7273 45.4369C37.473 43.9513 38.4253 42.5755 39.5567 41.3491C39.5711 41.3432 39.5866 41.3402 39.6022 41.3402C39.6178 41.3402 39.6333 41.3432 39.6477 41.3491C39.6654 41.3488 39.683 41.3527 39.6988 41.3605C39.7147 41.3683 39.7283 41.3798 39.7387 41.394L39.7842 41.4567C40.2277 42.1014 40.4769 42.8567 40.5029 43.6351C40.5187 44.5076 40.2648 45.3643 39.7751 46.0913C38.9915 47.3096 37.9129 48.3169 36.6364 49.0227H36.5454L36.4635 49.0496ZM39.5931 41.636C38.4991 42.8074 37.5801 44.1265 36.8638 45.5535C36.4169 46.4794 36.2647 47.5169 36.4271 48.5297C36.4271 48.6193 36.4271 48.709 36.4817 48.8076C37.7029 48.1278 38.7343 47.1608 39.4839 45.9927C39.9469 45.3053 40.188 44.4959 40.1754 43.6709C40.1756 42.9527 39.9741 42.2484 39.5931 41.636Z" fill="#969696"/>
    <path d="M38.8828 8.09989L40.1838 8.63776C40.9956 8.97762 41.8357 9.24756 42.6947 9.44456C43.9901 9.7746 45.3652 9.59116 46.5248 8.93358C46.634 8.87083 46.725 8.79912 46.8524 8.71844L46.6249 8.51225C45.6362 7.62426 44.4388 6.9927 43.1405 6.67454C42.572 6.51765 41.9752 6.48693 41.3931 6.58458C40.8111 6.68224 40.2582 6.90584 39.7744 7.2393C39.4318 7.47657 39.1306 7.76724 38.8828 8.09989Z" fill="#CCCCCC"/>
    <path d="M44.0321 9.71356C43.573 9.70963 43.1157 9.65855 42.6674 9.56117C41.8034 9.35692 40.9576 9.08411 40.1383 8.7454L39.2922 8.39579L38.8373 8.20753C38.8067 8.18926 38.7841 8.16054 38.7736 8.12685C38.7656 8.11161 38.7615 8.0947 38.7615 8.07755C38.7615 8.0604 38.7656 8.04349 38.7736 8.02825C39.0192 7.67755 39.3275 7.3738 39.6834 7.1318C40.1774 6.78336 40.7448 6.54921 41.3433 6.44677C41.9419 6.34433 42.5562 6.37625 43.1405 6.54015C44.4727 6.88214 45.698 7.54436 46.7068 8.4675L46.8523 8.61093L46.9342 8.68265C46.9407 8.69671 46.9441 8.712 46.9441 8.72747C46.9441 8.74294 46.9407 8.75823 46.9342 8.77229C46.9346 8.78975 46.9306 8.80704 46.9227 8.82266C46.9148 8.83827 46.9031 8.85176 46.8887 8.86194L46.7704 8.94262L46.5612 9.07709C45.7912 9.50844 44.9177 9.72824 44.0321 9.71356ZM39.0647 8.04617L39.3832 8.18064L40.2292 8.53025C41.0329 8.87271 41.8675 9.13984 42.722 9.32809C43.9889 9.65124 45.3337 9.47434 46.4702 8.83505L46.6613 8.70058L46.5521 8.60197C45.5767 7.71605 44.3961 7.07896 43.1132 6.74633C42.5613 6.59204 41.9813 6.56189 41.416 6.65809C40.8507 6.75429 40.3145 6.97439 39.8471 7.30213C39.55 7.51013 39.2862 7.76095 39.0647 8.04617Z" fill="#969696"/>
    <path d="M42.768 37.8619L42.3222 38.8659C41.8262 39.8972 41.4478 40.9796 41.1941 42.0931C40.9309 43.2488 41.0456 44.4569 41.5216 45.5444C41.5216 45.6251 41.6035 45.7058 41.649 45.7865V45.8403L41.7218 45.7865C42.6938 44.8426 43.4236 43.6841 43.8506 42.4069C44.0761 41.784 44.1515 41.118 44.071 40.4615C43.9905 39.805 43.7562 39.1759 43.3866 38.6239C43.232 38.355 42.9863 38.1308 42.768 37.8619Z" fill="#CCCCCC"/>
    <path d="M41.6396 45.9479C41.6253 45.9544 41.6098 45.9577 41.5941 45.9577C41.5784 45.9577 41.5629 45.9544 41.5486 45.9479C41.5276 45.9263 41.5092 45.9022 41.494 45.8762C41.457 45.8093 41.4236 45.7405 41.3939 45.67C40.9012 44.565 40.78 43.3337 41.0482 42.156C41.3033 41.033 41.6847 39.9415 42.1854 38.9019L42.4948 38.2744L42.6767 37.8979C42.6848 37.8803 42.6975 37.8653 42.7135 37.8542C42.7295 37.8432 42.7482 37.8366 42.7677 37.8351C42.7845 37.8265 42.8033 37.822 42.8223 37.822C42.8413 37.822 42.86 37.8265 42.8769 37.8351L43.0861 38.0861C43.2353 38.2493 43.3721 38.4231 43.4955 38.6061C43.8786 39.1707 44.1218 39.8161 44.2056 40.4903C44.2893 41.1645 44.2113 41.8486 43.9777 42.4877C43.5454 43.7818 42.8063 44.9557 41.8215 45.9121L41.7397 45.9748L41.6396 45.9479ZM42.795 38.0503L42.6858 38.2654C42.5766 38.4806 42.4766 38.6867 42.3856 38.8929C41.8884 39.9144 41.51 40.988 41.2575 42.0932C41.0069 43.2259 41.1214 44.4074 41.585 45.4728C41.6098 45.5323 41.6403 45.5893 41.676 45.6432C42.617 44.7196 43.3217 43.5889 43.732 42.3442C43.9558 41.7386 44.032 41.0897 43.9545 40.4497C43.8771 39.8098 43.6482 39.1967 43.2863 38.6598C43.1635 38.4874 43.0298 38.3227 42.886 38.1668C42.8535 38.1297 42.8231 38.0907 42.795 38.0503Z" fill="#969696"/>
    <path d="M45.7344 41.7974C45.9801 41.2864 46.2166 40.8203 46.4258 40.3452C46.5381 40.1062 46.6265 39.857 46.6897 39.6011C46.8207 39.1232 46.9239 38.6383 46.999 38.1489C47.1453 37.333 47.0438 36.4928 46.7071 35.7336C46.3705 34.9744 45.8137 34.3302 45.1067 33.8818L44.9702 33.8011C44.8701 34.2404 44.7701 34.6976 44.6791 35.1099C44.4619 36.1267 44.3582 37.1637 44.3698 38.2027C44.3844 39.3876 44.7758 40.5383 45.4888 41.4926C45.5616 41.5823 45.6343 41.636 45.7344 41.7974Z" fill="#CCCCCC"/>
    <path d="M45.7341 41.9139C45.7164 41.9143 45.6989 41.9104 45.683 41.9026C45.6672 41.8948 45.6535 41.8833 45.6431 41.8691L45.5613 41.7705C45.5031 41.7015 45.4484 41.6297 45.3975 41.5553C44.6665 40.5847 44.2653 39.4113 44.2512 38.2026C44.2347 37.1546 44.3385 36.1081 44.5605 35.083C44.6242 34.7872 44.697 34.4914 44.7607 34.1866L44.8608 33.7742C44.8629 33.7563 44.8699 33.7393 44.8812 33.7251C44.8924 33.7109 44.9074 33.7 44.9244 33.6935C44.9596 33.6804 44.9984 33.6804 45.0336 33.6935H45.0882L45.1701 33.7473C45.8959 34.2086 46.4676 34.8704 46.8139 35.65C47.1602 36.4297 47.2656 37.2926 47.117 38.1309C47.0601 38.5684 46.9781 39.0024 46.8713 39.4308V39.628C46.8019 39.889 46.7107 40.1439 46.5984 40.39L46.1253 41.394L45.907 41.8512C45.8976 41.8677 45.8846 41.882 45.8688 41.8928C45.853 41.9037 45.835 41.9109 45.816 41.9139H45.7341ZM45.0518 33.9804L44.9972 34.2404C44.9244 34.5362 44.8608 34.832 44.7971 35.1368C44.5756 36.1438 44.4719 37.1724 44.4877 38.2026C44.5006 39.3658 44.8858 40.4952 45.5886 41.4298L45.7068 41.5912L45.8433 41.3043C46.0162 40.9637 46.1708 40.641 46.3164 40.3093C46.4267 40.0767 46.5121 39.8333 46.5711 39.5832L46.6257 39.386C46.7349 38.9763 46.817 38.5601 46.8713 38.1399C47.0131 37.3472 46.9164 36.5311 46.593 35.7919C46.2697 35.0528 45.734 34.4231 45.0518 33.9804Z" fill="#969696"/>
    <path d="M42.4124 11.5425C42.7399 11.8473 43.0492 12.17 43.3858 12.4389C43.9499 12.9051 44.5048 13.3354 45.0962 13.7836C46.0227 14.4648 47.1624 14.8042 48.3167 14.7428C48.6442 14.7428 48.9718 14.6442 49.3357 14.5993C49.3357 14.5187 49.3357 14.4738 49.272 14.438C49.09 14.1422 48.9081 13.8374 48.717 13.5415C48.5224 13.2538 48.3097 12.9784 48.0802 12.7168C47.6613 12.2124 47.1639 11.7767 46.6064 11.4259C46.0203 11.0256 45.3263 10.8067 44.6132 10.7971C43.9001 10.7875 43.2004 10.9877 42.6034 11.3721C42.5515 11.3998 42.5027 11.4329 42.4578 11.4708C42.4578 11.4708 42.4396 11.5156 42.4124 11.5425Z" fill="#CCCCCC"/>
    <path d="M47.9984 14.8772C46.9302 14.8478 45.8987 14.487 45.0507 13.8463C44.4776 13.4429 43.9135 12.9498 43.3768 12.5375C43.1402 12.3402 42.9219 12.1251 42.7035 11.9189L42.3214 11.6231C42.3115 11.6139 42.3036 11.6028 42.2982 11.5904C42.2928 11.5781 42.29 11.5648 42.29 11.5514C42.29 11.5379 42.2928 11.5247 42.2982 11.5123C42.3036 11.5 42.3115 11.4889 42.3214 11.4797L42.376 11.4169L42.5307 11.3183C43.1471 10.9231 43.8688 10.7172 44.6041 10.7268C45.3395 10.7363 46.0554 10.9609 46.661 11.3721C47.2264 11.7301 47.7326 12.1715 48.1621 12.6809C48.3987 12.9588 48.617 13.2636 48.799 13.5235C48.9809 13.7835 49.0901 13.9718 49.2265 14.1959L49.363 14.42C49.3646 14.4528 49.3646 14.4858 49.363 14.5186V14.5903C49.3698 14.6059 49.3733 14.6227 49.3733 14.6396C49.3733 14.6566 49.3698 14.6733 49.363 14.6889C49.3545 14.7054 49.3414 14.7192 49.3254 14.7287C49.3093 14.7382 49.2908 14.7431 49.272 14.7427L48.9354 14.7965C48.7106 14.8433 48.4825 14.8733 48.2531 14.8861L47.9984 14.8772ZM42.5853 11.5424L42.8036 11.7486C43.0128 11.9548 43.2403 12.1699 43.4677 12.3582C44.0045 12.7974 44.5595 13.2546 45.1235 13.658C46.028 14.3263 47.1424 14.6596 48.2713 14.5993C48.4896 14.5993 48.708 14.5455 48.9354 14.5096H49.1356L48.9991 14.2855C48.8626 14.0704 48.7262 13.8373 48.5806 13.6222C48.3867 13.3391 48.1771 13.0668 47.9529 12.8064C47.5372 12.3165 47.0496 11.8905 46.5063 11.5424C45.9412 11.1494 45.2686 10.9339 44.577 10.9243C43.8853 10.9147 43.2069 11.1114 42.6307 11.4886L42.5853 11.5424Z" fill="#969696"/>
    <path d="M44.7512 15.5674C44.915 15.8184 45.0696 16.0605 45.2334 16.2936C45.804 17.1762 46.4756 17.9913 47.2349 18.7229C48.1301 19.602 49.3373 20.1036 50.601 20.1214H50.8194C50.824 20.0917 50.824 20.0614 50.8194 20.0317C50.4581 18.61 49.702 17.3156 48.6359 16.2936C48.1536 15.8187 47.5403 15.4942 46.8722 15.3603C46.2041 15.2264 45.5108 15.2891 44.8786 15.5405H44.8149C44.8149 15.5405 44.7876 15.5405 44.7512 15.5674Z" fill="#CCCCCC"/>
    <path d="M50.6105 20.202C49.3208 20.1929 48.085 19.6916 47.1625 18.8036C46.3918 18.0709 45.7137 17.2492 45.1428 16.3563L44.8062 15.8543L44.6515 15.6391C44.645 15.6251 44.6416 15.6098 44.6416 15.5943C44.6416 15.5789 44.645 15.5636 44.6515 15.5495C44.6589 15.5169 44.6784 15.4881 44.7061 15.4688H44.788C45.4395 15.2087 46.1543 15.1431 46.8434 15.2801C47.5325 15.4172 48.1655 15.7509 48.6636 16.2398C49.7455 17.2805 50.5138 18.5961 50.8834 20.0407C50.8925 20.0699 50.8925 20.1011 50.8834 20.1303C50.8845 20.1629 50.8742 20.1949 50.8544 20.2209C50.8345 20.247 50.8063 20.2656 50.7742 20.2738H50.5559L50.6105 20.202ZM44.9154 15.6391L44.9972 15.7557C45.1064 15.926 45.2156 16.0963 45.3339 16.2577C45.8954 17.1335 46.5612 17.9401 47.3171 18.6602C48.1931 19.5106 49.3717 19.9899 50.6014 19.9959H50.6833C50.3278 18.6145 49.5947 17.3556 48.5635 16.3563C48.0954 15.898 47.5012 15.5849 46.8544 15.4559C46.2076 15.3268 45.5367 15.3874 44.9245 15.6302L44.9154 15.6391Z" fill="#969696"/>
    <path d="M46.2979 29.3279C46.2979 29.4982 46.2979 29.6506 46.2979 29.803C46.2299 31.1361 46.3462 32.472 46.6436 33.7742C46.9123 34.9246 47.5504 35.9589 48.4631 36.7236L48.6814 36.8759C48.7269 36.7146 48.7724 36.5801 48.8179 36.4367C49.1362 35.3241 49.2471 34.1639 49.1454 33.0123C49.1007 32.2082 48.8186 31.4346 48.3337 30.7864C47.8488 30.1382 47.1823 29.6438 46.4161 29.3637L46.2979 29.3279Z" fill="#CCCCCC"/>
    <path d="M48.6908 36.9924C48.6674 37.0014 48.6414 37.0014 48.618 36.9924H48.5452L48.3996 36.8849C47.4518 36.1115 46.7872 35.0536 46.5073 33.8728C46.2084 32.5617 46.0921 31.2167 46.1616 29.8747C46.1616 29.7671 46.1616 29.6595 46.1616 29.5519V29.3906C46.1504 29.3617 46.1504 29.3298 46.1616 29.3009C46.1938 29.2884 46.2295 29.2884 46.2617 29.3009H46.3254H46.4254C47.1982 29.6003 47.8668 30.1131 48.351 30.7778C48.8351 31.4426 49.1142 32.2309 49.1547 33.0481C49.262 34.2121 49.1478 35.3856 48.8181 36.5083C48.8181 36.607 48.7635 36.7056 48.7271 36.8131V36.9566C48.7227 36.9739 48.7136 36.9897 48.7008 37.0024C48.688 37.015 48.6719 37.0239 48.6544 37.0283L48.6908 36.9924ZM46.4163 29.4802C46.4163 29.5878 46.4163 29.6954 46.4163 29.8029C46.3438 31.1238 46.4571 32.4481 46.753 33.7383C47.027 34.869 47.6654 35.8817 48.5725 36.6249L48.6544 36.6787L48.7362 36.3918C49.0578 35.2982 49.1689 34.155 49.0638 33.0212C49.033 32.2359 48.7681 31.477 48.3021 30.8392C47.8361 30.2015 47.1897 29.7132 46.4436 29.4354L46.4163 29.4802Z" fill="#969696"/>
    <path d="M46.697 24.6753C46.8699 26.0052 47.209 27.309 47.7069 28.5569C47.9855 29.2213 48.3865 29.8291 48.8896 30.3498C49.3095 30.7836 49.8058 31.139 50.3543 31.3986H50.4453H50.5089C50.5089 31.2821 50.5089 31.1655 50.5089 31.049C50.6447 29.7806 50.4956 28.4982 50.0722 27.2929C49.8542 26.5956 49.4356 25.9755 48.8672 25.5077C48.2989 25.04 47.6051 24.7446 46.8699 24.6573H46.7789L46.697 24.6753Z" fill="#CCCCCC"/>
    <path d="M50.5094 31.5689H50.4184H50.3092C49.7439 31.3078 49.2344 30.9427 48.8081 30.4932C48.3017 29.9715 47.8949 29.3642 47.6072 28.7003C47.1075 27.4401 46.7654 26.1246 46.5883 24.7828C46.5849 24.7558 46.5912 24.7284 46.6062 24.7055C46.6211 24.6826 46.6438 24.6655 46.6702 24.6573V24.7739V24.6484H46.8703C47.6307 24.7288 48.3507 25.0261 48.9422 25.5038C49.5336 25.9815 49.9708 26.6188 50.2001 27.3377C50.6532 28.583 50.8209 29.9116 50.6913 31.2283V31.4793V31.5869C50.68 31.6008 50.6652 31.6117 50.6485 31.6186C50.6317 31.6255 50.6135 31.6282 50.5954 31.6264C50.5774 31.6247 50.56 31.6185 50.545 31.6084C50.53 31.5984 50.5177 31.5848 50.5094 31.5689ZM46.8703 24.7739C47.0191 26.0516 47.3246 27.3068 47.7801 28.5121C48.0469 29.1762 48.4389 29.7846 48.9355 30.3049C49.3416 30.7317 49.8263 31.0785 50.3638 31.3269V31.1207C50.5263 29.8314 50.3894 28.5225 49.9635 27.2929C49.7472 26.6251 49.3406 26.0326 48.7922 25.586C48.2438 25.1394 47.5767 24.8575 46.8703 24.7739Z" fill="#969696"/>
    <path d="M51.256 25.7869C51.2014 25.4104 51.156 25.0428 51.0923 24.6932C50.9281 23.6882 50.5699 22.7235 50.037 21.8515C49.6866 21.1988 49.1404 20.6685 48.4728 20.3332C47.8051 19.9979 47.0488 19.8738 46.3069 19.9779H46.2341C46.2978 20.1393 46.3524 20.3096 46.4161 20.471C46.8019 21.6101 47.3306 22.6972 47.99 23.7071C48.715 24.7898 49.8418 25.5493 51.1287 25.8227L51.256 25.7869Z" fill="#CCCCCC"/>
    <path d="M51.2556 25.9033H51.1009C49.7795 25.6148 48.6261 24.826 47.8895 23.7071C47.2129 22.7244 46.6657 21.6611 46.261 20.5426C46.261 20.4261 46.17 20.3006 46.1336 20.184L46.079 20.0406C46.0649 20.0209 46.0574 19.9974 46.0574 19.9734C46.0574 19.9493 46.0649 19.9258 46.079 19.9061C46.079 19.9061 46.1427 19.8613 46.1609 19.8613H46.2519C47.0161 19.759 47.7938 19.8887 48.4814 20.2331C49.1689 20.5774 49.7336 21.12 50.1002 21.7887C50.6401 22.6753 51.0044 23.6548 51.1737 24.6752C51.1737 24.9173 51.2465 25.1593 51.2829 25.4193L51.3375 25.7689C51.3502 25.8006 51.3502 25.8358 51.3375 25.8675C51.3276 25.8793 51.3151 25.8887 51.3008 25.895C51.2866 25.9012 51.2711 25.9041 51.2556 25.9033ZM46.3338 20.1213C46.3338 20.2378 46.4157 20.3633 46.4702 20.4799C46.8678 21.5823 47.4059 22.6306 48.0714 23.5995C48.7715 24.6464 49.8583 25.3829 51.1009 25.6523V25.4462C51.1009 25.1952 51.0282 24.9531 50.9827 24.7111C50.8171 23.7218 50.462 22.7727 49.9364 21.9142C49.591 21.2909 49.0615 20.7857 48.4181 20.4655C47.7748 20.1453 47.0479 20.0253 46.3338 20.1213Z" fill="#969696"/>
    <path d="M24.8814 46.3962C20.6872 46.3962 16.5871 45.1706 13.0997 42.8745C9.61226 40.5784 6.89415 37.3149 5.28907 33.4966C3.68399 29.6784 3.26403 25.4768 4.08229 21.4234C4.90056 17.3699 6.92029 13.6466 9.88609 10.7242C12.8519 7.80185 16.6306 5.81169 20.7442 5.0054C24.8579 4.19912 29.1219 4.61294 32.9969 6.19451C36.8719 7.77609 40.1839 10.4544 42.5141 13.8908C44.8443 17.3271 46.0881 21.3672 46.0881 25.5C46.0808 31.0398 43.8443 36.3507 39.8688 40.268C35.8934 44.1852 30.5036 46.389 24.8814 46.3962ZM24.8814 5.02522C20.7717 5.02522 16.7543 6.22605 13.3372 8.47585C9.92013 10.7256 7.25683 13.9234 5.68411 17.6647C4.1114 21.4059 3.6999 25.5227 4.50167 29.4945C5.30343 33.4662 7.28244 37.1144 10.1884 39.9779C13.0944 42.8413 16.7969 44.7914 20.8277 45.5814C24.8584 46.3714 29.0364 45.966 32.8332 44.4163C36.6301 42.8666 39.8754 40.2423 42.1586 36.8752C44.4418 33.5082 45.6605 29.5496 45.6605 25.5C45.6557 20.0712 43.4649 14.8661 39.5691 11.0274C35.6733 7.18865 30.3909 5.02997 24.8814 5.02522Z" fill="#E83F40"/>
    <path d="M24.8817 44.1909C21.1301 44.1909 17.4627 43.0947 14.3433 41.0409C11.2239 38.9871 8.79268 36.068 7.35699 32.6527C5.92131 29.2374 5.54566 25.4793 6.27757 21.8536C7.00948 18.2279 8.81606 14.8975 11.4689 12.2836C14.1217 9.66962 17.5016 7.88948 21.1811 7.16829C24.8607 6.4471 28.6746 6.81724 32.1407 8.23191C35.6067 9.64658 38.5692 12.0422 40.6535 15.1159C42.7378 18.1896 43.8503 21.8033 43.8503 25.5C43.8431 30.455 41.8423 35.2049 38.2865 38.7086C34.7308 42.2123 29.9103 44.1838 24.8817 44.1909ZM24.8817 7.23944C21.2164 7.23944 17.6335 8.31041 14.5859 10.3169C11.5384 12.3234 9.16308 15.1753 7.76044 18.512C6.3578 21.8487 5.99081 25.5203 6.70587 29.0625C7.42093 32.6047 9.18592 35.8584 11.7777 38.4122C14.3694 40.966 17.6715 42.7052 21.2663 43.4097C24.8611 44.1143 28.5873 43.7527 31.9736 42.3706C35.3598 40.9885 38.2541 38.648 40.2904 35.6451C42.3267 32.6421 43.4136 29.1116 43.4136 25.5C43.4088 20.6585 41.4548 16.0166 37.9804 12.5931C34.506 9.16959 29.7952 7.24419 24.8817 7.23944Z" fill="#CCCCCC"/>
    <path d="M16.6575 36.3021C17.3853 35.6029 17.1033 34.8589 16.4574 34.2134L15.4566 33.2004L12.4817 36.0691L13.1458 36.7414L14.1193 35.7643L14.4741 36.1228C14.5933 36.2925 14.7492 36.434 14.9305 36.5373C15.1119 36.6405 15.3142 36.703 15.5229 36.7201C15.7316 36.7373 15.9415 36.7087 16.1377 36.6364C16.3339 36.5641 16.5114 36.45 16.6575 36.3021ZM15.029 35.5671L14.7015 35.2443L15.6113 34.3479L15.9115 34.6527C16.2754 35.0113 16.3573 35.316 16.048 35.6119C15.7386 35.9077 15.3747 35.9346 15.029 35.5671Z" fill="#747778"/>
    <path d="M15.511 38.7853L16.348 39.2425L17.0576 38.5164L18.1675 39.1259L17.9128 40.1031L18.777 40.5782L19.6868 36.3649L18.6679 35.827L15.511 38.7853ZM18.8225 36.7055C18.7043 37.1358 18.5587 37.602 18.4586 38.0233L18.3585 38.4088L17.5852 37.9785L17.8582 37.6916C18.1584 37.3868 18.495 37.0283 18.7679 36.6966L18.8225 36.7055Z" fill="#747778"/>
    <path d="M22.3069 37.2165C21.8162 37.1126 21.3037 37.1925 20.8695 37.4406L21.2061 38.1399C21.4843 37.9924 21.8049 37.9418 22.1159 37.9964C22.4525 38.0592 22.6617 38.2385 22.598 38.5522C22.5344 38.866 22.316 38.9736 21.8338 38.875L21.3972 38.7943L21.2607 39.4576L21.8066 39.5742C22.3069 39.6728 22.5162 39.87 22.4525 40.2107C22.3888 40.5513 22.0431 40.6858 21.6246 40.6051C21.2558 40.5297 20.9302 40.318 20.7148 40.0134L20.0962 40.5334C20.422 41.0014 20.9223 41.3236 21.4881 41.4298C22.3979 41.6091 23.1803 41.3222 23.3532 40.5334C23.4029 40.3215 23.3789 40.0992 23.2848 39.9024C23.1907 39.7056 23.0321 39.5458 22.8346 39.4487C23.0149 39.4062 23.1777 39.3105 23.3013 39.1743C23.4249 39.0381 23.5033 38.868 23.526 38.6867C23.6989 37.9158 23.2713 37.4048 22.3069 37.2165Z" fill="#747778"/>
    <path d="M26.0919 37.3958L24.6636 37.5482L25.1094 41.636L26.0191 41.5374L25.8645 40.1479L26.383 40.0941C27.2928 40.0045 27.9569 39.5024 27.8478 38.5432C27.7386 37.5841 27.0017 37.2972 26.0919 37.3958ZM26.2739 39.359L25.8099 39.4128L25.6825 38.1757H26.1101C26.6196 38.1219 26.9107 38.2474 26.9562 38.6777C27.0017 39.108 26.7833 39.3052 26.2739 39.359Z" fill="#747778"/>
    <path d="M29.585 36.5084C28.7844 36.867 28.2567 37.4048 28.6752 38.3013C28.7733 38.5426 28.9472 38.7468 29.1715 38.8839C29.3957 39.021 29.6585 39.0838 29.9216 39.0632L29.585 41.0085L30.5584 40.5603L30.7404 38.8571L31.168 38.6598L31.7957 40.0045L32.6509 39.619L30.9132 35.8898L29.585 36.5084ZM30.4947 38.1937C30.0399 38.3999 29.7123 38.3192 29.585 37.9517C29.4576 37.5841 29.585 37.351 30.049 37.1449L30.4856 36.9476L30.9769 37.9965L30.4947 38.1937Z" fill="#747778"/>
    <path d="M37.0367 35.6657L36.6273 35.2534L36.2634 35.603L33.9162 33.2095L32.0967 34.9038C32.5516 35.5582 32.7881 35.8988 33.1975 36.5084C33.7525 37.36 33.8616 37.7634 33.7706 37.9427L33.6342 38.0682L34.0436 38.4895L35.0716 39.386L35.6812 38.8033L34.8442 37.9068L36.4818 36.356L37.3096 37.2076L37.9192 36.6339L37.0367 35.6657ZM34.5258 37.2524C34.3912 36.8122 34.1887 36.3949 33.9253 36.0153C33.6433 35.594 33.4886 35.3878 33.2066 34.9665L33.7888 34.4107L35.6084 36.2484L34.5258 37.2524Z" fill="#747778"/>
    <path d="M16.7581 35.836C17.4859 35.1368 17.2039 34.3927 16.5579 33.7473L15.5572 32.7433L12.5823 35.6119L13.21 36.2573L14.2199 35.2802L14.5747 35.6477C14.6932 35.818 14.8485 35.9603 15.0295 36.0644C15.2104 36.1685 15.4125 36.2318 15.6213 36.2498C15.83 36.2678 16.0402 36.24 16.2368 36.1685C16.4333 36.0969 16.6114 35.9834 16.7581 35.836ZM15.1114 35.1099L14.7839 34.7871L15.6937 33.8907L15.9939 34.1955C16.3578 34.5541 16.4397 34.8589 16.1213 35.1637C15.8028 35.4684 15.4662 35.4774 15.1114 35.1099Z" fill="#DBDBDB"/>
    <path d="M15.5208 38.4088L16.3668 38.866L17.0764 38.1399L18.1864 38.7495L17.9225 39.7266L18.8323 40.2017L19.7421 35.9884L18.6685 35.4506L15.5208 38.4088ZM18.8323 36.3291C18.714 36.7594 18.5776 37.2255 18.4775 37.6469L18.3774 38.0323L17.595 37.602L17.8679 37.3152C18.1773 37.0104 18.5048 36.6518 18.7777 36.3201L18.8323 36.3291Z" fill="#DBDBDB"/>
    <path d="M22.4074 36.831C21.9166 36.7271 21.4042 36.807 20.9699 37.0551L21.3157 37.7544C21.5929 37.6041 21.9145 37.5534 22.2254 37.6109C22.562 37.6737 22.7713 37.853 22.7076 38.1667C22.6439 38.4805 22.4347 38.5881 21.9434 38.4895L21.5249 38.4088L21.3793 39.0721L21.9252 39.1887C22.4347 39.2873 22.6439 39.4845 22.5711 39.8252C22.4984 40.1658 22.1617 40.3003 21.7432 40.2196C21.3714 40.1455 21.0444 39.9296 20.8335 39.619L20.2148 40.1479C20.5399 40.6126 21.0359 40.9341 21.5977 41.0443C22.5075 41.2236 23.2989 40.9367 23.4172 40.1479C23.4704 39.9359 23.4479 39.7123 23.3535 39.5148C23.259 39.3172 23.0984 39.1577 22.8987 39.0632C23.0806 39.0223 23.2455 38.9273 23.3708 38.791C23.4961 38.6547 23.5758 38.4837 23.5992 38.3012C23.772 37.5303 23.3444 37.0193 22.4074 36.831Z" fill="#DBDBDB"/>
    <path d="M26.0376 37.0731L24.6184 37.2255L25.0369 41.3133L25.9467 41.2147L25.8466 39.8432L26.3561 39.7894C27.2658 39.6997 27.939 39.1977 27.8299 38.2385C27.7207 37.2793 26.9565 36.9835 26.0376 37.0731ZM26.2196 39.0453H25.7647L25.6373 37.8172H26.0649C26.5744 37.7634 26.8655 37.8889 26.911 38.3102C26.9565 38.7316 26.7382 38.9915 26.2196 39.0453Z" fill="#DBDBDB"/>
    <path d="M29.585 36.1318C28.7844 36.4904 28.2567 37.0283 28.6752 37.9247C28.7733 38.1661 28.9472 38.3702 29.1715 38.5073C29.3957 38.6444 29.6585 38.7073 29.9216 38.6867L29.585 40.623L30.5584 40.1838L30.7404 38.4805L31.168 38.2833L31.7957 39.628L32.6509 39.2425L30.9132 35.5133L29.585 36.1318ZM30.4947 37.8171C30.0399 38.0233 29.7123 37.9426 29.585 37.5751C29.4576 37.2076 29.585 36.9745 30.049 36.7683L30.4856 36.5711L30.9769 37.6199L30.4947 37.8171Z" fill="#DBDBDB"/>
    <path d="M37.0367 35.3609L36.6273 34.9395L36.2634 35.2891L33.9162 32.8956L32.0967 34.5899C32.5516 35.2533 32.7881 35.585 33.1975 36.1945C33.7525 37.0462 33.8616 37.4585 33.7706 37.6289L33.6342 37.7633L34.0436 38.1757L35.0716 39.0721L35.6812 38.4984L34.8442 37.6468L36.4818 36.087L37.3096 36.9386L37.9192 36.3649L37.0367 35.3609ZM34.5258 36.9476C34.3952 36.5058 34.1924 36.0879 33.9253 35.7105C33.6433 35.2802 33.4886 35.074 33.2066 34.6527L33.7888 34.1058L35.6084 35.8987L34.5258 36.9476Z" fill="#DBDBDB"/>
    <path d="M28.4026 31.8917H23.9721V19.96L24.0176 18.0057L24.1267 15.8632C23.3868 16.5804 22.8773 17.0555 22.5983 17.2886L20.1511 19.2249L18.0222 16.5355L24.7636 11.2465H28.4026V31.8917Z" fill="#747778"/>
    <path d="M27.8209 31.4255H23.3994V19.4939V17.5306L23.4722 15.3881C22.7322 16.1173 22.2228 16.5924 21.9438 16.8135L19.5784 18.714L17.4404 16.0963L24.1818 10.8073H27.8209V31.4255Z" fill="#DBDBDB"/>
    <path opacity="0.3" d="M47.8985 7.44594C44.187 2.86249 39.1422 -0.493491 33.4405 -2.17215C27.7387 -3.85081 21.6525 -3.77193 15.9975 -1.94607C10.3425 -0.120221 5.38914 3.36534 1.80127 8.0434C-1.7866 12.7215 -3.83746 18.3684 -4.07642 24.2274C0.254611 27.2366 5.23911 29.2073 10.4798 29.9826C18.4085 31.1426 26.4988 29.5216 33.3379 25.4025C40.1771 21.2833 45.3306 14.9279 47.8985 7.44594Z" fill="url(#paint0_linear)"/>
    </g>
    <rect x="-981.5" y="-233.5" width="1768" height="796" rx="4.5" stroke="#7B61FF" strokeDasharray="10 5"/>
    <defs>
    <linearGradient id="paint0_linear" x1="14.8649" y1="2.82924" x2="27.4004" y2="26.6759" gradientUnits="userSpaceOnUse">
    <stop stopColor="white" stopOpacity="0"/>
    <stop offset="0.35" stopColor="white" stopOpacity="0.01"/>
    <stop offset="0.49" stopColor="white" stopOpacity="0.03"/>
    <stop offset="0.59" stopColor="white" stopOpacity="0.08"/>
    <stop offset="0.67" stopColor="white" stopOpacity="0.15"/>
    <stop offset="0.74" stopColor="white" stopOpacity="0.24"/>
    <stop offset="0.8" stopColor="white" stopOpacity="0.35"/>
    <stop offset="0.85" stopColor="white" stopOpacity="0.47"/>
    <stop offset="0.9" stopColor="white" stopOpacity="0.62"/>
    <stop offset="0.95" stopColor="white" stopOpacity="0.79"/>
    <stop offset="1" stopColor="white"/>
    </linearGradient>
    <clipPath id="clip0">
    <rect width="50" height="50" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);

export default IconFirstCategory;
