/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';

import {
  GET_PDF_STUDENT_DIAGNOSIS_YEAR
} from '../../actions';

import {
  FAILURE,
  REQUEST,
  SUCCESS
} from '../../actions/utils';

import { IDiagnosisResponse } from '../../api/types';

import genericReducers from '../genericReducer';

import { IReducers } from '..';

export type PdfStudentDiagnosisYearState = IDiagnosisResponse;

const initialState = {} as IDiagnosisResponse;

const pdfStudentDiagnosisYear: IReducers<PdfStudentDiagnosisYearState> = {
  [GET_PDF_STUDENT_DIAGNOSIS_YEAR[REQUEST]]: genericReducers().get.request,
  [GET_PDF_STUDENT_DIAGNOSIS_YEAR[SUCCESS]]: genericReducers().get.success,
  [GET_PDF_STUDENT_DIAGNOSIS_YEAR[FAILURE]]: genericReducers().get.failure
};

export default connectReducers(initialState, pdfStudentDiagnosisYear);
