import { FC, useEffect, useState } from 'react';

import { Box, useMediaQuery } from '@mui/material';
import { IMappedLearningYearPerformance, SubjectThemesContainer } from 'portfolio3/features/performance';
import SubjectHint from 'portfolio3/features/performance/components/SubjectHint';
import { emitYMEvent } from 'portfolio3/features/yandexMetrika';
import { commonTheme } from 'portfolio3/styles/theme';

import ShowMore from '../../common/ShowMore';
import { AverageResultTile, SubjectResultTile } from './tile';

import './index.scss';

interface IPerformanceTilesProps {
  yearPerformance: IMappedLearningYearPerformance | undefined;
  selectedSubjectId: number | undefined;
  onSelectSubject: (id: number) => void;
}

const PerformanceTiles: FC<IPerformanceTilesProps> = ({ yearPerformance, selectedSubjectId, onSelectSubject }) => {
  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const { averageGrade, subjects } = yearPerformance ?? {};

  const initialSubjectsDisplayCount = 5;
  const [subjectsDisplayCount, setSubjectsDisplayCount] = useState(initialSubjectsDisplayCount);

  const totalSubjectsCount = subjects?.length ?? 0;
  const hiddenSubjectsCount = totalSubjectsCount - initialSubjectsDisplayCount;
  const isAllSubjectsShown = totalSubjectsCount === subjectsDisplayCount;

  useEffect(() => {
    setSubjectsDisplayCount(initialSubjectsDisplayCount);
  }, [yearPerformance?.year]);

  const handleShowAllSubjects = () => {
    emitYMEvent({ type: 'listItemsDisclosure' });
    setSubjectsDisplayCount(totalSubjectsCount);
  };
  const handleShowInitialSubjects = () => setSubjectsDisplayCount(initialSubjectsDisplayCount);

  return (
    <div className="performance-tiles-wrapper">
      <div className="performance-tiles">
        <div className="performance-tiles__results-container">
          {averageGrade !== undefined && <AverageResultTile doughuntValue={averageGrade} />}
          {subjects && totalSubjectsCount > 0 && (
            <div className="performance-tiles__subject-results">
              {subjects?.slice(0, subjectsDisplayCount).map(({ id, name, grade }) => (
                <div key={id} onClick={() => onSelectSubject(id)}>
                  <SubjectResultTile doughuntValue={grade} description={name} selected={id === selectedSubjectId} />
                </div>
              ))}
              <ShowMore
                hiddenCount={hiddenSubjectsCount}
                isAllShown={isAllSubjectsShown}
                onShowInitialItems={handleShowInitialSubjects}
                onShowAllItems={handleShowAllSubjects}
              />
            </div>
          )}
        </div>

        <div className="performance-tiles__details-container">
          <SubjectThemesContainer selectedSubjectId={selectedSubjectId} yearPerformance={yearPerformance} />
        </div>
      </div>
      {isMobile && (
        <Box marginTop="12px">
          <SubjectHint />
        </Box>
      )}
    </div>
  );
};

export default PerformanceTiles;
