import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root: SxStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  padding: '16px',
};

export const description: SxStyles = {
  textAlign: 'center',
  color: NeutralColors.light_neutrals_600,
};

export const buttons: SxStyles = {
  display: 'flex',
  gap: '16px',

  width: '100%',
  marginTop: '20px',

  '& > *': {
    flexBasis: 1,
    flexGrow: 1,
  },
};
