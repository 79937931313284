/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import CommonDialog, { ICommonDialog } from '../../../ui-kit/dialog/CommonDialog';
import useDialogHash from '../../../hooks/useDialogHash';

interface IViewDialogProps extends ICommonDialog {

}

const ViewDialog: React.FC<IViewDialogProps> = ({
  className,
  children,
  isOpen,
  title,
  description,
  onClose
}) => {
  useDialogHash(isOpen, 'view');

  return (
    <CommonDialog
      isOpen={isOpen}
      description={description}
      title={title}
      onClose={onClose}
      className={`dialog ${className}`}
    >
      {children}
    </CommonDialog>
  );
};

export default ViewDialog;
