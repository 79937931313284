import { BrowserRouter } from 'react-router-dom';

import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { StylesProvider } from '@mui/styles';
import createGenerateClassName from '@mui/styles/createGenerateClassName';

import Routes from './Routes';
import { commonTheme } from './styles/theme';
import { EmployeeToastContainer, StudentToastContainer } from './ui-kit';

const generateClassName = createGenerateClassName({
  productionPrefix: 'portfolio',
});

const App = () => {
  return (
    <StylesProvider generateClassName={generateClassName}>
      <StyledEngineProvider>
        <ThemeProvider theme={commonTheme}>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>

          <StudentToastContainer />
          <EmployeeToastContainer />
        </ThemeProvider>
      </StyledEngineProvider>
    </StylesProvider>
  );
};

export default App;
