import { FC } from 'react';

import { Box, Typography, useMediaQuery } from '@mui/material';
import FunnelBlock from 'components/common/FunnelBlock';
import { commonTheme } from 'portfolio3/styles/theme';
import Toggle from 'portfolio3/ui-kit/Toggle';

import * as styles from './styles';

interface ILinksOverviewProps {
  isAccessEnabled: boolean;
  onChangeAccess: (isActive: boolean) => void;
  onOpenLinks: () => void;
}

const LinksOverview: FC<ILinksOverviewProps> = ({ isAccessEnabled, onChangeAccess, onOpenLinks }) => {
  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const helperText = `Сейчас все сгенерированные ссылки на твое портфолио ${isAccessEnabled ? 'активны' : 'неактивны'}`;
  const linksButtonText = isMobile ? 'Все активные ссылки' : 'Показать все активные ссылки';

  return (
    <FunnelBlock className="sharing-links-overview" sx={styles.root}>
      <Box sx={styles.header}>
        <Typography variant={isMobile ? 'Headings/H6' : 'Headings/H5'}>
          Доступ по ссылкам {isAccessEnabled ? 'включен' : 'выключен'}
        </Typography>
        <Toggle checked={isAccessEnabled} onChange={(_, checked) => onChangeAccess(checked)} />
      </Box>
      {!isMobile && (
        <Typography variant="Paragraph LG/Regular" sx={styles.helper}>
          {helperText}
        </Typography>
      )}
      {isAccessEnabled && (
        <Typography component="button" variant="Paragraph LG/Semi Bold" onClick={onOpenLinks} sx={styles.linksButton}>
          {linksButtonText}
        </Typography>
      )}
    </FunnelBlock>
  );
};

export default LinksOverview;
