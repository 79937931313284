/* eslint-disable prettier/prettier */
/* eslint-disable */

import { IQueryParams } from '../../../../api';
import { IPostBaseEntityProperties, MetaObject } from '../../../../api/types';
import { IPortfolioEntryFormData } from 'portfolio3/features/dataEntryForm';
import { FormEditAction, FormPostAction, GetMappedEntityData } from './types';

export const getBasePostEntityProperties = (
  personId: string,
  creatorId: string,
  sourceCode: number,
  categoryCode: number,
  dataKind: number,
  typeCode: number,
): IPostBaseEntityProperties => {
  return {
    personId,
    creatorId,
    sourceCode,

    categoryCode,
    dataKind,
    typeCode,

    isImport: false,
    isDelete: false
  }
};

export const getEditEntityHandler = (
  personId: string,
  creatorId: string,
  sourceCode: number | undefined,
  queryParams: IQueryParams | undefined,
  studentType: boolean | undefined,
  sectionCodesString: string,
  formData: IPortfolioEntryFormData
) => {
  const { attachment } = formData;

  return <T>(editAction: FormEditAction<T>, getMappedEntityData: GetMappedEntityData<T>, entityId: number | undefined) => {
    const data = getMappedEntityData(personId, creatorId, sourceCode, formData);
    if (!data || !entityId) return;

    editAction(data, entityId, attachment.map((file) => file.response), queryParams, studentType, sectionCodesString);
  };
};

export const getPostEntityHandler = (
  personId: string,
  creatorId: string,
  sourceCode: number | undefined,
  queryParams: IQueryParams | undefined,
  studentType: boolean | undefined,
  sectionCodesString: string,
  formData: IPortfolioEntryFormData
) => {
  const { attachment } = formData;

  return <T>(postAction: FormPostAction<T>, getMappedEntityData: GetMappedEntityData<T>, yandexMetrikaMeta?: MetaObject) => {
    const data = getMappedEntityData(personId, creatorId, sourceCode, formData);
    if (!data) return;

    postAction(data, attachment.map((file) => file.response), queryParams, studentType, sectionCodesString, yandexMetrikaMeta);
  };
};
