/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import './index.scss';

interface IMobileBarProps {
  subject: string;
  value: number;
}

const MobileBar: React.FC<IMobileBarProps> = ({ subject, value }) => (
  <div className="mobile-bar-container">
    <div className="mobile-bar__info">
      <h4 className="mobile-bar__title">{subject}</h4>
      <p className="mobile-bar__value">
        {value.toFixed(1)}
        %
      </p>
    </div>
    <div className="mobile-bar">
      <div
        className="mobile-bar__substrate"
        style={{ width: `${value}%` }}
      />
    </div>
  </div>
);

export default MobileBar;
