import React, { useContext } from 'react';
import { connect } from 'react-redux';

import { Box, ThemeProvider, Typography, useTheme } from '@mui/material';
import { useBrightTheme } from 'hooks';
import TrimText from 'portfolio3/components/common/TrimText';
import TrimTextMultiLine from 'portfolio3/components/common/TrimTextMultiLine';
import {
  EntityActionsMenuButton,
  useAdminEntityId,
  useCommonEntityHandlers,
} from 'portfolio3/components/entityActions';
import { AccentColorType, getAccentColor } from 'portfolio3/styles';
import { generateAccentTheme } from 'portfolio3/styles/theme';
import { BaseBadge } from 'portfolio3/ui-kit/badges';

import { PortfolioSettingsContext } from '../../../../context';
import { IRootState } from '../../../../reducers';
import { IModifiedReward } from '../../../../types';
import { getCategoryAccentColor, mergeSx } from '../../../../utils';
import VerificationSign from '../../../common/VerificationSign';
import * as styles from './styles';

interface IAwardBlockProps {
  award: IModifiedReward;
  loading: boolean;
  linkMode: boolean | undefined;
}

const AwardBlock: React.FC<IAwardBlockProps> = ({ award, loading, linkMode }) => {
  const theme = useTheme();
  const isBrightTheme = useBrightTheme();

  const { isSettingsMode } = useContext(PortfolioSettingsContext);

  const { accentColor: themeAccentColor } = theme.palette;
  const { name, awardDate, description, image, awardType, formData, cardData, isApproved, entityType, entityFlags } =
    award;

  const { getHandlers } = useCommonEntityHandlers();
  const { handleView, handleEdit, handleDelete, handleReport, handleThanksTeacher, deleteDialogElement } = getHandlers({
    formData,
    cardData,
  });

  const adminEntityId = useAdminEntityId({ formData });

  const categoryCode: number | undefined = formData?.categoryCode;

  const accentColor = !categoryCode ? themeAccentColor : getCategoryAccentColor(categoryCode);
  const computedAccentColor: AccentColorType = isBrightTheme ? accentColor : 'indigo';

  const awardName = name ?? 'Не выбран';
  const awardDescription = description ?? 'Не выбран';

  const isActionsVisible = !linkMode && !isSettingsMode;

  return (
    <ThemeProvider theme={generateAccentTheme(computedAccentColor)}>
      <Box className="award-block" sx={styles.root}>
        {isApproved && (
          <Box className="award-block__verification" sx={styles.verification}>
            <VerificationSign />
          </Box>
        )}

        <Box component="img" alt="Награда" src={image} />

        <Typography
          component={TrimTextMultiLine}
          line={2}
          onClick={handleView}
          className="award-block__name"
          sx={mergeSx(styles.name, styles.block(16))}
        >
          {awardName}
        </Typography>

        <Typography variant="Paragraph MD/Regular" className="award-block__date" sx={styles.date}>
          {awardDate}
        </Typography>

        <Box className="award-block__badge-container" sx={styles.badgeContainer}>
          <Box sx={mergeSx(styles.divider, styles.badgeDivider, awardType ? styles.badgeDividerAbsolute : null)} />
          {awardType && (
            <BaseBadge
              textColor={getAccentColor(computedAccentColor, '200')}
              backgroundColor={getAccentColor(computedAccentColor, '8')}
              sx={{ maxWidth: '160px' }}
            >
              <TrimText>{awardType}</TrimText>
            </BaseBadge>
          )}
        </Box>

        <Typography
          component={TrimTextMultiLine}
          line={2}
          variant="Paragraph MD/Regular"
          sx={mergeSx(styles.description, styles.block(12))}
        >
          {awardDescription}
        </Typography>

        {adminEntityId && (
          <>
            <Box sx={styles.divider} />
            <Typography component={TrimTextMultiLine} line={2} variant="Paragraph MD/Regular" sx={styles.entityId}>
              ID: {adminEntityId}
            </Typography>
          </>
        )}

        {isActionsVisible && (
          <Box className="award-block__button-more" sx={styles.buttonMore}>
            <EntityActionsMenuButton
              sx={{ border: 'none' }}
              formData={formData}
              entityType={entityType}
              entityFlags={entityFlags ?? []}
              actionsMap={{
                view: {
                  isDisabled: loading,
                  effect: handleView,
                },
                edit: {
                  isDisabled: loading,
                  effect: handleEdit,
                },
                delete: {
                  isDisabled: loading,
                  effect: handleDelete([], []),
                },
                report: {
                  isDisabled: loading,
                  effect: handleReport,
                },
                thanks: {
                  isDisabled: loading,
                  effect: handleThanksTeacher,
                },
              }}
            />
          </Box>
        )}
        {deleteDialogElement}
      </Box>
    </ThemeProvider>
  );
};

export default connect((state: IRootState) => ({
  linkMode: state.linkMode.mode,
}))(AwardBlock);
