import { IRootState } from 'reducers';
import { createSelector } from 'reselect';

import { mapProfessionMetaskillsResponse } from './mappers';

const professionMetaskillsSelector = createSelector(
  [(state: IRootState) => state.studentMetaSkills, (state: IRootState) => state.metaSkillRef],
  (metaskills, metaskillRef) => {
    return mapProfessionMetaskillsResponse(metaskills, metaskillRef);
  },
);

export default professionMetaskillsSelector;
