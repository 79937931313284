import { IPortfolioEntryFormData } from 'portfolio3/features/dataEntryForm';

import { validatorFactory } from '../common';

const giaWorldSkillsRequiredProps = [
  'giaCompetenceCode',
  'giaName',
  'giaResultScore',
  'giaMaxCompetenceScore',
  'giaKod',
] as const;
type GiaWorldSkillsRequiredProps = (typeof giaWorldSkillsRequiredProps)[number];

export const isGiaWorldSkillsValid = validatorFactory<IPortfolioEntryFormData, GiaWorldSkillsRequiredProps>(
  giaWorldSkillsRequiredProps,
);
