import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root: SxStyles = {
  display: 'grid',
  gridAutoFlow: 'row',
  gridTemplateRows: '1fr auto',

  width: '216px',
  height: '340px',
  padding: '16px',
  paddingTop: '48px',

  border: '1px solid',
  borderColor: NeutralColors.night_neutrals_700,
};

export const actions: SxStyles = {
  display: 'flex',
  flexWrap: 'wrap',
  alignContent: 'flex-end',
  gap: '8px',
};
