import { styled } from '@mui/material';

import { ISectionColorTheme } from '../../../../types';
import { Button } from '../../../../ui-kit';

interface IComponentStyledProps {
  colors: ISectionColorTheme;
}

export const StyledAddEntityButton = styled(Button)(({ colors }: IComponentStyledProps) => ({
  color: colors.color_08,
  border: `2px solid ${colors.color_04}`,

  background: colors.color_01,
  fontWeight: 700,
  '& svg': {
    width: 14,
    height: 14,
  },
  '& svg path': {
    stroke: colors.color_08,
  },
  '&:hover, &:active': {
    background: colors.color_08,
    border: `2px solid ${colors.color_08}`,
  },
}));
