import { IProfessionSpoJobFormData } from 'portfolio3/features/dataEntryForm';

import { validatorFactory } from '../common';

const jobRequiredProps = ['organization', 'businessLevelCode', 'position', 'mainFunctionality'] as const;
type JobRequiredProps = (typeof jobRequiredProps)[number];

const jobWithContractRequiredProps = [...jobRequiredProps, 'contractDate'] as const;
type JobWithContractRequiredProps = (typeof jobWithContractRequiredProps)[number];

export const isJobValid = validatorFactory<IProfessionSpoJobFormData, JobRequiredProps>(jobRequiredProps);
export const isJobWithContractValid = validatorFactory<IProfessionSpoJobFormData, JobWithContractRequiredProps>(
  jobWithContractRequiredProps,
);
