import { getAccentColor, NeutralColors } from 'portfolio3/styles';
import { getButtonStyles } from 'portfolio3/ui-kit/utils';
import { SxStyles } from 'types';
import { inheritSx } from 'utils';

export const root: SxStyles = (theme) => {
  return {
    position: 'relative',

    display: 'flex',
    justifyContent: 'space-between',
    gap: '32px',

    paddingInline: '24px',

    backgroundColor: '#FEECC3',
    borderTop: '1px solid',
    borderColor: getAccentColor('yellow', '48'),

    [theme.breakpoints.down('commonSm')]: {
      paddingInline: '16px',
    },
  };
};

export const leftElement: SxStyles = (theme) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',

    paddingBlock: '20px',

    [theme.breakpoints.down('commonSm')]: {
      gap: '2px',
      paddingBlock: '8px',
    },
  };
};

export const rightElement: SxStyles = (theme) => {
  return {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',

    height: 'initial',

    [theme.breakpoints.down('commonSm')]: {
      gap: '8px',
    },
  };
};

export const rewardImage: SxStyles = (theme) => {
  return {
    alignSelf: 'flex-start',

    width: '60px',

    [theme.breakpoints.down('commonSm')]: {
      width: '41px',
    },
  };
};

export const arrowButton: SxStyles = (theme) => {
  return {
    ...inheritSx([getButtonStyles()]),

    borderRadius: '50%',

    svg: {
      width: '32px',
      height: '32px',
      color: NeutralColors.light_neutrals_600,
    },

    [theme.breakpoints.down('commonSm')]: {
      svg: {
        width: '24px',
        height: '24px',
      },
    },
  };
};
