import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import * as styles from './styles';

interface IWidgetContainerYearProps {
  year: number;
}

const WidgetContainerYear: FC<IWidgetContainerYearProps> = ({ year }) => {
  return (
    <Box className="widget-container-year" sx={styles.root}>
      <Typography sx={styles.year}>{year}</Typography>
    </Box>
  );
};

export default WidgetContainerYear;
