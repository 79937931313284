import { FC } from 'react';

import { Box, capitalize, Typography } from '@mui/material';
import { professionNavigation, StudentSectionSettingTypeCodes } from 'const';
import { useAppSelector, useBrightTheme, useWidgetVisibility } from 'hooks';
import { WidgetContainer as Widget } from 'portfolio3/components/common/WidgetContainer';
import { WidgetContainerTitle } from 'portfolio3/components/widgetContainerRecipes';
import { Button } from 'portfolio3/ui-kit/button';

import LinkContainer from '../components/LinkContainer';
import RecommendationCardList from '../components/RecommendationCardList';
import {
  proforientationRecommendedIndustriesSelector,
  proforientationResultLinkSelector,
  proforientationVisibilitySelector,
} from '../model/selectors';

const sectionTypeCode = StudentSectionSettingTypeCodes.profession;
const subsectionTypeCode = StudentSectionSettingTypeCodes.professionProforientationRecommendations;

const navigationData = professionNavigation.recommendations;

const ProforientationRecommendationsWidget: FC = () => {
  const isBrightTheme = useBrightTheme();
  const { isSettingsMode, isWidgetVisible, toggleElement, overlayElement } = useWidgetVisibility({
    sectionTypeCode,
    subsectionTypeCode,
  });

  const resultLink = useAppSelector(proforientationResultLinkSelector);
  const recommendedIndustries = useAppSelector(proforientationRecommendedIndustriesSelector);
  const { isRecommendationsVisible } = useAppSelector(proforientationVisibilitySelector);

  const industryNamesString = recommendedIndustries.map((industry) => capitalize(industry.name)).join(', ');
  const filteredIndustries = recommendedIndustries.filter(
    (industry) => industry.specialities.length > 0 || industry.collegeNames.length > 0,
  );

  const resultLinkElement = resultLink && (
    <LinkContainer link={resultLink}>
      <Button>Подробнее</Button>
    </LinkContainer>
  );
  const widgetTitleElement = (
    <WidgetContainerTitle title={navigationData.label} right={isSettingsMode ? toggleElement : resultLinkElement} />
  );

  if (!isWidgetVisible || !isRecommendationsVisible) return null;

  return (
    <Widget containerData={navigationData} withBackground={isBrightTheme}>
      {overlayElement}
      <Widget.Header title={widgetTitleElement} />
      <Widget.Padding>
        <Typography variant="Paragraph LG/Regular">
          Для&nbsp;тебя сформирован список отраслей, специальностей и&nbsp;колледжей.
        </Typography>
        <Typography variant="Paragraph LG/Regular" marginTop="8px">
          Тебе рекомендованы следующие отрасли:{' '}
          <Typography component="span" variant="Paragraph LG/Semi Bold">
            {industryNamesString}.
          </Typography>
        </Typography>

        {filteredIndustries.length > 0 && (
          <Box marginTop="16px">
            <RecommendationCardList recommendations={filteredIndustries} />
          </Box>
        )}
      </Widget.Padding>
    </Widget>
  );
};

export default ProforientationRecommendationsWidget;
