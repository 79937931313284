import { Dispatch, SetStateAction } from 'react';

import { IGiaItem } from 'api/types';
import { GiaType } from 'const';
import { isEqual } from 'lodash';

import { IVuzRecommendationClientFilters, IVuzRecommendationFilters, VuzLocalFilters } from '../types';
import { ILocalVuzSpeciality, SetFilterFunction } from '../types';
import { mapLocalSubjectNameToRefSubjectName } from './subjects';

export function filterTrialGiaExam(exam: IGiaItem) {
  return [GiaType.ege, GiaType.gve11].includes(exam.formaGia) && exam.approbation;
}

export function filterRealGiaExam(exam: IGiaItem) {
  return [GiaType.ege, GiaType.gve11].includes(exam.formaGia) && !exam.approbation;
}

export function setFilterFactory<T>(setStateAction: Dispatch<SetStateAction<T>>): SetFilterFunction<T> {
  return (key, value) => {
    setStateAction((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };
}

/**
 * Функция возвращает предикат для .filter который фильтрует специальность
 * для прохождения фильтра каждый предмет специальности должен быть найден в списке переданных предметов
 *
 * @param localSubjectNames локальные названия предметов (из фильтров)
 * @param refSubjectNames названия предметов из справочника mos.ru
 */
export const filterEverySpecialitySubjectIncludedInSubjectsFactory = (
  localSubjectNames: string[],
  refSubjectNames: string[],
) => {
  const localSubjectsNormalizedToRefFormat = localSubjectNames
    .map(mapLocalSubjectNameToRefSubjectName)
    .map((subject) => subject.toLowerCase());

  const normalizedRefSubjects = refSubjectNames.map((subject) => subject.toLowerCase());

  return (speciality: ILocalVuzSpeciality) => {
    const normalizedSpecialitySubjects = speciality.subjects?.map((subject) => subject.toLowerCase()) ?? [];
    // удаляем предметы специальности которых нет в справочнике mos.ru
    const filteredSpecialitySubjects = normalizedSpecialitySubjects.filter((subject) =>
      normalizedRefSubjects.includes(subject),
    );

    const isSubjectsContainsAllSpecialitySubjects = filteredSpecialitySubjects.every((subject) => {
      return localSubjectsNormalizedToRefFormat.includes(subject);
    });

    return isSubjectsContainsAllSpecialitySubjects;
  };
};

/**
 * Функция возвращает предикат для .filter который фильтрует специальность
 * для прохождения фильтра каждый переданный предмет должен быть найден в списке предметов специальности
 *
 * @param localSubjectNames локальные названия предметов (из фильтров)
 */
export const filterEverySubjectIncludedInSpecialitySubjectsFactory = (localSubjectNames: string[]) => {
  const localSubjectsNormalizedToRefFormat = localSubjectNames
    .map(mapLocalSubjectNameToRefSubjectName)
    .map((subject) => subject.toLowerCase());

  /**
   * Функция проверяет наличие каждого переданного предмета в предметах специальности
   */
  return (speciality: ILocalVuzSpeciality) => {
    const normalizedSpecialitySubjects = speciality.subjects?.map((subject) => subject.toLowerCase()) ?? [];

    const isSpecialityContainsAllSubjects = localSubjectsNormalizedToRefFormat.every((subject) => {
      return normalizedSpecialitySubjects.includes(subject);
    });

    return isSpecialityContainsAllSubjects;
  };
};

export const isInitialFiltersChanged = (
  localFilters: VuzLocalFilters,
  initialServerFilters: IVuzRecommendationFilters,
) => {
  const { isHigherBallToggle } = localFilters;

  const initialIsHigherBallToggleValue = false;

  const _isInitialServerFiltersChanged = isInitialServerFiltersChanged(localFilters, initialServerFilters);
  const isInitialClientFiltersChanged = isHigherBallToggle !== initialIsHigherBallToggleValue;

  return _isInitialServerFiltersChanged || isInitialClientFiltersChanged;
};

export const isFiltersChanged = (
  localFilters: VuzLocalFilters,
  serverFilters: IVuzRecommendationFilters | null,
  initialServerFilters: IVuzRecommendationFilters,
  clientFilters: IVuzRecommendationClientFilters | null,
) => {
  const {
    search,
    subjects,
    specialities,
    trainingTypeCode,
    militaryTrainingTypeCode,
    minPassingBall,
    maxPassingBall,
    isHigherBallToggle,
  } = localFilters;

  const sortedLocalSubjects = [...subjects].sort();
  const sortedServerSubjects = serverFilters ? [...serverFilters.subjects].sort() : [];

  const sortedLocalSpecialities = [...specialities].sort();
  const sortedServerSpecialities = serverFilters ? [...serverFilters.specialities].sort() : [];

  const hasAppliedServerFilters = serverFilters !== null;
  const isServerFiltersChanged =
    hasAppliedServerFilters &&
    (search !== serverFilters.search ||
      !isEqual(sortedLocalSubjects, sortedServerSubjects) ||
      !isEqual(sortedLocalSpecialities, sortedServerSpecialities) ||
      trainingTypeCode !== serverFilters.trainingTypeCode ||
      militaryTrainingTypeCode !== serverFilters.militaryTrainingTypeCode ||
      minPassingBall !== serverFilters.minPassingBall ||
      maxPassingBall !== serverFilters.maxPassingBall);

  const _isInitialServerFiltersChanged = isInitialServerFiltersChanged(localFilters, initialServerFilters);

  const computedIsServerFiltersChanged =
    (hasAppliedServerFilters && isServerFiltersChanged) || (!hasAppliedServerFilters && _isInitialServerFiltersChanged);

  const initialIsHigherBallToggleValue = false;
  const computedIsHigherBallToggleValue = clientFilters?.isHigherBallToggle ?? initialIsHigherBallToggleValue;
  const isClientFiltersChanged = isHigherBallToggle !== computedIsHigherBallToggleValue;

  return computedIsServerFiltersChanged || isClientFiltersChanged;
};

function isInitialServerFiltersChanged(localFilters: VuzLocalFilters, initialServerFilters: IVuzRecommendationFilters) {
  const { search, subjects, specialities, trainingTypeCode, militaryTrainingTypeCode, minPassingBall, maxPassingBall } =
    localFilters;

  const sortedLocalSubjects = [...subjects].sort();
  const sortedInitialServerSubjects = [...initialServerFilters.subjects].sort();

  const sortedLocalSpecialities = [...specialities].sort();
  const sortedInitialServerSpecialities = [...initialServerFilters.specialities].sort();

  const isInitialServerFiltersChanged =
    search !== initialServerFilters.search ||
    !isEqual(sortedLocalSubjects, sortedInitialServerSubjects) ||
    !isEqual(sortedLocalSpecialities, sortedInitialServerSpecialities) ||
    trainingTypeCode !== initialServerFilters.trainingTypeCode ||
    militaryTrainingTypeCode !== initialServerFilters.militaryTrainingTypeCode ||
    minPassingBall !== initialServerFilters.minPassingBall ||
    maxPassingBall !== initialServerFilters.maxPassingBall;

  return isInitialServerFiltersChanged;
}
