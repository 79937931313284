import { FAILURE, INVALIDATE, REQUEST, SUCCESS } from 'actions/utils';
import { ICommonResponseObject } from 'api/types';
import { IReducers } from 'reducers';
import genericReducers from 'reducers/genericReducer';
import { connectReducers } from 'utils';

import { GET_VUZ_LIST_COUNT } from '../actions';

export type VuzListCountState = ICommonResponseObject<number>;

const initialState: VuzListCountState = {
  content: 0,
  loading: false,
};

const vuzListCount: IReducers<VuzListCountState> = {
  [GET_VUZ_LIST_COUNT[REQUEST]]: genericReducers().get.request,
  [GET_VUZ_LIST_COUNT[SUCCESS]]: genericReducers().get.success,
  [GET_VUZ_LIST_COUNT[FAILURE]]: genericReducers().get.failure,
  [GET_VUZ_LIST_COUNT[INVALIDATE]]: genericReducers().get.invalidate(initialState),
};

export default connectReducers(initialState, vuzListCount);
