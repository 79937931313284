import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root: SxStyles = {
  paddingBlock: '16px',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',

  backgroundColor: NeutralColors.light_neutrals_0,
  borderBottom: '1px solid',
  borderColor: NeutralColors.light_neutrals_300,
  boxShadow: '0px 1px 3px rgba(41, 41, 64, 0.08), 0px 1px 2px rgba(41, 41, 64, 0.06)',
};

export const leftContainer: SxStyles = {
  flexGrow: 1,

  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
};
