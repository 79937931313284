import { StudentSectionSettingTypeCodes } from 'const';
import { useAppSelector } from 'hooks';
import { visibilitySettingsSelector } from 'selectors';
import { findTargetVisibilitySetting } from 'utils/localVisibilitySettingsService';

import { collection as searchActions } from '../model/actions';
import {
  searchScienceContestsSelector,
  searchScienceOccupationsSelector,
  searchScienceProjectsSelector,
  searchScienceRewardsSelector,
} from '../model/selectors';

const scienceCode = StudentSectionSettingTypeCodes.science;
const rewardCode = StudentSectionSettingTypeCodes.scienceReward;
const projectCode = StudentSectionSettingTypeCodes.scienceProject;
const contestCode = StudentSectionSettingTypeCodes.scienceContest;
const occupationCode = StudentSectionSettingTypeCodes.scienceEmployment;

const useSearchScience = () => {
  const settings = useAppSelector(visibilitySettingsSelector);
  const { loading: isRewardsLoading, content: rewards } = useAppSelector(searchScienceRewardsSelector);
  const { loading: isProjectsLoading, content: projects } = useAppSelector(searchScienceProjectsSelector);
  const { loading: isContestsLoading, content: contests } = useAppSelector(searchScienceContestsSelector);
  const { loading: isOccupationsLoading, content: occupations } = useAppSelector(searchScienceOccupationsSelector);

  const scienceEntitiesCount = [rewards.length, projects.length, contests.length, occupations.length].reduce(
    (prev, current) => prev + current,
    0,
  );

  const hasRewards = rewards.length > 0;
  const hasProjects = projects.length > 0;
  const hasContests = contests.length > 0;
  const hasOccupations = occupations.length > 0;

  const isRewardsEnabled =
    findTargetVisibilitySetting(settings, [scienceCode, rewardCode])?.computedVisibility ?? false;
  const isProjectsEnabled =
    findTargetVisibilitySetting(settings, [scienceCode, projectCode])?.computedVisibility ?? false;
  const isContestsEnabled =
    findTargetVisibilitySetting(settings, [scienceCode, contestCode])?.computedVisibility ?? false;
  const isOccupatonsEnabled =
    findTargetVisibilitySetting(settings, [scienceCode, occupationCode])?.computedVisibility ?? false;

  const isScienceRewardVisible = hasRewards && isRewardsEnabled;
  const isScienceProjectVisible = hasProjects && isProjectsEnabled;
  const isScienceContestVisible = hasContests && isContestsEnabled;
  const isScienceOccupationVisible = hasOccupations && isOccupatonsEnabled;

  const isScienceLoading = isRewardsLoading || isProjectsLoading || isContestsLoading || isOccupationsLoading;
  const isScienceVisible =
    isScienceRewardVisible || isScienceProjectVisible || isScienceContestVisible || isScienceOccupationVisible;

  const scienceActions = [
    isRewardsEnabled && searchActions.searchRewardsActions,
    isProjectsEnabled && searchActions.searchProjectsActions,
    isContestsEnabled && searchActions.searchEventsActions,
    isOccupatonsEnabled && searchActions.searchEmploymentsActions,
  ];

  return {
    rewards,
    projects,
    contests,
    occupations,
    isScienceRewardVisible,
    isScienceProjectVisible,
    isScienceContestVisible,
    isScienceOccupationVisible,
    isScienceLoading,
    isScienceVisible,
    scienceActions,
    scienceEntitiesCount,
  };
};

export default useSearchScience;
