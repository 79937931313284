import React, { useContext } from 'react';
import { connect } from 'react-redux';

import {
  ICommonResponse,
  IDictionaryItem,
  IDiscipline,
  IModifiedDictionaryItemList,
  IModifiedEventKindsList,
  IModifiedRewardKindsList,
} from 'api/types';
import { SectionCodes, SubcategoryParentsCodes } from 'const';
import { IRootState } from 'reducers';
import { createSelector } from 'reselect';
import { sectionRefArchiveFalseSelector } from 'selectors';
import { getAgeLimitFromArray, isDefined } from 'utils';

import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';
import {
  NameRewardSecondaryBlock,
  OccupationSecondaryBlock,
  ProjectSecondaryBlock,
  ScienceContestSecondaryBlock,
} from '../../secondaryBlocks';

interface IScienceSecondaryBlockProps {
  activityTypeData: IModifiedDictionaryItemList;
  eventKindData: IModifiedEventKindsList;
  olympiadTypes: ICommonResponse<IDictionaryItem>;
  rewardKindData: IModifiedRewardKindsList;
  disciplines: ICommonResponse<IDiscipline>;
  subcategories: IModifiedRewardKindsList;
}

const ScienceSecondaryBlock: React.FC<IScienceSecondaryBlockProps> = ({
  activityTypeData,
  eventKindData,
  disciplines,
  subcategories,
  olympiadTypes,
  rewardKindData,
}) => {
  const { isMobile, formData } = useContext(LocalDataEntryFormContext);

  const currentEventKindData = eventKindData.content.find((activity) => activity.code === formData.name);

  const eventData = {
    type:
      activityTypeData.content?.find((portfolioKind) => portfolioKind.code === currentEventKindData?.categoryCode)
        ?.value || 'Конкурс',
    name: currentEventKindData?.value,
    ageLimit: getAgeLimitFromArray(currentEventKindData?.ageLimits),
    levelEvent: currentEventKindData?.levelEvent
      ? olympiadTypes.content?.find((type) => type.code === currentEventKindData?.levelEvent)?.value
      : undefined,
    levelEventCode: currentEventKindData?.levelEvent,
    organizators: currentEventKindData?.organizators,
  };

  return (
    <>
      {formData.dataKind === SectionCodes.project && (
        <ProjectSecondaryBlock
          subjectOptions={
            disciplines?.content?.filter((discipline) => discipline.categoryCode === formData.subCategory) || []
          }
          subCategoryOptions={subcategories.content}
          eventLevelOptions={olympiadTypes?.content || []}
        />
      )}
      {formData.dataKind === SectionCodes.scienceOccupation && formData.typeCode && (
        <OccupationSecondaryBlock
          subCategoryOptions={subcategories.content}
          nameInputLabel="Название кружка"
          nameInputPlaceholder="Введите название кружка..."
          isSubjectInputRequired
          isSubjectInputDisabled={!isDefined(formData.subCategory)}
          subjectInputLabel={isMobile ? 'Предмет / дисциплина' : 'Предмет / дисциплина (один или несколько)'}
          subjectInputPlaceholder="Начните вводить название предмета..."
          subjectOptions={disciplines?.content || []}
        />
      )}
      {formData.dataKind === SectionCodes.scienceEvent && formData.name && (
        <ScienceContestSecondaryBlock
          eventData={eventData}
          nameLabel={
            formData.typeCode === SectionCodes.contest
              ? 'Название конкурса'
              : 'Название конференции / конкурса проектов'
          }
          namePlaceholder={
            formData.typeCode === SectionCodes.contest
              ? 'Введите название конкурса...'
              : 'Введите название конференции / конкурса проектов...'
          }
          subjectOptions={disciplines?.content || []}
          eventLevelOptions={olympiadTypes?.content || []}
        />
      )}
      {formData.dataKind === SectionCodes.scienceReward && formData.reward && (
        <NameRewardSecondaryBlock
          result={rewardKindData.content.find((reward) => reward.code === formData.reward)?.value}
        />
      )}
    </>
  );
};

const portfolioActivityTypeMapping = createSelector(
  [(state: IRootState) => sectionRefArchiveFalseSelector(state)],
  (sections) => ({
    content: sections.content?.filter((section) => section.parentId === SectionCodes.scienceEvent) || [],
    loading: sections.loading,
  }),
);

const eventKindsMapping = createSelector([(state: IRootState) => state.eventKinds], (eventKinds) => ({
  content:
    eventKinds?.content?.filter(
      (eventKind) =>
        eventKind.categoryCode === SectionCodes.contest || eventKind.categoryCode === SectionCodes.conference,
    ) || [],
  loading: eventKinds.loading,
}));

const rewardKindsMapping = createSelector([(state: IRootState) => state.rewardKinds], (rewardKinds) => ({
  content:
    rewardKinds.content?.filter(
      (rewardKind) =>
        rewardKind.categoryCode === SectionCodes.contestReward ||
        rewardKind.categoryCode === SectionCodes.projectReward,
    ) || [],
  loading: rewardKinds.loading,
}));

const subcategoriesMapping = createSelector([(state: IRootState) => state.portfolioSubcategories], (subcategories) => ({
  content:
    subcategories.content?.filter((subcategory) => subcategory?.parentId === SubcategoryParentsCodes.science) || [],
  loading: subcategories.loading,
}));

export default connect((state: IRootState) => ({
  activityTypeData: portfolioActivityTypeMapping(state),
  eventKindData: eventKindsMapping(state),
  rewardKindData: rewardKindsMapping(state),
  disciplines: state.disciplines,
  subcategories: subcategoriesMapping(state),
  olympiadTypes: state.olympiadTypes,
}))(ScienceSecondaryBlock);
