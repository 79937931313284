import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const vuzCard: SxStyles = {
  border: `1px solid ${NeutralColors.light_neutrals_400}`,
  borderRadius: '12px',

  boxShadow: '0px 1px 2px 0px rgba(41, 41, 64, 0.07)',

  overflow: 'hidden',
};
