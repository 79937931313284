import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root: SxStyles = {
  display: 'grid',
  placeItems: 'center',

  // работает как border width для активного состояния
  padding: '3px',

  border: '1px solid',
  borderColor: NeutralColors.light_neutrals_300,
  borderRadius: '16px',
  cursor: 'pointer',
};

export const rootActive: SxStyles = {
  border: 0,
  background: 'linear-gradient(157.45deg, #1FF7FD -14.38%, #B33BF6 42.31%, #FF844C 82.46%, #FF844B 101.14%)',
};

export const content: SxStyles = {
  display: 'grid',
  placeItems: 'center',
  width: '100%',
  height: '100%',

  borderRadius: '13px',
  backgroundColor: '#ffffff',

  overflow: 'hidden',
};
