import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getStudentAvatarActions } from 'actions';
import { useAppSelector, useUserFunctionality } from 'hooks';

const useLoadStudentAvatars = () => {
  const dispatch = useDispatch();
  const currentUser = useAppSelector((state) => state.currentUser);
  const linkSettings = useAppSelector((state) => state.linkVisibilitySettings);

  const { isStudent } = useUserFunctionality();

  // получение аватаров при обычном просмотре
  useEffect(() => {
    if (!currentUser.data) return;

    const currentUserData = currentUser.data;

    const studentIds: (string | undefined)[] = [];

    if (isStudent) {
      studentIds.push(currentUserData.meshId);
    } else if (currentUserData.children) {
      studentIds.push(...currentUserData.children.map((child) => child.meshId));
    }

    studentIds.map((meshId) => {
      if (meshId) {
        dispatch(getStudentAvatarActions.request(meshId));
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.data, dispatch]);

  // получение аватара при доступе по ссылке
  useEffect(() => {
    const meshId = linkSettings.content?.personId;

    if (meshId) {
      dispatch(getStudentAvatarActions.request(meshId));
    }
  }, [dispatch, linkSettings.content?.personId]);
};

export default useLoadStudentAvatars;
