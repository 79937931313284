/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

const IconSilverGto: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="50" height="50" fill="#E5E5E5"/>
    <rect width="1924" height="1397" transform="translate(-1111 -555)" fill="white"/>
    <g clip-path="url(#clip0)">
    <path d="M25 -5.81183V49.8692L28.1815 51.9999L30.8 48.9108L34.5103 50.2628L36.1735 47.1566L43.0653 48.8595L43.7647 42.1678L46.7756 41.5517L46.605 37.701L50.2726 36.0324L49.0018 32.3614L52.2344 29.9055L50.1362 26.5768L52.8826 23.4706L50.2726 21.0746L54 14.9648L47.8503 12.4405L48.1744 8.82081L44.5068 7.97365L44.0462 3.90048L40.0544 3.96037L38.8091 0.0925679L35.15 1.00818L32.9238 -2.33765L29.1879 -0.694685L25 -5.81183Z" fill="#CCCCCC"/>
    <path d="M25 -5.81183V49.8692L21.8185 51.9999L19.2 48.9108L15.4897 50.2628L13.8265 47.1566L6.93471 48.8595L6.23529 42.1678L3.22441 41.5517L3.395 37.701L-0.264118 36.0324L0.998235 32.3614L-2.23441 29.9055L-0.136177 26.5768L-2.89118 23.4706L-0.264118 21.0746L-4 14.9648L2.14971 12.4405L1.82559 8.82081L5.49323 7.97365L5.95382 3.90048L9.94559 3.96037L11.1909 0.0925679L14.85 1.00818L17.0762 -2.33765L20.8121 -0.694685L25 -5.81183Z" fill="#CCCCCC"/>
    <path d="M17.0759 -2.33752L24.0786 21.5282L20.8118 -0.694562L17.0759 -2.33752Z" fill="#969696"/>
    <path d="M11.1907 0.0925903L22.9527 25.9008L14.8498 1.0082L11.1907 0.0925903Z" fill="#969696"/>
    <path d="M5.95337 3.90057L21.7498 24.5574L9.94513 3.96047L5.95337 3.90057Z" fill="#969696"/>
    <path d="M1.82544 8.82091L21.3237 24.5574L5.49309 7.97375L1.82544 8.82091Z" fill="#969696"/>
    <path d="M-4 14.9649L21.3238 24.5574L2.14971 12.4406L-4 14.9649Z" fill="#969696"/>
    <path d="M-2.89087 23.4707L21.3241 24.5574L-0.26381 21.0747L-2.89087 23.4707Z" fill="#969696"/>
    <path d="M-2.23462 29.9056L21.7501 24.5574L-0.136384 26.5769L-2.23462 29.9056Z" fill="#969696"/>
    <path d="M-0.26416 36.0325L22.3388 24.5574L0.998193 32.3615L-0.26416 36.0325Z" fill="#969696"/>
    <path d="M3.22461 41.5518L22.6461 25.2334L3.3952 37.7011L3.22461 41.5518Z" fill="#969696"/>
    <path d="M6.93476 48.8595L23.5501 25.2334L6.23535 42.1679L6.93476 48.8595Z" fill="#969696"/>
    <path d="M15.4897 50.2629L24.6588 25.2334L13.8264 47.1567L15.4897 50.2629Z" fill="#969696"/>
    <path d="M21.8185 52L24.4114 25.9009L19.2 48.9109L21.8185 52Z" fill="#969696"/>
    <path d="M28.1815 52L25 24.4718V49.8693L28.1815 52Z" fill="#969696"/>
    <path d="M30.8 48.9109L25 24.4718L34.5103 50.2629L30.8 48.9109Z" fill="#969696"/>
    <path d="M43.0653 48.8595L25 24.4718L36.1735 47.1567L43.0653 48.8595Z" fill="#969696"/>
    <path d="M46.7756 41.5518L25 24.4718L43.7647 42.1679L46.7756 41.5518Z" fill="#969696"/>
    <path d="M50.2726 36.0324L25 24.4718L46.605 37.7011L50.2726 36.0324Z" fill="#969696"/>
    <path d="M52.2344 29.9056L25 24.4718L49.0018 32.3615L52.2344 29.9056Z" fill="#969696"/>
    <path d="M52.8826 23.4707L25 24.4719L50.1362 26.5769L52.8826 23.4707Z" fill="#969696"/>
    <path d="M54 14.9649L25 24.4718L50.2726 21.0747L54 14.9649Z" fill="#969696"/>
    <path d="M48.1744 8.82092L25 24.4719L47.8503 12.4406L48.1744 8.82092Z" fill="#969696"/>
    <path d="M44.0462 3.90057L25 24.4718L44.5068 7.97375L44.0462 3.90057Z" fill="#969696"/>
    <path d="M38.8091 0.0925903L25 24.4718L40.0544 3.9604L38.8091 0.0925903Z" fill="#969696"/>
    <path d="M32.9238 -2.33752L25 24.4719L35.15 1.0083L32.9238 -2.33752Z" fill="#969696"/>
    <path d="M25 -5.81183V24.4717L29.1879 -0.694685L25 -5.81183Z" fill="#969696"/>
    <path d="M47.8931 24.4717C47.8937 27.6509 47.2359 30.7955 45.9615 33.7062C44.687 36.617 42.8237 39.2303 40.4896 41.3806C39.7055 42.0986 38.8734 42.7619 37.999 43.3658C37.3481 43.8211 36.6733 44.241 35.9775 44.6237C33.3199 46.088 30.3962 47.0017 27.3799 47.3106C26.5894 47.3937 25.795 47.4336 25.0001 47.4304C21.1949 47.4344 17.4486 46.4875 14.0996 44.675C13.6475 44.4269 13.1954 44.1616 12.769 43.8878C11.3856 43.0019 10.099 41.9722 8.93074 40.8158C6.24736 38.1571 4.26028 34.8745 3.1461 31.2597C2.03192 27.6449 1.82515 23.81 2.54419 20.0957C3.26322 16.3815 4.88577 12.903 7.26763 9.9694C9.64949 7.03582 12.7169 4.73804 16.1971 3.2803C19.6774 1.82255 23.4627 1.25 27.2167 1.61352C30.9707 1.97704 34.577 3.26538 37.7151 5.36403C40.8532 7.46269 43.4259 10.3066 45.2045 13.6431C46.9831 16.9796 47.9125 20.7052 47.9102 24.4888L47.8931 24.4717Z" fill="#CCCCCC"/>
    <path d="M37.9988 43.3659C37.3479 43.8212 36.6731 44.2411 35.9773 44.6238C34.5693 45.3939 33.0859 46.0161 31.5506 46.4807C31.5506 46.4807 30.7573 46.7203 29.4779 46.9941C28.5226 47.1995 27.3797 47.3107 27.3797 47.3107C26.5892 47.3937 25.7948 47.4337 25 47.4305C21.1947 47.4345 17.4484 46.4875 14.0994 44.6751C13.6473 44.427 13.1953 44.1617 12.7688 43.8879L20.5988 33.7648H31.0644L37.9988 43.3659Z" fill="#748FFC"/>
    <path d="M41.146 38.6338C41.1462 39.5886 40.9212 40.5299 40.4893 41.3806C39.1211 42.6402 37.6061 43.7292 35.9772 44.6238C35.6947 44.6647 35.4097 44.6848 35.1243 44.6837C33.9295 44.6837 32.7617 44.3281 31.7684 43.6621C30.7751 42.996 30.0011 42.0493 29.5443 40.9417C29.0875 39.8342 28.9684 38.6156 29.2021 37.4402C29.4358 36.2647 30.0118 35.1852 30.8572 34.3383C31.7026 33.4913 32.7794 32.915 33.9514 32.6822C35.1234 32.4494 36.3378 32.5706 37.4411 33.0305C38.5444 33.4903 39.487 34.2682 40.1495 35.2656C40.812 36.263 41.1648 37.4352 41.1631 38.6338H41.146Z" fill="#CCCCCC"/>
    <path d="M8.6318 38.5739C8.6316 39.5287 8.85666 40.4699 9.28856 41.3207C10.6567 42.5803 12.1718 43.6693 13.8006 44.5639C14.0831 44.6048 14.3682 44.6248 14.6536 44.6238C15.8483 44.6238 17.0162 44.2682 18.0094 43.6021C19.0027 42.936 19.7767 41.9893 20.2335 40.8818C20.6903 39.7743 20.8094 38.5557 20.5757 37.3802C20.342 36.2048 19.766 35.1253 18.9206 34.2783C18.0752 33.4314 16.9984 32.8551 15.8264 32.6223C14.6545 32.3895 13.44 32.5107 12.3367 32.9705C11.2334 33.4304 10.2908 34.2082 9.62831 35.2057C8.96579 36.2031 8.61305 37.3753 8.61474 38.5739H8.6318Z" fill="#CCCCCC"/>
    <path d="M31.3542 39.9517C31.395 40.1489 31.4763 40.3354 31.5931 40.4993C31.8388 40.8534 32.1828 41.1272 32.5825 41.2866C33.0892 41.4981 33.6388 41.5861 34.186 41.5433C34.4716 41.5349 34.7565 41.5092 35.039 41.4663C35.3012 41.4266 35.5605 41.3694 35.8151 41.2951L36.5742 41.047H36.6339C36.6339 40.9956 36.5742 40.9528 36.5487 40.9101C36.3725 40.6991 36.162 40.5197 35.926 40.3795C35.6815 40.2316 35.4173 40.1192 35.1413 40.0458C34.8133 39.9709 34.4794 39.925 34.1434 39.9089H32.6763C32.2669 39.9089 31.8234 39.9089 31.4481 39.9773L31.3542 39.9517Z" fill="#969696"/>
    <path d="M34.8342 38.8906L34.9451 39.0703C35.0848 39.2764 35.2636 39.4529 35.4715 39.5895C35.6794 39.7261 35.9121 39.82 36.1563 39.8661C36.3866 39.9128 36.6207 39.9386 36.8557 39.9431C37.0915 39.9305 37.3256 39.8961 37.5551 39.8404C37.7929 39.789 38.0266 39.7203 38.2545 39.635C38.4867 39.5499 38.712 39.4469 38.9284 39.327C39.2354 39.1558 39.5169 38.9676 39.7813 38.7793H39.8325L39.6619 38.6338C39.2904 38.361 38.8501 38.1979 38.391 38.1632C37.8356 38.1312 37.2788 38.1948 36.7448 38.3515C36.1136 38.5226 35.491 38.7194 34.8598 38.9077L34.8342 38.8906Z" fill="#969696"/>
    <path d="M40.5747 15.6409C40.8022 15.3672 40.9577 15.0406 41.0268 14.691C41.1268 14.1927 41.0824 13.676 40.8988 13.2021C40.7662 12.8502 40.5946 12.5143 40.3871 12.2009C40.1837 11.8924 39.9494 11.6057 39.6877 11.3452C39.4574 11.1142 39.21 10.9002 38.9712 10.6778L38.8944 10.6179C38.8215 10.784 38.7727 10.9598 38.7494 11.1398C38.7208 11.4598 38.7351 11.7821 38.7921 12.0982C38.8578 12.4657 38.9726 12.8226 39.1333 13.1593C39.5449 14.0125 40.0151 14.836 40.5406 15.6238L40.5747 15.6409Z" fill="#969696"/>
    <path d="M39.3977 14.4344C39.0989 13.5422 38.5448 12.7581 37.8046 12.1798C37.0644 11.6015 36.1708 11.2547 35.2354 11.1827C35.5887 12.0451 36.1377 12.8128 36.8389 13.4247C37.5449 14.056 38.4522 14.414 39.3977 14.4344Z" fill="#969696"/>
    <path d="M42.3658 35.8185C42.1812 35.7004 41.9756 35.6191 41.7602 35.5789C41.2218 35.4655 40.6616 35.5223 40.1567 35.7415C39.5624 35.9925 38.9915 36.2961 38.4508 36.6486C38.212 36.794 37.9817 36.9395 37.7258 37.1192L37.8111 37.1791C38.1403 37.5233 38.5898 37.7258 39.065 37.7439C39.6367 37.766 40.2017 37.6138 40.6855 37.3075C41.2993 36.9432 41.8473 36.4774 42.3061 35.9298L42.3658 35.8185Z" fill="#969696"/>
    <path d="M44.1484 32.3786L43.6878 32.3187C43.3771 32.2912 43.0643 32.3381 42.7751 32.4556C42.5855 32.5236 42.4027 32.6096 42.2293 32.7123C41.7648 32.9831 41.34 33.3172 40.9669 33.7049L40.0372 34.6291L39.9519 34.7232C40.2181 34.9209 40.5344 35.0395 40.8646 35.0655C41.1773 35.0796 41.4897 35.0299 41.7829 34.9196C42.0761 34.8093 42.344 34.6406 42.5704 34.4237C42.7963 34.2228 43.0099 34.0085 43.2101 33.7819C43.545 33.3809 43.8313 32.9414 44.0631 32.4727L44.1484 32.3786Z" fill="#969696"/>
    <path d="M41.9564 19.0466C42.1014 18.9183 42.2464 18.8156 42.3658 18.6872C42.5299 18.5231 42.6603 18.3283 42.7497 18.1139C42.9045 17.7688 42.9914 17.3968 43.0055 17.0186C43.0192 16.4376 42.9327 15.8586 42.7497 15.3072C42.5979 14.8151 42.392 14.3415 42.1355 13.8953C42.1159 13.8684 42.0988 13.8397 42.0844 13.8097L41.982 13.9552C41.6596 14.4128 41.4732 14.9529 41.4447 15.5126C41.4067 16.0453 41.4411 16.5807 41.547 17.1042C41.6664 17.7117 41.7944 18.3107 41.9223 18.9097L41.9564 19.0466Z" fill="#969696"/>
    <path d="M45.1807 28.6648C44.9713 28.6461 44.7602 28.6664 44.5581 28.7247C43.9768 28.9015 43.4624 29.2505 43.0825 29.7259C42.614 30.2786 42.1945 30.8713 41.8287 31.4972L41.5813 31.9079L41.8457 31.9935C42.1814 32.0802 42.5354 32.0623 42.8607 31.9421C43.4274 31.722 43.9047 31.3185 44.2169 30.7955C44.6435 30.1639 44.9661 29.4676 45.1722 28.7332C45.1722 28.7332 45.1722 28.7332 45.1722 28.6733C45.1722 28.6134 45.1807 28.6819 45.1807 28.6648Z" fill="#969696"/>
    <path d="M43.9611 17.1812C43.7821 17.301 43.6284 17.4551 43.5091 17.6347C43.3279 17.8723 43.1815 18.1345 43.0741 18.4134C42.9289 18.819 42.8372 19.2419 42.8011 19.6713C42.7414 20.4917 42.7414 21.3154 42.8011 22.1358C42.7973 22.167 42.7973 22.1986 42.8011 22.2299L42.9888 22.1614C43.2779 22.0185 43.5329 21.8145 43.736 21.5634C43.9392 21.3123 44.0858 21.02 44.1658 20.7067C44.3131 20.19 44.3679 19.6511 44.3279 19.1151C44.2955 18.4985 44.1837 17.8888 43.9952 17.301C43.9803 17.2622 43.9689 17.2221 43.9611 17.1812Z" fill="#969696"/>
    <path d="M45.0953 20.8864C44.9931 20.9355 44.8935 20.9897 44.7968 21.049C44.2745 21.4139 43.8804 21.935 43.6709 22.5379C43.4317 23.1799 43.2601 23.8453 43.1592 24.5232C43.108 24.8141 43.0653 25.1051 43.0142 25.3789C43.0178 25.4159 43.0178 25.4531 43.0142 25.4901C43.0823 25.4942 43.1507 25.4942 43.2189 25.4901C43.5693 25.4078 43.8935 25.2385 44.1617 24.9978C44.43 24.7571 44.6337 24.4527 44.7542 24.1124C44.9879 23.5408 45.1207 22.9328 45.1465 22.3154C45.1934 21.8631 45.1934 21.4072 45.1465 20.9549C45.1347 20.9286 45.1172 20.9052 45.0953 20.8864Z" fill="#969696"/>
    <path d="M45.5136 24.8055H45.4027C44.7322 24.9895 44.1617 25.4326 43.8162 26.0378C43.4493 26.6405 43.1463 27.28 42.9121 27.946L42.6306 28.7247C42.6306 28.7247 42.6306 28.7247 42.6306 28.7846C42.8854 28.8487 43.152 28.8487 43.4068 28.7846C43.7206 28.6729 44.0087 28.4985 44.2535 28.272C44.4983 28.0455 44.6948 27.7716 44.8312 27.4668C45.0006 27.1496 45.1323 26.8136 45.2236 26.4656C45.3089 26.1062 45.3942 25.7468 45.4709 25.3789C45.4709 25.1564 45.4709 24.9767 45.5136 24.8055Z" fill="#969696"/>
    <path d="M34.3057 36.0325C33.6562 36.4857 33.0431 36.9893 32.4719 37.5385C32.0509 37.9389 31.7271 38.4306 31.5251 38.9761C31.4316 39.2225 31.3797 39.4828 31.3716 39.7462C31.3716 39.7462 31.3716 39.7976 31.3716 39.8318H31.5251C32.2373 39.6263 32.9014 39.2801 33.4783 38.8135C33.851 38.5273 34.1446 38.1503 34.3313 37.7182C34.5417 37.2088 34.5599 36.6399 34.3825 36.118C34.3588 36.0878 34.3332 36.0592 34.3057 36.0325Z" fill="#969696"/>
    <path d="M36.5232 34.4665C35.9044 35.1643 35.3849 35.9447 34.9794 36.7855C34.7391 37.3215 34.6562 37.9152 34.7405 38.4969C34.7405 38.5825 34.7405 38.6595 34.7917 38.745H34.8344C35.5311 38.3418 36.1173 37.7714 36.5402 37.085C36.7986 36.6759 36.932 36.2 36.9241 35.7158C36.9205 35.2824 36.7965 34.8586 36.5658 34.4922C36.5537 34.4805 36.5392 34.4717 36.5232 34.4665Z" fill="#969696"/>
    <path d="M36.1311 15.3927L36.839 15.7007C37.2849 15.8982 37.7473 16.0557 38.2208 16.1714C38.5803 16.2631 38.9545 16.2808 39.321 16.2234C39.6875 16.1661 40.0386 16.0348 40.3532 15.8376L40.5238 15.7178L40.3532 15.598C39.8188 15.0977 39.1723 14.7338 38.4682 14.537C38.1549 14.4501 37.8263 14.4342 37.5062 14.4905C37.1861 14.5467 36.8825 14.6737 36.6173 14.8621C36.4267 15.0104 36.2624 15.1897 36.1311 15.3927Z" fill="#969696"/>
    <path d="M38.2633 32.4299L37.9904 33.0118C37.7169 33.6035 37.5082 34.2232 37.3677 34.8601C37.2278 35.5228 37.2933 36.2125 37.5554 36.8368C37.5729 36.8848 37.5958 36.9308 37.6236 36.9737C38.1759 36.4459 38.5966 35.7951 38.8518 35.074C38.977 34.7161 39.019 34.3342 38.9747 33.9575C38.9303 33.5809 38.8008 33.2193 38.596 32.9005C38.4943 32.7373 38.3832 32.5801 38.2633 32.4299Z" fill="#969696"/>
    <path d="M39.8924 34.689L40.2677 33.8333C40.3299 33.6987 40.3785 33.5583 40.4127 33.414C40.4874 33.1326 40.5444 32.8468 40.5833 32.5582C40.6647 32.093 40.6097 31.6141 40.425 31.1797C40.2402 30.7452 39.9337 30.3739 39.5427 30.1109L39.5 30.1024C39.4403 30.3591 39.3806 30.5987 39.338 30.8468C39.2171 31.4322 39.1599 32.029 39.1674 32.6267C39.1737 33.3027 39.3882 33.9603 39.7815 34.5093L39.8924 34.689Z" fill="#969696"/>
    <path d="M38.0669 17.3353C38.246 17.515 38.4166 17.6947 38.6042 17.8572C38.7919 18.0198 39.2184 18.3878 39.534 18.6274C40.0431 19.0129 40.671 19.2067 41.3081 19.175C41.4872 19.175 41.6663 19.1237 41.8625 19.0895C41.8625 19.0895 41.8625 19.021 41.8625 19.0039L41.5554 18.4905C41.4445 18.3279 41.3251 18.1653 41.2057 18.0198C40.9635 17.7269 40.6755 17.4755 40.3528 17.2754C40.0293 17.0512 39.6456 16.9312 39.2525 16.9312C38.8594 16.9312 38.4756 17.0512 38.1522 17.2754L38.0669 17.3353Z" fill="#969696"/>
    <path d="M39.3464 19.6713L39.6108 20.0906C39.9254 20.5921 40.2944 21.057 40.7111 21.4768C40.9527 21.7239 41.2406 21.9207 41.5583 22.0558C41.8759 22.1909 42.217 22.2617 42.562 22.2641H42.6814C42.6814 22.2641 42.6814 22.2641 42.6814 22.2128C42.4837 21.4019 42.0711 20.6598 41.4873 20.0649C41.2243 19.7964 40.8876 19.6124 40.5201 19.5362C40.1526 19.4601 39.7708 19.4952 39.4232 19.6371C39.4232 19.6371 39.372 19.6713 39.3464 19.6713Z" fill="#969696"/>
    <path d="M40.1997 27.5353C40.1997 27.6294 40.1997 27.7235 40.1997 27.8091C40.1522 28.5713 40.2039 29.3365 40.3532 30.0853C40.5004 30.75 40.8561 31.3498 41.3682 31.7967L41.4876 31.8823C41.4876 31.7882 41.5473 31.7111 41.5644 31.6256C41.7393 30.9882 41.8 30.3247 41.7435 29.666C41.7235 29.2125 41.5738 28.7744 41.3121 28.4041C41.0504 28.0338 40.6879 27.747 40.2679 27.5781L40.1997 27.5353Z" fill="#969696"/>
    <path d="M40.4213 24.8654C40.5171 25.6265 40.7033 26.3734 40.9757 27.0902C41.124 27.4737 41.344 27.8251 41.6239 28.1257C41.8493 28.3763 42.1218 28.5798 42.4257 28.7247H42.4769V28.5278C42.5493 27.7842 42.459 27.0337 42.2125 26.3287C42.0942 25.9329 41.8633 25.5804 41.5481 25.3143C41.233 25.0483 40.8473 24.8804 40.4383 24.8312H40.3616L40.4213 24.8654Z" fill="#969696"/>
    <path d="M42.9116 25.5072C42.9116 25.2847 42.8604 25.0794 42.8263 24.874C42.7327 24.3013 42.5361 23.7504 42.2463 23.2481C42.0583 22.876 41.7591 22.572 41.3906 22.3787C41.0221 22.1854 40.6026 22.1124 40.1907 22.1699L40.293 22.4609C40.5034 23.0925 40.7898 23.6959 41.146 24.2579C41.3367 24.563 41.5855 24.8274 41.8783 25.0359C42.171 25.2444 42.5018 25.393 42.8518 25.473L42.9116 25.5072Z" fill="#969696"/>
    <path d="M18.6457 39.9516C18.5976 40.1464 18.5168 40.3316 18.4068 40.4992C18.16 40.8557 17.8124 41.1299 17.4089 41.2865C16.9054 41.4989 16.3584 41.587 15.8139 41.5432C15.5283 41.5349 15.2435 41.5091 14.961 41.4662C14.6961 41.4255 14.434 41.3684 14.1763 41.295L13.4257 41.064H13.366L13.4598 40.9271C13.6359 40.7161 13.8464 40.5368 14.0824 40.3965C14.327 40.2486 14.5912 40.1363 14.8671 40.0628C15.1951 39.9879 15.5291 39.9421 15.8651 39.9259H17.3236C17.733 39.9259 18.1766 39.9259 18.5433 39.9944L18.6457 39.9516Z" fill="#969696"/>
    <path d="M15.1657 38.8905L15.0548 39.0702C14.7714 39.4853 14.3362 39.7713 13.8436 39.8661C13.6105 39.9133 13.3735 39.9391 13.1357 39.9431C12.9028 39.9298 12.6716 39.8955 12.4448 39.8404C12.2042 39.7891 11.9676 39.7204 11.7369 39.635C11.5054 39.5482 11.2802 39.4453 11.0631 39.327C10.7645 39.1558 10.4745 38.9676 10.2101 38.7793H10.1589L10.3295 38.6338C10.7004 38.3597 11.141 38.1965 11.6004 38.1632C12.153 38.1316 12.7069 38.1953 13.2381 38.3514C13.8692 38.5226 14.5004 38.7194 15.1231 38.9077L15.1657 38.8905Z" fill="#969696"/>
    <path d="M9.4254 15.6409C9.19545 15.3677 9.03714 15.0412 8.96481 14.691C8.87383 14.1922 8.91801 13.678 9.09275 13.2021C9.23562 12.8467 9.42184 12.5104 9.64716 12.2009C9.85052 11.8924 10.0849 11.6057 10.3466 11.3452C10.5769 11.1142 10.8242 10.9002 11.063 10.6778C11.063 10.6778 11.1057 10.6778 11.1313 10.6179C11.212 10.7811 11.2612 10.9582 11.2763 11.1398C11.319 11.4579 11.319 11.7802 11.2763 12.0982C11.2026 12.4637 11.0882 12.8196 10.9351 13.1593C10.5238 14.0144 10.0506 14.8381 9.51922 15.6238L9.4254 15.6409Z" fill="#969696"/>
    <path d="M10.6023 14.4344C10.9029 13.5353 11.4632 12.7461 12.2119 12.1671C12.9607 11.5881 13.864 11.2455 14.8073 11.1827C14.4481 12.0418 13.8999 12.8082 13.2038 13.4247C12.4859 14.0642 11.5623 14.4227 10.6023 14.4344Z" fill="#969696"/>
    <path d="M7.62549 35.8185C7.81015 35.7004 8.01576 35.6191 8.23108 35.579C8.7723 35.4654 9.33526 35.5222 9.84314 35.7415C10.4374 35.9925 11.0083 36.2961 11.549 36.6486C11.7793 36.7941 12.0181 36.9395 12.274 37.1192C12.2415 37.1372 12.2102 37.1572 12.1802 37.1791C11.8547 37.5236 11.4077 37.7263 10.9349 37.7439C10.3605 37.7661 9.7927 37.6141 9.30578 37.3075C8.69198 36.9433 8.14405 36.4775 7.6852 35.9298L7.62549 35.8185Z" fill="#969696"/>
    <path d="M5.84302 32.3786L6.3036 32.3187C6.61712 32.2912 6.93269 32.3381 7.22478 32.4556C7.41374 32.5253 7.59637 32.6112 7.77066 32.7123C8.23362 32.9853 8.65816 33.3192 9.03302 33.705L9.96272 34.6291L10.0395 34.7232C9.7773 34.9224 9.46333 35.0413 9.13537 35.0655C8.82257 35.0796 8.51018 35.03 8.21701 34.9197C7.92383 34.8094 7.65595 34.6407 7.42949 34.4238C7.20159 34.2251 6.98784 34.0106 6.78978 33.782C6.45492 33.3809 6.16861 32.9414 5.93684 32.4727L5.84302 32.3786Z" fill="#969696"/>
    <path d="M8.04365 19.0466C7.89865 18.9183 7.75365 18.8156 7.62571 18.6872C7.46437 18.5209 7.33434 18.3267 7.24189 18.1139C7.09103 17.7674 7.00433 17.3963 6.986 17.0186C6.97505 16.4371 7.06451 15.8581 7.25042 15.3072C7.40213 14.8151 7.60811 14.3415 7.86453 13.8953L7.91571 13.8097L8.01806 13.9552C8.33681 14.4147 8.52277 14.9537 8.55542 15.5126C8.59062 16.0457 8.55331 16.5811 8.44453 17.1042C8.33365 17.7117 8.19718 18.3107 8.07777 18.9097L8.04365 19.0466Z" fill="#969696"/>
    <path d="M4.81958 28.6648C5.02904 28.6461 5.24013 28.6664 5.44223 28.7247C6.02349 28.9014 6.53787 29.2504 6.91781 29.7258C7.38352 30.2788 7.8002 30.8715 8.16311 31.4972C8.2484 31.6255 8.32517 31.7539 8.41046 31.9079L8.15458 31.9935C7.8189 32.0802 7.46486 32.0623 7.13958 31.9421C6.57153 31.7243 6.09358 31.3202 5.7834 30.7955C5.35002 30.1678 5.02693 29.4703 4.82811 28.7332C4.82811 28.7332 4.82811 28.7332 4.82811 28.6733C4.82502 28.6707 4.82217 28.6679 4.81958 28.6648Z" fill="#969696"/>
    <path d="M6.03885 17.1812C6.21407 17.3056 6.36694 17.459 6.49091 17.6347C6.66917 17.8741 6.81541 18.1359 6.92591 18.4134C7.06406 18.8206 7.15284 19.2429 7.19032 19.6713C7.25001 20.4917 7.25001 21.3154 7.19032 22.1358C7.19032 22.1358 7.19032 22.1957 7.19032 22.2299L6.99415 22.1614C6.70693 22.0158 6.45362 21.8109 6.25078 21.5602C6.04794 21.3095 5.90015 21.0187 5.81709 20.7067C5.67253 20.1895 5.62055 19.6506 5.66356 19.1151C5.69724 18.4994 5.80609 17.8901 5.98768 17.301L6.03885 17.1812Z" fill="#969696"/>
    <path d="M4.90466 20.8864L5.19466 21.049C5.71976 21.413 6.1168 21.9341 6.32908 22.5379C6.5644 23.1812 6.73582 23.8461 6.84084 24.5232C6.89202 24.8141 6.93467 25.1051 6.97731 25.3789C6.97731 25.3789 6.97731 25.4388 6.97731 25.4901C6.90916 25.4948 6.84076 25.4948 6.77261 25.4901C6.42219 25.4078 6.09799 25.2385 5.82975 24.9978C5.5615 24.7571 5.35779 24.4527 5.23731 24.1124C5.00356 23.5408 4.87079 22.9328 4.84496 22.3155C4.80233 21.8629 4.80233 21.4074 4.84496 20.9549C4.86181 20.9296 4.88188 20.9065 4.90466 20.8864Z" fill="#969696"/>
    <path d="M4.52948 24.8055H4.64036C5.31001 24.9912 5.87991 25.4339 6.22683 26.0378C6.57899 26.6422 6.8677 27.2817 7.0883 27.946C7.18213 28.2027 7.27595 28.468 7.36124 28.7247C7.36233 28.7446 7.36233 28.7646 7.36124 28.7846C7.10358 28.8487 6.8342 28.8487 6.57654 28.7846C6.26473 28.671 5.97879 28.4959 5.7356 28.2695C5.49241 28.0432 5.29691 27.7702 5.16066 27.4668C4.98765 27.1513 4.85578 26.8148 4.7683 26.4656C4.67448 26.1062 4.59771 25.7468 4.52095 25.3789C4.51376 25.1877 4.51661 24.9964 4.52948 24.8055Z" fill="#969696"/>
    <path d="M15.6943 36.0325H15.737C16.3865 36.4857 16.9996 36.9893 17.5708 37.5385C17.9917 37.9389 18.3156 38.4306 18.5175 38.9761C18.6083 39.2229 18.6573 39.4832 18.6625 39.7462V39.8318H18.5005C17.789 39.6244 17.1253 39.2784 16.5473 38.8135C16.1746 38.5273 15.881 38.1503 15.6943 37.7182C15.4902 37.2164 15.4721 36.6578 15.6431 36.1437C15.651 36.103 15.6686 36.0649 15.6943 36.0325Z" fill="#969696"/>
    <path d="M13.4681 34.4665C14.0898 35.1621 14.6096 35.9429 15.012 36.7855C15.2551 37.3207 15.341 37.9144 15.2593 38.4969C15.2593 38.5825 15.2593 38.6595 15.2081 38.745H15.1655C14.4687 38.3418 13.8826 37.7714 13.4596 37.085C13.1987 36.6767 13.0624 36.2008 13.0673 35.7158C13.0738 35.2817 13.2008 34.8579 13.434 34.4922L13.4681 34.4665Z" fill="#969696"/>
    <path d="M13.9118 15.3928C13.6645 15.504 13.4342 15.6067 13.1954 15.7008C12.753 15.8996 12.2933 16.0571 11.8221 16.1714C11.4563 16.2698 11.0741 16.2909 10.6997 16.2334C10.3254 16.176 9.96695 16.0412 9.64713 15.8377L9.46802 15.7179L9.58743 15.5981C10.1382 15.0885 10.8064 14.7239 11.5321 14.537C11.844 14.4503 12.1712 14.4345 12.4899 14.4908C12.8087 14.547 13.1109 14.6739 13.3745 14.8622C13.5852 15.0037 13.7675 15.1837 13.9118 15.3928Z" fill="#969696"/>
    <path d="M11.7368 32.4299C11.8391 32.6438 11.9244 32.8235 12.0012 33.0118C12.2783 33.602 12.4872 34.2222 12.6238 34.8601C12.7709 35.5216 12.7083 36.2127 12.4447 36.8368C12.4272 36.8848 12.4043 36.9308 12.3765 36.9737C11.8188 36.4487 11.3947 35.7973 11.1397 35.074C11.0146 34.7161 10.9725 34.3342 11.0169 33.9575C11.0612 33.5809 11.1908 33.2193 11.3956 32.9005C11.498 32.7123 11.6174 32.5839 11.7368 32.4299Z" fill="#969696"/>
    <path d="M10.1074 34.689C9.97095 34.3895 9.84301 34.1242 9.7236 33.8333C9.66796 33.6971 9.62234 33.5569 9.58713 33.414C9.50819 33.1336 9.45115 32.8475 9.41654 32.5583C9.33316 32.0939 9.38635 31.6152 9.56962 31.1806C9.75289 30.746 10.0583 30.3743 10.4486 30.111L10.5254 30.0682C10.5765 30.3249 10.6362 30.5645 10.6874 30.8126C10.8083 31.398 10.8655 31.9948 10.858 32.5925C10.8474 33.2678 10.6333 33.9241 10.2439 34.4751L10.1074 34.689Z" fill="#969696"/>
    <path d="M11.9328 17.3353C11.7537 17.515 11.5831 17.6947 11.3954 17.8572C11.2078 18.0198 10.7813 18.3878 10.4572 18.6274C9.95173 19.0136 9.32617 19.2077 8.6916 19.175C8.50396 19.175 8.32484 19.1237 8.12866 19.0895V19.0039C8.23102 18.8327 8.32484 18.6616 8.43572 18.4905C8.54261 18.3266 8.6594 18.1694 8.78543 18.0198C9.02799 17.724 9.31938 17.4722 9.6469 17.2754C9.97034 17.0512 10.3541 16.9312 10.7472 16.9312C11.1403 16.9312 11.524 17.0512 11.8475 17.2754L11.9243 17.3353H11.9328Z" fill="#969696"/>
    <path d="M10.6451 19.6713C10.5598 19.8168 10.4745 19.9537 10.3806 20.0906C10.0715 20.5959 9.702 21.0614 9.28035 21.4768C8.79446 21.9738 8.13183 22.2569 7.438 22.2641H7.31006V22.2128C7.51451 21.4027 7.92951 20.6615 8.51271 20.0649C8.77578 19.7964 9.11243 19.6124 9.47994 19.5362C9.84745 19.4601 10.2292 19.4952 10.5768 19.6371L10.6451 19.6713Z" fill="#969696"/>
    <path d="M9.8006 27.5353C9.8006 27.6294 9.8006 27.7235 9.8006 27.8091C9.85227 28.5713 9.80062 29.337 9.64707 30.0853C9.50334 30.7514 9.14699 31.3522 8.63207 31.7967L8.51266 31.8823C8.51266 31.7882 8.46148 31.7111 8.44442 31.6256C8.26659 30.9887 8.20306 30.3252 8.25677 29.666C8.27638 29.2113 8.42701 28.772 8.69043 28.4014C8.95385 28.0308 9.31874 27.7448 9.74089 27.5781L9.8006 27.5353Z" fill="#969696"/>
    <path d="M9.5789 24.8654C9.48304 25.6265 9.29692 26.3734 9.02449 27.0902C8.87312 27.4721 8.65343 27.823 8.37626 28.1257C8.15085 28.3763 7.8784 28.5798 7.57449 28.7247H7.51478C7.51019 28.6591 7.51019 28.5934 7.51478 28.5278C7.43899 27.7842 7.52932 27.0329 7.7792 26.3287C7.89553 25.9318 8.12584 25.5781 8.44137 25.3118C8.75689 25.0454 9.14365 24.8783 9.55331 24.8312H9.63008L9.5789 24.8654Z" fill="#969696"/>
    <path d="M7.08862 25.5073C7.08862 25.2848 7.1398 25.0794 7.17392 24.8741C7.26752 24.3014 7.46404 23.7505 7.75392 23.2482C7.9404 22.8767 8.23801 22.5731 8.60508 22.3797C8.97215 22.1864 9.3903 22.1131 9.80098 22.17L9.69862 22.461C9.48827 23.0926 9.20184 23.696 8.84568 24.258C8.45708 24.871 7.8448 25.3071 7.1398 25.4731L7.08862 25.5073Z" fill="#969696"/>
    <path d="M24.9996 37.2047C32.0091 37.2047 37.6914 31.504 37.6914 24.4717C37.6914 17.4395 32.0091 11.7388 24.9996 11.7388C17.9902 11.7388 12.3079 17.4395 12.3079 24.4717C12.3079 31.504 17.9902 37.2047 24.9996 37.2047Z" fill="#748FFC"/>
    <path d="M24.9998 19.3375C23.6199 19.3375 22.2965 19.8875 21.3208 20.8664C20.345 21.8453 19.7969 23.173 19.7969 24.5573H30.2028C30.2028 23.173 29.6546 21.8453 28.6788 20.8664C27.7031 19.8875 26.3797 19.3375 24.9998 19.3375Z" fill="#CCCCCC"/>
    <path d="M33.6866 13.8769L24.8596 24.4418L25.1538 24.6892L33.9807 14.1243L33.6866 13.8769Z" fill="#CCCCCC"/>
    <path d="M11.5051 21.9762L11.4385 22.3554L24.9706 24.7492L25.0373 24.37L11.5051 21.9762Z" fill="#CCCCCC"/>
    <path d="M12.1591 19.659L12.0278 20.0209L24.94 24.7358L25.0713 24.3739L12.1591 19.659Z" fill="#CCCCCC"/>
    <path d="M13.1946 17.5026L13.0027 17.8361L24.9026 24.7288L25.0946 24.3953L13.1946 17.5026Z" fill="#CCCCCC"/>
    <path d="M24.872 24.7028L14.3552 15.8463L14.6026 15.5468L25.1279 24.4119L24.872 24.7028Z" fill="#CCCCCC"/>
    <path d="M16.3182 13.8744L16.0242 14.1219L24.8566 24.6822L25.1507 24.4347L16.3182 13.8744Z" fill="#CCCCCC"/>
    <path d="M18.2994 12.5257L17.967 12.7183L24.8375 24.6568L25.1699 24.4643L18.2994 12.5257Z" fill="#CCCCCC"/>
    <path d="M20.481 11.5449L20.1204 11.6766L24.82 24.6308L25.1807 24.499L20.481 11.5449Z" fill="#CCCCCC"/>
    <path d="M22.8077 10.956L22.4297 11.0229L24.8158 24.5989L25.1938 24.5321L22.8077 10.956Z" fill="#CCCCCC"/>
    <path d="M25.1875 10.7719H24.8037V24.5573H25.1875V10.7719Z" fill="#CCCCCC"/>
    <path d="M27.2032 10.9522L24.803 24.5257L25.1809 24.593L27.5812 11.0194L27.2032 10.9522Z" fill="#CCCCCC"/>
    <path d="M29.515 11.536L24.8289 24.4951L25.1897 24.6264L29.8758 11.6673L29.515 11.536Z" fill="#CCCCCC"/>
    <path d="M31.7044 12.5247L24.834 24.4633L25.1664 24.6559L32.0368 12.7173L31.7044 12.5247Z" fill="#CCCCCC"/>
    <path d="M25.128 24.7028L24.8721 24.4119L35.3974 15.5468L35.6447 15.8463L25.128 24.7028Z" fill="#CCCCCC"/>
    <path d="M36.806 17.5066L24.906 24.3993L25.0979 24.7328L36.9979 17.84L36.806 17.5066Z" fill="#CCCCCC"/>
    <path d="M37.7946 19.7529L24.8782 24.4399L25.0087 24.8019L37.9251 20.1148L37.7946 19.7529Z" fill="#CCCCCC"/>
    <path d="M38.504 21.9815L24.9702 24.3658L25.0366 24.7451L38.5704 22.3608L38.504 21.9815Z" fill="#CCCCCC"/>
    <path d="M25 36.7C21.8002 36.696 18.7297 35.4324 16.4488 33.1809C14.168 30.9294 12.8588 27.8699 12.803 24.6602H37.1971C37.1434 27.8706 35.835 30.9314 33.5536 33.1834C31.2723 35.4353 28.2005 36.6982 25 36.7Z" fill="#CCCCCC"/>
    <path d="M37.0006 24.8569C36.8836 27.9715 35.568 30.9194 33.3302 33.0814C31.0924 35.2434 28.1065 36.4513 24.9998 36.4513C21.893 36.4513 18.9072 35.2434 16.6694 33.0814C14.4315 30.9194 13.1159 27.9715 12.9989 24.8569H37.0006ZM37.3845 24.4718H12.6492C12.8156 27.6499 14.1912 30.6427 16.4923 32.8332C18.7935 35.0237 21.8447 36.2448 25.0168 36.2448C28.189 36.2448 31.2402 35.0237 33.5413 32.8332C35.8424 30.6427 37.218 27.6499 37.3845 24.4718Z" fill="#969696"/>
    <path d="M22.3554 27.509V28.384H20.2567V32.5061H19.1971V27.509H22.3554ZM25.0555 32.5061H23.996V28.3909H22.639V27.509H26.4125V28.3909H25.0555V32.5061ZM31.7172 30.0007C31.7172 30.8279 31.5121 31.4636 31.1019 31.908C30.6918 32.3523 30.1039 32.5745 29.3383 32.5745C28.5726 32.5745 27.9847 32.3523 27.5746 31.908C27.1644 31.4636 26.9594 30.8256 26.9594 29.9939C26.9594 29.1622 27.1644 28.5276 27.5746 28.0901C27.987 27.6503 28.5772 27.4304 29.3451 27.4304C30.113 27.4304 30.6998 27.6514 31.1054 28.0935C31.5132 28.5356 31.7172 29.1713 31.7172 30.0007ZM28.0702 30.0007C28.0702 30.559 28.1762 30.9794 28.3881 31.262C28.6 31.5445 28.9167 31.6858 29.3383 31.6858C30.1836 31.6858 30.6063 31.1241 30.6063 30.0007C30.6063 28.8751 30.1859 28.3123 29.3451 28.3123C28.9235 28.3123 28.6057 28.4547 28.3915 28.7395C28.1773 29.0221 28.0702 29.4425 28.0702 30.0007Z" fill="#748FFC"/>
    <path d="M27.5589 45.4538H22.4839V44.3927L24.3007 42.5529C24.838 41.9967 25.1536 41.6202 25.3498 41.4063C25.4925 41.2246 25.6127 41.0264 25.708 40.8158C25.7756 40.6353 25.8103 40.444 25.8104 40.2511C25.818 40.1319 25.8007 40.0126 25.7596 39.9006C25.7184 39.7886 25.6544 39.6864 25.5716 39.6007C25.485 39.5233 25.3837 39.4644 25.2737 39.4276C25.1637 39.3909 25.0474 39.377 24.9319 39.3868C24.636 39.3834 24.3436 39.4509 24.0789 39.5836C23.7735 39.7307 23.4871 39.9146 23.226 40.1313L22.373 39.1472C22.6349 38.9023 22.9207 38.6844 23.226 38.4969C23.4672 38.3735 23.722 38.2787 23.9851 38.2145C24.2898 38.1433 24.6019 38.1088 24.9148 38.1118C25.3267 38.1 25.7356 38.185 26.1089 38.3599C26.4396 38.5085 26.7208 38.749 26.9192 39.0531C27.1103 39.3576 27.2081 39.7116 27.2007 40.0714C27.201 40.3929 27.1432 40.7118 27.0301 41.0126C26.9327 41.3327 26.792 41.6379 26.6122 41.9197C26.2098 42.3849 25.7767 42.8223 25.3157 43.2289L24.386 44.0846V44.1531H27.5589V45.4538Z" fill="#D8D8D8"/>
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="-4" y="-6" width="58" height="58">
    <path d="M25 -6V49.8623L28.1815 52L30.8 48.9008L34.5103 50.2573L36.1735 47.1409L43.0653 48.8493L43.7647 42.1359L46.7756 41.5178L46.605 37.6545L50.2726 35.9805L49.0018 32.2975L52.2344 29.8336L50.1362 26.4941L52.8826 23.3777L50.2726 20.9739L54 14.8443L47.8503 12.3117L48.1744 8.68029L44.5068 7.83037L44.0462 3.74393L40.0544 3.80403L38.8091 -0.0763764L35.15 0.842215L32.9238 -2.51451L29.1879 -0.866193L25 -6Z" fill="#CCCCCC"/>
    <path d="M25 -6V49.8623L21.8185 52L19.2 48.9008L15.4897 50.2573L13.8265 47.1409L6.93471 48.8493L6.23529 42.1359L3.22441 41.5178L3.395 37.6545L-0.264118 35.9805L0.998235 32.2975L-2.23441 29.8336L-0.136177 26.4941L-2.89118 23.3777L-0.264118 20.9739L-4 14.8443L2.14971 12.3117L1.82559 8.68029L5.49323 7.83037L5.95382 3.74393L9.94559 3.80403L11.1909 -0.0763764L14.85 0.842215L17.0762 -2.51451L20.8121 -0.866193L25 -6Z" fill="#CCCCCC"/>
    </mask>
    <g mask="url(#mask0)">
    <path opacity="0.5" d="M8.73386 29.3726C29.364 29.3726 46.0881 12.5936 46.0881 -8.10437C46.0881 -28.8023 29.364 -45.5813 8.73386 -45.5813C-11.8963 -45.5813 -28.6204 -28.8023 -28.6204 -8.10437C-28.6204 12.5936 -11.8963 29.3726 8.73386 29.3726Z" fill="url(#paint0_linear)" fill-opacity="0.6"/>
    </g>
    </g>
    <rect x="-1046.5" y="-30.5" width="1768" height="796" rx="4.5" stroke="#7B61FF" strokeDasharray="10 5"/>
    <defs>
    <linearGradient id="paint0_linear" x1="-4.392" y1="-32.7916" x2="24.6758" y2="21.508" gradientUnits="userSpaceOnUse">
    <stop stopColor="white" stopOpacity="0"/>
    <stop offset="0.35" stopColor="white" stopOpacity="0.01"/>
    <stop offset="0.49" stopColor="white" stopOpacity="0.03"/>
    <stop offset="0.59" stopColor="white" stopOpacity="0.08"/>
    <stop offset="0.67" stopColor="white" stopOpacity="0.15"/>
    <stop offset="0.74" stopColor="white" stopOpacity="0.24"/>
    <stop offset="0.8" stopColor="white" stopOpacity="0.35"/>
    <stop offset="0.85" stopColor="white" stopOpacity="0.47"/>
    <stop offset="0.9" stopColor="white" stopOpacity="0.62"/>
    <stop offset="0.95" stopColor="white" stopOpacity="0.79"/>
    <stop offset="1" stopColor="white"/>
    </linearGradient>
    <clipPath id="clip0">
    <rect width="50" height="50" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);

export default IconSilverGto;
