import { ElementRef, FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Modal, Typography, useMediaQuery } from '@mui/material';
import { useAppSelector, useDataLoad, usePreloadImages } from 'hooks';
import { IconClose } from 'icons/edit';
import IconSearch from 'icons/IconSearch';
import { placeholderWatch } from 'images';
import FixedWidthContainer from 'portfolio3/components/common/FixedWidthContainer';
import { emitYMEvent } from 'portfolio3/features/yandexMetrika';
import { commonTheme } from 'portfolio3/styles/theme';
import { Button } from 'portfolio3/ui-kit/button';
import { SxStyles } from 'types';
import { getDataLoadRequests, isDefined, mergeSx } from 'utils';

import { LoadingPlaceholder, NotFoundPlaceholder, SearchLoader } from '../../components/Indicators';
import SearchBar from '../../components/SearchBar';
import {
  useSearchCivil,
  useSearchCreation,
  useSearchCulture,
  useSearchProfession,
  useSearchProfile,
  useSearchScience,
  useSearchSport,
  useSearchStudy,
} from '../../hooks';
import { collection as allSearchActions, searchPanelActions } from '../../model/actions';
import SearcherResultSections from './SearcherResultSections';
import * as styles from './styles';

interface ISectionState {
  loading: boolean;
  visible: boolean;
}

interface IPortfolioSearcher {
  searchButtonRef: ElementRef<'button'> | null;
}

const PortfolioSearcher: FC<IPortfolioSearcher> = ({ searchButtonRef }) => {
  const dispatch = useDispatch();
  const { isProfileLoading, isProfileVisible, profileActions, profileEntitiesCount } = useSearchProfile();
  const { isStudyLoading, isStudyVisible, studyActions, studyEntitiesCount } = useSearchStudy();
  const { isScienceLoading, isScienceVisible, scienceActions, scienceEntitiesCount } = useSearchScience();
  const { isSportLoading, isSportVisible, sportActions, sportEntitiesCount } = useSearchSport();
  const { isCreationLoading, isCreationVisible, creationActions, creationEntitiesCount } = useSearchCreation();
  const { isCultureLoading, isCultureVisible, culureActions, cultureEntitiesCount } = useSearchCulture();
  const { isCivilLoading, isCivilVisible, civilActions, civilEntitiesCount } = useSearchCivil();
  const { isProfessionLoading, isProfessionVisible, professionActions, professionEntitiesCount } =
    useSearchProfession();

  const searchQuery = useAppSelector((state) => state.searchPanel.query);

  // первичная загрузка недостающих данных
  useDataLoad({ shouldLoad: true, requests: getDataLoadRequests().searchEntryMethods });

  // По каким-то причинам именно эта картинка не кешируется и из-за долгой загрузки она не успевает отобразиться
  usePreloadImages([placeholderWatch]);
  const isMobileView = useMediaQuery(commonTheme.breakpoints.down('commonMd'));

  // состояние показывает, что поиск был запущен хотя бы 1 раз
  const [hasSearched, setHasSearched] = useState(false);
  // состояние показывает, что в данный момент выполняется хотя бы один из методов поиска, через нажатие на кнопку
  const [isManualSearching, setManualSearching] = useState(false);

  // формирование экшенов, которые будут вызваны при поиске с учетом настроек видимости
  const searchActions = [
    profileActions,
    studyActions,
    scienceActions,
    sportActions,
    creationActions,
    culureActions,
    civilActions,
    professionActions,
  ];
  const flatSearchActions = searchActions.flatMap((action) => action);
  const uniqueSearchActions = new Set(flatSearchActions);
  const filteredSearchActions = Array.from(uniqueSearchActions)
    .map((action) => (action ? action : null))
    .filter(isDefined);

  const sectionStates: ISectionState[] = [
    { loading: isProfileLoading, visible: isProfileVisible },
    { loading: isStudyLoading, visible: isStudyVisible },
    { loading: isScienceLoading, visible: isScienceVisible },
    { loading: isSportLoading, visible: isSportVisible },
    { loading: isCreationLoading, visible: isCreationVisible },
    { loading: isCultureLoading, visible: isCultureVisible },
    { loading: isCivilLoading, visible: isCivilVisible },
    { loading: isProfessionLoading, visible: isProfessionVisible },
  ];

  const isEverySectionLoaded = sectionStates.every((state) => !state.loading);
  const hasLoadedVisibleSections = sectionStates.some((state) => !state.loading && state.visible);
  const isAnySectionLoading = sectionStates.some((state) => state.loading);
  const isAnySectionVisible = sectionStates.some((state) => state.visible);

  const isNotFoundPlaceholder = hasSearched && isEverySectionLoaded && !isAnySectionVisible;
  const isLoadingPlaceholder =
    hasSearched && isManualSearching && !hasLoadedVisibleSections && !isAnySectionVisible && !isNotFoundPlaceholder;
  const isSearchLoader = hasSearched && isManualSearching && !isEverySectionLoaded && isAnySectionVisible;
  const isResultVisible = isNotFoundPlaceholder || isLoadingPlaceholder || isSearchLoader || isAnySectionVisible;

  if (isEverySectionLoaded && isManualSearching) {
    setManualSearching(false);
  }

  const handleClose = useCallback(() => dispatch(searchPanelActions.setOpen(false)), [dispatch]);

  const invalidateSearchState = useCallback(() => {
    const allActionsArray = Object.values(allSearchActions);
    allActionsArray.map((actions) => dispatch(actions.invalidate()));
  }, [dispatch]);

  useEffect(() => {
    const isEverySectionEndedLoading = !isAnySectionLoading;

    if (hasSearched && isEverySectionEndedLoading) {
      const totalEntitesCount = [
        profileEntitiesCount,
        studyEntitiesCount,
        scienceEntitiesCount,
        sportEntitiesCount,
        creationEntitiesCount,
        cultureEntitiesCount,
        civilEntitiesCount,
        professionEntitiesCount,
      ].reduce((prev, current) => prev + current, 0);

      emitYMEvent({
        type: 'searchWords',
        payload: {
          wordSearch: searchQuery,
          resultWordSearch: totalEntitesCount,
        },
      });
    }
    // выполнить эффект при завершении загрузки всех методов
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSearched, isAnySectionLoading]);

  useEffect(() => {
    return () => {
      invalidateSearchState();
    };
  }, [invalidateSearchState]);

  const handleSearch = (personId: string, searchQuery: string) => {
    setHasSearched(true);
    setManualSearching(true);
    invalidateSearchState();
    filteredSearchActions.map((actions) => dispatch(actions.request(personId, searchQuery)));
  };

  const marginToButton = 12;
  const searchButtonRect = searchButtonRef?.getBoundingClientRect();
  const marginTop = searchButtonRect ? searchButtonRect.top + searchButtonRect.height : 100;
  const computedMargin = isMobileView ? 0 : marginTop + marginToButton;

  const searchButtonPositionStyles: SxStyles = {
    top: searchButtonRect?.top,
    left: searchButtonRect?.left,
  };

  const resultElement = (
    <Box className="portfolio-searcher__result" sx={mergeSx(styles.result, !isMobileView && styles.desktopBlock)}>
      {isNotFoundPlaceholder && <NotFoundPlaceholder />}
      {isLoadingPlaceholder && <LoadingPlaceholder />}
      {isSearchLoader && <SearchLoader />}
      <Box className="portfolio-searcher__sections" sx={styles.sections}>
        <SearcherResultSections />
      </Box>
    </Box>
  );

  const mobileHeaderElement = (
    <Box sx={styles.mobileHeader}>
      <Typography variant="Paragraph LG/Bold">Поиск по портфолио</Typography>
      <Box component="button" sx={styles.mobileCloseButton} onClick={handleClose}>
        <IconClose />
      </Box>
    </Box>
  );

  return (
    <Modal open={true} onClose={handleClose} className="portfolio-searcher" sx={styles.root}>
      <FixedWidthContainer>
        {!isMobileView && (
          <Button
            variant="secondary"
            onlyIcon
            onClick={handleClose}
            sx={mergeSx(styles.searchButton, searchButtonPositionStyles)}
          >
            <IconSearch />
          </Button>
        )}

        <Box className="portfolio-searcher__container" sx={styles.container(computedMargin)}>
          <Box className="portfolio-searcher__spacer" />
          <Box
            className="portfolio-searcher__search-bar"
            sx={mergeSx(styles.searchBar, !isMobileView && styles.desktopBlock)}
          >
            {isMobileView && mobileHeaderElement}
            <SearchBar isSearchButtonDisabled={isAnySectionLoading} onClose={handleClose} onSearch={handleSearch} />
          </Box>
          {isResultVisible && resultElement}
        </Box>
      </FixedWidthContainer>
    </Modal>
  );
};

export default PortfolioSearcher;
