import { ComponentProps, FC } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { IconOrganization } from 'icons';
import { capitalize } from 'lodash';
import { mergeSx } from 'utils';

import { FacultyListType } from '../../types';
import * as styles from './style';

interface IFacultyListNameProps {
  name: string;
  isMobile: boolean;
  listType: FacultyListType;
}

const FacultyListName: FC<IFacultyListNameProps> = ({ name, isMobile, listType }) => {
  const disableIcon = !isMobile && listType === 'short';
  let typographyVariant: ComponentProps<typeof Typography>['variant'] = undefined;

  if (isMobile) {
    typographyVariant = listType === 'short' ? 'Paragraph LG/Medium' : 'Paragraph LG/Semi Bold';
  } else {
    typographyVariant = listType === 'short' ? 'Paragraph LG/Medium' : 'Headings/H6';
  }

  const iconElement = isMobile ? (
    <Box sx={styles.mobileIconContainer}>
      <IconOrganization sx={mergeSx(styles.icon, styles.mobileIcon)} />
    </Box>
  ) : (
    <IconOrganization sx={styles.desktopIcon} />
  );

  return (
    <Stack className="faculty-list-name" direction="row" gap="8px">
      {!disableIcon && iconElement}
      <Typography variant={typographyVariant}>{capitalize(name)}</Typography>
    </Stack>
  );
};

export default FacultyListName;
