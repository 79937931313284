/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, {
  useRef,
  useLayoutEffect
} from 'react';

import { Grid } from '@mui/material';
import { format } from 'date-fns';

import {
  DoughnutColors, GiaMark, GiaCategory, CompulsoryGiaSubjects, GiaType
} from '../../../../const';
import { getMark, checkPassedExamDate } from '../../../../utils';
import { bronzeLeavesImage, goldenLeavesImage, silverLeavesImage } from '../../../../icons';
import ResultDoughnut from '../../../common/ResultDoughnut';

import './index.scss';

interface IExamBlockProps {
  exam: any;
  heightModificator: number;
  setHeightModificator: React.Dispatch<React.SetStateAction<number>>;
}

const ExamBlock: React.FC<IExamBlockProps> = ({
  exam,
  heightModificator,
  setHeightModificator
}) => {
  const {
    name,
    primaryMarkValue,
    primaryMarkBasis,
    normalizedMarkValue,
    normalizedMarkBasis,
    formaGia,
    date
  } = exam;

  const titleRef = useRef<any>();

  useLayoutEffect(() => {
    const heightOffset = 24;
    // Таймаут нужен для того, чтобы высота блока высчитывалась в самый последний момент, иначе высота высчитывается неправильно
    const heightModificatorTimeout = setTimeout(() => {
      const newHeightModificator = (titleRef?.current?.clientHeight - heightOffset) / heightOffset;
      if (newHeightModificator > heightModificator) setHeightModificator(newHeightModificator);
    });
    return () => {
      clearTimeout(heightModificatorTimeout);
    };
  }, [titleRef?.current, titleRef?.current?.clientHeight]);

  let subjectCategoryName;
  const examDate = new Date(date);
  const passingYear = date?.substr(0, 4);

  let valueProgressBar = 0;
  let chartColor = "#585858";
  let progressImage;
  let mark = normalizedMarkValue;

  if (normalizedMarkValue) {
    valueProgressBar = normalizedMarkBasis ? Number(normalizedMarkValue) * 100 / normalizedMarkBasis : Number(normalizedMarkValue);

    if (formaGia === GiaType.ege) {
      if (Number(valueProgressBar) >= 90) {
        chartColor = DoughnutColors.BRONZE;
        progressImage = bronzeLeavesImage;
      }
      if (Number(valueProgressBar) >= 96) {
        chartColor = DoughnutColors.SILVER;
        progressImage = silverLeavesImage;
      }
      if (Number(valueProgressBar) === 100) {
        chartColor = DoughnutColors.GOLD;
        progressImage = goldenLeavesImage;
      }
    }
  }

  if (normalizedMarkValue === GiaMark.passed) {
    mark = GiaMark.passedAbbreviation;
    valueProgressBar = 100;
  }
  if (normalizedMarkValue === GiaMark.notPassed) {
    mark = GiaMark.notPassedAbbreviation;
    valueProgressBar = 100;
  }

  if (name && (CompulsoryGiaSubjects).includes(name)) {
    subjectCategoryName = GiaCategory.compulsory;
  } else {
    subjectCategoryName = GiaCategory.optionally;
  }

  const doughnutData = {
    incomingData: [valueProgressBar, 100 - valueProgressBar],
    incomingLabels: ['1', '2']
  };

  return (
    <div className="pdf-exam-block pdf-keep">
      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        direction="column"
        style={{ height: '100%' }}
      >
        <Grid item className="progeress-bar">
          <ResultDoughnut total={mark !== null ? mark : '-'} data={doughnutData} color={chartColor} />
          {progressImage && <img src={progressImage} alt="" className="progress-image" />}
        </Grid>

        <Grid container item justifyContent="center" alignContent="space-between" style={{ height: '100%' }}>
          <Grid item>
            {formaGia !== GiaType.ege && (
              <p className={primaryMarkBasis === 0 ? 'result result--empty' : 'result'}>
                {!normalizedMarkValue ? 'Пока не сдан' : getMark(Number(primaryMarkValue), Number(primaryMarkBasis))}
              </p>
            )}
            <h4
              ref={titleRef}
              className="title"
            >
              {name || 'Не выбран'}
            </h4>
          </Grid>
          <Grid item>
            <p className='subject-category'>
              {subjectCategoryName}
            </p>
            <p className="subject-result">{checkPassedExamDate(examDate) ? `Проведен ${format(new Date(date), 'dd.MM.yyyy')}` : `Будет сдан в ${passingYear} г.`}</p>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ExamBlock;
