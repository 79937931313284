import { FC, useContext, useEffect, useState } from 'react';

import { IDictionaryItem } from 'api/types';
import { MobileActionsDrawer } from 'portfolio3/components/drawers';

import { VuzLocalFiltersContext } from '../../context';
import { VuzLocalFilters } from '../../types';
import { setFilterFactory } from '../../utils';
import InnerContextControl from './InnerContextControl';

interface IQuickAccessSubjectsDrawerProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  subjects: IDictionaryItem[];
}

/**
 * Дровер со списком предметов, который использует свой контекст с локальными фильтрами
 * Используется с кнопками быстрых действия
 */
const QuickAccessSubjectsDrawer: FC<IQuickAccessSubjectsDrawerProps> = ({ title, isOpen, onClose, subjects }) => {
  const { localFilters, onApplyFilters } = useContext(VuzLocalFiltersContext);

  const [innerLocalFilters, setInnerLocalFilters] = useState<VuzLocalFilters>(localFilters);

  const setInnerLocalSingleFilter = setFilterFactory(setInnerLocalFilters);

  // Синхронизация с верхним стейтом при открытии
  useEffect(() => {
    if (isOpen) {
      setInnerLocalFilters(localFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleReset = () => {
    const initialSubjects: VuzLocalFilters = {
      ...innerLocalFilters,
      subjects: [],
    };
    setInnerLocalFilters(initialSubjects);
  };

  const handleCloseAndRestoreState = () => {
    const initialSubjects: VuzLocalFilters = {
      ...innerLocalFilters,
      subjects: [],
    };

    onClose();
    setInnerLocalFilters(initialSubjects);
  };

  const handleCloseAndApply = () => {
    onClose();
    onApplyFilters?.(innerLocalFilters);
  };

  return (
    <MobileActionsDrawer
      open={isOpen}
      onClose={handleCloseAndRestoreState}
      title={title}
      onReset={handleReset}
      onApply={handleCloseAndApply}
      isResetDisabled={!innerLocalFilters.subjects.length}
      isFullHeight
    >
      <VuzLocalFiltersContext.Provider
        value={{
          localFilters: innerLocalFilters,
          setLocalFilter: setInnerLocalSingleFilter,
          onApplyFilters: null,
        }}
      >
        <InnerContextControl subjectOptions={subjects} />
      </VuzLocalFiltersContext.Provider>
    </MobileActionsDrawer>
  );
};

export default QuickAccessSubjectsDrawer;
