import { SxStyles } from 'types';

export const rightElement: SxStyles = {
  display: 'flex',
  gap: '24px',
};

export const chart: SxStyles = (theme) => ({
  width: '76px',
  height: '76px',

  [theme.breakpoints.down('commonSm')]: {
    width: '60px',
    height: '60px',
  },
});
