import { createSelector } from 'reselect';

import { IStudentReward, IStudentSportReward } from '../../api/types';
import { IRootState } from '../../reducers';
import { IRibbonReward } from '../../types';
import { getRewardShowcaseSvgIcon, getSectionSvgIcon, getSportRewardShowcaseSvgIcon } from '../../utils';
import filteredRewardsSelector from './filteredRewardsSelector';
import { getSectionBorderColor, getSectionRibbonColor, isRewardSport } from './utils';

const getMappedRibbonRewards = (rewards: (IStudentReward | IStudentSportReward)[]): IRibbonReward[] => {
  return rewards.map((reward): IRibbonReward => {
    const { categoryCode } = reward as IStudentReward;

    const rewardIcon = isRewardSport(reward)
      ? getSportRewardShowcaseSvgIcon(reward.sportRewardCode)
      : getRewardShowcaseSvgIcon(reward.name);

    return {
      ribbonBackgroundColor: getSectionRibbonColor(categoryCode),
      sectionIconColor: '#ffffff',
      rewardBorderColor: getSectionBorderColor(categoryCode),
      sectionIcon: getSectionSvgIcon(categoryCode),
      rewardIcon,
    };
  });
};

/**
 * Формирует данные для отображения наград в хедере
 */
const ribbonRewardsSelector = createSelector(
  [(state: IRootState) => filteredRewardsSelector(state)],
  (filteredRewards): IRibbonReward[] => {
    return getMappedRibbonRewards(filteredRewards);
  },
);

export default ribbonRewardsSelector;
