import { createTheme } from '@mui/material';

import { AccentColorType } from '../colors';
import { componentsSettings } from './components';
import { typographySettings } from './typography';

export { typographySettings };

export const commonTheme = createTheme({
  typography: typographySettings,
  spacing: 8,
  components: componentsSettings,
  palette: {
    accentColor: 'indigo',
  },
  breakpoints: {
    values: {
      commonSm: 639,
      commonMd: 767,
      commonLg: 1023,
      commonXl: 1280,
    },
  },
});

export const generateAccentTheme = (color: AccentColorType) => {
  return createTheme(commonTheme, {
    palette: {
      accentColor: color,
    },
  });
};

export const profileTheme = generateAccentTheme('blue');
export const studyTheme = generateAccentTheme('violet');
export const scienceTheme = generateAccentTheme('indigo');
export const sportTheme = generateAccentTheme('cyan');
export const creationTheme = generateAccentTheme('orange');
export const cultureTheme = generateAccentTheme('red');
export const civilTheme = generateAccentTheme('green');
export const professionTheme = generateAccentTheme('grape');
export const recommendationTheme = generateAccentTheme('pink');

export const tealTheme = generateAccentTheme('teal');
export const limeTheme = generateAccentTheme('lime');
export const yellowTheme = generateAccentTheme('yellow');
