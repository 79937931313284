import { Box, Typography } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';
import { formatDate, getPartOfDateNumeral } from 'utils';

interface IDatePeriods {
  years?: number;
  months?: number;
  days?: number;
}

export const getSpoTrainingDateElement = (studyStartDate: string, datePeriods?: IDatePeriods) => {
  const formatPeriodHaveToStudyString = ({ years, months, days }: IDatePeriods): string => {
    const stringPeriod = 'осталось ';
    const yearString = years ? `${years} ${getPartOfDateNumeral(years, 'years')} ` : '';
    const monthsString = months ? `${months} мес. ` : '';
    const daysString = days ? `${days} ${getPartOfDateNumeral(days, 'days')}` : '';
    return `${stringPeriod}${yearString}${monthsString}${daysString}`;
  };

  const formatedDate = formatDate(studyStartDate);
  const formatedPeriod = datePeriods ? formatPeriodHaveToStudyString(datePeriods) : null;

  const subtextStyling: SxStyles = {
    color: NeutralColors.light_neutrals_600,
  };

  const formedJSXElement = (
    <Box sx={{ display: 'flex', gap: '12px' }}>
      <Typography variant="Paragraph MD/Regular">{formatedDate}</Typography>
      {formatedPeriod && (
        <Typography sx={subtextStyling} variant="Paragraph MD/Regular">
          {formatedPeriod}
        </Typography>
      )}
    </Box>
  );
  return formedJSXElement;
};
