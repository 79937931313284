/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import clsx from 'clsx';

import { iconApprove, iconApprovePdf } from '../../../icons';

import './index.scss';

interface VerificationSignProps {
  isPdf?: boolean;
}

const VerificationSign: React.FC<VerificationSignProps> = ({
  isPdf
}) => (
  <div className={clsx('verification-sign', { 'verification-sign--pdf': isPdf })}>
    <img src={isPdf ? iconApprovePdf : iconApprove} alt="подтверждено" />
  </div>
);

export default VerificationSign;
