import { Colors } from 'style/variables';
import { SxStyles } from 'types';

export const root: SxStyles = {};

export const header: SxStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

export const filters: SxStyles = {
  display: 'grid',
  gridAutoFlow: 'row',
  gridTemplateColumns: 'minmax(0, 1fr) 256px',
  gap: '8px',

  marginTop: '8px',
};

export const items: SxStyles = {
  position: 'relative',
  marginTop: '16px',
};

export const year: SxStyles = (theme) => ({
  position: 'absolute',
  top: '12px',

  transform: 'rotate(270deg) translateY(-32px)',

  opacity: 0.4,
  color: Colors['note-text'],
  fontSize: '24px',
  lineHeight: '32px',
  fontWeight: 800,
  letterSpacing: '0.05em',

  [theme.breakpoints.down('commonSm')]: {
    display: 'none',
  },
});
