import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Box } from '@mui/material';
import { useAppSelector } from 'hooks';
import { EmployeeSubheader } from 'portfolio3/components/common/headers';
import PaddingContainer from 'portfolio3/components/common/PaddingContainer';
import { MainRendererAdminViewMode } from 'portfolio3/containers/renderers/containers/MainRenderer';
import { SearchResultStudentContainer, StudentSearcher } from 'portfolio3/features/employee';

import { setAdminViewingModeAction, setAdminViewingTypeAction } from '../../actions';
import ViewHeader from './ViewHeader';

import './index.scss';

const AdminStudentRegister: FC = () => {
  const dispatch = useDispatch();
  const currentStudent = useAppSelector((state) => state.currentStudent);
  const adminViewMode = useAppSelector((state) => state.adminViewMode);
  const personsList = useAppSelector((state) => state.personsList);

  const history = useHistory();

  const handleSetParentView = () => {
    dispatch(setAdminViewingTypeAction('parent'));
  };

  const handleCloseStudentPortfolio = () => {
    dispatch(setAdminViewingModeAction(false));
    history.push('/portfolio/admin/search');
  };

  const isLoading = personsList.loading;
  const isCached = personsList.requestCache === 'cached';
  const isResultsShown = !isLoading && isCached;

  return (
    <>
      {!adminViewMode.isViewing ? (
        <>
          <EmployeeSubheader title="Поиск портфолио учащихся" />
          <PaddingContainer className="admin-page" sx={{ marginTop: '24px' }}>
            <StudentSearcher />

            {isResultsShown && (
              <Box marginTop="32px">
                <SearchResultStudentContainer personsList={personsList} />
              </Box>
            )}
          </PaddingContainer>
        </>
      ) : (
        <>
          <ViewHeader
            firstName={currentStudent.firstname || ''}
            lastName={currentStudent.lastname || ''}
            isParentView={adminViewMode.viewType === 'parent'}
            onSetParentView={handleSetParentView}
            onClose={handleCloseStudentPortfolio}
          />
          <MainRendererAdminViewMode />
        </>
      )}
    </>
  );
};

export default AdminStudentRegister;
