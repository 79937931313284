import { action, createRequestTypes, FAILURE, INVALIDATE, REQUEST, SUCCESS } from 'actions/utils';

const createSearchActions = (types: Record<string, string>) => {
  return {
    request: (personId: string, searchString: string) =>
      action(types[REQUEST], { payload: { personId, searchString } }),
    success: (response: unknown) => action(types[SUCCESS], { payload: response }),
    failure: (error: unknown) => action(types[FAILURE], { payload: error }),
    invalidate: () => action(types[INVALIDATE]),
  };
};

export type CommonActionsObject = ReturnType<typeof createSearchActions>;

export const SEARCH_PANEL_SET_OPEN = 'SEARCH_PANEL_SET_OPEN';
export const SEARCH_PANEL_SET_QUERY = 'SEARCH_PANEL_SET_QUERY';
export const searchPanelActions = {
  setOpen: (open: boolean) => action(SEARCH_PANEL_SET_OPEN, { payload: open }),
  setQuery: (query: string) => action(SEARCH_PANEL_SET_QUERY, { payload: query }),
};

export const SEARCH_REWARDS = createRequestTypes('SEARCH_REWARDS');
export const searchRewardsActions = createSearchActions(SEARCH_REWARDS);

export const SEARCH_SPORT_REWARDS = createRequestTypes('SEARCH_SPORT_REWARDS');
export const searchSportRewardsActions = createSearchActions(SEARCH_SPORT_REWARDS);

export const SEARCH_GIA = createRequestTypes('SEARCH_GIA');
export const searchGiaActions = createSearchActions(SEARCH_GIA);

export const SEARCH_EVENTS = createRequestTypes('SEARCH_EVENTS');
export const searchEventsActions = createSearchActions(SEARCH_EVENTS);

export const SEARCH_PROJECTS = createRequestTypes('SEARCH_PROJECTS');
export const searchProjectsActions = createSearchActions(SEARCH_PROJECTS);

export const SEARCH_AFFILIATIONS = createRequestTypes('SEARCH_AFFILIATIONS');
export const searchAffiliationsActions = createSearchActions(SEARCH_AFFILIATIONS);

export const SEARCH_EMPLOYMENTS = createRequestTypes('SEARCH_EMPLOYMENTS');
export const searchEmploymentsActions = createSearchActions(SEARCH_EMPLOYMENTS);

export const SEARCH_DIAGNOSTICS = createRequestTypes('SEARCH_DIAGNOSTICS');
export const searchDiagnosticsActions = createSearchActions(SEARCH_DIAGNOSTICS);

export const SEARCH_CULTURE_VISITINGS = createRequestTypes('SEARCH_CULTURE_VISITINGS');
export const searchCultureVisitingsActions = createSearchActions(SEARCH_CULTURE_VISITINGS);

export const SEARCH_CHECKIN_HISTORY = createRequestTypes('SEARCH_CHECKIN_HISTORY');
export const searchCheckinHistoryActions = createSearchActions(SEARCH_CHECKIN_HISTORY);

export const SEARCH_INTERESTS = createRequestTypes('SEARCH_INTERESTS');
export const searchInterestsActions = createSearchActions(SEARCH_INTERESTS);

export const SEARCH_INDEPENDENT_DIAGNOSTICS = createRequestTypes('SEARCH_INDEPENDENT_DIAGNOSTICS');
export const searchIndependentDiagnosticsActions = createSearchActions(SEARCH_INDEPENDENT_DIAGNOSTICS);

export const SEARCH_PROFESSIONAL_ADDITIONAL_EDUCATION = createRequestTypes('SEARCH_PROFESSIONAL_ADDITIONAL_EDUCATION');
export const searchProfessionalAdditionalEducationActions = createSearchActions(
  SEARCH_PROFESSIONAL_ADDITIONAL_EDUCATION,
);

export const SEARCH_DOCUMENTS = createRequestTypes('SEARCH_DOCUMENTS');
export const searchDocumentsActions = createSearchActions(SEARCH_DOCUMENTS);

export const SEARCH_SPO_EDUCATION = createRequestTypes('SEARCH_SPO_EDUCATION');
export const searchSpoEducationActions = createSearchActions(SEARCH_SPO_EDUCATION);

export const SEARCH_JOBS = createRequestTypes('SEARCH_JOBS');
export const searchJobsActions = createSearchActions(SEARCH_JOBS);

export const SEARCH_METASKILLS = createRequestTypes('SEARCH_METASKILLS');
export const searchMetaskillsActions = createSearchActions(SEARCH_METASKILLS);

export const SEARCH_GIA_WORLDSKILLS = createRequestTypes('SEARCH_GIA_WORLDSKILLS');
export const searchGiaWorldskillsActions = createSearchActions(SEARCH_GIA_WORLDSKILLS);

export const collection = {
  searchRewardsActions,
  searchSportRewardsActions,
  searchGiaActions,
  searchEventsActions,
  searchProjectsActions,
  searchAffiliationsActions,
  searchEmploymentsActions,
  searchDiagnosticsActions,
  searchCultureVisitingsActions,
  searchCheckinHistoryActions,
  searchInterestsActions,
  searchIndependentDiagnosticsActions,
  searchProfessionalAdditionalEducationActions,
  searchDocumentsActions,
  searchSpoEducationActions,
  searchJobsActions,
  searchMetaskillsActions,
  searchGiaWorldskillsActions,
};
