import { FC, Fragment, useRef, useState } from 'react';

import { IMappedLearningYearPerformance } from 'portfolio3/features/performance';
import { cyanColors, grapeColors, limeColors, redColors, tealColors, violetColors } from 'portfolio3/styles';

import useClientWidth from '../../../hooks/useClientWidth';
import { FormControlLabel, Radio, RadioButton, Tooltip } from '../../../ui-kit';
import LineChart from '../../common/lineChart';
import LineChartAxis from '../../common/lineChart/lineChartAxis';
import Popper from '../../common/Popper';
import { SingleDictionarySelect } from '../../common/Select';
import { EducationLevel } from './educationLevels';
import SubjectCheckbox from './SubjectCheckbox';
import { filterPerformanceByEducationLevel, getAvailableSubjectNames } from './utils';

import './index.scss';

interface IChartHorizontalLine {
  name: string;
  values: {
    interval?: string;
    value?: number;
  }[];
}

interface IEducationalLevelFilterOption {
  code: number;
  value: EducationLevel;
  isVisible: boolean;
}

const AVERAGE_SUBJECTS_VALUE = 'По всем предметам';
const MAX_CHECKBOX_VALUES = 5;
const CHART_COLORS = [redColors, grapeColors, violetColors, cyanColors, tealColors, limeColors];
type ChartColor = (typeof CHART_COLORS)[number];

interface IPerformanceChartProps {
  performance: IMappedLearningYearPerformance[];
}

const PerformanceChart: FC<IPerformanceChartProps> = ({ performance }) => {
  const [checkboxPopperOpen, setCheckboxPopperOpen] = useState(false);
  const menuButtonRef = useRef<HTMLDivElement>(null);
  const menuIconRef = useRef(null);
  const clientWidth = useClientWidth();

  const isMobileView = clientWidth <= 800;

  /**
   * состояние компонента
   */
  // выбранное значение уровня образования
  const [educationLevel, setEducationLevel] = useState<EducationLevel>(EducationLevel.ANY);
  // выбранные значения в чекбоксах, массив строк
  const initialCheckboxValue = [AVERAGE_SUBJECTS_VALUE];
  const [checkboxValues, setCheckboxValues] = useState<string[]>(initialCheckboxValue);
  // маппинг значения чекбокса на цвет
  const initialColorMap = { [AVERAGE_SUBJECTS_VALUE]: redColors };
  const [colorMap, setColorMap] = useState<Record<string, ChartColor>>(initialColorMap);

  /**
   * подготовка всех возможных вычисляемых значений
   */
  // фильтруем данные по educationLevel
  const filteredPerformance = filterPerformanceByEducationLevel(performance, educationLevel);
  // формируем плоский массив предметов
  const flatSubjects = filteredPerformance.flatMap((performance) => performance.subjects);
  // находим уникальные предметы по фильтрованному массиву + сортируем по алфавиту
  const availableSubjectNames = getAvailableSubjectNames(flatSubjects);
  // оставляем только те предметы, которые выбраны в чекбоксах, по ним будет формироваться график
  const chartSubjectNames = availableSubjectNames.filter((subjectName) => checkboxValues.includes(subjectName));
  // опции в чекбоксах
  const checkboxOpions = [AVERAGE_SUBJECTS_VALUE, ...availableSubjectNames];
  // цвета графика
  const lineColors = Object.values(colorMap).map((colorObject) => colorObject[100]);

  /**
   * формирование данных для графика
   */
  const chartData: IChartHorizontalLine[] = chartSubjectNames.map((subjectName): IChartHorizontalLine => {
    const subjectObjects = flatSubjects.filter((subject) => subject.name === subjectName);
    return {
      name: subjectName,
      values: subjectObjects.map((subject): IChartHorizontalLine['values'][number] => {
        return {
          interval: subject.year,
          value: subject.grade,
        };
      }),
    };
  });

  if (checkboxValues.includes(AVERAGE_SUBJECTS_VALUE)) {
    chartData.unshift({
      name: AVERAGE_SUBJECTS_VALUE,
      values: filteredPerformance.map((performance): IChartHorizontalLine['values'][number] => {
        return {
          interval: performance.year,
          value: performance.averageGrade,
        };
      }),
    });
  }

  /**
   * функции для работы с чекбоксами
   */
  const getFirstAvailableColorFromPool = () => {
    // получаем список используемых в данный момент цветов
    const colorValues = Object.values(colorMap);
    // получаем список доступных цветов
    const colorPool = CHART_COLORS.filter((colorObject) => {
      return !colorValues.includes(colorObject);
    });

    return colorPool.length > 0 ? colorPool[0] : null;
  };

  const enableCheckboxValue = (value: string) => {
    const newValue = [...checkboxValues, value];
    setCheckboxValues(newValue);

    const availableColor = getFirstAvailableColorFromPool();
    if (availableColor) {
      setColorMap({ ...colorMap, [value]: availableColor });
    }
  };

  const disableCheckboxValue = (value: string) => {
    const newValue = checkboxValues.filter((currentValue) => currentValue !== value);
    setCheckboxValues(newValue);

    const newColorMap = { ...colorMap };
    delete newColorMap[value];
    setColorMap(newColorMap);
  };

  const toggleCheckboxValue = (value: string) => {
    const hasValue = checkboxValues.includes(value);
    if (hasValue) {
      disableCheckboxValue(value);
    } else {
      enableCheckboxValue(value);
    }
  };

  const resetCheckboxValue = () => {
    setCheckboxValues(initialCheckboxValue);
    setColorMap(initialColorMap);
  };

  const getCheckboxValueColor = (value: string) => {
    const colorObject = colorMap[value];
    return colorObject?.[100];
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const toggleCheckboxPopper = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    setCheckboxPopperOpen((prevstate) => !prevstate);
  };

  const isCheckboxLimitReached =
    checkboxValues.filter((value) => value !== AVERAGE_SUBJECTS_VALUE).length >= MAX_CHECKBOX_VALUES;

  /**
   * Фильтр уровень образования
   */
  const educationLevelSelectController = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleChange: (item: any) => {
      const educationLevel = educationLevelFilters.find((filter) => filter.code === item)?.value;
      setEducationLevel(educationLevel ?? EducationLevel.ANY);
    },
  };

  const hasEducationNOO = performance.some(
    (performanceObject) => performanceObject.educationLevel === EducationLevel.NOO,
  );
  const hasEducationOOO = performance.some(
    (performanceObject) => performanceObject.educationLevel === EducationLevel.OOO,
  );
  const hasEducationCOO = performance.some(
    (performanceObject) => performanceObject.educationLevel === EducationLevel.COO,
  );

  const educationLevelOptions: IEducationalLevelFilterOption[] = [
    {
      code: 1,
      value: EducationLevel.ANY,
      isVisible: true,
    },
    {
      code: 2,
      value: EducationLevel.NOO,
      isVisible: hasEducationNOO,
    },
    {
      code: 3,
      value: EducationLevel.OOO,
      isVisible: hasEducationOOO,
    },
    {
      code: 4,
      value: EducationLevel.COO,
      isVisible: hasEducationCOO,
    },
  ];

  const educationLevelFilters = educationLevelOptions.filter((filter) => filter.isVisible);
  const activeEducationalLevel = educationLevelFilters.find((filter) => filter.value === educationLevel)?.code;

  return (
    <div className="performance-chart">
      <div className="performance-chart__left-column">
        <div className="performance-chart__row">
          {isMobileView ? (
            <div className="performance-chart__filter-select">
              <SingleDictionarySelect
                optionsClass="select__item--common--type-study"
                options={educationLevelFilters}
                placeholder=""
                selectController={educationLevelSelectController}
                value={activeEducationalLevel}
              />
            </div>
          ) : (
            <RadioButton
              name="chart-type"
              defaultValue={EducationLevel.ANY}
              className="performance-chart__filter"
              value={educationLevel}
              onChange={(_, value) => {
                setEducationLevel((value as EducationLevel) ?? EducationLevel.ANY);
              }}
            >
              {educationLevelFilters.map((filter) => (
                <FormControlLabel
                  key={filter.code}
                  value={filter.value}
                  control={<Radio />}
                  label={<p>{filter.value}</p>}
                />
              ))}
            </RadioButton>
          )}
          <div
            className={`performance-chart__checkbox-container ${
              checkboxPopperOpen ? 'performance-chart__checkbox-container--active' : ''
            }`}
            ref={menuButtonRef}
            onClick={toggleCheckboxPopper}
            role="button"
          >
            Отображается: {checkboxValues.length}
          </div>
        </div>
        <LineChartAxis xAxisLabel="учебный год">
          <LineChart
            incomingData={chartData}
            lineColors={lineColors}
            yAxisLabel="балл"
            width={clientWidth <= 800 ? chartData[0]?.values.length * 80 : undefined}
          />
        </LineChartAxis>
      </div>
      <div className="performance-chart__right-column">
        <div className="performance-chart__subjects-header">
          <h5>Предметы</h5>
          <p className="performance-chart__clear-button" role="button" onClick={resetCheckboxValue}>
            Сбросить все
          </p>
        </div>
        <div className="performance-chart__subjects-container">
          {checkboxOpions.map((value) => {
            const isChecked = checkboxValues.includes(value);
            const checkboxColor = getCheckboxValueColor(value);
            const isTooltipShown = isCheckboxLimitReached && value !== AVERAGE_SUBJECTS_VALUE && !isChecked;

            const checkboxElement = (
              <SubjectCheckbox
                value={value}
                isChecked={isChecked}
                isDisabled={isCheckboxLimitReached && value !== AVERAGE_SUBJECTS_VALUE}
                color={checkboxColor}
                onToggle={() => toggleCheckboxValue(value)}
              />
            );

            return isTooltipShown ? (
              <Tooltip
                key={value}
                title="Одновременно можно выбрать не более 5-ти предметов"
                className="performance-chart__tooltip"
                arrow
                placement="left"
                width="190px"
              >
                {checkboxElement}
              </Tooltip>
            ) : (
              <Fragment key={value}>{checkboxElement}</Fragment>
            );
          })}
        </div>
      </div>
      <Popper
        open={checkboxPopperOpen}
        anchorEl={menuButtonRef.current}
        onClosePopperMenu={(event) => {
          if (event.target !== menuButtonRef.current && event.target !== menuIconRef.current)
            setCheckboxPopperOpen(false);
        }}
        placement="bottom-end"
        style={{ zIndex: 2 }}
      >
        <div className="performance-chart__checkbox-menu">
          {checkboxOpions.map((value) => {
            const isChecked = checkboxValues.includes(value);
            const checkboxColor = getCheckboxValueColor(value);

            return (
              <SubjectCheckbox
                key={value}
                value={value}
                isChecked={isChecked}
                isDisabled={isCheckboxLimitReached && value !== AVERAGE_SUBJECTS_VALUE}
                color={checkboxColor}
                onToggle={() => toggleCheckboxValue(value)}
              />
            );
          })}
        </div>
      </Popper>
    </div>
  );
};

export default PerformanceChart;
