/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconCulture: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M17.8 16.6021H2.2C1.88174 16.6021 1.57652 16.7285 1.35147 16.9535C1.12643 17.1786 1 17.4838 1 17.8021C1 18.1203 1.12643 18.4255 1.35147 18.6506C1.57652 18.8756 1.88174 19.0021 2.2 19.0021H17.8C18.1183 19.0021 18.4235 18.8756 18.6485 18.6506C18.8736 18.4255 19 18.1203 19 17.8021C19 17.4838 18.8736 17.1786 18.6485 16.9535C18.4235 16.7285 18.1183 16.6021 17.8 16.6021Z"
          fill={props.fill || "#FF6B6B"}/>
    <path d="M8.79248 9.59697V13.6545C8.79248 13.9727 8.91891 14.278 9.14395 14.503C9.369 14.728 9.67422 14.8545 9.99248 14.8545C10.3107 14.8545 10.616 14.728 10.841 14.503C11.0661 14.278 11.1925 13.9727 11.1925 13.6545V9.59697C11.1925 9.27871 11.0661 8.97349 10.841 8.74844C10.616 8.5234 10.3107 8.39697 9.99248 8.39697C9.67422 8.39697 9.369 8.5234 9.14395 8.74844C8.91891 8.97349 8.79248 9.27871 8.79248 9.59697Z"
          fill={props.fill || "#FF6B6B"}/>
    <path d="M14.0576 9.59697V13.6545C14.0576 13.9727 14.184 14.278 14.4091 14.503C14.6341 14.728 14.9394 14.8545 15.2576 14.8545C15.5759 14.8545 15.8811 14.728 16.1061 14.503C16.3312 14.278 16.4576 13.9727 16.4576 13.6545V9.59697C16.4576 9.27871 16.3312 8.97349 16.1061 8.74844C15.8811 8.5234 15.5759 8.39697 15.2576 8.39697C14.9394 8.39697 14.6341 8.5234 14.4091 8.74844C14.184 8.97349 14.0576 9.27871 14.0576 9.59697Z"
          fill={props.fill || "#FF6B6B"}/>
    <path d="M3.52734 9.59697V13.6545C3.52734 13.9727 3.65377 14.278 3.87882 14.503C4.10386 14.728 4.40908 14.8545 4.72734 14.8545C5.0456 14.8545 5.35083 14.728 5.57587 14.503C5.80092 14.278 5.92734 13.9727 5.92734 13.6545V9.59697C5.92734 9.27871 5.80092 8.97349 5.57587 8.74844C5.35083 8.5234 5.0456 8.39697 4.72734 8.39697C4.40908 8.39697 4.10386 8.5234 3.87882 8.74844C3.65377 8.97349 3.52734 9.27871 3.52734 9.59697Z"
          fill={props.fill || "#FF6B6B"}/>
    <path d="M1.9375 6.61946H18.0625C18.3111 6.61946 18.5496 6.52069 18.7254 6.34487C18.9012 6.16906 19 5.9306 19 5.68196C18.9986 5.51193 18.9502 5.3456 18.8602 5.20133C18.7702 5.05707 18.6421 4.94049 18.49 4.86446L18.4075 4.82696L10.69 1.15196C10.4737 1.05185 10.2383 1 10 1C9.7617 1 9.52625 1.05185 9.31 1.15196L1.5175 4.84196C1.36277 4.92139 1.23274 5.04166 1.14152 5.18974C1.05029 5.33781 1.00135 5.50804 1 5.68196C1 5.9306 1.09877 6.16906 1.27459 6.34487C1.4504 6.52069 1.68886 6.61946 1.9375 6.61946Z"
          fill={props.fill || "#FF6B6B"}/>
  </SvgIcon>
);

export default IconCulture;
