import { svgIconClasses } from '@mui/material';
import { SxStyles } from 'types';

export const radioTextContainer: SxStyles = () => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',

  [`.${svgIconClasses.root}`]: {
    width: '20px',
    height: '20px',
  },
});

export const radioButton: SxStyles = () => ({
  marginRight: '8px',
});
