import React from 'react';

import { useMediaQuery } from '@mui/material';
import { commonTheme } from 'portfolio3/styles/theme';
import { BaseInputLabel, BaseToggleLabel, Checkbox, FormControl, Textarea } from 'portfolio3/ui-kit';
import { InputController } from 'portfolio3/ui-kit/types';

import { CardDetailsChangeHandler } from '../../types';
import * as styles from './styles';

import './index.scss';

interface IDetailCheckboxProps {
  disabled: boolean;
  checked?: boolean;
  label: string;
  value?: string;
  onChange: CardDetailsChangeHandler;
}

const DetailCheckbox: React.FC<IDetailCheckboxProps> = ({ disabled, checked, label, value, onChange }) => {
  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const handleChangeChecked = (isChecked: boolean) => {
    onChange(isChecked, value);
  };

  const valueController: InputController = {
    handleChange(value) {
      onChange(checked ?? false, value);
    },
  };

  return (
    <div className="detail-checkbox">
      <BaseToggleLabel
        sx={styles.toggleLabel(checked ?? false)}
        size="medium"
        label={label}
        control={
          <Checkbox
            size={isMobile ? 'small' : 'medium'}
            disabled={disabled}
            checked={checked ?? false}
            onChange={(_, isChecked) => handleChangeChecked(isChecked)}
          />
        }
      />

      <FormControl
        sx={styles.textareaControl(checked ?? false)}
        disabled={disabled}
        required
        renderMode="fixed"
        inputSize="medium"
        label={<BaseInputLabel>Опиши ошибку</BaseInputLabel>}
        control={
          <Textarea
            renderMode="fixed"
            size="medium"
            value={value ?? ''}
            placeholder="Пожалуйста, опиши подробно, где именно допущена ошибка..."
            controller={valueController}
          />
        }
      />
    </div>
  );
};

export default DetailCheckbox;
