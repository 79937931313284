import { isDefined } from 'utils';

import { SortTypeCodes } from '../../const';
import { ILocalVuzFaculty, ILocalVuzSpeciality } from '../../types';

export const sortDetailsDrawerSpecialities = (
  specialities: ILocalVuzSpeciality[],
  sortType: number,
): ILocalVuzSpeciality[] => {
  if (sortType === SortTypeCodes.default) return specialities;

  const copySpecialities = [...specialities];

  if (sortType === SortTypeCodes.requiredBudgetBallAsc) copySpecialities.sort(specialityRequiredBudgetBallAsc);
  if (sortType === SortTypeCodes.requiredBudgetBallDesc) copySpecialities.sort(specialityRequiredBudgetBallDesc);
  if (sortType === SortTypeCodes.requiredPaidBallAsc) copySpecialities.sort(specialityRequiredPaidBallAsc);
  if (sortType === SortTypeCodes.requiredPaidBallDesc) copySpecialities.sort(specialityRequiredPaidBallDesc);
  if (sortType === SortTypeCodes.entityNameAToZ) copySpecialities.sort(specialityNameAToZ);
  if (sortType === SortTypeCodes.entityNameZToA) copySpecialities.sort(specialityNameZToA);

  return copySpecialities;
};

export const sortDetailsDrawerFacultiesShort = (
  faculties: ILocalVuzFaculty[],
  sortType: number,
): ILocalVuzFaculty[] => {
  const copyFaculties = faculties.map((faculty): ILocalVuzFaculty => {
    return {
      ...faculty,
      specialities: [...faculty.specialities],
    };
  });

  if (sortType === SortTypeCodes.entityNameAToZ) copyFaculties.sort(facultyNameAToZ);
  if (sortType === SortTypeCodes.entityNameZToA) copyFaculties.sort(facultyNameZToA);

  return copyFaculties;
};

export const sortDetailsDrawerFacultiesFull = (faculties: ILocalVuzFaculty[], sortType: number): ILocalVuzFaculty[] => {
  if (sortType === SortTypeCodes.default) return faculties;

  const copyFaculties = faculties.map((faculty): ILocalVuzFaculty => {
    return {
      ...faculty,
      specialities: [...faculty.specialities],
    };
  });

  if (sortType === SortTypeCodes.requiredBudgetBallAsc)
    copyFaculties
      .sort(facultySpecialitiesBudgetBallAsc)
      .forEach((faculty) => faculty.specialities.sort(specialityRequiredBudgetBallAsc));
  if (sortType === SortTypeCodes.requiredBudgetBallDesc)
    copyFaculties
      .sort(facultySpecialitiesBudgetBallDesc)
      .forEach((faculty) => faculty.specialities.sort(specialityRequiredBudgetBallDesc));
  if (sortType === SortTypeCodes.requiredPaidBallAsc)
    copyFaculties
      .sort(facultySpecialitiesPaidBallAsc)
      .forEach((faculty) => faculty.specialities.sort(specialityRequiredPaidBallAsc));
  if (sortType === SortTypeCodes.requiredPaidBallDesc)
    copyFaculties
      .sort(facultySpecialitiesPaidBallDesc)
      .forEach((faculty) => faculty.specialities.sort(specialityRequiredPaidBallDesc));
  if (sortType === SortTypeCodes.entityNameAToZ) copyFaculties.sort(facultyNameAToZ);
  if (sortType === SortTypeCodes.entityNameZToA) copyFaculties.sort(facultyNameZToA);

  return copyFaculties;
};

type CompareFn<T> = (a: T, b: T) => number;
type SpecialityCompareFn = CompareFn<ILocalVuzSpeciality>;
type FacultyCompareFn = CompareFn<ILocalVuzFaculty>;
type DirectionType = 'asc' | 'desc';

const specialityRequiredBudgetBallAsc = compareSpecialityBallFactory('budgetMinBall', 'asc');
const facultySpecialitiesBudgetBallAsc = compareFacultySpecialitiesFactory('budgetMinBall', 'asc');

const specialityRequiredBudgetBallDesc = compareSpecialityBallFactory('budgetMinBall', 'desc');
const facultySpecialitiesBudgetBallDesc = compareFacultySpecialitiesFactory('budgetMinBall', 'desc');

const specialityRequiredPaidBallAsc = compareSpecialityBallFactory('paidMinBall', 'asc');
const facultySpecialitiesPaidBallAsc = compareFacultySpecialitiesFactory('paidMinBall', 'asc');

const specialityRequiredPaidBallDesc = compareSpecialityBallFactory('paidMinBall', 'desc');
const facultySpecialitiesPaidBallDesc = compareFacultySpecialitiesFactory('paidMinBall', 'desc');

const specialityNameAToZ: SpecialityCompareFn = (a, b) => a.name.localeCompare(b.name);
const specialityNameZToA: SpecialityCompareFn = (a, b) => b.name.localeCompare(a.name);

const facultyNameAToZ: FacultyCompareFn = (a, b) => a.name.localeCompare(b.name);
const facultyNameZToA: FacultyCompareFn = (a, b) => b.name.localeCompare(a.name);

function compareSpecialityBallFactory(
  specialityCompareProp: keyof Pick<ILocalVuzSpeciality, 'budgetMinBall' | 'paidMinBall'>,
  direction: DirectionType,
): SpecialityCompareFn {
  return function compareFn(a, b) {
    const left = a[specialityCompareProp];
    const right = b[specialityCompareProp];

    if (!isDefined(left)) return 1;
    if (!isDefined(right)) return -1;

    return direction === 'asc' ? left - right : right - left;
  };
}

function compareFacultySpecialitiesFactory(
  facultySpecialityCompareProp: keyof Pick<ILocalVuzSpeciality, 'budgetMinBall' | 'paidMinBall'>,
  direction: DirectionType,
): FacultyCompareFn {
  return function compareFn(a, b) {
    const valuesA = a.specialities.map((speciality) => speciality[facultySpecialityCompareProp]).filter(isDefined);
    const valuesB = b.specialities.map((speciality) => speciality[facultySpecialityCompareProp]).filter(isDefined);

    const groupedValueA = direction === 'asc' ? Math.min(...valuesA) : Math.max(...valuesA);
    const groupedValueB = direction === 'asc' ? Math.min(...valuesB) : Math.max(...valuesB);

    return direction === 'asc' ? groupedValueA - groupedValueB : groupedValueB - groupedValueA;
  };
}
