import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const GroundSports: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="230" height="129" viewBox="0 0 230 129" xmlns="http://www.w3.org/2000/svg" {...props} fill="none">
    {/*eslint-disable max-len */}
    <path
      d="m23.57 66.52 28.48-49.69a4.6 4.6 0 0 1 4.18-2.29l1.56.07a8.33 8.33 0 0 1 5.11 2.07l1.71 1.52a3.11 3.11 0 0 1 .76 3.65L40.12 75.37l-4 1.14a10.279 10.279 0 0 0-6.44 5.41L13.69 115a3.355 3.355 0 0 1-2.69 1.93 4.584 4.584 0 0 1-4-1.61 3.37 3.37 0 0 1-.34-3.83L23 82.82a17.531 17.531 0 0 0 1-15.18l-.43-1.12Z"
      fill="#fff"
    />
    <path
      d="M10.52 117.45a5.006 5.006 0 0 1-3.88-1.81 3.902 3.902 0 0 1-.4-4.4l16.29-28.66a17 17 0 0 0 1-14.76L23 66.48l.12-.21 28.5-49.68A5 5 0 0 1 56.26 14l1.55.07a8.9 8.9 0 0 1 5.42 2.2l1.71 1.51a3.62 3.62 0 0 1 .88 4.24L40.48 75.79 36.25 77a9.78 9.78 0 0 0-6.13 5.15l-16 33.12a3.818 3.818 0 0 1-3.06 2.16l-.54.02Zm13.6-50.89.36.89a18 18 0 0 1-1.08 15.62L7.11 111.74a2.87 2.87 0 0 0 .3 3.26 4.047 4.047 0 0 0 3.59 1.43 2.851 2.851 0 0 0 2.27-1.61l16-33.11A10.789 10.789 0 0 1 36 76l3.77-1 25.14-53.36a2.64 2.64 0 0 0-.63-3.07l-1.72-1.51a7.87 7.87 0 0 0-4.8-1.95L56.21 15a4.05 4.05 0 0 0-3.72 2L24.12 66.56Z"
      fill={props.fill || 'currentColor'}
    />
    <path d="M23.48 66.56c2.35-.49 5.57 3.85 5.57 3.85L40 55.13l-6.8 17.18a11 11 0 0 1 6.78 3" fill="#fff" />
    <path
      d="M40 75.81a.51.51 0 0 1-.36-.15 10.49 10.49 0 0 0-6.44-2.85.5.5 0 0 1-.4-.22.5.5 0 0 1 0-.46l5.32-13.41-8.61 12a.521.521 0 0 1-.41.21.62.62 0 0 1-.4-.2c-.85-1.13-3.4-4-5.06-3.66a.49.49 0 0 1-.59-.38.5.5 0 0 1 .38-.6c2.13-.45 4.64 2.28 5.65 3.51l10.54-14.76a.5.5 0 0 1 .64-.15.51.51 0 0 1 .23.63l-6.56 16.56A11.1 11.1 0 0 1 40.35 75a.5.5 0 0 1-.35.85v-.04Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M211 74.464c7.103-4.305 7.661-16.376 1.246-26.96-6.415-10.585-17.374-15.675-24.478-11.37-7.103 4.306-7.661 16.377-1.246 26.96C192.937 73.68 203.896 78.77 211 74.465Z"
      fill="#fff"
    />
    <path
      d="M205.329 76.47c-6.62 0-14.29-5-19.24-13.13a29.102 29.102 0 0 1-4.52-16.11c.24-5.27 2.35-9.36 5.93-11.54 3.58-2.18 8.19-2.14 13 .08a29.137 29.137 0 0 1 12.2 11.46 29.128 29.128 0 0 1 4.52 16.12c-.24 5.26-2.35 9.36-5.93 11.53a11.337 11.337 0 0 1-5.96 1.59Zm-11.86-41.38a10.35 10.35 0 0 0-5.47 1.46c-3.29 2-5.23 5.8-5.45 10.72a28.131 28.131 0 0 0 4.37 15.56c6.26 10.33 16.94 15.35 23.8 11.19 3.29-2 5.22-5.8 5.45-10.72a28.177 28.177 0 0 0-4.37-15.55 28.208 28.208 0 0 0-11.77-11.08 15.85 15.85 0 0 0-6.56-1.58Z"
      fill={props.fill || 'currentColor'}
    />
    <path d="M185 38.89s25.12 5.67 22.46 36.66L185 38.89Z" fill="#fff" />
    <path
      d="m208 75.59-1-.09c.75-8.7-.38-20.89-10-29.5a30.22 30.22 0 0 0-12-6.62l.22-1c.16.08 25.42 6.14 22.78 37.21Z"
      fill={props.fill || 'currentColor'}
    />
    <path d="M183.41 41.5s-.12 25.22 20.4 34.39l-20.4-34.39Z" fill="#fff" />
    <path
      d="M203.61 76.34c-20.59-9.19-20.7-34.59-20.7-34.85h1c0 .25.12 25 20.1 33.93l-.4.92Z"
      fill={props.fill || 'currentColor'}
    />
    <path d="M198.869 35.76s-.93 9.93 17.51 29.74l-17.51-29.74Z" fill="#fff" />
    <path
      d="M216 65.84c-18.38-19.75-17.68-29.71-17.64-30.12l1 .09-.5-.05.5.05c0 .09-.62 10 17.37 29.35l-.73.68Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M152.32 99.09c14.084 0 25.5-11.417 25.5-25.5s-11.416-25.5-25.5-25.5c-14.083 0-25.5 11.417-25.5 25.5s11.417 25.5 25.5 25.5Z"
      fill="#fff"
    />
    <path
      d="M152.32 99.59a26.002 26.002 0 0 1-24.021-16.05 26.009 26.009 0 0 1-1.479-15.023 25.997 25.997 0 0 1 35.45-18.948 26.001 26.001 0 0 1-9.95 50.02Zm0-51a25 25 0 1 0 0 50 25 25 0 0 0 0-50Z"
      fill={props.fill || 'currentColor'}
    />
    <path d="M152.32 48.09s18.5 24-3.5 51l3.5-51Z" fill="#fff" />
    <path
      d="m149.211 99.41-.77-.64c21.48-26.36 3.67-50.14 3.49-50.37l.79-.61c.19.21 18.49 24.62-3.51 51.62Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M177.001 67.62a.488.488 0 0 1-.35-.15c-17.36-17.5-47-2.1-47.28-1.94a.498.498 0 0 1-.707-.189.513.513 0 0 1-.032-.397.501.501 0 0 1 .269-.294c.3-.16 30.57-15.92 48.46 2.11a.491.491 0 0 1 .148.355.491.491 0 0 1-.148.355.475.475 0 0 1-.36.15Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M137.05 94.52c-2.76-7.28.55-13.16 5.12-21.29 1.12-2 2.27-4 3.48-6.35 1.65-3.16 1.92-5.89.8-8.11-2.18-4.32-8.94-5.43-9-5.44l.15-1c.3 0 7.34 1.19 9.75 6 1.28 2.52 1 5.56-.81 9-1.21 2.31-2.37 4.38-3.49 6.37-4.6 8.19-7.65 13.6-5.06 20.45l-.94.37ZM169.24 93.37l-.83-.56c6.59-9.89 3.52-15.44 0-21.87-.64-1.16-1.31-2.36-1.92-3.6-2.37-4.81-2.83-8.36-1.37-10.56 1.46-2.2 4.64-2.19 4.77-2.19v1s-2.66-.12-3.89 1.75-.73 5.16 1.43 9.55c.61 1.23 1.27 2.42 1.9 3.57 3.48 6.43 6.84 12.54-.09 22.91Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M203.29 104.53c11.306 0 20.47-9.165 20.47-20.47s-9.164-20.47-20.47-20.47c-11.305 0-20.47 9.165-20.47 20.47s9.165 20.47 20.47 20.47Z"
      fill="#fff"
    />
    <path
      d="M203.289 105a21 21 0 1 1 .001-41.999A21 21 0 0 1 203.29 105Zm0-40.94a19.998 19.998 0 0 0-19.616 23.902 20.003 20.003 0 0 0 27.27 14.576 20 20 0 0 0 12.346-18.478 20.003 20.003 0 0 0-20-19.97v-.03Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M203.289 70.38s4.29-2.5 7.14-1.55c2.85.95 5.72 5.71 5.72 5.71l-3.34 5.71-8.09-2.38-1.43-7.49Z"
      fill="#fff"
    />
    <path
      d="m213.05 80.84-8.76-2.57-1.55-8.14.26-.13c.18-.11 4.51-2.61 7.55-1.6 3.04 1.01 5.87 5.73 6 5.93l.15.26-3.65 6.25Zm-7.89-3.36 7.42 2.18 3-5.11c-.62-1-3-4.49-5.28-5.25-2.28-.76-5.43.82-6.43 1.35l1.29 6.83Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M196.5 68.61s-4.6 1.91-6.52 5.82a12.811 12.811 0 0 0-4.3.11c-1.9.48 5.8-7.2 5.8-7.2l5.4-2.72-.38 3.99Z"
      fill="#fff"
    />
    <path
      d="M185.529 75.06a.627.627 0 0 1-.57-.31c-.19-.34-.58-1 6.16-7.76a.369.369 0 0 1 .13-.09l5.4-2.73a.516.516 0 0 1 .51 0 .506.506 0 0 1 .21.46l-.38 4a.484.484 0 0 1-.3.41c-.05 0-4.45 1.89-6.26 5.58a.515.515 0 0 1-.53.28c-1.362-.2-2.748-.169-4.1.09a.929.929 0 0 1-.27.07Zm6.24-7.31a66.812 66.812 0 0 0-5.61 6.18c1.175-.15 2.364-.15 3.54 0a14.322 14.322 0 0 1 6.3-5.66l.26-2.8-4.49 2.28Z"
      fill={props.fill || 'currentColor'}
    />
    <path d="m221.66 75-1.23 1.42s2.86 6.19 1.91 9l1.42-1.43s.09-5.64-2.1-8.99Z" fill="#fff" />
    <path
      d="M222.34 86a.517.517 0 0 1-.27-.07.527.527 0 0 1-.21-.59c.88-2.63-1.85-8.62-1.88-8.68a.485.485 0 0 1 .07-.53l1.23-1.43a.511.511 0 0 1 .8.05c2.24 3.42 2.189 9.09 2.179 9.33a.521.521 0 0 1-.139.35l-1.43 1.42a.502.502 0 0 1-.35.15ZM221 76.53c.47 1.08 2 4.8 2 7.56l.23-.24c0-.95-.15-5.13-1.67-8l-.56.68Z"
      fill={props.fill || 'currentColor'}
    />
    <path d="m219 90.73-4.76-1.91-5.71 5.71.95 5.72s5.72-.48 7.62-2.38c1.9-1.9 1.9-7.14 1.9-7.14Z" fill="#fff" />
    <path
      d="M209.48 100.75a.497.497 0 0 1-.49-.42l-1-5.71a.483.483 0 0 1 .14-.44l5.71-5.71a.485.485 0 0 1 .54-.11l4.76 1.9a.502.502 0 0 1 .31.47c0 .22 0 5.46-2.05 7.49-2.05 2.03-7.69 2.51-7.93 2.53h.01Zm-.41-6 .83 5c1.36-.16 5.39-.73 6.85-2.19s1.71-5.29 1.75-6.45l-4.14-1.65-5.29 5.29Z"
      fill={props.fill || 'currentColor'}
    />
    <path d="m200.44 92.15-1.91-9-7.61-1.43s-2.38 1.43-3.34 6.67c0 0 1.43 4.76 5.72 6.66l7.14-2.9Z" fill="#fff" />
    <path
      d="M193.299 95.51a.52.52 0 0 1-.21 0c-4.44-2-5.92-6.78-6-7a.593.593 0 0 1 0-.23c1-5.4 3.46-6.94 3.57-7 .107-.06.23-.081.35-.06l7.62 1.43a.497.497 0 0 1 .37.35l1.91 9a.496.496 0 0 1-.31.57l-7.14 2.86a.577.577 0 0 1-.16.08Zm-5.2-7.2a11.161 11.161 0 0 0 5.21 6.16l6.55-2.62-1.75-8.31-7.11-1.33c-.46.4-2.13 2.01-2.9 6.1Z"
      fill={props.fill || 'currentColor'}
    />
    <path d="m191.34 100.68 3.3-.43s4.85 3.28 8.65 2.83c0 0-.95.5.48 1s-3.9 1.8-12.43-3.4Z" fill="#fff" />
    <path
      d="M202.499 105.07c-2.25 0-6.39-.89-11.42-4a.505.505 0 0 1-.224-.531.503.503 0 0 1 .424-.389l3.29-.44a.6.6 0 0 1 .35.08c.05 0 4.73 3.17 8.31 2.75a.5.5 0 0 1 .39.88c.1.047.204.088.31.12a.726.726 0 0 1 .57.58.63.63 0 0 1-.32.61 3.4 3.4 0 0 1-1.68.34Zm-9.64-4.07c4.87 2.71 8.33 3.13 9.89 3.07a.75.75 0 0 1-.25-.44c-3.4 0-7.15-2.32-8-2.85l-1.64.22Z"
      fill={props.fill || 'currentColor'}
    />
    <path d="M196.5 68.61a10.18 10.18 0 0 1 6.79 1.77l-6.79-1.77Z" fill="#fff" />
    <path
      d="M203.29 70.88a.47.47 0 0 1-.31-.11 9.689 9.689 0 0 0-6.42-1.66.495.495 0 0 1-.5-.252.497.497 0 0 1-.06-.188.5.5 0 0 1 .1-.365.497.497 0 0 1 .33-.185A10.5 10.5 0 0 1 203.6 70a.5.5 0 0 1-.31.89v-.01ZM198.53 83.61a.5.5 0 0 1-.32-.88l6.19-5.24a.486.486 0 0 1 .549-.063c.058.03.11.072.151.123a.482.482 0 0 1-.06.7l-6.18 5.24a.52.52 0 0 1-.33.12ZM190.921 82.18a.499.499 0 0 1-.5-.43l-.94-7.25a.5.5 0 0 1 .248-.502.508.508 0 0 1 .559.037.498.498 0 0 1 .193.335l.93 7.25a.502.502 0 0 1-.43.56h-.06ZM208.531 95a.34.34 0 0 1-.14 0l-8.09-2.38a.487.487 0 0 1-.3-.62.51.51 0 0 1 .62-.34l8.09 2.39a.488.488 0 0 1 .34.62.504.504 0 0 1-.52.33ZM214.24 89.32a.49.49 0 0 1-.49-.42l-1.43-8.57a.505.505 0 0 1 .235-.511.5.5 0 0 1 .383-.062.51.51 0 0 1 .382.413l1.43 8.57a.5.5 0 0 1-.41.57l-.1.01ZM219 91.23a.507.507 0 0 1-.491-.519.505.505 0 0 1 .071-.25l3.33-5.24a.502.502 0 0 1 .69-.15.49.49 0 0 1 .16.69L219.43 91a.515.515 0 0 1-.43.23ZM220.43 76.94a.45.45 0 0 1-.2 0L215.94 75a.511.511 0 0 1-.25-.66.503.503 0 0 1 .469-.302.495.495 0 0 1 .191.042l4.28 1.92a.496.496 0 0 1 .302.469.486.486 0 0 1-.042.191.513.513 0 0 1-.46.28ZM210.44 69.33h-.08a.499.499 0 0 1-.42-.56l.48-3.34a.483.483 0 0 1 .195-.342A.496.496 0 0 1 211 65a.508.508 0 0 1 .43.57l-.48 3.33a.5.5 0 0 1-.51.43Z"
      fill={props.fill || 'currentColor'}
    />
    <path d="M209.479 100.25s-3.33 2.8-6.19 2.83l6.19-2.83Z" fill="#fff" />
    <path
      d="M203.289 103.58a.511.511 0 0 1-.5-.5.5.5 0 0 1 .5-.5c2.64 0 5.84-2.69 5.87-2.72a.488.488 0 0 1 .7.07.485.485 0 0 1 .063.55.484.484 0 0 1-.123.15c-.14.12-3.5 2.92-6.5 3l-.01-.05Z"
      fill={props.fill || 'currentColor'}
    />
    <path d="M194.641 100.25a11.906 11.906 0 0 1-1.34-5.25l1.34 5.25Z" fill="#fff" />
    <path
      d="M194.641 100.75a.507.507 0 0 1-.44-.26 12.435 12.435 0 0 1-1.4-5.49.499.499 0 1 1 1 0 11.35 11.35 0 0 0 1.28 5 .51.51 0 0 1 .041.382.49.49 0 0 1-.241.298.418.418 0 0 1-.24.07Z"
      fill={props.fill || 'currentColor'}
    />
    <path d="M182.85 85.08s.69 2.79 1.52 3.27h3.21" fill="#fff" />
    <path
      d="M187.579 88.85h-3.21a.592.592 0 0 1-.25-.07c-.94-.54-1.58-2.88-1.76-3.58a.508.508 0 0 1 .37-.61.502.502 0 0 1 .6.36 8.071 8.071 0 0 0 1.21 2.9h3a.5.5 0 0 1 .04 1Z"
      fill={props.fill || 'currentColor'}
    />
    <path d="M184.729 92.63c.47 1.43-.24-2.38-.36-4.28l.36 4.28Z" fill="#fff" />
    <path
      d="M184.85 93.45c-.38 0-.52-.42-.6-.66a.546.546 0 0 1 .09-.48c0-.32-.09-.73-.14-1.1-.13-.95-.28-2-.33-2.83a.51.51 0 0 1 .122-.364.501.501 0 0 1 .878.294c0 .76.2 1.88.32 2.77.28 2.06.3 2.23-.21 2.35l-.13.02ZM217.52 98.2h-1v.9h1v-.9Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="m39.67 108.31 71.151-77s11.37-11.68 1.18-19.51c-10.19-7.83-19 6.07-19 6.07l-58 85.05s-3.3-1.16-4.42.13c-1.51 1.71-1.78 3.88 2.51 7.78 4.29 3.9 6.91 1.79 6.91 1.79s2.54-1.07-.33-4.31Z"
      fill="#fff"
    />
    <path
      d="M38 113.7c-1.27 0-3-.53-5.21-2.5-3.85-3.5-4.64-6.12-2.56-8.48 1.11-1.26 3.62-.68 4.59-.39l57.77-84.75c.18-.28 4.73-7.36 11.219-8.52a10.569 10.569 0 0 1 8.49 2.34 10.346 10.346 0 0 1 4.42 7.6c.45 6.42-5.3 12.38-5.54 12.63l-70.84 76.7a3.838 3.838 0 0 1 1.12 3.19 2.345 2.345 0 0 1-1.25 1.53c-.65.443-1.423.67-2.21.65Zm-5.63-10.76a1.806 1.806 0 0 0-1.39.44c-1.23 1.39-1.77 3.21 2.48 7.08 3.88 3.52 6.07 1.85 6.16 1.78l.13-.08a1.346 1.346 0 0 0 .73-.88c.15-.67-.26-1.58-1.19-2.63l-.3-.34 71.459-77.39c.06-.07 5.69-5.9 5.26-11.87a9.355 9.355 0 0 0-4-6.85 9.557 9.557 0 0 0-7.7-2.15c-6 1.07-10.52 8-10.57 8.08l-58.21 85.39-.36-.13a8.76 8.76 0 0 0-2.49-.45h-.01Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M63.24 83.28a.46.46 0 0 1-.33-.13l-9-7.9a.502.502 0 0 1 0-.71.5.5 0 0 1 .7 0l9 7.9a.51.51 0 0 1 0 .71.52.52 0 0 1-.37.13ZM54.13 92.35a.57.57 0 0 1-.3-.1l-7.62-5.57a.502.502 0 0 1-.11-.7.51.51 0 0 1 .7-.11l7.62 5.57a.488.488 0 0 1 .11.7.5.5 0 0 1-.4.21ZM45.1 102.62a.471.471 0 0 1-.33-.13L39.63 98a.51.51 0 0 1-.05-.71.5.5 0 0 1 .71 0l5.14 4.51a.502.502 0 0 1-.043.792.501.501 0 0 1-.287.088v-.06Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M115.601 101.38a7.015 7.015 0 0 0 1.484-5.15 7.02 7.02 0 0 0-2.594-4.69 59.517 59.517 0 0 0 3.45-6.54 6.996 6.996 0 0 0-1.718-8.137 6.996 6.996 0 0 0-8.282-.753 59.335 59.335 0 0 0 2.1-6.68 7.001 7.001 0 0 0-2.1-6.9 6.884 6.884 0 0 0-3-1.6 7 7 0 0 0-8.46 5.07c-.2.8-.42 1.56-.64 2.29 0-.91.06-1.84.06-2.81a7 7 0 0 0-6.83-7h-.19a7 7 0 0 0-7 7c0 6.62-1.22 11.16-2.59 14.16l-.42.1c-2-7.42-5.54-11.1-6.19-11.72A7.016 7.016 0 1 0 63 78.18s3.35 3.89 3.11 12.94a19.491 19.491 0 0 0 21.17 26.64c3.01.12 15.58-.39 28.32-16.38Z"
      fill="#fff"
    />
    <path
      d="M84.17 118.51A20.001 20.001 0 0 1 65.65 91c.21-8.77-3-12.46-3-12.49a7.504 7.504 0 0 1 10.4-10.82c.68.65 4.07 4.15 6.09 11.11a34.132 34.132 0 0 0 2.26-13.29A7.52 7.52 0 0 1 88.88 58h.2a7.45 7.45 0 0 1 7.29 6.75 7.51 7.51 0 0 1 8.72-4.33 7.484 7.484 0 0 1 5.45 9.1c-.48 1.9-1 3.77-1.68 5.57a7.49 7.49 0 0 1 9.53 10.09 59.351 59.351 0 0 1-3.28 6.24 7.495 7.495 0 0 1 .89 10.28c-12.67 15.89-25.06 16.72-28.65 16.57a19.917 19.917 0 0 1-3.18.24Zm-16.3-51.89a6.5 6.5 0 0 0-4.49 11.2s3.51 4 3.27 13.31c.014.06.014.12 0 .18a19 19 0 0 0 20.63 26 .405.405 0 0 1 .11 0c3.39.14 15.43-.6 27.86-16.19a6.549 6.549 0 0 0 .76-6.91 6.458 6.458 0 0 0-1.79-2.23.531.531 0 0 1-.15-.67 57.163 57.163 0 0 0 3.45-6.5 6.504 6.504 0 0 0-1.594-7.534 6.5 6.5 0 0 0-7.666-.726.5.5 0 0 1-.58 0 .512.512 0 0 1-.15-.56 57.121 57.121 0 0 0 2.08-6.63 6.488 6.488 0 0 0-5.666-8.043 6.529 6.529 0 0 0-3.974.883 6.53 6.53 0 0 0-3 4c-.2.81-.42 1.57-.64 2.31a.506.506 0 0 1-1-.16c0-.91.06-1.83.06-2.79A6.46 6.46 0 0 0 89.05 59h-.17a6.51 6.51 0 0 0-6.48 6.53 34.3 34.3 0 0 1-2.64 14.36.49.49 0 0 1-.33.28H79.02a.47.47 0 0 1-.38 0 .48.48 0 0 1-.23-.31c-1.92-7.26-5.38-10.83-6-11.48a6.47 6.47 0 0 0-4.54-1.76Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M105.831 102.74a.507.507 0 0 1-.44-.26c-.1-.19-11.08-19.42-26.59-22.22a.508.508 0 1 1 .18-1c16 2.9 27.18 22.53 27.29 22.72a.496.496 0 0 1 .045.379.496.496 0 0 1-.235.301.466.466 0 0 1-.25.08ZM93.54 76.88h-.14a.51.51 0 0 1-.34-.62l2.3-8.11a.5.5 0 0 1 .977.078.492.492 0 0 1-.016.192L94 76.51a.5.5 0 0 1-.46.37ZM105.999 80.72a.59.59 0 0 1-.22 0 .492.492 0 0 1-.254-.287.51.51 0 0 1 .024-.383l2-4.15a.51.51 0 0 1 .67-.23.508.508 0 0 1 .254.287.498.498 0 0 1-.024.383l-2 4.14a.508.508 0 0 1-.45.24ZM114.461 92a.52.52 0 0 1-.35-.14c-.08-.08-1.82-1.68-5.52-.07a.49.49 0 0 1-.702-.441.507.507 0 0 1 .141-.358.486.486 0 0 1 .161-.111c4.36-1.91 6.53.18 6.62.27a.494.494 0 0 1 0 .7.511.511 0 0 1-.35.15ZM99.999 111.06a.51.51 0 1 1-.21-1c1.63-.73 2.61-1.32 2.62-1.32a.502.502 0 0 1 .69.16.51.51 0 0 1-.17.69c-.881.516-1.793.977-2.73 1.38a.483.483 0 0 1-.2.09ZM89.56 113.84a.5.5 0 0 1 0-1c1.77-.179 3.52-.514 5.23-1a.501.501 0 0 1 .61.35.51.51 0 0 1-.35.62 32.047 32.047 0 0 1-5.4 1l-.09.03Zm-5.4-.1h-.06a21.242 21.242 0 0 1-5.34-1.42.5.5 0 0 1 .38-.92 20.67 20.67 0 0 0 5.09 1.35.501.501 0 1 1-.07 1v-.01Zm-10-4a.6.6 0 0 1-.28-.08A19.717 19.717 0 0 1 69.8 106a.507.507 0 0 1 .07-.71.505.505 0 0 1 .71.07 18.03 18.03 0 0 0 3.92 3.49.507.507 0 0 1 .13.69.504.504 0 0 1-.42.22l-.05-.02ZM67.8 101.36a.5.5 0 0 1-.49-.4 7.42 7.42 0 0 1 0-3.22.51.51 0 0 1 .802-.24.491.491 0 0 1 .158.53 6.471 6.471 0 0 0 0 2.74.479.479 0 0 1-.39.58l-.08.01Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M93.54 69.06a.49.49 0 0 1-.44-.26.51.51 0 0 1 .2-.68l4.7-2.56a.5.5 0 0 1 .622.76.49.49 0 0 1-.153.12L93.78 69a.43.43 0 0 1-.24.06Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M97.84 70a.49.49 0 0 1-.38-.18l-3.92-4.54a.51.51 0 0 1 .42-.832.492.492 0 0 1 .34.172l3.92 4.55a.51.51 0 0 1-.06.71.49.49 0 0 1-.32.12ZM109.93 77.52h-.08l-5.76-.9a.512.512 0 0 1-.42-.57.512.512 0 0 1 .57-.42l5.76.9a.502.502 0 1 1-.07 1v-.01Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M107.81 79.45a.491.491 0 0 1-.49-.41l-1.09-5.9a.51.51 0 0 1 .607-.585.502.502 0 0 1 .383.405l1.08 5.9a.497.497 0 0 1-.4.58l-.09.01Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M93.57 96.09c5.385 0 9.75-4.365 9.75-9.75s-4.365-9.75-9.75-9.75c-5.384 0-9.75 4.365-9.75 9.75s4.366 9.75 9.75 9.75Z"
      fill="#fff"
    />
    <path
      d="M93.57 96.59a10.25 10.25 0 1 1 10.25-10.25 10.256 10.256 0 0 1-10.25 10.25Zm0-19.5a9.25 9.25 0 1 0 9.25 9.25 9.258 9.258 0 0 0-9.25-9.25Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M86 87.14a7.8 7.8 0 0 1-2.35-.33l.31-.94a7.64 7.64 0 0 0 7.62-2.16c3.33-3.45 1.61-6.74 1.54-6.88l.87-.48s2.16 4.05-1.69 8.05a8.54 8.54 0 0 1-6.3 2.74ZM91.48 96c0-.14 0-3.6 4.29-7.6 4.29-4 7.64-2.54 7.78-2.48l-.45.9c-.11-.05-2.78-1.27-6.65 2.31-3.87 3.58-4 6.82-4 6.85l-.97.02Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M87.6 89a.5.5 0 0 1-.5-.5v-3.73a.5.5 0 1 1 1 0v3.77a.5.5 0 0 1-.5.46ZM92.63 85.27a.49.49 0 0 1-.35-.15l-2.52-2.51a.51.51 0 0 1 0-.71.5.5 0 0 1 .71 0L93 84.41a.5.5 0 0 1 0 .71.47.47 0 0 1-.37.15ZM95.15 80.24h-2.52a.5.5 0 1 1 0-1h2.52a.5.5 0 0 1 .5.5.51.51 0 0 1-.5.5ZM93.89 94.07a.421.421 0 0 1-.22 0l-2.52-1.26a.493.493 0 0 1-.22-.67.5.5 0 0 1 .67-.22l2.51 1.26a.5.5 0 0 1 .23.67.51.51 0 0 1-.45.22ZM97.66 90.3a.5.5 0 0 1-.35-.15l-2.52-2.51a.502.502 0 0 1 .71-.71l2.5 2.52a.5.5 0 0 1-.34.85ZM101.441 87.78a.5.5 0 0 1-.5-.5v-2.51a.5.5 0 0 1 1 0v2.51a.499.499 0 0 1-.5.5Z"
      fill={props.fill || 'currentColor'}
    />
    <path d="M49.82 30.09a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z" fill="#fff" />
    <path
      d="M49.82 30.59a6.5 6.5 0 1 1 6.5-6.5 6.51 6.51 0 0 1-6.5 6.5Zm0-12a5.5 5.5 0 1 0 5.5 5.5 5.51 5.51 0 0 0-5.5-5.5Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M186.13 102.61a2.377 2.377 0 0 1 .75 1.73 2.375 2.375 0 0 1-.75 1.73c-4.13 4-15.35 13.52-28.56 13.52-13.21 0-24.42-9.52-28.55-13.52a2.376 2.376 0 0 1-.75-1.73 2.37 2.37 0 0 1 .75-1.73c4.13-4 15.35-13.52 28.55-13.52 13.2 0 24.43 9.52 28.56 13.52Z"
      fill="#fff"
    />
    <path
      d="M157.57 120.09c-13.34 0-24.66-9.55-28.9-13.66a2.864 2.864 0 0 1-.664-.953 2.869 2.869 0 0 1 0-2.275 2.87 2.87 0 0 1 .664-.952c4.24-4.11 15.56-13.66 28.9-13.66 13.34 0 24.67 9.55 28.91 13.66a2.87 2.87 0 0 1 .899 2.09c0 .391-.08.778-.235 1.137-.154.36-.38.684-.664.953-4.24 4.11-15.56 13.66-28.91 13.66Zm0-30.5c-13 0-24 9.36-28.21 13.38a1.883 1.883 0 0 0-.435 2.115c.101.236.249.448.435.625 4.16 4 15.23 13.38 28.21 13.38s24.06-9.35 28.21-13.38a1.859 1.859 0 0 0 0-2.74c-4.15-3.97-15.22-13.38-28.21-13.38Z"
      fill={props.fill || 'currentColor'}
    />
    <path d="M179.359 97.06s3.69 5.72-1.3 15.5l1.3-15.5Z" fill="#fff" />
    <path
      d="M178.059 113.06a.496.496 0 0 1-.425-.242.504.504 0 0 1-.015-.488c4.78-9.37 1.35-14.94 1.32-15a.5.5 0 0 1 .84-.54c.16.24 3.79 6.08-1.27 16a.511.511 0 0 1-.187.199.51.51 0 0 1-.263.071Z"
      fill={props.fill || 'currentColor'}
    />
    <path d="M136.38 97.06s-3.7 5.72 1.29 15.5l-1.29-15.5Z" fill="#fff" />
    <path
      d="M137.67 113.06a.49.49 0 0 1-.44-.28c-5.06-9.91-1.43-15.75-1.27-16a.502.502 0 0 1 .69-.15.508.508 0 0 1 .15.69c0 .06-3.46 5.63 1.32 15a.497.497 0 0 1-.22.67.466.466 0 0 1-.23.07Z"
      fill={props.fill || 'currentColor'}
    />
    <path d="M135.32 103.09s26.42-10 45 0h-45Z" fill="#fff" />
    <path
      d="M135.32 103.59a.478.478 0 0 1-.46-.32.512.512 0 0 1 .01-.386.5.5 0 0 1 .28-.264c.26-.1 26.84-10 45.41 0a.502.502 0 0 1 .237.692.517.517 0 0 1-.313.247.496.496 0 0 1-.394-.059c-18.18-9.78-44.33-.07-44.59 0a.553.553 0 0 1-.18.09Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M149.401 102.75a.504.504 0 0 1-.49-.37l-1.14-4.32a.516.516 0 1 1 1-.26l1.14 4.32a.515.515 0 0 1-.36.62l-.15.01ZM159.32 102.63a.5.5 0 0 1-.5-.5v-6.08a.5.5 0 0 1 1 0v6.08a.497.497 0 0 1-.5.5ZM167.83 102.59h-.13a.508.508 0 0 1-.36-.61l1-4a.498.498 0 0 1 .61-.36.5.5 0 0 1 .306.224.478.478 0 0 1 .054.376l-1 4a.5.5 0 0 1-.48.37Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default GroundSports;
