import React from 'react';

import { Slide, Slider } from 'pure-react-carousel';

import { IClassRecommendationEntity } from '../../../../../types';
import Carousel from '../../../../common/Carousel';
import { ClassRecommendation } from '../recommendation';

import './index.scss';

interface IClassRecommendationCarouselProps {
  classes: IClassRecommendationEntity[];
  onInfoDialogOpen: () => void;
}

const ClassRecommendationCarousel: React.FC<IClassRecommendationCarouselProps> = ({ classes, onInfoDialogOpen }) => {
  return (
    <div className="class-recommendation-carousel">
      <Carousel items={classes} cardWidth={220} gap={12}>
        <Slider>
          {classes.map((classItem, index) => {
            return (
              <Slide key={`${index}-${classItem.name}`} index={index} className="slide">
                <ClassRecommendation onOpen={onInfoDialogOpen} {...classItem} />
              </Slide>
            );
          })}
        </Slider>
      </Carousel>
    </div>
  );
};

export default ClassRecommendationCarousel;
