/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { format } from 'date-fns';

import {
  getAdminDeleteHistoryActions, getSourcesActions, restorePortfolioEntityActions
} from '../../../actions';
import { DeleteHistoryContext } from '../../../context';
import { DifferentCode } from '../../../const';
import { IRootState } from '../../../reducers';
import { IDeleteHistoryFilters } from '../../admin/DeleteHistory/types';
import { AdminDeleteHistoryHeader, AdminDeleteHistoryTable } from '../../../components/admin/DeleteHistory';
import { IAdminDeleteHistoryRequest, ICommonResponse, IDictionaryItem } from '../../../api/types';
import PaddingContainer from 'portfolio3/components/common/PaddingContainer';
import { parentSectionsArchiveFalseSelector } from 'selectors';
import { useLoadSectionRef } from 'hooks';

import './index.scss';

interface IDeleteHistoryProps {
  portfolioSections: ICommonResponse<IDictionaryItem>,
  sources: ICommonResponse<IDictionaryItem>,
  getAdminDeleteHistory: typeof getAdminDeleteHistoryActions.request,
  getSources: typeof getSourcesActions.request,
  restorePortfolioEntity: typeof restorePortfolioEntityActions.request
}

const DeleteHistory: React.FC<IDeleteHistoryProps> = ({
  portfolioSections,
  sources,
  getAdminDeleteHistory,
  getSources,
  restorePortfolioEntity
}) => {
  const initialFilters: IDeleteHistoryFilters = {
    startDate: null,
    endDate: null,
    category: portfolioSections.content?.length === 1 ? portfolioSections.content[0].code : DifferentCode,
    subCategory: undefined,
    student: undefined,
    deleter: undefined,
    page: 0,
    limit: 10,
    sort: { date: 'desc' }
  };

  const [filters, setFilters] = useState<IDeleteHistoryFilters>(initialFilters);
  const getCurrentHistoryCategoryCode = (categoryCode?: number) => {
    if (categoryCode === DifferentCode) return undefined;
    return categoryCode;
  };

  useLoadSectionRef();

  useEffect(() => {
    if (!sources.content) getSources();
  }, []);

  const adminHistoryRequestData: IAdminDeleteHistoryRequest = {
    categoryCode: getCurrentHistoryCategoryCode(filters.category),
    dataKind: getCurrentHistoryCategoryCode(filters.subCategory),
    startDate: filters.startDate ? format(filters.startDate, 'yyyy-MM-dd') : undefined,
    endDate: filters.endDate ? format(filters.endDate, 'yyyy-MM-dd') : undefined,
    student: filters.student?.lastName || filters.student?.firstName || filters.student?.patronymic ? {
      lastName: filters.student?.lastName,
      firstName: filters.student?.firstName,
      patronymic: filters.student?.patronymic
    } : undefined,
    creator: filters.deleter?.lastName || filters.deleter?.firstName || filters.deleter?.patronymic ? {
      lastName: filters.deleter?.lastName,
      firstName: filters.deleter?.firstName,
      patronymic: filters.deleter?.patronymic
    } : undefined,
    pagingOptions: {
      pageNumber: filters.page,
      pageSize: filters.limit
    },
    sort: filters.sort
  };

  useEffect(() => {
    getAdminDeleteHistory(adminHistoryRequestData);
  }, [filters]);

  const restoreEntity = (entityId: number, entityType: string) => {
    restorePortfolioEntity(entityId, entityType, adminHistoryRequestData);
  };

  return (
    <DeleteHistoryContext.Provider value={{
      currentFilters: filters,
      setCurrentFilters: setFilters
    }}
    >
      <AdminDeleteHistoryHeader />
      <PaddingContainer className="delete-history-container">
        <AdminDeleteHistoryTable onRestoreEntity={restoreEntity} />
      </PaddingContainer>
    </DeleteHistoryContext.Provider>
  );
};

export default connect(
  (state: IRootState) => ({
    portfolioSections: parentSectionsArchiveFalseSelector(state),
    sources: state.sources
  }),
  {
    getAdminDeleteHistory: getAdminDeleteHistoryActions.request,
    getSources: getSourcesActions.request,
    restorePortfolioEntity: restorePortfolioEntityActions.request
  }
)(DeleteHistory);
