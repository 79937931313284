/* eslint-disable max-len */
import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconScienceRewardsCommon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 5.7 9.6 8.9l-3.9 1.3 2.4 3.3v4.1l3.9-1.2 3.9 1.2v-4.1l2.4-3.3-3.9-1.3L12 5.7Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0Zm0 21.3c-5.2 0-9.3-4.2-9.3-9.3 0-5.2 4.2-9.3 9.3-9.3 5.1 0 9.3 4.2 9.3 9.3 0 5.2-4.2 9.3-9.3 9.3Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconScienceRewardsCommon;
