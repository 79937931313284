import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';

import { dataEntryDrawerActions, detailViewDrawerActions } from 'actions';
import PortfolioDataEntryForm from 'components/common/PortfolioDataEntryForm';
import { useAppSelector } from 'hooks';
import { WidgetDataContext } from 'portfolio3/components/common/WidgetContainer';
import { toast } from 'portfolio3/ui-kit';
import InfoModal from 'portfolio3/ui-kit/modals/InfoModal';

type CloseReason = 'submit' | 'close';

const DataEntryDrawersContainer: FC = () => {
  const dispatch = useDispatch();
  const {
    primaryState,
    secondaryState,
    primaryInitialFormData,
    secondaryInitialFormData,
    excludedEventTypes,
    personsObjectsQueryParams,
  } = useAppSelector((state) => state.dataEntryDrawer);

  const [isPrimaryChanged, setPrimaryChanged] = useState(false);
  const [isSecondaryChanged, setSecondaryChanged] = useState(false);
  const [isCloseConfirmDialogOpen, setCloseConfirmDialogOpen] = useState(false);

  // SP-4890 - требование закрывать форму подробного просмотра после сохранения изменений в форме редактирования
  const closeAllDetailViewDrawersOnSubmit = () => {
    // Если сохранили изменения во второй форме, то ничего не делать
    if (secondaryState?.isOpen) return;

    dispatch(detailViewDrawerActions.setPrimaryOpen(false, null));
    dispatch(detailViewDrawerActions.setSecondaryOpen(false, null));
  };

  const handleClosePrimary = (reason: CloseReason) => {
    if (reason === 'close' && isPrimaryChanged) {
      setCloseConfirmDialogOpen(true);
      return;
    }

    if (reason === 'submit') {
      closeAllDetailViewDrawersOnSubmit();
    }

    dispatch(dataEntryDrawerActions.setPrimaryOpen(false, null));
  };

  const handleCloseSecondary = (reason: CloseReason) => {
    if (reason === 'close' && isSecondaryChanged) {
      setCloseConfirmDialogOpen(true);
      return;
    }

    if (reason === 'submit') {
      closeAllDetailViewDrawersOnSubmit();
    }

    dispatch(dataEntryDrawerActions.setSecondaryOpen(false, null));
  };

  const handleCloseConfirm = () => {
    setCloseConfirmDialogOpen(false);

    if (secondaryState?.isOpen) {
      dispatch(dataEntryDrawerActions.setSecondaryOpen(false, null));
      return;
    }

    if (primaryState?.isOpen) {
      dispatch(dataEntryDrawerActions.setPrimaryOpen(false, null));
    }
  };

  const handleOpenEventForm = (categoryCode: number, dataKind: number) => {
    dispatch(dataEntryDrawerActions.setSecondaryInitialFormData({ categoryCode, dataKind }));
    dispatch(dataEntryDrawerActions.setSecondaryOpen(true, primaryState?.widgetLabel ?? null));
  };

  const handleCloseConfirmDialog = () => setCloseConfirmDialogOpen(false);

  const confirmCloseDialogElement = (
    <InfoModal
      open={isCloseConfirmDialogOpen}
      onClose={handleCloseConfirmDialog}
      header="Публикация информации в портфолио"
      text="Вы уверены, что хотите выйти из процесса ввода данных?
            Обратите внимание, что в случае выхода внесенные изменения не сохраняться."
      variant="warning"
      cancelButtonText="Отмена"
      onCancel={handleCloseConfirmDialog}
      actionButtonText="Да, закрыть"
      onAction={handleCloseConfirm}
    />
  );

  return (
    <WidgetDataContext.Provider
      value={{
        widgetLabel: primaryState?.widgetLabel ?? null,
      }}
    >
      <PortfolioDataEntryForm
        isOpen={primaryState?.isOpen ?? false}
        excludedEventTypeOptions={excludedEventTypes}
        queryParams={personsObjectsQueryParams}
        onOpenEventForm={handleOpenEventForm}
        onClose={handleClosePrimary}
        initialFormData={primaryInitialFormData ?? undefined}
        onFormChanged={setPrimaryChanged}
      />
      <PortfolioDataEntryForm
        isOpen={secondaryState?.isOpen ?? false}
        excludedEventTypeOptions={excludedEventTypes}
        queryParams={personsObjectsQueryParams}
        onOpenEventForm={() => toast.error('Открыто слишком много всплывающих окон')}
        onClose={handleCloseSecondary}
        initialFormData={secondaryInitialFormData ?? undefined}
        onFormChanged={setSecondaryChanged}
      />

      {confirmCloseDialogElement}
    </WidgetDataContext.Provider>
  );
};

export default DataEntryDrawersContainer;
