import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Collapse } from '@mui/material';
import { deleteAttachmentTemplateInfoActions, getAttachmentTemplateInfoActions, getImportListActions } from 'actions';
import { importerChapters, tooltipOptions } from 'const';
import { useAppSelector } from 'hooks';
import { toast } from 'portfolio3/ui-kit';
import { mergeSx } from 'utils';

import TaskPanelHeader from './Header';
import * as styles from './styles';
import Task from './Task';
import ToastContent from './ToastContent';

interface ITaskPanelProps {
  onOpenImportDialog: (initialChapter: number) => void;
}

const TaskPanel: FC<ITaskPanelProps> = ({ onOpenImportDialog }) => {
  const dispatch = useDispatch();
  const importList = useAppSelector((state) => state.importList.content);

  // подъем и подсветка хедера при наведении
  const [isHovered, setHovered] = useState(false);
  // подъем и подсветка хедера вне зависимости от наведения
  const [isForceActive, setForceActive] = useState(false);
  // состояние открытия, при этом хедер ведет себя как при наведении
  const [isOpen, setOpen] = useState(false);

  const computedActive = isHovered || isForceActive || isOpen;

  const { inProgress, ready, preview, parseInfo } = importList || {};
  const [currentParseInfo, setCurrentParseInfo] = useState(parseInfo);

  useEffect(() => {
    dispatch(getImportListActions.request());
    const importIntervalId = setInterval(() => dispatch(getImportListActions.request()), 60000);
    return () => clearInterval(importIntervalId);
  }, [dispatch]);

  const handleOpenImportDialog = (initialChapter: number, uuid: string) => {
    dispatch(getAttachmentTemplateInfoActions.request(uuid));
    setOpen(false);
    setForceActive(false);
    onOpenImportDialog(initialChapter);
  };

  useEffect(() => {
    let taskPanelTimeoutId: NodeJS.Timeout;
    if (parseInfo) {
      parseInfo.forEach((info) => {
        const currentInfo = currentParseInfo?.find((currentInfo) => currentInfo.uuid === info.uuid);
        if (!currentInfo || currentInfo?.status !== info?.status) {
          setForceActive(true);
          taskPanelTimeoutId = setTimeout(() => {
            setForceActive(false);
          }, 5000);

          if (info.status === 'PREVIEW') {
            toast.success(
              <ToastContent
                filename={info.filename ?? ''}
                onAction={() => handleOpenImportDialog(importerChapters.THIRD, info.uuid)}
              />,
              { ...tooltipOptions, autoClose: 6000 },
            );
          }
        }
      });
      setCurrentParseInfo(parseInfo);
    }
    return () => clearTimeout(taskPanelTimeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parseInfo]);

  useEffect(() => {
    if (isOpen) {
      dispatch(getImportListActions.request());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (!importList || importList.parseInfo?.length === 0) {
      setOpen(false);
      setForceActive(false);
    }
  }, [importList]);

  const handleOpenTaskPanel = () => {
    if (isOpen) return;
    setOpen(true);
    setForceActive(false);
  };

  if (!importList || importList.parseInfo?.length === 0) return null;

  return (
    <Box
      className="task-panel"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      sx={mergeSx(styles.root, computedActive && styles.rootActive)}
    >
      <Box
        role="button"
        className="task-panel__container"
        onClick={handleOpenTaskPanel}
        sx={mergeSx(styles.container, computedActive && styles.containerActive)}
      >
        <TaskPanelHeader
          isActive={computedActive}
          isOpen={isOpen}
          progressTasks={inProgress}
          previewTasks={preview}
          readyTasks={ready}
          onClear={() => dispatch(deleteAttachmentTemplateInfoActions.request())}
          onClose={() => setOpen(false)}
        />
        <Collapse in={isOpen}>
          <Box className="task-panel__list" sx={styles.taskList}>
            {parseInfo.map((task) => (
              <Task task={task} key={task.uuid} onOpenImportDialog={handleOpenImportDialog} />
            ))}
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
};

export default TaskPanel;
