import { RecommendationsHeadKindCodes, RecommendationsHeadKindKeys, SectionCodes } from 'const';
import {
  civilIllustration,
  creationIllustration,
  cultureIllustration,
  scienceIllustration,
  sportIllustration,
  studyIllustration,
} from 'images/recommendations';
import { AccentColorType } from 'portfolio3/styles';

interface IRecommendationsTheme {
  backgroundIllustration: string;
  accentColor: AccentColorType;
}

interface IEisDopRecommendationSection {
  cardTitle: string;
  drawerTitle: string;
  theme: IRecommendationsTheme;
}

export const studyTheme: IRecommendationsTheme = {
  backgroundIllustration: studyIllustration,
  accentColor: 'violet',
};

export const scienceTheme: IRecommendationsTheme = {
  backgroundIllustration: scienceIllustration,
  accentColor: 'indigo',
};

export const sportTheme: IRecommendationsTheme = {
  backgroundIllustration: sportIllustration,
  accentColor: 'cyan',
};

export const creationTheme: IRecommendationsTheme = {
  backgroundIllustration: creationIllustration,
  accentColor: 'orange',
};

export const cultureTheme: IRecommendationsTheme = {
  backgroundIllustration: cultureIllustration,
  accentColor: 'red',
};

export const civilTheme: IRecommendationsTheme = {
  backgroundIllustration: civilIllustration,
  accentColor: 'green',
};

export const RecommendationsSectionThemes: Record<number, IRecommendationsTheme> = {
  [RecommendationsHeadKindCodes[RecommendationsHeadKindKeys.SPORT]]: sportTheme,
  [RecommendationsHeadKindCodes[RecommendationsHeadKindKeys.STUDY]]: studyTheme,
  [RecommendationsHeadKindCodes[RecommendationsHeadKindKeys.SCIENCE]]: scienceTheme,
  [RecommendationsHeadKindCodes[RecommendationsHeadKindKeys.CULTURE]]: cultureTheme,
  [RecommendationsHeadKindCodes[RecommendationsHeadKindKeys.CREATION]]: creationTheme,
};

export const EisDopCategoryCodeToSectionCode: Record<number, number> = {
  164: SectionCodes.civil,
  165: SectionCodes.science,
  166: SectionCodes.creation,
  170: SectionCodes.sport,
  172: SectionCodes.sport,
  173: SectionCodes.science,
};

export const EisDopRecommendationProperties: Record<number, IEisDopRecommendationSection> = {
  [SectionCodes.science]: {
    cardTitle: 'Научные кружки',
    drawerTitle: 'Подборка научных кружков',
    theme: scienceTheme,
  },
  [SectionCodes.sport]: {
    cardTitle: 'Спортивные кружки',
    drawerTitle: 'Подборка спортивных кружков',
    theme: sportTheme,
  },
  [SectionCodes.creation]: {
    cardTitle: 'Творческие кружки ',
    drawerTitle: 'Подборка творческих кружков',
    theme: creationTheme,
  },
  [SectionCodes.civil]: {
    cardTitle: 'Кружки по гражданской активности',
    drawerTitle: 'Подборка кружков по гражданской активности',
    theme: civilTheme,
  },
};
