import { FC, useContext } from 'react';

import { PortfolioSettingsContext } from 'context';
import { useAppSelector, useDisclosure } from 'hooks';
import { IconHeight } from 'icons';
import { emitYMEvent } from 'portfolio3/features/yandexMetrika';
import { Button } from 'portfolio3/ui-kit/button';
import { Select } from 'portfolio3/ui-kit/selects';
import { SelectController } from 'portfolio3/ui-kit/types';

import {
  MINIMUM_EGE_EXAMS,
  vuzListSortOptions,
  vuzListSortOptionsWithBallDifference,
  VuzRecommendationTab,
} from '../../const';
import { realExamSubjectsSelector, trialExamSubjectsSelector } from '../../model/selectors';
import SortingDrawer from '../SortingDrawer';

/**
 * Для вкладки "По выбранным предметам" не нужна сортировка по разнице в баллах
 * Для вкладки "Избранное" при наличии 3+ настоящих или пробных экзаменов
 *   добавляются опции сортировки по разнице в баллах
 *
 */
const getSortOptions = (tab: VuzRecommendationTab, realExamCount: number, trialExamCount: number) => {
  switch (tab) {
    case VuzRecommendationTab.SUBJECTS:
      return vuzListSortOptions;
    case VuzRecommendationTab.FAVORITES: {
      const hasMinimumExams = realExamCount >= MINIMUM_EGE_EXAMS || trialExamCount >= MINIMUM_EGE_EXAMS;
      return hasMinimumExams ? vuzListSortOptionsWithBallDifference : vuzListSortOptions;
    }
    default:
      return vuzListSortOptionsWithBallDifference;
  }
};

interface IVuzListSortButtonProps {
  isMobile: boolean;
  currentTab: VuzRecommendationTab;
  sortType: number;
  onChangeSortType: (value: number) => void;
}

const VuzListSortButton: FC<IVuzListSortButtonProps> = ({ isMobile, currentTab, sortType, onChangeSortType }) => {
  const realExamSubjects = useAppSelector(realExamSubjectsSelector);
  const trialExamSubjects = useAppSelector(trialExamSubjectsSelector);

  const { isSettingsMode } = useContext(PortfolioSettingsContext);
  const { isOpen: isSortDrawerOpen, onOpen: onOpenSortDrawer, onClose: onCloseSortDrawer } = useDisclosure();

  const sortOptions = getSortOptions(currentTab, realExamSubjects.content.length, trialExamSubjects.content.length);

  const selectController: SelectController = {
    handleChange(value) {
      const newSortCode = Number(value);
      onChangeSortType(newSortCode);

      if (sortType !== newSortCode) {
        const sortTypeName = sortOptions.find((option) => option.code === newSortCode)?.value;

        emitYMEvent({
          type: 'recommendationSorting',
          payload: {
            typeSorting: sortTypeName ?? '',
          },
        });
      }
    },
  };

  const desktopElement = (
    <Select
      value={sortType}
      options={sortOptions}
      controller={selectController}
      inputRenderMode="fixed"
      inputSize="medium"
      startIcon={<IconHeight />}
    />
  );

  const mobileElement = (
    <Button variant="secondary" onlyIcon onClick={onOpenSortDrawer} disabled={isSettingsMode}>
      <IconHeight />
    </Button>
  );

  return (
    <>
      {isMobile ? mobileElement : desktopElement}
      {isMobile && (
        <SortingDrawer
          value={sortType}
          options={sortOptions}
          isOpen={isSortDrawerOpen}
          onChange={onChangeSortType}
          onClose={onCloseSortDrawer}
        />
      )}
    </>
  );
};

export default VuzListSortButton;
