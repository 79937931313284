import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ThemeProvider, useMediaQuery } from '@mui/material';
import { getAttachmentActions } from 'actions';
import { IPersonObject, IStudentFile } from 'api/types';
import { PortfolioEntityCard, PortfolioEntityCardReward } from 'components/common/PortfolioEntityCard';
import { SectionCodes } from 'const';
import { useAppSelector, useBrightTheme } from 'hooks';
import { WidgetDataContext } from 'portfolio3/components/common/WidgetContainer';
import {
  EntityActions,
  getGroupedEntityActionDeleteText,
  useCommonEntityHandlers,
} from 'portfolio3/components/entityActions';
import { emitYMEvent } from 'portfolio3/features/yandexMetrika';
import { AccentColorType } from 'portfolio3/styles';
import { commonTheme, generateAccentTheme } from 'portfolio3/styles/theme';
import { ICardData, ITab } from 'types';
import {
  getCategoryAccentColor,
  getDataItemType,
  getEntityCardIllustration,
  getEntityGeneralInfo,
  getEntitySectionTitle,
  getEntityTypeByTypeCode,
  isDefined,
  isProfessionalEducationEntity,
} from 'utils';

import DetailViewDrawerDesktop from './DetailViewDrawerDesktop';
import DetailViewDrawerMobile from './DetailViewDrawerMobile';
import { DetailViewTab, ICommonDrawerProps } from './types';

interface IDetailViewDrawerProps {
  isOpen: boolean;
  cardData: ICardData | undefined;
  onClose: () => void;
  onOpenSecondaryDetailViewDrawer: (currentCardData: ICardData) => void;
}

const DetailViewDrawer: FC<IDetailViewDrawerProps> = ({
  isOpen,
  cardData,
  onClose,
  onOpenSecondaryDetailViewDrawer,
}) => {
  const dispatch = useDispatch();
  const currentStudent = useAppSelector((state) => state.currentStudent);
  const studentRewards = useAppSelector((state) => state.studentRewards);
  const studentSportRewards = useAppSelector((state) => state.studentSportRewards);
  const personsObjects = useAppSelector((state) => state.personsObjects);

  const sectionRef = useAppSelector((state) => state.sectionRef.content);

  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const [activeTab, setActiveTab] = useState<DetailViewTab>(DetailViewTab.GENERAL);

  const isBrightTheme = useBrightTheme();
  const { widgetLabel } = useContext(WidgetDataContext);

  const { getHandlers } = useCommonEntityHandlers();
  const { handleEdit, handleDelete, handleReport, handleThanksTeacher, deleteDialogElement } = getHandlers({
    formData: cardData?.formData,
    cardData,
    onDeleted: onClose,
  });

  const linkedObjectIds = cardData?.linkedObjects?.map((linkedObject) => linkedObject.entityId);
  // получаем связанные сущности
  const personLinkedObjects = personsObjects.content?.filter((object) => {
    return linkedObjectIds?.includes(object.id);
  });
  // формируем список связанных сущностей в исходном порядке
  const sortedPersonLinkedObjects = linkedObjectIds
    ?.map((linkedObjectId) => {
      return personLinkedObjects.find((object) => object.id === linkedObjectId);
    })
    .filter(isDefined);

  const categoryCode: number | undefined = cardData?.formData?.categoryCode;
  const dataKind: number | undefined = cardData?.formData?.dataKind;
  const typeCode: number | undefined = cardData?.formData?.typeCode ?? cardData?.formData?.type?.code;
  const typeCodeOrDataKind = typeCode ?? dataKind;

  // невозможно по dataKind определить тип некоторых сущностей
  const entityType = getEntityTypeByTypeCode(typeCode) ?? getDataItemType(dataKind);

  const categoryAccentColor: AccentColorType = categoryCode ? getCategoryAccentColor(categoryCode) : 'indigo';
  const accentColor: AccentColorType = isBrightTheme ? categoryAccentColor : 'indigo';

  const eventSecondaryCardData = cardData?.event?.cardData;

  const isProfessionalEducation = isProfessionalEducationEntity(cardData?.formData);
  const isGiaWorldSkillsEntity = typeCode === SectionCodes.professionExamGia;

  const isFilesTabShown = !isProfessionalEducation && !cardData?.excludeCardTabs?.includes('files');
  const isLinkedObjectsTabShown =
    !isGiaWorldSkillsEntity && !isProfessionalEducation && !cardData?.excludeCardTabs?.includes('linkedObjects');

  const entitySectionTitle = getEntitySectionTitle(sectionRef, dataKind, typeCode, cardData?.titleFallback);

  const backgroundImage = isBrightTheme
    ? getEntityCardIllustration(typeCodeOrDataKind) ?? cardData?.illustrationFallback
    : null;

  const handleOpenRewardSecondaryCard = () => {
    if (cardData?.reward?.cardData) {
      onOpenSecondaryDetailViewDrawer(cardData?.reward?.cardData);
    }
  };

  const handleOpenEventSecondaryCard = useCallback(() => {
    if (eventSecondaryCardData) {
      onOpenSecondaryDetailViewDrawer(eventSecondaryCardData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventSecondaryCardData]);

  const handleDownloadFile = useCallback(
    (file: IStudentFile) => {
      dispatch(getAttachmentActions.request({ id: file.id, personId: currentStudent.meshId, name: file.name }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentStudent.meshId],
  );

  const handleOpenLinkedObjectSecondaryCard = useCallback((personObject: IPersonObject) => {
    const linkedObjectCardData: ICardData = {
      name: personObject.name,
      generalInfo: getEntityGeneralInfo(personObject),
      files: personObject.fileReferences,
      linkedObjects: personObject.linkedObjects,
      formData: personObject,
      illustrationFallback: null,
    };
    onOpenSecondaryDetailViewDrawer(linkedObjectCardData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isOpen) {
      setActiveTab(DetailViewTab.GENERAL);
    }
  }, [isOpen]);

  const bottomContent = (
    <>
      {cardData?.reward && (
        <PortfolioEntityCardReward
          name={cardData.reward.result}
          date={cardData.reward.rewardDate}
          image={cardData.reward.image}
          onOpenRewardCard={handleOpenRewardSecondaryCard}
        />
      )}
      <EntityActions
        formData={cardData?.formData}
        entityType={entityType}
        entityFlags={cardData?.entityFlags ?? []}
        actionsMap={{
          edit: {
            effect: handleEdit,
          },
          delete: {
            text: getGroupedEntityActionDeleteText(typeCode, isMobile),
            isDisabled: studentRewards.loading || studentSportRewards.loading,
            effect: handleDelete(studentRewards.content, studentSportRewards.content),
          },
          report: {
            isDisabled: studentRewards.loading || studentSportRewards.loading,
            effect: handleReport,
          },
          thanks: {
            effect: handleThanksTeacher,
          },
        }}
      />
    </>
  );

  const tabs: ITab[] = [
    {
      title: isMobile ? 'Общее' : 'Общие сведения',
      value: DetailViewTab.GENERAL,
    },
  ];

  if (isFilesTabShown) {
    tabs.push({
      title: isMobile ? 'Файлы' : 'Файлы и вложения',
      value: DetailViewTab.FILES,
    });
  }
  if (isLinkedObjectsTabShown) {
    tabs.push({
      title: isMobile ? 'Связанные' : 'Связанные достижения',
      value: DetailViewTab.LINKED_OBJECTS,
    });
  }

  const handleChangeActiveTab = (tabValue: number) => {
    setActiveTab(tabValue);

    const tabName = tabs.find((tab) => tab.value === tabValue)?.title;

    if (!tabName || activeTab === tabValue) return;

    const isRewardEntity = cardData?.entityFlags?.includes('reward');

    if (isRewardEntity) {
      emitYMEvent({
        type: 'tabsSwitchingReward',
        payload: {
          tabMoreReward: tabName,
        },
      });
    } else {
      emitYMEvent({
        type: 'tabsSwitchingEntity',
        payload: {
          Subsections: widgetLabel ?? '',
          tabMoreEntity: tabName,
        },
      });
    }
  };

  const commonDrawerProps: ICommonDrawerProps = {
    isOpen,
    title: entitySectionTitle,
    accentColor,
    image: backgroundImage ?? null,
    footer: bottomContent,
    cardData,
    onClose,
    isTabsShown: isFilesTabShown || isLinkedObjectsTabShown,
    tabs,
    currentTab: activeTab,
    onChangeTab: handleChangeActiveTab,
  };

  const contentElement = cardData && (
    <PortfolioEntityCard currentTab={activeTab}>
      <PortfolioEntityCard.GeneralInfoTab cardData={cardData} onOpenEventSecondaryCard={handleOpenEventSecondaryCard} />
      {isFilesTabShown && <PortfolioEntityCard.FilesTab cardData={cardData} onFileDownload={handleDownloadFile} />}
      {isLinkedObjectsTabShown && (
        <PortfolioEntityCard.LinkedObjectsTab
          linkedObjects={sortedPersonLinkedObjects}
          onOpenLinkedObjectCard={handleOpenLinkedObjectSecondaryCard}
        />
      )}
    </PortfolioEntityCard>
  );

  return (
    <ThemeProvider theme={generateAccentTheme(accentColor)}>
      {!isMobile && <DetailViewDrawerDesktop {...commonDrawerProps}>{contentElement}</DetailViewDrawerDesktop>}
      {isMobile && <DetailViewDrawerMobile {...commonDrawerProps}>{contentElement}</DetailViewDrawerMobile>}
      {deleteDialogElement}
    </ThemeProvider>
  );
};

export default DetailViewDrawer;
