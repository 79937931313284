import { FC } from 'react';

import { Box } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';
import { mergeSx } from 'utils';

import { IWidgetContainerDividerProps } from '../types';

const styles: SxStyles = (theme) => ({
  height: '1px',
  marginBlock: '20px',

  backgroundColor: NeutralColors.light_neutrals_300,

  [theme.breakpoints.down('commonSm')]: {
    marginBlock: '16px',
  },
});

const WidgetContainerDivider: FC<IWidgetContainerDividerProps> = ({ sx }) => {
  return <Box className="widget-container-divider" sx={mergeSx(styles, sx)} />;
};

export default WidgetContainerDivider;
