/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useEffect, useMemo, useState } from 'react';

import { connect } from 'react-redux';

import {
  IDiagnosisBarData, IDiagnosisResponse, IDictionaryItem
} from '../../../../api/types';
import { ALL_SUBJECTS_CODE, ALL_SUBJECTS_VALUE } from '../../../../containers/redesignStudent/Diagnostic/const';

import DiagnosticPlug from '../../../../containers/redesignStudent/Diagnostic/DiagnosticPlug';
import { IRootState } from '../../../../reducers';
import ProgressInLearning from '../../progressInLearning';
import { SelfDiagnocticDoughnutStats } from '../diagnocticDoughnutStats';

interface ISelfDiagnosticProps {
  studentDiagnosis: IDiagnosisResponse | null;
  filterSubject: number;
  setPossibleSubjectFilterValues: React.Dispatch<React.SetStateAction<IDictionaryItem[]>>
}

const SelfDiagnosticComponent: React.FC<ISelfDiagnosticProps> = ({
  studentDiagnosis,
  filterSubject,
  setPossibleSubjectFilterValues
}) => {
  const [subjectOptions, setSubjectOptions] = useState<IDiagnosisBarData[]>([]);
  const [bestSubject, setBestSubject] = useState<IDiagnosisBarData>();

  useEffect(() => {
    const newSubjectValues = subjectOptions?.map((subject, index) => ({
      value: subject.name,
      code: index + 1
    }));

    if (newSubjectValues) {
      setPossibleSubjectFilterValues([{
        value: ALL_SUBJECTS_VALUE,
        code: ALL_SUBJECTS_CODE
      }].concat(newSubjectValues));
    }
  }, [subjectOptions]);

  useEffect(() => {
    if (filterSubject === ALL_SUBJECTS_CODE) {
      setSubjectOptions(studentDiagnosis?.content?.selfDiagnosticSubjects ?? []);
      setBestSubject(studentDiagnosis?.content?.selfDiagnosticSubjects.find((subject) => subject.total === bestSubjectResult));
    }
  }, [studentDiagnosis?.content?.selfDiagnosticSubjects]);

  const bestSubjectResult = Math.max.apply(Math, studentDiagnosis?.content?.selfDiagnosticSubjects.map((subject) => subject.total) ?? []);

  const sortedDiagnosticSubjects = useMemo(() => {
    return studentDiagnosis?.content?.selfDiagnosticSubjects.sort((firstItem, secondItem) => secondItem.total - firstItem.total) ?? [];
  }, [studentDiagnosis?.content?.selfDiagnosticSubjects]);

  if (!studentDiagnosis || !studentDiagnosis.content || studentDiagnosis.content?.selfDiagnosticSubjects?.length === 0) {
    return <DiagnosticPlug loading={studentDiagnosis?.loading} />;
  }

  return (
    <>
      <SelfDiagnocticDoughnutStats studentDiagnosis={studentDiagnosis.content} bestSubject={studentDiagnosis.content?.bestResult} />
      <div className="divider-diagnostics" />
      <div className="diagnostics-linner pdf-keep">
        <h3 className="">По предметам</h3>
        <div className="self-diagnosis__horizontal-bar">
          <ProgressInLearning barData={sortedDiagnosticSubjects} bestSubjectValue={bestSubject?.total} />
        </div>
      </div>
    </>
  )
};

export default connect(
  (state: IRootState) => ({
    studentDiagnosis: state.studentDiagnosis
  })
)(SelfDiagnosticComponent);
