import { FC, ReactNode } from 'react';

import { Box, useMediaQuery } from '@mui/material';
import { IChildInfo } from 'api/types';
import { commonTheme } from 'portfolio3/styles/theme';

import ServiceButtons from './ServiceButtons';
import StudentCard from './StudentCard';
import * as styles from './styles';

interface IStudentServiceHeaderProps {
  currentStudent?: IChildInfo;
  studentPicker: ReactNode;
  searchButtonElement: ReactNode;
}

const StudentServiceHeader: FC<IStudentServiceHeaderProps> = ({
  currentStudent,
  studentPicker,
  searchButtonElement,
}) => {
  const isMobileHeader = useMediaQuery(commonTheme.breakpoints.down('commonLg'));

  const isLeftPicker = isMobileHeader && Boolean(studentPicker);

  return (
    <Box sx={styles.root} className="service-header">
      <Box sx={styles.leftSide} className="service-header__left">
        {isLeftPicker ? studentPicker : <StudentCard student={currentStudent} />}
      </Box>
      <Box sx={styles.rightSide} className="service-header__right">
        <ServiceButtons searchButtonElement={searchButtonElement} />
        {!isLeftPicker && studentPicker}
      </Box>
    </Box>
  );
};

export default StudentServiceHeader;
