import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root: SxStyles = {
  display: 'grid',
  gridAutoFlow: 'row',
  gridTemplateRows: '1fr auto',

  width: '216px',
  height: '270px',
  padding: '20px 16px',

  border: '1px solid',
  borderColor: NeutralColors.night_neutrals_700,
};

export const topBlock: SxStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
};

export const chart: SxStyles = {
  width: '80px',
  height: '80px',
};

export const info: SxStyles = {
  display: 'flex',
  flexDirection: 'column',
  gap: '2px',

  marginTop: '20px',
  textAlign: 'center',
};
