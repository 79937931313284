import { listClasses, paperClasses } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root: SxStyles = {
  display: 'flex',
  gap: '64px',
  width: '100%',
};

export const mainNavigationsContainer: SxStyles = {
  display: 'flex',
  gap: '4px',
  minWidth: 0,
  maxWidth: '100%',
  width: '100%',

  '.active-button-more': { backgroundColor: NeutralColors.light_neutrals_300 },
};

export const sideNavigationsContainer: SxStyles = {
  display: 'flex',
};

export const menu: SxStyles = {
  [`& .${paperClasses.root}`]: {
    padding: '4px',
    marginTop: '8px',
    boxShadow: '0px 4px 6px -2px rgba(41, 41, 64, 0.04), 0px 10px 15px -3px rgba(41, 41, 64, 0.08)',
    borderRadius: '12px',
    border: `1px solid ${NeutralColors.light_neutrals_300}`,
  },
  [`& .${listClasses.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    padding: 0,
  },
};
