import { svgIconClasses } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const popoverStyles =
  (color: string): SxStyles =>
  () => {
    //размеры стрелки
    const arrowWidth = 20;
    const arrowHeight = 17;
    const arrowBasicborder = `4px solid transparent`; // граница before элемента
    const arrowWhiteBasicBorder = `5px solid transparent`; // граница after элемента
    const arrowWhiteBorder = `5px solid ${color}`; // цвет before элемента
    const arrowGreyBorder = `5px solid ${NeutralColors.light_neutrals_400}`; // цвет after элемента
    const margin = '-0.9em';

    return {
      maxWidth: '310px',

      boxShadow: '0px 3px 6px -1px rgba(41, 41, 64, 0.08)',
      filter: 'drop-shadow(1px 0px 5px rgba(41, 41, 64, 0.04))',

      borderRadius: '8px',
      zIndex: 2000,

      '&[data-popper-placement*="bottom"] .popover__arrow': {
        top: 0,
        left: 0,

        marginTop: margin,

        width: `${arrowWidth}px`,
        height: `${arrowHeight}px`,

        '&::before': {
          top: '7px',
          left: 0,
          border: arrowBasicborder,
          borderBottom: arrowWhiteBorder,
          zIndex: 2,
        },
        '&::after': {
          top: '5px',
          left: '-1px',
          border: arrowWhiteBasicBorder,
          borderBottom: arrowGreyBorder,
          zIndex: 1,
        },
      },
      '&[data-popper-placement*="top"] .popover__arrow': {
        bottom: 0,
        left: 0,

        marginBottom: margin,

        width: `${arrowWidth}px`,
        height: `${arrowHeight}px`,

        '&::before': {
          top: '1px',
          left: 0,
          border: arrowBasicborder,
          borderTop: arrowWhiteBorder,
          zIndex: 2,
        },
        '&::after': {
          top: '2px',
          left: '-1px',
          border: arrowWhiteBasicBorder,
          borderTop: arrowGreyBorder,
          zIndex: 1,
        },
      },
      '&[data-popper-placement*="right"] .popover__arrow': {
        left: 0,

        marginLeft: margin,

        height: `${arrowWidth}px`,
        width: `${arrowHeight}px`,

        '&::before': {
          top: `3px`,
          left: `7px`,
          border: arrowBasicborder,
          borderRight: arrowWhiteBorder,
          zIndex: 2,
        },
        '&::after': {
          top: `2px`,
          left: `5px`,
          border: arrowWhiteBasicBorder,
          borderRight: arrowGreyBorder,
          zIndex: 1,
        },
      },
      '&[data-popper-placement*="left"] .popover__arrow': {
        right: 0,

        marginRight: margin,

        height: `${arrowWidth}px`,
        width: `${arrowHeight}px`,

        '&::before': {
          top: '3px',
          left: '1px',
          border: arrowBasicborder,
          borderLeft: arrowWhiteBorder,
          zIndex: 2,
        },
        '&::after': {
          top: '2px',
          left: '2px',
          border: arrowWhiteBasicBorder,
          borderLeft: arrowGreyBorder,
          zIndex: 1,
        },
      },
    };
  };

export const arrowSx: SxStyles = {
  position: 'absolute',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    display: 'block',
    margin: 'auto',
    position: 'absolute',
  },
  '&::after': {
    content: '""',
    display: 'block',
    position: 'absolute',
  },
};

export const popoverBox: SxStyles = () => ({
  border: `1px solid ${NeutralColors.light_neutrals_400}`,
  borderRadius: '8px',
  overflow: 'hidden',
});

export const popoverContent =
  (color: string): SxStyles =>
  () => ({
    backgroundColor: color,
  });

export const popoverHeader =
  (withHeaderDivider?: boolean): SxStyles =>
  () => ({
    display: 'flex',
    justifyContent: 'space-between',
    gap: '8px',

    paddingBlock: withHeaderDivider ? '12px' : '16px 0',
    paddingInline: '16px',

    borderBottom: withHeaderDivider ? `1px solid ${NeutralColors.light_neutrals_300}` : '',

    [`.${svgIconClasses.root}`]: {
      width: '20px',
      height: '20px',

      color: NeutralColors.light_neutrals_600,
      cursor: 'pointer',
    },
  });

export const popoverBody: SxStyles = () => ({
  paddingBlock: '12px',
  paddingInline: '16px',
});

export const popoverFooter =
  (withFooterDivider?: boolean): SxStyles =>
  () => ({
    paddingBlock: withFooterDivider ? '12px' : '0 16px',
    paddingInline: '16px',

    borderTop: withFooterDivider ? `1px solid ${NeutralColors.light_neutrals_300}` : '',
    backgroundColor: withFooterDivider ? NeutralColors.light_neutrals_100 : '',
  });
