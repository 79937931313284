/* eslint-disable max-len */
import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconScienceContestsCommon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16 6.1c1.4 0 2.5 1.1 2.5 2.5s-1.1 2.5-2.5 2.5-2.5-1.1-2.5-2.5 1.1-2.5 2.5-2.5ZM18.498 14.9l-.9 1.4v1.6l-1.6-.5-1.6.5-.1-1.6-.9-1.4 1.6-.5 1-1.4 1 1.4 1.5.5ZM10.398 8.5c0 1.4-1.1 2.5-2.5 2.5s-2.5-1.1-2.5-2.5 1.1-2.5 2.5-2.5c1.4.1 2.5 1.2 2.5 2.5ZM10.398 15.5c0 1.4-1.1 2.5-2.5 2.5s-2.5-1.1-2.5-2.5 1.1-2.5 2.5-2.5 2.5 1.1 2.5 2.5Z"
      fill={props.fill || 'currentColor'}
    />
    <path d="M24 24H0V0h21.6v2.8H2.8v18.4h18.4V2.8h-.9V0H24v24Z" fill={props.fill || 'currentColor'} />
  </SvgIcon>
);

export default IconScienceContestsCommon;
