/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { IInterestSection } from '../components/redesignStudent/StudentsInterests/types';

interface IInterestsProgressBarContext {
  sections: IInterestSection[];
  currentSection: IInterestSection;
  visibleSections: IInterestSection[];
  lastVisitedSectionIndex: number;
  onChangeCurrentSection: (section: IInterestSection) => void;
}

export default React.createContext({} as IInterestsProgressBarContext);
