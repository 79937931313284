/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useRef, useState } from 'react';

import GroupedActionButton from '../../GroupedActionButton';
import { Colors } from '../../../../../style/variables';
import { IconMoreHorizontal } from '../../../../../icons';
import { EntityActionsItem, EntityActionsPopper } from '../../../EntityActions';
import { IGroupedActionButtonProps } from '../../types';
import { commonTheme } from 'portfolio3/styles/theme';
import { useMediaQuery } from '@mui/material';

interface IButtonMore {
  hiddenButtons?: React.ReactElement<IGroupedActionButtonProps>[];
}

const ButtonMore: React.FC<IButtonMore> = ({
  hiddenButtons
}) => {
  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));
  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuButtonRef = useRef<HTMLButtonElement>(null);

  const handleClickMore = () => {
    setMenuOpen(true);
  };

  const handleClose = () => {
    setMenuOpen(false);
  };

  const handleActionClick = (action: () => void) => {
    return () => {
      setMenuOpen(false);
      action();
    };
  };

  const buttonLabel = isMobile ? 'Действия' : 'Другие действия';

  return (
    <>
      <GroupedActionButton
        startIcon={IconMoreHorizontal}
        variant='secondary'
        handleClick={handleClickMore}
        ref={menuButtonRef}
      >
        {buttonLabel}
      </GroupedActionButton>
      <EntityActionsPopper
        onClickAway={handleClose}
        open={isMenuOpen}
        anchorEl={menuButtonRef.current}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        transformOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        disablePortal
      >
        {hiddenButtons?.map((button, index) => (
          <EntityActionsItem
            isDisabled={button.props.disabled || false}
            text={button.props.children}
            icon={button.props.startIcon}
            onClick={handleActionClick(button.props.handleClick)}
            MenuItemProps={{ autoFocus: index === 0 }}
          />
        ))}
      </EntityActionsPopper>
    </>
  );
};

export default ButtonMore;
