/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useState, useEffect, useMemo } from 'react';

import { Box, IconButton } from '@mui/material';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { format } from 'date-fns';

import { PropagateLoader } from 'react-spinners';
import {
  iconClose,
  iconLongArrow,
  IconSortAsc
} from '../../../icons';
import {
  DifferentCode,
  EntityType,
  SectionCodes
} from '../../../const';
import {
  getRewardActivityIcon,
  getSportRewardActivityIcon,
  getBasicBlockAttributes,
  getEntityGeneralInfo,
  getEntityHeaderText
} from '../../../utils';

import { IRootState } from '../../../reducers';

import {
  deletePortfolioAffilationActions,
  deletePortfolioEmploymentActions,
  deletePortfolioEventActions,
  deletePortfolioGiaWorldSkillsActions,
  deletePortfolioProjectActions,
  deletePortfolioRewardActions,
  deletePortfolioSportRewardActions,
  getEventKindsActions,
  getPersonsObjectsActions,
  getSportClubsAction,
  getSportRewardKindsAction,
  getTourismKindsAction,
  setCurrentStudentAction
} from '../../../actions';

import { MAX_PAGE_SIZE } from '../../../api';
import { IChildInfo, ICommonResponse, IDictionaryItem, IPersonObject } from '../../../api/types';
import { IDeleteDialogData, IModifiedPersonObjectsList } from './types';

import { IModifiedPersonObject } from 'portfolio3/features/dataEntryForm';
import { Colors } from '../../../style/variables';

import { Button } from 'portfolio3/ui-kit/button';
import { IconPlus } from 'icons/edit';
import { IconSelect, Select } from 'portfolio3/ui-kit/selects';
import { IController } from 'portfolio3/ui-kit/types';
import { availableEmployeeParentSectionsSelector } from 'selectors';

import PortfolioDataWindowedList from './PortfolioDataWindowedList';
import { useDataEntryDrawer, useLoadSectionRef } from 'hooks';
import InfoModal from 'portfolio3/ui-kit/modals/InfoModal';

import './index.scss';

interface IPortfolioDataProps {
  personsObjects: IModifiedPersonObjectsList,
  portfolioSections: ICommonResponse<IDictionaryItem>,
  currentStudent: IChildInfo,
  open: boolean,
  availableStudents: IChildInfo[];
  getEventKinds: typeof getEventKindsActions.request,
  getSportClubs: typeof getSportClubsAction.request,
  getSportRewardKinds: typeof getSportRewardKindsAction.request,
  getPersonsObjects: typeof getPersonsObjectsActions.request,
  getTourismKinds: typeof getTourismKindsAction.request,
  deletePortfolioReward: typeof deletePortfolioRewardActions.request,
  deletePortfolioSportReward: typeof deletePortfolioSportRewardActions.request,
  deletePortfolioEvent: typeof deletePortfolioEventActions.request,
  deletePortfolioEmployment: typeof deletePortfolioEmploymentActions.request,
  deletePortfolioAffilation: typeof deletePortfolioAffilationActions.request,
  deletePortfolioProject: typeof deletePortfolioProjectActions.request,
  deletePortfolioGiaWorldSkills: typeof deletePortfolioGiaWorldSkillsActions.request,
  onSelectCurrentStudent: typeof setCurrentStudentAction
  onClose: () => void
}

const PortfolioData: React.FC<IPortfolioDataProps> = ({
  personsObjects,
  portfolioSections,
  currentStudent,
  open,
  availableStudents,
  getEventKinds,
  getSportClubs,
  getPersonsObjects,
  deletePortfolioReward,
  deletePortfolioSportReward,
  deletePortfolioEvent,
  deletePortfolioEmployment,
  getSportRewardKinds,
  getTourismKinds,
  deletePortfolioAffilation,
  deletePortfolioProject,
  deletePortfolioGiaWorldSkills,
  onSelectCurrentStudent,
  onClose
}) => {
  const [currentCategory, setCurrentCategory] = useState<number>();
  const [currentSorting, setCurrentSorting] = useState(1);
  const [isListUpdating, setListUpdating] = useState(false);
  const [deleteDialogData, setDeleteDialogData] = useState<IDeleteDialogData>();
  const { handleOpenPrimaryDataEntry, handleSetDataEntryPersonsObjectsQueryParams } = useDataEntryDrawer();

  useLoadSectionRef();

  const sectionCodesString = portfolioSections.content?.map((section) => `&categoryCode=${section.code}`).join('');

  const currentStudentIndex = availableStudents.findIndex((student) => student.meshId === currentStudent.meshId);

  const modifiedPortfolioSections = {
    loading: portfolioSections.loading,
    content: portfolioSections.content?.length > 1 ? [{
      value: 'Все категории',
      code: DifferentCode
    }, ...portfolioSections.content] : portfolioSections.content
  };

  const filteredPersonsObjects = useMemo(() => personsObjects.content.filter((personObject) => personObject.categoryCode === currentCategory || currentCategory === DifferentCode), [personsObjects, currentCategory]);

  useEffect(() => {
    setListUpdating(true);
  }, [currentSorting]);

  useEffect(() => {
    if (!personsObjects.loading) setListUpdating(false);
    else if (personsObjects.content.length === 0) setListUpdating(true);
  }, [personsObjects.loading]);

  useEffect(() => {
    getEventKinds({ size: MAX_PAGE_SIZE, sort: 'value', isArchive: 'false' });
    getSportRewardKinds({ size: MAX_PAGE_SIZE, isArchive: 'false' });
    getTourismKinds({ size: MAX_PAGE_SIZE });
  }, []);

  useEffect(() => {
    if (portfolioSections.content.find((section) => section.code === SectionCodes.sport)) {
      getSportClubs({ size: MAX_PAGE_SIZE });
    }
  }, [portfolioSections]);

  useEffect(() => {
    if (modifiedPortfolioSections.content.length > 0 && !currentCategory) setCurrentCategory(modifiedPortfolioSections.content[0].code);
  }, [modifiedPortfolioSections.content]);

  const getSortingValue = (sortCode: number) => {
    switch (sortCode) {
      case 1:
        return 'desc';
      case 2:
        return 'asc';
    }
  };

  const queryParams = {
    sort: getSortingValue(currentSorting), size: MAX_PAGE_SIZE
  };

  useEffect(() => {
    if (open) {
      handleSetDataEntryPersonsObjectsQueryParams(queryParams);
      setListUpdating(true);
      getPersonsObjects(currentStudent.meshId, queryParams, sectionCodesString);
    }
  }, [currentSorting, currentStudent, open]);

  const categoryController: IController<string | undefined> = {
    handleChange: (category) => {
      setCurrentCategory(Number(category));
    }
  };
  const sortingController: IController<string | undefined> = {
    handleChange: (sort) => {
      setCurrentSorting(Number(sort));
    }
  };

  const handleDeletePortfolioObject = () => {
    if (!deleteDialogData) return;
    const { type, id } = deleteDialogData;
    const { meshId } = currentStudent;
    switch (type) {
      case EntityType.EVENT:
        deletePortfolioEvent(id, meshId, queryParams, undefined, sectionCodesString);
        break;
      case EntityType.REWARD:
        deletePortfolioReward(id, meshId, queryParams, undefined, sectionCodesString);
        break;
      case EntityType.SPORT_REWARD:
        deletePortfolioSportReward(id, meshId, queryParams, undefined, sectionCodesString);
        break;
      case EntityType.EMPLOYMENT:
        deletePortfolioEmployment(id, meshId, queryParams, undefined, sectionCodesString);
        break;
      case EntityType.AFFILATION:
        deletePortfolioAffilation(id, meshId, queryParams, undefined, sectionCodesString);
        break;
      case EntityType.PROJECT:
        deletePortfolioProject(id, meshId, queryParams, undefined, sectionCodesString);
        break;
      case EntityType.GIA_WORLDSKILLS:
        deletePortfolioGiaWorldSkills(id, meshId, queryParams, undefined, sectionCodesString);
        break;
      default:
        break;
    }
    handleCloseDeleteDialog();
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogData((prevstate) => {
      if (!prevstate) return;
      return {
        ...prevstate,
        open: false
      };
    });
  };

  const renderDialogText = () => {
    const additionText = deleteDialogData?.linkedEntityNames ? (
      <>
        Нам также придется удалить
        {' '}
        {deleteDialogData?.linkedEntityTypeName}
        {' '}
        <b>{deleteDialogData?.linkedEntityNames}</b>
        .
      </>
    ) : null;
    return (
      <>
        {`Вы уверены, что хотите удалить ${deleteDialogData?.typeName} `}
        <b>{deleteDialogData?.name}</b>
        {' '}
        из портфолио учащегося
        {' '}
        <b>{currentStudent.firstname}</b>
        {' '}
        <b>{currentStudent.lastname}</b>
        ?
        {' '}
        {additionText}
      </>
    );
  };

  return (
    <div className="portfolio-data">
      <div className="portfolio-data__header">
        <div className="portfolio-data__header-info">
          <h4 className="portfolio-data__title">Добавленные данные в портфолио</h4>
          <p className="portfolio-data__name">{`${currentStudent.lastname} ${currentStudent.firstname}`}</p>
        </div>
        <div className="portfolio-data__controls">
          {availableStudents.length > 0 && (
          <>
            <button
              className={`portfolio-data__header-button portfolio-data__header-button--left ${availableStudents[currentStudentIndex - 1] === undefined ? 'portfolio-data__header-button--disabled' : ''}`}
              type="button"
              disabled={availableStudents[currentStudentIndex - 1] === undefined}
              onClick={() => onSelectCurrentStudent(availableStudents[currentStudentIndex - 1])}
            >
              <img src={iconLongArrow} alt="Влево" />
            </button>
            <button
              className={`portfolio-data__header-button portfolio-data__header-button--right ${availableStudents[currentStudentIndex + 1] === undefined ? 'portfolio-data__header-button--disabled' : ''}`}
              type="button"
              disabled={availableStudents[currentStudentIndex + 1] === undefined}
              onClick={() => onSelectCurrentStudent(availableStudents[currentStudentIndex + 1])}
            >
              <img src={iconLongArrow} alt="Вправо" />
            </button>
          </>
          )}
          <IconButton className="btn-close" disableTouchRipple onClick={onClose} size="large">
            <img src={iconClose} alt="закрыть" />
          </IconButton>
        </div>
      </div>
      <div className="portfolio-data__content">
        <div className="portfolio-data__content-controls">
          <Select
            inputRenderMode="fixed"
            inputSize="medium"
            value={currentCategory}
            options={modifiedPortfolioSections.content}
            controller={categoryController}
          />
          <IconSelect
            inputRenderMode="fixed"
            inputSize="medium"
            value={currentSorting}
            options={[
              {
                value: 'По дате добавления',
                code: 1,
                id: 1,
                startIcon: <IconSortAsc />,
                selectedIconColor: '#ffffff',
              },
              {
                value: 'По дате добавления',
                code: 2,
                id: 2,
                startIcon: <IconSortAsc style={{ transform: 'scale(1, -1)' }} />,
                selectedIconColor: '#ffffff',
              }
            ]}
            controller={sortingController}
          />
          <Button
            className="btn-add"
            iconLeft={<IconPlus />}
            onClick={() => handleOpenPrimaryDataEntry({})}
          >
            Добавить
          </Button>
        </div>
        {filteredPersonsObjects && currentCategory && (
        <Box className="portfolio-data__items-container">
          {!isListUpdating ? (
            <PortfolioDataWindowedList
              objects={filteredPersonsObjects}
              loading={personsObjects.loading || false}
              currentCategory={currentCategory}
              setDeleteDialogData={setDeleteDialogData}
            />
          ) : (
            <div className="loader-container">
              <PropagateLoader css="margin-top: 60px" color={Colors['blue-primrose']} />
            </div>
          )}
        </Box>
        )}
      </div>
      <InfoModal
        open={deleteDialogData?.open || false}
        onClose={handleCloseDeleteDialog}
        header={`Удаление ${deleteDialogData?.titleTypeName}`}
        text={renderDialogText()}
        variant="warning"
        cancelButtonText="Отмена"
        onCancel={handleCloseDeleteDialog}
        actionButtonText="Да, удалить"
        onAction={handleDeletePortfolioObject}
      />
    </div>
  );
};

const personsObjectsMapping = createSelector(
  [
    (state: IRootState) => state.personsObjects,
    (state: IRootState) => state.sectionRef
  ],
  (personsObjects, portfolioSections): IModifiedPersonObjectsList => ({
    content: personsObjects.content?.map((personObject): IModifiedPersonObject => {
      const dataItemAttributes = getBasicBlockAttributes(personObject.categoryCode);
      const dataItemType = portfolioSections.content?.find((section) => section.code === personObject.dataKind)?.value;

      const { entityId } = personObject;
      const boundEvent = entityId ? personsObjects.content?.find((object) => object.id == entityId) : undefined;
      const boundReward: IPersonObject | undefined = personsObjects.content?.find((object) => object.entityId == personObject.id && object.id !== personObject.id);

      const linkedObjectIdsArray = personObject.entityIds;
      const linkedPersonObjects = personsObjects.content?.filter((object) => linkedObjectIdsArray?.find((id) => id === object.id)).map((object) => ({
        ...object,
        dataItemType: portfolioSections.content?.find((section) => section.code === object.dataKind)?.value
      }));
      return {
        ...personObject,
        dataItemAttributes,
        dataItemType,
        linkedPersonObjects,
        cardData: {
          name: personObject.name,
          files: personObject.fileReferences || [],
          linkedObjects: personObject.linkedObjects,
          generalInfo: getEntityGeneralInfo(personObject),
          formData: personObject,
          illustrationFallback: null,
          event: boundEvent ? {
            cardData: {
              name: boundEvent?.name,
              files: boundEvent?.fileReferences || [],
              linkedObjects: boundEvent?.linkedObjects,
              generalInfo: getEntityGeneralInfo(boundEvent),
              formData: boundEvent,
              illustrationFallback: null,
            },
            type: getEntityHeaderText(boundEvent.typeCode || boundEvent.type?.code)
          } : undefined,
          reward: boundReward ? {
            result: boundReward.name,
            rewardDate: boundReward.date ? format(new Date(boundReward.date), 'dd.MM.yyyy') : undefined,
            image: boundReward.sportRewardCode ? getSportRewardActivityIcon(boundReward.sportRewardCode) : getRewardActivityIcon(boundReward.rewardType?.value),
            formData: boundReward,
            cardData: {
              name: boundReward.type?.value,
              files: boundReward.fileReferences,
              linkedObjects: boundReward.linkedObjects,
              generalInfo: getEntityGeneralInfo(boundReward),
              formData: boundReward,
              illustrationFallback: null,
              event: personObject && {
                cardData: {
                  name: personObject?.name,
                  files: personObject?.fileReferences,
                  linkedObjects: personObject?.linkedObjects,
                  generalInfo: getEntityGeneralInfo(personObject),
                  formData: personObject,
                  illustrationFallback: null,
                },
                type: getEntityHeaderText(personObject.typeCode || personObject.type?.code)
              }
            }
          } : undefined
        }
      };
    }) || [],
    loading: personsObjects.loading
  })
);

export default connect(
  (state: IRootState) => ({
    personsObjects: personsObjectsMapping(state),
    currentStudent: state.currentStudent,
    portfolioSections: availableEmployeeParentSectionsSelector(state),
  }),
  {
    getEventKinds: getEventKindsActions.request,
    getSportClubs: getSportClubsAction.request,
    getSportRewardKinds: getSportRewardKindsAction.request,
    getPersonsObjects: getPersonsObjectsActions.request,
    getTourismKinds: getTourismKindsAction.request,
    deletePortfolioReward: deletePortfolioRewardActions.request,
    deletePortfolioSportReward: deletePortfolioSportRewardActions.request,
    deletePortfolioEvent: deletePortfolioEventActions.request,
    deletePortfolioEmployment: deletePortfolioEmploymentActions.request,
    deletePortfolioAffilation: deletePortfolioAffilationActions.request,
    deletePortfolioProject: deletePortfolioProjectActions.request,
    deletePortfolioGiaWorldSkills: deletePortfolioGiaWorldSkillsActions.request,
    onSelectCurrentStudent: setCurrentStudentAction
  }
)(PortfolioData);
