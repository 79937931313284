import { FC } from 'react';

import { ListItem, ListItemIcon, Typography } from '@mui/material';
import { IPortfolioNavigation } from 'const';
import { IconArrowDiagonalRightUp, widgetIcons } from 'icons';

import * as styles from './styles';

interface IButtonNavigationProps {
  navigation: IPortfolioNavigation;
  currentPortfolioSection: string | undefined;
  onClickListItem: () => void;
}

const ButtonNavigation: FC<IButtonNavigationProps> = ({ navigation, currentPortfolioSection, onClickListItem }) => {
  const { id, label, url } = navigation;

  const IconComponent = widgetIcons[navigation.icon];

  return (
    <ListItem key={id} onClick={onClickListItem} sx={styles.root(id === currentPortfolioSection)}>
      <ListItemIcon sx={styles.sectionIconContainer}>
        <IconComponent />
      </ListItemIcon>
      <Typography variant="Paragraph LG/Regular" className="label">
        {label}
      </Typography>

      {url && <IconArrowDiagonalRightUp sx={styles.rightIcon} />}
    </ListItem>
  );
};

export default ButtonNavigation;
