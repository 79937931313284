/* eslint-disable prettier/prettier */
/* eslint-disable */

import { BaseInputLabel, FormControl } from 'portfolio3/ui-kit';
import { SearchSelect, Select } from 'portfolio3/ui-kit/selects';
import { IController } from 'portfolio3/ui-kit/types';
import React, { useContext, useEffect, useMemo } from 'react';

import { IDictionaryItem } from '../../../../api/types';
import { LocalDataEntryFormContext } from '../context/localDataEntryFormContext';

interface IAffiliationBlockProps {
  typeOptions: IDictionaryItem[];
  nameOptions: IDictionaryItem[];
  nameInputLabel: string;
  nameInputPlaceholder: string;
}

const AffiliationBlock: React.FC<IAffiliationBlockProps> = ({
  typeOptions,
  nameOptions,
  nameInputLabel,
  nameInputPlaceholder,
}) => {
  const { inputRenderMode, inputSize, formData, onChangeFormData } = useContext(LocalDataEntryFormContext);

  useEffect(() => {
    if (typeOptions.length === 1) {
      onChangeFormData({
        ...formData,
        typeCode: typeOptions[0].code
      });
    }
  }, [typeOptions.length]);

  useEffect(() => {
    if (nameOptions.length === 1 && formData.typeCode) {
      onChangeFormData({
        ...formData,
        name: nameOptions[0].code
      });
    }
  }, [nameOptions.length, formData.typeCode]);

  const typeController: IController<string | undefined> = useMemo(() => ({
    handleChange: (type) => {
      onChangeFormData((prevstate) => ({
        ...prevstate,
        typeCode: Number(type),
        name: undefined
      }));
    }
  }), [formData.id]);

  const nameController: IController<string | undefined> = useMemo(() => ({
    handleChange: (name) => {
      onChangeFormData((prevstate) => ({
        ...prevstate,
        name: Number(name)
      }));
    }
  }), [formData.id]);

  const isTypeSelectDisabled = typeOptions.length < 2;
  const isNameSelectDisabled = nameOptions.length < 2 && Boolean(formData.name);

  return (
    <>
      <FormControl
        renderMode={inputRenderMode}
        inputSize={inputSize}
        disabled={isTypeSelectDisabled}
        label={<BaseInputLabel>Тип принадлежности</BaseInputLabel>}
        control={
          <Select
            inputRenderMode={inputRenderMode}
            inputSize={inputSize}
            placeholder="Выберите тип принадлежности..."
            value={formData.typeCode}
            options={typeOptions}
            controller={typeController}
          />
        }
      />
      {formData.typeCode && (
        <FormControl
          disabled={isNameSelectDisabled}
          renderMode={inputRenderMode}
          inputSize={inputSize}
          label={<BaseInputLabel>{nameInputLabel}</BaseInputLabel>}
          control={
            <SearchSelect
              inputRenderMode={inputRenderMode}
              inputSize={inputSize}
              placeholder={nameInputPlaceholder}
              value={formData.name}
              options={nameOptions}
              controller={nameController}
              strictMaxWidth
              markOtherOption
            />
          }
        />
      )}
    </>
  );
};

export default AffiliationBlock;
