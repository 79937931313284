/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { FormControl, FormLabel } from '@mui/material';

import { Checkbox, FormControlLabel } from '../../../../ui-kit';
import { IInterestEditFormToggleableItem } from '../../../../types';

interface ICheckboxListFormControlProps {
  options?: IInterestEditFormToggleableItem[];
  title: string;
  onChange: (code: number) => void;
}

const CheckboxListFormControl: React.FC<ICheckboxListFormControlProps> = ({
  options,
  title,
  onChange
}) => {
  if (!options || options.length <= 1) return null;

  const handleChange = (code: number) => {
    return () => onChange(code);
  };

  return (
    <FormControl
      component="fieldset"
      className="checkbox-list-control"
    >
      <FormLabel component="p" className="checkbox-list-control__title">
        {title}
      </FormLabel>
      {options.map((option) => {
        return (
          <FormControlLabel
            key={option.name}
            control={
              <Checkbox checked={option.isActive} onChange={handleChange(option.code)} />
            }
            label={<p>{option.name}</p>}
          />
        );
      })}
    </FormControl>
  );
};

export default CheckboxListFormControl;
