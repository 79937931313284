import { FC, ReactNode } from 'react';

import { Box } from '@mui/material';

interface IWidgetContainerOptionsProps {
  left?: ReactNode;
  right?: ReactNode;
}

const WidgetContainerOptions: FC<IWidgetContainerOptionsProps> = ({ left, right }) => {
  const elementsCount = [left, right].filter(Boolean).length;
  const gridTemplateColumns = elementsCount > 1 ? 'minmax(0, 1fr) auto' : 'minmax(0, 1fr)';

  return (
    /* Грид для корректной работы компонента switcher со скрытием (useHiddenList) */
    <Box sx={{ display: 'grid', gridAutoFlow: 'row', gridTemplateColumns, gap: '10px' }}>
      {left}
      {right}
    </Box>
  );
};

export default WidgetContainerOptions;
