import { advertBackground } from 'images';
import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root: SxStyles = {
  backgroundImage: `url(${advertBackground})`,
  backgroundColor: NeutralColors.light_neutrals_200,
  borderRadius: '12px',
  overflow: 'hidden',
};

export const content: SxStyles = (theme) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',

  padding: '20px',

  [theme.breakpoints.down('commonMd')]: {
    backgroundImage: 'none',
  },

  [theme.breakpoints.down('commonSm')]: {
    padding: '12px',
  },
});

export const prompt: SxStyles = {
  marginTop: '4px',
  maxWidth: '556px',
};

export const link: SxStyles = {
  marginTop: '16px',
  textDecoration: 'none',
};
