import { FC, useContext, useMemo } from 'react';
import { connect } from 'react-redux';

import { IDictionaryItem } from 'api/types';
import { SectionCodes } from 'const';
import { useAppSelector } from 'hooks';
import { IRootState } from 'reducers';
import { EventKindState } from 'reducers/employee/eventKinds';
import { RewardKindState } from 'reducers/employee/rewardKinds';
import { createSelector } from 'reselect';
import { sectionRefArchiveFalseSelector } from 'selectors';

import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';
import { ActivityBlock, GiaBlock, RewardBlock } from '../../primaryBlocks';
import { filterPersonsObjects } from '../../selectors';

interface IProfessionPrimaryBlockProps {
  eventKinds: EventKindState;
  rewardKinds: RewardKindState;
  professionEventsDictionary: IDictionaryItem[];
}

const ProfessionPrimaryBlock: FC<IProfessionPrimaryBlockProps> = ({
  eventKinds,
  rewardKinds,
  professionEventsDictionary,
}) => {
  const portfolioSections = useAppSelector(sectionRefArchiveFalseSelector);

  const { studentType, onOpenEventForm, formData } = useContext(LocalDataEntryFormContext);

  // activity
  const activityTypeOptions = useMemo(() => {
    return portfolioSections.content.filter((section) => section.parentId === SectionCodes.professionEvent);
  }, [portfolioSections.content]);
  const activityNameOptions = useMemo(() => {
    // TODO сейчас происходит поиск по dataKind 70, при обновлении справочника нужно завязать на typeCode 72
    // return eventKinds.content.filter((eventKind) => eventKind.categoryCode === formData.type);
    return eventKinds.content.filter((eventKind) => eventKind.categoryCode === formData.dataKind);
  }, [eventKinds.content, formData.dataKind]);

  // rewards
  const rewardTypeOptions = useMemo(() => {
    return portfolioSections.content.filter((section) => section.parentId === SectionCodes.professionReward);
  }, [portfolioSections.content]);
  const rewardItemOptions = professionEventsDictionary;
  const rewardResultOptions = rewardKinds.content.filter((rewardKind) => rewardKind.categoryCode === formData.typeCode);

  // gia
  const giaTypeOptions = useMemo(() => {
    return portfolioSections.content.filter((section) => section.parentId === SectionCodes.professionExam);
  }, [portfolioSections.content]);

  const handleOpenEventForm = () => {
    onOpenEventForm(SectionCodes.profession, SectionCodes.professionEvent);
  };

  return (
    <>
      {/* награда */}
      {formData.dataKind === SectionCodes.professionReward && (
        <RewardBlock
          shortType={false}
          isNameOptionActive={formData.typeCode === SectionCodes.professionRewardEvent}
          typeOptions={rewardTypeOptions}
          itemOptions={rewardItemOptions}
          resultOptions={rewardResultOptions}
          nameInputLabel="Название мероприятия"
          nameInputPlaceholder={
            studentType ? 'Выбери мероприятие, за которое была получена награда...' : 'Выберите мероприятие...'
          }
          onOpenEventForm={handleOpenEventForm}
        />
      )}
      {/* мероприятие */}
      {formData.dataKind === SectionCodes.professionEvent && (
        <ActivityBlock
          typeOptions={activityTypeOptions}
          nameOptions={activityNameOptions}
          nameInputLabel="Название мероприятия"
          nameInputPlaceholder="Выберите мероприятие"
        />
      )}
      {/* ГИА */}
      {formData.dataKind === SectionCodes.professionExam && <GiaBlock typeLabel="ГИА" typeOptions={giaTypeOptions} />}
    </>
  );
};

const professionEventssSelector = createSelector([(state: IRootState) => state.personsObjects], (personsObjects) => {
  return filterPersonsObjects(personsObjects.content, [SectionCodes.professionEvent]);
});

export default connect((state: IRootState) => ({
  eventKinds: state.eventKinds,
  rewardKinds: state.rewardKinds,
  professionEventsDictionary: professionEventssSelector(state),
}))(ProfessionPrimaryBlock);
