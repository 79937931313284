import { useLayoutEffect, useState } from 'react';

import useClientWidth from './useClientWidth';

const getElementLineHeight = (element: HTMLElement) => {
  const lineHeightStyle = getComputedStyle(element).lineHeight;

  if (typeof lineHeightStyle !== 'string') {
    return 0;
  }

  return Number(lineHeightStyle.replace('px', ''));
};

/**
 * Хук принимает элемент и возвращает объект с данными
 * Требование к элементу: элемент с простым текстом, высота для одной строчки текста равна lineHeight
 *
 * isMultiline - признак того, что текст занимает более одной строки
 */
const useMeasureMultiline = (textElement: HTMLElement | null) => {
  const [isMultiline, setMultiline] = useState(false);
  const clientWidth = useClientWidth();

  useLayoutEffect(() => {
    if (!textElement) return;

    const lineHeight = getElementLineHeight(textElement);
    const { height } = textElement.getBoundingClientRect();

    setMultiline(height > lineHeight);
  }, [clientWidth, textElement]);

  return { isMultiline };
};

export default useMeasureMultiline;
