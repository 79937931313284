/* eslint-disable prettier/prettier */
/* eslint-disable */

import { validateProperties } from '../common';
import { SectionCodes } from '../../../../../const';
import { CommonValidator } from '../types';
import { IPortfolioEntryFormData } from 'portfolio3/features/dataEntryForm';

const employmentValidators: Record<string, CommonValidator> = {
  [SectionCodes.science]: isScienceEmploymentValid,
  [SectionCodes.sport]: isSportEmploymentValid,
  [SectionCodes.creation]: isCreationEmploymentValid,
  [SectionCodes.civil]: isCivilnEmploymentValid
};

export const isEmploymentValid = (formData: IPortfolioEntryFormData): boolean => {
  const { categoryCode } = formData;
  const commonValidator = () => validateProperties(getCommonEmploymentProperties(formData));

  const validator = categoryCode ? employmentValidators[categoryCode] : null;
  return validator ? validator(formData, commonValidator) : false;
};

// SectionCodes.science
function isScienceEmploymentValid(formData: IPortfolioEntryFormData, commonValidator: () => boolean): boolean {
  return validateProperties([
    commonValidator(),
    formData.categoryCode === SectionCodes.science,
    formData.subCategory,
    formData.secondName,
    formData.subjectId.length > 0
  ]);
}

// SectionCodes.sport
function isSportEmploymentValid(formData: IPortfolioEntryFormData, commonValidator: () => boolean): boolean {
  return validateProperties([
    commonValidator(),
    formData.categoryCode === SectionCodes.sport,
    formData.subCategory,
    formData.secondName,
    formData.sportKind || formData.tourismKind
  ]);
}

// SectionCodes.creation
function isCreationEmploymentValid(formData: IPortfolioEntryFormData, commonValidator: () => boolean): boolean {
  return validateProperties([
    commonValidator(),
    formData.categoryCode === SectionCodes.creation,
    formData.subCategory,
    formData.secondName,
    formData.creationKindCode
  ]);
}

// SectionCodes.civil
function isCivilnEmploymentValid(formData: IPortfolioEntryFormData, commonValidator: () => boolean): boolean {
  return validateProperties([
    commonValidator(),
    formData.categoryCode === SectionCodes.civil,
    formData.subCategory,
    formData.secondName
  ]);
}

function getCommonEmploymentProperties(formData: IPortfolioEntryFormData): unknown[] {
  return [formData.startDate];
}
