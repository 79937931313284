/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

const IconJuniorTourist2: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="50" height="50" fill="#E5E5E5"/>
    <rect width="1924" height="1397" transform="translate(-1166 -1085)" fill="white"/>
    <g clipPath="url(#clip0)">
    <path d="M2.18164 24.8877C2.35477 18.9158 4.83376 13.247 9.09235 9.08449C13.3509 4.92201 19.0538 2.59364 24.9905 2.59364C30.9271 2.59364 36.63 4.92201 40.8886 9.08449C45.1472 13.247 47.6262 18.9158 47.7993 24.8877H2.18164Z" fill="white"/>
    <path d="M24.9998 2.12905C30.9876 2.13648 36.7308 4.52018 40.9829 8.76277C45.235 13.0054 47.6528 18.7644 47.7116 24.79H2.27835C2.33966 18.7636 4.75964 13.0045 9.01329 8.76216C13.2669 4.51984 19.0112 2.13645 24.9998 2.12905ZM24.9998 1.9337C18.9222 1.9337 13.0936 4.36338 8.79605 8.68823C4.49854 13.0131 2.08423 18.8788 2.08423 24.9951H47.9154C47.9154 18.8788 45.5011 13.0131 41.2036 8.68823C36.9061 4.36338 31.0774 1.9337 24.9998 1.9337Z" fill="#969696"/>
    <path d="M25.0002 3.35979C22.1761 3.35851 19.3795 3.91718 16.7701 5.00388C14.1606 6.09058 11.7895 7.68403 9.79215 9.69318C7.7948 11.7023 6.21036 14.0878 5.12935 16.7134C4.04834 19.3389 3.49194 22.1531 3.49194 24.9951H46.4987C46.4987 22.1539 45.9426 19.3406 44.8622 16.7156C43.7818 14.0907 42.1983 11.7057 40.2019 9.69663C38.2056 7.68761 35.8356 6.09396 33.2273 5.00668C30.619 3.9194 27.8234 3.35979 25.0002 3.35979Z" fill="#748FFC"/>
    <path d="M25 4.77609C22.3599 4.77352 19.7453 5.29461 17.3055 6.30956C14.8657 7.32451 12.6485 8.81343 10.7808 10.6912C8.91314 12.569 7.4315 14.7988 6.42061 17.2531C5.40972 19.7075 4.8894 22.3383 4.8894 24.9951H45.0815C45.0827 22.3404 44.5643 19.7114 43.5556 17.2584C42.547 14.8053 41.068 12.5763 39.2032 10.6987C37.3383 8.82106 35.1241 7.33158 32.6871 6.31536C30.2501 5.29914 27.6379 4.77609 25 4.77609Z" fill="#E83F40"/>
    <path d="M25 -8L13.5762 24.9951L25 58L36.5112 24.9951L25 -8Z" fill="#748FFC"/>
    <path d="M25 58L36.5112 24.9951L25 -8" fill="#5073CC"/>
    <path d="M58 24.9951L25 13.5084L-8 24.9951L25 36.4819L58 24.9951Z" fill="#748FFC"/>
    <path d="M-8 24.9951L25 36.4819L58 24.9951" fill="#5073CC"/>
    <path d="M25 43.9541C35.4046 43.9541 43.8391 35.4659 43.8391 24.9951C43.8391 14.5243 35.4046 6.0361 25 6.0361C14.5955 6.0361 6.16089 14.5243 6.16089 24.9951C6.16089 35.4659 14.5955 43.9541 25 43.9541Z" fill="#CCCCCC"/>
    <path d="M35.6181 30.3087C34.6523 32.2845 33.1618 33.9527 31.3118 35.1284C29.4618 36.3041 27.3245 36.9414 25.1366 36.9697C22.9488 36.998 20.7959 36.4162 18.9165 35.2888C17.037 34.1614 15.5044 32.5324 14.4884 30.5822L4.83105 36.3842C6.84776 40.0136 9.79453 43.0325 13.3645 45.1264C16.9344 47.2203 20.9969 48.3127 25.1288 48.2898C29.2608 48.2668 33.3111 47.1294 36.8578 44.996C40.4046 42.8625 43.3181 39.8111 45.2949 36.1595L35.6181 30.3087Z" fill="#CCCCCC"/>
    <path d="M24.9999 36.941C31.5557 36.941 36.8702 31.5926 36.8702 24.9951C36.8702 18.3976 31.5557 13.0493 24.9999 13.0493C18.4442 13.0493 13.1296 18.3976 13.1296 24.9951C13.1296 31.5926 18.4442 36.941 24.9999 36.941Z" fill="url(#paint0_linear)" stroke="#969696" strokeWidth="0.2" strokeMiterlimit="10"/>
    <path d="M21.6611 20.1113H23.9808V30.465H21.6611V20.1113Z" fill="#969696"/>
    <path d="M26.019 20.1113H28.3387V30.465H26.019V20.1113Z" fill="#969696"/>
    <path opacity="0.5" d="M40.0635 13.5572C38.3189 11.2437 36.0684 9.36547 33.4865 8.068C30.9046 6.77052 28.0605 6.08864 25.1747 6.07518C14.9155 6.07518 6.55877 14.5437 6.15112 25.1514C8.95992 27.1912 12.21 28.5303 15.6338 29.0585C20.8705 29.806 26.2009 28.658 30.675 25.8191C35.1491 22.9801 38.4762 18.6347 40.0635 13.5572Z" fill="url(#paint1_linear)"/>
    <path d="M13.6247 34.6163L14.9059 35.7884C15.7309 36.5405 16.1288 37.4391 15.3038 38.3475C15.1384 38.5405 14.9326 38.6943 14.7012 38.7979C14.4698 38.9015 14.2185 38.9523 13.9653 38.9467C13.712 38.9411 13.4632 38.8792 13.2365 38.7655C13.0099 38.6518 12.811 38.489 12.6541 38.2889L12.198 37.8689L11.0527 39.1484L10.2083 38.3963L13.6247 34.6163ZM13.2559 37.5466C13.7218 37.9666 14.1197 37.9568 14.4788 37.5466C14.838 37.1363 14.7118 36.7847 14.2556 36.3647L13.8577 36.013L12.8385 37.1461L13.2559 37.5466Z" fill="#969696"/>
    <path d="M15.459 39.5782C16.1676 38.0838 17.4002 37.6247 18.6426 38.2108C19.8849 38.7968 20.2926 40.0666 19.6132 41.5513C18.9338 43.036 17.672 43.5048 16.4102 42.9578C15.1485 42.4108 14.7505 41.0727 15.459 39.5782ZM18.5358 41.0531C18.9726 40.135 18.8367 39.4024 18.2155 39.0996C17.5943 38.7968 16.944 39.1582 16.517 40.0764C16.0899 40.9945 16.1967 41.7662 16.8179 42.0299C17.439 42.2936 18.099 41.9713 18.5358 41.0531Z" fill="#969696"/>
    <path d="M20.4189 41.815C20.443 41.4806 20.5358 41.155 20.6915 40.8586C20.8472 40.5622 21.0622 40.3016 21.3232 40.0932C21.5841 39.8847 21.8851 39.7331 22.2073 39.6478C22.5295 39.5626 22.8657 39.5455 23.1947 39.5977C23.4753 39.6464 23.743 39.752 23.9816 39.9082C24.2202 40.0644 24.4247 40.2678 24.5827 40.5061L23.8645 41.1215C23.6777 40.8398 23.3884 40.6433 23.0589 40.5745C22.3795 40.4671 21.7389 40.975 21.5739 41.9811C21.4089 42.9871 21.7777 43.6806 22.4959 43.8076C22.682 43.8352 22.8718 43.8225 23.0526 43.7702C23.2334 43.718 23.401 43.6275 23.5442 43.5048L24.0197 44.3155C23.7835 44.516 23.506 44.6612 23.2072 44.7406C22.9084 44.82 22.5959 44.8316 22.2921 44.7746C21.9659 44.7239 21.6543 44.6038 21.3779 44.4222C21.1016 44.2406 20.8669 44.0018 20.6896 43.7216C20.5123 43.4415 20.3964 43.1265 20.3497 42.7977C20.3031 42.4689 20.3266 42.1339 20.4189 41.815Z" fill="#969696"/>
    <path d="M25.1649 42.489C25.1042 42.1591 25.1116 41.8203 25.1867 41.4935C25.2619 41.1667 25.4031 40.859 25.6016 40.5896C25.8002 40.3201 26.0517 40.0947 26.3406 39.9275C26.6295 39.7602 26.9495 39.6546 27.2808 39.6173C27.564 39.5929 27.8492 39.6261 28.1193 39.7151C28.3895 39.804 28.639 39.9468 28.8531 40.135L28.3193 40.9164C28.188 40.7992 28.0339 40.7109 27.8668 40.6571C27.6996 40.6033 27.5232 40.5851 27.3487 40.6038C26.6596 40.6722 26.1743 41.3266 26.2714 42.3424C26.3684 43.3583 26.9119 43.9346 27.6399 43.8662C27.8329 43.8487 28.0198 43.7889 28.1875 43.691C28.3551 43.5931 28.4995 43.4596 28.6105 43.2997L29.2705 43.9541C29.0962 44.2103 28.8671 44.424 28.6001 44.5794C28.333 44.7349 28.0348 44.8283 27.7272 44.8527C27.4007 44.8858 27.0709 44.849 26.7595 44.7449C26.448 44.6407 26.162 44.4715 25.92 44.2483C25.6781 44.0252 25.4858 43.7531 25.3557 43.4499C25.2255 43.1467 25.1605 42.8192 25.1649 42.489Z" fill="#969696"/>
    <path d="M29.4065 39.3731L30.4547 38.9531L31.0953 40.6429C31.3186 41.1996 31.503 41.8931 31.6583 42.4108V41.1996L32.0659 38.3377L33.1821 37.8786L35.1233 42.5671L34.0071 42.9969L33.3277 41.3364C33.1044 40.7796 32.9297 40.1154 32.7647 39.5782V40.7894L32.493 43.6416L31.3768 44.1006L29.4065 39.3731Z" fill="#969696"/>
    <path d="M34.0654 37.2828L34.9195 36.5405L36.094 37.8884C36.4811 38.3644 36.8439 38.86 37.181 39.3731L36.8316 38.2107L36.1813 35.4269L37.084 34.6358L40.4325 38.3963L39.5784 39.1484L38.404 37.781C38.0157 37.3316 37.6372 36.8042 37.3169 36.306L37.6663 37.4684L38.2875 40.3498L37.3848 41.1508L34.0654 37.2828Z" fill="#969696"/>
    <path d="M10.6353 17.2494L10.9653 16.7415L11.8388 17.3178L12.2755 16.6438L12.722 16.9368L12.2755 17.6108L13.2461 18.2359L12.9064 18.7438L10.6353 17.2494ZM12.3338 17.1029C12.1753 17.0322 12.034 16.9278 11.9197 16.7967C11.8055 16.6656 11.7209 16.511 11.6719 16.3437C11.623 16.1764 11.6108 16.0003 11.6363 15.8278C11.6617 15.6553 11.7242 15.4905 11.8194 15.3447C11.9147 15.1998 12.0399 15.0773 12.1865 14.9854C12.333 14.8935 12.4975 14.8345 12.6688 14.8122C12.84 14.79 13.014 14.8051 13.179 14.8565C13.3439 14.908 13.4959 14.9945 13.6247 15.1103C13.7859 15.1833 13.9296 15.2904 14.0461 15.4243C14.1625 15.5581 14.249 15.7157 14.2996 15.8861C14.3503 16.0566 14.3639 16.2361 14.3395 16.4123C14.3152 16.5885 14.2534 16.7574 14.1585 16.9075C14.0592 17.0514 13.9302 17.172 13.7805 17.2613C13.6307 17.3506 13.4636 17.4064 13.2905 17.4249C13.1174 17.4434 12.9424 17.4243 12.7773 17.3687C12.6122 17.3131 12.461 17.2225 12.3338 17.1029ZM13.3044 15.6377C12.8482 15.3447 12.4794 15.3349 12.2755 15.6377C12.0717 15.9405 12.2755 16.2922 12.6832 16.6145C13.0908 16.9368 13.5373 16.9368 13.7314 16.6145C13.9255 16.2922 13.7411 15.9307 13.285 15.6377H13.3044Z" fill="#969696"/>
    <path d="M13.5081 13.3716L13.9642 12.9614L14.6534 13.7428L15.3425 13.1274L14.6534 12.346L15.1095 11.9358L16.8954 13.9772L16.4392 14.3777L15.7016 13.5377L15.0028 14.153L15.7501 15.0028L15.2939 15.4033L13.5081 13.3716Z" fill="#969696"/>
    <path d="M16.323 11.0469L16.8665 10.7636L17.3227 11.6427L17.5556 11.5158C18.0992 11.2325 18.6039 11.2325 18.8756 11.7697C19.1474 12.3069 18.8756 12.7465 18.3321 13.0493L17.5653 13.4497L16.323 11.0469ZM18.0798 12.6293C18.3612 12.4828 18.4777 12.2972 18.3515 12.053C18.2254 11.8088 18.0409 11.8088 17.7401 11.9748L17.5459 12.0725L17.8856 12.7367L18.0798 12.6293ZM18.3515 9.98224L18.8854 9.69897L20.1277 12.1018L19.5939 12.3948L18.3515 9.98224Z" fill="#969696"/>
    <path d="M20.2827 9.23014L20.8651 9.08363L21.0883 10.0604C21.1567 10.3831 21.2085 10.7091 21.2436 11.0372L21.3698 10.4023L21.7483 8.92735L22.3695 8.77107L23.0004 11.4083L22.4083 11.5548L22.1851 10.5781C22.1171 10.2655 22.0783 9.90411 22.0395 9.60132L21.923 10.2362L21.5445 11.7111L20.9136 11.8674L20.2827 9.23014ZM20.4477 8.37059L20.9524 8.25338C21.0009 8.40966 21.0883 8.49757 21.2242 8.4585C21.3601 8.41943 21.3892 8.31199 21.3892 8.11663L21.8939 7.99942C21.9521 8.32175 21.8162 8.60501 21.3115 8.73199C20.8068 8.85897 20.5448 8.68316 20.4186 8.37059H20.4477Z" fill="#969696"/>
    <path d="M25.6795 9.09338L24.9321 9.04454V8.53662L27.0286 8.65383V9.17152L26.2424 9.09338L26.126 11.2911H25.5145L25.6795 9.09338Z" fill="#969696"/>
    <path d="M27.5525 11.4474L27.7854 10.959C27.8455 10.9954 27.9111 11.0218 27.9796 11.0371C28.0274 11.0537 28.0787 11.057 28.1283 11.0467C28.1778 11.0364 28.2237 11.0129 28.261 10.9785L28.3193 10.9102L27.9116 8.74174L28.5425 8.89803L28.659 9.74781L28.7172 10.4315L29.086 9.85526L29.5519 9.16175L30.144 9.30827L28.8337 11.1348C28.7416 11.305 28.5974 11.4407 28.4225 11.5217C28.2476 11.6026 28.0514 11.6245 27.8631 11.5841C27.7524 11.5572 27.6472 11.511 27.5525 11.4474Z" fill="#969696"/>
    <path d="M31.0953 9.67944L31.93 10.0799C32.4735 10.3339 32.8132 10.7246 32.5415 11.3204C32.4806 11.4374 32.3968 11.5408 32.2952 11.6243C32.1935 11.7078 32.0761 11.7696 31.95 11.8062C31.8239 11.8427 31.6917 11.8531 31.5615 11.8367C31.4313 11.8204 31.3057 11.7777 31.1924 11.7111L30.8915 11.5646L30.513 12.3948L29.9597 12.1409L31.0953 9.67944ZM31.3671 11.252C31.6679 11.3888 31.8815 11.3204 31.998 11.0664C32.1144 10.8125 31.9979 10.6464 31.6971 10.4999L31.4447 10.3827L31.0953 11.1251L31.3671 11.252Z" fill="#969696"/>
    <path d="M34.0071 11.1934L34.4923 11.5548L33.91 12.3167C33.7159 12.5804 33.4538 12.8442 33.2597 13.059L33.8323 12.7465L35.2106 12.1018L35.725 12.4925L34.0944 14.6609L33.6091 14.2898L34.1915 13.5279C34.3856 13.2739 34.6379 13.0102 34.8418 12.7855L34.2691 13.0981L32.8909 13.7428L32.3765 13.3521L34.0071 11.1934Z" fill="#969696"/>
    <path d="M35.6571 14.2995C35.7782 14.1605 35.9275 14.0491 36.0949 13.9727C36.2623 13.8964 36.4439 13.8569 36.6277 13.8569C36.8114 13.8569 36.9931 13.8964 37.1605 13.9727C37.3278 14.0491 37.4771 14.1605 37.5983 14.2995C37.6987 14.4148 37.7739 14.55 37.819 14.6964C37.8641 14.8428 37.8782 14.9971 37.8603 15.1493H37.3556C37.3666 15.0592 37.3594 14.9677 37.3344 14.8804C37.3093 14.7931 37.267 14.7118 37.21 14.6414C36.9674 14.3581 36.5306 14.3484 36.123 14.7098C35.7153 15.0712 35.628 15.4814 35.89 15.7842C35.9582 15.8582 36.0408 15.9172 36.1326 15.9575C36.2245 15.9978 36.3236 16.0187 36.4238 16.0186L36.3462 16.5168C36.1803 16.519 36.0159 16.4842 35.8649 16.4149C35.7139 16.3457 35.5799 16.2437 35.4727 16.1163C35.3609 15.9873 35.2766 15.8365 35.2251 15.6733C35.1736 15.5101 35.1559 15.338 35.1732 15.1677C35.1905 14.9973 35.2423 14.8324 35.3256 14.683C35.4088 14.5337 35.5216 14.4032 35.6571 14.2995Z" fill="#969696"/>
    <path d="M38.5105 16.6438L38.1417 15.9991L38.5784 15.7354L39.6267 17.5619L39.1899 17.8159L38.8599 17.1712L36.9187 18.275L36.6082 17.7475L38.5105 16.6438Z" fill="#969696"/>
    </g>
    <rect x="-1101.5" y="-560.5" width="1768" height="796" rx="4.5" stroke="#7B61FF" strokeDasharray="10 5"/>
    <defs>
    <linearGradient id="paint0_linear" x1="19.0308" y1="16.927" x2="28.64" y2="29.762" gradientUnits="userSpaceOnUse">
    <stop stopColor="white"/>
    <stop offset="0.03" stopColor="#FEFEFF"/>
    <stop offset="1" stopColor="#F1ECFF"/>
    </linearGradient>
    <linearGradient id="paint1_linear" x1="18.3902" y1="10.4511" x2="27.0651" y2="26.6001" gradientUnits="userSpaceOnUse">
    <stop stopColor="white" stopOpacity="0"/>
    <stop offset="0.35" stopColor="white" stopOpacity="0.01"/>
    <stop offset="0.49" stopColor="white" stopOpacity="0.03"/>
    <stop offset="0.59" stopColor="white" stopOpacity="0.08"/>
    <stop offset="0.67" stopColor="white" stopOpacity="0.15"/>
    <stop offset="0.74" stopColor="white" stopOpacity="0.24"/>
    <stop offset="0.8" stopColor="white" stopOpacity="0.35"/>
    <stop offset="0.85" stopColor="white" stopOpacity="0.47"/>
    <stop offset="0.9" stopColor="white" stopOpacity="0.62"/>
    <stop offset="0.95" stopColor="white" stopOpacity="0.79"/>
    <stop offset="1" stopColor="white"/>
    </linearGradient>
    <clipPath id="clip0">
    <rect width="50" height="50" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);

export default IconJuniorTourist2;
