import { SetStateAction } from 'react';

import { DateErrorKeys } from 'const';
import { isAfter, isBefore } from 'date-fns';
import {
  IErrorWithDescription,
  IPortfolioDataEntryFormErrors,
  IPortfolioEntryFormData,
} from 'portfolio3/features/dataEntryForm';
import { DayPickerController, DayRangePickerController, DayValue } from 'portfolio3/ui-kit/datePickers';
import { deepSetProperty, NestedKeyOf } from 'utils';

import { dataEntryDateErrorValues, dataEntryMaxDate, dataEntryMinDate } from '../dateErrors';

type ValidationOptions = {
  disableFuture?: boolean;
  disablePast?: boolean;
  minDate?: Date;
  maxDate?: Date;
  required?: boolean;
  errorValues?: Record<DateErrorKeys, string>;
};

export const getDayRangePickerControllerFactory = (
  onChangeFormData: (value: SetStateAction<IPortfolioEntryFormData>) => void,
  onChangeFormErrors: (value: SetStateAction<IPortfolioDataEntryFormErrors>) => void,
) => {
  return (
    startValueField: NestedKeyOf<Required<IPortfolioEntryFormData>>,
    endValueField: NestedKeyOf<Required<IPortfolioEntryFormData>>,
    formErrorField: NestedKeyOf<Required<IPortfolioDataEntryFormErrors>> | undefined,
    options?: ValidationOptions,
    additionalControllers?: DayRangePickerController[],
  ): DayRangePickerController => {
    return {
      /* change */
      handleChange(value) {
        const { start, end } = value;

        onChangeFormData((prevState) => {
          const firstUpdate = deepSetProperty(prevState, startValueField, start);
          return deepSetProperty(firstUpdate, endValueField, end);
        });

        validateDayPickerValue(start, end, options, formErrorField, onChangeFormErrors);

        additionalControllers?.forEach((controller) => controller.handleChange(value));
      },
      /* blur */
      handleBlur(value) {
        validateDayPickerValue(value.start, value.end, options, formErrorField, onChangeFormErrors);

        additionalControllers?.forEach((controller) => controller.handleBlur?.(value));
      },
      /* clear */
      handleClear() {
        onChangeFormData((prevState) => {
          const firstUpdate = deepSetProperty(prevState, startValueField, null);
          return deepSetProperty(firstUpdate, endValueField, null);
        });

        if (formErrorField) {
          onChangeFormErrors((prevState) => {
            const emptyError: IErrorWithDescription = {
              active: false,
              description: '',
            };

            return deepSetProperty(prevState, formErrorField, emptyError);
          });
        }

        validateDayPickerValue(null, null, options, formErrorField, onChangeFormErrors);

        additionalControllers?.forEach((controller) => controller.handleClear?.());
      },
    };
  };
};

export const getDayPickerControllerFactory = (
  onChangeFormData: (value: SetStateAction<IPortfolioEntryFormData>) => void,
  onChangeFormErrors: (value: SetStateAction<IPortfolioDataEntryFormErrors>) => void,
) => {
  return (
    formDataField: NestedKeyOf<Required<IPortfolioEntryFormData>>,
    formErrorField: NestedKeyOf<Required<IPortfolioDataEntryFormErrors>> | undefined,
    options?: ValidationOptions,
    additionalControllers?: DayPickerController[],
  ): DayPickerController => {
    return {
      /* change */
      handleChange(value) {
        onChangeFormData((prevState) => {
          return deepSetProperty(prevState, formDataField, value);
        });

        validateDayPickerValue(value, null, options, formErrorField, onChangeFormErrors);

        additionalControllers?.forEach((controller) => controller.handleChange(value));
      },
      /* blur */
      handleBlur(value) {
        validateDayPickerValue(value, null, options, formErrorField, onChangeFormErrors);

        additionalControllers?.forEach((controller) => controller.handleBlur?.(value));
      },
      /* clear */
      handleClear() {
        onChangeFormData((prevState) => {
          return deepSetProperty(prevState, formDataField, null);
        });

        if (formErrorField) {
          onChangeFormErrors((prevState) => {
            const emptyError: IErrorWithDescription = {
              active: false,
              description: '',
            };

            return deepSetProperty(prevState, formErrorField, emptyError);
          });
        }

        validateDayPickerValue(null, null, options, formErrorField, onChangeFormErrors);

        additionalControllers?.forEach((controller) => controller.handleClear?.());
      },
    };
  };
};

/**
 * Обрабатывает ошибки:
 * 1) отсутствие значения (required)
 * 2) выход за текущую дату (disableFuture)
 * 3) выход за диапазон (minDate/maxDate)
 */
function getDayPickerValidationError(value: DayValue, options: ValidationOptions = {}): string | null {
  const { disableFuture, disablePast, minDate, maxDate, required, errorValues } = options;

  const errors = errorValues ?? dataEntryDateErrorValues;

  if (required && !value) return errors.emptyDate;

  if (!value) return null;

  const now = Date.now();

  // проверка на текущую дату
  if (disablePast && isBefore(value, now)) return errors.beforeToday;
  if (disableFuture && isAfter(value, now)) return errors.afterToday;

  // проверка на переданные min/max
  if (minDate && isBefore(value, minDate)) return errors.beforeStartDate;
  if (maxDate && isAfter(value, maxDate)) return errors.afterEndDate;

  // проверка на глобальные min/max, при условии что они не определены в опциях конроллера
  if (!minDate && isBefore(value, dataEntryMinDate)) return errors.beforeMinDate;
  if (!maxDate && isAfter(value, dataEntryMaxDate)) return errors.afterMaxDate;

  return null;
}

function validateDayPickerValue(
  startValue: DayValue,
  endValue: DayValue,
  options: ValidationOptions | undefined,
  formErrorField: NestedKeyOf<Required<IPortfolioDataEntryFormErrors>> | undefined,
  onChangeFormErrors: (value: SetStateAction<IPortfolioDataEntryFormErrors>) => void,
) {
  if (!formErrorField) return;

  const startDateValidationError = getDayPickerValidationError(startValue, options);
  const endDatevalidationError = getDayPickerValidationError(endValue, { ...options, required: false });

  const newDateError: IErrorWithDescription = {
    active: startDateValidationError !== null || endDatevalidationError !== null,
    description: startDateValidationError ?? endDatevalidationError ?? '',
  };

  onChangeFormErrors((prevState) => {
    return deepSetProperty(prevState, formErrorField, newDateError);
  });
}
