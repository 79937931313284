import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const rootFull: SxStyles = {
  paddingTop: 0,
};
export const rootShort: SxStyles = (theme) => {
  return {
    paddingTop: '20px',

    [theme.breakpoints.down('commonSm')]: {
      paddingTop: 0,
    },
  };
};

export const facultyContainerFull: SxStyles = (theme) => {
  return {
    marginTop: '20px',

    [theme.breakpoints.down('commonSm')]: {
      marginTop: '16px',
    },
  };
};
export const facultyContainerShort: SxStyles = (theme) => {
  return {
    marginBlock: '12px 16px',

    [theme.breakpoints.down('commonSm')]: {
      marginBlock: '16px',
    },
  };
};

export const specialityCard: SxStyles = (theme) => {
  return {
    paddingBlock: '12px 16px',
    paddingLeft: '28px',

    [theme.breakpoints.down('commonSm')]: {
      paddingBlock: '12px',
      paddingLeft: 0,
    },
  };
};

export const listDivier: SxStyles = {
  height: '1px',
  backgroundColor: NeutralColors.light_neutrals_300,

  '&&&': {
    marginTop: 0,
  },
};

export const specialyDivider: SxStyles = (theme) => {
  return {
    marginLeft: '28px',

    [theme.breakpoints.down('commonSm')]: {
      marginLeft: 0,
    },
  };
};

export const bottomContainer: SxStyles = (theme) => {
  return {
    marginTop: '20px',

    [theme.breakpoints.down('commonSm')]: {
      marginTop: 0,
    },
  };
};
