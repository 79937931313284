/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import './index.scss';

const SubjectDetailsEmpty: React.VFC = () => {
  return (
    <div className="subject-details-empty">
      <p className="subject-details-empty__description">
        Нажми на любой предмет, чтобы увидеть подробную информацию по нему
      </p>
    </div>
  );
};

export default SubjectDetailsEmpty;
