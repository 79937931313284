/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, {
  useState, useEffect, useCallback, useMemo, useRef
} from 'react';

import useClientWidth from 'hooks/useClientWidth';
import { DifferentCode } from 'const';
import { Colors } from 'style/variables';

import {
  Checkbox, FormControlLabel, Radio, RadioButton, Tooltip
} from 'ui-kit';
import LineChart from 'components/common/lineChart';
import LineChartAxis from 'components/common/lineChart/lineChartAxis';
import Popper from 'components/common/Popper';
import { SingleDictionarySelect } from 'components/common/Select';
import { IPerformanceSubject, IPerformanceYear, MarkType } from '../../types';

import './index.scss';

interface IShownSubject {
  name: string;
  values: {
    interval?: string;
    value?: number;
  }[];
}

interface IPerformanceChartProps {
  markType: MarkType;
  performanceData: IPerformanceYear[];
  lowestGrade: number;
}

const WHOLE_PERIOD = 'Весь период';
const NOO = 'НОО';
const OOO = 'ООО';
const COO = 'СОО';
// TODO добавить в https://jira.mos.social/browse/SP-2649
// const CURRENT_YEAR = 'Текущий год';
const educationalLevels = [WHOLE_PERIOD, NOO, OOO, COO] as const;

type TEducationalLevel = typeof educationalLevels[number];

interface IEducationalLevelFilterOption {
  code: number;
  value: TEducationalLevel;
  isVisible: boolean;
}

const isEducationalLevel = (value: string): value is TEducationalLevel => {
  return educationalLevels.includes(value as TEducationalLevel);
};

const PerformanceChart: React.FC<IPerformanceChartProps> = ({ markType, performanceData, lowestGrade }) => {
  const ALL_SUBJECTS_VALUE = 'По всем предметам';
  const MAX_POSSIBLE_CHECKED = 5;
  const [activeCheckBoxes, setActiveCheckBoxes] = useState<string[]>([ALL_SUBJECTS_VALUE]);
  const [educationalLevel, setEducationalLevel] = useState <TEducationalLevel>(WHOLE_PERIOD);
  const [shownPerformanceData, setShownPerformanceData] = useState<IShownSubject[]>([]);
  const [checkboxPopperOpen, setCheckboxPopperOpen] = useState(false);
  const menuButtonRef = useRef<HTMLDivElement>(null);
  const menuIconRef = useRef(null);
  const clientWidth = useClientWidth();

  const isMobileView = clientWidth <= 800;

  const filteredPerformanceData = useMemo(() => performanceData?.filter((performance) => {
    if (educationalLevel === WHOLE_PERIOD) return true;
    if (performance.educationLevel === educationalLevel) return true;
    return false;
  }), [performanceData, educationalLevel, markType]);

  // Функция для получения массива уникальных предметов
  const getSubjectsArray = () => {
    const subjectsArray:IPerformanceSubject[] = [];

    filteredPerformanceData.forEach((performance) => {
      performance.subjects.forEach((item) => {
        if (!subjectsArray.find((subject) => subject.subjectName === item.subjectName)) subjectsArray.push(item);
      });
    });

    return subjectsArray.sort((x, y) => (x.subjectName.toLowerCase() < y.subjectName.toLowerCase() ? -1 : 1));
  };

  // Функция для получения данных, отображаемых на графике
  const getShownPerformanceData = () => {
    const subjectArray = getSubjectsArray();
    const allResult = {
      name: 'По всем предметам',
      values: filteredPerformanceData.map((performance) => ({
        interval: performance.learningYear,
        value: markType === MarkType.AVERAGE ? performance.averageAllMarks : performance.averageAllYearMarks
      }))
    };
    const newShownPerformanceData = subjectArray.map((subject) => ({
      name: subject.subjectName,
      values: filteredPerformanceData.map((performance) => {
        const performanceSubject = performance.subjects.find((performance) => performance.subjectName === subject.subjectName);
        const value = markType === MarkType.AVERAGE ? performanceSubject?.averageMark : performanceSubject?.averageYearMark;
        return {
          interval: performance.learningYear,
          value: value && value >= lowestGrade ? value : undefined
        };
      })
    }));

    newShownPerformanceData.unshift(allResult);

    return newShownPerformanceData;
  };

  // Сортировка данных на графике в соответствии с порядком чекбоксов
  const sortShownPerformanceData = (shownPerformanceData: IShownSubject[], checkBoxArray: string[]) => shownPerformanceData.sort((x, y) => checkBoxArray.indexOf(x.name) - checkBoxArray.indexOf(y.name));

  const handleChangePerformanceData = () => {
    const newShownPerformanceData = getShownPerformanceData();
    const newActiveCheckboxes = activeCheckBoxes.filter((checkbox) => newShownPerformanceData.find((performance) => performance.name === checkbox));
    const sortedNewPerformanceData = sortShownPerformanceData(newShownPerformanceData.filter((data) => newActiveCheckboxes.includes(data.name)), newActiveCheckboxes);
    setActiveCheckBoxes(newActiveCheckboxes);
    setShownPerformanceData(sortedNewPerformanceData);
  };

  const handleResetPerformanceChart = () => {
    const allResult = {
      name: 'По всем предметам',
      values: filteredPerformanceData.map((performance) => ({
        interval: performance.learningYear,
        value: markType === MarkType.AVERAGE ? performance.averageAllMarks : performance.averageAllYearMarks
      }))
    };
    setShownPerformanceData([allResult]);
    setActiveCheckBoxes([ALL_SUBJECTS_VALUE]);
  };

  // Сбрасываем значения графика при изменении настроек фильтрации
  useEffect(() => {
    handleChangePerformanceData();
  }, [performanceData, educationalLevel, markType]);

  // ВОзможные цвета графиков
  const lineColors = activeCheckBoxes.includes(ALL_SUBJECTS_VALUE) ? [
    Colors['red-strong'], '#CC5DE8', Colors['violet-strong'], Colors['cyan-strong'], '#20C997', '#94D82D'
  ] : ['#CC5DE8', Colors['violet-strong'], Colors['cyan-strong'], '#20C997', '#94D82D'];

  const subjects = getSubjectsArray().map((data, index) => ({
    code: index + 1,
    value: data.subjectName
  }));
  subjects.unshift({ code: DifferentCode, value: ALL_SUBJECTS_VALUE });

  // Чекбокс Все предметы всегда наверху
  const sortActiveCheckBoxes = (array: string[]) => array.sort((x, y) => (x === ALL_SUBJECTS_VALUE ? -1 : 0));

  // Функция, активирующаяся при выборе чекбокса
  const handleSetActiveCheckbox = (value: string) => {
    setActiveCheckBoxes((prevstate) => {
      if (prevstate.length === MAX_POSSIBLE_CHECKED + 1 && !prevstate.includes(value)) return prevstate;
      if (prevstate.length === MAX_POSSIBLE_CHECKED && !prevstate.includes(value) && !prevstate.includes(ALL_SUBJECTS_VALUE) && value !== ALL_SUBJECTS_VALUE) return prevstate;
      const newState = [...prevstate];
      const valueIndex = prevstate.indexOf(value);
      if (valueIndex > -1) newState.splice(valueIndex, 1);
      else value === ALL_SUBJECTS_VALUE ? newState.unshift(value) : newState.push(value);
      setShownPerformanceData(sortShownPerformanceData(getShownPerformanceData().filter((data) => newState.includes(data.name)), newState));
      return sortActiveCheckBoxes(newState);
    });
  };

  const getCheckboxColor = useCallback((value: string) => lineColors[activeCheckBoxes.indexOf(value)], [activeCheckBoxes]);

  const getCheckboxActiveState = useCallback((value: string) => (Boolean(activeCheckBoxes.includes(value))), [activeCheckBoxes]);

  const hasStartEducationData = !!performanceData.find((performance) => performance.educationLevel === NOO);
  const hasMiddleEducationData = !!performanceData.find((performance) => performance.educationLevel === OOO);
  const hasHighEducationData = !!performanceData.find((performance) => performance.educationLevel === COO);

  const isTooltipShown = activeCheckBoxes.length === 6 || (activeCheckBoxes.length === 5 && !activeCheckBoxes.includes(ALL_SUBJECTS_VALUE));

  const toggleCheckboxPopper = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    setCheckboxPopperOpen((prevstate) => !prevstate);
  };

  const filtersController = {
    handleChange: (item: any) => {
      const educationLevel = filters.find((filter) => filter.code === item)?.value;
      setEducationalLevel(educationLevel || WHOLE_PERIOD);
    }
  };

  const filterOptions: IEducationalLevelFilterOption[] = [
    {
      code: 1,
      value: WHOLE_PERIOD,
      isVisible: true
    },
    {
      code: 2,
      value: NOO,
      isVisible: hasStartEducationData
    },
    {
      code: 3,
      value: OOO,
      isVisible: hasMiddleEducationData
    },
    {
      code: 4,
      value: COO,
      isVisible: hasHighEducationData
    }
  ];

  const filters = filterOptions.filter((filter) => filter.isVisible);
  const educationalLevelCode = filters.find((filter) => filter.value === educationalLevel)?.code;

  return (
    <div className="performance-chart">
      <div className="performance-chart__left-column">
        <div className="performance-chart__row">
          {isMobileView ? (
            <div className="performance-chart__filter-select">
              <SingleDictionarySelect
                optionsClass="select__item--common--type-study"
                options={filters}
                placeholder=""
                selectController={filtersController}
                value={educationalLevelCode}
              />
            </div>
          ) : (
            <RadioButton
              name="chart-type"
              defaultValue={WHOLE_PERIOD}
              className="performance-chart__filter"
              value={educationalLevel}
              onChange={(event, value) => {
                if (isEducationalLevel(value)) {
                  setEducationalLevel(value);
                }
              }}
            >
              {filters.map((filter) => (
                <FormControlLabel
                  key={filter.code}
                  value={filter.value}
                  control={<Radio />}
                  label={(<p>{filter.value}</p>)}
                />
              ))}
            </RadioButton>
          )}
          <div
            className={`performance-chart__checkbox-container ${checkboxPopperOpen ? 'performance-chart__checkbox-container--active' : ''}`}
            ref={menuButtonRef}
            onClick={toggleCheckboxPopper}
            role="button"
          >
            Отображается:
            {' '}
            {activeCheckBoxes.length}
          </div>
        </div>
        <LineChartAxis
          xAxisLabel="учебный год"
        >
          <LineChart
            incomingData={shownPerformanceData}
            lineColors={lineColors}
            yAxisLabel="балл"
            width={clientWidth <= 800 ? shownPerformanceData[0]?.values.length * 80 : undefined}
          />
        </LineChartAxis>
      </div>
      <div className="performance-chart__right-column">
        <div className="performance-chart__subjects-header">
          <h5>Предметы</h5>
          <p
            className="performance-chart__clear-button"
            role="button"
            onClick={handleResetPerformanceChart}
          >
            Сбросить все
          </p>
        </div>
        <div className="performance-chart__subjects-container">
          {subjects.map((subject) => {
            const isChecked = getCheckboxActiveState(subject.value);
            const checkboxColor = getCheckboxColor(subject.value);
            return isTooltipShown && !isChecked && subject.value !== ALL_SUBJECTS_VALUE ? (
              <Tooltip
                title="Одновременно можно выбрать не более 5-ти предметов"
                className="performance-chart__tooltip"
                arrow
                placement="left"
                width="190px"
              >
                <div
                  className={isChecked ? 'performance-chart__subject performance-chart__subject--checked' : 'performance-chart__subject'}
                  key={subject.value}
                >
                  <Checkbox checked={isChecked} onChange={() => handleSetActiveCheckbox(subject.value)} background={checkboxColor} />
                  <p className="performance-chart__subject-name" style={{ color: checkboxColor }}>{subject.value}</p>
                </div>
              </Tooltip>
            ) : (
              <div
                className={isChecked ? 'performance-chart__subject performance-chart__subject--checked' : 'performance-chart__subject'}
                key={subject.value}
              >
                <Checkbox checked={isChecked} onChange={() => handleSetActiveCheckbox(subject.value)} background={checkboxColor} />
                <p className="performance-chart__subject-name" style={{ color: checkboxColor }}>{subject.value}</p>
              </div>
            );
          })}
        </div>

      </div>
      <Popper
        open={checkboxPopperOpen}
        anchorEl={menuButtonRef.current}
        onClosePopperMenu={(event) => { if (event.target !== menuButtonRef.current && event.target !== menuIconRef.current) setCheckboxPopperOpen(false); }}
        placement="bottom-end"
        style={{ zIndex: 2 }}
      >
        <div className="performance-chart__checkbox-menu">
          {subjects.map((subject) => {
            const isChecked = getCheckboxActiveState(subject.value);
            const checkboxColor = getCheckboxColor(subject.value);
            return (
              <div
                className={isChecked ? 'performance-chart__subject performance-chart__subject--checked' : 'performance-chart__subject'}
                key={subject.value}
              >
                <Checkbox checked={isChecked} onChange={() => handleSetActiveCheckbox(subject.value)} background={checkboxColor} />
                <p className="performance-chart__subject-name" style={{ color: checkboxColor }}>{subject.value}</p>
              </div>
            );
          })}
        </div>
      </Popper>
    </div>
  );
};

export default PerformanceChart;
