/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import clsx from 'clsx';
import { useMediaQuery } from '@mui/material';

import InterestsFooter from '../footer';
import { IInterestEntity } from '../../../../types';
import { InterestDataEntity } from '../../../../components/redesignStudent/DataEntity/profile';
import { smileCatPortrait } from '../../../../images';
import { IconPlus } from '../../../../icons';
import { ViewDialog } from '../../../common/Dialog';
import { IInterest } from '../types';
import { InterestsEditFormContext } from '../../../../context';
import { InterestSectionThemes } from '../../../../containers/redesignStudent/StudentInterests/utils';
import { IRootState } from '../../../../reducers';
import { createStudentInterestListActions } from '../../../../actions';
import { IChildInfo, ISaveInterestRequest } from '../../../../api/types';
import { getInterestCategoryLabel } from '../../../../utils';
import { useUserSourceCode } from 'hooks';

import './index.scss';

interface IInterestsSumamryProps {
  isWebView?: boolean,
  interests: IInterestEntity[],
  currentStudent: IChildInfo,
  setSelectedInterests: React.Dispatch<React.SetStateAction<IInterest[]>>,
  onChangeStepBack: () => void,
  redirectToCheckerPage: () => void,
  redirectToMainPage: () => void,
  createStudentInterestList: typeof createStudentInterestListActions.request
}

const InterestsSummary: React.FC<IInterestsSumamryProps> = ({
  isWebView,
  interests,
  currentStudent,
  setSelectedInterests,
  onChangeStepBack,
  redirectToCheckerPage,
  redirectToMainPage,
  createStudentInterestList
}) => {
  const sourceCode = useUserSourceCode();

  const { onOpen: onEditFormOpen, setEditFormAttributes, setEditFormData } = useContext(InterestsEditFormContext);
  const [isMobileInfoOpen, setMobileInfoOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width: 639px)', { noSsr: true });

  const handleCloseMobileInfo = () => {
    setMobileInfoOpen(false);
  };

  const editInterestById = (interestId: number) => {
    const interest = interests.find((interest) => interest.id === interestId);
    if (!interest) return;

    const sectionTheme = InterestSectionThemes[interest.headCode];

    setEditFormAttributes({
      interestId: interest.id,
      interestName: interest.name,
      categorySectionName: getInterestCategoryLabel(interest.headCode),
      backgroundColor: sectionTheme.header.backgroundColor,
      backgroundImage: sectionTheme.header.editFormImage,
      titleColor: sectionTheme.header.titleColor
    });

    setEditFormData(interest.editFormData);
    onEditFormOpen();
  };

  const deleteInterestById = (interestId: number) => {
    setSelectedInterests((prevstate) => {
      const interestIndex = prevstate.findIndex((entity) => entity.id === interestId);
      if (interestIndex < 0) return prevstate;
      const newState = [...prevstate];
      newState.splice(interestIndex, 1);
      return newState;
    });
  };

  useEffect(() => {
    if (isMobile) {
      setMobileInfoOpen(true);
    } else if (isMobileInfoOpen) {
      handleCloseMobileInfo();
    }
  }, [isMobile]);

  const handleSaveChanges = () => {
    if (!currentStudent.meshId || !sourceCode) return;

    const interestList: ISaveInterestRequest[] = interests.map((interest) => {
      const subinterestCodes = interest.subinterests?.map((subinterest) => subinterest.code);

      return {
        personId: currentStudent.meshId,
        sourceCode,
        interestCode: interest.code,
        interestHeadCode: interest.headCode,
        interestActionCode: interest.actionKinds.map((actionKind) => actionKind.code),
        subinterestCode: subinterestCodes && subinterestCodes.length > 0 ? subinterestCodes : null
      };
    });

    createStudentInterestList(interestList, currentStudent.meshId);
    redirectToMainPage();
  };

  return (
    <section className={clsx('interests-summary', { 'interests-summary--webview': isWebView })}>
      <div className="interests-summary__content">
        <h3 className="interests-summary__title">Анкета интересов</h3>
        <p className="interests-summary__description">Проверь все ли правильно указано.</p>
        <div className="interests-summary__cards">
          {interests.map((interest) => (
            <InterestDataEntity key={interest.id} interest={interest} onEdit={editInterestById} onDelete={deleteInterestById} />
          ))}
          <button
            className="interests-summary__add-btn"
            type="button"
            onClick={redirectToCheckerPage}
          >
            <IconPlus className="interests-summary__add-icon" />
            Добавить
          </button>
        </div>
        <InterestsFooter
          submitButtonTitle="Завершить"
          submitDisabled={interests.length === 0}
          onBack={onChangeStepBack}
          onSubmit={handleSaveChanges}
        />
      </div>
      <ViewDialog
        isOpen={isMobileInfoOpen}
        onClose={handleCloseMobileInfo}
        title=""
        className="info-dialog"
      >
        <div className="dialog-content">
          <img src={smileCatPortrait} alt="кот" />
          <h5>Ура, осталось совсем немного!</h5>
          <span>
            Перед тем как добавить твои интересы в Портфолио, давай проверим всё ли верно указано. Ты можешь удалить, отредактировать карточки или добавить новые интересы.
          </span>
        </div>
      </ViewDialog>
    </section>
  );
};

export default connect(
  (state: IRootState) => ({
    currentStudent: state.currentStudent,
  }),
  {
    createStudentInterestList: createStudentInterestListActions.request
  }
)(InterestsSummary);
