/* eslint-disable max-len */
import { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const Koala: FC<SvgIconProps> = (props) => (
  <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M23.59 15.7621C20.12 15.7621 17.29 12.9221 17.29 9.46211C17.29 5.99211 20.13 3.16211 23.59 3.16211C27.06 3.16211 29.89 6.00211 29.89 9.46211C29.89 12.9321 27.06 15.7621 23.59 15.7621Z"
      fill="url(#paint0_linear_64_90080)"
    />
    <path
      d="M23.59 15.7621C20.12 15.7621 17.29 12.9221 17.29 9.46211C17.29 5.99211 20.13 3.16211 23.59 3.16211C27.06 3.16211 29.89 6.00211 29.89 9.46211C29.89 12.9321 27.06 15.7621 23.59 15.7621Z"
      fill="url(#paint1_radial_64_90080)"
    />
    <path
      d="M23.59 15.7621C20.12 15.7621 17.29 12.9221 17.29 9.46211C17.29 5.99211 20.13 3.16211 23.59 3.16211C27.06 3.16211 29.89 6.00211 29.89 9.46211C29.89 12.9321 27.06 15.7621 23.59 15.7621Z"
      fill="url(#paint2_radial_64_90080)"
    />
    <path
      d="M23.59 15.7621C20.12 15.7621 17.29 12.9221 17.29 9.46211C17.29 5.99211 20.13 3.16211 23.59 3.16211C27.06 3.16211 29.89 6.00211 29.89 9.46211C29.89 12.9321 27.06 15.7621 23.59 15.7621Z"
      fill="url(#paint3_linear_64_90080)"
    />
    <path
      d="M23.59 15.7621C20.12 15.7621 17.29 12.9221 17.29 9.46211C17.29 5.99211 20.13 3.16211 23.59 3.16211C27.06 3.16211 29.89 6.00211 29.89 9.46211C29.89 12.9321 27.06 15.7621 23.59 15.7621Z"
      fill="url(#paint4_linear_64_90080)"
    />
    <path
      d="M23.12 14.8537C20.44 14.8537 18.24 12.6638 18.24 9.97375C18.24 7.29375 20.43 5.09375 23.12 5.09375C25.8 5.09375 28 7.28375 28 9.97375C28 12.6538 25.8 14.8537 23.12 14.8537Z"
      fill="url(#paint5_radial_64_90080)"
    />
    <path
      d="M23.12 14.8537C20.44 14.8537 18.24 12.6638 18.24 9.97375C18.24 7.29375 20.43 5.09375 23.12 5.09375C25.8 5.09375 28 7.28375 28 9.97375C28 12.6538 25.8 14.8537 23.12 14.8537Z"
      fill="url(#paint6_radial_64_90080)"
    />
    <path
      d="M8.41011 15.7621C4.95011 15.7621 2.11011 12.9321 2.11011 9.46211C2.11011 6.00211 4.95011 3.16211 8.41011 3.16211C11.8801 3.16211 14.7101 6.00211 14.7101 9.46211C14.7101 12.9321 11.8801 15.7621 8.41011 15.7621Z"
      fill="url(#paint7_linear_64_90080)"
    />
    <path
      d="M8.41011 15.7621C4.95011 15.7621 2.11011 12.9321 2.11011 9.46211C2.11011 6.00211 4.95011 3.16211 8.41011 3.16211C11.8801 3.16211 14.7101 6.00211 14.7101 9.46211C14.7101 12.9321 11.8801 15.7621 8.41011 15.7621Z"
      fill="url(#paint8_radial_64_90080)"
    />
    <path
      d="M8.41011 15.7621C4.95011 15.7621 2.11011 12.9321 2.11011 9.46211C2.11011 6.00211 4.95011 3.16211 8.41011 3.16211C11.8801 3.16211 14.7101 6.00211 14.7101 9.46211C14.7101 12.9321 11.8801 15.7621 8.41011 15.7621Z"
      fill="url(#paint9_linear_64_90080)"
    />
    <path
      d="M8.83996 14.8537C6.15996 14.8537 3.95996 12.6638 3.95996 9.97375C3.95996 7.29375 6.14996 5.09375 8.83996 5.09375C11.52 5.09375 13.72 7.28375 13.72 9.97375C13.72 12.6538 11.52 14.8537 8.83996 14.8537Z"
      fill="url(#paint10_radial_64_90080)"
    />
    <path
      d="M8.83996 14.8537C6.15996 14.8537 3.95996 12.6638 3.95996 9.97375C3.95996 7.29375 6.14996 5.09375 8.83996 5.09375C11.52 5.09375 13.72 7.28375 13.72 9.97375C13.72 12.6538 11.52 14.8537 8.83996 14.8537Z"
      fill="url(#paint11_radial_64_90080)"
    />
    <path
      d="M27.39 18.1231L24.87 10.8431L24.86 10.8731C23.52 7.27313 20.07 4.70312 16 4.70312C11.93 4.70312 8.47995 7.27313 7.13995 10.8731L7.11995 10.8431L4.61995 18.0731C4.2193 19.1 3.94995 19.9731 3.94995 21.0431C3.94995 24.8731 7.01995 27.9731 10.83 28.0431V28.0531H21.02C24.8899 28.0531 28.03 24.9131 28.03 21.0431C28.04 20.0031 27.7349 19.1 27.39 18.1231Z"
      fill="url(#paint12_linear_64_90080)"
    />
    <path
      d="M27.39 18.1231L24.87 10.8431L24.86 10.8731C23.52 7.27313 20.07 4.70312 16 4.70312C11.93 4.70312 8.47995 7.27313 7.13995 10.8731L7.11995 10.8431L4.61995 18.0731C4.2193 19.1 3.94995 19.9731 3.94995 21.0431C3.94995 24.8731 7.01995 27.9731 10.83 28.0431V28.0531H21.02C24.8899 28.0531 28.03 24.9131 28.03 21.0431C28.04 20.0031 27.7349 19.1 27.39 18.1231Z"
      fill="url(#paint13_radial_64_90080)"
    />
    <path
      d="M27.39 18.1231L24.87 10.8431L24.86 10.8731C23.52 7.27313 20.07 4.70312 16 4.70312C11.93 4.70312 8.47995 7.27313 7.13995 10.8731L7.11995 10.8431L4.61995 18.0731C4.2193 19.1 3.94995 19.9731 3.94995 21.0431C3.94995 24.8731 7.01995 27.9731 10.83 28.0431V28.0531H21.02C24.8899 28.0531 28.03 24.9131 28.03 21.0431C28.04 20.0031 27.7349 19.1 27.39 18.1231Z"
      fill="url(#paint14_radial_64_90080)"
    />
    <path
      d="M27.39 18.1231L24.87 10.8431L24.86 10.8731C23.52 7.27313 20.07 4.70312 16 4.70312C11.93 4.70312 8.47995 7.27313 7.13995 10.8731L7.11995 10.8431L4.61995 18.0731C4.2193 19.1 3.94995 19.9731 3.94995 21.0431C3.94995 24.8731 7.01995 27.9731 10.83 28.0431V28.0531H21.02C24.8899 28.0531 28.03 24.9131 28.03 21.0431C28.04 20.0031 27.7349 19.1 27.39 18.1231Z"
      fill="url(#paint15_radial_64_90080)"
    />
    <path
      d="M27.39 18.1231L24.87 10.8431L24.86 10.8731C23.52 7.27313 20.07 4.70312 16 4.70312C11.93 4.70312 8.47995 7.27313 7.13995 10.8731L7.11995 10.8431L4.61995 18.0731C4.2193 19.1 3.94995 19.9731 3.94995 21.0431C3.94995 24.8731 7.01995 27.9731 10.83 28.0431V28.0531H21.02C24.8899 28.0531 28.03 24.9131 28.03 21.0431C28.04 20.0031 27.7349 19.1 27.39 18.1231Z"
      fill="url(#paint16_radial_64_90080)"
    />
    <path
      d="M27.39 18.1231L24.87 10.8431L24.86 10.8731C23.52 7.27313 20.07 4.70312 16 4.70312C11.93 4.70312 8.47995 7.27313 7.13995 10.8731L7.11995 10.8431L4.61995 18.0731C4.2193 19.1 3.94995 19.9731 3.94995 21.0431C3.94995 24.8731 7.01995 27.9731 10.83 28.0431V28.0531H21.02C24.8899 28.0531 28.03 24.9131 28.03 21.0431C28.04 20.0031 27.7349 19.1 27.39 18.1231Z"
      fill="url(#paint17_radial_64_90080)"
    />
    <path
      d="M27.39 18.1231L24.87 10.8431L24.86 10.8731C23.52 7.27313 20.07 4.70312 16 4.70312C11.93 4.70312 8.47995 7.27313 7.13995 10.8731L7.11995 10.8431L4.61995 18.0731C4.2193 19.1 3.94995 19.9731 3.94995 21.0431C3.94995 24.8731 7.01995 27.9731 10.83 28.0431V28.0531H21.02C24.8899 28.0531 28.03 24.9131 28.03 21.0431C28.04 20.0031 27.7349 19.1 27.39 18.1231Z"
      fill="url(#paint18_linear_64_90080)"
    />
    <path
      d="M27.39 18.1231L24.87 10.8431L24.86 10.8731C23.52 7.27313 20.07 4.70312 16 4.70312C11.93 4.70312 8.47995 7.27313 7.13995 10.8731L7.11995 10.8431L4.61995 18.0731C4.2193 19.1 3.94995 19.9731 3.94995 21.0431C3.94995 24.8731 7.01995 27.9731 10.83 28.0431V28.0531H21.02C24.8899 28.0531 28.03 24.9131 28.03 21.0431C28.04 20.0031 27.7349 19.1 27.39 18.1231Z"
      fill="url(#paint19_linear_64_90080)"
    />
    <path
      d="M27.39 18.1231L24.87 10.8431L24.86 10.8731C23.52 7.27313 20.07 4.70312 16 4.70312C11.93 4.70312 8.47995 7.27313 7.13995 10.8731L7.11995 10.8431L4.61995 18.0731C4.2193 19.1 3.94995 19.9731 3.94995 21.0431C3.94995 24.8731 7.01995 27.9731 10.83 28.0431V28.0531H21.02C24.8899 28.0531 28.03 24.9131 28.03 21.0431C28.04 20.0031 27.7349 19.1 27.39 18.1231Z"
      fill="url(#paint20_linear_64_90080)"
    />
    <g filter="url(#filter0_f_64_90080)">
      <ellipse
        cx="21.1483"
        cy="14.5641"
        rx="4.8097"
        ry="8.29683"
        transform="rotate(-21.0527 21.1483 14.5641)"
        fill="url(#paint21_radial_64_90080)"
      />
    </g>
    <g filter="url(#filter1_f_64_90080)">
      <path
        d="M21.553 18.09C21.1598 18.09 20.8381 17.7683 20.8381 17.3751V15.9707C20.8381 15.5775 21.1598 15.2559 21.553 15.2559C21.9461 15.2559 22.2678 15.5775 22.2678 15.9707V17.3751C22.2678 17.7683 21.9461 18.09 21.553 18.09Z"
        fill="#8C8C95"
      />
    </g>
    <g filter="url(#filter2_f_64_90080)">
      <path
        d="M9.67652 18.09C9.28335 18.09 8.96167 17.7683 8.96167 17.3751V15.9707C8.96167 15.5775 9.28335 15.2559 9.67652 15.2559C10.0697 15.2559 10.3914 15.5775 10.3914 15.9707V17.3751C10.3914 17.7683 10.0697 18.09 9.67652 18.09Z"
        fill="#8C8C95"
      />
    </g>
    <path
      d="M9.96744 18.0914C9.55593 18.0914 9.21924 17.7547 9.21924 17.3432V15.8732C9.21924 15.4617 9.55593 15.125 9.96744 15.125C10.3789 15.125 10.7156 15.4617 10.7156 15.8732V17.3432C10.7156 17.7547 10.3789 18.0914 9.96744 18.0914Z"
      fill="url(#paint22_linear_64_90080)"
    />
    <path
      d="M9.96744 18.0914C9.55593 18.0914 9.21924 17.7547 9.21924 17.3432V15.8732C9.21924 15.4617 9.55593 15.125 9.96744 15.125C10.3789 15.125 10.7156 15.4617 10.7156 15.8732V17.3432C10.7156 17.7547 10.3789 18.0914 9.96744 18.0914Z"
      fill="url(#paint23_linear_64_90080)"
    />
    <path
      d="M9.96744 18.0914C9.55593 18.0914 9.21924 17.7547 9.21924 17.3432V15.8732C9.21924 15.4617 9.55593 15.125 9.96744 15.125C10.3789 15.125 10.7156 15.4617 10.7156 15.8732V17.3432C10.7156 17.7547 10.3789 18.0914 9.96744 18.0914Z"
      fill="url(#paint24_radial_64_90080)"
    />
    <path
      d="M21.9128 18.0914C21.5012 18.0914 21.1646 17.7547 21.1646 17.3432V15.8732C21.1646 15.4617 21.5012 15.125 21.9128 15.125C22.3243 15.125 22.6609 15.4617 22.6609 15.8732V17.3432C22.6609 17.7547 22.3243 18.0914 21.9128 18.0914Z"
      fill="url(#paint25_linear_64_90080)"
    />
    <path
      d="M21.9128 18.0914C21.5012 18.0914 21.1646 17.7547 21.1646 17.3432V15.8732C21.1646 15.4617 21.5012 15.125 21.9128 15.125C22.3243 15.125 22.6609 15.4617 22.6609 15.8732V17.3432C22.6609 17.7547 22.3243 18.0914 21.9128 18.0914Z"
      fill="url(#paint26_linear_64_90080)"
    />
    <path
      d="M21.9128 18.0914C21.5012 18.0914 21.1646 17.7547 21.1646 17.3432V15.8732C21.1646 15.4617 21.5012 15.125 21.9128 15.125C22.3243 15.125 22.6609 15.4617 22.6609 15.8732V17.3432C22.6609 17.7547 22.3243 18.0914 21.9128 18.0914Z"
      fill="url(#paint27_radial_64_90080)"
    />
    <path
      d="M15.94 26.1244C13.9 26.1244 12.24 24.4544 12.24 22.4244V19.6844C12.24 17.6444 13.91 15.9844 15.94 15.9844C17.98 15.9844 19.64 17.6544 19.64 19.6844V22.4244C19.64 24.4544 17.97 26.1244 15.94 26.1244Z"
      fill="url(#paint28_radial_64_90080)"
    />
    <path
      d="M15.94 26.1244C13.9 26.1244 12.24 24.4544 12.24 22.4244V19.6844C12.24 17.6444 13.91 15.9844 15.94 15.9844C17.98 15.9844 19.64 17.6544 19.64 19.6844V22.4244C19.64 24.4544 17.97 26.1244 15.94 26.1244Z"
      fill="url(#paint29_radial_64_90080)"
    />
    <path
      d="M15.94 26.1244C13.9 26.1244 12.24 24.4544 12.24 22.4244V19.6844C12.24 17.6444 13.91 15.9844 15.94 15.9844C17.98 15.9844 19.64 17.6544 19.64 19.6844V22.4244C19.64 24.4544 17.97 26.1244 15.94 26.1244Z"
      fill="url(#paint30_linear_64_90080)"
    />
    <path
      d="M15.94 26.1244C13.9 26.1244 12.24 24.4544 12.24 22.4244V19.6844C12.24 17.6444 13.91 15.9844 15.94 15.9844C17.98 15.9844 19.64 17.6544 19.64 19.6844V22.4244C19.64 24.4544 17.97 26.1244 15.94 26.1244Z"
      fill="url(#paint31_radial_64_90080)"
      fillOpacity="0.5"
    />
    <path
      d="M15.94 26.1244C13.9 26.1244 12.24 24.4544 12.24 22.4244V19.6844C12.24 17.6444 13.91 15.9844 15.94 15.9844C17.98 15.9844 19.64 17.6544 19.64 19.6844V22.4244C19.64 24.4544 17.97 26.1244 15.94 26.1244Z"
      fill="url(#paint32_radial_64_90080)"
    />
    <g filter="url(#filter3_f_64_90080)">
      <rect x="9.96948" y="15.5762" width="0.421875" height="2.00937" rx="0.210938" fill="#76676A" />
    </g>
    <g filter="url(#filter4_f_64_90080)">
      <rect x="21.9126" y="15.5762" width="0.421875" height="2.00937" rx="0.210938" fill="#76676A" />
    </g>
    <defs>
      <filter
        id="filter0_f_64_90080"
        x="12.7598"
        y="3.62891"
        width="16.7771"
        height="21.8711"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur_64_90080" />
      </filter>
      <filter
        id="filter1_f_64_90080"
        x="20.3381"
        y="14.7559"
        width="2.42969"
        height="3.83398"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_64_90080" />
      </filter>
      <filter
        id="filter2_f_64_90080"
        x="8.46167"
        y="14.7559"
        width="2.42969"
        height="3.83398"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_64_90080" />
      </filter>
      <filter
        id="filter3_f_64_90080"
        x="9.46948"
        y="15.0762"
        width="1.42188"
        height="3.00977"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_64_90080" />
      </filter>
      <filter
        id="filter4_f_64_90080"
        x="21.4126"
        y="15.0762"
        width="1.42188"
        height="3.00977"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_64_90080" />
      </filter>
      <linearGradient
        id="paint0_linear_64_90080"
        x1="22.36"
        y1="11.8027"
        x2="27.8518"
        y2="3.92021"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9C9EA1" />
        <stop offset="1" stopColor="#B9BABE" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_64_90080"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(28.4225 5.92773) rotate(142.696) scale(2.47507 4.71577)"
      >
        <stop stopColor="#D9D9DE" />
        <stop offset="1" stopColor="#D9D9DE" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_64_90080"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(22.36 11.9277) rotate(-53.1301) scale(6.25 9.41249)"
      >
        <stop stopColor="#93919C" />
        <stop offset="1" stopColor="#93919C" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint3_linear_64_90080"
        x1="27.1413"
        y1="14.8027"
        x2="26.4225"
        y2="11.834"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#888691" />
        <stop offset="1" stopColor="#93919C" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_64_90080"
        x1="18.8913"
        y1="3.42773"
        x2="20.485"
        y2="5.36523"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0886381" stopColor="#888691" />
        <stop offset="1" stopColor="#93919C" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint5_radial_64_90080"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(22.5475 7.99194) rotate(-17.6844) scale(4.5264 9.16671)"
      >
        <stop offset="0.00558659" stopColor="#828185" />
        <stop offset="1" stopColor="#727075" />
      </radialGradient>
      <radialGradient
        id="paint6_radial_64_90080"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(19.9225 5.92944) rotate(39.6791) scale(6.3342)"
      >
        <stop stopColor="#878688" />
        <stop offset="1" stopColor="#878688" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint7_linear_64_90080"
        x1="4.61011"
        y1="14.2402"
        x2="11.7351"
        y2="3.16211"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#86848D" />
        <stop offset="1" stopColor="#BCBEC0" />
      </linearGradient>
      <radialGradient
        id="paint8_radial_64_90080"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(13.1726 3.86523) rotate(125.628) scale(4.61358 8.7903)"
      >
        <stop stopColor="#D9D9DE" />
        <stop offset="1" stopColor="#D9D9DE" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint9_linear_64_90080"
        x1="7.48511"
        y1="15.7621"
        x2="5.86011"
        y2="11.7402"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#77767F" />
        <stop offset="1" stopColor="#77767F" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint10_radial_64_90080"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(5.17249 8.11694) rotate(20.556) scale(3.204 6.74682)"
      >
        <stop offset="0.00558659" stopColor="#828185" />
        <stop offset="1" stopColor="#727075" />
      </radialGradient>
      <radialGradient
        id="paint11_radial_64_90080"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(5.04749 9.14819) rotate(18.4349) scale(4.6446 8.38004)"
      >
        <stop stopColor="#929195" />
        <stop offset="0.461702" stopColor="#838286" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint12_linear_64_90080"
        x1="16.0001"
        y1="6.05142"
        x2="16.0001"
        y2="28.0531"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B9BBBE" />
        <stop offset="1" stopColor="#9A93A7" />
      </linearGradient>
      <radialGradient
        id="paint13_radial_64_90080"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(15.9901 28.0531) rotate(-90) scale(7.125 12.2503)"
      >
        <stop stopColor="#726387" />
        <stop offset="1" stopColor="#7D6F90" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint14_radial_64_90080"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(15.9901 30.9281) rotate(-90) scale(4.625 16.6198)"
      >
        <stop stopColor="#726387" />
        <stop offset="1" stopColor="#7D6F90" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint15_radial_64_90080"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(28.6099 12.8656) rotate(162.316) scale(13.5792 14.4646)"
      >
        <stop stopColor="#E0E1E6" />
        <stop offset="1" stopColor="#E0E1E6" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint16_radial_64_90080"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(7.29742 14.9906) rotate(24.479) scale(8.44675 12.1971)"
      >
        <stop stopColor="#A3A3A9" />
        <stop offset="1" stopColor="#9F9FA6" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint17_radial_64_90080"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(9.98492 4.24063) rotate(61.7712) scale(6.739 14.9033)"
      >
        <stop stopColor="#A3A3A9" />
        <stop offset="1" stopColor="#9F9FA6" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint18_linear_64_90080"
        x1="4.3443"
        y1="16.6625"
        x2="5.6568"
        y2="17.1156"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BDBEC0" />
        <stop offset="1" stopColor="#BDBEC0" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint19_linear_64_90080"
        x1="14.9849"
        y1="29.6156"
        x2="14.9849"
        y2="27.0531"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BDBEC0" />
        <stop offset="0.790698" stopColor="#BDBEC0" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint20_linear_64_90080"
        x1="19.1724"
        y1="4.70313"
        x2="18.0474"
        y2="6.36563"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BDBEC0" />
        <stop offset="1" stopColor="#BDBEC0" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint21_radial_64_90080"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(24.1243 18.4802) rotate(-84.7504) scale(15.364 7.51662)"
      >
        <stop stopColor="#E0E1E6" />
        <stop offset="1" stopColor="#E0E1E6" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint22_linear_64_90080"
        x1="10.7156"
        y1="16.4228"
        x2="9.84944"
        y2="16.4228"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.225" stopColor="#594C4F" />
        <stop offset="1" stopColor="#392833" />
      </linearGradient>
      <linearGradient
        id="paint23_linear_64_90080"
        x1="10.9494"
        y1="17.9523"
        x2="10.9494"
        y2="16.5391"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#492847" />
        <stop offset="1" stopColor="#552D52" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint24_radial_64_90080"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(10.1283 16.8418) rotate(-104.91) scale(1.73699 1.87928)"
      >
        <stop offset="0.813951" stopColor="#594C4F" stopOpacity="0" />
        <stop offset="1" stopColor="#594C4F" />
      </radialGradient>
      <linearGradient
        id="paint25_linear_64_90080"
        x1="22.6609"
        y1="16.4228"
        x2="21.7947"
        y2="16.4228"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.225" stopColor="#594C4F" />
        <stop offset="1" stopColor="#392833" />
      </linearGradient>
      <linearGradient
        id="paint26_linear_64_90080"
        x1="22.8948"
        y1="17.9523"
        x2="22.8948"
        y2="16.5391"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#492847" />
        <stop offset="1" stopColor="#552D52" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint27_radial_64_90080"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(22.0736 16.8418) rotate(-104.91) scale(1.73699 1.87928)"
      >
        <stop offset="0.813951" stopColor="#594C4F" stopOpacity="0" />
        <stop offset="1" stopColor="#594C4F" />
      </radialGradient>
      <radialGradient
        id="paint28_radial_64_90080"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17.36 17.6793) rotate(90) scale(8.44503)"
      >
        <stop stopColor="#735C88" />
        <stop offset="0.821489" stopColor="#3C2F49" />
      </radialGradient>
      <radialGradient
        id="paint29_radial_64_90080"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17.3912 18.4293) rotate(134.256) scale(1.70162 2.14052)"
      >
        <stop stopColor="#7F6E91" />
        <stop offset="1" stopColor="#7F6E91" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint30_linear_64_90080"
        x1="11.3912"
        y1="23.5856"
        x2="15.7037"
        y2="23.5856"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5A5163" stopOpacity="0" />
        <stop offset="0.369565" stopColor="#5A5163" />
        <stop offset="1" stopColor="#5A5163" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint31_radial_64_90080"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17.4225 22.6793) rotate(90) scale(3.44503 2.75803)"
      >
        <stop stopColor="#6E6379" />
        <stop offset="1" stopColor="#6E6379" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint32_radial_64_90080"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(15.94 13.7106) rotate(90) scale(12.5781 7.47202)"
      >
        <stop offset="0.940244" stopColor="#4B4155" stopOpacity="0" />
        <stop offset="1" stopColor="#60566A" />
      </radialGradient>
    </defs>
  </SvgIcon>
);

export default Koala;
