/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';

import {
  FAILURE,
  INVALIDATE,
  REQUEST,
  SUCCESS
} from '../../actions/utils';

import genericReducers from '../genericReducer';
import { IReducers } from '..';
import { ICommonResponse, StudentGiaWorldSkills } from '../../api/types';
import { GET_STUDENT_GIA_WORLDSKILLS } from './../../actions/students';

export type StudentGiaWorldSkillsState = ICommonResponse<StudentGiaWorldSkills>;

const initialState: StudentGiaWorldSkillsState = {
  content: [],
  loading: false,
};

const studentGiaWorldSkills: IReducers<StudentGiaWorldSkillsState> = {
  [GET_STUDENT_GIA_WORLDSKILLS[REQUEST]]: genericReducers().get.request,
  [GET_STUDENT_GIA_WORLDSKILLS[SUCCESS]]: genericReducers().get.success,
  [GET_STUDENT_GIA_WORLDSKILLS[FAILURE]]: genericReducers().get.failure,
  [GET_STUDENT_GIA_WORLDSKILLS[INVALIDATE]]: genericReducers().get.invalidate(initialState),
};

export default connectReducers(initialState, studentGiaWorldSkills);
