/* eslint-disable prettier/prettier */
/* eslint-disable */

import { ISectionTheme } from '../../../components/redesignStudent/StudentsInterests/types';
import {
  animalDesktopMainImage,
  animalDesktopSecondaryImage,
  animalLeftImage,
  animalMobileMainImage,
  animalMobileSecondaryImage,
  animalRightImage,
  animalsAndPlantsImage,
  artAndDesignImage,
  artDesktopMainImage,
  artDesktopSecondaryImage,
  artLeftImage,
  artMobileMainImage,
  artMobileSecondaryImage,
  artRightImage,
  circusDesktopMainImage,
  circusDesktopSecondaryImage,
  circusLeftImage,
  circusMobileMainImage,
  circusMobileSecondaryImage,
  circusRightImage,
  civicDesktopMainImage,
  civicDesktopSecondaryImage,
  civicEngagementImage,
  civicLeftImage,
  civicMobileMainImage,
  civicMobileSecondaryImage,
  civicRightImage,
  collectingDesktopMainImage,
  collectingDesktopSecondaryImage,
  collectingImage,
  collectingLeftImage,
  collectingMobileMainImage,
  collectingMobileSecondaryImage,
  collectingRightImage,
  cookingDesktopMainImage,
  cookingDesktopSecondaryImage,
  cookingImage,
  cookingLeftImage,
  cookingMobileMainImage,
  cookingMobileSecondaryImage,
  cookingRightImage,
  cultureDesktopMainImage,
  cultureDesktopSecondaryImage,
  cultureMobileMainImage,
  cultureMobileSecondaryImage,
  danceDesktopMainImage,
  danceDesktopSecondaryImage,
  danceImage,
  danceLeftImage,
  danceMobileMainImage,
  danceMobileSecondaryImage,
  danceRightImage,
  fashionDesktopMainImage,
  fashionDesktopSecondaryImage,
  fashionImage,
  fashionLeftImage,
  fashionMobileMainImage,
  fashionMobileSecondaryImage,
  fashionRightImage,
  foreignLanguageImage,
  gamesDesktopMainImage,
  gamesDesktopSecondaryImage,
  gamesImage,
  gamesLeftImage,
  gamesMobileMainImage,
  gamesMobileSecondaryImage,
  gamesRightImage,
  healthyLifestyleImage,
  historyDesktopMainImage,
  historyDesktopSecondaryImage,
  historyImage,
  historyLeftImage,
  historyMobileMainImage,
  historyMobileSecondaryImage,
  historyRightImage,
  itDesktopMainImage,
  itDesktopSecondaryImage,
  itImage,
  itLeftImage,
  itMobileMainImage,
  itMobileSecondaryImage,
  itRightImage,
  languageDesktopMainImage,
  languageDesktopSecondaryImage,
  languageLeftImage,
  languageMobileMainImage,
  languageMobileSecondaryImage,
  languageRightImage,
  leisureImage,
  literatureDesktopMainImage,
  literatureDesktopSecondaryImage,
  literatureImage,
  literatureLeftImage,
  literatureMobileMainImage,
  literatureMobileSecondaryImage,
  literatureRightImage,
  musicDesktopMainImage,
  musicDesktopSecondaryImage,
  musicImage,
  musicLeftImage,
  musicMobileMainImage,
  musicMobileSecondaryImage,
  musicRightImage,
  otherLeftImage,
  otherRightImage,
  photoDesktopMainImage,
  photoDesktopSecondaryImage,
  photoLeftImage,
  photoMobileMainImage,
  photoMobileSecondaryImage,
  photoRightImage,
  scienceDesktopMainImage,
  scienceDesktopSecondaryImage,
  scienceLeftImage,
  scienceMobileMainImage,
  scienceMobileSecondaryImage,
  scienceRightImage,
  sciencesAndTechnologiesImage,
  sportDesktopMainImage,
  sportDesktopSecondaryImage,
  sportImage,
  sportLeftImage,
  sportMobileMainImage,
  sportMobileSecondaryImage,
  sportRightImage,
  theatreDesktopMainImage,
  theatreDesktopSecondaryImage,
  theatreImage,
  theatreLeftImage,
  theatreMobileMainImage,
  theatreMobileSecondaryImage,
  theatreRightImage,
  thematicDesktopMainImage,
  thematicDesktopSecondaryImage,
  thematicImage,
  thematicMobileMainImage,
  thematicMobileSecondaryImage,
  tourismDesktopMainImage,
  tourismDesktopSecondaryImage,
  tourismImage,
  tourismLeftImage,
  tourismMobileMainImage,
  tourismMobileSecondaryImage,
  tourismRightImage,
  transportDesktopMainImage,
  transportDesktopSecondaryImage,
  transportLeftImage,
  transportMobileMainImage,
  transportMobileSecondaryImage,
  transportRightImage,
  videoDesktopMainImage,
  videoDesktopSecondaryImage,
  videoImage,
  videoLeftImage,
  videoMobileMainImage,
  videoMobileSecondaryImage,
  videoRightImage,
  zozhDesktopMainImage,
  zozhDesktopSecondaryImage,
  zozhLeftImage,
  zozhMobileMainImage,
  zozhMobileSecondaryImage,
  zozhRightImage,
  cosmosDesktopMainImage,
  cosmosDesktopSecondaryImage,
  cosmosMobileMainImage,
  cosmosMobileSecondaryImage,
  socialMediaDesktopMainImage,
  socialMediaDesktopSecondaryImage,
  socialMediaMobileMainImage,
  socialMediaMobileSecondaryImage
} from '../../../images';

import { Colors } from '../../../style/variables';

export const artTheme: ISectionTheme = {
  mainColor: Colors['orange-strong'],
  secondaryColor: Colors['orange-lite'],
  header: {
    backgroundColor: Colors['orange-medium'],
    titleColor: Colors['orange-ultra'],
    leftImage: artLeftImage,
    rightImage: artRightImage,
    editFormImage: artAndDesignImage,
  },
  card: {
    desktopMainImage: artDesktopMainImage,
    desktopSecondaryImage: artDesktopSecondaryImage,
    mobileMainImage: artMobileMainImage,
    mobileSecondaryImage: artMobileSecondaryImage
  }
};

export const animalTheme: ISectionTheme = {
  mainColor: Colors['teal-strong'],
  secondaryColor: Colors['teal-lite'],
  header: {
    backgroundColor: Colors['teal-medium'],
    titleColor: Colors['teal-ultra'],
    leftImage: animalLeftImage,
    rightImage: animalRightImage,
    editFormImage: animalsAndPlantsImage
  },
  card: {
    desktopMainImage: animalDesktopMainImage,
    desktopSecondaryImage: animalDesktopSecondaryImage,
    mobileMainImage: animalMobileMainImage,
    mobileSecondaryImage: animalMobileSecondaryImage
  }
};

export const circusTheme: ISectionTheme = {
  mainColor: Colors['orange-strong'],
  secondaryColor: Colors['orange-lite'],
  header: {
    backgroundColor: Colors['orange-medium'],
    titleColor: Colors['orange-ultra'],
    leftImage: circusLeftImage,
    rightImage: circusRightImage,
    editFormImage: theatreImage
  },
  card: {
    desktopMainImage: circusDesktopMainImage,
    desktopSecondaryImage: circusDesktopSecondaryImage,
    mobileMainImage: circusMobileMainImage,
    mobileSecondaryImage: circusMobileSecondaryImage
  }
};

export const musicTheme: ISectionTheme = {
  mainColor: Colors['orange-strong'],
  secondaryColor: Colors['orange-lite'],
  header: {
    backgroundColor: Colors['orange-medium'],
    titleColor: Colors['orange-ultra'],
    leftImage: musicLeftImage,
    rightImage: musicRightImage,
    editFormImage: musicImage
  },
  card: {
    desktopMainImage: musicDesktopMainImage,
    desktopSecondaryImage: musicDesktopSecondaryImage,
    mobileMainImage: musicMobileMainImage,
    mobileSecondaryImage: musicMobileSecondaryImage
  }
};

export const civicTheme: ISectionTheme = {
  mainColor: Colors['green-strong'],
  secondaryColor: Colors['green-lite'],
  header: {
    backgroundColor: Colors['green-medium'],
    titleColor: Colors['green-ultra'],
    leftImage: civicLeftImage,
    rightImage: civicRightImage,
    editFormImage: civicEngagementImage
  },
  card: {
    desktopMainImage: civicDesktopMainImage,
    desktopSecondaryImage: civicDesktopSecondaryImage,
    mobileMainImage: civicMobileMainImage,
    mobileSecondaryImage: civicMobileSecondaryImage
  }
};

export const collectingTheme: ISectionTheme = {
  mainColor: Colors['red-strong'],
  secondaryColor: Colors['red-lite'],
  header: {
    backgroundColor: Colors['red-medium'],
    titleColor: Colors['red-ultra'],
    leftImage: collectingLeftImage,
    rightImage: collectingRightImage,
    editFormImage: collectingImage
  },
  card: {
    desktopMainImage: collectingDesktopMainImage,
    desktopSecondaryImage: collectingDesktopSecondaryImage,
    mobileMainImage: collectingMobileMainImage,
    mobileSecondaryImage: collectingMobileSecondaryImage
  }
};

export const scienceTheme: ISectionTheme = {
  mainColor: Colors['blue-strong'],
  secondaryColor: Colors['blue-lite'],
  header: {
    backgroundColor: Colors['blue-medium'],
    titleColor: Colors['blue-ultra'],
    leftImage: scienceLeftImage,
    rightImage: scienceRightImage,
    editFormImage: sciencesAndTechnologiesImage
  },
  card: {
    desktopMainImage: scienceDesktopMainImage,
    desktopSecondaryImage: scienceDesktopSecondaryImage,
    mobileMainImage: scienceMobileMainImage,
    mobileSecondaryImage: scienceMobileSecondaryImage
  }
};

export const cookingTheme: ISectionTheme = {
  mainColor: Colors['pink-strong'],
  secondaryColor: Colors['pink-lite'],
  header: {
    backgroundColor: Colors['pink-medium'],
    titleColor: Colors['pink-ultra'],
    leftImage: cookingLeftImage,
    rightImage: cookingRightImage,
    editFormImage: cookingImage
  },
  card: {
    desktopMainImage: cookingDesktopMainImage,
    desktopSecondaryImage: cookingDesktopSecondaryImage,
    mobileMainImage: cookingMobileMainImage,
    mobileSecondaryImage: cookingMobileSecondaryImage
  }
};

export const sportTheme: ISectionTheme = {
  mainColor: Colors['cyan-strong'],
  secondaryColor: Colors['cyan-lite'],
  header: {
    backgroundColor: Colors['cyan-medium'],
    titleColor: Colors['cyan-ultra'],
    leftImage: sportLeftImage,
    rightImage: sportRightImage,
    editFormImage: sportImage
  },
  card: {
    desktopMainImage: sportDesktopMainImage,
    desktopSecondaryImage: sportDesktopSecondaryImage,
    mobileMainImage: sportMobileMainImage,
    mobileSecondaryImage: sportMobileSecondaryImage
  }
};

export const danceTheme: ISectionTheme = {
  mainColor: Colors['orange-strong'],
  secondaryColor: Colors['orange-lite'],
  header: {
    backgroundColor: Colors['orange-medium'],
    titleColor: Colors['orange-ultra'],
    leftImage: danceLeftImage,
    rightImage: danceRightImage,
    editFormImage: danceImage
  },
  card: {
    desktopMainImage: danceDesktopMainImage,
    desktopSecondaryImage: danceDesktopSecondaryImage,
    mobileMainImage: danceMobileMainImage,
    mobileSecondaryImage: danceMobileSecondaryImage
  }
};

export const theatreTheme: ISectionTheme = {
  mainColor: Colors['orange-strong'],
  secondaryColor: Colors['orange-lite'],
  header: {
    backgroundColor: Colors['orange-medium'],
    titleColor: Colors['orange-ultra'],
    leftImage: theatreLeftImage,
    rightImage: theatreRightImage,
    editFormImage: theatreImage
  },
  card: {
    desktopMainImage: theatreDesktopMainImage,
    desktopSecondaryImage: theatreDesktopSecondaryImage,
    mobileMainImage: theatreMobileMainImage,
    mobileSecondaryImage: theatreMobileSecondaryImage
  }
};

export const fashionTheme: ISectionTheme = {
  mainColor: Colors['red-strong'],
  secondaryColor: Colors['red-lite'],
  header: {
    backgroundColor: Colors['red-medium'],
    titleColor: Colors['red-ultra'],
    leftImage: fashionLeftImage,
    rightImage: fashionRightImage,
    editFormImage: fashionImage
  },
  card: {
    desktopMainImage: fashionDesktopMainImage,
    desktopSecondaryImage: fashionDesktopSecondaryImage,
    mobileMainImage: fashionMobileMainImage,
    mobileSecondaryImage: fashionMobileSecondaryImage
  }
};

export const transportTheme: ISectionTheme = {
  mainColor: Colors['red-strong'],
  secondaryColor: Colors['red-lite'],
  header: {
    backgroundColor: Colors['red-medium'],
    titleColor: Colors['red-ultra'],
    leftImage: transportLeftImage,
    rightImage: transportRightImage,
    editFormImage: thematicImage
  },
  card: {
    desktopMainImage: transportDesktopMainImage,
    desktopSecondaryImage: transportDesktopSecondaryImage,
    mobileMainImage: transportMobileMainImage,
    mobileSecondaryImage: transportMobileSecondaryImage
  }
};

export const thematicTheme: ISectionTheme = {
  mainColor: Colors['red-strong'],
  secondaryColor: Colors['red-lite'],
  header: {
    backgroundColor: Colors['red-medium'],
    titleColor: Colors['red-ultra'],
    leftImage: transportLeftImage,
    rightImage: transportRightImage,
    editFormImage: thematicImage
  },
  card: {
    desktopMainImage: thematicDesktopMainImage,
    desktopSecondaryImage: thematicDesktopSecondaryImage,
    mobileMainImage: thematicMobileMainImage,
    mobileSecondaryImage: thematicMobileSecondaryImage
  }
};

export const historyTheme: ISectionTheme = {
  mainColor: Colors['blue-strong'],
  secondaryColor: Colors['blue-lite'],
  header: {
    backgroundColor: Colors['blue-medium'],
    titleColor: Colors['blue-ultra'],
    leftImage: historyLeftImage,
    rightImage: historyRightImage,
    editFormImage: historyImage
  },
  card: {
    desktopMainImage: historyDesktopMainImage,
    desktopSecondaryImage: historyDesktopSecondaryImage,
    mobileMainImage: historyMobileMainImage,
    mobileSecondaryImage: historyMobileSecondaryImage
  }
};

export const languageTheme: ISectionTheme = {
  mainColor: Colors['blue-strong'],
  secondaryColor: Colors['blue-lite'],
  header: {
    backgroundColor: Colors['blue-medium'],
    titleColor: Colors['blue-ultra'],
    leftImage: languageLeftImage,
    rightImage: languageRightImage,
    editFormImage: foreignLanguageImage
  },
  card: {
    desktopMainImage: languageDesktopMainImage,
    desktopSecondaryImage: languageDesktopSecondaryImage,
    mobileMainImage: languageMobileMainImage,
    mobileSecondaryImage: languageMobileSecondaryImage
  }
};

export const itTheme: ISectionTheme = {
  mainColor: Colors['blue-strong'],
  secondaryColor: Colors['blue-lite'],
  header: {
    backgroundColor: Colors['blue-medium'],
    titleColor: Colors['blue-ultra'],
    leftImage: itLeftImage,
    rightImage: itRightImage,
    editFormImage: itImage
  },
  card: {
    desktopMainImage: itDesktopMainImage,
    desktopSecondaryImage: itDesktopSecondaryImage,
    mobileMainImage: itMobileMainImage,
    mobileSecondaryImage: itMobileSecondaryImage
  }
};

export const tourismTheme: ISectionTheme = {
  mainColor: Colors['cyan-strong'],
  secondaryColor: Colors['cyan-lite'],
  header: {
    backgroundColor: Colors['cyan-medium'],
    titleColor: Colors['cyan-ultra'],
    leftImage: tourismLeftImage,
    rightImage: tourismRightImage,
    editFormImage: tourismImage
  },
  card: {
    desktopMainImage: tourismDesktopMainImage,
    desktopSecondaryImage: tourismDesktopSecondaryImage,
    mobileMainImage: tourismMobileMainImage,
    mobileSecondaryImage: tourismMobileSecondaryImage
  }
};

export const videoTheme: ISectionTheme = {
  mainColor: Colors['red-strong'],
  secondaryColor: Colors['red-lite'],
  header: {
    backgroundColor: Colors['red-medium'],
    titleColor: Colors['red-ultra'],
    leftImage: videoLeftImage,
    rightImage: videoRightImage,
    editFormImage: videoImage
  },
  card: {
    desktopMainImage: videoDesktopMainImage,
    desktopSecondaryImage: videoDesktopSecondaryImage,
    mobileMainImage: videoMobileMainImage,
    mobileSecondaryImage: videoMobileSecondaryImage
  }
};

export const socialMediaTheme: ISectionTheme = {
  mainColor: Colors['red-strong'],
  secondaryColor: Colors['red-lite'],
  header: {
    backgroundColor: Colors['red-medium'],
    titleColor: Colors['red-ultra'],
    leftImage: videoLeftImage,
    rightImage: videoRightImage,
    editFormImage: videoImage
  },
  card: {
    desktopMainImage: socialMediaDesktopMainImage,
    desktopSecondaryImage: socialMediaDesktopSecondaryImage,
    mobileMainImage: socialMediaMobileMainImage,
    mobileSecondaryImage: socialMediaMobileSecondaryImage
  }
};

export const zozhTheme: ISectionTheme = {
  mainColor: Colors['lime-strong'],
  secondaryColor: Colors['lime-lite'],
  header: {
    backgroundColor: Colors['lime-medium'],
    titleColor: Colors['lime-ultra'],
    leftImage: zozhLeftImage,
    rightImage: zozhRightImage,
    editFormImage: healthyLifestyleImage
  },
  card: {
    desktopMainImage: zozhDesktopMainImage,
    desktopSecondaryImage: zozhDesktopSecondaryImage,
    mobileMainImage: zozhMobileMainImage,
    mobileSecondaryImage: zozhMobileSecondaryImage
  }
};

export const photoTheme: ISectionTheme = {
  mainColor: Colors['orange-strong'],
  secondaryColor: Colors['orange-lite'],
  header: {
    backgroundColor: Colors['orange-medium'],
    titleColor: Colors['orange-ultra'],
    leftImage: photoLeftImage,
    rightImage: photoRightImage
  },
  card: {
    desktopMainImage: photoDesktopMainImage,
    desktopSecondaryImage: photoDesktopSecondaryImage,
    mobileMainImage: photoMobileMainImage,
    mobileSecondaryImage: photoMobileSecondaryImage
  }
};

export const literatureTheme: ISectionTheme = {
  mainColor: Colors['violet_07'],
  secondaryColor: Colors['violet_05'],
  header: {
    backgroundColor: Colors['violet_02'],
    titleColor: Colors['violet_11'],
    leftImage: literatureLeftImage,
    rightImage: literatureRightImage,
    editFormImage: literatureImage
  },
  card: {
    desktopMainImage: literatureDesktopMainImage,
    desktopSecondaryImage: literatureDesktopSecondaryImage,
    mobileMainImage: literatureMobileMainImage,
    mobileSecondaryImage: literatureMobileSecondaryImage
  }
};

export const gamesTheme: ISectionTheme = {
  mainColor: Colors['grape-strong'],
  secondaryColor: Colors['grape-lite'],
  header: {
    backgroundColor: Colors['grape-medium'],
    titleColor: Colors['grape-ultra'],
    leftImage: gamesLeftImage,
    rightImage: gamesRightImage,
    editFormImage: gamesImage
  },
  card: {
    desktopMainImage: gamesDesktopMainImage,
    desktopSecondaryImage: gamesDesktopSecondaryImage,
    mobileMainImage: gamesMobileMainImage,
    mobileSecondaryImage: gamesMobileSecondaryImage
  }
};

export const cultureTheme: ISectionTheme = {
  mainColor: Colors['red-strong'],
  secondaryColor: Colors['red-lite'],
  header: {
    backgroundColor: Colors['red-medium'],
    titleColor: Colors['red-ultra'],
    leftImage: videoLeftImage,
    rightImage: videoRightImage,
    editFormImage: leisureImage
  },
  card: {
    desktopMainImage: cultureDesktopMainImage,
    desktopSecondaryImage: cultureDesktopSecondaryImage,
    mobileMainImage: cultureMobileMainImage,
    mobileSecondaryImage: cultureMobileSecondaryImage
  }
};

export const cosmosTheme: ISectionTheme = {
  mainColor: Colors['blue-strong'],
  secondaryColor: Colors['blue-lite'],
  header: {
    backgroundColor: Colors['blue-medium'],
    titleColor: Colors['blue-ultra'],
    leftImage: historyLeftImage,
    rightImage: historyRightImage,
    editFormImage: historyImage
  },
  card: {
    desktopMainImage: cosmosDesktopMainImage,
    desktopSecondaryImage: cosmosDesktopSecondaryImage,
    mobileMainImage: cosmosMobileMainImage,
    mobileSecondaryImage: cosmosMobileSecondaryImage
  }
};

// export const otherTheme: ISectionTheme = {
//   mainColor: Colors['teal-strong'],
//   secondaryColor: Colors['teal-lite'],
//   header: {
//     backgroundColor: Colors['teal-medium'],
//     titleColor: Colors['teal-ultra'],
//     leftImage: otherLeftImage,
//     rightImage: otherRightImage
//   },
//
// };
