import { REPORT_ERROR } from '../../actions';
import { FAILURE, INVALIDATE, REQUEST, SUCCESS } from '../../actions/utils';
import { IResponseError } from '../../api/types';
import { connectReducers } from '../../utils';
import { IReducers } from '..';

export type ReportErrorState = {
  loading: boolean;
  success: boolean;
  errorMessage?: string;
};

type FailureAction = {
  payload: {
    error: IResponseError;
  };
};

const initialState: ReportErrorState = {
  loading: false,
  success: false,
  errorMessage: undefined,
};

const reportErrorStatus: IReducers<ReportErrorState> = {
  [REPORT_ERROR[REQUEST]]: () => ({
    loading: true,
    success: false,
    errorMessage: undefined,
  }),
  [REPORT_ERROR[SUCCESS]]: () => ({
    loading: false,
    success: true,
    errorMessage: undefined,
  }),
  [REPORT_ERROR[FAILURE]]: (_, action: FailureAction) => ({
    loading: false,
    success: false,
    errorMessage: action?.payload?.error?.message,
  }),
  [REPORT_ERROR[INVALIDATE]]: () => ({
    ...initialState,
  }),
};

export default connectReducers(initialState, reportErrorStatus);
