import { isDefined } from 'utils';

import { SortTypeCodes } from '../../const';
import { ILocalVuzSpeciality, ISearchVuz } from '../../types';

export const sortVuzList = (vuzes: ISearchVuz[], sortType: number): ISearchVuz[] => {
  if (sortType === SortTypeCodes.default) return vuzes;

  const copyVuzes = vuzes.map((vuz): ISearchVuz => {
    return {
      ...vuz,
      specialities: [...vuz.specialities],
    };
  });

  if (sortType === SortTypeCodes.requiredBudgetBallAsc)
    copyVuzes
      .sort(vuzSpecialitiesBudgetBallAsc)
      .forEach((vuz) => vuz.specialities.sort(specialityRequiredBudgetBallAsc));
  if (sortType === SortTypeCodes.requiredBudgetBallDesc)
    copyVuzes
      .sort(vuzSpecialitiesBudgetBallDesc)
      .forEach((vuz) => vuz.specialities.sort(specialityRequiredBudgetBallDesc));
  if (sortType === SortTypeCodes.requiredPaidBallAsc)
    copyVuzes.sort(vuzSpecialitiesPaidBallAsc).forEach((vuz) => vuz.specialities.sort(specialityRequiredPaidBallAsc));
  if (sortType === SortTypeCodes.requiredPaidBallDesc)
    copyVuzes.sort(vuzSpecialitiesPaidBallDesc).forEach((vuz) => vuz.specialities.sort(specialityRequiredPaidBallDesc));
  if (sortType === SortTypeCodes.differenceBetweenRequiredBudgetBallAndBallAsc)
    copyVuzes
      .sort((a, b) => vuzSpecialitiesDifferenceBetweenBudgetBallAndStudentBallAsc(a, b))
      .forEach((vuz) => vuz.specialities.sort((a, b) => specialityDifferenceBetweenBudgetBallAndStudentBallAsc(a, b)));
  if (sortType === SortTypeCodes.differenceBetweenRequiredBudgetBallAndBallDesc)
    copyVuzes
      .sort((a, b) => vuzSpecialitiesDifferenceBetweenBudgetBallAndStudentBallDesc(a, b))
      .forEach((vuz) => vuz.specialities.sort((a, b) => specialityDifferenceBetweenBudgetBallAndStudentBallDesc(a, b)));

  if (sortType === SortTypeCodes.entityNameAToZ) copyVuzes.sort(vuzNameAToZ);
  if (sortType === SortTypeCodes.entityNameZToA) copyVuzes.sort(vuzNameZToA);

  return copyVuzes;
};

type CompareFn<T> = (a: T, b: T) => number;
type SpecialityCompareFn = CompareFn<ILocalVuzSpeciality>;
type VuzListCompareFn = CompareFn<ISearchVuz>;
type DirectionType = 'asc' | 'desc';
type BallTypes = keyof Pick<ILocalVuzSpeciality, 'budgetMinBall' | 'paidMinBall'>;

const specialityRequiredBudgetBallAsc = compareSpecialityBallFactory('budgetMinBall', 'asc', 'basic');
const vuzSpecialitiesBudgetBallAsc = compareVuzListSpecialitiesFactory('budgetMinBall', 'asc', 'basic');

const specialityRequiredBudgetBallDesc = compareSpecialityBallFactory('budgetMinBall', 'desc', 'basic');
const vuzSpecialitiesBudgetBallDesc = compareVuzListSpecialitiesFactory('budgetMinBall', 'desc', 'basic');

const specialityRequiredPaidBallAsc = compareSpecialityBallFactory('paidMinBall', 'asc', 'basic');
const vuzSpecialitiesPaidBallAsc = compareVuzListSpecialitiesFactory('paidMinBall', 'asc', 'basic');

const specialityRequiredPaidBallDesc = compareSpecialityBallFactory('paidMinBall', 'desc', 'basic');
const vuzSpecialitiesPaidBallDesc = compareVuzListSpecialitiesFactory('paidMinBall', 'desc', 'basic');

const specialityDifferenceBetweenBudgetBallAndStudentBallAsc = compareSpecialityBallFactory(
  'budgetMinBall',
  'asc',
  'difference',
);
const vuzSpecialitiesDifferenceBetweenBudgetBallAndStudentBallAsc = compareVuzListSpecialitiesFactory(
  'budgetMinBall',
  'asc',
  'difference',
);

const specialityDifferenceBetweenBudgetBallAndStudentBallDesc = compareSpecialityBallFactory(
  'budgetMinBall',
  'desc',
  'difference',
);
const vuzSpecialitiesDifferenceBetweenBudgetBallAndStudentBallDesc = compareVuzListSpecialitiesFactory(
  'budgetMinBall',
  'desc',
  'difference',
);

const vuzNameAToZ: VuzListCompareFn = (a, b) => a.info.name.localeCompare(b.info.name);
const vuzNameZToA: VuzListCompareFn = (a, b) => b.info.name.localeCompare(a.info.name);

function compareSpecialityBallFactory(
  specialityCompareProp: BallTypes,
  direction: DirectionType,
  type: 'basic' | 'difference',
): SpecialityCompareFn {
  return function compareFn(a, b) {
    const left = type === 'basic' ? a[specialityCompareProp] : getBallDifference(a, specialityCompareProp);
    const right = type === 'basic' ? b[specialityCompareProp] : getBallDifference(b, specialityCompareProp);

    if (!isDefined(left)) return 1;
    if (!isDefined(right)) return -1;

    return direction === 'asc' ? left - right : right - left;
  };
}

function compareVuzListSpecialitiesFactory(
  vuzSpecialityCompareProp: BallTypes,
  direction: DirectionType,
  type: 'basic' | 'difference',
): VuzListCompareFn {
  return function compareFn(a, b) {
    const mapSpeciality = (speciality: ILocalVuzSpeciality): number | null => {
      return type === 'basic'
        ? speciality[vuzSpecialityCompareProp] ?? null
        : getBallDifference(speciality, vuzSpecialityCompareProp);
    };

    const valuesA = a.specialities.map(mapSpeciality);
    const valuesB = b.specialities.map(mapSpeciality);

    const isAllNullA = valuesA.every((value) => value === null);
    const isAllNullB = valuesB.every((value) => value === null);

    if (isAllNullA) return 1;
    if (isAllNullB) return -1;

    const definedValuesA = valuesA.filter(isDefined);
    const definedValuesB = valuesB.filter(isDefined);

    const groupedValueA = direction === 'asc' ? Math.min(...definedValuesA) : Math.max(...definedValuesA);
    const groupedValueB = direction === 'asc' ? Math.min(...definedValuesB) : Math.max(...definedValuesB);

    return direction === 'asc' ? groupedValueA - groupedValueB : groupedValueB - groupedValueA;
  };
}

function getBallDifference(speciality: ILocalVuzSpeciality, vuzSpecialityCompareProp: BallTypes) {
  const specialityBall = speciality[vuzSpecialityCompareProp];
  const studentGiaBall = speciality.studentSubjectsBallSummary;

  if (!isDefined(specialityBall) || !isDefined(studentGiaBall)) {
    return null;
  }

  const difference = specialityBall - studentGiaBall;
  return difference;
}
