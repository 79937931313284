import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { getAccentColor } from 'portfolio3/styles';
import { createSelector } from 'reselect';

import {
  clearAdminsSettingsAction,
  getAdminFunctionSettingsActions,
  getAdminSettingsActions,
  getLearnerCategoriesActions,
  getSettingSectionsActions,
  saveAdminSettingsActions,
} from '../../../actions';
import { MAX_PAGE_SIZE } from '../../../api';
import { IAdminFunctionSettingResponse, ICommonResponse, IDictionaryItem } from '../../../api/types';
import { SettingBlock } from '../../../components/admin';
import SearchSettingBlock from '../../../components/admin/SettingBlock/searchSetting';
import { AdminSectionSettingIcons } from '../../../const';
import { IRootState } from '../../../reducers';
import {
  getAdminCategoryAccentColor,
  getSortedParentAdminSections,
  syncIndependentDiagnosticAdminSettings,
} from '../../../utils';
import { IAdminVisibilitySetting, IVisibilityChildSetting } from './types';

import './index.scss';

interface IVisibilitySettingsProps {
  adminSettings: ICommonResponse<IAdminVisibilitySetting>;
  adminFunctionSettings: IAdminFunctionSettingResponse;
  learnerCategories: ICommonResponse<IDictionaryItem>;
  getAdminSettings: typeof getAdminSettingsActions.request;
  getAdminFunctionSettings: typeof getAdminFunctionSettingsActions.request;
  getLearnerCategories: typeof getLearnerCategoriesActions.request;
  getSettingsSections: typeof getSettingSectionsActions.request;
  saveAdminSettings: typeof saveAdminSettingsActions.request;
  clearAdminSettings: typeof clearAdminsSettingsAction;
}

const AdminVisibilitySettingsContainer: React.FC<IVisibilitySettingsProps> = ({
  adminSettings,
  adminFunctionSettings,
  learnerCategories,
  getAdminSettings,
  getAdminFunctionSettings,
  getLearnerCategories,
  getSettingsSections,
  saveAdminSettings,
  clearAdminSettings,
}) => {
  const [visibilitySettings, setVisibilitySettings] = useState<IAdminVisibilitySetting[]>([]);
  const [isVisibilitySettingChanged, setVisibilitySettingChanged] = useState(false);
  const parallelOptions = learnerCategories.content?.map((category) => String(category.code)) || [];

  useEffect(() => {
    clearAdminSettings();
    getLearnerCategories({ size: MAX_PAGE_SIZE });
    getSettingsSections({ size: MAX_PAGE_SIZE });
    getAdminSettings();
    getAdminFunctionSettings('Search');
    return () => {
      clearAdminSettings();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Обратный маппинг для отправки на сервер
  const getReverseMappedVisibilitySettings = (visibilitySettings: IAdminVisibilitySetting[]) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newVisibilitySettings: any = [];
    visibilitySettings.forEach((setting) => {
      const parentLearnerCategories = new Set<number>();

      setting.children.forEach((childSetting) => {
        const childLearnerCategories = childSetting.selectValue?.split(',');
        childLearnerCategories?.forEach((learnerCategory) => parentLearnerCategories.add(Number(learnerCategory)));

        newVisibilitySettings.push({
          sectionId: childSetting.sectionCode,
          isVisible: childSetting.isActive,
          learnerCategoryCodes: childSetting.selectValue,
          parentSectionId: childSetting.parentSectionId,
          comment: childSetting.comment,
        });
        childSetting.children?.forEach((secondaryChildSetting) => {
          const secondaryChildLearnerCategories = secondaryChildSetting.selectValue?.split(',');
          secondaryChildLearnerCategories?.forEach((learnerCategory) =>
            parentLearnerCategories.add(Number(learnerCategory)),
          );

          newVisibilitySettings.push({
            sectionId: secondaryChildSetting.sectionCode,
            isVisible: secondaryChildSetting.isActive,
            learnerCategoryCodes: secondaryChildSetting.selectValue,
            parentSectionId: secondaryChildSetting.parentSectionId,
            comment: secondaryChildSetting.comment,
          });
        });
      });

      const learnerCategories = Array.from(parentLearnerCategories)
        .sort((a, b) => a - b)
        .join(',');

      newVisibilitySettings.push({
        sectionId: setting.sectionCode,
        isVisible: setting.isActive,
        learnerCategoryCodes: learnerCategories,
        comment: setting.comment,
      });
    });
    return newVisibilitySettings;
  };

  useEffect(() => {
    const settingsToSend = getReverseMappedVisibilitySettings(visibilitySettings);
    if (isVisibilitySettingChanged) {
      saveAdminSettings(settingsToSend);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibilitySettings]);

  useEffect(() => {
    setVisibilitySettings(adminSettings.content);
  }, [adminSettings.content]);

  const handleChangeSettingActivity = (setting: IAdminVisibilitySetting, comment?: string) => {
    setVisibilitySettings((prevstate) => {
      const currentSetting = prevstate.find((searchedSetting) => searchedSetting.name === setting.name);
      if (!currentSetting) return prevstate;
      const currentSettingIndex = prevstate.findIndex((searchedSetting) => searchedSetting.name === setting.name);
      const newSetting = {
        ...currentSetting,
        isActive: !currentSetting?.isActive,
        comment: comment ?? currentSetting.comment,
        children: currentSetting.children?.map((child) => ({
          ...child,
          isActive: !currentSetting?.isActive,
          children: child.children?.map((secondaryChild) => ({
            ...secondaryChild,
            isActive: !currentSetting?.isActive,
          })),
        })),
      };

      setVisibilitySettingChanged(true);

      const newSettings = [...prevstate];

      if (!newSetting.isActive) {
        newSetting.children = newSetting.children.map((child) => ({
          ...child,
          selectValue: parallelOptions.join(','),
          children: child.children
            ? child.children.map((secondaryChild) => ({
                ...secondaryChild,
                selectValue: parallelOptions.join(','),
              }))
            : undefined,
        }));
      }
      newSettings[currentSettingIndex] = newSetting;
      return newSettings;
    });
  };

  const handleChangeChildSetting = (
    parentName: string,
    childSetting: IVisibilityChildSetting,
    comment?: string,
    selectValue?: string,
    isActivityUnchanged?: boolean,
  ) => {
    setVisibilitySettings((prevstate) => {
      const parentSetting = prevstate.find((searchedSetting) => searchedSetting.name === parentName);
      const parentSettingIndex = prevstate.findIndex((searchedSetting) => searchedSetting.name === parentName);
      const currentSetting = parentSetting?.children?.find(
        (searchedSetting) => searchedSetting.name === childSetting.name,
      );
      const currentSettingIndex = parentSetting?.children?.findIndex(
        (searchedSetting) => searchedSetting.name === childSetting.name,
      );

      if (currentSettingIndex === undefined || !parentSetting || !currentSetting) return prevstate;

      const newSetting = {
        ...currentSetting,
        isActive: isActivityUnchanged ? currentSetting.isActive : !currentSetting.isActive,
        comment: comment ?? currentSetting.comment,
        selectValue: selectValue ?? currentSetting.selectValue,
        children: currentSetting?.children?.map((secondaryChildSetting) => ({
          ...secondaryChildSetting,
          isActive: isActivityUnchanged ? secondaryChildSetting.isActive : !currentSetting.isActive,
        })),
      };

      if (newSetting.isActive && !parentSetting.isActive) {
        parentSetting.isActive = true;
      }

      if (!newSetting.isActive) {
        newSetting.selectValue = parallelOptions.join(',');
        newSetting.children = newSetting.children
          ? newSetting.children.map((child) => ({ ...child, selectValue: parallelOptions.join(',') }))
          : undefined;
      }

      parentSetting.children[currentSettingIndex] = newSetting;
      const isEveryChildSettingDisabled = parentSetting.children.every((child) => !child.isActive);
      if (isEveryChildSettingDisabled) {
        parentSetting.isActive = false;
      }

      setVisibilitySettingChanged(true);

      const newSettings = [...prevstate];
      newSettings[parentSettingIndex] = parentSetting;

      return newSettings;
    });
  };

  const handleChangeSecondaryChildSetting = (
    grandParentName: string,
    parentName: string,
    childSetting: IVisibilityChildSetting,
    comment?: string,
    selectValue?: string,
    isActivityUnchanged?: boolean,
  ) => {
    setVisibilitySettings((prevstate) => {
      const grandParentSetting = prevstate.find((searchedSetting) => searchedSetting.name === grandParentName);
      const grandParentSettingIndex = prevstate.findIndex(
        (searchedSetting) => searchedSetting.name === grandParentName,
      );
      if (!grandParentSetting) return prevstate;
      const parentSetting = grandParentSetting.children.find((searchedSetting) => searchedSetting.name === parentName);
      const parentSettingIndex = grandParentSetting.children.findIndex(
        (searchedSetting) => searchedSetting.name === parentName,
      );
      const currentSetting = parentSetting?.children?.find(
        (searchedSetting) => searchedSetting.name === childSetting.name,
      );
      const currentSettingIndex = parentSetting?.children?.findIndex(
        (searchedSetting) => searchedSetting.name === childSetting.name,
      );

      if (currentSettingIndex === undefined || !parentSetting || !parentSetting.children || !currentSetting)
        return prevstate;
      const newValueIsActive = isActivityUnchanged ? currentSetting.isActive : !currentSetting.isActive;

      parentSetting.children = syncIndependentDiagnosticAdminSettings(
        childSetting.sectionCode,
        !newValueIsActive,
        parentSetting.children,
      );

      const newSetting = {
        ...currentSetting,
        isActive: newValueIsActive,
        comment: comment ?? currentSetting.comment,
        selectValue: selectValue ?? currentSetting.selectValue,
        children:
          currentSetting.isActive && !isActivityUnchanged
            ? currentSetting?.children?.map((secondaryChildSetting) => ({
                ...secondaryChildSetting,
                isActive: false,
              }))
            : currentSetting.children,
      };

      if (newSetting.isActive && !parentSetting.isActive) {
        parentSetting.isActive = true;

        if (!grandParentSetting.isActive) {
          grandParentSetting.isActive = true;
        }
      }

      if (!newSetting.isActive) {
        newSetting.selectValue = parallelOptions.join(',');
      }

      parentSetting.children[currentSettingIndex] = newSetting;
      const isEveryChildSettingDisabled = parentSetting.children.every((child) => !child.isActive);
      if (isEveryChildSettingDisabled) {
        parentSetting.isActive = false;
        parentSetting.selectValue = parallelOptions.join(',');
      }

      grandParentSetting.children[parentSettingIndex] = parentSetting;
      const isEveryGrandChildSettingDisabled = grandParentSetting.children.every((child) => !child.isActive);
      if (isEveryGrandChildSettingDisabled) {
        grandParentSetting.isActive = false;
      }

      setVisibilitySettingChanged(true);

      const newSettings = [...prevstate];
      newSettings[grandParentSettingIndex] = grandParentSetting;

      return newSettings;
    });
  };

  return (
    <section className="visibility-settings">
      <div className="visibility-settings__content">
        {adminFunctionSettings.content?.length > 0 && (
          <SearchSettingBlock adminSettings={adminFunctionSettings.content} />
        )}
        {learnerCategories.content?.length > 0 &&
          visibilitySettings?.map((setting) => (
            <SettingBlock
              setting={setting}
              parallelOptions={learnerCategories.content || []}
              onChangeSetting={handleChangeSettingActivity}
              onChangeChildSetting={handleChangeChildSetting}
              onChangeSecondaryChildSetting={handleChangeSecondaryChildSetting}
              key={setting.name}
            />
          ))}
      </div>
    </section>
  );
};

const adminSettingsMapping = createSelector(
  [(state: IRootState) => state.adminSettings, (state: IRootState) => state.settingSections],
  (adminSettings, settingSections): ICommonResponse<IAdminVisibilitySetting> => {
    const filteredSettings =
      settingSections.content?.length > 0 && Number(adminSettings.content?.sections.length) > 0
        ? adminSettings?.content?.sections.filter((setting) => !setting.parentSectionId)
        : [];

    const sortedSettings = getSortedParentAdminSections(filteredSettings);

    const adminSettingSections = sortedSettings.map((setting): IAdminVisibilitySetting => {
      const childSettings = adminSettings?.content?.sections.filter((childSetting) => {
        return childSetting.parentSectionId === setting.sectionId;
      });

      const isParentSettingActive = childSettings?.some((childSetting) => childSetting.isVisible) ?? false;
      const accentColorType = getAdminCategoryAccentColor(setting.sectionId);

      return {
        sectionCode: setting.sectionId,
        name: settingSections.content?.find((section) => section.code === setting.sectionId)?.value || '',
        backgroundColor: getAccentColor(accentColorType, '24'),
        color: getAccentColor(accentColorType, '200'),
        Icon: AdminSectionSettingIcons[setting.sectionId],
        isActive: isParentSettingActive,
        children:
          childSettings?.map((childSetting) => {
            const secondaryChildSettings = adminSettings?.content?.sections.filter(
              (setting) => setting.parentSectionId === childSetting.sectionId,
            );
            return {
              sectionCode: childSetting.sectionId,
              parentSectionId: childSetting.parentSectionId,
              name: settingSections.content?.find((section) => section.code === childSetting.sectionId)?.value || '',
              isActive: childSetting.isVisible,
              selectValue: childSetting.learnerCategoryCodes,
              Icon: AdminSectionSettingIcons[childSetting.sectionId],
              children: secondaryChildSettings?.map((secondaryChildSetting) => ({
                sectionCode: secondaryChildSetting.sectionId,
                parentSectionId: secondaryChildSetting.parentSectionId,
                name:
                  settingSections.content?.find((section) => section.code === secondaryChildSetting.sectionId)?.value ||
                  '',
                isActive: secondaryChildSetting.isVisible,
                selectValue: secondaryChildSetting.learnerCategoryCodes,
              })),
            };
          }) || [],
      };
    });

    return {
      ...adminSettings,
      content: adminSettingSections,
    };
  },
);

const learnerCategoriesMapping = createSelector(
  [(state: IRootState) => state.learnerCategories],
  (learnerCategories) => ({
    ...learnerCategories,
    content: learnerCategories.content?.filter((category) => {
      const value = category.value.toLowerCase();
      return value.includes('параллель') || value.includes('курс');
    }),
  }),
);

export default connect(
  (state: IRootState) => ({
    adminSettings: adminSettingsMapping(state),
    adminFunctionSettings: state.adminFunctionSettings,
    learnerCategories: learnerCategoriesMapping(state),
  }),
  {
    getAdminSettings: getAdminSettingsActions.request,
    getAdminFunctionSettings: getAdminFunctionSettingsActions.request,
    getLearnerCategories: getLearnerCategoriesActions.request,
    getSettingsSections: getSettingSectionsActions.request,
    saveAdminSettings: saveAdminSettingsActions.request,
    clearAdminSettings: clearAdminsSettingsAction,
  },
)(AdminVisibilitySettingsContainer);
