import { GlobalRoleTags } from '../const';
import { IUserFunctionality } from '../types';

export const getUserFunctionality = (globalRoleTag: string): IUserFunctionality => {
  const employeeTags = [GlobalRoleTags.headTeacher, GlobalRoleTags.adminOo, GlobalRoleTags.teacher];

  return {
    isStudent: globalRoleTag === GlobalRoleTags.student,
    isParent: globalRoleTag === GlobalRoleTags.agent,
    isOperator: globalRoleTag === GlobalRoleTags.operator,
    isEmployee: employeeTags.includes(globalRoleTag),
    isAdmin: globalRoleTag === GlobalRoleTags.admin,
  };
};

export const hasRolePermission = (userLocalRoles: string[], requiredLocalRoles: string[]): boolean => {
  return userLocalRoles.some((userLocalRole) => {
    return requiredLocalRoles.includes(userLocalRole);
  });
};
