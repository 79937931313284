import { FC, useContext } from 'react';

import { Box, FormHelperText } from '@mui/material';
import {
  IErrorWithDescription,
  IPortfolioDataEntryFormErrors,
  IPortfolioEntryFormData,
} from 'portfolio3/features/dataEntryForm';
import { DayPicker } from 'portfolio3/ui-kit/datePickers';
import { BaseInputLabel, FormControl } from 'portfolio3/ui-kit/forms';
import { deepGetProperty, mergeSx, NestedKeyOf } from 'utils';

import { formControlsColumn, formControlsRow } from '../../commonStyles';
import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';
import { getDayPickerControllerFactory } from '../../controllers';
import { dataEntryDateErrorValues } from '../../dateErrors';

interface ISportAffilationDatesCombinedControlProps {
  startDateField: NestedKeyOf<Required<IPortfolioEntryFormData>>;
  endDateField: NestedKeyOf<Required<IPortfolioEntryFormData>>;
  errorField: NestedKeyOf<Required<IPortfolioDataEntryFormErrors>>;
  isFirstFieldRequired: boolean;
  startLabel: string;
  endLabel: string;
  errorDescription: string;
}

const SportAffilationDatesCombinedControl: FC<ISportAffilationDatesCombinedControlProps> = ({
  errorDescription,
  startDateField,
  endDateField,
  errorField,
  isFirstFieldRequired,
  startLabel,
  endLabel,
}) => {
  const { isMobile, inputRenderMode, inputSize, formData, formErrors, onChangeFormData, onChangeFormErrors } =
    useContext(LocalDataEntryFormContext);

  const dayPickerControllerFactory = getDayPickerControllerFactory(onChangeFormData, onChangeFormErrors);

  const startDate = deepGetProperty(formData, startDateField) as Date | undefined;
  const endDate = deepGetProperty(formData, endDateField) as Date | undefined;
  const error = deepGetProperty(formErrors, errorField) as IErrorWithDescription | undefined;

  const datesErrorValues: typeof dataEntryDateErrorValues = {
    ...dataEntryDateErrorValues,
    afterEndDate: errorDescription,
    beforeStartDate: errorDescription,
  };
  const startDateController = dayPickerControllerFactory(startDateField, errorField, {
    required: isFirstFieldRequired,
    maxDate: endDate,
    errorValues: datesErrorValues,
  });
  const endDateController = dayPickerControllerFactory(endDateField, errorField, {
    minDate: startDate,
    errorValues: datesErrorValues,
  });

  const isDatesError = error?.active;
  const datesErrorDescription = error?.description;

  const startDateControl = (
    <FormControl
      required={isFirstFieldRequired}
      renderMode={inputRenderMode}
      inputSize={inputSize}
      error={isDatesError}
      label={<BaseInputLabel>{startLabel}</BaseInputLabel>}
      control={
        <Box sx={!isMobile ? { width: '220px' } : undefined}>
          <DayPicker
            isMobile={isMobile}
            renderMode={inputRenderMode}
            size={inputSize}
            value={startDate ?? null}
            controller={startDateController}
            maxValue={endDate}
          />
        </Box>
      }
    />
  );
  const endDateControl = (
    <FormControl
      renderMode={inputRenderMode}
      inputSize={inputSize}
      error={isDatesError}
      label={<BaseInputLabel>{endLabel}</BaseInputLabel>}
      control={
        <Box sx={!isMobile ? { width: '220px' } : undefined}>
          <DayPicker
            isMobile={isMobile}
            renderMode={inputRenderMode}
            size={inputSize}
            value={endDate ?? null}
            controller={endDateController}
            minValue={startDate}
          />
        </Box>
      }
    />
  );

  return (
    <FormControl
      label={null}
      error={isDatesError}
      helper={isDatesError && <FormHelperText>{datesErrorDescription}</FormHelperText>}
      control={
        <Box sx={mergeSx(!isMobile && { width: 'max-content' }, isMobile ? formControlsColumn : formControlsRow)}>
          {startDateControl}
          {endDateControl}
        </Box>
      }
    />
  );
};

export default SportAffilationDatesCombinedControl;
