import { NeutralColors, redColors } from 'portfolio3/styles';
import { getButtonStyles } from 'portfolio3/ui-kit/utils';
import { SxStyles } from 'types';

export const buttonStyles: SxStyles = {
  ...getButtonStyles(),
};

export const root: SxStyles = (theme) => ({
  display: 'flex',
  gap: '16px',

  width: '100%',
  padding: '16px',

  border: '1px solid',
  borderColor: NeutralColors.light_neutrals_300,
  borderRadius: '12px',
  boxShadow: '0px 1px 2px 0px rgba(41, 41, 64, 0.07)',
  backgroundColor: NeutralColors.light_neutrals_0,

  svg: {
    width: '24px',
    height: '24px',
  },

  [theme.breakpoints.down('commonSm')]: {
    padding: '12px',
    gap: '12px',
  },
});

export const iconContainer: SxStyles = {
  width: '24px',
  height: '24px',
};

export const titleContainer: SxStyles = {
  flexGrow: 1,
  display: 'flex',
  gap: '8px',

  color: redColors[200],
};

export const title: SxStyles = {
  alignSelf: 'center',
  textAlign: 'left',
};
