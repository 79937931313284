import { ChangeEventHandler, FC, useRef } from 'react';

import { InputController } from 'portfolio3/ui-kit/types';
import { detectTouchDevice } from 'utils';
import { mergeSx } from 'utils';

import { UiKitInputBaseProps } from '../../types';
import Input from '../Input';
import NumberInputEndAdornment from './NumberInputEndAdornment';
import * as styles from './styles';

interface INumberInputProps extends UiKitInputBaseProps {
  controller: InputController;
  min?: number;
  max?: number;
  step?: number;
  withControls?: boolean;
  validateOnBlur?: boolean;
}

const NumberInput: FC<INumberInputProps> = ({
  controller,
  min,
  max,
  step,
  withControls = true,
  validateOnBlur,
  sx,
  ...props
}) => {
  const isTouchDevice = detectTouchDevice();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value;

    if (validateOnBlur) {
      controller.handleChange(value);
    }

    const valueNumber = Number(value);

    const isInvalidMin = min !== undefined && valueNumber < min;
    const isInvalidMax = max !== undefined && valueNumber > max;

    if (isInvalidMin || isInvalidMax) {
      event.preventDefault();
      return;
    }

    controller.handleChange(value);
  };

  const handleBlur: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    const value = event.target.value;

    if (!validateOnBlur || value.length === 0) {
      controller.handleBlur?.(value);
      return;
    }

    const valueNumber = Number(value);

    const isInvalidMin = min !== undefined && valueNumber < min;
    const isInvalidMax = max !== undefined && valueNumber > max;

    let clampedValue = valueNumber;
    if (isInvalidMin) clampedValue = min;
    if (isInvalidMax) clampedValue = max;

    const newValue = String(clampedValue);

    controller.handleChange(newValue);
    controller.handleBlur?.(newValue);
  };

  const handleStepUp = () => {
    inputRef.current?.stepUp();
    const value = inputRef.current?.value;
    controller.handleChange(value ?? '');
  };

  const handleStepDown = () => {
    inputRef.current?.stepDown();
    const value = inputRef.current?.value;
    controller.handleChange(value ?? '');
  };

  const withControlsStyles = withControls ? styles.inputRootWithControls : undefined;

  return (
    <Input
      inputProps={{
        min,
        max,
        step,
      }}
      {...props}
      sx={mergeSx(styles.inputRoot, withControlsStyles, sx)}
      inputRef={inputRef}
      type="number"
      inputMode="numeric"
      controller={controller}
      onChange={handleChange}
      onBlur={handleBlur}
      endAdornment={
        !isTouchDevice &&
        withControls && <NumberInputEndAdornment onStepUp={handleStepUp} onStepDown={handleStepDown} />
      }
    />
  );
};

export default NumberInput;
