/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useContext, useMemo, useState } from 'react';

import { connect } from 'react-redux';

import { DeleteHistoryContext } from '../../../../context';
import { ICommonResponse, IDictionaryItem } from '../../../../api/types';
import { DifferentCode } from '../../../../const';
import { getSectionBackground, getSectionIcon } from '../../../../utils';
import { IconChevronSmallUp } from 'icons';
import { IRootState } from '../../../../reducers';

import { IconSelect, IIconSelectItem, Select } from 'portfolio3/ui-kit/selects';
import { BaseInputLabel, ClearableInput, FormControl } from 'portfolio3/ui-kit';
import { CommonUiKitSize, InputRenderMode } from 'portfolio3/ui-kit/types';
import { parentSectionsArchiveFalseSelector, sectionRefArchiveFalseSelector } from 'selectors';
import { Button } from 'portfolio3/ui-kit/button';
import { DayRangePicker, DayRangePickerController } from 'portfolio3/ui-kit/datePickers';

import './index.scss';

interface IDeleteHistoryFiltersProps {
  portfolioSections: ICommonResponse<IDictionaryItem>;
  portfolioSubSections: ICommonResponse<IDictionaryItem>;
  onCloseFilters: () => void;
}

interface ILocalDeleteHistoryFilters {
  startDate?: Date | null,
  endDate?: Date | null,
  category?: number;
  subCategory?: number;
  student?: {
    lastName?: string,
    firstName?: string,
    patronymic?: string
  },
  deleter?: {
    lastName?: string,
    firstName?: string,
    patronymic?: string
  }
}

const DeleteHistoryFilters: React.FC<IDeleteHistoryFiltersProps> = ({
  portfolioSections,
  portfolioSubSections,
  onCloseFilters
}) => {
  const initialFilters: ILocalDeleteHistoryFilters = {
    startDate: null,
    endDate: null,
    category: portfolioSections.content?.length === 1 ? portfolioSections.content[0].code : DifferentCode,
    subCategory: undefined,
    student: undefined,
    deleter: undefined
  };
  const { setCurrentFilters } = useContext(DeleteHistoryContext);
  const [localFilters, setLocalFilters] = useState<ILocalDeleteHistoryFilters>(initialFilters);
  const {
    startDate, endDate, student, deleter
  } = localFilters;

  const portfolioSectionsWithIcons: IIconSelectItem[] = useMemo(() => {
    return portfolioSections.content.map((section): IIconSelectItem => ({
      ...section,
      startIcon: getSectionIcon(section.code),
      selectedIconColor: '#ffffff',
      selectedBackgroundColor: getSectionBackground(section.code),
    }))
  }, [portfolioSections]);

  const modifiedPortfolioSections = useMemo(() => ({
    loading: portfolioSections.loading,
    content:
     portfolioSections.content.length > 1
       ? [{
         code: DifferentCode, value: 'Все разделы'
       },
       ...portfolioSectionsWithIcons]
       : portfolioSectionsWithIcons
  }), [portfolioSectionsWithIcons]);

  const portfolioSubcategoryOptions = useMemo(() => portfolioSubSections.content
   ?.filter((subsection) => subsection.parentId === localFilters.category),
  [localFilters.category, portfolioSubSections]);

  const modifiedSubCategoryOptions = useMemo(() => (
    portfolioSubcategoryOptions?.length > 1 ? [
      {
        code: DifferentCode, value: 'Все разделы'
      },
      ...portfolioSubcategoryOptions]
      : portfolioSubcategoryOptions), [portfolioSubcategoryOptions]);

  const dateRangeController: DayRangePickerController = {
    handleChange(value) {
      setLocalFilters((prevState) => {
        value.end?.setHours(23, 59, 59);
        return {
          ...prevState,
          startDate: value.start,
          endDate: value.end,
        };
      });
    },
    handleClear() {
      setLocalFilters((prevState) => {
        return {
          ...prevState,
          startDate: null,
          endDate: null,
        };
      });
    },
  };

  const categoryController = {
    handleChange: (category: string | undefined) => setLocalFilters((prevstate) => ({
      ...prevstate,
      category: Number(category),
      subCategory: undefined
    }))
  };

  const subCategoryController = {
    handleChange: (subCategory: string | undefined) => setLocalFilters((prevstate) => ({
      ...prevstate,
      subCategory: Number(subCategory)
    }))
  };

  const studentLastNameController = {
    handleChange: (lastName: string) => setLocalFilters((prevstate) => ({
      ...prevstate,
      student: {
        ...prevstate.student,
        lastName
      }
    })),
    handleClear: () => setLocalFilters((prevstate) => ({
      ...prevstate,
      student: {
        ...prevstate.student,
        lastName: undefined
      }
    }))
  };

  const studentFirstNameController = {
    handleChange: (firstName: string) => setLocalFilters((prevstate) => ({
      ...prevstate,
      student: {
        ...prevstate.student,
        firstName
      }
    })),
    handleClear: () => setLocalFilters((prevstate) => ({
      ...prevstate,
      student: {
        ...prevstate.student,
        firstName: undefined
      }
    }))
  };

  const studentPatronymicController = {
    handleChange: (patronymic: string) => setLocalFilters((prevstate) => ({
      ...prevstate,
      student: {
        ...prevstate.student,
        patronymic
      }
    })),
    handleClear: () => setLocalFilters((prevstate) => ({
      ...prevstate,
      student: {
        ...prevstate.student,
        patronymic: undefined
      }
    }))
  };

  const deleterLastNameController = {
    handleChange: (lastName: string) => setLocalFilters((prevstate) => ({
      ...prevstate,
      deleter: {
        ...prevstate.deleter,
        lastName
      }
    })),
    handleClear: () => setLocalFilters((prevstate) => ({
      ...prevstate,
      deleter: {
        ...prevstate.deleter,
        lastName: undefined
      }
    }))
  };

  const deleterFirstNameController = {
    handleChange: (firstName: string) => setLocalFilters((prevstate) => ({
      ...prevstate,
      deleter: {
        ...prevstate.deleter,
        firstName
      }
    })),
    handleClear: () => setLocalFilters((prevstate) => ({
      ...prevstate,
      deleter: {
        ...prevstate.deleter,
        firstName: undefined
      }
    }))
  };

  const deleterPatronymicController = {
    handleChange: (patronymic: string) => setLocalFilters((prevstate) => ({
      ...prevstate,
      deleter: {
        ...prevstate.deleter,
        patronymic
      }
    })),
    handleClear: () => setLocalFilters((prevstate) => ({
      ...prevstate,
      deleter: {
        ...prevstate.deleter,
        patronymic: undefined
      }
    }))
  };

  const isSubcategoyDisabled = localFilters.category === DifferentCode;

  const handleClearFilters = () => {
    setLocalFilters(initialFilters);
    setCurrentFilters((prevstate) => ({
      ...prevstate,
      ...initialFilters
    }));
    onCloseFilters();
  };

  const handleSubmitFilters = () => {
    setCurrentFilters((prevstate) => ({
      ...prevstate,
      ...localFilters
    }));
    onCloseFilters();
  };

  const inputsRenderMode: InputRenderMode = 'fixed';
  const inputsSize: CommonUiKitSize = 'medium';

  return (
    <div className="delete-history-filters">
      <div className="delete-history-filters__row">
        <FormControl
          className="delete-history-filters__input delete-history-filters__input--type-lastName"
          renderMode={inputsRenderMode}
          inputSize={inputsSize}
          label={<BaseInputLabel>Учащийся</BaseInputLabel>}
          control={
            <ClearableInput
              renderMode={inputsRenderMode}
              size={inputsSize}
              placeholder="Введите фамилию учащегося..."
              value={student?.lastName}
              controller={studentLastNameController}
            />
          }
        />
        <FormControl
          className="delete-history-filters__input delete-history-filters__input--type-name"
          renderMode={inputsRenderMode}
          inputSize={inputsSize}
          label={<BaseInputLabel hidden>Имя</BaseInputLabel>}
          control={
            <ClearableInput
              renderMode={inputsRenderMode}
              size={inputsSize}
              placeholder="Введите имя учащегося..."
              value={student?.firstName}
              controller={studentFirstNameController}
            />
          }
        />
        <FormControl
          className="delete-history-filters__input delete-history-filters__input--type-patronymic"
          renderMode={inputsRenderMode}
          inputSize={inputsSize}
          label={<BaseInputLabel hidden>Отчество</BaseInputLabel>}
          control={
            <ClearableInput
              renderMode={inputsRenderMode}
              size={inputsSize}
              placeholder="Введите отчество учащегося..."
              value={student?.patronymic}
              controller={studentPatronymicController}
            />
          }
        />
      </div>
      <div className="delete-history-filters__row">
        <FormControl
          className="delete-history-filters__input delete-history-filters__input--type-lastName"
          renderMode={inputsRenderMode}
          inputSize={inputsSize}
          label={<BaseInputLabel>Кто удалил</BaseInputLabel>}
          control={
            <ClearableInput
              renderMode={inputsRenderMode}
              size={inputsSize}
              placeholder="Введите фамилию удалившего..."
              value={deleter?.lastName}
              controller={deleterLastNameController}
            />
          }
        />
        <FormControl
          className="delete-history-filters__input delete-history-filters__input--type-name"
          renderMode={inputsRenderMode}
          inputSize={inputsSize}
          label={<BaseInputLabel hidden>Имя</BaseInputLabel>}
          control={
            <ClearableInput
              renderMode={inputsRenderMode}
              size={inputsSize}
              placeholder="Введите имя удалившего..."
              value={deleter?.firstName}
              controller={deleterFirstNameController}
            />
          }
        />
        <FormControl
          className="delete-history-filters__input delete-history-filters__input--type-patronymic"
          renderMode={inputsRenderMode}
          inputSize={inputsSize}
          label={<BaseInputLabel hidden>Имя</BaseInputLabel>}
          control={
            <ClearableInput
              renderMode={inputsRenderMode}
              size={inputsSize}
              placeholder="Введите отчество удалившего..."
              value={deleter?.patronymic}
              controller={deleterPatronymicController}
            />
          }
        />
      </div>
      <div className="delete-history-filters__row">
        <FormControl
          sx={{ maxWidth: 'fit-content' }}
          renderMode={inputsRenderMode}
          inputSize={inputsSize}
          label={<BaseInputLabel>Даты удаления</BaseInputLabel>}
          control={
            <DayRangePicker
              renderMode={inputsRenderMode}
              size={inputsSize}
              startValue={startDate ?? null}
              endValue={endDate ?? null}
              controller={dateRangeController}
            />
          }
        />
        <FormControl
          className="delete-history-filters__full-width-control"
          renderMode={inputsRenderMode}
          inputSize={inputsSize}
          label={<BaseInputLabel>Раздел</BaseInputLabel>}
          control={
            <IconSelect
              inputRenderMode={inputsRenderMode}
              inputSize={inputsSize}
              placeholder="Выберите раздел"
              value={localFilters.category}
              options={modifiedPortfolioSections.content}
              controller={categoryController}
              markFirstOption
            />
          }
        />
        <FormControl
          className="delete-history-filters__full-width-control"
          disabled={isSubcategoyDisabled}
          renderMode={inputsRenderMode}
          inputSize={inputsSize}
          label={<BaseInputLabel>Подраздел</BaseInputLabel>}
          control={
            <Select
              inputRenderMode={inputsRenderMode}
              inputSize={inputsSize}
              placeholder={isSubcategoyDisabled ? 'Сначала выберите раздел' : 'Выберите подраздел'}
              value={localFilters.subCategory}
              options={modifiedSubCategoryOptions}
              controller={subCategoryController}
              markFirstOption
            />
          }
        />
      </div>
      <div className="delete-history-filters__row delete-history-filters__controls">
        <Button variant="text" iconRight={<IconChevronSmallUp />} onClick={onCloseFilters}>
          Свернуть фильтры
        </Button>
        <div className="delete-history-filters__buttons-container">
          <Button variant="secondary" onClick={handleClearFilters}>
            Сбросить
          </Button>
          <Button onClick={handleSubmitFilters}>
            Применить
          </Button>
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state: IRootState) => ({
    portfolioSections: parentSectionsArchiveFalseSelector(state),
    portfolioSubSections: sectionRefArchiveFalseSelector(state),
  })
)(DeleteHistoryFilters);
