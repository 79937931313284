/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, {
  useCallback,
  useContext, useMemo
} from 'react';

import { connect } from 'react-redux';
import { Box, FormHelperText } from '@mui/material';

import { IDictionaryItem, IChildInfo } from '../../../../../api/types';
import { DifferentValue } from '../../../../../const';
import { mergeSx, validateParallel } from '../../../../../utils';
import { IRootState } from '../../../../../reducers';
import { SecondaryBlockContainer, useSecondaryBlockControllers } from '../common';

import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';
import { BaseInputLabel, FormControl, Input, Textarea } from 'portfolio3/ui-kit';
import { Select } from 'portfolio3/ui-kit/selects';
import { IController, TokenFieldController } from 'portfolio3/ui-kit/types';
import AgeLimitControl from 'portfolio3/components/forms/AgeLimitControl';
import { StretchyTokenField } from 'portfolio3/ui-kit/autocomplete';
import { useDisclosure } from 'hooks';
import AdditionalInfoButton from 'portfolio3/components/forms/AdditionalInfoButton';
import { getCommonInputControllerFactory } from '../../utils';
import { DayRangePicker } from 'portfolio3/ui-kit/datePickers';
import { getDayRangePickerControllerFactory } from '../../controllers';
import { dataEntryMaxDate, dataEntryMinDate } from '../../dateErrors';
import { commonAcceptFileTypes } from 'portfolio3/features/dataEntryForm';

interface IAffiliationSecondaryBlockProps {
  subCategoryOptions: IDictionaryItem[];
  isSubcategoryRequired: boolean;
  subjectOptions?: IDictionaryItem[];
  isSubjectsDisabled?: boolean;
  levelOptions: IDictionaryItem[];
  currentStudent: IChildInfo;
  nameInputLabel: string;
  nameInputPlaceholder: string;
  subjectInputLabel: string;
  subjectInputPlaceholder: string;
  hasSubCategory: boolean;
  name?: string;
  withoutSubjects?: boolean;
  areSubjectsOptional?: boolean;
  children?: JSX.Element;
}

const AffiliationSecondaryBlock: React.FC<IAffiliationSecondaryBlockProps> = ({
  subCategoryOptions,
  isSubcategoryRequired,
  subjectOptions,
  isSubjectsDisabled,
  levelOptions,
  currentStudent,
  nameInputLabel,
  nameInputPlaceholder,
  subjectInputLabel,
  subjectInputPlaceholder,
  hasSubCategory,
  name,
  withoutSubjects,
  areSubjectsOptional,
  children
}) => {
  const { studentType, isMobile, inputRenderMode, inputSize, formData, formErrors, onChangeFormData, onChangeFormErrors } = useContext(LocalDataEntryFormContext);
  const { isOpen: isAdditionalInfoShownState, onToggle: onToggleAdditionalInfo } = useDisclosure();
  const isAdditionalInfoShown = isAdditionalInfoShownState || !isMobile;

  const { fileController, linkedObjects, linkedObjectController } = useSecondaryBlockControllers({
    formData,
    onChangeFormData,
    onChangeFormErrors
  });

  const createInputController = getCommonInputControllerFactory(onChangeFormData, onChangeFormErrors);
  const dayRangePickerControllerFactory = getDayRangePickerControllerFactory(onChangeFormData, onChangeFormErrors);

  const subCategoryController: IController<string | undefined> = useMemo(() => ({
    handleChange: (subCategory) => {
      onChangeFormData((prevState) => ({
        ...prevState,
        subCategory: Number(subCategory),
        creationKindCode: undefined
      }));
      onChangeFormErrors((prevState) => ({
        ...prevState,
        subcategoryError: false
      }));
    },
    handleBlur: (type) => {
      if (!type) {
        onChangeFormErrors((prevState) => ({
          ...prevState,
          subcategoryError: true
        }));
      }
    }
  }), [formData.id]);

  const secondNameController = useMemo(() => ({
    handleChange: (secondName: string) => {
      onChangeFormData((prevState) => ({
        ...prevState,
        secondName
      }));
      onChangeFormErrors((prevState) => ({
        ...prevState,
        secondNameError: false
      }));
    },
    handleBlur: (secondName: any) => {
      if (!secondName) {
        onChangeFormErrors((prevState) => ({
          ...prevState,
          secondNameError: true
        }));
      }
    }
  }), [formData.id]);

  const addressController = useMemo(() => ({
    handleChange: (address: string) => {
      onChangeFormData((prevState) => ({
        ...prevState,
        address
      }));
    }
  }), [formData.id]);

  const subjectController: TokenFieldController = useMemo(() => ({
    handleChange: (subjects) => {
      onChangeFormData((prevState) => ({
        ...prevState,
        subjectId: subjects.map((subject) => subject.code),
      }));
      onChangeFormErrors((prevState) => ({
        ...prevState,
        subjectError: subjects.length === 0,
      }));
    },
    handleBlur: (subjects) => {
      onChangeFormErrors((prevState) => ({
        ...prevState,
        subjectError: subjects.length === 0,
      }));
    },
  }), [formData.id]);

  const dateRangeController = dayRangePickerControllerFactory('startDate', 'endDate', 'dateError', { required: true });

  const classStartController = useMemo(() => ({
    handleChange: (classStart: string | undefined) => {
      const newClassStart = classStart ? validateParallel(classStart) : null;
      onChangeFormData((prevstate) => ({
        ...prevstate,
        classStart: newClassStart === undefined ? prevstate.classStart : newClassStart || undefined
      }));
    }
  }), [formData.id]);

  const classEndController = useMemo(() => ({
    handleChange: (classEnd: string | undefined) => {
      const newClassEnd = classEnd ? validateParallel(classEnd) : null;
      onChangeFormData((prevstate) => ({
        ...prevstate,
        classEnd: newClassEnd === undefined ? prevstate.classEnd : newClassEnd || undefined
      }));
    }
  }), [formData.id]);

  const descriptionController = useMemo(() => ({
    handleChange: (description: string) => {
      onChangeFormData((prevstate) => ({
        ...prevstate,
        description
      }));
    }
  }), [formData.id]);

  const eventLevelController: IController<string | undefined> = useMemo(() => ({
    handleChange: (eventLevel) => {
      onChangeFormData((prevstate) => ({
        ...prevstate,
        eventLevel: Number(eventLevel)
      }));
      onChangeFormErrors((prevstate) => ({
        ...prevstate,
        eventLevelError: false
      }));
    },
    handleBlur: (type) => {
      if (!type) {
        onChangeFormErrors((prevstate) => ({
          ...prevstate,
          eventLevelError: true
        }));
      }
    }
  }), [formData.id]);

  const statusController = useMemo(() => createInputController('status'), [formData.id]);

  return (
    <SecondaryBlockContainer>
      <SecondaryBlockContainer.FilesTab
        personId={currentStudent.meshId}
        controller={fileController}
        files={formData.attachment}
        {...commonAcceptFileTypes}
      />
      <SecondaryBlockContainer.LinkedObjectsTab
        studentType={studentType}
        linkedObjectController={linkedObjectController}
        linkedObjects={linkedObjects}
        formData={formData}
      />

      <SecondaryBlockContainer.GeneralTab>
        {hasSubCategory && (
          <FormControl
            sx={{ width: '100%' }}
            required={isSubcategoryRequired}
            error={isSubcategoryRequired ? formErrors.subcategoryError : false}
            renderMode={inputRenderMode}
            inputSize={inputSize}
            label={<BaseInputLabel>Подкатегория</BaseInputLabel>}
            helper={formErrors.subcategoryError && <FormHelperText>Выберите подкатегорию</FormHelperText>}
            control={
              <Select
                inputRenderMode={inputRenderMode}
                inputSize={inputSize}
                placeholder="Выберите подкатегорию..."
                value={formData.subCategory}
                options={subCategoryOptions}
                controller={subCategoryController}
                strictMaxWidth
              />
            }
          />
        )}
        {name?.trim() === DifferentValue && (
          <FormControl
            sx={{ width: '100%' }}
            required
            error={formErrors.secondNameError}
            renderMode={inputRenderMode}
            inputSize={inputSize}
            label={<BaseInputLabel>{nameInputLabel}</BaseInputLabel>}
            helper={formErrors.secondNameError && <FormHelperText>Введите название</FormHelperText>}
            control={
              <Input
                renderMode={inputRenderMode}
                size={inputSize}
                placeholder={nameInputPlaceholder}
                value={formData.secondName}
                controller={secondNameController}
              />
            }
          />
        )}
        {children}
        {!withoutSubjects && (
          <FormControl
            required={!areSubjectsOptional}
            disabled={isSubjectsDisabled}
            error={formErrors.subjectError && !areSubjectsOptional}
            renderMode={inputRenderMode}
            inputSize={inputSize}
            label={<BaseInputLabel>{subjectInputLabel}</BaseInputLabel>}
            helper={
              formErrors.subjectError && !areSubjectsOptional && <FormHelperText>Выберите хотя бы один предмет</FormHelperText>
            }
            control={
              <StretchyTokenField
                size={inputSize}
                inputRenderMode={inputRenderMode}
                options={subjectOptions || []}
                controller={subjectController}
                value={formData.subjectId}
                placeholder={subjectInputPlaceholder}
              />
            }
          />
        )}
        <FormControl
          required
          renderMode={inputRenderMode}
          inputSize={inputSize}
          label={
            <BaseInputLabel>
              {isMobile ? 'Даты членства' : 'Даты членства (дата вступления или интервал)'}
            </BaseInputLabel>
          }
          error={formErrors.dateError.active}
          helper={formErrors.dateError.active && <FormHelperText>{formErrors.dateError.description}</FormHelperText>}
          control={
            <Box sx={mergeSx(!isMobile && {width: 'max-content'})}>
              <DayRangePicker
                isMobile={isMobile}
                renderMode={inputRenderMode}
                size={inputSize}
                startValue={formData.startDate}
                endValue={formData.endDate}
                controller={dateRangeController}
                minValue={dataEntryMinDate}
                maxValue={dataEntryMaxDate}
              />
            </Box>
          }
        />
        <FormControl
          sx={{ width: '100%' }}
          required
          error={formErrors.eventLevelError}
          renderMode={inputRenderMode}
          inputSize={inputSize}
          label={<BaseInputLabel>Уровень объединения</BaseInputLabel>}
          helper={formErrors.eventLevelError && <FormHelperText>Выберите уровень объединения</FormHelperText>}
          control={
            <Select
              inputRenderMode={inputRenderMode}
              inputSize={inputSize}
              placeholder="Выберите уровень объединения..."
              value={formData.eventLevel}
              options={levelOptions}
              controller={eventLevelController}
            />
          }
        />

        {isMobile && <AdditionalInfoButton isOpen={isAdditionalInfoShownState} onToggle={onToggleAdditionalInfo} />}
        {isAdditionalInfoShown && (
          <>
            <FormControl
              sx={{ width: '100%' }}
              renderMode={inputRenderMode}
              inputSize={inputSize}
              label={<BaseInputLabel>Адрес объединения</BaseInputLabel>}
              control={
                <Input
                  renderMode={inputRenderMode}
                  size={inputSize}
                  placeholder="Выберите адрес объединения..."
                  value={formData.address}
                  controller={addressController}
                />
              }
            />
            <FormControl
              renderMode={inputRenderMode}
              inputSize={inputSize}
              label={<BaseInputLabel>Особый статус участника объединения</BaseInputLabel>}
              control={
                <Input
                  renderMode={inputRenderMode}
                  size={inputSize}
                  placeholder="Введите особый статус участника объединения (если есть)..."
                  value={formData.status}
                  controller={statusController}
                />
              }
            />
            <AgeLimitControl
              isMobile={isMobile}
              renderMode={inputRenderMode}
              inputSize={inputSize}
              valueFrom={formData.classStart ? formData.classStart : ''}
              valueTo={formData.classEnd ? formData.classEnd : ''}
              fromController={classStartController}
              toController={classEndController}
              label="Возрастное ограничение участников"
              endAdornment="класс"
            />
            <FormControl
              sx={{ width: '100%' }}
              renderMode={inputRenderMode}
              inputSize={inputSize}
              label={<BaseInputLabel>Описание</BaseInputLabel>}
              control={
                <Textarea
                  renderMode={inputRenderMode}
                  size={inputSize}
                  placeholder="Любое описание или примечание..."
                  value={formData.description}
                  controller={descriptionController}
                />
              }
            />
          </>
        )}
      </SecondaryBlockContainer.GeneralTab>
    </SecondaryBlockContainer>
  );
};

export default connect(
  (state: IRootState) => ({
    currentStudent: state.currentStudent
  })
)(AffiliationSecondaryBlock);
