import { FC } from 'react';
import { DateValue } from 'react-aria';

import { CalendarDate } from '@internationalized/date';
import { MobileViewDrawer } from 'portfolio3/components/drawers';

import { DayPickerComponentProps } from '../localTypes';
import { DatePickerContext } from '../shared/context';
import DatePicker from '../shared/DatePicker';
import Popover from '../shared/Popover';
import { dateToCalendarDate, dateValueToDate } from '../utils';
import DayCalendar from './Calendar';

const DayPicker: FC<DayPickerComponentProps> = ({
  value,
  controller,
  disabled,
  minValue,
  maxValue,
  isMobile,
  renderMode,
  size,
  withTodayButton,
}) => {
  let mappedValue: CalendarDate | null = null;
  const mappedMinValue = minValue ? dateToCalendarDate(minValue) : undefined;
  const mappedMaxValue = maxValue ? dateToCalendarDate(maxValue) : undefined;

  if (value) {
    mappedValue = dateToCalendarDate(value);
  }

  const handleChange = (date: DateValue) => {
    const mappedDate = dateValueToDate(date);
    controller.handleChange(mappedDate);
  };

  return (
    <DatePickerContext.Provider
      value={{
        renderMode: renderMode ?? 'fixed',
        size: size ?? 'medium',
      }}
    >
      <DatePicker
        options={{
          value: mappedValue,
          onChange: handleChange,
          onBlur: () => controller.handleBlur?.(value),
          isDisabled: disabled,
          minValue: mappedMinValue,
          maxValue: mappedMaxValue,
        }}
        onClear={controller.handleClear}
      >
        {(triggerRef, state, calendarProps) => {
          const calendarElement = (
            <DayCalendar
              datePickerState={state}
              calendarOptions={calendarProps}
              controller={controller}
              withToday={withTodayButton}
            />
          );

          if (isMobile) {
            return (
              <MobileViewDrawer title="Дата" open={state.isOpen} onClose={state.close}>
                {calendarElement}
              </MobileViewDrawer>
            );
          }

          return (
            <Popover triggerRef={triggerRef} datePickerState={state}>
              {calendarElement}
            </Popover>
          );
        }}
      </DatePicker>
    </DatePickerContext.Provider>
  );
};

export default DayPicker;
