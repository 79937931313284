import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

interface ICountBadgeProps {
  count: number;
}

const root: SxStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  padding: '2px 8px',

  borderRadius: '6px',
  backgroundColor: NeutralColors.light_neutrals_600,
};

const CountBadge: FC<ICountBadgeProps> = ({ count }) => {
  return (
    <Box sx={root}>
      <Typography variant="Paragraph MD/Medium" color={NeutralColors.light_neutrals_0}>
        {count}
      </Typography>
    </Box>
  );
};

export default CountBadge;
