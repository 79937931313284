import { FC } from 'react';

import { listItemIconClasses, MenuItem, menuItemClasses, MenuItemProps, styled, svgIconClasses } from '@mui/material';
import clsx from 'clsx';
import { getAccentColor, NeutralColors } from 'portfolio3/styles';

const StyledBaseMenuItem = styled(MenuItem)(() => {
  const iconSize = '20px';

  return {
    '&&&': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: '12px',

      minHeight: 'unset',
      padding: '8px 12px',

      borderRadius: '4px',
      backgroundColor: '#ffffff',

      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 400,
      color: NeutralColors.light_neutrals_900,

      '& .MuiTouchRipple-root': {
        display: 'none',
      },

      [`& ~ .${menuItemClasses.root}, & ~ .menu-divider`]: {
        marginTop: '4px',
      },

      [`&.${menuItemClasses.root}:hover, &.${menuItemClasses.focusVisible}`]: {
        backgroundColor: NeutralColors.light_neutrals_200,
        color: NeutralColors.light_neutrals_900,
      },

      [`&.${menuItemClasses.root}.${menuItemClasses.selected}`]: {
        backgroundColor: getAccentColor('indigo', '100'),

        fontWeight: 600,
        color: '#ffffff',
      },

      [`&.${menuItemClasses.root}.${menuItemClasses.disabled}`]: {
        opacity: 'initial',
        color: NeutralColors.light_neutrals_500,
      },

      // это размеры для базовых иконок
      // при необходимости установки других размеров
      // возможным вариантом будет переопределить размер в месте использования
      [`& .${listItemIconClasses.root}`]: {
        width: iconSize,
        height: iconSize,

        minWidth: iconSize,

        [`& .${svgIconClasses.root}`]: {
          width: iconSize,
          height: iconSize,
        },
      },
    },
  };
});

const BaseMenuItem: FC<MenuItemProps> = ({ children, ...props }) => {
  return (
    <StyledBaseMenuItem {...props} className={clsx(props.className, 'base-menu-item')}>
      {children}
    </StyledBaseMenuItem>
  );
};

export default BaseMenuItem;
