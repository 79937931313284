import { FC } from 'react';

import { Box, Typography, useMediaQuery } from '@mui/material';
import { CircularDottedLoader } from 'portfolio3/components/common/loaders';
import { commonTheme } from 'portfolio3/styles/theme';
import { SxStyles } from 'types';

const styles: SxStyles = (theme) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  padding: '16px 20px',

  [theme.breakpoints.down('commonSm')]: {
    padding: '12px 16px',
  },
});

const SearchLoader: FC = () => {
  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  return (
    <Box className="search-loader" sx={styles}>
      <CircularDottedLoader />
      <Typography variant={isMobile ? 'Paragraph MD/Bold' : 'Paragraph LG/Bold'}>
        Мы продолжаем поиск, вот что уже найдено:
      </Typography>
    </Box>
  );
};

export default SearchLoader;
