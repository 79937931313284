/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import Popper from '../../../../common/Popper';
import { Checkbox, FormControlLabel } from '../../../../../ui-kit';
import { CommonEntityActionProps } from './types';

import './index.scss';

interface IInterestActionPopperProps extends CommonEntityActionProps {}

const InterestActionPopper: React.FC<IInterestActionPopperProps> = ({
  bubblePopperData,
  theme,
  onClose,
  onToggleAction
}) => {
  if (!bubblePopperData.isOpen) return null;

  return (
    <Popper
      open={bubblePopperData.isOpen}
      anchorEl={bubblePopperData.ref}
      onClosePopperMenu={onClose}
      placement="right-start"
      className="interests-action-popper"
      disablePortal={true}
    >
      <div className="interests-action-popper__actions">
        <p className="interests-action-popper__title">Выбери действия</p>
        {bubblePopperData.actions?.map((action) => (
          <div
            key={action.id}
            className="interests-action-popper__action-item"
          >
            <FormControlLabel
              control={(
                <Checkbox
                  checked={action.isActive}
                  onChange={onToggleAction(action.id)}
                  background={theme.mainColor}
                />
              )}
              label={<p className="interests-action-popper__action-name">{action.name}</p>}
            />
          </div>
        ))}
      </div>
    </Popper>
  );
};

export default InterestActionPopper;
