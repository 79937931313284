import { ComponentProps, FC, Fragment, ReactNode } from 'react';

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useBrightTheme } from 'hooks';
import { getAccentColor, NeutralColors } from 'portfolio3/styles';
import { commonTheme } from 'portfolio3/styles/theme';
import { mergeSx } from 'utils';

import SearchSectionCardContainer from './CardContainer';
import SearchSectionDivider from './Divider';
import SearchSectionPadding from './Padding';
import * as styles from './styles';
import SearchSectionSubsection from './Subsection';

interface ISearchSectionComposition {
  Padding: FC<ComponentProps<typeof SearchSectionPadding>>;
  Divider: FC<ComponentProps<typeof SearchSectionDivider>>;
  Subsection: FC<ComponentProps<typeof SearchSectionSubsection>>;
  CardContainer: FC<ComponentProps<typeof SearchSectionCardContainer>>;
}

interface ISearchSectionProps {
  name: string;
  icon: ReactNode;
  subsections: ReactNode[];
}

const SearchSection: FC<ISearchSectionProps> & ISearchSectionComposition = ({ name, icon, subsections }) => {
  const { palette } = useTheme();
  const isBrightTheme = useBrightTheme();
  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const { accentColor } = palette;
  const backgroundColor = isBrightTheme ? getAccentColor(accentColor, '100') : NeutralColors.light_neutrals_200;

  const titleColor = isBrightTheme ? NeutralColors.light_neutrals_0 : NeutralColors.light_neutrals_900;

  return (
    <Box component="section" className="search-section">
      <SearchSectionPadding className="search-section__header" sx={mergeSx(styles.header, { backgroundColor })}>
        <Typography variant="Paragraph LG/Bold" color={titleColor} sx={styles.title}>
          {isBrightTheme && icon}
          {name}
        </Typography>
      </SearchSectionPadding>
      {subsections.map((subsection, index, array) => {
        const isLast = index + 1 === array.length;
        return (
          <Fragment key={index}>
            {subsection}
            {/* разделитель между подразделами */}
            {!isLast && (
              <Box className="search-section__subsection-divider" sx={{ paddingTop: isMobile ? '16px' : 0 }}>
                <SearchSectionDivider />
              </Box>
            )}
          </Fragment>
        );
      })}
    </Box>
  );
};

SearchSection.Padding = SearchSectionPadding;
SearchSection.Divider = SearchSectionDivider;
SearchSection.Subsection = SearchSectionSubsection;
SearchSection.CardContainer = SearchSectionCardContainer;

export default SearchSection;
