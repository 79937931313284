import { FC, ReactNode } from 'react';

import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';
import { commonTheme } from 'portfolio3/styles/theme';
import { IconBadge, NeutralBadge } from 'portfolio3/ui-kit/badges';
import { SxStyles } from 'types';

import CardPadding from '../CardPadding';

const styles: SxStyles = {
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',

  cursor: 'pointer',
};

interface IMetaskillsCardProps {
  header: string;
  icon: ReactNode;
  skills: string[];
  onView: () => void;
}

const MetaskillsCard: FC<IMetaskillsCardProps> = ({ header, icon, skills, onView }) => {
  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  return (
    <CardPadding withHorizontalPadding>
      <Box className="metaskills-card" sx={styles} onClick={onView}>
        <Stack direction="row" gap="12px" alignItems="center">
          <IconBadge backgroundColor={NeutralColors.light_neutrals_200}>{icon}</IconBadge>
          <Typography variant="Paragraph LG/Semi Bold">{header}</Typography>
        </Stack>
        <Stack flexWrap="wrap" direction="row" gap={isMobile ? '8px' : '12px'}>
          {skills.map((skill) => (
            <NeutralBadge key={skill}>{skill}</NeutralBadge>
          ))}
        </Stack>
      </Box>
    </CardPadding>
  );
};

export default MetaskillsCard;
