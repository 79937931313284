import { IRootState } from 'reducers';
import { createSelector } from 'reselect';

import { filterArchiveFalse } from './utils';

const avatarTypeRefArchiveFalseSelector = createSelector(
  [(state: IRootState) => state.avatarTypeRef],
  (avatarTypeRef): IRootState['avatarTypeRef'] => {
    return {
      ...avatarTypeRef,
      content: avatarTypeRef.content.filter(filterArchiveFalse),
    };
  },
);

export default avatarTypeRefArchiveFalseSelector;
