import { SxStyles } from 'types';
import { numberToPixelsString } from 'utils';

import { innerPaddingDesktop, innerPaddingMobile } from '../../const';

export const header: SxStyles = {
  paddingBlock: '12px',
};

export const title: SxStyles = (theme) => ({
  display: 'flex',
  gap: '8px',

  letterSpacing: '0.75px',
  textTransform: 'uppercase',

  paddingLeft: numberToPixelsString(innerPaddingDesktop),

  [theme.breakpoints.down('commonSm')]: {
    paddingLeft: numberToPixelsString(innerPaddingMobile),
  },
});
