import { IIndependentDiagnosticsGeneralRatingResponse } from 'api/types';

import { GET_INDEPENDENT_DIAGNOSTICS_GENERAL_RATING } from '../../actions';
import { FAILURE, INVALIDATE, REQUEST, SUCCESS } from '../../actions/utils';
import { connectReducers } from '../../utils';
import { IReducers } from '..';
import genericReducers from '../genericReducer';

export type IndependentDiagnosticsGeneralRatingState = IIndependentDiagnosticsGeneralRatingResponse;

const initialState: IndependentDiagnosticsGeneralRatingState = {
  content: undefined,
  loading: false,
};

const independentDiagnosticsGeneralRating: IReducers<IndependentDiagnosticsGeneralRatingState> = {
  [GET_INDEPENDENT_DIAGNOSTICS_GENERAL_RATING[REQUEST]]: genericReducers().get.request,
  [GET_INDEPENDENT_DIAGNOSTICS_GENERAL_RATING[SUCCESS]]: genericReducers().get.success,
  [GET_INDEPENDENT_DIAGNOSTICS_GENERAL_RATING[FAILURE]]: genericReducers().get.failure,
  [GET_INDEPENDENT_DIAGNOSTICS_GENERAL_RATING[INVALIDATE]]: genericReducers().get.invalidate(initialState),
};

export default connectReducers(initialState, independentDiagnosticsGeneralRating);
