/* eslint-disable prettier/prettier */
/* eslint-disable */

import { GET_USER_SETTINGS, SAVE_VISIBILITY_SETTINGS } from 'actions';
import { FAILURE, INVALIDATE, REQUEST, SUCCESS } from 'actions/utils';
import { ICommonResponseObject, IUserSettingsResponse } from 'api/types';
import connectReducers from 'utils/connectReducers';

import { IReducers } from '.';
import genericReducers from './genericReducer';

export type UserSettingsState = ICommonResponseObject<IUserSettingsResponse | null>;

const initialState: UserSettingsState = {
  content: null,
  loading: false,
};

const userSettings: IReducers<UserSettingsState> = {
  [GET_USER_SETTINGS[REQUEST]]: genericReducers().get.request,
  [GET_USER_SETTINGS[SUCCESS]]: genericReducers().get.success,
  [GET_USER_SETTINGS[FAILURE]]: genericReducers().get.failure,
  [GET_USER_SETTINGS[INVALIDATE]]: genericReducers().get.invalidate(initialState),

  [SAVE_VISIBILITY_SETTINGS[REQUEST]]: genericReducers().createUpdate.request,
  [SAVE_VISIBILITY_SETTINGS[SUCCESS]]: genericReducers().createUpdate.success,
  [SAVE_VISIBILITY_SETTINGS[FAILURE]]: genericReducers().createUpdate.failure,
};

export default connectReducers(initialState, userSettings);
