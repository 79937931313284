/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useContext, useEffect, useMemo } from 'react';

import { IDictionaryItem, IEventKind } from '../../../../api/types';
import { SectionCodes } from '../../../../const';
import { LocalDataEntryFormContext } from '../context/localDataEntryFormContext';

import { SearchSelect, Select } from 'portfolio3/ui-kit/selects';
import { BaseInputLabel, FormControl } from 'portfolio3/ui-kit';
import { IController } from 'portfolio3/ui-kit/types';
import { useUserFunctionality } from 'hooks';

interface IActivityBlockProps {
  typeOptions: IDictionaryItem[];
  nameOptions: IEventKind[];
  nameInputLabel: string;
  nameInputPlaceholder: string;
  hasOtherNameOption?: boolean;
}

const ActivityBlock: React.FC<IActivityBlockProps> = ({
  typeOptions,
  nameOptions,
  nameInputLabel,
  nameInputPlaceholder,
  hasOtherNameOption,
}) => {
  const { isStudent, isParent } = useUserFunctionality();
  const { excludedEventTypeOptions, inputRenderMode, inputSize, formData, onChangeFormData } = useContext(LocalDataEntryFormContext);

  const filteredTypeOptions = excludedEventTypeOptions
    ? typeOptions.filter((option) => !excludedEventTypeOptions.includes(option.code))
    : typeOptions;

  useEffect(() => {
    if (typeOptions.length === 1) {
      onChangeFormData({
        ...formData,
        typeCode: typeOptions[0].code
      });
    }
  }, [filteredTypeOptions]);

  const typeController: IController<string | undefined> = useMemo(() => ({
    handleChange: (type) => {
      onChangeFormData((prevstate) => ({
        ...prevstate,
        typeCode: Number(type),
        name: undefined
      }));
    }
  }), [formData.id]);

  const nameController: IController<string | undefined> = useMemo(() => ({
    handleChange: (eventKindCode) => {
      const kindCodeNumber = Number(eventKindCode)
      const subcategoryCode = nameOptions?.find((eventKind) => eventKind.code === kindCodeNumber)?.subcategoryCode;
      onChangeFormData((prevstate) => {
        if (prevstate.categoryCode === SectionCodes.culture) {
          return ({
            ...prevstate,
            place: undefined,
            placeName: undefined,
            name: kindCodeNumber,
            subCategory: subcategoryCode
          });
        }
        return ({
          ...prevstate,
          name: kindCodeNumber,
          subCategory: subcategoryCode
        });
      });
    }
  }), [formData.id]);

  const isTypeSelectDisabled = filteredTypeOptions.length === 1;

  const localNameOptions = [...nameOptions];
  const canUseOtherOption = isStudent || isParent;
  const withOtherNameOption = canUseOtherOption && hasOtherNameOption;

  return (
    <>
      <FormControl
        renderMode={inputRenderMode}
        inputSize={inputSize}
        disabled={isTypeSelectDisabled}
        label={<BaseInputLabel>Тип мероприятия</BaseInputLabel>}
        control={
          <Select
            inputRenderMode={inputRenderMode}
            inputSize={inputSize}
            placeholder="Выберите тип мероприятия..."
            value={formData.typeCode}
            options={filteredTypeOptions}
            controller={typeController}
          />
        }
      />
      {formData.typeCode && (
        <FormControl
          renderMode={inputRenderMode}
          inputSize={inputSize}
          label={<BaseInputLabel>{nameInputLabel}</BaseInputLabel>}
          control={
            <SearchSelect
              inputRenderMode={inputRenderMode}
              inputSize={inputSize}
              placeholder={nameInputPlaceholder}
              value={formData.name}
              options={localNameOptions}
              controller={nameController}
              strictMaxWidth
              addOtherOption={withOtherNameOption}
            />
          }
        />
      )}
    </>
  );
};

export default ActivityBlock;
