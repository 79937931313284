import { NeutralColors } from 'portfolio3/styles';
import { getButtonStyles } from 'portfolio3/ui-kit/utils';
import { SxStyles } from 'types';

export const button: SxStyles = {
  ...getButtonStyles(),

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  width: '100%',

  svg: {
    width: '20px',
    height: '20px',
    color: NeutralColors.light_neutrals_600,
  },
};

export const speclialityList: SxStyles = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '4px',

  marginTop: '10px',
};
