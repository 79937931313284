/* eslint-disable max-len */
import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconMetaSkillBrain: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.308 9.375c-.53-4.573-4.412-8.125-9.127-8.125h-.77a8.785 8.785 0 0 0-8.786 8.786 3.09 3.09 0 0 0 3.09 3.089h1.3a3.437 3.437 0 0 0 3.423 3.125H15a4.375 4.375 0 0 0 4.375-4.375v-1.43c0-.363-.026-.719-.067-1.07Z"
      fill="#BE4BDB"
      fillOpacity=".64"
    />
    <path
      d="M15.624 16.239v2.252a.469.469 0 0 1-.8.332l-2.572-2.573 3.372-.011ZM6.36 4.71a.178.178 0 0 0 .125-.156c.046-.543.082-1.089.077-1.642-.175.471-.313.949-.443 1.427a5.082 5.082 0 0 0-3.027 1.705c-.8.924-1.26 2.147-1.183 3.331.33-1.13.872-2.126 1.631-2.928A6.478 6.478 0 0 1 6.326 4.72l.035-.011Zm5.94-1.486a1.934 1.934 0 0 0-.818-.835 2.436 2.436 0 0 0-1.17-.3 2.449 2.449 0 0 0-1.17.301 1.94 1.94 0 0 0-.819.834c.693-.339 1.327-.515 1.989-.51.662-.004 1.294.172 1.989.51ZM6.91 9.436c-.19.059-.373.142-.55.237a1.436 1.436 0 0 0-.027-.171 1.567 1.567 0 0 0-.41-.736c-.401-.402-1.049-.584-1.548-.328.485.25.813.474 1.105.77.2.197.365.415.53.681-.157.111-.308.23-.445.364a3.34 3.34 0 0 0-.817 1.343c-.158.5-.19 1.037-.06 1.529.251-.976.675-1.791 1.319-2.431.638-.645 1.455-1.069 2.43-1.319a2.776 2.776 0 0 0-1.527.061Zm7.492 2.775c-.263.048-.612-.001-.957-.006l-1.025-.018c-.457.004-.914.016-1.37.035.316-.346.446-.883.2-1.284-.252.403-.44.649-.678.883a3.937 3.937 0 0 1-.563.466c-.562.047-1.123.111-1.684.213 1.365.248 2.73.303 4.096.313l.223-.004c.161.12.298.238.428.37.237.235.426.481.679.883a.93.93 0 0 0 .106-.69 1.243 1.243 0 0 0-.303-.581l.404-.018c.164-.001.39-.025.57-.091.769-.231 1.246-1.054 1.099-1.744-.132.686-.653 1.178-1.225 1.273Zm3.951-1.672a3.632 3.632 0 0 0-2.33-2.329A3.225 3.225 0 0 0 15 8.057c.384-.401.688-.883.852-1.41A3.057 3.057 0 0 0 15.937 5c-.25 1.058-.708 1.956-1.41 2.652-.698.702-1.595 1.159-2.653 1.41.534.13 1.111.091 1.649-.084a3.581 3.581 0 0 0 1.268-.73c.876.266 1.635.68 2.236 1.287.702.697 1.159 1.594 1.41 2.652.13-.533.09-1.11-.085-1.648Z"
      fill="#BE4BDB"
    />
  </SvgIcon>
);

export default IconMetaSkillBrain;
