/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { SvgIcon } from '@mui/material';
import { IconRouteProps } from './types';

const IconRouteProfile: React.FC<IconRouteProps> = ({ outerFill, ...props }) => (
  <SvgIcon width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path className="outer" d="M0 8a8 8 0 0 1 8-8h14a8 8 0 0 1 8 8v14a8 8 0 0 1-8 8H8a8 8 0 0 1-8-8V8Z" fill={outerFill || '#E7F4FF'}/>
    <path className="inner" d="M15 7.24c4.275 0 7.758 3.483 7.758 7.756S19.275 22.751 15 22.751s-7.758-3.482-7.758-7.755S10.725 7.241 15 7.241Zm0-1.74c-5.249 0-9.5 4.25-9.5 9.496 0 5.247 4.251 9.496 9.5 9.496s9.5-4.25 9.5-9.496C24.5 9.749 20.249 5.5 15 5.5Z" fill="#339AF0"/>
    <path className="inner" d="M15 11.292a2.598 2.598 0 0 1 2.597 2.596 2.598 2.598 0 0 1-5.193 0A2.598 2.598 0 0 1 15 11.292Zm0-1.74a4.334 4.334 0 0 0-4.338 4.336A4.334 4.334 0 0 0 15 18.224a4.334 4.334 0 0 0 4.339-4.336A4.334 4.334 0 0 0 15 9.55Z" fill="#339AF0"/>
    <path className="inner" d="M7.978 21.382C9.72 23.289 12.213 24.5 15 24.5a9.442 9.442 0 0 0 7.022-3.118A7.702 7.702 0 0 0 15 16.848a7.702 7.702 0 0 0-7.022 4.534Z" fill="#339AF0"/>
  </SvgIcon>
);

export default IconRouteProfile;
