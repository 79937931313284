import { useSelector } from 'react-redux';

import { IRootState } from 'reducers';
import { userFunctionalitySelector } from 'selectors';

const useUserFunctionality = () => {
  const user = useSelector((state: IRootState) => userFunctionalitySelector(state));
  return user;
};

export default useUserFunctionality;
