import { FC } from 'react';

import { Box, BoxProps, Typography } from '@mui/material';
import { IconClose } from 'icons/edit';
import TrimText from 'portfolio3/components/common/TrimText';
import { NeutralColors } from 'portfolio3/styles';
import { mergeSx } from 'utils';

import { getTokenFieldTokenTokens } from '../utils';
import * as styles from './styles';
import { ITokenBaseProps } from './types';

const TokenBase: FC<ITokenBaseProps & BoxProps> = ({ label, size = 'medium', disabled, onDelete, ...props }) => {
  const tokens = getTokenFieldTokenTokens(size);
  const { typographyVariant } = tokens;

  const ownSx = styles.root(tokens, disabled);

  return (
    <Box {...props} sx={mergeSx(ownSx, props.sx)}>
      <Typography
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        variant={(typographyVariant as any) ?? 'Paragraph MD/Medium'}
        color={disabled ? NeutralColors.light_neutrals_600 : NeutralColors.light_neutrals_900}
        sx={styles.label}
      >
        <TrimText>{label}</TrimText>
      </Typography>
      {!disabled && (
        <Box component="button" tabIndex={-1} sx={styles.deleteButton(tokens)} onClick={onDelete} disabled={disabled}>
          <IconClose />
        </Box>
      )}
    </Box>
  );
};

export default TokenBase;
