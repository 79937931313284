import { FC, PropsWithChildren } from 'react';

import { Drawer } from '../../../../ui-kit';
import MobileDragIndicator from '../../MobileDragIndicator';

interface IFeaturePreviewMobileContainerProps {
  isOpen: boolean;
  onClose: () => void;
}

const FeaturePreviewMobileContainer: FC<IFeaturePreviewMobileContainerProps & PropsWithChildren> = ({
  isOpen,
  onClose,
  children,
}) => (
  <Drawer
    open={isOpen}
    anchor="bottom"
    className="feature-modal feature-modal--mobile"
    isContainerScrollable={false}
    isFullHeight={false}
    transitionDuration={{ appear: 300, enter: 300, exit: 250 }}
  >
    <MobileDragIndicator onTouchStart={onClose} />
    {children}
  </Drawer>
);

export default FeaturePreviewMobileContainer;
