import React, { Dispatch, SetStateAction } from 'react';

import {
  DataEntryFormStep,
  DataEntryFormTab,
  IPortfolioDataEntryFormErrors,
  IPortfolioEntryFormData,
} from 'portfolio3/features/dataEntryForm';
import { CommonUiKitSize, InputRenderMode } from 'portfolio3/ui-kit/types';
import { ITab } from 'types';

interface ILocalDataEntryFormContext {
  tabs: ITab[];
  formTab: DataEntryFormTab;
  onChangeFormTab: (tab: DataEntryFormTab) => void;

  formStep: DataEntryFormStep;
  onChangeFormStep: (step: DataEntryFormStep) => void;

  isEmployeeFormRolled: boolean;
  onChangeEmployeeFormRolled: (isRolled: boolean) => void;

  formData: IPortfolioEntryFormData;
  onChangeFormData: Dispatch<SetStateAction<IPortfolioEntryFormData>>;

  formErrors: IPortfolioDataEntryFormErrors;
  onChangeFormErrors: Dispatch<SetStateAction<IPortfolioDataEntryFormErrors>>;

  excludedEventTypeOptions?: number[];
  onOpenEventForm: (categoryCode: number, dataKind: number) => void;

  studentType?: boolean;
  isOpen: boolean;
  isMobile: boolean;
  inputRenderMode: InputRenderMode;
  inputSize: CommonUiKitSize;
  image?: string;
}

export const LocalDataEntryFormContext = React.createContext({} as ILocalDataEntryFormContext);
