/* eslint-disable prettier/prettier */
/* eslint-disable */

export enum SubsystemKeys {
  PORTFOLIO = 'portfolio',
}

export const SubsystemCodes: Record<string, number> = {
  [SubsystemKeys.PORTFOLIO]: 4
};
