import { forwardRef, ForwardRefRenderFunction } from 'react';

import { IconButton, IconButtonProps } from '@mui/material';
import { IconMoreHorizontal } from 'icons';
import { mergeSx } from 'utils';

import * as styles from './styles';

const ActionsButton: ForwardRefRenderFunction<HTMLButtonElement, IconButtonProps> = (props, ref) => {
  return (
    <IconButton {...props} ref={ref} sx={mergeSx(styles.button, props.sx)}>
      <IconMoreHorizontal />
    </IconButton>
  );
};

export default forwardRef<HTMLButtonElement, IconButtonProps>(ActionsButton);
