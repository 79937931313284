import { FC } from 'react';
import { connect, useDispatch } from 'react-redux';

import { useMediaQuery } from '@mui/material';
import { linkSharingDrawerActions } from 'actions';
import LinkSharingForm from 'components/redesignStudent/LinkSharingForm';
import { tooltipOptions } from 'const';
import copy from 'copy-to-clipboard';
import { emitYMEvent } from 'portfolio3/features/yandexMetrika';
import { commonTheme } from 'portfolio3/styles/theme';
import { toast } from 'portfolio3/ui-kit';
import { IRootState } from 'reducers';
import { LinkSharingDrawerState } from 'reducers/drawers/linkSharingDrawer';
import { getSharedLinkUrl } from 'utils';

import QrCodeDrawer from './QrCodeDrawer';
import QrCodePopUp from './QrCodePopUp';
import UrlDrawer from './UrlDrawer';
import UrlPopUp from './UrlPopUp';

interface ILinkSharingDrawerProps {
  linkSharingDrawer: LinkSharingDrawerState;
}

const LinkSharingDrawer: FC<ILinkSharingDrawerProps> = ({ linkSharingDrawer }) => {
  const dispatch = useDispatch();

  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const { isOpen, isLinkUrlOpen, isLinkQrCodeOpen, currentLinkUrl, currentQrCode } = linkSharingDrawer;

  const linkUrl = currentLinkUrl ? getSharedLinkUrl(currentLinkUrl) : null;
  const isUrlModalOpen = isLinkUrlOpen && Boolean(currentLinkUrl);

  const qrCodeImageData = `data:image/png;base64,${currentQrCode}`;
  const isQrModalOpen = isLinkQrCodeOpen && Boolean(currentQrCode);

  const handleClose = () => {
    dispatch(linkSharingDrawerActions.setOpen(false));
  };

  const handleCloseLinkModals = () => {
    dispatch(linkSharingDrawerActions.setLinkUrlOpen(false));
    dispatch(linkSharingDrawerActions.setLinkQrCodeOpen(false));
  };

  const handleCopyLink = () => {
    if (!linkUrl) return;

    const isCopied = copy(linkUrl);

    if (isCopied) {
      emitYMEvent({ type: 'linkCopy' });

      toast.success(isMobile ? 'Ссылка успешно скопирована!' : 'Ссылка скопирована в буфер обмена', tooltipOptions);
      handleCloseLinkModals();
    }
  };

  return (
    <>
      <LinkSharingForm isOpen={isOpen} onClose={handleClose} />

      {isMobile && (
        <>
          <UrlDrawer
            isOpen={isUrlModalOpen}
            onClose={handleCloseLinkModals}
            url={linkUrl ?? ''}
            onCopy={handleCopyLink}
          />
          <QrCodeDrawer isOpen={isQrModalOpen} onClose={handleCloseLinkModals} qrCode={qrCodeImageData} />
        </>
      )}
      {!isMobile && (
        <>
          <UrlPopUp
            isOpen={isUrlModalOpen}
            onClose={handleCloseLinkModals}
            url={linkUrl ?? ''}
            onCopy={handleCopyLink}
          />
          <QrCodePopUp isOpen={isQrModalOpen} onClose={handleCloseLinkModals} qrCode={qrCodeImageData} />
        </>
      )}
    </>
  );
};

export default connect((state: IRootState) => ({
  linkSharingDrawer: state.linkSharingDrawer,
}))(LinkSharingDrawer);
