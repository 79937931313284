import { FC } from 'react';
import { MoonLoader as MoonSpinner } from 'react-spinners';
import { CommonProps, LoaderSizeProps } from 'react-spinners/interfaces';

import { useTheme } from '@mui/material';
import { getAccentColor } from 'portfolio3/styles';

const MoonLoader: FC<CommonProps & LoaderSizeProps> = ({ size, color, css, ...props }) => {
  const theme = useTheme();
  const accentColor = getAccentColor(theme.palette.accentColor, '100');
  return <MoonSpinner {...props} color={color ?? accentColor} size={size ?? 150} css={css} />;
};

export default MoonLoader;
