import { NeutralColors } from 'portfolio3/styles';
import { getButtonStyles } from 'portfolio3/ui-kit/utils';
import { SxStyles } from 'types';

export const root = (hasStaticBodyElement: boolean): SxStyles => {
  return {
    display: 'grid',
    gridAutoFlow: 'rows',
    gridTemplateRows: hasStaticBodyElement ? 'auto auto 1fr auto' : 'auto 1fr auto',
  };
};

export const header: SxStyles = {
  padding: '20px',

  borderBottom: '1px solid',
  borderColor: NeutralColors.light_neutrals_300,
};

export const headerTitle: SxStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  gap: '12px',
};

export const headerDescription: SxStyles = {
  marginTop: '4px',
  color: NeutralColors.light_neutrals_600,
};

export const headerCloseButton: SxStyles = {
  ...getButtonStyles(),

  width: '24px',
  height: '24px',
  color: NeutralColors.light_neutrals_600,
};

export const body: SxStyles = {
  height: '100%',
  padding: '20px',
  overflowY: 'auto',
};

export const footer: SxStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '12px 20px',

  backgroundColor: NeutralColors.light_neutrals_100,
  borderTop: '1px solid',
  borderColor: NeutralColors.light_neutrals_300,
};

export const footerRightGroup: SxStyles = {
  display: 'flex',
  gap: '12px',

  marginLeft: 'auto',
};
