import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import ResultDoughnut from 'portfolio3/components/common/ResultDoughnut';
import { NeutralColors } from 'portfolio3/styles';

import * as styles from './styles';

interface ISubject {
  name: string;
  result: number;
}

interface IPerformanceYearCardProps {
  averageResult: number;
  subjects: ISubject[];
}

const PerformanceYearCard: FC<IPerformanceYearCardProps> = ({ averageResult, subjects }) => {
  return (
    <Box className="performance-year-card">
      <Box className="performance-year-card__average pdf-keep" sx={styles.averageCard}>
        <ResultDoughnut
          size={80}
          progressColor={NeutralColors.night_neutrals_600}
          trackColor={NeutralColors.night_neutrals_900}
          value={averageResult}
          maxValue={5}
          typographyVariant="Headings/H4"
        />
        <Typography variant="Headings/H5">Средний балл по всем предметам</Typography>
      </Box>

      <Box className="performance-year-card__subjects" sx={styles.subjects}>
        {subjects.map((subject) => {
          return (
            <Box key={subject.name} className="performance-year-card__subject pdf-keep" sx={styles.subjectCard}>
              <ResultDoughnut
                size={60}
                progressColor={NeutralColors.night_neutrals_600}
                trackColor={NeutralColors.night_neutrals_900}
                value={subject.result}
                maxValue={5}
                typographyVariant="Paragraph LG/Semi Bold"
              />
              <Typography variant="Paragraph LG/Regular">{subject.name}</Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default PerformanceYearCard;
