/* eslint-disable prettier/prettier */
/* eslint-disable */

import {
  useState,
  useLayoutEffect,
  RefObject,
} from 'react';
import useClientWidth from './useClientWidth';

/**
 * Функция подсчитывает количество элементов, которые можно поместить в заданную ширину
 */
const calculateVisibleElementsCount = (elements: HTMLElement[], availableWidth: number, gap: number) => {
  const { count: visibleElementsCount } = elements.reduce((prev, current, index) => {
    // убирает расчет отступа для первого элемента
    const firstElementModifier: number = Number(Boolean(index !== 0));
    const width: number = prev.width + current.offsetWidth + gap * firstElementModifier;

    return {
      count: width <= availableWidth ? prev.count + 1 : prev.count,
      width,
    }
  }, {width: 0, count: 0});

  return visibleElementsCount;
}

interface IUseHiddenHorizontalListProps {
  containerRef: RefObject<HTMLElement>;
  elementsContainerRef?: RefObject<HTMLElement>;
  buttonMoreRef?: RefObject<HTMLElement>;
  shouldHideButtonMore?: boolean;
  elementsGap: number;
  extraPaddings?: number;
  allElementsCount: number;
  hiddenElementClassName: string;
  additionalDeps: any[];
  onAllElementsVisible: () => void;
  skipProcessing?: boolean;
}

type UseHiddenHorizontalListReturnType = {
  visibleElementsCount: number;
}

/**
 * Позволяет скрывать элементы горизонтального списка в зависимости от ширины экрана
 * @param {IUseHiddenHorizontalListProps} props
 * @param {RefObject<HTMLElement>} props.containerRef
 * @param {RefObject<HTMLElement>} [props.elementsContainerRef] - используется когда элементы находятся не в самом контейнере
 * @param {RefObject<HTMLElement>} [props.buttonMoreRef]
 * @param {boolean} [props.shouldHideButtonMore] - определяет нужно ли скрывать кнопку "Еще" (def: true)
 * @param {number} props.elementsGap - Расстояние между элементами
 * @param {number} [props.extraPaddings] - Дополнительно расстояние внутри контейнера, которое нужно вычесть при расчете
 * @param {number} props.allElementsCount - Максимальное количество элементов в списке
 * @param {string} props.hiddenElementClassName - Класс применяемый к скрытому элементу
 * @param {any[]} props.additionalDeps - Дополнительные зависимости для запуска перерасчета количества видимых элементов
 * @param {Function} props.onAllElementsVisible
 * @param {boolean} props.skipProcessing - Определяет нужно ли выполнять расчет
 * @returns {number} Количество элементов которое можно отобразить
 */
const useHiddenHorizontalList = ({
  containerRef,
  elementsContainerRef = containerRef,
  buttonMoreRef,
  shouldHideButtonMore = true,
  elementsGap,
  extraPaddings = 0,
  allElementsCount,
  hiddenElementClassName,
  additionalDeps,
  onAllElementsVisible,
  skipProcessing = false,
}: IUseHiddenHorizontalListProps): UseHiddenHorizontalListReturnType => {
  const [displayCount, setDisplayCount] = useState(allElementsCount);
  const clientWidth = useClientWidth();

  useLayoutEffect(() => {
    if (skipProcessing) return;

    if (!containerRef.current || !elementsContainerRef.current) return;

    const listElements = Array.from(elementsContainerRef.current.children) as HTMLElement[];
    if (buttonMoreRef && shouldHideButtonMore) listElements.pop();

    if (!listElements || listElements.length === 0) return;

    const containerWidth = containerRef.current.offsetWidth + 1;
    const availableWidth = containerWidth - extraPaddings;

    // количество видимых элементов без кнопки "Ещё"
    let visibleElementsCount = calculateVisibleElementsCount(listElements, availableWidth, elementsGap);
    let isAllVisible = visibleElementsCount === listElements.length;
    let buttonMoreReCheck = false;

    // получение количества элементов с кнопкой "Ещё"
    if (!isAllVisible) {
      buttonMoreReCheck = true;
      const modifiedElements = [...listElements];
      // далее проверяется вместимость контейнера вместе с кнопкой
      // если кнопка "Ещё" есть, то она должна отобразиться на этом этапе
      if (buttonMoreRef?.current) modifiedElements.push(buttonMoreRef.current);

      while (!isAllVisible && modifiedElements.length > 0) {
        // индекс основного последнего элемент
        const lastElementIndex = modifiedElements.length - (buttonMoreRef ? 2 : 1);
        // удаление последнего элемента
        modifiedElements.splice(lastElementIndex, 1);

        // перепроверка
        visibleElementsCount = calculateVisibleElementsCount(modifiedElements, availableWidth, elementsGap);
        isAllVisible = visibleElementsCount === modifiedElements.length;
      }
    }

    const isCalculatedWithButtonMore = Boolean(buttonMoreRef && (buttonMoreReCheck || !shouldHideButtonMore));
    // если количество элементов расчитано с кнопкой "Ещё", то уменьшаем на 1
    if (isCalculatedWithButtonMore) {
      visibleElementsCount -= 1;
    }
    setDisplayCount(visibleElementsCount);

    // переустановка классов
    listElements.forEach((element, index) => {
      const isButtonMore = element === buttonMoreRef?.current;
      if (index + 1 > visibleElementsCount && (!isButtonMore || shouldHideButtonMore)) {
        element.tabIndex = -1;
        element.classList.add(hiddenElementClassName);
      }
      else {
        element.tabIndex = 0;
        element.classList.remove(hiddenElementClassName);
      }
    });

    if (shouldHideButtonMore) {
      if (visibleElementsCount < allElementsCount) {
        buttonMoreRef?.current?.classList.remove(hiddenElementClassName);
      } else {
        onAllElementsVisible();
        buttonMoreRef?.current?.classList.add(hiddenElementClassName);
      }
    }
  }, [elementsGap, clientWidth, allElementsCount, ...additionalDeps]);

  return {
    visibleElementsCount: displayCount
  };
};

export default useHiddenHorizontalList;
