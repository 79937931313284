import { FC } from 'react';

import './index.scss';

const SubjectDetailsEmpty: FC = () => {
  return (
    <div className="subject-details-empty">
      <p className="subject-details-empty__description">
        Нажми на любой предмет, чтобы увидеть подробную информацию по нему
      </p>
    </div>
  );
};

export default SubjectDetailsEmpty;
