import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const TargetSports: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="230" height="129" viewBox="0 0 230 129" xmlns="http://www.w3.org/2000/svg" {...props} fill="none">
    {/*eslint-disable max-len */}
    <path
      d="M172 72.27a33.997 33.997 0 0 1-31.412-20.989 34.002 34.002 0 0 1 7.37-37.053 34.003 34.003 0 0 1 52.312 5.153A34 34 0 0 1 206 38.27a34.06 34.06 0 0 1-34 34Zm0-67a33 33 0 1 0 33 33 33.051 33.051 0 0 0-33-33.04v.04Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M172.001 64.36a26.109 26.109 0 0 1-18.463-44.572 26.112 26.112 0 0 1 44.573 18.462 26.14 26.14 0 0 1-26.11 26.11Zm0-51.22a25.112 25.112 0 0 0-24.628 30.009 25.109 25.109 0 0 0 45.506 9.051 25.111 25.111 0 0 0 4.232-13.95 25.138 25.138 0 0 0-25.11-25.11Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M172 55.32a17.072 17.072 0 0 1-16.742-20.4 17.066 17.066 0 0 1 13.412-13.412 17.068 17.068 0 0 1 20.4 16.742A17.094 17.094 0 0 1 172 55.32Zm0-33.14a16.067 16.067 0 0 0-14.847 9.92 16.073 16.073 0 0 0 3.483 17.513 16.073 16.073 0 0 0 24.725-2.435 16.07 16.07 0 0 0 2.709-8.928A16.092 16.092 0 0 0 172 22.18Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M172.001 47a8.791 8.791 0 0 1-6.216-15.006 8.795 8.795 0 0 1 9.58-1.905 8.792 8.792 0 0 1 5.426 8.12 8.802 8.802 0 0 1-8.79 8.79Zm0-16.58a7.79 7.79 0 1 0 7.79 7.79 7.8 7.8 0 0 0-7.79-7.75v-.04Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M183.379 37.42c2.56-.36 12.52-2 14.82-.46a9.994 9.994 0 0 1 3.87 5.73l14.45-2c-1.07-3.81-3.59-6.86-7.52-7.78a10.152 10.152 0 0 0 5.06-9.57l-14.45 2.05a10.75 10.75 0 0 1-2.61 7.08c-1.62 1.54-11.66 3.13-13.8 3.44l.18 1.51Z"
      fill="#fff"
    />
    <path
      d="M202.07 43.19a.501.501 0 0 1-.49-.38 9.527 9.527 0 0 0-3.64-5.42c-2-1.3-10.9 0-13.82.43l-.67.09a.48.48 0 0 1-.37-.09.503.503 0 0 1-.2-.33l-.21-1.49a.494.494 0 0 1 .092-.372.503.503 0 0 1 .328-.198c4.57-.65 12.28-2.12 13.52-3.3a10.249 10.249 0 0 0 2.5-6.76.498.498 0 0 1 .43-.51L214 22.79a.502.502 0 0 1 .39.11.54.54 0 0 1 .18.36 10.85 10.85 0 0 1-4.34 9.44 11.054 11.054 0 0 1 6.77 7.81.49.49 0 0 1-.212.557.504.504 0 0 1-.198.073l-14.45 2-.07.05Zm-7.77-7.36a8.61 8.61 0 0 1 4.18.71 10.51 10.51 0 0 1 4 5.6l13.43-1.91a9.66 9.66 0 0 0-7-6.89.482.482 0 0 1-.38-.42.477.477 0 0 1 .26-.5 9.572 9.572 0 0 0 4.8-8.56l-13.46 1.91a11.151 11.151 0 0 1-2.81 7.06c-1.71 1.64-11.25 3.15-13.57 3.5l.07.52h.17a78.424 78.424 0 0 1 10.31-1.02Z"
      fill={props.fill || 'currentColor'}
    />
    <path d="m185.371 30.5-1.59 5.94 3.18 5.26-14.08-3.72 12.49-7.48Z" fill="#fff" />
    <path
      d="M187 42.2h-.13l-14.08-3.72a.521.521 0 0 1-.37-.42.494.494 0 0 1 .24-.49l12.49-7.49a.478.478 0 0 1 .56 0 .504.504 0 0 1 .18.52l-1.53 5.74 3.07 5.08a.524.524 0 0 1 0 .56.514.514 0 0 1-.43.22Zm-12.81-4.4 11.74 3.1-2.53-4.2a.537.537 0 0 1-.06-.39l1.27-4.75-10.42 6.24ZM39.92 123.86a.53.53 0 0 1-.21 0 .498.498 0 0 1-.24-.67c.1-.2 9.64-20.65 4.63-29.55a117.538 117.538 0 0 1-8-17.76l-7.3-3.18a33.001 33.001 0 0 1-10.35-7.08l-4.15-4.2a4.4 4.4 0 0 1-1.3-3.15 2.93 2.93 0 0 1 2.93-2.92H25l16.42 3.45c1.815.383 3.665.577 5.52.58H52a.48.48 0 0 1 .4.2l1.73 2.3a.51.51 0 0 1 .08.46l-.81 2.42a.84.84 0 0 1-1.61 0l-.18-.63-1.34-.13.09.07a1.73 1.73 0 0 1 .51 2.16.79.79 0 0 1-1 .4l-10-3.35-11.07.41a21.11 21.11 0 0 1-8.8-1.52l-1.66-.67a.5.5 0 0 1 .38-.92l1.66.66a20.27 20.27 0 0 0 8.33 1.45l11.15-.42h.18l10 3.32a.75.75 0 0 0-.28-.76l-1.48-1.11a.5.5 0 0 1 .34-.9l3.45.29a.5.5 0 0 1 .44.36l.14.48.56-1.7-1.42-1.89H47c-1.926 0-3.846-.2-5.73-.6l-16.4-3.41h-9A1.93 1.93 0 0 0 14 58.27a3.39 3.39 0 0 0 1 2.41l4.15 4.19a32 32 0 0 0 10 6.88L36.66 75a.471.471 0 0 1 .27.3 116.93 116.93 0 0 0 8 17.8c5.26 9.35-4.19 29.61-4.6 30.47a.476.476 0 0 1-.41.29Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M46.85 69.62A36.32 36.32 0 0 1 30 63.54a.5.5 0 1 1 .55-.83 36.358 36.358 0 0 0 15.81 5.86v-3.42a.5.5 0 0 1 1 0v4a.48.48 0 0 1-.16.36.49.49 0 0 1-.35.11ZM51.59 75.42a7.63 7.63 0 0 1-3-.65.5.5 0 0 1-.26-.66.491.491 0 0 1 .65-.26 5.81 5.81 0 0 0 3.9.39 3.46 3.46 0 0 0 2.4-3.78L55 68a.49.49 0 0 1 .43-.56.5.5 0 0 1 .56.43l.3 2.43a4.47 4.47 0 0 1-3.13 4.88c-.511.15-1.04.23-1.57.24Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M55.5 68c4.35-.58 28.28 2.3 28.28 2.3l21.8-.57 2.6-1.82a8.122 8.122 0 0 1 6.75-1.27c.68.18 1.17.43 1.17.78 0 1.15-3.47 1.15-3.47 1.15l-3.46 2.89-.72 1.76-21.15 2c-1.58.15-3.15.34-4.71.58l-5 .74c-2.805.41-5.655.41-8.46 0L65.31 76 67 83.55l7.51 21.35L78 123"
      fill="#fff"
    />
    <path
      d="M78 123.5a.489.489 0 0 1-.49-.41L74.06 105l-7.49-21.29-1.74-7.55a.49.49 0 0 1 .11-.45.53.53 0 0 1 .44-.16l3.85.55a29 29 0 0 0 8.32 0l5-.75a92.9 92.9 0 0 1 4.74-.58l20.85-2 .61-1.48a.564.564 0 0 1 .14-.2l3.46-2.88a.492.492 0 0 1 .32-.12c1.55 0 2.93-.36 3-.64a3.252 3.252 0 0 0-.8-.31 7.64 7.64 0 0 0-6.34 1.2l-2.59 1.82a.66.66 0 0 1-.28.09l-21.8.57c-.31 0-24-2.87-28.22-2.31A.491.491 0 0 1 55 68a.5.5 0 0 1 .43-.56c4.36-.58 27.42 2.19 28.4 2.31l21.58-.58 2.47-1.73a8.65 8.65 0 0 1 7.17-1.35c.38.11 1.54.42 1.54 1.27 0 1.44-2.69 1.63-3.78 1.65l-3.23 2.69-.68 1.65a.512.512 0 0 1-.41.31l-21.16 2c-1.56.15-3.13.34-4.68.57l-5 .75c-2.854.42-5.755.42-8.61 0L66 76.64l1.57 6.79 7.43 21.3 3.47 18.18a.499.499 0 0 1-.4.58l-.07.01Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M56 68.51a2.096 2.096 0 0 1-.57-.06.5.5 0 0 1-.36-.61.49.49 0 0 1 .6-.36 2.67 2.67 0 0 0 2-.55c1.12-.78 2.57-2.76 3.15-7.68.67-5.7-1.35-6.38-1.37-6.38a.54.54 0 0 1-.37-.49 1.852 1.852 0 0 0-.76-1.26c-.94-.75-3.06-1.45-7.88-.55-2.7.5-4.52 1.6-5.41 3.26-1.39 2.58-.1 5.71-.09 5.74A.517.517 0 1 1 44 60c-.06-.14-1.49-3.59.12-6.59 1-1.95 3.11-3.22 6.12-3.79 4.21-.79 7.14-.52 8.73.8.504.402.866.956 1.03 1.58.76.4 2.41 1.91 1.77 7.34-.5 4.28-1.72 7.11-3.6 8.41a4 4 0 0 1-2.17.76Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M50.89 60.39a.49.49 0 0 1-.46-.53c0-.09.69-8.58 9.19-8a.488.488 0 0 1 .46.53.487.487 0 0 1-.53.46c-3.15-.21-5.47.89-6.9 3.29a9.841 9.841 0 0 0-1.23 3.75.49.49 0 0 1-.53.5ZM53.19 61.37a.481.481 0 0 1-.36-.16.488.488 0 0 1 0-.7l30-28.68a.481.481 0 0 1 .7 0 .502.502 0 0 1 0 .71l-30 28.68a.52.52 0 0 1-.34.15ZM88.4 114.05a.503.503 0 0 1-.41-.2L52.52 65.44a.5.5 0 1 1 .81-.59l35.47 48.41a.51.51 0 0 1-.11.7.518.518 0 0 1-.29.09ZM119 65l-65.25-2.31a.5.5 0 0 1-.48-.51.51.51 0 0 1 .52-.49L119 64a.5.5 0 0 1 0 1Z"
      fill={props.fill || 'currentColor'}
    />
    <path d="m69.45 62.65-12.16-1a.999.999 0 0 0-1.09 1 1.471 1.471 0 0 0 1.74 1.45l11.51-1.45Z" fill="#fff" />
    <path
      d="M57.67 64.62a2 2 0 0 1-2-2 1.54 1.54 0 0 1 1.011-1.438c.198-.07.41-.099.619-.082l12.16 1a.52.52 0 0 1 .46.49.51.51 0 0 1-.44.51L58 64.59c-.109.02-.22.03-.33.03Zm-.45-2.5a.53.53 0 0 0-.52.52 1 1 0 0 0 .35.75 1 1 0 0 0 .8.21l6.82-.85-7.42-.63h-.03ZM43.84 56.35c-.05.01-.1.01-.15 0-.14 0-3.33-1.13-4.06-3.44a3.82 3.82 0 0 1 .83-3.53c.91-1.23 1.89-1.79 2.91-1.66 2.13.27 3.48 3.53 3.53 3.67a.49.49 0 0 1-.27.65.5.5 0 0 1-.65-.27c-.33-.8-1.45-2.89-2.73-3.05-.65-.08-1.31.34-2 1.25a2.941 2.941 0 0 0-.68 2.64c.59 1.79 3.43 2.75 3.43 2.76a.5.5 0 0 1 .136.882.509.509 0 0 1-.296.098ZM88.4 114.05a.464.464 0 0 1-.28-.08.497.497 0 0 1-.22-.38l-5.2-81.37a.51.51 0 0 1 .15-.39.55.55 0 0 1 .39-.14c.25 0 25.26 2.11 30.68 32.44 2.25 12.58.57 23.59-5 32.72A43.608 43.608 0 0 1 88.56 114l-.16.05Zm-4.66-81.3 5.11 80.09a42.853 42.853 0 0 0 19.23-16.51c5.43-8.92 7.06-19.69 4.86-32-2.76-15.44-10.7-23.21-16.87-27a32.77 32.77 0 0 0-12.33-4.58Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M108.18 68.38a.5.5 0 0 1-.5-.42l-3.35-19.62c-1.86-3.53-9.36-11.29-21-13.18a.51.51 0 0 1-.344-.763.49.49 0 0 1 .504-.227c12.15 2 19.94 10.13 21.82 13.78a.547.547 0 0 1 0 .14l3.36 19.71a.49.49 0 0 1-.41.57l-.08.01Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M104.81 48.68a.467.467 0 0 1-.35-.15.507.507 0 0 1-.109-.547.507.507 0 0 1 .109-.163l1.68-1.65a.506.506 0 0 1 .548-.11.491.491 0 0 1 .162.11.48.48 0 0 1 .147.35.494.494 0 0 1-.147.35l-1.68 1.65a.462.462 0 0 1-.36.16ZM110.559 93.24a.507.507 0 0 1-.5-.44l-2.06-19.41-2.81-3.39a.495.495 0 0 1-.108-.368.502.502 0 0 1 .888-.262l2.87 3.49a.536.536 0 0 1 .11.27l2.11 19.55a.497.497 0 0 1-.45.55l-.05.01ZM103.81 74.14a.5.5 0 0 1-.44-.26l-1.83-3.39a.494.494 0 0 1 .05-.554.493.493 0 0 1 .336-.184.5.5 0 0 1 .494.258l1.83 3.39a.503.503 0 0 1-.192.675.5.5 0 0 1-.248.065ZM36.46 76h-.13a.492.492 0 0 1-.35-.62l2.09-7.58a.5.5 0 0 1 .62-.35.5.5 0 0 1 .31.55l-2.1 7.59a.5.5 0 0 1-.44.41ZM56 81.87c-6.07 0-9.5-12.07-9.65-12.62a.49.49 0 0 1 .34-.61.5.5 0 0 1 .62.35c0 .13 3.79 13.32 9.68 11.75a3.27 3.27 0 0 0 2.23-1.82c1.77-3.75-1.68-11.39-1.71-11.46a.49.49 0 0 1 .24-.66.498.498 0 0 1 .67.24c.15.33 3.67 8.14 1.7 12.31a4.24 4.24 0 0 1-2.87 2.35 4.64 4.64 0 0 1-1.25.17ZM84 49.75H65a.5.5 0 0 1 0-1h19a.5.5 0 0 1 0 1ZM132 69.75a.501.501 0 1 1 0-1l81-1a.501.501 0 1 1 0 1l-81 1ZM180 92.75h-52a.501.501 0 1 1 0-1h52a.501.501 0 1 1 0 1ZM114 23.75H80a.5.5 0 0 1 0-1h34a.501.501 0 1 1 0 1ZM141 32.75h-34a.501.501 0 1 1 0-1h34a.501.501 0 1 1 0 1ZM172 81.75h-31a.501.501 0 1 1 0-1h31a.501.501 0 1 1 0 1Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default TargetSports;
