import { styled } from '@mui/material';
import { getAccentColor } from 'portfolio3/styles';

import StyledBaseButton from '../bases/BaseButton.styled';

const TextButton = styled(StyledBaseButton)(({ theme }) => {
  const accentColor = theme.palette.accentColor;
  const color_8 = getAccentColor(accentColor, '8');
  const color_24 = getAccentColor(accentColor, '24');
  const color_48 = getAccentColor(accentColor, '48');
  const color_200 = getAccentColor(accentColor, '200');

  return {
    color: color_200,
    backgroundColor: 'transparent',
    border: 0,

    '&:hover': {
      backgroundColor: color_8,
    },

    '&:active': {
      backgroundColor: color_24,
    },

    '&:disabled': {
      color: color_48,
    },
  };
});

export default TextButton;
