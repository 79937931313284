import { Components } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';

export const componentsSettings: Components<Omit<Theme, 'components'>> = {
  MuiButtonBase: {
    defaultProps: { disableRipple: true },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: 'unset',
      },
    },
  },
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        'Headings/Hero': 'h1',
        'Headings/H1': 'h1',
        'Headings/H2': 'h2',
        'Headings/H3': 'h3',
        'Headings/H3 Black': 'h3',
        'Headings/H4': 'h4',
        'Headings/H5': 'h5',
        'Headings/H6': 'h6',
        'Paragraph LG/Regular': 'p',
        'Paragraph LG/Medium': 'p',
        'Paragraph LG/Semi Bold': 'p',
        'Paragraph LG/Bold': 'p',
        'Paragraph MD/Regular': 'p',
        'Paragraph MD/Medium': 'p',
        'Paragraph MD/Semi Bold': 'p',
        'Paragraph MD/Bold': 'p',
        'Paragraph SM/Regular': 'p',
        'Paragraph SM/Medium': 'p',
        'Paragraph SM/Semi Bold': 'p',
        'Paragraph SM/Bold': 'p',
        'Paragraph TN/Regular': 'p',
        'Paragraph TN/Medium': 'p',
        'Paragraph TN/Semi Bold': 'p',
        'Paragraph TN/Bold': 'p',
      },
    },
    styleOverrides: {
      root: {
        fontFamily: 'Inter',
      },
    },
  },
};
