/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';

import { GET_IMPORT_HISTORY } from '../../actions/employees';

import {
  FAILURE,
  REQUEST,
  SUCCESS
} from '../../actions/utils';

import genericReducers from '../genericReducer';
import { IReducers } from '..';
import { IHistoryResponse, IImportHistory } from '../../api/types';

export type ImportHistoryState = IHistoryResponse<IImportHistory>;

const initialState = {
  content: [],
  loading: false
} as IHistoryResponse<IImportHistory>;

const importHistory: IReducers<ImportHistoryState> = {
  [GET_IMPORT_HISTORY[REQUEST]]: genericReducers().get.request,
  [GET_IMPORT_HISTORY[SUCCESS]]: genericReducers().get.success,
  [GET_IMPORT_HISTORY[FAILURE]]: genericReducers().get.failure
};

export default connectReducers(initialState, importHistory);
