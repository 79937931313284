import { useSelector } from 'react-redux';

import { SectionCodes } from 'const';
import {
  IEntryFormEntityProperties,
  IPortfolioEntryFormData,
  MappedEventData,
} from 'portfolio3/features/dataEntryForm';
import { IRootState } from 'reducers';
import { getCategoryProperties } from 'utils';

import {
  getCommonAffilationProperties,
  getCommonEventProperties,
  getCommonOccupationProperties,
  getCommonRewardProperties,
} from './utils';

interface IProps {
  formData: IPortfolioEntryFormData;
  mappedEventData: MappedEventData;
}

const useCreationEntityProperties = ({ formData, mappedEventData }: IProps): IEntryFormEntityProperties | null => {
  const portfolioSections = useSelector((state: IRootState) => state.sectionRef.content);
  const rewards = useSelector((state: IRootState) => state.rewardKinds.content);
  const affilationKinds = useSelector((state: IRootState) => state.affilationKinds.content);

  const categoryCode = formData.categoryCode;
  const dataKind = formData.dataKind;

  const categoryProperties = categoryCode ? getCategoryProperties(categoryCode) : null;

  let name: string | undefined;
  let typeName: string | undefined;
  let attributes: string[] | undefined;

  if (dataKind === SectionCodes.creationAffilation) {
    const props = getCommonAffilationProperties(affilationKinds, portfolioSections, formData);
    name = props.name;
    typeName = props.typeName;
    attributes = props.attributes;
  }

  if (dataKind === SectionCodes.creationOccupation) {
    const props = getCommonOccupationProperties(portfolioSections, formData);
    name = props.name;
    typeName = props.typeName;
    attributes = props.attributes;
  }

  if (dataKind === SectionCodes.creationEvent) {
    const props = getCommonEventProperties(mappedEventData);
    name = props.name ?? 'Другое';
    typeName = props.typeName;
    attributes = props.attributes;
  }

  if (dataKind === SectionCodes.creationReward) {
    const props = getCommonRewardProperties(rewards, portfolioSections, formData);
    name = props.name;
    typeName = props.typeName;
    attributes = props.attributes;
  }

  if (categoryCode !== SectionCodes.creation) return null;

  return {
    entityInfo: {
      name,
      categoryName: categoryProperties?.name ?? '',
      typeName: typeName ?? '',
      attributes,
    },
  };
};

export default useCreationEntityProperties;
