import { CommonUiKitSize } from 'portfolio3/ui-kit/types';
import { SxStyles } from 'types';

export const root = (size?: CommonUiKitSize): SxStyles => {
  const isSmall = size === 'small';
  const buttonSize = isSmall ? '24px' : '28px';
  const svgSize = isSmall ? '18px' : '20px';

  return {
    '&': {
      width: buttonSize,
      height: buttonSize,

      padding: 0,

      borderRadius: '50%',
    },

    '& svg': {
      width: svgSize,
      height: svgSize,
    },
  };
};
