import { FC, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { IRecord } from 'api/types';
import { DifferentCode } from 'const';
import { getAccentColor, NeutralColors } from 'portfolio3/styles';
import { Select } from 'portfolio3/ui-kit/selects';
import { IController } from 'portfolio3/ui-kit/types';
import { SxStyles } from 'types';
import { getProcessPluralization, getRecordingPluralization } from 'utils';

import IncorrectRecordItem from './IncorrectRecordItem';
import StatusIcon from './StatusIcon';

const stylesRoot: SxStyles = {
  border: '1px solid',
  borderColor: NeutralColors.light_neutrals_300,
  borderRadius: '8px',
  backgroundColor: NeutralColors.light_neutrals_0,
};

const stylesHeader: SxStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  padding: '12px 20px',

  borderBottom: '1px solid',
  borderColor: NeutralColors.light_neutrals_300,
};

const stylesIconText: SxStyles = {
  fontSize: '22px',
  fontWeight: 800,
  lineHeight: '20px',
};

interface IIncorrectRecordsProps {
  count: number;
  records: IRecord[];
}

const IncorrectRecords: FC<IIncorrectRecordsProps> = ({ count, records }) => {
  const [errorTypeCode, setErrorTypeCode] = useState(DifferentCode);

  /* error type select */
  const errorTypes = records.map((record) => record.errorType);
  const uniqueErrorTypes = Array.from(new Set(errorTypes));

  const errorTypesSelectOptions = uniqueErrorTypes.map((errorType, index) => ({
    value: errorType,
    code: index + 1,
  }));

  errorTypesSelectOptions.unshift({ code: DifferentCode, value: 'Все' });

  const typeController: IController<string | undefined> = {
    handleChange(type) {
      setErrorTypeCode(Number(type));
    },
  };

  /* title */
  const pluralizedRecording = getRecordingPluralization(count);
  const pluralizedProcess = getProcessPluralization(count);

  const title = `${count} ${pluralizedRecording} не ${pluralizedProcess} или ${pluralizedProcess} с ошибками`;

  /* filter */
  const filteredRecords =
    errorTypeCode === DifferentCode
      ? records
      : records.filter((record) => {
          const recordErrorTypeCode = errorTypesSelectOptions.find(
            (errorOption) => errorOption.value === record.errorType,
          )?.code;
          return recordErrorTypeCode === errorTypeCode;
        });

  return (
    <Box className="incorrect-records" sx={stylesRoot}>
      <Box sx={stylesHeader}>
        <StatusIcon
          color={getAccentColor('red', '100')}
          iconElement={
            <Typography sx={stylesIconText} color={getAccentColor('red', '100')}>
              !
            </Typography>
          }
          backgroundColor={getAccentColor('red', '8')}
        />
        <Typography variant="Paragraph LG/Semi Bold" color={getAccentColor('red', '200')}>
          {title}
        </Typography>
        <Box width="300px" marginLeft="auto">
          <Select
            inputRenderMode="fixed"
            inputSize="medium"
            value={errorTypeCode}
            options={errorTypesSelectOptions}
            placeholder="Выберите вид данных..."
            controller={typeController}
          />
        </Box>
      </Box>
      <Box>
        {filteredRecords.map((record, index, array) => {
          return <IncorrectRecordItem key={record.rowNum} record={record} isLastItem={index === array.length - 1} />;
        })}
      </Box>
    </Box>
  );
};

export default IncorrectRecords;
