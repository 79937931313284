import { SxStyles } from 'types';

export const header: SxStyles = {
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  marginBlock: '12px',
};

export const noDataPlug: SxStyles = {
  gridColumn: '1/-1',

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  padding: '32px 20px',
  textAlign: 'center',
};
