import { StudentSectionSettingTypeCodes } from 'const';
import useWidgetVisibility from 'hooks/useWidgetVisibility';

const studyUseWidgetVisibilityCreator = (subsectionTypeCode: string) => {
  const useProfessionWidgetVisibility = () => {
    return useWidgetVisibility({
      sectionTypeCode: StudentSectionSettingTypeCodes.study,
      subsectionTypeCode,
    });
  };
  return useProfessionWidgetVisibility;
};

const usePerformance = studyUseWidgetVisibilityCreator(StudentSectionSettingTypeCodes.studyPerformance);

const useSpoTraining = studyUseWidgetVisibilityCreator(StudentSectionSettingTypeCodes.studyTraining);

const useSpoPractice = studyUseWidgetVisibilityCreator(StudentSectionSettingTypeCodes.studyPractice);

const useSpoDocuments = studyUseWidgetVisibilityCreator(StudentSectionSettingTypeCodes.studyDocuments);

const useGia = studyUseWidgetVisibilityCreator(StudentSectionSettingTypeCodes.studyGia);

const useOlympiad = studyUseWidgetVisibilityCreator(StudentSectionSettingTypeCodes.studyOlympiad);

const useTrialExam = studyUseWidgetVisibilityCreator(StudentSectionSettingTypeCodes.studyTrialExam);

export const studyWidgetVisibilityHooks = {
  usePerformance,
  useSpoTraining,
  useSpoPractice,
  useSpoDocuments,
  useGia,
  useOlympiad,
  useTrialExam,
};
