import { SxStyles } from 'types';

export const removeBottomPadding: SxStyles = {
  '&&&': {
    paddingBottom: 0,
  },
};

export const dividerWithoutMargin: SxStyles = {
  '&&&': {
    margin: 0,
  },
};

export const filtersPaddingContainer: SxStyles = (theme) => {
  return {
    paddingBlock: '16px',

    [theme.breakpoints.down('commonSm')]: {
      paddingTop: '16px',
      paddingBottom: 0,
    },
  };
};

export const contentContainer: SxStyles = (theme) => {
  return {
    marginTop: '20px',

    [theme.breakpoints.down('commonSm')]: {
      marginTop: '16px',
    },
  };
};

export const bottomContainer: SxStyles = {
  marginTop: '20px',
};
