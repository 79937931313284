import { EntityType, GiaType, SectionCodes } from '../../../const';
import { IThanksFormData } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isSubjectRequired = (entityFormData: any) => {
  const isOlympiad = entityFormData?.typeCode === SectionCodes.eventOlympiad;

  const formaGia = entityFormData?.formaGia;
  const requiredExamTypes = [GiaType.oge, GiaType.ege];
  const isExam = Boolean(formaGia) && requiredExamTypes.includes(formaGia);

  return isOlympiad || isExam;
};

export const isSubmitEnabled = (formData: IThanksFormData, studentClassLevel: number) => {
  const { isManualTeacherInput, teacherId, teacherName, subjectCode, yearEducation } = formData;

  const teacher = isManualTeacherInput ? teacherName : teacherId;
  const requiredFields = [teacher];

  if (isSubjectRequired(formData.entityFormData)) {
    requiredFields.push(subjectCode);
  }

  const currentDate = new Date();
  const maxYear = currentDate.getFullYear();
  const minYear = new Date(maxYear - studentClassLevel, 0).getFullYear();

  const isRequiredFilled = requiredFields.every((value) => {
    return typeof value === 'string' ? Boolean(value.trim()) : Boolean(value);
  });
  const isYearInRange = !yearEducation || (yearEducation >= minYear && yearEducation <= maxYear);

  return isRequiredFilled && isYearInRange;
};

const getOlympiadGratitudeText = (eventName: string, subjectName?: string) => {
  const mainText = `Выражаю благодарность за помощь в подготовке и высокий результат в Олимпиаде ${eventName}.`;
  const subjectText = subjectName ? ` Предметом данной олимпиады является ${subjectName}` : '';
  return mainText + subjectText;
};

const getEventContestGratitudeText = (eventName: string) => {
  return `Выражаю благодарность за помощь в подготовке к конкурсу ${eventName}.`;
};

const getExamGratitudeText = (formaGia: string, normalizedMarkValue: string, subjectName?: string) => {
  const mainText = `Выражаю благодарность за высокий балл (${normalizedMarkValue}) ${formaGia}`;
  const subjectText = subjectName ? ` (${subjectName})` : '';
  return mainText + subjectText;
};

const getRewardGratitudeText = (subjectName?: string) => {
  const mainText = 'Выражаю благодарность за высокий результат и полученную награду.';
  const subjectText = subjectName ? ` Награда получена по предмету ${subjectName}` : '';
  return mainText + subjectText;
};

const getProjectGratitudeText = (projectName: string) => {
  return `Выражаю благодарность за помощь в реализации проекта ${projectName}.`;
};

export const getGratitudeText = (formData: IThanksFormData) => {
  const { teacherName, subjectName, entityType, entityFormData } = formData;

  if (!teacherName?.trim()) return undefined;

  const eventName: unknown = entityFormData?.name;
  const formaGia: unknown = entityFormData?.formaGia;
  const normalizedMarkValue: unknown = entityFormData?.normalizedMarkValue;
  const typeCode: unknown = entityFormData?.typeCode;

  let gratitudeText = getEventContestGratitudeText(String(eventName));

  if (entityType === EntityType.GOVEXAM && formaGia && normalizedMarkValue) {
    gratitudeText = getExamGratitudeText(String(formaGia), String(normalizedMarkValue), subjectName);
  }

  if (entityType === EntityType.PROJECT && eventName) {
    gratitudeText = getProjectGratitudeText(String(eventName));
  }

  if (entityType === EntityType.REWARD || entityType === EntityType.SPORT_REWARD) {
    gratitudeText = getRewardGratitudeText(subjectName);
  }

  if (entityType === EntityType.OLYMPIAD && typeCode === SectionCodes.eventOlympiad) {
    gratitudeText = getOlympiadGratitudeText(String(eventName), subjectName);
  }

  return gratitudeText;
};
