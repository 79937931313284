import { IPortfolioEntryFormData } from 'portfolio3/features/dataEntryForm';

import { IRewardKind, ISportReward } from '../../../../../api/types';
import { DifferentValue, SectionCodes } from '../../../../../const';
import { validateProperties } from '../common';
import { CommonValidator } from '../types';

const rewardValidators: Record<string, CommonValidator> = {
  [SectionCodes.study]: isCommonRewardValid,
  [SectionCodes.science]: isCommonRewardValid,
  [SectionCodes.sport]: isSportRewardValid,
  [SectionCodes.creation]: isCommonRewardValid,
  [SectionCodes.culture]: isCommonRewardValid,
  [SectionCodes.civil]: isCivilRewardValid,
  [SectionCodes.profession]: isCommonRewardValid,
};

export const isRewardValid = (
  formData: IPortfolioEntryFormData,
  rewardKinds: IRewardKind[],
  sportRewardKinds: ISportReward[],
): boolean => {
  const { categoryCode } = formData;

  const isSportReward = categoryCode === SectionCodes.sport;
  const kinds = isSportReward ? sportRewardKinds : rewardKinds;
  const commonRewardValidator = isSportReward ? getCommonSportRewardProperties : getCommonRewardProperties;

  const commonValidator = () => validateProperties(commonRewardValidator(formData, kinds));

  const validator = categoryCode ? rewardValidators[categoryCode] : null;
  return validator ? validator(formData, commonValidator) : false;
};

function isCommonRewardValid(formData: IPortfolioEntryFormData, commonValidator: () => boolean): boolean {
  return validateProperties([commonValidator()]);
}

// SectionCode.sport
function isSportRewardValid(formData: IPortfolioEntryFormData, commonValidator: () => boolean): boolean {
  const commonProperties = [commonValidator(), formData.categoryCode === SectionCodes.sport];

  switch (formData.typeCode) {
    // ГТО
    case SectionCodes.gtoReward: {
      return validateProperties([...commonProperties, formData.age, formData.rewardNumber]);
    }
    // Спортивное звание или Спортивный разряд
    case SectionCodes.sportRank:
    case SectionCodes.sportCategory: {
      return validateProperties([...commonProperties, formData.sportKind]);
    }
    // Знак отличия по ступени (ступени)
    case SectionCodes.sportRewardTourism: {
      return validateProperties([...commonProperties, formData.eventLevel]);
    }
    // Награда за спортивное соревнование
    case SectionCodes.sportRewardCompetition: {
      return validateProperties([...commonProperties]);
    }
  }

  return false;
}

// SectionCode.civil
function isCivilRewardValid(formData: IPortfolioEntryFormData, commonValidator: () => boolean): boolean {
  const commonProperties = [commonValidator(), formData.categoryCode === SectionCodes.civil];

  if (formData.typeCode === SectionCodes.civilRewardStatus) {
    return validateProperties([...commonProperties, formData.subCategory, formData.eventLevel]);
  }

  return validateProperties(commonProperties);
}

function getRewardName(formData: IPortfolioEntryFormData, rewardKinds: IRewardKind[]): string | undefined {
  const { typeCode, name, reward } = formData;

  let rewardName: string | undefined;
  if (typeCode === SectionCodes.civilRewardStatus) {
    rewardName = rewardKinds.find((rewardKind) => rewardKind.code === name)?.value;
  } else {
    rewardName = rewardKinds.find((rewardKind) => rewardKind.code === reward)?.value;
  }

  if (rewardName?.trim() === DifferentValue) {
    rewardName = formData.secondName;
  }

  return rewardName?.trim();
}

function getSportRewardName(formData: IPortfolioEntryFormData, rewardKinds: ISportReward[]): string | undefined {
  const { name } = formData;

  let rewardName = rewardKinds.find((rewardKind) => rewardKind.code === name)?.value;

  if (rewardName?.trim() === DifferentValue) {
    rewardName = formData.secondName;
  }

  return rewardName?.trim();
}

function getCommonRewardProperties(formData: IPortfolioEntryFormData, rewardKinds: IRewardKind[]): unknown[] {
  const rewardName = getRewardName(formData, rewardKinds);
  return [rewardName];
}

function getCommonSportRewardProperties(formData: IPortfolioEntryFormData, rewardKinds: ISportReward[]): unknown[] {
  const rewardName = getSportRewardName(formData, rewardKinds);
  return [rewardName];
}
