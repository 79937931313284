// Нейтральные цвета
export const NeutralColors = {
  light_neutrals_0: '#FFFFFF',
  light_neutrals_100: '#FBFBFC',
  light_neutrals_200: '#F4F4F8',
  light_neutrals_300: '#E8E8EF',
  light_neutrals_400: '#D6D6DF',
  light_neutrals_500: '#AEAEBC',
  light_neutrals_600: '#87879B',
  light_neutrals_700: '#53546E',
  light_neutrals_800: '#292940',
  light_neutrals_900: '#101025',

  night_neutrals_0: '#252528',
  night_neutrals_100: '#1B1B1E',
  night_neutrals_200: '#0D0D10',
  night_neutrals_300: '#333336',
  night_neutrals_400: '#464649',
  night_neutrals_500: '#68686A',
  night_neutrals_600: '#919191',
  night_neutrals_700: '#B8B8B8',
  night_neutrals_800: '#D5D5D5',
  night_neutrals_900: '#EBEBEB',
};
