import { IMappedLearningYearPerformance } from '../types';

type SortType = 'asc' | 'desc';

/**
 * Предикат для функции sort, для сравнения двух годов обучения
 * @param firstYear Первый год
 * @param secondYear Второй год
 * @param type Тип сортировки
 * @returns Результат сравнения
 */
export const learningYearsSortPredicate = (firstYear: string, secondYear: string, type: SortType) => {
  const firstYearPart = Number(firstYear.slice(0, 4));
  const secondYearPart = Number(secondYear.slice(0, 4));

  if (type === 'asc') {
    return firstYearPart - secondYearPart;
  } else {
    return secondYearPart - firstYearPart;
  }
};

/**
 * Сортирует года обучения
 * @param learningYears Массив с годами обучения в формате "2022-2023"
 * @param type Тип сортировки
 * @returns Отсортированный массив
 */
export const sortLearningYears = (learningYears: string[], type: SortType) => {
  const sortedLearningYears = [...learningYears].sort((a, b) => learningYearsSortPredicate(a, b, type));
  return sortedLearningYears;
};

/**
 * Сортирует данные по году обучения
 * @param performance Массив с данными
 * @param type Тип сортировки
 * @returns Отсортированный массив
 */
export const sortPerformanceByLearningYear = (performance: IMappedLearningYearPerformance[], type: SortType) => {
  const sortedPerformance = [...performance].sort((a, b) => {
    return learningYearsSortPredicate(a.year, b.year, type);
  });
  return sortedPerformance;
};
