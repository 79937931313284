import { GET_STUDENT_PERSONAL_ACCESS } from '../../actions';
import { FAILURE, INVALIDATE, REQUEST, SUCCESS } from '../../actions/utils';
import { ICommonResponseObject } from '../../api/types';
import { connectReducers } from '../../utils';
import { IReducers } from '..';
import genericReducers from '../genericReducer';

interface IPersonalAccess {
  id: number;
  person_id: string;
  creationDate: Date;
  editedDate: Date;
  isActive: boolean;
}

export type StudentPersonalAccessState = ICommonResponseObject<IPersonalAccess | null>;

const initialState: StudentPersonalAccessState = {
  content: null,
  loading: false,
};

const studentMetaSkills: IReducers<StudentPersonalAccessState> = {
  [GET_STUDENT_PERSONAL_ACCESS[REQUEST]]: genericReducers().get.request,
  [GET_STUDENT_PERSONAL_ACCESS[SUCCESS]]: genericReducers().get.success,
  [GET_STUDENT_PERSONAL_ACCESS[FAILURE]]: genericReducers().get.failure,
  [GET_STUDENT_PERSONAL_ACCESS[INVALIDATE]]: genericReducers().get.invalidate(initialState),
};

export default connectReducers(initialState, studentMetaSkills);
