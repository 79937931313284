/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useMemo } from 'react';

import { connect } from 'react-redux';

import { IRootState } from '../../../../reducers';
import { ICommonResponse } from '../../../../api/types';
import PdfCommonDataEntityContainer from '../common';
import { PdfCommonDataEntity } from '../../../../components/pdfStudent/DataEntity';
import { widgetPdfIcons } from '../../../../icons';
import { creationContestsSelector } from '../../../../selectors';
import { IEntity } from '../../../../types';

import '../index.scss';
import { useDataLoad } from 'hooks';
import { getDataLoadRequests } from 'utils';

interface IPdfCreativeContestContainerProps {
  studentContests: ICommonResponse<IEntity>
}

const PdfCreativeContestContainer: React.FC<IPdfCreativeContestContainerProps> = ({
  studentContests
}) => {
  useDataLoad({ shouldLoad: true, requests: getDataLoadRequests().creationContests });

  const filteredContestsYear = useMemo(() => (studentContests.content && studentContests.content?.length > 0
    ? studentContests.content?.reduce((prevItem, nextItem) => (prevItem.year > nextItem.year ? prevItem : nextItem)).year
    : undefined),
  [studentContests]);

  return (
    <PdfCommonDataEntityContainer
      title="Конкурсы"
      icon={widgetPdfIcons.pdfIconCreationContests}
    >
      {studentContests.content
        .map((olympiad: IEntity) => (
          <PdfCommonDataEntity key={olympiad.id || olympiad.recordId} entity={olympiad} year={filteredContestsYear} />
        ))}
    </PdfCommonDataEntityContainer>
  );
};

export default connect(
  (state: IRootState) => ({
    studentContests: creationContestsSelector(state)
  })
)(PdfCreativeContestContainer);
