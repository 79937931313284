/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';
import { IViewVisibilitySetting } from '../types';

interface IPortfolioSettingsContext {
  isSettingsMode?: boolean;
  setSectionVisibility: (
    prevSettings: IViewVisibilitySetting[],
    sectionTypeCode: string,
    visible: boolean
  ) => void;
  setSubsectionVisibility: (
    prevSettings: IViewVisibilitySetting[],
    sectionTypeCode: string,
    subsectionTypeCode: string,
    visible: boolean
  ) => void;
  setSubcategoryVisibility: (
    prevSettings: IViewVisibilitySetting[],
    sectionTypeCode: string,
    subsectionTypeCode: string,
    subcategoryTypeCode: string,
    visible: boolean
  ) => void;
}

export default React.createContext({} as IPortfolioSettingsContext);
