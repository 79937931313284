/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { SvgIcon } from '@mui/material';
import { IconRouteProps } from './types';

const IconRouteCivil: React.FC<IconRouteProps> = ({ outerFill, ...props }) => (
  <SvgIcon width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path className="outer" d="M0 8C0 3.58172 3.58172 0 8 0H22C26.4183 0 30 3.58172 30 8V22C30 26.4183 26.4183 30 22 30H8C3.58172 30 0 26.4183 0 22V8Z" fill={outerFill || '#E8FBEB'} />
    <path className="inner" fillRule="evenodd" clipRule="evenodd" d="M19.4031 22.5C19.278 22.5 19.1521 22.4707 19.0365 22.4106L14.9998 20.3024L10.9638 22.4106C10.6962 22.5491 10.3732 22.5253 10.131 22.3481C9.88712 22.1708 9.766 21.8709 9.81745 21.5741L10.5862 17.1204L7.32447 13.9669C7.10755 13.7572 7.02917 13.4422 7.1218 13.1542C7.21442 12.8677 7.46222 12.658 7.76147 12.6153L12.274 11.96L14.2912 7.90359C14.5588 7.36547 15.4415 7.36547 15.7091 7.90359L17.7263 11.96L22.2389 12.6153C22.5381 12.658 22.7859 12.8677 22.8785 13.1542C22.9712 13.4422 22.8928 13.7572 22.6759 13.9669L19.4142 17.1204L20.1829 21.5741C20.2343 21.8709 20.1124 22.1708 19.8694 22.3481C19.7316 22.4494 19.5677 22.5 19.4031 22.5Z" fill="#51CF66" />
  </SvgIcon>
);

export default IconRouteCivil;
