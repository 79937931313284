import { createSelector } from 'reselect';

import { IRootState } from '../../../reducers';
import { mapScienceRewardsResponse } from './mappers';

const scienceRewardsSelector = createSelector(
  [
    (state: IRootState) => state.studentRewards,
    (state: IRootState) => state.studentEvents,
    (state: IRootState) => state.studentProjects,
    (state: IRootState) => state.sectionRef,
  ],
  (studentRewards, studentEvents, studentProjects, sectionRef) => {
    return mapScienceRewardsResponse(studentEvents, studentProjects, studentRewards, sectionRef);
  },
);

export default scienceRewardsSelector;
