/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { useMediaQuery } from '@mui/material';

import InterestActionPopper from './popper';
import InterestActionDrawer from './drawer';
import { getItemAndIndexFromArrayById } from '../../../../../utils';
import { IBubblePopperData, IInterest, IInterestAction, ISectionTheme } from '../../types';
import { initialBubblePopperData } from '../../utils';
import { CommonEntityActionProps } from './types';

interface IInterestEntityActions {
  bubblePopperData: IBubblePopperData;
  selectedInterests: IInterest[];
  theme: ISectionTheme;
  setBubblePopperData: React.Dispatch<React.SetStateAction<IBubblePopperData>>;
  setSelectedInterests: React.Dispatch<React.SetStateAction<IInterest[]>>;
  onToggleChild: (interestId: number, childId: number) => void;
}

const InterestEntityActions: React.VFC<IInterestEntityActions> = ({
  bubblePopperData,
  selectedInterests,
  theme,
  setBubblePopperData,
  setSelectedInterests,
  onToggleChild
}) => {
  const isMobile = useMediaQuery('(max-width: 766px)', { noSsr: true });

  const handleCloseBubbleActions = (e: any) => {
    if (![...e.target.classList].includes('bubble') || e.target.value !== bubblePopperData.bubbleName) {
      setSelectedInterests((prevstate) => {
        if (!bubblePopperData.bubbleId) return prevstate;
        const [selectedInterest, selectedInterestIndex] = getItemAndIndexFromArrayById(prevstate, bubblePopperData.bubbleId);
        const isEveryActionInactive = selectedInterest?.interestActions.every((action) => !action.isActive);
        if (isEveryActionInactive) {
          const newState = [...prevstate];
          newState.splice(selectedInterestIndex, 1);
          return newState;
        }
        return prevstate;
      });

      setBubblePopperData(initialBubblePopperData);
    }
  };

  const handleToggleBubbleActions = (actionId: number) => {
    return () => toggleBubbleAction(actionId);
  };

  const handleToggleChild = (interestId: number, childId: number) => {
    onToggleChild(interestId, childId);
  };

  const toggleBubbleAction = (actionId: number) => {
    setBubblePopperData((prevstate) => {
      const currentActionIndex = prevstate.actions.findIndex((action) => action.id === actionId);
      const newActions = prevstate.actions.map((action, index) => {
        const newAction = { ...action };
        if (index === currentActionIndex) {
          newAction.isActive = !newAction.isActive;
        }
        return newAction;
      });
      return {
        ...prevstate,
        actions: newActions
      };
    });

    setSelectedInterests((prevstate) => {
      if (!bubblePopperData.bubbleId) return prevstate;
      const [selectedInterest, selectedInterestIndex] = getItemAndIndexFromArrayById(prevstate, bubblePopperData.bubbleId);
      const currentActionIndex = selectedInterest?.interestActions.findIndex((action) => action.id === actionId);
      const newActions = selectedInterest?.interestActions.map((action, index) => {
        const newAction: IInterestAction = { ...action };
        if (index === currentActionIndex) {
          newAction.isActive = !newAction.isActive;
        }
        return newAction;
      }) ?? [];
      const newSelectedInterest: IInterest | undefined = selectedInterest ? {
        ...selectedInterest,
        interestActions: newActions
      } : undefined;
      const newState = [...prevstate];
      if (newSelectedInterest) newState.splice(selectedInterestIndex, 1, newSelectedInterest);
      return newState;
    });
  };

  const commonProps: CommonEntityActionProps = {
    bubblePopperData: bubblePopperData,
    theme: theme,
    onClose: handleCloseBubbleActions,
    onToggleAction: handleToggleBubbleActions
  };

  return isMobile
    ? <InterestActionDrawer
        {...commonProps}
        selectedInterests={selectedInterests}
        onToggleChild={handleToggleChild}
      />
    : <InterestActionPopper  {...commonProps} />;
};

export default InterestEntityActions;
