import { forwardRef } from 'react';

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { AccentColorType, getAccentColor, NeutralColors } from 'portfolio3/styles';
import { commonTheme } from 'portfolio3/styles/theme';
import { SxStyles } from 'types';
import { mergeSx } from 'utils';

import { IGroupedActionButtonProps } from '../types';
import * as styles from './styles';

const GroupedActionButton = forwardRef<HTMLButtonElement, IGroupedActionButtonProps>(
  (
    { children, disabled, variant = 'primary', iconColorOverride, startIcon: StartIcon, endIcon: EndIcon, handleClick },
    ref,
  ) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

    const accentColor: AccentColorType = isSmallScreen ? theme.palette.accentColor : 'indigo';
    const accentColor200 = getAccentColor(accentColor, '200');
    const accentColor100 = getAccentColor(accentColor, '100');
    const accentColor64 = getAccentColor(accentColor, '64');

    const isPrimary = variant === 'primary';
    const mobileColor = NeutralColors.light_neutrals_0;
    let mobileBackgroundColor = accentColor100;
    let desktopColor = isPrimary ? accentColor200 : NeutralColors.light_neutrals_700;
    const desktopBackgroundColor = NeutralColors.light_neutrals_0;

    if (disabled) {
      mobileBackgroundColor = accentColor64;
      desktopColor = isPrimary ? accentColor64 : NeutralColors.light_neutrals_500;
    }

    const textColor = isSmallScreen ? mobileColor : desktopColor;
    const backgroundColor = isSmallScreen ? mobileBackgroundColor : desktopBackgroundColor;

    const themedStyles: SxStyles = {
      color: textColor,
      backgroundColor,

      '&:not(:last-child)': {
        borderColor: NeutralColors.light_neutrals_300,
      },

      '& svg path': {
        fill: iconColorOverride ?? textColor,
      },
    };

    return (
      <Box
        component="button"
        disabled={disabled}
        className="grouped-action-button"
        onClick={handleClick}
        ref={ref}
        sx={mergeSx(styles.root, themedStyles)}
      >
        {StartIcon && <StartIcon />}
        <Typography variant={isSmallScreen ? 'Headings/H6' : 'Headings/H5'} color={textColor}>
          {children}
        </Typography>
        {EndIcon && <EndIcon />}
      </Box>
    );
  },
);

GroupedActionButton.displayName = 'GroupedActionButton';

export default GroupedActionButton;
