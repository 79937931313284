/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';

import {
  FAILURE,
  REQUEST,
  SUCCESS
} from '../../actions/utils';

import genericReducers from '../genericReducer';
import { IReducers } from '..';
import { IHistoryResponse, IAdminDeleteHistory } from '../../api/types';
import { GET_ADMIN_DELETE_HISTORY } from '../../actions';

export type AdminDeleteHistoryState = IHistoryResponse<IAdminDeleteHistory>;

const initialState = {
  content: [],
  loading: false
} as IHistoryResponse<IAdminDeleteHistory>;

const adminDeleteHistory: IReducers<IAdminDeleteHistory> = {
  [GET_ADMIN_DELETE_HISTORY[REQUEST]]: genericReducers().get.request,
  [GET_ADMIN_DELETE_HISTORY[SUCCESS]]: genericReducers().get.success,
  [GET_ADMIN_DELETE_HISTORY[FAILURE]]: genericReducers().get.failure
};

export default connectReducers(initialState, adminDeleteHistory);
