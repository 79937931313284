import { FC } from 'react';

import { TextareaAutosizeProps } from '@mui/material';

import { CommonUiKitSize, InputController, InputRenderMode } from '../../types';
import { TextareaBase } from '../bases';
import { getBaseInputProps } from '../utils';

interface ITextareaProps extends TextareaAutosizeProps {
  renderMode: InputRenderMode;
  size: CommonUiKitSize;
  controller: InputController;
}

const Textarea: FC<ITextareaProps> = ({ controller, value, ...props }) => {
  return <TextareaBase {...getBaseInputProps(controller)} {...props} value={value ?? ''} />;
};

export default Textarea;
