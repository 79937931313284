import { FC, useContext } from 'react';

import { WIDGET_FILTER_ENTITIES_ALL, WIDGET_FILTER_ENTITIES_WITH_REWARD } from 'const';
import { WidgetDataContext } from 'portfolio3/components/common/WidgetContainer';
import { emitYMEvent } from 'portfolio3/features/yandexMetrika';
import { Switcher, SwitcherButtonCount } from 'portfolio3/ui-kit';
import { CommonUiKitSize } from 'portfolio3/ui-kit/types';

interface IWidgetContainerEventSwitcherProps {
  isMobile: boolean;
  value: number;
  allCount: number;
  rewardCount: number;
  onChange: (value: number) => void;
}

const WidgetContainerEventSwitcher: FC<IWidgetContainerEventSwitcherProps> = ({
  isMobile,
  value,
  allCount,
  rewardCount,
  onChange,
}) => {
  const { widgetLabel } = useContext(WidgetDataContext);

  const size: CommonUiKitSize = isMobile ? 'small' : 'medium';
  const awardButtonText = isMobile ? 'С наградой' : 'Только с наградой';

  const handleChange = (newValue: number) => {
    onChange(newValue);

    if (value !== newValue && newValue === WIDGET_FILTER_ENTITIES_WITH_REWARD) {
      emitYMEvent({
        type: 'onlyWithAwards',
        payload: {
          Subsections: widgetLabel ?? '',
        },
      });
    }
  };

  return (
    <Switcher componentSize={size} value={value} onChange={handleChange}>
      <SwitcherButtonCount value={WIDGET_FILTER_ENTITIES_ALL} content="Все" count={allCount} />
      <SwitcherButtonCount value={WIDGET_FILTER_ENTITIES_WITH_REWARD} content={awardButtonText} count={rewardCount} />
    </Switcher>
  );
};

export default WidgetContainerEventSwitcher;
