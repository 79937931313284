import { IPortfolioDataEntryFormErrors, IPortfolioEntryFormData } from 'portfolio3/features/dataEntryForm';
import { IController, InputController } from 'portfolio3/ui-kit/types';

import { IDictionaryItem } from '../../../api/types';
import { deepSetProperty, NestedKeyOf } from '../../../utils';
import { deepGetProperty } from './../../../utils/common';

export const defaultPortfolioEntryFormData: IPortfolioEntryFormData = {
  categoryCode: undefined,
  dataKind: undefined,
  typeCode: undefined,
  name: undefined,
  reward: undefined,
  subjectId: [],
  linkedObjects: [],
  startDate: null,
  date: null,
  endDate: null,
  format: 1,
  profile: undefined,
  entityId: undefined,
  entityType: undefined,
  sportRewardCode: undefined,
  participant: undefined,
  place: undefined,
  workCode: undefined,
  result: undefined,
  description: undefined,
  recordId: undefined,
  recordType: undefined,
  attachment: [],
  secondName: undefined,
  classStart: undefined,
  classEnd: undefined,
  eventLevel: undefined,
  olympiadLevel: undefined,
  organizator: [''],
  rewardNumber: undefined,
  subCategory: undefined,
  age: undefined,
  actionStage: undefined,
  stageEvent: undefined,
  actionDate: null,
  stageStartDate: null,
  stageEndDate: null,
  tourismKind: undefined,
  sportKind: undefined,
  organizationCategory: undefined,
  trainingStageCode: undefined,
  teamName: undefined,
  teamStartDate: null,
  teamEndDate: null,
  medicalClearanceFromDate: null,
  medicalClearanceToDate: null,

  // gia worldskills
  giaCompetenceCode: undefined,
  giaName: undefined,
  giaResultScore: undefined,
  giaMaxCompetenceScore: undefined,
  giaKod: undefined,
  giaIsEarlyRelease: undefined,

  studySpoDocument: undefined,
  professionSpoJob: undefined,
};

export const defaultPortfolioEntryFormErrors: IPortfolioDataEntryFormErrors = {
  subjectError: false,
  fileError: false,
  dateError: {
    active: false,
    description: '',
  },
  activityDateError: {
    active: false,
    description: '',
  },
  eventDateError: {
    active: false,
    description: '',
  },
  stageDateError: {
    active: false,
    description: '',
  },
  eventLevelError: false,
  nameError: false,
  secondNameError: false,
  subcategoryError: false,
  placeError: false,
  placeNameError: false,
  sportKindError: false,
  tourismKindError: false,
  creationKindError: false,
  ageError: false,
  rewardNumberError: false,
  teamNameError: false,

  participantCategoryError: false,

  giaCompetenceCodeError: false,
  giaNameError: false,
  giaResultScoreError: false,
  giaMaxCompetenceScoreError: false,
  giaCodeError: false,

  studySpoDocumentErrors: {
    documentName: false,
    organization: false,
    educationProgramm: false,
    professionalProgramm: false,
    hours: false,
    regNum: false,
    docDate: {
      active: false,
      description: '',
    },
    docPlace: false,
    examMark: false,
    profession: false,
    rank: false,
    docNum: false,
    docSeries: false,
    resultDate: {
      active: false,
      description: '',
    },
  },
  professionSpoJobErrors: {
    organizationError: false,
    businessLevelError: false,
    positionError: false,
    mainFunctionalityError: false,
    graduationDateError: {
      active: false,
      description: '',
    },
    expulsionDateError: {
      active: false,
      description: '',
    },
    contractDateError: {
      active: false,
      description: '',
    },
  },
  endDateError: {
    active: false,
    description: '',
  },
  teamStartDateError: {
    active: false,
    description: '',
  },
  teamEndDateError: {
    active: false,
    description: '',
  },
};

export const getCommonInputControllerFactory = (
  onChangeFormData: (value: React.SetStateAction<IPortfolioEntryFormData>) => void,
  onChangeFormErrors: (value: React.SetStateAction<IPortfolioDataEntryFormErrors>) => void,
) => {
  return (
    formDataField: NestedKeyOf<Required<IPortfolioEntryFormData>>,
    formErrorField?: NestedKeyOf<Required<IPortfolioDataEntryFormErrors>>,
  ): InputController => ({
    handleChange: (value) => {
      onChangeFormData((prevState) => {
        return deepSetProperty(prevState, formDataField, value);
      });
      if (formErrorField) {
        onChangeFormErrors((prevState) => {
          return deepSetProperty(prevState, formErrorField, false);
        });
      }
    },
    handleBlur: (value) => {
      if (formErrorField && (!value || !value.trim().length)) {
        onChangeFormErrors((prevState) => {
          return deepSetProperty(prevState, formErrorField, true);
        });
      }
    },
  });
};

export const getCommonToggleControllerFactory = (
  onChangeFormData: (value: React.SetStateAction<IPortfolioEntryFormData>) => void,
) => {
  return (formDataField: NestedKeyOf<Required<IPortfolioEntryFormData>>) => {
    return (checked: boolean) => {
      onChangeFormData((prevState) => {
        return deepSetProperty(prevState, formDataField, checked);
      });
    };
  };
};

export const getCommonSelectControllerFactory = (
  onChangeFormData: (value: React.SetStateAction<IPortfolioEntryFormData>) => void,
  onChangeFormErrors: (value: React.SetStateAction<IPortfolioDataEntryFormErrors>) => void,
) => {
  return (
    formDataField: NestedKeyOf<Required<IPortfolioEntryFormData>>,
    formErrorField?: NestedKeyOf<Required<IPortfolioDataEntryFormErrors>>,
  ): IController<string | undefined> => {
    return {
      handleChange: (value) => {
        onChangeFormData((prevstate) => {
          return deepSetProperty(prevstate, formDataField, Number(value));
        });
        if (formErrorField) {
          onChangeFormErrors((prevstate) => {
            return deepSetProperty(prevstate, formErrorField, false);
          });
        }
      },
      handleBlur: (value) => {
        if (formErrorField && !value) {
          onChangeFormErrors((prevstate) => {
            return deepSetProperty(prevstate, formErrorField, true);
          });
        }
      },
    };
  };
};

/**
 * Контроллер работает со строковым автокомплитом
 */
export const getDictionaryAutocompleteControllerFactory = (
  onChangeFormData: (value: React.SetStateAction<IPortfolioEntryFormData>) => void,
  onChangeFormErrors: (value: React.SetStateAction<IPortfolioDataEntryFormErrors>) => void,
) => {
  return (
    dictionary: IDictionaryItem[],
    itemProperty: keyof IDictionaryItem,
    formData: IPortfolioEntryFormData,
    formDataField: NestedKeyOf<Required<IPortfolioEntryFormData>>,
    formErrorField?: NestedKeyOf<Required<IPortfolioDataEntryFormErrors>>,
  ): IController<string | null> => {
    return {
      handleChange(itemValue) {
        const dictionaryItem = dictionary.find((item) => item.value === itemValue);

        if (itemValue) {
          onChangeFormData((prevState) => {
            const oldValue = deepGetProperty(prevState, formDataField);
            return deepSetProperty(prevState, formDataField, dictionaryItem?.[itemProperty] ?? oldValue);
          });
        } else {
          onChangeFormData((prevState) => {
            return deepSetProperty(prevState, formDataField, undefined);
          });
        }

        if (formErrorField) {
          onChangeFormErrors((prevState) => {
            const errorActive = !dictionaryItem && !deepGetProperty(formData, formDataField);
            return deepSetProperty(prevState, formErrorField, errorActive);
          });
        }
      },
      handleBlur(itemValue) {
        if (!formErrorField) return;

        if (!itemValue) {
          onChangeFormErrors((prevState) => {
            return deepSetProperty(prevState, formErrorField, true);
          });
        }
      },
    };
  };
};
