import { FC, lazy, Suspense } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { sectionRoutes } from 'const';
import { GlobalFooterContainer } from 'portfolio3/containers/footers';
import { AdminViewModeHeader, GlobalHeaderContainer, StudentHeaderContainer } from 'portfolio3/containers/headers';
import { compose } from 'redux';

import {
  withBottomNavigation,
  withDrawers,
  withDrawersContext,
  withErrorBoundary,
  withFixedWidthContainer,
  withFooter,
  withHeader,
  withReferenceData,
  withRendererType,
  withStudentDataMain,
  withStudentPaddingContainer,
  withThemeBackground,
  withUserContext,
} from '../hocs';

const ProfileSection = lazy(() => import('portfolio3/containers/sections/ProfileSection'));
const StudySection = lazy(() => import('portfolio3/containers/sections/StudySection'));
const ScienceSection = lazy(() => import('portfolio3/containers/sections/ScienceSection'));
const SportSection = lazy(() => import('portfolio3/containers/sections/SportSection'));
const CreationSection = lazy(() => import('portfolio3/containers/sections/CreationSection'));
const CultureSection = lazy(() => import('portfolio3/containers/sections/CultureSection'));
const CivilSection = lazy(() => import('portfolio3/containers/sections/CivilSection'));
const ProfessionSection = lazy(() => import('portfolio3/containers/sections/ProfessionSection'));
const RecommendationSection = lazy(() => import('portfolio3/containers/sections/RecommendationSection'));
const RewardSection = lazy(() => import('portfolio3/containers/sections/RewardSection'));

const MainRenderer: FC = () => {
  const match = useRouteMatch();

  return (
    <Suspense fallback={<></>}>
      <Switch>
        <Route path={`${match.url}${sectionRoutes.PROFILE}`} render={() => <ProfileSection />} />
        <Route path={`${match.url}${sectionRoutes.STUDY}`} render={() => <StudySection />} />
        <Route path={`${match.url}${sectionRoutes.SCIENCE}`} render={() => <ScienceSection />} />
        <Route path={`${match.url}${sectionRoutes.SPORT}`} render={() => <SportSection />} />
        <Route path={`${match.url}${sectionRoutes.CREATION}`} render={() => <CreationSection />} />
        <Route path={`${match.url}${sectionRoutes.CULTURE}`} render={() => <CultureSection />} />
        <Route path={`${match.url}${sectionRoutes.CIVIL}`} render={() => <CivilSection />} />
        <Route path={`${match.url}${sectionRoutes.PROFESSION}`} render={() => <ProfessionSection />} />
        <Route path={`${match.url}${sectionRoutes.RECOMMENDATION}`} render={() => <RecommendationSection />} />
        <Route path={`${match.url}${sectionRoutes.REWARD}`} render={() => <RewardSection />} />
      </Switch>
    </Suspense>
  );
};

const isSideContainersFullWidth = false;

export const MainRendererStudent = compose<FC>(
  withRendererType('main'),
  withErrorBoundary(),
  withUserContext(),
  withStudentDataMain(),
  withReferenceData(),
  withDrawersContext(),
  withDrawers(),
  withThemeBackground(),
  withHeader(GlobalHeaderContainer, isSideContainersFullWidth),
  withHeader(StudentHeaderContainer, isSideContainersFullWidth),
  withBottomNavigation(),
  withFooter(GlobalFooterContainer, isSideContainersFullWidth),
  withFixedWidthContainer(),
  withStudentPaddingContainer(),
)(MainRenderer);

export const MainRendererAdminViewMode = compose<FC>(
  withErrorBoundary(),
  withStudentDataMain(),
  withReferenceData(),
  withDrawersContext(),
  withDrawers(),
  withHeader(AdminViewModeHeader, isSideContainersFullWidth),
  withBottomNavigation(),
  withFixedWidthContainer(),
)(MainRenderer);
