import React from 'react';

import { IconArrowLeft, IconPaperPlaneOutline } from '../../../../../icons';
import { GroupedActionButton, GroupedActionButtonsContainer } from '../../../GroupedActionButtons';
import { FormStep, IReportErrorFormData } from '../../types';
import { isFormSubmitDisabled } from '../../utils';

interface IErrorReportActionButtonsProps {
  isMobile: boolean;
  formData: IReportErrorFormData;
  initialFormData?: IReportErrorFormData;
  formStep: FormStep;
  exitBackTitle: string;
  onExitBack: () => void;
  onSubmit: () => void;
}

const ErrorReportActionButtons: React.FC<IErrorReportActionButtonsProps> = ({
  isMobile,
  formData,
  formStep,
  initialFormData,
  exitBackTitle,
  onExitBack,
  onSubmit,
}) => {
  const isSubmitDisabled = isFormSubmitDisabled(formData, formStep, initialFormData);

  return (
    <GroupedActionButtonsContainer disableAbsolutePosition>
      {!isMobile && (
        <GroupedActionButton variant="secondary" startIcon={IconArrowLeft} handleClick={onExitBack}>
          {exitBackTitle}
        </GroupedActionButton>
      )}
      <GroupedActionButton
        variant="primary"
        startIcon={IconPaperPlaneOutline}
        handleClick={onSubmit}
        disabled={isSubmitDisabled}
      >
        Отправить
      </GroupedActionButton>
    </GroupedActionButtonsContainer>
  );
};

export default ErrorReportActionButtons;
