import { SectionCodes } from 'const';
import { IRootState } from 'reducers';
import { createSelector } from 'reselect';
import { isDefined } from 'utils';

import { ILinkablePersonObject } from '../types';

export const linkablePersonObjectsSelector = createSelector(
  [(state: IRootState) => state.personsObjects],
  (personsObjects) => {
    return (
      personsObjects.content
        // сущности ГИА из раздела профессия нельзя связывать с другими объектами
        .filter((object) => object.dataKind !== SectionCodes.professionExam)
        .map((object): ILinkablePersonObject | null => {
          if (!isDefined(object.entityTypeCode)) return null;

          return {
            code: object.id,
            value: object.name,
            typeCode: object?.typeCode || object?.type?.code || object?.rewardType?.code || object?.dataKind,
            format: object.format?.value,
            categoryCode: object.categoryCode || object.category?.code,
            entityTypeCode: object.entityTypeCode,
          };
        })
        .filter(isDefined)
    );
  },
);
