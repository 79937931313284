/* eslint-disable prettier/prettier */
/* eslint-disable */

import {
  DependencyList,
  EffectCallback,
  useEffect,
  useRef
} from 'react';

const useComponentDidUpdate = (effect: EffectCallback, deps?: DependencyList) => {
  const hasMounted = useRef(false);

  useEffect(
    () => {
      if (!hasMounted.current) {
        hasMounted.current = true;
        return;
      }
      effect();
    },
    deps
  );
};

export default useComponentDidUpdate;
