import { FC, ReactElement } from 'react';

import { Box, useTheme } from '@mui/material';
import { IconAlertTriangleFilled, IconInfoFilled, IconQuestionMarkCircleFilled } from 'icons';
import { getAccentColor } from 'portfolio3/styles';

import { InfoModalVariant } from '../..';
import * as styles from './Icon.styled';

interface IIconProps {
  variant?: InfoModalVariant;
}

const Icon: FC<IIconProps> = ({ variant = 'info' }) => {
  const { palette } = useTheme();
  const backgroundColor = getAccentColor(palette.accentColor, '100');

  const iconsObject: Record<InfoModalVariant, ReactElement> = {
    info: <IconInfoFilled fill={backgroundColor} />,
    warning: <IconQuestionMarkCircleFilled fill={backgroundColor} />,
    danger: <IconAlertTriangleFilled fill={backgroundColor} />,
  };

  return (
    <Box sx={styles.icon(palette.accentColor)} className="icon">
      {iconsObject[variant]}
    </Box>
  );
};

export default Icon;
