/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

const IconCivicAktivist: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="50" height="50" fill="#E5E5E5"/>
    <rect width="1924" height="1397" transform="translate(-1043 -976)" fill="white"/>
    <g clip-path="url(#clip0)">
    <path d="M25 51C39.3594 51 51 39.3594 51 25C51 10.6406 39.3594 -1 25 -1C10.6406 -1 -1 10.6406 -1 25C-1 39.3594 10.6406 51 25 51Z" fill="#FEC200"/>
    <path d="M24.9998 48.92C20.2689 48.92 15.6442 47.5171 11.7106 44.8887C7.77698 42.2604 4.71109 38.5246 2.90065 34.1538C1.0902 29.783 0.616502 24.9735 1.53946 20.3334C2.46242 15.6934 4.74058 11.4313 8.08585 8.086C11.4311 4.74073 15.6933 2.46257 20.3333 1.53961C24.9733 0.616655 29.7828 1.09035 34.1536 2.9008C38.5244 4.71125 42.2602 7.77713 44.8886 11.7108C47.517 15.6444 48.9198 20.2691 48.9198 25C48.9138 31.3421 46.3917 37.4227 41.9071 41.9073C37.4226 46.3918 31.342 48.9139 24.9998 48.92ZM24.9998 1.82941C20.4171 1.82941 15.9373 3.18834 12.127 5.73436C8.31657 8.28037 5.34674 11.8991 3.59301 16.133C1.83929 20.3669 1.38043 25.0257 2.27447 29.5204C3.16851 34.015 5.3753 38.1436 8.61576 41.3841C11.8562 44.6245 15.9848 46.8313 20.4795 47.7254C24.9741 48.6194 29.633 48.1606 33.8668 46.4068C38.1007 44.6531 41.7195 41.6833 44.2655 37.8729C46.8115 34.0625 48.1704 29.5827 48.1704 25C48.1644 18.8566 45.7212 12.9666 41.3772 8.62263C37.0332 4.27861 31.1432 1.83548 24.9998 1.82941Z" fill="#E57C13"/>
    <g clip-path="url(#clip1)">
    <path d="M47.2209 12.2691L2.61108 12.4637L2.65142 21.7086L47.2613 21.514L47.2209 12.2691Z" fill="white"/>
    <path d="M47.2683 21.5138L2.65845 21.7084L2.69658 30.4467L47.3064 30.252L47.2683 21.5138Z" fill="#748FFC"/>
    <path d="M47.2993 30.2591L2.68945 30.4537L2.72758 39.192L47.3374 38.9973L47.2993 30.2591Z" fill="#E83F40"/>
    </g>
    <path d="M7.90137 15.3112L8.55137 14.2482L13.239 15.4182L12.6655 16.3588L10.3714 15.6553C9.91254 15.5253 9.3849 15.3494 8.91078 15.1965C9.27019 15.5406 9.67549 15.9612 9.98902 16.2823L11.6637 18.0106L11.1055 18.9206L7.90137 15.3112ZM9.95078 17.1465L11.1055 15.25L11.7937 15.6782L10.639 17.5671L9.95078 17.1465Z" fill="#E57C13"/>
    <path d="M10.6467 11.4953L11.4114 10.7994L12.6197 12.153L13.4456 11.3883L16.5885 12.3671L15.8238 13.0936L13.6061 12.4436L13.2314 12.78L14.5314 14.2483L13.7667 14.9365L10.6467 11.4953ZM13.0556 12.0153L12.765 10.6236C12.673 10.3768 12.6581 10.108 12.7223 9.85268C12.7865 9.59733 12.9267 9.36748 13.1244 9.19356C13.2158 9.10298 13.3252 9.03263 13.4456 8.98709L13.9426 9.80533C13.8851 9.83461 13.8312 9.87055 13.782 9.91239C13.6214 10.05 13.5908 10.203 13.675 10.57L13.8814 11.5718L13.0556 12.0153Z" fill="#E57C13"/>
    <path d="M16.4583 8.31409L15.3112 8.86467L14.9365 8.09997L18.1712 6.57056L18.5459 7.33526L17.3989 7.8782L19.0048 11.2353L18.0642 11.6788L16.4583 8.31409Z" fill="#E57C13"/>
    <path d="M20.3352 5.88235L21.3523 5.73706L21.5893 7.35059C21.6658 7.88588 21.6734 8.53588 21.6964 9.02529L22.0099 7.9547L22.874 5.51529L23.9676 5.35471L24.6252 9.94294L23.6081 10.0882L23.3711 8.46706C23.2946 7.93176 23.287 7.30471 23.264 6.8L22.9505 7.86294L22.0864 10.31L20.9928 10.4706L20.3352 5.88235Z" fill="#E57C13"/>
    <path d="M26.6136 5.40823L28.143 5.66823C29.0912 5.82882 29.7795 6.21117 29.6724 7.08294C29.6444 7.28807 29.5572 7.48056 29.4213 7.63679C29.2855 7.79301 29.107 7.90616 28.9077 7.96235C29.1409 8.0546 29.3336 8.22685 29.4513 8.44825C29.569 8.66964 29.604 8.92571 29.5501 9.17059C29.3971 10.0959 28.5483 10.3865 27.5542 10.2182L25.8718 9.93529L26.6136 5.40823ZM27.5848 9.43059C28.1507 9.53 28.4718 9.37706 28.5407 8.96412C28.6095 8.55118 28.3495 8.35235 27.7759 8.26059L27.2101 8.17647L27.0112 9.35412L27.5848 9.43059ZM27.8142 7.48823C28.3189 7.5647 28.5789 7.39647 28.6248 7.06C28.6707 6.72353 28.4718 6.54 27.9977 6.45588L27.5083 6.37941L27.3401 7.41176L27.8142 7.48823Z" fill="#E57C13"/>
    <path d="M32.2801 6.73117L33.1978 7.19764L32.4331 8.65058C32.1883 9.13235 31.8213 9.66764 31.5536 10.0806L32.4178 9.3847L34.5436 7.85529L35.5225 8.35999L33.4272 12.4588L32.5095 11.9923L33.2742 10.5394C33.5266 10.05 33.8783 9.53764 34.1536 9.10941L33.2895 9.79L31.1636 11.3194L30.1848 10.8223L32.2801 6.73117Z" fill="#E57C13"/>
    <path d="M35.8743 11.4953C36.0677 11.2593 36.3079 11.0659 36.5797 10.9273C36.8515 10.7887 37.149 10.7078 37.4536 10.6899C37.7582 10.6719 38.0632 10.7172 38.3494 10.8229C38.6357 10.9286 38.8969 11.0925 39.1167 11.3041C39.4833 11.6771 39.6869 12.1805 39.6825 12.7035L38.8261 12.7647C38.8153 12.4793 38.7012 12.2075 38.5049 12C38.0537 11.5565 37.3043 11.6024 36.6543 12.26C36.0043 12.9177 35.8896 13.6365 36.402 14.1106C36.5267 14.2282 36.6752 14.3177 36.8375 14.3731C36.9997 14.4285 37.1719 14.4485 37.3425 14.4318L37.2737 15.2882C36.9927 15.3142 36.7095 15.2768 36.4448 15.1789C36.1801 15.0809 35.9408 14.9249 35.7443 14.7224C35.5194 14.5136 35.3424 14.2586 35.2255 13.9749C35.1085 13.6912 35.0543 13.3855 35.0667 13.0789C35.079 12.7723 35.1576 12.472 35.297 12.1986C35.4364 11.9253 35.6334 11.6853 35.8743 11.4953Z" fill="#E57C13"/>
    <path d="M40.9975 15.3112L40.3475 14.2177L41.1122 13.7741L42.9398 16.833L42.1751 17.2765L41.5251 16.183L38.3134 18.1177L37.7781 17.223L40.9975 15.3112Z" fill="#E57C13"/>
    <path d="M27.5845 42.5041C27.585 42.7042 27.6267 42.902 27.7068 43.0853C27.8761 43.4834 28.1578 43.8236 28.5174 44.0641C28.9767 44.3767 29.4986 44.5855 30.0468 44.6759C30.3374 44.7218 30.6204 44.7676 30.9109 44.7906C31.1809 44.8059 31.4516 44.8059 31.7215 44.7906L32.5321 44.7218H32.5933C32.5776 44.669 32.5571 44.6178 32.5321 44.5688C32.4016 44.3251 32.2339 44.1032 32.0351 43.9112C31.8207 43.7173 31.5816 43.5527 31.3239 43.4218C31.0142 43.265 30.6886 43.1419 30.3527 43.0547C29.8709 42.9324 29.3892 42.8176 28.8998 42.7182L27.6762 42.5118L27.5845 42.5041Z" fill="#E57C13"/>
    <path d="M31.2781 42.2518C31.2952 42.3199 31.3182 42.3865 31.3469 42.4506C31.4405 42.6831 31.5793 42.8947 31.7552 43.0732C31.931 43.2517 32.1406 43.3935 32.3716 43.4906C32.8086 43.6966 33.2881 43.7962 33.771 43.7812C34.0265 43.7847 34.2819 43.7719 34.5357 43.7429C34.7935 43.7076 35.0489 43.6565 35.3004 43.59C35.6293 43.4906 35.9581 43.3682 36.2945 43.2612H36.3481C36.3022 43.2 36.264 43.1388 36.2181 43.0853C35.9047 42.729 35.5002 42.4646 35.0481 42.3206C34.5063 42.168 33.943 42.106 33.381 42.1371C32.7157 42.1371 32.0504 42.2135 31.3851 42.2518H31.2781Z" fill="#E57C13"/>
    <path d="M42.7715 30.8424C43.1303 30.8308 43.4803 30.7281 43.7885 30.5441C44.2188 30.2746 44.5657 29.8907 44.7903 29.4353C44.9558 29.094 45.0816 28.7348 45.165 28.3647C45.2504 27.9983 45.2991 27.6243 45.3103 27.2483C45.3103 26.9118 45.3103 26.5753 45.3103 26.2465C45.3138 26.216 45.3138 26.1852 45.3103 26.1547C45.1365 26.2071 44.9733 26.2899 44.8285 26.3994C44.571 26.5908 44.344 26.8203 44.1556 27.08C43.9308 27.3827 43.7434 27.7113 43.5974 28.0588C43.256 28.9554 42.98 29.8754 42.7715 30.8118V30.8424Z" fill="#E57C13"/>
    <path d="M42.8332 29.1371C43.2706 28.2876 43.4515 27.3292 43.3537 26.3787C43.256 25.4283 42.8838 24.5268 42.2827 23.7841C41.8984 24.6439 41.7178 25.5808 41.755 26.5218C41.7979 27.4927 42.1794 28.418 42.8332 29.1371Z" fill="#E57C13"/>
    <path d="M39.4297 40.967C39.2688 40.8138 39.0825 40.6897 38.8792 40.6C38.3618 40.3765 37.7908 40.3074 37.235 40.4011C36.5797 40.5082 35.935 40.6719 35.308 40.8906C35.048 40.9747 34.7803 41.0664 34.4897 41.1811L34.5662 41.2653C34.8275 41.6736 35.2352 41.9664 35.7056 42.0835C36.2702 42.2316 36.866 42.2103 37.4186 42.0223C38.1119 41.8153 38.764 41.4893 39.3456 41.0588L39.4297 40.967Z" fill="#E57C13"/>
    <path d="M41.9382 38.0536L41.5023 37.8853C41.1956 37.7911 40.872 37.765 40.5541 37.8088C40.3524 37.8375 40.153 37.881 39.9576 37.9388C39.4419 38.104 38.9487 38.3326 38.4894 38.6194L37.3653 39.3077L37.2659 39.3689C37.4858 39.6225 37.7716 39.8105 38.0917 39.9118C38.4017 39.9979 38.7257 40.0214 39.0448 39.9807C39.3639 39.9399 39.6716 39.8359 39.95 39.6747C40.2134 39.5309 40.4687 39.3726 40.7147 39.2006C41.1446 38.8926 41.5327 38.5302 41.8694 38.1224L41.9382 38.0536Z" fill="#E57C13"/>
    <path d="M43.7735 34.6888C43.5682 34.6254 43.3532 34.5995 43.1388 34.6123C42.5262 34.6463 41.9388 34.8679 41.4564 35.2471C40.8748 35.6778 40.33 36.1561 39.8276 36.6771C39.7205 36.7841 39.6135 36.8912 39.4988 37.0212L39.7358 37.1588C40.0508 37.3145 40.4034 37.3782 40.7529 37.3423C41.3512 37.2641 41.9087 36.9961 42.3435 36.5776C42.9061 36.066 43.3791 35.4637 43.7429 34.7959V34.7347C43.7429 34.7347 43.7735 34.7118 43.7735 34.6888Z" fill="#E57C13"/>
    <path d="M44.9434 31.003H44.8287C44.4785 31.0202 44.1353 31.1067 43.8189 31.2577C43.5025 31.4087 43.2193 31.621 42.9857 31.8824C42.4833 32.3808 42.0394 32.935 41.6628 33.5341C41.5098 33.7559 41.3645 33.9853 41.2192 34.2147V34.2759C41.4614 34.3754 41.7226 34.4198 41.9839 34.4059C42.3182 34.363 42.6403 34.2528 42.9308 34.082C43.2213 33.9111 43.4741 33.6831 43.6739 33.4118C43.9128 33.1446 44.1183 32.8493 44.2857 32.5324C44.4463 32.2035 44.6069 31.8747 44.7598 31.5383C44.8325 31.364 44.8938 31.1852 44.9434 31.003Z" fill="#E57C13"/>
    <path d="M31.37 39.3765H31.3241C30.5821 39.6672 29.8637 40.0149 29.1753 40.4165C28.6718 40.7067 28.2425 41.1098 27.9212 41.5941C27.7752 41.8107 27.669 42.0515 27.6077 42.3053C27.6077 42.3053 27.6077 42.3589 27.6077 42.3894H27.7683C28.5146 42.3429 29.2446 42.1506 29.9171 41.8236C30.3411 41.6269 30.7092 41.3272 30.9877 40.9518C31.3042 40.5084 31.4458 39.9638 31.3853 39.4224C31.3789 39.4076 31.3738 39.3922 31.37 39.3765Z" fill="#E57C13"/>
    <path d="M33.9162 38.3594C33.149 38.9035 32.4639 39.5551 31.8821 40.2941C31.5189 40.7638 31.3033 41.3307 31.2627 41.923C31.2627 41.9994 31.2627 42.0836 31.2627 42.1753H31.3086C32.0803 41.9425 32.7837 41.5252 33.358 40.9594C33.7028 40.622 33.9419 40.1916 34.0462 39.7206C34.1315 39.297 34.0998 38.8581 33.9545 38.4512L33.9162 38.3594Z" fill="#E57C13"/>
    <path d="M39.9194 27.21C40.0112 27.47 40.0877 27.7147 40.1718 27.9747C40.3318 28.4408 40.5287 28.8934 40.7606 29.3283C41.1001 29.994 41.6879 30.4995 42.3971 30.7353L42.6112 30.7965C42.6112 30.7277 42.6112 30.6741 42.6112 30.6206C42.6252 29.8694 42.4545 29.1263 42.1141 28.4565C41.9667 28.1544 41.7523 27.89 41.4873 27.6833C41.2223 27.4765 40.9136 27.333 40.5847 27.2636C40.3676 27.2082 40.1426 27.1901 39.9194 27.21Z" fill="#E57C13"/>
    <path d="M36.0806 36.7841L35.6906 37.2812C35.2899 37.7901 34.9462 38.3414 34.6659 38.9253C34.3811 39.5333 34.2954 40.2157 34.4212 40.8753C34.4177 40.9262 34.4177 40.9773 34.4212 41.0282H34.4671C35.1147 40.6228 35.6571 40.0699 36.05 39.4147C36.2521 39.0986 36.3769 38.7393 36.4141 38.3659C36.4513 37.9925 36.3999 37.6157 36.2641 37.2659C36.2565 37.1129 36.1647 36.96 36.0806 36.7841Z" fill="#E57C13"/>
    <path d="M37.2354 39.3229C37.4265 39.0706 37.6177 38.8412 37.786 38.6041C37.8711 38.4876 37.9429 38.3619 38.0001 38.2294C38.1356 37.9833 38.2531 37.7277 38.3518 37.4647C38.5333 37.0316 38.5829 36.5546 38.4945 36.0935C38.406 35.6324 38.1835 35.2076 37.8548 34.8723C37.8354 34.8482 37.8122 34.8275 37.786 34.8112C37.6789 35.0482 37.5642 35.27 37.4648 35.4994C37.2205 36.0365 37.0334 36.5978 36.9065 37.1741C36.7729 37.8324 36.8449 38.5158 37.113 39.1317C37.1492 39.1983 37.1901 39.2622 37.2354 39.3229Z" fill="#E57C13"/>
    <path d="M39.0627 32.4865L38.9939 32.7541C38.7895 33.4808 38.6866 34.2322 38.688 34.987C38.6901 35.6629 38.916 36.319 39.3304 36.8529L39.4298 36.96L39.5598 36.7306C39.871 36.1551 40.0756 35.5282 40.1639 34.88C40.2416 34.4321 40.1867 33.9713 40.006 33.5543C39.8253 33.1372 39.5267 32.782 39.1468 32.5323L39.0627 32.4865Z" fill="#E57C13"/>
    <path d="M39.8659 29.963C39.7911 30.7275 39.8142 31.4984 39.9347 32.2571C40.0032 32.662 40.1458 33.0509 40.3553 33.4041C40.5385 33.704 40.7773 33.9662 41.0588 34.1765H41.0971C41.122 34.1143 41.1424 34.0504 41.1582 33.9853C41.3948 33.2847 41.4706 32.5398 41.38 31.8059C41.3432 31.3918 41.187 30.9973 40.9305 30.6702C40.674 30.3431 40.3281 30.0974 39.9347 29.963H39.8888H39.8659Z" fill="#E57C13"/>
    <path d="M22.8589 42.2823C22.8578 42.4841 22.8189 42.6838 22.7442 42.8712C22.5931 43.2729 22.3271 43.6213 21.9794 43.8729C21.5242 44.1989 21.0015 44.4184 20.45 44.5153C20.1671 44.5688 19.8842 44.6223 19.5936 44.6529C19.303 44.6835 19.0506 44.6529 18.783 44.6529C18.5153 44.6529 18.24 44.6529 17.9724 44.5994H17.9036C17.9036 44.5459 17.9418 44.4924 17.9647 44.4465C18.0878 44.1998 18.2506 43.975 18.4465 43.7812C18.6575 43.5802 18.8941 43.408 19.15 43.2688C19.4535 43.114 19.771 42.9886 20.0983 42.8941C20.58 42.7641 21.0618 42.6418 21.5512 42.5271C22.0406 42.4123 22.3618 42.3665 22.7747 42.29L22.8589 42.2823Z" fill="#E57C13"/>
    <path d="M19.1577 42.1294C19.1577 42.1982 19.1194 42.2671 19.0965 42.3282C18.9135 42.7826 18.5632 43.1494 18.1177 43.3529C17.8993 43.4584 17.6714 43.5429 17.4371 43.6053C17.2031 43.6538 16.9649 43.6794 16.7259 43.6818C16.4712 43.6975 16.2158 43.6975 15.9612 43.6818C15.7033 43.6586 15.4476 43.6151 15.1965 43.5518C14.86 43.46 14.5235 43.3453 14.1947 43.2459H14.1335C14.1794 43.1847 14.2177 43.1235 14.2635 43.07C14.5656 42.7114 14.9634 42.4462 15.4106 42.3053C15.9494 42.136 16.5134 42.061 17.0777 42.0835C17.743 42.0835 18.4082 42.1218 19.0659 42.1447L19.1577 42.1294Z" fill="#E57C13"/>
    <path d="M7.4117 30.9953C7.04929 30.9964 6.69307 30.9014 6.37934 30.72C5.94249 30.4649 5.58712 30.091 5.35464 29.6417C5.18308 29.3033 5.04971 28.9468 4.95699 28.5788C4.85809 28.2141 4.79912 27.8398 4.78111 27.4623C4.78111 27.1259 4.78111 26.797 4.78111 26.4606C4.77804 26.4301 4.77804 26.3993 4.78111 26.3688C4.95536 26.4163 5.11889 26.4968 5.26287 26.6059C5.52348 26.7926 5.75757 27.0139 5.95876 27.2635C6.18391 27.5604 6.37141 27.884 6.51699 28.227C6.88039 29.1144 7.1794 30.0267 7.4117 30.957C7.40876 30.9696 7.40876 30.9827 7.4117 30.9953Z" fill="#E57C13"/>
    <path d="M7.28938 29.29C6.82039 28.4486 6.61014 27.4875 6.68495 26.5271C6.75977 25.5667 7.11633 24.6498 7.70997 23.8912C8.11778 24.7396 8.31953 25.6724 8.29879 26.6135C8.29194 27.5975 7.93398 28.5466 7.28938 29.29Z" fill="#E57C13"/>
    <path d="M10.9751 41.0588C11.1349 40.9037 11.3181 40.7747 11.518 40.6765C12.0278 40.4454 12.5924 40.3632 13.1469 40.4394C13.806 40.5306 14.4559 40.6791 15.0892 40.883C15.3492 40.9594 15.6169 41.0435 15.9151 41.1582C15.8877 41.1818 15.8622 41.2073 15.8386 41.2347C15.5931 41.6492 15.1996 41.9553 14.7375 42.0912C14.1809 42.2554 13.5887 42.2554 13.0322 42.0912C12.3239 41.8934 11.6561 41.5724 11.0592 41.143L10.9751 41.0588Z" fill="#E57C13"/>
    <path d="M8.39819 38.1835L8.83408 38.0076C9.13563 37.9019 9.45767 37.8679 9.77466 37.9082C9.97616 37.931 10.1756 37.9693 10.3711 38.0229C10.8941 38.1711 11.3954 38.3871 11.8623 38.6653L13.0017 39.3229L13.1011 39.3918C12.8842 39.6487 12.6013 39.8417 12.2829 39.95C11.9761 40.0439 11.6536 40.0754 11.3344 40.0425C11.0153 40.0097 10.7059 39.9132 10.4247 39.7588C10.1607 39.6216 9.90527 39.4684 9.65996 39.3C9.22306 39.0011 8.82492 38.6492 8.47466 38.2524L8.39819 38.1835Z" fill="#E57C13"/>
    <path d="M6.48657 34.8647C6.69069 34.7953 6.90586 34.7642 7.12128 34.7729C7.73011 34.7995 8.31774 35.0043 8.81128 35.3617C9.40057 35.7836 9.95545 36.2515 10.4707 36.7612L10.8072 37.09C10.723 37.1435 10.6466 37.197 10.5701 37.2353C10.2591 37.4035 9.90504 37.4754 9.55304 37.4417C8.94852 37.3693 8.38442 37.1007 7.94716 36.677C7.37554 36.1837 6.89198 35.5967 6.51716 34.9412V34.88L6.48657 34.8647Z" fill="#E57C13"/>
    <path d="M5.22461 31.2094H5.33931C6.03671 31.2137 6.70537 31.4877 7.2052 31.9741C7.71296 32.4646 8.16672 33.0081 8.55873 33.5953C8.71932 33.817 8.87226 34.0464 9.0252 34.2682V34.3217C8.78673 34.4348 8.52409 34.4873 8.26049 34.4747C7.92643 34.4465 7.60161 34.3506 7.30581 34.1928C7.01 34.0351 6.74941 33.8187 6.5399 33.557C6.29464 33.2971 6.08388 33.0067 5.91284 32.6929C5.73696 32.3717 5.56873 32.0506 5.40814 31.7141C5.33932 31.5764 5.29343 31.4006 5.22461 31.2094Z" fill="#E57C13"/>
    <path d="M18.997 39.2465H19.0429C19.7907 39.5201 20.5166 39.8498 21.2147 40.2329C21.7246 40.5126 22.1639 40.9049 22.4994 41.38C22.6482 41.5941 22.7594 41.832 22.8282 42.0835C22.8332 42.1113 22.8332 42.1398 22.8282 42.1676H22.7058C21.9564 42.1457 21.2185 41.9768 20.5341 41.6706C20.1014 41.4884 19.7243 41.1957 19.4405 40.8218C19.1118 40.383 18.9566 39.8384 19.0047 39.2923C19.0047 39.2923 18.9894 39.2847 18.997 39.2465Z" fill="#E57C13"/>
    <path d="M16.4275 38.2982C17.2026 38.8165 17.9025 39.4393 18.5075 40.1488C18.8812 40.6107 19.1124 41.1713 19.1728 41.7624C19.1728 41.8465 19.1728 41.923 19.1728 42.0147H19.1269C18.3463 41.8 17.6298 41.3984 17.0392 40.8447C16.6883 40.5147 16.4411 40.0895 16.3281 39.6212C16.2329 39.2003 16.2541 38.7615 16.3892 38.3518L16.4275 38.2982Z" fill="#E57C13"/>
    <path d="M10.1496 27.2941C10.0731 27.5541 9.99667 27.8065 9.9202 28.0588C9.77398 28.5328 9.58721 28.9933 9.36197 29.4353C9.03689 30.109 8.46018 30.628 7.75609 30.8806L7.54962 30.9418C7.54962 30.8729 7.54962 30.8271 7.54962 30.7735C7.51648 30.0217 7.6688 29.2732 7.99314 28.5941C8.12947 28.293 8.33137 28.0262 8.58408 27.8132C8.83679 27.6002 9.13392 27.4463 9.45373 27.3629C9.6802 27.3012 9.91544 27.2779 10.1496 27.2941Z" fill="#E57C13"/>
    <path d="M14.2254 36.7765L14.6307 37.2582C15.0409 37.7582 15.3948 38.3019 15.686 38.8794C15.988 39.4819 16.0923 40.1642 15.9842 40.8294C15.9751 40.8787 15.9623 40.9273 15.946 40.9747H15.9001C15.2525 40.5877 14.7029 40.0565 14.2942 39.4223C14.0855 39.1097 13.9527 38.7526 13.9062 38.3796C13.8598 38.0066 13.901 37.6278 14.0266 37.2735C14.0572 37.1053 14.1413 36.9523 14.2254 36.7765Z" fill="#E57C13"/>
    <path d="M13.155 39.3382C12.9561 39.0935 12.765 38.8718 12.5814 38.6347C12.4886 38.5203 12.4066 38.3974 12.3367 38.2676C12.1991 38.0204 12.0765 37.765 11.9697 37.5029C11.7772 37.0737 11.7158 36.5972 11.7934 36.1332C11.8709 35.6692 12.0839 35.2386 12.4055 34.8953L12.4667 34.8341C12.5814 35.0635 12.7038 35.2853 12.8108 35.5147C13.0705 36.044 13.2706 36.6006 13.4073 37.1741C13.5591 37.83 13.503 38.5169 13.2467 39.1394C13.2238 39.2006 13.1932 39.2541 13.155 39.3382Z" fill="#E57C13"/>
    <path d="M11.1358 32.5477C11.1358 32.6394 11.1893 32.7312 11.2123 32.8153C11.4345 33.5369 11.5555 34.2858 11.5717 35.0406C11.5845 35.7137 11.3748 36.3723 10.9752 36.9141C10.9475 36.9546 10.9168 36.9929 10.8834 37.0288C10.8338 36.9573 10.7878 36.8833 10.7458 36.8071C10.4188 36.2402 10.1984 35.6181 10.0958 34.9718C10.0073 34.5246 10.0513 34.0613 10.2225 33.6388C10.3937 33.2163 10.6845 32.853 11.0593 32.5936L11.1358 32.5477Z" fill="#E57C13"/>
    <path d="M10.2795 30.0394C10.3726 30.8013 10.3726 31.5717 10.2795 32.3335C10.2202 32.7399 10.0853 33.1315 9.88186 33.4882C9.71824 33.7839 9.49725 34.0438 9.23186 34.2529C9.23186 34.2529 9.23186 34.2529 9.18598 34.2529L9.11715 34.0694C8.86531 33.3723 8.77383 32.6274 8.84951 31.89C8.87444 31.4763 9.01933 31.0788 9.26649 30.7461C9.51366 30.4134 9.85238 30.1598 10.2413 30.0165H10.2872L10.2795 30.0394Z" fill="#E57C13"/>
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="-1" y="-1" width="52" height="52">
    <path d="M25 51C39.3594 51 51 39.3594 51 25C51 10.6406 39.3594 -1 25 -1C10.6406 -1 -1 10.6406 -1 25C-1 39.3594 10.6406 51 25 51Z" fill="#A9BEFF"/>
    </mask>
    <g mask="url(#mask0)">
    <path opacity="0.3" d="M5.44668 28.7548C32.5226 28.7548 54.472 6.80538 54.472 -20.2705C54.472 -47.3465 32.5226 -69.2958 5.44668 -69.2958C-21.6292 -69.2958 -43.5786 -47.3465 -43.5786 -20.2705C-43.5786 6.80538 -21.6292 28.7548 5.44668 28.7548Z" fill="url(#paint0_linear)"/>
    </g>
    </g>
    <rect x="-978.5" y="-451.5" width="1768" height="796" rx="4.5" stroke="#7B61FF" strokeDasharray="10 5"/>
    <defs>
    <linearGradient id="paint0_linear" x1="-11.7821" y1="-52.5564" x2="26.1702" y2="18.5689" gradientUnits="userSpaceOnUse">
    <stop stopColor="white" stopOpacity="0"/>
    <stop offset="0.35" stopColor="white" stopOpacity="0.01"/>
    <stop offset="0.49" stopColor="white" stopOpacity="0.03"/>
    <stop offset="0.59" stopColor="white" stopOpacity="0.08"/>
    <stop offset="0.67" stopColor="white" stopOpacity="0.15"/>
    <stop offset="0.74" stopColor="white" stopOpacity="0.24"/>
    <stop offset="0.8" stopColor="white" stopOpacity="0.35"/>
    <stop offset="0.85" stopColor="white" stopOpacity="0.47"/>
    <stop offset="0.9" stopColor="white" stopOpacity="0.62"/>
    <stop offset="0.95" stopColor="white" stopOpacity="0.79"/>
    <stop offset="1" stopColor="white"/>
    </linearGradient>
    <clipPath id="clip0">
    <rect width="50" height="50" fill="white"/>
    </clipPath>
    <clipPath id="clip1">
    <rect x="12" y="12.7647" width="26" height="26" rx="13" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);

export default IconCivicAktivist;
