import { FC } from 'react';

import {
  Drawer as DrawerMaterial,
  DrawerProps as DrawerMaterialProps,
  DrawerProps,
  SwipeableDrawer,
  SwipeableDrawerProps,
} from '@mui/material';
import { SafeOmit } from 'utils';

import DrawerBase from './DrawerBase';
import * as styles from './style';
import { IDrawerBase } from './types';

interface IDrawer extends IDrawerBase {
  swipeable?: boolean;
  swipeableProps?: SafeOmit<SwipeableDrawerProps, 'open' | 'onOpen'> & Partial<Pick<SwipeableDrawerProps, 'onOpen'>>;
}

// оригинальный sx перенаправляется в DrawerBase
const Drawer: FC<IDrawer & DrawerProps> = ({
  // drawer base props
  header,
  footer,
  isMobile,
  sx,
  indicatorSize,
  indicatorContainerSx,
  crossSx,
  contentSx,
  isFullHeight,
  // other props
  ...props
}) => {
  const drawerBaseProps: IDrawerBase = {
    header,
    footer,
    isMobile,
    sx,
    indicatorSize,
    indicatorContainerSx,
    crossSx,
    contentSx,
    isFullHeight,
  };

  const { swipeable, swipeableProps, children } = props;

  if (swipeable && swipeableProps) {
    const combinedSwipableProps: SafeOmit<DrawerMaterialProps, 'onClose'> & SafeOmit<SwipeableDrawerProps, 'open'> = {
      ...props,
      ...swipeableProps,
      onOpen: swipeableProps.onOpen ?? (() => null),
    };
    return (
      <SwipeableDrawer
        {...combinedSwipableProps}
        PaperProps={{ sx: styles.drawerPaper(isFullHeight, isMobile, props.anchor) }}
        open={!!props.open}
      >
        <DrawerBase {...props} {...drawerBaseProps}>
          {children}
        </DrawerBase>
      </SwipeableDrawer>
    );
  }

  return (
    <DrawerMaterial {...props} PaperProps={{ sx: styles.drawerPaper(isMobile) }}>
      <DrawerBase {...props} {...drawerBaseProps}>
        {children}
      </DrawerBase>
    </DrawerMaterial>
  );
};

export default Drawer;
