/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';
import { TextareaAutosize, TextareaAutosizeProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Colors } from '../../style/variables';

import './index.scss';

const useStyles = makeStyles({
  root: () => ({
    marginTop: '28px',
    boxSizing: 'border-box',
    border: '1px solid',
    borderRadius: '8px',
    outline: 'none',
    padding: '8px 16px',
    fontSize: 16,
    minWidth: '100%',
    maxWidth: '100%',
    minHeight: '87px',
    borderColor: Colors['control-stroke']
  })
});

const TextArea: React.FC<TextareaAutosizeProps> = (props) => {
  const classes = useStyles();
  const { placeholder } = props;
  return (
    <TextareaAutosize
      className={`${classes.root}`}
      maxRows={Infinity}
      placeholder={placeholder}
      {...props}
    />
  );
};

export default TextArea;
