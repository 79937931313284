import { FC, useContext, useMemo } from 'react';
import { connect } from 'react-redux';

import { ICommonResponse, IDictionaryItem, IEventKind, ILocation, ISportReward } from 'api/types';
import { SectionCodes, SportClubParentCodes } from 'const';
import { useAppSelector } from 'hooks';
import { IRootState } from 'reducers';
import { createSelector } from 'reselect';
import { sectionRefArchiveFalseSelector } from 'selectors';

import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';
import { ActivityBlock, AffiliationBlock, OccupationBlock, RewardBlock } from '../../primaryBlocks';
import { filterPersonsObjects } from '../../selectors';

interface ISportPrimaryBlockProps {
  eventKinds: ICommonResponse<IEventKind>;
  sportRewards: ICommonResponse<ISportReward>;
  sportClubs: ICommonResponse<ILocation>;
  sportObjectsDictionary: IDictionaryItem[];
}

const SportPrimaryBlock: FC<ISportPrimaryBlockProps> = ({
  eventKinds,
  sportRewards,
  sportClubs,
  sportObjectsDictionary,
}) => {
  const portfolioSections = useAppSelector(sectionRefArchiveFalseSelector);

  const { onOpenEventForm, formData } = useContext(LocalDataEntryFormContext);

  // affilation
  const affilationTypeOptions = useMemo(() => {
    return portfolioSections.content.filter((section) => section.parentId === SectionCodes.sportAffilation);
  }, [portfolioSections.content]);
  const sportClubsOptions = sportClubs?.content.filter((club) => club.parentId === SportClubParentCodes.sportClub);

  const occupationTypeOptions = useMemo(() => {
    return portfolioSections.content.filter(
      (section) => section.parentId === SectionCodes.sportOccupation && !section.isArchive,
    );
  }, [portfolioSections.content]);

  const activityTypeOptions = useMemo(() => {
    return portfolioSections.content.filter((section) => section.parentId === SectionCodes.sportEvent);
  }, [portfolioSections.content]);
  const activityNameOptions = useMemo(() => {
    return eventKinds.content.filter((eventKind) => eventKind.categoryCode === formData.typeCode);
  }, [eventKinds.content, formData.typeCode]);

  const rewardTypeOptions = useMemo(
    () => portfolioSections.content.filter((section) => section.parentId === SectionCodes.sportReward),
    [portfolioSections],
  );
  const rewardItemOptions = useMemo(() => {
    switch (formData.typeCode) {
      case SectionCodes.sportRewardTourism:
        return sportObjectsDictionary.filter(
          (sportObject) =>
            sportObject.parentId === SectionCodes.sportEventTourism ||
            sportObject.parentId === SectionCodes.sportEventExpedition,
        );
      case SectionCodes.sportRewardCompetition:
        return sportObjectsDictionary.filter(
          (sportObject) => sportObject.parentId === SectionCodes.sportEventCompetition,
        );
      default:
        return [];
    }
  }, [formData.typeCode, sportObjectsDictionary]);
  const rewardResultOptions = sportRewards.content.filter(
    (sportReward) => sportReward.categoryCode === formData.typeCode,
  );

  const getActivityNameInputLabel = () => {
    if (formData.typeCode === SectionCodes.sportEventCompetition) return 'Название соревнования';
    return 'Название';
  };
  const getActivityNameInputPlaceholder = () => {
    if (formData.typeCode === SectionCodes.sportEventCompetition) return 'Выберите соревнование...';
    if (formData.typeCode === SectionCodes.sportEventExpedition) return 'Выбери поход / экспедицию...';
    if (formData.typeCode === SectionCodes.sportEventTourism) return 'Выбери первенство по туризму...';
    return 'Выберите название...';
  };

  const getSportRewardNameInputLabel = () => {
    if (formData.typeCode === SectionCodes.sportRewardTourism) return 'Поход / экспедиция';
    if (formData.typeCode === SectionCodes.sportRewardCompetition) return 'Спортивное соревнование';
    return '';
  };
  const getSportRewardNameInputPlaceholder = () => {
    if (formData.typeCode === SectionCodes.sportRewardTourism)
      return 'Выберите поход / экспедицию, за которую была получена награда...';
    if (formData.typeCode === SectionCodes.sportRewardCompetition) return 'Выберите спортивное соревнование...';
    return '';
  };

  const handleOpenEventForm = () => {
    onOpenEventForm(SectionCodes.sport, SectionCodes.sportEvent);
  };

  return (
    <>
      {formData.dataKind === SectionCodes.sportAffilation && (
        <AffiliationBlock
          typeOptions={affilationTypeOptions}
          nameOptions={sportClubsOptions}
          nameInputLabel="Название спортивной организации"
          nameInputPlaceholder="Выберите название спортивной организации (если есть)"
        />
      )}
      {formData.dataKind === SectionCodes.sportOccupation && <OccupationBlock typeOptions={occupationTypeOptions} />}
      {formData.dataKind === SectionCodes.sportEvent && (
        <ActivityBlock
          typeOptions={activityTypeOptions}
          nameOptions={activityNameOptions}
          nameInputLabel={getActivityNameInputLabel()}
          nameInputPlaceholder={getActivityNameInputPlaceholder()}
          hasOtherNameOption
        />
      )}
      {formData.dataKind === SectionCodes.sportReward && (
        <RewardBlock
          shortType
          typeOptions={rewardTypeOptions}
          itemOptions={rewardItemOptions}
          resultOptions={rewardResultOptions}
          isNameOptionActive={
            formData.typeCode === SectionCodes.sportRewardTourism ||
            formData.typeCode === SectionCodes.sportRewardCompetition
          }
          onOpenEventForm={handleOpenEventForm}
          nameInputLabel={getSportRewardNameInputLabel()}
          nameInputPlaceholder={getSportRewardNameInputPlaceholder()}
        />
      )}
    </>
  );
};

const sportObjectsSelector = createSelector([(state: IRootState) => state.personsObjects], (personsObjects) => {
  return filterPersonsObjects(personsObjects.content, [SectionCodes.sportEvent]);
});

export default connect((state: IRootState) => ({
  eventKinds: state.eventKinds,
  sportRewards: state.sportRewardKinds,
  sportClubs: state.sportClubs,
  sportObjectsDictionary: sportObjectsSelector(state),
}))(SportPrimaryBlock);
