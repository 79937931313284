/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useEffect, useMemo, useRef, useState } from 'react';

import Masonry from 'react-masonry-component';
import { connect } from 'react-redux';
import { useMediaQuery } from '@mui/material';

import InterestHeader from './Header';
import InterestStepFooter from './StepFooter';
import InterestEntityActions from './EntityActions';
import { ViewDialog } from '../../../common/Dialog';
import {
  IBubblePopperData,
  IInterest,
  IInterestAction,
  IInterestChild,
  IInterestKind,
  IInterestSection
} from '../types';
import {
  initialBubblePopperData
} from '../utils';
import InterestBubble from './bubble';
import { getItemAndIndexFromArrayById, isDefined } from '../../../../utils/common';
import { superMeshikPortrait } from '../../../../images';
import { IRootState } from '../../../../reducers';
import { InterestGroupKindsState } from '../../../../reducers/interests/interestGroupKinds';
import { InterestObjectKindsState } from '../../../../reducers/interests/interestObjectKinds';
import { InterestActionKindsState } from '../../../../reducers/interests/interestActionKinds';
import { InterestsProgressBarContext } from '../../../../context';
import { useInterestsProgressBar } from '../../../../hooks';

import './index.scss';

interface IInterestsBubblesProps {
  interestSections: IInterestSection[],
  lastVisitedSectionIndex: number,
  selectedInterests: IInterest[],
  interestGroupKinds: InterestGroupKindsState,
  interestActionKinds: InterestActionKindsState;
  interestObjectKinds: InterestObjectKindsState;
  setSelectedInterests: React.Dispatch<React.SetStateAction<IInterest[]>>,
  onChangeStepForward: () => void,
  onChangeStepBack: () => void,
  setCurrentStepSection: React.Dispatch<React.SetStateAction<IInterestSection | undefined>>
}

const InterestsBubbles: React.FC<IInterestsBubblesProps> = ({
  interestSections,
  lastVisitedSectionIndex,
  selectedInterests,
  interestGroupKinds,
  interestActionKinds,
  interestObjectKinds,
  setSelectedInterests,
  onChangeStepBack,
  onChangeStepForward,
  setCurrentStepSection
}) => {
  const [currentSection, setCurrentSection] = useState<IInterestSection>(interestSections[0]);
  const [currentKinds, setCurrentKinds] = useState<number[] | undefined>();
  const [filterValue, setFilterValue] = useState('');
  const [bubblePopperData, setBubblePopperData] = useState<IBubblePopperData>(initialBubblePopperData);

  const isMobile = useMediaQuery('(max-width: 767px)', { noSsr: true });
  const [isMobileInfoOpen, setMobileInfoOpen] = useState(false);

  // используется для обновления компонента с бубликами, когда переключается режим отображения дочерних объектов
  const masonryComponentRef = useRef<React.ClassType<any, any, any>>(null);

  const {
    visibleSections,
    onStepForward: onProgressStepForward,
    onStepBack: onProgressStepBack,
    desktopRef: desktopProgressBarRef,
    mobileRef: mobileProgressBarRef
  } = useInterestsProgressBar({ interestSections, currentSection });

  useEffect(() => {
    if (isMobile) {
      setMobileInfoOpen(true);
    } else if (isMobileInfoOpen) {
      handleCloseMobileInfo();
    }
  }, [isMobile]);

  useEffect(() => {
    setCurrentStepSection(currentSection);
    setCurrentKinds(undefined);
  }, [currentSection]);

  const getBubbleOffset = (index: number, isMobileOffsets: boolean) => {
    if (isMobileOffsets) {
      return {
        top: Math.random() * 20,
        bottom: Math.random() * 20,
        right: Math.random() * 20,
        left: Math.random() * 20
      };
    }

    const top = index % 2 !== 0 ? Math.random() * 50 : 0;
    const right = index % 2 !== 0 ? Math.random() * 50 : 0;
    const left = index % 2 === 0 ? Math.random() * 50 : 0;
    const bottom = index % 2 === 0 ? Math.random() * 50 : 0;
    return {
      top,
      bottom,
      right,
      left
    };
  };

  const currentInterests: IInterest[] = useMemo(() => {
    const selectedInterestObjects = interestSections.map((section) => {
      return interestObjectKinds?.content?.[section.id].filter((interest) => interest.interestActionCode !== null);
    }).filter(isDefined);

    let count = 0;
    const interests = selectedInterestObjects.flatMap((sectionObjects, sectionIndex) => {
      const primaryInterestObjects = sectionObjects.filter((interest) => interest.parentId === null);
      const childrenInterestObjects = sectionObjects.filter((interest) => interest.parentId !== null);

      return primaryInterestObjects.map((interest, interestIndex): IInterest => {
        const interestActions: IInterestAction[] = interest.interestActionCode
          .map((actionCode) => interestActionKinds.content.find((actionKind) => actionKind.code === actionCode) || null)
          .filter(isDefined)
          .map((actionKind, actionIndex): IInterestAction => ({
            id: interestIndex + actionIndex + 1,
            code: actionKind.code,
            name: actionKind.value,
            isActive: false,
          }));

        const interestChildren: IInterestChild[] = childrenInterestObjects
          .filter((childInterest) => childInterest.parentId === interest.code)
          .map((childInterest, childIndex): IInterestChild => ({
            id: childIndex,
            code: childInterest.code,
            name: childInterest.value,
            isActive: false
          }));

        return {
          id: ++count,
          code: interest.code,
          headId: interestSections[sectionIndex].id,
          groupId: interest.interestGroupCode,
          kindId: interest.interestGroupCode,
          name: interest.value,
          offsets: getBubbleOffset(interestIndex, isMobile),
          interestActions,
          children: interestChildren
        };
      });
    });

    return interests;
  }, [interestObjectKinds.content, interestActionKinds.content, interestSections, isMobile]);

  const currentSectionInterests: IInterest[] = useMemo(() => {
    return currentInterests
      .filter((interest) => interest.headId === currentSection.id)
      .filter((interest) => {
        if (!currentKinds) return true;
        return !!currentKinds.find((kindId) => kindId === interest.kindId) || false;
      })
      .filter((interest) => {
        if (!filterValue) return true;
        return interest.name.toLowerCase().includes(filterValue.toLowerCase());
      });
  }, [currentInterests, currentKinds, filterValue, currentSection]);

  const currentInterestKinds: IInterestKind[] = useMemo(() => {
    const selectedInterestObjects = interestSections
      .map((section) => interestObjectKinds?.content?.[section.id])
      .filter(isDefined);

    let count = 0;
    const interestKinds: IInterestKind[] = selectedInterestObjects.flatMap((sectionObjects, sectionIndex) => {
      const currentInterestGroupCodes = new Set(
        sectionObjects
          .filter((objectKind) => objectKind.interestGroupCode !== null)
          .map((objectKind) => objectKind.interestGroupCode)
      );

      const currentInterestGroupKinds = new Set(
        interestGroupKinds.content.filter((groupKind) => currentInterestGroupCodes.has(groupKind.code))
      );

      return Array.from(currentInterestGroupKinds).map((groupKind): IInterestKind => ({
        id: ++count,
        code: groupKind.code,
        name: groupKind.value,
        parentId: interestSections[sectionIndex].id
      }));

    });
    return interestKinds;
  }, [currentSection, interestObjectKinds.content, interestGroupKinds.content, interestSections]);

  const currentSectionInterestKinds = currentInterestKinds.filter((interestKind) => interestKind.parentId === currentSection.id);

  const handleCloseMobileInfo = () => {
    setMobileInfoOpen(false);
  };

  const handleChangeCurrentSection = (interestSection: IInterestSection) => {
    setCurrentSection((prevState) => {
      const prevSectionIndex = interestSections.findIndex((section) => section.id === prevState.id);
      const newSectionIndex = interestSections.findIndex((section) => section.id === interestSection.id);

      if (newSectionIndex > prevSectionIndex) {
        onProgressStepForward(interestSection);
      } else {
        onProgressStepBack(interestSection);
      }

      return interestSection;
    });
  };

  const resetPopperData = () => {
    setBubblePopperData(initialBubblePopperData);
  };

  const removeSelectedInterest = (interestIndex: number) => {
    setSelectedInterests((prevstate) => {
      const newState = [...prevstate];
      newState.splice(interestIndex, 1);
      return newState;
    });
  };

  const appendSelectedInterest = (interest: IInterest) => {
    setSelectedInterests((prevstate) => [
      ...prevstate,
      interest
    ]);
  };

  const initPopperData = (interest: IInterest, ref: any) => {
    setBubblePopperData({
      bubbleName: interest.name,
      isOpen: true,
      actions: [...interest.interestActions],
      ref,
      bubbleId: interest.id
    });
  };

  const handleClickOnInterest = (interest: IInterest, ref: any) => {
    const [currentInterest = { ...interest, interestActions: [...interest.interestActions] }, interestIndex] = getItemAndIndexFromArrayById(selectedInterests, interest.id);
    const activeInterestsActions = currentInterest.interestActions.filter((action) => action.isActive);

    // SetTimeout нужен для того, чтобы при нажатии на бублик, если выделен другой, сначала отрабатывала функция закрытия popper, а потом нажатие на бублик
    setTimeout(() => {
      if (interestIndex < 0) {
        appendSelectedInterest(currentInterest);
        initPopperData(currentInterest, ref);
      } else if (activeInterestsActions.length === 0) {
        removeSelectedInterest(interestIndex);
        resetPopperData();
      } else if (!bubblePopperData.isOpen) {
        initPopperData(currentInterest, ref);
      } else {
        resetPopperData();
      }
    }, 0);
  };

  const handleClickOnInterestChild = (interestId: number, childId: number) => {
    setSelectedInterests((prevstate) => {
      const [selectedInterest, selectedInterestIndex]: [IInterest | undefined, number] = getItemAndIndexFromArrayById(prevstate, interestId);
      if (!selectedInterest) return prevstate;
      const currentChildIndex = selectedInterest?.children?.findIndex((action) => action.id === childId);
      const newChildren: IInterestChild[] = selectedInterest.children?.map((child, index) => {
        const newChild: IInterestChild = { ...child };
        if (index === currentChildIndex) {
          newChild.isActive = !newChild.isActive;
        }
        return newChild;
      }) ?? [];
      const newSelectedInterest: IInterest | undefined = selectedInterest ? {
        ...selectedInterest,
        children: newChildren
      } : undefined;
      const newState = [...prevstate];
      if (newSelectedInterest) newState.splice(selectedInterestIndex, 1, newSelectedInterest);
      return newState;
    });
  };

  const getInterestActions = (id: number) => {
    const selectedInterest = selectedInterests.find((selectedInterest) => selectedInterest.id === id);
    if (!selectedInterest) return;
    return [...selectedInterest.interestActions];
  };

  const getInterestChildren = (id: number) => {
    const selectedInterest = selectedInterests.find((selectedInterest) => selectedInterest.id === id);
    if (!selectedInterest) return;
    return selectedInterest.children ? [...selectedInterest.children] : [];
  };

  const handleStepForward = () => {
    const lastSectionId = interestSections[interestSections.length - 1].id;
    if (lastSectionId === currentSection.id) {
      onChangeStepForward();
      return;
    }

    setCurrentSection((prevstate) => {
      const currentSectionIndex = interestSections.findIndex((interestSection) => interestSection.id === prevstate.id);
      const newSection = interestSections[currentSectionIndex + 1];

      onProgressStepForward(newSection);

      return newSection;
    });
  };

  const handleStepBack = () => {
    const firstSectionId = interestSections[0].id;
    if (firstSectionId === currentSection.id) {
      onChangeStepBack();
      return;
    }

    setCurrentSection((prevstate) => {
      const currentSectionIndex = interestSections.findIndex((interestSection) => interestSection.id === prevstate.id);
      const newSection = interestSections[currentSectionIndex - 1];

      onProgressStepBack(newSection);

      return newSection;
    });
  };

  const getSubmitDisable = () => {
    const lastSectionId = interestSections[interestSections.length - 1].id;
    const selectedInterestsWithActiveActions = selectedInterests?.filter((interest) => interest.interestActions?.some((action) => action.isActive));
    if (lastSectionId === currentSection.id && !selectedInterestsWithActiveActions.length) return true;
    return false;
  };

  const updateBubblesComponent = () => {
    masonryComponentRef.current.forceUpdate();
  };

  return (
    <InterestsProgressBarContext.Provider
      value={{
        sections: interestSections,
        currentSection,
        visibleSections,
        lastVisitedSectionIndex,
        onChangeCurrentSection: handleChangeCurrentSection
      }}
    >
    <section className="interests-bubbles">
      <div className="interests-bubbles__content">
        <InterestHeader
          currentSection={currentSection}
          progressBarRef={desktopProgressBarRef}

          interestKinds={currentSectionInterestKinds}
          currentInterestKinds={currentKinds}
          filterValue={filterValue}
          setFilterValue={setFilterValue}
          setCurrentInterestKinds={setCurrentKinds}
        />
        <div className="interests-bubbles__bubble-container">
          <Masonry
            ref={masonryComponentRef}
            options={{
              transitionDuration: 500, horizontalOrder: false, gutter: 32
            }}
          >
            {currentSectionInterests.map((interest) => (
              <InterestBubble
                key={interest.id}
                interest={interest}
                theme={currentSection.theme}
                actions={getInterestActions(interest.id)}
                bubbleChildren={getInterestChildren(interest.id)}
                onClick={handleClickOnInterest}
                onChildClick={handleClickOnInterestChild}
                updateBubblesComponent={updateBubblesComponent}
              />
            ))}
          </Masonry>
        </div>
        <InterestStepFooter
          progressBarRef={mobileProgressBarRef}
          onSubmit={handleStepForward}
          submitDisabled={getSubmitDisable()}
          onBack={handleStepBack}
        />
        <InterestEntityActions
          bubblePopperData={bubblePopperData}
          selectedInterests={selectedInterests}
          theme={currentSection.theme}
          setBubblePopperData={setBubblePopperData}
          setSelectedInterests={setSelectedInterests}
          onToggleChild={handleClickOnInterestChild}
        />
        {isMobile && (
          <ViewDialog
            isOpen={isMobileInfoOpen}
            onClose={handleCloseMobileInfo}
            title=""
            className="info-dialog"
          >
            <div className="dialog-content">
              <img src={superMeshikPortrait} alt="кот" />
              <h5>Расскажи подробнее о своих увлечениях</h5>
              <span>
                На этом шаге тебе нужно детализировать выбранные ранее интересы. Выбери кружки, которые содержат твои увлечения.
              </span>
            </div>
          </ViewDialog>
        )}
      </div>
    </section>
    </InterestsProgressBarContext.Provider>
  );
};

export default connect(
  (state: IRootState) => ({
    interestGroupKinds: state.interestGroupKinds,
    interestActionKinds: state.interestActionKinds,
    interestObjectKinds: state.interestObjectKinds,
  })
)(InterestsBubbles);
