import { FC, PropsWithChildren } from 'react';

import { RadioGroup, styled } from '@mui/material';
import { ISwitcherProps, Switcher } from 'portfolio3/ui-kit';

const StyledRadioGroup = styled(RadioGroup)(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px 24px',
    flexWrap: 'wrap',
  };
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDiagnosticTabsProps extends ISwitcherProps {}

const DiagnosticTabs: FC<IDiagnosticTabsProps & PropsWithChildren> = ({ children, ...props }) => {
  return (
    <Switcher {...props} RadioGroupComponent={StyledRadioGroup}>
      {children}
    </Switcher>
  );
};

export default DiagnosticTabs;
