/* eslint-disable max-len */
import React from 'react';

const IconDiploma_1: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_10155_216184)">
      <path
        d="M42.0408 0H8.20748C6.63559 0 5.36133 1.27427 5.36133 2.84615V47.1538C5.36133 48.7257 6.63559 50 8.20748 50H42.0408C43.6127 50 44.887 48.7257 44.887 47.1538V2.84615C44.887 1.27427 43.6127 0 42.0408 0Z"
        fill="#FEC200"
      />
      <path
        d="M39.3177 6.23693C38.3369 4.71129 38.5612 3.02539 38.5612 3.02539H11.6895C11.6895 3.02539 11.9138 4.71129 10.933 6.23693C9.92022 7.80744 7.93945 7.94206 7.93945 7.94206V42.051C7.93945 42.051 9.92022 42.1856 10.933 43.7562C11.9138 45.2818 11.6895 46.9677 11.6895 46.9677H38.5677C38.5677 46.9677 38.3433 45.2818 39.3241 43.7562C40.3369 42.1856 42.3177 42.051 42.3177 42.051V7.94206C42.3177 7.94206 40.3369 7.80744 39.3241 6.23693H39.3177Z"
        fill="#E57C13"
      />
      <mask
        id="mask0_10155_216184"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="7"
        y="3"
        width="36"
        height="44"
      >
        <path
          d="M39.3157 6.23693C38.3349 4.71129 38.5593 3.02539 38.5593 3.02539H11.6875C11.6875 3.02539 11.9119 4.71129 10.9311 6.23693C9.91827 7.80744 7.9375 7.94206 7.9375 7.94206V42.051C7.9375 42.051 9.91827 42.1856 10.9311 43.7562C11.9119 45.2818 11.6875 46.9677 11.6875 46.9677H38.5657C38.5657 46.9677 38.3413 45.2818 39.3221 43.7562C40.3349 42.1856 42.3157 42.051 42.3157 42.051V7.94206C42.3157 7.94206 40.3349 7.80744 39.3221 6.23693H39.3157Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_10155_216184)">
        <path d="M43.8686 6.24365H6.375V7.90391H43.8686V6.24365Z" fill="#E83F40" />
        <path d="M43.8686 4.58984H6.375V6.2501H43.8686V4.58984Z" fill="#748FFC" />
        <path d="M43.8686 2.99365H6.375V4.65391H43.8686V2.99365Z" fill="white" />
      </g>
      <path
        d="M28.2157 30.3008L20.4914 42.3457L19.3568 37.3072L14.5234 38.4867L22.6068 26.5957L28.2157 30.3008Z"
        fill="#FEC200"
      />
      <path
        d="M22.7285 30.3013L30.4465 42.3462L31.5811 37.3077L36.4208 38.4872L28.3375 26.5962L22.7285 30.3013Z"
        fill="#FEC200"
      />
      <path
        d="M22.2537 11.9998C24.0999 9.25617 28.164 9.35232 29.882 12.1728L29.9332 12.2626C33.2089 11.6664 36.0294 14.6216 35.2858 17.8651L35.2602 17.9677L35.3435 18.0254C38.0679 19.9421 37.9525 24.019 35.1191 25.769L35.1384 25.8716C35.7537 29.1216 32.7922 31.91 29.5871 31.1023C27.7409 33.8459 23.6768 33.7498 21.9589 30.9292L21.9076 30.8395C18.632 31.4357 15.8114 28.4805 16.555 25.2369L16.5807 25.1344L16.4973 25.0767C13.773 23.16 13.8884 19.0831 16.7217 17.3331L16.7025 17.2305C16.0871 13.9805 19.0486 11.1921 22.2537 11.9998Z"
        fill="#FEC200"
      />
      <path
        d="M22.9265 13.7498L23.2213 13.3139C24.5931 11.2755 27.6124 11.346 28.888 13.4421L29.2021 13.9614L29.7149 13.8652C32.1508 13.4229 34.2406 15.6152 33.6893 18.0255L33.5547 18.6152L34.0483 18.9614C36.0739 20.3844 35.9842 23.4101 33.8816 24.7114L33.4393 24.987L33.5547 25.5832C34.0098 27.9934 31.8111 30.0703 29.4329 29.4678L28.9201 29.3396L28.6252 29.7755C27.2534 31.8139 24.2342 31.7434 22.9585 29.6473L22.6444 29.128L22.1316 29.2242C19.6957 29.6665 17.606 27.4742 18.1572 25.0639L18.2919 24.4742L17.7983 24.128C15.7726 22.705 15.8624 19.6793 17.9649 18.378L18.4072 18.1024L18.2919 17.5062C17.8367 15.096 20.0354 13.0191 22.4137 13.6216L22.9265 13.7498Z"
        stroke="#E57C13"
        strokeWidth="0.641026"
        strokeMiterlimit="10"
      />
      <path d="M26.6893 17.6855V25.6471H25.0098V17.6855H26.6893Z" fill="#E57C13" />
      <path
        d="M34.786 29.3395C33.8629 30.8844 31.9975 31.8075 30.036 31.3203L29.677 31.2306L29.4719 31.5382C27.7219 34.1472 23.8565 34.0575 22.2283 31.3716L21.9847 30.9677L21.4591 31.0639C19.6193 31.3972 17.9399 30.5254 17.0488 29.1216"
        stroke="#E57C13"
        strokeWidth="0.641026"
        strokeMiterlimit="10"
      />
      <path
        d="M10.0156 10V40C10.0156 40 11.7592 40.1218 12.6438 41.5C13.5092 42.8397 13.3041 44.3205 13.3041 44.3205H36.9387C36.9387 44.3205 36.74 42.8397 37.599 41.5C38.49 40.1218 40.2272 40 40.2272 40V10"
        stroke="#FEC200"
        strokeWidth="0.641026"
        strokeMiterlimit="10"
      />
      <mask
        id="mask1_10155_216184"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="5"
        y="0"
        width="40"
        height="50"
      >
        <path
          d="M42.0428 0H8.20943C6.63755 0 5.36328 1.27427 5.36328 2.84615V47.1538C5.36328 48.7257 6.63755 50 8.20943 50H42.0428C43.6147 50 44.8889 48.7257 44.8889 47.1538V2.84615C44.8889 1.27427 43.6147 0 42.0428 0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_10155_216184)">
        <path
          opacity="0.3"
          d="M3.56881 28.5702C26.2656 28.5702 44.665 10.1709 44.665 -12.5259C44.665 -35.2227 26.2656 -53.6221 3.56881 -53.6221C-19.128 -53.6221 -37.5273 -35.2227 -37.5273 -12.5259C-37.5273 10.1709 -19.128 28.5702 3.56881 28.5702Z"
          fill="url(#paint0_linear_10155_216184)"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_10155_216184"
        x1="-10.8735"
        y1="-39.59"
        x2="20.9406"
        y2="20.0318"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0" />
        <stop offset="0.35" stopColor="white" stopOpacity="0" />
        <stop offset="0.49" stopColor="white" stopOpacity="0.03" />
        <stop offset="0.59" stopColor="white" stopOpacity="0.08" />
        <stop offset="0.67" stopColor="white" stopOpacity="0.15" />
        <stop offset="0.74" stopColor="white" stopOpacity="0.24" />
        <stop offset="0.8" stopColor="white" stopOpacity="0.35" />
        <stop offset="0.85" stopColor="white" stopOpacity="0.47" />
        <stop offset="0.9" stopColor="white" stopOpacity="0.62" />
        <stop offset="0.95" stopColor="white" stopOpacity="0.79" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <clipPath id="clip0_10155_216184">
        <rect width="50" height="50" fill="white" transform="translate(0.125)" />
      </clipPath>
    </defs>
  </svg>
);

export default IconDiploma_1;
