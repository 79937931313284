import { FC } from 'react';

import { Box, capitalize, Stack, Typography } from '@mui/material';
import { useAppSelector, useDataLoad } from 'hooks';
import { ProfessionRecommendationCard } from 'portfolio3/features/pdf/components/cards';
import PdfNoDataText from 'portfolio3/features/pdf/components/PdfNoDataText';
import { proforientationModel } from 'portfolio3/features/proforientation';
import { getDataLoadRequests } from 'utils';

import { widgetPdfIcons } from '../../../../icons';
import PdfCommonDataEntityContainer from '../common';

import '../index.scss';

const PdfProforientationRecommendations: FC = () => {
  const resultLink = useAppSelector(proforientationModel.selectors.proforientationResultLinkSelector);
  const recommendedIndustries = useAppSelector(
    proforientationModel.selectors.proforientationRecommendedIndustriesSelector,
  );
  useDataLoad({ shouldLoad: true, requests: getDataLoadRequests().professionProforientation });

  const hasData = recommendedIndustries.length > 0;

  const industryNamesString = recommendedIndustries.map((industry) => capitalize(industry.name)).join(', ');

  return (
    <PdfCommonDataEntityContainer
      title="Рекомендации"
      icon={widgetPdfIcons.pdfIconProfessionProforientationRecommendations}
    >
      {hasData && (
        <Box>
          <Typography variant="Paragraph LG/Regular">
            Тебе рекомендованы следующие отрасли:{' '}
            <Typography component="span" variant="Paragraph LG/Semi Bold">
              {industryNamesString}.
            </Typography>
          </Typography>

          {resultLink && (
            <Typography variant="Paragraph LG/Regular" marginTop="16px">
              Подробнее{' '}
              <Typography component="span" variant="Paragraph LG/Semi Bold" sx={{ textDecoration: 'underline' }}>
                {resultLink}
              </Typography>
            </Typography>
          )}

          <Typography variant="Paragraph LG/Regular" marginTop="16px">
            Ниже для&nbsp;тебя сформирован список отраслей, специальностей и&nbsp;колледжей.
          </Typography>

          <Stack gap="16px" marginTop="16px">
            {recommendedIndustries.map((recommendation) => {
              return <ProfessionRecommendationCard key={recommendation.name} recommendation={recommendation} />;
            })}
          </Stack>
        </Box>
      )}
      {!hasData && <PdfNoDataText />}
    </PdfCommonDataEntityContainer>
  );
};

export default PdfProforientationRecommendations;
