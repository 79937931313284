import { ComponentProps, FC } from 'react';

import { Box, Typography } from '@mui/material';
import { getDoughnutData, SafeOmit } from 'utils';

import CircularProgressBar from '../CircularProgressBar';
import * as styles from './styles';

type Value = string | number | null;

interface IResultDoughnutProps extends SafeOmit<ComponentProps<typeof CircularProgressBar>, 'data'> {
  value: Value;
  maxValue: number;
  typographyVariant: ComponentProps<typeof Typography>['variant'];
  renderValue?: (result: Value) => string;
}

const ResultDoughnut: FC<IResultDoughnutProps> = ({
  value,
  maxValue,
  typographyVariant,
  renderValue,
  ...progressBarProps
}) => {
  const data = getDoughnutData(Number(value), maxValue);

  const computedRenderedValue = renderValue ? renderValue(value) : value?.toString().replaceAll('.', ',');

  return (
    <Box className="result-doughnut" sx={styles.root}>
      <CircularProgressBar {...progressBarProps} data={data} />
      <Typography variant={typographyVariant} sx={styles.value}>
        {computedRenderedValue}
      </Typography>
    </Box>
  );
};

export default ResultDoughnut;
