import { FC, lazy, Suspense, useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import { routes, sectionRoutes } from 'const';
import { GlobalFooterContainer } from 'portfolio3/containers/footers';
import { GlobalHeaderContainer, SettingsHeaderContainer } from 'portfolio3/containers/headers';
import { IRootState } from 'reducers';
import { compose } from 'redux';
import { visibilitySettingsSelector } from 'selectors';
import { IViewVisibilitySetting } from 'types';
import { getStudentFirstVisibleSectionPath } from 'utils';

import {
  withErrorBoundary,
  withFixedWidthContainer,
  withFooter,
  withHeader,
  withReferenceData,
  withRendererType,
  withSettingsContext,
  withStudentDataMain,
  withStudentPaddingContainer,
  withThemeBackground,
  withUserContext,
} from '../hocs';

const ProfileSection = lazy(() => import('portfolio3/containers/sections/ProfileSection'));
const StudySection = lazy(() => import('portfolio3/containers/sections/StudySection'));
const ScienceSection = lazy(() => import('portfolio3/containers/sections/ScienceSection'));
const SportSection = lazy(() => import('portfolio3/containers/sections/SportSection'));
const CreationSection = lazy(() => import('portfolio3/containers/sections/CreationSection'));
const CultureSection = lazy(() => import('portfolio3/containers/sections/CultureSection'));
const CivilSection = lazy(() => import('portfolio3/containers/sections/CivilSection'));
const ProfessionSection = lazy(() => import('portfolio3/containers/sections/ProfessionSection'));
const RecommendationSection = lazy(() => import('portfolio3/containers/sections/RecommendationSection'));

interface ISettingsRendererProps {
  visibilitySettings: IViewVisibilitySetting[];
}

const SettingsRenderer: FC<ISettingsRendererProps> = ({ visibilitySettings }) => {
  const match = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    // Если портфолио открыто для студента или родителя, то вычисляем доступный раздел и открываем его
    const newPathName = getStudentFirstVisibleSectionPath(visibilitySettings);

    if (newPathName) {
      history.push(`${routes.PORTFOLIO_STUDENT_SETTING.path}/${newPathName}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibilitySettings]);

  return (
    <Suspense fallback={<></>}>
      <Switch>
        <Route path={`${match.url}${sectionRoutes.PROFILE}`} render={() => <ProfileSection />} />
        <Route path={`${match.url}${sectionRoutes.STUDY}`} render={() => <StudySection />} />
        <Route path={`${match.url}${sectionRoutes.SCIENCE}`} render={() => <ScienceSection />} />
        <Route path={`${match.url}${sectionRoutes.SPORT}`} render={() => <SportSection />} />
        <Route path={`${match.url}${sectionRoutes.CREATION}`} render={() => <CreationSection />} />
        <Route path={`${match.url}${sectionRoutes.CULTURE}`} render={() => <CultureSection />} />
        <Route path={`${match.url}${sectionRoutes.CIVIL}`} render={() => <CivilSection />} />
        <Route path={`${match.url}${sectionRoutes.PROFESSION}`} render={() => <ProfessionSection />} />
        <Route path={`${match.url}${sectionRoutes.RECOMMENDATION}`} render={() => <RecommendationSection />} />
      </Switch>
    </Suspense>
  );
};

const withRedux = connect((state: IRootState) => ({
  visibilitySettings: visibilitySettingsSelector(state),
}));

const isSideContainersFullWidth = false;

export default compose<FC>(
  withRedux,
  withRendererType('settings'),
  withErrorBoundary(),
  withUserContext(),
  withStudentDataMain(),
  withReferenceData(),
  withSettingsContext(),
  withThemeBackground(),
  withHeader(GlobalHeaderContainer, isSideContainersFullWidth),
  withHeader(SettingsHeaderContainer, isSideContainersFullWidth),
  withFooter(GlobalFooterContainer, isSideContainersFullWidth),
  withFixedWidthContainer(),
  withStudentPaddingContainer(),
)(SettingsRenderer);
