/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const MobileSelectArrow: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M9.99978 14.1665C9.57811 14.1665 9.17478 13.9723 8.89478 13.6315L5.38395 9.38317C4.96395 8.874 4.87978 8.1515 5.16811 7.54067C5.42228 7.0015 5.92811 6.6665 6.48895 6.6665H13.5106C14.0714 6.6665 14.5773 7.0015 14.8314 7.54067C15.1198 8.1515 15.0356 8.874 14.6164 9.38234L11.1048 13.6315C10.8248 13.9723 10.4214 14.1665 9.99978 14.1665Z" fill="#7B819B" />
  </SvgIcon>

);

export default MobileSelectArrow;
