import { createSelector } from 'reselect';

import { IRootState } from '../../../reducers';
import { mapCivilContestsResponse } from './mappers';

const civilContestsSelector = createSelector(
  [
    (state: IRootState) => state.studentEvents,
    (state: IRootState) => state.studentRewards,
    (state: IRootState) => state.sectionRef,
  ],
  (studentEvents, studentRewards, sectionRef) => {
    return mapCivilContestsResponse(studentEvents, studentRewards, sectionRef);
  },
);

export default civilContestsSelector;
