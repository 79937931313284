import { FC, PropsWithChildren } from 'react';

import { Box } from '@mui/material';
import PdfIconImage from 'portfolio3/features/pdf/components/PdfIconImage';
import { SxStyles } from 'types';

import './index.scss';

const blockStyles: SxStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
};

interface IPdfEducationSectionProps {
  title: string;
  icon: string;
}

const PdfEducationSection: FC<IPdfEducationSectionProps & PropsWithChildren> = ({ title, icon, children }) => (
  <div className="pdf-education-section pdf-keep">
    <div className="pdf-education-section__header">
      <Box sx={blockStyles}>
        <PdfIconImage size={32} svgUrl={icon} />
        <h3 className="pdf-education-section__title">{title}</h3>
      </Box>
    </div>
    <div className="pdf-education-section__content">{children}</div>
  </div>
);

export default PdfEducationSection;
