import { svgIconClasses } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const drawer: SxStyles = () => ({
  position: 'relative',

  display: 'grid',
  gridAutoFlow: 'row',
  gridTemplateRows: 'auto 1fr auto',

  height: '100%',
  overflow: 'hidden',
});

export const content: SxStyles = () => ({
  position: 'relative',
  overflow: 'auto',
});

export const drawerClose: SxStyles = {
  cursor: 'pointer',

  [`.${svgIconClasses.root}`]: {
    color: NeutralColors.light_neutrals_600,
  },
};

export const dragIndicator: SxStyles = () => ({
  zIndex: 2,
  position: 'absolute',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  paddingTop: '12px',
});
