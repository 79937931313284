import { isEqual } from 'lodash';

import { ReportErrorTypeCodes } from '../../../const';
import { CardDetailsChangeHandler, FormStep, IReportErrorFormData } from './types';

export const isFormSubmitDisabled = (
  formData: IReportErrorFormData,
  formStep: FormStep,
  initialFormData?: IReportErrorFormData,
): boolean => {
  const { errorTypeCode, ...restFormData } = formData;
  const isCardDetailsError = errorTypeCode === ReportErrorTypeCodes.cardDataError;

  if (isCardDetailsError && isEqual(restFormData, initialFormData)) return true;

  const cardDetailsChecks: [boolean | undefined, string | undefined][] = [
    [formData.errorGeneralMessageChecked, formData.errorGeneralMessage],
    [formData.errorFileMetadataMessageChecked, formData.errorFileMetadataMessage],
    [formData.errorChildEntityMessageChecked, formData.errorChildEntityMessage],
  ];

  const isSomeCardDetailsFilled = cardDetailsChecks.some(([checked]) => checked);
  const isCardDetailsFormValid =
    isSomeCardDetailsFilled &&
    cardDetailsChecks.every(([checked, value]) => {
      return !checked || (checked && value?.trim());
    });

  return formStep !== FormStep.SUBMIT_ERROR || (isCardDetailsError && !isCardDetailsFormValid);
};

export const createController = (
  formData: IReportErrorFormData,
  onChangeFormData: (formData: IReportErrorFormData) => void,
  valueProp: keyof IReportErrorFormData,
  checkedProp: keyof IReportErrorFormData,
): CardDetailsChangeHandler => {
  return (isChecked: boolean, value: string | undefined) => {
    onChangeFormData({
      ...formData,
      [valueProp]: value,
      [checkedProp]: isChecked,
    });
  };
};
