import { getAccentColor } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root: SxStyles = (theme) => ({
  paddingTop: '8px',

  [theme.breakpoints.down('commonSm')]: {
    paddingTop: '16px',
  },
});

export const desktopPadding: SxStyles = (theme) => {
  const { accentColor } = theme.palette;

  return {
    position: 'relative',

    '& > .view-button': {
      display: 'none',
      position: 'absolute',
      right: '4px',
      top: '50%',
      transform: 'translateY(-50%)',
    },

    '&:hover': {
      backgroundColor: getAccentColor(accentColor, '8'),

      '& > .view-button': {
        display: 'block',
      },
    },
  };
};

export const mobileDividerPadding: SxStyles = {
  paddingInline: '16px',
  paddingTop: '16px',
};
