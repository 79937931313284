import { StudentSectionSettingTypeCodes } from 'const';
import { visibilitySettingsSelector } from 'selectors';
import { findTargetVisibilitySetting } from 'utils/localVisibilitySettingsService';

import { collection as searchActions } from '../model/actions';
import {
  searchStudyDocumentsSelector,
  searchStudyOlympiadsSelector,
  searchStudyRewardsSelector,
  searchStudySelfDiagnosticSubjectsSelector,
  searchStudySpoPracticeSelector,
  searchStudySpoTrainingSelector,
} from '../model/selectors';
import { useAppSelector } from './../../../../hooks/index';

const studyCode = StudentSectionSettingTypeCodes.study;
const spoTrainingCode = StudentSectionSettingTypeCodes.studyTraining;
const spoPracticeCode = StudentSectionSettingTypeCodes.studyPractice;
const documentsCode = StudentSectionSettingTypeCodes.studyDocuments;
const giaCode = StudentSectionSettingTypeCodes.studyGia;
const diagnosticsCode = StudentSectionSettingTypeCodes.studyDiagnostic;
const independentDiagnosticsCode = StudentSectionSettingTypeCodes.diagnosticIndependent;
const selfDiagnosticsCode = StudentSectionSettingTypeCodes.diagnosticSelf;
const olympiadCode = StudentSectionSettingTypeCodes.studyOlympiad;

const useSearchStudy = () => {
  /* селекторы данных */
  const settings = useAppSelector(visibilitySettingsSelector);
  const { loading: isSpoTrainingLoading, content: spoTraining } = useAppSelector(searchStudySpoTrainingSelector);
  const { loading: isSpoPracticeLoading, content: spoPractice } = useAppSelector(searchStudySpoPracticeSelector);
  const { loading: isDocumentsLoading, content: documents } = useAppSelector(searchStudyDocumentsSelector);
  const { loading: isGiaLoading, content: gia } = useAppSelector((state) => state.searchGia);
  const { loading: isIndependentDiagnosticsLoading, content: independentDiagnostics } = useAppSelector(
    (state) => state.searchIndependentDiagnostics,
  );
  const { loading: isSelfDiagnosticsLoading, selfDiagnostics } = useAppSelector(
    searchStudySelfDiagnosticSubjectsSelector,
  );
  const { loading: isRewardsLoading, content: rewards } = useAppSelector(searchStudyRewardsSelector);
  const { loading: isOlympiadsLoading, content: olympiads } = useAppSelector(searchStudyOlympiadsSelector);

  const filteredGia = gia.filter((exam) => exam.subjectID);

  /* рассчет общего количества */
  const studyEntitiesCount = [
    spoTraining.length,
    spoPractice.length,
    documents.length,
    filteredGia.length,
    independentDiagnostics.length,
    selfDiagnostics.length,
    rewards.length,
    olympiads.length,
  ].reduce((prev, current) => prev + current, 0);

  /* определение статуса наличия сущностей */
  const hasSpoTraining = spoTraining.length > 0;
  const hasSpoPractice = spoPractice.length > 0;
  const hasDocuments = documents.length > 0;
  const hasGia = filteredGia.length > 0;
  const hasIndependentDiagnostics = independentDiagnostics.length > 0;
  const hasSelfDiagnostics = selfDiagnostics.length > 0;
  const hasRewards = rewards.length > 0;
  const hasOlympiads = olympiads.length > 0;

  /* определения настроек видимости */
  const isSpoTrainingEnabled =
    findTargetVisibilitySetting(settings, [studyCode, spoTrainingCode])?.computedVisibility ?? false;
  const isSpoPracticeEnabled =
    findTargetVisibilitySetting(settings, [studyCode, spoPracticeCode])?.computedVisibility ?? false;
  const isDocumentsEnabled =
    findTargetVisibilitySetting(settings, [studyCode, documentsCode])?.computedVisibility ?? false;
  const isGiaEnabled = findTargetVisibilitySetting(settings, [studyCode, giaCode])?.computedVisibility ?? false;
  const isIndependentDiagnosticsEnabled =
    findTargetVisibilitySetting(settings, [studyCode, diagnosticsCode, independentDiagnosticsCode])
      ?.computedVisibility ?? false;
  const isSelfDiagnosticsEnabled =
    findTargetVisibilitySetting(settings, [studyCode, diagnosticsCode, selfDiagnosticsCode])?.computedVisibility ??
    false;
  const isOlympiadEnabled =
    findTargetVisibilitySetting(settings, [studyCode, olympiadCode])?.computedVisibility ?? false;
  const isRewardsEnabled = isOlympiadEnabled;

  /* определение статуса отображения */
  const isStudySpoTrainingVisible = hasSpoTraining && isSpoTrainingEnabled;
  const isStudySpoPracticeVisible = hasSpoPractice && isSpoPracticeEnabled;
  const isStudyDocumentsVisible = hasDocuments && isDocumentsEnabled;
  const isStudyGiaVisible = hasGia && isGiaEnabled;
  const isStudyIndependentDiagnosticsVisible = hasIndependentDiagnostics && isIndependentDiagnosticsEnabled;
  const isStudySelfDiagnosticsVisible = hasSelfDiagnostics && isSelfDiagnosticsEnabled;
  const isStudyRewardVisible = hasRewards && isRewardsEnabled;
  const isStudyOlympiadVisible = hasOlympiads && isOlympiadEnabled;

  /* определение статуса загрузки */
  const isStudyLoading =
    isSpoTrainingLoading ||
    isSpoPracticeLoading ||
    isDocumentsLoading ||
    isGiaLoading ||
    isIndependentDiagnosticsLoading ||
    isSelfDiagnosticsLoading ||
    isRewardsLoading ||
    isOlympiadsLoading;
  const isStudyVisible =
    isStudySpoTrainingVisible ||
    isStudySpoPracticeVisible ||
    isStudyDocumentsVisible ||
    isStudyGiaVisible ||
    isStudyIndependentDiagnosticsVisible ||
    isStudySelfDiagnosticsVisible ||
    isStudyRewardVisible ||
    isStudyOlympiadVisible;

  /* экшены для загрузки данных */
  const studyActions = [
    isSpoTrainingEnabled && searchActions.searchSpoEducationActions,
    isSpoPracticeEnabled && searchActions.searchSpoEducationActions,
    isDocumentsEnabled && searchActions.searchDocumentsActions,
    isGiaEnabled && searchActions.searchGiaActions,
    isIndependentDiagnosticsEnabled && searchActions.searchIndependentDiagnosticsActions,
    isSelfDiagnosticsEnabled && searchActions.searchDiagnosticsActions,
    isRewardsEnabled && searchActions.searchRewardsActions,
    isOlympiadEnabled && searchActions.searchEventsActions,
  ];

  return {
    spoTraining,
    spoPractice,
    documents,
    gia: filteredGia,
    independentDiagnostics,
    selfDiagnostics,
    rewards,
    olympiads,
    isStudySpoTrainingVisible,
    isStudySpoPracticeVisible,
    isStudyDocumentsVisible,
    isStudyGiaVisible,
    isStudyIndependentDiagnosticsVisible,
    isStudySelfDiagnosticsVisible,
    isStudyRewardVisible,
    isStudyOlympiadVisible,
    isStudyLoading,
    isStudyVisible,
    studyActions,
    studyEntitiesCount,
  };
};

export default useSearchStudy;
