/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

const IconSecondCategory: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="50" height="50" fill="#E5E5E5"/>
    <rect width="1924" height="1397" transform="translate(-1108 -758)" fill="white"/>
    <g clip-path="url(#clip0)">
    <path d="M24.8685 54.3834C41.0516 54.3834 54.1706 41.4519 54.1706 25.5C54.1706 9.54808 41.0516 -3.38348 24.8685 -3.38348C8.6854 -3.38348 -4.43359 9.54808 -4.43359 25.5C-4.43359 41.4519 8.6854 54.3834 24.8685 54.3834Z" fill="white"/>
    <path d="M24.8683 54.5C19.0495 54.5 13.3614 52.7992 8.52327 49.6126C3.68513 46.4261 -0.0857472 41.8969 -2.3125 36.5978C-4.53925 31.2988 -5.12187 25.4678 -3.98669 19.8424C-2.8515 14.2169 -0.0494838 9.04964 4.06502 4.99391C8.17952 0.938193 13.4217 -1.82379 19.1287 -2.94276C24.8357 -4.06173 30.7511 -3.48743 36.127 -1.29249C41.5028 0.902448 46.0977 4.61945 49.3304 9.38847C52.5632 14.1575 54.2886 19.7643 54.2886 25.5C54.2814 33.1891 51.1795 40.5612 45.6636 45.9982C40.1478 51.4352 32.6689 54.4929 24.8683 54.5ZM24.8683 -3.27588C19.0945 -3.27588 13.4503 -1.5882 8.64959 1.57374C3.84883 4.73567 0.107097 9.22985 -2.10245 14.488C-4.31199 19.7461 -4.89011 25.5319 -3.76369 31.1139C-2.63728 36.6959 0.143081 41.8232 4.22579 45.8476C8.3085 49.872 13.5102 52.6126 19.1731 53.723C24.8359 54.8333 30.7057 54.2634 36.04 52.0855C41.3743 49.9075 45.9336 46.2192 49.1414 41.487C52.3491 36.7549 54.0613 31.1913 54.0613 25.5C54.0516 17.8711 50.9729 10.5573 45.5002 5.16286C40.0276 -0.231604 32.6078 -3.26639 24.8683 -3.27588Z" fill="#969696"/>
    <path d="M24.8684 52.0796C39.7607 52.0796 51.8332 40.1795 51.8332 25.5C51.8332 10.8205 39.7607 -1.07959 24.8684 -1.07959C9.97613 -1.07959 -2.09644 10.8205 -2.09644 25.5C-2.09644 40.1795 9.97613 52.0796 24.8684 52.0796Z" fill="#748FFC"/>
    <path d="M24.8685 49.704C38.4298 49.704 49.4234 38.8675 49.4234 25.5C49.4234 12.1325 38.4298 1.29596 24.8685 1.29596C11.3073 1.29596 0.313721 12.1325 0.313721 25.5C0.313721 38.8675 11.3073 49.704 24.8685 49.704Z" fill="#E83F40"/>
    <path d="M24.8684 48.0007C37.4753 48.0007 47.6953 37.9268 47.6953 25.5C47.6953 13.0731 37.4753 2.99921 24.8684 2.99921C12.2614 2.99921 2.0415 13.0731 2.0415 25.5C2.0415 37.9268 12.2614 48.0007 24.8684 48.0007Z" fill="#969696"/>
    <path d="M44.2577 4.03013L44.8215 6.48639L41.975 6.54018L37.7006 11.6141L12.8912 10.9238L8.344 6.49536H5.22463L5.70664 3.65363C2.54375 6.37991 0.00425625 9.73718 -1.74382 13.5033C-3.49191 17.2694 -4.40872 21.3585 -4.43359 25.5C-4.42671 31.4624 -2.54799 37.2767 0.944703 42.1448C4.4374 47.0128 9.3729 50.6961 15.0738 52.6892L19.8757 50.977L16.9746 48.1352L19.2845 42.6042H30.8526L33.4809 48.8076L30.1705 50.9949L35.0724 52.5637C39.8169 50.8534 44.028 47.9552 47.2916 44.1542C50.5551 40.3531 52.7587 35.7801 53.6854 30.885C54.6122 25.9899 54.2303 20.9413 52.5773 16.2358C50.9242 11.5303 48.057 7.33 44.2577 4.04806V4.03013Z" fill="#969696"/>
    <path d="M13.5367 49.1213C13.4639 49.2379 13.4003 49.3454 13.3275 49.4441C12.8093 50.1701 12.022 50.666 11.1358 50.8246C10.7129 50.9048 10.284 50.9498 9.85348 50.959C9.42452 50.9426 8.99816 50.8856 8.58026 50.7887C8.14574 50.6954 7.71748 50.5756 7.29796 50.4301C6.878 50.2792 6.47056 50.0964 6.07931 49.8833C5.53365 49.5875 5.00617 49.2558 4.46961 48.9331C4.46961 48.9331 4.46961 48.9331 4.37866 48.8614C4.4772 48.7724 4.58041 48.6886 4.68787 48.6104C5.3691 48.1302 6.17162 47.8448 7.00694 47.7856C8.0131 47.7302 9.02155 47.8423 9.98989 48.1173C11.1358 48.4131 12.2817 48.7538 13.4185 49.0765L13.5367 49.1213Z" fill="#CCCCCC"/>
    <path d="M9.90796 51.0755H9.8443C9.41199 51.0567 8.98255 50.9967 8.56199 50.8963C8.11975 50.81 7.68495 50.6901 7.26149 50.5377C6.83262 50.3835 6.41614 50.1977 6.01557 49.9819C5.6336 49.7757 5.25164 49.5426 4.87877 49.3185L4.40586 49.0317H4.3422C4.33566 49.0176 4.33228 49.0023 4.33228 48.9868C4.33228 48.9714 4.33566 48.9561 4.3422 48.942C4.33248 48.9129 4.33248 48.8815 4.3422 48.8524L4.42405 48.7807L4.65141 48.5924C5.35133 48.1011 6.1766 47.8123 7.03414 47.7587C8.05262 47.6895 9.07542 47.8019 10.0535 48.0904C10.8629 48.2966 11.6723 48.5296 12.4635 48.7538L13.4911 49.0496H13.5639H13.6185C13.6465 49.0666 13.6687 49.0916 13.6821 49.1213C13.6889 49.1369 13.6924 49.1537 13.6924 49.1706C13.6924 49.1876 13.6889 49.2043 13.6821 49.2199L13.6185 49.3275L13.473 49.5426C13.2075 49.9158 12.8701 50.2337 12.48 50.4783C12.0899 50.7228 11.6547 50.8892 11.1994 50.968C10.7733 51.0439 10.3409 51.0799 9.90796 51.0755ZM4.56047 48.8613L5.00609 49.1213C5.36986 49.3454 5.75183 49.5785 6.13379 49.7847C6.90799 50.2043 7.74191 50.5065 8.60746 50.6811C9.01683 50.7742 9.43378 50.8312 9.85339 50.8514H9.90796C10.3145 50.8431 10.7193 50.7981 11.1175 50.717C11.9613 50.5494 12.7072 50.0678 13.2001 49.3723C13.2456 49.3185 13.282 49.2468 13.3274 49.1841L12.3271 48.8972C11.5359 48.6731 10.7265 48.44 9.92615 48.2338C8.98228 47.9613 7.99764 47.8521 7.01595 47.9111C6.20101 47.9666 5.41741 48.2426 4.75145 48.7089C4.68374 48.7546 4.61987 48.8056 4.56047 48.8613Z" fill="#969696"/>
    <path d="M3.08734 8.59299C2.68146 8.11308 2.40606 7.5393 2.28703 6.9256C2.11474 6.06108 2.19355 5.16617 2.51439 4.34384C2.74603 3.71349 3.05123 3.11183 3.42383 2.55095C3.79328 2.00342 4.22002 1.49564 4.69704 1.03596C5.11538 0.623595 5.56101 0.256052 5.99754 -0.138383C6.04018 -0.172252 6.08584 -0.202255 6.13395 -0.228027C6.27106 0.0539246 6.35723 0.357284 6.3886 0.668418C6.4494 1.22432 6.42796 1.78598 6.32494 2.33581C6.1988 2.97465 5.991 3.59516 5.70652 4.18248C4.9544 5.67577 4.09428 7.11379 3.13281 8.48542L3.08734 8.59299Z" fill="#CCCCCC"/>
    <path d="M3.08733 8.70061C3.07446 8.7063 3.06051 8.70924 3.0464 8.70924C3.0323 8.70924 3.01835 8.7063 3.00548 8.70061C2.57141 8.21359 2.27363 7.62345 2.14151 6.9884C1.95749 6.08813 2.03956 5.1547 2.37797 4.29906C2.62472 3.66698 2.94518 3.06532 3.33287 2.50617C3.70616 1.95343 4.13583 1.43978 4.61518 0.973251C4.92439 0.677425 5.24269 0.399526 5.52462 0.121628L5.90658 -0.201092C5.93268 -0.229773 5.96341 -0.254005 5.99753 -0.272807H6.043C6.05881 -0.279465 6.07582 -0.282898 6.09302 -0.282898C6.11021 -0.282898 6.12723 -0.279465 6.14304 -0.272807C6.17314 -0.259587 6.1985 -0.237716 6.21579 -0.210056C6.36546 0.0873716 6.45796 0.409547 6.48862 0.740175C6.54885 1.3054 6.52433 1.87628 6.41587 2.43446C6.29527 3.0845 6.0841 3.71498 5.78836 4.30803C5.14045 5.62855 4.3926 6.89912 3.55114 8.10895L3.21465 8.62889L3.16918 8.67371C3.1581 8.68344 3.14513 8.69085 3.13106 8.69547C3.11699 8.70009 3.10211 8.70184 3.08733 8.70061ZM6.09757 -0.021803L5.7156 0.309882C5.40639 0.578815 5.08809 0.856713 4.80616 1.14358C4.33808 1.6024 3.91484 2.10364 3.54205 2.64064C3.1709 3.18007 2.86564 3.7608 2.63261 4.37078C2.31133 5.17425 2.23238 6.0521 2.40525 6.89875C2.51696 7.44647 2.7602 7.95983 3.11461 8.39581L3.41472 7.93863C4.24489 6.73447 4.98647 5.47323 5.63375 4.16459C5.91498 3.59558 6.11981 2.99288 6.24308 2.37171C6.34689 1.83717 6.3714 1.29061 6.31583 0.74914C6.27852 0.483666 6.2051 0.224355 6.09757 -0.021803Z" fill="#969696"/>
    <path d="M5.22437 6.48644C5.79576 4.90226 6.82728 3.51874 8.19048 2.50815C9.55368 1.49757 11.1883 0.904567 12.8909 0.802979C12.2366 2.31521 11.234 3.65646 9.96254 4.72044C8.64337 5.84069 6.96544 6.46608 5.22437 6.48644Z" fill="#CCCCCC"/>
    <path d="M5.22471 6.59401C5.20674 6.59631 5.18849 6.59328 5.17229 6.58529C5.15608 6.57731 5.14267 6.56473 5.13376 6.54918C5.12498 6.53419 5.12036 6.51719 5.12036 6.49988C5.12036 6.48257 5.12498 6.46556 5.13376 6.45057C5.71457 4.84714 6.76014 3.44706 8.14077 2.42398C9.52141 1.40091 11.1764 0.799862 12.9004 0.695398C12.9194 0.695031 12.9382 0.698866 12.9555 0.706623C12.9728 0.714379 12.9882 0.725859 13.0004 0.74022C13.0118 0.757592 13.0178 0.777822 13.0178 0.798489C13.0178 0.819155 13.0118 0.839385 13.0004 0.856758C12.3474 2.38703 11.3342 3.74214 10.0447 4.81008C8.7067 5.9498 7.0021 6.58505 5.2338 6.60297L5.22471 6.59401ZM12.7003 0.937438C11.0989 1.07451 9.56852 1.65106 8.2826 2.60172C6.99668 3.55238 6.00667 4.83913 5.42478 6.31611C7.08299 6.26898 8.67357 5.65768 9.9265 4.58597C11.1158 3.5891 12.0635 2.34243 12.7003 0.937438Z" fill="#969696"/>
    <path d="M-0.17749 43.7696C0.15794 43.5597 0.531763 43.4166 0.922929 43.3482C1.90656 43.1604 2.92444 43.2601 3.85132 43.6351C4.9505 44.0735 6.00936 44.6044 7.01616 45.2218C7.4436 45.4728 7.87103 45.7328 8.33485 46.0555C8.27534 46.0796 8.22009 46.1129 8.17115 46.1541C7.56496 46.7514 6.75382 47.1032 5.89755 47.1402C4.85672 47.1765 3.82829 46.9082 2.94188 46.3693C1.80025 45.7306 0.781339 44.8992 -0.0683578 43.913L-0.17749 43.7696Z" fill="#CCCCCC"/>
    <path d="M5.62446 47.2567C4.66004 47.2296 3.71956 46.9545 2.89614 46.4589C1.74344 45.8136 0.715194 44.973 -0.141379 43.9757L-0.205039 43.8951L-0.250511 43.8413C-0.258493 43.8276 -0.262695 43.8122 -0.262695 43.7964C-0.262695 43.7807 -0.258493 43.7653 -0.250511 43.7516C-0.243135 43.719 -0.22368 43.6902 -0.195945 43.6709C0.154047 43.4602 0.538798 43.3115 0.940852 43.2317C1.94617 43.0415 2.98593 43.1443 3.9329 43.5275C5.04253 43.9622 6.11077 44.4931 7.12503 45.1142C7.4979 45.3294 7.94352 45.5983 8.45281 45.9479C8.46792 45.9595 8.47995 45.9745 8.48787 45.9917C8.49579 46.0089 8.49936 46.0277 8.49828 46.0465C8.49862 46.065 8.49369 46.0832 8.48404 46.0991C8.47439 46.1149 8.46042 46.1278 8.44371 46.1362H8.38005L8.2982 46.19C7.67336 46.8288 6.82391 47.2074 5.92457 47.2478L5.62446 47.2567ZM-0.00496364 43.7964C0.835661 44.7703 1.842 45.5924 2.9689 46.2258C3.85197 46.7741 4.8815 47.0489 5.92457 47.0147C6.75343 46.9826 7.53944 46.6432 8.12541 46.0645C7.68888 45.7686 7.29782 45.5355 6.97042 45.3473C5.96853 44.7293 4.91238 44.2013 3.81468 43.7696C2.91278 43.3871 1.91823 43.2689 0.949946 43.4289C0.615638 43.5064 0.294143 43.6301 -0.00496364 43.7964Z" fill="#969696"/>
    <path d="M-3.42432 37.7813C-3.1242 37.7365 -2.86046 37.6917 -2.58763 37.6737C-2.01902 37.6209 -1.44576 37.7007 -0.914269 37.9068C-0.572436 38.0304 -0.240986 38.1803 0.0770185 38.355C0.919036 38.8313 1.69282 39.4161 2.3779 40.0941C2.94175 40.6201 3.5056 41.1579 4.06945 41.7077L4.21496 41.8512C3.73437 42.2074 3.15958 42.4191 2.55978 42.4608C1.9845 42.4913 1.40883 42.4079 0.866804 42.2154C0.324778 42.023 -0.172609 41.7254 -0.595965 41.3402C-1.00401 40.9874 -1.3926 40.6134 -1.76005 40.2196C-2.38333 39.5246 -2.91783 38.7569 -3.35156 37.9337L-3.42432 37.7813Z" fill="#CCCCCC"/>
    <path d="M2.28683 42.5862C1.19883 42.5597 0.156562 42.1494 -0.650652 41.4298C-1.05473 41.0853 -1.43739 40.7171 -1.79654 40.3272C-2.4314 39.6254 -2.97518 38.8485 -3.41534 38.0143V37.9247V37.853C-3.42861 37.8183 -3.42861 37.7801 -3.41534 37.7454C-3.40756 37.7303 -3.39593 37.7173 -3.38159 37.7079C-3.36725 37.6985 -3.35068 37.6929 -3.33349 37.6916H-3.05157L-2.48772 37.6199C-1.90127 37.5678 -1.31046 37.6475 -0.759786 37.853C-0.415321 37.9877 -0.0783544 38.1403 0.249691 38.3102C1.0986 38.8057 1.87821 39.4085 2.56876 40.1031L4.15118 41.636L4.26031 41.7525C4.26685 41.7666 4.27023 41.7819 4.27023 41.7973C4.27023 41.8128 4.26685 41.8281 4.26031 41.8422C4.25094 41.8739 4.23189 41.9021 4.20574 41.9228C3.70964 42.2911 3.1156 42.5091 2.496 42.5504L2.28683 42.5862ZM-3.25164 37.8709C-2.82276 38.6893 -2.29111 39.4513 -1.66922 40.1389C-1.31378 40.5206 -0.937314 40.8827 -0.54152 41.2236C-0.131052 41.5985 0.351982 41.8878 0.878622 42.0742C1.40526 42.2606 1.96466 42.3402 2.52328 42.3083C3.05558 42.2751 3.56838 42.0984 4.00567 41.7973H3.9602L2.28683 40.1748C1.60746 39.5068 0.843251 38.9283 0.0132368 38.4536C-0.298085 38.297 -0.620218 38.1622 -0.950768 38.0502C-1.46731 37.8479 -2.02565 37.771 -2.57866 37.8261C-2.76055 37.8261 -2.93334 37.8261 -3.12432 37.8888L-3.25164 37.8709Z" fill="#969696"/>
    <path d="M0.577496 14.5185C0.314548 14.3262 0.062555 14.1197 -0.177338 13.9C-0.477127 13.6153 -0.715336 13.2738 -0.877604 12.896C-1.15819 12.2985 -1.31878 11.6531 -1.35051 10.9955C-1.3727 9.98073 -1.2126 8.97011 -0.877604 8.01033C-0.600363 7.14959 -0.225102 6.32257 0.241004 5.5451L0.331948 5.40167L0.513835 5.64371C1.08796 6.433 1.42804 7.36415 1.49603 8.33305C1.56462 9.26362 1.50033 10.1991 1.30505 11.112C1.08678 12.1698 0.850327 13.2187 0.622968 14.2675C0.622968 14.3572 0.604779 14.4109 0.577496 14.5185Z" fill="#CCCCCC"/>
    <path d="M0.577241 14.6351C0.553849 14.6441 0.527878 14.6441 0.504486 14.6351L0.249843 14.4379C0.0678648 14.3091 -0.102527 14.1652 -0.259442 14.0076C-0.570138 13.7055 -0.81737 13.3461 -0.986992 12.9498C-1.27001 12.3373 -1.43057 11.6769 -1.4599 11.0045C-1.48201 9.97802 -1.32197 8.95563 -0.986992 7.98347C-0.700532 7.11472 -0.319373 6.27913 0.149805 5.49136V5.41964L0.195277 5.34793C0.195277 5.34793 0.258938 5.27621 0.295315 5.29414C0.333323 5.29562 0.369232 5.31171 0.395353 5.33896L0.568146 5.581C1.16256 6.39895 1.5123 7.36485 1.57762 8.36894C1.65855 9.30081 1.60651 10.2393 1.42302 11.1569C1.26841 11.9278 1.10472 12.6988 0.941016 13.4518L0.750034 14.3482V14.4737V14.5723C0.744761 14.5903 0.735492 14.6068 0.722906 14.6208C0.710319 14.6347 0.694731 14.6458 0.677279 14.653L0.577241 14.6351ZM0.349881 5.60789C-0.111832 6.37694 -0.484025 7.1949 -0.759633 8.04622C-1.087 8.99218 -1.24397 9.98729 -1.22345 10.9866C-1.19462 11.6301 -1.04346 12.2626 -0.777821 12.8512C-0.615246 13.2218 -0.383926 13.5593 -0.0957431 13.8462C0.0598377 13.9928 0.223805 14.1305 0.395353 14.2586L0.51358 14.3482L0.695468 13.4518C0.859167 12.6988 1.03196 11.9189 1.18656 11.1569C1.3833 10.2441 1.4476 9.30848 1.37755 8.37791C1.33343 7.41565 1.01854 6.48447 0.468108 5.68857L0.349881 5.60789Z" fill="#969696"/>
    <path d="M-5.29761 31.291C-4.91465 31.2621 -4.52954 31.3016 -4.16081 31.4076C-3.09564 31.7009 -2.14486 32.3038 -1.4325 33.1377C-0.600192 34.1146 0.148133 35.158 0.80472 36.2573L1.25034 36.9655C1.07755 37.0193 0.932041 37.0821 0.777437 37.1179C0.17498 37.2607 -0.456436 37.2296 -1.04144 37.0283C-2.05579 36.6614 -2.92108 35.9785 -3.50601 35.083C-4.28609 33.9929 -4.87339 32.7805 -5.24304 31.4972V31.3896C-5.26911 31.3616 -5.28785 31.3278 -5.29761 31.291Z" fill="#CCCCCC"/>
    <path d="M0.0133963 37.3151C-0.36877 37.3201 -0.749339 37.2656 -1.11431 37.1538C-2.15152 36.7698 -3.03727 36.0695 -3.64254 35.1547C-4.42679 34.0495 -5.01705 32.8222 -5.38866 31.5241V31.4076C-5.39349 31.3978 -5.396 31.3871 -5.396 31.3762C-5.396 31.3653 -5.39349 31.3546 -5.38866 31.3448C-5.38866 31.291 -5.38866 31.2104 -5.28863 31.2104C-4.89119 31.1692 -4.48943 31.2058 -4.10636 31.3179C-3.03442 31.6309 -2.08343 32.2559 -1.37804 33.1108C-0.529003 34.0841 0.237501 35.1245 0.913739 36.2215L1.18657 36.6607L1.35936 36.9297C1.36612 36.9453 1.3696 36.962 1.3696 36.979C1.3696 36.9959 1.36612 37.0127 1.35936 37.0283C1.35496 37.0456 1.34587 37.0614 1.33306 37.0741C1.32024 37.0867 1.30419 37.0957 1.28661 37.1L1.132 37.1538L0.804607 37.2614C0.543943 37.31 0.278362 37.328 0.0133963 37.3151ZM-5.1704 31.3986C-4.81096 32.6808 -4.2328 33.8934 -3.46066 34.9844C-2.88832 35.8614 -2.04258 36.5314 -1.05065 36.8938C-0.467008 37.1019 0.166444 37.1331 0.768229 36.9835L1.06834 36.8849L0.977401 36.7325L0.704569 36.3022C0.0398316 35.2128 -0.717734 34.1811 -1.55993 33.2184C-2.24336 32.4042 -3.15894 31.8108 -4.18821 31.5151C-4.50694 31.4237 -4.83874 31.3843 -5.1704 31.3986Z" fill="#969696"/>
    <path d="M14.5009 44.1371H14.5736C15.7496 44.928 16.8652 45.8029 17.9113 46.7547C18.6739 47.4505 19.265 48.3091 19.6392 49.2648C19.8073 49.6944 19.8996 50.1492 19.912 50.6094C19.912 50.6094 19.912 50.6991 19.912 50.7618L19.6301 50.6901C18.3357 50.3312 17.1269 49.7217 16.0742 48.8972C15.4114 48.4051 14.8832 47.7582 14.5372 47.0147C14.1772 46.1592 14.1417 45.2045 14.4372 44.3254C14.4554 44.2357 14.4827 44.1909 14.5009 44.1371Z" fill="#CCCCCC"/>
    <path d="M19.9029 50.9142H19.8028H19.6118C18.2912 50.5589 17.0548 49.9496 15.9741 49.1213C15.2905 48.61 14.7518 47.9339 14.4099 47.1581C14.0265 46.2607 13.9908 45.2558 14.3098 44.3343C14.3225 44.2945 14.3377 44.2556 14.3553 44.2177C14.3611 44.2021 14.3708 44.1881 14.3835 44.1771C14.3962 44.1661 14.4115 44.1585 14.4281 44.155C14.4423 44.1485 14.4578 44.1452 14.4735 44.1452C14.4892 44.1452 14.5047 44.1485 14.519 44.155H14.5827C15.7651 44.9444 16.884 45.8225 17.9294 46.7816C18.708 47.4871 19.309 48.3619 19.6846 49.3364C19.8575 49.7775 19.9528 50.2443 19.9665 50.717C19.971 50.7497 19.971 50.7828 19.9665 50.8156V50.8783C19.9777 50.9072 19.9777 50.9391 19.9665 50.968L19.9029 50.9142ZM14.5554 44.3253C14.2523 45.2034 14.2879 46.1608 14.6554 47.0146C14.9814 47.7549 15.495 48.4001 16.1469 48.8882C17.1864 49.7071 18.3792 50.3163 19.6573 50.6811H19.8028C19.8028 50.2258 19.7228 49.7738 19.5664 49.3454C19.2018 48.4044 18.6195 47.5603 17.8657 46.8802C16.8347 45.9411 15.7276 45.0866 14.5554 44.3253Z" fill="#969696"/>
    <path d="M10.4721 41.4119C11.6001 42.6268 12.5464 43.9942 13.2823 45.4728C13.7343 46.4163 13.8895 47.4716 13.7279 48.5028C13.7279 48.6462 13.6642 48.7896 13.637 48.942H13.5551C12.3017 48.2333 11.2433 47.2331 10.4721 46.0286C9.99967 45.3179 9.75271 44.4847 9.76275 43.6351C9.77191 42.8772 9.99896 42.1374 10.4175 41.5015L10.4721 41.4119Z" fill="#CCCCCC"/>
    <path d="M13.6186 49.0586C13.5826 49.0658 13.5455 49.0658 13.5094 49.0586C12.2178 48.3566 11.1263 47.3454 10.3355 46.1182C9.85108 45.3888 9.59781 44.5338 9.60796 43.662C9.617 42.8819 9.85036 42.1203 10.2809 41.4657L10.3355 41.394C10.3433 41.3775 10.3566 41.3641 10.3731 41.3559C10.3896 41.3478 10.4084 41.3454 10.4265 41.3491C10.4409 41.3432 10.4563 41.3402 10.4719 41.3402C10.4875 41.3402 10.503 41.3432 10.5174 41.3491C11.6484 42.5755 12.6004 43.9513 13.3457 45.4369C13.8021 46.4047 13.9602 47.4831 13.8005 48.5386C13.7845 48.6383 13.7633 48.737 13.7368 48.8345V48.9779C13.7426 48.9906 13.7456 49.0043 13.7456 49.0182C13.7456 49.0321 13.7426 49.0459 13.7368 49.0586H13.6186ZM10.4719 41.636C10.0767 42.2472 9.86247 42.9551 9.85351 43.6799C9.84092 44.5049 10.0819 45.3143 10.5447 46.0017C11.2975 47.1614 12.3242 48.1242 13.5367 48.8076C13.5367 48.709 13.5822 48.6193 13.6004 48.5207C13.7712 47.5027 13.632 46.4576 13.2002 45.5176C12.4791 44.1046 11.5607 42.798 10.4719 41.636Z" fill="#969696"/>
    <path d="M11.1814 8.10001L9.8809 8.63788C9.06942 8.97774 8.2296 9.24768 7.37085 9.44468C6.07594 9.77472 4.70138 9.59128 3.54212 8.9337L3.19653 8.71856L3.42389 8.51237C4.41741 7.62177 5.62099 6.99006 6.92523 6.67466C7.49358 6.51777 8.09016 6.48705 8.672 6.58471C9.25385 6.68236 9.80649 6.90597 10.2901 7.23942C10.6327 7.47669 10.9337 7.76736 11.1814 8.10001Z" fill="#CCCCCC"/>
    <path d="M6.03396 9.71361C5.13515 9.71955 4.25167 9.48402 3.47844 9.03231L3.26927 8.89784L3.19651 8.81716C3.18997 8.8031 3.18659 8.78781 3.18659 8.77234C3.18659 8.75687 3.18997 8.74158 3.19651 8.72752C3.18853 8.71389 3.18433 8.69843 3.18433 8.6827C3.18433 8.66696 3.18853 8.6515 3.19651 8.63788V8.5572L3.34202 8.42273C4.34552 7.51316 5.56037 6.86054 6.87973 6.52227C7.46404 6.36032 8.07772 6.32941 8.67573 6.43179C9.27374 6.53417 9.84091 6.76725 10.3356 7.11392C10.6914 7.35592 10.9995 7.65967 11.245 8.01036C11.253 8.02561 11.2572 8.04252 11.2572 8.05967C11.2572 8.07682 11.253 8.09373 11.245 8.10897C11.2346 8.14266 11.2119 8.17138 11.1814 8.18965L10.7085 8.38687L9.88088 8.72752C9.06336 9.07025 8.21751 9.34317 7.35264 9.54329C6.92001 9.64367 6.47821 9.70073 6.03396 9.71361ZM3.40568 8.70063L3.59666 8.82613C4.73275 9.46542 6.07712 9.64232 7.34355 9.31917C8.19773 9.13092 9.03203 8.8638 9.8354 8.52134L10.663 8.18069L10.9995 8.03726C10.7809 7.74952 10.5169 7.49829 10.2174 7.29321C9.75013 6.96547 9.21417 6.74537 8.64908 6.64917C8.08398 6.55297 7.50418 6.58313 6.95249 6.73741C5.6694 7.06833 4.48874 7.70564 3.51481 8.59305L3.40568 8.70063Z" fill="#969696"/>
    <path d="M7.29814 37.862L7.78924 38.866C8.28507 39.8973 8.66329 40.9797 8.91694 42.0932C9.18004 43.2489 9.06543 44.457 8.58954 45.5445C8.58954 45.6252 8.50769 45.7059 8.46222 45.7866L8.41675 45.8404L8.34399 45.7866C7.3723 44.8427 6.64279 43.6842 6.21591 42.407C5.99055 41.7841 5.91513 41.1181 5.99563 40.4616C6.07612 39.8051 6.31031 39.176 6.67972 38.624C6.83433 38.355 7.07988 38.1309 7.29814 37.862Z" fill="#CCCCCC"/>
    <path d="M8.40748 45.9479H8.35291L8.26197 45.8852C7.27789 44.9215 6.53928 43.7419 6.1066 42.4428C5.87311 41.8038 5.79508 41.1196 5.8788 40.4454C5.96252 39.7713 6.20564 39.1259 6.5886 38.5612C6.71191 38.3782 6.8487 38.2045 6.99785 38.0413L7.20702 37.7903C7.22387 37.7816 7.24259 37.7771 7.26159 37.7771C7.28058 37.7771 7.2993 37.7816 7.31615 37.7903C7.33562 37.7917 7.35433 37.7983 7.37033 37.8094C7.38632 37.8204 7.39902 37.8355 7.4071 37.853L7.5708 38.1757C7.67993 38.4178 7.78906 38.6329 7.8891 38.857C8.39621 39.8949 8.7807 40.9867 9.03499 42.1111C9.30149 43.2881 9.18372 44.5181 8.6985 45.6252C8.66579 45.6987 8.62937 45.7705 8.58937 45.8403L8.54389 45.9031C8.52575 45.9198 8.50418 45.9324 8.48064 45.9401C8.4571 45.9479 8.43215 45.9505 8.40748 45.9479ZM7.27068 38.0502L7.17974 38.1668C7.03595 38.3226 6.9023 38.4873 6.77959 38.6598C6.41976 39.1975 6.19213 39.8105 6.11477 40.4501C6.03741 41.0896 6.11246 41.7382 6.33396 42.3442C6.74415 43.5889 7.44862 44.7196 8.38929 45.6431C8.42497 45.5893 8.45542 45.5323 8.48023 45.4728C8.94369 44.4073 9.05815 43.2258 8.80763 42.0932C8.55129 41.0064 8.17306 39.951 7.67993 38.9467C7.57989 38.7226 7.47076 38.5074 7.35253 38.2743L7.27068 38.0502Z" fill="#969696"/>
    <path d="M4.33313 41.7974C4.08759 41.2864 3.85113 40.8203 3.64196 40.3451C3.52973 40.1062 3.4414 39.857 3.37822 39.6011C3.24728 39.1232 3.14404 38.6383 3.06901 38.1488C2.92272 37.333 3.02422 36.4927 3.36077 35.7336C3.69731 34.9744 4.2539 34.3302 4.96065 33.8818L5.09706 33.8011C5.1971 34.2403 5.29714 34.6975 5.38808 35.1099C5.6052 36.1266 5.70888 37.1637 5.69729 38.2026C5.68267 39.3876 5.29144 40.5382 4.57868 41.4926C4.50593 41.5822 4.43317 41.636 4.33313 41.7974Z" fill="#CCCCCC"/>
    <path d="M4.33323 41.9139C4.31425 41.9109 4.29621 41.9037 4.28045 41.8928C4.2647 41.882 4.25166 41.8677 4.24229 41.8512L4.0513 41.4478C3.87851 41.0802 3.70572 40.7396 3.55111 40.39C3.4389 40.1439 3.34765 39.889 3.27828 39.628V39.4577C3.15946 39.0336 3.06833 38.6024 3.00545 38.1668C2.8569 37.3284 2.96228 36.4655 3.30842 35.6859C3.65456 34.9063 4.22611 34.2444 4.95165 33.7832L5.0335 33.7294H5.08806C5.12323 33.7163 5.16203 33.7163 5.1972 33.7294C5.21333 33.7372 5.22745 33.7485 5.23848 33.7625C5.24951 33.7765 5.25716 33.7927 5.26086 33.8101L5.36089 34.2404C5.43365 34.5362 5.49731 34.823 5.56097 35.1368C5.78255 36.1619 5.88627 37.2084 5.87018 38.2564C5.85605 39.4651 5.45501 40.6385 4.72429 41.6091L4.57878 41.7974L4.47874 41.9229C4.45763 41.9393 4.4311 41.9475 4.40424 41.9459C4.37739 41.9442 4.3521 41.9328 4.33323 41.9139ZM5.01531 33.9804C4.33254 34.4223 3.79629 35.0519 3.47298 35.7912C3.14968 36.5306 3.05352 37.3472 3.19643 38.1399C3.25719 38.5633 3.34528 38.9825 3.46017 39.3949L3.50564 39.5742C3.56462 39.8243 3.64996 40.0677 3.76028 40.3003C3.91186 40.641 4.07556 40.9906 4.25138 41.3492L4.36961 41.5822L4.48784 41.4209C5.19031 40.4862 5.57542 39.3568 5.58825 38.1937C5.60413 37.1635 5.50039 36.1348 5.27905 35.1278C5.22448 34.841 5.15172 34.5451 5.08806 34.2314L5.01531 33.9804Z" fill="#969696"/>
    <path d="M7.65275 11.5424C7.32536 11.8471 7.01615 12.1699 6.67966 12.4388C6.1158 12.9049 5.56105 13.3352 4.96991 13.7835C4.04369 14.4647 2.90444 14.8041 1.7505 14.7427C1.42311 14.7427 1.09571 14.6441 0.731934 14.5992C0.731934 14.5185 0.777405 14.4737 0.795594 14.4379C0.977481 14.142 1.15937 13.8372 1.35035 13.5414C1.54494 13.2537 1.75756 12.9783 1.98696 12.7167C2.40566 12.2123 2.90295 11.7765 3.46025 11.4258C4.04614 11.0255 4.73986 10.8065 5.45268 10.797C6.1655 10.7874 6.86502 10.9876 7.46177 11.372C7.5137 11.3997 7.56249 11.4327 7.60728 11.4706C7.60728 11.4706 7.62547 11.5155 7.65275 11.5424Z" fill="#CCCCCC"/>
    <path d="M2.06866 14.8771H1.74126C1.51193 14.8643 1.28392 14.8343 1.05919 14.7875L0.722693 14.7427C0.70498 14.7436 0.687419 14.7389 0.672657 14.7292C0.657894 14.7195 0.646729 14.7054 0.640844 14.6889C0.632064 14.6739 0.627441 14.6569 0.627441 14.6396C0.627441 14.6223 0.632064 14.6052 0.640844 14.5903V14.5275C0.648615 14.489 0.664072 14.4525 0.686316 14.4199L0.831826 14.1689C0.968242 13.9538 1.10466 13.7297 1.25017 13.5235C1.39568 13.3173 1.65032 12.9587 1.88677 12.6808C2.31536 12.1708 2.82156 11.7292 3.38735 11.372C3.99273 10.9609 4.70838 10.7363 5.44348 10.7267C6.17858 10.7171 6.89999 10.923 7.51619 11.3182C7.57317 11.3458 7.62536 11.3821 7.6708 11.4258C7.69331 11.4389 7.71208 11.4574 7.72536 11.4796C7.72536 11.5334 7.72536 11.6141 7.72536 11.6499L7.43434 11.9368C7.21608 12.1519 6.98872 12.3671 6.75226 12.5643C6.2157 13.0215 5.65185 13.4607 5.06981 13.891C4.20157 14.5265 3.15008 14.872 2.06866 14.8771ZM0.895487 14.5096H1.09556C1.32292 14.5096 1.54119 14.5813 1.75945 14.5992C2.88813 14.6611 4.00266 14.3277 4.90611 13.658C5.47905 13.2546 6.03381 12.7615 6.57947 12.3491C6.81593 12.1609 7.03419 11.9457 7.25246 11.7396L7.46163 11.5334L7.37978 11.4796C6.80382 11.1024 6.12563 10.9057 5.43423 10.9153C4.74282 10.9249 4.07052 11.1404 3.50557 11.5334C2.9598 11.878 2.47193 12.3045 2.05957 12.7974C1.83541 13.0578 1.62593 13.3301 1.43206 13.6131C1.28655 13.8193 1.15922 14.0434 1.0319 14.2586L0.877298 14.5006L0.895487 14.5096Z" fill="#969696"/>
    <path d="M5.31575 15.5674C5.15205 15.8184 4.99745 16.0604 4.83375 16.2935C4.26336 17.1761 3.59204 17.9912 2.83299 18.7229C1.93809 19.602 0.731365 20.1035 -0.531932 20.1213H-0.750197C-0.754817 20.0916 -0.754817 20.0614 -0.750197 20.0317C-0.389056 18.61 0.366746 17.3155 1.43245 16.2935C1.91456 15.8187 2.52771 15.4941 3.19556 15.3602C3.86341 15.2263 4.55646 15.289 5.18843 15.5405H5.25209C5.25209 15.5405 5.27937 15.5405 5.31575 15.5674Z" fill="#CCCCCC"/>
    <path d="M-0.541134 20.202H-0.759399C-0.789153 20.1997 -0.816937 20.1865 -0.837179 20.1649C-0.85742 20.1432 -0.86862 20.1149 -0.868532 20.0855C-0.877807 20.0472 -0.877807 20.0072 -0.868532 19.9689C-0.487196 18.5204 0.289736 17.2027 1.37778 16.159C1.8757 15.6702 2.50845 15.3365 3.19729 15.1994C3.88614 15.0623 4.60068 15.128 5.25198 15.3881H5.33383C5.36157 15.4074 5.38102 15.4361 5.3884 15.4688C5.39494 15.4828 5.39832 15.4981 5.39832 15.5136C5.39832 15.5291 5.39494 15.5444 5.3884 15.5584L5.23379 15.7915L4.9064 16.2845C4.33233 17.175 3.65476 17.9963 2.88745 18.7318C1.96527 19.6199 0.729854 20.1211 -0.559323 20.1303L-0.541134 20.202ZM-0.622983 19.9689H-0.53204C0.69681 19.9611 1.8743 19.482 2.75103 18.6332C3.50666 17.9131 4.17224 17.1066 4.7336 16.2308L5.061 15.7377L5.15195 15.6122C4.53997 15.3695 3.86923 15.3089 3.22268 15.4379C2.57613 15.567 1.98215 15.88 1.51419 16.3383C0.481521 17.3355 -0.257141 18.5903 -0.622983 19.9689Z" fill="#969696"/>
    <path d="M3.76932 29.3278C3.76932 29.4981 3.76932 29.6505 3.76932 29.8029C3.83721 31.136 3.72095 32.472 3.42373 33.7742C3.15513 34.9246 2.51726 35.9589 1.60486 36.7235L1.38659 36.8759C1.38659 36.7145 1.29565 36.5801 1.25018 36.4366C0.932019 35.324 0.821094 34.1638 0.922779 33.0122C0.967486 32.2082 1.24952 31.4345 1.73422 30.7864C2.21892 30.1382 2.88517 29.6437 3.65109 29.3637L3.76932 29.3278Z" fill="#CCCCCC"/>
    <path d="M1.37765 36.9924C1.36007 36.9881 1.34401 36.9791 1.3312 36.9665C1.31839 36.9539 1.30929 36.938 1.30489 36.9207L1.25942 36.7683C1.25942 36.6607 1.19576 36.5711 1.16848 36.4725C0.838923 35.3497 0.724805 34.1762 0.831984 33.0122C0.876228 32.1899 1.16195 31.3979 1.65439 30.7326C2.14683 30.0674 2.82478 29.5575 3.60577 29.2651H3.70581H3.76947C3.80161 29.2526 3.83736 29.2526 3.86951 29.2651C3.8807 29.2939 3.8807 29.3259 3.86951 29.3547V29.5071C3.86951 29.6237 3.86951 29.7312 3.86951 29.8388C3.94196 31.1813 3.82256 32.5272 3.51483 33.8369C3.24195 35.0183 2.58001 36.0774 1.63229 36.849L1.48678 36.9566H1.41402C1.40528 36.9714 1.39274 36.9838 1.37765 36.9924ZM3.65124 29.4802C2.90785 29.7604 2.26425 30.2496 1.80027 30.887C1.33629 31.5244 1.07234 32.2821 1.04116 33.066C0.943522 34.1885 1.05761 35.3192 1.37765 36.4008L1.4595 36.6876L1.54135 36.6338C2.44815 35.8907 3.0863 34.8779 3.36022 33.7473C3.66386 32.4582 3.78021 31.1332 3.70581 29.8119C3.70581 29.7043 3.70581 29.5968 3.70581 29.4802H3.65124Z" fill="#969696"/>
    <path d="M3.36927 24.6752C3.1965 26.0052 2.85743 27.309 2.35979 28.5569C2.08128 29.2213 1.68044 29.8291 1.17752 30.3497C0.757692 30.7836 0.261624 31.139 -0.28667 31.3986H-0.377614H-0.441275C-0.441275 31.282 -0.441275 31.1655 -0.486747 31.049C-0.607905 29.7772 -0.443338 28.4948 -0.00474507 27.2929C0.213256 26.5955 0.631665 25.9755 1.19982 25.5077C1.76798 25.0399 2.46152 24.7446 3.19648 24.6573H3.28742L3.36927 24.6752Z" fill="#CCCCCC"/>
    <path d="M-0.441224 31.5689C-0.504885 31.5689 -0.586734 31.5241 -0.586734 31.4703V31.3538V31.1117C-0.718089 29.795 -0.55033 28.466 -0.0956375 27.2212C0.133579 26.5023 0.570582 25.865 1.16181 25.3873C1.75304 24.9096 2.47281 24.6123 3.2329 24.5318H3.3966C3.45117 24.5318 3.51483 24.6125 3.50574 24.6663C3.32872 26.008 2.98668 27.3235 2.48717 28.5838C2.19973 29.2499 1.78963 29.8578 1.27761 30.3767C0.852276 30.8231 0.346548 31.1879 -0.213865 31.4524H-0.322997L-0.441224 31.5689ZM3.19653 24.7739C2.49042 24.8575 1.82357 25.1394 1.27537 25.586C0.727177 26.0326 0.320735 26.6251 0.104439 27.2929C-0.339596 28.5028 -0.501238 29.7959 -0.368469 31.0759V31.291C0.166998 31.0395 0.650984 30.6931 1.05935 30.2691C1.55268 29.7464 1.9442 29.1386 2.21433 28.4762C2.69172 27.2856 3.02151 26.0424 3.19653 24.7739Z" fill="#969696"/>
    <path d="M-1.18701 25.7869C-1.13245 25.4104 -1.08697 25.0428 -1.02331 24.6932C-0.859227 23.6882 -0.501108 22.7235 0.0316349 21.8515C0.381831 21.1988 0.927904 20.6685 1.59527 20.3332C2.26263 19.9979 3.01874 19.8738 3.76033 19.9779H3.83308H3.87856C3.8149 20.1393 3.76033 20.3096 3.69667 20.471C3.2967 21.6122 2.75284 22.6995 2.07787 23.7071C1.35309 24.7898 0.226681 25.5493 -1.05969 25.8227L-1.18701 25.7869Z" fill="#CCCCCC"/>
    <path d="M-1.18697 25.9034C-1.19984 25.9091 -1.21379 25.912 -1.22789 25.912C-1.242 25.912 -1.25595 25.9091 -1.26882 25.9034C-1.28149 25.8717 -1.28149 25.8364 -1.26882 25.8048L-1.21425 25.4552C-1.21425 25.1952 -1.15059 24.9531 -1.10512 24.7111C-0.935805 23.6907 -0.571657 22.7112 -0.0319843 21.8246C0.333483 21.1551 0.897805 20.6117 1.58536 20.2672C2.27292 19.9227 3.05086 19.7936 3.81494 19.8972H3.89679H3.96045C3.98567 19.9131 4.00531 19.9362 4.0167 19.9635C4.02809 19.9907 4.03068 20.0208 4.02411 20.0496L3.96045 20.2199C3.96045 20.3365 3.8786 20.444 3.84222 20.5516C3.42355 21.6679 2.86442 22.728 2.17795 23.7071C1.43473 24.8125 0.282678 25.5877 -1.03237 25.8675H-1.18697V25.9034ZM3.26018 20.0586C2.61765 20.0621 1.98797 20.2363 1.43742 20.5629C0.886868 20.8894 0.435808 21.3563 0.131715 21.9142C-0.390464 22.7734 -0.742289 23.7225 -0.905045 24.7111C-0.950516 24.9531 -0.986894 25.1952 -1.02327 25.4462V25.6524C0.218883 25.3829 1.30539 24.6464 2.00516 23.5995C2.66836 22.628 3.20892 21.5804 3.61486 20.4799L3.73309 20.1572V20.0854L3.26018 20.0586Z" fill="#969696"/>
    <path d="M36.509 49.1213C36.5818 49.2379 36.6454 49.3454 36.7182 49.4441C37.2365 50.1701 38.0238 50.666 38.9099 50.8246C39.3328 50.9048 39.7617 50.9498 40.1923 50.959C40.6212 50.9426 41.0476 50.8856 41.4655 50.7887C41.9 50.6954 42.3282 50.5756 42.7478 50.4301C43.1677 50.2792 43.5752 50.0964 43.9664 49.8833C44.5121 49.5875 45.0396 49.2558 45.5761 48.9331C45.5761 48.9331 45.5761 48.9331 45.6671 48.8614C45.5685 48.7724 45.4653 48.6886 45.3579 48.6104C44.6766 48.1302 43.8741 47.8448 43.0388 47.7856C42.0326 47.7302 41.0242 47.8423 40.0558 48.1173C38.9099 48.4131 37.7641 48.7538 36.6273 49.0765L36.509 49.1213Z" fill="#CCCCCC"/>
    <path d="M40.1379 51.0755C39.719 51.0653 39.3019 51.0173 38.8919 50.9321C38.4366 50.8534 38.0014 50.687 37.6113 50.4424C37.2212 50.1978 36.8838 49.8799 36.6183 49.5068L36.4819 49.3006L36.4092 49.184C36.4024 49.1685 36.3989 49.1517 36.3989 49.1347C36.3989 49.1178 36.4024 49.101 36.4092 49.0854C36.4226 49.0558 36.4448 49.0308 36.4728 49.0137H36.5274H36.6001L37.6278 48.7179C38.419 48.4938 39.2284 48.2607 40.0378 48.0545C41.0157 47.7654 42.0387 47.6531 43.0572 47.7228C43.9147 47.7765 44.74 48.0652 45.4399 48.5565L45.6581 48.7358L45.7582 48.8165C45.7655 48.846 45.7655 48.8767 45.7582 48.9062C45.7647 48.9202 45.7681 48.9355 45.7681 48.951C45.7681 48.9664 45.7647 48.9817 45.7582 48.9958H45.6945L45.2216 49.2827C44.8487 49.5068 44.4668 49.7398 44.0757 49.946C43.6791 50.1634 43.2655 50.3493 42.8389 50.5018C42.4154 50.6543 41.9806 50.7742 41.5384 50.8604C41.1179 50.9614 40.6884 51.0215 40.2561 51.0397L40.1379 51.0755ZM36.682 49.184C36.682 49.2468 36.7638 49.3185 36.8093 49.3723C37.3099 50.075 38.0712 50.5549 38.9283 50.708C39.3448 50.7896 39.7678 50.8346 40.1924 50.8425C40.612 50.8222 41.029 50.7652 41.4384 50.6722C42.3039 50.4975 43.1378 50.1953 43.912 49.7757C44.294 49.5695 44.676 49.3364 45.0397 49.1123L45.4854 48.8524C45.4263 48.7935 45.3624 48.7395 45.2944 48.691C44.6265 48.2311 43.843 47.9613 43.0299 47.9111C42.0356 47.8509 41.0382 47.9631 40.0833 48.2428C39.283 48.449 38.4736 48.682 37.6824 48.9062L36.682 49.184Z" fill="#969696"/>
    <path d="M46.9585 8.59299C47.3644 8.11308 47.6398 7.5393 47.7588 6.9256C47.9311 6.06108 47.8523 5.16617 47.5315 4.34384C47.2998 3.71349 46.9947 3.11183 46.622 2.55095C46.2526 2.00342 45.8259 1.49564 45.3488 1.03596C44.9305 0.623595 44.4394 0.256052 44.0483 -0.138383C44.0057 -0.172252 43.96 -0.202255 43.9119 -0.228027C43.7748 0.0539246 43.6887 0.357284 43.6573 0.668418C43.5965 1.22432 43.6179 1.78598 43.7209 2.33581C43.8471 2.97465 44.0549 3.59516 44.3394 4.18248C45.0915 5.67577 45.9516 7.11379 46.9131 8.48542L46.9585 8.59299Z" fill="#CCCCCC"/>
    <path d="M46.9588 8.70066C46.9446 8.70711 46.929 8.71044 46.9134 8.71044C46.8977 8.71044 46.8821 8.70711 46.8679 8.70066L46.5314 8.17176C45.6899 6.96192 44.9421 5.69136 44.2942 4.37083C43.9984 3.77779 43.7873 3.1473 43.6667 2.49726C43.5582 1.93908 43.5337 1.3682 43.5939 0.802981C43.6233 0.472121 43.7159 0.149719 43.8667 -0.14725C43.884 -0.17491 43.9094 -0.196782 43.9395 -0.210002C43.9553 -0.21666 43.9723 -0.220093 43.9895 -0.220093C44.0067 -0.220093 44.0237 -0.21666 44.0395 -0.210002L44.1396 -0.138287L44.5215 0.193399C44.8307 0.462332 45.149 0.74023 45.431 1.03606C45.9124 1.50069 46.3423 2.01456 46.7133 2.56898C47.0852 3.13025 47.3903 3.73184 47.6227 4.36187C47.9611 5.21751 48.0432 6.15093 47.8592 7.0512C47.7271 7.68626 47.4293 8.2764 46.9952 8.76341C46.9755 8.74779 46.9625 8.72536 46.9588 8.70066ZM43.9486 -0.0217487C43.8498 0.219322 43.7856 0.472751 43.7576 0.731265C43.702 1.27274 43.7265 1.8193 43.8304 2.35383C43.9536 2.97501 44.1585 3.57771 44.4397 4.14672C45.087 5.45536 45.8285 6.7166 46.6587 7.92075L46.9588 8.37794C47.3132 7.94195 47.5565 7.4286 47.6682 6.88088C47.8411 6.03422 47.7621 5.15637 47.4408 4.3529C47.2078 3.74293 46.9025 3.1622 46.5314 2.62276C46.1632 2.08234 45.7396 1.58068 45.2673 1.1257C44.9762 0.838839 44.6579 0.560941 44.3578 0.292007L43.9668 -0.0396772L43.9486 -0.0217487Z" fill="#969696"/>
    <path d="M44.8216 6.48644C44.2502 4.90226 43.2187 3.51874 41.8555 2.50815C40.4923 1.49757 38.8576 0.904567 37.155 0.802979C37.8093 2.31521 38.812 3.65646 40.0834 4.72044C41.4026 5.84069 43.0805 6.46608 44.8216 6.48644Z" fill="#CCCCCC"/>
    <path d="M44.8216 6.594C43.0533 6.57608 41.3487 5.94082 40.0107 4.80111C38.7212 3.73317 37.708 2.37806 37.055 0.847786C37.0436 0.830413 37.0376 0.810184 37.0376 0.789517C37.0376 0.76885 37.0436 0.74862 37.055 0.731248C37.0672 0.716887 37.0825 0.705407 37.0999 0.69765C37.1172 0.689894 37.136 0.686059 37.155 0.686425C38.879 0.79089 40.534 1.39194 41.9146 2.41501C43.2953 3.43808 44.3408 4.83817 44.9216 6.4416C44.9304 6.45659 44.935 6.4736 44.935 6.49091C44.935 6.50822 44.9304 6.52522 44.9216 6.54021C44.9137 6.55907 44.8994 6.57468 44.8812 6.58446C44.863 6.59425 44.842 6.59761 44.8216 6.594ZM37.346 0.937431C37.9874 2.36212 38.9482 3.6249 40.1562 4.63078C41.4211 5.71217 43.0294 6.32412 44.7034 6.36092C44.1214 4.86457 43.1216 3.56116 41.8196 2.60141C40.5176 1.64165 38.9667 1.06476 37.346 0.937431Z" fill="#969696"/>
    <path d="M50.2238 43.7696C49.8883 43.5597 49.5145 43.4166 49.1233 43.3482C48.1397 43.1604 47.1218 43.2601 46.195 43.6351C45.0958 44.0735 44.0369 44.6044 43.0301 45.2218C42.6027 45.4728 42.1752 45.7328 41.7114 46.0555C41.7709 46.0796 41.8262 46.1129 41.8751 46.1541C42.4813 46.7514 43.2925 47.1032 44.1487 47.1402C45.1896 47.1765 46.218 46.9082 47.1044 46.3693C48.246 45.7306 49.2649 44.8992 50.1146 43.913L50.2238 43.7696Z" fill="#CCCCCC"/>
    <path d="M44.4212 47.2567H44.1211C43.2364 47.2192 42.3986 46.8543 41.7747 46.2348L41.6929 46.181H41.6292C41.6125 46.1726 41.5985 46.1597 41.5889 46.1439C41.5792 46.128 41.5743 46.1098 41.5747 46.0914C41.5736 46.0725 41.5771 46.0537 41.5851 46.0365C41.593 46.0193 41.605 46.0043 41.6201 45.9927C42.1294 45.6431 42.5296 45.3742 42.9479 45.159C43.9622 44.538 45.0304 44.007 46.14 43.5723C47.087 43.1891 48.1268 43.0863 49.1321 43.2765C49.5341 43.3564 49.9189 43.505 50.2689 43.7158C50.2966 43.735 50.3161 43.7638 50.3234 43.7965C50.3314 43.8101 50.3356 43.8255 50.3356 43.8413C50.3356 43.857 50.3314 43.8725 50.3234 43.8861V43.9399L50.2598 44.0206C49.4032 45.0178 48.375 45.8584 47.2223 46.5037C46.3731 46.9958 45.4062 47.2557 44.4212 47.2567ZM41.9293 46.0376C42.5087 46.6241 43.2916 46.9732 44.1211 47.0147C45.1388 47.0461 46.1438 46.7844 47.0131 46.2617C48.1423 45.6279 49.1491 44.8024 49.9869 43.8233C49.7006 43.6702 49.3948 43.5556 49.0775 43.4827C48.1174 43.3075 47.1264 43.407 46.2219 43.7696C45.1242 44.2013 44.068 44.7293 43.0661 45.3473C42.7569 45.5087 42.3659 45.7417 41.9293 46.0376Z" fill="#969696"/>
    <path d="M53.4701 37.7813C53.17 37.7365 52.9062 37.6917 52.6334 37.6737C52.0648 37.6209 51.4915 37.7007 50.96 37.9068C50.6182 38.0304 50.2868 38.1803 49.9688 38.355C49.1267 38.8313 48.3529 39.4161 47.6679 40.0941C47.104 40.6201 46.5402 41.1579 45.9763 41.7077L45.8308 41.8512C46.3114 42.2074 46.8862 42.4191 47.486 42.4608C48.0613 42.4913 48.6369 42.4079 49.179 42.2154C49.721 42.023 50.2184 41.7254 50.6417 41.3402C51.0498 40.9874 51.4384 40.6134 51.8058 40.2196C52.4291 39.5246 52.9636 38.7569 53.3973 37.9337L53.4701 37.7813Z" fill="#CCCCCC"/>
    <path d="M47.7589 42.5862H47.4588C46.8392 42.545 46.2452 42.3269 45.7491 41.9587C45.7229 41.938 45.7039 41.9098 45.6945 41.878C45.688 41.864 45.6846 41.8487 45.6846 41.8332C45.6846 41.8178 45.688 41.8025 45.6945 41.7884L45.94 41.636L47.6316 40.0224C48.3221 39.3278 49.1018 38.7251 49.9507 38.2295C50.2787 38.0596 50.6157 37.907 50.9601 37.7723C51.5108 37.5668 52.1016 37.4871 52.6881 37.5393L53.2519 37.611H53.5338C53.551 37.6122 53.5676 37.6178 53.5819 37.6273C53.5963 37.6367 53.6079 37.6496 53.6157 37.6648C53.629 37.6994 53.629 37.7377 53.6157 37.7723V37.853V37.9337C53.1755 38.7679 52.6318 39.5448 51.9969 40.2465C51.6377 40.6364 51.2551 41.0046 50.851 41.3491C50.0166 42.1329 48.9114 42.575 47.7589 42.5862ZM45.9946 41.8332C46.4319 42.1342 46.9447 42.311 47.477 42.3442C48.0356 42.3761 48.595 42.2964 49.1217 42.11C49.6483 41.9237 50.1313 41.6344 50.5418 41.2595C50.9376 40.9186 51.3141 40.5565 51.6695 40.1748C52.2914 39.4872 52.823 38.7252 53.2519 37.9068H53.1246C52.9336 37.9068 52.7608 37.853 52.5789 37.844C52.0259 37.7883 51.4674 37.8651 50.951 38.0682C50.6164 38.1914 50.2912 38.3382 49.9779 38.5074C49.1702 38.9692 48.4247 39.5294 47.7589 40.1748L46.0674 41.7884L45.9946 41.8332Z" fill="#969696"/>
    <path d="M49.4688 14.5185C49.7317 14.3262 49.9837 14.1197 50.2236 13.9C50.5234 13.6153 50.7616 13.2738 50.9239 12.896C51.2045 12.2985 51.3651 11.6531 51.3968 10.9955C51.419 9.98073 51.2589 8.97011 50.9239 8.01033C50.6467 7.14959 50.2714 6.32257 49.8053 5.5451L49.7143 5.40167L49.5325 5.64371C48.9583 6.433 48.6183 7.36415 48.5503 8.33305C48.4817 9.26362 48.546 10.1991 48.7412 11.112C48.9595 12.1698 49.196 13.2187 49.4233 14.2675C49.4233 14.3572 49.4415 14.4109 49.4688 14.5185Z" fill="#CCCCCC"/>
    <path d="M49.4689 14.6351C49.4514 14.6278 49.4358 14.6168 49.4232 14.6028C49.4106 14.5889 49.4014 14.5723 49.3961 14.5544V14.4558C49.3961 14.402 49.3961 14.3661 49.3961 14.3303C49.3324 14.0165 49.2597 13.6938 49.1869 13.3801C49.0323 12.645 48.8686 11.9099 48.7231 11.1748C48.5218 10.2506 48.4545 9.30285 48.523 8.35996C48.5884 7.35587 48.9381 6.38997 49.5325 5.57202L49.578 5.32998C49.6075 5.30778 49.6417 5.29246 49.678 5.28516C49.6979 5.28509 49.7176 5.28998 49.7351 5.29939C49.7525 5.30879 49.7673 5.3224 49.7781 5.33894V5.41066V5.48237C50.2472 6.27015 50.6284 7.10574 50.9149 7.97449C51.2498 8.94665 51.4099 9.96904 51.3878 10.9955C51.3971 11.6633 51.2767 12.3266 51.0331 12.9498C50.8581 13.3379 50.6114 13.6905 50.3055 13.9896C50.1486 14.1472 49.9782 14.2912 49.7963 14.4199L49.5416 14.6171C49.5206 14.6322 49.4945 14.6386 49.4689 14.6351ZM49.6962 5.60788L49.6144 5.70649C49.0478 6.49757 48.717 7.42912 48.6595 8.39582C48.5911 9.32044 48.6554 10.2499 48.8504 11.1569C48.9959 11.892 49.1596 12.627 49.3233 13.3621C49.387 13.6759 49.4598 13.9896 49.5234 14.3034C49.5277 14.318 49.5277 14.3336 49.5234 14.3482L49.6416 14.2586C49.8132 14.1305 49.9772 13.9928 50.1327 13.8462C50.4182 13.5672 50.6493 13.2391 50.8148 12.878C51.0805 12.2895 51.2316 11.657 51.2604 11.0134C51.281 10.0142 51.124 9.01905 50.7966 8.0731C50.521 7.22177 50.1488 6.40382 49.6871 5.63477L49.6962 5.60788Z" fill="#969696"/>
    <path d="M55.3436 31.291C54.9606 31.2621 54.5755 31.3016 54.2068 31.4076C53.1416 31.7009 52.1909 32.3038 51.4785 33.1377C50.6462 34.1146 49.8979 35.158 49.2413 36.2573L48.7957 36.9655C48.9684 37.0193 49.114 37.0821 49.2686 37.1179C49.871 37.2607 50.5024 37.2296 51.0874 37.0283C52.1018 36.6614 52.9671 35.9785 53.552 35.083C54.3321 33.9929 54.9194 32.7805 55.289 31.4972V31.3896C55.3151 31.3616 55.3338 31.3278 55.3436 31.291Z" fill="#CCCCCC"/>
    <path d="M50.0325 37.3152C49.7657 37.3133 49.5001 37.2802 49.2413 37.2166L48.9139 37.1538L48.7593 37.1C48.7417 37.0957 48.7256 37.0867 48.7128 37.0741C48.7 37.0615 48.6909 37.0456 48.6865 37.0283C48.6797 37.0127 48.6763 36.9959 48.6763 36.979C48.6763 36.9621 48.6797 36.9453 48.6865 36.9297L48.8593 36.6608L49.1321 36.2215C49.7976 35.11 50.555 34.0546 51.3966 33.066C52.1005 32.2094 53.052 31.5841 54.1249 31.2731C54.508 31.161 54.9098 31.1244 55.3072 31.1656C55.3337 31.1656 55.3592 31.1759 55.378 31.1944C55.3967 31.2129 55.4073 31.238 55.4073 31.2642H55.289H55.4073C55.4121 31.2739 55.4146 31.2847 55.4146 31.2955C55.4146 31.3064 55.4121 31.3171 55.4073 31.3269V31.4524C55.0356 32.7505 54.4454 33.9778 53.6611 35.083C53.0746 36.02 52.1977 36.7461 51.1602 37.1538C50.7952 37.2657 50.4146 37.3201 50.0325 37.3152ZM48.9775 36.8849L49.2776 36.9835C49.8794 37.1332 50.5129 37.1019 51.0965 36.8938C52.0885 36.5314 52.9342 35.8614 53.5065 34.9844C54.2787 33.8934 54.8568 32.6808 55.2163 31.3986C54.8846 31.3843 54.5528 31.4237 54.2341 31.5152C53.2036 31.8083 52.2873 32.4021 51.6058 33.2184C50.7665 34.1834 50.0091 35.2148 49.3413 36.3022L49.0685 36.7325L48.9775 36.8849Z" fill="#969696"/>
    <path d="M35.5452 44.1371H35.4724C34.2964 44.928 33.1809 45.8029 32.1348 46.7547C31.3721 47.4505 30.7811 48.3091 30.4069 49.2648C30.2387 49.6944 30.1465 50.1492 30.134 50.6094C30.134 50.6094 30.134 50.6991 30.134 50.7618L30.416 50.6901C31.7103 50.3312 32.9191 49.7217 33.9719 48.8972C34.6347 48.4051 35.1628 47.7582 35.5088 47.0147C35.8688 46.1592 35.9044 45.2045 35.6088 44.3254C35.5907 44.2357 35.5634 44.1909 35.5452 44.1371Z" fill="#CCCCCC"/>
    <path d="M30.1432 50.9142H30.0704C30.0592 50.8853 30.0592 50.8534 30.0704 50.8246V50.7618C30.0659 50.7291 30.0659 50.6959 30.0704 50.6632C30.0841 50.1906 30.1794 49.7237 30.3523 49.2827C30.728 48.3081 31.3289 47.4333 32.1076 46.7278C33.1525 45.7682 34.2714 44.89 35.4543 44.1012H35.5179C35.5322 44.0948 35.5477 44.0914 35.5634 44.0914C35.5791 44.0914 35.5946 44.0948 35.6089 44.1012C35.6254 44.1048 35.6407 44.1124 35.6535 44.1234C35.6662 44.1343 35.6759 44.1483 35.6816 44.164C35.6839 44.2028 35.6839 44.2417 35.6816 44.2805C36.0006 45.202 35.965 46.207 35.5816 47.1043C35.2397 47.8801 34.701 48.5562 34.0174 49.0675C32.9366 49.8958 31.7003 50.5052 30.3796 50.8604H30.1886H30.0886L30.1432 50.9142ZM35.4907 44.3253C34.3329 45.0974 33.2382 45.9576 32.2167 46.8981C31.452 47.5692 30.8572 48.4073 30.4797 49.3454C30.317 49.7638 30.2278 50.2064 30.2159 50.6542H30.3614C31.6396 50.2894 32.8324 49.6802 33.8719 48.8613C34.5334 48.3852 35.0596 47.7492 35.3997 47.0147C35.7672 46.1608 35.8028 45.2034 35.4998 44.3253H35.4907Z" fill="#969696"/>
    <path d="M39.5738 41.4119C38.4458 42.6268 37.4995 43.9942 36.7637 45.4728C36.3116 46.4163 36.1564 47.4716 36.318 48.5028C36.318 48.6462 36.3817 48.7896 36.409 48.942H36.4908C37.7442 48.2333 38.8026 47.2331 39.5738 46.0286C40.0463 45.3179 40.2932 44.4847 40.2832 43.6351C40.274 42.8772 40.047 42.1374 39.6284 41.5015L39.5738 41.4119Z" fill="#CCCCCC"/>
    <path d="M36.4455 49.0496C36.425 49.0572 36.4024 49.0572 36.3819 49.0496C36.3819 49.0496 36.3182 49.0496 36.3182 48.9869V48.8524C36.3182 48.7448 36.2727 48.6462 36.2546 48.5476C36.0941 47.4892 36.2522 46.4076 36.7093 45.4369C37.4546 43.9513 38.4066 42.5755 39.5376 41.3491C39.552 41.3432 39.5675 41.3402 39.5831 41.3402C39.5987 41.3402 39.6142 41.3432 39.6286 41.3491C39.6464 41.3479 39.6643 41.3514 39.6802 41.3593C39.6962 41.3672 39.7098 41.3792 39.7195 41.394L39.765 41.4567C40.2084 42.1014 40.4575 42.8567 40.4834 43.6351C40.4936 44.5069 40.2403 45.3619 39.7559 46.0913C38.9688 47.3097 37.8877 48.3169 36.6092 49.0227C36.5821 49.0265 36.5545 49.0265 36.5274 49.0227L36.4455 49.0496ZM39.574 41.636C38.4804 42.8074 37.5617 44.1265 36.8457 45.5535C36.4055 46.4813 36.2505 47.516 36.4001 48.5297C36.4001 48.6193 36.4001 48.7179 36.4637 48.8165C37.6845 48.1368 38.7155 47.1697 39.4649 46.0017C39.9277 45.3143 40.1686 44.5049 40.156 43.6799C40.158 42.9586 39.9565 42.2509 39.574 41.636Z" fill="#969696"/>
    <path d="M38.8647 8.10001L40.1652 8.63788C40.9767 8.97774 41.8165 9.24768 42.6753 9.44468C43.9702 9.77472 45.3448 9.59128 46.504 8.9337C46.6132 8.87095 46.7041 8.79924 46.8314 8.71856L46.6041 8.51237C45.6157 7.62438 44.4187 6.99283 43.1209 6.67466C42.5526 6.51777 41.956 6.48705 41.3741 6.58471C40.7923 6.68236 40.2396 6.90597 39.756 7.23942C39.4135 7.47669 39.1125 7.76736 38.8647 8.10001Z" fill="#CCCCCC"/>
    <path d="M44.0121 9.71356C43.5533 9.70963 43.0961 9.65856 42.648 9.56117C41.7831 9.36105 40.9373 9.08813 40.1198 8.7454L39.274 8.39579L38.8193 8.20754C38.7887 8.18926 38.766 8.16054 38.7556 8.12686C38.7429 8.09517 38.7429 8.05993 38.7556 8.02825C39.0011 7.67756 39.3093 7.3738 39.665 7.1318C40.1597 6.78513 40.7269 6.55205 41.3249 6.44967C41.9229 6.34729 42.5366 6.3782 43.1209 6.54015C44.4526 6.88214 45.6775 7.54436 46.6859 8.46751L46.8314 8.60197L46.9042 8.68265C46.9121 8.69628 46.9163 8.71174 46.9163 8.72748C46.9163 8.74321 46.9121 8.75867 46.9042 8.7723C46.9045 8.78976 46.9006 8.80704 46.8927 8.82266C46.8847 8.83828 46.8731 8.85176 46.8587 8.86194L46.7405 8.94262L46.5313 9.07709C45.7642 9.50689 44.8944 9.72664 44.0121 9.71356ZM39.0466 8.04618L39.3649 8.18064L40.2107 8.53026C41.0141 8.87272 41.8484 9.13984 42.7026 9.32809C43.969 9.65124 45.3134 9.47434 46.4494 8.83505L46.6404 8.70955L46.5313 8.61094C45.5574 7.72352 44.3767 7.08621 43.0936 6.7553C42.5419 6.60101 41.9621 6.57086 41.397 6.66705C40.8319 6.76325 40.296 6.98335 39.8287 7.31109C39.5324 7.51637 39.2687 7.76413 39.0466 8.04618Z" fill="#969696"/>
    <path d="M42.7481 37.862L42.3025 38.866C41.8066 39.8973 41.4284 40.9797 41.1748 42.0932C40.9117 43.2489 41.0263 44.457 41.5022 45.5445C41.5022 45.6252 41.584 45.7059 41.6295 45.7866V45.8404L41.7022 45.7866C42.6739 44.8427 43.4035 43.6842 43.8303 42.407C44.0557 41.7841 44.1311 41.1181 44.0506 40.4616C43.9701 39.8051 43.7359 39.176 43.3665 38.624C43.2119 38.355 42.9664 38.1309 42.7481 37.862Z" fill="#CCCCCC"/>
    <path d="M41.6201 45.9479C41.6074 45.9542 41.5934 45.9575 41.5792 45.9575C41.565 45.9575 41.551 45.9542 41.5383 45.9479L41.4746 45.8762C41.4346 45.8063 41.3982 45.7345 41.3655 45.6611C40.8803 44.554 40.7625 43.324 41.029 42.147C41.2819 41.0222 41.6664 39.9302 42.1749 38.8929C42.2749 38.6688 42.384 38.4537 42.4932 38.2116L42.6569 37.8889C42.6649 37.8714 42.6776 37.8563 42.6936 37.8453C42.7096 37.8342 42.7283 37.8276 42.7478 37.8261C42.7647 37.8175 42.7834 37.813 42.8024 37.813C42.8214 37.813 42.8401 37.8175 42.8569 37.8261L43.0661 38.0771C43.2153 38.2403 43.3521 38.4141 43.4754 38.5971C43.8583 39.1617 44.1015 39.8071 44.1852 40.4813C44.2689 41.1555 44.1909 41.8397 43.9574 42.4787C43.5253 43.7728 42.7864 44.9467 41.802 45.9031L41.7292 45.9569C41.693 45.9671 41.6542 45.9639 41.6201 45.9479ZM42.7751 38.0503L42.6932 38.2206C42.575 38.4537 42.4659 38.6688 42.3659 38.8929C41.8675 39.9139 41.4892 40.9876 41.2381 42.0932C40.9876 43.2259 41.1021 44.4074 41.5655 45.4728C41.5904 45.5323 41.6208 45.5893 41.6565 45.6431C42.5972 44.7196 43.3016 43.5889 43.7118 42.3442C43.9333 41.7382 44.0084 41.0897 43.931 40.4501C43.8537 39.8105 43.626 39.1975 43.2662 38.6598C43.1435 38.4873 43.0098 38.3227 42.866 38.1668L42.7751 38.0503Z" fill="#969696"/>
    <path d="M45.7127 41.7974C45.9582 41.2864 46.1947 40.8203 46.4039 40.3451C46.5161 40.1062 46.6044 39.857 46.6676 39.6011C46.7985 39.1232 46.9018 38.6383 46.9768 38.1488C47.1231 37.333 47.0216 36.4927 46.6851 35.7336C46.3485 34.9744 45.7919 34.3302 45.0852 33.8818L44.9488 33.8011C44.8487 34.2403 44.7487 34.6975 44.6577 35.1099C44.4406 36.1266 44.3369 37.1637 44.3485 38.2026C44.3632 39.3876 44.7544 40.5382 45.4671 41.4926C45.5399 41.5822 45.6126 41.636 45.7127 41.7974Z" fill="#CCCCCC"/>
    <path d="M45.7128 41.9139C45.6953 41.913 45.6782 41.9085 45.6626 41.9008C45.6469 41.8931 45.633 41.8823 45.6218 41.8691L45.54 41.7615L45.3763 41.5553C44.6456 40.5847 44.2445 39.4113 44.2304 38.2026C44.2143 37.1546 44.318 36.1081 44.5396 35.083C44.6033 34.7872 44.6669 34.5003 44.7397 34.1866L44.8397 33.7563C44.8434 33.7389 44.8511 33.7227 44.8621 33.7087C44.8731 33.6947 44.8872 33.6834 44.9034 33.6756C44.9385 33.6625 44.9773 33.6625 45.0125 33.6756H45.0671L45.1489 33.7294C45.8745 34.1906 46.446 34.8525 46.7921 35.6321C47.1383 36.4117 47.2437 37.2746 47.0951 38.113C47.0322 38.5486 46.9411 38.9798 46.8223 39.4039V39.5742C46.7589 39.8358 46.6706 40.0909 46.5585 40.3362L46.0856 41.3402L45.8674 41.7974C45.858 41.8139 45.8449 41.8282 45.8292 41.839C45.8134 41.8499 45.7954 41.8571 45.7764 41.8601L45.7128 41.9139ZM45.0307 33.9804L44.967 34.2493C44.9034 34.5451 44.8306 34.841 44.776 35.1457C44.5547 36.1527 44.451 37.1814 44.4668 38.2116C44.4797 39.3747 44.8648 40.5041 45.5673 41.4388L45.6946 41.6002L45.8219 41.3133C45.9947 40.9726 46.1493 40.6499 46.2948 40.3182C46.4037 40.085 46.489 39.8418 46.5494 39.5921V39.4128C46.6774 38.9954 46.7776 38.5702 46.8496 38.1399C46.9913 37.3472 46.8946 36.531 46.5714 35.7919C46.2482 35.0528 45.7126 34.4231 45.0307 33.9804Z" fill="#969696"/>
    <path d="M42.3936 11.5424C42.721 11.8471 43.0302 12.1699 43.3667 12.4388C43.9305 12.9049 44.4853 13.3352 45.0764 13.7835C46.0026 14.4647 47.1419 14.8041 48.2958 14.7427C48.6232 14.7427 48.9506 14.6441 49.3144 14.5992C49.3144 14.5185 49.3144 14.4737 49.2507 14.4379C49.0688 14.142 48.8869 13.8372 48.696 13.5414C48.5014 13.2537 48.2888 12.9783 48.0594 12.7167C47.6406 12.2123 47.1434 11.7765 46.5861 11.4258C46.0002 11.0255 45.3064 10.8065 44.5936 10.797C43.8808 10.7874 43.1813 10.9876 42.5845 11.372C42.5326 11.3997 42.4838 11.4327 42.439 11.4706C42.439 11.4706 42.4208 11.5155 42.3936 11.5424Z" fill="#CCCCCC"/>
    <path d="M47.977 14.8772C46.9092 14.8478 45.8781 14.487 45.0304 13.8463C44.4484 13.4429 43.8846 12.9498 43.348 12.5195C43.1115 12.3223 42.8842 12.1071 42.6659 11.892C42.4476 11.6769 42.4658 11.7037 42.3749 11.6051C42.365 11.5959 42.3571 11.5848 42.3517 11.5725C42.3463 11.5601 42.3435 11.5469 42.3435 11.5334C42.3435 11.52 42.3463 11.5067 42.3517 11.4944C42.3571 11.482 42.365 11.4709 42.3749 11.4617L42.4294 11.4079C42.4749 11.3642 42.5271 11.3279 42.5841 11.3003C43.2003 10.9051 43.9217 10.6992 44.6568 10.7088C45.3919 10.7184 46.1075 10.943 46.7129 11.3541C47.2787 11.7114 47.7849 12.1529 48.2135 12.6629C48.4499 12.9408 48.6682 13.2456 48.8501 13.5056C49.032 13.7656 49.132 13.9359 49.2684 14.151L49.4139 14.402C49.4155 14.4349 49.4155 14.4678 49.4139 14.5007V14.5724C49.4207 14.588 49.4242 14.6047 49.4242 14.6217C49.4242 14.6386 49.4207 14.6554 49.4139 14.671C49.4054 14.6874 49.3924 14.7012 49.3763 14.7107C49.3602 14.7202 49.3417 14.7251 49.323 14.7248L48.9865 14.7786C48.7618 14.8254 48.5337 14.8553 48.3044 14.8682L47.977 14.8772ZM42.5659 11.5424L42.775 11.7396C42.9882 11.9573 43.2128 12.1638 43.448 12.3582C43.9937 12.8064 44.5484 13.2546 45.1214 13.667C46.0256 14.3353 47.1396 14.6685 48.268 14.6082C48.4863 14.6082 48.7046 14.5544 48.9319 14.5186H49.132L48.9774 14.2765C48.8501 14.0614 48.7228 13.8373 48.5772 13.6311C48.3834 13.3481 48.1739 13.0757 47.9497 12.8153C47.5374 12.3224 47.0495 11.896 46.5037 11.5514C45.9388 11.1583 45.2665 10.9429 44.5751 10.9333C43.8837 10.9237 43.2055 11.1204 42.6295 11.4976L42.5659 11.5424Z" fill="#969696"/>
    <path d="M44.7305 15.5674C44.8942 15.8184 45.0488 16.0604 45.2125 16.2935C45.7829 17.1761 46.4542 17.9912 47.2132 18.7229C48.1081 19.602 49.3149 20.1035 50.5782 20.1213H50.7964C50.801 20.0916 50.801 20.0614 50.7964 20.0317C50.4353 18.61 49.6795 17.3155 48.6138 16.2935C48.1317 15.8187 47.5185 15.4941 46.8507 15.3602C46.1828 15.2263 45.4898 15.289 44.8578 15.5405H44.7941C44.7941 15.5405 44.7668 15.5405 44.7305 15.5674Z" fill="#CCCCCC"/>
    <path d="M50.5873 20.202C49.2982 20.1929 48.0628 19.6916 47.1406 18.8036C46.3733 18.068 45.6957 17.2467 45.1216 16.3563L44.7942 15.8632L44.6305 15.6391C44.624 15.625 44.6206 15.6098 44.6206 15.5943C44.6206 15.5788 44.624 15.5635 44.6305 15.5495C44.6379 15.5168 44.6574 15.4881 44.6851 15.4688H44.7669C45.4183 15.2086 46.1328 15.143 46.8216 15.2801C47.5105 15.4172 48.1432 15.7509 48.6412 16.2397C49.7178 17.2841 50.485 18.5983 50.8602 20.0407C50.8693 20.0699 50.8693 20.1011 50.8602 20.1303C50.8612 20.1629 50.851 20.1948 50.8312 20.2209C50.8113 20.247 50.7831 20.2656 50.751 20.2737H50.5328L50.5873 20.202ZM44.8943 15.6391L44.9852 15.7646L45.3126 16.2577C45.874 17.1335 46.5396 17.94 47.2952 18.6601C48.1719 19.5089 49.3494 19.988 50.5783 19.9958H50.6601C50.307 18.6136 49.5737 17.3541 48.5411 16.3563C48.0732 15.898 47.4792 15.5849 46.8326 15.4558C46.1861 15.3268 45.5153 15.3874 44.9034 15.6301L44.8943 15.6391Z" fill="#969696"/>
    <path d="M46.2766 29.3278C46.2766 29.4981 46.2766 29.6505 46.2766 29.8029C46.2087 31.136 46.325 32.472 46.6222 33.7742C46.8908 34.9246 47.5287 35.9589 48.4411 36.7235L48.6593 36.8759C48.7048 36.7145 48.7503 36.5801 48.7957 36.4366C49.1139 35.324 49.2248 34.1638 49.1231 33.0122C49.0784 32.2082 48.7964 31.4345 48.3117 30.7864C47.827 30.1382 47.1608 29.6437 46.3948 29.3637L46.2766 29.3278Z" fill="#CCCCCC"/>
    <path d="M48.6687 36.9924C48.6453 37.0014 48.6193 37.0014 48.5959 36.9924H48.5231L48.3776 36.8849C47.4299 36.1133 46.768 35.0542 46.4951 33.8728C46.1874 32.5631 46.068 31.2172 46.1404 29.8747C46.1404 29.7671 46.1404 29.6595 46.1404 29.543V29.3906C46.1292 29.3617 46.1292 29.3298 46.1404 29.3009C46.1726 29.2884 46.2083 29.2884 46.2405 29.3009H46.3041H46.4042C47.1766 29.6003 47.845 30.1131 48.329 30.7778C48.813 31.4426 49.092 32.2309 49.1325 33.0481C49.2396 34.2121 49.1255 35.3856 48.796 36.5083L48.7141 36.7862L48.6596 36.9566C48.6552 36.9739 48.6461 36.9897 48.6333 37.0024C48.6204 37.015 48.6044 37.0239 48.5868 37.0283L48.6687 36.9924ZM46.3951 29.4802C46.3951 29.5968 46.3951 29.7043 46.3951 29.8119C46.3207 31.1332 46.437 32.4582 46.7406 33.7473C47.0146 34.8779 47.6527 35.8907 48.5595 36.6338L48.6414 36.6876C48.6769 36.6006 48.7043 36.5106 48.7232 36.4187C49.0447 35.3251 49.1558 34.1819 49.0506 33.0481C49.0199 32.2628 48.755 31.5039 48.2892 30.8661C47.8234 30.2284 47.1772 29.7401 46.4314 29.4623L46.3951 29.4802Z" fill="#969696"/>
    <path d="M46.6768 24.6752C46.8495 26.0052 47.1886 27.309 47.6862 28.5569C47.9647 29.2213 48.3656 29.8291 48.8685 30.3497C49.2883 30.7836 49.7844 31.139 50.3327 31.3986H50.4236H50.4873C50.4873 31.282 50.4873 31.1655 50.4873 31.049C50.623 29.7805 50.474 28.4982 50.0508 27.2929C49.8328 26.5955 49.4144 25.9755 48.8462 25.5077C48.278 25.0399 47.5845 24.7446 46.8495 24.6573H46.7586L46.6768 24.6752Z" fill="#CCCCCC"/>
    <path d="M50.4872 31.5689H50.3963H50.2871C49.7254 31.3066 49.2193 30.9416 48.7957 30.4932C48.2837 29.9743 47.8736 29.3664 47.5861 28.7003C47.0866 27.44 46.7446 26.1245 46.5675 24.7828C46.5641 24.7558 46.5705 24.7284 46.5854 24.7055C46.6004 24.6825 46.623 24.6655 46.6494 24.6573V24.7738V24.6483H46.8495C47.6096 24.7288 48.3293 25.0261 48.9206 25.5038C49.5118 25.9815 49.9488 26.6188 50.178 27.3377C50.6309 28.5829 50.7986 29.9116 50.6691 31.2283V31.4703V31.5868C50.6577 31.6008 50.643 31.6117 50.6263 31.6186C50.6095 31.6255 50.5913 31.6282 50.5732 31.6264C50.5552 31.6246 50.5379 31.6184 50.5228 31.6084C50.5078 31.5984 50.4956 31.5848 50.4872 31.5689ZM46.8495 24.7738C46.9982 26.0516 47.3035 27.3068 47.7589 28.512C48.029 29.1744 48.4206 29.7822 48.9139 30.3049C49.3198 30.7317 49.8044 31.0785 50.3417 31.3269V31.1117C50.5033 29.8253 50.3665 28.5195 49.9416 27.2929C49.7253 26.625 49.3188 26.0326 48.7706 25.586C48.2224 25.1394 47.5556 24.8575 46.8495 24.7738Z" fill="#969696"/>
    <path d="M51.233 25.7869C51.1784 25.4104 51.1329 25.0428 51.0693 24.6932C50.9052 23.6882 50.5471 22.7235 50.0143 21.8515C49.6641 21.1988 49.1181 20.6685 48.4507 20.3332C47.7833 19.9979 47.0272 19.8738 46.2856 19.9779H46.2129C46.2766 20.1393 46.3311 20.3096 46.3948 20.471C46.7804 21.6101 47.309 22.6972 47.9681 23.7071C48.6929 24.7898 49.8193 25.5493 51.1057 25.8227L51.233 25.7869Z" fill="#CCCCCC"/>
    <path d="M51.2331 25.9034H51.0785C49.7592 25.6163 48.6086 24.8269 47.8772 23.7071C47.192 22.7283 46.6416 21.6642 46.2403 20.5427C46.2403 20.4351 46.1584 20.3275 46.122 20.211L46.0584 20.0407C46.0429 20.0216 46.0344 19.9979 46.0344 19.9734C46.0344 19.949 46.0429 19.9253 46.0584 19.9062L46.1311 19.8614H46.2221C46.986 19.7591 47.7635 19.8888 48.4507 20.2331C49.138 20.5775 49.7025 21.1201 50.069 21.7887C50.6087 22.6754 50.9728 23.6549 51.1421 24.6753C51.1421 24.9173 51.2149 25.1594 51.2512 25.4193L51.3058 25.7689C51.3185 25.8006 51.3185 25.8359 51.3058 25.8676C51.2969 25.8782 51.2858 25.8869 51.2733 25.8931C51.2608 25.8993 51.2471 25.9028 51.2331 25.9034ZM46.313 20.1213V20.1931L46.4312 20.5158C46.8352 21.6171 47.3759 22.6649 48.0409 23.6354C48.7407 24.6823 49.8272 25.4188 51.0694 25.6883V25.4821C51.0694 25.2311 50.9966 24.989 50.9511 24.747C50.7929 23.746 50.4409 22.7843 49.9144 21.9142C49.569 21.2909 49.0398 20.7857 48.3966 20.4656C47.7535 20.1454 47.0269 20.0254 46.313 20.1213Z" fill="#969696"/>
    <path d="M24.8684 46.3962C20.6757 46.3962 16.5771 45.1706 13.0909 42.8745C9.60475 40.5784 6.88762 37.3149 5.28312 33.4966C3.67862 29.6784 3.25881 25.4768 4.07678 21.4234C4.89474 17.3699 6.91375 13.6466 9.87848 10.7242C12.8432 7.80185 16.6205 5.81169 20.7327 5.0054C24.8449 4.19912 29.1073 4.61294 32.9809 6.19451C36.8545 7.77609 40.1654 10.4544 42.4948 13.8908C44.8241 17.3271 46.0674 21.3672 46.0674 25.5C46.0602 31.0398 43.8244 36.3507 39.8504 40.268C35.8764 44.1852 30.4885 46.389 24.8684 46.3962ZM24.8684 5.02522C20.7602 5.02522 16.7442 6.22605 13.3284 8.47585C9.91251 10.7256 7.25017 13.9234 5.67802 17.6647C4.10587 21.4059 3.69452 25.5227 4.496 29.4945C5.29747 33.4662 7.27577 37.1144 10.1807 39.9779C13.0857 42.8413 16.7868 44.7914 20.8161 45.5814C24.8454 46.3714 29.0219 45.966 32.8174 44.4163C36.6129 42.8666 39.8569 40.2423 42.1394 36.8752C44.4218 33.5081 45.64 29.5496 45.64 25.5C45.6352 20.0712 43.4452 14.8661 39.5508 11.0274C35.6564 7.18865 30.3759 5.02997 24.8684 5.02522Z" fill="#748FFC"/>
    <path d="M24.8683 44.1909C21.118 44.1909 17.4519 43.0947 14.3337 41.0409C11.2154 38.9871 8.78505 36.068 7.34988 32.6527C5.91471 29.2374 5.5392 25.4793 6.27085 21.8536C7.00249 18.2279 8.80843 14.8975 11.4603 12.2836C14.1121 9.66962 17.4908 7.88948 21.169 7.16829C24.8472 6.4471 28.6598 6.81724 32.1246 8.23191C35.5894 9.64658 38.5509 12.0422 40.6344 15.1159C42.718 18.1896 43.8301 21.8033 43.8301 25.5C43.8228 30.455 41.8228 35.2049 38.2683 38.7086C34.7138 42.2123 29.895 44.1838 24.8683 44.1909ZM24.8683 7.23944C21.2043 7.23944 17.6227 8.31041 14.5762 10.3169C11.5297 12.3234 9.15532 15.1753 7.75318 18.512C6.35105 21.8487 5.98419 25.5203 6.69899 29.0625C7.41379 32.6047 9.17815 35.8584 11.769 38.4122C14.3598 40.966 17.6606 42.7052 21.2542 43.4097C24.8477 44.1143 28.5725 43.7527 31.9576 42.3706C35.3426 40.9885 38.2359 38.648 40.2715 35.6451C42.307 32.6421 43.3935 29.1116 43.3935 25.5C43.3887 20.6585 41.4354 16.0166 37.9623 12.5931C34.4892 9.16959 29.78 7.24419 24.8683 7.23944Z" fill="#CCCCCC"/>
    <path d="M32.1803 31.2821H18.5387V28.4493L23.4406 23.5727C24.8866 22.1115 25.8324 21.0895 26.278 20.5248C26.67 20.0473 26.9943 19.5195 27.242 18.956C27.4361 18.4708 27.5349 17.9536 27.533 17.432C27.5602 17.116 27.5164 16.798 27.4048 16.5005C27.2932 16.203 27.1165 15.9334 26.8873 15.7108C26.4047 15.3129 25.788 15.1081 25.1594 15.1371C24.3951 15.1421 23.6421 15.32 22.9586 15.6571C22.1589 16.054 21.4108 16.5446 20.7304 17.1183L18.4841 14.5006C19.2218 13.8531 20.023 13.2795 20.876 12.7884C21.5332 12.4589 22.2298 12.2119 22.9495 12.0533C23.7693 11.866 24.6089 11.7757 25.4504 11.7844C26.5628 11.7603 27.6664 11.984 28.6789 12.4388C29.5684 12.8395 30.3249 13.4796 30.8616 14.2855C31.3775 15.0895 31.646 16.0236 31.6346 16.9748C31.6376 17.8275 31.4772 18.673 31.1617 19.4669C30.7916 20.3285 30.2952 21.132 29.6884 21.8515C28.6054 23.0931 27.4354 24.2583 26.1871 25.3387L23.677 27.6694V27.8487H32.1803V31.2821Z" fill="#747778"/>
    <path d="M31.7166 30.8787H18.075V28.0369L22.9769 23.1603C24.4229 21.6991 25.3778 20.6771 25.8143 20.1124C26.2096 19.6372 26.5342 19.109 26.7783 18.5436C26.9734 18.068 27.0723 17.5595 27.0694 17.0465C27.0965 16.7305 27.0527 16.4125 26.9411 16.115C26.8295 15.8175 26.6528 15.5479 26.4237 15.3253C25.941 14.9274 25.3243 14.7226 24.6957 14.7516C23.9314 14.7566 23.1784 14.9345 22.4949 15.2716C21.6952 15.6685 20.9471 16.1591 20.2668 16.7328L18.0295 14.1151C18.7626 13.466 19.561 12.8923 20.4123 12.4029C21.0678 12.061 21.7645 11.8019 22.4858 11.632C23.3056 11.4447 24.1452 11.3544 24.9867 11.3631C26.102 11.3401 27.2083 11.5637 28.2243 12.0175C29.1073 12.4159 29.86 13.0492 30.3979 13.8462C30.9138 14.6502 31.1823 15.5843 31.1709 16.5355C31.1739 17.3882 31.0135 18.2337 30.698 19.0277C30.3279 19.8893 29.8315 20.6927 29.2247 21.4122C28.1417 22.6538 26.9717 23.8191 25.7234 24.8994L23.2042 27.2929V27.4722H31.7075L31.7166 30.8787Z" fill="#DBDBDB"/>
    <path d="M16.3198 36.3021C17.0383 35.6029 16.7564 34.8589 16.1197 34.2134L15.1194 33.2004L12.1455 36.0691L12.8003 36.7414L13.8098 35.7643L14.1736 36.1228C14.2932 36.2865 14.4472 36.4227 14.6253 36.5221C14.8033 36.6216 15.001 36.6819 15.2049 36.6989C15.4087 36.7159 15.6139 36.6893 15.8064 36.6208C15.9989 36.5523 16.174 36.4436 16.3198 36.3021ZM14.6647 35.5671L14.3463 35.2443L15.2558 34.3479L15.565 34.6527C15.9197 35.0113 16.0015 35.316 15.6923 35.6119C15.3831 35.9077 15.0193 35.9346 14.6647 35.5671Z" fill="#747778"/>
    <path d="M15.1646 38.7853L16.0103 39.2425L16.7197 38.5164L17.8201 39.1259L17.5655 40.1031L18.4749 40.5782L19.3843 36.3649L18.4021 35.827L15.1646 38.7853ZM18.4749 36.7055C18.3567 37.1358 18.2203 37.602 18.1202 38.0233L18.0111 38.4088L17.2381 37.9785L17.5109 37.6916C17.8201 37.3868 18.1475 37.0283 18.4203 36.6966L18.4749 36.7055Z" fill="#747778"/>
    <path d="M21.9854 37.2165C21.4949 37.1126 20.9826 37.1925 20.5485 37.4406L20.8941 38.1399C21.1712 37.9896 21.4927 37.9389 21.8036 37.9964C22.14 38.0592 22.3492 38.2385 22.2856 38.5522C22.2219 38.866 22.0127 38.9736 21.5216 38.875L21.1033 38.7943L20.9578 39.4576L21.5034 39.5742C22.0127 39.6728 22.2219 39.87 22.1491 40.2107C22.0764 40.5513 21.7399 40.6858 21.3216 40.6051C20.9521 40.5314 20.6261 40.3193 20.4121 40.0134L19.7937 40.5334C20.1159 41.0009 20.613 41.3233 21.176 41.4298C22.0855 41.6091 22.8767 41.3222 22.9949 40.5334C23.0481 40.3214 23.0256 40.0978 22.9312 39.9003C22.8368 39.7027 22.6762 39.5432 22.4765 39.4487C22.6789 39.408 22.8612 39.3006 22.9935 39.1442C23.1257 38.9878 23.2 38.7917 23.2041 38.5881C23.3496 37.9158 22.9222 37.4048 21.9854 37.2165Z" fill="#747778"/>
    <path d="M25.7415 37.3958L24.3137 37.5482L24.7593 41.636L25.6688 41.5374L25.5233 40.1479L26.0326 40.0941C26.942 40.0045 27.6059 39.5024 27.5058 38.5432C27.4058 37.5841 26.651 37.2972 25.7415 37.3958ZM25.9234 39.359L25.4687 39.4128L25.3323 38.1757H25.7688C26.269 38.1219 26.5691 38.2474 26.6146 38.6777C26.6601 39.108 26.4418 39.3052 25.9325 39.359H25.9234Z" fill="#747778"/>
    <path d="M29.2155 36.5083C28.4151 36.8669 27.8877 37.4048 28.306 38.3012C28.4041 38.5426 28.5779 38.7467 28.8021 38.8838C29.0262 39.0209 29.289 39.0838 29.5519 39.0632L29.27 40.9816L30.2431 40.5334L30.4159 38.8301L30.8433 38.6329L31.4709 39.9776L32.3257 39.5921L30.5887 35.8629L29.2155 36.5083ZM30.1249 38.1936C29.6793 38.3998 29.3428 38.3191 29.2155 37.9516C29.0881 37.5841 29.2155 37.351 29.6702 37.1448L30.1067 36.9476L30.5978 37.9964L30.1249 38.1936Z" fill="#747778"/>
    <path d="M36.6819 35.6657L36.2727 35.2533L35.9089 35.603L33.5625 33.2094L31.7437 34.9037C32.1984 35.5581 32.4257 35.8988 32.835 36.5084C33.3988 37.36 33.4989 37.7634 33.4079 37.9427L33.2715 38.0682L33.6808 38.4895L34.7175 39.386L35.3177 38.8033L34.4811 37.9068L36.118 36.356L36.9547 37.2076L37.555 36.6339L36.6819 35.6657ZM34.1719 37.2524C34.0457 36.809 33.8426 36.3904 33.5716 36.0153C33.2897 35.594 33.1442 35.3878 32.8532 34.9665L33.4352 34.4107L35.2541 36.2484L34.1719 37.2524Z" fill="#747778"/>
    <path d="M16.4199 35.836C17.1383 35.1368 16.8564 34.3927 16.2198 33.7473L15.2194 32.7433L12.291 35.6119L12.9458 36.2753L13.9553 35.2982L14.3191 35.6657C14.4395 35.8215 14.5919 35.9506 14.7662 36.0446C14.9405 36.1387 15.133 36.1955 15.331 36.2116C15.5291 36.2277 15.7283 36.2025 15.9159 36.1378C16.1035 36.0731 16.2752 35.9703 16.4199 35.836ZM14.7647 35.1099L14.4464 34.7872L15.3558 33.8907L15.665 34.1955C16.0197 34.5541 16.1016 34.8589 15.7924 35.1637C15.4831 35.4685 15.1285 35.4774 14.7647 35.1099Z" fill="#DBDBDB"/>
    <path d="M15.1829 38.4088L16.0195 38.866L16.7289 38.1399L17.8384 38.7495L17.5747 39.7266L18.4841 40.2017L19.3936 35.9884L18.4114 35.4506L15.1829 38.4088ZM18.4841 36.3291C18.375 36.7594 18.2295 37.2255 18.1294 37.6469L18.0294 38.0323L17.2473 37.602L17.5292 37.3152C17.8293 37.0104 18.1658 36.6518 18.4386 36.3201L18.4841 36.3291Z" fill="#DBDBDB"/>
    <path d="M22.0675 36.8311C21.5769 36.7263 21.0643 36.8062 20.6306 37.0552L20.9671 37.7544C21.2444 37.6048 21.5656 37.5542 21.8765 37.611C22.213 37.6737 22.4313 37.853 22.3676 38.1668C22.3039 38.4805 22.0857 38.5881 21.5946 38.4895L21.1762 38.4088L21.0398 39.0722L21.5855 39.1887C22.0857 39.2873 22.2948 39.4846 22.2221 39.8252C22.1493 40.1658 21.8219 40.3003 21.4036 40.2196C21.0327 40.1437 20.7064 39.9283 20.4942 39.619L19.8757 40.1479C20.2021 40.6152 20.7019 40.9372 21.2672 41.0444C22.1766 41.2237 22.9587 40.9368 23.086 40.1479C23.1358 39.936 23.1117 39.7137 23.0177 39.517C22.9237 39.3202 22.7651 39.1603 22.5677 39.0632C22.7496 39.0224 22.9144 38.9273 23.0396 38.791C23.1649 38.6547 23.2446 38.4837 23.2679 38.3012C23.4225 37.5303 23.0042 37.0193 22.0675 36.8311Z" fill="#DBDBDB"/>
    <path d="M25.6961 37.0731L24.2683 37.2255L24.7139 41.3133L25.6234 41.2147L25.4961 39.8431L26.0144 39.7893C26.9239 39.6997 27.5878 39.1977 27.4786 38.2385C27.3695 37.2793 26.6056 36.9834 25.6961 37.0731ZM25.878 39.0453H25.4233L25.2869 37.8171H25.7143C26.2236 37.7633 26.5146 37.8889 26.5692 38.3102C26.6238 38.7315 26.3873 38.9915 25.878 39.0453Z" fill="#DBDBDB"/>
    <path d="M29.2155 36.1318C28.4151 36.4904 27.8877 37.0283 28.306 37.9247C28.4041 38.1661 28.5779 38.3702 28.8021 38.5073C29.0262 38.6444 29.289 38.7073 29.5519 38.6867L29.27 40.5961L30.2431 40.1569L30.4159 38.4536L30.8433 38.2564L31.4709 39.6011L32.3257 39.2156L30.5887 35.4864L29.2155 36.1318ZM30.1249 37.8171C29.6793 38.0233 29.3428 37.9427 29.2155 37.5751C29.0881 37.2076 29.2155 36.9745 29.6702 36.7683L30.1067 36.5711L30.5978 37.6199L30.1249 37.8171Z" fill="#DBDBDB"/>
    <path d="M36.6819 35.3609L36.2727 34.9396L35.9089 35.2892L33.5625 32.8957L31.7437 34.5899L32.835 36.1946C33.3988 37.0462 33.4989 37.4586 33.4079 37.6289L33.2715 37.7634L33.6808 38.1757L34.7175 39.0722L35.3177 38.4984L34.4811 37.6468L36.118 36.087L36.9547 36.9386L37.555 36.3649L36.6819 35.3609ZM34.1719 36.9476C34.0493 36.5028 33.846 36.0837 33.5716 35.7105C33.2897 35.2802 33.1442 35.074 32.8532 34.6527L33.4352 34.1059L35.2541 35.8987L34.1719 36.9476Z" fill="#DBDBDB"/>
    <path opacity="0.3" d="M47.8771 7.44557C44.1669 2.86212 39.124 -0.493858 33.4243 -2.17252C27.7246 -3.85118 21.6405 -3.77229 15.9876 -1.94644C10.3347 -0.120588 5.38305 3.36498 1.79647 8.04303C-1.79011 12.7211 -3.84023 18.3681 -4.0791 24.227C0.250367 27.2362 5.23307 29.207 10.4719 29.9822C18.3977 31.1423 26.4851 29.5212 33.3218 25.4021C40.1585 21.283 45.3101 14.9275 47.8771 7.44557Z" fill="url(#paint0_linear)"/>
    </g>
    <rect x="-1043.5" y="-233.5" width="1768" height="796" rx="4.5" stroke="#7B61FF" strokeDasharray="10 5"/>
    <defs>
    <linearGradient id="paint0_linear" x1="14.8554" y1="2.82888" x2="27.3935" y2="26.6718" gradientUnits="userSpaceOnUse">
    <stop stopColor="white" stopOpacity="0"/>
    <stop offset="0.35" stopColor="white" stopOpacity="0.01"/>
    <stop offset="0.49" stopColor="white" stopOpacity="0.03"/>
    <stop offset="0.59" stopColor="white" stopOpacity="0.08"/>
    <stop offset="0.67" stopColor="white" stopOpacity="0.15"/>
    <stop offset="0.74" stopColor="white" stopOpacity="0.24"/>
    <stop offset="0.8" stopColor="white" stopOpacity="0.35"/>
    <stop offset="0.85" stopColor="white" stopOpacity="0.47"/>
    <stop offset="0.9" stopColor="white" stopOpacity="0.62"/>
    <stop offset="0.95" stopColor="white" stopOpacity="0.79"/>
    <stop offset="1" stopColor="white"/>
    </linearGradient>
    <clipPath id="clip0">
    <rect width="50" height="50" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);

export default IconSecondCategory;
