import { FC, useContext } from 'react';

import { Box } from '@mui/material';
import FunnelBlock from 'components/common/FunnelBlock';
import { GroupedActionButton, GroupedActionButtonsContainer } from 'components/common/GroupedActionButtons';
import { SectionCodes } from 'const';
import { IconCheckMark } from 'icons';
import { FormEntityInfo } from 'portfolio3/components/dataEntry';
import { MobileDrawerHeader } from 'portfolio3/components/drawers';
import { IInnerFormProps } from 'portfolio3/features/dataEntryForm';
import { Switcher, SwitcherButton } from 'portfolio3/ui-kit';
import { Drawer, IDrawerBase } from 'portfolio3/ui-kit/Drawer';
import { mergeSx } from 'utils';

import * as commonStyles from '../../commonStyles';
import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';
import PrimaryBlocks from '../PrimaryBlocks';
import SecondaryBlocks from '../SecondaryBlocks';
import * as styles from './styles';

interface IStudentFormMobileProps {
  title: string;
}

const StudentFormMobile: FC<IStudentFormMobileProps & IInnerFormProps> = ({
  title,

  accentColor,
  isSubmitDisabled,
  entityProperties,
  tabs,
  relatedObjectType,
  relatedObjectName,
  onSubmit,
  onClose,
}) => {
  const { isOpen, formStep, formTab, onChangeFormTab, formData } = useContext(LocalDataEntryFormContext);

  const entityInfo = entityProperties?.entityInfo;
  const hasTabs = tabs && tabs.length > 0;

  const tabsElement = (
    <Switcher
      value={formTab}
      onChange={onChangeFormTab}
      componentSize="large"
      sx={{ display: 'flex', flexDirection: 'row', width: '100%', '& button': { flexGrow: 1, flexBasis: '1px' } }}
    >
      {tabs.map((tab) => (
        <SwitcherButton key={tab.value} value={tab.value} content={tab.title} />
      ))}
    </Switcher>
  );

  const header: IDrawerBase['header'] = (crossButton) => (
    <MobileDrawerHeader crossButton={crossButton} title={title} accentColor={accentColor} />
  );

  const studentFooter = (
    <GroupedActionButtonsContainer disableAbsolutePosition>
      <GroupedActionButton
        variant="primary"
        startIcon={IconCheckMark}
        handleClick={onSubmit}
        disabled={isSubmitDisabled}
      >
        {formData.typeCode === SectionCodes.professionSpoMetaskills || formData.id
          ? 'Сохранить'
          : 'Добавить в портфолио'}
      </GroupedActionButton>
    </GroupedActionButtonsContainer>
  );

  return (
    <Drawer
      open={isOpen}
      isFullHeight
      isMobile={true}
      anchor="bottom"
      swipeable
      swipeableProps={{
        onClose: () => onClose?.(),
      }}
      header={header}
      footer={formStep !== 'primary' && studentFooter}
      sx={mergeSx(commonStyles.drawer, { backgroundColor: '#F4F3F8' })}
      onClose={onClose}
    >
      <>
        {formStep === 'primary' && (
          <Box className="primary-blocks-container" sx={styles.inlinePadding}>
            <FunnelBlock sx={commonStyles.formControlsColumn}>
              <PrimaryBlocks />
            </FunnelBlock>
          </Box>
        )}
        {formStep === 'secondary' && (
          <Box className="entity-info-container" sx={styles.inlinePadding}>
            {entityInfo && (
              <FormEntityInfo
                name={entityInfo.name ?? 'Другое'}
                relatedObjectType={relatedObjectType}
                relatedObjectName={relatedObjectName}
              />
            )}
          </Box>
        )}
        {formStep === 'secondary' && hasTabs && (
          <Box
            className="tabs-container"
            sx={mergeSx(styles.inlinePadding, { zIndex: 10, position: 'sticky', top: 0, marginTop: '8px' })}
          >
            {tabsElement}
          </Box>
        )}
        <SecondaryBlocks />
      </>
    </Drawer>
  );
};

export default StudentFormMobile;
