import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const header: SxStyles = {
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',

  paddingBlock: '40px 16px',
  paddingInline: '16px',

  backgroundColor: '#F1F3FE',
};

export const headerCross: SxStyles = {
  position: 'absolute',
  top: '12px',
  right: '16px',
};

export const horizontalDivider: SxStyles = {
  width: '6px',
  height: '6px',
  backgroundColor: NeutralColors.light_neutrals_500,
  borderRadius: '50%',
  marginInline: '12px',
};

export const contentHeader: SxStyles = {
  display: 'flex',
  gap: '12px',
  alignItems: 'center',

  padding: '16px',
  paddingTop: 0,
};

export const doughnutContainer: SxStyles = {
  width: '58px',
  height: '58px',

  '.exam-doughnut__result': {
    fontSize: '16px',
    lineHeight: '24px',
  },
};
