import { FC, useState } from 'react';

import { Box } from '@mui/material';
import { recommendationNavigation, StudentSectionSettingTypeCodes } from 'const';
import {
  useAppSelector,
  useBrightTheme,
  useComponentDidMount,
  useDataLoad,
  useSingleEffect,
  useWidgetVisibility,
} from 'hooks';
import { widgetIllustrations } from 'images';
import { MoonLoader } from 'portfolio3/components/common/loaders';
import { WidgetContainer as Widget } from 'portfolio3/components/common/WidgetContainer';
import { WidgetContainerTitle } from 'portfolio3/components/widgetContainerRecipes';
import { emitYMEvent } from 'portfolio3/features/yandexMetrika';
import { getDataLoadRequests } from 'utils';

import { initialServerFilters, MINIMUM_EGE_EXAMS, VuzRecommendationTab } from '../../const';
import { useSearchVuz } from '../../hooks';
import { realExamSubjectsSelector, trialExamSubjectsSelector } from '../../model/selectors';
import VuzRecommendationWidget from '../VuzRecommendationWidget';

const sectionTypeCode = StudentSectionSettingTypeCodes.recommendation;
const subsectionTypeCode = StudentSectionSettingTypeCodes.recommendationVuz;

const VuzRecommendationWidgetContainer: FC = () => {
  const trialExamSubjects = useAppSelector(trialExamSubjectsSelector);
  const realExamSubjects = useAppSelector(realExamSubjectsSelector);
  const { classLevel } = useAppSelector((state) => state.currentStudent);
  const isAllowedEgeClass = Number(classLevel) === 11;

  const isBrightTheme = useBrightTheme();
  const { isSettingsMode, isWidgetVisible, toggleElement, overlayElement } = useWidgetVisibility({
    sectionTypeCode,
    subsectionTypeCode,
  });

  useDataLoad({ shouldLoad: isWidgetVisible, requests: getDataLoadRequests().recommendationVuz });

  const showRealEgeTab = realExamSubjects.content.length >= MINIMUM_EGE_EXAMS || isAllowedEgeClass;
  const showTrialEgeTab = trialExamSubjects.content.length >= MINIMUM_EGE_EXAMS || isAllowedEgeClass;

  const [currentTab, setCurrentTab] = useState<VuzRecommendationTab | null>(null);
  const { searchVuz, clearVuzList } = useSearchVuz({ currentTab: currentTab ?? VuzRecommendationTab.SUBJECTS });

  const handleChangeCurrentTab = (tab: VuzRecommendationTab) => {
    clearVuzList();
    setCurrentTab(tab);

    if (currentTab !== tab) {
      emitYMEvent({ type: 'tabsRecommendationsSwitch' });

      if (tab === VuzRecommendationTab.FAVORITES) {
        emitYMEvent({ type: 'favoritesRecommendation' });
      }
    }
  };

  useComponentDidMount(() => {
    return () => clearVuzList();
  });

  useSingleEffect(() => {
    const isUnknownTab = currentTab === null;
    const isEmptySubjectTrialGia = currentTab === VuzRecommendationTab.TRIAL_GIA && !trialExamSubjects.content.length;
    const isEmptySubjectRealGia = currentTab === VuzRecommendationTab.REAL_GIA && !realExamSubjects.content.length;

    const shouldSkipEffect = isUnknownTab || isEmptySubjectTrialGia || isEmptySubjectRealGia;

    if (shouldSkipEffect) return;

    return () => {
      clearVuzList();
      searchVuz(initialServerFilters);
    };
  }, [currentTab, trialExamSubjects, realExamSubjects]);

  useSingleEffect(() => {
    const shouldSkipEffect =
      trialExamSubjects.loading ||
      !trialExamSubjects.isCached ||
      realExamSubjects.loading ||
      !realExamSubjects.isCached;

    if (shouldSkipEffect) return;

    return () => {
      // логика определения первого выбранного раздела при открытии
      // проходим по каждому разделу справа налево -> если раздел доступен то ставим его
      let currentTab = VuzRecommendationTab.SUBJECTS;
      if (showTrialEgeTab) currentTab = VuzRecommendationTab.TRIAL_GIA;
      if (showRealEgeTab) currentTab = VuzRecommendationTab.REAL_GIA;
      setCurrentTab(currentTab);
    };
  }, [trialExamSubjects.loading, realExamSubjects.loading, showTrialEgeTab, showRealEgeTab]);

  if (!isWidgetVisible) return null;

  const isWidgetDataLoading = currentTab === null;

  const loadingTitleElement = (
    <WidgetContainerTitle title="Рекомендации вузов" right={isSettingsMode && toggleElement} />
  );

  return (
    <Widget containerData={recommendationNavigation.vuzRecommendations} withBackground={isBrightTheme}>
      {overlayElement}

      {/* Хедер в момент загрузки */}
      {isWidgetDataLoading && (
        <>
          <Widget.Header title={loadingTitleElement} backgroundImage={widgetIllustrations.recommendationVuz} />
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <MoonLoader />
          </Box>
        </>
      )}

      {!isWidgetDataLoading && currentTab && (
        <VuzRecommendationWidget
          currentTab={currentTab}
          onTabChange={handleChangeCurrentTab}
          showRealEgeTab={showRealEgeTab}
          showTrialEgeTab={showTrialEgeTab}
          isSettingsMode={isSettingsMode}
          toggleElement={toggleElement}
        />
      )}
    </Widget>
  );
};

export default VuzRecommendationWidgetContainer;
