import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { Box, InputAdornment, useMediaQuery } from '@mui/material';
import { useAppSelector } from 'hooks';
import { IconSearch } from 'icons/internetAndCode';
import { commonTheme } from 'portfolio3/styles/theme';
import { ClearableInput } from 'portfolio3/ui-kit';
import { Button } from 'portfolio3/ui-kit/button';
import { CommonUiKitSize, InputController } from 'portfolio3/ui-kit/types';

import { searchPanelActions } from '../../model/actions';
import * as styles from './styles';

interface ISearchBarProps {
  isSearchButtonDisabled: boolean;
  onClose: () => void;
  onSearch: (personId: string, searchQuery: string) => void;
}

const SearchBar: FC<ISearchBarProps> = ({ isSearchButtonDisabled, onClose, onSearch }) => {
  const dispatch = useDispatch();
  const searchQuery = useAppSelector((state) => state.searchPanel.query);
  const currentStudent = useAppSelector((state) => state.currentStudent);

  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonMd'));
  const isShortPlaceholder = useMediaQuery(commonTheme.breakpoints.down('commonLg'));

  const handleSearchData = () => {
    if (searchQuery.length < 3) return;
    if (!currentStudent.meshId) return;

    onSearch(currentStudent.meshId, searchQuery);
  };

  const handleSearchWithEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSearchData();
    }
  };

  const searchController: InputController = {
    handleChange: (value) => {
      dispatch(searchPanelActions.setQuery(value));
    },
  };

  const size: CommonUiKitSize = isMobile ? 'medium' : 'large';
  const isDisabled = isSearchButtonDisabled || searchQuery.length < 3;

  return (
    <Box className="search-bar" sx={styles.root}>
      <ClearableInput
        size={size}
        renderMode="fixed"
        autoFocus
        onKeyUp={handleSearchWithEnter}
        placeholder={isShortPlaceholder ? 'Поиск...' : 'Введите поисковый запрос и нажмите на кнопку “Начать поиск”...'}
        value={searchQuery}
        controller={searchController}
        startAdornment={
          <InputAdornment position="start">
            <IconSearch />
          </InputAdornment>
        }
      />
      <Box className="search-bar__actions" sx={styles.actions}>
        <Button size={size} onClick={handleSearchData} disabled={isDisabled} sx={styles.searchButton}>
          {isMobile ? 'Найти' : 'Начать поиск'}
        </Button>
        {!isMobile && (
          <Button size={size} variant="secondary" onClick={onClose}>
            Отмена
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default SearchBar;
