/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { format } from 'date-fns';

import { ChangeSettingsHeader } from '../../../components/admin';
import AdminVisibilityHistoryContainer from '../VisibilityHistory';
import AdminVisibilitySettingsContainer from '../VisibilitySettings';

import {
  getAdminHistoryActions
} from '../../../actions';
import { ChangeHistoryContext } from '../../../context';
import { AdminSettingsPageTabs, AdminSettingsPageTabsRoute, DifferentCode } from '../../../const';
import { IRootState } from '../../../reducers';
import { IChangeSettingFilters } from '../../admin/ChangeSettings/types';
import PaddingContainer from 'portfolio3/components/common/PaddingContainer';
import { ICommonResponse, IDictionaryItem } from 'api/types';
import { parentSectionsAllSelector } from 'selectors';

import './index.scss';

interface IChangeSettingsProps {
  portfolioSections: ICommonResponse<IDictionaryItem>,
  getAdminHistory: typeof getAdminHistoryActions.request
}

const ChangeSettings: React.FC<IChangeSettingsProps> = ({
  portfolioSections,
  getAdminHistory
}) => {
  const [settingType, setSettingType] = useState<AdminSettingsPageTabs>(AdminSettingsPageTabs.settings);
  const initialFilters: IChangeSettingFilters = {
    startDate: null,
    endDate: null,
    category: portfolioSections.content?.length === 1 ? portfolioSections.content[0].code : DifferentCode,
    page: 0,
    limit: 10,
    sort: { date: 'desc' }
  };
  const [filters, setFilters] = useState<IChangeSettingFilters>(initialFilters);

  const history = useHistory();
  const match = useRouteMatch();

  const getCurrentHistoryCategoryCode = (categoryCode?: number) => {
    if (categoryCode === DifferentCode) return undefined;
    return categoryCode;
  };

  useEffect(() => {
    setFilters(initialFilters);

    const tabRoute = AdminSettingsPageTabsRoute[settingType];
    history.replace(`${match.url}/${tabRoute}`);
  }, [settingType]);

  useEffect(() => {
    if (settingType === AdminSettingsPageTabs.history) {
      getAdminHistory({
        sectionCode: getCurrentHistoryCategoryCode(filters.category),
        startDate: filters.startDate ? format(filters.startDate, 'yyyy-MM-dd') : undefined,
        endDate: filters.endDate ? format(filters.endDate, 'yyyy-MM-dd') : undefined,
        pagingOptions: {
          pageNumber: filters.page,
          pageSize: filters.limit
        },
        sort: filters.sort
      });
    }
  }, [filters]);

  return (
    <ChangeHistoryContext.Provider value={{
      currentFilters: filters,
      setCurrentFilters: setFilters
    }}
    >
      <ChangeSettingsHeader settingType={settingType} onChangeSettingType={setSettingType} />
      <PaddingContainer className="change-settings-container" sx={{ marginTop: '24px' }}>
        {settingType === AdminSettingsPageTabs.settings && <AdminVisibilitySettingsContainer />}
        {settingType === AdminSettingsPageTabs.history && <AdminVisibilityHistoryContainer />}
      </PaddingContainer>
    </ChangeHistoryContext.Provider>
  );
};

export default connect(
  (state: IRootState) => ({
    portfolioSections: parentSectionsAllSelector(state),
  }),
  {
    getAdminHistory: getAdminHistoryActions.request
  }
)(ChangeSettings);
