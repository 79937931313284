/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';

import {
  FAILURE,
  REQUEST,
  SUCCESS
} from '../../actions/utils';

import genericReducers from '../genericReducer';
import { IReducers } from '..';
import {
  CLEAR_EMPLOYEE_HISTORY_FORM,
  GET_AFFILATION_BY_ID,
  GET_EMPLOYMENT_BY_ID,
  GET_EVENT_BY_ID,
  GET_GIA_WORLDSKILLS_BY_ID,
  GET_PROJECT_BY_ID,
  GET_REWARD_BY_ID,
  GET_SPORT_REWARD_BY_ID
} from '../../actions';

export type HistoryFormEnityState = {
  content: object,
  loading: boolean
};

const initialState = {
  content: {},
  loading: false
} as HistoryFormEnityState;

const historyFormEntity: IReducers<HistoryFormEnityState> = {
  [GET_EVENT_BY_ID[REQUEST]]: genericReducers().get.request,
  [GET_EVENT_BY_ID[SUCCESS]]: genericReducers().get.success,
  [GET_EVENT_BY_ID[FAILURE]]: genericReducers().get.failure,

  [GET_PROJECT_BY_ID[REQUEST]]: genericReducers().get.request,
  [GET_PROJECT_BY_ID[SUCCESS]]: genericReducers().get.success,
  [GET_PROJECT_BY_ID[FAILURE]]: genericReducers().get.failure,

  [GET_EMPLOYMENT_BY_ID[REQUEST]]: genericReducers().get.request,
  [GET_EMPLOYMENT_BY_ID[SUCCESS]]: genericReducers().get.success,
  [GET_EMPLOYMENT_BY_ID[FAILURE]]: genericReducers().get.failure,

  [GET_AFFILATION_BY_ID[REQUEST]]: genericReducers().get.request,
  [GET_AFFILATION_BY_ID[SUCCESS]]: genericReducers().get.success,
  [GET_AFFILATION_BY_ID[FAILURE]]: genericReducers().get.failure,

  [GET_REWARD_BY_ID[REQUEST]]: genericReducers().get.request,
  [GET_REWARD_BY_ID[SUCCESS]]: genericReducers().get.success,
  [GET_REWARD_BY_ID[FAILURE]]: genericReducers().get.failure,

  [GET_SPORT_REWARD_BY_ID[REQUEST]]: genericReducers().get.request,
  [GET_SPORT_REWARD_BY_ID[SUCCESS]]: genericReducers().get.success,
  [GET_SPORT_REWARD_BY_ID[FAILURE]]: genericReducers().get.failure,

  [GET_GIA_WORLDSKILLS_BY_ID[REQUEST]]: genericReducers().get.request,
  [GET_GIA_WORLDSKILLS_BY_ID[SUCCESS]]: genericReducers().get.success,
  [GET_GIA_WORLDSKILLS_BY_ID[FAILURE]]: genericReducers().get.failure,

  [CLEAR_EMPLOYEE_HISTORY_FORM]: () => ({
    content: {},
    loading: false
  })
};

export default connectReducers(initialState, historyFormEntity);
