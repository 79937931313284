import { NeutralColors } from 'portfolio3/styles';
import { Colors } from 'style/variables';
import { SxStyles } from 'types';
import { numberToPixelsString } from 'utils';

export const root: SxStyles = {
  position: 'relative',
  minHeight: '270px',
  width: '200px',
  paddingBottom: '16px',

  border: '1px solid',
  borderColor: NeutralColors.light_neutrals_300,
  borderRadius: '12px',

  textAlign: 'center',
  overflow: 'hidden',
};

export const block = (paddingInline: number): SxStyles => ({
  paddingInline: numberToPixelsString(paddingInline),
});

export const divider: SxStyles = {
  width: '100%',
  height: '1px',
  marginBlock: '8px',
  backgroundColor: NeutralColors.light_neutrals_300,
};

export const verification: SxStyles = {
  position: 'absolute',
  top: '12px',
  left: '12px',
};

export const buttonMore: SxStyles = {
  position: 'absolute',
  top: '4px',
  right: '4px',
};

export const name: SxStyles = {
  height: '44px',
  marginTop: '4px',

  fontSize: '15px',
  lineHeight: '22px',
  fontWeight: 600,

  cursor: 'pointer',

  '&:hover': {
    color: Colors['blue-primrose'],
  },
};

export const date: SxStyles = {
  height: '20px',
  marginTop: '8px',
  color: '#7B819B',
};

export const badgeContainer: SxStyles = {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  marginTop: '12px',
};

export const badgeDivider: SxStyles = {
  zIndex: -1,
  marginBlock: '14px',
};

export const badgeDividerAbsolute: SxStyles = {
  position: 'absolute',
};

export const description: SxStyles = {
  height: '40px',
  marginTop: '8px',
};

export const entityId: SxStyles = {
  height: '40px',
};
