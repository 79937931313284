import { FC, useContext } from 'react';

import { Box } from '@mui/material';
import { IIndependentDiagnosticInfo } from 'api/types';
import { IndependentDiagnosticLevelTypeNames } from 'const';

import DiagnosticContext from '../../context/diagnosticContext';
import { AerobaticsAchievement, SpecialistAchievement } from '../achievements';
import * as styles from './styles';
import { getAerobaticsAchieventText, getSpecialistAchievementText } from './utils';

const RatingAchievements: FC = () => {
  const { filters, independentDiagnostics, independentDiagnosticPeriods } = useContext(DiagnosticContext);

  /* находим текущий период */
  const currentPeriod = independentDiagnosticPeriods.find((period) => period.code === filters.independentPeriodCode);
  /* находим объект диагностики по текущему периоду */
  const currentDiagnosticObject = independentDiagnostics.content?.find((diagnosticGroup) => {
    return diagnosticGroup.learningYear === currentPeriod?.value;
  })?.diagnostics;

  /* определение видимости достижения Высший пилотаж */
  const diagnosticsTotal = currentDiagnosticObject?.totalDiagnostic;
  const totalLevelOverHighPercent =
    (Number(diagnosticsTotal?.totalLevelType?.totalLevelHight) / Number(diagnosticsTotal?.total)) * 100;
  const isAerobaticsAchievementVisible = totalLevelOverHighPercent > 40;

  /* определение видимости достижения Специалист */
  // поиск наиболее успешных диагностик (с нужными результатами)
  const specialDiagnostics =
    currentDiagnosticObject?.diagnostic?.filter((diagnostic) => {
      const { levelType } = diagnostic;
      if (!levelType) return false;
      return [
        IndependentDiagnosticLevelTypeNames.base,
        IndependentDiagnosticLevelTypeNames.overHigh,
        IndependentDiagnosticLevelTypeNames.high,
      ].includes(levelType);
    }) || [];
  // группируем диагностки по предметам
  const subjectToDiagnosticsMap = new Map<string, IIndependentDiagnosticInfo[]>();
  specialDiagnostics.forEach((diagnostic) => {
    const { subject } = diagnostic;
    const subjectDiagnostics = subjectToDiagnosticsMap.get(subject) || [];
    subjectDiagnostics.push(diagnostic);
    subjectToDiagnosticsMap.set(subject, subjectDiagnostics);
  });
  // находим предметы по которым более 2 результатов
  const specialistSubjectNames = Array.from(subjectToDiagnosticsMap.entries())
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .filter(([_subject, diagnostics]) => {
      return diagnostics.length >= 2;
    })
    .map(([subject]) => subject);
  // определяем видимости достижения
  const isSpecialistAchievementVisible = specialistSubjectNames.length >= 2 && specialistSubjectNames.length <= 3;

  if (!isAerobaticsAchievementVisible && !isSpecialistAchievementVisible) return null;

  return (
    <Box sx={styles.root}>
      {isAerobaticsAchievementVisible && (
        <AerobaticsAchievement text={getAerobaticsAchieventText(totalLevelOverHighPercent)} />
      )}
      {isSpecialistAchievementVisible && (
        <SpecialistAchievement text={getSpecialistAchievementText(specialistSubjectNames)} />
      )}
    </Box>
  );
};

export default RatingAchievements;
