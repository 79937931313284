import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { noData } from 'images';
import { NeutralColors } from 'portfolio3/styles';

import { IActiveShareLink } from '../../types';
import { formatActiveLinkDate, formatActiveLinkEndDate } from '../../utils';
import LinkCard from '../LinkCard';
import * as styles from './styles';

interface IActiveLinksListProps {
  activeLinks: IActiveShareLink[];
}
const ActiveLinksList: FC<IActiveLinksListProps> = ({ activeLinks }) => {
  return (
    <Box className="active-links-list" sx={styles.root}>
      {activeLinks.map((link) => {
        const { id, url, qrCode, creationDate, startDate, endDate, expireDays } = link;
        const formattedCreationDate = formatActiveLinkDate(creationDate);
        const formattedStartDate = formatActiveLinkDate(startDate);
        const formattedEndDate = formatActiveLinkEndDate(endDate, expireDays);

        return (
          <LinkCard
            key={link.id}
            id={id}
            linkUrl={url}
            linkQrCode={qrCode}
            creationDate={formattedCreationDate}
            startDate={formattedStartDate}
            endDate={formattedEndDate}
          />
        );
      })}

      {activeLinks.length === 0 && (
        <Box sx={styles.noDataContainer}>
          <Box component="img" src={noData} alt="Нет ссылок" sx={{ width: '213px', paddingBlock: '8px' }} />
          <Typography variant="Headings/H6" color={NeutralColors.light_neutrals_600}>
            Вы пока не поделились ни одной ссылкой...
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ActiveLinksList;
