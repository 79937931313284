import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const inputRoot: SxStyles = {
  position: 'relative',

  // скрытие дефолтных стрелок числового инпута
  '& input': {
    appearance: 'textarea',

    '::-webkit-inner-spin-button, ::-webkit-outer-spin-button': {
      display: 'none',
    },
  },
};

export const inputRootWithControls: SxStyles = {
  paddingRight: '32px',
};

export const endAdornmentRoot: SxStyles = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',

  width: '32px',
  height: '100%',
  borderLeft: '1px solid',
  borderColor: NeutralColors.light_neutrals_400,
};

export const endAdornmentButton: SxStyles = {
  display: 'flex',
  justifyContent: 'center',
  minHeight: 0,
  height: '100%',

  cursor: 'pointer',

  svg: {
    width: '16px',
    height: '16px',
    color: NeutralColors.light_neutrals_600,
  },
};
