import { ComponentType, FC, PropsWithChildren, useEffect, useRef } from 'react';

import { Box, useMediaQuery } from '@mui/material';
import { useVisibleNavigation } from 'hooks';
import { MobilePortfolioNavigation } from 'portfolio3/components/common/PortfolioNavigation';
import { StatusSelector } from 'portfolio3/components/proftech';
import { commonTheme } from 'portfolio3/styles/theme';
import { clamp, getScrollableContainerElement } from 'utils';

const WithBottomNavigationContainer: FC<PropsWithChildren> = ({ children }) => {
  const { primaryNavigation, secondaryNavigation } = useVisibleNavigation();

  const navigationBlockRef = useRef<HTMLDivElement | null>(null);
  const isBottomNavigation = useMediaQuery(commonTheme.breakpoints.down('commonSm'));
  const isBottomSpoStatusSelector = useMediaQuery(commonTheme.breakpoints.down('commonLg'));

  useEffect(() => {
    const scrollableContainer = getScrollableContainerElement();
    let prevScrollPosition = 0;

    const handleScroll = () => {
      if (!navigationBlockRef.current) return;
      if (!scrollableContainer) return;

      const currentScrollPosition = document.documentElement.scrollTop;
      const scrollDifference = prevScrollPosition - currentScrollPosition;
      prevScrollPosition = currentScrollPosition;

      // изменяет прозрачность в зависимости от направления скролла
      const currentOpacity = navigationBlockRef.current.style.opacity;
      const nextOpacity = clamp(Number(currentOpacity) + scrollDifference * 0.01, 0, 1);

      const scrollHeightDifference =
        currentScrollPosition + Number(scrollableContainer.clientHeight) - Number(scrollableContainer.scrollHeight);
      const isFullyScrolled = scrollHeightDifference > -40;

      navigationBlockRef.current.style.opacity = String(isFullyScrolled ? 1 : nextOpacity);
      navigationBlockRef.current.style.pointerEvents = nextOpacity <= 0.0 ? 'none' : 'all';
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const spoStatusSelectorBottomElement = (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        zIndex: 1000,
        position: 'sticky',
        bottom: 0,

        paddingRight: '16px',
        paddingBottom: '16px',
      }}
    >
      <StatusSelector isMobile />
    </Box>
  );

  const spoStatusSelectorBottomElementWithNavigation = (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',

        paddingInline: '16px',
        paddingBottom: '16px',
      }}
    >
      <StatusSelector isMobile withDrawerMenu />
    </Box>
  );

  return (
    <>
      {children}
      {isBottomSpoStatusSelector && !isBottomNavigation && spoStatusSelectorBottomElement}
      {isBottomNavigation && primaryNavigation.length > 0 && (
        <Box
          ref={navigationBlockRef}
          sx={{ zIndex: 1000, position: 'sticky', bottom: 0, transition: 'opacity 0.2s' }}
          className="bottom-navigation-container"
        >
          {spoStatusSelectorBottomElementWithNavigation}
          <MobilePortfolioNavigation primaryNavigation={primaryNavigation} secondaryNavigation={secondaryNavigation} />
        </Box>
      )}
    </>
  );
};

const withBottomNavigation = () => {
  return <P extends PropsWithChildren<unknown>>(WrappedComponent: ComponentType<P>): FC<P> => {
    return function withBottomNavigation({ ...props }) {
      return (
        <WithBottomNavigationContainer>
          <WrappedComponent {...props} />
        </WithBottomNavigationContainer>
      );
    };
  };
};

export default withBottomNavigation;
