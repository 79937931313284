/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useContext, useMemo } from 'react';

import { ActivitySecondaryBlock } from '../basicBlocks';
import { IDictionaryItem } from '../../../../../api/types';
import { IEventData } from 'portfolio3/features/dataEntryForm';
import { getCommonInputControllerFactory } from '../../utils';

import { BaseInputLabel, BaseToggleLabel, FormControl, ToggleFormControl } from 'portfolio3/ui-kit/forms';
import { Input } from 'portfolio3/ui-kit';
import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';
import { FormHelperText, Typography } from '@mui/material';
import Toggle from 'portfolio3/ui-kit/Toggle';

interface IProfessionActivitySecondaryBlockProps {
  eventLevelOptions: IDictionaryItem[];
  organizatorLabel: string;
  organizatorPlaceholder: string;
  eventData: IEventData;
}

const ProfessionActivitySecondaryBlock: React.FC<IProfessionActivitySecondaryBlockProps> = ({
  eventLevelOptions,
  organizatorLabel,
  organizatorPlaceholder,
  eventData,
}) => {
  const { isMobile, inputRenderMode, inputSize, formData, formErrors, onChangeFormData, onChangeFormErrors } = useContext(LocalDataEntryFormContext);

  const createController = getCommonInputControllerFactory(onChangeFormData, onChangeFormErrors);

  const professionNameController = useMemo(() => createController('profession'), [formData.id]);
  const participantCategoryController = useMemo(() => createController('participantCategory', 'participantCategoryError'), [formData.id]);

  const handlePresentaionCompetenceChange = (checked: boolean) => {
    onChangeFormData((prevstate) => ({
      ...prevstate,
      isPresentationCompetence: checked,
    }));
  };

  const additionalTopControllers = (
      <FormControl
        sx={{ width: '100%' }}
        renderMode={inputRenderMode}
        inputSize={inputSize}
        label={<BaseInputLabel>Профессия</BaseInputLabel>}
        control={
          <Input
            renderMode={inputRenderMode}
            size={inputSize}
            placeholder="Введите название профессии"
            value={formData.profession}
            controller={professionNameController}
          />
        }
      />
  );

  const additionalMiddleControllers = (
    <>
      <ToggleFormControl
        withTopMargin={isMobile}
        required
        renderMode={isMobile ? 'inline' : 'column'}
        inputSize={inputSize}
        label={
          <BaseInputLabel overrideRenderMode="fixed" overrideInputSize={inputSize}>
            {isMobile ? 'Ком-ция презентационная' : 'Компетенция презентационная'}
          </BaseInputLabel>
        }
        control={
          <BaseToggleLabel
            label={!isMobile && <Typography sx={{ fontSize: '16px', color: '#7B819B' }}>{formData.isPresentationCompetence ? 'Да' : 'Нет'}</Typography>}
            control={
              <Toggle
                checked={formData.isPresentationCompetence}
                onChange={(_, checked) => handlePresentaionCompetenceChange(checked)}
              />
            }
          />
        }
      />

      <FormControl
        sx={{ width: '100%' }}
        required
        error={formErrors.participantCategoryError}
        renderMode={inputRenderMode}
        inputSize={inputSize}
        label={<BaseInputLabel>Категория участников</BaseInputLabel>}
        helper={formErrors.participantCategoryError && <FormHelperText>Введите категорию участников</FormHelperText>}
        control={
          <Input
            renderMode={inputRenderMode}
            size={inputSize}
            placeholder="Введите категорию"
            value={formData.participantCategory}
            controller={participantCategoryController}
          />
        }
      />
    </>
  );

  return (
    <ActivitySecondaryBlock
      eventLevelOptions={eventLevelOptions}
      additionalTopControllers={additionalTopControllers}
      additionalMiddleControllers={additionalMiddleControllers}
      eventData={eventData}
      organizatorLabel={organizatorLabel}
      organizatorPlaceholder={organizatorPlaceholder}
    />
  );
};

export default ProfessionActivitySecondaryBlock;
