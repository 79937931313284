import { FC, PropsWithChildren } from 'react';

import { Box } from '@mui/material';
import { SxStyles } from 'types';

import BadgeSmall from './BadgeSmall';

const styles: SxStyles = {
  position: 'relative',
};

const badge: SxStyles = {
  zIndex: 1000,
  position: 'absolute',

  right: '-6px',
  bottom: '-6px',

  height: '22px',
};

const FirstMovementAvatarAttachmentSmall: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box className="avatar-attachment" sx={styles}>
      {children}
      <BadgeSmall sx={badge} />
    </Box>
  );
};

export default FirstMovementAvatarAttachmentSmall;
