import { IDictionaryItem } from 'api/types';

import { SortTypeCodes } from './sortType';

const commonSortOptions: IDictionaryItem[] = [
  {
    code: SortTypeCodes.default,
    value: 'По-умолчанию',
  },
  {
    code: SortTypeCodes.requiredBudgetBallAsc,
    value: 'По проходному баллу на бюджет (по возрастанию)',
  },
  {
    code: SortTypeCodes.requiredBudgetBallDesc,
    value: 'По проходному баллу на бюджет (по убыванию)',
  },
  {
    code: SortTypeCodes.requiredPaidBallAsc,
    value: 'По проходному баллу на платную форму (по возрастанию)',
  },
  {
    code: SortTypeCodes.requiredPaidBallDesc,
    value: 'По проходному баллу на платную форму (по убыванию)',
  },
];

const ballDifferenceSortOptions: IDictionaryItem[] = [
  {
    code: SortTypeCodes.differenceBetweenRequiredBudgetBallAndBallAsc,
    value: 'По разнице между проходным баллом на бюджет и набранным баллом (по возрастанию)',
  },
  {
    code: SortTypeCodes.differenceBetweenRequiredBudgetBallAndBallDesc,
    value: 'По разнице между проходным баллом на бюджет и набранным баллом (по убыванию)',
  },
];

const vuzNameSortOptions: IDictionaryItem[] = [
  {
    code: SortTypeCodes.entityNameAToZ,
    value: 'Вузы А -> Я',
  },
  {
    code: SortTypeCodes.entityNameZToA,
    value: 'Вузы Я -> А',
  },
];

export const vuzListSortOptions = [...commonSortOptions, ...vuzNameSortOptions];
export const vuzListSortOptionsWithBallDifference = [
  ...commonSortOptions,
  ...ballDifferenceSortOptions,
  ...vuzNameSortOptions,
];

export const drawerSpecialitiesSortOptions: IDictionaryItem[] = [
  ...commonSortOptions,
  {
    code: SortTypeCodes.entityNameAToZ,
    value: 'Специальности А -> Я',
  },
  {
    code: SortTypeCodes.entityNameZToA,
    value: 'Специальности Я -> А',
  },
];

export const drawerFacultiesSortOptions: IDictionaryItem[] = [
  {
    code: SortTypeCodes.entityNameAToZ,
    value: 'Факультеты А -> Я',
  },
  {
    code: SortTypeCodes.entityNameZToA,
    value: 'Факультеты Я -> А',
  },
];

export const drawerFacultiesFullSortOptions: IDictionaryItem[] = [...commonSortOptions, ...drawerFacultiesSortOptions];
