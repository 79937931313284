import { FC, PropsWithChildren } from 'react';

import { Stack, Typography } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';
import { mergeSx } from 'utils';

interface IDiagramCardProps {
  title: string;
  sx?: SxStyles;
}

const getStyles: SxStyles = (theme) => ({
  p: 2.5,
  border: 1,
  borderRadius: 1.5,
  borderColor: NeutralColors.light_neutrals_300,
  boxShadow: '0px 1px 2px rgba(41, 41, 64, 0.07)',
  backgroundColor: NeutralColors.light_neutrals_0,
  width: '100%',

  [theme.breakpoints.down('commonSm')]: {
    p: 1.5,
  },
});

const DiagramCard: FC<IDiagramCardProps & PropsWithChildren> = ({ title, sx, children }) => {
  return (
    <Stack sx={mergeSx(getStyles, sx)}>
      <Typography variant="Paragraph LG/Semi Bold">{title}</Typography>
      {children}
    </Stack>
  );
};

export default DiagramCard;
