import { SxProps } from '@mui/material';
import { numberToPixelsString } from 'utils';

export const backgroundPatternStyles = (pattern: string, gradient: string, layoutShift: number): SxProps => ({
  '& .pattern-background__pattern': {
    zIndex: -2,
    position: 'fixed',
    width: '100vw',
    height: '100vh',

    background: `${gradient}, url(${pattern}), rgba(100%, 100%, 100%, 0.2)`,
    backgroundBlendMode: 'overlay',
    backgroundRepeat: 'repeat',
  },

  '& .pattern-background__overlay': {
    zIndex: -1,
    position: 'fixed',
    width: '1280px',
    height: '100vh',

    left: '50%',
    transform: `translateX(calc(-50% - ${numberToPixelsString(layoutShift / 2)}))`,

    backgroundColor: 'rgba(255, 255, 255, 0.24)',
    backdropFilter: 'blur(15px)',
    isolation: 'isolate',
  },
});
