import { FC } from 'react';

import { FormControl as MaterialFormControl, FormControlProps, styled } from '@mui/material';

import { InputRenderMode } from '../../utils';

interface IFormControlProps extends FormControlProps {
  renderMode?: InputRenderMode;
}

const StyledFormControl = styled(MaterialFormControl)(({ renderMode = 'desktop' }: IFormControlProps) => {
  const isMobile = renderMode === 'mobile';
  const controlTopMargin = isMobile ? '10px' : 0;

  return {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',

    '& .MuiInputBase-formControl': {
      marginTop: controlTopMargin,
    },
  };
});

const FormControl: FC<IFormControlProps> = ({ children, ...props }) => {
  return <StyledFormControl {...props}>{children}</StyledFormControl>;
};

export default FormControl;
