import {
  GET_AVATAR_TYPE_REF,
  GET_DOCUMENT_REF,
  GET_EMPLOYMENT_DOC_TYPE_REF,
  GET_LEVEL_BUSINESS_REF,
  GET_META_SKILL_REF,
  GET_OLYMPIAD_SUBJECTS_REF,
  GET_PROFESSION_RANK_REF,
  GET_PROFESSIONAL_PROGRAMM_REF,
  GET_SALARY_RANGE_REF,
  GET_SECTION_REF,
  GET_SPO_GIA_MARK_REF,
  GET_SPO_ORGANIZATION_REF,
  GET_SPORT_AGES_REF,
} from 'actions';
import { FAILURE, REQUEST, SUCCESS } from 'actions/utils';
import {
  DocumentRefItem,
  IBaseRefItem,
  ICommonResponse,
  MetaSkillRefItem,
  ProfessionProgrammRefItem,
  SectionRefItem,
  SportAgeRefItem,
} from 'api/types';
import { IReducers } from 'reducers';
import genericReducers from 'reducers/genericReducer';
import { connectReducers } from 'utils';

type RefState<T> = ICommonResponse<T>;

const createRefReducer = (actions: Record<string, string>) => {
  const initialState: RefState<unknown> = {
    content: [],
    loading: false,
  };

  const reducer: IReducers<RefState<unknown>> = {
    [actions[REQUEST]]: genericReducers().get.request,
    [actions[SUCCESS]]: genericReducers().get.success,
    [actions[FAILURE]]: genericReducers().get.failure,
  };

  return connectReducers(initialState, reducer);
};

export type SectionRefState = RefState<SectionRefItem>;
export type DocumentRefState = RefState<DocumentRefItem>;
export type MetaSkillRefState = RefState<MetaSkillRefItem>;
export type ProfessionProgrammRefState = RefState<ProfessionProgrammRefItem>;
export type LevelBusinessRefState = RefState<IBaseRefItem>;
export type SalaryRangeRefState = RefState<IBaseRefItem>;
export type EmploymentDocTypeRefState = RefState<IBaseRefItem>;
export type SpoOrganizationRefState = RefState<IBaseRefItem>;
export type SpoGiaMarkRefState = RefState<IBaseRefItem>;
export type ProfessionRankRefState = RefState<IBaseRefItem>;
export type SportAgesRefState = RefState<SportAgeRefItem>;
export type OlympiadSubjectsRefState = RefState<IBaseRefItem>;
export type AvatarTypeRefState = RefState<IBaseRefItem>;

export const sectionRef = createRefReducer(GET_SECTION_REF);
export const documentRef = createRefReducer(GET_DOCUMENT_REF);
export const metaSkillRef = createRefReducer(GET_META_SKILL_REF);
export const professionProgrammRef = createRefReducer(GET_PROFESSIONAL_PROGRAMM_REF);
export const levelBusinessRef = createRefReducer(GET_LEVEL_BUSINESS_REF);
export const salaryRangeRef = createRefReducer(GET_SALARY_RANGE_REF);
export const employmentDocTypeRef = createRefReducer(GET_EMPLOYMENT_DOC_TYPE_REF);
export const spoOrganizationRef = createRefReducer(GET_SPO_ORGANIZATION_REF);
export const spoGiaMarkRef = createRefReducer(GET_SPO_GIA_MARK_REF);
export const professionRankRef = createRefReducer(GET_PROFESSION_RANK_REF);
export const sportAgesRef = createRefReducer(GET_SPORT_AGES_REF);
export const olympiadSubjectsRef = createRefReducer(GET_OLYMPIAD_SUBJECTS_REF);
export const avatarTypeRef = createRefReducer(GET_AVATAR_TYPE_REF);
