/* eslint-disable prettier/prettier */
/* eslint-disable */

// @ts-nocheck
import { IAdminSearchSetting, IAdminVisibilitySetting, IVisibilityChildSetting } from '../../../containers/admin/VisibilitySettings/types';

export const getSettingActivity = (checkedSetting: IAdminVisibilitySetting | IVisibilityChildSetting | IAdminSearchSetting) => {
  if (!checkedSetting.isActive) return 'Неактивен';
  const isSomeChildSettingDisabled = checkedSetting.children && (checkedSetting.children.some((childSetting) => childSetting.isActive === false
    || childSetting.children?.some((secondaryChildSetting) => secondaryChildSetting.isActive === false)));
  return isSomeChildSettingDisabled ? 'Частично' : 'Активен';
};
