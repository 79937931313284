import { FC } from 'react';
import { AriaButtonProps } from 'react-aria';

import { Box, Typography } from '@mui/material';
import { IconChevronLargeLeft, IconChevronLargeRight } from 'icons';
import { SxStyles } from 'types';

import { NavigationButton } from '../buttons';

const styles: SxStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  maxWidth: '260px',
};

interface ICalendarHeaderProps {
  title: string;
  prevButtonProps: AriaButtonProps<'button'>;
  nextButtonProps: AriaButtonProps<'button'>;
}

const CalendarHeader: FC<ICalendarHeaderProps> = ({ title, nextButtonProps, prevButtonProps }) => {
  return (
    <Box className="calendar-header" sx={styles}>
      <NavigationButton icon={<IconChevronLargeLeft />} ariaOptions={prevButtonProps} />
      <Typography variant="Paragraph MD/Semi Bold">{title}</Typography>
      <NavigationButton icon={<IconChevronLargeRight />} ariaOptions={nextButtonProps} />
    </Box>
  );
};

export default CalendarHeader;
