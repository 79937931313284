import { FC, MouseEvent } from 'react';

import { Box, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { IconClose } from 'icons/edit';
import { getAccentColor, NeutralColors } from 'portfolio3/styles';
import { commonTheme } from 'portfolio3/styles/theme';
import { SxStyles } from 'types';
import { trimString } from 'utils';

import { getButtonStyles } from '../utils';
import BaseBadge from './BaseBadge';

const badgeWrapperStyles: SxStyles = {
  ...getButtonStyles(),

  cursor: 'pointer',
  width: 'fit-content',
};

const resetIconStyles: SxStyles = {
  width: '16px',
  height: '16px',
  path: { fill: NeutralColors.light_neutrals_0 },
};

const getExtraStatuses = (values: string[], maxLength: number, minElements: number) => {
  const formattedValues: string[] = [];

  for (let i = 0; i < values.length; i++) {
    const currentCount = formattedValues.length;

    formattedValues.push(values[i]);
    const joinedString = formattedValues.join(', ');

    if (joinedString.length > maxLength && currentCount >= minElements) {
      formattedValues.pop();
      formattedValues.push(`+${values.length - formattedValues.length}`);
      break;
    }
  }

  const formattedTrimmedValues = formattedValues.map((value) => trimString(value, maxLength));

  return formattedTrimmedValues.join(', ');
};

enum StatusesMaxLength {
  medium = 32,
  small = 24,
}

interface IStatusBadgeProps {
  values: string[];
  minValues: number;
  badgeName: string;
  onReset: () => void;
  onClick: () => void;
  disabled?: boolean;
}

const StatusBadge: FC<IStatusBadgeProps> = ({ values, minValues, badgeName, onReset, onClick, disabled }) => {
  const isUpperMdScreen = useMediaQuery(commonTheme.breakpoints.up('commonMd'));
  const { palette } = useTheme();

  const isEmptyValues = !values.length;
  const accentColor = getAccentColor(palette.accentColor, disabled ? '80' : '100');

  const handleReset = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onReset();
  };

  return (
    <Box component="button" onClick={onClick} sx={badgeWrapperStyles} disabled={disabled}>
      <BaseBadge
        sx={{ ...(isEmptyValues && { border: `1px solid ${accentColor}` }) }}
        backgroundColor={isEmptyValues ? 'transparent' : accentColor}
        textColor={isEmptyValues ? accentColor : NeutralColors.light_neutrals_0}
      >
        {isEmptyValues ? (
          <>{badgeName}</>
        ) : (
          <>
            {getExtraStatuses(values, isUpperMdScreen ? StatusesMaxLength.medium : StatusesMaxLength.small, minValues)}
            <IconButton onClick={handleReset} sx={resetIconStyles} disabled={disabled}>
              <IconClose />
            </IconButton>
          </>
        )}
      </BaseBadge>
    </Box>
  );
};

export default StatusBadge;
