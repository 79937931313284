import { SxStyles } from 'types';

export const root: SxStyles = {
  display: 'flex',
  width: '216px',
  padding: '0 12px 12px 16px',
  height: 'max-content',

  borderRadius: '0 0 12px 12px',
  backgroundColor: 'rgba(255, 243, 191, 0.90)',
};

export const description: SxStyles = {
  flexGrow: 1,

  display: 'flex',
  flexDirection: 'column',
  gap: '8px',

  paddingTop: '12px',
};

export const image: SxStyles = {
  width: '57px',
  height: '68px',
};
