import { SxStyles } from 'types';

export const horizontalLayout: SxStyles = {
  display: 'flex',
  gap: '8px',

  padding: '8px',
};

export const verticalLayout: SxStyles = {
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',

  padding: '8px',
};
