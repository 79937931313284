import { SxStyles } from 'types';

export const root: SxStyles = (theme) => {
  return {
    marginTop: '20px',

    '&:empty': {
      padding: 0,
    },

    [theme.breakpoints.down('commonSm')]: {
      marginTop: 0,
      marginBottom: '8px',
      padding: '16px 12px',
      backgroundColor: 'white',
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
      borderRadius: '12px',
    },
  };
};
