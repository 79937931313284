import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const Concert: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="230" height="129" viewBox="0 0 230 129" xmlns="http://www.w3.org/2000/svg" {...props} fill="none">
    {/*eslint-disable max-len */}
    <path
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".75"
      d="m124.08 61.71 15.81-39.05M39.45 60.32l-10.23-1.86s1.86-10.23 7.44-10.23 6.51 4.65 6.51 4.65h-3.72v7.44ZM32.46 60.32l-1 .36a7.15 7.15 0 0 0-4.65 5.84l-1 8.3a3.19 3.19 0 0 0 1.73 3.24l11 5.5 1.86-2.79-8.6-4.69a2.8 2.8 0 0 1-1.45-2.73l.75-7.46M53.39 52.88 44.1 80.77"
    />
    <path
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".75"
      d="M30.18 79.39s-4.68 8.23-1 11.31c3.68 3.08 16.73 4 16.73 4s-5.57 26-4.64 26h19.95L72 118.89l-7.44-14-2.22-8.12A4 4 0 0 0 59.7 94l-.7-.21-.28-1.12a4.999 4.999 0 0 0-3.17-3.5L45 85.42M25.5 94.72h15.81M37.59 113.31v-10.23M79.92 64c4.65 12.08 9.29 10.22 9.29 10.22l.93 2.79 3.26 2.34-3.62 2.35H76.2s-1.86-14-.93-20.45c.93-6.45 7.44-8.37 7.44-8.37l5.79-6.62a2.38 2.38 0 0 1 1.8-.82h2.44A3.94 3.94 0 0 1 96 47.23l1.61 2.46a2.77 2.77 0 0 1 .15 2.74l-1.15 2.31H94l.51-4.88-6.24 1.65s.9-1.42-1-1.42c0 0-1.19.89-.6 1.84.59.95-2.19 2.81-2.19 2.81"
    />
    <path
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".75"
      d="m78 81.7.4 1.68a4.249 4.249 0 0 0 3.34 3.19l3.81.71h9.69a7.79 7.79 0 0 1 4.12 1.17l.56.34a5.648 5.648 0 0 1 2.59 4.21l1.49 15.2 2-.43 4.59-1L108 86.62a4.733 4.733 0 0 0-3.36-3.93l-11.24-3.3M106 113.31l3.72-.93-.12-1.31 4.77.38 5.58-1.86-2.65-.88a1.423 1.423 0 0 0-.29-.06l-5.32-.45-2.76-1-2.87.6-.06 5.51ZM60.83 112.38l-1.86-17.66M42.24 52.88l-.93 1.86.93 2.79h-.93s0 2.79-1.86 2.79M56.18 65.89s-.93 1.86-8.37-.93c0 0-1-.92-3.27 0s-6 0-6 0-6.18-2.78-.77-4.64"
    />
    <path
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".75"
      d="m43.16 67.75 1.07 1.86 3.58 2.79 1.86.93 7.44-4.65 1.86-2.79-.75-1.86M43 70.23l-1.67 4 1.67-4ZM41.31 67.75l1.67 2.48M61.3 66.82l2.88.89s-1.49 1 .37 1.9c1.86.9 3.78-2.5 1.89-3.11-1.89-.61-5.07-1.39-5.07-1.39"
    />
    <path
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".75"
      d="M41.31 59.23a12.35 12.35 0 0 1 5.57 2c2.79 1.86 3.29-.93 6.76 0 3.47.93 3.47 2.77 3.47 2.77h3.72l.93 1.85-3.24 3.72L51.05 76a4.3 4.3 0 0 1-4.94.46l-4.8-2.22 1.86 6.51"
    />
    <path
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".75"
      d="M50.61 67.44s3.71 1.29 5.57.34M58.97 65.89l-7.44-1.85M47.81 63.11l-7.43-.93M46.6 66.5s-1.12 1.63-7.36.32c-4.44-.93-3.71-4.45-3.71-4.45M40.38 80.77h4.36l.28 1.86v2.31l-.93.2-.73-1.58-3.91-.6M86.76 54.27s-.35 4 1.52 4.1L92 70.54l10.23 2.79-.93 3.72-12.09-2.79"
    />
    <path
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".75"
      d="M94 54.74s-2 3.55-5.72 3.63M80.85 60.32s0-3.72 2.79-3.72a7.12 7.12 0 0 1 4.65 1.86M77.13 91.93h19.52M84.57 96.58H92l.21 16.73M102.23 73.33H106l1.58 3.72H106l-1.74-1.86-2.62.71M117.11 75.19l-15.81-4.65M118.04 67.75l-23.25-7.43M110.6 80.77l-9.3-3.72M118.97 64.96l-14.88-4.64M115.7 83v-4.9s3.27-1 4.2-3.84c.93-2.84-1.63-4.65-1.63-4.65l-.37-4.5L148.61 64v16l-32.91 3ZM148.61 80v2.37l-7.53.8c-8.48.9-21 2.21-20.75 2 0 0 5.45.41 5.76 4.07.31 3.66 1.24 19.45 1.24 19.45l1.43 1.58 4.15-.65V89.14s3.72-4.65 8.37-5.58M148.61 78.41l4.76-.43s.93-.93 7.06-3.72 20.06-2.32 20.06-2.32l24-3.26V56.55l-24.07 2.68s-20.77-1.1-27.14 4.18l-4.63.63M153.37 63.11v14.87M106.88 59.39l14 4.65s1.25-2.79.93-4.65c-.32-1.86 6.34-4.27 3.72-5.58C121.76 52 114 51 114 51a2.593 2.593 0 0 0-3.36 1.89c-.97 2.78-.04 3.71-3.76 6.5ZM117.11 70.54l-21.39-6.5M126.4 55.67l55.79-1.86s20.34-1 22.26 2.74M187 53.5s30.52-18.29-2-18.29c-32.52 0-32.54-5.58-32.54-5.58l13-18-22.32-3.38-26.2 42.64"
    />
    <path
      stroke={props.fill || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".75"
      d="M188.7 71.47s2 0 2.38 3.72c.38 3.72 1.34 14.88 1.34 14.88l3.72.93.49-15.81s.25-1.53 4-2.94a5.907 5.907 0 0 0 2.89-3.25M107.81 57.53l-13.02.93M139.42 24.06l5.58 34.4.93 3.72L146 64M141.82 64.03l.39-4.64-3.72-32.55M127.33 109.59a3.434 3.434 0 0 0-1.85 2.79 2.528 2.528 0 0 0 .708 1.841A2.53 2.53 0 0 0 128 115M193 96.5a3.13 3.13 0 0 1-2.33-3.2 2.6 2.6 0 0 1 .718-1.996 2.601 2.601 0 0 1 1.962-.804M114.5 86.25l1 17M112.5 102.25l-1-19M5.5 84.25l20-2M51.5 80.25l22.08-.5M224.5 78.25h-26M188.5 78.25h-30"
    />
  </SvgIcon>
);

export default Concert;
