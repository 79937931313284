import {
  CommonReferenceActionsObjectType,
  getAvatarTypeRefActions,
  getDocumentRefActions,
  getEmploymentDocTypeRefActions,
  getLevelBusinessRefActions,
  getMetaSkillRefActions,
  getOlympiadSubjectsRefActions,
  getProfessionProgrammRefActions,
  getProfessionRankRefActions,
  getSalaryRangeRefActions,
  getSectionRefActions,
  getSpoGiaMarkRefActions,
  getSpoOrganizationRefActions,
  getSportAgesRefActions,
} from 'actions';
import {
  getAvatarTypeRef,
  getDocumentRef,
  getEmploymentDocTypeRef,
  getLevelBusinessRef,
  getMetaSkillRef,
  getOlympiadSubjectsRef,
  getProfessionProgramRef,
  getProfessionRankRef,
  getSalaryRangeRef,
  getSectionRef,
  getSpoGiaMarkRef,
  getSpoOrganizationRef,
  getSportAgesRef,
  IQueryParams,
} from 'api';
import { ApiResult } from 'api/impl';
import { call, put } from 'redux-saga/effects';
import { GenericGetAction } from 'sagas';

const getCommonSagaFunction = (
  actionsObject: CommonReferenceActionsObjectType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  apiMethod: (queryParams?: IQueryParams) => Promise<ApiResult<any, any>>,
) => {
  return function* ({ payload }: GenericGetAction<Parameters<typeof actionsObject.request>>) {
    const queryParams = payload?.[0] || undefined;

    const { response, error } = yield call(apiMethod, queryParams);

    if (response) {
      yield put(actionsObject.success(response));
    } else {
      yield put(actionsObject.failure(error));
    }
  };
};

export const getSectionRefSaga = getCommonSagaFunction(getSectionRefActions, getSectionRef);
export const getDocumentRefSaga = getCommonSagaFunction(getDocumentRefActions, getDocumentRef);
export const getMetaSkillRefSaga = getCommonSagaFunction(getMetaSkillRefActions, getMetaSkillRef);
export const getProfessionProgramRefSaga = getCommonSagaFunction(
  getProfessionProgrammRefActions,
  getProfessionProgramRef,
);
export const getLevelBusinessRefSaga = getCommonSagaFunction(getLevelBusinessRefActions, getLevelBusinessRef);
export const getSalaryRangeRefSaga = getCommonSagaFunction(getSalaryRangeRefActions, getSalaryRangeRef);
export const getEmploymentDocTypeRefSaga = getCommonSagaFunction(
  getEmploymentDocTypeRefActions,
  getEmploymentDocTypeRef,
);
export const getSpoOrganizationRefSaga = getCommonSagaFunction(getSpoOrganizationRefActions, getSpoOrganizationRef);
export const getSpoGiaMarkRefSaga = getCommonSagaFunction(getSpoGiaMarkRefActions, getSpoGiaMarkRef);
export const getProfessionRankRefSaga = getCommonSagaFunction(getProfessionRankRefActions, getProfessionRankRef);

export const getSportAgesRefSaga = getCommonSagaFunction(getSportAgesRefActions, getSportAgesRef);
export const getOlympiadSubjectsRefSaga = getCommonSagaFunction(getOlympiadSubjectsRefActions, getOlympiadSubjectsRef);

export const getAvatarTypeRefSaga = getCommonSagaFunction(getAvatarTypeRefActions, getAvatarTypeRef);
