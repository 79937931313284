import { ISectionsVisibility, IShareLink } from 'api/types';

export type DynamicDisplaySettings = Partial<Record<keyof ISectionsVisibility, boolean>>;

export interface ILinkSetting {
  name: string;
  sectionCode?: number;
  isActive: boolean;
  isIndeterminate: boolean;
  toggle: () => void;
  childSettings?: ILinkSetting[];
}

export enum LinkSharingTabs {
  LINK = 1,
  FILE,
}

export interface IActiveShareLink extends IShareLink {
  expireDays: number;
}
