import { FC } from 'react';

import { placeholderGeometry } from 'images';

import CommonPlaceholder from './CommonPlaceholder';

const NotFoundPlaceholder: FC = () => {
  return (
    <CommonPlaceholder
      image={placeholderGeometry}
      alt="Не найдено"
      description="По данному запросу ничего не найдено"
    />
  );
};

export default NotFoundPlaceholder;
