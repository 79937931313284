import { FC, useMemo } from 'react';

import { useAppSelector, useDataLoad } from 'hooks';
import PdfNoDataText from 'portfolio3/features/pdf/components/PdfNoDataText';
import { getDataLoadRequests } from 'utils';

import { PdfCommonDataEntity } from '../../../../components/pdfStudent/DataEntity';
import { widgetPdfIcons } from '../../../../icons';
import { professionEventsSelector } from '../../../../selectors';
import PdfCommonDataEntityContainer from '../common';

import '../index.scss';

const PdfProfessionEventsContainer: FC = () => {
  const events = useAppSelector(professionEventsSelector);
  useDataLoad({ shouldLoad: true, requests: getDataLoadRequests().professionEvents });

  const hasData = events.content.length > 0;

  const filteredYear = useMemo(
    () =>
      events.content.length > 0
        ? events.content?.reduce((prevItem, nextItem) => (prevItem.year > nextItem.year ? prevItem : nextItem)).year
        : undefined,
    [events],
  );

  return (
    <PdfCommonDataEntityContainer title="Мероприятия" icon={widgetPdfIcons.pdfIconProfessionEvents}>
      {hasData &&
        events.content.map((entity) => (
          <PdfCommonDataEntity key={entity.id || entity.recordId} entity={entity} year={filteredYear} />
        ))}
      {!hasData && <PdfNoDataText />}
    </PdfCommonDataEntityContainer>
  );
};

export default PdfProfessionEventsContainer;
