/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';

const Dog: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="3.96875" y="5" width="24" height="22" rx="4" fill="url(#paint0_linear_297_287327)" />
    <rect x="3.96875" y="5" width="24" height="22" rx="4" fill="url(#paint1_linear_297_287327)" />
    <rect x="3.96875" y="5" width="24" height="22" rx="4" fill="url(#paint2_linear_297_287327)" />
    <rect x="3.96875" y="5" width="24" height="22" rx="4" fill="url(#paint3_linear_297_287327)" />
    <rect x="3.96875" y="5" width="24" height="22" rx="4" fill="url(#paint4_linear_297_287327)" />
    <rect x="3.96875" y="5" width="24" height="22" rx="4" fill="url(#paint5_radial_297_287327)" />
    <rect x="3.96875" y="5" width="24" height="22" rx="4" fill="url(#paint6_radial_297_287327)" />
    <rect x="3.96875" y="5" width="24" height="22" rx="4" fill="url(#paint7_radial_297_287327)" />
    <rect x="3.96875" y="5" width="24" height="22" rx="4" fill="url(#paint8_radial_297_287327)" />
    <rect x="3.96875" y="5" width="24" height="22" rx="4" fill="url(#paint9_linear_297_287327)" />
    <g filter="url(#filter0_f_297_287327)">
      <path
        d="M24.6875 10.3184H26.9062V22.3652C26.9062 22.9779 26.4096 23.4746 25.7969 23.4746C25.1842 23.4746 24.6875 22.9779 24.6875 22.3652V10.3184Z"
        fill="url(#paint10_linear_297_287327)"
        fillOpacity="0.75"
      />
    </g>
    <g filter="url(#filter1_f_297_287327)">
      <path
        d="M26.2188 21.4746L19.4722 7.5152C19.0537 6.64938 19.7288 5.65508 20.6879 5.72453L23.0257 5.89382C25.5354 6.07556 27.4084 8.28 27.1825 10.7862L26.2188 21.4746Z"
        fill="#D89475"
      />
    </g>
    <path
      d="M24.0312 21.8438C24.0312 17.3688 19.7188 15.9688 16.0312 15.9688C11.9062 15.9688 7.96875 17.5312 7.96875 21.8438C7.96875 25.7937 12.2812 29.1875 16.0312 29.1875C20.5312 29.1875 24.0312 24.5312 24.0312 21.8438Z"
      fill="url(#paint11_radial_297_287327)"
    />
    <path
      d="M24.0312 21.8438C24.0312 17.3688 19.7188 15.9688 16.0312 15.9688C11.9062 15.9688 7.96875 17.5312 7.96875 21.8438C7.96875 25.7937 12.2812 29.1875 16.0312 29.1875C20.5312 29.1875 24.0312 24.5312 24.0312 21.8438Z"
      fill="url(#paint12_radial_297_287327)"
    />
    <path
      d="M24.0312 21.8438C24.0312 17.3688 19.7188 15.9688 16.0312 15.9688C11.9062 15.9688 7.96875 17.5312 7.96875 21.8438C7.96875 25.7937 12.2812 29.1875 16.0312 29.1875C20.5312 29.1875 24.0312 24.5312 24.0312 21.8438Z"
      fill="url(#paint13_radial_297_287327)"
    />
    <g filter="url(#filter2_i_297_287327)">
      <path
        d="M12.9609 24.6562L15.9688 22.4062L18.9766 24.6562V27C18.9766 28.6569 17.6256 30 15.9688 30C14.3119 30 12.9609 28.6569 12.9609 27V24.6562Z"
        fill="url(#paint14_linear_297_287327)"
      />
    </g>
    <g filter="url(#filter3_f_297_287327)">
      <path
        d="M15.5938 22.4062V29.8281C18.1607 29.7031 18.8125 27.5625 18.8125 26.7344V24.4688C16.922 24.0938 15.879 22.9375 15.5938 22.4062Z"
        fill="url(#paint15_linear_297_287327)"
      />
    </g>
    <g filter="url(#filter4_f_297_287327)">
      <path
        d="M16.6231 22.8957L15.9688 22.4062L15.3003 22.9063C14.906 23.889 14.0841 24.0733 13.3633 24.2211V25.1104C14.3227 25.0178 15.384 24.6578 15.9638 23.5218C16.5457 24.6617 17.6122 25.0208 18.5742 25.1114V24.2231C17.8485 24.0777 17.0175 23.8897 16.6231 22.8957Z"
        fill="#BD1836"
      />
    </g>
    <path
      d="M12.5 24.4844C13.9531 24.4844 15.9688 23.2031 15.9688 20.5156"
      stroke="url(#paint16_radial_297_287327)"
      strokeLinecap="round"
    />
    <path
      d="M12.5 24.4844C13.9531 24.4844 15.9688 23.2031 15.9688 20.5156"
      stroke="url(#paint17_linear_297_287327)"
      strokeLinecap="round"
    />
    <path
      d="M19.4263 24.4844C17.9731 24.4844 15.9575 23.2031 15.9575 20.5156"
      stroke="url(#paint18_radial_297_287327)"
      strokeLinecap="round"
    />
    <path
      d="M19.4263 24.4844C17.9731 24.4844 15.9575 23.2031 15.9575 20.5156"
      stroke="url(#paint19_linear_297_287327)"
      strokeLinecap="round"
    />
    <path
      d="M16.4746 20.5156C16.4746 20.2395 16.2508 20.0156 15.9746 20.0156L15.9689 20.0157L15.9632 20.0156C15.6871 20.0156 15.4632 20.2395 15.4632 20.5156C15.4632 20.598 15.4649 20.6793 15.4683 20.7594C15.3526 22.98 13.6622 23.9844 12.5059 23.9844C12.2297 23.9844 12.0059 24.2082 12.0059 24.4844C12.0059 24.7605 12.2297 24.9844 12.5059 24.9844C13.7167 24.9844 15.2048 24.2385 15.9689 22.7417C16.733 24.2385 18.2211 24.9844 19.432 24.9844C19.7081 24.9844 19.932 24.7605 19.932 24.4844C19.932 24.2082 19.7081 23.9844 19.432 23.9844C18.2757 23.9844 16.5852 22.98 16.4695 20.7594C16.4729 20.6793 16.4746 20.598 16.4746 20.5156Z"
      fill="url(#paint20_radial_297_287327)"
    />
    <path
      d="M4.39467 16.3617L11.6947 6.06679C12.2816 5.23902 11.6898 4.09375 10.675 4.09375H6.625C4.13972 4.09375 2.125 6.10847 2.125 8.59375V15.6387C2.125 16.8569 3.68999 17.3555 4.39467 16.3617Z"
      fill="url(#paint21_linear_297_287327)"
    />
    <path
      d="M4.39467 16.3617L11.6947 6.06679C12.2816 5.23902 11.6898 4.09375 10.675 4.09375H6.625C4.13972 4.09375 2.125 6.10847 2.125 8.59375V15.6387C2.125 16.8569 3.68999 17.3555 4.39467 16.3617Z"
      fill="url(#paint22_linear_297_287327)"
    />
    <path
      d="M4.39467 16.3617L11.6947 6.06679C12.2816 5.23902 11.6898 4.09375 10.675 4.09375H6.625C4.13972 4.09375 2.125 6.10847 2.125 8.59375V15.6387C2.125 16.8569 3.68999 17.3555 4.39467 16.3617Z"
      fill="url(#paint23_linear_297_287327)"
    />
    <path
      d="M4.39467 16.3617L11.6947 6.06679C12.2816 5.23902 11.6898 4.09375 10.675 4.09375H6.625C4.13972 4.09375 2.125 6.10847 2.125 8.59375V15.6387C2.125 16.8569 3.68999 17.3555 4.39467 16.3617Z"
      fill="url(#paint24_linear_297_287327)"
    />
    <path
      d="M4.39467 16.3617L11.6947 6.06679C12.2816 5.23902 11.6898 4.09375 10.675 4.09375H6.625C4.13972 4.09375 2.125 6.10847 2.125 8.59375V15.6387C2.125 16.8569 3.68999 17.3555 4.39467 16.3617Z"
      fill="url(#paint25_radial_297_287327)"
    />
    <path
      d="M27.5116 16.3617L20.2116 6.06679C19.6246 5.23902 20.2165 4.09375 21.2312 4.09375H25.2812C27.7665 4.09375 29.7812 6.10847 29.7812 8.59375V15.6387C29.7812 16.8569 28.2163 17.3555 27.5116 16.3617Z"
      fill="url(#paint26_linear_297_287327)"
    />
    <path
      d="M27.5116 16.3617L20.2116 6.06679C19.6246 5.23902 20.2165 4.09375 21.2312 4.09375H25.2812C27.7665 4.09375 29.7812 6.10847 29.7812 8.59375V15.6387C29.7812 16.8569 28.2163 17.3555 27.5116 16.3617Z"
      fill="url(#paint27_linear_297_287327)"
    />
    <path
      d="M27.5116 16.3617L20.2116 6.06679C19.6246 5.23902 20.2165 4.09375 21.2312 4.09375H25.2812C27.7665 4.09375 29.7812 6.10847 29.7812 8.59375V15.6387C29.7812 16.8569 28.2163 17.3555 27.5116 16.3617Z"
      fill="url(#paint28_linear_297_287327)"
    />
    <path
      d="M27.5116 16.3617L20.2116 6.06679C19.6246 5.23902 20.2165 4.09375 21.2312 4.09375H25.2812C27.7665 4.09375 29.7812 6.10847 29.7812 8.59375V15.6387C29.7812 16.8569 28.2163 17.3555 27.5116 16.3617Z"
      fill="url(#paint29_radial_297_287327)"
    />
    <g filter="url(#filter5_f_297_287327)">
      <rect x="11.7617" y="12.2285" width="1.47696" height="2.97717" rx="0.738478" fill="#DC9868" />
    </g>
    <rect
      x="12.2266"
      y="12.0098"
      width="1.47696"
      height="2.97717"
      rx="0.738478"
      fill="url(#paint30_linear_297_287327)"
    />
    <rect
      x="12.2266"
      y="12.0098"
      width="1.47696"
      height="2.97717"
      rx="0.738478"
      fill="url(#paint31_linear_297_287327)"
    />
    <g filter="url(#filter6_f_297_287327)">
      <path d="M13.2212 12.4629V14.0561" stroke="#725F62" strokeWidth="0.2" strokeLinecap="round" />
    </g>
    <rect
      x="18.2109"
      y="12.0098"
      width="1.47696"
      height="2.97717"
      rx="0.738478"
      fill="url(#paint32_linear_297_287327)"
    />
    <rect
      x="18.2109"
      y="12.0098"
      width="1.47696"
      height="2.97717"
      rx="0.738478"
      fill="url(#paint33_linear_297_287327)"
    />
    <g filter="url(#filter7_f_297_287327)">
      <path d="M19.2056 12.4629V14.0561" stroke="#725F62" strokeWidth="0.2" strokeLinecap="round" />
    </g>
    <path
      d="M16.9697 16.998H15.063C14.3862 16.998 14.0152 17.7863 14.4465 18.3079L15.1213 19.1238C15.6154 19.7213 16.5379 19.7004 17.0044 19.0812L17.6086 18.2795C18.0058 17.7523 17.6297 16.998 16.9697 16.998Z"
      fill="url(#paint34_linear_297_287327)"
    />
    <path
      d="M16.9697 16.998H15.063C14.3862 16.998 14.0152 17.7863 14.4465 18.3079L15.1213 19.1238C15.6154 19.7213 16.5379 19.7004 17.0044 19.0812L17.6086 18.2795C18.0058 17.7523 17.6297 16.998 16.9697 16.998Z"
      fill="url(#paint35_radial_297_287327)"
    />
    <path
      d="M16.9697 16.998H15.063C14.3862 16.998 14.0152 17.7863 14.4465 18.3079L15.1213 19.1238C15.6154 19.7213 16.5379 19.7004 17.0044 19.0812L17.6086 18.2795C18.0058 17.7523 17.6297 16.998 16.9697 16.998Z"
      fill="url(#paint36_linear_297_287327)"
    />
    <g filter="url(#filter8_f_297_287327)">
      <ellipse
        cx="17.1119"
        cy="17.6464"
        rx="0.215947"
        ry="0.370376"
        transform="rotate(-41.9976 17.1119 17.6464)"
        fill="#786366"
      />
    </g>
    <g filter="url(#filter9_f_297_287327)">
      <path d="M28.875 7.28711V15.5371" stroke="url(#paint37_linear_297_287327)" strokeWidth="0.2" />
    </g>
    <g filter="url(#filter10_f_297_287327)">
      <path d="M11.2812 5.47461L7.70312 10.6309" stroke="url(#paint38_linear_297_287327)" strokeWidth="0.2" />
    </g>
    <defs>
      <filter
        id="filter0_f_297_287327"
        x="22.1875"
        y="7.81836"
        width="7.21875"
        height="18.1562"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="1.25" result="effect1_foregroundBlur_297_287327" />
      </filter>
      <filter
        id="filter1_f_297_287327"
        x="17.3457"
        y="3.7207"
        width="11.8555"
        height="19.7539"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_297_287327" />
      </filter>
      <filter
        id="filter2_i_297_287327"
        x="12.9609"
        y="21.9062"
        width="6.01562"
        height="8.09375"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-0.5" />
        <feGaussianBlur stdDeviation="0.25" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.841667 0 0 0 0 0.164826 0 0 0 0 0.552771 0 0 0 1 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_297_287327" />
      </filter>
      <filter
        id="filter3_f_297_287327"
        x="15.1937"
        y="22.0062"
        width="4.01875"
        height="8.22188"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.2" result="effect1_foregroundBlur_297_287327" />
      </filter>
      <filter
        id="filter4_f_297_287327"
        x="12.8633"
        y="21.9062"
        width="6.21094"
        height="3.70508"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_297_287327" />
      </filter>
      <filter
        id="filter5_f_297_287327"
        x="10.9617"
        y="11.4285"
        width="3.07705"
        height="4.57754"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.4" result="effect1_foregroundBlur_297_287327" />
      </filter>
      <filter
        id="filter6_f_297_287327"
        x="12.8211"
        y="12.0633"
        width="0.800195"
        height="2.39297"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.15" result="effect1_foregroundBlur_297_287327" />
      </filter>
      <filter
        id="filter7_f_297_287327"
        x="18.8055"
        y="12.0633"
        width="0.800195"
        height="2.39297"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.15" result="effect1_foregroundBlur_297_287327" />
      </filter>
      <filter
        id="filter8_f_297_287327"
        x="16.3164"
        y="16.8359"
        width="1.59082"
        height="1.62109"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_297_287327" />
      </filter>
      <filter
        id="filter9_f_297_287327"
        x="28.0249"
        y="6.53711"
        width="1.7002"
        height="9.75"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.375" result="effect1_foregroundBlur_297_287327" />
      </filter>
      <filter
        id="filter10_f_297_287327"
        x="7.12109"
        y="4.91797"
        width="4.74219"
        height="6.26953"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_297_287327" />
      </filter>
      <linearGradient
        id="paint0_linear_297_287327"
        x1="15.9687"
        y1="6.03744"
        x2="15.9687"
        y2="31.4434"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F7C5A1" />
        <stop offset="1" stopColor="#E28454" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_297_287327"
        x1="2.84375"
        y1="27"
        x2="7.65625"
        y2="27"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BD8465" />
        <stop offset="1" stopColor="#BD8465" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_297_287327"
        x1="31.7813"
        y1="27"
        x2="24.1562"
        y2="27"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC390" />
        <stop offset="1" stopColor="#FFC390" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_297_287327"
        x1="15.9688"
        y1="21.2874"
        x2="15.9688"
        y2="31.4749"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#89254F" stopOpacity="0" />
        <stop offset="1" stopColor="#98335E" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_297_287327"
        x1="15.9687"
        y1="26.5374"
        x2="15.9687"
        y2="27.3187"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E273A2" stopOpacity="0" />
        <stop offset="1" stopColor="#E273A2" />
      </linearGradient>
      <radialGradient
        id="paint5_radial_297_287327"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(2.03125 12.1624) rotate(76.0717) scale(8.04916 7.19335)"
      >
        <stop stopColor="#BD8465" />
        <stop offset="1" stopColor="#BD8465" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint6_radial_297_287327"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(15.9687 23.0374) rotate(180) scale(10.8125 8.28742)"
      >
        <stop stopColor="#AD5C30" />
        <stop offset="1" stopColor="#AD5C30" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint7_radial_297_287327"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(14.8437 24.6624) rotate(87.7454) scale(7.94365 6.9459)"
      >
        <stop stopColor="#AD5C30" />
        <stop offset="1" stopColor="#AD5C30" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint8_radial_297_287327"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(12.4687 5) rotate(70.7479) scale(8.1942 18.8165)"
      >
        <stop stopColor="#FFD6B3" />
        <stop offset="1" stopColor="#FFC390" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint9_linear_297_287327"
        x1="15.1706"
        y1="3.73339"
        x2="15.1706"
        y2="6.41501"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE0C6" />
        <stop offset="1" stopColor="#FFC390" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_297_287327"
        x1="27.375"
        y1="21.7224"
        x2="27.7751"
        y2="4.74455"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC390" />
        <stop offset="1" stopColor="#FFC390" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint11_radial_297_287327"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16 18.5374) rotate(90) scale(11.625 24.0312)"
      >
        <stop stopColor="#FFE3B6" />
        <stop offset="0.317708" stopColor="#F5C19B" />
        <stop offset="0.895833" stopColor="#BE625B" />
      </radialGradient>
      <radialGradient
        id="paint12_radial_297_287327"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(18.4687 19.9749) rotate(140.599) scale(13.5883 13.9042)"
      >
        <stop offset="0.595075" stopColor="#BE625B" stopOpacity="0" />
        <stop offset="1" stopColor="#BE625B" />
      </radialGradient>
      <radialGradient
        id="paint13_radial_297_287327"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(23.0937 16.9749) rotate(125.776) scale(5.23845 6.83497)"
      >
        <stop stopColor="#FFE9BE" />
        <stop offset="1" stopColor="#FFE9BE" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint14_linear_297_287327"
        x1="14.2187"
        y1="21.2249"
        x2="14.2187"
        y2="28.4124"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B41D4E" />
        <stop offset="1" stopColor="#C9297C" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_297_287327"
        x1="20.2534"
        y1="29.5937"
        x2="15.5942"
        y2="29.693"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.4375" stopColor="#F26D9E" />
        <stop offset="0.947917" stopColor="#EF6A9A" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint16_radial_297_287327"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(14.2344 22.7562) rotate(48.0128) scale(1.6817 3.82046)"
      >
        <stop stopColor="#261317" />
        <stop offset="0.652642" stopColor="#65484A" />
        <stop offset="1" stopColor="#3C1534" />
      </radialGradient>
      <linearGradient
        id="paint17_linear_297_287327"
        x1="12.5"
        y1="24.2562"
        x2="14.345"
        y2="23.8379"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4A3435" />
        <stop offset="1" stopColor="#473132" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint18_radial_297_287327"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17.8361 22.5921) rotate(132.556) scale(1.81361 4.34755)"
      >
        <stop stopColor="#261317" />
        <stop offset="0.507886" stopColor="#65484A" />
        <stop offset="0.901577" stopColor="#3C1534" />
      </radialGradient>
      <linearGradient
        id="paint19_linear_297_287327"
        x1="16.1564"
        y1="19.5218"
        x2="16.5958"
        y2="21.1244"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#65484A" />
        <stop offset="1" stopColor="#65484A" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint20_radial_297_287327"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(14.7502 21.1781) rotate(133.995) scale(3.77918 1.8994)"
      >
        <stop stopColor="#392930" />
        <stop offset="1" stopColor="#392930" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint21_linear_297_287327"
        x1="7.60937"
        y1="4.09375"
        x2="2.8125"
        y2="15.5999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9F6C5B" />
        <stop offset="1" stopColor="#683C44" />
      </linearGradient>
      <linearGradient
        id="paint22_linear_297_287327"
        x1="0.71875"
        y1="11.3499"
        x2="4.40625"
        y2="11.3499"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5F333B" />
        <stop offset="1" stopColor="#683C44" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint23_linear_297_287327"
        x1="7.96875"
        y1="12.5374"
        x2="6.875"
        y2="11.6937"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.208291" stopColor="#5F333B" />
        <stop offset="1" stopColor="#683C44" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint24_linear_297_287327"
        x1="3.75"
        y1="18.3499"
        x2="5.0625"
        y2="12.6624"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#50212A" />
        <stop offset="1" stopColor="#683C44" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint25_radial_297_287327"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(11.2187 0.974937) rotate(125.218) scale(9.10379 9.64599)"
      >
        <stop stopColor="#BF867C" />
        <stop offset="1" stopColor="#BF867C" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint26_linear_297_287327"
        x1="24.2969"
        y1="4.09375"
        x2="29.0938"
        y2="15.5999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9F6C5B" />
        <stop offset="1" stopColor="#683C44" />
      </linearGradient>
      <linearGradient
        id="paint27_linear_297_287327"
        x1="22.7188"
        y1="13.5374"
        x2="25.9688"
        y2="11.3499"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5F333B" />
        <stop offset="1" stopColor="#683C44" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint28_linear_297_287327"
        x1="28.1563"
        y1="18.3499"
        x2="26.8438"
        y2="12.6624"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#50212A" />
        <stop offset="1" stopColor="#683C44" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint29_radial_297_287327"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(32.9687 10.6624) rotate(-173.904) scale(6.47411 6.85969)"
      >
        <stop stopColor="#BF867C" />
        <stop offset="1" stopColor="#BF867C" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint30_linear_297_287327"
        x1="14.2018"
        y1="13.4984"
        x2="11.9952"
        y2="13.4984"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00558659" stopColor="#5F5254" />
        <stop offset="1" stopColor="#2E2127" />
      </linearGradient>
      <linearGradient
        id="paint31_linear_297_287327"
        x1="11.9952"
        y1="15.0916"
        x2="11.9952"
        y2="14.2543"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00558659" stopColor="#34222C" />
        <stop offset="1" stopColor="#34222C" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint32_linear_297_287327"
        x1="20.1861"
        y1="13.4984"
        x2="17.9796"
        y2="13.4984"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00558659" stopColor="#5F5254" />
        <stop offset="1" stopColor="#2E2127" />
      </linearGradient>
      <linearGradient
        id="paint33_linear_297_287327"
        x1="17.9796"
        y1="15.0916"
        x2="17.9796"
        y2="14.2543"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00558659" stopColor="#34222C" />
        <stop offset="1" stopColor="#34222C" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint34_linear_297_287327"
        x1="17.4063"
        y1="16.7246"
        x2="14.4688"
        y2="19.6621"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.191489" stopColor="#5F5254" />
        <stop offset="1" stopColor="#2E2127" />
      </linearGradient>
      <radialGradient
        id="paint35_radial_297_287327"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16.3438 17.6309) rotate(135.864) scale(2.19891 4.04729)"
      >
        <stop offset="0.462647" stopColor="#34222C" stopOpacity="0" />
        <stop offset="0.955607" stopColor="#34222C" />
      </radialGradient>
      <linearGradient
        id="paint36_linear_297_287327"
        x1="16.375"
        y1="18.1465"
        x2="16.375"
        y2="16.4277"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.146552" stopColor="#34222C" stopOpacity="0" />
        <stop offset="0.872727" stopColor="#34222C" />
      </linearGradient>
      <linearGradient
        id="paint37_linear_297_287327"
        x1="30.75"
        y1="8.88086"
        x2="30.75"
        y2="16.3809"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B3887F" />
        <stop offset="1" stopColor="#B3887F" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint38_linear_297_287327"
        x1="11.2656"
        y1="6.45898"
        x2="7.39063"
        y2="12.209"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D3B4AE" />
        <stop offset="1" stopColor="#D3B4AE" stopOpacity="0" />
      </linearGradient>
    </defs>
  </SvgIcon>
);

export default Dog;
