import { FC, PropsWithChildren } from 'react';

import { SxProps, Typography } from '@mui/material';
import { mergeSx } from 'utils';

import { IBaseBadgeProps } from './types';

const root: SxProps = {
  // грид нужен для троеточия длинного текста внутри бейджа
  display: 'grid',
  gridAutoFlow: 'column',
  gridTemplateColumns: 'auto',
  whiteSpace: 'nowrap',

  alignItems: 'center',
  gap: '4px',

  width: 'fit-content',
  height: '28px',
  padding: '4px 12px',
  borderRadius: '8px',

  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: 500,

  '& svg': {
    width: '16px',
    height: '16px',
  },
};

const BaseBadge: FC<IBaseBadgeProps & PropsWithChildren> = ({ textColor, backgroundColor, sx, children }) => {
  return (
    <Typography
      component="div"
      variant="Paragraph SM/Medium"
      className="badge"
      color={textColor}
      sx={mergeSx(root, sx, { backgroundColor })}
    >
      {children}
    </Typography>
  );
};

export default BaseBadge;
