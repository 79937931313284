/* eslint-disable max-len */
import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconGia: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.7.6c1.2 0 2.2.7 2.6 1.7.5-1 1.5-1.7 2.6-1.7 1.2 0 2.2.7 2.7 1.7.5-1 1.5-1.7 2.7-1.7 1.6 0 2.9 1.3 2.9 2.9 0 1.6-1.3 2.9-2.9 2.9-1.2 0-2.2-.7-2.7-1.7-.5 1-1.5 1.7-2.7 1.7-1.2 0-2.2-.7-2.6-1.7-.5 1-1.5 1.7-2.6 1.7-1.6 0-2.9-1.3-2.9-2.9C3.8 1.9 5.1.6 6.7.6ZM24 14.3h-1.1c-1 0-1.8.7-1.8 1.6v2.3c0 2.9-2.4 5.3-5.4 5.3H8.3c-3 0-5.4-2.4-5.4-5.3V16c0-.8-.6-1.4-1.5-1.6H0V7.5h2.3v4.7c1.7.5 2.9 1.9 2.9 3.7v2.3c0 1.6 1.3 2.9 3 3v-5.7c0-.9-.7-1.6-1.6-1.6H5.5l.1-6.4h2.2c.7.1 1.4.2 2.3.5.8.3 1.5.7 1.9 1 .4-.3 1.1-.7 1.8-1 .9-.4 1.7-.5 2.4-.6h2.2l-.1 6.3h-1c-.9 0-1.6.7-1.6 1.6v5.6c1.7-.1 3-1.4 3-3v-2.3c0-1.7 1.2-3.2 2.9-3.7V7.5H24v6.8Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconGia;
