import { ComponentProps, FC } from 'react';

import { SxStyles } from 'types';
import { SafeOmit } from 'utils';

import Select from '../Select';

const styles: SxStyles = {
  width: 'max-content',
  minWidth: '190px',
  border: 'none',
  boxShadow: 'none',

  fontFamily: 'Inter',
};

type PaginationLimitSelect = SafeOmit<ComponentProps<typeof Select>, 'inputRenderMode' | 'inputSize' | 'fakeInputSx'>;

const PaginationLimitSelect: FC<PaginationLimitSelect> = (props) => {
  const limitRenderValue = (value: string | undefined): string => {
    if (!value) return '';
    return `Отображать по ${value}`;
  };

  return (
    <Select renderValue={limitRenderValue} {...props} inputRenderMode="fixed" inputSize="medium" fakeInputSx={styles} />
  );
};

export default PaginationLimitSelect;
