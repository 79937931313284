import { ReactNode, useContext } from 'react';

import { Box, SxProps, ThemeProvider, Typography } from '@mui/material';
import { PortfolioSettingsContext } from 'context';
import { useAppSelector } from 'hooks';
import { profileTheme } from 'portfolio3/styles/theme';
import { Button } from 'portfolio3/ui-kit/button';
import Toggle from 'portfolio3/ui-kit/Toggle';
import { linkVisibilitySettingsSelector, localVisibilitySettingsSelector, visibilitySettingsSelector } from 'selectors';
import { isDefined } from 'utils';
import { findTargetVisibilitySetting } from 'utils/localVisibilitySettingsService';

interface IUseWidgetVisibilitySettingsProps {
  sectionTypeCode: string;
  subsectionTypeCode: string;
  subcategoryTypeCode?: string;
}

interface IUseWidgetVisibilityResult {
  isSettingsMode: boolean;
  isWidgetVisible: boolean;
  toggleElement?: ReactNode;
  overlayElement?: ReactNode;
}

const toggleStyle: SxProps = {
  zIndex: 11,
};

const overlayStyle: SxProps = {
  zIndex: 10,
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',

  background: 'rgba(255, 255, 255, 0.8)',
};

const titleStyle: SxProps = {
  fontWeight: 700,
  fontSize: '24px',
  lineHeight: '36px',
};

const showButtonStyle: SxProps = {
  marginTop: '16px',
};

const useWidgetVisibility = ({
  sectionTypeCode,
  subsectionTypeCode,
  subcategoryTypeCode,
}: IUseWidgetVisibilitySettingsProps): IUseWidgetVisibilityResult => {
  const linkMode = useAppSelector((state) => state.linkMode.mode);
  const visibilitySettings = useAppSelector(visibilitySettingsSelector);
  const linkVisibilitySettings = useAppSelector(linkVisibilitySettingsSelector);
  const localVisibilitySettings = useAppSelector(localVisibilitySettingsSelector);

  const { isSettingsMode, setSubsectionVisibility, setSubcategoryVisibility } = useContext(PortfolioSettingsContext);

  const typeCodes = [sectionTypeCode, subsectionTypeCode, subcategoryTypeCode].filter(isDefined);

  /* режим настройки */
  if (isSettingsMode) {
    const hasSubcategory = Boolean(subcategoryTypeCode);
    const localSetting = findTargetVisibilitySetting(localVisibilitySettings, typeCodes);
    const isAdminEnabled = localSetting?.isAdminVisible;
    const isLocalVisible = localSetting?.computedVisibility ?? false;

    const handleSetVisibility = (isVisible: boolean) => {
      return () => {
        if (!sectionTypeCode || !subsectionTypeCode) return;

        if (subcategoryTypeCode) {
          setSubcategoryVisibility(
            localVisibilitySettings,
            sectionTypeCode,
            subsectionTypeCode,
            subcategoryTypeCode,
            isVisible,
          );
        } else {
          setSubsectionVisibility(localVisibilitySettings, sectionTypeCode, subsectionTypeCode, isVisible);
        }
      };
    };

    const overlayTitle = hasSubcategory ? 'Подраздел скрыт' : 'Раздел скрыт';

    const toggleElement = (
      <Toggle
        checked={isLocalVisible}
        onChange={handleSetVisibility(!isLocalVisible)}
        sx={toggleStyle}
        size={hasSubcategory ? 'small' : 'medium'}
      />
    );

    const overlayElement = !isLocalVisible && (
      <Box className="visibility-overlay" sx={overlayStyle}>
        <Typography sx={titleStyle}>{overlayTitle}</Typography>
        <ThemeProvider theme={profileTheme}>
          <Button variant="outline" onClick={handleSetVisibility(true)} sx={showButtonStyle}>
            Показать
          </Button>
        </ThemeProvider>
      </Box>
    );

    return {
      isSettingsMode: true,
      isWidgetVisible: isAdminEnabled ?? false,
      toggleElement,
      overlayElement,
    };
  }

  /* режим просмотра портфолио */
  const settings = linkMode ? linkVisibilitySettings : visibilitySettings;
  const sectionSettings = findTargetVisibilitySetting(settings, typeCodes);
  const isVisible = sectionSettings?.computedVisibility ?? false;

  return {
    isSettingsMode: false,
    isWidgetVisible: isVisible,
  };
};

export default useWidgetVisibility;
