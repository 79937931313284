/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';
import genericReducers from '../genericReducer';
import { IReducers } from '../index';

import { GET_AFFILATION_KINDS } from '../../actions/employees';
import { REQUEST, FAILURE, SUCCESS } from '../../actions/utils';

import { IAffilationKind, ICommonResponse } from '../../api/types';

export type AffilationKindState = ICommonResponse<IAffilationKind>;

const initialState: AffilationKindState = {
  content: [],
  loading: false,
};

const affilationKinds: IReducers<AffilationKindState> = {
  [GET_AFFILATION_KINDS[REQUEST]]: genericReducers().get.request,
  [GET_AFFILATION_KINDS[SUCCESS]]: genericReducers().get.success,
  [GET_AFFILATION_KINDS[FAILURE]]: genericReducers().get.failure
};

export default connectReducers(initialState, affilationKinds);
