import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Box } from '@mui/material';
import { useDataEntryDrawer, useSortState } from 'hooks';
import Table from 'portfolio3/components/common/Table';
import {
  TableFooterPagination,
  TableLoadingIndicator,
  TableTitleWithCounter,
} from 'portfolio3/components/tableRecipes';
import { AccentColorType } from 'portfolio3/styles';
import InfoModal from 'portfolio3/ui-kit/modals/InfoModal';
import { createSelector } from 'reselect';
import { getCategoryProperties, getDataItemType } from 'utils';

import { IChildInfo, IPagination } from '../../../../api/types';
import { ChangeHistoryTabs, EntityType, HistoryActionAttributesLabels } from '../../../../const';
import { ChangeHistoryContext } from '../../../../context';
import { IRootState } from '../../../../reducers';
import { HistoryFormEnityState } from '../../../../reducers/employee/historyFormEntity';
import ChangeHistoryManualItem from '../manualItem';
import ChangeHistoryTableFilters from '../TableFilters';
import { ChangeHistoryMethod, IChangeHistoryManualItem } from '../types';
import * as styles from './styles';

interface IDeleteDialogData {
  isOpen: boolean;
  id?: number;
  dataKind?: number;
  studentName?: string;
  additionalText?: string | null;
}
interface IEmployeeManualDataTable {
  history: IChangeHistoryManualItem[];
  historyFormEntity: HistoryFormEnityState;
  pagination: IPagination;
  loading: boolean;
  onDeleteEmployeeObject: (type: EntityType, id?: number | undefined) => void;
  onEditEmployeeObject: (type: EntityType, currentStudent: IChildInfo, id?: number) => void;
}

const EmployeeManualDataTable: React.FC<IEmployeeManualDataTable> = ({
  history,
  historyFormEntity,
  pagination,
  loading,
  onDeleteEmployeeObject,
  onEditEmployeeObject,
}) => {
  const { currentFilters, setCurrentFilters } = useContext(ChangeHistoryContext);

  const { handleSort } = useSortState(setCurrentFilters);

  const methodSortType = currentFilters.sort.method;
  const dateSortType = currentFilters.sort.date;
  const handleSortMethod = () => handleSort('method');
  const handleSortDate = () => handleSort('date');

  const initialDeleteDialogData = {
    isOpen: false,
    id: undefined,
    dataKind: undefined,
    studentName: undefined,
  };
  const [deleteDialogData, setDeleteDialogData] = useState<IDeleteDialogData>(initialDeleteDialogData);
  const { handleOpenPrimaryDataEntry } = useDataEntryDrawer();

  const handleOpenDeleteDialog = (id: number, dataKind: number, studentName: string, additionalText: string | null) => {
    setDeleteDialogData({
      isOpen: true,
      id,
      dataKind,
      studentName,
      additionalText,
    });
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogData((prevstate) => ({
      ...prevstate,
      isOpen: false,
    }));
  };

  const handleSubmitDeleteDialog = () => {
    const entityType = getDataItemType(deleteDialogData.dataKind);

    if (!entityType) return;

    onDeleteEmployeeObject(entityType, deleteDialogData.id);
    handleCloseDeleteDialog();
  };

  useEffect(() => {
    if (Object.keys(historyFormEntity.content).length > 0 && !historyFormEntity.loading) {
      handleOpenPrimaryDataEntry(historyFormEntity.content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historyFormEntity]);

  const dialogTextElement = (
    <>
      Вы уверены, что хотите удалить добавленные данные для <strong>{deleteDialogData.studentName}</strong>?{' '}
      {deleteDialogData.additionalText} Обратите внимание, что данное действие является необратимым.
    </>
  );

  const headerElement = (
    <Box sx={styles.headerContainer}>
      <TableTitleWithCounter title="История изменений" counter={pagination.totalElements ?? 0} />
      <ChangeHistoryTableFilters historyType={ChangeHistoryTabs.manual} />
    </Box>
  );

  return (
    <>
      <Table
        headerElement={headerElement}
        loading={loading}
        tableProps={{
          sx: {
            gridTemplateColumns: 'minmax(182px, auto) minmax(163px, auto) minmax(186px, auto) 1fr minmax(64px, auto)',
          },
        }}
      >
        <Table.THead>
          <Table.Row>
            <Table.HeadCell withSorting sortType={methodSortType} boxProps={{ onClick: handleSortMethod }}>
              Действие
            </Table.HeadCell>
            <Table.HeadCell withSorting sortType={dateSortType} boxProps={{ onClick: handleSortDate }}>
              Дата и время
            </Table.HeadCell>
            <Table.HeadCell>Учащийся</Table.HeadCell>
            <Table.HeadCell>Сущность</Table.HeadCell>
            <Table.HeadCell />
          </Table.Row>
        </Table.THead>
        <Table.TBody>
          {loading && <TableLoadingIndicator />}
          {!loading && (
            <>
              {history.map((item) => (
                <ChangeHistoryManualItem
                  key={item.id}
                  loading={loading}
                  onOpenDeleteDialog={handleOpenDeleteDialog}
                  onEditEmployeeObject={onEditEmployeeObject}
                  {...item}
                />
              ))}
            </>
          )}
        </Table.TBody>
        <Table.TFoot>
          <TableFooterPagination
            currentFilters={currentFilters}
            setCurrentFilters={setCurrentFilters}
            totalPages={pagination.totalPages ?? 0}
            disabled={loading}
            renderPageModification={(page) => page + 1}
            setPageModification={(page) => page - 1}
          />
        </Table.TFoot>
      </Table>

      <InfoModal
        open={deleteDialogData.isOpen}
        onClose={handleCloseDeleteDialog}
        header="Удаление данных"
        text={dialogTextElement}
        variant="warning"
        cancelButtonText="Отмена"
        onCancel={handleCloseDeleteDialog}
        actionButtonText="Удалить данные"
        onAction={handleSubmitDeleteDialog}
        isActionDisabled={loading}
      />
    </>
  );
};

const getHistoryActionLabel = (method: ChangeHistoryMethod): string => {
  switch (method) {
    case 'POST':
      return HistoryActionAttributesLabels.ADDING;
    case 'PUT':
    case 'PATCH':
      return HistoryActionAttributesLabels.EDITING;
    case 'DELETE':
      return HistoryActionAttributesLabels.DELETING;
    default:
      return HistoryActionAttributesLabels.UNKNOWN;
  }
};

const getHistoryActionAccentColor = (method: ChangeHistoryMethod): AccentColorType => {
  switch (method) {
    case 'POST':
      return 'green';
    case 'PUT':
    case 'PATCH':
      return 'cyan';
    case 'DELETE':
      return 'red';
    default:
      return 'indigo';
  }
};

const translateEntityDataType = (dataType: EntityType) => {
  switch (dataType) {
    case 'Reward':
    case 'SportReward':
      return 'Награда';
    case 'Event':
      return 'Мероприятие';
    case 'Affilation':
      return 'Принадлежность';
    case 'Employment':
      return 'Занятие';
    case 'Project':
      return 'Проект';
    case 'GIAWorldskills':
      return 'Демонстрационный экзамен';
    default:
      return dataType;
  }
};

const historyMapping = createSelector([(state: IRootState) => state.history], (history) => ({
  content:
    history.content?.map((action): IChangeHistoryManualItem => {
      const actionLabel = getHistoryActionLabel(action.method);
      const actionAccentColor = getHistoryActionAccentColor(action.method);
      const categoryProperties = getCategoryProperties(action.categoryCode);

      return {
        id: action.id,
        personId: action.personId,
        entityId: action.entityId,
        dataKind: action.dataKind,
        actionLabel,
        actionAccentColor,
        method: action.method,
        isImport: action.isImport,
        student: `${action.student?.lastName || ''} ${action.student?.firstName || ''} ${
          action.student?.patronymic || ''
        }`,
        firstName: action.student.firstName,
        lastName: action.student.lastName,
        patronymic: action.student.patronymic,
        date: action.creationDate ? new Date(action.creationDate) : undefined,
        entityInfo: {
          accentColor: categoryProperties.accentColor,
          category: categoryProperties.name,
          dataType: translateEntityDataType(action.entityType as EntityType),
          icon: categoryProperties.icon,
          name: action.entityName,
        },
      };
    }) || [],
  pagination: {
    pageNumber: history.number || 0,
    totalPages: history.totalPages || 0,
    pageSize: history.size || 0,
    offset: history.pageable?.offset,
    totalElements: history.totalElements,
    numberOfElements: history.numberOfElements,
  },
}));

export default connect((state: IRootState) => ({
  history: historyMapping(state).content,
  historyFormEntity: state.historyFormEntity,
  pagination: historyMapping(state).pagination,
  loading: state.history.loading,
}))(EmployeeManualDataTable);
