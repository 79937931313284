import { useEffect } from 'react';

import { TCurrentStudentState } from '../reducers/currentStudent';
import { TUserState } from '../reducers/user';
import useUserFunctionality from './useUserFunctionality';

export default (
  currentUser: TUserState,
  // eslint-disable-next-line @typescript-eslint/ban-types
  setCurrentStudent: Function,
  linkMode?: boolean,
  studentGuid?: string,
): void => {
  const { isStudent, isParent } = useUserFunctionality();

  const getCurrentStudentContext = (currentUser: TUserState) => {
    if (studentGuid && isParent) {
      return currentUser.data?.children?.find((child) => child.meshId === studentGuid);
    }

    const currentLocalStudent = localStorage.getItem('currentStudent');

    if (
      currentLocalStudent &&
      currentUser.data?.children?.find((child) => child.meshId === JSON.parse(currentLocalStudent).meshId)
    ) {
      return JSON.parse(currentLocalStudent);
    }

    if (isParent) {
      return currentUser.data?.children && currentUser.data.children.length > 0
        ? currentUser.data.children[0]
        : ({} as TCurrentStudentState);
    }

    if (isStudent) {
      return currentUser.data?.personMeshInfo;
    }

    return {} as TCurrentStudentState;
  };

  const currentStudentContext = getCurrentStudentContext(currentUser);

  useEffect(() => {
    if (!linkMode) {
      setCurrentStudent(currentStudentContext);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
