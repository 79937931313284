import { FC } from 'react';

import { NeutralColors } from 'portfolio3/styles';

import BaseNumberBadge from './BaseNumberBadge';
import { INumberBadgeProps } from './types';

const NeutralNumberBadge: FC<INumberBadgeProps> = ({ number, sx }) => {
  return (
    <BaseNumberBadge
      textColor={NeutralColors.light_neutrals_600}
      backgroundColor={NeutralColors.light_neutrals_300}
      sx={sx}
    >
      {number}
    </BaseNumberBadge>
  );
};

export default NeutralNumberBadge;
