import { FC } from 'react';

import { Box, Collapse, Stack, ThemeProvider, Typography } from '@mui/material';
import { IRecord } from 'api/types';
import { useDisclosure } from 'hooks';
import { IconChevronSmallDown, IconChevronSmallUp } from 'icons';
import { NeutralColors } from 'portfolio3/styles';
import { generateAccentTheme } from 'portfolio3/styles/theme';
import { UnstyledButton } from 'portfolio3/ui-kit';
import { AccentBadge } from 'portfolio3/ui-kit/badges';
import { SxStyles } from 'types';
import { mergeSx } from 'utils';

import IncorrectRecordItemComment from './IncorrectRecordItemComment';

const stylesRoot: SxStyles = {
  padding: '16px 20px',
  borderBottom: '2px solid',
  borderColor: NeutralColors.light_neutrals_300,
};

const stylesHeader: SxStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '12px',
};

const stylesBadgeContainer: SxStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
};

const stylesExpandButtonSlot: SxStyles = {
  width: '24px',
  height: '24px',
};

const stylesExpandButton: SxStyles = {
  width: '24px',
  height: '24px',

  svg: {
    width: '24px',
    height: '24px',
    color: NeutralColors.light_neutrals_600,
  },
};

const stylesChildItemsContainer: SxStyles = {
  '& > *': {
    paddingBlock: '12px',
    borderTop: '1px solid',
    borderColor: NeutralColors.light_neutrals_300,
  },

  '& > *:first-child': {
    borderTop: 'none',
  },

  '& > *:last-child': {
    paddingBottom: 0,
  },
};

interface IIncorrectRecordItemProps {
  record: IRecord;
  isLastItem: boolean;
}

const IncorrectRecordItem: FC<IIncorrectRecordItemProps> = ({ record, isLastItem }) => {
  const { isOpen, onToggle } = useDisclosure();

  const { errorType, rowNum, comment, firstname, lastname, patronymic } = record;

  const studentName = [lastname, firstname, patronymic].join(' ');

  const hasComments = comment && comment.length > 0;

  const headerRowNumberElement = (
    <Stack>
      <Typography variant="Paragraph MD/Medium" color={NeutralColors.light_neutrals_600}>
        Строка #{rowNum}
      </Typography>
      <Typography variant="Paragraph LG/Semi Bold">{studentName}</Typography>
    </Stack>
  );

  const headerBadgeElement = (
    <Box sx={stylesBadgeContainer}>
      <ThemeProvider theme={generateAccentTheme('red')}>
        <AccentBadge>{errorType}</AccentBadge>
      </ThemeProvider>
      <Box sx={stylesExpandButtonSlot}>
        {hasComments && (
          <UnstyledButton sx={stylesExpandButton} onClick={onToggle}>
            {isOpen ? <IconChevronSmallUp /> : <IconChevronSmallDown />}
          </UnstyledButton>
        )}
      </Box>
    </Box>
  );

  return (
    <Box className="incorrect-record-item" sx={mergeSx(stylesRoot, isLastItem && { borderBottom: 'none' })}>
      <Box sx={stylesHeader}>
        {headerRowNumberElement}
        {headerBadgeElement}
      </Box>
      {hasComments && (
        <Collapse in={isOpen}>
          <Box sx={stylesChildItemsContainer}>
            {comment.map((commentObject, index) => {
              const { param, value, description } = commentObject;

              return (
                <IncorrectRecordItemComment
                  key={`${param}-${index}`}
                  param={param}
                  value={value}
                  description={description}
                />
              );
            })}
          </Box>
        </Collapse>
      )}
    </Box>
  );
};

export default IncorrectRecordItem;
