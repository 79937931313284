/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import clsx from 'clsx';
import { connect } from 'react-redux';
import { useMediaQuery } from '@mui/material';

import { IconArrowOpen } from '../../../../icons';
import { IRootState } from '../../../../reducers';
import { Button } from '../../../../ui-kit';
import { InterestHeadKindsState } from '../../../../reducers/interests/interestHeadKinds';
import { emitYMEvent } from 'portfolio3/features/yandexMetrika';

import './index.scss';

interface IInterestsEntryProps {
  isWebView?: boolean;
  studentName?: string;
  interestHeadKinds: InterestHeadKindsState;
  onChangeStepForward: () => void;
}

const InterestsEntry: React.FC<IInterestsEntryProps> = ({
  isWebView,
  studentName,
  interestHeadKinds,
  onChangeStepForward
}) => {
  const isMobile = useMediaQuery('(max-width: 638px)', { noSsr: true });

  const getDescriptionText = () => {
    if (isMobile) return 'На основе них мы сможем давать тебе персональные рекомендации!';
    return 'На основе них мы сможем давать тебе персональные рекомендации, которые помогут улучшить твои навыки и сделать обучение более увлекательным!';
  };

  const handleStart = () => {
    onChangeStepForward();
    emitYMEvent({type: 'startAddingInterestClick'});
  };

  return (
    <section className={clsx('interests-entry', { 'interests-entry--webview': isWebView })}>
      <div className="interests-entry__content">
        <div className="interests-entry__info">
          <h3 className="interests-entry__title">
            {studentName}
            , расскажи о своих интересах, увлечениях и хобби!
          </h3>
          <p className="interests-entry__description">{getDescriptionText()}</p>
          <Button
            className="interests-entry__btn"
            size="large"
            endIcon={<IconArrowOpen className="interests-entry__btn-icon" />}
            onClick={handleStart}
            disabled={interestHeadKinds.loading}
          >
            Начать
          </Button>
        </div>
      </div>
    </section>
  );
};

export default connect(
  (state: IRootState) => ({
    studentName: state.currentStudent.firstname,
    interestHeadKinds: state.interestHeadKinds
  })
)(InterestsEntry);
