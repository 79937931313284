import { createSelector } from 'reselect';

import { IRootState } from '../../../reducers';
import { mapScienceProjectsResponse } from './mappers';

const scienceProjectsSelector = createSelector(
  [(state: IRootState) => state.studentProjects, (state: IRootState) => state.studentRewards],
  (studentProjects, studentRewards) => {
    return mapScienceProjectsResponse(studentProjects, studentRewards);
  },
);

export default scienceProjectsSelector;
