import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { EmployeeSubheader } from 'portfolio3/components/common/headers';
import PaddingContainer from 'portfolio3/components/common/PaddingContainer';
import UnderlinedTabs from 'portfolio3/components/common/UnderlinedTabs';

import { getActionKindsActions, getAdminIncorrectDataFileActions, getAdminLogActions } from '../../../actions';
import { ICommonResponse, IDictionaryItem, IGetAdminIncorrectDataRequest } from '../../../api/types';
import {
  AdminAnalyticsPageTabs,
  AdminAnalyticsPageTabsRoute,
  ReportErrorTypeAdminNames,
  ReportErrorTypeCodes,
  ReportErrorTypeKeys,
} from '../../../const';
import { IRootState } from '../../../reducers';
import Searcher from './Searcher';

const reportErrorTypeDictionary = Object.values(ReportErrorTypeKeys).map((reportErrorTypeKey): IDictionaryItem => {
  return {
    code: ReportErrorTypeCodes[reportErrorTypeKey],
    value: ReportErrorTypeAdminNames[reportErrorTypeKey],
  };
});

interface IAdminAnalyticsContainerProps {
  actionKinds: ICommonResponse<IDictionaryItem>;
  getAdminLog: typeof getAdminLogActions.request;
  getAdminIncorrectDataFile: typeof getAdminIncorrectDataFileActions.request;
  getActionKinds: typeof getActionKindsActions.request;
}

const AdminAnalyticsContainer: React.FC<IAdminAnalyticsContainerProps> = ({
  actionKinds,
  getAdminLog,
  getAdminIncorrectDataFile,
  getActionKinds,
}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [analyticType, setAnalyticType] = useState<AdminAnalyticsPageTabs>(AdminAnalyticsPageTabs.logs);

  useEffect(() => {
    getActionKinds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const tabRoute = AdminAnalyticsPageTabsRoute[analyticType];
    history.replace(`${match.url}/${tabRoute}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticType]);

  const handleDownloadIncorrectDataFile = (startDate: string, endDate: string, types: number[]) => {
    const request: IGetAdminIncorrectDataRequest = {
      dateStart: startDate,
      dateFinish: endDate,
      errorTypes: types,
    };

    getAdminIncorrectDataFile(request);
  };

  const tabsElement = (
    <UnderlinedTabs value={analyticType} onChange={setAnalyticType} sx={{ gap: '24px' }}>
      <UnderlinedTabs.Button value={AdminAnalyticsPageTabs.logs} content="Логи" />
      <UnderlinedTabs.Button value={AdminAnalyticsPageTabs.reports} content="Отчёт об ошибках" />
    </UnderlinedTabs>
  );

  return (
    <section className="admin-analytics">
      <EmployeeSubheader title="Аналитика" tabsElement={tabsElement} />
      <PaddingContainer sx={{ marginTop: '24px' }}>
        {analyticType === AdminAnalyticsPageTabs.logs && (
          <Searcher
            typeTitle="Тип события"
            typeLabel="события"
            typePlaceholder="Выберите тип события..."
            typeKinds={actionKinds?.content || []}
            onDownloadFile={getAdminLog}
          />
        )}
        {analyticType === AdminAnalyticsPageTabs.reports && (
          <Searcher
            typeTitle="Тип ошибки"
            typeLabel="ошибки"
            typePlaceholder="Выберите тип ошибки..."
            typeKinds={reportErrorTypeDictionary}
            onDownloadFile={handleDownloadIncorrectDataFile}
          />
        )}
      </PaddingContainer>
    </section>
  );
};

export default connect(
  (state: IRootState) => ({
    actionKinds: state.actionKinds,
  }),
  {
    getAdminLog: getAdminLogActions.request,
    getAdminIncorrectDataFile: getAdminIncorrectDataFileActions.request,
    getActionKinds: getActionKindsActions.request,
  },
)(AdminAnalyticsContainer);
