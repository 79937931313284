import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';

import ChartRow, { IChartRow } from './ChartRow';
// import RatingColumn from './RatingColumn';
import * as styles from './style';

interface IRatingChart {
  rows: IChartRow[];
  horizontalCaption: string;
  fullWidth?: boolean;
}

const RatingColumn2: FC<{ leftLabel?: string; rightLabel?: string }> = ({ leftLabel, rightLabel }) => {
  return (
    <Box
      className="rating-column-2"
      sx={(theme) => ({
        position: 'relative',
        height: '20px', // высота текста
        width: '100%',

        '& .label': {
          position: 'absolute',
          width: 'max-content',
          color: NeutralColors.light_neutrals_600,
        },

        '& .left-label': {
          transform: 'translateX(-100%)',
        },
        '& .right-label': {
          right: 0,
        },

        '&:before, &:last-child:after': {
          content: "''",
          position: 'absolute',
          width: '1px',
          height: '4000px', // TODO: лучше придумать что-то адекватное (визуально вроде все нормально)
          borderRight: `1px dashed ${NeutralColors.light_neutrals_400}`,
          transform: 'rotate(180deg)',
          transformOrigin: 'top',
          zIndex: '1',

          [theme.breakpoints.down('commonSm')]: {
            height: '800px',
          },
        },

        '&:last-child:after': {
          content: "''",
          position: 'absolute',
          right: 0,
          width: '1px',
          height: '4000px', // TODO: лучше придумать что-то адекватное (визуально вроде все нормально)
          borderRight: `1px dashed ${NeutralColors.light_neutrals_400}`,
          transform: 'rotate(180deg)',
          transformOrigin: 'top',
          zIndex: '1',

          [theme.breakpoints.down('commonSm')]: {
            height: '800px',
          },
        },
      })}
    >
      {leftLabel && (
        <Typography className="label left-label" variant="Paragraph MD/Semi Bold">
          {leftLabel}
        </Typography>
      )}
      {rightLabel && (
        <Typography className="label right-label" variant="Paragraph MD/Semi Bold">
          {rightLabel}
        </Typography>
      )}
    </Box>
  );
};

const RatingChart: FC<IRatingChart> = ({ rows, horizontalCaption, fullWidth }) => {
  return (
    <Box className="rating-chart" sx={styles.chart(fullWidth)}>
      {rows.map((row, index) => {
        return <ChartRow key={index} {...row} />;
      })}
      <Box className="rating-chart-background" sx={styles.chartBackground(fullWidth)}>
        <RatingColumn2 leftLabel="0" />
        <RatingColumn2 leftLabel="20" />
        <RatingColumn2 leftLabel="40" />
        <RatingColumn2 leftLabel="60" />
        <RatingColumn2 leftLabel="80" rightLabel="100" />
      </Box>
      <Box className="rating-typography-container" sx={styles.typographyContainer(fullWidth)}>
        <Typography sx={styles.columnTypography} variant="Paragraph MD/Regular">
          {horizontalCaption}
        </Typography>
      </Box>
    </Box>
  );
};

export default RatingChart;
