import { FC, useContext, useMemo } from 'react';
import { connect } from 'react-redux';

import { IDictionaryItem } from 'api/types';
import { SectionCodes } from 'const';
import { useAppSelector } from 'hooks';
import { IRootState } from 'reducers';
import { AffilationKindState } from 'reducers/employee/affilationKinds';
import { EventKindState } from 'reducers/employee/eventKinds';
import { RewardKindState } from 'reducers/employee/rewardKinds';
import { createSelector } from 'reselect';
import { sectionRefArchiveFalseSelector } from 'selectors';

import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';
import { ActivityBlock, AffiliationBlock, OccupationBlock, RewardBlock } from '../../primaryBlocks';
import { filterPersonsObjects } from '../../selectors';

interface ICreationPrimaryBlockProps {
  affilationKinds: AffilationKindState;
  eventKinds: EventKindState;
  rewardKinds: RewardKindState;
  creationObjectsDictionary: IDictionaryItem[];
}

const CreationPrimaryBlock: FC<ICreationPrimaryBlockProps> = ({
  affilationKinds,
  eventKinds,
  rewardKinds,
  creationObjectsDictionary,
}) => {
  const portfolioSections = useAppSelector(sectionRefArchiveFalseSelector);

  const { studentType, onOpenEventForm, formData } = useContext(LocalDataEntryFormContext);

  // affilation
  const affilationTypeOptions = useMemo(() => {
    return portfolioSections.content.filter((section) => section.parentId === SectionCodes.creationAffilation);
  }, [portfolioSections.content]);
  const affilationNameOptions = useMemo(() => {
    return affilationKinds.content.filter(
      (affilationKind) => affilationKind.categoryCode === SectionCodes.creationCollective && affilationKind.parentId,
    );
  }, [affilationKinds.content]);

  // occupation
  const occupationTypeOptions = useMemo(() => {
    return portfolioSections.content.filter((section) => section.parentId === SectionCodes.creationOccupation);
  }, [portfolioSections.content]);

  // activity
  const activityTypeOptions = useMemo(() => {
    return portfolioSections.content.filter((section) => section.parentId === SectionCodes.creationEvent);
  }, [portfolioSections.content]);
  const activityNameOptions = useMemo(() => {
    return eventKinds.content.filter((eventKind) => eventKind.categoryCode === formData.typeCode);
  }, [eventKinds.content, formData.typeCode]);

  // rewards
  const rewardTypeOptions = useMemo(() => {
    return portfolioSections.content.filter((section) => section.parentId === SectionCodes.creationReward);
  }, [portfolioSections.content]);
  const rewardItemOptions = creationObjectsDictionary.filter(
    (creationObject) => creationObject.parentId === SectionCodes.creationContest,
  );
  const rewardResultOptions = rewardKinds.content.filter((rewardKind) => rewardKind.categoryCode === formData.typeCode);

  const getAffiliationNameInputLabel = () => {
    if (formData.typeCode === SectionCodes.creationCollective) return 'Название коллектива';
    if (formData.typeCode === SectionCodes.creationEnsemble) return 'Название ансамбля';
    return 'Название';
  };
  const getAffiliationNameInputPlaceholder = () => {
    if (formData.typeCode === SectionCodes.creationCollective) return 'Выберите коллектив...';
    if (formData.typeCode === SectionCodes.creationEnsemble) return 'Выберите ансамбль...';
    return '';
  };

  const handleOpenEventForm = () => {
    onOpenEventForm(SectionCodes.creation, SectionCodes.creationEvent);
  };

  return (
    <>
      {formData.dataKind === SectionCodes.creationAffilation && (
        <AffiliationBlock
          typeOptions={affilationTypeOptions}
          nameOptions={affilationNameOptions}
          nameInputLabel={getAffiliationNameInputLabel()}
          nameInputPlaceholder={getAffiliationNameInputPlaceholder()}
        />
      )}
      {formData.dataKind === SectionCodes.creationOccupation && <OccupationBlock typeOptions={occupationTypeOptions} />}
      {formData.dataKind === SectionCodes.creationEvent && (
        <ActivityBlock
          typeOptions={activityTypeOptions}
          nameOptions={activityNameOptions}
          nameInputLabel="Название конкурса"
          nameInputPlaceholder="Выберите конкурс..."
          hasOtherNameOption
        />
      )}
      {formData.dataKind === SectionCodes.creationReward && (
        <RewardBlock
          shortType={false}
          isNameOptionActive
          typeOptions={rewardTypeOptions}
          itemOptions={rewardItemOptions}
          resultOptions={rewardResultOptions}
          onOpenEventForm={handleOpenEventForm}
          nameInputLabel="Конкурс"
          nameInputPlaceholder={
            studentType ? 'Выбери конкурс, за который была получена награда...' : 'Выберите конкурс...'
          }
        />
      )}
    </>
  );
};

const creationObjectsSelector = createSelector([(state: IRootState) => state.personsObjects], (personsObjects) => {
  return filterPersonsObjects(personsObjects.content, [SectionCodes.creationEvent]);
});

export default connect((state: IRootState) => ({
  affilationKinds: state.affilationKinds,
  eventKinds: state.eventKinds,
  rewardKinds: state.rewardKinds,
  creationObjectsDictionary: creationObjectsSelector(state),
}))(CreationPrimaryBlock);
