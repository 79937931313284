import { FC } from 'react';

import { useAppSelector } from 'hooks';

import SubjectThemes from '../../components/SubjectThemes';
import { IMappedLearningYearPerformance, ISubjectTheme } from '../../types';
import ResultsLoader from './ResultsLoader';
import SubjectDetailsEmpty from './SubjectDetailsEmpty';
import SubjectTopicsEmpty from './SubjectTopicsEmpty';

interface ISubjectThemesContainerProps {
  selectedSubjectId: number | undefined;
  yearPerformance: IMappedLearningYearPerformance | undefined;
}

const SubjectThemesContainer: FC<ISubjectThemesContainerProps> = ({ selectedSubjectId, yearPerformance }) => {
  const isSubjectThemesLoading = useAppSelector((state) => state.studentSubjectThemes.loading);
  const subjectThemesCache = useAppSelector((state) => state.studentSubjectThemesCache);

  // изначальное состояние, пока не выбран ни один предмет
  if (!selectedSubjectId || !yearPerformance) return <SubjectDetailsEmpty />;

  // состояние загрузки
  if (isSubjectThemesLoading) return <ResultsLoader />;

  const currentLearningYear = yearPerformance.year;
  // находим оценку выбранного предмета
  const selectedSubjectGrade = yearPerformance.subjects.find((subject) => subject.id === selectedSubjectId)?.grade;

  // объект с данными по выбранной теме
  const subjectThemeObject = subjectThemesCache[selectedSubjectId] as (typeof subjectThemesCache)[number] | undefined;
  // находим нужный объект с темами по предмету
  const currentYearSubjectThemePerformance = subjectThemeObject?.theme.find(
    (themeObject) => themeObject.learningYear === currentLearningYear,
  );

  // состояния отсутствия данных
  if (
    !subjectThemeObject ||
    !currentYearSubjectThemePerformance ||
    currentYearSubjectThemePerformance.averagesByTheme.length === 0
  ) {
    return <SubjectTopicsEmpty />;
  }

  const subjectThemes: ISubjectTheme[] = currentYearSubjectThemePerformance?.averagesByTheme.map(
    (subjectTheme): ISubjectTheme => {
      const { themeName, lessonsPassedCount, lessonsTotalCount, fivePointValue } = subjectTheme;
      return {
        name: themeName,
        passedLessons: lessonsPassedCount,
        totalLessons: lessonsTotalCount,
        averageGrade: fivePointValue,
      };
    },
  );

  const { subjectName } = subjectThemeObject;
  const { themePassedCount, themeTotalCount, percentThemeLearned } = currentYearSubjectThemePerformance;

  return (
    <SubjectThemes
      subject={subjectName}
      subjectGrade={selectedSubjectGrade ?? 0}
      themePassedCount={themePassedCount}
      themeTotalCount={themeTotalCount}
      themeCompletionPercent={percentThemeLearned}
      themes={subjectThemes}
    />
  );
};

export default SubjectThemesContainer;
