import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { detailViewDrawerActions } from 'actions';
import { useAppSelector } from 'hooks';
import { WidgetDataContext } from 'portfolio3/components/common/WidgetContainer';
import { toast } from 'portfolio3/ui-kit';
import { ICardData } from 'types';

import DetailViewDrawer from '../DetailViewDrawer';

const DetailViewDrawersContainer: FC = () => {
  const dispatch = useDispatch();
  const { primaryState, secondaryState, primaryCardData, secondaryCardData } = useAppSelector(
    (state) => state.detailViewDrawer,
  );

  const handleClosePrimary = () => {
    dispatch(detailViewDrawerActions.setPrimaryOpen(false, null));
  };

  const handleCloseSecondary = () => {
    dispatch(detailViewDrawerActions.setSecondaryOpen(false, null));
  };

  const handleOpenSecondary = (cardData: ICardData) => {
    dispatch(detailViewDrawerActions.setSecondaryCardData(cardData));
    dispatch(detailViewDrawerActions.setSecondaryOpen(true, primaryState?.widgetLabel ?? null));
  };

  return (
    <WidgetDataContext.Provider
      value={{
        widgetLabel: primaryState?.widgetLabel ?? null,
      }}
    >
      <DetailViewDrawer
        isOpen={primaryState?.isOpen ?? false}
        cardData={primaryCardData}
        onClose={handleClosePrimary}
        onOpenSecondaryDetailViewDrawer={handleOpenSecondary}
      />
      <DetailViewDrawer
        isOpen={secondaryState?.isOpen ?? false}
        cardData={secondaryCardData}
        onClose={handleCloseSecondary}
        onOpenSecondaryDetailViewDrawer={() => toast.error('Открыто слишком много всплывающих окон')}
      />
    </WidgetDataContext.Provider>
  );
};

export default DetailViewDrawersContainer;
