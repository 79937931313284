import React, { useContext } from 'react';
import { connect } from 'react-redux';

import { Box } from '@mui/material';
import { useAppSelector, useSortState } from 'hooks';
import Table from 'portfolio3/components/common/Table';
import {
  TableFooterPagination,
  TableLoadingIndicator,
  TableTitleWithCounter,
} from 'portfolio3/components/tableRecipes';
import { DayRangePicker, DayRangePickerController } from 'portfolio3/ui-kit/datePickers';
import { Select } from 'portfolio3/ui-kit/selects';
import { IController } from 'portfolio3/ui-kit/types';
import { createSelector } from 'reselect';
import { settingsParentSectionsSelector } from 'selectors';

import { IPagination } from '../../../../api/types';
import { AdminSectionSettingCodes, DifferentCode, SectionCodes } from '../../../../const';
import { ChangeHistoryContext } from '../../../../context';
import {
  IconCreation,
  IconCulture,
  IconProfession,
  IconProfile,
  IconRecommendation,
  IconSchool,
  IconScience,
  IconSport,
  IconStar,
} from '../../../../icons';
import { IRootState } from '../../../../reducers';
import { formatDateWithTime, formatLearnerCategoryCodes } from '../../../../utils';
import AdminHistoryItem from '../historyItem';
import * as styles from './styles';

interface IMappedAdminHistory {
  id: number;
  icon: JSX.Element;
  entity: string;
  author: string;
  date: string;
  action: string;
  comment?: string;
  prevInfo?: string;
  currentInfo?: string;
}

interface IAdminSettingsDataTable {
  adminHistory: IMappedAdminHistory[];
  pagination: IPagination;
  loading: boolean;
}

const AdminSettingsDataTable: React.FC<IAdminSettingsDataTable> = ({ adminHistory, pagination, loading }) => {
  const portfolioSections = useAppSelector(settingsParentSectionsSelector);

  const { currentFilters, setCurrentFilters } = useContext(ChangeHistoryContext);
  const { startDate, endDate } = currentFilters;

  const { handleSort } = useSortState(setCurrentFilters);

  const entitySortType = currentFilters.sort.sectionCode;
  const dateSortType = currentFilters.sort.date;
  const handleSortEntity = () => handleSort('sectionCode');
  const handleSortDate = () => handleSort('date');

  const modifiedPortfolioSections = [{ code: DifferentCode, value: 'Все разделы' }, ...portfolioSections.content];

  const dateRangeController: DayRangePickerController = {
    handleChange(value) {
      setCurrentFilters((prevState) => {
        value.end?.setHours(23, 59, 59);
        return {
          ...prevState,
          startDate: value.start,
          endDate: value.end,
          page: 0,
        };
      });
    },
    handleClear() {
      setCurrentFilters((prevState) => {
        return {
          ...prevState,
          startDate: null,
          endDate: null,
          page: 0,
        };
      });
    },
  };

  const categoryController: IController<string | undefined> = {
    handleChange: (category) =>
      setCurrentFilters({
        ...currentFilters,
        category: Number(category),
        page: 0,
      }),
  };

  const filtersElement = (
    <Box sx={styles.filtersContainer}>
      <Box sx={{ minWidth: '280px' }}>
        <DayRangePicker
          renderMode="fixed"
          size="small"
          startValue={startDate ?? null}
          endValue={endDate ?? null}
          controller={dateRangeController}
        />
      </Box>
      <Box sx={{ minWidth: '300px' }}>
        <Select
          inputRenderMode="fixed"
          inputSize="small"
          placeholder="Выберите категорию"
          value={currentFilters.category}
          options={modifiedPortfolioSections}
          controller={categoryController}
        />
      </Box>
    </Box>
  );

  const headerElement = (
    <Box sx={styles.headerContainer}>
      <TableTitleWithCounter title="История изменений настроек" counter={pagination.totalElements ?? 0} />
      {filtersElement}
    </Box>
  );

  return (
    <Table
      headerElement={headerElement}
      loading={loading}
      tableProps={{
        sx: {
          gridTemplateColumns: 'auto 1fr 2fr minmax(280px, auto) minmax(200px, auto)',
        },
      }}
    >
      <Table.THead>
        <Table.Row>
          <Table.HeadCell>№</Table.HeadCell>
          <Table.HeadCell withSorting sortType={entitySortType} boxProps={{ onClick: handleSortEntity }}>
            Сущность
          </Table.HeadCell>
          <Table.HeadCell>Действие</Table.HeadCell>
          <Table.HeadCell>Автор</Table.HeadCell>
          <Table.HeadCell withSorting sortType={dateSortType} boxProps={{ onClick: handleSortDate }}>
            Дата и время
          </Table.HeadCell>
        </Table.Row>
      </Table.THead>
      <Table.TBody>
        {loading && <TableLoadingIndicator />}
        {!loading && adminHistory.map((item) => <AdminHistoryItem key={item.id} {...item} />)}
      </Table.TBody>
      <Table.TFoot>
        <TableFooterPagination
          currentFilters={currentFilters}
          setCurrentFilters={setCurrentFilters}
          totalPages={pagination.totalPages ?? 0}
          disabled={loading}
          renderPageModification={(page) => page + 1}
          setPageModification={(page) => page - 1}
        />
      </Table.TFoot>
    </Table>
  );
};

const getSettingsHistoryIcon = (adminSectionRefCode?: number) => {
  switch (adminSectionRefCode) {
    case AdminSectionSettingCodes.profile:
      return <IconProfile />;
    case AdminSectionSettingCodes.study:
    case AdminSectionSettingCodes.gia:
    case AdminSectionSettingCodes.diagnosis:
      return <IconSchool />;
    case AdminSectionSettingCodes.science:
      return <IconScience />;
    case AdminSectionSettingCodes.sport:
      return <IconSport />;
    case SectionCodes.creation:
      return <IconCreation />;
    case AdminSectionSettingCodes.culture:
      return <IconCulture />;
    case AdminSectionSettingCodes.profession:
      return <IconProfession />;
    case AdminSectionSettingCodes.recommendation:
      return <IconRecommendation />;
    default:
      return <IconStar />;
  }
};

const historyMapping = createSelector(
  [
    (state: IRootState) => state.adminHistory,
    (state: IRootState) => state.settingSections,
    (state: IRootState) => state.learnerCategories,
  ],
  (history, settingSections, learnerCategories) => {
    const content = history.content.map((adminHistory): IMappedAdminHistory => {
      const parentId = adminHistory.parentSectionId ?? adminHistory.sectionId;

      const currentLearnerCategoryCodes = adminHistory.learnerCategoryCodes?.split(',').map(Number) ?? [];
      const prevLearnerCategoryCodes = adminHistory.prevLearnerCategoryCodes?.split(',').map(Number) ?? [];

      const formattedCurrentCodes = formatLearnerCategoryCodes(currentLearnerCategoryCodes, learnerCategories.content);
      const formattedPrevCodes = formatLearnerCategoryCodes(prevLearnerCategoryCodes, learnerCategories.content);

      return {
        id: adminHistory.id,
        icon: getSettingsHistoryIcon(parentId),
        entity: settingSections.content?.find((section) => section.code === adminHistory.sectionId)?.value ?? '',
        author: adminHistory.administrationFIO,
        date: formatDateWithTime(adminHistory.creationDate),
        action: adminHistory.actionType,
        comment: adminHistory.comment,
        currentInfo: formattedCurrentCodes,
        prevInfo: formattedPrevCodes,
      };
    });

    return {
      ...history,
      content,
      pagination: {
        pageNumber: history.number || 0,
        totalPages: history.totalPages || 0,
        pageSize: history.size || 0,
        offset: history.pageable?.offset,
        totalElements: history.totalElements,
        numberOfElements: history.numberOfElements,
      },
    };
  },
);

export default connect((state: IRootState) => ({
  adminHistory: historyMapping(state).content,
  pagination: historyMapping(state).pagination,
  loading: state.adminHistory.loading,
}))(AdminSettingsDataTable);
