import { NeutralColors } from 'portfolio3/styles';
import { getFocusStateStyles, resetButtonStyles } from 'portfolio3/ui-kit/utils';
import { SxStyles } from 'types';

export const root: SxStyles = {
  ...resetButtonStyles,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  paddingBlock: '12px 4px',
  cursor: 'pointer',

  '&:focus-visible': {
    ...getFocusStateStyles(),
  },
};

export const label: SxStyles = {
  fontWeight: 700,
  fontSize: '15px',
  lineHeight: '24px',
  color: NeutralColors.light_neutrals_900,
};

export const icon: SxStyles = {
  width: '24px',
  height: '24px',
  color: NeutralColors.light_neutrals_900,
};
