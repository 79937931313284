import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const Policeman: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="230" height="129" viewBox="0 0 230 129" xmlns="http://www.w3.org/2000/svg" {...props} fill="none">
    {/*eslint-disable max-len */}
    <path
      d="M166.111 107.386c-4.427 0-7.892-7.685-7.892-17.498 0-9.812 3.465-17.497 7.892-17.497 4.427 0 7.892 7.685 7.892 17.497 0 9.813-3.465 17.498-7.892 17.498Zm0-34.309c-3.871 0-7.141 7.7-7.141 16.811 0 9.113 3.27 16.812 7.141 16.812s7.14-7.7 7.14-16.812c0-9.112-3.269-16.81-7.14-16.81Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M166.111 104.984c-3.375 0-6.013-6.628-6.013-15.096 0-8.467 2.638-15.096 6.013-15.096s6.013 6.629 6.013 15.096c0 8.468-2.638 15.096-6.013 15.096Zm0-29.505c-2.541 0-5.262 5.79-5.262 14.41 0 8.618 2.721 14.409 5.262 14.409 2.54 0 5.261-5.791 5.261-14.41 0-8.618-2.721-14.41-5.261-14.41Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="m160.737 94.397-.067-.687c.045-.006 4.735-.404 5.066-2.977.203-1.544-.128-2.725-.97-3.52-1.458-1.36-3.999-1.153-4.029-1.146l-.075-.687c.12-.013 2.924-.24 4.63 1.352 1.015.947 1.413 2.32 1.188 4.083-.399 3.129-5.525 3.568-5.743 3.582Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M161.707 104.408c-.106-.062-10.456-6.285-17.769-6.285-.834 0-1.684.013-2.525.034-4.45.09-8.659.171-11.057-2.127-1.466-1.4-2.059-3.534-1.826-6.512.127-1.592.451-2.889.992-3.952 1.962-3.864 6.336-3.822 10.989-3.788 1.608.014 3.277.027 4.893-.124 7.328-.672 16.205-6.23 16.295-6.285l.429.563c-.369.233-9.125 5.716-16.649 6.402-1.661.15-3.345.137-4.976.123-4.585-.034-8.538-.068-10.297 3.39-.496.974-.805 2.23-.925 3.712-.218 2.772.316 4.735 1.624 5.99 2.172 2.08 6.216 1.997 10.493 1.915.849-.014 1.698-.034 2.54-.034 7.546 0 17.754 6.141 18.182 6.401l-.413.577Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M129.762 95.186c-3.788-.927-3.781-5.263-3.781-5.304 0-.028-.015-3.466 3.766-4.502l.218.66c-3.239.884-3.232 3.807-3.232 3.842 0 .164 0 3.856 3.217 4.645l-.188.659ZM138.549 114.392c-1.48 0-2.691-1.105-2.691-2.457V97.752h.752v14.183c0 .974.872 1.77 1.939 1.77 1.068 0 1.939-.796 1.939-1.77V97.821h.752v14.114c0 1.359-1.21 2.457-2.691 2.457ZM191.674 32.25h-5.659l.624-20.935c0-1.317.992-2.395 2.209-2.395 1.218 0 2.21 1.078 2.21 2.402l.616 20.928Zm-4.885-.686h4.111l-.601-20.235c0-.954-.654-1.722-1.458-1.722-.804 0-1.458.768-1.458 1.715l-.594 20.242Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M205.753 80.282h-18.4c-2.593 0-4.706-1.928-4.706-4.295V35.859c0-2.367 2.113-4.295 4.706-4.295h18.4c2.593 0 4.705 1.928 4.705 4.295v40.128c0 2.367-2.112 4.295-4.705 4.295Zm-18.4-48.032c-2.18 0-3.954 1.62-3.954 3.61v40.127c0 1.99 1.774 3.609 3.954 3.609h18.4c2.179 0 3.953-1.62 3.953-3.61V35.86c0-1.99-1.774-3.609-3.953-3.609h-18.4Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M206.7 48.032h-21.046v-12.35H206.7v12.35Zm-20.294-.686h19.542V36.367h-19.542v10.979ZM196.553 75.479c-4.765 0-8.644-3.54-8.644-7.891 0-4.35 3.879-7.891 8.644-7.891 4.765 0 8.644 3.54 8.644 7.89 0 4.351-3.879 7.892-8.644 7.892Zm0-15.096c-4.352 0-7.892 3.232-7.892 7.205s3.54 7.205 7.892 7.205 7.892-3.232 7.892-7.205-3.54-7.205-7.892-7.205ZM196.553 53.865c-1.24 0-2.255-.927-2.255-2.059 0-1.132 1.015-2.058 2.255-2.058s2.255.926 2.255 2.058c0 1.132-1.015 2.059-2.255 2.059Zm0-3.431c-.827 0-1.504.617-1.504 1.372 0 .755.677 1.372 1.504 1.372s1.503-.617 1.503-1.372c0-.755-.676-1.372-1.503-1.372ZM193.171 53.522h-7.517V50.09h7.517v3.43Zm-6.765-.686h6.013v-2.059h-6.013v2.059ZM206.7 53.522h-7.516V50.09h7.516v3.43Zm-6.765-.686h6.013v-2.059h-6.013v2.059ZM211.585 38.769h-1.503v7.548h1.503v-7.548ZM211.209 48.718h-1.503v5.49h1.503v-5.49Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M191.554 63.368a.37.37 0 0 1-.263-.103l-.263-.24a.319.319 0 0 1 0-.487.397.397 0 0 1 .533 0l.264.24a.32.32 0 0 1 0 .487.403.403 0 0 1-.271.103ZM200.889 71.89a.368.368 0 0 1-.263-.102l-.504-.46a.32.32 0 0 1 0-.487.398.398 0 0 1 .534 0l.503.46a.319.319 0 0 1 0 .487.398.398 0 0 1-.27.103Zm-1.165-1.063a.368.368 0 0 1-.263-.103l-.504-.46a.32.32 0 0 1 0-.487.398.398 0 0 1 .534 0l.503.46a.319.319 0 0 1 0 .487.427.427 0 0 1-.27.103Zm-1.165-1.063a.368.368 0 0 1-.263-.103l-.504-.46a.32.32 0 0 1 0-.487.398.398 0 0 1 .534 0l.503.46a.319.319 0 0 1 0 .487.427.427 0 0 1-.27.103Zm-1.173-1.07a.37.37 0 0 1-.263-.104l-.503-.46a.319.319 0 0 1 0-.487.397.397 0 0 1 .533 0l.504.46a.32.32 0 0 1 0 .487.401.401 0 0 1-.271.103Zm-1.165-1.064a.37.37 0 0 1-.263-.103l-.503-.46a.319.319 0 0 1 0-.487.396.396 0 0 1 .533 0l.504.46a.32.32 0 0 1 0 .487.401.401 0 0 1-.271.103Zm-1.165-1.064a.37.37 0 0 1-.263-.103l-.504-.46a.32.32 0 0 1 0-.487.398.398 0 0 1 .534 0l.504.46a.32.32 0 0 1 0 .487.416.416 0 0 1-.271.103Zm-1.165-1.064a.369.369 0 0 1-.263-.102l-.504-.46a.32.32 0 0 1 0-.487.398.398 0 0 1 .534 0l.504.46a.32.32 0 0 1 0 .487.432.432 0 0 1-.271.102Zm-1.165-1.063a.37.37 0 0 1-.263-.103l-.504-.46a.32.32 0 0 1 0-.487.398.398 0 0 1 .534 0l.504.46a.32.32 0 0 1 0 .487.432.432 0 0 1-.271.103ZM201.814 72.734a.367.367 0 0 1-.263-.102l-.263-.24a.319.319 0 0 1 0-.488.397.397 0 0 1 .533 0l.263.24a.319.319 0 0 1 0 .487.4.4 0 0 1-.27.103Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M196.553 74.381c-.211 0-.376-.15-.376-.343v-.686c0-.192.165-.343.376-.343.21 0 .375.15.375.343v.686c0 .192-.165.343-.375.343Zm0-1.578c-.211 0-.376-.151-.376-.343v-.686c0-.192.165-.343.376-.343.21 0 .375.15.375.343v.686c0 .192-.165.343-.375.343Zm0-1.578c-.211 0-.376-.151-.376-.343v-.687c0-.192.165-.342.376-.342.21 0 .375.15.375.343v.686c0 .192-.165.343-.375.343Zm0-1.578c-.211 0-.376-.151-.376-.344v-.686c0-.192.165-.343.376-.343.21 0 .375.151.375.343v.686c0 .193-.165.344-.375.344Zm0-1.579c-.211 0-.376-.15-.376-.343v-.686c0-.192.165-.343.376-.343.21 0 .375.151.375.343v.686c0 .192-.165.343-.375.343Zm0-1.578c-.211 0-.376-.15-.376-.343v-.686c0-.192.165-.343.376-.343.21 0 .375.15.375.343v.686c0 .192-.165.343-.375.343Zm0-1.578c-.211 0-.376-.15-.376-.343v-.686c0-.192.165-.343.376-.343.21 0 .375.15.375.343v.686c0 .192-.165.343-.375.343Zm0-1.578c-.211 0-.376-.151-.376-.343v-.686c0-.193.165-.343.376-.343.21 0 .375.15.375.343v.686c0 .192-.165.343-.375.343Zm0-1.578c-.211 0-.376-.151-.376-.343v-.687c0-.192.165-.343.376-.343.21 0 .375.151.375.343v.687c0 .192-.165.343-.375.343Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M191.502 72.542a.368.368 0 0 1-.263-.103.319.319 0 0 1 0-.487l.533-.487a.398.398 0 0 1 .534 0 .32.32 0 0 1 0 .487l-.534.487a.398.398 0 0 1-.27.103Zm1.225-1.118a.37.37 0 0 1-.263-.103.32.32 0 0 1 0-.487l.534-.487a.397.397 0 0 1 .533 0 .319.319 0 0 1 0 .487l-.533.487a.416.416 0 0 1-.271.103Zm1.225-1.112a.37.37 0 0 1-.263-.103.32.32 0 0 1 0-.487l.534-.487a.397.397 0 0 1 .533 0 .319.319 0 0 1 0 .487l-.533.487a.432.432 0 0 1-.271.103Zm1.218-1.118a.368.368 0 0 1-.263-.103.319.319 0 0 1 0-.487l.533-.487a.398.398 0 0 1 .534 0 .32.32 0 0 1 0 .487l-.534.487a.4.4 0 0 1-.27.103Zm1.225-1.119a.37.37 0 0 1-.263-.103.32.32 0 0 1 0-.487l.534-.487a.397.397 0 0 1 .533 0 .319.319 0 0 1 0 .487l-.533.487a.416.416 0 0 1-.271.103Zm1.218-1.111a.368.368 0 0 1-.263-.103.319.319 0 0 1 0-.487l.533-.487a.398.398 0 0 1 .534 0 .32.32 0 0 1 0 .487l-.534.487a.4.4 0 0 1-.27.103Zm1.225-1.119a.368.368 0 0 1-.263-.103.319.319 0 0 1 0-.487l.533-.487a.398.398 0 0 1 .534 0 .32.32 0 0 1 0 .487l-.534.487a.4.4 0 0 1-.27.103Zm1.225-1.118a.367.367 0 0 1-.263-.103.32.32 0 0 1 0-.487l.533-.487a.398.398 0 0 1 .534 0 .32.32 0 0 1 0 .487l-.534.487a.427.427 0 0 1-.27.103Zm1.218-1.112a.371.371 0 0 1-.264-.103.32.32 0 0 1 0-.487l.534-.487a.398.398 0 0 1 .534 0 .32.32 0 0 1 0 .487l-.534.487a.4.4 0 0 1-.27.103Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M204.069 67.931h-.751c-.211 0-.376-.15-.376-.343 0-.192.165-.343.376-.343h.751c.211 0 .376.151.376.343 0 .192-.165.343-.376.343Zm-1.728 0h-.752c-.211 0-.376-.15-.376-.343 0-.192.165-.343.376-.343h.752c.21 0 .375.151.375.343 0 .192-.165.343-.375.343Zm-1.729 0h-.752c-.21 0-.376-.15-.376-.343 0-.192.166-.343.376-.343h.752c.21 0 .376.151.376.343 0 .192-.166.343-.376.343Zm-1.729 0h-.752c-.21 0-.375-.15-.375-.343 0-.192.165-.343.375-.343h.752c.21 0 .376.151.376.343 0 .192-.166.343-.376.343Zm-1.729 0h-.751c-.211 0-.376-.15-.376-.343 0-.192.165-.343.376-.343h.751c.211 0 .376.151.376.343 0 .192-.165.343-.376.343Zm-1.728 0h-.752c-.211 0-.376-.15-.376-.343 0-.192.165-.343.376-.343h.752c.21 0 .375.151.375.343 0 .192-.165.343-.375.343Zm-1.729 0h-.752c-.21 0-.376-.15-.376-.343 0-.192.166-.343.376-.343h.752c.21 0 .376.151.376.343 0 .192-.166.343-.376.343Zm-1.729 0h-.752c-.21 0-.375-.15-.375-.343 0-.192.165-.343.375-.343h.752c.21 0 .376.151.376.343 0 .192-.166.343-.376.343Zm-1.729 0h-.751c-.211 0-.376-.15-.376-.343 0-.192.165-.343.376-.343h.751c.211 0 .376.151.376.343 0 .192-.165.343-.376.343Zm-1.729 0h-.225c-.21 0-.376-.15-.376-.343 0-.192.166-.343.376-.343h.225c.211 0 .376.151.376.343 0 .192-.165.343-.376.343ZM119.201 44.718c-1.315 0-2.277-.103-2.375-.117-.203-.02-.353-.192-.323-.384.022-.186.225-.323.421-.295.06.006 5.9.645 8.749-1.304 2.811-1.922 2.646-6.526 2.646-6.574-.008-.192.15-.35.36-.356.241.006.384.137.391.329.008.206.181 5.009-2.946 7.15-1.894 1.29-4.848 1.55-6.923 1.55Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M118.856 46.557c-1.12 0-1.902-.048-2.007-.055-.211-.014-.361-.179-.346-.364.015-.185.203-.336.398-.316.091.007 9.178.556 11.493-2.23 2.337-2.813 1.646-7.465 1.638-7.513-.03-.185.113-.364.316-.391.203-.028.398.103.429.288.03.199.744 4.982-1.789 8.028-1.842 2.223-7.178 2.553-10.132 2.553Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M150.327 36.367h-31.764c-.211 0-.376-.151-.376-.343 0-.192.165-.343.376-.343h31.388v-6.066l-50.6-.535c-.21 0-.376-.158-.368-.35 0-.192.142-.364.383-.337l50.968.536c.203 0 .369.158.369.343v6.752c0 .192-.166.343-.376.343.007 0 0 0 0 0Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M113.872 59.01c-.023 0-.038 0-.06-.006l-19.543-2.745a.384.384 0 0 1-.248-.137.325.325 0 0 1-.067-.261l5.028-27.186-.007-2.95c0-.193.165-.344.376-.344h60.363c.211 0 .376.151.376.343v8.166c0 .192-.165.343-.376.343h-9.388c-.21 0-.376-.151-.376-.343 0-.192.166-.343.376-.343h9.02v-7.473H99.734v2.663l-4.976 26.898 18.806 2.641 4.622-22.314c.03-.165.188-.281.369-.281h.007c.188.007.338.13.361.295.338 2.237 1.127 3.78 2.353 4.604.39.26.774.412 1.12.5-1.376-1.598-1.398-4.892-1.398-5.056 0-.192.165-.343.375-.343.211 0 .376.15.376.343 0 1.27.361 4.666 2 5.167.165.048.27.192.255.35a.364.364 0 0 1-.308.315c-.06.007-1.443.227-2.849-.706-1.045-.694-1.811-1.812-2.285-3.349l-4.337 20.928c-.015.165-.173.282-.353.282Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M163.684 32.723h-3.962c-.21 0-.375-.15-.375-.343 0-.192.165-.343.375-.343h3.571l-.173-4.59h-3.398c-.21 0-.375-.151-.375-.344 0-.192.165-.343.375-.343h3.759c.203 0 .368.144.375.33l.203 5.276a.33.33 0 0 1-.105.247.377.377 0 0 1-.27.11ZM128.694 36.367a.379.379 0 0 1-.33-.185l-2.796-4.804a.32.32 0 0 1 .007-.336.382.382 0 0 1 .323-.165h19.287a.39.39 0 0 1 .316.158l3.3 4.804c.112.157.06.37-.121.473a.392.392 0 0 1-.518-.11l-3.187-4.638h-18.453l2.503 4.302c.098.165.03.377-.158.467a.476.476 0 0 1-.173.034ZM115.376 62.441c-.023 0-.038 0-.061-.007L92 59.004a.366.366 0 0 1-.308-.261c-.015-.055-.369-1.414.436-2.347.466-.542 1.21-.816 2.202-.816.21 0 .376.15.376.343 0 .192-.166.343-.376.343-.752 0-1.293.185-1.609.556-.42.487-.39 1.194-.338 1.543l22.79 3.356c.127-.254.3-.727.142-1.249-.172-.583-.714-1.091-1.608-1.496-.188-.082-.263-.288-.165-.46.09-.171.315-.24.503-.15 1.098.5 1.774 1.152 2 1.941.308 1.064-.331 1.949-.354 1.983a.393.393 0 0 1-.315.151ZM99.178 30.088a.41.41 0 0 1-.263-.096l-5.826-5.194a.324.324 0 0 1-.037-.453l.977-1.187a.406.406 0 0 1 .474-.096l5.028 2.374c.188.09.255.295.158.46a.386.386 0 0 1-.504.144l-4.75-2.237-.586.714 5.584 4.988c.15.13.15.35.008.487a.434.434 0 0 1-.263.096ZM118.382 32.936h-14.281c-.21 0-.375-.15-.375-.343v-2.059c0-.192.165-.343.375-.343h14.281c.211 0 .376.151.376.344v2.058c0 .192-.165.343-.376.343Zm-13.905-.686h13.53v-1.372h-13.53v1.372ZM111.242 57.254c-.023 0-.045 0-.068-.007l-13.905-2.36a.359.359 0 0 1-.24-.145.297.297 0 0 1-.053-.26l4.262-18.527c.037-.172.21-.295.413-.268l13.026 1.373a.428.428 0 0 1 .255.13c.06.076.09.172.075.26l-3.382 19.516a.35.35 0 0 1-.158.226.464.464 0 0 1-.225.062ZM97.78 54.276l13.146 2.237 3.262-18.815-12.297-1.297-4.111 17.875ZM26.495 107.042c-1.661 0-3.007-1.228-3.007-2.744V36.71c0-.192.166-.343.376-.343h5.261c.211 0 .376.15.376.343v67.588c0 1.516-1.345 2.744-3.006 2.744Zm-2.255-69.99v67.246c0 1.132 1.015 2.058 2.255 2.058s2.255-.926 2.255-2.058V37.053h-4.51Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M47.728 45.287H29.69c-.21 0-.375-.15-.375-.343 0-.192.165-.343.375-.343h18.04c1.037 0 1.879-.769 1.879-1.715 0-.947-.842-1.716-1.88-1.716H29.69c-.21 0-.375-.15-.375-.343 0-.192.165-.343.375-.343h18.04c1.45 0 2.63 1.077 2.63 2.401 0 1.325-1.18 2.402-2.63 2.402ZM30.253 37.053h-7.516c-.21 0-.376-.15-.376-.343V19.72l-.541-1.42a.326.326 0 0 1 .045-.309.4.4 0 0 1 .308-.15h9.02a.37.37 0 0 1 .323.17.33.33 0 0 1 .008.344l-.895 1.482v16.88c0 .185-.165.336-.376.336Zm-7.14-.686h6.765v-16.62a.32.32 0 0 1 .045-.164l.638-1.056H22.7l.391 1.022a.288.288 0 0 1 .023.11v16.708Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M29.126 35.125h-5.45c-.21 0-.375-.15-.375-.343 0-.192.165-.343.376-.343h5.449c.21 0 .376.15.376.343 0 .192-.166.343-.376.343ZM29.126 33.066h-5.45c-.21 0-.375-.15-.375-.343 0-.192.165-.343.376-.343h5.449c.21 0 .376.151.376.343 0 .193-.166.343-.376.343ZM29.126 31.008h-5.45c-.21 0-.375-.151-.375-.343 0-.192.165-.343.376-.343h5.449c.21 0 .376.15.376.343 0 .192-.166.343-.376.343ZM29.126 28.95h-5.45c-.21 0-.375-.152-.375-.344 0-.192.165-.343.376-.343h5.449c.21 0 .376.151.376.343 0 .192-.166.343-.376.343ZM29.126 26.89h-5.45c-.21 0-.375-.15-.375-.342 0-.192.165-.343.376-.343h5.449c.21 0 .376.15.376.343 0 .192-.166.343-.376.343ZM29.126 24.832h-5.45c-.21 0-.375-.15-.375-.343 0-.192.165-.343.376-.343h5.449c.21 0 .376.151.376.343 0 .192-.166.343-.376.343ZM29.126 22.774h-5.45c-.21 0-.375-.15-.375-.343 0-.192.165-.343.376-.343h5.449c.21 0 .376.15.376.343 0 .192-.166.343-.376.343ZM29.126 20.715h-5.45c-.21 0-.375-.15-.375-.343 0-.192.165-.343.376-.343h5.449c.21 0 .376.151.376.343 0 .192-.166.343-.376.343ZM108.792 93.813c-5.938 0-12.537-1.612-13.003-1.73-.97-.37-22.602-8.823-28.314-13.565a.32.32 0 0 1-.023-.487.405.405 0 0 1 .534-.02c5.622 4.666 27.833 13.339 28.058 13.428.143.034 18.776 4.59 21.234-1.393.248-.597-.03-2.539-7.915-7.65C91.76 70.983 57.861 56.848 54.411 59.476c-.834.638-1.27 1.441-1.323 2.463-.21 4.282 6.494 10.458 6.562 10.52a.32.32 0 0 1 0 .487.397.397 0 0 1-.534 0c-.285-.261-7.005-6.45-6.78-11.04.06-1.208.594-2.203 1.594-2.958 4.262-3.239 40.528 12.94 55.869 22.883 6.193 4.015 8.869 6.78 8.178 8.447-1.12 2.724-4.984 3.534-9.185 3.534ZM77.666 93.827c-7.621 0-30.636-.515-35.116-6.65-.902-1.234-1.075-2.421-.511-3.512 2.255-4.371 15.852-5.696 16.43-5.744.188-.013.391.117.414.31.023.184-.128.356-.338.377-.136.013-13.755 1.33-15.83 5.359-.45.871-.3 1.804.459 2.84 5.043 6.903 36.567 6.327 36.883 6.32.24.014.383.144.383.336.007.192-.158.343-.368.35-.188 0-1.06.014-2.406.014Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M94.15 96.064h-.023c-.06 0-5.787-.336-12.717-2.1-6.412-1.633-15.19-4.858-20.625-10.958a.325.325 0 0 1 .052-.48.404.404 0 0 1 .527.048c9.56 10.738 29.945 12.59 32.53 12.783l1.639-3.733c.075-.178.293-.26.488-.192.196.069.286.268.21.446l-1.735 3.966a.364.364 0 0 1-.346.22ZM66.332 91.302c-17.415 0-21.053-6.272-21.196-6.54a.333.333 0 0 1 .165-.459c.188-.082.414-.014.504.15.007.015.894 1.552 3.833 3.068 2.736 1.414 7.847 3.088 16.694 3.088.21 0 .376.15.376.343 0 .192-.166.35-.376.35Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M61.348 82.704c-.707 0-1.173-.137-1.218-.151a.354.354 0 0 1-.195-.137c-.075-.103-1.804-2.498-1.804-4.954 0-2.423 2.495-7.644 2.6-7.864a.375.375 0 0 1 .316-.206l3.51-.26a.393.393 0 0 1 .339.144l4.344 5.606c.06.082.083.185.06.28-.037.131-.924 3.295-3.578 5.717-1.646 1.516-3.3 1.825-4.374 1.825Zm-.857-.769c.519.117 2.593.398 4.713-1.537 2.187-1.996 3.127-4.604 3.33-5.249l-4.12-5.318-3.074.234c-.45.96-2.45 5.365-2.45 7.397-.008 1.976 1.278 3.993 1.601 4.473Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M61.077 80.289a.385.385 0 0 1-.36-.24l-.835-2.478a.322.322 0 0 1 0-.205l1.752-4.77c.067-.178.278-.274.473-.219.195.062.3.254.24.433l-1.698 4.659.797 2.374c.06.178-.053.37-.248.425-.046.02-.083.02-.12.02ZM110.039 91.302c-13.627 0-27.059-8.577-27.194-8.667a.324.324 0 0 1-.09-.48.398.398 0 0 1 .526-.082c.135.082 13.379 8.536 26.765 8.536.211 0 .376.15.376.343 0 .192-.173.35-.383.35Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default Policeman;
