import React from 'react';

import { EmployeeSubheader } from 'portfolio3/components/common/headers';
import UnderlinedTabs from 'portfolio3/components/common/UnderlinedTabs';

import { AdminSettingsPageTabs } from '../../../../const';

interface IChangeSettingsHeaderProps {
  settingType: AdminSettingsPageTabs;
  onChangeSettingType: React.Dispatch<React.SetStateAction<AdminSettingsPageTabs>>;
}

const ChangeSettingsHeader: React.FC<IChangeSettingsHeaderProps> = ({ settingType, onChangeSettingType }) => {
  const tabsElement = (
    <UnderlinedTabs value={settingType} onChange={onChangeSettingType} sx={{ gap: '24px' }}>
      <UnderlinedTabs.Button value={AdminSettingsPageTabs.settings} content="Настройки отображения разделов" />
      <UnderlinedTabs.Button value={AdminSettingsPageTabs.history} content="История изменений настроек" />
    </UnderlinedTabs>
  );

  return <EmployeeSubheader title="Настройки отображения" tabsElement={tabsElement} />;
};

export default ChangeSettingsHeader;
