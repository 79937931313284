/* eslint-disable prettier/prettier */
/* eslint-disable */

import { all, call, put } from 'redux-saga/effects';

import {
  deleteStudentInterestActions,
  getInterestObjectKindsActions,
  getInterestActionKindsActions,
  getInterestGroupKindsActions,
  getInterestHeadKindsActions,
  getStudentInterestsActions,
  createStudentInterestListActions,
  getArchivedInterestObjectKindsActions,
} from '../actions';
import {
  deleteStudentInterest,
  editStudentInterest,
  getInterestActionKinds,
  getInterestGroupKinds,
  getInterestHeadKinds,
  getInterestObjectKinds,
  getStudentInterests,
  postStudentInterestList,
} from '../api';
import { GenericCreateUpdateAction, GenericDeleteAction, GenericGetAction } from '.';
import { InterestHeadKindsPath, InterestHeadKindKeys } from '../const';
import { ISaveInterestRequest, IStudentInterest } from '../api/types';
import { isDefined } from 'utils';

function* getStudentInterestsSaga({ payload: { personId } }: GenericGetAction<any>) {
  const { response, error } = yield call(getStudentInterests, personId);

  if (response) {
    yield put(getStudentInterestsActions.success(response));
  } else {
    yield put(getStudentInterestsActions.failure(error));
  }
}

function* createStudentInterestListSaga({ payload: { request, personId } }: GenericCreateUpdateAction<ISaveInterestRequest[]>) {
  const { response, error } = yield call(postStudentInterestList, request, personId || '');

  if (response) {
    yield put(createStudentInterestListActions.success(response));
    yield put(getStudentInterestsActions.request(personId || ''));
  } else {
    yield put(createStudentInterestListActions.failure(error));
  }
}

function* editStudentInterestSaga({ payload: { request, interestId, personId } }: GenericGetAction<any>) {
  const { response, error } = yield call(editStudentInterest, request, interestId);

  if (response) {
    yield put(createStudentInterestListActions.success(response));
    yield put(getStudentInterestsActions.request(personId || ''));
  } else {
    yield put(createStudentInterestListActions.failure(error));
  }
}

function* deleteStudentInterestsSaga({ payload: { personId, interestId } }: GenericDeleteAction<any>) {
  const { response, error } = yield call(deleteStudentInterest, interestId);

  if (response) {
    yield put(deleteStudentInterestActions.success(response));
    yield put(getStudentInterestsActions.request(personId));
  } else {
    yield put(deleteStudentInterestActions.failure(error));
  }
}

function* getInterestHeadKindsSaga({ payload: { queryParams } }: GenericGetAction<any>) {
  const { response, error } = yield call(getInterestHeadKinds, queryParams);

  if (response) {
    yield put(getInterestHeadKindsActions.success(response));
  } else {
    yield put(getInterestHeadKindsActions.failure(error));
  }
}

function* getInterestActionKindsSaga({ payload: { queryParams } }: GenericGetAction<any>) {
  const { response, error } = yield call(getInterestActionKinds, queryParams);

  if (response) {
    yield put(getInterestActionKindsActions.success(response));
  } else {
    yield put(getInterestActionKindsActions.failure(error));
  }
}

function* getInterestGroupKindsSaga({ payload: { queryParams } }: GenericGetAction<any>) {
  const { response, error } = yield call(getInterestGroupKinds, queryParams);

  if (response) {
    yield put(getInterestGroupKindsActions.success(response));
  } else {
    yield put(getInterestGroupKindsActions.failure(error));
  }
}

const interestHeadKindsPaths = [
  InterestHeadKindsPath[InterestHeadKindKeys.SPORT],
  InterestHeadKindsPath[InterestHeadKindKeys.HEALTHY],
  InterestHeadKindsPath[InterestHeadKindKeys.TRAVEL],
  InterestHeadKindsPath[InterestHeadKindKeys.MUSIC],
  InterestHeadKindsPath[InterestHeadKindKeys.LITERATURE],
  InterestHeadKindsPath[InterestHeadKindKeys.CINEMA],
  InterestHeadKindsPath[InterestHeadKindKeys.PHOTO],
  InterestHeadKindsPath[InterestHeadKindKeys.DANCE],
  InterestHeadKindsPath[InterestHeadKindKeys.THEATRICAL],
  InterestHeadKindsPath[InterestHeadKindKeys.DESIGN],
  InterestHeadKindsPath[InterestHeadKindKeys.CULTURAL_LEISURE],
  InterestHeadKindsPath[InterestHeadKindKeys.FASHION],
  InterestHeadKindsPath[InterestHeadKindKeys.COLLECTION],
  InterestHeadKindsPath[InterestHeadKindKeys.TRANSPORT],
  InterestHeadKindsPath[InterestHeadKindKeys.CHARACTER],
  InterestHeadKindsPath[InterestHeadKindKeys.HISTORY],
  InterestHeadKindsPath[InterestHeadKindKeys.SPACE],
  InterestHeadKindsPath[InterestHeadKindKeys.LANGUAGE],
  InterestHeadKindsPath[InterestHeadKindKeys.INFORMATION],
  InterestHeadKindsPath[InterestHeadKindKeys.SCIENCE],
  InterestHeadKindsPath[InterestHeadKindKeys.GAME],
  InterestHeadKindsPath[InterestHeadKindKeys.ANIMAL],
  InterestHeadKindsPath[InterestHeadKindKeys.COOKERY],
  InterestHeadKindsPath[InterestHeadKindKeys.ACTIVITY],
  InterestHeadKindsPath[InterestHeadKindKeys.SOCIAL_MEDIA],
];

function* getInterestObjectKindsSaga({ payload: { queryParams } }: GenericGetAction<any>) {
  const result: any[] = yield all(interestHeadKindsPaths.map((path) => call(getInterestObjectKinds, path, { ...queryParams, isArchive: false })));
  const successResult = result.filter(({ response }) => isDefined(response));

  const interestHeadToObjectKindMap = new Map<number, IStudentInterest>();
  successResult.forEach(({ response }, index) => {
    interestHeadToObjectKindMap.set(index + 1, response.data);
  });

  const objectKinds = Object.fromEntries(interestHeadToObjectKindMap);
  yield put(getInterestObjectKindsActions.success(objectKinds));
}

function* getArchivedInterestObjectKindsSaga({ payload: { queryParams } }: GenericGetAction<any>) {
  const result: any[] = yield all(interestHeadKindsPaths.map((path) => call(getInterestObjectKinds, path, queryParams)));
  const successResult = result.filter(({ response }) => isDefined(response));

  const interestHeadToObjectKindMap = new Map<number, IStudentInterest>();
  successResult.forEach(({ response }, index) => {
    interestHeadToObjectKindMap.set(index + 1, response.data);
  });

  const objectKinds = Object.fromEntries(interestHeadToObjectKindMap);
  yield put(getArchivedInterestObjectKindsActions.success(objectKinds));
}

export {
  getStudentInterestsSaga,
  createStudentInterestListSaga,
  editStudentInterestSaga,
  deleteStudentInterestsSaga,
  getInterestHeadKindsSaga,
  getInterestActionKindsSaga,
  getInterestGroupKindsSaga,
  getInterestObjectKindsSaga,
  getArchivedInterestObjectKindsSaga,
};
