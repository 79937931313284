/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useContext, useEffect, useMemo, useState } from 'react';

import { connect } from 'react-redux';
import { Box, FormHelperText } from '@mui/material';
import { IDictionaryItem, ILocation, IChildInfo } from '../../../../../api/types';
import { DifferentValue, SectionCodes } from '../../../../../const';
import { validateParallel, isDefined, mergeSx } from '../../../../../utils';
import { IRootState } from '../../../../../reducers';
import { commonAcceptFileTypes, IEventData } from 'portfolio3/features/dataEntryForm';
import { SecondaryBlockContainer, useSecondaryBlockControllers } from "../common";
import { getCommonInputControllerFactory } from "../../utils";

import AgeLimitControl from 'portfolio3/components/forms/AgeLimitControl';
import MultiInputControl from 'portfolio3/components/forms/MultiInputControl';
import { AutocompleteBase } from 'portfolio3/ui-kit/autocomplete';
import { BaseInputLabel, FormControl, Input, Textarea } from 'portfolio3/ui-kit';
import { Select } from 'portfolio3/ui-kit/selects';
import { IController } from 'portfolio3/ui-kit/types';
import { subcategoryCultureCodes } from 'const/eventKind';
import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';
import { DayRangePicker } from 'portfolio3/ui-kit/datePickers';
import { getDayRangePickerControllerFactory } from '../../controllers';
import { dataEntryMaxDate, dataEntryMinDate } from '../../dateErrors';

interface ICultureActivitySecondaryBlockProps {
  eventLevelOptions: IDictionaryItem[];
  placeOptions: ILocation[];
  name?: string;
  eventData: IEventData;
  currentStudent: IChildInfo;
}

const StringAutocomplete = AutocompleteBase<string, false, false, false>;

const CultureActivitySecondaryBlock: React.FC<ICultureActivitySecondaryBlockProps> = ({
  eventLevelOptions,
  placeOptions,
  name,
  eventData,
  currentStudent,
}) => {
  const [isPlaceControllerDisabled, setPlaceControllerDisabled] = useState(false);

  const { studentType, inputRenderMode, inputSize, isMobile, formData, formErrors, onChangeFormData, onChangeFormErrors } = useContext(LocalDataEntryFormContext);

  const { linkedObjects, linkedObjectController, fileController } = useSecondaryBlockControllers({
    formData,
    onChangeFormData,
    onChangeFormErrors
  });

  const newPlaceOptions = placeOptions.map((option) => option.value).filter(isDefined);

  useEffect(() => {
    if (eventData?.organizators) {
      onChangeFormData(() => ({
        ...formData,
        organizator: eventData?.organizators ? [eventData?.organizators] : ['']
      }));
    }
    if (eventData?.ageLimits) {
      onChangeFormData(() => ({
        ...formData,
        classStart: eventData?.ageLimits?.[0],
        classEnd: eventData?.ageLimits?.[eventData?.ageLimits?.length - 1]
      }));
    }
  }, [eventData]);

  const createController = getCommonInputControllerFactory(onChangeFormData, onChangeFormErrors);
  const dayRangePickerControllerFactory = getDayRangePickerControllerFactory(onChangeFormData, onChangeFormErrors);

  const secondNameController = useMemo(() => createController('secondName', 'secondNameError'), [formData.id]);
  const placeController = useMemo(() => createController('place', 'placeError'), [formData.id]);
  const descriptionController = useMemo(() => createController('description'), [formData.id]);
  const actionStageController = useMemo(() => createController('actionStage'), [formData.id]);
  const resultController = useMemo(() => createController('result'), [formData.id]);

  const organizatorController = useMemo(() => ({
    handleChange: (organizator: string, index: number) => {
      onChangeFormData((prevstate) => {
        const newOrganizators = [...prevstate.organizator];
        newOrganizators[index] = organizator;
        return ({
          ...prevstate,
          organizator: newOrganizators
        });
      });
    },
    handleAddField: () => {
      onChangeFormData((prevstate) => ({
        ...prevstate,
        organizator: [...prevstate.organizator, '']
      }));
    },
    handleDeleteField: (index: number) => {
      onChangeFormData((prevstate) => {
        const newOrganizators = prevstate.organizator;
        newOrganizators.splice(index, 1);
        return ({
          ...prevstate,
          organizator: newOrganizators
        });
      });
    }
  }), [formData.id]);

  const classStartController = useMemo(() => ({
    handleChange: (classStart: string | undefined) => {
      const newClassStart = classStart ? validateParallel(classStart) : null;
      onChangeFormData((prevstate) => ({
        ...prevstate,
        classStart: newClassStart === undefined ? prevstate.classStart : newClassStart || undefined
      }));
    }
  }), [formData.id]);

  const classEndController = useMemo(() => ({
    handleChange: (classEnd: string | undefined) => {
      const newClassEnd = classEnd ? validateParallel(classEnd) : null;
      onChangeFormData((prevstate) => ({
        ...prevstate,
        classEnd: newClassEnd === undefined ? prevstate.classEnd : newClassEnd || undefined
      }));
    }
  }), [formData.id]);

  const placeNameController: IController<string | null> = useMemo(() => ({
    handleChange: (placeName) => {
      const currentPlaceAddress = placeOptions?.find((place) => place.value === placeName)?.address;
      if (currentPlaceAddress) {
        setPlaceControllerDisabled(true);
      } else {
        setPlaceControllerDisabled(false);
      }
      if (placeName) {
        onChangeFormData((prevstate) => ({
          ...prevstate,
          placeName,
          place: currentPlaceAddress || prevstate.place
        }));
      } else {
        onChangeFormData((prevstate) => ({
          ...prevstate,
          placeName: undefined
        }));
      }
      onChangeFormErrors((prevstate) => ({
        ...prevstate,
        placeNameError: !placeName,
        placeError: !currentPlaceAddress && !formData.place
      }));
    },
  }), [formData.id, formData.place, placeOptions]);

  const dateRangeController = dayRangePickerControllerFactory('startDate', 'endDate', 'dateError', { required: true });

  const eventLevelController: IController<string | undefined> = useMemo(() => ({
    handleChange: (eventLevel) => {
      onChangeFormData((prevstate) => ({
        ...prevstate,
        eventLevel: Number(eventLevel)
      }));
      onChangeFormErrors((prevstate) => ({
        ...prevstate,
        eventLevelError: false
      }));
    },
    handleBlur: (type) => {
      if (!type) {
        onChangeFormErrors((prevstate) => ({
          ...prevstate,
          eventLevelError: true
        }));
      }
    }
  }), [formData.id]);

  const institutionComponent = [
    subcategoryCultureCodes.museums,
    subcategoryCultureCodes.theatre,
    subcategoryCultureCodes.cinema
  ].includes(Number(formData.name)) ? (
    <StringAutocomplete
      inputSize={inputSize}
      inputRenderMode={inputRenderMode}
      options={newPlaceOptions}
      controller={placeNameController}
      placeholder="Начните вводить название культурного учреждения..."
      value={formData.placeName}
    />
  ) : (
    <Input
      renderMode={inputRenderMode}
      size={inputSize}
      placeholder="Введите название культурного учреждения..."
      value={formData.placeName}
      controller={placeNameController}
    />
  );

  return (
    <SecondaryBlockContainer>
      <SecondaryBlockContainer.FilesTab
        personId={currentStudent.meshId}
        controller={fileController}
        files={formData.attachment}
        {...commonAcceptFileTypes}
      />
      <SecondaryBlockContainer.LinkedObjectsTab
        studentType={studentType}
        linkedObjectController={linkedObjectController}
        linkedObjects={linkedObjects}
        formData={formData}
      />
      <SecondaryBlockContainer.GeneralTab>
      {name === DifferentValue && (
          <FormControl
            renderMode={inputRenderMode}
            inputSize={inputSize}
            label={<BaseInputLabel>Вид мероприятия</BaseInputLabel>}
            control={
              <Input
                renderMode={inputRenderMode}
                size={inputSize}
                placeholder="Введите вид мероприятия..."
                value={formData.secondName}
                controller={secondNameController}
              />
            }
            error={formErrors.secondNameError}
            helper={formErrors.secondNameError && <FormHelperText>Введите вид мероприятия</FormHelperText>}
            required
          />
        )}
        {formData.typeCode === SectionCodes.cultureVisiting && (
        <>
          <FormControl
            required
            renderMode={inputRenderMode}
            inputSize={inputSize}
            className="student-searcher-form__control"
            label={<BaseInputLabel>Культурное учреждение</BaseInputLabel>}
            control={institutionComponent}
            error={formErrors.placeNameError}
            helper={formErrors.placeNameError && <FormHelperText>Введите название культурного учреждения</FormHelperText>}
          />
          <FormControl
            renderMode={inputRenderMode}
            inputSize={inputSize}
            label={<BaseInputLabel>Место проведения</BaseInputLabel>}
            control={
              <Input
                renderMode={inputRenderMode}
                size={inputSize}
                placeholder="Введите место проведения мероприятия..."
                value={formData.place}
                controller={placeController}
              />
            }
            error={formErrors.placeError}
            helper={formErrors.placeError && <FormHelperText>Введите место проведения</FormHelperText>}
            disabled={isPlaceControllerDisabled}
            required
          />
        </>
        )}
        <FormControl
          sx={{ width: '100%' }}
          renderMode={inputRenderMode}
          inputSize={inputSize}
          label={<BaseInputLabel>Уровень мероприятия</BaseInputLabel>}
          control={
            <Select
              inputRenderMode={inputRenderMode}
              inputSize={inputSize}
              placeholder="Выберите уровень мероприятия..."
              value={formData.eventLevel}
              options={eventLevelOptions}
              controller={eventLevelController}
              strictMaxWidth
            />
          }
          error={formErrors.eventLevelError}
          helper={formErrors.eventLevelError && <FormHelperText>Выберите уровень мероприятия</FormHelperText>}
          required
        />
        <FormControl
          required
          renderMode={inputRenderMode}
          inputSize={inputSize}
          label={<BaseInputLabel>Даты проведения (дата начала или интервал)</BaseInputLabel>}
          error={formErrors.dateError.active}
          helper={formErrors.dateError.active && <FormHelperText>{formErrors.dateError.description}</FormHelperText>}
          control={
            <Box sx={mergeSx(!isMobile && { width: '100%', maxWidth: '280px' })}>
              <DayRangePicker
                isMobile={isMobile}
                renderMode={inputRenderMode}
                size={inputSize}
                startValue={formData.startDate}
                endValue={formData.endDate}
                controller={dateRangeController}
                minValue={dataEntryMinDate}
                maxValue={dataEntryMaxDate}
              />
            </Box>
          }
        />
        <FormControl
          renderMode={inputRenderMode}
          inputSize={inputSize}
          label={
            <BaseInputLabel>
              {isMobile ? 'Событие в рамках мероприятия' : 'Конкретное событие в рамках мероприятия (если есть)'}
            </BaseInputLabel>
          }
          control={
            <Input
              renderMode={inputRenderMode}
              size={inputSize}
              placeholder="Например, матч, название конкретной выставки и т.д..."
              value={formData.actionStage}
              controller={actionStageController}
            />
          }
        />
        <MultiInputControl
          isMobile={isMobile}
          inputRenderMode={inputRenderMode}
          inputSize={inputSize}
          label="Организатор мероприятия"
          placeholder="Введите название организатора мероприятия..."
          values={formData.organizator}
          controller={organizatorController}
          addFieldButtonName="Добавить ещё организатора"
        />
        <div className="portfolio-form__field">
          <AgeLimitControl
            isMobile={isMobile}
            renderMode={inputRenderMode}
            inputSize={inputSize}
            valueFrom={formData.classStart ? formData.classStart : ''}
            valueTo={formData.classEnd ? formData.classEnd : ''}
            fromController={classStartController}
            toController={classEndController}
            label={isMobile ? 'Возрастное ограничение' : 'Возрастное ограничение участников'}
            endAdornment="класс"
          />
        </div>
        <FormControl
          sx={{ width: '100%' }}
          renderMode={inputRenderMode}
          inputSize={inputSize}
          label={<BaseInputLabel>Результат (кроме награды)</BaseInputLabel>}
          control={
            <Textarea
              renderMode={inputRenderMode}
              size={inputSize}
              placeholder="Вы можете указать результат участия в мероприятии, не являющийся наградой, например, полученный балл..."
              value={formData.result}
              controller={resultController}
            />
          }
        />
        <FormControl
          sx={{ width: '100%' }}
          renderMode={inputRenderMode}
          inputSize={inputSize}
          label={<BaseInputLabel>Описание</BaseInputLabel>}
          control={
            <Textarea
              renderMode={inputRenderMode}
              size={inputSize}
              placeholder="Любое описание или примечание..."
              value={formData.description}
              controller={descriptionController}
            />
          }
        />
      </SecondaryBlockContainer.GeneralTab>
    </SecondaryBlockContainer>
  );
};

export default connect(
  (state: IRootState) => ({
    currentStudent: state.currentStudent
  })
)(CultureActivitySecondaryBlock);
