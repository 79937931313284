import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconAlertCircleOutline: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M11 8a1 1 0 0 1 2 0v5a1 1 0 0 1-2 0V8Zm0 8a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm1 4c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8Zm0-18C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconAlertCircleOutline;
