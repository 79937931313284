import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';
import { numberToPixelsString } from 'utils';

export const wrapper: SxStyles = (theme) => ({
  height: '100%',
  alignItems: 'flex-start',
  gap: 1,

  [theme.breakpoints.down('commonSm')]: {
    gap: 0.5,
  },
});

export const legend: SxStyles = (theme) => ({
  position: 'relative',
  height: '100%',
  ml: '30px', // Отступ для rotated лейбла слева
  [theme.breakpoints.down('commonSm')]: {
    ml: 0,
  },
});

export const rotatedLabel: SxStyles = (theme) => ({
  transform: 'translate(-50%, -50%) rotate(-90deg)',
  position: 'absolute',
  top: '50%',
  left: '-20px',
  color: NeutralColors.light_neutrals_600,

  [theme.breakpoints.down('commonSm')]: {
    display: 'none',
  },
});

export const chartWrapper: SxStyles = (theme) => ({
  overflowX: 'scroll',
  height: '100%',

  [theme.breakpoints.down('commonSm')]: {
    width: 'initial',
  },
});

export const chart = (intervals: number, isMobile: boolean, width: number, height: number): SxStyles => {
  const chartHeight = numberToPixelsString(height);
  const intervalWidth = isMobile ? 64 : 140;
  let scrollableWidth: number | null = null;
  // 3 - количество видимых точек на графике
  if (intervals > 3) {
    scrollableWidth = width + (intervals - 3) * intervalWidth;
  }

  return (theme) => ({
    height: '100%',
    minWidth: scrollableWidth ? `${scrollableWidth}px` : 'initial',
    // не было необходимости устанавливать высоту для контейнера
    // height: '240px',

    '& canvas': {
      height: chartHeight,
    },

    [theme.breakpoints.down('commonSm')]: {
      height: chartHeight,
      '& canvas': {
        height: chartHeight,
      },
    },
  });
};
