/* eslint-disable prettier/prettier */
/* eslint-disable */

import connectReducers from '../utils/connectReducers';

import {
  GET_OLYMPIAD_RESULTS
} from '../actions';

import {
  FAILURE,
  REQUEST,
  SUCCESS
} from '../actions/utils';

import { IDictionaryItemList } from '../api/types';

import genericReducers from './genericReducer';

import { IReducers } from '.';

export type OlympiadResultsState = IDictionaryItemList;

const initialState = {} as IDictionaryItemList;

const olympiadResults: IReducers<OlympiadResultsState> = {
  [GET_OLYMPIAD_RESULTS[REQUEST]]: genericReducers().get.request,
  [GET_OLYMPIAD_RESULTS[SUCCESS]]: genericReducers().get.success,
  [GET_OLYMPIAD_RESULTS[FAILURE]]: genericReducers().get.failure
};

export default connectReducers(initialState, olympiadResults);
