import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconBookmarkFilled: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M6 21a1 1 0 0 1-1-1V5.333C5 4.047 5.987 3 7.2 3h9.6C18.013 3 19 4.047 19 5.333V20a1.001 1.001 0 0 1-1.492.871l-5.672-3.208-5.321 3.194A1.004 1.004 0 0 1 6 21Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconBookmarkFilled;
