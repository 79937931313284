import { InputBase, styled } from '@mui/material';
import { skipPropsForwarding } from 'utils';

import { UiKitInputBaseProps } from '../types';
import { InputBaseStyles } from './InputBase.styled';

const StyledInputBase = styled(InputBase, {
  shouldForwardProp: skipPropsForwarding<UiKitInputBaseProps>(['renderMode']),
})<UiKitInputBaseProps>(InputBaseStyles);

export default StyledInputBase;
