import { FC } from 'react';

import { Box, DrawerProps } from '@mui/material';
import { mergeSx } from 'utils';

import DragIndicator from '../DragIndicator';
import DrawerCross from '../DrawerCross';
import { IDrawerBase } from '../types';
import * as styles from './style';

const DrawerBase: FC<IDrawerBase & DrawerProps> = ({
  header,
  footer,
  isMobile,
  sx,
  indicatorContainerSx,
  crossSx,
  contentSx,
  children,
  indicatorSize = 'small',
  onClose,
}) => {
  const crossButton = (
    <DrawerCross sx={mergeSx(styles.drawerClose, crossSx)} onClick={() => onClose?.({}, 'backdropClick')} />
  );

  return (
    <Box className="drawer-base" sx={mergeSx(styles.drawer, sx)}>
      {isMobile && (
        <Box className="drawer-indicator" sx={mergeSx(styles.dragIndicator, indicatorContainerSx)}>
          <DragIndicator size={indicatorSize} />
        </Box>
      )}
      {/* header */}
      <Box className="drawer-header">{typeof header === 'function' ? header(crossButton) : header}</Box>
      {/* content */}
      <Box className="drawer-content" sx={mergeSx(styles.content, contentSx)}>
        {children}
      </Box>
      {/* footer */}
      <Box className="drawer-footer">{footer}</Box>
    </Box>
  );
};

export default DrawerBase;
