import { FC, PropsWithChildren } from 'react';

import { Box } from '@mui/material';
import clsx from 'clsx';
import { SxStyles } from 'types';
import { mergeSx, numberToPixelsString } from 'utils';

interface IPaddingContainerProps {
  backgroundColor?: string;
  padding?: number;
  sx?: SxStyles;
  className?: string;
}

/**
 * Компонент служит для добавления отступов по краям контента
 */
const PaddingContainer: FC<IPaddingContainerProps & PropsWithChildren> = ({
  backgroundColor,
  padding,
  sx,
  className,
  children,
}) => {
  const usePadding = padding !== undefined;

  const baseStyles: SxStyles = (theme) => {
    return {
      paddingInline: usePadding ? numberToPixelsString(padding) : '20px',
      backgroundColor,

      [theme.breakpoints.down('commonSm')]: {
        paddingInline: usePadding ? numberToPixelsString(padding) : '12px',
      },
    };
  };

  return (
    <Box sx={mergeSx(baseStyles, sx)} className={clsx(className, 'padding-container')}>
      {children}
    </Box>
  );
};

export default PaddingContainer;
