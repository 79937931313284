import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ICommonResponse } from 'api/types';
import { useAppSelector } from 'hooks';
import useClientWidth from 'hooks/useClientWidth';
import { getYMEventPayload } from 'portfolio3/features/yandexMetrika';
import { isDefined } from 'utils';

import { PerformanceChart, PerformanceDiagram, PerformanceTiles } from '../../../../components/redesignStudent';
import SubjectThemesDrawer from '../components/SubjectThemesDrawer';
import { getStudentSubjectThemesActions } from '../model/actions';
import { IMappedLearningYearPerformance, VisibilityType } from '../types';
import { sortPerformanceByLearningYear } from '../utils';

interface IPerformanceContainerProps {
  currentPerformance: ICommonResponse<IMappedLearningYearPerformance>;
  currentVisibilityType: VisibilityType;
  currentLearningYear: string | null;
}

const PerformanceContainer: React.FC<IPerformanceContainerProps> = ({
  currentPerformance,
  currentVisibilityType,
  currentLearningYear,
}) => {
  const dispatch = useDispatch();
  const currentStudent = useAppSelector((state) => state.currentStudent);
  const subjectThemesCache = useAppSelector((state) => state.studentSubjectThemesCache);

  const [selectedSubjectId, setSelectedSubjectId] = useState<number | undefined>(undefined);
  const [isSubjectThemesDrawerOpen, setSubjectsThemesDrawerOpen] = useState(false);
  const clientWidth = useClientWidth();

  const sortedChartPerformance = sortPerformanceByLearningYear(currentPerformance.content, 'asc');

  // данные за текущий год
  const currentYearPerformance = currentPerformance.content.find(
    (performance) => performance.year === currentLearningYear,
  );

  const handleCloseMobileDetails = useCallback(() => {
    setSubjectsThemesDrawerOpen(false);
  }, []);

  const handleChangeActiveSubject = (subjectId: number) => {
    if (subjectId === selectedSubjectId) return;

    setSelectedSubjectId(subjectId);

    dispatch(getStudentSubjectThemesActions.invalidate());
    const isCached = isDefined(subjectThemesCache[subjectId]);

    if (subjectId && !isCached) {
      const subjectName = currentYearPerformance?.subjects.find((subject) => subject.id === subjectId)?.name;
      const ymMeta = getYMEventPayload({ type: 'subjectChoosingStudy', payload: { subject: subjectName ?? '' } });

      dispatch(getStudentSubjectThemesActions.request(currentStudent.meshId, subjectId, ymMeta));
    }
  };

  // if (!hasCurrentYearPerformance) {
  //   return (
  //     <NoDataPlug
  //       image={placeholderGeometry}
  //       title="Данных по предметным результатам за выбранный период пока нет"
  //       loading={isLoading}
  //     />
  //   );
  // }

  return (
    <>
      {currentVisibilityType === VisibilityType.DIAGRAM && (
        <PerformanceDiagram
          yearPerformance={currentYearPerformance}
          selectedSubjectId={selectedSubjectId}
          onSelectSubject={handleChangeActiveSubject}
        />
      )}
      {currentVisibilityType === VisibilityType.TILES && (
        <PerformanceTiles
          yearPerformance={currentYearPerformance}
          selectedSubjectId={selectedSubjectId}
          onSelectSubject={handleChangeActiveSubject}
        />
      )}
      {clientWidth < 767 && selectedSubjectId && (
        <SubjectThemesDrawer
          isOpen={isSubjectThemesDrawerOpen}
          yearPerformance={currentYearPerformance}
          selectedSubjectId={selectedSubjectId}
          onClose={handleCloseMobileDetails}
        />
      )}
      {currentVisibilityType === VisibilityType.CHART && <PerformanceChart performance={sortedChartPerformance} />}
    </>
  );
};

export default PerformanceContainer;
