import { IIndependentDiagnosticGeneralRating } from 'api/types';
import { DiagnosticRatingRegionCodes } from 'const';
import { getRandomInt, roundNumberToTenths } from 'utils';

import { IChartRow } from '../components/RatingChart/ChartRow';
import {
  Bear,
  Cat,
  Dog,
  Fox,
  Hamster,
  Koala,
  Lion,
  Monkey,
  Mouse,
  Panda,
  Rabbit,
  Racoon,
  Tiger,
} from '../images/avatars';

const avatarPool = [Bear, Fox, Koala, Lion, Monkey, Mouse, Panda, Rabbit, Tiger, Racoon, Cat, Hamster, Dog];

export const useAllRating = (
  ratingObject: IIndependentDiagnosticGeneralRating,
  selectedLocation: DiagnosticRatingRegionCodes,
) => {
  let key: keyof IIndependentDiagnosticGeneralRating | null = null;

  if (selectedLocation === DiagnosticRatingRegionCodes.Region) key = 'generalRatingRegion';
  if (selectedLocation === DiagnosticRatingRegionCodes.School) key = 'generalRatingSchool';
  if (selectedLocation === DiagnosticRatingRegionCodes.Class) key = 'generalRatingClass';

  const generalRating = ratingObject[key as keyof IIndependentDiagnosticGeneralRating];

  if (!generalRating || typeof generalRating === 'string') {
    return [];
  }

  const rows = generalRating.allPlaces?.map((percent, index): IChartRow => {
    const place = index + 1;
    const yours = place === generalRating.placeGeneralRatingStudent;

    const roundedPercent = roundNumberToTenths(percent);
    const text = `${roundedPercent} %`;

    return {
      placeBarProps: {
        yours,
        percent,
        place,
        Emoji: avatarPool[getRandomInt(0, avatarPool.length - 1)],
        text,
      },
    };
  });

  return rows ?? [];
};
