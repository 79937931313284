import { GiaType } from 'const';
import { createSelector } from 'reselect';

import { IRootState } from '../../../reducers';

const studyTrialExamSelector = createSelector([(state: IRootState) => state.studentGiaList], (studentGiaList) => {
  const trialEgeExams = studentGiaList.content.filter((exam) => exam.formaGia === GiaType.ege && exam.approbation);

  return { ...studentGiaList, content: trialEgeExams };
});

export default studyTrialExamSelector;
