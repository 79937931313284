/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';

import {
  CREATE_STUDENT_SHARE_LINK
} from '../../actions';

import {
  FAILURE,
  REQUEST,
  SUCCESS
} from '../../actions/utils';

import { IShareLinkResponse } from '../../api/types';

import genericReducers from '../genericReducer';

import { IReducers } from '..';

export type StudentShareLinkState = IShareLinkResponse;

const initialState = {} as IShareLinkResponse;

const studentShareLink: IReducers<StudentShareLinkState> = {
  [CREATE_STUDENT_SHARE_LINK[REQUEST]]: genericReducers().get.request,
  [CREATE_STUDENT_SHARE_LINK[SUCCESS]]: genericReducers().get.success,
  [CREATE_STUDENT_SHARE_LINK[FAILURE]]: genericReducers().get.failure
};

export default connectReducers(initialState, studentShareLink);
