/* eslint-disable max-len */
import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconCivilContestsCommon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.9 8.6 3.1 7l1.2 1.6 1.8.6L5 10.8v2l-1.9-.6-1.9.6v-2L0 9.2l1.9-.6ZM6.2 16H0v8h6.2v-8ZM10.798 1.6l1.2-1.6 1.2 1.6 1.9.6-1.2 1.7v1.9l-1.9-.6-1.9.6V3.9l-1.2-1.7 1.9-.6ZM15.098 9.4h-6.2V24h6.2V9.4ZM19.698 5.7l1.2-1.6 1.2 1.6 1.9.6-1.2 1.6v2l-1.9-.6-1.9.6v-2l-1.1-1.6 1.8-.6ZM24 12.9h-6.2V24H24V12.9Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconCivilContestsCommon;
