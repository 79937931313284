import { SxStyles } from 'types';

export const secondaryBlockMobileContainer: SxStyles = {
  borderRadius: '12px',
  backgroundColor: '#ffffff',
  paddingBlock: '8px 16px',
  paddingInline: '12px',
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
};

// эти стили нужны для корректного отображения таба linkedObjects, с прижиманием инофобокса к низу блока
export const secondaryBlockLinkedTab: SxStyles = {
  height: '100%',
  overflow: 'auto',
};
