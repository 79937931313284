import { IAffilationKind, IRewardKind, SectionRefItem } from 'api/types';
import { IPortfolioEntryFormData, MappedEventData } from 'portfolio3/features/dataEntryForm';
import { isDefined } from 'utils';

type CommonProperties = {
  name?: string;
  typeName?: string;
  attributes?: string[];
};

export const getCommonAffilationProperties = (
  affilationKinds: IAffilationKind[],
  sections: SectionRefItem[],
  formData: IPortfolioEntryFormData,
): CommonProperties => {
  const affilationName = affilationKinds.find((affilationKind) => affilationKind.code === formData.name)?.value;
  const affilationType = sections.find((section) => section.code === formData.typeCode)?.value;

  return {
    name: affilationName,
    typeName: 'Принадлежность',
    attributes: [affilationType].filter(isDefined),
  };
};

export const getCommonOccupationProperties = (
  sections: SectionRefItem[],
  formData: IPortfolioEntryFormData,
): CommonProperties => {
  const occupationName = sections.find((section) => section.code === formData.typeCode)?.value;

  return {
    name: occupationName,
    typeName: 'Занятие',
  };
};

export const getCommonEventProperties = (eventData: MappedEventData): CommonProperties => {
  return {
    name: eventData.name,
    typeName: 'Мероприятие',
    attributes: [eventData.type, eventData.levelEvent, eventData.ageLimit].filter(isDefined),
  };
};

export const getCommonRewardProperties = (
  rewardKinds: IRewardKind[],
  sections: SectionRefItem[],
  formData: IPortfolioEntryFormData,
): CommonProperties => {
  const rewardName = rewardKinds.find((reward) => reward.code === formData.name)?.value;
  const rewardType = sections.find((section) => section.code === formData.typeCode)?.value;

  return {
    name: rewardName,
    typeName: 'Награда',
    attributes: [rewardType].filter(isDefined),
  };
};
