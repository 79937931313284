/* eslint-disable max-len */
import React from 'react';

const IconDiploma_3: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_10155_224361)">
      <path
        d="M41.9256 0H8.09225C6.52036 0 5.24609 1.27427 5.24609 2.84615V47.1538C5.24609 48.7257 6.52036 50 8.09225 50H41.9256C43.4975 50 44.7717 48.7257 44.7717 47.1538V2.84615C44.7717 1.27427 43.4975 0 41.9256 0Z"
        fill="#F2935F"
      />
      <path
        d="M39.2024 6.23693C38.2217 4.71129 38.446 3.02539 38.446 3.02539H11.5742C11.5742 3.02539 11.7986 4.71129 10.8178 6.23693C9.80499 7.80744 7.82422 7.94206 7.82422 7.94206V42.051C7.82422 42.051 9.80499 42.1856 10.8178 43.7562C11.7986 45.2818 11.5742 46.9677 11.5742 46.9677H38.4524C38.4524 46.9677 38.2281 45.2818 39.2088 43.7562C40.2217 42.1856 42.2024 42.051 42.2024 42.051V7.94206C42.2024 7.94206 40.2217 7.80744 39.2088 6.23693H39.2024Z"
        fill="#BA6445"
      />
      <mask
        id="mask0_10155_224361"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="7"
        y="3"
        width="36"
        height="44"
      >
        <path
          d="M39.2005 6.23693C38.2197 4.71129 38.4441 3.02539 38.4441 3.02539H11.5723C11.5723 3.02539 11.7966 4.71129 10.8159 6.23693C9.80304 7.80744 7.82227 7.94206 7.82227 7.94206V42.051C7.82227 42.051 9.80304 42.1856 10.8159 43.7562C11.7966 45.2818 11.5723 46.9677 11.5723 46.9677H38.4505C38.4505 46.9677 38.2261 45.2818 39.2069 43.7562C40.2197 42.1856 42.2005 42.051 42.2005 42.051V7.94206C42.2005 7.94206 40.2197 7.80744 39.2069 6.23693H39.2005Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_10155_224361)">
        <path d="M43.7534 6.27539H6.25977V7.93565H43.7534V6.27539Z" fill="#E83F40" />
        <path d="M43.7534 4.62158H6.25977V6.28184H43.7534V4.62158Z" fill="#748FFC" />
        <path d="M43.7534 3.02539H6.25977V4.68565H43.7534V3.02539Z" fill="white" />
      </g>
      <path
        d="M27.3036 30.3008L19.5857 42.3457L18.4511 37.3072L13.6113 38.4867L21.6947 26.5957L27.3036 30.3008Z"
        fill="#F2935F"
      />
      <path
        d="M21.8164 30.3013L29.5344 42.3462L30.669 37.3077L35.5087 38.4872L27.4254 26.5962L21.8164 30.3013Z"
        fill="#F2935F"
      />
      <path
        d="M21.3416 11.9998C23.1878 9.25617 27.2519 9.35232 28.9698 12.1728L29.0211 12.2626C32.2968 11.6664 35.1173 14.6216 34.3737 17.8651L34.3481 17.9677L34.4314 18.0254C37.1557 19.9421 37.0404 24.019 34.207 25.769L34.2263 25.8716C34.8416 29.1216 31.8801 31.91 28.675 31.1023C26.8288 33.8459 22.7647 33.7498 21.0468 30.9292L20.9955 30.8395C17.7198 31.4357 14.8993 28.4805 15.6429 25.2369L15.6686 25.1344L15.5852 25.0767C12.8609 23.16 12.9763 19.0831 15.8096 17.3331L15.7904 17.2305C15.175 13.9805 18.1365 11.1921 21.3416 11.9998Z"
        fill="#F2935F"
      />
      <path
        d="M33.8798 29.3395C32.9567 30.8844 31.0913 31.8075 29.1298 31.3203L28.7708 31.2306L28.5657 31.5382C26.8157 34.1472 22.9503 34.0575 21.3221 31.3716L21.0785 30.9677L20.5528 31.0639C18.7131 31.3972 17.0336 30.5254 16.1426 29.1216"
        stroke="#BA6445"
        strokeWidth="0.641026"
        strokeMiterlimit="10"
      />
      <path
        d="M22.0144 13.7498L22.3092 13.3139C23.681 11.2755 26.7003 11.346 27.9759 13.4421L28.29 13.9614L28.8028 13.8652C31.2387 13.4229 33.3285 15.6152 32.7772 18.0255L32.6426 18.6152L33.1362 18.9614C35.1618 20.3844 35.0721 23.4101 32.9695 24.7114L32.5272 24.987L32.6426 25.5832C33.0977 27.9934 30.899 30.0703 28.5208 29.4678L28.008 29.3396L27.7131 29.7755C26.3413 31.8139 23.3221 31.7434 22.0464 29.6473L21.7323 29.128L21.2195 29.2242C18.7836 29.6665 16.6939 27.4742 17.2451 25.0639L17.3797 24.4742L16.8862 24.128C14.8605 22.705 14.9503 19.6793 17.0528 18.378L17.4951 18.1024L17.3797 17.5062C16.9246 15.096 19.1233 13.0191 21.5015 13.6216L22.0144 13.7498Z"
        stroke="#BA6445"
        strokeWidth="0.641026"
        strokeMiterlimit="10"
      />
      <path d="M22.8299 17.4995V25.4611H21.1504V17.4995H22.8299Z" fill="#BA6445" />
      <path d="M25.9002 17.4995V25.4611H24.2207V17.4995H25.9002Z" fill="#BA6445" />
      <path d="M28.9705 17.4995V25.4611H27.291V17.4995H28.9705Z" fill="#BA6445" />
      <path
        d="M9.90039 10V40C9.90039 40 11.644 40.1218 12.5286 41.5C13.394 42.8397 13.1889 44.3205 13.1889 44.3205H36.8235C36.8235 44.3205 36.6247 42.8397 37.4837 41.5C38.3747 40.1218 40.1119 40 40.1119 40V10"
        stroke="#F2935F"
        strokeWidth="0.641026"
        strokeMiterlimit="10"
      />
      <mask
        id="mask1_10155_224361"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="5"
        y="0"
        width="40"
        height="50"
      >
        <path
          d="M41.9275 0H8.0942C6.52231 0 5.24805 1.27427 5.24805 2.84615V47.1538C5.24805 48.7257 6.52231 50 8.0942 50H41.9275C43.4994 50 44.7737 48.7257 44.7737 47.1538V2.84615C44.7737 1.27427 43.4994 0 41.9275 0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_10155_224361)">
        <path
          opacity="0.3"
          d="M3.45358 28.5702C26.1504 28.5702 44.5497 10.1709 44.5497 -12.5259C44.5497 -35.2227 26.1504 -53.6221 3.45358 -53.6221C-19.2432 -53.6221 -37.6426 -35.2227 -37.6426 -12.5259C-37.6426 10.1709 -19.2432 28.5702 3.45358 28.5702Z"
          fill="url(#paint0_linear_10155_224361)"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_10155_224361"
        x1="-10.9887"
        y1="-39.59"
        x2="20.8254"
        y2="20.0318"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0" />
        <stop offset="0.35" stopColor="white" stopOpacity="0" />
        <stop offset="0.49" stopColor="white" stopOpacity="0.03" />
        <stop offset="0.59" stopColor="white" stopOpacity="0.08" />
        <stop offset="0.67" stopColor="white" stopOpacity="0.15" />
        <stop offset="0.74" stopColor="white" stopOpacity="0.24" />
        <stop offset="0.8" stopColor="white" stopOpacity="0.35" />
        <stop offset="0.85" stopColor="white" stopOpacity="0.47" />
        <stop offset="0.9" stopColor="white" stopOpacity="0.62" />
        <stop offset="0.95" stopColor="white" stopOpacity="0.79" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <clipPath id="clip0_10155_224361">
        <rect width="50" height="50" fill="white" transform="translate(0.00976562)" />
      </clipPath>
    </defs>
  </svg>
);

export default IconDiploma_3;
