/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useEffect, useMemo, useState } from 'react';
import {
  FormControlLabel, FormControl, Box
} from '@mui/material';

import { IPopoverPositioningProps } from '../CommonSelect/types';

import { IconSearch } from '../../../../icons';

import { Checkbox, Input, Select } from '../../../../ui-kit';
import { IDictionaryItem } from '../../../../api/types';

import './index.scss';

interface IMultipleSearchSelectProps {
  placeholder: string;
  options: IDictionaryItem[];
  searchController: any;
  label: string;
  cutOutValue?: string;
  isNotClearable?: boolean;
  hasGlobalOption?: boolean;
  value: string | undefined;
  onClose?: () => void;
}

const MultipleSearchSelect: React.FC<IMultipleSearchSelectProps> = ({
  placeholder,
  options,
  searchController,
  label,
  cutOutValue,
  isNotClearable,
  hasGlobalOption,
  value,
  onClose
}) => {
  const optionCodes = options.map((option) => String(option.code));
  const [unSelectedOptions, setUnSelectedOptions] = useState<string[]>(optionCodes.filter((option) => !value?.split(',').includes(option)));
  const [selectedOptions, setSelectedOptions] = useState<string[]>(value?.split(',').filter((currentValue) => (optionCodes.includes(currentValue))) || []);
  const [searchedOptions, setSearchedOptions] = useState<string[]>([]);
  const [isAnyOptionExist, setAnyOptionExist] = useState(true);
  const [isOpenSelectPopover, setOpenSelectPopover] = useState(false);
  const [searchValue, setSearchValue] = useState<string>();

  const popoverPositioning: IPopoverPositioningProps = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left'
    }
  };

  useEffect(() => {
    if (!options) return;
    setSelectedOptions(value?.split(',').filter((currentValue) => (optionCodes.includes(currentValue))) || []);
    setUnSelectedOptions(optionCodes.filter((option) => !value?.split(',').includes(option)));
  }, [options]);

  useEffect(() => {
    if (!isOpenSelectPopover) {
      setSearchValue('');
      setSearchedOptions([]);
      setAnyOptionExist(true);
    }
  }, [isOpenSelectPopover]);

  useEffect(() => {
    if (searchValue === undefined) return;
    const resultsOfSearching: string[] = [];

    const searchProcess = () => {
      if (searchValue.length > 0) {
        unSelectedOptions.forEach((box, i) => {
          const boxOption = options.find((option) => String(option.code) === box)?.value;
          if (boxOption && boxOption.toLowerCase().includes(searchValue.toLowerCase())) {
            resultsOfSearching.push(unSelectedOptions[i]);
            setSearchedOptions([...resultsOfSearching]);
          }
        });
      } else {
        setSearchedOptions([]);
      }
    };

    searchProcess();

    if (resultsOfSearching.length === 0 && searchValue.length > 0) setAnyOptionExist(false);
    else setAnyOptionExist(true);
  }, [searchValue, isOpenSelectPopover]);

  useEffect(() => {
    const newValue = selectedOptions.join(',');
    if (newValue !== value) searchController.handleChange(newValue);
  }, [selectedOptions]);

  /*  handleSelectOption - удаляет из unSelectedOptions, выбранный пользователем option, и добавляет его в selectedOptions,
    а также удаляет его из searchedOptions. Массивы unSelectedOptions и selectedOptions нужны для того, чтобы перекладывать выбранные
    options из одного состояния в другое. */

  const handleSelectOption = (option: string) => {
    setSelectedOptions((prev) => [...prev, option]);
    setUnSelectedOptions((prev) => prev.filter((currentElName) => currentElName !== option));
    setSearchedOptions((prev) => {
      const newSearchedOptions = prev.filter((currentElName) => currentElName !== option);
      if (newSearchedOptions.length === 0 && searchValue) setAnyOptionExist(false);
      else setAnyOptionExist(true);
      return newSearchedOptions;
    });
  };

  //  handleRemoveOption - обратное действие handleSelectOption.

  const handleRemoveOption = (option: string) => {
    if (selectedOptions.length === 1 && isNotClearable) return;
    setUnSelectedOptions((prev) => [...prev, option]);
    setSelectedOptions((prev) => prev.filter((currentElName) => currentElName !== option));
    setSearchedOptions([...unSelectedOptions, option].filter((filteredOption) => (searchValue ? filteredOption.includes(searchValue) : true)));
    setAnyOptionExist(true);
  };

  /*  Массив searchedOptions нужен для того, чтобы при поиске options произошла подмена вывода unSelectedOptions
    на searchedOptions. Это нужно для того момента, когда поле поиска пустое и нужно вернуть все невыбранные options, то есть unSelectedOptions. */

  const handleOpen = () => setOpenSelectPopover(true);
  const handleClose = () => {
    setOpenSelectPopover(false);
    onClose?.();
  };

  const getValue = () => {
    if (!selectedOptions[0]) return ['placeholder'];
    return selectedOptions;
  };

  const selectedOptionValues = useMemo(() => selectedOptions.map((selectedOption) => {
    const currentOption = options.find((option) => String(option.code) === selectedOption);
    return currentOption;
  }), [selectedOptions]);

  const searchedOptionValues = useMemo(() => searchedOptions.map((selectedOption) => {
    const currentOption = options.find((option) => String(option.code) === selectedOption);
    return currentOption;
  }), [searchedOptions]);

  const unSelectedOptionValues = useMemo(() => unSelectedOptions.map((selectedOption) => {
    const currentOption = options.find((option) => String(option.code) === selectedOption);
    return currentOption;
  }), [unSelectedOptions]);

  return (
    <div className="multiple-search-select">
      <FormControl>
        <Select
          multiple
          value={getValue()}
          open={isOpenSelectPopover}
          onOpen={handleOpen}
          onClose={handleClose}
          MenuProps={{
            anchorOrigin: popoverPositioning.anchorOrigin,
            transformOrigin: popoverPositioning.transformOrigin,
            PaperProps: {
              style: {
                borderRadius: '8px'
              }
            },
            // getContentAnchorEl: null
          }}
          renderValue={(selectedValues: any) => {
            if (selectedValues[0] === 'placeholder') return <span className="multiple-search-select__placeholder">{placeholder}</span>;
            if (selectedValues.length === options.length) return `Все ${label}`;
            const renderValue = selectedValues.map((value: string) => {
              const selectedOption = options.find((option) => String(option.code) === value);
              return ` ${selectedOption?.value.replace(` ${cutOutValue}`, '')}`;
            });
            return `${renderValue} ${cutOutValue ? label : ''}`;
          }}
        >
          <Input
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Поиск..."
            value=""
            endAdornment={<IconSearch fill="#231F20" width="15" height="15" />}
            className="multiple-search-select__input"
          />
          {hasGlobalOption && (
          <div className="multiple-search-select__checkboxes--checked">
            <FormControlLabel

              control={(
                <Checkbox
                  checked={options.length === selectedOptions.length}
                  onClick={() => {
                    if (options.length !== selectedOptions.length) {
                      setSelectedOptions(optionCodes);
                      setUnSelectedOptions([]);
                      setSearchedOptions([]);
                    } else {
                      setSelectedOptions([]);
                      setUnSelectedOptions(optionCodes);
                      setSearchValue('');
                    }
                    setAnyOptionExist(true);
                  }}
                />
                )}
              label={`Все ${label}`}
            />
          </div>
          )}
          <Box className="multiple-search-select__scroll-box" sx={{ maxHeight: '616px', overflow: 'scroll' }}>
            { selectedOptionValues.length > 0
              && (
                <div className="multiple-search-select__checkboxes--checked">
                  {selectedOptionValues.sort((a, b) => Number(a?.code) - Number(b?.code)).map((selectedOption) => selectedOption && <FormControlLabel onClick={() => handleRemoveOption(String(selectedOption.code))} key={selectedOption.code} control={<Checkbox checked />} label={`${selectedOption.value}`} />)}
                </div>
              )}
            { isAnyOptionExist
              ? (
                <div className="multiple-search-select__checkboxes--unchecked">
                  {searchedOptionValues[0] ? searchedOptionValues.sort((a, b) => Number(a?.code) - Number(b?.code)).map((searchedOption) => searchedOption && <FormControlLabel onClick={() => handleSelectOption(String(searchedOption.code))} key={searchedOption.code} control={<Checkbox />} label={`${searchedOption.value}`} />)
                    : unSelectedOptionValues.sort((a, b) => Number(a?.code) - Number(b?.code)).map((unSelectedOption) => unSelectedOption && <FormControlLabel onClick={() => handleSelectOption(String(unSelectedOption.code))} key={unSelectedOption.code} control={<Checkbox />} label={`${unSelectedOption.value}`} />)}
                </div>
              ) : <div className="multiple-search-select_no-results">Ничего не найдено</div>}
          </Box>
          </Select>
      </FormControl>
    </div>
  );
};

export default MultipleSearchSelect;
