import { FC } from 'react';

import { styled, TextareaAutosizeProps } from '@mui/material';
import { skipPropsForwarding } from 'utils';

import { InputRenderMode } from '../../types';
import InputBase from './InputBase';

interface ITextAreaProps extends TextareaAutosizeProps {
  renderMode: InputRenderMode;
}

const StyledTextarea = styled(InputBase, {
  shouldForwardProp: skipPropsForwarding<ITextAreaProps>(['renderMode']),
})(() => {
  return {
    '& textarea': {
      minHeight: '62px',
      backgroundColor: 'transparent',

      resize: 'vertical',
    },
  };
});

const TextareaBase: FC<ITextAreaProps> = ({ renderMode, ...props }) => {
  return (
    <StyledTextarea renderMode={renderMode} disabled={props.disabled} multiline maxRows={Infinity} inputProps={props} />
  );
};

export default TextareaBase;
