import { SxStyles } from 'types';

export const root: SxStyles = {
  display: 'flex',
  alignItems: 'center',
};

export const name: SxStyles = {
  marginLeft: '8px',
};
