import { FC, useContext } from 'react';

import { IconPlus } from 'icons/edit';
import { WidgetDataContext } from 'portfolio3/components/common/WidgetContainer';
import { emitYMEvent } from 'portfolio3/features/yandexMetrika';
import { Button } from 'portfolio3/ui-kit/button';

interface IWidgetContainerAddButtonProps {
  isMobile: boolean;
  isReward: boolean;
  onClick: () => void;
}

export const WidgetContainerAddButton: FC<IWidgetContainerAddButtonProps> = ({ isMobile, isReward, onClick }) => {
  const { widgetLabel } = useContext(WidgetDataContext);

  const handleClick = () => {
    onClick();

    if (isReward) {
      emitYMEvent({ type: 'rewardAdding' });
    } else {
      emitYMEvent({
        type: 'addBlockClickButton',
        payload: {
          Subsections: widgetLabel ?? '',
        },
      });
    }
  };

  if (isMobile) {
    return (
      <Button size="small" onlyIcon onClick={handleClick}>
        <IconPlus />
      </Button>
    );
  }

  return (
    <Button size="medium" iconLeft={<IconPlus />} onClick={handleClick}>
      Добавить
    </Button>
  );
};

export default WidgetContainerAddButton;
