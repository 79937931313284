import { FC } from 'react';

import { createTheme, formControlLabelClasses, Stack, ThemeProvider } from '@mui/material';
import { getAccentColor } from 'portfolio3/styles';
import { commonTheme } from 'portfolio3/styles/theme';
import { BaseToggleLabel, Checkbox } from 'portfolio3/ui-kit';

import { IChartSubject } from './types';

interface ISubjectListProps {
  isMobile: boolean;
  chartSubjects: IChartSubject[];
  checkedSubjects: string[];
  onChange: (subject: string) => () => void;
}

const SubjectList: FC<ISubjectListProps> = ({ isMobile, chartSubjects, checkedSubjects, onChange }) => {
  return (
    <Stack
      sx={{
        overflowY: 'auto',
        gap: 1.5,
        maxHeight: isMobile ? '148px' : 'initial',
      }}
    >
      {chartSubjects.map((subject) => {
        const { name, color } = subject;
        const isChecked = checkedSubjects.includes(name);

        return (
          <Stack key={name} gap={1.5} alignItems="center" direction="row">
            <ThemeProvider
              theme={createTheme(commonTheme, {
                palette: {
                  accentColor: color,
                },
              })}
            >
              <BaseToggleLabel
                size={isMobile ? 'small' : 'medium'}
                sx={
                  isChecked
                    ? {
                        [`& .${formControlLabelClasses.label}`]: {
                          color: getAccentColor(color, '100'),
                        },
                      }
                    : null
                }
                label={name}
                control={
                  <Checkbox
                    disabled={checkedSubjects.length >= 5 && !isChecked}
                    size={isMobile ? 'small' : 'medium'}
                    checked={isChecked}
                    onChange={onChange(name)}
                  />
                }
              />
            </ThemeProvider>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default SubjectList;
