/* eslint-disable max-len */
import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconProfileGoalsCommon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M21.193 8.94a1.5 1.5 0 0 1-1.285.47l-.843-.095c.412.977.642 2.06.642 3.19 0 4.524-3.68 8.204-8.203 8.204-4.524 0-8.204-3.68-8.204-8.203 0-4.524 3.68-8.204 8.204-8.204 1.14 0 2.233.24 3.22.662l-.077-.882c-.029-.355.096-.7.345-.958l.383-.384a10.392 10.392 0 0 0-3.871-.747C5.696 1.993 1 6.698 1 12.496S5.706 23 11.504 23c5.798 0 10.503-4.706 10.503-10.504 0-1.313-.249-2.558-.69-3.718l-.134.144.01.019Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="m11.082 9.497 2.1-2.07a5.354 5.354 0 0 0-7.025 5.089 5.354 5.354 0 0 0 5.357 5.357 5.354 5.354 0 0 0 5.357-5.358c0-.575-.096-1.14-.269-1.658l-2.08 2.1a3.056 3.056 0 0 1-3.018 2.616 3.06 3.06 0 0 1-3.057-3.058 3.057 3.057 0 0 1 2.635-3.018Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="m22.677 4.954-1.706-.278.029-.029a1.151 1.151 0 1 0-1.63-1.629l-.028.029-.278-1.716c-.067-.325-.47-.44-.71-.21l-2.386 2.386c-.23.23-.335.546-.297.872l.259 2.09-5.233 5.222a1.151 1.151 0 1 0 1.63 1.63l5.242-5.233 2.06.259c.316.038.642-.067.872-.297l2.387-2.387c.24-.23.115-.632-.211-.709Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconProfileGoalsCommon;
