import { FC } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { IconFileFilled } from 'icons';
import { IconClose } from 'icons/edit';
import { getAccentColor, NeutralColors } from 'portfolio3/styles';
import { UnstyledButton } from 'portfolio3/ui-kit';
import { mergeSx } from 'utils';

import { IAttachmentError } from '../types';
import * as styles from './styles';

const getFileSize = (size: number) => {
  if (size > 1000) {
    return `${Math.round(size / 1000)} кБ`;
  }
  if (size > 1000000) {
    return `${Math.round(size / 1000000)} МБ`;
  }
  return `${size} Б`;
};

interface IExcelFileProps {
  file: File;
  attachmentError: IAttachmentError;
  onClearFile: () => void;
}

const ExcelFile: FC<IExcelFileProps> = ({ file, attachmentError, onClearFile }) => {
  const fileSizeValue = getFileSize(file.size);

  return (
    <>
      <Box className="excel-file" sx={mergeSx(styles.root, attachmentError.active && styles.rootError)}>
        <Box sx={styles.info}>
          <Box sx={styles.iconContainer}>
            <IconFileFilled />
          </Box>
          <Stack>
            <Typography variant="Paragraph MD/Medium">{file.name}</Typography>
            <Typography variant="Paragraph MD/Regular" color={NeutralColors.light_neutrals_600}>
              {fileSizeValue}
            </Typography>
          </Stack>
        </Box>
        <UnstyledButton sx={styles.deleteButton} onClick={onClearFile}>
          <IconClose />
        </UnstyledButton>
      </Box>

      {attachmentError.active && (
        <Typography variant="Paragraph MD/Regular" color={getAccentColor('red', '100')} marginTop="12px">
          {attachmentError.text}
        </Typography>
      )}
    </>
  );
};

export default ExcelFile;
