import { SxStyles } from 'types';

export const root: SxStyles = {
  position: 'relative',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const value: SxStyles = {
  position: 'absolute',
};
