import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root: SxStyles = {
  overflow: 'hidden',

  '.form-entity-info__tags': {
    opacity: 1,
    transition: 'opacity 0.1s',
  },

  '.form-entity-info__actions': {
    position: 'absolute',
    top: 0,
    right: 0,

    opacity: 0,
    pointerEvents: 'none',
    transition: 'opacity 0.1s',
  },

  '&:hover': {
    '.form-entity-info__tags': {
      opacity: 0,
      pointerEvents: 'none',
    },
    '.form-entity-info__actions': {
      opacity: 1,
      pointerEvents: 'all',
    },
  },

  '.form-entity-info__actions:focus-within': {
    opacity: 1,
    pointerEvents: 'all',

    '& ~ .form-entity-info__tags': {
      opacity: 0,
      pointerEvents: 'none',
    },
  },
};

export const tagsContainer: SxStyles = {
  alignSelf: 'flex-start',

  position: 'relative',
  // высота тегов, необходима для корректного отображения кнопок действий, когда тегов нет
  height: '32px',
};

export const title: SxStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
};

export const titleName: SxStyles = {
  flexGrow: 1,
};

export const attributes: SxStyles = {
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: '8px',

  marginTop: '4px',
};

export const attributeDivider: SxStyles = {
  width: '4px',
  height: '4px',

  borderRadius: '50%',
  backgroundColor: NeutralColors.light_neutrals_400,
};

export const related: SxStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  paddingBlock: '8px',

  borderTop: '1px solid',
  borderColor: NeutralColors.light_neutrals_300,

  svg: {
    width: '20px',
    height: '20px',
    color: NeutralColors.light_neutrals_600,
  },
};
