/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconDownloadFile: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M9.16907 10.0632A.8401.8401 0 0 1 9.16671 10V3.33333c0-.46.3725-.83333.83329-.83333.4609 0 .8334.37333.8334.83333v6.66652l2-1.49985c.3683-.2775.8908-.20167 1.1666.16667.2767.36833.2017.89083-.1666 1.16666L10.5 12.3333c-.1475.1109-.3241.1667-.5.1667-.16746 0-.33496-.0508-.47913-.1517l-3.33333-2.345c-.37667-.26497-.4675-.78497-.2025-1.1608.265-.37667.78417-.4675 1.16083-.2025l2.0232 1.4232Zm-4.16903 4.1035V15H15v-.8333c0-.4584.375-.8334.8334-.8334.4583 0 .8333.375.8333.8334v1.6666c0 .4584-.375.8334-.8333.8334H4.16671c-.45834 0-.83334-.375-.83334-.8334v-1.6666c0-.4584.375-.8334.83334-.8334.45833 0 .83333.375.83333.8334Z" fill="#fff" />
  </SvgIcon>
);

export default IconDownloadFile;
