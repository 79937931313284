/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

const IconSilverCup: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="50" height="50" fill="#E5E5E5"/>
    <rect width="1924" height="1397" transform="translate(-1132 -308)" fill="white"/>
    <g clip-path="url(#clip0)">
    <path d="M17.5212 34.8581C5.52299 31.4198 4.11763 18.3457 4.10525 18.2161L4 17.1111H13.3732V19.0865H6.24115C6.81692 22.0926 9.18189 30.3951 18.066 32.9445L17.5212 34.8581Z" fill="#AAAAAA"/>
    <path d="M32.4789 34.8581L31.9341 32.963C40.8182 30.4136 43.1832 22.1112 43.759 19.105H36.6269V17.1111H46.0001L45.8949 18.1976C45.8825 18.3457 44.4771 31.4198 32.4789 34.8581Z" fill="#AAAAAA"/>
    <path d="M38.5585 14.0247C38.5585 14.0247 38.2552 36.426 26.5974 39.2346C26.3808 39.2902 26.1641 39.3272 25.935 39.3705C25.7059 39.4137 25.5821 39.426 25.4026 39.4445C25.1439 39.4816 24.8812 39.4816 24.6225 39.4445C24.4182 39.4445 24.2201 39.3951 24.0034 39.3643C23.7867 39.3334 23.601 39.284 23.3843 39.2346C11.7699 36.4198 11.4666 14.0247 11.4666 14.0247H38.5585Z" fill="#CCCCCC"/>
    <path d="M35.1288 51.5247H14.8965C14.9206 51.2711 15.0228 51.0312 15.189 50.8378C15.3553 50.6444 15.5775 50.507 15.8251 50.4445C22.6353 47.8457 23.3782 41.4753 23.4215 39.2099C23.4359 38.9405 23.4359 38.6706 23.4215 38.4012H26.6099C26.6099 38.4012 26.6099 38.5309 26.6099 38.7531C26.6099 38.8766 26.6099 39.037 26.6099 39.2099C26.6533 41.4692 27.4148 47.8519 34.2125 50.4445C34.4579 50.5092 34.6773 50.6475 34.8412 50.8408C35.0051 51.034 35.1055 51.2727 35.1288 51.5247Z" fill="#CCCCCC"/>
    <path d="M35.1287 51.5248H30.3925C30.2254 51.2508 30.0043 51.0135 29.7425 50.8272C25.3221 47.6297 25.8421 40.2284 25.935 39.3704C26.1685 39.1786 26.3897 38.9725 26.5974 38.7531C32.3737 33.4383 33.259 18.3827 33.3704 14H38.5585C38.5585 14 38.2552 36.4013 26.5974 39.2099C26.6408 41.4692 27.4023 47.8519 34.2 50.4445C34.4477 50.5071 34.6699 50.6445 34.8361 50.8378C35.0024 51.0312 35.1046 51.2712 35.1287 51.5248Z" fill="#AAAAAA"/>
    <path opacity="0.4" d="M22.1274 51.5248L22.2884 50.6544C22.2884 50.6544 24.802 44.889 24.5667 39.4445C24.5667 39.4445 24.3562 39.105 23.9476 38.426C22.1274 35.6297 16.8589 28.2778 17.5708 14.0247H14.2524C14.4072 18.3889 15.8312 32.7778 23.4276 38.9754C23.6195 39.1112 23.8176 39.2346 24.0467 39.3643C24.0467 39.3643 24.3624 47.6236 18.4004 50.6976C18.113 50.9026 17.8966 51.1917 17.7813 51.5248H22.1274Z" fill="white"/>
    <path d="M28.4054 29.9567H22.2577V28.6728L24.4679 26.4444C25.1159 25.7777 25.543 25.3148 25.7494 25.0555C25.9238 24.8362 26.0695 24.5955 26.1828 24.3394C26.2693 24.1213 26.3134 23.8888 26.3128 23.6543C26.3239 23.5104 26.3036 23.3658 26.2534 23.2305C26.2032 23.0952 26.1242 22.9723 26.0218 22.8703C25.8041 22.6885 25.5253 22.5958 25.2418 22.611C24.898 22.6131 24.5592 22.6933 24.2512 22.8456C23.8893 23.0268 23.5505 23.2506 23.242 23.5123L22.2329 22.3148C22.5663 22.0218 22.927 21.7613 23.3102 21.537C23.6046 21.3786 23.919 21.2602 24.245 21.1851C24.6144 21.1 24.9926 21.0586 25.3718 21.0617C25.8752 21.0535 26.3742 21.1568 26.8328 21.3641C27.2318 21.5464 27.5708 21.8375 27.811 22.2036C28.0468 22.5718 28.1674 23.0015 28.1577 23.4382C28.162 23.8268 28.0905 24.2126 27.9472 24.574C27.7803 24.9681 27.5572 25.3361 27.2848 25.6666C26.797 26.2321 26.2694 26.7622 25.7061 27.253L24.5731 28.3148V28.4012H28.4054V29.9567Z" fill="#969696"/>
    </g>
    <rect x="-1067.5" y="-231.5" width="1768" height="373" rx="4.5" stroke="#7B61FF" strokeDasharray="10 5"/>
    <defs>
    <clipPath id="clip0">
    <rect width="50" height="50" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);

export default IconSilverCup;
