/* eslint-disable prettier/prettier */
/* eslint-disable */

export enum EVENT_LEVEL {
  SCHOOL = 1,
  DISTRICT,
  MUNICIPAL,
  REGIONAL,
  RUSSIAN,
  INTERNATIONAL,
  INTERREGIONAL,
}
