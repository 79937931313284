/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconScience: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M5.71331 3.76646H14.3046C14.6051 3.76646 14.8934 3.64706 15.1059 3.43454C15.3184 3.22202 15.4378 2.93378 15.4378 2.63323C15.4378 2.33268 15.3184 2.04444 15.1059 1.83191C14.8934 1.61939 14.6051 1.5 14.3046 1.5H5.71331C5.41276 1.5 5.12451 1.61939 4.91199 1.83191C4.69947 2.04444 4.58008 2.33268 4.58008 2.63323C4.58008 2.93378 4.69947 3.22202 4.91199 3.43454C5.12451 3.64706 5.41276 3.76646 5.71331 3.76646Z"
          fill={props.fill || "#5C7CFA"}/>
    <path d="M18.3203 16.1397L13.5395 8.25666V6.30184C13.5395 6.06703 13.4463 5.84184 13.2802 5.67581C13.1142 5.50978 12.889 5.4165 12.6542 5.4165C12.4194 5.4165 12.1942 5.50978 12.0282 5.67581C11.8621 5.84184 11.7689 6.06703 11.7689 6.30184V8.6887L12.5834 10.0132H7.28552L8.22752 8.44081V6.316C8.22752 6.0812 8.13424 5.85601 7.96821 5.68998C7.80218 5.52394 7.57699 5.43067 7.34219 5.43067C7.10738 5.43067 6.88219 5.52394 6.71616 5.68998C6.55013 5.85601 6.45685 6.0812 6.45685 6.316V8.03001L1.66896 16.1609C1.54637 16.4087 1.48884 16.6835 1.50179 16.9597C1.51473 17.2358 1.59772 17.504 1.74294 17.7392C1.88817 17.9744 2.09085 18.1688 2.33192 18.3041C2.57298 18.4393 2.8445 18.511 3.12091 18.5124H16.8755C17.1542 18.5116 17.4281 18.4393 17.6709 18.3025C17.9137 18.1656 18.1174 17.9687 18.2623 17.7306C18.4073 17.4926 18.4888 17.2213 18.4989 16.9427C18.5091 16.6642 18.4476 16.3877 18.3203 16.1397Z"
          fill={props.fill || "#5C7CFA"}/>
  </SvgIcon>
);

export default IconScience;
