import { ICommonResponse, IDictionaryItem } from 'api/types';
import { IRootState } from 'reducers';
import { createSelector } from 'reselect';
import { filterParentSectionRefCodes, sortParentSectionRefCodes } from 'utils';

const parentSectionsAllSelector = createSelector(
  [(state: IRootState) => state.sectionRef],
  (sections): ICommonResponse<IDictionaryItem> => {
    const parentSections = sections.content.filter((section) => filterParentSectionRefCodes(section.code));
    const sortedSections = parentSections.sort((a, b) => sortParentSectionRefCodes(a.code, b.code));

    return {
      ...sections,
      content: sortedSections,
    };
  },
);

export default parentSectionsAllSelector;
