import { FC, PropsWithChildren } from 'react';

import { RadioGroup, styled } from '@mui/material';
import { ISwitcherButtonBaseProps, ISwitcherProps, Switcher } from 'portfolio3/ui-kit';

import UnderlinedTabButton from './UnderlinedTabButton';

interface IUnderlinedTabsComposition {
  Button: FC<ISwitcherButtonBaseProps>;
}

const StyledRadioGroup = styled(RadioGroup)(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
    gap: '32px',
  };
});

const UnderlinedTabs: FC<ISwitcherProps & PropsWithChildren> & IUnderlinedTabsComposition = ({
  children,
  ...props
}) => {
  return (
    <Switcher {...props} RadioGroupComponent={StyledRadioGroup}>
      {children}
    </Switcher>
  );
};

UnderlinedTabs.Button = UnderlinedTabButton;

export default UnderlinedTabs;
