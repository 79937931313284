/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import {
  SectionCodes,
  SectionBageBackground
} from '../const';
import {
  IconSchool,
  IconScience,
  IconSport,
  IconCreation,
  IconCulture,
  IconStar,
  IconProfession
} from '../icons';

export const getSectionIcon = (category: number | undefined) => {
  switch (category) {
    case SectionCodes.study:
      return <IconSchool />;
    case SectionCodes.science:
      return <IconScience />;
    case SectionCodes.sport:
      return <IconSport />;
    case SectionCodes.culture:
      return <IconCulture />;
    case SectionCodes.creation:
      return <IconCreation />;
    case SectionCodes.civil:
      return <IconStar />;
    case SectionCodes.profession:
      return <IconProfession />;
    default:
      return <></>;
  }
};

export const getSectionBackground = (category: number | undefined) => {
  switch (category) {
    case SectionCodes.study:
      return SectionBageBackground.study;
    case SectionCodes.science:
      return SectionBageBackground.science;
    case SectionCodes.sport:
      return SectionBageBackground.sport;
    case SectionCodes.culture:
      return SectionBageBackground.culture;
    case SectionCodes.creation:
      return SectionBageBackground.creation;
    case SectionCodes.civil:
      return SectionBageBackground.civil;
    case SectionCodes.profession:
      return SectionBageBackground.profession;
    default:
      return SectionBageBackground.civil;
  }
};

export const getImportDataKindOptions = (section: number | undefined) => {
  switch (section) {
    case SectionCodes.study:
      return [{ value: 'Мероприятие + Награда за мероприятие', code: 7 }];
    case SectionCodes.science:
      return [
        { value: 'Занятие', code: 12 },
        { value: 'Мероприятие + Награда за мероприятие', code: 14 },
        { value: 'Проект', code: 11 }
      ];
    case SectionCodes.sport:
      return [
        { value: 'Занятие', code: 23 },
        { value: 'Мероприятие + Награда за мероприятие', code: 27 },
        { value: 'Награда', code: 31 },
        { value: 'Объединение', code: 20 }
      ];
    case SectionCodes.creation:
      return [
        { value: 'Занятие', code: 40 },
        { value: 'Мероприятие + Награда за мероприятие', code: 43 },
        { value: 'Объединение', code: 37 }
      ];
    case SectionCodes.culture:
      return [
        { value: 'Мероприятие + Награда за мероприятие', code: 47 }
      ];
    case SectionCodes.civil:
      return [
        { value: 'Занятие', code: 52 },
        { value: 'Мероприятие + Награда за мероприятие', code: 54 },
        { value: 'Награда', code: 60 },
        { value: 'Объединение', code: 49 }
      ];
    case SectionCodes.profession:
      return [
        { value: 'Мероприятие + Награда за мероприятие', code: SectionCodes.professionEvent },
        { value: 'Демонстрационный экзамен', code: SectionCodes.professionExam }
      ];
    default:
      return [];
  }
};

export const getImportDataTypeOptions = (dataKind: number | undefined) => {
  switch (dataKind) {
    case SectionCodes.studyEvent:
      return [{ value: 'Олимпиада', code: 8 }];
    case SectionCodes.scienceOccupation:
      return [{ value: 'Кружок', code: 13 }];
    case SectionCodes.scienceEvent:
      return [
        { value: 'Конкурс', code: 15 },
        { value: 'Конференции и конкурсы проектов', code: 16 }
      ];
    case SectionCodes.sportOccupation:
      return [
        { value: 'Кружок', code: 24 },
        { value: 'Секция', code: 25 }
      ];
    case SectionCodes.sportEvent:
      return [
        { value: 'Первенство по туризму', code: 30 },
        { value: 'Походы и экспедиции', code: 29 },
        { value: 'Соревнование', code: 28 }
      ];
    case SectionCodes.sportReward:
      return [
        { value: 'ГТО', code: 32 },
        { value: 'Знак отличия по туризму', code: 35 },
        { value: 'Спортивное звание', code: 33 },
        { value: 'Спортивный разряд', code: 34 }
      ];
    case SectionCodes.sportAffilation:
      return [
        { value: 'Клуб/команда', code: SectionCodes.sportClubTeam },
      ];
    case SectionCodes.creationOccupation:
      return [
        { value: 'Кружок', code: 41 },
        { value: 'Школа искусств', code: 42 }
      ];
    case SectionCodes.creationEvent:
      return [{ value: 'Конкурс', code: 44 }];
    case SectionCodes.creationAffilation:
      return [{ value: 'Коллектив', code: 38 }];
    case SectionCodes.cultureEvent:
      return [
        { value: 'Посещение онлайн-мероприятий', code: 63 },
        { value: 'Посещение учреждений', code: 48 }
      ];
    case SectionCodes.civilOccupation:
      return [{ value: 'Кружок', code: 53 }];
    case SectionCodes.civilEvent:
      return [
        { value: 'Военно-патриотические мероприятия', code: 58 },
        { value: 'Волонтерский и социальный конкурсы', code: 57 },
        { value: 'Квесты и квизы', code: 59 },
        { value: 'Мероприятия по краеведению', code: 55 },
        { value: 'Экологические конференции', code: 56 }
      ];
    case SectionCodes.civilReward:
      return [{ value: 'Звания и статусы', code: 61 }];
    case SectionCodes.civilAffilation:
      return [
        { value: 'Общественное движение', code: 51 },
        { value: 'Объединение', code: 50 }
      ];
    case SectionCodes.professionEvent:
      return [{ value: 'Чемпионат', code: SectionCodes.professionEventChampionship }];
    case SectionCodes.professionExam:
      return [{ value: 'Экзамен', code: SectionCodes.professionExamGia }];
    default:
      return [];
  }
};

export const getProtfolioKindValue = (dataKind: number | undefined) => {
  switch (dataKind) {
    case SectionCodes.studyEvent:
      return 'Мероприятие + Награда за мероприятие';
    case SectionCodes.scienceOccupation:
      return 'Занятие';
    case SectionCodes.scienceEvent:
      return 'Мероприятие + Награда за мероприятие';
    case SectionCodes.project:
      return 'Проект';
    case SectionCodes.sportOccupation:
      return 'Занятие';
    case SectionCodes.sportEvent:
      return 'Мероприятие + Награда за мероприятие';
    case SectionCodes.sportReward:
      return 'Награда';
    case SectionCodes.sportAffilation:
      return 'Объединение';
    case SectionCodes.creationOccupation:
      return 'Занятие';
    case SectionCodes.creationEvent:
      return 'Мероприятие + Награда за мероприятие';
    case SectionCodes.creationAffilation:
      return 'Объединение';
    case SectionCodes.cultureEvent:
      return 'Мероприятие + Награда за мероприятие';
    case SectionCodes.civilOccupation:
      return 'Занятие';
    case SectionCodes.civilEvent:
      return 'Мероприятие + Награда за мероприятие';
    case SectionCodes.civilReward:
      return 'Награда';
    case SectionCodes.civilAffilation:
      return 'Объединение';
    case SectionCodes.professionEvent:
      return 'Мероприятие + Награда за мероприятие';
    case SectionCodes.professionExam:
      return 'Демонстрационный экзамен';
    default:
      return '';
  }
};

export const getProtfolioTypeValue = (type: number | undefined) => {
  switch (type) {
    case SectionCodes.eventOlympiad:
      return 'Олимпиада';
    case SectionCodes.scienceCircle:
      return 'Кружок';
    case SectionCodes.contest:
      return 'Конкурс';
    case SectionCodes.conference:
      return 'Конференции и конкурсы проектов';
    case SectionCodes.project:
      return 'Научный проект';
    case SectionCodes.sportOccupationCircle:
      return 'Кружок';
    case SectionCodes.sportOccupationSection:
      return 'Секция';
    case SectionCodes.gtoReward:
      return 'ГТО';
    case SectionCodes.sportRewardTourism:
      return 'Знак отличия по туризму';
    case SectionCodes.sportRank:
      return 'Спортивное звание';
    case SectionCodes.sportCategory:
      return 'Спортивный разряд';
    case SectionCodes.sportClubTeam:
      return 'Клуб/команда';
    case SectionCodes.sportEventCompetition:
      return 'Соревнование';
    case SectionCodes.sportEventExpedition:
      return 'Походы и экспедиции';
    case SectionCodes.sportEventTourism:
      return 'Первенство по туризму';
    case SectionCodes.creationCircle:
      return 'Кружок';
    case SectionCodes.creationSchool:
      return 'Школа искусств';
    case SectionCodes.creationContest:
      return 'Конкурс';
    case SectionCodes.creationCollective:
      return 'Коллектив';
    case SectionCodes.cultureOnline:
      return 'Посещение онлайн-мероприятий';
    case SectionCodes.cultureVisiting:
      return 'Посещение учреждений';
    case SectionCodes.civilOccupationCircle:
      return 'Кружок';
    case SectionCodes.civilEventPatriotic:
      return 'Военно-патриотические мероприятия';
    case SectionCodes.civilEventVolonteer:
      return 'Волонтерский и социальный конкурсы';
    case SectionCodes.civilEventQuest:
      return 'Квесты и квизы';
    case SectionCodes.civilEventLocal:
      return 'Мероприятия по краеведению';
    case SectionCodes.civilEventEcology:
      return 'Экологические конференции';
    case SectionCodes.civilRewardStatus:
      return 'Звания и статусы';
    case SectionCodes.civilAffilationMovement:
      return 'Общественное движение';
    case SectionCodes.civilAffilationGroup:
      return 'Объединение';
    case SectionCodes.professionEventChampionship:
      return 'Чемпионат';
    case SectionCodes.professionExamGia:
      return 'Экзамен';
    default:
      return '';
  }
};

// Эта функция вычисляет третий параметр для метода импорта. Этот параметр означает код награды, добавляемой к сущности
export const getSampleSecondaryTypeCode = (typeCode: number | undefined) => {
  switch (typeCode) {
    case SectionCodes.eventOlympiad: // 8
      return SectionCodes.rewardOlympiad; // 10
    case undefined: // 11 // Проект - единственная сущность, у которой не передается typeCode во втором параметре
      return SectionCodes.projectReward; // 19
    case SectionCodes.scienceCircle: // 13
      return SectionCodes.scienceCircle; // 13
    case SectionCodes.contest: // 15
      return SectionCodes.contestReward; // 18
    case SectionCodes.conference: // 16
      return SectionCodes.contestReward; // 18
    case SectionCodes.sportOccupationCircle: // 24
      return SectionCodes.sportOccupationCircle; // 24
    case SectionCodes.sportOccupationSection: // 25
      return SectionCodes.sportOccupationSection; // 25
    case SectionCodes.sportEventCompetition: // 28
      return SectionCodes.sportRewardCompetition; // 36
    case SectionCodes.sportEventExpedition: // 29
      return SectionCodes.sportRewardTourism; // 35
    case SectionCodes.sportEventTourism: // 30
      return SectionCodes.sportRewardTourism; // 35
    case SectionCodes.gtoReward: // 32
      return SectionCodes.gtoReward; // 32
    case SectionCodes.sportRank: // 33
      return SectionCodes.sportRank; // 33
    case SectionCodes.sportCategory: // 34
      return SectionCodes.sportCategory; // 34
    case SectionCodes.sportRewardTourism: // 35
      return SectionCodes.sportRewardTourism;// 35
    case SectionCodes.creationCollective: // 38
      return SectionCodes.creationCollective; // 38
    case SectionCodes.creationCircle: // 41
      return SectionCodes.creationCircle; // 41
    case SectionCodes.creationSchool: // 42
      return SectionCodes.creationSchool; // 42
    case SectionCodes.creationContest: // 44
      return SectionCodes.creationContestReward; // 46
    case SectionCodes.cultureVisiting: // 48
      return undefined;
    case SectionCodes.cultureOnline: // 63
      return undefined;
    case SectionCodes.civilAffilationGroup: // 50
      return SectionCodes.civilAffilationGroup; // 50
    case SectionCodes.civilAffilationMovement: // 51
      return SectionCodes.civilAffilationMovement; // 51
    case SectionCodes.civilOccupationCircle: // 53
      return SectionCodes.civilOccupationCircle; // 53
    case SectionCodes.civilEventLocal: // 55
    case SectionCodes.civilEventEcology: // 56
    case SectionCodes.civilEventVolonteer: // 57
    case SectionCodes.civilEventPatriotic: // 58
    case SectionCodes.civilEventQuest: // 59
      return SectionCodes.civilRewardEvent; // 62
    case SectionCodes.civilRewardStatus: // 61
      return SectionCodes.civilRewardStatus; // 61
    case SectionCodes.professionEventChampionship: // 72
      return SectionCodes.professionRewardEvent; // 74
    case SectionCodes.sportClubTeam: // 75
      return SectionCodes.sportClubTeam; // 75
    default:
      return typeCode;
  }
};
