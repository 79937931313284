/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

const IconRussiaMaster: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="50" height="50" fill="#E5E5E5"/>
    <rect width="1924" height="1397" transform="translate(-1107 -649)" fill="white"/>
    <rect width="50" height="50" fill="#E83F40"/>
    <path d="M45.1926 2H4.80735C3.25689 2 2 3.25689 2 4.80735V45.1926C2 46.7431 3.25689 48 4.80735 48H45.1926C46.7431 48 48 46.7431 48 45.1926V4.80735C48 3.25689 46.7431 2 45.1926 2Z" fill="#E83F40"/>
    <path d="M8.5144 6.13324H5.91675V13.6962H7.62822V11.1256H8.56175C10.1717 11.1256 11.5247 10.3477 11.5247 8.56853C11.5247 6.78942 10.1717 6.13324 8.5144 6.13324ZM8.46028 9.77265H7.62822V7.49295H8.41969C9.33969 7.49295 9.84704 7.77706 9.84704 8.56853C9.84704 9.36001 9.40057 9.77265 8.46028 9.77265Z" fill="#CE2830"/>
    <path d="M15.4619 5.99115C13.4324 5.99115 12.0254 7.41174 12.0254 9.88086C12.0254 12.35 13.4189 13.8314 15.4619 13.8314C17.5048 13.8314 18.8983 12.35 18.8983 9.87409C18.8983 7.39821 17.5048 5.99115 15.4619 5.99115ZM15.4619 12.3635C14.4269 12.3635 13.7774 11.4029 13.7774 9.88086C13.7774 8.3588 14.4539 7.46586 15.4619 7.46586C16.4698 7.46586 17.1463 8.36556 17.1463 9.88086C17.1463 11.3961 16.4969 12.3635 15.4619 12.3635Z" fill="#CE2830"/>
    <path d="M23.0992 12.3635C21.9898 12.3635 21.2659 11.457 21.2659 9.90115C21.2659 8.34527 22.0845 7.46586 23.1262 7.46586C23.3813 7.47029 23.6327 7.52655 23.8653 7.63121C24.0979 7.73588 24.3067 7.88676 24.4792 8.07468L25.3789 6.97203C25.0849 6.66485 24.7322 6.41977 24.3418 6.25129C23.9514 6.0828 23.5311 5.99435 23.1059 5.99115C21.1915 5.99115 19.5342 7.44556 19.5342 9.96203C19.5342 12.4785 21.1171 13.8314 23.0248 13.8314C23.4898 13.8404 23.9509 13.7454 24.3746 13.5535C24.7982 13.3616 25.1737 13.0775 25.4736 12.722L24.5671 11.6464C24.3855 11.8612 24.1612 12.0358 23.9085 12.1592C23.6558 12.2827 23.3802 12.3522 23.0992 12.3635Z" fill="#CE2830"/>
    <path d="M28.971 12.3635C27.8616 12.3635 27.1378 11.457 27.1378 9.90115C27.1378 8.34527 27.9563 7.46586 28.9981 7.46586C29.2531 7.47029 29.5045 7.52655 29.7371 7.63121C29.9697 7.73588 30.1786 7.88676 30.351 8.07468L31.2575 6.97203C30.9617 6.66526 30.6078 6.42051 30.2164 6.25209C29.8249 6.08367 29.4039 5.99497 28.9778 5.99115C27.0634 5.99115 25.406 7.44556 25.406 9.96203C25.406 12.4785 26.9889 13.8314 28.9101 13.8314C29.3741 13.8403 29.8343 13.7453 30.2568 13.5534C30.6794 13.3614 31.0536 13.0774 31.3522 12.722L30.466 11.6532C30.2807 11.87 30.0518 12.0453 29.7942 12.1677C29.5366 12.2901 29.2561 12.3568 28.971 12.3635Z" fill="#CE2830"/>
    <path d="M34.315 9.90119L33.5641 11.5585L33.7062 11.2474C33.74 8.80531 33.74 9.69825 33.74 8.80531V6.09943H32.0488V13.6962H33.8482L35.8777 9.92149L36.6285 8.2709H36.6759C36.5947 9.10972 36.4527 10.1177 36.4527 10.9768V13.6827H38.1438V6.13325H36.3038L34.315 9.90119Z" fill="#CE2830"/>
    <path d="M42.0877 6.13324C40.4845 6.13324 39.1654 6.68795 39.1654 8.48736C39.1366 8.96443 39.2618 9.43812 39.5224 9.83875C39.783 10.2394 40.1652 10.5458 40.613 10.7129L38.6851 13.6962H40.613L42.223 10.9903H43.0754V13.6962H44.7868V6.13324H42.0877ZM43.1024 9.61706H42.2298C41.3301 9.61706 40.8768 9.23148 40.8768 8.48736C40.8768 7.74324 41.3639 7.49295 42.2298 7.49295H43.1024V9.61706Z" fill="#CE2830"/>
    <path d="M8.23698 5.8288H5.63257V13.3985H7.34404V10.8279H8.27757C9.88757 10.8279 11.2405 10.0432 11.2405 8.26409C11.2405 6.48497 9.9011 5.8288 8.23698 5.8288ZM8.1761 9.46821H7.34404V7.1885H8.14227C9.06227 7.1885 9.56963 7.47262 9.56963 8.26409C9.56963 9.05556 9.12316 9.46821 8.1761 9.46821Z" fill="#DDDDDD"/>
    <path d="M15.1774 5.71387C13.148 5.71387 11.741 7.13446 11.741 9.60357C11.741 12.0727 13.1345 13.5609 15.1774 13.5609C17.2204 13.5609 18.6139 12.0795 18.6139 9.60357C18.6139 7.12769 17.2204 5.71387 15.1774 5.71387ZM15.1774 12.0862C14.1492 12.0862 13.493 11.1256 13.493 9.60357C13.493 8.08151 14.1695 7.19534 15.1774 7.19534C16.1854 7.19534 16.8618 8.08828 16.8618 9.60357C16.8618 11.1189 16.2124 12.0592 15.1774 12.0592V12.0862Z" fill="#DDDDDD"/>
    <path d="M22.815 12.0592C21.7056 12.0592 20.9953 11.1256 20.9953 9.59681C20.9953 8.06798 21.8139 7.16828 22.8556 7.16828C23.1102 7.17145 23.3615 7.22648 23.5941 7.32999C23.8267 7.4335 24.0358 7.58333 24.2086 7.77034L25.0542 6.66769C24.4588 6.06456 23.6489 5.72163 22.8015 5.71387C20.8871 5.71387 19.2297 7.16828 19.2297 9.68475C19.2297 12.2012 20.833 13.5609 22.7541 13.5609C23.2181 13.5693 23.6781 13.4741 24.1005 13.2822C24.523 13.0903 24.8973 12.8065 25.1962 12.4515L24.2965 11.3759C24.1103 11.5861 23.8825 11.7554 23.6274 11.873C23.3724 11.9906 23.0958 12.054 22.815 12.0592Z" fill="#DDDDDD"/>
    <path d="M28.6869 12.0591C27.5774 12.0591 26.8536 11.1526 26.8536 9.59674C26.8536 8.04085 27.6789 7.16821 28.7139 7.16821C28.9685 7.17138 29.2198 7.2264 29.4524 7.32992C29.685 7.43343 29.8941 7.58326 30.0669 7.77027L30.9733 6.66762C30.6729 6.3633 30.3147 6.12215 29.9197 5.95834C29.5247 5.79453 29.1009 5.71139 28.6733 5.7138C26.7657 5.7138 25.1016 7.16821 25.1016 9.68468C25.1016 12.2011 26.7048 13.5609 28.626 13.5609C29.09 13.5697 29.5501 13.4747 29.9727 13.2828C30.3952 13.0908 30.7695 12.8068 31.068 12.4514L30.1683 11.3759C29.984 11.5883 29.7566 11.7592 29.5011 11.877C29.2457 11.9948 28.9681 12.0568 28.6869 12.0591Z" fill="#DDDDDD"/>
    <path d="M34.0308 9.59674L33.2867 11.2541L33.422 10.9429C33.4558 8.50085 33.4558 9.3938 33.4558 8.50085V5.8288H31.7646V13.3985H33.5708L35.5597 9.61703L36.3105 7.96644H36.3579C36.2767 8.80527 36.1347 9.81997 36.1347 10.7129V13.4188H37.8326V5.8288H36.0197L34.0308 9.59674Z" fill="#DDDDDD"/>
    <path d="M41.8035 5.8288C40.2003 5.8288 38.8879 6.3835 38.8879 8.18291C38.8595 8.66264 38.9872 9.13867 39.2518 9.53984C39.5164 9.941 39.9037 10.2457 40.3559 10.4085L38.4009 13.3985H40.3559L41.9659 10.6926H42.8182V13.3985H44.5297V5.8288H41.8035ZM42.8182 9.31262H41.9659C41.0662 9.31262 40.6129 8.92703 40.6129 8.18291C40.6129 7.4388 41.1 7.1885 41.9659 7.1885H42.8385L42.8182 9.31262Z" fill="#DDDDDD"/>
    <path d="M44.5298 16.463H5.43652V42.8453C5.43652 43.0582 5.47856 43.269 5.56024 43.4656C5.64191 43.6622 5.7616 43.8407 5.91245 43.9909C6.0633 44.1412 6.24233 44.2601 6.43927 44.341C6.6362 44.4218 6.84717 44.463 7.06005 44.4621H42.913C43.3424 44.4621 43.7543 44.292 44.0586 43.9889C44.3629 43.6859 44.5347 43.2747 44.5365 42.8453L44.5298 16.463Z" fill="#969696"/>
    <path d="M11.2677 31.0341C11.1797 31.3047 11.0986 31.6024 11.0038 31.8865C10.9159 31.6024 10.8347 31.3047 10.74 31.0341L9.99591 29.0047H8.81885V33.6047H9.76591V31.9068C9.76591 31.4535 9.67797 30.7838 9.63061 30.3374L10.0297 31.4197L10.7062 33.1785H11.2812L11.9577 31.4197L12.3297 30.3238H12.3636C12.2913 30.8443 12.2462 31.3681 12.2283 31.8933V33.5777H13.1753V28.9777H11.9915L11.2677 31.0341Z" fill="#747778"/>
    <path d="M16.8891 28.9911L15.4414 33.5912H16.5102L16.7943 32.4953H18.1946L18.4788 33.5912H19.5814L18.1338 28.9911H16.8891ZM17.0041 31.697L17.1123 31.2709C17.2408 30.8109 17.3626 30.2697 17.4776 29.7826C17.5993 30.2629 17.7211 30.8109 17.8496 31.2709L17.9579 31.697H17.0041Z" fill="#747778"/>
    <path d="M23.7891 32.7726C23.1126 32.7726 22.6797 32.2247 22.6797 31.2776C22.6797 30.3306 23.1802 29.7962 23.8094 29.7962C24.1173 29.807 24.4088 29.9381 24.6211 30.1615L25.1691 29.485C24.9897 29.2973 24.7746 29.1474 24.5365 29.044C24.2983 28.9406 24.0419 28.8858 23.7823 28.8829C23.477 28.8862 23.1758 28.9529 22.8976 29.0786C22.6194 29.2044 22.3704 29.3866 22.1663 29.6136C21.9621 29.8406 21.8074 30.1075 21.7118 30.3975C21.6162 30.6874 21.5818 30.994 21.6108 31.2979C21.6108 32.8471 22.585 33.6588 23.7552 33.6588C24.038 33.6639 24.3183 33.6058 24.5758 33.4887C24.8333 33.3717 25.0614 33.1987 25.2435 32.9824L24.6888 32.3059C24.5824 32.4451 24.4466 32.5591 24.2911 32.6398C24.1356 32.7205 23.9641 32.7658 23.7891 32.7726Z" fill="#747778"/>
    <path d="M28.5785 33.5912H29.6135V29.8638H30.8852V28.9911H27.3135V29.8638H28.5785V33.5912Z" fill="#747778"/>
    <path d="M36.3781 32.7185H34.4636V31.6564H36.033V30.7906H34.4636V29.8638H36.3036V28.9911H33.4219V33.5912H36.3781V32.7185Z" fill="#747778"/>
    <path d="M40.1531 32.0285H40.7213C41.6955 32.0285 42.5275 31.555 42.5275 30.4726C42.5275 29.3903 41.709 28.9911 40.6943 28.9911H39.1113V33.5912H40.1531V32.0285ZM40.1531 29.8164H40.6131C41.1746 29.8164 41.4857 29.9923 41.4857 30.4929C41.4857 30.9935 41.2152 31.2235 40.6402 31.2235H40.1328L40.1531 29.8164Z" fill="#747778"/>
    <path d="M10.7942 39.4562C10.1177 39.4562 9.67803 38.9082 9.67803 37.9612C9.67803 37.0141 10.1786 36.4797 10.8077 36.4797C10.9611 36.4841 11.112 36.5193 11.2514 36.5832C11.3909 36.6471 11.5161 36.7384 11.6195 36.8518L12.2013 36.155C12.0265 35.971 11.8168 35.8237 11.5846 35.7215C11.3523 35.6193 11.102 35.5643 10.8483 35.5597C10.5437 35.5631 10.243 35.6295 9.96536 35.7549C9.68768 35.8803 9.43899 36.0618 9.23497 36.2881C9.03095 36.5143 8.87602 36.7804 8.77996 37.0695C8.6839 37.3587 8.6488 37.6646 8.67685 37.9679C8.67685 39.517 9.65097 40.3288 10.8145 40.3288C11.0974 40.3348 11.378 40.2772 11.6357 40.1601C11.8933 40.043 12.1212 39.8694 12.3027 39.6523L11.7548 38.9759C11.6435 39.1256 11.4986 39.2471 11.3317 39.3305C11.1649 39.4139 10.9808 39.457 10.7942 39.4562Z" fill="#747778"/>
    <path d="M14.6907 40.268H15.7324V36.5406H17.2477V40.268H18.2895V35.668H14.6907V40.268Z" fill="#747778"/>
    <path d="M23.0246 35.5868C21.7799 35.5868 20.9343 36.4459 20.9343 37.9477C20.9343 39.4494 21.7799 40.3559 23.0246 40.3559C24.2693 40.3559 25.1149 39.4562 25.1149 37.9477C25.1149 36.4391 24.2355 35.5868 23.0246 35.5868ZM23.0246 39.4562C22.3955 39.4562 21.9964 38.8744 21.9964 37.9477C21.9964 37.0209 22.3482 36.4797 23.0246 36.4797C23.7011 36.4797 24.0461 37.0277 24.0461 37.9477C24.0461 38.8677 23.6267 39.4562 23.0246 39.4562Z" fill="#747778"/>
    <path d="M29.282 35.668H27.7058V40.268H28.7476V38.7121H29.3158C30.2899 38.7121 31.1152 38.2318 31.1152 37.1494C31.1152 36.0671 30.2967 35.668 29.282 35.668ZM29.282 37.88H28.7746V36.5271H29.2482C29.8164 36.5271 30.1208 36.703 30.1208 37.2036C30.1208 37.7041 29.8232 37.88 29.2549 37.88H29.282Z" fill="#747778"/>
    <path d="M33.199 36.5406H34.464V40.268H35.5057V36.5406H36.7707V35.668H33.199V36.5406Z" fill="#747778"/>
    <path d="M39.8147 35.668L38.3738 40.268H39.4358L39.72 39.1721H41.1203L41.4044 40.268H42.507L41.0594 35.668H39.8147ZM39.9297 38.3739L40.0379 37.9477C40.1664 37.4877 40.2882 36.9465 40.4032 36.4594C40.525 36.9397 40.6467 37.4877 40.7753 37.9477L40.8835 38.3739H39.9297Z" fill="#747778"/>
    <path d="M10.8483 30.7432C10.7536 31.0138 10.6792 31.3047 10.5845 31.5888C10.4898 31.3047 10.4086 31.0138 10.3207 30.7432L9.56306 28.7138H8.43335V33.3206H9.36688V31.6294C9.36688 31.1829 9.27894 30.5132 9.23158 30.0667L9.59688 31.1559L10.2733 32.9147H10.8483L11.5248 31.1559L11.8833 30.0667C11.836 30.5132 11.7548 31.1829 11.7548 31.6294V33.3206H12.7019V28.7138H11.5925L10.8483 30.7432Z" fill="#D8D8D8"/>
    <path d="M16.4901 28.7138L15.0425 33.307H16.1045L16.3954 32.2112H17.7957L18.0798 33.307H19.1825L17.7348 28.7138H16.4901ZM16.6051 31.4197L16.7134 30.9935C16.8419 30.5335 16.9637 29.9991 17.0719 29.512H17.1057C17.2275 29.9923 17.3492 30.5335 17.4778 30.9935L17.6131 31.4197H16.6051Z" fill="#D8D8D8"/>
    <path d="M23.3899 32.4885C22.7134 32.4885 22.2805 31.9406 22.2805 30.9935C22.2805 30.0465 22.7743 29.5188 23.4102 29.5188C23.7185 29.5282 24.0105 29.6596 24.2219 29.8841L24.7699 29.2077C24.4146 28.8392 23.9286 28.6254 23.4169 28.6124C23.1123 28.6157 22.8116 28.6822 22.534 28.8076C22.2563 28.9329 22.0076 29.1145 21.8036 29.3407C21.5995 29.567 21.4446 29.8331 21.3486 30.1222C21.2525 30.4113 21.2174 30.7172 21.2455 31.0206C21.2455 32.5765 22.2196 33.3815 23.3899 33.3815C23.6718 33.3875 23.9514 33.3297 24.2079 33.2126C24.4644 33.0955 24.6912 32.922 24.8713 32.705L24.3234 32.0285C24.2121 32.1702 24.0705 32.285 23.9089 32.3646C23.7473 32.4442 23.57 32.4866 23.3899 32.4885Z" fill="#D8D8D8"/>
    <path d="M28.1795 33.3071H29.2145V29.573H30.4795V28.7003H26.9077V29.573H28.1795V33.3071Z" fill="#D8D8D8"/>
    <path d="M35.9791 32.4344H34.0647V31.3724H35.6341V30.4997H34.0647V29.573H35.9047V28.7003H33.0229V33.3071H35.9791V32.4344Z" fill="#D8D8D8"/>
    <path d="M39.7537 31.7444H40.3219C41.296 31.7444 42.1213 31.2641 42.1213 30.1817C42.1213 29.0994 41.2893 28.7138 40.2949 28.7138H38.7119V33.3206H39.7537V31.7444ZM39.7537 29.5323H40.234C40.7954 29.5323 41.1066 29.7015 41.1066 30.2088C41.1066 30.7162 40.836 30.9462 40.261 30.9462H39.7537V29.5323Z" fill="#D8D8D8"/>
    <path d="M10.3951 39.1721C9.7186 39.1721 9.27889 38.6174 9.27889 37.6703C9.27889 36.7232 9.77948 36.1956 10.4086 36.1956C10.5614 36.1995 10.7119 36.2338 10.8513 36.2965C10.9906 36.3592 11.1161 36.4491 11.2204 36.5609L11.7683 35.8844C11.593 35.7011 11.3833 35.5541 11.1511 35.452C10.919 35.3498 10.6689 35.2945 10.4154 35.2891C10.1101 35.2924 9.80883 35.3591 9.53065 35.4848C9.25247 35.6106 9.00344 35.7928 8.79931 36.0198C8.59519 36.2468 8.44043 36.5137 8.34482 36.8037C8.24921 37.0936 8.21484 37.4002 8.24389 37.7041C8.24389 39.2532 9.21801 40.0582 10.3815 40.0582C10.6643 40.0633 10.9446 40.0052 11.2021 39.8882C11.4596 39.7711 11.6877 39.5981 11.8698 39.3818L11.3218 38.7053C11.2103 38.8455 11.0697 38.9599 10.9097 39.0405C10.7497 39.1211 10.5741 39.166 10.3951 39.1721Z" fill="#D8D8D8"/>
    <path d="M14.2913 39.9838H15.333V36.2565H16.8416V39.9838H17.8833V35.3771H14.2913V39.9838Z" fill="#D8D8D8"/>
    <path d="M22.5986 35.2958C21.3539 35.2958 20.5083 36.155 20.5083 37.6635C20.5083 39.172 21.3539 40.065 22.5986 40.065C23.8433 40.065 24.6889 39.1652 24.6889 37.6635C24.6889 36.1617 23.8365 35.2958 22.5986 35.2958ZM22.5986 39.172C21.9695 39.172 21.5704 38.5835 21.5704 37.6635C21.5704 36.7435 21.9695 36.1955 22.5986 36.1955C23.2277 36.1955 23.6201 36.7367 23.6201 37.6635C23.6201 38.5902 23.2209 39.172 22.5986 39.172Z" fill="#D8D8D8"/>
    <path d="M28.8828 35.3838H27.3066V39.9838H28.3416V38.4211H28.9099C29.8908 38.4211 30.716 37.9408 30.716 36.8585C30.716 35.7761 29.8975 35.3838 28.8828 35.3838ZM28.8828 37.5958H28.3755V36.2091H28.8558C29.4172 36.2091 29.7284 36.3782 29.7284 36.8856C29.7284 37.3929 29.424 37.5958 28.849 37.5958H28.8828Z" fill="#D8D8D8"/>
    <path d="M32.7998 36.2565H34.0648V39.9838H35.1066V36.2565H36.3716V35.3771H32.7998V36.2565Z" fill="#D8D8D8"/>
    <path d="M39.4154 35.3838L37.9678 39.9838H39.0366L39.3207 38.8609H40.721L41.0051 39.9568H42.1078L40.6601 35.3568L39.4154 35.3838ZM39.5304 38.0897L39.6387 37.6635C39.7672 37.2035 39.889 36.6691 39.9972 36.182C40.119 36.6623 40.2407 37.2035 40.3692 37.6635L40.4775 38.0897H39.5304Z" fill="#D8D8D8"/>
    <path d="M7.87168 21.2727C10.8305 22.1289 13.9199 22.4428 16.9905 22.1994C19.3512 22.0086 21.6969 21.6629 24.0123 21.1644C25.2164 21.0021 25.1082 20.9818 25.3652 20.9682C25.6223 20.9547 25.514 20.9682 26.7182 21.1644C29.0313 21.6629 31.3747 22.0086 33.7332 22.1994C36.806 22.4444 39.8979 22.1304 42.8587 21.2727L43.1293 21.1847C43.2085 21.1542 43.2854 21.1181 43.3593 21.0765C41.2947 21.6479 39.163 21.9414 37.0208 21.9491C33.9594 21.9641 30.908 21.6006 27.9358 20.8668C27.1092 20.6032 26.2563 20.4308 25.3923 20.3527H25.3449C24.4786 20.4306 23.6235 20.603 22.7946 20.8668C19.8225 21.601 16.771 21.9645 13.7096 21.9491C11.5652 21.926 9.43357 21.6166 7.37109 21.0291C7.44736 21.0708 7.52648 21.1069 7.60786 21.1374L7.87168 21.2727Z" fill="#747778"/>
    <path d="M25.0542 20.17C24.7498 19.0065 23.1939 18.2894 21.6719 18.6344C21.9518 19.2585 22.45 19.7589 23.0728 20.0416C23.6956 20.3244 24.4002 20.37 25.0542 20.17Z" fill="#747778"/>
    <path d="M21.7122 23.5997C23.1463 23.7012 24.7225 22.5986 24.8645 21.3741C23.2004 21.4553 21.9625 22.3415 21.7122 23.5997Z" fill="#747778"/>
    <path d="M18.533 24.107C19.1959 24.1545 19.8553 23.9755 20.4034 23.5995C20.9514 23.2235 21.3557 22.6726 21.55 22.037C20.8857 21.982 20.2227 22.1579 19.6731 22.5351C19.1234 22.9122 18.7207 23.4674 18.533 24.107Z" fill="#747778"/>
    <path d="M22.0099 20.8194C21.8882 19.7506 20.3864 19.02 18.8508 19.2906C19.0741 20.3797 20.3999 21.0291 22.0099 20.8194Z" fill="#747778"/>
    <path d="M18.9997 21.3132C18.8915 20.2309 17.4574 19.4597 16.03 19.7032C16.26 20.8194 17.4641 21.4823 18.9997 21.3132Z" fill="#747778"/>
    <path d="M15.3127 23.8703C15.9601 24.0436 16.6464 24.0013 17.2676 23.7499C17.8889 23.4985 18.4113 23.0516 18.756 22.4768C18.1084 22.327 17.4304 22.3804 16.8143 22.6298C16.1982 22.8791 15.6739 23.3123 15.3127 23.8703Z" fill="#747778"/>
    <path d="M15.6579 21.6041C15.4076 20.4609 14.3997 19.8318 12.9994 19.9197C12.8776 20.7585 14.1291 21.7935 15.6579 21.6041Z" fill="#747778"/>
    <path d="M11.8901 23.6268C12.4913 23.8227 13.1382 23.8292 13.7432 23.6452C14.3482 23.4613 14.8821 23.0959 15.2725 22.5985C14.6714 22.4016 14.0242 22.3946 13.419 22.5786C12.8138 22.7626 12.2799 23.1286 11.8901 23.6268Z" fill="#747778"/>
    <path d="M8.62939 23.0314C9.16587 23.2796 9.76477 23.3601 10.3477 23.2624C10.9307 23.1646 11.4706 22.8931 11.8967 22.4835C11.0782 21.9085 9.54939 22.152 8.62939 23.0314Z" fill="#747778"/>
    <path d="M12.2757 21.6988C12.4245 20.745 11.4166 19.9197 10.1719 19.9197C9.98247 20.8329 10.9769 21.6717 12.2757 21.6988Z" fill="#747778"/>
    <path d="M9.54276 21.2253C9.72541 20.4 8.97453 19.6627 7.82453 19.5815C7.76364 20.4947 8.40629 21.0968 9.54276 21.2253Z" fill="#747778"/>
    <path d="M6.11304 22.267C6.55114 22.5042 7.05159 22.6009 7.54652 22.5441C8.04145 22.4872 8.50692 22.2795 8.8798 21.9491C8.4417 21.7119 7.94125 21.6152 7.44632 21.6721C6.95138 21.7289 6.48592 21.9367 6.11304 22.267Z" fill="#747778"/>
    <path d="M4.1377 20.7856C4.42866 21.0939 4.81404 21.2966 5.23299 21.3615C5.65194 21.4264 6.08058 21.3499 6.45123 21.1441C6.16224 20.8327 5.77662 20.6279 5.35677 20.5628C4.93692 20.4977 4.50739 20.5762 4.1377 20.7856Z" fill="#747778"/>
    <path d="M7.06672 20.5285C7.0685 20.3344 7.02847 20.1422 6.94936 19.9649C6.87025 19.7876 6.75391 19.6294 6.60823 19.501C6.46256 19.3727 6.29097 19.2772 6.10512 19.221C5.91926 19.1649 5.7235 19.1494 5.53113 19.1756C5.49055 19.9197 6.04525 20.4338 7.06672 20.5285Z" fill="#747778"/>
    <path d="M4.75998 19.7776C4.67881 19.1011 4.22557 18.7764 3.40704 18.7426C3.27851 19.365 3.92116 19.8453 4.75998 19.7776Z" fill="#747778"/>
    <path d="M29.0589 18.6344C27.5368 18.2894 25.9809 19.0065 25.6765 20.17C26.3306 20.3716 27.0357 20.3266 27.6589 20.0437C28.2821 19.7608 28.7801 19.2595 29.0589 18.6344Z" fill="#747778"/>
    <path d="M25.8726 21.3741C26.0079 22.5986 27.584 23.7012 29.0181 23.5997C28.7679 22.3415 27.5299 21.4553 25.8726 21.3741Z" fill="#747778"/>
    <path d="M29.1804 22.037C29.3748 22.6726 29.779 23.2235 30.3271 23.5995C30.8751 23.9755 31.5345 24.1545 32.1975 24.107C32.0097 23.4674 31.607 22.9122 31.0574 22.5351C30.5077 22.1579 29.8447 21.982 29.1804 22.037Z" fill="#747778"/>
    <path d="M28.7273 20.8194C30.3305 21.0291 31.6632 20.3797 31.8796 19.2906C30.3508 19.02 28.8423 19.7506 28.7273 20.8194Z" fill="#747778"/>
    <path d="M34.7074 19.7032C33.2733 19.4597 31.846 20.2309 31.731 21.3132C33.2733 21.4823 34.5248 20.8194 34.7074 19.7032Z" fill="#747778"/>
    <path d="M31.9746 22.4768C32.3201 23.0508 32.8426 23.497 33.4636 23.7483C34.0846 23.9997 34.7704 24.0425 35.4178 23.8703C35.0567 23.3123 34.5324 22.8791 33.9163 22.6298C33.3002 22.3804 32.6222 22.327 31.9746 22.4768Z" fill="#747778"/>
    <path d="M37.731 19.9197C36.3307 19.8318 35.3228 20.4609 35.0725 21.6041C36.6013 21.7935 37.8325 20.7585 37.731 19.9197Z" fill="#747778"/>
    <path d="M35.4312 22.6256C35.8222 23.1222 36.3561 23.487 36.9609 23.6709C37.5656 23.8547 38.2122 23.8488 38.8135 23.6538C38.4225 23.1572 37.8886 22.7924 37.2838 22.6085C36.679 22.4247 36.0324 22.4306 35.4312 22.6256Z" fill="#747778"/>
    <path d="M38.8337 22.4835C39.2599 22.8931 39.7998 23.1646 40.3827 23.2624C40.9657 23.3601 41.5646 23.2796 42.1011 23.0314C41.1811 22.152 39.659 21.9085 38.8337 22.4835Z" fill="#747778"/>
    <path d="M40.6129 19.9197C39.3344 19.9197 38.3265 20.7585 38.4753 21.7123C39.7606 21.6717 40.7482 20.8329 40.6129 19.9197Z" fill="#747778"/>
    <path d="M42.9063 19.5815C41.763 19.6627 41.0054 20.4 41.188 21.2253C42.3245 21.0968 42.9671 20.4947 42.9063 19.5815Z" fill="#747778"/>
    <path d="M41.8508 21.9491C42.2261 22.2896 42.6983 22.5045 43.2015 22.5639C43.7047 22.6233 44.2139 22.5243 44.6582 22.2806C44.2824 21.9411 43.8103 21.7267 43.3073 21.6674C42.8044 21.608 42.2954 21.7064 41.8508 21.9491Z" fill="#747778"/>
    <path d="M44.2793 21.1441C44.6499 21.3499 45.0786 21.4264 45.4975 21.3615C45.9165 21.2966 46.3019 21.0939 46.5928 20.7856C46.2231 20.5762 45.7936 20.4977 45.3738 20.5628C44.9539 20.6279 44.5683 20.8327 44.2793 21.1441Z" fill="#747778"/>
    <path d="M45.2062 19.1824C44.2862 19.1824 43.603 19.7371 43.6706 20.5353C44.6718 20.4338 45.24 19.9197 45.2062 19.1824Z" fill="#747778"/>
    <path d="M46.0249 19.7776C46.8434 19.8453 47.4861 19.365 47.3778 18.7426C46.5187 18.7764 46.0655 19.1147 46.0249 19.7776Z" fill="#747778"/>
    <path d="M7.56065 20.9615C10.5204 21.8128 13.6093 22.1244 16.6795 21.8815C19.04 21.6925 21.3856 21.349 23.7012 20.8532C24.9054 20.6909 24.7971 20.6638 25.0542 20.6503C25.3112 20.6367 25.203 20.6909 26.4071 20.8532C28.7227 21.349 31.0683 21.6925 33.4289 21.8815C36.4991 22.1244 39.5879 21.8128 42.5477 20.9615C42.6356 20.9615 42.7304 20.9073 42.8115 20.8735L43.0483 20.7653C40.9849 21.3474 38.8536 21.6544 36.7098 21.6785C33.6484 21.6935 30.5969 21.3299 27.6248 20.5962C26.7896 20.3314 25.9273 20.1612 25.0542 20.0888C24.181 20.1612 23.3188 20.3314 22.4836 20.5962C19.5114 21.3299 16.46 21.6935 13.3986 21.6785C11.2548 21.6544 9.12349 21.3474 7.06006 20.7653L7.29682 20.8735C7.378 20.9073 7.46594 20.9276 7.56065 20.9615Z" fill="#D8D8D8"/>
    <path d="M24.7429 19.8791C24.4385 18.7156 22.8827 17.9917 21.3606 18.3367C21.6394 18.962 22.137 19.4639 22.7599 19.748C23.3828 20.032 24.0881 20.0786 24.7429 19.8791Z" fill="#D8D8D8"/>
    <path d="M21.4011 23.3021C22.8352 23.3968 24.4114 22.2941 24.5467 21.0765C22.8894 21.1577 21.6717 22.0438 21.4011 23.3021Z" fill="#D8D8D8"/>
    <path d="M18.2217 23.8162C18.8853 23.862 19.5448 23.6816 20.0927 23.3044C20.6406 22.9273 21.0446 22.3756 21.2387 21.7394C20.5735 21.6848 19.9099 21.8616 19.3601 22.2401C18.8103 22.6185 18.4081 23.1753 18.2217 23.8162Z" fill="#D8D8D8"/>
    <path d="M21.6718 20.5286C21.55 19.453 20.0415 18.7224 18.5127 18.993C18.7562 20.0821 20.0889 20.7315 21.6718 20.5286Z" fill="#D8D8D8"/>
    <path d="M18.6887 21.0156C18.5737 19.94 17.1463 19.162 15.719 19.4056C15.9219 20.5218 17.1463 21.1847 18.6887 21.0156Z" fill="#D8D8D8"/>
    <path d="M15.0017 23.5794C15.6492 23.7505 16.3346 23.7071 16.9553 23.4559C17.5761 23.2047 18.0987 22.7591 18.4449 22.1859C17.7974 22.0331 17.1183 22.0851 16.5016 22.3347C15.8848 22.5843 15.3607 23.0193 15.0017 23.5794Z" fill="#D8D8D8"/>
    <path d="M15.3468 21.3065C15.0965 20.17 14.0885 19.5341 12.6882 19.6221C12.5868 20.4609 13.8179 21.4959 15.3468 21.3065Z" fill="#D8D8D8"/>
    <path d="M11.572 23.3021C12.1773 23.5044 12.8303 23.5149 13.4418 23.332C14.0532 23.1492 14.5933 22.782 14.9882 22.2806C14.3831 22.0763 13.7295 22.0648 13.1176 22.2478C12.5057 22.4307 11.9656 22.7991 11.572 23.3021Z" fill="#D8D8D8"/>
    <path d="M8.31836 22.7339C8.85444 22.984 9.45379 23.0655 10.0372 22.9676C10.6206 22.8698 11.1605 22.5972 11.5857 22.1859C10.7604 21.6109 9.23836 21.8612 8.31836 22.7339Z" fill="#D8D8D8"/>
    <path d="M11.9643 21.4011C12.1131 20.4473 11.1051 19.6017 9.82661 19.6085C9.67102 20.542 10.6654 21.3741 11.9643 21.4011Z" fill="#D8D8D8"/>
    <path d="M9.23147 20.9345C9.41412 20.1024 8.65647 19.3651 7.51324 19.2839C7.46588 20.1971 8.095 20.8059 9.23147 20.9345Z" fill="#D8D8D8"/>
    <path d="M5.76807 21.9491C6.21131 22.1923 6.71937 22.291 7.2214 22.2316C7.72344 22.1721 8.19441 21.9575 8.56866 21.6177C8.12487 21.3765 7.61724 21.2789 7.11568 21.3383C6.61411 21.3976 6.14329 21.6111 5.76807 21.9491Z" fill="#D8D8D8"/>
    <path d="M3.82642 20.488C4.1154 20.7994 4.50102 21.0042 4.92087 21.0693C5.34072 21.1344 5.77025 21.0559 6.13995 20.8465C5.85096 20.5351 5.46534 20.3303 5.04549 20.2652C4.62564 20.2001 4.19611 20.2786 3.82642 20.488Z" fill="#D8D8D8"/>
    <path d="M6.74872 20.2309C6.81637 19.4462 6.13313 18.878 5.21313 18.878C5.17931 19.6424 5.73401 20.1362 6.74872 20.2309Z" fill="#D8D8D8"/>
    <path d="M4.43539 19.48C4.35421 18.8035 3.90098 18.4788 3.08245 18.4518C2.96745 19.0674 3.61009 19.5477 4.43539 19.48Z" fill="#D8D8D8"/>
    <path d="M28.7478 18.3367C27.2258 17.9917 25.6699 18.7156 25.3655 19.8791C26.0204 20.0786 26.7256 20.032 27.3485 19.748C27.9714 19.4639 28.4691 18.962 28.7478 18.3367Z" fill="#D8D8D8"/>
    <path d="M25.5615 21.0833C25.6968 22.3009 27.273 23.4035 28.7071 23.3088C28.4365 22.0438 27.2189 21.1577 25.5615 21.0833Z" fill="#D8D8D8"/>
    <path d="M28.8694 21.7394C29.0635 22.3756 29.4675 22.9273 30.0154 23.3044C30.5633 23.6816 31.2229 23.862 31.8864 23.8162C31.7 23.1753 31.2978 22.6185 30.748 22.2401C30.1983 21.8616 29.5346 21.6848 28.8694 21.7394Z" fill="#D8D8D8"/>
    <path d="M28.4365 20.5286C30.0465 20.7315 31.3792 20.0821 31.5956 18.993C30.0398 18.7224 28.5312 19.453 28.4365 20.5286Z" fill="#D8D8D8"/>
    <path d="M34.3894 19.4056C32.962 19.162 31.5347 19.94 31.4197 21.0156C32.962 21.1847 34.1864 20.5218 34.3894 19.4056Z" fill="#D8D8D8"/>
    <path d="M31.6633 22.1859C32.0096 22.7591 32.5322 23.2047 33.153 23.4559C33.7737 23.7071 34.4591 23.7505 35.1066 23.5794C34.7475 23.0193 34.2235 22.5843 33.6067 22.3347C32.9899 22.0851 32.3109 22.0331 31.6633 22.1859Z" fill="#D8D8D8"/>
    <path d="M37.42 19.6221C36.0197 19.5341 35.0118 20.17 34.7615 21.3065C36.2903 21.4959 37.5215 20.4609 37.42 19.6221Z" fill="#D8D8D8"/>
    <path d="M35.1201 22.3077C35.5126 22.8024 36.0469 23.1653 36.6514 23.3478C37.2559 23.5304 37.9018 23.5239 38.5025 23.3292C38.1112 22.833 37.577 22.4689 36.972 22.2862C36.3671 22.1035 35.7206 22.111 35.1201 22.3077Z" fill="#D8D8D8"/>
    <path d="M38.5227 22.1859C38.9479 22.5972 39.4878 22.8698 40.0712 22.9676C40.6546 23.0655 41.254 22.984 41.7901 22.7339C40.8701 21.8612 39.348 21.6109 38.5227 22.1859Z" fill="#D8D8D8"/>
    <path d="M40.2816 19.6085C39.0031 19.6085 37.9952 20.4473 38.144 21.4012C39.4428 21.3741 40.4372 20.5421 40.2816 19.6085Z" fill="#D8D8D8"/>
    <path d="M42.595 19.2839C41.4518 19.3651 40.6941 20.1024 40.8767 20.9345C42.0132 20.8059 42.6423 20.1971 42.595 19.2839Z" fill="#D8D8D8"/>
    <path d="M41.5398 21.638C41.914 21.9779 42.385 22.1925 42.887 22.2519C43.3891 22.3113 43.8971 22.2126 44.3404 21.9695C43.9652 21.6314 43.4943 21.418 42.9928 21.3586C42.4912 21.2992 41.9836 21.3969 41.5398 21.638Z" fill="#D8D8D8"/>
    <path d="M43.9954 20.8465C44.3651 21.0559 44.7946 21.1344 45.2144 21.0693C45.6343 21.0042 46.0199 20.7994 46.3089 20.488C45.9392 20.2786 45.5097 20.2001 45.0898 20.2652C44.67 20.3303 44.2844 20.5351 43.9954 20.8465Z" fill="#D8D8D8"/>
    <path d="M44.8949 18.8914C43.9749 18.8914 43.2917 19.4461 43.3594 20.2444C44.3741 20.1361 44.9288 19.6423 44.8949 18.8914Z" fill="#D8D8D8"/>
    <path d="M45.6731 19.48C46.4984 19.5477 47.141 19.0674 47.026 18.4518C46.2075 18.4788 45.7543 18.8171 45.6731 19.48Z" fill="#D8D8D8"/>
    <rect x="-1042.5" y="-124.5" width="1768" height="796" rx="4.5" stroke="#7B61FF" strokeDasharray="10 5"/>
  </svg>
);

export default IconRussiaMaster;
