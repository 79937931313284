import { FC } from 'react';
import { connect } from 'react-redux';

import { IChildInfo } from '../../../api/types';
import { pdfTitlePage } from '../../../images';
import { IRootState } from '../../../reducers';

import './index.scss';

interface IPdfPortfolioTitlePageProps {
  currentStudent?: IChildInfo;
  isStudentInfoShown: boolean;
}

const PdfPortfolioTitlePage: FC<IPdfPortfolioTitlePageProps> = ({ currentStudent, isStudentInfoShown }) => {
  const firstname = currentStudent?.firstname;
  const lastname = currentStudent?.lastname;

  const requiredFullName = firstname && lastname ? `${lastname} ${firstname}` : null;
  const fullName = isStudentInfoShown ? requiredFullName : 'Личные данные скрыты';

  return (
    <div className="pdf-portfolio-title-page">
      <img src={pdfTitlePage} className="title-page" alt="титульный лист" />
      {fullName && (
        <div className="student-info">
          <div className="student-info__fio">{fullName}</div>
          {isStudentInfoShown && <div className="student-info__school">{currentStudent?.schoolName}</div>}
        </div>
      )}
    </div>
  );
};

export default connect((state: IRootState) => ({
  currentStudent: state.currentStudent,
}))(PdfPortfolioTitlePage);
