import { forwardRef, ForwardRefRenderFunction, useRef } from 'react';
import { AriaButtonOptions, useButton } from 'react-aria';

import { useForkRef } from '@mui/material';
import { IconCalendarEmpty, IconCalendarFilled } from 'icons';
import { getAccentColor, NeutralColors } from 'portfolio3/styles';
import { CommonInputButton } from 'portfolio3/ui-kit/inputButtons';
import { SxStyles } from 'types';

interface ICalendarInputButtonProps {
  isOpen: boolean;
  ariaOptions?: AriaButtonOptions<'button'>;
}

const CalendarInputButton: ForwardRefRenderFunction<HTMLButtonElement, ICalendarInputButtonProps> = (
  { isOpen, ariaOptions = {} },
  ref,
) => {
  const localRef = useRef(null);
  const combinedRef = useForkRef(localRef, ref);

  const { buttonProps } = useButton(ariaOptions, localRef);

  const { isDisabled } = ariaOptions;

  const iconElement = isOpen ? <IconCalendarFilled /> : <IconCalendarEmpty />;

  const iconOpenColor = isDisabled ? NeutralColors.light_neutrals_500 : getAccentColor('indigo', '100');
  const iconClosedColor = isDisabled ? NeutralColors.light_neutrals_500 : NeutralColors.light_neutrals_600;

  const styles: SxStyles = {
    '&&& svg': {
      color: isOpen ? iconOpenColor : iconClosedColor,
    },
  };

  return (
    <CommonInputButton
      icon={iconElement}
      ref={combinedRef}
      htmlAttributes={buttonProps}
      buttonProps={{
        sx: styles,
        tabIndex: isDisabled ? -1 : 0,
      }}
    />
  );
};

export default forwardRef(CalendarInputButton);
