import React, { useContext } from 'react';

import { Box } from '@mui/material';
import { LocalDataEntryFormContext } from 'components/common/PortfolioDataEntryForm/context/localDataEntryFormContext';
import { IconInfoFilled } from 'icons';
import { ILinkablePersonObject, IPortfolioEntryFormData } from 'portfolio3/features/dataEntryForm';
import { PrimaryInfobox } from 'portfolio3/ui-kit/infobox';
import { SxStyles } from 'types';

import { EmployeeLinkedItemSelector, StudentLinkedItemSelector } from '../../../../../Select';
import { ILinkedSelectorController } from '../../../../../Select/LinkedItemSelector/types';

export interface ILinkedObjectsTabProps {
  studentType: boolean | undefined;
  linkedObjectController: ILinkedSelectorController;
  linkedObjects: ILinkablePersonObject[];
  formData: IPortfolioEntryFormData;
}

const linkedBlockStyles = (isMobile: boolean): SxStyles => {
  if (isMobile) {
    return null;
  }

  return {
    display: 'grid',
    gridTemplateRows: '1fr auto',
    minHeight: '100%',
    gap: '16px',
  };
};

const LinkedObjectsTab: React.FC<ILinkedObjectsTabProps> = ({
  studentType,
  linkedObjectController,
  linkedObjects,
  formData,
}) => {
  const { isMobile } = useContext(LocalDataEntryFormContext);

  return (
    <Box className="secondary-block-linked" sx={linkedBlockStyles(isMobile)}>
      {studentType ? (
        <StudentLinkedItemSelector
          objectController={linkedObjectController}
          options={linkedObjects}
          values={formData.linkedObjects}
        />
      ) : (
        <EmployeeLinkedItemSelector
          objectController={linkedObjectController}
          options={linkedObjects}
          values={formData.linkedObjects}
        />
      )}

      {!isMobile && (
        <PrimaryInfobox icon={<IconInfoFilled />}>
          {studentType
            ? // eslint-disable-next-line max-len
              'Здесь ты можешь указать связь с другими достижениями. Например, добавить связь с наградой, полученной за олимпиаду или конкурс.'
            : // eslint-disable-next-line max-len
              'Вы можете указать связь с другими добавленными в систему объектами. Например, добавить связь с наградой, полученной за эту олимпиаду'}
        </PrimaryInfobox>
      )}
    </Box>
  );
};

export default LinkedObjectsTab;
