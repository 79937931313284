import { FC } from 'react';

import { Box } from '@mui/material';
import { useBuildConfig } from 'hooks';

import * as styles from './styles';
import { getServiceErrorIframeHTML } from './utils';

const ServiceErrorPlug: FC = () => {
  const config = useBuildConfig();

  const message = config?.SERVICE_ERROR_MESSAGE?.trim() ?? '';
  const html = getServiceErrorIframeHTML(message.length > 0 ? message : null);

  return (
    <Box className="service-error-plug" sx={styles.root}>
      <Box component="iframe" srcDoc={html} sx={styles.iframe} />
    </Box>
  );
};

export default ServiceErrorPlug;
