import { FC, ReactNode } from 'react';

import { Box, ModalProps, ThemeProvider, Typography } from '@mui/material';
import { AccentColorType } from 'portfolio3/styles';
import { generateAccentTheme } from 'portfolio3/styles/theme';
import { Button } from 'portfolio3/ui-kit/button';
import { mergeSx, SafeOmit } from 'utils';

import ModalBase from '../ModalBase';
import Icon from './components/Icon';
import * as styles from './InfoModal.styled';

export type InfoModalVariant = 'danger' | 'warning' | 'info';

const createAccentTheme = (variant: InfoModalVariant): AccentColorType => {
  switch (variant) {
    case 'info':
      return 'indigo';
    case 'warning':
      return 'orange';
    case 'danger':
      return 'red';
    default:
      return 'indigo';
  }
};

interface IInfoModalProps extends SafeOmit<ModalProps, 'children'> {
  header: string;
  text?: ReactNode | string;
  variant?: InfoModalVariant;
  onCancel?: () => void;
  onAction?: () => void;
  cancelButtonText?: string;
  actionButtonText?: string;
  isActionDisabled?: boolean;
}

const InfoModal: FC<IInfoModalProps> = ({
  header,
  text,
  onCancel,
  onAction,
  cancelButtonText,
  actionButtonText,
  isActionDisabled,
  variant = 'warning',
  sx,
  ...props
}) => {
  const hasButtons = Boolean(onCancel) || Boolean(onAction);

  return (
    <ModalBase {...props} className="info-modal" contentSx={mergeSx(styles.root, sx)}>
      <ThemeProvider theme={generateAccentTheme(createAccentTheme(variant))}>
        <Box sx={styles.infoModalContent} className="info-modal__content">
          <Box className="info-modal__icon-block">
            <Icon variant={variant} />
          </Box>
          <Box className="info-modal__content-block" sx={styles.contentBlock}>
            <Typography variant="Headings/H6" className="info-modal__header">
              {header}
            </Typography>
            {text && (
              <Typography variant="Paragraph MD/Regular" className="info-modal__text">
                {text}
              </Typography>
            )}
          </Box>
        </Box>
        {hasButtons && (
          <Box sx={styles.infoModalButtons} className="info-modal__buttons">
            {onCancel && (
              <Button variant="secondary" onClick={onCancel}>
                {cancelButtonText ?? 'Отмена'}
              </Button>
            )}
            {onAction && (
              <Button variant="primary" onClick={onAction} disabled={isActionDisabled}>
                {actionButtonText ?? 'Понятно'}
              </Button>
            )}
          </Box>
        )}
      </ThemeProvider>
    </ModalBase>
  );
};

export default InfoModal;
