import { FC } from 'react';

import { Stack, Typography } from '@mui/material';
import { StrongAccentBadge } from 'portfolio3/ui-kit/badges';

import CardPadding from '../CardPadding';
import { clickableTitleStyles } from '../utils';

interface IDocumentCardProps {
  name: string;
  badge: string;
  onView: () => void;
}

const DocumentCard: FC<IDocumentCardProps> = ({ name, badge, onView }) => {
  return (
    <CardPadding withHorizontalPadding>
      <Stack className="document-card" gap="12px">
        <StrongAccentBadge>{badge}</StrongAccentBadge>
        <Typography component="button" variant="Paragraph LG/Semi Bold" sx={clickableTitleStyles} onClick={onView}>
          {name}
        </Typography>
      </Stack>
    </CardPadding>
  );
};

export default DocumentCard;
