import React from 'react';

import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';
import { commonTheme } from 'portfolio3/styles/theme';

import IconRocket from '../assets/IconRocket.svg';
import { wrapperStyles } from '../styles';
import { IAchievementProps } from '../types';

const AerobaticsAchievement: React.FC<IAchievementProps> = ({ text }) => {
  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));
  return (
    <Box sx={wrapperStyles}>
      <Stack direction="row">
        <Stack>
          <Stack direction="row" alignItems="center">
            <Typography
              flexGrow={1}
              variant={isMobile ? 'Paragraph MD/Bold' : 'Paragraph LG/Bold'}
              color={NeutralColors.light_neutrals_0}
            >
              Высший пилотаж
            </Typography>
            {isMobile && <img src={IconRocket} alt="" />}
          </Stack>

          <Typography
            variant={isMobile ? 'Paragraph SM/Regular' : 'Paragraph MD/Regular'}
            color={NeutralColors.light_neutrals_0}
          >
            {text}
          </Typography>
        </Stack>
        {!isMobile && <img src={IconRocket} alt="" />}
      </Stack>
    </Box>
  );
};

export default AerobaticsAchievement;
