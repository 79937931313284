import { IRootState } from 'reducers';
import { createSelector } from 'reselect';
import { isDefined } from 'utils';

const faviriteVuzIdSelector = createSelector(
  [(state: IRootState) => state.favoriteVuzList],
  (favoriteVuzList): number[] | null => {
    const favoriteVuzIds = favoriteVuzList.content?.map((favoriteVuz) => favoriteVuz.universityId).filter(isDefined);
    return favoriteVuzIds ?? null;
  },
);

export default faviriteVuzIdSelector;
