/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconSchool: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M18.6524 5.2783H17.8608V3.06324C17.8572 2.8501 17.7832 2.644 17.6502 2.47657C17.5173 2.30915 17.3326 2.18964 17.1246 2.13637C15.8533 1.90155 14.5438 1.97073 13.3049 2.33818C12.0659 2.70563 10.933 3.36078 10 4.24932C9.06711 3.36078 7.93421 2.70563 6.69521 2.33818C5.45622 1.97073 4.14682 1.90155 2.8755 2.13637C2.65198 2.18013 2.45075 2.29967 2.30632 2.47451C2.16189 2.64935 2.08326 2.86859 2.08389 3.09466V5.2783H1.29228C1.07179 5.29247 0.865609 5.39157 0.71772 5.55446C0.569831 5.71734 0.491939 5.93112 0.500661 6.15019V16.927C0.499197 17.0556 0.527476 17.1827 0.583319 17.2988C0.639163 17.4148 0.721084 17.5165 0.822779 17.5962C0.924474 17.6758 1.04323 17.7313 1.16991 17.7583C1.2966 17.7852 1.42783 17.783 1.55351 17.7518C4.24352 17.0478 7.07997 17.1186 9.73089 17.956C9.90002 18.0147 10.0842 18.0147 10.2534 17.956C12.9024 17.1362 15.7263 17.0466 18.4228 17.6968C18.5468 17.7315 18.6772 17.7377 18.804 17.7148C18.9308 17.692 19.0507 17.6407 19.1545 17.565C19.2583 17.4892 19.3433 17.391 19.4031 17.2777C19.4629 17.1644 19.4958 17.0391 19.4994 16.9113V6.15019C19.5037 6.0372 19.4849 5.92452 19.4443 5.8189C19.4036 5.71327 19.3419 5.61688 19.2628 5.53547C19.1837 5.45407 19.0889 5.38934 18.984 5.34515C18.8791 5.30096 18.7663 5.27822 18.6524 5.2783ZM9.01052 14.2328C7.59666 13.1666 5.89181 12.548 4.11834 12.4576V3.93513C5.04392 3.89216 5.96699 4.06192 6.81558 4.43115C7.66417 4.80039 8.41534 5.35912 9.01052 6.06379V14.2328ZM15.8817 12.4183L15.6522 12.4812C13.961 12.6101 12.3427 13.218 10.9896 14.2328V6.06379C11.5902 5.36812 12.3434 4.81813 13.1914 4.45597C14.0394 4.0938 14.9597 3.92907 15.8817 3.9744V12.4183Z"
          fill={props.fill || "#845EF7"}/>
  </SvgIcon>
);

export default IconSchool;
