/* eslint-disable prettier/prettier */
/* eslint-disable */

export enum SportClubParentKeys {
  SPORT_CLUB = 'sportClub'
}

export const SportClubParentCodes: Record<SportClubParentKeys, number> = {
  [SportClubParentKeys.SPORT_CLUB]: 1
};
