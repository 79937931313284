import { GET_STUDENT_CLASS_RECOMMENDATIONS } from '../../actions';
import { FAILURE, INVALIDATE, REQUEST, SUCCESS } from '../../actions/utils';
import { IClassRecommendationResponse } from '../../api/types';
import { connectReducers } from '../../utils';
import { IReducers } from '..';
import genericReducers from '../genericReducer';

export type StudentClassRecommendationsState = IClassRecommendationResponse;

const initialState: StudentClassRecommendationsState = {
  content: [],
  loading: false,
};

const studentClassRecommendations: IReducers<StudentClassRecommendationsState> = {
  [GET_STUDENT_CLASS_RECOMMENDATIONS[REQUEST]]: genericReducers().get.request,
  [GET_STUDENT_CLASS_RECOMMENDATIONS[SUCCESS]]: genericReducers().get.success,
  [GET_STUDENT_CLASS_RECOMMENDATIONS[FAILURE]]: genericReducers().get.failure,
  [GET_STUDENT_CLASS_RECOMMENDATIONS[INVALIDATE]]: genericReducers().get.invalidate(initialState),
};

export default connectReducers(initialState, studentClassRecommendations);
