import { FC, ReactNode } from 'react';

import { ThemeProvider } from '@mui/material';
import { SectionKeys, SectionNames } from 'const';
import { useSectionTheme } from 'hooks';
import { IconSectionCulture } from 'icons';
import { cultureTheme } from 'portfolio3/styles/theme';

import SearchSection from '../components/SearchSection';
import { useSearchCulture } from '../hooks';
import CommonEntityCardList from './CardLists/CommonEntityCardList';

const CultureSection: FC = () => {
  const { visitings, onlineEvents, isCultureVisitingVisible, isCultureOnlineEventVisible, isCultureVisible } =
    useSearchCulture();

  const theme = useSectionTheme(cultureTheme);

  if (!isCultureVisible) return null;

  const subsections: ReactNode[] = [];

  if (isCultureVisitingVisible) {
    subsections.push(
      <SearchSection.Subsection title="Посещение культурных учреждений">
        <CommonEntityCardList entities={visitings} />
      </SearchSection.Subsection>,
    );
  }

  if (isCultureOnlineEventVisible) {
    subsections.push(
      <SearchSection.Subsection title="Посещение культурных онлайн-мероприятий">
        <CommonEntityCardList entities={onlineEvents} />
      </SearchSection.Subsection>,
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <SearchSection name={SectionNames[SectionKeys.CULTURE]} icon={<IconSectionCulture />} subsections={subsections} />
    </ThemeProvider>
  );
};

export default CultureSection;
