import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteProps, useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { getLinkSettingsActions, initializationAction, setCurrentStudentAction, setLinkModeAction } from 'actions';
import { IChildInfo, IResponseError, IUserContext } from 'api/types';
import { useComponentDidMount, useLoadStudentAvatars, useQueryParams } from 'hooks';
import { LinkModeErrorPage } from 'pages';
import AppLoader from 'portfolio3/components/common/AppLoader';
import { IRootState } from 'reducers';
import { LinkErrorState } from 'reducers/linkError';
import { clearSharingCookie, getSharedLinkString } from 'utils';

export interface IAuthProps extends RouteProps {
  authenticated: boolean;
  authError?: IResponseError;
  linkError: LinkErrorState;
  currentStudent?: IChildInfo;
  currentUser: IUserContext;
  getLinkSettings: typeof getLinkSettingsActions.request;
  setLinkMode: typeof setLinkModeAction;
  initialization: typeof initializationAction;
  setCurrentStudent: typeof setCurrentStudentAction;
}

const Auth: React.FC<IAuthProps> = ({
  authenticated,
  authError,
  linkError,
  children,
  currentStudent,
  currentUser,
  getLinkSettings,
  setLinkMode,
  initialization,
  setCurrentStudent,
}) => {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const query = useQueryParams();
  const studentId = query.get('student');

  useLoadStudentAvatars();

  const [linkActivated, setLinkActivated] = useState(false);
  const linkMode = window.location.href.includes('shared-link');
  const isEmployeeMode = window.location.href.includes('employee');
  const isAdminMode = window.location.href.includes('admin');

  // установка ребенка при наличии параметра запроса student
  useEffect(() => {
    if (!currentUser.data?.children || !studentId) {
      return;
    }

    const student = currentUser.data.children.find((children) => children.meshId === studentId);
    if (student) {
      setCurrentStudent(student);
      localStorage.setItem('currentStudent', JSON.stringify(student));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.data?.children, studentId]);

  useComponentDidMount(() => {
    const urlString = getSharedLinkString();
    if (linkMode && urlString && urlString !== 'shared-link') {
      getLinkSettings(urlString, new Date());
    } else {
      clearSharingCookie();
      setLinkMode(false);
      initialization();
    }
  });

  useComponentDidMount(() => {
    if (linkMode || !location.pathname.length) {
      return;
    }
    // Если портфолио открыто для набиватора или админа, то при загрузке страницы убираем лишние символы из пути
    if ((isEmployeeMode || isAdminMode) && match.path !== location.pathname) {
      const difference = location.pathname.replace(match.path, '');
      history.replace(location.pathname.replace(difference, ''));
    }
  });

  useEffect(() => {
    if (currentStudent?.meshId) {
      setLinkActivated(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStudent?.meshId, window.location.href]);

  if (linkError.code) {
    return <LinkModeErrorPage code={linkError.code} />;
  }

  if (authenticated || linkActivated || authError) {
    return <>{children}</>;
  }

  return <AppLoader />;
};

export default connect(
  (state: IRootState) => ({
    authenticated: state.auth.authenticated,
    authError: state.auth.error,
    linkError: state.linkError,
    currentStudent: state.currentStudent,
    currentUser: state.currentUser,
  }),
  {
    getLinkSettings: getLinkSettingsActions.request,
    setLinkMode: setLinkModeAction,
    initialization: initializationAction,
    setCurrentStudent: setCurrentStudentAction,
  },
)(Auth);
