/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { IInterestEditFormAttributes, IInterestEditFormData } from '../types';

interface IInterestsEditFormContext {
  onOpen: () => void;
  setEditFormAttributes: React.Dispatch<React.SetStateAction<IInterestEditFormAttributes | undefined>>;
  setEditFormData: React.Dispatch<React.SetStateAction<IInterestEditFormData | undefined>>;
}

export default React.createContext({} as IInterestsEditFormContext);
