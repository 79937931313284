import { FC, Fragment, ReactNode } from 'react';

import { Box } from '@mui/material';
import { SxStyles } from 'types';
import { mergeSx } from 'utils';

import * as styles from './styles';

interface IAttributesProps {
  attributes: ReactNode[];
  sx?: SxStyles;
}

const Attributes: FC<IAttributesProps> = ({ attributes, sx }) => {
  const length = attributes.filter(Boolean).length;

  if (length === 0) return null;

  return (
    <Box className="attributes" sx={mergeSx(styles.root, sx)}>
      {attributes.map((attribute, index) => {
        if (!attribute) return null;
        return (
          <Fragment key={index}>
            {index > 0 && <Box sx={styles.divider} />}
            {attribute}
          </Fragment>
        );
      })}
    </Box>
  );
};

export default Attributes;
