import { FC, SyntheticEvent, useRef } from 'react';

import { Box, Typography, useFormControl } from '@mui/material';
import { useHiddenHorizontalList } from 'hooks';
import { NeutralColors } from 'portfolio3/styles';
import SelectArrow from 'portfolio3/ui-kit/SelectArrow';
import { ISelectItem } from 'portfolio3/ui-kit/selects/types';
import { TokenFieldToken } from 'portfolio3/ui-kit/tokens';
import { CommonUiKitSize, InputRenderMode } from 'portfolio3/ui-kit/types';
import { mergeSx } from 'utils';

import CountBadge from '../CountBadge';
import * as styles from './styles';

interface IRenderValueProps {
  value: string | undefined;
  options: ISelectItem[];
  placeholder: string;
  onChangeValueState: (value: string) => void;
  inputRenderMode: InputRenderMode;
  inputSize: CommonUiKitSize;
  isOpen: boolean;
  isDisabled: boolean;
}

const RenderValue: FC<IRenderValueProps> = ({
  value,
  options,
  placeholder,
  onChangeValueState,
  inputSize,
  inputRenderMode,
  isOpen,
  isDisabled,
}) => {
  const elementsContainerRef = useRef<HTMLElement>(null);
  const buttonMoreRef = useRef<HTMLElement>(null);
  const rightElementRef = useRef<HTMLElement>(null);

  const selectedCodes = typeof value === 'string' && value.length > 0 ? value?.split(',') : [];
  const hasSelectedOptions = selectedCodes.length > 0;

  const formControlState = useFormControl();

  useHiddenHorizontalList({
    containerRef: elementsContainerRef,
    elementsContainerRef: elementsContainerRef,
    buttonMoreRef,
    shouldHideButtonMore: true,
    elementsGap: 4,
    allElementsCount: selectedCodes.length,
    hiddenElementClassName: 'visually-hidden',
    additionalDeps: [selectedCodes],
    onAllElementsVisible: () => null,
  });

  const rightElementWidth = rightElementRef.current?.clientWidth ?? 0;
  const rightElementMargin = 4; // gap

  const isPlaceholderVisible = inputRenderMode !== 'floating' || formControlState?.focused || formControlState?.filled;

  return (
    <Box
      sx={mergeSx(styles.root, {
        // фиксируем ширину, иначе не работает скрытие токенов
        width: `calc(100% - ${rightElementWidth + rightElementMargin}px)`,
      })}
      className="render-value"
    >
      {!hasSelectedOptions && isPlaceholderVisible && (
        <Typography variant="Paragraph MD/Regular" color={NeutralColors.light_neutrals_600}>
          {placeholder}
        </Typography>
      )}

      {hasSelectedOptions && (
        <Box sx={styles.tags} ref={elementsContainerRef} className="render-value__tags">
          {selectedCodes.map((selectedCode, index) => {
            const option = options?.find((option) => option.code === Number(selectedCode));
            if (!option) return null;

            const { code, value } = option;

            const handleDelete = (e: SyntheticEvent) => {
              e.stopPropagation();
              const newCodes = selectedCodes?.filter((code) => code !== selectedCode);
              onChangeValueState(newCodes?.join(','));
            };

            return (
              <TokenFieldToken
                key={code}
                label={value}
                size={inputSize}
                onDelete={handleDelete}
                data-tag-index={index}
                tabIndex={-1}
              />
            );
          })}
          <Typography ref={buttonMoreRef} className="visually-hidden" variant="Paragraph MD/Regular">
            ...
          </Typography>
        </Box>
      )}

      <Box sx={styles.rightPanel(inputSize)} className="render-value__right" ref={rightElementRef}>
        {hasSelectedOptions && <CountBadge count={selectedCodes.length} />}
        <SelectArrow isOpen={isOpen} isDisabled={isDisabled} />
      </Box>
    </Box>
  );
};

export default RenderValue;
