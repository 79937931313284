import { FC } from 'react';

import { AutocompleteGetTagProps } from '@mui/material';

import { CommonUiKitSize } from '../types';
import TokenBase from './TokenBase';

interface ITokenFieldTokenProps extends ReturnType<AutocompleteGetTagProps> {
  label: string;
  size?: CommonUiKitSize;
  disabled?: boolean;
}

const TokenFieldToken: FC<ITokenFieldTokenProps> = ({ label, size, disabled, onDelete, ...props }) => {
  return <TokenBase label={label} size={size} disabled={disabled} onDelete={onDelete} {...props} />;
};

export default TokenFieldToken;
