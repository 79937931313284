import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const commonRoot = (isActive: boolean): SxStyles => {
  return {
    padding: '3px',
    borderRadius: '14px',

    cursor: 'pointer',

    background: isActive ? 'linear-gradient(to bottom right, #1FF7FD , #B33BF6, #FF844C, #FF844C)' : 'transparent',
  };
};

export const commonInnerContainer = (isActive: boolean): SxStyles => {
  return {
    position: 'relative',

    width: '100%',
    height: '100%',

    borderRadius: '12px',
    backgroundColor: '#ffffff',

    overflow: 'hidden',

    outline: isActive ? 'none' : `1px solid ${NeutralColors.light_neutrals_300}`,
  };
};
