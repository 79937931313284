/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

const IconJuniorAdventurer5: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="50" height="50" fill="#E5E5E5"/>
    <rect width="1924" height="1397" transform="translate(-1286 -1194)" fill="white"/>
    <g clip-path="url(#clip0)">
    <path d="M25 48.143C20.4496 48.1449 16.0009 46.7884 12.2166 44.2451C8.43226 41.7018 5.48241 38.0859 3.74017 33.8549C1.99793 29.6239 1.54157 24.9679 2.42884 20.4757C3.3161 15.9836 5.50712 11.8573 8.72472 8.61877C11.9423 5.38021 16.0419 3.17492 20.505 2.28188C24.968 1.38884 29.5939 1.84816 33.7976 3.60175C38.0012 5.35534 41.5937 8.3244 44.1205 12.1333C46.6474 15.9423 47.9951 20.42 47.9932 25C47.9881 31.1363 45.5639 37.0198 41.253 41.3588C36.942 45.6979 31.0966 48.1378 25 48.143Z" fill="white"/>
    <path d="M24.9999 1.95471C29.5283 1.95471 33.9551 3.30629 37.7203 5.83854C41.4856 8.37079 44.4203 11.97 46.1532 16.181C47.8862 20.3919 48.3396 25.0256 47.4561 29.4959C46.5727 33.9663 44.392 38.0725 41.19 41.2955C37.9879 44.5184 33.9082 46.7133 29.4667 47.6025C25.0253 48.4917 20.4217 48.0353 16.2379 46.2911C12.0542 44.5468 8.4783 41.5931 5.96244 37.8033C3.44657 34.0135 2.10374 29.5579 2.10374 25C2.10888 18.8896 4.5228 13.0309 8.81555 8.71023C13.1083 4.38952 18.929 1.95988 24.9999 1.95471ZM24.9999 1.74956C20.4312 1.74956 15.965 3.11317 12.1662 5.66796C8.36746 8.22276 5.40668 11.854 3.6583 16.1024C1.90991 20.3509 1.45245 25.0258 2.34377 29.5359C3.23509 34.0461 5.43515 38.1889 8.66575 41.4405C11.8963 44.6922 16.0124 46.9066 20.4933 47.8037C24.9743 48.7008 29.6189 48.2404 33.8399 46.4806C38.0609 44.7208 41.6686 41.7408 44.2069 37.9173C46.7451 34.0937 48.0999 29.5985 48.0999 25C48.0999 18.8336 45.6662 12.9198 41.3341 8.55946C37.002 4.19915 31.1264 1.74956 24.9999 1.74956Z" fill="#969696"/>
    <path d="M24.9999 46.8144C36.9697 46.8144 46.6731 37.0478 46.6731 25C46.6731 12.9522 36.9697 3.18562 24.9999 3.18562C13.0301 3.18562 3.32666 12.9522 3.32666 25C3.32666 37.0478 13.0301 46.8144 24.9999 46.8144Z" fill="#748FFC"/>
    <path d="M24.9999 45.3783C36.1817 45.3783 45.2464 36.2546 45.2464 25C45.2464 13.7454 36.1817 4.62167 24.9999 4.62167C13.8181 4.62167 4.75342 13.7454 4.75342 25C4.75342 36.2546 13.8181 45.3783 24.9999 45.3783Z" fill="#E83F40"/>
    <path d="M24.9998 -8L13.5857 25L24.9998 58L36.511 25L24.9998 -8Z" fill="#748FFC"/>
    <path d="M25 58L36.5112 25L25 -8" fill="#5073CC"/>
    <path d="M58 25L25 13.5018L-8 25L25 36.4884L58 25Z" fill="#748FFC"/>
    <path d="M-8 25L25 36.4885L58 25" fill="#5073CC"/>
    <path d="M25 43.9618C35.4046 43.9618 43.8391 35.4723 43.8391 25C43.8391 14.5277 35.4046 6.03819 25 6.03819C14.5954 6.03819 6.16089 14.5277 6.16089 25C6.16089 35.4723 14.5954 43.9618 25 43.9618Z" fill="#CCCCCC"/>
    <path d="M1.8999 25V43.2682L26.2423 25H1.8999Z" fill="#969696"/>
    <path d="M48.0999 25V43.2682L23.7576 25H48.0999Z" fill="#969696"/>
    <path d="M36.8702 25C36.8702 28.1687 35.6196 31.2076 33.3935 33.4482C31.1674 35.6888 28.1481 36.9476 24.9999 36.9476C21.8517 36.9476 18.8324 35.6888 16.6063 33.4482C14.3802 31.2076 13.1296 28.1687 13.1296 25H1.8999C1.8999 31.1664 4.33365 37.0802 8.66574 41.4405C12.9978 45.8008 18.8734 48.2504 24.9999 48.2504C31.1264 48.2504 37.002 45.8008 41.3341 41.4405C45.6662 37.0802 48.0999 31.1664 48.0999 25H36.8702Z" fill="#CCCCCC"/>
    <path d="M24.9998 37.0355C22.6325 37.0355 20.3183 36.3288 18.3501 35.0048C16.3819 33.6809 14.848 31.7991 13.9425 29.5975C13.037 27.3959 12.8006 24.9735 13.2631 22.6367C13.7257 20.2999 14.8664 18.1536 16.5411 16.4695C18.2157 14.7853 20.349 13.6389 22.6711 13.1752C24.9931 12.7115 27.3997 12.9515 29.5863 13.8646C31.7728 14.7778 33.6412 16.3232 34.955 18.3053C36.2689 20.2875 36.9691 22.6172 36.9672 25C36.962 28.1921 35.6992 31.2519 33.4558 33.5081C31.2123 35.7644 28.1713 37.0329 24.9998 37.0355ZM24.9998 13.1501C22.6713 13.1501 20.3951 13.8451 18.459 15.1472C16.5229 16.4492 15.0139 18.2999 14.1228 20.4652C13.2317 22.6305 12.9985 25.0131 13.4528 27.3118C13.9071 29.6105 15.0284 31.7219 16.6749 33.3792C18.3214 35.0364 20.4192 36.165 22.703 36.6222C24.9868 37.0795 27.354 36.8448 29.5052 35.9479C31.6565 35.051 33.4953 33.5322 34.7889 31.5835C36.0826 29.6347 36.7731 27.3437 36.7731 25C36.7705 21.858 35.5293 18.8454 33.3219 16.6237C31.1146 14.402 28.1215 13.1527 24.9998 13.1501Z" fill="#969696"/>
    <path d="M25.0001 36.7229C31.4326 36.7229 36.6471 31.4744 36.6471 25C36.6471 18.5256 31.4326 13.2771 25.0001 13.2771C18.5676 13.2771 13.353 18.5256 13.353 25C13.353 31.4744 18.5676 36.7229 25.0001 36.7229Z" fill="url(#paint0_linear)"/>
    <path d="M20.312 20.1741H22.7773L24.0294 25C24.3302 26.1332 24.5438 27.1785 24.8544 28.3215H24.9126C25.2232 27.1785 25.4464 26.1332 25.7376 25L26.9411 20.1741H29.3191L26.2229 30.5293H23.4276L20.312 20.1741Z" fill="#969696"/>
    <path opacity="0.5" d="M40.0635 13.5604C38.3219 11.2457 36.0735 9.36634 33.4929 8.0685C30.9123 6.77066 28.0692 6.08926 25.1844 6.07726C14.9156 6.07726 6.55883 14.5471 6.16089 25.1465C8.96172 27.2036 12.2089 28.5598 15.6338 29.103C20.8756 29.8485 26.21 28.6948 30.6849 25.8479C35.1597 23.0009 38.4837 18.6459 40.0635 13.5604Z" fill="url(#paint1_linear)"/>
    <path d="M13.5859 34.6226L14.8671 35.7948C15.6921 36.5373 16.09 37.4361 15.265 38.3543C15.0996 38.5389 14.8966 38.6853 14.6699 38.7838C14.4431 38.8822 14.198 38.9303 13.9511 38.9247C13.7042 38.9191 13.4614 38.8601 13.2393 38.7515C13.0172 38.643 12.8209 38.4875 12.6638 38.2957L12.198 37.8757L11.0527 39.1456L10.2083 38.3837L13.5859 34.6226ZM13.2171 37.5533C13.683 37.9734 14.0906 37.9734 14.44 37.5533C14.7894 37.1332 14.673 36.7913 14.2168 36.3712L13.8285 36.0195L12.7997 37.1528L13.2171 37.5533Z" fill="#969696"/>
    <path d="M15.4593 39.5755C16.1678 38.0808 17.4005 37.6217 18.6428 38.2176C19.8852 38.8135 20.2928 40.0737 19.6134 41.5586C18.934 43.0435 17.6722 43.5124 16.4105 42.9654C15.1487 42.4183 14.7508 41.0702 15.4593 39.5755ZM18.5361 41.0506C18.9728 40.1323 18.8369 39.4094 18.2158 39.0968C17.5946 38.7842 16.954 39.1554 16.5172 40.0737C16.0805 40.992 16.1969 41.7638 16.8181 42.0275C17.4393 42.2913 18.0993 41.9689 18.5361 41.0506Z" fill="#969696"/>
    <path d="M20.4288 41.8224C20.4591 41.4913 20.5567 41.17 20.7157 40.8785C20.8747 40.5871 21.0916 40.3317 21.3529 40.1282C21.6142 39.9248 21.9144 39.7777 22.2346 39.696C22.5548 39.6144 22.8883 39.6 23.2143 39.6536C23.4923 39.7024 23.7577 39.8071 23.9945 39.9615C24.2314 40.116 24.4348 40.3169 24.5926 40.5524L23.8841 41.1776C23.692 40.8924 23.3952 40.6956 23.0591 40.6306C22.3796 40.5133 21.7391 41.0311 21.5741 42.0275C21.4091 43.024 21.7779 43.7371 22.5446 43.8641C22.726 43.8846 22.9096 43.8683 23.0846 43.8163C23.2596 43.7643 23.4225 43.6776 23.5638 43.5613L24.0393 44.3623C23.8032 44.5666 23.5244 44.7147 23.2237 44.7959C22.923 44.8771 22.608 44.8892 22.302 44.8313C21.971 44.7802 21.6549 44.658 21.3751 44.4729C21.0953 44.2878 20.8585 44.0442 20.6807 43.7586C20.5029 43.4731 20.3884 43.1523 20.3449 42.8182C20.3014 42.484 20.33 42.1444 20.4288 41.8224Z" fill="#969696"/>
    <path d="M25.1649 42.4964C25.1086 42.1687 25.1194 41.8329 25.1966 41.5095C25.2738 41.1862 25.4158 40.8821 25.6139 40.6159C25.812 40.3497 26.062 40.1271 26.3487 39.9616C26.6353 39.7961 26.9525 39.6913 27.2808 39.6536C27.5632 39.6301 27.8474 39.6628 28.1172 39.75C28.387 39.8372 28.6371 39.9771 28.8531 40.1616L28.329 40.9529C28.1981 40.8352 28.044 40.7466 27.8768 40.6927C27.7095 40.6389 27.5329 40.621 27.3584 40.6403C26.679 40.6989 26.1937 41.3632 26.2908 42.3792C26.3878 43.3952 26.9217 43.9716 27.6496 43.8934C27.8415 43.8758 28.0274 43.8169 28.1949 43.7209C28.3623 43.6248 28.5073 43.4938 28.6202 43.3366L29.2802 43.9911C29.1054 44.2468 28.8762 44.4602 28.6093 44.6156C28.3423 44.7711 28.0444 44.8648 27.737 44.8899C27.4072 44.9248 27.0738 44.8884 26.7591 44.7831C26.4444 44.6779 26.1557 44.5063 25.9122 44.2797C25.6687 44.0531 25.4761 43.7768 25.3472 43.4693C25.2183 43.1618 25.1562 42.8301 25.1649 42.4964Z" fill="#969696"/>
    <path d="M29.4065 39.4192L30.4547 38.9893L31.1244 40.6501C31.3429 41.2258 31.5308 41.8128 31.6874 42.4085V41.1874L31.9591 38.3543L33.0753 37.8854L35.0165 42.5844L33.978 43.0142L33.2986 41.3437C33.0753 40.7966 32.9006 40.1323 32.7356 39.595L32.6871 40.8064L32.4153 43.6492L31.2991 44.1083L29.4065 39.4192Z" fill="#969696"/>
    <path d="M34.0752 37.2895L34.9196 36.5373L36.094 37.8952C36.4823 38.3446 36.8802 38.9405 37.1908 39.3801L36.8414 38.2176L36.1911 35.4334L37.1034 34.6323L40.4131 38.4618L39.559 39.2043L38.3846 37.8464C37.9964 37.397 37.6178 36.8206 37.2975 36.3712L37.6178 37.5338L38.2778 40.3179L37.3655 41.1092L34.0752 37.2895Z" fill="#969696"/>
    <path d="M10.8293 29.1519C9.97523 29.3766 9.37347 29.0639 9.18905 28.3606C9.00464 27.6572 9.38317 27.071 10.2276 26.8464C10.3883 26.7756 10.5623 26.7404 10.7377 26.7433C10.9131 26.7461 11.0858 26.7869 11.2442 26.8629C11.4025 26.9389 11.5428 27.0483 11.6554 27.1836C11.7681 27.319 11.8505 27.4772 11.897 27.6474C12.0814 28.3508 11.6835 28.9076 10.8293 29.1519ZM9.80053 30.5195L9.64523 29.8845L10.6158 29.6208L10.4411 28.9076L10.9555 28.7709L11.1593 29.5524L12.2464 29.2593L12.3823 29.8845L9.80053 30.5195ZM10.3829 27.452C9.86847 27.5986 9.60641 27.8721 9.69376 28.2238C9.78111 28.5755 10.1499 28.6927 10.6643 28.5559C11.1788 28.4192 11.4699 28.1359 11.3729 27.7842C11.2758 27.4325 10.907 27.3153 10.3829 27.452Z" fill="#969696"/>
    <path d="M8.85913 25.7718V25.1563H9.88795V24.1794H8.86884V23.5639H11.5574V24.1794H10.4412V25.1563H11.5671V25.762L8.85913 25.7718Z" fill="#969696"/>
    <path d="M9.03369 22.0693L9.18899 21.4734L10.1596 21.7274L10.2275 21.4636C10.3731 20.8677 10.7128 20.4867 11.2855 20.643C11.8581 20.7993 12.0231 21.3268 11.8775 21.9032L11.6543 22.7531L9.03369 22.0693ZM9.60634 19.8419L9.75193 19.2558L12.3628 19.9298L12.2075 20.5257L9.60634 19.8419ZM11.3825 21.7957C11.4602 21.4929 11.3825 21.278 11.1302 21.2096C10.8778 21.1412 10.7419 21.2877 10.6546 21.6199V21.825L11.3728 22.0107L11.3825 21.7957Z" fill="#969696"/>
    <path d="M9.82007 17.2336L10.0627 16.7744C10.218 16.8526 10.3345 16.8428 10.4024 16.706C10.4704 16.5693 10.4024 16.4716 10.286 16.3837L10.5286 15.9343C10.8004 16.1101 10.9265 16.3934 10.6742 16.9112C10.4218 17.429 10.121 17.3703 9.82007 17.2336ZM10.3442 17.9272L10.6257 17.3996L11.4604 17.8588C11.7448 18.0264 12.0201 18.209 12.2854 18.4059L11.8971 17.8881L11.0624 16.6181L11.373 16.0417L13.7315 17.3508L13.4404 17.8783L12.6057 17.4192C12.3242 17.2629 12.033 17.048 11.7807 16.8721L12.1592 17.3899L13.0036 18.6599L12.693 19.2362L10.3442 17.9272Z" fill="#969696"/>
    <path d="M13.1199 13.746L14.634 12.3002L16.4975 14.254L16.0608 14.6741L14.5466 13.0817L13.906 13.6972L15.4202 15.2895L14.9737 15.7096L13.1199 13.746Z" fill="#969696"/>
    <path d="M17.1674 13.7362L16.983 13.2771C17.0496 13.2658 17.1128 13.239 17.1674 13.1989C17.2742 13.1306 17.3324 13.0524 17.3227 12.9547V12.8668L15.4883 11.6554L16.0415 11.3135L16.7209 11.8117L17.2548 12.2416L17.0898 11.5773L16.915 10.7567L17.4295 10.4245L17.8371 12.6519C17.8901 12.8319 17.8837 13.0244 17.819 13.2004C17.7542 13.3765 17.6346 13.5268 17.478 13.6288C17.3842 13.6886 17.2779 13.7254 17.1674 13.7362Z" fill="#969696"/>
    <path d="M19.06 10.1998L18.3709 10.4734L18.1768 10.0044L20.1179 9.22291L20.3023 9.70159L19.6132 9.97513L20.4188 12.0169L19.8559 12.2513L19.06 10.1998Z" fill="#969696"/>
    <path d="M21.2534 8.92007L22.9131 8.60746L23.0102 9.10568L21.9425 9.31084L22.0493 9.84814L22.9519 9.67229L23.049 10.1803L22.1464 10.3561L22.2628 10.9716L23.3596 10.7567L23.4567 11.2647L21.7581 11.587L21.2534 8.92007Z" fill="#969696"/>
    <path d="M24.3107 8.39254H24.9027L24.796 10.5906H25.4754L25.5822 8.39254H26.1645L26.0577 10.5906H26.7372L26.8439 8.39254H27.436L27.3098 11.3233L24.1748 11.167L24.3107 8.39254Z" fill="#969696"/>
    <path d="M28.8823 8.9103L30.5032 9.38899L30.3576 9.88721L29.3288 9.5746L29.1735 10.1021L30.0567 10.3659L29.9111 10.8544L29.0279 10.6004L28.8532 11.1963L29.9305 11.5186L29.7849 12.0071L28.1252 11.5186L28.8823 8.9103Z" fill="#969696"/>
    <path d="M30.9982 11.0497C31.0623 10.882 31.1603 10.7295 31.2859 10.6018C31.4115 10.4742 31.5621 10.3741 31.7281 10.308C31.8942 10.2419 32.072 10.2112 32.2504 10.2178C32.4288 10.2244 32.6039 10.2682 32.7647 10.3464C32.8988 10.4172 33.0176 10.5141 33.1142 10.6315C33.2108 10.7489 33.2834 10.8844 33.3276 11.0302L32.8617 11.2353C32.813 11.0613 32.6979 10.9138 32.5414 10.825C32.2114 10.6492 31.8038 10.825 31.5708 11.294C31.3379 11.7629 31.4058 12.1927 31.7553 12.3783C31.8469 12.4193 31.9461 12.4404 32.0464 12.4404C32.1467 12.4404 32.2459 12.4193 32.3376 12.3783L32.4444 12.8668C32.2897 12.9342 32.1217 12.9649 31.9534 12.9565C31.7851 12.948 31.621 12.9006 31.4738 12.8179C31.3216 12.739 31.1874 12.629 31.0798 12.4949C30.9722 12.3609 30.8935 12.2057 30.8487 12.0394C30.804 11.873 30.7942 11.6991 30.8199 11.5287C30.8456 11.3583 30.9064 11.1952 30.9982 11.0497Z" fill="#969696"/>
    <path d="M34.5408 12.1634L33.9584 11.7043L34.269 11.294L35.919 12.5933L35.6084 13.0036L35.0261 12.5444L33.6769 14.254L33.1917 13.873L34.5408 12.1634Z" fill="#969696"/>
    <path d="M36.7637 13.4334L37.3655 14.1368C37.7343 14.5568 37.8993 14.9965 37.5111 15.3286C37.4216 15.4094 37.3114 15.4633 37.1929 15.484C37.0745 15.5047 36.9527 15.4915 36.8413 15.4458C36.9044 15.5753 36.9195 15.7232 36.8839 15.8629C36.8483 16.0025 36.7644 16.1249 36.6472 16.2078C36.2299 16.5693 35.7349 16.3837 35.3563 15.9343L34.7061 15.2309L36.7637 13.4334ZM35.6766 15.6119C35.8999 15.8659 36.094 15.9245 36.2881 15.7584C36.4822 15.5924 36.424 15.4165 36.2105 15.1625L35.9872 14.8988L35.4631 15.3579L35.6766 15.6119ZM36.5113 14.8206C36.7055 15.0453 36.8802 15.0648 37.0355 14.9378C37.1908 14.8108 37.1616 14.6448 36.9772 14.4201L36.8122 14.254L36.3463 14.6545L36.5113 14.8206Z" fill="#969696"/>
    <path d="M38.7727 15.9831L39.6074 17.4583L39.1707 17.7123L38.6271 16.7353L38.1612 17.0089L38.6174 17.8099L38.1709 18.0639L37.7148 17.2629L37.1809 17.5755L37.7342 18.5524L37.2877 18.8064L36.4336 17.2922L38.7727 15.9831Z" fill="#969696"/>
    <path d="M40.18 18.7087L40.3741 19.2949L39.4036 19.6172L39.6947 20.5062L40.6653 20.1838L40.8594 20.7602L38.2971 21.6101L38.103 21.024L39.1706 20.6723L38.8794 19.7833L37.8118 20.1155L37.6177 19.5586L40.18 18.7087Z" fill="#969696"/>
    <path d="M41.1507 22.1767L41.2089 22.7824L40.1704 22.8899L40.2577 23.8179L41.2963 23.7202L41.3545 24.3357L38.666 24.5897L38.5883 24.0231L39.7142 23.9156L39.6269 22.9387L38.5204 23.0462L38.4622 22.4307L41.1507 22.1767Z" fill="#969696"/>
    <path d="M41.3349 25.8011L41.2572 26.397L40.2866 26.2798C39.9597 26.2288 39.6357 26.1603 39.316 26.0746L39.8693 26.4165L41.1019 27.2957L41.0243 27.9405L38.3455 27.5888L38.4231 26.9929L39.3937 27.1101C39.7206 27.1611 40.0446 27.2296 40.3643 27.3153L39.8208 26.9538L38.5881 26.0941L38.6755 25.4494L41.3349 25.8011Z" fill="#969696"/>
    <path d="M40.7138 29.357L40.5099 29.9334L39.5393 29.5817L39.3161 30.1972L37.4429 30.5977L37.6564 29.992L38.9764 29.6989L39.0735 29.4156L37.9864 29.0346L38.1902 28.4485L40.7138 29.357ZM39.3258 29.7771L39.879 30.4023C39.9948 30.5043 40.0777 30.6387 40.1175 30.7882C40.1572 30.9378 40.1519 31.0959 40.1023 31.2425C40.0768 31.3157 40.0408 31.3848 39.9955 31.4476L39.5005 31.1643C39.5253 31.1317 39.5449 31.0955 39.5588 31.0568C39.5588 30.9396 39.5588 30.8615 39.4326 30.6954L39.0443 30.2362L39.3258 29.7771Z" fill="#969696"/>
    </g>
    <rect x="-1221.5" y="-669.5" width="1768" height="796" rx="4.5" stroke="#7B61FF" strokeDasharray="10 5"/>
    <defs>
    <linearGradient id="paint0_linear" x1="19.1432" y1="17.0729" x2="28.5735" y2="29.667" gradientUnits="userSpaceOnUse">
    <stop stopColor="white"/>
    <stop offset="0.03" stopColor="#FEFEFF"/>
    <stop offset="1" stopColor="#F1ECFF"/>
    </linearGradient>
    <linearGradient id="paint1_linear" x1="18.3903" y1="10.4538" x2="27.0671" y2="26.6042" gradientUnits="userSpaceOnUse">
    <stop stopColor="white" stopOpacity="0"/>
    <stop offset="0.35" stopColor="white" stopOpacity="0.01"/>
    <stop offset="0.49" stopColor="white" stopOpacity="0.03"/>
    <stop offset="0.59" stopColor="white" stopOpacity="0.08"/>
    <stop offset="0.67" stopColor="white" stopOpacity="0.15"/>
    <stop offset="0.74" stopColor="white" stopOpacity="0.24"/>
    <stop offset="0.8" stopColor="white" stopOpacity="0.35"/>
    <stop offset="0.85" stopColor="white" stopOpacity="0.47"/>
    <stop offset="0.9" stopColor="white" stopOpacity="0.62"/>
    <stop offset="0.95" stopColor="white" stopOpacity="0.79"/>
    <stop offset="1" stopColor="white"/>
    </linearGradient>
    <clipPath id="clip0">
    <rect width="50" height="50" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);

export default IconJuniorAdventurer5;
