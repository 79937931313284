import { FC, ReactNode } from 'react';

import { Box, MenuProps, svgIconClasses, SxProps } from '@mui/material';
import TrimText from 'portfolio3/components/common/TrimText';
import { NeutralColors } from 'portfolio3/styles';
import { BaseMenu } from 'portfolio3/ui-kit/menu';

import { IModalSelectBaseProps, ModalSelectBase } from '../../bases';
import { useSelectMenuList } from '../../hooks';
import { ISelectItem, ISelectOptionsListSettings } from '../../types';

interface ISelectProps
  extends Omit<IModalSelectBaseProps<MenuProps>, 'ModalComponent' | 'ModalComponentProps' | 'children'> {
  startIcon?: ReactNode;
  options?: ISelectItem[];
}

const Select: FC<ISelectProps & ISelectOptionsListSettings> = ({
  startIcon,
  options,
  addOtherOption,
  markOtherOption,
  markFirstOption,
  ...props
}) => {
  const TypedSelect = ModalSelectBase<MenuProps>;

  const menuList = useSelectMenuList({
    options: options ?? [],
    addOtherOption,
    markOtherOption,
    markFirstOption,
  });

  const renderValue = (value: string | undefined) => {
    const optionCode = Number(value);
    const renderedOption = options?.find((option) => option.code === optionCode);
    const optionValue = renderedOption?.value;

    const boxStyles: SxProps = {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      fontFamily: 'Inter',

      [`& .${svgIconClasses.root}`]: {
        width: '20px',
        height: '20px',
        color: NeutralColors.light_neutrals_600,
      },
    };

    return (
      <Box sx={boxStyles}>
        {startIcon}
        <TrimText>{optionValue}</TrimText>
      </Box>
    );
  };

  return (
    <TypedSelect
      {...props}
      ModalComponent={BaseMenu}
      ModalComponentProps={{}}
      renderValue={props.renderValue || renderValue}
    >
      {menuList}
    </TypedSelect>
  );
};

export default Select;
