import { FC, useContext } from 'react';

import { Box } from '@mui/material';
import { useAppSelector, useScrollToElement } from 'hooks';
import { IconArrowUp, IconBookmarkFilled, IconBookmarkOutline, IconBriefcaseFilled, IconOrganization } from 'icons';
import InlinePadding from 'portfolio3/components/common/InlinePadding';
import { MobileDrawerHeader } from 'portfolio3/components/drawers';
import { NeutralColors } from 'portfolio3/styles';
import { Button } from 'portfolio3/ui-kit/button';
import { Drawer, IDrawerBase } from 'portfolio3/ui-kit/Drawer';
import { SxStyles } from 'types';

import {
  drawerFacultiesFullSortOptions,
  drawerFacultiesSortOptions,
  drawerSpecialitiesSortOptions,
  VuzDetailsTab,
} from '../../const';
import { VuzDetailsContext } from '../../context';
import { IDetailsDrawerProps } from '../../types';
import DetailsDrawerFilters from '../DetailsDrawerFilters';
import DetailsFacultyList from '../DetailsFacultyList';
import DetailsSpecialityList from '../DetailsSpecialityList';
import DrawerVuzCard from '../DrawerVuzCard';
import SectionButton from './SectionButton';
import * as styles from './styles';

const footer: SxStyles = {
  paddingBlock: '16px 20px',
  paddingInline: '12px',
};

const footerButton: SxStyles = {
  width: '100%',
};

const MOBILE_LIMIT = 10;
const SECTION_LIST_ID = 'section-list-id';

const VuzDetailsDrawerMobile: FC<IDetailsDrawerProps> = ({
  isOpen,
  isLoading,
  isFavoriteVuz,
  isFavoriteStateLoading,
  onClose,
  onAddToFavorites,
  vuzInfo,
  specialities,
  faculties,
  facultyListType,
  isFacultyListEmpty,
  hasPagination,
  specialitiesDataPlugElement,
  facultiesDataPlugElement,
}) => {
  const { isViewing: isAdminViewing } = useAppSelector((state) => state.adminViewMode);

  const { filters, setFilter, totalElements, currentTab, onChangeCurrentTab } = useContext(VuzDetailsContext);

  const { scrollToElementGlobal } = useScrollToElement();

  const isOnLastPage = filters.limit >= totalElements;

  const handleChangeSearch = (value: string) => setFilter('search', value);
  const handleChangeSort = (value: number) => setFilter('sortType', value);

  const facultySortOptions = facultyListType === 'full' ? drawerFacultiesFullSortOptions : drawerFacultiesSortOptions;

  const handleExitBack = () => {
    onChangeCurrentTab(null);
  };

  const handleShowMore = () => {
    if (isOnLastPage) {
      scrollToElementGlobal(SECTION_LIST_ID);
      return;
    }

    setFilter('limit', filters.limit + MOBILE_LIMIT);
  };

  const header: IDrawerBase['header'] = (crossButton) => (
    <MobileDrawerHeader
      title="Информация о вузе"
      overrideTitleColor={NeutralColors.light_neutrals_900}
      overrideBackgroundColor={NeutralColors.light_neutrals_0}
      crossButton={currentTab === null ? crossButton : undefined}
      onExitBack={currentTab !== null ? handleExitBack : undefined}
    />
  );

  const footerElement = (
    <Box sx={footer}>
      <Button
        variant="tetriary"
        iconLeft={isFavoriteVuz ? <IconBookmarkFilled /> : <IconBookmarkOutline />}
        onClick={onAddToFavorites}
        sx={footerButton}
        disabled={isFavoriteStateLoading}
      >
        {isFavoriteVuz ? 'В избранном' : ' Добавить в избранное'}
      </Button>
    </Box>
  );

  const listFiltersElement = (
    <DetailsDrawerFilters
      searchTerm={filters.search}
      sortType={filters.sortType}
      sortOptions={currentTab === VuzDetailsTab.SPECIALITIES ? drawerSpecialitiesSortOptions : facultySortOptions}
      onChangeSearchTerm={handleChangeSearch}
      onChangeSortType={handleChangeSort}
    />
  );

  const showMoreButton = (
    <Box className="show-more-container" sx={styles.showMoreButtonContainer}>
      <Button
        variant="tetriary"
        iconLeft={isOnLastPage ? <IconArrowUp /> : undefined}
        sx={{ width: '100%' }}
        onClick={handleShowMore}
      >
        {isOnLastPage ? 'Вернуться в начало списка' : 'Показать еще'}
      </Button>
    </Box>
  );

  const specialitiesListElement = (
    <>
      {specialitiesDataPlugElement ? (
        <Box marginTop="12px">{specialitiesDataPlugElement}</Box>
      ) : (
        <DetailsSpecialityList specialities={specialities}>{hasPagination && showMoreButton}</DetailsSpecialityList>
      )}
    </>
  );

  const facultiesListElement = (
    <>
      {facultiesDataPlugElement ? (
        <Box marginTop="12px">{facultiesDataPlugElement}</Box>
      ) : (
        <DetailsFacultyList faculties={faculties} facultyListType={facultyListType}>
          {hasPagination && showMoreButton}
        </DetailsFacultyList>
      )}
    </>
  );

  const inlinePadding = '16px';

  return (
    <Drawer
      open={isOpen}
      isFullHeight
      isMobile
      swipeable
      anchor="bottom"
      swipeableProps={{
        onClose,
      }}
      header={header}
      footer={!isAdminViewing && currentTab === null ? footerElement : undefined}
      onClose={onClose}
      contentSx={{ paddingTop: '2px' }}
    >
      {currentTab === null && (
        <>
          <InlinePadding _p={inlinePadding}>{vuzInfo && <DrawerVuzCard vuzInfo={vuzInfo} />}</InlinePadding>
          {!isLoading && (
            <InlinePadding _p={inlinePadding} className="section-buttons" marginTop="4px">
              <SectionButton
                icon={<IconBriefcaseFilled />}
                text="Специальности"
                onClick={() => onChangeCurrentTab(VuzDetailsTab.SPECIALITIES)}
              />

              {!isFacultyListEmpty && (
                <SectionButton
                  icon={<IconOrganization />}
                  text="Факультеты"
                  onClick={() => onChangeCurrentTab(VuzDetailsTab.FACULTIES)}
                />
              )}
            </InlinePadding>
          )}
        </>
      )}

      {currentTab !== null && (
        <>
          <InlinePadding _p={inlinePadding}>{listFiltersElement}</InlinePadding>
          <Box className="section-list" id={SECTION_LIST_ID}>
            {currentTab === VuzDetailsTab.SPECIALITIES ? specialitiesListElement : facultiesListElement}
          </Box>
        </>
      )}
    </Drawer>
  );
};

export default VuzDetailsDrawerMobile;
