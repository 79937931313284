import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const TheatreCircusCinema: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="230" height="129" viewBox="0 0 230 129" xmlns="http://www.w3.org/2000/svg" {...props} fill="none">
    {/*eslint-disable max-len */}
    <path
      d="M135.26 21.32c1 15.43-7.07 33.29-18.4 34-11.33.71-21.62-15.92-22.62-31.32s9-3.57 20.32-4.3 19.71-13.81 20.7 1.62Z"
      fill="#fff"
    />
    <path
      d="M116.14 55.86c-5.24 0-10.49-3.47-14.87-9.86a45.931 45.931 0 0 1-7.53-22c-.27-4.31.25-6.72 1.71-7.83 1.81-1.38 4.58-.41 8.08.8 3.31 1.15 7.06 2.45 11 2.2 3.94-.25 7.49-2 10.62-3.59 3.32-1.66 5.94-3 7.91-1.84 1.59.91 2.42 3.24 2.7 7.54a45.863 45.863 0 0 1-4.63 22.79c-3.69 7.23-8.75 11.41-14.23 11.77l-.76.02ZM97.69 16.49a2.6 2.6 0 0 0-1.63.47c-1.13.86-1.56 3.14-1.32 7a44.783 44.783 0 0 0 7.35 21.5c4.39 6.4 9.63 9.74 14.74 9.41 5.11-.33 9.89-4.32 13.41-11.23a44.681 44.681 0 0 0 4.52-22.26c-.24-3.83-1-6-2.2-6.75-1.5-.86-3.91.35-7 1.87s-6.86 3.43-11 3.7-8.13-1.2-11.36-2.28a19.455 19.455 0 0 0-5.51-1.43Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M111.58 46.93a.996.996 0 0 1-.33-.07 1.268 1.268 0 0 1-.58-1.18 4.533 4.533 0 0 1 1.56-3.68 6.711 6.711 0 0 1 4.12-1.68c3.3-.21 6.14 1.83 6.31 4.54a1.276 1.276 0 0 1-.43 1.25c-.52.32-1.17-.06-2-.58a6.661 6.661 0 0 0-3.58-1.26 6.731 6.731 0 0 0-3.43 1.73 3.287 3.287 0 0 1-1.64.93Zm5.24-5.59h-.41a5.691 5.691 0 0 0-3.5 1.42 3.548 3.548 0 0 0-1.25 2.83c-.009.09-.009.18 0 .27.314-.197.615-.414.9-.65a7.593 7.593 0 0 1 3.94-1.92 7.512 7.512 0 0 1 4.14 1.4c.322.197.656.374 1 .53v-.27c-.11-2.04-2.24-3.61-4.82-3.61ZM124.21 31.77A7.328 7.328 0 0 1 120 30.5a4.992 4.992 0 0 1-2.22-3.73 1.371 1.371 0 0 1 .45-1.33c.56-.35 1.26.07 2.23.64a7.406 7.406 0 0 0 4 1.4 7.492 7.492 0 0 0 3.78-1.91c.88-.68 1.53-1.18 2.12-.91.59.27.59.82.62 1.25.19 3-2.61 5.61-6.25 5.84l-.52.02Zm-5.4-5.44a1.906 1.906 0 0 0 0 .37 3.994 3.994 0 0 0 1.8 3 6.338 6.338 0 0 0 4.08 1.07c3.08-.2 5.47-2.34 5.31-4.77a1.71 1.71 0 0 0-.05-.37 7.121 7.121 0 0 0-1.08.75 8.242 8.242 0 0 1-4.33 2.12 8.337 8.337 0 0 1-4.54-1.56 7.445 7.445 0 0 0-1.19-.61ZM105.2 33c-3.41 0-6.24-2.17-6.42-5a1.36 1.36 0 0 1 .45-1.32c.56-.35 1.26.06 2.22.63a7.477 7.477 0 0 0 4 1.41 7.491 7.491 0 0 0 3.78-1.91c.89-.69 1.53-1.18 2.13-.92.6.26.59.83.61 1.26a4.916 4.916 0 0 1-1.72 4 7.34 7.34 0 0 1-4.53 1.85h-.52Zm-5.42-5.07c.15 2.43 2.79 4.25 5.88 4.05a6.38 6.38 0 0 0 3.91-1.59 4 4 0 0 0 1.4-3.18 2.316 2.316 0 0 0-.06-.37 7.155 7.155 0 0 0-1.07.75 8.201 8.201 0 0 1-4.33 2.11 8.349 8.349 0 0 1-4.57-1.53 6.643 6.643 0 0 0-1.16-.61 1.441 1.441 0 0 0 0 .37Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M101.15 12.22c8.31 13 9.85 32.57.28 38.67-9.57 6.1-26.63-3.53-34.94-16.57-8.31-13.04 6.16-7.46 15.73-13.56 9.57-6.1 10.61-21.58 18.93-8.54Z"
      fill="#fff"
    />
    <path
      d="M94.52 53.27c-9.76 0-21.93-8.46-28.45-18.68-2.32-3.64-3-6-2.28-7.69.92-2.07 3.81-2.57 7.46-3.19 3.45-.58 7.36-1.25 10.7-3.38 3.34-2.13 5.59-5.39 7.57-8.27 2.11-3.05 3.78-5.43 6-5.42 1.84 0 3.69 1.67 6 5.31 8.27 13 10.13 33 .13 39.36a13.118 13.118 0 0 1-7.13 1.96Zm1-45.63c-1.72 0-3.24 2.2-5.16 5s-4.36 6.3-7.87 8.54c-3.51 2.24-7.7 2.95-11.07 3.52-3.37.57-6 1-6.72 2.61-.58 1.3.15 3.5 2.21 6.74 9.77 15.32 26.44 21.41 34.26 16.42 7.82-4.99 9.33-22.66-.44-38-2.07-3.22-3.73-4.8-5.19-4.83h-.02Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M93.46 44.4a4.5 4.5 0 0 1-4.07-2 1.25 1.25 0 0 1-.22-1.29c.3-.54 1-.52 2.08-.49A6.77 6.77 0 0 0 95 40a6.76 6.76 0 0 0 2.14-3.13c.4-.94.7-1.63 1.32-1.68.62-.05.86.41 1.08.75 1.46 2.3.38 5.62-2.41 7.4a6.92 6.92 0 0 1-3.67 1.06Zm-3.36-2.82.13.24a3.55 3.55 0 0 0 2.69 1.55 5.69 5.69 0 0 0 3.66-.92c2.33-1.49 3.27-4.19 2.1-6a2.007 2.007 0 0 0-.15-.22c-.186.32-.347.654-.48 1a7.54 7.54 0 0 1-2.53 3.57 7.43 7.43 0 0 1-4.3.78 8.113 8.113 0 0 0-1.12 0ZM75.65 39.87c-.5 0-.86-.46-1.08-.81a5 5 0 0 1-.42-4.33 7.37 7.37 0 0 1 3.08-3.8c3.07-2 6.87-1.5 8.48 1a1.41 1.41 0 0 1 .29 1.4c-.33.57-1.14.55-2.26.52a7.49 7.49 0 0 0-4.17.69A7.55 7.55 0 0 0 77.13 38c-.44 1-.76 1.78-1.41 1.83l-.07.04Zm5.61-9.16a6.58 6.58 0 0 0-3.5 1.07 6.38 6.38 0 0 0-2.66 3.27 4 4 0 0 0 .31 3.47c.065.108.142.209.23.3a7.63 7.63 0 0 0 .58-1.18A8.26 8.26 0 0 1 79 33.7a8.19 8.19 0 0 1 4.74-.85 7.56 7.56 0 0 0 1.31 0 1.463 1.463 0 0 0-.17-.33 4.15 4.15 0 0 0-3.62-1.81ZM91.72 29.62c-.5 0-.85-.46-1.08-.81a5 5 0 0 1-.41-4.33 7.68 7.68 0 0 1 7.81-5 5.001 5.001 0 0 1 3.74 2.2 1.36 1.36 0 0 1 .25 1.38c-.32.57-1.14.55-2.26.52a7.49 7.49 0 0 0-4.17.69 7.531 7.531 0 0 0-2.39 3.49c-.44 1-.76 1.78-1.42 1.83l-.07.03Zm5.6-9.16a6.48 6.48 0 0 0-3.48 1.07 6.33 6.33 0 0 0-2.66 3.27 3.94 3.94 0 0 0 .31 3.47c.062.107.136.208.22.3a7.62 7.62 0 0 0 .58-1.18 8.261 8.261 0 0 1 2.77-3.94 8.27 8.27 0 0 1 4.74-.85 7.7 7.7 0 0 0 1.32 0 2.563 2.563 0 0 0-.18-.33 3.944 3.944 0 0 0-3-1.74 4.19 4.19 0 0 0-.62-.07ZM195.68 82.79a.431.431 0 0 1-.25-.07l-22.69-13.1a.48.48 0 0 1-.25-.43.503.503 0 0 1 .25-.44l22.69-13.1a.502.502 0 0 1 .5 0 .511.511 0 0 1 .25.44v26.2a.503.503 0 0 1-.5.5ZM174 69.19l21.19 12.23V57L174 69.19Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M176.81 54.06h-35.38a5 5 0 0 0-5 5v20.25a5 5 0 0 0 5 5h35.38a5 5 0 0 0 5-5V59.06a5 5 0 0 0-5-5Z"
      fill="#fff"
    />
    <path
      d="M176.81 84.81h-35.38a5.497 5.497 0 0 1-5.5-5.5V59.06a5.507 5.507 0 0 1 5.5-5.5h35.38a5.51 5.51 0 0 1 5.5 5.5v20.25a5.508 5.508 0 0 1-1.611 3.89 5.497 5.497 0 0 1-3.889 1.61Zm-35.38-30.25a4.509 4.509 0 0 0-4.5 4.5v20.25a4.509 4.509 0 0 0 4.5 4.5h35.38a4.512 4.512 0 0 0 4.5-4.5V59.06a4.513 4.513 0 0 0-4.5-4.5h-35.38Z"
      fill={props.fill || 'currentColor'}
    />
    <path d="M149.03 54.06a8.82 8.82 0 1 0 0-17.64 8.82 8.82 0 0 0 0 17.64Z" fill="#fff" />
    <path
      d="M149 54.56a9.332 9.332 0 0 1-9.15-11.154A9.33 9.33 0 1 1 149 54.56Zm0-17.65a8.33 8.33 0 1 0 0 16.66 8.33 8.33 0 0 0 0-16.66Z"
      fill={props.fill || 'currentColor'}
    />
    <path d="M149.03 50.28a5.04 5.04 0 1 0 0-10.08 5.04 5.04 0 0 0 0 10.08Z" fill="#fff" />
    <path
      d="M149 50.78a5.549 5.549 0 1 1 0-11.098 5.549 5.549 0 0 1 0 11.098Zm0-10.09a4.552 4.552 0 0 0-4.462 5.438 4.548 4.548 0 0 0 6.203 3.316 4.546 4.546 0 0 0 2.809-4.204 4.55 4.55 0 0 0-4.55-4.55Z"
      fill={props.fill || 'currentColor'}
    />
    <path d="M167.94 54.06a8.82 8.82 0 1 0 0-17.64 8.82 8.82 0 0 0 0 17.64Z" fill="#fff" />
    <path
      d="M167.94 54.56a9.328 9.328 0 0 1-6.593-15.93 9.326 9.326 0 0 1 10.171-2.017 9.333 9.333 0 0 1 5.752 8.627 9.33 9.33 0 0 1-9.33 9.32Zm0-17.65a8.332 8.332 0 0 0-8.17 9.955 8.334 8.334 0 0 0 6.545 6.545 8.33 8.33 0 1 0 1.625-16.5Z"
      fill={props.fill || 'currentColor'}
    />
    <path d="M167.94 50.28a5.04 5.04 0 1 0 0-10.08 5.04 5.04 0 0 0 0 10.08Z" fill="#fff" />
    <path
      d="M167.94 50.78a5.552 5.552 0 0 1-5.443-6.637 5.552 5.552 0 0 1 7.573-4.038 5.547 5.547 0 0 1 3.42 5.135 5.554 5.554 0 0 1-5.55 5.54Zm0-10.09a4.55 4.55 0 1 0 0 9.098 4.55 4.55 0 0 0 0-9.098Z"
      fill={props.fill || 'currentColor'}
    />
    <path d="M176.77 59.1h-11.35v7.56h11.35V59.1Z" fill="#fff" />
    <path
      d="M176.77 67.17h-11.35a.51.51 0 0 1-.5-.5V59.1a.508.508 0 0 1 .5-.5h11.35a.512.512 0 0 1 .5.5v7.57a.513.513 0 0 1-.5.5Zm-10.85-1h10.35V59.6h-10.35v6.57ZM159.12 76H144a.501.501 0 1 1 0-1h15.13a.501.501 0 1 1 0 1h-.01ZM159.12 71H144a.501.501 0 1 1 0-1h15.13a.501.501 0 0 1 .5.5.513.513 0 0 1-.51.5ZM193.84 104.69a.435.435 0 0 1-.19 0 .497.497 0 0 1-.31-.4l-1-7.52a.564.564 0 0 1 .08-.34c.14-.22 3.59-5.31 11.38-4 7.23 1.2 13.37-4.85 13.43-4.91a.547.547 0 0 1 .44-.14.517.517 0 0 1 .37.29l3.49 7.56a.512.512 0 0 1-.15.6c-.37.3-9.2 7.18-15.47 5.5-5.79-1.54-11.7 3.23-11.76 3.28a.469.469 0 0 1-.31.08Zm-.48-7.9.86 6.48c1.81-1.27 6.83-4.31 11.95-2.94 5.12 1.37 12.66-3.89 14.28-5.09l-3-6.57c-1.69 1.49-7.22 5.8-13.78 4.7-6.56-1.1-9.67 2.63-10.31 3.42Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M176.62 120.61c11.488 0 20.8-9.313 20.8-20.8 0-11.488-9.312-20.8-20.8-20.8-11.487 0-20.8 9.312-20.8 20.8 0 11.487 9.313 20.8 20.8 20.8Z"
      fill="#fff"
    />
    <path
      d="M176.62 121.11a21.294 21.294 0 0 1-19.678-13.149 21.3 21.3 0 1 1 40.978-8.151 21.319 21.319 0 0 1-21.3 21.3Zm0-41.6a20.302 20.302 0 0 0-19.91 24.26 20.305 20.305 0 0 0 15.95 15.95 20.304 20.304 0 0 0 24.26-19.91 20.317 20.317 0 0 0-20.3-20.3Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M176.62 116.83c9.399 0 17.02-7.62 17.02-17.02 0-9.4-7.621-17.02-17.02-17.02-9.4 0-17.02 7.62-17.02 17.02 0 9.4 7.62 17.02 17.02 17.02Z"
      fill="#fff"
    />
    <path
      d="M176.62 117.33a17.52 17.52 0 1 1 17.51-17.52 17.54 17.54 0 0 1-17.51 17.52Zm0-34a16.521 16.521 0 0 0-3.237 32.722A16.517 16.517 0 0 0 193.13 99.85a16.541 16.541 0 0 0-16.51-16.56v.04Z"
      fill={props.fill || 'currentColor'}
    />
    <path d="M167.79 105.48a5.67 5.67 0 1 0 0-11.34 5.67 5.67 0 0 0 0 11.34Z" fill="#fff" />
    <path
      d="M167.79 106a6.17 6.17 0 1 1 .08-12.34 6.17 6.17 0 0 1-.08 12.34Zm0-11.34a5.167 5.167 0 0 0-5.024 6.21 5.174 5.174 0 0 0 4.082 4.036 5.176 5.176 0 0 0 5.293-2.222A5.17 5.17 0 0 0 173 99.81a5.17 5.17 0 0 0-1.531-3.667 5.176 5.176 0 0 0-3.679-1.503v.02Z"
      fill={props.fill || 'currentColor'}
    />
    <path d="M185.44 105.48a5.67 5.67 0 1 0-.001-11.341 5.67 5.67 0 0 0 .001 11.341Z" fill="#fff" />
    <path
      d="M185.44 106a6.167 6.167 0 0 1-5.701-3.809A6.17 6.17 0 1 1 185.44 106Zm0-11.34a5.17 5.17 0 1 0 5.17 5.17 5.189 5.189 0 0 0-1.511-3.666 5.177 5.177 0 0 0-3.659-1.524v.02Z"
      fill={props.fill || 'currentColor'}
    />
    <path d="M176.62 95.4a5.67 5.67 0 1 0 0-11.34 5.67 5.67 0 0 0 0 11.34Z" fill="#fff" />
    <path
      d="M176.62 95.9a6.178 6.178 0 0 1-5.718-3.81 6.18 6.18 0 1 1 5.718 3.81Zm0-11.35a5.181 5.181 0 0 0-1.025 10.26 5.183 5.183 0 0 0 6.195-5.08 5.182 5.182 0 0 0-5.17-5.18Z"
      fill={props.fill || 'currentColor'}
    />
    <path d="M176.62 115.57a5.67 5.67 0 1 0 0-11.34 5.67 5.67 0 0 0 0 11.34Z" fill="#fff" />
    <path
      d="M176.62 116.07a6.18 6.18 0 1 1-.02-12.36 6.18 6.18 0 0 1 .02 12.36Zm0-11.35a5.18 5.18 0 1 0-.021 10.36 5.18 5.18 0 0 0 .021-10.36ZM201.2 100.94a.501.501 0 0 1-.5-.5v-1.26a.5.5 0 0 1 .5-.5.51.51 0 0 1 .5.5v1.26a.5.5 0 0 1-.5.5ZM206.24 100.94a.501.501 0 0 1-.5-.5v-1.26a.5.5 0 0 1 1 0v1.26a.5.5 0 0 1-.5.5ZM216.33 98.42a.5.5 0 0 1-.5-.5v-1.26a.5.5 0 0 1 1 0v1.26a.503.503 0 0 1-.5.5ZM211.28 100.94a.498.498 0 0 1-.5-.5v-2.52a.51.51 0 0 1 .5-.5.5.5 0 0 1 .5.5v2.52a.5.5 0 0 1-.5.5Z"
      fill={props.fill || 'currentColor'}
    />
    <path d="M119.08 76.36s0 4-2.15 4c0 0 2.15 0 2.15 3 0 0 .14-3 2.15-3 0-.05-2.15.31-2.15-4Z" fill="#fff" />
    <path
      d="M119.08 83.86a.509.509 0 0 1-.49-.5c0-2.52-1.59-2.55-1.65-2.55a.5.5 0 0 1 0-1c1.6 0 1.65-3.41 1.65-3.45a.5.5 0 0 1 1 0c0 2 .47 2.83.87 3.18.165.161.381.26.61.28a.561.561 0 0 1 .67.45.496.496 0 0 1-.41.53.827.827 0 0 1-.22 0c-1.39.15-1.52 2.54-1.52 2.56a.495.495 0 0 1-.315.465.503.503 0 0 1-.195.035Zm-.78-3.57c.342.247.623.568.82.94.169-.348.403-.66.69-.92a2.8 2.8 0 0 1-.81-1.11c-.154.409-.392.78-.7 1.09Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M111.62 108.36s0 6.77-3.69 6.77c0 0 3.69 0 3.69 5.23 0 0 .23-5.23 3.69-5.23 0 0-3.69.62-3.69-6.77Z"
      fill="#fff"
    />
    <path
      d="M111.62 120.86a.51.51 0 0 1-.49-.5c0-4.64-3.06-4.73-3.2-4.73a.5.5 0 1 1 0-1c3.15 0 3.19-6.21 3.19-6.27a.499.499 0 0 1 .5-.5.513.513 0 0 1 .5.49c0 3.58.88 5.09 1.63 5.74.35.319.797.509 1.27.54h.29a.503.503 0 0 1 .5.46.491.491 0 0 1-.42.53 1.318 1.318 0 0 1-.32 0c-2.72.25-2.95 4.7-2.95 4.74a.5.5 0 0 1-.5.5Zm-2-5.73a4.485 4.485 0 0 1 2 2.46 4.75 4.75 0 0 1 1.78-2.45 3.41 3.41 0 0 1-.35-.26 5.26 5.26 0 0 1-1.56-2.71 5.302 5.302 0 0 1-1.84 2.96h-.03Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M12.62 57.36s0 6.77-3.69 6.77c0 0 3.69 0 3.69 5.23 0 0 .23-5.23 3.69-5.23 0 0-3.69.62-3.69-6.77Z"
      fill="#fff"
    />
    <path
      d="M12.62 69.86a.51.51 0 0 1-.49-.5c0-4.64-3.06-4.73-3.2-4.73a.5.5 0 1 1 0-1c3.15 0 3.19-6.21 3.19-6.27a.5.5 0 0 1 .5-.5.51.51 0 0 1 .5.49c0 3.58.88 5.09 1.63 5.74.35.319.797.51 1.27.54h.29a.5.5 0 0 1 .396.81.49.49 0 0 1-.316.18 1.349 1.349 0 0 1-.32 0c-2.72.25-3 4.7-3 4.74a.5.5 0 0 1-.45.5Zm-2-5.73a4.48 4.48 0 0 1 2 2.46c.31-.989.935-1.85 1.78-2.45a3.497 3.497 0 0 1-.35-.26 5.27 5.27 0 0 1-1.56-2.71 5.3 5.3 0 0 1-1.84 2.96h-.03Z"
      fill={props.fill || 'currentColor'}
    />
    <path d="m65.61 75.97 6.11 22.32-20.73-1.67 14.62-20.65Z" fill="#fff" />
    <path
      d="M71.72 98.79 51 97.12a.52.52 0 0 1-.41-.29.5.5 0 0 1 0-.5L65.2 75.68a.51.51 0 0 1 .49-.21.53.53 0 0 1 .4.36l6.12 22.33a.519.519 0 0 1-.1.45.499.499 0 0 1-.39.18Zm-19.81-2.6 19.14 1.55-5.65-20.62-13.49 19.07Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M53.18 92.73s-17.91 1.56-21.86-2.58c0 0-2.38 6.16 2.73 9 0 0-5.56 2.38-8.73 1.3a14.194 14.194 0 0 0 5.86 5.71s-5.35 3.7-8.42 2.29c-3.07-1.41 11 9 22.44 9.22 11.44.22 7.98-24.94 7.98-24.94Z"
      fill="#fff"
    />
    <path
      d="M45.37 118.17h-.18c-10.42-.22-22.94-8.64-23.32-9.62A.558.558 0 0 1 22 108c.3-.28.7-.09 1 0 2 .92 5.44-.72 7.24-1.8a14.673 14.673 0 0 1-5.34-5.53.502.502 0 0 1 .322-.699.5.5 0 0 1 .288.01c2.22.749 5.81-.34 7.47-.94-4.3-3.19-2.12-9-2.1-9.07a.509.509 0 0 1 .83-.17c3.75 3.92 21.28 2.45 21.46 2.44a.479.479 0 0 1 .53.43c.1.69 2.27 17.089-3.2 23.209a6.573 6.573 0 0 1-5.13 2.29Zm-21.13-8.9c3.55 2.43 12.82 7.73 21 7.9a5.568 5.568 0 0 0 4.52-2c4.66-5.23 3.33-19.21 3-22-2.82.21-16.62 1.06-21.17-2.25-.43 1.62-1 5.61 2.72 7.7a.49.49 0 0 1 .171.718.5.5 0 0 1-.21.172c-.21.09-4.6 1.94-7.9 1.57a14.003 14.003 0 0 0 5.06 4.54.494.494 0 0 1 .221.662.52.52 0 0 1-.162.198c-.21.23-4.09 2.89-7.25 2.79Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M65.94 93.33s18 .78 21.73-3.53c0 0 2.64 6.06-2.34 9.12 0 0 5.65 2.13 8.77.91a14.19 14.19 0 0 1-5.61 6s5.51 3.43 8.51 1.89c3-1.54-10.63 9.44-22 10.18-11.37.74-9.06-24.57-9.06-24.57Z"
      fill="#fff"
    />
    <path
      d="M74.5 118.41a6.545 6.545 0 0 1-4.86-2.07c-5.73-5.89-4.27-22.36-4.2-23.06a.5.5 0 0 1 .52-.45c.18 0 17.76.72 21.33-3.36a.49.49 0 0 1 .45-.16.46.46 0 0 1 .38.29c0 .06 2.46 5.78-1.7 9.16 1.69.52 5.32 1.46 7.5.61a.49.49 0 0 1 .54.12.51.51 0 0 1 .1.55 14.596 14.596 0 0 1-5.09 5.74c1.54.84 5.19 2.58 7.31 1.5.26-.14.66-.34 1-.08a.572.572 0 0 1 .16.58c-.33 1-12.48 9.94-22.89 10.62l-.55.01Zm-8.1-24.57c-.2 2.76-.93 16.78 3.95 21.8A5.586 5.586 0 0 0 75 117.4c8.13-.53 17.17-6.22 20.61-8.8-3.17.2-7.14-2.26-7.33-2.38a.514.514 0 0 1-.24-.43.53.53 0 0 1 .25-.43 13.998 13.998 0 0 0 4.87-4.75c-3.28.51-7.75-1.14-8-1.23a.5.5 0 0 1-.09-.89c3.66-2.24 2.89-6.21 2.4-7.81-4.42 3.51-18.25 3.25-21.07 3.16Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M60.31 121.87c10.875 0 19.69-6.814 19.69-15.22s-8.815-15.22-19.69-15.22c-10.874 0-19.69 6.814-19.69 15.22s8.816 15.22 19.69 15.22Z"
      fill="#fff"
    />
    <path
      d="M60.31 122.36c-11.14 0-20.19-7-20.19-15.71s9.05-15.72 20.19-15.72S80.5 98 80.5 106.65s-9.06 15.71-20.19 15.71Zm0-30.43c-10.58 0-19.19 6.6-19.19 14.72 0 8.12 8.61 14.71 19.19 14.71 10.58 0 19.19-6.6 19.19-14.71 0-8.11-8.61-14.72-19.19-14.72Z"
      fill={props.fill || 'currentColor'}
    />
    <path d="M60.31 110.23h13c0 4.91-5.86 9-13 9s-12.9-4-13-8.86l13-.14Z" fill="#fff" />
    <path
      d="M60.31 119.68c-7.35 0-13.4-4.2-13.48-9.35A.5.5 0 0 1 47 110a.522.522 0 0 1 .36-.16l13-.09h13a.503.503 0 0 1 .5.5c-.07 5.19-6.12 9.43-13.55 9.43Zm-12.45-8.86c.46 4.38 5.89 7.86 12.45 7.86s12.07-3.53 12.45-8H60.31l-12.45.14Z"
      fill={props.fill || 'currentColor'}
    />
    <path d="M60.31 112.92a4.48 4.48 0 1 0 0-8.96 4.48 4.48 0 0 0 0 8.96Z" fill="#fff" />
    <path
      d="M60.31 113.41a5.003 5.003 0 0 1-4.62-3.086 5 5 0 1 1 4.62 3.086Zm0-9a4.004 4.004 0 0 0-3.696 2.469 4.003 4.003 0 0 0 .867 4.36 4 4 0 0 0 6.829-2.829 4 4 0 0 0-4-3.95v-.05Z"
      fill={props.fill || 'currentColor'}
    />
    <path d="M64.93 78.9a2.69 2.69 0 1 0 0-5.38 2.69 2.69 0 0 0 0 5.38Z" fill="#fff" />
    <path
      d="M64.93 79.4a3.19 3.19 0 1 1 0-6.38 3.19 3.19 0 0 1 0 6.38Zm0-5.37a2.19 2.19 0 1 0 2.19 2.18A2.188 2.188 0 0 0 64.93 74v.03ZM68.51 87.45a.48.48 0 0 1-.26-.07l-7.16-4.48a.491.491 0 0 1-.16-.69.5.5 0 0 1 .69-.15l7.16 4.47a.5.5 0 0 1-.27.92ZM69.41 93.72a.53.53 0 0 1-.24-.06l-11.64-6.27a.501.501 0 1 1 .48-.88l11.63 6.27a.502.502 0 0 1 .21.68.52.52 0 0 1-.44.26ZM69.18 106.05a.749.749 0 0 1-.75-.75v-5.82a.75.75 0 1 1 1.5 0v5.82a.742.742 0 0 1-.75.75Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M72.09 103.14h-5.82a.749.749 0 1 1 0-1.5h5.82a.752.752 0 0 1 .75.75.75.75 0 0 1-.75.75ZM52.18 106.05a.75.75 0 0 1-.534-.217.744.744 0 0 1-.216-.533v-5.82a.75.75 0 0 1 1.5 0v5.82a.748.748 0 0 1-.75.75Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M55.09 103.14h-5.82a.749.749 0 1 1 0-1.5h5.82a.752.752 0 0 1 .75.75.75.75 0 0 1-.75.75ZM58.17 109.21a.493.493 0 0 1-.46-.32 2.293 2.293 0 0 1 .509-2.507 2.28 2.28 0 0 1 .751-.493.501.501 0 1 1 .38.93 1.29 1.29 0 0 0-.71 1.68.5.5 0 0 1-.28.65.637.637 0 0 1-.19.06Z"
      fill={props.fill || 'currentColor'}
    />
    <path d="M24.43 83.86a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Z" fill="#fff" />
    <path
      d="M24.43 84.36a5 5 0 1 1 0-10 5 5 0 0 1 0 10Zm0-9a4 4 0 1 0 0 8.001 4 4 0 0 0 0-8Z"
      fill={props.fill || 'currentColor'}
    />
    <path d="M45.43 68.86a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Z" fill="#fff" />
    <path
      d="M45.43 69.36a5 5 0 1 1 0-10 5 5 0 0 1 0 10Zm0-9a4 4 0 1 0 0 8.001 4 4 0 0 0 0-8Z"
      fill={props.fill || 'currentColor'}
    />
    <path d="M69.43 63.86a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Z" fill="#fff" />
    <path
      d="M69.43 64.36a5 5 0 1 1 0-10 5 5 0 0 1 0 10Zm0-9a4 4 0 1 0 0 8.001 4 4 0 0 0 0-8Z"
      fill={props.fill || 'currentColor'}
    />
    <path d="M92.43 79.86a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Z" fill="#fff" />
    <path
      d="M92.43 80.36a5 5 0 1 1 0-10 5 5 0 0 1 0 10Zm0-9a4 4 0 1 0 0 8.001 4 4 0 0 0 0-8Z"
      fill={props.fill || 'currentColor'}
    />
    <path d="M105.43 98.86a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Z" fill="#fff" />
    <path
      d="M105.43 99.36a5 5 0 1 1 0-10 5 5 0 0 1 0 10Zm0-9a4.003 4.003 0 0 0-3.696 2.47 4 4 0 1 0 3.696-2.47Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default TheatreCircusCinema;
