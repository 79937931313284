import { deserializeMeta } from 'api/utils';

import { ApiHeaders, ApiHeadersOptions } from './types';

export const prepareHeaders = (requestHeaders: ApiHeaders, options?: ApiHeadersOptions): ApiHeaders => {
  const optionalHeaders: Record<string, string | undefined> = {
    Authorization: options?.token && `Bearer ${options.token}`,
    ['X-Api-Key']: options?.xApiKey,
  };

  const optionalKeys = Object.keys(optionalHeaders);
  optionalKeys.forEach((headerKey) => {
    if (!optionalHeaders[headerKey]) {
      delete optionalHeaders[headerKey];
    }
  });

  return {
    ...requestHeaders,
    ...optionalHeaders,
    'X-Mes-Subsystem': 'studentportfolioweb',
  };
};

export const processBaseResponse = async (response: Response) => {
  const contentTypeHeader = response.headers.get('content-type');
  const metaHeader = response.headers.get('meta');
  const meta = metaHeader ? deserializeMeta(metaHeader) : undefined;

  if (!response.ok) {
    if (!contentTypeHeader?.includes('application/json')) {
      throw { statusCode: response.status };
    }

    const serviceResponse = await response.json();
    throw { ...serviceResponse, statusCode: response.status, meta };
  }

  if (contentTypeHeader?.includes('application/json') || contentTypeHeader?.includes('text/json')) {
    const responseJson = await response.json();

    // Некоторые методы могут возвращать массив (например методы из vuz-api)
    if (Array.isArray(responseJson)) {
      return responseJson;
    }

    return { ...responseJson, meta };
  }

  if (contentTypeHeader?.includes('application/octet-stream') || contentTypeHeader?.includes('application/pdf')) {
    const result = await response.blob();
    return {
      fileName: response.headers.get('content-disposition')?.split('filename=')?.[1],
      blob: result,
    };
  }
};
