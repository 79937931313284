import { IGiaItem } from 'api/types';
import { AUTOMATICALLY_ADDED_SOURCE_VALUE, EntityType, GiaType, LocalRoleTags, SectionCodes, SourceCode } from 'const';
import { getSourceValue, isDefined, isProfessionalEducationEntity } from 'utils';

import { IActionPermissionsRequest } from '../types';

/**
 * Функция определяет доступ пользователя к действию ***редактировать*** у сущности
 */
export const hasEditorPermission = ({ formData, userFunctionality }: IActionPermissionsRequest): boolean => {
  const sourceCode = getFormDataSourceCode(formData);
  const isCurrentUserStudent = userFunctionality.isStudent;
  const isCurrentUserParent = userFunctionality.isParent;
  const isSourceCodeStudent = sourceCode === SourceCode.student;
  const isSourceCodeParent = sourceCode === SourceCode.parent;

  const typeCode: number | undefined = formData?.typeCode || formData?.type?.code;
  const hasRequiredRole = isCurrentUserStudent || isCurrentUserParent;
  const isCheckIn = Boolean(formData?.isCheckIn);

  // проверка на read only админа не требуется, редактирование доступно только для ученика и родителя

  if (!hasRequiredRole) return false;
  if (isCheckIn) return false;

  // редактирование трудоустройства доступно для любого источника данных,
  // при условии что сущность добавил не ученик или родитель
  if (typeCode === SectionCodes.professionSpoJob && !isSourceCodeStudent && !isSourceCodeParent) {
    return true;
  }

  // проверка на соответствие роли источнику данных
  if ((isCurrentUserStudent && isSourceCodeStudent) || (isCurrentUserParent && isSourceCodeParent)) {
    return true;
  }

  return false;
};

/**
 * Функция определяет доступ пользователя к действию ***удалить*** у сущности
 */
export const hasDeletePermission = (request: IActionPermissionsRequest): boolean => {
  const { formData, entityFlags, userFunctionality, userLocalRoles, adminViewMode, adminViewType } = request;

  const sourceCode = getFormDataSourceCode(formData);
  const isProfessionalEducation = isProfessionalEducationEntity(formData);

  const isCurrentUserStudent = userFunctionality.isStudent;
  const isCurrentUserParent = userFunctionality.isParent;
  const isCurrentUserAdmin = userFunctionality.isAdmin;
  const isSourceCodeStudent = sourceCode === SourceCode.student;
  const isSourceCodeParent = sourceCode === SourceCode.parent;

  const isAdminViewingInParentMode = isCurrentUserAdmin && adminViewMode && adminViewType === 'parent';

  const isUserDeletable = (isCurrentUserStudent && isSourceCodeStudent) || (isCurrentUserParent && isSourceCodeParent);

  // админ может удалять только записи, у которых sourceCode IN (10,11,12,13,14)
  const isAdminSourceCodeDeletable = [
    SourceCode.student,
    SourceCode.parent,
    SourceCode.operator,
    SourceCode.employeeOo,
    SourceCode.admin,
  ].includes(sourceCode);
  const isAdminDeletable =
    isCurrentUserAdmin && userLocalRoles.includes(LocalRoleTags.adminOperator) && isAdminSourceCodeDeletable;

  if (isAdminViewingInParentMode) return false;
  if (isProfessionalEducation) return false;
  if (entityFlags?.includes('undeletable')) return false;

  return isUserDeletable || isAdminDeletable || Boolean(formData?.isCheckIn);
};

/**
 * Функция определяет доступ пользователя к действию ***сообщить об ошибке*** у сущности
 */
export const hasReportPermission = (request: IActionPermissionsRequest): boolean => {
  const { formData, userFunctionality, entityType } = request;

  const { isStudent, isParent } = userFunctionality;
  const hasRequiredRole = isStudent || isParent;

  if (!hasRequiredRole) return false;

  // предварительная проверка на конкретные типы сущностей, у которых должно быть действие сообщить об ошибке
  if (entityType === EntityType.TRAINING_INFO || entityType === EntityType.PRACTICE) {
    return true;
  }

  if (entityType === EntityType.GOVEXAM) {
    return true;
  }

  const sourceCode = getFormDataSourceCode(formData);
  const hasSourceCode = isDefined(sourceCode);

  const hasEntityId = Boolean(formData?.id) || Boolean(formData?.recordId);
  const isCheckin = Boolean(formData.isCheckIn);
  const isCreatedByUser = sourceCode === SourceCode.parent || sourceCode === SourceCode.student;
  const isAutomaticallyAdded = formData.source && getSourceValue(formData.source) === AUTOMATICALLY_ADDED_SOURCE_VALUE;

  if (!hasEntityId) return false;
  if (isCheckin) return false;
  if (!hasSourceCode) return false;
  if (isAutomaticallyAdded) return false;

  return !isCreatedByUser;
};

/**
 * Функция определяет доступ пользователя к действию ***сказать спасибо учителю*** у сущности
 */
export const hasThanksTeacherPermission = ({
  formData,
  userFunctionality,
  entityType,
}: IActionPermissionsRequest): boolean => {
  const isCurrentUserStudent = userFunctionality.isStudent;
  const isCurrentUserParent = userFunctionality.isParent;

  const hasRequiredRole = isCurrentUserStudent || isCurrentUserParent;

  /**
   * разрешены
   *
   * events (typeCodes 8, 15, 16, 44, 57)
   * exams (*)
   * project (*)
   * reward (*)
   * sport reward (*)
   */

  const allowedEventTypeCodes = [
    SectionCodes.eventOlympiad,
    SectionCodes.contest,
    SectionCodes.conference,
    SectionCodes.creationContest,
    SectionCodes.civilEventVolonteer,
  ];

  const allowedEntityTypes = [EntityType.PROJECT, EntityType.REWARD, EntityType.SPORT_REWARD];

  const isAllowedEvent = allowedEventTypeCodes.includes(formData?.typeCode);
  const isAllowedEntityType = isDefined(entityType) && allowedEntityTypes.includes(entityType);

  if (!hasRequiredRole) return false;

  if (entityType === EntityType.GOVEXAM) {
    const exam = formData as IGiaItem | undefined;

    const allowedExamTypes = [GiaType.oge, GiaType.ege];
    const isAllowedExamType = isDefined(exam) && allowedExamTypes.includes(exam?.formaGia);
    return isAllowedExamType && !exam?.approbation;
  }

  return isAllowedEvent || isAllowedEntityType;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getFormDataSourceCode(formData: any) {
  return formData?.source?.code;
}
