/* eslint-disable prettier/prettier */
/* eslint-disable */

export const Gradients = {
  GREEN: [
    {
      color: '#A8E063',
      offset: '0%'
    },
    {
      color: '#56AB2F',
      offset: '100%'
    }
  ],
  ORANGE: [
    {
      color: '#FFD200',
      offset: '0%'
    },
    {
      color: '#F7971E',
      offset: '100%'
    }
  ],
  RED: [
    {
      color: '#FF9966',
      offset: '0%'
    },
    {
      color: '#FF5E62',
      offset: '100%'
    }
  ]
};

export const DoughnutColors = {
  GREEN: '#69DB7C',
  YELLOW: '#FFD43B',
  GOLD: '#FFCB21',
  SILVER: '#BEBEBE',
  BRONZE: '#F4A275',
  GRAY: '#D7D9E1',
  RED: '#FD8A70' // TODO, исправить, когда добавят красный цвет на макет
};
