import { FC } from 'react';

import { Stack, Typography } from '@mui/material';
import { DiagnosticLevelColorSet1 } from 'const';
import { getDiagnosticLevelTypeColor } from 'utils';

interface IDiagnosticCardResultDescription {
  resultValue: number;
  maxResult: number;
  levelType?: string;
}

const DiagnosticCardResultDescription: FC<IDiagnosticCardResultDescription> = ({
  resultValue,
  maxResult,
  levelType,
}) => {
  const levelTypeColor = getDiagnosticLevelTypeColor(levelType || '', DiagnosticLevelColorSet1);

  return (
    <Stack>
      <Typography variant="Paragraph MD/Regular">
        <Typography variant="Paragraph MD/Bold" component="span">
          {resultValue} баллов
        </Typography>{' '}
        из {maxResult}
      </Typography>
      {levelType && (
        <Typography variant="Paragraph MD/Semi Bold" color={levelTypeColor}>
          {levelType.toLowerCase()}
        </Typography>
      )}
    </Stack>
  );
};

export default DiagnosticCardResultDescription;
