import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconGridFilled: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M5 3h4c1.103 0 2 .897 2 2v4c0 1.103-.897 2-2 2H5c-1.103 0-2-.897-2-2V5c0-1.103.897-2 2-2Zm10 0h4c1.103 0 2 .897 2 2v4c0 1.103-.897 2-2 2h-4c-1.103 0-2-.897-2-2V5c0-1.103.897-2 2-2ZM9 13H5c-1.103 0-2 .897-2 2v4c0 1.103.897 2 2 2h4c1.103 0 2-.897 2-2v-4c0-1.103-.897-2-2-2Zm6 0h4c1.103 0 2 .897 2 2v4c0 1.103-.897 2-2 2h-4c-1.103 0-2-.897-2-2v-4c0-1.103.897-2 2-2Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconGridFilled;
