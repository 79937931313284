/* eslint-disable max-len */
import { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const Fox: FC<SvgIconProps> = (props) => (
  <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M28.9284 19H26.7884C26.3384 19 25.9784 18.64 25.9784 18.19V18V10.42C25.9784 7.33 24.5584 4.15 22.1584 2.24C21.9684 2.08 21.7184 2 21.4684 2C21.2184 2 20.9684 2.08 20.7684 2.24C19.0184 3.64 17.7984 5.84 17.2684 8H14.6884C14.1584 5.84 12.9384 3.64 11.1884 2.24C10.9984 2.08 10.7484 2 10.4984 2C10.2484 2 9.99844 2.08 9.79844 2.24C7.40844 4.15 5.98844 7.32 5.98844 10.42V18V18.19C5.98844 18.64 5.62844 19 5.17844 19H3.03844C2.62844 19 2.32844 19.4 2.43844 19.8C4.09844 25.68 9.49845 30 15.9184 30C15.9384 30 15.9684 30 15.9884 30C16.0084 30 16.0384 30 16.0584 30C22.4684 30 27.8784 25.69 29.5384 19.8C29.6484 19.4 29.3484 19 28.9284 19Z"
      fill="url(#paint0_radial_64_89854)"
    />
    <path
      d="M28.9284 19H26.7884C26.3384 19 25.9784 18.64 25.9784 18.19V18V10.42C25.9784 7.33 24.5584 4.15 22.1584 2.24C21.9684 2.08 21.7184 2 21.4684 2C21.2184 2 20.9684 2.08 20.7684 2.24C19.0184 3.64 17.7984 5.84 17.2684 8H14.6884C14.1584 5.84 12.9384 3.64 11.1884 2.24C10.9984 2.08 10.7484 2 10.4984 2C10.2484 2 9.99844 2.08 9.79844 2.24C7.40844 4.15 5.98844 7.32 5.98844 10.42V18V18.19C5.98844 18.64 5.62844 19 5.17844 19H3.03844C2.62844 19 2.32844 19.4 2.43844 19.8C4.09844 25.68 9.49845 30 15.9184 30C15.9384 30 15.9684 30 15.9884 30C16.0084 30 16.0384 30 16.0584 30C22.4684 30 27.8784 25.69 29.5384 19.8C29.6484 19.4 29.3484 19 28.9284 19Z"
      fill="url(#paint1_radial_64_89854)"
    />
    <path
      d="M28.9284 19H26.7884C26.3384 19 25.9784 18.64 25.9784 18.19V18V10.42C25.9784 7.33 24.5584 4.15 22.1584 2.24C21.9684 2.08 21.7184 2 21.4684 2C21.2184 2 20.9684 2.08 20.7684 2.24C19.0184 3.64 17.7984 5.84 17.2684 8H14.6884C14.1584 5.84 12.9384 3.64 11.1884 2.24C10.9984 2.08 10.7484 2 10.4984 2C10.2484 2 9.99844 2.08 9.79844 2.24C7.40844 4.15 5.98844 7.32 5.98844 10.42V18V18.19C5.98844 18.64 5.62844 19 5.17844 19H3.03844C2.62844 19 2.32844 19.4 2.43844 19.8C4.09844 25.68 9.49845 30 15.9184 30C15.9384 30 15.9684 30 15.9884 30C16.0084 30 16.0384 30 16.0584 30C22.4684 30 27.8784 25.69 29.5384 19.8C29.6484 19.4 29.3484 19 28.9284 19Z"
      fill="url(#paint2_radial_64_89854)"
    />
    <ellipse
      cx="15.9884"
      cy="17.6314"
      rx="9.76748"
      ry="9.66262"
      fill="url(#paint3_radial_64_89854)"
      fillOpacity="0.5"
    />
    <g filter="url(#filter0_f_64_89854)">
      <path
        d="M20.4241 3.89062C19.9866 4.44271 18.7209 6.03125 18.3459 7.96875"
        stroke="url(#paint4_linear_64_89854)"
        strokeWidth="0.25"
        strokeLinecap="round"
      />
    </g>
    <g filter="url(#filter1_f_64_89854)">
      <path
        d="M24.074 5.19336C24.824 6.22461 25.574 8.25586 25.574 12.0684"
        stroke="#FFB160"
        strokeWidth="0.25"
        strokeLinecap="round"
      />
    </g>
    <g filter="url(#filter2_f_64_89854)">
      <path
        d="M12.4884 4.34961C12.9821 5.02833 13.8459 6.70288 14.0022 7.95288"
        stroke="#FFB160"
        strokeWidth="0.25"
        strokeLinecap="round"
      />
    </g>
    <path
      d="M22.1685 2.24C21.9685 2.08 21.7185 2 21.4685 2C21.2185 2 20.9685 2.08 20.7685 2.24C19.5685 3.21 18.6085 4.55 17.9585 6H25.0285C24.3685 4.53 23.3985 3.22 22.1685 2.24Z"
      fill="url(#paint5_linear_64_89854)"
    />
    <path
      d="M22.1685 2.24C21.9685 2.08 21.7185 2 21.4685 2C21.2185 2 20.9685 2.08 20.7685 2.24C19.5685 3.21 18.6085 4.55 17.9585 6H25.0285C24.3685 4.53 23.3985 3.22 22.1685 2.24Z"
      fill="url(#paint6_linear_64_89854)"
    />
    <path
      d="M22.1685 2.24C21.9685 2.08 21.7185 2 21.4685 2C21.2185 2 20.9685 2.08 20.7685 2.24C19.5685 3.21 18.6085 4.55 17.9585 6H25.0285C24.3685 4.53 23.3985 3.22 22.1685 2.24Z"
      fill="url(#paint7_linear_64_89854)"
    />
    <path
      d="M23.9829 12.0023C24.1338 12.204 24.4785 12.1012 24.4785 11.8493V10.4203C24.4785 7.94031 23.4185 5.61031 21.5885 3.99031C21.5185 3.93031 21.4085 3.93031 21.3385 3.99031C20.1738 5.03057 19.3252 6.35541 18.8632 7.82427C18.7665 8.13177 18.9545 8.44816 19.259 8.55385C21.1637 9.21491 22.8041 10.4267 23.9829 12.0023Z"
      fill="url(#paint8_linear_64_89854)"
    />
    <path
      d="M23.9829 12.0023C24.1338 12.204 24.4785 12.1012 24.4785 11.8493V10.4203C24.4785 7.94031 23.4185 5.61031 21.5885 3.99031C21.5185 3.93031 21.4085 3.93031 21.3385 3.99031C20.1738 5.03057 19.3252 6.35541 18.8632 7.82427C18.7665 8.13177 18.9545 8.44816 19.259 8.55385C21.1637 9.21491 22.8041 10.4267 23.9829 12.0023Z"
      fill="url(#paint9_linear_64_89854)"
    />
    <path
      d="M23.9829 12.0023C24.1338 12.204 24.4785 12.1012 24.4785 11.8493V10.4203C24.4785 7.94031 23.4185 5.61031 21.5885 3.99031C21.5185 3.93031 21.4085 3.93031 21.3385 3.99031C20.1738 5.03057 19.3252 6.35541 18.8632 7.82427C18.7665 8.13177 18.9545 8.44816 19.259 8.55385C21.1637 9.21491 22.8041 10.4267 23.9829 12.0023Z"
      fill="url(#paint10_linear_64_89854)"
    />
    <path
      d="M23.9829 12.0023C24.1338 12.204 24.4785 12.1012 24.4785 11.8493V10.4203C24.4785 7.94031 23.4185 5.61031 21.5885 3.99031C21.5185 3.93031 21.4085 3.93031 21.3385 3.99031C20.1738 5.03057 19.3252 6.35541 18.8632 7.82427C18.7665 8.13177 18.9545 8.44816 19.259 8.55385C21.1637 9.21491 22.8041 10.4267 23.9829 12.0023Z"
      fill="url(#paint11_linear_64_89854)"
    />
    <path
      d="M23.9829 12.0023C24.1338 12.204 24.4785 12.1012 24.4785 11.8493V10.4203C24.4785 7.94031 23.4185 5.61031 21.5885 3.99031C21.5185 3.93031 21.4085 3.93031 21.3385 3.99031C20.1738 5.03057 19.3252 6.35541 18.8632 7.82427C18.7665 8.13177 18.9545 8.44816 19.259 8.55385C21.1637 9.21491 22.8041 10.4267 23.9829 12.0023Z"
      fill="url(#paint12_radial_64_89854)"
    />
    <path
      d="M23.9829 12.0023C24.1338 12.204 24.4785 12.1012 24.4785 11.8493V10.4203C24.4785 7.94031 23.4185 5.61031 21.5885 3.99031C21.5185 3.93031 21.4085 3.93031 21.3385 3.99031C20.1738 5.03057 19.3252 6.35541 18.8632 7.82427C18.7665 8.13177 18.9545 8.44816 19.259 8.55385C21.1637 9.21491 22.8041 10.4267 23.9829 12.0023Z"
      fill="url(#paint13_linear_64_89854)"
    />
    <g filter="url(#filter3_f_64_89854)">
      <path
        d="M19.5623 20.2812C19.1523 20.2812 18.8123 19.9413 18.8123 19.5312V18.0312C18.8123 17.6213 19.1523 17.2812 19.5623 17.2812C19.9723 17.2812 20.3123 17.6213 20.3123 18.0312V19.5312C20.3123 19.9413 19.9723 20.2812 19.5623 20.2812Z"
        fill="#CD5C25"
      />
    </g>
    <g filter="url(#filter4_f_64_89854)">
      <path
        d="M11.9822 20.2812C11.5722 20.2812 11.2322 19.9413 11.2322 19.5312V18.0312C11.2322 17.6213 11.5722 17.2812 11.9822 17.2812C12.3922 17.2812 12.7322 17.6213 12.7322 18.0312V19.5312C12.7322 19.9413 12.4022 20.2812 11.9822 20.2812Z"
        fill="#CD5C25"
      />
    </g>
    <path
      d="M19.8086 20C19.3986 20 19.0586 19.66 19.0586 19.25V17.75C19.0586 17.34 19.3986 17 19.8086 17C20.2186 17 20.5586 17.34 20.5586 17.75V19.25C20.5586 19.66 20.2186 20 19.8086 20Z"
      fill="url(#paint14_linear_64_89854)"
    />
    <path
      d="M19.8086 20C19.3986 20 19.0586 19.66 19.0586 19.25V17.75C19.0586 17.34 19.3986 17 19.8086 17C20.2186 17 20.5586 17.34 20.5586 17.75V19.25C20.5586 19.66 20.2186 20 19.8086 20Z"
      fill="url(#paint15_linear_64_89854)"
    />
    <path
      d="M12.2285 20C11.8185 20 11.4785 19.66 11.4785 19.25V17.75C11.4785 17.34 11.8185 17 12.2285 17C12.6385 17 12.9785 17.34 12.9785 17.75V19.25C12.9785 19.66 12.6485 20 12.2285 20Z"
      fill="url(#paint16_linear_64_89854)"
    />
    <path
      d="M12.2285 20C11.8185 20 11.4785 19.66 11.4785 19.25V17.75C11.4785 17.34 11.8185 17 12.2285 17C12.6385 17 12.9785 17.34 12.9785 17.75V19.25C12.9785 19.66 12.6485 20 12.2285 20Z"
      fill="url(#paint17_linear_64_89854)"
    />
    <g filter="url(#filter5_f_64_89854)">
      <path
        d="M8.73413 5.07812C8.29663 5.63021 6.96851 7.6375 6.96851 10.0625C6.96851 12.4875 6.96851 12.25 6.96851 12.0938"
        stroke="url(#paint18_linear_64_89854)"
        strokeWidth="0.25"
        strokeLinecap="round"
      />
    </g>
    <path
      d="M13.9885 5.95C13.3385 4.52 12.3885 3.2 11.1985 2.24C10.9985 2.08 10.7485 2 10.4985 2C10.2485 2 9.99851 2.08 9.79851 2.24C8.58851 3.21 7.61851 4.5 6.96851 5.95H13.9885Z"
      fill="url(#paint19_linear_64_89854)"
    />
    <path
      d="M13.9885 5.95C13.3385 4.52 12.3885 3.2 11.1985 2.24C10.9985 2.08 10.7485 2 10.4985 2C10.2485 2 9.99851 2.08 9.79851 2.24C8.58851 3.21 7.61851 4.5 6.96851 5.95H13.9885Z"
      fill="url(#paint20_linear_64_89854)"
    />
    <path
      d="M13.9885 5.95C13.3385 4.52 12.3885 3.2 11.1985 2.24C10.9985 2.08 10.7485 2 10.4985 2C10.2485 2 9.99851 2.08 9.79851 2.24C8.58851 3.21 7.61851 4.5 6.96851 5.95H13.9885Z"
      fill="url(#paint21_linear_64_89854)"
    />
    <path
      d="M29.1384 20.9902H22.6784C20.9284 20.9902 19.2184 21.5402 17.7984 22.5602L15.9884 23.8502L14.1784 22.5602C12.7584 21.5402 11.0484 20.9902 9.29837 20.9902H2.82837C4.83837 26.2602 9.93837 30.0002 15.9084 30.0002C15.9284 30.0002 15.9584 30.0002 15.9784 30.0002C15.9984 30.0002 16.0284 30.0002 16.0484 30.0002C22.0284 29.9902 27.1284 26.2502 29.1384 20.9902Z"
      fill="url(#paint22_radial_64_89854)"
    />
    <path
      d="M29.1384 20.9902H22.6784C20.9284 20.9902 19.2184 21.5402 17.7984 22.5602L15.9884 23.8502L14.1784 22.5602C12.7584 21.5402 11.0484 20.9902 9.29837 20.9902H2.82837C4.83837 26.2602 9.93837 30.0002 15.9084 30.0002C15.9284 30.0002 15.9584 30.0002 15.9784 30.0002C15.9984 30.0002 16.0284 30.0002 16.0484 30.0002C22.0284 29.9902 27.1284 26.2502 29.1384 20.9902Z"
      fill="url(#paint23_radial_64_89854)"
    />
    <path
      d="M29.1384 20.9902H22.6784C20.9284 20.9902 19.2184 21.5402 17.7984 22.5602L15.9884 23.8502L14.1784 22.5602C12.7584 21.5402 11.0484 20.9902 9.29837 20.9902H2.82837C4.83837 26.2602 9.93837 30.0002 15.9084 30.0002C15.9284 30.0002 15.9584 30.0002 15.9784 30.0002C15.9984 30.0002 16.0284 30.0002 16.0484 30.0002C22.0284 29.9902 27.1284 26.2502 29.1384 20.9902Z"
      fill="url(#paint24_radial_64_89854)"
    />
    <path
      d="M29.1384 20.9902H22.6784C20.9284 20.9902 19.2184 21.5402 17.7984 22.5602L15.9884 23.8502L14.1784 22.5602C12.7584 21.5402 11.0484 20.9902 9.29837 20.9902H2.82837C4.83837 26.2602 9.93837 30.0002 15.9084 30.0002C15.9284 30.0002 15.9584 30.0002 15.9784 30.0002C15.9984 30.0002 16.0284 30.0002 16.0484 30.0002C22.0284 29.9902 27.1284 26.2502 29.1384 20.9902Z"
      fill="url(#paint25_radial_64_89854)"
    />
    <path
      d="M29.1384 20.9902H22.6784C20.9284 20.9902 19.2184 21.5402 17.7984 22.5602L15.9884 23.8502L14.1784 22.5602C12.7584 21.5402 11.0484 20.9902 9.29837 20.9902H2.82837C4.83837 26.2602 9.93837 30.0002 15.9084 30.0002C15.9284 30.0002 15.9584 30.0002 15.9784 30.0002C15.9984 30.0002 16.0284 30.0002 16.0484 30.0002C22.0284 29.9902 27.1284 26.2502 29.1384 20.9902Z"
      fill="url(#paint26_radial_64_89854)"
    />
    <path
      d="M29.1384 20.9902H22.6784C20.9284 20.9902 19.2184 21.5402 17.7984 22.5602L15.9884 23.8502L14.1784 22.5602C12.7584 21.5402 11.0484 20.9902 9.29837 20.9902H2.82837C4.83837 26.2602 9.93837 30.0002 15.9084 30.0002C15.9284 30.0002 15.9584 30.0002 15.9784 30.0002C15.9984 30.0002 16.0284 30.0002 16.0484 30.0002C22.0284 29.9902 27.1284 26.2502 29.1384 20.9902Z"
      fill="url(#paint27_radial_64_89854)"
    />
    <g filter="url(#filter6_f_64_89854)">
      <path
        d="M15.1708 23.9533L14.0613 22.8438C13.5213 22.3038 13.9042 21.3906 14.6701 21.3906H16.8891C17.6451 21.3906 17.0266 21.8662 16.4866 22.4062L16.3785 23.9533C16.3481 23.9838 16.3159 24.0115 16.2823 24.0364C16.305 25.169 17.2252 26.075 18.3634 26.075C18.6346 26.075 18.8544 26.2948 18.8544 26.5659C18.8544 26.8371 18.6346 27.0569 18.3634 27.0569C17.2762 27.0569 16.3231 26.4936 15.7796 25.6424C15.2361 26.4936 14.2831 27.0569 13.1958 27.0569C12.9246 27.0569 12.7048 26.8371 12.7048 26.5659C12.7048 26.2948 12.9246 26.075 13.1958 26.075C14.3316 26.075 15.2503 25.1728 15.2768 24.0434C15.2397 24.0167 15.2042 23.9867 15.1708 23.9533Z"
        fill="#B66A7D"
      />
    </g>
    <path
      d="M15.9769 23.6406C15.9769 25.0906 14.8069 26.2606 13.3569 26.2606"
      stroke="url(#paint28_radial_64_89854)"
      strokeLinecap="round"
    />
    <path
      d="M15.9769 23.6406C15.9769 25.0906 14.8069 26.2606 13.3569 26.2606"
      stroke="url(#paint29_radial_64_89854)"
      strokeLinecap="round"
    />
    <path
      d="M15.9769 23.6406C15.9769 25.0906 14.8069 26.2606 13.3569 26.2606"
      stroke="url(#paint30_linear_64_89854)"
      strokeLinecap="round"
    />
    <path
      d="M16 23.6406C16 25.0906 17.17 26.2606 18.62 26.2606"
      stroke="url(#paint31_radial_64_89854)"
      strokeLinecap="round"
    />
    <path
      d="M16 23.6406C16 25.0906 17.17 26.2606 18.62 26.2606"
      stroke="url(#paint32_radial_64_89854)"
      strokeLinecap="round"
    />
    <path
      d="M14.2385 22.4702L15.3685 23.6002C15.7085 23.9402 16.2585 23.9402 16.5985 23.6002L17.7285 22.4702C18.2785 21.9202 17.8885 20.9902 17.1185 20.9902H14.8585C14.0785 20.9902 13.6885 21.9202 14.2385 22.4702Z"
      fill="url(#paint33_linear_64_89854)"
    />
    <path
      d="M14.2385 22.4702L15.3685 23.6002C15.7085 23.9402 16.2585 23.9402 16.5985 23.6002L17.7285 22.4702C18.2785 21.9202 17.8885 20.9902 17.1185 20.9902H14.8585C14.0785 20.9902 13.6885 21.9202 14.2385 22.4702Z"
      fill="url(#paint34_radial_64_89854)"
    />
    <path
      d="M14.2385 22.4702L15.3685 23.6002C15.7085 23.9402 16.2585 23.9402 16.5985 23.6002L17.7285 22.4702C18.2785 21.9202 17.8885 20.9902 17.1185 20.9902H14.8585C14.0785 20.9902 13.6885 21.9202 14.2385 22.4702Z"
      fill="url(#paint35_linear_64_89854)"
    />
    <path
      d="M14.2385 22.4702L15.3685 23.6002C15.7085 23.9402 16.2585 23.9402 16.5985 23.6002L17.7285 22.4702C18.2785 21.9202 17.8885 20.9902 17.1185 20.9902H14.8585C14.0785 20.9902 13.6885 21.9202 14.2385 22.4702Z"
      fill="url(#paint36_linear_64_89854)"
    />
    <path
      d="M12.6888 8.5546C12.9929 8.44854 13.1806 8.13237 13.0838 7.82517C12.6201 6.35416 11.7635 5.02104 10.5985 3.98055C10.5285 3.92055 10.4185 3.92055 10.3485 3.98055C8.53852 5.61055 7.47852 7.93055 7.47852 10.4005V11.8617C7.47852 12.1135 7.82244 12.2161 7.9731 12.0144C9.15632 10.4305 10.7907 9.21653 12.6888 8.5546Z"
      fill="url(#paint37_linear_64_89854)"
    />
    <path
      d="M12.6888 8.5546C12.9929 8.44854 13.1806 8.13237 13.0838 7.82517C12.6201 6.35416 11.7635 5.02104 10.5985 3.98055C10.5285 3.92055 10.4185 3.92055 10.3485 3.98055C8.53852 5.61055 7.47852 7.93055 7.47852 10.4005V11.8617C7.47852 12.1135 7.82244 12.2161 7.9731 12.0144C9.15632 10.4305 10.7907 9.21653 12.6888 8.5546Z"
      fill="url(#paint38_linear_64_89854)"
    />
    <path
      d="M12.6888 8.5546C12.9929 8.44854 13.1806 8.13237 13.0838 7.82517C12.6201 6.35416 11.7635 5.02104 10.5985 3.98055C10.5285 3.92055 10.4185 3.92055 10.3485 3.98055C8.53852 5.61055 7.47852 7.93055 7.47852 10.4005V11.8617C7.47852 12.1135 7.82244 12.2161 7.9731 12.0144C9.15632 10.4305 10.7907 9.21653 12.6888 8.5546Z"
      fill="url(#paint39_linear_64_89854)"
    />
    <path
      d="M12.6888 8.5546C12.9929 8.44854 13.1806 8.13237 13.0838 7.82517C12.6201 6.35416 11.7635 5.02104 10.5985 3.98055C10.5285 3.92055 10.4185 3.92055 10.3485 3.98055C8.53852 5.61055 7.47852 7.93055 7.47852 10.4005V11.8617C7.47852 12.1135 7.82244 12.2161 7.9731 12.0144C9.15632 10.4305 10.7907 9.21653 12.6888 8.5546Z"
      fill="url(#paint40_linear_64_89854)"
    />
    <path
      d="M12.6888 8.5546C12.9929 8.44854 13.1806 8.13237 13.0838 7.82517C12.6201 6.35416 11.7635 5.02104 10.5985 3.98055C10.5285 3.92055 10.4185 3.92055 10.3485 3.98055C8.53852 5.61055 7.47852 7.93055 7.47852 10.4005V11.8617C7.47852 12.1135 7.82244 12.2161 7.9731 12.0144C9.15632 10.4305 10.7907 9.21653 12.6888 8.5546Z"
      fill="url(#paint41_radial_64_89854)"
    />
    <path
      d="M12.6888 8.5546C12.9929 8.44854 13.1806 8.13237 13.0838 7.82517C12.6201 6.35416 11.7635 5.02104 10.5985 3.98055C10.5285 3.92055 10.4185 3.92055 10.3485 3.98055C8.53852 5.61055 7.47852 7.93055 7.47852 10.4005V11.8617C7.47852 12.1135 7.82244 12.2161 7.9731 12.0144C9.15632 10.4305 10.7907 9.21653 12.6888 8.5546Z"
      fill="url(#paint42_linear_64_89854)"
    />
    <g filter="url(#filter7_f_64_89854)">
      <circle cx="17.0881" cy="21.8359" r="0.320312" fill="#6A6566" />
    </g>
    <g filter="url(#filter8_f_64_89854)">
      <path
        d="M28.8928 19.6094C28.6897 19.9219 26.8928 21.1875 26.0803 21.4062"
        stroke="url(#paint43_linear_64_89854)"
        strokeWidth="0.25"
        strokeLinecap="round"
      />
    </g>
    <g filter="url(#filter9_f_64_89854)">
      <path
        d="M3.2439 19.6094C3.44702 19.9219 5.2439 21.1875 6.0564 21.4062"
        stroke="url(#paint44_linear_64_89854)"
        strokeWidth="0.25"
        strokeLinecap="round"
      />
    </g>
    <g filter="url(#filter10_f_64_89854)">
      <path
        d="M23.4084 15.1875C23.4084 15.4065 23.419 17.6472 23.4395 17.847C23.6525 19.9282 24.9395 20.9688 26.5647 20.9688"
        stroke="#FFC177"
      />
    </g>
    <g filter="url(#filter11_f_64_89854)">
      <path
        d="M21.6755 2.66406C22.1208 3.0625 23.3396 4.23438 24.074 5.5625"
        stroke="#665D5F"
        strokeWidth="0.25"
        strokeLinecap="round"
      />
    </g>
    <g filter="url(#filter12_f_64_89854)">
      <path
        d="M10.5801 2.66406C11.0254 3.0625 12.2441 4.23438 12.9785 5.5625"
        stroke="#665D5F"
        strokeWidth="0.25"
        strokeLinecap="round"
      />
    </g>
    <g filter="url(#filter13_f_64_89854)">
      <path
        d="M21.5115 2.66406C21.0662 3.0625 19.8474 4.23438 19.113 5.5625"
        stroke="url(#paint45_linear_64_89854)"
        strokeOpacity="0.5"
        strokeWidth="0.25"
        strokeLinecap="round"
      />
    </g>
    <g filter="url(#filter14_f_64_89854)">
      <path
        d="M10.416 2.66406C9.9707 3.0625 8.75195 4.23438 8.01758 5.5625"
        stroke="url(#paint46_linear_64_89854)"
        strokeOpacity="0.5"
        strokeWidth="0.25"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_64_89854"
        x="17.7209"
        y="3.26562"
        width="3.32812"
        height="5.32812"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_64_89854" />
      </filter>
      <filter
        id="filter1_f_64_89854"
        x="23.449"
        y="4.56836"
        width="2.75"
        height="8.125"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_64_89854" />
      </filter>
      <filter
        id="filter2_f_64_89854"
        x="11.8635"
        y="3.72461"
        width="2.76367"
        height="4.85352"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_64_89854" />
      </filter>
      <filter
        id="filter3_f_64_89854"
        x="18.0623"
        y="16.5312"
        width="3"
        height="4.5"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.375" result="effect1_foregroundBlur_64_89854" />
      </filter>
      <filter
        id="filter4_f_64_89854"
        x="10.4822"
        y="16.5312"
        width="3"
        height="4.5"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.375" result="effect1_foregroundBlur_64_89854" />
      </filter>
      <filter
        id="filter5_f_64_89854"
        x="6.34351"
        y="4.45312"
        width="3.01562"
        height="8.23047"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_64_89854" />
      </filter>
      <filter
        id="filter6_f_64_89854"
        x="11.9548"
        y="20.6406"
        width="7.64966"
        height="7.16602"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.375" result="effect1_foregroundBlur_64_89854" />
      </filter>
      <filter
        id="filter7_f_64_89854"
        x="16.2678"
        y="21.0156"
        width="1.64062"
        height="1.64062"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_64_89854" />
      </filter>
      <filter
        id="filter8_f_64_89854"
        x="25.4553"
        y="18.9844"
        width="4.0625"
        height="3.04688"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_64_89854" />
      </filter>
      <filter
        id="filter9_f_64_89854"
        x="2.1189"
        y="18.4844"
        width="5.0625"
        height="4.04688"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_64_89854" />
      </filter>
      <filter
        id="filter10_f_64_89854"
        x="20.4084"
        y="12.6875"
        width="8.65625"
        height="11.2812"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="1.25" result="effect1_foregroundBlur_64_89854" />
      </filter>
      <filter
        id="filter11_f_64_89854"
        x="21.0505"
        y="2.03906"
        width="3.64844"
        height="4.14844"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_64_89854" />
      </filter>
      <filter
        id="filter12_f_64_89854"
        x="9.95508"
        y="2.03906"
        width="3.64844"
        height="4.14844"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_64_89854" />
      </filter>
      <filter
        id="filter13_f_64_89854"
        x="18.488"
        y="2.03906"
        width="3.64844"
        height="4.14844"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_64_89854" />
      </filter>
      <filter
        id="filter14_f_64_89854"
        x="7.39258"
        y="2.03906"
        width="3.64844"
        height="4.14844"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_64_89854" />
      </filter>
      <radialGradient
        id="paint0_radial_64_89854"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(20.1584 12.5) rotate(136.338) scale(20.8211 20.8211)"
      >
        <stop stopColor="#FFC177" />
        <stop offset="0.284634" stopColor="#FF8D28" />
        <stop offset="0.610861" stopColor="#F4763D" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_64_89854"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(29.2209 18.125) rotate(134.433) scale(4.46383 3.0354)"
      >
        <stop stopColor="#E78327" />
        <stop offset="1" stopColor="#F4953D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_64_89854"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(1.9397 19.6875) rotate(32.6375) scale(3.30276 1.76965)"
      >
        <stop stopColor="#C9510E" />
        <stop offset="1" stopColor="#C9510E" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint3_radial_64_89854"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(15.9884 19.875) rotate(-90) scale(13.4375 12.9825)"
      >
        <stop offset="0.795349" stopColor="#F46639" stopOpacity="0" />
        <stop offset="1" stopColor="#F46639" />
      </radialGradient>
      <linearGradient
        id="paint4_linear_64_89854"
        x1="20.4741"
        y1="7.40625"
        x2="20.4741"
        y2="-3.875"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB444" />
        <stop offset="1" stopColor="#FFB444" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_64_89854"
        x1="21.4935"
        y1="0.639241"
        x2="22.0873"
        y2="5.664"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.191489" stopColor="#5F5254" />
        <stop offset="1" stopColor="#2E2127" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_64_89854"
        x1="22.0576"
        y1="3.08397"
        x2="21.9569"
        y2="0.544347"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.146552" stopColor="#34222C" stopOpacity="0" />
        <stop offset="0.872727" stopColor="#34222C" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_64_89854"
        x1="23.2831"
        y1="7.28481"
        x2="23.2831"
        y2="4.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#552D52" />
        <stop offset="1" stopColor="#552D52" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_64_89854"
        x1="23.346"
        y1="13.7504"
        x2="20.0335"
        y2="5.18787"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FC5737" />
        <stop offset="1" stopColor="#EF7E3F" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_64_89854"
        x1="25.0335"
        y1="12.7303"
        x2="22.9085"
        y2="7.68787"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E83525" />
        <stop offset="1" stopColor="#E83525" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_64_89854"
        x1="24.7835"
        y1="7.68787"
        x2="22.6273"
        y2="7.68787"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E83525" />
        <stop offset="1" stopColor="#E83525" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_64_89854"
        x1="25.2835"
        y1="4.56287"
        x2="22.346"
        y2="7.37537"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BF5733" />
        <stop offset="1" stopColor="#BF5733" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint12_radial_64_89854"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(18.7085 9.37537) rotate(-22.746) scale(2.18223 4.8172)"
      >
        <stop stopColor="#FF894C" />
        <stop offset="1" stopColor="#FF894C" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint13_linear_64_89854"
        x1="18.7085"
        y1="6.40662"
        x2="20.0335"
        y2="7.34412"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF894C" />
        <stop offset="1" stopColor="#FF894C" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_64_89854"
        x1="20.4713"
        y1="18.5"
        x2="19.6459"
        y2="18.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.225" stopColor="#594C4F" />
        <stop offset="1" stopColor="#392833" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_64_89854"
        x1="20.793"
        y1="20.5249"
        x2="20.793"
        y2="18.9347"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#552D52" />
        <stop offset="1" stopColor="#552D52" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_64_89854"
        x1="12.8912"
        y1="18.5"
        x2="12.0658"
        y2="18.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.225" stopColor="#594C4F" />
        <stop offset="1" stopColor="#392833" />
      </linearGradient>
      <linearGradient
        id="paint17_linear_64_89854"
        x1="13.2129"
        y1="20.5249"
        x2="13.2129"
        y2="18.9347"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#552D52" />
        <stop offset="1" stopColor="#552D52" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint18_linear_64_89854"
        x1="8.73413"
        y1="9.5625"
        x2="8.73413"
        y2="-1.71875"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB444" />
        <stop offset="1" stopColor="#FFB444" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint19_linear_64_89854"
        x1="10.4785"
        y1="0.65625"
        x2="11.0618"
        y2="5.61895"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.191489" stopColor="#5F5254" />
        <stop offset="1" stopColor="#2E2127" />
      </linearGradient>
      <linearGradient
        id="paint20_linear_64_89854"
        x1="11.0386"
        y1="3.07042"
        x2="10.9398"
        y2="0.5625"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.146552" stopColor="#34222C" stopOpacity="0" />
        <stop offset="0.872727" stopColor="#34222C" />
      </linearGradient>
      <linearGradient
        id="paint21_linear_64_89854"
        x1="12.2554"
        y1="7.21875"
        x2="12.2554"
        y2="4.46875"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#552D52" />
        <stop offset="1" stopColor="#552D52" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint22_radial_64_89854"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(22.2834 18.6252) rotate(116.074) scale(14.3337 20.0167)"
      >
        <stop offset="0.0844541" stopColor="#FFDFA1" />
        <stop offset="1" stopColor="#EBA582" />
      </radialGradient>
      <radialGradient
        id="paint23_radial_64_89854"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(15.9834 20.9902) rotate(90) scale(10.01 21.575)"
      >
        <stop offset="0.512987" stopColor="#D87DA0" stopOpacity="0" />
        <stop offset="1" stopColor="#D87DA0" />
      </radialGradient>
      <radialGradient
        id="paint24_radial_64_89854"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(15.9834 20.9902) rotate(128.469) scale(13.5833 44.3384)"
      >
        <stop offset="0.588887" stopColor="#D87DA0" stopOpacity="0" />
        <stop offset="1" stopColor="#D87DA0" />
      </radialGradient>
      <radialGradient
        id="paint25_radial_64_89854"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(15.9834 20.9902) rotate(90) scale(10.5725 16.07)"
      >
        <stop offset="0.7921" stopColor="#C55188" stopOpacity="0" />
        <stop offset="1" stopColor="#C55188" />
      </radialGradient>
      <radialGradient
        id="paint26_radial_64_89854"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(24.3459 19.6252) rotate(118.156) scale(5.03309 7.12647)"
      >
        <stop stopColor="#F9E58C" />
        <stop offset="1" stopColor="#F9E58C" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint27_radial_64_89854"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(9.72089 31.1877) rotate(-46.0662) scale(9.026 11.2593)"
      >
        <stop stopColor="#D87DA0" />
        <stop offset="1" stopColor="#D87DA0" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint28_radial_64_89854"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(13.7485 24.2013) rotate(54.3924) scale(2.97885 3.73897)"
      >
        <stop stopColor="#261317" />
        <stop offset="0.588542" stopColor="#4E4647" />
        <stop offset="0.747451" stopColor="#2F172A" />
      </radialGradient>
      <radialGradient
        id="paint29_radial_64_89854"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(14.561 25.2638) rotate(-128.517) scale(0.978529 1.62106)"
      >
        <stop stopColor="#261317" />
        <stop offset="1" stopColor="#261317" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint30_linear_64_89854"
        x1="15.8423"
        y1="24.5294"
        x2="15.1392"
        y2="25.545"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2F172A" />
        <stop offset="1" stopColor="#2F172A" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint31_radial_64_89854"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(18.4673 24.2794) rotate(122.471) scale(3.05588 4.35358)"
      >
        <stop stopColor="#261317" />
        <stop offset="0.588542" stopColor="#4E4647" />
        <stop offset="0.747451" stopColor="#2F172A" />
      </radialGradient>
      <radialGradient
        id="paint32_radial_64_89854"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17.686 24.4981) rotate(135.988) scale(1.28182 2.1235)"
      >
        <stop stopColor="#261317" />
        <stop offset="1" stopColor="#261317" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint33_linear_64_89854"
        x1="17.0884"
        y1="20.7526"
        x2="14.5549"
        y2="22.9933"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.191489" stopColor="#5F5254" />
        <stop offset="1" stopColor="#2E2127" />
      </linearGradient>
      <radialGradient
        id="paint34_radial_64_89854"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16.2719 21.5402) rotate(132.349) scale(1.80048 3.30126)"
      >
        <stop offset="0.462647" stopColor="#34222C" stopOpacity="0" />
        <stop offset="0.955607" stopColor="#34222C" />
      </radialGradient>
      <linearGradient
        id="paint35_linear_64_89854"
        x1="16.3032"
        y1="21.7666"
        x2="16.3032"
        y2="20.3779"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.146552" stopColor="#34222C" stopOpacity="0" />
        <stop offset="0.872727" stopColor="#34222C" />
      </linearGradient>
      <linearGradient
        id="paint36_linear_64_89854"
        x1="16.9974"
        y1="24.0958"
        x2="16.9974"
        y2="21.9963"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#552D52" />
        <stop offset="1" stopColor="#552D52" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint37_linear_64_89854"
        x1="12.108"
        y1="13.7629"
        x2="8.32611"
        y2="6.38569"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FC5737" />
        <stop offset="1" stopColor="#EF7E3F" />
      </linearGradient>
      <linearGradient
        id="paint38_linear_64_89854"
        x1="13.2385"
        y1="11.063"
        x2="10.3481"
        y2="7.44628"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.139209" stopColor="#E83525" />
        <stop offset="1" stopColor="#E83525" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint39_linear_64_89854"
        x1="13.543"
        y1="7.68662"
        x2="11.3905"
        y2="7.68662"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E83525" />
        <stop offset="1" stopColor="#E83525" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint40_linear_64_89854"
        x1="13.2385"
        y1="5.87549"
        x2="11.1038"
        y2="7.36486"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BF5733" />
        <stop offset="1" stopColor="#BF5733" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint41_radial_64_89854"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(6.09601 7.12549) rotate(10.6197) scale(3.05228 12.7697)"
      >
        <stop stopColor="#FF894C" />
        <stop offset="1" stopColor="#FF894C" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint42_linear_64_89854"
        x1="7.47852"
        y1="6.40245"
        x2="9.60037"
        y2="7.55497"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF894C" />
        <stop offset="1" stopColor="#FF894C" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint43_linear_64_89854"
        x1="29.2053"
        y1="19.6094"
        x2="26.7834"
        y2="21.125"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.211043" stopColor="#FFCB8C" />
        <stop offset="1" stopColor="#FFCB8C" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint44_linear_64_89854"
        x1="2.9314"
        y1="19.6094"
        x2="6.62714"
        y2="21.875"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.211043" stopColor="#FFD199" />
        <stop offset="1" stopColor="#FFCB8C" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint45_linear_64_89854"
        x1="21.5115"
        y1="2.66406"
        x2="19.2458"
        y2="5.67188"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#665D5F" />
        <stop offset="1" stopColor="#665D5F" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint46_linear_64_89854"
        x1="10.416"
        y1="2.66406"
        x2="8.15039"
        y2="5.67188"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#665D5F" />
        <stop offset="1" stopColor="#665D5F" stopOpacity="0" />
      </linearGradient>
    </defs>
  </SvgIcon>
);

export default Fox;
