/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { RadioGroup, RadioGroupProps } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { Colors, BoxShadows } from '../../style/variables';

const useStyles = makeStyles({
  radioButton: {
    flexDirection: 'row',
    backgroundColor: Colors['page-background'],
    borderRadius: '8px',
    padding: 4,

    '& .MuiFormControlLabel-root': {
      marginLeft: 8,
      marginRight: 0,

      '&:first-child': {
        marginLeft: 0
      },

      '& .MuiRadio-root': {
        display: 'none'
      },

      '& .MuiFormControlLabel-label': {
        borderRadius: '8px',
        padding: '8px 16px',

        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '24px'
      },

      '& .Mui-checked + .MuiFormControlLabel-label': {
        backgroundColor: Colors.white,
        boxShadow: BoxShadows.empire
      }
    },

    '&.blue-button': {
      padding: 0,
      backgroundColor: 'transparent',

      '& .MuiFormControlLabel-root .MuiFormControlLabel-label': {
        padding: '6px 16px',

        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '24px',

        '& span': {
          color: Colors['disable-text']
        }
      },

      '& .MuiFormControlLabel-root .Mui-checked + .MuiFormControlLabel-label': {
        backgroundColor: Colors['blue-primrose_l4'],
        boxShadow: 'none',

        '& p': {
          color: Colors.white,

          '& span': {
            color: Colors.white
          }
        }
      }
    }
  }
});

const RadioButton: React.FC<RadioGroupProps> = (props) => {
  const { children } = props;

  const classes = useStyles();

  return (
    <RadioGroup
      classes={{
        root: classes.radioButton
      }}
      {...props}
    >
      {children}
    </RadioGroup>
  );
};

export default RadioButton;
