import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconClockFilled: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M16 13h-4a1 1 0 0 1-1-1V8a1 1 0 1 1 2 0v3h3a1 1 0 1 1 0 2ZM12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconClockFilled;
