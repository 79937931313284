import { FC, PropsWithChildren } from 'react';

import { Box } from '@mui/material';
import { SxStyles } from 'types';
import { mergeSx } from 'utils';

import * as styles from './styles';

interface ICardContainerRowProps {
  sx?: SxStyles;
}

const CardContainerRow: FC<ICardContainerRowProps & PropsWithChildren> = ({ sx, children }) => {
  return (
    <Box className="card-container-row" sx={mergeSx(styles.row, sx)}>
      {children}
    </Box>
  );
};

export default CardContainerRow;
