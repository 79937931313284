/* eslint-disable prettier/prettier */
/* eslint-disable */

import { useMediaQuery } from '@mui/material';

type QueryType = 'down' | 'up';

const queryDownTemplate = (width: number) => `(max-width: ${width}px)`;
const queryUpTemplate = (width: number) => `(min-width: ${width}px)`;

/**
 * @deprecated use Material useMediaQuery
 */
const useMediaQueryWidth = (width: number, type: QueryType): boolean => {
  const query = type === 'down' ? queryDownTemplate(width) : queryUpTemplate(width);
  const isMatching = useMediaQuery(query, { noSsr: true });

  return isMatching;
};

export default useMediaQueryWidth;
