/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useContext, useMemo } from 'react';

import { ActivitySecondaryBlock } from '../basicBlocks';
import { IDictionaryItem } from '../../../../../api/types';
import { DifferentCode } from '../../../../../const';
import { IEventData } from 'portfolio3/features/dataEntryForm';

import { IController } from 'portfolio3/ui-kit/types';
import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';
import { FormControl, Input, BaseInputLabel } from 'portfolio3/ui-kit';
import { Select, SearchSelect } from 'portfolio3/ui-kit/selects';
import { FormHelperText } from '@mui/material';

interface ICreationContestSecondaryBlockProps {
  subjectOptions: IDictionaryItem[];
  eventLevelOptions: IDictionaryItem[];
  subCategoryOptions: IDictionaryItem[];
  eventData: IEventData;
  name?: string;
}

const CreationContestSecondaryBlock: React.FC<ICreationContestSecondaryBlockProps> = ({
  eventData,
  subjectOptions,
  eventLevelOptions,
  subCategoryOptions,
}) => {
  const { inputRenderMode, inputSize, formData, formErrors, onChangeFormData, onChangeFormErrors } = useContext(LocalDataEntryFormContext);

  const secondNameController: IController<string | undefined> = useMemo(() => ({
    handleChange: (secondName) => {
      onChangeFormData((prevstate) => ({
        ...prevstate,
        secondName
      }));
      onChangeFormErrors((prevstate) => ({
        ...prevstate,
        secondNameError: false
      }));
    },
    handleBlur: (secondName: any) => {
      if (!secondName) {
        onChangeFormErrors((prevstate) => ({
          ...prevstate,
          secondNameError: true
        }));
      }
    }
  }), [formData.id]);

  const creationKindController: IController<string | undefined> = useMemo(() => ({
    handleChange: (creationKindCode) => {
      onChangeFormData((prevstate) => ({
        ...prevstate,
        creationKindCode: Number(creationKindCode),
      }));
      onChangeFormErrors((prevstate) => ({
        ...prevstate,
        creationKindError: false
      }));
    },
    handleBlur: (type) => {
      if (!type) {
        onChangeFormErrors((prevstate) => ({
          ...prevstate,
          creationKindError: true
        }));
      }
    }
  }), [formData.id]);

  const subCategoryController: IController<string | undefined> = useMemo(() => ({
    handleChange: (subCategory) => {
      onChangeFormData((prevstate) => ({
        ...prevstate,
        subCategory: Number(subCategory),
        creationKindCode: undefined
      }));
      onChangeFormErrors((prevstate) => ({
        ...prevstate,
        subcategoryError: false
      }));
    },
    handleBlur: (type) => {
      if (!type) {
        onChangeFormErrors((prevstate) => ({
          ...prevstate,
          subcategoryError: true
        }));
      }
    }
  }), [formData.id]);

  const additionalTopControllers = (
    <>
      {Number(formData.name) === DifferentCode && (
        <FormControl
          renderMode={inputRenderMode}
          inputSize={inputSize}
          label={<BaseInputLabel>Название конкурса</BaseInputLabel>}
          control={
            <Input
              renderMode={inputRenderMode}
              size={inputSize}
              placeholder="Введите название конкурса"
              value={formData.secondName}
              controller={secondNameController}
            />
          }
          error={formErrors.secondNameError}
          helper={formErrors.secondNameError && <FormHelperText>Введите название конкурса</FormHelperText>}
          required
        />
      )}
      <FormControl
        renderMode={inputRenderMode}
        inputSize={inputSize}
        label={<BaseInputLabel>Подкатегория</BaseInputLabel>}
        control={
          <Select
            inputRenderMode={inputRenderMode}
            inputSize={inputSize}
            value={formData.subCategory}
            options={subCategoryOptions}
            placeholder="Выберите подкатегорию..."
            controller={subCategoryController}
          />
        }
        error={formErrors.subcategoryError}
        helper={formErrors.subcategoryError && <FormHelperText>Выберите подкатегорию</FormHelperText>}
        required
      />
      <FormControl
        renderMode={inputRenderMode}
        inputSize={inputSize}
        label={<BaseInputLabel>Вид творческой деятельности</BaseInputLabel>}
        control={
          <SearchSelect
            inputRenderMode={inputRenderMode}
            inputSize={inputSize}
            options={subjectOptions}
            value={formData.creationKindCode}
            placeholder="Выберите вид творческой деятельности..."
            controller={creationKindController}
          />
        }
        error={formErrors.creationKindError}
        helper={formErrors.subcategoryError && <FormHelperText>Выберите творческую дейятельность</FormHelperText>}
        disabled={!formData.subCategory}
        required
      />
    </>
  );

  const organizatorLabel = 'Организатор конкурса';
  const organizatorPlaceholder = 'Введите название организатора конкурса...';
  return (
    <ActivitySecondaryBlock
      eventData={eventData}
      eventLevelOptions={eventLevelOptions}
      additionalTopControllers={additionalTopControllers}
      organizatorLabel={organizatorLabel}
      organizatorPlaceholder={organizatorPlaceholder}
    />
  );
};

export default CreationContestSecondaryBlock;
