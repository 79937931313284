/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';

import {
  FAILURE,
  REQUEST,
  SUCCESS
} from '../../actions/utils';

import genericReducers from '../genericReducer';
import { IReducers } from '..';
import { IHistoryResponse, IAdminHistory } from '../../api/types';
import { GET_ADMIN_HISTORY } from '../../actions';

export type AdminHistoryState = IHistoryResponse<IAdminHistory>;

const initialState = {
  content: [],
  loading: false
} as IHistoryResponse<IAdminHistory>;

const adminHistory: IReducers<AdminHistoryState> = {
  [GET_ADMIN_HISTORY[REQUEST]]: genericReducers().get.request,
  [GET_ADMIN_HISTORY[SUCCESS]]: genericReducers().get.success,
  [GET_ADMIN_HISTORY[FAILURE]]: genericReducers().get.failure
};

export default connectReducers(initialState, adminHistory);
