import { forwardRef, ForwardRefRenderFunction } from 'react';

import { Box, BoxProps } from '@mui/material';
import { mergeSx } from 'utils';

import { getButtonStyles } from '../utils';

const UnstyledButton: ForwardRefRenderFunction<HTMLButtonElement, BoxProps<'button'>> = (props, ref) => {
  return <Box component="button" {...props} ref={ref} sx={mergeSx(getButtonStyles(), props.sx)} />;
};

export default forwardRef(UnstyledButton);
