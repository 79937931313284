export const HttpErrorCodes = {
  FORBIDDEN: 403,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
} as const;

export enum LinkErrorKeys {
  EXPIRED = 'expired',
  UNAVAILABLE = 'unavailable',
  NOT_STARTED = 'notStarted',
  NO_TOKEN = 'noToken',
}

export const LinkErrorCode: Record<string, number> = {
  [LinkErrorKeys.UNAVAILABLE]: 456,
  [LinkErrorKeys.NOT_STARTED]: 457,
  [LinkErrorKeys.EXPIRED]: 458,
  [LinkErrorKeys.NO_TOKEN]: 561,
};

export enum FileErrorKeys {
  FORMAT = 'format',
  SIZE = 'size',
}

export const FileErrorCode: Record<string, number> = {
  [FileErrorKeys.FORMAT]: 449,
  [FileErrorKeys.SIZE]: 450,
};

export const GENERAL_ERROR = 'Извините, во время работы системы произошла ошибка. Попробуйте позже.';
export const DUPLICATE_ENTITY_ERROR = 'Запись с такими значениями уже существует в Портфолио Учащегося';
export const ACCESS_ERROR =
  'Недостаточно прав для выполнения действия. Пожалуйста, обратитесь в техническую поддержку.';

export const SLURS_ERROR_CODE = 465;
export const NOT_FOUND_CODE = 480;
export const NO_RESULT_CODE = 425;
export const WRONG_OPERATOR = 481;
export const SERVICE_TIMEOUT_CODE = 488;
export const DUPLICATE_ENTITY_CODE = 463;
export const ACCESS_ERROR_CODE = 562;

export enum DateErrorKeys {
  BEFORE_MIN_DATE = 'beforeMinDate',
  AFTER_MAX_DATE = 'afterMaxDate',
  BEFORE_START_DATE = 'beforeStartDate',
  AFTER_END_DATE = 'afterEndDate',
  BEFORE_TODAY = 'beforeToday',
  AFTER_TODAY = 'afterToday',
  EMPTY_DATE = 'emptyDate',
}

export enum ImportErrorKeys {
  MAX_FILES = 'maxFiles',
  NOT_READY = 'notReady',
  NO_FILE = 'noFile',
  ALREADY_DONE = 'alreadyDone',
  ALREADY_STARTED = 'alreadyStarted',
  CANT_BE_STOPPED = 'cantBeStopped',
}

export const ImportErrorCodes: Record<string, number> = {
  [ImportErrorKeys.MAX_FILES]: 474,
  [ImportErrorKeys.NOT_READY]: 475,
  [ImportErrorKeys.NO_FILE]: 476,
  [ImportErrorKeys.ALREADY_DONE]: 477,
  [ImportErrorKeys.ALREADY_STARTED]: 478,
  [ImportErrorKeys.CANT_BE_STOPPED]: 479,
};

export const ImportErrorsArray = [
  ImportErrorCodes.maxFiles,
  ImportErrorCodes.notReady,
  ImportErrorCodes.noFile,
  ImportErrorCodes.alreadyDone,
  ImportErrorCodes.alreadyStarted,
  ImportErrorCodes.cantBeStopped,
];

export const RedirectToServiceErrorPageHttpCodes: number[] = [
  HttpErrorCodes.BAD_GATEWAY,
  HttpErrorCodes.SERVICE_UNAVAILABLE,
];

export const ContextInternalCodesThatShouldNotRedirectToAuthPage = [SERVICE_TIMEOUT_CODE];
