import { ElementType, HTMLAttributes, ReactNode, SyntheticEvent } from 'react';

import {
  Autocomplete,
  autocompleteClasses,
  AutocompleteFreeSoloValueMapping,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  AutocompleteValue,
  Box,
  iconButtonClasses,
  ListItem,
  styled,
  svgIconClasses,
  useFormControl,
} from '@mui/material';
import { IconClose } from 'icons/edit';
import { InputBase } from 'portfolio3/ui-kit/inputs';
import SelectArrow from 'portfolio3/ui-kit/SelectArrow';
import { CommonUiKitSize, IAutocompleteController, InputRenderMode } from 'portfolio3/ui-kit/types';
import { InputTokens } from 'portfolio3/ui-kit/utils';
import { SafeOmit, skipPropsForwarding } from 'utils';

import { baseOptionStyles } from '../styles';

export interface IAutocompleteBaseProps<
  OptionType,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ChipComponent extends ElementType<any> = 'div',
> extends SafeOmit<
    AutocompleteProps<OptionType, Multiple, DisableClearable, FreeSolo, ChipComponent>,
    'renderInput' | 'options'
  > {
  inputRenderMode: InputRenderMode;
  inputSize: CommonUiKitSize;
  value: AutocompleteValue<OptionType, Multiple, DisableClearable, FreeSolo> | undefined;
  controller: IAutocompleteController<OptionType | null>;
  placeholder?: string;
  options: OptionType[];

  renderInput?: AutocompleteProps<OptionType, Multiple, DisableClearable, FreeSolo, ChipComponent>['renderInput'];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TypedAutocomplete = Autocomplete<any, any, any, any>;

interface IStyledAutocompleteProps {
  inputRenderMode: InputRenderMode;
}

const StyledAutocomplete = styled(TypedAutocomplete, {
  shouldForwardProp: skipPropsForwarding<IStyledAutocompleteProps>(['inputRenderMode']),
})<IStyledAutocompleteProps>(({ inputRenderMode }) => {
  const { paddingRight } = InputTokens[inputRenderMode];

  return {
    '& .autocomplete-root': {
      position: 'relative',
    },

    '& .autocomplete-buttons': {
      position: 'absolute',
      top: 0,
      right: paddingRight,

      display: 'flex',
      alignItems: 'center',
      gap: 4,
      height: '100%',

      [`& .${iconButtonClasses.root}`]: {
        width: 24,
        height: 24,
      },
      [`& .${svgIconClasses.root}`]: {
        width: 20,
        height: 20,
      },
    },

    [`& .${autocompleteClasses.input}`]: {
      display: 'flex',
      alignItems: 'center',
      paddingRight: '70px',
    },

    [`& .${autocompleteClasses.endAdornment}`]: {
      position: 'static',
    },
  };
});

const AutocompleteBase = <
  OptionType,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
  inputRenderMode,
  inputSize,
  controller,
  placeholder,

  renderInput,
  renderOption,
  getOptionLabel,
  renderTags,
  value,
  ...props
}: IAutocompleteBaseProps<OptionType, Multiple, DisableClearable, FreeSolo>) => {
  const formControlState = useFormControl();

  const handleChange = (_event: SyntheticEvent<Element, Event>, value: OptionType | null) => {
    controller.handleChange(value ?? null);
  };

  const defaultRenderInput = (params: AutocompleteRenderInputParams): ReactNode => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { color, size, value, ...inputBaseProps } = params.inputProps;
    const isPlaceholderShown = formControlState?.focused || inputRenderMode !== 'floating';

    // удаляем обработчик открытия модалки для состояния disabled
    const disabledProps = formControlState?.disabled
      ? {
          onMouseDown: undefined,
        }
      : undefined;

    return (
      // <TextField {...params} placeholder={'placeholder'} label={'label'} />
      <Box ref={params.InputProps.ref} className="autocomplete-root">
        <InputBase
          renderMode={inputRenderMode}
          size={inputSize}
          {...inputBaseProps}
          {...disabledProps}
          // нужно для поддержания controlled статуса компонента
          value={value ?? null}
          placeholder={isPlaceholderShown ? placeholder : undefined}
        />
        <div className="autocomplete-buttons">{params.InputProps.endAdornment}</div>
      </Box>
    );
  };

  const defaultRenderOption = (
    props: HTMLAttributes<HTMLLIElement>,
    option: OptionType,
    state: AutocompleteRenderOptionState,
  ): ReactNode => {
    return (
      <ListItem {...props} sx={baseOptionStyles(state.selected)}>
        {String(option)}
      </ListItem>
    );
  };

  const defaultGetOptionLabel = (option: OptionType | AutocompleteFreeSoloValueMapping<FreeSolo>): string => {
    return String(option);
  };

  return (
    <StyledAutocomplete
      inputRenderMode={inputRenderMode}
      popupIcon={<SelectArrow />}
      clearIcon={<IconClose />}
      renderInput={renderInput || defaultRenderInput}
      renderOption={renderOption || defaultRenderOption}
      getOptionLabel={getOptionLabel || defaultGetOptionLabel}
      renderTags={renderTags}
      noOptionsText="Нет доступных вариантов"
      onChange={handleChange}
      {...props}
      // нужно для поддержания controlled статуса компонента
      value={value ?? null}
      disabled={formControlState?.disabled}
    />
  );
};

export default AutocompleteBase;
