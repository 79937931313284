import React, { useContext } from 'react';
import { connect } from 'react-redux';

import { Box } from '@mui/material';
import { ChangeHistoryTabs } from 'const';
import { useSortState } from 'hooks';
import Table from 'portfolio3/components/common/Table';
import {
  TableFooterPagination,
  TableLoadingIndicator,
  TableTitleWithCounter,
} from 'portfolio3/components/tableRecipes';
import { createSelector } from 'reselect';

import { IPagination } from '../../../../api/types';
import { ChangeHistoryContext } from '../../../../context';
import { IRootState } from '../../../../reducers';
import {
  formatDateWithTime,
  getBasicBlockAttributes,
  getProtfolioKindValue,
  getProtfolioTypeValue,
  isDefined,
} from '../../../../utils';
import ChangeHistoryImportItem from '../importItem';
import ChangeHistoryTableFilters from '../TableFilters';
import { IImportHistoryEntity } from '../types';
import * as styles from './styles';

interface IEmployeeImportDataTable {
  importHistory: IImportHistoryEntity[];
  pagination: IPagination;
  loading: boolean;
}

const EmployeeImportDataTable: React.FC<IEmployeeImportDataTable> = ({ importHistory, pagination, loading }) => {
  const { currentFilters, setCurrentFilters } = useContext(ChangeHistoryContext);

  const { handleSort } = useSortState(setCurrentFilters);

  const dateSortType = currentFilters.sort.date;
  const correntCountSort = currentFilters.sort.correctCount;
  const handleSortDate = () => handleSort('date');
  const handleSortCorrentCount = () => handleSort('correctCount');

  const headerElement = (
    <Box sx={styles.headerContainer}>
      <TableTitleWithCounter title="История импорта" counter={pagination.totalElements ?? 0} />
      <ChangeHistoryTableFilters historyType={ChangeHistoryTabs.import} />
    </Box>
  );

  return (
    <Table
      headerElement={headerElement}
      loading={loading}
      tableProps={{
        sx: {
          gridTemplateColumns: '265px minmax(265px, auto) minmax(163px, auto) 1fr minmax(340px, auto)',
        },
      }}
    >
      <Table.THead>
        <Table.Row>
          <Table.HeadCell>Раздел импорта</Table.HeadCell>
          <Table.HeadCell>Путь импорта</Table.HeadCell>
          <Table.HeadCell withSorting sortType={dateSortType} boxProps={{ onClick: handleSortDate }}>
            Дата и время
          </Table.HeadCell>
          <Table.HeadCell>Имя файла</Table.HeadCell>
          <Table.HeadCell withSorting sortType={correntCountSort} boxProps={{ onClick: handleSortCorrentCount }}>
            Статус импорта строк
          </Table.HeadCell>
        </Table.Row>
      </Table.THead>
      <Table.TBody>
        {loading && <TableLoadingIndicator />}
        {!loading && importHistory.map((item) => <ChangeHistoryImportItem key={item.id} {...item} />)}
      </Table.TBody>
      <Table.TFoot>
        <TableFooterPagination
          currentFilters={currentFilters}
          setCurrentFilters={setCurrentFilters}
          totalPages={pagination.totalPages ?? 0}
          disabled={loading}
          renderPageModification={(page) => page + 1}
          setPageModification={(page) => page - 1}
        />
      </Table.TFoot>
    </Table>
  );
};

const importHistoryMapping = createSelector([(state: IRootState) => state.importHistory], (importHistory) => ({
  content:
    importHistory.content?.map((historyImport) => {
      const dataItemAttributes = getBasicBlockAttributes(historyImport.categoryCode);

      const path = [
        historyImport.dataKind ? getProtfolioKindValue(historyImport.dataKind) : null,
        getProtfolioTypeValue(historyImport.typeCode),
      ].filter(isDefined);

      return {
        id: historyImport.id,
        date: formatDateWithTime(historyImport.creationDate),
        section: dataItemAttributes.name,
        path,
        name: historyImport.fileName,
        icon: dataItemAttributes.icon,
        successCount: historyImport.correctCount,
        failCount: historyImport.incorrectCount,
      };
    }) || [],
  pagination: {
    pageNumber: importHistory.number || 0,
    totalPages: importHistory.totalPages || 0,
    pageSize: importHistory.size || 0,
    offset: importHistory.pageable?.offset,
    totalElements: importHistory.totalElements,
    numberOfElements: importHistory.numberOfElements,
  },
}));

export default connect((state: IRootState) => ({
  importHistory: importHistoryMapping(state).content,
  pagination: importHistoryMapping(state).pagination,
  loading: state.importHistory.loading,
}))(EmployeeImportDataTable);
