export enum ViewedFunctionKeys {
  INDEPENDENT_DIAGNOSTICS = 'independentDiagnostics',
  INDEPENDENT_DIAGNOSTICS_DATA_UPDATE = 'independentDiagnosticsDataUpdate',
  THANKS_TEACHER = 'thanksTeacher',
  SNOW_THEME = 'snowTheme',
  THEME_PANEL_NOTIFICATION = 'themePanelNotification',
}

// function_ref
export const ViewedFunctionCodes: Record<ViewedFunctionKeys, number> = {
  [ViewedFunctionKeys.INDEPENDENT_DIAGNOSTICS]: 1,
  [ViewedFunctionKeys.INDEPENDENT_DIAGNOSTICS_DATA_UPDATE]: 2,
  [ViewedFunctionKeys.THANKS_TEACHER]: 3,
  [ViewedFunctionKeys.SNOW_THEME]: 4,
  [ViewedFunctionKeys.THEME_PANEL_NOTIFICATION]: 5,
};
