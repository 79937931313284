/* eslint-disable prettier/prettier */
/* eslint-disable */

import { isGiaWorldSkillsValid } from './validators/gia-worldskills';
import { validateFormErrorsObject, validateProperties } from './common';
import { isAffilationValid, isEmploymentValid, isEventValid, isJobValid, isJobWithContractValid, isProjectValid, isRewardValid, isSpoDocumentValid } from './validators';
import { SectionCodes } from '../../../../const';
import { IAffilationKind, IEventKind, ILocation, IRewardKind, ISportReward } from '../../../../api/types';
import { IPortfolioDataEntryFormErrors, IPortfolioEntryFormData } from 'portfolio3/features/dataEntryForm';

export const isDataEntryFormValid = (
  formData: IPortfolioEntryFormData,
  formErrors: IPortfolioDataEntryFormErrors,
  eventKinds: IEventKind[],
  rewardKinds: IRewardKind[],
  sportRewardKinds: ISportReward[],
  affilationKinds: IAffilationKind[],
  sportClubs: ILocation[],
  creatorId: string,
  sourceCode: number | undefined
): boolean => {
  const { dataKind, typeCode } = formData;

  const isCommonPropertiesValid = validateProperties([
    creatorId,
    sourceCode
  ]);

  const isFormHasErrors = validateFormErrorsObject(formErrors);

  if (!isCommonPropertiesValid || isFormHasErrors) return false;

  // event
  if (
    dataKind === SectionCodes.studyEvent
    || dataKind === SectionCodes.scienceEvent
    || dataKind === SectionCodes.cultureEvent
    || dataKind === SectionCodes.creationEvent
    || dataKind === SectionCodes.sportEvent
    || dataKind === SectionCodes.civilEvent
    || dataKind === SectionCodes.professionEvent
  ) {
    return isEventValid(formData, eventKinds);
  }

  // reward
  if (
    dataKind === SectionCodes.studyReward
    || dataKind === SectionCodes.scienceReward
    || dataKind === SectionCodes.sportReward
    || dataKind === SectionCodes.creationReward
    || dataKind === SectionCodes.civilReward
    || dataKind === SectionCodes.professionReward
  ) {
    return isRewardValid(formData, rewardKinds, sportRewardKinds);
  }

  // employment
  if (
    dataKind === SectionCodes.scienceOccupation
    || dataKind === SectionCodes.creationOccupation
    || dataKind === SectionCodes.sportOccupation
    || dataKind === SectionCodes.civilOccupation
  ) {
    return isEmploymentValid(formData);
  }

  // affilation
  if (
    dataKind === SectionCodes.sportAffilation
    || dataKind === SectionCodes.creationAffilation
    || dataKind === SectionCodes.civilAffilation
  ) {
    return isAffilationValid(formData, sportClubs, affilationKinds);
  }

  // project
  if (dataKind === SectionCodes.project) {
    return isProjectValid(formData);
  }

  // gia worldskills
  if (typeCode === SectionCodes.professionExamGia) {
    return isGiaWorldSkillsValid(formData);
  }

  if (typeCode === SectionCodes.studySpoDocuments && formData.studySpoDocument) {
    return isSpoDocumentValid(formData.studySpoDocument, formData.attachment ?? []);
  }

  if (typeCode === SectionCodes.professionSpoJob && formData.professionSpoJob) {
    const jobFormData = formData.professionSpoJob;
    return jobFormData.hasContract
      ? isJobWithContractValid(jobFormData)
      : isJobValid(jobFormData);
  }

  if (typeCode === SectionCodes.professionSpoMetaskills) {
    return true;
  }

  return false;
}
