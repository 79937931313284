/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import clsx from 'clsx';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';

import ResultDoughnut from 'components/common/ResultDoughnut';

import { Tooltip } from 'ui-kit';
import { getDoughnutColor, getDoughnutData } from 'utils';
import { IconArrowRight } from 'icons';
import { IPerformanceSubjectTopic } from '../../../types';

import './index.scss';

interface ISubjectTopicsProps {
  topics: IPerformanceSubjectTopic[];
}

const SubjectTopics: React.FC<ISubjectTopicsProps> = ({
  topics
}) => {
  return (
    <>
      <TableContainer className="subject-topics">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className="cell-expand">Тема</TableCell>
              <TableCell>Уроков</TableCell>
              <TableCell align="center">Ср. балл</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {topics.map((topic) => (
              <TableRow key={topic.name} className="row">
                {/* topic */}
                <Tooltip
                  title={topic.name}
                  arrow
                  placement="top"
                  width="184px"
                >
                  <TableCell className="cell cell-expand cell-topic">
                    {topic.name}
                  </TableCell>
                </Tooltip>
                {/* lessons */}
                <TableCell className="cell cell-lessons">
                  <div className="cell-lessons__chart">
                    <ResultDoughnut
                      borderThickness={70}
                      total={null}
                      data={getDoughnutData(topic.passedLessons, topic.totalLessons)}
                      color={getDoughnutColor(topic.passedLessons, topic.totalLessons)}
                    />
                  </div>
                  <span className={clsx({'cell-lessons--grayed': topic.passedLessons === 0})}>
                    {topic.passedLessons} из {topic.totalLessons}
                  </span>
                </TableCell>

                {/* average result */}
                <TableCell className="cell cell-result" align="center">
                  {topic.averageResult && topic.averageResult.toString().replace('.', ',')}
                </TableCell>

                <div className="row__arrow">
                  <IconArrowRight />
                </div>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default SubjectTopics;
