import { forwardRef, ForwardRefRenderFunction } from 'react';

import { Box, BoxProps } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';
import { getButtonStyles } from 'portfolio3/ui-kit/utils';
import { SxStyles } from 'types';
import { mergeSx } from 'utils';

export const styles: SxStyles = {
  ...getButtonStyles(),

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '32px',
  height: '32px',

  borderRadius: '50%',

  svg: {
    width: '24px',
    height: '24px',
    color: NeutralColors.light_neutrals_600,
  },

  '&:hover': {
    backgroundColor: NeutralColors.light_neutrals_200,
  },
};

type ButtonProps = BoxProps<'button'>;

const SmallActionsButton: ForwardRefRenderFunction<HTMLButtonElement, ButtonProps> = (props, ref) => {
  return (
    <Box {...props} component="button" ref={ref} sx={mergeSx(styles, props.sx)}>
      {props.children}
    </Box>
  );
};

export default forwardRef<HTMLButtonElement, ButtonProps>(SmallActionsButton);
