import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconArrowUp: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M5.232 10.64a1 1 0 0 0 1.408.128L11 7.135V19a1 1 0 0 0 2 0V7.135l4.36 3.633a1 1 0 1 0 1.28-1.536l-6-5c-.047-.04-.103-.059-.155-.089-.041-.024-.077-.052-.122-.07A.992.992 0 0 0 12 4a.992.992 0 0 0-.363.073c-.045.018-.081.046-.122.07-.052.03-.108.05-.155.09l-6 5a1 1 0 0 0-.128 1.407Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconArrowUp;
