/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useEffect, useMemo, useState } from 'react';

import { connect } from 'react-redux';

import {
  IDiagnosisBarData,
  IDictionaryItem, IIndependentDiagnosticResponse
} from '../../../../api/types';
import { ALL_SUBJECTS_CODE, ALL_SUBJECTS_VALUE } from '../../../../containers/redesignStudent/Diagnostic/const';

import IndependentDiagnosticList from './IndependentDiagnosticList';
import { iconBlueArrow } from '../../../../icons';
import { IRootState } from '../../../../reducers';
import { Button } from '../../../../ui-kit';
import HorizontalBarChart from '../../../common/horizontalBarChart';
import MobileBar from '../../../common/mobileBar';
import IndependentDiagnocticDoughnutStats from '../diagnocticDoughnutStats/independent';
import NoDataPlug from 'portfolio3/components/common/NoDataPlug';
import { placeholderCharacter } from 'images';

interface IIndependentDiagnosticProps {
  independentDiagnostics: IIndependentDiagnosticResponse;
  filterSubject: number;
  setPossibleSubjectFilterValues: React.Dispatch<React.SetStateAction<IDictionaryItem[]>>
}

const DEFAULT_SHOWN_MOBILE_BARS_COUNT = 5;

const IndependentDiagnosticComponent: React.FC<IIndependentDiagnosticProps> = ({
  independentDiagnostics,
  filterSubject,
  setPossibleSubjectFilterValues
}) => {
  const [shownMobileBarsCount, setShownMobileBarsCount] = useState(DEFAULT_SHOWN_MOBILE_BARS_COUNT);
  const avaliableSubjects = useMemo(() => independentDiagnostics.content?.averageResultPercent?.map((diagnostic) => ({
    name: diagnostic.subject,
    total: diagnostic.resultPercent
  })), [independentDiagnostics.content?.selfDiagnostic]);
  const [subjectOptions, setSubjectOptions] = useState<IDiagnosisBarData[]>([]);
  const sortedDiagnosticSubjects = useMemo(() => independentDiagnostics.content?.averageResultPercent
      ?.map((diagnostic) => ({
        name: diagnostic.subject,
        total: diagnostic.resultPercent
      }))
      ?.sort((firstItem, secondItem) => secondItem.total - firstItem.total), [independentDiagnostics.content?.averageResultPercent]);

  useEffect(() => {
    const newSubjectValues = subjectOptions?.map((subject, index) => ({
      value: subject.name,
      code: index + 1
    }));

    if (newSubjectValues) {
      setPossibleSubjectFilterValues([{
        value: ALL_SUBJECTS_VALUE,
        code: ALL_SUBJECTS_CODE
      }].concat(newSubjectValues));
    }
  }, [subjectOptions]);

  useEffect(() => {
    if (filterSubject === ALL_SUBJECTS_CODE && avaliableSubjects) {
      setSubjectOptions(avaliableSubjects);
    }
  }, [avaliableSubjects]);

  return sortedDiagnosticSubjects && sortedDiagnosticSubjects?.length !== 0 ? (
    <>
      {independentDiagnostics.content?.totalDiagnostic && <IndependentDiagnocticDoughnutStats totalDiagnostic={independentDiagnostics.content?.totalDiagnostic} bestResult={independentDiagnostics.content?.bestResult} />}
      <div className="divider-diagnostics" />
      <div className="diagnostics-linner pdf-keep">
        <h3 className="diagnostic__title">Результаты независимых диагностик</h3>
        <p className="self-diagnosis__description">Средний результат пройденных диагностик в процентах</p>
        <div className="self-diagnosis__horizontal-bar self-diagnosis__chart">
          <HorizontalBarChart barData={sortedDiagnosticSubjects} loading={independentDiagnostics.loading} />
        </div>
        <div className="self-diagnosis__horizontal-bar--mobile self-diagnosis__mobile-bars">
          {sortedDiagnosticSubjects.map((data) => <MobileBar subject={data.name} value={data.total} key={data.name} />)}
          {sortedDiagnosticSubjects.length - DEFAULT_SHOWN_MOBILE_BARS_COUNT > 0 && sortedDiagnosticSubjects.length !== shownMobileBarsCount && (
          <Button
            className="btn-arrow"
            endIcon={(
              <img src={iconBlueArrow} alt="показать еще" />
              )}
            onClick={() => setShownMobileBarsCount(sortedDiagnosticSubjects.length)}
          >
            {`Показать еще ${sortedDiagnosticSubjects.length - DEFAULT_SHOWN_MOBILE_BARS_COUNT}`}
          </Button>
          )}
          {sortedDiagnosticSubjects.length === shownMobileBarsCount && sortedDiagnosticSubjects.length > DEFAULT_SHOWN_MOBILE_BARS_COUNT && (
          <Button
            className="btn-arrow"
            endIcon={(
              <img className="btn-arrow__icon btn-arrow__icon--flipped" src={iconBlueArrow} alt="скрыть" />
                  )}
            onClick={() => setShownMobileBarsCount(DEFAULT_SHOWN_MOBILE_BARS_COUNT)}
          >
            Свернуть
          </Button>
          )}
        </div>
      </div>
      {independentDiagnostics.content?.selfDiagnostic && (
        <>
          <div className="divider-diagnostics" />
          <IndependentDiagnosticList diagnostics={independentDiagnostics.content.selfDiagnostic} />
        </>
      )}
    </>
  ) : (
    <NoDataPlug
      image={placeholderCharacter}
      title="Пройденных независимых диагностик пока нет"
      subtitle="Как только они появятся, информация о них отобразится здесь"
      loading={independentDiagnostics.loading}
    />
  )
};

export default connect(
  (state: IRootState) => ({
    independentDiagnostics: state.studentIndependentDiagnosis
  })
)(IndependentDiagnosticComponent);
