import { FC } from 'react';

import { Box, Typography, useMediaQuery } from '@mui/material';
import FunnelBlock from 'components/common/FunnelBlock';
import { commonTheme } from 'portfolio3/styles/theme';
import { SxStyles } from 'types';
import { mergeSx } from 'utils';

import { ILinkSetting, LinkSharingTabs } from '../types';
import SettingBlock from './SettingBlock';
import * as styles from './styles';

interface ISettingsProps {
  currentTab: LinkSharingTabs;
  settings: ILinkSetting[];
  sx?: SxStyles;
}

const Settings: FC<ISettingsProps> = ({ currentTab, settings, sx }) => {
  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const headerText = isMobile ? 'Настройка информации' : 'Настройка отображаемой информации';
  const helperText =
    currentTab === LinkSharingTabs.LINK
      ? 'Выбери информацию, которая будет видна в твоем портфолио по ссылке'
      : 'Выбери информацию, которая будет включена в файл';

  return (
    <FunnelBlock className="sharing-settings" sx={mergeSx(styles.root, sx)}>
      <Typography variant={isMobile ? 'Headings/H6' : 'Headings/H5'}>{headerText}</Typography>
      <Typography variant="Paragraph LG/Regular" sx={styles.helper}>
        {helperText}
      </Typography>
      <Box sx={styles.settingsList}>
        {settings.map((setting) => {
          return <SettingBlock key={setting.name} setting={setting} />;
        })}
      </Box>
    </FunnelBlock>
  );
};

export default Settings;
