import { FC, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { IDictionaryItem } from 'api/types';
import { useDisclosure } from 'hooks';
import FilterButton from 'portfolio3/components/common/buttons/FilterButton';
import { MobileActionsDrawer } from 'portfolio3/components/drawers';
import { RadioGroupControl, RadioGroupController } from 'portfolio3/components/forms/RadioGroupControl';
import { Switcher, SwitcherButton } from 'portfolio3/ui-kit';
import { Select } from 'portfolio3/ui-kit/selects';
import { IController } from 'portfolio3/ui-kit/types';
import { isCurrentLearningYear } from 'utils';

import { MarkType } from '../../types';

const markTypeOptions: IDictionaryItem[] = [
  {
    code: MarkType.AVERAGE,
    value: 'Средняя',
  },
  {
    code: MarkType.FINAL,
    value: 'Итоговая',
  },
];

interface IPerformanceFiltersProps {
  markType: MarkType;
  onChangeMarkType: (markType: MarkType) => void;

  year: number | undefined;
  onChangeYear: (year: number) => void;
  yearOptions: IDictionaryItem[];

  withYearSelector: boolean;
  isMobile: boolean;
  isDisabled: boolean;
}

const PerformanceFilters: FC<IPerformanceFiltersProps> = ({
  markType,
  onChangeMarkType,
  year,
  onChangeYear,
  yearOptions,
  withYearSelector,
  isMobile,
  isDisabled,
}) => {
  const { isOpen: isMobileFiltersOpen, onOpen: onOpenMobileFilters, onClose: onCloseMobileFilters } = useDisclosure();

  const [tempMarkType, setTempMarkType] = useState<MarkType>(MarkType.AVERAGE);
  const [tempYearCode, setTempYearCode] = useState(-1);

  const yearController: IController<string | undefined> = {
    handleChange(year) {
      onChangeYear(Number(year));
    },
  };

  const tempMarkTypeController: RadioGroupController = {
    handleChange(markType) {
      setTempMarkType(markType as MarkType);
    },
  };

  const tempYearController: RadioGroupController = {
    handleChange(year) {
      if (year) {
        setTempYearCode(year);
      } else if (yearOptions.length > 0) {
        setTempYearCode(yearOptions[0].code);
      }
    },
  };

  const handleResetFiltersDrawer = () => {
    setTempMarkType(MarkType.AVERAGE);
    if (yearOptions.length > 0) {
      setTempYearCode(yearOptions[0].code);
    }
  };

  const handleApplyFiltersDrawer = () => {
    onChangeMarkType(tempMarkType);
    onChangeYear(tempYearCode);
    onCloseMobileFilters();
  };

  useEffect(() => {
    if (isMobileFiltersOpen) {
      setTempMarkType(markType);
      if (year) setTempYearCode(year);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileFiltersOpen]);

  const markTypeSwitcherElement = (
    <Switcher value={markType} onChange={onChangeMarkType}>
      <SwitcherButton value={MarkType.AVERAGE} content="Средняя" disabled={isDisabled} />
      <SwitcherButton value={MarkType.FINAL} content="Итоговая" disabled={isDisabled} />
    </Switcher>
  );

  const desktopFilters = (
    <Box sx={{ display: 'flex', gap: '16px' }}>
      {withYearSelector && (
        <Select
          inputRenderMode="fixed"
          inputSize="medium"
          value={year}
          controller={yearController}
          options={yearOptions}
          placeholder="Выберите год"
          disabled={isDisabled}
        />
      )}
      {markTypeSwitcherElement}
    </Box>
  );

  const isResetDisabled = tempMarkType === markType && tempYearCode === year;

  const learningYearRenderLabel = (period: IDictionaryItem): string => {
    const isCurrentPeriod = isCurrentLearningYear(period.value);
    const appendedText = isCurrentPeriod ? ' (текущий)' : '';
    return period.value + appendedText;
  };

  const withIndicator = markType !== MarkType.AVERAGE || (yearOptions.length > 0 && year !== yearOptions[0].code);

  const mobileFilters = (
    <>
      <FilterButton onClick={onOpenMobileFilters} withIndicator={withIndicator} disabled={isDisabled} />
      <MobileActionsDrawer
        open={isMobileFiltersOpen}
        onClose={onCloseMobileFilters}
        title="Фильтры результатов"
        isResetDisabled={isResetDisabled}
        onReset={handleResetFiltersDrawer}
        onApply={handleApplyFiltersDrawer}
      >
        <RadioGroupControl
          isMobile
          label="Оценка"
          value={tempMarkType}
          options={markTypeOptions}
          controller={tempMarkTypeController}
        />
        {withYearSelector && (
          <RadioGroupControl
            isMobile
            label="Учебный год"
            value={tempYearCode}
            options={yearOptions}
            controller={tempYearController}
            renderLabel={learningYearRenderLabel}
          />
        )}
      </MobileActionsDrawer>
    </>
  );

  return isMobile ? mobileFilters : desktopFilters;
};

export default PerformanceFilters;
