import React from 'react';

import { MenuItemProps, SvgIconProps } from '@mui/material';
import { BaseMenuItem } from 'portfolio3/ui-kit';

import './index.scss';

interface IEntityActionsItemProps {
  isDisabled: boolean;
  text: string;
  icon?: React.FC<SvgIconProps> | React.FC<React.SVGProps<SVGSVGElement>>;
  onClick?: () => void;
  MenuItemProps?: Partial<MenuItemProps>;
}

const EntityActionsItem: React.FC<IEntityActionsItemProps> = ({
  isDisabled,
  text,
  icon: Icon,
  onClick,
  MenuItemProps,
}) => {
  return (
    <BaseMenuItem
      sx={{ paddingInline: 0 }}
      disabled={isDisabled}
      onClick={onClick}
      className="entity-actions-item"
      {...MenuItemProps}
    >
      {Icon && <Icon className="entity-actions-item__menu-icon" />}
      {text}
    </BaseMenuItem>
  );
};

export default EntityActionsItem;
