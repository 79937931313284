/* eslint-disable max-len */
import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconSportHikesCommon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0Zm3.1 15.1L4.8 19.2 8.9 8.9l10.4-4.1-4.2 10.3Z"
      fill={props.fill || 'currentColor'}
    />
    <path d="M14 12c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2Z" fill={props.fill || 'currentColor'} />
  </SvgIcon>
);

export default IconSportHikesCommon;
