import { StudentSectionSettingTypeCodes } from 'const';
import { useAppSelector } from 'hooks';
import { visibilitySettingsSelector } from 'selectors';
import { findTargetVisibilitySetting } from 'utils/localVisibilitySettingsService';

import { collection as searchActions } from '../model/actions';
import {
  searchCivilClubsSelector,
  searchCivilContestsSelector,
  searchCivilRewardsSelector,
  searchCivilSectionsSelector,
} from '../model/selectors';

const civilCode = StudentSectionSettingTypeCodes.civil;
const rewardCode = StudentSectionSettingTypeCodes.civilReward;
const clubCode = StudentSectionSettingTypeCodes.civilClub;
const contestCode = StudentSectionSettingTypeCodes.civilContest;
const occupationCode = StudentSectionSettingTypeCodes.civilUnit;

const useSearchCivil = () => {
  const settings = useAppSelector(visibilitySettingsSelector);
  const { loading: isRewardsLoading, content: rewards } = useAppSelector(searchCivilRewardsSelector);
  const { loading: isClubsLoading, content: clubs } = useAppSelector(searchCivilClubsSelector);
  const { loading: isContestsLoading, content: contests } = useAppSelector(searchCivilContestsSelector);
  const { loading: isOccupationsLoading, content: occupations } = useAppSelector(searchCivilSectionsSelector);

  const civilEntitiesCount = [rewards.length, clubs.length, contests.length, occupations.length].reduce(
    (prev, current) => prev + current,
    0,
  );

  const hasRewards = rewards.length > 0;
  const hasClubs = clubs.length > 0;
  const hasContests = contests.length > 0;
  const hasOccupations = occupations.length > 0;

  const isRewardsEnabled = findTargetVisibilitySetting(settings, [civilCode, rewardCode])?.computedVisibility ?? false;
  const isClubsEnabled = findTargetVisibilitySetting(settings, [civilCode, clubCode])?.computedVisibility ?? false;
  const isContestsEnabled =
    findTargetVisibilitySetting(settings, [civilCode, contestCode])?.computedVisibility ?? false;
  const isOccupationsEnabled =
    findTargetVisibilitySetting(settings, [civilCode, occupationCode])?.computedVisibility ?? false;

  const isCivilRewardVisible = hasRewards && isRewardsEnabled;
  const isCivilClubVisible = hasClubs && isClubsEnabled;
  const isCivilContestVisible = hasContests && isContestsEnabled;
  const isCivilOccupationVisible = hasOccupations && isOccupationsEnabled;

  const isCivilLoading = isRewardsLoading || isClubsLoading || isContestsLoading || isOccupationsLoading;
  const isCivilVisible =
    isCivilRewardVisible || isCivilClubVisible || isCivilContestVisible || isCivilOccupationVisible;

  const civilActions = [
    isRewardsEnabled && searchActions.searchRewardsActions,
    isClubsEnabled && searchActions.searchAffiliationsActions,
    isContestsEnabled && searchActions.searchEventsActions,
    isOccupationsEnabled && searchActions.searchEmploymentsActions,
  ];

  return {
    rewards,
    clubs,
    contests,
    occupations,
    isCivilRewardVisible,
    isCivilClubVisible,
    isCivilContestVisible,
    isCivilOccupationVisible,
    isCivilLoading,
    isCivilVisible,
    civilActions,
    civilEntitiesCount,
  };
};

export default useSearchCivil;
