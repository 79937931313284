/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { Droppable } from 'react-beautiful-dnd';
import { useMediaQuery } from '@mui/material';

import DirectionsDragItem from './dragItem';
import { IDragItem, TContainerKeys } from '../types';
import { getDragItemRotation } from './utils';
import NoDataPlug from './noDataPlug';

import './index.scss';

interface IInitialItemsContainerProps {
  prefix: string,
  elements: IDragItem[],
  moveElementOnClick: (sourceIndex: number, destinationId: TContainerKeys) => void,
  onChangeStepForward: () => void
}

const InitialItemsContainer: React.FC<IInitialItemsContainerProps> = ({
  prefix,
  elements,
  moveElementOnClick,
  onChangeStepForward
}) => {
  const isTablet = useMediaQuery('(max-width: 1023px)', { noSsr: true });
  const isMobile = useMediaQuery('(max-width: 767px)', { noSsr: true });

  const getRotation = (itemIndex: number, length: number) => {
    if (isTablet) return '0deg';
    return getDragItemRotation(itemIndex, length);
  };

  return (
    <div className="interests-directions__items-container initial-items-container">
      <Droppable droppableId={prefix}>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {elements.map((dragItem: IDragItem, index: number, array: IDragItem[]) => (
              <DirectionsDragItem
                index={index}
                key={dragItem.id}
                id={dragItem.id}
                name={dragItem.value}
                cardTheme={dragItem.theme.card}
                rotation={getRotation(index, array.length)}
                isInInitialContainer
                moveElementOnClick={moveElementOnClick}
              />
            ))}
            <p>
              {provided.placeholder}
            </p>
          </div>
        )}
      </Droppable>
      {elements.length === 0 && !isMobile && (
        <NoDataPlug onChangeStepForward={onChangeStepForward} />
      )}
    </div>
  );
};

export default InitialItemsContainer;
