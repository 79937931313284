enum TrainingTypeKeys {
  BACHELOR = 'bachelor',
  SPECIALIST = 'specialist',
}

export const TrainingTypeCodes: Record<TrainingTypeKeys, number> = {
  bachelor: 1,
  specialist: 2,
};

export const TrainingTypeNames: Record<TrainingTypeKeys, string> = {
  bachelor: 'Бакалавриат',
  specialist: 'Специалитет',
};

export const TrainingTypeCodeNames: Record<number, string> = {
  [TrainingTypeCodes.bachelor]: TrainingTypeNames.bachelor,
  [TrainingTypeCodes.specialist]: TrainingTypeNames.specialist,
};

// в таком виде данные приходят с сервера
export const TrainingTypeResponseMapping = {
  ['бакалавр']: TrainingTypeCodes.bachelor,
  ['специалист']: TrainingTypeCodes.specialist,
};
