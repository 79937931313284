import { FC } from 'react';

import { Box } from '@mui/material';
import { getAccentColor, NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

const styles: SxStyles = {
  position: 'relative',
  width: '24px',
  height: '24px',
};

const innerStyles: SxStyles = {
  '@keyframes loading': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },

  position: 'absolute',
  width: '100%',
  height: '100%',

  border: '3px solid',
  borderRadius: '50%',
  borderColor: NeutralColors.light_neutrals_300,
  borderTopColor: getAccentColor('blue', '100'),
  animation: 'loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite',
};

const LoadingIndicator: FC = () => {
  return (
    <Box className="loading-indicator" sx={styles}>
      <Box sx={innerStyles} />
    </Box>
  );
};

export default LoadingIndicator;
