import { FC, PropsWithChildren } from 'react';

import { Typography } from '@mui/material';
import TrimText from 'portfolio3/components/common/TrimText';
import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

const styles: SxStyles = {
  display: 'grid',

  height: 'min-content',
  padding: '6px 12px',

  border: '1px solid',
  borderColor: NeutralColors.night_neutrals_700,
  borderRadius: '4px',
  backgroundColor: NeutralColors.light_neutrals_0,
};

const InterestBadge: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Typography className="interest-badge" variant="Paragraph MD/Regular" sx={styles}>
      <TrimText>{children}</TrimText>
    </Typography>
  );
};

export default InterestBadge;
