/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';
import { IDeleteHistoryFilters } from '../containers/admin/DeleteHistory/types';

interface IDeleteHistoryContext {
  currentFilters: IDeleteHistoryFilters;
  setCurrentFilters: React.Dispatch<React.SetStateAction<IDeleteHistoryFilters>>;
}

export default React.createContext({} as IDeleteHistoryContext);
