import { FC, useContext, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import { DocumentTypeCodes, SectionCodes } from 'const';
import { BaseInputLabel, FormControl } from 'portfolio3/ui-kit';
import { Select } from 'portfolio3/ui-kit/selects';
import { IRootState } from 'reducers';
import { DocumentRefState } from 'reducers/reference';
import { createSelector } from 'reselect';
import { deepSetProperty } from 'utils';

import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';
import { getCommonSelectControllerFactory } from '../../utils';

interface IStudySpoDocumentPrimaryBlockProps {
  documentRef: DocumentRefState;
}

const StudySpoDocumentPrimaryBlock: FC<IStudySpoDocumentPrimaryBlockProps> = ({ documentRef }) => {
  const { inputRenderMode, inputSize, formData, onChangeFormData, onChangeFormErrors } =
    useContext(LocalDataEntryFormContext);

  const selectController = getCommonSelectControllerFactory(onChangeFormData, onChangeFormErrors);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const documentTypeController = useMemo(() => selectController('studySpoDocument.documentTypeCode'), [formData.id]);

  const data = formData.studySpoDocument;

  // Установка documentName при выборе типа документа
  useEffect(() => {
    const documentCode = data?.documentTypeCode;

    if (documentCode === DocumentTypeCodes.spoOther) return;

    const documentName = documentRef.content.find((ref) => ref.code === documentCode)?.value;
    onChangeFormData((prevState) => deepSetProperty(prevState, 'studySpoDocument.documentName', documentName));
  }, [data?.documentTypeCode, documentRef.content, onChangeFormData]);

  return (
    <FormControl
      renderMode={inputRenderMode}
      inputSize={inputSize}
      label={<BaseInputLabel>Тип документа</BaseInputLabel>}
      control={
        <Select
          inputRenderMode={inputRenderMode}
          inputSize={inputSize}
          placeholder="Выбери тип..."
          value={data?.documentTypeCode}
          options={documentRef.content}
          controller={documentTypeController}
        />
      }
    />
  );
};

const documentRefSelector = createSelector([(state: IRootState) => state.documentRef], (documentRef) => {
  return {
    ...documentRef,
    content: documentRef.content.filter((document) => document.typeCode === SectionCodes.studySpoDocuments),
  };
});

export default connect((state: IRootState) => ({
  documentRef: documentRefSelector(state),
}))(StudySpoDocumentPrimaryBlock);
