/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';

import {
  GET_STUDENT_GIA_LIST
} from '../../actions';

import {
  FAILURE,
  INVALIDATE,
  REQUEST,
  SUCCESS
} from '../../actions/utils';

import { ICommonResponse, IGiaItem } from '../../api/types';

import genericReducers from '../genericReducer';

import { IReducers } from '..';

export type StudentGiaListState = ICommonResponse<IGiaItem>;

const initialState: StudentGiaListState = {
  content: [],
  loading: false,
  requestCache: 'notCached',
};

const studentGiaList: IReducers<StudentGiaListState> = {
  [GET_STUDENT_GIA_LIST[REQUEST]]: genericReducers().get.request,
  [GET_STUDENT_GIA_LIST[SUCCESS]]: genericReducers().get.success,
  [GET_STUDENT_GIA_LIST[FAILURE]]: genericReducers().get.failure,
  [GET_STUDENT_GIA_LIST[INVALIDATE]]: genericReducers().get.invalidate(initialState),
};

export default connectReducers(initialState, studentGiaList);
