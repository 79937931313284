/* eslint-disable max-len */
import { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const Monkey: FC<SvgIconProps> = (props) => (
  <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.5157 22.4238C9.54468 22.4238 12.0002 20.0069 12.0002 17.0254C12.0002 14.0439 9.54468 11.627 6.5157 11.627C3.48672 11.627 1.03125 14.0439 1.03125 17.0254C1.03125 20.0069 3.48672 22.4238 6.5157 22.4238Z"
      fill="url(#paint0_linear_64_89892)"
    />
    <path
      d="M6.5157 22.4238C9.54468 22.4238 12.0002 20.0069 12.0002 17.0254C12.0002 14.0439 9.54468 11.627 6.5157 11.627C3.48672 11.627 1.03125 14.0439 1.03125 17.0254C1.03125 20.0069 3.48672 22.4238 6.5157 22.4238Z"
      fill="url(#paint1_linear_64_89892)"
    />
    <path
      d="M6.5157 22.4238C9.54468 22.4238 12.0002 20.0069 12.0002 17.0254C12.0002 14.0439 9.54468 11.627 6.5157 11.627C3.48672 11.627 1.03125 14.0439 1.03125 17.0254C1.03125 20.0069 3.48672 22.4238 6.5157 22.4238Z"
      fill="url(#paint2_radial_64_89892)"
      fillOpacity="0.5"
    />
    <path
      d="M25.4329 22.4238C28.4619 22.4238 30.9174 20.0069 30.9174 17.0254C30.9174 14.0439 28.4619 11.627 25.4329 11.627C22.404 11.627 19.9485 14.0439 19.9485 17.0254C19.9485 20.0069 22.404 22.4238 25.4329 22.4238Z"
      fill="url(#paint3_linear_64_89892)"
    />
    <g filter="url(#filter0_f_64_89892)">
      <path
        d="M26.8369 12.3984C27.8162 12.5459 29.7109 13.8359 30.13 15.9082C30.6144 17.6895 29.9581 19.9707 28.4581 20.8614"
        stroke="url(#paint4_radial_64_89892)"
        strokeOpacity="0.8"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
    </g>
    <path
      d="M15.9999 29.3728C23.9662 29.3728 27.947 24.0239 27.947 17.4257C27.947 10.8274 23.9662 5.47852 15.9999 5.47852C8.03353 5.47852 4.05273 10.8274 4.05273 17.4257C4.05273 24.0239 8.03353 29.3728 15.9999 29.3728Z"
      fill="url(#paint5_radial_64_89892)"
    />
    <path
      d="M15.9999 29.3728C23.9662 29.3728 27.947 24.0239 27.947 17.4257C27.947 10.8274 23.9662 5.47852 15.9999 5.47852C8.03353 5.47852 4.05273 10.8274 4.05273 17.4257C4.05273 24.0239 8.03353 29.3728 15.9999 29.3728Z"
      fill="url(#paint6_linear_64_89892)"
    />
    <path
      d="M15.9999 29.3728C23.9662 29.3728 27.947 24.0239 27.947 17.4257C27.947 10.8274 23.9662 5.47852 15.9999 5.47852C8.03353 5.47852 4.05273 10.8274 4.05273 17.4257C4.05273 24.0239 8.03353 29.3728 15.9999 29.3728Z"
      fill="url(#paint7_radial_64_89892)"
    />
    <path
      d="M15.9999 29.3728C23.9662 29.3728 27.947 24.0239 27.947 17.4257C27.947 10.8274 23.9662 5.47852 15.9999 5.47852C8.03353 5.47852 4.05273 10.8274 4.05273 17.4257C4.05273 24.0239 8.03353 29.3728 15.9999 29.3728Z"
      fill="url(#paint8_radial_64_89892)"
    />
    <g filter="url(#filter1_f_64_89892)">
      <ellipse
        cx="23.4176"
        cy="12.9398"
        rx="1.84881"
        ry="4.33309"
        transform="rotate(-23.3995 23.4176 12.9398)"
        fill="#C58E7D"
      />
    </g>
    <g filter="url(#filter2_f_64_89892)">
      <ellipse
        cx="15.7736"
        cy="6.97139"
        rx="0.386034"
        ry="3.0636"
        transform="rotate(-90 15.7736 6.97139)"
        fill="#C58E7D"
      />
    </g>
    <g filter="url(#filter3_f_64_89892)">
      <path
        d="M11.8773 9.14844C9.24877 9.14844 7.11792 11.2793 7.11792 13.9078C7.11792 15.0687 7.53356 16.1326 8.22415 16.9586C8.33044 17.0858 8.37652 17.2536 8.34568 17.4165C8.25992 17.8692 8.21505 18.3364 8.21505 18.814C8.21505 22.936 11.5566 26.2776 15.6786 26.2776C19.8007 26.2776 23.1422 22.936 23.1422 18.814C23.1422 18.3364 23.0973 17.8692 23.0116 17.4165C22.9807 17.2536 23.0268 17.0858 23.1331 16.9586C23.8237 16.1326 24.2393 15.0687 24.2393 13.9078C24.2393 11.2793 22.1085 9.14844 19.48 9.14844H11.8773Z"
        fill="#561E25"
      />
    </g>
    <path
      d="M12.1562 8.90039C9.49838 8.90039 7.34375 11.055 7.34375 13.7129C7.34375 14.8878 7.76479 15.9644 8.46422 16.8C8.57059 16.9271 8.61667 17.095 8.58576 17.2578C8.49869 17.7165 8.45312 18.1898 8.45312 18.6738C8.45312 22.8419 11.832 26.2207 16 26.2207C20.168 26.2207 23.5469 22.8419 23.5469 18.6738C23.5469 18.1898 23.5013 17.7165 23.4142 17.2578C23.3833 17.095 23.4294 16.9271 23.5358 16.8C24.2352 15.9644 24.6562 14.8878 24.6562 13.7129C24.6562 11.055 22.5016 8.90039 19.8438 8.90039H12.1562Z"
      fill="url(#paint9_linear_64_89892)"
    />
    <path
      d="M12.1562 8.90039C9.49838 8.90039 7.34375 11.055 7.34375 13.7129C7.34375 14.8878 7.76479 15.9644 8.46422 16.8C8.57059 16.9271 8.61667 17.095 8.58576 17.2578C8.49869 17.7165 8.45312 18.1898 8.45312 18.6738C8.45312 22.8419 11.832 26.2207 16 26.2207C20.168 26.2207 23.5469 22.8419 23.5469 18.6738C23.5469 18.1898 23.5013 17.7165 23.4142 17.2578C23.3833 17.095 23.4294 16.9271 23.5358 16.8C24.2352 15.9644 24.6562 14.8878 24.6562 13.7129C24.6562 11.055 22.5016 8.90039 19.8438 8.90039H12.1562Z"
      fill="url(#paint10_radial_64_89892)"
    />
    <path
      d="M12.1562 8.90039C9.49838 8.90039 7.34375 11.055 7.34375 13.7129C7.34375 14.8878 7.76479 15.9644 8.46422 16.8C8.57059 16.9271 8.61667 17.095 8.58576 17.2578C8.49869 17.7165 8.45312 18.1898 8.45312 18.6738C8.45312 22.8419 11.832 26.2207 16 26.2207C20.168 26.2207 23.5469 22.8419 23.5469 18.6738C23.5469 18.1898 23.5013 17.7165 23.4142 17.2578C23.3833 17.095 23.4294 16.9271 23.5358 16.8C24.2352 15.9644 24.6562 14.8878 24.6562 13.7129C24.6562 11.055 22.5016 8.90039 19.8438 8.90039H12.1562Z"
      fill="url(#paint11_radial_64_89892)"
    />
    <g filter="url(#filter4_f_64_89892)">
      <path
        d="M5.7101 10.8256C4.11739 14.755 4.29871 20.5074 6.04088 22.9554"
        stroke="url(#paint12_linear_64_89892)"
        strokeOpacity="0.4"
        strokeWidth="0.8"
        strokeLinecap="round"
      />
    </g>
    <g filter="url(#filter5_f_64_89892)">
      <path
        d="M26.9201 12.9548C27.7501 16.2345 27.6702 20.5173 26.1702 22.9548"
        stroke="url(#paint13_linear_64_89892)"
        strokeOpacity="0.5"
        strokeWidth="0.4"
        strokeLinecap="round"
      />
    </g>
    <g filter="url(#filter6_f_64_89892)">
      <path
        d="M21.4197 9.51562C22.7163 10.025 25.1095 12.0107 23.9148 15.4005"
        stroke="url(#paint14_linear_64_89892)"
        strokeWidth="0.4"
        strokeLinecap="round"
      />
    </g>
    <g filter="url(#filter7_f_64_89892)">
      <path
        d="M23.0134 17.2598C23.5134 20.1973 22.4197 22.3848 21.4197 23.291"
        stroke="url(#paint15_linear_64_89892)"
        strokeWidth="0.1"
        strokeLinecap="round"
      />
    </g>
    <path
      d="M12.0063 20.4062C12.0063 22.702 13.6546 24.4117 16.0001 24.4117C18.523 24.4117 19.9939 22.63 19.9939 20.4062H12.0063Z"
      fill="url(#paint16_radial_64_89892)"
    />
    <g filter="url(#filter8_f_64_89892)">
      <rect x="11.6807" y="13.541" width="1.625" height="3.25" rx="0.8125" fill="#BB6F89" fillOpacity="0.6" />
    </g>
    <g filter="url(#filter9_f_64_89892)">
      <rect x="17.8574" y="13.541" width="1.625" height="3.25" rx="0.8125" fill="#BB6F89" fillOpacity="0.6" />
    </g>
    <rect x="12.2495" y="13.3867" width="1.50801" height="3.01601" rx="0.754003" fill="url(#paint17_linear_64_89892)" />
    <rect x="12.2495" y="13.3867" width="1.50801" height="3.01601" rx="0.754003" fill="url(#paint18_linear_64_89892)" />
    <rect x="18.2427" y="13.3867" width="1.50801" height="3.01601" rx="0.754003" fill="url(#paint19_linear_64_89892)" />
    <rect x="18.2427" y="13.3867" width="1.50801" height="3.01601" rx="0.754003" fill="url(#paint20_linear_64_89892)" />
    <g filter="url(#filter10_f_64_89892)">
      <rect x="13.1016" y="16.791" width="5.34375" height="2.41769" rx="1.11607" fill="#BB6F89" />
    </g>
    <rect x="13.5547" y="16.3867" width="4.89062" height="2.23214" rx="1.11607" fill="url(#paint21_linear_64_89892)" />
    <rect x="13.5547" y="16.3867" width="4.89062" height="2.23214" rx="1.11607" fill="url(#paint22_linear_64_89892)" />
    <circle cx="14.5488" cy="17.9043" r="0.511719" fill="url(#paint23_radial_64_89892)" />
    <circle cx="17.3457" cy="17.9043" r="0.511719" fill="url(#paint24_radial_64_89892)" />
    <g filter="url(#filter11_f_64_89892)">
      <path
        d="M17.2429 17.0016C17.5867 16.9313 18.0632 17.1891 18.1179 17.7125"
        stroke="url(#paint25_linear_64_89892)"
        strokeWidth="0.05"
      />
    </g>
    <g filter="url(#filter12_f_64_89892)">
      <circle cx="19.1987" cy="14.0142" r="0.283707" fill="#795D72" />
    </g>
    <g filter="url(#filter13_f_64_89892)">
      <circle cx="13.271" cy="14.1587" r="0.283707" fill="#795D72" />
    </g>
    <defs>
      <filter
        id="filter0_f_64_89892"
        x="26.1869"
        y="11.7484"
        width="4.75044"
        height="9.76289"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.2" result="effect1_foregroundBlur_64_89892" />
      </filter>
      <filter
        id="filter1_f_64_89892"
        x="17.501"
        y="5.39453"
        width="11.8333"
        height="15.0898"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="1.75" result="effect1_foregroundBlur_64_89892" />
      </filter>
      <filter
        id="filter2_f_64_89892"
        x="9.20996"
        y="3.08594"
        width="13.1272"
        height="7.77148"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="1.75" result="effect1_foregroundBlur_64_89892" />
      </filter>
      <filter
        id="filter3_f_64_89892"
        x="5.11792"
        y="7.14844"
        width="21.1213"
        height="21.1289"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_64_89892" />
      </filter>
      <filter
        id="filter4_f_64_89892"
        x="3.81753"
        y="10.0258"
        width="3.02339"
        height="13.7297"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.2" result="effect1_foregroundBlur_64_89892" />
      </filter>
      <filter
        id="filter5_f_64_89892"
        x="25.5702"
        y="12.3539"
        width="2.47676"
        height="11.2004"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.2" result="effect1_foregroundBlur_64_89892" />
      </filter>
      <filter
        id="filter6_f_64_89892"
        x="20.8197"
        y="8.91641"
        width="4.01973"
        height="7.0832"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.2" result="effect1_foregroundBlur_64_89892" />
      </filter>
      <filter
        id="filter7_f_64_89892"
        x="20.9696"
        y="16.809"
        width="2.61885"
        height="6.93281"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.2" result="effect1_foregroundBlur_64_89892" />
      </filter>
      <filter
        id="filter8_f_64_89892"
        x="10.6807"
        y="12.541"
        width="3.625"
        height="5.25"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_64_89892" />
      </filter>
      <filter
        id="filter9_f_64_89892"
        x="16.8574"
        y="12.541"
        width="3.625"
        height="5.25"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_64_89892" />
      </filter>
      <filter
        id="filter10_f_64_89892"
        x="12.1016"
        y="15.791"
        width="7.34375"
        height="4.41797"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_64_89892" />
      </filter>
      <filter
        id="filter11_f_64_89892"
        x="17.0378"
        y="16.7648"
        width="1.30503"
        height="1.15"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur_64_89892" />
      </filter>
      <filter
        id="filter12_f_64_89892"
        x="17.915"
        y="12.7305"
        width="2.56738"
        height="2.56836"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_64_89892" />
      </filter>
      <filter
        id="filter13_f_64_89892"
        x="11.9873"
        y="12.875"
        width="2.56738"
        height="2.56836"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_64_89892" />
      </filter>
      <linearGradient
        id="paint0_linear_64_89892"
        x1="6.5157"
        y1="13.2051"
        x2="6.5157"
        y2="24.1426"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A67874" />
        <stop offset="1" stopColor="#8F5872" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_64_89892"
        x1="6.5157"
        y1="16.4551"
        x2="6.5157"
        y2="24.6426"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B56595" stopOpacity="0" />
        <stop offset="1" stopColor="#CA6FA6" />
      </linearGradient>
      <radialGradient
        id="paint2_radial_64_89892"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(10.3848 23.0801) rotate(-135.32) scale(15.8218 18.8849)"
      >
        <stop offset="0.631933" stopColor="#F39585" stopOpacity="0" />
        <stop offset="1" stopColor="#F39585" />
      </radialGradient>
      <linearGradient
        id="paint3_linear_64_89892"
        x1="25.4329"
        y1="14.3926"
        x2="25.4329"
        y2="24.6426"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.09375" stopColor="#FAB8A9" />
        <stop offset="1" stopColor="#B56595" />
      </linearGradient>
      <radialGradient
        id="paint4_radial_64_89892"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(29.0206 20.5489) rotate(-131.065) scale(8.37243 17.6373)"
      >
        <stop stopColor="#FFDACC" stopOpacity="0" />
        <stop offset="0.447917" stopColor="#FFDACC" />
        <stop offset="1" stopColor="#FFDACC" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint5_radial_64_89892"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(24.2595 10.9559) rotate(120.705) scale(21.4199 37.9602)"
      >
        <stop stopColor="#A06857" />
        <stop offset="1" stopColor="#5B3838" />
      </radialGradient>
      <linearGradient
        id="paint6_linear_64_89892"
        x1="15.9999"
        y1="17.4257"
        x2="15.9999"
        y2="34.283"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#682B5B" stopOpacity="0" />
        <stop offset="1" stopColor="#682B5B" />
      </linearGradient>
      <radialGradient
        id="paint7_radial_64_89892"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16.0008 14.4396) rotate(90) scale(20.6725 18.4611)"
      >
        <stop offset="0.688473" stopColor="#FF7AF2" stopOpacity="0" />
        <stop offset="1" stopColor="#FFBCE6" />
      </radialGradient>
      <radialGradient
        id="paint8_radial_64_89892"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(23.4923 9.79798) rotate(123.75) scale(19.0483)"
      >
        <stop stopColor="#A9705E" />
        <stop offset="1" stopColor="#A9705E" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint9_linear_64_89892"
        x1="16"
        y1="8.90039"
        x2="16"
        y2="26.9473"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC8BC" />
        <stop offset="0.188" stopColor="#FAB8A9" />
        <stop offset="0.895425" stopColor="#AC628E" />
      </linearGradient>
      <radialGradient
        id="paint10_radial_64_89892"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(25.3054 12.3223) rotate(159.382) scale(20.2335 27.146)"
      >
        <stop offset="0.817708" stopColor="#A55F89" stopOpacity="0" />
        <stop offset="1" stopColor="#A55F89" />
      </radialGradient>
      <radialGradient
        id="paint11_radial_64_89892"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16.0007 15.396) rotate(90.0031) scale(12.7039 10.8463)"
      >
        <stop offset="0.812621" stopColor="#EF5CF2" stopOpacity="0" />
        <stop offset="1" stopColor="#FF63C4" />
      </radialGradient>
      <linearGradient
        id="paint12_linear_64_89892"
        x1="6.64652"
        y1="23.584"
        x2="4.18366"
        y2="10.9147"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7C6160" stopOpacity="0" />
        <stop offset="0.502642" stopColor="#7C6160" />
        <stop offset="1" stopColor="#7C6160" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_64_89892"
        x1="24.9784"
        y1="22.9548"
        x2="28.2693"
        y2="15.0138"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BE8B89" stopOpacity="0" />
        <stop offset="0.295666" stopColor="#BE8B89" stopOpacity="0.6" />
        <stop offset="0.808331" stopColor="#BE8B89" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_64_89892"
        x1="24.5281"
        y1="14.8318"
        x2="20.9161"
        y2="9.41383"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDACC" stopOpacity="0" />
        <stop offset="0.557292" stopColor="#FFDACC" />
        <stop offset="1" stopColor="#FFDACC" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_64_89892"
        x1="22.7673"
        y1="22.7088"
        x2="22.0867"
        y2="18.6348"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0255118" stopColor="#FFDACC" stopOpacity="0" />
        <stop offset="1" stopColor="#FFDACC" />
      </linearGradient>
      <radialGradient
        id="paint16_radial_64_89892"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16.6535 25.2036) rotate(-90) scale(9.07087 17.6698)"
      >
        <stop stopColor="#EF0084" />
        <stop offset="0.547619" stopColor="#89029C" />
      </radialGradient>
      <linearGradient
        id="paint17_linear_64_89892"
        x1="15.8515"
        y1="14.4797"
        x2="11.5056"
        y2="14.4797"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#432A3D" />
        <stop offset="0.567708" stopColor="#684E61" />
        <stop offset="0.765625" stopColor="#43253D" />
      </linearGradient>
      <linearGradient
        id="paint18_linear_64_89892"
        x1="13.3443"
        y1="14.3194"
        x2="13.3443"
        y2="16.6752"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.326531" stopColor="#43253D" stopOpacity="0" />
        <stop offset="1" stopColor="#43253D" />
      </linearGradient>
      <linearGradient
        id="paint19_linear_64_89892"
        x1="21.8447"
        y1="14.4797"
        x2="17.4988"
        y2="14.4797"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#432A3D" />
        <stop offset="0.567708" stopColor="#684E61" />
        <stop offset="0.765625" stopColor="#43253D" />
      </linearGradient>
      <linearGradient
        id="paint20_linear_64_89892"
        x1="19.3374"
        y1="14.3194"
        x2="19.3374"
        y2="16.6752"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.326531" stopColor="#43253D" stopOpacity="0" />
        <stop offset="1" stopColor="#43253D" />
      </linearGradient>
      <linearGradient
        id="paint21_linear_64_89892"
        x1="16"
        y1="16.3867"
        x2="16"
        y2="18.5251"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E9A4A2" />
        <stop offset="1" stopColor="#DC89A8" />
      </linearGradient>
      <linearGradient
        id="paint22_linear_64_89892"
        x1="15.4929"
        y1="21.322"
        x2="14.7528"
        y2="17.7068"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0700389" stopColor="#C971B6" />
        <stop offset="1" stopColor="#DC90CC" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint23_radial_64_89892"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(14.9046 17.5437) rotate(133.174) scale(1.90311 1.14415)"
      >
        <stop stopColor="#3E2739" />
        <stop offset="1" stopColor="#684E61" />
      </radialGradient>
      <radialGradient
        id="paint24_radial_64_89892"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17.608 17.5437) rotate(129.408) scale(1.21922 1.90769)"
      >
        <stop stopColor="#3C2737" />
        <stop offset="1" stopColor="#684E61" />
      </radialGradient>
      <linearGradient
        id="paint25_linear_64_89892"
        x1="17.6804"
        y1="16.9902"
        x2="17.6804"
        y2="17.7125"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDACC" stopOpacity="0" />
        <stop offset="0.348958" stopColor="#FFEBE3" />
      </linearGradient>
    </defs>
  </SvgIcon>
);

export default Monkey;
