export const makeRange = (first: number, last: number) => {
  const length = last - first + 1;
  return Array.from({ length }, (_, index) => first + index);
};

export const splitChunks = <T>(array: T[], chunkSize: number) => {
  return array
    .map((_, globalIndex: number, globalArray) => {
      return globalArray.slice(globalIndex * chunkSize, (globalIndex + 1) * chunkSize);
    })
    .filter((array) => array.length > 0);
};

export const sortByPropAsc = <T, Prop extends keyof T>(array: T[], prop: Prop): T[] => {
  return [...array].sort((a, b) => Number(a[prop]) - Number(b[prop]));
};

export const sortByPropDesc = <T, Prop extends keyof T>(array: T[], prop: Prop): T[] => {
  return [...array].sort((a, b) => Number(b[prop]) - Number(a[prop]));
};

export const trimString = (str: string, maxLength: number): string => {
  if (str.length <= maxLength) return str;
  return str.slice(0, maxLength - 3).padEnd(maxLength, '.');
};
