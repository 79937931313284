import { SxStyles } from 'types';

export const whiteBackground: SxStyles = {
  zIndex: 9999,
  position: 'fixed',
  inset: 0,
  backgroundColor: '#ffffff',
};

export const background: SxStyles = {
  zIndex: 10000,
  position: 'fixed',
  inset: 0,
  minHeight: '100vh',
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
};

export const image: SxStyles = {
  width: 168,
};
