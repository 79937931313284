import { createSelector } from 'reselect';
import { isDefined } from 'utils';

import proforientationRecommendedIndustriesSelector from './proforientationRecommendedIndustriesSelector';
import proforientationResultLinkSelector from './proforientationResultLinkSelector';
import proforientationTestsSelector from './proforientationTestsSelector';

const proforientationVisibilitySelector = createSelector(
  [proforientationResultLinkSelector, proforientationRecommendedIndustriesSelector, proforientationTestsSelector],
  (resultLink, recommendedIndustries, proforientationTests) => {
    return {
      isProforientationVisible: isDefined(resultLink),
      isRecommendationsVisible: recommendedIndustries.length > 0,
      isProforientationTestsVisible: proforientationTests.length > 0,
    };
  },
);

export default proforientationVisibilitySelector;
