import { useEffect } from 'react';

const dataKey = 'usePreloadImagesData';

// https://stackoverflow.com/questions/42615556/how-to-preload-images-in-react-js
const usePreloadImages = (imageSrcs: string[]) => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const anyWindow = window as any;
    const localKey = Symbol();

    anyWindow[dataKey] = anyWindow[dataKey] ?? {};
    anyWindow[dataKey][localKey] = [];
    const localArray = anyWindow[dataKey][localKey];

    for (const src of imageSrcs) {
      const image = new Image();
      image.src = src;
      localArray.push(image);
    }

    return () => {
      delete anyWindow[dataKey]?.[localKey];
    };
  }, [imageSrcs]);
};

export default usePreloadImages;
