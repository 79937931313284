import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconBookmarkOutline: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M11.821 15.506c.17 0 .34.043.492.129L17 18.285V5.335c0-.201-.12-.334-.2-.334H7.2c-.08 0-.2.133-.2.334v12.9l4.306-2.585c.16-.095.337-.143.515-.143ZM6.001 21a1 1 0 0 1-1-1V5.334C5 4.047 5.986 3 7.2 3h9.6C18.012 3 19 4.047 19 5.334V20a1 1 0 0 1-1.493.87l-5.672-3.207-5.322 3.195A1.006 1.006 0 0 1 6 21Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconBookmarkOutline;
