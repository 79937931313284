/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import MobileDragIndicator from '../../../../common/MobileDragIndicator';
import { Button, Checkbox, Drawer, FormControlLabel } from '../../../../../ui-kit';
import { CommonInterestKindsProps } from './types';

import './index.scss';

interface IMobileInterestKindsProps extends CommonInterestKindsProps {
  isOpen: boolean;
  onClose: () => void;
  onReset: () => void;
  onAccept: () => void;
}

const MobileInterestKinds: React.FC<IMobileInterestKindsProps> = ({
  interestKinds,
  theme,
  selectedKinds,
  onSelectKind,
  isOpen,
  onClose,
  onReset,
  onAccept
}) => {
  return (
    <Drawer
      className="interests-kinds interests-kinds--mobile"
      open={isOpen}
      isFullHeight={false}
      isContainerScrollable
      anchor="bottom"
      onClose={onClose}
    >
      <MobileDragIndicator onTouchStart={onClose} />

      <div className="interests-kinds__items">
        {interestKinds.map((kind) => {
          const isSelected = selectedKinds?.find((selectedId) => kind.code === selectedId) !== undefined;

          return (
            <FormControlLabel
              key={kind.id}
              control={(
                <Checkbox
                  checked={isSelected}
                  onChange={onSelectKind(kind.code)}
                  background={theme.mainColor}
                />
              )}
              label={<p>{kind.name}</p>}
            />
          );
        })}
      </div>
      <div className="interests-kinds__buttons">
        <Button
          className="interests-kinds__button"
          variant="outlined"
          color={"secondary"}
          onClick={onReset}
        >
          Сбросить
        </Button>
        <Button
          className="interests-kinds__button"
          variant="contained"
          onClick={onAccept}
        >
          Применить
        </Button>
      </div>
    </Drawer>
  );
};

export default MobileInterestKinds;
