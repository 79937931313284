import { FC } from 'react';

import { Box } from '@mui/material';
import { IconEditOutline, IconTrashAltOutline } from 'icons';
import { Button } from 'portfolio3/ui-kit/button';

interface IFormEntityHoverActionsProps {
  onEdit: () => void;
  onClear: () => void;
}

const FormEntityHoverActions: FC<IFormEntityHoverActionsProps> = ({ onEdit, onClear }) => {
  return (
    <Box sx={{ display: 'flex', gap: '10px' }}>
      <Button onlyIcon variant="primary" onClick={onEdit}>
        <IconEditOutline />
      </Button>
      <Button onlyIcon variant="secondary" onClick={onClear}>
        <IconTrashAltOutline />
      </Button>
    </Box>
  );
};

export default FormEntityHoverActions;
