import { FC } from 'react';

import useClientWidth from 'hooks/useClientWidth';
import { ISubjectTheme } from 'portfolio3/features/performance';

import SubjectOverview from './SubjectOverview';
import SubjectThemeList from './SubjectThemeList';
import SubjectThemeListMobile from './SubjectThemeListMobile';

import './index.scss';

interface ISubjectThemesProps {
  subject: string;
  subjectGrade: number;
  themePassedCount: number;
  themeTotalCount: number;
  themeCompletionPercent: number;
  themes: ISubjectTheme[];
}

const SubjectThemes: FC<ISubjectThemesProps> = ({
  subject,
  subjectGrade,
  themePassedCount,
  themeTotalCount,
  themeCompletionPercent,
  themes,
}) => {
  const clientWidth = useClientWidth();

  return (
    <div className="subject-themes">
      <div className="subject-subject-themes__overview">
        <SubjectOverview
          subject={subject}
          totalTopics={themeTotalCount}
          passedTopics={themePassedCount}
          topicsDonePercent={themeCompletionPercent}
          subjectResult={subjectGrade}
        />
      </div>
      <div className="subject-themes__topics">
        {clientWidth > 1023 ? <SubjectThemeList themes={themes} /> : <SubjectThemeListMobile themes={themes} />}
      </div>
    </div>
  );
};

export default SubjectThemes;
