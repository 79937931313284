import { CommonUiKitSize } from 'portfolio3/ui-kit/types';

import { IButtonTokens } from '../types';

export const ButtonHeight: Record<CommonUiKitSize, number> = {
  small: 36,
  medium: 40,
  large: 48,
};

export const ButtonFontSize: Record<CommonUiKitSize, number> = {
  small: 14,
  medium: 14,
  large: 15,
};

export const ButtonLineHeight: Record<CommonUiKitSize, number> = {
  small: 20,
  medium: 20,
  large: 24,
};

export const ButtonInlinePadding: Record<CommonUiKitSize, number> = {
  small: 12,
  medium: 16,
  large: 20,
};

export const getButtonTokens = (size: CommonUiKitSize): IButtonTokens => {
  return {
    height: ButtonHeight[size],
    fontSize: ButtonFontSize[size],
    lineHeight: ButtonLineHeight[size],
    inlinePadding: ButtonInlinePadding[size],
  };
};
