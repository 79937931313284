export enum ApiPathKeys {
  ATTACHMENT = 'ATTACHMENT',
  BASE_PREFIX = 'BASE_PREFIX',
  FOS = 'FOS',
  PORTFOLIO = 'PORTFOLIO',
  REFERENCE = 'REFERENCE',
  SETTINGS = 'SETTINGS',
  STUDENT = 'STUDENT',
  EMPLOYEE = 'EMPLOYEE',
  USERS = 'USERS',
  SEARCH = 'SEARCH',
  ADMIN = 'ADMIN',
}

export const apiPaths: Record<ApiPathKeys, string> = {
  ATTACHMENT: '/attachment',
  BASE_PREFIX: '/app',
  FOS: '/fos',
  PORTFOLIO: '/portfolio',
  REFERENCE: '/reference',
  SETTINGS: '/settings',
  STUDENT: '/persons',
  EMPLOYEE: '/employee',
  USERS: '/users',
  SEARCH: '/search',
  ADMIN: '/admin',
};
