import { Colors } from 'style/variables';
import { SxStyles } from 'types';

import { DragIndicatorSize } from '../types';

const dragIndicatorSizes: Record<DragIndicatorSize, string> = {
  small: '32px',
  medium: '48px',
};

export const dragIndicator =
  (size: DragIndicatorSize): SxStyles =>
  () => {
    return {
      width: dragIndicatorSizes[size],
      height: '4px',

      borderRadius: '100px',
      background: Colors['control-stroke'],
    };
  };
