import { CommonUiKitSize } from 'portfolio3/ui-kit/types';

import { IOutlineToggleTokens } from '../types';

export const OutlineToggleHeight: Record<CommonUiKitSize, number> = {
  small: 36,
  medium: 48,
  large: 56,
};

export const OutlineToggleInlinePadding: Record<CommonUiKitSize, number> = {
  small: 12,
  medium: 16,
  large: 16,
};

export const getOutlineToggleTokens = (size: CommonUiKitSize): IOutlineToggleTokens => {
  return {
    height: OutlineToggleHeight[size],
    paddingInline: OutlineToggleInlinePadding[size],
  };
};
