import React from 'react';

import { Box } from '@mui/material';

import { getPatternStyle } from './style';

interface IThemePatternPreviewProps {
  pattern: string;
  color: string;
}

const ThemePatternPreview: React.FC<IThemePatternPreviewProps> = ({ pattern, color }) => {
  return <Box sx={getPatternStyle(pattern, color)}></Box>;
};

export default ThemePatternPreview;
