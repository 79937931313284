import { SxStyles } from 'types';

export const container: SxStyles = {
  width: 'min-content',
  height: 'min-content',
  margin: 0,
};

export const avatar = (fontSize: number, borderRadius?: string, backdropColor?: string): SxStyles => {
  return {
    zIndex: 1,
    position: 'relative',

    fontSize,
    lineHeight: '20px',
    fontWeight: '800',
    letterSpacing: '10%',

    borderRadius: borderRadius ?? '50%',

    // белая подложка под аватар в случае прозрачного фона картинки
    '&:before': {
      zIndex: -1,
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',

      backgroundColor: backdropColor,
    },
  };
};
