import { SxStyles } from 'types';
import { numberToPixelsString } from 'utils';

import { CALENDAR_CELL_WIDTH } from '../../const';

const cell = numberToPixelsString(CALENDAR_CELL_WIDTH);
const connector = '6px';

export const root: SxStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

export const gridTemplate: SxStyles = {
  display: 'grid',
  // eslint-disable-next-line max-len
  gridTemplateColumns: `${cell} ${connector} ${cell} ${connector} ${cell} ${connector} ${cell} ${connector} ${cell} ${connector} ${cell} ${connector} ${cell}`,
};

export const days: SxStyles = {
  rowGap: '4px',
  marginTop: '8px',
};
