import { FC, PropsWithChildren } from 'react';

import { Box } from '@mui/material';
import { mergeSx } from 'utils';

import { backgroundFullHeightStyles } from '../utils';
import { backgroundColorStyles } from './styles';

interface IColorBackgroundProps {
  color: string;
}

const ColorBackground: FC<IColorBackgroundProps & PropsWithChildren> = ({ color, children }) => {
  return (
    <Box sx={mergeSx(backgroundColorStyles(color), backgroundFullHeightStyles)} className="color-background">
      {children}
    </Box>
  );
};

export default ColorBackground;
