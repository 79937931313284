/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';
import {
  DialogContent,
  IconButton
} from '@mui/material';
import Dialog from '../Dialog';
import { iconClose } from '../../../icons';

import './index.scss';

export interface ICommonDialog {
  isOpen: boolean;
  title: any;
  children?: any;
  actions?: JSX.Element;
  className?: string,
  description?: any;
  unControllable?: boolean;
  isCloseButtonDisabled?: boolean;
  onClose?: () => void;
}

const CommonDialog: React.FC<ICommonDialog> = ({
  actions,
  isOpen,
  title,
  children,
  className,
  description,
  unControllable,
  isCloseButtonDisabled,
  onClose
}) => (
  <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={isOpen} className={className}>
    {!unControllable && (
    <div className="dialog__top-controls">
      <button className="btn-drag-container" type="button">
        <button className="btn-drag-active-area" type="button" onTouchStart={() => onClose?.()} />
      </button>
      <div id="customized-dialog-title" className="dialog__title">
        <h6>{title}</h6>
        <IconButton
          disableTouchRipple
          onClick={onClose}
          disabled={isCloseButtonDisabled}
          className="dialog__close-icon"
          size="large">
          <img src={iconClose} alt="закрыть" />
        </IconButton>
      </div>
      <p className="dialog__description">{description}</p>
    </div>
    )}
    {children && (
    <DialogContent>
      {children}
    </DialogContent>
    )}
    {actions}
  </Dialog>
);

export default CommonDialog;
