import { SectionCodes, SourceCode } from 'const';
import { useUserFunctionality } from 'hooks';
import { isProfessionalEducationEntity } from 'utils';

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formData: any;
}

const dataKindCodesWithEntityId = [
  /* учеба */
  SectionCodes.studyEvent,
  /* наука */
  SectionCodes.scienceReward,
  SectionCodes.project,
  SectionCodes.scienceOccupation,
  SectionCodes.scienceEvent,
  /* спорт */
  SectionCodes.sportReward,
  SectionCodes.sportAffilation,
  SectionCodes.sportOccupation,
  SectionCodes.sportEvent,
  /* творчество */
  SectionCodes.creationReward,
  SectionCodes.creationAffilation,
  SectionCodes.creationEvent,
  SectionCodes.creationOccupation,
  /* культура */
  SectionCodes.cultureEvent,
  /* гражданская активность */
  SectionCodes.civilReward,
  SectionCodes.civilAffilation,
  SectionCodes.civilEvent,
  SectionCodes.civilOccupation,
  /* моя профессия */
  SectionCodes.professionReward,
  // профессиональное обучение проверяется отдельно
  SectionCodes.professionEvent,
];

const useAdminEntityId = ({ formData }: IProps) => {
  const user = useUserFunctionality();

  const recordId = formData?.recordId;
  const id = formData?.id;
  const sourceCode = formData?.source?.code;
  const dataKind = formData?.dataKind;

  const isAdmin = user.isAdmin;
  const isEmployee = user.isOperator || user.isEmployee;

  // 1. Для ролей "Администратор" и "Администратор в режиме просмотра" отображается в сущностях,
  // добавленных автоматически, оператором или администратором
  let adminEntityId: unknown | null;
  const adminRequiredSourceCodes = [
    SourceCode.douEnrollment,
    SourceCode.ezhd,
    SourceCode.olympiad,
    SourceCode.operator,
    SourceCode.employeeOo,
    SourceCode.admin,
    SourceCode.uchiRu,
    SourceCode.proftech,
  ];

  // Если recordId != NULL, то в поле отображается recordId
  if (recordId) {
    adminEntityId = recordId;
  }
  // ИНАЧЕ, если sourceCode ="1, 2, 3, 12, 14, 15, 16", то в поле отображается id
  else if (adminRequiredSourceCodes.includes(sourceCode)) {
    adminEntityId = id;
  }
  // ИНАЧЕ, поле не отображается
  else {
    adminEntityId = null;
  }

  // 2. Для роли "Оператор" отображается только в сущностях, которые добавил сам оператор
  let employeeEntityId: unknown | null;

  // Если sourceCode = "12" ИЛИ "13" , то в поле отображается id
  if (sourceCode === SourceCode.operator || sourceCode === SourceCode.employeeOo) {
    employeeEntityId = id;
  }
  // ИНАЧЕ, поле не отображается
  else {
    employeeEntityId = null;
  }

  const shouldShowEntityId = dataKindCodesWithEntityId.includes(dataKind) || isProfessionalEducationEntity(formData);
  const entityId = isAdmin ? adminEntityId : employeeEntityId;

  if (!isAdmin && !isEmployee) return null;
  if (!shouldShowEntityId) return null;

  return entityId ? String(entityId) : null;
};

export default useAdminEntityId;
