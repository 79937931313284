/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';

import {
  FAILURE,
  REQUEST,
  SUCCESS
} from '../../actions/utils';

import { ICommonResponse, IDictionaryItem } from '../../api/types';

import genericReducers from '../genericReducer';
import { GET_ACTION_KINDS } from '../../actions';

import { IReducers } from '..';

export type ActionKindsState = ICommonResponse<IDictionaryItem>;

const initialState = {} as ICommonResponse<IDictionaryItem>;

const actionKinds: IReducers<ActionKindsState> = {
  [GET_ACTION_KINDS[REQUEST]]: genericReducers().get.request,
  [GET_ACTION_KINDS[SUCCESS]]: genericReducers().get.success,
  [GET_ACTION_KINDS[FAILURE]]: genericReducers().get.failure
};

export default connectReducers(initialState, actionKinds);
