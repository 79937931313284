import { FC } from 'react';

import clsx from 'clsx';

import { Drawer } from '../../../../../ui-kit';
import MobileDragIndicator from '../../../MobileDragIndicator';
import ModalContainer from '../../../ModalContainer';
import { IInfoDialogProps } from '../types';

import './index.scss';

const MobileInfoDialog: FC<IInfoDialogProps> = ({ className, description, icon, onClose, ...props }) => {
  return (
    <ModalContainer>
      <Drawer
        className={clsx('mobile-info-dialog', className)}
        onClose={onClose}
        anchor="bottom"
        isContainerScrollable={false}
        isFullHeight={false}
        {...props}
      >
        <div className="mobile-info-dialog__close-handle">
          <MobileDragIndicator onTouchStart={onClose} />
        </div>
        {icon && <div className="mobile-info-dialog__icon">{icon}</div>}
        <div className="mobile-info-dialog__text-wrapper">
          <p className="mobile-info-dialog__text">{description}</p>
        </div>
      </Drawer>
    </ModalContainer>
  );
};

export default MobileInfoDialog;
