import { FC } from 'react';

import { Box } from '@mui/material';
import { IDictionaryItem } from 'api/types';
import { Pagination } from 'portfolio3/ui-kit';
import { PaginationLimitSelect } from 'portfolio3/ui-kit/selects';
import { SelectController } from 'portfolio3/ui-kit/types';
import { SxStyles } from 'types';
import { mergeSx } from 'utils';

import * as styles from './styles';

interface IPaginationWidgetProps {
  totalElements: number;
  page: number;
  limit: number;
  limitOptions: IDictionaryItem[];
  onChangePage: (value: number) => void;
  onChangeLimit: (value: number) => void;
  disabled?: boolean;
  sx?: SxStyles;
}

const PaginationWidget: FC<IPaginationWidgetProps> = ({
  totalElements,
  page,
  limit,
  limitOptions,
  onChangePage,
  onChangeLimit,
  disabled,
  sx,
}) => {
  const totalPages = Math.ceil(totalElements / limit);

  const limitController: SelectController = {
    handleChange(value) {
      onChangeLimit(Number(value));
    },
  };

  const handleChangePage = (page: number) => onChangePage(page);

  return (
    <Box className="pagination" sx={mergeSx(styles.root, sx)}>
      <PaginationLimitSelect value={limit} options={limitOptions} controller={limitController} disabled={disabled} />
      <Pagination page={page} totalPages={totalPages} onChange={handleChangePage} disabled={disabled} />
    </Box>
  );
};

export default PaginationWidget;
