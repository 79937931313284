import { SxStyles } from 'types';

export const root: SxStyles = (theme) => {
  return {
    display: 'flex',
    gap: '24px',

    [theme.breakpoints.down('commonSm')]: {
      gap: '4px',
    },
  };
};

export const image: SxStyles = (theme) => {
  return {
    width: '70px',
    height: '84px',

    [theme.breakpoints.down('commonSm')]: {
      width: '57px',
      height: '68px',
    },
  };
};
