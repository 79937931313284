import { NeutralColors } from 'portfolio3/styles';
import { getButtonStyles } from 'portfolio3/ui-kit/utils';
import { SxStyles } from 'types';

export const root: SxStyles = (theme) => ({
  padding: '14px 16px',

  backgroundColor: '#ffffff',
  border: '1px solid',
  borderColor: NeutralColors.light_neutrals_300,
  borderRadius: '8px',
  boxShadow: '0px 1px 2px rgba(41, 41, 64, 0.07)',

  [theme.breakpoints.down('commonSm')]: {
    boxShadow: 'none',
  },
});

export const title: SxStyles = {
  display: 'flex',
  alignItems: 'center',
};

export const name: SxStyles = {
  marginLeft: '8px',
};

export const expandButton: SxStyles = {
  ...getButtonStyles(),

  width: '24px',
  height: '24px',
  marginLeft: 'auto',

  '& svg': {
    width: 'inherit',
    height: 'inherit',
    color: NeutralColors.light_neutrals_600,
  },
};
