import { FC } from 'react';

import ServiceErrorPlug from 'portfolio3/components/common/ServiceErrorPlug';
import { GlobalFooterContainer } from 'portfolio3/containers/footers';
import { GlobalHeaderContainer } from 'portfolio3/containers/headers';

const ServiceErrorPage: FC = () => {
  return (
    <>
      <GlobalHeaderContainer isFullWidth />
      <ServiceErrorPlug />
      <GlobalFooterContainer isFullWidth />
    </>
  );
};

export default ServiceErrorPage;
