/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useContext, useMemo } from 'react';

import { ActivitySecondaryBlock } from '../basicBlocks';
import { IDictionaryItem } from '../../../../../api/types';
import { DifferentCode } from '../../../../../const';
import { IEventData } from 'portfolio3/features/dataEntryForm';

import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';
import { BaseInputLabel, FormControl, Input } from 'portfolio3/ui-kit';
import { FormHelperText } from '@mui/material';

interface ICivilActivitySecondaryBlockProps {
  eventLevelOptions: IDictionaryItem[];
  inputLabel: string;
  inputPlaceholder: string;
  organizatorLabel: string;
  organizatorPlaceholder: string;
  eventData: IEventData;
}

const CivilActivitySecondaryBlock: React.FC<ICivilActivitySecondaryBlockProps> = ({
  eventLevelOptions,
  inputLabel,
  inputPlaceholder,
  organizatorLabel,
  organizatorPlaceholder,
  eventData,
}) => {
  const { inputRenderMode, inputSize, formData, formErrors, onChangeFormData, onChangeFormErrors } = useContext(LocalDataEntryFormContext);

  const secondNameController = useMemo(() => ({
    handleChange: (secondName: string) => {
      onChangeFormData((prevstate) => ({
        ...prevstate,
        secondName
      }));
      onChangeFormErrors((prevstate) => ({
        ...prevstate,
        secondNameError: false
      }));
    },
    handleBlur: (secondName: any) => {
      if (!secondName) {
        onChangeFormErrors((prevstate) => ({
          ...prevstate,
          secondNameError: true
        }));
      }
    }
  }), [formData.id]);

  const additionalTopControllers = (
    <>
      {Number(formData.name) === DifferentCode && (
        <FormControl
          sx={{ width: '100%' }}
          required
          error={formErrors.secondNameError}
          renderMode={inputRenderMode}
          inputSize={inputSize}
          label={<BaseInputLabel>{inputLabel}</BaseInputLabel>}
          helper={formErrors.secondNameError && <FormHelperText>Введите название мероприятия</FormHelperText>}
          control={
            <Input
              renderMode={inputRenderMode}
              size={inputSize}
              placeholder={inputPlaceholder}
              value={formData.secondName}
              controller={secondNameController}
            />
          }
        />
      )}
    </>
  );

  return (
    <ActivitySecondaryBlock
      eventLevelOptions={eventLevelOptions}
      additionalTopControllers={additionalTopControllers}
      eventData={eventData}
      organizatorLabel={organizatorLabel}
      organizatorPlaceholder={organizatorPlaceholder}
    />
  );
};

export default CivilActivitySecondaryBlock;
