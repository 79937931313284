import { FC, ReactNode } from 'react';

import { ThemeProvider } from '@mui/material';
import { SectionKeys, SectionNames } from 'const';
import { useSectionTheme } from 'hooks';
import { IconSectionCivil } from 'icons';
import { civilTheme } from 'portfolio3/styles/theme';

import SearchSection from '../components/SearchSection';
import { useSearchCivil } from '../hooks';
import CommonEntityCardList from './CardLists/CommonEntityCardList';
import CommonRewardCardList from './CardLists/CommonRewardCardList';

const CivilSection: FC = () => {
  const {
    rewards,
    clubs,
    contests,
    occupations,
    isCivilRewardVisible,
    isCivilClubVisible,
    isCivilContestVisible,
    isCivilOccupationVisible,
    isCivilVisible,
  } = useSearchCivil();

  const theme = useSectionTheme(civilTheme);

  if (!isCivilVisible) return null;

  const subsections: ReactNode[] = [];

  if (isCivilRewardVisible) {
    subsections.push(
      <SearchSection.Subsection title="Награды и статусы">
        <CommonRewardCardList rewards={rewards} />
      </SearchSection.Subsection>,
    );
  }

  if (isCivilClubVisible) {
    subsections.push(
      <SearchSection.Subsection title="Клубы">
        <CommonEntityCardList entities={clubs} />
      </SearchSection.Subsection>,
    );
  }

  if (isCivilContestVisible) {
    subsections.push(
      <SearchSection.Subsection title="Конкурсы, соревнования">
        <CommonEntityCardList entities={contests} />
      </SearchSection.Subsection>,
    );
  }

  if (isCivilOccupationVisible) {
    subsections.push(
      <SearchSection.Subsection title="Кружки">
        <CommonEntityCardList entities={occupations} />
      </SearchSection.Subsection>,
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <SearchSection name={SectionNames[SectionKeys.CIVIL]} icon={<IconSectionCivil />} subsections={subsections} />
    </ThemeProvider>
  );
};

export default CivilSection;
