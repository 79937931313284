/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { Box } from '@mui/material';
import { format } from 'date-fns';

import { connect } from 'react-redux';
import {
  iconLinkedItem,
  IconEditOutline,
  IconInfoOutline,
  IconTrashAltOutline
} from '../../../icons';

import { IModifiedPersonObject } from 'portfolio3/features/dataEntryForm';
import {
  getDataItemType,
  getDeleteDialogEntityTitle,
  getDeleteDialogEntityType,
} from '../../../utils';
import { ICardData } from '../../../types';
import { IDeleteDialogData } from '../PortfolioData/types';
import { IRootState } from '../../../reducers';
import { ICommonResponse, IPersonObject } from '../../../api/types';
import { EntityType } from '../../../const';

import { IEntityAction, useAdminEntityId } from 'portfolio3/components/entityActions';
import { BaseBadge, NeutralBadge } from 'portfolio3/ui-kit/badges';
import WithActionsPopup from 'portfolio3/components/common/WithActionsPopup';
import ActionsButton from 'portfolio3/components/common/buttons/ActionsButton';
import { NeutralColors } from 'portfolio3/styles';

import './index.scss';
import * as styles from './styles';

interface IPortfolioDataItemProps {
  personsObjects: ICommonResponse<IPersonObject>;
  personObject: IModifiedPersonObject;
  canUserEdit: boolean;
  isMenuActionsDisabled: boolean;
  onEditEntryFormData: (entryFormData: IModifiedPersonObject) => void;
  onOpenDataCard: (cardData: ICardData, entityType: EntityType) => void;
  onDeletePortfolioObject: React.Dispatch<React.SetStateAction<IDeleteDialogData | undefined>>
}

const PortfolioDataItem: React.FC<IPortfolioDataItemProps> = ({
  personsObjects,
  personObject,
  canUserEdit,
  isMenuActionsDisabled,
  onEditEntryFormData,
  onOpenDataCard,
  onDeletePortfolioObject
}) => {
  const {
    name,
    creationDate,
    dataItemAttributes,
    dataItemType,
    linkedPersonObjects,
    dataKind,
    typeCode,
    type,
    cardData,
  } = personObject;

  const adminEntityId = useAdminEntityId({ formData: personObject });

  const entityType = getDataItemType(dataKind);
  const isEntityAttachable = entityType === EntityType.EVENT || entityType === EntityType.PROJECT;

  const hasLinkedItem = linkedPersonObjects && linkedPersonObjects?.length > 0;

  const handleOpenMoreEntityData = () => {
    if (entityType) {
      onOpenDataCard(cardData, entityType);
    }
  };

  const handleDeletePortfolioObject = () => {
    if (!entityType) return;

    const linkedEntities = isEntityAttachable ? personsObjects.content?.filter((object) => object.entityId == personObject.id) : undefined;
    onDeletePortfolioObject({
      type: entityType,
      id: personObject.id,
      open: true,
      name: personObject.name,
      typeName: getDeleteDialogEntityType(typeCode || type?.code || dataKind),
      titleTypeName: getDeleteDialogEntityTitle(typeCode || type?.code || dataKind),
      linkedEntityNames: linkedEntities?.map((entity) => entity.name).join(', '),
      linkedEntityTypeName: linkedEntities && linkedEntities?.length > 1 ? 'награды' : 'награду'
    });
  };

  const menuActions: IEntityAction[] = [];

  if (canUserEdit) {
    menuActions.push({
      text: 'Редактировать',
      icon: IconEditOutline,
      isDisabled: isMenuActionsDisabled,
      effect: () => onEditEntryFormData(personObject),
    });
    menuActions.push({
      text: 'Удалить',
      icon: IconTrashAltOutline,
      isDisabled: isMenuActionsDisabled,
      effect: handleDeletePortfolioObject,
    });
  } else {
    menuActions.push({
      text: 'Подробнее',
      icon: IconInfoOutline,
      isDisabled: isMenuActionsDisabled,
      effect: handleOpenMoreEntityData,
    });
  }

  return (
    <div className="portfolio-item">
      <div className={hasLinkedItem ? 'portfolio-item__container portfolio-item__container--has-linked' : 'portfolio-item__container'}>
        <div className="portfolio-item__top-container">
          <BaseBadge
            textColor={dataItemAttributes?.color ?? NeutralColors.light_neutrals_900}
            backgroundColor={dataItemAttributes?.background ?? '#ffffff'}
          >
            {dataItemAttributes?.icon}
            {dataItemAttributes?.name}
          </BaseBadge>
          {dataItemType && <NeutralBadge>{dataItemType}</NeutralBadge>}

          <Box className="menu-button-container" sx={styles.menuButtonContainer}>
            <WithActionsPopup actions={menuActions}>
              {(ref, onClick) => {
                return <ActionsButton className="menu-button" ref={ref} onClick={onClick} sx={styles.menuButton} />;
              }}
            </WithActionsPopup>
          </Box>
        </div>
        <div className="portfolio-item__content">
          <h5 className="portfolio-item__name">{name}</h5>
          {adminEntityId && <Box sx={{marginTop: '8px'}}>ID: {adminEntityId}</Box>}
          <p className="portfolio-item__date">
            Добавлено:
            {' '}
            {format(new Date(creationDate), 'dd.MM.yyyy')} в {format(new Date(creationDate), 'HH:MM')}
          </p>
        </div>
      </div>
      {linkedPersonObjects?.map((object) => (
        <div className="portfolio-item__linked-item linked-item" key={object.id}>
          <img className="linked-item__image" src={iconLinkedItem} alt="связанная сущность" />
          <div className="linked-item__content">
            <p className="linked-item__type">{object.dataItemType}</p>
            <p className="linked-item__name">{object.name}</p>
          </div>
        </div>
      )) }
    </div>
  );
};

export default connect(
  (state: IRootState) => ({
    personsObjects: state.personsObjects
  })
)(PortfolioDataItem);
