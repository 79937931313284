/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconStar: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M9.93122 16.0746L14.5005 18.8432C14.6858 18.9542 14.8996 19.0084 15.1154 18.999C15.3311 18.9895 15.5394 18.917 15.7143 18.7902C15.8892 18.6635 16.023 18.4882 16.0992 18.286C16.1753 18.0839 16.1904 17.8639 16.1426 17.6533L14.9289 12.4573L18.9746 8.95097C19.137 8.80881 19.254 8.62213 19.3113 8.41408C19.3685 8.20603 19.3635 7.98574 19.2968 7.78052C19.2301 7.57531 19.1046 7.39418 18.9359 7.25958C18.7673 7.12498 18.5628 7.04282 18.3479 7.0233L13.025 6.57113L10.9466 1.66072C10.8595 1.46411 10.7172 1.29701 10.5369 1.17972C10.3567 1.06243 10.1463 1 9.93122 1C9.71617 1 9.50574 1.06243 9.32549 1.17972C9.14525 1.29701 9.00293 1.46411 8.91582 1.66072L6.83742 6.5632L1.51451 7.01537C1.29764 7.0313 1.09043 7.11146 0.919297 7.24563C0.748165 7.3798 0.620867 7.56189 0.553636 7.76869C0.486404 7.97549 0.482285 8.19763 0.541803 8.40678C0.601321 8.61593 0.721779 8.80262 0.887819 8.94304L4.93355 12.4414L3.71983 17.6374C3.66798 17.8488 3.68033 18.0708 3.75529 18.2752C3.83026 18.4795 3.96444 18.6569 4.14069 18.7846C4.31694 18.9123 4.52726 18.9846 4.74479 18.9922C4.96232 18.9998 5.17718 18.9424 5.36192 18.8273L9.93122 16.0746Z"
      fill={props.fill || "#51CF66"}/>
  </SvgIcon>
);

export default IconStar;
