/* eslint-disable max-len */
import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconCreationUnitsCommon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.398 1.1v21.8h16.6V1.1h-16.6Zm14.1 16.7-3.6-6.2-2.5 5.1-2-2.2-3.4 4.5V3.6h11.6v14.2h-.1Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M13.9 9.7c1.2 0 2.2-1 2.2-2.2 0-1.2-1-2.2-2.2-2.2-1.2 0-2.2 1-2.2 2.2 0 1.2 1 2.2 2.2 2.2ZM2.599 1.1c0-.1-.1-.1-.1 0l-2 3.2c-.8 1.3-.3 2.9 1 3.6v13.9c0 .6.5 1.2 1.2 1.2.6 0 1.2-.5 1.2-1.2V7.9c1.2-.6 1.8-2.3 1-3.6l-2.3-3.2Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconCreationUnitsCommon;
