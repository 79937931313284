import { SxStyles } from 'types';

export const drawerRoot: SxStyles = {
  backgroundColor: '#F4F3F8',
};

export const drawerContent: SxStyles = {
  overflow: 'hidden',
};

export const drawerInnerContent: SxStyles = {
  height: '100%',
  overflow: 'auto',
};

export const root: SxStyles = (theme) => ({
  position: 'relative',
  height: '100%',

  [theme.breakpoints.down('commonSm')]: {
    height: 'auto',
  },
});

export const mobileTabs: SxStyles = {
  zIndex: 10,
  position: 'sticky',
  top: 0,
};

export const widgetsContainer = (isFileTab: boolean): SxStyles => {
  return (theme) => {
    return {
      display: isFileTab ? 'block' : 'grid',
      gridAutoFlow: 'row',
      gridTemplateRows: 'auto 1fr auto',
      // убирает горизонтальный оверфлоу при display: grid
      gridTemplateColumns: '100%',

      height: '100%',
      overflow: isFileTab ? 'auto' : 'hidden',

      '& > * ~ *': {
        marginTop: '12px',
      },

      [theme.breakpoints.down('commonSm')]: {
        height: 'auto',
        overflow: 'auto',
        marginTop: '12px',
      },
    };
  };
};

export const settingsWidget = (isFileTab: boolean): SxStyles => {
  return {
    height: isFileTab ? '100%' : 'auto',
  };
};

export const scrollableSettings: SxStyles = {
  overflow: 'scroll',
};
