import { Dispatch, SetStateAction } from 'react';

import { Box } from '@mui/material';
import { Pagination } from 'portfolio3/ui-kit';
import { PaginationLimitSelect } from 'portfolio3/ui-kit/selects';
import { SelectController } from 'portfolio3/ui-kit/types';
import { SxStyles } from 'types';
import { getPaginationLimitOptions } from 'utils';

const paginationContainer: SxStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '12px',
};

const limitOptions = getPaginationLimitOptions([10, 25, 50]);

interface ILocalCurrentFilters {
  page: number;
  limit: number;
}

interface ITableFooterPaginationProps<T extends ILocalCurrentFilters> {
  currentFilters: T;
  setCurrentFilters: Dispatch<SetStateAction<T>>;
  totalPages: number;
  disabled?: boolean;

  renderPageModification?: (page: number) => number;
  setPageModification?: (page: number) => number;
}

const defaultPageModification = (page: number) => page;

const TableFooterPagination = <T extends ILocalCurrentFilters>({
  currentFilters,
  setCurrentFilters,
  totalPages,
  disabled,
  renderPageModification = defaultPageModification,
  setPageModification = defaultPageModification,
}: ITableFooterPaginationProps<T>) => {
  const handleChangePage = (page: number) => {
    setCurrentFilters((prevState) => {
      return {
        ...prevState,
        page: setPageModification(page),
      };
    });
  };

  const limitController: SelectController = {
    handleChange(limit) {
      setCurrentFilters((prevState) => {
        return {
          ...prevState,
          limit: Number(limit),
          page: setPageModification(1),
        };
      });
    },
  };

  return (
    <Box className="table-footer-pagination" sx={paginationContainer}>
      <PaginationLimitSelect
        value={currentFilters.limit}
        options={limitOptions}
        controller={limitController}
        disabled={disabled}
      />
      <Pagination
        page={renderPageModification(currentFilters.page)}
        totalPages={totalPages}
        onChange={handleChangePage}
        disabled={disabled}
      />
    </Box>
  );
};

export default TableFooterPagination;
