import { FC } from 'react';
import { ToastContainer, ToastContainerProps } from 'react-toastify';

import { Box } from '@mui/material';

import { ToastTypeKeys } from '../types';
import EmployeeCloseButton from './CloseButton';
import * as styles from './styles';

import 'react-toastify/dist/ReactToastify.css';

const EmployeeToastContainer: FC<ToastContainerProps> = (props) => {
  return (
    <Box className="employee-toast-container" sx={styles.root}>
      <ToastContainer
        enableMultiContainer
        containerId={ToastTypeKeys.EMPLOYEE}
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        closeButton={EmployeeCloseButton}
        {...props}
      />
    </Box>
  );
};

export default EmployeeToastContainer;
