import { FC, useEffect, useRef, useState } from 'react';

import { Box } from '@mui/material';
import { mergeSx } from 'utils';

import * as styles from './styles';

interface ILazyProgressBarProps {
  isLoading: boolean;
}

const LazyProgressBar: FC<ILazyProgressBarProps> = ({ isLoading }) => {
  const hasMounted = useRef(false);
  const [isExiting, setIsExiting] = useState(false);
  const [isExtendedLoading, setExtendedLoading] = useState(false);

  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
      return;
    }

    let timeout: NodeJS.Timeout;
    let disabledExtendedLoadingTimeout: NodeJS.Timeout;

    if (!isLoading) {
      setIsExiting(true);
      setExtendedLoading(true);

      // откладываем состояние завершения загрузки
      // чтобы успела отобразиться анимация
      timeout = setTimeout(() => {
        setIsExiting(false);
      }, 350);

      // первый прогресс бар нужно скрыть раньше завершения анимации второго
      // иначе имеется визуальная проблема
      disabledExtendedLoadingTimeout = setTimeout(() => {
        setExtendedLoading(false);
      }, 300);
    }

    return () => {
      clearTimeout(timeout);
      clearTimeout(disabledExtendedLoadingTimeout);
    };
  }, [isLoading]);

  return (
    <Box className="lazy-progress-bar" sx={styles.root}>
      <Box sx={mergeSx(styles.loader, (isLoading || isExtendedLoading) && styles.testAnimation)} />
      <Box sx={mergeSx(styles.loader, isExiting && styles.exitingAnimation)} />
    </Box>
  );
};

export default LazyProgressBar;
