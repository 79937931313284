/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconMoreHorizontal: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M3 12a2 2 0 1 1 4.001.001A2 2 0 0 1 3 12Zm9-2a2 2 0 1 0 .001 4.001A2 2 0 0 0 12 10Zm7 0a2 2 0 1 0 .001 4.001A2 2 0 0 0 19 10Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconMoreHorizontal;
