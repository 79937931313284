import { FC, useRef, useState } from 'react';

import { Button, Menu, Stack, Typography } from '@mui/material';
import { IconChevronSmallDown, IconChevronSmallUp } from 'icons';
import { NeutralColors } from 'portfolio3/styles';
import Avatar from 'portfolio3/ui-kit/Avatar';

import StudentItem from './StudentItem';
import { styles } from './styles';
import { IStudentPickerItem } from './types';

interface IStudentPickerProps {
  title: string;
  comment: string;
  selectedStudentMeshId: string;
  students: IStudentPickerItem[];
  onChange: (meshId: string) => void;
}

const StudentPicker: FC<IStudentPickerProps> = ({ title, comment, selectedStudentMeshId, students, onChange }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setOpen] = useState(false);
  const ArrowIcon = isOpen ? IconChevronSmallUp : IconChevronSmallDown;

  const selectedStudent = students.find((student) => student.meshId === selectedStudentMeshId);

  const handleOpenMenu = () => {
    setOpen(true);
  };

  const handleCloseMenu = () => {
    setOpen(false);
  };

  const handleSelectStudent = (meshId: string) => {
    return () => {
      onChange(meshId);
      handleCloseMenu();
    };
  };

  return (
    <>
      <Button
        sx={styles.button}
        onClick={handleOpenMenu}
        ref={buttonRef}
        id="picker-button"
        aria-haspopup="true"
        aria-controls={isOpen ? 'picker-menu' : undefined}
        aria-expanded={isOpen ? 'true' : undefined}
      >
        <Stack direction="row" alignItems="center" gap="8px">
          <Avatar
            image={selectedStudent?.avatar}
            firstName={selectedStudent?.firstName || ''}
            lastName={selectedStudent?.lastName || ''}
            size={32}
          />
          <Stack alignItems="start" textAlign="start">
            <Typography variant="Paragraph MD/Medium" color={NeutralColors.light_neutrals_900}>
              {title}
            </Typography>
            <Typography variant="Paragraph SM/Medium" color={NeutralColors.light_neutrals_600}>
              {comment}
            </Typography>
          </Stack>
        </Stack>
        <ArrowIcon fill={NeutralColors.light_neutrals_600} />
      </Button>
      <Menu
        id="picker-menu"
        sx={styles.menu(buttonRef.current?.clientWidth || 0)}
        anchorEl={buttonRef.current}
        open={isOpen}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'picker-button',
        }}
      >
        {students.map((student) => {
          const { meshId } = student;
          return (
            <StudentItem
              key={meshId}
              student={student}
              onSelect={handleSelectStudent(meshId)}
              selected={meshId === selectedStudent?.meshId}
            />
          );
        })}
      </Menu>
    </>
  );
};

export default StudentPicker;
