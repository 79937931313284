import { FC } from 'react';

import { IconEditOutline } from 'icons';
import { Button } from 'portfolio3/ui-kit/button';

interface IWidgetContainerEditButtonProps {
  isMobile: boolean;
  onClick: () => void;
}

export const WidgetContainerEditButton: FC<IWidgetContainerEditButtonProps> = ({ isMobile, onClick }) => {
  if (isMobile) {
    return (
      <Button size="small" onlyIcon onClick={onClick}>
        <IconEditOutline />
      </Button>
    );
  }

  return (
    <Button size="medium" iconLeft={<IconEditOutline />} onClick={onClick}>
      Редактировать
    </Button>
  );
};

export default WidgetContainerEditButton;
