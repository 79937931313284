/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';

import {
  FAILURE,
  REQUEST,
  SUCCESS
} from '../../actions/utils';

import genericReducers from '../genericReducer';

import { CLEAR_ATTACHMENT_TEMPLATE, GET_ATTACHMENT_TEMPLATE_INFO, GET_ATTACHMENT_TEMPLATE_INFO_PARTITION, POST_ATTACHMENT_TEMPLATE } from '../../actions';
import { IReducers } from '..';
import { IAttachmentTemplate, IPutTemplateAttachmentResponse, IRecord } from '../../api/types';

const hasIncorrectRecords = (value: IPutTemplateAttachmentResponse | {}): value is IPutTemplateAttachmentResponse => {
  return (value as IPutTemplateAttachmentResponse)?.incorrectRecords !== undefined;
};

export type AttachmentTemplateState = IAttachmentTemplate;

const initialState = {
  content: {},
  loading: false,
  partitionLoading: false
} as IAttachmentTemplate;

type LoadPartitionAction = {
  payload: {
    data: IRecord[]
  }
}

const attachmentTemplate: IReducers<AttachmentTemplateState> = {
  [GET_ATTACHMENT_TEMPLATE_INFO[REQUEST]]: genericReducers().get.request,
  [GET_ATTACHMENT_TEMPLATE_INFO[SUCCESS]]: genericReducers().get.success,
  [GET_ATTACHMENT_TEMPLATE_INFO[FAILURE]]: genericReducers().get.failure,
  [POST_ATTACHMENT_TEMPLATE[REQUEST]]: (state) => ({
    ...state,
    loading: true,
    status: 'REQUEST'
  }),
  [POST_ATTACHMENT_TEMPLATE[SUCCESS]]: (state, { payload }) => ({
    ...state,
    content: {
      ...state.content,
      cacheUUID: payload.data
    },
    loading: false,
    status: 'SUCCESS'
  }),
  [POST_ATTACHMENT_TEMPLATE[FAILURE]]: () => ({
    content: {},
    loading: false,
    status: 'FAIL'
  }),
  [GET_ATTACHMENT_TEMPLATE_INFO_PARTITION[REQUEST]]: (state) => ({
    ...state,
    partitionLoading: true
  }),
  [GET_ATTACHMENT_TEMPLATE_INFO_PARTITION[FAILURE]]: (state) => ({
    ...state,
    partitionLoading: false
  }),
  [GET_ATTACHMENT_TEMPLATE_INFO_PARTITION[SUCCESS]]: (state, { payload }: LoadPartitionAction) => {
    const currentIncorrectRecords = hasIncorrectRecords(state.content) ? state.content.incorrectRecords?.records || [] : [];

    return {
      ...state,
      content: {
        ...state.content,
        incorrectRecords: {
          records: [
            ...currentIncorrectRecords,
            ...payload.data
          ]
        }
      },
      partitionLoading: false
    };
  },
  [CLEAR_ATTACHMENT_TEMPLATE]: () => ({
    content: {},
    loading: false,
    status: 'CLEARED'
  })
};

export default connectReducers(initialState, attachmentTemplate);
