/* eslint-disable prettier/prettier */
/* eslint-disable */

import { useBuildConfig } from 'hooks';
import React from 'react';

import { connect } from 'react-redux';

import { IChildInfo } from '../../../api/types';
import { IRootState } from '../../../reducers';
import { placeholderCharacter } from 'images';
import NoDataPlug from 'portfolio3/components/common/NoDataPlug';

import './index.scss';

interface IDiagnosticPlugProps {
  currentStudent: IChildInfo,
  loading?: boolean
}

const DiagnosticPlug: React.FC<IDiagnosticPlugProps> = ({
  loading,
  currentStudent
}) => {
  const config = useBuildConfig();

  const getLinkWithAdornment = () => {
    const globalPath = config?.AUTH_PAGE_URL;
    let adornment = '';
    if (globalPath?.includes('-dev')) adornment = '-dev';
    if (globalPath?.includes('-test')) adornment = '-test';
    const link = `https://uchebnik${adornment}.mos.ru/self_test?external_class_levels=${currentStudent.classLevel}`;

    return link;
  };

  const handleRedirectToLibrary = () => {
    window.open(getLinkWithAdornment(), '_blank');
  };

  return (
    <NoDataPlug
      image={placeholderCharacter}
      title="Пройденных самодиагностик пока нет"
      loading={loading}
    >
      {/* <Button
        className="redirect-button redirect-button--type-study"
        size="large"
        type="button"
        onClick={handleRedirectToLibrary}
        endIcon={<IconArrowOpen className="redirect-button__icon" />}
      >
        Пройти самодиагностику
      </Button> */}
    </NoDataPlug>
  );
};

export default connect(
  (state: IRootState) => ({
    currentStudent: state.currentStudent
  })
)(DiagnosticPlug);
