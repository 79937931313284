import { FC } from 'react';

import { ClearInputButton } from 'portfolio3/ui-kit/inputButtons';
import { SxStyles } from 'types';

const styles: SxStyles = {
  width: '28px',
  height: '28px',

  svg: {
    width: '28px',
    height: '28px',
  },
};

interface ISelectClearButtonProps {
  isDisabled: boolean;
  onClear: () => void;
}

const SelectClearButton: FC<ISelectClearButtonProps> = ({ isDisabled, onClear }) => {
  return (
    <ClearInputButton
      disabled={isDisabled}
      sx={styles}
      onClick={(e) => {
        e.stopPropagation();
      }}
      ariaOptions={{
        onPress: (event) => {
          event.continuePropagation();
          onClear();
        },
      }}
    />
  );
};

export default SelectClearButton;
