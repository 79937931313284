import { FC } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { spoPlaceholders } from 'images';
import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

const imagStyles: SxStyles = (theme) => {
  return {
    width: '287px',
    height: '100%',

    [theme.breakpoints.down('commonSm')]: {
      width: '200px',
    },
  };
};

interface IZeroSubjectsSpoProps {
  description: string;
}

const ZeroSubjectsSpo: FC<IZeroSubjectsSpoProps> = ({ description }) => {
  return (
    <Stack gap="12px" alignItems="center" paddingBottom="16px">
      <Box component="img" src={spoPlaceholders.laboratoryAssistant} alt="Нет данных" sx={imagStyles} />
      <Stack gap="4px" alignItems="center">
        <Typography variant="Headings/H6" textAlign="center">
          Ничего не найдено
        </Typography>
        <Typography variant="Paragraph LG/Regular" color={NeutralColors.light_neutrals_600} textAlign="center">
          {description}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ZeroSubjectsSpo;
