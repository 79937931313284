import { FC, PropsWithChildren } from 'react';

import { Box, useMediaQuery } from '@mui/material';
import { snowBackgroundOverlay, snowBackgroundPattern } from 'images';
import { commonTheme } from 'portfolio3/styles/theme';
import { SxStyles } from 'types';
import { mergeSx } from 'utils';

import { backgroundFullHeightStyles } from '../utils';

const overlay: SxStyles = {
  zIndex: -1,
  position: 'fixed',
  width: '100vw',
  height: '100vh',
};

const overlayDesktop: SxStyles = {
  backgroundImage: `url(${snowBackgroundPattern}), url(${snowBackgroundOverlay})`,
  backgroundRepeat: 'repeat, no-repeat',
  backgroundSize: 'auto, cover',
};

const overlayMobile: SxStyles = {
  backgroundImage: `url(${snowBackgroundOverlay})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
};

const SnowBackground: FC<PropsWithChildren> = ({ children }) => {
  const isMobileView = useMediaQuery(commonTheme.breakpoints.down('commonLg'));

  return (
    <Box className="snow-background" sx={backgroundFullHeightStyles}>
      <Box className="snow-background__overlay" sx={mergeSx(overlay, isMobileView ? overlayMobile : overlayDesktop)} />
      {children}
    </Box>
  );
};

export default SnowBackground;
