/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import DiagramDoughnut from '../../common/DiagramDoughnut';
import DoughnutDiagnosticChart from '../../common/doughnutDiagnosticChart';

import { IDiagnosis } from '../../../api/types';

import './index.scss';

interface DiagnosticDoughnutStatsProps {
  studentDiagnosis: IDiagnosis;
  bestSubject?: string;
}

const DiagnocticDoughnutStats: React.FC<DiagnosticDoughnutStatsProps> = ({ studentDiagnosis, bestSubject }) => {
  const markColors = ['#EDEDED', '#C6C6C6', '#A1A1A1', '#585858'];
  const markData = {
    incomingData: studentDiagnosis?.groupMark ? Object.values(studentDiagnosis?.groupMark) : [],
    incomingLabels: ['от 0 до 25%', 'от 26% до 50%', 'от 51% до 75%', 'от 76% до 100%']
  };

  let valueProgressBar = 0;
  const normalizedMarkValue = studentDiagnosis?.averageResult || 0;
  const normalizedMarkBasis = 100;
  const mark = normalizedMarkValue;

  if (normalizedMarkValue) {
    valueProgressBar = normalizedMarkBasis ? Number(normalizedMarkValue) * 100 / normalizedMarkBasis : Number(normalizedMarkValue);
  }

  const doughnutData = {
    incomingData: [valueProgressBar, 100 - valueProgressBar],
    incomingLabels: ['1', '2']
  };

  return (
    <div className="pdf-doughnut-stats">
      <div className="pdf-doughnut-stats__completed completed">
        <h3 className="completed__title">Пройденные диагностики</h3>
        <div className="completed__body">
          <div className="completed__chart">
            <DoughnutDiagnosticChart colors={markColors} data={markData} />
          </div>
          <div className="completed__info">
            <div className="completed__info-item">
              <div className="completed__color-tip" style={{ backgroundColor: markColors[0] }} />
              <span className="completed__rank">попробуй еще (от 0 до 25%)</span>
            </div>
            <div className="completed__info-item">
              <div className="completed__color-tip" style={{ backgroundColor: markColors[1] }} />
              <span className="completed__rank">неплохо (от 26% до 50%)</span>
            </div>
            <div className="completed__info-item">
              <div className="completed__color-tip" style={{ backgroundColor: markColors[2] }} />
              <span className="completed__rank">хорошо (от 51% до 75%)</span>
            </div>
            <div className="completed__info-item">
              <div className="completed__color-tip" style={{ backgroundColor: markColors[3] }} />
              <span className="completed__rank">отлично (от 76% до 100%)</span>
            </div>
          </div>
        </div>
      </div>
      <div className="pdf-doughnut-stats__aside">
        <div className="pdf-doughnut-stats__best-percent best-percent">
          <span className="best-percent__title">Процент правильных ответов</span>
          <span className="best-percent__result">
            {normalizedMarkValue}%
          </span>
          <div className="best-percent__chart">
          <DiagramDoughnut total={mark !== null ? mark : '-'} incomingData={doughnutData.incomingData} incomingLabels={doughnutData.incomingLabels} color="#B9B9B9" />
          </div>
        </div>
        <div className="pdf-doughnut-stats__best-subject best-subject">
          <span className="best-subject__title">Лучший результат по предмету</span>
          <span className="best-subject__result">{bestSubject}</span>
        </div>
      </div>
    </div>
  );
};

export default DiagnocticDoughnutStats;
