import { getAccentColor } from 'portfolio3/styles';
import { SxStyles } from 'types';
import { inheritSx } from 'utils';

import { barCircleBase } from '../style';

export const barAvatar: SxStyles = (theme) => ({
  ...inheritSx([barCircleBase]),
  boxShadow: '-1px 1px 2px rgba(0, 0, 0, 0.1)',

  zIndex: 4,

  '&.active': {
    border: `1px solid ${getAccentColor(theme.palette.accentColor, '64')}`,
  },
});

export const BarAvatarIcon: SxStyles = () => ({
  width: 32,
  height: 32,
});
