import { FC, Fragment } from 'react';
import { useSelector } from 'react-redux';

import { routes } from 'const';
import { useUserFunctionality } from 'hooks';
import FixedWidthContainer from 'portfolio3/components/common/FixedWidthContainer';
import { EmployeeServiceHeader } from 'portfolio3/components/common/headers';
import PaddingContainer from 'portfolio3/components/common/PaddingContainer';
import { NeutralColors } from 'portfolio3/styles';
import { IRootState } from 'reducers';
import { currentLocalRoleSelector } from 'selectors';
import { IPathWithName } from 'types';

import { IHeaderContainerCommonProps } from '../types';

const employeeRoutes: IPathWithName[] = [
  { ...routes.PORTFOLIO_EMPLOYEE, title: 'Ввод данных' },
  { ...routes.EMPLOYEE_HISTORY, title: 'История изменений' },
];

const adminRoutes: IPathWithName[] = [
  { ...routes.PORTFOLIO_ADMIN, title: 'Поиск портфолио' },
  { ...routes.ADMIN_SETTINGS, title: 'Настройки отображения' },
  { ...routes.ADMIN_ANALYTICS, title: 'Аналитика' },
  { ...routes.ADMIN_DELETE_HISTORY, title: 'История удаления' },
];

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IEmployeeHeaderProps extends IHeaderContainerCommonProps {}

const EmployeeHeaderContainer: FC<IEmployeeHeaderProps> = ({ isFullWidth }) => {
  const currentLocalRole = useSelector(currentLocalRoleSelector);
  const isAdminViewing = useSelector((state: IRootState) => state.adminViewMode.isViewing);
  const { isAdmin, isOperator, isEmployee } = useUserFunctionality();

  const Container = isFullWidth ? Fragment : FixedWidthContainer;

  let headerRoutes: IPathWithName[] = [];
  if (isAdmin) headerRoutes = adminRoutes;
  if (isOperator || isEmployee) headerRoutes = employeeRoutes;

  if (isAdminViewing) return null;

  const roleAlignedRoutes = headerRoutes.filter((route) => {
    return route.permissions.some((permission) => permission === currentLocalRole?.title);
  });

  return (
    <Container>
      <PaddingContainer
        backgroundColor={NeutralColors.light_neutrals_0}
        sx={{ borderBottom: '1px solid', borderColor: NeutralColors.light_neutrals_300 }}
      >
        <EmployeeServiceHeader routes={roleAlignedRoutes} />
      </PaddingContainer>
    </Container>
  );
};

export default EmployeeHeaderContainer;
