import { FAILURE, INVALIDATE, REQUEST, SUCCESS } from 'actions/utils';
import {
  ICommonResponse,
  ICommonResponseObject,
  IDiagnosis,
  IGiaItem,
  IIndependentSelfDiagnostic,
  IInterestResponse,
  IJobsResponse,
  IProfessionalAdditionalEducationResponse,
  IStudentAffilation,
  IStudentCheckIn,
  IStudentCultureVisiting,
  IStudentEmployment,
  IStudentEvent,
  IStudentProject,
  IStudentReward,
  IStudentSportReward,
  MetaSkill,
  SpoDocument,
  SpoEducation,
  StudentGiaWorldSkills,
} from 'api/types';
import { IReducers } from 'reducers';
import genericReducers from 'reducers/genericReducer';
import { connectReducers } from 'utils';

import {
  SEARCH_AFFILIATIONS,
  SEARCH_CHECKIN_HISTORY,
  SEARCH_CULTURE_VISITINGS,
  SEARCH_DIAGNOSTICS,
  SEARCH_DOCUMENTS,
  SEARCH_EMPLOYMENTS,
  SEARCH_EVENTS,
  SEARCH_GIA,
  SEARCH_GIA_WORLDSKILLS,
  SEARCH_INDEPENDENT_DIAGNOSTICS,
  SEARCH_INTERESTS,
  SEARCH_JOBS,
  SEARCH_METASKILLS,
  SEARCH_PANEL_SET_OPEN,
  SEARCH_PANEL_SET_QUERY,
  SEARCH_PROFESSIONAL_ADDITIONAL_EDUCATION,
  SEARCH_PROJECTS,
  SEARCH_REWARDS,
  SEARCH_SPO_EDUCATION,
  SEARCH_SPORT_REWARDS,
} from './actions';

function createReducer<DataType>(actions: Record<string, string>, initialContent: DataType) {
  const initialState: ICommonResponseObject<DataType> = {
    content: initialContent,
    loading: false,
    requestCache: 'notCached',
  };

  const reducer: IReducers<ICommonResponseObject<DataType>> = {
    [actions[REQUEST]]: genericReducers().get.request,
    [actions[SUCCESS]]: genericReducers().get.success,
    [actions[FAILURE]]: genericReducers().get.failure,
    [actions[INVALIDATE]]: genericReducers().get.invalidate(initialState),
  };

  return connectReducers(initialState, reducer);
}

export type SearchPanelState = {
  isOpen: boolean;
  query: string;
};
const searchPanelInitialState: SearchPanelState = {
  isOpen: false,
  query: '',
};
const searchPanelReducer: IReducers<SearchPanelState> = {
  [SEARCH_PANEL_SET_OPEN]: (state, action: { payload: boolean }) => ({
    ...state,
    isOpen: action.payload,
  }),
  [SEARCH_PANEL_SET_QUERY]: (state, action: { payload: string }) => ({
    ...state,
    query: action.payload,
  }),
};
export const searchPanel = connectReducers(searchPanelInitialState, searchPanelReducer);

export type SearchRewardsState = ICommonResponse<IStudentReward>;
export const searchRewards = createReducer(SEARCH_REWARDS, []);

export type SearchSportRewardsState = ICommonResponse<IStudentSportReward>;
export const searchSportRewards = createReducer(SEARCH_SPORT_REWARDS, []);

export type SearchGiaState = ICommonResponse<IGiaItem>;
export const searchGia = createReducer(SEARCH_GIA, []);

export type SearchEventsState = ICommonResponse<IStudentEvent>;
export const searchEvents = createReducer(SEARCH_EVENTS, []);

export type SearchProjectsState = ICommonResponse<IStudentProject>;
export const searchProjects = createReducer(SEARCH_PROJECTS, []);

export type SearchAffiliationsState = ICommonResponse<IStudentAffilation>;
export const searchAffiliations = createReducer(SEARCH_AFFILIATIONS, []);

export type SearchEmploymentsState = ICommonResponse<IStudentEmployment>;
export const searchEmployments = createReducer(SEARCH_EMPLOYMENTS, []);

export type SearchDiagnosticsState = ICommonResponse<IDiagnosis>;
export const searchDiagnostics = createReducer(SEARCH_DIAGNOSTICS, []);

export type SearchCultureVisitingsState = ICommonResponse<IStudentCultureVisiting>;
export const searchCultureVisitings = createReducer(SEARCH_CULTURE_VISITINGS, []);

export type SearchCheckinHistoryState = ICommonResponse<IStudentCheckIn>;
export const searchCheckinHistory = createReducer(SEARCH_CHECKIN_HISTORY, []);

export type SearchInterestsState = ICommonResponse<IInterestResponse>;
export const searchInterests = createReducer(SEARCH_INTERESTS, []);

export type SearchIndependentDiagnosticsState = ICommonResponse<IIndependentSelfDiagnostic>;
export const searchIndependentDiagnostics = createReducer(SEARCH_INDEPENDENT_DIAGNOSTICS, []);

export type SearchProfessionalAdditionalEducationState =
  ICommonResponseObject<IProfessionalAdditionalEducationResponse>;
export const searchProfessionalAdditionalEducation = createReducer<IProfessionalAdditionalEducationResponse>(
  SEARCH_PROFESSIONAL_ADDITIONAL_EDUCATION,
  { certificate: [] },
);

export type SearchDocumentsState = ICommonResponse<SpoDocument>;
export const searchDocuments = createReducer(SEARCH_DOCUMENTS, []);

export type SearchSpoEducationState = ICommonResponseObject<SpoEducation>;
export const searchSpoEducation = createReducer<SpoEducation>(SEARCH_SPO_EDUCATION, {
  profEducations: [],
  practices: [],
});

export type SearchJobsState = ICommonResponseObject<IJobsResponse>;
export const searchJobs = createReducer<IJobsResponse>(SEARCH_JOBS, { job: [] });

export type SearchMetaskillsState = ICommonResponse<MetaSkill>;
export const searchMetaskills = createReducer(SEARCH_METASKILLS, []);

export type SearchGiaWorldskillsState = ICommonResponse<StudentGiaWorldSkills>;
export const searchGiaWorldskills = createReducer(SEARCH_GIA_WORLDSKILLS, []);

export const collection = {
  searchPanel,
  searchRewards,
  searchSportRewards,
  searchGia,
  searchEvents,
  searchProjects,
  searchAffiliations,
  searchEmployments,
  searchDiagnostics,
  searchCultureVisitings,
  searchCheckinHistory,
  searchInterests,
  searchIndependentDiagnostics,
  searchProfessionalAdditionalEducation,
  searchDocuments,
  searchSpoEducation,
  searchJobs,
  searchMetaskills,
  searchGiaWorldskills,
};
