import { getAccentColor, NeutralColors } from 'portfolio3/styles';
import { reverseRecord } from 'utils/common';

export interface IStatusColors {
  text: string;
  background: string;
  border?: string;
}

export enum SpoStatusKeys {
  ACTIVE_SEARCH = 'activeSearch',
  EMPLOYED = 'employed',
  PRACTICE_SEARCH = 'practiceSearch',
  PRACTICE_DONE = 'practiceDone',
  NOT_LOOKING_FOR_JOB = 'notLookingForJob',
}

// spo_status_ref
export const SpoStatusValues: Record<SpoStatusKeys, string> = {
  [SpoStatusKeys.ACTIVE_SEARCH]: 'Активно ищу работу',
  [SpoStatusKeys.EMPLOYED]: 'Трудоустроен',
  [SpoStatusKeys.PRACTICE_SEARCH]: 'Ищу место для практики',
  [SpoStatusKeys.PRACTICE_DONE]: 'Практика пройдена',
  [SpoStatusKeys.NOT_LOOKING_FOR_JOB]: 'Не ищу работу',
};

export const SpoStatusCodes: Record<SpoStatusKeys, number> = {
  [SpoStatusKeys.ACTIVE_SEARCH]: 1,
  [SpoStatusKeys.EMPLOYED]: 2,
  [SpoStatusKeys.PRACTICE_SEARCH]: 3,
  [SpoStatusKeys.PRACTICE_DONE]: 4,
  [SpoStatusKeys.NOT_LOOKING_FOR_JOB]: 5,
};

export const StatusCodeToKey = reverseRecord(SpoStatusCodes);

export const SpoStatusSelectColors: Record<number, IStatusColors> = {
  [SpoStatusCodes.activeSearch]: {
    text: getAccentColor('blue', '100'),
    background: getAccentColor('blue', '8'),
  },
  [SpoStatusCodes.employed]: {
    text: getAccentColor('green', '100'),
    background: getAccentColor('green', '8'),
  },
  [SpoStatusCodes.practiceSearch]: {
    text: getAccentColor('orange', '100'),
    background: getAccentColor('orange', '8'),
  },
  [SpoStatusCodes.practiceDone]: {
    text: getAccentColor('violet', '100'),
    background: getAccentColor('violet', '8'),
  },
  [SpoStatusCodes.notLookingForJob]: {
    text: NeutralColors.light_neutrals_600,
    background: NeutralColors.light_neutrals_200,
  },
};

export const SpoStatusButtonColors: Record<number, IStatusColors> = {
  [SpoStatusCodes.activeSearch]: {
    text: getAccentColor('blue', '200'),
    background: '#CAE3F9',
    border: getAccentColor('blue', '100'),
  },
  [SpoStatusCodes.employed]: {
    text: getAccentColor('green', '200'),
    background: '#D1F0D7',
    border: getAccentColor('green', '100'),
  },
  [SpoStatusCodes.practiceSearch]: {
    text: getAccentColor('orange', '200'),
    background: '#FFE0C7',
    border: getAccentColor('orange', '100'),
  },
  [SpoStatusCodes.practiceDone]: {
    text: getAccentColor('violet', '200'),
    background: '#DFD5FC',
    border: getAccentColor('violet', '100'),
  },
  [SpoStatusCodes.notLookingForJob]: {
    text: NeutralColors.light_neutrals_700,
    background: NeutralColors.light_neutrals_200,
    border: NeutralColors.light_neutrals_300,
  },
};
