import { FC, ReactNode } from 'react';

import { ThemeProvider } from '@mui/material';
import { SectionKeys, SectionNames } from 'const';
import { useSectionTheme } from 'hooks';
import { IconSectionScience } from 'icons';
import { sportTheme } from 'portfolio3/styles/theme';

import SearchSection from '../components/SearchSection';
import { useSearchSport } from '../hooks';
import CommonEntityCardList from './CardLists/CommonEntityCardList';
import CommonRewardCardList from './CardLists/CommonRewardCardList';

const SportSection: FC = () => {
  const {
    rewards,
    clubs,
    occupations,
    competitions,
    expeditions,
    isSportRewardVisible,
    isSportClubVisible,
    isSportOccupationVisible,
    isSportCompetitionVisible,
    isSportExpeditionVisible,
    isSportVisible,
  } = useSearchSport();

  const theme = useSectionTheme(sportTheme);

  if (!isSportVisible) return null;

  const subsections: ReactNode[] = [];

  if (isSportRewardVisible) {
    subsections.push(
      <SearchSection.Subsection title="Награды и разряды">
        <CommonRewardCardList rewards={rewards} />
      </SearchSection.Subsection>,
    );
  }

  if (isSportClubVisible) {
    subsections.push(
      <SearchSection.Subsection title="Клубы и команды">
        <CommonEntityCardList entities={clubs} />
      </SearchSection.Subsection>,
    );
  }

  if (isSportOccupationVisible) {
    subsections.push(
      <SearchSection.Subsection title="Кружки и секции">
        <CommonEntityCardList entities={occupations} />
      </SearchSection.Subsection>,
    );
  }

  if (isSportCompetitionVisible) {
    subsections.push(
      <SearchSection.Subsection title="Соревнования">
        <CommonEntityCardList entities={competitions} />
      </SearchSection.Subsection>,
    );
  }

  if (isSportExpeditionVisible) {
    subsections.push(
      <SearchSection.Subsection title="Походы и экспедиции">
        <CommonEntityCardList entities={expeditions} />
      </SearchSection.Subsection>,
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <SearchSection name={SectionNames[SectionKeys.SPORT]} icon={<IconSectionScience />} subsections={subsections} />
    </ThemeProvider>
  );
};

export default SportSection;
