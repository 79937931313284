import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

import { getButtonStyles } from '../utils';

export const root: SxStyles = {
  display: 'flex',
  gap: '8px',
};

export const pages: SxStyles = {
  display: 'flex',
  gap: '4px',
};

export const paginationButton: SxStyles = {
  ...getButtonStyles(),

  minWidth: '32px',
  height: '32px',

  padding: '6px',
  borderRadius: '8px',

  '&:hover': {
    backgroundColor: NeutralColors.light_neutrals_200,
  },

  '&:disabled': {
    backgroundColor: 'initial',
    cursor: 'auto',
  },
};

export const navigationArrow: SxStyles = {
  width: '20px',
  height: '20px',

  color: NeutralColors.light_neutrals_600,
};

export const selectedButton: SxStyles = {
  '&&&': {
    backgroundColor: NeutralColors.light_neutrals_600,
  },
};

export const selectedContent: SxStyles = {
  color: NeutralColors.light_neutrals_0,
};
