/* eslint-disable prettier/prettier */
/* eslint-disable */

import { call, put } from 'redux-saga/effects';

import {
  getAttachment, putAttachment
} from '../api';
import { GenericGetAction } from '.';
import {
  getAttachmentActions, putAttachmentActions
} from '../actions';
import { downloadFileFromResponse } from '../utils';

function* getAttachmentSaga({ payload: { queryParams } }: GenericGetAction<any>) {
  const { id, personId, name } = queryParams;
  const { response, error } = yield call(getAttachment, { id, personId });

  if (response) {
    yield put(getAttachmentActions.success(response));
    downloadFileFromResponse(name, response);
  } else {
    yield put(getAttachmentActions.failure(error));
  }
}

function* putAttachmentSaga({ payload: { request, entityId, entityName } }: GenericGetAction<any>) {
  const { response, error } = yield call(putAttachment, request, entityId, entityName);

  if (response) {
    yield put(putAttachmentActions.success(response));
  } else {
    yield put(putAttachmentActions.failure(error));
  }
}

export {
  getAttachmentSaga,
  putAttachmentSaga
};
