import { FC, ReactNode } from 'react';

import { Box, Typography, useTheme } from '@mui/material';
import { useBrightTheme } from 'hooks';
import { getAccentColor, NeutralColors } from 'portfolio3/styles';

interface IWidgetContainerTitleProps {
  title: string;
  secondLine?: string;
  right?: ReactNode;
  isSmall?: boolean;
}

const WidgetContainerTitle: FC<IWidgetContainerTitleProps> = ({ title, secondLine, right, isSmall }) => {
  const isBrightTheme = useBrightTheme();
  const theme = useTheme();

  const accentColor200 = getAccentColor(theme.palette.accentColor, '200');

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '10px' }}>
      <Box display="grid">
        <Typography
          variant={isSmall ? 'Paragraph LG/Semi Bold' : 'Headings/H6'}
          sx={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
          color={isBrightTheme && !isSmall ? accentColor200 : NeutralColors.light_neutrals_900}
        >
          {title}
        </Typography>
        {secondLine && (
          <Typography variant="Paragraph MD/Regular" color={NeutralColors.light_neutrals_600}>
            {secondLine}
          </Typography>
        )}
      </Box>
      {right}
    </Box>
  );
};

export default WidgetContainerTitle;
