import { FC, ReactElement } from 'react';

import { Box, Typography, TypographyProps } from '@mui/material';
import { useBrightTheme } from 'hooks';
import { IconArrowLeft } from 'icons';
import TrimText from 'portfolio3/components/common/TrimText';
import { AccentColorType, getAccentColor, NeutralColors } from 'portfolio3/styles';

import * as styles from './styles';

interface IMobileDrawerHeaderProps {
  title: string;
  onExitBack?: () => void;
  crossButton?: ReactElement;
  multilineTitle?: boolean;
  titleVariant?: TypographyProps['variant'];
  isExtendedBackground?: boolean;
  accentColor?: AccentColorType;
  overrideTitleColor?: string;
  overrideBackgroundColor?: string;
}

const MobileDrawerHeader: FC<IMobileDrawerHeaderProps> = ({
  title,
  onExitBack,
  crossButton,
  multilineTitle,
  titleVariant = 'Paragraph LG/Semi Bold',
  isExtendedBackground = true,
  accentColor = 'indigo',
  overrideTitleColor,
  overrideBackgroundColor,
}) => {
  const isBrightTheme = useBrightTheme();

  const accent200 = getAccentColor(accentColor, '200');
  const accent8 = getAccentColor(accentColor, '8');

  const defaultTitleColor = isBrightTheme ? accent200 : NeutralColors.light_neutrals_900;
  const defaultBackgroundColor = isBrightTheme ? accent8 : NeutralColors.light_neutrals_100;

  const computedTitleColor = overrideTitleColor ?? defaultTitleColor;
  const computedBackgroundColor = overrideBackgroundColor ?? defaultBackgroundColor;

  return (
    <Box
      className="mobile-drawer-header"
      sx={styles.root(computedTitleColor, computedBackgroundColor, isExtendedBackground)}
    >
      {crossButton && <Box sx={styles.crossButton}>{crossButton}</Box>}
      <Box sx={styles.titleContainer}>
        {onExitBack && <IconArrowLeft sx={styles.exitBackButton} onClick={onExitBack} />}
        <Typography
          component={multilineTitle ? 'div' : TrimText}
          variant={titleVariant}
          sx={styles.title}
          color={computedTitleColor}
        >
          {title}
        </Typography>
        {onExitBack && <Box sx={styles.exitBackButton} />}
      </Box>
    </Box>
  );
};

export default MobileDrawerHeader;
