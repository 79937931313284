import { FC, ReactNode } from 'react';

import { ThemeProvider } from '@mui/material';
import { profileNavigation, SectionKeys, SectionNames, sectionRoutes } from 'const';
import { useSectionTheme, useStudentNavigation } from 'hooks';
import { IconSectionProfile } from 'icons';
import { profileTheme } from 'portfolio3/styles/theme';

import { InterestCard } from '../components/cards';
import SearchSection from '../components/SearchSection';
import { useSearchProfile } from '../hooks';

interface IProfileSectionProps {
  onCloseSearcher: () => void;
}

const ProfileSection: FC<IProfileSectionProps> = ({ onCloseSearcher }) => {
  const { interests, isProfileInterestsVisible, isProfileVisible } = useSearchProfile();

  const { navigateToSection, navigateToWidget } = useStudentNavigation();

  const theme = useSectionTheme(profileTheme);

  if (!isProfileVisible) return null;

  const viewInterestCard = () => {
    onCloseSearcher();
    navigateToSection(sectionRoutes.PROFILE);
    navigateToWidget(profileNavigation.interest.id, true);
  };

  const subsections: ReactNode[] = [];

  if (isProfileInterestsVisible) {
    subsections.push(
      <SearchSection.Subsection title="Анкета интересов">
        {interests.map(({ name, actions }, index, array) => {
          const card = <InterestCard name={name} actions={actions} onView={viewInterestCard} />;
          const key = `${name}-${index}`;
          return (
            <SearchSection.CardContainer
              key={key}
              card={card}
              mapIndex={index}
              totalCards={array.length}
              onView={viewInterestCard}
            />
          );
        })}
      </SearchSection.Subsection>,
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <SearchSection name={SectionNames[SectionKeys.PROFILE]} icon={<IconSectionProfile />} subsections={subsections} />
    </ThemeProvider>
  );
};

export default ProfileSection;
