/* eslint-disable prettier/prettier */
/* eslint-disable */

export enum subcategoryCultureKeys {
    MUSEUMS = 'museums',
    EXHIBITION = 'exhibition',
    INSTITUTION = 'institution',
    THEATRE = 'theatre',
    CONCERT = 'concert',
    CINEMA = 'cinema',
    EDUCATION = 'education',
    EXCURSION = 'excursion',
    OVER = 'over',
    ONLINELECTURE = 'onlinelecture',
    ONLINEEXHIBITION = 'onlineexhibition',
    ONLINEEXCURSION = 'onlineexcursion',
    ONLINEOVER = 'onlineover'
}
    
export const subcategoryCultureValues: Record<string, string> = {
    [subcategoryCultureKeys.MUSEUMS]: 'Посещение музея',
    [subcategoryCultureKeys.EXHIBITION]: 'Посещение выставки',
    [subcategoryCultureKeys.INSTITUTION]: 'Посещение культурного ОУ',
    [subcategoryCultureKeys.THEATRE]: 'Посещение театра',
    [subcategoryCultureKeys.CONCERT]: 'Посещение концерта',
    [subcategoryCultureKeys.CINEMA]: 'Посещение кинотеатра',
    [subcategoryCultureKeys.EDUCATION]: 'Посещение просветительского мероприятия',
    [subcategoryCultureKeys.EXCURSION]: 'Посещение экскурсии',
    [subcategoryCultureKeys.OVER]: 'Прочее (Универсальная группа по культурной тематике для офлайн-посещений)',
    [subcategoryCultureKeys.ONLINELECTURE]: 'Онлайн-лекция по культуре',
    [subcategoryCultureKeys.ONLINEEXHIBITION]: 'Онлайн-выставка',
    [subcategoryCultureKeys.ONLINEEXCURSION]: 'Онлайн-экскурсия',
    [subcategoryCultureKeys.ONLINEOVER]: 'Прочее (Универсальная группа по культурной тематике для онлайн-посещений)',
};
    
export const subcategoryCultureCodes: Record<string, number> = {
    [subcategoryCultureKeys.MUSEUMS]: 250,
    [subcategoryCultureKeys.EXHIBITION]: 251,
    [subcategoryCultureKeys.INSTITUTION]: 252,
    [subcategoryCultureKeys.THEATRE]: 253,
    [subcategoryCultureKeys.CONCERT]: 254,
    [subcategoryCultureKeys.CINEMA]: 255,
    [subcategoryCultureKeys.EDUCATION]: 256,
    [subcategoryCultureKeys.EXCURSION]: 257,
    [subcategoryCultureKeys.OVER]: 258,
    [subcategoryCultureKeys.ONLINELECTURE]: 259,
    [subcategoryCultureKeys.ONLINEEXHIBITION]: 260,
    [subcategoryCultureKeys.ONLINEEXCURSION]: 261,
    [subcategoryCultureKeys.ONLINEOVER]: 262,
};
