import { ComponentType, FC, PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { setCurrentStudentAction } from 'actions';
import { IUserContext } from 'api/types';
import { routes } from 'const';
import { useStudentContext } from 'hooks';
import { IRootState } from 'reducers';
import { getStudentGuid } from 'utils';

interface IUserContextContainerProps {
  linkMode: boolean;
  currentUser: IUserContext;
  setCurrentStudent: typeof setCurrentStudentAction;
}

const UserContextContainer: FC<IUserContextContainerProps & PropsWithChildren> = ({
  currentUser,
  linkMode,
  setCurrentStudent,
  children,
}) => {
  const matchWebviewRoute = useRouteMatch({ path: routes.WEBVIEW.path });
  const isWebview = matchWebviewRoute !== null;

  // TODO проверить в linkMode
  useStudentContext(currentUser, setCurrentStudent, linkMode, getStudentGuid(isWebview));

  return <>{children}</>;
};

const UserContextContainerRedux = connect(
  (state: IRootState) => ({
    linkMode: state.linkMode.mode ?? false,
    currentUser: state.currentUser,
  }),
  {
    setCurrentStudent: setCurrentStudentAction,
  },
)(UserContextContainer);

const withUserContext = () => {
  return <P extends PropsWithChildren<unknown>>(WrappedComponent: ComponentType<P>): FC<P> => {
    return function WithUserContext({ ...props }) {
      return (
        <UserContextContainerRedux>
          <WrappedComponent {...props} />
        </UserContextContainerRedux>
      );
    };
  };
};

export default withUserContext;
