import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { EntityType } from 'const';
import { useUserFunctionality } from 'hooks';
import {
  IconAlertTriangleOutline,
  IconEditOutline,
  IconHeartOutline,
  IconInfoOutline,
  IconTrashAltOutline,
} from 'icons';
import { IconDownload } from 'icons';
import { WidgetDataContext } from 'portfolio3/components/common/WidgetContainer';
import { emitYMEvent, YandexMetrikaTypeControl } from 'portfolio3/features/yandexMetrika';
import { IRootState } from 'reducers';
import { userLocalRolesSelector } from 'selectors';
import { EntityFlag } from 'types';

import { IActionPermissionsRequest, IEntityActionMap, IEntityActionWithType } from '../types';
import { hasDeletePermission, hasEditorPermission, hasReportPermission, hasThanksTeacherPermission } from '../utils';

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formData: any;
  entityType: EntityType | null;
  entityFlags?: EntityFlag[];
  actionsMap: IEntityActionMap;
}

const useEntityActions = ({ formData, entityType, entityFlags, actionsMap }: IProps, kind: 'menu' | 'drawer') => {
  const adminViewMode = useSelector((state: IRootState) => state.adminViewMode);
  const userLocalRoles = useSelector((state: IRootState) => userLocalRolesSelector(state));
  const linkMode = useSelector((state: IRootState) => state.linkMode.mode ?? false);

  const user = useUserFunctionality();

  const { widgetLabel } = useContext(WidgetDataContext);

  const { isViewing: isAdminViewing, viewType: adminViewType } = adminViewMode;

  const actionsRequest: IActionPermissionsRequest = {
    formData,
    userFunctionality: user,
    userLocalRoles,
    adminViewMode: isAdminViewing,
    adminViewType,
    entityFlags,
    entityType,
  };

  const hasEditOption = hasEditorPermission(actionsRequest);
  const hasDeleteOption = hasDeletePermission(actionsRequest);
  const hasReportOption = hasReportPermission(actionsRequest);
  const hasThanksOption = hasThanksTeacherPermission(actionsRequest);

  /**
   * managementReward - вызывается только для наград
   * managementDocument - вызывается только для документов
   * managementBlock - вызывается для всех сущностей
   */
  const emitManagementEvent = (typeControl: YandexMetrikaTypeControl) => {
    const isEntityReward = entityFlags?.includes('reward');
    const isEntityDocument = entityFlags?.includes('document');

    if (isEntityReward) {
      emitYMEvent({
        type: 'managementReward',
        payload: {
          typeControl: typeControl,
        },
      });
    }

    if (isEntityDocument) {
      emitYMEvent({
        type: 'managementDocument',
        payload: {
          typeControl,
        },
      });
    }

    emitYMEvent({
      type: 'managementBlock',
      payload: {
        Subsections: widgetLabel ?? '',
        typeControl,
      },
    });
  };

  const actions: IEntityActionWithType[] = [];

  if (actionsMap.view) {
    actions.push({
      type: 'view',
      isDisabled: actionsMap.view.isDisabled,
      desktopButtonVariant: actionsMap.view.desktopButtonVariant ?? 'primary',
      text: actionsMap.view.text ?? 'Подробнее',
      icon: IconInfoOutline,
      effect: () => {
        if (kind === 'menu') {
          emitYMEvent({ type: 'informationMore' });
        }
        emitManagementEvent('detailed');
        actionsMap.view?.effect();
      },
    });
  }

  if (actionsMap.edit && hasEditOption) {
    actions.push({
      type: 'edit',
      isDisabled: actionsMap.edit.isDisabled,
      desktopButtonVariant: actionsMap.edit.desktopButtonVariant ?? 'primary',
      mobileButtonVariant: actionsMap.edit.mobileButtonVariant ?? 'tetriary',
      text: actionsMap.edit.text ?? 'Редактировать',
      icon: IconEditOutline,
      effect: () => {
        emitManagementEvent('edit');
        actionsMap.edit?.effect();
      },
    });
  }

  if (actionsMap.download) {
    actions.push({
      type: 'download',
      isDisabled: actionsMap.download.isDisabled,
      desktopButtonVariant: actionsMap.download.desktopButtonVariant ?? 'secondary',
      mobileButtonVariant: actionsMap.download.mobileButtonVariant ?? 'tetriary',
      text: actionsMap.download.text ?? 'Скачать',
      icon: IconDownload,
      effect: () => {
        emitManagementEvent('download');
        actionsMap.download?.effect();
      },
    });
  }

  if (actionsMap.delete && hasDeleteOption) {
    actions.push({
      type: 'delete',
      isDisabled: actionsMap.delete.isDisabled,
      desktopButtonVariant: actionsMap.delete.desktopButtonVariant ?? 'secondary',
      mobileButtonVariant: actionsMap.delete.mobileButtonVariant ?? 'secondary',
      text: actionsMap.delete.text ?? 'Удалить',
      icon: IconTrashAltOutline,
      effect: () => {
        emitManagementEvent('delete');
        actionsMap.delete?.effect();
      },
    });
  }

  if (actionsMap.thanks && hasThanksOption) {
    actions.push({
      type: 'thanks',
      isDisabled: actionsMap.thanks.isDisabled,
      desktopButtonVariant: actionsMap.thanks.desktopButtonVariant ?? 'secondary',
      mobileButtonVariant: actionsMap.thanks.mobileButtonVariant ?? 'tetriary',
      text: actionsMap.thanks.text ?? 'Сказать спасибо учителю',
      icon: IconHeartOutline,
      effect: () => {
        emitManagementEvent('thanks');
        emitYMEvent({ type: 'gratitudeTeacherOpen' });
        actionsMap.thanks?.effect();
      },
    });
  }

  if (actionsMap.report && hasReportOption) {
    actions.push({
      type: 'report',
      isDisabled: actionsMap.report.isDisabled,
      desktopButtonVariant: actionsMap.report.desktopButtonVariant ?? 'secondary',
      mobileButtonVariant: actionsMap.report.mobileButtonVariant ?? 'secondary',
      text: actionsMap.report.text ?? 'Сообщить об ошибке',
      icon: IconAlertTriangleOutline,
      effect: () => {
        emitManagementEvent('mistake');
        emitYMEvent({ type: 'bugReport' });
        actionsMap.report?.effect();
      },
    });
  }

  return linkMode ? [] : actions;
};

export default useEntityActions;
