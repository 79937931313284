import { FC } from 'react';

import { FormControl as MaterialFormControl, formHelperTextClasses, styled } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';
import { skipPropsForwarding } from 'utils';

import { IBaseFormControlProps } from '../types';

interface IStyledFormControlProps {
  withTopMargin?: boolean;
}

const StyledFormControl = styled(MaterialFormControl, {
  shouldForwardProp: skipPropsForwarding<IStyledFormControlProps>(['withTopMargin']),
})<IStyledFormControlProps>(({ withTopMargin }) => {
  const marginTop = withTopMargin ? '10px' : 0;

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',

    paddingTop: marginTop,

    [`& .${formHelperTextClasses.root}`]: {
      margin: 0,
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 400,
      fontFamily: 'Inter',
      color: NeutralColors.light_neutrals_500,
    },

    [`& .${formHelperTextClasses.root}.${formHelperTextClasses.error}`]: {
      color: '#C92A2A',
    },
  };
});

/**
 * Базовый компонент FormControl.
 * Включает в себя вертикальное расположение элементов, стилизацию поясняющего текста.
 * Не использовать напрямую в качестве конечного компонента.
 */
const BaseFormControl: FC<IBaseFormControlProps<unknown>> = ({ children, ...props }) => {
  return <StyledFormControl {...props}>{children}</StyledFormControl>;
};

export default BaseFormControl;
