/* eslint-disable max-len */
import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconCreationContestsCommon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.7 8.8c1.4 0 2.5-1.1 2.5-2.5v-4h.9V0H3.9v3.8h-.2c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5ZM19.6 24c1.4 0 2.5-1.1 2.5-2.5v-4h.9v-2.3h-3.2V19h-.2c-1.4 0-2.5 1.1-2.5 2.5-.1 1.4 1.1 2.5 2.5 2.5ZM14.619 11.471 11.434 8.89 1.295 21.398l3.186 2.582L14.619 11.47Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="m13.402 11.8-1.7-1.4c-2-1.6-2.3-4.5-.7-6.5l1.8-2.2c1.6-2 4.5-2.3 6.5-.7l1.7 1.4c2 1.6 2.3 4.5.7 6.5l-1.8 2.2c-1.6 2-4.5 2.3-6.5.7Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconCreationContestsCommon;
