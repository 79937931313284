import { FC, PropsWithChildren } from 'react';

import { Box, Typography } from '@mui/material';
import FunnelBlock from 'components/common/FunnelBlock';
import { MobileDrawerHeader } from 'portfolio3/components/drawers';
import { NeutralColors } from 'portfolio3/styles';
import { Switcher, SwitcherButton } from 'portfolio3/ui-kit';
import { Drawer, IDrawerBase } from 'portfolio3/ui-kit/Drawer';

import { ICommonDrawerProps } from '../types';

const DetailViewDrawerMobile: FC<ICommonDrawerProps & PropsWithChildren> = ({
  isOpen,
  title,
  accentColor,
  footer,
  cardData,
  onClose,

  isTabsShown,
  tabs,
  currentTab,
  onChangeTab,

  children,
}) => {
  const hasTabs = isTabsShown && tabs.length > 0;

  const tabsElement = (
    <Switcher
      value={currentTab}
      onChange={onChangeTab}
      componentSize="large"
      sx={{ display: 'flex', flexDirection: 'row', width: '100%', '& button': { flexGrow: 1, flexBasis: '1px' } }}
    >
      {tabs.map((tab) => (
        <SwitcherButton key={tab.value} value={tab.value} content={tab.title} />
      ))}
    </Switcher>
  );

  const header: IDrawerBase['header'] = (crossButton) => (
    <MobileDrawerHeader crossButton={crossButton} title={title} accentColor={accentColor} />
  );

  return (
    <Drawer
      open={isOpen}
      isFullHeight
      isMobile={true}
      anchor="bottom"
      swipeable
      swipeableProps={{
        onClose: () => onClose?.(),
      }}
      header={header}
      footer={footer}
      sx={{ backgroundColor: NeutralColors.light_neutrals_200 }}
      onClose={onClose}
    >
      <Box sx={{ paddingInline: '8px' }}>
        <FunnelBlock>
          <Typography variant="Paragraph LG/Semi Bold">{cardData?.name}</Typography>
        </FunnelBlock>
        {hasTabs && (
          <Box className="tabs-container" sx={{ zIndex: 10, position: 'sticky', top: 0, marginTop: '8px' }}>
            {tabsElement}
          </Box>
        )}
        <Box sx={{ marginTop: '8px' }}>{children}</Box>
      </Box>
    </Drawer>
  );
};

export default DetailViewDrawerMobile;
