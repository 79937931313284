import { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconSchoolFilled: FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M2.0634 8.11625L10.4934 3.51625C11.0934 3.19625 11.8134 3.19625 12.4134 3.51625L21.9334 8.70624C22.2534 8.88624 22.4534 9.21625 22.4534 9.58625V15.9962C22.4534 16.5462 22.0034 16.9962 21.4534 16.9962C20.9034 16.9962 20.4534 16.5462 20.4534 15.9962V10.0862L12.4134 14.4762C11.8134 14.8062 11.0934 14.8062 10.4934 14.4762L2.0634 9.87625C1.3734 9.49624 1.3734 8.49625 2.0634 8.11625ZM4.4534 15.9862V13.1762L10.4934 16.4762C11.0934 16.8062 11.8134 16.8062 12.4134 16.4762L18.4534 13.1762V15.9862C18.4534 16.7162 18.0534 17.3962 17.4134 17.7462L12.4134 20.4762C11.8134 20.8062 11.0934 20.8062 10.4934 20.4762L5.4934 17.7462C4.8534 17.3962 4.4534 16.7162 4.4534 15.9862Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconSchoolFilled;
