import { IRootState } from 'reducers';
import { createSelector } from 'reselect';
import { isDefined } from 'utils';

const maxSpecialitiesBallSelector = createSelector([(state: IRootState) => state.vuzList], (vuzList) => {
  const specialities = vuzList.content.items.flatMap((vuz) => vuz.specialities).filter(isDefined);
  const getMaxBall = (maxBall1?: number, maxBall2?: number) => {
    if (maxBall1 && maxBall2) return Math.max(maxBall1, maxBall2);

    return isDefined(maxBall1) ? maxBall1 : maxBall2;
  };

  const getNearestHundred = (num: number): number => {
    const acc = num % 100;
    if (acc === 0) return num;

    return num + 100 - acc;
  };

  const maxBall = specialities.reduce((counter, { paid_min_ball, budget_min_ball }) => {
    const currentSpecialityMinBall = getMaxBall(paid_min_ball, budget_min_ball);
    return (counter = Math.max(counter, currentSpecialityMinBall || 0));
  }, 0);

  return getNearestHundred(maxBall);
});

export default maxSpecialitiesBallSelector;
