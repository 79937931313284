/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { HorizontalBar } from '@reactchartjs/react-chart.js';

import HorizontalBarChartSkeleton from './skeleton';
import { IDiagnosisBarData } from '../../../api/types';
import { Colors } from '../../../style/variables';

import 'chartjs-plugin-datalabels';

import './index.scss';

interface IHorizontalBarChartProps {
  barData: IDiagnosisBarData[];
  loading?: boolean;
  isOnPdfPage?: boolean;
}

const HorizontalBarChart: React.FC<IHorizontalBarChartProps> = ({ barData, loading, isOnPdfPage }) => {
  const incomingData = barData.map((data) => data.total.toFixed(1));
  const incomingLabels = barData.map((data) => data.name);

  const data = (canvas: any) => {
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, canvas.width, 0);
    gradient.addColorStop(0, isOnPdfPage ? '#B9B9B9' : '#D5CAFF');
    gradient.addColorStop(1, isOnPdfPage ? '#B9B9B9' : '#BBD6FF');
    return ({
      labels: incomingLabels,
      datasets: [
        {
          label: 'результат',
          data: incomingData,
          backgroundColor: gradient,
          borderWidth: 0,
          barThickness: 12
        }
      ]
    });
  };

  const options = {
    maintainAspectRatio: false,
    aspectRatio: 2,
    legend: {
      display: false
    },
    plugins: {
      datalabels: {
        display:  false
      }
    },
    scales: {
      yAxes: [
        {
          ticks: {
            display: false
          },
          gridLines: {
            offsetGridLines: false,
            lineWidth: 12,
            drawBorder: false,
            color: Colors['page-background'],
            drawTicks: false
          }
        }
      ],
      xAxes: [{
        stacked: true,
        gridLines: {
          borderDash: [5, 15],
          color: Colors['disable-input'],
          drawBorder: false
        },
        ticks: {
          beginAtZero: true,
          fontSize: 16,
          lineHeight: 1.5,
          fontWeight: 500,
          fontColor: Colors['note-text'],
          suggestedMax: 100
        }
      }]
    }
  };
  return (
    <div className="bar-chart">
      { !loading ? (
        <div className="bar-chart__container">
          <div className="bar-chart__labels">
            {incomingLabels.map((label) => (
              <p className="bar-chart__label" key={label}>{label}</p>
            ))}
          </div>
          <div className="bar-chart__canvas">
            <HorizontalBar data={data} options={options} type="bar" />
            <p className="bar-chart__title">Средний результат пройденных диагностик в процентах</p>
          </div>
        </div>
      ) : <HorizontalBarChartSkeleton />}
    </div>
  );
};

export default HorizontalBarChart
