import { FC, useMemo } from 'react';

import { useAppSelector, useDataLoad } from 'hooks';
import PdfNoDataText from 'portfolio3/features/pdf/components/PdfNoDataText';
import { getDataLoadRequests } from 'utils';

import { PdfCommonDataEntity } from '../../../../components/pdfStudent/DataEntity';
import { widgetPdfIcons } from '../../../../icons';
import { studySpoPracticeSelector } from '../../../../selectors';
import PdfCommonDataEntityContainer from '../common';

import '../index.scss';

const PdfPracticeContainer: FC = () => {
  const practice = useAppSelector(studySpoPracticeSelector);
  useDataLoad({ shouldLoad: true, requests: getDataLoadRequests().studySpoEducation });

  const hasData = practice.content.length > 0;

  const filteredYear = useMemo(
    () =>
      practice.content.length > 0
        ? practice.content?.reduce((prevItem, nextItem) => (prevItem.year > nextItem.year ? prevItem : nextItem)).year
        : undefined,
    [practice],
  );

  return (
    <PdfCommonDataEntityContainer title="Практика" icon={widgetPdfIcons.pdfIconStudyPractice}>
      {hasData &&
        practice.content.map((entity) => (
          <PdfCommonDataEntity key={entity.id || entity.recordId} entity={entity} year={filteredYear} />
        ))}
      {!hasData && <PdfNoDataText />}
    </PdfCommonDataEntityContainer>
  );
};

export default PdfPracticeContainer;
