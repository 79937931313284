import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import {
  IconAlertCircleFilled,
  IconCheckmarkCircleFilled,
  IconChevronLargeDown,
  IconChevronLargeUp,
  IconClockFilled,
  IconTrashAltFilled,
} from 'icons';
import UnstyledButton from 'portfolio3/components/common/UnstyledButton';
import { getAccentColor, NeutralColors } from 'portfolio3/styles';
import { mergeSx } from 'utils';

import TaskCounter from '../TaskCounter';
import * as styles from './styles';

interface ITaskPanelHeaderProps {
  isActive: boolean;
  isOpen: boolean;
  progressTasks: number;
  previewTasks: number;
  readyTasks: number;
  onClear: () => void;
  onClose: () => void;
}

const TaskPanelHeader: FC<ITaskPanelHeaderProps> = ({
  isActive,
  isOpen,
  progressTasks,
  previewTasks,
  readyTasks,
  onClear,
  onClose,
}) => {
  const taskCounters = (
    <Box display="flex">
      <TaskCounter
        icon={<IconClockFilled />}
        count={progressTasks}
        color={NeutralColors.light_neutrals_400}
        label="Выполняется"
      />
      <TaskCounter
        icon={<IconAlertCircleFilled />}
        count={previewTasks}
        color={getAccentColor('orange', '100')}
        label="Требуются действия"
      />
      <TaskCounter
        icon={<IconCheckmarkCircleFilled />}
        count={readyTasks}
        color={getAccentColor('green', '100')}
        label="Завершено"
      />
    </Box>
  );

  return (
    <Box
      className="task-panel-header"
      sx={mergeSx(styles.header, isActive && styles.headerVisible, !isOpen && { cursor: 'pointer' })}
    >
      <Box sx={styles.headerLeft}>
        <Typography variant="Paragraph LG/Semi Bold">Задачи</Typography>
        {taskCounters}
      </Box>
      <Box sx={styles.headerRight}>
        <UnstyledButton sx={styles.button} onClick={onClear}>
          <IconTrashAltFilled />
        </UnstyledButton>
        <UnstyledButton sx={styles.button} onClick={onClose}>
          {isOpen ? <IconChevronLargeDown /> : <IconChevronLargeUp />}
        </UnstyledButton>
      </Box>
    </Box>
  );
};

export default TaskPanelHeader;
