/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';

import ResultDoughnut from '../../../../common/ResultDoughnut';
import SettingsSwitch from '../../../../common/SettingsSwitch';
import { IRootState } from '../../../../../reducers';
import { IndependentDiagnosisSettingsState } from '../../../../../reducers/independentDiagnosisSettings';
import { setIndependentDiagnosisSettingAction } from '../../../../../actions';
import { IIndependentDiagnosticData } from '../../../../../types';
import { PortfolioSettingsContext } from '../../../../../context';

import { Box, SxProps, Typography, useMediaQuery, useTheme } from '@mui/material';
import { getAccentColor } from 'portfolio3/styles';
import { getDiagnosticLevelTypeColor, mergeSx } from 'utils';
import SpoilerButton from 'components/common/SpoilerButton';
import * as styles from './styles';
import { DiagnosticLevelColorSet1, DiagnosticRatingRegionCodes, IndependentDiagnosticLevelTypeNames } from 'const';
import {
  useDiagnosticRating,
  city,
  school,
  classIcon,
  Conclusions,
  DiagnosticCardResultDescription,
  DiagnosticCardSubjectDynamic,
  InfoBox,
  MobileDiagnosticDetailsDrawer,
  Rating,
  getDiagnosticRatingInfoBoxImage,
  getDiagnosticRatingInfoBoxText,
  getDiagnosticRatingInfoBoxTitle,
  cardRatingTooltipText,
  IRatingTab,
  createMapFromRating,
} from 'portfolio3/features/diagnostics';

import './index.scss';

import { IChildInfo, IIndependentDiagnosticsRatingResponse, IIndependentDiagnosticRating, IIndependentRating } from 'api/types';
import { useDisclosure } from 'hooks';
import { emitYMEvent } from 'portfolio3/features/yandexMetrika';

const getDoughnutData = (result: number, max: number) => {
  const valueProgressbar = result / max * 100;
  return {
    incomingData: [valueProgressbar, 100 - valueProgressbar],
    incomingLabels: ['1', '2']
  };
};

interface IDiagnosticCardProps {
  diagnostic: IIndependentDiagnosticData;
  currentStudent: IChildInfo;
  independentDiagnosisSettings: IndependentDiagnosisSettingsState;
  independentDiagnosticsRatingResponse: IIndependentDiagnosticsRatingResponse;
  setIndependentDiagnosisSetting: typeof setIndependentDiagnosisSettingAction;
}

const DiagnosticCard: React.VFC<IDiagnosticCardProps> = ({
  diagnostic,
  currentStudent,
  independentDiagnosisSettings,
  independentDiagnosticsRatingResponse,
  setIndependentDiagnosisSetting
}) => {
  const {
    recordId,
    name,
    subject,
    eventDate,
    resultValue,
    maxResult,
    workId,
    learningYear,
    levelType,
    visibilitySettingValue,
    note,
    percentResult,
  } = diagnostic;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('commonSm'));
  const [selectedRegion, setSelectedRegion] = useState<number>(DiagnosticRatingRegionCodes.Region);
  const { isSettingsMode } = useContext(PortfolioSettingsContext);
  const [isExpanded, setIsExpanded] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { formChartRows } = useDiagnosticRating('independentRating');

  const isCardVisible = independentDiagnosisSettings.settings[recordId] !== undefined
    ? independentDiagnosisSettings.settings[recordId]
    : visibilitySettingValue;

  const progressValue = Math.round((resultValue * 100) / maxResult);
  const doughnutData = getDoughnutData(resultValue, maxResult);

  const dateZeroPadding = (num: number) => num.toString().padStart(2, '0');
  const date = new Date(eventDate);
  const [day, month, year] = [date.getDate(), date.getMonth() + 1, date.getFullYear()];
  const dateValue = `${dateZeroPadding(day)}.${dateZeroPadding(month)}.${year}`;

  const handleSwitchVisibilitySetting = () => {
    setIndependentDiagnosisSetting(recordId, !isCardVisible);
  };

  const handleToggleDesktopDetails = () => {
    const isCollapsed = !isExpanded;
    setIsExpanded((prev) => !prev);

    if (isCollapsed) {
      emitYMEvent({ type: 'diagnosticInformationExpand' });
    }
  };

  const expandedStyles: SxProps = {
    backgroundColor: getAccentColor(theme.palette.accentColor, '8')
  };

  // TODO перенести проверку отображения до рендера компонента, тут вроде проблема с дивайдерами
  const subjectDynamicChart = DiagnosticCardSubjectDynamic({ subject });

  const currentYearRating = independentDiagnosticsRatingResponse.content?.find((rating) => rating.learningYear === learningYear);
  const currentRatingObject = currentYearRating?.independentDiagnosticRating?.find((rating) => (
    rating.workId === workId && rating.subject.toLowerCase() === subject.toLowerCase()
  ));
  // скрытие блока рейтинга если уровень диагностики "Ниже базового"
  const isRatingChartVisible = levelType?.toLowerCase() !== IndependentDiagnosticLevelTypeNames.belowBase.toLowerCase();

  /* определение информации для инфобокса */
  const infoboxHasRequiredLevelType = [
    IndependentDiagnosticLevelTypeNames.base,
    IndependentDiagnosticLevelTypeNames.overHigh,
    IndependentDiagnosticLevelTypeNames.high
  ].some((requiredLevelType) => requiredLevelType.toLowerCase() === levelType?.toLowerCase());

  const studentName = currentStudent.firstname || '';
  const infoboxRenderCondition = infoboxHasRequiredLevelType && Boolean(levelType) && Boolean(studentName);
  const infoboxTitle = getDiagnosticRatingInfoBoxTitle(levelType ?? '');
  const infoboxText = getDiagnosticRatingInfoBoxText(levelType ?? '', studentName);
  const infoboxImage = getDiagnosticRatingInfoBoxImage(levelType ?? '');

  function createRatingTabs(generalRating: IIndependentDiagnosticRating): IRatingTab[] {
    const ratingTabsMap = createMapFromRating<IIndependentRating>(generalRating, 'independentRating');
    const locationNamesPool = ['Москве', 'твоей школе', 'твоем классе'];
    const ratingImagesPool = [city, school, classIcon];

    return Array.from(ratingTabsMap).map((tab) => {
      const [tabIndex, rating] = tab;

      return {
        typography: {
          heading: `${rating.studentPlace} место`,
          paragraph: `В ${locationNamesPool.shift()}`,
        },
        image: ratingImagesPool[tabIndex - 1],
        hasCrown: rating.studentPlace === 1,
        value: tabIndex,
      };
    });
  }

  function handleRegionChange(value: number) {
    setSelectedRegion(value);
  }

  const resultDescription = (
    <DiagnosticCardResultDescription
      resultValue={resultValue}
      maxResult={maxResult}
      levelType={levelType}
    />
  );

  const resultDoughnutChart = (
    <ResultDoughnut
      total={`${progressValue}%`}
      data={doughnutData}
      color={getDiagnosticLevelTypeColor(levelType || '', DiagnosticLevelColorSet1, '80')}
    />
  );

  const diagnosticCardDetails: JSX.Element[] = [];

  if (currentRatingObject && isRatingChartVisible) {
    diagnosticCardDetails.push(
      <Box sx={styles.cardBlock}>
        <Rating
          header={{ title: 'Твой рейтинг', buttonText: 'Как это считается?', tooltipText: cardRatingTooltipText }}
          InfoBox={
            infoboxRenderCondition && infoboxTitle && infoboxImage && (
              <InfoBox
                image={infoboxImage}
                title={infoboxTitle}
                text={infoboxText}
              />
            )
          }
          tabs={createRatingTabs(currentRatingObject)}
          tabValue={selectedRegion}
          onTabChange={handleRegionChange}
          chartRows={formChartRows(currentRatingObject, selectedRegion)}
          caption="результат диагностики в %"
          isDiagnosticRating
        />
      </Box>
    );
  }

  if (subjectDynamicChart) {
    diagnosticCardDetails.push(
      <Box sx={styles.cardBlock}>
        {subjectDynamicChart}
      </Box>
    );
  }

  if (note) {
    diagnosticCardDetails.push(
      <Box sx={styles.cardBlock}>
        <Conclusions title='Выводы по результатам диагностики' text={note}/>
      </Box>
    );
  }

  const cardDetailsWithDividers = diagnosticCardDetails.map((element, index, elements) => {
    if (index > 0 && elements.length > 1) {
      return (
        <>
          <Box sx={styles.cardDivider} />
          {element}
        </>
      );
    }
    return element;
  });

  const hasDetails = diagnosticCardDetails.length > 0;

  return (
    <Box className="diagnostic-card" sx={hasDetails ? { paddingBottom: '16px' } : null}>
      {isSettingsMode && !isCardVisible && <div className="diagnostic-card__overlay"></div>}
      <Box
        className="diagnostic-card__content"
        sx={mergeSx(styles.cardBlock, styles.mainCardBlock, isExpanded && !isSmallScreen ? expandedStyles : {})}
      >
        <div className="diagnostic-card__info info">
          {
            isSettingsMode && (
              <div className="diagnostic-card__switcher">
                <SettingsSwitch checked={isCardVisible} onChange={handleSwitchVisibilitySetting} />
                {!isCardVisible && <p className="diagnostic-card__switcher-text-state">Карточка скрыта</p>}
              </div>
            )
          }
          <header className="info__header">
            <p className="info__title">{name}</p>
            <p className="info__subject">{subject}</p>
          </header>
          <p className="info__date">{dateValue}</p>
        </div>
        <div className="diagnostic-card__result result">
          <div className="result__description">
            <p className="result__title">Твой результат:</p>
            <p className="result__data">
              {resultDescription}
            </p>
          </div>
          <div className="result__chart">
            {resultDoughnutChart}
          </div>
        </div>
      </Box>

      {isSmallScreen && hasDetails && (
        <>
          <Box component="button" onClick={onOpen} sx={mergeSx(styles.detailButton, styles.cardBlock)}>
            <Typography variant="Paragraph MD/Semi Bold" color={getAccentColor('indigo', '200')}>Подробнее</Typography>
          </Box>

          <MobileDiagnosticDetailsDrawer
            open={isOpen}
            onClose={onClose}
            diagnostic={diagnostic}
            date={dateValue}
            resultDoughnut={resultDoughnutChart}
            resultDescription={resultDescription}
          >
            <Box sx={{ paddingTop: '16px' }}>
              {cardDetailsWithDividers}
            </Box>
          </MobileDiagnosticDetailsDrawer>
        </>
      )}

      {!isSmallScreen && hasDetails && (
        <>
          <Box sx={mergeSx(styles.cardDivider, { marginTop: 0 })} />

          {isExpanded && cardDetailsWithDividers}

          {isExpanded && <Box sx={styles.cardDivider} />}
          <Box className="diagnostic-card__footer" sx={styles.cardBlock}>
            <SpoilerButton
              isExpanded={isExpanded}
              expandedText="Свернуть"
              collapsedText="Развернуть"
              onClick={handleToggleDesktopDetails}
            />
          </Box>
        </>
      )}
    </Box>
  );
}

export default connect(
  (state: IRootState) => ({
    currentStudent: state.currentStudent,
    independentDiagnosisSettings: state.independentDiagnosisSettings,
    independentDiagnosticsRatingResponse: state.independentDiagnosticsRating,
  }),
  {
    setIndependentDiagnosisSetting: setIndependentDiagnosisSettingAction
  }
)(DiagnosticCard);
