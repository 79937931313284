import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root: SxStyles = (theme) => ({
  position: 'relative',
  paddingBottom: '24px',

  border: '1px solid',
  borderRadius: '8px',
  borderColor: NeutralColors.light_neutrals_300,
  boxShadow: '0px 1px 2px rgba(41, 41, 64, 0.07)',
  backgroundColor: NeutralColors.light_neutrals_0,

  overflow: 'hidden',

  [theme.breakpoints.down('commonSm')]: {
    paddingBottom: '12px',

    border: 'none',
    borderRadius: '12px',
    boxShadow: 'box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1)',
  },
});
