import { IPerformanceYear } from './api';

export * from './api';

export enum MarkType {
  AVERAGE = 1,
  FINAL = 2,
}

export enum VisibilityType {
  DIAGRAM = 1,
  TILES = 2,
  CHART = 3,
}

export interface IYearOption {
  value: string;
  code: number;
}

export interface IPerformanceSubjectInfo {
  name: string;
  mark: number;
}

export interface IPerformanceSubjectTopic {
  name: string;
  passedLessons: number;
  totalLessons: number;
  averageResult: number | null;
}

export interface IPerformanceSubjectTopicInfo {
  learningYear: string;
  totalTopics: number;
  doneTopics: number;
  percentDoneTopics: number;
  topics: IPerformanceSubjectTopic[];
}

export interface IPerformanceDiagramData {
  currentYear: IPerformanceYear;
  previousYear: IPerformanceYear | undefined;
}

export interface IPerformanceDiagramSubject {
  name: string;
  mark: number;
  markChange: number;
  color: string;
}
