import { IReducers } from 'reducers';
import { connectReducers } from 'utils';

import { IVuzInfo } from '../../types';
import { VUZ_DETAILS_DRAWER_SET_INFO, VUZ_DETAILS_DRAWER_SET_OPEN } from '../actions';

export type VuzDetailsDrawerState = {
  isOpen: boolean;
  vuzInfo: IVuzInfo | null;
};

type SetOpenAction = { payload: boolean };
type SetInfoAction = { payload: IVuzInfo | null };

const initialState: VuzDetailsDrawerState = {
  isOpen: false,
  vuzInfo: null,
};

const vuzDetailsDrawer: IReducers<VuzDetailsDrawerState> = {
  [VUZ_DETAILS_DRAWER_SET_OPEN]: (state, { payload }: SetOpenAction) => {
    return {
      ...state,
      isOpen: payload,
    };
  },
  [VUZ_DETAILS_DRAWER_SET_INFO]: (state, { payload }: SetInfoAction) => {
    return {
      ...state,
      vuzInfo: payload,
    };
  },
};

export default connectReducers(initialState, vuzDetailsDrawer);
