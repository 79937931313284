import { FC, useRef } from 'react';
import { AriaCalendarCellProps, mergeProps, useCalendarCell } from 'react-aria';
import { CalendarState, RangeCalendarState } from 'react-stately';

import { CalendarDate, isSameMonth } from '@internationalized/date';
import { Typography } from '@mui/material';
import { getAccentColor, NeutralColors } from 'portfolio3/styles';
import { getButtonStyles } from 'portfolio3/ui-kit/utils';
import { SxStyles } from 'types';
import { mergeSx } from 'utils';

const styles = (isSelected: boolean, isDisabled: boolean): SxStyles => {
  return {
    ...getButtonStyles(),

    zIndex: 10,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    width: '32px',
    height: '32px',

    borderRadius: '8px',

    '&:hover': {
      backgroundColor: isSelected || isDisabled ? undefined : getAccentColor('indigo', '24'),
    },
  };
};

interface IDayCellProps {
  cellOptions: AriaCalendarCellProps;
  calendarState: CalendarState | RangeCalendarState;
  startDate: CalendarDate;
  isRangeSelected?: boolean;
  isSelectedInBetweenRange?: boolean;
}

const DayCell: FC<IDayCellProps> = ({
  cellOptions,
  calendarState,
  startDate,
  isRangeSelected,
  isSelectedInBetweenRange,
}) => {
  const ref = useRef(null);
  const { cellProps, buttonProps, isSelected, isDisabled, formattedDate } = useCalendarCell(
    cellOptions,
    calendarState,
    ref,
  );

  const isOutsideMonth = !isSameMonth(startDate, cellOptions.date);
  const isGrayedOut = isDisabled || isOutsideMonth;
  const computedIsSelected = (isSelected || isRangeSelected) ?? false;

  // color
  let color = NeutralColors.light_neutrals_900;
  if (isGrayedOut) color = NeutralColors.light_neutrals_500;
  if (computedIsSelected && !isSelectedInBetweenRange) color = NeutralColors.light_neutrals_0;

  // backgroundColor
  let backgroundColor = NeutralColors.light_neutrals_0;
  if (computedIsSelected) backgroundColor = getAccentColor('indigo', '100');
  if (isSelectedInBetweenRange) backgroundColor = getAccentColor('indigo', '24');

  const dynamicStyles: SxStyles = {
    color,
    backgroundColor,
  };

  return (
    <Typography
      variant="Paragraph MD/Regular"
      className="day-cell"
      component="button"
      {...mergeProps(cellProps, buttonProps)}
      ref={ref}
      sx={mergeSx(styles(computedIsSelected, isGrayedOut), dynamicStyles)}
    >
      {formattedDate}
    </Typography>
  );
};

export default DayCell;
