import { menuClasses, SxProps } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';

export const container: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  gap: '2px',

  padding: '8px',
  backgroundColor: NeutralColors.light_neutrals_0,

  boxShadow: '0px -1px 5px rgba(41, 41, 64, 0.04), 0px -3px 6px -1px rgba(41, 41, 64, 0.08)',
};

export const menu: SxProps = {
  [`& .${menuClasses.paper}`]: {
    padding: '8px',

    borderRadius: '8px',
    border: `1px solid ${NeutralColors.light_neutrals_300}`,
  },

  [`& .${menuClasses.list}`]: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    padding: 0,
  },
};
