import { SxStyles } from 'types';

export const root: SxStyles = {
  padding: '16px',
};

export const switcher: SxStyles = (theme) => {
  return {
    marginTop: '16px',

    [theme.breakpoints.down('commonSm')]: {
      marginTop: '12px',
    },
  };
};

export const customPeriodControl: SxStyles = (theme) => {
  return {
    width: 'max-content',
    marginTop: '16px',

    [theme.breakpoints.down('commonSm')]: {
      marginTop: '12px',
    },
  };
};
