import { ICommonResponse } from 'api/types';
import { EntityType, SectionCodes, SourceCode, SportSubcategoryCodes, WithoutOrganization } from 'const';
import { cardIllustrations } from 'images';
import { SportKindsState } from 'reducers/employee/sportKinds';
import { TourismKindsState } from 'reducers/employee/tourismKinds';
import { SectionRefState } from 'reducers/reference';
import { StudentAffilationsState } from 'reducers/student/affilations';
import { StudentEmploymentsState } from 'reducers/student/employments';
import { StudentEventsState } from 'reducers/student/events';
import { StudentSportRewardsState } from 'reducers/student/sportRewards';
import { mapSportCompetitionToEntity, mapSportExpeditionToEntity, mapSportRewardEntity } from 'selectors/entityMappers';
import { IEntity, IEntityFeature, IModifiedReward } from 'types';
import { flagsMap, getDateRange, getEntityGeneralInfo, getSportEntityBackgroundImage, getYearFromDate } from 'utils';

const getClubFeature = (typeCode: number) => {
  if (typeCode === SectionCodes.sportClub) return 'клуб';
  return 'команда';
};

export const mapSportClubsResponse = (
  affilations: StudentAffilationsState,
  sportKinds: SportKindsState,
): ICommonResponse<IEntity> => {
  const sportAffilations = affilations.content.filter(
    (affilation) => affilation.category?.code === SectionCodes.sport || affilation.categoryCode === SectionCodes.sport,
  );
  const clubs = sportAffilations.map((affilation): IEntity => {
    const { id, recordId, name, source, affilationLevel, type, startDate, sportKind, endDate, subspecies } = affilation;

    const subspeciesArray = subspecies?.split(', ');
    const eventTypes = sportKind ? [sportKind.value] : undefined;

    const eventFeatures: IEntityFeature[] = [{ text: getClubFeature(type?.code), isColored: true }];
    if (affilationLevel) eventFeatures.push({ text: affilationLevel.value });

    return {
      id,
      recordId,
      name: name === WithoutOrganization ? 'Клуб/команда' : name,
      types: eventTypes,
      date: getDateRange(startDate, endDate),
      year: getYearFromDate(endDate || startDate),
      features: eventFeatures,
      backgroundImage: getSportEntityBackgroundImage(sportKind?.value),
      formData: {
        ...affilation,
        subspeciesString: sportKinds.content
          ?.filter((sportKind) => subspeciesArray?.includes(String(sportKind.code)))
          .map((sportKind) => sportKind.value.trim())
          .join(', '),
      },
      cardData: {
        name,
        generalInfo: getEntityGeneralInfo(affilation),
        files: affilation.fileReferences,
        linkedObjects: affilation.linkedObjects,
        formData: affilation,
        illustrationFallback: cardIllustrations.sportClub,
      },
      entityProps: {
        type: EntityType.AFFILATION,
        flags: flagsMap({ approved: source?.code !== SourceCode.student && source?.code !== SourceCode.parent }),
      },
    };
  });

  return {
    ...affilations,
    content: clubs,
  };
};

export const mapSportCompetitionsResponse = (
  events: StudentEventsState,
  sportRewards: StudentSportRewardsState,
  sportKinds: SportKindsState,
  sectionRef: SectionRefState,
): ICommonResponse<IEntity> => {
  const boundRewards = sportRewards.content?.filter((reward) => reward.entityId);
  const competitions = events.content
    .filter(
      (event) =>
        (event.category?.code === SectionCodes.sport || event.categoryCode === SectionCodes.sport) &&
        event.typeCode !== SectionCodes.sportEventTourism &&
        event.typeCode !== SectionCodes.sportEventExpedition,
    )
    .map((event) => {
      const { id, recordId } = event;

      const eventReward = boundRewards?.find((reward) => reward.entityId == id || reward.entityId == recordId);
      return mapSportCompetitionToEntity(sectionRef.content, event, eventReward, sportKinds.content);
    });

  return {
    ...events,
    content: competitions,
  };
};

export const mapSportExpeditionsResponse = (
  events: StudentEventsState,
  sportRewards: StudentSportRewardsState,
  tourismKinds: TourismKindsState,
  sectionRef: SectionRefState,
): ICommonResponse<IEntity> => {
  const boundRewards = sportRewards.content?.filter((reward) => reward.entityId);
  const expeditions = events.content
    .filter(
      (event) =>
        (event.category?.code === SectionCodes.sport || event.categoryCode === SectionCodes.sport) &&
        (event.typeCode === SectionCodes.sportEventExpedition || event.typeCode === SectionCodes.sportEventTourism),
    )
    .map((event) => {
      const { id, recordId } = event;

      const eventReward = boundRewards?.find((reward) => reward.entityId == id || reward.entityId == recordId);
      return mapSportExpeditionToEntity(sectionRef.content, event, eventReward, tourismKinds.content);
    });

  return {
    ...events,
    content: expeditions,
  };
};

export const mapSportOccupationsResponse = (employments: StudentEmploymentsState): ICommonResponse<IEntity> => {
  const occupations = employments.content
    .filter((employment) => employment.categoryCode === SectionCodes.sport)
    .map((employment): IEntity => {
      const {
        id,
        recordId,
        name,
        source,
        startDate,
        sportKind,
        tourismKind,
        achievementActivityFormat,
        type,
        location,
        endDate,
        subcategory,
      } = employment;

      const occupationTypes = [];
      if (subcategory?.code === SportSubcategoryCodes.sport && sportKind) occupationTypes.push(sportKind?.value);
      if (subcategory?.code === SportSubcategoryCodes.tourism && tourismKind) occupationTypes.push(tourismKind?.value);

      const occupationFeatures: IEntityFeature[] = [];
      if (type) occupationFeatures.push({ text: type.value, isColored: true });
      if (achievementActivityFormat) occupationFeatures.push({ text: achievementActivityFormat.value });

      return {
        id,
        recordId,
        name,
        types: occupationTypes,
        date: getDateRange(startDate, endDate),
        year: getYearFromDate(endDate || startDate),
        features: occupationFeatures,
        backgroundImage: getSportEntityBackgroundImage(sportKind?.value),
        place: location,
        formData: employment,
        cardData: {
          name,
          generalInfo: getEntityGeneralInfo(employment),
          files: employment.fileReferences,
          linkedObjects: employment.linkedObjects,
          formData: employment,
          illustrationFallback: cardIllustrations.sportSection,
        },
        entityProps: {
          type: EntityType.EMPLOYMENT,
          flags: flagsMap({ approved: source?.code !== SourceCode.student && source?.code !== SourceCode.parent }),
        },
      };
    });

  return {
    ...employments,
    content: occupations,
  };
};

export const mapSportRewardsResponse = (
  events: StudentEventsState,
  sportRewards: StudentSportRewardsState,
  sportKinds: SportKindsState,
  tourismKinds: TourismKindsState,
  sectionRef: SectionRefState,
): ICommonResponse<IModifiedReward> => {
  const rewards = sportRewards.content.map((reward) => {
    return mapSportRewardEntity(reward, events.content, sportKinds.content, tourismKinds.content, sectionRef.content);
  });

  return {
    ...sportRewards,
    content: rewards,
  };
};
