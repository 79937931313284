/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { SvgIcon } from '@mui/material';
import { IconRouteProps } from './types';

const IconRouteCulture: React.FC<IconRouteProps> = ({ outerFill, ...props }) => (
  <SvgIcon width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path className="outer" d="M0 8C0 3.58172 3.58172 0 8 0H22C26.4183 0 30 3.58172 30 8V22C30 26.4183 26.4183 30 22 30H8C3.58172 30 0 26.4183 0 22V8Z" fill={outerFill || '#FFF0F0'} />
    <path className="inner" fillRule="evenodd" clipRule="evenodd" d="M7.64183 10.0178L14.2252 6.55117C14.7085 6.29284 15.2918 6.29284 15.7752 6.55117L22.3585 10.0178C22.7002 10.1928 22.9168 10.5512 22.9168 10.9345C22.9168 11.5095 22.4502 11.9762 21.8835 11.9762H8.12516C7.55016 11.9762 7.0835 11.5095 7.0835 10.9345C7.0835 10.5512 7.30016 10.1928 7.64183 10.0178ZM8.75016 14.8928V18.2262C8.75016 18.9178 9.3085 19.4762 10.0002 19.4762C10.6918 19.4762 11.2502 18.9178 11.2502 18.2262V14.8928C11.2502 14.2012 10.6918 13.6428 10.0002 13.6428C9.3085 13.6428 8.75016 14.2012 8.75016 14.8928ZM13.7502 14.8928V18.2262C13.7502 18.9178 14.3085 19.4762 15.0002 19.4762C15.6918 19.4762 16.2502 18.9178 16.2502 18.2262V14.8928C16.2502 14.2012 15.6918 13.6428 15.0002 13.6428C14.3085 13.6428 13.7502 14.2012 13.7502 14.8928ZM22.9168 22.3928C22.9168 23.0845 22.3585 23.6428 21.6668 23.6428H8.3335C7.64183 23.6428 7.0835 23.0845 7.0835 22.3928C7.0835 21.7012 7.64183 21.1428 8.3335 21.1428H21.6668C22.3585 21.1428 22.9168 21.7012 22.9168 22.3928ZM18.7502 14.8928V18.2262C18.7502 18.9178 19.3085 19.4762 20.0002 19.4762C20.6918 19.4762 21.2502 18.9178 21.2502 18.2262V14.8928C21.2502 14.2012 20.6918 13.6428 20.0002 13.6428C19.3085 13.6428 18.7502 14.2012 18.7502 14.8928Z" fill="#FF6B6B" />
  </SvgIcon>
);

export default IconRouteCulture;
