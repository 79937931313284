import { ElementRef, FC, useRef } from 'react';
import { useDateSegment } from 'react-aria';
import { DateFieldState, DateSegment } from 'react-stately';

import { Typography } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';
import { getFocusStateStyles } from 'portfolio3/ui-kit/utils';
import { SxStyles } from 'types';

const styles: SxStyles = {
  textTransform: 'uppercase',

  '&:focus-visible': {
    ...getFocusStateStyles(),
    borderRadius: '4px',
  },
};

interface IDateSegment {
  segment: DateSegment;
  state: DateFieldState;
}

const DateFieldSegment: FC<IDateSegment> = ({ segment, state }) => {
  const ref = useRef<ElementRef<'span'>>(null);
  const { segmentProps } = useDateSegment(segment, state, ref);

  const color = segment.isPlaceholder ? NeutralColors.light_neutrals_600 : NeutralColors.light_neutrals_900;

  return (
    <Typography variant="Paragraph MD/Regular" color={color} {...segmentProps} ref={ref} sx={styles}>
      {segment.text}
    </Typography>
  );
};

export default DateFieldSegment;
