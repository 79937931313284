import { Dispatch, SetStateAction, useCallback } from 'react';

interface IStateWithSortAndPage {
  sort: Record<string, unknown>;
  page: number;
}

const useSortState = <T extends IStateWithSortAndPage>(setFilters: Dispatch<SetStateAction<T>>) => {
  const handleSort = useCallback(
    (sortKey: keyof T['sort']) => {
      setFilters((prevState) => {
        const currentSort = prevState.sort[sortKey as string];
        let nextSort: string | undefined = undefined;

        if (!currentSort) nextSort = 'asc';
        if (currentSort === 'asc') nextSort = 'desc';
        if (currentSort === 'desc') nextSort = undefined;

        return {
          ...prevState,
          sort: {
            ...prevState.sort,
            [sortKey]: nextSort,
          },
          page: 0,
        };
      });
    },
    [setFilters],
  );

  return { handleSort };
};

export default useSortState;
