import { MetaObject } from 'api/types';

export const serializeMetaObjectToHeader = (meta: MetaObject | undefined) => {
  if (!meta) return undefined;

  try {
    return { Meta: base64encode(JSON.stringify(meta)) };
  } catch {
    console.error('Возникла ошибка при формировании метаданных');
    return undefined;
  }
};

export const deserializeMeta = (meta: string): MetaObject | undefined => {
  try {
    return JSON.parse(base64decode(meta));
  } catch {
    console.error('Возникла ошибка при чтении метаданных');
    return undefined;
  }
};

function base64encode(str: string) {
  const encoder = new TextEncoder();
  const charCodes = encoder.encode(str);
  const binaryString = String.fromCharCode(...Array.from(charCodes));
  return btoa(binaryString);
}

function base64decode(str: string) {
  const base64 = atob(str);
  const charCodes = Uint8Array.from(base64, (m) => m.codePointAt(0) ?? 0);

  const decoder = new TextDecoder();
  const decoded = decoder.decode(charCodes);
  return decoded;
}
