/* eslint-disable max-len */
import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconMetaSkillChart: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m17.741 3.801-.096.294.22.218.385.382V16.25a2 2 0 0 1-2 2H3.75a2.001 2.001 0 0 1-1.906-1.392l.366-1.034.111-.313-.246-.224-.325-.296V3.75a2 2 0 0 1 2-2h12.5a2 2 0 0 1 1.797 1.122l-.306.93Z"
      fill="#fff"
      stroke="#E8E8EF"
    />
    <path
      d="M6.25 6.875v6.25h-5v.625h5v5h.625v-5h6.25v5h.625v-5h5v-.625h-5v-6.25h5V6.25h-5v-5h-.625v5h-6.25v-5H6.25v5h-5v.625h5Zm.625 0h6.25v6.25h-6.25v-6.25Z"
      fill="#E8E8EF"
    />
    <path
      d="M1.325 16.86a2.505 2.505 0 0 1-.075-.61v-1.063l4.414-4.358a1.375 1.375 0 0 1 1.913-.019l1.342 1.276a.313.313 0 0 0 .437-.006l9.22-9.248c.112.284.174.594.174.918v.742l-8.668 8.572c-.525.52-1.368.53-1.907.025L6.83 11.828a.313.313 0 0 0-.434.006L1.325 16.86Z"
      fill="#FA5252"
    />
  </SvgIcon>
);

export default IconMetaSkillChart;
