import { capitalize } from '@mui/material';
import { IGiaItem } from 'api/types';
import { cloneDeep } from 'lodash';
import { IRootState } from 'reducers';
import { createSelector } from 'reselect';

const replaceInformationTechnologySubjectName = (exam: IGiaItem): IGiaItem => {
  return {
    ...exam,
    // Информатика и ИКТ -> Информатика
    name: exam.name?.replace(' и ИКТ', '') ?? null,
  };
};

const replaceMathSubjectName = (exam: IGiaItem): IGiaItem => {
  // Математика базовая
  // Математика профильная
  // Математика профильного уровня

  const mathSubjectName = 'математика';
  const examName = exam.name;
  const isIncludesMath = examName?.toLowerCase()?.includes(mathSubjectName.toLowerCase()) ?? false;
  const modifiedExamName = isIncludesMath ? capitalize(mathSubjectName) : examName;

  return {
    ...exam,
    name: modifiedExamName,
  };
};

const recommendationGiaList = createSelector([(state: IRootState) => state.studentGiaList], (studentGiaList) => {
  const copyGiaList = cloneDeep(studentGiaList);
  const normalizedGiaList: typeof copyGiaList = {
    ...copyGiaList,
    content: copyGiaList.content.map(replaceInformationTechnologySubjectName).map(replaceMathSubjectName),
  };

  return normalizedGiaList;
});

export default recommendationGiaList;
