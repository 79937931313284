import { Dispatch, FC, SetStateAction, useCallback, useEffect, useRef } from 'react';
import { VariableSizeList } from 'react-window';

import { Box } from '@mui/material';
import { IModifiedPersonObject } from 'portfolio3/features/dataEntryForm';

import PortfolioDataItemRow from './PortfolioDataItemRow';
import { IDeleteDialogData } from './types';

const LIST_GAP = 12;
const LIST_VIEWPORT_UNUSED_HEIGHT = 174;
const LIST_ESTIMATED_CARD_HEIGHT = 180;

interface IPortfolioDataWindowedListProps {
  objects: IModifiedPersonObject[];
  loading: boolean;
  currentCategory: number;
  setDeleteDialogData: Dispatch<SetStateAction<IDeleteDialogData | undefined>>;
}

const PortfolioDataWindowedList: FC<IPortfolioDataWindowedListProps> = ({
  objects,
  currentCategory,
  loading,
  setDeleteDialogData,
}) => {
  const listRef = useRef<VariableSizeList>(null);
  const sizeMap = useRef<Record<number, number>>({});

  const viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

  const getSize = (index: number) => {
    const cardHeight = sizeMap.current[index] ?? 0;
    const gap = index !== objects.length - 1 ? LIST_GAP : 0;
    return cardHeight + gap;
  };

  const updateSizeMap = useCallback((index: number, size: number) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
    listRef.current?.resetAfterIndex(index);
  }, []);

  useEffect(() => {
    if (listRef?.current && !loading) {
      listRef.current.resetAfterIndex(0);
    }
  }, [loading]);

  useEffect(() => {
    if (listRef?.current) {
      listRef.current.resetAfterIndex(0);
      listRef.current.scrollTo(0);
    }
  }, [currentCategory]);

  return (
    <VariableSizeList
      height={viewportHeight - LIST_VIEWPORT_UNUSED_HEIGHT}
      itemCount={objects.length}
      itemSize={getSize}
      width="100%"
      estimatedItemSize={LIST_ESTIMATED_CARD_HEIGHT}
      ref={listRef}
    >
      {(props) => {
        return (
          <Box style={props.style}>
            <PortfolioDataItemRow
              index={props.index}
              filteredPersonsObjects={objects}
              isActionsDisabled={loading}
              updateSizeMap={updateSizeMap}
              setDeleteDialogData={setDeleteDialogData}
            />
          </Box>
        );
      }}
    </VariableSizeList>
  );
};

export default PortfolioDataWindowedList;
