/* eslint-disable prettier/prettier */
/* eslint-disable */

import genericReducers from '../genericReducer';
import { connectReducers } from '../../utils';
import { IReducers } from '../index';

import { GET_INTEREST_ACTION_KINDS } from '../../actions';
import { REQUEST, FAILURE, SUCCESS } from '../../actions/utils';

import { ICommonResponse, IInterestActionKind } from '../../api/types';

export type InterestActionKindsState = ICommonResponse<IInterestActionKind>;

const initialState = {
  content: [],
  loading: false
} as InterestActionKindsState;

const interestActionKinds: IReducers<InterestActionKindsState> = {
  [GET_INTEREST_ACTION_KINDS[REQUEST]]: genericReducers().get.request,
  [GET_INTEREST_ACTION_KINDS[SUCCESS]]: genericReducers().get.success,
  [GET_INTEREST_ACTION_KINDS[FAILURE]]: genericReducers().get.failure
};

export default connectReducers(initialState, interestActionKinds);
