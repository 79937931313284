import { ThemeCategoryKeys } from 'const';

import useThemeSettings from './useThemeSettings';

const useBrightTheme = () => {
  const themeSettings = useThemeSettings();

  const isBrightTheme = themeSettings.themeName === ThemeCategoryKeys.BRIGHT;

  return isBrightTheme;
};

export default useBrightTheme;
