/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const IconImportCloudUpload: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99984 2.49992C12.1507 2.49992 14.0457 3.88408 14.729 5.87075C16.7615 6.14658 18.3332 7.89325 18.3332 9.99992C18.3332 11.0174 17.9632 11.9966 17.2915 12.7574C17.1265 12.9432 16.8973 13.0391 16.6665 13.0391C16.4707 13.0391 16.274 12.9707 16.1148 12.8307C15.7707 12.5249 15.7373 11.9991 16.0423 11.6532C16.4448 11.1982 16.6665 10.6099 16.6665 9.99992C16.6665 8.62158 15.5448 7.49992 14.1665 7.49992H14.0832C13.6865 7.49992 13.3448 7.21992 13.2665 6.83075C12.9548 5.28742 11.5815 4.16658 9.99984 4.16658C8.419 4.16658 7.04484 5.28742 6.734 6.83075C6.65567 7.21992 6.31317 7.49992 5.9165 7.49992H5.83317C4.45484 7.49992 3.33317 8.62158 3.33317 9.99992C3.33317 10.6099 3.55484 11.1982 3.95817 11.6532C4.26234 11.9991 4.22984 12.5249 3.88484 12.8307C3.53984 13.1357 3.01317 13.1016 2.709 12.7574C2.0365 11.9966 1.6665 11.0174 1.6665 9.99992C1.6665 7.89325 3.23817 6.14658 5.27067 5.87075C5.95484 3.88408 7.84984 2.49992 9.99984 2.49992ZM9.42084 9.4C9.74917 9.08667 10.2683 9.08917 10.5892 9.41083L13.0892 11.9108C13.415 12.2367 13.415 12.7633 13.0892 13.0892C12.9267 13.2517 12.7133 13.3333 12.5 13.3333C12.2867 13.3333 12.0733 13.2517 11.9108 13.0892L10.8333 12.0117V16.6667C10.8333 17.1275 10.46 17.5 10 17.5C9.54 17.5 9.16667 17.1275 9.16667 16.6667V11.9633L8.07917 13.0133C7.74834 13.3342 7.22084 13.3233 6.90084 12.9925C6.58084 12.6608 6.59 12.1342 6.92084 11.8142L9.42084 9.4Z" fill="#B0B3C3"/>
  </SvgIcon>
);

export default IconImportCloudUpload;
