import React from 'react';

import { IconButton } from '@mui/material';
import clsx from 'clsx';

import { IconClose } from '../../../../../icons/edit';
import Dialog from '../../../../../ui-kit/dialog/Dialog';
import { IInfoDialogProps } from '../types';

import './index.scss';

const InfoDialog: React.FC<IInfoDialogProps> = ({ className, icon, title, description, onClose, open, ...props }) => {
  return (
    <Dialog open={open} className={clsx('info-dialog', className)} onClose={onClose} {...props}>
      <div className="info-dialog__title-container">
        <div className="info-dialog__icon">{icon}</div>
        <h5 className="info-dialog__title">{title}</h5>
        <IconButton disableTouchRipple onClick={onClose} className="info-dialog__close-button" size="large">
          <IconClose />
        </IconButton>
      </div>
      <p className="info-dialog__description">{description}</p>
    </Dialog>
  );
};

export default InfoDialog;
