import { isDefined } from 'utils';

import { FacultyListType, ILocalVuzFaculty, ILocalVuzSpeciality } from '../../types';

export const searchDetailsDrawerSpecialities = (
  specialities: ILocalVuzSpeciality[],
  searchTerm: string,
): ILocalVuzSpeciality[] => {
  if (searchTerm.trim().length === 0) return specialities;

  const filteredSpecialities = specialities.filter((speciality) => {
    const { name, subjects, facultyName } = speciality;
    const searchCandidates = [name, facultyName, ...(subjects ?? [])].filter(isDefined).map((v) => v.toLowerCase());

    return searchCandidates.some((candidate) => candidate.includes(searchTerm.toLowerCase()));
  });

  return filteredSpecialities;
};

export const searchDetailsDrawerFaculties = (
  faculties: ILocalVuzFaculty[],
  searchTerm: string,
  facultyListType: FacultyListType,
): ILocalVuzFaculty[] => {
  if (searchTerm.trim().length === 0) return faculties;

  const searchTermLowerCase = searchTerm.toLowerCase();

  const filteredFaculties = faculties.map((faculty): ILocalVuzFaculty | null => {
    const { name, specialities } = faculty;

    const isMatchFacultyName = name.toLowerCase().includes(searchTermLowerCase);

    // имя факультета совпало - добавляем факультет со всеми специальностями
    if (isMatchFacultyName) return faculty;

    // имя факультета не совпало в коротком списке
    if (facultyListType === 'short') return null;

    // имя факультета не совпало в длинном списке - фильтруем специальности
    const filteredSpecialities = searchDetailsDrawerSpecialities(specialities, searchTermLowerCase);
    if (filteredSpecialities.length === 0) return null;

    return {
      ...faculty,
      specialities: filteredSpecialities,
    };
  });

  return filteredFaculties.filter(isDefined);
};
