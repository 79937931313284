import { IIndependentDiagnosticsRatingResponse } from 'api/types';

import { GET_INDEPENDENT_DIAGNOSTICS_RATING } from '../../actions';
import { FAILURE, INVALIDATE, REQUEST, SUCCESS } from '../../actions/utils';
import { connectReducers } from '../../utils';
import { IReducers } from '..';
import genericReducers from '../genericReducer';

export type IndependentDiagnosticsRatingState = IIndependentDiagnosticsRatingResponse;

const initialState: IndependentDiagnosticsRatingState = {
  content: undefined,
  loading: false,
};

const independentDiagnosticsRating: IReducers<IndependentDiagnosticsRatingState> = {
  [GET_INDEPENDENT_DIAGNOSTICS_RATING[REQUEST]]: genericReducers().get.request,
  [GET_INDEPENDENT_DIAGNOSTICS_RATING[SUCCESS]]: genericReducers().get.success,
  [GET_INDEPENDENT_DIAGNOSTICS_RATING[FAILURE]]: genericReducers().get.failure,
  [GET_INDEPENDENT_DIAGNOSTICS_RATING[INVALIDATE]]: genericReducers().get.invalidate(initialState),
};

export default connectReducers(initialState, independentDiagnosticsRating);
