import { connectReducers } from '../../utils';
import { IReducers } from '..';
import { GRATITUDE_TEACHER, gratitudeTeacherActions } from './../../actions/students';
import { FAILURE, REQUEST, SUCCESS } from './../../actions/utils';

export type GratitudeTeacherState = {
  errorMessage?: string | null;
};

type FailureAction = {
  payload: Parameters<typeof gratitudeTeacherActions.failure>;
};

const initialState: GratitudeTeacherState = {
  errorMessage: undefined,
};

const gratitudeTracherStatus: IReducers<GratitudeTeacherState> = {
  [GRATITUDE_TEACHER[REQUEST]]: () => ({
    errorMessage: undefined,
  }),
  [GRATITUDE_TEACHER[SUCCESS]]: () => ({
    errorMessage: undefined,
  }),
  [GRATITUDE_TEACHER[FAILURE]]: (_, action: FailureAction) => {
    const [error] = action.payload;
    return {
      errorMessage: error?.message ?? null,
    };
  },
};

export default connectReducers(initialState, gratitudeTracherStatus);
