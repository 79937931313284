import { FC, PropsWithChildren } from 'react';

import { useBuildConfig, useLoadApplicationStatusConfig } from 'hooks';
import AppLoader from 'portfolio3/components/common/AppLoader';

const BuildConfigLoader: FC<PropsWithChildren> = ({ children }) => {
  useLoadApplicationStatusConfig();
  const config = useBuildConfig();

  if (config === null) {
    return <AppLoader />;
  }

  return <>{children}</>;
};

export default BuildConfigLoader;
