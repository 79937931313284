import { SxStyles } from 'types';

export const top: SxStyles = {
  display: 'flex',
  gap: '12px',
};

export const quickAccess: SxStyles = {
  display: 'flex',
  gap: '12px',
  marginTop: '12px',
};

export const overflowContainer: SxStyles = {
  overflowX: 'auto',
};

export const overflowElement: SxStyles = {
  minWidth: 'max-content',
};
