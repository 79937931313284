import { FC, useEffect, useRef } from 'react';
import { DateValue, useRangeCalendar } from 'react-aria';
import { DateRangePickerState, RangeCalendarStateOptions, useRangeCalendarState } from 'react-stately';

import { createCalendar } from '@internationalized/date';
import { Box, Stack } from '@mui/material';
import { capitalize } from 'lodash';

import { TodayButton } from '../../shared/buttons';
import CalendarGrid from '../../shared/CalendarGrid';
import CalendarHeader from '../../shared/CalendarHeader';
import { DayRangePickerController } from '../../types';

interface IDayRangeCalendarProps {
  datePickerState: DateRangePickerState;
  calendarOptions: Partial<RangeCalendarStateOptions>;
  onSetInitialStartDate: (date: DateValue | null) => void;
  controller: DayRangePickerController;
  withToday?: boolean;
}

const DayRangeCalendar: FC<IDayRangeCalendarProps> = ({
  datePickerState,
  calendarOptions,
  onSetInitialStartDate,
  controller,
  withToday,
}) => {
  const calendarStateOptions = {
    ...calendarOptions,
    locale: 'ru-RU',
    createCalendar,
  };

  const state = useRangeCalendarState(calendarStateOptions);
  const ref = useRef(null);
  const { calendarProps, prevButtonProps, nextButtonProps } = useRangeCalendar(calendarStateOptions, state, ref);

  const { anchorDate, focusedDate } = state;
  const focusedDateObject = new Date(focusedDate.year, focusedDate.month - 1, focusedDate.day);
  const formattedMonth = Intl.DateTimeFormat('ru-RU', { month: 'long' }).format(focusedDateObject);
  const title = `${capitalize(formattedMonth)} ${focusedDate.year} г.`;

  // Обновление startDate при условии что была выбрана первая дата и не выбрана вторая дата
  useEffect(() => {
    if (anchorDate && !datePickerState.value.end) {
      onSetInitialStartDate(anchorDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorDate]);

  const handleSetToday = () => {
    controller.handleChange({ start: new Date(), end: null });
    datePickerState.close();
  };

  return (
    <Stack className="day-range-calendar" {...calendarProps} ref={ref} alignItems="center">
      <CalendarHeader title={title} prevButtonProps={prevButtonProps} nextButtonProps={nextButtonProps} />
      <Box sx={{ marginTop: '12px' }}>
        <CalendarGrid calendarState={state} />
      </Box>
      {withToday && (
        <Box sx={{ marginTop: '8px' }}>
          <TodayButton onSetToday={handleSetToday} />
        </Box>
      )}
    </Stack>
  );
};

export default DayRangeCalendar;
