import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconColorPaletteOutline: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M10.5 6.5a1.5 1.5 0 1 1 3.001.001A1.5 1.5 0 0 1 10.5 6.5Zm4.201 2.75a1.5 1.5 0 1 1 2.6-1.5 1.5 1.5 0 0 1-2.6 1.5ZM8.75 7.201a1.5 1.5 0 1 0-1.5 2.6 1.5 1.5 0 0 0 1.5-2.6ZM5.757 13.34a1.499 1.499 0 1 1 2.486-1.678 1.5 1.5 0 1 1-2.486 1.678ZM15.881 15h-1.65a2.502 2.502 0 0 0-2.5 2.5c0 .606.224 1.193.63 1.653.125.14.166.305.122.488-.05.205-.285.338-.594.358a8.01 8.01 0 0 1-5.946-2.773c-1.515-1.755-2.18-4.004-1.87-6.332.512-3.833 3.959-6.862 7.85-6.895h.075c2.357 0 4.578.9 6.107 2.478 1.288 1.329 1.96 3.024 1.89 4.772-.08 1.998-2.002 3.751-4.114 3.751Zm3.659-9.916C17.617 3.101 14.815 1.993 11.906 2h-.001c-4.944.042-9.164 3.752-9.813 8.631a10.057 10.057 0 0 0 2.338 7.903A10.006 10.006 0 0 0 11.86 22h.039c1.2 0 2.258-.787 2.527-1.886a2.517 2.517 0 0 0-.567-2.288.494.494 0 0 1-.128-.325.5.5 0 0 1 .5-.5h1.65c3.192 0 5.99-2.598 6.112-5.672.092-2.3-.78-4.517-2.453-6.244Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconColorPaletteOutline;
