import { ICommonResponseObject, IDictionaryItem } from 'api/types';
import { IRootState } from 'reducers';
import { createSelector } from 'reselect';

import { filterRealGiaExam } from '../../utils';
import recommendationGiaList from './recommendationGiaList';
import { getSubjectsForExams } from './utils';

type RealExamsResponseObject = ICommonResponseObject<IDictionaryItem[]> & {
  isCached: boolean;
};

const realExamSubjectsSelector = createSelector(
  [(state: IRootState) => recommendationGiaList(state), (state: IRootState) => state.egeExamSubjectsRef],
  (studentGiaList, egeExamSubjectsRef): RealExamsResponseObject => {
    const realExams = studentGiaList.content.filter(filterRealGiaExam);
    const realExamSubjects = getSubjectsForExams(egeExamSubjectsRef.content, realExams);

    return {
      content: realExamSubjects,
      loading: studentGiaList.loading || egeExamSubjectsRef.loading,
      isCached:
        studentGiaList.requestCache !== undefined &&
        egeExamSubjectsRef.requestCache !== undefined &&
        studentGiaList.requestCache !== 'notCached' &&
        egeExamSubjectsRef.requestCache !== 'notCached',
    };
  },
);

export default realExamSubjectsSelector;
