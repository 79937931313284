/* eslint-disable prettier/prettier */
/* eslint-disable */

import { IQueryParams } from '../api';
import { ISaveInterestRequest } from '../api/types';

import {
  createRequestTypes,
  action,
  FAILURE,
  REQUEST,
  SUCCESS
} from './utils';

export const GET_STUDENT_INTERESTS = createRequestTypes('GET_STUDENT_INTERESTS');
export const getStudentInterestsActions = {
  request: (personId: string) => action(GET_STUDENT_INTERESTS[REQUEST], { payload: { personId } }),
  success: (response: any) => action(GET_STUDENT_INTERESTS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_STUDENT_INTERESTS[FAILURE], { payload: error })
};

export const CREATE_STUDENT_INTEREST_LIST = createRequestTypes('CREATE_STUDENT_INTEREST_LIST');
export const createStudentInterestListActions = {
  request: (request: ISaveInterestRequest[], personId: string) => action(CREATE_STUDENT_INTEREST_LIST[REQUEST], { payload: { request, personId } }),
  success: (response: any) => action(CREATE_STUDENT_INTEREST_LIST[SUCCESS], { payload: response }),
  failure: (error: any) => action(CREATE_STUDENT_INTEREST_LIST[FAILURE], { payload: error })
};

export const EDIT_STUDENT_INTEREST = createRequestTypes('EDIT_STUDENT_INTEREST');
export const editStudentInterestActions = {
  request: (request: ISaveInterestRequest, interestId: number, personId: string) =>
    action(EDIT_STUDENT_INTEREST[REQUEST], { payload: { request, interestId, personId } }),
  success: (response: any) => action(EDIT_STUDENT_INTEREST[SUCCESS], { payload: response }),
  failure: (error: any) => action(EDIT_STUDENT_INTEREST[FAILURE], { payload: error })
};

export const DELETE_STUDENT_INTEREST = createRequestTypes('DELETE_STUDENT_INTEREST');
export const deleteStudentInterestActions = {
  request: (personId: string, interestId: number) => action(DELETE_STUDENT_INTEREST[REQUEST], { payload: { personId, interestId } }),
  success: (response: any) => action(DELETE_STUDENT_INTEREST[SUCCESS], { payload: response }),
  failure: (error: any) => action(DELETE_STUDENT_INTEREST[FAILURE], { payload: error })
};

export const GET_INTEREST_HEAD_KINDS = createRequestTypes('GET_INTEREST_HEAD_KINDS');
export const getInterestHeadKindsActions = {
  request: (queryParams?: IQueryParams) => action(GET_INTEREST_HEAD_KINDS[REQUEST], { payload: { queryParams } }),
  success: (response: any) => action(GET_INTEREST_HEAD_KINDS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_INTEREST_HEAD_KINDS[FAILURE], { payload: error })
};

export const GET_INTEREST_ACTION_KINDS = createRequestTypes('GET_INTEREST_ACTION_KINDS');
export const getInterestActionKindsActions = {
  request: (queryParams?: IQueryParams) => action(GET_INTEREST_ACTION_KINDS[REQUEST], { payload: { queryParams } }),
  success: (response: any) => action(GET_INTEREST_ACTION_KINDS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_INTEREST_ACTION_KINDS[FAILURE], { payload: error })
};

export const GET_INTEREST_GROUP_KINDS = createRequestTypes('GET_INTEREST_GROUP_KINDS');
export const getInterestGroupKindsActions = {
  request: (queryParams?: IQueryParams) => action(GET_INTEREST_GROUP_KINDS[REQUEST], { payload: { queryParams } }),
  success: (response: any) => action(GET_INTEREST_GROUP_KINDS[SUCCESS], { payload: response }),
  failure: (error: any) => action(GET_INTEREST_GROUP_KINDS[FAILURE], { payload: error })
};

export const GET_INTEREST_OBJECT_KINDS = createRequestTypes('GET_INTEREST_OBJECT_KINDS');
export const getInterestObjectKindsActions = {
  request: (queryParams?: IQueryParams) => action(GET_INTEREST_OBJECT_KINDS[REQUEST], { payload: { queryParams } }),
  success: (response: any) => action(GET_INTEREST_OBJECT_KINDS[SUCCESS], { payload: response }),
  failure: () => action(GET_INTEREST_OBJECT_KINDS[FAILURE])
};

export const GET_ARCHIVED_INTEREST_OBJECT_KINDS = createRequestTypes('GET_ARCHIVED_INTEREST_OBJECT_KINDS');
export const getArchivedInterestObjectKindsActions = {
  request: (queryParams?: IQueryParams) => action(GET_ARCHIVED_INTEREST_OBJECT_KINDS[REQUEST], { payload: { queryParams } }),
  success: (response: any) => action(GET_ARCHIVED_INTEREST_OBJECT_KINDS[SUCCESS], { payload: response }),
  failure: () => action(GET_ARCHIVED_INTEREST_OBJECT_KINDS[FAILURE])
};
