import { FC } from 'react';

import { Box, Typography, useMediaQuery } from '@mui/material';
import TrimText from 'portfolio3/components/common/TrimText';
import { commonTheme } from 'portfolio3/styles/theme';
import { Checkbox } from 'portfolio3/ui-kit';
import { mergeSx, numberToPixelsString } from 'utils';

import { ILinkSetting } from '../../types';
import * as styles from './styles';

interface IChildSettingBlockProps {
  setting: ILinkSetting;
  indentLevel: number;
}

const ChildSettingBlock: FC<IChildSettingBlockProps> = ({ setting, indentLevel }) => {
  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const { isActive, isIndeterminate, name, toggle, childSettings } = setting;
  const hasChildSettings = childSettings && childSettings.length > 0;

  const desktopMarginTop = indentLevel === 1 ? '12px' : '8px';
  const mobileMarginTop = '16px';

  const desktopIndentation = indentLevel * 32;
  const mobileIndentation = indentLevel * 24;
  const indentation = numberToPixelsString(isMobile ? mobileIndentation : desktopIndentation);

  return (
    <>
      <Box
        className="sharing-child-setting-block"
        sx={mergeSx(styles.root, {
          marginTop: isMobile ? mobileMarginTop : desktopMarginTop,
          paddingLeft: indentation,
        })}
      >
        <Checkbox size="small" checked={isActive} onChange={toggle} indeterminate={isIndeterminate} />
        <Typography component={TrimText} variant="Paragraph MD/Regular" sx={styles.name}>
          {name}
        </Typography>
      </Box>
      {hasChildSettings &&
        childSettings.map((childSetting) => {
          return <ChildSettingBlock key={childSetting.name} setting={childSetting} indentLevel={indentLevel + 1} />;
        })}
    </>
  );
};

export default ChildSettingBlock;
