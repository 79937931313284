/* eslint-disable prettier/prettier */
/* eslint-disable */

import { call, put } from 'redux-saga/effects';
import { toast } from 'portfolio3/ui-kit';
import { GenericDeleteAction, GenericGetAction, GenericPostAction } from '.';

import {
  getPortfolioSubcategoriesActions,
  getStatusRewardsActions,
  getProjectFormatsActions,
  getEventKindsActions,
  getSportKindsAction,
  getTourismKindsAction,
  getCreationKindsAction,
  getCulturalKindsAction,
  getRewardKindsAction,
  postPortfolioEventActions,
  postPortfolioEmploymentActions,
  postPortfolioAffilationActions,
  editPortfolioEventActions,
  editPortfolioEmploymentActions,
  editPortfolioAffilationActions,
  postPortfolioRewardActions,
  editPortfolioRewardActions,
  postPortfolioSportRewardActions,
  editPortfolioSportRewardActions,
  postPortfolioProjectActions,
  editPortfolioProjectActions,
  getPersonsObjectsActions,
  getOrganizationsAction,
  getParallelsAction,
  deletePortfolioRewardActions,
  deletePortfolioSportRewardActions,
  deletePortfolioProjectActions,
  deletePortfolioEventActions,
  deletePortfolioEmploymentActions,
  deletePortfolioAffilationActions,
  getDisciplinesAction,
  getMuseumsAction,
  getTheatresAction,
  getCinemasAction,
  getAffilationKindsActions,
  getSportClubsAction,
  getSportRewardKindsAction,
  putAttachmentActions,
  getPersonsListActions,
  getStudentProjectsActions,
  getStudentRewardsActions,
  getStudentSportRewardsActions,
  getStudentEventsActions,
  getStudentEmploymentsActions,
  getStudentAffilationsActions,
  getHistoryActions,
  getImportHistoryActions,
  getImportReportActions,
  getImportTemplateActions,
  clearAttachmentTemlpateAction,
  postAttachmentTemplateActions,
  getImportListActions,
  getAttachmentTemplateInfoActions,
  deleteAttachmentTemplateInfoActions,
  getEventByIdActions,
  getProjectByIdActions,
  getEmploymentByIdActions,
  getAffilationByIdActions,
  getRewardByIdActions,
  getSportRewardByIdActions,
  getAttachmentTemplateInfoPartitionActions,
  deletePortfolioGiaWorldSkillsActions,
  getStudentGiaWorldSkillsActions,
  editPortfolioGiaWorldSkillsActions,
  postPortfolioGiaWorldSkillsActions,
  getSportTrainingStageAction,
  deletePortfolioJobActions,
  getStudentJobsActions,
  postPortfolioJobActions,
  editPortfolioJobActions,
  postPortfolioSpoDocumentActions,
  getStudentSpoDocumentsActions,
  editPortfolioSpoDocumentActions,
  deletePortfolioSpoDocumentActions,
} from '../actions';
import {
  getEventKinds,
  getSportKinds,
  getPortfolioSubcategories,
  getStatusRewards,
  getProjectFormats,
  postPortfolioEvent,
  postPortfolioEmployment,
  postPortfolioAffilation,
  editPortfolioEvent,
  editPortfolioEmployment,
  editPortfolioAffilation,
  getTourismKinds,
  getCreationKinds,
  getCulturalKinds,
  getRewardKinds,
  postPortfolioReward,
  editPortfolioReward,
  postPortfolioSportReward,
  editPortfolioSportReward,
  postPortfolioProject,
  editPortfolioProject,
  getPersonsObjects,
  getOrganizations,
  getParallels,
  deletePortfolioReward,
  deletePortfolioSportReward,
  deletePortfolioProject,
  deletePortfolioEvent,
  deletePortfolioEmployment,
  deletePortfolioAffilation,
  getDisciplines,
  getMuseums,
  getTheatres,
  getCinemas,
  getAffilationKinds,
  getSportClubs,
  getSportRewardKinds,
  getPersonsList,
  MAX_PAGE_SIZE,
  getHistory,
  getImportHistory,
  getImportReport,
  getImportTemplate,
  postAttachmentTemplate,
  getImportList,
  getAttachmentTemplateInfo,
  getAttachmentTemplatePartitionInfo,
  deleteAttachmentTemplateInfo,
  getEventById,
  getProjectById,
  getEmploymentById,
  getAffilationsById,
  getRewardById,
  getSportRewardById,
  postPortfolioGiaWorldSkills,
  editPortfolioGiaWorldSkills,
  deletePortfolioGiaWorldSkills,
  getGiaWorldSkillsById,
  getSportTrainingStage,
  deletePortfolioJob,
  postPortfolioJob,
  editPortfolioJob,
  postPortfolioSpoDocument,
  editPortfolioSpoDocument,
  deletePortfolioSpoDocument
} from '../api';

import {
  IEditPortfolioEventRequest,
  IEditPortfolioEmploymentRequest,
  IPostPortfolioEventRequest,
  IPostPortfolioEmploymentRequest,
  IPostPortfolioAffilationRequest,
  IEditPortfolioAffilationRequest,
  IPostPortfolioRewardRequest,
  IEditPortfolioRewardRequest,
  IPostPortfolioSportRewardRequest,
  IEditPortfolioSportRewardRequest,
  IPostPortfolioProjectRequest,
  IEditPortfolioProjectRequest,
  IPostPersonsList,
  IGetHistory,
  IGetImportHistory,
  IPostGiaWorldSkillsRequest,
  IEditGiaWorldSkillsRequest,
  PostJobRequest,
  IPostEntityRequest,
  IEditEntityRequest,
  EditJobRequest,
  PostSpoDocumentRequest,
  EditSpoDocumentRequest,
  IResponsePayload,
  SpoDocument
} from '../api/types';
import { EntityType, tooltipOptions } from '../const';
import { downloadFileFromResponse } from '../utils';
import { ApiResult } from 'api/impl';

function* getPortfolioSubcategoriesSaga({ payload: { queryParams } }: GenericGetAction<any>) {
  const { response, error } = yield call(getPortfolioSubcategories, queryParams);

  if (response) {
    yield put(getPortfolioSubcategoriesActions.success(response));
  } else {
    yield put(getPortfolioSubcategoriesActions.failure(error));
  }
}

function* getStatusRewardsSaga({ payload: { queryParams } }: GenericGetAction<any>) {
  const { response, error } = yield call(getStatusRewards, queryParams);

  if (response) {
    yield put(getStatusRewardsActions.success(response));
  } else {
    yield put(getStatusRewardsActions.failure(error));
  }
}

function* getProjectFormatsSaga({ payload: { queryParams } }: GenericGetAction<any>) {
  const { response, error } = yield call(getProjectFormats, queryParams);

  if (response) {
    yield put(getProjectFormatsActions.success(response));
  } else {
    yield put(getProjectFormatsActions.failure(error));
  }
}

function* getAffilationKindSaga({ payload: { queryParams } }: GenericGetAction<any>) {
  const { response, error } = yield call(getAffilationKinds, queryParams);

  if (response) {
    yield put(getAffilationKindsActions.success(response));
  } else {
    yield put(getAffilationKindsActions.failure(error));
  }
}

function* getEventKindSaga({ payload: { queryParams } }: GenericGetAction<any>) {
  const { response, error } = yield call(getEventKinds, queryParams);

  if (response) {
    yield put(getEventKindsActions.success(response));
  } else {
    yield put(getEventKindsActions.failure(error));
  }
}

function* getSportKindSaga({ payload: { queryParams } }: GenericGetAction<any>) {
  const { response, error } = yield call(getSportKinds, queryParams);

  if (response) {
    yield put(getSportKindsAction.success(response));
  } else {
    yield put(getSportKindsAction.failure(error));
  }
}

function* getTourismKindSaga({ payload: { queryParams } }: GenericGetAction<any>) {
  const { response, error } = yield call(getTourismKinds, queryParams);

  if (response) {
    yield put(getTourismKindsAction.success(response));
  } else {
    yield put(getTourismKindsAction.failure(error));
  }
}

function* getCreationKindSaga({ payload: { queryParams } }: GenericGetAction<any>) {
  const { response, error } = yield call(getCreationKinds, queryParams);

  if (response) {
    yield put(getCreationKindsAction.success(response));
  } else {
    yield put(getCreationKindsAction.failure(error));
  }
}

function* getCulturalKindSaga({ payload: { queryParams } }: GenericGetAction<any>) {
  const { response, error } = yield call(getCulturalKinds, queryParams);

  if (response) {
    yield put(getCulturalKindsAction.success(response));
  } else {
    yield put(getCulturalKindsAction.failure(error));
  }
}

function* getRewardKindSaga({ payload: { queryParams } }: GenericGetAction<any>) {
  const { response, error } = yield call(getRewardKinds, queryParams);

  if (response) {
    yield put(getRewardKindsAction.success(response));
  } else {
    yield put(getRewardKindsAction.failure(error));
  }
}

function* getDisciplinesSaga({ payload: { queryParams } }: GenericGetAction<any>) {
  const { response, error } = yield call(getDisciplines, queryParams);

  if (response) {
    yield put(getDisciplinesAction.success(response));
  } else {
    yield put(getDisciplinesAction.failure(error));
  }
}

function* getMuseumsSaga({ payload: { queryParams } }: GenericGetAction<any>) {
  const { response, error } = yield call(getMuseums, queryParams);

  if (response) {
    yield put(getMuseumsAction.success(response));
  } else {
    yield put(getMuseumsAction.failure(error));
  }
}

function* getTheatresSaga({ payload: { queryParams } }: GenericGetAction<any>) {
  const { response, error } = yield call(getTheatres, queryParams);

  if (response) {
    yield put(getTheatresAction.success(response));
  } else {
    yield put(getTheatresAction.failure(error));
  }
}

function* getCinemasSaga({ payload: { queryParams } }: GenericGetAction<any>) {
  const { response, error } = yield call(getCinemas, queryParams);

  if (response) {
    yield put(getCinemasAction.success(response));
  } else {
    yield put(getCinemasAction.failure(error));
  }
}

function* getSportClubsSaga({ payload: { queryParams } }: GenericGetAction<any>) {
  const { response, error } = yield call(getSportClubs, queryParams);

  if (response) {
    yield put(getSportClubsAction.success(response));
  } else {
    yield put(getSportClubsAction.failure(error));
  }
}

function* getSportTrainingStageSaga({ payload: { queryParams } }: GenericGetAction<any>) {
  const { response, error } = yield call(getSportTrainingStage, queryParams);
  if (response) {
    yield put(getSportTrainingStageAction.success(response));
  } else {
    yield put(getSportTrainingStageAction.failure(error));
  }
}

function* getSportRewardKindsSaga({ payload: { queryParams } }: GenericGetAction<any>) {
  const { response, error } = yield call(getSportRewardKinds, queryParams);

  if (response) {
    yield put(getSportRewardKindsAction.success(response));
  } else {
    yield put(getSportRewardKindsAction.failure(error));
  }
}

function* getPersonsObjectsSaga({ payload: { personId, queryParams, sectionCodes } }: GenericGetAction<any>) {
  const { response, error } = yield call(getPersonsObjects, personId, queryParams, sectionCodes);

  if (response) {
    yield put(getPersonsObjectsActions.success(response));
  } else {
    yield put(getPersonsObjectsActions.failure(error));
  }
}

function* getOrganizationsSaga({ payload: { queryParams } }: GenericGetAction<any>) {
  const { response, error } = yield call(getOrganizations, queryParams);

  if (response) {
    yield put(getOrganizationsAction.success(response));
  } else {
    yield put(getOrganizationsAction.failure(error));
  }
}

function* getParallelsSaga({ payload: { queryParams, schoolId } }: GenericGetAction<any>) {
  const { response, error } = yield call(getParallels, schoolId, queryParams);

  if (response) {
    yield put(getParallelsAction.success(response));
  } else {
    yield put(getParallelsAction.failure(error));
  }
}

function* postPortfolioRewardSaga({
  payload: {
    request, attachments, queryParams, studentType, sectionCodesString, meta
  }
}: Required<GenericGetAction<IPostPortfolioRewardRequest>>) {
  const { response, error } = yield call(postPortfolioReward, request, meta);

  if (response) {
    yield put(postPortfolioRewardActions.success(response));
    const { id } = response.data;
    if (attachments.length > 0) yield put(putAttachmentActions.request({ attachment: attachments }, id, EntityType.REWARD));
    yield put(getPersonsObjectsActions.request(request.personId, queryParams, sectionCodesString));
    if (studentType) yield put(getStudentRewardsActions.request(request.personId, { size: MAX_PAGE_SIZE }));
    toast.success('Награда добавлена', tooltipOptions);
  } else {
    yield put(postPortfolioRewardActions.failure(error));
  }
}

function* editPortfolioRewardSaga({
  payload: {
    request, id, attachments, queryParams, studentType, sectionCodesString
  }
}: Required<GenericGetAction<IEditPortfolioRewardRequest>>) {
  const { response, error } = yield call(editPortfolioReward, request, id);

  if (response) {
    yield put(editPortfolioRewardActions.success(response));
    const { id } = response.data;
    if (attachments.length > 0) yield put(putAttachmentActions.request({ attachment: attachments }, id, EntityType.REWARD));
    yield put(getPersonsObjectsActions.request(request.personId, queryParams, sectionCodesString));
    if (studentType) yield put(getStudentRewardsActions.request(request.personId, { size: MAX_PAGE_SIZE }));
    toast.success('Награда изменена', tooltipOptions);
  } else {
    yield put(editPortfolioRewardActions.failure(error));
  }
}

function* deletePortfolioRewardSaga({
  payload: {
    id,
    personId,
    queryParams,
    studentType,
    sectionCodesString
  }
}: Required<GenericDeleteAction<any>>) {
  const { response, error } = yield call(deletePortfolioReward, id);

  if (response) {
    yield put(deletePortfolioRewardActions.success(response));
    yield put(getPersonsObjectsActions.request(personId, queryParams, sectionCodesString));
    if (studentType) {
      yield put(getStudentRewardsActions.request(personId, { size: MAX_PAGE_SIZE }));
      yield put(getStudentEventsActions.request(personId, { size: MAX_PAGE_SIZE }));
      yield put(getStudentProjectsActions.request(personId, { size: MAX_PAGE_SIZE }));
    }
    toast.success('Награда удалена', tooltipOptions);
  } else {
    yield put(deletePortfolioRewardActions.failure(error));
  }
}

function* deleteEmployeeRewardSaga({
  payload: {
    id, filters
  }
}: Required<GenericDeleteAction<any>>) {
  const { response, error } = yield call(deletePortfolioReward, id);

  if (response) {
    yield put(deletePortfolioRewardActions.success(response));
    yield put(getHistoryActions.request(filters));
    toast.success('Награда удалена', tooltipOptions);
  } else {
    yield put(deletePortfolioRewardActions.failure(error));
  }
}

function* postPortfolioSportRewardSaga({
  payload: {
    request, attachments, queryParams, studentType, sectionCodesString, meta
  }
}: Required<GenericGetAction<IPostPortfolioSportRewardRequest>>) {
  const { response, error } = yield call(postPortfolioSportReward, request, meta);

  if (response) {
    yield put(postPortfolioSportRewardActions.success(response));
    const { id } = response.data;
    if (attachments.length > 0) yield put(putAttachmentActions.request({ attachment: attachments }, id, EntityType.SPORT_REWARD));
    yield put(getPersonsObjectsActions.request(request.personId, queryParams, sectionCodesString));
    if (studentType) {
      yield put(getStudentSportRewardsActions.request(request.personId, { size: MAX_PAGE_SIZE }));
      yield put(getStudentEventsActions.request(request.personId, { size: MAX_PAGE_SIZE }));
    }
    toast.success('Награда добавлена', tooltipOptions);
  } else {
    yield put(postPortfolioSportRewardActions.failure(error));
  }
}

function* editPortfolioSportRewardSaga({
  payload: {
    request, id, attachments, queryParams, studentType, sectionCodesString
  }
}: Required<GenericGetAction<IEditPortfolioSportRewardRequest>>) {
  const { response, error } = yield call(editPortfolioSportReward, request, id);

  if (response) {
    yield put(editPortfolioSportRewardActions.success(response));
    const { id } = response.data;
    if (attachments.length > 0) yield put(putAttachmentActions.request({ attachment: attachments }, id, EntityType.SPORT_REWARD));
    yield put(getPersonsObjectsActions.request(request.personId, queryParams, sectionCodesString));
    if (studentType) yield put(getStudentSportRewardsActions.request(request.personId, { size: MAX_PAGE_SIZE }));
    toast.success('Награда изменена', tooltipOptions);
  } else {
    yield put(editPortfolioSportRewardActions.failure(error));
  }
}

function* deletePortfolioSportRewardSaga({
  payload: {
    id, personId, queryParams, studentType, sectionCodesString
  }
}: Required<GenericDeleteAction<any>>) {
  const { response, error } = yield call(deletePortfolioSportReward, id);

  if (response) {
    yield put(deletePortfolioSportRewardActions.success(response));
    yield put(getPersonsObjectsActions.request(personId, queryParams, sectionCodesString));
    if (studentType) yield put(getStudentSportRewardsActions.request(personId, { size: MAX_PAGE_SIZE }));
    toast.success('Награда удалена', tooltipOptions);
  } else {
    yield put(deletePortfolioSportRewardActions.failure(error));
  }
}

function* deleteEmployeeSportRewardSaga({
  payload: {
    id, filters
  }
}: Required<GenericDeleteAction<any>>) {
  const { response, error } = yield call(deletePortfolioSportReward, id);

  if (response) {
    yield put(deletePortfolioSportRewardActions.success(response));
    yield put(getHistoryActions.request(filters));
    toast.success('Награда удалена', tooltipOptions);
  } else {
    yield put(deletePortfolioSportRewardActions.failure(error));
  }
}

function* deleteEmployeeGiaWorldSkillsSaga({
  payload: {
    id, filters
  }
}: Required<GenericDeleteAction<any>>) {
  const { response, error } = yield call(deletePortfolioGiaWorldSkills, id);

  if (response) {
    yield put(deletePortfolioGiaWorldSkillsActions.success(response));
    yield put(getHistoryActions.request(filters));
    toast.success('Демонстрационный экзамен удален', tooltipOptions);
  } else {
    yield put(deletePortfolioGiaWorldSkillsActions.failure(error));
  }
}

function* postPortfolioProjectSaga({
  payload: {
    request, attachments, queryParams, studentType, sectionCodesString, meta
  }
}: Required<GenericGetAction<IPostPortfolioProjectRequest>>) {
  const { response, error } = yield call(postPortfolioProject, request, meta);

  if (response) {
    yield put(postPortfolioProjectActions.success(response));
    const { id } = response.data;
    if (attachments.length > 0) yield put(putAttachmentActions.request({ attachment: attachments }, id, EntityType.PROJECT));
    yield put(getPersonsObjectsActions.request(request.personId, queryParams, sectionCodesString));
    if (studentType) yield put(getStudentProjectsActions.request(request.personId, { size: MAX_PAGE_SIZE }));
    toast.success('Проект добавлен', tooltipOptions);
  } else {
    yield put(postPortfolioProjectActions.failure(error));
  }
}

function* editPortfolioProjectSaga({
  payload: {
    request, id, attachments, queryParams, studentType, sectionCodesString
  }
}: Required<GenericGetAction<IEditPortfolioProjectRequest>>) {
  const { response, error } = yield call(editPortfolioProject, request, id);

  if (response) {
    yield put(editPortfolioProjectActions.success(response));
    const { id } = response.data;
    if (attachments.length > 0) yield put(putAttachmentActions.request({ attachment: attachments }, id, EntityType.PROJECT));
    if (studentType) yield put(getStudentProjectsActions.request(request.personId, { size: MAX_PAGE_SIZE }));
    yield put(getPersonsObjectsActions.request(request.personId, queryParams, sectionCodesString));
    toast.success('Проект изменен', tooltipOptions);
  } else {
    yield put(editPortfolioProjectActions.failure(error));
  }
}

function* deletePortfolioProjectSaga({
  payload: {
    id, personId, queryParams, studentType, sectionCodesString
  }
}: Required<GenericDeleteAction<any>>) {
  const { response, error } = yield call(deletePortfolioProject, id);

  if (response) {
    yield put(deletePortfolioProjectActions.success(response));
    yield put(getPersonsObjectsActions.request(personId, queryParams, sectionCodesString));
    if (studentType) yield put(getStudentProjectsActions.request(personId, { size: MAX_PAGE_SIZE }));
    toast.success('Проект удален', tooltipOptions);
  } else {
    yield put(deletePortfolioProjectActions.failure(error));
  }
}

function* deleteEmployeeProjectSaga({
  payload: {
    id, filters
  }
}: Required<GenericDeleteAction<any>>) {
  const { response, error } = yield call(deletePortfolioProject, id);

  if (response) {
    yield put(deletePortfolioProjectActions.success(response));
    yield put(getHistoryActions.request(filters));
    toast.success('Проект удален', tooltipOptions);
  } else {
    yield put(deletePortfolioProjectActions.failure(error));
  }
}

function* postPortfolioEventSaga({
  payload: {
    request, attachments, queryParams, studentType, sectionCodesString, meta
  }
}: Required<GenericGetAction<IPostPortfolioEventRequest>>) {
  const { response, error } = yield call(postPortfolioEvent, request, meta);

  if (response) {
    yield put(postPortfolioEventActions.success(response));
    const { id } = response.data;
    if (attachments.length > 0) yield put(putAttachmentActions.request({ attachment: attachments }, id, EntityType.EVENT));
    yield put(getPersonsObjectsActions.request(request.personId, queryParams, sectionCodesString));
    if (studentType) yield put(getStudentEventsActions.request(request.personId, { size: MAX_PAGE_SIZE }));
    toast.success('Мероприятие добавлено', tooltipOptions);
  } else {
    yield put(postPortfolioEventActions.failure(error));
  }
}

function* editPortfolioEventSaga({
  payload: {
    request, id, attachments, queryParams, studentType, sectionCodesString
  }
}: Required<GenericGetAction<IEditPortfolioEventRequest>>) {
  const { response, error } = yield call(editPortfolioEvent, request, id);

  if (response) {
    yield put(editPortfolioEventActions.success(response));
    const { id } = response.data;
    if (attachments.length > 0) yield put(putAttachmentActions.request({ attachment: attachments }, id, EntityType.EVENT));
    yield put(getPersonsObjectsActions.request(request.personId, queryParams, sectionCodesString));
    if (studentType) yield put(getStudentEventsActions.request(request.personId, { size: MAX_PAGE_SIZE }));
    toast.success('Мероприятие изменено', tooltipOptions);
  } else {
    yield put(editPortfolioEventActions.failure(error));
  }
}

function* deletePortfolioEventSaga({
  payload: {
    id, personId, queryParams, studentType, sectionCodesString
  }
}: Required<GenericDeleteAction<any>>) {
  const { response, error } = yield call(deletePortfolioEvent, id);

  if (response) {
    yield put(deletePortfolioEventActions.success(response));
    yield put(getPersonsObjectsActions.request(personId, queryParams, sectionCodesString));
    if (studentType) yield put(getStudentEventsActions.request(personId, { size: MAX_PAGE_SIZE }));
    toast.success('Мероприятие удалено', tooltipOptions);
  } else {
    yield put(deletePortfolioEventActions.failure(error));
  }
}

function* deleteEmployeeEventSaga({
  payload: {
    id, filters
  }
}: Required<GenericDeleteAction<any>>) {
  const { response, error } = yield call(deletePortfolioEvent, id);

  if (response) {
    yield put(deletePortfolioEventActions.success(response));
    yield put(getHistoryActions.request(filters));
    toast.success('Мероприятие удалено', tooltipOptions);
  } else {
    yield put(deletePortfolioEventActions.failure(error));
  }
}

function* postPortfolioEmploymentSaga({
  payload: {
    request, attachments, queryParams, studentType, sectionCodesString, meta
  }
}: Required<GenericGetAction<IPostPortfolioEmploymentRequest>>) {
  const { response, error } = yield call(postPortfolioEmployment, request, meta);

  if (response) {
    yield put(postPortfolioEmploymentActions.success(response));
    const { id } = response.data;
    if (attachments.length > 0) yield put(putAttachmentActions.request({ attachment: attachments }, id, EntityType.EMPLOYMENT));
    yield put(getPersonsObjectsActions.request(request.personId, queryParams, sectionCodesString));
    if (studentType) yield put(getStudentEmploymentsActions.request(request.personId, { size: MAX_PAGE_SIZE }));
    toast.success('Занятие добавлено', tooltipOptions);
  } else {
    yield put(postPortfolioEmploymentActions.failure(error));
  }
}

function* editPortfolioEmploymentSaga({
  payload: {
    request, id, attachments, queryParams, studentType, sectionCodesString
  }
}: Required<GenericGetAction<IEditPortfolioEmploymentRequest>>) {
  const { response, error } = yield call(editPortfolioEmployment, request, id);

  if (response) {
    yield put(editPortfolioEmploymentActions.success(response));
    const { id } = response.data;
    if (attachments.length > 0) yield put(putAttachmentActions.request({ attachment: attachments }, id, EntityType.EMPLOYMENT));
    yield put(getPersonsObjectsActions.request(request.personId, queryParams, sectionCodesString));
    if (studentType) yield put(getStudentEmploymentsActions.request(request.personId, { size: MAX_PAGE_SIZE }));
    toast.success('Занятие изменено', tooltipOptions);
  } else {
    yield put(editPortfolioEmploymentActions.failure(error));
  }
}

function* deletePortfolioEmploymentSaga({
  payload: {
    id, personId, queryParams, studentType, sectionCodesString
  }
}: Required<GenericDeleteAction<any>>) {
  const { response, error } = yield call(deletePortfolioEmployment, id);

  if (response) {
    yield put(deletePortfolioEmploymentActions.success(response));
    yield put(getPersonsObjectsActions.request(personId, queryParams, sectionCodesString));
    if (studentType) yield put(getStudentEmploymentsActions.request(personId, { size: MAX_PAGE_SIZE }));
    toast.success('Занятие удалено', tooltipOptions);
  } else {
    yield put(deletePortfolioEmploymentActions.failure(error));
  }
}

function* deleteEmployeeEmploymentSaga({
  payload: {
    id, filters
  }
}: Required<GenericDeleteAction<any>>) {
  const { response, error } = yield call(deletePortfolioEmployment, id);

  if (response) {
    yield put(deletePortfolioEmploymentActions.success(response));
    yield put(getHistoryActions.request(filters));
    toast.success('Занятие удалено', tooltipOptions);
  } else {
    yield put(deletePortfolioEmploymentActions.failure(error));
  }
}

function* postPortfolioAffilationSaga({
  payload: {
    request, attachments, queryParams, studentType, sectionCodesString, meta
  }
}: Required<GenericGetAction<IPostPortfolioAffilationRequest>>) {
  const { response, error } = yield call(postPortfolioAffilation, request, meta);

  if (response) {
    yield put(postPortfolioAffilationActions.success(response));
    const { id } = response.data;
    if (attachments.length > 0) yield put(putAttachmentActions.request({ attachment: attachments }, id, EntityType.AFFILATION));
    yield put(getPersonsObjectsActions.request(request.personId, queryParams, sectionCodesString));
    if (studentType) yield put(getStudentAffilationsActions.request(request.personId, { size: MAX_PAGE_SIZE }));
    toast.success('Принадлежность добавлена', tooltipOptions);
  } else {
    yield put(postPortfolioAffilationActions.failure(error));
  }
}

function* editPortfolioAffilationSaga({
  payload: {
    request, id, attachments, queryParams, studentType, sectionCodesString
  }
}: Required<GenericGetAction<IEditPortfolioAffilationRequest>>) {
  const { response, error } = yield call(editPortfolioAffilation, request, id);

  if (response) {
    yield put(editPortfolioAffilationActions.success(response));
    const { id } = response.data;
    if (attachments.length > 0) yield put(putAttachmentActions.request({ attachment: attachments }, id, EntityType.AFFILATION));
    yield put(getPersonsObjectsActions.request(request.personId, queryParams, sectionCodesString));
    if (studentType) yield put(getStudentAffilationsActions.request(request.personId, { size: MAX_PAGE_SIZE }));
    toast.success('Принадлежность изменена', tooltipOptions);
  } else {
    yield put(editPortfolioAffilationActions.failure(error));
  }
}

function* deletePortfolioAffilationSaga({
  payload: {
    id, personId, queryParams, studentType, sectionCodesString
  }
}: Required<GenericDeleteAction<any>>) {
  const { response, error } = yield call(deletePortfolioAffilation, id);

  if (response) {
    yield put(deletePortfolioAffilationActions.success(response));
    yield put(getPersonsObjectsActions.request(personId, queryParams, sectionCodesString));
    if (studentType) yield put(getStudentAffilationsActions.request(personId, { size: MAX_PAGE_SIZE }));
    toast.success('Принадлежность удалена', tooltipOptions);
  } else {
    yield put(deletePortfolioAffilationActions.failure(error));
  }
}

function* deleteEmployeeAffilationSaga({
  payload: {
    id, filters
  }
}: Required<GenericDeleteAction<any>>) {
  const { response, error } = yield call(deletePortfolioAffilation, id);

  if (response) {
    yield put(deletePortfolioAffilationActions.success(response));
    yield put(getHistoryActions.request(filters));
    toast.success('Принадлежность удалена', tooltipOptions);
  } else {
    yield put(deletePortfolioAffilationActions.failure(error));
  }
}

function* postPortfolioGiaWorldSkillsSaga({
  payload: {
    request, attachments, queryParams, studentType, sectionCodesString, meta
  }
}: Required<GenericGetAction<IPostGiaWorldSkillsRequest>>) {
  const { response, error } = yield call(postPortfolioGiaWorldSkills, request, meta);

  if (response) {
    yield put(postPortfolioGiaWorldSkillsActions.success(response));
    const { id } = response.data;
    if (attachments.length > 0) yield put(putAttachmentActions.request({ attachment: attachments }, id, EntityType.GIA_WORLDSKILLS));
    yield put(getPersonsObjectsActions.request(request.personId, queryParams, sectionCodesString));
    if (studentType) yield put(getStudentGiaWorldSkillsActions.request(request.personId, { size: MAX_PAGE_SIZE }));
    toast.success('Демонстрационный экзамен добавлен', tooltipOptions);
  } else {
    yield put(postPortfolioGiaWorldSkillsActions.failure(error));
  }
}

function* editPortfolioGiaWorldSkillsSaga({
  payload: {
    request, id, attachments, queryParams, studentType, sectionCodesString
  }
}: Required<GenericGetAction<IEditGiaWorldSkillsRequest>>) {
  const { response, error } = yield call(editPortfolioGiaWorldSkills, request, id);

  if (response) {
    yield put(editPortfolioGiaWorldSkillsActions.success(response));
    const { id } = response.data;
    if (attachments.length > 0) yield put(putAttachmentActions.request({ attachment: attachments }, id, EntityType.GIA_WORLDSKILLS));
    yield put(getPersonsObjectsActions.request(request.personId, queryParams, sectionCodesString));
    if (studentType) yield put(getStudentGiaWorldSkillsActions.request(request.personId, { size: MAX_PAGE_SIZE }));
    toast.success('Демонстрационный экзамен изменен', tooltipOptions);
  } else {
    yield put(editPortfolioGiaWorldSkillsActions.failure(error));
  }
}

function* deletePortfolioGiaWorldSkillsSaga({
  payload: {
    id, personId, queryParams, studentType, sectionCodesString
  }
}: Required<GenericDeleteAction<any>>) {
  const { response, error } = yield call(deletePortfolioGiaWorldSkills, id);

  if (response) {
    yield put(deletePortfolioGiaWorldSkillsActions.success(response));
    yield put(getPersonsObjectsActions.request(personId, queryParams, sectionCodesString));
    if (studentType) yield put(getStudentGiaWorldSkillsActions.request(personId, { size: MAX_PAGE_SIZE }));
    toast.success('Демонстрационный экзамен удален', tooltipOptions);
  } else {
    yield put(deletePortfolioGiaWorldSkillsActions.failure(error));
  }
}

function* postPortfolioSpoDocumentSaga({
  payload: {
    request, attachments, queryParams, studentType, sectionCodesString, meta
  }
}: Required<GenericGetAction<IPostEntityRequest<PostSpoDocumentRequest>>>) {
  const { response, error }: ApiResult<IResponsePayload<SpoDocument>, any> = yield call(postPortfolioSpoDocument, request, meta);

  if (response) {
    yield put(postPortfolioSpoDocumentActions.success(response));
    const { id } = response.data;
    if (attachments.length > 0) yield put(putAttachmentActions.request({ attachment: attachments }, id.toString(), EntityType.DOCUMENT));
    yield put(getPersonsObjectsActions.request(request.personId, queryParams, sectionCodesString));
    if (studentType) yield put(getStudentSpoDocumentsActions.request(request.personId));
    toast.success('Документ успешно добавлен', tooltipOptions);
  } else {
    yield put(postPortfolioSpoDocumentActions.failure(error));
  }
}

function* editPortfolioSpoDocumentSaga({
  payload: {
    request, id, attachments, queryParams, studentType, sectionCodesString
  }
}: Required<GenericGetAction<IEditEntityRequest<EditSpoDocumentRequest>>>) {
  const { response, error } = yield call(editPortfolioSpoDocument, request, id);

  if (response) {
    yield put(editPortfolioSpoDocumentActions.success(response));
    const { id } = response.data;
    if (attachments.length > 0) yield put(putAttachmentActions.request({ attachment: attachments }, id, EntityType.DOCUMENT));
    yield put(getPersonsObjectsActions.request(request.personId, queryParams, sectionCodesString));
    if (studentType) yield put(getStudentSpoDocumentsActions.request(request.personId));
    toast.success('Документ изменен', tooltipOptions);
  } else {
    yield put(editPortfolioSpoDocumentActions.failure(error));
  }
}

function* deletePortfolioSpoDocumentSaga({
  payload: {
    id, personId, queryParams, studentType, sectionCodesString
  }
}: Required<GenericDeleteAction<any>>) {
  const { response, error } = yield call(deletePortfolioSpoDocument, id);

  if (response) {
    yield put(deletePortfolioSpoDocumentActions.success(response));
    yield put(getPersonsObjectsActions.request(personId, queryParams, sectionCodesString));
    if (studentType) yield put(getStudentSpoDocumentsActions.request(personId));
    toast.success('Документ удален', tooltipOptions);
  } else {
    yield put(deletePortfolioSpoDocumentActions.failure(error));
  }
}

function* postPortfolioJobSaga({
  payload: {
    request, attachments, queryParams, studentType, sectionCodesString, meta
  }
}: Required<GenericGetAction<IPostEntityRequest<PostJobRequest>>>) {
  const { response, error } = yield call(postPortfolioJob, request, meta);

  if (response) {
    yield put(postPortfolioJobActions.success(response));
    const { id } = response.data;
    if (attachments.length > 0) yield put(putAttachmentActions.request({ attachment: attachments }, id, EntityType.JOB));
    yield put(getPersonsObjectsActions.request(request.personId, queryParams, sectionCodesString));
    if (studentType) yield put(getStudentJobsActions.request(request.personId));
    toast.success('Трудоустройство успешно добавлено', tooltipOptions);
  } else {
    yield put(postPortfolioJobActions.failure(error));
  }
}

function* editPortfolioJobSaga({
  payload: {
    request, id, attachments, queryParams, studentType, sectionCodesString
  }
}: Required<GenericGetAction<IEditEntityRequest<EditJobRequest>>>) {
  const { response, error } = yield call(editPortfolioJob, request, id);

  if (response) {
    yield put(editPortfolioJobActions.success(response));
    const { id } = response.data;
    if (attachments.length > 0) yield put(putAttachmentActions.request({ attachment: attachments }, id, EntityType.JOB));
    yield put(getPersonsObjectsActions.request(request.personId, queryParams, sectionCodesString));
    if (studentType) yield put(getStudentJobsActions.request(request.personId));
    toast.success('Трудоустройство изменено', tooltipOptions);
  } else {
    yield put(editPortfolioJobActions.failure(error));
  }
}

function* deletePortfolioJobSaga({
  payload: {
    id, personId, queryParams, studentType, sectionCodesString
  }
}: Required<GenericDeleteAction<any>>) {
  const { response, error } = yield call(deletePortfolioJob, id);

  if (response) {
    yield put(deletePortfolioJobActions.success(response));
    yield put(getPersonsObjectsActions.request(personId, queryParams, sectionCodesString));
    if (studentType) yield put(getStudentJobsActions.request(personId));
    toast.success('Трудоустройство удалено', tooltipOptions);
  } else {
    yield put(deletePortfolioJobActions.failure(error));
  }
}

function* getPersonsListSaga({ payload: { request } }: Required<GenericGetAction<IPostPersonsList>>) {
  const { response, error } = yield call(getPersonsList, request);

  if (response) {
    yield put(getPersonsListActions.success(response));
  } else {
    yield put(getPersonsListActions.failure(error));
  }
}

function* getHistorySaga({ payload: { request } }: Required<GenericGetAction<IGetHistory>>) {
  const { response, error } = yield call(getHistory, request);

  if (response) {
    yield put(getHistoryActions.success(response));
  } else {
    yield put(getHistoryActions.failure(error));
  }
}

function* getImportHistorySaga({ payload: { request } }: Required<GenericGetAction<IGetImportHistory>>) {
  const { response, error } = yield call(getImportHistory, request);

  if (response) {
    yield put(getImportHistoryActions.success(response));
  } else {
    yield put(getImportHistoryActions.failure(error));
  }
}

function* getImportReportSaga({ payload: { id } }: GenericGetAction<any>) {
  const { response, error } = yield call(getImportReport, id);

  if (response) {
    yield put(getImportReportActions.success(response));
    downloadFileFromResponse('report.xlsx', response);
  } else {
    yield put(getImportReportActions.failure(error));
  }
}

function* getImportTemplateSaga({ payload: { categoryCode, dataKind, typeCode } }: GenericGetAction<any>) {
  const { response, error } = yield call(getImportTemplate, categoryCode, dataKind, typeCode);

  if (response) {
    yield put(getImportTemplateActions.success(response));
    downloadFileFromResponse('template.xlsx', response);
  } else {
    yield put(getImportTemplateActions.failure(error));
  }
}

function* postAttachmentTemplateSaga({ payload: formData, requestHeaders }: GenericPostAction<any>) {
  const { response, error } = yield call(postAttachmentTemplate, formData, requestHeaders);

  if (response) {
    yield put(postAttachmentTemplateActions.success(response));
    yield put(getImportListActions.request());
  } else {
    yield put(postAttachmentTemplateActions.failure(error));
  }
}

function* getAttachmentTemplateInfoSaga({ payload: uuid }: GenericGetAction<any>) {
  const { response, error } = yield call(getAttachmentTemplateInfo, uuid);

  if (response) {
    yield put(getAttachmentTemplateInfoActions.success(response));
  } else {
    yield put(getAttachmentTemplateInfoActions.failure(error));
  }
}

function* getAttachmentTemplatePartitionInfoSaga({ payload: { partition, uuid } }: GenericGetAction<any>) {
  const { response, error } = yield call(getAttachmentTemplatePartitionInfo, partition, uuid);

  if (response) {
    yield put(getAttachmentTemplateInfoPartitionActions.success(response));
  } else {
    yield put(getAttachmentTemplateInfoPartitionActions.failure(error));
  }
}

function* deleteAttachmentTemplateInfoSaga({ payload: uuid }: GenericGetAction<any>) {
  yield put(clearAttachmentTemlpateAction());
  const { response, error } = yield call(deleteAttachmentTemplateInfo, uuid);

  if (response) {
    yield put(deleteAttachmentTemplateInfoActions.success(response));
    yield put(getImportListActions.request());
  } else {
    yield put(deleteAttachmentTemplateInfoActions.failure(error));
  }
}

function* getImportListSaga() {
  const { response, error } = yield call(getImportList);

  if (response) {
    yield put(getImportListActions.success(response));
  } else {
    yield put(getImportListActions.failure(error));
  }
}

function* getEventByIdSaga({ payload: { id } }: Required<GenericGetAction<any>>) {
  const { response, error } = yield call(getEventById, id);
  if (response) {
    yield put(getEventByIdActions.success(response));
  } else {
    yield put(getEventByIdActions.failure(error));
  }
}

function* getProjectByIdSaga({ payload: { id } }: Required<GenericGetAction<any>>) {
  const { response, error } = yield call(getProjectById, id);
  if (response) {
    yield put(getProjectByIdActions.success(response));
  } else {
    yield put(getProjectByIdActions.failure(error));
  }
}

function* getEmploymentByIdSaga({ payload: { id } }: Required<GenericGetAction<any>>) {
  const { response, error } = yield call(getEmploymentById, id);
  if (response) {
    yield put(getEmploymentByIdActions.success(response));
  } else {
    yield put(getEmploymentByIdActions.failure(error));
  }
}

function* getAffilationByIdSaga({ payload: { id } }: Required<GenericGetAction<any>>) {
  const { response, error } = yield call(getAffilationsById, id);
  if (response) {
    yield put(getAffilationByIdActions.success(response));
  } else {
    yield put(getAffilationByIdActions.failure(error));
  }
}

function* getRewardByIdSaga({ payload: { id } }: Required<GenericGetAction<any>>) {
  const { response, error } = yield call(getRewardById, id);
  if (response) {
    yield put(getRewardByIdActions.success(response));
  } else {
    yield put(getRewardByIdActions.failure(error));
  }
}

function* getSportRewardByIdSaga({ payload: { id } }: Required<GenericGetAction<any>>) {
  const { response, error } = yield call(getSportRewardById, id);
  if (response) {
    yield put(getSportRewardByIdActions.success(response));
  } else {
    yield put(getSportRewardByIdActions.failure(error));
  }
}

function* getGiaWorldSkillsByIdSaga({ payload: { id } }: Required<GenericGetAction<any>>) {
  const { response, error } = yield call(getGiaWorldSkillsById, id);
  if (response) {
    yield put(getSportRewardByIdActions.success(response));
  } else {
    yield put(getSportRewardByIdActions.failure(error));
  }
}

export {
  getPortfolioSubcategoriesSaga,
  getStatusRewardsSaga,
  getProjectFormatsSaga,
  getAffilationKindSaga,
  getEventKindSaga,
  getSportKindSaga,
  getTourismKindSaga,
  getCreationKindSaga,
  getCulturalKindSaga,
  getRewardKindSaga,
  getDisciplinesSaga,
  getPersonsObjectsSaga,
  getOrganizationsSaga,
  getParallelsSaga,
  getMuseumsSaga,
  getTheatresSaga,
  getCinemasSaga,
  getSportClubsSaga,
  getSportTrainingStageSaga,
  getSportRewardKindsSaga,
  getImportTemplateSaga,
  getImportListSaga,
  getAttachmentTemplateInfoSaga,
  getAttachmentTemplatePartitionInfoSaga,
  postAttachmentTemplateSaga,
  postPortfolioRewardSaga,
  editPortfolioRewardSaga,
  postPortfolioSportRewardSaga,
  editPortfolioSportRewardSaga,
  postPortfolioProjectSaga,
  editPortfolioProjectSaga,
  postPortfolioEventSaga,
  postPortfolioEmploymentSaga,
  postPortfolioAffilationSaga,
  postPortfolioGiaWorldSkillsSaga,
  editPortfolioAffilationSaga,
  editPortfolioEventSaga,
  editPortfolioEmploymentSaga,
  editPortfolioGiaWorldSkillsSaga,
  getPersonsListSaga,
  deletePortfolioRewardSaga,
  deletePortfolioSportRewardSaga,
  deletePortfolioEventSaga,
  deletePortfolioEmploymentSaga,
  deletePortfolioAffilationSaga,
  deletePortfolioProjectSaga,
  deletePortfolioGiaWorldSkillsSaga,
  postPortfolioSpoDocumentSaga,
  editPortfolioSpoDocumentSaga,
  deletePortfolioSpoDocumentSaga,
  postPortfolioJobSaga,
  editPortfolioJobSaga,
  deletePortfolioJobSaga,
  deleteAttachmentTemplateInfoSaga,
  deleteEmployeeAffilationSaga,
  deleteEmployeeEmploymentSaga,
  deleteEmployeeEventSaga,
  deleteEmployeeProjectSaga,
  deleteEmployeeRewardSaga,
  deleteEmployeeSportRewardSaga,
  deleteEmployeeGiaWorldSkillsSaga,
  getHistorySaga,
  getImportHistorySaga,
  getImportReportSaga,
  getEventByIdSaga,
  getProjectByIdSaga,
  getEmploymentByIdSaga,
  getAffilationByIdSaga,
  getSportRewardByIdSaga,
  getRewardByIdSaga,
  getGiaWorldSkillsByIdSaga
};
