import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root: SxStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  gap: '16px',
  padding: '20px',

  border: '1px solid',
  borderColor: NeutralColors.light_neutrals_300,
  borderRadius: '12px',
};

export const content: SxStyles = {
  maxWidth: '544x',
};

export const image = (imageSrc: string): SxStyles => ({
  alignSelf: 'center',

  minWidth: '200px',
  minHeight: '200px',
  height: '200px',

  backgroundImage: `url(${imageSrc})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: '100%',
});

export const specialityContainer: SxStyles = (theme) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',

  justifyContent: 'space-between',
  width: 'fit-content',

  [theme.breakpoints.down('commonSm')]: {
    width: '100%',
  },
});
