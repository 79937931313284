import { FC, PropsWithChildren } from 'react';

import { Box, useMediaQuery } from '@mui/material';
import { commonTheme } from 'portfolio3/styles/theme';
import { SxStyles } from 'types';
import { mergeSx } from 'utils';

import { backgroundFullHeightStyles } from '../utils';
import BackgroundIcons from './BackgroundIcons';

const overlay: SxStyles = {
  zIndex: -1,
  position: 'fixed',
  width: '100vw',
  height: '100vh',
};

const iconsGroup1: SxStyles = {
  position: 'fixed',
  top: '173px',
  left: '-46px',
};

const iconsGroup2: SxStyles = {
  position: 'fixed',
  top: '351px',
  right: '19px',

  transform: 'rotate(-180deg)',
};

interface IImageBackgroundProps {
  image: string;
}

const ImageBackground: FC<IImageBackgroundProps & PropsWithChildren> = ({ image, children }) => {
  const isSmallScreen = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  return (
    <Box className="image-background" sx={backgroundFullHeightStyles}>
      <Box
        className="image-background__overlay"
        sx={mergeSx(overlay, {
          backgroundImage: `url(${image})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        })}
      />
      {!isSmallScreen && (
        <>
          <BackgroundIcons sx={iconsGroup1} />
          <BackgroundIcons sx={iconsGroup2} />
        </>
      )}
      {children}
    </Box>
  );
};

export default ImageBackground;
