import {
  IconCreation,
  IconCulture,
  IconProfession,
  IconProfile,
  IconSchool,
  IconScience,
  IconSport,
  IconStar,
} from 'icons';
import {
  AirSports,
  CivilEcology,
  CivilLocalHistory,
  CivilOther,
  CivilPatriotic,
  CivilVolunteering,
  ContactSports,
  CreationChoreohraphy,
  CreationCrafts,
  CreationFineArt,
  CreationLiterature,
  CreationMusic,
  CreationTheatreCircusCinema,
  CultureCinema,
  CultureConcert,
  CultureEducation,
  CultureExcursion,
  CultureExhibition,
  CultureInstitution,
  CultureMuseum,
  CultureOnlineExcursion,
  CultureOnlineExhibition,
  CultureOnlineLecture,
  CultureOnlineOther,
  CultureOther,
  CultureTheatre,
  GroundSports,
  HeavySports,
  LightSports,
  LogicSports,
  OtherSports,
  ProfessionArtist,
  ProfessionBankAgent,
  ProfessionCarDriver,
  ProfessionChemist,
  ProfessionComputerOperator,
  ProfessionConfectioner,
  ProfessionCook,
  ProfessionDigitalCurator,
  ProfessionDraftman,
  ProfessionDrones,
  ProfessionGuide,
  ProfessionManicurist,
  ProfessionMechanic,
  ProfessionOther,
  ProfessionPhotographer,
  ProfessionPoliceman,
  ScienceEngineer,
  ScienceHumanitarian,
  ScienceIt,
  ScienceLanguage,
  ScienceNatural,
  ScienceOther,
  SingleSports,
  // StudyArt,
  // StudyHumanitarian,
  // StudyMath,
  StudyOther,
  // StudyPhilology,
  // StudyPhysical,
  // StudyScience,
  // StudyWork,
  TargetSports,
  TransportSports,
  WaterSports,
  WinterSports,
} from 'portfolio3/components/entityImages';
import { AccentColorType } from 'portfolio3/styles';

import { IDictionaryItem, SectionRefItem } from '../api/types';
import {
  AdminSectionSettingCodes,
  EntityType,
  ProffClassesCodes,
  SectionCodeColors,
  SectionCodes,
  SectionColorsFallback,
  SectionKeys,
  SectionNames,
} from '../const';
import { ActivityFormatCodes, ActivityFormatNames } from '../const/activityFormat';
import {
  CHOREOHRAPHY_CREATION,
  CRAFTS_CREATION,
  FINE_ART_CREATION,
  LITERATURE_CREATION,
  MUSIC_CREATION,
  THEATRE_CREATION,
} from '../const/creationKind';
import { subcategoryCultureCodes, subcategoryCultureKeys } from '../const/eventKind';
import {
  airSports,
  contactSports,
  groundSports,
  heavySports,
  lightSports,
  logicSports,
  singleSports,
  targetSports,
  transportSports,
  waterSports,
  winterSports,
} from '../const/sportKind';
import { subcategoryCodes, subcategoryKeys } from '../const/subcategory';
import {
  achievement1_Unarmy,
  achievement2_UnTraveller,
  achievement3_Sport,
  achievement4_Spofdo,
  achievement5_SouzMol,
  achievement6_Sodruzhestvo,
  achievement7_Rdsh,
  achievement8_First,
  achievement9_Orur,
  achievement10_Nosd,
  achievement11_MzhkRossii,
  achievement12_MoldEdinstvo,
  achievement13_MolObed,
  achievement14_IntelBudush,
  achievement15_Dimsi,
  achievement16_CentrlPodderzhki,
  achievement17_Ads,
  classAcademic,
  classCadet,
  classEngineering,
  classEntrepreneurial,
  classIt,
  classKurchatov,
  classLinguistic,
  classMathematical,
  classMathematicalPlus,
  classMedia,
  classMedical,
  classNaturalScience,
  classPedagogical,
  classSportClass,
  classSportVertical,
} from '../images';
import { CategoryProperties, EntityFlag, IEntityColors, IEntityFeature } from '../types';
import { getEntityHeaderText } from './dataEntryForm';

export const getEntityColors = (entityCategoryCode: number): IEntityColors =>
  SectionCodeColors[entityCategoryCode] || SectionColorsFallback;

export const getEntityFallbackColors = () => SectionColorsFallback;

export const getActivityFormatName = (formatCode: number | undefined) => {
  switch (formatCode) {
    case ActivityFormatCodes.inPerson:
      return ActivityFormatNames.inPerson;
    case ActivityFormatCodes.inAbsentia:
      return ActivityFormatNames.inAbsentia;
    case ActivityFormatCodes.mixed:
      return ActivityFormatNames.mixed;
    case ActivityFormatCodes.distant:
      return ActivityFormatNames.distant;
  }
  return null;
};

export const getEntityFeatureLabel = (sectionRef: SectionRefItem[], typeCode: number) => {
  const sectionRefItem = sectionRef.find((ref) => ref.code === typeCode);
  const entityFeatureLabel = sectionRefItem?.shortValue ?? sectionRefItem?.value;
  return entityFeatureLabel;
};

export const getEntityFeatures = (
  sectionRef: SectionRefItem[],
  typeCode: number,
  level: IDictionaryItem | null = null,
  format: IDictionaryItem | null = null,
) => {
  const entityFeatures: IEntityFeature[] = [];

  const entityFeatureLabel = getEntityFeatureLabel(sectionRef, typeCode);
  const formatName = getActivityFormatName(format?.code);

  if (entityFeatureLabel) entityFeatures.push({ text: entityFeatureLabel, isColored: true });
  if (level) entityFeatures.push({ text: level.value.toLowerCase() });
  if (formatName) entityFeatures.push({ text: formatName });

  return entityFeatures;
};

export const getEntityFeaturesOld = (
  typeCode: number,
  level: IDictionaryItem | null = null,
  format: IDictionaryItem | null = null,
) => {
  const entityFeatureLabel = getEntityDataTypeBadge(typeCode);
  const formatName = getActivityFormatName(format?.code);

  const entityFeatures: IEntityFeature[] = [
    ...(entityFeatureLabel ? [{ text: entityFeatureLabel, isColored: true }] : []),
    ...(level ? [{ text: level?.value }] : []),
    ...(formatName ? [{ text: formatName }] : []),
  ];
  return entityFeatures;
};

/** @deprecated */
export const getEntityDataTypeBadge = (categoryTypeCode: number): string | null => {
  switch (categoryTypeCode) {
    // study
    case SectionCodes[SectionKeys.EVENT_OLYMPIAD]:
      return 'олимпиада';
    // science
    case SectionCodes[SectionKeys.PROJECT]:
      return 'проект';
    case SectionCodes[SectionKeys.SCIENCE_CIRCLE]:
      return 'кружок';
    case SectionCodes[SectionKeys.CONTEST]:
      return 'конкурс';
    case SectionCodes[SectionKeys.CONFERENCE]:
      return 'конференция / конкурс проектов';
    // sport
    case SectionCodes[SectionKeys.SPORT_TEAM]:
      return 'команда';
    case SectionCodes[SectionKeys.SPORT_CLUB]:
      return 'клуб';
    case SectionCodes[SectionKeys.SPORT_OCCUPATION_CIRCLE]:
      return 'кружок';
    case SectionCodes[SectionKeys.SPORT_OCCUPATION_SECTION]:
      return 'секция';
    case SectionCodes[SectionKeys.SPORT_OCCUPATION_PREPARING]:
      return 'спортподготовка';
    case SectionCodes[SectionKeys.SPORT_EVENT_COMPETITION]:
      return 'соревнование';
    case SectionCodes[SectionKeys.SPORT_EVENT_EXPEDITION]:
      return 'поход / экспедиция';
    case SectionCodes[SectionKeys.SPORT_EVENT_TOURISM]:
      return 'первенство по туризму';
    // creation
    case SectionCodes[SectionKeys.CREATION_COLLECTIVE]:
      return 'коллектив';
    case SectionCodes[SectionKeys.CREATION_CONTEST]:
      return 'конкурс';
    case SectionCodes[SectionKeys.CREATION_CIRCLE]:
      return 'кружок';
    case SectionCodes[SectionKeys.CREATION_SCHOOL]:
      return 'школа искусств';
    // civil
    case SectionCodes[SectionKeys.CIVIL_AFFILATION_GROUP]:
      return 'объединение';
    case SectionCodes[SectionKeys.CIVIL_AFFILATION_MOVEMENT]:
      return 'общественное движение';
    case SectionCodes[SectionKeys.CIVIL_EVENT_LOCAL]:
      return 'мероприятие по краеведению';
    case SectionCodes[SectionKeys.CIVIL_EVENT_ECOLOGY]:
      return 'экологическая конференция / фестиваль';
    case SectionCodes[SectionKeys.CIVIL_EVENT_VOLONTEER]:
      return 'волонтерский / социальный конкурс';
    case SectionCodes[SectionKeys.CIVIL_EVENT_PATRIOTIC]:
      return 'военно-патриотическое мероприятие';
    case SectionCodes[SectionKeys.CIVIL_EVENT_QUEST]:
      return 'квест / квиз';
    case SectionCodes[SectionKeys.CIVIL_OCCUPATION_CIRCLE]:
      return 'кружок';
    // profession
    case SectionCodes.professionEventChampionship:
      return 'чемпионат';
    default:
      return null;
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getStudyEntityBackgroundImage = (subjects?: string[] | null) =>
  // TODO сделать выбор картинки по дисциплинам
  StudyOther;

export const getScienceEntityBackgroundImage = (subcategoryCode?: number | null) => {
  switch (subcategoryCode) {
    case subcategoryCodes.natural:
      return ScienceNatural;
    case subcategoryCodes.it:
      return ScienceIt;
    case subcategoryCodes.humanitarian:
      return ScienceHumanitarian;
    case subcategoryCodes.engineer:
      return ScienceEngineer;
    case subcategoryCodes.language:
      return ScienceLanguage;
    default:
      return ScienceOther;
  }
};

export const getSportEntityBackgroundImage = (sportKind?: string) => {
  if (!sportKind) return OtherSports;
  if (transportSports.includes(sportKind)) return TransportSports;
  if (groundSports.includes(sportKind)) return GroundSports;
  if (singleSports.includes(sportKind)) return SingleSports;
  if (contactSports.includes(sportKind)) return ContactSports;
  if (waterSports.includes(sportKind)) return WaterSports;
  if (heavySports.includes(sportKind)) return HeavySports;
  if (lightSports.includes(sportKind)) return LightSports;
  if (targetSports.includes(sportKind)) return TargetSports;
  if (airSports.includes(sportKind)) return AirSports;
  if (winterSports.includes(sportKind)) return WinterSports;
  if (logicSports.includes(sportKind)) return LogicSports;
  return OtherSports;
};

export const getCreationEntityBackgroundImage = (creationKind?: string | null) => {
  if (!creationKind) return CreationFineArt;
  if (THEATRE_CREATION.includes(creationKind)) return CreationTheatreCircusCinema;
  if (MUSIC_CREATION.includes(creationKind)) return CreationMusic;
  if (CHOREOHRAPHY_CREATION.includes(creationKind)) return CreationChoreohraphy;
  if (LITERATURE_CREATION.includes(creationKind)) return CreationLiterature;
  if (FINE_ART_CREATION.includes(creationKind)) return CreationFineArt;
  if (CRAFTS_CREATION.includes(creationKind)) return CreationCrafts;
  return CreationFineArt;
};

export const getCivilEntityBackgroundImage = (subcategoryCode: number | undefined | null) => {
  switch (subcategoryCode) {
    case subcategoryCodes[subcategoryKeys.SOCIAL]:
      return CivilVolunteering;
    case subcategoryCodes[subcategoryKeys.MILITARY]:
      return CivilPatriotic;
    case subcategoryCodes[subcategoryKeys.LOCAL]:
      return CivilLocalHistory;
    case subcategoryCodes[subcategoryKeys.ECOLOGY]:
      return CivilEcology;
    default:
      return CivilOther;
  }
};

export const getCivilAffiliationAchiementImage = (affiliationKindCode: number) => {
  switch (affiliationKindCode) {
    case 7:
      return achievement1_Unarmy;
    case 24:
      return achievement2_UnTraveller;
    case 18:
      return achievement3_Sport;
    case 8:
      return achievement4_Spofdo;
    case 16:
      return achievement5_SouzMol;
    case 17:
      return achievement6_Sodruzhestvo;
    case 15:
      return achievement7_Rdsh;
    case 25:
      return achievement8_First;
    case 12:
      return achievement9_Orur;
    case 11:
      return achievement10_Nosd;
    case 21:
      return achievement11_MzhkRossii;
    case 22:
      return achievement12_MoldEdinstvo;
    case 19:
      return achievement13_MolObed;
    case 10:
      return achievement14_IntelBudush;
    case 9:
      return achievement15_Dimsi;
    case 20:
      return achievement16_CentrlPodderzhki;
    case 14:
      return achievement17_Ads;
    default:
      return undefined;
  }
};

export const getCultureEntityBackgroundImage = (subcategoryCultureCode: number | undefined | null) => {
  switch (subcategoryCultureCode) {
    case subcategoryCultureCodes[subcategoryCultureKeys.MUSEUMS]:
      return CultureMuseum;
    case subcategoryCultureCodes[subcategoryCultureKeys.EXHIBITION]:
      return CultureExhibition;
    case subcategoryCultureCodes[subcategoryCultureKeys.INSTITUTION]:
      return CultureInstitution;
    case subcategoryCultureCodes[subcategoryCultureKeys.THEATRE]:
      return CultureTheatre;
    case subcategoryCultureCodes[subcategoryCultureKeys.CONCERT]:
      return CultureConcert;
    case subcategoryCultureCodes[subcategoryCultureKeys.CINEMA]:
      return CultureCinema;
    case subcategoryCultureCodes[subcategoryCultureKeys.EDUCATION]:
      return CultureEducation;
    case subcategoryCultureCodes[subcategoryCultureKeys.EXCURSION]:
      return CultureExcursion;
    case subcategoryCultureCodes[subcategoryCultureKeys.OVER]:
      return CultureOther;
    default:
      return CultureOther;
  }
};

export const getOnlineCultureEntityBackgroundImage = (subcategoryCultureCode: number | undefined | null) => {
  switch (subcategoryCultureCode) {
    case subcategoryCultureCodes[subcategoryCultureKeys.ONLINELECTURE]:
      return CultureOnlineLecture;
    case subcategoryCultureCodes[subcategoryCultureKeys.ONLINEEXHIBITION]:
      return CultureOnlineExhibition;
    case subcategoryCultureCodes[subcategoryCultureKeys.ONLINEEXCURSION]:
      return CultureOnlineExcursion;
    case subcategoryCultureCodes[subcategoryCultureKeys.ONLINEOVER]:
      return CultureOnlineOther;
    default:
      return CultureOnlineOther;
  }
};

export const getProfessionalEducationEntityBackgroundImage = (profession: string) => {
  switch (profession) {
    case 'Оператор ЭВМ':
      return ProfessionComputerOperator;
    case 'Консультант в области развития цифровой грамотности населения (цифровой куратор)':
      return ProfessionDigitalCurator;
    case 'Водитель автомобиля':
      return ProfessionCarDriver;
    case 'Вожатый':
      return ProfessionGuide;
    case 'Чертежник':
      return ProfessionDraftman;
    case 'Лаборант химического анализа':
      return ProfessionChemist;
    case 'Полицейский':
      return ProfessionPoliceman;
    case 'Слесарь по ремонту автомобилей':
      return ProfessionMechanic;
    case 'Исполнитель художественно-оформительских работ':
      return ProfessionArtist;
    case 'Оператор наземных средств управления беспилотным летательным аппаратом':
      return ProfessionDrones;
    case 'Фотограф':
      return ProfessionPhotographer;
    case 'Повар':
      return ProfessionCook;
    case 'Чертежник-конструктор':
      return ProfessionDraftman;
    case 'Агент банка':
      return ProfessionBankAgent;
    case 'Кондитер':
      return ProfessionConfectioner;
    case 'Специалист по маникюру':
      return ProfessionManicurist;
    default:
      return ProfessionOther;
  }
};

export const getProfessionEventsEntityBackgroundImage = () => {
  return StudyOther;
};
export const getDeleteDialogEntityTitle = (categoryTypeCode?: number | null): string => {
  switch (categoryTypeCode) {
    // study
    case SectionCodes[SectionKeys.EVENT_OLYMPIAD]:
      return 'олимпиады';
    case SectionCodes.studySpoDocuments:
      return 'документа';
    // science
    case SectionCodes[SectionKeys.PROJECT]:
      return 'проекта';
    case SectionCodes[SectionKeys.SCIENCE_CIRCLE]:
      return 'кружка';
    case SectionCodes[SectionKeys.CONTEST]:
      return 'конкурса';
    case SectionCodes[SectionKeys.CONFERENCE]:
      return 'конференции / конкурса проектов';
    // sport
    case SectionCodes[SectionKeys.SPORT_TEAM]:
      return 'команды';
    case SectionCodes[SectionKeys.SPORT_CLUB]:
      return 'клуба';
    case SectionCodes[SectionKeys.SPORT_CLUB_TEAM]:
      return 'команды / клуба';
    case SectionCodes[SectionKeys.SPORT_OCCUPATION_CIRCLE]:
      return 'кружка';
    case SectionCodes[SectionKeys.SPORT_OCCUPATION_SECTION]:
      return 'секции';
    case SectionCodes[SectionKeys.SPORT_OCCUPATION_PREPARING]:
      return 'спортподготовки';
    case SectionCodes[SectionKeys.SPORT_EVENT_COMPETITION]:
      return 'соревнования';
    case SectionCodes[SectionKeys.SPORT_EVENT_EXPEDITION]:
      return 'похода / экспедиции';
    case SectionCodes[SectionKeys.SPORT_EVENT_TOURISM]:
      return 'первенства по туризму';
    // creation
    case SectionCodes[SectionKeys.CREATION_COLLECTIVE]:
      return 'коллектива';
    case SectionCodes[SectionKeys.CREATION_CONTEST]:
      return 'конкурса';
    case SectionCodes[SectionKeys.CREATION_CIRCLE]:
      return 'кружка';
    case SectionCodes[SectionKeys.CREATION_SCHOOL]:
      return 'школы искусств';
    // civil
    case SectionCodes[SectionKeys.CIVIL_AFFILATION_GROUP]:
      return 'объединения';
    case SectionCodes[SectionKeys.CIVIL_AFFILATION_MOVEMENT]:
      return 'общественного движения';
    case SectionCodes[SectionKeys.CIVIL_EVENT_LOCAL]:
      return 'мероприятия по краеведению';
    case SectionCodes[SectionKeys.CIVIL_EVENT_ECOLOGY]:
      return 'экологической конференции / фестиваля';
    case SectionCodes[SectionKeys.CIVIL_EVENT_VOLONTEER]:
      return 'волонтерского / социального конкурса';
    case SectionCodes[SectionKeys.CIVIL_EVENT_PATRIOTIC]:
      return 'военно-патриотического мероприятия';
    case SectionCodes[SectionKeys.CIVIL_EVENT_QUEST]:
      return 'квеста / квиза';
    case SectionCodes[SectionKeys.CIVIL_OCCUPATION_CIRCLE]:
      return 'кружка';
    // culture
    case SectionCodes[SectionKeys.CULTURE_VISITING]:
      return 'мероприятия';
    case SectionCodes[SectionKeys.CULTURE_ONLINE]:
      return 'онлайн мероприятия';
    // profession
    case SectionCodes.professionEventChampionship:
      return 'чемпионата';
    case SectionCodes.professionExamGia:
      return 'ГИА';
    case SectionCodes.professionSpoJob:
      return 'трудоустройства';
    default:
      return 'награды';
  }
};

export const getDeleteDialogEntityType = (categoryTypeCode?: number | null): string => {
  switch (categoryTypeCode) {
    // study
    case SectionCodes[SectionKeys.EVENT_OLYMPIAD]:
      return 'олимпиаду';
    case SectionCodes.studySpoDocuments:
      return 'документ';
    // science
    case SectionCodes[SectionKeys.PROJECT]:
      return 'проект';
    case SectionCodes[SectionKeys.SCIENCE_CIRCLE]:
      return 'кружок';
    case SectionCodes[SectionKeys.CONTEST]:
      return 'конкурс';
    case SectionCodes[SectionKeys.CONFERENCE]:
      return 'конференцию / конкурс проектов';
    // sport
    case SectionCodes[SectionKeys.SPORT_TEAM]:
      return 'команду';
    case SectionCodes[SectionKeys.SPORT_CLUB]:
      return 'клуб';
    case SectionCodes[SectionKeys.SPORT_CLUB_TEAM]:
      return 'клуб / команду';
    case SectionCodes[SectionKeys.SPORT_OCCUPATION_CIRCLE]:
      return 'кружок';
    case SectionCodes[SectionKeys.SPORT_OCCUPATION_SECTION]:
      return 'секцию';
    case SectionCodes[SectionKeys.SPORT_OCCUPATION_PREPARING]:
      return 'спортподготовку';
    case SectionCodes[SectionKeys.SPORT_EVENT_COMPETITION]:
      return 'соревнование';
    case SectionCodes[SectionKeys.SPORT_EVENT_EXPEDITION]:
      return 'поход / экспедицию';
    case SectionCodes[SectionKeys.SPORT_EVENT_TOURISM]:
      return 'первенство по туризму';
    // creation
    case SectionCodes[SectionKeys.CREATION_COLLECTIVE]:
      return 'коллектив';
    case SectionCodes[SectionKeys.CREATION_CONTEST]:
      return 'конкурс';
    case SectionCodes[SectionKeys.CREATION_CIRCLE]:
      return 'кружок';
    case SectionCodes[SectionKeys.CREATION_SCHOOL]:
      return 'школу искусств';
    // civil
    case SectionCodes[SectionKeys.CIVIL_AFFILATION_GROUP]:
      return 'объединение';
    case SectionCodes[SectionKeys.CIVIL_AFFILATION_MOVEMENT]:
      return 'общественное движение';
    case SectionCodes[SectionKeys.CIVIL_EVENT_LOCAL]:
      return 'мероприятие по краеведению';
    case SectionCodes[SectionKeys.CIVIL_EVENT_ECOLOGY]:
      return 'экологическую конференцию / фестиваль';
    case SectionCodes[SectionKeys.CIVIL_EVENT_VOLONTEER]:
      return 'волонтерский / социальный конкурс';
    case SectionCodes[SectionKeys.CIVIL_EVENT_PATRIOTIC]:
      return 'военно-патриотическое мероприятие';
    case SectionCodes[SectionKeys.CIVIL_EVENT_QUEST]:
      return 'квест / квиз';
    case SectionCodes[SectionKeys.CIVIL_OCCUPATION_CIRCLE]:
      return 'кружок';
    // culture
    case SectionCodes[SectionKeys.CULTURE_VISITING]:
      return 'мероприятие';
    case SectionCodes[SectionKeys.CULTURE_ONLINE]:
      return 'онлайн мероприятие';
    // profession
    case SectionCodes.professionEventChampionship:
      return 'чемпионат';
    case SectionCodes.professionExamGia:
      return 'ГИА';
    case SectionCodes.professionSpoJob:
      return 'трудоустройство';

    default:
      return 'награду';
  }
};

export const getCategoryAccentColor = (categoryCode: number): AccentColorType => {
  switch (categoryCode) {
    case SectionCodes.profile:
      return 'blue';
    case SectionCodes.study:
      return 'violet';
    case SectionCodes.science:
      return 'indigo';
    case SectionCodes.sport:
      return 'cyan';
    case SectionCodes.creation:
      return 'orange';
    case SectionCodes.culture:
      return 'red';
    case SectionCodes.civil:
      return 'green';
    case SectionCodes.profession:
      return 'grape';
    case SectionCodes.recommendation:
      return 'pink';
    default:
      return 'indigo';
  }
};

// Проверка по справочнику админских кодов
export const getAdminCategoryAccentColor = (categoryCode: number): AccentColorType => {
  switch (categoryCode) {
    case AdminSectionSettingCodes.profile:
      return 'blue';
    case AdminSectionSettingCodes.study:
      return 'violet';
    case AdminSectionSettingCodes.science:
      return 'indigo';
    case AdminSectionSettingCodes.sport:
      return 'cyan';
    case AdminSectionSettingCodes.creation:
      return 'orange';
    case AdminSectionSettingCodes.culture:
      return 'red';
    case AdminSectionSettingCodes.civil:
      return 'green';
    case AdminSectionSettingCodes.profession:
      return 'grape';
    case AdminSectionSettingCodes.recommendation:
      return 'pink';
    default:
      return 'indigo';
  }
};

export const getCategoryProperties = (categoryCode: number): CategoryProperties => {
  const accentColor = getCategoryAccentColor(categoryCode);

  switch (categoryCode) {
    case SectionCodes.profile:
      return {
        accentColor,
        icon: <IconProfile />,
        name: SectionNames.profile,
      };
    case SectionCodes.study:
      return {
        accentColor,
        icon: <IconSchool />,
        name: SectionNames.study,
      };
    case SectionCodes.science:
      return {
        accentColor,
        icon: <IconScience />,
        name: SectionNames.science,
      };
    case SectionCodes.sport:
      return {
        accentColor,
        icon: <IconSport />,
        name: SectionNames.sport,
      };
    case SectionCodes.creation:
      return {
        accentColor,
        icon: <IconCreation />,
        name: SectionNames.creation,
      };
    case SectionCodes.culture:
      return {
        accentColor,
        icon: <IconCulture />,
        name: SectionNames.culture,
      };
    case SectionCodes.civil:
      return {
        accentColor,
        icon: <IconStar />,
        name: SectionNames.civil,
      };
    case SectionCodes.profession:
      return {
        accentColor,
        icon: <IconProfession />,
        name: SectionNames.profession,
      };
    default:
      return {
        accentColor,
        icon: <IconSchool />,
        name: SectionNames.study,
      };
  }
};

export const getEntityTypeByTypeCode = (typeCode: number | undefined): EntityType | null => {
  switch (typeCode) {
    case SectionCodes.eventOlympiad:
      return EntityType.OLYMPIAD;
    case SectionCodes.studySpoDocuments:
      return EntityType.DOCUMENT;
    case SectionCodes.studySpoTraining:
      return EntityType.TRAINING_INFO;
    case SectionCodes.studySpoPractice:
      return EntityType.PRACTICE;
    case SectionCodes.professionSpoJob:
      return EntityType.JOB;
    default:
      return null;
  }
};

export const getDataItemType = (dataKind: number | undefined | null): EntityType | null => {
  switch (dataKind) {
    case SectionCodes.studyEvent:
    case SectionCodes.scienceEvent:
    case SectionCodes.cultureEvent:
    case SectionCodes.cultureVisiting:
    case SectionCodes.creationEvent:
    case SectionCodes.sportEvent:
    case SectionCodes.civilEvent:
    case SectionCodes.professionEvent:
      return EntityType.EVENT;
    case SectionCodes.studyReward:
    case SectionCodes.scienceReward:
    case SectionCodes.creationReward:
    case SectionCodes.civilReward:
    case SectionCodes.professionReward:
      return EntityType.REWARD;
    case SectionCodes.sportReward:
      return EntityType.SPORT_REWARD;
    case SectionCodes.project:
      return EntityType.PROJECT;
    case SectionCodes.scienceOccupation:
    case SectionCodes.creationOccupation:
    case SectionCodes.sportOccupation:
    case SectionCodes.civilOccupation:
      return EntityType.EMPLOYMENT;
    case SectionCodes.creationAffilation:
    case SectionCodes.sportAffilation:
    case SectionCodes.civilAffilation:
      return EntityType.AFFILATION;
    case SectionCodes.professionExam:
      return EntityType.GIA_WORLDSKILLS;
    default:
      return null;
  }
};

export const getProffClassRecommendationImage = (proffClassRefCode: number): string => {
  // Добавлять новые коды по необходимости
  switch (proffClassRefCode) {
    case ProffClassesCodes.engineeringClass:
      return classEngineering;
    case ProffClassesCodes.medicalClass:
      return classMedical;
    case ProffClassesCodes.kurchatovClass:
      return classKurchatov;
    case ProffClassesCodes.academicClass:
      return classAcademic;
    case ProffClassesCodes.itClass:
      return classIt;
    case ProffClassesCodes.pedagogicalClass:
      return classPedagogical;
    case ProffClassesCodes.entrepreneurialClass:
      return classEntrepreneurial;
    case ProffClassesCodes.mediaClass:
      return classMedia;
    case ProffClassesCodes.cadetClass:
      return classCadet;
    case ProffClassesCodes.mathematicalVertical:
      return classMathematical;
    case ProffClassesCodes.mathematicalVerticalPlus:
      return classMathematicalPlus;
    case ProffClassesCodes.sportVertical:
      return classSportVertical;
    case ProffClassesCodes.naturalScienceVertical:
      return classNaturalScience;
    case ProffClassesCodes.linguisticVertical:
      return classLinguistic;
    case ProffClassesCodes.sportClass:
      return classSportClass;
    default:
      return '';
  }
};

/**
 * Определяет что сущность относится к разделу Профессия/Профсессиональное обучение
 *
 * @param formData объект с полями формы сущности
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isProfessionalEducationEntity = (formData: any): boolean => {
  return (
    Boolean(formData?.profession) &&
    Boolean(formData?.organization) &&
    // Тип документа
    Boolean(formData?.type)
  );
};

export const flagsMap = (flagsMap: Partial<Record<EntityFlag, boolean>>): EntityFlag[] | undefined => {
  const entries = Object.entries(flagsMap);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const enabled = entries.filter(([_, isEnabled]) => isEnabled);
  const enabledFlags = enabled.map(([flag]) => flag as EntityFlag);

  return enabledFlags.length > 0 ? enabledFlags : undefined;
};

export const getEntitySectionTitle = (
  sectionRef: SectionRefItem[],
  dataKind: number | undefined,
  typeCode: number | undefined,
  fallback?: string,
) => {
  const typeCodeOrDataKind = typeCode ?? dataKind;

  const localEnittySectionTitle = typeCodeOrDataKind ? getEntityHeaderText(typeCodeOrDataKind) : '';
  const refEntitySectionTitle = sectionRef.find((ref) => ref.code === typeCodeOrDataKind)?.value;
  const entitySectionTitle = refEntitySectionTitle ?? fallback ?? localEnittySectionTitle;

  return entitySectionTitle;
};
