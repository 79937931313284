import { ICommonResponse } from 'api/types';
import { EntityType, SectionCodes, SourceCode } from 'const';
import { cardIllustrations } from 'images';
import { SectionRefState } from 'reducers/reference';
import { StudentAffilationsState } from 'reducers/student/affilations';
import { StudentEmploymentsState } from 'reducers/student/employments';
import { StudentEventsState } from 'reducers/student/events';
import { StudentRewardsState } from 'reducers/student/rewards';
import { mapCivilContestToEntity, mapRewardEntity } from 'selectors/entityMappers';
import { IEntity, IModifiedReward } from 'types';
import {
  flagsMap,
  getCivilEntityBackgroundImage,
  getDateRange,
  getEntityFeatures,
  getEntityGeneralInfo,
  getYearFromDate,
} from 'utils';

import { getCivilAffilationAchievementImage } from './utils';

export const mapCivilClubsResponse = (
  affilations: StudentAffilationsState,
  sectionRef: SectionRefState,
): ICommonResponse<IEntity> => {
  const clubs = affilations.content
    .filter((affilation) => affilation.categoryCode === SectionCodes.civil)
    .map((affilation): IEntity => {
      const { id, recordId, subcategory, name, source, startDate, type, endDate, affilationLevel } = affilation;

      const entityTypes = [subcategory?.value];
      const entityFeatures = getEntityFeatures(sectionRef.content, type.code, affilationLevel);

      return {
        id,
        recordId,
        name,
        types: entityTypes,
        date: getDateRange(startDate, endDate),
        year: getYearFromDate(endDate || startDate),
        features: entityFeatures,
        backgroundImage: getCivilEntityBackgroundImage(subcategory?.code),
        formData: affilation,
        achievementImage: getCivilAffilationAchievementImage(affilation) ?? undefined,
        cardData: {
          name,
          generalInfo: getEntityGeneralInfo(affilation),
          files: affilation.fileReferences,
          linkedObjects: affilation.linkedObjects,
          formData: affilation,
          illustrationFallback: cardIllustrations.civilAffilation,
        },
        entityProps: {
          type: EntityType.AFFILATION,
          flags: flagsMap({ approved: source.code !== SourceCode.student && source.code !== SourceCode.parent }),
        },
      };
    });

  return {
    ...affilations,
    content: clubs,
  };
};

export const mapCivilContestsResponse = (
  events: StudentEventsState,
  rewards: StudentRewardsState,
  sectionRef: SectionRefState,
): ICommonResponse<IEntity> => {
  const boundRewards = rewards.content.filter((reward) => reward.entityId);
  const civilEvents = events.content
    .filter((event) => event.categoryCode === SectionCodes.civil)
    .map((event): IEntity => {
      const { id, recordId } = event;

      const eventReward = boundRewards?.find((reward) => reward.entityId == id || reward.entityId == recordId);
      return mapCivilContestToEntity(sectionRef.content, event, eventReward);
    });

  return {
    ...events,
    content: civilEvents,
  };
};

export const mapCivilSectionsResponse = (
  employments: StudentEmploymentsState,
  sectionRef: SectionRefState,
): ICommonResponse<IEntity> => {
  const civilEmployments = employments.content.filter((employment) => employment.categoryCode === SectionCodes.civil);
  const sections = civilEmployments.map((employment): IEntity => {
    const { id, recordId, subcategory, name, source, startDate, type, location, endDate, achievementActivityFormat } =
      employment;

    const entityTypes = subcategory ? [subcategory.value] : undefined;
    const entityFeatures = getEntityFeatures(sectionRef.content, type?.code, null, achievementActivityFormat);

    return {
      id,
      recordId,
      name,
      types: entityTypes,
      date: getDateRange(startDate, endDate),
      year: getYearFromDate(endDate || startDate),
      features: entityFeatures,
      backgroundImage: getCivilEntityBackgroundImage(subcategory?.code),
      place: location,
      formData: employment,
      cardData: {
        name,
        generalInfo: getEntityGeneralInfo(employment),
        files: employment.fileReferences,
        linkedObjects: employment.linkedObjects,
        formData: employment,
        illustrationFallback: cardIllustrations.civilOccupation,
      },
      entityProps: {
        type: EntityType.EMPLOYMENT,
        flags: flagsMap({ approved: source.code !== SourceCode.student && source.code !== SourceCode.parent }),
      },
    };
  });

  return {
    ...employments,
    content: sections,
  };
};

export const mapCivilRewardsResponse = (
  events: StudentEventsState,
  rewards: StudentRewardsState,
  sectionRef: SectionRefState,
): ICommonResponse<IModifiedReward> => {
  const civilRewards = rewards.content
    .filter((reward) => reward.categoryCode === SectionCodes.civil)
    .map((reward) => {
      return mapRewardEntity(reward, events.content, sectionRef.content);
    });

  return {
    ...rewards,
    content: civilRewards,
  };
};
