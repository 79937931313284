/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';
import genericReducers from '../genericReducer';
import { IReducers } from '../index';

import { GET_DISCIPLINES } from '../../actions/employees';
import { REQUEST, FAILURE, SUCCESS } from '../../actions/utils';

import { ICommonResponse, IDiscipline } from '../../api/types';

export type DisciplinesState = ICommonResponse<IDiscipline>;

const initialState = {} as ICommonResponse<IDiscipline>;

const disciplines: IReducers<DisciplinesState> = {
  [GET_DISCIPLINES[REQUEST]]: genericReducers().get.request,
  [GET_DISCIPLINES[SUCCESS]]: genericReducers().get.success,
  [GET_DISCIPLINES[FAILURE]]: genericReducers().get.failure
};

export default connectReducers(initialState, disciplines);
