import { FC } from 'react';

import { Box, ThemeProvider, Typography } from '@mui/material';
import { generateAccentTheme } from 'portfolio3/styles/theme';
import { Button } from 'portfolio3/ui-kit/button';

interface IToastContentProps {
  filename: string;
  onAction: () => void;
}

const ToastContent: FC<IToastContentProps> = ({ filename, onAction }) => {
  return (
    <Box className="task-panel-toast-content">
      <Typography variant="Paragraph MD/Semi Bold">Считывание данных из файла завершено</Typography>
      <Typography variant="Paragraph MD/Regular" marginTop="4px">
        Считывание данных из файла{' '}
        <Typography component="span" variant="Paragraph MD/Semi Bold">
          {filename}
        </Typography>{' '}
        успешно завершено, перейдите к результатам, чтобы завершить импорт
      </Typography>
      <ThemeProvider theme={generateAccentTheme('green')}>
        <Button sx={{ position: 'relative', zIndex: 10, marginTop: '12px' }} size="small" onClick={onAction}>
          Перейти к результатам
        </Button>
      </ThemeProvider>
    </Box>
  );
};

export default ToastContent;
