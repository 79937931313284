import { FC } from 'react';

import { Box, InputAdornment, SxProps } from '@mui/material';
import { BaseInputLabel, FormControl, Input } from 'portfolio3/ui-kit';
import { CommonUiKitSize, IController, InputRenderMode } from 'portfolio3/ui-kit/types';

interface IAgeLimitControlProps {
  isMobile: boolean;
  renderMode: InputRenderMode;
  inputSize: CommonUiKitSize;
  valueFrom: unknown;
  valueTo: unknown;
  fromController: IController<string>;
  toController: IController<string>;
  label: string;
  endAdornment: string;
}

const AgeLimitControl: FC<IAgeLimitControlProps> = ({
  isMobile,
  renderMode,
  inputSize,
  valueFrom,
  valueTo,
  fromController,
  toController,
  label,
  endAdornment,
}) => {
  const inputSx: SxProps = { maxWidth: isMobile ? '100px' : '140px' };

  return (
    <FormControl
      sx={isMobile ? { gap: 0 } : undefined}
      renderMode="fixed"
      inputSize={inputSize}
      label={<BaseInputLabel sx={isMobile ? { color: '#7B819B !important' } : undefined}>{label}</BaseInputLabel>}
      control={
        <Box sx={{ display: 'flex', gap: '12px', width: 'max-content' }}>
          <FormControl
            withTopMargin={isMobile}
            renderMode={renderMode}
            inputSize={inputSize}
            label={isMobile && <BaseInputLabel>C</BaseInputLabel>}
            control={
              <Input
                sx={inputSx}
                renderMode={renderMode}
                size={inputSize}
                value={valueFrom}
                controller={fromController}
                startAdornment={!isMobile && <InputAdornment position="start">C</InputAdornment>}
                endAdornment={!isMobile && <InputAdornment position="end">{endAdornment}</InputAdornment>}
              />
            }
          />
          <FormControl
            withTopMargin={isMobile}
            renderMode={renderMode}
            inputSize={inputSize}
            label={isMobile && <BaseInputLabel>По</BaseInputLabel>}
            control={
              <Input
                sx={inputSx}
                renderMode={renderMode}
                size={inputSize}
                value={valueTo}
                controller={toController}
                startAdornment={!isMobile && <InputAdornment position="start">По</InputAdornment>}
                endAdornment={!isMobile && <InputAdornment position="end">{endAdornment}</InputAdornment>}
              />
            }
          />
        </Box>
      }
    />
  );
};

export default AgeLimitControl;
