import { FC } from 'react';

import { EntityFlags, EntityType } from 'const';
import ActionsButton from 'portfolio3/components/common/buttons/ActionsButton';
import { SxStyles } from 'types';

import WithActionsPopup from '../../../common/WithActionsPopup';
import useEntityActions from '../../hooks/useEntityActions';
import { IEntityActionMap } from '../../types';

interface IEntityActionsMenuButtonProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formData: any;
  entityType: EntityType;
  entityFlags: (keyof typeof EntityFlags)[];
  actionsMap: IEntityActionMap;

  sx?: SxStyles;
}

const EntityActionsMenuButton: FC<IEntityActionsMenuButtonProps> = ({
  formData,
  entityType,
  entityFlags,
  actionsMap,
  sx,
}) => {
  const actions = useEntityActions({ formData, entityType, entityFlags, actionsMap }, 'menu');

  return (
    <WithActionsPopup title="Действия" actions={actions}>
      {(ref, onClick) => {
        return <ActionsButton ref={ref} onClick={onClick} sx={sx} />;
      }}
    </WithActionsPopup>
  );
};

export default EntityActionsMenuButton;
