/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';

import {
  FAILURE,
  REQUEST,
  SUCCESS
} from '../../actions/utils';

import { IAdminFunctionSettingResponse } from '../../api/types';

import genericReducers from '../genericReducer';
import { GET_ADMIN_FUNCTION_SETTINGS } from '../../actions';

import { IReducers } from '..';

export type AdminFunctionSettingsState = IAdminFunctionSettingResponse;

const initialState = {} as IAdminFunctionSettingResponse;

// TODO В текущем состоянии этот редьюсер может сохранять только 1 тип настройки админа (Search)
// нужно либо переделать редьюсер, либо переименовать в adminSearchFunctionSetting
const adminFunctionSettings: IReducers<AdminFunctionSettingsState> = {
  [GET_ADMIN_FUNCTION_SETTINGS[REQUEST]]: genericReducers().get.request,
  [GET_ADMIN_FUNCTION_SETTINGS[SUCCESS]]: genericReducers().get.success,
  [GET_ADMIN_FUNCTION_SETTINGS[FAILURE]]: genericReducers().get.failure,
};

export default connectReducers(initialState, adminFunctionSettings);
