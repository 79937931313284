import React from 'react';

import { GlobalFooterContainer } from 'portfolio3/containers/footers';
import { GlobalHeaderContainer } from 'portfolio3/containers/headers';

import { LinkErrorCode } from '../../const';
import { brokenLink } from '../../images';

import './index.scss';

interface ILinkModeErrorPageProps {
  code?: number;
}

const LinkModeErrorPage: React.FC<ILinkModeErrorPageProps> = ({ code }) => {
  let headerText = '';
  if (code === LinkErrorCode.expired) {
    headerText = 'К сожалению, срок действия ссылки на данное портфолио истек';
  } else if (code === LinkErrorCode.notStarted) {
    headerText = 'К сожалению, ссылка на данное портфолио пока не действует';
  } else {
    headerText = 'К сожалению, ссылка на данное портфолио не существует';
  }

  let bodyText = '';
  if (code === LinkErrorCode.expired) {
    bodyText = 'Обратитесь к владельцу портфолио для повторного предоставления доступа';
  } else {
    bodyText = 'Обратитесь к владельцу портфолио для предоставления доступа';
  }
  return (
    <>
      <GlobalHeaderContainer isFullWidth />
      <div className="scrollable-container link-mode-error-page">
        <div className="link-mode-error-page__content">
          <img src={brokenLink} alt="ссылка недоступна" />
          <h4>{headerText}</h4>
          <p>{bodyText}</p>
        </div>
      </div>
      <GlobalFooterContainer isFullWidth />
    </>
  );
};

export default LinkModeErrorPage;
