import { IQueryParams } from 'api';

import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from './utils';

const createCommonReferenceActionsObject = (requestTypes: Record<string, string>) => {
  return {
    request: (queryParams?: IQueryParams) => action(requestTypes[REQUEST], { payload: [queryParams] }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    success: (response: any) => action(requestTypes[SUCCESS], { payload: response }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    failure: (error: any) => action(requestTypes[FAILURE], { payload: error }),
  };
};

export type CommonReferenceActionsObjectType = ReturnType<typeof createCommonReferenceActionsObject>;

export const GET_SECTION_REF = createRequestTypes('GET_SECTION_REF');
export const getSectionRefActions = createCommonReferenceActionsObject(GET_SECTION_REF);

export const GET_DOCUMENT_REF = createRequestTypes('GET_DOCUMENT_REF');
export const getDocumentRefActions = createCommonReferenceActionsObject(GET_DOCUMENT_REF);

export const GET_META_SKILL_REF = createRequestTypes('GET_META_SKILL_REF');
export const getMetaSkillRefActions = createCommonReferenceActionsObject(GET_META_SKILL_REF);

export const GET_PROFESSIONAL_PROGRAMM_REF = createRequestTypes('GET_PROFESSIONAL_PROGRAMM_REF');
export const getProfessionProgrammRefActions = createCommonReferenceActionsObject(GET_PROFESSIONAL_PROGRAMM_REF);

export const GET_LEVEL_BUSINESS_REF = createRequestTypes('GET_LEVEL_BUSINESS_REF');
export const getLevelBusinessRefActions = createCommonReferenceActionsObject(GET_LEVEL_BUSINESS_REF);

export const GET_SALARY_RANGE_REF = createRequestTypes('GET_SALARY_RANGE_REF');
export const getSalaryRangeRefActions = createCommonReferenceActionsObject(GET_SALARY_RANGE_REF);

export const GET_EMPLOYMENT_DOC_TYPE_REF = createRequestTypes('GET_EMPLOYMENT_DOC_TYPE_REF');
export const getEmploymentDocTypeRefActions = createCommonReferenceActionsObject(GET_EMPLOYMENT_DOC_TYPE_REF);

export const GET_SPO_ORGANIZATION_REF = createRequestTypes('GET_SPO_ORGANIZATION_REF');
export const getSpoOrganizationRefActions = createCommonReferenceActionsObject(GET_SPO_ORGANIZATION_REF);

export const GET_SPO_GIA_MARK_REF = createRequestTypes('GET_SPO_GIA_MARK_REF');
export const getSpoGiaMarkRefActions = createCommonReferenceActionsObject(GET_SPO_GIA_MARK_REF);

export const GET_PROFESSION_RANK_REF = createRequestTypes('GET_PROFESSION_RANK_REF');
export const getProfessionRankRefActions = createCommonReferenceActionsObject(GET_PROFESSION_RANK_REF);

export const GET_SPORT_AGES_REF = createRequestTypes('GET_SPORT_AGES_REF');
export const getSportAgesRefActions = createCommonReferenceActionsObject(GET_SPORT_AGES_REF);

export const GET_OLYMPIAD_SUBJECTS_REF = createRequestTypes('GET_OLYMPIAD_SUBJECTS_REF');
export const getOlympiadSubjectsRefActions = createCommonReferenceActionsObject(GET_OLYMPIAD_SUBJECTS_REF);

export const GET_AVATAR_TYPE_REF = createRequestTypes('GET_AVATAR_TYPE_REF');
export const getAvatarTypeRefActions = createCommonReferenceActionsObject(GET_AVATAR_TYPE_REF);
