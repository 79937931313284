import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { IconCheckmark } from 'icons';
import { getAccentColor, NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';
import { getProcessPluralization, getRecordingPluralization } from 'utils';

import StatusIcon from './StatusIcon';

const stylesRoot: SxStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  padding: '12px 20px',

  border: '1px solid',
  borderColor: NeutralColors.light_neutrals_300,
  borderRadius: '8px',
  backgroundColor: NeutralColors.light_neutrals_0,
  boxShadow: '0px 1px 2px 0px rgba(41, 41, 64, 0.07)',
};
interface ICorretRecordsProps {
  count: number;
}

const CorrectRecords: FC<ICorretRecordsProps> = ({ count }) => {
  const pluralizedRecording = getRecordingPluralization(count);
  const pluralizedProcess = getProcessPluralization(count);

  const title = `${count} ${pluralizedRecording} ${pluralizedProcess} успешно`;

  return (
    <Box className="correct-records" sx={stylesRoot}>
      <StatusIcon
        color={getAccentColor('green', '100')}
        iconElement={<IconCheckmark />}
        backgroundColor={getAccentColor('green', '8')}
      />
      <Typography variant="Paragraph LG/Semi Bold" color={getAccentColor('green', '200')}>
        {title}
      </Typography>
    </Box>
  );
};

export default CorrectRecords;
