/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import ChangeSettings from '../../containers/admin/ChangeSettings';

const AdminSettingsPage: React.FC = () => (
  <div className="admin-content">
    <ChangeSettings />
  </div>
);

export default AdminSettingsPage;
