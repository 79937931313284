import {
  IDictionaryItem,
  IStudentEvent,
  IStudentProject,
  IStudentReward,
  IStudentSportReward,
  SectionRefItem,
} from 'api/types';
import { EntityType, SectionCodes, SourceCode, SportSubcategoryCodes } from 'const';
import { format } from 'date-fns';
import { cardIllustrations } from 'images';
import { capitalize } from 'lodash';
import { IModifiedReward } from 'types';
import {
  getEntityCategoryCodeEventIllustration,
  getEntityCategoryCodeRewardIllustration,
  getEntityGeneralInfo,
  getRewardCardIcon,
  getSportAwardDescription,
  getSportRewardCardIcon,
  isDefined,
} from 'utils';

export const mapRewardEntity = (
  reward: IStudentReward,
  events: (IStudentProject | IStudentEvent)[],
  sectionRef: SectionRefItem[],
): IModifiedReward => {
  const { id, entityId, name, date, rewardType, categoryCode, typeCode } = reward;

  const numberEntityId = Number(entityId);

  const linkedEvent = entityId
    ? events.find((event) => event.id === numberEntityId || Number(event.recordId) === numberEntityId)
    : undefined;

  const eventCompareCode = linkedEvent && 'typeCode' in linkedEvent ? linkedEvent.typeCode : linkedEvent?.dataKind;
  const eventSectionRefItem = sectionRef.find((ref) => ref.code === eventCompareCode);
  const eventSectionRefValue =
    eventSectionRefItem?.shortValue?.toLowerCase() ?? eventSectionRefItem?.value.toLowerCase();

  const rewardSectionRefItem = sectionRef.find((ref) => ref.code === typeCode);
  const rewardSectionRefValue = rewardSectionRefItem?.value;

  const linkedEventType = sectionRef.find((ref) => ref.code === linkedEvent?.dataKind)?.value;

  return {
    id: id,
    name: name,
    awardDate: isDefined(date) ? format(new Date(date), 'dd.MM.yyyy') : '',
    image: getRewardCardIcon(rewardType.value),
    description: linkedEvent?.name ?? rewardSectionRefValue ?? name,
    awardType: eventSectionRefValue,
    formData: reward,
    cardData: {
      name: rewardType.value,
      files: reward.fileReferences,
      linkedObjects: reward.linkedObjects,
      generalInfo: getEntityGeneralInfo(reward),
      formData: reward,
      illustrationFallback: getEntityCategoryCodeRewardIllustration(categoryCode),
      event: linkedEvent && {
        cardData: {
          name: linkedEvent?.name,
          files: linkedEvent?.fileReferences,
          linkedObjects: linkedEvent?.linkedObjects,
          generalInfo: getEntityGeneralInfo(linkedEvent),
          formData: linkedEvent,
          illustrationFallback: getEntityCategoryCodeEventIllustration(categoryCode),
        },
        type: capitalize(linkedEventType),
      },
      entityFlags: ['reward'],
    },
    isApproved: reward.source?.code !== SourceCode.student && reward.source?.code !== SourceCode.parent,
    entityType: EntityType.REWARD,
    entityFlags: ['reward'],
  };
};

export const mapSportRewardEntity = (
  reward: IStudentSportReward,
  events: IStudentEvent[],
  sportKinds: IDictionaryItem[],
  tourismKinds: IDictionaryItem[],
  sectionRef: SectionRefItem[],
): IModifiedReward => {
  const { type } = reward;

  const rewardEntityId = Number(reward.entityId);

  const linkedEvent = reward.entityId
    ? events.find((event) => event.id === rewardEntityId || Number(event.recordId) === rewardEntityId)
    : undefined;

  const correctKind =
    linkedEvent?.typeCode === SectionCodes.sportEventTourism ||
    linkedEvent?.typeCode === SectionCodes.sportEventExpedition ||
    reward.subcategory?.code === SportSubcategoryCodes.tourism
      ? tourismKinds
      : sportKinds;

  const subspeciesArray = reward.subspecies?.split(', ');
  const subspeciesString = correctKind
    ?.filter((sportKind) => subspeciesArray?.includes(String(sportKind.code)))
    .map((sportKind) => sportKind?.value.trim())
    .join(', ');

  const eventSectionRefItem = sectionRef.find((ref) => ref.code === linkedEvent?.typeCode);
  const eventSectionRefValue =
    eventSectionRefItem?.shortValue?.toLowerCase() ?? eventSectionRefItem?.value.toLowerCase();

  const rewardSectionRefItem = sectionRef.find((ref) => ref.code === type.code);
  const rewardSectionRefValue = rewardSectionRefItem?.value;

  const linkedEventType = sectionRef.find((ref) => ref.code === linkedEvent?.dataKind)?.value;

  return {
    id: reward.id,
    recordId: reward.recordId,
    name: reward.name,
    awardDate: isDefined(reward.date) ? format(new Date(reward.date), 'dd.MM.yyyy') : '',
    image: getSportRewardCardIcon(reward.sportRewardCode),
    description: getSportAwardDescription(
      type.code,
      reward.ageLimit?.value,
      reward.sportKind?.value,
      reward.tourismKind?.value,
      linkedEvent?.name,
      rewardSectionRefValue,
    ),
    awardType: eventSectionRefValue,
    formData: reward,
    cardData: {
      name: type.value,
      files: reward.fileReferences,
      linkedObjects: reward.linkedObjects,
      generalInfo: getEntityGeneralInfo(reward, subspeciesString),
      formData: reward,
      illustrationFallback: cardIllustrations.sportReward,
      event: linkedEvent && {
        cardData: {
          name: linkedEvent?.name,
          files: linkedEvent?.fileReferences,
          linkedObjects: linkedEvent?.linkedObjects,
          generalInfo: getEntityGeneralInfo(linkedEvent),
          formData: linkedEvent,
          illustrationFallback:
            linkedEvent.dataKind === SectionCodes.sportEventCompetition
              ? cardIllustrations.sportCompetition
              : cardIllustrations.sportExpedition,
        },
        type: capitalize(linkedEventType),
      },
      entityFlags: ['reward'],
    },
    isApproved: reward.source?.code !== SourceCode.student && reward.source?.code !== SourceCode.parent,
    entityType: EntityType.SPORT_REWARD,
    entityFlags: ['reward'],
  };
};
