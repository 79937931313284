import { getAccentColor } from 'portfolio3/styles';
import { getButtonStyles } from 'portfolio3/ui-kit/utils';
import { SxStyles } from 'types';

const desktopColor = getAccentColor('indigo', '200');

export const desktopRoot: SxStyles = {
  ...getButtonStyles(),

  display: 'flex',
  gap: '8px',
};

export const desktopText: SxStyles = {
  color: desktopColor,
};

export const desktopIcon: SxStyles = {
  width: '20px',
  height: '20px',
  color: desktopColor,
};
