import { FC } from 'react';

import { Avatar as MuiAvatar, Box } from '@mui/material';
import { SxStyles } from 'types';
import { getRandomAvatarColor, mergeSx } from 'utils';

import * as styles from './styles';

// соотнощение размера компонента с размером шрифта (макет)
const sizeToFontSize: Record<string, number> = {
  '20': 8,
  '24': 10,
  '32': 10,
  '40': 14,
  '52': 16,
  '72': 24,
};

interface IAvatarProps {
  image?: string;
  firstName: string;
  lastName: string;
  size: number;
  borderRadius?: string;
  backdropColor?: string;
  containerSx?: SxStyles;
}

const Avatar: FC<IAvatarProps> = ({ image, firstName, lastName, size, borderRadius, backdropColor, containerSx }) => {
  const avatarText = image ? null : firstName.charAt(0) + lastName.charAt(0);
  const backgroundColor = image ? undefined : getRandomAvatarColor(`${firstName} ${lastName}`);
  const avatarSize = size ? `${size}px` : '100%';

  const fontSizeKey = Object.keys(sizeToFontSize)
    .map(Number)
    .reduce((prevSize, currentSize) => {
      return size >= currentSize ? currentSize : prevSize;
    }, 20);
  const fontSize = sizeToFontSize[fontSizeKey] ?? 16;
  const mainAvatarStyles = styles.avatar(fontSize, borderRadius, backdropColor);
  const dynamicStyles: SxStyles = { backgroundColor, width: avatarSize, height: avatarSize };

  return (
    <Box className="base-avatar" sx={mergeSx(styles.container, containerSx)}>
      <MuiAvatar sx={mergeSx(mainAvatarStyles, dynamicStyles)} src={image}>
        {avatarText}
      </MuiAvatar>
    </Box>
  );
};

export default Avatar;
