import React from 'react';

import { EmployeeSubheader } from 'portfolio3/components/common/headers';
import UnderlinedTabs from 'portfolio3/components/common/UnderlinedTabs';

import { ChangeHistoryTabs } from '../../../../const';
import { isRegionalUser } from '../../../../utils';

interface IChangeHistoryHeaderProps {
  historyType: ChangeHistoryTabs;
  onChangeHistoryType: React.Dispatch<React.SetStateAction<ChangeHistoryTabs>>;
}

const ChangeHistoryHeader: React.FC<IChangeHistoryHeaderProps> = ({ historyType, onChangeHistoryType }) => {
  const isRegional = isRegionalUser();

  const tabsElement = (
    <UnderlinedTabs value={historyType} onChange={onChangeHistoryType} sx={{ gap: '24px' }}>
      <UnderlinedTabs.Button value={ChangeHistoryTabs.manual} content="История изменений" />
      {!isRegional && <UnderlinedTabs.Button value={ChangeHistoryTabs.import} content="История импорта" />}
    </UnderlinedTabs>
  );

  return <EmployeeSubheader title="История" tabsElement={tabsElement} />;
};

export default ChangeHistoryHeader;
