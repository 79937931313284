enum MilitaryTrainingTypeKeys {
  WAR_DEPARTMENT = 'warDepartment',
  WAR_CENTER = 'warCenter',
}

export const MilitaryTrainingTypeCodes: Record<MilitaryTrainingTypeKeys, number> = {
  warDepartment: 1,
  warCenter: 2,
};

export const MilitaryTrainingTypeNames: Record<MilitaryTrainingTypeKeys, string> = {
  warDepartment: 'Подготовка офицеров и сержантов запаса', // war
  warCenter: 'Подготовка офицеров контрактной службы', // war_center
};
