import { useEffect, useState } from 'react';

import { useAppSelector } from 'hooks';
import {
  studentPrimaryNavigation,
  studentSecondaryNavigation,
} from 'portfolio3/components/common/PortfolioNavigation/const';
import { ISectionNavigationLink } from 'portfolio3/components/common/PortfolioNavigation/types';
import { linkVisibilitySettingsSelector, visibilitySettingsSelector } from 'selectors';
import { findTargetVisibilitySetting } from 'utils/localVisibilitySettingsService';

const useVisibleNavigation = () => {
  const linkMode = useAppSelector((state) => state.linkMode.mode) ?? false;
  const linkVisibilitySettings = useAppSelector((state) => linkVisibilitySettingsSelector(state));
  const visibilitySettings = useAppSelector((state) => visibilitySettingsSelector(state));

  const mappedVisibilitySettings = linkMode ? linkVisibilitySettings : visibilitySettings;

  const [primaryNavigation, setPrimaryNavigation] = useState<ISectionNavigationLink[]>(studentPrimaryNavigation);
  const [secondaryNavigation, setSecondaryNavigation] = useState<ISectionNavigationLink[]>(studentSecondaryNavigation);

  useEffect(() => {
    const primaryNavigation = studentPrimaryNavigation.filter((navigation) => {
      return findTargetVisibilitySetting(mappedVisibilitySettings, [navigation.sectionTypeCode])?.computedVisibility;
    });
    const secondaryNavigation = studentSecondaryNavigation.filter((navigation) => {
      return findTargetVisibilitySetting(mappedVisibilitySettings, [navigation.sectionTypeCode])?.computedVisibility;
    });

    setPrimaryNavigation(primaryNavigation);
    setSecondaryNavigation(secondaryNavigation);
  }, [mappedVisibilitySettings]);

  return {
    primaryNavigation,
    secondaryNavigation,
  };
};

export default useVisibleNavigation;
