import { FC } from 'react';

import { Box, SxProps } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';

const styles: SxProps = {
  width: '100%',
  borderTop: '1px solid',
  borderTopColor: NeutralColors.light_neutrals_300,
};

const FormDivider: FC = () => {
  return <Box className="form-divider" sx={styles} />;
};

export default FormDivider;
