import { SxStyles } from 'types';

export const root: SxStyles = (theme) => ({
  display: 'flex',
  gap: '20px',

  [theme.breakpoints.down('commonMd')]: {
    flexDirection: 'column',
    gap: '16px',
  },

  [theme.breakpoints.down('commonSm')]: {
    gap: '12px',
  },
});
