/* eslint-disable prettier/prettier */
/* eslint-disable */

export const MOSCOW_DOMAIN = '.mos.ru';
export const REGIONAL_DOMAIN = '.gov.ru';

export * from './reference';

export * from './apiPath';
export * from './avatars';
export * from './dialogSubmitTitle';
export * from './errors';
export * from './gender';
export * from './gia';
export * from './gradient';
export * from './linkSharing';
export * from './locationSteps';
export * from './masks';
export * from './portfolioNavigation';
export * from './roles';
export * from './routes';
export * from './sections';
export * from './diagnostics';
export * from './subsystems';
export * from './tooltipOptions';
export * from './visibility';
export * from './employeeEntryForm';
export * from './importer';
export * from './history';
export * from './adminSettings';
export * from './interests';
export * from './recommendations';
export * from './viewedFunctions';
export * from './adaptive';
export * from './sportClubs';
export * from './links';
export * from './eventLevel';
export * from './regionalCodes';
export * from './queryParams'
export * from './queryParams';
export * from './reportErrorTypes';
export * from './entityFlags';
export * from './entityTypes';
export * from './themeSettings';
export * from './widget';
export * from './spoStatus';
export * from './spoDocument';
export * from './portfolioPageTabs';
export * from './subcategory';
export * from './localStorageGlobals';
