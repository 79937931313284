import { IRootState } from 'reducers';
import { createSelector } from 'reselect';

const proforientationLoadingSelector = createSelector(
  [(state: IRootState) => state.proforientation],
  (proforientation) => {
    return proforientation.loading;
  },
);

export default proforientationLoadingSelector;
