/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';

const Racoon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#filter0_ii_311_287393)">
      <path
        d="M6.24784 7.02641C6.37227 8.83411 7.14232 10.2744 7.81064 11.0819L10.92 7.7382C9.66344 6.81102 7.94014 6.42361 6.91029 6.34541C6.5198 6.31576 6.22094 6.63572 6.24784 7.02641Z"
        fill="url(#paint0_linear_311_287393)"
      />
      <path
        d="M6.24784 7.02641C6.37227 8.83411 7.14232 10.2744 7.81064 11.0819L10.92 7.7382C9.66344 6.81102 7.94014 6.42361 6.91029 6.34541C6.5198 6.31576 6.22094 6.63572 6.24784 7.02641Z"
        fill="url(#paint1_linear_311_287393)"
      />
    </g>
    <path
      d="M7.19432 11.592L7.77561 12.2944L8.39648 11.6267L11.5059 8.28298L12.1162 7.62662L11.395 7.09447C9.97334 6.04548 8.08436 5.63226 6.97086 5.54771C6.05589 5.47823 5.39203 6.24313 5.44973 7.08135C5.58725 9.07916 6.43508 10.6746 7.19432 11.592Z"
      stroke="#AEAAB3"
      strokeWidth="1.6"
    />
    <path
      d="M7.19432 11.592L7.77561 12.2944L8.39648 11.6267L11.5059 8.28298L12.1162 7.62662L11.395 7.09447C9.97334 6.04548 8.08436 5.63226 6.97086 5.54771C6.05589 5.47823 5.39203 6.24313 5.44973 7.08135C5.58725 9.07916 6.43508 10.6746 7.19432 11.592Z"
      stroke="url(#paint2_linear_311_287393)"
      strokeWidth="1.6"
    />
    <path
      d="M7.19432 11.592L7.77561 12.2944L8.39648 11.6267L11.5059 8.28298L12.1162 7.62662L11.395 7.09447C9.97334 6.04548 8.08436 5.63226 6.97086 5.54771C6.05589 5.47823 5.39203 6.24313 5.44973 7.08135C5.58725 9.07916 6.43508 10.6746 7.19432 11.592Z"
      stroke="url(#paint3_radial_311_287393)"
      strokeWidth="1.6"
    />
    <g filter="url(#filter1_f_311_287393)">
      <path
        d="M6.31494 5.25684C7.22119 5.26725 9.89307 5.72559 11.4868 6.77246"
        stroke="url(#paint4_linear_311_287393)"
        strokeWidth="0.15"
        strokeLinecap="round"
      />
    </g>
    <g filter="url(#filter2_f_311_287393)">
      <path
        d="M6.61963 7.10059C6.70557 8.13965 7.229 9.74121 7.68213 10.3231"
        stroke="url(#paint5_linear_311_287393)"
        strokeWidth="0.15"
        strokeLinecap="round"
      />
    </g>
    <g filter="url(#filter3_ii_311_287393)">
      <path
        d="M26.3327 7.02641C26.2083 8.83411 25.4382 10.2744 24.7699 11.0819L21.6606 7.7382C22.9171 6.81102 24.6404 6.42361 25.6703 6.34541C26.0608 6.31576 26.3596 6.63572 26.3327 7.02641Z"
        fill="url(#paint6_linear_311_287393)"
      />
    </g>
    <path
      d="M25.3862 11.592L24.805 12.2944L24.1841 11.6267L21.0747 8.28298L20.4644 7.62662L21.1856 7.09447C22.6072 6.04548 24.4962 5.63226 25.6097 5.54771C26.5247 5.47823 27.1885 6.24313 27.1308 7.08135C26.9933 9.07916 26.1455 10.6746 25.3862 11.592Z"
      stroke="#AEAAB3"
      strokeWidth="1.6"
    />
    <path
      d="M25.3862 11.592L24.805 12.2944L24.1841 11.6267L21.0747 8.28298L20.4644 7.62662L21.1856 7.09447C22.6072 6.04548 24.4962 5.63226 25.6097 5.54771C26.5247 5.47823 27.1885 6.24313 27.1308 7.08135C26.9933 9.07916 26.1455 10.6746 25.3862 11.592Z"
      stroke="url(#paint7_linear_311_287393)"
      strokeWidth="1.6"
    />
    <path
      d="M25.3862 11.592L24.805 12.2944L24.1841 11.6267L21.0747 8.28298L20.4644 7.62662L21.1856 7.09447C22.6072 6.04548 24.4962 5.63226 25.6097 5.54771C26.5247 5.47823 27.1885 6.24313 27.1308 7.08135C26.9933 9.07916 26.1455 10.6746 25.3862 11.592Z"
      stroke="url(#paint8_radial_311_287393)"
      strokeWidth="1.6"
    />
    <path
      d="M25.3862 11.592L24.805 12.2944L24.1841 11.6267L21.0747 8.28298L20.4644 7.62662L21.1856 7.09447C22.6072 6.04548 24.4962 5.63226 25.6097 5.54771C26.5247 5.47823 27.1885 6.24313 27.1308 7.08135C26.9933 9.07916 26.1455 10.6746 25.3862 11.592Z"
      stroke="url(#paint9_radial_311_287393)"
      strokeWidth="1.6"
    />
    <g filter="url(#filter4_f_311_287393)">
      <path
        d="M27.0183 5.92871C27.4558 6.44434 27.284 8.95996 26.0652 10.8662"
        stroke="url(#paint10_linear_311_287393)"
        strokeWidth="0.15"
        strokeLinecap="round"
      />
    </g>
    <g filter="url(#filter5_f_311_287393)">
      <path
        d="M25.5962 6.74121C24.4816 6.74121 22.5181 7.47184 22.0649 8.05371"
        stroke="url(#paint11_linear_311_287393)"
        strokeOpacity="0.3"
        strokeWidth="0.15"
        strokeLinecap="round"
      />
    </g>
    <path
      d="M23.5049 8.72039C23.4449 8.64039 23.3749 8.56039 23.3049 8.49039C21.5349 6.44039 18.9249 5.15039 16.0049 5.15039C13.0849 5.15039 10.4749 6.44039 8.69488 8.48039C8.63488 8.56039 8.56488 8.64039 8.50488 8.72039L2.70488 15.6404C1.96488 16.5304 1.79488 17.7604 2.26488 18.8104C4.61488 24.0504 9.88488 27.7104 16.0049 27.7104C22.1249 27.7104 27.3949 24.0504 29.7449 18.8004C30.2149 17.7404 30.0549 16.5104 29.3049 15.6304L23.5049 8.72039Z"
      fill="url(#paint12_radial_311_287393)"
    />
    <path
      d="M23.5049 8.72039C23.4449 8.64039 23.3749 8.56039 23.3049 8.49039C21.5349 6.44039 18.9249 5.15039 16.0049 5.15039C13.0849 5.15039 10.4749 6.44039 8.69488 8.48039C8.63488 8.56039 8.56488 8.64039 8.50488 8.72039L2.70488 15.6404C1.96488 16.5304 1.79488 17.7604 2.26488 18.8104C4.61488 24.0504 9.88488 27.7104 16.0049 27.7104C22.1249 27.7104 27.3949 24.0504 29.7449 18.8004C30.2149 17.7404 30.0549 16.5104 29.3049 15.6304L23.5049 8.72039Z"
      fill="url(#paint13_radial_311_287393)"
    />
    <path
      d="M23.5049 8.72039C23.4449 8.64039 23.3749 8.56039 23.3049 8.49039C21.5349 6.44039 18.9249 5.15039 16.0049 5.15039C13.0849 5.15039 10.4749 6.44039 8.69488 8.48039C8.63488 8.56039 8.56488 8.64039 8.50488 8.72039L2.70488 15.6404C1.96488 16.5304 1.79488 17.7604 2.26488 18.8104C4.61488 24.0504 9.88488 27.7104 16.0049 27.7104C22.1249 27.7104 27.3949 24.0504 29.7449 18.8004C30.2149 17.7404 30.0549 16.5104 29.3049 15.6304L23.5049 8.72039Z"
      fill="url(#paint14_radial_311_287393)"
    />
    <path
      d="M23.5049 8.72039C23.4449 8.64039 23.3749 8.56039 23.3049 8.49039C21.5349 6.44039 18.9249 5.15039 16.0049 5.15039C13.0849 5.15039 10.4749 6.44039 8.69488 8.48039C8.63488 8.56039 8.56488 8.64039 8.50488 8.72039L2.70488 15.6404C1.96488 16.5304 1.79488 17.7604 2.26488 18.8104C4.61488 24.0504 9.88488 27.7104 16.0049 27.7104C22.1249 27.7104 27.3949 24.0504 29.7449 18.8004C30.2149 17.7404 30.0549 16.5104 29.3049 15.6304L23.5049 8.72039Z"
      fill="url(#paint15_radial_311_287393)"
    />
    <path
      d="M23.5049 8.72039C23.4449 8.64039 23.3749 8.56039 23.3049 8.49039C21.5349 6.44039 18.9249 5.15039 16.0049 5.15039C13.0849 5.15039 10.4749 6.44039 8.69488 8.48039C8.63488 8.56039 8.56488 8.64039 8.50488 8.72039L2.70488 15.6404C1.96488 16.5304 1.79488 17.7604 2.26488 18.8104C4.61488 24.0504 9.88488 27.7104 16.0049 27.7104C22.1249 27.7104 27.3949 24.0504 29.7449 18.8004C30.2149 17.7404 30.0549 16.5104 29.3049 15.6304L23.5049 8.72039Z"
      fill="url(#paint16_linear_311_287393)"
    />
    <path
      d="M23.5049 8.72039C23.4449 8.64039 23.3749 8.56039 23.3049 8.49039C21.5349 6.44039 18.9249 5.15039 16.0049 5.15039C13.0849 5.15039 10.4749 6.44039 8.69488 8.48039C8.63488 8.56039 8.56488 8.64039 8.50488 8.72039L2.70488 15.6404C1.96488 16.5304 1.79488 17.7604 2.26488 18.8104C4.61488 24.0504 9.88488 27.7104 16.0049 27.7104C22.1249 27.7104 27.3949 24.0504 29.7449 18.8004C30.2149 17.7404 30.0549 16.5104 29.3049 15.6304L23.5049 8.72039Z"
      fill="url(#paint17_radial_311_287393)"
    />
    <path
      d="M23.5049 8.72039C23.4449 8.64039 23.3749 8.56039 23.3049 8.49039C21.5349 6.44039 18.9249 5.15039 16.0049 5.15039C13.0849 5.15039 10.4749 6.44039 8.69488 8.48039C8.63488 8.56039 8.56488 8.64039 8.50488 8.72039L2.70488 15.6404C1.96488 16.5304 1.79488 17.7604 2.26488 18.8104C4.61488 24.0504 9.88488 27.7104 16.0049 27.7104C22.1249 27.7104 27.3949 24.0504 29.7449 18.8004C30.2149 17.7404 30.0549 16.5104 29.3049 15.6304L23.5049 8.72039Z"
      fill="url(#paint18_radial_311_287393)"
    />
    <path
      d="M23.5049 8.72039C23.4449 8.64039 23.3749 8.56039 23.3049 8.49039C21.5349 6.44039 18.9249 5.15039 16.0049 5.15039C13.0849 5.15039 10.4749 6.44039 8.69488 8.48039C8.63488 8.56039 8.56488 8.64039 8.50488 8.72039L2.70488 15.6404C1.96488 16.5304 1.79488 17.7604 2.26488 18.8104C4.61488 24.0504 9.88488 27.7104 16.0049 27.7104C22.1249 27.7104 27.3949 24.0504 29.7449 18.8004C30.2149 17.7404 30.0549 16.5104 29.3049 15.6304L23.5049 8.72039Z"
      fill="url(#paint19_radial_311_287393)"
    />
    <path
      d="M11.785 13.21C11.755 13.21 11.735 13.21 11.705 13.21C10.595 13.21 9.55499 13.7 8.83498 14.54L6.24498 17.52C5.01498 18.94 4.47499 20.8 4.75498 22.65C7.39499 25.63 11.205 27.55 15.475 27.7V17.01C15.465 14.95 13.815 13.25 11.785 13.21Z"
      fill="url(#paint20_linear_311_287393)"
    />
    <path
      d="M20.2452 13.21C20.2752 13.21 20.2952 13.21 20.3252 13.21C21.4352 13.21 22.4752 13.7 23.1952 14.54L25.7852 17.52C27.0152 18.94 27.5552 20.8 27.2752 22.65C24.6352 25.63 20.8252 27.55 16.5552 27.7V17.01C16.5652 14.95 18.2152 13.25 20.2452 13.21Z"
      fill="url(#paint21_linear_311_287393)"
    />
    <path
      d="M11.689 14.8196C11.009 14.8096 10.399 15.1096 9.99896 15.5896L7.39896 18.5796C5.42896 20.8496 6.01896 24.3696 8.64896 25.8296C10.209 26.6996 11.949 27.2996 13.789 27.5596V17.0096C13.789 15.8296 12.859 14.8396 11.689 14.8196Z"
      fill="url(#paint22_linear_311_287393)"
    />
    <path
      d="M11.689 14.8196C11.009 14.8096 10.399 15.1096 9.99896 15.5896L7.39896 18.5796C5.42896 20.8496 6.01896 24.3696 8.64896 25.8296C10.209 26.6996 11.949 27.2996 13.789 27.5596V17.0096C13.789 15.8296 12.859 14.8396 11.689 14.8196Z"
      fill="url(#paint23_radial_311_287393)"
    />
    <path
      d="M20.2748 14.8196C20.9548 14.8096 21.5648 15.1096 21.9648 15.5896L24.5648 18.5796C26.5348 20.8496 25.9448 24.3696 23.3148 25.8296C21.7548 26.6996 20.0148 27.2996 18.1748 27.5596V17.0096C18.1748 15.8296 19.1048 14.8396 20.2748 14.8196Z"
      fill="url(#paint24_linear_311_287393)"
    />
    <path
      d="M20.2748 14.8196C20.9548 14.8096 21.5648 15.1096 21.9648 15.5896L24.5648 18.5796C26.5348 20.8496 25.9448 24.3696 23.3148 25.8296C21.7548 26.6996 20.0148 27.2996 18.1748 27.5596V17.0096C18.1748 15.8296 19.1048 14.8396 20.2748 14.8196Z"
      fill="url(#paint25_radial_311_287393)"
    />
    <g filter="url(#filter6_f_311_287393)">
      <path
        d="M19.8735 19.2344C19.4635 19.2344 19.1235 18.8944 19.1235 18.4844V16.9844C19.1235 16.5744 19.4635 16.2344 19.8735 16.2344C20.2835 16.2344 20.6235 16.5744 20.6235 16.9844V18.4844C20.6235 18.9044 20.2835 19.2344 19.8735 19.2344Z"
        fill="#574F5E"
      />
    </g>
    <g filter="url(#filter7_f_311_287393)">
      <path
        d="M11.7549 19.2344C11.3449 19.2344 11.0049 18.8944 11.0049 18.4844V16.9844C11.0049 16.5744 11.3449 16.2344 11.7549 16.2344C12.1649 16.2344 12.5049 16.5744 12.5049 16.9844V18.4844C12.5049 18.9044 12.1649 19.2344 11.7549 19.2344Z"
        fill="#574F5E"
      />
    </g>
    <path
      d="M20.0522 19C19.6422 19 19.3022 18.66 19.3022 18.25V16.75C19.3022 16.34 19.6422 16 20.0522 16C20.4622 16 20.8022 16.34 20.8022 16.75V18.25C20.8022 18.67 20.4622 19 20.0522 19Z"
      fill="url(#paint26_linear_311_287393)"
    />
    <path
      d="M20.0522 19C19.6422 19 19.3022 18.66 19.3022 18.25V16.75C19.3022 16.34 19.6422 16 20.0522 16C20.4622 16 20.8022 16.34 20.8022 16.75V18.25C20.8022 18.67 20.4622 19 20.0522 19Z"
      fill="url(#paint27_linear_311_287393)"
    />
    <path
      d="M12.0049 19C11.5949 19 11.2549 18.66 11.2549 18.25V16.75C11.2549 16.34 11.5949 16 12.0049 16C12.4149 16 12.7549 16.34 12.7549 16.75V18.25C12.7549 18.67 12.4149 19 12.0049 19Z"
      fill="url(#paint28_linear_311_287393)"
    />
    <path
      d="M12.0049 19C11.5949 19 11.2549 18.66 11.2549 18.25V16.75C11.2549 16.34 11.5949 16 12.0049 16C12.4149 16 12.7549 16.34 12.7549 16.75V18.25C12.7549 18.67 12.4149 19 12.0049 19Z"
      fill="url(#paint29_linear_311_287393)"
    />
    <path
      d="M21.375 22.9115C21.375 20.8178 19.705 19.1104 17.635 19.1104H14.365C12.305 19.1104 10.625 20.8077 10.625 22.9115C10.625 25.5235 12.635 27.6883 15.205 27.8306C15.475 27.8408 15.745 27.851 16.005 27.851C16.265 27.851 16.535 27.8408 16.795 27.8306C19.365 27.6985 21.375 25.5337 21.375 22.9115Z"
      fill="url(#paint30_radial_311_287393)"
    />
    <path
      d="M21.375 22.9115C21.375 20.8178 19.705 19.1104 17.635 19.1104H14.365C12.305 19.1104 10.625 20.8077 10.625 22.9115C10.625 25.5235 12.635 27.6883 15.205 27.8306C15.475 27.8408 15.745 27.851 16.005 27.851C16.265 27.851 16.535 27.8408 16.795 27.8306C19.365 27.6985 21.375 25.5337 21.375 22.9115Z"
      fill="url(#paint31_radial_311_287393)"
    />
    <path
      d="M21.375 22.9115C21.375 20.8178 19.705 19.1104 17.635 19.1104H14.365C12.305 19.1104 10.625 20.8077 10.625 22.9115C10.625 25.5235 12.635 27.6883 15.205 27.8306C15.475 27.8408 15.745 27.851 16.005 27.851C16.265 27.851 16.535 27.8408 16.795 27.8306C19.365 27.6985 21.375 25.5337 21.375 22.9115Z"
      fill="url(#paint32_radial_311_287393)"
    />
    <path
      d="M21.375 22.9115C21.375 20.8178 19.705 19.1104 17.635 19.1104H14.365C12.305 19.1104 10.625 20.8077 10.625 22.9115C10.625 25.5235 12.635 27.6883 15.205 27.8306C15.475 27.8408 15.745 27.851 16.005 27.851C16.265 27.851 16.535 27.8408 16.795 27.8306C19.365 27.6985 21.375 25.5337 21.375 22.9115Z"
      fill="url(#paint33_radial_311_287393)"
    />
    <path
      d="M21.375 22.9115C21.375 20.8178 19.705 19.1104 17.635 19.1104H14.365C12.305 19.1104 10.625 20.8077 10.625 22.9115C10.625 25.5235 12.635 27.6883 15.205 27.8306C15.475 27.8408 15.745 27.851 16.005 27.851C16.265 27.851 16.535 27.8408 16.795 27.8306C19.365 27.6985 21.375 25.5337 21.375 22.9115Z"
      fill="url(#paint34_radial_311_287393)"
    />
    <path
      d="M14.0752 20.7704L15.3252 22.0304C15.7052 22.4104 16.3152 22.4104 16.6852 22.0304L17.9352 20.7704C18.5452 20.1604 18.1152 19.1104 17.2552 19.1104H14.7552C13.8952 19.1104 13.4652 20.1604 14.0752 20.7704Z"
      fill="url(#paint35_linear_311_287393)"
    />
    <path
      d="M14.0752 20.7704L15.3252 22.0304C15.7052 22.4104 16.3152 22.4104 16.6852 22.0304L17.9352 20.7704C18.5452 20.1604 18.1152 19.1104 17.2552 19.1104H14.7552C13.8952 19.1104 13.4652 20.1604 14.0752 20.7704Z"
      fill="url(#paint36_radial_311_287393)"
    />
    <path
      d="M14.0752 20.7704L15.3252 22.0304C15.7052 22.4104 16.3152 22.4104 16.6852 22.0304L17.9352 20.7704C18.5452 20.1604 18.1152 19.1104 17.2552 19.1104H14.7552C13.8952 19.1104 13.4652 20.1604 14.0752 20.7704Z"
      fill="url(#paint37_linear_311_287393)"
    />
    <path
      d="M14.0752 20.7704L15.3252 22.0304C15.7052 22.4104 16.3152 22.4104 16.6852 22.0304L17.9352 20.7704C18.5452 20.1604 18.1152 19.1104 17.2552 19.1104H14.7552C13.8952 19.1104 13.4652 20.1604 14.0752 20.7704Z"
      fill="url(#paint38_linear_311_287393)"
    />
    <path
      d="M14.0752 20.7704L15.3252 22.0304C15.7052 22.4104 16.3152 22.4104 16.6852 22.0304L17.9352 20.7704C18.5452 20.1604 18.1152 19.1104 17.2552 19.1104H14.7552C13.8952 19.1104 13.4652 20.1604 14.0752 20.7704Z"
      fill="url(#paint39_radial_311_287393)"
    />
    <g filter="url(#filter8_f_311_287393)">
      <ellipse cx="25.6198" cy="17.2904" rx="3.47628" ry="0.919355" fill="#E1DDE6" fillOpacity="0.5" />
    </g>
    <g filter="url(#filter9_f_311_287393)">
      <path
        d="M17.4248 7.12109C18.0498 7.14193 19.5998 7.54609 20.7998 8.99609C21.9998 10.4461 25.3415 14.5169 26.8623 16.3711"
        stroke="#E1DDE6"
        strokeLinecap="round"
      />
    </g>
    <path
      d="M12.8984 23.3975C12.8984 23.9287 13.2266 24.9053 14.4141 24.9053C15.6016 24.9053 15.9479 23.9782 16 23.499"
      stroke="url(#paint40_radial_311_287393)"
      strokeLinecap="round"
    />
    <path
      d="M12.8984 23.3975C12.8984 23.9287 13.2266 24.9053 14.4141 24.9053C15.6016 24.9053 15.9479 23.9782 16 23.499"
      stroke="url(#paint41_radial_311_287393)"
      strokeLinecap="round"
    />
    <path
      d="M12.8984 23.3975C12.8984 23.9287 13.2266 24.9053 14.4141 24.9053C15.6016 24.9053 15.9479 23.9782 16 23.499"
      stroke="url(#paint42_radial_311_287393)"
      strokeLinecap="round"
    />
    <path
      d="M19.1016 23.3975C19.1016 23.9287 18.7734 24.9053 17.5859 24.9053C16.3984 24.9053 16.0521 23.9782 16 23.499"
      stroke="url(#paint43_radial_311_287393)"
      strokeLinecap="round"
    />
    <path
      d="M19.1016 23.3975C19.1016 23.9287 18.7734 24.9053 17.5859 24.9053C16.3984 24.9053 16.0521 23.9782 16 23.499"
      stroke="url(#paint44_radial_311_287393)"
      strokeLinecap="round"
    />
    <defs>
      <filter
        id="filter0_ii_311_287393"
        x="4.64648"
        y="4.74316"
        width="9.16602"
        height="9.26367"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" />
        <feGaussianBlur stdDeviation="0.25" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.26434 0 0 0 0 0.25276 0 0 0 0 0.2875 0 0 0 0.5 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_311_287393" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="0.25" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.26434 0 0 0 0 0.25276 0 0 0 0 0.2875 0 0 0 0.5 0" />
        <feBlend mode="normal" in2="effect1_innerShadow_311_287393" result="effect2_innerShadow_311_287393" />
      </filter>
      <filter
        id="filter1_f_311_287393"
        x="5.93975"
        y="4.88164"
        width="5.92227"
        height="2.26602"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.15" result="effect1_foregroundBlur_311_287393" />
      </filter>
      <filter
        id="filter2_f_311_287393"
        x="6.29443"
        y="6.77539"
        width="1.71289"
        height="3.87305"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.125" result="effect1_foregroundBlur_311_287393" />
      </filter>
      <filter
        id="filter3_ii_311_287393"
        x="19.2681"
        y="4.74316"
        width="9.16602"
        height="9.26367"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" />
        <feGaussianBlur stdDeviation="0.25" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.26434 0 0 0 0 0.25276 0 0 0 0 0.2875 0 0 0 0.5 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_311_287393" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="0.25" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.26434 0 0 0 0 0.25276 0 0 0 0 0.2875 0 0 0 0.5 0" />
        <feBlend mode="normal" in2="effect1_innerShadow_311_287393" result="effect2_innerShadow_311_287393" />
      </filter>
      <filter
        id="filter4_f_311_287393"
        x="25.6902"
        y="5.55352"
        width="1.92178"
        height="5.68789"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.15" result="effect1_foregroundBlur_311_287393" />
      </filter>
      <filter
        id="filter5_f_311_287393"
        x="21.7397"
        y="6.41602"
        width="4.18164"
        height="1.96289"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.125" result="effect1_foregroundBlur_311_287393" />
      </filter>
      <filter
        id="filter6_f_311_287393"
        x="18.6235"
        y="15.7344"
        width="2.5"
        height="4"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_311_287393" />
      </filter>
      <filter
        id="filter7_f_311_287393"
        x="10.5049"
        y="15.7344"
        width="2.5"
        height="4"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_311_287393" />
      </filter>
      <filter
        id="filter8_f_311_287393"
        x="20.1436"
        y="14.3711"
        width="10.9526"
        height="5.83887"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_311_287393" />
      </filter>
      <filter
        id="filter9_f_311_287393"
        x="13.9248"
        y="3.62109"
        width="16.4375"
        height="16.25"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur_311_287393" />
      </filter>
      <linearGradient
        id="paint0_linear_311_287393"
        x1="8.57524"
        y1="6.33008"
        x2="8.20578"
        y2="9.3507"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#656169" />
        <stop offset="1" stopColor="#77747C" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_311_287393"
        x1="8.72141"
        y1="9.19445"
        x2="7.75266"
        y2="8.2882"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5A585D" />
        <stop offset="0.737991" stopColor="#5A585D" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_311_287393"
        x1="9.69016"
        y1="10.0226"
        x2="7.12766"
        y2="7.27258"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A19EA5" />
        <stop offset="1" stopColor="#A19EA5" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint3_radial_311_287393"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(9.53391 7.52258) rotate(166.185) scale(4.95587 10.6481)"
      >
        <stop offset="0.783876" stopColor="#BDBAC0" stopOpacity="0" />
        <stop offset="1" stopColor="#BDBAC0" />
      </radialGradient>
      <linearGradient
        id="paint4_linear_311_287393"
        x1="11.0493"
        y1="6.86621"
        x2="6.33057"
        y2="6.00684"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CBC7CE" />
        <stop offset="1" stopColor="#CBC7CE" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_311_287393"
        x1="10.4745"
        y1="8.61996"
        x2="5.75573"
        y2="7.76059"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CBC7CE" />
        <stop offset="1" stopColor="#CBC7CE" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_311_287393"
        x1="24.0053"
        y1="5.77258"
        x2="24.5336"
        y2="8.70601"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#656169" />
        <stop offset="1" stopColor="#77747C" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_311_287393"
        x1="24.0053"
        y1="4.42883"
        x2="25.4529"
        y2="7.27258"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A19EA5" />
        <stop offset="1" stopColor="#A19EA5" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint8_radial_311_287393"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(23.0467 7.52258) rotate(13.8154) scale(4.95587 10.6481)"
      >
        <stop offset="0.783876" stopColor="#BDBAC0" stopOpacity="0" />
        <stop offset="1" stopColor="#BDBAC0" />
      </radialGradient>
      <radialGradient
        id="paint9_radial_311_287393"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(28.5023 5.25695) rotate(103.069) scale(1.79654 2.92202)"
      >
        <stop stopColor="#BFBDC2" />
        <stop offset="1" stopColor="#BFBDC2" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint10_linear_311_287393"
        x1="21.5314"
        y1="6.86621"
        x2="26.2502"
        y2="6.00684"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CBC7CE" />
        <stop offset="1" stopColor="#CBC7CE" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_311_287393"
        x1="22.106"
        y1="8.61996"
        x2="26.8247"
        y2="7.76059"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CBC7CE" />
        <stop offset="1" stopColor="#CBC7CE" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint12_radial_311_287393"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(20.1274 9.46039) rotate(100.521) scale(17.7992 33.527)"
      >
        <stop stopColor="#C6C5C7" />
        <stop offset="1" stopColor="#B0A7B9" />
      </radialGradient>
      <radialGradient
        id="paint13_radial_311_287393"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(28.9399 24.8979) rotate(-133.345) scale(7.64878 26.8589)"
      >
        <stop offset="0.0942793" stopColor="#978EA0" />
        <stop offset="0.64732" stopColor="#978EA0" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint14_radial_311_287393"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(9.06494 18.3979) rotate(90) scale(4.375 6.91532)"
      >
        <stop offset="0.479167" stopColor="#9C93A7" />
        <stop offset="1" stopColor="#A098A9" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint15_radial_311_287393"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(23.8149 19.3354) rotate(90) scale(3.5 5.53226)"
      >
        <stop offset="0.277778" stopColor="#A99EB6" />
        <stop offset="1" stopColor="#A098A9" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint16_linear_311_287393"
        x1="3.37744"
        y1="10.8979"
        x2="7.25244"
        y2="14.4604"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E1DDE6" />
        <stop offset="0.707654" stopColor="#E1DDE6" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint17_radial_311_287393"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17.5024 11.8354) rotate(145.919) scale(20.299 22.0288)"
      >
        <stop offset="0.730729" stopColor="#E1DDE6" stopOpacity="0" />
        <stop offset="1" stopColor="#E1DDE6" />
      </radialGradient>
      <radialGradient
        id="paint18_radial_311_287393"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16.0062 23.7104) rotate(-90) scale(20.375 19.4539)"
      >
        <stop offset="0.868167" stopColor="#B0A9B8" stopOpacity="0" />
        <stop offset="1" stopColor="#B0A9B8" />
      </radialGradient>
      <radialGradient
        id="paint19_radial_311_287393"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(11.5649 8.77289) rotate(55.2348) scale(6.46686 7.14113)"
      >
        <stop stopColor="#B6B2B9" />
        <stop offset="1" stopColor="#B6B2B9" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint20_linear_311_287393"
        x1="13.8776"
        y1="13.96"
        x2="11.3775"
        y2="26.8975"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E1DAEA" />
        <stop offset="1" stopColor="#B7A8C7" />
      </linearGradient>
      <linearGradient
        id="paint21_linear_311_287393"
        x1="21.9514"
        y1="15.835"
        x2="26.6277"
        y2="26.335"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E6E1ED" />
        <stop offset="1" stopColor="#B7A8C7" />
      </linearGradient>
      <linearGradient
        id="paint22_linear_311_287393"
        x1="13.4365"
        y1="14.8193"
        x2="13.4365"
        y2="29.0846"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6F6C72" />
        <stop offset="1" stopColor="#574F60" />
      </linearGradient>
      <radialGradient
        id="paint23_radial_311_287393"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(8.87402 19.1471) rotate(109.567) scale(7.89334 19.6076)"
      >
        <stop offset="0.652159" stopColor="#8664A8" stopOpacity="0" />
        <stop offset="1" stopColor="#8664A8" />
      </radialGradient>
      <linearGradient
        id="paint24_linear_311_287393"
        x1="25.3809"
        y1="14.8193"
        x2="25.3809"
        y2="29.7721"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#868488" />
        <stop offset="1" stopColor="#574F60" />
      </linearGradient>
      <radialGradient
        id="paint25_radial_311_287393"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(20.8184 19.1471) rotate(76.6492) scale(8.64617 21.4777)"
      >
        <stop offset="0.652159" stopColor="#8664A8" stopOpacity="0" />
        <stop offset="1" stopColor="#8664A8" />
      </radialGradient>
      <linearGradient
        id="paint26_linear_311_287393"
        x1="20.7149"
        y1="17.5"
        x2="19.8895"
        y2="17.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.225" stopColor="#594C4F" />
        <stop offset="1" stopColor="#392833" />
      </linearGradient>
      <linearGradient
        id="paint27_linear_311_287393"
        x1="21.0366"
        y1="19.5249"
        x2="21.0366"
        y2="17.9347"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#552D52" />
        <stop offset="1" stopColor="#552D52" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint28_linear_311_287393"
        x1="12.6676"
        y1="17.5"
        x2="11.8422"
        y2="17.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.225" stopColor="#594C4F" />
        <stop offset="1" stopColor="#392833" />
      </linearGradient>
      <linearGradient
        id="paint29_linear_311_287393"
        x1="12.9893"
        y1="19.5249"
        x2="12.9893"
        y2="17.9347"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#552D52" />
        <stop offset="1" stopColor="#552D52" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint30_radial_311_287393"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(19.3776 20.7365) rotate(111.165) scale(9.35488 9.26794)"
      >
        <stop stopColor="#DFDBE4" />
        <stop offset="1" stopColor="#978EA2" />
      </radialGradient>
      <radialGradient
        id="paint31_radial_311_287393"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16 29.7416) rotate(-90) scale(7.09375 14.8776)"
      >
        <stop stopColor="#8664A8" />
        <stop offset="1" stopColor="#8664A8" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint32_radial_311_287393"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16.5026 22.8354) rotate(145.763) scale(7.10955 6.98471)"
      >
        <stop offset="0.677908" stopColor="#E1DDE6" stopOpacity="0" />
        <stop offset="1" stopColor="#E1DDE6" />
      </radialGradient>
      <radialGradient
        id="paint33_radial_311_287393"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(15.4401 25.4604) rotate(-49.1028) scale(8.40074 8.25323)"
      >
        <stop offset="0.609581" stopColor="#ABA5B1" stopOpacity="0" />
        <stop offset="1" stopColor="#ABA5B1" />
      </radialGradient>
      <radialGradient
        id="paint34_radial_311_287393"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(15.4401 25.1479) rotate(-90) scale(6.0375 9.5625)"
      >
        <stop offset="0.561023" stopColor="#ABA5B1" stopOpacity="0" />
        <stop offset="1" stopColor="#ABA5B1" />
      </radialGradient>
      <linearGradient
        id="paint35_linear_311_287393"
        x1="17.2279"
        y1="18.8445"
        x2="14.3976"
        y2="21.321"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.191489" stopColor="#5F5254" />
        <stop offset="1" stopColor="#2E2127" />
      </linearGradient>
      <radialGradient
        id="paint36_radial_311_287393"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16.3242 19.7255) rotate(132.042) scale(2.00441 3.67132)"
      >
        <stop offset="0.462647" stopColor="#34222C" stopOpacity="0" />
        <stop offset="0.955607" stopColor="#34222C" />
      </radialGradient>
      <linearGradient
        id="paint37_linear_311_287393"
        x1="16.3588"
        y1="19.9789"
        x2="16.3588"
        y2="18.4253"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.146552" stopColor="#34222C" stopOpacity="0" />
        <stop offset="0.872727" stopColor="#34222C" />
      </linearGradient>
      <linearGradient
        id="paint38_linear_311_287393"
        x1="17.1271"
        y1="22.5845"
        x2="17.1271"
        y2="20.2358"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#552D52" />
        <stop offset="1" stopColor="#552D52" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint39_radial_311_287393"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(18.784 21.4291) rotate(-161.02) scale(5.71706 2.5936)"
      >
        <stop offset="0.827602" stopColor="#5C5458" stopOpacity="0" />
        <stop offset="0.935007" stopColor="#5C5458" />
      </radialGradient>
      <radialGradient
        id="paint40_radial_311_287393"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(14.7057 22.8193) rotate(100.835) scale(3.3249 2.8652)"
      >
        <stop stopColor="#261317" />
        <stop offset="0.588542" stopColor="#4E4647" />
        <stop offset="0.747451" stopColor="#2F172A" />
      </radialGradient>
      <radialGradient
        id="paint41_radial_311_287393"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(15.5338 23.9131) rotate(138.918) scale(0.808433 1.03129)"
      >
        <stop stopColor="#261317" />
        <stop offset="1" stopColor="#261317" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint42_radial_311_287393"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(12.7369 22.5225) rotate(50.1104) scale(1.36439 1.02305)"
      >
        <stop stopColor="#261317" />
        <stop offset="1" stopColor="#261317" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint43_radial_311_287393"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17.6901 23.3193) rotate(81.3546) scale(2.70258 2.8068)"
      >
        <stop stopColor="#261317" />
        <stop offset="0.588542" stopColor="#4E4647" />
        <stop offset="0.747451" stopColor="#2F172A" />
      </radialGradient>
      <radialGradient
        id="paint44_radial_311_287393"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(15.7057 23.0381) rotate(43.5057) scale(1.27101 1.40586)"
      >
        <stop stopColor="#261317" />
        <stop offset="1" stopColor="#261317" stopOpacity="0" />
      </radialGradient>
    </defs>
  </SvgIcon>
);

export default Racoon;
