import { styled } from '@mui/material';
import { getAccentColor, NeutralColors } from 'portfolio3/styles';

import StyledBaseButton from '../bases/BaseButton.styled';

const PrimaryButton = styled(StyledBaseButton)(({ theme }) => {
  const accentColor = theme.palette.accentColor;
  const color_100 = getAccentColor(accentColor, '100');
  const color_80 = getAccentColor(accentColor, '80');
  const color_24 = getAccentColor(accentColor, '24');
  const color_200 = getAccentColor(accentColor, '200');

  return {
    color: NeutralColors.light_neutrals_0,
    backgroundColor: color_100,
    border: 0,

    '&:hover': {
      backgroundColor: color_80,
    },

    '&:active': {
      backgroundColor: color_200,
    },

    '&:disabled': {
      backgroundColor: color_24,
    },
  };
});

export default PrimaryButton;
