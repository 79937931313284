import { FC } from 'react';

import { styled } from '@mui/material';
import { getAccentColor } from 'portfolio3/styles';
import { ISwitcherButtonBaseProps } from 'portfolio3/ui-kit';
import { getFocusStateStyles } from 'portfolio3/ui-kit/utils';

interface IStyledButtonProps {
  checked: boolean;
}

const StyledButton = styled('button')<IStyledButtonProps>(({ theme, checked }) => {
  const accentColor = getAccentColor(theme.palette.accentColor, checked ? '200' : '48');

  return {
    color: accentColor,

    fontSize: '18px',
    lineHeight: '28px',
    fontWeight: 600,

    cursor: 'pointer',
    border: 'none',
    background: 'none',
    padding: 0,

    '&:focus-visible': {
      ...getFocusStateStyles(),
    },

    [theme.breakpoints.down('commonSm')]: {
      fontSize: '15px',
      lineHeight: '28px',
    },
  };
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDiagnosticTabButtonProps extends ISwitcherButtonBaseProps {}

const DiagnosticTabButton: FC<IDiagnosticTabButtonProps> = ({ content, InputProps, ...props }) => {
  return (
    <StyledButton checked={InputProps?.checked ?? false} {...props}>
      {content}
    </StyledButton>
  );
};

export default DiagnosticTabButton;
