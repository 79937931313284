import { useContext, useEffect, useState } from 'react';

import { useFormControl } from '@mui/material';

import { DatePickerContext } from '../context';

const useCommonDatePickerState = (isOpen: boolean, hasFilledSegments: boolean) => {
  const { renderMode } = useContext(DatePickerContext);
  const formControl = useFormControl();
  const [isFocused, setFocused] = useState(false);

  useEffect(() => {
    if (hasFilledSegments) {
      formControl?.onFilled();
    } else {
      formControl?.onEmpty();
    }
  }, [formControl, hasFilledSegments]);

  useEffect(() => {
    if (isOpen || isFocused) {
      formControl?.onFocus();
    } else {
      formControl?.onBlur();
    }
  }, [formControl, isFocused, isOpen]);

  const isInputVisible = renderMode !== 'floating' || formControl?.focused || hasFilledSegments;

  return {
    isInputHidden: !isInputVisible,
    setFocused,
  };
};

export default useCommonDatePickerState;
