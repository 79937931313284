/* eslint-disable prettier/prettier */
/* eslint-disable */

import clsx from 'clsx';
import React from 'react';
import { getDoughnutColor, getDoughnutData } from 'utils';
import ResultDoughnut from 'components/common/ResultDoughnut';
import { IPerformanceSubjectTopic } from '../../../../types';

import './index.scss';

interface ISubjectTopicsMobileProps {
  topics: IPerformanceSubjectTopic[];
}

const SubjectTopicsMobile: React.FC<ISubjectTopicsMobileProps> = ({topics}) => {
  return (
    <div className="subject-topics-mobile-wrapper">
      {topics.map((topic) => {
        return (
          <div key={topic.name} className="subject-topics-mobile">
            <div className="subject-topics-mobile__main-info">
              <div className="subject-topics-mobile__title">{topic.name}</div>
              <div className="subject-topics-mobile__data">
                <div className="subject-topics-mobile__lesson">
                  <p className="subject-topics-mobile__lesson-significative">Уроков:</p>
                  <div className="subject-topics-mobile__lesson-chart">
                    <ResultDoughnut
                      borderThickness={70}
                      total={null}
                      data={getDoughnutData(topic.passedLessons, topic.totalLessons)}
                      color={getDoughnutColor(topic.passedLessons, topic.totalLessons)}
                    />
                  </div>
                  <p className={clsx({'subject-topics-mobile__lesson-chart--grayed': topic.passedLessons === 0})}>
                    {topic.passedLessons} из {topic.totalLessons}
                  </p>
                </div>
                {
                  topic.averageResult && (
                    <div>
                      <p className="subject-topics-mobile__lesson-significative">Ср. балл:</p>
                      <span className="subject-topics-mobile__lesson-result">{topic.averageResult.toString().replace('.', ',')}</span>
                    </div>
                  )
                }
              </div>
            </div>
            {/* TODO вернуть когда будет доступен подробный просмотр тем */}
            {/* <div className="subject-topics__more-btn">
              <IconArrowRight fill="#7B819B" />
            </div> */}
          </div>
        )
      })}
    </div>
  )
}

export default SubjectTopicsMobile
