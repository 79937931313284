/* eslint-disable prettier/prettier */
/* eslint-disable */

export enum subcategoryKeys {
  HUMANITARIAN = 'humanitarian',
  ENGINEER = 'engineer',
  LANGUAGE = 'language',
  SOCIAL = 'social',
  MILITARY = 'military',
  LOCAL = 'local',
  ECOLOGY = 'ecology',
  SPORT = 'sport',
  TOURISM = 'tourism',
  THEATRE = 'theatre',
  MUSIC = 'music',
  DECORATE = 'decorate',
  PAINING = 'painting',
  LITERATURE = 'literature',
  CHOREOGRAPHY = 'choreography',
  NATURAL = 'natural',
  IT = 'it'
}

export const subcategoryValues: Record<string, string> = {
  [subcategoryKeys.HUMANITARIAN]: 'Гуманитарный профиль',
  [subcategoryKeys.ENGINEER]: 'Инженерно-технический профиль',
  [subcategoryKeys.LANGUAGE]: 'Иностранные языки и международные отношения',
  [subcategoryKeys.SOCIAL]: 'Социальная деятельность и волонтерство',
  [subcategoryKeys.MILITARY]: 'Военно-патриотическая деятельность',
  [subcategoryKeys.LOCAL]: 'Краеведение',
  [subcategoryKeys.ECOLOGY]: 'Экология',
  [subcategoryKeys.SPORT]: 'Спорт',
  [subcategoryKeys.TOURISM]: 'Туризм',
  [subcategoryKeys.THEATRE]: 'Театральное, цирковое и  киноискусство',
  [subcategoryKeys.MUSIC]: 'Музыка',
  [subcategoryKeys.DECORATE]: 'Декоративно-прикладное искусство',
  [subcategoryKeys.PAINING]: 'Изобразительное искусство',
  [subcategoryKeys.LITERATURE]: 'Литературно-словесное творчество',
  [subcategoryKeys.CHOREOGRAPHY]: 'Хореография',
  [subcategoryKeys.NATURAL]: 'Естественно-научный профиль',
  [subcategoryKeys.IT]: 'ИТ-профиль'
};

// subcategory_ref
export const subcategoryCodes: Record<subcategoryKeys, number> = {
  [subcategoryKeys.HUMANITARIAN]: 5,
  [subcategoryKeys.ENGINEER]: 6,
  [subcategoryKeys.LANGUAGE]: 7,
  [subcategoryKeys.SOCIAL]: 8,
  [subcategoryKeys.MILITARY]: 9,
  [subcategoryKeys.LOCAL]: 10,
  [subcategoryKeys.ECOLOGY]: 11,
  [subcategoryKeys.SPORT]: 12,
  [subcategoryKeys.TOURISM]: 13,
  [subcategoryKeys.THEATRE]: 14,
  [subcategoryKeys.MUSIC]: 15,
  [subcategoryKeys.DECORATE]: 16,
  [subcategoryKeys.PAINING]: 17,
  [subcategoryKeys.LITERATURE]: 18,
  [subcategoryKeys.CHOREOGRAPHY]: 19,
  [subcategoryKeys.NATURAL]: 20,
  [subcategoryKeys.IT]: 21
};
