import { GET_VIEWED_FUNCTIONS } from 'actions';
import { FAILURE, REQUEST, SUCCESS } from 'actions/utils';
import { ICommonResponseObject } from 'api/types';
import connectReducers from 'utils/connectReducers';

import { IReducers } from '.';
import genericReducers from './genericReducer';

export type ViewedFunctionsRequestState = ICommonResponseObject<null>;

const initialState: ViewedFunctionsRequestState = {
  content: null,
  loading: false,
};

const viewedFunctionsRequest: IReducers<ViewedFunctionsRequestState> = {
  [GET_VIEWED_FUNCTIONS[REQUEST]]: genericReducers().get.request,
  [GET_VIEWED_FUNCTIONS[SUCCESS]]: genericReducers().get.success,
  [GET_VIEWED_FUNCTIONS[FAILURE]]: genericReducers().get.failure,
};

export default connectReducers(initialState, viewedFunctionsRequest);
