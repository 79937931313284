import React from 'react';

import { SvgIconProps } from '@mui/material';
import { IconMetaSkillBrain, IconMetaSkillChart, IconMetaSkillLevelSlider, IconMetaSkillTeacup } from 'icons/proftech';

export enum MetaSkillsKeys {
  SOCIABILITY = 'sociability',
  EMOTIONAL_INTELLIGENCE = 'emotionalIntelligence',
  EFFICIENCY = 'efficiency',
  CAREER_MANAGEMENT = 'careerManagement',
}

// meta_skill_ref
// представлены только главные элементы справочника
export const MetaSkillsTypeCodes: Record<MetaSkillsKeys, number> = {
  sociability: 1,
  emotionalIntelligence: 2,
  efficiency: 3,
  careerManagement: 4,
};

export const MetaSkillsCodeToIcon: Record<number, React.FC<SvgIconProps> | undefined> = {
  [MetaSkillsTypeCodes.sociability]: IconMetaSkillTeacup,
  [MetaSkillsTypeCodes.emotionalIntelligence]: IconMetaSkillBrain,
  [MetaSkillsTypeCodes.efficiency]: IconMetaSkillChart,
  [MetaSkillsTypeCodes.careerManagement]: IconMetaSkillLevelSlider,
};
