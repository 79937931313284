/* eslint-disable prettier/prettier */
/* eslint-disable */

import { isDefined } from '.';
import {
  IAdminSettingSection, IStudentVisibilitySetting, IDictionaryItem
} from '../api/types';
import {
  AdminSectionSettingCodes,
  AdminSectionSettingKeys,
  AdminSectionSettingNames,
  SectionCodes,
  SectionKeys,
  SectionTypeCodes,
  StudentSectionSettingTypeCodes
} from '../const';
import { AdminSettingsState } from '../reducers/admin/adminSettings';
import { IViewVisibilitySetting } from '../types';

// TODO требуется рефакторинг
const studentSectionPaths = [
  {
    path: 'study',
    key: StudentSectionSettingTypeCodes.study
  },
  {
    path: 'science',
    key: StudentSectionSettingTypeCodes.science
  },
  {
    path: 'sport',
    key: StudentSectionSettingTypeCodes.sport
  },
  {
    path: 'creation',
    key: StudentSectionSettingTypeCodes.creation
  },
  {
    path: 'culture',
    key: StudentSectionSettingTypeCodes.culture
  },
  {
    path: 'civil',
    key: StudentSectionSettingTypeCodes.civil
  },
  {
    path: 'profession',
    key: StudentSectionSettingTypeCodes.profession
  },
  {
    path: 'profile',
    key: StudentSectionSettingTypeCodes.profile
  },
  {
    path: 'recommendation',
    key: StudentSectionSettingTypeCodes.recommendation
  },
];

/**
 * Находит путь первого видимого раздела для роутинга в приложении
 * @param settings настройки видимости
 */
export const getStudentFirstVisibleSectionPath = (settings: IViewVisibilitySetting[]) => {
  const firstVisibleSectionPath = studentSectionPaths.find((section) => {
    const typeCode = section.key;
    const setting = settings.find((setting) => setting.sectionTypeCode === typeCode);
    return setting && setting.isVisible;
  })?.path;

  return firstVisibleSectionPath || studentSectionPaths[0].path;
}

/**
 * Создано для быстрого фикса, больше не требуется, при удалении последней ссылки требуется удалить
 * @deprecated
 */
export const getStudentPortfolioSettingsPath = (
  pathname: string,
  adminSettings: AdminSettingsState
) => {
  const sections = [
    {
      path: 'study',
      key: SectionKeys.STUDY
    },
    {
      path: 'science',
      key: SectionKeys.SCIENCE
    },
    {
      path: 'sport',
      key: SectionKeys.SPORT
    },
    {
      path: 'creation',
      key: SectionKeys.CREATION
    },
    {
      path: 'culture',
      key: SectionKeys.CULTURE
    },
    {
      path: 'civil',
      key: SectionKeys.CIVIL
    },
    {
      path: 'profile',
      key: SectionKeys.PROFILE
    },
    {
      path: 'recommendation',
      key: SectionKeys.RECOMMENDATION
    },
  ];

  const paths = sections.map((sectionPath) => sectionPath.path);

  const typeCodeToVisibilityMapping = new Map<string, boolean>();

  adminSettings.content?.sections.forEach((section) => {
    typeCodeToVisibilityMapping.set(AdminSectionSettingNames[section.sectionId], section.isVisible);
  });

  const pathArray = pathname.split('/');
  const currentAvailableSection = pathArray.find((path) => paths.includes(path));

  const currentVisibleSection = sections.find((section) => {
    return section.path === currentAvailableSection
      && typeCodeToVisibilityMapping.get(SectionTypeCodes[SectionCodes[section.key]])
  });

  if (currentVisibleSection) return currentVisibleSection.path;

  for (const section of sections) {
    if (typeCodeToVisibilityMapping.get(SectionTypeCodes[SectionCodes[section.key]])) {
      return section.path;
    }
  }
};

export const getStudentPortfolioPath = (
  pathname: string,
  settings: IViewVisibilitySetting[]
) => {
  if (settings.length === 0) return undefined;

  const paths = ['study', 'science', 'sport', 'creation', 'culture', 'civil', 'reward', 'profession', 'profile', 'recommendation'];

  const typeCodeToVisibilityMapping = new Map<string, boolean>();

  settings.forEach((setting) => {
    typeCodeToVisibilityMapping.set(setting.sectionTypeCode, setting.computedVisibility ?? false);
  });

  const pathArray = pathname.split('/');
  const clearedPathArray: string[] = [];
  pathArray.every((pathItem) => {
    if (!paths.includes(pathItem)) {
      clearedPathArray.push(pathItem);
      return true;
    }
    clearedPathArray.push(pathItem);
    return false;
  });
  const clearedPath = clearedPathArray.join('/');

  if (typeCodeToVisibilityMapping.get(SectionTypeCodes[SectionCodes[SectionKeys.STUDY]]) && clearedPath.includes('study')) return clearedPath;
  if (typeCodeToVisibilityMapping.get(SectionTypeCodes[SectionCodes[SectionKeys.SCIENCE]]) && clearedPath.includes('science')) return clearedPath;
  if (typeCodeToVisibilityMapping.get(SectionTypeCodes[SectionCodes[SectionKeys.SPORT]]) && clearedPath.includes('sport')) return clearedPath;
  if (typeCodeToVisibilityMapping.get(SectionTypeCodes[SectionCodes[SectionKeys.CREATION]]) && clearedPath.includes('creation')) return clearedPath;
  if (typeCodeToVisibilityMapping.get(SectionTypeCodes[SectionCodes[SectionKeys.CULTURE]]) && clearedPath.includes('culture')) return clearedPath;
  if (typeCodeToVisibilityMapping.get(SectionTypeCodes[SectionCodes[SectionKeys.CIVIL]]) && clearedPath.includes('civil')) return clearedPath;
  if (typeCodeToVisibilityMapping.get(SectionTypeCodes[SectionCodes[SectionKeys.PROFESSION]]) && clearedPath.includes('profession')) return clearedPath;
  if (typeCodeToVisibilityMapping.get(SectionTypeCodes[SectionCodes[SectionKeys.PROFILE]]) && clearedPath.includes('profile')) return clearedPath;
  if (typeCodeToVisibilityMapping.get(SectionTypeCodes[SectionCodes[SectionKeys.RECOMMENDATION]]) && clearedPath.includes('recommendation')) return clearedPath;

  const filteredPath = clearedPathArray.filter((pathItem) => !paths.includes(pathItem)).join('/');

  if (typeCodeToVisibilityMapping.get(SectionTypeCodes[SectionCodes[SectionKeys.STUDY]])) return `${filteredPath}/study`;
  if (typeCodeToVisibilityMapping.get(SectionTypeCodes[SectionCodes[SectionKeys.SCIENCE]])) return `${filteredPath}/science`;
  if (typeCodeToVisibilityMapping.get(SectionTypeCodes[SectionCodes[SectionKeys.SPORT]])) return `${filteredPath}/sport`;
  if (typeCodeToVisibilityMapping.get(SectionTypeCodes[SectionCodes[SectionKeys.CREATION]])) return `${filteredPath}/creation`;
  if (typeCodeToVisibilityMapping.get(SectionTypeCodes[SectionCodes[SectionKeys.CULTURE]])) return `${filteredPath}/culture`;
  if (typeCodeToVisibilityMapping.get(SectionTypeCodes[SectionCodes[SectionKeys.CIVIL]])) return `${filteredPath}/civil`;
  if (typeCodeToVisibilityMapping.get(SectionTypeCodes[SectionCodes[SectionKeys.PROFESSION]])) return `${filteredPath}/profession`;
  if (typeCodeToVisibilityMapping.get(SectionTypeCodes[SectionCodes[SectionKeys.PROFILE]])) return `${filteredPath}/profile`;
  if (typeCodeToVisibilityMapping.get(SectionTypeCodes[SectionCodes[SectionKeys.RECOMMENDATION]])) return `${filteredPath}/recommendation`;
};

export const isLinkSectionVisible = (
  baseVisibility: boolean | undefined,
  sectionSettingTypeCode: string,
  adminSettingSections: IAdminSettingSection[] | undefined
) => {
  if (!adminSettingSections) return baseVisibility;
  const adminSetting = adminSettingSections?.find((section) => AdminSectionSettingNames[section.sectionId] === sectionSettingTypeCode);
  return !adminSetting?.isVisible ? false : baseVisibility;
};

/**
 * Синхронизирует объект с настройками видимости, добавляя недостающие ветви настроек
 * @param localSettings массив настроек из ответа сервера
 * @param defaultSettings наиболее полный набор настроек видимости
 * @returns измененные настройки видимости
 */
export const syncLocalVisibilitySettings = (
  localSettings: IStudentVisibilitySetting[] | undefined,
  defaultSettings: IViewVisibilitySetting[]
): IStudentVisibilitySetting[] => {
  const newSettings = defaultSettings.map((defaultSetting) => {
    const existingSetting = localSettings?.find((setting) => setting.sectionTypeCode === defaultSetting.sectionTypeCode);
    const newSetting = existingSetting ? existingSetting : defaultSetting;

    if (defaultSetting.subSections) {
      newSetting.subSections = syncLocalVisibilitySettings(newSetting.subSections, defaultSetting.subSections);
    }

    return newSetting;
  });

  return newSettings;
}

export const filterOutParentSections = (
  settings: IDictionaryItem[],
  removedParentSettingKeys: SectionKeys[]
): IDictionaryItem[] => {
  return settings.map((setting) => {
    const isRemoved = removedParentSettingKeys.some((parentKey) => SectionCodes[parentKey] === setting.code);
    return isRemoved ? null : setting;
  }).filter(isDefined);
};

/**
 * Используется для искусственного удаления требуемых дочерних настроек администратора
 * @param adminSettings настройки видимости администратора
 * @param parentSettingKey тайп код родительского раздела
 * @param childSettingKeys тайп коды дочерних разделов
 * @returns измененные настройки видимости
 */
export const filterOutAdminChildSettings = (
  adminSettings: IAdminSettingSection[],
  parentSettingKey: AdminSectionSettingKeys,
  childSettingKeys: AdminSectionSettingKeys[]
): IAdminSettingSection[] => {
  const parentSettingCode = AdminSectionSettingCodes[parentSettingKey];

  // удаляет все дочерние разделы из childSettingCodes c parentSectionCode равным parentSettingKey
  const modifiedSettings = adminSettings.map((setting) => {
    const isFilteredOutSetting =
      setting.parentSectionId === parentSettingCode
      && childSettingKeys.some((childSettingCode) => setting.sectionId === AdminSectionSettingCodes[childSettingCode]);

    return isFilteredOutSetting ? null : setting;
  }).filter(isDefined);

  const activeChildSettings = modifiedSettings.filter((setting) => setting.parentSectionId === parentSettingCode && setting.isVisible).length;

  // отключает родительский раздел если не осталось включенных дочерних разделов
  if (activeChildSettings === 0) {
    return modifiedSettings.map((setting) => {
      return setting.sectionId === parentSettingCode ? { ...setting, isVisible: false } : setting;
    });
  }

  return modifiedSettings;
};

/**
 * Приводит настройки видимости к виду, подходящему для отправки.
 * @param settings настройки видимости
 * @returns измененные настройки видимости
 */
export const mapViewSettingsToSaveSettingsRequest = (
  settings: IViewVisibilitySetting[]
): IStudentVisibilitySetting[] => {
  return settings.map((setting): IStudentVisibilitySetting => ({
    // чтобы не заменить значение isVisible при сохранении настроек
    // необходимо использовать вычисляемое значение только когда раздел не скрыт админом
    // когда раздел скрыт админом, используется старое значение isVisible
    isVisible: setting.isAdminVisible ? setting.computedVisibility ?? false : setting.isVisible,
    name: setting.name,
    sectionTypeCode: setting.sectionTypeCode,
    subSections: setting.subSections
      ? mapViewSettingsToSaveSettingsRequest(setting.subSections)
      : undefined
  }));
};

/**
 * Позволяет изменить вычисляемое значение видимости раздела в зависимости от подразделов.
 * Полезно, когда администратор включает подраздел, который был ранее включен у пользователя,
 * и теперь пользователю необходимо отображать родительский раздел
 * @param settings настройки видимости
 * @returns измененные настройки видимости
 */
export const normalizeViewSettings = (
  settings: IViewVisibilitySetting[]
): IViewVisibilitySetting[] => {
  return settings.map((setting): IViewVisibilitySetting => {
    const isSettingVisible = setting.subSections
      ?.some((childSetting) => {
        return childSetting.computedVisibility;
      }) ?? setting.computedVisibility ?? false;

    return {
      ...setting,
      computedVisibility: isSettingVisible,
      subSections: setting.subSections
        ? normalizeViewSettings(setting.subSections)
        : undefined,
    };
  });
};

/**
 * Изменяет значения видимости всех настроек на выбранное.
 * @param settings настройки видимости
 * @returns измененные настройки видимости
 */
export const toggleViewSettings = (
  settings: IViewVisibilitySetting[],
  enabled: boolean,
): IViewVisibilitySetting[] => {
  return settings.map((setting): IViewVisibilitySetting => {
    return {
      ...setting,
      computedVisibility: enabled,
      isAdminVisible: enabled,
      isVisible: enabled,
      subSections: setting.subSections
        ? toggleViewSettings(setting.subSections, enabled)
        : undefined,
    };
  });
};
