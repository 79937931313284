/* eslint-disable max-len */
import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconStudyResultsCommon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m24 10.7-2.1-2.3V5.3L19 4.2l-1.4-2.8-3.1.4L12 0 9.5 1.8l-3.1-.4-1.5 2.8-2.8 1.1v3.1L0 10.7l1.4 2.8-.7 3 2.5 1.8.7 3 3.1.4L9.1 24l2.9-1.1 2.9 1.1 2.1-2.3 3.1-.4.7-3 2.5-1.8-.7-3 1.4-2.8Zm-11.6 4.5L10.7 17l-5-5 1.8-1.8 3.3 3.3L16.3 8 18 9.7l-5.6 5.5Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconStudyResultsCommon;
