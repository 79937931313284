import { FC } from 'react';

import { Box, useTheme } from '@mui/material';
import { getAccentColor } from 'portfolio3/styles';
import { SxStyles } from 'types';

import RecommendationWaveBackground from '../common/waveBackground';

export interface IRecommendationContainerIllustrationProps {
  illustration: string;
  width: number;
  height: number;
}

const RecommendationContainerIllustration: FC<IRecommendationContainerIllustrationProps> = ({
  illustration,
  width,
  height,
}) => {
  const { accentColor } = useTheme().palette;

  const backgroundImageStyles: SxStyles = {
    backgroundImage: `url(${illustration})`,
    width: `${width}px`,
    height: `${height}px`,
  };

  const backgroundColor = getAccentColor(accentColor, '24');

  return (
    <>
      <Box className="recommendation-container__illustration" sx={backgroundImageStyles} />
      <RecommendationWaveBackground fill={backgroundColor} className="recommendation-container__wave" />
    </>
  );
};

export default RecommendationContainerIllustration;
