import {
  REPORT_ERROR_DRAWER_SET_FORM_DATA,
  REPORT_ERROR_DRAWER_SET_OPEN,
  REPORT_ERROR_DRAWER_SET_SUBMIT_MODAL_OPEN,
} from 'actions';
import { IReportErrorFormData } from 'components/common/PortfolioReportErrorForm';
import { IReducers } from 'reducers';
import { connectReducers } from 'utils';

export type ReportErrorDrawerState = {
  isOpen: boolean;
  formData: IReportErrorFormData | undefined;
  isSubmitModalOpen: boolean;
};

type SetOpenAction = { payload: boolean };
type SetFormDataAction = { payload: IReportErrorFormData | undefined };
type SetSubmitModalOpenAction = { payload: boolean };

const initialState: ReportErrorDrawerState = {
  isOpen: false,
  formData: undefined,
  isSubmitModalOpen: false,
};

const reportErrorDrawer: IReducers<ReportErrorDrawerState> = {
  [REPORT_ERROR_DRAWER_SET_OPEN]: (state, { payload }: SetOpenAction) => {
    return {
      ...state,
      isOpen: payload,
      isSubmitModalOpen: payload ? false : state.isSubmitModalOpen,
    };
  },
  [REPORT_ERROR_DRAWER_SET_FORM_DATA]: (state, { payload }: SetFormDataAction) => {
    return {
      ...state,
      formData: payload,
    };
  },
  [REPORT_ERROR_DRAWER_SET_SUBMIT_MODAL_OPEN]: (state, { payload }: SetSubmitModalOpenAction) => {
    return {
      ...state,
      isSubmitModalOpen: payload,
    };
  },
};

export default connectReducers(initialState, reportErrorDrawer);
