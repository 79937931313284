/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useMemo } from 'react';

import { connect } from 'react-redux';

import { ICommonResponse } from '../../../../api/types';
import { IRootState } from '../../../../reducers';
import PdfCommonDataEntityContainer from '../common';
import { PdfCommonDataEntity } from '../../../../components/pdfStudent/DataEntity';
import { widgetPdfIcons } from '../../../../icons';
import { sportExpeditionsSelector } from '../../../../selectors';
import { IEntity } from '../../../../types';

import '../index.scss';
import { useDataLoad } from 'hooks';
import { getDataLoadRequests } from 'utils';

interface IPdfSportExpeditionContainerProps {
  studentExpeditions: ICommonResponse<IEntity>
}

const PdfSportExpeditionContainer: React.FC<IPdfSportExpeditionContainerProps> = ({
  studentExpeditions
}) => {
  useDataLoad({ shouldLoad: true, requests: getDataLoadRequests().sportExpeditions });

  const filteredExpeditionsYear = useMemo(() => (studentExpeditions.content && studentExpeditions.content?.length > 0
    ? studentExpeditions.content?.reduce((prevItem, nextItem) => (prevItem.year > nextItem.year ? prevItem : nextItem)).year
    : undefined),
  [studentExpeditions]);

  return (
    <PdfCommonDataEntityContainer
      title="Походы и экспедиции"
      icon={widgetPdfIcons.pdfIconSportHikes}
    >
      {studentExpeditions.content
        .map((olympiad: IEntity) => (
          <PdfCommonDataEntity key={olympiad.id || olympiad.recordId} entity={olympiad} year={filteredExpeditionsYear} />
        ))}
    </PdfCommonDataEntityContainer>
  );
};

export default connect(
  (state: IRootState) => ({
    studentExpeditions: sportExpeditionsSelector(state)
  })
)(PdfSportExpeditionContainer);
