/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';

import {
  FAILURE,
  INVALIDATE,
  REQUEST,
  SUCCESS
} from '../../actions/utils';

import genericReducers from '../genericReducer';
import { IReducers } from '..';
import { IRecommendationResponse } from '../../api/types';
import { GET_STUDENT_RECOMMENDATIONS } from '../../actions';

export type StudentRecommendationsState = IRecommendationResponse;

const initialState: StudentRecommendationsState = {
  content: {
    recommendObject: [],
    literature: {
      literatureObject: [],
      count: 0
    },
    visitGTO: true
  },
  loading: false,
};

const studentRecommendations: IReducers<StudentRecommendationsState> = {
  [GET_STUDENT_RECOMMENDATIONS[REQUEST]]: genericReducers().get.request,
  [GET_STUDENT_RECOMMENDATIONS[SUCCESS]]: genericReducers().get.success,
  [GET_STUDENT_RECOMMENDATIONS[FAILURE]]: genericReducers().get.failure,
  [GET_STUDENT_RECOMMENDATIONS[INVALIDATE]]: genericReducers().get.invalidate(initialState),
};

export default connectReducers(initialState, studentRecommendations);
