/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconChevronSmallLeft: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M13.768 7.023 9.5 12l4.268 4.977a.883.883 0 0 1-.128 1.308 1.043 1.043 0 0 1-.64.215c-.286 0-.57-.114-.768-.334l-5-5.572c-.039-.043-.059-.095-.088-.143-.024-.039-.053-.072-.071-.115a.859.859 0 0 1-.072-.332L7 12l.001-.004c0-.113.027-.225.072-.332.018-.043.047-.076.071-.115.029-.049.049-.1.088-.143l5-5.572a1.053 1.053 0 0 1 1.408-.119.883.883 0 0 1 .128 1.308Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconChevronSmallLeft;
