import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useMediaQuery } from '@mui/material';
import { deleteStudentShareLinkActions, linkSharingDrawerActions } from 'actions';
import { tooltipOptions } from 'const';
import copy from 'copy-to-clipboard';
import { IconCopyOutline, IconQr } from 'icons';
import { IconClose } from 'icons/edit';
import ActionsButton from 'portfolio3/components/common/buttons/ActionsButton';
import WithActionsPopup from 'portfolio3/components/common/WithActionsPopup';
import { IEntityAction } from 'portfolio3/components/entityActions';
import { commonTheme } from 'portfolio3/styles/theme';
import { toast } from 'portfolio3/ui-kit';
import { IRootState } from 'reducers';
import { SxStyles } from 'types';
import { getSharedLinkUrl, mergeSx } from 'utils';

interface ILinkActionsButtonProps {
  id: number;
  linkUrl: string;
  linkQrCode: string;
  sx?: SxStyles;
}

const LinkActionsButton: FC<ILinkActionsButtonProps> = ({ id, linkUrl, linkQrCode, sx }) => {
  const dispatch = useDispatch();
  const currentStudent = useSelector((state: IRootState) => state.currentStudent);

  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const handleCopy = () => {
    const url = getSharedLinkUrl(linkUrl);
    const isCopied = copy(url);

    if (isCopied) {
      toast.success(isMobile ? 'Ссылка успешно скопирована!' : 'Ссылка скопирована в буфер обмена', tooltipOptions);
    }
  };

  const handleShowQrCode = () => {
    dispatch(linkSharingDrawerActions.setCurrentLink(linkUrl, linkQrCode));
    dispatch(linkSharingDrawerActions.setLinkQrCodeOpen(true));
  };

  const handleDelete = () => {
    if (currentStudent.meshId) {
      dispatch(deleteStudentShareLinkActions.request(id, currentStudent.meshId));
    }
  };

  const actions: IEntityAction[] = [
    {
      text: 'Копировать ссылку',
      icon: IconCopyOutline,
      effect: handleCopy,
    },
    {
      text: 'Показать QR-код',
      icon: IconQr,
      effect: handleShowQrCode,
    },
    {
      text: 'Отменить доступ',
      icon: IconClose,
      effect: handleDelete,
    },
  ];

  return (
    <WithActionsPopup actions={actions}>
      {(ref, onClick) => {
        return <ActionsButton ref={ref} onClick={onClick} sx={mergeSx(sx, { border: 'none' })} />;
      }}
    </WithActionsPopup>
  );
};

export default LinkActionsButton;
