/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useMemo } from 'react';

import { connect } from 'react-redux';

import { IRootState } from '../../../../reducers';
import { ICommonResponse } from '../../../../api/types';
import PdfCommonDataEntityContainer from '../common';
import { PdfCommonDataEntity } from '../../../../components/pdfStudent/DataEntity';
import { widgetPdfIcons } from '../../../../icons';
import { sportCompetitionsSelector } from '../../../../selectors';
import { IEntity } from '../../../../types';

import '../index.scss';
import { useDataLoad } from 'hooks';
import { getDataLoadRequests } from 'utils';

interface IPdfSportCompetitionContainerProps {
  studentCompetitions: ICommonResponse<IEntity>
}

const PdfSportCompetitionContainer: React.FC<IPdfSportCompetitionContainerProps> = ({
  studentCompetitions
}) => {
  useDataLoad({ shouldLoad: true, requests: getDataLoadRequests().sportCompetitions });

  const filteredCompetitionsYear = useMemo(() => (studentCompetitions && studentCompetitions.content?.length > 0
    ? studentCompetitions.content.reduce((prevItem, nextItem) => (prevItem.year > nextItem.year ? prevItem : nextItem)).year
    : undefined),
  [studentCompetitions]);

  return (
    <PdfCommonDataEntityContainer
      title="Соревнования"
      icon={widgetPdfIcons.pdfIconSportContests}
    >
      {studentCompetitions.content
        .map((olympiad: IEntity) => (
          <PdfCommonDataEntity key={olympiad.id || olympiad.recordId} entity={olympiad} year={filteredCompetitionsYear} />
        ))}
    </PdfCommonDataEntityContainer>
  );
};

export default connect(
  (state: IRootState) => ({
    studentCompetitions: sportCompetitionsSelector(state)
  })
)(PdfSportCompetitionContainer);
