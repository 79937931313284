import { ILocalVuzSpeciality } from '../types';

export const mergeSpecialities = (spec1: ILocalVuzSpeciality, spec2: ILocalVuzSpeciality): ILocalVuzSpeciality => {
  return {
    name: spec1.name ?? spec2.name,
    number: spec1.number ?? spec2.number,
    subjects: spec1.subjects ?? spec2.subjects,
    type: spec1.type ?? spec2.type,
    budgetPlaces: spec1.budgetPlaces ?? spec2.budgetPlaces,
    paidPlaces: spec1.paidPlaces ?? spec2.paidPlaces,
    budgetMinBall: spec1.budgetMinBall ?? spec2.budgetMinBall,
    paidMinBall: spec1.paidMinBall ?? spec2.paidMinBall,
    facultyName: spec1.facultyName ?? spec2.facultyName,
    studentSubjectsBallSummary: spec1.studentSubjectsBallSummary ?? spec2.studentSubjectsBallSummary,
  };
};
