/* eslint-disable prettier/prettier */
/* eslint-disable */

import { IDragItem } from '../types';

export interface ISubmitDialogData {
  open: boolean,
  action: () => void
}

export const initialSubmitDialogData: ISubmitDialogData = {
  open: false,
  action: () => {}
};

export const getDraggableItem = (list: IDragItem[], sourceIndex: number) => {
  const result = Array.from(list);
  return result.splice(sourceIndex, 1)[0];
};
