import { FC, ReactNode, useRef } from 'react';
import { AriaButtonOptions, useButton } from 'react-aria';

import { Box } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';
import { getButtonStyles } from 'portfolio3/ui-kit/utils';
import { SxStyles } from 'types';

const styles: SxStyles = {
  ...getButtonStyles(),

  width: '20px',
  height: '20px',

  svg: {
    width: 'inherit',
    height: 'inherit',
    color: NeutralColors.light_neutrals_600,
  },
};

interface INavigationButtonProps {
  icon: ReactNode;
  ariaOptions?: AriaButtonOptions<'button'>;
}

const NavigationButton: FC<INavigationButtonProps> = ({ icon, ariaOptions = {} }) => {
  const ref = useRef(null);
  const { buttonProps } = useButton(ariaOptions, ref);

  return (
    <Box component="button" className="navigation-button" ref={ref} {...buttonProps} sx={styles}>
      {icon}
    </Box>
  );
};

export default NavigationButton;
