import { useDispatch } from 'react-redux';

import { Box } from '@mui/material';
import {
  getAdminSettingsActions,
  getAdminViewModeSettingsActions,
  getStudentAvatarActions,
  setAdminViewingModeAction,
  setAdminViewingTypeAction,
  setCurrentStudentAction,
} from 'actions';
import { IPerson } from 'api/types';
import { useAppSelector } from 'hooks';
import { IconEyeOutline } from 'icons';
import { Button } from 'portfolio3/ui-kit/button';
import { AdminPortfolioViewType } from 'types';
import { invalidateStudentData } from 'utils';

import { mapPersonToChildInfo } from '../../utils';

export const useAdminStudentResult = (foundStudent: IPerson | undefined) => {
  const dispatch = useDispatch();
  const avatarStore = useAppSelector((state) => state.avatars);
  const organizations = useAppSelector((state) => state.organizations.content);

  const viewPortfolio = (student: IPerson | undefined, viewAs: AdminPortfolioViewType) => {
    if (!student) return;

    const mappedStudent = mapPersonToChildInfo(student, organizations);
    const parallelName = student.grade?.parallelName;
    const meshId = mappedStudent.meshId;

    dispatch(setCurrentStudentAction(mappedStudent));
    dispatch(setAdminViewingModeAction(true));
    dispatch(setAdminViewingTypeAction(viewAs));

    // инвалидация данных
    invalidateStudentData();
    dispatch(getAdminSettingsActions.invalidate());
    dispatch(getAdminViewModeSettingsActions.invalidate());

    // получение настроек админа и настроек пользователя (за админа)
    dispatch(getAdminSettingsActions.request(parallelName));
    dispatch(getAdminViewModeSettingsActions.request(meshId));

    const isAvatarCached = avatarStore.has(meshId);

    // получение аватара
    if (!isAvatarCached) {
      dispatch(getStudentAvatarActions.request(meshId));
    }
  };

  const adminRightElement = (
    <Box display="flex" gap="16px">
      <Button
        onClick={() => viewPortfolio(foundStudent, 'parent')}
        size="small"
        variant="secondary"
        iconLeft={<IconEyeOutline />}
      >
        Что видит родитель
      </Button>
      <Button onClick={() => viewPortfolio(foundStudent, 'student')} size="small">
        Подробнее
      </Button>
    </Box>
  );

  return {
    adminRightElement,
  };
};
