import { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconFlagOutline: FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M18 14.5859C17.604 14.7629 16.825 14.9999 15.5 14.9999C14.376 14.9999 13.399 14.6169 12.365 14.2119C11.203 13.7569 10.001 13.2859 8.5 13.2859C7.443 13.2859 6.624 13.4129 6 13.5839V11.9999V5.41589C6.396 5.23889 7.177 4.99989 8.5 4.99989C9.624 4.99989 10.601 5.38289 11.635 5.78789C12.797 6.24289 13.999 6.71389 15.5 6.71389C16.438 6.71389 17.276 6.61289 18 6.41389V14.5859ZM19.268 4.67989C18.806 4.34589 18.208 4.25089 17.669 4.42689C17.093 4.61389 16.343 4.71389 15.5 4.71389C14.376 4.71389 13.399 4.33089 12.365 3.92589C11.203 3.47089 10.001 2.99989 8.5 2.99989C5.613 2.99989 4.495 3.95189 4.3 4.14389C4.108 4.33089 4 4.58889 4 4.85689V11.9999V15.1429V19.9999C4 20.5529 4.447 20.9999 5 20.9999C5.553 20.9999 6 20.5529 6 19.9999V15.7019C6.395 15.5259 7.173 15.2859 8.5 15.2859C9.624 15.2859 10.601 15.6689 11.635 16.0739C12.797 16.5289 13.999 16.9999 15.5 16.9999C17.309 16.9999 18.423 16.6219 19.041 16.3049C19.633 15.9979 20 15.4019 20 14.7489V6.10589C20 5.54589 19.727 5.01289 19.268 4.67989Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconFlagOutline;
