import { FC, PropsWithChildren, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { getScrollableContainerElement, mergeSx } from 'utils';

import { backgroundFullHeightStyles } from '../utils';
import { backgroundPatternStyles } from './styles';

interface IPatternBackgroundProps {
  pattern: string;
  gradient: string;
}

const PatternBackground: FC<IPatternBackgroundProps & PropsWithChildren> = ({ gradient, pattern, children }) => {
  const [layoutShift, setLayoutShift] = useState(0);

  useEffect(() => {
    const mainContainer = getScrollableContainerElement();

    const observer = new MutationObserver(() => {
      const paddingRight = Number(getComputedStyle(mainContainer).paddingRight.replace('px', ''));
      setLayoutShift(paddingRight);
    });

    observer.observe(mainContainer, { attributes: true, attributeFilter: ['style'] });
    return () => observer.disconnect();
  }, []);

  return (
    <Box
      sx={mergeSx(backgroundPatternStyles(pattern, gradient, layoutShift), backgroundFullHeightStyles)}
      className="pattern-background"
    >
      <Box className="pattern-background__pattern" />
      <Box className="pattern-background__overlay" />
      {children}
    </Box>
  );
};

export default PatternBackground;
