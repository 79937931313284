import { CSSProperties, FC } from 'react';

import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import { crown } from 'portfolio3/features/diagnostics/images';

import { IBarCircleBase } from '../../types';
import * as styles from './styles';

interface IBarPlaceProps extends IBarCircleBase {
  place: number;
}

const BarPlace: FC<IBarPlaceProps> = ({ place, yours }) => {
  const imageStyles: CSSProperties = {
    width: '24px',
    height: '24px',
  };
  return (
    <Box sx={styles.barPlace} className={clsx('barPlace', yours && 'active')}>
      {place === 1 && (
        <Box sx={styles.barCrown}>
          <img style={imageStyles} src={crown} alt="Корона" />
        </Box>
      )}
      <Typography variant="Headings/H5">{place}</Typography>
    </Box>
  );
};

export default BarPlace;
