import { FC, PropsWithChildren, ReactNode } from 'react';

import { Box } from '@mui/material';
import { SxStyles } from 'types';
import { mergeSx } from 'utils';

const styles: SxStyles = {
  display: 'flex',
  gap: '8px',
};

const iconContainer: SxStyles = {
  width: '20px',
  height: '20px',

  svg: {
    width: '20px',
    height: '20px',
  },
};

interface IEmployeeToastContentProps {
  icon: ReactNode;
  color: string;
}

const EmployeeToastContent: FC<PropsWithChildren<IEmployeeToastContentProps>> = ({ icon, color, children }) => {
  return (
    <Box className="employee-toast-content" sx={styles}>
      <Box className="icon-container" sx={mergeSx(iconContainer, { color })}>
        {icon}
      </Box>
      <Box>{children}</Box>
    </Box>
  );
};

export default EmployeeToastContent;
