import { DateValue } from 'react-aria';

import { CalendarDate, fromDate, getLocalTimeZone, toCalendarDate } from '@internationalized/date';

const timeZone = getLocalTimeZone();

export const dateToCalendarDate = (date: Date): CalendarDate => {
  return toCalendarDate(fromDate(date, timeZone));
};

export const dateValueToDate = (date: DateValue | null): Date | null => {
  if (!date) return null;
  return date.toDate(timeZone);
};

export const getDateFromYear = (year: number): Date => {
  const date = new Date(year, 0, 1);
  date.setFullYear(year);
  return date;
};
