/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useState } from 'react';

import { Select } from '../../../../ui-kit';
import { ICommonSelectController, IPopoverPositioningProps } from './types';

import './index.scss';

interface ICommonSelectProps {
  value: any;
  options: any[];
  placeholder: string;
  children?: any;
  className?: string;
  error?: boolean;
  multiple?: boolean;
  controller?: ICommonSelectController;
  renderValue?: ((value: unknown) => React.ReactNode) | undefined
}

const CommonSelect: React.FC<ICommonSelectProps> = ({
  value,
  options,
  placeholder,
  controller,
  children,
  className,
  error,
  multiple,
  renderValue
}) => {
  const [popoverPositioning, setPopoverPositioning] = useState<IPopoverPositioningProps>({
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left'
    }
  });
  return (
    <Select
      onChange={(e) => { if (e.target.value) controller?.handleChange(e.target.value); }}
      onBlur={(e) => { controller?.handleBlur?.(e.target.value); }}
      placeholder={placeholder}
      value={value}
      error={error}
      className={className}
      multiple={multiple}
      onKeyDown={(event) => event.preventDefault()}
      onKeyUp={(event) => event.preventDefault()}
      onKeyPress={(event) => event.preventDefault()}
      SelectDisplayProps={{
        id: placeholder
      }}
      MenuProps={{
        anchorOrigin: popoverPositioning.anchorOrigin,
        transformOrigin: popoverPositioning.transformOrigin,
        PaperProps: {
          style: {
            maxHeight: '360px',
            maxWidth: 0,
            borderRadius: '8px',
            paddingLeft: '8px',
            paddingRight: '8px',
            overflowY: 'auto',
          }
        },

        // @ts-ignore
        getContentAnchorEl: (element:Element) => {
          if (popoverPositioning.anchorOrigin.vertical === 'top') element.classList.add('select-topped');
          else element.classList.remove('select-topped');
          return null;
        },
        TransitionProps: {
          onEntering: () => {
            const domRect = document.getElementById(placeholder)?.getBoundingClientRect();
            if (domRect) {
              const spaceBelow = window.innerHeight - domRect.bottom;
              if (spaceBelow < options?.length * 36 && spaceBelow < 360) {
                setPopoverPositioning({
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  },
                  transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  }
                });
              } else {
                setPopoverPositioning({
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  }
                });
              }
            }
          }
        }
      }}
      renderValue={renderValue}
    >
      {children}
    </Select>
  );
};

export default CommonSelect;
