import { SectionCodes } from 'const';
import {
  IEntryFormEntityProperties,
  IPortfolioEntryFormData,
  MappedEventData,
} from 'portfolio3/features/dataEntryForm';
import { getCategoryProperties, isDefined } from 'utils';

interface IProps {
  formData: IPortfolioEntryFormData;
  mappedEventData: MappedEventData;
}

const useCultureEntityProperties = ({ formData, mappedEventData }: IProps): IEntryFormEntityProperties | null => {
  const categoryCode = formData.categoryCode;
  const dataKind = formData.dataKind;

  const categoryProperties = categoryCode ? getCategoryProperties(categoryCode) : null;

  let name: string | undefined;
  let typeName: string | undefined;
  let attributes: string[] | undefined;

  if (dataKind === SectionCodes.cultureEvent) {
    name = mappedEventData.name ?? 'Другое';
    typeName = 'Мероприятие';
    attributes = [mappedEventData.type].filter(isDefined);
  }

  if (categoryCode !== SectionCodes.culture) return null;

  return {
    entityInfo: {
      name,
      categoryName: categoryProperties?.name ?? '',
      typeName: typeName ?? '',
      attributes,
    },
  };
};

export default useCultureEntityProperties;
