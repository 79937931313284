/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';
import { IChangeHistoryFilters } from '../containers/employee/ChangeHistory/types';

interface IChangeHistoryContext {
  currentFilters: IChangeHistoryFilters;
  setCurrentFilters: React.Dispatch<React.SetStateAction<IChangeHistoryFilters>>;
}

export default React.createContext({} as IChangeHistoryContext);
