/* eslint-disable prettier/prettier */
/* eslint-disable */

import { call, put } from 'redux-saga/effects';

import {
  getOlympiadLevels,
  getOlympiadResults,
  getOlympiadParallels,
  getOlympiadTypes,
  getOlympiadFormats,
  getSources
} from '../api';
import {
  TGetOlympiadLevelsRequest,
  TGetOlympiadResultsRequest,
  TGetOlympiadParallelsRequest,
  TGetOlympiadTypesRequest,
  TGetOlympiadFormatsRequest
} from '../api/types/students';

import {
  getOlympiadLevelsActions,
  getOlympiadResultsActions,
  getOlympiadParallelsActions,
  getOlympiadTypesActions,
  getOlympiadFormatsActions,
  getSourcesActions
} from '../actions';

import { GenericGetAction } from '.';

function* getOlympiadLevelsSaga({ payload: { olympiadLevelId, queryParams } }: Required<GenericGetAction<TGetOlympiadLevelsRequest>>) {
  const { response, error } = yield call(getOlympiadLevels, olympiadLevelId, queryParams);

  if (response) {
    yield put(getOlympiadLevelsActions.success(response));
  } else {
    yield put(getOlympiadLevelsActions.failure(error));
  }
}

function* getOlympiadResultsSaga({ payload: { olympiadResultId } }: Required<GenericGetAction<TGetOlympiadResultsRequest>>) {
  const { response, error } = yield call(getOlympiadResults, olympiadResultId);

  if (response) {
    yield put(getOlympiadResultsActions.success(response));
  } else {
    yield put(getOlympiadResultsActions.failure(error));
  }
}

function* getOlympiadParallelsSaga({ payload: { parallelId, queryParams } }: Required<GenericGetAction<TGetOlympiadParallelsRequest>>) {
  const { response, error } = yield call(getOlympiadParallels, parallelId, queryParams);

  if (response) {
    yield put(getOlympiadParallelsActions.success(response));
  } else {
    yield put(getOlympiadParallelsActions.failure(error));
  }
}

function* getOlympiadTypesSaga({ payload: { olympiadTypeId, queryParams } }: Required<GenericGetAction<TGetOlympiadTypesRequest>>) {
  const { response, error } = yield call(getOlympiadTypes, olympiadTypeId, queryParams);

  if (response) {
    yield put(getOlympiadTypesActions.success(response));
  } else {
    yield put(getOlympiadTypesActions.failure(error));
  }
}

function* getOlympiadFormatsSaga({ payload: { olympiadFormatId } }: Required<GenericGetAction<TGetOlympiadFormatsRequest>>) {
  const { response, error } = yield call(getOlympiadFormats, olympiadFormatId);

  if (response) {
    yield put(getOlympiadFormatsActions.success(response));
  } else {
    yield put(getOlympiadFormatsActions.failure(error));
  }
}

function* getSourcesSaga() {
  const { response, error } = yield call(getSources);

  if (response) {
    yield put(getSourcesActions.success(response));
  } else {
    yield put(getSourcesActions.failure(error));
  }
}

export {
  getOlympiadLevelsSaga,
  getOlympiadResultsSaga,
  getOlympiadParallelsSaga,
  getOlympiadTypesSaga,
  getOlympiadFormatsSaga,
  getSourcesSaga
};
