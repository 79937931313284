import { backdropClasses } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';
import { getButtonStyles } from 'portfolio3/ui-kit/utils';
import { SCROLL_WIDTH } from 'style/variables';
import { SxStyles } from 'types';
import { numberToPixelsString } from 'utils';

export const root: SxStyles = {
  zIndex: 1000,
  paddingRight: numberToPixelsString(SCROLL_WIDTH),

  [`& .${backdropClasses.root}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
  },
};

export const container = (marginTop: number): SxStyles => {
  return {
    display: 'grid',
    gridTemplateRows: `${numberToPixelsString(marginTop)} auto 1fr`,
    maxHeight: '100vh',
  };
};

export const desktopBlock: SxStyles = {
  border: '1px solid',
  borderColor: NeutralColors.light_neutrals_300,
  borderRadius: '12px',
  boxShadow: '0px 1px 2px 0px rgba(41, 41, 64, 0.07)',
};

export const searchBar: SxStyles = (theme) => ({
  padding: '12px 20px',

  backgroundColor: NeutralColors.light_neutrals_0,

  [theme.breakpoints.down('commonMd')]: {
    paddingBlock: '16px 20px',
    paddingInline: '12px',

    borderBottom: '1px solid',
    borderColor: NeutralColors.light_neutrals_300,
  },
});

export const result: SxStyles = (theme) => ({
  marginBlock: '8px',

  backgroundColor: NeutralColors.light_neutrals_0,
  overflowY: 'auto',

  [theme.breakpoints.down('commonMd')]: {
    marginBlock: 0,
  },
});

export const mobileHeader: SxStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '12px',
};

export const mobileCloseButton: SxStyles = {
  ...getButtonStyles(),
  width: '24px',
  height: '24px',

  svg: {
    width: '24px',
    height: '24px',
    color: NeutralColors.light_neutrals_600,
  },
};

export const sections: SxStyles = {
  '& > *:not(:first-child)': {
    marginTop: '24px',
  },
};

export const searchButton: SxStyles = {
  position: 'absolute',
  borderColor: NeutralColors.light_neutrals_300,
  borderRadius: '100%',
  transform: 'scale(1.2)',
};
