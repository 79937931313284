import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const averageCard: SxStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  padding: '20px',
  marginTop: '16px',

  border: '2px solid',
  borderColor: NeutralColors.night_neutrals_700,
};

export const subjects: SxStyles = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gap: '12px',
  marginTop: '12px',
};

export const subjectCard: SxStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  padding: '12px 20px',

  border: '2px solid',
  borderColor: NeutralColors.night_neutrals_700,
};
