import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root: SxStyles = {
  zIndex: 100,
  position: 'fixed',
  right: '16px',
  bottom: 0,

  width: '436px',
  paddingBottom: '16px',

  transform: 'translateY(50px)',
  transition: 'transform 0.2s',
};

export const rootActive: SxStyles = {
  transform: 'translateY(0)',
};

export const container: SxStyles = {
  borderRadius: '8px',
  backgroundColor: NeutralColors.light_neutrals_0,
  overflow: 'hidden',
};

export const containerActive: SxStyles = {
  boxShadow: '0px 3px 6px -1px rgba(41, 41, 64, 0.08), 1px 0px 5px 0px rgba(41, 41, 64, 0.04)',
};

export const taskList: SxStyles = {
  maxHeight: '490px',
  paddingBlock: '8px',
  overflowY: 'auto',
};
