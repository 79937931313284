/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

const IconFirstPlace: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="44" height="44" fill="#E5E5E5"/>
    <rect width="1924" height="1397" transform="translate(-1082 -207)" fill="white"/>
    <path d="M22 43.948C34.1503 43.948 44 34.1099 44 21.974C44 9.83811 34.1503 0 22 0C9.84974 0 0 9.83811 0 21.974C0 34.1099 9.84974 43.948 22 43.948Z" fill="#FEC200"/>
    <path d="M19.6122 34.8547C19.5717 35.0241 19.4992 35.1842 19.3987 35.3265C19.1733 35.6216 18.8679 35.8459 18.5187 35.9728C18.0729 36.15 17.5935 36.2272 17.1145 36.199C16.8633 36.1884 16.6127 36.1647 16.364 36.1279C16.1327 36.0914 15.9037 36.0418 15.6781 35.9792C15.4516 35.9146 15.2316 35.837 15.031 35.7659H14.9792C15.0021 35.7252 15.028 35.6863 15.0569 35.6496C15.2167 35.4705 15.402 35.3159 15.6069 35.1907C15.8241 35.0673 16.057 34.9739 16.2992 34.9128C16.5879 34.8435 16.8826 34.8024 17.1792 34.79H18.4734C18.8292 34.79 19.1916 34.8288 19.5539 34.8482L19.6122 34.8547Z" fill="#E57C13"/>
    <path d="M16.5389 33.9433C16.5001 34.0015 16.4742 34.0532 16.4419 34.0984C16.1835 34.4442 15.8006 34.676 15.3742 34.7447C15.1613 34.7892 14.9446 34.813 14.7272 34.8158C14.5203 34.8043 14.3148 34.7762 14.1124 34.7318C13.8921 34.6896 13.6757 34.6291 13.4654 34.5509C13.2847 34.4825 13.1094 34.4004 12.9413 34.3053C12.676 34.1631 12.4172 33.995 12.1583 33.8399L12.113 33.8012L12.2619 33.6784C12.5915 33.4408 12.982 33.3018 13.3877 33.2777C13.8766 33.2471 14.3671 33.3017 14.8372 33.4392C15.3936 33.5814 15.9501 33.7494 16.5001 33.911L16.5389 33.9433Z" fill="#E57C13"/>
    <path d="M11.4726 14.0246C11.2696 13.7906 11.1313 13.5077 11.0715 13.2038C10.9787 12.7716 11.017 12.3216 11.1815 11.9112C11.2998 11.6067 11.4517 11.3162 11.6344 11.0452C11.8246 10.7765 12.0414 10.5275 12.2815 10.302C12.482 10.1016 12.702 9.92065 12.9285 9.72676C12.9285 9.72676 12.9673 9.72676 12.9868 9.68152C13.058 9.82054 13.102 9.97192 13.1162 10.1275C13.1445 10.3991 13.1336 10.6733 13.0838 10.9418C13.0227 11.2575 12.9205 11.5639 12.7797 11.8531C12.4178 12.5869 12.0025 13.2932 11.5373 13.9665L11.4726 14.0246Z" fill="#E57C13"/>
    <path d="M12.5078 12.9777C12.7844 12.1996 13.284 11.5201 13.9444 11.0236C14.6048 10.5272 15.3969 10.2357 16.2219 10.1857C15.9084 10.9341 15.423 11.5983 14.8049 12.1246C14.1625 12.6682 13.3498 12.97 12.5078 12.9777Z" fill="#E57C13"/>
    <path d="M9.88672 31.313C10.0496 31.2126 10.2294 31.1426 10.4173 31.1062C10.8955 31.0139 11.3904 31.0633 11.8408 31.2484C12.3727 31.464 12.8856 31.7234 13.3744 32.0239C13.5814 32.1467 13.7885 32.2695 14.0214 32.4311C14.0214 32.4311 13.9632 32.4311 13.9438 32.4828C13.6478 32.7756 13.2535 32.9484 12.8373 32.9675C12.3336 32.9822 11.8366 32.8496 11.4073 32.5862C10.8512 32.2743 10.3558 31.8649 9.94495 31.3776L9.88672 31.313Z" fill="#E57C13"/>
    <path d="M8.3147 28.3723L8.71587 28.3141C8.99302 28.2882 9.27238 28.328 9.53117 28.4304C9.69491 28.4943 9.85479 28.5677 10.01 28.6502C10.4164 28.8852 10.7905 29.172 11.1229 29.5033L11.9447 30.2982C11.9716 30.321 11.9955 30.347 12.0159 30.3758C11.7812 30.5474 11.5034 30.6503 11.2135 30.6731C10.6585 30.6934 10.1174 30.4963 9.70587 30.1237C9.50713 29.95 9.31914 29.7644 9.14293 29.5679C8.83633 29.2283 8.57532 28.8503 8.36646 28.4433C8.34362 28.4243 8.32584 28.3998 8.3147 28.3723Z" fill="#E57C13"/>
    <path d="M10.2495 16.9393C10.1201 16.8294 9.99719 16.7454 9.88719 16.6355C9.74209 16.493 9.62567 16.3241 9.54425 16.1379C9.41214 15.8445 9.33746 15.5286 9.32425 15.2072C9.31084 14.7085 9.38755 14.2116 9.55072 13.7401C9.68389 13.3153 9.86631 12.9076 10.0943 12.5251L10.1395 12.454L10.2237 12.5768C10.5052 12.9693 10.6711 13.4327 10.7025 13.9146C10.7349 14.3736 10.7022 14.8349 10.6054 15.2847C10.5019 15.8018 10.3919 16.3188 10.2819 16.8294C10.2754 16.8552 10.269 16.8552 10.2495 16.9393Z" fill="#E57C13"/>
    <path d="M7.40234 25.1861C7.58942 25.1646 7.77891 25.1823 7.95881 25.2378C8.46733 25.3834 8.91898 25.6811 9.25293 26.0909C9.66135 26.5657 10.0293 27.0737 10.3529 27.6097L10.5729 27.9587C10.497 27.9895 10.4192 28.0154 10.34 28.0363C10.0424 28.1114 9.72909 28.0957 9.44058 27.991C8.95024 27.8098 8.53232 27.474 8.24999 27.0345C7.87151 26.4944 7.58913 25.8932 7.41528 25.2572C7.41239 25.2401 7.41239 25.2226 7.41528 25.2055C7.41528 25.2055 7.40234 25.199 7.40234 25.1861Z" fill="#E57C13"/>
    <path d="M8.4828 15.343C8.6373 15.4467 8.77114 15.5782 8.87751 15.7308C9.03631 15.9272 9.16697 16.1447 9.26574 16.3771C9.38833 16.7168 9.46662 17.0709 9.49868 17.4306C9.55048 18.1362 9.55048 18.8447 9.49868 19.5504C9.49868 19.5504 9.49868 19.5956 9.49868 19.628L9.33045 19.5633C9.07682 19.443 8.85233 19.2692 8.67248 19.0539C8.49262 18.8385 8.36168 18.5868 8.28868 18.316C8.1595 17.8759 8.11342 17.4157 8.1528 16.9588C8.18084 16.429 8.27875 15.9053 8.44398 15.4012L8.4828 15.343Z" fill="#E57C13"/>
    <path d="M7.48021 18.5164C7.5708 18.5616 7.65492 18.6004 7.73904 18.6521C8.20112 18.9669 8.55207 19.4192 8.74198 19.9447C8.94666 20.4951 9.09621 21.0645 9.18845 21.6444C9.23374 21.8965 9.27256 22.1485 9.31139 22.3941C9.31139 22.3941 9.31139 22.4458 9.31139 22.4911H9.13021C8.82022 22.4162 8.53348 22.2661 8.29535 22.0542C8.05723 21.8423 7.87504 21.575 7.76492 21.276C7.56278 20.7869 7.44676 20.2665 7.42198 19.7379C7.38636 19.3487 7.38636 18.9572 7.42198 18.5681C7.42198 18.5681 7.47374 18.5487 7.48021 18.5164Z" fill="#E57C13"/>
    <path d="M7.11792 21.8447H7.21498C7.8033 21.9982 8.30762 22.3766 8.6191 22.8982C8.94428 23.4176 9.21324 23.97 9.42145 24.5462C9.50557 24.766 9.58321 24.9857 9.66733 25.1925C9.66887 25.2097 9.66887 25.227 9.66733 25.2442C9.4425 25.2993 9.2082 25.3038 8.98145 25.2572C8.7072 25.1619 8.45483 25.0128 8.23923 24.8185C8.02364 24.6243 7.84918 24.3888 7.72616 24.1261C7.57873 23.8539 7.46363 23.5655 7.38321 23.2666C7.30557 22.9564 7.22792 22.6526 7.16321 22.3359C7.11792 22.1808 7.11792 22.0257 7.11792 21.8447Z" fill="#E57C13"/>
    <path d="M17.0048 31.4939H17.0436C17.6129 31.8858 18.1534 32.3178 18.6612 32.7865C19.0316 33.129 19.3192 33.5511 19.5024 34.0209C19.5794 34.2282 19.6231 34.4463 19.6318 34.6672C19.6318 34.6672 19.6318 34.7124 19.6318 34.7448L19.4959 34.706C18.8677 34.5328 18.2803 34.2362 17.7683 33.8335C17.4457 33.5871 17.1917 33.2622 17.0306 32.8899C16.8538 32.4596 16.8378 31.9802 16.9853 31.5391C16.9911 31.5237 16.9975 31.5086 17.0048 31.4939Z" fill="#E57C13"/>
    <path d="M15.0438 30.156C15.5894 30.7514 16.047 31.4218 16.4026 32.1466C16.6268 32.617 16.7035 33.1442 16.6226 33.6589C16.6226 33.73 16.5902 33.8011 16.5773 33.8722H16.5385C15.9268 33.5289 15.409 33.0406 15.0308 32.4504C14.8024 32.1009 14.683 31.6915 14.6879 31.2741C14.695 30.8996 14.8071 30.5347 15.0114 30.2206L15.0438 30.156Z" fill="#E57C13"/>
    <path d="M15.3933 13.779L14.7463 14.044C14.3542 14.2151 13.9469 14.3493 13.5298 14.4447C12.9015 14.6046 12.2355 14.5142 11.6728 14.1926L11.511 14.0892L11.621 13.9858C12.1014 13.5432 12.6837 13.2257 13.3163 13.0616C13.5913 12.9871 13.8794 12.9739 14.1601 13.023C14.4408 13.0722 14.7072 13.1824 14.9404 13.346C15.1166 13.4614 15.2702 13.6082 15.3933 13.779Z" fill="#E57C13"/>
    <path d="M13.5106 28.4111C13.6012 28.5921 13.6788 28.7472 13.75 28.9023C13.9918 29.4103 14.1762 29.9435 14.3 30.4922C14.4255 31.0596 14.3689 31.652 14.1382 32.1855L14.08 32.3083C13.6098 31.843 13.2552 31.2741 13.0447 30.6473C12.9355 30.3407 12.8992 30.013 12.9385 29.69C12.9778 29.367 13.0917 29.0575 13.2712 28.786C13.2971 28.6567 13.4071 28.5469 13.5106 28.4111Z" fill="#E57C13"/>
    <path d="M12.0737 30.3434C11.9508 30.0914 11.8408 29.8652 11.7373 29.6325C11.6835 29.5137 11.6403 29.3904 11.6079 29.2641C11.5561 29.0314 11.4914 28.7923 11.459 28.5532C11.3878 28.1516 11.437 27.7379 11.6004 27.3642C11.7639 26.9904 12.0344 26.6733 12.3779 26.4527L12.4426 26.4139C12.4943 26.6337 12.5396 26.8405 12.5849 27.0602C12.6924 27.5592 12.7423 28.0687 12.7337 28.579C12.7301 29.1638 12.5396 29.7321 12.1902 30.2012L12.0737 30.3434Z" fill="#E57C13"/>
    <path d="M13.6854 15.4722C13.5236 15.6273 13.3748 15.7824 13.213 15.9181C12.9413 16.1508 12.6695 16.377 12.3848 16.5644C11.9367 16.9 11.3846 17.067 10.8254 17.0362C10.6636 17.0362 10.5019 16.991 10.3271 16.9651C10.3271 16.9264 10.3271 16.907 10.3595 16.8876C10.4501 16.7454 10.5342 16.5967 10.6313 16.4546C10.7237 16.3124 10.8253 16.1764 10.9354 16.0474C11.1386 15.7949 11.3811 15.5767 11.6536 15.4011C11.9406 15.2085 12.2785 15.1057 12.6242 15.1057C12.9699 15.1057 13.3078 15.2085 13.5948 15.4011L13.666 15.4463L13.6854 15.4722Z" fill="#E57C13"/>
    <path d="M12.553 17.45C12.4689 17.5728 12.3977 17.6956 12.3201 17.8119C12.0429 18.2458 11.7149 18.645 11.343 19.0011C10.9042 19.42 10.3194 19.6518 9.71242 19.6474H9.60889C9.60889 19.6474 9.60889 19.6474 9.60889 19.6086C9.78531 18.9099 10.1501 18.2729 10.6636 17.7667C10.8981 17.5335 11.1961 17.3742 11.5205 17.3087C11.8449 17.2432 12.1815 17.2744 12.4883 17.3983H12.5206L12.553 17.45Z" fill="#E57C13"/>
    <path d="M11.8022 24.2167C11.8022 24.3007 11.8022 24.3718 11.8022 24.4493C11.8327 25.1002 11.7761 25.7523 11.634 26.3882C11.5067 26.9571 11.1951 27.4683 10.7475 27.8424L10.644 27.9199C10.644 27.8424 10.5987 27.7713 10.5792 27.7002C10.4252 27.1542 10.3704 26.5851 10.4175 26.0198C10.4363 25.6283 10.5687 25.2506 10.7984 24.9328C11.0282 24.615 11.3454 24.3707 11.7116 24.2296L11.8022 24.2167Z" fill="#E57C13"/>
    <path d="M11.6082 21.9288C11.524 22.5822 11.3588 23.2226 11.1164 23.8354C10.9817 24.1624 10.7888 24.4624 10.547 24.7208C10.3439 24.9357 10.1025 25.111 9.83523 25.2378H9.78993H9.75758C9.75404 25.1819 9.75404 25.1257 9.75758 25.0698C9.69117 24.4312 9.77082 23.7859 9.99052 23.1826C10.0961 22.8377 10.3008 22.5313 10.5792 22.3015C10.8576 22.0718 11.1974 21.9287 11.5564 21.89H11.6276L11.6082 21.9288Z" fill="#E57C13"/>
    <path d="M9.39526 22.4781C9.42762 22.2906 9.44703 22.1097 9.47938 21.9352C9.55805 21.4413 9.73163 20.9672 9.99056 20.5392C10.1602 20.2191 10.4246 19.9592 10.7478 19.7949C11.0709 19.6306 11.4369 19.57 11.7959 19.6214H11.8347C11.8023 19.699 11.7764 19.783 11.7441 19.867C11.5624 20.4174 11.3125 20.9428 11 21.4311C10.6504 21.9647 10.1038 22.3387 9.47938 22.4716L9.39526 22.4781Z" fill="#E57C13"/>
    <path d="M24.5884 34.8547C24.627 35.0247 24.6996 35.1852 24.8019 35.3265C25.0255 35.6206 25.3286 35.8448 25.6754 35.9728C26.1236 36.1495 26.605 36.2267 27.086 36.199C27.3373 36.1881 27.5878 36.1644 27.8366 36.1279C28.0678 36.0908 28.2967 36.0411 28.5225 35.9792C28.749 35.9146 28.969 35.837 29.1696 35.7659H29.2213C29.1964 35.725 29.1683 35.6861 29.1372 35.6496C28.9812 35.4692 28.7978 35.3143 28.5937 35.1907C28.3748 35.0662 28.1396 34.9727 27.8949 34.9128C27.6063 34.8432 27.3115 34.8021 27.0149 34.79H25.7207C25.3584 34.79 25.0025 34.8288 24.6401 34.8482L24.5884 34.8547Z" fill="#E57C13"/>
    <path d="M27.6812 33.9435L27.7782 34.0986C28.0356 34.4454 28.419 34.6775 28.8459 34.7449C29.0588 34.7893 29.2754 34.8132 29.4929 34.816C29.7106 34.8062 29.927 34.7781 30.14 34.732C30.3603 34.6898 30.5768 34.6292 30.787 34.551C30.9922 34.4788 31.1913 34.3901 31.3823 34.2861C31.6476 34.1439 31.9 33.9758 32.1653 33.8207L32.2041 33.782L32.0553 33.6592C31.7262 33.4206 31.3354 33.2815 30.9294 33.2585C30.4427 33.2282 29.9544 33.2829 29.4864 33.42C28.93 33.5622 28.3735 33.7302 27.8235 33.8918L27.6812 33.9435Z" fill="#E57C13"/>
    <path d="M32.7476 14.0246C32.9507 13.7906 33.0889 13.5077 33.1488 13.2038C33.2416 12.7716 33.2033 12.3216 33.0388 11.9112C32.9209 11.6053 32.7666 11.3145 32.5794 11.0452C32.3914 10.7747 32.1745 10.5254 31.9323 10.302C31.7317 10.1016 31.5117 9.92065 31.2853 9.72676L31.2206 9.68152C31.1512 9.82087 31.1095 9.9723 31.0976 10.1275C31.0586 10.3975 31.0586 10.6717 31.0976 10.9418C31.1596 11.2565 31.2595 11.5625 31.3953 11.8531C31.7619 12.5857 32.1792 13.2918 32.6441 13.9665L32.7476 14.0246Z" fill="#E57C13"/>
    <path d="M31.7056 12.9777C31.429 12.1996 30.9294 11.5201 30.269 11.0236C29.6086 10.5272 28.8165 10.2357 27.9915 10.1857C28.305 10.9341 28.7904 11.5983 29.4085 12.1246C30.0504 12.6692 30.8634 12.9711 31.7056 12.9777Z" fill="#E57C13"/>
    <path d="M34.3331 31.313C34.1684 31.2115 33.9863 31.1414 33.796 31.1062C33.3199 31.0145 32.8273 31.0639 32.379 31.2484C31.8444 31.4626 31.3292 31.722 30.839 32.0239C30.6319 32.1467 30.4313 32.2695 30.1919 32.4311C30.2192 32.4461 30.2452 32.4634 30.2695 32.4828C30.5655 32.7757 30.9598 32.9484 31.376 32.9675C31.8797 32.9822 32.3767 32.8496 32.806 32.5862C33.3714 32.2769 33.8757 31.8674 34.2942 31.3776L34.3331 31.313Z" fill="#E57C13"/>
    <path d="M35.905 28.3723L35.5039 28.3141C35.2267 28.2882 34.9474 28.328 34.6886 28.4304C34.5248 28.4943 34.3649 28.5677 34.2097 28.6502C33.8033 28.8852 33.4292 29.172 33.0968 29.5033L32.275 30.2982C32.2481 30.321 32.2242 30.347 32.2039 30.3758C32.4378 30.5486 32.716 30.6517 33.0062 30.6731C33.5679 30.6986 34.1169 30.5011 34.5333 30.1237C34.732 29.95 34.92 29.7644 35.0962 29.5679C35.4007 29.2266 35.6615 28.8489 35.8727 28.4433L35.905 28.3723Z" fill="#E57C13"/>
    <path d="M33.9642 16.9393C34.0923 16.8444 34.2154 16.743 34.333 16.6355C34.4781 16.493 34.5945 16.3241 34.676 16.1379C34.8081 15.8445 34.8828 15.5286 34.896 15.2072C34.9094 14.7085 34.8327 14.2116 34.6695 13.7401C34.5363 13.3153 34.3539 12.9076 34.126 12.5251C34.1095 12.5022 34.0944 12.4785 34.0807 12.454L33.9901 12.5768C33.7108 12.97 33.5472 13.4334 33.5177 13.9146C33.4831 14.3733 33.5136 14.8346 33.6083 15.2847C33.7118 15.8018 33.8283 16.3188 33.9383 16.8294L33.9642 16.9393Z" fill="#E57C13"/>
    <path d="M36.8176 25.1862C36.6206 25.167 36.4218 25.1913 36.2352 25.2573C35.7267 25.4028 35.275 25.7006 34.9411 26.1104C34.5326 26.5866 34.1627 27.0945 33.8346 27.6292L33.6211 27.9782L33.8476 28.0557C34.1451 28.1313 34.4585 28.1155 34.747 28.0105C35.2402 27.8313 35.6608 27.4952 35.944 27.054C36.3337 26.5098 36.6249 25.9017 36.8046 25.2573C36.8075 25.2401 36.8075 25.2227 36.8046 25.2056L36.8176 25.1862Z" fill="#E57C13"/>
    <path d="M35.7369 15.343C35.5804 15.4466 35.4444 15.578 35.3357 15.7308C35.1812 15.929 35.0529 16.1462 34.9539 16.3771C34.8313 16.7168 34.753 17.0709 34.721 17.4306C34.6692 18.1362 34.6692 18.8447 34.721 19.5504C34.721 19.5504 34.721 19.5956 34.721 19.628L34.8957 19.5633C35.1499 19.4449 35.3746 19.2715 35.5536 19.0558C35.7327 18.8401 35.8615 18.5874 35.931 18.316C36.0622 17.8763 36.1105 17.4161 36.0733 16.9588C36.0421 16.4294 35.9443 15.9061 35.7822 15.4012L35.7369 15.343Z" fill="#E57C13"/>
    <path d="M36.74 18.5164C36.6511 18.5564 36.5646 18.6017 36.4812 18.6521C36.0191 18.9669 35.6681 19.4192 35.4782 19.9447C35.2706 20.4942 35.1209 21.0639 35.0318 21.6444C34.9865 21.8965 34.9476 22.1485 34.9023 22.3941C34.905 22.4264 34.905 22.4588 34.9023 22.4911H35.077C35.3843 22.4226 35.6695 22.2785 35.9068 22.072C36.1441 21.8654 36.3259 21.6029 36.4359 21.3084C36.6432 20.8205 36.7615 20.2996 36.7853 19.7702C36.8209 19.381 36.8209 18.9895 36.7853 18.6004C36.7632 18.5767 36.7476 18.5478 36.74 18.5164Z" fill="#E57C13"/>
    <path d="M37.1088 21.8447H37.0117C36.7147 21.9215 36.4359 22.0565 36.1916 22.2417C35.9472 22.427 35.7421 22.6589 35.5882 22.924C35.263 23.4434 34.9941 23.9959 34.7859 24.5721C34.7017 24.7918 34.6241 25.0116 34.54 25.2184C34.5384 25.2356 34.5384 25.2529 34.54 25.2701C34.7687 25.3186 35.0055 25.3141 35.2323 25.2572C35.5076 25.1623 35.761 25.0134 35.9778 24.8192C36.1945 24.6249 36.37 24.3893 36.4941 24.1262C36.6415 23.8539 36.7566 23.5655 36.837 23.2666C36.9147 22.9564 36.9923 22.6526 37.0506 22.3359C37.0829 22.1808 37.0894 22.0257 37.1088 21.8447Z" fill="#E57C13"/>
    <path d="M27.2154 31.4939H27.1766C26.6061 31.8842 26.0654 32.3162 25.559 32.7865C25.1859 33.1267 24.8979 33.5495 24.7178 34.0209C24.638 34.2274 24.5943 34.446 24.5884 34.6672C24.5854 34.693 24.5854 34.719 24.5884 34.7448L24.7243 34.706C25.352 34.5314 25.939 34.2349 26.4519 33.8335C26.7628 33.5892 27.0112 33.2747 27.1766 32.9157C27.3534 32.4855 27.3695 32.0061 27.2219 31.565C27.2219 31.565 27.2219 31.5197 27.2154 31.4939Z" fill="#E57C13"/>
    <path d="M29.1756 30.156C28.6346 30.7537 28.1795 31.4237 27.8232 32.1466C27.5991 32.6171 27.5203 33.1435 27.5968 33.6589C27.6056 33.7312 27.6208 33.8026 27.6421 33.8722H27.6809C28.2912 33.527 28.8085 33.0391 29.1885 32.4504C29.4142 32.0996 29.5333 31.691 29.5315 31.2741C29.5243 30.8996 29.4122 30.5347 29.2079 30.2206L29.1756 30.156Z" fill="#E57C13"/>
    <path d="M28.8264 13.779C29.0399 13.876 29.247 13.96 29.4735 14.044C29.8652 14.2161 30.2726 14.3503 30.6899 14.4447C31.3183 14.6046 31.9842 14.5142 32.547 14.1926L32.7023 14.0892L32.5988 13.9858C32.1184 13.5432 31.5361 13.2257 30.9035 13.0616C30.6285 12.9871 30.3404 12.9739 30.0597 13.023C29.779 13.0722 29.5126 13.1824 29.2794 13.346C29.1031 13.4614 28.9496 13.6082 28.8264 13.779Z" fill="#E57C13"/>
    <path d="M30.7026 28.4111L30.4697 28.9023C30.2279 29.4103 30.0434 29.9435 29.9197 30.4922C29.7942 31.0596 29.8508 31.652 30.0814 32.1855L30.1397 32.3083H30.1785C30.6489 31.8443 31.0016 31.2749 31.2073 30.6473C31.319 30.3415 31.3572 30.0138 31.319 29.6905C31.2808 29.3672 31.1672 29.0574 30.9873 28.786C30.9161 28.6567 30.8126 28.5469 30.7026 28.4111Z" fill="#E57C13"/>
    <path d="M32.146 30.3434C32.2625 30.0914 32.3789 29.8652 32.4825 29.6325C32.5369 29.5146 32.5781 29.391 32.6054 29.2641C32.6711 29.0308 32.7208 28.7932 32.7542 28.5532C32.8261 28.1522 32.7779 27.739 32.6156 27.3653C32.4533 26.9917 32.1841 26.6742 31.8419 26.4527L31.7772 26.4139C31.7254 26.6337 31.6736 26.8405 31.6348 27.0602C31.5252 27.5589 31.4731 28.0685 31.4795 28.579C31.4893 29.1627 31.6791 29.7292 32.0231 30.2012C32.0664 30.2466 32.1074 30.294 32.146 30.3434Z" fill="#E57C13"/>
    <path d="M30.5347 15.4722C30.6964 15.6273 30.8453 15.7824 31.007 15.9181C31.2788 16.1508 31.5506 16.377 31.8353 16.5644C32.2828 16.901 32.8354 17.0681 33.3947 17.0362C33.5564 17.0362 33.7182 16.991 33.8864 16.9651V16.8876C33.7958 16.7454 33.7117 16.5967 33.6147 16.4546C33.5222 16.3124 33.4206 16.1764 33.3105 16.0474C33.1057 15.7965 32.8635 15.5785 32.5923 15.4011C32.3053 15.2085 31.9674 15.1057 31.6217 15.1057C31.276 15.1057 30.9381 15.2085 30.6511 15.4011L30.58 15.4463L30.5347 15.4722Z" fill="#E57C13"/>
    <path d="M31.667 17.4499L31.8999 17.8118C32.1769 18.244 32.5025 18.643 32.8705 19.001C33.3101 19.4187 33.8944 19.6503 34.5011 19.6473H34.6111C34.6111 19.6473 34.6111 19.6473 34.6111 19.6085C34.436 18.9078 34.0685 18.2699 33.5499 17.7666C33.3125 17.5312 33.01 17.3722 32.6814 17.31C32.3527 17.2478 32.013 17.2853 31.7058 17.4176L31.667 17.4499Z" fill="#E57C13"/>
    <path d="M32.4174 24.2167C32.4174 24.3007 32.4174 24.3718 32.4174 24.4493C32.3837 25.1003 32.4403 25.7528 32.5857 26.3882C32.713 26.9556 33.0219 27.466 33.4657 27.8424L33.5692 27.9199C33.5692 27.8424 33.621 27.7713 33.6404 27.7002C33.7955 27.1545 33.8482 26.5847 33.7957 26.0198C33.7793 25.6276 33.6479 25.2489 33.4178 24.9306C33.1877 24.6123 32.8691 24.3686 32.5015 24.2296L32.4174 24.2167Z" fill="#E57C13"/>
    <path d="M32.6119 21.9288C32.6961 22.5822 32.8613 23.2226 33.1037 23.8354C33.2283 24.1617 33.4126 24.4619 33.6472 24.7208C33.8504 24.9357 34.0917 25.111 34.359 25.2378H34.4043V25.0698C34.4674 24.4313 34.3878 23.7867 34.1713 23.1826C34.0657 22.8377 33.861 22.5313 33.5827 22.3015C33.3043 22.0718 32.9645 21.9287 32.6055 21.89H32.6119V21.9288Z" fill="#E57C13"/>
    <path d="M34.8184 22.4781C34.8184 22.2907 34.7731 22.1097 34.7408 21.9352C34.6621 21.4413 34.4885 20.9673 34.2296 20.5392C34.0589 20.2186 33.7933 19.9584 33.469 19.7942C33.1448 19.6299 32.7777 19.5696 32.4179 19.6215H32.3855C32.3855 19.699 32.4437 19.783 32.4761 19.8671C32.6714 20.4126 32.9345 20.9315 33.259 21.4117C33.6086 21.9453 34.1552 22.3193 34.7796 22.4522L34.8184 22.4781Z" fill="#E57C13"/>
    <path d="M21.9998 40.4451C18.3404 40.4464 14.7628 39.3636 11.7196 37.3338C8.67642 35.3039 6.30431 32.4182 4.90333 29.0416C3.50235 25.665 3.13545 21.9493 3.84905 18.3644C4.56265 14.7795 6.32468 11.4865 8.91226 8.90195C11.4998 6.31743 14.7967 4.55748 18.3858 3.84472C21.975 3.13197 25.6951 3.49843 29.0757 4.89776C32.4563 6.29708 35.3455 8.6664 37.3777 11.706C39.41 14.7456 40.494 18.319 40.4927 21.974C40.4876 26.8713 38.5376 31.5665 35.0706 35.0294C31.6036 38.4923 26.9028 40.44 21.9998 40.4451ZM21.9998 4.86014C18.611 4.86014 15.2983 5.86385 12.4806 7.74435C9.66289 9.62485 7.46677 12.2977 6.16993 15.4248C4.87308 18.552 4.53377 21.993 5.1949 25.3128C5.85602 28.6325 7.48788 31.682 9.88413 34.0754C12.2804 36.4688 15.3334 38.0987 18.6571 38.7591C21.9808 39.4194 25.4259 39.0805 28.5567 37.7852C31.6876 36.4899 34.3636 34.2964 36.2463 31.482C38.129 28.6676 39.1339 25.3588 39.1339 21.974C39.1288 17.4367 37.3219 13.0867 34.1098 9.87835C30.8976 6.66999 26.5425 4.86527 21.9998 4.86014Z" fill="#E57C13"/>
    <path d="M24.6854 29.4258H21.5149V20.7331V19.3113L21.5666 17.7472C21.0425 18.2772 20.6737 18.6197 20.4666 18.7878L18.7649 20.1838L17.2378 18.2772L22.0778 14.3994H24.666L24.6854 29.4258Z" fill="#E57C13"/>
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="44" height="44">
      <path d="M22 43.9999C34.1503 43.9999 44 34.1618 44 22.0259C44 9.88999 34.1503 0.0518799 22 0.0518799C9.84974 0.0518799 0 9.88999 0 22.0259C0 34.1618 9.84974 43.9999 22 43.9999Z" fill="#A9BEFF"/>
    </mask>
    <g mask="url(#mask0)">
      <path opacity="0.3" d="M5.45437 25.1989C28.3648 25.1989 46.9373 6.64824 46.9373 -16.2351C46.9373 -39.1185 28.3648 -57.6691 5.45437 -57.6691C-17.456 -57.6691 -36.0286 -39.1185 -36.0286 -16.2351C-36.0286 6.64824 -17.456 25.1989 5.45437 25.1989Z" fill="url(#paint0_linear)"/>
    </g>
    <rect x="-1017.5" y="-130.5" width="1768" height="373" rx="4.5" stroke="#7B61FF" strokeDasharray="10 5"/>
    <defs>
      <linearGradient id="paint0_linear" x1="-9.12386" y1="-43.5217" x2="22.9306" y2="16.6217" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" stopOpacity="0"/>
        <stop offset="0.35" stopColor="white" stopOpacity="0.01"/>
        <stop offset="0.49" stopColor="white" stopOpacity="0.03"/>
        <stop offset="0.59" stopColor="white" stopOpacity="0.08"/>
        <stop offset="0.67" stopColor="white" stopOpacity="0.15"/>
        <stop offset="0.74" stopColor="white" stopOpacity="0.24"/>
        <stop offset="0.8" stopColor="white" stopOpacity="0.35"/>
        <stop offset="0.85" stopColor="white" stopOpacity="0.47"/>
        <stop offset="0.9" stopColor="white" stopOpacity="0.62"/>
        <stop offset="0.95" stopColor="white" stopOpacity="0.79"/>
        <stop offset="1" stopColor="white"/>
      </linearGradient>
    </defs>
  </svg>
);

export default IconFirstPlace;
