/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

const IconGoldGto: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="50" height="50" fill="#E5E5E5"/>
    <rect width="1924" height="1397" transform="translate(-1049 -555)" fill="white"/>
    <g clip-path="url(#clip0)">
    <path d="M25 -5.81177V49.8693L28.1815 52L30.8 48.9109L34.5103 50.2629L36.1735 47.1567L43.0653 48.8595L43.7647 42.1679L46.7756 41.5518L46.605 37.7011L50.2726 36.0324L49.0017 32.3614L52.2344 29.9056L50.1362 26.5768L52.8826 23.4706L50.2726 21.0746L54 14.9649L47.8503 12.4405L48.1744 8.82087L44.5067 7.97372L44.0462 3.90054L40.0544 3.96044L38.8091 0.0926299L35.15 1.00824L32.9238 -2.33759L29.1879 -0.694623L25 -5.81177Z" fill="#FEC200"/>
    <path d="M25 -5.81177V49.8693L21.8185 52L19.2 48.9109L15.4897 50.2629L13.8265 47.1567L6.93469 48.8595L6.23528 42.1679L3.2244 41.5518L3.39499 37.7011L-0.264121 36.0324L0.99823 32.3614L-2.23441 29.9056L-0.136181 26.5768L-2.89118 23.4706L-0.264121 21.0746L-4 14.9649L2.1497 12.4405L1.82558 8.82087L5.49323 7.97372L5.95381 3.90054L9.94557 3.96044L11.1909 0.0926299L14.85 1.00824L17.0762 -2.33759L20.812 -0.694623L25 -5.81177Z" fill="#FEC200"/>
    <path d="M17.0762 -2.33765L24.0788 21.5281L20.8121 -0.694684L17.0762 -2.33765Z" fill="#E57C13"/>
    <path d="M11.1912 0.0925903L22.9532 25.9008L14.8503 1.0082L11.1912 0.0925903Z" fill="#E57C13"/>
    <path d="M5.95361 3.90051L21.7501 24.5574L9.94537 3.96041L5.95361 3.90051Z" fill="#E57C13"/>
    <path d="M1.82568 8.82085L21.3239 24.5573L5.49333 7.97369L1.82568 8.82085Z" fill="#E57C13"/>
    <path d="M-4 14.9647L21.3238 24.5572L2.1497 12.4404L-4 14.9647Z" fill="#E57C13"/>
    <path d="M-2.89111 23.4706L21.3239 24.5573L-0.264057 21.0746L-2.89111 23.4706Z" fill="#E57C13"/>
    <path d="M-2.23438 29.9056L21.7503 24.5574L-0.136142 26.5769L-2.23438 29.9056Z" fill="#E57C13"/>
    <path d="M-0.26416 36.0325L22.3388 24.5574L0.998191 32.3615L-0.26416 36.0325Z" fill="#E57C13"/>
    <path d="M3.22437 41.5519L22.6458 25.2335L3.39495 37.7012L3.22437 41.5519Z" fill="#E57C13"/>
    <path d="M6.93476 48.8597L23.55 25.2335L6.23535 42.168L6.93476 48.8597Z" fill="#E57C13"/>
    <path d="M15.4897 50.263L24.6588 25.2335L13.8264 47.1568L15.4897 50.263Z" fill="#E57C13"/>
    <path d="M21.8187 52L24.4117 25.9008L19.2002 48.9109L21.8187 52Z" fill="#E57C13"/>
    <path d="M28.1815 52L25 24.4718V49.8693L28.1815 52Z" fill="#E57C13"/>
    <path d="M30.8 48.9109L25 24.4718L34.5103 50.2629L30.8 48.9109Z" fill="#E57C13"/>
    <path d="M43.0653 48.8595L25 24.4718L36.1735 47.1567L43.0653 48.8595Z" fill="#E57C13"/>
    <path d="M46.7756 41.5518L25 24.4718L43.7647 42.1679L46.7756 41.5518Z" fill="#E57C13"/>
    <path d="M50.2726 36.0324L25 24.4718L46.605 37.7011L50.2726 36.0324Z" fill="#E57C13"/>
    <path d="M52.2344 29.9056L25 24.4718L49.0017 32.3615L52.2344 29.9056Z" fill="#E57C13"/>
    <path d="M52.8826 23.4706L25 24.4718L50.1362 26.5769L52.8826 23.4706Z" fill="#E57C13"/>
    <path d="M54 14.9649L25 24.4718L50.2726 21.0747L54 14.9649Z" fill="#E57C13"/>
    <path d="M48.1744 8.82098L25 24.4719L47.8503 12.4406L48.1744 8.82098Z" fill="#E57C13"/>
    <path d="M44.0462 3.90051L25 24.4718L44.5067 7.97369L44.0462 3.90051Z" fill="#E57C13"/>
    <path d="M38.8091 0.0925903L25 24.4718L40.0544 3.9604L38.8091 0.0925903Z" fill="#E57C13"/>
    <path d="M32.9238 -2.33765L25 24.4717L35.15 1.00818L32.9238 -2.33765Z" fill="#E57C13"/>
    <path d="M25 -5.81177V24.4718L29.1879 -0.694623L25 -5.81177Z" fill="#E57C13"/>
    <path d="M47.8928 24.4719C47.8934 27.6511 47.2356 30.7957 45.9612 33.7064C44.6867 36.6172 42.8234 39.2305 40.4893 41.3807C39.7052 42.0987 38.8731 42.762 37.9987 43.366C37.3478 43.8213 36.673 44.2412 35.9772 44.6239C33.3196 46.0881 30.3959 47.0019 27.3796 47.3108C26.5891 47.3938 25.7947 47.4338 24.9999 47.4306C21.1947 47.4346 17.4484 46.4876 14.0993 44.6752C13.6472 44.4271 13.1952 44.1618 12.7687 43.888C11.3853 43.0021 10.0988 41.9724 8.93049 40.816C6.24712 38.1573 4.26003 34.8746 3.14585 31.2599C2.03167 27.6451 1.82491 23.8102 2.54394 20.0959C3.26297 16.3817 4.88553 12.9032 7.26738 9.96958C9.64924 7.03601 12.7166 4.73823 16.1969 3.28048C19.6771 1.82273 23.4625 1.25018 27.2164 1.6137C30.9704 1.97723 34.5767 3.26557 37.7148 5.36422C40.8529 7.46287 43.4256 10.3068 45.2042 13.6433C46.9828 16.9797 47.9122 20.7054 47.9099 24.489L47.8928 24.4719Z" fill="#FEC200"/>
    <path d="M37.999 43.3658C37.3481 43.8211 36.6733 44.241 35.9775 44.6237C34.5696 45.3937 33.0861 46.016 31.5508 46.4806C31.5508 46.4806 30.7576 46.7202 29.4781 46.994C28.5229 47.1994 27.3799 47.3106 27.3799 47.3106C26.5894 47.3936 25.795 47.4336 25.0002 47.4304C21.195 47.4344 17.4487 46.4874 14.0996 44.675C13.6476 44.4269 13.1955 44.1616 12.769 43.8878L20.599 33.7647H31.0646L37.999 43.3658Z" fill="#E83F40"/>
    <path d="M41.1463 38.5739C41.1465 39.5287 40.9214 40.47 40.4895 41.3208C39.1213 42.5804 37.6063 43.6693 35.9774 44.5639C35.695 44.6049 35.4099 44.6249 35.1245 44.6238C33.9298 44.6238 32.7619 44.2683 31.7686 43.6022C30.7754 42.9361 30.0014 41.9894 29.5446 40.8819C29.0877 39.7743 28.9687 38.5557 29.2024 37.3803C29.4361 36.2048 30.012 35.1254 30.8574 34.2784C31.7028 33.4315 32.7797 32.8551 33.9516 32.6223C35.1236 32.3895 36.3381 32.5107 37.4414 32.9706C38.5447 33.4305 39.4872 34.2083 40.1497 35.2057C40.8123 36.2032 41.165 37.3753 41.1633 38.5739H41.1463Z" fill="#FEC200"/>
    <path d="M8.63205 38.5739C8.63186 39.5287 8.85691 40.47 9.28882 41.3208C10.657 42.5804 12.172 43.6693 13.8009 44.5639C14.0833 44.6049 14.3684 44.6249 14.6538 44.6238C15.8485 44.6238 17.0164 44.2683 18.0097 43.6022C19.0029 42.9361 19.777 41.9894 20.2338 40.8819C20.6906 39.7743 20.8097 38.5557 20.576 37.3803C20.3423 36.2048 19.7663 35.1254 18.9209 34.2784C18.0755 33.4315 16.9987 32.8551 15.8267 32.6223C14.6547 32.3895 13.4402 32.5107 12.3369 32.9706C11.2336 33.4305 10.2911 34.2083 9.62857 35.2057C8.96604 36.2032 8.6133 37.3753 8.61499 38.5739H8.63205Z" fill="#FEC200"/>
    <path d="M31.3542 39.9516C31.395 40.1488 31.4763 40.3354 31.5931 40.4992C31.8388 40.8534 32.1828 41.1271 32.5825 41.2865C33.0892 41.498 33.6388 41.586 34.186 41.5432C34.4716 41.5349 34.7565 41.5092 35.039 41.4662C35.3012 41.4265 35.5605 41.3693 35.8151 41.2951L36.5742 41.0469H36.634C36.634 40.9956 36.5742 40.9528 36.5487 40.91C36.3725 40.6991 36.162 40.5197 35.926 40.3795C35.6815 40.2315 35.4173 40.1192 35.1413 40.0457C34.8133 39.9708 34.4794 39.925 34.1434 39.9088H32.6763C32.2669 39.9088 31.8234 39.9088 31.4481 39.9773L31.3542 39.9516Z" fill="#E57C13"/>
    <path d="M34.8342 38.8906L34.9451 39.0703C35.0848 39.2764 35.2636 39.4529 35.4715 39.5895C35.6794 39.7261 35.9121 39.82 36.1563 39.8661C36.3866 39.9128 36.6207 39.9386 36.8557 39.9431C37.0915 39.9305 37.3256 39.8961 37.5551 39.8404C37.7929 39.789 38.0266 39.7203 38.2545 39.635C38.4867 39.5499 38.712 39.4469 38.9283 39.327C39.2354 39.1558 39.5169 38.9676 39.7813 38.7793H39.8325L39.6619 38.6338C39.2904 38.361 38.8501 38.1979 38.391 38.1632C37.8356 38.1312 37.2788 38.1948 36.7448 38.3515C36.1136 38.5226 35.491 38.7194 34.8598 38.9077L34.8342 38.8906Z" fill="#E57C13"/>
    <path d="M40.5745 15.6409C40.802 15.3672 40.9574 15.0407 41.0265 14.6911C41.1265 14.1927 41.0821 13.676 40.8986 13.2022C40.766 12.8502 40.5943 12.5144 40.3868 12.201C40.1835 11.8925 39.9491 11.6058 39.6874 11.3453C39.4571 11.1142 39.2098 10.9003 38.971 10.6778L38.8942 10.6179C38.8212 10.7841 38.7724 10.9599 38.7492 11.1399C38.7205 11.4598 38.7349 11.7822 38.7918 12.0983C38.8576 12.4658 38.9723 12.8227 39.133 13.1594C39.5446 14.0126 40.0149 14.8361 40.5404 15.6238L40.5745 15.6409Z" fill="#E57C13"/>
    <path d="M39.3975 14.4344C39.0987 13.5422 38.5446 12.758 37.8043 12.1797C37.0641 11.6015 36.1705 11.2547 35.2351 11.1827C35.5884 12.045 36.1375 12.8127 36.8386 13.4246C37.5447 14.0559 38.4519 14.4139 39.3975 14.4344Z" fill="#E57C13"/>
    <path d="M42.3658 35.8186C42.1812 35.7005 41.9756 35.6191 41.7602 35.579C41.2218 35.4656 40.6616 35.5224 40.1567 35.7416C39.5624 35.9926 38.9915 36.2961 38.4508 36.6486C38.212 36.7941 37.9817 36.9396 37.7258 37.1193L37.8111 37.1792C38.1403 37.5234 38.5898 37.7259 39.0649 37.7439C39.6367 37.7661 40.2017 37.6139 40.6855 37.3075C41.2993 36.9433 41.8473 36.4775 42.3061 35.9298L42.3658 35.8186Z" fill="#E57C13"/>
    <path d="M44.1486 32.3785L43.688 32.3186C43.3773 32.2911 43.0645 32.3381 42.7754 32.4555C42.5857 32.5236 42.403 32.6095 42.2295 32.7122C41.7651 32.9831 41.3402 33.3171 40.9671 33.7049L40.0374 34.629L39.9521 34.7232C40.2183 34.9209 40.5346 35.0395 40.8648 35.0654C41.1776 35.0795 41.49 35.0299 41.7832 34.9196C42.0763 34.8093 42.3442 34.6406 42.5707 34.4237C42.7965 34.2228 43.0101 34.0084 43.2104 33.7819C43.5452 33.3808 43.8315 32.9413 44.0633 32.4726L44.1486 32.3785Z" fill="#E57C13"/>
    <path d="M41.9564 19.0466C42.1014 18.9183 42.2464 18.8156 42.3658 18.6872C42.5299 18.5231 42.6603 18.3283 42.7497 18.1139C42.9045 17.7688 42.9914 17.3968 43.0055 17.0186C43.0192 16.4376 42.9327 15.8586 42.7497 15.3072C42.5979 14.8151 42.392 14.3415 42.1355 13.8953C42.1159 13.8684 42.0988 13.8397 42.0844 13.8097L41.982 13.9552C41.6596 14.4128 41.4732 14.9529 41.4447 15.5126C41.4067 16.0453 41.4411 16.5808 41.547 17.1042C41.6664 17.7117 41.7944 18.3107 41.9223 18.9097L41.9564 19.0466Z" fill="#E57C13"/>
    <path d="M45.1805 28.6647C44.971 28.646 44.7599 28.6663 44.5578 28.7246C43.9766 28.9014 43.4622 29.2504 43.0822 29.7258C42.6138 30.2786 42.1943 30.8713 41.8284 31.4971L41.5811 31.9079L41.8455 31.9934C42.1811 32.0802 42.5352 32.0623 42.8605 31.9421C43.4272 31.722 43.9044 31.3185 44.2166 30.7954C44.6433 30.1639 44.9658 29.4676 45.1719 28.7332C45.1719 28.7332 45.1719 28.7332 45.1719 28.6733C45.1719 28.6134 45.1805 28.6818 45.1805 28.6647Z" fill="#E57C13"/>
    <path d="M43.9611 17.1812C43.782 17.3009 43.6284 17.455 43.5091 17.6347C43.3279 17.8722 43.1815 18.1344 43.0741 18.4134C42.9289 18.819 42.8372 19.2418 42.8011 19.6713C42.7414 20.4917 42.7414 21.3153 42.8011 22.1357C42.7973 22.167 42.7973 22.1986 42.8011 22.2298L42.9888 22.1614C43.2779 22.0184 43.5329 21.8144 43.736 21.5633C43.9392 21.3122 44.0858 21.02 44.1658 20.7067C44.3131 20.1899 44.3679 19.651 44.3279 19.1151C44.2955 18.4985 44.1837 17.8887 43.9952 17.301C43.9803 17.2621 43.9689 17.222 43.9611 17.1812Z" fill="#E57C13"/>
    <path d="M45.0956 20.8864C44.9934 20.9355 44.8937 20.9897 44.797 21.049C44.2747 21.4139 43.8807 21.935 43.6712 22.5379C43.4319 23.1799 43.2604 23.8453 43.1594 24.5232C43.1082 24.8141 43.0656 25.1051 43.0144 25.3789C43.0181 25.4159 43.0181 25.4531 43.0144 25.4901C43.0826 25.4942 43.1509 25.4942 43.2191 25.4901C43.5695 25.4078 43.8937 25.2385 44.162 24.9978C44.4302 24.7571 44.6339 24.4527 44.7544 24.1124C44.9882 23.5408 45.1209 22.9328 45.1468 22.3155C45.1936 21.8631 45.1936 21.4072 45.1468 20.9549C45.1349 20.9286 45.1174 20.9052 45.0956 20.8864Z" fill="#E57C13"/>
    <path d="M45.5128 24.8055H45.4019C44.7314 24.9895 44.161 25.4326 43.8155 26.0377C43.4486 26.6404 43.1456 27.28 42.9114 27.9459L42.6299 28.7246C42.6299 28.7246 42.6299 28.7246 42.6299 28.7845C42.8847 28.8487 43.1513 28.8487 43.4061 28.7845C43.7199 28.6728 44.0079 28.4984 44.2527 28.2719C44.4976 28.0454 44.6941 27.7715 44.8305 27.4667C44.9999 27.1496 45.1316 26.8135 45.2228 26.4656C45.3081 26.1062 45.3934 25.7468 45.4702 25.3788C45.4702 25.1563 45.4702 24.9766 45.5128 24.8055Z" fill="#E57C13"/>
    <path d="M34.3057 36.0325C33.6562 36.4857 33.0431 36.9893 32.4719 37.5385C32.0509 37.9389 31.7271 38.4306 31.5251 38.9761C31.4316 39.2225 31.3797 39.4828 31.3716 39.7462C31.3716 39.7462 31.3716 39.7976 31.3716 39.8318H31.5251C32.2373 39.6263 32.9014 39.2801 33.4783 38.8135C33.851 38.5273 34.1446 38.1503 34.3313 37.7182C34.5417 37.2088 34.5599 36.6399 34.3825 36.118C34.3588 36.0878 34.3332 36.0592 34.3057 36.0325Z" fill="#E57C13"/>
    <path d="M36.5229 34.4664C35.9042 35.1642 35.3846 35.9446 34.9791 36.7853C34.7388 37.3214 34.656 37.9151 34.7403 38.4968C34.7403 38.5823 34.7403 38.6593 34.7915 38.7449H34.8341C35.5309 38.3417 36.117 37.7713 36.54 37.0848C36.7984 36.6758 36.9318 36.1999 36.9238 35.7157C36.9203 35.2823 36.7962 34.8585 36.5656 34.492C36.5535 34.4804 36.5389 34.4716 36.5229 34.4664Z" fill="#E57C13"/>
    <path d="M36.1306 15.3928L36.8386 15.7009C37.2844 15.8984 37.7468 16.0559 38.2203 16.1715C38.5798 16.2633 38.9541 16.281 39.3205 16.2236C39.687 16.1663 40.0381 16.035 40.3527 15.8378L40.5233 15.718L40.3527 15.5982C39.8183 15.0979 39.1718 14.734 38.4677 14.5371C38.1544 14.4503 37.8259 14.4344 37.5057 14.4907C37.1856 14.5469 36.882 14.6738 36.6168 14.8623C36.4262 15.0105 36.2619 15.1899 36.1306 15.3928Z" fill="#E57C13"/>
    <path d="M38.2628 32.4299L37.9899 33.0118C37.7165 33.6035 37.5077 34.2232 37.3672 34.8601C37.2273 35.5228 37.2928 36.2125 37.5549 36.8368C37.5725 36.8848 37.5953 36.9308 37.6231 36.9737C38.1754 36.4459 38.5961 35.7951 38.8514 35.074C38.9765 34.7161 39.0185 34.3342 38.9742 33.9575C38.9298 33.5809 38.8003 33.2193 38.5955 32.9005C38.4938 32.7373 38.3828 32.5801 38.2628 32.4299Z" fill="#E57C13"/>
    <path d="M39.8922 34.689L40.2675 33.8333C40.3297 33.6988 40.3783 33.5583 40.4125 33.414C40.4872 33.1327 40.5441 32.8469 40.583 32.5583C40.6644 32.0931 40.6094 31.6142 40.4247 31.1797C40.24 30.7453 39.9335 30.374 39.5425 30.111L39.4998 30.1024C39.4401 30.3591 39.3804 30.5987 39.3377 30.8469C39.2168 31.4322 39.1596 32.029 39.1672 32.6268C39.1734 33.3028 39.3879 33.9604 39.7813 34.5093L39.8922 34.689Z" fill="#E57C13"/>
    <path d="M38.0671 17.3353C38.2463 17.515 38.4168 17.6947 38.6045 17.8572C38.7921 18.0198 39.2186 18.3878 39.5342 18.6274C40.0433 19.0129 40.6712 19.2067 41.3083 19.175C41.4874 19.175 41.6665 19.1237 41.8627 19.0895C41.8627 19.0895 41.8627 19.021 41.8627 19.0039L41.5557 18.4905C41.4448 18.3279 41.3254 18.1653 41.206 18.0198C40.9638 17.7269 40.6757 17.4755 40.353 17.2754C40.0296 17.0512 39.6458 16.9312 39.2527 16.9312C38.8596 16.9312 38.4759 17.0512 38.1524 17.2754L38.0671 17.3353Z" fill="#E57C13"/>
    <path d="M39.3467 19.6713L39.6111 20.0906C39.9257 20.5921 40.2947 21.057 40.7114 21.4768C40.953 21.7239 41.2409 21.9207 41.5585 22.0558C41.8762 22.1909 42.2173 22.2617 42.5623 22.2641H42.6817C42.6817 22.2641 42.6817 22.2641 42.6817 22.2128C42.4839 21.4019 42.0713 20.6598 41.4876 20.0649C41.2245 19.7964 40.8878 19.6124 40.5203 19.5362C40.1528 19.4601 39.771 19.4952 39.4234 19.6371C39.4234 19.6371 39.3723 19.6713 39.3467 19.6713Z" fill="#E57C13"/>
    <path d="M40.1994 27.5353C40.1994 27.6295 40.1994 27.7236 40.1994 27.8092C40.152 28.5714 40.2036 29.3365 40.353 30.0854C40.5001 30.7501 40.8559 31.3499 41.368 31.7968L41.4874 31.8823C41.4874 31.7882 41.5471 31.7112 41.5641 31.6256C41.7391 30.9882 41.7997 30.3247 41.7432 29.6661C41.7233 29.2126 41.5735 28.7745 41.3118 28.4042C41.0501 28.0339 40.6876 27.7471 40.2677 27.5781L40.1994 27.5353Z" fill="#E57C13"/>
    <path d="M40.421 24.8654C40.5169 25.6265 40.703 26.3734 40.9754 27.0902C41.1238 27.4737 41.3438 27.8251 41.6237 28.1257C41.8491 28.3763 42.1215 28.5798 42.4254 28.7247H42.4766V28.5278C42.549 27.7842 42.4588 27.0337 42.2122 26.3287C42.0939 25.9329 41.863 25.5804 41.5479 25.3143C41.2327 25.0483 40.847 24.8804 40.4381 24.8312H40.3613L40.421 24.8654Z" fill="#E57C13"/>
    <path d="M42.912 25.5073C42.912 25.2849 42.8609 25.0795 42.8267 24.8741C42.7331 24.3014 42.5366 23.7505 42.2467 23.2483C42.0588 22.8761 41.7596 22.5721 41.3911 22.3788C41.0226 22.1855 40.6031 22.1125 40.1912 22.1701L40.2935 22.461C40.5039 23.0926 40.7903 23.6961 41.1465 24.258C41.3372 24.5631 41.586 24.8275 41.8787 25.036C42.1715 25.2446 42.5023 25.3931 42.8523 25.4731L42.912 25.5073Z" fill="#E57C13"/>
    <path d="M18.6459 39.9516C18.5978 40.1464 18.517 40.3316 18.4071 40.4993C18.1603 40.8558 17.8127 41.13 17.4091 41.2865C16.9056 41.499 16.3586 41.587 15.8141 41.5433C15.5285 41.5349 15.2437 41.5092 14.9612 41.4662C14.6964 41.4256 14.4343 41.3684 14.1765 41.2951L13.4259 41.0641H13.3662L13.46 40.9271C13.6361 40.7162 13.8467 40.5368 14.0827 40.3966C14.3272 40.2487 14.5914 40.1363 14.8674 40.0629C15.1954 39.988 15.5293 39.9421 15.8653 39.926H17.3239C17.7333 39.926 18.1768 39.926 18.5436 39.9944L18.6459 39.9516Z" fill="#E57C13"/>
    <path d="M15.1657 38.8904L15.0548 39.0701C14.7714 39.4852 14.3361 39.7712 13.8436 39.8659C13.6105 39.9132 13.3735 39.9389 13.1357 39.9429C12.9028 39.9297 12.6715 39.8953 12.4448 39.8403C12.2042 39.789 11.9676 39.7203 11.7369 39.6349C11.5054 39.5481 11.2802 39.4452 11.0631 39.3268C10.7645 39.1557 10.4745 38.9674 10.2101 38.7792H10.1589L10.3295 38.6337C10.7004 38.3596 11.141 38.1964 11.6004 38.1631C12.153 38.1315 12.7069 38.1952 13.2381 38.3513C13.8692 38.5225 14.5004 38.7193 15.1231 38.9075L15.1657 38.8904Z" fill="#E57C13"/>
    <path d="M9.4254 15.6409C9.19545 15.3677 9.03714 15.0412 8.96481 14.6911C8.87383 14.1922 8.91801 13.678 9.09275 13.2022C9.23562 12.8467 9.42184 12.5104 9.64716 12.201C9.85052 11.8925 10.0849 11.6058 10.3466 11.3453C10.5769 11.1142 10.8242 10.9003 11.063 10.6778C11.063 10.6778 11.1057 10.6778 11.1313 10.6179C11.212 10.7812 11.2612 10.9583 11.2763 11.1399C11.319 11.4579 11.319 11.7803 11.2763 12.0983C11.2026 12.4637 11.0882 12.8197 10.9351 13.1594C10.5238 14.0145 10.0506 14.8382 9.51922 15.6238L9.4254 15.6409Z" fill="#E57C13"/>
    <path d="M10.6025 14.4344C10.9032 13.5352 11.4634 12.746 12.2122 12.167C12.9609 11.588 13.8643 11.2454 14.8075 11.1827C14.4483 12.0417 13.9002 12.8082 13.204 13.4246C12.4861 14.0641 11.5625 14.4226 10.6025 14.4344Z" fill="#E57C13"/>
    <path d="M7.62549 35.8185C7.81015 35.7004 8.01576 35.6191 8.23108 35.579C8.7723 35.4654 9.33526 35.5222 9.84313 35.7415C10.4374 35.9925 11.0083 36.2961 11.549 36.6486C11.7793 36.7941 12.0181 36.9395 12.274 37.1192C12.2415 37.1372 12.2102 37.1572 12.1802 37.1791C11.8547 37.5236 11.4077 37.7263 10.9349 37.7439C10.3605 37.7661 9.7927 37.6141 9.30578 37.3075C8.69198 36.9433 8.14405 36.4775 7.6852 35.9298L7.62549 35.8185Z" fill="#E57C13"/>
    <path d="M5.84351 32.3785L6.30409 32.3186C6.61761 32.2911 6.93318 32.338 7.22527 32.4556C7.41422 32.5253 7.59686 32.6112 7.77115 32.7123C8.23411 32.9853 8.65865 33.3191 9.0335 33.7049L9.96321 34.6291L10.04 34.7232C9.77778 34.9223 9.46381 35.0412 9.13585 35.0655C8.82306 35.0795 8.51067 35.0299 8.21749 34.9196C7.92432 34.8093 7.65643 34.6406 7.42997 34.4237C7.20208 34.225 6.98833 34.0105 6.79027 33.7819C6.45541 33.3808 6.1691 32.9413 5.93733 32.4727L5.84351 32.3785Z" fill="#E57C13"/>
    <path d="M8.04341 19.0466C7.89841 18.9183 7.75341 18.8156 7.62546 18.6872C7.46413 18.5209 7.33409 18.3267 7.24164 18.1139C7.09079 17.7674 7.00408 17.3963 6.98576 17.0186C6.97481 16.4371 7.06427 15.8581 7.25017 15.3072C7.40188 14.8151 7.60786 14.3415 7.86429 13.8953L7.91546 13.8097L8.01782 13.9552C8.33656 14.4147 8.52252 14.9537 8.55517 15.5126C8.59037 16.0457 8.55307 16.5811 8.44429 17.1042C8.33341 17.7117 8.19694 18.3107 8.07752 18.9097L8.04341 19.0466Z" fill="#E57C13"/>
    <path d="M4.81958 28.6648C5.02903 28.6461 5.24013 28.6664 5.44223 28.7247C6.02349 28.9015 6.53787 29.2505 6.91781 29.7259C7.38352 30.2788 7.8002 30.8715 8.16311 31.4972C8.2484 31.6256 8.32517 31.7539 8.41046 31.908L8.15458 31.9935C7.8189 32.0803 7.46485 32.0624 7.13958 31.9422C6.57153 31.7244 6.09358 31.3203 5.7834 30.7955C5.35002 30.1678 5.02693 29.4703 4.82811 28.7333C4.82811 28.7333 4.82811 28.7333 4.82811 28.6734C4.82502 28.6708 4.82217 28.6679 4.81958 28.6648Z" fill="#E57C13"/>
    <path d="M6.0391 17.1812C6.21432 17.3055 6.36718 17.4589 6.49115 17.6347C6.66941 17.8741 6.81566 18.1359 6.92615 18.4134C7.0643 18.8205 7.15308 19.2428 7.19057 19.6713C7.25026 20.4917 7.25026 21.3153 7.19057 22.1357C7.19057 22.1357 7.19057 22.1956 7.19057 22.2298L6.99439 22.1614C6.70717 22.0157 6.45386 21.8108 6.25102 21.5601C6.04819 21.3094 5.9004 21.0186 5.81733 20.7067C5.67278 20.1894 5.62079 19.6505 5.6638 19.1151C5.69748 18.4993 5.80633 17.8901 5.98792 17.301L6.0391 17.1812Z" fill="#E57C13"/>
    <path d="M4.90515 20.8864L5.19515 21.049C5.72025 21.413 6.11729 21.9341 6.32956 22.5379C6.56489 23.1812 6.73631 23.8461 6.84133 24.5232C6.8925 24.8141 6.93515 25.1051 6.9778 25.3789C6.9778 25.3789 6.9778 25.4388 6.9778 25.4901C6.90964 25.4948 6.84125 25.4948 6.77309 25.4901C6.42268 25.4078 6.09848 25.2385 5.83023 24.9978C5.56199 24.7571 5.35828 24.4527 5.2378 24.1124C5.00404 23.5408 4.87128 22.9328 4.84545 22.3155C4.80282 21.8629 4.80282 21.4074 4.84545 20.9549C4.8623 20.9296 4.88237 20.9065 4.90515 20.8864Z" fill="#E57C13"/>
    <path d="M4.52924 24.8055H4.64012C5.30977 24.9912 5.87966 25.4338 6.22659 26.0377C6.57875 26.6422 6.86745 27.2817 7.08806 27.9459C7.18188 28.2026 7.2757 28.4679 7.361 28.7246C7.36209 28.7446 7.36209 28.7646 7.361 28.7845C7.10333 28.8487 6.83395 28.8487 6.57629 28.7845C6.26449 28.6709 5.97854 28.4958 5.73535 28.2695C5.49216 28.0431 5.29666 27.7702 5.16041 27.4667C4.9874 27.1513 4.85554 26.8148 4.76806 26.4656C4.67423 26.1062 4.59747 25.7468 4.52071 25.3788C4.51352 25.1877 4.51637 24.9963 4.52924 24.8055Z" fill="#E57C13"/>
    <path d="M15.6943 36.0325H15.737C16.3865 36.4857 16.9996 36.9893 17.5708 37.5385C17.9917 37.9389 18.3156 38.4306 18.5175 38.9761C18.6083 39.2229 18.6573 39.4832 18.6625 39.7462V39.8318H18.5005C17.789 39.6244 17.1253 39.2784 16.5473 38.8135C16.1746 38.5273 15.881 38.1503 15.6943 37.7182C15.4902 37.2164 15.4721 36.6578 15.6431 36.1437C15.651 36.103 15.6686 36.0649 15.6943 36.0325Z" fill="#E57C13"/>
    <path d="M13.4681 34.4664C14.0898 35.162 14.6096 35.9428 15.012 36.7853C15.2551 37.3206 15.341 37.9143 15.2593 38.4968C15.2593 38.5823 15.2593 38.6593 15.2081 38.7449H15.1655C14.4687 38.3417 13.8826 37.7713 13.4596 37.0848C13.1987 36.6765 13.0624 36.2007 13.0673 35.7157C13.0738 35.2815 13.2008 34.8578 13.434 34.492L13.4681 34.4664Z" fill="#E57C13"/>
    <path d="M13.9123 15.3928C13.665 15.504 13.4347 15.6067 13.1959 15.7008C12.7535 15.8996 12.2938 16.0571 11.8226 16.1715C11.4568 16.2698 11.0746 16.2909 10.7002 16.2334C10.3259 16.176 9.96744 16.0412 9.64762 15.8377L9.46851 15.7179L9.58792 15.5981C10.1387 15.0885 10.8069 14.7239 11.5326 14.537C11.8445 14.4503 12.1717 14.4345 12.4904 14.4908C12.8091 14.547 13.1113 14.6739 13.375 14.8622C13.5857 15.0037 13.768 15.1837 13.9123 15.3928Z" fill="#E57C13"/>
    <path d="M11.7368 32.4299C11.8391 32.6438 11.9244 32.8235 12.0012 33.0118C12.2783 33.602 12.4872 34.2222 12.6238 34.8601C12.7709 35.5216 12.7083 36.2127 12.4447 36.8368C12.4272 36.8848 12.4043 36.9308 12.3765 36.9737C11.8188 36.4487 11.3947 35.7973 11.1397 35.074C11.0146 34.7161 10.9725 34.3342 11.0169 33.9575C11.0612 33.5809 11.1908 33.2193 11.3956 32.9005C11.498 32.7123 11.6174 32.5839 11.7368 32.4299Z" fill="#E57C13"/>
    <path d="M10.1079 34.6891C9.97144 34.3896 9.8435 34.1243 9.72409 33.8334C9.66844 33.6971 9.62283 33.557 9.58762 33.4141C9.50867 33.1337 9.45164 32.8476 9.41703 32.5584C9.33365 32.0939 9.38684 31.6152 9.57011 31.1806C9.75338 30.746 10.0588 30.3744 10.4491 30.111L10.5259 30.0682C10.577 30.325 10.6367 30.5645 10.6879 30.8127C10.8088 31.3981 10.866 31.9948 10.8585 32.5926C10.8479 33.2679 10.6338 33.9242 10.2444 34.4751L10.1079 34.6891Z" fill="#E57C13"/>
    <path d="M11.9328 17.3353C11.7537 17.515 11.5831 17.6947 11.3954 17.8572C11.2078 18.0198 10.7813 18.3878 10.4572 18.6274C9.95172 19.0136 9.32616 19.2077 8.6916 19.175C8.50396 19.175 8.32484 19.1237 8.12866 19.0895V19.0039C8.23101 18.8327 8.32484 18.6616 8.43572 18.4905C8.54261 18.3266 8.6594 18.1694 8.78543 18.0198C9.02799 17.724 9.31938 17.4722 9.6469 17.2754C9.97034 17.0512 10.3541 16.9312 10.7472 16.9312C11.1403 16.9312 11.524 17.0512 11.8475 17.2754L11.9242 17.3353H11.9328Z" fill="#E57C13"/>
    <path d="M10.6451 19.6713C10.5598 19.8168 10.4745 19.9537 10.3806 20.0906C10.0715 20.5959 9.702 21.0614 9.28035 21.4768C8.79446 21.9738 8.13183 22.2569 7.438 22.2641H7.31006V22.2128C7.51451 21.4027 7.92951 20.6615 8.5127 20.0649C8.77577 19.7964 9.11243 19.6124 9.47994 19.5362C9.84745 19.4601 10.2292 19.4952 10.5768 19.6371L10.6451 19.6713Z" fill="#E57C13"/>
    <path d="M9.8006 27.5353C9.8006 27.6295 9.8006 27.7236 9.8006 27.8092C9.85227 28.5714 9.80062 29.3371 9.64707 30.0854C9.50334 30.7514 9.14699 31.3523 8.63207 31.7968L8.51266 31.8823C8.51266 31.7882 8.46148 31.7112 8.44442 31.6256C8.26659 30.9888 8.20306 30.3252 8.25677 29.6661C8.27638 29.2113 8.42701 28.772 8.69043 28.4015C8.95385 28.0309 9.31874 27.7449 9.74089 27.5781L9.8006 27.5353Z" fill="#E57C13"/>
    <path d="M9.57866 24.8654C9.4828 25.6265 9.29667 26.3734 9.02425 27.0902C8.87288 27.4721 8.65318 27.823 8.37601 28.1257C8.15061 28.3763 7.87815 28.5798 7.57425 28.7247H7.51454C7.50995 28.6591 7.50995 28.5934 7.51454 28.5278C7.43875 27.7842 7.52907 27.0329 7.77895 26.3287C7.89528 25.9318 8.12559 25.5781 8.44112 25.3118C8.75665 25.0454 9.1434 24.8783 9.55307 24.8312H9.62983L9.57866 24.8654Z" fill="#E57C13"/>
    <path d="M7.08813 25.5072C7.08813 25.2847 7.13931 25.0794 7.17343 24.874C7.26703 24.3013 7.46356 23.7504 7.75343 23.2482C7.93991 22.8767 8.23752 22.573 8.60459 22.3797C8.97166 22.1863 9.38981 22.113 9.80049 22.17L9.69813 22.4609C9.48778 23.0925 9.20135 23.696 8.84519 24.2579C8.45659 24.8709 7.84431 25.307 7.13931 25.473L7.08813 25.5072Z" fill="#E57C13"/>
    <path d="M25.9043 45.702H24.3519V41.4235V40.7304V39.9602C24.0875 40.2169 23.9084 40.3881 23.8146 40.4651L22.9616 41.1496L22.2622 40.2084L24.6249 38.3258H25.9043V45.702Z" fill="#FEC200"/>
    <path d="M24.9999 37.2048C32.0093 37.2048 37.6916 31.5041 37.6916 24.4719C37.6916 17.4396 32.0093 11.7389 24.9999 11.7389C17.9904 11.7389 12.3081 17.4396 12.3081 24.4719C12.3081 31.5041 17.9904 37.2048 24.9999 37.2048Z" fill="#E83F40"/>
    <path d="M24.9996 19.3376C23.6197 19.3376 22.2963 19.8875 21.3205 20.8664C20.3448 21.8453 19.7966 23.173 19.7966 24.5574H30.2025C30.2025 23.173 29.6543 21.8453 28.6786 20.8664C27.7029 19.8875 26.3795 19.3376 24.9996 19.3376Z" fill="#FEC200"/>
    <path d="M33.6861 13.8769L24.8591 24.4418L25.1533 24.6892L33.9802 14.1243L33.6861 13.8769Z" fill="#FEC200"/>
    <path d="M11.5051 21.9763L11.4382 22.3555L24.9717 24.7418L25.0385 24.3626L11.5051 21.9763Z" fill="#FEC200"/>
    <path d="M12.1591 19.659L12.0278 20.0208L24.94 24.7357L25.0713 24.3739L12.1591 19.659Z" fill="#FEC200"/>
    <path d="M13.1946 17.5026L13.0027 17.8361L24.9026 24.7288L25.0945 24.3953L13.1946 17.5026Z" fill="#FEC200"/>
    <path d="M24.872 24.7029L14.3552 15.8463L14.6026 15.5468L25.1279 24.4119L24.872 24.7029Z" fill="#FEC200"/>
    <path d="M16.318 13.8743L16.0239 14.1218L24.8564 24.6821L25.1504 24.4346L16.318 13.8743Z" fill="#FEC200"/>
    <path d="M18.2992 12.5256L17.9668 12.7181L24.8372 24.6567L25.1696 24.4642L18.2992 12.5256Z" fill="#FEC200"/>
    <path d="M20.4813 11.5448L20.1206 11.6765L24.8203 24.6306L25.1809 24.4989L20.4813 11.5448Z" fill="#FEC200"/>
    <path d="M22.8072 10.9561L22.4292 11.0229L24.8153 24.599L25.1933 24.5321L22.8072 10.9561Z" fill="#FEC200"/>
    <path d="M25.1878 10.7718H24.804V24.5573H25.1878V10.7718Z" fill="#FEC200"/>
    <path d="M27.2025 10.9522L24.8022 24.5257L25.1802 24.593L27.5804 11.0194L27.2025 10.9522Z" fill="#FEC200"/>
    <path d="M29.515 11.5359L24.8289 24.495L25.1897 24.6263L29.8758 11.6673L29.515 11.5359Z" fill="#FEC200"/>
    <path d="M31.7042 12.5246L24.8337 24.4632L25.1661 24.6557L32.0366 12.7171L31.7042 12.5246Z" fill="#FEC200"/>
    <path d="M25.1275 24.7029L24.8716 24.4119L35.3969 15.5468L35.6442 15.8463L25.1275 24.7029Z" fill="#FEC200"/>
    <path d="M36.8055 17.5066L24.9055 24.3993L25.0974 24.7328L36.9974 17.84L36.8055 17.5066Z" fill="#FEC200"/>
    <path d="M37.7951 19.7528L24.8787 24.4399L25.0092 24.8018L37.9256 20.1148L37.7951 19.7528Z" fill="#FEC200"/>
    <path d="M38.504 21.9814L24.9702 24.3658L25.0366 24.7451L38.5704 22.3607L38.504 21.9814Z" fill="#FEC200"/>
    <path d="M24.9995 36.7C21.799 36.6982 18.7273 35.4353 16.446 33.1834C14.1646 30.9314 12.8562 27.8706 12.8025 24.6602H37.1966C37.1429 27.8706 35.8345 30.9314 33.5531 33.1834C31.2718 35.4353 28.2 36.6982 24.9995 36.7Z" fill="#FEC200"/>
    <path d="M37.0011 24.8569C36.884 27.9715 35.5684 30.9194 33.3306 33.0814C31.0928 35.2434 28.107 36.4513 25.0002 36.4513C21.8935 36.4513 18.9076 35.2434 16.6698 33.0814C14.432 30.9194 13.1164 27.9715 12.9994 24.8569H37.0011ZM37.3849 24.4718H12.6497C12.8161 27.6499 14.1917 30.6427 16.4928 32.8332C18.794 35.0237 21.8452 36.2448 25.0173 36.2448C28.1894 36.2448 31.2406 35.0237 33.5418 32.8332C35.8429 30.6427 37.2185 27.6499 37.3849 24.4718Z" fill="#F7931E"/>
    <path d="M22.3551 27.509V28.384H20.2565V32.5061H19.1969V27.509H22.3551ZM25.0553 32.5061H23.9957V28.3909H22.6388V27.509H26.4122V28.3909H25.0553V32.5061ZM31.7169 30.0007C31.7169 30.8279 31.5118 31.4636 31.1017 31.908C30.6915 32.3523 30.1036 32.5745 29.338 32.5745C28.5724 32.5745 27.9845 32.3523 27.5743 31.908C27.1642 31.4636 26.9591 30.8256 26.9591 29.9939C26.9591 29.1622 27.1642 28.5276 27.5743 28.0901C27.9868 27.6503 28.5769 27.4304 29.3449 27.4304C30.1128 27.4304 30.6995 27.6514 31.1051 28.0935C31.513 28.5356 31.7169 29.1713 31.7169 30.0007ZM28.07 30.0007C28.07 30.559 28.1759 30.9794 28.3878 31.262C28.5997 31.5445 28.9165 31.6858 29.338 31.6858C30.1834 31.6858 30.6061 31.1241 30.6061 30.0007C30.6061 28.8751 30.1857 28.3123 29.3449 28.3123C28.9233 28.3123 28.6054 28.4547 28.3912 28.7395C28.177 29.0221 28.07 29.4425 28.07 30.0007Z" fill="#E83F40"/>
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="-4" y="-6" width="58" height="58">
    <path d="M25 -6V49.8623L28.1815 52L30.8 48.9008L34.5103 50.2573L36.1735 47.1409L43.0652 48.8493L43.7646 42.1359L46.7755 41.5178L46.6049 37.6545L50.2726 35.9805L49.0017 32.2975L52.2343 29.8336L50.1361 26.4941L52.8826 23.3777L50.2726 20.9739L53.9999 14.8443L47.8502 12.3117L48.1743 8.68029L44.5067 7.83037L44.0461 3.74393L40.0544 3.80403L38.8091 -0.0763764L35.15 0.842215L32.9238 -2.51451L29.1879 -0.866193L25 -6Z" fill="#CCCCCC"/>
    <path d="M24.9999 -6V49.8623L21.8185 52L19.1999 48.9008L15.4896 50.2573L13.8264 47.1409L6.93467 48.8493L6.23526 42.1359L3.22439 41.5178L3.39498 37.6545L-0.264129 35.9805L0.99822 32.2975L-2.23442 29.8336L-0.136189 26.4941L-2.89118 23.3777L-0.264129 20.9739L-4 14.8443L2.14969 12.3117L1.82557 8.68029L5.49321 7.83037L5.95379 3.74393L9.94555 3.80403L11.1908 -0.0763764L14.8499 0.842215L17.0761 -2.51451L20.812 -0.866193L24.9999 -6Z" fill="#CCCCCC"/>
    </mask>
    <g mask="url(#mask0)">
    <path opacity="0.5" d="M8.73723 29.3725C29.3682 29.3725 46.0929 12.5936 46.0929 -8.10438C46.0929 -28.8023 29.3682 -45.5813 8.73723 -45.5813C-11.8937 -45.5813 -28.6184 -28.8023 -28.6184 -8.10438C-28.6184 12.5936 -11.8937 29.3725 8.73723 29.3725Z" fill="url(#paint0_linear)" fill-opacity="0.6"/>
    </g>
    </g>
    <rect x="-984.5" y="-30.5" width="1768" height="796" rx="4.5" stroke="#7B61FF" strokeDasharray="10 5"/>
    <defs>
    <linearGradient id="paint0_linear" x1="-4.38913" y1="-32.7916" x2="24.6781" y2="21.5089" gradientUnits="userSpaceOnUse">
    <stop stopColor="white" stopOpacity="0"/>
    <stop offset="0.35" stopColor="white" stopOpacity="0.01"/>
    <stop offset="0.49" stopColor="white" stopOpacity="0.03"/>
    <stop offset="0.59" stopColor="white" stopOpacity="0.08"/>
    <stop offset="0.67" stopColor="white" stopOpacity="0.15"/>
    <stop offset="0.74" stopColor="white" stopOpacity="0.24"/>
    <stop offset="0.8" stopColor="white" stopOpacity="0.35"/>
    <stop offset="0.85" stopColor="white" stopOpacity="0.47"/>
    <stop offset="0.9" stopColor="white" stopOpacity="0.62"/>
    <stop offset="0.95" stopColor="white" stopOpacity="0.79"/>
    <stop offset="1" stopColor="white"/>
    </linearGradient>
    <clipPath id="clip0">
    <rect width="50" height="50" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);

export default IconGoldGto;
