import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconEditFilled: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="m16.019 10.679-2.695-2.695 1.948-1.95 2.694 2.695-1.947 1.95Zm3.385-3.341-.001-.001-2.738-2.738c-.741-.74-2.014-.774-2.716-.07l-8.996 8.997a1.973 1.973 0 0 0-.57 1.214l-.38 4.17a1.002 1.002 0 0 0 1.088 1.086l4.17-.38a1.974 1.974 0 0 0 1.214-.567l8.997-8.997c.728-.73.697-1.948-.068-2.714Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconEditFilled;
