import { FC } from 'react';

import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { commonTheme } from 'portfolio3/styles/theme';
import { BaseInputLabel, FormControl, Input, Textarea } from 'portfolio3/ui-kit';
import { Button } from 'portfolio3/ui-kit/button';
import { YearPicker, YearPickerController } from 'portfolio3/ui-kit/datePickers';
import { Select } from 'portfolio3/ui-kit/selects';
import { IController } from 'portfolio3/ui-kit/types';
import { isDefined, mergeSx } from 'utils';

import { IDictionaryItem } from '../../../../api/types';
import { IconInfoOutline, IconTrashAltOutline } from '../../../../icons';
import { Tooltip } from '../../../../ui-kit';
import { IThanksFormData } from '../types';
import { isSubjectRequired } from '../utils';
import * as styles from './styles';

import './index.scss';

interface IThanksFormProps {
  studentClassLevel: number;
  formData: IThanksFormData;
  onChangeFormData: (formData: IThanksFormData) => void;

  teachers: IDictionaryItem[];
  subjects: IDictionaryItem[];
}

const ThanksForm: FC<IThanksFormProps> = ({ studentClassLevel, formData, onChangeFormData, teachers, subjects }) => {
  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));
  const renderMode = 'fixed';
  const inputSize = 'medium';

  const setManualTeacherInput = (value: boolean) => {
    onChangeFormData({
      ...formData,
      isManualTeacherInput: value,
      teacherId: undefined,
      teacherName: undefined,
    });
  };

  const teacherNameController = {
    handleChange(value: string) {
      onChangeFormData({
        ...formData,
        teacherName: value,
      });
    },
  };

  const teacherController: IController<string | undefined> = {
    handleChange(code) {
      const teacherCode = code ? Number(code) : undefined;
      const teacher = teachers.find((teacher) => teacher.code === teacherCode);
      onChangeFormData({
        ...formData,
        teacherId: teacherCode,
        teacherName: teacher?.value,
      });
    },
    handleClear() {
      onChangeFormData({
        ...formData,
        teacherId: undefined,
        teacherName: undefined,
      });
    },
  };

  const subjectController: IController<string | undefined> = {
    handleChange(code) {
      const subjectCode = code ? Number(code) : undefined;
      const subject = subjects.find((subject) => subject.code === subjectCode);
      onChangeFormData({
        ...formData,
        subjectCode: subjectCode,
        subjectName: subject?.value,
      });
    },
    handleClear() {
      onChangeFormData({
        ...formData,
        subjectCode: undefined,
        subjectName: undefined,
      });
    },
  };

  const yearController: YearPickerController = {
    handleChange(value) {
      onChangeFormData({
        ...formData,
        yearEducation: value ?? undefined,
      });
    },
    handleClear() {
      onChangeFormData({
        ...formData,
        yearEducation: undefined,
      });
    },
  };

  const handleTeacherButtonAddClick = () => {
    setManualTeacherInput(true);
  };

  const handleTeacherButtonDeleteClick = () => {
    setManualTeacherInput(false);
  };

  const yearPickerTooltip = !isMobile && (
    <Tooltip
      arrow
      placement="top"
      width="230px"
      title="Укажи год обучения, в котором ты обучался(лась) у данного учителя"
    >
      <Box sx={styles.yearPickerTooltipButton}>
        <IconInfoOutline sx={styles.tooltipIcon} />
      </Box>
    </Tooltip>
  );

  const { yearEducation, gratitudeText, teacherName } = formData;

  const currentDate = new Date();
  const maxYear = currentDate.getFullYear();
  const minYear = new Date(maxYear - studentClassLevel, 0).getFullYear();
  const isYearError = isDefined(yearEducation) && (yearEducation > maxYear || yearEducation < minYear);

  const isSubjectFieldRequired = isSubjectRequired(formData.entityFormData);
  const subjectDisabled = subjects.length === 1;
  const isTeachersLoading = teachers.length === 0;

  const computedGratitudeText = gratitudeText && teacherName ? `${teacherName.trim()}!\n` + gratitudeText : '';

  return (
    <div className="thanks-form">
      <FormControl
        required
        renderMode={renderMode}
        inputSize={inputSize}
        disabled={formData.isManualTeacherInput}
        label={<BaseInputLabel>ФИО учителя</BaseInputLabel>}
        control={
          <Stack className="teacher-control-container" gap={formData.isManualTeacherInput ? '16px' : '8px'}>
            <Select
              loading={isTeachersLoading}
              options={teachers}
              value={formData.teacherId}
              controller={teacherController}
              placeholder={formData.isManualTeacherInput ? 'Недоступно' : 'Выбери учителя...'}
              inputRenderMode={renderMode}
              inputSize={inputSize}
            />
            <FormControl
              label={null}
              control={
                <>
                  {!formData.isManualTeacherInput && (
                    <Typography
                      variant="Paragraph LG/Semi Bold"
                      component="button"
                      onClick={handleTeacherButtonAddClick}
                      sx={styles.teacherMissingButton}
                    >
                      Учителя нет в списке
                    </Typography>
                  )}
                  {formData.isManualTeacherInput && (
                    <Box sx={styles.teacherMissingControlContainer}>
                      <Input
                        placeholder="Введи ФИО учителя..."
                        value={formData.teacherName}
                        renderMode={renderMode}
                        controller={teacherNameController}
                        size={inputSize}
                      />

                      <Button variant="secondary" onlyIcon onClick={handleTeacherButtonDeleteClick}>
                        <IconTrashAltOutline />
                      </Button>
                    </Box>
                  )}
                </>
              }
            />
          </Stack>
        }
      />
      <FormControl
        required={isSubjectFieldRequired}
        renderMode={renderMode}
        inputSize={inputSize}
        disabled={subjectDisabled}
        control={
          <Select
            options={subjects}
            value={formData.subjectCode}
            controller={subjectController}
            placeholder="Выбери предмет..."
            inputRenderMode={renderMode}
            inputSize={inputSize}
          />
        }
        label={<BaseInputLabel>Предмет</BaseInputLabel>}
      />
      <Box sx={styles.yearPickerContainer}>
        <FormControl
          sx={mergeSx(!isMobile && { maxWidth: '220px' })}
          error={isYearError}
          renderMode={renderMode}
          inputSize={inputSize}
          label={<BaseInputLabel>Год обучения</BaseInputLabel>}
          control={
            <YearPicker
              isMobile={isMobile}
              renderMode={renderMode}
              size={inputSize}
              value={formData.yearEducation ?? null}
              controller={yearController}
              minValue={minYear}
              maxValue={maxYear}
            />
          }
        />
        {yearPickerTooltip}
      </Box>
      <FormControl
        renderMode={renderMode}
        inputSize={inputSize}
        disabled
        label={<BaseInputLabel>Слова благодарности</BaseInputLabel>}
        control={
          <Textarea
            renderMode={renderMode}
            size={inputSize}
            value={computedGratitudeText}
            placeholder="Сформируются автоматически после выбора учителя"
            controller={{ handleChange: () => null }}
          />
        }
      />
    </div>
  );
};

export default ThanksForm;
