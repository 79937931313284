import { useMemo } from 'react';

import { DifferentCode, DifferentValue } from 'const';
import { MenuDivider, MenuEmptyResult } from 'portfolio3/ui-kit/menu';

import { ISelectItem, ISelectOptionsListSettings } from '../types';
import { FilterOption, RenderOption } from './types';
import { defaultFilterOption, defaultRenderOption } from './utils';

interface ISelectMenuList<OptionType extends ISelectItem> extends ISelectOptionsListSettings {
  options: (OptionType | ISelectItem)[];
  search?: string;
  shouldShowEmptyListPlaceholder?: boolean;

  // фукнция, которая будет использоваться при сравнении элемента со сторокой поиска
  filterOption?: FilterOption<OptionType>;
  // функция, которая будет использоваться при рендере элемента
  renderOption?: RenderOption<OptionType>;
}

const useSelectMenuList = <OptionType extends ISelectItem>({
  options,
  search = '',
  shouldShowEmptyListPlaceholder = true,
  markFirstOption,
  markOtherOption,
  addOtherOption,

  filterOption = defaultFilterOption,
  renderOption = defaultRenderOption,
}: ISelectMenuList<OptionType>) => {
  const otherOption = useMemo(
    () => options.find((option) => option.value.toLowerCase().trim() === DifferentValue.toLowerCase()),
    [options],
  );

  const optionsWithoutOtherValue = useMemo(() => {
    return markOtherOption && otherOption
      ? options?.filter((option) => option.code !== otherOption.code)
      : [...(options ?? [])];
  }, [markOtherOption, options, otherOption]);

  const topOptions = markFirstOption ? optionsWithoutOtherValue.slice(0, 1) : [];
  const mainOptions = optionsWithoutOtherValue.slice(markFirstOption ? 1 : 0);
  const otherOptions: ISelectItem[] = [];

  // Выделение существующей опции "другое"
  if (markOtherOption && otherOption) {
    otherOptions.push(otherOption);
  }

  // Добавление опции "другое" при условии что ее не было в списке
  if (addOtherOption && !otherOption) {
    otherOptions.push({
      code: DifferentCode,
      value: DifferentValue,
    });
  }

  const filteredTopOptions = topOptions.filter((option) => filterOption(option, search));
  const filteredMainOptions = mainOptions.filter((option) => filterOption(option, search));
  const filteredOtherOptions = otherOptions.filter((option) => filterOption(option, search));

  const renderedTopOptions = filteredTopOptions.map(renderOption);
  const renderedMainOptions = filteredMainOptions.map(renderOption);
  const renderedOtherOptions = filteredOtherOptions.map(renderOption);

  const combinedOptions = [renderedTopOptions, renderedMainOptions, renderedOtherOptions];
  const nonEmptyOptionGroups = combinedOptions.filter((optionGroup) => optionGroup.length > 0);

  const isEmptyList = nonEmptyOptionGroups.length === 0;

  const renderedList = nonEmptyOptionGroups.map((optionGroup, index) => {
    return [index > 0 && <MenuDivider key="divider" />, optionGroup.map((element) => element)];
  });

  return shouldShowEmptyListPlaceholder && isEmptyList ? <MenuEmptyResult key="empty" /> : renderedList;
};

export default useSelectMenuList;
