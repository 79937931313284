import { format } from 'date-fns';
import { IRootState } from 'reducers';
import { createSelector } from 'reselect';
import { isDefined } from 'utils';

import { industryIllustrations } from '../../assets';
import { IndustryCodeIllustration } from '../../const/industryIllustrationMapping';
import { IProforientationTest } from '../types';

const proforientationTestsSelector = createSelector(
  [(state: IRootState) => state.proforientation],
  (proforientation): IProforientationTest[] => {
    const tests = proforientation.content?.proftests?.data
      ?.map((proftest): IProforientationTest | null => {
        const { trial_date, trial_name, trial_inst, industryID } = proftest;

        if (!trial_date) return null;

        const illustrationId = industryID ?? -1;

        return {
          date: format(new Date(trial_date), 'dd.MM.yy'),
          name: trial_name ?? '',
          location: trial_inst ?? '',
          illustration: IndustryCodeIllustration[illustrationId] ?? industryIllustrations.проф_тестирование,
        };
      })
      .filter(isDefined);

    return tests ?? [];
  },
);

export default proforientationTestsSelector;
