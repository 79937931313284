import { Children, cloneElement, FC, isValidElement, PropsWithChildren, useMemo, useRef, useState } from 'react';
import { ReactElement } from 'react';

import { Box, RadioGroup, styled } from '@mui/material';
import Popper from 'components/common/Popper';
import { useHiddenHorizontalList } from 'hooks';
import { NeutralColors } from 'portfolio3/styles';
import { skipPropsForwarding } from 'utils';

import { MenuList } from '../menu';
import { CommonUiKitSize } from '../types';
import { getSwitcherTokens } from '../utils';
import { SwitcherButtonMore } from './buttons';
import { ISwitcherButtonProps, ISwitcherProps, SwitcherVariant } from './types';

interface IStyledRadioGroupProps {
  componentSize?: CommonUiKitSize;
  componentVariant?: SwitcherVariant;
  stretchy?: boolean;
}

const StyledRadioGroup = styled(RadioGroup, {
  shouldForwardProp: skipPropsForwarding<IStyledRadioGroupProps>(['componentSize', 'componentVariant', 'stretchy']),
})<IStyledRadioGroupProps>(({ componentSize = 'medium', componentVariant = 'accent', stretchy }) => {
  const isAccent = componentVariant === 'accent';
  const border = isAccent ? '1px solid' : undefined;
  const borderColor = isAccent ? NeutralColors.light_neutrals_300 : undefined;
  const borderRadius = componentSize === 'large' ? 12 : 6;
  const backgroundColor = isAccent ? '#ffffff' : NeutralColors.light_neutrals_200;

  const width = stretchy ? '100%' : 'max-content';
  const gridTemplateColumns = stretchy
    ? 'repeat(auto-fit, minmax(1px, 1fr))'
    : 'repeat(auto-fill, minmax(min-content, 1fr))';

  const { height, padding } = getSwitcherTokens(componentSize);

  return {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns,
    gap: 4,

    width,
    minHeight: height,
    padding,

    border,
    borderRadius,
    borderColor,
    backgroundColor,
  };
});

/**
 * Переключатель, аналог группе радио-кнопок.
 * Принимает в children компоненты реализующией интерфейс ISwitcherButtonProps.
 *
 * @param {ISwitcherProps} props
 * @param props.defaultValue изначально выбранное значение
 * @param props.onChange коллбэк на изменение значения
 * @param props.componentSize размер компонента
 * @param props.componentVariant вариант компонента
 * @param props.RadioGroupComponent внешний компонент для стилизации
 * @param props.useHiddenList определяет нужно ли скрывать элементы списка в кнопку при нехватке ширины
 * @param props.stretchy позволяет компоненту растягиваться на всю ширину контейнера
 *    (если используется, автоматически отключается скрытие элементов в кнопку)
 */
const Switcher: FC<ISwitcherProps & PropsWithChildren> = ({
  sx,
  value,
  onChange,
  componentSize,
  componentVariant,
  RadioGroupComponent,
  children,
  useHiddenList,
  stretchy,
}) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const rootRef = useRef<HTMLElement>(null);
  const switcherRef = useRef<HTMLElement>(null);
  const buttonMoreRef = useRef<HTMLElement>(null);

  const childrenArray = Children.toArray(children);

  const switcherButtons = useMemo(() => {
    return childrenArray.map((child) => {
      if (isValidElement(child)) {
        return cloneElement<ISwitcherButtonProps>(child as ReactElement<ISwitcherButtonProps>, {
          ...child.props,
          componentSize,
          componentVariant,
          onClick: () => {
            const value = child.props?.value;
            onChange?.(value);
            setMenuOpen(false);
          },
          InputProps: {
            ...child.props?.InputProps,
            checked: value === child.props?.value,
            readOnly: true,
          },
        });
      }
    });
  }, [childrenArray, componentSize, componentVariant, onChange, value]);

  const { visibleElementsCount } = useHiddenHorizontalList({
    containerRef: rootRef,
    elementsContainerRef: switcherRef,
    buttonMoreRef,
    shouldHideButtonMore: true,
    elementsGap: 4,
    extraPaddings: 8,
    allElementsCount: switcherButtons.length,
    hiddenElementClassName: 'visually-hidden',
    additionalDeps: [value, switcherButtons],
    onAllElementsVisible: () => setMenuOpen(false),
    skipProcessing: !useHiddenList || stretchy,
  });

  const Component = RadioGroupComponent || StyledRadioGroup;

  return (
    <Box ref={rootRef} className="switcher-outer">
      <Component
        ref={switcherRef}
        className="switcher-inner"
        sx={sx}
        componentSize={componentSize}
        componentVariant={componentVariant}
        stretchy={stretchy}
      >
        {switcherButtons}

        {useHiddenList && (
          <Box ref={buttonMoreRef} className="visually-hidden">
            <SwitcherButtonMore
              value={-1}
              content="Ещё"
              count={switcherButtons.length - visibleElementsCount}
              onClick={() => setMenuOpen(true)}
              tabIndex={visibleElementsCount === switcherButtons.length ? -1 : 0}
            />
          </Box>
        )}
      </Component>

      {useHiddenList && (
        <Popper
          open={isMenuOpen}
          anchorEl={buttonMoreRef.current}
          onClosePopperMenu={() => setMenuOpen(false)}
          placement="bottom-end"
          style={{ zIndex: 10 }}
        >
          <MenuList autoFocusItem={true}>
            {switcherButtons.slice(visibleElementsCount).map((button) => button)}
          </MenuList>
        </Popper>
      )}
    </Box>
  );
};

export default Switcher;
