import { IBaseRefItem, ProfessionProgrammRefItem, SpoDocument } from 'api/types';
import { DifferentCode, DifferentValue, documentPlaceDictionary } from 'const';

import { IStudySpoDocumentFormData } from '../types';

export const mapSpoDocumentFormData = (
  formData: unknown,
  spoOrganizationRef: IBaseRefItem[],
  professionProgrammRef: ProfessionProgrammRefItem[],
): IStudySpoDocumentFormData => {
  const fd = formData as Partial<SpoDocument>;

  const organizationRef = spoOrganizationRef.find(
    (ref) => ref.value.toLowerCase() === fd.organizationName?.toLowerCase(),
  );
  const docIssueDate = fd.issueDate ? new Date(Date.parse(fd.issueDate)) : undefined;

  const docPlaceRef = documentPlaceDictionary.find((item) => item.value.toLowerCase() === fd.issuePlace?.toLowerCase());
  const profProgrammRef = professionProgrammRef.find(
    (ref) => ref.value.toLowerCase() === fd.professionProgramName?.toLowerCase(),
  );

  return {
    documentTypeCode: fd.document?.code,

    documentName: fd.name,
    organizationCode: fd.id && fd.organizationName ? organizationRef?.code ?? DifferentCode : undefined,
    organizationName: fd.organizationName,
    educationProgramm: fd.educationProgram,
    professionalProgrammCode: fd.id && fd.professionProgramName ? profProgrammRef?.code ?? DifferentCode : undefined,
    professionalProgrammName: profProgrammRef
      ? `${profProgrammRef?.value} ${profProgrammRef?.profCode}`
      : fd.professionProgramName,
    hours: String(fd.hours),
    regNum: fd.regNumber,
    docDate: docIssueDate,
    docPlace: fd.id && fd.issuePlace ? docPlaceRef?.value ?? DifferentValue : undefined,
    docPlaceOther: fd.issuePlace,
    examMarkCode: fd.examMark?.code,
    profession: fd.profession,
    rankCode: fd.rank?.code,
    docNum: fd.docNumber,
    docSeries: fd.docSeries,
    resultDate: docIssueDate,
  };
};
