/* eslint-disable prettier/prettier */
/* eslint-disable */

import { IDragItem, IInterest, ISectionTheme } from '../../../components/redesignStudent/StudentsInterests/types';
import { InterestHeadKindCodes, InterestHeadKindKeys } from '../../../const';
import { IInterestEntity, InterestActionKind, InterestSubinterest } from '../../../types';
import { getInterestCardImage } from './cardImages';
import {
  animalTheme,
  artTheme,
  circusTheme,
  civicTheme,
  collectingTheme,
  cookingTheme,
  cosmosTheme,
  cultureTheme,
  danceTheme,
  fashionTheme,
  gamesTheme,
  historyTheme,
  itTheme,
  languageTheme,
  literatureTheme,
  musicTheme,
  photoTheme,
  scienceTheme,
  socialMediaTheme,
  sportTheme,
  theatreTheme,
  thematicTheme,
  tourismTheme,
  transportTheme,
  videoTheme,
  zozhTheme
} from './themes';

export const InterestSectionThemes: Record<number, ISectionTheme> = {
  [InterestHeadKindCodes[InterestHeadKindKeys.SPORT]]: sportTheme,
  [InterestHeadKindCodes[InterestHeadKindKeys.HEALTHY]]: zozhTheme,
  [InterestHeadKindCodes[InterestHeadKindKeys.TRAVEL]]: tourismTheme,
  [InterestHeadKindCodes[InterestHeadKindKeys.MUSIC]]: musicTheme,
  [InterestHeadKindCodes[InterestHeadKindKeys.LITERATURE]]: literatureTheme,
  [InterestHeadKindCodes[InterestHeadKindKeys.SOCIAL_MEDIA]]: socialMediaTheme,
  [InterestHeadKindCodes[InterestHeadKindKeys.CINEMA]]: videoTheme,
  [InterestHeadKindCodes[InterestHeadKindKeys.PHOTO]]: photoTheme,
  [InterestHeadKindCodes[InterestHeadKindKeys.DANCE]]: danceTheme,
  [InterestHeadKindCodes[InterestHeadKindKeys.THEATRICAL]]: theatreTheme,
  [InterestHeadKindCodes[InterestHeadKindKeys.DESIGN]]: artTheme,
  [InterestHeadKindCodes[InterestHeadKindKeys.CULTURAL_LEISURE]]: cultureTheme,
  [InterestHeadKindCodes[InterestHeadKindKeys.FASHION]]: fashionTheme,
  [InterestHeadKindCodes[InterestHeadKindKeys.COLLECTION]]: collectingTheme,
  [InterestHeadKindCodes[InterestHeadKindKeys.TRANSPORT]]: transportTheme,
  [InterestHeadKindCodes[InterestHeadKindKeys.CHARACTER]]: thematicTheme,
  [InterestHeadKindCodes[InterestHeadKindKeys.HISTORY]]: historyTheme,
  [InterestHeadKindCodes[InterestHeadKindKeys.SPACE]]: cosmosTheme,
  [InterestHeadKindCodes[InterestHeadKindKeys.LANGUAGE]]: languageTheme,
  [InterestHeadKindCodes[InterestHeadKindKeys.INFORMATION]]: itTheme,
  [InterestHeadKindCodes[InterestHeadKindKeys.SCIENCE]]: scienceTheme,
  [InterestHeadKindCodes[InterestHeadKindKeys.GAME]]: gamesTheme,
  [InterestHeadKindCodes[InterestHeadKindKeys.ANIMAL]]: animalTheme,
  [InterestHeadKindCodes[InterestHeadKindKeys.COOKERY]]: cookingTheme,
  [InterestHeadKindCodes[InterestHeadKindKeys.ACTIVITY]]: civicTheme,
  // [InterestHeadKindCodes[InterestHeadKindKeys.THEATRE]]: theatreTheme,
};

export const getMainPortfolioPath = (currentPathName: string) => {
  const newPath = currentPathName.split('/');
  newPath.pop();
  return newPath.join('/');
};

export const calculateNewStep = ((currentStep: number, step: number) => currentStep + step);

export const mapDragElementIntoInterestSection = (dragElement: IDragItem) => ({
  id: dragElement.code,
  name: dragElement.value,
  theme: dragElement.theme
});

export const mapSelectedInterestIntoInterestEntity = (interest: IInterest): IInterestEntity => {
  const { desktop, mobile } = getInterestCardImage(interest.headId, interest.groupId, interest.name);

  return ({
    id: interest.id,
    code: interest.code,
    headCode: interest.headId,
    image: desktop,
    mobileImage: mobile,
    name: interest.name,
    overlayColor: InterestSectionThemes[interest.headId].mainColor,
    actionKinds: interest.interestActions
      .filter((action) => action.isActive)
      .map((action): InterestActionKind => ({
        code: action.code,
        value: action.name
      })),
    subinterests: interest.children
      ?.filter((child) => child.isActive)
      ?.map((child): InterestSubinterest => ({
        code: child.code,
        value: child.name
      })),
    editFormData: {
      actions: interest.interestActions,
      categories: interest.children || []
    }
  });
};

export const InterestChapters: Record<string, number> = {
  FIRST: 1,
  SECOND: 2,
  THIRD: 3,
  FOURTH: 4,
  FIFTH: 5
};
