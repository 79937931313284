import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root: SxStyles = {
  position: 'relative',

  border: '1px solid',
  borderColor: NeutralColors.light_neutrals_300,
  borderRadius: '8px',
  backgroundColor: NeutralColors.light_neutrals_0,

  boxShadow: '0px 1px 2px 0px rgba(41, 41, 64, 0.07)',

  overflow: 'hidden',
};

export const header: SxStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  gap: '10px',

  paddingBlock: '20px 12px',
  paddingInline: '20px',
};

export const filtersContent: SxStyles = {
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',

  paddingBlock: '16px',
  paddingInline: '20px',
};

export const footer: SxStyles = {
  display: 'flex',
  justifyContent: 'space-between',

  borderTop: '1px solid',
  borderColor: NeutralColors.light_neutrals_300,

  paddingBlock: '16px',
  paddingInline: '20px',
};

export const footerRightButtons: SxStyles = {
  display: 'flex',
  gap: '16px',
  marginLeft: 'auto',
};

export const openButton: SxStyles = {
  width: '24px',
  height: '24px',
  borderRadius: '50%',

  svg: {
    width: 'inherit',
    height: 'inherit',
    color: NeutralColors.light_neutrals_600,
  },
};
