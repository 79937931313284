import { FC, useMemo } from 'react';

import { useAppSelector, useDataLoad } from 'hooks';
import PdfNoDataText from 'portfolio3/features/pdf/components/PdfNoDataText';
import { getDataLoadRequests } from 'utils';

import { PdfCommonDataEntity } from '../../../../components/pdfStudent/DataEntity';
import { widgetPdfIcons } from '../../../../icons';
import { studySpoTrainingSelector } from '../../../../selectors';
import PdfCommonDataEntityContainer from '../common';

import '../index.scss';

const PdfTrainingContainer: FC = () => {
  const training = useAppSelector(studySpoTrainingSelector);
  useDataLoad({ shouldLoad: true, requests: getDataLoadRequests().studySpoEducation });

  const hasData = training.content.length > 0;

  const filteredYear = useMemo(
    () =>
      training.content.length > 0
        ? training.content?.reduce((prevItem, nextItem) => (prevItem.year > nextItem.year ? prevItem : nextItem)).year
        : undefined,
    [training],
  );

  return (
    <PdfCommonDataEntityContainer title="Сведения об обучении" icon={widgetPdfIcons.pdfIconStudyEducation}>
      {hasData &&
        training.content.map((entity) => (
          <PdfCommonDataEntity key={entity.id || entity.recordId} entity={entity} year={filteredYear} />
        ))}
      {!hasData && <PdfNoDataText />}
    </PdfCommonDataEntityContainer>
  );
};

export default PdfTrainingContainer;
