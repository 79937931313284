import React, { Children } from 'react';

import { MenuProps } from '@mui/material';
import { BaseMenu } from 'portfolio3/ui-kit';

import './index.scss';

interface IEntityActionsPopperProps extends MenuProps {
  title?: string;
  onClickAway: () => void;
}

const EntityActionsPopper: React.FC<IEntityActionsPopperProps> = ({ children, title, onClickAway, ...menuProps }) => {
  const childrenArray = Children.toArray(children);
  const childrenLength = childrenArray.filter(Boolean).length;

  if (childrenLength === 0) {
    return null;
  }

  return (
    <BaseMenu
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={onClickAway}
      {...menuProps}
    >
      {title && (
        <p className="entity-actions-container__title" aria-disabled>
          {title}
        </p>
      )}
      {children}
    </BaseMenu>
  );
};

export default EntityActionsPopper;
