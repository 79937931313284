import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { IGiaItem } from 'api/types';
import ResultDoughnut from 'components/common/ResultDoughnut';
import { useGiaExamCardProperties } from 'hooks';

import BaseCard from '../BaseCard';
import * as styles from './styles';

interface IExamGiaCardProps {
  exam: IGiaItem;
  onView: () => void;
}

const ExamGiaCard: FC<IExamGiaCardProps> = ({ exam, onView }) => {
  const { name, formaGia } = exam;

  const { doughnutTotal, doughnutData, doughnutColor, resultDate, isCreditExam, creditExamTitle } =
    useGiaExamCardProperties(exam);

  const creditRightElement = <Typography variant="Headings/H5">{creditExamTitle}</Typography>;

  const doughnutElement = (
    <Box sx={styles.chart}>
      <ResultDoughnut total={doughnutTotal ?? '-'} data={doughnutData} color={doughnutColor} />
    </Box>
  );

  return (
    <BaseCard
      title={name ?? undefined}
      place={formaGia}
      dateElement={resultDate}
      rightElement={isCreditExam ? creditRightElement : doughnutElement}
      onView={onView}
    />
  );
};

export default ExamGiaCard;
