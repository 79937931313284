import { FC } from 'react';

import { noMark } from 'images';
import Advert from 'portfolio3/components/common/Advert';
import { SxStyles } from 'types';

const DiagnosticsResultBanner: FC = () => {
  const contentSx: SxStyles = {
    backgroundImage: `url(${noMark})`,
    backgroundPosition: 'right 0 top 11px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '201px 180px',
  };

  return (
    <Advert
      title="Результаты диагностик"
      prompt="На сайте можно посмотреть подробную информацию о всех пройденных диагностиках и скачать результаты"
      linkText="Перейти на сайт МЦКО"
      href="https://okmcko.mos.ru/"
      contentSx={contentSx}
    />
  );
};

export default DiagnosticsResultBanner;
