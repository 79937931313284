import { FC } from 'react';

import { SvgIcon } from '@mui/material';

interface IExclamationMarkIndicatorProps {
  color: string;
}

const ExclamationMarkIndicator: FC<IExclamationMarkIndicatorProps> = ({ color }) => {
  return (
    <SvgIcon width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M13.2 13.2a1.2 1.2 0 0 1-2.4 0v-6a1.2 1.2 0 0 1 2.4 0v6ZM12 18a1.2 1.2 0 1 1 0-2.401 1.2 1.2 0 0 1 0 2.4Zm0-18C5.372 0 0 5.372 0 12s5.372 12 12 12 12-5.372 12-12S18.628 0 12 0Z"
        fill={color || 'currentColor'}
      />
    </SvgIcon>
  );
};

export default ExclamationMarkIndicator;
