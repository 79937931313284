import { FC, PropsWithChildren } from 'react';

import { Box } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

import MetaskillsContainerBody from './MetaskillsContainerBody';
import MetaskillsContainerHeader from './MetaskillsContainerHeader';
import { IMetaskillsContainerRootComposition } from './types';

interface IMetaskillsContainerRoot {
  containerChecked?: boolean;
}

const metaskillsContainer = (containerChecked?: boolean): SxStyles => ({
  border: containerChecked ? '1px solid transparent' : `1px solid ${NeutralColors.light_neutrals_300}`,
  borderRadius: '12px',
  boxShadow: `0px 1px 2px rgba(41, 41, 64, 0.07)`,
});

const MetaskillsContainerRoot: FC<IMetaskillsContainerRoot & PropsWithChildren> &
  IMetaskillsContainerRootComposition = ({ containerChecked, children }) => {
  return (
    <Box sx={metaskillsContainer(containerChecked)} className="metaskills-container">
      {children}
    </Box>
  );
};

MetaskillsContainerRoot.Header = MetaskillsContainerHeader;
MetaskillsContainerRoot.Body = MetaskillsContainerBody;

export default MetaskillsContainerRoot;
