/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import { MenuItem, Select as SelectMaterial, SelectProps } from '@mui/material';

import { Colors } from '../../../style/variables';
import { IconSelectArrow } from '../../../icons';

const useStyles = makeStyles({
  select: ({ value, error }: SelectProps) => ({
    boxSizing: 'border-box',
    border: '1px solid',
    fontSize: '14px',
    borderColor: error ? Colors['red-rose'] : Colors['control-stroke'],
    color: value === 'placeholder' ? Colors['disable-text'] : undefined,
    borderRadius: '8px',
    padding: '8px 16px',
    outline: 'none',
    minHeight: '40px',
    '&.MuiSelect-select:focus': {
      borderRadius: '8px',
      backgroundColor: 'white'

    },
    '&[aria-expanded="true"]': {
      borderColor: Colors['blue-primrose']
    }
  })
});

const Select: React.FC<SelectProps> = (props) => {
  const classes = useStyles(props);
  const { children, placeholder } = props;

  return (
    <SelectMaterial
      disableUnderline
      IconComponent={IconSelectArrow}
      classes={{
        select: classes.select
      }}
      {...props}
    >
      <MenuItem value="placeholder" key="placeholder" style={{ display: 'none' }}>{placeholder}</MenuItem>
      {children}
    </SelectMaterial>
  );
};

export default Select;
