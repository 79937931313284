import { SxStyles } from 'types';

export const addType: SxStyles = {
  display: 'flex',
  gap: '4px',

  svg: {
    width: '20px',
    height: '20px',
  },
};

export const entityContainer: SxStyles = {
  display: 'flex',
  gap: '8px',
};
