import { FC } from 'react';

import { useDetailViewDrawer } from 'hooks';
import { IGiaWorldSkillsEntity } from 'types';

import { GiaWorldskillsCard } from '../../components/cards';
import SearchSection from '../../components/SearchSection';

interface IGiaWorldskillsCardListProps {
  giaWorldskills: IGiaWorldSkillsEntity[];
}

const GiaWorldskillsCardList: FC<IGiaWorldskillsCardListProps> = ({ giaWorldskills }) => {
  const { handleOpenPrimaryDetailView } = useDetailViewDrawer();

  const cards = giaWorldskills.map((giaWorldskillEntity, index, array) => {
    const handleView = () => {
      handleOpenPrimaryDetailView(giaWorldskillEntity.cardData, giaWorldskillEntity.entityProps.type);
    };

    const card = <GiaWorldskillsCard entity={giaWorldskillEntity} onView={handleView} />;

    return (
      <SearchSection.CardContainer
        key={giaWorldskillEntity.id}
        card={card}
        mapIndex={index}
        totalCards={array.length}
        onView={handleView}
      />
    );
  });

  return cards;
};

export default GiaWorldskillsCardList;
