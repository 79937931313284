import { sectionRoutes, StudentSectionSettingTypeCodes } from 'const';
import {
  IconLikeOutline,
  IconPersonOutline,
  IconSectionCivil,
  IconSectionCreation,
  IconSectionCulture,
  IconSectionProfession,
  IconSectionScience,
  IconSectionSport,
  IconSectionStudy,
} from 'icons';

import { ISectionNavigationLink } from './types';

export const studentPrimaryNavigation: ISectionNavigationLink[] = [
  {
    name: 'Учёба',
    to: sectionRoutes.STUDY,
    icon: IconSectionStudy,
    sectionTypeCode: StudentSectionSettingTypeCodes.study,
  },
  {
    name: 'Наука',
    to: sectionRoutes.SCIENCE,
    icon: IconSectionScience,
    sectionTypeCode: StudentSectionSettingTypeCodes.science,
  },
  {
    name: 'Спорт',
    to: sectionRoutes.SPORT,
    icon: IconSectionSport,
    sectionTypeCode: StudentSectionSettingTypeCodes.sport,
  },
  {
    name: 'Творчество',
    to: sectionRoutes.CREATION,
    icon: IconSectionCreation,
    sectionTypeCode: StudentSectionSettingTypeCodes.creation,
  },
  {
    name: 'Культура',
    to: sectionRoutes.CULTURE,
    icon: IconSectionCulture,
    sectionTypeCode: StudentSectionSettingTypeCodes.culture,
  },
  {
    name: 'Гражданская активность',
    to: sectionRoutes.CIVIL,
    icon: IconSectionCivil,
    sectionTypeCode: StudentSectionSettingTypeCodes.civil,
  },
  {
    name: 'Моя профессия',
    to: sectionRoutes.PROFESSION,
    icon: IconSectionProfession,
    sectionTypeCode: StudentSectionSettingTypeCodes.profession,
  },
];

export const studentSecondaryNavigation: ISectionNavigationLink[] = [
  {
    name: 'Обо мне',
    to: sectionRoutes.PROFILE,
    icon: IconPersonOutline,
    sectionTypeCode: StudentSectionSettingTypeCodes.profile,
  },
  {
    name: 'Рекомендации',
    to: sectionRoutes.RECOMMENDATION,
    icon: IconLikeOutline,
    sectionTypeCode: StudentSectionSettingTypeCodes.recommendation,
  },
];
