import { FC } from 'react';

import { Box } from '@mui/material';
import { IMappedMetaskillsCategory, SxStyles } from 'types';

import MetaskillsTagsBlock from './TagsBlock';

const rootStyles: SxStyles = {
  '& > *:not(:first-child)': {
    marginTop: '16px',
  },
};

interface IMetaskillsProps {
  metaskills: IMappedMetaskillsCategory[];
}

const Metaskills: FC<IMetaskillsProps> = ({ metaskills }) => {
  return (
    <Box className="metaskills" sx={rootStyles}>
      {metaskills.map((metaskillCategory) => {
        const { code, iconElement, name, childMetaskills } = metaskillCategory;
        const tags = childMetaskills.map((skill) => skill.name);

        return <MetaskillsTagsBlock key={code} icon={iconElement} title={name} tags={tags} />;
      })}
    </Box>
  );
};

export default Metaskills;
