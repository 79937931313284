import { ICommonResponse, ILearningYearPerformance } from 'api/types';
import { IRootState } from 'reducers';
import { createSelector } from 'reselect';

import { IMappedLearningYearPerformance } from '../../types';
import { learningYearsSortPredicate } from '../../utils';

type AttestationPeriod = ILearningYearPerformance['subjects'][number]['attestationPeriods'][number];

export const learningYearFinalPerformanceSelector = createSelector(
  [(state: IRootState) => state.studentFinalGrades],
  (finalGrades): ICommonResponse<IMappedLearningYearPerformance> => {
    return {
      ...finalGrades,
      content: mapLearningYearPerformance(finalGrades.content),
    };
  },
);

export const learningYearAveragePerformanceSelector = createSelector(
  [(state: IRootState) => state.studentAverageGrades],
  (averageGrades) => {
    return {
      ...averageGrades,
      content: mapLearningYearPerformance(averageGrades.content),
    };
  },
);

function mapLearningYearPerformance(performance: ILearningYearPerformance[]): IMappedLearningYearPerformance[] {
  const sortedPerformanceDesc = [...performance].sort((a, b) => learningYearsSortPredicate(a.year, b.year, 'desc'));

  const mappedLearningYearPerformance = sortedPerformanceDesc.map(
    (performance, index): IMappedLearningYearPerformance => {
      return {
        year: performance.year,
        averageGrade: performance.averageAllSubjects,
        educationLevel: performance.educationLevel,
        subjects: performance.subjects.map((subject) => {
          const { id, name } = subject;

          const prevYearPerformance = sortedPerformanceDesc[index + 1] as typeof performance | undefined;
          const prevYearSubject = prevYearPerformance?.subjects.find(
            (prevSubject) => prevSubject.name.toLowerCase() === name.toLowerCase(),
          );

          const currentYearGrade = getSubjectGrade(subject);
          const prevYearGrade = prevYearSubject ? getSubjectGrade(prevYearSubject) : null;
          const gradeDifference = prevYearGrade ? currentYearGrade - prevYearGrade : null;

          return {
            id,
            name,
            grade: currentYearGrade,
            gradeDifference,
            year: performance.year,
          };
        }),
      };
    },
  );

  return mappedLearningYearPerformance;
}

function getSubjectGrade(subject: ILearningYearPerformance['subjects'][number]): number {
  const { yearFivePointValue, attestationPeriods } = subject;

  // если нет yearFivePointValue - будем брать оценку последнего аттестационного периода за этого год
  // (максимальный ИД аттестац периода)
  const latestAttestationPeriod = attestationPeriods.reduce<AttestationPeriod | null>((prevPeriod, currentPeriod) => {
    if (!prevPeriod) return currentPeriod;
    return currentPeriod.id > prevPeriod.id ? currentPeriod : prevPeriod;
  }, null);

  const subjectGrade = yearFivePointValue ?? latestAttestationPeriod?.fivePointValue ?? 0;
  return subjectGrade;
}
