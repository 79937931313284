/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconDelete: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
       <path fillRule="evenodd" clipRule="evenodd" d="M8.83268 13.3334C8.83268 13.7917 8.45768 14.1667 7.99935 14.1667C7.54102 14.1667 7.16602 13.7917 7.16602 13.3334V10.0001C7.16602 9.54175 7.54102 9.16675 7.99935 9.16675C8.45768 9.16675 8.83268 9.54175 8.83268 10.0001V13.3334ZM13.8327 13.3334C13.8327 13.7917 13.4577 14.1667 12.9993 14.1667C12.541 14.1667 12.166 13.7917 12.166 13.3334V10.0001C12.166 9.54175 12.541 9.16675 12.9993 9.16675C13.4577 9.16675 13.8327 9.54175 13.8327 10.0001V13.3334ZM15.4993 15.8334C15.4993 16.2926 15.126 16.6667 14.666 16.6667H6.33268C5.87268 16.6667 5.49935 16.2926 5.49935 15.8334V6.66675H15.4993V15.8334ZM8.83268 3.60675C8.83268 3.47758 9.01101 3.33341 9.24935 3.33341H11.7493C11.9877 3.33341 12.166 3.47758 12.166 3.60675V5.00008H8.83268V3.60675ZM17.9993 5.00008H17.166H13.8327V3.60675C13.8327 2.53675 12.8985 1.66675 11.7493 1.66675H9.24935C8.10018 1.66675 7.16602 2.53675 7.16602 3.60675V5.00008H3.83268H2.99935C2.54102 5.00008 2.16602 5.37508 2.16602 5.83342C2.16602 6.29175 2.54102 6.66675 2.99935 6.66675H3.83268V15.8334C3.83268 17.2117 4.95435 18.3334 6.33268 18.3334H14.666C16.0443 18.3334 17.166 17.2117 17.166 15.8334V6.66675H17.9993C18.4577 6.66675 18.8327 6.29175 18.8327 5.83342C18.8327 5.37508 18.4577 5.00008 17.9993 5.00008Z" fill="#E47B83"/>
  </SvgIcon>
);

export default IconDelete;
