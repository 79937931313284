export const CONTAINER_MAX_WIDTH = 1280;
export const SCROLL_WIDTH = 14;

// color
export enum ColorKeys {
  HEADING = 'heading',
  MAIN_TEXT = 'main-text',
  NOTE_TEXT = 'note-text',
  DISABLE_TEXT = 'disable-text',
  CONTROL_STROKE = 'control-stroke',
  DELIMITERS = 'delimiters',
  PAGE_BACKGROUND = 'page-background',
  DISABLE_INPUT = 'disable-input',
  WHITE = 'white',
  NAVIGATION_SELECT = 'navigation-select',
  TOOLTIP = 'tooltip',

  AVATAR_RED = 'avatar-red',
  AVATAR_ORANGE = 'avatar-orange',
  AVATAR_YELLOW = 'avatar-yellow',
  AVATAR_GREEN = 'avatar-green',
  AVATAR_LIGHT_BLUE = 'avatar-light-blue',
  AVATAR_BLUE = 'avatar-blue',
  AVATAR_PURPLE = 'avatar-purple',

  BLUE_PRIMROSE_D8 = 'blue-primrose_d8',
  BLUE_PRIMROSE_D6 = 'blue-primrose_d6',
  BLUE_PRIMROSE = 'blue-primrose',
  BLUE_PRIMROSE_L4 = 'blue-primrose_l4',
  BLUE_PRIMROSE_L2 = 'blue-primrose_l2',

  AMETHYST_STARLING_D8 = 'amethyst-starling_d8',
  AMETHYST_STARLING_D6 = 'amethyst-starling_d6',
  AMETHYST_STARLING = 'amethyst-starling',
  AMETHYST_STARLING_L4 = 'amethyst-starling_l4',
  AMETHYST_STARLING_L2 = 'amethyst-starling_l2',

  RED_ROSE_D8 = 'red-rose_d8',
  RED_ROSE_D6 = 'red-rose_d6',
  RED_ROSE = 'red-rose',
  RED_ROSE_L4 = 'red-rose_l4',
  RED_ROSE_L2 = 'red-rose_l2',

  ORANGE_SUNSET_D8 = 'orange-sunset_d8',
  ORANGE_SUNSET_D6 = 'orange-sunset_d6',
  ORANGE_SUNSET = 'orange-sunset',
  ORANGE_SUNSET_L4 = 'orange-sunset_l4',
  ORANGE_SUNSET_L2 = 'orange-sunset_l2',

  FIT_GREEN_D8 = 'fir-green_d8',
  FIT_GREEN_D6 = 'fir-green_d6',
  FIT_GREEN = 'fir-green',
  FIT_GREEN_L4 = 'fir-green_l4',
  FIT_GREEN_L2 = 'fir-green_l2',

  SUNGLOW_D8 = 'sunglow_d8',
  SUNGLOW_D6 = 'sunglow_d6',
  SUNGLOW = 'sunglow',
  SUNGLOW_L4 = 'sunglow_l4',
  SUNGLOW_L2 = 'sunglow_l2',

  MUNSELL_BLUE_D8 = 'munsell-blue_d8',
  MUNSELL_BLUE_D6 = 'munsell-blue_d6',
  MUNSELL_BLUE = 'munsell-blue',
  MUNSELL_BLUE_L4 = 'munsell-blue_l4',
  MUNSELL_BLUE_L2 = 'munsell-blue_l2',

  NOTIFICATION_SUCCESS = 'notification-success',

  CYAN_LITE = 'cyan-lite',
  CYAN_STRONG = 'cyan-strong',
  CYAN_MEDIUM = 'cyan-medium',
  CYAN_ULTRA = 'cyan-ultra',
  ORANGE_LITE = 'orange-lite',
  ORANGE_STRONG = 'orange-strong',
  ORANGE_MEDIUM = 'orange-medium',
  ORANGE_ULTRA = 'orange-ultra',
  RED_LITE = 'red-lite',
  RED_STRONG = 'red-strong',
  RED_MEDIUM = 'red-medium',
  RED_ULTRA = 'red-ultra',
  VIOLET_LITE = 'violet-lite',
  VIOLET_STRONG = 'violet-strong',
  VIOLET_MEDIUM = 'violet-medium',
  VIOLET_ULTRA = 'violet-ultra',
  BLUE_LITE = 'blue-lite',
  BLUE_STRONG = 'blue-strong',
  BLUE_MEDIUM = 'blue-medium',
  BLUE_ULTRA = 'blue-ultra',
  GREEN_LITE = 'green-lite',
  GREEN_STRONG = 'green-strong',
  GREEN_MEDIUM = 'green-medium',
  GREEN_ULTRA = 'green-ultra',
  TEAL_LITE = 'teal-lite',
  TEAL_STRONG = 'teal-strong',
  TEAL_MEDIUM = 'teal-medium',
  TEAL_ULTRA = 'teal-ultra',
  PINK_STRONG = 'pink-strong',
  PINK_MEDIUM = 'pink-medium',
  PINK_LITE = 'pink-lite',
  PINK_ULTRA = 'pink-ultra',
  LIME_STRONG = 'lime-strong',
  LIME_MEDIUM = 'lime-medium',
  LIME_LITE = 'lime-lite',
  LIME_ULTRA = 'lime-ultra',
  GRAPE_STRONG = 'grape-strong',
  GRAPE_MEDIUM = 'grape-medium',
  GRAPE_LITE = 'grape-lite',
  GRAPE_ULTRA = 'grape-ultra',

  BLUE_01 = 'blue_01',
  BLUE_02 = 'blue_02',
  BLUE_03 = 'blue_03',
  BLUE_04 = 'blue_04',
  BLUE_05 = 'blue_05',
  BLUE_06 = 'blue_06',
  BLUE_07 = 'blue_07',
  BLUE_08 = 'blue_08',
  BLUE_09 = 'blue_09',
  BLUE_10 = 'blue_10',
  BLUE_11 = 'blue_11',

  VIOLET_01 = 'violet_01',
  VIOLET_02 = 'violet_02',
  VIOLET_03 = 'violet_03',
  VIOLET_04 = 'violet_04',
  VIOLET_05 = 'violet_05',
  VIOLET_06 = 'violet_06',
  VIOLET_07 = 'violet_07',
  VIOLET_08 = 'violet_08',
  VIOLET_09 = 'violet_09',
  VIOLET_10 = 'violet_10',
  VIOLET_11 = 'violet_11',

  INDIGO_01 = 'indigo_01',
  INDIGO_02 = 'indigo_02',
  INDIGO_03 = 'indigo_03',
  INDIGO_04 = 'indigo_04',
  INDIGO_05 = 'indigo_05',
  INDIGO_06 = 'indigo_06',
  INDIGO_07 = 'indigo_07',
  INDIGO_08 = 'indigo_08',
  INDIGO_09 = 'indigo_09',
  INDIGO_10 = 'indigo_10',
  INDIGO_11 = 'indigo_11',

  CYAN_01 = 'cyan_01',
  CYAN_02 = 'cyan_02',
  CYAN_03 = 'cyan_03',
  CYAN_04 = 'cyan_04',
  CYAN_05 = 'cyan_05',
  CYAN_06 = 'cyan_06',
  CYAN_07 = 'cyan_07',
  CYAN_08 = 'cyan_08',
  CYAN_09 = 'cyan_09',
  CYAN_10 = 'cyan_10',
  CYAN_11 = 'cyan_11',

  ORANGE_01 = 'orange_01',
  ORANGE_02 = 'orange_02',
  ORANGE_03 = 'orange_03',
  ORANGE_04 = 'orange_04',
  ORANGE_05 = 'orange_05',
  ORANGE_06 = 'orange_06',
  ORANGE_07 = 'orange_07',
  ORANGE_08 = 'orange_08',
  ORANGE_09 = 'orange_09',
  ORANGE_10 = 'orange_10',
  ORANGE_11 = 'orange_11',

  RED_01 = 'red_01',
  RED_02 = 'red_02',
  RED_03 = 'red_03',
  RED_04 = 'red_04',
  RED_05 = 'red_05',
  RED_06 = 'red_06',
  RED_07 = 'red_07',
  RED_08 = 'red_08',
  RED_09 = 'red_09',
  RED_10 = 'red_10',
  RED_11 = 'red_11',

  GREEN_01 = 'green_01',
  GREEN_02 = 'green_02',
  GREEN_03 = 'green_03',
  GREEN_04 = 'green_04',
  GREEN_05 = 'green_05',
  GREEN_06 = 'green_06',
  GREEN_07 = 'green_07',
  GREEN_08 = 'green_08',
  GREEN_09 = 'green_09',
  GREEN_10 = 'green_10',
  GREEN_11 = 'green_11',

  GRAPE_01 = 'grape_01',
  GRAPE_02 = 'grape_02',
  GRAPE_03 = 'grape_03',
  GRAPE_04 = 'grape_04',
  GRAPE_05 = 'grape_05',
  GRAPE_06 = 'grape_06',
  GRAPE_07 = 'grape_07',
  GRAPE_08 = 'grape_08',
  GRAPE_09 = 'grape_09',
  GRAPE_10 = 'grape_10',
  GRAPE_11 = 'grape_11',
}

// shadow
export enum ShadowKeys {
  EMPIRE = 'empire',
  TAIPEI = 'taipei',
  TOKYO = 'tokyo',
  KHALIFA = 'khalifa',
}

/**
 * @deprecated use getAccentColor
 * @xample getAccentColor('indigo', '200')
 */
export const Colors: Record<ColorKeys, string> = {
  [ColorKeys.HEADING]: '#0c0d10',
  [ColorKeys.MAIN_TEXT]: '#181920',
  [ColorKeys.NOTE_TEXT]: '#7B819B',
  [ColorKeys.DISABLE_TEXT]: '#B0B3C3',
  [ColorKeys.CONTROL_STROKE]: '#D7D9E1',
  [ColorKeys.DELIMITERS]: '#E5E6EB',
  [ColorKeys.PAGE_BACKGROUND]: '#F4F3F8',
  [ColorKeys.DISABLE_INPUT]: '#E8E8EF',
  [ColorKeys.WHITE]: '#fff',
  [ColorKeys.NAVIGATION_SELECT]: '#F4F9FF',
  [ColorKeys.TOOLTIP]: 'rgba(43, 56, 88, 0.8)',

  [ColorKeys.AVATAR_RED]: '#FF6B6B',
  [ColorKeys.AVATAR_ORANGE]: '#FFAB5A',
  [ColorKeys.AVATAR_YELLOW]: '#FFC442',
  [ColorKeys.AVATAR_GREEN]: '#80E17E',
  [ColorKeys.AVATAR_LIGHT_BLUE]: '#2BD7D8',
  [ColorKeys.AVATAR_BLUE]: '#69ACFF',
  [ColorKeys.AVATAR_PURPLE]: '#D98CFF',

  [ColorKeys.BLUE_PRIMROSE_D8]: '#2b3858',
  [ColorKeys.BLUE_PRIMROSE_D6]: '#0044a2',
  [ColorKeys.BLUE_PRIMROSE]: '#0055cb',
  [ColorKeys.BLUE_PRIMROSE_L4]: '#4794ff',
  [ColorKeys.BLUE_PRIMROSE_L2]: '#e0edff',

  [ColorKeys.AMETHYST_STARLING_D8]: '#3d114e',
  [ColorKeys.AMETHYST_STARLING_D6]: '#621b7c',
  [ColorKeys.AMETHYST_STARLING]: '#7a229b',
  [ColorKeys.AMETHYST_STARLING_L4]: '#bb61dc',
  [ColorKeys.AMETHYST_STARLING_L2]: '#f4e5f9',

  [ColorKeys.RED_ROSE_D8]: '#66151a',
  [ColorKeys.RED_ROSE_D6]: '#a3212a',
  [ColorKeys.RED_ROSE]: '#cc2a35',
  [ColorKeys.RED_ROSE_L4]: '#e47b83',
  [ColorKeys.RED_ROSE_L2]: '#fbe9ea',

  [ColorKeys.ORANGE_SUNSET_D8]: '#99540c',
  [ColorKeys.ORANGE_SUNSET_D6]: '#ed861a',
  [ColorKeys.ORANGE_SUNSET]: '#f2a657',
  [ColorKeys.ORANGE_SUNSET_L4]: '#f7ca9a',
  [ColorKeys.ORANGE_SUNSET_L2]: '#fef6ee',

  [ColorKeys.FIT_GREEN_D8]: '#1f581d',
  [ColorKeys.FIT_GREEN_D6]: '#318c2e',
  [ColorKeys.FIT_GREEN]: '#3daf3a',
  [ColorKeys.FIT_GREEN_L4]: '#84d682',
  [ColorKeys.FIT_GREEN_L2]: '#ebf8ea',

  [ColorKeys.SUNGLOW_D8]: '#d39f00',
  [ColorKeys.SUNGLOW_D6]: '#f1b600',
  [ColorKeys.SUNGLOW]: '#ffcc2e',
  [ColorKeys.SUNGLOW_L4]: '#ffebad',
  [ColorKeys.SUNGLOW_L2]: '#fffae9',

  [ColorKeys.MUNSELL_BLUE_D8]: '#006f77',
  [ColorKeys.MUNSELL_BLUE_D6]: '#00949e',
  [ColorKeys.MUNSELL_BLUE]: '#00b9c6',
  [ColorKeys.MUNSELL_BLUE_L4]: '#b5f2f5',
  [ColorKeys.MUNSELL_BLUE_L2]: '#e6fbfd',

  [ColorKeys.NOTIFICATION_SUCCESS]: '#6fc86d',

  [ColorKeys.CYAN_LITE]: '#99E9F2',
  [ColorKeys.CYAN_STRONG]: '#22B8CF',
  [ColorKeys.CYAN_MEDIUM]: '#E1FAFC',
  [ColorKeys.CYAN_ULTRA]: '#0B7285',
  [ColorKeys.ORANGE_LITE]: '#FFD8A8',
  [ColorKeys.ORANGE_STRONG]: '#FF922B',
  [ColorKeys.ORANGE_MEDIUM]: '#FFF3E5',
  [ColorKeys.ORANGE_ULTRA]: '#D9480F',
  [ColorKeys.RED_LITE]: '#FFC9C9',
  [ColorKeys.RED_STRONG]: '#FF6B6B',
  [ColorKeys.RED_MEDIUM]: '#FFF0F0',
  [ColorKeys.RED_ULTRA]: '#C92A2A',
  [ColorKeys.VIOLET_LITE]: '#F8F7FF',
  [ColorKeys.VIOLET_STRONG]: '#845EF7',
  [ColorKeys.VIOLET_MEDIUM]: '#F1ECFF',
  [ColorKeys.VIOLET_ULTRA]: '#862E9C',
  [ColorKeys.BLUE_LITE]: '#BAC8FF',
  [ColorKeys.BLUE_STRONG]: '#5C7CFA',
  [ColorKeys.BLUE_MEDIUM]: '#ECF1FF',
  [ColorKeys.BLUE_ULTRA]: '#364FC7',
  [ColorKeys.GREEN_LITE]: '#B2F2BB',
  [ColorKeys.GREEN_STRONG]: '#51CF66',
  [ColorKeys.GREEN_MEDIUM]: '#E8FBEB',
  [ColorKeys.GREEN_ULTRA]: '#2B8A3E',
  [ColorKeys.PINK_STRONG]: '#F06595',
  [ColorKeys.PINK_MEDIUM]: '#FFEEF4',
  [ColorKeys.PINK_LITE]: '#FCC2D7',
  [ColorKeys.PINK_ULTRA]: '#A61E4D',
  [ColorKeys.TEAL_LITE]: '#96F2D7',
  [ColorKeys.TEAL_STRONG]: '#20C997',
  [ColorKeys.TEAL_MEDIUM]: '#E0FCF3',
  [ColorKeys.TEAL_ULTRA]: '#087F5B',
  [ColorKeys.LIME_LITE]: '#D8F5A2',
  [ColorKeys.LIME_STRONG]: '#94D82D',
  [ColorKeys.LIME_MEDIUM]: '#F3FCE3',
  [ColorKeys.LIME_ULTRA]: '#5C940D',
  [ColorKeys.GRAPE_LITE]: '#EEBEFA',
  [ColorKeys.GRAPE_STRONG]: '#CC5DE8',
  [ColorKeys.GRAPE_MEDIUM]: '#F8EBFC',
  [ColorKeys.GRAPE_ULTRA]: '#862E9C',

  [ColorKeys.BLUE_01]: '#f2f9ff',
  [ColorKeys.BLUE_02]: '#E7F4FF',
  [ColorKeys.BLUE_03]: '#D0EBFF',
  [ColorKeys.BLUE_04]: '#a5d8ff',
  [ColorKeys.BLUE_05]: '#74c0fc',
  [ColorKeys.BLUE_06]: '#4DABF7',
  [ColorKeys.BLUE_07]: '#339AF0',
  [ColorKeys.BLUE_08]: '#228be6',
  [ColorKeys.BLUE_09]: '#1C7ED6',
  [ColorKeys.BLUE_10]: '#1971c2',
  [ColorKeys.BLUE_11]: '#1864AB',

  [ColorKeys.VIOLET_01]: '#f8f7ff',
  [ColorKeys.VIOLET_02]: '#F1ECFF',
  [ColorKeys.VIOLET_03]: '#E5DBFF',
  [ColorKeys.VIOLET_04]: '#d0bfff',
  [ColorKeys.VIOLET_05]: '#b197fc',
  [ColorKeys.VIOLET_06]: '#9775FA',
  [ColorKeys.VIOLET_07]: '#845EF7',
  [ColorKeys.VIOLET_08]: '#7950f2',
  [ColorKeys.VIOLET_09]: '#7048E8',
  [ColorKeys.VIOLET_10]: '#6741d9',
  [ColorKeys.VIOLET_11]: '#5F3DC4',

  [ColorKeys.INDIGO_01]: '#f5f8ff',
  [ColorKeys.INDIGO_02]: '#ECF1FF',
  [ColorKeys.INDIGO_03]: '#DBE4FF',
  [ColorKeys.INDIGO_04]: '#bac8ff',
  [ColorKeys.INDIGO_05]: '#91a7ff',
  [ColorKeys.INDIGO_06]: '#748FFC',
  [ColorKeys.INDIGO_07]: '#5C7CFA',
  [ColorKeys.INDIGO_08]: '#4c6ef5',
  [ColorKeys.INDIGO_09]: '#4263EB',
  [ColorKeys.INDIGO_10]: '#3b5bdb',
  [ColorKeys.INDIGO_11]: '#364fc7',

  [ColorKeys.CYAN_01]: '#f0fcfd',
  [ColorKeys.CYAN_02]: '#E1FAFC',
  [ColorKeys.CYAN_03]: '#C5F6FA',
  [ColorKeys.CYAN_04]: '#99e9f2',
  [ColorKeys.CYAN_05]: '#66d9e8',
  [ColorKeys.CYAN_06]: '#3BC9DB',
  [ColorKeys.CYAN_07]: '#22B8CF',
  [ColorKeys.CYAN_08]: '#15aabf',
  [ColorKeys.CYAN_09]: '#1098AD',
  [ColorKeys.CYAN_10]: '#0c8599',
  [ColorKeys.CYAN_11]: '#0B7285',

  [ColorKeys.ORANGE_01]: '#fff9f2',
  [ColorKeys.ORANGE_02]: '#FFF3E5',
  [ColorKeys.ORANGE_03]: '#FFE8CC',
  [ColorKeys.ORANGE_04]: '#ffd8a8',
  [ColorKeys.ORANGE_05]: '#ffc078',
  [ColorKeys.ORANGE_06]: '#FFA94D',
  [ColorKeys.ORANGE_07]: '#FF922B',
  [ColorKeys.ORANGE_08]: '#fd7e14',
  [ColorKeys.ORANGE_09]: '#F76707',
  [ColorKeys.ORANGE_10]: '#e8590c',
  [ColorKeys.ORANGE_11]: '#D9480F',

  [ColorKeys.RED_01]: '#fff9f9',
  [ColorKeys.RED_02]: '#FFF0F0',
  [ColorKeys.RED_03]: '#FFE3E3',
  [ColorKeys.RED_04]: '#ffc9c9',
  [ColorKeys.RED_05]: '#ffa8a8',
  [ColorKeys.RED_06]: '#FF8787',
  [ColorKeys.RED_07]: '#FF6B6B',
  [ColorKeys.RED_08]: '#fa5252',
  [ColorKeys.RED_09]: '#F03E3E',
  [ColorKeys.RED_10]: '#e03131',
  [ColorKeys.RED_11]: '#C92A2A',

  [ColorKeys.GREEN_01]: '#f4fcf6',
  [ColorKeys.GREEN_02]: '#E8FBEB',
  [ColorKeys.GREEN_03]: '#D3F9D8',
  [ColorKeys.GREEN_04]: '#b2f2bb',
  [ColorKeys.GREEN_05]: '#8ce99a',
  [ColorKeys.GREEN_06]: '#69DB7C',
  [ColorKeys.GREEN_07]: '#51CF66',
  [ColorKeys.GREEN_08]: '#40c057',
  [ColorKeys.GREEN_09]: '#37B24D',
  [ColorKeys.GREEN_10]: '#2f9e44',
  [ColorKeys.GREEN_11]: '#2B8A3E',

  [ColorKeys.GRAPE_01]: '#FBF7FD',
  [ColorKeys.GRAPE_02]: '#F8EBFC',
  [ColorKeys.GRAPE_03]: '#F3D9FA',
  [ColorKeys.GRAPE_04]: '#EEBEFA',
  [ColorKeys.GRAPE_05]: '#E599F7',
  [ColorKeys.GRAPE_06]: '#da77f2',
  [ColorKeys.GRAPE_07]: '#CC5DE8',
  [ColorKeys.GRAPE_08]: '#BE4BDB',
  [ColorKeys.GRAPE_09]: '#AE3EC9',
  [ColorKeys.GRAPE_10]: '#9c36b5',
  [ColorKeys.GRAPE_11]: '#862E9C',
};

export const BoxShadows: Record<string, string> = {
  [ShadowKeys.EMPIRE]: '0px 1px 2px rgba(0, 0, 0, 0.1)',
  [ShadowKeys.TAIPEI]: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  [ShadowKeys.TOKYO]: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  [ShadowKeys.KHALIFA]: '0px 8px 12px rgba(0, 0, 0, 0.1)',
};

export const zIndexes = {
  themePanel: 1100,
};
