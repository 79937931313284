import { IIndependentDiagnosticGeneralRating, IIndependentDiagnosticRating } from 'api/types';
import { DiagnosticRatingRegionCodes, IndependentDiagnosticLevelTypeNames } from 'const';
import { smileWithCoolLike, smileWithHeart, smileWithLike } from 'images';

import { DiagnosticLevels } from '../DynamicSubjectChart/types';

export const getChartLevelType = (levelType: string | undefined): DiagnosticLevels => {
  switch (levelType) {
    case IndependentDiagnosticLevelTypeNames.belowBase:
      return 'belowBase';
    case IndependentDiagnosticLevelTypeNames.base:
      return 'base';
    case IndependentDiagnosticLevelTypeNames.high:
      return 'high';
    case IndependentDiagnosticLevelTypeNames.overHigh:
      return 'overHigh';
    default:
      return 'base';
  }
};

export const getGeneralRatingPercentLowerOthers = (
  selectedRegion: number,
  generalRatingObject: IIndependentDiagnosticGeneralRating | undefined,
) => {
  switch (selectedRegion) {
    case DiagnosticRatingRegionCodes.Region:
      return generalRatingObject?.generalRatingRegion?.percentLowerOthers;
    case DiagnosticRatingRegionCodes.School:
      return generalRatingObject?.generalRatingSchool?.percentLowerOthers;
    case DiagnosticRatingRegionCodes.Class:
      return generalRatingObject?.generalRatingClass?.percentLowerOthers;
    default:
      return 0;
  }
};

export const getGeneralRatingStudentPlaceNumber = (
  selectedRegion: number,
  generalRatingObject: IIndependentDiagnosticGeneralRating | undefined,
) => {
  switch (selectedRegion) {
    case DiagnosticRatingRegionCodes.Region:
      return generalRatingObject?.generalRatingRegion?.placeGeneralRatingStudent;
    case DiagnosticRatingRegionCodes.School:
      return generalRatingObject?.generalRatingSchool?.placeGeneralRatingStudent;
    case DiagnosticRatingRegionCodes.Class:
      return generalRatingObject?.generalRatingClass?.placeGeneralRatingStudent;
    default:
      return 0;
  }
};

export const getGeneralRatingPenultimatePlaceNumber = (
  selectedRegion: number,
  generalRatingObject: IIndependentDiagnosticGeneralRating | undefined,
) => {
  switch (selectedRegion) {
    case DiagnosticRatingRegionCodes.Region:
      return generalRatingObject?.generalRatingRegion?.placeGeneralRatingPenultimatePlace;
    case DiagnosticRatingRegionCodes.School:
      return generalRatingObject?.generalRatingSchool?.placeGeneralRatingPenultimatePlace;
    case DiagnosticRatingRegionCodes.Class:
      return generalRatingObject?.generalRatingClass?.placeGeneralRatingPenultimatePlace;
    default:
      return 0;
  }
};

export const getGeneralRatingLastPlaceNumber = (
  selectedRegion: number,
  generalRatingObject: IIndependentDiagnosticGeneralRating | undefined,
) => {
  switch (selectedRegion) {
    case DiagnosticRatingRegionCodes.Region:
      return generalRatingObject?.generalRatingRegion?.placeGeneralRatingLastPlace;
    case DiagnosticRatingRegionCodes.School:
      return generalRatingObject?.generalRatingSchool?.placeGeneralRatingLastPlace;
    case DiagnosticRatingRegionCodes.Class:
      return generalRatingObject?.generalRatingClass?.placeGeneralRatingLastPlace;
    default:
      return 0;
  }
};

export const getGeneralRatingStudentPlace = (
  selectedRegion: number,
  generalRatingObject: IIndependentDiagnosticGeneralRating | undefined,
) => {
  switch (selectedRegion) {
    case DiagnosticRatingRegionCodes.Region:
      return generalRatingObject?.generalRatingRegion?.placeGeneralRatingStudent;
    case DiagnosticRatingRegionCodes.School:
      return generalRatingObject?.generalRatingSchool?.placeGeneralRatingStudent;
    case DiagnosticRatingRegionCodes.Class:
      return generalRatingObject?.generalRatingClass?.placeGeneralRatingStudent;
    default:
      return 0;
  }
};

export const getDiagnosticRatingPercentLowerOthers = (
  selectedRegion: number,
  diagnosticRatingObject: IIndependentDiagnosticRating | undefined,
) => {
  switch (selectedRegion) {
    case DiagnosticRatingRegionCodes.Region:
      return diagnosticRatingObject?.ratingRegion?.diagnosticPercentLowerOthers;
    case DiagnosticRatingRegionCodes.School:
      return diagnosticRatingObject?.ratingSchool?.diagnosticPercentLowerOthers;
    case DiagnosticRatingRegionCodes.Class:
      return diagnosticRatingObject?.ratingClass?.diagnosticPercentLowerOthers;
    default:
      return 0;
  }
};

export const getRatingRegionPlaceName = (selectedRegion: number) => {
  switch (selectedRegion) {
    case DiagnosticRatingRegionCodes.Region:
      return 'города';
    case DiagnosticRatingRegionCodes.School:
      return 'школы';
    case DiagnosticRatingRegionCodes.Class:
      return 'класса';
    default:
      return 'класса';
  }
};

export const getGeneralRatingInfoBoxText = (percent: string, place: string, subject: string) => {
  // eslint-disable-next-line max-len
  return `Ты входишь в ${percent}% лучших учеников ${place} по результатам всех сданных независимых диагностик по предмету ${subject}!`;
};

export const getDiagnosticRatingInfoBoxTitle = (levelType: string) => {
  switch (levelType.toLowerCase()) {
    case IndependentDiagnosticLevelTypeNames.belowBase.toLowerCase():
      return 'Неплохо!';
    case IndependentDiagnosticLevelTypeNames.base.toLowerCase():
      return 'Хороший результат!';
    case IndependentDiagnosticLevelTypeNames.overHigh.toLowerCase():
    case IndependentDiagnosticLevelTypeNames.high.toLowerCase():
      return 'Высокий результат!';
  }
};

export const getDiagnosticRatingInfoBoxText = (levelType: string, name: string) => {
  switch (levelType.toLowerCase()) {
    case IndependentDiagnosticLevelTypeNames.belowBase.toLowerCase():
      return `${name}, не переживай, у тебя все обязательно получится!`;
    case IndependentDiagnosticLevelTypeNames.base.toLowerCase():
      return `${name}, тебе есть куда стремиться!`;
    case IndependentDiagnosticLevelTypeNames.overHigh.toLowerCase():
    case IndependentDiagnosticLevelTypeNames.high.toLowerCase():
      return `${name}, ты молодец, на тебя должны равняться!`;
  }
};

export const getDiagnosticRatingInfoBoxImage = (levelType: string) => {
  switch (levelType.toLowerCase()) {
    case IndependentDiagnosticLevelTypeNames.belowBase.toLowerCase():
      return smileWithHeart;
    case IndependentDiagnosticLevelTypeNames.base.toLowerCase():
      return smileWithLike;
    case IndependentDiagnosticLevelTypeNames.overHigh.toLowerCase():
    case IndependentDiagnosticLevelTypeNames.high.toLowerCase():
      return smileWithCoolLike;
  }
};
