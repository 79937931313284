import { FC, PropsWithChildren } from 'react';

import { Typography } from '@mui/material';
import { SxStyles } from 'types';
import { mergeSx } from 'utils';

import { IBaseBadgeProps } from '..';

export const styles: SxStyles = {
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',

  width: 'fit-content',
  padding: '2px 8px',

  borderRadius: '12px',
};

const BaseNumberBadge: FC<IBaseBadgeProps & PropsWithChildren> = (props) => {
  const { textColor, backgroundColor, children, sx } = props;

  const dynamicStyles: SxStyles = {
    color: textColor,
    backgroundColor,
  };

  return (
    <Typography component="div" sx={mergeSx(styles, dynamicStyles, sx)} variant="Paragraph SM/Semi Bold">
      {children}
    </Typography>
  );
};

export default BaseNumberBadge;
