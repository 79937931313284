import { FC, HTMLAttributes, ReactNode } from 'react';

import { AutocompleteProps, AutocompleteRenderOptionState, ListItem } from '@mui/material';

import AutocompleteBase, { IAutocompleteBaseProps } from '../bases/AutocompleteBase';
import { organizationOptionStyles } from '../styles';
import { OrganizationAutocompleteOption } from '../types';

const getOrganizationAutocompleteRenderOptions = (): Partial<
  AutocompleteProps<OrganizationAutocompleteOption, false, false, false>
> => {
  const getOptionLabel = (option: OrganizationAutocompleteOption): string => {
    return option.shortName;
  };

  const renderOption = (
    props: HTMLAttributes<HTMLLIElement>,
    option: OrganizationAutocompleteOption,
    state: AutocompleteRenderOptionState,
  ): ReactNode => {
    return (
      <ListItem {...props} sx={organizationOptionStyles(state.selected)}>
        <div>{option.fullName}</div>
        <div style={{ width: '100%', borderTop: '1px solid #F4F3F8FF' }} />
        <div>{option.shortName}</div>
      </ListItem>
    );
  };

  return {
    getOptionLabel,
    renderOption,
  };
};

const TypedOrganizationAutocomplete = AutocompleteBase<OrganizationAutocompleteOption, false, false, false>;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IOrganizationAutocompleteProps
  extends IAutocompleteBaseProps<OrganizationAutocompleteOption, false, false, false> {}

const OrganizationAutocomplete: FC<IOrganizationAutocompleteProps> = ({ ...props }) => {
  return <TypedOrganizationAutocomplete {...getOrganizationAutocompleteRenderOptions()} {...props} />;
};

export default OrganizationAutocomplete;
