import { ComponentProps, FC, ReactNode, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Box, useMediaQuery } from '@mui/material';
import { linkSharingDrawerActions } from 'actions';
import { routes } from 'const';
import { DrawerFormsContext } from 'context';
import { useAppSelector, useUserFunctionality } from 'hooks';
import { IconColorPaletteOutline, IconSettingsOutline, IconShareOutline } from 'icons';
import { emitYMEvent } from 'portfolio3/features/yandexMetrika';
import { NeutralColors } from 'portfolio3/styles';
import { commonTheme } from 'portfolio3/styles/theme';
import { Button } from 'portfolio3/ui-kit/button';
import { isDeviceMobile } from 'utils';

import { wrapper } from './styles';

interface IServiceButtonsProps {
  searchButtonElement: ReactNode;
}

const ServiceButtons: FC<IServiceButtonsProps> = ({ searchButtonElement }) => {
  const dispatch = useDispatch();
  const linkMode = useAppSelector((state) => state.linkMode.mode);
  const adminFunctionSettings = useAppSelector((state) => state.adminFunctionSettings);

  const isSmallScreen = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const { isStudent, isParent } = useUserFunctionality();
  const { onOpenThemePanel } = useContext(DrawerFormsContext);
  const history = useHistory();

  const isMobileDevice = isDeviceMobile();

  const hasShareRole = isStudent || isParent;
  const searchSetting = adminFunctionSettings.content?.find((setting) => setting.functionType === 'Search');

  const isShareVisible = !linkMode && linkMode !== undefined && hasShareRole;
  const isSettingsVisible = !isMobileDevice && !linkMode;
  const isThemeVisible = isStudent;
  const isSearchVisible = searchSetting?.isVisible;

  const handleOpenThemePanel = () => {
    emitYMEvent({ type: 'visualConfiguring' });
    onOpenThemePanel();
  };

  const handleOpenLinkSharing = () => {
    emitYMEvent({ type: 'shareButtonClick' });
    dispatch(linkSharingDrawerActions.setOpen(true));
  };

  const handleOpenSettings = () => {
    emitYMEvent({ type: 'portfolioSettingsClick' });
    history.push(routes.PORTFOLIO_STUDENT_SETTING.path);
  };

  const buttonCommonProps: Partial<ComponentProps<typeof Button>> = {
    variant: 'secondary',
    borderRadius: '12px',
    sx: {
      borderColor: NeutralColors.light_neutrals_300,
    },
  };

  return (
    <Box className="service-buttons" sx={wrapper}>
      {isShareVisible && (
        <Button
          iconLeft={<IconShareOutline />}
          onlyIcon={isSmallScreen}
          onClick={handleOpenLinkSharing}
          {...buttonCommonProps}
        >
          {isSmallScreen ? <IconShareOutline /> : 'Поделиться'}
        </Button>
      )}
      {isThemeVisible && (
        <Button onlyIcon onClick={handleOpenThemePanel} {...buttonCommonProps}>
          <IconColorPaletteOutline />
        </Button>
      )}
      {isSettingsVisible && (
        <Button onlyIcon onClick={handleOpenSettings} {...buttonCommonProps}>
          <IconSettingsOutline />
        </Button>
      )}
      {isSearchVisible && searchButtonElement}
    </Box>
  );
};

export default ServiceButtons;
