/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';

import {
  FAILURE,
  INVALIDATE,
  REQUEST,
  SUCCESS
} from '../../actions/utils';

import genericReducers from '../genericReducer';
import { IReducers } from '..';
import { ICommonResponse, IStudentAffilation } from '../../api/types';
import { GET_STUDENT_AFFILATIONS } from '../../actions';

export type StudentAffilationsState = ICommonResponse<IStudentAffilation>;

const initialState = {
  content: [],
  loading: false
} as ICommonResponse<IStudentAffilation>;

const studentAffilations: IReducers<StudentAffilationsState> = {
  [GET_STUDENT_AFFILATIONS[REQUEST]]: genericReducers().get.request,
  [GET_STUDENT_AFFILATIONS[SUCCESS]]: genericReducers().get.success,
  [GET_STUDENT_AFFILATIONS[FAILURE]]: genericReducers().get.failure,
  [GET_STUDENT_AFFILATIONS[INVALIDATE]]: genericReducers().get.invalidate(initialState),
};

export default connectReducers(initialState, studentAffilations);
