import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconShareOutline: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M18 19a1 1 0 1 1 0-2 1 1 0 0 1 0 2ZM5 13a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm13-8a1 1 0 1 1 0 2 1 1 0 0 1 0-2Zm0 10c-.817 0-1.557.33-2.099.861l-7.935-3.526C7.979 12.224 8 12.114 8 12c0-.114-.021-.224-.034-.335l7.935-3.526A2.991 2.991 0 0 0 18 9c1.654 0 3-1.346 3-3s-1.346-3-3-3-3 1.346-3 3c0 .114.021.224.034.335L7.099 9.861A2.991 2.991 0 0 0 5 9c-1.654 0-3 1.346-3 3s1.346 3 3 3c.817 0 1.557-.33 2.099-.861l7.935 3.526c-.013.111-.034.221-.034.335 0 1.654 1.346 3 3 3s3-1.346 3-3-1.346-3-3-3Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconShareOutline;
