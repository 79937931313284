import { FC } from 'react';

import { useAppSelector, useDataLoad } from 'hooks';
import { InterestCard } from 'portfolio3/features/pdf/components/cards';
import GridLayout from 'portfolio3/features/pdf/components/GridLayout';
import PdfNoDataText from 'portfolio3/features/pdf/components/PdfNoDataText';
import { getDataLoadRequests } from 'utils';

import { widgetPdfIcons } from '../../../../icons';
import { profileInterestsSelector } from '../../../../selectors';
import PdfCommonDataEntityContainer from '../common';

import '../index.scss';

const PdfInterestsContainer: FC = () => {
  const interests = useAppSelector(profileInterestsSelector);

  const dataRequests = getDataLoadRequests();
  useDataLoad({ shouldLoad: true, requests: [...dataRequests.profileInterests, ...dataRequests.archivedInterestRefs] });

  const hasData = interests.content.length > 0;

  return (
    <PdfCommonDataEntityContainer title="Анкета интересов" icon={widgetPdfIcons.pdfIconProfileInterests}>
      {hasData && (
        <GridLayout gap="16px">
          {interests.content.map((interest) => {
            const { id, name, actionKinds, image } = interest;
            const actions = actionKinds.map((action) => action.value);

            return <InterestCard key={id} name={name} actions={actions} image={image} />;
          })}
        </GridLayout>
      )}
      {!hasData && <PdfNoDataText />}
    </PdfCommonDataEntityContainer>
  );
};

export default PdfInterestsContainer;
