import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const footer: SxStyles = {
  display: 'flex',
  gap: '12px',
  justifyContent: 'flex-end',
  height: '64px',
  padding: '12px 20px',

  borderTop: '1px solid',
  borderColor: NeutralColors.light_neutrals_300,
  backgroundColor: NeutralColors.light_neutrals_100,
};

export const overlay: SxStyles = {
  zIndex: 1000,
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',

  background: 'rgba(255, 255, 255, 0.6)',
};
