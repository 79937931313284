import { ISearchVuzSpeciality, IVuzFaculty, IVuzFacultySpeciality, IVuzSpeciality } from 'api/types';
import { isDefined } from 'utils';

import { ILocalVuzFaculty, ILocalVuzSpeciality } from '../types';

export const convertSearchVuzSpecialityToLocal = ({
  name,
  number,
  subjects,
  budget_min_ball,
  paid_min_ball,
  faculty,
}: ISearchVuzSpeciality): ILocalVuzSpeciality => {
  return {
    name: name ?? '',
    number: number ?? '',
    budgetMinBall: budget_min_ball,
    paidMinBall: paid_min_ball,
    facultyName: faculty,
    subjects,
  };
};

export const convertVuzSpecialityToLocal = (speciality: IVuzSpeciality): ILocalVuzSpeciality[] => {
  const { name, number, faculties } = speciality;

  const localSpecialities = faculties?.map((faculty): ILocalVuzSpeciality => {
    const { name: facultyName, subjects, type, budget_places, paid_places, budget_min_ball, paid_min_ball } = faculty;

    return {
      name: name ?? '',
      number: number ?? '',
      facultyName,
      subjects,
      type,
      budgetMinBall: numberOrUndefined(budget_min_ball),
      paidMinBall: numberOrUndefined(paid_min_ball),
      budgetPlaces: numberOrUndefined(budget_places),
      paidPlaces: numberOrUndefined(paid_places),
    };
  });

  return localSpecialities ?? [];
};

export const convertVuzFacultySpecialityToLocal = (
  { name, number, subjects, type, budget_places, budget_min_ball, paid_min_ball, paid_places }: IVuzFacultySpeciality,
  facultyName: string | undefined,
): ILocalVuzSpeciality => {
  return {
    name: name ?? '',
    number: number ?? '',
    facultyName,
    subjects,
    type,
    budgetMinBall: numberOrUndefined(budget_min_ball),
    paidMinBall: numberOrUndefined(paid_min_ball),
    budgetPlaces: numberOrUndefined(budget_places),
    paidPlaces: numberOrUndefined(paid_places),
  };
};

export const convertVuzFacultyToLocal = (faculty: IVuzFaculty): ILocalVuzFaculty => {
  const { name, specialities } = faculty;

  return {
    name,
    specialities: specialities.map((facultySpeciality) =>
      convertVuzFacultySpecialityToLocal(facultySpeciality, undefined),
    ),
  };
};

function numberOrUndefined(value: unknown): number | undefined {
  return isDefined(value) ? Number(value) : undefined;
}
