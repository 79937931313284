/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';

import {
  GET_STUDENT_DIAGNOSIS
} from '../../actions';

import {
  FAILURE,
  REQUEST,
  SUCCESS
} from '../../actions/utils';

import { IDiagnosisResponse } from '../../api/types';

import genericReducers from '../genericReducer';

import { IReducers } from '..';

export type StudentDiagnosisState = IDiagnosisResponse | null;

const initialState: StudentDiagnosisState = null;

const studentDiagnosis: IReducers<StudentDiagnosisState> = {
  [GET_STUDENT_DIAGNOSIS[REQUEST]]: genericReducers().get.request,
  [GET_STUDENT_DIAGNOSIS[SUCCESS]]: genericReducers().get.success,
  [GET_STUDENT_DIAGNOSIS[FAILURE]]: genericReducers().get.failure
};

export default connectReducers(initialState, studentDiagnosis);
