import { FC } from 'react';

import { Box } from '@mui/material';
import { SxStyles } from 'types';
import { mergeSx } from 'utils';

import backgroundIcons from './backgroundIcons.png';

const styles: SxStyles = {
  width: '253px',
  height: '230px',

  backgroundImage: `url(${backgroundIcons})`,
  backgroundRepeat: 'no-repeat',
};

interface IBackgroundIconsProps {
  sx?: SxStyles;
}

const BackgroundIcons: FC<IBackgroundIconsProps> = ({ sx }) => {
  return <Box className="background-icons" sx={mergeSx(styles, sx)} />;
};

export default BackgroundIcons;
