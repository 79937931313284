import {
  DATA_ENTRY_DRAWER_SET_EXCLUDED_EVENT_TYPES,
  DATA_ENTRY_DRAWER_SET_PERSONS_OBJECTS_QUERY_PARAMS,
  DATA_ENTRY_DRAWER_SET_PRIMARY_INITIAL_FORM_DATA,
  DATA_ENTRY_DRAWER_SET_PRIMARY_OPEN,
  DATA_ENTRY_DRAWER_SET_SECONDARY_INITIAL_FORM_DATA,
  DATA_ENTRY_DRAWER_SET_SECONDARY_OPEN,
  dataEntryDrawerActions,
} from 'actions';
import { IQueryParams } from 'api';
import { getPersonsObjectsQueryParams } from 'const';
import { IModifiedPersonObject } from 'portfolio3/features/dataEntryForm';
import { IReducers } from 'reducers';
import { connectReducers } from 'utils';

type DrawerState = {
  isOpen: boolean;
  widgetLabel: string | null;
};

export type DataEntryDrawerState = {
  primaryState: DrawerState | null;
  secondaryState: DrawerState | null;
  primaryInitialFormData: Partial<IModifiedPersonObject> | null;
  secondaryInitialFormData: Partial<IModifiedPersonObject> | null;
  excludedEventTypes: number[] | undefined;
  personsObjectsQueryParams: IQueryParams;
};

const initialState: DataEntryDrawerState = {
  primaryState: null,
  secondaryState: null,
  primaryInitialFormData: null,
  secondaryInitialFormData: null,
  excludedEventTypes: undefined,
  personsObjectsQueryParams: getPersonsObjectsQueryParams,
};

type ActionType<T extends keyof typeof dataEntryDrawerActions> = ReturnType<
  (typeof dataEntryDrawerActions)[T]
>['payload'];

type SetOpenAction = ActionType<'setPrimaryOpen'>;
type SetInitialFormDataAction = ActionType<'setPrimaryInitialFormData'>;
type SetExcludedEventTypesAction = ActionType<'setExcludedEventTypes'>;
type SetPersonsObjectsQueryParams = ActionType<'setPersonsObjectsQueryParams'>;

const dataEntryDrawer: IReducers<DataEntryDrawerState> = {
  [DATA_ENTRY_DRAWER_SET_PRIMARY_OPEN]: (state, action: SetOpenAction) => {
    const isOpen = action.payload.open;
    const widgetLabel = action.payload.widgetLabel;

    const drawerState: DrawerState = {
      isOpen,
      widgetLabel,
    };

    const newState: DataEntryDrawerState = isOpen
      ? state
      : {
          ...state,
          primaryInitialFormData: null,
          excludedEventTypes: undefined,
          personsObjectsQueryParams: getPersonsObjectsQueryParams,
        };

    return {
      ...newState,
      primaryState: drawerState,
    };
  },
  [DATA_ENTRY_DRAWER_SET_SECONDARY_OPEN]: (state, action: SetOpenAction) => {
    const isOpen = action.payload.open;
    const widgetLabel = action.payload.widgetLabel;

    const drawerState: DrawerState = {
      isOpen,
      widgetLabel,
    };

    const newState: DataEntryDrawerState = isOpen
      ? state
      : {
          ...state,
          secondaryInitialFormData: null,
          excludedEventTypes: undefined,
          personsObjectsQueryParams: getPersonsObjectsQueryParams,
        };

    return {
      ...newState,
      secondaryState: drawerState,
    };
  },
  [DATA_ENTRY_DRAWER_SET_PRIMARY_INITIAL_FORM_DATA]: (state, action: SetInitialFormDataAction) => {
    return { ...state, primaryInitialFormData: action.payload };
  },
  [DATA_ENTRY_DRAWER_SET_SECONDARY_INITIAL_FORM_DATA]: (state, action: SetInitialFormDataAction) => {
    return { ...state, secondaryInitialFormData: action.payload };
  },
  [DATA_ENTRY_DRAWER_SET_EXCLUDED_EVENT_TYPES]: (state, action: SetExcludedEventTypesAction) => {
    return { ...state, excludedEventTypes: action.payload };
  },
  [DATA_ENTRY_DRAWER_SET_PERSONS_OBJECTS_QUERY_PARAMS]: (state, action: SetPersonsObjectsQueryParams) => {
    return { ...state, personsObjectsQueryParams: action.payload };
  },
};

export default connectReducers(initialState, dataEntryDrawer);
