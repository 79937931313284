/* eslint-disable prettier/prettier */
/* eslint-disable */

import { useLoadSectionRef } from 'hooks';
import React, { useEffect } from 'react';

import { connect } from 'react-redux';

import { getLearnerCategoriesActions, getSettingSectionsActions } from '../../../actions';
import { MAX_PAGE_SIZE } from '../../../api';
import { AdminSettingsDataTable } from '../../../components/admin/SettingsHistory';

import './index.scss';

interface IVisibilityHistoryProps {
  getSettingsSections: typeof getSettingSectionsActions.request,
  getLearnerCategories: typeof getLearnerCategoriesActions.request
}

const AdminVisibilityHistoryContainer: React.FC<IVisibilityHistoryProps> = ({
  getSettingsSections,
  getLearnerCategories
}) => {
  useLoadSectionRef();

  useEffect(() => {
    getLearnerCategories({ size: MAX_PAGE_SIZE });
    getSettingsSections({ size: MAX_PAGE_SIZE });
  }, []);

  return (
    <section className="visibility-history">
      <div className="visibility-history__content">
        <AdminSettingsDataTable />
      </div>
    </section>
  );
};

export default connect(
  null,
  {
    getSettingsSections: getSettingSectionsActions.request,
    getLearnerCategories: getLearnerCategoriesActions.request
  }
)(AdminVisibilityHistoryContainer);
