import { getAccentColor, NeutralColors } from 'portfolio3/styles';
import { zIndexes } from 'style/variables';
import { SxStyles } from 'types';

export const root: SxStyles = (theme) => ({
  zIndex: zIndexes.themePanel,
  position: 'fixed',
  top: 0,
  left: 0,

  display: 'grid',
  gridAutoFlow: 'row',
  gridTemplateRows: 'auto 1fr',

  height: '100vh',
  width: '364px',

  backgroundColor: getAccentColor('violet', '200'),
  boxShadow: '3px 1px 6px -1px #29294014, 1px 1px 5px 0px #2929400A',

  overflow: 'hidden',

  [theme.breakpoints.down('commonSm')]: {
    width: '100%',
  },
});

export const body: SxStyles = {
  paddingBlock: '20px',

  backgroundColor: '#ffffff',
  borderRadius: '12px 12px 0 0',

  overflow: 'auto',

  '& > *': {
    marginTop: '24px',
  },

  '& > *:first-child': {
    marginTop: 0,
  },
};

export const settingsGrid: SxStyles = {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(88px, 1fr))',
  gap: '12px',
};

export const alert: SxStyles = {
  position: 'sticky',
  bottom: 0,

  padding: '20px',
  borderTop: '1px solid',
  backgroundColor: '#ffffff',
  borderColor: NeutralColors.light_neutrals_300,
};
