import { FC } from 'react';

import { buttonBaseClasses, Radio, radioClasses, RadioProps, styled } from '@mui/material';
import { getAccentColor, NeutralColors } from 'portfolio3/styles';

import { getRadioButtonTokens } from '../utils';
import StyledRadioIcon from './RadioIcon.styled';

const StyledRadio = styled(Radio)(({ size, theme }) => {
  const focusedColor = getAccentColor(theme.palette.accentColor, '24');
  const tokens = size ? getRadioButtonTokens(size) : getRadioButtonTokens('medium');
  const { outerSize } = tokens;

  return {
    width: outerSize,
    height: outerSize,
    minWidth: outerSize,
    minHeight: outerSize,

    padding: 0,

    [`&.${radioClasses.disabled}`]: {
      backgroundColor: NeutralColors.light_neutrals_300,
    },

    [`&.${buttonBaseClasses.focusVisible}`]: {
      boxShadow: `0 0 0 3px ${focusedColor}`,
    },

    '&:hover': {
      boxShadow: `0 0 0 3px ${focusedColor}`,
    },
  };
});

const RadioButton: FC<RadioProps> = (props) => {
  return (
    <StyledRadio
      {...props}
      disableRipple
      icon={<StyledRadioIcon size={props.size} />}
      checkedIcon={<StyledRadioIcon size={props.size} />}
    />
  );
};

export default RadioButton;
