import { RangePreset } from './localTypes';

export const CALENDAR_CELL_WIDTH = 32;
export const YEARS_PER_ROW = 4;
export const YEARS_PER_COLS = 4;
export const YEARS_PER_PAGE = YEARS_PER_ROW * YEARS_PER_COLS;

export const RangePresetNameMapping: Record<RangePreset, string> = {
  today: 'Сегодня',
  tomorrow: 'Завтра',
  'last 2 days': 'За последние сутки',
  'last 7 days': 'За последние 7 дней',
  'last month': 'За последний месяц',
  'last year': 'За последний год',
  'selected range': 'Указанный период',
};
