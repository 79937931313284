import React from 'react';
import { connect } from 'react-redux';

import { Box, useMediaQuery } from '@mui/material';
import { ReportErrorTypeCodes } from 'const';
import { commonTheme } from 'portfolio3/styles/theme';
import { IRootState } from 'reducers';
import { EntityErrorMessagesState } from 'reducers/student/entityErrorMessages';

import FunnelBlock from '../../FunnelBlock';
import CardDetailsError from '../CardDetailsError';
import ErrorReportType from '../common/ErrorType';
import InformationError from '../InformationError';
import { FormStep, ICardDetailsController, IReportErrorFormData } from '../types';
import { createController } from '../utils';

interface IPortfolioReportErrorFormProps {
  entityErrorMessages: EntityErrorMessagesState;
  formData: IReportErrorFormData;
  onChangeFormData: (formData: IReportErrorFormData) => void;
  formStep: FormStep;
  onChangeFormStep: (formStep: FormStep) => void;
}

const PortfolioReportErrorForm: React.FC<IPortfolioReportErrorFormProps> = ({
  entityErrorMessages,
  formData,
  onChangeFormData,
  formStep,
  onChangeFormStep,
}) => {
  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const isCardDetailsError = formData.errorTypeCode === ReportErrorTypeCodes.cardDataError;
  const isInformationError = formData.errorTypeCode === ReportErrorTypeCodes.informationDoesNotApplyToMe;

  const handleChangeErrorType = () => {
    return (errorType: number) => {
      onChangeFormData({
        ...formData,
        errorTypeCode: errorType,
      });
      onChangeFormStep(FormStep.SUBMIT_ERROR);
    };
  };

  const cardDetailsController: ICardDetailsController = {
    general: createController(formData, onChangeFormData, 'errorGeneralMessage', 'errorGeneralMessageChecked'),
    fileMetadata: createController(
      formData,
      onChangeFormData,
      'errorFileMetadataMessage',
      'errorFileMetadataMessageChecked',
    ),
    childEntity: createController(
      formData,
      onChangeFormData,
      'errorChildEntityMessage',
      'errorChildEntityMessageChecked',
    ),
  };

  const ContainerElement = isMobile && isCardDetailsError ? FunnelBlock : Box;

  return (
    <ContainerElement sx={{ marginTop: isMobile ? '12px' : 0 }}>
      {formStep === FormStep.SELECT_ERROR_TYPE && <ErrorReportType onChangeErrorType={handleChangeErrorType()} />}

      {formStep === FormStep.SUBMIT_ERROR && isCardDetailsError && (
        <CardDetailsError
          formData={formData}
          controller={cardDetailsController}
          isDisabled={entityErrorMessages.loading}
        />
      )}
      {formStep === FormStep.SUBMIT_ERROR && isInformationError && <InformationError />}
    </ContainerElement>
  );
};

export default connect((state: IRootState) => ({
  entityErrorMessages: state.entityErrorMessages,
}))(PortfolioReportErrorForm);
