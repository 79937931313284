import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconStarFilled: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M17.284 21a.952.952 0 0 1-.44-.107l-4.845-2.53-4.843 2.53a.952.952 0 0 1-1.375-1.004l.922-5.345L2.79 10.76a.95.95 0 0 1 .525-1.622l5.415-.786 2.42-4.868c.321-.645 1.38-.645 1.702 0l2.42 4.868 5.415.786a.948.948 0 0 1 .525 1.622l-3.914 3.784.922 5.345A.95.95 0 0 1 17.283 21Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconStarFilled;
