/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { Droppable } from 'react-beautiful-dnd';
import DirectionsDragItem from './dragItem';
import { IDragItem } from '../types';
import { getDragItemRotation } from './utils';

import './index.scss';

interface IDirectionsDropContainerProps {
  prefix: string,
  elements: IDragItem[]
}

const DirectionsDropContainer: React.FC<IDirectionsDropContainerProps> = ({ prefix, elements }) => (
  <div className={`drop-container ${prefix}`}>
    <Droppable droppableId={`${prefix}`}>
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          {elements.map((dragItem, index, array) => (
            <DirectionsDragItem
              index={index}
              key={dragItem.id}
              id={dragItem.id}
              name={dragItem.value}
              cardTheme={dragItem.theme.card}
              rotation={getDragItemRotation(index, array.length)}
            />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  </div>

);

export default DirectionsDropContainer;
