import { createContext } from 'react';

interface IWidgetContainerContext {
  withBackground: boolean;
}

const initialState: IWidgetContainerContext = {
  withBackground: false,
};

export const WidgetContainerContext = createContext(initialState);
