/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconArrowBack: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M12.8333 5.16643H2.94575L5.97325 1.53309C6.26825 1.17976 6.21992 0.653928 5.86658 0.359761C5.51242 0.0647615 4.98742 0.113095 4.69325 0.466428L0.526585 5.46643C0.494085 5.5056 0.477419 5.55143 0.453252 5.59476C0.433252 5.62976 0.409085 5.65976 0.394085 5.69809C0.356585 5.79393 0.334085 5.89476 0.334085 5.99643C0.334085 5.99726 0.333252 5.99893 0.333252 5.99976C0.333252 6.0006 0.334085 6.00226 0.334085 6.0031C0.334085 6.10476 0.356585 6.2056 0.394085 6.30143C0.409085 6.33976 0.433252 6.36976 0.453252 6.40476C0.477419 6.44809 0.494085 6.49393 0.526585 6.5331L4.69325 11.5331C4.85825 11.7306 5.09492 11.8331 5.33325 11.8331C5.52158 11.8331 5.71075 11.7698 5.86658 11.6398C6.21992 11.3456 6.26825 10.8198 5.97325 10.4664L2.94575 6.8331H12.8333C13.2933 6.8331 13.6666 6.45976 13.6666 5.99976C13.6666 5.53976 13.2933 5.16643 12.8333 5.16643Z" />
  </SvgIcon>

);

export default IconArrowBack;
