/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';

import {
  FAILURE,
  INVALIDATE,
  REQUEST,
  SUCCESS
} from '../../actions/utils';

import genericReducers from '../genericReducer';
import { IReducers } from '..';
import { ICommonResponse, IStudentCultureVisiting } from '../../api/types';
import { GET_STUDENT_CULTURE_VISITINGS } from '../../actions';

export type CultureVisitingsState = ICommonResponse<IStudentCultureVisiting>;

const initialState = {
  content: [],
  loading: false
} as ICommonResponse<IStudentCultureVisiting>;

const cultureVisitings: IReducers<CultureVisitingsState> = {
  [GET_STUDENT_CULTURE_VISITINGS[REQUEST]]: genericReducers().get.request,
  [GET_STUDENT_CULTURE_VISITINGS[SUCCESS]]: genericReducers().get.success,
  [GET_STUDENT_CULTURE_VISITINGS[FAILURE]]: genericReducers().get.failure,
  [GET_STUDENT_CULTURE_VISITINGS[INVALIDATE]]: genericReducers().get.invalidate(initialState),
};

export default connectReducers(initialState, cultureVisitings);
