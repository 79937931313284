import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useAppSelector } from 'hooks';

import { addFavoriteVuzActions, deleteFavoriteVuzActions } from '../model/actions';
import { favoriteVuzIdSelector } from '../model/selectors';

const useFavoriteVuz = (id: number | undefined) => {
  const dispatch = useDispatch();
  const { meshId: personId } = useAppSelector((state) => state.currentStudent);
  const isFavoriteListLoading = useAppSelector((state) => state.favoriteVuzList).loading;
  const isAddToFavoriteLoading = useAppSelector((state) => state.addFavoriteVuz).loading;
  const isDeleteFavoriteLoading = useAppSelector((state) => state.deleteFavoriteVuz).loading;
  const favoriteVuzIds = useAppSelector(favoriteVuzIdSelector);

  const [isLoading, setLoading] = useState(false);

  const isFavoriteVuz = id ? favoriteVuzIds?.includes(id) ?? false : false;

  // Отключаем состояние загрузки когда все методы выполнились
  useEffect(() => {
    if (!isFavoriteListLoading && !isAddToFavoriteLoading && !isDeleteFavoriteLoading) {
      setLoading(false);
    }
  }, [isAddToFavoriteLoading, isDeleteFavoriteLoading, isFavoriteListLoading]);

  const handleToggleVuzFavorite = useCallback(() => {
    if (!id || !favoriteVuzIds) return;

    const action = isFavoriteVuz
      ? deleteFavoriteVuzActions.request(personId, id)
      : addFavoriteVuzActions.request(personId, id);

    dispatch(action);
    setLoading(true);
  }, [dispatch, favoriteVuzIds, id, isFavoriteVuz, personId]);

  return { isFavoriteVuz, isFavoriteStateLoading: isLoading, handleToggleVuzFavorite };
};

export default useFavoriteVuz;
