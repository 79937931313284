import React, { useRef, useState } from 'react';

import { Box, Stack, ThemeProvider, Typography } from '@mui/material';
import { format } from 'date-fns';
import ruLocale from 'date-fns/locale/ru';
import SmallActionsButton from 'portfolio3/components/common/buttons/SmallActionsButton';
import Table from 'portfolio3/components/common/Table';
import { generateAccentTheme } from 'portfolio3/styles/theme';
import { AccentBadge, NeutralBadge } from 'portfolio3/ui-kit/badges';

import { IChildInfo } from '../../../../api/types';
import { EntityType } from '../../../../const';
import {
  IconDeleteFilled,
  IconEditFilled,
  IconImportCloudUpload,
  IconManual,
  IconMoreHorizontal,
} from '../../../../icons';
import { getDataItemType } from '../../../../utils';
import { EntityActionsItem, EntityActionsPopper } from '../../../common/EntityActions';
import { IChangeHistoryManualItem } from '../types';
import * as styles from './styles';

interface IChangeHistoryManualItemProps extends IChangeHistoryManualItem {
  loading: boolean;
  onOpenDeleteDialog: (id: number, dataKind: number, studentName: string, additionalText: string | null) => void;
  onEditEmployeeObject: (type: EntityType, currentStudent: IChildInfo, id?: number) => void;
}

const ChangeHistoryManualItem: React.FC<IChangeHistoryManualItemProps> = ({
  loading,
  entityId,
  personId,
  dataKind,
  method,
  onOpenDeleteDialog,
  onEditEmployeeObject,
  actionLabel,
  actionAccentColor,
  student,
  firstName,
  lastName,
  patronymic,
  date,
  entityInfo,
  isImport,
}) => {
  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const [dataItemMenuOpen, setDataMenuOpen] = useState(false);

  const entityType = getDataItemType(dataKind);
  const isEntityAttachable = entityType === EntityType.EVENT || entityType === EntityType.PROJECT;

  const additionalDialogText = isEntityAttachable ? 'Нам также придется удалить связанные награды.' : null;

  const handleOpenDeleteDialog = () => {
    onOpenDeleteDialog(entityId, dataKind, student, additionalDialogText);
    setDataMenuOpen(false);
  };

  const handleEditEntity = () => {
    if (!entityType) return;

    onEditEmployeeObject(
      entityType,
      {
        meshId: personId,
        firstname: firstName,
        lastname: lastName,
        patronymic,
      },
      entityId,
    );
    setDataMenuOpen(false);
  };

  const addTypeElement = (
    <Box sx={styles.addType}>
      {isImport ? <IconImportCloudUpload /> : <IconManual />}
      <Typography variant="Paragraph MD/Regular">{isImport ? 'импорт' : 'ручное'}</Typography>
    </Box>
  );

  const actionDate = date && format(date, 'dd.MM.yyyy');
  const actionTime = date && format(date, 'HH:mm', { locale: ruLocale });
  const formattedDate = date && `${actionDate} в ${actionTime}`;

  return (
    <Table.Row>
      <Table.DataCell>
        <Stack gap="4px">
          <ThemeProvider theme={generateAccentTheme(actionAccentColor)}>
            <AccentBadge>{actionLabel}</AccentBadge>
          </ThemeProvider>
          {method === 'POST' && addTypeElement}
        </Stack>
      </Table.DataCell>
      <Table.DataCell>
        {formattedDate && <Typography variant="Paragraph MD/Regular">{formattedDate}</Typography>}
      </Table.DataCell>
      <Table.DataCell>
        <Typography variant="Paragraph MD/Semi Bold">{student}</Typography>
      </Table.DataCell>
      <Table.DataCell>
        <Stack gap="8px">
          <Typography sx={{ wordBreak: 'break-word' }} variant="Paragraph MD/Regular">
            {entityInfo.name}
          </Typography>
          <Stack direction="row" gap="8px">
            <ThemeProvider theme={generateAccentTheme(entityInfo.accentColor)}>
              <AccentBadge>
                {entityInfo.icon} {entityInfo.category}
              </AccentBadge>
            </ThemeProvider>
            <NeutralBadge>{entityInfo.dataType}</NeutralBadge>
          </Stack>
        </Stack>
      </Table.DataCell>
      <Table.DataCell>
        {method !== 'DELETE' && (
          <SmallActionsButton ref={menuButtonRef} onClick={() => setDataMenuOpen(true)}>
            <IconMoreHorizontal />
          </SmallActionsButton>
        )}
      </Table.DataCell>

      <EntityActionsPopper
        open={dataItemMenuOpen}
        anchorEl={menuButtonRef.current}
        onClickAway={() => setDataMenuOpen(false)}
      >
        <EntityActionsItem
          isDisabled={loading}
          text="Перейти к редактированию"
          icon={IconEditFilled}
          onClick={handleEditEntity}
        />
        <EntityActionsItem
          isDisabled={loading}
          text="Перейти к удалению"
          icon={IconDeleteFilled}
          onClick={handleOpenDeleteDialog}
        />
      </EntityActionsPopper>
    </Table.Row>
  );
};

export default ChangeHistoryManualItem;
