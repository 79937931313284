/* eslint-disable max-len */
import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconCivilClubsCommon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.398 23v-2.6c0-1.9 1.1-3.6 2.6-4.6-.7-.7-1.1-1.7-1.1-2.8 0-2.2 1.8-4 4.1-4s4.1 1.8 4.1 4.1c0 1.1-.4 2-1.1 2.8 1.5 1 2.5 2.6 2.6 4.6v2.6h-11.2V23Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M17.4 13.1c0 .9-.2 1.8-.6 2.5.4.4.8.9 1.1 1.4H24v-2.6c-.1-1.9-1.1-3.6-2.6-4.6.7-.7 1.1-1.7 1.1-2.8 0-2.3-1.8-4.1-4.1-4.1S14.3 4.7 14.3 7c0 .5.1.9.2 1.3 1.7 1 2.9 2.7 2.9 4.8ZM6.6 13.1c0-2 1.2-3.8 2.8-4.7.2-.4.3-.9.3-1.3C9.7 4.8 7.8 3 5.6 3 3.3 3 1.5 4.8 1.5 7.1c0 1.1.4 2 1.1 2.8-1.5.9-2.6 2.6-2.6 4.5V17h6.1c.3-.5.7-1 1.1-1.4-.4-.8-.6-1.6-.6-2.5Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconCivilClubsCommon;
