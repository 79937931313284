import {
  GET_ADMIN_SETTINGS,
  GET_INDEPENDENT_DIAGNOSTICS,
  GET_INDEPENDENT_DIAGNOSTICS_GENERAL_RATING,
  GET_INDEPENDENT_DIAGNOSTICS_RATING,
  GET_PERSONS_OBJECTS,
  GET_STUDENT_AFFILATIONS,
  GET_STUDENT_CHECKIN_HISTORY,
  GET_STUDENT_CLASS_RECOMMENDATIONS,
  GET_STUDENT_CULTURE_VISITINGS,
  GET_STUDENT_EIS_DOP_RECOMMENDATIONS,
  GET_STUDENT_EMPLOYMENTS,
  GET_STUDENT_EVENTS,
  GET_STUDENT_GIA_LIST,
  GET_STUDENT_GIA_WORLDSKILLS,
  GET_STUDENT_INTERESTS,
  GET_STUDENT_JOBS,
  GET_STUDENT_META_SKILLS,
  GET_STUDENT_PROFESSIONAL_ADDITIONAL_EDUCATION,
  GET_STUDENT_PROJECTS,
  GET_STUDENT_RECOMMENDATIONS,
  GET_STUDENT_REWARDS,
  GET_STUDENT_SPO_DOCUMENTS,
  GET_STUDENT_SPO_EDUCATION,
  GET_STUDENT_SPO_STATUS,
  GET_STUDENT_SPORT_REWARDS,
  GET_USER_SETTINGS,
  getAffilationKindsActions,
  getArchivedInterestObjectKindsActions,
  getCinemasAction,
  getCreationKindsAction,
  getCulturalKindsAction,
  getDisciplinesAction,
  getDocumentRefActions,
  getEventKindsActions,
  getIndependentDiagnosticsActions,
  getIndependentDiagnosticsGeneralRatingActions,
  getIndependentDiagnosticsRatingActions,
  getInterestActionKindsActions,
  getInterestGroupKindsActions,
  getInterestHeadKindsActions,
  getInterestObjectKindsActions,
  getMuseumsAction,
  getOlympiadLevelsActions,
  getOlympiadSubjectsRefActions,
  getOlympiadTypesActions,
  getOrganizationsAction,
  getPortfolioSubcategoriesActions,
  getProfessionProgrammRefActions,
  getRewardKindsAction,
  getSectionRefActions,
  getSettingSectionsActions,
  getSportAgesRefActions,
  getSportClubsAction,
  getSportKindsAction,
  getSportRewardKindsAction,
  getSportTrainingStageAction,
  getStudentAffilationsActions,
  getStudentCheckInHistoryActions,
  getStudentCultureVisitingsActions,
  getStudentEmploymentsActions,
  getStudentEventsActions,
  getStudentGiaListActions,
  getStudentGiaWorldSkillsActions,
  getStudentInterestsActions,
  getStudentJobsActions,
  getStudentMetaSkillsActions,
  getStudentPersonalAccessActions,
  getStudentProfessionalAdditionalEducationActions,
  getStudentProjectsActions,
  getStudentRecommendationsActions,
  getStudentRewardsActions,
  getStudentSpoDocumentsActions,
  getStudentSpoEducationActions,
  getStudentSportRewardsActions,
  getStudentSpoStatusActions,
  getTheatresAction,
  getTourismKindsAction,
  getViewedFunctionsActions,
  INVALIDATE_PARALLEL_CURRICULA,
} from 'actions';
import { action, INVALIDATE } from 'actions/utils';
import { MAX_PAGE_SIZE } from 'api';
import { RequestCacheStatus } from 'api/types';
import { DiagnosticCountCode } from 'const';
import { IDataRequest } from 'hooks/dataLoad/useDataLoad';
import { performanceModel } from 'portfolio3/features/performance';
import { performanceOldModel } from 'portfolio3/features/performanceOld';
import { proforientationModel } from 'portfolio3/features/proforientation';
import { vuzRecommendationModel } from 'portfolio3/features/vuzRecommendation';
import { IRootState } from 'reducers';
import store from 'store';

const performanceActions = performanceModel.actions;
const performanceOldActions = performanceOldModel.actions;
const vuzActions = vuzRecommendationModel.actions;
const proforientationActions = proforientationModel.actions;

const maxSizeParams = { size: MAX_PAGE_SIZE };

export const getStateRequestCacheStatus = (
  state: IRootState,
  key: keyof IRootState,
): RequestCacheStatus | undefined => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (state as any)[key]?.requestCache;
};

export const shouldLoadCache = (state: IRootState, key: keyof IRootState) => {
  const requestStatuses: RequestCacheStatus[] = ['notCached', 'invalidated'];
  const status = getStateRequestCacheStatus(state, key);
  return !status || requestStatuses.includes(status);
};

export const invalidateStudentData = () => {
  const clearRequestActionTypes = [
    GET_STUDENT_AFFILATIONS,
    GET_STUDENT_EMPLOYMENTS,
    GET_STUDENT_EVENTS,
    GET_STUDENT_PROJECTS,
    GET_STUDENT_REWARDS,
    GET_STUDENT_SPORT_REWARDS,
    GET_STUDENT_GIA_WORLDSKILLS,
    GET_STUDENT_GIA_LIST,
    GET_STUDENT_INTERESTS,
    GET_STUDENT_RECOMMENDATIONS,
    GET_STUDENT_CLASS_RECOMMENDATIONS,
    GET_STUDENT_EIS_DOP_RECOMMENDATIONS,
    GET_STUDENT_PROFESSIONAL_ADDITIONAL_EDUCATION,
    GET_STUDENT_JOBS,
    GET_STUDENT_META_SKILLS,
    GET_STUDENT_SPO_EDUCATION,
    GET_STUDENT_SPO_DOCUMENTS,
    GET_STUDENT_SPO_STATUS,
    GET_STUDENT_CHECKIN_HISTORY,
    GET_STUDENT_CULTURE_VISITINGS,
    GET_PERSONS_OBJECTS,
    GET_USER_SETTINGS,
    GET_ADMIN_SETTINGS,
    GET_INDEPENDENT_DIAGNOSTICS,
    GET_INDEPENDENT_DIAGNOSTICS_GENERAL_RATING,
    GET_INDEPENDENT_DIAGNOSTICS_RATING,
    performanceActions.GET_STUDENT_FINAL_GRADES,
    performanceActions.GET_STUDENT_AVERAGE_GRADES,
    performanceActions.GET_STUDENT_SUBJECT_THEMES,
    performanceOldActions.GET_STUDENT_PERFORMANCE_OLD,
    performanceOldActions.GET_STUDENT_PERFORMANCE_LESSONS_OLD,
    vuzActions.GET_FAVORITE_VUZ_LIST,
    proforientationActions.GET_PROFORIENTATION,
  ];

  clearRequestActionTypes.forEach((actionType) => {
    const invalidateAction = getInvalidateRequestCacheActionType(actionType);
    store.dispatch(invalidateAction);
  });

  store.dispatch(action(INVALIDATE_PARALLEL_CURRICULA));
  store.dispatch(action(performanceActions.INVALIDATE_STUDENT_SUBJECT_THEMES_CACHE));
};

export const getDataLoadRequests = () => {
  const state: IRootState = store.getState();

  const { currentStudent } = state;
  const { meshId, classLevel, schoolId } = currentStudent;

  const a = getCommonActions(meshId, classLevel, schoolId);
  const commonRequests = getCommonRequests(a);
  const refRequests = getCommonReferenceRequests();

  return {
    /* Widgets */
    // Profile
    profileInterests: [requestFactory('studentInterests', a.interests)],
    profileInterestRecommendations: [requestFactory('studentRecommendations', a.recommendations)],
    // Study
    studyGia: [requestFactory('studentGiaList', a.giaList)],
    studySpoEducation: [requestFactory('studentSpoEducation', a.spoEducation)],
    studySpoDocuments: [requestFactory('studentSpoDocuments', a.spoDocuments)],
    studyOlympiads: commonRequests.eventsWidget,
    // только для блока рейтинга
    studyDiagnosticsOnlyRating: [
      requestFactory(
        'independentDiagnosticsGeneralRating',
        getIndependentDiagnosticsGeneralRatingActions.request(meshId),
      ),
    ],
    // только для блока со списком
    studyDiagnosticsOnlyList: [
      requestFactory('independentDiagnosticsRating', getIndependentDiagnosticsRatingActions.request(meshId)),
    ],
    // TODO эти 2 independentDiagnostics запроса будут блокировать друг друга
    // TODO возможно нужна инвализация этого ключа при переходе в настройки и обратно
    // для блока рейтинга и списка
    studyDiagnosticsRatingAndList: [
      requestFactory(
        'independentDiagnostics',
        getIndependentDiagnosticsActions.request(meshId, DiagnosticCountCode.visible),
      ),
    ],
    studyDiagnosticsRatingAndListSettingsMode: [
      requestFactory(
        'independentDiagnostics',
        getIndependentDiagnosticsActions.request(meshId, DiagnosticCountCode.all),
      ),
    ],
    // Science
    scienceRewards: [
      requestFactory('studentRewards', a.rewards),
      requestFactory('studentEvents', a.events),
      requestFactory('studentProjects', a.projects),
    ],
    scienceProjects: [requestFactory('studentProjects', a.projects), requestFactory('studentRewards', a.rewards)],
    scienceOccupations: [commonRequests.employments],
    scienceContests: commonRequests.eventsWidget,
    // Sport
    sportRewards: [requestFactory('studentSportRewards', a.sportRewards), requestFactory('studentEvents', a.events)],
    sportClubs: [commonRequests.affilations],
    sportSections: [commonRequests.employments],
    sportCompetitions: [
      requestFactory('studentEvents', a.events),
      requestFactory('studentSportRewards', a.sportRewards),
    ],
    sportExpeditions: [
      requestFactory('studentEvents', a.events),
      requestFactory('studentSportRewards', a.sportRewards),
    ],
    // Creation
    creationRewards: commonRequests.rewardsWidget,
    creationClubs: [commonRequests.affilations],
    creationContests: commonRequests.eventsWidget,
    creationSections: [commonRequests.employments],
    // Culture
    cultureOfflineVisitings: [
      requestFactory('studentEvents', a.events),
      requestFactory('studentCheckInHistory', a.checkInHistory),
      requestFactory('cultureVisitings', a.cultureVisitings),
    ],
    cultureOnlineVisitings: [requestFactory('studentEvents', a.events)],
    // Civil
    civilRewards: commonRequests.rewardsWidget,
    civilClubs: [commonRequests.affilations],
    civilContests: commonRequests.eventsWidget,
    civilSections: [commonRequests.employments],
    // Profession
    professionRewards: commonRequests.rewardsWidget,
    professionJobs: [requestFactory('studentJobs', a.jobs)],
    professionMetaSkills: [requestFactory('studentMetaSkills', a.metaSkills)],
    professionEducation: [requestFactory('studentProfessionalAdditionalEducation', a.professionalAdditionalEducation)],
    professionEvents: commonRequests.eventsWidget,
    professionGiaWorldSkills: [requestFactory('studentGiaWorldSkills', a.giaWorldSkills)],
    professionProforientation: [
      requestFactory('proforientation', proforientationActions.getProforientationActions.request(meshId)),
    ],
    // Recommendation
    recommendationVuz: [
      requestFactory('egeExamSubjectsRef', vuzActions.getEgeExamSubjectsRefActions.request()),
      requestFactory('specialitiesRef', vuzActions.getSpecialitiesRefActions.request()),
      requestFactory('studentGiaList', a.giaList),
      requestFactory('favoriteVuzList', vuzActions.getFavoriteVuzListActions.request(meshId)),
    ],

    /* Data entry form */
    dataEntryMain: [
      requestFactory(
        'eventKinds',
        getEventKindsActions.request({ ...maxSizeParams, sort: 'value', isArchive: 'false' }),
      ),
      requestFactory('tourismKinds', getTourismKindsAction.request(maxSizeParams)),
      requestFactory('affilationKinds', getAffilationKindsActions.request({ ...maxSizeParams, sort: 'value' })),
      requestFactory('sportClubs', getSportClubsAction.request(maxSizeParams)),
      refRequests.sportRewardKinds,
    ],
    dataEntryStudy: [
      requestFactory('olympiadSubjectsRef', getOlympiadSubjectsRefActions.request(maxSizeParams)),
      requestFactory('olympiadTypes', getOlympiadTypesActions.request(undefined, maxSizeParams)),
      requestFactory(
        'olympiadLevels',
        getOlympiadLevelsActions.request(undefined, { ...maxSizeParams, isArchive: 'false' }),
      ),
      refRequests.rewardKinds,
    ],
    dataEntryScience: [
      requestFactory('disciplines', getDisciplinesAction.request(maxSizeParams)),
      requestFactory('portfolioSubcategories', getPortfolioSubcategoriesActions.request(maxSizeParams)),
      requestFactory('olympiadTypes', getOlympiadTypesActions.request()),
      refRequests.rewardKinds,
    ],
    dataEntrySport: [
      requestFactory('sportKinds', getSportKindsAction.request({ ...maxSizeParams, sort: 'value', isArchive: 'fase' })),
      requestFactory('portfolioSubcategories', getPortfolioSubcategoriesActions.request(maxSizeParams)),
      requestFactory('olympiadTypes', getOlympiadTypesActions.request()),
      requestFactory('sportAgesRef', getSportAgesRefActions.request(maxSizeParams)),
      requestFactory('sportClubs', getSportClubsAction.request(maxSizeParams)),
      requestFactory('tourismKinds', getTourismKindsAction.request(maxSizeParams)),
      requestFactory('sportTrainingStage', getSportTrainingStageAction.request(maxSizeParams)),
      refRequests.rewardKinds,
      refRequests.sportRewardKinds,
    ],
    dataEntryCreation: [
      requestFactory('creationKinds', getCreationKindsAction.request(maxSizeParams)),
      requestFactory('portfolioSubcategories', getPortfolioSubcategoriesActions.request(maxSizeParams)),
      requestFactory('olympiadTypes', getOlympiadTypesActions.request()),
      requestFactory('affilationKinds', getAffilationKindsActions.request({ ...maxSizeParams, sort: 'value' })),
      refRequests.rewardKinds,
    ],
    dataEntryCulture: [
      requestFactory('olympiadTypes', getOlympiadTypesActions.request()),
      requestFactory('culturalKinds', getCulturalKindsAction.request(maxSizeParams)),
      requestFactory('museums', getMuseumsAction.request(maxSizeParams)),
      requestFactory('theatres', getTheatresAction.request(maxSizeParams)),
      requestFactory('cinemas', getCinemasAction.request(maxSizeParams)),
    ],
    dataEntryCivil: [
      requestFactory('portfolioSubcategories', getPortfolioSubcategoriesActions.request(maxSizeParams)),
      requestFactory('olympiadTypes', getOlympiadTypesActions.request()),
      requestFactory('affilationKinds', getAffilationKindsActions.request({ ...maxSizeParams, sort: 'value' })),
      refRequests.rewardKinds,
    ],
    dataEntryProfession: [requestFactory('olympiadTypes', getOlympiadTypesActions.request()), refRequests.rewardKinds],

    /* Search */
    searchEntryMethods: [
      commonRequests.events,
      commonRequests.projects,
      commonRequests.rewards,
      commonRequests.sportRewards,

      refRequests.sectionRef,
      refRequests.documentRef,
      refRequests.professionProgrammRef,
      refRequests.sportKinds,
      refRequests.tourismKinds,
    ],

    /* Performance */
    performanceEntryMethods: [
      requestFactory('studentFinalGrades', performanceModel.actions.getStudentFinalGradesActions.request(meshId)),
      requestFactory('studentAverageGrades', performanceModel.actions.getStudentAverageGradesActions.request(meshId)),
    ],
    performanceOldEntryMethods: [
      requestFactory(
        'studentPerformanceOld',
        performanceOldModel.actions.getStudentPerformanceOldActions.request(meshId),
      ),
    ],

    /* Other */
    viewedFunctions:
      /* ожидаем пока currentStudent.meshId установится */
      meshId ? [requestFactory('viewedFunctionsRequest', getViewedFunctionsActions.request(meshId))] : [],
    rewardsPage: [
      requestFactory('studentEvents', a.events),
      requestFactory('studentProjects', a.projects),
      requestFactory('studentRewards', a.rewards),
      requestFactory('studentSportRewards', a.sportRewards),
    ],
    sectionsRef: [requestFactory('sectionRef', getSectionRefActions.request(maxSizeParams))],
    settingSectionsRef: [requestFactory('settingSections', getSettingSectionsActions.request(maxSizeParams))],
    interestRefs: [
      requestFactory('interestHeadKinds', getInterestHeadKindsActions.request(maxSizeParams)),
      requestFactory('interestGroupKinds', getInterestGroupKindsActions.request({ ...maxSizeParams, sort: 'code' })),
      requestFactory('interestActionKinds', getInterestActionKindsActions.request(maxSizeParams)),
      requestFactory('interestObjectKinds', getInterestObjectKindsActions.request(maxSizeParams)),
    ],
    archivedInterestRefs: [
      requestFactory('interestHeadKinds', getInterestHeadKindsActions.request(maxSizeParams)),
      requestFactory('interestGroupKinds', getInterestGroupKindsActions.request({ ...maxSizeParams, sort: 'code' })),
      requestFactory('interestActionKinds', getInterestActionKindsActions.request(maxSizeParams)),
      requestFactory('archivedInterestObjectKinds', getArchivedInterestObjectKindsActions.request(maxSizeParams)),
    ],
    spoStatusSelector: [requestFactory('studentSpoStatus', getStudentSpoStatusActions.request(meshId))],
    studentPersonalAccess: [requestFactory('studentPersonalAccess', getStudentPersonalAccessActions.request(meshId))],
    studentSearcher: [requestFactory('organizations', getOrganizationsAction.request())],
    thanksTeacherDrawer: [requestFactory('olympiadSubjectsRef', getOlympiadSubjectsRefActions.request(maxSizeParams))],
    // для определения настроек аватаров
    // affilations - для получения информация о движении первых
    useAvatarSettings: [commonRequests.affilations],
  };
};

function getCommonRequests(commonActions: ReturnType<typeof getCommonActions>) {
  const rewardsWidget = [
    requestFactory('studentEvents', commonActions.events),
    requestFactory('studentRewards', commonActions.rewards),
  ];
  const eventsWidget = [
    requestFactory('studentEvents', commonActions.events),
    requestFactory('studentRewards', commonActions.rewards),
  ];

  const events = requestFactory('studentEvents', commonActions.events);
  const projects = requestFactory('studentProjects', commonActions.projects);
  const affilations = requestFactory('studentAffilations', commonActions.affilations);
  const employments = requestFactory('studentEmployments', commonActions.employments);
  const rewards = requestFactory('studentRewards', commonActions.rewards);
  const sportRewards = requestFactory('studentSportRewards', commonActions.sportRewards);

  return {
    rewardsWidget,
    eventsWidget,
    events,
    projects,
    affilations,
    employments,
    rewards,
    sportRewards,
  };
}

function getCommonReferenceRequests() {
  const sectionRef = requestFactory('sectionRef', getSectionRefActions.request(maxSizeParams));
  const documentRef = requestFactory('documentRef', getDocumentRefActions.request({ ...maxSizeParams, sort: 'code' }));
  const professionProgrammRef = requestFactory(
    'professionProgrammRef',
    getProfessionProgrammRefActions.request(maxSizeParams),
  );
  const sportKinds = requestFactory(
    'sportKinds',
    getSportKindsAction.request({ ...maxSizeParams, sort: 'value', isArchive: 'fase' }),
  );
  const tourismKinds = requestFactory('tourismKinds', getTourismKindsAction.request(maxSizeParams));
  const rewardKinds = requestFactory(
    'rewardKinds',
    getRewardKindsAction.request({ ...maxSizeParams, isArchive: 'false' }),
  );
  const sportRewardKinds = requestFactory(
    'sportRewardKinds',
    getSportRewardKindsAction.request({ ...maxSizeParams, isArchive: 'false' }),
  );

  return {
    sectionRef,
    documentRef,
    professionProgrammRef,
    sportKinds,
    tourismKinds,
    rewardKinds,
    sportRewardKinds,
  };
}

function getCommonActions(meshId: string, classLevel?: string, schoolId?: number) {
  const rewards = getStudentRewardsActions.request(meshId, maxSizeParams);
  const sportRewards = getStudentSportRewardsActions.request(meshId, maxSizeParams);
  const events = getStudentEventsActions.request(meshId, maxSizeParams);
  const projects = getStudentProjectsActions.request(meshId, maxSizeParams);
  const employments = getStudentEmploymentsActions.request(meshId, maxSizeParams);
  const affilations = getStudentAffilationsActions.request(meshId, maxSizeParams);
  const cultureVisitings = getStudentCultureVisitingsActions.request(meshId, maxSizeParams);
  const checkInHistory = getStudentCheckInHistoryActions.request(meshId);
  const interests = getStudentInterestsActions.request(meshId);
  const recommendations =
    classLevel && schoolId ? getStudentRecommendationsActions.request(meshId, { classLevel, schoolId }) : null;
  const giaList = getStudentGiaListActions.request(meshId);
  const spoEducation = getStudentSpoEducationActions.request(meshId);
  const spoDocuments = getStudentSpoDocumentsActions.request(meshId);
  const jobs = getStudentJobsActions.request(meshId);
  const metaSkills = getStudentMetaSkillsActions.request(meshId);
  const professionalAdditionalEducation = getStudentProfessionalAdditionalEducationActions.request(meshId);
  const giaWorldSkills = getStudentGiaWorldSkillsActions.request(meshId, maxSizeParams);

  return {
    rewards,
    sportRewards,
    events,
    projects,
    employments,
    affilations,
    cultureVisitings,
    checkInHistory,
    interests,
    recommendations,
    giaList,
    spoEducation,
    spoDocuments,
    jobs,
    metaSkills,
    professionalAdditionalEducation,
    giaWorldSkills,
  };
}

function requestFactory(stateCacheKey: keyof IRootState, action: unknown): IDataRequest {
  const dispatch = store.dispatch;

  return {
    getStatus: () => {
      const state: IRootState = store.getState();
      return getStateRequestCacheStatus(state, stateCacheKey);
    },
    action: () => {
      if (action) {
        dispatch(action);
      }
    },
  };
}

function getInvalidateRequestCacheActionType(requestActionTypes: Record<string, string>) {
  return action(requestActionTypes[INVALIDATE]);
}
