import { useCallback, useMemo } from 'react';

import { AvatarTypeRef } from 'const';
import { useAppSelector, useDataLoad } from 'hooks';
import { avatarTypeRefArchiveFalseSelector, hasFirstMovementEntitySelector } from 'selectors';
import { getDataLoadRequests } from 'utils';

const useAvatar = () => {
  useDataLoad({ shouldLoad: true, requests: getDataLoadRequests().useAvatarSettings });

  const currentStudent = useAppSelector((state) => state.currentStudent);
  const avatarTypeRef = useAppSelector(avatarTypeRefArchiveFalseSelector);
  const avatars = useAppSelector((state) => state.avatars);

  const hasFirstMovementEntity = useAppSelector(hasFirstMovementEntitySelector);

  // в дальнейшем добавлять проверки сюда
  const availableAvatarTypes = useMemo(() => {
    return avatarTypeRef.content.filter((ref) => {
      if (ref.code === AvatarTypeRef.default) return true;
      if (ref.code === AvatarTypeRef.firstMovement && hasFirstMovementEntity) return true;
      return false;
    });
  }, [avatarTypeRef.content, hasFirstMovementEntity]);

  const getAvatarSettings = useCallback(
    (personId: string) => {
      const avatar = avatars.get(personId);

      const refHasAvatarType = availableAvatarTypes.some((ref) => ref.code === avatar?.type);
      const avatarType = refHasAvatarType ? avatar?.type ?? AvatarTypeRef.default : AvatarTypeRef.default;

      return {
        avatarImage: avatar?.image ?? undefined,
        avatarType,
      };
    },
    [availableAvatarTypes, avatars],
  );

  const { avatarImage, avatarType } = getAvatarSettings(currentStudent.meshId);

  return {
    avatarImage,
    avatarType,
    availableAvatarTypes,
    getAvatarSettings,
  };
};

export default useAvatar;
