/* eslint-disable prettier/prettier */
/* eslint-disable */

import { useMediaQuery } from '@mui/material';
import React from 'react';

import { IDiagnosis } from '../../../../api/types';
import { DoughnutColors } from '../../../../const';
import { bestResultImage } from '../../../../images';
import DiagramDoughnut from '../../../common/DiagramDoughnut';
import DoughnutDiagnosticChart from '../../../common/doughnutDiagnosticChart';

import './index.scss';

interface SelfDiagnocticDoughnutStatsProps {
  studentDiagnosis: IDiagnosis;
  bestSubject?: string;
}

const SelfDiagnocticDoughnutStats: React.FC<SelfDiagnocticDoughnutStatsProps> = ({ studentDiagnosis, bestSubject }) => {
  const isMobile = useMediaQuery('(max-width: 600px)', { noSsr: true });
  const markColors = ['#FF8787', '#FFA94D', '#FFD43B', '#A9E34B'];
  const markData = {
    incomingData: studentDiagnosis?.groupMark ? Object.values(studentDiagnosis?.groupMark) : [],
    incomingLabels: ['от 0 до 25%', 'от 26% до 50%', 'от 51% до 75%', 'от 76% до 100%']
  };

  const bestResultData = {
    lesson: 'Алгебра',
    resultImage: bestResultImage
  };

  let valueProgressBar = 0;
  let chartColor = DoughnutColors.RED;
  const normalizedMarkValue = studentDiagnosis?.averageResult || 0;
  const normalizedMarkBasis = 100;
  const mark = normalizedMarkValue;

  if (normalizedMarkValue) {
    valueProgressBar = normalizedMarkBasis ? Number(normalizedMarkValue) * 100 / normalizedMarkBasis : Number(normalizedMarkValue);
    if (Number(valueProgressBar) >= 26) {
      chartColor = DoughnutColors.YELLOW;
    }
    if (Number(valueProgressBar) >= 75) {
      chartColor = DoughnutColors.GREEN;
    }
  }

  const doughnutData = {
    incomingData: [valueProgressBar, 100 - valueProgressBar],
    incomingLabels: ['1', '2']
  };

  return (
    <div className="self-diagnosis__doughnut-stats doughnut-stats diagnostic-gap">
      <h3 className="self-diagnosis__title self-diagnosis__title--mobile">Выполнение самодиагностик</h3>
      <div className="data-entity-diagnosis completed-diagnostics">
        <div className="doughnut-stats__wrapper">
          <h3 className="doughnut-stats__title">Пройденные диагностики</h3>
          <div className="doughnut-stats__item">
            <div className="doughnut-stats__diagram--circle">
              <DoughnutDiagnosticChart colors={markColors} data={markData} />
            </div>
            <div className="doughnut-stats__block">
              <div className="doughnut-stats__block-item">
                <div className="doughnut-stats__color-tip" style={{ backgroundColor: markColors[0] }} />
                <p className="doughnut-stats__rank">
                  попробуй еще
                  {!isMobile && <span className="doughnut-stats__rank-caption">{' '}(от 0 до 25%)</span>}
                </p>
              </div>
              <div className="doughnut-stats__block-item">
                <div className="doughnut-stats__color-tip" style={{ backgroundColor: markColors[1] }} />
                <p className="doughnut-stats__rank">
                  неплохо
                  {!isMobile && <span className="doughnut-stats__rank-caption">{' '}(от 26% до 50%)</span>}
                </p>
              </div>
              <div className="doughnut-stats__block-item">
                <div className="doughnut-stats__color-tip" style={{ backgroundColor: markColors[2] }} />
                <p className="doughnut-stats__rank">
                  хорошо
                  {!isMobile && <span className="doughnut-stats__rank-caption">{' '}(от 51% до 75%)</span>}
                </p>
              </div>
              <div className="doughnut-stats__block-item">
                <div className="doughnut-stats__color-tip" style={{ backgroundColor: markColors[3] }} />
                <p className="doughnut-stats__rank">
                  отлично
                  {!isMobile && <span className="doughnut-stats__rank-caption">{' '}(от 76% до 100%)</span>}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="data-entity-small">
        <div className="data-entity-diagnosis data-entity-diagnosis__percent">
          <div className="right-procent-text">
            <h3 className="doughnut-stats__lesson-title">Процент правильных ответов</h3>
            <span className="exam-doughnut__result diagosis-result">
              {normalizedMarkValue}
              %
            </span>
          </div>
          <div className="exam-doughnut right-procent-diagramm">
            <DiagramDoughnut total={mark !== null ? mark : '-'} incomingData={doughnutData.incomingData} incomingLabels={doughnutData.incomingLabels} color={chartColor} />
          </div>
        </div>
        <div
          className="data-entity-diagnosis data-entity-diagnosis-result data-entity-diagnosis__best-result"
          style={{ backgroundImage: !isMobile ? `url(${bestResultData.resultImage})` : undefined }}
        >
          <div className="data-entity-back">
            <h3 className="doughnut-stats__lesson-title">Лучший результат по предмету</h3>
            <span className="doughnut-stats__lesson">{bestSubject}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelfDiagnocticDoughnutStats;
