import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconOrganization: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="m3.17 6.021 7.9-4.16c.58-.31 1.28-.31 1.86 0l7.9 4.16c.41.21.67.64.67 1.1 0 .69-.56 1.25-1.24 1.25H3.75c-.69 0-1.25-.56-1.25-1.25 0-.46.26-.89.67-1.1Zm1.33 5.85v4c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5v-4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5Zm6 0v4c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5v-4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5Zm11 9c0 .83-.67 1.5-1.5 1.5H4c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5h16c.83 0 1.5.67 1.5 1.5Zm-5-9v4c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5v-4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconOrganization;
