import { FC, useContext } from 'react';

import FunnelBlock from 'components/common/FunnelBlock';
import { GroupedActionButton, GroupedActionButtonsContainer } from 'components/common/GroupedActionButtons';
import { SectionCodes } from 'const';
import { IconSave } from 'icons';
import { IconPlus } from 'icons/edit';
import UnderlinedTabs from 'portfolio3/components/common/UnderlinedTabs';
import { FormEntityInfo } from 'portfolio3/components/dataEntry';
import { DrawerHeader } from 'portfolio3/components/drawers';
import { IInnerFormProps } from 'portfolio3/features/dataEntryForm';
import { Drawer, IDrawerBase } from 'portfolio3/ui-kit/Drawer';
import { mergeSx } from 'utils';

import * as commonStyles from '../../commonStyles';
import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';
import PrimaryBlocks from '../PrimaryBlocks';
import SecondaryBlocks from '../SecondaryBlocks';

interface IStudentFormDesktopProps {
  title: string;
}

const StudentFormDesktop: FC<IStudentFormDesktopProps & IInnerFormProps> = ({
  title,

  accentColor,
  entityActions,
  isSubmitDisabled,
  entityProperties,
  tabs,
  relatedObjectType,
  relatedObjectName,
  onSubmit,
  onClose,
}) => {
  const { isOpen, formStep, formTab, onChangeFormTab, formData, image } = useContext(LocalDataEntryFormContext);

  const entityInfo = entityProperties?.entityInfo;
  const hasTabs = tabs && tabs.length > 0;

  const entityInfoElement = entityInfo && (
    <FormEntityInfo
      name={entityInfo.name ?? 'Другое'}
      attributes={entityInfo.attributes}
      actions={entityActions}
      relatedObjectType={relatedObjectType}
      relatedObjectName={relatedObjectName}
      sx={{ marginTop: '16px' }}
    />
  );

  const tabsElement = hasTabs && (
    <UnderlinedTabs value={formTab} onChange={onChangeFormTab} sx={{ marginTop: '24px' }}>
      {tabs.map((tab) => (
        <UnderlinedTabs.Button key={tab.value} value={tab.value} content={tab.title} />
      ))}
    </UnderlinedTabs>
  );

  const header: IDrawerBase['header'] = (crossButton) => (
    <DrawerHeader
      title={title}
      backgroundImage={image ?? null}
      removeBottomPadding={hasTabs && formStep !== 'primary'}
      crossButton={crossButton}
      accentColor={accentColor}
    >
      {/* рендер primary контролов */}
      {formStep === 'primary' && (
        <FunnelBlock sx={mergeSx(commonStyles.formControlsColumn, { marginTop: '16px' })}>
          <PrimaryBlocks />
        </FunnelBlock>
      )}

      {/* данные о сущности */}
      {entityInfo && formStep !== 'primary' && entityInfoElement}

      {/* табы */}
      {hasTabs && formStep !== 'primary' && tabsElement}
    </DrawerHeader>
  );

  const isSaveMode = formData.id || formData.typeCode === SectionCodes.professionSpoMetaskills;

  const studentFooter = (
    <GroupedActionButtonsContainer disableAbsolutePosition>
      <GroupedActionButton
        variant="primary"
        startIcon={isSaveMode ? IconSave : IconPlus}
        handleClick={onSubmit}
        disabled={isSubmitDisabled}
      >
        {isSaveMode ? 'Сохранить' : 'Добавить в портфолио'}
      </GroupedActionButton>
    </GroupedActionButtonsContainer>
  );

  return (
    <Drawer
      open={isOpen}
      isMobile={false}
      anchor="right"
      header={header}
      footer={formStep !== 'primary' && studentFooter}
      sx={commonStyles.drawer}
      onClose={onClose}
    >
      <SecondaryBlocks />
    </Drawer>
  );
};

export default StudentFormDesktop;
