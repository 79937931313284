/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';

import {
  FAILURE,
  INVALIDATE,
  REQUEST,
  SUCCESS
} from '../../actions/utils';

import genericReducers from '../genericReducer';
import { IReducers } from '..';
import { ICommonResponse, IStudentSportReward } from '../../api/types';
import { GET_STUDENT_SPORT_REWARDS } from '../../actions';

export type StudentSportRewardsState = ICommonResponse<IStudentSportReward>;

const initialState = {
  content: [],
  loading: false
} as ICommonResponse<IStudentSportReward>;

const studentSportRewards: IReducers<StudentSportRewardsState> = {
  [GET_STUDENT_SPORT_REWARDS[REQUEST]]: genericReducers().get.request,
  [GET_STUDENT_SPORT_REWARDS[SUCCESS]]: genericReducers().get.success,
  [GET_STUDENT_SPORT_REWARDS[FAILURE]]: genericReducers().get.failure,
  [GET_STUDENT_SPORT_REWARDS[INVALIDATE]]: genericReducers().get.invalidate(initialState),
};

export default connectReducers(initialState, studentSportRewards);
