import { FC, useContext, useEffect, useState } from 'react';

import { ICommonResponse, IDictionaryItem } from 'api/types';
import { MetaSkillsCodeToIcon } from 'const';

import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';
import { SecondaryBlockContainer } from '../common';
import { checkArrayIncludesCode, getCodesArrayFromDictionaryArray, removeItemsById } from './helpers';
import MetaskillsContainerRoot from './MetaskillsContainer';

interface IProfessionMetaskillsSecondaryBlockProps {
  metaskillRef: ICommonResponse<IDictionaryItem>;
  studentMetaSkills: ICommonResponse<IDictionaryItem>;
}

export interface IMetaskillsController {
  handleSelectBadge: (skill: IDictionaryItem) => void;
  handleCheckContainer: (skill: IDictionaryItem) => void;
}

const ProfessionMetaskillsSecondaryBlock: FC<IProfessionMetaskillsSecondaryBlockProps> = ({
  metaskillRef,
  studentMetaSkills,
}) => {
  const metaskills = metaskillRef.content;
  const studentMetaskills = studentMetaSkills.content;

  const { onChangeFormData } = useContext(LocalDataEntryFormContext);

  const [selectedBadgesItem, setSelectedBadgesItem] = useState<IDictionaryItem[]>([]);
  const [checkedContainersItem, setCheckedContainersItem] = useState<IDictionaryItem[]>([]);

  useEffect(() => {
    studentMetaskills.forEach((skill) => {
      if (typeof skill.parentId === 'number') {
        setSelectedBadgesItem((prevState) => [...prevState, skill]);
      } else {
        setCheckedContainersItem((prevState) => [...prevState, skill]);
      }
    });
  }, [studentMetaskills]);

  useEffect(() => {
    onChangeFormData((prevState) => ({
      ...prevState,
      spoMetaskills: [...getCodesArrayFromDictionaryArray([...checkedContainersItem, ...selectedBadgesItem])],
    }));
  }, [selectedBadgesItem, onChangeFormData, checkedContainersItem]);

  function handleChangeCodesArray(
    skill: IDictionaryItem,
    array: IDictionaryItem[],
    handler: (codesArray: IDictionaryItem[]) => void,
  ) {
    if (checkArrayIncludesCode(array, skill.code)) {
      if (typeof skill.parentId !== 'number') {
        setSelectedBadgesItem((prevState) => removeItemsById(prevState, skill.code));
      }
      handler(array.filter((item) => item.code !== skill.code));
    } else {
      handler([...array, skill]);
    }
  }

  const metaskillsController: IMetaskillsController = {
    handleSelectBadge: (skill) => {
      handleChangeCodesArray(skill, selectedBadgesItem, setSelectedBadgesItem);
    },
    handleCheckContainer: (skill) => {
      handleChangeCodesArray(skill, checkedContainersItem, setCheckedContainersItem);
    },
  };

  const rootMetaSkills = metaskills.filter((skill) => typeof skill.parentId !== 'number');

  return (
    <SecondaryBlockContainer>
      <SecondaryBlockContainer.GeneralTab>
        {rootMetaSkills.map((rootSkill) => {
          const childSkills = metaskills.filter((skill) => skill.parentId === rootSkill.code);

          const isContainerChecked = checkedContainersItem.some((item) => item.code === rootSkill.code);
          const Icon = MetaSkillsCodeToIcon[rootSkill.code];
          const IconElement = Icon ? <Icon /> : undefined;

          return (
            <MetaskillsContainerRoot key={rootSkill.code}>
              <MetaskillsContainerRoot.Header
                checked={isContainerChecked}
                handleCheckContainer={metaskillsController.handleCheckContainer}
                skill={rootSkill}
                icon={IconElement}
              >
                {rootSkill.value}
              </MetaskillsContainerRoot.Header>
              {isContainerChecked && (
                <MetaskillsContainerRoot.Body
                  handleSelectBadge={metaskillsController.handleSelectBadge}
                  metaskills={childSkills}
                  selectedMetaskills={getCodesArrayFromDictionaryArray(selectedBadgesItem)}
                />
              )}
            </MetaskillsContainerRoot>
          );
        })}
      </SecondaryBlockContainer.GeneralTab>
    </SecondaryBlockContainer>
  );
};

export default ProfessionMetaskillsSecondaryBlock;
