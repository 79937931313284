import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { mergeSx } from 'utils';

import InterestBadge from './InterestBadge';
import * as styles from './styles';

interface IInterestCardProps {
  name: string;
  actions: string[];
  image: string;
}

const InterestCard: FC<IInterestCardProps> = ({ name, actions, image }) => {
  const maxVisibleActions = 3;
  const visibleActions = actions.slice(0, maxVisibleActions);
  const hiddenActions = actions.slice(maxVisibleActions);
  const hiddenActionsCount = hiddenActions.length;

  return (
    <Box
      className="interest-card pdf-keep"
      // backgroundPositionY: 'bottom' нужен для корректного отображения в пдф
      sx={mergeSx(styles.root, { backgroundImage: `url(${image})`, backgroundPositionY: 'bottom' })}
    >
      <Typography variant="Paragraph LG/Semi Bold" textAlign="center">
        {name}
      </Typography>

      <Box className="interest-card__actions" sx={styles.actions}>
        {visibleActions.map((action) => (
          <InterestBadge key={action}>{action}</InterestBadge>
        ))}
        {hiddenActionsCount > 0 && <InterestBadge>ещё +{hiddenActionsCount}</InterestBadge>}
      </Box>
    </Box>
  );
};

export default InterestCard;
