/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useState, useRef } from 'react';

import { FormControl, Typography } from '@mui/material';

import { IDictionaryItem } from '../../../api/types';
import { MultipleSearchSelect } from '../../common/Select';
import { getSettingActivity } from './utils';
import { IVisibilityChildSetting } from '../../../containers/admin/VisibilitySettings/types';
import ReasonMessagePopper from './reasonMessagePopper';

import './index.scss';
import Toggle from 'portfolio3/ui-kit/Toggle';

interface ISecondaryChildSetting {
  secondaryChildSetting: IVisibilityChildSetting,
  parallelOptions: IDictionaryItem[],
  onChangeSecondaryChildSetting: (childSetting: IVisibilityChildSetting, comment?: string, searchString?: string, isActivityUnchanged?: boolean) => void,
}

const SecondaryChildSetting: React.FC<ISecondaryChildSetting> = ({
  secondaryChildSetting,
  parallelOptions,
  onChangeSecondaryChildSetting
}) => {
  const switchRef = useRef<HTMLDivElement>(null);
  const [isSecondaryReasonPopperOpen, setSecondaryReasonPopperOpen] = useState(false);
  const [parallelsValue, setParallelsValue] = useState(secondaryChildSetting.selectValue);

  const parallelController = {
    handleChange: (searchString: string) => {
      if (parallelsValue !== searchString) {
        setParallelsValue(searchString);
      }
    }
  };

  const handleSubmitSecondaryChildMessagePopper = (comment?: string) => {
    setSecondaryReasonPopperOpen(false);
    onChangeSecondaryChildSetting(secondaryChildSetting, comment);
  };

  const handleCloseParallelsSelect = () => {
    onChangeSecondaryChildSetting(secondaryChildSetting, undefined, parallelsValue, true);
  };

  return (
    <div className="setting-block__child setting-block__child--type-secondary" key={secondaryChildSetting.name}>
      <Typography variant="Paragraph MD/Regular">{secondaryChildSetting.name}</Typography>
      <div className="setting-block__right-column">
        <div
          className="setting-block__status-container"
          ref={switchRef}
        >
          <Toggle
            className="setting-block__switch"
            onChange={(e) => { if (secondaryChildSetting.isActive) setSecondaryReasonPopperOpen(true); else onChangeSecondaryChildSetting(secondaryChildSetting); }}
            checked={secondaryChildSetting.isActive}
          />
          <Typography variant="Paragraph MD/Regular">{getSettingActivity(secondaryChildSetting)}</Typography>
        </div>
        <FormControl className={`setting-block__control ${!secondaryChildSetting.isActive ? 'setting-block__control--disabled' : ''}`}>
          <MultipleSearchSelect
            searchController={parallelController}
            value={secondaryChildSetting.selectValue}
            options={parallelOptions}
            isNotClearable
            label="параллели"
            cutOutValue="параллель"
            placeholder="Выберите параллель ..."
            onClose={handleCloseParallelsSelect}
          />
        </FormControl>
      </div>
      <ReasonMessagePopper
        anchorElement={switchRef.current}
        isOpen={isSecondaryReasonPopperOpen}
        onClose={() => setSecondaryReasonPopperOpen(false)}
        onSubmit={handleSubmitSecondaryChildMessagePopper}
      />
    </div>
  );
};

export default SecondaryChildSetting;
