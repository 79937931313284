import { FC, forwardRef, ReactNode } from 'react';

import { InputLabel, InputLabelProps, styled } from '@mui/material';

import { Colors } from '../../../style/variables';
import { InputRenderMode, InputTokens } from '../../utils';

interface IFormInputLabelProps extends InputLabelProps {
  renderMode?: InputRenderMode;
  endAdornment?: ReactNode;
}

const withRef = forwardRef<HTMLLabelElement, InputLabelProps>((props, ref) => {
  return <InputLabel {...props} ref={ref} />;
});

withRef.displayName = 'FormInputLabel';

const StyledFormInputLabel = styled(withRef)(({ renderMode = 'desktop' }: IFormInputLabelProps) => {
  const tokens = InputTokens[renderMode];

  const isMobile = renderMode === 'mobile';
  const position = isMobile ? 'absolute' : 'static';
  const top = isMobile ? '10px' : 0;
  const transform = isMobile ? `translate(${tokens.paddingLeft}px, ${tokens.paddingBlock}px)` : 'none';
  const focusedTransform = isMobile
    ? `translate(${tokens.paddingLeft - 3}px, -${tokens.fontSize / 2}px) scale(0.75)`
    : 'none';

  const color = isMobile ? Colors['note-text'] : Colors['main-text'];

  return {
    zIndex: 1,
    position,
    top,
    transform,

    display: 'flex',
    alignItems: 'center',

    color,
    fontSize: '16px',
    lineHeight: '24px',

    '&.Mui-focused, &.MuiFormLabel-filled': !isMobile
      ? {}
      : {
          transform: focusedTransform,
          fontWeight: 500,

          paddingInline: 6,
          backgroundColor: '#ffffff',
        },

    '& .asterisk': {
      width: '12px',
      height: '12px',
      verticalAlign: 'top',
    },

    [`& .MuiInputLabel-asterisk`]: {
      color: Colors['red-rose'],
      fontSize: '20px',
      lineHeight: '16px',
    },

    '& .form-input-label__end-adornment': {
      marginLeft: 8,
    },
  };
});

const FormInputLabel: FC<IFormInputLabelProps> = ({ children, endAdornment, ...props }) => {
  const isMobile = props.renderMode === 'mobile';

  return (
    <StyledFormInputLabel shrink={!isMobile || props.focused} {...props}>
      {children}
      {endAdornment && <span className="form-input-label__end-adornment">{endAdornment}</span>}
    </StyledFormInputLabel>
  );
};

export default FormInputLabel;
