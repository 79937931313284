import { useMemo } from 'react';

import { SectionCodes } from 'const';
import { useUserFunctionality } from 'hooks';
import { DataEntryFormTab, IPortfolioEntryFormData } from 'portfolio3/features/dataEntryForm';
import { ITab } from 'types';

interface IProps {
  formData: IPortfolioEntryFormData;
  isMobile: boolean;
}

/**
 * Хук возвращает массивов табов исходя из обрабатываемой сущности
 */
const useTabs = ({ formData, isMobile }: IProps): ITab[] => {
  const { isParent, isStudent } = useUserFunctionality();

  const tabsMemo = useMemo(() => {
    const linkedObjectsLongName = isParent || isStudent ? 'Связанные достижения' : 'Связанные объекты';

    const generalTab: ITab = {
      title: isMobile ? 'Общее' : 'Общие сведения',
      value: DataEntryFormTab.GENERAL,
    };
    const filesTab: ITab = {
      title: isMobile ? 'Файлы' : 'Файлы и вложения',
      value: DataEntryFormTab.FILES,
    };
    const linkedTab: ITab = {
      title: isMobile ? 'Связанные' : linkedObjectsLongName,
      value: DataEntryFormTab.LINKED,
    };

    let tabs: ITab[] | null = [];
    const typeCode = formData.typeCode;

    const omitLinkedTypeCodes = [
      SectionCodes.studySpoTraining,
      SectionCodes.studySpoPractice,
      SectionCodes.studySpoDocuments,
      SectionCodes.professionExamGia,
      SectionCodes.professionSpoJob,
    ];

    const emptyTabsTypeCodes = [SectionCodes.professionSpoJob, SectionCodes.professionSpoMetaskills];

    if (typeCode && omitLinkedTypeCodes.includes(typeCode)) {
      tabs.push(generalTab, filesTab);
    }

    if (typeCode && emptyTabsTypeCodes.includes(typeCode)) {
      tabs = null;
    }

    if (tabs?.length === 0) {
      tabs.push(generalTab, filesTab, linkedTab);
    }

    return tabs ?? [];
  }, [formData.typeCode, isMobile, isParent, isStudent]);

  return tabsMemo;
};

export default useTabs;
