import { FC, useContext } from 'react';

import { Box } from '@mui/material';
import { SxStyles } from 'types';
import { mergeSx, numberToPixelsString } from 'utils';

import { paddingInlineLarge, paddingInlineSmall, paddingInlineSmallBackground } from '../const';
import { WidgetContainerContext } from '../context/widgetContainerContext';
import { IWidgetContainerPaddingProps } from '../types';

const styles = (withBackground: boolean, disableRightPadding?: boolean): SxStyles => {
  const paddingProperty = disableRightPadding ? 'paddingLeft' : 'paddingInline';

  return (theme) => ({
    [paddingProperty]: numberToPixelsString(paddingInlineLarge),

    [theme.breakpoints.down('commonSm')]: {
      [paddingProperty]: numberToPixelsString(withBackground ? paddingInlineSmallBackground : paddingInlineSmall),
    },
  });
};

const WidgetContainerPadding: FC<IWidgetContainerPaddingProps> = ({ sx, disableRightPadding, children }) => {
  const { withBackground } = useContext(WidgetContainerContext);

  return (
    <Box className="widget-container-padding" sx={mergeSx(styles(withBackground, disableRightPadding), sx)}>
      {children}
    </Box>
  );
};

export default WidgetContainerPadding;
