import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root = (isDisabled: boolean): SxStyles => {
  const arrowSize = '20px';

  return {
    display: 'flex',
    alignItems: 'center',
    width: arrowSize,
    height: arrowSize,

    cursor: isDisabled ? 'default' : 'pointer',

    '&&& svg': {
      width: arrowSize,
      height: arrowSize,
      color: isDisabled ? NeutralColors.light_neutrals_500 : NeutralColors.light_neutrals_700,
    },
  };
};
