import { SxStyles } from 'types';
import { numberToPixelsString } from 'utils';

import { paddingInlineSmall, paddingInlineSmallBackground } from '../const';

export const rootSpacing: SxStyles = {
  '& > * ~ *': {
    marginTop: '12px',
  },
};

// Контейнер без фона использует только вертикальные паддинги
export const rootPaddings: SxStyles = (theme) => {
  return {
    paddingBlock: '20px',
    paddingBottom: '16px',

    [theme.breakpoints.down('commonSm')]: {
      paddingBlock: numberToPixelsString(paddingInlineSmall),
    },
  };
};

// Если есть фон, то на контейнере используются все паддинги
export const rootPaddingsBackground: SxStyles = (theme) => {
  return {
    padding: '12px',

    [theme.breakpoints.down('commonSm')]: {
      padding: numberToPixelsString(paddingInlineSmallBackground),
    },
  };
};

export const elementsInlinePadding: SxStyles = (theme) => {
  return {
    paddingInline: '20px',

    [theme.breakpoints.down('commonSm')]: {
      paddingInline: numberToPixelsString(paddingInlineSmall),
    },
  };
};

export const elementsInlinePaddingBackground: SxStyles = (theme) => {
  return {
    paddingInline: '16px',

    [theme.breakpoints.down('commonSm')]: {
      paddingInline: '12px',
    },
  };
};

export const overflowContainer: SxStyles = {
  overflowX: 'auto',
};

export const overflowElement: SxStyles = {
  minWidth: 'max-content',
};
