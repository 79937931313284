import { createContext } from 'react';

import { SetFilterFunction } from '../types';
import { VuzLocalFilters } from './../types/index';

interface IVuzLocalFiltersContext {
  localFilters: VuzLocalFilters;
  setLocalFilter: SetFilterFunction<VuzLocalFilters>;
  onApplyFilters: ((filters: VuzLocalFilters) => void) | null;
}

export const VuzLocalFiltersContext = createContext({} as IVuzLocalFiltersContext);
