import { resetButtonStyles } from 'portfolio3/ui-kit/utils';
import { SxStyles } from 'types';
import { inheritSx } from 'utils';

export const root: SxStyles = (theme) => {
  return {
    ...inheritSx([resetButtonStyles]),

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '12px',

    width: '100%',

    cursor: 'pointer',
    userSelect: 'none',

    '& svg': {
      width: '24px',
      height: '24px',
    },

    '&:disabled': {
      pointerEvents: 'none',
      touchAction: 'none',
    },

    '&:not(:last-child)': {
      borderRight: '1px solid',
    },

    [theme.breakpoints.down('commonSm')]: {
      '& svg': {
        width: '20px',
        height: '20px',
      },
    },
  };
};
