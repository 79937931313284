import { FC, PropsWithChildren, ReactNode } from 'react';

import { Box } from '@mui/material';
import { useBrightTheme } from 'hooks';
import { AccentColorType, getAccentColor, NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';
import { mergeSx } from 'utils';

import DrawerHeaderTitle from '../DrawerHeaderTitle';

interface IDrawerHeaderProps {
  title: string | ReactNode;
  backgroundImage: string | null;
  accentColor?: AccentColorType;
  overrideBackgroundColor?: string;
  overrideBorderColor?: string;
  removeBottomPadding?: boolean;
  crossButton?: ReactNode;
}

const root: SxStyles = {
  padding: '24px',
  borderBottom: '1px solid',
};

const backgroundImageStyles = (imageUrl: string | null): SxStyles => {
  if (!imageUrl) return null;

  return {
    backgroundImage: `url(${imageUrl})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 52px top',
  };
};

const DrawerHeader: FC<IDrawerHeaderProps & PropsWithChildren> = ({
  title,
  backgroundImage,
  accentColor = 'indigo',
  overrideBackgroundColor,
  overrideBorderColor,
  removeBottomPadding,
  crossButton,
  children,
}) => {
  const isBrightTheme = useBrightTheme();

  const accent24 = getAccentColor(accentColor, '24');
  const accent8 = getAccentColor(accentColor, '8');

  const defaultBackgroundColor = isBrightTheme ? accent8 : NeutralColors.light_neutrals_100;
  const defaultBorderColor = isBrightTheme ? accent24 : NeutralColors.light_neutrals_400;

  const computedBackgroundColor = overrideBackgroundColor ?? defaultBackgroundColor;
  const computedBorderColor = overrideBorderColor ?? defaultBorderColor;

  return (
    <Box
      className="drawer-header"
      sx={mergeSx(root, backgroundImageStyles(backgroundImage), {
        backgroundColor: computedBackgroundColor,
        borderColor: computedBorderColor,
        paddingBottom: removeBottomPadding ? 0 : '24px',
      })}
    >
      <DrawerHeaderTitle title={title} crossButton={crossButton} />
      {children}
    </Box>
  );
};

export default DrawerHeader;
