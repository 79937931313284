import { IQueryParams } from 'api';
import { serializeQueryParams } from 'utils';

import { referenceApi } from './impl';

const createRefMethod = (refPath: string) => {
  return (queryParams?: IQueryParams) => referenceApi.get(`${refPath}${serializeQueryParams(queryParams)}`);
};

export const getSectionRef = createRefMethod('/sections');
export const getDocumentRef = createRefMethod('/document');
export const getMetaSkillRef = createRefMethod('/metaskill');
export const getProfessionProgramRef = createRefMethod('/profession-program');
export const getLevelBusinessRef = createRefMethod('/level-business');
export const getSalaryRangeRef = createRefMethod('/salary-range');
export const getEmploymentDocTypeRef = createRefMethod('/employment-doc-type');
export const getSpoOrganizationRef = createRefMethod('/spo-organization');
export const getSpoGiaMarkRef = createRefMethod('/spo-gia-mark');
export const getProfessionRankRef = createRefMethod('/profession-rank');
export const getSportAgesRef = createRefMethod('/sport-ages');
export const getOlympiadSubjectsRef = createRefMethod('/olympiad/subject');
export const getAvatarTypeRef = createRefMethod('/avatar-type');
