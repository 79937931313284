import { FC, useContext, useMemo } from 'react';
import { connect } from 'react-redux';

import { ICommonResponse, IDictionaryItem, IEventKind, IModifiedRewardKindsList } from 'api/types';
import { SectionCodes } from 'const';
import { useAppSelector } from 'hooks';
import { IRootState } from 'reducers';
import { createSelector } from 'reselect';
import { sectionRefArchiveFalseSelector } from 'selectors';

import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';
import { ActivityBlock, OccupationBlock, RewardBlock } from '../../primaryBlocks';
import { filterPersonsObjects } from '../../selectors';

interface ISciencePrimaryBlockProps {
  eventKinds: ICommonResponse<IEventKind>;
  rewardKinds: IModifiedRewardKindsList;
  scienceObjectsDictionary: IDictionaryItem[];
}

const SciencePrimaryBlock: FC<ISciencePrimaryBlockProps> = ({ eventKinds, rewardKinds, scienceObjectsDictionary }) => {
  const portfolioSections = useAppSelector(sectionRefArchiveFalseSelector);

  const { studentType, onOpenEventForm, isMobile, formData } = useContext(LocalDataEntryFormContext);

  const activityTypeOptions = useMemo(() => {
    return portfolioSections.content.filter((section) => section.parentId === SectionCodes.scienceEvent);
  }, [portfolioSections.content]);
  const activityNameOptions = useMemo(() => {
    return eventKinds.content.filter((eventKind) => eventKind.categoryCode === formData.typeCode);
  }, [eventKinds.content, formData.typeCode]);

  const occupationTypeOptions = useMemo(
    () => portfolioSections.content.filter((section) => section.parentId === SectionCodes.scienceOccupation),
    [portfolioSections.content],
  );

  const rewardTypeOptions = useMemo(
    () => portfolioSections.content.filter((section) => section.parentId === SectionCodes.scienceReward),
    [portfolioSections],
  );
  const rewardItemOptions = useMemo(() => {
    switch (formData.typeCode) {
      case SectionCodes.contestReward:
        return scienceObjectsDictionary.filter(
          (scienceObject) =>
            scienceObject.parentId === SectionCodes.contest || scienceObject.parentId === SectionCodes.conference,
        );
      case SectionCodes.projectReward:
        return scienceObjectsDictionary.filter((scienceObject) => scienceObject.parentId === SectionCodes.project);
      default:
        return [];
    }
  }, [formData.typeCode, scienceObjectsDictionary]);
  const rewardResultOptions = rewardKinds.content.filter((rewardKind) => rewardKind.categoryCode === formData.typeCode);

  const getEventInputLabel = () => {
    if (formData.typeCode === SectionCodes.contest) {
      return 'Название конкурса';
    }
    return studentType && !isMobile ? 'Название конференции / конкурса проектов' : 'Название конференции / конкурса';
  };

  const getEventInputPlaceholder = () => {
    if (formData.typeCode === SectionCodes.contest) {
      return studentType ? 'Выбери конкурс...' : 'Введите название конкурса...';
    }
    return studentType ? 'Выбери конференцию / конкурс проектов...' : 'Выберите конференцию / конкурс...';
  };

  const getRewardNameInputLabel = () => {
    if (formData.typeCode === SectionCodes.contestReward) {
      return studentType ? 'Конкурс' : 'Научный конкурс';
    }
    if (formData.typeCode === SectionCodes.projectReward) return 'Конкурс проектов';
    return '';
  };

  const getRewardNameInputPlaceholder = () => {
    if (formData.typeCode === SectionCodes.contestReward) {
      return studentType ? 'Выбери конкурс, за который была получена награда...' : 'Выберите научный конкурс...';
    }
    if (formData.typeCode === SectionCodes.projectReward) {
      return studentType
        ? 'Выбери конкурс проектов, за который была получена награда...'
        : 'Выберите конкурс проектов...';
    }
    return '';
  };

  const handleOpenEventForm = () => {
    onOpenEventForm(
      SectionCodes.science,
      formData.typeCode === SectionCodes.projectReward ? SectionCodes.project : SectionCodes.scienceEvent,
    );
  };

  return (
    <>
      {formData.dataKind === SectionCodes.scienceOccupation && <OccupationBlock typeOptions={occupationTypeOptions} />}
      {formData.dataKind === SectionCodes.scienceEvent && (
        <ActivityBlock
          typeOptions={activityTypeOptions}
          nameOptions={activityNameOptions}
          nameInputLabel={getEventInputLabel()}
          nameInputPlaceholder={getEventInputPlaceholder()}
          hasOtherNameOption
        />
      )}
      {formData.dataKind === SectionCodes.scienceReward && (
        <RewardBlock
          shortType={false}
          isNameOptionActive
          typeOptions={rewardTypeOptions}
          itemOptions={rewardItemOptions}
          resultOptions={rewardResultOptions}
          onOpenEventForm={handleOpenEventForm}
          nameInputLabel={getRewardNameInputLabel()}
          nameInputPlaceholder={getRewardNameInputPlaceholder()}
        />
      )}
    </>
  );
};

const rewardKindsSelector = createSelector([(state: IRootState) => state.rewardKinds], (rewardKinds) => ({
  content:
    rewardKinds.content?.filter(
      (rewardKind) =>
        rewardKind.categoryCode === SectionCodes.contestReward ||
        rewardKind.categoryCode === SectionCodes.projectReward,
    ) || [],
  loading: rewardKinds.loading,
}));

const scienceObjectsSelector = createSelector([(state: IRootState) => state.personsObjects], (personsObjects) => {
  return filterPersonsObjects(personsObjects.content, [SectionCodes.scienceEvent, SectionCodes.project]);
});

export default connect((state: IRootState) => ({
  eventKinds: state.eventKinds,
  rewardKinds: rewardKindsSelector(state),
  scienceObjectsDictionary: scienceObjectsSelector(state),
}))(SciencePrimaryBlock);
