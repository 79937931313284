import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { IDictionaryItem, IVuzSearchQueryParams } from 'api/types';
import { useAppSelector } from 'hooks';

import { MINIMUM_EGE_EXAMS, VuzRecommendationTab } from '../const';
import { getVuzListActions } from '../model/actions';
import { egeExamSubjectsSelector, realExamSubjectsSelector, trialExamSubjectsSelector } from '../model/selectors';
import { IVuzRecommendationFilters } from '../types';
import { getMappedUniqueRefSubjectCodes } from '../utils';
import { MilitaryTrainingTypeCodes } from './../const/militaryTrainingType';
import { TrainingTypeCodes } from './../const/trainingType';

const stringOrNull = (value: string | undefined) => (value && value.length > 0 ? value : undefined);
const valueOrNull = <T>(value: T | undefined) => (value ? value : undefined);
const arrayValueOrNull = <T>(value: T[] | undefined) => (value && value.length > 0 ? value : undefined);
const mappingExamCodes = (exam: IDictionaryItem) => exam.code;

const useSearchVuz = ({ currentTab }: { currentTab: number }) => {
  const dispatch = useDispatch();
  const allExamSubjects = useAppSelector(egeExamSubjectsSelector);
  const trialExamSubjects = useAppSelector(trialExamSubjectsSelector);
  const realExamSubjects = useAppSelector(realExamSubjectsSelector);
  const egeExamSubjectsRef = useAppSelector((state) => state.egeExamSubjectsRef);

  const searchVuz = useCallback(
    (filters: IVuzRecommendationFilters) => {
      const getAllPossibleFilterSubjectsByCurrentTab = () => {
        if (currentTab === VuzRecommendationTab.REAL_GIA) return realExamSubjects.content.map(mappingExamCodes);
        if (currentTab === VuzRecommendationTab.TRIAL_GIA) return trialExamSubjects.content.map(mappingExamCodes);
        if (currentTab === VuzRecommendationTab.FAVORITES) return allExamSubjects.map(mappingExamCodes);
        return subjects;
      };

      const {
        search,
        subjects,
        specialities,
        maxPassingBall,
        minPassingBall,
        trainingTypeCode,
        militaryTrainingTypeCode,
      } = filters;

      const isExamResultBasedTab =
        currentTab === VuzRecommendationTab.REAL_GIA || currentTab === VuzRecommendationTab.TRIAL_GIA;

      const allPossibleSubjects = getAllPossibleFilterSubjectsByCurrentTab();

      const mappedAllPossibleSubjects = getMappedUniqueRefSubjectCodes(allPossibleSubjects, egeExamSubjectsRef.content);
      const mappedSelectedSubjects = getMappedUniqueRefSubjectCodes(subjects, egeExamSubjectsRef.content);

      const querySubjects =
        isExamResultBasedTab && mappedSelectedSubjects.length >= MINIMUM_EGE_EXAMS
          ? mappedSelectedSubjects
          : mappedAllPossibleSubjects;

      const hasBachelorTraining = trainingTypeCode === TrainingTypeCodes.bachelor;
      const hasSpecialistTraining = trainingTypeCode === TrainingTypeCodes.specialist;
      const hasWarDepartment = militaryTrainingTypeCode === MilitaryTrainingTypeCodes.warDepartment;
      const hasWarCenter = militaryTrainingTypeCode === MilitaryTrainingTypeCodes.warCenter;

      const searchQuery: IVuzSearchQueryParams = {
        page: 1,
        per_page: 9999,
        name: stringOrNull(search),
        subject: arrayValueOrNull(querySubjects),
        speciality: arrayValueOrNull(specialities),
        max_ball: valueOrNull(maxPassingBall),
        min_ball: valueOrNull(minPassingBall),

        // Передаем undefined, если не выбран ни один тип,
        // Если выбран тип, то передаем в него true, в другой передаем undefined
        // Скорее всего это правильное использование этих фильтров, так как они обозначают флаги
        // Просто на макете их решили отобразить как Select/Radio, хотя по факту это Toggle/Checkbox
        bachelor: hasBachelorTraining ? 'true' : undefined,
        specialist: hasSpecialistTraining ? 'true' : undefined,
        war: hasWarDepartment ? 'true' : undefined,
        war_center: hasWarCenter ? 'true' : undefined,
      };

      dispatch(getVuzListActions.request(searchQuery));
    },
    [
      dispatch,
      currentTab,
      allExamSubjects,
      egeExamSubjectsRef.content,
      realExamSubjects.content,
      trialExamSubjects.content,
    ],
  );

  const clearVuzList = () => {
    dispatch(getVuzListActions.invalidate());
  };

  return {
    searchVuz,
    clearVuzList,
  };
};

export default useSearchVuz;
