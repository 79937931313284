import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root: SxStyles = {
  gap: '2px',
  px: '16px',
  py: '12px',

  border: '1px solid',
  borderColor: NeutralColors.light_neutrals_300,
  borderRadius: '8px',

  overflow: 'hidden',
};

export const scrollableElement: SxStyles = {
  display: 'flex',
  gap: '2px',
};
