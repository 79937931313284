import { FC, forwardRef, HTMLAttributes } from 'react';

import { styled, svgIconClasses, SvgIconProps } from '@mui/material';
import clsx from 'clsx';
import { getAccentColor, NeutralColors } from 'portfolio3/styles';
import { getFocusStateStyles } from 'portfolio3/ui-kit/utils';

interface IVisibleNavigationButtonProps extends HTMLAttributes<HTMLButtonElement> {
  name: string;
  icon?: FC<SvgIconProps>;
  isActive: boolean;
}

const StyledButton = styled('button')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '2px',

    width: 60,
    minWidth: 60,
    maxWidth: 60,
    height: 48,
    padding: '8px 4px',

    borderRadius: 12,
    textDecoration: 'none',

    border: 'none',
    background: 'none',
    cursor: 'pointer',

    [`& .${svgIconClasses.root}`]: {
      justifySelf: 'center',
      width: 16,
      height: 16,
      color: NeutralColors.light_neutrals_500,
    },

    '& .navigation-link__name': {
      display: 'grid',
      gridTemplateColumns: 'auto',
      width: '100%',

      fontFamily: 'Inter',
      color: NeutralColors.light_neutrals_900,
      fontWeight: 500,
      fontSize: 10,
      lineHeight: '14px',

      '& > *': {
        textAlign: 'center',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
    },

    '&.navigation-link--active': {
      backgroundColor: getAccentColor('indigo', '100'),

      [`& .${svgIconClasses.root}`]: {
        color: NeutralColors.light_neutrals_0,
      },

      '& .navigation-link__name': {
        color: NeutralColors.light_neutrals_0,
        fontWeight: 600,
      },
    },

    '&:focus-visible': {
      ...getFocusStateStyles(),
    },
  };
});

const VisibleNavigationButton = forwardRef<HTMLButtonElement, IVisibleNavigationButtonProps>(
  ({ name, icon: SectionIcon, isActive, ...props }, ref) => {
    return (
      <StyledButton ref={ref} className={clsx('navigation-link', { 'navigation-link--active': isActive })} {...props}>
        {SectionIcon && <SectionIcon />}
        {/* Вложенность нужна для корректного троеточия в тексте */}
        {/* grid parent */}
        <div className="navigation-link__name">
          {/* grid item */}
          <span>{name}</span>
        </div>
      </StyledButton>
    );
  },
);

VisibleNavigationButton.displayName = 'VisibleNavigationButton';

export default VisibleNavigationButton;
