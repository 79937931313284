import { FC } from 'react';

import { Box } from '@mui/material';

import PlaceBar from '../PlaceBar';
import ResultBar from '../ResultBar';
import { IPlaceBar, IResultBar } from '../types';
import * as styles from './style';

export interface IChartRow {
  placeBarProps: IPlaceBar;
  resultBarProps?: IResultBar;
}

const ChartRow: FC<IChartRow> = ({ placeBarProps, resultBarProps }) => {
  const { yours } = placeBarProps;

  const resultElement = resultBarProps && (
    <Box className="chart-row-resultBar" sx={styles.resultContainer}>
      <ResultBar {...resultBarProps} />
    </Box>
  );
  return (
    <Box className="chart-row" sx={styles.chartRow(yours, !!resultBarProps)}>
      <Box className="chart-row-placeBar" sx={styles.barContainer(!!resultBarProps)}>
        <PlaceBar yours={yours} {...placeBarProps} />
      </Box>

      {resultElement}
    </Box>
  );
};

export default ChartRow;
