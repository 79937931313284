/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';

import {
  GET_STUDENT_CHECKIN_HISTORY
} from '../../actions';

import {
  FAILURE,
  INVALIDATE,
  REQUEST,
  SUCCESS
} from '../../actions/utils';

import { ICommonResponse, IStudentCheckInHistory } from '../../api/types';

import genericReducers from '../genericReducer';

import { IReducers } from '..';

export type StudentCheckInHistoryState = ICommonResponse<IStudentCheckInHistory>;

const initialState = {
  content: [],
  loading: false
} as ICommonResponse<IStudentCheckInHistory>;

const studentCheckInHistory: IReducers<StudentCheckInHistoryState> = {
  [GET_STUDENT_CHECKIN_HISTORY[REQUEST]]: genericReducers().get.request,
  [GET_STUDENT_CHECKIN_HISTORY[SUCCESS]]: genericReducers().get.success,
  [GET_STUDENT_CHECKIN_HISTORY[FAILURE]]: genericReducers().get.failure,
  [GET_STUDENT_CHECKIN_HISTORY[INVALIDATE]]: genericReducers().get.invalidate(initialState),
};

export default connectReducers(initialState, studentCheckInHistory);
