/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useContext } from 'react';

import { IconButton, ThemeProvider } from '@mui/material';

import CheckboxListFormControl from './checkboxListFormControl';
import { IconClose, IconSave } from '../../../../icons';
import { GroupedActionButton, GroupedActionButtonsContainer } from '../../../common/GroupedActionButtons';
import { Colors } from '../../../../style/variables';
import { IInterestEditFormAttributes, IInterestEditFormData, IInterestEditFormToggleableItem } from '../../../../types';
import { InterestsEditFormContext } from '../../../../context';
import { commonBreakpoints } from '../../../../const';
import { useMediaQueryWidth } from '../../../../hooks';
import { generateAccentTheme } from 'portfolio3/styles/theme';

import './index.scss';

interface IInterestEditFormProps {
  formAttributes: IInterestEditFormAttributes;
  formData: IInterestEditFormData;
  onClose: () => void;
  onSave: (interestId: number, formData: IInterestEditFormData) => void;
}

const InterestEditForm: React.FC<IInterestEditFormProps> = ({
  formAttributes,
  formData,
  onClose,
  onSave
}) => {
  const ALL_VALUES_CODE = -1;
  const { setEditFormData } = useContext(InterestsEditFormContext);
  const isMobile = useMediaQueryWidth(commonBreakpoints.breakpointSm, 'down');

  const canSaveChanges = formData.actions.length > 0 && formData.actions.some((action) => action.isActive);

  const headerBackgroundStyles: React.CSSProperties = {
    backgroundColor: formAttributes.backgroundColor,
    backgroundImage: `url(${formAttributes.backgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center right 60px'
  };

  const titleStyles: React.CSSProperties = {
    color: formAttributes.titleColor
  };

  const actions: IInterestEditFormToggleableItem[] = [{
    code: ALL_VALUES_CODE,
    name: 'Все действия',
    isActive: formData.actions.every((action) => action.isActive) ?? false
  }, ...(formData.actions ?? [])];

  const categories: IInterestEditFormToggleableItem[] = [{
    code: ALL_VALUES_CODE,
    name: `Все ${formAttributes.categorySectionName.toLowerCase()}`,
    isActive: formData.categories?.every((category) => category.isActive) ?? false
  }, ...(formData.categories ?? [])];

  const toggleItem = (
    code: number,
    items: IInterestEditFormToggleableItem[],
    localItems: IInterestEditFormToggleableItem[]
  ): IInterestEditFormToggleableItem[] => {
    if (code === ALL_VALUES_CODE) {
      const isActive = localItems.find((item) => item.code === ALL_VALUES_CODE)?.isActive ?? false;

      return items.map((item) => ({
        ...item,
        isActive: !isActive
      }));
    }

    return items.map((item): IInterestEditFormToggleableItem => {
      if (item.code === code) {
        return {
          ...item,
          isActive: !item.isActive
        };
      }

      return item;
    });
  };

  const handleSave = () => {
    onSave(formAttributes.interestId, formData);
  };

  const handleActionChange = (code: number) => {
    const newActions = toggleItem(code, formData.actions, actions);

    setEditFormData({
      ...formData,
      actions: newActions
    });
  };

  const handleCategoryChange = (code: number) => {
    const newCategories = toggleItem(code, formData.categories ?? [], categories);

    setEditFormData({
      ...formData,
      categories: newCategories
    });
  };

  return (
    <div className="interest-edit-form">
      <div className="interest-edit-form__background" style={headerBackgroundStyles} />
      <h4 className="interest-edit-form__title" style={titleStyles}>
        Редактирование карточки «
        <span className="interest-edit-form__interest-name">
          {formAttributes.interestName}
        </span>
        »
      </h4>
      <IconButton
        className="interest-edit-form__close-button"
        disableTouchRipple
        onClick={onClose}
        size="large">
        <IconClose />
      </IconButton>

      <div className="interest-edit-form__sections">
        <CheckboxListFormControl
          options={actions}
          title="Действия"
          onChange={handleActionChange}
        />
        <CheckboxListFormControl
          options={categories}
          title={formAttributes.categorySectionName}
          onChange={handleCategoryChange}
        />
      </div>

      <ThemeProvider theme={generateAccentTheme('indigo')}>
        <GroupedActionButtonsContainer>
          <GroupedActionButton
            variant="secondary"
            startIcon={IconClose}
            handleClick={onClose}
          >
            Отменить
          </GroupedActionButton>
          <GroupedActionButton
            variant="primary"
            startIcon={IconSave}
            handleClick={handleSave}
            disabled={!canSaveChanges}
          >
            Сохранить
          </GroupedActionButton>
        </GroupedActionButtonsContainer>
      </ThemeProvider>
    </div>
  );
};

export default InterestEditForm;
