/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, {
  useEffect, useContext, useMemo, useCallback
} from 'react';

import { connect } from 'react-redux';
import { isAfter, isBefore } from 'date-fns';
import { Box, FormHelperText } from '@mui/material';

import { IDictionaryItem, IChildInfo } from '../../../../../api/types';

import { SectionCodes, SectionKeys } from '../../../../../const';

import {
  mergeSx,
  validateParallel,
} from '../../../../../utils';
import { IRootState } from '../../../../../reducers';
import { SecondaryBlockContainer, useSecondaryBlockControllers } from '../common';
import { commonAcceptFileTypes, IEventData } from 'portfolio3/features/dataEntryForm';

import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';
import { BaseInputLabel, FormControl, Input, Textarea } from 'portfolio3/ui-kit';
import { Select } from 'portfolio3/ui-kit/selects';
import { IController } from 'portfolio3/ui-kit/types';
import MultiInputControl from 'portfolio3/components/forms/MultiInputControl';
import AgeLimitControl from 'portfolio3/components/forms/AgeLimitControl';
import DualSwitcherControl from 'portfolio3/components/forms/DualSwitcherControl';
import { useDisclosure } from 'hooks';
import AdditionalInfoButton from 'portfolio3/components/forms/AdditionalInfoButton';
import { formControlsColumn, formControlsRow } from '../../commonStyles';
import { DayPicker, DayRangePicker, DayRangePickerController } from 'portfolio3/ui-kit/datePickers';
import { getDayPickerControllerFactory, getDayRangePickerControllerFactory } from '../../controllers';
import { dataEntryMinDate } from '../../dateErrors';

interface IActivitySecondaryBlockProps {
  eventLevelOptions: IDictionaryItem[];
  organizatorLabel: string;
  organizatorPlaceholder: string;
  currentStudent: IChildInfo;
  additionalTopControllers?: JSX.Element;
  additionalMiddleControllers?: JSX.Element;
  eventData?: IEventData;
  name?: string;
}

const ActivitySecondaryBlock: React.FC<IActivitySecondaryBlockProps> = ({
  eventLevelOptions,
  additionalTopControllers,
  additionalMiddleControllers,
  organizatorLabel,
  organizatorPlaceholder,
  currentStudent,
  eventData,
}) => {
  const { studentType, isMobile, inputRenderMode, inputSize, formData, formErrors, onChangeFormData, onChangeFormErrors } = useContext(LocalDataEntryFormContext);
  const { isOpen: isAdditionalInfoShownState, onToggle: onToggleAdditionalInfo } = useDisclosure();
  const isAdditionalInfoShown = isAdditionalInfoShownState || !isMobile;

  const { fileController, linkedObjects, linkedObjectController } = useSecondaryBlockControllers({
    formData,
    onChangeFormData,
    onChangeFormErrors
  });

  const dayPickerControllerFactory = getDayPickerControllerFactory(onChangeFormData, onChangeFormErrors);
  const dayRangePickerControllerFactory = getDayRangePickerControllerFactory(onChangeFormData, onChangeFormErrors);

  const today = new Date();
  const isStartDateAfterToday = formData.startDate ? isAfter(new Date(formData.startDate.toDateString()), today) : false;

  const StudySection = SectionCodes[SectionKeys.STUDY];
  const CivilSection = SectionCodes[SectionKeys.CIVIL];

  useEffect(() => {
    if (eventData) {
      let newOrganizators = formData.organizator || [''];
      if (eventData?.organizators && (formData.organizator[0] === '' && formData.organizator.length === 1)) {
        newOrganizators = [eventData?.organizators];
      }
      onChangeFormData((prevState) => ({
        ...prevState,
        organizator: newOrganizators,
        eventLevel: eventData?.levelEventCode || formData.eventLevel,
      }));
    }
  }, [eventData?.name]);

  const participantController = useMemo(() => ({
    handleChange: (participant: string) => {
      onChangeFormData((prevstate) => ({
        ...prevstate,
        participant,
      }));
    }
  }), [formData.id]);
  const eventNumberController = useMemo(() => ({
    handleChange: (eventNumber: string) => {
      onChangeFormData((prevstate) => ({
        ...prevstate,
        eventNumber
      }));
    }
  }), [formData.id]);
  const actionStageController = useMemo(() => ({
    handleChange: (actionStage: string) => {
      onChangeFormData((prevstate) => ({
        ...prevstate,
        actionStage
      }));
    }
  }), [formData.id]);

  const stageController = useMemo(() => ({
    handleChange: (stageEvent: string) => {
      onChangeFormData((prevstate) => ({
        ...prevstate,
        stageEvent
      }));
    }
  }), [formData.id]);
  const placeController = useMemo(() => ({
    handleChange: (place: string) => {
      onChangeFormData((prevstate) => ({
        ...prevstate,
        place
      }));
    }
  }), [formData.id]);
  const workCodeController = useMemo(() => ({
    handleChange: (workCode: string) => {
      onChangeFormData((prevstate) => ({
        ...prevstate,
        workCode
      }));
    }
  }), [formData.id]);
  const resultController = useMemo(() => ({
    handleChange: (result: string) => {
      onChangeFormData((prevstate) => ({
        ...prevstate,
        result
      }));
    }
  }), [formData.id]);
  const descriptionController = useMemo(() => ({
    handleChange: (description: string) => {
      onChangeFormData((prevstate) => ({
        ...prevstate,
        description
      }));
    }
  }), [formData.id]);

  const organizatorController = useMemo(() => ({
    handleChange: (organizator: string, index: number) => {
      onChangeFormData((prevstate) => {
        const newOrganizators = [...prevstate.organizator];
        newOrganizators[index] = organizator;
        return ({
          ...prevstate,
          organizator: newOrganizators
        });
      });
    },
    handleAddField: () => {
      onChangeFormData((prevstate) => ({
        ...prevstate,
        organizator: [...prevstate.organizator, '']
      }));
    },
    handleDeleteField: (index: number) => {
      onChangeFormData((prevstate) => {
        const newOrganizators = prevstate.organizator;
        newOrganizators.splice(index, 1);
        return ({
          ...prevstate,
          organizator: newOrganizators
        });
      });
    }
  }), [formData.id]);

  const classStartController = useMemo(() => ({
    handleChange: (classStart: string | undefined) => {
      const newClassStart = classStart ? validateParallel(classStart) : null;
      onChangeFormData((prevstate) => ({
        ...prevstate,
        classStart: newClassStart === undefined ? prevstate.classStart : newClassStart || undefined
      }));
    }
  }), [formData.id]);

  const classEndController = useMemo(() => ({
    handleChange: (classEnd: string | undefined) => {
      const newClassEnd = classEnd ? validateParallel(classEnd) : null;
      onChangeFormData((prevstate) => ({
        ...prevstate,
        classEnd: newClassEnd === undefined ? prevstate.classEnd : newClassEnd || undefined
      }));
    }
  }), [formData.id]);

  const eventLevelController: IController<string | undefined> = useMemo(() => ({
    handleChange: (eventLevel) => {
      onChangeFormData((prevstate) => ({
        ...prevstate,
        eventLevel: Number(eventLevel)
      }));
      onChangeFormErrors((prevstate) => ({
        ...prevstate,
        eventLevelError: false
      }));
    },
    handleBlur: (value) => {
      if (!value) {
        onChangeFormErrors((prevstate) => ({
          ...prevstate,
          eventLevelError: true
        }));
      }
    }
  }), [formData.id]);

  const additionalActivityController: DayRangePickerController = {
    handleChange(value) {
      const { actionDate, stageStartDate, stageEndDate } = formData;
      const { start, end } = value;

      const isEventDateBeforeStart = actionDate && start && isBefore(actionDate, start);
      const isEventDateAfterEnd = actionDate && end && isAfter(actionDate, end);
      const isStageDateBeforeStart = stageStartDate && start && isBefore(stageStartDate, start);
      const isStageDateAfterEnd = stageEndDate && end && isAfter(stageEndDate, end);

      if (isEventDateBeforeStart || isEventDateAfterEnd) {
        onChangeFormData((prevState) => {
          return {
            ...prevState,
            actionDate: null,
          };
        });
      }
      if (isStageDateBeforeStart || isStageDateAfterEnd) {
        onChangeFormData((prevState) => {
          return {
            ...prevState,
            stageStartDate: null,
            stageEndDate: null,
          };
        });
      }
    },
    handleClear() {
      onChangeFormData((prevState) => {
        return {
          ...prevState,
          actionDate: null,
          stageStartDate: null,
          stageEndDate: null,
        };
      });
    },
  }

  const activityDateController = dayRangePickerControllerFactory('startDate', 'endDate', 'activityDateError', {
    required: true,
    disableFuture: true,
  }, [additionalActivityController]);

  const stageDateController = dayRangePickerControllerFactory('stageStartDate', 'stageEndDate', 'stageDateError', {
    minDate: formData.startDate ?? undefined,
    maxDate: formData.endDate ?? undefined,
  })

  const eventDateController = dayPickerControllerFactory('actionDate', 'eventDateError', {
    minDate: formData.startDate ?? undefined,
    maxDate: formData.endDate ?? undefined,
  });

  const handleChangeFormat = useCallback((value: number) => {
    onChangeFormData((prevstate) => ({
      ...prevstate,
      format: value
    }));
  }, [formData.id]);

  return (
    <SecondaryBlockContainer>
      <SecondaryBlockContainer.FilesTab
        personId={currentStudent.meshId}
        controller={fileController}
        files={formData.attachment}
        {...commonAcceptFileTypes}
      />
      <SecondaryBlockContainer.LinkedObjectsTab
        studentType={studentType}
        linkedObjectController={linkedObjectController}
        linkedObjects={linkedObjects}
        formData={formData}
      />

      <SecondaryBlockContainer.GeneralTab>
        {additionalTopControllers}
        <FormControl
          required
          renderMode={inputRenderMode}
          inputSize={inputSize}
          error={formErrors.activityDateError.active}
          helper={formErrors.activityDateError.active && <FormHelperText>{formErrors.activityDateError.description}</FormHelperText>}
          label={
            <BaseInputLabel>
              {isMobile ? 'Даты проведения' : 'Даты проведения (дата начала или интервал)'}
            </BaseInputLabel>
          }
          control={
            <Box sx={mergeSx(!isMobile && { width: '100%', maxWidth: '280px' })}>
              <DayRangePicker
                isMobile={isMobile}
                renderMode={inputRenderMode}
                size={inputSize}
                controller={activityDateController}
                startValue={formData.startDate}
                endValue={formData.endDate}
                minValue={dataEntryMinDate}
                maxValue={today}
              />
            </Box>
          }
        />
        {!eventData?.levelEventCode && (
          <FormControl
            sx={{ width: '100%' }}
            required
            error={formErrors.eventLevelError}
            renderMode={inputRenderMode}
            inputSize={inputSize}
            label={<BaseInputLabel>Уровень мероприятия</BaseInputLabel>}
            helper={formErrors.eventLevelError && <FormHelperText>Выберите уровень мероприятия</FormHelperText>}
            control={
              <Select
                inputRenderMode={inputRenderMode}
                inputSize={inputSize}
                placeholder="Выберите уровень мероприятия..."
                value={formData.eventLevel}
                options={eventLevelOptions}
                controller={eventLevelController}
                strictMaxWidth
              />
            }
          />
        )}
        <DualSwitcherControl
          required
          isMobile={isMobile}
          inputRenderMode={inputRenderMode}
          inputSize={inputSize}
          label="Формат проведения"
          value={formData.format}
          firstLabel="Очно"
          secondLabel="Заочно"
          onChange={handleChangeFormat}
        />

        {isMobile && <AdditionalInfoButton isOpen={isAdditionalInfoShownState} onToggle={onToggleAdditionalInfo} />}
        {isAdditionalInfoShown && (
          <>
            {additionalMiddleControllers}
            <FormControl
              sx={{ width: '100%' }}
              renderMode={inputRenderMode}
              inputSize={inputSize}
              label={<BaseInputLabel>Номер мероприятия (если есть)</BaseInputLabel>}
              control={
                <Input
                  renderMode={inputRenderMode}
                  size={inputSize}
                  placeholder={studentType ? 'Например, Первая олимпиада, IV конкурс...' : 'Введите номер мероприятия'}
                  value={formData.eventNumber}
                  controller={eventNumberController}
                />
              }
            />
            <Box sx={isMobile ? formControlsColumn : formControlsRow}>
              <FormControl
                renderMode={inputRenderMode}
                inputSize={inputSize}
                label={<BaseInputLabel>Этап мероприятия</BaseInputLabel>}
                control={
                  <Input
                    renderMode={inputRenderMode}
                    size={inputSize}
                    placeholder="Введите этап мероприятия"
                    value={formData.stageEvent}
                    controller={stageController}
                  />
                }
              />
              <FormControl
                disabled={!formData.startDate || !formData.endDate}
                renderMode={inputRenderMode}
                inputSize={inputSize}
                error={formErrors.stageDateError.active}
                helper={formErrors.stageDateError.active && <FormHelperText>{formErrors.stageDateError.description}</FormHelperText>}
                label={
                  <BaseInputLabel>
                    {isMobile ? 'Даты этапа' : 'Даты этапа (дата начала или интервал)'}
                  </BaseInputLabel>
                }
                control={
                  <DayRangePicker
                    isMobile={isMobile}
                    renderMode={inputRenderMode}
                    size={inputSize}
                    controller={stageDateController}
                    startValue={formData.stageStartDate}
                    endValue={formData.stageEndDate}
                    minValue={formData.startDate}
                    maxValue={formData.endDate}
                  />
                }
              />
            </Box>
            <FormControl
              sx={{ width: '100%' }}
              renderMode={inputRenderMode}
              inputSize={inputSize}
              label={
                <BaseInputLabel>
                  {isMobile ? 'Событие в рамках мероприятия' : 'Конкретное событие в рамках мероприятия (если есть)'}
                </BaseInputLabel>
              }
              control={
                <Input
                  renderMode={inputRenderMode}
                  size={inputSize}
                  placeholder="Например, матч, название конкретной выставки и т.д..."
                  value={formData.actionStage}
                  controller={actionStageController}
                />
              }
            />
            <FormControl
              renderMode={inputRenderMode}
              inputSize={inputSize}
              disabled={!formData.startDate || !formData.endDate || isStartDateAfterToday}
              error={formErrors.eventDateError.active}
              helper={formErrors.eventDateError.active && <FormHelperText>{formErrors.eventDateError.description}</FormHelperText>}
              label={<BaseInputLabel>Дата проведения события</BaseInputLabel>}
              control={
                <Box sx={!isMobile ? { width: '220px' } : undefined}>
                  <DayPicker
                    isMobile={isMobile}
                    renderMode={inputRenderMode}
                    size={inputSize}
                    value={formData.actionDate ?? null}
                    minValue={formData.startDate}
                    maxValue={formData.endDate}
                    controller={eventDateController}
                  />
                </Box>
              }
            />
            <MultiInputControl
              isMobile={isMobile}
              inputRenderMode={inputRenderMode}
              inputSize={inputSize}
              label={organizatorLabel}
              placeholder={organizatorPlaceholder}
              values={formData.organizator}
              controller={organizatorController}
              addFieldButtonName="Добавить ещё организатора"
            />
            <Box sx={isMobile ? formControlsColumn : formControlsRow}>
              <FormControl
                renderMode={inputRenderMode}
                inputSize={inputSize}
                label={<BaseInputLabel>Номер участника</BaseInputLabel>}
                control={
                  <Input
                    renderMode={inputRenderMode}
                    size={inputSize}
                    placeholder="Укажите номер участника..."
                    value={formData.participant}
                    controller={participantController}
                  />
                }
              />
              {!eventData?.ageLimit && (
                <AgeLimitControl
                  isMobile={isMobile}
                  renderMode={inputRenderMode}
                  inputSize={inputSize}
                  valueFrom={formData.classStart ? formData.classStart : ''}
                  valueTo={formData.classEnd ? formData.classEnd : ''}
                  fromController={classStartController}
                  toController={classEndController}
                  label={isMobile ? 'Возрастное ограничение' : 'Возрастное ограничение участников'}
                  endAdornment="класс"
                />
              )}
            </Box>
            <FormControl
              sx={{ width: '100%' }}
              renderMode={inputRenderMode}
              inputSize={inputSize}
              label={<BaseInputLabel>Место проведения</BaseInputLabel>}
              control={
                <Input
                  renderMode={inputRenderMode}
                  size={inputSize}
                  placeholder="Укажите место проведения мероприятия..."
                  value={formData.place}
                  controller={placeController}
                />
              }
            />
            <FormControl
              sx={{ width: '100%' }}
              renderMode={inputRenderMode}
              inputSize={inputSize}
              label={<BaseInputLabel>Результат (кроме награды)</BaseInputLabel>}
              control={
                <Textarea
                  renderMode={inputRenderMode}
                  size={inputSize}
                  placeholder="Вы можете указать результат участия в мероприятии, не являющийся наградой, например, полученный балл..."
                  value={formData.result}
                  controller={resultController}
                />
              }
            />
            <FormControl
              sx={{ width: '100%' }}
              renderMode={inputRenderMode}
              inputSize={inputSize}
              label={<BaseInputLabel>Описание</BaseInputLabel>}
              control={
                <Textarea
                  renderMode={inputRenderMode}
                  size={inputSize}
                  placeholder="Любое описание или примечание..."
                  value={formData.description}
                  controller={descriptionController}
                />
              }
            />
            {(formData.categoryCode === StudySection || formData.categoryCode === CivilSection) && (
              <FormControl
                renderMode={inputRenderMode}
                inputSize={inputSize}
                label={<BaseInputLabel>Код работы</BaseInputLabel>}
                control={
                  <Input
                    renderMode={inputRenderMode}
                    size={inputSize}
                    placeholder="Укажите код..."
                    value={formData.workCode}
                    controller={workCodeController}
                  />
                }
              />
            )}
          </>
        )}
      </SecondaryBlockContainer.GeneralTab>
    </SecondaryBlockContainer>
  );
};

export default connect(
  (state: IRootState) => ({
    currentStudent: state.currentStudent
  })
)(ActivitySecondaryBlock);
