import React, { useRef, useState } from 'react';

import { IconButton, Typography } from '@mui/material';
import Toggle from 'portfolio3/ui-kit/Toggle';
import { getAdminCategoryAccentColor } from 'utils';

import { IDictionaryItem } from '../../../api/types';
import { IAdminVisibilitySetting, IVisibilityChildSetting } from '../../../containers/admin/VisibilitySettings/types';
import { IconSelectArrow } from '../../../icons';
import { Colors } from '../../../style/variables';
import ChildSettingBlock from './childSetting';
import ReasonMessagePopper from './reasonMessagePopper';
import SectionIconWrapper from './SectionIconWrapper';
import { getSettingActivity } from './utils';

import './index.scss';

interface ISettingBlockProps {
  setting: IAdminVisibilitySetting;
  parallelOptions: IDictionaryItem[];
  onChangeSetting: (setting: IAdminVisibilitySetting, comment?: string) => void;
  onChangeChildSetting: (
    parentName: string,
    childSetting: IVisibilityChildSetting,
    comment?: string,
    selectValue?: string,
    isActivityUnchanged?: boolean,
  ) => void;
  onChangeSecondaryChildSetting: (
    grandParentName: string,
    parentName: string,
    childSetting: IVisibilityChildSetting,
    comment?: string,
    selectValue?: string,
    isActivityUnchanged?: boolean,
  ) => void;
}

const SettingBlock: React.FC<ISettingBlockProps> = ({
  setting,
  parallelOptions,
  onChangeSetting,
  onChangeChildSetting,
  onChangeSecondaryChildSetting,
}) => {
  const switchRef = useRef<HTMLDivElement>(null);
  const hasChildren = setting.children && setting.children.length > 0;
  const [isChildrenShown, setChildrenShown] = useState(true);
  const [isReasonPopperOpen, setReasonPopperOpen] = useState(false);
  const { name, color, backgroundColor, isActive, children, Icon } = setting;

  const toggleSettingChildren = () => {
    setChildrenShown((prevstate) => !prevstate);
  };

  const handleToggleParent = () => {
    if (setting.isActive) {
      setReasonPopperOpen(true);
    } else {
      onChangeSetting(setting);
    }
  };

  const handleChangeChildSetting = (
    childSetting: IVisibilityChildSetting,
    comment?: string,
    selectValue?: string,
    isActivityUnchanged?: boolean,
  ) => {
    onChangeChildSetting(setting.name, childSetting, comment, selectValue, isActivityUnchanged);
  };

  const handleChangeSecondaryChildSetting = (
    parentName: string,
    childSetting: IVisibilityChildSetting,
    comment?: string,
    selectValue?: string,
    isActivityUnchanged?: boolean,
  ) => {
    onChangeSecondaryChildSetting(setting.name, parentName, childSetting, comment, selectValue, isActivityUnchanged);
  };

  const handleSubmitMessagePopper = (comment?: string) => {
    setReasonPopperOpen(false);
    onChangeSetting(setting, comment);
  };

  const accentColorType = getAdminCategoryAccentColor(setting.sectionCode);

  return (
    <div className="setting-block ">
      <div
        className={`setting-block__section ${!isChildrenShown ? 'setting-block__section--rolled' : ''}`}
        style={{ backgroundColor: isActive ? backgroundColor : Colors['disable-input'] }}
      >
        <div className="setting-block__opener" onClick={toggleSettingChildren}>
          {hasChildren && (
            <IconButton
              className={
                isChildrenShown ? 'setting-block__arrow' : 'setting-block__arrow setting-block__arrow--reversed'
              }
              size="large"
            >
              <IconSelectArrow
                className="setting-block__arrow-icon"
                style={{ color: isActive ? color : Colors['note-text'] }}
              />
            </IconButton>
          )}
          <SectionIconWrapper Icon={Icon} accentColorType={accentColorType} disabled={!isActive} />
          <Typography color={{ color: isActive ? color : Colors['note-text'] }} variant="Headings/H6">
            {name}
          </Typography>
        </div>
        <div className="setting-block__status-container" ref={switchRef}>
          <Toggle className="setting-block__switch" onChange={handleToggleParent} checked={isActive} />
          <Typography variant="Paragraph LG/Regular">{getSettingActivity(setting)}</Typography>
          <ReasonMessagePopper
            anchorElement={switchRef.current}
            isOpen={isReasonPopperOpen}
            onClose={() => setReasonPopperOpen(false)}
            onSubmit={handleSubmitMessagePopper}
            placement="bottom-end"
          />
        </div>
      </div>
      {isChildrenShown &&
        children.map((childSetting) => (
          <ChildSettingBlock
            childSetting={childSetting}
            parallelOptions={parallelOptions}
            key={childSetting.name}
            onChangeChildSetting={handleChangeChildSetting}
            onChangeSecondaryChildSetting={handleChangeSecondaryChildSetting}
          />
        ))}
    </div>
  );
};
export default SettingBlock;
