/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconPlus: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M15.8335 9.16683H10.8335V4.16683C10.8335 3.706 10.4602 3.3335 10.0002 3.3335C9.54016 3.3335 9.16683 3.706 9.16683 4.16683V9.16683H4.16683C3.70683 9.16683 3.3335 9.53933 3.3335 10.0002C3.3335 10.461 3.70683 10.8335 4.16683 10.8335H9.16683V15.8335C9.16683 16.2943 9.54016 16.6668 10.0002 16.6668C10.4602 16.6668 10.8335 16.2943 10.8335 15.8335V10.8335H15.8335C16.2935 10.8335 16.6668 10.461 16.6668 10.0002C16.6668 9.53933 16.2935 9.16683 15.8335 9.16683Z" fill="#845EF7" />
    <path d="M15.8335 9.16683H10.8335V4.16683C10.8335 3.706 10.4602 3.3335 10.0002 3.3335C9.54016 3.3335 9.16683 3.706 9.16683 4.16683V9.16683H4.16683C3.70683 9.16683 3.3335 9.53933 3.3335 10.0002C3.3335 10.461 3.70683 10.8335 4.16683 10.8335H9.16683V15.8335C9.16683 16.2943 9.54016 16.6668 10.0002 16.6668C10.4602 16.6668 10.8335 16.2943 10.8335 15.8335V10.8335H15.8335C16.2935 10.8335 16.6668 10.461 16.6668 10.0002C16.6668 9.53933 16.2935 9.16683 15.8335 9.16683" stroke="#845EF7" strokeWidth="0.5" />
  </SvgIcon>

);

export default IconPlus;
