import civilClubs from './civilClubs.png';
import civilContests from './civilContests.png';
import civilOccupations from './civilOccupations.png';
import civilRewards from './civilStatus.png';
import creationRewards from './creationAwards.png';
import creationClubs from './creationClubs.png';
import creationContests from './creationContests.png';
import creationOccupations from './creationOccupations.png';
import cultureOnlineVisiting from './cultureOnlineVisiting.png';
import cultureVisiting from './cultureVisiting.png';
import studyGia from './GIA.png';
import professionEducation from './professionEducation.png';
import professionEvents from './professionEvents.png';
import professionGia from './professionGia.png';
import professionReward from './professionReward.png';
import recommendationVuz from './recommendationVuz.png';
import rewardsWidget from './rewardsWidget.png';
import scienceRewards from './scienceAward.png';
import scienceContests from './scienceContests.png';
import scienceOccupations from './scienceOccupations.png';
import scienceProjects from './scienceProjects.png';
import sportClubs from './sportClubs.png';
import sportCompetitions from './sportCompetitions.png';
import sportExpeditions from './sportExpeditions.png';
import sportRewards from './sportRank.png';
import sportSections from './sportSections.png';
import studyDiagnostics from './studyDiagnostics.png';
import studyOlimpiads from './studyOlimpiads.png';

export const widgetIllustrations = {
  rewardsWidget,

  studyPerformance: studyGia,
  studyDiagnostics,
  studyGia,
  studyOlimpiads,

  scienceRewards,
  scienceProjects,
  scienceOccupations,
  scienceContests,

  sportRewards,
  sportClubs,
  sportSections,
  sportCompetitions,
  sportExpeditions,

  creationRewards,
  creationClubs,
  creationContests,
  creationOccupations,

  cultureVisiting,
  cultureOnlineVisiting,

  civilRewards,
  civilClubs,
  civilContests,
  civilOccupations,

  professionEducation,
  professionEvents,
  professionGia,
  professionReward,

  recommendationVuz,
};
