import { FC } from 'react';

import { useMediaQuery } from '@mui/material';
import { GroupedActionButton, GroupedActionButtonsContainer } from 'components/common/GroupedActionButtons';
import { EntityFlags, EntityType } from 'const';
import { commonTheme } from 'portfolio3/styles/theme';

import MobileEntityActions from '../../components/MobileEntityActions';
import useEntityActions from '../../hooks/useEntityActions';
import { IEntityActionMap } from '../../types';

interface IEntityActionsProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formData: any;
  entityType: EntityType | null;
  entityFlags: (keyof typeof EntityFlags)[];
  actionsMap: IEntityActionMap;
}

const EntityActions: FC<IEntityActionsProps> = ({ formData, entityType, entityFlags, actionsMap }) => {
  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const actions = useEntityActions({ formData, entityType, entityFlags, actionsMap }, 'drawer');

  if (isMobile) {
    return <MobileEntityActions actions={actions} />;
  }

  return (
    <GroupedActionButtonsContainer disableAbsolutePosition>
      {actions.map((action) => {
        const { isDisabled, desktopButtonVariant, text, icon, effect } = action;
        return (
          <GroupedActionButton
            key={text}
            variant={desktopButtonVariant}
            startIcon={icon}
            handleClick={effect}
            disabled={isDisabled}
          >
            {text}
          </GroupedActionButton>
        );
      })}
    </GroupedActionButtonsContainer>
  );
};

export default EntityActions;
