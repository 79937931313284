import { EventMapShape, Subscription, Unsubscribe } from './types';

export class EventBus<EventMap extends EventMapShape> {
  private _subscriptions: Partial<Record<keyof EventMap, Set<Subscription> | undefined>> = {};

  /**
   * Добавляет подписку на событие
   * @param eventName название события
   * @param callback коллбек
   * @param once
   * @returns функция отписки от события
   */
  subscribe<K extends keyof EventMap>(eventName: K, callback: EventMap[K], once?: boolean): Unsubscribe | undefined {
    if (!this._subscriptions[eventName]) {
      this._subscriptions[eventName] = new Set();
    }

    const subscriptions = this._subscriptions[eventName];
    if (!subscriptions) return;

    subscriptions.add({
      once: once ?? false,
      callback,
    });

    return () => {
      const subscriptionsArray = Array.from(subscriptions.values());
      const currentSubscription = subscriptionsArray.find((subscription) => subscription.callback === callback);

      if (currentSubscription) {
        subscriptions.delete(currentSubscription);
      }

      if (subscriptions.size === 0) {
        delete this._subscriptions[eventName];
      }
    };
  }

  /**
   * Вызывает коллбек у всех подписчиков события
   * @param eventName название события
   * @param args аргументы, которые передаются в коллбек
   */
  broadcast<K extends keyof EventMap>(eventName: K, ...args: Parameters<EventMap[K]>) {
    const subscriptions = this._subscriptions[eventName];
    if (!subscriptions) return;

    const subscriptionsArray = Array.from(subscriptions);
    for (const subscription of subscriptionsArray) {
      subscription.callback(...args);

      if (subscription.once) {
        subscriptions.delete(subscription);
      }
    }

    if (subscriptions.size === 0) {
      delete this._subscriptions[eventName];
    }
  }
}
