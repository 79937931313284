import civilAffilation from './civilAffilation.png';
import civilEvent from './civilEvent.png';
import civilOccupation from './civilOccupation.png';
import civilReward from './civilReward.png';
import creationClub from './creationClub.png';
import creationContest from './creationContest.png';
import creationReward from './creationReward.png';
import creationSection from './creationSection.png';
import cultureOnlineVisiting from './cultureOnlineVisiting.png';
import cultureVisiting from './cultureVisiting.png';
import errorReport from './errorReport.png';
import professionEducation from './professionEducation.png';
import professionEvent from './professionEvent.png';
import professionGia from './professionGia.png';
import professionReward from './professionReward.png';
import scienceContest from './scienceContest.png';
import scienceOccupation from './scienceOccupation.png';
import scienceProject from './scienceProject.png';
import scienceReward from './scienceReward.png';
import sportClub from './sportClub.png';
import sportCompetition from './sportCompetition.png';
import sportExpedition from './sportExpedition.png';
import sportReward from './sportReward.png';
import sportSection from './sportSection.png';
import studyOlympiad from './studyOlympiad.png';

export const cardIllustrations = {
  studyOlympiad,

  scienceReward,
  scienceProject,
  scienceOccupation,
  scienceContest,

  sportReward,
  sportClub,
  sportSection,
  sportCompetition,
  sportExpedition,

  creationReward,
  creationClub,
  creationContest,
  creationSection,

  cultureVisiting,
  cultureOnlineVisiting,

  civilReward,
  civilAffilation,
  civilEvent,
  civilOccupation,

  professionEducation,
  professionEvent,
  professionGia,
  professionReward,

  errorReport,
};
