import { NeutralColors } from 'portfolio3/styles';
import { Colors } from 'style/variables';
import { SxStyles } from 'types';

export const root: SxStyles = (theme) => {
  return {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '80px',
    padding: 0,

    backgroundColor: '#ffffff',
    borderTop: '1px solid',
    borderColor: NeutralColors.light_neutrals_300,
    boxShadow: '0px -1px 5px rgba(41, 41, 64, 0.04), 0px -3px 6px -1px rgba(41, 41, 64, 0.08)',

    [theme.breakpoints.down('commonSm')]: {
      height: '64px',
      padding: '8px',

      backgroundColor: Colors['page-background'],
      borderTop: 'none',
      boxShadow: 'none',
    },
  };
};

export const buttonGroup: SxStyles = (theme) => {
  return {
    height: '100%',

    [theme.breakpoints.down('commonSm')]: {
      height: '48px',

      borderRadius: '8px',
      overflow: 'hidden',
    },
  };
};
