import { FC } from 'react';

import clsx from 'clsx';
import ResultDoughnut from 'components/common/ResultDoughnut';
import { ISubjectTheme } from 'portfolio3/features/performance';
import { getDoughnutColor, getDoughnutData } from 'utils';

import './index.scss';

interface ISubjectThemeListMobileProps {
  themes: ISubjectTheme[];
}

const SubjectThemeListMobile: FC<ISubjectThemeListMobileProps> = ({ themes }) => {
  return (
    <div className="subject-theme-list-mobile-wrapper">
      {themes.map(({ name, passedLessons, totalLessons, averageGrade }) => {
        return (
          <div key={name} className="subject-theme-list-mobile">
            <div className="subject-theme-list-mobile__main-info">
              <div className="subject-theme-list-mobile__title">{name}</div>
              <div className="subject-theme-list-mobile__data">
                <div className="subject-theme-list-mobile__lesson">
                  <p className="subject-theme-list-mobile__lesson-significative">Уроков:</p>
                  <div className="subject-theme-list-mobile__lesson-chart">
                    <ResultDoughnut
                      borderThickness={70}
                      total={null}
                      data={getDoughnutData(passedLessons, totalLessons)}
                      color={getDoughnutColor(passedLessons, totalLessons)}
                    />
                  </div>
                  <p className={clsx({ 'subject-theme-list-mobile__lesson-chart--grayed': passedLessons === 0 })}>
                    {passedLessons} из {totalLessons}
                  </p>
                </div>
                <div>
                  <p className="subject-theme-list-mobile__lesson-significative">Ср. балл:</p>
                  <span className="subject-theme-list-mobile__lesson-result">
                    {averageGrade && averageGrade.toString().replace('.', ',')}
                  </span>
                </div>
              </div>
            </div>
            {/* TODO вернуть когда будет доступен подробный просмотр тем */}
            {/* <div className="subject-topics__more-btn">
              <IconArrowRight fill="#7B819B" />
            </div> */}
          </div>
        );
      })}
    </div>
  );
};

export default SubjectThemeListMobile;
