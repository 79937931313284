import { GET_STUDENT_META_SKILLS } from '../../actions';
import { FAILURE, INVALIDATE, REQUEST, SUCCESS } from '../../actions/utils';
import { ICommonResponse, MetaSkill } from '../../api/types';
import { connectReducers } from '../../utils';
import { IReducers } from '..';
import genericReducers from '../genericReducer';

export type StudentMetaSkillsState = ICommonResponse<MetaSkill>;

const initialState: StudentMetaSkillsState = {
  content: [],
  loading: false,
};

const studentMetaSkills: IReducers<StudentMetaSkillsState> = {
  [GET_STUDENT_META_SKILLS[REQUEST]]: genericReducers().get.request,
  [GET_STUDENT_META_SKILLS[SUCCESS]]: genericReducers().get.success,
  [GET_STUDENT_META_SKILLS[FAILURE]]: genericReducers().get.failure,
  [GET_STUDENT_META_SKILLS[INVALIDATE]]: genericReducers().get.invalidate(initialState),
};

export default connectReducers(initialState, studentMetaSkills);
