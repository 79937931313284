/* eslint-disable prettier/prettier */
/* eslint-disable */

import {
  girl1,
  girl2,
  girl3,
  girl4,
  girl5,
  girl6,
  girl7,
  girl8,
  girl9,
  girl10,
  girl11,
  girl12,

  boy1,
  boy2,
  boy3,
  boy4,
  boy5,
  boy6,
  boy7,
  boy8,
  boy9,
  boy10,
  boy11,
  boy12,

  animal1,
  animal2,
  animal3,
  animal4
} from '../images';

/** @deprecated */
export interface IAvatarData {
  id: number,
  path: string
}

/** @deprecated */
export const GirlsAvatarsArray: IAvatarData[] = [
  { id: 13, path: girl1 },
  { id: 14, path: girl2 },
  { id: 15, path: girl3 },
  { id: 16, path: girl4 },
  { id: 17, path: girl5 },
  { id: 18, path: girl6 },
  { id: 19, path: girl7 },
  { id: 20, path: girl8 },
  { id: 21, path: girl9 },
  { id: 22, path: girl10 },
  { id: 23, path: girl11 },
  { id: 24, path: girl12 }
];

/** @deprecated */
export const BoysAvatarsArray: IAvatarData[] = [
  { id: 1, path: boy1 },
  { id: 2, path: boy2 },
  { id: 3, path: boy3 },
  { id: 4, path: boy4 },
  { id: 5, path: boy5 },
  { id: 6, path: boy6 },
  { id: 7, path: boy7 },
  { id: 8, path: boy8 },
  { id: 9, path: boy9 },
  { id: 10, path: boy10 },
  { id: 11, path: boy11 },
  { id: 12, path: boy12 }
];

/** @deprecated */
export const AnimalsAvatarsArray: IAvatarData[] = [
  { id: 25, path: animal1 },
  { id: 26, path: animal2 },
  { id: 27, path: animal3 },
  { id: 28, path: animal4 }
];
