import { FC } from 'react';

import { MenuItem, Stack, Typography } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';
import Avatar from 'portfolio3/ui-kit/Avatar';

import { IStudentPickerItem } from '../types';
import { styles } from './styles';

interface IStudentItemProps {
  student: IStudentPickerItem;
  selected: boolean;
  onSelect: () => void;
}

const StudentItem: FC<IStudentItemProps> = ({ student, selected, onSelect }) => {
  const { avatar, firstName, lastName, studentClass } = student;
  const name = `${firstName} ${lastName}`;

  return (
    <MenuItem sx={styles} onClick={onSelect} selected={selected} autoFocus={selected}>
      <Avatar image={avatar} firstName={firstName || ''} lastName={lastName || ''} size={32} />
      <Stack alignItems="start" textAlign="start">
        <Typography
          variant="Paragraph MD/Medium"
          color={selected ? NeutralColors.light_neutrals_0 : NeutralColors.light_neutrals_900}
        >
          {name}
        </Typography>
        {studentClass && (
          <Typography
            variant="Paragraph SM/Medium"
            color={selected ? NeutralColors.light_neutrals_0 : NeutralColors.light_neutrals_600}
          >
            {studentClass}
          </Typography>
        )}
      </Stack>
    </MenuItem>
  );
};

export default StudentItem;
