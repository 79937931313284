/* eslint-disable prettier/prettier */
/* eslint-disable */

export enum CommonBreakpointKeys {
  BREAKPOINT_SM = 'breakpointSm',
  BREAKPOINT_MD = 'breakpointMd',
  BREAKPOINT_LG = 'breakpointLg'
}

export const commonBreakpoints: Record<CommonBreakpointKeys, number> = {
  [CommonBreakpointKeys.BREAKPOINT_SM]: 639,
  [CommonBreakpointKeys.BREAKPOINT_MD]: 767,
  [CommonBreakpointKeys.BREAKPOINT_LG]: 1023
};
