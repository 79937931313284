import { IndependentDiagnosticLevelTypeKeys, IndependentDiagnosticLevelTypeNames } from 'const';
import { AccentColorModifier, AccentColorType, getAccentColor } from 'portfolio3/styles';

export const getDiagnosticLevelTypeColor = (
  levelType: string,
  colorSet: Record<IndependentDiagnosticLevelTypeKeys, AccentColorType>,
  modifier?: AccentColorModifier,
): string => {
  const levelTypeKey = Object.entries(IndependentDiagnosticLevelTypeNames).find(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ([_, value]) => value.toLowerCase() === levelType?.toLocaleLowerCase(),
  )?.[0] as IndependentDiagnosticLevelTypeKeys | undefined;

  const levelTypeAccentColor = levelTypeKey ? colorSet[levelTypeKey] : 'green';
  const levelTypeColor = getAccentColor(levelTypeAccentColor, modifier ?? '100');

  return levelTypeColor;
};
