import { FC } from 'react';

import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';
import { commonTheme } from 'portfolio3/styles/theme';

import { IRecommendedIndustry } from '../../model/types';
import * as styles from './styles';

interface IRecommendationCardProps {
  recommendation: IRecommendedIndustry;
}

const RecommendationCard: FC<IRecommendationCardProps> = ({ recommendation }) => {
  const { name, specialities, collegeNames, illustration } = recommendation;

  const isMediumScreen = useMediaQuery(commonTheme.breakpoints.down('commonMd'));

  const specialitiesElement = specialities.length > 0 && (
    <Stack gap="8px">
      <Typography variant="Paragraph MD/Regular" color={NeutralColors.light_neutrals_600}>
        Специальности:
      </Typography>
      {specialities.map((spec) => {
        const { name, code } = spec;

        return (
          <Box key={`${name}-${code}`} sx={styles.specialityContainer}>
            <Typography variant="Paragraph LG/Regular">{name}</Typography>
            <Typography variant="Paragraph MD/Regular" color={NeutralColors.light_neutrals_600}>
              {code}
            </Typography>
          </Box>
        );
      })}
    </Stack>
  );

  const collegesElement = collegeNames.length > 0 && (
    <Stack gap="8px">
      <Typography variant="Paragraph MD/Regular" color={NeutralColors.light_neutrals_600}>
        Колледжи:
      </Typography>
      {collegeNames.map((college) => {
        return (
          <Typography key={college} variant="Paragraph LG/Regular">
            {college}
          </Typography>
        );
      })}
    </Stack>
  );

  const isDetailsVisible = specialitiesElement || collegesElement;

  return (
    <Box className="recommendation-card" sx={styles.root}>
      <Box className="recommendation-card__content" sx={styles.content}>
        <Typography variant="Paragraph LG/Semi Bold">{name}</Typography>

        {isDetailsVisible && (
          <Stack marginTop="16px" gap="16px">
            {specialitiesElement}
            {collegesElement}
          </Stack>
        )}
      </Box>
      {!isMediumScreen && <Box className="recommendation-card__image" sx={styles.image(illustration)}></Box>}
    </Box>
  );
};

export default RecommendationCard;
