/* eslint-disable prettier/prettier */
/* eslint-disable */

import { createSelector } from 'reselect';

import { IRootState } from '../../../reducers';
import { ICommonResponse, IInterestResponse } from '../../../api/types';
import {
  IInterestEntity,
  IInterestEditFormToggleableItem,
  InterestActionKind,
  InterestSubinterest
} from '../../../types';
import { getInterestCardImage } from '../../../containers/redesignStudent/StudentInterests/cardImages';
import { InterestSectionThemes } from '../../../containers/redesignStudent/StudentInterests/utils';
import { isDefined } from '../../../utils';

const profileInterestsSelector = createSelector(
  [
    (state: IRootState) => state.studentInterests,
    (state: IRootState) => state.interestActionKinds,
    (state: IRootState) => state.archivedInterestObjectKinds
  ],
  (studentInterests, interestActionKinds, interestObjectKinds): ICommonResponse<IInterestEntity> => {
    const sortDateDescending = (interestA: IInterestResponse, interestB: IInterestResponse) => (
      Number(new Date(interestB.creationDate)) - Number(new Date(interestA.creationDate))
    );
    const interests: (IInterestEntity | null)[] = [...studentInterests.content].sort(sortDateDescending).map((interestResponse) => {
      const headCode = interestResponse.interestHead.code;

      const interestHeadObjects = interestObjectKinds?.content?.[headCode]?.filter((interestObjectKind) => interestObjectKind.interestActionCode !== null);
      if (!interestHeadObjects) return null;

      const {
        value: interestName,
        code: interestCode,
        interestGroupCode
      } = interestResponse.interest;

      const { desktop, mobile } = getInterestCardImage(headCode, interestGroupCode, interestName);

      const activeActionKinds: InterestActionKind[] = interestResponse.interestAction.map((action) => ({
        code: action.code,
        value: action.value,
        sectionCode: action.section.code
      }));

      const activeSubinterests: InterestSubinterest[] =  interestResponse.subinterest.map((subinterest) => ({
        code: subinterest.code,
        value: subinterest.value
      }));

      const actionKinds: IInterestEditFormToggleableItem[] = interestResponse.interest.interestActionCode
        .map((actionCode) => interestActionKinds.content.find((actionKind) => actionKind.code === actionCode))
        .filter(isDefined)
        .map((actionKind) => ({
          code: actionKind.code,
          name: actionKind.value,
          isActive: !!activeActionKinds.find((activeActionKind) => activeActionKind.code === actionKind.code)
        }));

      const subinterests: IInterestEditFormToggleableItem[] = interestHeadObjects
        .filter((interest) => interest.parentId === interestCode)
        .map((interest) => ({
          code: interest.code,
          name: interest.value,
          isActive: !!activeSubinterests.find((activeSubinterest) => activeSubinterest.code === interest.code)
        }));

      return {
        id: interestResponse.id,
        code: interestCode,
        name: interestName,
        headCode: interestResponse.interestHead.code,
        image: desktop,
        mobileImage: mobile,
        overlayColor: InterestSectionThemes[interestResponse.interestHead.code].mainColor,
        actionKinds: activeActionKinds,
        subinterests: activeSubinterests,
        editFormData: {
          actions: actionKinds,
          categories: subinterests
        }
      };
    });

    return {
      ...studentInterests,
      content: interests?.filter(isDefined),
      loading: studentInterests.loading || interestActionKinds.loading || interestObjectKinds.loading
    };
  }
);

export default profileInterestsSelector;
