/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { SwitchProps } from '@mui/material';

import { Switch } from '../../../ui-kit';

import './index.scss';

interface ISettingsSwitchProps extends SwitchProps {}

const SettingsSwitch: React.FC<ISettingsSwitchProps> = (props) => {
  return (
    <Switch {...props} className="settings-switch" />
  );
};

export default SettingsSwitch;
