import { FC, forwardRef, ForwardRefRenderFunction, PropsWithChildren, ReactNode } from 'react';

import { Box, BoxProps } from '@mui/material';
import { SxStyles } from 'types';
import { mergeSx } from 'utils';

import { TableBody, TableDataCell, TableFoot, TableHead, TableHeadCell, TableRow } from './components';
import {
  TableDataCellProps,
  TableHeadCellProps,
  TableRowProps,
  TBodyProps,
  TFootProps,
  THeadProps,
} from './compositionProps';
import { TableContext } from './context';
import * as styles from './styles';

interface ITableComposition {
  TBody: FC<TBodyProps>;
  THead: FC<THeadProps>;
  TFoot: FC<TFootProps>;
  Row: FC<TableRowProps>;
  HeadCell: FC<TableHeadCellProps>;
  DataCell: FC<TableDataCellProps>;
}

interface ITableProps extends PropsWithChildren {
  headerElement?: ReactNode;
  tableProps?: BoxProps<'table'>;
  loading?: boolean;
  sx?: SxStyles;
}

const Table: ForwardRefRenderFunction<HTMLDivElement, ITableProps> = (
  { headerElement, tableProps, loading, sx, children },
  ref,
) => {
  return (
    <TableContext.Provider
      value={{
        loading: loading ?? false,
      }}
    >
      <Box ref={ref} className="ui-table" sx={mergeSx(styles.container, sx)}>
        {headerElement && (
          <Box className="ui-table__header" sx={styles.header}>
            {headerElement}
          </Box>
        )}
        <Box {...tableProps} component="table" sx={mergeSx(styles.table, tableProps?.sx)}>
          {children}
        </Box>
      </Box>
    </TableContext.Provider>
  );
};

const TableWithRef = forwardRef(Table);
const TableWithComposition: typeof TableWithRef & ITableComposition = TableWithRef as typeof TableWithRef &
  ITableComposition;

TableWithComposition.TBody = TableBody;
TableWithComposition.THead = TableHead;
TableWithComposition.TFoot = TableFoot;
TableWithComposition.Row = TableRow;
TableWithComposition.HeadCell = TableHeadCell;
TableWithComposition.DataCell = TableDataCell;

export default TableWithComposition;
