/* eslint-disable max-len */
import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconSectionCreation: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.887 8.252a8.99 8.99 0 0 0-2.085-4.212 9.008 9.008 0 0 0-8.627-2.843A9.011 9.011 0 0 0 3.99 3.343c-.118.104-.23.214-.338.33a8.953 8.953 0 0 0-.563.621l-.052.068a7.644 7.644 0 0 0-.75 1.154c-.076.135-.159.27-.234.412l-.202.42-.18.45c-.145.31-.266.63-.36.96a7.486 7.486 0 0 0-.218 1.153c0 .083 0 .165-.038.24-.037.075 0 .3-.037.442-.038.143 0 .308 0 .465a8.987 8.987 0 0 0 2.327 6.026A9.01 9.01 0 0 0 9.115 19h.556c.173.002.346-.01.518-.038a1.412 1.412 0 0 0 1.013-.712 1.498 1.498 0 0 0-.21-1.836 1.385 1.385 0 0 1 .555-2.249 4.842 4.842 0 0 1 1.666-.21c2.92-.18 4.744-1.274 5.494-3.065.315-.843.377-1.76.18-2.638ZM4.574 10.328a1.503 1.503 0 0 1-1.472-1.792 1.498 1.498 0 0 1 2.047-1.092 1.5 1.5 0 0 1 .926 1.385 1.498 1.498 0 0 1-1.5 1.499Zm2.95-3.942a1.503 1.503 0 0 1-1.472-1.792 1.498 1.498 0 0 1 2.046-1.092 1.5 1.5 0 0 1 .927 1.385 1.498 1.498 0 0 1-1.501 1.499Zm4.939 0a1.502 1.502 0 0 1-1.473-1.792 1.498 1.498 0 0 1 2.047-1.092 1.501 1.501 0 0 1 .927 1.385 1.498 1.498 0 0 1-1.501 1.499Zm2.957 3.942a1.502 1.502 0 0 1-1.472-1.792 1.498 1.498 0 0 1 1.179-1.177 1.503 1.503 0 0 1 1.794 1.47 1.498 1.498 0 0 1-1.501 1.499Z"
      fill={props.fill || 'currentColor' || '#FF922B'}
    />
  </SvgIcon>
);

export default IconSectionCreation;
