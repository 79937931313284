import { SET_ADMIN_VIEWING_MODE, SET_ADMIN_VIEWING_TYPE } from '../../actions';
import { AdminPortfolioViewType } from '../../types';
import { connectReducers } from '../../utils';
import { IReducers } from '..';

export type AdminViewModeState = {
  isViewing: boolean;
  viewType: AdminPortfolioViewType;
};

type SetModeAction = {
  payload: {
    isViewing: boolean;
  };
};

type SetTypeAction = {
  payload: {
    type: AdminPortfolioViewType;
  };
};

const initialState: AdminViewModeState = {
  isViewing: false,
  viewType: 'student',
};

const adminViewMode: IReducers<AdminViewModeState> = {
  [SET_ADMIN_VIEWING_MODE]: (state, action: SetModeAction) => {
    return {
      ...state,
      isViewing: action.payload.isViewing,
    };
  },
  [SET_ADMIN_VIEWING_TYPE]: (state, action: SetTypeAction) => {
    return {
      ...state,
      viewType: action.payload.type,
    };
  },
};

export default connectReducers(initialState, adminViewMode);
