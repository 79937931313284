/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconChevronLargeRight: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M16.5 12.012c0-.28-.116-.537-.3-.747l-6.91-6.95a1.026 1.026 0 0 0-1.478 0 1.049 1.049 0 0 0 0 1.493l6.192 6.204-6.169 6.18a1.049 1.049 0 0 0 0 1.493c.416.42 1.063.42 1.479 0l6.909-6.927c.185-.187.277-.466.277-.746Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconChevronLargeRight;
