import { SxStyles } from 'types';

export const mobileRoot: SxStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '12px',

  textAlign: 'center',
};

export const mobileIconContainer: SxStyles = {
  svg: {
    width: '48px',
    height: '48px',
  },
};
