/* eslint-disable max-len */
import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconCivilRewardsCommon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.5 1.7 11.7 0 13 1.7l2 .7-1.2 1.7v2.1l-2-.6-2 .6V4.1L8.5 2.3l2-.6ZM5.9 10l-1.7-.5-1.7.5V8.3l-1-1.4 1.7-.6 1-1.4 1 1.4 1.7.6-1 1.4V10Zm.5 14v-2.6c0-1.9 1.1-3.6 2.6-4.6-.7-.7-1.1-1.7-1.1-2.8 0-2.3 1.8-4.1 4.1-4.1s4.1 1.8 4.1 4.1c0 1.1-.4 2-1.1 2.8 1.5 1 2.5 2.6 2.6 4.6V24H6.4ZM21.5 8.2v1.7l-1.7-.5-1.7.6V8.3l-1-1.4 1.7-.6 1-1.4 1 1.4 1.7.6-1 1.3Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconCivilRewardsCommon;
