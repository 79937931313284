/* eslint-disable prettier/prettier */
/* eslint-disable */

import { isValidElement } from 'react';

import GroupedActionButton from './GroupedActionButton';
import { IGroupedActionButtonProps } from './types';

export function isGroupedActionButton(value: React.ReactElement | any): value is React.ReactElement<IGroupedActionButtonProps> {
  return isValidElement(value) && value?.type === GroupedActionButton;
};
