/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';
import { HorizontalBar } from '@reactchartjs/react-chart.js';
import { Colors } from '../../../style/variables';
import { Skeleton } from '../../../ui-kit';

import './index.scss';

interface IHorizontalBarChartSkeletonProps {}

const HorizontalBarChartSkeleton: React.FC<IHorizontalBarChartSkeletonProps> = () => {
  const incomingData = [12, 10, 7, 6, 5, 5, 5];
  const incomingLabels = ['Алгебра', 'Геометрия', 'Физика', 'Биология', 'Химия', 'Физкультура', 'Музыка'];
  const chartBasis = 20;
  const basisArray = incomingData.map((item) => chartBasis - item);

  const data = (canvas: any) => {
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, canvas.width, 0);
    gradient.addColorStop(0, '#D5CAFF');
    gradient.addColorStop(1, '#BBD6FF');
    return ({
      labels: incomingLabels,
      datasets: [
        {
          label: 'Количество выполнений',
          data: incomingData,
          backgroundColor: Colors['disable-input'],
          borderWidth: 0,
          barThickness: 12
        },
        {
          label: 'addition',
          data: basisArray,
          backgroundColor: Colors['page-background'],
          hoverBackgroundColor: Colors['page-background'],
          borderWidth: 0,
          barThickness: 12
        }
      ]
    });
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    scales: {
      yAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
            display: false
          },
          gridLines: {
            drawBorder: false,
            offsetGridLines: false,
            drawTicks: false
          },
          barPercentage: 1.0,
          categoryPercentage: 1.0
        }
      ],
      xAxes: [{
        stacked: true,
        gridLines: {
          borderDash: [5, 15],
          drawBorder: false,
          color: Colors['disable-input']
        },
        ticks: {
          display: false
        }
      }]
    },
    tooltips: {
      enabled: false
    }
  };
  return (
    <div className="bar-chart__skeleton">
      <div className="bar-chart__skeleton-labels">
        <Skeleton animation="wave" variant="text" height={7} width={133} marginBottom={33} backgroundColor={Colors['control-stroke']} />
        <Skeleton animation="wave" variant="text" height={7} width={133} marginBottom={33} backgroundColor={Colors['control-stroke']} />
        <Skeleton animation="wave" variant="text" height={7} width={133} marginBottom={33} backgroundColor={Colors['control-stroke']} />
        <Skeleton animation="wave" variant="text" height={7} width={133} marginBottom={33} backgroundColor={Colors['control-stroke']} />
        <Skeleton animation="wave" variant="text" height={7} width={133} marginBottom={33} backgroundColor={Colors['control-stroke']} />
        <Skeleton animation="wave" variant="text" height={7} width={133} marginBottom={33} backgroundColor={Colors['control-stroke']} />
        <Skeleton animation="wave" variant="text" height={7} width={133} marginBottom={33} backgroundColor={Colors['control-stroke']} />
      </div>
      <div className="bar-chart__skeleton-chart">
        <HorizontalBar data={data} options={options} type="bar" />
      </div>
    </div>
  );
};

export default HorizontalBarChartSkeleton;
