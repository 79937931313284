import { createContext } from 'react';

import { RendererType } from 'types';

interface IRendererContext {
  currentRenderer: RendererType | null;
  setCurrentRenderer: (renderer: RendererType | null) => void;
}

export default createContext({} as IRendererContext);
