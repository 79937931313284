import { CommonUiKitSize } from 'portfolio3/ui-kit/types';

import { ISwitcherTokens } from '..';

// -1 из-за бордера (настоящий 4)
const switcherPadding = 3;

export const SwitcherHeight: Record<CommonUiKitSize, number> = {
  small: 36,
  medium: 40,
  large: 48,
};

export const SwitcherButtonHeight: Record<CommonUiKitSize, number> = {
  // -2 для корректной высоты кнопки (28, 32, 40)
  small: SwitcherHeight.small - 2 - switcherPadding * 2,
  medium: SwitcherHeight.medium - 2 - switcherPadding * 2,
  large: SwitcherHeight.large - 2 - switcherPadding * 2,
};

export const getSwitcherTokens = (size: CommonUiKitSize): ISwitcherTokens => {
  return {
    height: SwitcherHeight[size],
    padding: switcherPadding,
    buttonHeight: SwitcherButtonHeight[size],
  };
};
