/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useMemo } from 'react';

import { connect } from 'react-redux';

import { IRootState } from '../../../../reducers';
import { ICommonResponse } from '../../../../api/types';
import PdfCommonDataEntityContainer from '../common';
import { PdfCommonDataEntity } from '../../../../components/pdfStudent/DataEntity';
import { widgetPdfIcons } from '../../../../icons';
import { sportClubsSelector } from '../../../../selectors';
import { IEntity } from '../../../../types';

import '../index.scss';
import { useDataLoad } from 'hooks';
import { getDataLoadRequests } from 'utils';

interface IPdfSportClubContainerProps {
  studentClubs: ICommonResponse<IEntity>
}

const PdfSportClubContainer: React.FC<IPdfSportClubContainerProps> = ({
  studentClubs
}) => {
  useDataLoad({ shouldLoad: true, requests: getDataLoadRequests().sportClubs });

  const filteredOlympiadsYear = useMemo(() => (studentClubs.content && studentClubs.content?.length > 0
    ? studentClubs.content?.reduce((prevItem, nextItem) => (prevItem.year > nextItem.year ? prevItem : nextItem)).year
    : undefined),
  [studentClubs]);

  return (
    <PdfCommonDataEntityContainer
      title="Клубы и команды"
      icon={widgetPdfIcons.pdfIconSportClubs}
    >
      {studentClubs.content
        .map((olympiad: IEntity) => (
          <PdfCommonDataEntity key={olympiad.id || olympiad.recordId} entity={olympiad} year={filteredOlympiadsYear} />
        ))}
    </PdfCommonDataEntityContainer>
  );
};

export default connect(
  (state: IRootState) => ({
    studentClubs: sportClubsSelector(state)
  })
)(PdfSportClubContainer);
