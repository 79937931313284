import { useUserFunctionality } from 'hooks';
import { AdminSettingsState } from 'reducers/admin/adminSettings';

import { ViewedFunctionCodes, ViewedFunctionKeys } from '../../../../const';
import { IUseFeaturePreview } from '../types';
import ThanksTeacherPreviewComponent from './component';

interface IUseThanksTeacherPreviewProps {
  adminSettings: AdminSettingsState;
}

const useThanksTeacherPreview = ({ adminSettings }: IUseThanksTeacherPreviewProps): IUseFeaturePreview => {
  const user = useUserFunctionality();

  const isLoading = adminSettings.loading ?? true;
  const hasAccess = user.isStudent;
  const functionCode = ViewedFunctionCodes[ViewedFunctionKeys.THANKS_TEACHER];

  return {
    isLoaded: !isLoading,
    hasAccess,
    functionCode,
    component: ThanksTeacherPreviewComponent,
  };
};

export default useThanksTeacherPreview;
