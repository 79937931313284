/* eslint-disable prettier/prettier */
/* eslint-disable */

import { getAccentColor } from 'portfolio3/styles';
import { Colors } from '../style/variables';
import { IEntityColors } from '../types';
import { reverseRecord } from '../utils/common';

export enum SectionKeys {
  PROFILE = 'profile',
  INTEREST = 'interest',
  RECOMMENDATIONS = 'recommendations',
  GOALS = 'goals',
  STUDY = 'study',
  SPORT = 'sport',
  SCIENCE = 'science',
  CULTURE = 'culture',
  CREATION = 'creation',
  CIVIL = 'civil',
  STUDY_EVENT = 'studyEvent',
  EVENT_OLYMPIAD = 'eventOlympiad',
  STUDY_REWARD = 'studyReward',
  REWARD_OLYMPIAD = 'rewardOlympiad',
  STUDY_SPO = 'studySpo',
  STUDY_SPO_TRAINING = 'studySpoTraining',
  STUDY_SPO_PRACTICE = 'studySpoPractice',
  STUDY_SPO_DOCUMENTS = 'studySpoDocuments',
  SCIENCE_EVENT = 'scienceEvent',
  CONTEST = 'contest',
  CONFERENCE = 'conference',
  SCIENCE_REWARD = 'scienceReward',
  CONTEST_REWARD = 'contestReward',
  PROJECT_REWARD = 'projectReward',
  PROJECT = 'project',
  SCIENCE_OCCUPATION = 'scienceOccupation',
  CULTURE_EVENT = 'cultureEvent',
  CULTURE_VISITING = 'cultureVisiting',
  MUSEUM_VISITING = 'museumVisiting',
  THEATRE_VISITING = 'theatreVisiting',
  CINEMA_VISITING = 'cinemaVisiting',
  CREATION_OCCUPATION = 'creationOccupation',
  CREATION_AFFILATION = 'creationAffilation',
  CREATION_COLLECTIVE = 'creationCollective',
  CREATION_ENSEMBLE = 'creationEnsemble',
  CREATION_CIRCLE = 'creationCircle',
  CREATION_EVENT = 'creationEvent',
  CREATION_REWARD = 'creationReward',
  CREATION_CONTEST = 'creationContest',
  CREATION_SCHOOL = 'creationSchool',
  CREATION_CONTEST_REWARD = 'creationContestReward',
  SPORT_AFFILATION = 'sportAffilation',
  SPORT_CLUB = 'sportClub',
  SPORT_TEAM = 'sportTeam',
  SPORT_CLUB_TEAM = 'sportClubTeam',
  SPORT_OCCUPATION = 'sportOccupation',
  SPORT_OCCUPATION_CIRCLE = 'sportOccupationCircle',
  SPORT_OCCUPATION_SECTION = 'sportOccupationSection',
  SPORT_OCCUPATION_PREPARING = 'sportOccupationPreparing',
  SPORT_EVENT = 'sportEvent',
  SPORT_EVENT_COMPETITION = 'sportEventCompetition',
  SPORT_EVENT_EXPEDITION = 'sportEventExpedition',
  SPORT_EVENT_TOURISM = 'sportEventTourism',
  SPORT_REWARD = 'sportReward',
  SPORT_RANK = 'sportRank',
  SPORT_CATEGORY = 'sportCategory',
  GTO_REWARD = 'gtoReward',
  SPORT_REWARD_TOURISM = 'sportRewardTourism',
  SPORT_REWARD_COMPETITION = 'sportRewardCompetition',
  CIVIL_AFFILATION = 'civilAffilation',
  CIVIL_AFFILATION_GROUP = 'civilAffilationGroup',
  CIVIL_AFFILATION_MOVEMENT = 'civilAffilationMovement',
  CIVIL_OCCUPATION = 'civilOccupation',
  CIVIL_OCCUPATION_CIRCLE = 'civilOccupationCircle',
  CIVIL_EVENT = 'civilEvent',
  CIVIL_EVENT_LOCAL = 'civilEventLocal',
  CIVIL_EVENT_ECOLOGY = 'civilEventEcology',
  CIVIL_EVENT_VOLONTEER = 'civilEventVolonteer',
  CIVIL_EVENT_PATRIOTIC = 'civilEventPatriotic',
  CIVIL_EVENT_QUEST = 'civilEventQuest',
  CIVIL_REWARD = 'civilReward',
  CIVIL_REWARD_STATUS = 'civilRewardStatus',
  CIVIL_REWARD_EVENT = 'civilRewardEvent',
  CULTURE_ONLINE = 'cultureOnline',
  SCIENCE_CIRCLE = 'scienceCircle',
  PROFESSION = 'profession',
  PROFESSION_REWARD = 'professionReward',
  PROFESSION_EVENT = 'professionEvent',
  PROFESSION_EXAM = 'professionExam',
  PROFESSION_EVENT_CHAMPIONSHIP = 'professionEventChampionship',
  PROFESSION_EXAM_GIA = 'professionExamGia',
  PROFESSION_REWARD_EVENT = 'professionRewardEvent',
  PROFESSION_SPO = 'professionSpo',
  PROFESSION_SPO_METASKILLS = 'professionSpoMetaskills',
  PROFESSION_SPO_JOB = 'professionSpoJob',
  RECOMMENDATION = 'recommendation',
  TRIAL_EXAM = 'trialExam',
  RECOMMENDATION_VUZ = 'recommendationVuz',
  FAVIRITE_UNIVERSITY = 'favoriteUniversity',
  OTHER = 'other'
}

// section_ref
export const SectionCodes: Record<SectionKeys, number> = {
  [SectionKeys.STUDY]: 1,
  [SectionKeys.SCIENCE]: 2,
  [SectionKeys.SPORT]: 3,
  [SectionKeys.CREATION]: 4,
  [SectionKeys.CULTURE]: 5,
  [SectionKeys.CIVIL]: 6,
  [SectionKeys.STUDY_EVENT]: 7,
  [SectionKeys.EVENT_OLYMPIAD]: 8,
  [SectionKeys.STUDY_REWARD]: 9,
  [SectionKeys.REWARD_OLYMPIAD]: 10,
  [SectionKeys.PROJECT]: 11,
  [SectionKeys.SCIENCE_OCCUPATION]: 12,
  [SectionKeys.SCIENCE_CIRCLE]: 13,
  [SectionKeys.SCIENCE_EVENT]: 14,
  [SectionKeys.CONTEST]: 15,
  [SectionKeys.CONFERENCE]: 16,
  [SectionKeys.SCIENCE_REWARD]: 17,
  [SectionKeys.CONTEST_REWARD]: 18,
  [SectionKeys.PROJECT_REWARD]: 19,
  [SectionKeys.SPORT_AFFILATION]: 20,
  [SectionKeys.SPORT_CLUB]: 21,
  [SectionKeys.SPORT_TEAM]: 22,
  [SectionKeys.SPORT_OCCUPATION]: 23,
  [SectionKeys.SPORT_OCCUPATION_CIRCLE]: 24,
  [SectionKeys.SPORT_OCCUPATION_SECTION]: 25,
  [SectionKeys.SPORT_OCCUPATION_PREPARING]: 26,
  [SectionKeys.SPORT_EVENT]: 27,
  [SectionKeys.SPORT_EVENT_COMPETITION]: 28,
  [SectionKeys.SPORT_EVENT_EXPEDITION]: 29,
  [SectionKeys.SPORT_EVENT_TOURISM]: 30,
  [SectionKeys.SPORT_REWARD]: 31,
  [SectionKeys.GTO_REWARD]: 32,
  // спорт награда спортивное звание
  [SectionKeys.SPORT_RANK]: 33,
  // спорт награда спортивный разряд
  [SectionKeys.SPORT_CATEGORY]: 34,
  // спорт награда знак отличия по туризму (ступени)
  [SectionKeys.SPORT_REWARD_TOURISM]: 35,
  [SectionKeys.SPORT_REWARD_COMPETITION]: 36,
  [SectionKeys.CREATION_AFFILATION]: 37,
  [SectionKeys.CREATION_COLLECTIVE]: 38,
  [SectionKeys.CREATION_ENSEMBLE]: 39,
  [SectionKeys.CREATION_OCCUPATION]: 40,
  [SectionKeys.CREATION_CIRCLE]: 41,
  [SectionKeys.CREATION_SCHOOL]: 42,
  [SectionKeys.CREATION_EVENT]: 43,
  [SectionKeys.CREATION_CONTEST]: 44,
  [SectionKeys.CREATION_REWARD]: 45,
  [SectionKeys.CREATION_CONTEST_REWARD]: 46,
  [SectionKeys.CULTURE_EVENT]: 47,
  [SectionKeys.CULTURE_VISITING]: 48,
  [SectionKeys.CIVIL_AFFILATION]: 49,
  [SectionKeys.CIVIL_AFFILATION_GROUP]: 50,
  [SectionKeys.CIVIL_AFFILATION_MOVEMENT]: 51,
  [SectionKeys.CIVIL_OCCUPATION]: 52,
  [SectionKeys.CIVIL_OCCUPATION_CIRCLE]: 53,
  [SectionKeys.CIVIL_EVENT]: 54,
  [SectionKeys.CIVIL_EVENT_LOCAL]: 55,
  [SectionKeys.CIVIL_EVENT_ECOLOGY]: 56,
  [SectionKeys.CIVIL_EVENT_VOLONTEER]: 57,
  [SectionKeys.CIVIL_EVENT_PATRIOTIC]: 58,
  [SectionKeys.CIVIL_EVENT_QUEST]: 59,
  [SectionKeys.CIVIL_REWARD]: 60,
  [SectionKeys.CIVIL_REWARD_STATUS]: 61,
  [SectionKeys.CIVIL_REWARD_EVENT]: 62,
  [SectionKeys.CULTURE_ONLINE]: 63,
  [SectionKeys.OTHER]: 64,
  [SectionKeys.PROFILE]: 65,
  [SectionKeys.INTEREST]: 66,
  [SectionKeys.RECOMMENDATIONS]: 67,
  [SectionKeys.MUSEUM_VISITING]: 250,
  [SectionKeys.THEATRE_VISITING]: 253,
  [SectionKeys.CINEMA_VISITING]: 255,
  // TODO поменять на реальные значения
  [SectionKeys.GOALS]: 1236,

  [SectionKeys.PROFESSION]: 68,
  [SectionKeys.PROFESSION_REWARD]: 69,
  [SectionKeys.PROFESSION_EVENT]: 70,
  [SectionKeys.PROFESSION_EXAM]: 71,
  [SectionKeys.PROFESSION_EVENT_CHAMPIONSHIP]: 72,
  [SectionKeys.PROFESSION_EXAM_GIA]: 73,
  [SectionKeys.PROFESSION_REWARD_EVENT]: 74,
  [SectionKeys.SPORT_CLUB_TEAM]: 75,

  studySpo: 76,
  studySpoTraining: 77,
  studySpoPractice: 78,
  studySpoDocuments: 79,
  professionSpo: 80,
  professionSpoMetaskills: 81,
  professionSpoJob: 82,

  trialExam: 83,

  recommendation: 84,
  recommendationVuz: 85,
  favoriteUniversity: 86,
};

export const SectionCodeToKey = reverseRecord(SectionCodes);

export const SectionNames: Record<string, string> = {
  [SectionKeys.PROFILE]: 'Мой профиль',
  [SectionKeys.STUDY]: 'Учёба',
  [SectionKeys.SPORT]: 'Спорт',
  [SectionKeys.SCIENCE]: 'Наука',
  [SectionKeys.CULTURE]: 'Культура',
  [SectionKeys.CREATION]: 'Творчество',
  [SectionKeys.CIVIL]: 'Гражданская Активность',
  [SectionKeys.PROFESSION]: 'Моя профессия',
  [SectionKeys.RECOMMENDATION]: 'Рекомендации',
  [SectionKeys.OTHER]: 'Другое'
};

interface ISectionColors {
  activeBackground: string,
  activeIconBackground: string;
}

type SectionColors = IEntityColors & ISectionColors

type SectionColorSet = {
  [key: string]: SectionColors;
}
/** @deprecated */
const SECTION_COLORS: SectionColorSet = {
  [SectionKeys.PROFILE]: {
    background: '#E7F4FF',
    text: '#1864AB',
    pin: '#E7F4FF',
    activeBackground: '#339AF0',
    activeIconBackground: '#74C0FC'
  },
  [SectionKeys.STUDY]: {
    background: Colors['violet-medium'],
    text: '#5F3DC4',
    pin: '#D0BFFF',
    activeBackground: Colors['violet-strong'],
    activeIconBackground: '#B197FC'
  },
  [SectionKeys.SPORT]: {
    background: '#E1FAFC',
    text: '#0B7285',
    pin: '#99E9F2',
    activeBackground: Colors['cyan-strong'],
    activeIconBackground: '#66D9E8'
  },
  [SectionKeys.SCIENCE]: {
    background: '#ECF1FF',
    text: '#364FC7',
    pin: '#BAC8FF',
    activeBackground: Colors['blue-strong'],
    activeIconBackground: '#91A7FF'
  },
  [SectionKeys.CREATION]: {
    background: '#FFF3E5',
    text: '#D9480F',
    pin: '#FFD8A8',
    activeBackground: Colors['orange-strong'],
    activeIconBackground: '#FFC078'
  },
  [SectionKeys.CULTURE]: {
    background: '#FFF0F0',
    text: '#F03E3E',
    pin: '#FFC9C9',
    activeBackground: Colors['red-strong'],
    activeIconBackground: '#FFA8A8'
  },
  [SectionKeys.CIVIL]: {
    background: '#E8FBEB',
    text: '#2B8A3E',
    pin: '#B2F2BB',
    activeBackground: Colors['green-strong'],
    activeIconBackground: '#8CE99A'
  },
  [SectionKeys.PROFESSION]: {
    background: Colors.grape_02,
    text: Colors.grape_09,
    pin: Colors.grape_04,
    activeBackground: Colors.grape_07,
    activeIconBackground: Colors.grape_05
  },
  [SectionKeys.RECOMMENDATION]: {
    background: getAccentColor('pink', '8'),
    text: getAccentColor('pink', '200'),
    pin: getAccentColor('pink', '48'),
    activeBackground: getAccentColor('pink', '100'),
    activeIconBackground: getAccentColor('pink', '200'),
  },
};

/** @deprecated */
export const SectionColors: Record<string, SectionColors> = {
  [SectionKeys.PROFILE]: SECTION_COLORS.profile,
  [SectionKeys.STUDY]: SECTION_COLORS.study,
  [SectionKeys.SPORT]: SECTION_COLORS.sport,
  [SectionKeys.SCIENCE]: SECTION_COLORS.science,
  [SectionKeys.CREATION]: SECTION_COLORS.creation,
  [SectionKeys.CULTURE]: SECTION_COLORS.culture,
  [SectionKeys.CIVIL]: SECTION_COLORS.civil,
  [SectionKeys.PROFESSION]: SECTION_COLORS.profession,
  [SectionKeys.RECOMMENDATION]: SECTION_COLORS.recommendation,
};

export const SectionCodeColors: Record<number, SectionColors> = {
  [SectionCodes[SectionKeys.PROFILE]]: SECTION_COLORS.profile,
  [SectionCodes[SectionKeys.STUDY]]: SECTION_COLORS.study,
  [SectionCodes[SectionKeys.SPORT]]: SECTION_COLORS.sport,
  [SectionCodes[SectionKeys.SCIENCE]]: SECTION_COLORS.science,
  [SectionCodes[SectionKeys.CREATION]]: SECTION_COLORS.creation,
  [SectionCodes[SectionKeys.CULTURE]]: SECTION_COLORS.culture,
  [SectionCodes[SectionKeys.CIVIL]]: SECTION_COLORS.civil,
  [SectionCodes[SectionKeys.PROFESSION]]: SECTION_COLORS.profession,
  [SectionCodes.recommendation]: SECTION_COLORS.recommendation,
};

export const SectionColorsFallback: IEntityColors = {
  background: '#ECF1FF',
  text: '#364FC7',
  pin: '#ECF1FF'
};

/** @deprecated */
export const SectionBageBackground: Record<string, string> = {
  [SectionKeys.PROFILE]: '#339AF0',
  [SectionKeys.STUDY]: Colors['violet-strong'],
  [SectionKeys.SPORT]: Colors['cyan-strong'],
  [SectionKeys.SCIENCE]: Colors['blue-strong'],
  [SectionKeys.CULTURE]: Colors['red-strong'],
  [SectionKeys.CREATION]: Colors['orange-strong'],
  [SectionKeys.CIVIL]: Colors['green-strong'],
  [SectionKeys.PROFESSION]: Colors.grape_07
};

/** @deprecated */
export const SectionActiveIconBackground: Record<string, string> = {
  [SectionKeys.PROFILE]: '#74C0FC',
  [SectionKeys.STUDY]: '#B197FC',
  [SectionKeys.SPORT]: '#66D9E8',
  [SectionKeys.SCIENCE]: '#91A7FF',
  [SectionKeys.CULTURE]: '#FFA8A8',
  [SectionKeys.CREATION]: '#FFC078',
  [SectionKeys.CIVIL]: '#8CE99A',
  [SectionKeys.PROFESSION]: Colors.grape_05
};

export enum SportSubcategoryKeys {
  SPORT = 'sport',
  TOURISM = 'tourism',
}

export const SportSubcategoryCodes: Record<string, number> = {
  [SportSubcategoryKeys.SPORT]: 12,
  [SportSubcategoryKeys.TOURISM]: 13
};
