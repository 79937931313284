/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import {
  Chip as ChipMaterial,
  ChipProps
} from '@mui/material';

import {
  Colors
} from '../../style/variables';

interface IChipProps extends ChipProps {
  width?: string;
}

const useStyles = makeStyles({
  chip: ({ width }: IChipProps) => ({
    backgroundColor: Colors['page-background'],
    borderRadius: '8px',
    outline: 'none',
    margin: '2px',
    maxWidth: width,
    textOverflow: width ? 'ellipsis' : undefined,
    '& .MuiChip-label': {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: '400',
      color: Colors['main-text'],
    }
  })
});

const Chip: React.FC<IChipProps> = (props) => {
  const classes = useStyles(props);

  return (
    <ChipMaterial
      classes={{
        root: classes.chip
      }}
      {...props}
    />
  );
};

export default Chip;
