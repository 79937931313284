import { isDefined } from 'utils';

import { ISearchVuz } from '../../types';
import {
  filterEverySpecialitySubjectIncludedInSubjectsFactory,
  filterEverySubjectIncludedInSpecialitySubjectsFactory,
} from '../../utils';

export const mapVuzByHigherBallToggleFilter = (vuz: ISearchVuz): ISearchVuz | null => {
  const filteredSpecialities = vuz.specialities.filter((speciality) => {
    const { budgetMinBall, paidMinBall, studentSubjectsBallSummary } = speciality;

    // получаем сумму баллов по необходимым предметам для специальности
    const studentBallSummary = studentSubjectsBallSummary ?? 0;

    // сравниваем баллы учащегося с баллами специальности
    const hasBudgetMinBall = isDefined(budgetMinBall);
    const hasPaidMinBall = isDefined(paidMinBall);
    const isNotPassesBudgetMinBall = hasBudgetMinBall && studentBallSummary < budgetMinBall;
    const isNotPassesPaidMinBall = hasPaidMinBall && studentBallSummary < paidMinBall;

    if (!hasBudgetMinBall && !hasPaidMinBall) return false;

    return isNotPassesBudgetMinBall || isNotPassesPaidMinBall;
  });

  if (filteredSpecialities.length === 0) return null;

  return {
    ...vuz,
    specialities: filteredSpecialities,
  };
};

export const mapVuzWithEverySubjectIncludedInSpecialitySubjects = (
  vuz: ISearchVuz,
  selectedSubjects: string[],
): ISearchVuz | null => {
  const filterSpecialityPredicate = filterEverySubjectIncludedInSpecialitySubjectsFactory(selectedSubjects);
  const filteredSpecialities = vuz?.specialities.filter(filterSpecialityPredicate);

  if (filteredSpecialities?.length === 0) return null;

  return {
    ...vuz,
    specialities: filteredSpecialities,
  };
};

export const mapVuzWithEverySpecialitySubjectIncludedInSubjects = (
  vuz: ISearchVuz,
  selectedSubjects: string[],
  refSubjects: string[],
): ISearchVuz | null => {
  const filterSpecialityPredicate = filterEverySpecialitySubjectIncludedInSubjectsFactory(
    selectedSubjects,
    refSubjects,
  );
  const filteredSpecialities = vuz?.specialities.filter(filterSpecialityPredicate);

  if (filteredSpecialities?.length === 0) return null;

  return {
    ...vuz,
    specialities: filteredSpecialities,
  };
};
