import { FC } from 'react';

import { Box, Typography, useMediaQuery } from '@mui/material';
import { IIndependentSelfDiagnostic } from 'api/types';
import ResultDoughnut from 'components/common/ResultDoughnut';
import { DiagnosticLevelColorSet1, DiagnosticLevelColorSet2 } from 'const';
import { NeutralColors } from 'portfolio3/styles';
import { commonTheme } from 'portfolio3/styles/theme';
import { formatDate, getDiagnosticLevelTypeColor, getDoughnutData, getMarkPluralization } from 'utils';

import BaseCard from '../BaseCard';
import * as styles from './styles';

interface IIndependentDiagnosticCardProps {
  diagnostic: IIndependentSelfDiagnostic;
  onView: () => void;
}

const IndependentDiagnosticCard: FC<IIndependentDiagnosticCardProps> = ({ diagnostic, onView }) => {
  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonMd'));

  const { name, subject, eventDate, resultValue, maxResult, levelType } = diagnostic;

  const date = formatDate(eventDate);

  const progressValue = Math.round((resultValue * 100) / maxResult);
  const doughnutData = getDoughnutData(resultValue, maxResult);
  const diagnosticLevelTypeColor = getDiagnosticLevelTypeColor(
    levelType || '',
    isMobile ? DiagnosticLevelColorSet2 : DiagnosticLevelColorSet1,
  );
  const pluralizedResultValue = getMarkPluralization(resultValue);

  const descriptionElement = (
    <Box>
      <Typography variant="Paragraph MD/Semi Bold" color={NeutralColors.light_neutrals_600} noWrap>
        Твой результат:
      </Typography>
      <Typography variant="Paragraph LG/Medium" marginTop="8px">
        {resultValue} {pluralizedResultValue} из {maxResult}
      </Typography>
      {levelType && (
        <Typography variant="Paragraph MD/Semi Bold" color={diagnosticLevelTypeColor}>
          {levelType.toLowerCase()}
        </Typography>
      )}
    </Box>
  );

  const chartElement = (
    <Box sx={styles.chart}>
      <ResultDoughnut total={`${progressValue}%`} data={doughnutData} color={diagnosticLevelTypeColor} />
    </Box>
  );

  const rightElement = (
    <Box className="diagnostic-chart" sx={styles.rightElement}>
      {!isMobile && descriptionElement}
      {chartElement}
    </Box>
  );

  return <BaseCard title={name} place={subject} dateElement={date} rightElement={rightElement} onView={onView} />;
};

export default IndependentDiagnosticCard;
