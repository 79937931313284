import { FC } from 'react';

import { professionNavigation, StudentSectionSettingTypeCodes } from 'const';
import { useAppSelector, useBrightTheme, useWidgetVisibility } from 'hooks';
import { WidgetContainer as Widget } from 'portfolio3/components/common/WidgetContainer';
import { WidgetContainerTitle } from 'portfolio3/components/widgetContainerRecipes';

import ProforientationTestCardList from '../components/ProforientationTestCardList';
import { proforientationTestsSelector, proforientationVisibilitySelector } from '../model/selectors';

const sectionTypeCode = StudentSectionSettingTypeCodes.profession;
const subsectionTypeCode = StudentSectionSettingTypeCodes.professionProforientationTests;

const navigationData = professionNavigation.proforientationTests;

const ProforientationTestsWidget: FC = () => {
  const isBrightTheme = useBrightTheme();
  const { isSettingsMode, isWidgetVisible, toggleElement, overlayElement } = useWidgetVisibility({
    sectionTypeCode,
    subsectionTypeCode,
  });

  const proforientationTests = useAppSelector(proforientationTestsSelector);
  const { isProforientationTestsVisible } = useAppSelector(proforientationVisibilitySelector);

  const widgetTitleElement = (
    <WidgetContainerTitle title={navigationData.label} right={isSettingsMode && toggleElement} />
  );

  if (!isWidgetVisible || !isProforientationTestsVisible) return null;

  return (
    <Widget containerData={navigationData} withBackground={isBrightTheme}>
      {overlayElement}
      <Widget.Header title={widgetTitleElement} />
      <Widget.Padding>
        <ProforientationTestCardList tests={proforientationTests} />
      </Widget.Padding>
    </Widget>
  );
};

export default ProforientationTestsWidget;
