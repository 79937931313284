import { autocompleteClasses, SxProps } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';
import { typographySettings } from 'portfolio3/styles/theme';

export const baseOptionStyles = (selected: boolean): SxProps => {
  const backgroundColor = selected ? NeutralColors.light_neutrals_300 : undefined;
  const hoverBackgroundColor = selected ? NeutralColors.light_neutrals_400 : NeutralColors.light_neutrals_200;

  return {
    p: 1,
    cursor: 'pointer',
    backgroundColor: `${backgroundColor} !important`,

    [`&:hover, &.${autocompleteClasses.focused}`]: {
      backgroundColor: `${hoverBackgroundColor} !important`,
    },
  };
};

export const organizationOptionStyles = (selected: boolean): SxProps => {
  const baseStyles = baseOptionStyles(selected);

  return {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    '& > *': {
      alignSelf: 'flex-start',

      ...typographySettings['Paragraph MD/Regular'],
    },

    ...baseStyles,
  };
};
