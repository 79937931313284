import { createSelector } from 'reselect';

import { IRootState } from '../../../reducers';
import { mapCivilSectionsResponse } from './mappers';

const civilSectionsSelector = createSelector(
  [(state: IRootState) => state.studentEmployments, (state: IRootState) => state.sectionRef],
  (studentEmployments, sectionRef) => {
    return mapCivilSectionsResponse(studentEmployments, sectionRef);
  },
);

export default civilSectionsSelector;
