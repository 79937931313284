import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setDisplayedEntityCountAction } from 'actions';
import { WIDGET_FILTER_ENTITIES_ALL, WIDGET_FILTER_ENTITIES_WITH_REWARD } from 'const';
import { useAppSelector } from 'hooks';
import { IRootState } from 'reducers';
import { IEntity } from 'types';

interface IProps {
  containerId: string;
  entities: IEntity[];
  initialEntityCount: number;
}

type UseEntityFiltersReturnType = {
  filteredEntities: IEntity[] | undefined;
  rewardEntities: IEntity[];
  displayedEntityCount: number;
  hiddenEntityCount: number;
  allShown: boolean;
  year: number | undefined;
  filterValue: number;
  setInitialEntityCount: () => void;
  setAllEntityCount: () => void;
  setFilterValue: (value: number) => void;
};

export default ({ containerId, entities, initialEntityCount = 5 }: IProps): UseEntityFiltersReturnType => {
  const dispatch = useDispatch();
  const displayedEntityCount = useAppSelector(
    (state: IRootState) => state.displayedEntityCount[containerId] || initialEntityCount,
  );

  const [filteredEntities, setFilteredEntities] = useState<IEntity[]>(entities);
  const [filterValue, setFilterValue] = useState(WIDGET_FILTER_ENTITIES_ALL);

  const hiddenEntityCount =
    filteredEntities.length > initialEntityCount ? filteredEntities.length - initialEntityCount : 0;
  const allShown = filteredEntities.length === displayedEntityCount && displayedEntityCount !== initialEntityCount;

  const year = useMemo(
    () =>
      filteredEntities.length > 0
        ? filteredEntities.reduce((prev, next) => (prev.year > next.year ? prev : next)).year
        : undefined,
    [filteredEntities],
  );

  const rewardEntities = useMemo(() => entities.filter((entity) => entity.reward), [entities]);

  useEffect(() => {
    setFilteredEntities(filterValue === WIDGET_FILTER_ENTITIES_WITH_REWARD ? rewardEntities : entities);
  }, [filterValue, entities, rewardEntities]);

  const setInitialEntityCount = () => {
    dispatch(setDisplayedEntityCountAction(containerId, initialEntityCount));
  };

  const setAllEntityCount = () => {
    dispatch(setDisplayedEntityCountAction(containerId, filteredEntities.length || initialEntityCount));
  };

  return {
    filteredEntities,
    rewardEntities,
    displayedEntityCount,
    hiddenEntityCount,
    allShown,
    year,
    filterValue,
    setInitialEntityCount,
    setAllEntityCount,
    setFilterValue,
  };
};
