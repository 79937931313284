import React, { useState } from 'react';

import { Box } from '@mui/material';
import { format } from 'date-fns';
import { BaseInputLabel, FormControl } from 'portfolio3/ui-kit';
import { DayRangePicker, DayRangePickerController } from 'portfolio3/ui-kit/datePickers';

import { IDictionaryItem } from '../../../../api/types';
import { MultipleSearchSelect } from '../../../../components/common/Select';
import { IconDownloadFile } from '../../../../icons';
import { Button } from '../../../../ui-kit';

import './index.scss';

interface ISearcherProps {
  typeKinds: IDictionaryItem[];
  typeTitle: string;
  typeLabel: string;
  typePlaceholder: string;
  onDownloadFile: (startDate: string, endDate: string, types: number[]) => void;
}

const Searcher: React.FC<ISearcherProps> = ({ typeKinds, typeTitle, typeLabel, typePlaceholder, onDownloadFile }) => {
  const today = new Date();

  const [actionType, setActionType] = useState('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const actionTypeController = {
    handleChange: (searchString: string) => {
      setActionType(searchString);
    },
  };

  const dateController: DayRangePickerController = {
    handleChange(value) {
      setStartDate(value.start);
      setEndDate(value.end);
    },
  };

  const handleDownloadLog = () => {
    if (!startDate || !endDate || !actionType) return;
    onDownloadFile(
      format(startDate, 'yyyy-MM-dd'),
      format(endDate, 'yyyy-MM-dd'),
      actionType.split(',').map((type) => Number(type)),
    );
  };

  const handleDownloadLogWithEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleDownloadLog();
    }
  };

  return (
    <section className="searcher">
      <div className="searcher-container">
        <Box
          component="form"
          role="presentation"
          onKeyPress={handleDownloadLogWithEnter}
          className="searcher__form"
          sx={{ display: 'grid', gap: '16px', gridAutoFlow: 'column', gridTemplateColumns: '280px 1fr', width: '100%' }}
        >
          <FormControl
            renderMode="fixed"
            inputSize="medium"
            label={<BaseInputLabel>Период</BaseInputLabel>}
            control={
              <DayRangePicker
                startValue={startDate}
                endValue={endDate}
                controller={dateController}
                maxValue={today}
                presets={['today', 'last 2 days', 'last 7 days', 'selected range']}
              />
            }
          />
          <FormControl
            renderMode="fixed"
            inputSize="medium"
            label={<BaseInputLabel>{typeTitle}</BaseInputLabel>}
            control={
              <MultipleSearchSelect
                searchController={actionTypeController}
                value={actionType}
                options={typeKinds}
                hasGlobalOption
                label={typeLabel}
                placeholder={typePlaceholder}
              />
            }
          />
        </Box>
        <Button
          className="searcher__control-button"
          onClick={handleDownloadLog}
          disabled={!startDate || !endDate || !actionType}
          startIcon={<IconDownloadFile />}
        >
          Скачать
        </Button>
      </div>
    </section>
  );
};

export default Searcher;
