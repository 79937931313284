import { FC, ReactNode } from 'react';

import { ThemeProvider } from '@mui/material';
import { professionNavigation, SectionKeys, SectionNames, sectionRoutes } from 'const';
import { useSectionTheme, useStudentNavigation } from 'hooks';
import { IconSectionProfession } from 'icons';
import { professionTheme } from 'portfolio3/styles/theme';

import { MetaskillsCard } from '../components/cards';
import SearchSection from '../components/SearchSection';
import { useSearchProfession } from '../hooks';
import CommonEntityCardList from './CardLists/CommonEntityCardList';
import CommonRewardCardList from './CardLists/CommonRewardCardList';
import GiaWorldskillsCardList from './CardLists/GiaWorldskillsCardList';

interface IProfessionSectionProps {
  onCloseSearcher: () => void;
}

const ProfessionSection: FC<IProfessionSectionProps> = ({ onCloseSearcher }) => {
  const {
    rewards,
    jobs,
    metaskills,
    additionalEducation,
    events,
    giaWorldskills,
    isProfessionRewardVisible,
    isProfessionJobVisible,
    isProfessionMetaskillsVisible,
    isProfessionAdditionalEducationVisible,
    isProfessionEventVisible,
    isProfessionGiaWorldskillsVisible,
    isProfessionVisible,
  } = useSearchProfession();

  const { navigateToSection, navigateToWidget } = useStudentNavigation();

  const theme = useSectionTheme(professionTheme);

  if (!isProfessionVisible) return null;

  const viewMetaskillsCard = () => {
    onCloseSearcher();
    navigateToSection(sectionRoutes.PROFESSION);
    navigateToWidget(professionNavigation.metaskills.id, true);
  };

  const subsections: ReactNode[] = [];

  if (isProfessionRewardVisible) {
    subsections.push(
      <SearchSection.Subsection title="Награды">
        <CommonRewardCardList rewards={rewards} />
      </SearchSection.Subsection>,
    );
  }

  if (isProfessionJobVisible) {
    subsections.push(
      <SearchSection.Subsection title="Трудоустройство">
        <CommonEntityCardList entities={jobs} />
      </SearchSection.Subsection>,
    );
  }

  if (isProfessionMetaskillsVisible) {
    const cards = metaskills.map((metaskillCategory, index, array) => {
      const { name, iconElement, childMetaskills } = metaskillCategory;
      const skills = childMetaskills.map((childMetaskill) => childMetaskill.name);

      const card = <MetaskillsCard header={name} icon={iconElement} skills={skills} onView={viewMetaskillsCard} />;

      return (
        <SearchSection.CardContainer
          key={metaskillCategory.code}
          card={card}
          mapIndex={index}
          totalCards={array.length}
          onView={viewMetaskillsCard}
        />
      );
    });
    subsections.push(<SearchSection.Subsection title="Метанавыки">{cards}</SearchSection.Subsection>);
  }

  if (isProfessionAdditionalEducationVisible) {
    subsections.push(
      <SearchSection.Subsection title="Профессиональное обучение">
        <CommonEntityCardList entities={additionalEducation} />
      </SearchSection.Subsection>,
    );
  }

  if (isProfessionEventVisible) {
    subsections.push(
      <SearchSection.Subsection title="Мероприятия">
        <CommonEntityCardList entities={events} />
      </SearchSection.Subsection>,
    );
  }

  if (isProfessionGiaWorldskillsVisible) {
    subsections.push(
      <SearchSection.Subsection title="Демонстрационные экзамены">
        <GiaWorldskillsCardList giaWorldskills={giaWorldskills} />
      </SearchSection.Subsection>,
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <SearchSection
        name={SectionNames[SectionKeys.PROFESSION]}
        icon={<IconSectionProfession />}
        subsections={subsections}
      />
    </ThemeProvider>
  );
};

export default ProfessionSection;
