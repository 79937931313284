import { FC, MouseEventHandler, useState } from 'react';

import { Box } from '@mui/material';
import { NeutralBadge, PartiallyVerifiedBadge, StrongAccentBadge, VerifiedBadge } from 'portfolio3/ui-kit/badges';
import { IEntityFeature, SxStyles } from 'types';
import { mergeSx } from 'utils';

import JobPartialApprovePopper from '../JobPartialApprovePopper';
import TrimText from '../TrimText';
import * as styles from './styles';

interface ITagLineProps {
  tags: IEntityFeature[];
  isApproved?: boolean;
  isPartiallyApproved?: boolean;
  sx?: SxStyles;
}

const TagLine: FC<ITagLineProps> = ({ tags, isApproved, isPartiallyApproved, sx }) => {
  const [partialApprovedAnchorEl, setPartialApprovedAnchorEl] = useState<Element | null>(null);
  const [isPartialApprovedPopperOpen, setPartialApprovedPopperOpen] = useState(false);

  const handleClick: MouseEventHandler = (event) => {
    setPartialApprovedAnchorEl(event.currentTarget);
    setPartialApprovedPopperOpen(true);
  };

  return (
    <Box className="tag-line" sx={mergeSx(styles.root, sx)}>
      {tags.map(({ isColored, text }) => {
        const textElement = <TrimText>{text}</TrimText>;

        return isColored ? (
          <StrongAccentBadge key={text}>{textElement}</StrongAccentBadge>
        ) : (
          <NeutralBadge key={text}>{textElement}</NeutralBadge>
        );
      })}
      {isApproved && !isPartiallyApproved && <VerifiedBadge />}
      {isPartiallyApproved && (
        <Box onClick={handleClick} sx={styles.partialApprovePopperTarget}>
          <PartiallyVerifiedBadge />

          {/* Пока реализация только для Job сущностей */}
          {/* При необходимости добавить новые входные параметры компоненту TagLine */}
          <JobPartialApprovePopper
            anchorEl={partialApprovedAnchorEl}
            isOpen={isPartialApprovedPopperOpen}
            onClose={() => setPartialApprovedPopperOpen(false)}
          />
        </Box>
      )}
    </Box>
  );
};

export default TagLine;
