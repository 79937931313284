import { FC } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { Button } from 'portfolio3/ui-kit/button';

import { styles } from './styles';

interface ISettingsHeaderProps {
  isSaveButtonDisabled: boolean;
  onSettingsSave: () => void;
}

const SettingsHeader: FC<ISettingsHeaderProps> = ({ isSaveButtonDisabled, onSettingsSave }) => {
  return (
    <Box sx={styles}>
      <Stack gap={0.5}>
        <Typography variant="Headings/H5">Сейчас ты находишься в режиме настройки портфолио</Typography>
        <Typography variant="Paragraph LG/Regular">
          Здесь ты можешь настраивать отображение информации в своем портфолио
        </Typography>
      </Stack>
      <Button size="large" disabled={isSaveButtonDisabled} onClick={onSettingsSave}>
        Сохранить и выйти из настроек
      </Button>
    </Box>
  );
};

export default SettingsHeader;
