/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { FC } from 'react';

import { IconButton } from '@mui/material';

import { IconDownloadFile } from '../../../../../icons';
import { getFileExtenson } from '../../../../../utils';
import { IStudentFile } from '../../../../../api/types';
import { ICardData } from '../../../../../types';

import './index.scss';

export interface IFilesTabProps {
  cardData: ICardData;
  onFileDownload: (file: IStudentFile) => void;
}

const getFileClassName = (extension: string) => {
  switch (extension) {
    case 'JPG':
    case 'BMP':
    case 'JPEG':
    case 'PNG':
      return 'file--type-image';
    case 'PDF':
      return 'file--type-pdf';
    case 'PPT':
      return 'file--type-presentation';
    case 'XLS':
    case 'XLSX':
      return 'file--type-excel';
    default:
      return 'file--type-document';
  }
};

const FilesTab: FC<IFilesTabProps> = ({
  cardData,
  onFileDownload
}) => {
  if (!cardData.files || cardData.files.length === 0) return null;

  return (
    <div className="files-container">
      {cardData.files?.map((file) => (
        <div className={`file ${getFileClassName(getFileExtenson(file.name))}`} key={file.id}>
          <div className="file__top-container">
            <p className="file__extension">{getFileExtenson(file.name)}</p>
          </div>
          <IconButton
            className="btn-download"
            disableTouchRipple
            onClick={() => onFileDownload(file)}
            size="large">
            <IconDownloadFile />
          </IconButton>
          <div className="file__bottom-container">
            <p className="file__name">{file.name}</p>
          </div>

        </div>
      ))}
    </div>
  );
};

export default FilesTab;
