import { StudentSectionSettingTypeCodes } from 'const';
import { useAppSelector, useDataLoad, useWidgetVisibility } from 'hooks';
import {
  independentDiagnosticAnalyticPeriodsSelector,
  independentDiagnosticRatingPeriodsSelector,
  selfDiagnosticSubjectsSelector,
} from 'selectors';
import { getDataLoadRequests, isDefined } from 'utils';

const sectionTypeCode = StudentSectionSettingTypeCodes.study;
const subsectionTypeCode = StudentSectionSettingTypeCodes.studyDiagnostic;

export const useDiagnosticsWidgetLoader = () => {
  // видимость раздела диагностик
  const mainWidgetVisibility = useWidgetVisibility({
    sectionTypeCode,
    subsectionTypeCode,
  });
  const { isSettingsMode, isWidgetVisible } = mainWidgetVisibility;

  // видимость подраздела независимые диагностики
  const independentSubsectionVisibility = useWidgetVisibility({
    sectionTypeCode,
    subsectionTypeCode,
    subcategoryTypeCode: StudentSectionSettingTypeCodes.diagnosticIndependent,
  });
  const { isWidgetVisible: isIndependentWidgetVisible } = independentSubsectionVisibility;

  // видимость подраздела самодиагностики
  const selfSubsectionVisiblity = useWidgetVisibility({
    sectionTypeCode,
    subsectionTypeCode,
    subcategoryTypeCode: StudentSectionSettingTypeCodes.diagnosticSelf,
  });

  // видимость блока с рейтингом в независимых диагностиках
  const independentRatingVisibility = useWidgetVisibility({
    sectionTypeCode,
    subsectionTypeCode,
    subcategoryTypeCode: StudentSectionSettingTypeCodes.diagnosticIndependentRating,
  });
  const { isWidgetVisible: isIndependentRatingVisible } = independentRatingVisibility;

  // видимость блока со списком в независимых диагностиках
  const independentListVisibility = useWidgetVisibility({
    sectionTypeCode,
    subsectionTypeCode,
    subcategoryTypeCode: StudentSectionSettingTypeCodes.diagnosticIndependentList,
  });
  const { isWidgetVisible: isIndependentListVisible } = independentListVisibility;

  const isRatingBlockWisible = isWidgetVisible && isIndependentWidgetVisible && isIndependentRatingVisible;
  const isListBlockWisible = isWidgetVisible && isIndependentWidgetVisible && isIndependentListVisible;

  useDataLoad({ shouldLoad: isRatingBlockWisible, requests: getDataLoadRequests().studyDiagnosticsOnlyRating });
  useDataLoad({ shouldLoad: isListBlockWisible, requests: getDataLoadRequests().studyDiagnosticsOnlyList });
  useDataLoad({
    shouldLoad: isIndependentWidgetVisible && !isSettingsMode,
    requests: getDataLoadRequests().studyDiagnosticsRatingAndList,
  });
  useDataLoad({
    shouldLoad: isIndependentWidgetVisible && isSettingsMode,
    requests: getDataLoadRequests().studyDiagnosticsRatingAndListSettingsMode,
  });

  const independentDiagnostics = useAppSelector((state) => state.independentDiagnostics);
  const selfDiagnostics = useAppSelector((state) => state.studentDiagnosis);
  const selfDiagnosticSubjects = useAppSelector(selfDiagnosticSubjectsSelector);
  const independentDiagnosticAnalyticPeriods = useAppSelector(independentDiagnosticAnalyticPeriodsSelector);
  const independentDiagnosticRatingPeriods = useAppSelector(independentDiagnosticRatingPeriodsSelector);
  const generalRating = useAppSelector((state) => state.independentDiagnosticsGeneralRating);

  const visibility = {
    mainWidget: mainWidgetVisibility,
    independentSubsection: independentSubsectionVisibility,
    selfSubsection: selfSubsectionVisiblity,
    independentRating: independentRatingVisibility,
    independentList: independentListVisibility,
  };

  const data = {
    independentDiagnostics,
    selfDiagnostics,
    selfDiagnosticSubjects,
    independentDiagnosticAnalyticPeriods,
    independentDiagnosticRatingPeriods,
    generalRating,
  };

  const isLoading = [independentDiagnostics, generalRating].some((v) => v?.loading);
  const hasData =
    independentDiagnostics.content.length > 0 ||
    isDefined(selfDiagnostics?.content?.totalResult) ||
    (generalRating.content ? generalRating.content.length > 0 : false);

  return {
    visibility,
    data,
    isLoading,
    hasData,
  };
};
