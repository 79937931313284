import { ComponentProps, FC, PropsWithChildren } from 'react';

import { Box } from '@mui/material';
import { mergeSx } from 'utils';

import CardPaddingRow from './CardPaddingRow';
import * as styles from './styles';

interface ICardPaddingComposition {
  Row: FC<ComponentProps<typeof CardPaddingRow>>;
}

interface ICardPaddingProps {
  withHorizontalPadding?: boolean;
}

const CardPadding: FC<ICardPaddingProps & PropsWithChildren> & ICardPaddingComposition = ({
  withHorizontalPadding,
  children,
}) => {
  return (
    <Box className="card-padding" sx={mergeSx(styles.root, withHorizontalPadding && styles.row)}>
      {children}
    </Box>
  );
};

CardPadding.Row = CardPaddingRow;

export default CardPadding;
