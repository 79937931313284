/* eslint-disable prettier/prettier */
/* eslint-disable */

import connectReducers from '../utils/connectReducers';

import {
  GET_ATTACHMENT
} from '../actions';

import {
  FAILURE,
  REQUEST,
  SUCCESS
} from '../actions/utils';

import { IAttachmentState } from '../api/types';

import genericReducers from './genericReducer';

import { IReducers } from '.';

export type AttachmentState = IAttachmentState;

const initialState = {} as IAttachmentState;

const attachment: IReducers<AttachmentState> = {
  [GET_ATTACHMENT[REQUEST]]: genericReducers().get.request,
  [GET_ATTACHMENT[SUCCESS]]: genericReducers().get.success,
  [GET_ATTACHMENT[FAILURE]]: genericReducers().get.failure
};

export default connectReducers(initialState, attachment);
