/* eslint-disable max-len */
import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconRecommendation: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.382 1H5.609A4.607 4.607 0 0 0 1 5.609V18.39A4.607 4.607 0 0 0 5.609 23H18.39A4.607 4.607 0 0 0 23 18.391V5.61A4.607 4.607 0 0 0 18.391 1h-.01Zm-.44 10.308-2.657 2.3.797 3.418a.721.721 0 0 1-1.081.779l-3.006-1.815-3.005 1.815c-.55.33-1.228-.156-1.081-.78l.797-3.417-2.657-2.3c-.486-.421-.23-1.219.412-1.264l3.5-.293 1.365-3.226c.248-.586 1.09-.586 1.33 0L14.02 9.76l3.5.293a.72.72 0 0 1 .413 1.264l.01-.009Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconRecommendation;
