import { forwardRef, ForwardRefRenderFunction } from 'react';

import { Box, BoxProps } from '@mui/material';
import clsx from 'clsx';
import { SxStyles } from 'types';
import { mergeSx } from 'utils';

const styles: SxStyles = {
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

const TrimText: ForwardRefRenderFunction<HTMLDivElement, BoxProps> = (props, ref) => {
  return <Box {...props} ref={ref} className={clsx('trim-text', props.className)} sx={mergeSx(styles, props.sx)} />;
};

export default forwardRef(TrimText);
