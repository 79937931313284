import { FC, PropsWithChildren } from 'react';

import { Box } from '@mui/material';
import UnderlinedTabs from 'portfolio3/components/common/UnderlinedTabs';
import { DrawerHeader } from 'portfolio3/components/drawers';
import { Drawer, IDrawerBase } from 'portfolio3/ui-kit/Drawer';

import { ICommonDrawerProps } from '../types';

const DetailViewDrawerDesktop: FC<ICommonDrawerProps & PropsWithChildren> = ({
  isOpen,
  title,
  accentColor,
  image,
  footer,
  onClose,

  isTabsShown,
  tabs,
  currentTab,
  onChangeTab,

  children,
}) => {
  const hasTabs = isTabsShown && tabs.length > 0;

  const tabsElement = hasTabs && (
    <UnderlinedTabs value={currentTab} onChange={onChangeTab} sx={{ marginTop: '16px' }}>
      {tabs.map((tab) => (
        <UnderlinedTabs.Button key={tab.value} value={tab.value} content={tab.title} />
      ))}
    </UnderlinedTabs>
  );

  const header: IDrawerBase['header'] = (crossButton) => (
    <DrawerHeader
      title={title}
      backgroundImage={image ?? null}
      accentColor={accentColor}
      removeBottomPadding={hasTabs}
      crossButton={crossButton}
    >
      {tabsElement}
    </DrawerHeader>
  );

  return (
    <Drawer open={isOpen} isMobile={false} anchor="right" header={header} footer={footer} onClose={onClose}>
      <Box sx={{ paddingInline: '24px' }}>{children}</Box>
    </Drawer>
  );
};

export default DetailViewDrawerDesktop;
