import { getAccentColor, NeutralColors } from 'portfolio3/styles';
import { getButtonStyles } from 'portfolio3/ui-kit/utils';
import { SxStyles } from 'types';

export const root: SxStyles = {
  padding: '16px',
  dispaly: 'flex',
  flexDirection: 'column',
};

export const header: SxStyles = {
  display: 'flex',
  justifyContent: 'space-between',
};

export const helper: SxStyles = {
  marginTop: '4px',
  color: NeutralColors.light_neutrals_600,
};

export const linksButton: SxStyles = {
  ...getButtonStyles(),

  marginTop: '12px',
  color: getAccentColor('indigo', '200'),
};
