import { ICommonResponse } from 'api/types';
import { differenceInDays, startOfDay } from 'date-fns';
import { IRootState } from 'reducers';
import { createSelector } from 'reselect';

import { IActiveShareLink } from './types';

export const activeShareLinksSelector = createSelector(
  [(state: IRootState) => state.studentShareLinkList],
  (links): ICommonResponse<IActiveShareLink> => {
    const mappedLinks = links.content.map((link): IActiveShareLink => {
      const today = startOfDay(new Date());
      const daysCount = differenceInDays(new Date(link.endDate), today);

      return {
        ...link,
        expireDays: daysCount,
      };
    });

    return {
      ...links,
      content: mappedLinks,
    };
  },
);
