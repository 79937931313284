/* eslint-disable prettier/prettier */
/* eslint-disable */

import { validateProperties } from '../common';
import { DifferentValue, OtherOrganizationCode, SectionCodes, WithoutOrganizationCode } from '../../../../../const';
import { IAffilationKind, ILocation } from '../../../../../api/types';
import { CommonValidator } from '../types';
import { IPortfolioEntryFormData } from 'portfolio3/features/dataEntryForm';

const affilationValidators: Record<string, CommonValidator> = {
  [SectionCodes.sport]: isSportAffilationValid,
  [SectionCodes.creation]: isCreationAffilationValid,
  [SectionCodes.civil]: isCivilAffilationValid
};

export const isAffilationValid = (formData: IPortfolioEntryFormData, sportClubs: ILocation[], affilationKinds: IAffilationKind[]): boolean => {
  const { categoryCode } = formData;
  const commonValidator = () => validateProperties(getCommonAffilationProperties(formData, sportClubs, affilationKinds));

  const validator = categoryCode ? affilationValidators[categoryCode] : null;
  return validator ? validator(formData, commonValidator) : false;
};

// SectionCodes.sport
function isSportAffilationValid(formData: IPortfolioEntryFormData, commonValidator: () => boolean): boolean {

  const commonProperties = [
    formData.categoryCode === SectionCodes.sport,
    formData.sportKind,
  ];

  const commonPropertiesOtherOrganization = [
    ...commonProperties,
    formData.secondName,
    formData.startDate
  ];

  const commonPropertiesWithoutOrganization = [
    ...commonProperties,
    formData.teamName
  ];

  const commonPropertiesWithOrganization = [
    ...commonProperties,
    formData.startDate
  ];

  switch (formData.name) {
    case OtherOrganizationCode: {
      return validateProperties([
        ...commonPropertiesOtherOrganization,
      ]);
    }
    case WithoutOrganizationCode: {
      return validateProperties([
        ...commonPropertiesWithoutOrganization,
      ]);
    }
    default: {
      return validateProperties([
        ...commonPropertiesWithOrganization,
      ])
    }
  }
}

// SectionCodes.creation
function isCreationAffilationValid(formData: IPortfolioEntryFormData, commonValidator: () => boolean): boolean {
  return validateProperties([
    commonValidator(),
    formData.categoryCode === SectionCodes.creation,
    formData.subCategory,
    formData.secondName,
    formData.creationKindCode
  ]);
}

// SectionCodes.civil
function isCivilAffilationValid(formData: IPortfolioEntryFormData, commonValidator: () => boolean): boolean {
  return validateProperties([
    commonValidator(),
    formData.categoryCode === SectionCodes.civil,
    formData.subCategory
  ]);
}

function getAffilationName(formData: IPortfolioEntryFormData, sportClub: ILocation[], affilationKinds: IAffilationKind[]): string | undefined {
  const { categoryCode, name, secondName } = formData;

  if (categoryCode === SectionCodes.sport) {
    const sportName = sportClub.find((location) => location.code === name)?.value;
    return !name || sportName === DifferentValue ? secondName : sportName;
  }
  const eventName = affilationKinds.find((eventKind) => eventKind.code === Number(name))?.value;
  return !name || eventName === DifferentValue ? secondName : eventName;
};

function getCommonAffilationProperties(formData: IPortfolioEntryFormData, sportClubs: ILocation[], affilationKinds: IAffilationKind[]): unknown[] {
  const affilationName = getAffilationName(formData, sportClubs, affilationKinds);
  const { startDate, eventLevel } = formData;

  return [affilationName, startDate, eventLevel];
}
