/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { SvgIcon } from '@mui/material';
import { IconRouteProps } from './types';
import { Colors } from '../../style/variables';

const IconRouteStudy: React.FC<IconRouteProps> = ({ outerFill, ...props }) => (
  <SvgIcon width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path className="outer" d="M0 8C0 3.58172 3.58172 0 8 0H22C26.4183 0 30 3.58172 30 8V22C30 26.4183 26.4183 30 22 30H8C3.58172 30 0 26.4183 0 22V8Z" fill={outerFill || Colors['violet-medium']} />
    <path className="inner" d="M23.6524 10.2783H22.8608V8.06324C22.8572 7.8501 22.7832 7.644 22.6502 7.47657C22.5173 7.30915 22.3326 7.18964 22.1246 7.13637C20.8533 6.90155 19.5438 6.97073 18.3049 7.33818C17.0659 7.70563 15.933 8.36078 15 9.24932C14.0671 8.36078 12.9342 7.70563 11.6952 7.33818C10.4562 6.97073 9.14682 6.90155 7.8755 7.13637C7.65198 7.18013 7.45075 7.29967 7.30632 7.47451C7.16189 7.64935 7.08326 7.86859 7.08389 8.09466V10.2783H6.29228C6.07179 10.2925 5.86561 10.3916 5.71772 10.5545C5.56983 10.7173 5.49194 10.9311 5.50066 11.1502V21.927C5.4992 22.0556 5.52748 22.1827 5.58332 22.2988C5.63916 22.4148 5.72108 22.5165 5.82278 22.5962C5.92447 22.6758 6.04323 22.7313 6.16991 22.7583C6.2966 22.7852 6.42783 22.783 6.55351 22.7518C9.24352 22.0478 12.08 22.1186 14.7309 22.956C14.9 23.0147 15.0842 23.0147 15.2534 22.956C17.9024 22.1362 20.7263 22.0466 23.4228 22.6968C23.5468 22.7315 23.6772 22.7377 23.804 22.7148C23.9308 22.692 24.0507 22.6407 24.1545 22.565C24.2583 22.4892 24.3433 22.391 24.4031 22.2777C24.4629 22.1644 24.4958 22.0391 24.4994 21.9113V11.1502C24.5037 11.0372 24.4849 10.9245 24.4443 10.8189C24.4036 10.7133 24.3419 10.6169 24.2628 10.5355C24.1837 10.4541 24.0889 10.3893 23.984 10.3451C23.8791 10.301 23.7663 10.2782 23.6524 10.2783ZM14.0105 19.2328C12.5967 18.1666 10.8918 17.548 9.11834 17.4576V8.93513C10.0439 8.89216 10.967 9.06192 11.8156 9.43115C12.6642 9.80039 13.4153 10.3591 14.0105 11.0638V19.2328ZM20.8817 17.4183L20.6522 17.4812C18.961 17.6101 17.3427 18.218 15.9896 19.2328V11.0638C16.5902 10.3681 17.3434 9.81813 18.1914 9.45597C19.0394 9.0938 19.9597 8.92907 20.8817 8.9744V17.4183Z" fill="#845EF7" />
  </SvgIcon>
);

export default IconRouteStudy;
