import { SET_LOCAL_VISIBILITY_SETTINGS } from '../actions';
import { ILocalVisibilitySettingsList } from '../api/types';
import connectReducers from '../utils/connectReducers';
import { IReducers } from './index';

export type LocalVisibilitySettingState = ILocalVisibilitySettingsList;

type SetLocalVisibilitySettingsAction = {
  payload: ILocalVisibilitySettingsList;
};

const initialState = {
  sections: [],
} as LocalVisibilitySettingState;

const localVisibilitySettings: IReducers<LocalVisibilitySettingState> = {
  [SET_LOCAL_VISIBILITY_SETTINGS]: (state, { payload }: SetLocalVisibilitySettingsAction) => {
    return {
      sections: payload.sections,
    };
  },
};

export default connectReducers(initialState, localVisibilitySettings);
