import { SxProps } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';
import { getFocusStateStyles } from 'portfolio3/ui-kit/utils';

export const button: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '40px',
  height: '40px',

  border: '1px solid',
  borderColor: NeutralColors.light_neutrals_400,
  backgroundColor: '#ffffff',
  boxShadow: '0px 1px 2px rgba(41, 41, 64, 0.07)',

  '&:hover': {
    backgroundColor: NeutralColors.light_neutrals_200,
  },

  '& svg': {
    width: '20px',
    height: '20px',
    color: NeutralColors.light_neutrals_600,
  },

  '&:focus-visible': {
    ...getFocusStateStyles(),
  },
};
