/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconSave: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M15.292 3.878l4.829 4.829c.562.562.879 1.326.879 2.122V18c0 1.657-1.343 3-3 3h-2v-4c0-1.103-.898-2-2-2h-4c-1.103 0-2 .897-2 2v4H6c-1.657 0-3-1.343-3-3V6c0-1.657 1.343-3 3-3h2v7c0 .552.447 1 1 1h6c.553 0 1-.448 1-1s-.447-1-1-1h-5V3h3.172c.795 0 1.558.316 2.12.878zM9.9999 17h4l.001 4h-4.001v-4z" fill="#0055CB"/>
  </SvgIcon>
);

export default IconSave;
