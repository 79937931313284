/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

interface ICurrentPortfolioSectionContext {
  setCurrentPortfolioSection: (sectionId: string | undefined) => void;
  isContainerScrolled: boolean;
  currentPortfolioSection?: string;
}

export default React.createContext({} as ICurrentPortfolioSectionContext);
