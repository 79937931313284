import { createSelector } from 'reselect';

import { IRootState } from '../../../reducers';
import { mapSportClubsResponse } from './mappers';

const sportClubsSelector = createSelector(
  [(state: IRootState) => state.studentAffilations, (state: IRootState) => state.sportKinds],
  (affiliations, sportKinds) => {
    return mapSportClubsResponse(affiliations, sportKinds);
  },
);

export default sportClubsSelector;
