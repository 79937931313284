/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';
import genericReducers from '../genericReducer';
import { IReducers } from '../index';

import { GET_SPORT_REWARD_KINDS } from '../../actions/employees';
import { REQUEST, FAILURE, SUCCESS } from '../../actions/utils';

import { ICommonResponse, ISportReward } from '../../api/types';

export type SportRewardKindsState = ICommonResponse<ISportReward>;

const initialState: SportRewardKindsState = {
  content: [],
  loading: false,
};

const sportRewardKinds: IReducers<SportRewardKindsState> = {
  [GET_SPORT_REWARD_KINDS[REQUEST]]: genericReducers().get.request,
  [GET_SPORT_REWARD_KINDS[SUCCESS]]: genericReducers().get.success,
  [GET_SPORT_REWARD_KINDS[FAILURE]]: genericReducers().get.failure
};

export default connectReducers(initialState, sportRewardKinds);
