/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { connect } from 'react-redux';

import {
  iconApprovePdf
} from '../../../icons';
import { IRootState } from '../../../reducers';
import { IEntity } from '../../../types';
import Achievement from './Achievement';

import './index.scss';

interface ICommonDataEntityProps {
  entity: IEntity,
  year?: number
}

const PdfCommonDataEntity: React.FC<ICommonDataEntityProps> = ({
  entity,
  year
}) => {
  const {
    features,
    name,
    stage,
    place,
    types,
    date,
    reward,
    entityProps,
    achievementImage
  } = entity;

  const isApproved = entityProps.flags?.includes('approved');

  const getRewardResult = (result: string) => {
    if (result.length < 36) return result;
    return `${result.slice(0, 36)}...`;
  };

  return (
    <div className="pdf-data-entity pdf-keep">
      {/* год проведения (year) - должен отображаться только у первой сущности на странице,
          внесен внутрь для единого неразрывного отображения с сущностью  */}
      {year && <div className="year">{year}</div>}
      <div className="pdf-data-entity__pin" />
      <div className="pdf-data-entity__info">
        <div className="pdf-data-entity__features-container">
          {features.filter((feature) => feature?.text).map((feature) => (
            <p key={feature.text} className="pdf-data-entity__feature">{feature.text}</p>
          ))}
          {isApproved && <div className="pdf-data-entity__feature pdf-data-entity__feature--approve"><img src={iconApprovePdf} alt="подтверждено" /></div>}
        </div>
        <h5 className="pdf-data-entity__name">
          {name}
        </h5>
        {stage && <p className="pdf-data-entity__stage">{stage}</p>}
        <p className="pdf-data-entity__types">
          {types && types.map((type) => <span key={type + Math.random() * 10}>{type}</span>)}
        </p>
        {place && <p>{place}</p>}
        <p className="pdf-data-entity__date">{date}</p>
      </div>
      {reward && (
        <div className="pdf-data-entity__reward reward">
          <div className="reward__info">
            <h5 className="reward__result">{getRewardResult(reward.result)}</h5>
            {reward.rewardDate && <p className="reward__date">{reward.rewardDate}</p>}
          </div>
          <img src={reward.image} />
        </div>
      )}
      {!reward && achievementImage && <Achievement image={achievementImage} />}
    </div>
  );
};

export default connect(
  (state: IRootState) => ({
    linkMode: state.linkMode.mode
  }),
  {
  }
)(PdfCommonDataEntity);
