/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { InputAdornment } from '@mui/material';

import Input from '../../../../ui-kit/input/Input';
import { ICommonInputController } from './types';

interface ICommonInput {
  inputController: ICommonInputController,
  error?: boolean;
  multiline?: boolean,
  placeholder?: string,
  startAdornment?: any,
  endAdornment?: any,
  value?: string | number | null,
  className?: string
}

const CommonInput: React.FC<ICommonInput> = ({
  error,
  inputController,
  multiline,
  placeholder,
  startAdornment,
  endAdornment,
  value,
  ...props
}) => (
  <Input
    error={error}
    disableUnderline
    value={value || ''}
    placeholder={placeholder}
    multiline={multiline}
    startAdornment={startAdornment ? <InputAdornment position="start">{startAdornment}</InputAdornment> : undefined}
    endAdornment={endAdornment ? <InputAdornment position="end">{endAdornment}</InputAdornment> : undefined}
    onChange={(e) => inputController.handleChange(e.target.value)}
    onBlur={(e) => { if (inputController.handleBlur) inputController.handleBlur(e.target.value); }}
    {...props}
  />
);

export default CommonInput;
