/* eslint-disable max-len */
import { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const Tiger: FC<SvgIconProps> = (props) => (
  <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="7.87239" cy="6.89729" r="3.89729" fill="url(#paint0_radial_64_89960)" />
    <circle cx="24.0936" cy="6.89729" r="3.89729" fill="url(#paint1_radial_64_89960)" />
    <circle cx="24.0936" cy="6.89729" r="3.89729" fill="url(#paint2_radial_64_89960)" />
    <circle cx="24.0936" cy="6.89729" r="3.89729" fill="url(#paint3_radial_64_89960)" fillOpacity="0.5" />
    <circle cx="7.94409" cy="6.95508" r="2.45312" fill="url(#paint4_radial_64_89960)" />
    <circle cx="7.94409" cy="6.95508" r="2.45312" fill="url(#paint5_radial_64_89960)" />
    <circle cx="24.0278" cy="6.95508" r="2.45312" fill="url(#paint6_radial_64_89960)" />
    <circle cx="24.0278" cy="6.95508" r="2.45312" fill="url(#paint7_radial_64_89960)" fillOpacity="0.75" />
    <path
      d="M27.741 12.4657L25.961 10.3857C24.991 9.18746 22.9544 6.45069 20.641 5.2357C19.251 4.5057 17.671 4.0957 15.991 4.0957C14.311 4.0957 12.731 4.5057 11.341 5.2357C9.77222 5.9687 6.83472 9.43745 6.01097 10.3857L4.24097 12.4657C2.79097 14.1657 1.99097 16.3257 1.99097 18.5557V22.4057C1.99097 24.9957 4.09097 27.0957 6.68097 27.0957H25.301C27.891 27.0957 29.991 24.9957 29.991 22.4057V18.5557C29.991 16.3257 29.191 14.1657 27.741 12.4657Z"
      fill="url(#paint8_radial_64_89960)"
    />
    <path
      d="M27.741 12.4657L25.961 10.3857C24.991 9.18746 22.9544 6.45069 20.641 5.2357C19.251 4.5057 17.671 4.0957 15.991 4.0957C14.311 4.0957 12.731 4.5057 11.341 5.2357C9.77222 5.9687 6.83472 9.43745 6.01097 10.3857L4.24097 12.4657C2.79097 14.1657 1.99097 16.3257 1.99097 18.5557V22.4057C1.99097 24.9957 4.09097 27.0957 6.68097 27.0957H25.301C27.891 27.0957 29.991 24.9957 29.991 22.4057V18.5557C29.991 16.3257 29.191 14.1657 27.741 12.4657Z"
      fill="url(#paint9_linear_64_89960)"
    />
    <path
      d="M27.741 12.4657L25.961 10.3857C24.991 9.18746 22.9544 6.45069 20.641 5.2357C19.251 4.5057 17.671 4.0957 15.991 4.0957C14.311 4.0957 12.731 4.5057 11.341 5.2357C9.77222 5.9687 6.83472 9.43745 6.01097 10.3857L4.24097 12.4657C2.79097 14.1657 1.99097 16.3257 1.99097 18.5557V22.4057C1.99097 24.9957 4.09097 27.0957 6.68097 27.0957H25.301C27.891 27.0957 29.991 24.9957 29.991 22.4057V18.5557C29.991 16.3257 29.191 14.1657 27.741 12.4657Z"
      fill="url(#paint10_radial_64_89960)"
    />
    <path
      d="M27.741 12.4657L25.961 10.3857C24.991 9.18746 22.9544 6.45069 20.641 5.2357C19.251 4.5057 17.671 4.0957 15.991 4.0957C14.311 4.0957 12.731 4.5057 11.341 5.2357C9.77222 5.9687 6.83472 9.43745 6.01097 10.3857L4.24097 12.4657C2.79097 14.1657 1.99097 16.3257 1.99097 18.5557V22.4057C1.99097 24.9957 4.09097 27.0957 6.68097 27.0957H25.301C27.891 27.0957 29.991 24.9957 29.991 22.4057V18.5557C29.991 16.3257 29.191 14.1657 27.741 12.4657Z"
      fill="url(#paint11_linear_64_89960)"
    />
    <path
      d="M27.741 12.4657L25.961 10.3857C24.991 9.18746 22.9544 6.45069 20.641 5.2357C19.251 4.5057 17.671 4.0957 15.991 4.0957C14.311 4.0957 12.731 4.5057 11.341 5.2357C9.77222 5.9687 6.83472 9.43745 6.01097 10.3857L4.24097 12.4657C2.79097 14.1657 1.99097 16.3257 1.99097 18.5557V22.4057C1.99097 24.9957 4.09097 27.0957 6.68097 27.0957H25.301C27.891 27.0957 29.991 24.9957 29.991 22.4057V18.5557C29.991 16.3257 29.191 14.1657 27.741 12.4657Z"
      fill="url(#paint12_linear_64_89960)"
    />
    <path
      d="M15.991 30.002C12.121 30.002 8.99097 26.872 8.99097 23.002V21.002H22.991V23.002C22.991 26.872 19.861 30.002 15.991 30.002Z"
      fill="url(#paint13_radial_64_89960)"
    />
    <path
      d="M15.991 30.002C12.121 30.002 8.99097 26.872 8.99097 23.002V21.002H22.991V23.002C22.991 26.872 19.861 30.002 15.991 30.002Z"
      fill="url(#paint14_radial_64_89960)"
    />
    <path
      d="M15.991 30.002C12.121 30.002 8.99097 26.872 8.99097 23.002V21.002H22.991V23.002C22.991 26.872 19.861 30.002 15.991 30.002Z"
      fill="url(#paint15_radial_64_89960)"
    />
    <path
      d="M15.991 30.002C12.121 30.002 8.99097 26.872 8.99097 23.002V21.002H22.991V23.002C22.991 26.872 19.861 30.002 15.991 30.002Z"
      fill="url(#paint16_radial_64_89960)"
    />
    <g filter="url(#filter0_i_64_89960)">
      <path
        d="M19.991 18.002H11.991C10.331 18.002 8.99097 19.342 8.99097 21.002C8.99097 22.662 10.331 24.002 11.991 24.002H13.491C14.531 24.002 15.451 23.472 15.991 22.662C16.531 23.472 17.451 24.002 18.491 24.002H19.991C21.651 24.002 22.991 22.662 22.991 21.002C22.991 19.342 21.651 18.002 19.991 18.002Z"
        fill="url(#paint17_radial_64_89960)"
      />
      <path
        d="M19.991 18.002H11.991C10.331 18.002 8.99097 19.342 8.99097 21.002C8.99097 22.662 10.331 24.002 11.991 24.002H13.491C14.531 24.002 15.451 23.472 15.991 22.662C16.531 23.472 17.451 24.002 18.491 24.002H19.991C21.651 24.002 22.991 22.662 22.991 21.002C22.991 19.342 21.651 18.002 19.991 18.002Z"
        fill="url(#paint18_linear_64_89960)"
      />
      <path
        d="M19.991 18.002H11.991C10.331 18.002 8.99097 19.342 8.99097 21.002C8.99097 22.662 10.331 24.002 11.991 24.002H13.491C14.531 24.002 15.451 23.472 15.991 22.662C16.531 23.472 17.451 24.002 18.491 24.002H19.991C21.651 24.002 22.991 22.662 22.991 21.002C22.991 19.342 21.651 18.002 19.991 18.002Z"
        fill="url(#paint19_radial_64_89960)"
      />
    </g>
    <g filter="url(#filter1_f_64_89960)">
      <circle cx="20.4672" cy="20.3825" r="0.72625" fill="#D6C8D9" />
    </g>
    <g filter="url(#filter2_f_64_89960)">
      <path
        d="M19.741 17.0957C19.331 17.0957 18.991 16.7557 18.991 16.3457V14.8457C18.991 14.4357 19.331 14.0957 19.741 14.0957C20.151 14.0957 20.491 14.4357 20.491 14.8457V16.3457C20.491 16.7657 20.151 17.0957 19.741 17.0957Z"
        fill="#F86121"
      />
    </g>
    <g filter="url(#filter3_f_64_89960)">
      <path
        d="M11.741 17.0957C11.331 17.0957 10.991 16.7557 10.991 16.3457V14.8457C10.991 14.4357 11.331 14.0957 11.741 14.0957C12.151 14.0957 12.491 14.4357 12.491 14.8457V16.3457C12.491 16.7657 12.151 17.0957 11.741 17.0957Z"
        fill="#F86121"
      />
    </g>
    <path
      d="M19.991 16.8926C19.581 16.8926 19.241 16.5526 19.241 16.1426V14.6426C19.241 14.2326 19.581 13.8926 19.991 13.8926C20.401 13.8926 20.741 14.2326 20.741 14.6426V16.1426C20.741 16.5626 20.401 16.8926 19.991 16.8926Z"
      fill="url(#paint20_linear_64_89960)"
    />
    <path
      d="M19.991 16.8926C19.581 16.8926 19.241 16.5526 19.241 16.1426V14.6426C19.241 14.2326 19.581 13.8926 19.991 13.8926C20.401 13.8926 20.741 14.2326 20.741 14.6426V16.1426C20.741 16.5626 20.401 16.8926 19.991 16.8926Z"
      fill="url(#paint21_linear_64_89960)"
    />
    <path
      d="M13.871 19.812L15.251 21.192C15.661 21.602 16.331 21.602 16.751 21.192L18.131 19.812C18.801 19.142 18.321 18.002 17.381 18.002H14.631C13.671 18.002 13.201 19.142 13.871 19.812Z"
      fill="url(#paint22_linear_64_89960)"
    />
    <path
      d="M13.871 19.812L15.251 21.192C15.661 21.602 16.331 21.602 16.751 21.192L18.131 19.812C18.801 19.142 18.321 18.002 17.381 18.002H14.631C13.671 18.002 13.201 19.142 13.871 19.812Z"
      fill="url(#paint23_linear_64_89960)"
    />
    <path
      d="M13.871 19.812L15.251 21.192C15.661 21.602 16.331 21.602 16.751 21.192L18.131 19.812C18.801 19.142 18.321 18.002 17.381 18.002H14.631C13.671 18.002 13.201 19.142 13.871 19.812Z"
      fill="url(#paint24_linear_64_89960)"
    />
    <path
      d="M13.871 19.812L15.251 21.192C15.661 21.602 16.331 21.602 16.751 21.192L18.131 19.812C18.801 19.142 18.321 18.002 17.381 18.002H14.631C13.671 18.002 13.201 19.142 13.871 19.812Z"
      fill="url(#paint25_radial_64_89960)"
    />
    <path
      d="M29.621 24.1329C29.861 23.5729 29.991 22.9629 29.991 22.3129V18.4629C29.991 17.6129 29.871 16.7729 29.651 15.9629L25.291 18.8829C24.731 19.2529 24.991 20.1329 25.671 20.1329H29.421L25.291 22.8829C24.731 23.2529 24.991 24.1329 25.671 24.1329H29.621Z"
      fill="url(#paint26_linear_64_89960)"
    />
    <path
      d="M6.69097 22.8929L2.56097 20.1429H6.31097C6.98097 20.1429 7.25097 19.2729 6.69097 18.8929L2.34097 15.9629C2.11097 16.7629 1.99097 17.6129 1.99097 18.4629V22.3129C1.99097 22.9629 2.12097 23.5729 2.36097 24.1329H6.31097C6.98097 24.1329 7.25097 23.2629 6.69097 22.8929Z"
      fill="url(#paint27_linear_64_89960)"
    />
    <path
      d="M13.141 7.50195H18.841C19.221 7.50195 19.381 7.97195 19.091 8.21195L16.391 10.372C16.161 10.562 15.831 10.562 15.591 10.372L12.891 8.21195C12.601 7.97195 12.761 7.50195 13.141 7.50195Z"
      fill="url(#paint28_linear_64_89960)"
    />
    <path
      d="M17.8808 4.2757C17.2708 4.1557 16.6408 4.0957 15.9908 4.0957C15.3408 4.0957 14.7108 4.1557 14.1008 4.2757L15.2708 5.79688C15.6608 6.20688 16.3108 6.20688 16.6908 5.79688L17.8808 4.2757Z"
      fill="url(#paint29_linear_64_89960)"
    />
    <path
      d="M11.5309 19.5728L8.07091 17.5728C7.83091 17.4328 7.53091 17.5128 7.39091 17.7528C7.25091 17.9928 7.33091 18.2928 7.57091 18.4328L11.0309 20.4328C11.2709 20.5728 11.5709 20.4928 11.7109 20.2528C11.8509 20.0128 11.7709 19.7128 11.5309 19.5728Z"
      fill="url(#paint30_linear_64_89960)"
    />
    <path
      d="M11.5309 19.5728L8.07091 17.5728C7.83091 17.4328 7.53091 17.5128 7.39091 17.7528C7.25091 17.9928 7.33091 18.2928 7.57091 18.4328L11.0309 20.4328C11.2709 20.5728 11.5709 20.4928 11.7109 20.2528C11.8509 20.0128 11.7709 19.7128 11.5309 19.5728Z"
      fill="url(#paint31_linear_64_89960)"
    />
    <path
      d="M11.5309 19.5728L8.07091 17.5728C7.83091 17.4328 7.53091 17.5128 7.39091 17.7528C7.25091 17.9928 7.33091 18.2928 7.57091 18.4328L11.0309 20.4328C11.2709 20.5728 11.5709 20.4928 11.7109 20.2528C11.8509 20.0128 11.7709 19.7128 11.5309 19.5728Z"
      fill="url(#paint32_linear_64_89960)"
    />
    <path
      d="M7.56092 23.5728L11.0209 21.5728C11.2609 21.4328 11.5609 21.5128 11.7009 21.7528C11.8409 21.9928 11.7609 22.2928 11.5209 22.4328L8.06092 24.4328C7.82092 24.5728 7.52092 24.4928 7.38092 24.2528C7.24092 24.0128 7.33092 23.7128 7.56092 23.5728Z"
      fill="url(#paint33_linear_64_89960)"
    />
    <path
      d="M7.56092 23.5728L11.0209 21.5728C11.2609 21.4328 11.5609 21.5128 11.7009 21.7528C11.8409 21.9928 11.7609 22.2928 11.5209 22.4328L8.06092 24.4328C7.82092 24.5728 7.52092 24.4928 7.38092 24.2528C7.24092 24.0128 7.33092 23.7128 7.56092 23.5728Z"
      fill="url(#paint34_linear_64_89960)"
    />
    <path
      d="M20.4508 19.5728L23.9108 17.5728C24.1508 17.4328 24.4508 17.5128 24.5908 17.7528C24.7308 17.9928 24.6508 18.2928 24.4108 18.4328L20.9508 20.4328C20.7108 20.5728 20.4108 20.4928 20.2708 20.2528C20.1308 20.0128 20.2108 19.7128 20.4508 19.5728Z"
      fill="url(#paint35_linear_64_89960)"
    />
    <path
      d="M20.4508 19.5728L23.9108 17.5728C24.1508 17.4328 24.4508 17.5128 24.5908 17.7528C24.7308 17.9928 24.6508 18.2928 24.4108 18.4328L20.9508 20.4328C20.7108 20.5728 20.4108 20.4928 20.2708 20.2528C20.1308 20.0128 20.2108 19.7128 20.4508 19.5728Z"
      fill="url(#paint36_linear_64_89960)"
    />
    <path
      d="M20.4508 19.5728L23.9108 17.5728C24.1508 17.4328 24.4508 17.5128 24.5908 17.7528C24.7308 17.9928 24.6508 18.2928 24.4108 18.4328L20.9508 20.4328C20.7108 20.5728 20.4108 20.4928 20.2708 20.2528C20.1308 20.0128 20.2108 19.7128 20.4508 19.5728Z"
      fill="url(#paint37_linear_64_89960)"
    />
    <path
      d="M24.4208 23.5728L20.9608 21.5728C20.7208 21.4328 20.4208 21.5128 20.2808 21.7528C20.1408 21.9928 20.2208 22.2928 20.4608 22.4328L23.9208 24.4328C24.1608 24.5728 24.4608 24.4928 24.6008 24.2528C24.7408 24.0128 24.6508 23.7128 24.4208 23.5728Z"
      fill="url(#paint38_linear_64_89960)"
    />
    <path
      d="M24.4208 23.5728L20.9608 21.5728C20.7208 21.4328 20.4208 21.5128 20.2808 21.7528C20.1408 21.9928 20.2208 22.2928 20.4608 22.4328L23.9208 24.4328C24.1608 24.5728 24.4608 24.4928 24.6008 24.2528C24.7408 24.0128 24.6508 23.7128 24.4208 23.5728Z"
      fill="url(#paint39_linear_64_89960)"
    />
    <path
      d="M24.4208 23.5728L20.9608 21.5728C20.7208 21.4328 20.4208 21.5128 20.2808 21.7528C20.1408 21.9928 20.2208 22.2928 20.4608 22.4328L23.9208 24.4328C24.1608 24.5728 24.4608 24.4928 24.6008 24.2528C24.7408 24.0128 24.6508 23.7128 24.4208 23.5728Z"
      fill="url(#paint40_linear_64_89960)"
    />
    <path
      d="M24.4208 23.5728L20.9608 21.5728C20.7208 21.4328 20.4208 21.5128 20.2808 21.7528C20.1408 21.9928 20.2208 22.2928 20.4608 22.4328L23.9208 24.4328C24.1608 24.5728 24.4608 24.4928 24.6008 24.2528C24.7408 24.0128 24.6508 23.7128 24.4208 23.5728Z"
      fill="url(#paint41_linear_64_89960)"
    />
    <path
      d="M11.991 16.8926C11.581 16.8926 11.241 16.5526 11.241 16.1426V14.6426C11.241 14.2326 11.581 13.8926 11.991 13.8926C12.401 13.8926 12.741 14.2326 12.741 14.6426V16.1426C12.741 16.5626 12.401 16.8926 11.991 16.8926Z"
      fill="url(#paint42_linear_64_89960)"
    />
    <path
      d="M11.991 16.8926C11.581 16.8926 11.241 16.5526 11.241 16.1426V14.6426C11.241 14.2326 11.581 13.8926 11.991 13.8926C12.401 13.8926 12.741 14.2326 12.741 14.6426V16.1426C12.741 16.5626 12.401 16.8926 11.991 16.8926Z"
      fill="url(#paint43_linear_64_89960)"
    />
    <path
      d="M11.991 16.8926C11.581 16.8926 11.241 16.5526 11.241 16.1426V14.6426C11.241 14.2326 11.581 13.8926 11.991 13.8926C12.401 13.8926 12.741 14.2326 12.741 14.6426V16.1426C12.741 16.5626 12.401 16.8926 11.991 16.8926Z"
      fill="url(#paint44_linear_64_89960)"
    />
    <g filter="url(#filter4_f_64_89960)">
      <path
        d="M20.8972 20.0059L24.2097 18.0684"
        stroke="url(#paint45_linear_64_89960)"
        strokeWidth="0.2"
        strokeLinecap="round"
      />
    </g>
    <g filter="url(#filter5_f_64_89960)">
      <path
        d="M20.7566 21.7871L24.1941 23.8027"
        stroke="url(#paint46_linear_64_89960)"
        strokeWidth="0.2"
        strokeLinecap="round"
      />
    </g>
    <g filter="url(#filter6_f_64_89960)">
      <circle cx="11.2957" cy="22.0293" r="0.1875" fill="#CCC1D6" />
    </g>
    <defs>
      <filter
        id="filter0_i_64_89960"
        x="8.99097"
        y="17.752"
        width="14"
        height="6.25"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-0.25" />
        <feGaussianBlur stdDeviation="0.375" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.652983 0 0 0 0 0.534201 0 0 0 0 0.754167 0 0 0 1 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_64_89960" />
      </filter>
      <filter
        id="filter1_f_64_89960"
        x="19.241"
        y="19.1562"
        width="2.45239"
        height="2.45312"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_64_89960" />
      </filter>
      <filter
        id="filter2_f_64_89960"
        x="17.991"
        y="13.0957"
        width="3.5"
        height="5"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_64_89960" />
      </filter>
      <filter
        id="filter3_f_64_89960"
        x="9.99097"
        y="13.0957"
        width="3.5"
        height="5"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_64_89960" />
      </filter>
      <filter
        id="filter4_f_64_89960"
        x="20.5971"
        y="17.7687"
        width="3.9127"
        height="2.53672"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur_64_89960" />
      </filter>
      <filter
        id="filter5_f_64_89960"
        x="20.4565"
        y="21.4875"
        width="4.0377"
        height="2.61484"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur_64_89960" />
      </filter>
      <filter
        id="filter6_f_64_89960"
        x="10.7082"
        y="21.4418"
        width="1.175"
        height="1.175"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.2" result="effect1_foregroundBlur_64_89960" />
      </filter>
      <radialGradient
        id="paint0_radial_64_89960"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(10.5533 4.25) rotate(135.385) scale(6.58508)"
      >
        <stop stopColor="#FF9E56" />
        <stop offset="1" stopColor="#D65E27" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_64_89960"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(26.8033 5.25) rotate(152.526) scale(5.28338)"
      >
        <stop stopColor="#FFA761" />
        <stop offset="1" stopColor="#FC7520" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_64_89960"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(26.2721 8.56869) rotate(-148.662) scale(7.39067 6.69471)"
      >
        <stop offset="0.723775" stopColor="#E16F15" stopOpacity="0" />
        <stop offset="1" stopColor="#CA572A" />
      </radialGradient>
      <radialGradient
        id="paint3_radial_64_89960"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(24.5846 5.72494) rotate(49.9138) scale(4.90147 5.73234)"
      >
        <stop offset="0.783692" stopColor="#E16F15" stopOpacity="0" />
        <stop offset="1" stopColor="#CA572A" />
      </radialGradient>
      <radialGradient
        id="paint4_radial_64_89960"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(6.58472 6.95508) rotate(16.5316) scale(2.70559 5.16131)"
      >
        <stop stopColor="#E16F15" />
        <stop offset="1" stopColor="#CA572A" />
      </radialGradient>
      <radialGradient
        id="paint5_radial_64_89960"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(5.49097 7.53744) rotate(-12.5085) scale(2.68882 2.35237)"
      >
        <stop stopColor="#E08353" />
        <stop offset="0.875617" stopColor="#E08353" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint6_radial_64_89960"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(22.4285 7.63119) rotate(-24.901) scale(4.46783 2.45749)"
      >
        <stop stopColor="#F88A5D" />
        <stop offset="0.83742" stopColor="#FF7612" />
      </radialGradient>
      <radialGradient
        id="paint7_radial_64_89960"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(25.6316 8.59994) rotate(-139.341) scale(5.34783 4.06175)"
      >
        <stop offset="0.693561" stopColor="#E16F15" stopOpacity="0" />
        <stop offset="1" stopColor="#CA572A" />
      </radialGradient>
      <radialGradient
        id="paint8_radial_64_89960"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(22.241 10.5937) rotate(107.9) scale(20.3342 25.8683)"
      >
        <stop stopColor="#FFA24D" />
        <stop offset="0.501156" stopColor="#FC7C2B" />
        <stop offset="1" stopColor="#CA4835" />
      </radialGradient>
      <linearGradient
        id="paint9_linear_64_89960"
        x1="26.9285"
        y1="9.25614"
        x2="24.116"
        y2="11.8186"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.136266" stopColor="#F97420" />
        <stop offset="0.648948" stopColor="#FC7C2B" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint10_radial_64_89960"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(15.991 19.9124) rotate(-90) scale(5.625 6.51316)"
      >
        <stop stopColor="#E75F09" />
        <stop offset="1" stopColor="#E75F09" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint11_linear_64_89960"
        x1="15.991"
        y1="28.0686"
        x2="15.991"
        y2="24.3186"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C8485B" />
        <stop offset="1" stopColor="#C8485B" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_64_89960"
        x1="1.99097"
        y1="13.6936"
        x2="3.95972"
        y2="15.5957"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BF603D" />
        <stop offset="0.462401" stopColor="#BF603D" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint13_radial_64_89960"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(15.991 22.25) rotate(92.4264) scale(8.85783 8.28011)"
      >
        <stop stopColor="#E8D0FF" />
        <stop offset="0.78125" stopColor="#E1D2EE" />
        <stop offset="1" stopColor="#AB8BCA" />
      </radialGradient>
      <radialGradient
        id="paint14_radial_64_89960"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(15.991 22.0374) rotate(90) scale(11.5 14.5)"
      >
        <stop offset="0.48913" stopColor="#C7A5E6" stopOpacity="0" />
        <stop offset="0.864583" stopColor="#C7A5E6" />
      </radialGradient>
      <radialGradient
        id="paint15_radial_64_89960"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(15.991 20.0999) rotate(90) scale(6.125 10.25)"
      >
        <stop offset="0.130208" stopColor="#A58EB9" />
        <stop offset="1" stopColor="#A58EB9" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint16_radial_64_89960"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(7.24097 21.5374) rotate(30.4464) scale(9.49712 7.44628)"
      >
        <stop offset="0.130208" stopColor="#A58EB9" />
        <stop offset="1" stopColor="#A58EB9" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint17_radial_64_89960"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(19.8044 18.3499) rotate(90) scale(8 14.4548)"
      >
        <stop offset="0.177083" stopColor="#E4D5E8" />
        <stop offset="1" stopColor="#C7A5E6" />
      </radialGradient>
      <linearGradient
        id="paint18_linear_64_89960"
        x1="15.991"
        y1="25.7874"
        x2="15.991"
        y2="21.5374"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C7A5E6" />
        <stop offset="1" stopColor="#C7A5E6" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint19_radial_64_89960"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(21.4285 20.5374) rotate(154.885) scale(2.20882 1.99123)"
      >
        <stop stopColor="#F6E9F9" />
        <stop offset="1" stopColor="#F6E9F9" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint20_linear_64_89960"
        x1="20.6537"
        y1="15.3926"
        x2="19.8283"
        y2="15.3926"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.225" stopColor="#594C4F" />
        <stop offset="1" stopColor="#392833" />
      </linearGradient>
      <linearGradient
        id="paint21_linear_64_89960"
        x1="20.9753"
        y1="17.4175"
        x2="20.9753"
        y2="15.8273"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#552D52" />
        <stop offset="1" stopColor="#552D52" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint22_linear_64_89960"
        x1="17.3482"
        y1="17.7119"
        x2="14.2555"
        y2="20.4459"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.191489" stopColor="#5F5254" />
        <stop offset="1" stopColor="#2E2127" />
      </linearGradient>
      <linearGradient
        id="paint23_linear_64_89960"
        x1="16.39"
        y1="18.9497"
        x2="16.39"
        y2="17.2544"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.146552" stopColor="#34222C" stopOpacity="0" />
        <stop offset="0.872727" stopColor="#34222C" />
      </linearGradient>
      <linearGradient
        id="paint24_linear_64_89960"
        x1="17.2371"
        y1="21.7931"
        x2="17.2371"
        y2="19.2301"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#552D52" />
        <stop offset="1" stopColor="#552D52" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint25_radial_64_89960"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(19.0639 20.5323) rotate(-161.201) scale(6.29662 2.83336)"
      >
        <stop offset="0.827602" stopColor="#5C5458" stopOpacity="0" />
        <stop offset="0.935007" stopColor="#5C5458" />
      </radialGradient>
      <linearGradient
        id="paint26_linear_64_89960"
        x1="26.9285"
        y1="23.2883"
        x2="25.6785"
        y2="18.6633"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00558659" stopColor="#4E3D5E" />
        <stop offset="1" stopColor="#695C77" />
      </linearGradient>
      <linearGradient
        id="paint27_linear_64_89960"
        x1="6.99349"
        y1="20.0479"
        x2="0.115967"
        y2="20.0479"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.06398" stopColor="#443851" />
        <stop offset="1" stopColor="#5D5863" />
      </linearGradient>
      <linearGradient
        id="paint28_linear_64_89960"
        x1="13.5535"
        y1="9.97489"
        x2="17.6785"
        y2="6.97489"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00558659" stopColor="#4E3D5E" />
        <stop offset="1" stopColor="#5A4969" />
      </linearGradient>
      <linearGradient
        id="paint29_linear_64_89960"
        x1="15.9908"
        y1="3.72489"
        x2="15.9908"
        y2="6.10437"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00558659" stopColor="#635C6C" />
        <stop offset="1" stopColor="#4F3E5E" />
        <stop offset="1" stopColor="#503F5F" />
      </linearGradient>
      <linearGradient
        id="paint30_linear_64_89960"
        x1="10.6315"
        y1="18.3195"
        x2="9.91279"
        y2="19.5539"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E8DFED" />
        <stop offset="1" stopColor="#C3B3D2" />
      </linearGradient>
      <linearGradient
        id="paint31_linear_64_89960"
        x1="10.5847"
        y1="21.507"
        x2="10.2097"
        y2="20.0226"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BEADCC" />
        <stop offset="0.783217" stopColor="#BEADCC" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint32_linear_64_89960"
        x1="11.5534"
        y1="18.382"
        x2="10.9593"
        y2="19.8818"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F6EBF9" />
        <stop offset="1" stopColor="#F6EBF9" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint33_linear_64_89960"
        x1="6.99092"
        y1="19.9132"
        x2="8.75513"
        y2="23.3202"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E8DFED" />
        <stop offset="1" stopColor="#C3B3D2" />
      </linearGradient>
      <linearGradient
        id="paint34_linear_64_89960"
        x1="9.83467"
        y1="23.7257"
        x2="8.83532"
        y2="22.2253"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BB99D7" />
        <stop offset="0.783217" stopColor="#BEADCC" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint35_linear_64_89960"
        x1="25.6783"
        y1="17.0007"
        x2="19.3345"
        y2="20.5017"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E8DFED" />
        <stop offset="1" stopColor="#C3B3D2" />
      </linearGradient>
      <linearGradient
        id="paint36_linear_64_89960"
        x1="18.9908"
        y1="21.507"
        x2="21.7408"
        y2="19.8507"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BEADCC" />
        <stop offset="1" stopColor="#BEADCC" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint37_linear_64_89960"
        x1="25.2095"
        y1="17.3976"
        x2="23.9752"
        y2="18.1945"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F6EBF9" />
        <stop offset="1" stopColor="#F6EBF9" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint38_linear_64_89960"
        x1="23.3658"
        y1="22.4757"
        x2="22.439"
        y2="24.1003"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E8DFED" />
        <stop offset="1" stopColor="#C3B3D2" />
      </linearGradient>
      <linearGradient
        id="paint39_linear_64_89960"
        x1="21.5377"
        y1="23.132"
        x2="21.913"
        y2="22.4603"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BEADCC" />
        <stop offset="0.783217" stopColor="#BEADCC" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint40_linear_64_89960"
        x1="19.7877"
        y1="21.5039"
        x2="22.0371"
        y2="22.8361"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BEADCC" />
        <stop offset="1" stopColor="#BEADCC" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint41_linear_64_89960"
        x1="25.3658"
        y1="22.6007"
        x2="23.6781"
        y2="23.002"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F6EBF9" />
        <stop offset="1" stopColor="#F6EBF9" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint42_linear_64_89960"
        x1="12.6537"
        y1="15.3926"
        x2="11.8283"
        y2="15.3926"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.225" stopColor="#594C4F" />
        <stop offset="1" stopColor="#392833" />
      </linearGradient>
      <linearGradient
        id="paint43_linear_64_89960"
        x1="10.7848"
        y1="15.3926"
        x2="11.991"
        y2="15.3926"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#594C4F" />
        <stop offset="1" stopColor="#594C4F" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint44_linear_64_89960"
        x1="12.9753"
        y1="17.4175"
        x2="12.9753"
        y2="15.8273"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#552D52" />
        <stop offset="1" stopColor="#552D52" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint45_linear_64_89960"
        x1="25.0535"
        y1="17.8027"
        x2="21.5535"
        y2="19.9277"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F6EBF9" />
        <stop offset="0.936976" stopColor="#E4D6E8" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint46_linear_64_89960"
        x1="24.3503"
        y1="24.0059"
        x2="21.3191"
        y2="22.2402"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F6EBF9" />
        <stop offset="0.936976" stopColor="#E4D6E8" stopOpacity="0" />
      </linearGradient>
    </defs>
  </SvgIcon>
);

export default Tiger;
