/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { Badge as BadgeMaterial, BadgeProps } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { Colors } from '../../style/variables';

const useStyles = makeStyles({
  root: {
    paddingBottom: 4,
    paddingRight: 10,

    '& .MuiBadge-anchorOriginBottomRightCircular': {
      margin: 'auto',
      border: `1px solid ${Colors['blue-primrose_l4']}`,
      backgroundColor: 'transparent',

      color: Colors['blue-primrose_l4'],
      fontWeight: 'bold'
    },
    '& .MuiBadge-dot': {
      minWidth: 12,
      height: 12,
      border: `2px solid ${Colors.white}`,
      borderRadius: 8,
      backgroundColor: `${Colors['red-rose']}`
    },
    '& .MuiBadge-colorPrimary': {
      backgroundColor: `${Colors['fir-green_l4']}`
    },
    '& .MuiBadge-colorSecondary': {
      backgroundColor: `${Colors['orange-sunset']}`
    }
  }
});

const Badge: React.FC<BadgeProps> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <BadgeMaterial
        overlap="circular"
        badgeContent=" "
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        {...props}
      />
    </div>
  );
};

export default Badge;
