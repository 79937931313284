import { PostJobRequest } from 'api/types';
import { SectionCodes } from 'const';
import { convertDateToServerDate } from 'utils';

import { isJobValid, isJobWithContractValid } from '../validation/validators';
import { GetMappedEntityData } from './types';

export const getMappedPostJobData: GetMappedEntityData<PostJobRequest> = (
  personId,
  creatorId,
  sourceCode,
  formData,
) => {
  if (!formData.professionSpoJob) return null;

  const jobFormData = formData.professionSpoJob;
  const withContract = jobFormData.hasContract;

  const isValid = isJobValid(jobFormData);
  const isValidWithContract = withContract && isJobWithContractValid(jobFormData);

  if (!sourceCode) return null;
  if (!isValid && !isValidWithContract) return null;

  const {
    recordId,
    eventId,
    position,
    mainFunctionality,
    businessLevelCode,
    salaryRangeCode,
    hasContract,
    contractTypeCode,
    isProfile,
    organization,
    graduationDate,
    expulsionDate,
    contractDate,
    hasGraduationDate,
    hasExpulsionDate,
  } = jobFormData;

  // отправка данных для клика
  if (Boolean(recordId) && Boolean(eventId)) {
    return {
      recordId,
      eventId,
      position,
      mainFunctionality,

      personId,
      creatorId,
      sourceCode,

      categoryCode: SectionCodes.profession,
      dataKind: SectionCodes.professionSpo,
      typeCode: SectionCodes.professionSpoJob,

      isDelete: false,
    };
  }

  // отправка данных для постгры
  return {
    position,
    mainFunctionality,
    businessLevelCode,
    salaryRangeCode,
    isContract: hasContract,
    contractTypeCode: hasContract ? contractTypeCode : undefined,
    isByProfile: isProfile,
    organization,
    graduationDate: hasGraduationDate ? convertDateToServerDate(graduationDate) : undefined,
    expulsionDate: hasExpulsionDate ? convertDateToServerDate(expulsionDate) : undefined,
    contractDate: hasContract ? convertDateToServerDate(contractDate) : undefined,

    personId,
    creatorId,
    sourceCode,

    categoryCode: SectionCodes.profession,
    dataKind: SectionCodes.professionSpo,
    typeCode: SectionCodes.professionSpoJob,

    isDelete: false,
  };
};
