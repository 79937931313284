import { FC, useContext, useEffect, useState } from 'react';

import { IDictionaryItem } from 'api/types';
import { MobileActionsDrawer } from 'portfolio3/components/drawers';

import { VuzLocalFiltersContext } from '../../context';
import { VuzLocalFilters } from '../../types';
import { setFilterFactory } from '../../utils';
import InnerContextControl from './InnerContextControl';

interface IQuickAccessSpecialitiesDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  specialities: IDictionaryItem[];
}

/**
 * Дровер со списком специальностей, который использует свой контекст с локальными фильтрами
 * Используется с кнопками быстрых действия
 */
const QuickAccessSpecialitiesDrawer: FC<IQuickAccessSpecialitiesDrawerProps> = ({ isOpen, onClose, specialities }) => {
  const { localFilters, onApplyFilters } = useContext(VuzLocalFiltersContext);

  const [innerLocalFilters, setInnerLocalFilters] = useState<VuzLocalFilters>(localFilters);

  const setInnerLocalSingleFilter = setFilterFactory(setInnerLocalFilters);

  // Синхронизация с верхним стейтом при открытии
  useEffect(() => {
    if (isOpen) {
      setInnerLocalFilters(localFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleReset = () => {
    const initialSpecialities: VuzLocalFilters = {
      ...innerLocalFilters,
      specialities: [],
    };
    setInnerLocalFilters(initialSpecialities);
  };

  const handleCloseAndRestoreState = () => {
    const initialSpecialities: VuzLocalFilters = {
      ...innerLocalFilters,
      specialities: [],
    };

    onClose();
    setInnerLocalFilters(initialSpecialities);
  };

  const handleCloseAndApply = () => {
    onClose();
    onApplyFilters?.(innerLocalFilters);
  };

  return (
    <MobileActionsDrawer
      open={isOpen}
      onClose={handleCloseAndRestoreState}
      title="Специальности"
      onReset={handleReset}
      onApply={handleCloseAndApply}
      isResetDisabled={!innerLocalFilters.specialities.length}
      isFullHeight
    >
      <VuzLocalFiltersContext.Provider
        value={{
          localFilters: innerLocalFilters,
          setLocalFilter: setInnerLocalSingleFilter,
          onApplyFilters: null,
        }}
      >
        <InnerContextControl specialityOptions={specialities} />
      </VuzLocalFiltersContext.Provider>
    </MobileActionsDrawer>
  );
};

export default QuickAccessSpecialitiesDrawer;
