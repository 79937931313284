import { FC, useContext, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import { Typography } from '@mui/material';
import { getStudentDiagnosisActions } from 'actions';
import { IChildInfo, ILinkVisibilitySettings } from 'api/types';
import { SelfDiagnocticDoughnutStats } from 'components/redesignStudent/diagnostic/diagnocticDoughnutStats';
import ProgressInLearning from 'components/redesignStudent/progressInLearning';
import { ALL_SUBJECTS_VALUE } from 'containers/redesignStudent/Diagnostic/const';
import { placeholderCharacter } from 'images';
import NoDataPlug from 'portfolio3/components/common/NoDataPlug';
import { WidgetContainer } from 'portfolio3/components/common/WidgetContainer';
import { IRootState } from 'reducers';

import DiagnosticContext from '../context/diagnosticContext';

interface ISelfDiagnosticsContainer {
  currentStudent: IChildInfo;
  linkMode: boolean | undefined;
  linkVisibilitySettings: ILinkVisibilitySettings;
  getStudentDiagnosis: typeof getStudentDiagnosisActions.request;
}

const SelfDiagnosticsContainer: FC<ISelfDiagnosticsContainer> = ({
  currentStudent,
  linkMode,
  linkVisibilitySettings,
  getStudentDiagnosis,
}) => {
  const { filters, selfDiagnostics, selfDiagnosticSubjects } = useContext(DiagnosticContext);
  const diagnosticContent = selfDiagnostics?.content;

  const sortedDiagnosticSubjects = useMemo(
    () => diagnosticContent?.selfDiagnosticSubjects.sort((firstItem, secondItem) => secondItem.total - firstItem.total),
    [diagnosticContent?.selfDiagnosticSubjects],
  );

  /* отправка запроса при изменении фильтров */
  useEffect(() => {
    if (!currentStudent.meshId) return;

    const { display } = linkVisibilitySettings.content || {};
    if (!linkMode || display?.selfDiagnostic) {
      const subjectFromOptions = selfDiagnosticSubjects.find(
        (subject) => subject.code === filters.selfSubjectCode,
      )?.value;
      // При выбранном "Все предметы" отправляем undefined
      const subject = subjectFromOptions === ALL_SUBJECTS_VALUE ? undefined : subjectFromOptions;
      getStudentDiagnosis(currentStudent.meshId, filters.selfPeriodCode, subject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStudent.meshId, filters.selfPeriodCode, filters.selfSubjectCode]);

  const bestSubjectResult = diagnosticContent?.selfDiagnosticSubjects.reduce<number | undefined>(
    (bestResult, subject) => {
      if (!bestResult) return subject.total;
      return subject.total > bestResult ? subject.total : bestResult;
    },
    undefined,
  );

  if (
    !selfDiagnostics?.content?.selfDiagnosticSubjects ||
    selfDiagnostics.content?.selfDiagnosticSubjects.length === 0
  ) {
    return (
      <NoDataPlug
        image={placeholderCharacter}
        title="Пройденных самодиагностик пока нет"
        subtitle="Как только они появятся, информация о них отобразится здесь"
        loading={selfDiagnostics?.loading}
      />
    );
  }

  return (
    <>
      {/* графики */}
      <WidgetContainer.Padding sx={{ marginTop: '12px' }}>
        <SelfDiagnocticDoughnutStats
          studentDiagnosis={selfDiagnostics.content}
          bestSubject={selfDiagnostics.content?.bestResult}
        />
      </WidgetContainer.Padding>

      <WidgetContainer.Divider />

      {/* статистика по предметам */}
      <WidgetContainer.Padding>
        <Typography variant="Headings/H4">По предметам</Typography>
        <ProgressInLearning barData={sortedDiagnosticSubjects ?? []} bestSubjectValue={bestSubjectResult} />
      </WidgetContainer.Padding>
    </>
  );
};

export default connect(
  (state: IRootState) => ({
    currentStudent: state.currentStudent,
    linkMode: state.linkMode.mode,
    linkVisibilitySettings: state.linkVisibilitySettings,
  }),
  {
    getStudentDiagnosis: getStudentDiagnosisActions.request,
  },
)(SelfDiagnosticsContainer);
