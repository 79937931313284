import { FC, PropsWithChildren } from 'react';
import { MoonLoader } from 'react-spinners';

import { Box, Stack, Typography, useTheme } from '@mui/material';
import { getAccentColor } from 'portfolio3/styles';
import { mergeSx } from 'utils';

import * as styles from './styles';

interface INoDataPlugProps {
  textWidth?: number;
  image?: string;
  title?: string;
  subtitle?: string | JSX.Element;

  loading?: boolean;
}

const NoDataPlug: FC<INoDataPlugProps & PropsWithChildren> = ({
  textWidth,
  image,
  title,
  subtitle,
  loading,
  children,
}) => {
  const theme = useTheme();

  const accentColor = getAccentColor(theme.palette.accentColor, '100');

  if (loading) {
    return (
      <Box sx={styles.root}>
        <MoonLoader css="margin-top: 20px" color={accentColor} loading={true} size={150} />
      </Box>
    );
  }

  return (
    <Box sx={styles.root} className="no-data-plug">
      {image && <img src={image} alt="Данных пока нет" />}
      {(title || subtitle) && (
        <Stack className="no-data-plug__text" gap="4px" maxWidth={textWidth} sx={styles.marginTop(12)}>
          {title && (
            <Typography sx={mergeSx(styles.text, styles.headingText)} variant="Headings/H5">
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography sx={mergeSx(styles.text, { color: '#7B819B' })} variant="Paragraph LG/Medium">
              {subtitle}
            </Typography>
          )}
        </Stack>
      )}
      {children && (
        <Box className="no-data-plug__content" sx={styles.marginTop(12)}>
          {children}
        </Box>
      )}
    </Box>
  );
};

export default NoDataPlug;
