import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconMinus: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 13H5a1 1 0 1 1 0-2h14a1 1 0 1 1 0 2Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconMinus;
