import { FC } from 'react';

import { IModifiedReward } from 'types';

import RewardCompact from '../../RewardCompact';
import BaseCard from '../BaseCard';

interface ICommonRewardCardProps {
  reward: IModifiedReward;
  onView: () => void;
}

const CommonRewardCard: FC<ICommonRewardCardProps> = ({ reward, onView }) => {
  const { name, awardDate, description, image, isApproved } = reward;

  const previewElement = <RewardCompact isApproved={isApproved} image={image} />;

  return (
    <BaseCard
      isApproved={isApproved}
      title={name}
      place={description}
      dateElement={awardDate}
      rightElement={previewElement}
      onView={onView}
    />
  );
};

export default CommonRewardCard;
