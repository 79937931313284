/* eslint-disable max-len */
import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconMetaSkillTeacup: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.125 17.501h-4.75l-3.332-1.19-3.472 1.19H1.875a.627.627 0 0 0-.625.625c0 .344.281.625.625.625h16.25a.627.627 0 0 0 .625-.625.627.627 0 0 0-.625-.625Z"
      fill="#7D4533"
    />
    <path
      d="M13.4 17.502c2.262 0 4.1-1.838 4.1-4.1V7.464a.587.587 0 0 0-.587-.588H3.088a.587.587 0 0 0-.588.588v5.938c0 2.262 1.838 4.1 4.1 4.1h6.8Z"
      fill="#A7A8B7"
    />
    <path
      d="M6.831 5.776c-.15-1.014.016-1.325.23-1.725.122-.23.261-.49.364-.93.2-.832-.1-1.47-.331-1.813-.069-.1-.225-.063-.244.056-.086.621-.403 1.105-.693 1.547-.278.425-.532.812-.532 1.247 0 1.018.644 1.543 1.006 1.756a.135.135 0 0 0 .2-.138ZM10.269 5.776c-.15-1.014.015-1.325.229-1.725.123-.23.262-.49.365-.93.2-.832-.1-1.47-.332-1.813-.069-.1-.225-.063-.244.056-.086.621-.402 1.105-.692 1.547-.279.425-.533.812-.533 1.247 0 1.018.644 1.543 1.007 1.756a.135.135 0 0 0 .2-.138ZM13.506 5.914a.135.135 0 0 0 .2-.138c-.15-1.014.016-1.325.23-1.725.122-.23.261-.49.364-.93.2-.832-.1-1.47-.331-1.813-.069-.1-.225-.063-.244.056-.086.621-.403 1.105-.693 1.547-.278.425-.532.812-.532 1.247 0 1.018.644 1.543 1.006 1.756Z"
      fill="#D3D3D3"
    />
    <path
      d="M6.256 8.125 4.638 9.218a.6.6 0 0 0-.263.495v3.018a.39.39 0 0 0 .389.394h2.972a.39.39 0 0 0 .389-.394V9.713a.6.6 0 0 0-.263-.495L6.256 8.125Z"
      fill="#86D72F"
    />
    <path
      d="M6.25 6.25a.469.469 0 0 0-.469.469V9.53a.469.469 0 0 0 .938 0V6.72a.469.469 0 0 0-.469-.469Z"
      fill="#E6E6E6"
    />
  </SvgIcon>
);

export default IconMetaSkillTeacup;
