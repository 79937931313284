import { ComponentProps, FC } from 'react';

import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { commonTheme } from 'portfolio3/styles/theme';
import { isDefined } from 'utils';

import { MilitaryTrainingTypeNames } from '../../const';
import { IVuzInfo } from '../../types';
import VuzIcon from '../VuzCard/VuzCardHeader/VuzIcon';
import DrawerVuzCardRow from './DrawerVuzCardRow';
import { drawerVuzCard } from './styles';

type CardRowComponentProps = ComponentProps<typeof DrawerVuzCardRow>;

interface IDrawerVuzCardProps {
  vuzInfo: IVuzInfo;
}

const DrawerVuzCard: FC<IDrawerVuzCardProps> = ({ vuzInfo }) => {
  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));
  const { name, logo, address, commiteeAddress, phone, commiteePhone, vuzUrl, warDepartment, warCenter } = vuzInfo;

  const warText: string[] = [
    warDepartment ? MilitaryTrainingTypeNames.warDepartment : null,
    warCenter ? MilitaryTrainingTypeNames.warCenter : null,
  ].filter(isDefined);

  const cardRows: CardRowComponentProps[] = [];

  if (address) {
    cardRows.push({
      leftSide: 'Ректорат',
      rightSide: `${address} ${isDefined(phone) ? phone : ''}`,
    });
  }

  if (commiteeAddress) {
    cardRows.push({
      leftSide: 'Приёмная комиссия',
      rightSide: `${commiteeAddress} ${isDefined(commiteePhone) ? commiteePhone : ''}`,
    });
  }

  if (vuzUrl) {
    cardRows.push({
      leftSide: 'Сайт',
      rightSide: vuzUrl,
      isUrl: true,
    });
  }

  if (warDepartment || warCenter) {
    cardRows.push({
      leftSide: 'Военная подготовка',
      rightSide: warText.join('; '),
    });
  }

  const mappedCardRows = cardRows.map((cardRow, index): CardRowComponentProps => {
    return {
      ...cardRow,
      isMobile,
      disableBottomUnderline: !isMobile && index === cardRows.length - 1,
    };
  });

  return (
    <Box sx={drawerVuzCard(isMobile)} className="drawer-vuz-card">
      <Stack gap="12px" direction="row" className="drawer-vuz-card__header">
        <VuzIcon logo={logo} />
        <Typography
          variant={isMobile ? 'Paragraph MD/Semi Bold' : 'Paragraph LG/Semi Bold'}
          fontWeight={600}
          className="drawer-vuz-card__title"
        >
          {name}
        </Typography>
      </Stack>
      <Stack marginTop="16px" gap={isMobile ? '16px' : '8px'}>
        {mappedCardRows.map((cardRow) => (
          <DrawerVuzCardRow key={cardRow.leftSide} {...cardRow} />
        ))}
      </Stack>
    </Box>
  );
};

export default DrawerVuzCard;
