import { FC } from 'react';

import { InputAdornment } from '@mui/material';
import { ClearInputButton } from 'portfolio3/ui-kit/inputButtons';

import { InputController } from '../../types';
import { UiKitInputBaseProps } from '../types';
import Input from './Input';

interface IClearableInputProps extends UiKitInputBaseProps {
  controller: InputController;
  forceClearButtonShow?: boolean;
  onClear?: () => void;
}

const ClearableInput: FC<IClearableInputProps> = ({ forceClearButtonShow, onClear, ...props }) => {
  const handleClear = () => {
    props.controller.handleChange('');
    onClear?.();
  };

  const showClearButton = forceClearButtonShow || (Boolean(props.value) && String(props.value).length > 0);

  const endAdornment = showClearButton && (
    <InputAdornment position="end">
      <ClearInputButton onClick={handleClear} disabled={props.disabled} />
    </InputAdornment>
  );

  return <Input endAdornment={endAdornment} {...props} />;
};

export default ClearableInput;
