import { FC } from 'react';

import { Stack } from '@mui/material';
import { useAppSelector, useDataLoad } from 'hooks';
import { ProfessionProforientationTestCard } from 'portfolio3/features/pdf/components/cards';
import PdfNoDataText from 'portfolio3/features/pdf/components/PdfNoDataText';
import { proforientationModel } from 'portfolio3/features/proforientation';
import { getDataLoadRequests } from 'utils';

import { widgetPdfIcons } from '../../../../icons';
import PdfCommonDataEntityContainer from '../common';

import '../index.scss';

const PdfProforientationTests: FC = () => {
  const proforientationTests = useAppSelector(proforientationModel.selectors.proforientationTestsSelector);
  useDataLoad({ shouldLoad: true, requests: getDataLoadRequests().professionProforientation });

  const hasData = proforientationTests.length > 0;

  return (
    <PdfCommonDataEntityContainer
      title="Профессиональные пробы"
      icon={widgetPdfIcons.pdfIconProfessionProforientationTests}
    >
      {hasData && (
        <Stack gap="16px">
          {proforientationTests.map((proforientationTest, index) => {
            const key = `${proforientationTest.name}-${index}`;
            return <ProfessionProforientationTestCard key={key} proforientationTest={proforientationTest} />;
          })}
        </Stack>
      )}
      {!hasData && <PdfNoDataText />}
    </PdfCommonDataEntityContainer>
  );
};

export default PdfProforientationTests;
