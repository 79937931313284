import { CSSObject, SxProps } from '@mui/material';
export const imgWrapper: SxProps = {
  width: '28px',
  height: '28px',
  position: 'absolute',
  bottom: '8px',
  left: '10px',
  border: '3px solid #FFF',
  borderRadius: '50%',
  '& img': {
    width: '100%',
    height: '100%',
  },
};

export const chipContent: CSSObject = {
  position: 'relative',
  height: '100%',
  '& > img': {
    height: '65%',
  },
};
