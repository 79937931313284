/* eslint-disable prettier/prettier */
/* eslint-disable */

import connectReducers from '../utils/connectReducers';

import { GET_USER_CONTEXT } from '../actions';

import { SUCCESS } from '../actions/utils';

import { IUserContext } from '../api/types';

export type TUserState = IUserContext;

export interface IUserReducers {
    [key: string]: (state: TUserState, action: any) => TUserState;
}

const initialState = {
  data: undefined
} as IUserContext;

const currentUser: IUserReducers = {
    [GET_USER_CONTEXT[SUCCESS]]: (state, { payload: user }: { payload: IUserContext }) => ({
        ...state,
        ...user
    })
};

export default connectReducers(initialState, currentUser);
