import { FC } from 'react';

import { Box } from '@mui/material';
import { IconMoreHorizontal } from 'icons';
import TrimText from 'portfolio3/components/common/TrimText';
import WithActionsPopup from 'portfolio3/components/common/WithActionsPopup';
import { Button } from 'portfolio3/ui-kit/button';

import { IEntityActionWithType } from '../../types';
import * as styles from './styles';

interface IMobileEntityActionsProps {
  actions: IEntityActionWithType[];
}

const MobileEntityActions: FC<IMobileEntityActionsProps> = ({ actions }) => {
  const totalActions = actions.length;

  const editAction = actions.find((action) => action.type === 'edit');
  const deleteAction = actions.find((action) => action.type === 'delete');

  if (!editAction && totalActions <= 2) {
    return (
      <Box className="mobile-entity-actions" sx={styles.verticalLayout}>
        {actions.map((action) => {
          const IconComponent = action.icon;

          return (
            <Button
              key={action.type}
              variant={action.mobileButtonVariant}
              iconLeft={IconComponent && <IconComponent />}
              disabled={action.isDisabled}
              onClick={action.effect}
              sx={{ width: '100%' }}
              size="large"
            >
              <TrimText>{action.text}</TrimText>
            </Button>
          );
        })}
      </Box>
    );
  }

  const firstAction = editAction ? editAction : actions[0];
  const restActions = editAction ? actions.filter((action) => action !== editAction) : actions.slice(1);
  // перенос действия удаления в начало списка
  const sortedRestActions = deleteAction
    ? [deleteAction, ...restActions.filter((action) => action !== deleteAction)]
    : restActions;

  const FirstActionIconComponent = firstAction.icon;
  const DeleteActionIconComponent = deleteAction?.icon;

  const firstButton = (
    <Button
      variant={firstAction.mobileButtonVariant}
      iconLeft={FirstActionIconComponent && <FirstActionIconComponent />}
      disabled={firstAction.isDisabled}
      onClick={firstAction.effect}
      sx={{ flexGrow: 1 }}
      size="large"
    >
      <TrimText>{firstAction.text}</TrimText>
    </Button>
  );

  const restActionsElement = (
    <WithActionsPopup
      actions={sortedRestActions}
      MenuProps={{
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      }}
    >
      {(ref, onClick) => {
        return (
          <Button onlyIcon ref={ref} onClick={onClick} variant="secondary" size="large">
            <IconMoreHorizontal />
          </Button>
        );
      }}
    </WithActionsPopup>
  );

  const deleteActionButtonElement = deleteAction && (
    <Button
      onlyIcon
      variant={deleteAction.mobileButtonVariant}
      disabled={deleteAction.isDisabled}
      onClick={deleteAction.effect}
      size="large"
    >
      {DeleteActionIconComponent && <DeleteActionIconComponent />}
    </Button>
  );

  return (
    <Box className="mobile-entity-actions" sx={styles.horizontalLayout}>
      {firstButton}
      {totalActions === 2 && Boolean(deleteAction) ? deleteActionButtonElement : restActionsElement}
    </Box>
  );
};

export default MobileEntityActions;
