import React from 'react';

import { Box } from '@mui/material';

import { colorPreview } from './style';

interface IThemeBgColorPreviewProps {
  themeColor: string;
}

const ThemeBgColorPreview: React.FC<IThemeBgColorPreviewProps> = ({ themeColor }) => {
  return <Box sx={{ background: themeColor, ...colorPreview }}></Box>;
};

export default ThemeBgColorPreview;
