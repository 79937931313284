import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { proforientationModelTypes } from 'portfolio3/features/proforientation';
import { NeutralColors } from 'portfolio3/styles';

import * as styles from './styles';

interface IProfessionProforientationTestCardProps {
  proforientationTest: proforientationModelTypes.IProforientationTest;
}

const ProfessionProforientationTestCard: FC<IProfessionProforientationTestCardProps> = ({ proforientationTest }) => {
  const { date, name, location } = proforientationTest;

  return (
    <Box className="proforientation-test-card pdf-keep" sx={styles.root}>
      <Typography variant="Paragraph MD/Regular" color={NeutralColors.light_neutrals_700}>
        {date}
      </Typography>
      <Typography variant="Paragraph LG/Semi Bold">{name}</Typography>
      <Typography variant="Paragraph MD/Regular">{location}</Typography>
    </Box>
  );
};

export default ProfessionProforientationTestCard;
