import { FC, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { IconChevronSmallDown, IconChevronSmallUp } from 'icons';
import { Checkbox } from 'portfolio3/ui-kit';

import { ILinkSetting } from '../../types';
import ChildSettingBlock from '../ChildSettingBlock';
import * as styles from './styles';

interface ISettingBlockProps {
  setting: ILinkSetting;
}

const SettingBlock: FC<ISettingBlockProps> = ({ setting }) => {
  const [isOpen, setOpen] = useState(false);

  const { isActive, isIndeterminate, name, toggle, childSettings } = setting;
  const hasChildSettings = childSettings && childSettings.length > 0;

  const toggleleOpen = () => {
    setOpen((prevState) => !prevState);
  };

  return (
    <Box className="sharing-setting-block" sx={styles.root}>
      <Box className="sharing-setting-block__title" sx={styles.title}>
        <Checkbox size="small" checked={isActive} onChange={toggle} indeterminate={isIndeterminate} />
        <Typography variant="Paragraph MD/Semi Bold" sx={styles.name}>
          {name}
        </Typography>
        {hasChildSettings && (
          <Box component="button" sx={styles.expandButton} onClick={toggleleOpen}>
            {isOpen ? <IconChevronSmallUp /> : <IconChevronSmallDown />}
          </Box>
        )}
      </Box>
      {isOpen && (
        <Box className="sharing-setting-block__content">
          {childSettings?.map((childSetting) => {
            return <ChildSettingBlock key={childSetting.name} setting={childSetting} indentLevel={1} />;
          })}
        </Box>
      )}
    </Box>
  );
};

export default SettingBlock;
