import { FC } from 'react';

import { Box } from '@mui/material';
import { IconFunnelFilled } from 'icons';
import { getAccentColor } from 'portfolio3/styles';
import { Button, IButtonProps } from 'portfolio3/ui-kit/button';
import { SxStyles } from 'types';

const styles = (withIndicator: boolean): SxStyles => {
  return {
    position: 'relative',
    display: 'flex',

    ':after': withIndicator
      ? {
          content: '""',
          position: 'absolute',
          top: '-1px',
          right: '-2px',

          width: '6px',
          height: '6px',

          border: '2px solid #ffffff',
          borderRadius: '50%',
          backgroundColor: getAccentColor('indigo', '100'),
        }
      : null,
  };
};

interface IFilterButtonProps extends IButtonProps {
  withIndicator?: boolean;
}

const FilterButton: FC<IFilterButtonProps> = ({ withIndicator, ...props }) => {
  return (
    <Button variant="secondary" size="small" onlyIcon {...props}>
      <Box sx={styles(withIndicator ?? false)}>
        <IconFunnelFilled />
      </Box>
    </Button>
  );
};

export default FilterButton;
