/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';

import { GET_PERSONS_OBJECTS } from '../../actions/employees';

import {
  FAILURE,
  INVALIDATE,
  REQUEST,
  SUCCESS
} from '../../actions/utils';

import genericReducers from '../genericReducer';
import { IReducers } from '..';
import { ICommonResponse, IPersonObject } from '../../api/types';

export type PersonsObjectsState = ICommonResponse<IPersonObject>;

const initialState: PersonsObjectsState = {
  content: [],
  loading: false,
};

const personsObjects: IReducers<PersonsObjectsState> = {
  [GET_PERSONS_OBJECTS[REQUEST]]: genericReducers().get.request,
  [GET_PERSONS_OBJECTS[SUCCESS]]: genericReducers().get.success,
  [GET_PERSONS_OBJECTS[FAILURE]]: genericReducers().get.failure,
  [GET_PERSONS_OBJECTS[INVALIDATE]]: genericReducers().get.invalidate(initialState),
};

export default connectReducers(initialState, personsObjects);
