import { FC, PropsWithChildren, ReactNode, RefObject } from 'react';
import { DatePickerState, DateRangePickerState } from 'react-stately';

import { Box, ClickAwayListener, Grow, Popper } from '@mui/material';

import * as styles from './styles';

interface IPopoverProps {
  triggerRef: RefObject<Element>;
  datePickerState: DatePickerState | DateRangePickerState;
  optionsElement?: ReactNode;
}

const Popover: FC<IPopoverProps & PropsWithChildren> = ({ triggerRef, datePickerState, optionsElement, children }) => {
  const { isOpen, close } = datePickerState;

  return (
    <Popper open={isOpen} anchorEl={triggerRef.current} transition placement="bottom-start" sx={{ zIndex: 9999 }}>
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} style={{ transformOrigin: '0 0 0' }}>
          <Box className="date-popover" sx={styles.root}>
            <ClickAwayListener onClickAway={close}>
              <Box className="date-popover__container" sx={styles.container}>
                <Box className="date-popover__content" sx={styles.content}>
                  {children}
                </Box>
                {optionsElement}
              </Box>
            </ClickAwayListener>
          </Box>
        </Grow>
      )}
    </Popper>
  );
};

export default Popover;
