import { CommonUiKitSize } from '../../types';
import { IToggleLabelTokens } from '../types';

export const ToggleLabelFontSize: Record<CommonUiKitSize, number> = {
  small: 14,
  medium: 15,
  large: 15,
};

export const ToggleLabelLineHeight: Record<CommonUiKitSize, number> = {
  small: 20,
  medium: 24,
  large: 24,
};

export const getToggleLabelTokens = (size: CommonUiKitSize): IToggleLabelTokens => {
  return {
    fontSize: ToggleLabelFontSize[size],
    lineHeight: ToggleLabelLineHeight[size],
  };
};
