/* eslint-disable prettier/prettier */
/* eslint-disable */

import { InterestHeadKindCodes, InterestHeadKindKeys } from '../const';

export const getInterestCategoryLabel = (interestHeadCode: number | undefined) => {
  switch (interestHeadCode) {
    case InterestHeadKindCodes[InterestHeadKindKeys.CINEMA]:
    case InterestHeadKindCodes[InterestHeadKindKeys.MUSIC]: {
      return 'Жанры';
    }
    default: return 'Категории';
  }
};
