import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  clearEntityErrorMessagesAction,
  deletePortfolioAffilationActions,
  deletePortfolioEmploymentActions,
  deletePortfolioEventActions,
  deletePortfolioGiaWorldSkillsActions,
  deletePortfolioJobActions,
  deletePortfolioProjectActions,
  deletePortfolioRewardActions,
  deletePortfolioSpoDocumentActions,
  deletePortfolioSportRewardActions,
  deleteStudentCheckInActions,
  getAttachmentActions,
  getEntityErrorMessagesActions,
  reportErrorDrawerActions,
  thanksTeacherDrawerActions,
} from 'actions';
import { IStudentFile, IStudentReward, IStudentSportReward } from 'api/types';
import { IDeleteDialogData } from 'components/redesignStudent/DataEntity/types';
import { EntityType, getPersonsObjectsQueryParams } from 'const';
import { useAppSelector, useDataEntryDrawer, useDetailViewDrawer, useUserFunctionality } from 'hooks';
import { WidgetDataContext } from 'portfolio3/components/common/WidgetContainer';
import InfoModal from 'portfolio3/ui-kit/modals/InfoModal';
import { Action } from 'redux';
import { ICardData } from 'types';
import {
  getDataItemType,
  getDeleteDialogEntityTitle,
  getDeleteDialogEntityType,
  getEntitySectionTitle,
  getEntityTypeByTypeCode,
} from 'utils';

import { renderDeleteEntityDialogText } from '../utils';

interface IGetHandlersParameters {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formData: any;
  cardData?: ICardData;
  onDeleted?: () => void;
}

const useCommonEntityHandlers = () => {
  const dispatch = useDispatch();
  const currentStudent = useAppSelector((state) => state.currentStudent);
  const linkMode = useAppSelector((state) => state.linkMode.mode ?? false);
  const sectionRef = useAppSelector((state) => state.sectionRef.content);

  const { widgetLabel } = useContext(WidgetDataContext);

  const { isStudent } = useUserFunctionality();

  const { handleOpenPrimaryDataEntry } = useDataEntryDrawer();
  const { handleOpenPrimaryDetailView } = useDetailViewDrawer();
  const [deleteDialogData, setDeleteDialogData] = useState<IDeleteDialogData>();

  const getHandlers = ({ formData, cardData, onDeleted }: IGetHandlersParameters) => {
    const dataKind: number | undefined = formData?.dataKind;
    const typeCode: number | undefined = formData?.typeCode || formData?.type?.code;
    // невозможно по dataKind определить тип некоторых сущностей
    const entityType = getEntityTypeByTypeCode(typeCode) ?? getDataItemType(dataKind);

    const isEntityAttachable = entityType === EntityType.EVENT || entityType === EntityType.PROJECT;

    const entitySectionTitle = getEntitySectionTitle(sectionRef, dataKind, typeCode, cardData?.titleFallback);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const handleDownloadAttachments = (files: IStudentFile[] | null) => {
      return () => {
        files?.map((item: IStudentFile) => {
          dispatch(getAttachmentActions.request({ id: item.id, personId: currentStudent.meshId, name: item.name }));
        });
      };
    };

    const handleView = () => {
      if (cardData && entityType) {
        handleOpenPrimaryDetailView(cardData, entityType);
      }
    };

    const handleEdit = () => {
      if (linkMode) return;
      if (!formData?.id && !formData.recordId) return;

      handleOpenPrimaryDataEntry(formData, { widgetLabel: widgetLabel ?? undefined });
    };

    const handleOpenDeleteDialog = (rewards?: IStudentReward[], sportRewards?: IStudentSportReward[]) => {
      return () => {
        if (linkMode) return;

        const linkedEntities = isEntityAttachable
          ? [...(rewards ?? []), ...(sportRewards ?? [])]?.filter((object) => object.entityId == cardData?.formData.id)
          : undefined;

        setDeleteDialogData({
          open: true,
          name: cardData?.formData.name,
          typeName: cardData?.formData.isCheckIn ? 'чек-ин' : getDeleteDialogEntityType(typeCode || dataKind),
          titleTypeName: cardData?.formData.isCheckIn ? 'чек-ина' : getDeleteDialogEntityTitle(typeCode || dataKind),
          linkedEntityNames: linkedEntities?.map((entity) => entity.name).join(', '),
          linkedEntityTypeName: linkedEntities && linkedEntities?.length > 1 ? 'награды' : 'награду',
        });
      };
    };

    const handleReport = () => {
      if (linkMode) return;
      if (!entityType) return;

      const entityId = formData.id;
      const recordId = formData.recordId;

      dispatch(clearEntityErrorMessagesAction());
      dispatch(
        reportErrorDrawerActions.setFormData({
          entityName: formData.name,
          categoryName: entitySectionTitle,
          entityType,
          entityId,
          recordId,
        }),
      );
      dispatch(reportErrorDrawerActions.setOpen(true));
      dispatch(getEntityErrorMessagesActions.request(currentStudent.meshId, entityType, Number(entityId), recordId));
    };

    const handleThanksTeacher = () => {
      if (linkMode) return;
      if (!entityType) return;

      dispatch(
        thanksTeacherDrawerActions.setFormData({
          entityName: formData.name,
          categoryName: entitySectionTitle,
          entityType,
          entityId: formData.id,
          recordId: formData.recordId,
          entityFormData: formData,
        }),
      );
      dispatch(thanksTeacherDrawerActions.setOpen(true, widgetLabel));
    };

    function handleCloseDeleteDialog() {
      setDeleteDialogData((prevstate) => {
        if (!prevstate) return;
        return {
          ...prevstate,
          open: false,
        };
      });
    }

    function handleSubmitDeleteDialog() {
      if (cardData?.formData.isCheckIn) {
        dispatch(deleteStudentCheckInActions.request(cardData.formData?.personId, cardData.formData?.id));
        return;
      }

      if (!entityType) return;

      const personId = currentStudent.meshId;
      handleDeletePortfolioObject(entityType, cardData?.formData?.id, personId);
    }

    function handleDeletePortfolioObject(type: EntityType, id: number, personId: string) {
      let action: Action<unknown> | null = null;

      switch (type) {
        case EntityType.REWARD:
          action = deletePortfolioRewardActions.request(id, personId, getPersonsObjectsQueryParams, true);
          break;
        case EntityType.SPORT_REWARD:
          action = deletePortfolioSportRewardActions.request(id, personId, getPersonsObjectsQueryParams, true);
          break;
        case EntityType.EVENT:
        case EntityType.OLYMPIAD:
          action = deletePortfolioEventActions.request(id, personId, getPersonsObjectsQueryParams, true);
          break;
        case EntityType.PROJECT:
          action = deletePortfolioProjectActions.request(id, personId, getPersonsObjectsQueryParams, true);
          break;
        case EntityType.AFFILATION:
          action = deletePortfolioAffilationActions.request(id, personId, getPersonsObjectsQueryParams, true);
          break;
        case EntityType.EMPLOYMENT:
          action = deletePortfolioEmploymentActions.request(id, personId, getPersonsObjectsQueryParams, true);
          break;
        case EntityType.JOB:
          action = deletePortfolioJobActions.request(id, personId, getPersonsObjectsQueryParams, true);
          break;
        case EntityType.DOCUMENT:
          action = deletePortfolioSpoDocumentActions.request(id, personId, getPersonsObjectsQueryParams, true);
          break;
        case EntityType.GIA_WORLDSKILLS:
          action = deletePortfolioGiaWorldSkillsActions.request(id, personId, getPersonsObjectsQueryParams, true);
          break;
        default:
          break;
      }

      if (action) {
        dispatch(action);
      }

      handleCloseDeleteDialog();
      onDeleted?.();
    }

    const deleteDialogElement = (
      <InfoModal
        open={deleteDialogData?.open ?? false}
        onClose={handleCloseDeleteDialog}
        header={`Удаление ${deleteDialogData?.titleTypeName}`}
        text={renderDeleteEntityDialogText(currentStudent, deleteDialogData, isStudent)}
        variant="warning"
        cancelButtonText="Отмена"
        onCancel={handleCloseDeleteDialog}
        actionButtonText="Да, удалить"
        onAction={handleSubmitDeleteDialog}
      />
    );

    return {
      deleteDialogElement,
      handleView,
      handleEdit,
      handleDelete: handleOpenDeleteDialog,
      handleReport,
      handleThanksTeacher,
      handleDownloadAttachments,
    };
  };

  return {
    getHandlers,
  };
};

export default useCommonEntityHandlers;
