import { SxStyles } from 'types';

export const root: SxStyles = (theme) => {
  return {
    display: 'flex',
    gap: '24px',

    [theme.breakpoints.down('commonSm')]: {
      gap: '16px',
    },
  };
};

export const image: SxStyles = {
  width: '48px',
  height: '48px',
};
