import { FC } from 'react';

import { Box } from '@mui/material';
import { IPathWithName } from 'types';

import { DesktioPortfolioNavigationButton } from '../../PortfolioNavigation';
import ServiceTitle from '../../ServiceTitle';
import * as styles from './styles';

interface IEmployeeServiceHeaderProps {
  routes: IPathWithName[];
}

const EmployeeServiceHeader: FC<IEmployeeServiceHeaderProps> = ({ routes }) => {
  return (
    <Box className="employee-service-header" sx={styles.root}>
      <ServiceTitle />
      {routes.length > 0 && (
        <Box className="routes" sx={styles.routes}>
          {routes.map((route) => (
            <DesktioPortfolioNavigationButton key={route.path} to={route.path} name={route.title} />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default EmployeeServiceHeader;
