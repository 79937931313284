import { SxStyles } from 'types';

export const ratingTabTypographyContainer: SxStyles = ({ breakpoints }) => ({
  textAlign: 'left',
  [breakpoints.down('commonSm')]: {
    textAlign: 'center',
  },
});

export const ratingTabHeadingTypography: SxStyles = ({ breakpoints }) => ({
  fontWeight: 800,
  [breakpoints.down('commonMd')]: {
    fontSize: '16px',
    fontWeight: 800,
  },

  [breakpoints.down('commonSm')]: {
    lineHeight: '20px',
    fontSize: '14px',
    fontWeight: 700,
  },
});

export const ratingTabTextTypography: SxStyles = ({ breakpoints }) => ({
  [breakpoints.down('commonMd')]: {
    textAlign: 'left',
    fontSize: '14px',
  },

  [breakpoints.down('commonSm')]: {
    textAlign: 'center',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
  },
});
