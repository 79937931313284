import { shuffle } from 'lodash';
import { createSelector } from 'reselect';

import { ICommonResponse, ILiteratureObject, IRecommendObject } from '../../../api/types';
import { IRootState } from '../../../reducers';
import { IRecommendationEntity } from '../../../types';
import { getAge } from '../../../utils';
import { SectionCodes } from './../../../const/sections';
import { RecommendationsSectionThemes } from './recommendationThemes';

/**
 * Преобразует список объеков рекомендаций кружков
 * @param recommendObjects список объектов рекомендаций из ответа
 * @returns список рекомендаций
 */
const mapSectionRecommendations = (recommendObjects: IRecommendObject[]): IRecommendationEntity[] => {
  const sectionBadge = 'Кружок';

  const sectionRecommendations = recommendObjects.map((sectionRecommendations): IRecommendationEntity[] => {
    const { recommendInfo: recommendations, sectionCode } = sectionRecommendations;
    const { backgroundIllustration, accentColor } = RecommendationsSectionThemes[sectionCode];

    return recommendations.map((recommendation): IRecommendationEntity => {
      const { programmShortName, placeServiceName, serviceId } = recommendation;

      return {
        badge: sectionBadge,
        title: programmShortName,
        place: placeServiceName,
        link: `https://www.mos.ru/pgu/ru/app/dogm/077060701/?serviceId=${serviceId}#step_1`,
        sectionCode,
        buttonTitle: 'Подробнее',
        backgroundIllustration,
        accentColor,
      };
    });
  });

  return sectionRecommendations.flatMap((_) => _);
};

/**
 * Преобразует список объеков рекомендаций по литературе
 * @param literatureObjects список объектов рекомендаций из ответа
 * @returns список рекомендаций
 */
const mapLiteratureRecommendations = (literatureObjects: ILiteratureObject[]): IRecommendationEntity[] => {
  const literatureBadge = 'Литература';
  const { backgroundIllustration, accentColor } = RecommendationsSectionThemes[SectionCodes.study];

  return literatureObjects.map((literatureRecommendation): IRecommendationEntity => {
    const { name, author, launchUrl } = literatureRecommendation;

    return {
      badge: literatureBadge,
      title: name,
      place: author,
      link: launchUrl,
      sectionCode: SectionCodes.study,
      buttonTitle: 'Читать',
      backgroundIllustration,
      accentColor,
    };
  });
};

const profileRecommendationsSelector = createSelector(
  [(state: IRootState) => state.studentRecommendations, (state: IRootState) => state.currentStudent],
  (studentRecommendations, currentStudent): ICommonResponse<IRecommendationEntity> => {
    if (!studentRecommendations.content?.recommendObject) {
      return {
        loading: studentRecommendations.loading,
        content: [],
      };
    }

    const responseSectionRecommendations = studentRecommendations.content?.recommendObject || [];
    const responseLiteratureRecommendations = studentRecommendations.content?.literature?.literatureObject || [];

    const sectionRecommendations = mapSectionRecommendations(responseSectionRecommendations);
    const literatureRecommendations = mapLiteratureRecommendations(responseLiteratureRecommendations);

    const recommendationEntities = [...sectionRecommendations, ...literatureRecommendations];

    const { backgroundIllustration, accentColor } = RecommendationsSectionThemes[SectionCodes.sport];

    const getGTOTitle = (): string => {
      const { birthdate } = currentStudent;

      if (!birthdate) return '';

      const studentAge = getAge(birthdate);
      if (studentAge < 14) return 'Попроси родителей записать тебя на сдачу ГТО!';
      else return `Сдай на ${studentAge <= 15 ? '4' : '5'} ступень ГТО и получи дополнительные баллы к ЕГЭ`;
    };

    if (!studentRecommendations.content?.visitGTO) {
      recommendationEntities.unshift({
        title: getGTOTitle(),
        link: 'https://www.gto.ru/',
        isGTOCard: true,
        sectionCode: SectionCodes.sport,
        buttonTitle: 'Подробнее',
        backgroundIllustration,
        accentColor,
      });
    }

    shuffle(recommendationEntities);

    return {
      loading: studentRecommendations.loading,
      content: recommendationEntities,
    };
  },
);

export default profileRecommendationsSelector;
