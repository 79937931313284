/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';
import genericReducers from '../genericReducer';
import { IReducers } from '../index';

import { GET_THEATRES } from '../../actions/employees';
import { REQUEST, FAILURE, SUCCESS } from '../../actions/utils';

import { ICommonResponse, ILocation } from '../../api/types';

export type TheatresState = ICommonResponse<ILocation>;

const initialState = {} as ICommonResponse<ILocation>;

const theatres: IReducers<TheatresState> = {
  [GET_THEATRES[REQUEST]]: genericReducers().get.request,
  [GET_THEATRES[SUCCESS]]: genericReducers().get.success,
  [GET_THEATRES[FAILURE]]: genericReducers().get.failure
};

export default connectReducers(initialState, theatres);
