/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useContext, useEffect, useMemo } from 'react';

import { connect } from 'react-redux';
import { FormHelperText } from '@mui/material';

import { IDictionaryItem, IChildInfo } from '../../../../../api/types';
import { OtherOrganizationCode, SectionCodes, WithoutOrganization, WithoutOrganizationCode } from '../../../../../const';

import { IRootState } from '../../../../../reducers';
import { SecondaryBlockContainer, useSecondaryBlockControllers } from '../common';

import { BaseInputLabel, FormControl, Input, Textarea } from 'portfolio3/ui-kit';
import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';
import { SearchSelect } from 'portfolio3/ui-kit/selects';
import { IController } from 'portfolio3/ui-kit/types';
import SportAffilationDatesCombinedControl from './SportAffilationDatesCombinedControl';
import { commonAcceptFileTypes } from 'portfolio3/features/dataEntryForm';

interface ISportAffiliationSecondaryBlockProps {
  sportTrainingStageOptions: IDictionaryItem[]
  currentStudent: IChildInfo;
  nameInputLabel: string;
  nameInputPlaceholder: string;
  name?: string;
  children?: JSX.Element;
}

const SportAffiliationSecondaryBlock: React.FC<ISportAffiliationSecondaryBlockProps> = ({
  currentStudent,
  nameInputLabel,
  nameInputPlaceholder,
  name,
  sportTrainingStageOptions,
  children
}) => {
  const { studentType, inputRenderMode, inputSize, formData, formErrors, onChangeFormData, onChangeFormErrors } = useContext(LocalDataEntryFormContext);

  const { fileController, linkedObjects, linkedObjectController } = useSecondaryBlockControllers({
    formData,
    onChangeFormData,
    onChangeFormErrors
  });

  useEffect(() => {
    onChangeFormData((prevstate) => ({
      ...prevstate,
      typeCode: SectionCodes.sportClubTeam
    }));
  }, []);

  const organizationNameController = useMemo(() => ({
    handleChange: (secondName: string) => {
      onChangeFormData((prevState) => ({
        ...prevState,
        secondName
      }));
      onChangeFormErrors((prevState) => ({
        ...prevState,
        secondNameError: false
      }));
    },
    handleBlur: (name: any) => {
      if (!name) {
        onChangeFormErrors((prevState) => ({
          ...prevState,
          secondNameError: true
        }));
      }
    }
  }), [formData.id]);

  const teamNameController = useMemo(() => ({
    handleChange: (teamName: string) => {
      onChangeFormData((prevState) => ({
        ...prevState,
        teamName
      }));
      if (name?.trim() === WithoutOrganization) {
        onChangeFormErrors((prevState) => ({
          ...prevState,
          teamNameError: false
        }));
      }
    },
    handleBlur: (name: any) => {
      if (!name && name?.trim() === WithoutOrganization) {
        onChangeFormErrors((prevState) => ({
          ...prevState,
          teamNameError: true
        }));
      }
    }
  }), [formData.id]);

  const trainingStageController: IController<string | undefined> = useMemo(() => ({
    handleChange: (trainingStageCode) => {
      onChangeFormData((prevstate) => ({
        ...prevstate,
        trainingStageCode: Number(trainingStageCode),
      }));
    },
  }), [formData.id]);

  const organizationCategoryController = useMemo(() => ({
    handleChange: (organizationCategory : string) => {
      onChangeFormData((prevState) => ({
        ...prevState,
        organizationCategory
      }));
    },
  }), [formData.id]);

  const descriptionController = useMemo(() => ({
    handleChange: (description: string) => {
      onChangeFormData((prevstate) => ({
        ...prevstate,
        description
      }));
    }
  }), [formData.id]);

  const addressController = useMemo(() => ({
    handleChange: (address: string) => {
      onChangeFormData((prevState) => ({
        ...prevState,
        address
      }));
    }
  }), [formData.id]);

  const statusController = useMemo(() => ({
    handleChange: (status: string) => {
      onChangeFormData((prevState) => ({
        ...prevState,
        status
      }));
    }
  }), [formData.id]);

  const mainDatesControl = (
    <SportAffilationDatesCombinedControl
      startDateField="startDate"
      endDateField="endDate"
      errorField="dateError"
      isFirstFieldRequired
      startLabel="Дата зачисления"
      endLabel="Дата отчисления"
      errorDescription="Дата зачисления не может быть позже даты отчисления"
    />
  );

  const teamDatesControl = (
    <SportAffilationDatesCombinedControl
      startDateField="teamStartDate"
      endDateField="teamEndDate"
      errorField="teamStartDateError"
      isFirstFieldRequired={false}
      startLabel="Дата вступления в состав"
      endLabel="Дата выхода из состава"
      errorDescription="Дата вступления в состав не может быть позже даты выхода"
    />
  );

  const medicalDatesControl = (
    <SportAffilationDatesCombinedControl
      startDateField="medicalClearanceFromDate"
      endDateField="medicalClearanceToDate"
      errorField="stageDateError"
      isFirstFieldRequired={false}
      startLabel="Дата начала мед. допуска"
      endLabel="Дата окончания мед. допуска"
      errorDescription="Дата начала мед. допуска не может быть позже даты окончания"
    />
  );

  return (
    <SecondaryBlockContainer>
      <SecondaryBlockContainer.FilesTab
        personId={currentStudent.meshId}
        controller={fileController}
        files={formData.attachment}
        {...commonAcceptFileTypes}
      />
      <SecondaryBlockContainer.LinkedObjectsTab
        studentType={studentType}
        linkedObjectController={linkedObjectController}
        linkedObjects={linkedObjects}
        formData={formData}
      />

      <SecondaryBlockContainer.GeneralTab>
        {formData.name === OtherOrganizationCode && (
          <FormControl
            required
            renderMode={inputRenderMode}
            inputSize={inputSize}
            label={<BaseInputLabel>{nameInputLabel}</BaseInputLabel>}
            error={formErrors.secondNameError}
            helper={formErrors.secondNameError && <FormHelperText>Введите название спортивной организации</FormHelperText>}
            control={
              <Input
                renderMode={inputRenderMode}
                size={inputSize}
                placeholder={nameInputPlaceholder}
                value={formData.secondName}
                controller={organizationNameController}
              />
            }
          />
        )}
        {children}
        {formData.name !== WithoutOrganizationCode && mainDatesControl}

        <FormControl
          required={formData.name === WithoutOrganizationCode}
          renderMode={inputRenderMode}
          inputSize={inputSize}
          label={<BaseInputLabel>Название команды</BaseInputLabel>}
          error={formErrors.teamNameError}
          helper={formErrors.teamNameError && <FormHelperText>Введите название команды</FormHelperText>}
          control={
            <Input
              renderMode={inputRenderMode}
              size={inputSize}
              placeholder="Введите название команды..."
              value={formData.teamName}
              controller={teamNameController}
            />
          }
        />

        {teamDatesControl}

        {formData.name !== WithoutOrganizationCode && (
          <>
            <FormControl
              renderMode={inputRenderMode}
              inputSize={inputSize}
              label={<BaseInputLabel>Этап спортивной подготовки</BaseInputLabel>}
              control={
                <SearchSelect
                  inputRenderMode={inputRenderMode}
                  inputSize={inputSize}
                  placeholder="Выберите этап спортивной подготовки"
                  value={formData.trainingStageCode}
                  options={sportTrainingStageOptions}
                  controller={trainingStageController}
                  strictMaxWidth
                />
              }
            />

            {medicalDatesControl}

            <FormControl
              renderMode={inputRenderMode}
              inputSize={inputSize}
              label={<BaseInputLabel>Категория организации</BaseInputLabel>}
              control={
                <Input
                  renderMode={inputRenderMode}
                  size={inputSize}
                  placeholder="Введите категорию спортивной организации..."
                  value={formData.organizationCategory}
                  controller={organizationCategoryController}
                />
              }
            />

            <FormControl
              sx={{ width: '100%' }}
              renderMode={inputRenderMode}
              inputSize={inputSize}
              label={<BaseInputLabel>Описание организации</BaseInputLabel>}
              control={
                <Textarea
                  renderMode={inputRenderMode}
                  size={inputSize}
                  placeholder="Любое описание или примечание..."
                  value={formData.description}
                  controller={descriptionController}
                />
              }
            />

            <FormControl
              renderMode={inputRenderMode}
              inputSize={inputSize}
              label={<BaseInputLabel>Адрес организации</BaseInputLabel>}
              control={
                <Input
                  renderMode={inputRenderMode}
                  size={inputSize}
                  placeholder="Введите адрес спортивной организации..."
                  value={formData.address}
                  controller={addressController}
                />
              }
            />
          </>
        )}

        <FormControl
          renderMode={inputRenderMode}
          inputSize={inputSize}
          label={<BaseInputLabel>Особый статус участника объединения</BaseInputLabel>}
          control={
            <Input
              renderMode={inputRenderMode}
              size={inputSize}
              placeholder="Введите особый статус участника объединения (если есть)..."
              value={formData.status}
              controller={statusController}
            />
          }
        />
      </SecondaryBlockContainer.GeneralTab>
    </SecondaryBlockContainer>
  );
};

export default connect(
  (state: IRootState) => ({
    currentStudent: state.currentStudent
  })
)(SportAffiliationSecondaryBlock);

