import { createSelector } from 'reselect';

import { defaultVisibilitySettings } from '../../const';
import { IRootState } from '../../reducers';
import { IViewVisibilitySetting } from '../../types';
import { getMappedViewSettings } from './utils';

const localVisibilitySettingsSelector = createSelector(
  [(state: IRootState) => state.localVisibilitySettings, (state: IRootState) => state.adminSettings],
  (localVisibilitySettings, adminSettings): IViewVisibilitySetting[] => {
    if (adminSettings.loading || !localVisibilitySettings.sections || !adminSettings.content?.sections) {
      return [];
    }

    const settings = getMappedViewSettings(
      defaultVisibilitySettings,
      localVisibilitySettings.sections,
      adminSettings.content.sections,
    );
    return settings;
  },
);

export default localVisibilitySettingsSelector;
