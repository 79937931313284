import { FC } from 'react';

import { Box } from '@mui/material';
import { numberToPixelsString } from 'utils';

interface IPdfIconImageProps {
  size: number;
  svgUrl: string;
}

const PdfIconImage: FC<IPdfIconImageProps> = ({ size, svgUrl }) => {
  const sizePx = numberToPixelsString(size);

  return <Box component="img" src={svgUrl} sx={{ width: sizePx, height: sizePx }} />;
};

export default PdfIconImage;
