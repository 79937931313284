import { FC, PropsWithChildren } from 'react';

import { Box } from '@mui/material';
import { SxStyles } from 'types';
import { mergeSx } from 'utils';

interface ILinkButtonProps {
  link: string;
  sx?: SxStyles;
}

const LinkContainer: FC<PropsWithChildren<ILinkButtonProps>> = ({ link, sx, children }) => {
  return (
    <Box
      component="a"
      href={link ?? ''}
      rel="noreferrer"
      target="_blank"
      sx={mergeSx({ display: 'inline-block', textDecoration: 'none' }, sx)}
      tabIndex={-1}
    >
      {children}
    </Box>
  );
};

export default LinkContainer;
