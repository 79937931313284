import React, { useMemo, useState } from 'react';

import { Typography } from '@mui/material';
import { useSortState } from 'hooks';
import Table from 'portfolio3/components/common/Table';
import { TableFooterPagination } from 'portfolio3/components/tableRecipes';
import { sortTableByField } from 'utils';

import { IActiveShareLink } from '../../types';
import { formatActiveLinkDate, formatActiveLinkEndDate } from '../../utils';
import LinkActionsButton from '../ActionsButton';

const initialFilters: IFilters = {
  page: 0,
  limit: 10,
  sort: {
    creationDate: undefined,
    startDate: undefined,
    expireDays: undefined,
  },
};

interface IFilters {
  page: number;
  limit: number;
  sort: {
    creationDate: SortDirection;
    startDate: SortDirection;
    expireDays: SortDirection;
  };
}

interface IActiveLinksTableProps {
  links: IActiveShareLink[];
}

type SortDirection = 'asc' | 'desc' | undefined;

const ActiveLinksTable: React.FC<IActiveLinksTableProps> = ({ links }) => {
  const [filters, setFilters] = useState<IFilters>(initialFilters);

  const { handleSort } = useSortState(setFilters);

  const { page, limit, sort } = filters;

  const sortedLinks = useMemo(() => {
    if (!links) return [];

    const sortEntries = Object.entries(sort);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const activeEntry = sortEntries.find(([_, direction]) => direction !== undefined);

    if (!activeEntry) return links;

    const [key, direction] = activeEntry;
    const sortFunc = sortTableByField(key, direction);
    return [...links].sort(sortFunc);
  }, [links, sort]);

  const slicedSharedLinks = sortedLinks.slice(page * limit, page * limit + limit);
  const totalPages = Math.ceil(sortedLinks.length / limit);

  const resetSortFilters = (keepKey: keyof typeof sort) => {
    setFilters((prevState) => ({ ...prevState, sort: { ...initialFilters.sort, [keepKey]: prevState.sort[keepKey] } }));
  };

  const creationDateSortType = sort.creationDate;
  const startDateSortType = sort.startDate;
  const expireDaysSortType = sort.expireDays;
  const handleSortCreationDate = () => {
    handleSort('creationDate');
    resetSortFilters('creationDate');
  };
  const handleSortStartDate = () => {
    handleSort('startDate');
    resetSortFilters('startDate');
  };
  const handleSortExpireDays = () => {
    handleSort('expireDays');
    resetSortFilters('expireDays');
  };

  return (
    <Table
      tableProps={{ sx: { gridTemplateColumns: 'minmax(160px, 1fr) minmax(160px, 2fr) minmax(160px, 3fr) auto' } }}
    >
      <Table.THead>
        <Table.Row>
          <Table.HeadCell withSorting sortType={creationDateSortType} boxProps={{ onClick: handleSortCreationDate }}>
            Дата создания
          </Table.HeadCell>
          <Table.HeadCell withSorting sortType={startDateSortType} boxProps={{ onClick: handleSortStartDate }}>
            Дата начала дейсвтия
          </Table.HeadCell>
          <Table.HeadCell withSorting sortType={expireDaysSortType} boxProps={{ onClick: handleSortExpireDays }}>
            Срок действия
          </Table.HeadCell>
          <Table.HeadCell />
        </Table.Row>
      </Table.THead>
      <Table.TBody>
        {slicedSharedLinks?.map((link) => (
          <Table.Row key={link.id}>
            <Table.DataCell>
              <Typography variant="Paragraph MD/Regular">{formatActiveLinkDate(link.creationDate)}</Typography>
            </Table.DataCell>
            <Table.DataCell>
              <Typography variant="Paragraph MD/Regular">{formatActiveLinkDate(link.startDate)}</Typography>
            </Table.DataCell>
            <Table.DataCell>
              <Typography variant="Paragraph MD/Regular">
                {formatActiveLinkEndDate(link.endDate, link.expireDays)}
              </Typography>
            </Table.DataCell>
            <Table.DataCell density={8}>
              <LinkActionsButton
                id={link.id}
                linkUrl={link.url}
                linkQrCode={link.qrCode}
                sx={{ width: '32px', height: '32px', backgroundColor: 'transparent' }}
              />
            </Table.DataCell>
          </Table.Row>
        ))}
      </Table.TBody>
      <Table.TFoot>
        <TableFooterPagination
          currentFilters={filters}
          setCurrentFilters={setFilters}
          totalPages={totalPages}
          renderPageModification={(page) => page + 1}
          setPageModification={(page) => page - 1}
        />
      </Table.TFoot>
    </Table>
  );
};

export default ActiveLinksTable;
