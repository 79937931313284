import { useSelector } from 'react-redux';

import { SectionCodes, WithoutOrganizationCode } from 'const';
import {
  IEntryFormEntityProperties,
  IPortfolioEntryFormData,
  MappedEventData,
} from 'portfolio3/features/dataEntryForm';
import { IRootState } from 'reducers';
import { getCategoryProperties, isDefined } from 'utils';

import { getCommonEventProperties, getCommonOccupationProperties, getCommonRewardProperties } from './utils';

interface IProps {
  formData: IPortfolioEntryFormData;
  mappedEventData: MappedEventData;
}

const useSportEntityProperties = ({ formData, mappedEventData }: IProps): IEntryFormEntityProperties | null => {
  const portfolioSections = useSelector((state: IRootState) => state.sectionRef.content);
  const sportRewards = useSelector((state: IRootState) => state.sportRewardKinds.content);
  const sportClubs = useSelector((state: IRootState) => state.sportClubs.content);

  const categoryCode = formData.categoryCode;
  const dataKind = formData.dataKind;

  const categoryProperties = categoryCode ? getCategoryProperties(categoryCode) : null;

  let name: string | undefined;
  let typeName: string | undefined;
  let attributes: string[] | undefined;

  if (dataKind === SectionCodes.sportAffilation) {
    const defaultName = 'Клуб/команда';
    const affilationName =
      formData.name === WithoutOrganizationCode
        ? defaultName
        : sportClubs.find((club) => club.code === formData.name)?.value ?? defaultName;
    const affilationType =
      formData?.name !== WithoutOrganizationCode
        ? portfolioSections.find((section) => section.code === SectionCodes.sportClubTeam)?.value
        : undefined;

    name = affilationName;
    typeName = 'Принадлежность';
    attributes = [affilationType].filter(isDefined);
  }

  if (dataKind === SectionCodes.sportOccupation) {
    const props = getCommonOccupationProperties(portfolioSections, formData);
    name = props.name;
    typeName = props.typeName;
    attributes = props.attributes;
  }

  if (dataKind === SectionCodes.sportEvent) {
    const props = getCommonEventProperties(mappedEventData);
    name = props.name ?? 'Другое';
    typeName = props.typeName;
    attributes = props.attributes;
  }

  if (dataKind === SectionCodes.sportReward) {
    const props = getCommonRewardProperties(sportRewards, portfolioSections, formData);
    name = props.name;
    typeName = props.typeName;
    attributes = props.attributes;
  }

  if (categoryCode !== SectionCodes.sport) return null;

  return {
    entityInfo: {
      name,
      categoryName: categoryProperties?.name ?? '',
      typeName: typeName ?? '',
      attributes,
    },
  };
};

export default useSportEntityProperties;
