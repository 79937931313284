import { FC } from 'react';

import { Stack, Typography } from '@mui/material';

import { content } from './styles';

interface IConclusionsProps {
  title: string;
  text: string;
}

// const Button = styled('button')<IStyledButtonProps>(() => ({
//   fontSize: '14px',
//   lineHeight: '20px',
//   fontWeight: 600,
//   color: getAccentColor('indigo', '200'),
//   width: 'max-content',
//   padding: 0,
//   border: 'none',
//   backgroundColor: 'unset',

//   '&:hover': {
//     cursor: 'pointer',
//   },
// }));

const Conclusions: FC<IConclusionsProps> = ({ title, text }) => {
  // const [isMaxHeightContent, setIsMaxHeightContent] = useState(false);
  // const handleSetMaxHeight = () => setIsMaxHeightContent(true);

  return (
    <Stack gap="8px">
      <Typography variant="Paragraph MD/Semi Bold">{title}</Typography>
      <Typography variant="Paragraph MD/Regular" sx={content}>
        {text}
      </Typography>
      {/* TODO вернуть когда придет время и сделать нормальное скрытие раскрытие тексти,
          и скрывать кнопку если текст раскрыт полностью */}
      {/* <Button onClick={handleSetMaxHeight}>Подробнее</Button> */}
    </Stack>
  );
};

export default Conclusions;
