import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

/**
 * Позволяет выполнить определенную логику один раз после монтирования компонента после выполнения определенных условий.
 * Логику необходимо разместить в деструкторе эффекта.
 *
 * Пример:
 * useSingleEffect(() => {
 *   if (data === null || isDataLoading) return;
 *   return () => {
 *     console.log("Данные получены");
 *   }
 * }, [isDataLoading, data]);
 *
 * @param effect Эффект колбек с деструктором
 * @param deps Массив зависимостей
 */
const useSingleEffect = (effect: EffectCallback, deps?: DependencyList) => {
  const hasDone = useRef(false);

  useEffect(() => {
    if (hasDone.current) return;

    const result = effect();

    if (typeof result === 'function') {
      hasDone.current = true;
      result();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useSingleEffect;
