import { IPortfolioDataEntryFormErrors } from 'portfolio3/features/dataEntryForm';
import { isDefined } from 'utils';

import { RequiredProperties } from './types';

export interface IValidateAlternative<T> {
  targetProp: keyof T;
  excludeValue?: unknown;
  orSomeProps?: (keyof T)[];
}

const isValid = (value: unknown) => isDefined(value) && Boolean(value);

export const validateProperties = (values: unknown[]): boolean => {
  return values.every((value) => Boolean(value));
};

export const validateEmptyString = (value: unknown): boolean => {
  if (typeof value !== 'string') return true;
  return value.trim().length !== 0;
};

/**
 * Проверяет объект с ошибками формы на наличие активных ошибок
 * @param formErrors объект с ошибками формы
 * @returns true если форма корректна и может быть отправлена, иначе false
 */
export const validateFormErrorsObject = (formErrors: IPortfolioDataEntryFormErrors) => {
  const result: boolean[] = [];

  // any указан для избежания ошибок в типах - принимает любой объект
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function checkObjectValues(obj: any) {
    for (const key in obj) {
      const value = obj[key];
      if (typeof value === 'boolean') {
        result.push(value);
      } else if (isDefined(value) && typeof value === 'object') {
        checkObjectValues(value);
      }
    }
  }

  checkObjectValues(formErrors);
  return result.length === 0 ? true : result.some(Boolean);
};

export const validateObject = <T, RequiredProps extends keyof T>(
  object: T,
  props: readonly RequiredProps[],
): object is T & Required<Pick<T, RequiredProps>> => {
  return props.every((prop) => isValid(object[prop]));
};

export const validatorFactory = <FormData, RequiredProps extends keyof FormData>(
  requiredProps: readonly RequiredProps[],
) => {
  return (formData: FormData): formData is RequiredProperties<FormData, RequiredProps> => {
    return validateObject<FormData, RequiredProps>(formData, requiredProps);
  };
};

export const validateObjectAlternative = <T, RequiredProp extends keyof T>(
  object: T,
  props: readonly RequiredProp[],
  alternatives: IValidateAlternative<T>[],
): boolean => {
  const orSome = (object: T, alternative: IValidateAlternative<T>): boolean => {
    if (!alternative.orSomeProps) return true;
    return alternative.orSomeProps.some((prop) => isValid(object[prop]));
  };

  return props.every((prop) => {
    const alternative = alternatives.find((alt) => alt.targetProp === prop);

    const baseResult = isValid(object[prop]) && object[prop] !== alternative?.excludeValue;
    const orSomeResult = alternative ? orSome(object, alternative) : false;

    return baseResult || orSomeResult;
  });
};

export const alternativeValidatorFactory = <FormData, RequiredProps extends keyof FormData>(
  requiredProps: readonly RequiredProps[],
  alternatives: IValidateAlternative<FormData>[],
) => {
  return (formData: FormData): formData is RequiredProperties<FormData, RequiredProps> => {
    return validateObjectAlternative<FormData, RequiredProps>(formData, requiredProps, alternatives);
  };
};
