import {
  FC,
  FocusEvent,
  HTMLAttributes,
  KeyboardEvent,
  MouseEventHandler,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import { useAutocomplete } from '@mui/lab';
import { AutocompleteRenderOptionState, Box, ListItem, Popper, useFormControl } from '@mui/material';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { IDictionaryItem } from 'api/types';
import { MenuEmptyResult, TokenFieldToken } from 'portfolio3/ui-kit';
import { FakeInputBox } from 'portfolio3/ui-kit/inputs';
import { CommonUiKitSize, InputRenderMode, TokenFieldController } from 'portfolio3/ui-kit/types';

import { baseOptionStyles } from '../../styles';
import * as styles from './styles';

interface IStretchyTokenFieldProps {
  size: CommonUiKitSize;
  inputRenderMode: InputRenderMode;
  controller: TokenFieldController;
  options: IDictionaryItem[];
  value: number[];
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
}

const defaultRenderOption = (
  props: HTMLAttributes<HTMLLIElement>,
  option: IDictionaryItem,
  state: AutocompleteRenderOptionState,
): ReactNode => {
  return (
    <ListItem {...props} sx={baseOptionStyles(state.selected)} data-option-code={option.code}>
      {option.value}
    </ListItem>
  );
};

const StretchyTokenField: FC<IStretchyTokenFieldProps> = ({
  size,
  inputRenderMode,
  controller,
  options,
  value,
  placeholder,
  disabled,
  error,
}) => {
  const valueOptions = useMemo(() => options.filter((option) => value.includes(option.code)), [options, value]);
  const availableOptions = useMemo(() => options.filter((option) => !value.includes(option.code)), [options, value]);

  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    popupOpen,
    inputValue,
    setAnchorEl,
  } = useAutocomplete({
    id: 'autocomplete',
    multiple: true,
    getOptionLabel: (option) => option.value,
    options: availableOptions,
    value: valueOptions,
  });

  const formControl = useFormControl();
  const rootRef = useRef<HTMLDivElement>(null);
  const popperRef = useRef<HTMLDivElement>(null);

  const { onFocus, onBlur, ...inputProps } = getInputProps();
  const listboxProps = getListboxProps();

  const isDisabled = formControl?.disabled ?? disabled;
  const isError = formControl?.error ?? error;

  const isFloating = inputRenderMode === 'floating';
  const isPlaceholderHidden = Boolean(inputValue) || formControl?.filled || (isFloating && !formControl?.focused);

  const handleChangeValue = (option: IDictionaryItem) => {
    const modifiedOptions = valueOptions.filter((option2) => option2.code !== option.code);
    const isSameLength = modifiedOptions.length === value.length;

    if (isSameLength) {
      modifiedOptions.push(option);
    }

    controller.handleChange(modifiedOptions);
  };

  const handleInputFocus = (event: FocusEvent<HTMLInputElement, Element>) => {
    onFocus?.(event);
    formControl?.onFocus();
  };

  const handleInputBlur = (event: FocusEvent<HTMLInputElement, Element>) => {
    onBlur?.(event);
    formControl?.onBlur();
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // выбор элемента списка через enter
    if (event.key === 'Enter' && popperRef.current) {
      const listboxOptions = popperRef.current.querySelector('[role="listbox"]')?.children || [];

      const focusedItem = Array.from(listboxOptions).find((element) =>
        element.classList.contains(autocompleteClasses.focused),
      ) as HTMLElement;

      // необходимо наличие data-option-code на элементе списка
      const optionCode = Number(focusedItem?.dataset?.optionCode);
      const option = options.find((option) => option.code === optionCode);

      if (option) {
        handleChangeValue(option);
      }
    }

    // удаление последнего значения
    if (event.key === 'Backspace' && value.length > 0 && (event.target as HTMLInputElement)?.value?.length === 0) {
      const lastValueCode = value[value.length - 1];
      const option = options.find((option) => option.code === lastValueCode);

      if (option) {
        handleChangeValue(option);
      }
    }
  };

  useEffect(() => {
    if (value.length > 0) {
      formControl?.onFilled();
    } else {
      formControl?.onEmpty();
    }
  }, [formControl, value]);

  const renderedOptions = (groupedOptions as IDictionaryItem[]).map((option, index) => {
    const isSelected = value.some((code) => code === option.code);
    const state: AutocompleteRenderOptionState = {
      inputValue,
      selected: isSelected,
    };

    const { onClick, ...optionProps } = getOptionProps({ option, index });

    const handleClick: MouseEventHandler<HTMLLIElement> = (event) => {
      onClick?.(event);
      handleChangeValue(option);
    };

    return defaultRenderOption({ ...optionProps, onClick: handleClick }, option, state);
  });

  return (
    <div className="stretchy-token-field">
      <Box ref={rootRef} {...getRootProps()}>
        <FakeInputBox
          size={size}
          renderMode={inputRenderMode}
          ref={setAnchorEl}
          disabled={isDisabled}
          error={isError}
          onBlur={() => controller.handleBlur?.(valueOptions)}
        >
          {/* render value */}
          <Box sx={styles.renderValue(size)}>
            <Box sx={styles.placeholder(Boolean(!isPlaceholderHidden))}>{placeholder}</Box>

            {value.map((code, index) => {
              const { onDelete, ...tagProps } = getTagProps({ index });
              const currentOption = options.find((option) => option.code === code);

              if (!currentOption) return null;

              const handleDelete: MouseEventHandler<HTMLButtonElement> = (event) => {
                onDelete(event);
                handleChangeValue(currentOption);
              };

              return (
                <TokenFieldToken
                  label={currentOption.value}
                  size={size}
                  disabled={isDisabled}
                  {...tagProps}
                  onDelete={handleDelete}
                  key={currentOption.code}
                />
              );
            })}
            {/* input */}
            <Box
              component="input"
              sx={styles.input(size)}
              {...inputProps}
              onKeyDownCapture={handleKeyDown}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              disabled={isDisabled}
            />
          </Box>
        </FakeInputBox>
      </Box>
      <Popper
        sx={{ zIndex: 10000, width: rootRef.current ? rootRef.current.clientWidth : null }}
        open={popupOpen}
        anchorEl={rootRef.current}
        role="presentation"
        ref={popperRef}
        disablePortal
      >
        {groupedOptions.length === 0 && (
          <Box sx={styles.listbox}>
            <MenuEmptyResult />
          </Box>
        )}
        {groupedOptions.length > 0 && (
          <Box
            sx={styles.listbox}
            component="div"
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            {...(listboxProps as any)}
          >
            {renderedOptions}
          </Box>
        )}
      </Popper>
    </div>
  );
};

export default StretchyTokenField;
