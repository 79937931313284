import { SxStyles } from 'types';

export const chart: SxStyles = (theme) => ({
  width: '70px',
  height: '70px',

  [theme.breakpoints.down('commonSm')]: {
    width: '60px',
    height: '60px',
  },
});
