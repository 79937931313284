import { FC } from 'react';

import { Typography, useMediaQuery } from '@mui/material';
import { getAccentColor } from 'portfolio3/styles';
import { commonTheme } from 'portfolio3/styles/theme';
import { getFocusStateStyles } from 'portfolio3/ui-kit/utils';
import { SxStyles } from 'types';

import { IconChevronLargeDown, IconChevronLargeUp } from '../../../icons';

interface ISpoilerButtonProps {
  isExpanded: boolean;
  expandedText: string;
  collapsedText: string;
  onClick: () => void;
}

const styles: SxStyles = () => ({
  padding: 0,
  background: 'none',
  border: 'none',

  display: 'flex',
  gap: '8px',
  alignItems: 'center',

  color: getAccentColor('indigo', '200'),
  cursor: 'pointer',

  '& svg': {
    width: '20px',
    height: '20px',
  },

  ':focus-visible': {
    ...getFocusStateStyles(),
  },
});

const SpoilerButton: FC<ISpoilerButtonProps> = ({ isExpanded, expandedText, collapsedText, onClick }) => {
  const isSmallScreen = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const buttonText = isExpanded ? expandedText : collapsedText;
  const ButtonIcon = isExpanded ? IconChevronLargeUp : IconChevronLargeDown;

  return (
    <Typography
      sx={styles}
      component="button"
      variant={isSmallScreen ? 'Paragraph MD/Semi Bold' : 'Paragraph MD/Medium'}
      onClick={onClick}
    >
      {buttonText}
      <ButtonIcon className="spoiler-button__icon" />
    </Typography>
  );
};

export default SpoilerButton;
