import {
  ThemeBackgroundKeys,
  ThemeBackgroundValues,
  ThemeCoverKeys,
  ThemeCoverValues,
  ThemePatternKeys,
  ThemePatternValues,
} from 'const';

export const getBackgroundValue = (key: string | undefined) => {
  return ThemeBackgroundValues[key as ThemeBackgroundKeys] ?? ThemeBackgroundValues.neutral;
};

export const getCoverValue = (key: string | undefined) => {
  return ThemeCoverValues[key as ThemeCoverKeys] ?? ThemeCoverValues.abstract;
};

export const getPatternValue = (key: string | undefined) => {
  return ThemePatternValues[key as ThemePatternKeys] ?? ThemePatternValues.cats1;
};
