export const getMissingBallPluralization = (ball: number) => {
  const textForms = ['балла', 'балла', 'баллов'];
  const remainderDiv100 = Math.abs(+ball) % 100;
  const remainderDiv110 = remainderDiv100 % 10;

  if (remainderDiv100 > 10 && remainderDiv100 < 20) return textForms[2];
  if (remainderDiv110 > 1 && remainderDiv110 < 5) return textForms[1];
  if (remainderDiv110 === 1) return textForms[0];

  return textForms[2];
};
