/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import SubjectTopics from './subjectTopics';
import SubjectTopicsMobile from './subjectTopics/subjectTopicsMobile';
import SubjectOverview from './subjectOverview';
import { resultsMath } from 'images';
import useClientWidth from 'hooks/useClientWidth';
import { IPerformanceSubjectTopicInfo } from '../../types';

import './index.scss';

interface ISubjectDetailsProps {
  subject: string;
  subjectResult: number;
  subjectTopic?: IPerformanceSubjectTopicInfo;
}

const SubjectDetails: React.FC<ISubjectDetailsProps> = ({
  subject,
  subjectResult,
  subjectTopic
}) => {
  const clientWidth = useClientWidth();
  return (
    <div className="subject-details">
      <div className="subject-details__overview">
        <SubjectOverview
          subject={subject}
          backgroundImage={resultsMath}
          totalTopics={subjectTopic?.totalTopics || 0}
          passedTopics={subjectTopic?.doneTopics || 0}
          topicsDonePercent={subjectTopic?.percentDoneTopics || 0}
          subjectResult={subjectResult}
        />
      </div>
      <div className="subject-details__topics">
        {clientWidth > 1023 ? <SubjectTopics topics={subjectTopic?.topics || []} /> : <SubjectTopicsMobile topics={subjectTopic?.topics || []} /> }
      </div>
    </div>
  );
};

export default SubjectDetails;
