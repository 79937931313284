/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconCornerRight: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M19.781 10.3756L15.781 5.37557C15.434 4.94457 14.806 4.87357 14.375 5.21957C13.944 5.56357 13.874 6.19357 14.219 6.62457L16.92 10.0006H8C6.345 10.0006 5 11.3456 5 13.0006V18.0006C5 18.5526 5.448 19.0006 6 19.0006C6.552 19.0006 7 18.5526 7 18.0006V13.0006C7 12.4486 7.448 12.0006 8 12.0006H16.92L14.219 15.3756C13.874 15.8066 13.944 16.4366 14.375 16.7806C14.559 16.9286 14.78 17.0006 14.999 17.0006C15.293 17.0006 15.583 16.8716 15.781 16.6246L19.781 11.6246C20.073 11.2596 20.073 10.7406 19.781 10.3756Z" fill="#7B819B" />
  </SvgIcon>

);

export default IconCornerRight;
