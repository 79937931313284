import { FC, useEffect, useState } from 'react';

import { Stack, Typography } from '@mui/material';
import { IGeneralRating, IIndependentDiagnosticGeneralRating } from 'api/types';
import UnderlinedTabs from 'portfolio3/components/common/UnderlinedTabs';
import { DrawerHeader } from 'portfolio3/components/drawers';
import { emitYMEvent } from 'portfolio3/features/yandexMetrika';
import { Button } from 'portfolio3/ui-kit/button';
import { Drawer, IDrawerBase } from 'portfolio3/ui-kit/Drawer';

import { MAX_VISIBLE_RATING } from '../../const';
import { useAllRating } from '../../hooks/useAllRating';
import { createMapFromRating } from '../../utils';
import RatingChart from '../RatingChart';
import * as styles from './style';

interface IShowAllRatingProps {
  tabValue: number;
  ratingObject: IIndependentDiagnosticGeneralRating;
}

const ShowAllRating: FC<IShowAllRatingProps> = ({ tabValue, ratingObject }) => {
  const [isOpenAllRating, setIsOpenAllRating] = useState(false);
  const [currentTab, setCurrentTab] = useState(tabValue);

  const chartRows = useAllRating(ratingObject, currentTab);
  const tabs = getTabs(ratingObject);

  useEffect(() => {
    setCurrentTab(tabValue);
  }, [tabValue]);

  const handleOpenAllRating = () => {
    setIsOpenAllRating(true);
    emitYMEvent({ type: 'diagnosticRatingDisclosure' });
  };
  const handleCloseAllRating = () => setIsOpenAllRating(false);

  const handleChangeTab = (tab: number) => setCurrentTab(tab);

  const getStudentPositionByCurrentTab = () => {
    const tab = tabs.find((tab) => tab.value === currentTab);
    if (!tab) return '';

    const position = tab.studentPlace;
    const place = tab.label.replace('В', '');
    const totalPositions = chartRows.length;

    return `Ты занимаешь ${position} место из ${totalPositions} в ${place}`;
  };

  function getTabs(ratingObject: IIndependentDiagnosticGeneralRating) {
    const ratingTabsMap = createMapFromRating<IGeneralRating>(ratingObject, 'generalRating');
    const locationNamesPool = ['В Москве', 'В твоей школе', 'В твоем классе'];

    return Array.from(ratingTabsMap).map(([tabIndex, rating]) => ({
      value: tabIndex,
      label: locationNamesPool[tabIndex - 1],
      studentPlace: rating.placeGeneralRatingStudent,
      visible: (rating?.allPlaces?.length || 0) > MAX_VISIBLE_RATING,
    }));
  }

  const drawerHeader: IDrawerBase['header'] = (crossButton) => (
    <DrawerHeader
      title={`Рейтинг по предмету ${ratingObject.subjectGeneralRatingName}`}
      accentColor="violet"
      crossButton={crossButton}
      backgroundImage={null}
      removeBottomPadding
    >
      <UnderlinedTabs onChange={handleChangeTab} value={currentTab} sx={styles.headerTabs}>
        {tabs.map(
          (tab) => tab.visible && <UnderlinedTabs.Button key={tab.value} value={tab.value} content={tab.label} />,
        )}
      </UnderlinedTabs>
    </DrawerHeader>
  );

  return (
    <>
      <Button onClick={handleOpenAllRating} sx={styles.showAllAction}>
        Посмотреть весь рейтинг
      </Button>
      <Drawer
        open={isOpenAllRating}
        onClose={handleCloseAllRating}
        swipeableProps={{ onClose: handleCloseAllRating }}
        anchor="right"
        header={drawerHeader}
      >
        <Stack gap={1} px="24px" py="20px">
          <Typography variant="Paragraph LG/Semi Bold">{getStudentPositionByCurrentTab()}</Typography>
          <Typography variant="Paragraph MD/Medium">Средний результат прохождения всех диагностик в %</Typography>
          <RatingChart rows={chartRows} horizontalCaption="" fullWidth />
        </Stack>
      </Drawer>
    </>
  );
};

export default ShowAllRating;
