import { ButtonHTMLAttributes, forwardRef, ForwardRefRenderFunction, ReactNode } from 'react';

import { Box, ButtonProps, useFormControl } from '@mui/material';
import { SxStyles } from 'types';
import { mergeSx } from 'utils';

import { getButtonStyles, getFocusStateStyles } from '../utils';

const styles: SxStyles = {
  ...getButtonStyles(),

  padding: '4px',
  borderRadius: '50%',

  '&:focus-visible': {
    ...getFocusStateStyles(),
  },
};

interface ICommonInputButtonProps {
  icon: ReactNode;
  buttonProps: ButtonProps;
  htmlAttributes?: ButtonHTMLAttributes<HTMLButtonElement>;
}

const CommonInputButton: ForwardRefRenderFunction<HTMLButtonElement, ICommonInputButtonProps> = (
  { icon, buttonProps, htmlAttributes },
  ref,
) => {
  const { disabled, sx, onClick } = buttonProps;

  const formControlState = useFormControl();
  const isDisabled = disabled || formControlState?.disabled;

  return (
    <Box
      component="button"
      {...htmlAttributes}
      {...buttonProps}
      ref={ref}
      onClick={onClick}
      disabled={isDisabled}
      sx={mergeSx(styles, sx)}
    >
      {icon}
    </Box>
  );
};

export default forwardRef(CommonInputButton);
