/* eslint-disable max-len */
import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconSectionSport: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.997 9.811A8.998 8.998 0 0 0 10 1.001a8.684 8.684 0 0 0-2.43.337A8.999 8.999 0 0 0 1 9.676V10a8.894 8.894 0 0 0 1.935 5.556 8.998 8.998 0 0 0 3.892 2.865c2.061.79 4.342.79 6.404 0a9.043 9.043 0 0 0 5.766-8.421 1.584 1.584 0 0 0 0-.188Zm-1.77 1.283a7.38 7.38 0 0 1-.75 2.354.75.75 0 0 1-.63.36h-2.466a.749.749 0 0 0-.683.495l-.75 2.37a.75.75 0 0 1-.525.487 7.3 7.3 0 0 1-2.834-.008.69.69 0 0 1-.525-.472l-.75-2.302a.75.75 0 0 0-.682-.495h-2.4a.697.697 0 0 1-.622-.352 7.244 7.244 0 0 1-.84-2.52.75.75 0 0 1 .292-.667L5.034 8.91a.705.705 0 0 0 .263-.802l-.75-2.392a.697.697 0 0 1 .142-.698A7.244 7.244 0 0 1 7 3.355a.697.697 0 0 1 .712.075L9.61 4.81a.75.75 0 0 0 .84 0l1.904-1.38a.705.705 0 0 1 .705-.083c.891.414 1.691 1 2.355 1.725a.75.75 0 0 1 .142.697l-.795 2.437a.704.704 0 0 0 .263.803l1.934 1.402a.75.75 0 0 1 .263.683h.008Z"
      fill={props.fill || 'currentColor' || '#22B8CF'}
    />
    <path
      d="M10.419 7.299a.697.697 0 0 0-.84 0L7.457 8.836a.75.75 0 0 0-.263.802l.81 2.498a.75.75 0 0 0 .682.495h2.625a.75.75 0 0 0 .682-.495l.81-2.498a.749.749 0 0 0-.262-.802L10.419 7.3Z"
      fill={props.fill || 'currentColor' || '#22B8CF'}
    />
  </SvgIcon>
);

export default IconSectionSport;
