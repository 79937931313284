import { SxStyles } from 'types';

export const menuButtonContainer: SxStyles = {
  marginLeft: 'auto',
};

export const menuButton: SxStyles = {
  padding: 0,
  width: '32px',
  height: '32px',

  border: 0,

  '& svg': {
    width: '24px',
    height: '24px',
  },
};
