import { StudentSectionSettingTypeCodes } from 'const';
import useWidgetVisibility from 'hooks/useWidgetVisibility';

const sportUseWidgetVisibilityCreator = (subsectionTypeCode: string) => {
  const useProfessionWidgetVisibility = () => {
    return useWidgetVisibility({
      sectionTypeCode: StudentSectionSettingTypeCodes.sport,
      subsectionTypeCode,
    });
  };
  return useProfessionWidgetVisibility;
};

const useRewards = sportUseWidgetVisibilityCreator(StudentSectionSettingTypeCodes.sportReward);

const useClubs = sportUseWidgetVisibilityCreator(StudentSectionSettingTypeCodes.sportClub);

const useSections = sportUseWidgetVisibilityCreator(StudentSectionSettingTypeCodes.sportUnit);

const useCompetitions = sportUseWidgetVisibilityCreator(StudentSectionSettingTypeCodes.sportGame);

const useExpeditions = sportUseWidgetVisibilityCreator(StudentSectionSettingTypeCodes.sportHike);

export const sportWidgetVisibilityHooks = { useRewards, useClubs, useSections, useCompetitions, useExpeditions };
