import { IRootState } from 'reducers';
import { createSelector } from 'reselect';
import { isDefined } from 'utils';

import { industryIllustrations } from '../../assets';
import { IndustryCodeIllustration } from '../../const/industryIllustrationMapping';
import { IRecommendedIndustry } from '../types';

const proforientationRecommendedIndustriesSelector = createSelector(
  [(state: IRootState) => state.proforientation],
  (proforientation): IRecommendedIndustry[] => {
    const industruies = proforientation.content?.industry?.data
      ?.map((industryPart): IRecommendedIndustry | null => {
        const { industry, specs, colleges, industryID } = industryPart;

        if (!isDefined(industry)) return null;

        const specialities = specs?.spec?.map((spec) => {
          return {
            code: spec.code ?? '',
            name: spec.name ?? '',
          };
        });

        const collegeNames = colleges?.college?.map((college) => college.name ?? '');

        const illustrationId = Number(industryID) ?? -1;

        return {
          name: industry,
          specialities: specialities ?? [],
          collegeNames: collegeNames ?? [],
          illustration: IndustryCodeIllustration[illustrationId] ?? industryIllustrations.проф_тестирование,
        };
      })
      .filter(isDefined);

    return industruies ?? [];
  },
);

export default proforientationRecommendedIndustriesSelector;
