import React, { useContext } from 'react';
import { connect } from 'react-redux';

import {
  IBaseRefItem,
  ICommonResponse,
  IDictionaryItem,
  IModifiedEventKindsList,
  IModifiedRewardKindsList,
} from 'api/types';
import { SectionCodes } from 'const';
import { IRootState } from 'reducers';
import { createSelector } from 'reselect';
import { getAgeLimitFromArray } from 'utils';

import { LocalDataEntryFormContext } from '../../context/localDataEntryFormContext';
import {
  NameRewardSecondaryBlock,
  ProfessionActivitySecondaryBlock,
  ProfessionGiaWorldSkillsSecondaryBlock,
  ProfessionJobSecondaryBlock,
  ProfessionMetaskillsSecondaryBlock,
} from '../../secondaryBlocks';

interface IProfessionSecondaryBlockProps {
  rewardKindData: IModifiedRewardKindsList;
  professionEventKinds: IModifiedEventKindsList;
  olympiadTypes: ICommonResponse<IDictionaryItem>;
  studentMetaSkills: ICommonResponse<IDictionaryItem>;
  metaskillRef: ICommonResponse<IDictionaryItem>;
  levelBusinessRef: ICommonResponse<IBaseRefItem>;
  salaryRangeRef: ICommonResponse<IBaseRefItem>;
  employmentDocTypeRef: ICommonResponse<IBaseRefItem>;
}

const ProfessionSecondaryBlock: React.FC<IProfessionSecondaryBlockProps> = ({
  rewardKindData,
  professionEventKinds,
  olympiadTypes,
  studentMetaSkills,
  metaskillRef,
  levelBusinessRef,
  salaryRangeRef,
  employmentDocTypeRef,
}) => {
  const { formData } = useContext(LocalDataEntryFormContext);

  const eventKindData = professionEventKinds.content;
  const currentEventKindData = eventKindData.find((activity) => activity.code === formData.name);

  // reward
  const rewardSecondaryResult = rewardKindData.content.find((reward) => reward.code === formData.reward)?.value;

  const eventData = {
    type: 'Мероприятие',
    name: currentEventKindData?.value,
    ageLimit: getAgeLimitFromArray(currentEventKindData?.ageLimits),
    levelEvent: currentEventKindData?.levelEvent
      ? olympiadTypes.content?.find((type) => type.code === currentEventKindData?.levelEvent)?.value
      : undefined,
    levelEventCode: currentEventKindData?.levelEvent,
    organizators: currentEventKindData?.organizators,
  };

  return (
    <>
      {/* Награда Мероприятие */}
      {formData.dataKind === SectionCodes.professionReward &&
        formData.typeCode === SectionCodes.professionRewardEvent &&
        formData.reward && <NameRewardSecondaryBlock result={rewardSecondaryResult} />}

      {/* Мероприятие */}
      {formData.dataKind === SectionCodes.professionEvent && formData.name && (
        <ProfessionActivitySecondaryBlock
          eventData={eventData}
          eventLevelOptions={olympiadTypes?.content || []}
          organizatorLabel="Организатор мероприятия"
          organizatorPlaceholder="Введите название организатора"
        />
      )}

      {/* Экзамен - Демонстрационный экзамен */}
      {formData.dataKind === SectionCodes.professionExam && formData.typeCode === SectionCodes.professionExamGia && (
        <ProfessionGiaWorldSkillsSecondaryBlock />
      )}

      {formData?.typeCode === SectionCodes.professionSpoMetaskills && (
        <ProfessionMetaskillsSecondaryBlock metaskillRef={metaskillRef} studentMetaSkills={studentMetaSkills} />
      )}

      {/* трудоустройство */}
      {formData?.typeCode === SectionCodes.professionSpoJob && (
        <ProfessionJobSecondaryBlock
          levelBusinessRef={levelBusinessRef.content}
          salaryRangeRef={salaryRangeRef.content}
          employmentDocTypeRef={employmentDocTypeRef.content}
        />
      )}
    </>
  );
};

const rewardKindsMapping = createSelector([(state: IRootState) => state.rewardKinds], (rewardKinds) => ({
  content:
    rewardKinds.content?.filter((rewardKind) => rewardKind.categoryCode === SectionCodes.professionRewardEvent) || [],
  loading: rewardKinds.loading,
}));

const professionEventKindsMapping = createSelector([(state: IRootState) => state.eventKinds], (eventKinds) => ({
  content: eventKinds?.content?.filter((eventKind) => eventKind.categoryCode === SectionCodes.professionEvent) || [],
  loading: eventKinds.loading,
}));

export default connect((state: IRootState) => ({
  rewardKindData: rewardKindsMapping(state),
  professionEventKinds: professionEventKindsMapping(state),
  olympiadTypes: state.olympiadTypes,
  studentMetaSkills: state.studentMetaSkills,
  metaskillRef: state.metaSkillRef,
  levelBusinessRef: state.levelBusinessRef,
  salaryRangeRef: state.salaryRangeRef,
  employmentDocTypeRef: state.employmentDocTypeRef,
}))(ProfessionSecondaryBlock);
