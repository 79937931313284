/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { Line } from '@reactchartjs/react-chart.js';

import { Colors } from '../../../style/variables';

interface ILineChartProps {
  incomingData: {
    name: string;
    values: {
        interval?: string;
        value?: number;
    }[];
  }[],
  lineColors: string[],
  width?: number;
  yAxisLabel?: string;
}

const LineChart: React.FC<ILineChartProps> = ({ incomingData, lineColors, width, yAxisLabel }) => {
  const getLineColors = (index: number) => lineColors[index];
  const data = () => ({
    labels: incomingData[0]?.values.map((value) => value.interval),
    datasets: incomingData?.map((performance, index) => ({
      label: performance.name,
      data: performance.values.map((item) => item.value),
      fill: false,
      borderColor: getLineColors(index),
      lineTension: 0,
      pointRadius: 6,
      pointBorderWidth: 2,
      pointBackgroundColor: 'white',
      pointBorderColor: getLineColors(index)
    }))
  });

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    spanGaps: true,
    tooltips: {
      backgroundColor: 'rgba(43, 56, 88, 0.8)',
      titleAlign: 'center',
      bodyAlign: 'center',
      titleFontFamily: 'Lato',
      bodyFontFamily: 'Lato',
      displayColors: false,
      titleSpacing: 4,
      bodySpacing: 4,
      callbacks: {
        title() {},
        label(tooltipItem: any, data:any) {
          const label = data.datasets[tooltipItem.datasetIndex].label || '';
          return label;
        },
        beforeLabel() {},
        afterLabel(tooltipItem: any) {
          return `Ср. балл = ${tooltipItem.yLabel}`;
        }
      }

    },
    plugins: {
      datalabels: {
        display: false
      }
    },
    scales: {
      yAxes: [
        {
          type: 'linear',
          ticks: {
            min: 2,
            max: 5,
            stepSize: 1,
            fontColor: Colors['note-text'],
            fontWeight: 700,
            fontSize: 18
          },
          color: Colors['disable-input']
        }
      ],
      xAxes: [
        {
          gridLines: {
            borderDash: [5, 15],
            color: Colors['disable-input']
          },
          ticks: {
            fontColor: Colors['note-text'],
            fontWeight: 700,
            fontSize: 14
          }
        }
      ]
    }

  };

  return (
    <div className="line" style={{width: width ? width + 'px' : undefined}}>
      {yAxisLabel && <p className="line-chart__axis-y">{yAxisLabel}</p>}
      <Line data={data} options={options} />
    </div>
  );
};

export default LineChart;
