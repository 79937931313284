import { useDispatch } from 'react-redux';

import { dataEntryDrawerActions } from 'actions';
import { IQueryParams } from 'api';
import { IModifiedPersonObject } from 'portfolio3/features/dataEntryForm';

type OpenDataEntryOptions = {
  widgetLabel?: string;
  excludedEventTypes?: number[];
};

const useDataEntryDrawer = () => {
  const dispatch = useDispatch();

  const handleOpenPrimaryDataEntry = (
    initialFormData: Partial<IModifiedPersonObject>,
    options?: OpenDataEntryOptions,
  ) => {
    const { widgetLabel, excludedEventTypes } = options ?? {};

    dispatch(dataEntryDrawerActions.setPrimaryInitialFormData(initialFormData));
    dispatch(dataEntryDrawerActions.setExcludedEventTypes(excludedEventTypes));
    dispatch(dataEntryDrawerActions.setPrimaryOpen(true, widgetLabel ?? null));
  };

  const handleSetDataEntryPersonsObjectsQueryParams = (personsObjectsQueryParams: IQueryParams) => {
    dispatch(dataEntryDrawerActions.setPersonsObjectsQueryParams(personsObjectsQueryParams));
  };

  return {
    handleOpenPrimaryDataEntry,
    handleSetDataEntryPersonsObjectsQueryParams,
  };
};

export default useDataEntryDrawer;
