/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useState } from 'react';

import MobileDragIndicator from '../../../../common/MobileDragIndicator';
import { Button, Checkbox, Drawer, FormControlLabel } from '../../../../../ui-kit';
import { CommonEntityActionProps } from './types';
import { IInterest, IInterestAction, IInterestChild } from '../../types';
import { iconArrowLeft, iconArrowRight } from '../../../../../icons';
import { getInterestCategoryLabel } from '../../../../../utils';

import './index.scss';

type SelectableSections = "actions" | "childrens";

interface IInterestActionDrawerProps extends CommonEntityActionProps {
  selectedInterests: IInterest[];
  onToggleChild: (interestId: number, childId: number) => void;
}

const InterestActionDrawer: React.FC<IInterestActionDrawerProps> = ({
  bubblePopperData,
  theme,
  onClose,
  onToggleAction,
  onToggleChild,
  selectedInterests
}) => {
  const [currentSection, setCurrentSection] = useState<SelectableSections | null>(null);

  const currentInterest = selectedInterests.find((interest) => interest.id === bubblePopperData.bubbleId);
  const interestChildren = currentInterest?.children;

  const handleClose = (e: any) => {
    onClose(e);
    setCurrentSection(null);
  };

  const handleChangeSection = (section: SelectableSections) => {
    return () => setCurrentSection(section);
  };

  const handleToggleChild = (childId: number) => {
    return () => {
      if (currentInterest) {
        onToggleChild(currentInterest?.id, childId);
      }
    };
  };

  const getChildActivityTypeLabel = () => {
    if (currentSection === 'actions') return 'Действия';

    return getInterestCategoryLabel(currentInterest?.headId);
  };

  if (interestChildren) {
    const childActivityTypeLabel = getChildActivityTypeLabel();
    const selectedActions = currentSection === 'actions' ? bubblePopperData.actions : currentInterest?.children || [];
    const handler = currentSection === 'actions' ? onToggleAction : handleToggleChild;

    return (
      <Drawer
        className="interests-action-drawer"
        open={bubblePopperData.isOpen}
        isFullHeight={false}
        isContainerScrollable
        anchor="bottom"
        onClose={handleClose}
      >
        <MobileDragIndicator onTouchStart={handleClose} />

        {currentSection === null ? (
          <>
            <h5 className="interests-action-drawer__interest-name">{bubblePopperData.bubbleName}</h5>
            <p className="interests-action-drawer__caption">
              Выбери действия для детализации интереса, также ты можешь указать какие увлечения твои любимые.
            </p>

            <div className="interests-action-drawer__section-select">
              <Button
                endIcon={<img src={iconArrowRight} />}
                className="interests-action-drawer__button"
                onClick={handleChangeSection('actions')}
              >
                Действия
              </Button>
              {interestChildren.length > 0 && (
                <Button
                  endIcon={<img src={iconArrowRight} />}
                  className="interests-action-drawer__button"
                  onClick={handleChangeSection('childrens')}
                >
                  {childActivityTypeLabel}
                </Button>
              )}
            </div>
          </>
        ) : (
          <>
            <Button
              className="interests-action-drawer__exit-button"
              startIcon={<img src={iconArrowLeft} />}
              onClick={() => setCurrentSection(null)}
            >
              {childActivityTypeLabel}
            </Button>

            <div className="interests-action-drawer__actions">
              <EntityActions
                actions={selectedActions}
                checkboxBackground={theme.mainColor}
                onToggle={handler}
              />
            </div>
          </>
        )}
      </Drawer>
    );
  }

  return (
    <Drawer
      className="interests-action-drawer"
      open={bubblePopperData.isOpen}
      isFullHeight={false}
      isContainerScrollable
      anchor="bottom"
      onClose={handleClose}
    >
      <MobileDragIndicator onTouchStart={handleClose} />

      <h5 className="interests-action-drawer__interest-name">{bubblePopperData.bubbleName}</h5>
      <div className="interests-action-drawer__actions">
        <EntityActions
          actions={bubblePopperData.actions}
          checkboxBackground={theme.mainColor}
          onToggle={onToggleAction}
        />
      </div>
    </Drawer>
  );
};

export default InterestActionDrawer;

type EntityAction = {
  name: string;
  id: number;
  isActive: boolean;
}

interface IEntityActionsProps<T extends EntityAction> {
  actions: T[] | undefined
  checkboxBackground: string;
  onToggle: (id: number) => () => void;
}

const EntityActions: React.VFC<IEntityActionsProps<IInterestAction | IInterestChild>> = ({
  actions,
  checkboxBackground,
  onToggle
}) => {
  return (
    <>
      {actions?.map((action) => (
        <div
          key={action.id}
          className="interests-action-drawer__action-item"
        >
          <FormControlLabel
            control={(
              <Checkbox
                checked={action.isActive}
                onChange={onToggle(action.id)}
                background={checkboxBackground}
              />
            )}
            label={<p className="interests-action-drawer__label">{action.name}</p>}
          />
        </div>
      ))}
    </>
  );
};
