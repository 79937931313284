import { forwardRef, ForwardRefRenderFunction, useContext } from 'react';

import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import { IconChevronFilledArrowDown, IconChevronFilledArrowUp } from 'icons';
import { getAccentColor } from 'portfolio3/styles';
import { getButtonStyles } from 'portfolio3/ui-kit/utils';
import { mergeSx } from 'utils';

import { cellNestedRenderBox } from './classes';
import {
  TableDataCellProps,
  TableHeadCellProps,
  TableRowProps,
  TBodyProps,
  TFootProps,
  THeadProps,
} from './compositionProps';
import { TableContext } from './context';
import * as styles from './styles';

const TableBodyComponent: ForwardRefRenderFunction<HTMLTableSectionElement, TBodyProps> = (props, ref) => {
  const { boxProps, children } = props;

  return (
    <Box
      {...boxProps}
      ref={ref}
      component="tbody"
      className={(clsx('ui-table-body'), boxProps?.className)}
      sx={mergeSx(boxProps?.sx)}
    >
      {children}
    </Box>
  );
};

const TableHeadComponent: ForwardRefRenderFunction<HTMLTableSectionElement, THeadProps> = (props, ref) => {
  const { boxProps, children } = props;

  return (
    <Box
      {...boxProps}
      ref={ref}
      component="thead"
      className={clsx('ui-table-head', boxProps?.className)}
      sx={mergeSx(boxProps?.sx)}
    >
      {children}
    </Box>
  );
};

const TableFootComponent: ForwardRefRenderFunction<HTMLTableSectionElement, TFootProps> = (props, ref) => {
  const { boxProps, children } = props;

  return (
    <Box
      {...boxProps}
      ref={ref}
      component="tfoot"
      className={clsx('ui-table-footer', boxProps?.className)}
      sx={mergeSx(styles.tableFooter, boxProps?.sx)}
    >
      {children}
    </Box>
  );
};

const TableRowComponent: ForwardRefRenderFunction<HTMLTableRowElement, TableRowProps> = (props, ref) => {
  const { boxProps, children } = props;

  return (
    <Box
      {...boxProps}
      ref={ref}
      component="tr"
      className={clsx('ui-table-row', boxProps?.className)}
      sx={mergeSx(boxProps?.sx)}
    >
      {children}
    </Box>
  );
};

const TableHeadCellComponent: ForwardRefRenderFunction<HTMLTableCellElement, TableHeadCellProps> = (props, ref) => {
  const { boxProps, density = 8, withSorting, sortType, children } = props;

  const { loading } = useContext(TableContext);

  const accentColor = getAccentColor('indigo', '100');

  return (
    <Box
      {...boxProps}
      ref={ref}
      component="th"
      className={clsx('ui-table-head-cell', boxProps?.className)}
      sx={mergeSx(styles.cell, styles.headCellInlinePadding, boxProps?.sx)}
    >
      <Box
        component={withSorting ? 'button' : 'div'}
        className={cellNestedRenderBox}
        sx={mergeSx(
          styles.headCell,
          getButtonStyles(),
          styles.getCellRenderBoxStyles(density),
          !withSorting ? { cursor: 'default' } : null,
        )}
        disabled={loading}
      >
        <Typography variant="Paragraph MD/Semi Bold" textAlign="left">
          {children}
        </Typography>
        {withSorting && (
          <Box className="sort-icons-container" sx={styles.sortIconsContainer}>
            <IconChevronFilledArrowUp fill={sortType === 'asc' ? accentColor : undefined} />
            <IconChevronFilledArrowDown fill={sortType === 'desc' ? accentColor : undefined} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

const TableDataCellComponent: ForwardRefRenderFunction<HTMLTableCellElement, TableDataCellProps> = (props, ref) => {
  const { boxProps, density = 'small', children } = props;

  return (
    <Box
      {...boxProps}
      ref={ref}
      component="td"
      className={clsx('ui-table-data-cell', boxProps?.className)}
      sx={mergeSx(styles.cell, styles.dataCellInlinePadding, styles.getCellRenderBoxStyles(density), boxProps?.sx)}
    >
      {children}
    </Box>
  );
};

export const TableBody = forwardRef(TableBodyComponent);
export const TableHead = forwardRef(TableHeadComponent);
export const TableFoot = forwardRef(TableFootComponent);
export const TableRow = forwardRef(TableRowComponent);
export const TableHeadCell = forwardRef(TableHeadCellComponent);
export const TableDataCell = forwardRef(TableDataCellComponent);
