import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Typography } from '@mui/material';
import { getParallelsAction, getPersonsListActions } from 'actions';
import { IPersonsListRequest } from 'api/types';
import { format } from 'date-fns';
import { useAppSelector, useComponentDidMount, useDataLoad, useUserFunctionality } from 'hooks';
import { IconChevronLargeDown, IconChevronLargeUp, IconChevronSmallDown, IconChevronSmallUp } from 'icons';
import { isEqual } from 'lodash';
import UnstyledButton from 'portfolio3/components/common/UnstyledButton';
import { Button } from 'portfolio3/ui-kit/button';
import { currentLocalRoleSelector } from 'selectors';
import { getDataLoadRequests, mergeSx } from 'utils';

import StudentSearcherBottomControls from './BoottomControls';
import LazyProgressBar from './LazyProgressBar';
import * as styles from './styles';
import StudentSearcherTopControls from './TopControls';
import { IStudentSearcherFormData } from './types';

const StudentSearcher: FC = () => {
  const dispatch = useDispatch();
  const currentLocalRole = useAppSelector(currentLocalRoleSelector);
  const isPersonsListLoading = useAppSelector((state) => state.personsList.loading);

  const { isEmployee } = useUserFunctionality();
  useDataLoad({ shouldLoad: true, requests: getDataLoadRequests().studentSearcher, visibilitySettingsCheck: false });

  const initialFormData: IStudentSearcherFormData = {
    name: '',
    surname: '',
    patronymic: '',
    birthDate: null,
    snils: '',
    phoneNumber: '',
    email: '',
    organization: isEmployee && currentLocalRole?.organization ? currentLocalRole.organization : null,
    parallelId: undefined,
    classId: undefined,
  };

  const defaultOpen = !isEmployee;
  const defaultButtomControlsVisible = !isEmployee;

  const [isOpen, setOpen] = useState(defaultOpen);
  const [isBottomControlsVisible, setBottomControlsVisible] = useState(defaultButtomControlsVisible);
  const [formData, setFormData] = useState(initialFormData);
  const lastSearchedFormData = useRef<typeof formData | null>(null);

  const toggleOpen = () => setOpen((prevState) => !prevState);
  const toggleBottomControls = () => setBottomControlsVisible((prevState) => !prevState);

  // Кнопка поиска должна становиться активной только в том случае, если Пользователь заполнил на форме минимум 2 поля
  // (Поле "Отчество" при подсчете количества заполненных полей не учитывается)
  const formKeyValues = Object.entries(formData);
  const ignoredFields: (keyof IStudentSearcherFormData)[] = ['patronymic'];
  const possibleMasks = ['___-___-___ __', '+7 (___) ___-__-__'];

  const filteredFormValues = formKeyValues
    .filter(([key]) => !ignoredFields.includes(key as keyof IStudentSearcherFormData))
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .map(([_, value]) => value);

  const filledFieldsCount = filteredFormValues.reduce((count, value) => {
    let isFilled = false;
    if (typeof value === 'string') {
      isFilled = value.trim().length > 0 && !possibleMasks.includes(value);
    } else {
      isFilled = Boolean(value);
    }
    return isFilled ? count + 1 : count;
  }, 0);

  const normalizedFormDataEntries = Object.entries(formData).map((entry) => {
    const [key, value] = entry;
    if (possibleMasks.includes(value)) {
      return [key, ''] as [string, unknown];
    }
    return entry;
  });
  // formData без масок
  const normalizedFormData = Object.fromEntries(normalizedFormDataEntries);

  const snilsValueLength = formData.snils
    ?.split('')
    .map((item) => +item)
    .filter((item) => !isNaN(item) && typeof item === 'number').length;

  // для сотрудника (классного руководителя) не должна выполняться проверка на заполнение полей
  // так как нужна возможность поиска даже если заполнена только организация
  const isFormSubmitEnabled = filledFieldsCount >= 2 || snilsValueLength === 12 || isEmployee;
  const isFormClearDisabled = isPersonsListLoading || isEqual(normalizedFormData, initialFormData);
  const isLastSearchEqualsCurrentFormData = isEqual(normalizedFormData, lastSearchedFormData.current);
  const isFormSubmitDisabled = isPersonsListLoading || !isFormSubmitEnabled || isLastSearchEqualsCurrentFormData;

  const getPersonsList = (requestFormData: IStudentSearcherFormData) => {
    const schooldId = requestFormData.organization?.id;

    const request: IPersonsListRequest = {
      currentSubsystemRoleId: currentLocalRole?.id,
      searchParams: {
        schoolId: schooldId ? [String(schooldId)] : null,
        lastname: requestFormData.surname || null,
        firstname: requestFormData.name || null,
        patronymic: requestFormData.patronymic || null,
        birthdate: requestFormData.birthDate ? format(requestFormData.birthDate, 'yyyy-MM-dd') : null,
        snils: requestFormData.snils?.replace(/\D/g, '') || null,
        phone: requestFormData.phoneNumber?.replace(/\D/g, '') || null,
        mail: requestFormData.email || null,
        parallel: requestFormData.parallelId ? String(requestFormData.parallelId) : null,
        grade: requestFormData.classId ? String(requestFormData.classId) : null,
      },
      sortParams: {
        sortField: 'lastname',
        sort: 'asc',
      },
    };

    lastSearchedFormData.current = requestFormData;
    dispatch(getPersonsListActions.request(request));
  };

  useComponentDidMount(() => {
    // получение списка учащихся для сотрудника сразу при открытии страницы
    if (isEmployee) {
      getPersonsList(initialFormData);
    }
  });

  useEffect(() => {
    if (formData.organization) {
      const organizationId = formData.organization?.id;
      dispatch(getParallelsAction.request(organizationId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.organization]);

  useEffect(() => {
    if (!formData.organization) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        parallelId: undefined,
      }));
    }
  }, [formData.organization]);

  useEffect(() => {
    if (!formData.parallelId) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        classId: undefined,
      }));
    }
  }, [formData.parallelId]);

  const handleSearchPersonWithEnter = (e: React.KeyboardEvent) => {
    if (isFormSubmitDisabled) return;

    if (e.key === 'Enter') {
      getPersonsList(formData);
    }
  };

  const handleClearFormData = () => {
    setFormData(initialFormData);
    lastSearchedFormData.current = null;
    dispatch(getPersonsListActions.invalidate());

    if (isEmployee) {
      getPersonsList(initialFormData);
    }
  };

  const handleSearch = () => {
    getPersonsList(formData);
  };

  return (
    <Box className="student-searcher" sx={styles.root}>
      <LazyProgressBar isLoading={isPersonsListLoading} />
      <Box className="student-searcher__header" sx={mergeSx(styles.header, !isOpen && { paddingBottom: '20px' })}>
        <Typography variant="Paragraph LG/Semi Bold">Поиск учащихся</Typography>
        {isEmployee && (
          <UnstyledButton onClick={toggleOpen} sx={styles.openButton}>
            {isOpen ? <IconChevronLargeUp /> : <IconChevronLargeDown />}
          </UnstyledButton>
        )}
      </Box>
      {isOpen && (
        <>
          <Box
            className="student-searcher__filters-content"
            component="form"
            role="presentation"
            onKeyDown={handleSearchPersonWithEnter}
            sx={styles.filtersContent}
          >
            <StudentSearcherTopControls formData={formData} setFormData={setFormData} />
            {isBottomControlsVisible && <StudentSearcherBottomControls formData={formData} setFormData={setFormData} />}
          </Box>
          <Box className="student-searcher__footer" sx={styles.footer}>
            {isEmployee && (
              <Button
                variant="text"
                onClick={toggleBottomControls}
                iconRight={isBottomControlsVisible ? <IconChevronSmallUp /> : <IconChevronSmallDown />}
              >
                {isBottomControlsVisible ? 'Свернуть фильтры' : 'Показать еще фильтры'}
              </Button>
            )}
            <Box className="student-searcher__footer-right" sx={styles.footerRightButtons}>
              <Button variant="secondary" disabled={isFormClearDisabled} onClick={handleClearFormData}>
                Очистить все
              </Button>
              <Button variant="primary" disabled={isFormSubmitDisabled} onClick={handleSearch}>
                {isEmployee ? 'Начать поиск' : 'Начать поиск учащегося'}
              </Button>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default StudentSearcher;
