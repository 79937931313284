/* eslint-disable max-len */
import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconMetaSkillLevelSlider: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.974 1.25H5.026c-1.05 0-1.901.853-1.901 1.906v13.688c0 1.052.851 1.906 1.901 1.906h9.948c1.05 0 1.901-.854 1.901-1.906V3.156a1.904 1.904 0 0 0-1.901-1.906Z"
      fill="#D6D6DF"
    />
    <path
      d="M4.375 4.531c0-.259.21-.468.469-.468h1.562a.469.469 0 0 1 0 .937H4.844a.469.469 0 0 1-.469-.469Zm.469 5a.469.469 0 0 0 0 .938h1.562a.469.469 0 0 0 0-.938H4.844Zm8.75 0a.469.469 0 1 0 0 .938h1.562a.469.469 0 0 0 0-.938h-1.562Zm0-5.469a.469.469 0 1 0 0 .938h1.562a.469.469 0 1 0 0-.938h-1.562Zm-9.219 11.72c0-.26.21-.47.469-.47h1.562a.469.469 0 0 1 0 .938H4.844a.469.469 0 0 1-.469-.469Zm9.219-.47a.469.469 0 1 0 0 .938h1.562a.469.469 0 0 0 0-.938h-1.562Z"
      fill="#87879B"
    />
    <path
      d="M7.813 5.625a2.188 2.188 0 0 1 4.375 0v8.75a2.188 2.188 0 0 1-4.376 0v-8.75Z"
      fill="#7950F2"
      fillOpacity=".48"
    />
    <path
      d="M8.438 5.625a1.562 1.562 0 1 1 3.124 0v8.75a1.563 1.563 0 0 1-3.124 0v-8.75Z"
      fill="#7950F2"
      fillOpacity=".8"
    />
    <path
      d="M6.875 7.5c0-.69.56-1.25 1.25-1.25h3.75a1.25 1.25 0 1 1 0 2.5h-3.75c-.69 0-1.25-.56-1.25-1.25Z"
      fill="#53546E"
    />
  </SvgIcon>
);

export default IconMetaSkillLevelSlider;
