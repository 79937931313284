import { IRootState } from 'reducers';
import { createSelector } from 'reselect';

import { mapStudySpoPracticeResponse } from './mappers';

const studySpoPracticeSelector = createSelector(
  [(state: IRootState) => state.studentSpoEducation],
  (studentEducation) => {
    return mapStudySpoPracticeResponse(studentEducation);
  },
);

export default studySpoPracticeSelector;
