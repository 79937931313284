import { styled, svgIconClasses } from '@mui/material';
import { NeutralColors } from 'portfolio3/styles';
import { CommonUiKitSize } from 'portfolio3/ui-kit/types';
import { skipPropsForwarding } from 'utils';

import { getFocusStateStyles, getSwitcherTokens } from '../../../utils';

interface IStyledButtonProps {
  checked?: boolean;
  disabled?: boolean;
  componentSize?: CommonUiKitSize;
}

const StyledBaseButton = styled('button', {
  shouldForwardProp: skipPropsForwarding<IStyledButtonProps>(['componentSize']),
})<IStyledButtonProps>(({ checked, componentSize = 'medium' }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 4,
  minHeight: getSwitcherTokens(componentSize).buttonHeight,
  height: '100%',
  maxHeight: '100%',
  padding: 0,
  paddingInline: 12,

  borderRadius: 6,
  cursor: 'pointer',

  fontSize: 14,
  fontWeight: checked ? 500 : 400,
  lineHeight: '20px',
  fontFamily: 'Inter',
  whiteSpace: 'nowrap',

  [`& .${svgIconClasses.root}`]: {
    width: 20,
    height: 20,
    color: NeutralColors.light_neutrals_600,
  },

  '&:focus-visible': {
    ...getFocusStateStyles(),
  },
}));

export default StyledBaseButton;
