import { diagramCat } from 'images';
import { SxStyles } from 'types';

export const diagramCard: SxStyles = (theme) => ({
  backgroundImage: `url(${diagramCat})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '100% 100%',

  [theme.breakpoints.down('commonSm')]: {
    backgroundImage: 'none',
  },
});

export const container: SxStyles = (theme) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '24px',

  marginTop: '16px',

  [theme.breakpoints.down('commonSm')]: {
    gap: '12px',
    marginTop: '12px',
  },
});
