/* eslint-disable prettier/prettier */
/* eslint-disable */

export const getChildrenPluralization = (count: number) => {
  let sEnding;
  let i;
  count %= 100;
  if (count >= 11 && count <= 19) {
    sEnding = 'детей';
  } else {
    i = count % 10;
    switch (i) {
      case (1): sEnding = 'ребенок'; break;
      case (2):
      case (3):
      case (4): sEnding = 'ребенка'; break;
      default: sEnding = 'детей';
    }
  }
  return sEnding;
};

export const getDaysPluralization = (count: number) => {
  let sEnding;
  let i;
  count %= 100;
  if (count >= 11 && count <= 19) {
    sEnding = 'дней';
  } else {
    i = count % 10;
    switch (i) {
      case (1): sEnding = 'день'; break;
      case (2):
      case (3):
      case (4): sEnding = 'дня'; break;
      default: sEnding = 'дней';
    }
  }
  return sEnding;
};

export const getRecordingPluralization = (count: number) => {
  let sEnding;
  let i;
  count %= 100;
  if (count >= 11 && count <= 19) {
    sEnding = 'записей';
  } else {
    i = count % 10;
    switch (i) {
      case (1): sEnding = 'запись'; break;
      case (2):
      case (3):
      case (4): sEnding = 'записи'; break;
      default: sEnding = 'записей';
    }
  }
  return sEnding;
};

export const getProcessPluralization = (count: number) => {
  let sEnding;
  let i;
  count %= 100;
  if (count >= 11 && count <= 19) {
    sEnding = 'обработано';
  } else {
    i = count % 10;
    switch (i) {
      case (1): sEnding = 'обработана'; break;
      case (2):
      case (3):
      case (4): sEnding = 'обработаны'; break;
      default: sEnding = 'обработано';
    }
  }
  return sEnding;
};

export const getAddingPluralization = (count: number) => {
  let sEnding;
  let i;
  count %= 100;
  if (count >= 11 && count <= 19) {
    sEnding = 'добавлено';
  } else {
    i = count % 10;
    switch (i) {
      case (1): sEnding = 'добавлена'; break;
      case (2):
      case (3):
      case (4): sEnding = 'добавлены'; break;
      default: sEnding = 'добавлено';
    }
  }
  return sEnding;
};

export const getMarkPluralization = (mark: number) => {
  let sEnding;
  let i;
  mark %= 100;
  if (mark >= 11 && mark <= 19) {
    sEnding = 'баллов';
  } else {
    i = mark % 10;
    switch (i) {
      case (1): sEnding = 'балл'; break;
      case (2):
      case (3):
      case (4): sEnding = 'балла'; break;
      default: sEnding = 'баллов';
    }
  }
  return sEnding;
}

export const getMark = (primaryMarkValue: number, primaryMarkBasis: number) => {
  const markName = getMarkPluralization(primaryMarkValue);
  if (primaryMarkBasis === 0) return undefined;
  return `${primaryMarkValue} ${markName} (${(100 / primaryMarkBasis * primaryMarkValue).toFixed(1)} %)`;
};

/**
 * Функция принимает число и 3 формы слова в Именительном, Родительном ед. ч. и Родительном мн. ч. и возвращает правильный падеж
 * @param num - Число
 * @param nominative - Именительный падеж
 * @param genitiveSingular - Родительный падеж единственного числа
 * @param genitivePlural - Родительный падеж множественного числа
 * @returns Соответствующий числу падеж в формате строки
 * @example
 * getNumeral(12, 'год', 'года', 'лет') - лет
 * getNumeral(3, 'день', 'дня', 'дней') - дня
 */
export function getNumeral(num: number, nominative: string, genitiveSingular: string, genitivePlural: string): string {
  const absNum = Math.abs(num);
  const lastDigit = absNum % 10;
  const lastTwoDigits = absNum % 100;
  if (lastTwoDigits > 10 && lastTwoDigits < 20) {
    return genitivePlural;
  }
  if (lastDigit === 1) {
    return nominative;
  }
  if (lastDigit >= 2 && lastDigit <= 4) {
    return genitiveSingular;
  }
  return genitivePlural;
}
