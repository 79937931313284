import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const Ecology: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="230" height="129" viewBox="0 0 230 129" xmlns="http://www.w3.org/2000/svg" {...props} fill="none">
    {/*eslint-disable max-len */}
    <path
      d="M117.5 116.16a50.87 50.87 0 0 1-6.64-101.28A50.859 50.859 0 0 1 158 96.17a50.994 50.994 0 0 1-40.52 20l.02-.01Zm.21-100.73a49.68 49.68 0 1 0-.076 99.36 49.68 49.68 0 0 0 .076-99.36Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M117.61 115.79c-11 0-19.9-22.82-19.9-50.86s8.93-50.86 19.9-50.86c10.97 0 19.9 22.82 19.9 50.86s-8.93 50.86-19.9 50.86Zm0-100.72c-10.42 0-18.9 22.37-18.9 49.86s8.48 49.86 18.9 49.86c10.42 0 18.9-22.37 18.9-49.86s-8.51-49.86-18.9-49.86Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="m118.76 115.78-.2-1c.36-.07 36.41-7.93 36.41-53 0-21-9.88-32.55-18.17-38.58a48.42 48.42 0 0 0-18.22-8.17l.16-1c.38.06 37.23 6.76 37.23 47.74.03 45.94-36.84 53.93-37.21 54.01ZM115.58 115.78c-.37-.08-37.21-8.07-37.21-54 0-41 36.85-47.68 37.22-47.74l.17 1c-.36.06-36.39 6.63-36.39 46.75 0 23.57 9.91 36.72 18.22 43.6a46.53 46.53 0 0 0 18.19 9.38l-.2 1.01Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M117.61 71c-22.22 0-40.29-12.75-40.29-28.45s18.07-28.48 40.29-28.48 40.29 12.78 40.29 28.48c0 15.7-18.08 28.45-40.29 28.45Zm0-56c-21.67 0-39.29 12.33-39.29 27.48S95.94 70 117.61 70c21.67 0 39.29-12.3 39.29-27.45 0-15.15-17.63-27.48-39.29-27.48V15Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M117.54 93.41c-27.7 0-50.23-16.12-50.23-35.94h1c0 19.27 22.08 34.94 49.23 34.94s49.24-15.67 49.24-34.94h1c0 19.82-22.54 35.94-50.24 35.94Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M117.54 107.09c-22.25 0-42.1-10.67-48.26-25.94l.92-.37c6 14.9 25.49 25.31 47.34 25.31 21.69 0 41.12-10.31 47.24-25.07l.93.38c-6.28 15.12-26.08 25.69-48.17 25.69ZM117.61 49.89c-16.49 0-29.91-8-29.91-17.91s13.42-17.91 29.91-17.91c16.49 0 29.91 8 29.91 17.91s-13.42 17.91-29.91 17.91Zm0-34.82c-15.94 0-28.91 7.59-28.91 16.93s13 16.91 28.91 16.91 28.91-7.6 28.91-16.91-12.97-16.93-28.91-16.93Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M117.61 32.48c-11.11 0-19.81-4-19.81-9.2 0-5.2 8.7-9.21 19.81-9.21s19.8 4.05 19.8 9.21c0 5.16-8.7 9.2-19.8 9.2Zm0-17.41c-10.2 0-18.81 3.76-18.81 8.21 0 4.45 8.61 8.2 18.81 8.2s18.8-3.75 18.8-8.2c0-4.45-8.61-8.21-18.8-8.21Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M162.46 63.46s9.32 9.48 20.88 9.55c11.56.07 21-9.28 21-9.28s-9.32-9.48-20.88-9.56c-11.56-.08-21 9.29-21 9.29Z"
      fill="#fff"
    />
    <path
      d="M183.49 73.51h-.15c-11.64-.07-20.85-9.31-21.23-9.7l-.35-.36.35-.35c.39-.39 9.72-9.51 21.36-9.43 11.64.08 20.85 9.33 21.23 9.71l.35.35-.35.36c-.39.38-9.64 9.42-21.21 9.42Zm-20.3-10.05c1.83 1.71 10.21 9 20.16 9.05h.13c9.89 0 18.3-7.11 20.14-8.78-1.83-1.71-10.21-9-20.16-9.06h-.13c-9.89 0-18.33 7.11-20.14 8.79Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M152.21 26.18s-3.1 10.54 1.5 18.91c4.6 8.37 15.15 11.42 15.15 11.42S172 46 167.36 37.6c-4.64-8.4-15.15-11.42-15.15-11.42Z"
      fill="#fff"
    />
    <path
      d="m169.2 57.13-.48-.14c-.44-.12-10.8-3.2-15.45-11.66-4.65-8.46-1.67-18.85-1.54-19.33l.14-.48.48.14c.43.13 10.8 3.2 15.44 11.66 4.64 8.46 1.68 18.86 1.55 19.29l-.14.52Zm-16.64-30.31c-.53 2.16-2.3 11 1.59 18 3.89 7 12.25 10.31 14.36 11 .52-2.17 2.29-11-1.59-18s-12.25-10.29-14.36-11Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M187 13.35S176 18 172.11 27.64c-3.89 9.64.82 20.63.82 20.63s11-4.66 14.88-14.3c3.88-9.64-.81-20.62-.81-20.62Z"
      fill="#fff"
    />
    <path
      d="m172.66 48.92-.19-.46c-.2-.45-4.75-11.28-.83-21s14.7-14.37 15.16-14.57l.46-.19.19.46c.2.45 4.75 11.28.83 21s-14.7 14.37-15.16 14.57l-.46.19ZM186.72 14c-2.12 1-10.84 5.59-14.15 13.81-3.31 8.22-.2 17.57.63 19.76 2.11-1 10.84-5.59 14.15-13.81 3.31-8.22.2-17.54-.63-19.76Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M149 68.08a21.274 21.274 0 0 1-5.53-.66l.29-1c.8.24 19.76 5.71 28.66-18.38l.94.35c-6.19 16.92-17.54 19.69-24.36 19.69Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="m182.691 25.222-10.06 23.236.918.397 10.059-23.236-.917-.397ZM159.755 37.677l-.892.452 9.609 18.965.892-.452-9.609-18.965ZM187.699 61.494l-25.3 1.771.07 1 25.3-1.77-.07-1Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M116.46 63.46s9.32 9.48 20.88 9.55c11.56.07 21-9.28 21-9.28s-9.32-9.48-20.88-9.56c-11.56-.08-21 9.29-21 9.29Z"
      fill="#fff"
    />
    <path
      d="M137.49 73.51h-.15c-11.64-.07-20.85-9.31-21.23-9.7l-.35-.36.35-.35c.39-.39 9.64-9.43 21.21-9.43h.15c11.64.08 20.85 9.32 21.23 9.71l.35.35-.35.36c-.39.38-9.64 9.42-21.21 9.42Zm-20.3-10.05c1.83 1.71 10.21 9 20.16 9.05h.13c9.89 0 18.3-7.11 20.14-8.78-1.83-1.71-10.21-9-20.16-9.06h-.13c-9.89 0-18.33 7.11-20.14 8.79Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M106.21 26.18s-3.1 10.54 1.5 18.91c4.6 8.37 15.15 11.42 15.15 11.42S126 46 121.36 37.6c-4.64-8.4-15.15-11.42-15.15-11.42Z"
      fill="#fff"
    />
    <path
      d="m123.2 57.13-.48-.14c-.44-.12-10.8-3.2-15.45-11.66-4.65-8.46-1.67-18.85-1.54-19.33l.14-.48.48.14c.43.13 10.8 3.2 15.44 11.66 4.64 8.46 1.68 18.86 1.55 19.29l-.14.52Zm-16.64-30.31c-.53 2.16-2.3 11 1.59 18 3.89 7 12.25 10.31 14.36 11 .52-2.17 2.29-11-1.59-18s-12.25-10.29-14.36-11Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M141 13.35S130 18 126.11 27.64c-3.89 9.64.82 20.63.82 20.63s11-4.66 14.88-14.3c3.88-9.64-.81-20.62-.81-20.62Z"
      fill="#fff"
    />
    <path
      d="m126.66 48.92-.19-.46c-.2-.45-4.75-11.28-.83-21s14.7-14.37 15.16-14.57l.46-.19.19.46c.2.45 4.75 11.28.83 21s-14.7 14.37-15.16 14.57l-.46.19ZM140.72 14c-2.12 1-10.84 5.59-14.15 13.81-3.31 8.22-.2 17.57.63 19.76 2.11-1 10.84-5.59 14.15-13.81 3.31-8.22.2-17.54-.63-19.76Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M103 68.08a21.28 21.28 0 0 1-5.53-.66l.29-1c.8.24 19.76 5.71 28.66-18.38l.94.35c-6.19 16.92-17.54 19.69-24.36 19.69Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="m136.685 25.225-10.06 23.236.918.397 10.06-23.235-.918-.398ZM113.762 37.688l-.892.452 9.609 18.964.892-.452-9.609-18.964ZM141.579 61.434l-25.3 1.771.07 1 25.3-1.77-.07-1Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M81.54 76.46S72.22 85.94 60.66 86c-11.56.06-21-9.28-21-9.28s9.34-9.47 20.87-9.55c11.53-.08 21.01 9.29 21.01 9.29Z"
      fill="#fff"
    />
    <path
      d="M60.51 86.51c-11.57 0-20.82-9-21.21-9.42l-.3-.36.35-.35c.38-.39 9.59-9.63 21.23-9.71h.15c11.57 0 20.82 9 21.21 9.43l.35.35-.35.36c-.38.39-9.59 9.63-21.23 9.7h-.2Zm-20.13-9.78c1.84 1.67 10.25 8.78 20.14 8.78h.13c9.95-.06 18.33-7.34 20.16-9.05-1.81-1.68-10.25-8.79-20.14-8.79h-.13c-9.95.07-18.33 7.33-20.16 9.06Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M91.79 39.18s3.1 10.54-1.5 18.91c-4.6 8.37-15.15 11.42-15.15 11.42S72.05 59 76.64 50.6s15.15-11.42 15.15-11.42Z"
      fill="#fff"
    />
    <path
      d="m74.8 70.13-.14-.48c-.13-.43-3.1-10.83 1.55-19.29 4.65-8.46 15-11.53 15.44-11.66l.48-.14.14.48c.13.44 3.1 10.83-1.54 19.29C86.09 66.79 75.72 69.87 75.28 70l-.48.13Zm16.64-30.31c-2.11.72-10.47 4-14.36 11-3.89 7-2.11 15.87-1.59 18 2.11-.72 10.48-3.95 14.36-11 3.88-7.05 2.15-15.82 1.59-18Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M57 26.35S68 31 71.89 40.64c3.89 9.64-.82 20.63-.82 20.63s-11-4.66-14.88-14.3c-3.88-9.64.81-20.62.81-20.62Z"
      fill="#fff"
    />
    <path
      d="m71.34 61.92-.46-.19c-.46-.2-11.24-4.85-15.16-14.57s.63-20.56.83-21l.19-.46.46.19c.46.2 11.24 4.85 15.16 14.57s-.63 20.56-.83 21l-.19.46ZM57.28 27c-.83 2.2-3.94 11.55-.63 19.76s12 12.8 14.15 13.81c.83-2.2 3.94-11.55.63-19.76S59.4 28 57.28 27Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M95 81.08c-6.78 0-18.13-2.77-24.36-19.64l.94-.35c8.9 24.09 27.86 18.62 28.66 18.38l.29 1a21.28 21.28 0 0 1-5.53.61Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="m61.307 38.22-.917.397 10.06 23.236.917-.397-10.06-23.236ZM84.24 50.688 74.624 69.65l.892.453L85.13 51.14l-.891-.452ZM56.69 74.073l-.072.998 25.235 1.813.072-.997-25.235-1.814Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default Ecology;
