import { FC, ReactNode } from 'react';

import { Stack, Typography } from '@mui/material';
import { getAccentColor } from 'portfolio3/styles';
import { Attributes } from 'portfolio3/ui-kit';
import { horizontalOverflowStyles } from 'portfolio3/ui-kit/utils';
import { SxStyles } from 'types';
import { isDefined, mergeSx } from 'utils';

import { getMissingBallPluralization } from '../utils';
import * as styles from './styles';

interface ISpecialityInfoProps {
  format: string;
  studentBall?: number;
  placeCount?: number;
  minBall?: number;
  sx?: SxStyles;
}

const SpecialityInfo: FC<ISpecialityInfoProps> = ({ format, studentBall, placeCount, minBall, sx }) => {
  const attributes = [
    { label: 'Мест', value: placeCount ?? 'Н/Д' },
    { label: 'Проходной балл', value: minBall ?? 'Н/Д' },
  ];

  const attributeArray = attributes.map((attribute) => {
    return (
      <Typography key={attribute.value + attribute.label} variant="Paragraph MD/Regular">
        {attribute.label}: <b>{attribute.value}</b>
      </Typography>
    );
  });

  let noteElement: ReactNode = null;

  if (isDefined(minBall) && isDefined(studentBall) && minBall !== 0) {
    // Признак наличия необходимых баллов у учащегося
    const isStudentPassedExams = studentBall >= minBall;
    // Необходимое количество баллов, в случае если не хватает
    const missingBalls = !isStudentPassedExams ? minBall - studentBall : 0;

    const pluralizatedBall = getMissingBallPluralization(missingBalls);
    const noteColor = isStudentPassedExams ? getAccentColor('green', '100') : getAccentColor('orange', '100');
    const noteText = isStudentPassedExams ? 'Ты проходишь!' : `Не хватает ${missingBalls} ${pluralizatedBall}`;

    noteElement = (
      <Typography variant="Paragraph MD/Semi Bold" color={noteColor}>
        {noteText}
      </Typography>
    );
  }

  return (
    <Stack className="speciality-info" sx={mergeSx(styles.root, sx)}>
      <Typography variant="Paragraph MD/Semi Bold">{format}</Typography>
      <Stack className="overflow-container" sx={mergeSx(styles.scrollableElement, horizontalOverflowStyles.container)}>
        <Attributes attributes={attributeArray} sx={horizontalOverflowStyles.element} />
        {noteElement}
      </Stack>
    </Stack>
  );
};

export default SpecialityInfo;
