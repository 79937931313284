import { FC } from 'react';

import { useMediaQuery } from '@mui/material';
import { commonTheme } from 'portfolio3/styles/theme';
import { IEntity } from 'types';

import Achievement from '../../Achievement';
import AchievementCompact from '../../AchievementCompact';
import Reward from '../../Reward';
import RewardCompact from '../../RewardCompact';
import BaseCard from '../BaseCard';

interface ICommonEntityCardProps {
  entity: IEntity;
  onView: () => void;
}

const CommonEntityCard: FC<ICommonEntityCardProps> = ({ entity, onView }) => {
  const isCompactReward = useMediaQuery(commonTheme.breakpoints.down('commonMd'));

  const { features, date, name, reward, stage, types, place, entityProps, achievementImage } = entity;

  const isApproved = entityProps.flags?.includes('approved') ?? false;
  const isPartiallyApproved = entityProps.flags?.includes('partiallyApproved') ?? false;

  const rewardElement = reward && <Reward name={reward.result} date={reward.rewardDate} image={reward.image} />;
  const compactRewardElement = reward && <RewardCompact isApproved={isApproved} image={reward.image} />;

  const achievementElement = achievementImage && <Achievement image={achievementImage} />;
  const compactAchievementElement = achievementImage && (
    <AchievementCompact isApproved={isApproved} image={achievementImage} />
  );

  const entityRightElement = achievementImage ? achievementElement : rewardElement;
  const entityCompactRightElement = achievementImage ? compactAchievementElement : compactRewardElement;

  return (
    <BaseCard
      tags={features}
      title={name}
      stage={stage}
      types={types}
      place={place ?? undefined}
      isApproved={!isCompactReward && isApproved}
      isPartiallyApproved={isPartiallyApproved}
      dateElement={date}
      rightElement={isCompactReward ? entityCompactRightElement : entityRightElement}
      onView={onView}
    />
  );
};

export default CommonEntityCard;
