import { FC, PropsWithChildren, ReactNode } from 'react';

import { Box, Typography, useMediaQuery } from '@mui/material';
import { commonTheme } from 'portfolio3/styles/theme';

import { IRatingTab } from '../../types';
import RatingChart from '../RatingChart';
import { IChartRow } from '../RatingChart/ChartRow';
import RatingHeader, { IRatingHeader } from '../RatingHeader';
import RatingTabs from '../RatingTabs';
import * as styles from './style';

interface IRatingProps {
  header: IRatingHeader;
  tabs: IRatingTab[];
  tabValue: number;
  onTabChange: (value: number) => void;
  chartRows: IChartRow[];
  caption: string;
  chartFullWidth?: boolean;
  isDiagnosticRating?: boolean;
  InfoBox?: ReactNode;
}

interface IRatingHeaderComponent {
  header: IRatingHeader;
  combined?: boolean;
}

interface IRatingTitleRow {
  isMobile?: boolean;
}

const RatingHeaderWrapper: FC<IRatingHeaderComponent & PropsWithChildren> = ({ combined, header, children }) => {
  const headerElement = combined ? (
    <RatingHeader sx={{ flexDirection: 'column' }} {...header}>
      {children}
    </RatingHeader>
  ) : (
    <>
      <RatingHeader {...header} />
      {children}
    </>
  );
  return headerElement;
};

const RatingTitleRow: FC<IRatingTitleRow> = ({ isMobile }) => {
  const titleContent = isMobile ? 'Место и результат' : 'Место';
  return (
    <Box sx={styles.chartTitleRow(isMobile)} className="rating-title-row">
      <Box sx={styles.chartTitlePlaceBar} className="rating-title-placeBar">
        <Typography variant="Paragraph MD/Semi Bold">{titleContent}</Typography>
      </Box>
      {!isMobile && (
        <Box sx={styles.resultContainer} className="rating-title-resultBar">
          <Typography variant="Paragraph MD/Semi Bold">Результат</Typography>
        </Box>
      )}
    </Box>
  );
};

const Rating: FC<IRatingProps> = ({
  header,
  tabs,
  tabValue,
  onTabChange,
  chartRows,
  chartFullWidth,
  isDiagnosticRating,
  InfoBox,
  caption,
}) => {
  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'), { noSsr: true });

  return (
    <>
      <RatingHeaderWrapper header={header} combined={isMobile}>
        <Box sx={styles.tabsContainer}>
          <RatingTabs tabs={tabs} value={tabValue} onChange={onTabChange} />
        </Box>
      </RatingHeaderWrapper>

      {InfoBox && <Box sx={{ marginTop: '16px' }}>{InfoBox}</Box>}

      <Box sx={{ marginTop: '16px' }}>
        {isDiagnosticRating && <RatingTitleRow isMobile={isMobile} />}
        <RatingChart fullWidth={chartFullWidth} rows={chartRows} horizontalCaption={caption} />
      </Box>
    </>
  );
};

export default Rating;
