import { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconCloudUploadOutline: FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M12 3a6.018 6.018 0 0 1 5.675 4.045A5.008 5.008 0 0 1 22 12a4.995 4.995 0 0 1-1.25 3.309 1 1 0 1 1-1.5-1.325A2.993 2.993 0 0 0 20 12c0-1.654-1.346-3-3-3h-.1a1 1 0 0 1-.98-.803A4.006 4.006 0 0 0 12 5a4.005 4.005 0 0 0-3.92 3.197A1.001 1.001 0 0 1 7.1 9H7c-1.654 0-3 1.346-3 3 0 .732.266 1.438.75 1.984a1 1 0 1 1-1.499 1.325A4.991 4.991 0 0 1 2 11.999a5.008 5.008 0 0 1 4.325-4.954A6.02 6.02 0 0 1 12 3Zm-.695 8.28a1.003 1.003 0 0 1 1.402.013l3 3a.999.999 0 1 1-1.414 1.414L13 14.414V20a1 1 0 1 1-2 0v-5.644l-1.305 1.26a1 1 0 0 1-1.39-1.439l3-2.897Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconCloudUploadOutline;
