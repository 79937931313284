import { FC, ReactNode } from 'react';

import { Box, Typography } from '@mui/material';
import { IconChevronSmallRight } from 'icons';
import { NeutralColors } from 'portfolio3/styles';
import { getButtonStyles } from 'portfolio3/ui-kit/utils';
import { SxStyles } from 'types';

const styles: SxStyles = {
  ...getButtonStyles(),

  display: 'flex',
  alignItems: 'center',
  gap: '6px',

  width: '100%',
  paddingBlock: '16px',
  borderBottom: '1px solid',
  borderColor: NeutralColors.light_neutrals_300,

  svg: {
    width: '20px',
    height: '20px',
    color: NeutralColors.light_neutrals_600,
  },
};

interface ISectionButtonProps {
  icon: ReactNode;
  text: string;
  onClick: () => void;
}

const SectionButton: FC<ISectionButtonProps> = ({ icon, text, onClick }) => {
  return (
    <Box className="section-button" component="button" sx={styles} onClick={onClick}>
      {icon}
      <Typography variant="Paragraph MD/Regular">{text}</Typography>
      <IconChevronSmallRight sx={{ marginLeft: 'auto' }} />
    </Box>
  );
};

export default SectionButton;
