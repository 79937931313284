import { SxStyles } from 'types';

export const root: SxStyles = (theme) => ({
  display: 'grid',
  gridAutoFlow: 'row',
  gridTemplateColumns: 'minmax(0, 1fr) 256px',
  gap: '8px',

  marginTop: '8px',

  [theme.breakpoints.down('commonSm')]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    marginTop: '12px',
  },
});
