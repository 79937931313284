/* eslint-disable prettier/prettier */
/* eslint-disable */

import { DropResult } from 'react-beautiful-dnd';
import {
  IBubblePopperData, IDragElements, IDragItem, ISectionTheme, TContainerKeys, TDirectionsKeys
} from './types';

export const removeFromList = (list: IDragItem[], index: number): [IDragItem, IDragItem[]] => {
  const result = Array.from(list);
  const [removed] = result.splice(index, 1);
  return [removed, result];
};

export const addToList = (list: IDragItem[], index: number, element: IDragItem) => {
  const result = Array.from(list);
  result.splice(index, 0, element);
  return result;
};

export const generateListWithNewElement = (currentList: IDragElements, result: DropResult, shouldAddOnTop: boolean) => {
  if (!result.destination
    || (result.source.droppableId !== 'insipid' && result.source.droppableId !== 'unknown' && result.source.droppableId !== 'sipid' && result.source.droppableId !== 'initial')
    || (result.destination.droppableId !== 'insipid' && result.destination.droppableId !== 'unknown' && result.destination.droppableId !== 'sipid' && result.destination.droppableId !== 'initial')
  ) {
    return currentList;
  }
  const listCopy = { ...currentList };

  const sourceList = listCopy[result.source.droppableId];
  const [removedElement, newSourceList] = removeFromList(
    sourceList,
    result.source.index
  );
  listCopy[result.source.droppableId] = newSourceList;
  const destinationList = listCopy[result.destination.droppableId];
  listCopy[result.destination.droppableId] = addToList(
    destinationList,
    shouldAddOnTop ? destinationList.length : result.destination.index,
    removedElement
  );
  return listCopy;
};

export const generateNewListOnClick = (currentList: IDragElements, sourceId: TDirectionsKeys, sourceIndex: number, destinationId: TContainerKeys, shouldAddOnBottom?: boolean) => {
  const listCopy = { ...currentList };

  const sourceList = listCopy[sourceId];
  const [removedElement, newSourceList] = removeFromList(
    sourceList,
    sourceIndex
  );
  listCopy[sourceId] = newSourceList;
  const destinationList = listCopy[destinationId];
  listCopy[destinationId] = addToList(
    destinationList,
    shouldAddOnBottom ? 0 : destinationList.length,
    removedElement
  );

  return listCopy;
};

export const initialBubblePopperData: IBubblePopperData = {
  bubbleName: undefined,
  isOpen: false,
  actions: [],
  ref: undefined,
  bubbleId: undefined
};
