import { FC, ReactNode } from 'react';

import { Box, Typography } from '@mui/material';
import { UsePaginationItem } from '@mui/material/usePagination';
import { IconChevronSmallLeft, IconChevronSmallRight } from 'icons';
import { mergeSx } from 'utils';

import * as styles from './styles';

interface IPaginationButtonProps {
  paginationItem: UsePaginationItem;
}

const PaginationButton: FC<IPaginationButtonProps> = ({ paginationItem }) => {
  const { type, page, selected, onClick, disabled } = paginationItem;

  let content: ReactNode | null = null;

  switch (type) {
    case 'previous': {
      content = <IconChevronSmallLeft sx={styles.navigationArrow} />;
      break;
    }
    case 'next': {
      content = <IconChevronSmallRight sx={styles.navigationArrow} />;
      break;
    }
    case 'start-ellipsis':
    case 'end-ellipsis': {
      content = <Typography variant="Paragraph MD/Medium">...</Typography>;
      break;
    }
    case 'page': {
      content = (
        <Typography variant="Paragraph MD/Regular" sx={selected ? styles.selectedContent : null}>
          {page}
        </Typography>
      );
      break;
    }
    default:
      content = null;
  }

  const clickTypes: UsePaginationItem['type'][] = ['page', 'previous', 'next'];
  const shouldClick = clickTypes.includes(type);
  const isDots = type === 'start-ellipsis' || type === 'end-ellipsis';

  if (content === null) return null;

  return (
    <Box
      component="button"
      onClick={shouldClick ? onClick : undefined}
      sx={mergeSx(styles.paginationButton, selected ? styles.selectedButton : null)}
      disabled={disabled || isDots}
    >
      {content}
    </Box>
  );
};

export default PaginationButton;
