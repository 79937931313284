import { createSelector } from 'reselect';

import { IStudentEvent, IStudentProject, IStudentReward, IStudentSportReward } from '../../api/types';
import { EVENT_LEVEL, SectionCodeVisibilitySettings } from '../../const';
import { IRootState } from '../../reducers';
import { IViewVisibilitySetting } from '../../types';
import { findTargetVisibilitySetting } from '../../utils/localVisibilitySettingsService';
import { linkVisibilitySettingsSelector, visibilitySettingsSelector } from '../visibilitySettings';
import { getSectionCodeRewardSettingSubsectionTypeCode, isStudentEntityProject } from './utils';

/**
 * Фильтрует награды по выбранным мероприятиям
 * @param rewards
 * @param eventMapping
 * @param visibilitySettings
 * @returns
 */
const getFilteredRewards = (
  rewards: (IStudentReward | IStudentSportReward)[],
  eventMapping: Map<number, IStudentEvent | IStudentProject>,
  visibilitySettings: IViewVisibilitySetting[],
) => {
  return rewards.filter((reward) => {
    const boundEntity = eventMapping.get(Number(reward.entityId));
    if (!boundEntity) return false;

    const sectionCode = boundEntity.categoryCode;
    const { sectionTypeCode: settingSectionTypeCode } = SectionCodeVisibilitySettings[sectionCode];

    const settingSubsectionTypecode = getSectionCodeRewardSettingSubsectionTypeCode(sectionCode);

    if (!settingSubsectionTypecode) return false;

    const subsectionSetting = findTargetVisibilitySetting(visibilitySettings, [
      settingSectionTypeCode,
      settingSubsectionTypecode,
    ]);

    return subsectionSetting?.computedVisibility;
  });
};

/**
 * Проверяет что уровень мероприятия соответствует "Всероссийский" или "Международный"
 * @param entity мероприятие или проект
 * @returns true если мероприятие соответствует фильтру, иначе false
 */
const filterEntityEventLevel = <T extends IStudentEvent | IStudentProject>(entity: T): boolean => {
  const eventLevel = isStudentEntityProject(entity)
    ? entity.levelProject?.code
    : (entity as IStudentEvent).levelEvent?.code;
  return eventLevel === EVENT_LEVEL.RUSSIAN || eventLevel === EVENT_LEVEL.INTERNATIONAL;
};

/**
 * Возвращает награды которые привязаны к мероприятиям с уровнем "Всероссийский" или "Международный"
 * Учитывая настройки видимости
 */
const filteredRewardsSelector = createSelector(
  [
    (state: IRootState) => state.studentRewards,
    (state: IRootState) => state.studentSportRewards,
    (state: IRootState) => state.studentEvents,
    (state: IRootState) => state.studentProjects,
    (state: IRootState) => state.linkMode.mode,
    (state: IRootState) => visibilitySettingsSelector(state),
    (state: IRootState) => linkVisibilitySettingsSelector(state),
  ],
  (
    commonRewards,
    sportRewards,
    events,
    projects,
    isLinkMode,
    viewVisibilitySettings,
    viewLinkVisibilitySettings,
  ): (IStudentReward | IStudentSportReward)[] => {
    const entityIdToEntityMapping = new Map<number, IStudentEvent | IStudentProject>();

    const studentActivities = [...events.content, ...projects.content].filter(filterEntityEventLevel);

    studentActivities.forEach((entity) => {
      if (entity.id) {
        entityIdToEntityMapping.set(entity.id, entity);
      }
    });

    const rewards = [
      ...commonRewards.content.filter((reward) => reward.entityId),
      ...sportRewards.content.filter((reward) => reward.entityId),
    ];

    const visibilitySettings = isLinkMode ? viewLinkVisibilitySettings : viewVisibilitySettings;
    return getFilteredRewards(rewards, entityIdToEntityMapping, visibilitySettings);
  },
);

export default filteredRewardsSelector;
