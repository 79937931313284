import { cardIllustrations } from 'images';
import { createSelector } from 'reselect';

import { IStudentReward, IStudentSportReward } from '../../api/types';
import { EntityType, SectionCodes, SourceCode } from '../../const';
import { IRootState } from '../../reducers';
import { IEntity, IModifiedReward } from '../../types';
import {
  formatDate,
  getEntityCategoryCodeEventIllustration,
  getEntityCategoryCodeRewardIllustration,
  getEntityGeneralInfo,
  getEntityHeaderText,
  isDefined,
} from '../../utils';
import filteredRewardsSelector from './filteredRewardsSelector';
import rewardEntitiesSelector from './rewardEntitiesSelector';
import { getRewardImage, getSportRewardImage, isRewardSport } from './utils';

const mapStudentRewards = (
  boundRewards: (IStudentReward | IStudentSportReward)[],
  events: IEntity[],
): IModifiedReward[] => {
  const rewardEntityIdToEventMapping = new Map<number, IEntity>();
  const rewards = boundRewards
    .filter((reward) => {
      const rewardEntityId = Number(reward.entityId);
      const rewardEvent = events.find(
        (event) => event.id === rewardEntityId || Number(event.recordId) === rewardEntityId,
      );

      if (rewardEvent !== undefined) {
        rewardEntityIdToEventMapping.set(rewardEntityId, rewardEvent);
      }
      return rewardEvent !== undefined;
    })
    .map((reward): IModifiedReward => {
      const { id, recordId, name, date, description, rewardType, typeCode, categoryCode } = reward as IStudentReward;

      const rewardTypeCode = isRewardSport(reward) ? reward.sportRewardCode : rewardType?.code;
      const rewardName = isRewardSport(reward) ? reward.name : rewardType?.value;
      const rewardImage = isRewardSport(reward) ? getSportRewardImage(rewardTypeCode) : getRewardImage(rewardName);

      const rewardEntityId = Number(reward.entityId);
      const rewardEvent = rewardEntityIdToEventMapping.get(rewardEntityId);
      let awardType = '';
      if (rewardEvent && rewardEvent.features[0].isColored) {
        awardType = rewardEvent.features[0].text;
      }

      const isSportReward = isRewardSport(reward);

      const linkedEventIllustration = getEntityCategoryCodeEventIllustration(categoryCode);
      const linkedSportEventIllustration =
        rewardEvent?.formData?.dataKind === SectionCodes.sportEventCompetition
          ? cardIllustrations.sportCompetition
          : cardIllustrations.sportExpedition;

      return {
        id,
        recordId,
        name,
        // awardDate: formatDate(date),
        awardDate: isDefined(date) ? formatDate(date) : '',
        awardType,
        description: rewardEvent?.name || description || '',
        image: rewardImage,
        formData: reward,
        cardData: {
          name,
          files: reward.fileReferences,
          linkedObjects: reward.linkedObjects,
          generalInfo: getEntityGeneralInfo(reward),
          formData: reward,
          illustrationFallback: getEntityCategoryCodeRewardIllustration(categoryCode),
          event: rewardEvent && {
            cardData: {
              name: rewardEvent.name,
              files: rewardEvent.formData.fileReferences,
              linkedObjects: rewardEvent?.formData.linkedObjectIds,
              generalInfo: getEntityGeneralInfo(rewardEvent.formData),
              formData: rewardEvent.formData,
              illustrationFallback: isSportReward ? linkedSportEventIllustration : linkedEventIllustration,
            },
            type: getEntityHeaderText(typeCode || rewardType?.code),
          },
        },
        isApproved: reward.source?.code !== SourceCode.student && reward.source?.code !== SourceCode.parent,
        entityType: isSportReward ? EntityType.SPORT_REWARD : EntityType.REWARD,
      };
    });

  return rewards;
};

/**
 * Формирует данные для отображения карточек наград на странице наград
 */
const cardRewardsSelector = createSelector(
  [(state: IRootState) => filteredRewardsSelector(state), (state: IRootState) => rewardEntitiesSelector(state)],
  (filteredRewards, rewardEntities): IModifiedReward[] => {
    return mapStudentRewards(filteredRewards, rewardEntities);
  },
);

export default cardRewardsSelector;
