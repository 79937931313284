/* eslint-disable prettier/prettier */
/* eslint-disable */

import { FC, PropsWithChildren } from 'react';

import {
  IconButton, PopperPlacementType
} from '@mui/material';

import { iconClose } from '../../../../icons';

import { Button } from '../../../../ui-kit';

import './index.scss';
import Popper from '../../../common/Popper';

interface IAnnouncementProps {
  isAnnouncementOpen: boolean,
  anchorElement: HTMLElement | null,
  title: string,
  titleColor: string,
  buttonText: string,
  buttonColor: string,
  hasControls: boolean,
  className?: string,
  hasCustomArrow?: boolean,
  placement?: PopperPlacementType,
  onCloseAnnouncement: () => void
}

const CommonAnnouncement: FC<IAnnouncementProps & PropsWithChildren> = ({
  isAnnouncementOpen,
  anchorElement,
  title,
  titleColor,
  buttonText,
  buttonColor,
  className,
  hasCustomArrow,
  hasControls,
  placement,
  children,
  onCloseAnnouncement
}) => (
  <Popper
    open={isAnnouncementOpen}
    anchorEl={anchorElement}
    placement={placement || 'bottom-start'}
    className={className || 'announcement'}
    onClosePopperMenu={onCloseAnnouncement}
    role={undefined}
    transition
    disablePortal
    popperOptions={{
    }}
    modifiers={[
      {name: 'preventOverflow', enabled: true},
      {name: 'arrow', enabled: true},
    ]}
  >
    <div>
      <p className="announcement__title" style={{ color: titleColor }}>{title}</p>
      {!hasCustomArrow && <div data-popper-arrow className="announcement__arrow" />}
      {hasControls && (
      <IconButton
        className="announcement__btn-close"
        disableTouchRipple
        onClick={onCloseAnnouncement}
        size="large">
        <img src={iconClose} alt="закрыть" />
      </IconButton>
      )}
      {children}
      {hasControls && (
      <Button
        className="announcement__button"
        style={{ backgroundColor: buttonColor }}
        onClick={onCloseAnnouncement}
      >
        {buttonText}
      </Button>
      )}
    </div>
  </Popper>
);

export default CommonAnnouncement;
