/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useContext, useEffect, useState } from 'react';

import ShowMore from 'components/common/ShowMore';
import { SubjectDetails } from '../SubjectDetails';
import SubjectDetailsEmpty from '../SubjectDetailsEmpty';
import SubjectTopicsEmpty from '../SubjectTopicsEmpty';
import ResultsLoader from '../ResultsLoader';
import SubjectHint from '../SubjectHint';
import { AverageResultTile, SubjectResultTile } from './tile';
import { IPerformanceSubjectTopicInfo, IPerformanceSubjectInfo, MarkType, IPerformanceYear } from '../../types';
import { PerformanceSubjectContext } from '../../context';

import { Box, useMediaQuery } from '@mui/material';
import { commonTheme } from 'portfolio3/styles/theme';
import { emitYMEvent } from 'portfolio3/features/yandexMetrika';

import './index.scss';

interface IPerformanceTilesProps {
  markType: MarkType;
  performanceData?: IPerformanceYear;
  subjectTopic?: IPerformanceSubjectTopicInfo;
  isTopicsLoading: boolean;
  onOpenDetailedInfoSubject: (a: boolean) => void;
}

interface ITilePerformanceData {
  averageResult?: number;
  subjects: IPerformanceSubjectInfo[];
}

const PerformanceTiles: React.FC<IPerformanceTilesProps> = ({
  markType,
  performanceData,
  subjectTopic,
  isTopicsLoading,
  onOpenDetailedInfoSubject,
}) => {
  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const initialSubjectsDisplayCount = 5;
  const [performanceTileData, setPerformanceTitleData] = useState<ITilePerformanceData>();
  const { selectedSubject, setSelectedSubject } = useContext(PerformanceSubjectContext);
  const [subjectsDisplayCount, setSubjectsDisplayCount] = useState(initialSubjectsDisplayCount);

  const isAverageMark = markType === MarkType.AVERAGE;
  const totalSubjectsCount = performanceTileData?.subjects?.length || 0;
  const hiddenSubjectsCount = totalSubjectsCount - initialSubjectsDisplayCount;
  const isAllSubjectsShown = totalSubjectsCount === subjectsDisplayCount;

  const subjectTopicsCount = subjectTopic?.totalTopics || 0;

  const isVisibleDetails = selectedSubject && (subjectTopicsCount > 0 || isTopicsLoading);
  const isEmptyTopics = selectedSubject && subjectTopicsCount === 0 && !isTopicsLoading;

  useEffect(() => {
    if (!performanceData) return;
    setPerformanceTitleData({
      averageResult: isAverageMark ? performanceData.averageAllMarks : performanceData.averageAllYearMarks,
      subjects: performanceData.subjects.map((subject) => ({
        name: subject.subjectName,
        mark: isAverageMark ? subject.averageMark : subject.averageYearMark
      }))
    });
  }, [performanceData, markType]);

  useEffect(() => {
    setSubjectsDisplayCount(initialSubjectsDisplayCount)
  }, [performanceData]);

  const renderSubjectDetails = () => {
    if (isVisibleDetails && selectedSubject) {
      return (
        <SubjectDetails
          subject={selectedSubject.name}
          subjectResult={selectedSubject.mark}
          subjectTopic={subjectTopic}
        />
      );
    }

    if (isEmptyTopics) return <SubjectTopicsEmpty />;

    return <SubjectDetailsEmpty />;
  };

  const handleSubjectTileClick = (subject: IPerformanceSubjectInfo) => {
    // нужно для более плавного обновления боковой панели при смене предмета
    requestAnimationFrame(() => {
      setSelectedSubject(subject);
      onOpenDetailedInfoSubject(true);
    });
  }

  const handleShowAllSubjects = () => {
    emitYMEvent({ type: 'listItemsDisclosure' });
    setSubjectsDisplayCount(totalSubjectsCount);
  };
  const handleShowInitialSubjects = () => setSubjectsDisplayCount(initialSubjectsDisplayCount);

  return (
    <div className="performance-tiles-wrapper">
      <div className="performance-tiles">
        <div className="performance-tiles__results-container">
          {performanceTileData?.averageResult !== undefined && (
            <AverageResultTile
              doughuntValue={performanceTileData.averageResult}
            />
          )}
          {performanceTileData?.subjects && totalSubjectsCount > 0 && (
            <div className="performance-tiles__subject-results">
              {performanceTileData.subjects?.slice(0, subjectsDisplayCount).map((subject) => (
                <div key={subject.name} onClick={() => handleSubjectTileClick(subject)}>
                  <SubjectResultTile
                    doughuntValue={subject.mark}
                    description={subject.name}
                    selected={selectedSubject?.name === subject.name}
                  />
                </div>
              ))}
              <ShowMore
                hiddenCount={hiddenSubjectsCount}
                isAllShown={isAllSubjectsShown}
                onShowInitialItems={handleShowInitialSubjects}
                onShowAllItems={handleShowAllSubjects}
              />
            </div>
          )}
        </div>

        <div className="performance-tiles__details-container">
          {isTopicsLoading ? <ResultsLoader /> : renderSubjectDetails()}
        </div>
      </div>
      {isMobile && (
        <Box marginTop="12px">
          <SubjectHint />
        </Box>
      )}
    </div>
  );
};

export default PerformanceTiles;
