/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import clsx from 'clsx';

import { Radio as RadioMaterial, RadioProps } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { Colors } from '../../style/variables';

const useStyles = makeStyles({
  radio: {
    borderRadius: '50%',
    width: 20,
    height: 20,
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  icon: {
    borderRadius: '50%',
    width: 20,
    minWidth: 20,
    height: 20,
    boxShadow: 'none',
    border: `1px solid ${Colors['control-stroke']}`,
    backgroundColor: Colors.white,
    'input:hover ~ &': {
      border: `1px solid ${Colors['blue-primrose']}`
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      border: `1px solid ${Colors['control-stroke']}`,
      background: Colors['disable-input']
    }
  },
  checkedIcon: {
    border: `1px solid ${Colors['blue-primrose']}`,
    backgroundColor: Colors.white,
    '&:before': {
      display: 'block',
      borderRadius: '50%',
      marginTop: 4,
      marginLeft: 4,
      width: 12,
      height: 12,
      backgroundColor: Colors['blue-primrose'],
      content: '""'
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      border: `1px solid ${Colors['control-stroke']}`,
      background: Colors['disable-input']
    },
    'input:disabled ~ &:before': {
      backgroundColor: Colors['disable-text']
    }
  }
});

const Radio: React.FC<RadioProps> = (props) => {
  const classes = useStyles();

  return (
    <RadioMaterial
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      classes={{
        root: classes.radio
      }}
      {...props}
    />
  );
};

export default Radio;
