import { SxStyles } from 'types';

export const root: SxStyles = {
  display: 'flex',
  gap: '32px',
  paddingBlock: '12px',
};

export const routes: SxStyles = {
  display: 'flex',
  gap: '4px',
};
