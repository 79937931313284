import { ChangeEvent, forwardRef, SyntheticEvent } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { FormControl, InputAdornment } from '@mui/material';
import { Colors } from 'style/variables';

import { InputBase } from '../../inputs';
import { ISearchableMenuProps } from '../types';
import StyledSearchableMenu from './SearchableMenu.styled';

const SearchableMenu = forwardRef<HTMLDivElement, ISearchableMenuProps>(({ children, ...props }, ref) => {
  const { searchValue, onSearchChange, ...menuProps } = props;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    onSearchChange?.(value);
  };

  const stopEventPropagation = (e: SyntheticEvent) => {
    e.stopPropagation();
  };

  const endAdornment = (
    <InputAdornment position="end">
      <SearchIcon htmlColor={Colors['note-text']} />
    </InputAdornment>
  );

  return (
    <StyledSearchableMenu {...menuProps} ref={ref} MenuListProps={{ className: 'searchable-menu' }}>
      <div tabIndex={-1} className="searchable-menu__input-container">
        <FormControl sx={{ width: '100%' }}>
          <InputBase
            renderMode="fixed"
            size="small"
            className="searchable-menu__input"
            value={searchValue}
            onChange={handleChange}
            // для предотвращения перевода фокуса на input-container
            onKeyDown={stopEventPropagation}
            placeholder="Поиск..."
            endAdornment={endAdornment}
          />
        </FormControl>
      </div>
      <div className="searchable-menu__list">{children}</div>
    </StyledSearchableMenu>
  );
});

SearchableMenu.displayName = 'SearchableMenu';

export default SearchableMenu;
