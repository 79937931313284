import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { DiagnosticLevelColorSet2 } from 'const';
import { SxStyles } from 'types';
import { getDiagnosticLevelTypeColor, mergeSx } from 'utils';

import { IResultBar } from '../types';
import Bar from './Bar';
import * as styles from './style';

interface IResultBarProps extends IResultBar {
  sx?: SxStyles;
}

const ResultBar: FC<IResultBarProps> = ({ maxScore, studentScore, subjectLevel, sx }) => {
  const percent = Math.round((studentScore / maxScore) * 100);
  const color = getDiagnosticLevelTypeColor(subjectLevel, DiagnosticLevelColorSet2);

  return (
    <Box sx={mergeSx(styles.resultBar, { ...sx })} className="resultBar">
      <Box sx={styles.resultContent}>
        <Typography variant="Paragraph MD/Regular">{`${studentScore} из ${maxScore} (${percent}%)`}</Typography>
        <Typography variant="Paragraph SM/Regular">{subjectLevel.toLowerCase()}</Typography>
      </Box>
      <Bar percent={percent} color={color} />
    </Box>
  );
};

export default ResultBar;
