import { FC, useState } from 'react';

import { useMediaQuery } from '@mui/material';
import { IDictionaryItem } from 'api/types';
import { StudentSectionSettingTypeCodes, studyNavigation } from 'const';
import { useAppSelector, useBrightTheme, useDataLoad, useWidgetVisibility } from 'hooks';
import { IconBarChart, IconGridFilled, IconPieChartFilled } from 'icons';
import { placeholderCharacter, widgetIllustrations } from 'images';
import NoDataPlug from 'portfolio3/components/common/NoDataPlug';
import { WidgetContainer as Widget } from 'portfolio3/components/common/WidgetContainer';
import { WidgetContainerOptions, WidgetContainerTitle } from 'portfolio3/components/widgetContainerRecipes';
import { emitYMEvent } from 'portfolio3/features/yandexMetrika';
import { commonTheme } from 'portfolio3/styles/theme';
import { Switcher, SwitcherButton } from 'portfolio3/ui-kit';
import { getDataLoadRequests } from 'utils';

import PerformanceFilters from '../components/PerformanceFilters';
import { learningYearAveragePerformanceSelector, learningYearFinalPerformanceSelector } from '../model/selectors';
import { MarkType, VisibilityType } from '../types';
import { sortLearningYears } from '../utils';
import PerformanceContainer from './PerformanceContainer';

const sectionTypeCode = StudentSectionSettingTypeCodes.study;
const subsectionTypeCode = StudentSectionSettingTypeCodes.studyPerformance;

const PerformanceWidgetContainer: FC = () => {
  const averagePerformance = useAppSelector(learningYearAveragePerformanceSelector);
  const finalPerformance = useAppSelector(learningYearFinalPerformanceSelector);

  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  const isBrightTheme = useBrightTheme();
  const { isSettingsMode, isWidgetVisible, toggleElement, overlayElement } = useWidgetVisibility({
    sectionTypeCode,
    subsectionTypeCode,
  });

  useDataLoad({ shouldLoad: isWidgetVisible, requests: getDataLoadRequests().performanceEntryMethods });

  const [currentVisibilityType, setCurrentVisibilityType] = useState<VisibilityType>(VisibilityType.DIAGRAM);
  const initialFilterYear = 1;
  const [filterYear, setFilterYear] = useState(initialFilterYear);
  const [markType, setMarkType] = useState<MarkType>(MarkType.AVERAGE);

  const currentPerformance = markType === MarkType.AVERAGE ? averagePerformance : finalPerformance;
  const isLoading = currentPerformance.loading;

  const hasLearningYears = currentPerformance.content.length > 0;
  const hasAnySubjects = currentPerformance.content.flatMap((performance) => performance.subjects).length > 0;
  const isOptionsVisible = hasLearningYears && !isLoading;

  // общая заглушка - нет объектов в массиве years ИЛИ (нет подходящих average subjects И нет подходящих final subjects)
  const hasNoDataPlug = !hasLearningYears || !hasAnySubjects;

  const learningYears = currentPerformance.content.map((performance) => performance.year);
  const sortedLearningYears = sortLearningYears(learningYears, 'desc');
  const learningYearOptions: IDictionaryItem[] = sortedLearningYears.map((year, index) => ({
    code: index + 1,
    value: year,
  }));

  const mappedLearningYearOptions = learningYearOptions.map((option) => {
    return {
      code: option.code,
      value: option.value + ' уч. год',
    };
  });

  const currentLearningYear = learningYearOptions.find((year) => year.code === filterYear)?.value;

  const showYearSelection =
    currentVisibilityType === VisibilityType.TILES || currentVisibilityType === VisibilityType.DIAGRAM;

  // Обертка над setMarkType, для проверки наличия в новых данных текущего года
  // При отсутствии года в новых данных будет установлен первый год из списка
  const handleChangeMarkType = (type: MarkType) => {
    emitYMEvent({ type: 'averageTotalStudySwitching' });

    setMarkType(type);

    const nextPerformanceData = type === MarkType.AVERAGE ? averagePerformance : finalPerformance;
    const learningYears = nextPerformanceData.content.map((performance) => performance.year);
    const sortedLearningYears = sortLearningYears(learningYears, 'desc');

    if (!currentLearningYear || !sortedLearningYears.includes(currentLearningYear)) {
      setFilterYear(initialFilterYear);
    }
  };

  const handleVisibilityTypeChange = (type: VisibilityType) => {
    const typeToNameMapping: Record<VisibilityType, 'radarChart' | 'gridTiles' | 'lineChart'> = {
      [VisibilityType.DIAGRAM]: 'radarChart',
      [VisibilityType.TILES]: 'gridTiles',
      [VisibilityType.CHART]: 'lineChart',
    };
    emitYMEvent({
      type: 'graphViewSwitching',
      payload: {
        typeChart: typeToNameMapping[type],
      },
    });

    setCurrentVisibilityType(type);
  };

  const visibilityTypeSwitcherElement = (
    <Switcher
      value={currentVisibilityType}
      onChange={handleVisibilityTypeChange}
      componentSize={isMobile ? 'small' : 'medium'}
      stretchy={isMobile}
    >
      <SwitcherButton value={VisibilityType.DIAGRAM} content={<IconPieChartFilled />} disabled={hasNoDataPlug} />
      <SwitcherButton value={VisibilityType.TILES} content={<IconGridFilled />} disabled={hasNoDataPlug} />
      <SwitcherButton value={VisibilityType.CHART} content={<IconBarChart />} disabled={hasNoDataPlug} />
    </Switcher>
  );

  const handleChangeFilterYear = (year: number) => {
    emitYMEvent({ type: 'yearStudySwitching' });
    setFilterYear(year);
  };

  const filtersElement = (
    <PerformanceFilters
      markType={markType}
      onChangeMarkType={handleChangeMarkType}
      year={filterYear}
      onChangeYear={handleChangeFilterYear}
      yearOptions={mappedLearningYearOptions}
      withYearSelector={showYearSelection}
      isMobile={isMobile}
      isDisabled={hasNoDataPlug}
    />
  );

  const generalNoDataPlug = (
    <NoDataPlug
      image={placeholderCharacter}
      title="Данных по предметным результатам пока нет"
      subtitle="Как только они появятся, информация о них отобразится здесь"
      loading={isLoading}
    />
  );

  if (!isWidgetVisible) return null;

  return (
    <Widget containerData={studyNavigation.performance} withBackground={isBrightTheme}>
      {overlayElement}
      <Widget.Header
        title={<WidgetContainerTitle title="Предметные результаты" right={isSettingsMode && toggleElement} />}
        options={
          isOptionsVisible && <WidgetContainerOptions left={visibilityTypeSwitcherElement} right={filtersElement} />
        }
        backgroundImage={widgetIllustrations.studyPerformance}
      />
      <Widget.Padding>
        {hasNoDataPlug && generalNoDataPlug}

        {!hasNoDataPlug && (
          <PerformanceContainer
            currentPerformance={currentPerformance}
            currentVisibilityType={currentVisibilityType}
            currentLearningYear={currentLearningYear ?? null}
          />
        )}
      </Widget.Padding>
    </Widget>
  );
};

export default PerformanceWidgetContainer;
