import { FC, useContext } from 'react';

import { useMediaQuery } from '@mui/material';
import { IIndependentDiagnosticInfo } from 'api/types';
import { WidgetContainer } from 'portfolio3/components/common/WidgetContainer';
import { commonTheme } from 'portfolio3/styles/theme';
import { isDefined } from 'utils';

import AnalyticsDiagrams from '../components/AnalyticsDiagrams';
import DiagnosticContext from '../context/diagnosticContext';
import { IDiagnosticChartData } from '../DynamicSubjectChart/types';
import ResultDynamics from '../ResultDynamics';
import { getChartLevelType } from '../utils';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IIndependentAnalysisContainer {}

const IndependentAnalysisContainer: FC<IIndependentAnalysisContainer> = () => {
  const { independentDiagnostics } = useContext(DiagnosticContext);
  const isMobile = useMediaQuery(commonTheme.breakpoints.down('commonSm'));

  /* формирование данных для графика */
  const flatDiagnostics = independentDiagnostics.content
    ?.flatMap((group) => group.diagnostics.diagnostic)
    .filter(isDefined);
  const subjectToDiagnosticsMap = new Map<string, IIndependentDiagnosticInfo[]>();
  flatDiagnostics?.forEach((diagnostic) => {
    const { subject } = diagnostic;
    const subjectDiagnostics = subjectToDiagnosticsMap.get(subject) || [];
    subjectDiagnostics.push(diagnostic);
    subjectToDiagnosticsMap.set(subject, subjectDiagnostics);
  });

  const validSubjects = Array.from(subjectToDiagnosticsMap.entries())
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .filter(([_subject, diagnostics]) => {
      return diagnostics.length >= 2;
    });

  const dynamicSubjectChartData = validSubjects
    .map(([subject, diagnostics]): IDiagnosticChartData | null => {
      // лучшие диагностики по предмету за каждый год
      const bestYearSubjectDiagnosticMap = new Map<number, IIndependentDiagnosticInfo>();
      diagnostics.forEach((diagnostic) => {
        const year = new Date(diagnostic.eventDate).getFullYear();
        const yearDiagnostic = bestYearSubjectDiagnosticMap.get(year);

        if (!yearDiagnostic || diagnostic.percentResult > yearDiagnostic.percentResult) {
          bestYearSubjectDiagnosticMap.set(year, diagnostic);
        }
      });

      // фильтруем предметы, по которым есть запись только в одном году
      if (bestYearSubjectDiagnosticMap.size < 2) return null;

      const sortedYearDiagnostics = Array.from(bestYearSubjectDiagnosticMap.entries()).sort(([left], [right]) => {
        return left - right;
      });

      return {
        name: subject,
        values: sortedYearDiagnostics.map(([year, diagnostic]) => ({
          value: diagnostic.resultValue,
          interval: String(year),
          levelType: getChartLevelType(diagnostic.levelType),
        })),
      };
    })
    .filter(isDefined);
  const subjectNames = dynamicSubjectChartData.map((chartData) => chartData.name);

  const isSubjectDynamicChartVisible = subjectNames.length > 0;

  return (
    <>
      <WidgetContainer.Divider sx={{ marginTop: 0 }} />
      {/* 2 виджета - пройдено и лучший результат */}
      <WidgetContainer.Padding>
        <AnalyticsDiagrams />
      </WidgetContainer.Padding>
      {/* график с динамикой по предметам */}
      {isSubjectDynamicChartVisible && (
        <WidgetContainer.Padding sx={{ marginTop: isMobile ? '12px' : '16px' }}>
          <ResultDynamics subjects={subjectNames} results={dynamicSubjectChartData} />
        </WidgetContainer.Padding>
      )}
    </>
  );
};

export default IndependentAnalysisContainer;
