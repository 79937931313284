/* eslint-disable prettier/prettier */
/* eslint-disable */

import React, { useMemo } from 'react';

import { connect } from 'react-redux';

import PdfCommonDataEntityContainer from '../common';
import { PdfCommonDataEntity } from '../../../../components/pdfStudent/DataEntity';
import { ICommonResponse } from '../../../../api/types';
import { IRootState } from '../../../../reducers';
import { widgetPdfIcons } from '../../../../icons';
import { civilSectionsSelector } from '../../../../selectors';
import { IEntity } from '../../../../types';

import '../index.scss';
import { useDataLoad } from 'hooks';
import { getDataLoadRequests } from 'utils';

interface IPdfCivilSectionContainerProps {
  studentSections: ICommonResponse<IEntity>;
}

const PdfCivilSectionContainer: React.FC<IPdfCivilSectionContainerProps> = ({
  studentSections
}) => {
  useDataLoad({ shouldLoad: true, requests: getDataLoadRequests().civilSections });

  const filteredSectionsYear = useMemo(() => (studentSections.content && studentSections.content?.length > 0
    ? studentSections.content?.reduce((prevItem, nextItem) => (prevItem.year > nextItem.year ? prevItem : nextItem)).year
    : undefined),
  [studentSections]);

  return (
    <PdfCommonDataEntityContainer
      title="Кружки"
      icon={widgetPdfIcons.pdfIconCivilUnits}
    >
      {studentSections.content
        .map((olympiad: IEntity) => (
          <PdfCommonDataEntity key={olympiad.id || olympiad.recordId} entity={olympiad} year={filteredSectionsYear} />
        ))}
    </PdfCommonDataEntityContainer>
  );
};

export default connect(
  (state: IRootState) => ({
    studentSections: civilSectionsSelector(state)
  })
)(PdfCivilSectionContainer);
