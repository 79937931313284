/* eslint-disable prettier/prettier */
/* eslint-disable */

export enum SubcategoryParentsKeys {
  SCIENCE = 'science',
  CIVIL = 'civil',
  SPORT = 'sport',
  CREATION = 'creation',
}

export const SubcategoryParentsCodes: Record<string, number> = {
  [SubcategoryParentsKeys.SCIENCE]: 1,
  [SubcategoryParentsKeys.CIVIL]: 2,
  [SubcategoryParentsKeys.SPORT]: 3,
  [SubcategoryParentsKeys.CREATION]: 4
};

export const DifferentCode = 7777;
export const DifferentValue = 'Другое';
export const OtherOrganizationCode = 10000;
export const OtherOrganization = 'Другая организация';
export const WithoutOrganizationCode = 10001;
export const WithoutOrganization = 'Без организации';
export const DifferentNameCode = 2;

export enum DifferentValuesKeys {
  OLYMPIAD_REWARD = 'olympiadReward',
  OLYMPIAD_NAME = 'olympiadName'
}

export enum StatusRewardKeys {
  CONSTANT = 'constant',
  ONCE = 'once',
  TEMPORARY = 'temporary'
}

export const StatusRewardCodes: Record<string, number> = {
  [StatusRewardKeys.CONSTANT]: 1,
  [StatusRewardKeys.ONCE]: 2,
  [StatusRewardKeys.TEMPORARY]: 3
};
