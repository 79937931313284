import { SxStyles } from 'types';

export const drawer: SxStyles = {
  width: '100vw',
  maxWidth: '724px',
};

export const formControlsColumn: SxStyles = (theme) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',

    [theme.breakpoints.down('commonSm')]: {
      gap: '4px',
    },
  };
};

export const formControlsRow: SxStyles = (theme) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',

    [theme.breakpoints.down('commonSm')]: {
      gap: '8px',
    },
  };
};
