import { CommonUiKitSize, InputRenderMode } from '../../types';
import { IInputTokens } from '../types';

export const InputHeight: Record<CommonUiKitSize, number> = {
  small: 36,
  medium: 40,
  large: 48,
};

export const InputFontSize: Record<CommonUiKitSize, number> = {
  small: 14,
  medium: 14,
  large: 15,
};

export const InputFontLineHeight: Record<CommonUiKitSize, number> = {
  small: 20,
  medium: 20,
  large: 24,
};

// -1 к вертикальным паддингами из-за бордеров
// нужно для установки более точноый высоты
export const InputBlockPadding: Record<CommonUiKitSize, number> = {
  small: 7,
  medium: 9,
  large: 11,
};

export const InputLeftPadding: Record<CommonUiKitSize, number> = {
  small: 12,
  medium: 12,
  large: 12,
};

export const InputRightPadding: Record<CommonUiKitSize, number> = {
  small: 12,
  medium: 12,
  large: 12,
};

export const getInputTokens = (size: CommonUiKitSize): IInputTokens => {
  return {
    minHeight: InputHeight[size],
    fontSize: InputFontSize[size],
    lineHeight: InputFontLineHeight[size],
    paddingBlock: InputBlockPadding[size],
    paddingLeft: InputLeftPadding[size],
    paddingRight: InputRightPadding[size],
  };
};

export const InputTokens: Record<InputRenderMode, IInputTokens> = {
  fixed: getInputTokens('medium'),
  floating: getInputTokens('medium'),
};
