import { SxStyles } from 'types';

export const entityNameContainer: SxStyles = {
  display: 'flex',
  gap: '8px',

  svg: {
    width: '20px',
    height: '20px',
  },
};

export const dateContainer: SxStyles = {
  display: 'flex',
  gap: '24px',
};
