import { Colors } from 'style/variables';
import { SxStyles } from 'types';
import { numberToPixelsString } from 'utils';

export const root: SxStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  padding: '32px 24px',

  '& img': {
    maxWidth: '100%',
  },

  '& a': {
    textDecoration: 'none',
    color: Colors['blue-primrose'],
  },

  '*:first-child': {
    marginTop: 0,
  },
};

export const headingText: SxStyles = (theme) => ({
  [theme.breakpoints.down('commonSm')]: {
    fontSize: '16px',
    lineHeight: '22px',
  },
});

export const text: SxStyles = {
  textAlign: 'center',
};

export const marginTop = (marginTop: number): SxStyles => ({
  marginTop: numberToPixelsString(marginTop),
});
