import { GET_ARCHIVED_INTEREST_OBJECT_KINDS } from '../../actions';
import { FAILURE, REQUEST, SUCCESS } from '../../actions/utils';
import { ICommonResponseObject, IStudentInterest } from '../../api/types';
import { connectReducers } from '../../utils';
import { IReducers } from '../index';

type InterestHeadToObjectKinds = Record<number, IStudentInterest[]>;
export type ArchivedInterestObjectKindsState = ICommonResponseObject<InterestHeadToObjectKinds>;

const initialState: ArchivedInterestObjectKindsState = {
  content: {},
  loading: false,
  requestCache: undefined,
};

const archivedInterestObjectKinds: IReducers<ArchivedInterestObjectKindsState> = {
  [GET_ARCHIVED_INTEREST_OBJECT_KINDS[REQUEST]]: (state) => ({
    ...state,
    loading: true,
    requestCache: 'requested',
  }),
  [GET_ARCHIVED_INTEREST_OBJECT_KINDS[SUCCESS]]: (state, action) => {
    return {
      ...state,
      content: action.payload,
      loading: false,
      requestCache: 'cached',
    };
  },
  [GET_ARCHIVED_INTEREST_OBJECT_KINDS[FAILURE]]: (state) => ({
    ...state,
    loading: false,
    requestCache: 'failed',
  }),
};

export default connectReducers(initialState, archivedInterestObjectKinds);
