import { FC } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { spoPlaceholders } from 'images';
import { NeutralColors } from 'portfolio3/styles';
import { Button } from 'portfolio3/ui-kit/button';
import { SxStyles } from 'types';

const imagStyles: SxStyles = (theme) => {
  return {
    width: '260px',
    height: '100%',

    [theme.breakpoints.down('commonSm')]: {
      width: '200px',
    },
  };
};

const ErrorSpo: FC = () => {
  const handleClickReloadPage = () => {
    window.location.reload();
  };

  return (
    <Stack gap="12px" alignItems="center" paddingBottom="16px">
      <Box component="img" src={spoPlaceholders.girlWithNotebookAndHeadphones} alt="Нет данных" sx={imagStyles} />

      <Stack gap="12px" alignItems="center">
        <Stack gap="4px" alignItems="center">
          <Typography variant="Headings/H6" textAlign="center">
            Не удалось получить данные
          </Typography>
          <Typography variant="Paragraph LG/Regular" color={NeutralColors.light_neutrals_600} textAlign="center">
            Попробуй обновить страницу
          </Typography>
        </Stack>

        <Button onClick={handleClickReloadPage}>Обновить</Button>
      </Stack>
    </Stack>
  );
};

export default ErrorSpo;
