import { FC, SyntheticEvent, useMemo, useRef, useState } from 'react';

import { MenuProps } from '@mui/material';
import TrimText from 'portfolio3/components/common/TrimText';
import { CheckboxMenuItem, ISearchableMenuProps, MenuEmptyResult, SearchableMenu } from 'portfolio3/ui-kit/menu';

import { IModalSelectBaseProps, ModalSelectBase } from '../../bases';
import { ISelectItem, ISelectStateController } from '../../types';
import RenderValue from './RenderValue';
import * as styles from './styles';

interface ITokenFieldSelectProps
  extends Omit<IModalSelectBaseProps<MenuProps>, 'ModalComponent' | 'ModalComponentProps' | 'children'> {
  options?: ISelectItem[];
}

const TokenFieldSelect: FC<ITokenFieldSelectProps> = ({ options, ...props }) => {
  const TypedSelect = ModalSelectBase<ISearchableMenuProps>;

  const stateRef = useRef<ISelectStateController>(null);

  const [searchValue, setSearchValue] = useState('');

  const currentValue = props.value;
  const stringCodes = typeof currentValue === 'string' && currentValue.length > 0 ? currentValue.split(',') : [];
  const numberCodes = Array.isArray(currentValue) ? currentValue : [];
  const hasSelectedOptions = stringCodes.filter(Boolean).length > 0 || numberCodes.filter(Boolean).length > 0;

  const singleOptions: (boolean | JSX.Element)[][] | undefined = useMemo(() => {
    const filteredOptions = options?.filter((option) => {
      if (searchValue.trim().length === 0) return true;
      return option.value.toLowerCase().includes(searchValue.toLowerCase());
    });

    return filteredOptions?.map((option) => {
      return [
        <CheckboxMenuItem key={option.code} value={option.code}>
          <TrimText>{option.value}</TrimText>
        </CheckboxMenuItem>,
      ];
    });
  }, [options, searchValue]);

  if (singleOptions?.length === 0) {
    singleOptions.push([<MenuEmptyResult key="empty" />]);
  }

  const handleOpen = (e: SyntheticEvent<Element, Event>) => {
    props.onOpen?.(e);
    setSearchValue('');
  };

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
  };

  const renderValue = (value: string | undefined) => {
    return (
      <RenderValue
        value={value}
        options={options ?? []}
        placeholder={props.placeholder ?? ''}
        onChangeValueState={props.controller.handleChange}
        inputSize={props.inputSize}
        inputRenderMode={props.inputRenderMode}
        isOpen={props.open ?? false}
        isDisabled={props.disabled ?? false}
      />
    );
  };

  return (
    <TypedSelect
      {...props}
      stateController={stateRef}
      multiple
      onOpen={handleOpen}
      ModalComponent={SearchableMenu}
      ModalComponentProps={{ searchValue, onSearchChange: handleSearchChange }}
      renderValue={renderValue}
      useFakeBoxComponent
      fakeInputSx={hasSelectedOptions ? styles.fakeInputNarrowPaddings : null}
    >
      {singleOptions}
    </TypedSelect>
  );
};

export default TokenFieldSelect;
