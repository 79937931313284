import { TypographyOptions } from '@mui/material/styles/createTypography';

import { NeutralColors } from '../colors';

const getTypographyOptions = (fontSize: number, lineHeight: number, fontWeight: number) => {
  return {
    fontSize,
    lineHeight: `${lineHeight}px`,
    fontWeight,
    color: NeutralColors.light_neutrals_900,
  };
};

export const typographySettings: TypographyOptions = {
  fontFamily: 'Inter',
  'Headings/Hero': getTypographyOptions(48, 72, 900),
  'Headings/H1': getTypographyOptions(32, 48, 700),
  'Headings/H2': getTypographyOptions(28, 42, 700),
  'Headings/H3': getTypographyOptions(24, 36, 700),
  'Headings/H3 Black': getTypographyOptions(24, 36, 900),
  'Headings/H4': getTypographyOptions(20, 32, 700),
  'Headings/H5': getTypographyOptions(18, 28, 600),
  'Headings/H6': getTypographyOptions(16, 24, 600),
  'Paragraph LG/Regular': getTypographyOptions(15, 24, 400),
  'Paragraph LG/Medium': getTypographyOptions(15, 24, 500),
  'Paragraph LG/Semi Bold': getTypographyOptions(15, 24, 600),
  'Paragraph LG/Bold': getTypographyOptions(15, 24, 700),
  'Paragraph MD/Regular': getTypographyOptions(14, 20, 400),
  'Paragraph MD/Medium': getTypographyOptions(14, 20, 500),
  'Paragraph MD/Semi Bold': getTypographyOptions(14, 20, 600),
  'Paragraph MD/Bold': getTypographyOptions(14, 20, 700),
  'Paragraph SM/Regular': getTypographyOptions(12, 16, 400),
  'Paragraph SM/Medium': getTypographyOptions(12, 16, 500),
  'Paragraph SM/Semi Bold': getTypographyOptions(12, 16, 600),
  'Paragraph SM/Bold': getTypographyOptions(12, 16, 700),
  'Paragraph TN/Regular': getTypographyOptions(10, 14, 400),
  'Paragraph TN/Medium': getTypographyOptions(10, 14, 500),
  'Paragraph TN/Semi Bold': getTypographyOptions(10, 14, 600),
  'Paragraph TN/Bold': getTypographyOptions(10, 14, 700),
};
