/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CREATE_STUDENT_INTEREST_LIST,
  CREATE_STUDENT_SHARE_LINK,
  EDIT_PORTFOLIO_REWARD,
  EDIT_PORTFOLIO_SPORT_REWARD,
  GRATITUDE_TEACHER,
  POST_PORTFOLIO_AFFILATION,
  POST_PORTFOLIO_EMPLOYMENT,
  POST_PORTFOLIO_EVENT,
  POST_PORTFOLIO_GIA_WORLDSKILLS,
  POST_PORTFOLIO_JOB,
  POST_PORTFOLIO_PROJECT,
  POST_PORTFOLIO_REWARD,
  POST_PORTFOLIO_SPO_DOCUMENT,
  POST_PORTFOLIO_SPORT_REWARD,
  POST_STUDENT_SPO_STATUS,
  postPortfolioSpoDocumentActions,
  REPORT_ERROR,
  SAVE_USER_THEME_SETTINGS,
  SAVE_VISIBILITY_SETTINGS,
} from 'actions';
import { SUCCESS } from 'actions/utils';
import { IStudentVisibilitySetting } from 'api/types';
import { GET_STUDENT_SUBJECT_THEMES } from 'portfolio3/features/performance/model/actions';
import { GET_STUDENT_PERFORMANCE_LESSONS_OLD } from 'portfolio3/features/performanceOld/model';
import { ADD_FAVORITE_VUZ, DELETE_FAVORITE_VUZ } from 'portfolio3/features/vuzRecommendation/model/actions';
import { IReducers } from 'reducers';
import { connectReducers } from 'utils';

import { emitYMEvent } from '../utils/emitYMEvent';
import { getMetrikaVisibilitySections } from '../utils/shapeMapping';

const reducer: IReducers<null> = {
  [CREATE_STUDENT_SHARE_LINK[SUCCESS]]: (state, action) => {
    const meta = action?.payload?.meta;
    if (meta) {
      const eventType = meta?.type === 'qr' ? 'qrCodeSettingCopy' : 'linkSettingCopy';
      emitYMEvent({ type: eventType, payload: meta });
    }

    return state;
  },
  [SAVE_VISIBILITY_SETTINGS[SUCCESS]]: (state, action) => {
    const sections = action?.payload?.data?.setting?.visibilitySettings as IStudentVisibilitySetting[];

    if (sections) {
      emitYMEvent({
        type: 'settingsSaving',
        payload: {
          sections: getMetrikaVisibilitySections(sections),
        },
      });
    }

    return state;
  },
  [SAVE_USER_THEME_SETTINGS[SUCCESS]]: (state) => {
    emitYMEvent({ type: 'visualConfiguringSuccessful' });
    return state;
  },
  [POST_STUDENT_SPO_STATUS[SUCCESS]]: (state) => {
    emitYMEvent({ type: 'typeEmploymentSelectionSuccessful' });
    return state;
  },
  [GET_STUDENT_SUBJECT_THEMES[SUCCESS]]: (state, action) => {
    if (action?.payload?.meta) {
      emitYMEvent({ type: 'subjectChoosingStudy', payload: action.payload.meta });
    }
    return state;
  },
  // Дублирует GET_STUDENT_SUBJECT_THEMES[SUCCESS]
  // TODO удалить после удаления старого виджета диагностик
  [GET_STUDENT_PERFORMANCE_LESSONS_OLD[SUCCESS]]: (state, action) => {
    if (action?.payload?.meta) {
      emitYMEvent({ type: 'subjectChoosingStudy', payload: action.payload.meta });
    }
    return state;
  },
  [REPORT_ERROR[SUCCESS]]: (state, action) => {
    if (action?.payload?.meta) {
      emitYMEvent({ type: 'bugReportSend', payload: action.payload.meta });
    }
    return state;
  },
  [GRATITUDE_TEACHER[SUCCESS]]: (state, action) => {
    if (action?.payload?.meta) {
      emitYMEvent({ type: 'gratitudeTeacherOpenSuccessfull', payload: action.payload.meta });
    }
    return state;
  },

  /* Успешный ответ сервера о добавлении сущностей */
  // any entity success
  [POST_PORTFOLIO_EVENT[SUCCESS]]: entityAddingSuccessReducer,
  [POST_PORTFOLIO_PROJECT[SUCCESS]]: entityAddingSuccessReducer,
  [POST_PORTFOLIO_EMPLOYMENT[SUCCESS]]: entityAddingSuccessReducer,
  [POST_PORTFOLIO_AFFILATION[SUCCESS]]: entityAddingSuccessReducer,
  [POST_PORTFOLIO_GIA_WORLDSKILLS[SUCCESS]]: entityAddingSuccessReducer,
  [POST_PORTFOLIO_SPO_DOCUMENT[SUCCESS]]: (
    state,
    action: ReturnType<typeof postPortfolioSpoDocumentActions.success>,
  ) => {
    emitEntityAddingSuccessEvent(action);

    // document success
    if (action?.payload?.data?.name) {
      emitYMEvent({
        type: 'addingDocumentSuccessfull',
        payload: {
          type_document: action.payload.data.name,
        },
      });
    }

    return state;
  },
  [POST_PORTFOLIO_JOB[SUCCESS]]: entityAddingSuccessReducer,
  // reward success
  [POST_PORTFOLIO_REWARD[SUCCESS]]: rewardAddingSuccessReducer,
  [POST_PORTFOLIO_SPORT_REWARD[SUCCESS]]: rewardAddingSuccessReducer,

  // Успешный ответ сервера о редактировании сущностей
  [EDIT_PORTFOLIO_REWARD[SUCCESS]]: rewardEditingSuccessReducer,
  [EDIT_PORTFOLIO_SPORT_REWARD[SUCCESS]]: rewardEditingSuccessReducer,

  /* рекомендации вузов */
  [ADD_FAVORITE_VUZ[SUCCESS]]: (state) => {
    emitYMEvent({ type: 'favoritesAdding' });
    return state;
  },
  [DELETE_FAVORITE_VUZ[SUCCESS]]: (state) => {
    emitYMEvent({ type: 'favoritesDeletion' });
    return state;
  },

  /* интересы */
  [CREATE_STUDENT_INTEREST_LIST[SUCCESS]]: (state) => {
    emitYMEvent({ type: 'completionInterestSuccessful' });
    return state;
  },
};

export const yandexMetrikaAsyncEventsReducer = connectReducers(null, reducer);

export const collection = { yandexMetrikaAsyncEventsReducer };

function emitEntityAddingSuccessEvent(action: any) {
  if (action?.payload?.meta) {
    emitYMEvent({ type: 'addEntitySuccessfull', payload: action.payload.meta });
  }
}

function entityAddingSuccessReducer(state: any, action: any) {
  emitEntityAddingSuccessEvent(action);
  return state;
}

function rewardAddingSuccessReducer(state: any, action: any) {
  if (action?.payload?.meta) {
    emitYMEvent({ type: 'rewardAddingSuccessfull', payload: action.payload.meta });
  }
  return state;
}

function rewardEditingSuccessReducer(state: any) {
  emitYMEvent({ type: 'awardEditing' });
  return state;
}
