/* eslint-disable prettier/prettier */
/* eslint-disable */

import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const IconChevronLargeUp: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M11.988 8c.28 0 .537.116.747.3l6.95 6.91c.42.415.42 1.062 0 1.478a1.049 1.049 0 0 1-1.493 0l-6.204-6.192-6.18 6.169a1.049 1.049 0 0 1-1.493 0 1.026 1.026 0 0 1 0-1.479l6.927-6.909c.187-.185.466-.277.746-.277Z"
      fill={props.fill || 'currentColor'}
    />
  </SvgIcon>
);

export default IconChevronLargeUp;
