import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getAdminSettingsActions, getUserSettingsActions } from 'actions';
import { IChildInfo, RequestCacheStatus } from 'api/types';
import { RendererContext } from 'context';
import AppLoader from 'portfolio3/components/common/AppLoader';
import { IRootState } from 'reducers';
import { AdminSettingsState } from 'reducers/admin/adminSettings';
import { BoundaryErrorState } from 'reducers/boundaryError';
import { UserSettingsState } from 'reducers/userSettings';
import { linkVisibilitySettingsSelector, visibilitySettingsSelector } from 'selectors';
import { IViewVisibilitySetting, RendererType } from 'types';
import { getStudentPortfolioPath } from 'utils';

interface IMainSettingsLoaderProps {
  currentStudent?: IChildInfo;
  linkMode: boolean | undefined;
  stateVisibilitySettings: UserSettingsState;
  stateAdminVisibilitySettings: AdminSettingsState;
  visibilitySettings: IViewVisibilitySetting[];
  linkVisibilitySettings: IViewVisibilitySetting[];
  boundaryError: BoundaryErrorState;
  getUserSettings: typeof getUserSettingsActions.request;
  getAdminVisibilitySettings: typeof getAdminSettingsActions.request;
}

const MainSettingsLoader: FC<IMainSettingsLoaderProps & PropsWithChildren> = ({
  currentStudent,
  linkMode,
  stateVisibilitySettings,
  stateAdminVisibilitySettings,
  visibilitySettings,
  linkVisibilitySettings,
  boundaryError,
  getUserSettings,
  getAdminVisibilitySettings,
  children,
}) => {
  const history = useHistory();
  const [rendererType, setRendererType] = useState<RendererType | null>(null);

  const settings = linkMode ? linkVisibilitySettings : visibilitySettings;
  const isVisiblitySettingsLoading = settings.length === 0;

  const handleChangeRendererType = (renderer: RendererType | null) => {
    setRendererType(renderer);
  };

  useEffect(() => {
    if (linkMode) {
      const newPathName = getStudentPortfolioPath(location.pathname, linkVisibilitySettings);
      if (newPathName) history.push(newPathName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkMode, linkVisibilitySettings]);

  useEffect(() => {
    // Если портфолио открыто для студента или родителя, то вычисляем доступный раздел и открываем его
    const newPathName = getStudentPortfolioPath(location.pathname, visibilitySettings);
    if (newPathName && rendererType !== 'interests') {
      history.push(`${newPathName}${location.search}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibilitySettings]);

  useEffect(() => {
    const meshId = currentStudent?.meshId;
    const classLevel = currentStudent?.classLevel;

    if (!meshId) return;
    if (rendererType === null) return;

    const userSettingsRenderers: RendererType[] = ['main', 'settings', 'interests'];
    const adminSettingsRenderers: RendererType[] = ['main', 'settings', 'link', 'interests'];
    const requestStatuses: RequestCacheStatus[] = ['notCached', 'invalidated'];

    const userStatus = stateVisibilitySettings.requestCache ?? 'notCached';
    const shouldRequestUserSettings = requestStatuses.includes(userStatus);

    const adminStatus = stateAdminVisibilitySettings.requestCache ?? 'notCached';
    const shouldRequestAdminSettings = requestStatuses.includes(adminStatus);

    if (userSettingsRenderers.includes(rendererType) && shouldRequestUserSettings) {
      getUserSettings(meshId);
    }

    if (adminSettingsRenderers.includes(rendererType) && shouldRequestAdminSettings) {
      getAdminVisibilitySettings(classLevel);
    }
  }, [
    rendererType,
    currentStudent?.classLevel,
    currentStudent?.meshId,
    getAdminVisibilitySettings,
    getUserSettings,
    stateVisibilitySettings.requestCache,
    stateAdminVisibilitySettings.requestCache,
  ]);

  const typesWithLoaders: RendererType[] = ['main', 'settings', 'link'];
  const isLoader =
    !rendererType || (rendererType && typesWithLoaders.includes(rendererType) && isVisiblitySettingsLoading);

  return (
    <RendererContext.Provider
      value={{
        currentRenderer: rendererType,
        setCurrentRenderer: handleChangeRendererType,
      }}
    >
      {/* При наличии основной ошибки, лоадер скрывается, в children рендерится заглушка */}
      {!boundaryError.hasMainBoundaryError && isLoader && <AppLoader />}
      {/* Лоадер не должен блокировать рендер секций для работы RendererContext */}
      {children}
    </RendererContext.Provider>
  );
};

export default connect(
  (state: IRootState) => ({
    currentStudent: state.currentStudent,
    linkMode: state.linkMode.mode,
    stateVisibilitySettings: state.visibilitySettings,
    stateAdminVisibilitySettings: state.adminSettings,
    visibilitySettings: visibilitySettingsSelector(state),
    linkVisibilitySettings: linkVisibilitySettingsSelector(state),
    boundaryError: state.boundaryError,
  }),
  {
    getUserSettings: getUserSettingsActions.request,
    getAdminVisibilitySettings: getAdminSettingsActions.request,
  },
)(MainSettingsLoader);
