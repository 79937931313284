import { createSelector } from 'reselect';

import { IRootState } from '../../../reducers';
import { mapSportExpeditionsResponse } from './mappers';

const sportExpeditionsSelector = createSelector(
  [
    (state: IRootState) => state.studentEvents,
    (state: IRootState) => state.studentSportRewards,
    (state: IRootState) => state.tourismKinds,
    (state: IRootState) => state.sectionRef,
  ],
  (studentEvents, studentRewards, tourismKinds, sectionRef) => {
    return mapSportExpeditionsResponse(studentEvents, studentRewards, tourismKinds, sectionRef);
  },
);

export default sportExpeditionsSelector;
