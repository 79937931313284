import { NeutralColors } from 'portfolio3/styles';
import { SxStyles } from 'types';

export const root: SxStyles = {
  height: '60px',
};

export const overlay: SxStyles = {
  position: 'absolute',
  inset: 0,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  width: '100%',
  height: '100%',

  backgroundColor: 'rgba(13, 13, 16, 0.48)',

  opacity: 0,
  transition: 'opacity 0.1s',
};

export const rootOverlayTrigger = (isActive: boolean): SxStyles => {
  return {
    '& .theme-preview__overlay': {
      opacity: isActive ? 1 : 0,
    },

    '&:hover .theme-preview__overlay': {
      opacity: 1,
    },
  };
};

export const overlayIcon: SxStyles = {
  color: NeutralColors.light_neutrals_0,

  width: '24px',
  height: '24px',
};

export const overlayText: SxStyles = {
  color: NeutralColors.light_neutrals_0,
};
