/* eslint-disable prettier/prettier */
/* eslint-disable */

import { connectReducers } from '../../utils';
import genericReducers from '../genericReducer';
import { IReducers } from '../index';

import { GET_SPORT_TRAINING_STAGE } from '../../actions/employees';
import { REQUEST, FAILURE, SUCCESS } from '../../actions/utils';

import { ICommonResponse, IDictionaryItem } from '../../api/types';

export type SportTrainingStageState = ICommonResponse<IDictionaryItem>;

const initialState = {} as ICommonResponse<IDictionaryItem>;

const sportTrainingStage: IReducers<SportTrainingStageState> = {
  [GET_SPORT_TRAINING_STAGE[REQUEST]]: genericReducers().get.request,
  [GET_SPORT_TRAINING_STAGE[SUCCESS]]: genericReducers().get.success,
  [GET_SPORT_TRAINING_STAGE[FAILURE]]: genericReducers().get.failure
};

export default connectReducers(initialState, sportTrainingStage);
